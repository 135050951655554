import React, {useEffect} from 'react';
import {TabItem, TabView as TabViewComponent} from '@unthinkable/react-tabs';
import {StatusTabViewStyles, TabViewStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../providers/AppState';
import {useNavigation} from '@react-navigation/native';
import {useFetchCount} from '@unthinkable/react-data-handler';
import {useModuleGlobalParams} from '../../controllers/useModuleGlobalParams';
import {removeRouteParams} from '../menu/Utility';

const TabItemWithCount = props => {
  const {fetch} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);

  let {item, params, ...restProps} = props;
  if (typeof item === 'string') {
    item = {label: item, value: item};
  }
  let {params: itemParams, ...restItem} = item;

  params = removeRouteParams({
    ...props,
    params: itemParams || params,
  });

  const {count} = item || {};

  let itemFetchCount = useFetchCount({
    fetch,
    globalParams,
    params,
    ...restItem,
  });

  return (
    <TabItem {...restProps} item={{...item, count: count || itemFetchCount}} />
  );
};

const renderTabItem = props => <TabItemWithCount {...props} />;

const LinkTabComponent = props => {
  const navigation = useNavigation();

  let params = props?.params || props?.route?.params || {};

  useEffect(() => {
    if (!params?.tabId) {
      const newParams = {
        tabId: props?.selectedTab || Object.keys(props?.tabs)[0],
      };
      if (
        props?.tabs[props?.selectedTab]?.apiKey ||
        props?.tabs[0]?.apiKey ||
        props?.tabs[Object.keys(props?.tabs)[0]]?.apiKey
      ) {
        newParams.tabApiKey =
          props?.tabs[props?.selectedTab]?.apiKey ||
          props?.tabs[0]?.apiKey ||
          props?.tabs[Object.keys(props?.tabs)[0]]?.apiKey;
      }
      navigation.setParams(newParams);
    } else if (params?.tabId && !params?.tabApiKey) {
      const newParams = {};
      if (props?.tabs[params.tabId]?.apiKey) {
        newParams.tabApiKey = props?.tabs[params.tabId]?.apiKey;
      }
      if (Object.keys(newParams).length) {
        navigation.setParams(newParams);
      }
    }
  }, [params]);

  return <TabViewComponent {...props} isLinkTab />;
};

export const LinkTabs = props => {
  const styles = useStyles(TabViewStyles);
  const navigation = useNavigation();
  const onSelectTab = (value, apiKey) => {
    const newParams = {
      tabId: value,
    };
    if (apiKey) {
      newParams.tabApiKey = apiKey;
    } else {
      newParams.tabApiKey = value;
    }
    navigation.setParams(newParams);
  };

  return (
    <LinkTabComponent
      {...props}
      renderTabItem={renderTabItem}
      onSelectTab={onSelectTab}
      styles={styles}
    />
  );
};

export const TabView = ({isLinkTab, ...props}) => {
  const styles = useStyles(TabViewStyles);
  if (isLinkTab) {
    return <LinkTabs {...props} />;
  }
  return (
    <TabViewComponent
      {...props}
      renderTabItem={renderTabItem}
      styles={styles}
    />
  );
};

export const StatusTabView = props => {
  const styles = useStyles(StatusTabViewStyles);
  return (
    <TabViewComponent
      {...props}
      renderTabItem={renderTabItem}
      styles={styles}
    />
  );
};
