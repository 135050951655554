import React from 'react';
import {UpdateQAStatusChip} from '../chip/UpdateQAStatusChip';
import {useComponentTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';

export const BugStatusChangeRender = ({
  row,
  navigation,
  params,
  invoke,
  readOnly,
}) => {
  const {status} = row;

  let renderChip = void 0;

  let {
    columnTextStyle,
    activeTextStyle,
    activeBackgroundStyle,
    passedBackgroundStyle,
    rejectedBackgroundStyle,
    passedTextStyle,
    rejectedTextStyle,
  } = useComponentTheme('StatusRenderStyles');

  if (['Open', 'Ignored', 'Resolved'].includes(status)) {
    renderChip = (
      <Tag
        value={status}
        color={activeBackgroundStyle.backgroundColor}
        textColor={activeTextStyle.color}
        styles={columnTextStyle}
      />
    );
  } else if (status === 'Passed') {
    renderChip = (
      <Tag
        value={status}
        color={passedBackgroundStyle.backgroundColor}
        textColor={passedTextStyle.color}
        styles={columnTextStyle}
      />
    );
  } else if (status === 'Failed') {
    renderChip = (
      <Tag
        value={status}
        color={rejectedBackgroundStyle.backgroundColor}
        textColor={rejectedTextStyle.color}
        styles={columnTextStyle}
      />
    );
  } else if (status === 'QA') {
    renderChip = (
      <UpdateQAStatusChip
        onPass={() => {
          !readOnly &&
            invoke &&
            invoke({
              uri: `/projectbugs/${row?._id}`,
              props: {
                status: 'Passed',
              },
            });
        }}
        onReject={() => {
          navigation.navigate('add-bug-comment', {
            ...params,
            row,
            status: 'Failed',
          });
        }}
      />
    );
  }
  return renderChip;
};
