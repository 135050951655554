import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {RepositoryDetail} from './RepositoryForm';
import {RepositoryCodeSamplesTable} from './RepositoryCodeSamplesTable';
import {RepositoryFolderPathScreen} from './RepositoryFolderPathScreen';
import {RepositoryEnvironmentWithFeatures} from './RepositoryEnvironmentWithFeatures';

const RepositoriesMenu = props => {
  const {
    route: {params},
  } = props;

  const {project} = params;

  let menus = [
    {
      label: 'Detail',
      view: <RepositoryDetail {...props} />,
    },
    {
      label: 'Environments',
      view: <RepositoryEnvironmentWithFeatures {...props} />,
    },
    {
      visible:
        params?.repository?.type === 'Frontend' ||
        params?.repository?.type === 'Backend',
      label: 'Folders',
      view: <RepositoryFolderPathScreen {...props} />,
    },
    {
      label: 'Code Samples',
      view: <RepositoryCodeSamplesTable {...props} />,
      visible: !!project?.ai_enabled,
    },
    
  ];

  return <LeftMenuView {...props} menus={menus} />;
};

export default RepositoriesMenu;
