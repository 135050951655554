import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ActiveElementsForm = props => {
  const {
    route: {params},
  } = props;
  const {activeElementId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/activeElements',
    eventSourceId: 'ActiveElement',
  });
  return (
    <Form
      api={`/activeElements/${activeElementId}`}
      fields={{
        label: 1,
        filter: 1,
        on_press_url: 1,
        department: {name: 1},
        model: {name: 1},
        product: {
          name: 1,
        },
        sequence: 1,
        access_groups: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'label',
          label: 'Label',
        },
        {
          type: 'text',
          field: 'filter',
          label: 'Filter',
        },
        {
          type: 'text',
          field: 'on_press_url',
          label: 'On Press URL',
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Model',
          field: 'model',
          type: 'autoComplete',
          api: '/types',
          filter: {
            departments: params?.department?._id,
            is_show: true,
          },
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Products',
          field: 'product',
          type: 'multiAutoComplete',
          api: '/products',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          type: 'number',
          field: 'sequence',
          label: 'Sequence',
        },
        {
          type: 'multiAutoComplete',
          field: 'access_groups',
          label: 'Access Groups',
          api: '/accessGroups',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddActiveElementsForm = props => {
  return <ActiveElementsForm header="Add Active Elements" {...props} />;
};

export const UpdateActiveElementsForm = props => {
  return (
    <ActiveElementsForm
      mode="edit"
      header="Update Active Elements"
      {...props}
    />
  );
};
