import React from 'react';
import StrategicInitiativesTable from './StrategicInitiativesTable';

export const CompletedStrategicInitiativesTable = props => {
  return (
    <StrategicInitiativesTable
      title="Completed Strategic Initiatives"
      filter={{status: 'Complete'}}
      isCompleted = {true}
      {...props}
    />
  );
};
