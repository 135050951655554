import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Table} from '../../../components/table/Table';
import {RenderSkills} from './BenchResource';
import {ActionText} from '../../../components/action/ActionText';
import {Efficiency} from '../../../components/efficiency/Efficiency';
import moment from 'moment';
import {Text} from '@unthinkable/react-core-components';
import { useInvoke } from '../../../controllers/useInvoke';

export const NAME = {
  header: 'Name',
  field: 'name',
};

export const PERFORMANCEMANAGER = {
  header: 'Performance Manager',
  field: 'performance_manager',
  type: 'userAvatarGroup',
  align: 'right',
  width: 170,
};

const assignShadow = ({navigation, params, fromMyView}) => ({
  title: 'Assign Shadow',
  onPress: props => {
    const {row} = props;
    navigation.navigate('workload-assign-shadow-form', {
      row,
    });
  },
  visible: !fromMyView,
});

const assignTrainee = ({navigation, params, fromMyView}) => ({
  title: 'Assign Trainee',
  onPress: props => {
    const {row} = props || {};
    let {assigned_to} = row || {};
    navigation.navigate('assign-trainee-form', {
      employee: {
        _id: assigned_to?._id,
        name: assigned_to?.name,
      },
    });
  },
  visible: !fromMyView,
});

const changeAllocation = ({navigation, params, fromMyView}) => ({
  title: 'Add Replacement',
  onPress: props => {
    const {row} = props || {};
    const {_id, to_date, skill_requirement = {}, ...rest} = row || {};
    navigation.navigate('workload-assign-replacement-form', {
      row,
    });
  },
  visible: !fromMyView,
});

export const BillableEmployeeTeamList = props => {
  const {
    route: {params},
    navigation,
    fromMyView,
    user,
    searchValue,
    tab,
    filterValues,
  } = props;
  const {filter: filterValue = {}, params: dateParams} = filterValues || {};
  let filter = {};
  if (fromMyView) {
    filter = {
      performance_manager: user?.employee?._id,
    };
  }
  const {period} = params || {};
  const {from: start_date, to: end_date} = period || {};
  params.period = dateParams?.period;
  return (
    <Table
      params={params}
      addOnFilter={{...filter, ...filterValue}}
      eventSourceId={['EmployeeTeam']}
      search={searchValue}
      limit={5000}
      searchFields="name"
      api={`/employees/billableteam`}
      onRowPress={({row}) => {
        navigation.navigate('edit-employee-team', {row});
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          width: 200,
          onPress: ({row}) => {
            let {project} = row;
            navigation.navigate(`employee-team-project`, {
              ...params,
              _id: project._id,
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'all_resource',
          field: 'all_resource',
          header: 'Resource',
          aggregate: true,
          align: 'right',
          width: 110,
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              employee_team: {_id: row?._id, name: row.name},
              employeeFilter: {
                project_id: row?.project,
                from_date: {$lte: end_date},
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'resource_count',
          field: 'resource_count',
          header: 'Billable',
          aggregate: true,
          align: 'right',
          width: 110,
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              row,
              employeeFilter: {
                project_id: row?.project,
                from_date: {$lte: end_date},
                assigned_type: 'Billable',
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'avg_experience',
          header: 'Avg Exp',
          aggregate: true,
          align: 'right',
          width: 110,
        },
        {
          render: CountCellRender,
          count_field: 'shadow_resource_count',
          field: 'shadow_resource_count',
          header: 'Shadow',
          aggregate: true,
          align: 'right',
          width: 110,
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              row,
              employeeFilter: {
                project_id: row?.project,
                from_date: {$lte: end_date},
                is_shadow: true,
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'manager_resource_count',
          field: 'manager_resource_count',
          header: 'Manager',
          aggregate: true,
          align: 'right',
          width: 110,
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              row,
              employeeFilter: {
                project_id: row?.project,
                from_date: {$lte: end_date},
                is_manager: true,
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'workload_count',
          field: 'workload_count',
          header: 'WorkLoad',
          aggregate: true,
          align: 'right',
          width: 110,
          onPress: ({row}) => {
            let {workload} = row;
            navigation.navigate(`employee-team-workload`, {
              ...params,
              assigned_type: 'Billable',
              employee_team: {_id: row?._id, project: row?.project},
            });
          },
        },
        {
          header: 'Quality',
          field: 'quality',
          type: 'text',
          align: 'right',
          width: 110,
        },
        {
          render: CountCellRender,
          count_field: 'efficiency_count',
          field: 'efficiency_count',
          header: 'Efficiency',
          aggregate: true,
          align: 'right',
          width: 110,
          onPress: ({row}) => {
            navigation.navigate('team-employee-efficiency', {
              ...params,
              employee_team: {_id: row?._id},
              fromEmployeeTeam: true,
            });
          },
        },
        {
          header: 'Impact',
          field: 'impact',
          type: 'text',
          width: 110,
          align: 'right',
        },
        PERFORMANCEMANAGER,
        {
          header: 'Managed By',
          field: 'project_sub_category',
          type: 'text',
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Custom Tasks',
          onPress: ({row}) => {
            navigation.navigate('adhoc-task-tabs', {
              ...params,
              employee_team: {_id: row?._id},
            });
          },
        },
        {
          title: 'All Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-task-tabs', {
              ...params,
              employee_team: {_id: row?._id, name: row?.name},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const NonBillableEmployeeTeamList = props => {
  const {
    route,
    params,
    navigation,
    fromMyView,
    user,
    searchValue,
    tab,
    filterValues,
  } = props;
  let filter = {};
  if (fromMyView) {
    filter = {
      performance_manager: user?.employee?._id,
    };
  }
  let {filter: filtervalue, params: dateParams} = filterValues;
  const {period} = params || {};
  const {from: start_date, to: end_date} = period || {};
  params.period = dateParams?.period;
  return (
    <Table
      params={params}
      addOnFilter={{...filter, ...filtervalue}}
      eventSourceId={['EmployeeTeam']}
      search={searchValue}
      limit={2000}
      searchFields="name"
      api={`/employees/nonbillableteam`}
      onRowPress={({row}) => {
        navigation.navigate('edit-employee-team', {row});
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          width: 200,
          onPress: ({row}) => {
            let {project} = row;
            navigation.navigate(`employee-team-project`, {
              ...params,
              _id: project._id,
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'resource_count',
          field: 'resource_count',
          aggregate: true,
          header: 'Resources',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              employee_team: {_id: row?._id, name: row.name},
              employeeFilter: {
                project_id: row?.project,
                from_date: {$lte: end_date},
                assigned_type: 'Non-Billable',
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'avg_experience',
          header: 'Avg Exp',
          aggregate: true,
          align: 'right',
        },
        // {
        //   render: CountCellRender,
        //   count_field: 'workload_count',
        //   field: 'workload_count',
        //   header: 'WorkLoad',
        //   aggregate: true,
        //   align: 'right',
        //   onPress: ({row}) => {
        //     let {workload} = row;
        //     navigation.navigate(`employee-team-workload`, {
        //       ...params,
        //       assigned_type: 'Billable',
        //       employee_team: {_id: row?._id, project: row?.project},
        //     });
        //   },
        // },
        {
          header: 'Quality',
          field: 'quality',
          type: 'text',
          align: 'right',
        },
        {
          render: CountCellRender,
          count_field: 'efficiency_count',
          field: 'efficiency_count',
          header: 'Efficiency',
          aggregate: true,
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate('team-employee-efficiency', {
              ...params,
              employee_team: {_id: row?._id},
              fromEmployeeTeam: true,
            });
          },
        },
        {
          header: 'Impact',
          field: 'impact',
          type: 'text',
          align: 'right',
        },
        PERFORMANCEMANAGER,
      ]}
      moreActions={[
        {
          title: 'Custom Tasks',
          onPress: ({row}) => {
            navigation.navigate('adhoc-task-tabs', {
              ...params,
              employee_team: {_id: row?._id},
            });
          },
        },
        {
          title: 'All Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-task-tabs', {
              ...params,
              employee_team: {_id: row?._id, name: row?.name},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const OtherEmployeeTeamList = props => {
  const {params, navigation, fromMyView, departmentTasks, user, searchValue, filterValues} =
    props;
  const {params: filterParams = {}} = filterValues;
  let {period} = filterParams || {};

  const updateInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'markInactive',
  });

  let filter = {};
  if (fromMyView) {
    filter = {
      performance_manager: user?.employee?._id,
    };
  }

  const navigationParams = ({row}) => ({
    employee_team: {_id: row?._id, name: row.name},
    fromNonProject: true,
    employeeFilter: {
      assigned_type: 'Non-Billable',
      team_id: row?._id,
      from_date: {
        $lte: period?.to || moment.utc().endOf('day').toDate(),
      },
      $or: [
        {
          to_date: {
            $gte: period?.from || moment.utc().startOf('day').toDate(),
          },
        },
        {to_date: {$exists: false}},
      ],
    },
  });

  return (
    <Table
      params={{
        ...params,
        ...filterParams,
      }}
      addOnFilter={{...filter}}
      eventSourceId={['EmployeeTeam', 'markInactive']}
      search={searchValue}
      searchFields="name"
      api={`/employees/nonprojectteams`}
      columns={[
        NAME,
        {
          render: CountCellRender,
          count_field: 'resource_count',
          header: 'Team',
          field: 'resource_count',
          aggregate: true,
          align: 'right',
          onPress: ({row}) => {
            if(departmentTasks){
              navigation.navigate(`resource-allocations-list-department`, {
                ...navigationParams({row}),
                api: '/teamAllocatedResources',
              });
            }
            else{
              navigation.navigate(`resource-allocations-list`, {
                ...navigationParams({row}),
                api: '/teamAllocatedResources',
              });
            }
           
          },
          width: 100,
        },
        {
          field: 'avg_experience',
          header: 'Avg Exp',
          align: 'right',
          render: ({row, styles: {rowText = {}}}) => {
            return <Text style={{...rowText}}>{row?.avg_experience} yrs</Text>;
          },
          width: 100,
        },
        // {
        //   value: ({row}) => {
        //     const {resource_count = 0, workload = 0} = row;
        //     if (!resource_count) {
        //       return 0;
        //     }
        //     const percentage = (workload * 100) / (resource_count * 190);
        //     return `${percentage.toFixed(1)}%`;
        //   },
        //   field: 'workload',
        //   header: 'WorkLoad(%)',
        //   align: 'right',
        //   onPress: ({row}) => {
        //     navigation.navigate(`employee-team-workload`, {
        //       ...params,
        //       assigned_type: 'NonBillable',
        //       employee_team: {_id: row?._id, project: row?.project},
        //     });
        //   },
        //   width: 120,
        // },
        PERFORMANCEMANAGER,
        {
          align: 'right',
          width: 140,
          header: 'Eff. (Last week)',
          render: ({row}) => {
            if (row.efficiency_percentage == undefined) {
              return <ActionText text="View Efficiency" />;
            } else {
              return <Efficiency value={row.efficiency_percentage} />;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('team-employee-efficiency', {
              ...params,
              employee_team: {_id: row?._id},
              fromEmployeeTeam: true,
              fromOrganisation: true,
            });
          },
        },
        {
          align: 'right',
          width: 100,
          render: () => {
            return <ActionText text="View Tasks" />;
          },
          onPress: ({row}) => {
            navigation.navigate('team-tasks-menus', {
              ...params,
              employee_team: {_id: row?._id, name: row?.name},
            });
          },
        },
        {
          align: 'right',
          width: 120,
          render: () => {
            return <ActionText text="View Threads" />;
          },
          onPress: ({row}) => {
            navigation.navigate('team-thread-tabs', {
              ...params,
              employee_team: {_id: row?._id, name: row?.name},
              teamThreads: true,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-employee-team', {row});
          },
          visible: () => {
            if (
              user?.email === 'sushil.nagvan@daffodilsw.com' ||
              user?.email === 'amit.singh@daffodildb.com' ||
              user?.email === 'aswathy.krishnankutty@unthinkable.co' ||
              user?.email === 'rohit.bansal@daffodilsw.com'
            ) {
              return true;
            }
          },
        },
        {
          title: 'Mark Team Inactive',
          confirm: {
            title: 'Mark Team Inactive',
            message:
                `Are you sure you want to mark this team as Inactive ?`,
            confirmText: 'Confirm',
          },
          onPress: ({ row }) => {
            updateInvoke({
              uri: `/employeeteams/${row?._id}`,
              props: {
                  status: 'Inactive',
              }
            });
          },
          visible: () => {
            if (
              user?.email === 'sushil.nagvan@daffodilsw.com' ||
              user?.email === 'amit.singh@daffodildb.com' ||
              user?.email === 'aswathy.krishnankutty@unthinkable.co'
            ) {
              return true;
            }
          },
        },
        {
          title: 'Inactive Employees',
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              ...navigationParams({row}),
              api: '/teamInactiveAllocatedResources',
              skipAddMember: true,
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const TrainingTeamList = props => {
  const {
    route: {params},
    navigation,
    fromMyView,
    user,
    searchValue,
    tab,
    filterValues,
  } = props;
  let filter = {};
  if (fromMyView) {
    filter = {
      performance_manager: user?.employee?._id,
    };
  }
  let {params: dateParams} = filterValues;
  const {period} = params || {};
  const {from: start_date, to: end_date} = period || {};
  params.period = dateParams.period;
  return (
    <Table
      params={params}
      addOnFilter={{...filter}}
      eventSourceId={['EmployeeTeam']}
      search={searchValue}
      searchFields="name"
      api={`/employees/trainingteams`}
      onRowPress={({row}) => {
        navigation.navigate('edit-employee-team', {row});
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          width: 200,
          onPress: ({row}) => {
            let {project} = row;
            navigation.navigate(`employee-team-project`, {
              ...params,
              _id: project._id,
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'resource_count',
          field: 'resource_count',
          aggregate: true,
          header: 'Resources',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(`training-resource-allocations-list`, {
              employee_team: {_id: row?._id, name: row.name},
              employeeFilter: {
                project_id: row?.project,
                from_date: {$lte: end_date},
                assigned_type: 'Non-Billable',
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'avg_experience',
          header: 'Avg Exp',
          aggregate: true,
          align: 'right',
        },
        PERFORMANCEMANAGER,
      ]}
      moreActions={[
        {
          title: 'Workload',
          onPress: ({row}) => {
            navigation.navigate('employee-team-workload', {
              ...params,
              employee_team: {_id: row?._id},
            });
          },
        },
        {
          title: 'Efficiency',
          onPress: ({row}) => {
            navigation.navigate('team-employee-efficiency', {
              ...params,
              employee_team: {_id: row?._id},
              fromEmployeeTeam: true,
            });
          },
        },
        {
          title: 'Custom Tasks',
          onPress: ({row}) => {
            navigation.navigate('adhoc-task-tabs', {
              ...params,
              employee_team: {_id: row?._id},
            });
          },
        },
        {
          title: 'All Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-task-tabs', {
              ...params,
              employee_team: {_id: row?._id, name: row?.name},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const TicketEnabledTeamList = props => {
  const {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeTeam']}
      searchFields="name"
      api={`/employeeTeams`}
      filter={{
        is_ticket_enabled: true,
      }}
      fields={{
        name: 1,
        performance_manager: {
          name: 1,
          color: 1,
        },
        ticket_head: {
          name: 1,
          color: 1,
        },
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-employee-team', {row});
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
        },
        {
          header: 'Performance Manager',
          field: 'performance_manager',
          type: 'userAvatarGroup',
          align: 'right',
        },
        {
          header: 'Ticket Head',
          type: 'userAvatarGroup',
          field: 'ticket_head',
        },
      ]}
      {...props}
    />
  );
};

export const NoAllocationTeamList = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    filterValues,
    fromMyView,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  let filtermain = {...filter};
  if (filterValues) {
    let {filter} = filterValues;
    filtermain = {...filter};
  }
  let {params: dateParams} = filterValues;
  params.period = dateParams.period;
  return (
    <Table
      eventSourceId={['NoAllocationTeam']}
      search={searchValue}
      limit={2000}
      searchFields="name"
      addOnFilter={filtermain}
      api={`/employees/no-allocation`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Employee',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Experience',
          field: 'experience',
        },
        {
          header: 'Code',
          field: 'employee_code',
          type: 'text',
        },
        {
          render: RenderSkills,
          header: 'Skills',
          width: 150,
        },
      ]}
      moreActions={() => [
        assignShadow({navigation, params, fromMyView}),
        changeAllocation({navigation, params, fromMyView}),
        assignTrainee({navigation, params, fromMyView}),
      ]}
      params={params}
      {...props}
    />
  );
};
export const LessAllocationTeamList = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    filterValues,
    fromMyView,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  let filtermain = {...filter};
  if (filterValues) {
    let {filter} = filterValues;
    if (filter && filter.tool) {
      const {tool, ...rest} = filter;
      filtermain = {...rest};
    }
  }
  let {params: dateParams} = filterValues;
  params.period = dateParams.period;
  return (
    <Table
      eventSourceId={['lessAllocationTeam']}
      search={searchValue}
      limit={2000}
      searchFields="name"
      addOnFilter={filtermain}
      api={`/employees/lessallocation`}
      // onRowPress={props => {
      //   const {row} = props;
      //   navigation.navigate(`resource-allocations-list`, {
      //     row,
      //     employeeFilter: {employee: row?._id},
      //   });
      // }}
      columns={[
        {
          header: 'Employee',
          field: 'assigned_to.name',
          type: 'text',
        },
        {
          header: 'Experience',
          field: 'experience',
        },
        {
          header: 'Code',
          field: 'employee_code',
          type: 'text',
        },
        {
          render: RenderSkills,
          header: 'Skills',
          width: 150,
        },
        {
          header: 'free',
          field: 'freePercentage',
        },
      ]}
      moreActions={() => [
        assignShadow({navigation, params, fromMyView}),
        changeAllocation({navigation, params, fromMyView}),
        assignTrainee({navigation, params, fromMyView}),
      ]}
      params={params}
      {...props}
    />
  );
};
