import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ProjectFeedbackDetail = props => {
  const {
    route: {params},
  } = props;
  const {feedback} = params;
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'ClientFeedback',
    uri: '/clientFeedbacks',
  });
  return (
    <Form
      header={{
        title: 'Client Feedback',
        secondaryTitle: feedback.project?.project,
      }}
      mode="edit"
      api={'/clientFeedbacks/' + feedback?._id}
      eventSourceId="ClientFeedback"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            feedback_requested: false,
            date: new Date(),
          },
        };
      }}
      onSubmit={onSubmit}
      fields={{
        client_score: 1,
        description: 1,
      }}
      readOnly={true}
      layoutFields={[
        {
          label: 'Client Score',
          field: 'client_score',
          type: 'rating',
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textArea',
        },
      ]}
      {...props}
    />
  );
};

export const EditProjectFeedbackForm = props => (
  <ProjectFeedbackDetail readOnly={false} {...props} />
);
