import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AssignLearningResource = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });
  const defaultValues = {
    is_shadow: true,
    bench_training_type: 'Shadow',
    employee: {_id: row._id, name: row.name},
    is_from_bench_resource: true,
  };

  return (
    <Form
      defaultValues={{
        ...defaultValues,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Assign Shadow For Learning"
      layoutFields={[
        {
          placeholder: 'Fornt Facing',
          label: 'Fornt Facing',
          field: 'front_facing_resource',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue('training_with_whom', value);
          },
          size: 6,
        },
        {
          placeholder: 'Project',
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/employeeassignments/projects`,
          suggestionField: 'project',
          valueField: 'project',
          secondarySuggestionField: 'order_number',
          required: true,
          filter: ({values}) => {
            return {
              employee: values?.training_with_whom?._id,
            };
          },
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue('order', value?.order);
            setFieldValue('skill_requirement', value?.skill_requirement);
            setFieldValue('milestone_id', value?.milestone_id);
            setFieldValue('order_assignment', value?.order_assignment);
            setFieldValue('resource_tool', value?.order_assignment);
            setFieldValue('tool', value?.order_assignment);
            setFieldValue('project_skill', value?.order_assignment);
            setFieldValue('resource_skill', value?.order_assignment);
            setFieldValue('to_date', value?.to_date);
            setFieldValue('from_date', getZeroTimeDate(new Date()));
          },
          size: 6,
        },
        {
          placeholder: 'Order',
          label: 'Order',
          field: 'order',
          type: 'autoComplete',
          api: `/orders`,
          suggestionField: 'order_number',
          valueField: 'order_number',
          required: true,
          readOnly: true,
          size: 6,
        },
        {
          placeholder: 'Hours',
          label: 'Hours',
          field: 'hours',
          type: 'number',
          size: 6,
        },

        {
          placeholder: 'Start Date',
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          size: 6,
        },
        {
          placeholder: 'End Date',
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          size: 6,
        },
        {
          label: 'Project Skill',
          placeholder: 'Project Skill',
          field: 'project_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          filter: {
            status: 'active',
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Project Tool',
          placeholder: 'Project Tool',
          field: 'tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Resource Skill',
          placeholder: 'Resource Skill',
          field: 'resource_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          filter: {
            status: 'active',
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Resource Tool',
          placeholder: 'Resource Tool',
          field: 'resource_tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
