import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {RenderName} from './CustomRendersCollection';

export const CommunicationTable = props => {
  const {editEmailReplyMessage} = messageControllers(props);
  const {
    route: {
      params: {
        _id,
        hide_contact_detail = false,
        addOnFilter: _addOnFilter,
      } = {},
    },
    navigation,
  } = props;
  let addOnFilter = {
    ..._addOnFilter,
  };
  const {data = [], ...restFetchProps} = useFetchData({
    api: '/communications',
    filter: addOnFilter,
    fields: {
      _id: 1,
      communication_mode: 1,
      contact_id: {
        email: 1,
        name: 1,
        first_name: 1,
        last_name: 1,
        linkedin_url: 1,
        designation: 1,
      },
      potential_customer: {
        name: 1,
        website: 1,
        country: {name: 1, code: 1},
        website_summary: 1,
        industry: {name: 1},
      },
      subject: 1,
      campaign_id: {name: 1},
      description: 1,
      communication_mode: 1,
      date: 1,
    },
    sort: {date: -1},
  });

  return (
    <Table
      {...props}
      onRowPress={({row}) => {
        navigation.navigate('action-summary-form', {
          ...row,
          data,
        });
      }}
      data={data}
      restFetchProps={restFetchProps}
      columns={[
        {
          type: 'text',
          field: 'contact_id.name',
          header: 'Contact',
          width: 200,
          visible: props => {
            return !hide_contact_detail;
          },
        },
        {
          header: 'Website',
          render: RenderName,
          width: 200,
          visible: props => {
            return !hide_contact_detail;
          },
        },
        {
          type: 'date',
          field: 'date',
          header: 'Date',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
        {
          type: 'textArea',
          field: 'description',
          header: 'Description',
        },
      ]}
      moreActions={[
        {
          title: 'View',
          onPress: editEmailReplyMessage,
        },
      ]}
    />
  );
};
