export const ColorInputStyles = ({theme: {colors, fonts}}) => {
  return {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 6,
      backgroundColor: colors.SURFACE1,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 13,
      paddingBottom: 13,
      alignItems: 'center',
    },
    colorViewStyle: {
      width: 50,
      height: 18,
      borderRadius: 4,
      marginRight: 8,
    },
    textStyle: {
      flex: 1,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    labelStyle: {...fonts.BODY3, color: colors.NEUTRAL_MEDIUM},
    modalStyle: {
      actionContainerStyle: {
        paddingTop: 8,
        justifyContent: 'flex-end',
      },
      actionTextStyle: {
        ...fonts.ACTION,
        color: colors.SECONDARY_HIGH,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
      },
      sm: {
        modalContainerStyle: {
          backgroundColor: colors.BACKGROUND,
          padding: 8,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          borderWidth: 1,
          borderColor: colors.OUTLINE,
        },
      },
      md: {
        modalContainerStyle: {
          backgroundColor: colors.BACKGROUND,
          borderRadius: 6,
          padding: 8,
          borderWidth: 1,
          borderColor: colors.OUTLINE,
        },
      },
    },
  };
};
