import React from 'react';
import {TouchableOpacity, View} from '@unthinkable/react-core-components';
import {hasValue, resolveExp} from '@unthinkable/react-utils';
import {
  ColumnText,
  HeaderAggregateText,
  HeaderColumnRow,
  HeaderIcon,
  HeaderText,
} from './styles/TableStyles';
import {HeaderSelectionRender} from './Renders';

export const TextRender = ({
  styles,
  title,
  value,
  textAlign,
  format,
  formatOptions,
  numberOfLines = 1,
  variant,
  color,
  ...props
}) => {
  if (typeof color === 'function') color = color({...props, value});
  if (value && format) {
    value = format(value, formatOptions);
  }
  if (Array.isArray(value) || (hasValue(value) && typeof value !== 'string')) {
    value = JSON.stringify(value);
  }

  return (
    <ColumnText
      title={title || value}
      styles={styles}
      color={color}
      textAlign={textAlign}
      variant={variant}
      numberOfLines={numberOfLines}>
      {value}
    </ColumnText>
  );
};

export const ColumnHeader = ({selection, ...props}) => {
  const {styles, title, label, numberOfLines = 1, textAlign, icon} = props;
  let selectionComponent = void 0;
  if (selection) {
    selectionComponent = (
      <HeaderSelectionRender {...props} selection={selection} />
    );
  }
  return (
    <HeaderColumnRow styles={styles}>
      {selectionComponent}
      {icon ? <HeaderIcon icon={icon} styles={styles} /> : void 0}
      {label ? (
        <View style={{flex: 1, overflow: 'hidden'}}>
          <HeaderText
            styles={styles}
            title={title || label}
            textAlign={textAlign}
            numberOfLines={numberOfLines}>
            {label}
          </HeaderText>
        </View>
      ) : (
        void 0
      )}
    </HeaderColumnRow>
  );
};

export const AggregateRender = ({
  styles,
  value,
  textAlign,
  format,
  formatOptions,
  numberOfLines = 1,
  suffix,
}) => {
  if (value && format) {
    value = format(value, formatOptions);
  }
  if (value && suffix) {
    value = `${value}${suffix}`;
  }
  return (
    <HeaderAggregateText
      styles={styles}
      title={value}
      textAlign={textAlign}
      numberOfLines={numberOfLines}>
      {value || 0}
    </HeaderAggregateText>
  );
};

export const Column = ({
  onPress,
  render = TextRender,
  fallbackRender,
  value,
  title,
  ...props
}) => {
  let {row, disabled, field, toggleRowExpand, expandable} = props;

  let renderComponent = null;

  if (value !== undefined || field) {
    if (value !== undefined) {
      value = typeof value === 'function' ? value(props) : value;
    } else if (field) {
      value = resolveExp(row, field);
    }
    if (!hasValue(value)) {
      if (fallbackRender) {
        renderComponent = fallbackRender(props);
      }
      if (!renderComponent) {
        return null;
      }
    }
  }
  if (typeof title === 'function') {
    title = title(props);
  }
  if (!renderComponent && render) {
    renderComponent = render({...props, value, title});
  }

  if (renderComponent && (onPress || expandable)) {
    if (typeof disabled === 'function') {
      disabled = disabled(props);
    }
    renderComponent = (
      <TouchableOpacity
        disabled={disabled}
        onPress={e => {
          if (expandable) {
            toggleRowExpand?.();
          } else {
            onPress?.(props);
          }
        }}>
        {renderComponent}
      </TouchableOpacity>
    );
  }
  return renderComponent;
};

export const ColumnAggregate = ({styles, column, ...props}) => {
  let {field, aggregate, ...restColumn} = column;
  if (!aggregate) {
    return null;
  }
  aggregate =
    aggregate === true
      ? {field}
      : typeof aggregate === 'string'
      ? {field: aggregate}
      : aggregate;
  let {
    field: aggregateField = field,
    value,
    render = AggregateRender,
    suffix,
  } = aggregate;
  if (typeof value === 'function') {
    value = value(props);
  } else {
    value =
      typeof aggregateField === 'string'
        ? resolveExp(props.aggregates, aggregateField)
        : void 0;
  }
  return render({styles, ...props, ...restColumn, suffix, value});
};
