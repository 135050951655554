import {Table} from '../../../components/table/Table';
import {UserAvatarWithDateChip} from '../../reimbursement/components/ReimbursementColumnRenders';
import {createJobOpening} from '../controllers/ManPowerRequistionController';

export const HrRecruitmentMrfApprovedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/manpowerrequisition/all/Approved`}
      eventSourceId="HrMrfApproved"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-mrf`, {
          mrf: row?._id,
        });
      }}
      columns={[
        {
          render: props => (
            <UserAvatarWithDateChip
              field={'requested_by'}
              dateField={'requested_on'}
              requested
              {...props}
            />
          ),
          header: 'Requestor',
          width: 200,
        },
        {
          field: 'department.name',
          header: 'Department',
          width: 150,
        },
        {
          field: 'position_type',
          header: 'Position Type',
          width: 150,
        },
        {
          field: 'position_title',
          header: 'Position',
        },
        {
          field: 'no_of_positions',
          header: 'Requested Position',
          type: 'number',
          align: 'center',
          width: 150,
        },
        {
          header: 'Hiring Managers',
          field: 'hiring_manager',
          type: 'userAvatarGroup',
          width: 150,
        },
        {
          header: 'Location',
          field: 'location',
        },
        {
          header: 'Priority',
          field: 'priority',
        },
        {
          header: 'Salary Budget',
          field: 'salary_offered',
          type: 'number',
          align: 'center',
          width: 150,
        },
      ]}
      moreActions={() => [createJobOpening({navigation, params})]}
    />
  );
};
