import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddTicketSubCategory} from './TicketSubCategoryForms';

export const TicketSubCategoryList = props => {
  const {
    route: {params},
  } = props;
  const {employee_team} = params;
  return (
    <Table
      renderHeader={() => <AddTicketSubCategory {...props} />}
      api="/ticketSubCategories"
      filter={{
        employee_team: employee_team?._id,
      }}
      eventSourceId={['TicketSubCategory']}
      fields={{
        name: 1,
        parent_category: {
          name: 1,
        },
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
          
        },
        {
          header: 'Parent Category',
          field: 'parent_category.name',
          colorField: 'parent_category.name',
          type: 'colorTag',
        },
      ]}
      moreActions={[{
        title: 'Edit',
        onPress: ({row}) => {
          props.navigation.navigate('edit-ticket-sub-category', {
            ticket_sub_category: row,
            employee_team
          });
        },
      }]}
      {...props}
    />
  );
};
