import { FlatList} from "@unthinkable/react-core-components";
import React from "react";
import { getGroupedMessages } from "./utils";
import { InputBox } from "./InputBox";

export const ChatList = (props) => {
  const { renderRow, data = [], timeField = "time", onSend, ...rest } = props;
  let groupedData = getGroupedMessages({ data, timeField });
  return (
    <>
      <FlatList
        {...props}
        inverted
        data={groupedData}
        renderItem={renderRow}
      />
      <InputBox {...rest} onPress={onSend} />
    </>
  );
};
