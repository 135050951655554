import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddFixedOrderAssignment = props => {
  const {route: {params} = {}} = props;
  const {order_allocations = {}, order_resource_request} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/createOrderAssignments',
    eventSourceId: ['orderresourcerequests'],
  });

  return (
    <Form
      header={`Assign Resource`}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        skill_required: order_allocations?.skill_required?._id,
        order: order_allocations.order?._id,
        product: order_allocations.product?._id,
        skill_requirement: order_allocations?.skill_requirement,
        from_date: order_allocations?.order_date,
        to_date: order_allocations?.recurring_till,
        hours: order_allocations?.employee_per_day_working_hour,
        project_skill: order_allocations?.skill_required?._id
          ? order_allocations?.skill_required
          : void 0,
        project_id: order_allocations?.project,
        status: 'Assigned',
        tool: order_allocations?.tool,
        resource_skill: order_allocations?.skill_required?._id
          ? order_allocations?.skill_required
          : void 0,
        resource_tool: order_allocations?.tool,
        order_resource_request,
        is_locomo: true,
        incentive_mode: 'Pay To Module Lead',
      }}
      layoutFields={[
        {
          label: 'Resource',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
          fields: {
            _id: 1,
            name: 1,
            official_email_id: 1,
          },
        },
        {
          fields: [
            {
              label: 'Start Date',
              placeholder: 'Start Date',
              field: 'from_date',
              type: 'date',
              required: true,
              size: 4,
            },
            {
              label: 'End Date',
              placeholder: 'End Date',
              field: 'to_date',
              type: 'date',
              required: true,
              size: 4,
            },
            {
              label: 'Hours',
              placeholder: 'Hours',
              field: 'hours',
              type: 'number',
              required: true,
              size: 4,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Project',
              placeholder: 'Project',
              field: 'project_id',
              type: 'autoComplete',
              api: `/projects`,
              suggestionField: 'project',
              valueField: 'project',
              required: true,
              size: 12,
            },
            {
              label: 'Project Skill',
              placeholder: 'Project Skill',
              field: 'project_skill',
              type: 'autoComplete',
              api: `/skillmetrics`,
              filter: {
                status: 'active',
              },
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Project Tool',
              placeholder: 'Project Tool',
              field: 'tool',
              type: 'autoComplete',
              api: ({values = {}}) => {
                const {project_skill} = values;
                if (!project_skill) return `/tools`;
                return `/skillMetrics/toolsSuggestion/${project_skill?._id}`;
              },
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Resource Skill',
              placeholder: 'Resource Skill',
              field: 'resource_skill',
              type: 'autoComplete',
              api: `/skillmetrics`,
              filter: {
                status: 'active',
              },
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Resource Tool',
              placeholder: 'Resource Tool',
              field: 'resource_tool',
              type: 'autoComplete',
              api: ({values = {}}) => {
                const {resource_skill} = values;
                if (!resource_skill) return `/tools`;
                return `/skillMetrics/toolsSuggestion/${resource_skill?._id}`;
              },
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              placeholder: 'Benefit Type',
              type: 'autoComplete',
              field: 'benefit_type',
              label: 'Benefit Type',
              valueField: 'label',
              keyField: 'value',
              suggestionField: 'label',
              options: [
                {value: 'training', label: 'Training-Project'},
                {value: 'shadow', label: 'Shadow'},
                {value: 'skill', label: 'Training-Cross-Skill'},
                {value: 'technology', label: 'Training-Cross-Technology'},
                {value: 'kt', label: 'KT'},
              ],
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value == 'shadow') {
                  setFieldValue('is_shadow', true);
                }
              },
              size: 6,
            },
            {
              label: 'Benefit Upto',
              placeholder: 'Benefit Upto',
              field: 'benefit_upto',
              type: 'date',
              visible: ({values}) => {
                return values && values.benefit_type == 'training';
              },
              size: 6,
            },
          ],
        },

        {
          fields: [
            {
              label: 'Level 1 Owner',
              placeholder: 'Level 1 Owner',
              field: 'level_one_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Level 2 Owner',
              placeholder: 'Level 2 Owner',
              field: 'level_two_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Level 3 Owner',
              placeholder: 'Level 3 Owner',
              field: 'level_three_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Is Included In Utilisation',
          field: 'is_included_in_utilisation',
          size: 6,
        },
        {
          label: 'Percentage Included In Utilisation',
          field: 'percIncludeInUtilisation',
          type: 'autoComplete',
          options: [
            {label: '100 %', value: 100},
            {label: '75 %', value: 75},
            {label: '50 %', value: 50},
          ],
          visible: ({values = {}}) => values?.is_included_in_utilisation,
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          required: true,
          size: 6,
        },
        {
          defaultHidden: true,
          label: 'Incentive Mode',
          fields: [
            {
              label: 'Incentive Mode',
              type: 'radioGroup',
              field: 'incentive_mode',
              options: [
                {
                  label: 'Pay To Module Lead',
                  value: 'Pay To Module Lead',
                },
                {
                  label: 'Sharing With Skill Manager',
                  value: 'Sharing With Skill Manager',
                },
              ],
              required: true,
            },
            {
              label: 'Incentive Shared With',
              placeholder: 'Incentive Shared With',
              field: 'incentive_shared_with',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              visible: ({values = {}}) =>
                values?.incentive_mode === 'Sharing With Skill Manager',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
