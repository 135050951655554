import { Row } from '@unthinkable/react-core-components';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { ObjectWiseTasks } from '../../common/actions/ObjectWiseTasks';
import {
    DropInterviewTable,
    LostInterviewTable,
    PlannedInterviewTable,
    UnPlannedInterviewTable,
    WonInterviewTable,
} from './InterviewTable';
import {
    ActiveStaffAugmentationOpportunityTable,
    DropStaffAugmentationOpportunityTable,
    LostStaffAugmentationOpportunityTable,
    PartiallyWonStaffAugmentationOpportunityTable,
    PotentialCustomerWonStaffAugmentationOpportunityTable,
    PotentialCustomerWonStaffAugmentationOpportunityTableApi,
    WonStaffAugmentationOpportunityTable,
} from './StaffAugmentationTable';

export const StaffAugmentationOppTabView = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, searchValue} = useFilter({});
  const {filter, params: filterParams = {}} = filterValues;
  let tabs = {};
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  tabs.active = {
    label: 'Active',
    api: '/staffAugmentationOpportunities/active',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <ActiveStaffAugmentationOpportunityTable
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };

  tabs.won = {
    label: 'Won',
    api: '/staffAugmentationOpportunities/won',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <WonStaffAugmentationOpportunityTable
        sort={{outcome_date: -1}}
        {...props}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };

  tabs.lost = {
    label: 'Lost',
    api: '/staffAugmentationOpportunities/lost',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <LostStaffAugmentationOpportunityTable
        sort={{outcome_date: -1}}
        {...props}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    params: params,
  };

  tabs.partially_won = {
    label: 'Partially Won',
    api: '/staffAugmentationOpportunities/partiallyWon',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    params: params,
    view: (
      <PartiallyWonStaffAugmentationOpportunityTable
        sort={{outcome_date: -1}}
        {...props}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };

  tabs.drop = {
    label: 'Drop',
    api: '/staffAugmentationOpportunities/drop',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    params: params,
    view: (
      <DropStaffAugmentationOpportunityTable
        sort={{outcome_date: -1}}
        {...props}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };

  tabs.unplanned = {
    label: 'Unplanned',
    view: (
      <UnPlannedInterviewTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    eventSourceId: [
      'CustomPlatformOpportunity',
      'StaffAugmentation',
      'communicationAdded',
    ],
    api: `/unplannedinterviewresourcesWithEntity`,
    params: params,
    addOnFilter: filter,
  };

  tabs.planned = {
    label: 'Planned',
    view: (
      <PlannedInterviewTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: `/plannedinterviewresourcesWithEntity`,
    params: params,
    addOnFilter: filter,
    eventSourceId: [
      'CustomPlatformOpportunity',
      'StaffAugmentation',
      'communicationAdded',
    ],
  };

  tabs.interview_won = {
    label: 'Interview Won',
    params: params,
    view: <WonInterviewTable filter={filter} params={params} />,
    api: `/woninterviewresourcesWithEntity`,
    params: params,
    addOnFilter: filter,
    eventSourceId: [
      'CustomPlatformOpportunity',
      'StaffAugmentation',
      'communicationAdded',
    ],
  };

  tabs.interview_lost = {
    label: 'Interview Lost',
    params: params,
    view: <LostInterviewTable filter={filter} params={params} />,
    api: `/lostinterviewresourcesWithEntity`,
    params: params,
    addOnFilter: filter,
    eventSourceId: [
      'CustomPlatformOpportunity',
      'StaffAugmentation',
      'communicationAdded',
    ],
  };
  tabs.inerview_drop = {
    label: 'Interview Drop',
    params: params,
    view: <DropInterviewTable filter={filter} params={params} />,
    api: `/dropInterviewResourcesWithEntity`,
    params: params,
    addOnFilter: filter,
    eventSourceId: [
      'CustomPlatformOpportunity',
      'StaffAugmentation',
      'communicationAdded',
    ],
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Customer',
                  value: 'Customer',
                },
                {
                  label: 'Prospect',
                  value: 'PotentailCustomer',
                },
              ],
              placeholder: 'Select',
              field: 'rfr_type',
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Mode',
              options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
              placeholder: 'Select',
              field: 'process',
            },
            {
              type: 'autoComplete',
              label: 'Engagement Type',
              options: [
                'Trial Engagement',
                'Billable Engagement',
                'Replacement',
              ],
              placeholder: 'Select',
              field: 'rfr_engagement_type',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task date',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },
            {
              type: 'date',
              field: 'creation_date',
              asParam: true,
              label: 'Creation date',
            },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <ObjectWiseTasks
            {...props}
            params={params}
            object={{
              _id: '64e06ebb94f415bdfd0a4aed',
            }}
          />

          <AddButton
            title="Staff Augmentation"
            view="add-staffaugmentationopportunity"
            params={props?.route?.params}
          />
        </Row>,
      ]}
    />
  );
};

export const InterviewStaffAugOppTabView = props => {
  const {route: {params} = {}} = props;
  let tabs = {};

  tabs.won = {
    label: 'Won',
    ...PotentialCustomerWonStaffAugmentationOpportunityTableApi,
    view: (
      <PotentialCustomerWonStaffAugmentationOpportunityTable
        sort={{outcome_date: -1}}
        {...props}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
