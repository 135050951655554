import React, {useEffect} from 'react';
import pluralize from 'pluralize';
import {MultiDataTable} from './MultiDataTable';
import {useAIActions} from '../../modules/pmt/actions/AIActions';
import {useFetchData} from '../../controllers/useFetchData';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {AIButton} from '../button/Button';
import {useNavigation} from '@react-navigation/native';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {BorderLessButton} from '../button/AddButton';
import {AIIconButtonStyle, AIIconRejectButtonStyle} from './theme';
import {useEventHandler} from '@unthinkable/react-event-handler';
import PulseLoader from '../loader/PulseLoader';

export const AIIconButton = ({variant, ...props}) => {
  const styles = useStyles(AIIconButtonStyle);
  const errorStyles = useStyles(AIIconRejectButtonStyle);
  return (
    <View style={{flex: 1, padding: 8}}>
      <BorderLessButton
        {...props}
        styles={variant === 'error' ? errorStyles : styles}
      />
    </View>
  );
};

export const AITableButton = ({
  buttonText = 'Ask AI',
  loadingAction,
  ...props
}) => {
  const {AISuggestNew} = useTheme('icons');
  const shadows = useTheme('shadows');
  return (
    <View
      style={{
        height: 300,
        width: 300,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {loadingAction ? (
        void 0
      ) : (
        <View
          style={{
            ...shadows.L,
            borderRadius: 6,
          }}>
          <AIButton {...props} icon={AISuggestNew} text={buttonText} />
        </View>
      )}
    </View>
  );
};

export const AILoading = () => {
  const {icons, colors, fonts} = useTheme();

  return (
    <View
      style={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: 'rgba(255,255,255,0.6)',
        backdropFilter: 'blur(2px)',
      }}>
      <PulseLoader>
        <Image source={icons?.AILoader} />
      </PulseLoader>
      <Text
        style={{
          paddingTop: 30,
          ...fonts.BODY2,
          color: colors.BRAND_UPPER_MEDIUM,
        }}>
        Analyzing & generating...
      </Text>
    </View>
  );
};

const AITableHeader = ({
  suggestionCountData,
  entityCountData,
  loadingAction,
  onNewSuggestions,
  onImproveSuggestions,
  onAcceptAll,
  onRejectAll,
  newPromptName,
  improvePromptName,
  morePromptName,
  improveMorePromptName,
  params,
}) => {
  const navigation = useNavigation();
  const {icons} = useTheme();
  const isMoreSuggestion = entityCountData?.count > 0;

  let actions = [];
  if (suggestionCountData?.count) {
    const {entity_category_prompts} = params;
    let options = [];

    if (entity_category_prompts?.length) {
      options = entity_category_prompts?.map(prompt => {
        return {
          label: prompt?.label || prompt.category,
          value: {...prompt, prompt_name: 'ImproveDetailSuggestion'},
        };
      });
      options.unshift({
        label: params.label || 'All',
        value: {},
      });
    }
    actions.push(
      <AIIconButton
        icon={icons.AIAcceptAll}
        text="Accept All"
        disabled={loadingAction}
        onPress={onAcceptAll}
      />,
    );
    actions.push(
      <AIIconButton
        icon={icons.AISuggestNew}
        text="Instruct AI further"
        title={
          isMoreSuggestion
            ? 'Chat with AI to improve more suggestions'
            : 'Chat with AI to improve suggestions'
        }
        disabled={loadingAction}
        onPress={() => {
          navigation.navigate('ai-suggestion-form', {
            options,
            onSubmit: onImproveSuggestions({
              prompt_name: isMoreSuggestion
                ? improveMorePromptName
                : improvePromptName,
              is_more_suggestion: isMoreSuggestion ? true : void 0,
            }),
          });
        }}
      />,
    );
    actions.push(
      <AIIconButton
        icon={icons.AIRefresh}
        text={'Regenerate'}
        disabled={loadingAction}
        onPress={onNewSuggestions({
          prompt_name: isMoreSuggestion ? morePromptName : newPromptName,
          is_more_suggestion: isMoreSuggestion ? true : void 0,
        })}
      />,
    );

    actions.push(
      <AIIconButton
        icon={icons.AIRejectAll}
        text="Dismiss All"
        variant={'error'}
        disabled={loadingAction}
        onPress={onRejectAll}
      />,
    );
  }
  return (
    <RowWithSeparator
      style={{alignItems: 'center'}}
      separator={<LineSeparator />}>
      {actions}
    </RowWithSeparator>
  );
};

export const AITable = ({
  moreActions: propMoreActions,
  aiEnabled,
  aiProps,
  ...props
}) => {
  const {
    entityName,
    params,
    uri,
    filter,
    suggestionUri,
    eventSourceId = 'ProjectAI',
    newPromptName = 'EntityNewSuggestions',
    improvePromptName = 'EntityImproveSuggestions',
    morePromptName = 'EntityMoreSuggestions',
    improveMorePromptName = 'EntityImproveMoreSuggestions',
    buttonText,
  } = aiProps || {};
  const pluralEntityName = entityName ? pluralize.plural(entityName) : void 0;
  let {data: suggestionCountData, loading: suggestionDataLoading} =
    useFetchData({
      api:
        suggestionUri || pluralEntityName ? `/${pluralEntityName}/ai` : void 0,
      count: true,
      params,
      eventSourceId,
    });

  const {data: entityCountData, loading: entityDataLoading} = useFetchData({
    api: uri || pluralEntityName ? `/${pluralEntityName}/ai` : void 0,
    count: true,
    filter,
    params,
    eventSourceId,
  });

  const {listen, unlisten} = useEventHandler();

  let {
    loadingAction,
    onNewSuggestions,
    onMoreSuggestions,
    onImproveSuggestions,
    onAcceptAll,
    onRejectAll,
    setLoadingAction,
  } = useAIActions({
    ...aiProps,
    pluralEntityName,
  });

  const onMoreSuggestionCallback = ({params: {loadingAction: state}}) => {
    setLoadingAction(state);
  };

  useEffect(() => {
    listen('onMoreSuggestion', onMoreSuggestionCallback);

    return () => {
      unlisten('onMoreSuggestion', onMoreSuggestionCallback);
    };
  }, [listen]);
  // if (suggestionDataLoading !== false && entityDataLoading !== false) {
  //   return null;
  // }

  let moreActions = propMoreActions;
  if (moreActions) {
    moreActions = actionProps => {
      const {row} = actionProps;
      if (row?.aiGenerated) {
        return [];
      } else if (typeof propMoreActions === 'function') {
        return propMoreActions(actionProps);
      } else {
        return propMoreActions;
      }
    };
  }

  const renderNoData = () => {
    return (
      <AITableButton
        {...props}
        buttonText={buttonText}
        loadingAction={loadingAction}
        onPress={onNewSuggestions({
          prompt_name: newPromptName,
        })}
      />
    );
  };

  const renderHeader = () => {
    return (
      <AITableHeader
        suggestionCountData={suggestionCountData}
        entityCountData={entityCountData}
        loadingAction={loadingAction}
        onNewSuggestions={onNewSuggestions}
        onMoreSuggestions={onMoreSuggestions}
        onImproveSuggestions={onImproveSuggestions}
        onAcceptAll={onAcceptAll}
        onRejectAll={onRejectAll}
        newPromptName={newPromptName}
        improvePromptName={improvePromptName}
        morePromptName={morePromptName}
        improveMorePromptName={improveMorePromptName}
        params={params}
      />
    );
  };
  let loading =
    loadingAction &&
    loadingAction !== 'RejectAllSuggestions' &&
    loadingAction !== 'AcceptAllSuggestions';
  return (
    <>
      <MultiDataTable
        {...props}
        moreActions={moreActions}
        {...(aiEnabled ? {renderNoData} : {})}
        {...(aiEnabled && suggestionCountData?.count ? {renderHeader} : {})}
      />
      {aiEnabled && loading ? <AILoading /> : void 0}
    </>
  );
};
