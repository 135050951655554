import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {leadControllers} from '../controllers/leadControllers';

const contactCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Contacts',
  align: 'right',
  count_field: 'segment_contact_count',
  responsive: 'sm',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`user-segment-contact-list`, {
      user_segment_id: row?._id,
    });
  },
});

const prospectCount = ({params, navigation}) => ({
  render: props => {
    const {row} = props;

    const count_field = row?.api
      ? 'segment_contact_count'
      : 'potential_customer_count';

    return <CountCellRender {...props} count_field={count_field} />;
  },
  width: 100,
  header: 'Prospects',
  align: 'right',
  responsive: 'sm',
  eventSourceId: 'loadData',
  onPress: props => {
    const {row} = props;
    row?.api
      ? navigation.navigate(`user-segment-active-prospect-list`, {
          user_segment_id: row?._id,
          api: row?.api,
        })
      : navigation.navigate(`user-segment-prospect-list`, {
          user_segment_id: row?._id,
        });
  },
});

export const UserSegmentList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  const {loadDataForActive} = leadControllers(props);

  return (
    <Table
      api={`/usersegments`}
      eventSourceId={[
        'segmentAdded',
        'importedUserSegment',
        'reloadImportHistoryLogs',
        'removeProspect',
        'loadData',
      ]}
      fields={{
        name: 1,
        segment_contact_count: {_id: 1},
        segment_campaign: {name: 1},
        potential_customer_count: {_id: 1},
        api: 1,
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
        },
        // {
        //   header: 'Campaigns',
        //   type: 'textArea',
        //   field: 'segment_campaign.name',
        // },
        // {
        //   header: 'Potential Customer Count',
        //   type: 'text',
        //   field: 'potential_customer_count',
        // },
        prospectCount({params, navigation}),
        // contactCount({params, navigation}),
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-segment', {row: {_id: row._id}});
          },
        },
        {
          title: 'Sync',
          onPress: ({row}) => {
            navigation.navigate('define-filter', {row: {_id: row._id}});
          },
        },
        {
          title: 'Load Data',
          onPress: ({row}) => loadDataForActive({row}),
          eventSourceId: 'loadData',
          visible: props => {
            const {row} = props;
            return row?.api;
          },
        },
      ]}
    />
  );
};
