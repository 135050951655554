import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

const NavBack = ({styles, icon, onPress}) => {
  return (
    <TouchableOpacity style={styles.backIconContainerStyle} onPress={onPress}>
      <Image source={icon} style={styles.backIconStyle} />
    </TouchableOpacity>
  );
};

const NavTitle = ({titleStyle, title}) => {
  if (!title) {
    return null;
  }
  return (
    <Text style={titleStyle} numberOfLines={1}>
      {title}
    </Text>
  );
};

const Logo = ({styles, icon, onPress}) => {
  if (!icon) {
    return null;
  }
  return (
    <TouchableOpacity onPress={onPress}>
      <Image source={icon} style={styles.logoStyle} />
    </TouchableOpacity>
  );
};

export const NavHeader = ({
  styles,
  actions,
  back,
  onBack,
  skipLogo,
  onLogoPress,
  title,
  secondaryTitle,
  children,
}) => {
  if (!children) {
    children = (
      <>
        {title ? <NavTitle titleStyle={styles?.title} title={title} /> : void 0}
        {secondaryTitle ? (
          <NavTitle
            titleStyle={styles?.secondaryTitle}
            title={secondaryTitle}
          />
        ) : (
          void 0
        )}
      </>
    );
  }

  const backIcon = styles?.back;
  const logoIcon = styles.brandIcon || styles.logo;

  return (
    <Row
      style={{
        gap: 12,
        alignItems: 'center',
        overflow: 'hidden',
        ...styles?.container,
      }}>
      {back && backIcon ? (
        <NavBack icon={backIcon} styles={styles} onPress={onBack} />
      ) : !skipLogo && logoIcon ? (
        <Logo icon={logoIcon} styles={styles} onPress={onLogoPress} />
      ) : (
        void 0
      )}
      <View style={{flex: 1, overflow: 'hidden'}}>{children}</View>
      <Row style={{gap: 12, ...styles.actionContainer}}>{actions}</Row>
    </Row>
  );
};
