import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {CommunicationComponentStyles} from './communicationTheme';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {
  UserAvatarChip,
  UserAvatarGroup,
} from '../../../components/avatar/UserAvatar';
import {Avatar} from '../../../components/avatar/Avatar';
import {AvatarGroup} from '@unthinkable/react-avatar';

export const ClientAttendeesComponent = ({value}) => {
  if (!value) {
    return null;
  }

  const {
    seperator,
    clientAttendeeName,
    clientAttendeeDesignation,
    clientStyle,
    communicationDate,
    initiatorStyle,
    headersStyle,
    Infoseperator,
    clientAttendeeArrayStyle,
  } = useStyles(CommunicationComponentStyles);
  if (
    value?.communication_mode == 'Meeting' ||
    value?.communication_mode == 'Demo'
  ) {
    console.log('came here');
    if (!value.initiator)
      return (
        <Row gap={8} style={{padding: '0px 12px', alignItems: 'center'}}>
          <>
            <Text style={headersStyle}>Initiator</Text>
            <Text style={initiatorStyle}> </Text>
            <View style={Infoseperator}></View>
            <Text style={headersStyle}>Our Attendees</Text>

            <UserAvatarChip value={value?.owner} size={22} />
            <View style={Infoseperator}></View>
            <Text style={headersStyle}>Client Attendees</Text>

            <View style={clientStyle}>
              <Text style={clientAttendeeName}>
                {value?.contact_id?.first_name ||
                  value?.potential_customer?.name}
              </Text>
              {value?.contact_id?.designation ? (
                <>
                  <View style={seperator}></View>
                  <Text style={clientAttendeeDesignation}>
                    {value?.contact_id?.designation}
                  </Text>
                </>
              ) : (
                void 0
              )}
            </View>
          </>
        </Row>
      );
    else
      return (
        <>
          <Row gap={6} style={{padding: '0px 12px', alignItems: 'center'}}>
            <>
              <Text style={headersStyle}>Initiator</Text>
              <Text style={initiatorStyle}>{value?.initiator}</Text>
              <View style={Infoseperator}></View>
              <Text style={headersStyle}>Our Attendees</Text>

              <UserAvatarGroup value={value?.our_attendes} size={24} />
              <View style={Infoseperator}></View>
              <Text style={headersStyle}>Client Attendees</Text>

              <View style={clientAttendeeArrayStyle}>
                <AvatarGroup>
                  {value?.client_attendees?.map(_contactId => {
                    return <Avatar size={24} value={_contactId?.first_name} />;
                  })}
                </AvatarGroup>
              </View>
            </>
          </Row>
        </>
      );
  } else {
    const ClientComponent = () => {
      return (
        <View style={clientStyle}>
          <Text style={clientAttendeeName}>
            {value?.contact_id?.first_name ||
              value?.contact_id?.name ||
              value?.potential_customer?.name}
          </Text>
          {value?.contact_id?.designation ? (
            <>
              <View style={seperator}></View>
              <Text style={clientAttendeeDesignation}>
                {value?.contact_id?.designation}
              </Text>
            </>
          ) : (
            void 0
          )}
        </View>
      );
    };

    const OwnerComponent = () => {
      return <UserAvatarChip value={value?.owner} size={22} />;
    };
    return (
      <>
        <Row gap={6} style={{padding: '0px 12px', alignItems: 'center'}}>
          <Text style={headersStyle}>From</Text>
          {value?.initiator === 'Client' ? (
            <ClientComponent />
          ) : (
            <OwnerComponent />
          )}
          <Text style={headersStyle}>To</Text>
          {value?.initiator === 'Client' ? (
            <OwnerComponent />
          ) : (
            <ClientComponent />
          )}
        </Row>
      </>
    );
  }
};
// return (

//   <>

//   {/* <Row gap={8} style={{padding: '0px 12px', alignItems: 'center'}}>
//     {value?.initiator ? (
//       <>
//         <Text style={headersStyle}>Initiator</Text>
//         <Text style={initiatorStyle}>{value?.initiator}</Text>
//         <View style={Infoseperator}></View>
//         <Text style={headersStyle}>Our Attendees</Text>

//         <UserAvatarGroup value={value?.our_attendes} />
//         <View style={Infoseperator}></View>
//         <Text style={headersStyle}>Client Attendees</Text>

//         <View style={clientAttendeeArrayStyle}>
//           <AvatarGroup>
//             {value?.client_attendees?.map(_contactId => {
//               return <Avatar value={_contactId?.first_name} />;
//             })}
//           </AvatarGroup>
//         </View>
//       </>
//     ) : (
//       <>
//         <Text style={headersStyle}>From</Text>
//         <UserAvatarChip value={value?.owner} />
//         <View style={headersStyle}></View>
//         <Text style={headersStyle}>To</Text>
//         <View style={clientStyle}>
//           <Text style={clientAttendeeName}>
//             {value?.contact_id?.first_name || value?.potential_customer?.name}
//           </Text>
//           {value?.contact_id?.designation ? (
//             <>
//               <View style={seperator}></View>
//               <Text style={clientAttendeeDesignation}>
//                 {value?.contact_id?.designation}
//               </Text>
//             </>
//           ) : (
//             void 0
//           )}
//         </View>
//       </>
//     )}
//   </Row> */}

//   </>

// );
