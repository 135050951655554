import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const DripInmailForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Drip Inmail',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/dripInmailContents',
    eventSourceId: ['dripInmailAdded'],
  });
  const {department, product, marketing_theme, row} = params;
  return (
    <Form
      api={`/dripInmailContents/${row?._id}`}
      fields={{
        inmail_subject: 1,
        inmail_body: 1,
        drip_sequence: 1,
        send_after: 1,
        when: 1,
        value_proposition: 1,
        tags: 1,
        type: 1,
        name: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        created_on: getZeroTimeDate(new Date()),
        department: department?._id,
        product: product?._id,
        status: 'active',
        marketing_theme,
        target_audience: marketing_theme?.target_audience,
        ...defaultValue,
      }}
      layoutFields={[
        // {
        //   field: 'type',
        //   type: 'autoComplete',
        //   label: 'Type',
        //   placeholder: 'Type',
        //   options: ['Message Series', 'Connection Message'],
        // },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        // {
        //   label: 'Subject',
        //   placeholder: 'Subject',
        //   field: 'inmail_subject',
        //   type: 'text',
        // },
        {
          label: 'Body',
          field: 'inmail_body',
          type: 'richText',
        },
      ]}
      {...props}
    />
  );
};

export const EditDripInmailForm = props => {
  return <DripInmailForm mode="edit" header={'Edit Drip Inmail'} {...props} />;
};

export const DetailDripInmailForm = props => {
  return (
    <DripInmailForm
      mode="edit"
      readOnly={true}
      header={'Detail Drip Inmail'}
      {...props}
    />
  );
};
