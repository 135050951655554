import React from 'react';
import {TabView} from '../../../components/tab';
import {MySkillsTable} from './MySkillsTable';
import {AddButton} from '../../../components/button/AddButton';

export const MySkillsTabView = props => {
  const {
    route: {params = {}},
  } = props;
  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        activeSkills: {
          label: 'Active',
          eventSourceId: 'MySkill',
          view: <MySkillsTable status={'active'} />,
        },
        archiveSkills: {
          label: 'Archived',
          eventSourceId: 'MySkill',
          view: <MySkillsTable status={'archive'} />,
        },
      }}
      actions={[
        <AddButton title="Skills" view="add-my-skills" params={{...props}} />,
      ]}
    />
  );
};
