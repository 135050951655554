import React from 'react';
import moment from 'moment';
import {useNavigation} from '@react-navigation/native';
import {useComponentTheme, useStyles, useTheme} from '@unthinkable/react-theme';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {
  getCurrentMonth,
  getPreviousMonth,
} from '@unthinkable/react-filters/src/DateUtility';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

import {TextRenderer} from '../../../components/form/Renderers';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupTable, Table} from '../../../components/table/Table';
import {
  PROJECT_MILESTONE_TYPE_ID,
  PROJECT_MODULE_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
  PROJECT_TYPE_ID,
  navigateToListView,
} from '../../common/constants/SourceConstants';
import StatusIconRender from '../../pmt/cellrenders/StatusIconRender';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {
  AutoCompleteFilter,
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import Images from '../../../images';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import DynamicFKRender from '../../pmt/cellrenders/DynamicFKRender';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {TaskWithActionRenderer} from '../../pmt/cellrenders/TaskWithActionRenderer';
import {useFetchData} from '../../../controllers/useFetchData';
import {
  PerformanceRenderStyle,
  ReviewedTaskRenderStyle,
} from '../../pmt/cellrenders/theme';
import {
  AssignUserAvatar,
  MarkCompletedRender,
} from '../../../components/table/Renders';
import {EstRender} from '../../pmt/cellrenders/EstRender';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {ActionText} from '../../../components/action/ActionText';
import {Chip, Tag} from '../../../components/chip/Chip';
import {TaskTypeRender} from '../../pmt/cellrenders/TaskTypeRender';
import {IconTextLabel} from '../../../app-components/IconTextLabel';
import {AddAction, AddFilledAction} from '../../../components/action/AddAction';
import {
  AdhocTaskInlineForm,
  OrganizationTaskInlineForm,
  StrategicTaskInlineForm,
} from './TaskForm';
import {taskFilters, useTaskActions} from './TaskActions';
import {getGroupedTasks} from '../CommonFunctions';
import {useInvoke} from '../../../controllers/useInvoke';
import {taskWithSource} from './FeatureTasksTable';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import { DateRenderer } from '@unthinkable/react-date-picker';
import { fonts } from '../../../theme/theme';

const {Owner, EstHours} = Images;

export const ReviewComponent = props => {
  const {row, onReviewPress, readOnly, hideReview, reviewRejected} = props;
  const rating = Math.round((row?.recent_review?.review_avg || 0) * 2) / 2;

  const reviewedBy = row?.recent_review?.reviewed_by?.name;

  const {colors} = useTheme();

  const {
    columnStyle,
    textStyle,
    reviewTextStyle,
    noRatingTextBackgroundColor,
    noRatingTextColor,
  } = useStyles(PerformanceRenderStyle);

  if (hideReview && hideReview({row})) {
    return;
  }
  let renderComponent = readOnly ? (
    <Col gap={4} style={columnStyle}>
      {row.review_pending || !row.reviewer || row.reviewer.length == 0 ? (
        <Tag
          value={'No Rating'}
          color={noRatingTextBackgroundColor}
          textColor={noRatingTextColor}
        />
      ) : row.status === 'completed' || reviewRejected ? (
        <RatingRenderer value={rating} />
      ) : (
        <Tag
          value={'Rejected'}
          textColor={colors.ERROR_HIGH}
          color={colors.ERROR_LOW}
        />
      )}
      {!row.review_pending ? (
        <Text numberOfLines={1} style={[textStyle, reviewTextStyle]}>
          {reviewedBy}
        </Text>
      ) : null}
    </Col>
  ) : (
    <ActionText text={'Review'} />
  );

  if (onReviewPress) {
    renderComponent = (
      <TouchableOpacity
        onPress={() => {
          onReviewPress({row, readOnly});
        }}>
        {renderComponent}
      </TouchableOpacity>
    );
  }

  return renderComponent;
};

const updateEstHrs = ({navigation}) => ({
  render: EstRender,
  align: 'center',
  width: 70,
  header: 'Est.',
  columnField: 'est_hours',
  onPress: ({row}) => {
    navigation.navigate('update-est-hours', {
      row,
    });
  },
  onAdd: props => {
    const {row} = props;
    navigation.navigate('update-est-hours', {
      row,
    });
  },
});

const source = ({project, navigation}) => ({
  render: DynamicFKRender,
  width: 300,
  sourceField: 'parent_model',
  sourceIdField: 'parent_model_id',
  navigation,
  params: {project, project_id: project?._id},
  onPress: ({row}) => {
    const {
      feature_id,
      parent_model,
      project_id,
      module_id,
      master_task_assignment,
    } = row;
    if (feature_id?._id) {
      navigation.navigate(`feature-detail-screen`, {
        project: project_id,
        project_id: project_id?._id,
        module: module_id,
        module_id: module_id?._id,
        feature: feature_id,
        feature_id: feature_id?._id,
        featureType: 'feature',
        clickedSource: parent_model?._id,
        masterTaskId:
          master_task_assignment?.master_task?._id ||
          master_task_assignment?.master_task,
      });
    }
  },
});

const dueDate = ({navigation}) => ({
  align: 'center',
  width: 80,
  header: 'Due Date',
  field: 'due_date',
  type: 'date',
  onPress: ({row}) => {
    row.status === 'completed'
      ? void 0
      : navigation.navigate('due-date-form', {
          row,
          api: '/projecttasks',
        });
  },
  fallbackRender: ({row}) => {
    if (row.status === 'completed') {
      return null;
    }
    return <AddFilledAction />;
  },
  visible: props => {
    const {filter} = props;
    return filter?.status === 'active';
  },
});

const assignedTo = {
  type: 'userAvatar',
  header: {icon: Owner},
  align: 'center',
  field: 'assigned_to',
  width: 60,
};

const userAvatarChip = {
  type: 'userAvatarChip',
  field: 'assigned_to',
  header: 'Owner',
};

export const TaskAssignUserAvatar = ({params, row, navigation, onLock}) => {
  const value = row['assigned_to'];
  return (
    <AssignUserAvatar
      value={value}
      lockable={true}
      onLock={() => {
        if (onLock) {
          onLock({row});
        } else {
          navigation.navigate('assign-est-hours', {...params, row});
        }
      }}
    />
  );
};

export const RenderMarkActive = props => {
  const {
    styles: {rowText},
    row,
    displayText = () => {},
  } = props;
  const colors = useTheme('colors');
  if (row?.aiGenerated) {
    return (
      <Row gap={8}>
        <TextRenderer
          value="Accept"
          style={{...rowText, color: colors['INFORMATION_HIGH']}}
        />
        <TextRenderer
          value="Reject"
          style={{...rowText, color: colors['ERROR_HIGH']}}
        />
      </Row>
    );
  }
  return (
    <TextRenderer
      value={displayText({row}) || 'Mark Active'}
      style={{...rowText, color: colors['INFORMATION_HIGH']}}
    />
  );
};

export const estHrs = ({navigation}) => {
  const onPress = ({row}) => {
    navigation.navigate('update-est-hours', {
      row,
    });
  };
  return {
    render: EstRender,
    colVisible: ({row}) => {
      return !row?.sub_task_count || !!row?.est_hours;
    },
    width: 70,
    header: 'Est.',
    columnField: 'est_hours',
    align: 'right',
    onPress: onPress,
    onAdd: onPress,
  };
};

export const TaskDynamicRender = props => {
  const {row, navigation, parentSourceView, isSubTaskTable, threadNameNotVisible, threadGoalNameNotVisible,
  } = props;
  const {
    recent_reviewed_status,
    name,
    feature_id,
    source,
    project_id,
    module_id,
    master_task_assignment,
    disqualified,
    disqualify_reason,
    is_ad_hoc,
    is_strategic,
    recurring_task_id,
    parent_task,
    thread_goal,
    thread,
  } = row;
  const {fonts, colors, icons} = useTheme();

  const {textStyle, lowTextStyle} = useStyles(ReviewedTaskRenderStyle);
  const isReOpen =
    recent_reviewed_status === 'rejected' && row.status !== 'archived';
  return (
    <Col gap={4}>
      <Text title={name} numberOfLines={2} style={textStyle}>
        {name}
      </Text>
      {!isSubTaskTable && parent_task ? (
        <Tag
          value={`Parent: ${parent_task?.name}`}
          color={colors.ACCENT2_LOW}
          textColor={colors.ACCENT2_HIGH}
        />
      ) : null}
      <RowWithSeparator
        gap={6}
        style={{alignItems: 'center', flexWrap: 'wrap'}}>
        {source ? (
          <DynamicFKRender
            {...props}
            maxWidth={150}
            onPress={() => {
              if (feature_id) {
                navigation?.navigate(`feature-detail-screen`, {
                  project: project_id,
                  project_id: project_id?._id,
                  module: module_id,
                  module_id: module_id?._id,
                  feature: feature_id,
                  feature_id: feature_id?._id,
                  featureType: 'feature',
                  clickedSource: source?._id,
                  masterTaskId:
                    master_task_assignment?.master_task?._id ||
                    master_task_assignment?.master_task,
                });
              } else {
                navigateToListView(props);
              }
            }}
          />
        ) : (
          void 0
        )}

        {is_strategic && parentSourceView !== 'StrategicTask' ? (
          <Text style={{...lowTextStyle, color: colors.NEUTRAL_MEDIUM}}>
            {'Strategic'}
          </Text>
        ) : (
          void 0
        )}
        {recurring_task_id?.frequency_mode ? (
          <Text style={{...lowTextStyle, color: colors.NEUTRAL_MEDIUM}}>
            {recurring_task_id?.frequency_mode}
          </Text>
        ) : (
          void 0
        )}
        {thread_goal && !threadGoalNameNotVisible? (
          <Text style={{...lowTextStyle, color: colors.NEUTRAL_MEDIUM}}>
            {`${thread_goal?.goal || ''}`}
          </Text>
        ) : (
          void 0
        )}
        {thread && !threadNameNotVisible? (
          <Text style={{...lowTextStyle, color: colors.NEUTRAL_MEDIUM}}>
            {`${thread?.name || ''}`}
          </Text>
        ) : (
          void 0
        )}
        {disqualified ? (
          <IconTextLabel
            title={disqualify_reason}
            value={'Disqualified'}
            iconStyle={{height: 12, width: 12}}
            textStyle={{
              paddingTop: 2,
              ...fonts.CAPTION_LARGE,
              color: colors.ERROR_HIGH,
            }}
            icon={icons.DisqualifiedRed}
          />
        ) : isReOpen ? (
          <IconTextLabel
            value={'Re-open'}
            icon={icons.Reopen}
            iconStyle={{height: 12, width: 12}}
            textStyle={{
              ...fonts.CAPTION_LARGE,
              color: colors.WARNING_HIGH,
            }}
          />
        ) : (
          void 0
        )}
      </RowWithSeparator>
    </Col>
  );
};

export const taskCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_TASK_TYPE_ID, label: 'Task'},
      comment_source_id: {_id: row._id, name: row.name},
    }),
  });
};

const ChangeSubTaskStatusRender = ({
  row,
  navigation,
  params,
  subtaskMyView,
}) => {
  const {user} = useAppStateContext();

  let isAction = true;
  if (subtaskMyView) {
    isAction = user?._id === row?.assigned_to?._id ? true : false;
  }

  const {BacklogIcon, GreyTickIcon, GreenTickIcon, ArchiveIcon} =
    useTheme('icons');
  const invoke = useInvoke({method: 'put', eventSourceId: 'Task'});

  if (!row?.parent_task) {
    return;
  }
  let statusChangeAction;

  if (row.status === 'backlog') {
    statusChangeAction = (
      <TouchableOpacity
        title={isAction ? 'Mark as Active' : 'Backlog'}
        onPress={() => {
          isAction &&
            invoke({
              uri: `/projecttasks/${row?._id}`,
              props: {
                status: 'active',
              },
            });
        }}>
        <Image style={{height: 24, width: 24}} source={BacklogIcon} />
      </TouchableOpacity>
    );
  }
  if (row.status === 'active') {
    statusChangeAction = (
      <TouchableOpacity
        title={isAction ? 'Mark Complete' : 'Active'}
        onPress={() => {
          isAction &&
            navigation.navigate('mark-task-complete', {
              ...params,
              row,
            });
        }}>
        <Image style={{height: 24, width: 24}} source={GreyTickIcon} />
      </TouchableOpacity>
    );
  }

  if (row.status === 'completed') {
    statusChangeAction = (
      <Image
        title={'Completed'}
        style={{height: 24, width: 24}}
        source={GreenTickIcon}
      />
    );
  }
  if (row.status === 'archived') {
    statusChangeAction = (
      <Image
        title={'Archived'}
        style={{height: 24, width: 24}}
        source={ArchiveIcon}
      />
    );
  }
  return statusChangeAction;
};

export const SubtasksCountColumnRender = ({row}) => {
  const {SubTaskBlue, SubTaskGreen} = useTheme('icons');
  const {
    sub_task_count = 0,
    completed_sub_task_count = 0,
    est_hours,
    parent_task,
  } = row;
  const isAllCompleted =
    sub_task_count && sub_task_count === completed_sub_task_count;
  if (parent_task || (est_hours && !sub_task_count)) {
    return;
  }
  return (
    <Chip
      gap={4}
      prefix={
        <Image
          style={{height: 16, width: 16}}
          source={isAllCompleted ? SubTaskGreen : SubTaskBlue}
        />
      }
      color={isAllCompleted ? 'SUCCESS_LOW' : 'INFORMATION_LOW'}
      textColor={isAllCompleted ? 'SUCCESS_HIGH' : 'INFORMATION_HIGH'}
      value={
        sub_task_count ? `${completed_sub_task_count}/${sub_task_count}` : '0'
      }
    />
  );
};

const DueDateRender = ({row}) => {
  const {due_date, update_due_date_reason} = row;
  const {colors, fonts} = useTheme();
  let {alertCircleGray} = useComponentTheme('PlusColumnRenderStyles');
  return (
    <Row gap={4}>
      {due_date ? (
        <>
          {update_due_date_reason ? (
            <View
              title={`Reason: ${update_due_date_reason}`}
              style={{justifyContent: 'center'}}>
              <Image source={alertCircleGray} style={{height: 12, width: 12}} />
            </View>
          ) : null}
          <DateRenderer
            format="DD MMM"
            value={due_date}
            color={colors.NEUTRAL_LOW}
            styles={{text: {...fonts.BODY2}}}
          />
        </>
      ) : (
        <AddFilledAction />
      )}
    </Row>
  );
};

export const dueDateColumn = ({navigation}) => ({
  render: DueDateRender,
  header: 'Due on',
  onPress: ({row}) => {
    navigation.navigate('due-date-form', {
      row,
      api: '/projecttasks',
    });
  },
  width: 80,
  fallbackRender: () => {
    <AddFilledAction />;
  },
  align: 'center',
});

export const TasksTable = props => {
  const {
    navigation,
    route: {params},
    filter: propsFilter,
    searchValue,
    showMarkComplete = true,
    fromMyView,
    parentSourceView,
    archived,
    isBacklog,
    isSubTaskTable,
    delegatedTask,
  } = props;
  let {filter, parent_task, thread = {}} = params;
  const threadFilter = thread?._id ? {thread: thread?._id} : {};
  filter = {...filter, ...threadFilter};
  const {markComplete, markActive} = useTaskActions({
    navigation,
    params,
    fromMyView,
  });

  let parentTaskFilter = {};

  if (parent_task) {
    parentTaskFilter = {
      parent_task: parent_task?._id,
    };
  }

  return (
    <Table
      eventSourceId={['Task', 'Comment']}
      filter={{...parentTaskFilter, ...filter, ...propsFilter}}
      search={searchValue}
      searchFields={'name'}
      api={`/projecttasks`}
      dataScope={'both'}
      aggregates={{
        count: 'count',
      }}
      fields={{
        name: 1,
        assigned_to: {name: 1},
        est_hours: 1,
        plan_date: 1,
        source: {name: 1, list_view: 1, label: 1},
        source_id: {name: 1},
        status: 1,
        completed_on: 1,
        recurring_task_id: {
          frequency_mode: 1,
        },
        output_link: 1,
        output_attachment: 1,
        is_continuous: 1,
        type: 1,
        open_for: 1,
        is_open: 1,
        due_date: 1,
        output_remark: 1,
        recent_reviewed_status: 1,
        recent_review: {
          review_avg: 1,
          reviewed_by: {name: 1, color: 1},
        },
        review_pending: 1,
        next_action_date: 1,
        next_action: 1,
        feature_id: {feature: 1, sub_status: 1},
        module_id: {module: 1},
        project_id: {project: 1},
        reviewer: {
          name: 1,
          color: 1,
        },
        master_task_assignment: {
          archive_disabled: 1,
          output_model_view: {
            label: 1,
            view: 1,
            filter_api: 1,
          },
        },
        archive_reason: 1,
        archived_on: 1,
        is_ad_hoc: 1,
        is_strategic: 1,
        disqualified: 1,
        sub_task_count: {_id: 1},
        completed_sub_task_count: {_id: 1},
        active_and_backlog_sub_task_count: {_id: 1},
        comments_count: {_id: 1},
        review_not_required: 1,
        parent_task: {name: 1},
        last_comment: {
          createdAt: 1,
        },
        favorite_by: 1,
        delegators: {name: 1},
        thread_goal: {goal: 1},
        thread: {name: 1},
        update_due_date_reason: 1,
      }}
      columns={[
        {
          width: 40,
          onPress: markComplete.onPress,
          render: MarkCompletedRender,
          disabled: ({row}) => !row?.assigned_to || !row?.est_hours,
          visible: !!(
            showMarkComplete &&
            !props.isCompleted &&
            props.isActive &&
            !isSubTaskTable
          ),
        },
        {
          width: 40,
          render: _props => {
            return ChangeSubTaskStatusRender({
              ...props,
              ..._props,
              params,
            });
          },
          visible: !!isSubTaskTable,
        },
        {
          header: ({aggregates}) => {
            return aggregates?.count ? `Task (${aggregates?.count})` : 'Task';
          },
          sourceField: 'source',
          sourceIdField: 'source_id',
          target_view_field: 'master_task_assignment.output_model_view.view',
          render: _props => {
            return TaskDynamicRender({
              ...props,
              ..._props,
              params,
            });
          },
        },
        {
          width: 70,
          params: params,
          navigation,
          header: {icon: Owner},
          render: TaskAssignUserAvatar,
          onLock: ({row}) => {
            navigation.navigate('assign-task', {
              ...params,
              row,
              delegatedTask,
            });
          },
          responsive: 'sm',
          align: 'center',
        },
        fromMyView ||
        props?.isCompleted ||
        props?.archived ||
        parentSourceView === 'OpenTask' ||
        parentSourceView === 'Overdue'
          ? {
              header: 'Est.',
              width: 80,
              type: 'duration',
              field: 'est_hours',
              align: 'right',
            }
          : estHrs({
              params,
              navigation,
            }),
        !thread?._id
          ? {
              header: 'Reviewer',
              type: 'userAvatarGroup',
              field: 'reviewer',
              width: 100,
              visible: !!props?.isCompleted,
              align: 'center',
            }
          : void 0,
        fromMyView || props.isCompleted
          ? {
              type: 'date',
              header: 'Due On',
              field: 'due_date',
              width: 80,
              align: 'center',
            }
          : dueDateColumn({navigation}),
        {
          type: 'date',
          header: 'Completed',
          field: 'completed_on',
          width: 100,
          align: 'center',
          visible: !!props?.isCompleted,
        },
        !thread?._id
          ? {
              type: 'rating',
              header: 'Performance',
              width: 120,
              align: 'center',
              render: ReviewComponent,
              readOnly: true,
              visible: !!props?.isCompleted,
            }
          : void 0,
        {
          type: 'date',
          header: 'Archived On',
          field: 'archived_on',
          visible: !!props.archived,
          width: 100,
          align: 'center',
        },
        {
          type: 'text',
          header: 'Archive Reason',
          field: 'archive_reason',
          visible: !!props.archived,
          width: 300,
        },
        {
          visible: !params?.parent_task,
          width: 85,
          render: SubtasksCountColumnRender,
          onPress: ({row}) => {
            navigation.navigate('sub-task-table', {
              parent_task: row,
              fromMyView,
              parentSourceView,
              ...params,
            });
          },
        },
        taskCommentColumn({params, navigation}),
        {
          width: 120,
          type: 'text',
          render: RenderMarkActive,
          align: 'right',
          onPress: markActive.onPress,
          visible: !!isBacklog,
        },
      ]}
      {...props}
    />
  );
};

export const AdHocTasksTable = props => {
  const {route, navigation, isCompleted, isActive, isBacklog} = props;
  const {params = {}} = route;
  const {assigned_to, employee_team} = params;
  const {
    activityLogs,
    updateAssignedTo,
    updateDueDate,
    moveToBacklog,
    archive,
    unarchive,
    updatePlan,
    markFavorite,
  } = useTaskActions({navigation, params});

  return (
    <>
      {isActive || isBacklog ? (
        <AdhocTaskInlineForm
          defaultValues={{
            status: isBacklog ? 'backlog' : 'active',
            employee_team: employee_team,
          }}
          params={params}
        />
      ) : (
        void 0
      )}
      <TasksTable
        {...props}
        parentSourceView="AdhocTask"
        filter={{
          is_ad_hoc: true,
          assigned_to,
        }}
        onRowPress={({row}) => {
          navigation.navigate('ad-hoc-task-edit', {
            ...params,
            row,
            readOnly: isCompleted,
          });
        }}
        sort={isCompleted ? {completed_on: -1} : props.sort || {createdAt: -1}}
        moreActions={[
          updateAssignedTo,
          updateDueDate,
          moveToBacklog,
          archive,
          unarchive,
          updatePlan,
          markFavorite,
          activityLogs,
        ]}
      />
    </>
  );
};

export const StrategicTasksTable = props => {
  const {route, navigation, isCompleted, isBacklog, skipAdd} = props;
  const {params = {}} = route;
  const {userId, employee_team} = params;
  const {
    activityLogs,
    updateAssignedTo,
    updateDueDate,
    moveToBacklog,
    archive,
    unarchive,
    updatePlan,
    markFavorite,
  } = useTaskActions({navigation, params});

  return (
    <>
      {isBacklog && !skipAdd ? (
        <StrategicTaskInlineForm
          defaultValues={{
            status: isBacklog ? 'backlog' : 'active',
            employee_team: employee_team,
          }}
          params={params}
        />
      ) : (
        void 0
      )}
      <TasksTable
        {...props}
        api={`/organizationtasks`}
        params={{teamId: employee_team?._id, userId}}
        parentSourceView="StrategicTask"
        filter={{
          is_strategic: true,
        }}
        onRowPress={({row}) => {
          navigation.navigate('strategic-task-edit', {
            ...params,
            row,
            readOnly: isCompleted,
          });
        }}
        sort={isCompleted ? {completed_on: -1} : props.sort || {createdAt: -1}}
        moreActions={[
          updateAssignedTo,
          updateDueDate,
          moveToBacklog,
          archive,
          unarchive,
          updatePlan,
          markFavorite,
          activityLogs,
        ]}
      />
    </>
  );
};

export const OpenTasksTable = props => {
  let {
    navigation,
    route: {params},
    user,
  } = props;

  const {department, product} = params || {};

  const {filterValues, applyFilter} = useFilter({});

  let {filter} = filterValues;

  const updatedFilter = {
    ...filter,
    team: params?.userId || user?._id,
    type: {$in: ['Task', 'Continuous']},
    assigned_to: null,
    project_id: null,
    status: 'backlog',
    product,
    department,
  };

  const taskDetail = ({row}) => {
    navigation.navigate('edit-active-task', {
      ...params,
      row,
      parentSourceView: 'OpenTask',
    });
  };

  return (
    <TasksTable
      {...props}
      isOpen
      renderHeader={() => {
        return (
          <TableHeader
            title="Open Tasks"
            actions={[
              <GroupFilter
                applyFilter={applyFilter}
                filterValues={filterValues}
                filters={[taskFilters.custom]}
              />,
            ]}
          />
        );
      }}
      filter={updatedFilter}
      onRowPress={taskDetail}
      api={'/open-tasks'}
      parentSourceView={'OpenTask'}
      showMarkComplete={false}
    />
  );
};

export const BacklogTasksTable = props => {
  const {
    navigation,
    route: {params},
    skipAddTask,
    fromMyView,
    filterValues = {},
  } = props;
  const {department, product, assigned_to, filter = {}} = params;
  const {user} = useAppStateContext();

  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
    });
  };
  const addOnFilter = {
    ...filter,
    department: department?._id,
    product: product?._id,
    assigned_to: fromMyView ? user._id : assigned_to,
  };

  return (
    <TasksTable
      {...props}
      params={params}
      onRowPress={taskDetail}
      isBacklog={true}
      renderHeader={props => {
        return (
          !skipAddTask &&
          !fromMyView && (
            <OrganizationTaskInlineForm
              {...props}
              defaultValues={{
                status: 'backlog',
                is_ad_hoc: true,
              }}
              navigation={navigation}
              params={params}
            />
          )
        );
      }}
      filter={{
        status: 'backlog',
        project_id: {$exists: false},
        ...addOnFilter,
        ...filterValues?.filter,
      }}
    />
  );
};

export const ActiveTasksTable = props => {
  const {
    route: {params},
    fromMyView,
    searchValue,
    filterValues,
  } = props || {};
  const navigation = useNavigation();
  const {user} = useAppStateContext();

  const {department, product, assigned_to, filter = {}} = params;
  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
      readOnly: fromMyView,
    });
  };

  const addOnFilter = {
    ...filter,
    project_id: null,
    department: department?._id,
    product: product?._id,
    assigned_to: fromMyView ? user._id : assigned_to,
  };

  const {activityLogs, updateAssignedTo, archive, markFavorite} =
    useTaskActions({
      navigation,
      params,
      fromMyView,
    });

  return (
    <TasksTable
      {...props}
      search={searchValue}
      searchFields={'name'}
      params={params}
      isActive={true}
      onRowPress={taskDetail}
      filter={{
        status: 'active',
        type: {$in: ['Task', 'Continuous']},
        ...addOnFilter,
        ...filterValues?.filter,
      }}
      moreActions={[updateAssignedTo, archive, activityLogs, markFavorite]}
    />
  );
};

export const CompletedTasksTable = props => {
  const {
    route: {params},
    fromMyView,
    customCompletedTask,
    navigation,
    defaultFilter,
    defaultParams,
    searchValue,
    skipAddTask,
    parentSourceView,
    title,
    delegatedFilter = {},
    delegatedTask,
    favorite,
  } = props;

  const {
    department,
    product,
    fromOrganisation,
    assigned_to,
    filter = {},
    thread = {},
    threadGoalId,
    threadMeetingNotes,
  } = params;
  const {user} = useAppStateContext();
  const threadFilter = thread?._id ? {thread: thread._id, thread_goal: threadGoalId, thread_meeting_notes: threadMeetingNotes} : {};
  const favoriteFilter = favorite ? {favorite_by: {$in: [user?._id]}} : {};

  const {
    activityLogs,
    markDisqualified,
    markQualified,
    showOutput,
    markFavorite,
  } = useTaskActions({
    navigation,
    params,
  });

  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
      readOnly: true,
    });
  };

  const addOnFilter = {...filter, is_ad_hoc: defaultFilter?.is_ad_hoc};
  if (parentSourceView !== 'EmployeeEfficiency') {
    addOnFilter.department = department?._id;
    addOnFilter.product = product?._id;
  }
  if (fromMyView) {
    addOnFilter.assigned_to = user._id;
  } else if (customCompletedTask) {
    addOnFilter.assigned_to = user._id;
  } else if (defaultParams?.fromReview) {
    addOnFilter.reviewer = assigned_to;
  } else if (assigned_to) {
    addOnFilter.assigned_to = assigned_to;
  }
  if (typeof defaultParams?.review_pending === 'boolean') {
    addOnFilter.review_pending = defaultParams?.review_pending;
  }

  const {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      period:
        thread?._id || customCompletedTask || delegatedTask
          ? getCurrentMonth()
          : getPreviousMonth(),
      ...defaultParams,
    },
    filter: defaultFilter,
  });
  const {
    params: {period},
    filter: headerFilters,
  } = filterValues;
  const {from: start_date, to: end_date} = period;

  const filters = [];

  if (fromOrganisation) {
    filters.push(
      <GroupFilter
        applyFilter={applyFilter}
        filterValues={filterValues}
        filters={[taskFilters.custom]}
      />,
    );
  }

  return (
    <TasksTable
      renderHeader={() => (
        <TableHeader
          title={title ? title : thread?._id ? 'Completed Tasks' : ''}
          actions={
            parentSourceView === 'EmployeeEfficiency'
              ? [...filters]
              : [
                  !customCompletedTask ? (
                    <AutoCompleteFilter
                      field={'assigned_to'}
                      onChangeFilter={onChangeFilter}
                      filterValues={filterValues}
                      placeholder="Select Assignee"
                      api="/usersuggestions"
                      suggestionField="name"
                      secondarySuggestionField="email"
                    />
                  ) : (
                    void 0
                  ),
                  <PeriodRangeFilter
                    filterValues={filterValues}
                    onChangeFilter={onChangeFilter}
                  />,
                  ...filters,
                ]
          }
        />
      )}
      params={{...params}}
      isCompleted
      sort={{completed_on: -1}}
      onRowPress={taskDetail}
      filter={{
        status: 'completed',
        type: {$in: ['Task', 'Continuous']},
        project_id: {$exists: false},
        completed_on: {
          $gte: start_date,
          $lte: end_date,
        },
        ...headerFilters,
        ...addOnFilter,
        ...props.groupFilter?.filter,
        ...threadFilter,
        ...delegatedFilter,
        ...favoriteFilter,
      }}
      search={searchValue}
      searchFields={'name'}
      moreActions={[
        !skipAddTask && showOutput,
        markDisqualified,
        markQualified,
        markFavorite,
        activityLogs,
      ]}
      {...props}
    />
  );
};

const navigateToFeatureDetail = ({
  navigateTo,
  navigation,
  row,
  project,
  featureType,
}) => {
  const {_id, feature, module_id, project_id, type, sub_status} = row;
  const {tasks, ...restRow} = row || {};

  let navigateParams = {
    project: project || project_id,
    project_id: project?._id || project_id?._id,
    featureType: featureType || type || 'feature',
    feature: restRow,
    feature_id: _id,
    module: module_id,
    module_id: module_id?._id,
    sub_status,
  };
  if (navigateTo) {
    navigateParams.parentTabApiKey = 'active';
    navigateParams.breadcrumb = {
      title: feature,
    };
    navigateTo(`feature-detail-screen-new`, navigateParams);
  } else {
    navigation.navigate(`feature-detail-screen`, navigateParams);
  }
};

export const ProjectReviewTaskTable = props => {
  const {
    navigation,
    route: {params} = {},
    user,
    filterValues,
    searchValue,
    featureType,
    fromMyView,
    source: taskTableSource,
  } = props;

  const {
    project,
    userId = user?._id,
    start_date,
    end_date,
    span,
    filter: paramsFilter,
    source: _source,
  } = params;
  const {
    activityLogs,
    markDisqualified,
    markQualified,
    markFavorite,
    markReviewArchived,
    markReviewUnarchived,
  } = useTaskActions({
    navigation,
    params,
    taskTableSource,
  });
  const {filter, params: filterParams} = filterValues || {};
  const colors = useTheme('colors');
  const {navigateTo} = useLeftMenuContext() || {};

  let addOnFilter = {
    ...filter,
    ...paramsFilter,
    ...props.filter,
    ...props.filterParams,
  };
  const paramsObj = {
    ...filterParams,
    scope: 'self',
    span,
    start_date,
    end_date,
    source: _source,
    featureType,
    fromReview: true,
  };
  let columns = [
    assignedTo,
    {
      header: 'Task',
      render: TaskWithActionRenderer,
      taskField: 'name',
      actionField: 'master_task_assignment.task',
      colorField: 'master_task_assignment.color',
      responsive: 'sm',
    },
    source({params, project, navigation}),
    {
      header: 'Est.',
      width: 100,
      type: 'duration',
      field: 'est_hours',
      align: 'right',
    },
    {
      header: 'Completed On',
      type: 'date',
      width: 120,
      align: 'center',
      readOnly: true,
      field: 'completed_on',
    },
    {
      header: 'Rating',
      type: 'rating',
      width: 120,
      align: 'center',
      render: ReviewComponent,
      readOnly: taskTableSource !== 'Pending',
      reviewRejected: true,
      visible: taskTableSource !== 'Archived',
    },
    taskCommentColumn({params, navigation}),
  ];

  return (
    <GroupTable
      selection={{
        actions: [markReviewArchived, markReviewUnarchived],
      }}
      search={searchValue}
      searchFields={'name'}
      eventSourceId={['Task', 'TaskReview', 'Comment']}
      api={`/users/${userId}/featureTasks`}
      params={paramsObj}
      filter={addOnFilter}
      limit={-1}
      onRowPress={({row}) => {
        navigation.navigate('task-edit', {
          ...params,
          fromReview: true,
          row,
          readOnly: fromMyView || row?.status === 'completed',
          addReviewEnabled: taskTableSource === 'Pending',
        });
      }}
      moreActions={[
        featureType == 'feature' && markDisqualified,
        featureType == 'feature' && markQualified,
        markFavorite,
        markReviewArchived,
        markReviewUnarchived,
        activityLogs,
      ]}
      groupRow={{
        data: 'tasks',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {feature, module_id, project_id, type} = row;
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                onPress={() => {
                  navigateToFeatureDetail({
                    navigateTo,
                    navigation,
                    row,
                    project,
                    featureType,
                  });
                }}
                color={colors['INFORMATION_HIGH']}
                value={`${feature} ${
                  module_id?.module ? '(' + module_id.module + ')' : ''
                }`}
              />
              <GroupContentItem value={project_id?.project} />
              {type === 'issue' ? (
                <GroupContentItem value={'Issue'} color="red" />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          let {aggregateEsthours, status, sub_status} = row;

          status = status?.charAt(0)?.toUpperCase() + status?.slice(1);
          status = sub_status || status;
          if (status === 'Passed') {
            status = 'QA Passed';
          } else if (status === 'Failed') {
            status = 'QA Failed';
          }

          aggregateEsthours = aggregateEsthours.toFixed(1);
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                color={status === 'Released' ? colors['SUCCESS_HIGH'] : null}
                value={`${status}`}
              />
              <GroupContentItem
                value={`${aggregateEsthours} h`}
                icon={EstHours}
              />
            </RowWithSeparator>
          );
        },
      }}
      columns={columns}
    />
  );
};

export const ReviewTasksTable = props => {
  const {
    navigation,
    source,
    searchValue,
    fromMyView,
    source: taskTableSource,
  } = props;
  const {params, filterParams, filter} = props;
  const {colors} = useTheme();

  const {
    activityLogs,
    markDisqualified,
    markQualified,
    markReviewArchived,
    markReviewUnarchived,
  } = useTaskActions({
    navigation,
    params,
    taskTableSource,
  });
  return (
    <GroupTable
      {...props}
      selection={{
        actions: [markReviewArchived, markReviewUnarchived],
      }}
      onRowPress={({row}) => {
        navigation.navigate('task-edit', {
          ...params,
          fromReview: true,
          row,
          readOnly: fromMyView || row?.status === 'completed',
          addReviewEnabled: source === 'Pending',
        });
      }}
      eventSourceId={['Task', 'TaskReview', 'Comment']}
      search={searchValue}
      searchFields={'name'}
      filter={filter}
      params={{...params, ...filterParams}}
      api={'/reviewTasksGroupBySpan'}
      groupRow={{
        title: ({row: {span}}) => span,
        data: 'tasks',
        defaultExpanded: true,
      }}
      moreActions={[
        markDisqualified,
        markQualified,
        markReviewArchived,
        markReviewUnarchived,
        activityLogs,
      ]}
      columns={[
        assignedTo,
        {
          header: 'Task',
          responsive: 'sm',
          render: ({row, ...props}) => {
            const {styles} = props;
            const {parent_task} = row;
            return (
              <Col gap={8}>
                <TaskWithActionRenderer
                  row={row}
                  styles={styles}
                  taskField={'name'}
                  actionField="master_task_assignment.task"
                  colorField="master_task_assignment.color"
                />
                <DynamicFKRender
                  row={row}
                  styles={styles}
                  sourceField="source"
                  sourceIdField="source_id"
                  target_view_field="master_task_assignment.output_model_view.view"
                  onPress={props => {
                    navigateToListView({...props, navigation, params});
                  }}
                />
                {parent_task ? (
                  <Tag
                    value={`Parent: ${parent_task?.name}`}
                    color={colors.ACCENT2_LOW}
                    textColor={colors.ACCENT2_HIGH}
                  />
                ) : null}
              </Col>
            );
          },
          responsive: 'sm',
        },
        {
          render: TaskTypeRender,
          columnField: 'recurring_task_id.frequency_mode',
          valueField: ({row}) => (row?.is_ad_hoc ? 'Custom' : null),
          width: 140,
          align: 'center',
        },
        {
          type: 'duration',
          header: 'Est.',
          field: 'est_hours',
          width: 100,
          align: 'center',
        },
        {
          header: 'Due On',
          field: 'due_date',
          type: 'date',
          width: 100,
          align: 'center',
        },
        {
          header: 'Completed On',
          field: 'completed_on',
          type: 'date',
          width: 120,
          align: 'center',
        },
        {
          type: 'rating',
          header: 'Performance',
          width: 120,
          align: 'center',
          render: ReviewComponent,
          readOnly: source !== 'Pending',
          reviewRejected: true,
        },
        taskCommentColumn({params, navigation}),
      ]}
    />
  );
};

export const OverDueTasksTable = props => {
  const {route: {params} = {}} = props;
  const {userId} = params;
  return (
    <TasksTable
      filter={{
        assigned_to: userId,
        status: 'active',
        due_date: {$lt: moment.utc().startOf('day').toDate()},
      }}
      isActive
      parentSourceView="Overdue"
      {...props}
    />
  );
};

export const OverDueTasks = props => {
  const {route: {params} = {}} = props;
  const {userId} = params;
  return (
    <>
      <TableHeader title="Overdue Tasks" />
      <TasksTable
        filter={{
          assigned_to: userId,
          status: {$in: ['active', 'backlog']},
          due_date: {$lt: moment.utc().startOf('day').toDate()},
        }}
        parentSourceView="Overdue"
        {...props}
      />
    </>
  );
};

export const EmployeeProjectTasks = props => {
  const {
    navigation,
    route: {params} = {},
    user,
    filterValues,
    addOnParams,
    featureType,
    fromMyView,
    searchValue,
  } = props;
  let {
    project,
    userId,
    start_date,
    end_date,
    span,
    filter: paramsFilter,
    source: _source,
    assigned_to,
  } = params;

  const {navigateTo} = useLeftMenuContext() || {};

  if (!userId) {
    userId = assigned_to?._id || assigned_to || user?._id;
  }

  const {filter, params: filterParams} = filterValues || {};

  let addOnFilter = {
    status: {$in: ['backlog', 'active']},
    project_id: project?._id || {$exists: true},
    ...filter,
    ...paramsFilter,
  };
  const paramsObj = {
    ...filterParams,
    scope: 'self',
    span,
    start_date,
    end_date,
    source: _source,
    ...addOnParams,
  };
  const {
    activityLogs,
    deleteTask,
    markQualified,
    markDisqualified,
    updateAssignedTo,
    updateDueDate,
    updateCompletedOnDate,
    markComplete,
    markIncomplete,
    markFavorite,
  } = useTaskActions({navigation, params, fromMyView});

  let columns = [
    {
      render: StatusIconRender,
      iconField: 'status',
      onIconPress: markComplete.onPress,
      width: 40,
      responsive: 'sm',
      visible: () => addOnFilter?.status !== 'completed',
      disabled: ({row}) => {
        return !row?.assigned_to;
      },
    },
    taskWithSource({params, project, navigation}),
    {
      header: 'Est.',
      width: 100,
      type: 'duration',
      field: 'est_hours',
      align: 'right',
      aggregate: {
        suffix: ' hr',
      },
    },
    dueDate({navigation}),
    {
      header: 'Completed On',
      type: 'date',
      width: 100,
      align: 'center',
      readOnly: true,
      field: 'completed_on',
      visible: addOnFilter?.status === 'completed',
    },
    {
      header: 'Rating',
      type: 'rating',
      width: 120,
      align: 'center',
      render: ReviewComponent,
      readOnly: true,
      visible: addOnFilter?.status === 'completed',
    },
    taskCommentColumn({params, navigation}),
  ];

  return (
    <GroupTable
      eventSourceId={['Task', 'Comment']}
      api={`/users/${userId}/featureTasks`}
      params={{...paramsObj, featureType}}
      filter={addOnFilter}
      limit={-1}
      search={searchValue}
      searchFields={['name']}
      onRowPress={({row}) => {
        navigation.navigate('task-edit', {
          ...params,
          row,
          readOnly: fromMyView || row?.status === 'completed',
        });
      }}
      columns={columns}
      moreActions={({row}) => [
        updateAssignedTo,
        updateDueDate,
        updateCompletedOnDate,
        row.feature_id?.status !== 'released' && markIncomplete,
        markDisqualified,
        markQualified,
        markFavorite,
        activityLogs,
        deleteTask,
      ]}
      groupRow={{
        data: 'tasks',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {feature, module_id, project_id, type} = row;
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                onPress={() => {
                  navigateToFeatureDetail({
                    navigateTo,
                    navigation,
                    row,
                    project,
                    featureType,
                  });
                }}
                color="#007AFF"
                value={`${feature} ${
                  module_id?.module ? '(' + module_id.module + ')' : ''
                }`}
              />
              <GroupContentItem value={project_id?.project} />
              {type === 'issue' ? (
                <GroupContentItem value={'Issue'} color="red" />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          let {aggregateEsthours} = row;
          aggregateEsthours = aggregateEsthours.toFixed(1);
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                value={`${aggregateEsthours} h`}
                icon={EstHours}
              />
            </RowWithSeparator>
          );
        },
      }}
    />
  );
};

export const ProjectTeamTasks = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      eventSourceId={'Task'}
      api={`/projects/${project._id}/teamtasks`}
      onRowPress={props => {
        let {row} = props || {};
        if (navigateTo) {
          navigateTo(`project-team-active-tasks-new`, {
            ...params,
            userId: row?.assigned_to?._id,
            assigned_to: row?.assigned_to,
            est_hours: row?.est_hours,
            breadcrumb: {
              title: row?.assigned_to?.name,
            },
          });
        } else {
          navigation.navigate(`project-team-active-tasks`, {
            ...params,
            userId: row?.assigned_to?._id,
            assigned_to: row?.assigned_to,
            est_hours: row?.est_hours,
          });
        }
      }}
      columns={[
        userAvatarChip,
        {
          type: 'number',
          header: 'Tasks',
          field: 'taskCount',
          width: 150,
        },
        {
          type: 'duration',
          field: 'est_hours',
          width: 150,
          align: 'right',
          header: 'Est Hrs',
        },
      ]}
    />
  );
};

const tasks = {
  taskEstHrsAll: 'All Tasks',
  taskEstHrsActive: 'Active Tasks',
  taskOverdue: 'Overdue Tasks',
};

// Akshat Garg - 21/08/24 - Creating new team view for team tasks
const Tasks = ({navigation, task, all_tasks, overdueTasks}) => ({
  header: tasks[task],
  align: 'center',
  render: ({row}) => {
    const taskData = row[task] || {};

    let taskCount = taskData.count;
    let taskHours = taskData.est_hours;
    taskCount = taskCount || 0;
    taskHours = taskHours || 0;

    if (taskCount === 0 && taskHours === 0) {
      return null;
    }

    const { colors } = useTheme();
    const { textStyle } = useStyles(ReviewedTaskRenderStyle);

    return (
      <Col gap={4}>
        <Text title={taskHours} numberOfLines={2} style={textStyle}>
          {`${taskHours} hrs`}
        </Text>
        <View>
          <Text style={{ ...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM }}>
            {`${taskCount} ${taskCount > 1 ? 'tasks' : 'task'}`}
          </Text>
        </View>
      </Col>
    );
  },
  onPress: ({row}) => {
    if (overdueTasks) {
      navigation.navigate('overdue-tasks', {
        userId: row?.employee_user?._id,
        row,
        teamTask: true,
      });
    } else {
      navigation.navigate('team-active-tasks', {
        userId: row?.employee_user?._id,
        assigned_to: row?.employee_user,
        est_hours: row?.est_hours,
        all_tasks,
        activeTask: true,
        row,
      });
    }
  },
  width: 150,
});

// Akshat Garg - 21/08/24 - Creating new team view for team tasks
export const TeamTaskAllTable = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  let {employeeId = user?.employee?._id} = params;

  return (
    <Table
      eventSourceId={'Task'}
      api={`/employees/${employeeId}/teamTasks`}
      params={{teamAllTask: true}}
      columns={[
        {
          render: ({row}) => <UserAvatarChip value={row} />,
          header: 'Members',
        },
        {
          header: 'Team',
          field: 'teamCount',
          type: 'colorChip',
          width: 80,
          align: 'center',
          onPress: ({row}) => {
            row?.teamCount &&
              navigation.navigate(`employee-team-tasks`, {
                ...params,
                employeeId: row._id,
                row,
              });
          },
        },
        {
          header: 'Threads',
          field: 'threadCount',
          type: 'colorChip',
          width: 80,
          align: 'center',
          onPress: ({row}) => {
            row = {...row, threadNameNotVisible: true};
            navigation.navigate('team-thread-tabs', {
              teamMemberThreadFilter : {collaborators: {$in: [row?.employee_user?._id]}},
              row,
            });
          }
        },
        {
          header: 'Goals',
          field: 'threadGoalCount',
          type: 'colorChip',
          width: 80,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('my-thread-goal', {row});
          }
        },
        Tasks({navigation, task: 'taskEstHrsAll', all_tasks: true}),
        Tasks({navigation, task: 'taskEstHrsActive'}),
      ]}
    />
  );
};

export const TeamTasksTable = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  let {employeeId = user?.employee?._id} = params;

  return (
    <Table
      eventSourceId={'Task'}
      api={`/employees/${employeeId}/teamTasks`}
      onRowPress={props => {
        const {row} = props;

        navigation.navigate(`team-active-tasks`, {
          ...params,
          userId: row?.employee_user?._id,
          assigned_to: row?.employee_user,
          est_hours: row?.est_hours,
        });
      }}
      columns={[
        {
          render: ({row}) => <UserAvatarChip value={row} />,
          header: 'Members',
        },
        {
          header: 'Team count',
          field: 'teamCount',
          type: 'colorChip',
          width: 120,
          align: 'center',
          onPress: ({row}) => {
            row?.teamCount &&
              navigation.navigate(`employee-team`, {
                ...params,
                employeeId: row._id,
                row,
              });
          },
        },
        {
          field: 'tasksCount',
          header: 'Tasks',
          width: 100,
          type: 'text',
          align: 'center',
        },
        {
          field: 'taskEstHrs.est_hours',
          header: 'Est Hrs',
          width: 150,
          type: 'duration',
          align: 'right',
        },
      ]}
    />
  );
};

export const OverdueReviewTasksTable = props => {
  const {
    route: {params},
    filter,
    searchValue,
    pending,
  } = props;

  const {userId, employee_team} = params;
  const invoke = useInvoke({method: 'put', eventSourceId: 'Task'});

  return (
    <Table
      api={`/reviewTasks`}
      eventSourceId={['Task']}
      params={{employeeTeam: employee_team?._id, userId}}
      filter={{
        status: 'completed',
        review_pending: true,
        ...filter,
      }}
      search={searchValue}
      searchFields={['name', 'code']}
      columns={[
        {
          type: 'userAvatar',
          header: {icon: Owner},
          field: 'assigned_to',
          width: 60,
        },
        {
          header: 'Task',
          sourceField: 'source',
          sourceIdField: 'source_id',
          target_view_field: 'master_task_assignment.output_model_view.view',
          render: _props => {
            return TaskDynamicRender({
              ...props,
              ..._props,
              params,
            });
          },
        },
        {
          type: 'duration',
          header: 'Est.',
          field: 'est_hours',
          width: 70,
          align: 'right',
        },
        {
          type: 'userAvatarGroup',
          header: 'Reviewer',
          field: 'reviewer',
          width: 120,
          align: 'center',
        },
        {
          type: 'date',
          header: 'Due On',
          field: 'due_date',
          width: 80,
          align: 'center',
        },
        {
          type: 'date',
          header: 'Completed On',
          field: 'completed_on',
          width: 120,
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Archive Review',
          confirm: {
            title: 'Archive Review',
            message:
              'Are you sure you want to archive the review for the selected tasks?',
          },
          onPress: ({row}) => {
            const updates = {
              is_review_archived: true,
              review_pending: null,
            };

            invoke({
              uri: `/projecttasks/${row?._id}`,
              props: updates,
            });
          },
          visible: pending ? true : false,
        },
      ]}
    />
  );
};

// shows tasks in project, module, milestone
export const ComprehensiveTasks = props => {
  const {
    navigation,
    route: {params} = {},
    addOnParams,
    featureType,
    fromMyView,
    showHeader,
    title,
    typeId,
  } = props;

  const {
    project,
    module,
    start_date,
    end_date,
    span,
    source: _source,
    milestone,
  } = params;

  const {navigateTo} = useLeftMenuContext() || {};

  let addOnFilter = {
    project_id: project?._id || {$exists: true},
    ...addOnParams?.groupFilters,
  };

  if (addOnParams.status) {
    addOnFilter.status = addOnParams.status;
  }

  const paramsObj = {
    scope: 'self',
    span,
    start_date,
    end_date,
    source: _source,
    ...addOnParams,
  };
  const {
    activityLogs,
    markDisqualified,
    markQualified,
    deleteTask,
    updateCompletedOnDate,
    markComplete,
    markIncomplete,
    markFavorite,
  } = useTaskActions({navigation, params});

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );

  let columns = [
    {
      render: StatusIconRender,
      iconField: 'status',
      onIconPress: markComplete.onPress,
      width: 40,
      responsive: 'sm',
      visible: () => addOnFilter?.status !== 'completed',
      disabled: ({row}) => {
        return !row?.assigned_to;
      },
    },
    taskWithSource({params, project, navigation}),
    updateEstHrs({navigation}),
    assignedTo,
    dueDate({navigation}),
    {
      header: 'Completed',
      type: 'date',
      width: 100,
      align: 'center',
      readOnly: true,
      field: 'completed_on',
      visible: () => addOnFilter?.status === 'completed',
    },
    {
      header: 'Rating',
      type: 'rating',
      width: 120,
      align: 'center',
      render: ReviewComponent,
      readOnly: true,
      visible: () => addOnFilter?.status === 'completed',
    },
    taskCommentColumn({params, navigation}),
  ];

  let api;
  switch (typeId) {
    case PROJECT_TYPE_ID:
      api = `/projects/${project?._id}/featureTasks`;
      break;
    case PROJECT_MILESTONE_TYPE_ID:
      api = `/milestones/${milestone?._id}/featureTasks`;
      break;
    case PROJECT_MODULE_TYPE_ID:
      api = `/modules/${module?._id}/featureTasks`;
      break;
  }

  return (
    <GroupTable
      renderHeader={() => {
        if (showHeader) {
          return (
            <TableHeader
              title={title}
              actions={[
                <SearchFilter
                  value={searchValue}
                  onChangeFilter={onChangeFilter}
                />,
                <GroupFilter
                  filterValues={filterValues}
                  applyFilter={applyFilter}
                  filters={[
                    {
                      type: 'autoComplete',
                      label: 'Assignee',
                      api: `/projects/${project._id}/members`,
                      placeholder: 'Select',
                      suggestionField: 'name',
                      valueField: 'name',
                      field: 'assigned_to',
                    },
                  ]}
                />,
              ]}
            />
          );
        }
      }}
      eventSourceId={['Task', 'Comment']}
      search={addOnParams?.searchValue || searchValue}
      searchFields={'name'}
      api={api}
      params={{...paramsObj, featureType}}
      filter={{...addOnFilter, ...filterValues.filter}}
      limit={-1}
      onRowPress={({row}) => {
        navigation.navigate('task-edit', {
          ...params,
          row,
          readOnly: fromMyView || row?.status === 'completed',
        });
      }}
      columns={columns}
      moreActions={({row}) => {
        return [
          updateCompletedOnDate,
          row?.feature_id?.status !== 'released' && markIncomplete,
          markDisqualified,
          markQualified,
          markFavorite,
          activityLogs,
          deleteTask,
        ];
      }}
      groupRow={{
        data: 'tasks',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {feature, module_id, type} = row;
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                onPress={() => {
                  navigateToFeatureDetail({
                    navigateTo,
                    navigation,
                    row,
                    project,
                    featureType,
                  });
                }}
                color="#007AFF"
                value={`${feature} ${
                  !params.module_id && module_id?.module
                    ? '(' + module_id.module + ')'
                    : ''
                }`}
              />
              {type === 'issue' ? (
                <GroupContentItem value={'Issue'} color="red" />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          let {aggregateEsthours} = row;
          aggregateEsthours = aggregateEsthours.toFixed(1);
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                value={`${aggregateEsthours} h`}
                icon={EstHours}
              />
            </RowWithSeparator>
          );
        },
      }}
    />
  );
};

const completedTaskColumns = ({
  colors,
  fromMyView,
  parentSourceView,
  navigation,
  params,
  props,
  isReleased = false,
}) => [
  {
    header: 'Task',
    sourceField: 'source',
    sourceIdField: 'source_id',
    target_view_field: 'master_task_assignment.output_model_view.view',
    render: _props => {
      return TaskDynamicRender({
        ...props,
        ..._props,
        params,
      });
    },
  },
  {
    width: 70,
    align: 'center',
    header: {icon: Owner},
    type: 'userAvatar',
    field: 'assigned_to',
    responsive: 'sm',
    onPress: ({row}) => {
      isReleased ||
        navigation.navigate('update-assignee', {
          row,
          task_id: row?._id,
          project: row?.project_id,
        });
    },
    fallbackRender: ({row}) => {
      const {LockGrey} = useTheme('icons');
      return (
        <AddAction
          icon={LockGrey}
          onPress={() => {
            isReleased ||
              navigation.navigate('update-assignee', {
                row,
                task_id: row?._id,
                project: row?.project_id,
              });
          }}
        />
      );
    },
  },
  {
    header: 'Est.',
    width: 70,
    type: 'duration',
    field: 'est_hours',
    align: 'right',
  },
  {
    header: 'Reviewer',
    type: 'userAvatarGroup',
    field: 'reviewer',
    width: 100,
    align: 'center',
  },
  {
    type: 'date',
    header: 'Completed',
    field: 'completed_on',
    width: 100,
    align: 'center',
    color: ({row}) => {
      return moment
        .utc(row.completed_on || void 0)
        .startOf('day')
        .toDate() > moment.utc(row.due_date).startOf('day').toDate()
        ? colors.ERROR_HIGH
        : colors.NEUTRAL_LOW;
    },
    title: ({row}) => {
      if (row?.due_date) {
        return `Due On : ${moment.utc(row.due_date).format('DD MMM')}`;
      }
    },
  },
  {
    type: 'rating',
    header: 'Performance',
    width: 120,
    align: 'center',
    render: ReviewComponent,
    readOnly: true,
    visible: !!props?.isCompleted,
  },
  {
    visible: !params?.parent_task,
    width: 85,
    render: ({row}) => {
      const {SubTaskBlue, SubTaskGreen} = useTheme('icons');
      const {sub_task_count = 0, completed_sub_task_count = 0} = row;
      const isAllCompleted =
        sub_task_count && sub_task_count === completed_sub_task_count;

      return (
        <Chip
          gap={4}
          prefix={
            <Image
              style={{height: 16, width: 16}}
              source={isAllCompleted ? SubTaskGreen : SubTaskBlue}
            />
          }
          color={isAllCompleted ? 'SUCCESS_LOW' : 'INFORMATION_LOW'}
          textColor={isAllCompleted ? 'SUCCESS_HIGH' : 'INFORMATION_HIGH'}
          value={
            sub_task_count
              ? `${completed_sub_task_count}/${sub_task_count}`
              : '0'
          }
        />
      );
    },
    onPress: ({row}) => {
      navigation.navigate('sub-task-table', {
        parent_task: row,
        fromMyView,
        parentSourceView,
        ...params,
      });
    },
  },
  taskCommentColumn({params, navigation}),
];

export const CompletedTeamTasksTable = ({api, ...props}) => {
  const {
    navigation,
    route: {params},
    filter: propsFilter = {},
    searchValue,
    fromMyView,
    parentSourceView,
    employee_team,
  } = props;

  const colors = useTheme('colors');

  return (
    <GroupTable
      api={`/employeeTeam/${employee_team?._id}/completedTasks`}
      eventSourceId={['Task', 'Comment']}
      limit={3}
      skip={3}
      searchFields={['name']}
      search={searchValue}
      filter={{...propsFilter}}
      columns={completedTaskColumns({
        colors,
        fromMyView,
        parentSourceView,
        navigation,
        params,
        props,
      })}
      groupRow={{
        data: '_children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {_id} = row || {};
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={_id} />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          const {total_est_hours = 0} = row || {};

          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                value={`Total hours: ${Number(total_est_hours).toFixed()} h`}
              />
            </RowWithSeparator>
          );
        },
      }}
      {...props}
    />
  );
};

export const CompletedTeamCustomTasksTable = ({api, ...props}) => {
  const {
    navigation,
    route: {params},
    addOnFilter: propsAddOnFilter = {},
    filter: propsFilter = {},
    searchValue,
    fromMyView,
    parentSourceView,
    employee_team,
  } = props;

  const colors = useTheme('colors');

  const {data: tasks = [], ...restFetchProps} = useFetchData({
    api: `/employeeTeam/${employee_team?._id}/tasks`,
    addOnFilter: {...propsAddOnFilter},
    params: {employee_team},
    filter: propsFilter,
    limit: 50,
    skip: 50,
    eventSourceId: ['Task', 'Comment'],
    searchFields: ['name'],
    search: searchValue,
  });

  const groupedTasks = getGroupedTasks({tasks});

  return (
    <GroupTable
      data={groupedTasks}
      {...restFetchProps}
      columns={completedTaskColumns({
        colors,
        fromMyView,
        parentSourceView,
        navigation,
        params,
        props,
      })}
      groupRow={{
        data: '_children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {_id} = row || {};
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={_id} />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          const {total_est_hours = 0} = row || {};

          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                value={`Total hours: ${Number(total_est_hours).toFixed()} h`}
              />
            </RowWithSeparator>
          );
        },
      }}
      {...props}
    />
  );
};
export const CompletedTeamStrategicTasksTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
    fromMyView,
    parentSourceView,
    addOnFilter,
  } = props;

  const {employee_team, userId} = params;

  const colors = useTheme('colors');

  const {data: tasks = [], ...restFetchProps} = useFetchData({
    api: `/organizationtasks`,
    params: {teamId: employee_team?._id, userId: userId},
    filter: {is_strategic: true, status: 'completed'},
    addOnFilter,
    limit: 50,
    skip: 50,
    eventSourceId: ['Task', 'Comment'],
    searchFields: ['name'],
    search: searchValue,
  });

  const groupedTasks = getGroupedTasks({tasks});

  return (
    <GroupTable
      data={groupedTasks}
      {...restFetchProps}
      columns={completedTaskColumns({
        colors,
        fromMyView,
        parentSourceView,
        navigation,
        params,
        props,
      })}
      groupRow={{
        data: '_children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {_id} = row || {};
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={_id} />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          const {total_est_hours = 0} = row || {};

          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                value={`Total hours: ${Number(total_est_hours).toFixed()} h`}
              />
            </RowWithSeparator>
          );
        },
      }}
      {...props}
    />
  );
};

export const FavoritesTaskTable = props => {
  const {user} = useAppStateContext();
  const {navigation, route: {params} = {}} = props;
  const {markFavorite} = useTaskActions({navigation, params});
  return (
    <TasksTable
      filter={{
        favorite_by: {$in: [user._id]},
      }}
      moreActions={[markFavorite]}
      {...props}
    />
  );
};
