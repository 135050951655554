import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const InternalProjectResourceRequestForm = props => {
  const {route: {params} = {}} = props || {};
  const {user = {}} = useAppStateContext();
  const {employee = {}} = user;
  const {row = {}} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/internalrequests',
    eventSourceId: ['InternalResourceRequest'],
  });

  return (
    <Form
      header="Internal Resource Request"
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          field: 'internal_resource_request',
          type: 'text',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              defaultValues={{
                project: row?._id,
                customer: row?.customer?._id,
                owner_id: row?.owner_id?._id,
                total_potential: row?.total_potential,
                requested_date: new Date(),
                request_from: employee?._id,
                status: 'pending',
              }}
              inlineAddForm
              fields={[
                {
                  field: 'no_of_resources_requested',
                  label: 'Count',
                  placeholder: 'Count',
                  type: 'number',
                  required: true,
                },
                {
                  field: 'tool',
                  placeholder: 'Skill',
                  label: 'Skill',
                  type: 'autoComplete',
                  api: `/tools`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                },
                {
                  field: 'experience',
                  label: 'Experience',
                  placeholder: 'Experience',
                  type: 'autoComplete',
                  api: '/experiences',
                  suggestionField: 'experience',
                  valueField: 'experience',
                  required: true,
                },
                {
                  type: 'date',
                  field: 'from_date',
                  label: 'From',
                  placeholder: 'From',
                  required: true,
                },
                {
                  type: 'date',
                  field: 'to_date',
                  label: 'To',
                  placeholder: 'To',
                  required: true,
                },
                {
                  type: 'number',
                  field: 'hours',
                  label: 'Hrs/Day',
                  placeholder: 'Hrs/Day',
                  required: true,
                },
              ]}
              columns={[
                {
                  field: 'no_of_resources_requested',
                  header: 'Count',
                },
                {
                  field: 'tool.name',
                  header: 'Skill',
                },
                {
                  field: 'experience.experience',
                  header: 'Experience',
                },
                {
                  type: 'date',
                  field: 'from_date',
                  header: 'From',
                  formatOptions: {
                    format: 'DD MMM YY',
                  },
                },
                {
                  type: 'date',
                  field: 'to_date',
                  header: 'To',
                  formatOptions: {
                    format: 'DD MMM YY',
                  },
                },
                {
                  type: 'number',
                  field: 'hours',
                  header: 'Hrs/Day',
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};
