import React from 'react';
import {Tag} from './Chip';
import {useAccentColor} from '../../controllers/useAccentColor';

const prStatusObj = {
  NEW: {
    color: 'ACCENT1',
  },
  OPEN: {
    color: 'ACCENT1',
  },
  MERGED: {
    color: 'SUCCESS',
  },
  DECLINED: {
    color: 'ERROR',
  },
};

export const PrStatusChip = ({value, ...props}) => {
  const {HIGH, LOW} = useAccentColor(prStatusObj[value]?.color);
  return <Tag value={value} color={LOW} textColor={HIGH} {...props} />;
};

const testCaseObj = {
  PASS: {
    color: 'SUCCESS',
  },
  FAILED: {
    color: 'ERROR',
  },
};

export const TestCaseChip = ({value, status}) => {
  const {HIGH, LOW} = useAccentColor(testCaseObj[status]?.color);
  return <Tag value={value} color={LOW} textColor={HIGH} />;
};
