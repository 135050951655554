import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const KRAInputForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/keyresponsibilityareas',
    eventSourceId: 'keyresponsibilityareas',
    ...props,
  });
  return (
    <Form
      api={`/keyresponsibilityareas/${row?._id}`}
      fields={{
        name: 1,
        description: 1,
        department: {name: 1},
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Key Responsibility Area'}}
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        {
          label: 'Description',
          type: 'text',
          field: 'description',
          required: true,
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'name',
          size: 'medium',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditKRAInputForm = props => {
  return (
    <KRAInputForm
      header={{title: 'Edit Key Responsibility Area'}}
      mode="edit"
      {...props}
    />
  );
};
