import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const AssignmentChecklist = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/mastertaskassignments',
    eventSourceId: ['MasterTaskAssignment'],
    ...props,
  });
  const {
    route: {params},
  } = props;

  const {row} = params;

  return (
    <Form
      defaultValues={row}
      header={{title: 'Task Checklists', secondaryTitle: row?.task}}
      onSubmit={onSubmit}
      submitAction="Save"
      {...props}
      layoutFields={[
        {
          field: 'acceptance_criteria',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm={true}
              fields={[
                {
                  type: 'text',
                  field: 'name',
                  placeholder: 'Add checklist item',
                },
              ]}
              columns={[
                {
                  type: 'text',
                  field: 'name',
                  header: 'Checklist',
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};
