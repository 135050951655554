import React from 'react';

import {TabView} from '../../../components/tab/TabView';
import {ClosedFeatureBugsTable, FeatureBugsTable} from './FeatureBugTable';
import {FeatureAssetHeader, FeatureAssetSummary} from '../headers/Header';
import {MarkQaDoneButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {useFetchData} from '../../../controllers/useFetchData';

const MarkResolved = ({feature_id}) => {
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['Feature', 'MarkDone'],
  });

  const {data: featureData = {}} = useFetchData({
    api: `/projectFeatures/${feature_id}`,
    eventSourceId: ['Feature', 'Bug', 'MarkDone'],
  });

  const {sub_status} = featureData;

  return (
    <MarkQaDoneButton
      title={'Mark Done'}
      isMarked={sub_status === 'Passed' || sub_status === 'Failed'}
      onPress={() => {
        invoke({
          uri: `/feature/${feature_id}/markResolved`,
          message: 'Marked done.',
        });
      }}
    />
  );
};

export const FeatureBugTabView = props => {
  const {route: {params} = {}} = props;
  const {feature, project_id, sub_status} = params;

  const {searchValue, onChangeFilter} = useFilter({});
  const tabs = {
    new: {
      label: 'Open',
      view: <FeatureBugsTable searchValue={searchValue} />,
      api: `/projectbugs/open`,
      params: {
        feature_id: feature?._id,
        status: 'open',
      },
      eventSourceId: 'Bug',
    },
    closed: {
      label: 'Closed',
      view: <ClosedFeatureBugsTable searchValue={searchValue} />,
      api: `/projectbugs/closed`,
      params: {
        feature_id: feature?._id,
        status: 'closed',
      },
      eventSourceId: 'Bug',
    },
  };

  return (
    <>
      <FeatureAssetHeader
        title={'Bugs'}
        rightActions={[
          <FeatureAssetSummary
            api={`/features/${feature._id}/feature-bug-summary`}
            eventSourceId={['Bug', 'Task', 'Feature']}
          />,
          <MarkResolved feature_id={feature._id} sub_status={sub_status} />,
        ]}
      />
      <TabView
        tabs={tabs}
        params={params}
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        ]}
        {...props}
      />
    </>
  );
};
