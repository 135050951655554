import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const TaxReopenForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/incometaxes`,
    eventSourceId: 'taxDeclarationAccounts',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={'Re-Open Tax'}
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'ReOpen'}};
      }}
      api={`/taxDeclarationDetail/${row?._id}`}
      mode={'edit'}
      submitAction={'Update'}
      layoutFields={[
        {
          type: 'text',
          field: 'remark',
          label: 'Remark',
          required: true,
        },
      ]}
    />
  );
};
