import { Chip } from '@unthinkable/react-chip';
import {
    Linking,
    TouchableOpacity,
    View,
} from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { Form } from '../../../components/form/Form';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFetchData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const QUODOC = resourceUrl => ({
  header: 'Document',
  align: 'center',
  render: ({row}) => {
    const colors = useTheme('colors');
    return (
      <View style={{gap: 4}}>
        {row?.quoted_document?.map(data => {
          return (
            <TouchableOpacity
              onPress={() => {
                const url = resourceUrl(data);
                Linking.openURL(url, '_blank');
              }}>
              <Chip
                color={colors.INFORMATION_LOW}
                textColor={colors.INFORMATION_HIGH}
                value={data?.filename}
                borderRadius={6}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  },
});

const PROPOSALQUODOC = resourceUrl => ({
  header: 'Document',
  align: 'center',
  render: ({row}) => {
    const colors = useTheme('colors');
    return (
      <View style={{gap: 4}}>
        {row?.proposal_document?.map(data => {
          return (
            <TouchableOpacity
              onPress={() => {
                const url = resourceUrl(data);
                Linking.openURL(url, '_blank');
              }}>
              <Chip
                color={colors.INFORMATION_LOW}
                textColor={colors.INFORMATION_HIGH}
                value={data?.filename}
                borderRadius={6}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  },
});

export const ShowOpportunityQuotation = props => {
  const params = props.route.params;
  const {product, row} = params;
  const {data} = useFetchData({
    api: '/opportunities',
    filter: {
      _id: params?.opportunity?._id,
    },
    fields: {
      _id: 1,
      submitted_quote_description: 1,
      rfr_type: 1,
      proposal_owner: {_id: 1, name: 1, color: 1},
      quoted_hours: 1,
      proposal_link: 1,
      quoted_document: 1,
      quoted_amount: 1,
      proposal_submit_date: 1,
      quoted_amount: 1,
      quoted_document: 1,
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
  });
  return (
    <Table
      data={data}
      renderHeader={() => (
        <TableHeader
          title={'Proposal'}
          actions={
            <>
              <AddButton
                title=" Update Proposal"
                view="opportunity-submit-proposal"
                params={{opportunity: data?.[0]}}
              />
              <AddButton
                title=" Update Quotation"
                view="opportunity-submit-quote"
                params={{opportunity: data?.[0]}}
              />
            </>
          }
        />
      )}
      columns={[
        {
          header: 'Submitted Proposal',
          field: 'quoted_amount',
          type: 'number',
          align: 'center',
          width: 200,
        },
        {
          header: 'Proposal Submitted Date',
          field: 'proposal_submit_date',
          type: 'date',
          align: 'center',
          width: 400,
        },
        {
          header: 'Quoted Document',
          type: 'file',
          field: 'quoted_document',
          fileText: 'view',
          width: 300,
        },
      ]}
    />
  );
};

export const OpportunitySubmitProposal = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });

  const {opportunity} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        ...opportunity,
        proposal_submit_date: new Date(),
      }}
      onSubmit={onSubmit}
      header={'Submit Proposal'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          placeholder: 'Quote Value',
          label: 'Quote Value',
          field: 'quoted_amount',
          type: 'number',
        },
      ]}
    />
  );
};

export const ShowOpportunitySubmitProposal = props => {
  const params = props.route.params;
  const {product, row} = params;
  return (
    <Table
      api={`/opportunities/show-proposal`}
      addOnFilter={{
        _id: params?.opportunity?._id,
      }}
      columns={[
        {
          header: 'Submitted Proposal',
          field: 'quoted_amount',
          type: 'number',
          align: 'center',
          width: 200,
        },
        {
          header: 'Proposal Submitted Date',
          field: 'proposal_submit_date',
          type: 'date',
          align: 'center',
          required: true,
          width: 400,
        },
        {
          header: 'Quoted Document',
          type: 'file',
          field: 'quoted_document',
          fileText: 'view',
          width: 300,
        },
      ]}
    />
  );
};

export const OpportunitySubmitQuote = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  const {opportunity} = params;
  return (
    <Form
      {...props}
      defaultValues={{
        ...opportunity,
        is_proposal_added: true,
      }}
      onSubmit={onSubmit}
      header={'Submit Quotation'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          placeholder: 'Description',
          label: 'Description',
          field: 'submitted_quote_description',
          type: 'textArea',
        },
        {
          placeholder: 'Proposal Owner',
          field: 'proposal_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          placeholder: 'Quoted Hours',
          label: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          size: 6,
          required: true,
        },
        {
          placeholder: 'Paste Link',
          label: 'Paste Link',
          field: 'proposal_link',
          type: 'textArea',
          size: 6,
        },
        {
          placeholder: 'Attatchemnt',
          field: 'quoted_document',
          type: 'file',
          multiple: true,
          required: true,
          size: 6,
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const ShowOpportunitySubmitProposalRestimation = props => {
  const params = props.route.params;
  const {product, row} = params;
  const {data} = useFetchData({
    api: '/opportunities',
    filter: {
      _id: params?.opportunity?._id,
    },
    fields: {
      _id: 1,
      submitted_quote_description: 1,
      rfr_type: 1,
      proposal_owner: {_id: 1, name: 1},
      quoted_hours: 1,
      proposal_link: 1,
      quoted_document: 1,
      quoted_amount: 1,
      proposal_submit_date: 1,
      quoted_amount: 1,
      quoted_document: 1,
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
  });
  return (
    <Table
      data={data}
      renderHeader={() => (
        <TableHeader
          title={'Proposal'}
          actions={
            <>
              <AddButton
                title=" Update Proposal"
                view="opportunity-submit-proposal"
                params={{opportunity: data?.[0]}}
              />
              <AddButton
                title=" Update Quotation"
                view="opportunity-submit-quote"
                params={{opportunity: data?.[0]}}
              />
            </>
          }
        />
      )}
      columns={[
        {
          header: 'Submitted Proposal',
          field: 'quoted_amount',
          type: 'number',
          align: 'center',
          width: 200,
        },
        {
          header: 'Proposal Submitted Date',
          field: 'proposal_submit_date',
          type: 'date',
          align: 'center',
          width: 400,
        },
        {
          header: 'Quoted Document',
          type: 'file',
          field: 'quoted_document',
          fileText: 'view',
          width: 300,
        },
      ]}
    />
  );
};

export const OpportunitySubmitQuoteFeatureForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunityquotations',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {opportunity} = params;
  return (
    <Form
      {...props}
      defaultValues={{
        opportunity: opportunity,
        is_proposal_added: true,
        quote_date: new Date(),
        proposal_owner: employeeId,
      }}
      onSubmit={onSubmit}
      header={'Submit Quotation'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          placeholder: 'Description',
          label: 'Description',
          field: 'submitted_quote_description',
          type: 'textArea',
          required: true,
        },
        {
          placeholder: 'Quoted Hours',
          label: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          required: true,
          // size: 6,
        },
        {
          placeholder: 'Attatchemnt',
          field: 'quoted_document',
          type: 'file',
          multiple: true,
          required: true,
          size: 6,
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const ShowOpportunityQuotationRFQFeature = props => {
  const params = props.route.params;
  const {product, row, fromClosed} = params;
  const {resourceUrl} = useAppStateContext();

  const {data} = useFetchData({
    api: '/opportunityquotations',
    filter: {
      opportunity: params?.opportunity?._id,
    },
    fields: {
      _id: 1,
      submitted_quote_description: 1,
      proposal_owner: {_id: 1, name: 1, color: 1},
      quoted_hours: 1,
      quoted_document: 1,
      quoted_amount: 1,
      quote_date: 1,
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
  });
  return (
    <Table
      data={data}
      renderHeader={() => (
        <TableHeader
          title={'Quotations'}
          actions={
            !fromClosed ? (
              <>
                <AddButton
                  title=" Quotation"
                  view="opportunity-submit-quote-rfq-feature"
                  params={{opportunity: params?.opportunity?._id}}
                />
              </>
            ) : (
              void 0
            )
          }
        />
      )}
      columns={[
        {
          header: 'Submitted On',
          type: 'date',
          field: 'quote_date',
        },
        {
          header: 'Quotation(hrs)',
          field: 'quoted_hours',
          type: 'number',
          align: 'right',
          width: 200,
        },
        QUODOC(resourceUrl),
        {
          header: 'By',
          field: 'proposal_owner',
          type: 'userAvatar',
          width: 80,
        },
      ]}
    />
  );
};

export const OpportunitySubmitProposalFeatureForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunityproposals',
    eventSourceId: 'CustomPlatformOpportunity',
    ...props,
  });

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {opportunity} = params;
  return (
    <Form
      {...props}
      defaultValues={{
        opportunity: opportunity,
        proposal_submit_date: new Date(),
        opportunity_proposal_owner: employeeId,
      }}
      onSubmit={onSubmit}
      header={'Submit Proposal'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          placeholder: 'Proposal Hours',
          label: 'Proposal Hours',
          field: 'proposal_hours',
          type: 'number',
          required: true,
        },
        {
          placeholder: 'Proposal Value(₹)',
          label: 'Proposal Value(₹)',
          field: 'proposal_value',
          type: 'number',
          required: true,
        },
        {
          placeholder: 'Attatchemnt',
          field: 'proposal_document',
          type: 'file',
          multiple: true,
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const ShowOpportunityProposalRFQFeature = props => {
  const params = props.route.params;
  const {product, row, fromClosed} = params;
  const {resourceUrl} = useAppStateContext();

  const {data} = useFetchData({
    api: '/opportunityproposals',
    filter: {
      opportunity: params?.opportunity?._id,
    },
    fields: {
      _id: 1,
      proposal_hours: 1,
      opportunity_proposal_owner: {_id: 1, name: 1, color: 1},
      proposal_value: 1,
      proposal_document: 1,
      proposal_submit_date: 1,
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
  });
  return (
    <Table
      data={data}
      renderHeader={() => (
        <TableHeader
          title={'Proposal'}
          actions={
            !fromClosed ? (
              <>
                <AddButton
                  title=" Proposal"
                  view="opportunity-submit-proposal-rfq-feature"
                  params={{opportunity: params?.opportunity?._id}}
                />
              </>
            ) : (
              void 0
            )
          }
        />
      )}
      columns={[
        {
          header: 'Submitted On',
          type: 'date',
          field: 'proposal_submit_date',
        },
        {
          header: 'Proposal(hrs)',
          field: 'proposal_hours',
          type: 'number',
          align: 'right',
        },
        {
          header: 'Value(₹)',
          field: 'proposal_value',
          type: 'number',
          align: 'center',
        },
        PROPOSALQUODOC(resourceUrl),
        {
          header: 'By',
          field: 'opportunity_proposal_owner',
          type: 'userAvatar',
          width: 80,
        },
      ]}
    />
  );
};
