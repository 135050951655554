import { Row } from '@unthinkable/react-core-components';
import { plural } from 'pluralize';
import React from 'react';
import { Chip } from '../../../components/chip/Chip';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

const RenderResources = ({value}) => {
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      {value?.map(resource => (
        <Chip value={resource.label} />
      ))}
    </Row>
  );
};
const RenderDataEntity = ({value}) => {
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      <Chip value={value?.name} />
    </Row>
  );
};

export const UserAccessForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/userAccesses',
    eventSourceId: ['userAccessAdded'],
  });
  return (
    <Form
      api={`/userAccesses/${row?._id}`}
      fields={{
        user: {name: 1},
        employee: {name: 1},
        access_groups: {
          access_group: {name: 1},
          resources: {label: 1},
          access_level: 1,
        },
        access_roles: {
          role: 1,
          view: 1,
          dataEntity: {name: 1},
        },
        access_rights: {role: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          filter: {
            employee_status: 'Active',
          },
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          label: 'Access Groups',
          field: 'access_groups',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              columns={[
                {
                  header: 'Access Group',
                  field: 'access_group.name',
                  type: 'text',
                },
                {
                  header: 'Access Level',
                  field: 'access_level',
                  type: 'text',
                },
                {
                  header: 'Resources',
                  field: 'resources',
                  render: RenderResources,
                  type: 'text',
                  width: 300,
                },
              ]}
              fields={[
                {
                  label: 'Access Group',
                  field: 'access_group',
                  type: 'autoComplete',
                  api: '/accessGroups',
                  valueField: 'name',
                  suggestionField: 'name',
                },
                {
                  label: 'Access Level',
                  field: 'access_level',
                  type: 'autoComplete',
                  options: ['full', 'partial'],
                },
                {
                  label: 'Resources',
                  field: 'resources',
                  type: 'multiAutoComplete',
                  api: '/activeElements',
                  filter: ({values}) => {
                    return {
                      access_groups: values?.access_group?._id,
                    };
                  },
                  suggestionField: 'label',
                  valueField: 'label',
                  visible: ({values}) => values?.access_level == 'partial',
                },
              ]}
            />
          ),
        },
        {
          label: 'Access Entities',
          field: 'access_roles',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              columns={[
                {
                  header: 'Data Entity',
                  field: 'dataEntity',
                  render: RenderDataEntity,
                  type: 'text',
                  width: 300,
                },
                {
                  header: 'View',
                  field: 'view',
                  type: 'text',
                },
                {
                  header: 'Role',
                  field: 'role',
                  type: 'text',
                },
              ]}
              fields={[
                {
                  label: 'Data Entity',
                  field: 'dataEntity',
                  type: 'autoComplete',
                  api: '/types',
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  label: 'View',
                  field: 'view',
                  type: 'autoComplete',
                  api: props => {
                    const {values: {dataEntity: {name = ''} = {}} = {}} = props;
                    return `/${plural(name)}/metadata/views`;
                  },
                },
                {
                  label: 'Role',
                  field: 'role',
                  type: 'autoComplete',
                  api: props => {
                    const {values: {view, dataEntity: {name = ''} = {}} = {}} =
                      props;
                    return {
                      uri: `/${plural(name)}/metadata/roles`,
                    };
                  },
                  params: props => {
                    const {values: {view, dataEntity: {name = ''} = {}} = {}} =
                      props;
                    return {
                      view,
                    };
                  },
                },
              ]}
            />
          ),
        },
        {
          label: 'Access Role',
          field: 'access_rights',
          type: 'multiAutoComplete',
          api: '/accessroles',
          suggestionField: 'role',
          valueField: 'role',
        },
      ]}
      {...props}
    />
  );
};

export const AddUserAccess = props => {
  return <UserAccessForm header="Add User Access" {...props} />;
};

export const UpdateUserAccess = props => {
  return <UserAccessForm mode="edit" header="Update User Access" {...props} />;
};
