import React from 'react';
import { Table } from '../../../components/table/Table';
import { TabView } from '../../../components/tab';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { useFilter } from '../../../controllers/useFilter';
import { Row } from '@unthinkable/react-core-components';

export const VoucherLineItemTable = props => {
    const {
        route: { params } = {}} = props;
    const { lineItemFilter = {} } = params;
    return (
        <Table
            {...props}
            limit={50}
            api={`/voucherlineitemdetails`}
            addOnFilter = {lineItemFilter}
            eventSourceId="voucherlineitem"
            columns={[
                {
                    field: 'voucher_id.voucher_no',
                    header: 'Number',
                },
                {
                    type: 'date',
                    field: 'date',
                    header: 'Date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 200,
                },

                {
                    field: 'narration',
                    header: 'Narration',
                    width: 250,
                },
                {
                    field: 'account.name',
                    header: 'Account',
                    width: 200,
                },
                {
                    field: 'entity.name',
                    header: 'Entity',
                    width: 200,
                },
                {
                    field: 'department.name',
                    header: 'Department',
                    width: 200,
                },
                {
                    field: 'cr_amount',
                    header: 'Credit',
                    type: "currency",
                    width: 150,
                },
                {
                    aggregate : true,
                    field: 'dr_amount',
                    header: 'Debit',
                    type: 'currency',
                    formatOptions: {
                        maximumFractionDigits: 1,
                    },
                },
            ]}
        />
    );
};

const VoucherLineItemTab = props => {
    let { route: { params } = {}, navigation } = props;
    const { filterValues, searchValue, onChangeFilter, applyFilter } = useFilter({
        // params: {
        //   period: params.period || getPreviousMonth(),
        // },
    });
    const { filter, params: filterParams = {} } = filterValues;
    const { organization, product, period, ...rest } = params;
    params = {
        ...filterParams,
        ...rest,
        product,
        organization,
    };

    const tabs = {
        myAttendance: {
            label: 'Voucher',
            view: (
                <VoucherLineItemTable
                    filter={filter}
                    filterParams={filterParams}
                    params={params}
                />
            ),
            //   actions: [
            //     <GroupFilter
            //       filterValues={filterValues}
            //       applyFilter={applyFilter}
            //       filters={[
            //         {
            //           type: 'autoComplete',
            //           label: 'Product',
            //           api: '/products',
            //           placeholder: 'Select',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //           field: 'product',
            //         },
            //         {
            //           type: 'autoComplete',
            //           label: 'Location',
            //           api: '/offices',
            //           placeholder: 'Select',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //           field: 'location',
            //         },
            //         {
            //           type: 'autoComplete',
            //           label: 'Organization',
            //           api: '/organizations',
            //           placeholder: 'Select',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //           field: 'organization',
            //         },
            //         {
            //           type: 'date',
            //           field: 'voucher_date',
            //           // asParam: true,
            //           label: 'Voucher Date',
            //         },
            //       ]}
            //     />,
            //   ],
            eventSourceId: 'voucherlineitem',
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
        />
    );
};

export default VoucherLineItemTab;
