import React from 'react';
import {
  FileInput as FileInputComponent,
  FileRenderer as FileRendererComponent,
} from '@unthinkable/react-file-upload';
import {useStyles} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {FileInputStyles, MultipleAttachmentPreviewStyles} from './theme';
import {AttachmentCountRender} from '../../table/Renders';

export const FileInput = props => {
  let {upload, resourceUrl} = useAppStateContext();
  const styles = useStyles(FileInputStyles);
  const multipleAttachmentsPreviewTheme = useStyles(
    MultipleAttachmentPreviewStyles,
  );

  return (
    <FileInputComponent
      resourceUrl={resourceUrl}
      upload={upload}
      styles={styles}
      valueStyle={multipleAttachmentsPreviewTheme}
      inlineImageComponent={({value, ...props}) => {
        return (
          <AttachmentCountRender
            value={value}
            suffix={value?.length > 1 ? 'attachments' : 'attachment'}
            {...props}
          />
        );
      }}
      {...props}
    />
  );
};

export const FileRenderer = props => {
  const styles = useStyles(FileInputStyles);
  let {resourceUrl} = useAppStateContext();
  return (
    <FileRendererComponent
      styles={styles}
      resourceUrl={resourceUrl}
      {...props}
    />
  );
};
