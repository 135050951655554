import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {MultiOptionsSwitch} from '../../../app-components/renders/multiOptionsSwitch/MultiOptionsSwitch';

const options = {
  Mobile: {
    value: 'Mobile',
    label: 'Mobile',
  },
  Tab: {
    value: 'Tab',
    label: 'Tablet',
  },
  Desktop: {
    value: 'Desktop',
    label: 'Desktop',
  },
};

export const SupportedDevicesForm = props => {
  const {
    route: {params},
  } = props;

  const {project} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Project',
    uri: '/projects',
    ...props,
  });

  return (
    <Form
      header="Supported Devices"
      mode="edit"
      api={`/projects/${project?._id}`}
      fields={{
        deviceTypes: 1,
        primaryDeviceType: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Supported Devices',
          field: 'deviceTypes',
          render: MultiOptionsSwitch,
          options: [
            {id: 'Mobile', label: 'Mobile'},
            {id: 'Tab', label: 'Tablet'},
            {id: 'Desktop', label: 'Desktop'},
          ],
          onChangeValue: (value, e, {setFieldValue}) => {
            if (!value?.length) {
              setFieldValue('primaryDeviceType', void 0);
            }
            if (value?.length === 1) {
              setFieldValue('primaryDeviceType', value[0]);
            }
          },
          required: true,
        },
        {
          visible: ({values}) => {
            return values?.deviceTypes?.length;
          },
          label: 'Primary Device Type',
          field: 'primaryDeviceType',
          type: 'radioGroup',
          options: ({values}) => {
            const {deviceTypes = []} = values;

            let primaryDeviceValues = [];

            deviceTypes.forEach(deviceType => {
              primaryDeviceValues.push(options[deviceType]);
            });
            return primaryDeviceValues;
          },
          required: true,
        },
      ]}
      {...props}
    />
  );
};
