import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Table} from '../../../components/table/Table';
import {RenderLinkedInUrl} from './CustomRendersCollection';
import {Linking} from '@unthinkable/react-core-components';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const messageCount = ({params, navigation, addOnFilter}) => ({
  render: CountCellRender,
  width: 150,
  header: 'Actions',
  align: 'right',
  count_field: 'inmail_message_count',
  released_count_field: 'inmail_message_sent_count',
  responsive: 'sm',
  visible: () => {
    return addOnFilter?.showActive;
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contact-messages`, {
      campaign_id: params.campaign_id,
      contact_id: {_id: row.contact_id},
      campaign_contact_id: {_id: row._id, name: row.contact_id.name},
    });
  },
});

const RenderName = props => {
  const {row, styles = {}} = props;
  const {potential_customer = {}} = row || {};
  return (
    <PrimaryColumnCard
      primaryTitle={potential_customer?.name}
      variant="secondary"
      titleOnPress={() => {
        potential_customer?.website &&
          Linking.openURL(
            potential_customer?.website.startsWith('https')
              ? potential_customer?.website
              : `https://${potential_customer?.website}`,
            '_blank',
          );
      }}
      items={[
        {
          value: potential_customer?.employee_count?.number,
          backgroundColor: potential_customer?.employee_count?.color,
          numberOfLines: 1,
          // onPress
        },
        {
          value: potential_customer?.country?.name,
          backgroundColor: potential_customer?.country?.color,
          numberOfLines: 1,
        },
        {
          value: potential_customer?.industry?.name,
          backgroundColor: potential_customer?.industry?.name?.color,
          numberOfLines: 1,
        },
      ]}
    />
  );
};

export const CampaignContactDetails = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
  } = props;
  let api = addOnFilter?.api ? addOnFilter?.api : `/campaigncontacts`;
  return (
    <Table
      api={api}
      addOnFilter={addOnFilter}
      fields={{
        _id: 1,
        contact_id: {
          designation: 1,
          name: 1,
          linkedin_url: 1,
          first_name: 1,
          last_name: 1,
        },
        potential_customer: {
          employee_count: {number: 1},
          country: {name: 1},
          name: 1,
          website: 1,
          owner: 1,
          lead_sore: 1,
          last_communication: 1,
          channel: {
            name: 1,
            color: 1,
          },
        },
      }}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
        },
        {
          header: 'Organisation',
          render: RenderName,
        },
        {
          header: 'Company size',
          field: 'potential_customer.employee_count.number',
          type: 'text',
          width: 150,
        },
        messageCount({navigation, params, addOnFilter}),
        {
          header: 'Reason',
          field: 'reason',
          type: 'text',
          width: 250,
          visible: () => {
            return addOnFilter?.status === 'dropped';
          },
        },
        {
          header: 'Next Action Date',
          field: 'next_campaign_action.actualScheduledOn',
          type: 'date',
          align: 'right',
          width: 250,
          formatOptions: {
            format: 'DD MMM',
          },
          visible: () => {
            return addOnFilter?.showActive;
          },
        },
      ]}
      {...props}
    />
  );
};
