import React from 'react';
import {JobOpeningTab} from './JobOpeningsTab';
import {ActiveCandidateTabs, ClosedCandidateTabs} from './AllCandidateTabs';
import {HrRecruitmentTab} from './HrRecruitmentTab';
import {ReferralTabs} from './RefferalTabs';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';

const recruitmentMenus = [
  {
    label: 'Job Opening',
    view: <JobOpeningTab />,
  },
  {
    label: 'Active Candidates',
    view: <ActiveCandidateTabs />,
  },
  {
    label: 'Closed Candidates',
    view: <ClosedCandidateTabs />,
  },
  {
    label: 'Refferals',
    view: <ReferralTabs />,
  },
  {
    label: 'Recruiters',
    //   view: <ResourceAllocationTab />,
  },
];

export const HrRecruitmentMenu = props => {
  const {
    route: {params},
  } = props;
  return <LeftMenuView {...props} params={params} menus={recruitmentMenus} />;
};
