import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const OrganizationCategoryForm = props => {
  const params = props.route.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/organizationcategories',
    eventSourceId: 'OrganizationCategory'
  });
  return (
    <Form
      api={`/organizationcategories/${row?._id}`}
      onSubmit={onSubmit}
      header={props.header}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Score',
          field: 'score',
          type: 'number',
        },
      ]}
      {...props}
    />
  );
};

export const AddOrganizationCategoryForm = props => {
  return (
    <OrganizationCategoryForm header="Add Organization Category" {...props} />
  );
};

export const UpdateOrganizationCategoryForm = props => {
  return (
    <OrganizationCategoryForm
      mode="edit"
      header="Update Organization Category"
      {...props}
    />
  );
};
