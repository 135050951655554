import React, {useEffect, useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {
  Col,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';

import {EmailFormStyles} from './theme';
import {ResetPasswordForm} from './ResetPasswordForm';
import {useResetPassword} from '../../../modules/login/controllers';
import {OTPInput} from './OTPInput';

const Counter = ({
  resendCodeTextStyle,
  resendButtonTextStyle,
  onForgotPassword,
  resendTimerTextStyle,
  email,
}) => {
  const toast = useToast();
  const resendCodeInSec = 30;
  const [timeRemaining, setTimeRemaining] = useState(resendCodeInSec);
  const [showResendCodeButton, setShowResendCodeButton] = useState(true);

  useEffect(() => {
    let intervalId;
    if (timeRemaining > 0) {
      intervalId = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [timeRemaining]);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const resendDisabled = timeRemaining > 0;

  const handleResendClick = async () => {
    const apiResult = await onForgotPassword({email});
    if (apiResult === true) {
      setTimeRemaining(resendCodeInSec);
    } else if (apiResult?.error) {
      setShowResendCodeButton(false);
      toast({message: apiResult.error, type: 'Error'});
    }
  };

  return (
    <Col gap={54} style={{justifyContent: 'space-between', height: 90}}>
      {showResendCodeButton && (
        <Row style={{...resendCodeTextStyle, justifyContent: 'space-between'}}>
          <Text style={{}}>Didn’t Receive Code?</Text>
          {resendDisabled ? (
            <Text style={[resendCodeTextStyle, resendTimerTextStyle]}>
              Resend Code in {formatTime(timeRemaining)}
            </Text>
          ) : (
            <TouchableOpacity onPress={handleResendClick}>
              <Text style={resendButtonTextStyle}>Resend Code</Text>
            </TouchableOpacity>
          )}
        </Row>
      )}
    </Col>
  );
};

export const OtpForm = props => {
  const {
    route: {params},
  } = props;
  const {email} = params || {};
  const navigation = useNavigation();

  const {onForgotPassword, onVerifyOtp} = useResetPassword({
    forgotPasswordApi: '/users/forgot-password',
    verifyOtpApi: '/users/check-otp',
  });

  const {resendCodeTextStyle, resendButtonTextStyle, resendTimerTextStyle} =
    useStyles(EmailFormStyles);

  const [data, updateData] = useState({});

  const verifyOtp = async () => {
    const otpResult = await onVerifyOtp({...data, email});
    if (otpResult) {
      navigation.navigate('password-form', {...data, email});
    }
  };

  return (
    <ResetPasswordForm
      heading={'Verify Account'}
      subHeading={`Code has been sent to ${email}. Enter the code to verify your account.`}
      buttonText={`Verify Code`}
      onButtonPress={verifyOtp}
      disabled={data?.otp?.length != 6}>
      <Col
        style={{
          justifyContent: 'space-between',
          flex: 1,
        }}>
        <OTPInput
          autoFocus
          onChange={text => {
            updateData({...data, otp: text});
          }}
          onSubmitEditing={verifyOtp}
        />

        <Counter
          resendCodeTextStyle={resendCodeTextStyle}
          resendButtonTextStyle={resendButtonTextStyle}
          resendTimerTextStyle={resendTimerTextStyle}
          onForgotPassword={onForgotPassword}
          email={email}
        />
      </Col>
    </ResetPasswordForm>
  );
};
