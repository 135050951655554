import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';

const PackageDetailComputation = fetch => ({
    'Select Template for data': {
        compute: (value) => {
            console.log("value")
            const { parent } = value;
            const {
                attendance_base_components = [],
                ctc_amount = undefined,
                deductions_components = [],
                fix_base_components = [],
                performance_base_components = [],
            } = parent || {};
            return {
                ctc_amount,
                artificialTotalAmount: 0,
                attendance_base_components,
                deductions_components,
                fix_base_components,
                performance_base_components,
            }
        },
        multi: true,
        dependencies: ['parent'],
    },
    'copy parent onchange default': {
        compute: async (value) => {
            if (value?.parent) {
                const { data: salaryTemplateDetail } = await fetch({
                    uri: '/artificialsalarycomponents',
                    props: {
                        filter: {
                            _id: parent?._id,
                        },
                        fields: {
                            _id: 1,
                            parent: 1,
                            from_date: 1,
                            fixed_salary: 1,
                            incentive_salary: 1,
                            mark_salary: 1,
                            code: 1,
                            to_date: 1,
                            code_suggestion: {
                                _id: 1,
                                code: 1,
                            },
                            ctc_amount: 1,
                            attendance_base_components: {
                                _id: 1,
                                salary_component_id: { _name: 1 },
                                from_date: 1,
                                to_date: 1,
                                amount: 1,
                            },
                            performance_base_components: {
                                _id: 1,
                                salary_component_id: { name: 1 },
                                from_date: 1,
                                to_date: 1,
                                link_to_project: 1,
                                sequence: 1,
                                amount: 1,
                                level_details: {
                                    _id: 1,
                                    level: { name: 1 },
                                    amount: 1,
                                    no_of_resources: 1,
                                    per_head_amount: 1,
                                    rate: 1,
                                    target: 1,
                                },
                            },
                            deductions_components: {
                                _id: 1,
                                salary_component_id: { name: 1 },
                                from_date: 1,
                                to_date: 1,
                                formula: 1,
                                sequence: 1,
                                amount: 1,
                                salary_detailId: 1,
                            },
                            fix_base_components: {
                                _id: 1,
                                salary_component_id: { name: 1 },
                                from_date: 1,
                                to_date: 1,
                                formula: 1,
                                amount: 1,
                                sequence: 1,
                            },
                            include_in_gross_id: { _id: 1, name: 1 },
                            include_in_id: { _id: 1, name: 1 },
                        },
                        only: true,
                    },
                });

                //props: queryProps,
                if (salaryTemplateDetail) {
                    delete salaryTemplateDetail._id;
                    delete salaryTemplateDetail.parent;

                    if (salaryTemplateDetail?.attendance_base_components) {
                        for (let i = 0; i < salaryTemplateDetail.attendance_base_components.length; i++) {
                            delete salaryTemplateDetail.attendance_base_components[i]._id;
                        }
                    }
                    if (salaryTemplateDetail?.deductions_components) {
                        for (let i = 0; i < salaryTemplateDetail.deductions_components.length; i++) {
                            delete salaryTemplateDetail.deductions_components[i]._id;
                        }
                    }
                    if (salaryTemplateDetail?.fix_base_components) {
                        for (let i = 0; i < salaryTemplateDetail.fix_base_components.length; i++) {
                            delete salaryTemplateDetail.fix_base_components[i]._id;
                        }
                    }
                    if (salaryTemplateDetail?.performance_base_components) {
                        for (let i = 0; i < salaryTemplateDetail.performance_base_components.length; i++) {
                            delete salaryTemplateDetail.performance_base_components[i]._id;
                        }
                    }
                    return { ...salaryTemplateDetail };
                }
            }
        },
        dependencies: ['parent'],
    },
    artificialTotalAmount: {
        compute: value => {
            const { performance_base_components = [], attendance_base_components = [], deductions_components = [] } = value
            const response = {};
            let linkStatus = false;
            let totalAmount = 0;
            if (performance_base_components?.length > 0) {
                for (const component of performance_base_components) {
                    if (component?.link_to_project) {
                        linkStatus = true;
                        if (!component?.isNotCtcIncluded) {
                            totalAmount += component?.amount * 1 || 0
                        }
                    }
                    if (!component?.linkStatus && !component?.isNotCtcIncluded) {
                        totalAmount += component?.amount * 1 || 0
                    }
                }
            }
            response['artificialTotalAmount'] =
                ((attendance_base_components?.length &&
                    attendance_base_components.reduce((amount, component) => {
                        if (!component.isNotCtcIncluded) {
                            amount = amount + ((component.amount && component.amount * 1) || 0);
                        }
                        return amount;
                    }, 0)) ||
                    0) +
                totalAmount +
                ((deductions_components?.length &&
                    deductions_components.reduce((amount, component) => {
                        let total = 0;
                        if (
                            component?.salary_component_id?._id &&
                            (component.salary_component_id._id === '53ae739a79df44bac9b0b9f1' ||
                                component.salary_component_id._id === '53ae73a779df44bac9b0ba0a' ||
                                component.salary_component_id._id === '53ae738f79df44bac9b0b9da' ||
                                component.salary_component_id._id === '6124d7ff9b6ed50d4422690a' ||
                                component.salary_component_id._id === '53ae739d79df44bac9b0b9f7' ||
                                component.salary_component_id._id === '6295aaa19028f27e7e904a77')
                        ) {
                            if (!component?.isNotCtcIncluded) {
                                total = (component?.amount * 1) || 0;
                            }
                        }
                        return amount + total;
                    }, 0)) ||
                    0);
            return { ...response };
        },
        multi: true,
        dependencies: [
            'attendance_base_components',
            'performance_base_components',
            'deductions_components',
            'fix_base_components',
            '_set_ctc',
        ],
    },
    fixed_salary: {
        compute: value => {
            const { attendance_base_components, deductions_components } = value || {}
            let response = {};
            response['fixed_salary'] =
                ((
                    attendance_base_components &&
                    attendance_base_components.reduce((amount, component) => {
                        if (!component.isNotCtcIncluded) {
                            amount = amount + ((component?.amount * 1) || 0);
                        }
                        return amount;
                    }, 0)) ||
                    0) +
                ((deductions_components &&
                    deductions_components.reduce((amount, component) => {
                        let total = 0;
                        //Esi employer,gratuity, pf employer ,health insurance
                        if (
                            component?.salary_component_id?._id &&
                            (component.salary_component_id._id === '53ae739a79df44bac9b0b9f1' ||
                                component.salary_component_id._id === '53ae73a779df44bac9b0ba0a' ||
                                component.salary_component_id._id === '53ae738f79df44bac9b0b9da' ||
                                component.salary_component_id._id === '6124d7ff9b6ed50d4422690a' ||
                                component.salary_component_id._id === '53ae739d79df44bac9b0b9f7' ||
                                component.salary_component_id._id === '6295aaa19028f27e7e904a77')
                        ) {
                            if (!component.isNotCtcIncluded) {
                                total = (component.amount && component.amount * 1) || 0;
                            }
                        }
                        return amount + total;
                    }, 0)) ||
                    0);
            return { ...response };
        },
        multi: true,
        dependencies: [
            'attendance_base_components',
            'deductions_components',
        ],
    },
    incentive_salary: {
        compute: value => {
            const response = {};
            response['incentive_salary'] =
                value?.performance_base_components &&
                value.performance_base_components.reduce((amount, component) => {
                    if (!component.isNotCtcIncluded) {
                        amount = amount + ((component.amount && component.amount * 1) || 0);
                    }
                    return amount;
                }, 0);
            return { ...response };
        },
        multi: true,
        dependencies: [
            'performance_base_components',
        ],
    },
    'set data of attendance based components': {
        compute: async value => {
            console.log("value>>>>>>>>>>>>>>",JSON.stringify(value))
            const { employee_id, ctc_amount, working_days, is_pf_included, variable_perc } = value
            if (value?.parent?.is_master_component) {
                return;
            }

            if (!value.ctc_amount) {
                return;
            }
            const response = {};
            if (employee_id) {
                const { data: employeeData } = await fetch({
                    uri: '/getAttendanceBasedComponents',
                    props: {
                        filter: {
                            _id: employee_id,
                        },
                        fields: {
                            organization: 1,
                            employee_level: {
                                insurance_unthinkable: 1,
                                insurance_daffodil: 1,
                                books_and_periodicals: 1,
                                professional_development: 1,
                                telephone_internet: 1,
                                lta: 1,
                                gift_item: 1,
                                sodexho_meal_vouchers: 1,
                                medical_reimbersement: 1,
                                child_education: 1,
                                uniform_allowance: 1,
                                hra_perc: 1,
                                basic_perc: 1,
                                gratuity_perc: 1,
                            }
                        },
                        only: true,
                    },
                });
                if (!working_days) {
                    return;
                }
                const { employee_level, organization } = employeeData || {}
                const {
                    insurance_unthinkable,
                    insurance_daffodil,
                    books_and_periodicals,
                    professional_development,
                    telephone_internet,
                    lta,
                    gift_item,
                    sodexho_meal_vouchers,
                    medical_reimbersement,
                    child_education,
                    uniform_allowance,
                    hra_perc,
                    basic_perc,
                    gratuity_perc
                } = employee_level || {}
                const saturdayWorkingAllowanceFor55Wd = (ctc_amount * 1 / 22) * 2;
                const saturdayWorkingAllowanceFor6Wd = (ctc_amount * 1 / 22) * 4;
                let artificialTotalAmount = 0;
                let ctcAmountVariableDeduction = ctc_amount - ((variable_perc && ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) || 0);
                const ctcAmountVariableDeductionForBasic = ctc_amount - ((variable_perc && ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) || 0);
                let noChangeHardComponent = [
                    '53ae736b79df44bac9b0b9b6', //Basic
                    '53ae738479df44bac9b0b9b9', //HRA
                    '5bb72626d79fbb677289b7f2', //Saturday Working Allowance
                    '53ae738479df44bac9b0b9bc', //Transport Allowance
                    '53ae738f79df44bac9b0b9da', //PF Employer //Deductions
                    '53ae73a779df44bac9b0ba0a', //Gratuity //Deductions
                    '53ae739d79df44bac9b0b9f7', //Health Insurance //Deductions
                    '53ae738879df44bac9b0b9c6', //PF Employer
                    '53ae738879df44bac9b0b9c9'  //Labour welfare Fund
                ];
                let changeableHardComponent = [
                    '55926c8e75d1f19476f9176c', //Uniform Allowance
                    '55926ce675d1f19476f91800', //Children Education Allowance
                    '53ae73a079df44bac9b0b9fd', //Medical Reimbursement
                    '55addc9c99e5d54e393e8420', //Sodexho Meal Voucher
                    '55926e6875d1f19476f91cef', //Gift Allowance
                    '55926fec75d1f19476f9270c', //leave trave allowance
                    '55926d5075d1f19476f91935', //Telephone & Internet Allowance
                    '55926d9a75d1f19476f91a10', //Professional Development Allowance
                    '55926dd775d1f19476f91b83', //Books & periodicals Allowance
                    '54097fe45d20628a1de84d23', //Special Allowance
                    '53ae760779df44bac9b0ba10'  // Delivery incentive Project

                ];
                const components = {};
                let insuranceAmount = 0
                let amountToSeperate = 0
                const variableAmount = ((variable_perc && (ctc_amount * variable_perc) / 100) || 0).toFixed(0) * 1;
                if (working_days === 5) {
                    ctcAmountVariableDeduction =
                        ctc_amount - ((variable_perc && ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) || 0);
                } else if (working_days === 5.5) {
                    ctcAmountVariableDeduction =
                        ctc_amount - ((variable_perc && ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) || 0) - (saturdayWorkingAllowanceFor55Wd.toFixed(0) * 1);
                } else if (working_days === 6) {
                    ctcAmountVariableDeduction =
                        ctc_amount - ((variable_perc && ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) || 0) - (saturdayWorkingAllowanceFor6Wd.toFixed(0) * 1);
                }
                const { data: salaryComponentData } = await fetch({
                    uri: '/salarycomponents',
                    props: {
                        filter: {
                            _id: { $in: [...noChangeHardComponent, ...changeableHardComponent] },
                        },
                    },
                });
                if (salaryComponentData?.length > 0) {
                    for (const salaryComponent of salaryComponentData) {
                        if (salaryComponent?._id) {
                            components[salaryComponent._id] = salaryComponent
                        }
                    }
                }
                const basicTemporaryAmount =
                    (
                        (ctcAmountVariableDeductionForBasic *
                            ((basic_perc) ||
                                0)) /
                        100
                    ).toFixed(0) * 1;
                const gratuityPerc =
                    gratuity_perc ||
                    0;
                const hraAmount = ((basicTemporaryAmount * hra_perc) / 100).toFixed(0) * 1

                const attendance_base_components = [
                    {
                        salary_component_id: {
                            ...components['53ae736b79df44bac9b0b9b6'],
                        },
                        ctc_percentage: basic_perc,
                        amount: basicTemporaryAmount
                    },
                    {
                        salary_component_id: {
                            ...components['53ae738479df44bac9b0b9b9'],
                        },
                        ctc_percentage: hra_perc,
                        amount: hraAmount
                    },
                    {
                        salary_component_id: { ...components['53ae738479df44bac9b0b9bc'] },
                        amount: 1600,
                    },
                ];
                const deductions_components = [
                    {
                        salary_component_id: { ...components['53ae73a779df44bac9b0ba0a'] },
                        amount:
                            ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) *
                            1
                    },
                    {
                        salary_component_id: { ...components["53ae738879df44bac9b0b9c9"] },
                        amount: 25
                    }
                ];

                const performance_base_components = []

                if (
                    organization == '5a3d06900731bf3c3668c6a5' ||
                    organization == '60476b96a4e05f4e4e3e7455'
                ) {
                    insuranceAmount = insurance_daffodil || 0;
                } else if (
                    organization == '5a3d06b90731bf3c3668c6a6' ||
                    organization == '60476bcbcada254e24c91e56'
                ) {
                    insuranceAmount = insurance_unthinkable || 0;
                }
                if (is_pf_included) {
                    deductions_components.push({
                        salary_component_id: { ...components['53ae738f79df44bac9b0b9da'] },
                        amount: 1800,
                    });
                    deductions_components.push({
                        salary_component_id: { ...components["53ae738879df44bac9b0b9c6"] },
                        amount: 1800,
                    });
                }
                if (working_days === 5) {
                    artificialTotalAmount =
                        1600 +
                        (is_pf_included ? 1800 : 0) +
                        basicTemporaryAmount * 1 +
                        ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
                        ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
                        insuranceAmount * 1 +
                        (variableAmount || 0);
                    amountToSeperate =
                        ctcAmountVariableDeduction -
                        (1600 +
                            (is_pf_included ? 1800 : 0) +
                            basicTemporaryAmount * 1 +
                            ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
                            ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
                            insuranceAmount * 1);
                } else if (working_days === 5.5) {
                    amountToSeperate =
                        ctcAmountVariableDeduction -
                        (1600 +
                            (is_pf_included ? 1800 : 0) +
                            basicTemporaryAmount * 1 +
                            ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
                            ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
                            insuranceAmount * 1);
                    attendance_base_components.push({
                        salary_component_id: { ...components['5bb72626d79fbb677289b7f2'] },
                        amount: saturdayWorkingAllowanceFor55Wd.toFixed(0) * 1,
                    });
                } else if (working_days === 6) {
                    amountToSeperate =
                        ctcAmountVariableDeduction -
                        (1600 +
                            (is_pf_included ? 1800 : 0) +
                            basicTemporaryAmount * 1 +
                            ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
                            ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
                            insuranceAmount * 1);
                    attendance_base_components.push({
                        salary_component_id: { ...components['5bb72626d79fbb677289b7f2'] },
                        amount: saturdayWorkingAllowanceFor6Wd.toFixed(0) * 1,
                    });
                }
                if (amountToSeperate < 0) {
                    amountToSeperate = 0;
                }
                if (insuranceAmount) {
                    deductions_components.push({
                        salary_component_id: { ...components['53ae739d79df44bac9b0b9f7'] },
                        amount: insuranceAmount,
                    });
                }
                let handleCheck = ({ componentDetails, componentAmount }) => {
                    amountToSeperate = amountToSeperate * 1;
                    if (componentAmount && amountToSeperate > componentAmount) {
                        amountToSeperate = (amountToSeperate - componentAmount) * 1;
                        artificialTotalAmount += componentAmount || 0;
                        attendance_base_components.push({
                            salary_component_id: { ...componentDetails },
                            amount: componentAmount,
                        });
                    } else {
                        if (componentDetails?._id == '54097fe45d20628a1de84d23') {
                            artificialTotalAmount += (amountToSeperate && amountToSeperate * 1) || 0;
                            attendance_base_components.splice(3, 0, {
                                salary_component_id: { ...componentDetails },
                                amount: amountToSeperate && amountToSeperate * 1,
                            });
                        }
                    }
                };
                for (let component of changeableHardComponent) {
                    if (component == '55926c8e75d1f19476f9176c') {
                        handleCheck({
                            componentDetails: components["55926c8e75d1f19476f9176c"],
                            componentAmount: uniform_allowance || 0
                        });
                    } else if (component == '55926ce675d1f19476f91800') {
                        handleCheck({
                            componentDetails: components["55926ce675d1f19476f91800"],
                            componentAmount: child_education || 0
                        });
                    } else if (component == '53ae73a079df44bac9b0b9fd') {
                        handleCheck({
                            componentDetails: components["53ae73a079df44bac9b0b9fd"],
                            componentAmount: medical_reimbersement || 0
                        });
                    } else if (component == '55addc9c99e5d54e393e8420') {
                        handleCheck({
                            componentDetails: components["55addc9c99e5d54e393e8420"],
                            componentAmount: sodexho_meal_vouchers || 0
                        });
                    } else if (component == "55926e6875d1f19476f91cef") {
                        handleCheck({
                            componentDetails: components["55926e6875d1f19476f91cef"],
                            componentAmount: gift_item || 0
                        });
                    } else if (component == '55926fec75d1f19476f9270c') {
                        handleCheck({
                            componentDetails: components["55926fec75d1f19476f9270c"],
                            componentAmount: lta || 0
                        });
                    } else if (component == '55926d5075d1f19476f91935') {
                        handleCheck({
                            componentDetails: components["55926d5075d1f19476f91935"],
                            componentAmount: telephone_internet || 0
                        });
                    } else if (component == '55926d9a75d1f19476f91a10') {
                        handleCheck({
                            componentDetails: components["55926d9a75d1f19476f91a10"],
                            componentAmount: professional_development || 0
                        });
                    } else if (component == '55926dd775d1f19476f91b83') {
                        handleCheck({
                            componentDetails: components["55926dd775d1f19476f91b83"],
                            componentAmount: books_and_periodicals || 0
                        });
                    } else if (component == '54097fe45d20628a1de84d23') {
                        handleCheck({
                            componentDetails: components["54097fe45d20628a1de84d23"],
                            componentAmount: amountToSeperate || 0
                        });
                    } else if (component == '53ae760779df44bac9b0ba10') {
                        if (variableAmount) {
                            performance_base_components.push({
                                salary_component_id: { ...components['53ae760779df44bac9b0ba10'] },
                                amount: variableAmount,
                            });
                        }
                    }
                }
                response["acc_automated_component_breakdown"] = true;
                response["attendance_base_components"] = attendance_base_components;
                response["performance_base_components"] = performance_base_components;
                response["deductions_components"] = deductions_components;
            }
            return response
        },
        multi: true,
        dependencies: ["ctc_amount", "is_pf_included", "variable_perc"]
    },
    package_review_date: {
        compute: value => {
            if (value?.to_date) {
                const package_review_date = new Date(new Date(value.to_date).setDate(new Date(value.to_date).getDate() + 1));
                return package_review_date;
            }
        },
        dependencies: ['to_date'],
    },
    nestedComputations: {
        attendance_base_components: {
            'set from and to date': {
                compute: (value, { _parentValues }) => {
                    const response = {};
                    if (!value.from_date) {
                        response["from_date"] = _parentValues.from_date;
                    }
                    if (!value.to_date) {
                        response["to_date"] = _parentValues.to_date;
                    }
                    return response
                },
                multi: true,
                dependencies: ['_id', '_parentValues.from_date', '_parentValues.to_date'],
            },
        },
        deductions_components: {
            'set from and to date': {
                compute: (value, { _parentValues }) => {
                    const response = {};
                    if (!value.from_date) {
                        response["from_date"] = _parentValues.from_date;
                    }
                    if (!value.to_date) {
                        response["to_date"] = _parentValues.to_date;
                    }
                    return response
                },
                multi: true,
                dependencies: ['_id', '_parentValues.from_date', '_parentValues.to_date'],
            },
        },
        fix_base_components: {
            'set from and to date': {
                compute: (value, { _parentValues }) => {
                    const response = {};
                    if (!value.from_date) {
                        response["from_date"] = _parentValues.from_date;
                    }
                    if (!value.to_date) {
                        response["to_date"] = _parentValues.to_date;
                    }
                    return response
                },
                multi: true,
                dependencies: ['_id', '_parentValues.from_date', '_parentValues.to_date'],
            },
        },
        performance_base_components: {
            amount: {
                compute: (value) => {
                    if (value.level_details) {
                        let amount = value.level_details.reduce((accum, doc) => accum + Number(doc.amount) || 0, 0);
                        return amount;
                    }
                },
                dependencies: ['level_details'],
            },
            'set from and to date': {
                compute: (value, { _parentValues }) => {
                    const response = {};
                    if (!value.from_date) {
                        response["from_date"] = _parentValues.from_date;
                    }
                    if (!value.to_date) {
                        response["to_date"] = _parentValues.to_date;
                    }
                    return response
                },
                multi: true,
                dependencies: ['_id', '_parentValues.from_date', '_parentValues.to_date'],
            },
            nestedComputations: {
                level_details: {
                    per_head_amount: {
                        compute: (value) => {
                            if (value.rate && value.target) {
                                let amount = value.rate * value.target || 0;
                                return amount;
                            }
                        },
                        dependencies: ['target', 'rate'],
                    },
                    amount: {
                        compute: (value) => {
                            if (value.per_head_amount && value.no_of_resources) {
                                let amount = Math.floor(value.per_head_amount * value.no_of_resources) || 0;
                                return amount;
                            }
                        },
                        dependencies: ['no_of_resources', 'per_head_amount'],
                    },
                },
            },
        },
    },
});

export const PreparePackageDetail = props => {
    const { route: { params } = {} } = props;
    const { row, formName } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/artificialsalarycomponents',
        eventSourceId: 'ArtificialSalaryComponent',
    });
    const { fetch, user } = useAppStateContext();
    return (
        <Form
            api={`/artificialsalarycomponent/${row?._id}`}
            beforeSubmit={({ data }) => {
                const { artificialTotalAmount = 0, ctc_amount = 0 } = data;
                if (artificialTotalAmount < ctc_amount || artificialTotalAmount > ctc_amount) {
                    throw new Error('CTC Amount should be same as Total Amount Calculated');
                }
                if (formName == "first-biannual-form") {
                    data["biannual_first_package_added"] = true;
                }
                if (formName == "second-biannual-form") {
                    data["biannual_second_package_added"] = true;
                }
                delete data.artificialTotalAmount
                return {
                    data: {
                        ...data,
                    },
                };
            }}
            onSubmit={onSubmit}
            submitAction="Save"
            defaultValues={{
                appraisal_id: row._id,
                employee_id: row?.employee?._id,
                from_date: new Date(),
                working_days: row?.employee?.working_days,
                variable_perc: row?.employee?.employee_level?.variable_perc,
                artificialTotalAmount: row?.ctc_amount,
                mark_salary: true,
                created_by: user._id,
                is_pf_included : row?.employee_salary_component?.is_pf_included
            }}
            computations={{ ...PackageDetailComputation(fetch) }}
            layoutFields={
                [
                    {
                        label: 'Basic Details',
                        fields: [
                            {
                                field: 'ctc_amount_per_year',
                                label: 'CTC(Per Year)',
                                type: 'number',
                                size: 4,
                            },
                            {
                                field: 'ctc_amount',
                                label: 'CTC(Per Month)',
                                type: 'number',
                                placeholder: 'CTC(Per Month)',
                                size: 4,
                            },
                            {
                                label: 'Total Amount Calculated',
                                type: 'number',
                                field: 'artificialTotalAmount',
                                size: 4,
                                readOnly: true,
                            },
                            {
                                label: 'From Date',
                                type: 'date',
                                field: 'from_date',
                                size: 4,
                            },
                            {
                                label: 'To Date',
                                type: 'date',
                                field: 'to_date',
                                size: 4,
                            },
                            {
                                label: 'Package Review Date',
                                type: 'date',
                                field: 'package_review_date',
                                size: 4,
                            },
                            {
                                label: 'Is PF Included',
                                field: 'is_pf_included',
                                type: 'checkbox',
                                size: 4,
                              },
                              {
                                label: 'Working Days',
                                field: 'working_days',
                                type: 'autoComplete',
                                suggestionField: 'label',
                                keyField: 'value',
                                valueField: 'label',
                                options: [
                                  { value: 5, label: '5' },
                                  { value: 5.5, label: '5.5' },
                                  { value: 6, label: '6' },
                                ],
                                size: 4,
                                placeholder: 'Select',
                                readOnly: true,
                              },
                              {
                                label: 'Variable %',
                                field: 'variable_perc',
                                type: 'number',
                                size: 4,
                              },
                            {
                                label: 'Incentive',
                                type: 'number',
                                field: 'incentive_salary',
                                size: 6,
                                readOnly: true,
                            },
                            {
                                label: 'Fixed',
                                type: 'number',
                                field: 'fixed_salary',
                                size: 6,
                                readOnly: true,
                            },
                            {
                                type: 'number',
                                label: 'Sales Incentive Amount',
                                field: 'sales_incentive',
                                size: 6,
                            },
                            {
                                field: 'sales_incentive_clause',
                                type: 'text',
                                label: 'Sales Incentive Clause',
                                size: 6,
                            },
                            {
                                type: 'number',
                                placeholder: 'Level 1 Resource',
                                label: 'Level 1 Resource',
                                field: 'level_1_resource_number',
                                size: 6,
                            },
                            {
                                type: 'number',
                                placeholder: 'Level 2 Resource',
                                label: 'Level 2 Resource',
                                field: 'level_2_resource_number',
                                size: 6,
                            },
                            {
                                label: 'Upload Offer Letter Copy',
                                type: 'file',
                                field: 'offer_letter_copy_package',
                            },
                           
                        ],
                    },
                    {
                        label: 'Earning',
                        fields: [
                            {
                                field: 'attendance_base_components',
                                nested: true,
                                addInBottom: true,
                                render: props => {
                                    const {
                                        form: { values = {} },
                                    } = props;
                                    return (
                                        <NestedTable
                                            {...props}
                                            fields={[
                                                {
                                                    label: 'Salary Component',
                                                    field: 'salary_component_id',
                                                    type: 'autoComplete',
                                                    api: '/salarycomponents',
                                                    suggestionField: 'name',
                                                    valueField: 'name',
                                                },
                                                {
                                                    label: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 350,
                                                },
                                                {
                                                    label: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 50,
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    header: 'Salary Component',
                                                    field: 'salary_component_id.name',
                                                    type: 'text',
                                                },
                                                {
                                                    header: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 200,
                                                    align: 'center',
                                                },
                                                {
                                                    header: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 200,
                                                },
                                                {
                                                    header: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 200,
                                                },
                                                {
                                                    header: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 250,
                                                },
                                                {
                                                    header: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 100,
                                                },
                                            ]}
                                        />
                                    );
                                },
                            },
                        ]
                    },
                    {
                        label: 'Performance',
                        fields: [
                            {
                                field: 'performance_base_components',
                                nested: true,
                                addInBottom: true,
                                render: props => {
                                    const {
                                        form: { values = {} },
                                    } = props;
                                    return (
                                        <NestedTable
                                            {...props}
                                            fields={[
                                                {
                                                    label: 'Salary Component',
                                                    field: 'salary_component_id',
                                                    type: 'autoComplete',
                                                    api: '/salarycomponents',
                                                    suggestionField: 'name',
                                                    valueField: 'name',
                                                },
                                                {
                                                    type: 'date',
                                                    label: 'From Date',
                                                    field: 'from_date',
                                                    width: 250
                                                },
                                                {
                                                    type: 'date',
                                                    label: 'To Date',
                                                    field: 'to_date',
                                                    width: 250
                                                },
                                                {
                                                    label: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 300
                                                },
                                                {
                                                    label: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 50
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    header: 'Salary Component',
                                                    field: 'salary_component_id.name',
                                                    type: 'text',
                                                },
                                                {
                                                    header: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 150,
                                                },
                                                {
                                                    innerNested: true,
                                                    header: 'Level Detail',
                                                    label: 'Level Details',
                                                    fields: [
                                                        {
                                                            type: 'text',
                                                            field: 'level_details',
                                                            nested: true,
                                                            addInBottom: true,

                                                            render: props => {
                                                                return (
                                                                    <NestedTable
                                                                        {...props}
                                                                        columns={[
                                                                            {
                                                                                field: 'level.name',
                                                                                header: 'Level.',
                                                                                width: 100
                                                                            },
                                                                            {
                                                                                field: 'no_of_resources',
                                                                                header: 'No of Resources',
                                                                                type: 'number',
                                                                                width: 100
                                                                            },
                                                                            {
                                                                                field: 'amount',
                                                                                header: 'Amount',
                                                                                type: 'number',
                                                                                width: 80
                                                                            },
                                                                            {
                                                                                field: 'per_head_amount',
                                                                                header: 'Per Head Amount',
                                                                                type: 'number',
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                field: 'rate',
                                                                                header: 'Rate',
                                                                                type: 'number',
                                                                                width: 80
                                                                            },
                                                                            {
                                                                                field: 'target',
                                                                                header: 'Target',
                                                                                type: 'number',
                                                                                width: 80
                                                                            },
                                                                        ]}
                                                                        fields={[
                                                                            {
                                                                                field: 'level',
                                                                                label: 'Level',
                                                                                suggestionField: 'name',
                                                                                valueField: 'name',
                                                                                api: '/leveltypes',
                                                                                type: 'autoComplete',
                                                                            },
                                                                            {
                                                                                field: 'no_of_resources',
                                                                                label: 'No of Resources',
                                                                                type: 'number',
                                                                            },
                                                                            {
                                                                                field: 'rate',
                                                                                label: 'Rate',
                                                                                type: 'number',
                                                                            },
                                                                            {
                                                                                field: 'target',
                                                                                label: 'Target',
                                                                                type: 'number',
                                                                            },
                                                                        ]}
                                                                    />
                                                                );
                                                            },
                                                        },
                                                    ],
                                                    width: 200,
                                                },
                                                {
                                                    header: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 150,
                                                },
                                                {
                                                    header: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 150,
                                                },
                                                {
                                                    header: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 200,
                                                },
                                                {
                                                    header: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 50,
                                                },
                                            ]}
                                        />
                                    );
                                },
                            },
                        ]
                    },
                    {
                        label: 'Deduction',
                        fields: [
                            {
                                field: 'deductions_components',
                                nested: true,
                                addInBottom: true,
                                render: props => {
                                    const {
                                        form: { values = {} },
                                    } = props;
                                    return (
                                        <NestedTable
                                            {...props}
                                            fields={[
                                                {
                                                    label: 'Salary Component',
                                                    field: 'salary_component_id',
                                                    type: 'autoComplete',
                                                    api: '/salarycomponents',
                                                    suggestionField: 'name',
                                                    valueField: 'name',
                                                },
                                                {
                                                    label: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 350,
                                                },
                                                {
                                                    label: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 50,
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    header: 'Salary Component',
                                                    field: 'salary_component_id.name',
                                                    type: 'text',
                                                    width: 200,
                                                },
                                                {
                                                    header: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 200,
                                                    align: 'center',
                                                },
                                                {
                                                    header: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 200,
                                                },
                                                {
                                                    header: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 200,
                                                },
                                                {
                                                    header: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 250,
                                                },
                                                {
                                                    header: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 100,
                                                },
                                            ]}
                                        />
                                    );
                                },
                            },
                        ]
                    },
                    {
                        label: 'Bonus',
                        fields: [
                            {
                                field: 'fix_base_components',
                                nested: true,
                                addInBottom: true,
                                render: props => {
                                    const {
                                        form: { values = {} },
                                    } = props;
                                    return (
                                        <NestedTable
                                            {...props}
                                            fields={[
                                                {
                                                    label: 'Salary Component',
                                                    field: 'salary_component_id',
                                                    type: 'autoComplete',
                                                    api: '/salarycomponents',
                                                    suggestionField: 'name',
                                                    valueField: 'name',
                                                },
                                                {
                                                    label: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 220,
                                                },
                                                {
                                                    label: 'Remarks',
                                                    field: 'remarks',
                                                    type: 'text',
                                                    width: 350,
                                                },
                                                {
                                                    label: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 50,
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    header: 'Salary Component',
                                                    field: 'salary_component_id.name',
                                                    type: 'text',
                                                },
                                                {
                                                    header: 'Amount',
                                                    field: 'amount',
                                                    type: 'number',
                                                    width: 100,
                                                },
                                                {
                                                    header: 'From Date',
                                                    field: 'from_date',
                                                    type: 'date',
                                                    width: 150,
                                                },
                                                {
                                                    header: 'To Date',
                                                    field: 'to_date',
                                                    type: 'date',
                                                    width: 100,
                                                },
                                                {
                                                    header: 'Locking Date',
                                                    field: 'locking_date',
                                                    type: 'date',
                                                    width: 150,
                                                },
                                                {
                                                    header: 'Pay On',
                                                    type: 'text',
                                                    field: 'pay_on',
                                                    width: 100,
                                                },
                                                {
                                                    header: 'Pay On Confirmation',
                                                    type: 'checkbox',
                                                    field: 'pay_on_confirmation',
                                                    width: 100,
                                                },
                                                {
                                                    header: 'Remark',
                                                    type: 'text',
                                                    field: 'remarks',
                                                    width: 150,
                                                },
                                                {
                                                    header: 'NIC',
                                                    type: 'checkbox',
                                                    field: 'isNotCtcIncluded',
                                                    width: 50,
                                                },
                                            ]}
                                        />
                                    );
                                },
                            }
                        ]
                    },
                ]
            }
            {...props}
        />
    );
};

export const AddPreparePackageDetail = props => {
    return <PreparePackageDetail header="Edit & Add Salary Package" {...props} />;
};

export const EditPreparePackageDetail = props => {
    return (
        <PreparePackageDetail
            mode="edit"
            header={'Edit Salary Details'}
            {...props}
        />
    );
};
