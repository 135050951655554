import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const ZoomInAndOut = props => {
  const {zoomIn, zoomOut, resetTransform, ZoomImageStyle} = props;
  const {
    ZoomIn,
    ZoomOut,
    Refresh,
    textContainerstyle,
    textStyle,
    zoomAbsoluteContainerStyle,
    zoomContainerStyle,
    zoomIconContainerStyle,
  } = ZoomImageStyle || {};
  return (
    <View style={zoomAbsoluteContainerStyle}>
      <Row gap={8} style={zoomContainerStyle}>
        <TouchableOpacity
          onPress={() => {
            zoomIn();
          }}
          style={zoomIconContainerStyle}>
          <Image source={ZoomIn} />
        </TouchableOpacity>
        <Row style={textContainerstyle}>
          <Text style={textStyle}>Zoom</Text>
        </Row>
        <TouchableOpacity
          onPress={() => {
            zoomOut();
          }}
          style={zoomIconContainerStyle}>
          <Image source={ZoomOut} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            resetTransform();
          }}
          style={zoomIconContainerStyle}>
          <Image source={Refresh} />
        </TouchableOpacity>
      </Row>
    </View>
  );
};
