import React from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {theme} from './theme';
import {useGoogleLogin} from './hooks/UseGoogleLogin';

const GoogleLoginButton = ({onSubmit}) => {
  const signIn = useGoogleLogin({
    onSuccess: tokenResponse => {
      onSubmit &&
        onSubmit({
          ...tokenResponse,
          loginUsing: 'google',
        });
    },
  });

  const {GoogleLoginButton: GoogleLoginButtonStyles} = theme;
  const {
    containerStyle,
    googleIcon,
    iconContainerStyle,
    textContainerStyle,
    textStyle,
  } = GoogleLoginButtonStyles;
  return (
    <TouchableOpacity onPress={signIn} style={containerStyle}>
      <View style={iconContainerStyle}>
        <Image source={googleIcon} />
      </View>
      <View style={textContainerStyle}>
        <Text style={textStyle}>Sign in with Google</Text>
      </View>
    </TouchableOpacity>
  );
};

export default GoogleLoginButton;
