import React from 'react';
import {Col, Image, Row, Text} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {comparisonCardStyles, comparisonIndicatorStyles} from './theme';
import {formats} from '@unthinkable/react-table';

export const ComparisonIndicator = ({
  value = 0,
  compareValue = 0,
  toFixed = 0,
}) => {
  const styles = useStyles(comparisonIndicatorStyles);
  let percentage =
    value === compareValue
      ? 0
      : compareValue
      ? Math.abs(((value - compareValue) / compareValue) * 100)
      : 100;

  let isProfit = !compareValue || value >= compareValue;

  let icon = isProfit ? styles.profitIcon : styles.lossIcon;
  let textStyle = isProfit ? styles.profit : styles.loss;
  return (
    <Row gap={2} style={{alignItems: 'center'}}>
      <Image source={icon} />
      <Text style={textStyle}>
        {formats.formatPercentage(percentage, {toFixed})}
      </Text>
    </Row>
  );
};

export const ComparisonCard = ({label, value, compareValue, onPress}) => {
  const styles = useStyles(comparisonCardStyles);
  return (
    <Col gap={2} style={styles.container} onPress={onPress}>
      <Row gap={8} style={{alignItems: 'center'}}>
        <Text style={styles.valueText}>{formats.formatCurrency(value)}</Text>
        <ComparisonIndicator
          styles={styles}
          value={value}
          compareValue={compareValue}
        />
      </Row>
      <Text style={styles.label}>{label}</Text>
    </Col>
  );
};
