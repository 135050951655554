import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {Linking} from '@unthinkable/react-core-components';
import {LinkRenderer} from '../../../components/form/Renderers';
import {useTheme} from '@unthinkable/react-theme';
import {ColorTag} from '../../../components/chip/ColorChip';

export const PrFileChangedTable = props => {
  const {
    route: {params},
  } = props;

  const {title, secondaryTitle, fileChangedArray} = params;

  const {ArrowSquareOut} = useTheme('icons');
  const {SUCCESS_MEDIUM, SECONDARY_MEDIUM, ERROR_MEDIUM} = useTheme('colors');
  return (
    <Table
      renderHeader={() => (
        <TableHeader title={title} secondaryTitle={secondaryTitle} />
      )}
      data={fileChangedArray}
      onRowPress={({row}) => {
        Linking.openURL(row.file_url, '_blank');
      }}
      columns={[
        {
          header: 'File',
          render: ({row}) => (
            <LinkRenderer linkText={row.file_name} value={row.file_url} />
          ),
        },
        {
          header: 'Status',
          render: ({row}) => {
            let color;

            if (row.file_status === 'added') {
              color = SUCCESS_MEDIUM;
            } else if (row.file_status === 'modified') {
              color = SECONDARY_MEDIUM;
            } else if (row.file_status === 'removed') {
              color = ERROR_MEDIUM;
            }
            return <ColorTag color={color} value={row.file_status} />;
          },
          width: 100,
          align: 'center',
        },
        {
          header: 'Lines Added',
          field: 'additions',
          align: 'center',
          width: 100,
        },
        {
          header: 'Lines Deleted',
          field: 'deletions',
          align: 'center',
          width: 100,
        },
        {
          render: ({row}) => (
            <LinkRenderer
              linkText={row.file_name}
              value={row.file_url}
              icon={ArrowSquareOut}
            />
          ),
          width: 70,
        },
      ]}
    />
  );
};
