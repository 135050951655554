import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const UserEntityRoleForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {product, department, row, target_audience, organization} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/userentityroles',
    eventSourceId: 'UserEntity',
    ...props,
    EditUserEntityRoleForm,
  });
  return (
    <Form
      api={`/userentityroles/${row?._id}`}
      fields={{
        entity: {
          name: 1,
        },
        user_id: {
          name: 1,
        },
        role: 1,
        entity_id: {
          name: 1,
        },
      }}
      beforeSubmit={({data}) => {

        return {data: {...data, role: data?.role?.role}};
      }}
      onSubmit={onSubmit}
      header={{title: 'Add User Entity'}}
      layoutFields={[
        {
          label: 'Entity Details',
          fields: [
            {
              placeholder: 'Entity',
              label: 'Entity',
              field: 'entity',
              type: 'cascader',
              // required: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'entity',
                  api: '/types',
                  suggestionField: 'label',
                  valueField: 'label',
                  // required: true,
                },
                {
                  label: 'Entity Name',
                  placeholder: 'Entity_id',
                  field: 'entity_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  params: ({values: {entity} = {}}) => {
                    return {
                      source: entity,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
            {
              type: 'text',
              field: 'entity_id.name',
              placeholder: 'Entity Name',
              label: 'Entity Name',
              // required: true,
            },
          ],
        },
        {
          label: 'User',
          type: 'autoComplete',
          field: 'user_id',
          api: '/users',
          filter: {
            status: 'a',
          },
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Role',
          field: 'role',
          type: 'autoComplete',
          api: '/approles',
          suggestionField: 'role',
          valueField: 'role',
          // options: ["Admin", "Member", "TechAdmin"],
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditUserEntityRoleForm = props => {
  return (
    <UserEntityRoleForm
      header={{title: 'Edit User Entity Role'}}
      mode="edit"
      {...props}
    />
  );
};
