const suspendJobOpening = ({invoke}) => ({
  title: 'Drop',
  variant: 'error',
  confirm: {
    title: 'Drop Job Opening',
    message: 'Are you sure you want to Drop?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/jobopenings/${row?._id}`,
      props: {
        status: 'Suspended',
      },
    });
  },
});

const holdJobOpening = ({invoke}) => ({
  title: 'Hold',
  confirm: {
    title: 'Hold Job Opening',
    message: 'Are you sure you want to Hold?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/jobopenings/${row?._id}`,
      props: {
        status: 'Hold',
      },
    });
  },
});

const closeJobOpening = ({invoke}) => ({
  title: 'Close',
  confirm: {
    title: 'Close Job Opening',
    message: 'Are you sure you want to Close?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/jobopenings/${row?._id}`,
      props: {
        status: 'Closed',
      },
    });
  },
});

const activeJobOpening = ({invoke}) => ({
  title: 'Active',
  confirm: {
    title: 'Active Job Opening',
    message: 'Are you sure you want to Active?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/jobopenings/${row?._id}`,
      props: {
        status: 'Active',
      },
    });
  },
});

export {suspendJobOpening, activeJobOpening, holdJobOpening, closeJobOpening};
