import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';

export const KeywordTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;

  return (
    <Table
      {...props}
      params={params}
      renderHeader={props => {
        return (
          <TableHeader
            title="Keywords"
            actions={[
              <ObjectWiseTasks
                {...props}
                params={{...props?.route?.params}}
                object={{
                  _id: '654de2af0ac185c7cfd943b3',
                  name: 'Keyword',
                }}
              />,
              <AddButton title="Add Keyword" view="add-keyword" {...props} />,
            ]}
          />
        );
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-keyword', {
          keyword: row,
        });
      }}
      eventSourceId="Keyword"
      fields={{
        keyword: 1,
        webpage_id: {
          name: 1,
        },
        search_volume: 1,
        keyword_ranking: 1,
      }}
      api={`/keywords`}
      columns={[
        {
          type: 'text',
          field: 'keyword',
          header: 'Keyword',
        },
        {
          type: 'text',
          field: 'webpage_id.name',
          header: 'Webpage',
        },
        {
          type: 'number',
          field: 'search_volume',
          header: 'Search Volume',
          width: 200,
        },
        {
          type: 'number',
          field: 'keyword_ranking',
          header: 'Keyword ranking',
          width: 200,
        },
      ]}
    />
  );
};
