import React from 'react';
import {Table} from '../../../../components/table/Table';
import {useFilter} from '../../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {TableHeader} from '../../../../components/table/Headers';
import {SplitScreen} from '../../../../components/SplitScreen';
import {UpdateQATestCaseForm} from './QATestCaseForm';
import {AssetColumns, assetFilters} from './AssetUtility';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {PROJECT_QA_TEST_CASE_TYPE_ID} from '../../../common/constants/SourceConstants';
import {AllQaTestCaseScreen} from './FeatureQATestCasesScreen';
import {TabView} from '../../../../components/tab';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupBy} from '../../../../components/table/GroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {AddButton} from '../../../../components/button/AddButton';
import {useInvoke} from '../../../../controllers/useInvoke';

const TestCasesHeader = ({
  params,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  selectedIds,
  excelParams,
  groupBy,
}) => {
  const {project, module_id} = params;
  const filters = assetFilters({project});
  const postInvoke = useInvoke();

  return (
    <TableHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            !module_id && filters.module,
            filters.testcaseType,
            filters.owner,
          ]}
        />,
        !module_id && <GroupBy {...groupBy} />,
        <ExportExcelAction selectedIds={selectedIds} params={excelParams} />,
        !module_id && (
          <AddButton
            onPress={async () => {
              try {
                await postInvoke({
                  uri: `/projects/${project?._id}/requestExecution`,
                });
              } catch (err) {
                toast({message: err.message, type: 'Error'});
              }
            }}
            text={'Execute'}
          />
        ),
      ]}
    />
  );
};

export const qaTestCasesExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  ...params
}) => {
  let columns = [
    {
      header: 'UserStory',
      key: 'user_story_ids',
      objectField: 'userstory',
      width: 30,
    },
    {header: 'Test Case Id', key: 'code', width: 15},
    {header: 'Test Case type', key: 'type', width: 25},
    {header: 'Test Case Title/Summary', key: 'name', width: 50},
    {
      header: 'Test Case Description',
      key: 'description',
      width: 50,
      isHtml: true,
    },
    {
      header: 'Pre-Conditions / Assumptions',
      key: 'pre_conditions',
      width: 50,
      isHtml: true,
    },
    {header: 'Steps to be followed', key: 'steps', width: 50, isHtml: true},
  ];
  if (fromFeature) {
    columns.unshift({
      header: 'Feature Name',
      key: 'feature_id.feature',
      width: 50,
    });
    columns.unshift({
      header: 'Feature Code',
      key: 'feature_id.code',
      width: 15,
    });
  }
  return {
    addOnFilter,
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: 'QaTestCases',
      columns,
    },
  };
};

export const QaTestCaseTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
    status,
    selectedId,
  } = props;
  const {groupBy, ...restProps} = props;

  const {
    assetDetail,
    copyTestcase,
    changeModule,
    mergeReferences,
    deleteAsset,
    deleteMultipleAssets,
    updateModuleMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({
    navigation,
    params,
    api: '/qaTestCases',
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
  });

  const {allowDynamicGrouping} = groupBy || {};

  return (
    <Table
      {...restProps}
      allowDynamicGrouping={allowDynamicGrouping}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId={['TestCase', 'MapUserstory']}
      isRowActive={isRowActive}
      selection={{
        actions: [updateModuleMultipleAssets, deleteMultipleAssets],
      }}
      columns={[
        !selectedId && !allowDynamicGrouping && assetColumns.module,
        assetColumns.code,
        assetColumns.title,
        !selectedId && assetColumns.userstory_count,
        assetColumns.type,
        !selectedId && assetColumns.owner,
        assetColumns.testcase_status,
      ]}
      onRowPress={props => assetDetail({...props, readOnly: true})}
      moreActions={() => [
        copyTestcase,
        changeModule,
        mergeReferences({
          targetApi: `/qatestcases`,
        }),
        deleteAsset,
      ]}
    />
  );
};

export const QaTestCaseSplitScreen = props => {
  const {
    route: {params},
    groupBy,
  } = props;

  const {project, module_id} = params;

  const filterProps = useFilter();
  const {searchValue, filterValues} = filterProps;

  const tableProps = {
    search: searchValue,
    searchFields: 'name',
    api: `/qatestcases/released`,
    addOnFilter: {
      project_id: project?._id,
      module_id,
      ...filterValues?.filter,
    },
  };

  const renderHeader = props => {
    return (
      <TestCasesHeader
        {...props}
        {...filterProps}
        params={params}
        groupBy={groupBy}
        excelParams={qaTestCasesExportParams(tableProps)}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<UpdateQATestCaseForm {...props} />}>
      <QaTestCaseTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        groupBy={groupBy}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const QaTestCasesTab = props => {
  const {route: {params} = {}} = props;
  const {project, module} = params;

  let groupBy = useGroupBy({
    defaultSelected: module?._id ? void 0 : 'module_id',
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          exclusiveExpand: true,
          defaultExpandedIndex: 0,
          leftContent: ({row}) => {
            const moduleName = row?.module || 'No module';
            return <GroupContentItem value={moduleName + ` (${row.count})`} />;
          },
        },
      },
    ],
  });

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: <QaTestCaseSplitScreen {...props} groupBy={groupBy} />,
          api: '/qatestcases',
          filter: {
            status: 'released',
            project_id: project?._id,
            module_id: module?._id,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllQaTestCaseScreen
              api={`/projects/${project?._id}/asset/${PROJECT_QA_TEST_CASE_TYPE_ID}/all-data`}
              groupBy={groupBy}
              {...props}
            />
          ),
          params: {module_id: module?._id},
          api: `/projects/${project?._id}/asset/${PROJECT_QA_TEST_CASE_TYPE_ID}/in-development/count`,
        },
      }}
    />
  );
};
