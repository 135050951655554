import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  AllVoluntaryAttritionTable,
  TotalAttritionTable,
} from './AttritionTable';
import { AttritionTab } from './AttritionTabs';
import {RegrettableVoluntaryTab} from './RegrettableAttritionTab';


const menus = [
  {
    label: 'Total attrition',
    view: <AttritionTab />,
    eventSourceId: 'TotalAttrition',
  },
  {
    label: 'Voluntary attrition',
    section: true,
    menus: [
      {
        label: 'All',
        view: <AllVoluntaryAttritionTable />,
      },
      {
        label: 'Regrettable',
        view: <RegrettableVoluntaryTab />,
      },
    ],
  },
];

export const AttritionMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
