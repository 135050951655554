import React, {useState, useContext} from 'react';
import {MenuBar} from './MenuBar';
import {isMobile} from '@unthinkable/react-utils';
import {
  Container,
  ViewContainer,
  MenuBarContainer,
  ToggleButton,
  CollapseView,
  ToggleButtonIcon,
} from './Styles';

const LeftMenuContext = React.createContext();

export const useLeftMenuContext = () => {
  return useContext(LeftMenuContext);
};

const getSelectedMenu = (menus, checkDefault) => {
  for (let menu of menus) {
    if (!menu) {
      continue;
    }
    if (menu.section) {
      let selectedMenu = getSelectedMenu(menu.menus, checkDefault);
      if (selectedMenu) {
        return {menu: selectedMenu, section: menu};
      }
    }
    if (Array.isArray(menu)) {
      let selectedMenu = getSelectedMenu(menu, checkDefault);
      if (selectedMenu) {
        return selectedMenu;
      }
    } else if (menu.view && (!checkDefault || menu.defaultSelected)) {
      return menu;
    }
  }
};

export const LeftMenuView = ({
  menus,
  styles,
  isCollapsable = true,
  renderHeader,
  ...props
}) => {
  menus = menus?.filter(menu => {
    if (menu) {
      let {visible = true} = menu;
      if (typeof visible === 'function') {
        visible = visible(props);
      }
      return visible;
    }
  });

  const defaultSelectedMenu = isMobile
    ? void 0
    : getSelectedMenu(menus, true) || getSelectedMenu(menus);

  const [selectedMenu, setSelectedMenu] = useState(
    defaultSelectedMenu?.menu || defaultSelectedMenu,
  );

  const [selectedSection, setSelectedSection] = useState(
    defaultSelectedMenu?.section,
  );

  const [isOpen, setIsOpen] = useState(true);
  const [isMenuVisible, setMenuVisible] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      setMenuVisible(!isMenuVisible); // hide menu during animation
    }, 300);
  };

  const onSelectMenu = menu => {
    setSelectedMenu(menu);
  };

  const onSelectSection = menu => {
    setSelectedSection(menu);
  };

  const onBack = () => {
    setSelectedMenu(void 0);
  };

  let {view, label} = selectedMenu || {};

  const viewComponent = view ? React.cloneElement(view, props) : void 0;

  return (
    <LeftMenuContext.Provider value={{toggleMenu, isMenuVisible}}>
      {renderHeader?.(
        isMobile && view
          ? {
              back: {title: label},
              title: label,
              onBack,
            }
          : void 0,
      )}
      <Container styles={styles} direction={isMobile ? 'column' : 'row'}>
        <MenuBarContainer
          styles={styles}
          isOpen={isOpen}
          hidden={isMobile && view}>
          <MenuBar
            {...props}
            styles={styles}
            menus={menus}
            selectedMenu={selectedMenu}
            onSelectMenu={onSelectMenu}
            selectedSection={selectedSection}
            onSelectSection={onSelectSection}
          />
          <CollapseView
            styles={styles}
            isCollapsed={!isOpen || !isMenuVisible}
          />
          <ToggleButton
            styles={styles}
            hidden={isMobile || !isCollapsable}
            onPress={toggleMenu}>
            <ToggleButtonIcon isOpen={isOpen} />
          </ToggleButton>
        </MenuBarContainer>
        <ViewContainer styles={styles}>{viewComponent}</ViewContainer>
      </Container>
    </LeftMenuContext.Provider>
  );
};
