const url = process.env.REACT_APP_URL;
// let url = 'https://apiv6.locomo.io';
const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
const SOCKET_URL = process.env.REACT_APP_REALTIME_SOCKET_URL;
const SECONDARY_SOCKET_URL =
  process.env.REACT_APP_SECONDARY_REALTIME_SOCKET_URL;
const SECONDARY_SERVER_URL = process.env.REACT_APP_SECONDARY_SERVER_URL;

const BASE_URL = url;
const pmtUrl = process.env.REACT_APP_PMTURL;
const fetchUrl = BASE_URL;
const postUrl = BASE_URL;
const fetchSecondaryServerUrl = SECONDARY_SERVER_URL || BASE_URL;
const postSecondaryServerUrl = SECONDARY_SERVER_URL || BASE_URL;

const socketUrl = SOCKET_URL;
const secondarySocketUrl = SECONDARY_SOCKET_URL || SOCKET_URL;
const uploadUrl =
  process.env.NODE_ENV === 'development' ? BASE_URL : BUCKET_URL;
const downloadUrl =
  process.env.NODE_ENV === 'development' ? BASE_URL : BUCKET_URL;
const logsEnabled = process.env.REACT_APP_LOGS_ENABLED === 'true';
const clientBucket= process.env.REACT_APP_CLIENTBUCKET_NAME
const en_k1 =
  'TUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FEQ0IwR3V2Z05JTzZqRWVUQTB0TlcxSnR0alUzaWRCLytHbE9Sblg1WjNMdlU5eGliR1N0NDA4MURlSytQeXk2QXdxK2E0';
const en_k2 =
  'U3ZSeiswaTBsRlFzQnc4Sm5JMm85b2g3aytoR2NjeTNjVmZBOVVSUzJZUUFpbkpwbHJFaXpLbERHVkZ6Q3I4RndlVHZ5TzFBLzFzNGk5T0ZKVXgva3BCZ1JhMmx0djZEVHM5YWJRSURBUUFC';

export default {
  fetchUrl,
  postUrl,
  uploadUrl,
  downloadUrl,
  logsEnabled,
  socketUrl,
  secondarySocketUrl,
  en_k1,
  en_k2,
  fetchSecondaryServerUrl,
  postSecondaryServerUrl,
  clientBucket,
  pmtUrl,
};
