import React, {useMemo, useState, useContext, useCallback} from 'react';

export const FilterContext = React.createContext();

export const FilterContextProvider = ({children, filters = {}}) => {
  let [filterValues, setFilterValues] = useState(filters);

  const setFilter = useCallback(
    (key, values) => {
      setFilterValues({
        ...filterValues,
        [key]: values,
        loaded: true,
      });
    },
    [filterValues, setFilterValues],
  );

  const clearFilter = key => {
    setFilterValues(filterValues => {
      let newFilterValues = {
        ...filterValues,
      };
      delete newFilterValues[key];
      return newFilterValues;
    });
  };

  const clearSearchValue = key => {
    setFilterValues(filterValues => {
      let newFilterValues = {
        ...filterValues,
      };
      if (newFilterValues[key]?.searchValue) {
        delete newFilterValues[key]?.searchValue;
      }
      return newFilterValues;
    });
  };

  const filterContextValue = useMemo(
    () => ({
      filter: filterValues,
      setFilter: setFilter,
      clearFilter,
      clearSearchValue,
    }),
    [filterValues, setFilter, clearFilter],
  );

  return (
    <FilterContext.Provider value={filterContextValue}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext) || {};
};

export const useFilterKeyValues = key => {
  const {filter} = useFilterContext();
  return (key && filter?.[key]) || {};
};
