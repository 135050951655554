import React from 'react';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {useAppStateContext} from '../../../providers/AppState';
import {useColorMode, useStyles} from '@unthinkable/react-theme';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {ToggleButton} from '../../../components/toggle/ToggleButton';
import {UserProfileStyles} from './theme';
import {Confirm} from '../../../components/confirm/Confirm';
import {WithModal} from '@unthinkable/react-popper';
import {isMobile} from '@unthinkable/react-utils';

export const UserProfile = props => {
  const {navigation} = props;
  const {user, logout} = useAppStateContext();
  const styles = useStyles(UserProfileStyles);
  const {setColorMode, colorMode} = useColorMode();
  const navigationType = localStorage.getItem('navigationType') || 'old';
  const newNavigation = user?.newNavigation;
  const oldNavigation = user?.oldNavigation;

  return (
    <WithModal
      position={isMobile ? 'screenCenter' : void 0}
      styles={styles.modalStyles}
      width={isMobile ? void 0 : 400}
      renderModal={({hide}) => {
        return (
          <View style={styles.container}>
            <Row style={styles.itemContainer}>
              <UserAvatar size={36} value={user} />
              <View style={{flex: 1, overflow: 'hidden'}}>
                {user?.name ? (
                  <Row gap={4}>
                    <Text style={styles.label}>User name :</Text>
                    <Text
                      style={styles.itemText}
                      title={user?.name}
                      numberOfLines={1}>
                      {user?.name}
                    </Text>
                  </Row>
                ) : (
                  void 0
                )}
                <Row gap={4}>
                  <Text style={styles.label}>Email ID :</Text>
                  <View style={{flex: 1, overflow: 'hidden'}}>
                    <Text
                      style={styles.itemText}
                      title={user?.email}
                      numberOfLines={1}>
                      {user?.email}
                    </Text>
                  </View>
                </Row>
              </View>
            </Row>
            <Row style={styles.itemContainer}>
              <View style={{flex: 1}}>
                <Text style={styles.itemText}>Theme</Text>
              </View>
              <ToggleButton
                width={150}
                value={colorMode}
                options={[
                  {label: 'Light', value: 'light'},
                  {label: 'Dark', value: 'dark'},
                ]}
                onChange={value => {
                  setColorMode(value);
                }}
              />
            </Row>
            {user?.email === 'yogesh@daffodilsw.com' ||
            user?.email === 'sushil.nagvan@daffodilsw.com' ||
            user?.email === 'sachin.bansal@daffodilsw.com' ||
            user?.email === 'amit.singh@daffodildb.com' ||
            user?.email === 'amit@daffodilsw.com' ||
            user?.email === 'aswathy.krishnankutty@unthinkable.co' ||
            user?.email === 'shruti.chauhan@unthinkable.co'  || oldNavigation? (
              <Row style={styles.itemContainer}>
                <View style={{flex: 1}}>
                  <Text style={styles.itemText}>Navigation</Text>
                </View>
                <ToggleButton
                  width={150}
                  value={navigationType}
                  options={[
                    {label: 'Old', value: 'old'},
                    {label: 'New', value: 'new'},
                  ]}
                  onChange={value => {
                    localStorage.setItem('navigationType', value);
                    history.replaceState(null, '', '/');
                    location.reload();
                  }}
                />
              </Row>
            ) : (
              void 0
            )}
            <TouchableOpacity
              onPress={() => {
                hide();
                navigation.navigate('change-password');
              }}>
              <Row style={styles.itemContainer}>
                <Text style={styles.itemText}>Change Password</Text>
              </Row>
            </TouchableOpacity>
            <Confirm
              title="Logout"
              message="Are you sure you want to log out?"
              confirmText="Logout"
              onConfirm={() => {
                logout();
              }}>
              <Row style={styles.itemContainer}>
                <Text style={styles.logoutText}>Logout</Text>
                <Image
                  style={styles.logoutIconStyle}
                  source={styles.LogoutIcon}
                />
              </Row>
            </Confirm>
          </View>
        );
      }}>
      <UserAvatar value={user} size={28} />
    </WithModal>
  );
};
