import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {EmployeeProjectTasks, ProjectReviewTaskTable} from './TasksTable';
import {GroupFilter, PeriodRangeFilter, SearchFilter} from '../../../components/filter/Filters';
import {getYesterday, useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {taskFilters} from './TaskActions';
import {useAppStateContext} from '../../../providers/AppState';

export const ProjectMyActiveTasksTable = props => {
  const {filterValues, searchValue, onChangeFilter, ...filterProps} = useFilter(
    {},
  );

  const {user} = useAppStateContext();

  return (
    <>
      <TableHeader
        title={'Active Tasks'}
        actions={[
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
            placeholder="Search"
          />,
          <GroupFilter
            {...filterProps}
            filterValues={filterValues}
            filters={[taskFilters.project({user})]}
          />,
        ]}
      />
      <EmployeeProjectTasks
        {...props}
        filterValues={filterValues}
        searchValue={searchValue}
      />
    </>
  );
};

// Akshat Garg - 26/08/24 - completed in project tasks
export const ProjectMyCompletedTasksTable = props => {

  const {searchValue, filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      completed_on: getYesterday(),
    },
  });

  const {
    params: {completed_on},
    filter,
  } = filterValues;

  const {from: start_date, to: end_date} = completed_on;


  const {user} = useAppStateContext();

  return (
    <>
      <TableHeader
        title={'Completed Tasks'}
        actions={[
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
          />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[taskFilters.project({user})]}
          />,
          <PeriodRangeFilter
            field="completed_on"
            filterValues={filterValues}
            onChangeFilter={onChangeFilter}
          />,
        ]}
      />
      <EmployeeProjectTasks
          {...props}
          searchValue={searchValue}
          addOnParams={{sort: {completed_on: -1, project_id: 1, index: 1}}}
          filterValues={{
            filter: {
              ...filter,
              status: 'completed',
              completed_on: {
                $gte: start_date,
                $lte: end_date,
              },
            },
          }}
        />
    </>
  );
};

export const ProjectTaskReviewTab = props => {
  const {searchValue, onChangeFilter, applyFilter, filterValues} = useFilter({
    params: {span: 'Weekly'},
  });
  const {user} = useAppStateContext();

  const {params: filterParams = {}, filter} = filterValues;

  const commonFilter = {
    ...filter,
    project_id: filter?.project_id?._id || {$exists: true},
    module_id: filter?.module_id?._id,
  };

  const filters = {
    pending: {
      ...commonFilter,
      review_pending: true,
      status: 'completed',
    },
    reviewed: {
      ...commonFilter,
      review_pending: false,
      recent_reviewed_status: 'accepted',
      status: 'completed',
    },
    rejected: {
      ...commonFilter,
      review_pending: false,
      recent_reviewed_status: 'rejected',
      status: 'active',
    },
    archived: {
      ...commonFilter,
      status: 'completed',
      is_review_archived: true,
    },
  };

  const actions = [
    <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        taskFilters.assigned_to,
        taskFilters.project({user}),
        {
          type: 'autoComplete',
          field: 'module_id',
          api: '/projectmodules',
          suggestionField: 'module',
          valueField: 'module',
          placeholder: 'Select',
          label: 'Module',
          filter: props => {
            const {params} = props;
            return params;
          },
          params: props => {
            const {filter} = props;
            const {project_id} = filter || {};
            return {
              project_id: project_id?._id,
            };
          },
        },
      ]}
    />,
  ];

  const tabs = {
    pending: {
      label: 'Pending',
      api: `/users/${user?._id}/featureTasks`,
      params: {fromReview: true},
      view: (
        <ProjectReviewTaskTable
          key={'Pending'}
          source={'Pending'}
          filter={filters.pending}
          searchValue={searchValue}
          filterParams={filterParams}
          {...props}
        />
      ),
      actions,
      filter: filters.pending,
      eventSourceId: ['TaskReview', 'Task'],
    },
    reviewed: {
      label: 'Reviewed',
      view: (
        <ProjectReviewTaskTable
          key={'Reviewed'}
          source={'Reviewed'}
          filter={filters.reviewed}
          searchValue={searchValue}
          filterParams={filterParams}
          {...props}
        />
      ),
      api: `/users/${user?._id}/featureTasks`,
      params: {fromReview: true},
      actions,
      filter: filters.reviewed,
      eventSourceId: ['TaskReview', 'Task'],
    },
    rejected: {
      label: 'Rejected',
      actions,
      view: (
        <ProjectReviewTaskTable
          key={'Rejected'}
          source={'Rejected'}
          filter={filters.rejected}
          searchValue={searchValue}
          filterParams={filterParams}
          {...props}
        />
      ),
      api: `/users/${user?._id}/featureTasks`,
      params: {fromReview: true},
      filter: filters.rejected,
      eventSourceId: ['TaskReview', 'Task'],
    },
    archived: {
      label: 'Archived',
      actions,
      view: (
        <ProjectReviewTaskTable
          key={'Archived'}
          source={'Archived'}
          filter={filters.archived}
          searchValue={searchValue}
          filterParams={filterParams}
          {...props}
        />
      ),
      api: `/users/${user?._id}/featureTasks`,
      params: {fromReview: true},
      filter: filters.archived,
      eventSourceId: ['TaskReview', 'Task'],
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export const MyProjectTaskMenus = props => {
  const {route: {params} = {}} = props;

  const menus = [
    {
      label: 'Active',
      view: <ProjectMyActiveTasksTable {...props} />,
    },
    {
      label: 'Reviews',
      view: <ProjectTaskReviewTab {...params} />,
    },
  ];

  return <LeftMenuView {...props} menus={menus} />;
};
