import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {BuyerPersonaActiveTable} from './BuyerPersonaTable';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

export const BuyerPersonaTabView = props => {
  const {route: {params} = {}} = props;
  const {onChangeFilter, searchValue} = useFilter({});
  const tabs = {
    active: {
      label: 'All Buyer Personas',
      view: <BuyerPersonaActiveTable searchValue={searchValue} />,
    },
    // completed : {
    //   label: 'Completed',
    //   view: <BuyerPersonaCompletedTable />,
    // }
  };
  // return (
  //   <TabView
  //     tabs={tabs}
  //     params={params}
  //     {...props}
  //     actions={[
  //       <SearchFilter
  //         value={searchValue}
  //         onChangeFilter={onChangeFilter}
  //         placeholder="Search"
  //       />,
  //     ]}
  //   />
  // );
  return <BuyerPersonaActiveTable {...props} searchValue={searchValue} />;
};
