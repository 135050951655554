import React from 'react';
import {Form, InlineForm, MultiStepForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import moment from 'moment';
import {useFetchData} from '../../../controllers/useFetchData';
import {Table} from '../../../components/table/Table';
import {DurationRenderer} from '../../../components/form-editors/duration/DurationInput';
import {useInvoke} from '../../../controllers/useInvoke';

let hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
let minutes = ['00', '15', '30', '45'];
let timeArray = [];
let time = {};
hours.map(hour => {
  minutes.map(minute => {
    timeArray.push({
      label: moment(hour + ':' + minute, ['HH:mm']).format('h:mm A'),
      value: Number(hour) * 60 + Number(minute),
    });
    time[Number(hour) * 60 + Number(minute)] = moment(hour + ':' + minute, [
      'HH:mm',
    ]).format('h:mm A');
  });
});

const FistResponseTimeForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {employee_team} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeTeams',
    eventSourceId: 'SLAConfiguration',
  });
  return (
    <MultiStepForm
      {...props}
      api={`/employeeTeams/${employee_team?._id}`}
      fields={{
        first_response_time_urgent: 1,
        first_response_time_high: 1,
        first_response_time_medium: 1,
        first_response_time_low: 1,
      }}
      submitAction={{type: 'Save', label: 'Save'}}
      onSubmit={onSubmit}
      header={{
        title: 'Edit Response Time',
        secondaryTitle: 'First Response Time',
      }}
      steps={[
        {
          title: 'Urgent',
          fields: [
            {
              field: 'first_response_time_urgent',
              type: 'duration',
              required: true,
            },
          ],
        },
        {
          title: 'High',
          fields: [
            {
              field: 'first_response_time_high',
              type: 'duration',
              required: true,
            },
          ],
        },
        {
          title: 'Medium',
          fields: [
            {
              field: 'first_response_time_medium',
              type: 'duration',
              required: true,
            },
          ],
        },
        {
          title: 'Low',
          fields: [
            {
              field: 'first_response_time_low',
              type: 'duration',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const EditFistResponseTimeForm = props => {
  return <FistResponseTimeForm mode={'edit'} {...props} />;
};

const OverallResolutionTime = props => {
  const {
    route: {params = {}},
  } = props;
  const {employee_team} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeTeams',
    eventSourceId: 'SLAConfiguration',
  });
  return (
    <MultiStepForm
      {...props}
      api={`/employeeTeams/${employee_team?._id}`}
      fields={{
        overall_resolution_time_urgent: 1,
        overall_resolution_time_high: 1,
        overall_resolution_time_medium: 1,
        overall_resolution_time_low: 1,
      }}
      submitAction={{type: 'Save', label: 'Save'}}
      onSubmit={onSubmit}
      header={{
        title: 'Edit Response Time',
        secondaryTitle: 'Overall Response Time',
      }}
      steps={[
        {
          title: 'Urgent',
          fields: [
            {
              field: 'overall_resolution_time_urgent',
              type: 'duration',
              required: true,
            },
          ],
        },
        {
          title: 'High',
          fields: [
            {
              field: 'overall_resolution_time_high',
              type: 'duration',
              required: true,
            },
          ],
        },
        {
          title: 'Medium',
          fields: [
            {
              field: 'overall_resolution_time_medium',
              type: 'duration',
              required: true,
            },
          ],
        },
        {
          title: 'Low',
          fields: [
            {
              field: 'overall_resolution_time_low',
              type: 'duration',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const EditOverallResolutionTime = props => {
  return <OverallResolutionTime mode={'edit'} {...props} />;
};

export const SLAConfigurationList = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {employee_team} = params;
  const {data, loading} = useFetchData({
    api: '/employeeTeams/' + employee_team?._id,
    params: {
      fields: {
        first_response_time_urgent: 1,
        first_response_time_high: 1,
        first_response_time_medium: 1,
        first_response_time_low: 1,
        overall_resolution_time_urgent: 1,
        overall_resolution_time_high: 1,
        overall_resolution_time_medium: 1,
        overall_resolution_time_low: 1,
      },
    },
    eventSourceId: ['SLAConfiguration'],
  });

  const tableData = [
    {
      name: 'First Response Time',
      urgent: data?.first_response_time_urgent,
      high: data?.first_response_time_high,
      medium: data?.first_response_time_medium,
      low: data?.first_response_time_low,
    },
    {
      name: 'Overall Resolution Time',
      urgent: data?.overall_resolution_time_urgent,
      high: data?.overall_resolution_time_high,
      medium: data?.overall_resolution_time_medium,
      low: data?.overall_resolution_time_low,
    },
  ];

  return (
    <Table
      data={tableData}
      loading={loading}
      columns={[
        {
          header: 'Response Time',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Urgent',
          field: 'urgent',
          type: 'number',
          render: DurationRenderer,
        },
        {
          header: 'High',
          field: 'high',
          type: 'number',
          render: DurationRenderer,
        },
        {
          header: 'Medium',
          field: 'medium',
          type: 'number',
          render: DurationRenderer,
        },
        {
          header: 'Low',
          field: 'low',
          type: 'number',
          render: DurationRenderer,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: _ => {
            navigation.navigate('edit-employee-team-response-time', {
              employee_team,
            });
          },
          visible: ({row}) => row.name == 'First Response Time',
        },
        {
          title: 'Edit',
          onPress: _ => {
            navigation.navigate('edit-employee-team-overall-resolution-time', {
              employee_team,
            });
          },
          visible: ({row}) => row.name == 'Overall Resolution Time',
        },
      ]}
    />
  );
};

export const EditWorkingCycleForm = props => {
  const {
    route: {params},
  } = props;
  const {working_cycle, index, employee_team} = params;
  const invoke = useInvoke({
    eventSourceId: 'workingCycle',
    method: 'put',
  });
  return (
    <Form
      header={{
        title: 'Edit Working Cycle',
        secondaryTitle: working_cycle[index].day,
      }}
      defaultValues={{
        ...working_cycle[index],
      }}
      beforeSubmit={({data}) => {
        const {start_time, end_time} = data || {};
        let start_time_date = new Date();
        start_time_date = new Date(
          start_time_date.setHours(parseInt(start_time / 60)),
        );
        start_time_date = new Date(start_time_date.setMinutes(start_time % 60));
        start_time_date = start_time_date.setSeconds(0);

        let end_time_date = new Date();
        end_time_date = new Date(
          end_time_date.setHours(parseInt(end_time / 60)),
        );
        end_time_date = new Date(end_time_date.setMinutes(end_time % 60));
        end_time_date = end_time_date.setSeconds(0);
        return {
          data: {
            ...data,
            end_time_date,
            start_time_date,
          },
        };
      }}
      onSubmit={data => {
        working_cycle.forEach((_, i) => {
          if (i == index) working_cycle[i] = data;
        });
        invoke({
          uri: '/employeeTeams/' + employee_team?._id,
          props: {
            working_cycle: working_cycle,
          },
        });
      }}
      layoutFields={[
        {
          label: 'Start Time',
          type: 'autoComplete',
          options: timeArray,
          suggestionField: 'label',
          keyField: 'value',
          field: 'start_time',
          size: 'medium',
          required: true,
        },
        {
          label: 'End Time',
          type: 'autoComplete',
          options: timeArray,
          suggestionField: 'label',
          keyField: 'value',
          field: 'end_time',
          size: 'medium',
          required: true,
        },
      ]}
    />
  );
};

export const WorkingCycleScreen = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {employee_team} = params;

  const {data: {working_cycle = []} = {}} = useFetchData({
    api: '/employeeTeams/' + employee_team?._id,
    fields: {
      working_cycle: 1,
    },
    eventSourceId: ['workingCycle'],
  });

  const invoke = useInvoke({
    eventSourceId: 'workingCycle',
    method: 'put',
  });

  return (
    <Table
      {...props}
      renderHeader={props => (
        <InlineForm
          beforeSubmit={({data}) => {
            const {start_time, end_time} = data || {};
            let start_time_date = new Date();
            start_time_date = new Date(
              start_time_date.setHours(parseInt(start_time / 60)),
            );
            start_time_date = new Date(
              start_time_date.setMinutes(start_time % 60),
            );
            start_time_date = start_time_date.setSeconds(0);

            let end_time_date = new Date();
            end_time_date = new Date(
              end_time_date.setHours(parseInt(end_time / 60)),
            );
            end_time_date = new Date(end_time_date.setMinutes(end_time % 60));
            end_time_date = end_time_date.setSeconds(0);
            return {
              data: {
                ...data,
                end_time_date,
                start_time_date,
              },
            };
          }}
          onSubmit={data =>
            invoke({
              uri: '/employeeTeams/' + employee_team?._id,
              props: {
                working_cycle: [...working_cycle, data],
              },
            })
          }
          layoutFields={[
            {
              label: 'Day',
              type: 'autoComplete',
              field: 'day',
              options: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ],
              required: true,
            },
            {
              label: 'Start Time',
              type: 'autoComplete',
              options: timeArray,
              suggestionField: 'label',
              keyField: 'value',
              field: 'start_time',
              required: true,
            },
            {
              label: 'End Time',
              type: 'autoComplete',
              options: timeArray,
              suggestionField: 'label',
              keyField: 'value',
              field: 'end_time',
              required: true,
            },
          ]}
        />
      )}
      data={working_cycle}
      columns={[
        {
          header: 'Day',
          type: 'text',
          field: 'day',
        },
        {
          header: 'Start Time',
          type: 'text',
          field: row => {
            return time[row?.start_time];
          },
        },
        {
          header: 'End Time',
          type: 'text',
          field: row => {
            return time[row?.end_time];
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row, index}) => {
            console.log(row, index);
            navigation.navigate('edit-working-cycle', {
              ...params,
              working_cycle,
              index,
            });
          },
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete Working Cycle',
            message:
              'Do you really want to delete this day from your working cycle?',
            confirmText: 'Delete',
          },
          onPress: ({index}) => {
            working_cycle.splice(index, 1);
            invoke({
              uri: '/employeeTeams/' + employee_team?._id,
              props: {
                working_cycle: working_cycle,
              },
            });
          },
        },
      ]}
    />
  );
};
