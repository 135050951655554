import React from 'react';
import {SHORT_NAMES} from '../../common/constants/SourceConstants';
import {Tag} from '../../../components/chip/Chip';
import {
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

const DynamicFKRender = props => {
  let {sourceField, sourceIdField, row, onPress, maxWidth} = props;
  const {BODY3} = useTheme('fonts');
  const {NEUTRAL_HIGH} = useTheme('colors');

  let sourceValue = row[sourceField];

  const sourceDisplayValue =
    (sourceValue?._id && SHORT_NAMES[sourceValue?._id]) ||
    sourceValue?.label ||
    '';

  let sourceIdValue = row[sourceIdField]?.name || '';

  if (!sourceDisplayValue && !sourceIdValue) {
    return null;
  }

  let textValue = '';
  if (sourceDisplayValue) {
    textValue = (
      <Text title={sourceDisplayValue} style={{...BODY3, color: NEUTRAL_HIGH}}>
        ({sourceDisplayValue})
      </Text>
    );
  }
  if (sourceIdValue) {
    textValue = (
      <Row gap={4} style={{alignItems: 'center'}}>
        <View style={maxWidth ? {maxWidth} : {}}>
          <Text
            title={sourceIdValue}
            numberOfLines={1}
            style={{...BODY3, color: NEUTRAL_HIGH}}>
            {sourceIdValue}
          </Text>
        </View>
        {textValue}
      </Row>
    );
  }
  let component = <Tag color={sourceValue?.color}>{textValue}</Tag>;
  if (onPress) {
    component = (
      <TouchableOpacity
        onPress={() => {
          onPress(props);
        }}>
        {component}
      </TouchableOpacity>
    );
  }
  return component;
};

export default DynamicFKRender;
