import React from 'react';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import { SelfProjectFeedbackTable, SelfRMFeedbackTable } from './SelfManagerFeedbackTable';
import { GroupFilter } from '../../../components/filter/Filters';

const SelfProjectFeedbackTab = props => {
    let { user = {}, route: { params = {} } = {} , ...rest} = props || {};
    console.log("rest>>>>>>>>",rest)
    const { filterValues, searchValue, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };

    let tabs = {};
    tabs['SelfProjectFeedback'] = {
        label: 'Project Feedback',
        eventSourceId: 'SelfProjectFeedback',
        view: <SelfProjectFeedbackTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                $or: [
                    { is_project_manager_feedback_given: true },
                    { is_processed: true }
                ],
                rm_feedback_generated: { $in: [null, false] },
                employee: user && user.employee && user.employee._id
            }}
            user={user}
            {...rest}
        />,
        addOnFilter: {
            ...filter,
            $or: [
                { is_project_manager_feedback_given: true },
                { is_processed: true }
            ],
            rm_feedback_generated: { $in: [null, false] },
            employee: user && user.employee && user.employee._id
        },
        api: '/employeeprojectfeedback',
    }

    tabs['SelfRMFeedback'] = {
        label: 'Resource Management Feedback',
        eventSourceId: 'SelfRMFeedback',
        view: <SelfRMFeedbackTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                is_rm_feedback_given: true,
                rm_feedback_generated: true,
                employee: user && user.employee && user.employee._id
            }}
            user={user}
            {...rest} />,
        addOnFilter: {
            ...filter,
            is_rm_feedback_given: true,
            rm_feedback_generated: true,
            employee: user && user.employee && user.employee._id
        },
        api: '/employeeprojectfeedback',
    }

    return <TabView
        tabs={tabs}
        // params={params}
        {...props}
        actions={[
            <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                    {
                        type: 'autoComplete',
                        label: 'Financial Year',
                        api: '/FinancialYears',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        valueField: 'name',
                        field: 'financial_year',
                    },
                ]}
            />,
        ]}
    />;
};

export default SelfProjectFeedbackTab;
