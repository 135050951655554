import React from 'react';
import {PrStatusChip} from '../../components/chip/PrStatusChip';
import {LinkRenderer} from '../../components/form/Renderers';
import {useTheme} from '@unthinkable/react-theme';

export const PRStatusChipRender = ({row}) => {
  const {state} = row || {};
  return <PrStatusChip value={state} />;
};

export const PRLinkRender = ({row}) => {
  const {pullrequest_link} = row || {};
  const {ArrowSquareOut} = useTheme('icons');
  return <LinkRenderer value={pullrequest_link} icon={ArrowSquareOut} />;
};

export const PrNumberLinkRender = ({row}) => {
  const {pullrequest_link, pullrequest_id} = row || {};

  return (
    <LinkRenderer linkText={`# ${pullrequest_id}`} value={pullrequest_link} />
  );
};
