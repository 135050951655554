import React, {useEffect, useState} from 'react';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {Col, Row, Text} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {MultiOptionsSwitchTheme} from './theme';

export const MultiOptionsSwitch = props => {
  const {options, onChangeValue, value = [], invertSelection} = props;
  const {label: labelStyle} = useStyles(MultiOptionsSwitchTheme);
  const [list, setList] = useState(value);
  useEffect(() => {
    onChangeValue && onChangeValue(list);
  }, [list]);
  const remove = id => {
    var index = value.indexOf(id);
    if (index > -1) {
      value.splice(index, 1);
    }
    setList([...value]);
  };

  const RowOption = ({label, id}) => {
    return (
      <Row gap={16}>
        <Text style={labelStyle}>{label}</Text>
        <Switch
          value={invertSelection ? !value.includes(id) : value.includes(id)}
          onChangeValue={switchValue =>
            invertSelection
              ? switchValue
                ? remove(id)
                : setList([...value, id])
              : switchValue
              ? setList([...value, id])
              : remove(id)
          }
        />
      </Row>
    );
  };

  return (
    <Col gap={16}>
      {options.map(option => {
        return <RowOption {...option} />;
      })}
    </Col>
  );
};
