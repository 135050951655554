import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {GroupContentItemStyles} from './theme';
import {Image, Row, Text} from '@unthinkable/react-core-components';

export const GroupContentItem = ({
  value,
  title,
  icon,
  color,
  onPress,
  variant,
}) => {
  const styles = useStyles(GroupContentItemStyles);
  let textStyle = variant === 'secondary' ? styles.secondaryText : styles.text;
  return (
    <Row title={title} style={styles.itemContainer} onPress={onPress}>
      {icon ? <Image source={icon} style={styles.icon} /> : void 0}
      <Text style={{...textStyle, ...(color ? {color} : {})}}>{value}</Text>
    </Row>
  );
};
