import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {CompactAddButton} from '../../../components/button/AddButton';
import {useAppStateContext} from '../../../providers/AppState';
const OrganizationGroup = createMenuNavigator();

const CampaignSetupNavigator = ({navigation, route}) => {
  let {user} = useAppStateContext();

  let {email} = user || {};
  return (
    <OrganizationGroup.Navigator data-navigator-id="CampaignSetup">
      <OrganizationGroup.Group>
        <OrganizationGroup.Screen
          name="AIPrompts"
          options={{
            label: 'AI Prompt',
            onPress: () => {
              navigation.navigate('ai-prompts-table', {
                addOnFilter: {for_crm: true},
              });
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Add Linkedin Profile"
          options={{
            label: 'Linkedin Profile',
            actions: [
              <CompactAddButton
                title={'Add Linkedin Profile'}
                view={'add-linkedin-profile'}
              />,
            ],
            onPress: () => {
              navigation.navigate('linkedin-profile-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Add Email Profile"
          options={{
            label: 'Email Profile',
            actions: [
              <CompactAddButton
                title={'Add Email Profile'}
                view={'email-profile-add-form'}
              />,
            ],
            onPress: () => {
              navigation.navigate('email-profile-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Add Email Provider"
          options={{
            label: 'Email Provider',
            actions: [
              <CompactAddButton
                title={'Add Email Provider'}
                view={'email-provider-add-form'}
                extraParams={route.params}
              />,
            ],
            onPress: () => {
              navigation.navigate('email-provider-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Add Email Signature"
          options={{
            label: 'Email Signature',
            actions: [
              <CompactAddButton
                title={'Add Email Signature'}
                view={'email-signature-add-form'}
                extraParams={route.params}
              />,
            ],
            onPress: () => {
              navigation.navigate('email-signature-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Campaign Contact Connection"
          options={{
            label: 'Campaign Contact Connection',
            onPress: () => {
              navigation.navigate('contact-connection-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Email Unsubscribe List"
          options={{
            label: 'Email Unsubscribe List',
            onPress: () => {
              navigation.navigate('email-unsubscribe-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Token Usage"
          options={{
            label: 'Token Usage',
            onPress: () => {
              navigation.navigate('token-usage-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Finetune UseCase"
          options={{
            label: 'Finetune UseCases',
            onPress: () => {
              navigation.navigate('fine-tune-use-case-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Training Sample Data"
          options={{
            label: 'Training Sample Data',
            onPress: () => {
              navigation.navigate('fine-tune-training-sample-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Error Logs"
          options={{
            label: 'Error Logs',
            onPress: () => {
              navigation.navigate('error-logs', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Add Unique Selling Point"
          options={{
            label: 'Unique Selling Point',
            actions: [
              <CompactAddButton
                title={'Add Unique Selling Point'}
                view={'unique-selling-point-add-form'}
              />,
            ],
            onPress: () => {
              navigation.navigate('unique-selling-point-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Personalization-errors"
          options={{
            label: 'Personalization-errors',
            onPress: () => {
              navigation.navigate('perosnalization-issues', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Gmail History Logs"
          options={{
            label: 'Gmail History Logs',
            onPress: () => {
              navigation.navigate('gmail-history-logs', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Campaign Global Setting"
          options={{
            label: 'Campaign Global Setting',
            onPress: () => {
              navigation.navigate('campaign-global-setting', {});
            },
          }}
        />
        {email === 'rohit.bansal@daffodilsw.com' ||
        email === 'amit.singh@daffodildb.com' ? (
          <OrganizationGroup.Screen
            name="List"
            options={{
              label: 'Batch',
              onPress: () => {
                navigation.navigate('segment-list', {});
              },
            }}
          />
        ) : null}
        <OrganizationGroup.Screen
          name="Mappings"
          options={{
            label: 'Mappings',
            onPress: () => {
              navigation.navigate('mapping-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="Industry"
          options={{
            label: 'Industry',
            onPress: () => {
              navigation.navigate('industry-list', {});
            },
          }}
        />

        <OrganizationGroup.Screen
          name="Country"
          options={{
            label: 'Country',
            onPress: () => {
              navigation.navigate('country-list', {});
            },
          }}
        />
        <OrganizationGroup.Screen
          name="View Communications"
          options={{
            label: 'View Communications',
            onPress: () => {
              navigation.navigate('email-sync-communication-view', {});
            },
          }}
        />
      </OrganizationGroup.Group>
    </OrganizationGroup.Navigator>
  );
};

export default CampaignSetupNavigator;
