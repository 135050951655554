import React from 'react';
import {ScrollView} from '@unthinkable/react-core-components';
import {TabItem} from './TabItem';
import {isMobile} from '@unthinkable/react-utils';
import {
  TabBarContainer,
  TabBarRowColStyled,
  TabContainer,
  TabBarRowStyled,
} from './TabStyles';

const TabBar = ({
  tabs,
  selectedTab,
  onSelectTab,
  itemWidth,
  fullWidth = false,
  showsHorizontalScrollIndicator = false,
  renderTabItem,
  ...props
}) => {
  let renderComponent = (
    <TabBarContainer>
      {Object.keys(tabs).map((tabKey, index) => {
        const tabItem = {value: tabKey, ...tabs[tabKey]};
        const tabItemProps = {
          ...props,
          key: tabKey,
          isLast: index === Object.keys(tabs)?.length - 1,
          index,
          itemWidth,
          selectedTab,
          fullWidth,
          item: tabItem,
          onChange: (value, apiKey) => {
            onSelectTab && onSelectTab(value, apiKey);
          },
        };
        if (renderTabItem && typeof renderTabItem === 'function') {
          return renderTabItem(tabItemProps);
        } else {
          return <TabItem {...tabItemProps} />;
        }
      })}
    </TabBarContainer>
  );

  if (!fullWidth) {
    renderComponent = (
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}>
        {renderComponent}
      </ScrollView>
    );
  }

  return renderComponent;
};

export const TabBarRow = ({
  tabs,
  selectedTab,
  onSelectTab,
  itemWidth,
  fullWidth,
  actions,
  ...props
}) => {
  if (Array.isArray(actions)) {
    actions = actions.filter(action => !!action);
  }
  const {styles} = props;
  return isMobile ? (
    <TabBarRowColStyled gap={12} styles={styles}>
      <TabBar
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectTab={onSelectTab}
        fullWidth={fullWidth}
        {...props}
      />
      {actions}
    </TabBarRowColStyled>
  ) : (
    <TabBarRowStyled gap={12} styles={styles}>
      <TabContainer>
        <TabBar
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectTab={onSelectTab}
          itemWidth={itemWidth}
          fullWidth={fullWidth}
          {...props}
        />
      </TabContainer>
      {Array.isArray(actions)
        ? actions.map((action, index) => {
            let isFirst = index === 0;
            let isLast = index === actions.length - 1;
            if (action && React.isValidElement(action) && (isFirst || isLast)) {
              let cloneProps = {};
              if (action.props?.isFirst === undefined) {
                cloneProps.isFirst = isFirst;
              }
              if (action.props?.isLast === undefined) {
                cloneProps.isLast = isLast;
              }
              return React.cloneElement(action, cloneProps);
            } else {
              return action;
            }
          })
        : actions}
    </TabBarRowStyled>
  );
};
