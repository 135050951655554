import React from 'react';
import {ResponsiveModal, WithModal} from '@unthinkable/react-popper';
import {
  CancelButton,
  ConfirmButton,
  ConfirmContainer,
  FooterContainer,
  MessageText,
  SecondaryTitleText,
  TitleContainer,
  TitleText,
} from './styles/ConfirmStyles';

const ConfirmRender = ({
  styles,
  children,
  title,
  secondaryTitle,
  message,
  confirmText = 'Ok',
  cancelText = 'Cancel',
  onConfirm,
  onHide,
  variant,
  ...props
}) => {
  styles = {...styles, ...styles?.[variant]};
  if (typeof secondaryTitle === 'function') {
    secondaryTitle = secondaryTitle(props);
  }
  if (typeof message === 'function') {
    message = message(props);
  }

  return (
    <ConfirmContainer styles={styles}>
      <TitleContainer styles={styles}>
        {title ? <TitleText styles={styles}>{title}</TitleText> : void 0}
        {secondaryTitle ? (
          <SecondaryTitleText styles={styles}>
            {secondaryTitle}
          </SecondaryTitleText>
        ) : (
          void 0
        )}
      </TitleContainer>
      {message ? <MessageText styles={styles}>{message}</MessageText> : void 0}
      <FooterContainer styles={styles}>
        <CancelButton
          styles={styles?.cancelButton}
          text={cancelText}
          onPress={() => {
            onHide && onHide();
          }}
        />
        <ConfirmButton
          styles={styles?.confirmButton}
          text={confirmText}
          onPress={() => {
            onConfirm && onConfirm(props);
            onHide && onHide();
          }}
        />
      </FooterContainer>
    </ConfirmContainer>
  );
};

export const ConfirmPopup = ({styles = {}, onHide, isVisible, ...props}) => {
  return (
    <ResponsiveModal
      position="screenCenter"
      styles={styles?.modal}
      isVisible={isVisible}
      hideModal={onHide}>
      <ConfirmRender {...props} styles={styles} onHide={onHide} />
    </ResponsiveModal>
  );
};

const Confirm = ({
  styles,
  children,
  onHide,
  disabled,
  modalProps,
  ...props
}) => {
  const renderModal = ({hide}) => {
    return (
      <ConfirmRender
        {...props}
        styles={styles}
        onHide={() => {
          hide();
          onHide && onHide();
        }}
      />
    );
  };

  return (
    <WithModal
      styles={styles?.modal}
      position="screenCenter"
      renderModal={renderModal}
      disabled={disabled}
      {...modalProps}>
      {children}
    </WithModal>
  );
};

export default Confirm;
