import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import { sectionTypes } from '../constants/unthinkableWebsiteConstants';

export const SectionForm = props => {
  const {
    route: { params },
  } = props;
  
  let { page, row, url: pageUrl = '', type } = params;

  if(type && type === 'domain'){
    pageUrl = '/domain';
  }
  else if(type && type === 'blogs'){
    pageUrl = '/blogs-detail';
  }
  else if(type && type === 'case-study'){
    pageUrl = '/case-study-detail';
  }
  else if (type && type === 'career') {
    pageUrl = '/career-detail'
  }
  let { onSubmit } = useFormSubmit({
    uri: '/sections',
    eventSourceId: 'section',
  });

  return (
    <Form
      api={`/sections/${row?._id}`}
      fields={{
        type: 1,
        icons: 1,
        heading: 1,
        sub_heading: 1,
        description: 1,
        tags: 1,
        image: 1,
        cards: {
            image: 1,
            heading: 1,
            sub_heading: 1,
            description: 1,
            key_features: 1,
            index: 1,
            page: 1,
        },
        parent: {
          type: 1,
        },
        index: 1,
      }}
      beforeSubmit={({ data }) => {
        return {
          data: {
            page,
            ...data,
          }
        };
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Type',
          type: 'autoComplete',
          options: pageUrl && sectionTypes[pageUrl] ? sectionTypes[pageUrl] : [],
          suggestionField: 'label',
          valueField: 'label',
          keyField: 'id',
          field: 'type',
          required: true,
        },
        {
          label: 'Heading',
          type: 'textArea',
          field: 'heading',
          visible: ({ values }) => ['intro', 'challenge', 'module', 'requirement', 'userpersona', 'expertise', 'aboutUsIntro', 'values', 'journey', 'partners', 'visionary', 'positiveImpact', 'history', 'locations', 'careerIntro', 'commitment', 'breakTheBreak', 'ourPartners', 'careerDetailIntro', 'candidateForm', 'summary', 'textEditor', 'quote', 'frontend-architecture', 'backend-architecture', 'modular-development', 'poll'].includes(values.type),
        },
        {
          label: 'Sub Heading',
          type: 'textArea',
          field: 'sub_heading',
          visible: ({ values }) => ['intro', 'challenge', 'module', 'requirement', 'userpersona', 'journey', 'history', 'careerIntro', 'breakTheBreak'].includes(values.type),
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          visible: ({ values }) => ['intro', 'module', 'requirement', 'expertise', 'aboutUsIntro', 'partners', 'positiveImpact', 'breakTheBreak', 'careerDetailIntro', 'candidateForm', 'summary', 'textEditor', 'quote', 'conclusion'].includes(values.type),
        },
        {
          label: 'Cards',
          field: 'cards',
          nested: true,
          visible: ({ values }) => ['challenge', 'module', 'requirement', 'userpersona', 'expertise', "FAQ's", 'values', 'journey', 'partners', 'visionary', 'positiveImpact', 'history', 'locations', 'commitment', 'breakTheBreak', 'child', 'ourPartners', 'jobDesc', 'journey', 'textEditor', 'cards', 'frontend-architecture', 'backend-architecture', 'modular-development'].includes(values.type),
          render: (props, _props) => {
            const { values } = _props || {};
            return <NestedTable
              {...props}
              fields={[
                {
                  label: 'Image',
                  field: 'image',
                  type: 'file',
                  visible: ['challenge', 'module', 'requirement', 'userpersona', 'expertise', 'values', 'journey', 'partners', 'visionary', 'positiveImpact', 'history', 'locations', 'commitment', 'breakTheBreak', 'child', 'ourPartners', 'journey', 'frontend-architecture', 'backend-architecture', 'modular-development'].includes(values.type),
                },
                {
                  label: 'Heading',
                  field: 'heading',
                  type: 'textArea',
                  visible: ['challenge', 'module', 'requirement', 'userpersona', "FAQ's", 'expertise', 'values', 'journey', 'partners', 'visionary', 'positiveImpact', 'history', 'locations', 'commitment', 'breakTheBreak', 'child', 'ourPartners', 'jobDesc', 'journey', 'textEditor', 'cards', 'frontend-architecture', 'backend-architecture', 'modular-development'].includes(values.type),
                },
                {
                  label: values.type === 'locations' ? 'Location Url' : 'Sub Heading',
                  field: 'sub_heading',
                  type: 'textArea',
                  visible: ['visionary', 'breakTheBreak', 'frontend-architecture', 'locations'].includes(values.type),
                },
                {
                  label: values.type === 'visionary' ? 'LinkedIn Url' : 'Description',
                  field: 'description',
                  type: 'textArea',
                  visible: ['module', 'requirement', "FAQ's", 'expertise', 'values', 'journey', 'visionary', 'locations', 'commitment', 'child', 'ourPartners', 'journey', 'textEditor', 'cards', 'frontend-architecture', 'backend-architecture', 'modular-development'].includes(values.type),
                },
                {
                  label: 'Domain',
                  field: 'page',
                  type: 'autoComplete',
                  api: '/pages',
                  filter: {
                    type: 'domain',
                  },
                  suggestionField: 'url',
                  valueField: 'url',
                  visible: ['modular-development'].includes(values.type),
                },
                {
                  label: 'Key Features',
                  field: 'key_features',
                  type: 'multiText',
                  visible: ['module', 'jobDesc', 'textEditor'].includes(values.type),
                },
                {
                  label: 'Index',
                  field: 'index',
                  type: 'number',
                }
              ]}
              columns={[
                {
                  header: 'Heading',
                  field: 'heading',
                  placeHolder: 'Heading',
                  type: 'textArea',
                  visible: ['challenge', 'module', 'requirement', 'userpersona', "FAQ's", 'expertise', 'values', 'journey', 'partners', 'visionary', 'positiveImpact', 'history', 'locations', 'commitment', 'breakTheBreak', 'child', 'ourPartners', 'jobDesc', 'journey', 'textEditor', 'cards', 'frontend-architecture', 'backend-architecture', 'modular-development'].includes(values.type),
                },
                {
                  label: 'Sub Heading',
                  field: 'sub_heading',
                  placeHolder: 'Sub Heading',
                  type: 'textArea',
                  visible: ['visionary', 'breakTheBreak', 'frontend-architecture'].includes(values.type),
                },
                {
                  label: values.type === 'visionary' ? 'LinkedIn Url' : 'Description',
                  field: 'description',
                  placeHolder: values.type === 'visionary' ? 'LinkedIn Url' : 'Description',
                  type: 'textArea',
                  visible: ['module', 'requirement', "FAQ's", 'expertise', 'values', 'journey', 'visionary', 'locations', 'commitment', 'child', 'ourPartners', 'journey', 'textEditor', 'cards', 'frontend-architecture', 'backend-architecture', 'modular-development'].includes(values.type),
                },
                {
                  label: 'Index',
                  field: 'index',
                  placeHolder: 'Index',
                  type: 'number',
                }
              ]}
            />
          },
        },
        {
          label: 'Parent',
          type: 'autoComplete',
          api: '/sections',
          filter: {
            page,
          },
          suggestionField: 'type',
          valueField: 'type',
          field: 'parent',
          visible: ({ values }) => ['child'].includes(values.type),
        },
        {
          field: 'icons',
          type: 'file',
          label: 'Icon',
          multiple: true,
          visible: ({ values }) => ['intro', 'history'].includes(values.type),
        },
        {
          field: 'tags',
          type: 'multiText',
          label: 'Tags',
          visible: ({ values }) => ['careerDetailIntro', 'summary', 'frontend-architecture', 'backend-architecture', 'modular-development', 'poll'].includes(values.type),
        },
        {
          field: 'image',
          type: 'file',
          label: 'Image',
          visible: ({ values }) => ['careerDetailIntro', 'imageEditor'].includes(values.type),
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
      ]}
      {...props}
    />
  );
};

export const AddSectionForm = props => {
  return <SectionForm header="Add Section" {...props} />;
};

export const UpdateSectionForm = props => {
  return (
    <SectionForm mode="edit" header="Update Section" {...props} />
  );
};
