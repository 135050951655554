// @ Adit Verma 08/01/2024
//  Api Logs Screen

import { getCurrentMonth } from '@unthinkable/react-filters';
import React from 'react';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';

//  View for Api Logs
export const ApiLogsView = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter = {}, params: filterParams} = filterValues || {};
  params = {...params, ...filterParams};
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Api Logs"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'TotalTime',
                  placeholder: 'Time',
                  suggestionField: 'value',
                  valueField: 'value',
                  keyField: 'value',
                  field: 'totalTime',
                  options: [
                    {label: '100', value: 100},
                    {label: '200', value: 200},
                    {label: '300', value: 300},
                    {label: '400', value: 400},
                    {label: '500', value: 500},
                    {label: '1000', value: 1000},
                    {label: '2000', value: 2000},
                    {label: '5000', value: 5000},
                    {label: '10000', value: 10000},
                  ],
                },
                {
                  type: 'autoComplete',
                  label: 'Status',
                  placeholder: 'Status',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  field: 'status',
                  options: [
                    {label: 'Completed', value: 'completed'},
                    {label: 'Error', value: 'error'},
                    {label: 'Pending', value: 'pending'},
                  ],
                },
                {
                  type: 'autoComplete',
                  label: 'Decrypted',
                  placeholder: 'Decrypted',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  field: 'decrypted',
                  options: [
                    {label: 'Decrypted', value: 'true'},
                    {label: 'NonDecrypted', value: 'false'},
                  ],
                },
                {
                  type: 'autoComplete',
                  label: 'User',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  field: 'user',
                },
              ]}
            />,
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
          ]}
        />
      )}
      search={searchValue}
      api="/apiLogs"
      fields={{
        path: 1,
        user: 1,
        method: 1,
        createdAt: 1,
        totalTime: 1,
        server: 1,
        routePath: 1,
      }}
      filter={filter}
      onRowPress={({row}) => {
        navigation.navigate(`api-logs-detail`, {
          apiId: row?._id,
        });
      }}
      columns={[
        {
          header: 'Api-routePath',
          field: 'routePath',
          type: 'text',
        },
        {
          header: 'Api-Method',
          field: 'method',
          type: 'text',
        },
        {
          header: 'User',
          field: 'user.name',
          type: 'text',
        },
        {
          header: 'ServerName',
          field: 'server',
          type: 'text',
        },
        {
          header: 'CreatedAt',
          field: 'createdAt',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
          width: 150,
        },
        {
          header: 'TotalTime',
          field: 'totalTime',
          type: 'number',
        },
      ]}
      {...props}
      params={params}
    />
  );
};
