import React from 'react';
import { Form } from '../../../../components/form/Form';
import { FormErrorConfirmWrapper } from '../../../../components/form/FormWrapper';
import { useFormSubmit } from '../../../../controllers/useSubmitForm';

const AssignShadowForm = props => {
  const {
    route: {params},
  } = props;
  const {allocated_data = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
  });

  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        onSubmit={onSubmit}
        submitAction={'Save'}
        header="Assign Shadow"
        defaultValues={{
          is_shadow: true,
          ...allocated_data,
          order_date: allocated_data?.order?.order_date,
          order_end_date: allocated_data?.order?.order_end_date,
          is_front_facing_shadow: true,
          role: 'IC',
          shadow_type: 'learning',
        }}
        layoutFields={[
          {
            label: 'Order Start Date',
            placeholder: 'Order Start Date',
            field: 'order_date',
            type: 'date',
            readOnly: true,
            size: 6,
          },
          {
            label: 'Order End Date',
            placeholder: 'Order End Date',
            field: 'order_end_date',
            type: 'date',
            readOnly: true,
            size: 6,
          },
          {
            label: 'Role',
            type: 'radioGroup',
            field: 'role',
            options: ['IC', 'Manager'],
            onChangeValue: (value, e, {setFieldValue}) => {
              if (value == 'Manager') {
                setFieldValue('is_manager', true);
              } else {
                setFieldValue('is_manager', void 0);
              }
            },
            required: true,
            size: 4,
          },
          {
            label: 'Shadow',
            type: 'radioGroup',
            field: 'shadow_type',
            options: [
              {label: 'Learning', value: 'learning'},
              {label: 'Fixed', value: 'accelerate'},
            ],
            required: true,
            size: 4,
          },
          {
            type: 'switch',
            label: 'Is Front Facing Shadow?',
            field: 'is_front_facing_shadow',
            size: 4,
            onChangeValue: (value, e, {setFieldValue}) => {
              if (value) {
                setFieldValue(
                  'front_facing_resource',
                  allocated_data?.front_facing_resource,
                );
              } else {
                setFieldValue('front_facing_resource', void 0);
              }
            },
          },
          {
            label: 'Front Facing Resource',
            field: 'front_facing_resource',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
            visible: ({values = {}}) => values?.is_front_facing_shadow,
          },
          {
            label: 'Resource',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            required: true,
            size: 6,
          },
          {
            label: 'Start Date',
            field: 'from_date',
            type: 'date',
            required: true,
            size: 4,
          },
          {
            label: 'End Date',
            field: 'to_date',
            type: 'date',
            required: true,
            size: 4,
          },
          {
            label: 'Hours',
            field: 'hours',
            type: 'number',
            required: true,
            size: 4,
          },
          {
            label: 'Project',
            field: 'project_id',
            type: 'autoComplete',
            api: `/projects`,
            suggestionField: 'project',
            valueField: 'project',
            required: true,
            size: 4,
          },
          {
            label: 'Project Skill',
            field: 'tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            size: 4,
          },
          {
            label: 'Resource Skill',
            field: 'resource_tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            size: 4,
          },
          {
            type: 'switch',
            label: 'Shift allowance',
            field: 'applicable_for_daily_incentive',
            size: 6,
          },
          {
            type: 'number',
            label: 'Amount',
            field: 'daily_incentive',
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            required: true,
            size: 6,
          },
          {
            type: 'switch',
            label: 'Is Included In Utilisation',
            field: 'is_included_in_utilisation',
            size: 6,
          },
          {
            label: 'Percentage Included In Utilisation',
            field: 'percIncludeInUtilisation',
            type: 'autoComplete',
            options: [
              {label: '100 %', value: 100},
              {label: '75 %', value: 75},
              {label: '50 %', value: 50},
            ],
            visible: ({values = {}}) => values?.is_included_in_utilisation,
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
            required: true,
            size: 6,
          },
          {
            size: 6,
            groups: [
              {
                defaultHidden: true,
                label: 'Leader',
                fields: [
                  {
                    label: 'Skill Leader',
                    placeholder: 'Skill Leader',
                    field: 'level_one_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 4,
                  },
                  {
                    label: 'Project Owner',
                    placeholder: 'Project Owner',
                    field: 'level_two_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 4,
                  },
                ],
              },
            ],
          },
        ]}
        {...props}
      />
    </FormErrorConfirmWrapper>
  );
};

export default AssignShadowForm;
