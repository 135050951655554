import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const RoleForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/roles',
    eventSourceId: 'roleAdded',
    ...props,
  });

  return (
    <Form
      api={`/roles/${row?._id}`}
      fields={{
        role: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Roles'}}
      layoutFields={[
        {
          label: 'Role',
          type: 'text',
          field: 'role',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditRoleForm = props => {
  return <RoleForm header={{title: 'Edit Role'}} mode="edit" {...props} />;
};
