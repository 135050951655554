import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const MarkNotAvailable = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employees/${params.row._id}`}
      mode="edit"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            working_status: 'Not Available',
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Mark Unavailable"
      fields={{
        not_available_type: 1,
        not_available_reason: 1,
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'not_available_type',
          placeholder: 'Not Available Type',
          label: 'Not Available Type',
          options: ['Long Leave', 'Other'],
          required: true,
        },
        {
          type: 'date',
          field: 'not_available_until_date',
          placeholder: 'Until',
          label: 'Until',
          required: true,
        },
        {
          type: 'textArea',
          field: 'not_available_reason',
          placeholder: 'Not Available Reason',
          visible: ({values}) => {
            return values?.not_available_type === 'Other';
          },
        },
      ]}
      {...props}
    />
  );
};
