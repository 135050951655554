import React, {useCallback, useEffect, useState} from 'react';
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {UnverifiedActionStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {RenderName} from './CustomRendersCollection';
import PreText from '../../../components/chat/Pretext';
import {leadControllers} from '../controllers/leadControllers';
import {Form} from '../../../components/form/Form';
import {SubmitButton} from '@unthinkable/react-form';
import {OutlineButton} from '../../../components/button/OutlineButton';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Button} from '../../../components/button/Button';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {ActivityIndicator} from 'react-native';
import {RecursiveComponent} from './ViewAssistantResponse';

export const CampaignActionUnverifiedFormTable = props => {
  let {data, campaignData, onDataChange, for_training} = props || {};
  const {nextCampaignActiondetail} = data[0];
  let invoke = useInvoke({method: 'post'});
  let invokePut = useInvoke({method: 'put'});
  const {user} = useAppStateContext();

  const [activeIdx, setActiveIdx] = useState(-1);
  const [activeItem, setActiveItem] = useState({});
  let [showWebsiteSummary, setShowWebsiteSummary] = useState(false);
  let [editMessage, setEditMessage] = useState(false);

  let [personalizedData, setPersonalizedData] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setActiveIdx(data.findIndex(item => item._id == campaignData._id));
  }, [campaignData]);

  useEffect(() => {
    setActiveItem(data[activeIdx]);
  }, [activeIdx, data]);

  const handleTogglePrevious = useCallback(() => {
    let desiredIdx = activeIdx - 1;
    if (desiredIdx >= 0) setActiveIdx(desiredIdx);
    setPersonalizedData({});
  }, [activeIdx]);

  const handleToggleNext = useCallback(() => {
    let desiredIdx = activeIdx + 1;
    if (desiredIdx < data.length) setActiveIdx(desiredIdx);
    setPersonalizedData({});
  }, [activeIdx]);

  const handleRemove = useCallback(() => {
    const updatedData = data.filter((_, index) => index !== activeIdx);
    onDataChange(updatedData);
    if (activeIdx >= updatedData.length - 1) {
      setActiveIdx(0);
    }
    setPersonalizedData({});
  }, [data, activeIdx]);

  const handleUpdate = useCallback(
    newData => {
      const newId = newData?.data?._id;

      const updatedData = data.map(item => {
        if (item?._id === newId) {
          return {
            ...item,
            personalize_body: newData?.data?.personalize_body,
            personalize_subject: newData?.data?.personalize_subject,
          };
        }
        return item;
      });
      onDataChange(updatedData);
    },
    [data, onDataChange],
  );

  const {useAsSampleDocument, addAsDocument} = leadControllers(props);
  const styles = useStyles(UnverifiedActionStyles);

  const {
    containerStyle,
    bodyContainer,
    subjectContainer,
    subjectLabel,
    subjectText,
    messageText,
    headerStyle,
    nameHeaderStyle,
    contentStyle,
    lineStyle,
    dotStyle,
    subHeaderTextStyle,
    headerSubTextStyle,
    subContainerStyle,
    headerContainerStyle,
    secondHeaderStyle,
    summaryContainerStyle,
    buttonContainerStyle,
    editScrollViewStyle,
    editBodyContainerStyle,
    caretLeft,
    caretRight,
    smallDotstyle,
    smallSubHeaderStyle,
    summaryTextStyle,
    personalisedHeaderStyle,
    downArrowIcons,
    bodyScrollViewStyle,
    toggleSubContainerStyle,
    websiteSummaryStyle,
    renderBodyContainerStyle,
    imageContainerStyle,
  } = styles || {};

  let {
    _id,
    name,
    subject,
    body,
    personalize_body,
    personalize_subject,
    potential_customer,
    contact_id,
    verified,
    auto_verified,
  } = activeItem || {};

  let {
    name: contactName,
    first_name,
    last_name,
    designation,
  } = contact_id || {};
  let {website_summary, industry} = potential_customer || {};

  let {name: industryName} = industry || {};

  contactName = contactName
    ? contactName
    : `${first_name || ''} ${last_name || ''}`;
  contactName = contactName.trim();

  const renderBody = (subject, body) => {
    subject = personalizedData?.subject ? personalizedData.subject : subject;
    body = personalizedData?.body ? personalizedData.body : body;
    return (
      <View style={bodyContainer}>
        <View style={{flexDirection: 'row'}}>
          <Text style={headerStyle}>Template message</Text>
          <View style={dotStyle} />
          <Text style={nameHeaderStyle}>{name}</Text>
        </View>
        <ScrollView style={bodyScrollViewStyle}>
          {subject ? (
            <View style={subjectContainer}>
              <Text style={subjectLabel}>Subject</Text>
              <Text style={subjectText}>{subject}</Text>
            </View>
          ) : null}
          <Text style={messageText}>{body}</Text>
        </ScrollView>
      </View>
    );
  };

  const renderPersonalisedBody = (subject, body) => {
    return (
      <View
        style={{
          ...bodyContainer,
          paddingLeft: 12,
          padding: 2,
        }}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={personalisedHeaderStyle}>Personalised message</Text>
        </View>
        <>
          {loading ? (
            <View
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}>
              <ActivityIndicator color={'#999999'} />
            </View>
          ) : (
            void 0
          )}
          <ScrollView
            style={{
              ...editScrollViewStyle,
              ...(loading ? {opacity: 0.4} : {}),
            }}>
            {subject ? (
              <View style={subjectContainer}>
                <Text style={subjectLabel}>Subject</Text>
                <Text style={subjectText}>{subject}</Text>
              </View>
            ) : null}
            <PreText text={body} isAnswer textStyle={messageText} />
          </ScrollView>
        </>
      </View>
    );
  };
  const renderEditedBody = (
    personalizeId,
    personalizeSubject,
    personalizeBody,
  ) => {
    let uri = for_training ? '/campaignactiontrainings' : '/campaignactions';
    const {onSubmit} = useFormSubmit({
      uri,
      eventSourceId: [
        'campaignActionEdited',
        'actionVerified',
        'verifyActions',
      ],
    });

    return (
      <View
        key={personalizeId}
        style={{...bodyContainer, paddingTop: 2, paddingLeft: 12}}>
        <View style={editBodyContainerStyle}>
          <Text style={personalisedHeaderStyle}>Personalised message</Text>
        </View>
        <ScrollView
          style={{...editScrollViewStyle, padding: 2}}
          showsVerticalScrollIndicator={false}>
          <Form
            onSubmit={onSubmit}
            skipDefaultSave
            afterSubmit={updatedData => {
              setEditMessage(false);
              handleUpdate(updatedData);
            }}
            mode="edit"
            data={{
              _id: personalizeId,
              personalize_body: personalizeBody,
              personalize_subject: personalizeSubject,
            }}
            layoutFields={[
              {
                field: 'personalize_subject',
                type: 'textArea',
                label: 'Personalize subject',
                placeholder: 'Personalize subject',
                visible: () => personalizeSubject,
                required: true,
              },
              {
                field: 'personalize_body',
                type: 'textArea',
                label: 'Personalize body',
                placeholder: 'Personalize body',
                visible: () => personalizeBody,
                required: true,
              },
            ]}
            footer={() => {
              return {
                actions: [
                  <OutlineButton
                    text={'Cancel'}
                    onPress={() => setEditMessage(false)}
                  />,
                  <SubmitButton type="save" label="Save" />,
                ],
              };
            }}
          />
        </ScrollView>
      </View>
    );
  };

  let selectedIds = activeItem?._id ? [activeItem?._id] : [];

  if (data && data.length === 0) {
    return (
      <View>
        <Text style={summaryTextStyle}>No Data Found</Text>
      </View>
    );
  }
  // Make function to get personalised data from AI - Rajnish Trikaldarshi 27-01-2024
  const getPersonalizedDataFromAI = async () => {
    try {
      setLoading(true);
      let resp = await invoke({
        uri: `/generate-ai-message-v2`,
        props: {campaign_action_id: _id},
      });
      setLoading(false);
      if (resp?.data) {
        setPersonalizedData(resp.data);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const regenerateMessage = async ({activeId}) => {
    try {
      console.log('regenerate');
      setLoading(true);
      let resp = await invoke({
        uri: `/createContactcustomizedmessage`,
        eventSourceId: 'regenrateMessage',
        props: {campaign_action_id: activeId, for_training},
      });

      setLoading(false);

      if (resp?.data) {
        let {data} = resp || {};
        let updateData = {data: {...data, _id: activeId}};
        handleUpdate(updateData);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const verifyActions = async ({selectedIds}) => {
    try {
      let uri = for_training
        ? `/campaignactiontrainings/batchUpdate`
        : `/campaignactions/batchUpdate`;
      let resp = await invokePut({
        uri,
        props: {
          ids: selectedIds,
          updates: {
            verified: true,
          },
        },
        eventSourceId: ['actionVerified'],
      });

      let apiUrl = for_training
        ? '/finetune-add-document-personalization'
        : '/finetuneAddDocumentApproveMessage';
      if (resp?.data) {
        await invoke({
          uri: apiUrl,
          props: {
            campaign_action_ids: selectedIds,
            ...(for_training && {for_training}),
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <View style={containerStyle}>
        <View style={subContainerStyle}>
          <View style={headerContainerStyle}>
            <Text style={subHeaderTextStyle}>{contactName}</Text>
            <View style={smallDotstyle} />
            {designation ? (
              <Text style={smallSubHeaderStyle}>{designation}</Text>
            ) : null}
          </View>
          <View style={dotStyle} />
          <View style={secondHeaderStyle}>
            <Text style={subHeaderTextStyle}>{industryName || ''}</Text>
          </View>
        </View>
        <View style={subContainerStyle}>
          {data.length > 1 ? (
            <>
              <TouchableOpacity
                style={{...imageContainerStyle, marginRight: 8}}
                onPress={handleTogglePrevious}>
                <Image source={caretLeft} style={{height: 16, width: 16}} />
              </TouchableOpacity>
              <TouchableOpacity
                style={imageContainerStyle}
                onPress={handleToggleNext}>
                <Image source={caretRight} style={{height: 16, width: 16}} />
              </TouchableOpacity>
            </>
          ) : null}
          {!nextCampaignActiondetail ? (
            <>
              <MoreActions
                actions={[
                  {
                    title: 'Regenerate',
                    onPress: () => {
                      regenerateMessage({activeId: _id});
                    },
                  },
                  !for_training && {
                    title: 'Use as Sample',
                    onPress: () => {
                      useAsSampleDocument({campaign_action_id: _id});
                    },
                  },
                  auto_verified === false && {
                    title: 'Add as Document',
                    onPress: () => {
                      addAsDocument({selectedIds});
                    },
                  },
                ]}
              />
            </>
          ) : null}
        </View>
      </View>
      <View style={lineStyle} />

      <View style={{flex: 1, overflow: 'hidden', flexDirection: 'row'}}>
        <View style={{flex: 6, overflow: 'hidden'}}>
          <View
            style={{
              ...toggleSubContainerStyle,
              borderBottomLeftRadius: !showWebsiteSummary ? 8 : 0,
              borderBottomRightRadius: !showWebsiteSummary ? 8 : 0,
              borderWidth: !showWebsiteSummary ? 1 : null,
            }}>
            <View style={summaryContainerStyle}>
              <View style={{flexDirection: 'row'}}>
                <Text style={headerStyle}>Summary</Text>
                <View style={{...dotStyle, marginBottom: 4}} />
                <Text style={headerSubTextStyle}>
                  <RenderName row={activeItem} />
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => setShowWebsiteSummary(!showWebsiteSummary)}>
                <Image
                  source={downArrowIcons}
                  style={{height: 16, width: 16}}
                />
              </TouchableOpacity>
            </View>
          </View>
          {showWebsiteSummary || !body ? (
            <View style={websiteSummaryStyle}>
              <ScrollView>
                <Text style={contentStyle}>
                  {/* {PreText({text: website_summary, isAnswer: true})} */}
                  <RecursiveComponent data={website_summary} />
                </Text>
              </ScrollView>
            </View>
          ) : null}

          {body ? (
            <View style={renderBodyContainerStyle}>
              {renderBody(subject, body)}
            </View>
          ) : null}
        </View>
        <View style={{flex: 4, overflow: 'hidden'}}>
          {editMessage
            ? renderEditedBody(_id, personalize_subject, personalize_body)
            : renderPersonalisedBody(personalize_subject, personalize_body)}

          {!editMessage ? (
            <View style={buttonContainerStyle}>
              <Row gap={10}>
                {/* <Button
                  text={'Regenerate'}
                  loading={loading}
                  onPress={() => {
                    regenerateMessage({activeId: _id});
                  }}
                /> */}
                <OutlineButton
                  text={'Edit'}
                  onPress={() => setEditMessage(!editMessage)}
                />
                {!nextCampaignActiondetail ? (
                  <>
                    <Button
                      text={'Approve Message'}
                      onPress={() => {
                        verifyActions({selectedIds});
                        handleRemove(data);
                      }}
                    />
                  </>
                ) : null}
              </Row>
            </View>
          ) : null}
        </View>
      </View>
    </>
  );
};

export const CampaignActionUnverifiedForm = props => {
  let {navigation, route: {params} = {}} = props || {};
  let {data: paramsData = [], for_training} = params || {};

  const [data, setData] = useState(paramsData);
  return (
    <CampaignActionUnverifiedFormTable
      data={data}
      campaignData={params}
      navigation={navigation}
      onDataChange={data => setData(data)}
      for_training={for_training}
    />
  );
};
