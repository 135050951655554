import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';

export const FinetuneJobList = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {filter} = params || {};

  const {useSyncStatus, useEditJob} = leadControllers(props);

  return (
    <Table
      api={`/finetunejobs`}
      eventSourceId={['syncStatus', 'editJob']}
      params={params}
      filter={filter}
      columns={[
        {
          field: 'name',
          type: 'text',
          header: 'Name',
        },
        {
          field: 'reference_model',
          type: 'text',
          header: 'Reference Model',
        },
        {
          field: 'output_model',
          type: 'text',
          header: 'Output Model',
        },
        {
          field: 'status',
          type: 'text',
          header: 'Status',
        },
        {
          field: 'error',
          type: 'text',
          header: 'Error',
        },
        {
          field: 'created_at',
          type: 'date',
          label: 'Date',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: useEditJob,
        },
        {
          title: 'Sync',
          onPress: useSyncStatus,
        },
      ]}
      {...props}
    />
  );
};
