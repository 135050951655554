export const ImageViewerStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    CloseIcon: icons.Close,
    VideoContentTypeIcon: icons.VideoContentType,
    ApplicationContentTypeIcon: icons.ApplicationContentType,
    ImageContentTypeIcon: icons.ImageContentType,
    containerStyle: {
      cursor: 'default',
      flex: 1,
      overflow: 'hidden',
      backgroundColor: colors.BACKGROUND,
    },
    imageContainerStyle: {
      maxWidth: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.SURFACE2,
    },
    centerContainerStyle: {
      height: '100%',
      width: '100%',
    },
    footerContainerStyle: {
      paddingTop: 8,
      justifyContent: 'center',
    },
    deviceTypeFileNameTextStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      numberOfLines: 1,
    },
    closeContainerStyle: {
      position: 'absolute',
      top: 24,
      right: 20,
      height: 32,
      width: 32,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.SURFACE2,
      borderRadius: 100,
      cursor: 'pointer',
    },
    closeIconStyle: {
      height: 20,
      width: 20,
    },
    openInBrowserAbsoluteContainerStyle: {
      position: 'absolute',
      right: 70,
      top: 20,
    },
  };
};

export const ContainerImageViewerStyles = ({theme: {fonts, colors}}) => {
  return {
    openInBrowserAbsoluteContainerStyle: {
      position: 'absolute',
      right: 20,
      top: 20,
    },
  };
};
export const ZoomImageStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    ZoomOut: icons.ZoomOutIcon,
    ZoomIn: icons.ZoomInIcon,
    Refresh: icons.RefreshIcon,
    zoomAbsoluteContainerStyle: {
      position: 'absolute',
      right: 20,
      bottom: 20,
      alignItems: 'center',
    },
    zoomContainerStyle: {
      padding: 4,
      backgroundColor: colors.BACKGROUND,
      borderRadius: 8,
      alignItems: 'center',
    },
    zoomIconContainerStyle: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textContainerstyle: {width: 48, justifyContent: 'center'},
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const OpenInBrowseButtonStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    openInBrowserContainerStyle: {
      cursor: 'pointer',
      paddingTop: 11,
      paddingRight: 16,
      paddingBottom: 11,
      paddingLeft: 16,
      backgroundColor: colors.BACKGROUND,
      alignItems: 'center',
      borderRadius: 8,
    },
    openInBrowserTextStyle: {
      ...fonts.ACTION,
      color: colors.SECONDARY_UPPER_MEDIUM,
    },
  };
};

export const ImagePaginationStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    LeftCaret: icons.CaretLeft,
    RightCaret: icons.CaretRight,
    textContainerstyle: {width: 48, justifyContent: 'center'},
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    paginationAbsoluteContainerStyle: {
      position: 'absolute',
      left: 20,
      bottom: 20,
      alignItems: 'center',
    },
    paginationContainerStyle: {
      padding: 4,
      backgroundColor: colors.BACKGROUND,
      borderRadius: 8,
      alignItems: 'center',
    },
    moveIconContainerStyle: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
    },
    disabeMoveIconContainerStyle: {
      cursor: 'not-allowed',
      opacity: 0.4,
    },
  };
};
