import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import { Button } from '../../../components/button/Button';
import {
    GroupFilter,
    PeriodRangeFilter
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { RfrTatList } from './RfrTATList';

export const RfrTatTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {download} = useAppStateContext();
  const {filter, params: filterParams = {}} = filterValues;
  const tabs = {
    allocation: {
      label: 'Fullfilment TAT',
      view: (
        <RfrTatList
          searchValue={searchValue}
          params={params}
          filter={filter}
          filterParams={filterParams}
        />
      ),
      eventSourceId: 'TATReport',
      api: '/rfr/tat-fullfilment',
      search: searchValue,
      searchFields: 'name',
      addOnFilter: filter,
      params: {...params, ...filterParams},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Resource',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'resource',
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Mode',
              options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
              placeholder: 'Select',
              field: 'process',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },
            {
              type: 'date',
              field: 'creation_date',
              asParam: true,
              label: 'Creation date',
            },
          ]}
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/rfr/tat-fullfilment/export`,
              props: {
                ...params,
                ...filterParams,
              },
            });
          }}
        />,
      ]}
    />
  );
};
