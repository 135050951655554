import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import NestedTable from '../../../components/form/NestedTable';
import GenerateSubjectMessageCellRender from '../../../app-components/renders/GenerateSubjectMessageCellRender';

export const OutreachCampaignMessageFormNew = props => {
  const {
    route: {params},
    header = 'Add Message',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignmessages',
    eventSourceId: ['campaignMessageAdded'],
  });
  const {defaultValues, target_audience, campaign_id} = params;
  const [campaignMessageId, setCampaignMessageId] = useState();

  return (
    <Form
      fields={{
        action: 1,
        buyer_persona: {_id: 1, name: 1},
        value_proposition_id: {_id: 1, name: 1},
        inmail_message_id: {_id: 1, name: 1},
        send_after: 1,
        send_after_span: 1,
        status: 1,
        enable_thread: 1,
        enable_link_tracking: 1,
        sample_messages: {
          campaign_contact_id: {name: 1},
          message: 1,
        },
        ai_prompt: {name: 1},
        fine_tune_job: {name: 1},
        followup_prompt: {name: 1},
      }}
      onSubmit={onSubmit}
      afterSubmit={({data}) => {
        setCampaignMessageId(data?._id);
      }}
      header={header}
      submitAction={{type: 'Save', label: 'Save'}}
      defaultValues={{
        created_on: getZeroTimeDate(new Date()),
        ...defaultValues,
      }}
      computations={{
        usp_id: [
          value => {
            const {value_proposition_id: {usp_id} = {}} = value || {};
            return usp_id;
          },
          ['value_proposition_id'],
        ],
      }}
      layoutFields={[
        {
          field: 'action',
          type: 'autoComplete',
          label: 'Action',
          size: 4,
          options: [
            'connection_request',
            'direct_message',
            'inmail_message',
            'follow_request',
            'email',
            'degree_of_connection',
            'like_post',
          ],
        },

        {
          label: 'Value proposition',
          field: 'value_proposition_id',
          type: 'autoComplete',
          api: `/valuePropositions`,
          suggestionField: 'name',
          valueField: 'name',
          fields: {
            usp_id: 1,
            name: 1,
          },
          size: 4,
          filter: props => {
            const {values} = props;
            const {buyer_persona} = values || {};
            if (!buyer_persona) {
              return {_id: null};
            }
            return {
              target_audience: buyer_persona?._id,
            };
          },
        },
        {
          label: 'Message',
          field: 'inmail_message_id',
          type: 'autoComplete',
          api: `/dripinmailcontents`,
          suggestionField: 'name',
          valueField: 'name',
          size: 4,
          filter: props => {
            const {values} = props;
            const {value_proposition_id} = values || {};
            if (!value_proposition_id) {
              return {_id: null};
            }
            return {
              value_proposition: value_proposition_id?._id,
            };
          },
        },
        [
          {
            header: 'Ai Prompt',
            field: 'ai_prompt',
            placeholder: 'ai prompt',
            type: 'autoComplete',
            api: '/aiprompts',
            suggestionField: 'name',
            valueField: 'name',
            filter: {for_crm: true},
            size: 2,
          },
          {
            header: 'Followup Prompt',
            field: 'followup_prompt',
            placeholder: 'Followup Prompt',
            type: 'autoComplete',
            api: '/aiprompts',
            suggestionField: 'name',
            valueField: 'name',
            filter: {for_crm: true},
            size: 2,
          },
          {
            header: 'Finetune Model',
            field: 'fine_tune_job',
            placeholder: 'Finetune Model',
            type: 'autoComplete',
            api: '/finetunejobs',
            suggestionField: 'name',
            valueField: 'name',
            filter: {status: 'succeeded'},
            size: 2,
          },
        ],
        {
          field: 'send_after',
          type: 'number',
          label: 'Delay',
          size: 3,
        },
        {
          field: 'send_after_span',
          type: 'autoComplete',
          label: 'Span',
          options: ['Minutes', 'Hours', 'Days'],
          size: 3,
        },
        {
          field: 'status',
          type: 'autoComplete',
          label: 'Status',
          options: ['active', 'archived'],
          visible: () => mode === 'edit',
        },
        {
          label: 'Enable Thread',
          field: 'enable_thread',
          type: 'autoComplete',
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          size: 3,
          visible: props => {
            const {values} = props || {};
            const {action} = values || {};
            return action === 'email';
          },
        },
        {
          label: 'Enable Link Tracking',
          field: 'enable_link_tracking',
          type: 'autoComplete',
          size: 3,
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          visible: props => {
            const {values} = props || {};
            const {action} = values || {};
            return (
              action === 'email' ||
              action === 'inmail_message' ||
              action === 'direct_message'
            );
          },
        },

        {
          field: 'sample_messages',
          nested: true,
          label: 'Sample Messages',
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  field: 'campaign_contact_id',
                  header: 'Contact',
                  label: 'Contact',
                  type: 'autoComplete',
                  api: `/campaigncontacts`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                  filter: {
                    campaign_id: params?.campaign_id?._id,
                    website_summary_status: 'verified',
                  },
                },
                {
                  field: 'message',
                  header: 'Message',
                  type: 'textArea',
                  label: 'Message',
                },
              ]}
              columns={[
                {
                  header: 'Contact',
                  type: 'text',
                  field: 'campaign_contact_id.name',
                  size: 6,
                },
                {
                  header: 'Message',
                  field: 'message',
                  type: 'text',
                  size: 6,
                },
              ]}
            />
          ),
        },
        {
          label: 'Test Profiles',
          field: 'test_profiles',
          placeholder: 'test profile',
          type: 'multiAutoComplete',
          api: `/campaigncontacts`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
          filter: {
            campaign_id: params?.campaign_id?._id,
            website_summary_status: 'verified',
          },
        },
        {
          render: GenerateSubjectMessageCellRender,
          field: 'test_profiles',
          campaignMessage_id: campaignMessageId,
          visible: ({values}) => {
            return values?.test_profiles;
          },
        },
      ]}
      {...props}
    />
  );
};

export const OutreachCampaignMessageFormEdit = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <OutreachCampaignMessageFormNew
      mode="edit"
      api={`/campaignmessages/${_id}`}
      header={'Edit Campaign Message'}
      {...props}
    />
  );
};
