import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';
import { Text,View } from '@unthinkable/react-core-components';
import moment from 'moment';


const LWFReportTable = props => {
    const { filterParams, params } = props;
    return (
        <Table
            {...props}
            api={'/getlwfreport'}
            addOnFilter={filterParams}
            params={{...filterParams,...params}}
            eventSourceId="lwfreport"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'employee.name',
                    header: 'Employee',
                    minWidth: 200,
                },
                {
                    field: 'employee.employee_code',
                    header: 'Employee Code.',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    field: 'employee.adhar_number',
                    header: 'Adhaar umber',
                    type: 'text',
                    width: 140,
                },
                {
                    type: 'text',
                    header: "LWF Number",
                    field: "employee.lwf_number",
                    width: 140,
                },
                {
                    field: 'employee.father_name',
                    type: 'text',
                    header: 'Father Name',
                    width: 150,
                },
                {
                    field: 'employee.designation.name',
                    type: 'text',
                    header: 'Designation',
                    width: 250,
                },
                {
                    field: 'employee.joining_date',
                    header: 'Joining Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 180,
                },
                {
                    field: 'employee.relieving_date',
                    header: 'Relieving Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 180,
                },
                {
                    header: 'Duration',
                    type: 'date',
                    render: ({ row, styles }) => {
                        let { rowText } = styles;
                        return (
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ ...rowText, marginRight: 3, color: '#737373' }}>
                                    {moment(row?.from).format('DD MMM YY')}
                                </Text>
                                <Text>-</Text>
                                <Text style={{ ...rowText, marginLeft: 3, color: '#737373' }}>
                                    {moment(row?.to).format('DD MMM YY')}
                                </Text>
                            </View>
                        );
                    },
                    width: 300,
                },
                {
                    field: 'employee_share',
                    header: 'Employee Share',
                    type: 'currency',
                    width: 180,
                },
                {
                    field: 'employer_share',
                    header: 'Employer Share',
                    type: 'currency',
                    width: 180,
                },
                {
                    field: 'total_amount',
                    header: 'Amount',
                    type: 'currency',
                    width: 180,
                },

            ]}
        />
    );
};

export const LWFReportTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'LWF Report',
            view: (
                <LWFReportTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'LWFReport',
            addOnFilter: filter,
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportlwfreport',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                    ]}
                />,
            ]}
        />
    );
};
