import {useTheme} from '@react-navigation/native';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {useAssetActions} from '../actions/AssetActions';
const JsBarcode = require('jsbarcode');
const {createCanvas} = require('canvas');

export const NewITAssetTable = props => {
  const {route: {params = {}} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const color = useTheme('colors');
  const {download, user} = useAppStateContext();
  const {employee} = user;
  return (
    <Table
      eventSourceId={['NewITAsset', 'AssetRegister']}
      renderHeader={() => {
        return (
          <TableHeader
            title="New"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
            ]}
          />
        );
      }}
      search={searchValue}
      searchFields="code"
      api={`/new-it-assets`}
      columns={[
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Asset code',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.code}
                // items={[{value: row?.asset_type}]}
              />
            );
          },
        },
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        {
          header: 'Specification',
          field: 'specification',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Location',
          field: 'location.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Action',
          render: ({row, styles: {rowText = {}}}) => {
            if (
              employee?._id == '5db673b0fdfc8569be32aa76' ||
              employee?._id == '53a438926dc89c02007cd37d' ||
              employee?._id == '5a24d508c5eded3207e2873c' ||
              employee?._id == '540458a0dd329b0c9672efc1'
            ) {
              return (
                <Text
                  style={{...rowText, color: '#007AFF'}}
                  onPress={() => {
                    navigation.navigate('new-asset-register-form-for-pi', {
                      row,
                    });
                  }}>
                  Register
                </Text>
              );
            } else {
              return (
                <Text
                  style={{...rowText, color: '#007AFF'}}
                  onPress={() => {
                    navigation.navigate('new-asset-register-form', {row});
                  }}>
                  Register
                </Text>
              );
            }
          },
        },
      ]}
    />
  );
};

export const AllITAssetTable = props => {
  const {
    route: {params = {}} = {},
    navigation,
    addOnFilter = {},
    searchValue,
    tab,
  } = props;
  let {filterValues, onChangeFilter, applyFilter} = useFilter({});
  const color = useTheme('colors');
  const {download} = useAppStateContext();
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const fetchProps = {
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/all-it-assets`,
    sort: {date: -1},
    addOnFilter,
  };
  return (
    <Table
      eventSourceId={['NewITAsset', 'AssetRegister']}
      {...fetchProps}
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      Status: 'status',
                      'Invoice No.': 'invoice_no',
                      date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      // 'Tagged With': 'tagged_with', // Uncomment if needed
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                    },
                  },
                },
              }),
          },
          {
            title: 'FAR Report',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Sr. No': 'sNo',
                      'Asset classification': '',
                      Department: 'department.name',
                      Location: 'location.name',
                      Code: 'code',
                      'Name of the asset': '',
                      'Category in books of acc': '',
                      'Category in books of financial statements': '',
                      'Identification no/Serial no': 'serial_no',
                      'Assets life': '',
                      'Date of purchase': 'date',
                      'Name of supplier': 'payee_id.name',
                      'Accounting voucher no': '',
                      'Cost amount(INR)': 'current_amount',
                      'Residual value': '',
                      'Date of put of use': '',
                      'Date of expiry': '',
                      'Additions(if required)': '',
                      'Date of additions': '',
                      'Remaining life': '',
                      'Days used in year': '',
                      'Rate as per chart': '',
                      'Disposal gross': '',
                      'Disspoal Acc dep': '',
                      'Net disposable value': '',
                      'Realised value': '',
                      'Loss/gain on disposal of asset': '',
                    },
                  },
                },
              }),
          },
        ],
      }}
      // renderHeader={() => {
      //   return (
      //     <TableHeader
      //       title="All"
      //       actions={[
      //         <SearchFilter
      //           value={searchValue}
      //           onChangeFilter={onChangeFilter}
      //           placeholder="Search"
      //         />,
      //       ]}
      //     />
      //   );
      // }}
      // search={searchValue}
      // sort={{date: -1}}
      // searchFields="code"
      // api={`/all-it-assets`}
      columns={[
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Asset code',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.code}
                // items={[{value: row?.asset_type}]}
              />
            );
          },
        },
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },
        {
          header: 'Brand & model',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        {
          header: 'Specification',
          field: 'specification',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Location',
          field: 'location.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        // {
        //   header: 'Status',
        //   align: 'center',
        //   width: 180,
        //   render: ({row}) => {
        //     return (
        //       <PrimaryColumnCard
        //         primaryTitle={row?.asset_status}
        //         items={[{value: row?.status}]}
        //       />
        //     );
        //   },
        // },
      ]}
      moreActions={[
        tab == 'all' && {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        tab == 'all' && {
          title: 'Verification Status',
          onPress: ({row}) => {
            navigation.navigate('verification-asset-status', {row});
          },
        },

        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
      {...props}
    />
  );
};

export const AllDeletedITAssetTable = props => {
  const {
    route: {params = {}} = {},
    navigation,
    addOnFilter = {},
    searchValue,
    tab,
  } = props;
  let {filterValues, onChangeFilter, applyFilter} = useFilter({});
  const color = useTheme('colors');
  const {download} = useAppStateContext();
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const fetchProps = {
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/all-it-assets`,
    sort: {asset_delete_date: -1},
    addOnFilter,
  };
  return (
    <Table
      eventSourceId={['NewITAsset', 'AssetRegister']}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      Status: 'status',
                      'Invoice No.': 'invoice_no',
                      date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      // 'Tagged With': 'tagged_with', // Uncomment if needed
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Archived on',
          field: 'asset_delete_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Asset code',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.code}
                // items={[{value: row?.asset_type}]}
              />
            );
          },
        },
        {
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.type?.name}
                items={[{value: row?.internal_code}]}
              />
            );
          },
          header: 'Asset category',
        },

        {
          header: 'Brand & model',
          width: 180,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.brand}
                items={[{value: row?.model}]}
              />
            );
          },
        },
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        {
          header: 'Specification',
          field: 'specification',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        {
          header: 'Purchased On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Location',
          field: 'location.name',
          type: 'colorTag',
          colorField: randomColor,
        },
      ]}
      moreActions={[
        // {
        //   title: 'Verification Status',
        //   onPress: ({row}) => {
        //     navigation.navigate('verification-asset-status', {row});
        //   },
        // },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
      {...props}
    />
  );
};

const assetStatus = [
  null,
  'Available',
  'Scrap',
  'Stock',
  'Assigned',
  'Dead',
  'Donated/Scrap',
  'Donate/Scrap',
  'Repair',
  'Broken',
  'MW-18',
  'Faulty',
  'Donate',
  'Donated',
  'Lost',
  'Theft',
  'Sold',
  'Disposed',
  'Server Room',
  'AQ-01',
  'Unassigned',
  'Unknown',
  'Already set not identify ',
  'Scrap/faulty',
  'NA',
  'CS-02',
  'ASsigned',
  'H-18',
  'Scrap/Faulty',
  'Broken ',
  'f',
  'Conf room',
  'Scrap/Donated',
  'Broken or In Repair',
  'To Be Donated',
  'Returned',
  'To Be Scraped',
  'Unusable',
  'Under Repair',
];

export const AllITAssetTab = props => {
  const {route: {params = {}} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter = {}} = filterValues;
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllITAssetTable
          tab={'all'}
          addOnFilter={{
            asset_type: {$in: ['Asset Inventory', 'Ready To Use']},
            asset_status: {$in: ['Active', 'Inactive']},
            status: {
              $in: [
                'Lost',
                'Scrap',
                'Donated',
                'Unassigned',
                null,
                'Assigned',
                'Unusable',
                'Under Repair',
                'Returned',
                'Deleted',
              ],
            },
            ...filter,
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'ActiveITAsset',
      api: '/all-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        asset_type: {$in: ['Asset Inventory', 'Ready To Use']},
        asset_status: {$in: ['Active', 'Inactive']},
        status: {
          $in: [
            'Lost',
            'Scrap',
            'Donated',
            'Unassigned',
            null,
            'Assigned',
            'Unusable',
            'Under Repair',
            'Returned',
            'Deleted',
          ],
        },
        ...filter,
      },
      limit: 1000,
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Asset Type',
              placeholder: 'Asset Type',
              options: ['Ready To Use', 'Asset Inventory'],
              field: 'asset_type',
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'assigned_to',
            },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Asset Aggrement',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: {$in: [null, false]}},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'asset_aggrement_signed',
            },
            {
              type: 'autoComplete',
              label: 'Asset Category',
              api: '/assetMasterCategories',
              searchField: 'name',
              suggestionField: 'name',
              field: 'type',
            },
            {
              type: 'autoComplete',
              label: 'Status',
              placeholder: 'Status',
              options: [
                'Assigned',
                'Broken and in repair',
                'Deleted',
                'Donated',
                'Lost',
                'Returned',
                'Scrap',
                'To Be Donated',
                'To Be Scraped',
                'Unassigned',
                'Unusable',
              ],
              field: 'status',
            },
          ]}
        />,
      ]}
    />
  );
};

export const DeletedITAssetTab = props => {
  const {route: {params = {}} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter = {}} = filterValues;
  const tabs = {
    all: {
      label: 'Deleted',
      view: (
        <AllDeletedITAssetTable
          tab={'deleted'}
          addOnFilter={{
            ...filter,
            // asset_type: 'Ready To Use',
            status: 'Deleted',
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'ActiveITAsset',
      api: '/all-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        // asset_type: 'Ready To Use',
        status: 'Deleted',
        asset_type: {$in: ['Asset Inventory', 'Ready To Use']},
      },
      limit: 1000,
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Asset Type',
              options: ['Ready To Use', 'Asset Inventory'],
              field: 'asset_type',
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              api: '/employeeSuggestions',
              filter: {employee_status: {$exists: true}},
              placeholder: 'Select',
              suggestionField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'assigned_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Asset Aggrement',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: {$in: [null, false]}},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'asset_aggrement_signed',
            },
            {
              type: 'autoComplete',
              label: 'Asset Category',
              api: '/assetMasterCategories',
              searchField: 'name',
              suggestionField: 'name',
              field: 'type',
            },
          ]}
        />,
      ]}
    />
  );
};
