import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import {
    SelfFixedBillingTable,
    SelfTMBillingTable,
} from '../../department/views/EmployeeSalaryTable';

export const EmployeeSelfLevelHrTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    TM: {
      label: 'T & M Billing',
      view: (
        <SelfTMBillingTable
          filterParams={filter}
          period={period}
          api={'/selfleveltmSalary/payroll'}
          {...props}
        />
      ),
      eventSourceId: 't&m',
    },
    fixed: {
      label: 'Fixed',
      view: (
        <SelfFixedBillingTable
          filterParams={filter}
          period={period}
          api={'/selflevelfixedSalary/payroll'}
          {...props}
        />
      ),
      eventSourceId: 'fixed',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
            {
              type: 'autoComplete',
              field: 'employee',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
