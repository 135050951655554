import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {EmployeeSkillRequest} from '../../employee/views/EmployeeSkillRequest';
import {EmployeeRequestTable} from './EmployeeRequestTable';

export const AllocationOnOrderTypeBasisTab = props => {
  let {route: {params} = {}} = props;
  const {department} = params;
  let filterParams = {};
  const {applyFilter, filterValues} = useFilter({
    filter: {
      'project.department': department,
    },
  });
  const {filter = {}} = filterValues || {};
  const tabs = {
    employee_assignment_tmorder: {
      label: 'T&M',
      view: (
        <EmployeeSkillRequest
          filterParams={filterParams}
          {...props}
          filterValues={filterValues}
        />
      ),
      api: '/orderRequest/pending/r',
      addOnFilter: {...filter},
    },
    fixed_order_assignment: {
      label: 'Fixed',
      view: (
        <EmployeeRequestTable
          filterParams={filterParams}
          {...props}
          filterValues={filterValues}
        />
      ),
      api: '/orderRequest/pending/ot',
      addOnFilter: {...filter},
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Order',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
          ]}
        />,
      ]}
    />
  );
};
