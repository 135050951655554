import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';
import NestedTable from '../../../components/form/NestedTable';
import {plural} from 'pluralize';

const MasterTaskForm = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'MasterTask',
    uri: '/mastertasks',
    ...props,
  });

  let defaultValue = {
    parent_model: row,
    parent_model_relation: 'Event',
  };

  return (
    <Form
      api={`/mastertasks/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={defaultValue}
      fields={{
        task: 1,
        output_model: {name: 1, label: 1},
        output_model_id: {name: 1},
        output_model_view: {label: 1},
        parent_model: {name: 1, label: 1},
        parent_model_relation: 1,
        event_on: 1,
        acceptance_criteria: 1,
        event_field: 1,
        event_field_value: 1,
      }}
      layoutFields={[
        {
          label: 'Task copy',
          type: 'text',
          field: 'task',
          required: true,
        },
        {
          fields: [
            {
              label: 'Event On',
              type: 'radioGroup',
              field: 'event_on',
              required: true,
              options: ['Insert', 'Update'],
            },
            {
              label: 'Field',
              type: 'autoComplete',
              api: ({values}) => {
                let model = values?.parent_model?.name;
                if (model.indexOf(' ') != -1) {
                  model = model.split(' ').join('');
                }
                return `/${plural(model)}/metadata/fields`;
              },
              field: 'event_field',
              size: 6,
              key: 'event_field_insert',
              visible: ({values}) => values?.event_on === 'Insert',
            },
            {
              label: 'Field',
              type: 'autoComplete',
              api: ({values}) => {
                let model = values?.parent_model?.name;
                if (model.indexOf(' ') != -1) {
                  model = model.split(' ').join('');
                }
                return `/${plural(model)}/metadata/fields`;
              },
              field: 'event_field',
              size: 6,
              key: 'event_field_update',
              visible: ({values}) => values?.event_on === 'Update',
              required: true,
            },
            {
              label: 'Value',
              type: 'text',
              field: 'event_field_value',
              size: 6,
              visible: ({values}) => !!values?.event_field,
            },
          ],
        },
        {
          label: 'Output',
          fields: [
            {
              label: 'Model',
              field: 'output_model',
              type: 'cascader',
              changeOnSelect: true,
              cascades: [
                {
                  api: '/types',
                  type: 'autoComplete',
                  field: 'output_model',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: '_id',
                  fields: {
                    label: 1,
                    name: 1,
                    add_view: 1,
                  },
                },
                {
                  field: 'output_model_id',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  params: ({values}) => {
                    const {output_model} = values || {};
                    return {
                      source: output_model,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
              size: 6,
              required: true,
            },
            {
              field: 'output_model_view',
              label: 'View',
              type: 'autoComplete',
              api: '/dataentityviews',
              suggestionField: 'label',
              valueField: 'label',
              filter: ({values}) => {
                return {
                  data_entity_id: values?.output_model?._id,
                };
              },
              visible: ({values}) => values?.output_model?._id,
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddMasterTask = props => {
  const {
    route: {params},
  } = props;
  return (
    <MasterTaskForm
      header={{title: 'Create Task'}}
      submitAction="Save"
      {...props}
    />
  );
};

export const EditMasterTask = props => {
  const {
    route: {params},
  } = props;
  const {readOnly} = params;
  return (
    <MasterTaskForm
      mode="edit"
      header={{title: 'Update Task'}}
      submitAction="Save"
      readOnly={readOnly}
      {...props}
    />
  );
};

export const UpdateMasterTaskChecklist = props => {
  return (
    <MasterTaskForm
      mode="edit"
      header="Task Checklists"
      submitAction="Save"
      {...props}
      fields={{acceptance_criteria: 1}}
      layoutFields={[
        {
          field: 'acceptance_criteria',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm={true}
              fields={[
                {
                  type: 'text',
                  field: 'name',
                  placeholder: 'Add checklist item',
                },
              ]}
              columns={[
                {
                  type: 'text',
                  field: 'name',
                  header: 'Checklist',
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};
