import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AwsSetupForm = props => {
  const {
    route: {params},
  } = props;
  const {project, envName, aws} = params || {};

  let {onSubmit} = useFormSubmit({
    uri: `/projects/${project?._id}/aws`,
    postfixUri: '/terraformSetup',
    eventSourceId: 'AWSSetup',
    ...props,
  });

  return (
    <Form
      header={{title: 'AWS Setup', secondaryTitle: envName}}
      onSubmit={onSubmit}
      submitAction="Setup"
      successMessage="Process is initiated. Credentials will be sent after the process is completed to the reviewer."
      params={{
        project_id: project?._id,
      }}
      defaultValues={{
        ...aws,
      }}
      layoutFields={[
        {
          field: 'accessKey',
          type: 'password',
          label: 'Access key',
          required: true,
        },
        {
          field: 'secretAccessKey',
          type: 'password',
          label: 'Secret Access key',
          required: true,
        },
      ]}
    />
  );
};
