import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {FormErrorConfirmWrapper} from '../../../components/form/FormWrapper';

export const InternalAllocationForm = props => {
  const params = props?.route?.params;
  const {
    row,
    fromProject = false,
    projectDetail = {},
    OrderResourceRequest = {},
    fromThread = false,
  } = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'orderresourcerequests',
    ...props,
  });
  let defaultValues = {};
  if (fromProject) {
    defaultValues = {
      project_id: {_id: row?._id, project: row?.project},
      is_actual_allocation: true,
    };
  } else {
    defaultValues = {
      project_id: {
        _id: projectDetail?.project?._id,
        project: projectDetail?.project?.project,
      },
      customer: projectDetail?.customer?._id,
      is_actual_allocation: true,
      employee: {_id: row?.assigned_to?._id, name: row?.assigned_to?.name},
      internal_project_resource_request: OrderResourceRequest?._id,
      from_date: OrderResourceRequest?.from_date,
      to_date: OrderResourceRequest?.to_date,
    };
  }

  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        onSubmit={onSubmit}
        header="Allocation"
        submitAction="Save"
        defaultValues={{...defaultValues}}
        layoutFields={[
          {
            label: 'Project',
            field: 'project_id',
            type: 'autoComplete',
            api: `/projects`,
            filter: {
              projecttype: 'i',
              project_status: 'a',
            },
            suggestionField: 'project',
            valueField: 'project',
            readOnly: true,
            size: 6,
          },
          {
            label: 'Employee',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            type: 'date',
            field: 'from_date',
            label: 'Start Date',
            size: 6,
          },
          {
            type: 'date',
            field: 'to_date',
            label: 'End Date',
            size: 6,
          },
          {
            type: 'number',
            field: 'hours',
            label: 'Per Day Allocation',
            size: 6,
          },
        ]}
      />
    </FormErrorConfirmWrapper>
  );
};
