import {
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  Row,
} from '@unthinkable/react-core-components';
import React, {useEffect, useState, useRef} from 'react';

const FadeAnimatedComponent = ({item, selected, index, ScreenWidth, style}) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    if (selected) {
      setOpacity(1);
    } else if (opacity != 0) setOpacity(0);
  }, [selected]);

  return (
    <View
      key={index}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        scrollSnapAlign: 'start',
        opacity: opacity,
        transition: '0.6s ease',
        width: ScreenWidth,
        ...style,
      }}>
      {item}
    </View>
  );
};

const Swiper = props => {
  const {
    children,
    buttonGroupStyle,
    buttonRightStyle,
    buttonLeftStyle,
    buttonStyle,
    disableNextButton,
    disablePrevButton,
    buttonLeftText,
    buttonRightText,
    renderTitle,
    titleStyle,
    showsIndex,
    showsPagination,
    animate,
    showsScrollBar = false,
    autoPlay,
    autoPlayDuration,
    indexStyle,
    loop,
    width,
    style,
    items,
    showsButtons,
    pagingNavigation,
    buttonTextStyle,
    renderNextButtonOnly,
    paginationStyle,
    selectedPaginationStyle,
    setSwiperComplete,
    paginationContainerStyle,
    renderNextButton,
  } = props;

  const w = useRef(null);

  let [viewWidth, updateviewWidth] = useState();

  React.useLayoutEffect(() => {
    viewWidth = w.current?.getBoundingClientRect().width;
    updateviewWidth(viewWidth);
  }, []);

  const ScreenWidth = width ? width : viewWidth;

  const myRef = React.createRef(null);
  const size = Math.ceil(children.length / (items ? items : 1));
  const [index, setIndex] = useState(1);
  const [byScroll, setByScroll] = useState(true);

  // let [w,updateW]

  useEffect(() => {
    byScroll &&
      myRef.current?.scrollTo({
        left: (index - 1) * ScreenWidth,
        behavior: animate == 'faded' ? 'auto' : 'smooth',
      });
  }, [index]);

  const autoPlayFunc = debounce(
    e => f1(e),
    autoPlayDuration ? autoPlayDuration : 1200,
  );
  useEffect(() => {
    autoPlay && autoPlayFunc();
  }, [index]);

  const f1 = () => {
    setByScroll(true);
    if (index < size) setIndex(p => p + 1);
    else if (index == size && loop) {
      setIndex(1);
    } else if (index == size && !loop && setSwiperComplete) {
      setSwiperComplete(true);
    }
  };

  function debounce(func, timeout = 50) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const scrollHandler = () => {
    setByScroll(false);
    myRef.current &&
      setIndex(
        parseInt(myRef?.current?.scrollLeft / Math.floor(ScreenWidth)) + 1,
      );
  };

  const processChange = debounce(e => scrollHandler(e));

  const paginationHandler = i => {
    if (pagingNavigation) {
      setByScroll(true);
      setIndex(i + 1);
    }
  };

  const clickHandler = num => {
    setByScroll(true);
    if (num == 1) {
      if (index < size) {
        setIndex(p => p + 1);
      } else if (index == size && loop) {
        setIndex(1);
      } else if (index == size && !loop && setSwiperComplete) {
        setSwiperComplete(true);
      }
    } else if (num == -1) {
      if (index > 1) {
        setIndex(p => p - 1);
      } else if (index == 1 && loop) {
        setIndex(size);
      }
    }
  };

  return (
    <View
      style={{
        flex: 1,
        width: ScreenWidth,
        overflow: 'hidden',
        alignItems: 'center',
      }}
      // getRef={(e) => (w.current = e)}
      ref={w}>
      {renderTitle && (
        <Text
          style={{
            margin: 5,
            fontSize: 20,
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'urbanist',
            textTransform: 'uppercase',
            ...titleStyle,
          }}>
          {renderTitle}
        </Text>
      )}
      {showsIndex && (
        <View style={{margin: 5, textAlign: 'center'}}>
          <Text style={indexStyle}>
            {index}/{size}
          </Text>
        </View>
      )}
      <View style={{flex: 1, width: ScreenWidth, overflow: 'hidden'}}>
        <ScrollView
          style={{
            scrollBehavior: animate == 'faded' ? null : 'smooth',
          }}
          onScroll={processChange}
          // getRef={(e) => (myRef.current = e)}
          ref={myRef}
          horizontal={true}
          showsHorizontalScrollIndicator={showsScrollBar}
          showsVerticalScrollIndicator={showsScrollBar}>
          {children.map((item, i) => {
            return animate == 'faded' || animate == 'fadedWithScroll' ? (
              <FadeAnimatedComponent
                item={item}
                selected={index - 1 === i}
                index={i}
                ScreenWidth={ScreenWidth}
                style={style}
              />
            ) : (
              <View
                key={i}
                style={{
                  scrollSnapAlign: 'start',
                  opacity: 1,
                  width: ScreenWidth / (items ? items : 1),
                  ...style,
                }}>
                {item}
              </View>
            );
          })}
        </ScrollView>
      </View>
      {showsPagination && (
        <View
          style={{
            alignItems: 'center',
            ...paginationContainerStyle,
          }}>
          <Row
            gap={8}
            style={{
              display: 'flex',
              margin: 10,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {new Array(size).fill().map((_, i) => {
              return (
                <TouchableOpacity
                  onPress={() => paginationHandler(i)}
                  style={
                    i + 1 == index
                      ? {
                          width: 24,
                          height: 4,
                          borderRadius: 100,
                          backgroundColor: '#0f0',
                          transition: '0.3s ease',
                          ...selectedPaginationStyle,
                        }
                      : {
                          width: 4,
                          height: 4,
                          borderRadius: 100,
                          backgroundColor: '#BBED8C',
                          transition: '0.3s ease',
                          ...paginationStyle,
                        }
                  }
                />
              );
            })}
          </Row>
        </View>
      )}
      {showsButtons && (
        <View
          style={{
            display: 'flex',
            margin: 20,
            justifyContent: 'center',
            flexDirection: 'row',
            ...buttonGroupStyle,
          }}>
          {!renderNextButtonOnly ? (
            <TouchableOpacity
              disabled={
                (disablePrevButton || index == 1) && !loop ? true : false
              }
              onPress={() => clickHandler(-1)}
              style={{
                alignSelf: 'center',
                backgroundColor: 'red',
                padding: 15,
                borderRadius: 10,
                cursor: 'pointer',
                opacity: (disablePrevButton || index == 1) && !loop ? 0.4 : 1,
                ...buttonStyle,
                ...buttonLeftStyle,
              }}
              activeOpacity={0.6}>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  color: 'white',
                  ...buttonTextStyle,
                }}>
                {buttonLeftText ? buttonLeftText : 'BACK'}
              </Text>
            </TouchableOpacity>
          ) : null}

          <View style={{margin: 10}} />

          {renderNextButton ? (
            <TouchableOpacity onPress={() => clickHandler(+1)}>
              {renderNextButton({clickHandler})}
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={() => clickHandler(+1)}
              style={{
                alignSelf: 'center',
                backgroundColor: 'red',
                padding: 15,
                borderRadius: 10,
                cursor: 'pointer',
                opacity:
                  (disableNextButton || index == size) && !loop ? 0.4 : 1,
                ...buttonStyle,
                ...buttonRightStyle,
              }}
              activeOpacity={0.6}
              disabled={
                (disableNextButton || index == size) && !loop ? true : false
              }>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  color: 'white',
                  ...buttonTextStyle,
                }}>
                {buttonRightText ? buttonRightText : 'NEXT'}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
};

export default React.memo(Swiper);
