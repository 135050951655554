import React from 'react';
import {Header} from '@unthinkable/react-screen-components';
import {useStyles} from '@unthinkable/react-theme';
import {TableHeaderStyles} from './theme';
import {useNavigation} from '@react-navigation/native';
import {IconButton} from '@unthinkable/react-button';

export const TableHeader = ({closeable, ...props}) => {
  const styles = useStyles(TableHeaderStyles);
  let navigation = useNavigation();
  let {
    close: {icon: closeIcon, ...closeStyles},
    ...rest
  } = styles;

  let closeAction;

  if (closeable) {
    closeAction = (
      <IconButton
        styles={closeStyles}
        icon={closeIcon}
        onPress={() => {
          navigation.goBack();
        }}
      />
    );
  }

  return <Header styles={rest} closeAction={closeAction} {...props} />;
};
