import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {DepartmentRolesTable} from './DepartmentRoleTable';

const DepartmentRoleTab = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;
  const tabs = {
    Active: {
      label: 'Roles',
      view: <DepartmentRolesTable {...props} />,
      actions: [
        <AddButton
          title=" Add Roles"
          eventSourceId="DepartmentRole"
          view="add-department-role-with-skill"
          params={params}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export default DepartmentRoleTab;
