import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddWorkInProgressForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/markDeliveredDelivery/${row?._id}`,
    eventSourceId: ['allocatedResource'],
    method: 'put',
  });
  return (
    <Form
      header={`Submit WIP (Milestone ${row?.milestone?.order_milestone_no})`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const {is_delivered, end_date} = data;
        return {
          data: {
            ...data,
            customer: row?.customer?._id,
            milestone: row?.milestone?._id,
            delivery_owner_id: row?.delivery_owner_id?._id,
            approval_status: is_delivered ? 'Delivered' : 'Partially Delivered',
            delivered_date: end_date,
            order_type: 'ot',
            order: row?.order?._id,
            is_internal: row?.is_internal,
            is_locomo: true,
            due_date: end_date,
            prduct: row.product,
            project: row.project,
          },
        };
      }}
      defaultValues={{is_delivered: false}}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Start Date',
          field: 'start_date',
          type: 'date',
          required: true,
        },
        {
          label: 'End Date',
          field: 'end_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Delivered Hours',
          field: 'billed_hours',
          type: 'number',
          required: true,
        },
        {
          label: 'Mark Delivered',
          field: 'is_delivered',
          type: 'radioGroup',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
