export const FileInputStyles = ({theme: {colors, fonts, icons}}) => {
  return {
    gap: 12,
    input: {
      uploadIcon: icons.Upload,
      uploadText: 'Upload Attachment',
      gap: 8,
      container: {
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        padding: 4,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: colors.NEUTRAL_LOW,
        borderRadius: 8,
        backgroundColor: colors.SURFACE1,
      },
      text: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_MEDIUM,
        numberOfLines: 1,
      },
    },
    error: {
      borderColor: colors.ERROR_HIGH,
    },
    container: {
      borderColor: colors.OUTLINE,
    },
    removeContainer: {
      backgroundColor: colors.BACKGROUND,
      borderColor: colors.OUTLINE,
    },
    removeText: {
      ...fonts.FOOTNOTE,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const MultipleAttachmentPreviewStyles = ({
  theme: {colors, fonts, icons},
}) => {
  return {
    itemGap: 12,
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    scrollStyle: {
      style: {flex: 1, scrollBehavior: 'smooth', paddingTop: 8},
      leftIcon: icons.CaretRight,
      rightIcon: icons.CaretRight,
      iconContainerStyle: {
        marginTop: 8,
        height: 28,
        width: 28,
        borderRadius: 14,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.SURFACE2,
        marginLeft: 6,
        marginRight: 6,
      },
      iconStyle: {
        height: 14,
      },
      iconTextStyle: {
        padding: 6,
        ...fonts.ACTION,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    itemStyle: {
      containerStyle: {
        cursor: 'pointer',
        justifyContent: 'center',
        height: 70,
        minWidth: 100,
        maxWidth: 150,
        padding: 4,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        borderRadius: 4,
        backgroundColor: colors.BACKGROUND,
      },
      selectedContainerStyle: {
        cursor: 'pointer',
        justifyContent: 'center',
        height: 70,
        minWidth: 100,
        maxWidth: 150,
        padding: 4,
        borderWidth: 3,
        borderColor: colors.BRAND_UPPER_MEDIUM,
        borderRadius: 4,
        backgroundColor: colors.BACKGROUND,
      },
      textContainerStyle: {
        maxWidth: 200,
        padding: 8,
      },
      textStyle: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
        numberOfLines: 1,
      },
      removePositionStyle: {
        position: 'absolute',
        top: -8,
        right: -8,
      },
      removeContainerStyle: {
        cursor: 'pointer',
        height: 18,
        width: 18,
        borderRadius: 18,
        backgroundColor: colors.BACKGROUND,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        alignItems: 'center',
        justifyContent: 'center',
      },
      removeTextStyle: {
        ...fonts.FOOTNOTE,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
  };
};
