import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

export const VendorInvoiceDetailsTable = props => {
  const params = props.route.params;
  return (
    <Table
      api={`/vendorInvoices/all`}
      renderHeader={() => <TableHeader title="Vender Invoice" />}
      params={params}
      columns={[
        {
          type: 'date',
          field: 'invoice_date',
          header: 'Date',
        },
        {
          type: 'text',
          field: 'invoice_no',
          header: 'Invoice Number',
        },
        {
          type: 'currency',
          formatOptions: {maximumFractionDigits: 0},

          field: 'voucher_amount',
          header: 'Amount',
          aggregate: true,
        },
        {
          type: 'currency',
          field: 'base_payable_amount',
          header: 'Total Base Amount',
          aggregate: true,
        },
        {
          type: 'currency',
          field: 'payable_amount',
          header: 'Total Amount',
          aggregate: true,
        },
      ]}
    />
  );
};
