import {Form} from '../../../components/form/Form';
import {useFetchData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const ProjectResourceWorkingForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projectresourceworkingdata',
    eventSourceId: [
      'unapprove-project-resource',
      'approve-project-resource',
      'projectResourceWorking',
    ],
  });

  return (
    <Form
      api={`/projectresourceworkingdata/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'Approved'}};
      }}
      defaultValues={{
        status: 'Approved',
      }}
      fields={{
        billed_hours: 1,
        status: 1,
      }}
      layoutFields={[
        {
          type: 'number',
          field: 'billed_hours',
          placeholder: 'Billed Hrs',
          label: 'Billed Hrs',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateProjectResourceWorkingForm = props => {
  return <ProjectResourceWorkingForm mode="edit" header="Update " {...props} />;
};

export const PotentialAdjust = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/adjustPotential',
    eventSourceId: 'revenue-invoice',
  });

  return (
    <Form
      defaultValues={{
        delivery_id: row._id,
        variable_potential: row?.variable_potential,
        pre_variable_potential: row?.variable_potential,
      }}
      onSubmit={onSubmit}
      header="Adjust Potential"
      submitAction="Save"
      layoutFields={[
        {
          label: 'Variable Potential',
          field: 'variable_potential',
          type: 'number',
        },
      ]}
      {...props}
    />
  );
};

export const MarkDeliveryUnadjust = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projectresourceworkingdata',
    eventSourceId: 'revenue-invoice',
  });
  return (
    <Form
      defaultValues={{
        _id: row._id,
        pending_amount: row?.pending_amount,
        pending_billing_hrs: row?.pending_billing_hrs,
        base_pending_amount: row?.pending_amount * row?.exchange_rate || 1,
        status: 'New',
        less_amount_paid: 0,
      }}
      onSubmit={onSubmit}
      header="Adjust Potential"
      submitAction="Save"
      layoutFields={[
        {
          placeholder: 'Pending Amount',
          field: 'pending_amount',
          type: 'number',
          label: 'Pending Amount',
          size: 6,
          onChangeValue: async (value, e, {setFieldValue}) => {
            if (value) {
              setFieldValue('base_pending_amount', value * row?.exchange_rate);
            }
          },
        },
        {
          placeholder: 'Pending Billing Hrs',
          field: 'pending_billing_hrs',
          type: 'number',
          label: 'Pending Billing Hrs',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
