import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFetchData} from '../../../controllers/useFetchData';
export const MasterPromptForm = props => {
  const {
    route: {params = {}},
    defaultValues,
  } = props;

  const {project, row} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Master Prompt',
    uri: '/masterprompts',
    ...props,
  });

  let generalFields = [
    {
      label: 'Prompt',
      placeholder: 'Prompt',
      type: 'textArea',
      field: 'prompt',
      required: true,
    },
  ];

  return (
    <Form
      fields={{
        prompt: 1,
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[...generalFields]}
      {...props}
    />
  );
};

export const MasterPromptViewForm = props => {
  return <MasterPromptForm header="Add Master Prompt" {...props} />;
};

export const UpdateMasterPromptForm = props => {
  const {
    route: {params},
  } = props;
  let {project, row} = params;
  const {data} = useFetchData({
    api: '/masterprompts',
    filter: {
      _id: row?._id,
    },
    only: true,
    fields: {
      prompt: 1,
    },
  });

  if (!data) {
    return null;
  }

  return (
    <MasterPromptForm
      header={'Update Master prompt'}
      defaultValues={data}
      {...props}
    />
  );
};
