import React from 'react';
import {Form} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';

export const ModelViewForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    uri: '/projectmodelviews',
    eventSourceId: 'ProjectModelView',
  });

  return (
    <Form
      afterSubmit={params?.afterSubmit}
      onSubmit={onSubmit}
      defaultValues={{
        model_id: params?.dataEntity?._id || params?.model,
        name: params?.searchValue,
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          label: 'Path',
          field: 'path',
          type: 'text',
        },
        {
          label: 'Query',
          field: 'query',
          type: 'json',
          inputProps: {
            minRows: 6,
            maxRows: 10,
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddModelViewForm = props => {
  return <ModelViewForm header={{title: 'Add View'}} {...props} />;
};

export const EditModelViewForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <ModelViewForm
      mode={'edit'}
      initialValues={params?.row}
      header={{title: 'Edit View'}}
      {...props}
    />
  );
};
