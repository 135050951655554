import React from 'react';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {AddButton} from '../../../components/button/AddButton';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';

export const UserSetupTable = props => {
  const {navigation, params} = props;
  const {filter, onChangeFilter, searchValue} = useFilter();
  const {user, download} = useAppStateContext();
  return (
    <Table
      {...props}
      renderHeader={() => (
        <TableHeader
          actions={
            <>
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />
              <AddButton
                title={'User'}
                view={'add-user-setup'}
                params={{
                  user,
                  ...params,
                }}
              />
            </>
          }
        />
      )}
      searchFields="name"
      addOnFilter={{...filter, userType: 'employee'}}
      search={searchValue}
      eventSourceId={['usersetup']}
      api={`/users`}
      fields={{
        name: 1,
        mobile: 1,
        email: 1,
        userType: 1,
        color: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-user-setup`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          render: ({row}) => {
            return (
              <UserAvatarChip value={{color: row?.color, name: row?.name}} />
            );
          },
          minWidth: 300,
        },
        {
          type: 'text',
          field: 'mobile',
          header: 'Mobile',
        },
        {
          type: 'text',
          field: 'email',
          header: 'Email',
        },
        // {
        //   type: 'text',
        //   field: 'userType',
        //   header: 'UserType',
        // },
      ]}
    />
  );
};
