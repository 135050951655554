import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';

export const MarkLeadLostForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  return (
    <Form
      defaultValues={{
        lead_status: 'Lost',
        lost_date: getZeroTimeDate(new Date()),
        _id: row?._id,
      }}
      onSubmit={onSubmit}
      header={'Mark lead Lost'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Lost Reason',
          field: 'lost_description_comment',
          type: 'autoComplete',
          options: [
            'Prospect not relevant',
            'DNC/Unsubscribe',
            'Not interested',
          ],
        },
        {
          placeholder: 'Lost Description',
          field: 'lost_description',
          type: 'text',
        },
      ]}
    />
  );
};

export const LeadDescriptionPopUp = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {CAPTION_LARGE} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_HIGH,
          fontSize: 13,
        }}>
        {row?.lost_description}
      </Text>
    </>
  );
};

export const CandidateDescriptionPopUp = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {CAPTION_LARGE} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_HIGH,
          fontSize: 13,
        }}>
        {row?.outcome_description}
      </Text>
    </>
  );
};
