import {useFilter} from '@unthinkable/react-filters';

import {Table} from '../../../../components/table/Table';
import {TableHeader} from '../../../../components/table/Headers';
import {AddButton} from '../../../../components/button/AddButton';
import {Button} from '../../../../components/button/Button';
import {useInvoke} from '../../../../controllers/useInvoke';
import {PrimaryColumnCard} from '../../../../components/card/PrimaryColumnCard';
import {SearchFilter} from '../../../../components/filter/Filters';
import {useFetchData} from '../../../../controllers/useFetchData';
import {useToast} from '@unthinkable/react-toast';
import {Col, View} from '@unthinkable/react-core-components';
import Text from '@unthinkable/react-core-components/src/Text/BaseText';
import {useTheme} from '@unthinkable/react-theme';

export const MappedUserstoryScreen = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const {asset, module_id, api} = params;

  const result = useFetchData({
    api,
    eventSourceId: ['MapUserstory'],
    filter: {_id: asset?.source_id || asset?._id},
    fields: {
      user_story_ids: {userstory: 1, title: 1},
    },
  });

  const {data, ...fetchProps} = result || {};
  const userstoriesData = data?.[0]?.user_story_ids;

  const addUserstory = !!module_id;

  return (
    <Table
      data={userstoriesData}
      renderHeader={_props => {
        return (
          <TableHeader
            title={'User Stories'}
            actions={[
              addUserstory && (
                <AddButton
                  title={'Map User Stories'}
                  view={'map-userstories'}
                  params={{...params, userstoriesData}}
                />
              ),
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'User Story',
          render: _props => {
            const {row} = _props;
            const primaryTitle = row?.title || row?.userstory;
            const secondaryTitle = row?.title ? row?.userstory : void 0;

            return (
              <PrimaryColumnCard
                primaryTitle={primaryTitle}
                secondaryTitle={secondaryTitle}
              />
            );
          },
        },
      ]}
      onRowPress={({row}) => {
        navigation.navigate('userstory-detail', {
          ...params,
          userstory: row,
          isReadOnly: true,
        });
      }}
      {...fetchProps}
    />
  );
};

export const SelectUserstoryTable = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const toast = useToast();
  const {project, module, asset, api, userstoriesData, assetName, assetType} =
    params;
  const {colors, fonts} = useTheme();

  const {user_story_ids} = asset;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'MapUserstory',
    close: true,
  });

  const {searchValue, onChangeFilter} = useFilter({});
  const defaultSelectedIds = userstoriesData
    ? userstoriesData?.map(story => story?._id)
    : user_story_ids?.map(story => story?._id);

  return (
    <Table
      renderHeader={_props => {
        return (
          <>
            <TableHeader
              title={'User Stories'}
              secondaryTitle={`Module: ${module?.module}`}
              actions={[
                <Button
                  text={'Map'}
                  onPress={async () => {
                    const res = await invoke({
                      uri: `${api}/${asset?.source_id || asset?._id}`,
                      props: {
                        user_story_ids: _props?.selectedIds,
                      },
                    });
                    if (res.message === 'Success') {
                      toast({message: 'User Stories mapped successfully.'});
                    } else {
                      toast({
                        message:
                          'Some error occured while mapping user stories.',
                        type: 'Error',
                      });
                    }
                  }}
                />,
              ]}
            />
            <Col gap={12} style={{marginBottom: 12}}>
              <View
                style={{
                  backgroundColor: colors.SURFACE2,
                  padding: 12,
                  borderRadius: 12,
                }}>
                <Text style={{...fonts.BODY2, color: colors.NEUTRAL_MEDIUM}}>
                  These are user stories from the module "{module?.module}".
                  Select and map the relevant user stories for this "{assetType}
                  " - "{assetName}".
                </Text>
              </View>
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />
            </Col>
          </>
        );
      }}
      search={searchValue}
      searchFields={['userstory', 'title']}
      eventSourceId={['UserStory']}
      api={`/projectUserstories`}
      fields={{
        title: 1,
        userstory: 1,
      }}
      filter={{
        project_id: project?._id,
        module_id: module?._id,
      }}
      selection={{defaultSelectedIds}}
      columns={[
        {
          header: 'User Story',
          render: _props => {
            const {row} = _props;
            const primaryTitle = row?.title || row?.userstory;
            const secondaryTitle = row?.title ? row?.userstory : void 0;

            return (
              <PrimaryColumnCard
                primaryTitle={primaryTitle}
                secondaryTitle={secondaryTitle}
              />
            );
          },
        },
      ]}
      onRowPress={({row}) => {
        navigation.navigate('userstory-detail', {
          ...params,
          userstory: row,
          isReadOnly: true,
        });
      }}
    />
  );
};
