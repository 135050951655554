import React from 'react';
import {Platform, Row, Text} from '@unthinkable/react-core-components';
import {resolveExp} from '@unthinkable/react-utils';
import {useTheme} from '@unthinkable/react-theme';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {IconTextLabel} from '../../../app-components/IconTextLabel';

export const TaskWithActionRenderer = ({
  row,
  actionField,
  taskField,
  styles,
  numberOfLines = 1,
  colorField,
  hideDisqualifiedText,
}) => {
  const taskName = resolveExp(row, taskField);
  const actionName = resolveExp(row, actionField);
  const color = colorField ? resolveExp(row, colorField) : void 0;
  let {HIGH} = useAccentColor(color);
  let isEqual = actionName?.toLowerCase() === taskName?.toLowerCase();
  const {fonts, colors, icons} = useTheme();

  return (
    <Row gap={20}>
      <Text
        title={taskName}
        style={styles?.rowText}
        numberOfLines={numberOfLines}>
        <Text
          {...(Platform.OS === 'web' ? {as: 'span'} : {})}
          style={{...styles?.rowText, color: HIGH}}>
          {actionName}{' '}
        </Text>
        {!isEqual ? taskName : ''}
      </Text>
      {!hideDisqualifiedText && row.disqualified ? (
        <IconTextLabel
          value={'Disqualified'}
          iconStyle={{height: 10, width: 10}}
          textStyle={{...fonts.CAPTION_LARGE, color: colors.ERROR_HIGH}}
          icon={icons.DisqualifiedRed}
        />
      ) : null}
    </Row>
  );
};
