import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const VideoForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Video Ad',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/videos',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/videos/${row?._id}`}
      fields={{
        video_title: 1,
        video_link: 1,
        ad_creative_id: {ad_head_line: 1},
        reference: 1,
        attachment: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
      }}
      layoutFields={[
        {
          placeholder: 'Video',
          field: 'video_title',
          type: 'text',
        },
        {
          placeholder: 'URL',
          field: 'video_link',
          type: 'text',
        },
        {
          placeholder: 'Ad Creative',
          field: 'ad_creative_id',
          type: 'autoComplete',
          api: `/adcreatives`,
          suggestionField: 'ad_head_line',
          valueField: 'ad_head_line',
        },
        {
          field: 'reference',
          type: 'file',
          placeholder: 'Reference*',
          multiple: true,
        },
        {
          field: 'attachment',
          type: 'file',
          placeholder: 'Upload file*',
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditVideoForm = props => {
  return <VideoForm mode="edit" header={'Edit Video Ad'} {...props} />;
};
