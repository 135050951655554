import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';

export const GeographicCategoryTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="Geographic"
      api={`/geographylocations`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-geographic-category`, {row});
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'score',
          header: 'Score',
        },
      ]}
    />
  );
};
