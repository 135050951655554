import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {Text, View} from '@unthinkable/react-core-components';

const FinetuneOutputDataRender = ({data}) => {
  const {finetune_output, expected_ouput} = data || {};

  return (
    <View style={{flex: 1, flexDirection: 'row'}}>
      <View style={{flex: 1}}>
        <Text
          style={{
            fontSize: 16,
            fontFamily: 'Inter-SemiBold',
            lineHeight: '20px',
          }}>
          Expected Output
        </Text>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Inter-Regular',
            lineHeight: '20px',
          }}>
          {expected_ouput}
        </Text>
      </View>
      <View style={{flex: 1}}>
        <Text
          style={{
            fontSize: 16,
            fontFamily: 'Inter-SemiBold',
            lineHeight: '20px',
          }}>
          Finetune Output
        </Text>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Inter-Regular',
            lineHeight: '20px',
          }}>
          {finetune_output}
        </Text>
      </View>
    </View>
  );
};

export const FinetuneJobSelectForm = props => {
  const {
    route: {params},
  } = props;

  let invoke = useInvoke({method: 'post'});
  const {defaultValues, potential_customer} = params || {};

  let [finetuneData, setFinetuneData] = useState({});

  const getFineTuneOutput = async props => {
    try {
      let resp = await invoke({
        uri: `/finetune/test-mode`,
        props: {...props},
      });
      if (resp?.data) {
        setFinetuneData(resp.data);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Form
      onSubmit={getFineTuneOutput}
      submitAction={{type: 'Save', label: 'Save'}}
      header={'Select Job'}
      defaultValues={{
        ...defaultValues,
        potential_customer,
      }}
      layoutFields={[
        {
          label: 'Job',
          field: 'jobId',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          render: (props, value) => {
            return <FinetuneOutputDataRender data={finetuneData} />;
          },
          visible: () => {
            if (Object.keys(finetuneData).length !== 0) {
              return true;
            }
          },
        },
      ]}
      {...props}
    />
  );
};
