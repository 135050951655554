import React from 'react';
import {Wrap} from '@unthinkable/react-core-components';
import {hasValue} from '@unthinkable/react-utils';
import Chip from './Chip';

const Chips = ({children, value, ...props}) => {
  if (!children && !hasValue(value)) {
    return null;
  }
  if (!Array.isArray(value)) {
    value = [value];
  }
  return (
    <Wrap gap={4}>
      {children ? value.map(_val => <Chip value={_val} {...props} />) : void 0}
    </Wrap>
  );
};

export default Chips;
