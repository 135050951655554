import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AddCompanyForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/userorganizations',
  });
  return (
    <Form
      header={'Add Company'}
      onSubmit={onSubmit}
      afterSubmit={params?.afterSubmit}
      defaultValues={params?.defaultValues}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Company Name',
        },
        {
          type: 'text',
          field: 'domain',
          label: 'Domain',
        },
      ]}
      {...props}
    />
  );
};
