import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';
import {TextRenderer} from '../../../components/form/Renderers';
import {TouchableOpacity} from '@unthinkable/react-core-components';

const RenderOverAllScore = props => {
  const {row} = props;
  const {overall_score} = row;
  if (overall_score) {
    return <RatingRenderer value={overall_score} />;
  }
  return (
    <TextRenderer {...props} value={'Feedback'} style={{color: '#007AFF'}} />
  );
};

export const TraineeToTrainerFeedbacks = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_batch, employee_training} = params;
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            title={`${training_batch.name} (${employee_training?.employee?.name})`}
            actions={[
              employee_training?.trainee_to_training_feedback?._id ? (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('trainee-to-training-feedback', {
                      feedback:
                        employee_training?.trainee_to_training_feedback?._id,
                      training_batch,
                    });
                  }}>
                  <TextRenderer
                    value={'Give Training Feedback'}
                    style={{color: '#007AFF'}}
                  />
                </TouchableOpacity>
              ) : (
                void 0
              ),
            ]}
          />
        );
      }}
      eventSourceId={['traineeToTrainerFeedbacks']}
      api="/traineeToTrainerFeedbacks"
      filter={{
        employee_training: employee_training._id,
      }}
      fields={{
        skill_topic: {
          name: 1,
        },
        mentor: {
          name: 1,
        },
        feedback_date: 1,
        overall_score: 1,
        mentor_subjective_feedback: 1,
      }}
      params={params}
      columns={[
        {
          field: 'skill_topic.name',
          header: 'Skill Topic',
          type: 'text',
          visible: training_batch.mentorship_type == 'Topic-wise Trainers',
        },
        {
          header: 'Trainer',
          type: 'userAvatar',
          field: 'mentor',
        },
        {
          header: 'Feedback Date',
          type: 'date',
          field: 'feedback_date',
          width: 150,
        },
        {
          header: 'Overall Score',
          render: RenderOverAllScore,
          onPress: ({row}) => {
            navigation.navigate('edit-trainee-to-trainer-feedback-form', {
              feedback: row,
              employee_training,
              training_batch,
            });
          },
          width: 120,
        },
      ]}
    />
  );
};

export const TraineeToTrainerFeedbacksRM = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_batch, mentor} = params;
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            title={`Trainer Feedback`}
            secondaryTitle={`${mentor.name}`}
          />
        );
      }}
      eventSourceId={['traineeToTrainerFeedbacks']}
      api="/traineeToTrainerFeedbacks"
      filter={{
        mentor: mentor._id,
        overall_score: {
          $exists: true,
        },
        training_batch: training_batch._id,
      }}
      fields={{
        skill_topic: {
          name: 1,
        },
        employee: {
          name: 1,
          color: 1,
        },
        feedback_date: 1,
        overall_score: 1,
        mentor_subjective_feedback: 1,
      }}
      params={params}
      columns={[
        {
          field: 'skill_topic.name',
          header: 'Skill Topic',
          type: 'text',
          visible: training_batch.mentorship_type == 'Topic-wise Trainers',
        },
        {
          header: 'Trainee',
          type: 'userAvatarChip',
          field: 'employee',
        },
        {
          header: 'Feedback Date',
          type: 'date',
          field: 'feedback_date',
          width: 150,
        },
        {
          header: 'Overall Score',
          render: RenderOverAllScore,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedback-detail-rm', {
              feedback: row,
              training_batch,
            });
          },
          width: 120,
        },
      ]}
    />
  );
};
