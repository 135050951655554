import React from 'react';
import {Form} from '../../../components/form/Form';
// Form to view email reply - Parveen -23-12-2023
export const OutreachCampaignEmailReplyFormEdit = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;

  return (
    <Form
      mode="edit"
      readOnly={true}
      api={`/communications/${_id}`}
      header={'View Reply Message'}
      fields={{
        _id: 1,
        description: 1,
      }}
      layoutFields={[
        {
          type: 'textArea',
          field: 'description',
          header: 'Description',
        },
      ]}
    />
  );
};
