import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import React from 'react';
import {
  UserAvatarChip,
  UserAvatarGroup,
} from '../../../components/avatar/UserAvatar';
import {useFetchData} from '../../../controllers/useFetchData';
import {HeaderStyle, graphHeaderStyle} from './theme';
import DurationChip from '../../../components/chip/DurationChip';
import {Header as HeaderComponent} from '@unthinkable/react-screen-components';

export const FeatureAssetSummary = ({api, params, eventSourceId}) => {
  const {data: {assignees, est_hours} = {}} = useFetchData({
    api,
    params,
    eventSourceId,
  });
  if (!assignees && !est_hours) {
    return null;
  }

  return (
    <Row style={{alignItems: 'center'}} gap={12}>
      {assignees?.length === 1 ? (
        <UserAvatarChip value={assignees?.[0]} />
      ) : assignees?.length > 1 ? (
        <UserAvatarGroup value={assignees} />
      ) : (
        void 0
      )}
      {est_hours ? <DurationChip showIcon={true} value={est_hours} /> : void 0}
    </Row>
  );
};

export const FeatureAssetHeader = ({
  inlineForm,
  title,
  rightActions,
  ...props
}) => {
  let {textStyle} = useStyles(HeaderStyle);
  if (typeof title === 'function') {
    title = title(props);
  }
  return (
    <Row
      style={{
        alignItems: 'center',
        gap: 8,
      }}>
      <View style={{flex: 1, overflow: 'hidden'}}>
        {title ? <Text style={textStyle}>{title}</Text> : inlineForm}
      </View>
      <Row style={{marginBottom: 12}} gap={8}>
        {rightActions}
      </Row>
    </Row>
  );
};

const Header = ({title}) => {
  let {textStyle, containerStyle} = useStyles(HeaderStyle);
  return (
    <Col style={containerStyle}>
      <Text style={textStyle}>{title}</Text>
    </Col>
  );
};

export default Header;

export const GraphHeader = props => {
  const style = useStyles(graphHeaderStyle);
  return <HeaderComponent {...props} styles={style} gap={12} />;
};
