export const BadgeStyles = ({theme: {fonts, colors}}) => {
  return {
    backgroundColor: colors.NEUTRAL_MEDIUM,
    absolute: {position: 'absolute', top: -8, right: -10},
    badge: {
      container: {
        backgroundColor: colors.NEUTRAL_MEDIUM,
        borderRadius: 100,
        minWidth: 20,
        maxWidth: 30,
        height: 14,
        paddingRight: 3,
        paddingLeft: 3,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      text: {
        numberOfLines: 1,
        ...fonts.FOOTNOTE,
        color: colors.BACKGROUND,
      },
    },
  };
};
