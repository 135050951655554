import {useCallback} from 'react';
import {useNavigation} from '@react-navigation/native';
import {useAppStateContext} from '../providers/AppState';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useToast} from '@unthinkable/react-toast';

const invokeEvents = ({eventSourceId, notify}) => {
  if (typeof eventSourceId === 'string') {
    eventSourceId = [eventSourceId];
  }

  if (!eventSourceId?.length) {
    return;
  }

  const notifyEvent = (event, data) => {
    notify && notify(event, data);
  };

  let eventName = 'dataUpdated';

  eventSourceId.forEach(event => {
    notifyEvent(eventName, {id: event});
  });
};

export const useInvoke = ({
  close,
  method = 'post',
  eventSourceId = [],
  setLoading,
  throwError,
} = {}) => {
  const {post} = useAppStateContext();
  const navigate = useNavigation();
  const {notify} = useEventHandler();
  const toast = useToast();
  return useCallback(
    async ({
      uri,
      props,
      eventSourceId: _eventSourceId,
      message,
      afterInvoke,
      loadingAction,
    }) => {
      try {
        setLoading?.(loadingAction || true);
        _eventSourceId = _eventSourceId || eventSourceId;
        let resp = await post({uri, props, method});
        invokeEvents({eventSourceId: _eventSourceId, notify});
        afterInvoke && afterInvoke(resp);
        close && navigate.goBack();
        message && toast({message, type: 'Success'});
        setLoading?.(loadingAction ? void 0 : false);
        return resp;
      } catch (err) {
        if (throwError) {
          throw err;
        } else {
          toast({message: err.message, type: 'Error'});
        }
        setLoading?.(loadingAction ? void 0 : false);
      }
    },
    [post, close, navigate, method],
  );
};
