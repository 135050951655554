import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const FeaturePullRequestForm = props => {
  const {
    route: {params},
  } = props;

  const {branch} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['PullRequest', 'Task'],
    uri: `/branches/${branch?._id}/createPullRequest`,
    ...props,
  });

  let prDesc = `Merge ${branch?.branch_name} ${
    branch.parent_branch_id?.branch_name
      ? `to ${branch.parent_branch_id?.branch_name}`
      : ''
  }`;

  return (
    <Form
      onSubmit={onSubmit}
      header="Create Pull Request"
      defaultValues={{
        title: prDesc,
        description: prDesc,
      }}
      submitAction="Create"
      successMessage="PR created"
      layoutFields={[
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true,
        },
        {
          label: 'Description',
          field: 'description',
          type: 'text',
        },
      ]}
    />
  );
};
