export const CustomNodeComponentStyles = ({
  theme: {colors, fonts, icons, shadows},
}) => {
  return {
    ExpandGreenIcon: icons.RecursiveExpandGreen,
    CollapseGreenIcon: icons.RecursiveCollapseGreen,
    FocusGreenIcon: icons.FocusGreenIcon,
    BlurGreenIcon: icons.BlurGreenIcon,
    ExpandGreyIcon: icons.RecursiveExpand,
    CollapseGreyIcon: icons.RecursiveCollapse,
    FocusGreyIcon: icons.FocusGreyIcon,
    BlurGreyIcon: icons.BlurGreyIcon,
    AIIcon: icons.Sparkle,
    nodeContainer: {
      flex: 1,
      padding: 12,
      justifyContent: 'center',
      borderRadius: 6,
      backgroundColor: colors.BACKGROUND,
      borderWidth: 1,
      borderColor: colors.BRAND_MEDIUM,
      // ...shadows.XS,
    },
    selectedNodeContainer: {
      borderColor: colors.BRAND_UPPER_MEDIUM,
    },
    focusedNodeContainer: {},
    textStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    iconContainerStyle: {
      height: 24,
      width: 24,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.BRAND_LOW,
      borderRadius: 4,
    },
    iconStyle: {height: 16, width: 16},
    expandedIconStyle: {
      transform: 'rotate(180deg)',
    },
    moreActionAbsoluteContainer: {position: 'absolute', right: 5, top: 3},
  };
};
