import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateStatusForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'ActiveITAsset',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      fields={{
        status: 1,
      }}
      header={{
        title: 'Update Status',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Status',
          field: 'status',
          type: 'autoComplete',
          options: ['Assigned', 'Unassigned', 'Unusable'],
          required: true,
        },
      ]}
    />
  );
};
