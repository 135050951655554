import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';

export const LeaveAllowedTable = props => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="LeaveAllowed"
      api={`/leavealloweds`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('edit-leave-allowed', {
          leaveAllowed: row?._id,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'leave_allowed',
          header: 'Leave Allowed',
        },
        {
          type: 'date',
          field: 'from_date',
          header: 'Form',
        },
        {
          type: 'date',
          field: 'to_date',
          header: 'To',
        },
      ]}
    />
  );
};
