import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const NAME = {
  type: 'text',
  field: 'name',
  label: 'Name',
  required: true,
  size: 6,
};

// schedule forms
const ScheduleForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/schedules',
    eventSourceId: 'Schedule',
  });
  return (
    <Form
      api={`/schedules/${row?._id}`}
      fields={{
        name: 1,
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 1,
        sunday: 1,
        department: {name: 1},
        default: 1,
        sitting_location: {name: 1},
      }}
      defaultValues={{
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        NAME,
        {
          type: 'checkbox',
          field: 'monday',
          label: 'Monday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'tuesday',
          label: 'Tuesday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'wednesday',
          label: 'Wednesday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'thursday',
          label: 'Thursday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'friday',
          label: 'Friday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'saturday',
          label: 'Saturday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'sunday',
          label: 'Sunday',
          size: 6,
        },
        {
          placeholder: 'Department',
          label: 'Department',
          field: 'department',
          type: 'multiAutoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',

          size: 6,
        },
        {
          label: 'Working location',
          field: 'sitting_location',
          type: 'multiAutoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'default',
          type: 'checkbox',
          label: 'Default',
        },
      ]}
      {...props}
    />
  );
};

const AddScheduleForm = props => {
  return <ScheduleForm header={'Add Schedules'} {...props} />;
};

const EditScheduleForm = props => {
  return <ScheduleForm mode="edit" header={'Edit Schedules'} {...props} />;
};

// shifts forms

const ShiftsForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/shifts',
    eventSourceId: 'Shifts',
  });
  return (
    <Form
      api={`/shifts/${row?._id}`}
      fields={{
        name: 1,
        start_time: 1,
        duration: 1,
        half_day_time: 1,
        first_from_time: 1,
        first_to_time: 1,
        second_from_time: 1,
        second_to_time: 1,
        cut_off: 1,
        department: {name: 1},
        sitting_location: {name: 1},
        default: 1
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        NAME,
        {
          type: 'text',
          field: 'start_time',
          label: 'Start Time',
          size: 6,
        },
        {
          type: 'text',
          field: 'duration',
          label: 'Duration',
          size: 6,
        },
        {
          type: 'text',
          field: 'half_day_time',
          label: 'Half Day Duration',
          size: 6,
        },
        {
          label: 'First Half Day',
          fields: [
            {
              type: 'text',
              field: 'first_from_time',
              label: 'First From Time',
              size: 6,
            },
            {
              type: 'text',
              field: 'first_to_time',
              label: 'First To Time',
              size: 6,
            },
          ],
        },
        {
          label: 'Second Half Day',
          fields: [
            {
              type: 'text',
              field: 'second_from_time',
              label: 'Second From Time',
              size: 6,
            },
            {
              type: 'text',
              field: 'second_to_time',
              label: 'Second To Time',
              size: 6,
            },
          ],
        },
        {
          type: 'text',
          field: 'cut_off',
          label: 'Cut Off',
          size: 6,
        },
        {
          placeholder: 'Department',
          label: 'Department',
          field: 'department',
          type: 'multiAutoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',

          size: 6,
        },
        {
          label: 'Working location',
          field: 'sitting_location',
          type: 'multiAutoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'default',
          type: 'checkbox',
          label: 'Default',
        },
      ]}
      {...props}
    />
  );
};

const AddShiftsForm = props => {
  return <ShiftsForm header={'Add Shifts'} {...props} />;
};

const EditShiftsForm = props => {
  return <ShiftsForm mode="edit" header={'Edit Shifts'} {...props} />;
};

// leave type forms

const LeaveTypeForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/leavetypes',
    eventSourceId: 'LeaveType',
  });
  return (
    <Form
      api={`/leavetypes/${row?._id}`}
      fields={{
        name: 1,
        short_name: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        NAME,
        {
          type: 'text',
          field: 'short_name',
          label: 'Short Name',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

const AddLeaveTypeForm = props => {
  return <LeaveTypeForm header={'Add Leave Type'} {...props} />;
};

const EditLeaveTypeForm = props => {
  return <LeaveTypeForm mode="edit" header={'Edit Leave Type'} {...props} />;
};

// leave policy forms

const LeavePolicyForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/leavepolicies',
    eventSourceId: 'LeavePolicy',
  });
  return (
    <Form
      api={`/leavepolicies/${row?._id}`}
      fields={{
        name: 1,
        is_sandwich_applicable: 1,
        department: {name: 1},
        sitting_location: {name: 1},
        leave_policy_details: {
          no_of_leaves_assigned_in_year: 1,
          leave_credit_type_id: 1,
          accrual_base_id: 1,
          leave_type_id: {
            _id: 1,
            name: 1,
          },
        },
        ewd_policy_object: {
          ewd_policy: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        NAME,
        {
          type: 'text',
          field: 'is_sandwich_applicable',
          label: 'Sandwich Policy',
          // required: true,
          size: 6,
        },
        {
          placeholder: 'Department',
          label: 'Department',
          field: 'department',
          type: 'multiAutoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',

          size: 6,
        },
        {
          label: 'Working location',
          field: 'sitting_location',
          type: 'multiAutoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'default',
          type: 'checkbox',
          label: 'Default',
        },
        {
          groups: [
            {
              field: 'leave_policy_details',
              label: 'Leave Policy',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      header: 'Leave Type',
                      type: 'autoComplete',
                      field: 'leave_type_id',
                      placeholder: 'Leave Type',
                      api: '/leavetypes',
                      suggestionField: 'name',
                      size: 6,
                    },
                    {
                      placeholder: 'No Of Leaves Assigned in a Year',
                      field: 'no_of_leaves_assigned_in_year',
                      type: 'number',
                      size: 6,
                    },
                    {
                      placeholder: 'Leave Credit Type',
                      field: 'leave_credit_type_id',
                      type: 'text',
                      size: 6,
                    },
                    {
                      placeholder: 'Accrual Base',
                      field: 'accrual_base_id',
                      type: 'text',
                      size: 6,
                    },
                  ]}
                  columns={[
                    {
                      header: 'Leave Type',
                      type: 'text',
                      field: 'leave_type_id.name',
                      size: 6,
                    },
                    {
                      header: 'No Of Leaves Assigned in a Year',
                      field: 'no_of_leaves_assigned_in_year',
                      type: 'number',
                      align: 'left',
                      size: 6,
                    },
                    {
                      header: 'Leave Credit Type',
                      field: 'leave_credit_type_id',
                      type: 'text',
                      size: 6,
                    },
                    {
                      header: 'Accrual Base',
                      field: 'accrual_base_id',
                      type: 'text',
                      size: 6,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          groups: [
            {
              field: 'ewd_policy_object',
              label: 'EWD Policy',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      type: 'text',
                      field: 'ewd_policy',
                      label: 'EDW Policy',
                    },
                  ]}
                  columns={[
                    {
                      type: 'text',
                      field: 'ewd_policy',
                      header: 'Ewd Policy',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const AddLeavePolicyForm = props => {
  return <LeavePolicyForm header={'Add Leave Policy'} {...props} />;
};

const EditLeavePolicyForm = props => {
  return (
    <LeavePolicyForm mode="edit" header={'Edit Leave Policy'} {...props} />
  );
};

// holiday calendar forms

const HolidayCalendarForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/holidaycalendars',
    eventSourceId: 'HolidayCalendar',
  });
  return (
    <Form
      api={`/holidaycalendars/${row?._id}`}
      fields={{
        name: 1,
        sitting_location: {name: 1},
        department: {name: 1},
        default: 1,
        from_date: 1,
        to_date: 1,
        days: {
          date: 1,
          description: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        NAME,
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          required: true,
          size: 6,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          required: true,
          size: 6,
        },
        {
          placeholder: 'Department',
          label: 'Department',
          field: 'department',
          type: 'multiAutoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',

          size: 6,
        },
        {
          label: 'Working location',
          field: 'sitting_location',
          type: 'multiAutoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'default',
          type: 'checkbox',
          label: 'Default',
        },
        {
          groups: [
            {
              field: 'days',
              label: 'Days',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      type: 'text',
                      field: 'description',
                      placeholder: 'Description',
                      size: 6,
                    },
                    {
                      type: 'date',
                      field: 'date',
                      placeholder: 'Date',
                      size: 6,
                    },
                  ]}
                  columns={[
                    {
                      type: 'text',
                      field: 'description',
                      header: 'Description',
                      size: 6,
                    },
                    {
                      type: 'date',
                      field: 'date',
                      header: 'Date',
                      size: 6,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const AddHolidayCalendarForm = props => {
  return <HolidayCalendarForm header={'Add Holiday Calendar'} {...props} />;
};

const EditHolidayCalendarForm = props => {
  return (
    <HolidayCalendarForm
      mode="edit"
      header={'Edit Holiday Calendar'}
      {...props}
    />
  );
};

// cron history forms

const CronHistoryForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/cronhistories',
    eventSourceId: 'CronHistory',
  });
  return (
    <Form
      api={`/cronhistories/${row?._id}`}
      fields={{
        date: 1,
        location: {
          name: 1,
        },
        employee: {
          name: 1,
          official_email_id: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          size: 6,
        },
        {
          placeholder: 'Location',
          field: 'location',
          type: 'autoComplete',
          api: '/offices',
          suggestionField: 'name',
          size: 6,
        },
        {
          placeholder: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

const AddCronHistoryForm = props => {
  return <CronHistoryForm header={'Add Cron History'} {...props} />;
};

const EditCronHistoryForm = props => {
  return (
    <CronHistoryForm mode="edit" header={'Edit Cron History'} {...props} />
  );
};

// punch history forms

const PunchHistoryForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/punchhistories',
    eventSourceId: 'PunchHistory',
  });
  return (
    <Form
      api={`/punchhistories/${row?._id}`}
      fields={{
        date: 1,
        location: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          size: 6,
        },
        {
          placeholder: 'Location',
          field: 'location',
          type: 'autoComplete',
          api: '/offices',
          suggestionField: 'name',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

const AddPunchHistoryForm = props => {
  return <PunchHistoryForm header={'Add Punch History'} {...props} />;
};

const EditPunchHistoryForm = props => {
  return (
    <PunchHistoryForm mode="edit" header={'Edit Punch History'} {...props} />
  );
};

// roster policy

const RosterPolicyForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/rosterpolicies',
    eventSourceId: 'Schedule',
  });
  return (
    <Form
      api={`/rosterpolicies/${row?._id}`}
      fields={{
        name: 1,
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 1,
        sunday: 1,
        department: {name: 1},
        sitting_location: {name: 1},
        default: 1,
      }}
      defaultValues={{
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        NAME,
        {
          type: 'checkbox',
          field: 'monday',
          label: 'Monday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'tuesday',
          label: 'Tuesday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'wednesday',
          label: 'Wednesday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'thursday',
          label: 'Thursday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'friday',
          label: 'Friday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'saturday',
          label: 'Saturday',
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'sunday',
          label: 'Sunday',
          size: 6,
        },
        {
          placeholder: 'Department',
          label: 'Department',
          field: 'department',
          type: 'multiAutoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',

          size: 6,
        },
        {
          label: 'Working location',
          field: 'sitting_location',
          type: 'multiAutoComplete',
          api: `/offices`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'default',
          type: 'checkbox',
          label: 'Default',
        },
      ]}
      {...props}
    />
  );
};

const AddRosterPolicyForm = props => {
  return <RosterPolicyForm header={'Add Roster Policy Form'} {...props} />;
};

const EditRosterPolicyForm = props => {
  return (
    <RosterPolicyForm
      mode="edit"
      header={'Edit Roster Policy Form'}
      {...props}
    />
  );
};

export {
  AddCronHistoryForm,
  AddHolidayCalendarForm,
  AddLeavePolicyForm,
  AddLeaveTypeForm,
  AddPunchHistoryForm,
  AddScheduleForm,
  AddShiftsForm,
  EditCronHistoryForm,
  EditHolidayCalendarForm,
  EditLeavePolicyForm,
  EditLeaveTypeForm,
  EditPunchHistoryForm,
  EditScheduleForm,
  EditShiftsForm,
  AddRosterPolicyForm,
  EditRosterPolicyForm,
};
