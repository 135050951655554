import {IconButton} from '@unthinkable/react-button';
import {Row, Text} from '@unthinkable/react-core-components';
import {
  getCurrentMonth,
  getPreviousMonth,
  useFilter,
} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {MultiTag} from '../../../components/chip/MultiChip';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {ChipComponent} from '../../../components/chip/Chip';
import {randomColor} from '../../../utils/util';

export const EngGrossMarginReport = props => {
  const {
    navigation,
    route: {params = {}},
    filterValues = {},
    searchValue,
    api,
  } = props;
  // const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter({
  //   params: {
  //     period: getPreviousMonth(),
  //   },
  // });
  const {formProjectGrossMargin = false} = params;
  const {params: filterParams, filter} = filterValues;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  let addOnFilter = {...filter};
  if (params?.employeeId) {
    addOnFilter = {...addOnFilter, employee: {$in: params.employeeId}};
  }
  if (params?.project_id) {
    addOnFilter = {...addOnFilter, project_id: params?.project_id};
  }
  if (params?.customer) {
    addOnFilter = {...addOnFilter, customer: params?.customer};
  }
  return (
    <Table
      {...props}
      api={api || params?.api}
      params={filterParams || params}
      addOnFilter={addOnFilter}
      search={searchValue}
      renderHeader={() =>
        formProjectGrossMargin && <TableHeader title={'Gross Margin'} />
      }
      limit={5000}
      searchFields="name"
      columns={[
        {
          header: 'Employee',
          minWidth: 220,
          render: ({row}) => {
            return (
              <Row
                gap={8}
                style={{
                  alignItems: 'center',
                }}>
                <UserAvatar size={30} value={row} />
                <PrimaryColumnCard
                  primaryTitle={row?.name}
                  items={[
                    {
                      value: row?.official_email_id,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Exp.',
          width: 80,
          render: ({row, styles}) => {
            return <Text style={{...styles.rowText}}>{row.experience} y</Text>;
          },
        },
        !formProjectGrossMargin && {
          header: 'Projects',
          field: 'project',
          minWidth: 200,
          render: ({row}) => {
            return (
              <MultiTag
                color={randomColor()}
                values={row.project}
                maxChips={2}
                gap={8}
              />
            );
          },
        },
        {
          header: 'Skill',
          render: ({row}) => {
            return (
              <ChipComponent
                value={row?.resource_language?.name}
                color={row?.resource_language?.color}
              />
            );
          },
          width: 120,
        },
        {
          header: 'Alloc %',
          type: 'number',
          width: 60,
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {row.billing_percentage.toFixed(0)}%
              </Text>
            );
          },
        },
        // {
        //   header: 'Billing Share',
        //   field: 'billing_percentage',
        //   count_field: 'billing_percentage',
        //   width: 120,
        //   // aggregate: true,
        //   render: ({row, styles}) => {
        //     return (
        //       <Text style={{...styles.rowText}}>
        //         {row.billing_percentage.toFixed(1)}%
        //       </Text>
        //     );
        //   },
        // },
        {
          count_field: 'billing_amount',
          aggregate: true,
          header: 'Billing Amount',
          field: 'billing_amount',
          type: 'currency',
          width: 120,
          align: 'right',
        },
        {
          count_field: 'salary',
          aggregate: true,
          header: 'Cost',
          field: 'salary',
          type: 'currency',
          width: 120,
          align: 'right',
        },
        {
          header: 'Gross Margin %',
          type: 'number',
          field: 'grossMarginPercentage',
          align: 'center',
          render: ({row, styles}) => {
            return (
              <Row
                style={{
                  backgroundColor: row.avgColor,
                  width: 120,
                  flex: 1,
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  borderRadius: 8,
                }}>
                <Text style={{...styles.rowText}}>
                  {row.grossMarginPercentage.toFixed(2)}%
                </Text>
              </Row>
            );
          },
          width: 140,
        },
        // {
        //   count_field: 'grossMargin',
        //   aggregate: true,
        //   field: 'grossMargin',
        //   header: 'Gross Margin',
        //   type: 'currency',
        //   align: 'center',
        //   width: 200,
        //   render: ({row, styles}) => {
        //     return (
        //       <Row
        //         style={{
        //           backgroundColor: row.avgColor,
        //           width: 200,
        //           flex: 1,
        //           alignItems: 'center',
        //           overflow: 'hidden',
        //           justifyContent: 'center',
        //           borderRadius: 8,
        //         }}>
        //         <Text style={{...styles.rowText}}>
        //           {row.grossMargin ? `₹${row?.grossMargin}` : '0'}
        //         </Text>
        //       </Row>
        //     );
        //   },
        // },
      ]}
    />
  );
};

export const EngEfficiencyReport = props => {
  const {
    navigation,
    route: {params = {}},
    filterValues = {},
    searchValue,
    api,
  } = props;
  const {params: filterParams, filter} = filterValues;
  let addOnFilter = {...filter};
  if (params?.employeeId) {
    addOnFilter = {...addOnFilter, employee: {$in: params.employeeId}};
  }
  if (params?.project_id) {
    addOnFilter = {...addOnFilter, project_id: params?.project_id};
  }
  if (params?.customer) {
    addOnFilter = {...addOnFilter, customer: params?.customer};
  }
  return (
    <Table
      {...props}
      api={api || params?.api}
      renderHeader={() => {
        return <TableHeader title="Eng. Efficiency" />;
      }}
      params={filterParams || params}
      addOnFilter={addOnFilter}
      search={searchValue}
      limit={5000}
      searchFields="name"
      columns={[
        {
          header: 'Employee',
          minWidth: 220,
          render: ({row}) => {
            return (
              <Row
                gap={8}
                style={{
                  alignItems: 'center',
                }}>
                <UserAvatar size={30} value={row} />
                <PrimaryColumnCard
                  primaryTitle={row?.name}
                  items={[
                    {
                      value: row?.official_email_id,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Exp.',
          render: ({row, styles}) => {
            return <Text style={{...styles.rowText}}>{row.experience} y</Text>;
          },
        },
        {
          header: 'Projects',
          field: 'project',
          minWidth: 260,
          render: ({row}) => {
            return <MultiTag values={row.project} maxChips={2} gap={8} />;
          },
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          width: 120,
        },
        {
          header: 'Allocation %',
          type: 'number',
          width: 100,
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {row.billing_percentage.toFixed(0)}%
              </Text>
            );
          },
        },
        {
          header: 'Allocated Hrs',
          field: 'allocatedHours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Delivered Hrs',
          field: 'deliveredHours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Efficiency',
          field: 'efficiency',
          type: 'number',
          width: 120,
        },
      ]}
    />
  );
};
