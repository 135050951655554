import {Platform} from '@unthinkable/react-core-components';
import icons from '../images';

const breakpoints = {
  sm: 480,
  md: 768,
};

const lightColors = {
  MODAL_BACKGROUND: 'rgba(0,0,0,0.2)',

  BACKGROUND: '#FFFFFF',
  SHADOW: '#EEF0F2',
  SURFACE1: '#FCFDFD',
  SURFACE2: '#F3F5F6',
  SURFACE3: '#F9FAFB',
  OUTLINE: '#E8EBED',
  UPPER_OUTLINE: '#D5D7D9',

  NEUTRAL_HIGH: '#2B3743',
  NEUTRAL_MEDIUM: '#697887',
  NEUTRAL_LOW: '#929DA9',

  BRAND_HIGH: '#2EB839',
  BRAND_UPPER_MEDIUM: '#31C33E',
  BRAND_MEDIUM: '#5DD667',
  BRAND_UPPER_LOW: '#E8FCEA',
  BRAND_LOW: '#F3FCF4',

  SECONDARY_HIGH: '#3562FF',
  SECONDARY_UPPER_MEDIUM: '#4770FF',
  SECONDARY_MEDIUM: '#E3E8FC',
  SECONDARY_LOW: '#F1F4FE',

  ERROR_HIGHEST: '#B6351A',
  ERROR_HIGH: '#D44B2D',
  ERROR_MEDIUM: '#FDDCD8',
  ERROR_LOW: ' #FFECEB',

  SUCCESS_HIGHEST: '#2BAB35',
  SUCCESS_HIGH: '#36B540',
  SUCCESS_MEDIUM: '#DFFBE2',
  SUCCESS_LOW: '#EFFBF0',

  WARNING_HIGHEST: '#BC7016',
  WARNING_HIGH: '#E38812',
  WARNING_MEDIUM: '#FFEACD',
  WARNING_LOW: '#FFF7EB',

  INFORMATION_HIGHEST: '#205AB1',
  INFORMATION_HIGH: '#4180DE',
  INFORMATION_MEDIUM: '#DCECFE',
  INFORMATION_LOW: '#F0F7FF',

  ACCENT1_HIGHEST: '#1C6367',
  ACCENT1_HIGH: '#44BABB',
  ACCENT1_MEDIUM: '#E5FBFB',
  ACCENT1_LOW: '#F0F9F9',

  ACCENT2_HIGHEST: '#884464',
  ACCENT2_HIGH: '#E580AA',
  ACCENT2_MEDIUM: '#FFEBF3',
  ACCENT2_LOW: '#FEF2F7',

  ACCENT3_HIGHEST: '#6D4687',
  ACCENT3_HIGH: '#C199D5',
  ACCENT3_MEDIUM: '#F8E8FF',
  ACCENT3_LOW: '#F9F2FD',

  ACCENT4_HIGHEST: '#675C59',
  ACCENT4_HIGH: '#BEA79D',
  ACCENT4_MEDIUM: '#F8EDE7',
  ACCENT4_LOW: '#F7F4F3',

  ACCENT5_HIGHEST: '#33705E',
  ACCENT5_HIGH: '#64C6A2',
  ACCENT5_MEDIUM: '#E8FAF3',
  ACCENT5_LOW: '#F0F9F6',

  ACCENT6_HIGHEST: '#43418D',
  ACCENT6_HIGH: '#8D87D3',
  ACCENT6_MEDIUM: '#E6E5FF',
  ACCENT6_LOW: '#F2F1FE',
};

const darkColors = {
  MODAL_BACKGROUND: 'rgba(17,17,14,0.7)',

  BACKGROUND: '#222226',
  SURFACE1: '#242528',
  SURFACE2: '#26272B',
  SURFACE3: '#292A2E',
  OUTLINE: '#333438',
  UPPER_OUTLINE: '#3D3F43',
  SHADOW: '#1A1A1A',

  NEUTRAL_HIGH: '#DFDFE2',
  NEUTRAL_MEDIUM: '#A0A4AC',
  NEUTRAL_LOW: '#8D929B',

  BRAND_HIGH: '#76E57F',
  BRAND_UPPER_MEDIUM: '#67E071',
  BRAND_MEDIUM: '#29A333',
  BRAND_UPPER_LOW: '#243825',
  BRAND_LOW: '#242E25',

  SECONDARY_HIGH: '#3360FF',
  SECONDARY_UPPER_MEDIUM: '#3149A0',
  SECONDARY_MEDIUM: '#232D52',
  SECONDARY_LOW: '#1F263E',

  ERROR_HIGHEST: '#F06058',
  ERROR_HIGH: '#F1736C',
  ERROR_MEDIUM: '#4A1E1C',
  ERROR_LOW: '#342728',

  SUCCESS_HIGHEST: '#71C177',
  SUCCESS_HIGH: '#61D16A',
  SUCCESS_MEDIUM: '#1C361F',
  SUCCESS_LOW: '#242E24',

  WARNING_HIGHEST: '#DDA451',
  WARNING_HIGH: '#F6B961',
  WARNING_MEDIUM: '#4D3819',
  WARNING_LOW: '#332E26',

  INFORMATION_HIGHEST: '#4683DE',
  INFORMATION_HIGH: '#62A1FF',
  INFORMATION_MEDIUM: '#1A324C',
  INFORMATION_LOW: '#212D3A',

  ACCENT1_HIGHEST: '#A8F2F2',
  ACCENT1_HIGH: '#3EABAC',
  ACCENT1_MEDIUM: '#264045',
  ACCENT1_LOW: '#242E30',

  ACCENT2_HIGHEST: '#FFD0E3',
  ACCENT2_HIGH: '#E26F9E',
  ACCENT2_MEDIUM: '#493644',
  ACCENT2_LOW: '#312A2F',

  ACCENT3_HIGHEST: '#F4DCFF',
  ACCENT3_HIGH: '#B584CC',
  ACCENT3_MEDIUM: '#403650',
  ACCENT3_LOW: '#2B2830',

  ACCENT4_HIGHEST: '#F2D2C0',
  ACCENT4_HIGH: '#AD9185',
  ACCENT4_MEDIUM: '#312F2E',
  ACCENT4_LOW: '#292929',

  ACCENT5_HIGHEST: '#B4EFD9',
  ACCENT5_HIGH: '#4CBD94',
  ACCENT5_MEDIUM: '#2D4442',
  ACCENT5_LOW: '#252D2B',

  ACCENT6_HIGHEST: '#CBC8FF',
  ACCENT6_HIGH: '#7E79CE',
  ACCENT6_MEDIUM: '#323452',
  ACCENT6_LOW: '#2A2A34',
};

export const fonts = {
  TITLE: {
    fontSize: 17,
    fontFamily: 'Inter-Bold',
    lineHeight: '20px',
  },
  HEADING1: {
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
  },
  HEADING2: {
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
  },
  SECTION_HEADING1: {
    fontSize: 15,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
  },
  SECTION_HEADING2: {
    fontSize: 15,
    fontFamily: 'Inter-Medium',
  },
  BODY1: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '18px',
  },
  BODY2: {
    fontSize: 14,
    fontFamily: 'Inter-Medium',
    lineHeight: '18px',
  },
  BODY3: {
    fontSize: 14,
    fontFamily: 'Inter-Regular',
    lineHeight: '20px',
  },
  ACTION: {
    fontSize: 14,
    fontFamily: 'Inter-Medium',
    lineHeight: '18px',
  },
  SIDE_NAVIGATION: {
    fontSize: 13,
    fontFamily: 'Inter-Medium',
    lineHeight: '18px',
  },
  CAPTION: {
    fontSize: 13,
    fontFamily: 'Inter-Italic',
    lineHeight: '16px',
  },
  CAPTION_LARGE: {
    fontSize: 12,
    fontFamily: 'Inter-Medium',
    lineHeight: '16px',
  },
  CAPTION_SMALL: {
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    lineHeight: '16px',
  },
  FOOTNOTE: {
    fontSize: 10,
    fontFamily: 'Inter-Medium',
    lineHeight: '10px',
  },
  FOOTNOTE_SMALL: {
    fontSize: 10,
    fontFamily: 'Inter-Medium',
    lineHeight: '12px',
  },
};

const lightWebShadow = {
  M: {boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)'},
  XL: {boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.16)'},
  S: {boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)'},
  L: {
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
  },
  Text: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
  },
  XS: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
  },
};

const lightNativeShadow = {
  M: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  XL: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.08,
    shadowRadius: 40,
  },
  S: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.06,
    shadowRadius: 16,
  },
  L: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.25,
    shadowRadius: 10,
  },
  Text: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
};

const darkWebShadow = {
  M: {boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.20)'},
  XL: {boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.4)'},
  S: {boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)'},
  L: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.3)',
  },
  Text: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
  },
  XS: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
  },
};

const darkNativeShadow = {
  M: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.04,
    shadowRadius: 16,
  },
  XL: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.04,
    shadowRadius: 40,
  },
  S: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.02,
    shadowRadius: 16,
  },
  L: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  Text: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.05,
    shadowRadius: 4,
  },
};

const lightShadows = Platform.OS === 'web' ? lightWebShadow : lightNativeShadow;
const darkShadows = Platform.OS === 'web' ? darkWebShadow : darkNativeShadow;

const radius = {
  '2xs': 1,
  xs: 2,
  sm: 3,
  md: 4,
  lg: 6,
  xl: 8,
  '2xl': 12,
  '3xl': 16,
  '4xl': 20,
  '5xl': 24,
};

const spacing = {
  '2xs': 2,
  xs: 4,
  sm: 6,
  md: 8,
  lg: 10,
  xl: 12,
  '2xl': 16,
  '3xl': 20,
  '4xl': 24,
  '5xl': 30,
  '6xl': 32,
  '7xl': 36,
};

export default {
  breakpoints,
  lightColors,
  darkColors,
  icons,
  fonts,
  radius,
  spacing,
  lightShadows,
  darkShadows,
};
