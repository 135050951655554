import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const inmailCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Messages',
  align: 'right',

  count_field: 'inmail_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`add-dripinmailcontent-new`, {
      defaultValues: {
        value_proposition: {_id: row._id, name: row.name},
      },
      filter: {
        value_proposition: row._id,
      },
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`value-proposition-drip-inmail-list`, {
      defaultValues: {
        value_proposition: {_id: row._id, name: row.name},
      },
      filter: {
        value_proposition: row._id,
      },
    });
  },
});

export const ValuePropositionListNew = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {filter} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'valuePropositionRemoved',
  });
  return (
    <Table
      api="/valuePropositions/all"
      eventSourceId={[
        'valuePropositionAdded',
        'dripInmailAdded',
        'valuePropositionRemoved',
      ]}
      filter={{...filter}}
      onRowPress={({row}) => {
        navigation.navigate('edit-new-value-proposition', {...params, row});
      }}
      columns={[
        {
          header: 'Title',
          field: 'name',
          type: 'textArea',
        },
        inmailCount({params, navigation}),

        // {
        //   header: 'Email',
        //   field: 'email_count',
        //   type: 'number',
        //   width: 100,
        // },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-new-value-proposition', {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Delete',
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/valuePropositions/${row?._id}`,
              props: {status: 'deleted'},
            });
          },
        },
      ]}
      {...props}
    />
  );
};
