import React, {forwardRef} from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  ColorInput as ColorInputComponent,
  ColorRenderer as ColorRendererComponent,
} from '@unthinkable/react-color-picker';
import {ColorInputStyles} from './theme';

export const ColorInput = forwardRef((props, ref) => {
  const styles = useStyles(ColorInputStyles);
  return <ColorInputComponent ref={ref} {...styles} {...props} />;
});

export const ColorRenderer = props => {
  const styles = useStyles(ColorInputStyles);
  return <ColorRendererComponent styles={styles} {...props} />;
};
