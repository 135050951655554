import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';

export const FontForm = props => {
  const {
    route: {params},
  } = props;

  const {project} = params;

  const {deviceTypes = []} = project || {};
  return (
    <Form
      layoutFields={[
        {
          label: 'Name',
          field: 'font_name',
          type: 'text',
          rules: {
            required: true,
          },
        },
        {
          groups: [
            {
              visible: !deviceTypes.length || deviceTypes.includes('Desktop'),
              label: 'Desktop',
              fields: [
                {
                  label: 'Font Family',
                  field: 'font_family',
                  type: 'text',
                },
                {
                  label: 'Font Size',
                  field: 'font_size',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Line Height',
                  field: 'line_height',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Font Weight',
                  field: 'font_weight',
                  type: 'autoComplete',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  options: [
                    {
                      value: 400,
                      label: 'Regular',
                    },
                    {
                      value: 500,
                      label: 'Medium',
                    },
                    {
                      value: 600,
                      label: 'Semi-Bold',
                    },
                    {
                      value: 700,
                      label: 'Bold',
                    },
                  ],
                },

                {
                  label: 'Letter Spacing',
                  field: 'letter_spacing',
                  type: 'number',
                },
                {
                  label: 'Italic',
                  field: 'italic',
                  type: 'switch',
                },
              ],
            },
            {
              visible: !deviceTypes.length || deviceTypes.includes('Mobile'),
              label: 'Mobile',
              fields: [
                {
                  label: 'Font Family',
                  field: 'mobile_font_family',
                  type: 'text',
                },
                {
                  label: 'Font Size',
                  field: 'mobile_font_size',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Line Height',
                  field: 'mobile_line_height',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Font Weight',
                  field: 'mobile_font_weight',
                  type: 'autoComplete',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  options: [
                    {
                      value: 400,
                      label: 'Regular',
                    },
                    {
                      value: 500,
                      label: 'Medium',
                    },
                    {
                      value: 600,
                      label: 'Semi-Bold',
                    },
                    {
                      value: 700,
                      label: 'Bold',
                    },
                  ],
                },
                {
                  label: 'Letter Spacing',
                  field: 'mobile_letter_spacing',
                  type: 'number',
                },
                {
                  label: 'Italic',
                  field: 'mobile_italic',
                  type: 'switch',
                },
              ],
            },
            {
              visible: !deviceTypes.length || deviceTypes.includes('Tab'),
              label: 'Tablet',
              fields: [
                {
                  label: 'Font Family',
                  field: 'tablet_font_family',
                  type: 'text',
                },
                {
                  label: 'Font Size',
                  field: 'tablet_font_size',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Line Height',
                  field: 'tablet_line_height',
                  type: 'number',
                  size: 6,
                },
                {
                  label: 'Font Weight',
                  field: 'tablet_font_weight',
                  type: 'autoComplete',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  options: [
                    {
                      value: 400,
                      label: 'Regular',
                    },
                    {
                      value: 500,
                      label: 'Medium',
                    },
                    {
                      value: 600,
                      label: 'Semi-Bold',
                    },
                    {
                      value: 700,
                      label: 'Bold',
                    },
                  ],
                },
                {
                  label: 'Letter Spacing',
                  field: 'tablet_letter_spacing',
                  type: 'number',
                },
                {
                  label: 'Italic',
                  field: 'tablet_italic',
                  type: 'switch',
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddFontForm = props => {
  const {
    route: {params},
  } = props;
  const {row, feature} = params;

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'fonts',
  });

  const onSubmit = props => {
    putInvoke({
      uri: `/projectAssetVersions/${row?.versionId}/addFont`,
      props: {font: props, feature: {_id: feature?._id}},
    });
  };

  return (
    <FontForm
      onSubmit={onSubmit}
      header={{
        title: 'Add Font',
      }}
      {...props}
    />
  );
};

export const UpdateFontForm = props => {
  const {
    route: {params},
  } = props;

  const {themeAssetVersion, font, feature} = params;

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'fonts',
  });

  const onSubmit = props => {
    putInvoke({
      uri: `/projectAssetVersions/${themeAssetVersion?.versionId}/fonts/${props?._id}`,
      props: {font: props, feature: {_id: feature?._id}},
    });
  };

  return (
    <FontForm
      onSubmit={onSubmit}
      data={font}
      mode={'edit'}
      header={{
        title: 'Update Font',
      }}
      {...props}
    />
  );
};
