import React from 'react';
import {BaseRadio} from './Radio';
import {RadioGroupContainer} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';

const RadioGroup = ({options = [], styles, ...props}) => {
  return (
    <InputWrapper {...props} styles={styles}>
      <RadioGroupContainer styles={styles}>
        {options.map((option, index) => (
          <BaseRadio
            index={index}
            option={option}
            {...props}
            styles={styles?.radio}
          />
        ))}
      </RadioGroupContainer>
    </InputWrapper>
  );
};

export default RadioGroup;
