import {Table} from '../../../components/table/Table';

const LinkedInProfileList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['profileAdded']}
      api={`/linkedinprofiles`}
      fields={{
        email: 1,
      }}
      columns={[
        {
          field: 'email',
          header: 'LinkedIn Profile',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-linkedin-profile', {row});
          },
        },
        {
          title: 'Update Password',
          onPress: ({row}) => {
            navigation.navigate('update-linkedin-password', {row});
          },
        },
      ]}
    />
  );
};

export default LinkedInProfileList;
