import React from 'react';
import {Text} from '@unthinkable/react-core-components';

export const FontNameColumn = ({row}) => {
  const {
    fontName,
    fontFamily,
    fontSize,
    fontWeight,
    italic,
    letterSpacing,
    lineHeight,
  } = row;

  let styles = {};

  // if(fontFamily){
  //   styles.fontFamily = fontFamily
  // }
  if (fontSize) {
    styles.fontSize = `${fontSize}px`;
  }
  if (fontWeight) {
    styles.fontWeight = fontWeight;
  }
  if (letterSpacing) {
    styles.letterSpacing = `${letterSpacing}px`;
  }
  if (lineHeight) {
    styles.lineHeight = `${lineHeight}px`;
  }

  return (
    <Text numberOfLines={1} style={styles}>
      {fontName}
    </Text>
  );
};
