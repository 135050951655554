import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {RenderName} from './LeadToCustomerTable';
import moment from 'moment';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {leadControllers} from '../controllers/leadControllers';

const ChannelWiseCampaignTable = props => {
  let {
    route: {params},
    navigation,
  } = props;

  let {header, period = {}} = params || {};
  let previousPeriod = {};
  if (period.from && period.filterOf === 'Month') {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = moment(period.from)
      .subtract(1, 'M')
      .endOf('M')
      .toDate();
  }
  const {uqls, preuqls, qualifiedLeads, prequalifiedLeads} = leadControllers({
    ...props,
    params,
  });
  return (
    <Table
      api={'/types/channelWiseCampaign'}
      eventSourceId={'campaignDetails'}
      renderHeader={() => (
        <TableHeader
          title={header}
        />
      )}
      columns={[
        {header: 'Name', render: RenderName},
        {
          type: 'number',
          field: 'lead_count',
          width: 150,
          header: 'Lead',
          compareField: 'previous_lead_count',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: uqls,
          onComparePress: preuqls,
        },
        {
          type: 'number',
          field: 'ql_count',
          width: 150,
          header: 'QL',
          compareField: 'previous_ql_count',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: qualifiedLeads,
          onComparePress: prequalifiedLeads,
        },

        {
          header: 'RFR',
          field: 'opportunity_rfr_count',
          width: 150,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('channel-wise-rfr', {
              ...params,
              row,
              filters: {
                campaign_id: row?._id,
                status: 'Won',
                opportunity_type: 'StaffAugmentationOpportunity',
                outcome_date: {
                  $lte: period?.to,
                  $gte: period?.from,
                },
              },
            });
          },
        },
        {
          header: 'RFQ',
          field: 'opportunity_rfq_count',
          width: 150,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('channel-wise-rfq', {
              ...params,
              row,
              filters: {
                campaign_id: row?._id,
                opportunity_type: 'CustomPlatformOpportunity',
                status: {$in: ['Active', 'New']},
                creation_date: {
                  $lte: period?.to,
                  $gte: period?.from,
                },
              },
            });
          },
        },
        {
          header: 'Customer',
          field: 'customer_count',
          type: 'number',
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('channel-wise-customer', {
              ...params,
              row,
              filters: {
                campaign_id: row?._id,
                created_on: {
                  $lte: period?.to,
                  $gte: period?.from,
                },
              },
            });
          },
        },
      ]}
      params={params}
    />
  );
};

export default ChannelWiseCampaignTable;
