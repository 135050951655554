import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const MessageCategoryType = props => {
  const params = props.route.params;
  const {onSubmit} = useFormSubmit({
    uri: '/messagecategorytypes',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Message Category Type'}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
        },
      ]}
    />
  );
};
