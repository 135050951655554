import React from 'react';
import KpiTable from './KpiTable';
import pluralize from 'pluralize';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {ModuleOverview} from './ModuleOverview';
import {AddAction} from '../../../components/action/AddAction';
import StrategicInitiativesTable from './StrategicInitiativesTable';
import {useFilter} from '../../../controllers/useFilter';
import {TeamTableTabView} from '../../common/views/TeamTableTabView';
import {useFetchData} from '../../../controllers/useFetchData';

const ModuleDashboard = props => {
  const {navigation, params} = props;
  const {filterValues} = useFilter({
    params: {
      period: params?.period || getPreviousMonth(),
    },
  });
  const {params: filterParams = {}} = filterValues;
  const {data: activeElements} = useFetchData({
    api: `/department/${params?.department?._id}/active-element`,
  });
  const mergedParams = {...params, ...filterParams};
  const dynamicMenuParams = [];
  if (activeElements?.length) {
    activeElements.forEach(activeElement => {
      let {label, model, filter, on_press_url} = activeElement;
      const menuParams = {
        label,
        onPress: () => {
          navigation.navigate(`${on_press_url?.toLowerCase()}`, params);
        },
      };
      if (filter) {
        filter = JSON.parse(filter);
        menuParams.api = `/${pluralize.plural(model?.name)}`;
        menuParams.filter = filter;
        menuParams.params = params;
      }
      dynamicMenuParams.push({...menuParams});
    });
  }

  const menus = [
    [
      {
        label: 'KPI',
        view: <KpiTable {...props} params={mergedParams} />,
        actions: [
          <AddAction
            onPress={() => {
              navigation.navigate(`add-kpi`, {...mergedParams});
            }}
          />,
        ],
      },
      {
        label: 'Performance',
        view: <ModuleOverview params={mergedParams} />,
      },
      ...dynamicMenuParams,
    ],
    [
      {
        label: 'Team',
        view: <TeamTableTabView {...props} params={mergedParams} />,
      },
    ],
    [
      {
        label: 'Custom Object',
        view: <StrategicInitiativesTable {...props} params={mergedParams} />,
        actions: [
          <AddAction
            onPress={() => {
              navigation.navigate(`add-strategic-initiative`, {
                ...mergedParams,
              });
            }}
          />,
        ],
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export default ModuleDashboard;
