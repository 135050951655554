// Rajnish Trikaldarshi 23-02-2024 - Make Screen to show the gmail history logs.
import React from 'react';
import {Table} from '../../../components/table/Table';

export const GmailHistoryLogsTable = props => {
  return (
    <Table
      api="/gmailhistorylogs"
      fields={{
        user: {name: 1},
        createdAt: 1,
        error: 1,
        email: 1,
      }}
      sort={{_id: -1}}
      columns={[
        {
          header: 'Name',
          field: 'user.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Email',
          field: 'email',
          type: 'text',
          width: 250,
        },
        {
          header: 'Date',
          field: 'createdAt',
          type: 'date',
          width: 100,
        },
        {
          header: 'Error',
          field: 'error',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
