import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinetuneUseSampleForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/finetune/finetune-useas-sample-data',
  });
  const {campaign_action_id} = params || {};

  return (
    <Form
      onSubmit={onSubmit}
      header={'Select Usecase'}
      defaultValues={{
        campaign_action_id,
      }}
      layoutFields={[
        {
          label: 'Usecase',
          field: 'usecase_id',
          type: 'autoComplete',
          api: '/finetuneusecases',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'AIprompt',
          field: 'aiprompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
