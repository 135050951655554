import React from 'react';
import {TabView} from '../../../components/tab';
import {ProjectFeedbackTable} from './ProjectFeedbacks';
import {ResourceWiseClientFeedbackTable} from './ResourceWiseProjectFeedback';
import {Button} from '../../../components/button/Button';

export const ClientFeedbackTabs = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {project} = params;

  return (
    <TabView
      {...props}
      actions={[
        <Button
          text="Request Feedback"
          onPress={() => {
            navigation.navigate('request-feedback', {
              project: project,
            });
          }}
        />,
      ]}
      tabs={{
        project: {
          label: 'Project',
          view: <ProjectFeedbackTable />,
          api: '/clientFeedbacks',
          filter: {project: project?._id},
          eventSourceId: 'ClientFeedback',
        },
        resource: {
          label: 'Resource',
          view: <ResourceWiseClientFeedbackTable />,
          api: '/resourceWiseClientFeedbacks',
          filter: {project: project?._id},
          eventSourceId: 'ResourceWiseClientFeedback',
        },
      }}
    />
  );
};
