import React from 'react';
import {Table} from '../../../components/table/Table';
import {Industry} from '../controllers/Industry';

export const ToolList = props => {
  const {navigation} = props;
  const {markIndustryInactive} = Industry(props);
  return (
    <Table
      eventSourceId="Industry"
      api={`/tools-details`}
      onRowPress={props => {
        navigation.navigate(`edit-tools`, {
          ...props,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'practice.name',
          header: 'Practice',
        },
      ]}
    />
  );
};
