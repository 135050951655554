import { Row } from '@unthinkable/react-core-components';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { ObjectWiseTasks } from '../../common/actions/ObjectWiseTasks';
import {
    AllOpportunityTable,
    CustomPlatformOppProposalSubTable,
    CustomPlatformOpportunityActiveTable,
    CustomPlatformOpportunityProposalApi,
    CustomPlatformOpportunityProposalTable,
    CustomPlatformOpportunityUnassignedTable,
    ProposalSubmittedDropTable,
    ProposalSubmittedLostTable,
    ProposalSubmittedWonTable,
} from './CustomPlatformOpportunityTable';

export const CustomDigitalPlatformOppTabView = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  let tabs = {};

  tabs.unassigned = {
    label: 'Unassigned',
    view: (
      <CustomPlatformOpportunityUnassignedTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/unassigned',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
  };
  tabs.active = {
    label: 'Active',
    view: (
      <CustomPlatformOpportunityActiveTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/active',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
  };
  tabs.proposal = {
    label: 'Proposal',
    view: (
      <CustomPlatformOppProposalSubTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/proposal',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],

    params: params,
  };
  tabs.won = {
    label: 'Won',
    view: (
      <ProposalSubmittedWonTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/won',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],

    params: params,
  };

  tabs.lost = {
    label: 'Lost',
    view: (
      <ProposalSubmittedLostTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/lost',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
  };

  tabs.drop = {
    label: 'Drop',
    view: (
      <ProposalSubmittedDropTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/drop',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Customer',
                  value: 'Customer',
                },
                {
                  label: 'Prospect',
                  value: 'PotentailCustomer',
                },
              ],
              placeholder: 'Select',
              field: 'rfr_type',
            },
            {
              type: 'autoComplete',
              label: 'Outcome',
              options: [
                'New',
                'Active',
                'Won',
                'Lost',
                {label: 'Drop', value: 'invalidRfq'},
              ],
              placeholder: 'Select',
              field: 'status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Dropped RFQ',
              options: [{label: 'True', value: 'true'}],
              placeholder: 'Select',
              field: 'is_drop',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Pre Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'rfq_owner',
            },
            {
              type: 'autoComplete',
              label: 'Quote Status',
              options: ['Submitted', 'Pending', 'On Hold'],
              placeholder: 'Select',
              field: 'quote_status',
            },
            {
              type: 'date',
              field: 'quote_status_date',
              asParam: true,
              label: 'Quote Date',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'date',
              field: 'creation_date',
              asParam: true,
              label: 'Created On',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task date',
            },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <ObjectWiseTasks
            {...props}
            params={params}
            object={{
              _id: '64e06ebb94f415bdfd0a4aed',
            }}
          />

          <AddButton
            title="Opportunity"
            view="add-customplatformopportunity"
            params={props?.route?.params}
          />
        </Row>,
      ]}
    />
  );
};

export const ProposalOppTabView = props => {
  const params = props?.route?.params;

  let tabs = {};
  tabs.all = {
    label: 'All',
    ...CustomPlatformOpportunityProposalApi,
    view: (
      <CustomPlatformOpportunityProposalTable
        sort={{quote_date: -1}}
        {...props}
      />
    ),
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OpportunityTabView = props => {
  const params = props?.route?.params;

  let tabs = {};
  tabs.all = {
    label: 'All',
    api: '/potentialcustomer/opportunites',
    view: <AllOpportunityTable {...props} />,
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
