import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const UpdateAnchorResourceSwappingForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'allocatedResource',
    ...props,
  });

  return (
    <Form
      api={`/employees/${row?._id}`}
      fields={{
        replaced_with: {name: 1},
      }}
      mode="edit"
      header={'Update Swapped With'}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Swapped With',
          placeholder: 'Swapped With',
          field: 'replaced_with',
          type: 'multiAutoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
        },
      ]}
    />
  );
};
