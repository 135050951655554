import React from 'react';
import {Login, LoginMobile, Signup} from '@unthinkable/react-login';
import {isMobile} from '@unthinkable/react-utils';
import {useAuthenticate} from '../controllers';

const LoginComponent = isMobile ? LoginMobile : Signup;

const LoginForm = () => {
  let {onLogin, onGoogleLogin, onAppleLogin} = useAuthenticate({
    loginApi: '/login',
    googleLoginApi: '/user/googleAuthenticate',
    appleLoginApi: '/authenticateAppleLogin',
  });

  return (
    <LoginComponent
      onLogin={onLogin}
      onGoogleLogin={onGoogleLogin}
      onAppleLogin={onAppleLogin}
    />
  );
};
export default LoginForm;
