import React, {useState} from 'react';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import moment from 'moment';
import {RevisionStyles} from './theme';
import {Chip} from '../../../components/chip/Chip';

const Status = ({status}) => {
  return (
    <Chip
      value={status?.toUpperCase()}
      color={status == 'released' ? 'INFORMATION' : 'WARNING'}
    />
  );
};

const CreationDate = ({updatedAt}) => {
  const {date} = useStyles(RevisionStyles);
  return <Text style={date}>{moment(updatedAt).format('DD MMM YY')}</Text>;
};

const Card = ({
  data,
  setContainerIndex,
  containerIndex,
  type,
  showVersion,
  hide,
  currentFeatureDesign,
}) => {
  let feature = data?.feature?.feature;

  if (!feature) {
    feature = data?.name || data?.design;
  }

  if (showVersion) {
    title = `${data?.length - index}. ${title}`;
  }

  let currentIndex = data?.version;
  let isSelected = containerIndex === currentIndex;

  if (type?.version === data?.version) {
    currentIndex = undefined;
    isSelected = false;
  }
  const [isExpanded, toggleExpanded] = useState(false);
  const {cardContainer, selected, icon, star, informationText} =
    useStyles(RevisionStyles);
  return (
    <TouchableOpacity
      style={{
        ...cardContainer,
        ...(isSelected && selected),
      }}
      onPress={() => {
        setContainerIndex(currentIndex);
        toggleExpanded(!isExpanded);
        hide && hide();
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          overflow: 'hidden',
        }}>
        <Row style={{flex: 1, overflow: 'hidden', marginRight: 12}} gap={6}>
          <Image source={star} style={icon} />
          <Text
            title={feature}
            numberOfLines={1}
            style={{...informationText, flex: 1}}>
            {feature}
          </Text>
          {type?.version === data?.version && currentFeatureDesign ? (
            <Text title={feature} numberOfLines={1} style={informationText}>
              {'(current)'}
            </Text>
          ) : (
            void 0
          )}
        </Row>
        <Row gap={12}>
          <Status {...data} />
          <CreationDate {...data} />
        </Row>
      </View>
    </TouchableOpacity>
  );
};

export const AttachmentHistory = ({data, ...props}) => {
  return (
    <Col gap={6}>
      {data.map((_data, index) => {
        return <Card data={_data} index={index} {...props} />;
      })}
    </Col>
  );
};
