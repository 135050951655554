import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { IconButton } from '../../../components/button/IconButton';
import { PrimaryColumnCard } from '../../../components/card/PrimaryColumnCard';
import { GroupFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { AmountRender } from './components/AmountRender';
import { Button } from '../../../components/button/Button';
import { Text } from '@unthinkable/react-core-components';

export const OverduePaymentReportDatewiseTable = props => {
  const {
    navigation,
    route: { params },
  } = props;
  const { applyFilter, searchValue, filterValues, onChangeFilter } = useFilter(
    {},
  );
  const icons = useTheme('icons');
  const { params: filterParams, filter } = filterValues;
  const { download } = useAppStateContext();
  let invoiceFilter = {
    $or: [
      {
        invoice_id: { $exists: true },
        receipt_id: { $exists: false },
        $or: [
          {
            base_pending_receipt_amount: { $gt: 5 },
          },
          { base_pending_receipt_amount: { $lt: -10 } },
        ],
      },
      {
        receipt_id: { $exists: true }
      }
    ]

  };
  return (
    <Table
      {...props}
      params={filterParams}
      addOnFilter={filter}
      limit={1000}
      api={'/overduePaymentReportDatewise'}
      variant={'bordered'}
      renderHeader={() => (
        <TableHeader
          title="Overdue Payment"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: "date",
                  label: "Date",
                  field: "period"
                },
                {
                  type: 'multiAutoComplete',
                  name: 'customer',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer',
                },
                {
                  type: 'multiAutoComplete',
                  name: 'Organization',
                  label: 'Organization',
                  api: '/organizations',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'organization',
                },
                {
                  type: 'multiAutoComplete',
                  name: 'Product',
                  label: 'Product',
                  api: '/products',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'product',
                },
              ]}
            />,
            <IconButton
              icon={icons.Download}
              onPress={() => {
                download({
                  uri: '/exportcustomerageingdatewise',
                  props: {
                    uri: '/overduePaymentReportDatewise',
                    parameters: {
                      params: { ...filterParams },
                      addOnFilter: { ...filter },
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Customer',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'AM',
          width: 100,
          type: 'userAvatar',
          field: 'account_manager',
        },
        {
          header: 'Overdue',
          children: [
            {
              render: CountCellRender,
              count_field: 'total_amount',
              aggregate: true,
              header: 'Total',
              field: 'total_amount',
              type: 'currency',
              width: 200,
              render: ({ row }) => {
                let { total_amount = 0, max_ageing } = row;
                let maxAgeing = 0;
                if (max_ageing) {
                  let date1 = new Date(max_ageing);
                  let date2 = new Date(filter?.period?.$lte);
                  let diffTime = Math.abs(date2 - date1);
                  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  maxAgeing = diffDays;
                }
                return (
                  <PrimaryColumnCard
                    primaryTitle={row.total_amount}
                    primaryTitleColor="#FF3B30"
                    items={[
                      {
                        value: `max ageing:${maxAgeing} Days`,
                      },
                    ]}
                  />
                );
              },
              onPress: ({ row }) => {
                navigation.navigate('customer-ageing-invoices-datewise', {
                  row,
                  invoiceFilter: {
                    customer: row._id,
                    due_date: row?.limit_365_setupDate,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_0_30',
              aggregate: true,
              header: '0-31 days',
              field: 'limit_0_30',
              type: 'currency',
              width: 200,
              onPress: ({ row }) => {
                navigation.navigate('customer-ageing-invoices-datewise', {
                  row,
                  invoiceFilter: {
                    customer: { $in: row?.limit0_30Ids },
                    date: row?.limit0_30Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_30_90',
              aggregate: true,
              header: '31-90 days',
              field: 'limit_30_90',
              type: 'currency',
              width: 200,
              onPress: ({ row }) => {
                navigation.navigate('customer-ageing-invoices-datewise', {
                  row,
                  invoiceFilter: {
                    customer: { $in: row?.limit30_90Ids },
                    date: row?.limit30_90Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_91_270',
              aggregate: true,
              header: '91-270 days',
              field: 'limit_91_270',
              type: 'currency',
              width: 200,
              onPress: ({ row }) => {
                navigation.navigate('customer-ageing-invoices-datewise', {
                  row,
                  invoiceFilter: {
                    customer: { $in: row?.limit90_270Ids },
                    date: row?.limit90_270Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_270_365',
              aggregate: true,
              header: '270-365 days',
              field: 'limit_270_365',
              type: 'currency',
              width: 200,
              onPress: ({ row }) => {
                navigation.navigate('customer-ageing-invoices-datewise', {
                  row,
                  invoiceFilter: {
                    customer: { $in: row?.limit270_365Ids },
                    date: row?.limit270_365Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_365',
              aggregate: true,
              header: '>365 days',
              field: 'limit_365',
              type: 'currency',
              width: 200,
              onPress: ({ row }) => {
                navigation.navigate('customer-ageing-invoices-datewise', {
                  row,
                  invoiceFilter: {
                    customer: { $in: row?.limit365PlusIds },
                    date: row?.limit365PlusDate,
                    ...invoiceFilter,
                  },
                });
              },
            },
          ],
        },
      ]}
    />
  );
};


export const overdueReportDrillTable = props => {
  const {
    route: { params } = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const { invoiceFilter = {} } = params;
  const fetchProps = {
    params,
    searchValue,
    addOnFilter,
    api: '/revenueInvoices/all',
    addonGroupBy: groupBy?.addonGroupBy,
    groupRow: groupBy?.groupRow,
  };
  return (
    <Table
      api={`/invoicereceipthistory`}
      search={searchValue}
      // limit={100}
      addOnFilter={{ ...addOnFilter, ...invoiceFilter }}
      renderHeader={() => {
        return (
          Object.keys(invoiceFilter).length > 0 && (
            <TableHeader title="Invoices" />
          )
        );
      }}
      params={params}
      onRowPress={({ row }) => {
        if (row?.receipt_id) {
          navigation.navigate(`details-revenue-receipt`, {
            row: row?.receipt_id,
          });
        } else if (row?.invoice_id) {
          navigation.navigate(`non-editable-revenue-invoice`, {
            row: row?.invoice_id,
          });
        }
      }}
      eventSourceId="revenue-invoice"
      columns={[
        {
          type: 'userAvatarChip',
          field: 'customer',
          header: 'Customer',
        },
        {
          type: 'date',
          field: 'date',
          header: "Date"
        },
        {
          type: 'text',
          render: ({ row, styles }) => {
            if (row?.credit_invoice_id) {
              return <Text style={{fontSize: 14, ...styles}}>{row?.credit_invoice_id?.invoice_number}</Text>;
            } else if (row?.invoice_id) {
              return <Text style={{fontSize: 14,...styles}}>{row?.invoice_id?.invoice_number}</Text>;
            } else if (row?.receipt_id) {
              return <Text style={{fontSize: 14, ...styles}}>{row?.receipt_id?.receipt_number}</Text>;
            } else if (row?.number) {
              return <Text style={{fontSize: 14 , ...styles}}>{row?.number}</Text>;
            }
          },
          header: "Number"
        },
        {
          header: "Amount",
          width: 150,
          aggregate: true,
          field : 'amount',
          render: ({ row, styles }) => {
            return <AmountRender
              row={row}
              styles={styles}
              amount={row?.amount || 0}
            />;
          },
        },
      ]}
    />
  );
};