import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const ProjectResourceWorkingForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projectresourceworkingdata',
    eventSourceId: 'projectResourceWorking',
  });

  return (
    <Form
      api={`/projectresourceworkingdata/${row?._id}`}
      fields={{
        project: {project: 1},
        project_resource_employee: {name: 1},
        billing_start_date: 1,
        billing_end_date: 1,
        billing_hrs: 1,
        total_leave_hours: 1,
        total_ewd_hours: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Project Resource Working"
      layoutFields={[
        {
          label: 'Project',
          placeholder: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          required: true,
          size: 6,
        },
        {
          label: 'Employee',
          placeholder: 'Employee',
          field: 'project_resource_employee',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Billing Start Date',
          placeholder: 'Billing Start Date',
          field: 'billing_start_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'Billing End Date',
          placeholder: 'Billing End Date',
          field: 'billing_end_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'Billing Hours',
          placeholder: 'Billing Hours',
          field: 'billing_hrs',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'Total Leave Hours',
          placeholder: 'Total Leave Hours',
          field: 'total_leave_hours',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'Total EWD Hours',
          placeholder: 'Total EWD Hours',
          field: 'total_ewd_hours',
          type: 'number',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const EditProjectResourceWorkingForm = props => {
  return (
    <ProjectResourceWorkingForm
      mode="edit"
      header={'Edit Project Resource Working'}
      {...props}
    />
  );
};
