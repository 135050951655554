import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const customerController = props => {
  const {params, navigation} = props;
  const {post, user} = useAppStateContext();
  const invoke = useInvoke({method: 'put'});
  const invokePost = useInvoke({
    method: 'post',
    eventSourceId: [
      'popuateMessage',
      'populateBusiness',
      'sendAction',
      'updateBrowser',
      'UnlockRequest',
      'RemoveLocks',
      'removeError',
    ],
  });
  const invokeDelete = useInvoke({
    method: 'delete',
    eventSourceId: ['communicationAdded', 'indexUpdated'],
  });

  const markCustomerLost = ({row}) => {
    navigation.navigate(`mark-customer-lost`, {
      ...params,
      row,
    });
  };

  const makeDuplicateCustomer = ({row}) => {
    navigation.navigate(`make-duplicate-customer`, {
      ...params,
      row,
    });
  };

  const activityLogOfCustomer = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [{path: 'owner', select: {name: 1}}],
      api: `/customers/${row?._id}/activityLogs`,
      displayFields: {
        name: {
          label: 'Name',
        },
        website: {
          label: 'Website',
        },
        active_status: {
          label: 'Active Status',
        },
        owner: {
          label: 'Owner',
          field: 'owner.name',
        },
        account_owner: {
          label: 'Account Owner',
          field: 'account_owner.name',
        },
      },
    });
  };

  const CustomerCommunications = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row: {
        _id: row?.potential_customer?._id,
      },
    });
  };

  return {
    markCustomerLost,
    makeDuplicateCustomer,
    activityLogOfCustomer,
    CustomerCommunications,
  };
};
