import React from 'react';
import {RecursiveTable, Table} from '../../../components/table/Table';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  BilledManagedTeamProjects,
  BilledSDLCProjects,
  BilledStaffaugmentationProjects,
  PerformanceAllTab,
  PerformanceClosedTandMTab,
  PerformanceFixedRetainerTab,
  PerformanceFixedTab,
} from '../../pmt/views/PerformanceTab';
import {PerformanceUnbilledReportTable} from '../../pmt/views/PerformanceReportTable';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {RecursiveRender} from '@unthinkable/react-table';
import {useAppStateContext} from '../../../providers/AppState';
import { TabView } from '../../../components/tab';
import { useInvoke } from '../../../controllers/useInvoke';

export const ProjectTypeWiseTable = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const addOnFilter = {};
  if (user?.org) {
    addOnFilter.org = user?.org;
  }
  const fetchProps = {
    api: '/projecttypes',
    fields: {
      project_billing_type: 1,
    },
    addOnFilter,
  };

  return (
    <Table
      {...fetchProps}
      eventSourceId={['ProjectsRole']}
      onRowPress={({row}) => {
        navigation.navigate('project-type-wise-project-sub-type', {
          ...params,
          project_type: row,
          title: row.project_billing_type,
          user,
        });
      }}
      columns={[
        {
          header: 'Billing status',
          field: 'project_billing_type',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const BilledProjectLeftMenu = props => {
  const {
    route: {params},
  } = props;

  //   const currentMonth = getCurrentMonth();
  const menus = [
    {
      label: 'Staff augmentation',
      view: <BilledStaffaugmentationProjects />,
    },
    {
      label: 'Managed Team',
      view: <BilledManagedTeamProjects />,
    },
    {
      label: 'SDLC',
      view: <BilledSDLCProjects />,
    },
    {
      label: 'SDLC+',
      view: (
        <PerformanceFixedTab
          project_status="a"
          tab="Assigned"
          {...props}
          addOnParamFilter={{
            $or: [
              {is_support_project: false},
              {is_support_project: {$exists: false}},
            ],
          }}
        />
      ),
    },
    {
      label: 'Retainer',
      view: (
        <PerformanceFixedRetainerTab
          project_status="a"
          addOnParamFilter={{is_support_project: true}}
          tab="Assigned"
          {...props}
        />
      ),
    },
    {
      label: 'All',
      section: true,
      menus: [
        {
          label: 'All',
          view: (
            <PerformanceAllTab
              key="NewProject"
              project_status="a"
              tab="All Active"
              is_project_assign={true}
              billed_project_menu={true}
              {...props}
            />
          ),
          //   view: <DepartmentWiseEmployeeTabs />,
        },
        {
          label: 'Closed',
          view: (
            <PerformanceClosedTandMTab
              project_status="c"
              tab="Completed"
              billed_project_menu={true}
              {...props}
            />
          ),
        },
      ],
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const UnBilledProjectLeftMenu = props => {
  const {
    route: {params},
  } = props;

  //   const currentMonth = getCurrentMonth();
  const menus = [
    // {
    //   label: 'Unbilled',
    //   view: <BilledStaffaugmentationProjects />,
    // },
    // {
    //   label: 'Managed Team',
    //   view: <BilledManagedTeamProjects />,
    // },
    // {
    //   label: 'SDLC',
    //   view: <BilledSDLCProjects />,
    // },
    {
      label: 'Unassigned',
      section: true,
      menus: [
        {
          label: 'Active',
          view: (
            <PerformanceUnbilledReportTable
              filter={{
                project_status: 'a',
                projecttype: 'i',
              }}
              tab="Active"
              project_status="a"
              tabName="unbilled"
              fromUnbilled={true}
              {...props}
            />
          ),
        },
        {
          label: 'Closed',
          view: (
            <PerformanceUnbilledReportTable
              filter={{
                project_status: 'c',
                projecttype: 'i',
              }}
              tab="Closed"
              project_status="c"
              tabName="unbilled"
              fromUnbilled={true}
              {...props}
            />
          ),
        },
      ],
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const ProjectTypeWiseTableSetup = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const addOnFilter = {};
  if (user?.org) {
    addOnFilter.org = user?.org;
  }

  const fetchProps = {
    api: '/projecttypes',
    fields: {
      project_billing_type: 1,
    },
    addOnFilter,
  };

  return (
    <Table
      {...fetchProps}
      renderHeader={() => (
        <TableHeader
          title={'Resources'}
          actions={
            <AddButton
              title=" Add Project Type"
              view="add-project-type"
              params={props?.route?.params}
            />
          }
        />
      )}
      eventSourceId={['ProjectsRole']}
      onRowPress={({row}) => {
        navigation.navigate('edit-project-type', {
          ...params,
          project_type: row._id,
        });
      }}
      columns={[
        {
          header: 'Billing status',
          field: 'project_billing_type',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const ProjectSubTypeWiseTableSetup = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const addOnFilter = {};
  if (user?.org) {
    addOnFilter.org = user?.org;
  }

  const fetchProps = {
    api: '/project-subtypes',
    fields: {
      project_sub_type: 1,
      parent: {_id: 1, project_sub_type: 1},
    },
    addOnFilter,
  };
  return (
    <RecursiveTable
      {...fetchProps}
      renderHeader={() => (
        <TableHeader
          title={'Label'}
          actions={
            <AddButton
              title=" Add Project Label"
              view="add-project-sub-type"
              params={props?.route?.params}
            />
          }
        />
      )}
      eventSourceId={['ProjectsRole']}
      onRowPress={({row}) => {
        navigation.navigate('edit-project-sub-type', {
          ...params,
          project_type: row._id,
        });
      }}
      columns={[
        {
          header: 'Label',
          field: 'project_sub_type',
          type: 'text',
          render: props => <RecursiveRender {...props} />,
          indent: false,
        },
        // {
        //   header: 'Count',
        //   field: 'project_count',
        //   type: 'number',
        //   indent: false,
        // },
      ]}
      {...props}
    />
  );
};

export const ProjectSubTypeWiseNavigationTable = props => {
  const {
    route: {params},
    navigation,
    user,
  } = props;
  const {project_type, title} = params;
  const navigationMap = {
    'Managed Team': 'managed-team-sub-type-project',
    'Staff Augmentation': 'staff-augmentation-sub-type-project',
    SDLC: 'sdlc-sub-type-project',
    'SDLC+': 'sdlc-plus-sub-type-project',
    Retainer: 'retainer-sub-type-project',
  };
  const {fetch} = useAppStateContext();

  const handleRowPress = async ({row, user}) => {
    const navigationTarget = navigationMap[row?.project_sub_type];
    const commonParams = {...params};
    const {externalUser} = user || {};
    const {data: projectParentData = {}} = await fetch({
      uri: `/projectsubtypes`,
      props: {
        fields: {
          _id: 1,
          parent: {project_sub_type: 1, _id: 1},
        },
        filter: {
          parent: row?.parent,
        },
        only: true,
      },
    });
    if (
      navigationTarget &&
      projectParentData?.parent?.project_sub_type == 'Billed'
    ) {
      let additionalParams = {};
      if (row?.project_sub_type === 'SDLC+') {
        additionalParams = {
          project_status: 'a',
          tab: 'Assigned',
          addOnParamFilter: {
            $or: [
              {is_support_project: false},
              {is_support_project: {$exists: false}},
            ],
          },
        };
      } else if (row?.project_sub_type === 'Retainer') {
        additionalParams = {
          project_status: 'a',
          tab: 'Assigned',
          addOnParamFilter: {
            is_support_project: true,
          },
        };
      }

      if (navigationTarget) {
        navigation.navigate(navigationTarget, {
          ...commonParams,
          ...additionalParams,
        });
      }
    } else if (projectParentData?.parent?.project_sub_type == 'Unbilled') {
      const additionalParams = {
        addOnParamFilter: {
          new_project_sub_type: row?._id,
          title: row?.project_sub_type,
          project_status: 'a',
          projecttype: 'i',
        },
        tab: row?.project_sub_type,
      };
      if (
        row?.project_sub_type == 'POC' ||
        row?.project_sub_type == 'Internal' ||
        row?.project_sub_type == 'Social' ||
        row?.project_sub_type == 'Module Developement'
      ) {
        navigation.navigate('unbilled-sub-type-project', {
          ...commonParams,
          ...additionalParams,
          fromUnbilled: true,
        });
      } else {
        navigation.navigate('project-sub-type-wise-common-tabs', {
          ...commonParams,
          ...additionalParams,
        });
      }
    } else {
      const {data: projectParentData = []} = await fetch({
        uri: `/projectsubtypes`,
        props: {
          fields: {
            _id: 1,
          },
          filter: {
            parent: row?._id,
          },
        },
      });
      let filter = [];
      if (projectParentData && projectParentData.length > 0) {
        filter = projectParentData.map(item => item._id);
      } else {
        filter = [row?._id];
      }
      const additionalParams = {
        addOnParamFilter: {
          new_project_sub_type: {$in: filter},
          new_project_sub_type_id: row?._id,
        },
        tab: row?.project_sub_type,
        secondaryTitle: projectParentData?.parent?.project_sub_type,
      };
      if (externalUser) {
        navigation.navigate('client-project-sub-type-wise-common-tabs', {
          ...commonParams,
          ...additionalParams,
          user,
        });
      } else {
        navigation.navigate('project-sub-type-wise-common-tabs', {
          ...commonParams,
          ...additionalParams,
        });
      }
    }
  };

  return (
    <RecursiveTable
      eventSourceId="ProjectsRole"
      api={`/project-subtypes`}
      renderHeader={() => <TableHeader title="Types" />}
      fields={{
        project_sub_type: 1,
        parent: {_id: 1, project_sub_type: 1},
      }}
      limit={800}
      onRowPress={props => handleRowPress({row: props.row, user})}
      columns={[
        {
          header: 'Name',
          field: 'project_sub_type',
          type: 'text',
          render: props => <RecursiveRender {...props} />,
          indent: false,
        },
        {
          header: 'Count',
          field: 'children_count',
          type: 'number',
          indent: false,
        },
      ]}
    />
  );
};

export const ProjectSubTypeWiseNavigationTableForPM = props => {
  const {
    route: {params},
    navigation,
    user,
  } = props;
  const {project_type, title} = params;
  const navigationMap = {
    'Managed Team': 'managed-team-sub-type-project-for-pm',
    'Staff Augmentation': 'staff-augmentation-sub-type-project-for-pm',
    SDLC: 'sdlc-sub-type-project-for-pm',
    'SDLC+': 'sdlc-plus-sub-type-project-for-pm',
    Retainer: 'retainer-sub-type-project-for-pm',
  };
  const {fetch} = useAppStateContext();

  const handleRowPress = async ({row, user}) => {
    const navigationTarget = navigationMap[row?.project_sub_type];
    const commonParams = {...params};
    const {externalUser} = user || {};
    const {data: projectParentData = {}} = await fetch({
      uri: `/projectsubtypes`,
      props: {
        fields: {
          _id: 1,
          parent: {project_sub_type: 1, _id: 1},
        },
        filter: {
          parent: row?.parent,
        },
        only: true,
      },
    });
    if (
      navigationTarget &&
      projectParentData?.parent?.project_sub_type == 'Billed'
    ) {
      let additionalParams = {};
      if (row?.project_sub_type === 'SDLC+') {
        additionalParams = {
          project_status: 'a',
          tab: 'Assigned',
          addOnParamFilter: {
            $or: [
              {is_support_project: false},
              {is_support_project: {$exists: false}},
            ],
          },
        };
      } else if (row?.project_sub_type === 'Retainer') {
        additionalParams = {
          project_status: 'a',
          tab: 'Assigned',
          addOnParamFilter: {
            is_support_project: true,
          },
        };
      }

      if (navigationTarget) {
        navigation.navigate(navigationTarget, {
          ...commonParams,
          ...additionalParams,
        });
      }
    } else if (projectParentData?.parent?.project_sub_type == 'Unbilled') {
      const additionalParams = {
        addOnParamFilter: {
          new_project_sub_type: row?._id,
          title: row?.project_sub_type,
          project_status: 'a',
          projecttype: 'i',
        },
        tab: row?.project_sub_type,
      };
      if (
        row?.project_sub_type == 'POC' ||
        row?.project_sub_type == 'Internal' ||
        row?.project_sub_type == 'Social' ||
        row?.project_sub_type == 'Module Developement'
      ) {
        navigation.navigate('unbilled-sub-type-project-for-pm', {
          ...commonParams,
          ...additionalParams,
          fromUnbilled: true,
        });
      } else {
        navigation.navigate('project-sub-type-wise-common-tabs-for-pm', {
          ...commonParams,
          ...additionalParams,
        });
      }
    } else {
      const {data: projectParentData = []} = await fetch({
        uri: `/projectsubtypes`,
        props: {
          fields: {
            _id: 1,
          },
          filter: {
            parent: row?._id,
          },
        },
      });
      let filter = [];
      if (projectParentData && projectParentData.length > 0) {
        filter = projectParentData.map(item => item._id);
      } else {
        filter = [row?._id];
      }
      const additionalParams = {
        addOnParamFilter: {
          new_project_sub_type: {$in: filter},
          new_project_sub_type_id: row?._id,
        },
        tab: row?.project_sub_type,
        secondaryTitle: projectParentData?.parent?.project_sub_type,
      };
      if (externalUser) {
        navigation.navigate('client-project-sub-type-wise-common-tabs', {
          ...commonParams,
          ...additionalParams,
          user,
        });
      } else {
        navigation.navigate('project-sub-type-wise-common-tabs-for-pm', {
          ...commonParams,
          ...additionalParams,
        });
      }
    }
  };

  return (
    <RecursiveTable
      eventSourceId="ProjectsRole"
      api={`/project-subtypes-pm`}
      renderHeader={() => <TableHeader title="Types" />}
      fields={{
        project_sub_type: 1,
        parent: {_id: 1, project_sub_type: 1},
      }}
      limit={800}
      onRowPress={props => handleRowPress({row: props.row, user})}
      columns={[
        {
          header: 'Name',
          field: 'project_sub_type',
          type: 'text',
          render: props => <RecursiveRender {...props} />,
          indent: false,
        },
        {
          header: 'Count',
          field: 'children_count',
          type: 'number',
          indent: false,
        },
      ]}
    />
  );
};

export const ProjectSubTypeWiseNavigationTableForAM = props => {
  const {
    route: {params},
    navigation,
    user,
  } = props;
  const {project_type, title} = params;
  const navigationMap = {
    'Managed Team': 'managed-team-sub-type-project-for-am',
    'Staff Augmentation': 'staff-augmentation-sub-type-project-for-am',
    SDLC: 'sdlc-sub-type-project-for-am',
    'SDLC+': 'sdlc-plus-sub-type-project-for-am',
    Retainer: 'retainer-sub-type-project-for-am',
  };
  const {fetch} = useAppStateContext();

  const handleRowPress = async ({row, user}) => {
    const navigationTarget = navigationMap[row?.project_sub_type];
    const commonParams = {...params};
    const {externalUser} = user || {};
    const {data: projectParentData = {}} = await fetch({
      uri: `/projectsubtypes`,
      props: {
        fields: {
          _id: 1,
          parent: {project_sub_type: 1, _id: 1},
        },
        filter: {
          parent: row?.parent,
        },
        only: true,
      },
    });
    if (
      navigationTarget &&
      projectParentData?.parent?.project_sub_type == 'Billed'
    ) {
      let additionalParams = {};
      if (row?.project_sub_type === 'SDLC+') {
        additionalParams = {
          project_status: 'a',
          tab: 'Assigned',
          addOnParamFilter: {
            $or: [
              {is_support_project: false},
              {is_support_project: {$exists: false}},
            ],
          },
        };
      } else if (row?.project_sub_type === 'Retainer') {
        additionalParams = {
          project_status: 'a',
          tab: 'Assigned',
          addOnParamFilter: {
            is_support_project: true,
          },
        };
      }

      if (navigationTarget) {
        navigation.navigate(navigationTarget, {
          ...commonParams,
          ...additionalParams,
        });
      }
    } else if (projectParentData?.parent?.project_sub_type == 'Unbilled') {
      const additionalParams = {
        addOnParamFilter: {
          new_project_sub_type: row?._id,
          title: row?.project_sub_type,
          project_status: 'a',
          projecttype: 'i',
        },
        tab: row?.project_sub_type,
      };
      if (
        row?.project_sub_type == 'POC' ||
        row?.project_sub_type == 'Internal' ||
        row?.project_sub_type == 'Social' ||
        row?.project_sub_type == 'Module Developement'
      ) {
        navigation.navigate('unbilled-sub-type-project-for-pm', {
          ...commonParams,
          ...additionalParams,
          fromUnbilled: true,
        });
      } else {
        navigation.navigate('project-sub-type-wise-common-tabs-for-am', {
          ...commonParams,
          ...additionalParams,
        });
      }
    } else {
      const {data: projectParentData = []} = await fetch({
        uri: `/projectsubtypes`,
        props: {
          fields: {
            _id: 1,
          },
          filter: {
            parent: row?._id,
          },
        },
      });
      let filter = [];
      if (projectParentData && projectParentData.length > 0) {
        filter = projectParentData.map(item => item._id);
      } else {
        filter = [row?._id];
      }
      const additionalParams = {
        addOnParamFilter: {
          new_project_sub_type: {$in: filter},
          new_project_sub_type_id: row?._id,
        },
        tab: row?.project_sub_type,
        secondaryTitle: projectParentData?.parent?.project_sub_type,
      };
      if (externalUser) {
        navigation.navigate('client-project-sub-type-wise-common-tabs', {
          ...commonParams,
          ...additionalParams,
          user,
        });
      } else {
        navigation.navigate('project-sub-type-wise-common-tabs-for-am', {
          ...commonParams,
          ...additionalParams,
        });
      }
    }
  };

  return (
    <RecursiveTable
      eventSourceId="ProjectsRole"
      api={`/project-subtypes-am`}
      renderHeader={() => <TableHeader title="Types" />}
      fields={{
        project_sub_type: 1,
        parent: {_id: 1, project_sub_type: 1},
      }}
      limit={800}
      onRowPress={props => handleRowPress({row: props.row, user})}
      columns={[
        {
          header: 'Name',
          field: 'project_sub_type',
          type: 'text',
          render: props => <RecursiveRender {...props} />,
          indent: false,
        },
        {
          header: 'Count',
          field: 'children_count',
          type: 'number',
          indent: false,
        },
      ]}
    />
  );
};

export const ProjectApprovalPendingTablSetup = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const addOnFilter = {};
  if (user?.org) {
    addOnFilter.org = user?.org;
  }
  const fetchProps = {
    api: '/projectapprovalrequireds',
    fields: {
      approval_required: 1,
      org: {_id: 1, name: 1},
    },
    addOnFilter,
  };
  return (
    <Table
      {...fetchProps}
      renderHeader={() => (
        <TableHeader
          title={'Resources'}
          actions={
            <AddButton
              title=" Add Approval"
              view="add-project-approval-required"
              params={props?.route?.params}
            />
          }
        />
      )}
      eventSourceId={['ProjectsRole']}
      onRowPress={({row}) => {
        navigation.navigate('edit-project-approval-required', {
          ...params,
          project_type: row._id,
        });
      }}
      columns={[
        {
          header: 'Approval required',
          field: 'approval_required',
          type: 'text',
        },
        {
          header: 'Organization',
          field: 'org.name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

// export const ProjectSubTypeWiseNavigationTable = props => {
//   const {
//     route: {params},
//     navigation,
//   } = props;
//   const {project_type, title, user} = params;
//   const navigationMap = {
//     'Managed Team': 'managed-team-sub-type-project',
//     'Staff Augmentation': 'staff-augmentation-sub-type-project',
//     SDLC: 'sdlc-sub-type-project',
//     'SDLC+': 'sdlc-plus-sub-type-project',
//     Retainer: 'retainer-sub-type-project',
//   };

//   const handleRowPress = ({row, user}) => {
//     const navigationTarget = navigationMap[row?.project_sub_type];
//     const commonParams = {...params};
//     const {externalUser} = user || {};
//     if (navigationTarget && project_type?.project_billing_type == 'Billed') {
//       let additionalParams = {};
//       if (row?.project_sub_type === 'SDLC+') {
//         additionalParams = {
//           project_status: 'a',
//           tab: 'Assigned',
//           addOnParamFilter: {
//             $or: [
//               {is_support_project: false},
//               {is_support_project: {$exists: false}},
//             ],
//           },
//         };
//       } else if (row?.project_sub_type === 'Retainer') {
//         additionalParams = {
//           project_status: 'a',
//           tab: 'Assigned',
//           addOnParamFilter: {
//             is_support_project: true,
//           },
//         };
//       }

//       if (navigationTarget) {
//         navigation.navigate(navigationTarget, {
//           ...commonParams,
//           ...additionalParams,
//         });
//       }
//     } else if (project_type?.project_billing_type == 'Unbilled') {
//       const additionalParams = {
//         addOnParamFilter: {
//           new_project_sub_type: row?._id,
//           title: row?.project_sub_type,
//           project_status: 'a',
//           projecttype: 'i',
//         },
//         tab: row?.project_sub_type,
//       };
//       navigation.navigate('unbilled-sub-type-project', {
//         ...commonParams,
//         ...additionalParams,
//       });
//     } else {
//       const additionalParams = {
//         addOnParamFilter: {
//           new_project_sub_type: row?._id,
//         },
//         tab: row?.project_sub_type,
//         secondaryTitle: project_type?.project_billing_type,
//       };
//       if (externalUser) {
//         navigation.navigate('client-project-sub-type-wise-common-tabs', {
//           ...commonParams,
//           ...additionalParams,
//           user,
//         });
//       } else {
//         navigation.navigate('project-sub-type-wise-common-tabs', {
//           ...commonParams,
//           ...additionalParams,
//         });
//       }
//     }
//   };

//   return (
//     <Table
//       eventSourceId="ProjectsRole"
//       api={`/projectsubtypes`}
//       renderHeader={() => <TableHeader title="Types" />}
//       fields={{
//         project_sub_type: 1,
//         project_type: {_id: 1, project_billing_type: 1},
//       }}
//       filter={{
//         project_type: project_type?._id,
//       }}
//       onRowPress={props => handleRowPress({row: props.row, user})}
//       columns={[
//         {
//           header: 'Name',
//           field: 'project_sub_type',
//           type: 'text',
//         },
//       ]}
//     />
//   );
// };

export const AuditParameterTableSetup = props => {
  const {
    navigation,
    route: {params},
    tab,
    addOnFilter,
  } = props;
  console.log(">>>>>>>>>>>>>>>>>>",props)
  const fetchProps = {
    api: '/auditparameters',
    fields: {
      name: 1,
      description: 1,
      project_type : {_id : 1, project_sub_type : 1},
      max_score : 1
    },
    addOnFilter
  };
  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'AuditParameter',
  });
  return (
    <Table
      {...fetchProps}
      // renderHeader={() => (
      //   <TableHeader
      //     title={'Audit Parameter'}
      //     actions={
      //       <AddButton
      //         title=" Add Audit Parameter"
      //         view="add-project-type"
      //         params={props?.route?.params}
      //       />
      //     }
      //   />
      // )}
      eventSourceId={['AuditParameter']}
      onRowPress={({row}) => {
        navigation.navigate('edit-audit-parameter', {
          ...params,
          project_type: row._id,
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Project Type',
          field: 'project_type.project_sub_type',
          type: 'text',
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
        },
        {
          header: 'Max Score',
          field: 'max_score',
          type: 'text',
        },
      ]}
      moreActions = {[
        {
          title: 'Mark Inactive',
          variant: 'error',
          confirm: {
            title: 'Mark Inactive',
            message: 'Are you sure you want to inactive this parameter?',
          },
          visible: tab && tab === "Active",
          onPress: ({row}) => {
            putInvoke({
              uri: `/auditparameters/${row._id}`,
              props: {
                status: 'Inactive',
              },
            });
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to active this parameter?',
          },
          visible: tab && tab === "Inactive",
          onPress: ({row}) => {
            putInvoke({
              uri: `/auditparameters/${row._id}`,
              props: {
                status: 'Active',
              },
            });
          },
        }
      ]}
      {...props}
    />
  );
};

export const AuditParameterTab = props => {
  let { route: { params } = {}, navigation } = props;
  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <AuditParameterTableSetup
          tab={"Active"}
          addOnFilter={{ status: "Active" }}
          {...props} />
      ),
      eventSourceId: 'AuditParameter',
      api: '/auditparameters',
      tab: "Active",
      addOnFilter: { status: "Active" }
    },
    Inactive: {
      label: 'Inactive',
      view: (
        <AuditParameterTableSetup
          tab={"Inactive"}
          addOnFilter={{ status: "Inactive" }}
          {...props} />
      ),
      eventSourceId: 'AuditParameter',
      api: '/auditparameters',
      tab: "Inactive",
      addOnFilter: { status: "Inactive" }
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <AddButton
          title=" Add Audit Parameter"
          view="add-audit-parameter"
          params={props?.route?.params}
        />
      ]}
    />
  );
};