// List to show the industry of potential customer. - Rajnish Trikaldarshi 15-03-2024
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';

export const IndustryList = props => {
  let {params, navigation} = props || {};

  const {user} = useAppStateContext();
  let {email} = user || {};
  const showAction =
    email === 'rohit.bansal@daffodilsw.com' ||
    email === 'kanika.kashyap@unthinkable.co';

  return (
    <Table
      api={`/potentialCustomers/industryData`}
      eventSourceId={['mergeIndustryData']}
      sort={{createdAt: -1}}
      limit={-1}
      columns={[
        {
          header: 'Industry Name',
          type: 'text',
          field: 'industry',
        },
        {
          header: 'Count',
          type: 'text',
          field: 'count',
          align: 'center',
        },
      ]}
      moreActions={[
        showAction
          ? {
              title: 'Select',
              onPress: ({row}) => {
                navigation.navigate(`select-industry-to-merge`, {
                  row,
                });
              },
            }
          : void 0,
      ]}
    />
  );
};
