import {Avatar} from '@unthinkable/react-avatar';
import moment from 'moment';
import React from 'react';
import {
  DateText,
  DateChipContainer,
  Line,
  MessageBoxWrapper,
  MessageWrapper,
  Sender,
  TextMessage,
  Time,
  TimeContainer,
} from './Styles';

export const Message = ({
  styles,
  position = 'left',
  color,
  background,
  message,
  sender,
  time,
}) => {
  const avatar = sender ? <Avatar value={sender} color={color} /> : void 0;
  time = time ? moment(time).format('hh:mm A') : void 0;
  if (!message) return null;
  const messageBox = (
    <MessageBoxWrapper
      gap={8}
      position={position}
      backgroundColor={background}
      styles={styles}>
      <Sender color={color} styles={styles}>
        {sender}
      </Sender>
      <TextMessage styles={styles?.message} message={message} />
      <TimeContainer position={position}>
        <Time styles={styles}>{time}</Time>
      </TimeContainer>
    </MessageBoxWrapper>
  );
  return (
    <MessageWrapper gap={8} position={position} styles={styles}>
      {avatar}
      {messageBox}
    </MessageWrapper>
  );
};

export const DateChip = ({date, format = 'DD MMM', styles}) => {
  let formattedDate =
    date == new Date()
      ? 'Today'
      : new Date(Date.now() - 864e5) == date
      ? 'Yesterday'
      : `${moment(date).format(format)} | ${moment(date).format('ddd')}`;
  return (
    <DateChipContainer gap={10} styles={styles}>
      <Line styles={styles} />
      <DateText styles={styles}>{formattedDate}</DateText>
      <Line styles={styles} />
    </DateChipContainer>
  );
};
