import React, {useEffect, useState} from 'react';
import {
  Col,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {BranchStyle} from '../headers/theme';
import {useToast} from '@unthinkable/react-toast';
import {IconButton} from '@unthinkable/react-button';
import {GroupTable, Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {PrStatusChip} from '../../../components/chip/PrStatusChip';
import {LinkRenderer} from '../../../components/form/Renderers';
import {useFetchData} from '../../../controllers/useFetchData';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Tag} from '../../../components/chip/Chip';
import {FeatureStatus, QAStages} from '../constants/FeatureStages';
import {BorderLessButton} from '../../../components/button/AddButton';
import useRealTimeSync from '../../../components/customHooks/useRealTimeSync';

// const PullRequest = ({
//   row,
//   branch,
//   navigation,
//   feature_branch_merge_to,
//   from,
//   ...params
// }) => {
// const {
//   vcs: {type, workspace},
//   repository,
// } = row;

// const {
//   target_pr_status,
//   target_pr_status_link,
//   target_pr_status_count,
//   pr_status,
//   pr_status_link,
//   open_pr_count = 0,
//   branch_name,
//   parent_branch_id: {branch_name: parentBranchName} = {},
// } = branch || {};

// const {colors, fonts, icons} = useTheme();
// const {ArrowSquareOut, LockGrey} = icons;

// const styles = useStyles(RenderCommitColumnStyles);
//   const openPRTable = filter => {
//     navigation.navigate('pull-request-table', {
//       ...params,
//       filter,
//       secondaryTitle: params?.feature?.feature
//         ? `Feature : ${params?.feature?.feature}`
//         : '',
//     });
//   };

//   return (
//     <View style={{gap: 6}}>
//       {from === 'User' ? (
//         <LinkRenderer
//           value={getPullRequestLink({
//             type,
//             workspace,
//             repository,
//             branch_name,
//             parentBranchName,
//           })}
//           icon={ArrowSquareOut}
//           linkText={`PR To ${Labels.Feature}`}
//         />
//       ) : (
//         void 0
//       )}
//       {target_pr_status ? (
//         <Row style={{alignItems: 'center', gap: 8}}>
//           <Text style={styles?.text}>{`Review PR : `}</Text>
//           <PrStatusChip
//             onPress={() => {
//               if (target_pr_status_count === 1) {
//                 target_pr_status_link &&
//                   Linking.openURL(target_pr_status_link, '_blank');
//               } else {
//                 openPRTable({parent_branch_id: branch?._id});
//               }
//             }}
//             value={`${target_pr_status}${
//               target_pr_status_count > 1
//                 ? '(' + target_pr_status_count + ')'
//                 : ''
//             }`}
//           />
//         </Row>
//       ) : (
//         void 0
//       )}
//       {from === 'Feature'
//         ? feature_branch_merge_to?.map(mergeToEnvironment => {
//             if (mergeToEnvironment?.environment === 'qa' && row?.lock_for_qa) {
//               return (
//                 <Row>
//                   <Tooltip content="This repository is locked for QA. Please wait for the QA to unlock it.">
//                     <Row gap={8}>
//                       <Image
//                         source={LockGrey}
//                         style={{
//                           width: 12,
//                         }}
//                       />
//                       <Text
//                         style={{
//                           color: colors.ERROR_HIGH,
//                           ...fonts.BODY2,
//                         }}>
//                         {`PR To ${mergeToEnvironment.label}`}
//                       </Text>
//                     </Row>
//                   </Tooltip>
//                 </Row>
//               );
//             }
//             return (
//               <LinkRenderer
//                 value={getPullRequestLink({
//                   type,
//                   workspace,
//                   repository,
//                   branch_name,
//                   parentBranchName: mergeToEnvironment.branch,
//                 })}
//                 icon={ArrowSquareOut}
//                 linkText={`PR To ${mergeToEnvironment.label}`}
//               />
//             );
//           })
//         : void 0}
//       {from === 'Bug'
//         ? feature_branch_merge_to?.map(mergeToEnvironment => {
//             return (
// <LinkRenderer
//   value={getPullRequestLink({
//     type,
//     workspace,
//     repository,
//     branch_name,
//     parentBranchName: mergeToEnvironment.branch,
//   })}
//   icon={ArrowSquareOut}
//   linkText={`PR To ${mergeToEnvironment.label}`}
// />
//             );
//           })
//         : void 0}

//       {pr_status ? (
//         <Row style={{gap: 4, alignItems: 'center'}}>
//           <Text style={styles?.text}>PR :</Text>
//           <PrStatusChip
//             onPress={() => {
//               if (open_pr_count === 1) {
//                 pr_status_link && Linking.openURL(pr_status_link, '_blank');
//               } else {
//                 openPRTable({branch_id: branch?._id});
//               }
//             }}
//             value={`${pr_status}${
//               open_pr_count > 1 ? '(' + open_pr_count + ')' : ''
//             }`}
//           />
//         </Row>
//       ) : (
//         void 0
//       )}
//       {/* <TouchableOpacity
//           onPress={() => {
//             openPRTable('FeaturePr');
//           }}>
//           <Text
//             style={
//               pr_count > 0 ? styles?.text : styles?.textDisabled
//             }>{`${pr_count} PR`}</Text>
//         </TouchableOpacity> */}

//       {/* <AddFilledAction
//           view="pull-request-form"
//           params={{
//             ...params,
//             branch: row,
//           }}
//         /> */}
//     </View>
//   );
// };

const useCreateBranch = ({feature_id}) => {
  const {user} = useAppStateContext();
  const [isLoading, setLoading] = useState(false);

  const postInvoke = useInvoke({
    setLoading,
    eventSourceId: ['RepositoryBranches'],
  });

  const toast = useToast();

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      toast({
        message: 'Branch copied to clipboard.',
      });
      setIsCopied(false);
    }
  }, [isCopied]);

  const ensureBranch = async ({row}) => {
    const {data} = await postInvoke({
      uri: `/ensureFeatureAndCreateUserBranch`,
      props: {
        feature_id,
        assigned_to: user._id,
        repository_id: row.repository_id,
      },
    });

    return data;
  };

  const showMessage = message => {
    try {
      navigator.clipboard.writeText(`${message}`);
      setIsCopied(true);
    } catch (err) {
      throw new Error('Error while copying branch');
    }
  };

  return {isLoading, ensureBranch, showMessage};
};

const BranchRender = props => {
  let {row, styles, ...rest} = props;
  let {rowText} = styles;
  const {label} = row;

  return (
    <Row gap={4} style={{alignItems: 'center'}}>
      <Text style={rowText}>{label}</Text>
      <BranchCheckoutRender {...props} />
    </Row>
  );
};

const BranchCheckoutRender = ({row, feature_id, ...props}) => {
  const {buttonContainer, copyIcon} = useStyles(BranchStyle);

  let {isLoading, ensureBranch, showMessage} = useCreateBranch({
    feature_id,
  });
  let branchName = row.branch_name;
  return (
    <Row gap={8}>
      <TouchableOpacity
        title={'Checkout Branch'}
        style={{
          alignItems: 'center',
        }}
        disabled={isLoading}
        onPress={async () => {
          try {
            if (!branchName) {
              let data = await ensureBranch({row});
              branchName = data?.userBranch?.branch_name;
            }

            if (branchName) {
              showMessage(`git fetch
git checkout ${branchName}`);
            }
          } catch (err) {
            //err
          }
        }}>
        <IconButton styles={buttonContainer} icon={copyIcon} />
      </TouchableOpacity>
      {isLoading ? <ActivityIndicator size={20} /> : void 0}
    </Row>
  );
};

const PullRequest = ({row, params, navigation}) => {
  const {status, prLinks = [], open_pr_count, open_pr_link, type, is_bug} = row;
  const {ArrowSquareOut} = useTheme('icons');

  const openPRTable = filter => {
    navigation.navigate('pull-request-table', {
      ...params,
      filter,
    });
  };

  return (
    <Col style={{alignItems: 'flex-end'}} gap={4}>
      {prLinks?.map(doc => {
        if (!doc) {
          return;
        }
        return (
          <LinkRenderer
            value={doc?.link}
            icon={ArrowSquareOut}
            title={`${doc.source} -> ${doc.target}`}
            linkText={
              doc?.target && (type !== 'User' || is_bug)
                ? `Create PR to ${doc?.target}`
                : 'Create PR'
            }
          />
        );
      })}
      <TouchableOpacity
        onPress={() => {
          if (open_pr_count === 1) {
            open_pr_link && Linking.openURL(open_pr_link, '_blank');
          } else {
            openPRTable({branch_id: row?._id});
          }
        }}>
        {open_pr_count ? (
          <Tag
            displayTextColor
            color={'Information'}
            value={`${open_pr_count} pending ${
              open_pr_count > 1 ? 'PRs' : 'PR'
            }`}
          />
        ) : (
          <PrStatusChip value={status} />
        )}
      </TouchableOpacity>
    </Col>
  );
};

const ReviewPullRequest = ({row, params, navigation}) => {
  const {type, targetPrStatus, targetPrStatusLink, target_pr_status_count} =
    row;

  const openPRTable = filter => {
    navigation.navigate('pull-request-table', {
      ...params,
      filter,
    });
  };

  if (type === 'User' || !targetPrStatus) {
    return null;
  }

  return (
    <Col style={{alignItems: 'flex-end'}} gap={4}>
      <TouchableOpacity
        onPress={() => {
          if (target_pr_status_count === 1) {
            targetPrStatusLink && Linking.openURL(targetPrStatusLink, '_blank');
          } else {
            openPRTable({parent_branch_id: row?._id});
          }
        }}>
        {target_pr_status_count ? (
          <Tag
            displayTextColor
            color={'Information'}
            value={`${target_pr_status_count} pending ${
              target_pr_status_count > 1 ? 'PRs' : 'PR'
            }`}
          />
        ) : (
          <PrStatusChip value={targetPrStatus} />
        )}
      </TouchableOpacity>
    </Col>
  );
};

const BranchTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {project_id, feature_id, feature} = params;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'RepositoryBranches',
  });

  const {data, loading, setData} = useFetchData({
    api: `/features/${feature_id}/repository-branches`,
    params: {
      project_id,
    },
    eventSourceId: ['RepositoryBranches'],
  });

  useRealTimeSync({
    event: 'PullRequestCreated',
    group: 'PullRequest',
    callBack: data => {
      setData(data);
    },
  });

  return (
    <GroupTable
      data={data}
      loading={loading}
      params={{project_id}}
      groupRow={{
        data: 'branches',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.repository} (${row.type})`} />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          let {feature_status, feature_sub_status} = row;

          let status = void 0;
          if (feature_status === FeatureStatus.active.value) {
            if (!feature_sub_status) {
              status = 'Code';
            } else {
              status = feature_sub_status;
            }
          } else if (feature_status !== FeatureStatus.backlog.value) {
            status = FeatureStatus[feature_status].label;
          }
          if (!status) {
            return null;
          }
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <Tag value={status} />
              {row?.feature_all_pr_count ? (
                <BorderLessButton
                  text={'View All PRs'}
                  onPress={() => {
                    navigation.navigate('pull-request-table', {
                      ...params,
                      filter: {
                        repository_id: row._id,
                        feature_id: feature_id,
                      },
                      repository: row,
                      source: 'RepositoryFeaturePr',
                      secondaryTitle: row.repository,
                    });
                  }}
                />
              ) : (
                void 0
              )}
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Branch',
          render: props => {
            return <BranchRender {...props} feature_id={feature_id} />;
          },
        },
        {
          align: 'right',
          header: 'Review Pull Requests',
          render: props => {
            return (
              <ReviewPullRequest
                branch={props?.row}
                navigation={navigation}
                params={params}
                {...props}
              />
            );
          },
        },
        {
          align: 'right',
          header: 'Pull Requests',
          render: props => {
            return (
              <PullRequest
                branch={props?.row}
                navigation={navigation}
                params={params}
                {...props}
              />
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Mark Self Testing',
          onPress: () => {
            invoke({
              uri: `/projectfeatures/${feature_id}`,
              props: {
                sub_status: 'Self-Testing',
              },
            });
          },
          visible: ({row}) =>
            row?.is_self_testing &&
            row.feature_sub_status !== 'Self-Testing' &&
            row.feature_status !== FeatureStatus.released.value,
        },
        {
          title: 'Mark Self Tested',
          onPress: () => {
            invoke({
              uri: `/projectfeatures/${feature_id}`,
              props: {
                sub_status: 'Self-Tested',
              },
            });
          },
          visible: ({row}) =>
            row.is_self_testing &&
            row.feature_status !== FeatureStatus.released.value &&
            row.feature_sub_status === 'Self-Testing',
        },
      ]}
    />
  );
};

export default BranchTable;
