import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const AppRoleForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/approles',
    eventSourceId: 'appRoleAdded',
    ...props,
  });

  return (
    <Form
      api={`/approles/${row?._id}`}
      fields={{
        entity: {
          name: 1,
        },
        entity_id: {
          name: 1,
        },
        role_id: {
          _id: 1,
          role: 1,
        },
      }}
      onSubmit={onSubmit}
      header={{title: 'Add App Role'}}
      layoutFields={[
        {
          label: 'Entity Details',
          fields: [
            {
              placeholder: 'Entity',
              label: 'Entity',
              field: 'entity',
              type: 'cascader',
              cascades: [
                {
                  label: 'Select Source',
                  field: 'entity',
                  api: '/types',
                  suggestionField: 'label',
                  valueField: 'label',
                },
                {
                  label: 'Entity Name',
                  placeholder: 'Entity_id',
                  field: 'entity_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  params: ({values: {entity} = {}}) => {
                    return {
                      source: entity,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
            {
              type: 'text',
              field: 'entity_id.name',
              placeholder: 'Entity Name',
              label: 'Entity Name',
            },
          ],
        },
        {
          label: 'Role',
          field: 'role_id',
          type: 'autoComplete',
          api: '/roles',
          suggestionField: 'role',
          valueField: 'role',
        },
        {
          label: 'Default',
          field: 'is_default',
          type: 'autoComplete',
          options: ['true', 'false'],
        },
      ]}
      {...props}
    />
  );
};

export const EditAppRolesForm = props => {
  return (
    <AppRoleForm header={{title: 'Edit App Role'}} mode="edit" {...props} />
  );
};
