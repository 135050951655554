import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateEstHourForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projecttasks',
    eventSourceId: 'Task',
  });
  const {
    route: {params},
  } = props;

  const {row = {}} = params;
  return (
    <Form
      onSubmit={onSubmit}
      header={{
        title: 'Update Est Hour',
        secondaryTitle: row.name,
      }}
      submitAction="Save"
      defaultValues={{_id: row._id, est_hours: row.est_hours}}
      layoutFields={[
        {
          placeholder: 'Estimate Hours',
          field: 'est_hours',
          type: 'duration',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
