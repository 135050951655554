import React from 'react';
import {Text, Row, Col, Image} from '@unthinkable/react-core-components';
import {useComponentTheme, useTheme} from '@unthinkable/react-theme';

const AddCountCellRender = ({
  totalCountField,
  completedCountField,
  row,
  skipAdd,
}) => {
  const {colors} = useTheme();
  let completedCountValue = row[completedCountField];
  let {
    columnTextStyle,
    addPlus,
    iconStyle,
    iconContainerStyle,
    rowStyle,
    containerStyle,
  } = useComponentTheme('CountCellRenderStyles');
  let totalCountValue = row[totalCountField];

  if (completedCountValue === totalCountValue) {
    containerStyle = {
      ...containerStyle,
      backgroundColor: colors['SUCCESS_LOW'],
    };
    columnTextStyle = {
      ...columnTextStyle,
      color: colors['SUCCESS_HIGH'],
    };
  }

  return (
    <Row style={rowStyle}>
      {totalCountValue ? (
        <Row style={containerStyle}>
          <Text style={columnTextStyle}>{completedCountValue}</Text>
          <Text style={columnTextStyle}>/</Text>
          <Text style={columnTextStyle}>{totalCountValue}</Text>
        </Row>
      ) : skipAdd ? (
        void 0
      ) : (
        <Col gap={2} style={iconContainerStyle}>
          <Image source={addPlus} style={iconStyle} />
        </Col>
      )}
    </Row>
  );
};

export default AddCountCellRender;
