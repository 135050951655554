import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {EmployeeSelfLevelTab} from './EmployeeSalaryTab';
import {
  BonusSalaryTable,
  CompensationStructureSalaryTable,
  FixedSalaryTable,
  ICIncentiveTable,
  IncentiveSalaryTable,
  InvoiceSalaryTable,
  KPIIncentiveTable,
  ShiftAllowanceTable,
} from './EmployeeSalaryTable';
import {TaxDeclarationTabs} from './EmployeeTaxDeclarationTabs';
import {EmployeeSelfForm16Table} from '../../employee/views/EmployeeForm16';
import {useAppStateContext} from '../../../providers/AppState';

export const SalaryMenu = props => {
  const {route: {params} = {}} = props;
  const {user} = useAppStateContext();
  const menus = [
    {
      label: 'Fixed',
      view: <FixedSalaryTable props={props} />,
    },
    {
      label: 'Incentives',
      view: <IncentiveSalaryTable />,
    },
    {
      label: 'Bonus',
      view: <BonusSalaryTable />,
    },
    // {
    //   label: 'IC Incentive',
    //   view: <ICIncentiveTable />,
    // },
    {
      label: 'KPI Incentive',
      view: <KPIIncentiveTable {...props} />,
    },
    {
      label: 'Shift Allowance',
      view: <ShiftAllowanceTable />,
    },
    {
      label: 'Compensation Structure',
      view: <CompensationStructureSalaryTable props={props} />,
    },
    {
      label: 'Employee Form 16',
      view: <EmployeeSelfForm16Table />,
    },
    {
      label: 'Tax Declaration',
      view: <TaxDeclarationTabs />,
    },
    {
      label: 'Invoice',
      view: <InvoiceSalaryTable />,
    },
    {
      label: 'Self L1 L2 L3',
      view: <EmployeeSelfLevelTab />,
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
