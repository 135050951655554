import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddSkillToEmployeeForm = props => {
  const {
    route: {params},
  } = props;

  let {employee} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/employeeSkills',
    eventSourceId: 'EmployeeSkills',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header="Assign Skill On Resource"
      defaultValues={{
        employee_id: employee?._id,
      }}
      layoutFields={[
        {
          placeholder: 'Skill',
          field: 'skill_metrics',
          type: 'autoComplete',
          api: `/skillMetrics`,
          label: 'Skill',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          fields: {
            name: 1,
            tools: 1,
          },
          filter: {
            parent: {
              $eq: null,
            },
          },
        },
        {
          placeholder: 'Child Skills',
          field: 'child_skill_metrics',
          type: 'multiAutoComplete',
          api: `/skillMetrics`,
          label: 'Child Skills',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          fields: {
            name: 1,
            tools: 1,
          },
          filter: ({values}) => ({
            parent: values.skill_metrics,
          }),
        },
        {
          placeholder: 'Tools',
          field: 'tools',
          type: 'multiAutoComplete',
          api: `/tools`,
          label: 'Tools & Languages',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          filter: ({values}) => {
            return {
              _id: {
                $in: values?.skill_metrics?.tools || [],
              },
            };
          },
        },
      ]}
      {...props}
    />
  );
};
