import React from 'react';
import {Table} from '../../../components/table/Table';
import {Industry} from '../controllers/Industry';

export const InActiveIndustryTable = props => {
  const {navigation} = props;
  const {markIndustryActive} = Industry(props);

  return (
    <Table
      eventSourceId="Industry"
      api={`/industry/inActive`}
      onRowPress={props => {
        navigation.navigate(`industry-detail`, {
          ...props,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'code',
          header: 'Code',
        },
        {
          type: 'date',
          field: 'creation_date',
          header: 'Creation Date',
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          onPress: markIndustryActive,
        },
      ]}
    />
  );
};
