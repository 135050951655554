import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';

const RenderLinkedInUrl = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {name, linkedin_url, first_name, last_name} = row || {};

  const underline = linkedin_url ? {textDecoration: 'underline'} : void 0;
  if (linkedin_url) {
    linkedin_url =
      linkedin_url &&
      (linkedin_url.startsWith('https') || linkedin_url.startsWith('http'))
        ? linkedin_url
        : `https://${linkedin_url}`;
  }

  const displayName = name
    ? name
    : `${first_name || ''} ${last_name || ''}`.trim();
  return (
    <TouchableOpacity
      onPress={() => {
        linkedin_url && Linking.openURL(linkedin_url, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {website = ''} = row || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {website}
      </Text>
    </TouchableOpacity>
  );
};

export const UserSegmentContactList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  let {user_segment_id} = params || {};
  return (
    <Table
      {...props}
      api={`/usersegmentcontacts`}
      filter={{user_segment_id, status: 'active'}}
      eventSourceId={'segmentAdded'}
      fields={{
        name: 1,
        first_name: 1,
        last_name: 1,
        email: 1,
        website: 1,
        linkedin_url: 1,
        createdAt: 1,
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          type: 'text',
          render: RenderLinkedInUrl,
          header: 'Contact Name',
        },
        {
          type: 'text',
          render: RenderName,
          header: 'Website',
        },
        {
          type: 'text',
          header: 'Email',
          field: 'email',
        },
        {
          type: 'date',
          header: 'Date',
          field: 'createdAt',
        },
      ]}
    />
  );
};
