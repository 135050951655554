import React from 'react';

import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

import {TextRenderer} from '../../../components/form/Renderers';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {Text} from '@unthinkable/react-core-components';

const RenderField = props => {
  const {row, styles = {}, field} = props;
  const {rowText} = styles;

  let value = row[field] ? row[field] : 0;

  return (
    <Text
      style={{
        ...rowText,
      }}>
      {value}
    </Text>
  );
};

const RenderBody = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;

  const {subject, body} = row || {};

  const renderContent = subject ? subject : body;

  return (
    <Text
      style={{
        ...rowText,
      }}
      numberOfLines={3}>
      {renderContent}
    </Text>
  );
};

export const CampaignMessageTableNew = props => {
  const {deleteCampaignMessage, markArchive, editNewCampaignMessage} =
    messageControllers(props);
  const {
    status = 'active',
    filterParams = {},
    route: {params},
    navigation,
  } = props;
  const {campaign_id} = params || {};

  const invoke = useInvoke({method: 'put', eventSourceId: ['indexUpdated']});

  let postInvoke = useInvoke({method: 'post'});
  const updateTemplateVersion = async ({row}) => {
    let {data} = await postInvoke({
      uri: `/campaignmessages/data`,
      props: {
        filter: {
          _id: row?._id,
        },
        fields: {
          action: 1,
          send_after: 1,
          send_after_span: 1,
          status: 1,
          enable_thread: 1,
          enable_link_tracking: 1,
          body: 1,
          subject: 1,
          personalize_with_ai: 1,
          personalize_subject_with_ai: 1,
          usp_id: {name: 1},
          template: 1,
          campaign_id: 1,
          sequence: 1,
          sequence_display: 1,
        },
        only: true,
      },
    });
    let {_id: parent_id, ...rest} = data || {};

    let defaultValues = {};
    if (rest && Object.keys(rest).length) {
      defaultValues = {
        ...rest,
        parent_id,
      };
    }
    navigation.navigate('add-campaign-message-form', {
      ...params,
      defaultValues,
    });
  };
  return (
    <Table
      {...props}
      fields={{
        _id: 1,
        name: 1,
        body: 1,
        sequence: 1,
        send_after: 1,
        action: 1,
        personalize_with_ai: 1,
      }}
      api="/campaign-messages"
      params={{
        campaign_id: campaign_id._id,
        status,
        ...filterParams,
      }}
      eventSourceId={[
        'indexUpdated',
        'campaignMessageAdded',
        'campaignMessageDeleted',
        'markArchive',
        'versionUpdated',
      ]}
      // draggable={{
      //   indexField: 'sequence',
      //   updateIndexOnDragEnd: props => {
      //     const {updatedIndex, row} = props;
      //     invoke({
      //       uri: `/campaignMessages/${row?._id}`,
      //       props: {
      //         sequence: updatedIndex,
      //       },
      //     });
      //   },
      // }}
      sort={{
        sequence: 1,
      }}
      columns={[
        {
          header: 'SN',
          width: 50,
          render: props => <TextRenderer {...props} value={props.index + 1} />,
        },

        // {
        //   field: 'name',
        //   type: 'text',
        //   header: 'Name',
        //   width: 200,
        // },
        {
          field: 'action',
          type: 'text',
          header: 'Action',
          align: 'right',
          width: 100,
        },
        // {
        //   field: 'body',
        //   type: 'textArea',
        //   header: 'Body',
        // },
        {
          // field: 'subject',
          // type: 'textArea',
          header: ({data}) => {
            if (data && data[0]?.subject) {
              return 'Subject';
            } else {
              return 'Body';
            }
          },
          render: RenderBody,
          onPress: ({row}) => {
            editNewCampaignMessage({row, dualMode: true});
          },
        },
        {
          field: 'send_after',
          type: 'number',
          align: 'right',
          header: 'Delay',
          width: 70,
        },
        {
          field: 'personalize_with_ai',
          type: 'text',
          align: 'right',
          header: 'Personalize',
          width: 110,
        },
        {
          header: 'Version',
          align: 'right',
          width: 100,
          render: ({row, styles}) => {
            const {version} = row || {};
            const {rowText} = styles;

            const versionText = version ? version : 1;
            return <Text style={rowText}>v{versionText}</Text>;
          },
          onPress: ({row}) => {
            const {parent_id} = row || {};
            if (parent_id) {
              editNewCampaignMessage({row: {_id: parent_id}, dualMode: true});
            }
          },
        },
        // {
        //   field: 'pipeline_action_count',
        //   header: 'Pipeline',
        //   type: 'text',
        //   align: 'right',
        //   responsive: 'sm',
        //   width: 100,
        //   onPress: ({row}) => {
        //     navigation.navigate('show-actions', {
        //       addOnFilter: {
        //         createdAt: {
        //           $gte: filterParams?.period?.from,
        //           $lte: filterParams?.period?.to,
        //         },
        //         campaign_message: row?._id,
        //         status: 'pending',
        //         actionType: 'inmail-actions',
        //       },
        //     });
        //   },
        // },

        {
          // field: 'unique_prospect_count',
          // type: 'text',
          header: 'Prospect Touched',
          align: 'right',
          width: 140,
          render: props =>
            RenderField({...props, field: 'unique_prospect_count'}),
        },
        {
          // field: 'sent_actions_count',
          header: 'Sent',
          // type: 'text',
          align: 'right',
          // responsive: 'sm',
          render: props => RenderField({...props, field: 'sent_actions_count'}),
          width: 70,
          onPress: ({row}) => {
            navigation.navigate('show-analytics-actions', {
              addOnFilter: {
                completedOn: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                status: 'completed',
                campaign_message: row?._id,
              },
            });
          },
        },
        {
          // field: 'email_unsubscribe_count',
          header: 'Unsubscribe',
          // type: 'text',
          align: 'right',
          // responsive: 'sm',
          render: props =>
            RenderField({...props, field: 'email_unsubscribe_count'}),
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-analytics-actions', {
              addOnFilter: {
                completedOn: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                status: 'completed',
                campaign_message: row?._id,
                email_unsubscribe_on: {$exists: true},
              },
            });
          },
        },
        {
          // field: 'positive_response_count',
          header: 'Response',
          // type: 'text',
          align: 'right',
          // responsive: 'sm',
          render: props =>
            RenderField({
              ...props,
              field: 'positive_response_count',
            }),
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-analytics-actions', {
              addOnFilter: {
                completedOn: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                status: 'completed',
                campaign_message: row?._id,
                positive_response_on: {$exists: true},
              },
            });
          },
        },
        // {
        //   field: 'replied_count',
        //   header: 'Replied',
        //   type: 'text',
        //   align: 'right',
        //   responsive: 'sm',
        //   width: 100,
        //   onPress: ({row}) => {
        //     navigation.navigate('show-communications', {
        //       addOnFilter: {
        //         date: {
        //           $gte: filterParams?.period?.from,
        //           $lte: filterParams?.period?.to,
        //         },
        //         initiator: 'Client',
        //         campaign_message_id: row?._id,
        //       },
        //       title: row?.name,
        //     });
        //   },
        // },
      ]}
      // onRowPress={({row}) => {
      //   editNewCampaignMessage({row, dualMode: true});
      // }}
      moreActions={[
        // {
        //   title: 'Edit',
        //   onPress: editNewCampaignMessage,
        // },
        {
          title: 'Modify',
          onPress: updateTemplateVersion,
          visible: status === 'active',
        },
        {
          title: 'Archive',
          confirm: {
            title: 'Archive',
            message: 'Are you sure you want to Archive?',
            confirmText: 'Archive',
          },
          onPress: markArchive,
          visible: status === 'active',
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: deleteCampaignMessage,
          visible: status === 'active',
        },
      ]}
    />
  );
};
