import { getCurrentMonth } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import {
    PeriodRangeFilter
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';

export const CustomerChurnReportTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  filterParams = {...params, ...filterParams};
  return (
    <Table
      params={{...filterParams}}
      variant={'bordered'}
      addOnFilter={{...filter}}
      limit={5000}
      api={'/customer/churn-report'}
      columns={[
        {
          header: 'Initial Count',
          field: 'initial_customers',
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('churn-customer-drill-list', {
              // filter: {
              //   _id: {$in: row.initial_customers_ids},
              // },
              filter: {
                legal: {$ne: true},
                is_duplicate: {$in: [null, false]},
                product: {
                  $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
                },
                first_order_date: {
                  $lt: row?.startDate,
                },
                $or: [
                  {
                    dormant_date: {
                      $gte: row?.startDate,
                    },
                  },
                  {
                    dormant_date: null,
                  },
                ],
              },
            });
          },
        },
        {
          header: 'New Aqusition',
          field: 'new_customers',
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('churn-customer-drill-list', {
              // filter: {
              //   _id: {$in: row.new_customer_ids},
              // },
              filter: {
                legal: {$ne: true},
                is_duplicate: {$in: [null, false]},
                first_order_date: {
                  $gte: row?.startDate,
                  $lte: row?.endDate,
                },
                product: {
                  $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
                },
              },
            });
          },
        },
        {
          header: 'Closed',
          field: 'lost_customers',
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('churn-customer-drill-list', {
              // filter: {
              //   _id: {$in: row.lost_customer_ids},
              // },
              filter: {
                legal: {$ne: true},
                is_duplicate: {$in: [null, false]},
                product: {
                  $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
                },
                first_order_date: {
                  $lt: row?.startDate,
                },
                dormant_date: {
                  $gte: row?.startDate,
                  $lte: row?.endDate,
                },
              },
            });
          },
        },
        {
          header: 'Final Count',
          field: 'final_count',
          type: 'number',
        },
      ]}
    />
  );
};

export const CustomerChurnReportTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const {download} = useAppStateContext();
  const tabs = {
    am_report: {
      label: 'Customer Churn Report',
      view: (
        <CustomerChurnReportTable
          filterParams={filterParams}
          filter={{...filter, period}}
        />
      ),
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        // <GroupFilter
        //   filterValues={filterValues}
        //   applyFilter={applyFilter}
        //   filters={[
        //     {
        //       type: 'date',
        //       field: 'created_on',
        //       asParam: true,
        //       label: 'Customer Since',
        //     },
        //     {
        //       type: 'autoComplete',
        //       label: 'Channel',
        //       api: '/types/suggestionsForLead',
        //       placeholder: 'Select',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       field: 'campaign_type',
        //     },
        //     {
        //       label: 'Source',
        //       placeholder: 'Select',
        //       field: 'campaign_id',
        //       type: 'autoComplete',
        //       api: '/sourceSuggestions',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       params: ({filter: {campaign_type} = {}}) => {
        //         return {
        //           source: campaign_type,
        //         };
        //       },
        //     },
        //     {
        //       type: 'autoComplete',
        //       field: 'owner',
        //       api: '/employeeSuggestions',
        //       suggestionField: 'name',
        //       secondarySuggestionField: 'official_email_id',
        //       valueField: 'name',
        //       label: 'AM',
        //     },
        //     {
        //       type: 'autoComplete',
        //       field: 'csm',
        //       api: '/employeeSuggestions',
        //       suggestionField: 'name',
        //       secondarySuggestionField: 'official_email_id',
        //       valueField: 'name',
        //       label: 'CSM',
        //     },
        //   ]}
        // />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
