import React from 'react';
import {Table} from '../../../components/table/Table';
import {Text, View} from '@unthinkable/react-core-components';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import moment from 'moment';
import {AmountRender} from './components/AmountRender';
import {randomColor} from '../../../utils/util';
import {Tag} from '../../../components/chip/Chip';
import {useAppStateContext} from '../../../providers/AppState';

const CUSTOMER = {
  header: 'Customer',
  minWidth: 220,
  render: ({row, styles}) => {
    return <Tag value={row?.customer_id?.name} color={randomColor()} />;
  },
};
const AM = {
  type: 'userAvatar',
  field: 'account_owner_id',
  header: 'AM',
  width: 80,
  align: 'center',
};

const CSM = {
  header: 'CSM',
  type: 'userAvatar',
  width: 80,
  field: 'delivery_owner_id',
};

const AMOUNT = {
  header: 'Value (₹)',
  type: 'currency',
  aggregate: true,
  align: 'right',
  field: 'base_amount',
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.amount || 0}
        base_amount={row?.base_amount || 0}
        currency={row.currency_id?.currency}
      />
    );
  },
};

const ORDER = {
  header: 'Order',
  minWidth: 150,
  render: ({row, styles: {rowText = {}}}) => {
    return (
      <View style={{flexDirection: 'column'}}>
        <Tag value={row?.order_number} color={randomColor()} />
        <Text style={{...rowText, fontSize: 12, color: '#737373'}}>
          {moment(row.order_date).format('DD MMM YY')}-
          {moment(row.order_end_date).format('DD MMM YY')}
        </Text>
      </View>
    );
  },
};

const ORDERCREATEDON = {
  header: 'Created On',
  field: 'createdAt',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const ORDERENGG = {
  header: 'Order Engg.',
  field: 'engg_count',
  aggregate: true,
  type: 'number',
  width: 100,
};

const project = ({navigation, tmOrder = false}) => {
  return {
    header: 'Project',
    align: 'right',
    render: CountCellRender,
    count_field: 'project_count',
    onPlusPress: ({row}) => {
      navigation.navigate(tmOrder ? `tm-order-map` : `fixed-order-map`, {
        row: {
          ...row,
          customer: row.customer_id,
          projectTab: 'Billed',
          orderDefaultValue: {
            order: {
              _id: row?._id,
              order_number: row?.order_number,
            },
            start_date: row?.order_date,
            end_date: row?.order_end_date,
            hours: row?.est_order_hrs,
          },
        },
        fromOrder: true,
      });
    },
  };
};

export const NewTMOrderTable = props => {
  let {
    route: {params},
    addOnFilter,
    navigation,
    searchValue,
    addOnParams,
  } = props;
  const {download} = useAppStateContext();
  const fetchProps = {
    params: {...params, ...addOnParams},
    search: searchValue,
    addOnFilter: {
      ...addOnFilter,
    },
    searchFields: ['order_number'],
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/orderDataDownload',
                    },
                    column: {
                      'Order date': 'order_date',
                      'Order Number': 'order_number',
                      Customer: 'customer_id.name',
                      Amount: 'amount',
                      'Base amount': 'base_amount',
                      Relation: 'account_owner_id.name',
                      Product: 'product.name',
                      Organization: 'organization.name',
                      'Billing type': 'billing_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId={['t&m', 'performance-report']}
      api={'/tmNewOrder'}
      params={{...params, ...addOnParams}}
      search={searchValue}
      searchFields="order_number"
      onRowPress={({row}) => {
        navigation.navigate('detail-tm-order', {row});
      }}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        ORDERCREATEDON,
        CUSTOMER,
        AM,
        ORDER,
        AMOUNT,
        {
          header: 'Potential(MM)',
          align: 'right',
          width: 120,
          field: 'man_month',
          aggregate: true,
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {row?.man_month && `${(row?.man_month).toFixed(2)} MM`}
              </Text>
            );
          },
        },
        ORDERENGG,
        project({navigation, tmOrder: true}),
      ]}
    />
  );
};

export const NewFixedOrderTable = props => {
  let {
    route: {params},
    navigation,
    searchValue,
    addOnFilter,
    addOnParams,
    tmOrder = false,
  } = props;
  const {download} = useAppStateContext();
  const fetchProps = {
    params: {...params, ...addOnParams},
    search: searchValue,
    addOnFilter: {
      ...addOnFilter,
    },
    searchFields: ['order_number'],
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/orderDataDownload',
                    },
                    column: {
                      'Order date': 'order_date',
                      'Order Number': 'order_number',
                      Customer: 'customer_id.name',
                      Amount: 'amount',
                      'Base amount': 'base_amount',
                      Relation: 'account_owner_id.name',
                      Product: 'product.name',
                      Organization: 'organization.name',
                      'Billing type': 'billing_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={'/fixedNewOrder'}
      params={{...params, ...addOnParams}}
      addOnFilter={{
        ...addOnFilter,
      }}
      eventSourceId="fixed"
      search={searchValue}
      searchFields="order_number"
      onRowPress={({row}) => {
        navigation.navigate('detail-fixed-order', {row});
      }}
      columns={[
        ORDERCREATEDON,
        CUSTOMER,
        AM,
        ORDER,
        AMOUNT,
        {
          header: 'Potential(MM)',
          field: 'est_order_hrs',
          align: 'right',
          aggregate: true,
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {row?.totalHours} MM
              </Text>
            );
          },
        },
        project({navigation, tmOrder}),
      ]}
    />
  );
};
