import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {PeriodRangeFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {AddButton} from '../../../components/button/AddButton';
import {PaidAdCampaignTable} from './PaidAdsCampaignTable';
import { STATUS } from './TargetAudienceTable';

export const PaidCampaignTabView = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {
    onChangeFilter,
    searchValue,
    filterValues = {},
  } = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });

  const {params: filterParams = {}} = filterValues;
  const actions = [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search"
    />,
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
    />,
    <AddButton title="Create Ad" view={'add-paidadcampaign'} params={params} />,
  ];

  const tabs = {
    in_experiment: {
      label: 'In Experiment',
      view: (
        <PaidAdCampaignTable
          searchValue={searchValue}
          filterParams={filterParams}
          tabFilter={{status: STATUS.IN_EXPERIMENT}}
        />
      ),
    },
    confirmed: {
      label: 'Confirmed',
      view: (
        <PaidAdCampaignTable
          searchValue={searchValue}
          filterParams={filterParams}
          tabFilter={{status: STATUS.CONFIRMED }}
        />
      ),
    },
    retired: {
      label: 'Retired',
      view: (
        <PaidAdCampaignTable
          searchValue={searchValue}
          filterParams={filterParams}
          tabFilter={{status: STATUS.RETIRED}}
        />
      ),
    },
  };

  return <TabView tabs={tabs} params={params} actions={actions} {...props} />;
};
