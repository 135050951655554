import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const DeliveryOwnerForm = props => {
  const {route: {params} = {}, headerTitle} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassociations',
    eventSourceId: 'employeeassociation',
  });
  return (
    <Form
      header={{
        title: headerTitle,
        secondaryTitle: `${row?.name}`,
      }}
      defaultValues={{
        employee: row?._id,
        // type: 'Delivery Owner',
      }}
      api={`/employeeassociations/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          placeholder: 'Delivery owner',
          label: 'Delivery owner',
          field: 'delivery_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
        {
          label: 'Start Date',
          type: 'date',
          field: 'from_date',
          required: true,
          size: 6,
        },
        {
          label: 'End Date',
          type: 'date',
          field: 'to_date',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddDeliveryOwnerForm = props => {
  return <DeliveryOwnerForm headerTitle="Delivery owner" {...props} />;
};

export const EditDeliveryOwnerForm = props => {
  return (
    <DeliveryOwnerForm
      mode="edit"
      headerTitle={'Update Delivery owner'}
      {...props}
    />
  );
};
