import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const MarkUnusableForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetUnusable',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      fields={{
        reason: 1,
      }}
      header={{
        title: 'Mark unusable',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Unusable',
            unusable_date: getZeroTimeDate(new Date()),
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Reason',
          field: 'reason',
          type: 'autoComplete',
          options: ['To be repaired', 'Broken & unrepairable', 'To be donated'],
          required: true,
        },
      ]}
    />
  );
};
