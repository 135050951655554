import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {filterProjectEnvironments} from '../utility';

export const ChangeFeatureStatusForm = props => {
  const {
    route: {params},
  } = props;

  const {row, project, secondaryTitle} = params;
  const {project_environments} = project;

  const {onSubmit} = useFormSubmit({
    uri: '/projectfeatures',
    eventSourceId: ['Feature'],
    ...props,
  });

  const filteredEnvironments = filterProjectEnvironments({
    project_environments,
  });

  const options = [
    {label: 'Backlog', value: 'backlog'},
    {label: 'Development', value: 'active'},
    {label: 'QA', value: 'qa'},
    ...filteredEnvironments.map(env => ({
      label: env.label,
      value: env.environment,
    })),
  ];

  if (row?.type === 'feature') {
    options.splice(1, 0, {
      label: 'Architecture',
      value: 'architecture',
    });
  }

  return (
    <Form
      defaultValues={{_id: row?._id}}
      header={{
        title: `Update Status`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        let {status} = data;

        const sub_status = status === 'architecture' ? 'Architecture' : void 0;
        status = status === 'architecture' ? 'backlog' : status;

        return {
          data: {
            ...data,
            status,
            sub_status,
          },
        };
      }}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Status',
          field: 'status',
          type: 'autoComplete',
          options,
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
