import { Text } from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchFormData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const projectionComputations = fetch => ({
  'set customer based fields': {
    compute: async value => {
      if (value?.customer?._id) {
        const {data: customerData} = await fetch({
          uri: `/customers/${value?.customer?._id}`,
          props: {
            fields: {
              _id: 1,
              csm: {_id: 1, name: 1},
              owner: {_id: 1, name: 1},
            },
          },
        });
        if (customerData?.csm && customerData?.csm?._id) {
          const csm = customerData?.csm;
          console.log(customerData);

          return {csm: customerData?.csm, pm: customerData?.owner};
        }
      }
    },
    multi: true,
    dependencies: ['customer'],
  },
});

export const OpportunityProjectionFormForTnM = props => {
  const {
    route: {params},
  } = props;
  const {fromOpportunity, defaultValues = {}} = props;
  const {row, fromOppProjection} = params;
  const {post, fetch, user} = useAppStateContext();
  const {employee} = user;
  let readOnly = false;
  let defaultData = {
    type: 'Customer',
    owner: employee,
  };

  if (fromOpportunity) {
    readOnly = false;
    defaultData = {
      ...defaultValues,
    };
  }

  if (fromOppProjection) {
    readOnly = true;
    defaultData = {
      ...row,
    };
  }

  let {onSubmit} = useFormSubmit({
    uri: '/opportunityprojections',
    eventSourceId: 'OpportunityProjection',
    ...props,
  });

  return (
    <Form
      defaultValues={defaultData}
      onSubmit={onSubmit}
      header={'Opportunity Projection'}
      submitAction={'Save'}
      readOnly={readOnly}
      computations={{...projectionComputations(fetch)}}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'type',
          options: [
            {
              label: 'Customer',
              value: 'Customer',
            },
            {
              label: 'Potential Customer',
              value: 'PotentialCustomer',
            },
          ],
          onChangeValue: (value, _, {setFieldValue}) => {
            if (value === 'Customer') {
              setFieldValue('potential_customer', void 0);
            } else if (value === 'PotentialCustomer') {
              setFieldValue('customer', void 0);
            }
          },
        },
        {
          fields: [
            {
              placeholder: 'Customer',
              label: 'Customer',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // filter: {
              //   csm: {$exists: true},
              // },
              visible: ({values}) =>
                values && values.type && values.type === 'Customer',
              required: ({values}) =>
                values && values.type && values.type === 'Customer',
            },
            {
              placeholder: 'Potential Customer',
              label: 'Potential Customer',
              field: 'potential_customer',
              type: 'autoComplete',
              api: `/potentialcustomers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              visible: ({values}) =>
                values && values.type && values.type === 'PotentialCustomer',
              required: ({values}) =>
                values && values.type && values.type === 'PotentialCustomer',
            },
            {
              placeholder: 'Opportunity',
              label: 'Opportunity',
              field: 'opportunity',
              type: 'autoComplete',
              api: `/opportunities`,
              suggestionField: 'requirement',
              valueField: 'requirement',
              size: 6,
              required: true,
              filter: ({values}) => {
                let filter = {};
                if (values?.customer?._id) {
                  filter = {
                    customer_id: values?.customer?._id,
                  };
                } else if (values?.potential_customer?._id) {
                  filter = {
                    potential_customer: values?.potential_customer?._id,
                  };
                } else {
                  filter = {_id: null};
                }
                filter = {
                  ...filter,
                  opportunity_type: {
                    $in: [
                      'StaffAugmentationOpportunity',
                      'CustomPlatformOpportunity',
                    ],
                  },
                  status: {$in: ['Active', 'New']},
                  // rfq_owner: {$exists: true, $ne: []},
                };
                return filter;
              },
              onChangeValue: async (value, e, {setFieldValue}) => {
                if (value?._id) {
                  const {data: opportunityData} = await fetch({
                    uri: `/opportunities/${value?._id}`,
                    props: {
                      fields: {
                        _id: 1,
                        opportunity_type: 1,
                        no_of_resources: 1,
                      },
                    },
                  });
                  if (
                    opportunityData?.opportunity_type ==
                    'CustomPlatformOpportunity'
                  ) {
                    setFieldValue('projection_type', 'fixed');
                    setFieldValue('opportunity_number', void 0);
                  } else if (
                    opportunityData?.opportunity_type ==
                    'StaffAugmentationOpportunity'
                  ) {
                    setFieldValue('projection_type', 'tm');
                    if (opportunityData?.no_of_resources) {
                      setFieldValue(
                        'opportunity_number',
                        opportunityData?.no_of_resources,
                      );
                    }
                  }
                }
              },
            },
          ],
        },

        {
          type: 'number',
          field: 'opportunity_number',
          label: 'Opportunity Number',
          placeholder: 'Opportunity Number',
          size: 6,
          required: true,
          visible: ({values}) =>
            values && values.projection_type && values.projection_type == 'tm',
          required: ({values}) =>
            values && values.projection_type && values.projection_type == 'tm',
        },
        {
          type: 'textArea',
          field: 'probability_of_close',
          label: 'Percent of Close',
          placeholder: 'Probability of Close',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          field: 'expected_close_date',
          label: 'Expected Close Date',
          placeholder: 'Expected Close Date',
          size: 6,
          required: true,
        },
        {
          type: 'number',
          field: 'quoted_hours',
          label: 'Quoted Hrs',
          placeholder: 'Quoted Hrs',
          size: 6,
          visible: ({values}) =>
            values &&
            values.projection_type &&
            values.projection_type == 'fixed',
          // required: ({values}) =>
          //   values &&
          //   values.projection_type &&
          //   values.projection_type == 'fixed',

          // onChangeValue: async (value, e, {setFieldValue}) => {
          //   if (value) {
          //     const oppNo = value / 180;
          //     setFieldValue('opportunity_number', oppNo.toFixed(2));
          //   }
          // },
        },
        {
          label: 'CSM',
          placeholder: 'CSM',
          field: 'csm',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          readOnly: true,
          visible: ({values}) =>
            values && values.type && values.type === 'Customer',
        },
        {
          label: 'PM',
          placeholder: 'PM',
          field: 'pm',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          readOnly: true,
          visible: ({values}) =>
            values && values.type && values.type === 'Customer',
        },
      ]}
    />
  );
};

export const ProjectedBillingMM = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunityprojections',
    eventSourceId: 'OpportunityProjection',
    ...props,
  });

  return (
    <Form
      defaultValues={{
        _id: row?._id,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Project Billing (in MM)',
          field: 'man_months',
          type: 'number',
        },
      ]}
      {...props}
    />
  );
};

export const ProjectedBillingForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ProjectedBillingMM
      header={{
        title: 'Projected billing',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          if (row?.customer) {
            return (
              <Text
                style={{
                  ...CAPTION_LARGE,
                  color: colors.NEUTRAL_MEDIUM,
                  paddingLeft: 5,
                }}>
                {row?.customer?.name}
              </Text>
            );
          }
          if (row?.potential_customer) {
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.potential_customer?.name}
            </Text>;
          }
        },
      }}
      {...props}
    />
  );
};

export const AddProjectionFromOpportunity = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  let {data} = useFetchFormData({
    api: `/opportunities/${row?._id}`,
    filter: {
      _id: row?._id,
    },
    fields: {
      name: 1,
      rfr_type: 1,
      requirement: 1,
      owner: {_id: 1, name: 1},
      opportunity_type: 1,
      no_of_resources: 1,
      customer_id: {
        _id: 1,
        name: 1,
        owner: {_id: 1, name: 1},
        csm: {_id: 1, name: 1},
      },
      potential_customer: {_id: 1, name: 1},
    },
  });
  if (!data) {
    return null;
  }
  const {_id, ...rest} = data;
  data = {...rest};
  if (data) {
    if (data.rfr_type == 'PotentailCustomer') {
      data.rfr_type = 'PotentialCustomer';
      data.potential_customer = data.potential_customer;
    }
    if (data.rfr_type == 'Customer') {
      data.customer = data?.customer_id;
      data.pm = data?.customer_id?.owner;
      data.csm = data?.customer_id?.csm;
    }
    if (data?.opportunity_type == 'CustomPlatformOpportunity') {
      data.projection_type = 'fixed';
      data.opportunity_number = void 0;
    }
    if (data?.opportunity_type == 'StaffAugmentationOpportunity') {
      data.projection_type = 'tm';
      data.opportunity_number = data?.no_of_resources;
    }
  }
  const defaultData = {
    ...data,
    type: data?.rfr_type,
    opportunity: row,
  };

  return (
    <OpportunityProjectionFormForTnM
      defaultValues={defaultData}
      fromOpportunity={true}
      {...props}
    />
  );
};
