import {Form, InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const folderTypeOptions = [
  {value: 'screen', label: 'Screen'},
  {value: 'model', label: 'Model'},
  {value: 'api', label: 'API'},
  {value: 'theme', label: 'Theme'},
  {
    value: 'styled-components',
    label: 'Styled Components',
  },
];

export const frontendFolderTypeOptions = [
  {value: 'screen', label: 'Screen'},
  {value: 'theme', label: 'Theme'},
  {
    value: 'styled-components',
    label: 'Styled Components',
  },
];
export const backendFolderTypeOptions = [
  {value: 'model', label: 'Model'},
  {value: 'api', label: 'API'},
];

const folderPathFields = ({params} = {}) => {
  const {repository} = params || {};
  return {
    repository: {
      field: 'repository_id',
      type: 'autoComplete',
      api: '/projectrepositories',
      filter: {project_id: params?.project?._id, type: params?.repositoryType},
      suggestionField: 'repository',
      secondarySuggestionField: 'type',
      label: 'Repository',
      required: true,
    },
    type: {
      field: 'type',
      type: 'autoComplete',
      label: 'Folder Type',
      options: ({values}) => {
        let repositoryType = values?.repository_id?.type || repository?.type;
        return repositoryType === 'Frontend'
          ? frontendFolderTypeOptions
          : backendFolderTypeOptions;
      },
      keyField: 'value',
      valueField: 'label',
      suggestionField: 'label',
      required: true,
      visible: ({values}) => {
        let repositoryType = values?.repository_id?.type || repository?.type;
        return repositoryType === 'Frontend' || repositoryType === 'Backend';
      },
    },
    path: {
      field: 'path',
      type: 'text',
      label: 'Folder Path',
      required: true,
      placeholder: 'Folder path e.g. /modules/{module_name}/models',
    },
  };
};

export const AddRepositoryFolderPathForm = props => {
  const {route: {params} = {}} = props;
  const {afterSubmit, defaultValues} = params;
  const {onSubmit} = useFormSubmit({
    eventSourceId: ['RepositoryFolderPath'],
    uri: '/repositoryFolderPaths',
  });

  const fields = folderPathFields({params});

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      afterSubmit={afterSubmit}
      header="Add Folder Path"
      layoutFields={[
        fields.repository,
        fields.path,
        defaultValues?.type ? void 0 : fields.type,
      ]}
    />
  );
};

export const UpdateRepositoryFolderPathForm = props => {
  const {
    route: {params},
  } = props;

  const {onSubmit} = useFormSubmit({
    eventSourceId: ['RepositoryFolderPath'],
    uri: '/repositoryFolderPaths',
  });

  const fields = folderPathFields({params});
  const {row: {file_count, ...restRow} = {}} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header="Folder Detail"
      data={restRow}
      layoutFields={[fields.path, fields.type]}
      mode="edit"
    />
  );
};

export const InlineRepositoryFolderPathForm = props => {
  const {
    defaultValues,
    route: {params},
  } = props;
  const {onSubmit} = useFormSubmit({
    eventSourceId: ['RepositoryFolderPath'],
    uri: '/repositoryFolderPaths',
  });

  const fields = folderPathFields({params});

  return (
    <InlineForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      layoutFields={[
        {
          ...fields.type,
          width: 200,
        },
        fields.path,
      ]}
    />
  );
};
