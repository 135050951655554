import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { TabView } from '../../../components/tab';
import { useInvoke } from '../../../controllers/useInvoke';
import { Button } from '../../../components/button/Button';

const BulkForm16Table = props => {
    const { navigation,filterParams, params } = props;
    const invoke = useInvoke({
        method: 'put',
        eventSourceId: 'form16',
    });
    const importHistoryLogDetail = ({ row }) => {
        navigation.navigate(`import-bulk-form16-detail`, {
            ...params,
            importHistoryLogId: row?._id,
        });
    };
    return (
        <Table
            {...props}
            api={'/form16batchdocuments'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="form16"
            limit={50}
            onRowPress={importHistoryLogDetail}
            columns={[
                {
                    field: 'createdAt',
                    header: 'Created On',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                },
                {
                    type: 'text',
                    field: 'status',
                    header: 'Status',
                },
                {
                    header: 'Error Count',
                    field: "pending_count",
                    type: 'number',
                },
                {
                    field: 'success_count',
                    type: 'number',
                    header: 'Success Count',
                },
            ]}
            moreActions={[
                {
                    title: 'Import',
                    onPress: ({ row }) => {
                        invoke({
                            uri: `/importForm16`,
                            props: {
                                _id: row._id,
                            },
                        });
                    }
                }
            ]}
        />
    );
};

export const BulkForm16Tab = props => {
    let { navigation, route: { params } = {} } = props;
    const { filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Bulk Form16',
            view: (
                <BulkForm16Table filterParams={filter} {...props} />
            ),
            eventSourceId: 'form16',
            addOnFilter: filter,
            actions: [
                <Button
                    text="Upload Form16"
                    onPress={() => {
                        navigation.navigate('import-form-16')
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
        />
    );
};
