import { Text } from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { Button } from '../../../components/button/Button';
import { Tag } from '../../../components/chip/Chip';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

export const RenderSkills = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const skillName = row?.employee_skills
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

const assignShadow = ({navigation, params}) => ({
  title: 'Assign Shadow',
  onPress: props => {
    const {row} = props;
    navigation.navigate('workload-assign-shadow-form', {
      row,
    });
  },
});

const updateCV = ({navigation, params}) => ({
  title: 'Update CV',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-cv', {
      row,
    });
  },
});

const assignAsReplacement = ({navigation, params}) => ({
  title: 'Assign As Replacement',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-as-replacement', {
      row,
    });
  },
});

const assignAsLearning = ({navigation, params}) => ({
  title: 'Assign Shadow For Learning',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-as-learning', {
      row,
    });
  },
});

const RenderProject = props => {
  const {row} = props;
  let {employeeLastAssignProject = {}} = row || {};

  if (!employeeLastAssignProject?.project) return null;
  return <Tag value={employeeLastAssignProject.project} />;
};

const unMarkTraining = ({invoke}) => ({
  title: 'Un-Mark Status',
  confirm: {
    title: 'Un-Mark Status',
    message: 'Do you want un-mark the status of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        proposed_deployment_date: null,
        proposed_deployment_comment: null,
        is_proposed_deployment: null,
        training_with_whom: null,
        bench_training_type: null,
        is_from_bench_resource: null,
        is_from_batch_trainee: null,
        opportunity: null,
      },
    });
  },
});

const proposedDeploymentDate = ({navigation, params}) => ({
  title: 'Plan For Deployment',
  onPress: props => {
    const {row} = props;
    navigation.navigate('proposed-deployment-date', {
      row,
    });
  },
});

const assignTraining = ({navigation, params}) => ({
  title: 'Training On Opportunity',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-training', {
      row,
    });
  },
});

const markNotAvailable = ({navigation, params}) => ({
  title: 'Mark Unavailable',
  onPress: props => {
    const {row} = props;
    navigation.navigate('mark-not-available', {
      row,
    });
  },
});

const markAvailable = ({invoke}) => ({
  title: 'Mark Available',
  confirm: {
    title: 'Mark Available',
    message: 'Do you want Mark Available of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        working_status: 'No Project',
      },
    });
  },
});

const markUnBillable = ({invoke}) => ({
  title: 'Mark Unbillable',
  confirm: {
    title: 'Mark Unbillable',
    message: 'Do you want Mark Unbillable of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        working_status: 'Unbillable',
      },
    });
  },
});

const markBillable = ({invoke}) => ({
  title: 'Mark Billable',
  confirm: {
    title: 'Mark Billable',
    message: 'Do you want Mark Billable of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        working_status: 'No Project',
      },
    });
  },
});

export const BenchResource = props => {
  let {
    navigation,
    route: {params},
    filterParams,
  } = props;
  // const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      api={`/employees/bench`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 240,
        },
        {
          render: RenderSkills,
          header: 'Skills',
          width: 150,
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          header: 'Project',
          render: RenderProject,
          width: 250,
        },
        {
          header: 'Hours',
          field: 'totalActualWorkingHours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Free (%)',
          align: 'right',
          render: ({row = {}, styles = {}}) => {
            let {rowText = {}} = styles;

            let {totalActualWorkingHours = 0} = row || {};
            let freePercentage = 0;
            freePercentage = ((9 - Number(totalActualWorkingHours)) / 9) * 100;
            if (Number.isInteger(freePercentage)) {
              return <Text style={{...rowText}}>{freePercentage}</Text>;
            } else {
              return (
                <Text style={{...rowText}}>{freePercentage.toFixed(2)}</Text>
              );
            }
          },
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
      ]}
      params={params}
      {...props}
    />
  );
};

export const DeployableBenchResource = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      searchFields="name"
      api={`/employees/consumableBench`}
      limit={2000}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 240,
        },
        {
          render: RenderSkills,
          header: 'Skills',
          // align: 'right',
          width: 150,
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          header: 'Project',
          render: RenderProject,
          width: 250,
        },
        // {
        //   header: 'Project',
        //   render: RenderProject,
        // },
        {
          header: 'Hours',
          field: 'totalActualWorkingHours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Free (%)',
          align: 'right',
          render: ({row = {}, styles = {}}) => {
            let {rowText = {}} = styles;

            let {totalActualWorkingHours = 0} = row || {};
            let freePercentage = 0;
            freePercentage = ((9 - Number(totalActualWorkingHours)) / 9) * 100;
            if (Number.isInteger(freePercentage)) {
              return <Text style={{...rowText}}>{freePercentage}</Text>;
            } else {
              return (
                <Text style={{...rowText}}>{freePercentage.toFixed(2)}</Text>
              );
            }
          },
        },
      ]}
      moreActions={() => [
        assignShadow({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
      ]}
      params={params}
      {...props}
    />
  );
};

export const NotAvailableResource = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      search={searchValue}
      searchFields="name"
      api={`/employeesNotAvailable`}
      limit={2000}
      fields={{
        name: 1,
        color: 1,
        experience: 1,
        not_available_reason: 1,
        not_available_type: 1,
        official_email_id: 1,
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          header: 'Type',
          field: 'not_available_type',
        },
        {
          header: 'Reason',
          field: 'not_available_reason',
        },
      ]}
      moreActions={() => [
        markAvailable({invoke}),
        proposedDeploymentDate({navigation, params}),
      ]}
      params={params}
      {...props}
    />
  );
};

export const NotBillableResource = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      search={searchValue}
      searchFields="name"
      api={`/employeesNotBillable`}
      limit={2000}
      fields={{
        name: 1,
        color: 1,
        experience: 1,
        not_available_reason: 1,
        not_available_type: 1,
        official_email_id: 1,
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          header: 'Type',
          field: 'not_available_type',
        },
        {
          header: 'Reason',
          field: 'not_available_reason',
        },
      ]}
      moreActions={() => [
        markBillable({invoke}),
        proposedDeploymentDate({navigation, params}),
      ]}
      params={params}
      {...props}
    />
  );
};

export const DeploybleBenchTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {download} = useAppStateContext();
  const tabs = {
    bench_resource: {
      label: 'Bench',
      view: (
        <BenchResource
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={filter}
        />
      ),
      api: '/employees/bench',
      limit: 2000,
      addOnFilter: filter,
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {
        ...params,
      },
    },

    deployable_bench_resource: {
      label: 'Consumable Bench',
      view: (
        <DeployableBenchResource
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={filter}
        />
      ),
      api: '/employees/consumableBench',
      limit: 2000,
      addOnFilter: filter,
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {
        ...params,
      },
    },

    not_available: {
      label: 'Not Available',
      view: (
        <NotAvailableResource
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
          }}
        />
      ),
      api: '/employeesNotAvailable',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {
        ...params,
      },
    },

    not_billable: {
      label: 'Not Billable',
      view: (
        <NotBillableResource
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
          }}
        />
      ),
      api: '/employeesNotBillable',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {
        ...params,
      },
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Training Type',
              options: [
                {label: 'Bandwidth Release', value: 'Project Shadow'},
                {label: 'Swapping', value: 'KT For Swapping'},
                {label: 'Resignation', value: 'KT For Resignation'},
                {label: 'RFR', value: 'RFR'},
                {label: 'Content', value: 'Offline'},
                {label: 'Is Internal', value: 'Internal'},
                {label: 'Is Shadow', value: 'Shadow'},
              ],
              field: 'bench_training_type',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              placeholder: 'Select',
            },
            {
              type: 'autoComplete',
              label: 'Deployable Status',
              options: ['Trainable', 'Deployable'],
              field: 'deployable_trainable_status',
              placeholder: 'Select',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: {$in: [false, null]}},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                ...params,
                period,
              },
            });
          }}
        />,
      ]}
    />
  );
};
