export const getGroupedMessages = ({data, timeField}) => {
  const groupedMessages = {};

  data.forEach(message => {
    const date = new Date(
      new Date(new Date(message[timeField]))?.setHours(0, 0, 0, 0),
    );

    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }

    groupedMessages[date].push({type: 'message', ...message});
  });

  const result = [];
  for (const date in groupedMessages) {
    if (groupedMessages.hasOwnProperty(date)) {
      result.push(...groupedMessages[date], {type: 'date', date});
    }
  }

  return result;
};
