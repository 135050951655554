// Rajnish Trikaldarshi 22/12/2023 - Make screen to show potential customer and industry
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab/TabView';
import React from 'react';
import {useFilter} from '../../../controllers/useFilter';
import {UploadButton} from '../buttons/Upload';
import {useFetchData} from '../../../controllers/useFetchData';
import {leadControllers} from '../controllers/leadControllers';
import {RenderName} from './CustomRendersCollection';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {GroupFilter} from '../../../components/filter/Filters';
import {getToday} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';

const PotentialCustomerLocksByOtherTable = props => {
  let {
    route: {params},
    searchValue,
  } = props;

  const {user} = useAppStateContext();
  const {email} = user || {};

  const {campaign_id: {for_customer} = {}} = params || {};
  const {
    acceptUnlockRequestBatch,
    potentialCustomerLocksBatchRemove,
    viewCommunications,
  } = leadControllers(props);

  const headerTitle = for_customer ? 'Import Customer' : 'Unlock';

  let headerActions =
    for_customer || email === 'rohit.bansal@daffodilsw.com'
      ? {
          actions: [
            {
              title: headerTitle,
              onPress: acceptUnlockRequestBatch,
            },
            {
              title: 'Remove Locked',
              onPress: potentialCustomerLocksBatchRemove,
            },
          ],
        }
      : {
          actions: [
            {
              title: 'Remove Locked',
              onPress: potentialCustomerLocksBatchRemove,
            },
          ],
        };

  return (
    <Table
      selection={headerActions}
      eventSourceId={['communicationAdded', 'UnlockRequest', 'RemoveLocks']}
      search={searchValue}
      searchFields={[
        'contact_linkedIn_url',
        'contact_email',
        'website',
        'name',
      ]}
      api={'/potentialcustomercontactlocks/active'}
      params={{
        campaign_id: params?.campaign_id?._id,
        status: 'locked',
        date: props?.params?.date,
      }}
      sort={{last_communication_date: -1}}
      columns={[
        {
          header: 'Contact',
          field: 'contact_id.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 150,
        },
        {
          header: 'Locked by',
          field: 'owner.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Campaign',
          field: 'campaign_id.name',
          type: 'text',
        },
        {
          header: 'Type',
          field: 'campaign_type.name',
          type: 'text',
          width: 150,
        },
        // {
        //   header: 'Status',
        //   field: 'status',
        //   type: 'text',
        //   width: 150,
        // },
        {
          header: 'Pipeline',
          field: 'potential_customer.pipeline_type',
          type: 'text',
          width: 100,
        },
        {
          header: 'Stage',
          field: 'potential_customer.stage.name',
          type: 'text',
          width: 100,
        },
        {
          header: 'Lead Status',
          field: 'potential_customer.lead_status',
          type: 'text',
          width: 100,
        },
        {
          header: 'Communication',
          field: 'last_communication_date',
          type: 'date',
          width: 150,
          onPress: ({row}) => viewCommunications({row}),
        },
      ]}
    />
  );
};

const verifiedActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 150,
  header: 'POC',
  align: 'right',

  count_field: 'count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('all-campaign-contact_from_prospects', {
      ...row,
      campaign_id: params?.campaign_id,
    });
  },
});

const CampaignProspectsList = props => {
  let {
    navigation,
    route: {params},
    campaignContactsfilter,
    title,
  } = props;

  let {campaign_id} = params || {};
  let {
    populateSummary,
    updateBusinessSummary,
    updatePotentialCustomerSummaryBatch,
    moveToUnverifiedBatch,
    moveSelectedProspectToNewCampaign,
    useAsSampleData,
    useTestFineTuneModel,
    useFinetuneMessage,
    useFinetuneSummary,
  } = leadControllers(props);

  const {data = [], ...restFetchProps} = useFetchData({
    ...campaignContactsfilter,
    eventSourceId: [
      'campaignActionEdited',
      'communicationAdded',
      'reloadImportHistoryLogs',
      'populateBusiness',
      'droppedContact',
      'updateStatus',
      'potentialcustomerupdated',
      'indexUpdated',
      'test website',
      'summaryVerified',
      'markVerified',
      'UnlockRequest',
      'RemoveLocks',
      'moveToUnverified',
      'moveToNewCampaign',
      'addDocument',
    ],
  });
  let column = [];
  let headerActions = [];

  if (campaignContactsfilter?.filter?.title === 'Dropped') {
    column.push({
      header: 'Reason',
      field: 'reason',
      type: 'text',
    });
    column.push({
      header: 'Drop on',
      field: 'drop_on',
      type: 'date',
      width: 150,
    });
    headerActions.push({
      title: 'Move to unverified',
      onPress: ({selectedIds}) => {
        moveToUnverifiedBatch({
          selectedIds,
          campaign_id: campaign_id?._id,
        });
      },
    });
  }

  if (
    campaignContactsfilter?.filter?.title === 'Pending' ||
    campaignContactsfilter?.filter?.title === 'Non-Verified'
  ) {
    headerActions.push({
      title: 'Move To New Campaign',
      onPress: moveSelectedProspectToNewCampaign,
    });
  }

  if (campaignContactsfilter?.filter?.title === 'Error') {
    column.push({
      header: 'Error',
      field: 'potential_customer.error_website_summary',
      type: 'text',
    });
  }

  let extraActions = [];
  if (
    campaignContactsfilter?.filter?.title === 'Verified' ||
    campaignContactsfilter?.filter?.title === 'Non-Verified'
  ) {
    extraActions.push(
      // {
      //   title: 'Use as Sample Data',
      //   onPress: useAsSampleData,
      // },
      // {
      //   title: 'Test with Finetune Model',
      //   onPress: useTestFineTuneModel,
      // },
      {
        title: 'Finetune Email Message',
        onPress: useFinetuneMessage,
      },
      {
        title: 'Finetune Business Summary',
        onPress: ({row}) => {
          useFinetuneSummary({row, campaign_id: campaign_id?._id});
        },
      },
    );
  }

  if (
    campaignContactsfilter?.filter?.title === 'Pending' ||
    campaignContactsfilter?.filter?.title === 'Dropped'
  ) {
    extraActions.push({
      title: 'Finetune Business Summary',
      onPress: ({row}) => {
        useFinetuneSummary({row, campaign_id: campaign_id?._id});
      },
    });
  }
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Populate Summary',
            onPress: ({selectedIds}) => {
              updatePotentialCustomerSummaryBatch({
                selectedIds,
                campaign_id: campaign_id?._id,
              });
            },
          },
          ...headerActions,
        ],
      }}
      data={data}
      {...restFetchProps}
      onRowPress={({row}) => {
        navigation.navigate('edit-campaign-prospect', {
          ...row,
          campaign_id: campaign_id._id,
          title,
          data,
        });
      }}
      columns={[
        {
          header: 'Prospect Company',
          field: 'potential_customer.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Industry',
          field: 'potential_customer.industry.name',
          type: 'text',
          width: 200,
        },
        verifiedActionCount({navigation, params}),

        ...column,
      ]}
      moreActions={[
        {
          title: 'Populate Summary',
          onPress: ({row}) => {
            populateSummary({row, campaign_id: campaign_id?._id});
          },
        },
        {
          title: 'Update Summary',
          onPress: updateBusinessSummary,
        },
        ...extraActions,
      ]}
      {...props}
    />
  );
};

export const CampaignProspectsTab = props => {
  let {route: {params} = {}, navigation} = props;
  let {filter = {}, campaign_id} = params;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: params.period || getToday(),
    },
  });
  let {filter: filters = {}, params: filterParams = {}} = filterValues || {};
  filter = {...filter, ...filters};
  params = {...params, ...filterParams};

  let campaignContactsfilter = {
    api: `/outreachcampaigns/${campaign_id._id}/prospects`,
    filter: filter,
    params,
    eventSourceId: [
      'markVerified',
      'droppedContact',
      'communicationAdded',
      'campaignActionEdited',
      'reloadImportHistoryLogs',
      'populateBusiness',
      'droppedContact',
      'updateStatus',
      'potentialcustomerupdated',
      'indexUpdated',
      'test website',
      'summaryVerified',
      'markVerified',
      'UnlockRequest',
      'RemoveLocks',
      'moveToUnverified',
      'moveToNewCampaign',
      'addDocument',
    ],
  };

  const tabs = {
    pending: {
      label: `Pending`,
      view: (
        <CampaignProspectsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              website_summary_status: 'pending',
              status: 'new',
              title: 'Pending',
            },
            sort: {createdAt: -1},
          }}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        website_summary_status: 'pending',
        status: 'new',
        title: 'Pending',
      },
    },
    non_verified: {
      label: `Non-Verified`,
      view: (
        <CampaignProspectsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              website_summary_status: 'unverified',
              title: `Non-Verified`,
              status: 'new',
            },
            sort: {createdAt: -1},
          }}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        website_summary_status: 'unverified',
        title: `Non-Verified`,
        status: 'new',
      },
    },
    verified: {
      label: `Verified`,
      view: (
        <CampaignProspectsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              website_summary_status: 'verified',
              title: 'Verified',
              status: {
                $in: [
                  'new',
                  'connected',
                  'withdrawal',
                  'connection_request_sent',
                  'reply_received',
                  'nurturing',
                ],
              },
            },
            sort: {createdAt: -1},
          }}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        website_summary_status: 'verified',
        title: 'Verified',
        status: {
          $in: [
            'new',
            'connected',
            'withdrawal',
            'connection_request_sent',
            'reply_received',
            'nurturing',
          ],
        },
      },
    },
    error: {
      label: `Error`,
      view: (
        <CampaignProspectsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              website_summary_status: 'error',
              title: `Error`,
            },
            sort: {createdAt: -1},
          }}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        website_summary_status: 'error',
        title: `Error`,
      },
    },
    dropped: {
      label: `Dropped`,
      view: (
        <CampaignProspectsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              status: 'dropped',
              error: null,
              title: `Dropped`,
            },
            sort: {createdAt: -1},
          }}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        status: 'dropped',
        title: `Dropped`,
      },
    },
    lockedByOther: {
      label: `Locked`,
      view: <PotentialCustomerLocksByOtherTable />,
      api: '/potentialcustomercontactlocks/active',
      params: {
        campaign_id: params?.campaign_id?._id,
        status: 'locked',
        date: params?.date,
      },
      eventSourceId: 'reloadImportHistoryLogs',
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'drop_by_system',
              options: [
                {label: 'true', value: true},
                {label: 'false', value: false},
              ],
              placeholder: 'Select',
              valueField: 'label',
              keyField: 'value',
              suggestionField: 'label',

              field: 'drop_by_system',
              asParam: true,
            },
            {
              type: 'date',
              field: 'date',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
        <UploadButton props={props} navigation={navigation} />,
      ]}
    />
  );
};
