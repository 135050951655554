import React, {useEffect, useRef, useState} from 'react';
import {MediaQueryProvider, ThemeProvider} from '@unthinkable/react-theme';
import theme from './theme/theme';
import {EventHandlerProvider} from '@unthinkable/react-event-handler';
import config from './Config';
import {AppStateProvider} from './providers/AppState';
import componentsTheme from './theme/componentsTheme';
import {FilterContextProvider} from './providers/FilterProvider';
import {
  NavigationContainer,
  getPathFromState as defaultGetPathFromState,
  getStateFromPath as defaultGetStateFromPath,
  useNavigationContainerRef,
} from '@react-navigation/native';
import GoogleOAuthProvider from './providers/GoogleOAuthProvider';
import {Platform} from '@unthinkable/react-core-components';
import {ToastProvider} from '@unthinkable/react-toast';
import {Toast} from './components/toast/Toast';
import {handleInitialRoute} from './routes/Utility';
import {screenPaths} from './routes';
import {SocketContextProvider} from './providers/SocketProvider';
import {HomeWithPopup} from './routes/HomeWithPopupScreens';
import {CaptureContextProvider} from './providers/CaptureContextProvider';
import {decodeData, encodeData, key} from './Encryption';

function logActiveRouteName(state) {
  if (!state) {
    return;
  }
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return logActiveRouteName(route.state);
  }
  // console.log(`@@@ activeRouteName: [${route.name}]`);
}

const initialApis = {
  types: {
    uri: '/types',
    params: {fields: {name: 1}},
  },
  accessRights: {
    uri: '/myAccessRights',
    params: {},
  },
  chatCount: {
    uri: '/chatCount',
    params: {},
  },
  notificationCount: {
    uri: '/notificationcount',
    params: {
      filter: {read_on: {$exists: false}},
    },
  },
};

const linkingConfig = {
  screens: screenPaths
    ? Object.keys(screenPaths).reduce((prev, next) => {
        prev[next] = screenPaths[next].path;
        return prev;
      }, {})
    : {},
};

const AppProviders = ({
  children,
  googleClientId,
  theme,
  componentsTheme,
  authenticateApi,
  config,
  currentNavigationPath,
  currentNavigationState,
}) => {
  const {logsEnabled} = config;

  const getNavigationType = ({user, config}) => {
    // set navigationType
    if (
      user?.externalUser ||
      (config?.pmtUrl && window.origin === config?.pmtUrl) ||
      !user?.oldNavigation ||
      localStorage.getItem('navigationType') === 'new'
    ) {
      return 'new';
    } else {
      return 'old';
    }
  };
  const navigationRef = useNavigationContainerRef();

  const [user, setUser] = useState();

  const [pathname] = useState(window.location.pathname);
  // get navigation type @adit 18 July 2024
  const navigationType = getNavigationType({user, config});
  const initialPathState = defaultGetStateFromPath(pathname, linkingConfig);

  const linking =
    navigationType === 'new'
      ? {
          config: linkingConfig,
          getStateFromPath: (path, config) => {
            const state = defaultGetStateFromPath(path, config);

            // Check if the current path is the same as the one we're trying to set to maintain breadcrumbs
            const navigationPath = window.location.pathname;
            let newState =
              navigationPath &&
              navigationPath.includes('pmt') &&
              navigationPath === currentNavigationPath
                ? currentNavigationState
                : state;

            return newState;
          },
          getPathFromState: (state, options) => {
            // Get the default path
            const path = defaultGetPathFromState(state, options);
            const pathWithoutQuery = path.split('?')[0];

            try {
              if (pathWithoutQuery.includes('pmt')) {
                // Encode path
                const encodedPath = encodeData(pathWithoutQuery, key);
                localStorage.setItem('current_navigation_path', encodedPath);
              }
            } catch (e) {
              console.log('🚀 ~ getPathFromState ~ e:', e);
            }

            return pathWithoutQuery;
          },
        }
      : void 0;

  return (
    <NavigationContainer
      key={navigationType}
      linking={linking}
      onStateChange={state => {
        if (Platform.OS === 'web') document.title = 'Locomo';
        logsEnabled && logActiveRouteName(state);

        try {
          const pathUrl = window.location.pathname;

          if (pathUrl && pathUrl.includes('pmt')) {
            //Set current state in localStorage
            const encodedData = encodeData(
              JSON.stringify({routes: state?.routes}),
              key,
            );

            localStorage.setItem('current_navigation_state', encodedData);
          }
        } catch (e) {
          console.log('🚀 ~ onStateChange ~ e:', e);
        }
      }}
      ref={navigationRef}>
      <ToastProvider>
        <GoogleOAuthProvider clientId={googleClientId}>
          <EventHandlerProvider>
            <MediaQueryProvider breakpoints={theme.breakpoints}>
              <ThemeProvider base={theme} components={componentsTheme}>
                <AppStateProvider
                  api={authenticateApi}
                  config={config}
                  initialApis={initialApis}
                  navigationRef={navigationRef}
                  initialPath={pathname}
                  navigationType={navigationType}
                  initialPathState={
                    navigationType === 'new' ? initialPathState : void 0
                  }
                  setUserInApp={setUser}
                  handleInitialRoute={handleInitialRoute}>
                  <SocketContextProvider>
                    <CaptureContextProvider>
                      <FilterContextProvider>
                        {children}
                        <Toast />
                      </FilterContextProvider>
                    </CaptureContextProvider>
                  </SocketContextProvider>
                </AppStateProvider>
              </ThemeProvider>
            </MediaQueryProvider>
          </EventHandlerProvider>
        </GoogleOAuthProvider>
      </ToastProvider>
    </NavigationContainer>
  );
};

function App() {
  const [currentNavigationPath, setCurrentNavigationPath] = useState(null);
  const [currentNavigationState, setCurrentNavigationState] = useState(null);
  const [isMount, setMounted] = useState(false);

  //Get current navigation path and state from localStorage when component mounts.
  useEffect(() => {
    try {
      const pathUrl = window.location.pathname;

      if (pathUrl && pathUrl.includes('pmt')) {
        const storedNavigationPath = localStorage.getItem(
          'current_navigation_path',
        );
        const storedNavigationState = localStorage.getItem(
          'current_navigation_state',
        );

        if (storedNavigationPath) {
          const decodedPath = decodeData(storedNavigationPath, key);
          setCurrentNavigationPath(decodedPath);
        }

        if (storedNavigationState) {
          const decodedData = decodeData(storedNavigationState, key);
          setCurrentNavigationState(JSON.parse(decodedData));
        }
      }
    } catch (e) {
      console.log('🚀 ~ useEffect ~ e:', e);
    } finally {
      setMounted(true);
    }
  }, []);

  if (isMount) {
    return (
      <AppProviders
        googleClientId={
          '996957158544-vm8k9hnee4d5o959gi4f7oojajsnr4nb.apps.googleusercontent.com'
        }
        theme={theme}
        componentsTheme={componentsTheme}
        authenticateApi={'/get-user-by-token'}
        currentNavigationPath={currentNavigationPath}
        currentNavigationState={currentNavigationState}
        config={config}>
        <HomeWithPopup />
      </AppProviders>
    );
  }
}

export default App;
