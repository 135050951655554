import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {useAppStateContext} from '../../../providers/AppState';
import SkillMetricsTable from './SkillMetricsTable';
import {TrainingBatchTabs} from './TrainingBatchTable';
import {TrainingProgramTab} from './TrainingProgramTabView';
import {AllTrainers} from './AllTrainersList';
import { AllTrainerTabs } from './AllTrainersTabs';
import { AllTraineesTabs } from './AllTraineeTabs';

export const SkillAndTrainingNavigatorHR = props => {
  const {route: {params} = {}} = props;
  const menus = [
    {
      label: 'Skill Matrix',
      view: <SkillMetricsTable fromHr={true} />,
      api: '/skillMetrics',
      params: {
        type: 'component',
        ...params,
      },
      filter: {
        product: 'params?.product?',
        parent: {$eq: null},
        status: 'active',
      },
      eventSourceId: ['SkillMetrics', 'skillMetricsAdded'],
    },
    {
      label: 'Course Setup',
      view: <TrainingProgramTab />,
      api: '/trainingPrograms',
      filter: {product: 'params?.product?', status: {$ne: 'deleted'}},
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'TrainingPrograms',
    },
    {
      label: 'Course Batches',
      view: <TrainingBatchTabs />,
      api: '/trainingBatches',
      filter: {product: 'params?.product?', status: {$ne: 'deleted'}},
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'TrainingBatches',
    },
    {
      label: 'All Trainers',
      view: <AllTrainerTabs />,
      api: '/allTrainers',
    },
    {
      label:'All Trainees',
      view: <AllTraineesTabs/>,
      api: '/allTrainees',
    }
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const SkillAndTrainingNavigatorSkillLeader = props => {
  const {route: {params} = {}} = props;
  const {
    user: {employee},
  } = useAppStateContext();
  const menus = [
    {
      label: 'Skills',
      view: <SkillMetricsTable fromSkillLeader />,
      api: '/skillMetrics',
      params: {
        type: 'component',
        ...params,
      },
      filter: {
        product: 'params?.product?',
        parent: {$eq: null},
        status: 'active',
        leader: employee?._id,
      },
      eventSourceId: ['SkillMetrics', 'skillMetricsAdded'],
    },
    {
      label: 'Course',
      view: <TrainingProgramTab fromSkillLeader />,
      api: '/trainingPrograms',
      filter: {
        product: 'params?.product?',
        status: {$ne: 'deleted'},
        skill_leader: employee?._id,
      },
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'TrainingPrograms',
    },
    {
      label: 'Course Batches',
      view: <TrainingBatchTabs fromSkillLeader />,
      api: '/trainingBatches',
      filter: {
        product: 'params?.product?',
        status: {$ne: 'deleted'},
        skill_leader: employee?._id,
      },
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'TrainingBatches',
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
