import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddStrategicInitiativesForm = props => {
  const {route = {}, navigation} = props;
  const {params} = route;
  const {
    defaultValues,
    row,
    product,
    organization,
    department,
    searchValue,
    afterSubmit,
  } = params;
  let {onSubmit} = useFormSubmit({
    uri: '/strategicInitiatives',
    eventSourceId: 'reloadStrategicInitiative',
    ...props,
  });
  return (
    <Form
      api={`/strategicInitiatives/${row?._id}`}
      fields={{
        name: 1,
        description: 1,
        department: {name: 1},
        leader: {
          name: 1,
        },
        resources: {
          name: 1,
        },
        date: 1,
        status: 1,
        is_open: 1,
        resource_interests: {
          resource: {
            name: 1,
          },
          interest: 1,
          date: 1,
        },
        task_list: {name: 1},
        custom_object_type: {name: 1},
      }}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      header={'Add Custom Object'}
      defaultValues={{
        department: department?._id,
        product: product?._id,
        organization: organization?._id,
        status: 'Active',
        name: searchValue,
        ...defaultValues,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Object Name',
          field: 'name',
          type: 'text',
          size: 8,
        },
        {
          label: 'Object Type',
          placeholder: 'Object Type',
          field: 'custom_object_type',
          type: 'autoComplete',
          api: '/CustomObjectTypes',
          suggestionField: 'name',
          valueField: 'name',
          onCreate: () => {
            navigation.navigate('add-custom-object-type');
          },
          size: 4,
        },
        {
          placeholder: 'Leader',
          field: 'leader',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 8,
        },
        {
          placeholder: 'Target',
          field: 'date',
          type: 'date',
          size: 4,
        },
        {
          placeholder: 'Description',
          field: 'description',
          type: 'textArea',
        },
        {
          groups: [
            {
              field: 'task_list',
              label: 'Add Acceptance Checklist',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      type: 'text',
                      field: 'name',
                      label: 'Add Acceptance Criteria',
                    },
                  ]}
                  columns={[
                    {
                      type: 'text',
                      field: 'name',
                      header: 'Name',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditStrategicInitiativeForm = props => {
  return (
    <AddStrategicInitiativesForm
      {...props}
      mode="edit"
      header={'Edit Custom Object'}
    />
  );
};
