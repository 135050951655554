import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const UpdateProposalStatus = props => {
  const params = props?.route?.params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });
  const {opportunity} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Status & Win-ability'}
      submitAction={'Save'}
      defaultValues={{
        ...opportunity,
      }}
      layoutFields={[
        {
          placeholder: 'Status',
          label: 'Status',
          field: 'proposal_status',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: [
            'Proposal Walkthrough',
            'Re-Estimation',
            'Negotiation',
            'Walkthrough done',
            'Revise Proposal',
          ],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('proposal_status_date', getZeroTimeDate(new Date()));
          },
        },
        {
          placeholder: 'Win-ability',
          label: 'Win-ability',
          field: 'winning_chance',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['High', 'Mid', 'Low'],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('winning_chance_date', getZeroTimeDate(new Date()));
          },
        },
      ]}
    />
  );
};

export const UpdateProposalStatusPreSales = props => {
  const params = props?.route?.params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });
  const {opportunity} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Status & Win-ability'}
      submitAction={'Save'}
      defaultValues={{
        ...opportunity,
      }}
      layoutFields={[
        {
          placeholder: 'Status',
          label: 'Status',
          field: 'proposal_status',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: [
            'Proposal Walkthrough',
            'Re-Estimation',
            'Negotiation',
            'Walkthrough done',
            'Revise Proposal',
            'Client Review',
          ],
          onChangeValue: (value, e, {setFieldValue}) => {
            if (value == 'Client Review') {
              setFieldValue('sub_stage', 'Client Review');
              setFieldValue('sub_stage_date', new Date());
              setFieldValue(
                'proposal_status_date',
                getZeroTimeDate(new Date()),
              );
            }
            setFieldValue('proposal_status_date', getZeroTimeDate(new Date()));
          },
        },
        {
          placeholder: 'Win-ability',
          label: 'Win-ability',
          field: 'winning_chance',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['High', 'Mid', 'Low'],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('winning_chance_date', getZeroTimeDate(new Date()));
          },
        },
      ]}
    />
  );
};

export const UpdateWinningChanceForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });

  const {opportunity} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Winning Chance'}
      submitAction={'Save'}
      defaultValues={{
        ...opportunity,
      }}
      layoutFields={[
        {
          placeholder: 'Status',
          field: 'winning_chance',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['High', 'Medium', 'Low'],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('winning_chance_date', getZeroTimeDate(new Date()));
          },
        },
      ]}
    />
  );
};

export const UpdatePOCStatus = props => {
  const params = props?.route?.params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'POC',
    uri: '/opportunities',
    ...props,
  });
  const {opportunity} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Status'}
      submitAction={'Save'}
      defaultValues={{
        ...opportunity,
      }}
      layoutFields={[
        {
          placeholder: 'Status',
          label: 'Status',
          field: 'poc_status',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['POC Successful', 'POC Fail'],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('poc_status_date', getZeroTimeDate(new Date()));
          },
        },
      ]}
    />
  );
};

export const UpdateCombinedSubStage = props => {
  const params = props?.route?.params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });
  const {opportunity} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Status & Win-ability'}
      submitAction={'Save'}
      defaultValues={{
        ...opportunity,
        sub_stage: opportunity?.sub_stage,
        sub_stage_date: new Date(),
      }}
      layoutFields={[
        {
          placeholder: 'Status',
          label: 'Status',
          field: 'sub_stage',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: [
            'Requirement gathering',
            'Scope Freezing and Estimation',
            'Proposal Drafting',
            'Sales Review',
            'Proposal Walkthrough',
            'Re-Estimation',
            'Negotiation',
            'Walkthrough done',
            'Revise Proposal',
          ],
          onChangeValue: async (value, e, {setFieldValue}) => {
            if (value == 'Requirement gathering') {
              setFieldValue(
                'requirement_gathering_date',
                getZeroTimeDate(new Date()),
              );
            }
            if (value == 'Scope Freezing and Estimation') {
              setFieldValue('scoop_freezing_date', getZeroTimeDate(new Date()));
            }
            if (value == 'Proposal Drafting') {
              setFieldValue(
                'proposal_drafting_date',
                getZeroTimeDate(new Date()),
              );
            }
            if (value == 'Sales Review') {
              setFieldValue('sales_review_date', getZeroTimeDate(new Date()));
            }
            if (value == 'Proposal Walkthrough') {
              setFieldValue(
                'proposal_walkthrough_date',
                getZeroTimeDate(new Date()),
              );
            }
            if (value == 'Re-Estimation') {
              setFieldValue('re_estimation_date', getZeroTimeDate(new Date()));
            }
            if (value == 'Negotiation') {
              setFieldValue('negotiation_date', getZeroTimeDate(new Date()));
            }
            if (value == 'Walkthrough done') {
              setFieldValue(
                'walkthrought_done_date',
                getZeroTimeDate(new Date()),
              );
            }
            if (value == 'Revise Proposal') {
              setFieldValue(
                'revise_proposal_date',
                getZeroTimeDate(new Date()),
              );
            }
          },
        },
      ]}
    />
  );
};
