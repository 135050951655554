import { useFilter } from '@unthinkable/react-filters';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

export const LeaveRegisterTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, filterValues} = useFilter({});
  return (
    <Table
      eventSourceId={['leaveRegister']}
      api={`/getleaveregister`}
      addOnFilter={filterValues.filter}
      renderHeader={() => (
        <TableHeader
          title="Leave Register"
          actions={[
            <AddButton
              title="Leave Register"
              view="add-leave-register"
              params={params}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Employee',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'employee',
                  secondarySuggestionField: 'official_email_id',
                },
              ]}
            />,
          ]}
        />
      )}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-leave-register`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          type: 'date',
          header: 'Date',
          field: 'date',
        },
        {
          type: 'text',
          header: 'Month',
          field: 'month_id.name',
        },
        {
          type: 'text',
          header: 'Year',
          field: 'year_id.name',
        },
      ]}
    />
  );
};
