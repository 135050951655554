import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const BudgetCategoryTab = props => {
  let {route: {params} = {}} = props;
  const tabs = {
    tds: {
      label: 'Budget Category',
      view: <BudgetCategoryTable {...props} />,
      eventSourceId: 'BudgetCategory',
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      actions={[
        <AddButton
          title=" Add Budget Category"
          view="add-budget-category"
          params={params}
        />,
      ]}
    />
  );
};

export const BudgetCategoryTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={['BudgetCategory']}
      api={`/budgetcategories`}
      fields={{
        name: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-budget-category`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
        },
      ]}
    />
  );
};

export const BudgetCategory = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/budgetcategories',
    eventSourceId: ['BudgetCategory'],
  });
  return (
    <Form
      api={`/budgetCategories/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddBudgetCategory = props => {
  return <BudgetCategory header="Add Budget Category " {...props} />;
};

export const EditBudgetCategory = props => {
  return (
    <BudgetCategory mode="edit" header={'Update Budget Category'} {...props} />
  );
};
