import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const projectResourceWorkingComputations = fetch => ({
  'set amount': {
    compute: value => {
      const {exchange_rate = 1, billing_hrs, rate} = value || {};
      if (billing_hrs && rate) {
        const amount = billing_hrs * rate;
        const base_amount = amount * exchange_rate;
        const variable_potential = billing_hrs;
        return {amount, base_amount, variable_potential};
      }
    },
    multi: true,
    dependencies: ['billing_hrs', 'rate'],
  },
});

export const UpdateProjectResourceWorking = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/projectresourceworkingdata',
    ...props,
    eventSourceId: ['projectResourceWorking'],
  });
  const {row} = params;
  const {fetch} = useAppStateContext();
  return (
    <Form
      header={{
        title: 'Edit Project Resource Working',
        secondaryTitle: `${row?.delivery_number}`,
      }}
      mode="edit"
      api={`/projectresourceworkingdata/${row?._id}`}
      fields={{
        advance_due_date: 1,
        invoice_due_date: 1,
        due_date: 1,
        delivery_number: 1,
        billing_hrs: 1,
        variable_potential: 1,
        rate: 1,
        exchange_rate: 1,
        account_owner_id: {name: 1},
        sales_owner: {name: 1},
        delivery_owner_id: {name: 1},
        description: 1,
        product: {name: 1},
      }}
      computations={{
        ...projectResourceWorkingComputations(fetch),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Rate',
          placeholder: 'Rate',
          field: 'rate',
          type: 'number',
          size: 6,
        },
        {
          label: 'Billing Hrs.',
          placeholder: 'Billing Hrs.',
          field: 'variable_potential',
          type: 'number',
          size: 6,
        },
        {
          label: 'Advance Due Date',
          placeholder: 'Advance Due Date',
          field: 'advance_due_date',
          type: 'date',
          size: 4,
        },
        {
          label: 'Invoice Due Date',
          placeholder: 'Invoice Due Date',
          field: 'invoice_due_date',
          type: 'date',
          size: 4,
        },
        {
          label: 'End Date',
          placeholder: 'End Date',
          field: 'due_date',
          type: 'date',
          size: 4,
        },
        {
          label: 'Account Manager',
          placeholder: 'Account Manager',
          field: 'account_owner_id',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        // {
        //   label: 'Sales Owner',
        //   placeholder: 'Sales Owner',
        //   field: 'sales_owner',
        //   type: 'autoComplete',
        //   api: `/employeeSuggestions`,
        //   suggestionField: 'name',
        //   secondarySuggestionField: 'official_email_id',
        //   valueField: 'name',
        //   required: true,
        //   size: 6,
        // },
        {
          label: 'CSM',
          placeholder: 'CSM',
          field: 'delivery_owner_id',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Description',
          placeholder: 'Description',
          field: 'description',
          type: 'textArea',
        },
      ]}
      {...props}
    />
  );
};
