import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {STATUS} from '../views/TargetAudienceTable';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useAppStateContext} from '../../../providers/AppState';
import {INMAIL_CAMPAIGN_TYPE_ID} from '../../common/constants/SourceConstants';
import {Text, TouchableOpacity} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {comparisonIndicatorStyles} from '../../../components/card/theme';

const prospectCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'POC',
  align: 'center',

  count_field: 'prospect_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contacts`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        for_customer: row.for_customer,
        campaign_method: row?.campaign_method,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contacts`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        for_customer: row.for_customer,
        campaign_method: row?.campaign_method,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});
const unverifiedActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Unverified',
  align: 'right',

  count_field: 'unverified_action_count',
  responsive: 'sm',
  styleForCountField: true,

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-verified', {
      ...row,
      verified: {$in: [null, false]},
      status: 'pending',
      showUnverified: true,
    });
  },
});
const verifiedActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Verified',
  align: 'right',

  count_field: 'verified_action_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      verified: true,
      status: 'pending',
      showUnverified: false,
    });
  },
});
const sentActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Actions',
  align: 'right',

  count_field: 'sent_action_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      sent_action: true,
      status: 'completed',
    });
  },
});

const messageCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Actions',
  align: 'right',

  count_field: 'campaign_message_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`add-campaign-message-form`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        communication_type: row?.communication_type,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`campaign-messages`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        communication_type: row?.communication_type,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});

const uspCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'USP',
  align: 'right',

  count_field: 'usp_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`add-outreach-usp-form`, {
      campaign_id: {_id: row._id, name: row.name},
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-usps`, {
      campaign_id: {_id: row._id, name: row.name},
    });
  },
});
const TrainingData = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Training Data',
  align: 'right',

  count_field: 'training_data_count',
  responsive: 'sm',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`show-actions`, {
      ...row,
      verified: {$in: [null, false]},
      status: 'pending',
      showUnverified: true,
      for_training: true,
      // addOnFilter: {
      //   $or: [
      //     {
      //       personalize_with_ai: true,
      //       personalize_body: {$exists: true},
      //     },
      //     {
      //       personalize_with_ai: {$in: [false, null]},
      //     },
      //   ],
      // },
      api: `/campaignactiontrainings`,
    });
  },
});

const RenderResponse = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const {color, ...rest} = rowText;
  let {sent_count, positive_response_count} = row || {};

  let positive_percent = positive_response_count
    ? ((positive_response_count / sent_count) * 100).toFixed(2)
    : 0;

  const _styles = useStyles(comparisonIndicatorStyles);

  return (
    <Text
      style={{
        ..._styles.profit,
        ...rest,
      }}>
      {positive_percent}
    </Text>
  );
};

const RenderCampaign = props => {
  const {row, styles = {}, navigation} = props;
  const {rowText} = styles;
  let {name} = row || {};

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('view-campaign-prospect-details', {
          campaign_id: {
            _id: props?.row._id,
            name: props?.row.name,
            communication_type: props?.row?.communication_type,
          },
          defaultValues: {
            campaign_id: {_id: props?.row._id, name: props?.row.name},
          },
          filter: {
            campaign_id: props?.row._id,
          },
        });
      }}>
      <Text
        style={{
          ...rowText,
        }}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export const OutreachCampaigns = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    searchValue,
    filter,
    tabFilter = {},
  } = props;
  params = {...params, ...filterParams};
  const {user, download} = useAppStateContext();

  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64d0fd55ab78dfead48c144b',

      label: row?.name,
    });
  };

  const {period} = params || {};

  const {
    editCampaign,
    populateRandomContacts,
    checkLinkedinProfile,
    settingsOutreachCampaign,
    moveToNewCampaign,
    populateNextMessage,
    activityLogDetailforInmailCampaign,
    moveAllProspectsToNewCampaign,
    verifyPastMessages,
    loadRandomDataFromList,
    importDataForTraining,
  } = leadControllers({
    ...props,
    params: {...params, fromInmailCampaign: true},
  });

  return (
    <Table
      eventSourceId={[
        'outreachAdded',
        'inmailCampaignAdded',
        'Task',
        'campaignMessageAdded',
        'reloadImportHistoryLogs',
        'actionVerified',
        'summaryVerified',
        'indexUpdated',
        'markArchive',
        'moveToNewCampaign',
        'campaignUSPAdded',
        'importData',
      ]}
      api={`/outreachcampaigns/analytics`}
      params={params}
      addOnFilter={{...filter, ...tabFilter}}
      search={searchValue}
      searchFields={'name'}
      columns={[
        {
          header: 'Campaign',
          render: props => RenderCampaign({...props, navigation}),
        },

        {
          width: 100,
          header: 'Owner',
          field: 'assigned_to',
          type: 'userAvatar',
        },
        // {
        //   width: 80,
        //   header: 'Profile',
        //   field: 'linkedin_profile',
        //   type: 'userAvatar',
        // },
        prospectCount({navigation, params}),
        {
          width: 140,
          header: 'Prospect Touched',
          field: 'unique_prospect_count',
          type: 'text',
          align: 'right',
        },
        // messageCount({navigation, params}),
        // uspCount({navigation, params}),
        // TrainingData({navigation, params}),
        // unverifiedActionCount({navigation, params}),
        // verifiedActionCount({navigation, params}),
        sentActionCount({navigation, params}),
        {
          width: 120,
          render: RenderResponse,
          header: 'Response %',
          align: 'right',
          onPress: ({row}) => {
            let addOnFilter = {
              title: 'Positive response',
              campaign_id: row?._id,
              campaign_name: row?.name,
              positive_response_on: {$exists: true},
              completedOn: {$gte: period?.from, $lte: period?.to},
            };
            navigation.navigate('show-analytics-actions', {addOnFilter});
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editCampaign,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        // {
        //   title: 'Import data for Training',
        //   onPress: ({row}) =>
        //     importDataForTraining({
        //       row: {
        //         ...row,
        //         campaign_type: INMAIL_CAMPAIGN_TYPE_ID,
        //       },
        //     }),
        // },
        // Akshat Garg - 24/05/24 - adding view analytics row action
        {
          title: 'View Analytics',
          onPress: props => {
            navigation.navigate('show-outreach-analytics', {
              row: {
                ...props?.row,
                campaignAnalytics: {campaign_id: props?.row._id},
              },
              fromRow: true,
            });
          },
        },
        {
          title: 'Add Messages',
          onPress: ({row}) => {
            navigation.navigate(`campaign-messages`, {
              campaign_id: {
                _id: row._id,
                name: row.name,
                communication_type: row?.communication_type,
              },
              defaultValues: {
                campaign_id: {_id: row._id, name: row.name},
              },
              filter: {
                campaign_id: row._id,
              },
            });
          },
        },
        {
          title: 'Load Data',
          onPress: ({row}) =>
            loadRandomDataFromList({
              row: {...row, campaign_type: INMAIL_CAMPAIGN_TYPE_ID},
            }),
        },
        {
          title: 'Move to Batch',
          onPress: ({row}) => {
            navigation.navigate('set-batch', {
              campaign_id: row?._id,
            });
          },
          visible: ({row}) => row?.status === STATUS.RETIRED,
        },

        {
          title: 'Download Campaign contacts',
          onPress: ({row}) =>
            download({
              uri: `/download-campaign-contact-as-excel`,
              props: {campaign_id: row?._id, campaign_name: row?.name},
            }),
        },
        {
          title: 'Update Campaign contacts',
          onPress: ({row}) => {
            navigation.navigate('import-list', {
              ...props?.route?.params,
              source: 'segmentation',
              model: 'UserSegmentContact',
              updateOnly: true,
              campaign_name: row?.name,
            });
          },
        },
        {
          title: 'AI Prompt',
          onPress: ({row}) => {
            navigation.navigate('ai-prompts-table', {
              addOnFilter: {for_crm: true, _id: row?.personalization_prompt_id},
            });
          },
          visible: ({row}) => row?.campaign_method === 'AI',
        },
        {
          title: 'Finetune Usecase',
          onPress: ({row}) => {
            navigation.navigate('fine-tune-use-case-list', {
              addOnFilter: {_id: row?.usecase_personalization_id},
            });
          },
          visible: ({row}) => row?.campaign_method === 'AI',
        },
        {
          title: 'Set Actual ScheduleOn',
          onPress: ({row}) => {
            navigation.navigate('set-actual-schedule-on', {
              campaign_id: row?._id,
            });
          },
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/campaigns`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        {
          title: 'Fail',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Failed Date',
              header: 'Failed Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'failed_on',
              status: STATUS.FAILED,
            });
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailforInmailCampaign,
        },
      ]}
    />
  );
};
