import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from './components/Durations';

const navigateToOrderResourceAssignment = ({navigation, params}) => ({
  title: 'Assign Resource',
  onPress: props => {
    const {row} = props;
    let {
      order = {},
      skill_required = {},
      employee_per_day_working_hour,
    } = row || {};

    let order_allocations = {
      skill_required,
      order,
      _id: row?._id,
      recurring_till: order?.recurring_till,
      order_date: order?.order_date,
      employee_per_day_working_hour,
      product: order?.product,
    };
    navigation.navigate('order-resource-assignment', {
      order_allocations,
    });
  },
});

const SkillRequirementTable = props => {
  let {route: {params = {}} = {}, navigation} = props || {};
  let {data = {}} = params;
  let { _id} = data;
  let filter = {

    skill_required: _id,
    status: 'Unassigned',
  };

  return (
    <Table
      api={'/skillrequirments'}
      params={params}
      eventSourceId={['skillRequirement', 'employeeAssigned']}
      addOnFilter={filter}
      fields={{
        order: {
          order_number: 1,
          product: {_id: 1, name: 1},
          recurring_till: 1,
          order_date: 1,
        },
        skill_required: {
          name: 1,
        },
        skill_requirement_no: 1,
        from_date: 1,
        to_date: 1,
        employee_per_day_working_hour: 1,
        status: 1,
      }}
      columns={[
        {header: 'Req', field: 'skill_requirement_no', type: 'text'},
        {header: 'Order', field: 'order.order_number', type: 'text'},
        {header: 'Skill ', field: 'skill_required.name', type: 'text'},
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },

        {
          header: 'Working Hrs',
          field: 'employee_per_day_working_hour',
          type: 'number',
        },
      ]}
      moreActions={() => [
        navigateToOrderResourceAssignment({navigation, params}),
      ]}
    />
  );
};

export default SkillRequirementTable;
