import React from 'react';

import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

import {TextRenderer} from '../../../components/form/Renderers';
import {leadControllers} from '../controllers/leadControllers';
import {
  Linking,
  Text,
  View,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {useFetchData} from '../../../controllers/useFetchData';

const moment = require('moment');

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {potential_customer: {website = ''} = {}} = row || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {row?.potential_customer?.name}
      </Text>
    </TouchableOpacity>
  );
};

const RenderLinkedInUrl = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {contact_id: {linkedin_url = '', name, first_name, last_name} = {}} =
    row || {};
  const underline = linkedin_url ? {textDecoration: 'underline'} : void 0;
  linkedin_url =
    linkedin_url &&
    (linkedin_url.startsWith('https') || linkedin_url.startsWith('http'))
      ? linkedin_url
      : `https://${linkedin_url}`;

  const displayName = name ? name : first_name + last_name;

  return (
    <TouchableOpacity
      onPress={() => {
        linkedin_url && Linking.openURL(linkedin_url, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};
const RenderCountry = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {
    scheduleOnCustomerTimezone,
    potential_customer: {country: {name = '', code = ''} = {}} = {},
  } = row || {};
  const formattedCountry = `${code}-${name}`;

  // Parse the date-time string with Moment.js
  const dateTime = moment.parseZone(scheduleOnCustomerTimezone);

  // Get the UTC offset in minutes
  const offsetMinutes = dateTime.utcOffset();

  // Calculate hours and minutes from the total offset in minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesPart = Math.abs(offsetMinutes) % 60;

  // Format the offset into a string with a colon between hours and minutes
  // Ensure it includes the leading '+' or '-' and zero-pads single digit hours and minutes
  const formattedOffset =
    (offsetMinutes >= 0 ? '+' : '-') +
    String(offsetHours).padStart(2, '0') +
    ':' +
    String(offsetMinutesPart).padStart(2, '0');

  return (
    <View>
      <Text
        style={{
          ...rowText,
        }}>
        {formattedCountry}
      </Text>
      <Text
        style={{
          ...rowText,
        }}>
        {formattedOffset}
      </Text>
    </View>
  );
};
export const ActionsTable = props => {
  const {
    route: {
      params: {
        _id,
        showUnverified,
        addOnFilter,
        sort,
        campaign_name,
        sent_action,
        status,
        api,
        for_training,
      } = {},
    },
    navigation,
  } = props;
  const {auto_verified} = addOnFilter || {};
  const {
    sendParticularAction,
    sendActions,
    verifyActions,
    deleteCampaignActionRecord,
    actionsBatchRemove,
    verifyActionsBatchUpdate,
    useAddFinetuneFollowupMessage,
  } = leadControllers(props);
  const {editCampaignContactMessage} = messageControllers(props);

  let filter = {
    campaign_id: _id,
  };

  if (addOnFilter) {
    filter = {...filter, ...addOnFilter};
  }

  let addOnSort = {scheduledOn: 1};

  if (sort) {
    addOnSort = sort;
  }

  const invoke = useInvoke({method: 'put', eventSourceId: ['indexUpdated']});
  const headerActions = [];
  if (showUnverified) {
    headerActions.push({
      title: 'Verify actions',
      onPress: verifyActionsBatchUpdate,
      confirm: {
        title: 'Verify',
        message: 'Are you sure you want to verify these messages?',
        confirmText: 'Confirm',
      },
    });
  }
  headerActions.push({
    title: 'Delete',
    onPress: actionsBatchRemove,
    confirm: {
      title: 'Delete',
      message: 'Are you sure you want to delete these messages?',
      confirmText: 'Confirm',
    },
  });

  // else {
  //   headerActions.push({
  //     title: 'Send Now',
  //     onPress: sendActions,
  //   });
  // }

  let extraColumns = [];

  if (sent_action) {
    extraColumns.push({
      field: 'completedOn',
      type: 'date',
      header: 'Completed on',
      width: 150,
      formatOptions: {
        format: 'DD MMM YY HH:mm',
      },
    });
  } else if (showUnverified) {
    extraColumns.push({
      field: 'scheduledOn',
      type: 'date',
      header: 'Scheduled on',
      width: 150,
      formatOptions: {
        format: 'DD MMM YY HH:mm',
      },
    });
  } else {
    extraColumns.push({
      field: 'actualScheduledOn',
      header: 'Scheduled on',
      width: 180,
      render: ({row, styles}) => {
        return (
          <Text style={{...styles.rowText}}>
            {moment.utc(row.actualScheduledOn).format('DD MMM YY HH:mm')} (UTC)
          </Text>
        );
      },
    });
  }

  if (!auto_verified && !for_training) {
    extraColumns.push({
      header: 'Reason',
      field: 'reason',
      type: 'textArea',
      width: 200,
    });
  }

  // show campaign name in case of status inprocess - Ali 25/01/24
  if (status === 'inprocess') {
    extraColumns.push({
      header: 'Campaign Name',
      field: 'campaign_id.name',
      type: 'text',
      width: 200,
    });
  }

  const {data = [], ...restFetchProps} = useFetchData({
    api: api || '/campaignactions',
    filter: filter,
    eventSourceId: [
      'indexUpdated',
      'sendAction',
      'campaignActionEdited',
      'actionVerified',
      'verifyActions',
    ],
    fields: {
      _id: 1,
      name: 1,
      body: 1,
      sequence: 1,
      sequence_display: 1,
      send_after: 1,
      action: 1,
      personalize_body: 1,
      linkedin_profile: {email: 1},
      contact_id: {
        linkedin_url: 1,
        name: 1,
        first_name: 1,
        last_name: 1,
        designation: 1,
      },
      potential_customer: {
        name: 1,
        website: 1,
        country: {name: 1, code: 1},
        website_summary: 1,
        industry: {name: 1},
      },
      status: 1,
      scheduledOn: 1,
      scheduleOnCustomerTimezone: 1,
      schedule_without_message: 1,
      completedOn: 1,
      personalize_subject: 1,
      subject: 1,
      actualScheduledOn: 1,
      campaign_id: {name: 1},
      verified: 1,
      target_audience: 1,
      email_profile: 1,
      value_proposition_id: 1,
      reason: 1,
      auto_verified: 1,
    },
    sort: addOnSort,
  });

  return (
    <Table
      {...(filter?.actionType !== 'inmail-actions'
        ? {
            selection: {
              actions: headerActions,
            },
          }
        : {})}
      {...props}
      eventSourceId={['contactsImported']}
      onRowPress={({row}) => {
        navigation.navigate('unverified-campaign-action-form', {
          ...row,
          campaign_name,
          data,
          for_training,
        });
      }}
      data={data}
      {...restFetchProps}
      columns={[
        {
          header: '',
          type: 'number',
          field: 'sequence_display',
          width: 30,
        },
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 200,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 200,
        },
        {
          field: 'action',
          type: 'text',
          header: 'Action',
          width: 150,
        },
        {
          type: 'text',
          header: 'Country',
          render: RenderCountry,
          width: 100,
        },
        ...extraColumns,
        {
          field: 'personalize_body',
          type: 'textArea',
          header: 'Personalized Body',
        },
      ]}
      moreActions={
        for_training
          ? []
          : [
              {
                title: 'Edit',
                onPress: editCampaignContactMessage,
              },
              // {
              //   title: 'Send Now',
              //   onPress: sendParticularAction,
              //   visible: () => {
              //     const {actionType} = filter || {};
              //     return actionType !== 'inmail-actions';
              //   },
              // },
              {
                title: 'Delete',
                onPress: deleteCampaignActionRecord,
                visible: () => {
                  const {actionType} = filter || {};
                  return actionType !== 'inmail-actions';
                },
              },
              {
                title: 'Add Follow-up Message',
                onPress: useAddFinetuneFollowupMessage,
              },
            ]
      }
    />
  );
};

export const ErrorActionsTable = props => {
  let {
    route: {params: {campaign_id, addOnFilter} = {}},
    navigation,
    searchValue,
  } = props;
  const {removeError, viewLinkedinProfile, deleteCampaignActionRecord} =
    leadControllers(props);
  const {editCampaignContactMessage} = messageControllers(props);

  campaign_id = campaign_id || campaign_id?._id;

  let filter = {
    campaign_id: campaign_id,
    status: 'error',
  };

  // when user is checking error from  email profile list so filter is changed - Ali 25/01/24
  if (addOnFilter) {
    filter = {...filter, ...addOnFilter};
  }

  // show campaign name in case of error when coming from email profile - Ali 25/01/24
  let extraColumns = [];

  if (addOnFilter?.email_profile) {
    extraColumns.push({
      header: 'Campaign Name',
      field: 'campaign_id.name',
      type: 'text',
      width: 200,
    });
  }

  let addOnSort = {error_on: -1};

  return (
    <Table
      {...props}
      fields={{
        _id: 1,
        action: 1,
        linkedin_profile: {email: 1},
        contact_id: {linkedin_url: 1, name: 1},
        potential_customer: {name: 1, website: 1, country: {name: 1, code: 1}},
        status: 1,
        error: 1,
        error_on: 1,
        campaign_contact_id: 1,
        error_stack: 1,
        campaign_id: {name: 1},
      }}
      api="/campaignactions"
      filter={filter}
      search={searchValue}
      searchFields={['contact_id.name']}
      eventSourceId={[
        'indexUpdated',
        'sendAction',
        'campaignActionEdited',
        'actionVerified',
        'potentialcustomeradded',
        'verifyActions',
      ]}
      sort={addOnSort}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 200,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 200,
        },
        ...extraColumns,
        {
          field: 'action',
          type: 'text',
          header: 'Action',
          width: 250,
        },
        {
          field: 'error_on',
          type: 'date',
          header: 'Error on',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
        {
          field: 'error',
          type: 'text',
          header: 'Error',
        },
        {
          field: 'error_stack',
          type: 'text',
          header: 'Error Stack',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editCampaignContactMessage,
        },
        {
          title: 'Remove Error',
          onPress: removeError,
        },
        {
          title: 'Edit Contact',
          onPress: ({row}) => {
            navigation.navigate('edit-potential-customer', row);
          },
        },
        {
          title: 'Update Contact Status',
          onPress: ({row}) => {
            navigation.navigate('update-status', {
              _id: row.campaign_contact_id,
            });
          },
        },
        {title: 'Visit Profile', onPress: viewLinkedinProfile},
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete this action?',
            confirmText: 'Delete',
          },
          onPress: deleteCampaignActionRecord,
        },
      ]}
    />
  );
};
