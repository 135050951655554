import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';

export const RenderLinkedInUrl = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {contact_id = {}} = row || {};

  let {name, linkedin_url, first_name, last_name} = contact_id || {};
  const underline = linkedin_url ? {textDecoration: 'underline'} : void 0;
  if (linkedin_url) {
    linkedin_url =
      linkedin_url &&
      (linkedin_url.startsWith('https') || linkedin_url.startsWith('http'))
        ? linkedin_url
        : `https://${linkedin_url}`;
  }

  const displayName = name
    ? name
    : `${first_name || ''} ${last_name || ''}`.trim();
  return (
    <TouchableOpacity
      onPress={() => {
        linkedin_url && Linking.openURL(linkedin_url, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};

export const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {potential_customer = {}} = row || {};
  let {name = '', website = ''} = potential_customer || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  let displayName = name ? name : website ? website : '-';

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};
