import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';

const OrganizationGroup = createMenuNavigator();
const SetupNavigator = ({navigation}) => {
  return (
    <OrganizationGroup.Navigator data-navigator-id="OrganizationGroup">
      <OrganizationGroup.Group>
        <OrganizationGroup.Screen
          name="Setup"
          options={{
            label: 'Setup',
            onPress: () => {
              navigation.navigate('setup', {});
            },
          }}
        />
      </OrganizationGroup.Group>
    </OrganizationGroup.Navigator>
  );
};

export default SetupNavigator;
