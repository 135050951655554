import {useStyles} from '@unthinkable/react-theme';
import {CommunicationComponentStyles} from './communicationTheme';
import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import {
  RichText,
  RichTextRenderer,
} from '../../../components/form-editors/text/RichTextAreaInput';
import {ClientAttendeesComponent} from './ClientAttendeesStyle';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import PreText from '../../../components/chat/Pretext';

export const ContentHeader = ({value}) => {
  const theme = useStyles(CommunicationComponentStyles);
  if (!value) {
    return null;
  }
  const {
    contentHeader,
    communicationDate,
    communicationMode,
    positiveSentiment,
    negativeSentiment,
    neutralSentiment,
  } = theme;
  let sentimentStyle;
  let sentiment;
  if (value?.sentiment == 'positive') {
    sentiment = 'Positive Sentiment';
    sentimentStyle = positiveSentiment;
  } else if (value?.sentiment == 'negative') {
    sentiment = 'Negative Sentiment';
    sentimentStyle = negativeSentiment;
  } else if (value?.sentiment == 'neutral') {
    sentiment = 'Neutral Sentiment';
    sentimentStyle = neutralSentiment;
  }
  return (
    <RowWithSeparator gap={4} style={contentHeader}>
      {value?.date && (
        <Text style={communicationDate}>
          {moment(value?.date).format('DD MMM, YY')}
        </Text>
      )}
      {value?.time && <Text style={communicationDate}>{value?.time}</Text>}
      {value?.communication_mode && (
        <Text style={communicationMode}>{value?.communication_mode}</Text>
      )}
      {sentiment && <Text style={sentimentStyle}>{sentiment}</Text>}
      {value?.next_task_date && (
        <Text title={value?.next_task} style={communicationDate}>
          {moment(value?.next_task_date).format('DD MMM, YY')}
        </Text>
      )}
    </RowWithSeparator>
  );
};

export const CommunicationViewComponent = ({value}) => {
  if (!value) {
    return null;
  }
  const {containerStyle, subjectStyle, subjectContentStyle} = useStyles(
    CommunicationComponentStyles,
  );
  if (value?.subject) {
    return (
      <Col gap={8} style={containerStyle}>
        <ContentHeader value={value} />
        <ClientAttendeesComponent value={value} />
        <Row gap={6} style={{alignItems: 'center', paddingLeft: 12}}>
          <Text style={subjectStyle}>Subject:</Text>
          <Text style={subjectContentStyle}>{value?.subject}</Text>
        </Row>
        <View style={{paddingLeft: 12}}>
          <RichTextRenderer value={value?.description} />
          {/* <PreText isAnswer text={value?.description} /> */}
        </View>
      </Col>
    );
  } else if (!value.subject) {
    return (
      <Col gap={8} style={containerStyle}>
        <ContentHeader value={value} />
        <ClientAttendeesComponent value={value} />
        <View style={{paddingLeft: 12}}>
          <RichTextRenderer value={value?.description} />
          {/* <PreText isAnswer text={value?.description} /> */}
        </View>
      </Col>
    );
  } else {
    return null;
  }
};
