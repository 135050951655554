import React, {useEffect, useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useAppStateContext} from '../../../providers/AppState';
import {Text} from '@unthinkable/react-core-components';
import {useFetchData} from '../../../controllers/useFetchData';

const renderCount = dataCount => {
  return <Text>Count: {dataCount}</Text>;
};
export const DefineSegmentCriteria = props => {
  const {
    route: {params},
  } = props;

  let {row} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/import-potential-customer',
    eventSourceId: 'importedUserSegment',
  });

  const {post} = useAppStateContext();

  const [dataCount, setDataCount] = useState(0);

  const fetchData = async () => {
    const {data} = await post({
      uri: `/fetch-potential-customer-count`,
      props: {
        user_segment_id: row._id,
      },
    });
    setDataCount(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Form
      onSubmit={(data, {setFieldValue}) => {
        onSubmit(data, {setFieldValue});
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            count: dataCount,
          },
        };
      }}
      submitAction="Import"
      header={'Sync'}
      defaultValues={{user_segment_id: row._id}}
      layoutFields={[
        {
          field: 'count',
          render: () => renderCount(dataCount),
        },
        {
          type: 'checkbox',
          field: 'fetch_all',
          label: 'Fetch All',
          size: 4,
        },
        {
          field: 'limit',
          placeholder: 'Limit',
          label: 'Limit',
          type: 'text',
          size: 4,
          visible: ({values}) => !values.fetch_all,
        },
      ]}
      {...props}
    />
  );
};
