import React from 'react';
import {Table} from '../../../components/table/Table';
import {RenderSkills} from '../../employee/views/BenchResource';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

export const EmployeeWorkloadTable = props => {
  const {
    route: {params},
    navigation,
    onChangeFilter,
    searchValue,
    filterValues,
  } = props;
  let {params: filterParams = {}, filter = {}} = filterValues || {};
  return (
    <Table
      {...props}
      api="/employee-workload-from-tasks"
      limit={2000}
      onChangeFilter={onChangeFilter}
      params={{...params, ...filterParams}}
      search={searchValue}
      addOnFilter={filter}
      searchFields={['name']}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
        },
        {
          header: 'Skills',
          render: RenderSkills,
          width: 200,
          align: 'center',
        },
        {
          header: 'Recurring Workload',
          width: 150,
          field: 'workload',
          align: 'right',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
          onPress: ({row}) => {
            navigation.navigate('monthly-workload-overview', {
              ...params,
              employee: row,
            });
          },
        },
        {
          header: 'One Time Task',
          field: 'plantaskHours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('employee-tasks', {
              ...params,
              assigned_to: row.employee_user,
              selectedTab: 'organizationTasks',
            });
          },
        },
        {
          header: 'Reviewer Workload',
          field: 'reviewer_workload',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('monthly-workload-overview', {
              ...params,
              reviewer: row,
            });
          },
        },
        {
          header: 'Overdue Tasks',
          field: 'overDueTasksHour',
          type: 'number',
          width: 150,
          formatOptions: {
            maximumFractionDigits: 1,
          },
          onPress: ({row}) => {
            navigation.navigate('employee-tasks', {
              ...params,
              assigned_to: row.employee_user,
              selectedTab: 'organizationTasks',
            });
          },
        },
      ]}
    />
  );
};
