import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';

export const FinancialYearTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="FinancialYear"
      api={`/FinancialYears`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`financial-year-detail`, {
          financialYearId: row._id,
        });
      }}
      renderHeader={() => (
        <TableHeader
          title="Financial Year"
          actions={[
            <AddButton
              title="Add Financial Year"
              view="add-financial-year"
              // params={params}
            />,
          ]}
        />
      )}
      columns={[
        {
          type: 'text',
          field: 'financial_year',
          header: 'Financial Year',
        },
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'date',
          field: 'start_date',
          header: 'Start Date',
        },
        {
          type: 'date',
          field: 'end_date',
          header: 'End Date',
        },
      ]}
    />
  );
};
