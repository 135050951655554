import React from 'react';
import {Form} from '../../../components/form/Form';
import {Labels} from '../constants/Label';
import {useAppStateContext} from '../../../providers/AppState';

export const ExportFeaturesForm = props => {
  const {
    route: {params},
  } = props;

  const {download} = useAppStateContext();

  const onSubmit = props => {
    params.excelParams.filter.releasedOn = {
      $gte: props.from_date,
      $lte: props.to_date,
    };
    params.excelParams.sort = {releasedOn: -1};

    download({
      uri: `/generateExcel`,
      props: params,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      header={{
        title: `Export ${Labels.Feature}`,
      }}
      submitAction="Export"
      layoutFields={[
        {
          type: 'date',
          field: 'from_date',
          label: 'From',
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To',
        },
      ]}
    />
  );
};
