import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {BudgetRequestAccountTable, BudgetTable} from './BudgetRequestTables';
import {useAppStateContext} from '../../../providers/AppState';
import {
  AutomaticPaymentApproverTable,
  AutomaticPaymentFinanceApproverTable,
  AutomaticPaymentRequesterTable,
} from './AutomaticPaymentTables';

export const AutomaticPaymentRequesterTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Requested: {
      label: 'Requested',
      view: (
        <AutomaticPaymentRequesterTable
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{
            // status: 'Requested',
            requested_by: employee._id,
            $or: [
              {status: 'Requested'},
              {status: 'Approved', approver_status: 'Finance'},
            ],
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: '/automaticpayment/details',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        requested_by: employee._id,
        // status: 'Requested',
        $or: [
          {status: 'Requested'},
          {status: 'Approved', approver_status: 'Finance'},
        ],
        ...filterValues.filter,
      },
      limit: 500,
    },
    Active: {
      label: 'Active Payments',
      view: (
        <AutomaticPaymentRequesterTable
          tab={'Active'}
          filterValues={filterValues}
          addOnFilter={{status: 'Active', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/automaticpayment/details',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Active',
        ...filterValues.filter,
      },
    },
    Inactive: {
      label: 'Inactive Payments',
      view: (
        <AutomaticPaymentRequesterTable
          tab={'Inactive'}
          filterValues={filterValues}
          addOnFilter={{status: 'Inactive', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/automaticpayment/details',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Inactive',
        ...filterValues.filter,
      },
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <BudgetTable
          tab={'Rejected'}
          filterValues={filterValues}
          addOnFilter={{status: 'Rejected', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      api: '/automaticpayment/details',
      eventSourceId: 'budget',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Rejected',
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
          ]}
        />,
        <AddButton
          title="Automatic Payment"
          view={'add-automatic-payment-request'}
        />,
      ]}
    />
  );
};

export const AutoaticPaymentApproverTab = props => {
  let {route: {params} = {}, orgView} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  let addOnFilter = {};
  if (!orgView) {
    addOnFilter = {approver: employee._id};
  }
  const tabs = {
    Active: {
      label: 'Requested',
      view: (
        <AutomaticPaymentApproverTable
          tab={'ApproverActive'}
          addOnFilter={{status: 'Requested'}}
          orgView={orgView}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: `/automaticpayment/details`,
      addOnFilter: {
        ...filterValues.filter,
        status: 'Requested',
        ...addOnFilter,
      },
    },
    close: {
      label: 'Closed',
      view: (
        <AutomaticPaymentApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            status: {$in: ['Approved', 'Rejected', 'Active', 'Inactive']},
            ...filterValues.filter,
          }}
          orgView={orgView}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: `/automaticpayment/details`,
      addOnFilter: {
        status: {$in: ['Approved', 'Rejected', 'Active', 'Inactive']},
        ...filterValues.filter,
        ...addOnFilter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
            {
              type: 'autoComplete',
              label: 'Status',
              options: ['Approved', 'Rejected'],
              field: 'status',
            },
          ]}
        />,
      ]}
    />
  );
};

export const FinanceAutomaticPaymentTab = props => {
  let {routes: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let currentdate = new Date();
  let monthFirstDate = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth(),
    1,
  );

  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <AutomaticPaymentFinanceApproverTable
          addOnFilter={{
            status: 'Approved',
            approver_status: 'Finance',
            // from_date: {$gte: monthFirstDate},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/automaticpayment/details',
      addOnFilter: {
        status: 'Approved',
        approver_status: 'Finance',
        // from_date: {$gte: monthFirstDate},
        ...filterValues.filter,
      },
      limit: 500,
    },
    // Expired: {
    //   label: 'Expired',
    //   view: (
    //     <AutomaticPaymentFinanceApproverTable
    //       addOnFilter={{
    //         status: 'Requested',
    //         // from_date: {$lt: monthFirstDate},
    //         ...filterValues.filter,
    //       }}
    //       {...props}
    //     />
    //   ),
    //   api: '/budget/budgetdetail',
    //   addOnFilter: {
    //     status: 'Requested',
    //     // from_date: {$lt: monthFirstDate},
    //     ...filterValues.filter,
    //   },
    //   limit: 500,
    // },
    close: {
      label: 'Closed',
      view: (
        <AutomaticPaymentFinanceApproverTable
          tab={'ApproverClosed'}
          addOnFilter={{
            $or: [
              {
                status: 'Approved',
                approver_status: 'Finance',
                approved_by_finance_date: {$exists: true},
              },
              {
                status: {$in: ['Rejected', 'Active', 'Inactive']},
                approver_status: 'Finance',
              },
            ],
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: `/automaticpayment/details`,
      addOnFilter: {
        $or: [
          {
            status: 'Approved',
            approver_status: 'Finance',
            approved_by_finance_date: {$exists: true},
          },
          {
            status: {$in: ['Rejected', 'Active', 'Inactive']},
            approver_status: 'Finance',
          },
        ],
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
          ]}
        />,
      ]}
    />
  );
};
