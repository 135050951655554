import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const SelectedArchiveTaskForm = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds = []} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/bulk-archive-tasks',
    eventSourceId: 'Task',
  });

  return (
    <Form
      header={{title: 'Archive Task'}}
      submitAction="Archive"
      onSubmit={onSubmit}
      defaultValues={{selectedIds}}
      layoutFields={[
        {
          field: 'archive_reason',
          type: 'textArea',
          label: 'Reason',
          required: true,
        },
      ]}
    />
  );
};
