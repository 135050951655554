import React from 'react';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {
  ResignationActiveTable,
  ResignationAllGroupTable,
  ResignationClosedGroupTable,
  ResignationWithdrawnGroupTable,
  // ResignationCloseTable,
} from './ResignationTables';
import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate, useFilter} from '@unthinkable/react-filters';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const AllResginationTabs = props => {
  let {
    route: {params: routeParams},
  } = props;
  const {department} = routeParams;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
    filter: {
      department: department,
    },
  });
  const {params = {}, filter} = filterValues;
  let addOnFilter = {};
  addOnFilter = {...addOnFilter, ...filter};
  const tabs = {
    All: {
      label: 'All',
      view: (
        <ResignationAllGroupTable
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),

      params: {
        ...params,
      },
      api: '/employees/all-resigned',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'expected_relieving_date',
              label: 'Relieving Date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'Regrettable', value: 'Regrettable'},
                {label: 'Desirable', value: 'Desirable'},
              ],
              field: 'desireable_status',
              label: 'Desireable Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'exit_type',
              label: 'Type',
              options: ['Involuntary', 'Voluntary'],
            },
          ]}
        />,
      ],
    },
    Active: {
      label: 'Active',
      view: (
        <ResignationActiveTable
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),
      params: filterValues?.filter,
      api: '/employees/active-resignations',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'expected_relieving_date',
              label: 'Relieving Date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'Regrettable', value: 'Regrettable'},
                {label: 'Desirable', value: 'Desirable'},
              ],
              field: 'desireable_status',
              label: 'Desireable Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'exit_type',
              label: 'Type',
              options: ['Involuntary', 'Voluntary'],
            },
          ]}
        />,
      ],
    },
    Closed: {
      label: 'Closed',
      view: (
        <ResignationClosedGroupTable
          filterValues={filterValues}
          searchValue={searchValue}
          {...props}
        />
      ),
      params: {
        ...params,
      },
      api: '/employees/closed-resigned',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            // {
            //   type: 'date',
            //   field: 'resign_date_new',
            //   label: 'Resign Date',
            // },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'Regrettable', value: 'Regrettable'},
                {label: 'Desirable', value: 'Desirable'},
              ],
              field: 'desireable_status',
              label: 'Desireable Status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'exit_type',
              label: 'Type',
              options: ['Involuntary', 'Voluntary'],
            },
          ]}
        />,
      ],
    },
    Withdraw: {
      label: 'Withdraw',
      view: (
        <ResignationWithdrawnGroupTable
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),

      params: {
        ...params,
      },
      api: '/employees/all-resign-withdraw',
      addOnFilter: {...addOnFilter},
      search: searchValue,
      searchFields: ['name'],
      actions: [
        // <SearchFilter
        //   value={searchValue}
        //   onChangeFilter={onChangeFilter}
        //   placeholder="Search"
        // />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'employee',
            },
            {
              type: 'date',
              field: 'resign_date_new',
              label: 'Resign Date',
            },
            {
              type: 'date',
              field: 'expected_relieving_date',
              label: 'Relieving Date',
            },
          ]}
        />,
      ],
    },
  };
  console.log('props = ', props);
  const {isLinkTab, ...restProps} = props;
  return (
    <TabView
      tabs={tabs}
      params={params}
      // actions={[
      //   <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
      //   <GroupFilter
      //     filterValues={filterValues}
      //     applyFilter={applyFilter}
      //     filters={[
      //       {
      //         type: 'date',
      //         field: 'resign_date_new',
      //         label: 'Resign Date',
      //       },
      //       {
      //         type: 'autoComplete',
      //         field: 'exit_type',
      //         label: 'Type',
      //         options: ['Involuntary', 'Voluntary'],
      //       },
      //     ]}
      //   />,
      // ]}
      // {...restProps}
    />
  );
};
