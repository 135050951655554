import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {OnlineFundTransferTable} from './OnlineFundTransferTable';

export const OnlineFundTransferTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, applyFilter} = useFilter({});
  const tabs = {
    ToApprove: {
      label: 'To approve',
      view: (
        <OnlineFundTransferTable
          addOnFilter={{
            transaction_status: 'New',
            ...filterValues.filter,
          }}
          tab="ToApprove"
          {...props}
        />
      ),
      api: '/fundtransfer',
      addOnFilter: {
        transaction_status: 'New',
        ...filterValues.filter,
      },
      eventSourceId: 'OnlineFundTransfer',
    },
    Approved: {
      label: 'Approved',
      view: (
        <OnlineFundTransferTable
          addOnFilter={{
            transaction_status: {
              $in: ['Pending maker approval', 'Pending authoriser Appoval'],
            },
            ...filterValues.filter,
          }}
          tab="Approved"
          {...props}
        />
      ),
      api: '/fundtransfer',
      addOnFilter: {
        transaction_status: {
          $in: ['Pending maker approval', 'Pending authoriser Appoval'],
        },
        ...filterValues.filter,
      },
      eventSourceId: 'OnlineFundTransfer',
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <OnlineFundTransferTable
          addOnFilter={{
            transaction_status: 'Rejected',
            ...filterValues.filter,
          }}
          tab="Rejected"
          {...props}
        />
      ),
      api: '/fundtransfer',
      addOnFilter: {
        transaction_status: 'Rejected',
        ...filterValues.filter,
      },
      eventSourceId: 'OnlineFundTransfer',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'date',
              label: 'Date',
            },
            {
              type: 'autoComplete',
              field: 'from_fund',
              label: 'From Bank',
              api: '/banks',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              field: 'to_fund',
              label: 'To Bank',
              api: '/banks',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
