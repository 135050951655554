import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const KPITargetInputForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/kpitargets',
    eventSourceId: 'kpitargets',
    ...props,
  });
  return (
    <Form
      api={`/kpitargets/${row?._id}`}
      fields={{
        kpi_id: {name: 1},
        target_value: 1,
        start_date: 1,
        end_date: 1,
        status: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add KPI Target'}}
      layoutFields={[
        {
          label: 'KPI',
          field: 'kpi_id',
          type: 'autoComplete',
          api: `/keyperformanceindicators`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'name',
          size: 'medium',
          required: true,
        },
        {
          label: 'Target value',
          type: 'text',
          field: 'target_value',
        },
        {
          label: 'Start date',
          type: 'date',
          field: 'start_date',
          required: true,
          responsive: 'sm',
        },
        {
          label: 'End date',
          type: 'date',
          field: 'end_date',
          required: true,
          responsive: 'sm',
        },
      ]}
      {...props}
    />
  );
};

export const EditKPITargetInputForm = props => {
  return (
    <KPITargetInputForm
      header={{title: 'Edit KPI Target'}}
      mode="edit"
      {...props}
    />
  );
};
