import React from 'react';
import {Rating} from '@unthinkable/react-star-rating';
import { InputLabel } from '@unthinkable/react-input';

export const RatingRenderer = (props) => {
  return (
    <InputLabel {...props}>
      <Rating {...props} readOnly={true} />
    </InputLabel>
  );
};
