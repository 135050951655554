import React from 'react';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ReferralCandidateForm = props => {
  const {
    route: {
      params: {job_opening},
    },
  } = props;
  const {
    user: {
      employee: {_id: employeeId},
    },
  } = useAppStateContext();

  const {onSubmit} = useFormSubmit({
    uri: '/referralCandidates',
    eventSourceId: 'ReferralCandidate',
  });

  return (
    <Form
      header={{
        title: 'Refer Candidate',
        secondaryTitle: job_opening?.position_title?.name,
      }}
      defaultValues={{
        job_opening: job_opening?._id,
        referred_by: employeeId,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
          size: 6,
        },
        {
          label: 'Email',
          field: 'email',
          type: 'text',
          required: true,
          size: 6,
        },
        {
          label: 'Mobile',
          field: 'mobile',
          type: 'text',
          required: true,
          size: 6,
        },
        {
          palceholder:"Upload CV",
          field: 'attachment',
          type: 'file',
          required: true,
          size: 6,
        },
      ]}
    />
  );
};

export const ReferralCandidateCommentForm = props => {
  const {
    route: {
      params: {referral_candidate},
    },
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/referralCandidates',
    eventSourceId: 'ReferralCandidate',
  });

  return (
    <Form
      header={{
        title: 'Add Comment',
        secondaryTitle: referral_candidate.name,
      }}
      mode="edit"
      api={'/referralCandidates/' + referral_candidate._id}
      fields={{
        comment: 1,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Comment',
          field: 'comment',
          type: 'textArea',
        },
      ]}
    />
  );
};

export const AddReferralToOtherOpening = props => {
  const {
    route: {
      params: {referral_candidate},
    },
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/addReferralToOpening',
    eventSourceId: 'ReferralCandidate',
  });

  return (
    <Form
      header={{
        title: 'Add To Another Opening',
        secondaryTitle: referral_candidate.name,
      }}
      mode="edit"
      api={'/referralCandidates/' + referral_candidate._id}
      fields={{
        _id: 1,
        name: 1,
        email: 1,
        mobile: 1,
        attachment: 1,
        job_opening: {
          position_title: {
            name: 1,
          },
        },
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'New Job Opening',
          field: 'job_opening',
          type: 'autoComplete',
          api: '/jobOpenings',
          suggestionField: 'position_title.name',
          required: true,
          filter: {
            status: 'Active',
          },
          fields: {
            position_title: {
              name: 1,
            },
          },
          getDisplayValue: ({value}) => value?.position_title?.name,
        },
      ]}
    />
  );
};
