import {ModalViewStyles} from '../../../components/modal/theme';

export const UserProfileStyles = ({theme}) => {
  const {colors, icons, fonts, shadows} = theme;

  return {
    LogoutIcon: icons.LogOut,
    container: {
      gap: 2,
      overflow: 'hidden',
    },
    itemContainer: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 6,
      backgroundColor: colors.BACKGROUND,
      alignItems: 'center',
      gap: 12,
      overflow: 'hidden',
    },
    label: {...fonts.BODY3, color: colors.NEUTRAL_MEDIUM},
    itemText: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    logoutText: {
      ...fonts.BODY2,
      color: colors.ERROR_HIGH,
      flex: 1,
    },
    logoutIconStyle: {
      height: 20,
      width: 20,
    },
    modalStyles: {
      ...ModalViewStyles({theme}),
      container: {
        padding: 8,
        backgroundColor: colors.SURFACE2,
        borderRadius: 6,
        margin: 8,
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        ...shadows.M,
      },
    },
  };
};
