import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {Col, Image, Text, View} from '@unthinkable/react-core-components';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import images from '../../../images';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupBy} from '../../../components/table/GroupBy';
import {randomColor} from '../../../utils/util';
import {useInvoke} from '../../../controllers/useInvoke';
import {Durations} from './components/Durations';
import moment from 'moment';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

const {GreenBackGroundAdd} = images;

export const deleteOrderResourceRequest = ({invoke}) => ({
  title: 'Reject Request',
  confirm: {
    title: 'Reject Request',
    message: 'Do you want to reject this request?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/orderresourcerequests/${row?._id}`,
    });
  },
  visible: ({row}) => {
    return row?.status === 'pending';
  },
});

export const AllocationsRequestsGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    groupBy,
    tab,
  } = props;
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'orderresourcerequests',
  });

  return (
    <Table
      params={{...params, tab}}
      addOnFilter={{...filterValues?.filter}}
      eventSourceId={[
        'orderresourcerequests',
        'replacement',
        'employeeAssigned',
      ]}
      sort={{_id: -1}}
      api={`/allocationRequests`}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
          render: ({row}) => {
            return (
              <>
                <Tag value={row?.project?.project} color={randomColor()} />
                <PrimaryColumnCard
                  items={[{value: row?.order?.order_number}]}
                />
              </>
            );
          },
          minWidth: 240,
        },
        {
          header: 'Skill',
          field: 'tool.name',
        },
        {
          header: 'Start Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          visible: tab !== 'inprogress',
        },
        {
          header: 'End Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          visible: tab !== 'inprogress',
        },
        {
          header: 'Named Employee',
          field: 'notes',
          visible: tab == 'due' || tab == 'completed',
        },
        {
          header: 'Req. Type',
          width: 120,
          render: ({row, styles: {rowText = {}} = {}}) => {
            let modifyType = '';
            if (row?.status === 'allocated' || row?.status === 'pending') {
              modifyType = 'New';
            } else {
              modifyType = 'Replacement';
            }
            return (
              <PrimaryColumnCard
                primaryTitle={modifyType}
                items={[
                  {
                    value: row?.type,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'To Replace',
          minWidth: 200,
          render: ({row}) => {
            return (
              row?.employee && (
                <>
                  <Col style={{flexDirection: 'row'}} gap={10}>
                    <UserAvatar value={row?.employee} size={32} />
                    <PrimaryColumnCard
                      primaryTitle={row?.employee?.name}
                      items={[
                        {
                          value: row?.employee?.employee_code,
                        },
                        {
                          value: `${row?.employee?.experience} yr`,
                        },
                      ]}
                    />
                  </Col>
                  <View style={{marginLeft: 40}}>
                    <PrimaryColumnCard
                      items={[
                        {
                          value:
                            row?.employee?.expected_relieving_date &&
                            `Exp. relieving on ${moment(
                              row?.employee?.expected_relieving_date,
                            )?.format('DD MMM YY')}`,
                        },
                      ]}
                    />
                  </View>
                </>
              )
            );
          },
        },
        {
          header: 'Assign Date',
          field: 'assign_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          visible: tab == 'inprogress',
        },
        {
          header: 'Target Date',
          field: 'target_release_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          visible: tab == 'inprogress' || tab == 'due',
        },
        {
          header: tab == 'due' ? 'Assign' : 'Assigned',
          width: tab == 'due' ? 80 : 160,
          render: ({row}) => {
            const {assignments_tandm, assignments_fixed} = row;
            let assignments = {};
            if (assignments_tandm?.employee?._id) {
              assignments = assignments_tandm;
            }
            if (assignments_fixed?.employee?._id) {
              assignments = assignments_fixed;
            }
            return (row?.replacement?.length || assignments?.employee?._id) &&
              row?.status !== 'pending' ? (
              row?.replacement?.length ? (
                row?.replacement?.map(item => (
                  <UserAvatarWithNameAndEmail
                    name={item?.name}
                    color={item?.color}
                    values={[
                      {value: item?.employee_code},
                      {value: `${item?.experience} yrs`},
                    ]}
                  />
                ))
              ) : (
                <UserAvatarWithNameAndEmail
                  name={assignments?.employee?.name}
                  color={assignments?.employee?.color}
                  values={[
                    {value: assignments?.employee?.employee_code},
                    {value: `${assignments?.employee?.experience} yrs`},
                  ]}
                />
              )
            ) : (
              tab !== 'completed' && (
                <Image
                  source={GreenBackGroundAdd}
                  style={{width: 36, height: 24}}
                />
              )
            );
          },
          onPress: ({row}) => {
            if (
              row?.completed_status !== 'Successful' &&
              row?.status !== 'allocated'
            ) {
              if (row.status !== 'pending') {
                navigation.navigate('update-employee-as-replacement', {
                  row,
                  formResign: true,
                  replacement_id: row._id,
                });
              } else {
                let {
                  order = {},
                  skill_required = {},
                  hours: employee_per_day_working_hour,
                  skill_requirement,
                  tool,
                  from_date,
                  to_date,
                  project = {},
                  project_id = {},
                } = row || {};
                let order_allocations = {
                  skill_required,
                  order,
                  skill_requirement,
                  recurring_till: order?.recurring_till,
                  order_date: order?.order_date,
                  employee_per_day_working_hour,
                  product: order?.product,
                  tool,
                  order_date: from_date,
                  recurring_till: to_date,
                  project: project || project_id,
                  new_project_type: project?.new_project_type,
                };
                navigation.navigate(
                  // order?.order_type == 'r'
                  //   ? 'order-resource-assignment'
                  //   : 'create-fixed-order-assignment',
                  'order-resource-assignment',
                  {
                    order_allocations,
                    order_resource_request: row?._id,
                  },
                );
              }
            }
          },
        },
        {
          header: 'Status',
          render: ({row, styles: {rowText = {}} = {}}) => {
            let modifyStatus = '';
            let color = '';
            if (row?.status === 'pending') {
              modifyStatus = 'New';
              color = '#FCF4D4';
            }
            if (row?.status === 'due') {
              modifyStatus = 'Due';
              color = '#FFEFE0';
            }
            if (row?.status === 'inprogress') {
              modifyStatus = 'In Progress';
              color = '#DFFBF6';
            }
            if (
              row?.completed_status === 'Successful' ||
              row?.status === 'allocated'
            ) {
              modifyStatus = 'Successful';
              color = '#F2ECFD';
            }
            if (row?.completed_status == 'Failed') {
              modifyStatus = 'Failed';
              color = '#FFECEB';
            }
            return <Tag value={modifyStatus} color={color} />;
          },
          visible: tab == 'completed',
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Complete',
          visible: ({row}) => {
            if (row.status === 'inprogress' || row.status === 'due') {
              return true;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('update-replacement-status', {row});
          },
        },
        deleteOrderResourceRequest({invoke, params}),
        {
          title: 'Update Replacement Type',
          onPress: ({row}) => {
            navigation.navigate('replacement-type-form', {
              row,
            });
          },
          visible: ({row}) => {
            return row.status === 'inprogress' || row.status === 'due';
          },
        },
      ]}
    />
  );
};

export const AllocationsRequestsTab = props => {
  const {route: {params} = {}, employeeMenu = false} = props;
  const {department} = params;
  const filterInfo = useFilter({
    // filter: {
    //   'project.department': department,
    //   'project.project_status': 'a',
    // },
  });
  const groupBy = useGroupBy({
    options: [
      {
        label: 'Project',
        field: 'project',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem
                  value={row?._children?.[0]?.project?.project}
                />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Type',
        field: 'status',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem
                  value={
                    row?._children?.[0]?.status == 'pending'
                      ? `New (${row?._children?.length})`
                      : `Due (${row?._children?.length})`
                  }
                />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const {filterValues} = filterInfo;
  let tabs = {};
  tabs.allocations_requests = {
    label: 'Due',
    view: (
      <AllocationsRequestsGroupTable
        groupBy={groupBy}
        filterValues={filterValues}
        tab="due"
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
    },
    params: {...params, tab: 'due'},
    api: '/allocationRequests',
    limit: 5000,
    eventSourceId: ['orderresourcerequests', 'replacement', 'employeeAssigned'],
  };

  tabs.allocations_inprogress = {
    label: 'In Progress',
    view: (
      <AllocationsRequestsGroupTable
        groupBy={groupBy}
        filterValues={filterValues}
        tab="inprogress"
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
    },
    params: {...params, tab: 'inprogress'},
    api: '/allocationRequests',
    limit: 5000,
    eventSourceId: ['orderresourcerequests', 'replacement', 'employeeAssigned'],
  };

  tabs.completed = {
    label: 'Completed',
    view: (
      <AllocationsRequestsGroupTable
        groupBy={groupBy}
        filterValues={filterValues}
        tab="completed"
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
    },
    params: {...params, tab: 'completed'},
    api: '/allocationRequests',
    limit: 5000,
    eventSourceId: ['orderresourcerequests', 'replacement', 'employeeAssigned'],
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupBy {...groupBy} />,
        <GroupFilter
          {...filterInfo}
          filters={[
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project',
            },
            {
              type: 'Replacement Categories',
              label: 'Replacement Categories',
              placeholder: 'Select',
              field: 'type',
              type: 'autoComplete',
              options: [
                'Negative Margin',
                'Swapping',
                'Resignation',
                'Anchor',
                'Long Leave',
              ],
            },
            {
              type: 'autoComplete',
              label: 'Project Type',
              placeholder: 'Select',
              options: [
                {label: 'Fixed', value: 'f'},
                {label: 'T&M', value: 'tm'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              valueField: 'label',
              field: 'project.projecttype',
            },
          ]}
        />,
      ]}
    />
  );
};
