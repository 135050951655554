import React from 'react';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {getZeroTimeDate} from '@unthinkable/react-filters/src/DateUtility';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {
  BillableEmployeeTeamList,
  LessAllocationTeamList,
  NoAllocationTeamList,
  NonBillableEmployeeTeamList,
  OtherEmployeeTeamList,
  TrainingTeamList,
} from './EmployeeTeamsList';
import {AddButton} from '../../../components/button/AddButton';
import moment from 'moment';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

export const getCurrentDay = () => {
  let filterOf = 'Day';
  let from = moment().startOf(filterOf).toDate();
  let to = moment().endOf(filterOf).toDate();
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);

  return {from, to, filterOf};
};

export const EmployeeTeamTabs = props => {
  const {route, fromMyView, user} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });

  const {filter: filterValue = {}, params} = filterValues;
  let filter = {};
  if (fromMyView) {
    filter = {
      performance_manager: user?.employee?._id,
    };
  }
  const {filter: filterForPartialAllocation} = filterValue || {};
  const {tool = {}, ...rest} = filterForPartialAllocation || {};
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    billable: {
      label: 'Billable Project',
      view: (
        <BillableEmployeeTeamList
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filterValues={filterValues}
          params={params}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportBillableTeamReport',
              props: {
                uri: '/employees/billableteam',
                parameters: {
                  params,
                  addOnFilter: {...filter, ...filterValue},
                },
              },
            });
          }}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Managed By',
            },
            {
              label: 'Type',
              field: 'projecttype',
              placeholder: 'Select Project Type',
              type: 'autoComplete',
              options: [
                {value: 'f', label: 'Fixed'},
                {value: 'i', label: 'Internal'},
                {value: 'tm', label: 'T & M'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
            {
              label: 'Tool',
              field: 'tool',
              placeholder: 'Select Tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
            },
          ]}
        />,
      ],
      eventSourceId: 'EmployeeTeam',
      api: '/employees/billableteam',
      search: searchValue,
      searchFields: 'name',
      addOnFilter: {...filter, ...filterValue},
      params: params,
    },
    nonBillable: {
      label: 'Non-Billable Project',
      view: (
        <NonBillableEmployeeTeamList
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filterValues={filterValues}
          params={params}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportNonBillableTeamReport',
              props: {
                uri: '/employees/nonbillableteam',
                parameters: {
                  params,
                  addOnFilter: {...filter, ...filterValue},
                },
              },
            });
          }}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Tool',
              field: 'tool',
              placeholder: 'Select Tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
            },
          ]}
        />,
      ],
      eventSourceId: 'EmployeeTeam',
      api: '/employees/nonbillableteam',
      search: searchValue,
      limit: 2000,
      searchFields: 'name',
      addOnFilter: {...filter, ...filterValue},
      params: params,
    },
    others: {
      label: 'Non Project',
      view: (
        <OtherEmployeeTeamList
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          params={{...params}}
          filterValues={filterValues}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportNonProjectTeamReport',
              props: {
                uri: '/employees/nonprojectteams',
                parameters: {
                  params,
                  addOnFilter: {...filter},
                },
              },
            });
          }}
        />,
        <AddButton {...props} title={'Team'} view="add-employee-team" />,
      ],
      api: '/employees/nonprojectteams',
      search: searchValue,
      searchFields: 'name',
      addOnFilter: {...filter},
      params: params,
    },
    training: {
      label: 'Training',
      view: (
        <TrainingTeamList
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          params={{...params}}
          filterValues={filterValues}
        />
      ),
      api: '/employees/trainingteams',
      search: searchValue,
      searchFields: 'name',
      addOnFilter: {...filter},
      params: params,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportTrainingTeamReport',
              props: {
                uri: '/employees/trainingteams',
                parameters: {
                  params,
                  addOnFilter: {...filter, ...filterValue},
                },
              },
            });
          }}
        />,
      ],
    },
    NoAllocation: {
      label: 'No Allocation',
      eventSourceId: ['NoAllocationTeam'],
      view: (
        <NoAllocationTeamList
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filterValues={filterValues}
          params={{...params}}
          fromMyView={fromMyView}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportNoAllocationTeamReport',
              props: {
                uri: '/employees/no-allocation',
                parameters: {
                  params,
                  addOnFilter: {...filter, ...filterValue},
                },
              },
            });
          }}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              size: 6,
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
      ],
      api: '/employees/no-allocation',
      limit: 2000,
      search: searchValue,
      searchFields: 'name',
      params: params,
      addOnFilter: {...filter, ...filterValue},
    },
    Lessallocation: {
      label: 'Partial Allocation',
      eventSourceId: ['lessAllocationTeam'],
      view: (
        <LessAllocationTeamList
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filterValues={filterValues}
          params={{...params}}
          fromMyView={fromMyView}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportLessAllocationTeamReport',
              props: {
                uri: '/employees/lessallocation',
                parameters: {
                  params,
                  addOnFilter: {...filter, ...filterValue},
                },
              },
            });
          }}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              size: 6,
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
      ],
      api: '/employees/lessallocation',
      limit: 2000,
      search: searchValue,
      searchFields: 'name',
      params: params,
      addOnFilter: {...filter, ...rest},
    },
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
