import React from 'react';
import {Form} from '../../../../components/form/Form';
import {PROJECT_QA_TEST_CASE_TYPE_ID} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';

const qaTestCasesFields = ({params}) => {
  const {project} = params;

  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    title: {
      label: 'Title',
      field: 'name',
      type: 'text',
      required: true,
    },
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      size: 6,
      readOnly,
    }),
    type: {
      label: 'Type',
      field: 'type',
      type: 'autoComplete',
      options: [
        'Functional',
        'Non-Functional',
        'UI/UX',
        'Security',
        'Performance',
        'Responsive',
        'Cross-Browser',
        'API',
        'Other',
      ],
      size: 6,
    },
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    description: {
      field: 'description',
      label: 'Description',
      placeholder: 'Write description here...',
      type: 'richText',
    },
    preConditions: {
      field: 'pre_conditions',
      label: 'Pre Conditions',
      placeholder: 'Write pre conditions here...',
      type: 'richText',
      minHeight: 200,
    },
    steps: {
      field: 'steps',
      label: 'Steps',
      type: 'richText',
      placeholder: 'Write steps here...',
      minHeight: 200,
    },
    remarks: {
      label: 'Remarks',
      field: remarksField,
      placeholder: 'Write remarks here...',
      type: 'richText',
      minHeight: 200,
    },
  };
};

export const AddQATestCaseForm = props => {
  const {
    route: {params},
  } = props;
  const {project, module, searchValue, afterSubmit, feature} = params;

  const fields = qaTestCasesFields({params});

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    name: searchValue,
    project_id: project?._id,
    module_id: module,
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
    feature_id: feature?._id,
  };

  return (
    <Form
      header="Add Test Case"
      onSubmit={createAsset}
      submitAction="Save"
      afterSubmit={afterSubmit}
      beforeSubmit={requiredValidation}
      defaultValues={initialValues}
      type={'tab'}
      tabs={{
        basicInfo: {
          label: 'Basic Info',
          layoutFields: [
            fields.title,
            fields.module(),
            fields.type,
            fields.owner,
            fields.description,
          ],
        },
        preConditions: {
          label: 'Pre Conditions',
          layoutFields: [fields.preConditions],
        },
        steps: {
          label: 'Steps',
          layoutFields: [fields.steps],
        },
      }}
      {...props}
    />
  );
};

export const UpdateQATestCaseForm = props => {
  const {
    route: {params},
  } = props;

  const {row, readOnly, feature} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  const fields = qaTestCasesFields({params});

  return (
    <Form
      key={row?._id}
      header={{title: 'TestCase Detail'}}
      mode="edit"
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      isDualMode
      readOnly={readOnly}
      data={row}
      submitAction="Save"
      type={'tab'}
      tabs={{
        basicInfo: {
          label: 'Basic Info',
          layoutFields: [
            fields.title,
            fields.module({readOnly: true}),
            fields.type,
            fields.owner,
            fields.description,
          ],
        },
        preConditions: {
          label: 'Pre Conditions',
          layoutFields: [fields.preConditions],
        },
        steps: {
          label: 'Steps',
          layoutFields: [fields.steps],
        },
        remarks: {
          label: 'Remarks',
          layoutFields: [fields.remarks],
        },
      }}
      {...props}
    />
  );
};

export const CopyTestCaseForm = props => {
  const {
    route: {params},
  } = props;
  const {project, testCase, feature} = params;
  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
  });
  const {name, type, owner_id, description, pre_conditions, steps} = testCase;

  const defaultValues = {
    status: 'active',
    project_id: project?._id,
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
    feature_id: feature?._id,
    name,
    type,
    owner_id,
    description,
    pre_conditions,
    steps,
  };

  const fields = qaTestCasesFields({params});

  return (
    <Form
      header={{title: 'Copy TestCase', secondaryTitle: testCase.name}}
      onSubmit={createAsset}
      beforeSubmit={requiredValidation}
      submitAction="Save"
      type={'tab'}
      defaultValues={defaultValues}
      tabs={{
        basicInfo: {
          label: 'Basic Info',
          layoutFields: [
            fields.title,
            fields.module({readOnly: true}),
            fields.type,
            fields.owner,
            fields.description,
          ],
        },
        preConditions: {
          label: 'Pre Conditions',
          layoutFields: [fields.preConditions],
        },
        steps: {
          label: 'Steps',
          layoutFields: [fields.steps],
        },
      }}
      {...props}
    />
  );
};
