import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {CustomerOverviewDashboard} from './CustomerDashboardOverview';
import {RecieptTab} from '../../order/views/RecieptTab';
import {PendingInvoiceTab} from '../../order/views/DueInvoiceTab';
import {CustomerCurrencyTable} from './currencyTable';

const CustomerMenu = createMenuNavigator();

const CustomerNavigator = props => {
  const {route: {params} = {}} = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});

  return (
    <CustomerMenu.Navigator
      canAccess={canAccess}
      data-navigator-id="CustomerGroup"
      {...props}>
      <CustomerMenu.Screen
        data-resource-id="CustomerOverview"
        name="CustomerOverview"
        props={{user: user, ...props}}
        component={CustomerOverviewDashboard}
        options={{
          label: 'Overview',
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="Orders"
        name="Orders"
        props={{user: user, ...props}}
        options={{
          label: 'Orders',
          onPress: () => {
            props.navigation.navigate('order-menu', {
              ...params,
            });
          },
        }}
      />
      {/* <CustomerMenu.Screen
        data-resource-id="Project"
        props={{...props.route.params, scope: 'product'}}
        options={{
          label: 'Projects',
          onPress: () => {
            props.navigation.navigate('organization-projects-menu', {
              ...props.route.params,
            });
          },
        }}
      /> */}
      <CustomerMenu.Screen
        data-resource-id="Projections"
        name="Projections"
        props={{user: user, ...props}}
        options={{
          label: 'Projections',
          onPress: () => {
            props.navigation.navigate('projections-menu', {
              ...params,
            });
          },
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="RevenueInvoice"
        name="RevenueInvoice"
        options={{
          label: 'Invoices',
          onPress: () => {
            props.navigation.navigate('invoice-menu', {
              ...params,
            });
          },
        }}
      />
      {/* <CustomerMenu.Screen
        data-resource-id="InvoiceDue"
        name="InvoiceDue"
        component={PendingInvoiceTab}
        options={{
          label: 'Due Invoicing',
        }}
      /> */}
      <CustomerMenu.Screen
        data-resource-id="Receipts"
        name="Receipts"
        component={RecieptTab}
        options={{
          label: 'Receipts',
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="RevneuReports"
        name="Reports"
        props={{user: user, ...props}}
        options={{
          label: 'Reports',
          onPress: () => {
            props.navigation.navigate('customer-reports-menu', {
              ...params,
            });
          },
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="ExchangeRateCurrency"
        name="Exchange Rate"
        component={CustomerCurrencyTable}
        options={{
          label: 'Exchange Rate Setup',
        }}
      />
      {/* <CustomerMenu.Screen
        data-resource-id="OrdersAndDeliveries"
        name="OrdersAndDeliveries"
        component={OrderTableTabView}
        options={{
          label: 'Orders & Deliveries',
        }}
      /> */}
      {/* <CustomerMenu.Screen
        data-resource-id="AcquisitionCost"
        name="AcquisitionCost"
        // component={RecieptTab}
        options={{
          label: 'Acquisition Cost',
        }}
      /> */}
      {/* <CustomerMenu.Screen
        data-resource-id="SalesFunnelConversion"
        name="SalesFunnelConversion"
        // component={RecieptTab}
        options={{
          label: 'Sales Funnel Conversion',
        }}
      /> */}
      {/* <CustomerMenu.Screen
        data-resource-id="CustomerGrossMarginReport"
        name="Customer Gross Margin Report"
        component={CustomerGrossMarginTab}
        options={{
          label: 'Customer Gross Margin',
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="AMReport"
        name="AM Performance"
        component={AMReportTab}
        options={{
          label: 'AM Performance',
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="CustomerRevenueReport"
        name="Customer Wise Revenue"
        component={CustomerRevenueTabs}
        options={{
          label: 'Customer Wise Revenue',
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="CustomerChurnReport"
        name="Customer Churn Report"
        component={CustomerChurnReportTabs}
        options={{
          label: 'Customer Churn Report',
        }}
      /> */}
    </CustomerMenu.Navigator>
  );
};

export default CustomerNavigator;
