import {Row, Text, View} from '@unthinkable/react-core-components';
import {Avatar} from '@unthinkable/react-avatar';
import {resolveExp} from '@unthinkable/react-utils';

export const MentionSuggestionItem = props => {
  const {
    row,
    suggestionField = 'value',
    secondarySuggestionField,
    styles,
  } = props;

  let title = resolveExp(row, suggestionField);
  let secondaryTitle = resolveExp(row, secondarySuggestionField);
  return (
    <Row style={styles.container}>
      <Avatar value={title} styles={styles.avatar} />
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
        {secondarySuggestionField ? (
          <Text style={styles.secondaryTitle}>{secondaryTitle}</Text>
        ) : (
          void 0
        )}
      </View>
    </Row>
  );
};
