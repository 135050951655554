import React from 'react';

import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {OutreachCampaignTableGrouped} from './OutreachCampaignTableGrouped';
import {OutreachAnalyticsTableGrouped} from './OutreachAnalyticsTableGrouped';
import {PeriodRangeFilter} from '../../../components/filter/Filters';

export const ActionAnalyticsTab = props => {
  const {
    route: {params},
  } = props;
  const {filterValues = {}, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {params: filterParams} = filterValues || {};
  const tabs = {
    inexperiment: {
      label: 'Completed actions',
      view: <OutreachCampaignTableGrouped />,
    },
    confirmed: {
      label: 'Analytics',
      view: <OutreachAnalyticsTableGrouped filterParams={filterParams} />,
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
    },
  };

  return <TabView {...props} tabs={tabs} />;
};
