import moment from 'moment';
export const timeZoneOffset = new Date().getTimezoneOffset() * -1;

export const getZeroTimeDate = (date, eod) => {
  if (!date) {
    return date;
  }
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }
  let minutes = date.getTimezoneOffset() * -1;
  if (eod) {
    return moment(date).endOf('day').add(minutes, 'minutes').utc().toDate();
  } else {
    return moment(date).startOf('day').add(minutes, 'minutes').utc().toDate();
  }
};

export const getRangeData = ({filterOf, from, to, source}) => {
  if ((source === 'Prev' || source === 'Next') && filterOf !== 'Custom') {
    const filterTye = filterOf === 'FY' ? 'Year' : filterOf;
    if (source === 'Prev') {
      from = moment(from).subtract(1, filterTye).toDate();
      to = moment(to).subtract(1, filterTye).toDate();
    } else {
      from = moment(from).add(1, filterTye).toDate();
      to = moment(to).add(1, filterTye).toDate();
    }
  } else if (filterOf !== 'Custom') {
    const today = moment().toDate();
    if (filterOf === 'Day') {
      from = getZeroTimeDate(today);
      to = getZeroTimeDate(today, true);
    } else if (filterOf === 'Week' || filterOf === 'Month') {
      from = moment().startOf(filterOf).toDate();
      to = moment().endOf(filterOf).toDate();

      from = getZeroTimeDate(from);
      to = getZeroTimeDate(to, true);
    } else if (filterOf === 'FY') {
      const month = moment(today).format('M');
      const year = moment(today).format('YYYY');
      if (month > 3) {
        from = moment(`${4} ${year}`, 'MM YYYY').startOf('month').toDate();
        from = getZeroTimeDate(from);
        to = moment(`${3} ${year * 1 + 1}`, 'MM YYYY')
          .endOf('month')
          .toDate();
        to = getZeroTimeDate(to, true);
      } else {
        from = moment(`${4} ${year * 1 - 1}`, 'MM YYYY')
          .startOf('month')
          .toDate();
        from = getZeroTimeDate(from);
        to = moment(`${3} ${year}`, 'MM YYYY').endOf('month').toDate();
        to = getZeroTimeDate(to, true);
      }
    } else if (filterOf === 'Year') {
      const year = moment(today).format('YYYY') * 1;
      from = moment(`${1} ${year}`, 'MM YYYY').startOf('month').toDate();
      from = getZeroTimeDate(from);
      to = moment(`${12} ${year}`, 'MM YYYY').endOf('month').toDate();
      to = getZeroTimeDate(to, true);
    }
  } else if (filterOf === 'Custom') {
    let timezoneOffset = new Date().getTimezoneOffset() * -1;
    from =
      from && moment(from).subtract(timezoneOffset, 'minutes').utc().toDate();
    to = to && moment(to).subtract(timezoneOffset, 'minutes').utc().toDate();
    from = from && getZeroTimeDate(from);
    to = to && getZeroTimeDate(to, true);
  }
  return {from, to};
};

export const rangeCalculator = ({
  filterOf,
  from,
  to,
  label = 'Select Range',
} = {}) => {
  from =
    from &&
    moment(from).subtract(timeZoneOffset, 'minutes').startOf('day').toDate();
  to =
    to && moment(to).subtract(timeZoneOffset, 'minutes').endOf('day').toDate();
  let Range = '';
  let fromDay = moment(from).format('ddd');
  let fromDate = moment(from).format('DD');
  let toDay = moment(to).format('ddd');
  let toDate = moment(to).format('DD');
  let toMonth = moment(to).format('MMM');
  let toYear = moment(to).format('YYYY');
  let fromMonth = moment(from).format('MMM');
  let fromYear = moment(from).format('YYYY');
  if (filterOf === 'Day' && to) {
    Range = `${fromDate} ${fromMonth.slice(0, 3)}, ${fromDay}`;
  } else if (filterOf === 'Month' && from) {
    Range = `${fromMonth}, ${fromYear}`;
  } else if (filterOf === 'Week' && from && to) {
    Range = `${fromDate} ${fromMonth}-${toDate} ${toMonth}`;
  } else if (filterOf === 'FY' && from && to) {
    fromYear = fromYear;
    fromYear = fromYear[fromYear.length - 2] + fromYear[fromYear.length - 1];
    toYear = toYear;
    toYear = toYear[toYear.length - 2] + toYear[toYear.length - 1];

    Range = `${fromMonth},${fromYear}-${toMonth},${toYear} `;
  } else if (filterOf === 'Year' && from && to) {
    Range = fromYear;
  } else if (filterOf === 'Custom') {
    if (from && to) {
      Range = `${fromDate} ${fromMonth} - ${toDate} ${toMonth}`;
    } else if (from) {
      Range = `${fromDate} ${fromMonth}, ${fromYear}`;
    } else if (to) {
      Range = `${toDate} ${toMonth}, ${toYear}`;
    } else {
      Range = 'Select Range';
    }
  } else {
    Range = label;
  }
  return Range;
};

export const rangeCalculatorWithNewFormat = ({
  filterOf,
  from,
  to,
  label = 'Select Range',
}) => {
  from =
    from &&
    moment(from).subtract(timeZoneOffset, 'minutes').startOf('day').toDate();
  to =
    to && moment(to).subtract(timeZoneOffset, 'minutes').endOf('day').toDate();
  let Range = '';
  let fromDay = moment(from).format('ddd');
  let fromDate = moment(from).format('DD');
  let toDay = moment(to).format('ddd');
  let toDate = moment(to).format('DD');
  let toMonth = moment(to).format('MMM');
  let toYear = moment(to).format('YYYY');
  let fromMonth = moment(from).format('MMM');
  let fromYear = moment(from).format('YYYY');
  if (filterOf === 'Day' && to) {
    Range = `${fromDate} ${fromMonth.slice(0, 3)} - ${fromDay}`;
  } else if (filterOf === 'Month' && from) {
    Range = `${fromMonth}  ${fromYear}`;
  } else if (filterOf === 'Week' && from && to) {
    Range = `${fromDate} ${fromMonth} - ${toDate} ${toMonth}`;
  } else if (filterOf === 'FY' && from && to) {
    fromYear = fromYear;
    fromYear = fromYear[fromYear.length - 2] + fromYear[fromYear.length - 1];
    toYear = toYear;
    toYear = toYear[toYear.length - 2] + toYear[toYear.length - 1];

    Range = `${fromMonth} ${fromYear} - ${toMonth} ${toYear} `;
  } else if (filterOf === 'Year' && from && to) {
    Range = fromYear;
  } else if (filterOf === 'Custom') {
    if (from && to) {
      Range = `${fromDate} ${fromMonth} - ${toDate} ${toMonth}`;
    } else if (from) {
      Range = `${fromDate} ${fromMonth}  ${fromYear}`;
    } else if (to) {
      Range = `${toDate} ${toMonth}  ${toYear}`;
    } else {
      Range = 'Select Range';
    }
  } else {
    Range = label;
  }
  return Range;
};

export const getPreviousWeek = () => {
  let filterOf = 'Week';
  let from = moment().startOf(filterOf).toDate();
  let to = moment().endOf(filterOf).toDate();

  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);

  from = moment(from).subtract(1, filterOf).toDate();
  to = moment(to).subtract(1, filterOf).toDate();
  return {from, to, filterOf};
};

export const getPreviousMonth = () => {
  let filterOf = 'Month';
  let from = moment().startOf(filterOf).toDate();
  let to = moment().endOf(filterOf).toDate();

  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);

  from = moment(from).subtract(1, filterOf).toDate();
  to = moment(to).subtract(1, filterOf).toDate();
  return {from, to, filterOf};
};

export const getCurrentMonth = () => {
  let filterOf = 'Month';
  let from = moment().startOf(filterOf).toDate();
  let to = moment().endOf(filterOf).toDate();

  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);

  return {from, to, filterOf};
};

export const getToday = () => {
  let filterOf = 'day';
  let from = moment().startOf('day').toDate();
  let to = moment().endOf('day').toDate();

  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);

  return {from, to, filterOf};
};

export const getYesterday = () => {
  let filterOf = 'day';
  let from = moment().subtract(1, 'days').startOf('day').toDate();
  let to = moment().subtract(1, 'days').endOf('day').toDate();

  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);

  return {from, to, filterOf};
};

export const getDateRangeOfWeek = (
  weekNumber,
  year = new Date().getFullYear(),
) => {
  const januaryFirst = new Date(year, 0, 1);
  const weekMilliseconds = 604800000;
  const targetWeekStart = new Date(
    januaryFirst.valueOf() + (weekNumber - 1) * weekMilliseconds,
  );

  const dayOfWeek = targetWeekStart.getDay();
  if (dayOfWeek !== 1) {
    targetWeekStart.setDate(targetWeekStart.getDate() - ((dayOfWeek + 6) % 7));
  }

  const weekStartDate = new Date(targetWeekStart.valueOf());
  const weekEndDate = new Date(targetWeekStart.valueOf() + 6 * 86400000);

  return {from: weekStartDate, to: weekEndDate};
};
