import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const VCSForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, vcs, afterSubmit, searchValue} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'VersionControl',
    uri: '/projectversioncontrols',
    ...props,
  });

  const defaultValues = {name: searchValue};

  if (project?._id) {
    defaultValues.project_id = project?._id;
  }

  return (
    <Form
      api={`/projectversioncontrols/${vcs?._id}`}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'VCS',
          type: 'autoComplete',
          options: ['bitbucket', 'github'],
          field: 'type',
          required: true,
          helperText: 'Select the version control service provider.',
        },
        {
          label: 'Workspace/User Name',
          field: 'workspace',
          type: 'text',
          required: true,
          helperText: 'Input the workspace or user ID for the VCS.',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateVCSForm = props => {
  return <VCSForm mode="edit" header="Update VCS" {...props} />;
};

export const AddVCSForm = props => {
  return <VCSForm header="Create VCS" {...props} />;
};
