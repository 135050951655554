// Akshat Garg - 11/03/24 - Creating a new communication table screen for on row press of communication analytics 
import React from 'react';
import {Table} from '../../../components/table/Table';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {RenderLinkedInUrl} from './CustomRendersCollection';
import { useFetchData } from '../../../controllers/useFetchData';

export const EmailSyncCommunicationTable = props => {
  const {editEmailReplyMessage} = messageControllers(props);
  const {
    route: {params: {addOnFilter: _addOnFilter} = {}},
    navigation,
  } = props;
  let addOnFilter = {
    ..._addOnFilter,
  };

  const {data = [], ...restFetchProps} = useFetchData({
    api: '/communications',
    filter: addOnFilter,
    fields: {
      _id: 1,
      initiator: 1,
      communication_mode: 1,
      contact_id: {
        email: 1,
        name: 1,
        first_name: 1,
        last_name: 1,
        linkedin_url: 1,
        designation: 1,
      },
      potential_customer: {
        name: 1,
        website: 1,
        country: {name: 1, code: 1},
        website_summary: 1,
        industry: {name: 1},
      },
      subject: 1,
      campaign_id: {name: 1},
      description: 1,
      communication_mode: 1,
      date: 1,
    },
    sort: {date: -1},
  });


  return (
    <Table
      {...props}
      onRowPress={({row}) => {
        navigation.navigate('action-summary-form', {
          ...row,
          data,
        });
      }}
      data={data}
      {...restFetchProps}
      columns={[
        {
          type: 'date',
          field: 'date',
          header: 'Date',
          width: 200,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 250,
        },
        {
          type: 'text',
          field: 'initiator',
          header: 'Initiator',
          width: 150,
        },
        {
          type: 'text',
          field: 'subject',
          header: 'Subject',
        },
      ]}
      moreActions={[
        {
          title: 'View',
          onPress: editEmailReplyMessage,
        },
      ]}
    />
  );
};
