import React from 'react';
import {Row, Text} from '@unthinkable/react-core-components';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {MultiTag} from '../../../components/chip/MultiChip';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {IconButton} from '../../../components/button/IconButton';

export const ManagementCostTable = props => {
  const {navigation} = props;
  const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {params: filterParams, filter} = filterValues;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <Table
      {...props}
      limit={2000}
      renderHeader={() => (
        <TableHeader
          title="Management cost report"
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <IconButton
              icon={icons.Download}
              onPress={() => {
                download({
                  uri: '/exportManagingCostReport',
                  props: {
                    uri: '/employee/managementcost',
                    parameters: {
                      addOnFilter: {...filterParams},
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      api={`/employee/managementcost`}
      params={filterParams}
      addOnFilter={filter}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Manager',
          minWidth: 300,
          render: ({row}) => {
            return (
              <Row
                gap={8}
                style={{
                  alignItems: 'center',
                }}>
                <UserAvatar size={30} value={row} />
                <PrimaryColumnCard
                  primaryTitle={row?.name}
                  items={[
                    {
                      value: row?.official_email_id,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Exp.',
          width: 100,
          render: ({row, styles}) => {
            return <Text style={{...styles.rowText}}>{row.experience} y</Text>;
          },
        },
        {
          header: 'Projects',
          field: 'project',
          minWidth: 200,
          render: ({row}) => {
            return <MultiTag values={row.project} maxChips={2} gap={8} />;
          },
        },
        {
          render: CountCellRender,
          count_field: 'managing',
          width: 100,
          aggregate: true,
          header: 'Managing',
          type: 'number',
          align: 'center',
          field: 'managing',
          onPress: ({row}) => {
            navigation.navigate('managing-table', {
              ...row,
            });
          },
        },
        {
          count_field: 'salary',
          aggregate: true,
          header: 'Cost',
          field: 'salary',
          type: 'currency',
          align: 'center',
          width: 200,
        },
        {
          count_field: 'costPerPerson',
          aggregate: true,
          field: 'costPerPerson',
          header: 'Management Cost / Person',
          type: 'currency',
          width: 200,
          align: 'center',
          render: ({row, styles}) => {
            return (
              <Row
                style={{
                  backgroundColor: row.avgColor,
                  width: 200,
                  flex: 1,
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  borderRadius: 8,
                }}>
                <Text style={{...styles.rowText, textAlign: 'center'}}>
                  {`₹${row?.costPerPerson}`}
                </Text>
              </Row>
            );
          },
        },
      ]}
    />
  );
};

export const ManagingTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      eventSourceId={['escalationreasons']}
      api={`/employeeassignments`}
      fields={{
        employee: {
          _id: 1,
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        from_date: 1,
        to_date: 1,
        hours: 1,
        project_id: {_id: 1, project: 1},
        level_one_owner: {_id: 1, name: 1},
        level_two_owner: {_id: 1, name: 1},
      }}
      addOnFilter={{_id: {$in: params.managingIds}}}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          width: 200,
        },
        {
          type: 'text',
          header: 'Project',
          width: 200,
          field: 'project_id.project',
        },

        {
          type: 'date',
          field: 'from_date',
          header: 'From',
        },
        {
          type: 'date',
          field: 'to_date',
          header: 'To',
        },
        {
          type: 'number',
          field: 'hours',
          header: 'Hours',
        },
        {
          type: 'userAvatar',
          field: 'level_one_owner',
          header: 'Level 1',
        },
        {
          type: 'userAvatar',
          field: 'level_two_owner',
          header: 'Level 2',
        },
      ]}
    />
  );
};
