import React from 'react';
import EmployeeAccessTable from './EmployeeAccessTable';
import DepartmentTable from '../../department/views/DepartmentTable';
import ProductTable from './ProductTable';
import DepartmentInputTable from '../../department/views/DepartmentInputTable';
import DepartmentOutputTable from '../../department/views/DepartmentOutputTable';
import {CompactAddButton} from '../../../components/button/AddButton';
import {OfficeTable} from './OfficeTable';
import {CountryTable} from './CountryTable';
import {FinancialYearTable} from './FinancialYearTable';
import {CurrencyTable} from './CurrencyTable';
import {OrganizationTable} from './OrganizationTable';
import {ActiveElementsTable} from './ActiveElementsTable';
import {TypesTable} from './TypesTable';
import {EmployeeAssignmentTable} from './EmployeeAssignmentTable';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {EmployeeSizeTab, EmployeeTab} from './EmployeeSizeTab';
import {IndustryTab} from './IndustryTab';
import {OrganizationCategoryTable} from './OrganizationCategoryTable';
import {GeographicCategoryTable} from './GeographicCategoryTable';
import {AccessGroupTable} from './AccessGroupList';
import {UserAccessTable} from './UserAccessList';
import {AllCampaignContactList} from '../../leadGeneration/views/CampaignContactsList';
import {FreezeRangeTable} from './FreezeRangeTable';
import {UserSetupTable} from './UserSetupTable';
import {ReimbursementCategoryTable} from './ReimbursementCategoryTable';
import {AccessResourceTable} from './AccessResourceList';
import {AccessRoleTable} from './AccessRoleList';
import {TicketEnabledTeamList} from '../../employee/views/EmployeeTeamsList';
import {ApiLogsView} from '../../logs/views/ApiLogsView';
import {CronLogsTable} from '../../logs/views/CronLogsTable';
import {SlowQueryView} from '../../logs/views/SlowQueryView';
import {LeaveAllowedTable} from './LeaveAllowedTable';
import {EscalationReasonTable} from './EscalationReasonTable';
import {LeadTableTabView} from '../../leadGeneration/views/LeadTableTabView';
import {ExpenseApproverLimitTable} from './ExpenseApproverLimitTable';
import {EntityVariablesTable} from './EntityVariablesTable';
import {AiPromptViewTable} from '../../pmt/views/AiPromptViewTable';
import {CategoryTable} from './CategoryTable';
import {useAppStateContext} from '../../../providers/AppState';
import {UIComponents} from './UIComponents';
// import { LeadTableTabView } from '../../leadGeneration/views/LeadTableTabView';
// import { BankTable } from './BanksList';

const menus = user => {
  return [
    {
      label: 'Access Groups',
      view: <AccessGroupTable />,
      actions: [
        <CompactAddButton title={'Access Group'} view={'add-access-group'} />,
      ],
    },
    {
      label: 'User Access',
      view: <UserAccessTable />,
      actions: [
        <CompactAddButton title={'User Access'} view={'add-user-access'} />,
      ],
    },
    {
      label: 'Access Resources',
      view: <AccessResourceTable />,
      actions: (
        <CompactAddButton
          title={'Access Resource'}
          view={'add-access-resource'}
        />
      ),
    },
    {
      label: 'Access Roles',
      view: <AccessRoleTable />,
      actions: [
        <CompactAddButton title={'Access Role'} view={'add-access-role'} />,
      ],
    },
    {
      label: 'User',
      view: <UserSetupTable />,
      actions:
        user?.email == 'amit.singh@daffodildb.com' ||
        user?.email == 'sushil.nagvan@daffodilsw.com'
          ? [<CompactAddButton title={'User'} view={'add-user-setup'} />]
          : null,
    },
    {
      label: 'Employee Access',
      view: <EmployeeAccessTable />,
      actions: [
        <CompactAddButton
          title={'Employee Access'}
          view={'add-employee-access'}
        />,
      ],
    },
    {
      label: 'Department',
      view: <DepartmentTable />,
      api: '/departments',
      // actions: [
      //   <CompactAddButton title={'Department'} view={'add-department'} />,
      // ],
    },

    {
      label: 'Department Input',
      view: <DepartmentInputTable />,
      api: '/departmentinputs',
      actions: [
        <CompactAddButton
          title={'Department Input'}
          view={'add-department-input'}
        />,
      ],
    },
    {
      label: 'Department Output',
      view: <DepartmentOutputTable />,
      api: '/departmentoutputs',
      actions: [
        <CompactAddButton
          title={'Department Output'}
          view={'add-department-output'}
        />,
      ],
    },
    {
      label: 'Product',
      view: <ProductTable />,
      api: '/products',
      // actions: [<CompactAddButton title={'Product'} view={'add-product'} />],
    },
    {
      label: 'Office',
      view: <OfficeTable />,
      // actions: [<CompactAddButton title={'Office'} view={'add-office'} />],
    },
    {
      label: 'Organization',
      view: <OrganizationTable />,
      // actions: [
      //   <CompactAddButton title={'Organization'} view={'add-organization'} />,
      // ],
    },
    {
      label: 'Organization Category',
      view: <OrganizationCategoryTable />,
      actions: [
        <CompactAddButton
          title={'Organization Category'}
          view={'add-organization-category'}
        />,
      ],
    },
    {
      label: 'Geographic Category',
      view: <GeographicCategoryTable />,
      actions: [
        <CompactAddButton
          title={'Geographic Category'}
          view={'add-geographic-category'}
        />,
      ],
    },

    {
      label: 'Country',
      view: <CountryTable />,
    },
    {
      label: 'Financial Year',
      view: <FinancialYearTable />,
      // actions: [
      //   <CompactAddButton
      //     title={'Financial Year'}
      //     view={'add-financial-year'}
      //   />,
      // ],
    },
    // {
    //   label: 'Banks',
    //   view: <BankTable />,
    //   actions: [
    //     <CompactAddButton title={'Banks'} view={'add-bank'} />,
    //   ],
    // },
    {
      label: 'Currency',
      view: <CurrencyTable />,
      actions: [<CompactAddButton title={'Currency'} view={'add-currency'} />],
    },
    {
      label: 'Active Elements',
      view: <ActiveElementsTable />,
      actions: [
        <CompactAddButton
          title={'Active Elements'}
          view={'add-active-elements'}
        />,
      ],
    },
    {
      label: 'Escalation Reason',
      view: <EscalationReasonTable />,
      actions: (
        <CompactAddButton
          title={'Add EscalationReason'}
          view={'add-escalation-reason'}
        />
      ),
    },
    {
      label: 'Category',
      view: <CategoryTable />,
      actions: (
        <CompactAddButton title={'Add Category'} view={'add-category'} />
      ),
    },
    {
      label: 'Data Entity',
      view: <TypesTable />,
      actions: [<CompactAddButton title={'Data Entity'} view={'add-types'} />],
    },
    {
      label: 'Employee Assignment',
      view: <EmployeeAssignmentTable />,
      actions: [
        <CompactAddButton
          title={'Employee Assignment'}
          view={'add-employee-assignment'}
        />,
      ],
    },
    {
      label: 'Employee Size',
      api: '/EmployeeSizes',
      view: <EmployeeSizeTab />,
      actions: [
        <CompactAddButton title={'Employee Size'} view={'add-employee-size'} />,
      ],
    },
    {
      label: 'Industry',
      api: '/industries',
      view: <IndustryTab />,
      actions: [<CompactAddButton title={'Industry'} view={'add-industry'} />],
    },
    {
      label: 'All Campaign Contacts',
      api: '/campaigncontacts',
      view: <AllCampaignContactList />,
    },
    {
      label: 'All Lead',
      view: <LeadTableTabView />,
    },
    // {
    //   label: 'Freeze Range',
    //   api: '/freezes',
    //   view: <FreezeRangeTable />,
    //   // actions: [
    //   //   <CompactAddButton title={'Freeze Range'} view={'add-freeze-range'} />,
    //   // ],
    // },
    {
      label: 'Reimbursement Category',
      view: <ReimbursementCategoryTable />,
      // actions: [
      //   <CompactAddButton
      //     title={'Reimburement Category'}
      //     view={'add-reimbursement-category'}
      //   />,
      // ],
    },
    {
      label: 'Leave Allowed',
      view: <LeaveAllowedTable />,
      actions: [
        <CompactAddButton title={'Leave Allowed'} view={'add-leave-allowed'} />,
      ],
    },
    {
      label: 'Ticket Enabled Teams',
      view: <TicketEnabledTeamList />,
    },
    // Adit verma 08-01-2024 - Make view for api logs
    {
      label: 'API logs',
      view: <ApiLogsView />,
    },
    // Rajnish Trikaldarshi 08-01-2024 - Make menu to open cron logs screen
    {
      label: 'Cron Logs',
      view: <CronLogsTable />,
    },
    // Adit verma 15-01-2024 - Make view for Slow Query
    {
      label: 'Slow Query',
      view: <SlowQueryView />,
    },
    {
      label: 'Entity Variables',
      view: <EntityVariablesTable />,
      actions: [
        <CompactAddButton title={'Data Entity'} view={'add-entity-variable'} />,
      ],
    },
    {
      label: 'AI Prompts',
      view: <AiPromptViewTable />,
    },
    {
      label: 'UI Components',
      view: <UIComponents />,
    },
  ];
};

const CompanySetup = props => {
  const {route: {params} = {}} = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const {employee} = user;
  return <LeftMenuView {...props} params={params} menus={menus(user)} />;
};

export default CompanySetup;
