import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';

export const salaryTemplateController = props => {
    const { params, navigation, tab } = props;
    const { post, user } = useAppStateContext();
    const invoke = useInvoke({ method: 'put', eventSourceId: ['SalaryTemplate'] });
    const invokeDelete = useInvoke({
        method: 'delete',
        eventSourceId: ['SalaryTemplate'],
    });

    const deleteSalaryTemplate = () => ({
        title: 'Delete',
        confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this template?',
        },
        onPress: props => {
            const { row } = props;
            invokeDelete({
                uri: `/employeesalarycomponents/${row?._id}`,
                props: {},
            });
        },
    });

    const markTemplateActive = () => ({
        title: 'Mark Active',
        confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to active this template?',
        },
        visible: () => tab && tab === "Draft" || tab === "InActive",
        onPress: props => {
            const { row } = props;
            invoke({
                uri: `/employeesalarycomponents/${row?._id}`,
                message: 'Active successfully',
                props: {
                    status: 'active',
                },
            });
        },
    });

    const markTemplateInActive = () => ({
        title: 'Mark Inactive',
        confirm: {
            title: 'Mark Inactive',
            message: 'Are you sure you want to inactive this template?',
        },
        visible: () => tab && tab === "Active",
        onPress: props => {
            const { row } = props;
            invoke({
                uri: `/employeesalarycomponents/${row?._id}`,
                message: 'Active successfully',
                props: {
                    status: 'inActive',
                },
            });
        },
    });

    const duplicateSalaryTemplateForm = ({ row }) => {
        navigation.navigate('duplicate-salary-template-form', {
            ...params,
            row
        })
    }

    const editSalaryTemplateForm = ({ row }) => {
        navigation.navigate('edit-salary-template', {
            ...params,
            row
        })
    }

    const SalaryTemplateResourceList = ({ row }) => {
        navigation.navigate('employee-salary-template', {
            ...params,
            row
        })
    }

    return {
        deleteSalaryTemplate,
        markTemplateActive,
        markTemplateInActive,
        editSalaryTemplateForm,
        duplicateSalaryTemplateForm,
        SalaryTemplateResourceList
    };
};
