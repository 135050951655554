const {InlineForm, Form} = require('../../../components/form/Form');
const {useFormSubmit} = require('../../../controllers/useSubmitForm');

export const AddTicketSubCategory = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/ticketsubcategories',
    eventSourceId: 'TicketSubCategory',
  });
  const {
    route: {params},
  } = props;
  const {employee_team} = params;
  return (
    <InlineForm
      header="Add Ticket Sub Category"
      onSubmit={onSubmit}
      defaultValues={{
        employee_team: employee_team?._id,
      }}
      layoutFields={[
        {
          label: 'Parent Category',
          field: 'parent_category',
          type: 'autoComplete',
          api: '/ticketcategories',
          suggestionField: 'name',
          filter: {
            employee_team: employee_team?._id,
          },
          required: true,
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
    />
  );
};

export const EditTicketSubCategory = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/ticketsubcategories',
    eventSourceId: 'TicketSubCategory',
  });
  const {
    route: {params},
  } = props;
  const {employee_team, ticket_sub_category} = params;
  return (
    <Form
      header="Edit Sub Category"
      onSubmit={onSubmit}
      defaultValues={{
        employee_team: employee_team?._id,
      }}
      api={'/ticketSubCategories/' +ticket_sub_category?._id}
      fields={{
        name: 1,
        parent_category: {
          name: 1,
        },
      }}
      mode="edit"
      layoutFields={[
        {
          label: 'Parent Category',
          field: 'parent_category',
          type: 'autoComplete',
          api: '/ticketcategories',
          suggestionField: 'name',
          filter: {
            employee_team: employee_team?._id,
          },
          required: true,
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
    />
  );
};
