import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {SwaggerRedirectURL} from '../cellrenders/SwaggerRender';
import {useAppStateContext} from '../../../providers/AppState';

export const HEADER_CONTENT_TYPES = {
  'application/json': 'object',
  'application/xml': 'xml',
  'application/javascript': 'javascript',
  'application/octet-stream': 'binary',
  'text/html': 'html',
  'text/plain': 'text',
  'text/css': 'css',
  'application/vnd.api+json': 'json',
};

const ApiConfigurationForm = props => {
  const {
    route: {params},
  } = props;

  const {project, row} = params;
  const {download} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    uri: '/apiconfigurations',
    eventSourceId: 'Swagger',
  });
  const defaultValues = {
    project_id: project?._id,
    req_header: 'application/json',
    resp_header: 'application/json',
  };
  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/apiconfigurations/${row?._id}`}
      defaultValues={defaultValues}
      header="Swagger API Configuration"
      layoutFields={[
        {
          label: 'Content Type',
          fields: [
            {
              label: 'Request header',
              field: 'req_header',
              type: 'autoComplete',
              options: Object.keys(HEADER_CONTENT_TYPES),
              required: true,
              size: 4,
            },
            {
              label: 'Response header',
              field: 'resp_header',
              type: 'autoComplete',
              options: Object.keys(HEADER_CONTENT_TYPES),
              required: true,
              size: 4,
            },
          ],
        },
        {
          label: 'Security',
          fields: [
            {
              label: 'Authentication',
              field: 'security_type',
              type: 'autoComplete',
              options: ['BasicAuth', 'BearerAuth', 'ApiKeyAuth'],
              width: 300,
            },
          ],
        },
        {
          label: 'Responses',
          fields: [
            {
              field: 'resp_format',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Code',
                      type: 'text',
                      field: 'status_code',
                      required: true,
                      width: 150,
                    },
                    {
                      field: 'status_desc',
                      type: 'text',
                      label: 'Description',
                      required: true,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Swagger Environments',
          fields: [
            {
              field: 'swagger_environments',
              nested: true,
              render: props => (
                <NestedTable
                  inlineAddForm={true}
                  {...props}
                  fields={[
                    {
                      label: 'Environment',
                      type: 'autoComplete',
                      options: ['Development', 'QA', 'Staging'],
                      field: 'environment',
                      required: true,
                      width: 300,
                    },
                    {
                      label: 'Environment Domain',
                      placeholder: 'https://v2.locomo.io',
                      type: 'text',
                      field: 'environment_domain',
                      required: true,
                    },
                  ]}
                  columns={[
                    {
                      label: 'Environment',
                      type: 'text',
                      field: 'environment',
                      width: 300,
                    },
                    {
                      label: 'Environment Domain',
                      type: 'text',
                      field: 'environment_domain',
                    },
                    {
                      render: props => {
                        const {row} = props;
                        return (
                          <SwaggerRedirectURL
                            onPress={() => {
                              download({
                                uri: `/swagger-docs/${project?._id}/${row?.environment}/`,
                                props: {
                                  time: new Date(),
                                },
                              });
                            }}
                          />
                        );
                      },
                      width: 50,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddApiConfigurationForm = props => {
  return <ApiConfigurationForm {...props} />;
};

export const EditApiConfigurationForm = props => {
  return <ApiConfigurationForm {...props} mode="edit" />;
};
