import React from 'react';
import {
  Text,
  Row,
  TouchableOpacity,
  Col,
  Image,
  View,
} from '@unthinkable/react-core-components';
import {useComponentTheme, useTheme} from '@unthinkable/react-theme';
import {resolveExp} from '@unthinkable/react-utils';
import {Chip} from '../../components/chip/Chip';

export const CountPlusRender = ({count_field, hidePlus, row, onPlusPress}) => {
  let {addPlus, iconStyle, iconContainerStyle, rowStyle} = useComponentTheme(
    'CountCellRenderStyles',
  );

  let {columnTextStyle} = useComponentTheme('CountPlusRenderStyles');
  const {colors} = useTheme();

  let countValue = resolveExp(row, count_field);

  if (Array.isArray(countValue)) {
    countValue = countValue?.length;
  }

  return (
    <Row style={rowStyle}>
      {countValue && hidePlus ? (
        <Chip
          value={countValue}
          color={colors.INFORMATION_LOW}
          textColor={colors.INFORMATION_HIGH}
        />
      ) : countValue && !hidePlus ? (
        <View>
          <Text style={columnTextStyle}>{countValue}</Text>
        </View>
      ) : null}
      {onPlusPress && !hidePlus ? (
        <TouchableOpacity
          onPress={() => {
            onPlusPress({row});
          }}>
          <Col gap={2} style={iconContainerStyle}>
            <Image source={addPlus} style={iconStyle} />
          </Col>
        </TouchableOpacity>
      ) : null}
    </Row>
  );
};
