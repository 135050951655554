import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import moment from 'moment';

export const UpdateLeadStageForm = props => {
  const {
    route: {params},
  } = props;
  const {row, stage} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  return (
    <Form
      defaultValues={{_id: row?._id, stage}}
      onSubmit={onSubmit}
      header={'Update Lead Stage'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Stage',
          field: 'stage',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          api: `/stages`,
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => ({
            name: 'Proposal',
          }),
          onChangeValue: (value, e, {setFieldValue}) => {
            if (value?.name === 'Proposal') {
              setFieldValue('proposal_stage_date', getZeroTimeDate(new Date()));
              setFieldValue('opportunity_date', getZeroTimeDate(new Date()));
              setFieldValue(
                'expected_customer_date',
                moment(getZeroTimeDate(new Date())).add(30, 'd').toDate(),
              );
            }
            if (value.name === 'RQL') {
              setFieldValue('rql_stage_date', getZeroTimeDate(new Date()));
            }
          },
        },
      ]}
    />
  );
};

export const UpdateUqlDateFrom = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  return (
    <Form
      defaultValues={{_id: row?._id}}
      onSubmit={onSubmit}
      header={'Update UQL Date'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'UQL Date',
          field: 'uql_stage_date',
          type: 'date',
        },
      ]}
    />
  );
};
