export const useProjectResourceWorkingDataActions = ({navigation}) => {
  const activityLogVerifiedOrdersAndDeliveries = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [{path: 'order', select: {order_number: 1}}],
      api: `/projectresourceworkingdata/${row?._id}/activityLogs`,
      displayFields: {
        order: {
          label: 'Order',
          field: 'order.order_number',
        },
        billing_hours: {
          label: 'Billing Hrs',
        },
        pending_amount: {
          label: 'Pending Amount',
        },
      },
    });
  };

  return {
    activityLogVerifiedOrdersAndDeliveries,
  };
};
