import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Durations} from './components/Durations';

export const OrderResourceRequiremnetTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId="employeeAssigned"
      api={'/requirement/orderResourceRequirement'}
      params={params}
      columns={[
        {
          header: 'Order',
          field: 'order.order_number',
        },
        {
          header: 'Type',
          field: 'order.order_exists_type',
        },
        {
          header: 'Exp.(Yrs)',
          field: 'experience.experience',
        },
        {
          header: 'Duration',
          align: 'center',
          width: 220,
          render: ({row}) => {
            return Durations({
              toDate: row?.recurring_till,
              fromDate: row?.order_date,
            });
          },
        },
        {
          render: CountCellRender,
          count_field: 'no_of_resource',
          released_count_field: 'assigned_resource',
          header: 'Resources',
          align: 'right',
          onPress: ({row}) => {
            row?.no_of_resource != row.assigned_resource
              ? navigation.navigate('skill-requirement-table', {
                  data: row,
                })
              : void 0;
          },
        },
      ]}
    />
  );
};
