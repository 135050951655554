import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {DotSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {useStyles} from '@unthinkable/react-theme';
import {PrimaryColumnCardStyles} from './theme';
import {useAccentColor} from '../../controllers/useAccentColor';

export const CardItem = ({item = {}, variant: propVariant} = {}) => {
  const styles = useStyles(PrimaryColumnCardStyles);
  let {
    value,
    title = item?.value,
    numberOfLines = 1,
    color,
    icon,
    variant = propVariant,
    backgroundColor,
    onPress,
    width,
    maxWidth,
    renderComponent,
  } = item;

  backgroundColor = useAccentColor(backgroundColor);
  backgroundColor = backgroundColor?.MEDIUM;

  if (variant !== 'secondary') {
    backgroundColor = void 0;
  }

  if (!renderComponent) {
    renderComponent = icon ? (
      <Image source={icon} style={styles?.icon} />
    ) : value ? (
      <Text
        style={{
          ...(variant === 'secondary' ? styles.secondaryItem : styles.item),
          ...(color ? {color} : {}),
          ...(width ? {width} : {}),
          ...(maxWidth ? {maxWidth} : {}),
          ...(backgroundColor ? {backgroundColor} : {}),
        }}
        numberOfLines={numberOfLines}
        title={numberOfLines ? title : void 0}>
        {value}
      </Text>
    ) : null;
  }

  if (onPress) {
    renderComponent = (
      <TouchableOpacity onPress={onPress}>{renderComponent}</TouchableOpacity>
    );
  }
  return renderComponent;
};

export const PrimaryCardItems = ({items, variant}) => {
  const styles = useStyles(PrimaryColumnCardStyles);
  return (
    <RowWithSeparator
      style={styles.itemsContainer}
      separator={<DotSeparator color={styles.separatorColor} />}>
      {items?.map(item => {
        let isBlankItem =
          !item || (!item.value && !item.icon && !item.renderComponent);
        if (isBlankItem) {
          return;
        }
        return <CardItem item={item} variant={variant} />;
      })}
    </RowWithSeparator>
  );
};

export const SecondaryTitle = ({
  secondaryTitle,
  secondaryTitleColor,
  numberOfLines,
  secondaryTitleFont,
}) => {
  const styles = useStyles(PrimaryColumnCardStyles);
  if (!secondaryTitle) return null;
  return (
    <Text
      title={secondaryTitle}
      numberOfLines={numberOfLines}
      style={{
        ...styles.item,
        ...(secondaryTitleColor ? {color: secondaryTitleColor} : {}),
        ...secondaryTitleFont,
      }}>
      {secondaryTitle}
    </Text>
  );
};

export const PrimaryColumnCard = ({
  primaryTitle,
  titleOnPress,
  primaryTitleColor,
  numberOfLines = 1,
  secondaryTitleNumberOfLines,
  secondaryTitle,
  secondaryTitleColor,
  secondaryTitleFont,
  items,
  primarySubTitle,
  primarySubTitleColor,
  variant,
  icon,
  renderIcon,
  gap = 4,
  title,
}) => {
  const styles = useStyles(PrimaryColumnCardStyles);
  let primaryComponent = void 0;
  let underlineStyle = {};

  if (titleOnPress) {
    underlineStyle = {
      textDecoration: 'underline',
    };
  }
  if (primaryTitle) {
    primaryComponent = (
      <Row gap={gap} style={{alignItems: 'center'}}>
        <Text
          numberOfLines={numberOfLines}
          title={numberOfLines ? title || primaryTitle : void 0}
          style={{
            ...styles.title,
            ...(primaryTitleColor ? {color: primaryTitleColor} : {}),
            ...underlineStyle,
          }}>
          {primaryTitle}
        </Text>
        {icon ? (
          <Image source={icon} style={styles?.titleIcon} />
        ) : renderIcon ? (
          renderIcon()
        ) : (
          void 0
        )}
        {primarySubTitle ? (
          <>
            <DotSeparator color={primarySubTitleColor} />
            <Text
              title={primarySubTitle}
              numberOfLines={numberOfLines}
              style={{
                ...styles.item,
                ...(primarySubTitleColor ? {color: primarySubTitleColor} : {}),
              }}>
              {primarySubTitle}
            </Text>
          </>
        ) : (
          void 0
        )}
      </Row>
    );
  }

  if (titleOnPress) {
    primaryComponent = (
      <TouchableOpacity onPress={titleOnPress}>
        {primaryComponent}
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      {primaryComponent}
      {secondaryTitle ? (
        <SecondaryTitle
          secondaryTitle={secondaryTitle}
          secondaryTitleColor={secondaryTitleColor}
          numberOfLines={secondaryTitleNumberOfLines || numberOfLines}
          secondaryTitleFont={secondaryTitleFont}
        />
      ) : (
        void 0
      )}
      <PrimaryCardItems items={items} variant={variant} />
    </View>
  );
};
