import React, {useEffect, useState} from 'react';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {
  ItemCountContainer,
  ItemCountText,
  ItemLabel,
  ItemContainer,
  ItemRow,
  GroupContainer,
  EmptyMenuContainer,
  SectionContainer,
  SectionItemContainer,
  SectionItemLabel,
  ItemIconContainer,
  ItemIcon,
  MenuDot,
  MenuBarScrollView,
  TopGroupContainer,
} from './Styles';
import DownArrow from './images/DownArrow.svg';
import RightArrow from './images/RightArrow.svg';
import CaretRight from './images/CaretRight.svg';

export const MenuItem = ({
  styles,
  extraParams,
  onSelect,
  onSelectSection,
  isSelected,
  label,
  onPress,
  actions,
  expandable,
  expanded,
  setExpanded,
  parentSection,
  menuItem,
  icon,
  hideIcon,
  isSubMenu,
  skipRightArrow,
  onNavigate,
  renderTooltip,
  tooltip,
  count,
}) => {
  if (typeof label === 'function') {
    label = label(extraParams);
  }

  let render = (
    <ItemContainer
      styles={styles}
      isSelected={isSelected}
      isSubMenu={isSubMenu}
      onPress={e => {
        expandable && setExpanded?.(!expanded);
        if (menuItem?.view) {
          onSelect?.(menuItem);
          onSelectSection?.(parentSection);
        }
        onPress?.(menuItem, e);
      }}>
      <ItemRow styles={styles}>
        {expandable ? (
          <ItemIconContainer styles={styles}>
            <Image source={expanded ? DownArrow : RightArrow} />
          </ItemIconContainer>
        ) : (
          void 0
        )}
        {!hideIcon ? (
          <ItemIconContainer styles={styles}>
            {icon ? (
              <ItemIcon source={icon} styles={styles} />
            ) : (
              <MenuDot
                className="menu-dot"
                styles={styles}
                isSelected={isSelected}
              />
            )}
          </ItemIconContainer>
        ) : (
          void 0
        )}
        <ItemLabel styles={styles} isSelected={isSelected}>
          {label}
        </ItemLabel>
        {count ? (
          <ItemCountContainer styles={styles}>
            <ItemCountText isSelected={isSelected} styles={styles}>
              {count}
            </ItemCountText>
          </ItemCountContainer>
        ) : null}
        {actions}
        {(!skipRightArrow && typeof onPress === 'function') ||
        typeof onNavigate === 'function' ? (
          <ItemIconContainer styles={styles}>
            <Image source={CaretRight} />
          </ItemIconContainer>
        ) : (
          void 0
        )}
      </ItemRow>
    </ItemContainer>
  );
  if (renderTooltip) {
    render = renderTooltip({
      tooltip,
      children: render,
      reset: isSelected,
    });
  }
  return render;
};

export const SectionItem = ({
  styles,
  expandable,
  expanded,
  setExpanded,
  label,
  isSelected,
  menuItem,
  count,
}) => {
  if (!label || !menuItem?.menus || !menuItem?.menus?.length) {
    return null;
  }
  let itemRender = (
    <SectionItemContainer
      expanded={expanded}
      styles={styles}
      isSelected={isSelected}>
      {expandable ? (
        <ItemIconContainer styles={styles}>
          <Image source={expanded ? DownArrow : RightArrow} />
        </ItemIconContainer>
      ) : (
        void 0
      )}
      <SectionItemLabel
        expanded={expanded}
        isSelected={isSelected}
        styles={styles}>
        {label}
      </SectionItemLabel>
      {count ? (
        <ItemCountContainer styles={styles}>
          <ItemCountText isSelected={isSelected} styles={styles}>
            {count}
          </ItemCountText>
        </ItemCountContainer>
      ) : null}
    </SectionItemContainer>
  );
  if (expandable) {
    itemRender = (
      <TouchableOpacity
        onPress={() => {
          setExpanded(!expanded);
        }}>
        {itemRender}
      </TouchableOpacity>
    );
  }
  return itemRender;
};

const useExpandable = defaultExpandedSectionIndex => {
  const [expandedState, setExpandedState] = useState({});

  useEffect(() => {
    setExpandedState({[defaultExpandedSectionIndex]: true});
  }, [defaultExpandedSectionIndex]);

  const toggleExpanded = index => {
    setExpandedState({[index]: !expandedState[index]});
  };

  const isExpanded = index => {
    return expandedState[index];
  };
  return {
    isExpanded,
    toggleExpanded,
  };
};

const Menus = ({menus, ...props}) => {
  const {
    styles,
    onSelectMenu,
    selectedMenu,
    selectedSection,
    onSelectSection,
    hideMenuIcon,
    renderMenuItem,
    renderSectionItem,
    ...restProps
  } = props;

  if (!menus?.length) {
    return null;
  }

  let defaultExpandedSectionIndex;

  menus.forEach((menu, index) => {
    if (
      menu?.id === selectedSection?.id ||
      menu?.label === selectedSection?.label
    ) {
      defaultExpandedSectionIndex = index;
    }
  });

  const {isExpanded, toggleExpanded} = useExpandable(
    defaultExpandedSectionIndex,
  );

  let menusRender = menus?.length
    ? menus.map((menuItem, index) => {
        if (!menuItem) {
          return null;
        }
        let expanded = isExpanded(index);
        if (Array.isArray(menuItem)) {
          menuItem = {group: true, menus: menuItem};
        }
        let {group, section, menus: childMenus, expandable, ...rest} = menuItem;
        if (expandable === undefined && childMenus?.length) {
          expandable = true;
        }
        if (group) {
          if (props?.parentSection) {
            return childMenus?.length ? (
              <GroupContainer
                key={index}
                index={index}
                isSubMenu={restProps?.isSubMenu}
                styles={styles}>
                <Menus
                  menus={childMenus}
                  {...props}
                  isSubMenu={restProps?.isSubMenu}
                />
              </GroupContainer>
            ) : null;
          } else {
            return childMenus?.length ? (
              <TopGroupContainer
                key={index}
                index={index}
                isSubMenu={restProps?.isSubMenu}
                styles={styles}>
                <Menus
                  menus={childMenus}
                  {...props}
                  isSubMenu={restProps?.isSubMenu}
                />
              </TopGroupContainer>
            ) : null;
          }
        } else if (section) {
          const {id, label} = rest;
          const isSelected = selectedMenu
            ? selectedSection?.id
              ? selectedSection?.id === id
              : selectedSection?.label
              ? selectedSection?.label === label
              : void 0
            : false;
          return (
            <SectionContainer
              key={index}
              index={index}
              styles={styles}
              expanded={expanded}
              expandable={expandable}
              isSelected={isSelected}>
              {typeof renderSectionItem === 'function' ? (
                renderSectionItem({
                  styles,
                  isSelected,
                  expandable,
                  expanded,
                  setExpanded: () => toggleExpanded(index),
                  menuItem,
                  ...rest,
                  ...restProps,
                })
              ) : (
                <SectionItem
                  styles={styles}
                  isSelected={isSelected}
                  expandable={expandable}
                  expanded={expanded}
                  setExpanded={() => toggleExpanded(index)}
                  menuItem={menuItem}
                  {...rest}
                  {...restProps}
                />
              )}
              {(!expandable || expanded || !label) && childMenus?.length ? (
                <Menus
                  parentSection={menuItem}
                  menus={childMenus}
                  {...props}
                  isSubMenu
                />
              ) : (
                void 0
              )}
            </SectionContainer>
          );
        } else {
          if (typeof renderMenuItem === 'function') {
            return (
              <React.Fragment key={index}>
                {renderMenuItem({
                  styles,
                  menuItem,
                  expandable,
                  expanded,
                  setExpanded: () => toggleExpanded(index),
                  isSelected: selectedMenu?.id
                    ? selectedMenu?.id == menuItem?.id
                    : selectedMenu?.label == menuItem?.label,
                  onSelect: onSelectMenu,
                  onSelectSection,
                  menuItem,
                  hideIcon: hideMenuIcon,
                  ...restProps,
                  ...menuItem,
                })}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <MenuItem
                  styles={styles}
                  expandable={expandable}
                  expanded={expanded}
                  setExpanded={() => toggleExpanded(index)}
                  isSelected={
                    selectedMenu?.id
                      ? selectedMenu?.id == menuItem?.id
                      : selectedMenu?.label == menuItem?.label
                  }
                  onSelect={onSelectMenu}
                  onSelectSection={onSelectSection}
                  menuItem={menuItem}
                  {...restProps}
                  {...menuItem}
                  hideIcon={hideMenuIcon}
                />
                {expanded && childMenus?.length ? (
                  <Menus menus={childMenus} {...props} />
                ) : (
                  void 0
                )}
              </React.Fragment>
            );
          }
        }
      })
    : null;
  return menusRender;
};

export const MenuBar = ({horizontal, menus, renderMenuFooter, ...props}) => {
  if (!menus?.length) return null;
  return (
    <>
      <MenuBarScrollView horizontal={horizontal} styles={props.styles}>
        <Menus menus={menus} {...props} />
        <EmptyMenuContainer styles={props.styles} />
      </MenuBarScrollView>
      {renderMenuFooter && renderMenuFooter(props)}
    </>
  );
};
