import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';
import { useTheme } from '@unthinkable/react-theme';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { salaryTemplateController } from './SalaryTemplateControllers';

export const SalaryTemplateTable = props => {
    const { navigation, route: { params } = {}, filter, addOnFilter, tab } = props;
    const colors = useTheme('colors');
    const {
        deleteSalaryTemplate,
        markTemplateActive,
        markTemplateInActive,
        editSalaryTemplateForm,
        duplicateSalaryTemplateForm,
        SalaryTemplateResourceList
    } = salaryTemplateController(props);
    return (
        <Table
            eventSourceId={['SalaryTemplate']}
            renderHeader={() => {
                if (tab === "Draft") {
                    return (
                        <TableHeader
                            actions={[
                                <AddButton
                                    title="Add Template"
                                    view="add-salary-template"
                                    params={params}
                                />
                            ]}
                        />
                    );
                }
            }}
            api={'/employeesalarycomponents'}
            addOnFilter={{ ...filter, ...addOnFilter }}
            onRowPress={({ row }) =>
                navigation.navigate('salary-template-detail', {
                    row,
                })
            }
            columns={[
                {
                    header: 'Name',
                    field: 'name',
                    type: 'text',
                },
                {
                    header: 'Amount',
                    type: 'number',
                    field: 'ctc_amount',
                },
            ]}
            moreActions={[
                {
                    title: 'Edit',
                    onPress: editSalaryTemplateForm,
                    visible: () => tab && tab !== 'InActive',
                },
                deleteSalaryTemplate({ tab }),
                markTemplateActive({ tab }),
                markTemplateInActive({ tab }),
                {
                    title: 'Duplicate',
                    onPress: duplicateSalaryTemplateForm,
                    visible: () => tab && tab === 'Active',
                },
                {
                    title: 'Resource',
                    onPress: SalaryTemplateResourceList,
                    visible: () => tab && tab != 'Draft',
                },
            ]}
        />
    );
};

const SalaryTemplateTab = props => {
    let { user = {}, route: { params = {} } = {}, ...rest } = props || {};
    const { applyFilter, filterValues, searchValue } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    let tabs = {};
    tabs['Draft'] = {
        label: 'Draft',
        eventSourceId: 'SalaryTemplate',
        view: <SalaryTemplateTable
            searchValue={searchValue}
            tab="Draft"
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: 'draft',
                is_master_component: true
            }}
            user={user}
            {...rest}
        />,
        addOnFilter: {
            ...filter,
            status: 'draft',
            is_master_component: true
        },
        api: '/employeesalarycomponents',
    },
        tabs['Active'] = {
            label: 'Active',
            eventSourceId: 'SalaryTemplate',
            view: <SalaryTemplateTable
                searchValue={searchValue}
                tab="Active"
                filterValues={filterValues}
                addOnFilter={{
                    ...filterValues.filter,
                    status: 'active',
                    is_master_component: true
                }}
                user={user}
                {...rest}
            />,
            addOnFilter: {
                ...filter,
                status: 'active',
                is_master_component: true
            },
            api: '/employeesalarycomponents',
        },
        tabs['InActive'] = {
            label: 'In Active',
            eventSourceId: 'SalaryTemplate',
            view: <SalaryTemplateTable
                searchValue={searchValue}
                tab="InActive"
                filterValues={filterValues}
                addOnFilter={{
                    ...filterValues.filter,
                    status: 'inActive',
                    is_master_component: true
                }}
                user={user}
                {...rest}
            />,
            addOnFilter: {
                ...filter,
                status: 'inActive',
                is_master_component: true
            },
            api: '/employeesalarycomponents',
        }
    return (
        <TabView
            tabs={tabs}
            // params={params}
            {...props}
        //   actions={[
        //     <GroupFilter
        //       filterValues={filterValues}
        //       applyFilter={applyFilter}
        //       filters={[
        //         {
        //           type: 'multiAutoComplete',
        //           label: 'Employee',
        //           api: '/employeeSuggestions',
        //           placeholder: 'Select',
        //           suggestionField: 'name',
        //           secondarySuggestionField: 'official_email_id',
        //           valueField: 'name',
        //           field: 'employee',
        //           searchFields: ['name', 'official_email_id', 'employee_code'],
        //         },
        //       ]}
        //     />,
        //   ]}
        />
    );
}

export const EmployeeSalaryTemplateTable = props => {
    const { route: { params } = {} } = props;
    const {row} = params;
    return (
        <Table
            eventSourceId={['SalaryTemplate']}
            renderHeader={() => {
                return (
                    <TableHeader
                        title={'Employee Using Selected Salary Template'}
                    />
                );
            }}
            api={`/salarytemplates/employee/${row?._id}`}
            columns={[
                { header: 'Code', field: 'employee.employee_code' },
                { header: 'Name', field: 'employee', type: 'userAvatarChip' },
                {
                    header: 'Designation',
                    field: 'employee.designation.name',
                    type: 'text',
                },
                {
                    header: 'Joining Date',
                    field: 'employee.joining_date',
                    type: 'date',
                },
                {
                    header: 'Reporting',
                    field: 'employee.reporting_to',
                    type: 'userAvatarGroup',
                    align: 'center',
                },
                {
                    header: 'Status',
                    field: 'employee.employee_status',
                    type: 'text',
                },
            ]}
        />
    );
};

export default SalaryTemplateTab;

