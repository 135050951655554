import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {ExpandRowIconRender} from '../../../components/table/Renders';
import {Linking, Text, TouchableOpacity, View} from '@unthinkable/react-core-components';
import { useTheme } from '@react-navigation/native';

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const {colors} = useTheme('colors');
  console.log(colors)
  const color = row?.url ? {color:colors['primary']} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.url &&
          Linking.openURL(
            row?.url.startsWith('https')
              ? row?.url
              : `https://${row?.url}`,
            '_blank',
          );
      }}>
      <Text
        title={row?.name}
        style={{
          ...rowText,
          ...color,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};


export const SkillTopicsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const topicDetails = ({row}) => {
    navigation.navigate('edit-skill-topic', {
      ...params,
      skill_topic: row,
    });
  };
  const {skill_metrics} = params;

  return (
    <Table
      renderHeader={props => (
        <TableHeader
          {...props}
          title="Skill Topic Library"
          actions={[
            <AddButton
              title="Skill Topic"
              view={'add-skill-topic'}
              params={params}
            />,
          ]}
        />
      )}
      api="/skilltopics"
      addOnFilter={{
        skill_metrics,
      }}
      eventSourceId={['SkillTopics', 'TrainingMaterial','TrainingMaterials']}
      fields={{
        name: 1,
        materials: {
          name: 1,
          url: 1,
          material_type: {
            name: 1,
            color: 1,
          },
        },
      }}
      renderExpandRow={({row}) => (
        <View
          style={{
            padding: 20,
          }}>
          <Table
            data={row?.materials || []}
            columns={[
              {
                field: 'name',
                header: 'Material',
                render:RenderName
              },
              {
                field: 'material_type.name',
                colorField: 'material_type.color',
                header: 'Category',
                type: 'colorChip',
              },
            ]}
          />
        </View>
      )}
      columns={[
        {
          render: ExpandRowIconRender,
          expandable: true,
          width: 50,
        },
        {
          field: 'name',
          header: 'Topic',
          type: 'text',
        },
        // {
        //   render: CountCellRender,
        //   width: 200,
        //   header: 'Materials',
        //   align: 'right',
        //   count_field: 'material_count',
        //   onPlusPress: ({row}) => {
        //     navigation.navigate(`add-training-material`, {
        //       ...params,
        //       skill_topic: row,
        //     });
        //   },
        //   onPress: ({row}) => {
        //     navigation.navigate(`training-material-list`, {
        //       ...params,
        //       skill_topic: row,
        //       label: row?.name,
        //     });
        //   },
        // },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: topicDetails,
        },
        {
          title: 'Add Material',
          onPress: ({row}) => {
            navigation.navigate(`add-training-material`, {
              ...params,
              skill_topic: row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
