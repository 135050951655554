import React from 'react';
import {Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {RenderSkills} from './BenchResource';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';
import {Text, Row} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {getCurrentDay} from './EmployeeTeamTabs';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const ManagerSkillForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'AllManagers',
    ...props,
  });

  const {manager} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        _id: manager?._id,
        manager_skill_tool: manager?.manager_skill_tool,
      }}
      onSubmit={onSubmit}
      header={'Select Language'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Language',
          label: 'Language',
          field: 'manager_skill_tool',
          type: 'multiAutoComplete',
          api: `/tools`,
          suggestionField: 'name',
        },
      ]}
    />
  );
};

const ManagerSkillRender = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: CountCellRender,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`add-manager-skills`, {
              manager: row,
            });
          },
          visible: ({row}) =>
            !row?.manager_skill_tool || row?.manager_skill_tool == 0,
        },
        {
          field: 'manager_skill_tool',
          labelField: 'name',
          titleField: 'name',
          colorField: 'color',
          render: MultiColorChip,
          maxChips: 1,
          numberOfLines: 1,
          onPress: props => {
            const {row} = props;
            navigation.navigate(`add-manager-skills`, {
              manager: row,
            });
          },
          visible: ({row}) =>
            row?.manager_skill_tool && row?.manager_skill_tool.length,
        },
      ]}
    />
  );
};

const RenderBenchAvailability = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <>
      <Tag value={row?.available_for_opportunity_count} />
      {!isNaN(Number(row?.bench_percentage)) &&
      !Number(row?.bench_percentage) == 0.0 ? (
        Number(row?.bench_percentage) < 10 ? (
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.ERROR_HIGH,
              paddingLeft: 10,
            }}>
            {row?.bench_percentage} %
          </Text>
        ) : (
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {row?.bench_percentage} %
          </Text>
        )
      ) : null}
    </>
  );
};

const ManagerDashboard = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;

  return (
    <Table
      {...props}
      api={'/employees/managerdashboard'}
      eventSourceId={['AllManagers']}
      search={searchValue}
      addOnFilter={filter}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      renderHeader={() => (
        <TableHeader
          title="Manager"
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  label: 'Practice',
                  field: 'primary_practice',
                  type: 'autoComplete',
                  api: '/practices',
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoComplete',
                  placeholder: 'Select',
                  options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
                  label: 'Experience Range',
                  field: 'experience_range',
                },
              ]}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {name, color, official_email_id} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={name}
                color={color}
                official_email_id={official_email_id}
              />
            );
          },
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Language',
          render: props => {
            return (
              <ManagerSkillRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
        },
        {
          header: 'Team',
          children: [
            {
              render: CountCellRender,
              header: 'Total',
              field: 'total_count',
              count_field: 'total_count',
              aggregate: true,
              type: 'number',
              width: 70,
              onPress: ({row}) => {
                const employeeIds = row?.skill_leader_team.map(
                  item => item._id,
                ) || ['xxxx'];
                navigation.navigate(`employee-workload`, {
                  employeeFilter: {_id: {$in: employeeIds}},
                });
              },
            },
            {
              render: CountCellRender,
              header: 'Billed',
              field: 'billed_count',
              count_field: 'billed_count',
              aggregate: true,
              type: 'number',
              width: 70,
            },
            {
              render: CountCellRender,
              header: 'Bench',
              field: 'unbilled_count',
              count_field: 'unbilled_count',
              aggregate: true,
              type: 'number',
              width: 70,
            },
            {
              render: CountCellRender,
              header: 'Avg Exp',
              field: 'avg_experience',
              count_field: 'avg_experience',
              type: 'number',
              width: 70,
            },
            {
              render: RenderBenchAvailability,
              header: 'Available bench',
              field: 'available_for_opportunity_count',
              aggregate: true,
              type: 'number',
              align:"center",
              width: 150,
            },
            // {
            //   header: 'Winnability',
            //   width: 70,
            //   field: 'winnablity',
            //   type: 'text',
            // },
          ],
        },
        {
          header: 'Self',
          children: [
            {
              render: CountCellRender,
              header: 'Billed',
              field: 'self_billed_count',
              count_field: 'self_billed_count',
              aggregate: true,
              type: 'number',
              width: 70,
            },
          ],
        },
        {
          render: CountCellRender,
          header: 'Open Opp.',
          field: 'total_open_opportunities',
          count_field: 'total_open_opportunities',
          onPress: ({row}) => {
            const ids = row.open_opportunities.reduce((acc, currentArray) => {
              const ids = currentArray.map(item => item._id);
              return acc.concat(ids);
            }, []);
            navigation.navigate('opportunity-analytics-active-engineering', {
              row: ids,
            });
          },
          // aggregate: true,
          type: 'number',
          width: 100,
        },
      ]}
      params={{...params, period}}
    />
  );
};

export default ManagerDashboard;
