import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const EmailSignatureAddForm = props => {
  const {header = 'Add Signature'} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/emailsignatures',
    eventSourceId: ['signaturesAdded'],
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
          required: true,
        },
        {
          label: 'Signature',
          field: 'signature',
          type: 'richText',
        },
      ]}
      {...props}
    />
  );
};

export const EditEmailSignatureAddForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <EmailSignatureAddForm
      mode="edit"
      api={`/emailsignatures/${_id}`}
      header={'Edit Email Signature'}
      {...props}
    />
  );
};
