import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useMarkStrategicInitiativeInactive} from '../controllers';
import {useNavigation} from '@react-navigation/native';
import {
  CompletedRender,
  MarkCompletedRender,
} from '../../../components/table/Renders';
import {UnderlineButton} from '../../../components/button/UnderlineButton';
import {AddButton} from '../../../components/button/AddButton';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';

const NAME = {
  header: 'Object Name',
  field: 'name',
  type: 'text',
  responsive: 'sm',
};

const LEADERCHIP = {
  type: 'userAvatar',
  header: 'Leader',
  field: 'leader',
};

const TARGETDATE = {
  type: 'date',
  header: 'Target', //icon required in header
  field: 'date',
  width: 80,
};

const RESOURCES = {
  field: 'resources',
  header: 'Resources', //header to be changed
  type: 'userAvatarGroup',
  width: 120,
};

const StrategicInitiativesTable = props => {
  const {
    route: {params = {}},
    filter,
    isCompleted = false,
  } = props;
  const {department, product, organization} = params;
  const navigation = useNavigation();
  const markInactive = useMarkStrategicInitiativeInactive(props);
  return (
    <Table
      api="/strategicInitiatives"
      eventSourceId={['reloadStrategicInitiative', 'Task']}
      params={params}
      renderHeader={() => {
        return (
          <TableHeader
            title={'Custom Objects'}
            actions={
              !isCompleted
                ? [
                    <UnderlineButton
                      onPress={() => {
                        navigation.navigate(
                          'completed-strategic-initiatives-list',
                          {...params},
                        );
                      }}
                      text={'Completed'}
                    />,
                    <ObjectWiseTasks
                      {...props}
                      object={{
                        label: 'Custom Object',
                        _id: '64febeefa69e3a5a80847c3f',
                      }}
                      params={params}
                    />,
                    <AddButton
                      title="Custom Object"
                      view="add-strategic-initiative"
                      params={params}
                    />,
                  ]
                : []
            }
          />
        );
      }}
      fields={{
        name: 1,
        department: {name: 1},
        leader: {
          name: 1,
        },
        resources: {
          name: 1,
        },
        date: 1,
        status: 1,
        open_for: 1,
        is_open: 1,
        resource_interests: {
          resource: {
            name: 1,
          },
          interest: 1,
          date: 1,
        },
        kpi: {
          kpi: 1,
        },
      }}
      filter={
        department || product || organization
          ? {
              // department: department?._id,
              // product: product?._id,
              // organization: organization?._id,
              status: 'Active',
              ...filter,
            }
          : {
              // $or: [{leader: employeeId}, {resources: employeeId}],
              status: 'Active',
              ...filter,
            }
      }
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation?.navigate(`edit-strategic-initiative`, {...params, row});
          },
        },
        {
          title: 'Close',
          onPress: markInactive,
        },
      ]}
      columns={[
        isCompleted
          ? {
              width: 40,
              render: CompletedRender,
            }
          : {
              width: 40,
              onPress: ({row}) => {
                navigation?.navigate(`complete-strategic-initiative`, {
                  ...params,
                  row,
                });
              },
              render: MarkCompletedRender,
            },
        NAME,
        TARGETDATE,
        LEADERCHIP,
        RESOURCES,
      ]}
    />
  );
};

export default StrategicInitiativesTable;
