import React, {useEffect, useRef, useState} from 'react';
import {Row} from '@unthinkable/react-core-components';
import {DurationInputCell, DurationInputContainer} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';

const DurationInput = props => {
  let {
    styles,
    hrPlaceholder = 'Hr',
    minPlaceholder = 'Min',
    value,
    inputStyle,
    error,
    ...restProps
  } = props;

  const [isHrActive, setIsHrActive] = useState(false);
  const [isMinActive, setIsMinActive] = useState(false);
  const hrRef = useRef('');
  const minRef = useRef('');

  const getValue = value => {
    if (value !== undefined && value !== null) {
      hrRef.current = Math.floor(value).toString(); // Store hours as string
      minRef.current = Math.round((value - Math.floor(value)) * 60).toString(); // Store minutes as string
    } else {
      hrRef.current = '';
      minRef.current = '';
    }
  };

  const onChangeValue = e => {
    let {onChangeValue} = props;
    let newValue =
      (parseInt(hrRef.current, 10) || 0) +
      (parseInt(minRef.current, 10) || 0) / 60;
    if (newValue % 1 !== 0) {
      newValue = parseFloat(newValue.toFixed(2));
    }
    onChangeValue && onChangeValue(newValue, e);
  };

  const onChangeHr = (value, e) => {
    if (value === '') {
      hrRef.current = '';
      onChangeValue(e);
      return;
    }
    value = Number(value);
    if (isNaN(value)) {
      hrRef.current = hrRef.current === undefined ? '' : hrRef.current;
      return;
    }
    let {maxHr} = props;
    if (!maxHr || value < maxHr) {
      hrRef.current = value.toString();
      onChangeValue(e);
    }
  };

  const onChangeMin = (value, e) => {
    if (value === '') {
      minRef.current = '';
      onChangeValue(e);
      return;
    }
    value = Number(value);
    if (isNaN(value)) {
      minRef.current = minRef.current === undefined ? '' : minRef.current;
      return;
    }
    // Allow minutes to be between 0 and 59
    if (value >= 0 && value < 60) {
      minRef.current = value.toString();
      onChangeValue(e);
    }
  };

  const onFocus = e => {
    let {onFocus} = props;
    onFocus && onFocus(e);
  };

  const onFocusHr = e => {
    setIsHrActive(true);
    onFocus(e);
  };

  const onFocusMin = e => {
    setIsMinActive(true);
    onFocus(e);
  };

  const onBlur = e => {
    let {onBlur} = props;
    onBlur && onBlur(e);
  };

  const onBlurHr = e => {
    setIsHrActive(false);
    onBlur(e);
  };

  const onBlurMin = e => {
    setIsMinActive(false);
    onBlur(e);
  };

  useEffect(() => {
    getValue(value);
  }, [value]);

  const hrDisplayValue = isHrActive ? hrRef.current : hrRef.current + ' Hr';
  const minDisplayValue = isMinActive
    ? minRef.current
    : minRef.current + ' Min';
  return (
    <InputWrapper {...props}>
      <Row gap={12}>
        <DurationInputContainer styles={styles} error={error}>
          <DurationInputCell
            {...restProps}
            styles={styles}
            placeholder={hrPlaceholder}
            isActive={isHrActive}
            value={hrDisplayValue}
            onChangeValue={onChangeHr}
            onFocus={onFocusHr}
            onBlur={onBlurHr}
          />
        </DurationInputContainer>
        <DurationInputContainer styles={styles} error={error}>
          <DurationInputCell
            {...restProps}
            styles={styles}
            placeholder={minPlaceholder}
            isActive={isMinActive}
            value={minDisplayValue}
            onChangeValue={onChangeMin}
            onFocus={onFocusMin}
            onBlur={onBlurMin}
          />
        </DurationInputContainer>
      </Row>
    </InputWrapper>
  );
};

export default DurationInput;
