import React from 'react';
import {TabView} from '../../../components/tab';
import {ReferralTable} from './ReferralTable';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';

export const ReferralTabs = props => {
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter();
  const {download} = useAppStateContext();
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <ReferralTable
          {...props}
          addOnFilter={{
            added_to_job_opening: {
              $in: [null, false],
            },
            status: {
              $nin: ['Rejected'],
            },
            ...filterValues?.filter,
          }}
          fromOpening
          showStage={false}
        />
      ),
      api: '/referralCandidates',
      filter: {
        added_to_job_opening: {
          $in: [null, false],
        },
        status: {
          $nin: ['Rejected'],
        },
        ...filterValues?.filter,
      },
      eventSourceId: 'ReferralCandidate',
      search: searchValue,
      searchFields: ['name', 'email'],
    },
    closed: {
      label: 'Closed',
      view: (
        <ReferralTable
          {...props}
          addOnFilter={{
            $or: [
              {added_to_job_opening: true},
              {
                status: 'Rejected',
              },
            ],
            ...filterValues?.filter,
          }}
          fromOpening
          showStage
        />
      ),
      api: '/referralCandidates',
      filter: {
        $or: [
          {added_to_job_opening: true},
          {
            status: 'Rejected',
          },
        ],
        ...filterValues?.filter,
      },
      eventSourceId: 'ReferralCandidate',
      search: searchValue,
      searchFields: ['name', 'email'],
      actions: [
        <Button
          text="Download"
          onPress={async () => {
            await download({
              uri: `/exportExcelHelper`,
              props: {
                props: {
                  fetchProps: {
                    api: '/referralCandidates',
                    params: {
                      ...props.params,
                      ...filterValues.params,
                    },
                    addOnFilter: filterValues?.filter,
                    search: searchValue,
                    searchFields: ['name', 'email'],
                  },
                  column: {
                    Name: 'name',
                    Email: 'email',
                    'Reffred On': 'createdAt',
                    'Job Opening': 'job_opening.position_title.name',
                    'Reffered By': 'referred_by.name',
                    'Reffered by Email': 'referred_by.official_email_id',
                    'Refferal Status': 'status',
                    'Candidate Status ': 'candidate.status',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      tabs={tabs}
      search={searchValue}
      searchFields={['name', 'email']}
      actions={[
        <SearchFilter
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          placeholder="Search Candidate"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Job Opening',
              field: 'job_opening',
              api: '/jobOpenings',
              type: 'autoComplete',
              suggestionField: 'position_title.name',
              getDisplayValue: ({value}) => value?.position_title?.name,
              placeholder: 'Position',
              fields: {
                position_title: {
                  name: 1,
                },
              },
            },
          ]}
        />,
      ]}
    />
  );
};
