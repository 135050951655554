import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const BloodGroup = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/bloodgroups',
        eventSourceId: 'bloodgroup',
    });
    return (
        <Form
            api={`/bloodgroups/${row?._id}`}
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    label: 'Name',
                    type: 'text',
                    field: 'name',
                },
            ]}
            {...props}
        />
    );
};

export const AddBloodGroup = props => {
    return <BloodGroup header="Add Blood Group" {...props} />;
};

export const EditBloodGroup = props => {
    return (
        <BloodGroup
            mode="edit"
            header={'Update Blood Group'}
            {...props}
        />
    );
};
