import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {STATUS} from './TargetAudienceTable';

export const PaidAdForm = props => {
  const {
    route: {params},
    defaultValue,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaigns',
    eventSourceId: 'paidAdCampaign',
    ...props,
  });
  const {product, row, afterSubmit, searchValue} = params;
  return (
    <Form
      api={`/campaigns/${row?._id}`}
      fields={{
        name: 1,
        adId: 1,
        ad_type: 1,
        description: 1,
        owner: {name: 1, color: 1},
        channel: {name: 1},
        employee_team: {name: 1},
        organization: {name: 1},
      }}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
      header={'Add Paid Ad'}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        product: product?._id,
        created_on: new Date(),
        name: searchValue,
        status: STATUS.IN_EXPERIMENT,
      }}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
          size: 6,
          required: true,
        },
        {
          placeholder: 'Ad Id',
          field: 'adId',
          type: 'text',
          size: 6,
          required: true,
        },
        {
          placeholder: 'Ad Type',
          field: 'ad_type',
          type: 'autoComplete',
          options: [
            'Facebook',
            'Google Daffodil',
            'Google Unthinkable',
            'Linkedin',
          ],
          size: 6,
          required: true,
        },
        {
          placeholder: 'Description',
          field: 'description',
          type: 'text',
          size: 6,
        },
        {
          placeholder: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 6,
        },
        {
          label: 'Channel',
          field: 'channel',
          type: 'autoComplete',
          api: `/channels`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          filter: {
            _id: {
              $nin: ['66d2ec16f897151a53daf08b'],
            },
          },
          required: true,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          label: 'Employee Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: `/employeeteams`,
          filter: {
            _id: {
              $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
            },
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditPaidAdsForm = props => {
  return <PaidAdForm header={'Edit Paid Ad'} mode="edit" {...props} />;
};
