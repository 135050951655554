import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const GeographicForm = props => {
  const params = props.route.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/geographylocations',
    eventSourceId: 'Geographic'
  });
  return (
    <Form
      api={`/geographylocations/${row?._id}`}
      onSubmit={onSubmit}
      header={props.header}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Score',
          field: 'score',
          type: 'number',
        },
      ]}
      {...props}
    />
  );
};

export const AddGeographicForm = props => {
  return <GeographicForm header="Add Geographic" {...props} />;
};

export const UpdateGeographicForm = props => {
  return <GeographicForm mode="edit" header="Update Geographic" {...props} />;
};
