import React from 'react';
import { Form, InlineForm } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddTicketOwner = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/ticketOwners',
    eventSourceId: 'TicketOwner',
  });
  const {
    route: {params},
  } = props;
  const {employee_team} = params;
  return (
    <InlineForm
      header="Add Ticket Owners"
      onSubmit={onSubmit}
      defaultValues={{
        employee_team: employee_team?._id,
      }}
      layoutFields={[
        {
          label: 'Category',
          field: 'category',
          type: 'autoComplete',
          api: '/ticketcategories',
          suggestionField: 'name',
          filter: {
            employee_team: employee_team?._id,
          },
          required: true,
        },
        {
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
      ]}
    />
  );
};

export const EditTicketOwnerForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/ticketOwners',
    eventSourceId: 'TicketOwner',
  });
  const {
    route: {params},
  } = props;
  const {employee_team, ticket_owner} = params;
  return (
    <Form
      header="Edit Ticket Owner"
      onSubmit={onSubmit}
      defaultValues={{
        employee_team: employee_team?._id,
      }}
      api={'/ticketOwners/' + ticket_owner._id}
      mode="edit"
      fields={{
        category: {
          name: 1,
        },
        owner: {
          name: 1,
        },
      }}
      layoutFields={[
        {
          label: 'Category',
          field: 'category',
          type: 'autoComplete',
          api: '/ticketcategories',
          suggestionField: 'name',
          filter: {
            employee_team: employee_team?._id,
          },
          required: true,
        },
        {
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
      ]}
    />
  );
};
