export const CustomerDashboardOverview = ({theme: {fonts, colors}}) => {
  return {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE3,
      overflow: 'hidden',
      borderRadius: 6,
      //   display: 'flex',
    },
    viewContainer: {
      padding: 12,
      display: 'flex',
      borderRadius: 6,
    },
    tableHeaderStyle: {
      ...fonts.CAPTION_LARGE,
      color: '#1A1A1A',
      display: 'flex',
    },
    topSourceStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    activeProjectCountStyle: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    customerConvertedStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    cardStyle: {
      flex: 1,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE1,
      height: 100,
      width: 150,
    },
    detailcardStyle: {
      flex: 1,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE1,
      height: 80,
      width: 150,
    },
  };
};
