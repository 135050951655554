import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';

import {HeaderStyle} from './theme';

const Header = props => {
  const {UnthinkableIcon, headerTextStyle, emailStyle, logOutStyle} =
    useStyles(HeaderStyle);

  const {email, logout} = props || {};

  return (
    <Row style={{alignItems: 'center'}} gap={12}>
      <Image source={UnthinkableIcon} />
      <Row gap={12} style={{flex: 1}}>
        <View>
          <Text style={headerTextStyle}>Logged in as :</Text>
          <Text style={emailStyle}>{email}</Text>
        </View>
      </Row>
      <TouchableOpacity onPress={logout}>
        <Text style={logOutStyle}>Logout</Text>
      </TouchableOpacity>
    </Row>
  );
};
export default Header;
