import React from 'react';
import {AssignedTicketTabs} from './views/AssignedTicketsTabs';
import {EmployeeTicketTabs} from './views/EmployeeTicketsTabs';
import {TicketHeadTabs} from './views/TicketsMenus';
import { OrgTicketTabs } from './views/OrgTicketTabs';

export const Screens = [
  {
    menuItemId: 'orgtickets',
    views: [
      {
        id: 'OrgTickets',
        label: 'Org Tickets',
        view: props => {
          return <OrgTicketTabs {...props} />;
        },
      },
    ],
  },
  {
    menuItemId: 'teamtickets',
    views: props => {
      const {user} = props;
      let views = [];
      user?.employee?.ticket_team?.forEach(employee_team => {
        const teamId = employee_team.name.replace(/\s+/g, '').toLowerCase();
        views.push({
          id: `teamticket${teamId}`,
          label: employee_team.name,
          view: (
            <TicketHeadTabs
              key={employee_team._id}
              user={user}
              employee_team={employee_team}
            />
          ),
        });
      });
      return views;
    },
  },
  {
    menuItemId: 'assignedtickets',
    views: [
      {
        id: 'AssignedTicket',
        label: 'Assigned Ticket',
        view: props => {
          return <AssignedTicketTabs {...props} />;
        },
      },
    ],
  },
  {
    menuItemId: 'myticket',
    views: [
      {
        id: 'MyTicket',
        label: 'My Ticket',
        view: props => {
          return <EmployeeTicketTabs {...props} />;
        },
      },
    ],
  },
];

export const MenuItems = [
  {
    id: 'orgtickets',
    label: 'Org Ticket',
  },
  {
    id: 'teamtickets',
    label: 'Team Ticket',
  },
  {
    id: 'assignedtickets',
    label: 'Assigned Ticket',
  },
  {
    id: 'myticket',
    label: 'My Ticket',
  },
];
