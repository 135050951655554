import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const UnthinkablePageForm = props => {
  const {
    route: { params },
  } = props;
  const { row } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/pages',
    eventSourceId: 'page',
  });
  return (
    <Form
      api={`/pages/${row?._id}`}
      fields={{
        title: 1,
        metatitle: 1,
        metadesc: 1,
        type: 1,
        index: 1,
        domain: {
          domain: 1,
        },
        blogs: {
          title: 1,
        },
        career: {
          position_title: {
            name: 1,
          },
        },
        case_study: {
          title: 1,
        }
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          label: 'Metatitle',
          type: 'text',
          field: 'metatitle',
        },
        {
          label: 'Metadescription',
          type: 'textArea',
          field: 'metadesc',
        },
        {
          label: 'Type',
          type: 'autoComplete',
          options: [{ label: 'Domain', _id: 'domain' }, { label: 'Blogs', _id: 'blogs' }, { label: 'Career', _id: 'career'}, {label: 'Case Study', _id: 'case-study'}],
          suggestionField: 'label',
          keyField: '_id',
          valueField: 'label',
          field: 'type',
        },
        {
          label: 'Domain',
          type: 'autoComplete',
          api: '/domains',
          suggestionField: 'domain',
          valueField: 'domain',
          field: 'domain',
          required: true,
          visible: ({ values }) => values.type === 'domain'
        },
        {
          label: 'Blog',
          type: 'autoComplete',
          api: '/blogmodels',
          suggestionField: 'title',
          valueField: 'title',
          field: 'blogs',
          required: true,
          visible: ({ values }) => values.type === 'blogs'
        },
        {
          label: 'Case Study',
          type: 'autoComplete',
          api: '/casestudymodels',
          suggestionField: 'title',
          valueField: 'title',
          field: 'case_study',
          required: true,
          visible: ({ values }) => values.type === 'case-study'
        },
        {
          label: 'Career',
          type: 'autoComplete',
          api: '/jobopenings',
          fields: {
            position_title: {
              name: 1,
            },
          },
          filter: {
            status: 'Active',
          },
          suggestionField: 'position_title.name',
          valueField: 'position_title.name',
          field: 'career',
          required: true,
          visible: ({ values }) => values.type === 'career'
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
      ]}
      {...props}
    />
  );
};

export const AddUnthinkablePageForm = props => {
  return <UnthinkablePageForm header="Add Page" {...props} />;
};

export const UpdateUnthinkablePageForm = props => {
  return (
    <UnthinkablePageForm mode="edit" header="Update Page" {...props} />
  );
};
