import React from 'react';
import {TabView} from '../../../components/tab';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {AllUserTable, ClientUserTable} from './ClientUserTableView';

export const ClientUserTabs = props => {
  const {
    user,
    route: {params},
  } = props;
  const {org} = user;
  const {searchValue, onChangeFilter} = useFilter({});
  const allFilter = {
    org,
  };
  return (
    <TabView
      selectedTab={'clients'}
      tabs={{
        clients: {
          label: 'Clients',
          view: (
            <ClientUserTable
              addOnFilter={{
                ...allFilter,
              }}
              searchValue={searchValue}
              {...props}
            />
          ),
          api: `/clientUsers`,
          addOnFilter: {
            ...allFilter,
          },
          eventSourceId: 'ClientUser',
        },
      }}
      {...props}
      actions={() => {
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <AddButton
            title={'User'}
            view={'client-add-user'}
            params={{
              user,
              ...params,
            }}
          />,
        ];
      }}
    />
  );
};

export const AllUsersTabs = props => {
  const {
    user,
    route: {params},
  } = props;
  const {org} = user;
  const {searchValue, onChangeFilter} = useFilter({});
  const allFilter = {
    org,
  };
  return (
    <TabView
      selectedTab={'all'}
      tabs={{
        all: {
          label: 'All',
          view: (
            <AllUserTable
              // addOnFilter={{
              //   ...allFilter,
              // }}
              searchValue={searchValue}
              {...props}
            />
          ),
          api: `/users`,
          // addOnFilter: {
          //   ...allFilter,
          // },
          eventSourceId: 'AllUser',
        },
      }}
      {...props}
      actions={() => {
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <AddButton
            title={'User'}
            view={'add-all-user'}
            params={{
              user,
              ...params,
            }}
          />,
        ];
      }}
    />
  );
};
