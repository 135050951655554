import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import moment from 'moment';

export const UpdateCompleteDateForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projecttasks',
    eventSourceId: 'Task',
  });
  const {
    route: {params},
    defaultValues: oldDefaultValues,
  } = props;

  const {row = {}, project} = params;

  const defaultValues = {...oldDefaultValues};

  if (project?._id) {
    defaultValues.project_id = project?._id;
  }

  return (
    <Form
      onSubmit={onSubmit}
      api={`/projecttasks/${row?._id}`}
      submitAction="Save"
      mode="edit"
      header={'Update Completed date'}
      defaultValues={defaultValues}
      beforeSubmit={({data}) => {
        if (
          data.completed_on &&
          moment.utc(data.completed_on).startOf('day').toDate() >
            moment.utc().startOf('day').toDate()
        ) {
          throw new Error('Completed date should not be future date');
        }
      }}
      layoutFields={[
        {
          label: 'Completed Date',
          placeholder: 'Completed Date',
          field: 'completed_on',
          type: 'date',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
