import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {TableHeader} from '../../../components/table/Headers';
import {TextRenderer} from '../../../components/form/Renderers';

const RenderCourses = props => {
  return (
    <TextRenderer
      value="Courses"
      style={{
        color: '#007AFF',
      }}
    />
  );
};

export const InactiveTrainerList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  return (
    <Table
      renderHeader={() => <TableHeader title="Inactive Trainers" />}
      api={`/getInactiveTrainers`}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 300,
        },
        {
          header: 'Exp',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },

        {
          header: 'Reason',
          field: 'trainer_inactive_reason',
          type: 'text',
        },
        {
          render: RenderCourses,
          onPress: ({row}) => {
            navigation.navigate('inactive-trainer-courses', {
              mentor: row,
            });
          },
          width: 80,
        },
      ]}
      {...props}
    />
  );
};
