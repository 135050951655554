import React from 'react';
import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {ReviewStyles} from '../theme';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import moment from 'moment';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {useFetchData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';

const LabelledRating = props => {
  const {label} = useStyles(ReviewStyles);
  return (
    <Col gap={6} style={{flex: 1}}>
      <Text style={label}>{props?.label}</Text>
      <RatingRenderer value={props.rating} />
    </Col>
  );
};

const ReviewCard = props => {
  const {row, navigation} = props;
  const {duration, note} = useStyles(ReviewStyles);
  const {user} = useAppStateContext();
  const {ERROR_HIGH, ERROR_LOW, SUCCESS_HIGH, SUCCESS_LOW} = useTheme('colors');

  const {status, reviewed_by, createdAt, comment} = row;
  const titleCaseStatus = status?.[0]?.toUpperCase() + status?.slice(1);
  return (
    <Col gap={8} style={{padding: 16}}>
      <Row gap={20} style={{alignItems: 'center'}}>
        <View style={{flex: 1}}>
          <UserAvatarChip value={reviewed_by} />
        </View>
        <Tag
          value={titleCaseStatus}
          textColor={status === 'accepted' ? SUCCESS_HIGH : ERROR_HIGH}
          color={status === 'accepted' ? SUCCESS_LOW : ERROR_LOW}
        />
        <Text style={duration}>{moment(createdAt).format('DD MMM YY')}</Text>
        <MoreActions
          actions={[
            {
              title: 'Edit',
              visible: () => reviewed_by?._id === user?._id,
              onPress: () => {
                navigation.navigate('edit-review', {
                  row,
                });
              },
            },
          ]}
        />
      </Row>
      <Row gap={20}>
        <LabelledRating
          rating={row?.review_estimated_time}
          label={'Estimate Time:'}
          field={'review_estimated_time'}
        />
        <LabelledRating
          rating={row?.review_quality_standards}
          label={'Quality Standards:'}
          field={'review_quality_standards'}
        />
      </Row>
      <Text style={note}>{comment}</Text>
    </Col>
  );
};

export const AllReviews = ({row, navigation}) => {
  const {data} = useFetchData({
    api: '/taskreviews',
    filter: {task_id: row._id},
    fields: {
      status: 1,
      reviewed_by: {
        name: 1,
        email: 1,
      },
      reviewed_on: 1,
      review_estimated_time: 1,
      review_quality_standards: 1,
      review_avg: 1,
      comment: 1,
      createdAt: 1,
    },
    sort: {createdAt: -1},
    eventSourceId: ['TaskReview'],
  });
  if (!data?.length) {
    return;
  }
  return (
    <Table
      data={data}
      renderRow={props => <ReviewCard {...props} navigation={navigation} />}
    />
  );
};
