import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const employeeController = props => {
  const {params, navigation} = props;
  const {post, user} = useAppStateContext();
  const invoke = useInvoke({method: 'put'});
  const invokePost = useInvoke({
    method: 'post',
    eventSourceId: [
      'popuateMessage',
      'populateBusiness',
      'sendAction',
      'updateBrowser',
      'UnlockRequest',
      'RemoveLocks',
      'removeError',
    ],
  });
  const invokeDelete = useInvoke({
    method: 'delete',
    eventSourceId: ['communicationAdded', 'indexUpdated'],
  });

  const period = params?.period;
  let previousPeriod = {};
  if (period?.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period?.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = getZeroTimeDate(
      moment(period?.from).subtract(1, 'M').endOf('M').toDate(),
    );
  }
  const periodFilter = period
    ? {
        $lte: period?.to,
        $gte: period?.from,
      }
    : void 0;

  const preViousPeriodFilter = previousPeriod
    ? {
        $lte: previousPeriod?.to,
        $gte: previousPeriod?.from,
      }
    : void 0;

  const markPip = ({row}) => {
    navigation.navigate(`mark-employee-pip`, {
      ...params,
      row,
    });
  };

  const markPotentialPip = ({row}) => {
    navigation.navigate(`mark-potential-pip`, {
      ...params,
      row,
    });
  };

  const holdUnholdSalary = ({row}) => {
    navigation.navigate(`hold-unhold-salary`, {
      ...params,
      row,
    });
  };
  const startNodues = ({row}) => {
    navigation.navigate(`start-nodues-form`, {
      ...params,
      row,
    });
  };

  const closeBackgroundVerification = ({row}) => {
    navigation.navigate(`close-background-verification-form`, {
      ...params,
      row,
    });
  };

  const markLegal = ({row}) => ({
    title: 'Mark Legal',
    message: 'Resource has been mark legal successfully.',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`mark-employee-legal`, {
        ...params,
        row,
      });
    },
  });

  const unmarkLegal = ({row}) => ({
    title: 'Unmark Legal',
    confirm: {
      title: 'Unmark Legal',
      message: 'Are you sure you want to unmark legal?',
    },

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/employees/${row?._id}`,
        message: 'Unmarked successfully',
        props: {
          legal: false,
        },
      });
    },
  });

  const withdrawLegalProceedings = ({row}) => ({
    title: 'Withdraw Legal Proceedings',
    confirm: {
      title: 'Withdraw Legal Proceedings',
      message: 'Are you sure you want to withdraw legal proceedings?',
    },

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/employees/${row?._id}`,
        message: 'Withdraw successfully',
        props: {
          legal: null,
          legal_marked_on: null,
          legal_status: null,
        },
      });
    },
  });

  const markRecoveryPending = ({row}) => ({
    title: 'Mark Recovery Pending',
    message: 'Successfully Marked',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`mark-employee-recovery-pending`, {
        ...params,
        row,
      });
    },
  });

  const markInactive = ({row}) => {
    navigation.navigate(`mark-inactive`, {
      ...params,
      row,
    });
  };

  const unmarkPip = ({row}) => {
    navigation.navigate(`unmark-employee-pip`, {
      ...params,
      row,
    });
  };

  const EmployeeDetails = ({row}) => {
    navigation.navigate(`employee-detail-form`, {
      ...params,
      row,
    });
  };

  const EmployeeDetailsEditable = ({row}) => {
    navigation.navigate(`employee-detail-form-editable`, {
      ...params,
      row,
    });
  };

  const extendPip = ({row}) => {
    navigation.navigate(`extend-employee-pip`, {
      ...params,
      row,
    });
  };

  const updatePipMentor = ({row}) => {
    navigation.navigate(`update-pip-metor`, {
      ...params,
      row,
    });
  };

  const createEmployee = ({row}) => {
    navigation.navigate(`add-employee-form`, {
      ...params,
      row,
    });
  };

  const createEmployeeFromParichay = ({row}) => {
    navigation.navigate(`add-employee-with-package-form`, {
      ...params,
      row,
    });
  };

  const clearEmployeeNoDues = ({row}) => {
    navigation.navigate(`employee-no-dues-form`, {
      ...params,
      row,
    });
  };

  const clearTeamNoDues = ({row}) => {
    navigation.navigate(`team-no-dues-form`, {
      ...params,
      row,
    });
  };

  const clearAccountsNoDues = ({row}) => {
    navigation.navigate(`accounts-no-dues-form`, {
      ...params,
      row,
    });
  };
  const clearHRNoDues = ({row}) => {
    console.log('row-', row);
    navigation.navigate(`hr-no-dues-form`, {
      ...params,
      row,
    });
  };

  const clearAdminNoDues = ({row}) => {
    navigation.navigate(`admin-no-dues-form`, {
      ...params,
      row,
    });
  };
  const clearITNoDues = ({row}) => {
    navigation.navigate(`it-no-dues-form`, {
      ...params,
      row,
    });
  };
  const CancelNodues = ({invoke}) => ({
    title: 'Cancel Nodues',
    confirm: {
      title: 'Cancel Nodues',
      message: 'Are you sure you want to cancel?',
    },

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/nodues/${row?._id}`,
        message: 'Cancelled successfully',
        props: {
          is_cancelled: true,
          cancel_date: getZeroTimeDate(new Date()),
        },
      });
    },
  });
  const HoldNodues = ({invoke}) => ({
    title: 'Hold Nodues',
    confirm: {
      title: 'Hold Nodues',
      message: 'Are you sure you want to put nodues on hold?',
    },

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/nodues/${row?._id}`,
        message: 'Nodues put on hold successfully',
        props: {
          on_hold: true,
          on_hold_date: getZeroTimeDate(new Date()),
        },
      });
    },
  });
  const AbscondingNodues = ({invoke}) => ({
    title: 'Mark absconding',
    confirm: {
      title: 'Mark absconding',
      message: 'Are you sure you want to mark this as absconding?',
    },

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/nodues/${row?._id}`,
        message: 'Nodues put on hold successfully',
        props: {
          fnf_status: 'absconding',
          absconding_date: getZeroTimeDate(new Date()),
        },
      });
    },
    visible: ({row}) =>
      row && !row.fnf_date && !row.fnf_status && !row.is_cancelled,
  });
  const StartFnF = ({invoke}) => ({
    title: 'Start FnF',
    confirm: {
      title: 'Start FnF',
      message: 'Do you want to start Fnf of this emloyee?',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/noduesupdateandgratuity/${row?._id}`,
        message: 'FnF started successfully',
        props: {
          fnf_date: getZeroTimeDate(new Date()),
          fnf_status: 'Un-Paid',
          nodues_data: row,
        },
      });
    },
    visible: ({row}) => !row.fnf_date && !row.is_cancelled,
  });

  const deactivateUser = ({invoke}) => ({
    title: 'Deactivate User',
    confirm: {
      title: 'Deactivate User',
      message: 'Do you want to deactivate the user?',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/deactivateuser`,
        message: 'User Deactivated successfully',
        props: {
          _id: row?._id,
        },
      });
    },
  });

  const RestartFnF = ({invoke}) => ({
    title: 'Start FnF',
    confirm: {
      title: 'Start FnF',
      message: 'Do you want to start Fnf of this emloyee?',
    },

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/nodues/${row?._id}`,
        message: 'FnF started successfully',
        props: {
          on_hold: false,
        },
      });
    },
    visible: ({row}) => row.on_hold,
  });

  const UpdateFnfRemark = ({navigation}) => ({
    title: 'Update Remark',
    message: 'Remark update successfully',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`update-fnf-remark`, {
        ...params,
        row,
      });
    },
  });
  const ShareFnf = ({navigation}) => ({
    title: 'Share FNF',
    message: 'Fnf shared successfully',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`share-fnf`, {
        ...params,
        row,
      });
    },
  });
  const ReShareFnf = ({tab, navigation}) => ({
    title: 'Reshare FNF',
    message: 'Fnf re-shared successfully',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`share-fnf`, {
        ...params,
        row,
      });
    },
    visible: () => tab == 'accounts',
  });
  const promoteEmployee = ({row}) => {
    navigation.navigate(`promote-employee`, {
      ...params,
      row,
    });
  };
  const downloadpromotionLetter = ({row}) => {
    navigation.navigate(`promotion-letter-download`, {
      ...params,
      row,
    });
  };

  const downloadAppointmentLetter = ({row}) => {
    navigation.navigate(`appointment-letter-download`, {
      ...params,
      row,
    });
  };

  const downloadrelievingLetter = ({row}) => {
    navigation.navigate(`relieving-letter-download`, {
      ...params,
      row,
    });
  };
  const downloadinternshipLetter = ({row}) => {
    navigation.navigate(`internship-letter-download`, {
      ...params,
      row,
    });
  };
  const changeEmployeeDepartment = ({row}) => {
    navigation.navigate(`change-employee-department`, {
      ...params,
      row,
    });
  };
  const hrUploadLetters = ({row}) => {
    navigation.navigate(`upload-letters`, {
      ...params,
      row,
    });
  };
  return {
    markPip,
    unmarkPip,
    extendPip,
    updatePipMentor,
    markPotentialPip,
    createEmployee,
    startNodues,
    holdUnholdSalary,
    EmployeeDetails,
    clearTeamNoDues,
    clearAccountsNoDues,
    clearHRNoDues,
    clearAdminNoDues,
    clearITNoDues,
    clearEmployeeNoDues,
    CancelNodues,
    HoldNodues,
    StartFnF,
    UpdateFnfRemark,
    ShareFnf,
    ReShareFnf,
    AbscondingNodues,
    RestartFnF,
    markInactive,
    markLegal,
    markRecoveryPending,
    withdrawLegalProceedings,
    unmarkLegal,
    promoteEmployee,
    changeEmployeeDepartment,
    downloadpromotionLetter,
    downloadrelievingLetter,
    downloadinternshipLetter,
    deactivateUser,
    hrUploadLetters,
    EmployeeDetailsEditable,
    downloadAppointmentLetter,
    createEmployeeFromParichay,
    closeBackgroundVerification,
  };
};
