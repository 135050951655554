import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  DurationInput as DurationInputComponent,
  DurationRenderer as DurationRendererComponent,
} from '@unthinkable/react-duration-input';
import {DurationInputStyles} from './theme';

export const DurationInput = props => {
  const styles = useStyles(DurationInputStyles);
  return <DurationInputComponent styles={styles} {...props} />;
};

export const DurationRenderer = props => {
  const styles = useStyles(DurationInputStyles);
  return <DurationRendererComponent styles={styles} {...props} />;
};
