import {Row, View} from '@unthinkable/react-core-components';
import {useLeftMenuContext} from '@unthinkable/react-menus';
import React, {useEffect, useState} from 'react';
import {useLeftMenuContext as useLeftMenuNewContext} from './menu/LeftMenuContext';

const SplitScreenContext = React.createContext();

export const useSplitScreen = () => {
  return React.useContext(SplitScreenContext);
};

export const SplitScreen = ({
  children,
  splitScreen,
  keyField = '_id',
  sideScreenWidth = '40%',
  defaultSelectedData,
}) => {
  const [splitScreenData, setSplitScreenData] = useState();

  let isMenuVisible;
  let toggleMenu;

  let leftMenuNewContextValue = useLeftMenuNewContext() || {};
  let leftMenuContextValue = useLeftMenuContext() || {};

  if (leftMenuNewContextValue) {
    isMenuVisible = leftMenuNewContextValue.isMenuVisible;
    toggleMenu = leftMenuNewContextValue.toggleMenu;
  } else if (leftMenuContextValue) {
    isMenuVisible = leftMenuContextValue.isMenuVisible;
    toggleMenu = leftMenuContextValue.toggleMenu;
  }

  if (!toggleMenu) {
    const {isMenuVisible: isMenuVisibleOld, toggleMenu: toggleMenuOld} =
      useLeftMenuContext() || {};
    isMenuVisible = isMenuVisibleOld;
    toggleMenu = toggleMenuOld;
  }

  useEffect(() => {
    setSplitScreenData(defaultSelectedData);
  }, [defaultSelectedData]);

  useEffect(() => {
    if (toggleMenu) {
      splitScreenData
        ? isMenuVisible && toggleMenu()
        : !isMenuVisible && toggleMenu();
    }
  }, [splitScreenData]);

  const toggleSplitScreen = (params = {}) => {
    const {row} = params;
    const selectedId = row?.[keyField];
    if (!splitScreenData || splitScreenData.selectedId !== selectedId) {
      setSplitScreenData({
        selectedId,
        ...params,
      });
    }
  };

  const isRowActive = row => {
    return row?.[keyField] === splitScreenData?.selectedId;
  };

  return (
    <SplitScreenContext.Provider value={{toggleSplitScreen, isRowActive}}>
      <Row
        gap={8}
        style={{
          flex: 1,
          overflow: 'hidden',
        }}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          {React.cloneElement(children, {
            isRowActive,
            selectedId: splitScreenData?.selectedId,
            keyField,
          })}
        </View>
        {splitScreenData?.selectedId ? (
          <View
            key={splitScreenData.selectedId}
            style={{
              width: sideScreenWidth,
              borderWidth: 1,
              borderColor: '#EEEEED',
              borderRadius: 12,
              overflow: 'hidden',
            }}>
            {React.cloneElement(splitScreen, {
              route: {params: {...splitScreenData, is_side_view: true}},
              isLinkTab: false,
              onClose: () => {
                setSplitScreenData(void 0);
              },
            })}
          </View>
        ) : (
          void 0
        )}
      </Row>
    </SplitScreenContext.Provider>
  );
};
