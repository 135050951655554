import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const ThreadMeetingNotesForm = props => {
    let {
        navigation,
        route: { params },
        mode,
    } = props;

    const { onSubmit } = useFormSubmit({
        uri: '/threadmeetingnotes',
        eventSourceId: 'threadMeetingNotesAdded',
        ...props,
    });

    const { user } = useAppStateContext();
    const { _id: userId } = user || {};
    const { thread = {}, row } = params;

    return (
        <Form
            api={`/threadmeetingnotes/${row?._id}`}
            fields={{
                title: 1,
                description: 1,
                participants: { name: 1 }
            }}
            beforeSubmit={({ data }) => {
                if (!mode) {
                    let { participants = []} = data;
                    if (!participants) {
                        participants = [userId];
                    }
                    else if (!participants.some(item => item === userId)) {
                        participants.push(userId);
                    }

                    return {
                        data: {
                            ...data,
                            thread: thread?._id,
                            participants,
                        }
                    }
                }
            }}
            onSubmit={onSubmit}
            header={{ title: 'Add Meeting Notes' }}
            layoutFields={[
                {
                    label: 'Title',
                    type: 'text',
                    field: 'title',
                },
                {
                    label: 'Description',
                    type: 'textArea',
                    field: 'description',
                },
                {
                    label: 'Participants',
                    type: 'multiAutoComplete',
                    api: '/users',
                    filter: {
                        status: 'a',
                    },
                    field: 'participants',
                    suggestionField: 'name',
                    secondarySuggestionField: 'email',
                    valueField: 'name',
                },
            ]}
            {...props}
        />
    );
};

export const EditThreadMeetingNotesForm = props => {
    return <ThreadMeetingNotesForm header={{ title: 'Edit Meeting Notes' }} mode="edit" {...props} />;
};

