import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { HeaderButton } from '../../../components/button/AddButton';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { LeftMenuView } from '../../../components/menu/LeftMenuView';
import { TabView } from '../../../components/tab';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { TicketHeadTicketList } from './TicketHeadTicketList';
export const TicketHeadTabs = props => {
  const {
    route: {params},
    navigation,
    employee_team,
  } = props;

  const filterProps = useFilter();

  const {
    filterValues: {filter: addOnFilter = {}, params: filterParams},
    onChangeFilter,
    searchValue,
  } = filterProps;

  const raisedOnFilter = {};
  raisedOnFilter.raised_on_date = {
    $gte: filterParams?.raised_on_date?.from,
    $lte: filterParams?.raised_on_date?.to,
  };

  const closeFilter = {};
  closeFilter.is_delayed = filterParams?.is_delayed;
  closeFilter.closed_on = {
    $gte: filterParams?.closed_on?.from,
    $lte: filterParams?.closed_on?.to,
  };
  closeFilter.agent_dependency_time = filterParams?.agent_dependency_time;
  if (filterParams?.status) {
    closeFilter.status = filterParams?.status;
  }

  const {icons} = useTheme();
  const {download} = useAppStateContext();

  const tabs = {
    new: {
      view: (
        <TicketHeadTicketList
          filterParams={filterParams}
          employee_team={employee_team}
          addOnFilter={{
            ...raisedOnFilter,
            ...addOnFilter,
            status: {$nin: ['Closed', 'Temporarily Closed']},
            $or: [{agent: {$exists: false}}, {agent: null}],
          }}
          isUnassigned
          sort={{raised_on_date: -1}}
        />
      ),
      label: 'Unassigned',
      api: '/tickets',
      filter: {
        employee_team: employee_team?._id,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      addOnFilter: {
        ...raisedOnFilter,
        ...addOnFilter,
        status: {$nin: ['Closed', 'Temporarily Closed']},
        $or: [{agent: {$exists: false}}, {agent: null}],
      },
      eventSourceId: ['tickets'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              field: 'agent',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            {
              type: 'autoComplete',
              label: 'Is Transfered',
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_transfered',
            },
            {
              type: 'autoComplete',
              label: 'Transfered from',
              api: '/employeeTeams',
              suggestionField: 'name',
              filter: {
                is_ticket_enabled: true,
              },
              field: 'old_employee_teams',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/tickets/download',
              props: {
                params: {
                  ...params,
                  ...filterParams,
                },
                filter: {
                  employee_team: employee_team?._id,
                  ...raisedOnFilter,
                  ...addOnFilter,
                  status: {$nin: ['Closed', 'Temporarily Closed']},
                  $or: [{agent: {$exists: false}}, {agent: null}],
                },
              },
            });
          }}
        />,
      ],
    },
    open: {
      view: (
        <TicketHeadTicketList
          isOpen
          employee_team={employee_team}
          addOnFilter={{
            ...raisedOnFilter,
            ...addOnFilter,
            status: {$nin: ['Closed', 'Temporarily Closed']},
            $and: [{agent: {$exists: true}}, {agent: {$ne: null}}],
          }}
          sort={{raised_on_date: -1}}
        />
      ),
      label: 'Assigned',
      api: '/tickets',
      filter: {
        employee_team: employee_team?._id,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      addOnFilter: {
        ...raisedOnFilter,
        ...addOnFilter,
        status: {$nin: ['Closed', 'Temporarily Closed']},
        $and: [{agent: {$exists: true}}, {agent: {$ne: null}}],
      },
      eventSourceId: ['tickets'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              field: 'agent',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            {
              type: 'autoComplete',
              label: 'Dependency Status',
              field: 'dependency_status',
              options: [
                'No Dependency',
                'Awaiting Vendor Reply',
                'User Response Awaited',
                'Under Observation',
                'Manager Approval',
              ],
            },
            {
              type: 'autoComplete',
              label: 'Is Transfered',
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_transfered',
            },
            {
              type: 'autoComplete',
              label: 'Transfered from',
              api: '/employeeTeams',
              suggestionField: 'name',
              filter: {
                is_ticket_enabled: true,
              },
              field: 'old_employee_teams',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/tickets/download',
              props: {
                params: {
                  ...params,
                  ...filterParams,
                },
                filter: {
                  employee_team: employee_team?._id,
                  ...raisedOnFilter,
                  ...addOnFilter,
                  status: {$nin: ['Closed', 'Temporarily Closed']},
                  $and: [{agent: {$exists: true}}, {agent: {$ne: null}}],
                },
              },
            });
          }}
        />,
      ],
    },
    escalations: {
      view: (
        <TicketHeadTicketList
          employee_team={employee_team}
          addOnFilter={{
            ...raisedOnFilter,
            ...addOnFilter,
            expected_close: {
              $lte: new Date(),
            },
            status: {
              $nin: ['Closed', 'Temporarily Closed'],
            },
          }}
          isOpen
          sort={{expected_close: 1}}
        />
      ),
      label: 'Overdue',
      api: '/tickets',
      filter: {
        employee_team: employee_team?._id,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      addOnFilter: {
        ...raisedOnFilter,
        ...addOnFilter,
        expected_close: {
          $lte: new Date(),
        },
        status: {
          $nin: ['Closed', 'Temporarily Closed'],
        },
      },
      eventSourceId: ['tickets'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              field: 'agent',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            {
              type: 'autoComplete',
              label: 'Dependency Status',
              field: 'dependency_status',
              options: [
                'No Dependency',
                'Awaiting Vendor Reply',
                'User Response Awaited',
                'Under Observation',
                'Manager Approval',
              ],
            },
            {
              type: 'autoComplete',
              label: 'Is Transfered',
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_transfered',
            },
            {
              type: 'autoComplete',
              label: 'Transfered from',
              api: '/employeeTeams',
              suggestionField: 'name',
              filter: {
                is_ticket_enabled: true,
              },
              field: 'old_employee_teams',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/tickets/download',
              props: {
                params: {
                  ...params,
                  ...filterParams,
                },
                filter: {
                  employee_team: employee_team?._id,
                  ...raisedOnFilter,
                  ...addOnFilter,
                  expected_close: {
                    $lte: new Date(),
                  },
                  status: {
                    $nin: ['Closed', 'Temporarily Closed'],
                  },
                },
              },
            });
          }}
        />,
      ],
    },
    underObservation: {
      view: (
        <TicketHeadTicketList
          employee_team={employee_team}
          addOnFilter={{
            ...raisedOnFilter,
            ...addOnFilter,
            status: {
              $in: ['Temporarily Closed'],
            },
            ...closeFilter,
          }}
          isTempClosed
          sort={{temporarily_closed_on: -1}}
        />
      ),
      label: 'Under Observation',
      api: '/tickets',
      filter: {
        employee_team: employee_team?._id,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      addOnFilter: {
        ...raisedOnFilter,
        ...addOnFilter,
        status: {
          $in: ['Temporarily Closed'],
        },
        ...closeFilter,
      },
      eventSourceId: ['tickets'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              field: 'agent',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            {
              type: 'autoComplete',
              label: 'Closure Time',
              field: 'is_delayed',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              asParam: true,
            },
            {
              type: 'date',
              label: 'Closed On',
              field: 'closed_on',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Closed with Dependency',
              field: 'agent_dependency_time',
              suggestionField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'No',
                  value: {
                    $lte: 0,
                  },
                },
                {
                  label: 'Yes',
                  value: {
                    $gt: 0,
                  },
                },
              ],
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Is Transfered',
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_transfered',
            },
            {
              type: 'autoComplete',
              label: 'Transfered from',
              api: '/employeeTeams',
              suggestionField: 'name',
              filter: {
                is_ticket_enabled: true,
              },
              field: 'old_employee_teams',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/tickets/download',
              props: {
                params: {
                  ...params,
                  ...filterParams,
                },
                filter: {
                  employee_team: employee_team?._id,
                  ...raisedOnFilter,
                  ...addOnFilter,
                  status: {
                    $in: ['Closed', 'Temporarily Closed'],
                  },
                  ...closeFilter,
                },
              },
            });
          }}
        />,
      ],
    },
    closed: {
      view: (
        <TicketHeadTicketList
          employee_team={employee_team}
          addOnFilter={{
            ...raisedOnFilter,
            ...addOnFilter,
            status: {
              $in: ['Closed'],
            },
            ...closeFilter,
          }}
          isClosed
          sort={{closed_on: -1}}
        />
      ),
      label: 'Closed',
      api: '/tickets',
      filter: {
        employee_team: employee_team?._id,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      addOnFilter: {
        ...raisedOnFilter,
        ...addOnFilter,
        status: {
          $in: ['Closed'],
        },
        ...closeFilter,
      },
      eventSourceId: ['tickets'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              field: 'agent',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            {
              type: 'autoComplete',
              label: 'Closure Time',
              field: 'is_delayed',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              asParam: true,
            },
            {
              type: 'date',
              label: 'Closed On',
              field: 'closed_on',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Closed with Dependency',
              field: 'agent_dependency_time',
              suggestionField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'No',
                  value: {
                    $lte: 0,
                  },
                },
                {
                  label: 'Yes',
                  value: {
                    $gt: 0,
                  },
                },
              ],
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Is Transfered',
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_transfered',
            },
            {
              type: 'autoComplete',
              label: 'Transfered from',
              api: '/employeeTeams',
              suggestionField: 'name',
              filter: {
                is_ticket_enabled: true,
              },
              field: 'old_employee_teams',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/tickets/download',
              props: {
                params: {
                  ...params,
                  ...filterParams,
                },
                filter: {
                  employee_team: employee_team?._id,
                  ...raisedOnFilter,
                  ...addOnFilter,
                  status: {
                    $in: ['Closed', 'Temporarily Closed'],
                  },
                  ...closeFilter,
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      {...props}
      tabs={tabs}
      search={searchValue}
      searchFields={['subject', 'description', 'ticket_number']}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <HeaderButton
          title="Setup"
          onPress={() => {
            navigation.navigate('ticket-setup', {...params, employee_team});
          }}
        />,
      ]}
    />
  );
};

export const TicketMenus = props => {
  const {user} = useAppStateContext();

  const menus = user?.employee?.ticket_team.map(employee_team => ({
    label: employee_team.name,
    view: (
      <TicketHeadTabs
        key={employee_team._id}
        user={user}
        employee_team={employee_team}
      />
    ),
  }));

  return <LeftMenuView {...props} menus={menus} />;
};
