import React from 'react';
import MilestoneTable from './MilestoneTable';
import {TabView} from '../../../components/tab';

const MilestonesTab = (props) => {
  let {route: {params} = {}} = props ||{};
  let {row = {}} = params || {};
  let filterParams = {
    order:row?._id
  };

  const tabs = {
    milestone: {
      label: 'MileStone',
      view: <MilestoneTable filterParams={filterParams} {...props} />,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export default MilestonesTab;
