import React from 'react';
import {Table} from '../../../components/table/Table';

export const TalentRequestTable = props => {
  const params = props.route.params;
  return (
    <Table
      api={`/request-talents`}
      columns={[
        {
          field: 'skill_set.name',
          header: 'Skill',
        },
        {
          field: 'requested_by.name',
          header: 'Requested By',
        },
        {
          field: 'display_field',
          header: 'Project',
        },
        {
          field: 'number_of_resource_required',
          header: 'Resource',
        },
        {
          field: 'experience.experience',
          header: 'Experience',
        },
        {
          field: 'allocation',
          header: 'Allocation',
        },
        {
          type: 'date',
          field: 'start_date',
          header: 'Start date',
        },
        {
          type: 'date',
          field: 'end_date',
          header: 'End date',
        },
      ]}
      moreActions={[
        {
          title: 'Resource',
          onPress: ({row}) => {
            props.navigation.navigate(
              row?.number_of_resource_required
                ? 'organization-resource'
                : 'request-talent-assignment',
              {row, ...params},
            );
          },
        },
      ]}
    />
  );
};
