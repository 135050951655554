export const FontThemeStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    mainContainer: {
      borderRadius: 20,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      flex: 1,
      overflow: 'hidden',
    },
    titleText: {...fonts.BODY1, color: colors.NEUTRAL_HIGH},
    descriptionText: {...fonts.BODY3, color: colors.NEUTRAL_MEDIUM},
    tab: {
      container: {},
      item: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 16,
        paddingRight: 16,
        cursor: 'pointer',
        borderBottomWidth: 1,
        borderBottomColor: colors.OUTLINE,
      },
      activeItem: {
        borderBottomWidth: 1,
        borderBottomColor: colors.NEUTRAL_HIGH,
      },
      label: {
        color: colors.NEUTRAL_MEDIUM,
        ...fonts.BODY2,
      },
      activeLabel: {
        color: colors.NEUTRAL_HIGH,
        ...fonts.BODY2,
      },
    },
  };
};
