import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const ProjectMarkHoldForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projects/' + row?._id,
    eventSourceId: 'Project',
    method: 'put',
  });

  return (
    <Form
      onSubmit={onSubmit}
      header="Mark Hold"
      submitAction="Save"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            project_status: 'h',
          },
        };
      }}
      layoutFields={[
        {
          label: 'Hold Date',
          field: 'on_hold_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Reason',
          field: 'on_hold_reason',
          type: 'textArea',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateProjectAccountOwner = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
  });

  return (
    <Form
      onSubmit={onSubmit}
      header="Update Account Owner"
      submitAction="Save"
      defaultValues={{
        _id: row?._id,
        account_owner: row?.account_owner,
      }}
      layoutFields={[
        {
          label: 'Account Owner',
          field: 'account_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
