import {Text} from '@unthinkable/react-core-components';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useAppStateContext} from '../../../providers/AppState';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Resource',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'employee',
      searchFields: ['name', 'official_email_id', 'employee_code'],
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project',
    },
  ];
};

const shiftAllowanceFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'Active',
  params,
  period,
  download = () => {},
  icons,
}) => {
  return [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
      skipQuarter
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    <IconButton
      icon={icons.Download}
      onPress={() => {
        download({
          uri: '/exportExcelHelper',
          props: {
            props: {
              fetchProps: {
                skipFields: true,
                api: '/shiftAllowanceReport',
                addOnFilter: {
                  ...filterValues?.filter,
                },
                params: {
                  period,
                },
              },
              column: {
                Employee: 'employee.name',
                Email: 'employee.official_email_id',
                'From Date': 'from_date',
                'To Date': 'to_date',
                Project: 'project_id.project',
                'Shift Allowance': 'daily_incentive',
                'Shift Timing': 'shift_timing',
              },
            },
          },
        });
      }}
    />,
  ];
};

const RESOURCE = {
  header: 'Resource',
  render: ({row}) => {
    const {employee} = row;
    return (
      <UserAvatarWithNameAndEmail
        name={employee?.name}
        color={employee?.color}
        official_email_id={employee?.official_email_id}
      />
    );
  },
};

const FROMDATE = {
  header: 'From Date',
  field: 'from_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const TODATE = {
  header: 'To Date',
  field: 'to_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const PROJECT = {
  header: 'Project',
  field: 'project_id.project',
};

const SHIFTALLOWANCE = {
  header: 'Shift Allowance',
  field: 'daily_incentive',
  align: 'right',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText, color: '#34C759'}}>
        ₹{row?.daily_incentive}
      </Text>
    );
  },
};

const SHIFTTIMING = {
  header: 'Shift Timing',
  field: 'shift_timing',
  align: 'right',
};

const ShiftAllowanceReport = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    tab,
  } = props;
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/shiftAllowanceReport`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    fetchProps: {
                      skipFields: true,
                      api: '/shiftAllowanceReport',
                      addOnFilter: {
                        ...addOnFilter,
                      },
                      params: {
                        period,
                      },
                    },
                    column: {
                      Employee: 'employee.name',
                      Email: 'employee.official_email_id',
                      'From Date': 'from_date',
                      'To Date': 'to_date',
                      Project: 'project_id.project',
                      'Shift Allowance': 'daily_incentive',
                      'Shift Timing': 'shift_timing',
                    },
                  },
                },
              }),
          },
        ],
      }}
      params={{...params, period}}
      limit={1000}
      eventSourceId={['shiftallowance']}
      columns={[
        RESOURCE,
        FROMDATE,
        TODATE,
        PROJECT,
        SHIFTALLOWANCE,
        SHIFTTIMING,
      ]}
      moreActions={[
        {
          title: 'Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                employee: row?.employee?._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const ShiftAllowanceReportTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {download} = useAppStateContext();
  const icons = useTheme('icons');

  const tabs = {
    shift_allowance_reports: {
      label: 'Shift Allowance Report',
      view: (
        <ShiftAllowanceReport
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
          }}
          period={period}
          tab="shiftAllowanceReport"
        />
      ),
      api: '/shiftAllowanceReport',
      limit: 1000,
      eventSourceId: ['shiftallowance'],
      addOnFilter: {
        ...filter,
      },
      params: {...params, period},
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'shiftAllowanceReport',
        params,
        period,
        download,
        icons,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
