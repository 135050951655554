import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {Row, Text, TouchableOpacity} from '@unthinkable/react-core-components';
import {UpdateQAStatusChipStyles} from './theme';

export const UpdateQAStatusChip = props => {
  const {onPass, onReject} = props;
  const {gap, passTextStyle, seperatorStyle, rejectTextStyle, actionStyle} =
    useStyles(UpdateQAStatusChipStyles);

  return (
    <Row style={{justifyContent: 'center'}} gap={gap}>
      <TouchableOpacity onPress={onPass} style={actionStyle}>
        <Text style={passTextStyle}>{'Pass'}</Text>
      </TouchableOpacity>
      <Text style={seperatorStyle}>|</Text>
      <TouchableOpacity onPress={onReject} style={actionStyle}>
        <Text style={rejectTextStyle}>{'Fail'}</Text>
      </TouchableOpacity>
    </Row>
  );
};
