import React, {useEffect, useRef} from 'react';
import {Form} from '../form/Form';
import {Field} from '@unthinkable/react-form';
import {Row, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {CommentStyle} from './theme';
import {Button} from '../button/Button';

export const CommentBox = props => {
  const {
    setIsFocused,
    actionContainerStyle,
    field = 'comment',
    componentProps,
    ...rest
  } = props;

  const editorRef = useRef();

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor(); // Access the Quill editor instance
      quill.focus(); // Focus the editor
      const length = quill.getLength(); // Get the length of the editor content
      if (length) {
        quill.setSelection(length, 0); // Place the cursor at the end
      }
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.blur();
      }
    };
  }, [editorRef.current]);

  const {actions, cancelButton} = useStyles(CommentStyle);
  return (
    <Form {...rest}>
      {props => {
        const {handleSubmit, isValid} = props;

        return (
          <View>
            <Field
              {...props}
              {...componentProps}
              editorRef={editorRef}
              type="richText"
              field={field}
              required={true}
            />
            <Row gap={6} style={{...actions, ...actionContainerStyle}}>
              <Button
                text="Cancel"
                styles={cancelButton}
                onPress={() => setIsFocused(false)}
              />
              <Button
                disabled={!isValid}
                text="Post"
                onPress={() => {
                  handleSubmit && handleSubmit(props);
                  setIsFocused(false);
                }}
              />
            </Row>
          </View>
        );
      }}
    </Form>
  );
};
