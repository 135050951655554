import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
const OrganizationGroup = createMenuNavigator();
const MasterSetupNavigator = ({navigation}) => {
  return (
    <OrganizationGroup.Navigator data-navigator-id="MasterSetup">
      <OrganizationGroup.Group>
        <OrganizationGroup.Screen
          name="Master_Setup"
          options={{
            label: 'Master Setup',
            onPress: () => {
              navigation.navigate('company-setup', {});
            },
          }}
        />
      </OrganizationGroup.Group>
    </OrganizationGroup.Navigator>
  );
};

export default MasterSetupNavigator;
