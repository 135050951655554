import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useTheme} from '@unthinkable/react-theme';
import {Col, Text} from '@unthinkable/react-core-components';
import moment from 'moment';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {numDifferentiation} from '../../customer/views/CustomerOverview';

const RenderInvoiceId = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  if (row?.invoice_number) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_HIGH,
          }}>
          {row.invoice_number}
        </Text>
        <Text
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_LOW,
          }}>
          {moment(row.invoice_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderReceiptNo = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  if (row?.receipt_number) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_HIGH,
          }}>
          {row.receipt_number}
        </Text>
        <Text
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_LOW,
          }}>
          {moment(row.receipts_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderOverDueAmt = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.base_pending_receipt_amount) {
    const value = numDifferentiation(row?.base_pending_receipt_amount);

    return (
      <>
        <Col style={{alignItems: 'flex-end'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.ERROR_HIGH,
            }}>
            ₹{value}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Max aging: {row.days_difference} d
          </Text>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.ERROR_HIGH,
          }}>
          0
        </Text>
      </>
    );
  }
};

const RenderRevInvoiceAmt = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.base_total_invoice_amount) {
    const value = numDifferentiation(row?.base_total_invoice_amount);

    return (
      <>
        <Col style={{alignItems: 'flex-end'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
            }}>
            ₹{value}
          </Text>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
          }}>
          0
        </Text>
      </>
    );
  }
};

const RenderReceiptAmt = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.base_amount) {
    const value = numDifferentiation(row?.base_amount);

    return (
      <>
        <Col style={{alignItems: 'flex-end'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
            }}>
            ₹{value}
          </Text>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
          }}>
          0
        </Text>
      </>
    );
  }
};
const RenderInvoiceAmt = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  if (row?.base_total_invoice_amount) {
    const value = numDifferentiation(row?.base_total_invoice_amount);

    return (
      <>
        <Col style={{alignItems: 'flex-end'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
            }}>
            ₹{value}
          </Text>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.ERROR_HIGH,
          }}>
          0
        </Text>
      </>
    );
  }
};

export const ExpenseInvoiceList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title} = params;
  return (
    <Table
      api={`/vendorinvoicesExpense`}
      filter={{
        ...filter,
        department: row?._id,
        product: row?.product?._id,
        invoice_type: 'Salary',
      }}
      columns={[
        {
          type: 'text',
          field: 'invoice_no',
          header: 'Invoice No',
        },
        {
          type: 'date',
          field: 'invoice_date',
          header: 'Invoice Date',
        },
        {
          type: 'text',
          field: 'payee_id.name',
          header: 'Vendor Name',
        },
        {
          width: 200,
          type: 'number',
          field: 'voucher_amount',
          header: 'Amount',
          aggregate: true,
        },
        {
          type: 'text',
          field: 'purpose',
          header: 'Description',
        },
      ]}
    />
  );
};

export const RevenueInvoiceList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title} = params;
  return (
    <Table
      api={`/getRevenueInvoices`}
      filter={{
        ...filter,
        product: row?.product?._id,
      }}
      renderHeader={() => <TableHeader title={'Revenue'} />}
      columns={[
        {
          type: 'date',
          field: 'invoice_date',
          header: 'Invoice Date',
        },
        {
          type: 'text',
          field: 'invoice_number',
          header: 'Invoice No',
        },
        {
          type: 'text',
          field: 'customer.name',
          header: 'Customer',
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
        },
        {
          type: 'number',
          field: 'base_invoice_amount',
          header: 'Base Amount',
        },
        {
          type: 'number',
          field: 'total_invoice_amount',
          header: 'Amount',
        },
      ]}
    />
  );
};

export const DirectExpenseInvoiceList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title} = params;
  return (
    <Table
      api={`/department/vendorInvoices`}
      filter={{
        ...filter,
        department: row?._id,
        product: row?.product?._id,
        invoice_type: 'Expense',
      }}
      columns={[
        {
          field: 'invoice_no',
          header: 'Invoice No',
        },
        {
          type: 'date',
          field: 'invoice_date',
          header: 'Invoice Date',
        },
        {
          field: 'payee_id.name',
          header: 'Vendor Name',
        },
        {
          field: 'purpose',
          header: 'Description',
        },
        {
          type: 'number',
          field: 'voucher_amount',
          header: 'Amount',
          aggregate: true,
        },
        {
          field: 'purpose',
          header: 'Description',
        },
      ]}
    />
  );
};

export const OverduePendingAmtCustomerList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, period, title} = params;
  return (
    <Table
      api={`/customer/pending-invoices`}
      filter={{
        customer: row,
      }}
      renderHeader={() => <TableHeader title={'Overdue'} />}
      fields={{
        invoice_number: 1,
        invoice_date: 1,
        customer: {_id: 1, name: 1},
        status: 1,
        base_invoice_amount: 1,
        invoice_amount: 1,
      }}
      columns={[
        {
          render: RenderInvoiceId,
          header: 'Invoice ID',
        },
        {
          type: 'number',
          field: 'base_total_invoice_amount', //
          header: 'Invoice Amount',
          render: RenderInvoiceAmt,
        },
        {
          header: 'Overdue',
          type: 'currency',
          aggregate: true,
          field: 'base_pending_receipt_amount',
          width: 150,
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};

export const CustomerOverviewRevenueList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title, period} = params;
  const previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = moment(period.from)
      .subtract(1, 'M')
      .endOf('M')
      .toDate();
  }

  let invoiceId = [];
  if (row && row.length > 0) {
    for (const data of row) {
      invoiceId.push(data._id);
    }
  }
  return (
    <Table
      api={`/customer/all-revenue-invoice`}
      filter={{
        customer: row,
        invoice_date: {
          $gte: previousPeriod.from,
          $lte: previousPeriod.to,
        },
      }}
      renderHeader={() => <TableHeader title={'Revenue'} />}
      fields={{
        invoice_number: 1,
        invoice_date: 1,
        customer: {_id: 1, name: 1},
        status: 1,
        base_invoice_amount: 1,
        invoice_amount: 1,
      }}
      columns={[
        {
          render: RenderInvoiceId,
          header: 'Invoice ID',
        },
        {
          type: 'number',
          field: 'base_total_invoice_amount',
          header: 'Invoice Amount',
          render: RenderRevInvoiceAmt,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};

export const CustomerWiseRevenueInvoiceList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title, period} = params;
  const previousPeriod = {};
  // if (period.from) {
  //   previousPeriod.from = getZeroTimeDate(
  //     moment(period.from).subtract(1, 'M').startOf('M').toDate(),
  //   );
  //   previousPeriod.to = moment(period.from)
  //     .subtract(1, 'M')
  //     .endOf('M')
  //     .toDate();
  // }

  // let invoiceId = [];
  // if (row && row.length > 0) {
  //   for (const data of row) {
  //     invoiceId.push(data._id);
  //   }
  // }
  return (
    <Table
      api={`/customer/all-revenue-invoice`}
      filter={{
        // customer: row,
        // invoice_date: {
        //   $gte: previousPeriod.from,
        //   $lte: previousPeriod.to,
        // },
        _id: {$in: params?._id},
      }}
      renderHeader={() => <TableHeader title={'Revenue'} />}
      fields={{
        invoice_number: 1,
        invoice_date: 1,
        customer: {_id: 1, name: 1},
        status: 1,
        base_invoice_amount: 1,
        invoice_amount: 1,
        base_pending_receipt_amount: 1,
      }}
      columns={[
        {
          render: RenderInvoiceId,
          header: 'Invoice ID',
        },
        {
          type: 'number',
          field: 'base_total_invoice_amount',
          type: 'indianCurrency',
          header: 'Invoice Amount',
          render: RenderRevInvoiceAmt,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        // {
        //   header: 'Overdue',
        //   type: 'currency',
        //   aggregate: true,
        //   field: 'base_pending_receipt_amount',
        //   width: 150,
        //   render: RenderOverDueAmt,
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        // },
      ]}
    />
  );
};

export const CustomerWiseReceiptList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title, period} = params;
  const previousPeriod = {};
  // if (period.from) {
  //   previousPeriod.from = getZeroTimeDate(
  //     moment(period.from).subtract(1, 'M').startOf('M').toDate(),
  //   );
  //   previousPeriod.to = moment(period.from)
  //     .subtract(1, 'M')
  //     .endOf('M')
  //     .toDate();
  // }

  // let invoiceId = [];
  // if (row && row.length > 0) {
  //   for (const data of row) {
  //     invoiceId.push(data._id);
  //   }
  // }
  return (
    <Table
      api={`/customer-wise-receipt-report`}
      filter={{
        // customer: row,
        // invoice_date: {
        //   $gte: previousPeriod.from,
        //   $lte: previousPeriod.to,
        // },
        _id: {$in: params?._id},
      }}
      renderHeader={() => <TableHeader title={'Revenue'} />}
      fields={{
        invoice_number: 1,
        invoice_date: 1,
        customer: {_id: 1, name: 1},
        status: 1,
        base_invoice_amount: 1,
        invoice_amount: 1,
      }}
      columns={[
        {
          render: RenderReceiptNo,
          header: 'Receipt No.',
        },
        {
          type: 'number',
          field: 'base_amount',
          type: 'indianCurrency',
          header: 'Amount',
          render: RenderReceiptAmt,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};
