import {useAppStateContext} from '../../providers/AppState';
import {useStyles} from '@unthinkable/react-theme';
import {AITextAreaInputStyles} from '../form-editors/text/theme';
import {AITextAreaInput as AITextAreaInputComponent} from '@unthinkable/react-text-area-input';

export const AITextAreaInput = props => {
  const styles = useStyles(AITextAreaInputStyles);
  const {fetch} = useAppStateContext();
  const fetchSuggestion = async state => {
    const {value, improvementText} = state;
    let params = props?.getParams?.({...props, aiState: state});
    if (!params) {
      params = {
        textToImprove: value,
      };
    }
    if (improvementText) {
      params.improvementText = improvementText;
    }
    const response = await fetch({
      uri: '/ai/improve-suggestion',
      props: {
        params,
      },
    });
    return response?.data;
  };

  return (
    <AITextAreaInputComponent
      styles={styles}
      {...props}
      inputProps={{
        ...props?.inputProps,
        getSuggestion: fetchSuggestion,
      }}
    />
  );
};
