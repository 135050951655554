import ProjectAboutTable from '../department/views/ProjectAboutTable';
import ModuleAboutTable from './views/ModuleAbout';

import {
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
  PROJECT_DATA_SERVICE_TYPE_ID,
  PROJECT_MILESTONE_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_QA_TEST_CASE_TYPE_ID,
  PROJECT_THEME_TYPE_ID,
  PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  PROJECT_VIEW_TYPE_ID,
} from '../common/constants/SourceConstants';
import {FeatureTasksTable} from '../task/views/FeatureTasksTable';
import {ProjectTeamTab} from '../task/views/ProjectTeamTab';
import {ComprehensiveTaskTab} from './views/ComprehensiveTaskTab';
import {IssueTabs} from './views/IssueTab';
import {IssueScreen} from './views/IssuesTable';
import {ModuleRoleTable} from './views/ModuleRoleTable';
import {ProjectMilestoneTabs} from './views/ProjectMilestoneTabs';
import {ProjectRoleTable} from './views/ProjectRoleTable';
import {
  ProjectActiveTabs,
  ProjectClosedTabs,
  ProjectTabs,
} from './views/ProjectTabs';
import {RoadmapTabs} from './views/RoadmapTabs';
import {ApiEndpointsTab} from './views/assets/ApiEndPointsScreen';
import {
  ComponentsTab,
  IntegratedProjectComponentScreen,
} from './views/assets/ComponentScreen';
import {DataEntitiesTab} from './views/assets/DataEntityScreen';
import {FeatureApiEndpointTab} from './views/assets/FeatureApiEndpointsScreen';
import {FeatureComponentTab} from './views/assets/FeatureComponentsScreen';
import {FeatureDataEntitiesTab} from './views/assets/FeatureDataEntitiesScreen';
import {FeatureQATestCasesTab} from './views/assets/FeatureQATestCasesScreen';
import {FeatureScreenTab} from './views/assets/FeatureScreens';
import {FeatureThirdPartyIntegrationTab} from './views/assets/FeatureThirdPartyIntegrationScreen';
import {QaTestCasesTab} from './views/assets/QATestCasesScreen';
import {ScreensTab} from './views/assets/ScreensTable';
import {ThirdPartyIntegrationsTab} from './views/assets/ThirdPartyIntegrationScreen';
import {UserStoryScreen} from './views/UserstoryTable';
import {FeatureBugTabView} from './views/FeatureBugTabView';
import {
  ClientProjectAllTabs,
  ClientProjectTabs,
} from './views/ClientProjectTabs';
import {ClientProjectEmployeeAssignmentTable} from '../common/views/ClientProjectEmployeeAssignmentTable';
import {TeamTasksTab} from '../task/views/ProjectActiveTasksTab';
import {
  ComprehensiveTasks,
  EmployeeProjectTasks,
} from '../task/views/TasksTable';
import {
  NewProjectFixedTab,
  NewProjectTab,
  NewProjectTandMTab,
  NewProjectUnbilledTab,
} from '../employee/views/NewProjectTab';
import {
  PerformanceAllTab,
  PerformanceClosedTandMTab,
  PerformanceFixedTab,
  PerformanceTab,
  PerformanceTandMTab,
} from './views/PerformanceTab';
import {PerformanceCompletedTab} from './views/PerformanceCompletedTab';
import {
  PerformanceUnbilledReportTable,
  PerformanceUnbilledReportTableAm,
  PerformanceUnbilledReportTablePm,
} from './views/PerformanceReportTable';
import {ProjectGrossMarginTab} from './views/ProjectGrossMarginTable';
import {GrowthAndBillingTab} from '../order/views/CurrentBusinessTAndM';
import {ProjectEfficiencyTab} from './views/ProjectEfficiencyTab';
import {
  PendingSDLCProjectTabsAm,
  ProjectEfficiencyTabAM,
  ProjectFixedTabsAm,
  ProjectTabsAm,
  ProjectTandMTabsAm,
} from './views/ProjectTabsForAm';
import {
  ProjectEfficiencyTabPM,
  ProjectFixedTabsPm,
  ProjectTabsPm,
  ProjectTandMTabsPm,
} from './views/ProjectTabsForPM';
import {
  OrdersAndDeliveriesForTAndMTab,
  OrdersAndDeliveriesForTAndMTabForAM,
  OrdersAndDeliveriesForTAndMTabForPM,
} from '../order/views/OrdersAndDeliveriesForTAndM';
import {
  OrdersAndDeliveriesForFixedRetainerTab,
  OrdersAndDeliveriesForFixedRetainerTabForAM,
  OrdersAndDeliveriesForFixedRetainerTabForPM,
  OrdersAndDeliveriesForFixedTab,
  OrdersAndDeliveriesForFixedTabForAM,
  OrdersAndDeliveriesForFixedTabForPM,
} from '../order/views/OrdersAndDeliveriesForFixed';
import {
  PendingProjectApprovalsTable,
  PendingProjectApprovalsTableAm,
  PendingProjectApprovalsTablePm,
} from './views/PendingProjectApprovals';
import {DataServicesTab} from './views/assets/DataServiceScreen';
import {FeatureDataServiceTab} from './views/assets/FeatureDataServicesScreen';
import PmtTooltip from './constants/PmtTooltip';
import {RepositoriesTable} from './views/RepositoriesTable';
import {ProjectEnvironmentTable} from './views/ProjectEnvironmentTable';
import {
  AMClientFeedbackReportTable,
  ClientFeedbackReportTable,
} from './views/ResourceWiseProjectFeedback';
import {AllocationPendingForAMTab} from '../employee/views/AllocationPendingForAM';
import {DeliveredProjectResourceWorkingTab} from '../employee/views/DeliveredProjectResourceWorking';
import {Labels} from './constants/Label';
import {HelpGuide} from '../../components/helpguide/HelpGuide';
import {ProjectReleasedFeaturesTable} from './views/FeaturesTable';
import {FeatureStatus, QAStages} from './constants/FeatureStages';
import {ModulesActiveTable} from './views/ModulesActiveTable';
import {ProjectLibraryTable} from './views/assets/ProjectLibraryTable';
import {
  DbServicesTab,
  IntegratedProjectDataServiceLibraryScreen,
} from './views/assets/DataServiceLibraryScreen';
import {FeatureDataServiceLibraryTab} from './views/assets/FeatureDataServiceLibraryScreen';
import {
  IntegratedProjectStyledComponentScreen,
  StyledComponentsTab,
} from './views/assets/StyledComponentScreen';
import {FeatureStyledComponentTab} from './views/assets/FeatureStyledComponentsScreen';
import {FeatureColorTheme} from './views/FeatureColorTheme';
import {FeatureFontTheme} from './views/FeatureFontTheme';
import {ProjectThemeColorTable} from './views/ProjectThemeColorTable';
import {ProjectThemeFontTable} from './views/ProjectThemeFontTable';
import {UserStoryTab} from './views/UserStoryTab';
import {LibraryTypes} from './constants/LibraryConstants';
import {ProjectBoilerplateLibraryTable} from './views/assets/ProjectBoilerplateLibraryTable';
import {
  AuditParameterTab,
  ProjectApprovalPendingTablSetup,
  ProjectSubTypeWiseNavigationTable,
  ProjectSubTypeWiseNavigationTableForAM,
  ProjectSubTypeWiseNavigationTableForPM,
  ProjectSubTypeWiseTableSetup,
  ProjectTypeWiseTableSetup,
} from '../employee/views/ProjectTypeWiseTable';
import {UserOrganizationTable} from '../common/views/UserOrganizationTable';
import {
  AllActiveProjectsTab,
  AllClosedProjectsTab,
  AllClosedProjectsTabAm,
  AllClosedProjectsTabPm,
} from './views/AllProjectsAndClientTabs';
import {
  ComposedComponentsTab,
  IntegratedProjectComposedComponentScreen,
} from './views/assets/ComposedComponentScreen';
import {FeatureComposedComponentTab} from './views/assets/FeatureComposedComponentsScreen';

export const ScreenMapping = [
  {
    id: 'myactiveproject',
    view: <ProjectActiveTabs />,
    visible: ({user}) => {
      return !user.externalUser;
    },
  },
  {
    id: 'myClient',
    view: ({user}) => {
      return <ClientProjectTabs user={user} />;
    },
    visible: ({user}) => {
      return user.externalUser;
    },
  },
  {
    id: 'pmt_clientAllProjects',
    view: ({user}) => {
      return <ClientProjectAllTabs user={user} />;
    },
    visible: ({user}) => {
      return user.externalUser && user.org_admin;
    },
  },
  {
    id: 'newproject',
    view: props => {
      return <NewProjectTandMTab key="NewProject" {...props} />;
    },
  },
  {
    id: 'activeproject',
    view: props => {
      return (
        <PerformanceTandMTab project_status="a" tab="Assigned" {...props} />
      );
    },
  },
  // {
  //   id: 'projectbilledandunbilled',
  //   view: ({user}) => {
  //     return <ProjectTypeWiseTable user={user} />;
  //   },
  // },
  {
    id: 'projectbilledandunbilled',
    view: ({user}) => {
      return <ProjectSubTypeWiseNavigationTable user={user} />;
    },
  },
  {
    id: 'projectbilledandunbilledpm',
    view: ({user}) => {
      return <ProjectSubTypeWiseNavigationTableForPM user={user} />;
    },
  },
  {
    id: 'projectbilledandunbilledam',
    view: ({user}) => {
      return <ProjectSubTypeWiseNavigationTableForAM user={user} />;
    },
  },
  {
    id: 'closedproject',
    view: props => {
      return (
        <PerformanceClosedTandMTab
          project_status="c"
          tab="Completed"
          {...props}
        />
      );
    },
  },
  {
    id: 'allproject',
    view: props => {
      return (
        <PerformanceAllTab
          key="NewProject"
          project_status="a"
          tab="All Active"
          is_project_assign={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'holdproject',
    view: props => {
      return (
        <PerformanceAllTab
          key="HoldProject"
          project_status="h"
          tab="Hold"
          is_project_assign={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'newprojectFixed',
    view: props => {
      return (
        <NewProjectFixedTab
          addOnParamFilter={{is_support_project: {$in: [null, false]}}}
          key="NewProject"
          {...props}
        />
      );
    },
  },
  {
    id: 'activeprojectFixed',
    view: props => {
      return (
        <PerformanceFixedTab
          project_status="a"
          tab="Assigned"
          addOnParamFilter={{is_support_project: {$in: [null, false]}}}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectFixed',
    view: props => {
      return (
        <PerformanceFixedTab
          addOnParamFilter={{is_support_project: {$in: [null, false]}}}
          project_status="c"
          tab="Completed"
          {...props}
        />
      );
    },
  },
  {
    id: 'newprojectretainer',
    view: props => {
      return (
        <NewProjectFixedTab
          key="NewProjectRetainer"
          addOnParamFilter={{is_support_project: true}}
          {...props}
        />
      );
    },
  },
  {
    id: 'activeprojectretainer',
    view: props => {
      return (
        <PerformanceFixedTab
          project_status="a"
          addOnParamFilter={{is_support_project: true}}
          tab="Assigned"
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectretainer',
    view: props => {
      return (
        <PerformanceFixedTab
          project_status="c"
          tab="Completed"
          addOnParamFilter={{is_support_project: true}}
          {...props}
        />
      );
    },
  },
  {
    id: 'retaineractiveprojectpm',
    view: props => {
      return (
        <ProjectFixedTabsPm
          key="retaineractivepm"
          project_status="a"
          tab="Assigned"
          addOnParamFilter={{is_support_project: true}}
          {...props}
        />
      );
    },
  },
  {
    id: 'retainerclosedprojectpm',
    view: props => {
      return (
        <ProjectFixedTabsPm
          key="retainerclosedpm"
          project_status="c"
          tab="Completed"
          addOnParamFilter={{is_support_project: true}}
          {...props}
        />
      );
    },
  },
  {
    id: 'retaineractiveprojectam',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectFixedTabsAm
          key="retaineractiveam"
          project_status="a"
          tab="Assigned"
          addOnParamFilter={{is_support_project: true}}
          {...props}
        />
      );
    },
  },
  {
    id: 'retainerclosedprojectam',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectFixedTabsAm
          key="retainerclosedam"
          project_status="c"
          tab="Completed"
          addOnParamFilter={{is_support_project: true}}
          {...props}
        />
      );
    },
  },
  {
    id: 'allactiveprojectsscreen',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        // <PerformanceAllTab
        <AllActiveProjectsTab {...props} />
      );
    },
  },
  {
    id: 'allclosedprojectsscreen',
    view: props => {
      return <AllClosedProjectsTab label="Closed" {...props} />;
    },
  },
  {
    id: 'newunbilledproject',
    view: props => {
      return <NewProjectUnbilledTab {...props} />;
    },
  },
  {
    id: 'activeunbilledproject',
    view: props => {
      return (
        <PerformanceUnbilledReportTable
          filter={{
            project_status: 'a',
            projecttype: 'i',
          }}
          tab="Active"
          project_status="a"
          tabName="unbilled"
          fromUnbilled={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedunbilledproject',
    view: props => {
      return (
        <PerformanceUnbilledReportTable
          filter={{
            project_status: 'c',
            projecttype: 'i',
          }}
          tab="Closed"
          project_status="c"
          tabName="unbilled"
          fromUnbilled={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'approvals',
    view: props => {
      return <PendingProjectApprovalsTable {...props} />;
    },
  },
  {
    id: 'approvalsSetup',
    view: ({user}) => {
      return <ProjectApprovalPendingTablSetup user={user} />;
    },
  },
  {
    id: 'activeprojectam',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectTandMTabsAm project_status="a" tab="Assigned" {...props} />
      );
    },
  },
  {
    id: 'closedprojectam',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <PerformanceClosedTandMTab
          project_status="c"
          tab="Completed"
          extraAddOnfilter={{account_owner: employee?._id}}
          {...props}
        />
      );
    },
  },
  {
    id: 'activeprojectamFixed',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectFixedTabsAm
          project_status="a"
          tab="Assigned"
          addOnParamFilter={{
            is_support_project: {
              $in: [null, false],
            },
          }}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectamFixed',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectFixedTabsAm project_status="c" tab="Completed" {...props} />
      );
    },
  },
  {
    id: 'activeunbilledprojectam',
    view: props => {
      const {user} = props;
      return (
        <PerformanceUnbilledReportTableAm
          filter={{
            project_status: 'a',
            projecttype: 'i',
          }}
          user={user}
          tab="Assigned"
          tabName="unbilled"
          fromUnbilled={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectamUnbilled',
    view: props => {
      return (
        <PerformanceUnbilledReportTableAm
          filter={{
            project_status: 'c',
            projecttype: 'i',
          }}
          tab="Completed"
          project_status="c"
          fromUnbilled={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'allholdprojectsscreenam',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <AllClosedProjectsTab
          key="holdProjectAm"
          label="Hold"
          extraAddOnfilter={{account_owner: employee?._id}}
          {...props}
        />
      );
    },
  },
  {
    id: 'approvalsPending',
    view: props => {
      return (
        <PendingProjectApprovalsTableAm
          project_status="a"
          tab="Active"
          {...props}
        />
      );
    },
  },
  {
    id: 'approvalsPending_am',
    view: props => {
      return (
        <PendingProjectApprovalsTableAm
          project_status="a"
          tab="Active"
          {...props}
        />
      );
    },
  },
  {
    id: 'pendingmilestoneam',
    view: props => {
      return (
        <PendingSDLCProjectTabsAm project_status="a" tab="Active" {...props} />
      );
    },
  },
  {
    id: 'allocationpendingam',
    view: props => {
      return <AllocationPendingForAMTab {...props} />;
    },
  },
  {
    id: 'allocationpending_am',
    view: props => {
      return <AllocationPendingForAMTab {...props} />;
    },
  },
  {
    id: 'deliveredprojectresourceworking',
    view: props => {
      return <DeliveredProjectResourceWorkingTab {...props} />;
    },
  },
  {
    id: 'tandmoutputam',
    view: props => {
      return <OrdersAndDeliveriesForTAndMTabForAM {...props} />;
    },
  },
  {
    id: 'tandmoutput_am',
    view: props => {
      return <OrdersAndDeliveriesForTAndMTabForAM {...props} />;
    },
  },
  {
    id: 'fixedoutputam',
    view: props => {
      return <OrdersAndDeliveriesForFixedTabForAM {...props} />;
    },
  },
  {
    id: 'fixedretaineroutputam',
    view: props => {
      return <OrdersAndDeliveriesForFixedRetainerTabForAM {...props} />;
    },
  },
  {
    id: 'projectbillingefficiencyam',
    view: props => {
      return <ProjectEfficiencyTabAM {...props} />;
    },
  },
  {
    id: 'activeprojectpm',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectTandMTabsPm
          user={user}
          project_status="a"
          tab="Assigned"
          addOnParamFilter={{
            owner_id: employee?._id,
          }}
          {...props}
        />
      );
    },
  },
  {
    id: 'allholdprojectsscreenpm',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <AllClosedProjectsTab
          key="holdProjectPm"
          label="Hold"
          extraAddOnfilter={{owner_id: employee?._id}}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectpm',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <PerformanceClosedTandMTab
          project_status="c"
          extraAddOnfilter={{
            owner_id: employee?._id,
          }}
          tab="Completed"
          {...props}
        />
      );
    },
  },
  {
    id: 'activeprojectpmFixed',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectFixedTabsPm
          user={user}
          project_status="a"
          tab="Assigned"
          addOnParamFilter={{
            owner_id: employee?._id,
          }}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectpmFixed',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <ProjectFixedTabsPm project_status="c" tab="Completed" {...props} />
      );
    },
  },
  {
    id: 'closedprojectspm',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <AllClosedProjectsTabPm project_status="c" tab="Completed" {...props} />
      );
    },
  },
  {
    id: 'closedprojectsam',
    view: props => {
      const {user} = props;
      const {employee = {}} = user;
      return (
        <AllClosedProjectsTabAm project_status="c" tab="Completed" {...props} />
      );
    },
  },
  {
    id: 'activeunbilledprojectpm',
    view: props => {
      const {user} = props;
      return (
        <PerformanceUnbilledReportTablePm
          filter={{
            project_status: 'a',
            projecttype: 'i',
          }}
          user={user}
          tab="Assigned"
          tabName="unbilled"
          fromUnbilled={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'closedprojectpmUnbilled',
    view: props => {
      return (
        <PerformanceUnbilledReportTablePm
          project_status="c"
          projecttype="i"
          tab="Completed"
          tabName="unbilled"
          fromUnbilled={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'approvalsPendingPm',
    view: props => {
      return <PendingProjectApprovalsTablePm {...props} />;
    },
  },
  {
    id: 'approvalsPending_pm',
    view: props => {
      return <PendingProjectApprovalsTablePm {...props} />;
    },
  },
  {
    id: 'tandmoutputpm',
    view: props => {
      return <OrdersAndDeliveriesForTAndMTabForPM {...props} />;
    },
  },
  {
    id: 'tandmoutput_pm',
    view: props => {
      return <OrdersAndDeliveriesForTAndMTabForPM {...props} />;
    },
  },
  {
    id: 'fixedoutputpm',
    view: props => {
      return <OrdersAndDeliveriesForFixedTabForPM {...props} />;
    },
  },
  {
    id: 'fixedoutputretainerpm',
    view: props => {
      return <OrdersAndDeliveriesForFixedRetainerTabForPM {...props} />;
    },
  },
  {
    id: 'projectbillingefficiencypm',
    view: props => {
      return <ProjectEfficiencyTabPM {...props} />;
    },
  },
  {
    id: 'growthandbillingPmt',
    view: props => {
      return <GrowthAndBillingTab {...props} />;
    },
  },
  {
    id: 'tandmoutput',
    view: props => {
      return <OrdersAndDeliveriesForTAndMTab {...props} />;
    },
  },
  {
    id: 'fixedoutput',
    view: props => {
      return <OrdersAndDeliveriesForFixedTab {...props} />;
    },
  },
  {
    id: 'fixedretaineroutput',
    view: props => {
      return <OrdersAndDeliveriesForFixedRetainerTab {...props} />;
    },
  },
  {
    id: 'projectgrossmarignreport',
    view: props => {
      return <ProjectGrossMarginTab {...props} />;
    },
  },
  {
    id: 'projectefficiencyreport',
    view: props => {
      return <ProjectEfficiencyTab {...props} />;
    },
  },
  {
    id: 'projectfeedbacks',
    view: props => {
      return <ClientFeedbackReportTable {...props} />;
    },
  },
  {
    id: 'customerfeedbackreport',
    view: props => {
      return <ClientFeedbackReportTable {...props} />;
    },
  },
  {
    id: 'amwisecustomerfeedbackreport',
    view: props => {
      return <AMClientFeedbackReportTable {...props} />;
    },
  },
  {
    id: 'projecttypesetup',
    view: ({user}) => {
      return <ProjectTypeWiseTableSetup user={user} />;
    },
  },
  {
    id: 'projectsubtypesetup',
    view: ({user}) => {
      return <ProjectSubTypeWiseTableSetup user={user} />;
    },
  },
  {
    id: 'userorganization',
    view: props => {
      return <UserOrganizationTable {...props} />;
    },
  },
  {
    id: 'auditparameter',
    view: ({user}) => {
      return <AuditParameterTab user={user} />;
    },
  },
];

export const featureScreens = [
  {
    skipFiltering: true,
    menuItemId: 'featureTasks',
    views: [
      {
        id: 'featureTask',
        label: 'Tasks',
        view: <FeatureTasksTable />,
        countProps: ({params}) => {
          return {
            api: `/projecttasks/all`,
            eventSourceId: 'Task',
            filter: {
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureRequirement',
    views: [
      {
        id: `${Labels.Requirements_lower}`,
        label: `${Labels.Requirements}`,
        view: <UserStoryScreen />,
        countProps: ({params}) => {
          return {
            api: `/projectuserstories`,
            eventSourceId: 'UserStory',
            filter: {
              objective_ids: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureImprovements',
    views: [
      {
        id: 'featureImprovements',
        label: 'Improvements',
        view: <IssueScreen type={'Enhancement'} />,
        countProps: ({params}) => {
          return {
            api: `/projectissues`,
            eventSourceId: 'Issue',
            filter: {
              objective_ids: params?.feature?._id,
              type: 'Enhancement',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureIssues',
    views: [
      {
        id: 'featureIssues',
        label: 'Issues',
        view: <IssueScreen type={'Bug'} />,
        countProps: ({params}) => {
          return {
            api: `/projectissues`,
            eventSourceId: 'Issue',
            filter: {
              objective_ids: params?.feature?._id,
              type: 'Bug',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureDataEntities',
    views: [
      {
        id: 'dataEntities',
        label: 'Models',
        view: <FeatureDataEntitiesTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'Model',
            filter: {
              source: PROJECT_MODEL_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureApis',
    views: [
      {
        id: 'apis',
        label: 'APIs',
        view: <FeatureApiEndpointTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'Controller',
            filter: {
              source: PROJECT_CONTROLLER_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureScreens',
    views: [
      {
        id: 'screen',
        label: 'Screens',
        view: <FeatureScreenTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'View',
            filter: {
              source: PROJECT_VIEW_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureDataServices',
    views: [
      {
        id: 'featureDataService',
        label: 'Services',
        view: <FeatureDataServiceTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'DataService',
            filter: {
              source: PROJECT_DATA_SERVICE_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureThirdPartyIntegrations',
    views: [
      {
        id: 'thirdPartyIntegrations',
        label: 'Integrations',
        view: <FeatureThirdPartyIntegrationTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'ThirdPartyIntegration',
            filter: {
              source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureQATestCases',
    views: [
      {
        id: 'qatestcases',
        label: 'TestCases',
        view: <FeatureQATestCasesTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'TestCase',
            filter: {
              source: PROJECT_QA_TEST_CASE_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureThemeFonts',
    views: [
      {
        id: 'featureThemeFont',
        label: 'Theme',
        view: <FeatureFontTheme />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureThemeColors',
    views: [
      {
        id: 'featureThemeColor',
        label: 'Theme',
        view: <FeatureColorTheme />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureComponents',
    views: [
      {
        id: 'components',
        label: 'Components',
        view: <FeatureComponentTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'Component',
            filter: {
              source: PROJECT_COMPONENT_TYPE_ID,
              feature_id: params?.feature?._id,
              $or: [
                {component_type: 'component'},
                {component_type: {$exists: false}},
              ],
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureStyledComponents',
    views: [
      {
        id: 'styledComponents',
        label: 'Styled Components',
        view: <FeatureStyledComponentTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'StyledComponent',
            filter: {
              source: PROJECT_COMPONENT_TYPE_ID,
              feature_id: params?.feature?._id,
              component_type: 'styled',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureComposedComponents',
    views: [
      {
        id: 'composedComponents',
        label: 'Composed Components',
        view: <FeatureComposedComponentTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'ComposedComponent',
            filter: {
              source: PROJECT_COMPONENT_TYPE_ID,
              feature_id: params?.feature?._id,
              component_type: 'composed',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureDataServiceLibrary',
    views: [
      {
        id: 'dataServiceLibraries',
        label: 'Data Services',
        view: <FeatureDataServiceLibraryTab />,
        countProps: ({params}) => {
          return {
            api: `/projectAssetVersions`,
            eventSourceId: 'DataServiceLibrary',
            filter: {
              source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
              feature_id: params?.feature?._id,
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'featureBugs',
    views: [
      {
        id: 'featureBug',
        label: 'Bugs',
        view: <FeatureBugTabView />,
        api: `/projectbugs/open`,
        eventSourceId: 'Bug',
      },
    ],
  },
];

export const helpScreens = [
  {
    menuItemId: 'help_pmt',
    views: [
      {
        id: 'help_screen_pmt',
        label: 'Help',
        view: props => {
          return <HelpGuide {...props} helpId={'pmt_project_detail'} />;
        },
      },
    ],
  },
  {
    menuItemId: 'help_pmt_module',
    views: [
      {
        id: 'help_screen_pmt',
        label: 'Help',
        view: props => {
          return <HelpGuide {...props} helpId={'pmt_module_detail'} />;
        },
      },
    ],
  },
];

export const addOnScreens = [
  {
    menuItemId: 'modules',
    skipFiltering: true,
    views: [
      {
        id: 'module',
        label: 'Modules',
        view: <ModulesActiveTable />,
        countProps: ({params}) => {
          return {
            api: `/projectmodules`,
            eventSourceId: 'Module',
            filter: {
              project_id: params?.project?._id,
              status: {$ne: 'archived'},
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: `${Labels.Requirement_lower}`,
    views: [
      {
        id: `${Labels.Requirements_lower}`,
        label: `${Labels.Requirements}`,
        view: <UserStoryTab />,
        // countProps: ({params}) => {
        //   return {
        //     api: `/projectuserstories`,
        //     eventSourceId: 'UserStory',
        //     filter: {
        //       project_id: params?.project?._id,
        //       module_id: params?.module?._id,
        //       library_id: params?.projectlibrary_id || null,
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'moduleImprovement',
    views: [
      {
        id: 'improvements',
        label: 'Improvements',
        view: <IssueTabs type={'Enhancement'} />,
        // countProps: ({params}) => {
        //   return {
        //     api: `/projectissues`,
        //     eventSourceId: ['Issue'],
        //     filter: {
        //       project_id: params?.project?._id,
        //       module_id: params?.module?._id,
        //       library_id: params?.projectlibrary_id || null,
        //       type: 'Enhancement',
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'moduleIssue',
    views: [
      {
        id: 'issues',
        label: 'Issues',
        view: <IssueTabs type={'Bug'} />,
        // countProps: ({params}) => {
        //   return {
        //     api: `/projectissues`,
        //     eventSourceId: ['Issue'],
        //     filter: {
        //       project_id: params?.project?._id,
        //       module_id: params?.module?._id,
        //       library_id: params?.projectlibrary_id || null,
        //       type: 'Bug',
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: `${Labels.Feature_lower_module}`,
    views: [
      {
        id: `${Labels.Features_lower}`,
        label: `${Labels.Features}`,
        view: <RoadmapTabs fromModule />,
        // countProps: ({params}) => {
        //   return {
        //     api: `/projectfeatures`,
        //     eventSourceId: 'Feature',
        //     filter: {
        //       project_id: params?.project?._id,
        //       module_id: params?.module?._id,
        //       status: {$ne: 'archived'},
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectModels',
    views: [
      {
        id: 'dataEntities',
        label: 'Models',
        view: <DataEntitiesTab source={'project'} />,
        countProps: ({params}) => {
          return {
            api: `/projectmodels`,
            eventSourceId: 'Model',
            filter: {
              project_id: params?.project?._id,
              module_id: params?.module?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectControllers',
    views: [
      {
        id: 'api',
        label: 'APIs',
        view: <ApiEndpointsTab />,
        countProps: ({params}) => {
          return {
            api: '/projectcontrollers',
            eventSourceId: 'Controller',
            filter: {
              project_id: params?.project?._id,
              module_id: params?.module?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectViews',
    views: [
      {
        id: 'screen',
        label: 'Screens',
        view: <ScreensTab />,
        countProps: ({params}) => {
          return {
            api: '/projectviews',
            eventSourceId: 'View',
            filter: {
              project_id: params?.project?._id,
              module_id: params?.module?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectDataServices',
    views: [
      {
        id: 'dataService',
        label: 'Services',
        view: <DataServicesTab />,
        countProps: ({params}) => {
          return {
            api: '/projectdataservices',
            eventSourceId: 'DataService',
            filter: {
              project_id: params?.project?._id,
              module_id: params?.module?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectThirdPartyIntegrations',
    views: [
      {
        id: 'thirdPartyIntegration',
        label: 'Integrations',
        view: <ThirdPartyIntegrationsTab />,
        countProps: ({params}) => {
          return {
            api: '/projectthirdpartyintegrations',
            eventSourceId: 'ThirdPartyIntegration',
            filter: {
              project_id: params?.project?._id,
              module_id: params?.module?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'qaTestCases',
    views: [
      {
        id: 'qaTestCase',
        label: 'TestCases',
        view: <QaTestCasesTab />,
        countProps: ({params}) => {
          return {
            api: '/qatestcases',
            eventSourceId: 'TestCase',
            filter: {
              project_id: params?.project?._id,
              module_id: params?.module?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'moduleRoles',
    views: [
      {
        id: 'modulerole',
        label: 'Role',
        view: <ModuleRoleTable />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'moduleSettings',
    views: [
      {
        id: 'moduleSetting',
        label: 'Settings',
        view: <ModuleAboutTable />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'role',
    views: [
      {
        id: 'roles',
        label: 'Role',
        view: <ProjectRoleTable />,
      },
    ],
  },

  {
    skipFiltering: true,
    menuItemId: 'completedMilestoneTasks',
    views: [
      {
        id: 'released-milestone-task',
        label: 'Released Milestones',
        view: (
          <ComprehensiveTasks
            typeId={PROJECT_MILESTONE_TYPE_ID}
            title={'Completed Tasks'}
            showHeader
            addOnParams={{status: 'completed'}}
          />
        ),
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'team',
    views: [
      {
        id: 'tasks',
        label: 'Tasks',
        view: ({user}) => {
          return <ProjectTeamTab user={user} />;
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'user-tasks',
    views: [
      {
        id: 'Tasks',
        label: 'Tasks',
        view: ({route}) => {
          const {params} = route || {};
          if (params?.parentTabId === 'output') {
            const {row = {}} = params || {};

            const from = row?.datePeriod?.start_date;
            const to = row?.datePeriod?.end_date;
            const daysDifference = Math.floor(
              (new Date(to) - new Date(from)) / (1000 * 60 * 60 * 24),
            );
            let filterOf = daysDifference > 7 ? 'Month' : 'Week';
            let period = {from, to, filterOf};
            return (
              <EmployeeProjectTasks
                key="Task"
                addOnParams={{period}}
                parentSourceView={'EmployeeEfficiency'}
                featureType="feature"
              />
            );
          }
          return <TeamTasksTab />;
        },
      },
      {
        id: 'review',
        label: 'Reviews',
        view: props => {
          const {route: {params} = {}} = props;

          const {row = {}} = params || {};

          const from = row?.datePeriod?.start_date;
          const to = row?.datePeriod?.end_date;
          const daysDifference = Math.floor(
            (new Date(to) - new Date(from)) / (1000 * 60 * 60 * 24),
          );
          let filterOf = daysDifference > 7 ? 'Month' : 'Week';
          let period = {from, to, filterOf};
          return (
            <EmployeeProjectTasks
              key="Review"
              addOnParams={{
                fromReview: true,
                period,
                review_pending: false,
              }}
              parentSourceView={'EmployeeEfficiency'}
              featureType="feature"
            />
          );
        },
        visible: ({params}) => {
          return params?.parentTabId === 'output';
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'team-member',
    views: [
      {
        id: 'members',
        label: 'Team',
        view: ({user, ...props}) => {
          return (
            <ClientProjectEmployeeAssignmentTable user={user} {...props} />
          );
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'milestone',
    views: [
      {
        id: 'milestones',
        label: 'Milestones',
        view: ({user}) => {
          return <ProjectMilestoneTabs user={user} />;
        },
        // countProps: ({params}) => {
        //   return {
        //     api: '/projectmilestones/all',
        //     eventSourceId: 'Milestone',
        //     filter: {
        //       project_id: params?.project?._id,
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'releasedFeatures',
    views: [
      {
        id: 'releasedfeature',
        label: `Released ${Labels.Features}`,
        view: ({route}) => {
          const {params} = route || {};
          return (
            <ProjectReleasedFeaturesTable
              showHeader
              filter={{milestone_id: params?.milestone?._id}}
            />
          );
        },
        countProps: ({params}) => {
          return {
            api: '/projectfeatures',
            eventSourceId: ['Feature', 'Issue'],
            filter: {
              project_id: params?.project?._id,
              milestone_id: params?.milestone?._id,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: `${Labels.Feature_lower}`,
    views: [
      {
        id: 'roadmap',
        label: 'Roadmap',
        view: ({user, route}) => {
          let fromProject = true;
          if (
            route?.params?.milestone?._id ||
            route?.params?.projectlibrary_id
          ) {
            fromProject = false;
          }
          return (
            <RoadmapTabs
              user={user}
              fromProject={fromProject}
              type={'feature'}
            />
          );
        },
        // countProps: ({params}) => {
        //   return {
        //     api: '/projectfeatures',
        //     eventSourceId: ['Feature', 'Issue'],
        //     filter: {
        //       project_id: params?.project?._id,
        //       milestone_id: params?.milestone?._id,
        //       status: {$ne: 'archived'},
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'milestoneTasks',
    views: [
      {
        id: 'milestoneTask',
        label: 'Milestones',
        view: <ComprehensiveTaskTab typeId={PROJECT_MILESTONE_TYPE_ID} />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'improvement',
    views: [
      {
        id: 'improvement',
        label: 'Improvements',
        view: <IssueTabs type={'Enhancement'} />,
        // countProps: ({params}) => {
        //   return {
        //     api: `/projectissues`,
        //     eventSourceId: ['Issue'],
        //     filter: {
        //       project_id: params?.project?._id,
        //       module_id: params?.module?._id,
        //       library_id: params?.projectlibrary_id || null,
        //       type: 'Enhancement',
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'issue',
    views: [
      {
        id: 'issues',
        label: 'Issues',
        view: <IssueTabs type={'Bug'} />,
        // countProps: ({params}) => {
        //   return {
        //     api: `/projectissues`,
        //     eventSourceId: ['Issue'],
        //     filter: {
        //       project_id: params?.project?._id,
        //       module_id: params?.module?._id,
        //       library_id: params?.projectlibrary_id || null,
        //       type: 'Bug',
        //     },
        //   };
        // },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectThemeColors',
    views: [
      {
        id: 'projectThemeColor',
        label: 'Theme',
        view: <ProjectThemeColorTable />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectThemeFonts',
    views: [
      {
        id: 'projectThemeFont',
        label: 'Theme',
        view: <ProjectThemeFontTable />,
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectComponents',
    views: [
      {
        id: 'projectComponent',
        label: 'UI Components',
        view: props => {
          const {
            route: {params},
          } = props;
          const {integratedProject} = params;
          return integratedProject ? (
            <IntegratedProjectComponentScreen {...props} />
          ) : (
            <ComponentsTab {...props} />
          );
        },
        countProps: ({params}) => {
          const {projectlibrary_id, integratedProject} = params;
          return {
            api: '/projectcomponents',
            eventSourceId: 'Component',
            filter: {
              library_id: projectlibrary_id,
              project_id: params?.project?._id,
              library_version_id: integratedProject?.library_version_id || null,
              status: 'released',
              $or: [
                {
                  component_type: 'component',
                },
                {
                  component_type: null,
                },
              ],
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectStyledComponents',
    views: [
      {
        id: 'projectStyledComponent',
        label: 'Styled Components',
        view: props => {
          const {
            route: {params},
          } = props;
          const {integratedProject} = params;
          return integratedProject ? (
            <IntegratedProjectStyledComponentScreen {...props} />
          ) : (
            <StyledComponentsTab {...props} />
          );
        },
        countProps: ({params}) => {
          const {projectlibrary_id, integratedProject} = params;
          return {
            api: '/projectcomponents',
            eventSourceId: 'StyledComponent',
            filter: {
              library_id: projectlibrary_id,
              project_id: params?.project?._id,
              library_version_id: integratedProject?.library_version_id || null,
              status: 'released',
              component_type: 'styled',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectComposedComponents',
    views: [
      {
        id: 'projectComposedComponent',
        label: 'Composed Components',
        view: props => {
          const {
            route: {params},
          } = props;
          const {integratedProject} = params;
          return integratedProject ? (
            <IntegratedProjectComposedComponentScreen {...props} />
          ) : (
            <ComposedComponentsTab {...props} />
          );
        },
        countProps: ({params}) => {
          const {projectlibrary_id, integratedProject} = params;
          return {
            api: '/projectcomponents',
            eventSourceId: 'ComposedComponent',
            filter: {
              library_id: projectlibrary_id,
              project_id: params?.project?._id,
              library_version_id: integratedProject?.library_version_id || null,
              status: 'released',
              component_type: 'composed',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectDataServicesLibraries',
    views: [
      {
        id: 'projectDataServicesLibrary',
        label: 'DB Services',
        view: props => {
          const {
            route: {params},
          } = props;
          const {integratedProject} = params;
          return integratedProject ? (
            <IntegratedProjectDataServiceLibraryScreen {...props} />
          ) : (
            <DbServicesTab {...props} />
          );
        },
        countProps: ({params}) => {
          const {projectlibrary_id, integratedProject} = params;
          return {
            api: '/projectdataservicelibraries',
            eventSourceId: 'DataServiceLibrary',
            filter: {
              library_id: projectlibrary_id,
              project_id: params?.project?._id,
              library_version_id: integratedProject?.library_version_id || null,
              status: 'released',
            },
          };
        },
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectUiLibraries',
    views: [
      {
        id: 'projectUiLibraries',
        label: 'UI Libraries',
        view: (
          <ProjectLibraryTable
            libraryType={LibraryTypes.UI_LIBRARY}
            title={'UI Libraries'}
          />
        ),
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectServerLibraries',
    views: [
      {
        id: 'projectServerLibraries',
        label: 'Server Libraries',
        view: (
          <ProjectLibraryTable
            libraryType={LibraryTypes.SERVER_LIBRARY}
            title={'Server Libraries'}
          />
        ),
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'boilerplateLibrary',
    views: [
      {
        id: 'projectBoilerplateLibraries',
        label: 'Boilerplate',
        view: (
          <ProjectBoilerplateLibraryTable
            libraryType={LibraryTypes.BOILERPLATE}
            title={'Boilerplates'}
          />
        ),
      },
    ],
  },
  {
    menuItemId: 'projectEnvironments',
    skipFiltering: true,
    views: [
      {
        id: 'projectEnvironments',
        label: 'Environments',
        view: ({user}) => {
          return <ProjectEnvironmentTable />;
        },
        countProps: ({params}) => {
          return {
            api: `/projectEnvironments`,
            eventSourceId: 'Environment',
            filter: {
              project_id: params?.project?._id,
            },
          };
        },
        visible: ({params}) => params?.project?.tool === 'Locomo',
      },
    ],
  },
  {
    menuItemId: 'projectRepositories',
    skipFiltering: true,
    views: [
      {
        id: 'repositories',
        label: 'Repositories',
        view: ({user}) => {
          return <RepositoriesTable user={user} />;
        },
        countProps: ({params}) => {
          return {
            api: `/projectRepositories`,
            eventSourceId: 'Repository',
            filter: {
              project_id: params?.project?._id,
            },
          };
        },
        visible: ({params}) => params?.project?.tool === 'Locomo',
      },
    ],
  },
  {
    skipFiltering: true,
    menuItemId: 'projectSettings',
    views: [
      {
        id: 'projectSetting',
        label: 'Settings',
        view: ({user}) => {
          return <ProjectAboutTable user={user} />;
        },
      },
    ],
  },
  ...featureScreens,
  ...helpScreens,
];

// section has no id. Passing id to section will cause errors. - danger :)
export const featureMenus = [
  [
    {
      id: 'featureTasks',
      label: 'Tasks',
      visible: ({params}) => {
        return params?.feature?.status === 'released';
      },
      tooltip: ({params}) => PmtTooltip({params}).TASKS,
    },
  ],
  [
    {
      id: 'featureRequirement',
      label: `${Labels.Requirements}`,
      tooltip: ({params}) => PmtTooltip({params}).REQUIREMENTS,
    },
    {
      id: 'featureImprovements',
      label: 'Improvements',
      tooltip: ({params}) => PmtTooltip({params}).IMPROVEMENTS,
    },
    {
      id: 'featureIssues',
      label: 'Issues',
      tooltip: ({params}) => PmtTooltip({params}).ISSUES,
    },
  ],
  [
    {
      id: 'featureDataEntities',
      label: 'Models',
      tooltip: ({params}) => PmtTooltip({params}).SCHEMA,
      visible: ({params}) => {
        let {feature} = params;
        return !feature?.library_id;
      },
    },
    {
      id: 'featureApis',
      label: 'APIs',
      tooltip: ({params}) => PmtTooltip({params}).APIs,
      visible: ({params}) => {
        let {feature} = params;
        return !feature?.library_id;
      },
    },
    {
      id: 'featureScreens',
      label: 'Screens',
      tooltip: ({params}) => PmtTooltip({params}).SCREENS,
      visible: ({params}) => {
        let {feature} = params;
        return !feature?.library_id;
      },
    },
    {
      id: 'featureDataServices',
      label: 'Services',
      // tooltip: ({params}) => PmtTooltip({params}).DATA_SERVICES,
      visible: ({params}) => {
        let {feature} = params;
        return !feature?.library_id;
      },
    },
    {
      id: 'featureThirdPartyIntegrations',
      label: 'Integrations',
      tooltip: ({params}) => PmtTooltip({params}).INTEGRATIONS,
      visible: ({params}) => {
        let {feature} = params;
        return !feature?.library_id;
      },
    },
    {
      id: 'featureQATestCases',
      label: 'TestCases',
      tooltip: ({params}) => PmtTooltip({params}).TEST_CASES,
      visible: ({params}) => {
        let {feature} = params;
        return !feature?.library_id;
      },
    },
    {
      id: 'featureTheme',
      label: 'Theme',
      section: true,
      menus: [
        {
          id: 'featureThemeFonts',
          label: 'Fonts',
          tooltip: ({params}) => PmtTooltip({params}).THEME,
          visible: ({params}) => {
            const {libraryType} = params;
            return libraryType === LibraryTypes.UI_LIBRARY;
          },
        },
        {
          id: 'featureThemeColors',
          label: 'Colors',
          tooltip: ({params}) => PmtTooltip({params}).THEME,
          visible: ({params}) => {
            const {libraryType} = params;
            return libraryType === LibraryTypes.UI_LIBRARY;
          },
        },
      ],
      visible: ({params}) => {
        const {libraryType} = params;
        return libraryType === LibraryTypes.UI_LIBRARY;
      },
    },
    {
      id: 'featureComponents',
      label: 'UI Components',
      tooltip: ({params}) => PmtTooltip({params}).UI_COMPONENTS,
      visible: ({params}) => {
        const {feature, libraryType} = params;
        return libraryType === LibraryTypes.UI_LIBRARY || !feature?.library_id;
      },
    },
    {
      id: 'featureStyledComponents',
      label: 'Styled Components',
      visible: ({params}) => {
        const {libraryType} = params;
        return libraryType === LibraryTypes.UI_LIBRARY;
      },
    },
    {
      id: 'featureComposedComponents',
      label: 'Composed Components',
      visible: ({params}) => {
        const {libraryType} = params;
        return libraryType === LibraryTypes.UI_LIBRARY;
      },
    },
    {
      id: 'featureDataServiceLibrary',
      label: 'Data Services',
      visible: ({params}) => {
        const {libraryType} = params;
        return libraryType === LibraryTypes.SERVER_LIBRARY;
      },
    },
  ],
  [
    {
      id: 'featureBugs',
      label: 'Bugs',
      visible: ({params}) => {
        const {feature} = params || {};
        return (
          feature?.status === FeatureStatus.qa.value ||
          feature?.status === FeatureStatus.released.value
        );
      },
      tooltip: ({params}) => PmtTooltip({params}).BUGS,
    },
  ],
  [
    {
      id: 'featureTasks',
      label: 'Tasks',
      visible: ({params}) => {
        return params?.feature?.status !== 'released';
      },
      tooltip: ({params}) => PmtTooltip({params}).TASKS,
    },
  ],
];

const LibraryCommonMenus = [
  [
    {
      id: `${Labels.Feature_lower}`,
      label: `${Labels.Features}`,
      tooltip: ({params}) => PmtTooltip({params}).FEATURES,
      menuItems: featureMenus,
      visible: ({params}) => {
        const {integratedProject} = params || {};
        return !integratedProject;
      },
    },
  ],
  [
    {
      id: `${Labels.Requirement_lower}`,
      label: `${Labels.Requirements}`,
      tooltip: ({params}) => PmtTooltip({params}).REQUIREMENTS,
      visible: ({params}) => {
        const {integratedProject} = params || {};
        return !integratedProject;
      },
    },
    {
      id: 'moduleImprovement',
      label: 'Improvements',
      tooltip: ({params}) => PmtTooltip({params}).IMPROVEMENTS,
      visible: ({params}) => {
        const {integratedProject} = params || {};
        return !integratedProject;
      },
    },
    {
      id: 'moduleIssue',
      label: 'Issues',
      tooltip: ({params}) => PmtTooltip({params}).ISSUES,
      visible: ({params}) => {
        const {integratedProject} = params || {};
        return !integratedProject;
      },
    },
  ],
];

const projectMenus = [
  [
    {
      id: `${Labels.Feature_lower}`,
      label: `${Labels.Features}`,
      menuItems: featureMenus,
      tooltip: ({params}) => PmtTooltip({params}).FEATURES,
    },
    {
      id: 'milestone',
      entityKey: 'projectmilestones',
      label: 'Milestones',
      tooltip: ({params}) => PmtTooltip({params}).MILESTONES,
      menuItems: [
        {
          id: 'releasedFeatures',
          label: `Released ${Labels.Features}`,
          menuItems: featureMenus,
          tooltip: ({params}) => PmtTooltip({params}).FEATURES,
          visible: ({params}) => {
            return params?.milestone?.status === 'completed';
          },
        },
        {
          id: `${Labels.Feature_lower}`,
          label: `${Labels.Features}`,
          menuItems: featureMenus,
          tooltip: ({params}) => PmtTooltip({params}).FEATURES,
          visible: ({params}) => {
            return params?.milestone?.status !== 'completed';
          },
        },
        {
          id: 'milestoneTasks',
          label: 'Tasks',
          menuItems: featureMenus,
          tooltip: ({params}) => PmtTooltip({params}).TASKS,
          visible: ({params}) => {
            return params?.milestone?.status !== 'completed';
          },
        },
        {
          id: 'completedMilestoneTasks',
          label: 'Completed Tasks',
          menuItems: featureMenus,
          tooltip: ({params}) => PmtTooltip({params}).TASKS,
          visible: ({params}) => {
            return params?.milestone?.status === 'completed';
          },
        },
      ],
    },
    {
      id: 'team',
      label: 'Team Tasks',
      tooltip: ({params}) => PmtTooltip({params}).TEAM_TASKS,
      menuItems: [
        {
          id: 'user-tasks',
          label: 'Tasks',
          menuItems: featureMenus,
        },
      ],
    },
  ],
  {
    id: 'help_pmt',
    label: 'Help',
    hidden: true,
    defaultSelected: ({params, user}) => {
      return (
        !params?.project?.module_count &&
        params?.showHelpData &&
        user?.help_contents &&
        user?.help_contents['pmt_project_detail']
      );
    },
  },
  [
    {
      id: 'team-member',
      label: 'Team Members',
      visible: props => {
        const {data, user} = props;
        const {userType, email, externalUser, org} = user;
        return (
          (userType === 'employee' &&
            (data?.project_group === 'Testing' ||
              data?.project_group === 'Product' ||
              email === 'amit.singh@daffodildb.com' ||
              email === 'rohit.bansal@daffodilsw.com' ||
              email === 'sachin.bansal@daffodilsw.com' ||
              email === 'sushil.nagvan@daffodilsw.com')) ||
          (externalUser && org)
        );
      },
    },
  ],
  [
    {
      id: 'role',
      label: 'Roles',
      tooltip: ({params}) => PmtTooltip({params}).ROLES,
    },
  ],
  [
    {
      id: 'modules',
      label: 'Modules',
      helpId: 'pmt_module_detail',
      helpItemId: 'help_pmt_module',
      helpScreenId: 'help_screen_pmt',
      tooltip: ({params}) => PmtTooltip({params}).MODULES,
      menuItems: [
        [
          {
            id: `${Labels.Feature_lower_module}`,
            defaultSelected: true,
            label: `${Labels.Features}`,
            menuItems: featureMenus,
            tooltip: ({params}) => PmtTooltip({params}).FEATURES,
          },
        ],
        {
          id: 'help_pmt_module',
          label: 'Help',
          hidden: true,
          defaultSelected: ({params, user}) => {
            return (
              !params?.module?.active_feature_count &&
              user?.help_contents &&
              user?.help_contents['pmt_module_detail']
            );
          },
        },
        [
          {
            id: 'moduleRoles',
            label: 'Roles',
            tooltip: ({params}) => PmtTooltip({params}).ROLES,
          },
        ],
        [
          {
            id: `${Labels.Requirement_lower}`,
            label: `${Labels.Requirements}`,
            tooltip: ({params}) => PmtTooltip({params}).REQUIREMENTS,
          },
          {
            id: 'moduleImprovement',
            label: 'Improvements',
            tooltip: ({params}) => PmtTooltip({params}).IMPROVEMENTS,
          },
          {
            id: 'moduleIssue',
            label: 'Issues',
            tooltip: ({params}) => PmtTooltip({params}).ISSUES,
          },
        ],
        [
          {
            id: 'projectModels',
            label: 'Models',
            tooltip: ({params}) => PmtTooltip({params}).SCHEMA,
          },
          {
            id: 'projectControllers',
            label: 'APIs',
            tooltip: ({params}) => PmtTooltip({params}).APIs,
          },
          {
            id: 'projectViews',
            label: 'Screens',
            tooltip: ({params}) => PmtTooltip({params}).SCREENS,
          },
          {
            id: 'projectDataServices',
            label: 'Services',
            // tooltip: ({params}) => PmtTooltip({params}).DATA_SERVICES,
          },
          // {
          //   id: 'projectThirdPartyIntegrations',
          //   label: 'Integrations',
          //   tooltip: ({params}) => PmtTooltip({params}).INTEGRATIONS,
          // },
          {
            id: 'qaTestCases',
            label: 'TestCases',
            tooltip: ({params}) => PmtTooltip({params}).TEST_CASES,
          },
        ],
        // [
        //   {
        //     id: 'moduleTasks',
        //     label: 'Tasks',
        //   },
        // ],

        [
          {
            id: 'moduleSettings',
            label: 'Settings',
            tooltip: ({params}) => PmtTooltip({params}).SETTINGS,
          },
        ],
      ],
    },
    {
      id: 'projectUiLibraries',
      label: 'UI Libraries',
      menuItems: [
        ...LibraryCommonMenus,
        [
          {
            id: 'projectTheme',
            label: 'Theme',
            section: true,
            menus: [
              {
                id: 'projectThemeFonts',
                label: 'Fonts',
                tooltip: ({params}) => PmtTooltip({params}).THEME,
              },
              {
                id: 'projectThemeColors',
                label: 'Colors',
                tooltip: ({params}) => PmtTooltip({params}).THEME,
              },
            ],
          },
          {
            id: 'projectComponents',
            label: 'UI Components',
            tooltip: ({params}) => PmtTooltip({params}).UI_COMPONENTS,
          },
          {
            id: 'projectStyledComponents',
            label: 'Styled Components',
          },
          {
            id: 'projectComposedComponents',
            label: 'Composed Components',
          },
        ],
      ],
    },
    {
      id: 'projectServerLibraries',
      label: 'Server Libraries',
      menuItems: [
        ...LibraryCommonMenus,
        [
          {
            id: 'projectDataServicesLibraries',
            label: 'Data Services',
            // add tooltip for db service library
          },
        ],
      ],
    },
  ],
  [
    {
      id: `${Labels.Requirement_lower}`,
      label: `${Labels.Requirements}`,
      tooltip: ({params}) => PmtTooltip({params}).REQUIREMENTS,
    },
    {
      id: 'improvement',
      label: 'Improvements',
      tooltip: ({params}) => PmtTooltip({params}).IMPROVEMENTS,
    },
    {
      id: 'issue',
      label: 'Issues',
      tooltip: ({params}) => PmtTooltip({params}).ISSUES,
    },
  ],
  [
    // {
    //   id: 'projectModels',
    //   label: 'Models',
    //   tooltip: ({params}) => PmtTooltip({params}).SCHEMA,
    // },
    // {
    //   id: 'projectControllers',
    //   label: 'APIs',
    //   tooltip: ({params}) => PmtTooltip({params}).APIs,
    // },
    // {
    //   id: 'projectViews',
    //   label: 'Screens',
    //   tooltip: ({params}) => PmtTooltip({params}).SCREENS,
    // },
    // {
    //   id: 'projectDataServices',
    //   label: 'Services',
    // },
    {
      id: 'projectThirdPartyIntegrations',
      label: 'Integrations',
      tooltip: ({params}) => PmtTooltip({params}).INTEGRATIONS,
    },
    // {
    //   id: 'qaTestCases',
    //   label: 'TestCases',
    //   tooltip: ({params}) => PmtTooltip({params}).TEST_CASES,
    // },
  ],
  [
    {
      id: 'projectEnvironments',
      label: 'Environments',
    },
    {
      id: 'projectRepositories',
      label: 'Repositories',
    },
  ],
  [
    {
      id: 'projectSettings',
      label: 'Settings',
      tooltip: ({params}) => PmtTooltip({params}).SETTINGS,
    },
  ],
];

export const addOnMenus = {
  projects: {
    menuItems: projectMenus,
    helpId: 'pmt_project_detail',
    helpItemId: 'help_pmt',
    helpScreenId: 'help_screen_pmt',
    label: ({user}) => {
      return user?.externalUser ? 'Projects' : 'My Projects';
    },
  },
  projectstAndm: {
    menuItems: projectMenus,
    entityKey: 'projects',
  },
  projectsbilledorunbilled: {
    menuItems: projectMenus,
    entityKey: 'projects',
  },
  projectsfixed: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  unbilledprojects: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  projectpendingapprovals: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  tandmprojectam: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  fixedprojectam: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  unbilledprojectam: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  projectpendingapprovalsam: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  tandmprojectpm: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  fixedprojectpm: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  unbilledprojectpm: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  projectpendingapprovalspm: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  projectsretainer: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  retainerprojectpm: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  retainerprojectam: {
    entityKey: 'projects',
    menuItems: projectMenus,
  },
  allactiveprojectsmenus: {
    menuItems: projectMenus,
    entityKey: 'projects',
  },
  allclosedprojectsmenu: {
    menuItems: projectMenus,
    entityKey: 'projects',
  },
};

// export const MenuItems = [
//   {
//     id: 'projects',
//     label: ({user}) => {
//       return user?.externalUser ? 'Project' : 'My Project';
//     },
//     menuItems: projectMenus,
//   },
//   {
//     id: 'adminprojects',
//     label: 'Projects',
//     section: true,
//     menus: [
//       {
//         id: 'projectstAndm',
//         label: 'T&M Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projectsfixed',
//         label: 'Fixed Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projectsretainer',
//         label: 'Retainer Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'unbilledprojects',
//         label: 'Unbilled Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projectpendingapprovals',
//         label: 'Pending Approvals',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projection',
//         label: 'Performance',
//         entityKey: 'projects',
//       },
//     ],
//   },
//   {
//     id: 'projectspm',
//     label: 'Projects(PM)',
//     section: true,
//     menus: [
//       {
//         id: 'tandmprojectpm',
//         label: 'T&M Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'fixedprojectpm',
//         label: 'Fixed Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'retainerprojectpm',
//         label: 'Retainer Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'unbilledprojectpm',
//         label: 'Unbilled Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projectpendingapprovalspm',
//         label: 'Pending Approvals',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projectreportpm',
//         label: 'Performance',
//       },
//     ],
//   },
//   {
//     id: 'projectsam',
//     label: 'Projects(AM)',
//     section: true,
//     menus: [
//       {
//         id: 'tandmprojectam',
//         label: 'T&M Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'fixedprojectam',
//         label: 'Fixed Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'retainerprojectam',
//         label: 'Retainer Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'unbilledprojectam',
//         label: 'Unbilled Projects',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'projectpendingapprovalsam',
//         label: 'Pending Approvals',
//         entityKey: 'projects',
//         menuItems: projectMenus,
//       },
//       {
//         id: 'allocationpendingam',
//         label: 'Allocation Pending',
//       },
//       // { this view is shown in customer am order delivery
//       //   id: 'deliveredprojectresourceworkingsam',
//       //   label: 'Delivered Project Resource Working',
//       // },
//       {
//         id: 'projectreportam',
//         label: 'Performance',
//       },
//     ],
//   },
// ];
