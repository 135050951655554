import {useCallback} from 'react';
import {resolveFilterValue} from '../util';

export const useFieldFilter = props => {
  const {field, type, asParam, dependentFilterFields, onChangeFilter} = props;
  const value = resolveFilterValue(props);
  const onChangeValue = useCallback(
    value => {
      onChangeFilter &&
        onChangeFilter({type, field, value, asParam, dependentFilterFields});
    },
    [field, value, onChangeFilter, dependentFilterFields, type, asParam],
  );
  return {
    value,
    onChangeValue,
  };
};
