import {useEffect} from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const base64ToBlob = (base64, mime) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: mime});
};

const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, {type: blob.type});
};

export const ReportIssueForm = props => {
  const {route: {params} = {}} = props || {};

  let {image} = params || {};
  const {upload} = useAppStateContext();

  const {onSubmit} = useFormSubmit({
    eventSourceId: ['Issue', 'tickets'],
    uri: '/report-issues',
  });

  let attachments = [];

  let time = new Date().getTime();

  const handleUpload = async () => {
    try {
      const _image = image.replace('data:image/png;base64,', '');
      const blob = base64ToBlob(_image, 'image/png');

      const file = blobToFile(blob, `screen_shot_${time}`);

      const uploadedFile = await upload(file);
      attachments.push(uploadedFile);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  useEffect(() => {
    handleUpload();
  }, [image]);

  let defaultValues = {type: 'Project Issue', file: attachments};

  return (
    <Form
      onSubmit={onSubmit}
      header={'Report Issue/Suggestion'}
      submitAction="Send to Locomo Team"
      defaultValues={defaultValues}
      layoutFields={[
        {
          field: 'type',
          Label: 'Type',
          type: 'radioGroup',
          options: ['Project Issue', 'Ticket', 'Feature Suggestion'],
          required: true,
        },
        {
          field: 'issue',
          type: 'textArea',
          label: 'Write here',
          placeholder: 'Write here',
          required: true,
        },
        {
          label: 'Priority',
          field: 'priority',
          type: 'autoComplete',
          options: ['Urgent', 'High', 'Medium', 'Low'],
          size: 6,
          required: true,
        },
        {
          field: 'file',
          type: 'file',
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};
