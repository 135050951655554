import {useFilter} from '@unthinkable/react-filters';
import {SearchFilter} from '../../../components/filter/Filters';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import { useAppStateContext } from '../../../providers/AppState';

export const EmployeeForIT = props => {
  let {filter, params, search, api} = props;
  const {organization, product, period, _id, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {
    ...filter,
  };
  if (_id) {
    addOnFilter = {
      ...addOnFilter,
      _id: _id,
    };
  }
  const {user, download} = useAppStateContext();

  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: api ? api : `/employees/active`,
  };
  return (
    <Table
      {...props}
      {...fetchProps}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Joining Date': 'joining_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      Practice: 'primary_practice.name',
                      'Practice head': 'primary_practice.owner.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Email',
          field: 'official_email_id',
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 80,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
      ]}
    />
  );
};

export const EmployeeForITTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter} = useFilter({});
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        filter={{
          ...filter,
          employee_status: 'Active',
          legal: {$in: [null, false]},
        }}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.inactive = {
    label: 'Inactive',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.legal = {
    label: 'Legal',
    api: '/employees/legal-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
        api="/employees/legal-with-package"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };
  return <TabView tabs={tabs} {...props} />;
};
