import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const RequestFeedbackForm = props => {
  const {route} = props;
  const {params} = route;
  const {project} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/sendClientFeedbackMail',
    eventSourceId: ['Project', 'ClientFeedback'],
    method: 'post',
    idField: false,
  });

  return (
    <Form
      header={{
        title: 'Request Feedback',
        secondaryTitle: project?.project,
      }}
      defaultValues={{
        _id: project?._id,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          type: 'date',
          field: 'client_feedback_from_date',
          label: 'Start Date',
          size: 6,
          helperText:
            'An email will be sent to the client, requesting for CSAT for the following duration.',
          required: true,
        },
        {
          type: 'date',
          field: 'client_feedback_to_date',
          label: 'End Date',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};
