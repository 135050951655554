import React, {useState} from 'react';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {ImageViewer} from '../../../components/attachment/ImageViewer';
import {isMobile} from '@unthinkable/react-utils';
import {LinkRenderer} from '../../../components/form/Renderers';
import {
  ContainerInfoStyles,
  ContainerPreviewerHeaderStyles,
  ContainerPreviewerStyles,
  ContainerSelectorStyles,
} from './theme';
import {ContainerImageViewerStyles} from '../../../components/attachment/theme';
import {SwitchInput as Switch} from '@unthinkable/react-switch';
import {Revision} from './Revision';
import {useFetchData} from '../../../controllers/useFetchData';
import {Tag} from '../../../components/chip/Chip';
import {ToggleButton} from '../../../components/toggle/ToggleButton';

export const AttachmentFields = {
  Mobile: {
    linkField: 'mobile_link',
    fileField: 'mobile_attachments',
  },
  Desktop: {
    linkField: 'desktop_link',
    fileField: 'desktop_attachments',
  },
  Tab: {
    linkField: 'tab_link',
    fileField: 'tab_attachments',
  },
};

export const DEVICE_TYPES = ['Mobile', 'Tab', 'Desktop'];

const LabelledSwitch = ({label, value, onChange}) => {
  const theme = useStyles(ContainerPreviewerHeaderStyles);
  const {switchLabel, switchStyle} = theme;
  return (
    <Row gap={16} style={{alignItems: 'center'}}>
      <Text style={switchLabel}>{label}</Text>
      <Switch value={value} onChangeValue={onChange} styles={switchStyle} />
    </Row>
  );
};

const ContainerSelector = ({
  typesCount,
  type,
  currentTypeIndex,
  setCurrentTypeIndex,
  setRevisionContainerIndex,
}) => {
  const theme = useStyles(ContainerSelectorStyles);
  const {
    container,
    LeftCaret,
    RightCaret,
    moveIconContainer,
    disableMoveIconContainer,
    textContainer,
    containerLabel,
    containerNameText,
  } = theme;

  return (
    <Row style={container}>
      <TouchableOpacity
        disabled={currentTypeIndex === 0}
        onPress={() => {
          if (currentTypeIndex > 0) {
            setCurrentTypeIndex(currentTypeIndex - 1);
            setRevisionContainerIndex(void 0);
          }
        }}
        style={{
          ...moveIconContainer,
          ...(currentTypeIndex === 0 ? disableMoveIconContainer : {}),
        }}>
        <Image source={LeftCaret} />
      </TouchableOpacity>
      <View style={textContainer}>
        <Text style={containerLabel}>{'Screen'}</Text>
        <Text style={containerNameText}>{type?.name}</Text>
      </View>
      <TouchableOpacity
        disabled={currentTypeIndex === typesCount - 1}
        onPress={() => {
          if (currentTypeIndex < typesCount - 1) {
            setCurrentTypeIndex(currentTypeIndex + 1);
            setRevisionContainerIndex(void 0);
          }
        }}
        style={{
          ...moveIconContainer,
          ...(currentTypeIndex === typesCount - 1
            ? disableMoveIconContainer
            : {}),
        }}>
        <Image source={RightCaret} />
      </TouchableOpacity>
    </Row>
  );
};

const ContainerPreviewerHeader = ({
  typesCount,
  type,
  showTypeSelector,
  currentTypeIndex,
  setCurrentTypeIndex,
  revisions,
  revisionContainerIndex,
  setRevisionContainerIndex,
  deviceTypes,
  deviceType,
  setDevice,
  currentFeatureDesign,
}) => {
  const theme = useStyles(ContainerPreviewerHeaderStyles);
  const {headerContainer} = theme;

  let dataIndexes = [];
  let indexToShow;

  if (revisions?.length) {
    revisions.map(_data => {
      dataIndexes.push(_data.version);
    });
  }
  if (dataIndexes?.length > 1) {
    dataIndexes.map((dataIndex, index) => {
      if (dataIndex === type?.version) {
        if (index === dataIndexes?.length - 1) {
          indexToShow = index - 1;
        } else {
          indexToShow = index + 1;
        }
      }
    });
  }
  let isTabVisible = deviceTypes?.length > 1;
  return (
    <View style={headerContainer}>
      {isMobile && showTypeSelector ? (
        <View style={{alignItems: 'center', paddingBottom: 12}}>
          <ContainerSelector
            typesCount={typesCount}
            currentTypeIndex={currentTypeIndex}
            setCurrentTypeIndex={setCurrentTypeIndex}
            type={type}
            setRevisionContainerIndex={setRevisionContainerIndex}
          />
        </View>
      ) : (
        void 0
      )}
      {isTabVisible ? (
        <View style={{flex: 1}}>
          <ToggleButton
            itemWidth={isMobile ? 75 : 100}
            value={deviceType}
            options={deviceTypes}
            onChange={value => {
              setDevice(value);
            }}
          />
        </View>
      ) : (
        void 0
      )}
      <Row
        style={{
          flex: 1,
          justifyContent: !isTabVisible ? 'flex-start' : 'center',
          alignItems: 'center',
        }}>
        {!isMobile && showTypeSelector ? (
          <ContainerSelector
            typesCount={typesCount}
            currentTypeIndex={currentTypeIndex}
            setCurrentTypeIndex={setCurrentTypeIndex}
            setRevisionContainerIndex={setRevisionContainerIndex}
            type={type}
          />
        ) : (
          void 0
        )}
      </Row>
      <View style={{flex: 1, alignItems: 'flex-end'}}>
        {revisions?.length > 1 ? (
          <Row gap={12}>
            <LabelledSwitch
              label={'Comparison'}
              value={revisionContainerIndex}
              onChange={() => {
                setRevisionContainerIndex(
                  revisionContainerIndex ? null : dataIndexes[indexToShow],
                );
              }}
            />
            <Revision
              type={type}
              data={revisions}
              setContainerIndex={setRevisionContainerIndex}
              containerIndex={revisionContainerIndex}
              currentFeatureDesign={currentFeatureDesign}
            />
          </Row>
        ) : (
          void 0
        )}
      </View>
    </View>
  );
};

const ContainerInfo = ({featureName, type, status, link}) => {
  const {
    FeatureIcon,
    infoContainer,
    containerNameText,
    requirementName,
    date,
    remark,
  } = useStyles(ContainerInfoStyles);

  const {name, updatedAt} = type;
  let viewName = name;
  return (
    <Col style={infoContainer} gap={4}>
      <Row gap={16} style={{alignItems: 'center'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <Text style={containerNameText}>{viewName}</Text>
        </View>
        <Row gap={16} style={{alignItems: 'center'}}>
          {link && <LinkRenderer linkText="Figma" value={link} />}
          {status ? (
            <Tag
              value={status.toUpperCase()}
              color={status == 'released' ? 'INFORMATION' : 'WARNING'}
            />
          ) : (
            void 0
          )}
          <Text style={date}>{moment(updatedAt).format('DD MMM YY')}</Text>
        </Row>
      </Row>
      {featureName ? (
        <Row gap={7} style={{alignItems: 'center'}}>
          <Image source={FeatureIcon} />
          <Text style={requirementName}>{featureName}</Text>
        </Row>
      ) : (
        void 0
      )}
      <Text title={type?.remarks} numberOfLines={1} style={remark}>
        {type?.remarks ? type?.remarks : 'No remarks...'}
      </Text>
    </Col>
  );
};

const ContainerImageViewer = ({attachments, ...props}) => {
  const theme = useStyles(ContainerImageViewerStyles);
  const icons = useTheme('icons');

  if (!attachments?.length) {
    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image source={icons.NoData} />
      </View>
    );
  }
  return <ImageViewer attachments={attachments} {...theme} {...props} />;
};

export const ContainerDetail = ({type, feature, deviceType} = {}) => {
  if (!type) {
    return null;
  }
  const link = type?.[AttachmentFields?.[deviceType]?.linkField] || type?.link;
  const attachments =
    type?.[AttachmentFields?.[deviceType]?.fileField] || type?.attachments;

  let featureName;

  if (type?.feature) {
    featureName = type?.feature?.feature;
  }

  const {status} = feature || {};
  return (
    <View style={{flex: 1, overflow: 'hidden'}}>
      <ContainerInfo
        featureName={featureName}
        status={status}
        type={type}
        link={link}
      />
      <ContainerImageViewer attachments={attachments} />
    </View>
  );
};

const DetailComponent = ({
  separator,
  revisions,
  revisionContainerIndex,
  ...props
}) => {
  let compareContainer;

  if (revisions?.length) {
    revisions.map(revision => {
      if (revision.version && revision.version === revisionContainerIndex) {
        compareContainer = revision;
      }
    });
  }

  let renderComponent = <ContainerDetail {...props} />;
  if (compareContainer) {
    renderComponent = (
      <View style={{flexDirection: 'row', flex: 1, overflow: 'hidden'}}>
        {renderComponent}
        <View style={separator} />
        <ContainerDetail {...props} type={compareContainer} />
      </View>
    );
  }
  return renderComponent;
};

export const FeatureTypeDesignPreviewer = ({
  typesCount,
  type,
  sourceId,
  showTypeSelector,
  currentTypeIndex,
  setCurrentTypeIndex,
  feature,
  currentFeatureDesign = true,
} = {}) => {

  if (!type) {
    return null;
  }
  if (!sourceId) {
    sourceId = type?._id;
  }

  const {project_id: {primaryDeviceType, deviceTypes = []} = {}} = type || {};

  const {data: revisions = []} = useFetchData({
    api: `/projectview/olddesigns`,
    filter: {source_id: sourceId},
  });

  const {container, separator} = useStyles(ContainerPreviewerStyles);
  const [revisionContainerIndex, setRevisionContainerIndex] = useState(void 0);
  let projectDeviceTypes = !deviceTypes?.length
    ? DEVICE_TYPES
    : DEVICE_TYPES.filter(deviceType => {
        if (deviceTypes.indexOf(deviceType) !== -1) {
          return true;
        }
      });

  let selectedIndex = projectDeviceTypes.indexOf(primaryDeviceType);
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }
  const [deviceType, setDeviceType] = useState(
    projectDeviceTypes[selectedIndex],
  );

  return (
    <View style={container}>
      {showTypeSelector ||
      revisions.length > 1 ||
      projectDeviceTypes?.length > 1 ? (
        <ContainerPreviewerHeader
          typesCount={typesCount}
          showTypeSelector={showTypeSelector}
          setCurrentTypeIndex={setCurrentTypeIndex}
          currentTypeIndex={currentTypeIndex}
          type={type}
          revisions={revisions}
          revisionContainerIndex={revisionContainerIndex}
          setRevisionContainerIndex={setRevisionContainerIndex}
          deviceTypes={projectDeviceTypes}
          deviceType={deviceType}
          setDevice={setDeviceType}
          currentFeatureDesign={currentFeatureDesign}
        />
      ) : (
        void 0
      )}
      <DetailComponent
        type={type}
        revisions={revisions}
        revisionContainerIndex={revisionContainerIndex}
        separator={separator}
        deviceType={deviceType}
        feature={feature}
      />
    </View>
  );
};
