import React from 'react';
import {MultiTag, MultiChip} from './MultiChip';
import {Chip, Tag} from './Chip';

export const MultiColorChip = ({
  value,
  labelField,
  colorField,
  titleField,
  ...props
}) => {
  if (!value || !Array.isArray(value)) {
    return null;
  }
  value = value.map((_value, index) => {
    const label = labelField ? _value?.[labelField] : void 0;
    const color = colorField ? _value?.[colorField] : void 0;
    const title = titleField ? _value?.[titleField] : void 0;
    return (
      <Chip value={label} color={color} title={title} key={index} {...props} />
    );
  });
  return <MultiChip values={value} {...props} />;
};

export const MultiColorTag = ({
  value,
  labelField,
  colorField,
  titleField,
  ...props
}) => {
  if (!value || !Array.isArray(value)) {
    return null;
  }
  value = value.map((_value, index) => {
    const label = labelField ? _value?.[labelField] : _value;
    const color = colorField ? _value?.[colorField] : void 0;
    const title = titleField ? _value?.[titleField] : _value;
    return (
      <Tag value={label} color={color} title={title} key={index} {...props} />
    );
  });
  return <MultiTag values={value} {...props} />;
};
