import React, {useState, useEffect, useRef} from 'react';
import {
  TextInput,
  View,
  Platform,
  Keyboard,
  Text,
  Row,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';

import {OTPInputStyles} from './theme';

export const OTPInput = ({
  onChange,
  otpLength = 6,
  value: propValue,
  autoFocus,
  ...restProps
}) => {
  const [internalVal, setInternalVal] = useState(propValue);
  const [focused, setFocused] = useState(false);

  const textInputRef = useRef();
  const styles = useStyles(OTPInputStyles);

  const isFocused = () => focused;

  const _keyboardDidHide = () => {
    isFocused() && blur();
  };

  useEffect(() => {
    autoFocus && focus();
    if (Platform.OS !== 'web') {
      const keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        _keyboardDidHide,
      );
      return () => keyboardDidHideListener?.remove?.();
    }
  }, []);

  useEffect(() => {
    if (propValue !== undefined && propValue !== internalVal) {
      setInternalVal(propValue);
    }
  }, [propValue]);

  const handleChangeText = val => {
    const numericValue = val.replace(/[^0-9]/g, '');
    onChange?.(numericValue);
    setInternalVal(numericValue);
  };

  // public methods
  const inputRef = () => textInputRef.current;

  const focus = () => {
    inputRef()?.focus?.();
    setFocused(true);
  };

  const blur = () => {
    inputRef()?.blur?.();
    setFocused(false);
  };

  return (
    <>
      <TextInput
        ref={input => (textInputRef.current = input)}
        onChangeText={handleChangeText}
        style={{width: 0, height: 0, padding: 1}}
        value={internalVal}
        minLength={otpLength}
        maxLength={otpLength}
        returnKeyType="done"
        inputMode="numeric"
        keyboardType={'numeric'}
        onBlur={blur}
        {...restProps}
      />
      <Row style={styles.container}>
        {new Array(otpLength).fill('').map((_, index) => (
          <View
            key={index}
            style={{
              ...styles.cell,
              ...((isFocused() && index === 0 && !internalVal) ||
              (internalVal && index === internalVal.length)
                ? styles.activeCell
                : {}),
            }}
            onPress={() => focus()}>
            <Text
              style={{
                ...styles.cellText,
                ...(internalVal && internalVal.length > index
                  ? styles.activeCellText
                  : {}),
              }}>
              {internalVal && internalVal.length > index
                ? internalVal[index]
                : '0'}
            </Text>
          </View>
        ))}
      </Row>
    </>
  );
};
