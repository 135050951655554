import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const QuotationComputation = fetch => ({
  'amount total in po': {
    compute: value => {
      let totalAmount = 0;
      if (value.exp_details && value.exp_details.length > 0) {
        value.exp_details.forEach(size => {
          if (size.purchase_amount) {
            totalAmount += size.purchase_amount;
          }
        });
      }
      return {
        total_amount: totalAmount,
      };
    },
    multi: true,
    dependencies: ['exp_details'],
  },
  nestedComputations: {
    exp_details: {
      purchase_amount: {
        compute: values => {
          if (values.rate && values.quantity) {
            return values.rate * values.quantity;
          }
        },
        // multi: true,
        dependencies: ['_id', 'rate', 'quantity'],
      },
    },
  },
});

const QuotationRequest = props => {
  const {
    route: {params},
  } = props;
  const {row, readOnly} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/quotations',
    eventSourceId: 'quotations',
  });
  let {user, fetch} = useAppStateContext();
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitActions="Save"
      readOnly={readOnly}
      defaultValues={{
        requested_by: user?.employee?._id,
        status: 'Requested',
        organization: user?.employee.organization,
        product: user?.employee.product,
      }}
      beforeSubmit={({data}) => {
        if (data && data.approved_mail.length < 3) {
          throw new Error('Please add atleast 3 quotation documents');
        }
      }}
      api={`/quotation/all/${row?._id}`}
      computations={{
        ...QuotationComputation(fetch),
      }}
      eventSourceId="Quotations"
      layoutFields={[
        {
          label: 'Basic',
          fields: [
            {
              label: 'PO Date',
              type: 'date',
              field: 'po_date',
              size: 4,
              required: true,
            },
            {
              label: 'Description',
              type: 'text',
              field: 'purpose',
              size: 4,
              required: true,
            },
            {
              label: 'Payment Term',
              field: 'payment_term',
              type: 'autoComplete',
              options: ['Advance', 'After Filter'],

              size: 4,
              required: true,
            },
            {
              label: 'Warranty Period(yrs)',
              field: 'warrenty_period',
              type: 'text',
              size: 4,
              required: true,
            },
            {
              label: 'Delivery days',
              field: 'delivery_days',
              type: 'text',
              size: 4,
              required: true,
            },
            {
              label: 'GST Applicable',
              field: 'gst_applicable',
              type: 'autoComplete',
              options: ['With Gst', 'Without Gst'],
              size: 4,
            },
            {
              field: 'priority',
              type: 'autoComplete',
              options: ['Urgent', 'High', 'Medium', 'Low'],
              placeholder: 'Priority',
              size: 4,
            },
            {
              placeholder: 'Quotations(min 3)',
              field: 'approved_mail',
              type: 'file',
              options: {bucketName: 'manaze'},
              multiple: true,
              required: true,
            },
          ],
        },
        {
          label: 'Vendor 1',
          fields: [
            {
              label: 'Vendor',
              field: 'vendor1',
              type: 'text',
              required: true,
              size: 3,
            },
            {
              label: 'Email',
              type: 'text',
              field: 'vendor1_email',
              size: 3,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'amount1',
              required: true,
              size: 3,
            },
            {
              type: 'file',
              placeholder: 'Quotation 1',
              field: 'attachment1',
              options: {bucketName: 'manaze'},
              required: true,
            },
          ],
        },
        {
          label: 'Vendor 2',
          fields: [
            {
              label: 'Vendor',
              field: 'vendor2',
              required: true,
              type: 'text',
              size: 3,
            },
            {
              label: 'Email',
              type: 'text',
              field: 'vendor2_email',
              size: 3,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'amount2',
              required: true,
              size: 3,
            },
            {
              type: 'file',
              placeholder: 'Quotation 2',
              field: 'attachment2',
              options: {bucketName: 'manaze'},
              required: true,
            },
          ],
        },
        {
          label: 'Vendor 3',
          fields: [
            {
              label: 'Vendor',
              field: 'vendor3',
              required: true,
              type: 'text',
              size: 3,
            },
            {
              label: 'Email',
              type: 'text',
              field: 'vendor3_email',
              size: 3,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'amount3',
              size: 3,
              required: true,
            },
            {
              type: 'file',
              placeholder: 'Quotation 3',
              options: {bucketName: 'manaze'},
              field: 'attachment3',
              required: true,
            },
          ],
        },
        {
          label: 'Quotations Details',
          fields: [
            {
              field: 'exp_details',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  columns={[
                    {
                      header: 'Item',
                      field: 'purchase_category',
                      type: 'text',
                    },
                    {
                      header: 'Quantity',
                      field: 'quantity',
                      type: 'number',
                    },
                    {
                      header: 'Rate',
                      field: 'rate',
                      type: 'number',
                    },
                    {
                      header: 'Amount',
                      field: 'purchase_amount',
                      type: 'number',
                    },
                  ]}
                  fields={[
                    {
                      label: 'Item',
                      field: 'purchase_category',
                      type: 'text',
                    },
                    {
                      label: 'Quantity',
                      field: 'quantity',
                      type: 'number',
                    },
                    {
                      label: 'Rate',
                      field: 'rate',
                      type: 'number',
                    },
                    {
                      label: 'Amount',
                      field: 'purchase_amount',
                      type: 'number',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [user?.employee?._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approved_by',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
          ],
        },
        {
          label: 'Accounting',
          fields: [
            {
              label: 'Organization',
              type: 'autoComplete',
              field: 'organization',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Product',
              type: 'autoComplete',
              field: 'product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Location',
              type: 'autoComplete',
              field: 'location_id',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Department',
              type: 'autoComplete',
              field: 'department',
              api: '/departments',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
          ],
        },
      ]}
    />
  );
};

export const AddQuotationRequest = props => {
  return <QuotationRequest header="Add Quotations" {...props} />;
};

export const EditQuotationRequest = props => {
  return (
    <QuotationRequest mode="edit" header={'Update Quotations'} {...props} />
  );
};

export const RejectQuotationReason = props => {
  const {route: {params} = {}} = props;

  const {row, reffer_to_coo} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/quotations`,
    eventSourceId: 'quotations',
  });
  let {
    user: {employee},
  } = useAppStateContext();
  return (
    <Form
      api={`/quotations/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_on: new Date(),
          },
        };
      }}
      mode="edit"
      header="Reject reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Reject reason',
          field: 'reject_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const QuotaitonApproveForm = props => {
  const {route: {params} = {}} = props;

  const {row, reffer_to_coo} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/quotations`,
    eventSourceId: 'quotations',
  });
  let {
    user: {employee},
  } = useAppStateContext();
  return (
    <Form
      api={`/quotations/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Approved',
            approved_on: new Date(),
            final_approver: employee._id,
          },
        };
      }}
      mode="edit"
      header="Approve vendor"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Approve Vendor',
          field: 'approve_vendor',
          type: 'radioGroup',
          valueField: 'value',
          suggestionField: 'label',
          keyField: 'value',
          options: [
            {label: 'Vendor 1', value: row?.vendor1},
            {label: 'Vendor 2', value: row?.vendor2},
            {label: 'Vendor 3', value: row?.vendor3},
          ],
          // onChangeValue: async (value, e, {setFieldValue}) => {
          //   if (value) {
          //     const {data: quotationData} = await fetch({
          //       uri: `/quotations/${row._id}`,
          //       props: {
          //         fields: {
          //           vendor1: 1,
          //           vendor2: 1,
          //           vendor3: 1,
          //         },
          //       },
          //     });
          //     console.log("quotationData>>>>>>>.",quotationData)
          //     if (value === 'vendor1') {
          //       setFieldValue('approve_vendor', quotationData?.vendor1);
          //     }
          //     if (value === 'vendor2') {
          //       setFieldValue('approve_vendor', quotationData?.vendor2);
          //     }
          //     if (value === 'vendor3') {
          //       setFieldValue('approve_vendor', quotationData?.vendor3);
          //     }
          //   }
          // },
          required: true,
        },
      ]}
      {...props}
    />
  );
};
