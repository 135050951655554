import React from 'react';
import {
  RadioInnerContainer,
  RadioContainer,
  RadioText,
  RadioWrapper,
} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';

export const BaseRadio = ({
  option,
  onChangeValue,
  value,
  disabled,
  readOnly,
  color,
  ...props
}) => {
  const {styles} = props;
  if (!option) {
    return null;
  }
  if (typeof option === 'string') {
    option = {value: option, label: option};
  }
  let {value: optionValue, label: optionLabel, color: colorValue} = option;

  colorValue = colorValue || color;

  const selected = value === option.value;
  return (
    <RadioWrapper gap={8}>
      <RadioContainer
        styles={styles}
        colorValue={colorValue}
        key={optionValue}
        onPress={() => onChangeValue && onChangeValue(optionValue)}
        disabled={disabled || readOnly}>
        {selected ? (
          <RadioInnerContainer colorValue={colorValue} styles={styles} />
        ) : (
          void 0
        )}
      </RadioContainer>
      <RadioText colorValue={colorValue} styles={styles}>
        {optionLabel || optionValue}
      </RadioText>
    </RadioWrapper>
  );
};

export const Radio = props => {
  return (
    <InputWrapper {...props}>
      <BaseRadio {...props} />
    </InputWrapper>
  );
};
