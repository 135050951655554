// Akshat Garg - 1/08/24 - showing the user segment contacts for active leads
import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {potential_customer: {website = ''} = {}} = row || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {website}
      </Text>
    </TouchableOpacity>
  );
};

export const UserSegmentActiveProspectList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  const invokePost = useInvoke({
    method: 'post',
    eventSourceId: ['removeProspect'],
  });

  let {user_segment_id, api: apiPath} = params || {};
  return (
    <Table
      {...props}
      selection={{
        actions: [
          {
            title: 'Delete',
            variant: 'error',
            confirm: {
              title: 'Delete',
              message: 'Are you sure you want to Delete?',
              confirmText: 'Delete',
            },
            onPress: ({selectedIds}) => {
              invokePost({
                uri: `/delete-selected-prospects`,
                props: {
                  selectedIds,
                  user_segment_id,
                  apiPath,
                },
              });
            },
          },
        ],
      }}
      api={`/usersegmentcontacts`}
      filter={{user_segment_id}}
      eventSourceId={'removeProspect'}
      fields={{
        potential_customer: {
          name: 1,
          email: 1,
          website: 1,
          createdAt: 1,
        },
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          type: 'text',
          header: 'Prospect',
          field: 'potential_customer.name',
        },
        {
          type: 'text',
          render: RenderName,
          header: 'Website',
        },
        {
          type: 'text',
          header: 'Email',
          field: 'potential_customer.email',
        },
        {
          type: 'date',
          header: 'Date',
          field: 'potential_customer.createdAt',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            invokePost({
              uri: `/delete-prospects`,
              props: {
                _id: row?._id,
                user_segment_id,
                apiPath,
              },
            });
          },
        },
      ]}
    />
  );
};
