import React from 'react';
import {resolveExp} from '@unthinkable/react-utils';

import {Tag} from '../../../components/chip/Chip';

export const TaskTypeRender = ({
  row,
  columnField,
  valueField,
  color = 'ACCENT1_MEDIUM',
  ...props
}) => {
  if (!columnField && !valueField) {
    return null;
  }

  let value;

  if (valueField && valueField({row})) {
    value = valueField({row});
  } else if (columnField) {
    value = resolveExp(row, columnField);
    color = 'ACCENT6_MEDIUM';
  }

  return <Tag {...props} value={value} color={color} />;
};
