import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {NavHeader} from '../../../components/nav/NavHeader';
import {TableHeader} from '../../../components/table/Headers';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {leadControllers} from '../controllers/leadControllers';

export const WebPageCampaignHeader = props => {
  const {
    route: {params},
  } = props;
  return (
    <NavHeader
      title="Web Page Campaign"
      {...props}
      actions={[
        <AddButton
          title="Web Page Campaign"
          view="add-webcampaign"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

const WebPageCampaignTableHeader = props => {
  const {
    route: {params},
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues,
  } = props;
  return (
    <TableHeader
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'assigned_to',
            },
          ]}
        />,
        <AddButton
          title="Web Page Campaign"
          view="add-webcampaign"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

export const WebPageTable = props => {
  const {navigation} = props;
  let params = props?.route?.params;
  const {product} = params;
  const filterInfo = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });

  const {searchValue, filterValues} = filterInfo;
  const {filter, params: filterParams = {}} = filterValues;
  params = {...params, ...filterParams};
  const {qualifiedLeads, uqls} = leadControllers({
    ...props,
    params,
  });
  return (
    <Table
      renderHeader={() => {
        return <WebPageCampaignTableHeader {...props} {...filterInfo} />;
      }}
      addOnFilter={filter}
      search={searchValue}
      limit={5000}
      eventSourceId={'WebPageCampaigns'}
      searchFields={'name'}
      // filterParams={filterParams}
      api={`/product/${product?._id}/webpage-campaign`}
      // onRowPress={({row}) => {
      //   navigation.navigate(`web-campaign-detail`, {
      //     leadId: row._id,
      //     row,
      //   });
      // }}
      params={params}
      columns={[
        {
          field: 'name',
          header: 'Web Page',
        },
        {
          field: 'url',
          header: 'URL',
        },
        {
          field: 'assigned_to.name',
          colorField: 'assigned_to.color',
          header: 'Owner',
          type: 'colorTag',
        },
        {
          type: 'number',
          field: 'visiter',
          header: 'Visitors',
        },
        {
          width: 140,
          type: 'number',
          field: 'lead_count',
          compareField: 'previous_lead_count',
          header: 'Lead',
          aggregate: AggregateComparisonRender,
          onPress: uqls,
          onComparePress: uqls,
          render: ComparisonRender,
        },
        {
          width: 140,
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'Qualified Lead',
          aggregate: AggregateComparisonRender,
          onPress: qualifiedLeads,
          onComparePress: qualifiedLeads,
          render: ComparisonRender,
        },
      ]}
      moreActions={[
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/campaigns`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};
