import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const DropOutreachCampaignContactsForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/campaigncontacts',
    eventSourceId: ['droppedContact'],
  });

  const {
    route: {params},
  } = props;

  let {row} = params || {};

  return (
    <Form
      onSubmit={onSubmit}
      header={'Reason'}
      submitAction={'Save'}
      defaultValues={{_id: row?._id, status: 'dropped'}}
      layoutFields={[
        {
          field: 'reason',
          type: 'text',
          label: 'Reason',
        },
      ]}
      {...props}
    />
  );
};
// Rajnish Trikaldarshi 22/12/2023 - Make campaignProspectDropForm to give the reason of drop the potential customer
export const OutreachCampaignProspectDropForm = props => {
  let {route: {params} = {}} = props || {};
  let {
    activeItem: {_id: potential_customer_id},
    campaign_id: _id,
    handleRemove,
  } = params || {};

  let {onSubmit} = useFormSubmit({
    uri: `/campaigns/${_id}/dropProspect`,
    eventSourceId: ['droppedContact'],
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={'Reason'}
      submitAction={'Save'}
      defaultValues={{potential_customer: potential_customer_id}}
      layoutFields={[
        {
          field: 'reason',
          type: 'text',
          label: 'Reason',
          required: true,
        },
      ]}
      afterSubmit={handleRemove}
      {...props}
    />
  );
};
