import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  EnggAllResourceTabs,
  ICEnggAllResourceTabs,
} from './EnggAllResourceTabs';
import {EnggBilledTab} from './EnggBilledTab';
import {EnggNonBilledTab} from './EnggNonBilledTab';
import ManagerDashboard from './ManagerDashboard';
import {EnggAnalyticsTabs} from './EngineeringEmployeeAnalyticsTabs';
import SkillLeaderDashboard from './SkillLeaderDashboard';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {BenchComparisionReport} from './BenchComparisionReport';

const EngineeringGroup = createMenuNavigator();
export const EngineeringNavigator = props => {
  const {route: {params} = {}} = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <EngineeringGroup.Navigator
      canAccess={canAccess}
      data-navigator-id="EngineeringGroup"
      {...props}>
      <EngineeringGroup.Screen
        data-resource-id="AllEng"
        name="All Eng"
        component={EnggAllResourceTabs}
        props={{user: user, key: 'Eng', ...props}}
        options={{
          label: 'All Eng',
          api: '/employees-team/workload',
          limit: 5000,
        }}
      />
      <EngineeringGroup.Screen
        data-resource-id="Managers"
        name="Managers"
        component={ManagerDashboard}
        props={{user: user, ...props}}
        options={{
          label: 'Managers',
          api: '/employees/managerdashboard',
          limit: 5000,
        }}
      />
      <EngineeringGroup.Section
        data-resource-id="ICs"
        name="ICs"
        options={{
          label: 'ICs',
          limit: 5000,
          addOnFilter: {is_manager: false},
        }}>
        <EngineeringGroup.Screen
          data-resource-id="IcsAll"
          name="All"
          component={EnggAllResourceTabs}
          props={{user: user, fromICMenu: true, key: 'All', ...props}}
          options={{
            label: 'All',
            api: '/employees-team/workload',
            addOnFilter: {is_manager: false},
            limit: 5000,
          }}
        />
        <EngineeringGroup.Screen
          data-resource-id="IcsBilled"
          name="Billed"
          component={EnggBilledTab}
          props={{user: user, fromICMenu: true, ...props}}
          options={{
            label: 'Billed',
            api: '/employees/internalresources',
            addOnFilter: {is_manager: false, project_type: {$in: ['tm', 'f']}},
            limit: 5000,
          }}
        />
        <EngineeringGroup.Screen
          data-resource-id="IcsBench"
          name="Bench"
          component={EnggNonBilledTab}
          props={{user: user, fromICMenu: true, ...props}}
          options={{
            label: 'Bench',
            api: '/employees/bench',
            addOnFilter: {is_manager: false},
            params: {is_AllBenchEmployee: true},
            limit: 5000,
          }}
        />
      </EngineeringGroup.Section>
      <EngineeringGroup.Screen
        data-resource-id="Analytics"
        name="Analytics"
        component={EnggAnalyticsTabs}
        props={{user: user, ...props}}
        options={{
          label: 'Analytics',
        }}
      />
      <EngineeringGroup.Screen
        data-resource-id="BenchComparison"
        name="Bench Comparison"
        component={BenchComparisionReport}
        props={{user: user, ...props}}
        options={{
          label: 'Bench Comparison',
        }}
      />
      <EngineeringGroup.Screen
        data-resource-id="SkillLeader"
        name="Skill Leader"
        component={SkillLeaderDashboard}
        props={{user: user, ...props}}
        options={{
          label: 'Skill Leader',
          api: '/employees/skillLeaderashboard',
        }}
      />
    </EngineeringGroup.Navigator>
  );
};
