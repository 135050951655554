export default {
  date: {
    formatOptions: {
      format: 'DD MMM',
    },
  },
  number: {
    formatOptions: {
      maximumFractionDigits: 0,
    },
  },
  currency: {
    formatOptions: {
      maximumFractionDigits: 0,
    },
  },
};
