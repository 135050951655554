import React from 'react';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {TableHeader} from '../../../components/table/Headers';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';
import {Image, View} from '@unthinkable/react-core-components';
import {IconButton} from '@unthinkable/react-button';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useTheme} from '@unthinkable/react-theme';

const RenderAvgRating = props => {
  return <RatingRenderer value={props?.row?.avg_rating} />;
};

const RatingCount = ({row}) => {
  const icons = useTheme('icons');
  return (
    <View
      style={{
        backgroundColor: '#FAFAFA',
        flexDirection: 'row',
        gap: 4,
        padding: 12,
        marginBottom: 12,
        borderRadius:4,
      }}>
      <Image style={{width: 16, height: 16}} source={icons.EstChange} />
      <GroupContentItem value={`Rated by ${row.feedback_count} Trainee(s).`} />
    </View>
  );
};

export const TraineeFeedbackForTrainerView = props => {
  const {
    route: {params},
  } = props;
  const {training_batch} = params;
  const {
    user: {employee},
  } = useAppStateContext();
  return (
    <Table
      api="/getTraineeFeedbackForTrainer"
      filter={{
        training_batch: training_batch?._id,
        mentor: employee?._id,
      }}
      renderHeader={() => (
        <View>
          <TableHeader
            title={'Trainee Feedback'}
            secondaryTitle={training_batch?.name}
          />
          <RatingCount row={training_batch} />
        </View>
      )}
      columns={[
        {
          header: 'Criteria',
          field: 'question',
          type: 'text',
        },
        {
          header: 'Avg. Rating',
          render: RenderAvgRating,
          width: 120,
        },
      ]}
    />
  );
};
