import React, {useState} from 'react';
import {
  FormBody,
  FormGroupContainer,
  FormGroupLabel,
  InlineFormContainer,
  FormGroupRow,
  FormGroupImage,
  GroupButton,
  GroupButtonText,
  GroupButtonIcon,
  GroupButtonRow,
  FormFooter,
  FormHeader,
  CollapsedFieldsRow,
  CollapsedFieldButtonText,
} from './FormStyles';
import Field from './Field';
import {useFormContext} from './FormContext';
import {Grid, GridItem} from '@unthinkable/react-layout';
import {Text, TouchableOpacity, View} from '@unthinkable/react-core-components';
import NestedField from './NestedField';
import {hasValue} from '@unthinkable/react-utils';
import {
  CancelAction,
  CrossAction,
  EditAction,
  InlineSubmitButton,
  SubmitButton,
} from './FormActions';
import {TabView} from '@unthinkable/react-tabs';

export const useResolveFormProps = ({
  header,
  footer,
  skipDefaultSave,
  submitAction,
  submitActionPosition = 'footer',
  closable = true,
  cancelLabel,
  onClose,
  closeByIcon,
  editAsIcon,
  editAction,
  ...props
}) => {
  const {styles} = props;
  const context = useFormContext();
  const {readOnly, isDualMode} = context;

  if (typeof header === 'function') {
    header = header(context);
  }
  if (typeof header === 'string') {
    header = {title: header};
  }
  if (typeof footer === 'function') {
    footer = footer(context);
  }
  if (typeof footer === 'string') {
    footer = {title: footer};
  }
  if (typeof submitAction === 'string') {
    submitAction = {label: submitAction};
  }

  const submitActionButton = (
    <SubmitButton styles={styles?.primaryButton} {...submitAction} />
  );

  let {actions} = header || {};
  let {actions: footerActions} = footer || {};
  if (typeof actions === 'function') {
    actions = actions(context);
  }
  if (typeof footerActions === 'function') {
    footerActions = footerActions(context);
  }
  if (isDualMode && readOnly) {
    actions = actions ? [...actions] : [];
    actions.push(
      <EditAction
        styles={styles?.primaryButton}
        iconStyles={styles.iconButton}
        asIcon={editAsIcon}
        {...(typeof editAction === 'string'
          ? {label: editAction}
          : {icon: styles.editIcon})}
      />,
    );
  }
  if (!readOnly && !skipDefaultSave) {
    if (submitActionPosition === 'footer') {
      footerActions = footerActions ? [...footerActions] : [];
      footerActions.push(submitActionButton);
    } else if (submitActionPosition === 'header') {
      actions = actions ? [...actions] : [];
      actions.push(submitActionButton);
    }
  }

  if (closable && onClose) {
    if (closeByIcon) {
      actions = actions ? [...actions] : [];
      actions.push(
        <CrossAction styles={styles?.iconButton} onClose={onClose} />,
      );
    } else {
      const cancelAction = (
        <CancelAction
          styles={
            footerActions?.length == 0
              ? styles?.primaryButton
              : styles?.secondaryButton
          }
          label={cancelLabel}
          onClose={onClose}
        />
      );
      footerActions = footerActions ? [...footerActions] : [];
      footerActions.unshift(cancelAction);
    }
  }

  return {
    ...props,
    header: {...header, actions},
    footer: {...footer, actions: footerActions},
  };
};

const FormGroupHeader = ({label, count, icon, styles}) => {
  if (!label) {
    return null;
  }

  let value = label;
  if (count) {
    value = `${value} (${count})`;
  }

  return (
    <FormGroupRow gap={8} styles={styles}>
      {icon ? <FormGroupImage styles={styles} source={icon} /> : void 0}
      <FormGroupLabel styles={styles}>{value}</FormGroupLabel>
    </FormGroupRow>
  );
};

const isValueExist = ({valueFields, fields, field}, {values}) => {
  if (!valueFields && Array.isArray(fields)) {
    valueFields = fields?.map(fieldInfo => fieldInfo?.field);
  }
  if (!valueFields && field) {
    valueFields = [field];
  }
  if (Array.isArray(valueFields)) {
    return valueFields.some(field => field && hasValue(values[field]));
  }
};

const CollapsedFieldsLayout = ({styles, collapsedFields}) => {
  const context = useFormContext();
  const {values} = context;
  const [visibleFields, setVisibleFields] = useState({});

  collapsedFields = collapsedFields?.filter(collapsedField => {
    if (collapsedField && collapsedField.label) {
      const {visible = true} = collapsedField;
      if (typeof visible == 'function') return visible(context);
      return visible;
    }
  });

  const isFieldVisible = field => {
    const {label, defaultExpanded, defaultHidden} = field;
    if (defaultExpanded) {
      return true;
    }
    if (visibleFields[label] !== undefined) {
      return visibleFields[label];
    }
    return defaultHidden ? false : isValueExist(field, {values});
  };

  const onCollapsedFieldPress = field => {
    const {label} = field;
    setVisibleFields({
      ...visibleFields,
      [label]: true,
    });
  };

  let activeFields = collapsedFields.filter(collapsedField =>
    isFieldVisible(collapsedField),
  );
  let inActiveFields = collapsedFields.filter(
    collapsedField => !isFieldVisible(collapsedField),
  );

  return (
    <>
      {activeFields?.length ? (
        <LayoutFieldsRender styles={styles} layoutFields={activeFields} />
      ) : null}
      {inActiveFields?.length ? (
        <CollapsedFieldsRow styles={styles} activeCount={activeFields?.length}>
          {inActiveFields.map(inActiveField => {
            const label = inActiveField.label;
            return (
              <TouchableOpacity
                key={label}
                onPress={() => onCollapsedFieldPress(inActiveField)}>
                <CollapsedFieldButtonText styles={styles}>
                  {label}
                </CollapsedFieldButtonText>
              </TouchableOpacity>
            );
          })}
        </CollapsedFieldsRow>
      ) : (
        void 0
      )}
    </>
  );
};

const GroupsLayout = ({styles, groups}) => {
  const context = useFormContext();
  const {values} = context;
  const [selectedGroups, setSelectedGroups] = useState({});

  groups = groups?.filter(group => {
    if (group && group.label) {
      const {visible = true} = group;
      if (typeof visible == 'function') return visible(context);
      return visible;
    }
  });

  const isGroupActive = group => {
    const {label, defaultHidden} = group;
    if (selectedGroups[label] !== undefined) {
      return selectedGroups[label];
    }
    return defaultHidden ? false : isValueExist(group, {values});
  };

  const toggleGroup = group => {
    const {label} = group;
    setSelectedGroups({
      ...selectedGroups,
      [label]: !isGroupActive(group),
    });
  };

  let activeGroups = groups.filter(group => isGroupActive(group));
  activeGroups = activeGroups?.map(activeGroup => {
    if (
      !(activeGroup?.fields && Array.isArray(activeGroup?.fields)) &&
      activeGroup?.field
    ) {
      let {label, ...rest} = activeGroup;
      return rest;
    } else {
      return activeGroup;
    }
  });
  return (
    <>
      <GroupButtonRow styles={styles} activeCount={activeGroups?.length}>
        {groups.map(group => {
          const label = group.label;
          const isActive = isGroupActive(group);
          const hasValue = isValueExist(group, {values});
          return (
            <GroupButton
              key={label}
              styles={styles}
              isActive={isActive}
              onPress={() => toggleGroup(group)}>
              <GroupButtonText styles={styles} isActive={isActive}>
                {label}
              </GroupButtonText>
              {hasValue ? (
                <GroupButtonIcon styles={styles} isActive={isActive} />
              ) : (
                void 0
              )}
            </GroupButton>
          );
        })}
      </GroupButtonRow>
      {activeGroups?.length ? (
        <LayoutFieldsRender styles={styles} layoutFields={activeGroups} />
      ) : null}
    </>
  );
};

const CollapsedField = ({children, fieldInfo, values, styles}) => {
  const valueExist = isValueExist(fieldInfo, {values});
  const [isVisible, setIsVisible] = useState(valueExist);

  let renderComponent = children;
  if (!isVisible) {
    renderComponent = (
      <TouchableOpacity
        onPress={() => {
          setIsVisible(true);
        }}>
        <CollapsedFieldButtonText styles={styles}>
          {fieldInfo?.label}
        </CollapsedFieldButtonText>
      </TouchableOpacity>
    );
  }
  return renderComponent;
};

export const LayoutFieldsRender = ({layoutFields, level = 0, ...props}) => {
  let {styles, defaultSize, computations, _parentValues} = props;
  const nestedComputations = computations?.nestedComputations;
  const context = useFormContext();
  const {rowGap = 12, colGap = 12} = styles || {};

  if (!layoutFields || !Array.isArray(layoutFields)) {
    return (
      <View>
        <Text>{'Fields must be defined in Array in form'}</Text>
      </View>
    );
  }

  layoutFields = layoutFields?.filter(layoutField => {
    if (!layoutField) {
      return;
    }
    let {visible = true} = layoutField;
    if (typeof visible === 'function') {
      visible = visible(context);
    }
    return visible;
  });
  if (!layoutFields?.length) {
    return null;
  }

  return (
    <Grid colGap={colGap} rowGap={rowGap}>
      {layoutFields.map((info, index) => {
        if (Array.isArray(info)) {
          return (
            <GridItem key={index}>
              <LayoutFieldsRender {...props} layoutFields={info} />
            </GridItem>
          );
        } else {
          let {width, size, maxWidth, nested, ...restInfo} = info;
          let {
            field,
            render,
            fields,
            groups,
            collapsed,
            collapsedFields,
            tabs,
            header,
            variant,
            tabProps,
            ...rest
          } = restInfo;

          let {height, ...restTabProps} = tabProps || {};

          if (typeof header === 'function') {
            header = header(props);
          }
          let renderComponent = void 0;
          if (nested) {
            renderComponent = (
              <NestedField
                {...restInfo}
                _parentValues={_parentValues}
                computations={nestedComputations?.[field]}
              />
            );
          } else if (field) {
            renderComponent = <Field key={field} {...restInfo} />;
            if (collapsed) {
              renderComponent = (
                <CollapsedField
                  fieldInfo={{...restInfo, field: field}}
                  values={context.values}
                  styles={styles}>
                  {renderComponent}
                </CollapsedField>
              );
            }
          } else if (render) {
            renderComponent = render(restInfo, context);
          } else if (fields && Array.isArray(fields)) {
            renderComponent = (
              <>
                {header === false ? (
                  void 0
                ) : (
                  <FormGroupHeader {...rest} {...header} styles={styles} />
                )}
                <LayoutFieldsRender
                  {...props}
                  layoutFields={fields}
                  level={level + 1}
                />
              </>
            );
            if (variant !== 'none') {
              renderComponent = (
                <FormGroupContainer key={index} styles={styles} level={level}>
                  {renderComponent}
                </FormGroupContainer>
              );
            }
          } else if (groups) {
            renderComponent = <GroupsLayout {...props} groups={groups} />;
          } else if (collapsedFields) {
            renderComponent = (
              <CollapsedFieldsLayout
                {...props}
                collapsedFields={collapsedFields}
              />
            );
          } else if (tabs) {
            let newTabs = Object.keys(tabs)?.reduce((acc, key) => {
              let {visible = true, ...tab} = tabs[key] || {};
              if (typeof visible == 'function') {
                visible = visible(context);
              }
              if (visible) {
                acc[key] = {
                  ...tab,
                  view: (
                    <View style={{height}}>
                      <FormBody styles={styles}>
                        <LayoutFieldsRender {...props} {...tab} />
                      </FormBody>
                    </View>
                  ),
                };
              }
              return acc;
            }, {});
            renderComponent = (
              <TabView
                {...props}
                {...restTabProps}
                tabs={newTabs}
                showsHorizontalScrollIndicator={false}
                styles={styles?.tabView}
              />
            );
          } else {
            renderComponent = (
              <View>
                <Text>Invalid Field</Text>
              </View>
            );
          }
          return (
            <GridItem
              key={index}
              size={size || defaultSize}
              width={width}
              maxWidth={maxWidth}>
              {renderComponent}
            </GridItem>
          );
        }
      })}
    </Grid>
  );
};

export const FormLayout = props => {
  const {header, footer, inline, skipHeader, skipFooter, ...restProps} =
    useResolveFormProps(props);
  const {styles} = restProps;
  if (inline) {
    return (
      <InlineFormContainer gap={8} styles={styles}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <LayoutFieldsRender {...restProps} defaultSize={1} />
        </View>
        {props.skipDefaultSave ? (
          void 0
        ) : (
          <InlineSubmitButton styles={styles?.inlineFormButton} />
        )}
      </InlineFormContainer>
    );
  } else {
    return (
      <>
        {header && !skipHeader ? (
          <FormHeader gap={12} styles={styles?.header} {...header} />
        ) : (
          void 0
        )}
        <FormBody styles={styles}>
          <LayoutFieldsRender {...restProps} />
        </FormBody>
        {footer?.actions && !skipFooter ? (
          <FormFooter styles={styles?.footer} {...footer} />
        ) : (
          void 0
        )}
      </>
    );
  }
};

export const InlineFormLayout = props => {
  const {styles, skipDefaultSave} = props;
  return (
    <InlineFormContainer gap={8} styles={styles}>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <LayoutFieldsRender {...restProps} defaultSize={1} />
      </View>
      {skipDefaultSave ? (
        void 0
      ) : (
        <InlineSubmitButton styles={styles?.inlineFormButton} />
      )}
    </InlineFormContainer>
  );
};
