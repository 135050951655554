import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';

export const ImportHistoryLogDetail = props => {
  const {
    route: {params},
  } = props;
  const {importHistoryLogId} = params;
  return (
    <Form
      api={`/importhistorylogs/${importHistoryLogId}`}
      fields={{
        source: 1,
        model: 1,
        importDate: 1,
        status: 1,
        total: 1,
        success: 1,
        error: 1,
        file: 1,
        analytics: 1,
        errorLogs: 1,
        campaign_id: {name: 1},
      }}
      mode="edit"
      readOnly={true}
      header={{title: 'Import History Detail'}}
      editable={false}
      layoutFields={[
        {
          field: 'source',
          type: 'text',
          label: 'Source',
        },
        {
          field: 'model',
          type: 'text',
          label: 'Model',
        },
        {
          field: 'importDate',
          type: 'date',
          label: 'Date',
        },
        {
          field: 'status',
          type: 'text',
          label: 'Status',
        },
        {
          field: 'total',
          type: 'number',
          label: 'Total',
        },
        {
          field: 'success',
          type: 'number',
          label: 'Success',
        },
        {
          field: 'error',
          type: 'number',
          label: 'Error',
        },
        {
          field: 'campaign_id.name',
          type: 'text',
          label: 'Campaign',
        },
        {
          field: 'file',
          type: 'file',
          label: 'File',
        },
        {
          field: 'analytics',
          type: 'text',
          label: 'Analytics',
        },
        {
          field: 'errorLogs',
          nested: true,
          label: 'Errors',
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  field: 'index',
                  header: 'Index',
                  type: 'number',
                },
                {
                  field: 'error',
                  header: 'Error',
                  type: 'textArea',
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};
