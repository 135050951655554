import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Tag} from '../../../components/chip/Chip';

export const TypesTable = props => {
  const {navigation} = props;
  const {filter, onChangeFilter, searchValue} = useFilter();

  return (
    <Table
      renderHeader={() => (
        <TableHeader
          actions={
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />
          }
        />
      )}
      searchFields="label"
      addOnFilter={{...filter}}
      search={searchValue}
      eventSourceId={['Type', 'TaskActions', 'DataEntityView']}
      api={`/Types`}
      fields={{
        name: 1,
        label: 1,
        display_field: 1,
        departments: {name: 1},
        views_count: {_id: 1},
        task_assignment_type: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`types-detail`, {
          typeId: row._id,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Data Entity',
        },
        {
          header: 'Assignment Type',
          render: ({row}) => {
            const {task_assignment_type} = row || {};
            if (!task_assignment_type) {
              return;
            }
            return (
              <Tag
                value={task_assignment_type}
                color={
                  task_assignment_type === 'Product' ? 'ACCENT1' : 'ACCENT2'
                }
              />
            );
          },
          width: 150,
        },
        {
          render: CountCellRender,
          count_field: 'views_count',
          header: 'Views',
          onPress: ({row}) => {
            navigation.navigate('data-entity-views', {
              dataEntity: row,
            });
          },
          align: 'center',
          width: 150,
        },
      ]}
    />
  );
};
