import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateLeadStatusForm = props => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Lead',
    uri: '/potentialcustomers',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Lead Status'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Status',
          field: 'lead_status',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          options: ['Lost'],
          dependentFields: ['lost_description_comment', 'lost_description'],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue(
              'lost_date',
              value === 'Lost' ? getZeroTimeDate(new Date()) : void 0,
            );
          },
        },
        {
          placeholder: 'Lost Reason',
          field: 'lost_description_comment',
          type: 'autoComplete',
          options: [
            'Not Interested',
            'Do Not Call',
            'Not Relevant',
            'Rejected',
          ],

          visible: ({values}) => {
            return values?.lead_status === 'Lost' ? true : false;
          },
        },
        {
          placeholder: 'Lost Description',
          field: 'lost_description',
          type: 'text',
          visible: ({values}) => {
            return values?.lead_status === 'Lost' ? true : false;
          },
        },
      ]}
    />
  );
};

export const MarkLeadLostCumulativeForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  return (
    <Form
      defaultValues={{
        lead_status: 'Lost',
        lost_date: getZeroTimeDate(new Date()),
        _id: row?._id,
      }}
      onSubmit={onSubmit}
      header={'Mark Lost'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Lost Stage',
          field: 'lead_sub_stage',
          type: 'autoComplete',
          options: ['Unsubscribed', 'Rejected'],
        },
        {
          placeholder: 'Lost Reason',
          field: 'lost_description_comment',
          required: true,
          type: 'autoComplete',
          options: [
            'Do not call/ Unsubscribe/Negative Response',
            'Budget',
            'No Requirement',
            'Lost to Competition',
            'Delay in supplying inputs',
            'T&C Disagreement',
            'Not relevant',
            'Small requirement',
            'Unavailability of tech expertise',
            'Count not available on the bench',
          ],
        },
        {
          placeholder: 'Lost Description',
          field: 'lost_description',
          type: 'text',
        },
      ]}
    />
  );
};

export const AddLeadConsultants = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  return (
    <Form
      defaultValues={{
        _id: row?._id,
        tech_consultant: row?.tech_consultant,
        solution_consultant: row?.solution_consultant,
        business_analyst: row?.business_analyst,
        business_consultant: row?.business_consultant,
      }}
      onSubmit={onSubmit}
      header={'Add Consultants'}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Technical Consultant',
          field: 'tech_consultant',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Solution Consultant',
          field: 'solution_consultant',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Business Analyst',
          field: 'business_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Business Consultant',
          field: 'business_consultant',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
      ]}
    />
  );
};
