// Form to add Unique selling point - Parveen 13-02-2024
import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UniqueSellingPointAddForm = props => {
  const {
    header = 'Add Unique Selling Point',
    route: params = {},
    navigation,
  } = props;
  console.log('propssss', props);
  let {onSubmit} = useFormSubmit({
    uri: '/uniquesellingpoints',
    eventSourceId: ['uniqueSellingPointAdded'],
  });

  return (
    <Form
      fields={{
        name: 1,
        sample: 1,
        sample_followup: 1,
        use_case: 1,
        product: {name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
          placeholder: 'Name',
          required: true,
        },
        {
          field: 'sample',
          type: 'text',
          label: 'Sample',
          placeholder: 'Sample',
        },
        {
          field: 'sample_followup',
          type: 'text',
          label: 'Sample_follow up',
          placeholder: 'Sample_follow up',
        },
        {
          field: 'use_case',
          type: 'text',
          label: 'Use Case',
          placeholder: 'Use Case',
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: '/products',
          suggestionField: 'name',
          valueField: 'name',
          size: 'medium',
        },
      ]}
      {...props}
    />
  );
};

export const UniqueSellingPointEditForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <UniqueSellingPointAddForm
      mode="edit"
      api={`/uniquesellingpoints/${_id}`}
      header={'Edit Unique Selling Point'}
      {...props}
    />
  );
};
