import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {randomColor} from '../../../utils/util';

const DUE_ALLOCATIONS = ({navigation}) => ({
  title: 'Skill Requirement',
  onPress: ({row}) => {
    navigation.navigate('order-resource-request-table', {
      data: {order: row._id, fromOrderWiseAllocations: true},
    });
  },
  aggregate: true,
});

export const OrderWiseAllocations = props => {
  let {
    filter = {},
    filterParams = {},
    route: {params},
    navigation,
    searchValue,
  } = props;

  return (
    <Table
      api={'/orderAllocations'}
      eventSourceId={['employeeAssigned']}
      searchFields="order_number"
      search={searchValue}
      limit={2000}
      addOnFilter={{...filterParams, ...filter}}
      columns={[
        {
          header: 'Order No.',
          field: 'order_number',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Customer',
          field: 'customer_id.name',
          type: 'colorTag',
          colorField: 'customer_id.color',
        },
        {
          header: 'AM',
          field: 'account_owner_id',
          type: 'userAvatarChip',
        },
        {
          header: 'CSM/PM',
          field: 'delivery_owner_id',
          type: 'userAvatarChip',
        },
        // {
        //   header: 'Start Date',
        //   field: 'order_date',
        //   type: 'date',
        //   formatOptions: {
        //     format: 'DD MMM YY',
        //   },
        // },
        // {
        //   header: 'End Date',
        //   field: 'order_end_date',
        //   type: 'date',
        //   formatOptions: {
        //     format: 'DD MMM YY',
        //   },
        // },
        {
          render: CountCellRender,
          count_field: 'allocationHourWise',
          header: 'Allocations',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate('project-allocations-tab', {
              addOnFilter: {
                order: row?._id,
              },
            });
          },
        },
      ]}
      moreActions={() => [DUE_ALLOCATIONS({navigation})]}
      params={params}
    />
  );
};

export const OrderWiseAllocationTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter(
    {},
  );
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  let filterParams = {...filter};

  const tabs = {
    order_wise_allocation_tm: {
      label: 'T&M Order Allocations',
      view: (
        <OrderWiseAllocations
          searchValue={searchValue}
          filterParams={{...filterParams, order_type: 'r'}}
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/orderAllocations',
      addOnFilter: {...filterParams, order_type: 'r'},
      limit: 2000,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
          ]}
        />,
      ]}
    />
  );
};
