import React from 'react';
import {Col, Image, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';

// import Locomo2 from './images/Locomo2.svg';
import UnthinkableLogo from './images/UnthinkableLogo.svg';
import {LoginPageComponentStyles} from './theme';

const LoginPageComponent = props => {
  const {
    containerStyle,
    innerContainerStyle,
    headerContainerStyle,
    headerTextStyle,
    footerContainerStyle,
    footerTextStyle,
    LOCOMOIcon
  } = useStyles(LoginPageComponentStyles);

  return (
    <View style={containerStyle}>
      <Col gap={40} style={innerContainerStyle}>
        <Col gap={8} style={headerContainerStyle}>
          <Image source={LOCOMOIcon} />
          {/* <Text style={headerTextStyle}>Everything more efficient</Text> */}
        </Col>
        {props.children}
        <Col gap={8} style={footerContainerStyle}>
          <Text style={footerTextStyle}>Powered by</Text>
          <Image source={UnthinkableLogo} />
        </Col>
      </Col>
    </View>
  );
};
export default LoginPageComponent;
