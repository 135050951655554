import {Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {ProjectDetailedName} from '../../../app-components/renders/ProjectDetailedName';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {useProjectActions} from '../actions/ProjectActions';
import {Durations} from '../../order/views/components/Durations';
import {Tag} from '../../../components/chip/Chip';
import {PROJECT_TYPES} from '../constants/ProjectConstant';

const TYPE = {
  header: 'Type',
  width: 140,
  render: ({row}) => {
    const {new_project_type, projecttype} = row;
    if (!(new_project_type || projecttype)) {
      return null;
    }
    return (
      <Tag
        value={PROJECT_TYPES[new_project_type || projecttype]?.label}
        color={PROJECT_TYPES[new_project_type || projecttype]?.color}
      />
    );
  },
};

export const projectFilters = [
  {
    label: 'Department',
    field: 'department',
    placeholder: 'Select Department',
    type: 'autoComplete',
    api: `/departments`,
    suggestionField: 'name',
    valueField: 'name',
  },
  {
    label: 'Product',
    field: 'product',
    placeholder: 'Select Product',
    type: 'autoComplete',
    api: `/products`,
    suggestionField: 'name',
    valueField: 'name',
  },
  {
    label: 'Type',
    field: 'projecttype',
    placeholder: 'Select Project Type',
    type: 'autoComplete',
    options: [
      {value: 'f', label: 'Fixed'},
      {value: 'i', label: 'Internal'},
      {value: 'tm', label: 'T & M'},
      {value: 'tr', label: 'Training'},
    ],
    suggestionField: 'label',
    keyField: 'value',
    valueField: 'label',
  },

  {
    label: 'Sub Category',
    field: 'project_sub_category',
    placeholder: 'Select Project Sub Category',
    type: 'autoComplete',
    options: [
      'Client Managed',
      'Daffodil Managed',
      'Hybrid Managed',
      'Daffodil Managed - Support',
    ],
  },
  {
    label: 'Customer',
    field: 'customer',
    placeholder: 'Select Customer',
    type: 'autoComplete',
    api: `/customers`,
    suggestionField: 'name',
    valueField: 'name',
  },
  {
    label: 'Tool',
    field: 'tool',
    placeholder: 'Select Tool',
    type: 'autoComplete',
    options: ['Locomo', 'Other'],
  },
  {
    label: 'Feedback Mode',
    field: 'feedback_mode',
    placeholder: 'Select Feedback Mode',
    type: 'autoComplete',
    options: ['Automatic', 'Manual'],
  },
  {
    label: 'Last automatic feedback request',
    field: 'automatic_feedback_request_success_date',
    type: 'date',
  },
  {
    label: 'Last automatic feedback failure',
    field: 'automatic_feedback_request_failure_date',
    type: 'date',
  },
];

const mergeProject = ({params, navigation}) => ({
  title: 'Merge Project',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`merge-detail`, {
      ...params,
      source: {_id: row?._id},
      title: 'Merge Project',
      secondaryTitle: row?.project,
      targetField: {
        api: `/projects`,
        suggestionField: 'project',
        valueField: 'project',
      },
      uri: '/projects/merge',
      eventSourceId: 'Project',
    });
  },
});

export const ProjectsTable = props => {
  const {
    navigation,
    searchValue,
    filter,
    scope,
    status,
    searchFields = 'project',
    route: {params},
    tab,
  } = props;
  const {product} = params || {};
  const {fonts, colors} = useTheme();

  const {
    projectDetail,
    editProject,
    projectDescription,
    markReleased,
    markDelete,
    markActive,
    activityLogDetail,
  } = useProjectActions({
    navigation,
    params: {...params, showHelpData: true},
  });

  return (
    <Table
      eventSourceId={['Project', 'Employee', 'Feature']}
      api={`/projects/active`}
      params={{
        product: product?._id,
        scope,
        status,
        ...params,
      }}
      onRowPress={projectDetail}
      renderNoDataProps={{helpId: 'pmt_project_list'}}
      filter={{...filter, projecttype: {$ne: 'dpt'}}}
      search={searchValue}
      searchFields={searchFields}
      limit={50}
      columns={[
        // tab !== 'closed' && {
        //   header: 'Active Since',
        //   field: 'createdAt',
        //   type: 'date',
        //   formatOptions: {format: 'DD MMM YY'},
        //   width: 120,
        // },
        {
          type: 'text',
          field: 'project',
          header: 'Project',
          render: ProjectDetailedName,
          minWidth: 250,
        },
        {
          header: 'Customer',
          width: 300,
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {row?.customer?.name}
              </Text>
            );
          },
        },
        TYPE,
        // {
        //   width: 150,
        //   render: CountColorChip,
        //   field: 'feature_count',
        //   suffix: 'Feature',
        // },
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: 'Owner',
          width: 160,
        },
        tab == 'closed' && {
          header: 'Duration',
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return Durations({
              styles: rowText,
              fromDate: row?.project_start_date,
              toDate: row?.project_end_date,
            });
          },
        },
      ]}
      moreActions={() => [
        // {
        //   title: 'Edit',
        //   onPress: editProject,
        // },
        {
          title: 'View Description',
          onPress: projectDescription,
        },
        // {
        //   title: 'Mark Complete',
        //   confirm: {
        //     title: 'Complete',
        //     message: 'Are you sure you want to mark complete this project?',
        //     confirmText: 'Complete',
        //   },
        //   onPress: markReleased,
        //   visible: () => status === 'active',
        // },
        // {
        //   title: 'Mark Active',
        //   confirm: {
        //     title: 'Active',
        //     message: 'Are you sure you want to mark active this project?',
        //     confirmText: 'Active',
        //   },
        //   onPress: markActive,
        //   visible: () => status === 'completed',
        // },
        // mergeProject({params, navigation}),
        // {
        //   title: 'Delete',
        //   variant: 'error',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to Delete this project?',
        //     confirmText: 'Delete',
        //   },
        //   onPress: markDelete,
        // },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
              projectId: row?._id,
            });
          },
        },
      ]}
    />
  );
};

export const AllProjectsTable = props => {
  const {
    navigation,
    addOnFilter,
    route: {params = {}},
    filtersInfo,
  } = props;
  const {product} = params;
  const {searchValue, filterValues} = filtersInfo;
  const {projectDetail, projectTeam, editProject, activityLogDetail} =
    useProjectActions({
      navigation,
      params,
    });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'Project',
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  const markHold = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'h',
      },
    });
  };

  return (
    <Table
      eventSourceId={['Project', 'Employee', 'Feature']}
      api={`/allProjects`}
      filter={{
        product: product?._id,
        ...filterValues?.filter,
      }}
      addOnFilter={addOnFilter}
      onRowPress={projectDetail}
      searchFields={['project']}
      search={searchValue}
      limit={10000}
      columns={[
        {
          type: 'text',
          field: 'customer.name',
          header: 'Customer',
        },
        {
          type: 'text',
          field: 'project',
          header: 'Project',
          render: ProjectDetailedName,
        },
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: 'Owner',
          width: 200,
        },
        {
          header: 'Team',
          type: 'number',
          field: 'team_count',
          onPress: projectTeam,
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProject,
        },
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) => row.project_status == 'h',
        },
        {
          title: 'Mark Hold',
          confirm: {
            title: 'Mark Hold',
            message: 'Are you sure you want to mark hold this project?',
            confirmText: 'Hold',
          },
          onPress: markHold,
          visible: ({row}) => row.project_status == 'a',
        },
        // mergeProject({params, navigation}),
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
      ]}
    />
  );
};

export const AllProjectTabs = props => {
  const {
    route: {params = {}},
  } = props;
  const {product} = params;
  const filtersInfo = useFilter();
  const {onChangeFilter, searchValue, filterValues} = filtersInfo;
  return (
    <TabView
      tabs={{
        active: {
          label: 'Active',
          api: '/allProjects',
          filter: {
            project_status: 'a',
            product: product?._id,
            ...filterValues?.filter,
          },
          eventSourceId: 'Project',
          view: (
            <AllProjectsTable
              {...props}
              addOnFilter={{project_status: 'a'}}
              filtersInfo={filtersInfo}
            />
          ),
        },
        completed: {
          label: 'Completed',
          eventSourceId: 'Project',
          api: '/allProjects',
          filter: {
            project_status: 'c',
            product: product?._id,
            ...filterValues?.filter,
          },
          view: (
            <AllProjectsTable
              {...props}
              addOnFilter={{project_status: 'c'}}
              filtersInfo={filtersInfo}
            />
          ),
        },
        hold: {
          label: 'On Hold',
          api: '/allProjects',
          eventSourceId: 'Project',
          filter: {
            project_status: 'h',
            product: product?._id,
            ...filterValues?.filter,
          },
          view: (
            <AllProjectsTable
              {...props}
              addOnFilter={{project_status: 'h'}}
              filtersInfo={filtersInfo}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter {...filtersInfo} filters={projectFilters} />,
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: product?._id}}
        />,
      ]}
    />
  );
};
