import React from 'react';
import {Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {RenderSkills} from './BenchResource';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';
import {Text, Row, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {getCurrentDay} from './EmployeeTeamTabs';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const ManagerSkillForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'AllManagers',
    ...props,
  });

  const {manager} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        _id: manager?._id,
        manager_skill_tool: manager?.manager_skill_tool,
      }}
      onSubmit={onSubmit}
      header={'Select Language'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Language',
          label: 'Language',
          field: 'manager_skill_tool',
          type: 'multiAutoComplete',
          api: `/tools`,
          suggestionField: 'name',
        },
      ]}
    />
  );
};

const ManagerSkillRender = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: CountCellRender,
          // responsive: 'sm',
          // align: 'center',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`add-manager-skills`, {
              manager: row,
            });
          },
          visible: ({row}) =>
            !row?.manager_skill_tool || row?.manager_skill_tool == 0,
        },
        {
          field: 'manager_skill_tool',
          labelField: 'name',
          titleField: 'name',
          colorField: 'color',
          render: MultiColorChip,
          maxChips: 1,
          numberOfLines: 1,
          // align: 'right',

          onPress: props => {
            const {row} = props;
            navigation.navigate(`add-manager-skills`, {
              manager: row,
            });
          },
          visible: ({row}) =>
            row?.manager_skill_tool && row?.manager_skill_tool.length,
        },
      ]}
    />
  );
};

const RenderBenchAvailability = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <>
      <Tag value={row?.available_for_opportunity_count} />
      {!isNaN(Number(row?.bench_percentage)) &&
      !Number(row?.bench_percentage) == 0.0 ? (
        Number(row?.bench_percentage) < 10 ? (
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.ERROR_HIGH,
              paddingLeft: 10,
            }}>
            {row?.bench_percentage}
          </Text>
        ) : (
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {row?.bench_percentage}
          </Text>
        )
      ) : null}
    </>
  );
};

const SkillLeaderDashboard = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  let addonFilter = {};
  if (params && params.employee) {
    addonFilter = {
      _id: params.employee.map(item => item._id),
    };
  }
  return (
    <Table
      {...props}
      api={'/employees/skillLeaderashboard'}
      eventSourceId={['AllManagers']}
      search={searchValue}
      addOnFilter={{filter, ...addonFilter}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      renderHeader={() => (
        <TableHeader
          title="Skill Leader"
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  label: 'Practice',
                  field: 'primary_practice',
                  type: 'autoComplete',
                  api: '/practices',
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoComplete',
                  placeholder: 'Select',
                  options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
                  label: 'Experience Range',
                  field: 'experience_range',
                },
              ]}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Employee',
          // width: 250,
          minWidth: 300,
          render: ({row}) => {
            const {name, color, official_email_id} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={name}
                color={color}
                official_email_id={official_email_id}
              />
            );
          },
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Language',
          width: 170,
          render: props => {
            return (
              <ManagerSkillRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
        },

        {
          // render: CountCellRender,
          header: 'Self Billing',
          field: 'self_billed',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {self_billed} = row;
            if (self_billed == 'w/o shadow') {
              return <Tag value={self_billed} color={colors.SUCCESS_MEDIUM} />;
            }
            if (self_billed == 'w/ shadow') {
              return <Tag value={self_billed} color={colors.ACCENT1_MEDIUM} />;
            }
            if (self_billed == 'No') {
              return <Tag value={self_billed} color={colors.ACCENT5_MEDIUM} />;
            }
          },
          type: 'text',
          width: 150,
        },
        {
          header: 'Team',
          children: [
            {
              render: CountCellRender,
              header: 'Size',
              align: 'right',
              field: 'total_count',
              count_field: 'total_count',
              aggregate: true,
              // type: 'number',
              width: 70,
              onPress: ({row}) => {
                const employeeIds = row?.skill_leader_team.map(
                  item => item._id,
                ) || ['xxxx'];
                navigation.navigate(
                  `employee-monthwise-workload-for-project-team`,
                  {
                    employeeFilter: {_id: {$in: employeeIds}},
                    period,
                  },
                );
              },
            },
            {
              render: CountCellRender,
              header: 'Avg Exp',
              align: 'right',
              field: 'avg_experience',
              count_field: 'avg_experience',
              type: 'number',
              width: 100,
            },

            // {
            //   render: CountCellRender,
            //   header: 'Unbilled',
            //   field: 'unbilled_count',
            //   count_field: 'unbilled_count',
            //   aggregate: true,
            //   type: 'number',
            //   width: 70,
            // },
          ],
        },
        {
          header: 'Billing',
          children: [
            {
              render: CountCellRender,
              header: 'Billed',
              field: 'billed_count',
              align: 'right',
              count_field: 'billed_count',
              aggregate: true,
              type: 'number',
              width: 80,
            },
            {
              render: CountCellRender,
              header: 'Unbilled',
              field: 'unbilled_count',
              count_field: 'unbilled_count',
              sub_count_field: 'unbilled_team_percent',
              aggregate: true,
              type: 'number',
              align: 'right',
              width: 80,
            },
            // {
            //   render: CountCellRender,
            //   header: 'Avg Exp',
            //   count_field: 'billable_avg_experience',
            //   type: 'number',
            //   align: 'right',
            //   width: 100,
            // },
            {
              header: 'Billing Effi',
              // width: 70
              // render: CountCellRender,
              // field: 'avg_experience',
              // count_field: 'avg_experience',
              // type: 'number',
              width: 100,
            },
          ],
        },
        // {
        //   header: 'Unbilling',
        //   children: [
        //     {
        //       render: CountCellRender,
        //       header: 'Unbilled',
        //       field: 'unbilled_count',
        //       count_field: 'unbilled_count',
        //       aggregate: true,
        //       type: 'number',
        //       align: 'right',
        //       width: 80,
        //     },
        //     {
        //       render: CountCellRender,
        //       header: 'Avl. For Opp.',
        //       field: 'available_for_opportunity_count',
        //       count_field: 'available_for_opportunity_count',
        //       aggregate: true,
        //       type: 'number',
        //       align: 'right',
        //       width: 120,
        //     },
        //   ],
        // },
        {
          header: 'Interivew Selection',
          children: [
            {
              header: 'Selected',
              width: 100,
              field: 'winnablity',
              type: 'text',
            },
            {
              header: 'Rate(Q3)',
              width: 100,
              field: 'winnablityPercentage',
              type: 'text',
            },
          ],
        },
        // {
        //   render: CountCellRender,
        //   header: 'Open Opp.',
        //   field: 'total_open_opportunities',
        //   count_field: 'total_open_opportunities',
        //   onPress: ({row}) => {
        //     const ids = row.open_opportunities.reduce((acc, currentArray) => {
        //       const ids = currentArray.map(item => item._id);
        //       return acc.concat(ids);
        //     }, []);
        //     navigation.navigate('opportunity-analytics-active-engineering', {
        //       row: ids,
        //     });
        //   },
        //   // aggregate: true,
        //   type: 'number',
        //   align: 'right',
        //   width: 100,
        // },
      ]}
      params={{/* ...params, */ period}}
    />
  );
};

export const DeliveryOwnerWise = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period}}
      api={'/billingStatusDeliveryOwnerWiseReport'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Delivery Owner',
          minWidth: 300,
          render: ({row}) => {
            const {delivery_owner = {}} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={delivery_owner?.name}
                color={delivery_owner?.color}
                values={[
                  {value: delivery_owner?.employee_code},
                  {value: `${row?.experienceInYears} y`},
                  {value: delivery_owner?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Team',
          align: 'right',
          field: 'team_count',
          aggregate: true,
          width: 100,
          onPress: ({row}) => {
            const employeeIds = row?.employeeTeam.map(
              item => item?.employee,
            ) || ['xxxx'];
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: employeeIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          render: props => {
            const {row, styles: {rowText = {}} = {}} = props;
            const newProps = {
              ...props,
              count_field: 'team_count',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <Text
                  style={{
                    ...rowText,
                    color: '#A3A3A3',
                    fontSize: 13,
                    marginTop: 4,
                  }}>
                  {row?.team_avg_experience && `${row?.team_avg_experience} yr`}
                </Text>
              </>
            );
          },
        },
        {
          header: 'Billable',
          render: CountCellRender,
          align: 'right',
          count_field: 'billed_count',
          field: 'billed_count',
          aggregate: true,
          type: 'number',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: row?.billableTeamIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          width: 100,
        },
        {
          render: CountCellRender,
          header: 'Training',
          field: 'trainingEmployeesCount',
          count_field: 'trainingEmployeesCount',
          aggregate: true,
          type: 'number',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: row?.trainingEmployeeIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          width: 100,
        },
        {
          render: CountCellRender,
          header: 'Internal',
          field: 'internalEmployeesCount',
          count_field: 'internalEmployeesCount',
          aggregate: true,
          type: 'number',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: row?.internalEmployeeIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          width: 100,
        },
        {
          render: CountCellRender,
          header: 'Self Module',
          field: 'selfModuleEmployeesCount',
          count_field: 'selfModuleEmployeesCount',
          aggregate: true,
          type: 'number',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: row?.selfModuleEmployeeIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          width: 100,
        },

        {
          render: CountCellRender,
          header: 'Unassigned',
          field: 'unbilled_count',
          count_field: 'unbilled_count',
          aggregate: true,
          type: 'number',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: row?.unBillableTeamIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          width: 100,
        },
        {
          header: 'Billing efficiency',
          field: 'billingEfficiency',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return <Text style={{...rowText}}>{row?.billingEfficiency}%</Text>;
          },
          width: 160,
        },
      ]}
    />
  );
};

export const TechnicalLeadWise = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period}}
      api={'/billingStatusSkillLeaderReport'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Technical Lead',
          minWidth: 300,
          render: ({row}) => {
            const {skill_leader} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={skill_leader?.name}
                color={skill_leader?.color}
                values={[
                  {value: skill_leader?.employee_code},
                  {value: `${row?.experienceInYears} y`},
                  {value: skill_leader?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'DO',
          align: 'center',
          field: 'skill_leader.delivery_owner',
          type: 'userAvatar',
          width: 80,
        },
        {
          header: 'Team',
          align: 'right',
          field: 'team_count',
          aggregate: true,
          width: 100,
          onPress: ({row}) => {
            const employeeIds = row?.employeeTeam.map(
              item => item?.employee,
            ) || ['xxxx'];
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: employeeIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          render: props => {
            const {row, styles: {rowText = {}} = {}} = props;
            const newProps = {
              ...props,
              count_field: 'team_count',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <Text
                  style={{
                    ...rowText,
                    color: '#A3A3A3',
                    fontSize: 13,
                    marginTop: 4,
                  }}>
                  {row?.team_avg_experience && `${row?.team_avg_experience} yr`}
                </Text>
              </>
            );
          },
        },
        {
          header: 'Billing',
          children: [
            {
              header: 'Self Billing',
              field: 'self_billed',
              render: ({row}) => {
                const colors = useTheme('colors');
                const {self_billed} = row;
                if (self_billed == 'w/o shadow') {
                  return (
                    <Tag value={self_billed} color={colors.SUCCESS_MEDIUM} />
                  );
                }
                if (self_billed == 'w/ shadow') {
                  return (
                    <Tag value={self_billed} color={colors.ACCENT1_MEDIUM} />
                  );
                }
                if (self_billed == 'No') {
                  return (
                    <Tag value={self_billed} color={colors.ACCENT5_MEDIUM} />
                  );
                }
              },
              type: 'text',
              width: 150,
            },
            {
              header: 'Billable',
              render: CountCellRender,
              align: 'right',
              count_field: 'billed_count',
              field: 'billed_count',
              aggregate: true,
              type: 'number',
              align: 'right',
              onPress: ({row}) => {
                navigation.navigate(
                  `employee-monthwise-workload-for-project-team`,
                  {
                    employeeFilter: {_id: {$in: row?.billableTeamIds}},
                    fromBenchStatusReport: true,
                    period,
                  },
                );
              },
              width: 100,
            },
          ],
        },
        {
          render: CountCellRender,
          header: 'Unbillable',
          field: 'unbilled_count',
          count_field: 'unbilled_count',
          aggregate: true,
          type: 'number',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(
              `employee-monthwise-workload-for-project-team`,
              {
                employeeFilter: {_id: {$in: row?.unBillableTeamIds}},
                fromBenchStatusReport: true,
                period,
              },
            );
          },
          width: 100,
        },
        {
          header: 'Billing efficiency',
          field: 'billingEfficiency',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return <Text style={{...rowText}}>{row?.billingEfficiency}%</Text>;
          },
          width: 160,
        },
      ]}
    />
  );
};

export const AllocationsStatus = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period}}
      api={'/allocation/billingStatusreports'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.employee_code},
                  {value: `${employee?.experience} y`},
                  {value: employee?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Tech Lead',
          align: 'right',
          field: 'techleadCount',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate(`all-resource-allocations-list`, {
              employeeassignmentFilter: {_id: {$in: row?.techleadIds}},
              period,
              fromBillingStatus: true,
            });
          },
          render: props => {
            const newProps = {
              ...props,
              count_field: 'techleadCount',
            };
            return <CountCellRender {...newProps} />;
          },
        },
        {
          header: 'Anchor Resource',
          align: 'right',
          field: 'anchorresourceCount',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate(`all-resource-allocations-list`, {
              employeeassignmentFilter: {_id: {$in: row?.anchorresourceIds}},
              period,
              fromBillingStatus: true,
            });
          },
          render: props => {
            const newProps = {
              ...props,
              count_field: 'anchorresourceCount',
            };
            return <CountCellRender {...newProps} />;
          },
        },
        {
          header: 'Module Owner',
          align: 'right',
          field: 'projectmoduleleadCount',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate(`all-resource-allocations-list`, {
              employeeassignmentFilter: {_id: {$in: row?.projectmoduleleadIds}},
              period,
              fromBillingStatus: true,
            });
          },
          render: props => {
            const newProps = {
              ...props,
              count_field: 'projectmoduleleadCount',
            };
            return <CountCellRender {...newProps} />;
          },
        },
      ]}
    />
  );
};

export const AnchorBillingStatus = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period, fromAnchorResource: true}}
      api={'/allocation/anchorBillingStatusreports'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.employee_code},
                  {value: `${employee?.experience} y`},
                  {value: employee?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Anchor Resource',
          children: [
            {
              header: 'Allocation',
              align: 'right',
              field: 'anchorresourceCount',
              aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate(
                  `resource-allocations-list-for-billing-status`,
                  {
                    employeeassignmentFilter: {
                      _id: {$in: row?.anchorresourceIds},
                    },
                    period,
                    fromBillingStatus: true,
                  },
                );
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'anchorresourceCount',
                };
                return <CountCellRender {...newProps} />;
              },
            },
            {
              header: 'Delivered Hours',
              align: 'right',
              field: 'anchorResourceDeliveredHours',
              aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('delivery-progress', {
                  deliveryProgressFilter: {
                    _id: {$in: row?.anchorresourceDeliveredIds},
                  },
                });
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'anchorResourceDeliveredHours',
                };
                return <CountCellRender {...newProps} />;
              },
            },
          ],
        },
      ]}
    />
  );
};

export const TechBillingStatus = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period, fromTechLead: true}}
      api={'/allocation/techLeadBillingStatusreports'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.employee_code},
                  {value: `${employee?.experience} y`},
                  {value: employee?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Tech Lead',
          children: [
            {
              header: 'Allocation',
              align: 'right',
              field: 'techleadCount',
              aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate(
                  `resource-allocations-list-for-billing-status`,
                  {
                    employeeassignmentFilter: {_id: {$in: row?.techleadIds}},
                    period,
                    fromBillingStatus: true,
                  },
                );
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'techleadCount',
                };
                return <CountCellRender {...newProps} />;
              },
            },
            {
              header: 'Delivered Hours',
              align: 'right',
              field: 'teachLeadDeliveredHours',
              aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('delivery-progress', {
                  deliveryProgressFilter: {
                    _id: {$in: row?.techLeadDeliveredIds},
                  },
                });
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'teachLeadDeliveredHours',
                };
                return <CountCellRender {...newProps} />;
              },
            },
          ],
        },
      ]}
    />
  );
};

export const DeliveryOwnerBillingStatus = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period, fromDeliveryOwner: true}}
      api={'/allocation/deliveryOwnerBillingStatusreports'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.employee_code},
                  {value: `${employee?.experience} y`},
                  {value: employee?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Delivery Owner',
          children: [
            {
              header: 'Allocation',
              align: 'right',
              field: 'deliveryOwnerCount',
              aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate(
                  `resource-allocations-list-for-billing-status`,
                  {
                    employeeassignmentFilter: {
                      _id: {$in: row?.deliveryOwnerIds},
                    },
                    period,
                    fromBillingStatus: true,
                  },
                );
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'deliveryOwnerCount',
                };
                return <CountCellRender {...newProps} />;
              },
            },
            {
              header: 'Delivered Hours',
              align: 'right',
              field: 'deliveryOnwerDeliveredHours',
              aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('delivery-progress', {
                  deliveryProgressFilter: {
                    _id: {$in: row?.deliveryOwnerDeliveredIds},
                  },
                });
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'deliveryOnwerDeliveredHours',
                };
                return <CountCellRender {...newProps} />;
              },
            },
          ],
        },
      ]}
    />
  );
};

export const SelfBillingStatus = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period}}
      api={'/selfbillingStatusDeliveryOwnerWiseReport'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Delivery Owner',
          minWidth: 300,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                values={[
                  {value: row?.employee_code},
                  {value: `${row?.experienceInYears} y`},
                  {value: row?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Self Billing',
          field: 'self_billed',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {self_billed} = row;
            if (self_billed == 'w/o shadow') {
              return <Tag value={self_billed} color={colors.SUCCESS_MEDIUM} />;
            }
            if (self_billed == 'w/ shadow') {
              return <Tag value={self_billed} color={colors.ACCENT1_MEDIUM} />;
            }
            if (self_billed == 'No') {
              return <Tag value={self_billed} color={colors.ACCENT5_MEDIUM} />;
            }
          },
          type: 'text',
          width: 150,
        },
        {
          header: 'With Shadow Count',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate(
              `resource-allocations-list-for-billing-status`,
              {
                employeeassignmentFilter: {
                  _id: {$in: row?.selfBilledWithShadowIds},
                },
                period,
                fromBillingStatus: true,
              },
            );
          },
          render: props => {
            const newProps = {
              ...props,
              count_field: 'selfBilledWithShadowCount',
            };
            return <CountCellRender {...newProps} />;
          },
        },
        {
          header: 'Without Shadow Count',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate(
              `resource-allocations-list-for-billing-status`,
              {
                employeeassignmentFilter: {
                  _id: {$in: row?.selfBilledWithoutShadowIds},
                },
                period,
                fromBillingStatus: true,
              },
            );
          },
          render: props => {
            const newProps = {
              ...props,
              count_field: 'selfBilledWithoutShadowCount',
            };
            return <CountCellRender {...newProps} />;
          },
        },
        {
          header: 'Allocation Hrs.',
          field: 'selfBilledAllocationHours',
          type: 'number',
          width: 140,
        },
      ]}
    />
  );
};

export const ModuleOwnerBillingStatus = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterParams,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, period}}
      api={'/projectModuleOwnerDeliveryPhases'}
      search={searchValue}
      addOnFilter={{...filterParams}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            const {owner: employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.employee_code},
                  {value: `${employee?.experience} y`},
                  {value: employee?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Module Owner',
          children: [
            {
              header: 'Allocation Hrs.',
              align: 'right',
              field: 'totalWorkload',
              // aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate(
                  `resource-allocations-list-for-billing-status`,
                  {
                    employeeassignmentFilter: {_id: {$in: row?.allocationIds}},
                    period,
                    fromBillingStatus: true,
                  },
                );
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'totalWorkload',
                };
                return <CountCellRender {...newProps} />;
              },
            },
            {
              header: 'Delivered Hrs',
              align: 'right',
              field: 'totalDelivered',
              // aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('delivery-progress', {
                  deliveryProgressFilter: {
                    _id: {$in: row?.deliveredIds},
                  },
                });
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'totalDelivered',
                };
                return <CountCellRender {...newProps} />;
              },
            },
            {
              header: 'Project Delivered',
              align: 'right',
              field: 'totalDelivedHours',
              // aggregate: true,
              width: 150,
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'totalDelivedHours',
                  released_count_field: 'delivedHours',
                };
                return <CountCellRender {...newProps} />;
              },
            },
          ],
        },
      ]}
    />
  );
};

export const InternalBilling = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    period,
  } = props;
  return (
    <Table
      {...props}
      params={{...params, ...period}}
      api={'/internalBilling'}
      addOnFilter={{...filterValues.filter}}
      limit={2000}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            const {
              owner: {employee},
            } = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.employee_code},
                  {value: `${employee?.experience} y`},
                  {value: employee?.official_email_id},
                ]}
              />
            );
          },
        },
        {
          header: 'Module Owner',
          children: [
            {
              header: 'Allocation Hrs.',
              align: 'right',
              field: 'totalWorkload',
              // aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate(`all-resource-allocations-list`, {
                  employeeassignmentFilter: {_id: {$in: row?.allocationIds}},
                  period,
                  fromBillingStatus: true,
                });
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'totalWorkload',
                };
                return <CountCellRender {...newProps} />;
              },
            },
            {
              header: 'Project Delivered',
              align: 'right',
              field: 'totalDelivedHours',
              // aggregate: true,
              width: 150,
              onPress: ({row}) => {
                navigation.navigate(`project-wise-internal-billing`, {
                  feature_id: {$in: row?.featureIds},
                  api: '/projecttask/internalBilling',
                });
              },
              render: props => {
                const newProps = {
                  ...props,
                  count_field: 'totalDelivedHours',
                  released_count_field: 'delivedHours',
                };
                return <CountCellRender {...newProps} />;
              },
            },
          ],
        },
      ]}
    />
  );
};

export const TeamSkillLeaderDashboard = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;

  return (
    <Table
      {...props}
      api={'/employees/skillLeaderashboard'}
      eventSourceId={['AllManagers']}
      search={searchValue}
      addOnFilter={{...filter, _id: employeeId?._id}}
      limit={2000}
      searchFields={['name']}
      variant={'bordered'}
      renderHeader={() => (
        <TableHeader
          title="Skill Leader"
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  label: 'Practice',
                  field: 'primary_practice',
                  type: 'autoComplete',
                  api: '/practices',
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoComplete',
                  placeholder: 'Select',
                  options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
                  label: 'Experience Range',
                  field: 'experience_range',
                },
              ]}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Employee',
          // width: 250,
          minWidth: 300,
          render: ({row}) => {
            const {name, color, official_email_id} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={name}
                color={color}
                official_email_id={official_email_id}
              />
            );
          },
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Language',
          width: 170,
          render: props => {
            return (
              <ManagerSkillRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
        },

        {
          // render: CountCellRender,
          header: 'Self Billing',
          field: 'self_billed',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {self_billed} = row;
            if (self_billed == 'w/o shadow') {
              return <Tag value={self_billed} color={colors.SUCCESS_MEDIUM} />;
            }
            if (self_billed == 'w/ shadow') {
              return <Tag value={self_billed} color={colors.ACCENT1_MEDIUM} />;
            }
            if (self_billed == 'No') {
              return <Tag value={self_billed} color={colors.ACCENT5_MEDIUM} />;
            }
          },
          type: 'text',
          width: 150,
        },
        {
          header: 'Team',
          children: [
            {
              render: CountCellRender,
              header: 'Size',
              align: 'right',
              field: 'total_count',
              count_field: 'total_count',
              aggregate: true,
              // type: 'number',
              width: 70,
              onPress: ({row}) => {
                const employeeIds = row?.skill_leader_team.map(
                  item => item._id,
                ) || ['xxxx'];
                navigation.navigate(`employee-workload`, {
                  employeeFilter: {_id: {$in: employeeIds}},
                });
              },
            },
            {
              render: CountCellRender,
              header: 'Avg Exp',
              align: 'right',
              field: 'avg_experience',
              count_field: 'avg_experience',
              type: 'number',
              width: 100,
            },

            // {
            //   render: CountCellRender,
            //   header: 'Unbilled',
            //   field: 'unbilled_count',
            //   count_field: 'unbilled_count',
            //   aggregate: true,
            //   type: 'number',
            //   width: 70,
            // },
          ],
        },
        {
          header: 'Billing',
          children: [
            {
              render: CountCellRender,
              header: 'Billed',
              field: 'billed_count',
              align: 'right',
              count_field: 'billed_count',
              aggregate: true,
              type: 'number',
              width: 80,
            },
            {
              render: CountCellRender,
              header: 'Unbilled',
              field: 'unbilled_count',
              count_field: 'unbilled_count',
              sub_count_field: 'unbilled_team_percent',
              aggregate: true,
              type: 'number',
              align: 'right',
              width: 80,
            },
            // {
            //   render: CountCellRender,
            //   header: 'Avg Exp',
            //   count_field: 'billable_avg_experience',
            //   type: 'number',
            //   align: 'right',
            //   width: 100,
            // },
            {
              header: 'Billing Effi',
              // width: 70
              // render: CountCellRender,
              // field: 'avg_experience',
              // count_field: 'avg_experience',
              // type: 'number',
              width: 100,
            },
          ],
        },
        // {
        //   header: 'Unbilling',
        //   children: [
        //     {
        //       render: CountCellRender,
        //       header: 'Unbilled',
        //       field: 'unbilled_count',
        //       count_field: 'unbilled_count',
        //       aggregate: true,
        //       type: 'number',
        //       align: 'right',
        //       width: 80,
        //     },
        //     {
        //       render: CountCellRender,
        //       header: 'Avl. For Opp.',
        //       field: 'available_for_opportunity_count',
        //       count_field: 'available_for_opportunity_count',
        //       aggregate: true,
        //       type: 'number',
        //       align: 'right',
        //       width: 120,
        //     },
        //   ],
        // },
        {
          header: 'Interivew Selection',
          children: [
            {
              header: 'Selected',
              width: 100,
              field: 'winnablity',
              type: 'text',
            },
            {
              header: 'Rate(Q3)',
              width: 100,
              field: 'winnablityPercentage',
              type: 'text',
            },
          ],
        },
        // {
        //   render: CountCellRender,
        //   header: 'Open Opp.',
        //   field: 'total_open_opportunities',
        //   count_field: 'total_open_opportunities',
        //   onPress: ({row}) => {
        //     const ids = row.open_opportunities.reduce((acc, currentArray) => {
        //       const ids = currentArray.map(item => item._id);
        //       return acc.concat(ids);
        //     }, []);
        //     navigation.navigate('opportunity-analytics-active-engineering', {
        //       row: ids,
        //     });
        //   },
        //   // aggregate: true,
        //   type: 'number',
        //   align: 'right',
        //   width: 100,
        // },
      ]}
      params={{...params, period}}
    />
  );
};
export default SkillLeaderDashboard;
