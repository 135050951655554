export const useBugActions = ({navigation, params}) => {
  const bugActivityLog = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row?._id,
      api: `/projectbugs/${row?._id}/activities`,
      title: row?.bug ? `Bug : ${row?.bug}` : '',
      displayFields: {owner_id: {label: 'Assignee'}},
    });
  };

  return {
    bugActivityLog,
  };
};
