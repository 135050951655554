import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const WebPageCommunicationForm = props => {
  const {
    route: {params},
    header = 'Add Web Page',
    defaultValue,
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/webpages',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/webpages/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
      }}
      layoutFields={[
        {
          placeholder: 'Topic',
          field: 'page_title',
          type: 'text',
        },
        {
          placeholder: 'URL',
          field: 'page_url',
          type: 'text',
        },
        {
          placeholder: 'Description',
          field: 'page_content',
          type: 'text',
        },
        {
          placeholder: 'Marketing Concept',
          field: 'marketing_concept',
          type: 'autoComplete',
          api: `/marketingconcepts`,
          suggestionField: 'concept_name',
          valueField: 'concept_name',
        },
        {
          field: 'attachments',
          type: 'file',
          // label: 'Upload file',
          placeholder: 'Upload file*',
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditWebPageForm = props => {
  return (
    <WebPageCommunicationForm mode="edit" header={'Edit Webpage'} {...props} />
  );
};
