import React from 'react';
import {
  Col,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {NotificationCardStyle} from './theme';
import {Tag} from '../../../components/chip/Chip';
import {formats} from '@unthinkable/react-table';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {RichText} from '../../../components/form-editors/text/RichTextAreaInput';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {RichTextRenderer} from '../../../components/form-editors/text/RichTextAreaInput';

export const NotificationCard = ({data, onPress, loading}) => {
  const {createdAt, read_on, createdBy, payload} = data;
  const {body, title, info} = payload;
  const {code, type, source, source_id, comment} = info || {};

  const parentSource =
    type === 'Comment' ? source_id?.source : type === 'Task' ? source : void 0;
  const parentSourceId =
    type === 'Comment'
      ? source_id?.source_id
      : type === 'Task'
      ? source_id
      : void 0;

  const {
    notepadEmojiStyle,
    container,
    avatarContainer,
    highText,
    mediumText,
    mediumTextCaption,
    lowTextFootnote,
    hasNotSeenMessageDotStyle,
    hasSeenMessageDotStyle,
    commentContainer,
    indicatorContainerStyle,
  } = useStyles(NotificationCardStyle);

  let bodyEmoji = '📋';

  if (
    type === 'Feature' ||
    (type === 'Comment' && source?.label === 'Feature')
  ) {
    bodyEmoji = '⭐';
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <Row style={container} gap={12}>
        <View style={avatarContainer}>
          {createdBy ? (
            <UserAvatar size={32} value={createdBy} />
          ) : (
            <Text style={notepadEmojiStyle}>📋</Text>
          )}
        </View>
        <Col gap={6} style={{flex: 1, overflow: 'hidden'}}>
          <Row gap={8}>
            <View style={{flex: 1, overflow: 'hidden'}}>
              <Text style={highText}>{title}</Text>
            </View>
            <Text style={lowTextFootnote}>
              {formats.formatDate(createdAt, {fromNow: true})}
            </Text>
          </Row>
          <Text
            title={body}
            numberOfLines={1}
            style={mediumText}>{`${bodyEmoji} ${body}`}</Text>
          {type === 'Feature' ? (
            <Text style={mediumTextCaption}>{code}</Text>
          ) : type === 'Comment' && source?.label === 'Feature' ? (
            <Text style={mediumTextCaption}>{source_id?.code}</Text>
          ) : (
            void 0
          )}
          {parentSource?.label && parentSourceId?.name ? (
            <Tag
              color={parentSource?.color}
              value={`${parentSource?.label}: ${parentSourceId?.name}`}
            />
          ) : (
            void 0
          )}
          {type === 'Comment' && comment ? (
            <View style={commentContainer}>
              <RichTextRenderer value={comment} />
            </View>
          ) : (
            void 0
          )}
        </Col>
        {!read_on ? (
          <View style={hasNotSeenMessageDotStyle} />
        ) : (
          <View style={hasSeenMessageDotStyle} />
        )}
      </Row>
      {loading ? (
        <View style={indicatorContainerStyle}>
          <ActivityIndicator />
        </View>
      ) : null}
    </TouchableOpacity>
  );
};
