import {Text} from '@unthinkable/react-core-components';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {AmountRender} from './components/AmountRender';

const DATE = {
  type: 'date',
  width: 150,
  field: 'invoice_date',
  header: 'Invoice Date',
};

const TYPE = {
  header: 'Type',
  width: 100,
  render: ({row, styles}) => {
    let {invoice_type} = row;
    let Type = '';
    if (invoice_type === 'i') {
      Type = 'ACT-S';
    } else if (invoice_type === 'c') {
      Type = 'CN';
    } else if (invoice_type === 'a') {
      Type = 'ADV-S';
    }
    return <Text style={{...styles.rowText}}>{Type}</Text>;
  },
};

const BILLINGMODEL = {
  header: 'Billing Model',
  width: 150,
  render: ({row, styles}) => {
    let billing_model = '';
    if (row.pricing_type === 'ot') {
      billing_model = 'Fixed';
    } else {
      billing_model = 'T&M';
    }
    return <Text style={{...styles.rowText}}>{billing_model}</Text>;
  },
};

const INVOICE_NUMBER = {
  field: 'invoice_number',
  header: 'Invoice No',
  width: 150,
};

const CUSTOMER = {
  type: 'userAvatarChip',
  field: 'customer',
  header: 'Customer',
  minWidth: 350,
};

const AM = {
  header: 'AM',
  type: 'userAvatar',
  field: 'account_owner_id',
};

const STATUS = {
  field: 'status',
  header: 'Status',
  width: 200,
  render: ({row, styles}) => {
    let {status} = row;
    if (status === 'New') {
      return <Text style={{...styles.rowText}}>Pending</Text>;
    } else if (status === 'Fully Invoiced') {
      return <Text style={{...styles.rowText}}>Paid</Text>;
    } else if (status === 'Partilly Invoiced') {
      return <Text style={{...styles.rowText}}>Partially paid</Text>;
    } else if (status === 'Cancelled') {
      return <Text style={{...styles.rowText}}>Cancelled</Text>;
    }
  },
};

const DUEDATE = {
  header: 'Due on',
  field: 'due_date',
  type: 'date',
};

const AMOUNT = {
  aggregate: true,
  field: 'base_invoice_amount',
  header: 'Amount',
  type: 'currency',
  align: 'right',
  width: 150,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.invoice_amount || 0}
        base_amount={row?.base_invoice_amount || 0}
        currency={row.currency?.currency}
      />
    );
  },
};

const UNPAID_AMOUNT = {
  aggregate: true,
  field: 'pending_receipt_amount',
  header: 'Unpaid Amount',
  type: 'currency',
  width: 150,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.pending_receipt_amount || 0}
        base_amount={row?.base_pending_receipt_amount || 0}
        currency={row.currency?.currency}
      />
    );
  },
};

const activityLogDetailForDraftInvoice = ({row, navigation}) => {
  navigation.navigate(`activity-logs`, {
    _id: row._id,
    populate: [{path: 'account_owner_id', select: {name: 1}}],
    api: `/DraftInvoices/${row?._id}/activityLogs`,
    displayFields: {
      desc: {
        label: 'Description',
      },
    },
  });
};

export const DraftInvoiceAllTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    tab,
    addOnFilter = {},
  } = props;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['draft-invoice', 'revenue-invoice'],
  });
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/draftInvoice/all`}
      search={searchValue}
      addOnFilter={addOnFilter}
      searchFields="invoice_number"
      params={params}
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-draft-invoice`, {
          row,
        });
      }}
      eventSourceId="draft-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        DUEDATE,
        // STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          visible: () => {
            if (tab === 'Pending') return true;
          },
          onPress: ({row}) => {
            navigation.navigate(`editable-draft-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Create Invoice',
          visible: () => {
            if (tab === 'Pending') return true;
          },
          onPress: ({row}) => {
            navigation.navigate(`create-revenue-invoice`, {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete Invoice',
            message: 'Do you want to delete this invoice',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/deleteDraftInvoice`,
              props: row,
            });
          },
          visible: () => tab === 'Pending',
        },
        {
          title: 'Activity Logs',
          onPress: ({row}) =>
            activityLogDetailForDraftInvoice({row, navigation}),
        },
        // {
        //   title: 'Voucher',
        //   onPress: ({row}) => {
        //     navigation.navigate(`open-voucher`, {
        //       invoice: row._id,
        //     });
        //   },
        // },
        // {
        //   title: 'Update invoice submission date',
        //   onPress: ({row}) => {
        //     navigation.navigate(`update-invoice-ubmission-date`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Update Softex Details',
        //   onPress: ({row}) => {
        //     navigation.navigate(`update-softex-details`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Settle Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate(`settle-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Adjust Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate(`adjust-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Cancel',
        //   confirm: {
        //     title: 'Cancel Invoice',
        //     message: 'Do you want to cancel this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/cancel/${row?._id}`,
        //       props: row,
        //     });
        //   },
        // },
        // {
        //   title: 'Print Voucher',
        //   onPress: ({row}) => {
        //     download({
        //       uri: '/voucher/print-voucher',
        //       props: {
        //         invoiceId: row._id,
        //       },
        //     });
        //   },
        // },
        // {
        //   title: 'Download Invoice PDF',
        //   onPress: ({row}) => {
        //     navigation.navigate(`download-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Send Mail',
        //   confirm: {
        //     title: 'Send Mail',
        //     message: 'Do you want to Send Mail for this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/send-mail`,
        //       props: {_selectedid: row._id, callfrom: 'MailInvoice'},
        //     });
        //   },
        // },
        // {
        //   title: 'Sync With GST',
        //   confirm: {
        //     title: 'Sync With GST',
        //     message: 'Do you want to Sync this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/sync-gst`,
        //       props: row,
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const DraftInvoiceAllTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    tab,
    addOnFilter = {},
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['draft-invoice', 'revenue-invoice'],
  });
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/draftInvoice/all`}
      search={searchValue}
      addOnFilter={addOnFilter}
      searchFields="invoice_number"
      params={params}
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-draft-invoice`, {
          row,
        });
      }}
      eventSourceId="draft-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        // STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          visible: () => {
            if (tab === 'Pending') return true;
          },
          onPress: ({row}) => {
            navigation.navigate(`editable-draft-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Create Invoice',
          visible: () => {
            if (tab === 'Pending') return true;
          },
          onPress: ({row}) => {
            navigation.navigate(`create-revenue-invoice`, {row});
          },
        },
        // {
        //   title: 'Voucher',
        //   onPress: ({row}) => {
        //     navigation.navigate(`open-voucher`, {
        //       invoice: row._id,
        //     });
        //   },
        // },
        // {
        //   title: 'Update invoice submission date',
        //   onPress: ({row}) => {
        //     navigation.navigate(`update-invoice-ubmission-date`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Update Softex Details',
        //   onPress: ({row}) => {
        //     navigation.navigate(`update-softex-details`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Settle Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate(`settle-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Adjust Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate(`adjust-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Cancel',
        //   confirm: {
        //     title: 'Cancel Invoice',
        //     message: 'Do you want to cancel this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/cancel/${row?._id}`,
        //       props: row,
        //     });
        //   },
        // },
        // {
        //   title: 'Print Voucher',
        //   onPress: ({row}) => {
        //     download({
        //       uri: '/voucher/print-voucher',
        //       props: {
        //         invoiceId: row._id,
        //       },
        //     });
        //   },
        // },
        // {
        //   title: 'Download Invoice PDF',
        //   onPress: ({row}) => {
        //     navigation.navigate(`download-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Send Mail',
        //   confirm: {
        //     title: 'Send Mail',
        //     message: 'Do you want to Send Mail for this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/send-mail`,
        //       props: {_selectedid: row._id, callfrom: 'MailInvoice'},
        //     });
        //   },
        // },
        // {
        //   title: 'Sync With GST',
        //   confirm: {
        //     title: 'Sync With GST',
        //     message: 'Do you want to Sync this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/sync-gst`,
        //       props: row,
        //     });
        //   },
        // },
        // {
        //   title: 'Activity Logs',
        //   onPress: ({row}) => activityLogDetailForInvoice({row, navigation}),
        // },
      ]}
    />
  );
};
