import React from 'react';
import {
  Form as FormComponent,
  MultiStepForm as MultiStepFormComponent,
} from '@unthinkable/react-form';
import {FormStyles, InlineFormStyles, TableRowInlineFormStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import FieldTypes from './FieldTypes';
import {useAppStateContext} from '../../providers/AppState';
import {useNavigation} from '@react-navigation/native';
import {useToast} from '@unthinkable/react-toast';

export const BaseForm = ({Component = FormComponent, ...props}) => {
  const {fetch} = useAppStateContext();
  const navigation = useNavigation();
  const toast = useToast();

  return (
    <Component
      fieldTypes={FieldTypes}
      fetch={fetch}
      closeByIcon={true}
      editAsIcon={true}
      onClose={() => {
        navigation.goBack();
      }}
      onSubmitError={err => {
        toast({message: err.message, type: 'Error'});
      }}
      onError={err => {
        toast({message: err.message, type: 'Error'});
      }}
      onSubmitSuccess={(message = 'Data Saved.') => {
        message && toast({message});
      }}
      {...props}
    />
  );
};

export const Form = props => {
  const styles = useStyles(FormStyles);
  return <BaseForm {...props} styles={styles} />;
};

export const InlineForm = props => {
  const styles = useStyles(InlineFormStyles);
  return <BaseForm {...props} styles={styles} inline hideInputError />;
};

export const MultiStepForm = props => {
  const styles = useStyles(FormStyles);
  return (
    <BaseForm {...props} styles={styles} Component={MultiStepFormComponent} />
  );
};

export const TableRowInlineForm = props => {
  const styles = useStyles(TableRowInlineFormStyles);
  return <BaseForm {...props} styles={styles} inline hideInputError />;
};
