import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';
import { Tag } from '../../../components/chip/Chip';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import moment from 'moment';
import { useFetchData } from '../../../controllers/useFetchData';
import { compareTwoDatesEquals, compareTwoDatesGreaterEquals } from '../../pmt/utility';

const PackageDetailComputation = (fetch, { to_date: original_to_date, package_review_date : original_package_review_date }) => ({
  // 'change maching to date in earning': {
  //   compute: value => {
  //     const { to_date, attendance_base_components = [] } = value;
  //     console.log("original_to_date<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",original_to_date)
  //     if (to_date && original_to_date) {
  //       console.log("original_to_date<<<<<<<<<<<<<<<<<<",original_to_date)
  //       attendance_base_components.forEach(attendance_base_component => {
  //         console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",moment(attendance_base_component.to_date).isSame(
  //             moment(original_to_date),
  //           ))
  //         if (
  //           moment(attendance_base_component.to_date).isSame(
  //             moment(original_to_date),
  //           )
  //         ) {
  //           console.log("><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
  //           attendance_base_component.to_date = to_date;
  //         }
  //       });
  //     }
  //     console.log(">>>>>>>>>>>>>attendance_base_components<<<<<<<<<<<<",attendance_base_components)
  //     return {
  //       attendance_base_component: [...attendance_base_components],
  //     };
  //   },
  //   multi: true,
  //   dependencies: ['to_date'],
  // },
  'Select Template for data': {
    compute: async value => {
      const { parent } = value;
      const { data: parentData } = await fetch({
        uri: '/employeesalarycomponents',
        props: {
          filter: {
            _id: parent?._id,
          },
          fields: {
            _id: 1,
            is_master_component : 1,
            name : 1,
            package_name: 1,
            employee: 1,
            from_date: 1,
            to_date: 1,
            mark_salary: 1,
            fixed_salary: 1,
            incentive_salary: 1,
            ctc_amount: 1,
            variable_perc: 1,
            working_days: 1,
            is_pf_included: 1,
            ctc_amount_per_year: 1,
            attendance_base_components: {
              _id: 1,
              salary_component_id: {
                name: 1,
                ctc_percentage: 1,
                dependency_field: {name: 1},
                ctc_calculation_type: 1,
              },
              from_date: 1,
              to_date: 1,
              amount: 1,
              remarks: 1,
              ctc_percentage: 1,
              is_not_ctc_included: 1,
            },
            performance_base_components: {
              _id: 1,
              salary_component_id: {
                name: 1,
                ctc_percentage: 1,
                dependency_field: {name: 1},
                ctc_calculation_type: 1,
              },
              from_date: 1,
              to_date: 1,
              remarks: 1,
              link_with: 1,
              amount: 1,
              level_details: {
                _id: 1,
                level: {name: 1},
                amount: 1,
                no_of_resources: 1,
                per_head_amount: 1,
                rate: 1,
                target: 1,
              },
              is_not_ctc_included: 1,
            },
            deductions_components: {
              _id: 1,
              salary_component_id: {
                name: 1,
                ctc_percentage: 1,
                dependency_field: {name: 1},
                ctc_calculation_type: 1,
              },
              from_date: 1,
              to_date: 1,
              remarks: 1,
              amount: 1,
              is_not_ctc_included: 1,
            },
            offer_letter_copy_package : 1,
            fix_base_components: {
              _id: 1,
              salary_component_id: {
                _id: 1,
                name: 1,
                ctc_percentage: 1,
                dependency_field: {name: 1},
                ctc_calculation_type: 1,
              },
              from_date: 1,
              to_date: 1,
              amount: 1,
              remarks: 1,
              is_not_ctc_included: 1,
            },
            package_review_date: 1,
            is_master_component: 1,
            total_deductions_salary: 1, 
          },
          only: true,
        },
      });
      const {
        attendance_base_components = [],
        ctc_amount = undefined,
        deductions_components = [],
        fix_base_components = [],
        performance_base_components = [],
      } = parentData || {};
      return {
        ctc_amount,
        artificialTotalAmount: 0,
        attendance_base_components,
        deductions_components,
        fix_base_components,
        performance_base_components,
      };
    },
    multi: true,
    dependencies: ['parent'],
  },
  'copy parent onchange default': {
    compute: async value => {
      if (value?.parent) {
        const { data: salaryTemplateDetail } = await fetch({
          uri: '/employeesalarycomponents',
          props: {
            filter: {
              _id: parent?._id,
            },
            fields: {
              _id: 1,
              parent: 1,
              from_date: 1,
              fixed_salary: 1,
              incentive_salary: 1,
              mark_salary: 1,
              code: 1,
              to_date: 1,
              code_suggestion: {
                _id: 1,
                code: 1,
              },
              ctc_amount: 1,
              attendance_base_components: {
                _id: 1,
                salary_component_id: { _name: 1 },
                from_date: 1,
                to_date: 1,
                amount: 1,
              },
              performance_base_components: {
                _id: 1,
                salary_component_id: { name: 1 },
                from_date: 1,
                to_date: 1,
                link_to_project: 1,
                sequence: 1,
                amount: 1,
                level_details: {
                  _id: 1,
                  level: { name: 1 },
                  amount: 1,
                  no_of_resources: 1,
                  per_head_amount: 1,
                  rate: 1,
                  target: 1,
                },
              },
              deductions_components: {
                _id: 1,
                salary_component_id: { name: 1 },
                from_date: 1,
                to_date: 1,
                formula: 1,
                sequence: 1,
                amount: 1,
                salary_detailId: 1,
              },
              fix_base_components: {
                _id: 1,
                salary_component_id: { name: 1 },
                from_date: 1,
                to_date: 1,
                formula: 1,
                amount: 1,
                sequence: 1,
              },
              include_in_gross_id: { _id: 1, name: 1 },
              include_in_id: { _id: 1, name: 1 },
            },
            only: true,
          },
        });

        //props: queryProps,
        if (salaryTemplateDetail) {
          delete salaryTemplateDetail._id;
          delete salaryTemplateDetail.parent;

          if (salaryTemplateDetail?.attendance_base_components) {
            for (
              let i = 0;
              i < salaryTemplateDetail.attendance_base_components.length;
              i++
            ) {
              delete salaryTemplateDetail.attendance_base_components[i]._id;
            }
          }
          if (salaryTemplateDetail?.deductions_components) {
            for (
              let i = 0;
              i < salaryTemplateDetail.deductions_components.length;
              i++
            ) {
              delete salaryTemplateDetail.deductions_components[i]._id;
            }
          }
          if (salaryTemplateDetail?.fix_base_components) {
            for (
              let i = 0;
              i < salaryTemplateDetail.fix_base_components.length;
              i++
            ) {
              delete salaryTemplateDetail.fix_base_components[i]._id;
            }
          }
          if (salaryTemplateDetail?.performance_base_components) {
            for (
              let i = 0;
              i < salaryTemplateDetail.performance_base_components.length;
              i++
            ) {
              delete salaryTemplateDetail.performance_base_components[i]._id;
            }
          }
          return { ...salaryTemplateDetail };
        }
      }
    },
    dependencies: ['parent'],
  },
  'copy last deduction component': {
    compute: async value => {
      if (value?.copy_last_deduction_component) {
        const deductionComponents = value?.deductions_components || [];
        const fromDate = value?.from_date;
        const { data: salaryTemplateDetail } = await fetch({
          uri: '/employeesalarycomponents',
          props: {
            filter: {
              to_date: { $lt: fromDate },
              employee : value?.employee
            },
            fields: {
              _id: 1,
              to_date : 1,
              deductions_components_copy: {
                _id: 1,
                salary_component_id: {_id : 1, name : 1},
                from_date: 1,
                to_date: 1,
                amount: 1,
              },
            },
            sort: { to_date: -1 },
            only: true,
          },
        });
        if (salaryTemplateDetail?._id) {
          if (salaryTemplateDetail?.deductions_components_copy?.length) {
            for (const dedComp of salaryTemplateDetail?.deductions_components_copy) {
                const { to_date } = dedComp;
                if (compareTwoDatesGreaterEquals(to_date, fromDate)) {
                  delete dedComp._id;
                  deductionComponents.push(dedComp);
                }
              }
          }
          return { deductions_components : deductionComponents };
        }
      }
    },
    multi : true,
    dependencies: ['copy_last_deduction_component'],
  },
  'copy deduction component': {
    compute: async value => {
      if (value?.copy_deduction_component) {
        const deductionComponentCopy = []
        const deductionComponents = value?.deductions_components || [];
        const salaryComponentId = [
          "53ae738f79df44bac9b0b9da",
          "53ae739d79df44bac9b0b9f7",
          "53ae738879df44bac9b0b9c6",
          "53ae73a779df44bac9b0ba0a",
          "53ae738879df44bac9b0b9c9",
        ]
        for (const dedComp of deductionComponents) {
          const { salary_component_id } = dedComp;
          if (!salaryComponentId.includes(salary_component_id?._id)) {
            delete dedComp._id;
            deductionComponentCopy.push(dedComp);
          }
        }
        return { deductions_components_copy: deductionComponentCopy };
      }
    },
    multi: true,
    dependencies: ['copy_deduction_component'],
  },
  artificialTotalAmount: {
    compute: value => {
      const {
        performance_base_components = [],
        attendance_base_components = [],
        deductions_components = [],
      } = value;
      const response = {};
      let linkStatus = false;
      let totalAmount = 0;
      if (performance_base_components?.length > 0) {
        for (const component of performance_base_components) {
          if (component?.link_to_project) {
            linkStatus = true;
            if (!component?.is_not_ctc_included) {
              totalAmount += component?.amount * 1 || 0;
            }
          }
          if (!component?.linkStatus && !component?.is_not_ctc_included) {
            totalAmount += component?.amount * 1 || 0;
          }
        }
      }
      response['artificialTotalAmount'] =
        ((attendance_base_components?.length &&
          attendance_base_components.reduce((amount, component) => {
            if (!component.is_not_ctc_included) {
              amount =
                amount + ((component.amount && component.amount * 1) || 0);
            }
            return amount;
          }, 0)) ||
          0) +
        totalAmount +
        ((deductions_components?.length &&
          deductions_components.reduce((amount, component) => {
            let total = 0;
            if (
              component?.salary_component_id?._id &&
              (component.salary_component_id._id ===
                '53ae739a79df44bac9b0b9f1' ||
                component.salary_component_id._id ===
                '53ae73a779df44bac9b0ba0a' ||
                component.salary_component_id._id ===
                '53ae738f79df44bac9b0b9da' ||
                component.salary_component_id._id ===
                '6124d7ff9b6ed50d4422690a' ||
                component.salary_component_id._id ===
                '53ae739d79df44bac9b0b9f7' ||
                component.salary_component_id._id ===
                '6295aaa19028f27e7e904a77')
            ) {
              if (!component?.is_not_ctc_included) {
                total = component?.amount * 1 || 0;
              }
            }
            return amount + total;
          }, 0)) ||
          0);
      return { ...response };
    },
    multi: true,
    dependencies: [
      'attendance_base_components',
      'performance_base_components',
      'deductions_components',
      '_set_ctc',
    ],
  },
  fixed_salary: {
    compute: value => {
      const { attendance_base_components, deductions_components } = value || {};
      let response = {};
      response['fixed_salary'] =
        ((attendance_base_components &&
          attendance_base_components.reduce((amount, component) => {
            if (!component.is_not_ctc_included) {
              amount = amount + (component?.amount * 1 || 0);
            }
            return amount;
          }, 0)) ||
          0) +
        ((deductions_components &&
          deductions_components.reduce((amount, component) => {
            let total = 0;
            //Esi employer,gratuity, pf employer ,health insurance
            if (
              component?.salary_component_id?._id &&
              (component.salary_component_id._id ===
                '53ae739a79df44bac9b0b9f1' ||
                component.salary_component_id._id ===
                '53ae73a779df44bac9b0ba0a' ||
                component.salary_component_id._id ===
                '53ae738f79df44bac9b0b9da' ||
                component.salary_component_id._id ===
                '6124d7ff9b6ed50d4422690a' ||
                component.salary_component_id._id ===
                '53ae739d79df44bac9b0b9f7' ||
                component.salary_component_id._id ===
                '6124d7ff9b6ed50d4422690a')
            ) {
              if (!component.is_not_ctc_included) {
                total = (component.amount && component.amount * 1) || 0;
              }
            }
            return amount + total;
          }, 0)) ||
          0);
      return { ...response };
    },
    multi: true,
    dependencies: ['attendance_base_components', 'deductions_components'],
  },
  incentive_salary: {
    compute: value => {
      const response = {};
      response['incentive_salary'] =
        value?.performance_base_components &&
        value.performance_base_components.reduce((amount, component) => {
          if (!component.is_not_ctc_included) {
            amount = amount + ((component.amount && component.amount * 1) || 0);
          }
          return amount;
        }, 0);
      return { ...response };
    },
    multi: true,
    dependencies: ['performance_base_components'],
  },
  'set data of attendance based components': {
    compute: async value => {
      const {
        employee,
        ctc_amount,
        working_days,
        is_pf_included,
        variable_perc,
        from_date,
        to_date,
      } = value;
      if (value?.parent) {
        return;
      }

      if (!value.ctc_amount) {
        return;
      }
      const response = {};
      if (employee) {
        const { data: employeeData } = await fetch({
          uri: '/getAttendanceBasedComponents',
          props: {
            filter: {
              _id: employee,
            },
            fields: {
              organization: 1,
              employee_level: {
                insurance_unthinkable: 1,
                insurance_daffodil: 1,
                books_and_periodicals: 1,
                professional_development: 1,
                telephone_internet: 1,
                lta: 1,
                gift_item: 1,
                sodexho_meal_vouchers: 1,
                medical_reimbersement: 1,
                child_education: 1,
                uniform_allowance: 1,
                hra_perc: 1,
                basic_perc: 1,
                gratuity_perc: 1,
              },
            },
            only: true,
          },
        });
        if (!working_days) {
          return;
        }
        const { employee_level, organization } = employeeData || {};
        const {
          insurance_unthinkable,
          insurance_daffodil,
          books_and_periodicals,
          professional_development,
          telephone_internet,
          lta,
          gift_item,
          sodexho_meal_vouchers,
          medical_reimbersement,
          child_education,
          uniform_allowance,
          hra_perc,
          basic_perc,
          gratuity_perc,
        } = employee_level || {};
        const saturdayWorkingAllowanceFor55Wd = ((ctc_amount * 1) / 22) * 2;
        const saturdayWorkingAllowanceFor6Wd = ((ctc_amount * 1) / 22) * 4;
        let artificialTotalAmount = 0;
        let ctcAmountVariableDeduction =
          ctc_amount -
          ((variable_perc &&
            ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) ||
            0);
        const ctcAmountVariableDeductionForBasic =
          ctc_amount -
          ((variable_perc &&
            ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) ||
            0);
        let noChangeHardComponent = [
          '53ae736b79df44bac9b0b9b6', //Basic
          '53ae738479df44bac9b0b9b9', //HRA
          '5bb72626d79fbb677289b7f2', //Saturday Working Allowance
          '53ae738479df44bac9b0b9bc', //Transport Allowance
          '53ae738f79df44bac9b0b9da', //PF Employer //Deductions
          '53ae73a779df44bac9b0ba0a', //Gratuity //Deductions
          '53ae739d79df44bac9b0b9f7', //Health Insurance //Deductions
          '53ae738879df44bac9b0b9c6', //PF Employer
          '53ae738879df44bac9b0b9c9', //Labour welfare Fund
        ];
        let changeableHardComponent = [
          '55926c8e75d1f19476f9176c', //Uniform Allowance
          '55926ce675d1f19476f91800', //Children Education Allowance
          '53ae73a079df44bac9b0b9fd', //Medical Reimbursement
          '55addc9c99e5d54e393e8420', //Sodexho Meal Voucher
          '55926e6875d1f19476f91cef', //Gift Allowance
          '55926fec75d1f19476f9270c', //leave trave allowance
          '55926d5075d1f19476f91935', //Telephone & Internet Allowance
          '55926d9a75d1f19476f91a10', //Professional Development Allowance
          '55926dd775d1f19476f91b83', //Books & periodicals Allowance
          '54097fe45d20628a1de84d23', //Special Allowance
          '53ae760779df44bac9b0ba10', // Delivery incentive Project
        ];
        const components = {};
        let insuranceAmount = 0;
        let amountToSeperate = 0;
        const variableAmount =
          ((variable_perc && (ctc_amount * variable_perc) / 100) || 0).toFixed(
            0,
          ) * 1;
        if (working_days === 5) {
          ctcAmountVariableDeduction =
            ctc_amount -
            ((variable_perc &&
              ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) ||
              0);
        } else if (working_days === 5.5) {
          ctcAmountVariableDeduction =
            ctc_amount -
            ((variable_perc &&
              ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) ||
              0) -
            saturdayWorkingAllowanceFor55Wd.toFixed(0) * 1;
        } else if (working_days === 6) {
          ctcAmountVariableDeduction =
            ctc_amount -
            ((variable_perc &&
              ((ctc_amount * variable_perc) / 100).toFixed(0) * 1) ||
              0) -
            saturdayWorkingAllowanceFor6Wd.toFixed(0) * 1;
        }
        const { data: salaryComponentData } = await fetch({
          uri: '/salarycomponents',
          props: {
            filter: {
              _id: {
                $in: [...noChangeHardComponent, ...changeableHardComponent],
              },
            },
          },
        });
        if (salaryComponentData?.length > 0) {
          for (const salaryComponent of salaryComponentData) {
            if (salaryComponent?._id) {
              components[salaryComponent._id] = salaryComponent;
            }
          }
        }
        const basicTemporaryAmount =
          (
            (ctcAmountVariableDeductionForBasic * (basic_perc || 0)) /
            100
          ).toFixed(0) * 1;
        const gratuityPerc = gratuity_perc || 0;
        const hraAmount =
          ((basicTemporaryAmount * hra_perc) / 100).toFixed(0) * 1;

        const attendance_base_components = [
          {
            salary_component_id: {
              ...components['53ae736b79df44bac9b0b9b6'],
            },
            ctc_percentage: basic_perc,
            amount: basicTemporaryAmount,
          },
          {
            salary_component_id: {
              ...components['53ae738479df44bac9b0b9b9'],
            },
            ctc_percentage: hra_perc,
            amount: hraAmount,
          },
          {
            salary_component_id: { ...components['53ae738479df44bac9b0b9bc'] },
            amount: 1600,
          },
        ];
        const deductions_components = [
          {
            salary_component_id: { ...components['53ae73a779df44bac9b0ba0a'] },
            amount:
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1,
          },
          {
            salary_component_id: { ...components['53ae738879df44bac9b0b9c9'] },
            amount: 31,
          },
        ];

        const performance_base_components = [];

        if (
          organization == '5a3d06900731bf3c3668c6a5' ||
          organization == '60476b96a4e05f4e4e3e7455'
        ) {
          insuranceAmount = insurance_daffodil || 0;
        } else if (
          organization == '5a3d06b90731bf3c3668c6a6' ||
          organization == '60476bcbcada254e24c91e56'
        ) {
          insuranceAmount = insurance_unthinkable || 0;
        }
        if (is_pf_included) {
          deductions_components.push({
            salary_component_id: { ...components['53ae738f79df44bac9b0b9da'] },
            amount: 1800,
          });
          deductions_components.push({
            salary_component_id: { ...components['53ae738879df44bac9b0b9c6'] },
            amount: 1800,
          });
        }
        if (working_days === 5) {
          artificialTotalAmount =
            1600 +
            (is_pf_included ? 1800 : 0) +
            basicTemporaryAmount * 1 +
            ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
            ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
            insuranceAmount * 1 +
            (variableAmount || 0);
          amountToSeperate =
            ctcAmountVariableDeduction -
            (1600 +
              (is_pf_included ? 1800 : 0) +
              basicTemporaryAmount * 1 +
              ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
              insuranceAmount * 1);
        } else if (working_days === 5.5) {
          amountToSeperate =
            ctcAmountVariableDeduction -
            (1600 +
              (is_pf_included ? 1800 : 0) +
              basicTemporaryAmount * 1 +
              ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
              insuranceAmount * 1);
          attendance_base_components.push({
            salary_component_id: { ...components['5bb72626d79fbb677289b7f2'] },
            amount: saturdayWorkingAllowanceFor55Wd.toFixed(0) * 1,
          });
        } else if (working_days === 6) {
          amountToSeperate =
            ctcAmountVariableDeduction -
            (1600 +
              (is_pf_included ? 1800 : 0) +
              basicTemporaryAmount * 1 +
              ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
              insuranceAmount * 1);
          attendance_base_components.push({
            salary_component_id: { ...components['5bb72626d79fbb677289b7f2'] },
            amount: saturdayWorkingAllowanceFor6Wd.toFixed(0) * 1,
          });
        }
        if (amountToSeperate < 0) {
          amountToSeperate = 0;
        }
        if (insuranceAmount) {
          deductions_components.push({
            salary_component_id: { ...components['53ae739d79df44bac9b0b9f7'] },
            amount: insuranceAmount,
          });
        }
        let handleCheck = ({ componentDetails, componentAmount }) => {
          amountToSeperate = amountToSeperate * 1;
          if (componentAmount && amountToSeperate > componentAmount) {
            amountToSeperate = (amountToSeperate - componentAmount) * 1;
            artificialTotalAmount += componentAmount || 0;
            attendance_base_components.push({
              salary_component_id: { ...componentDetails },
              amount: componentAmount,
            });
          } else {
            if (componentDetails?._id == '54097fe45d20628a1de84d23') {
              artificialTotalAmount +=
                (amountToSeperate && amountToSeperate * 1) || 0;
              attendance_base_components.splice(3, 0, {
                salary_component_id: { ...componentDetails },
                amount: amountToSeperate && amountToSeperate * 1,
              });
            }
          }
        };
        for (let component of changeableHardComponent) {
          if (component == '55926c8e75d1f19476f9176c') {
            handleCheck({
              componentDetails: components['55926c8e75d1f19476f9176c'],
              componentAmount: uniform_allowance || 0,
            });
          } else if (component == '55926ce675d1f19476f91800') {
            handleCheck({
              componentDetails: components['55926ce675d1f19476f91800'],
              componentAmount: child_education || 0,
            });
          } else if (component == '53ae73a079df44bac9b0b9fd') {
            handleCheck({
              componentDetails: components['53ae73a079df44bac9b0b9fd'],
              componentAmount: medical_reimbersement || 0,
            });
          } else if (component == '55addc9c99e5d54e393e8420') {
            handleCheck({
              componentDetails: components['55addc9c99e5d54e393e8420'],
              componentAmount: sodexho_meal_vouchers || 0,
            });
          } else if (component == '55926e6875d1f19476f91cef') {
            handleCheck({
              componentDetails: components['55926e6875d1f19476f91cef'],
              componentAmount: gift_item || 0,
            });
          } else if (component == '55926fec75d1f19476f9270c') {
            handleCheck({
              componentDetails: components['55926fec75d1f19476f9270c'],
              componentAmount: lta || 0,
            });
          } else if (component == '55926d5075d1f19476f91935') {
            handleCheck({
              componentDetails: components['55926d5075d1f19476f91935'],
              componentAmount: telephone_internet || 0,
            });
          } else if (component == '55926d9a75d1f19476f91a10') {
            handleCheck({
              componentDetails: components['55926d9a75d1f19476f91a10'],
              componentAmount: professional_development || 0,
            });
          } else if (component == '55926dd775d1f19476f91b83') {
            handleCheck({
              componentDetails: components['55926dd775d1f19476f91b83'],
              componentAmount: books_and_periodicals || 0,
            });
          } else if (component == '54097fe45d20628a1de84d23') {
            handleCheck({
              componentDetails: components['54097fe45d20628a1de84d23'],
              componentAmount: amountToSeperate || 0,
            });
          } else if (component == '53ae760779df44bac9b0ba10') {
            if (variableAmount) {
              performance_base_components.push({
                salary_component_id: {
                  ...components['53ae760779df44bac9b0ba10'],
                },
                amount: variableAmount,
              });
            }
          }
        }
        if (from_date && to_date) {
          attendance_base_components.map(attendance => {
            attendance.from_date = from_date;
            attendance.to_date = to_date;
          });
          performance_base_components.map(performance => {
            performance.from_date = from_date;
            performance.to_date = to_date;
          });
          deductions_components.map(deduction => {
            deduction.from_date = from_date;
            deduction.to_date = to_date;
          });
        }
        response['acc_automated_component_breakdown'] = true;
        response['attendance_base_components'] = attendance_base_components;
        response['performance_base_components'] = performance_base_components;
        response['deductions_components'] = deductions_components;
      }
      return response;
    },
    multi: true,
    dependencies: ['ctc_amount', 'is_pf_included', 'variable_perc'],
  },
  package_review_date: {
    compute: value => {
      if (!original_to_date && value?.to_date) {
        const package_review_date = new Date(
          new Date(value.to_date).setDate(
            new Date(value.to_date).getDate() + 1,
          ),
        );
        return package_review_date;
      }
      else {
        return original_package_review_date;
      }
    },
    dependencies: ['to_date'],
  },
  offer_letter_copy_package: {
    compute: async value => {
      // console.log('value>>>>>>>>>>>>', value);
      if (value?.from_date && value?.to_date && value?.employee) {
        const { data: employeeData } = await fetch({
          uri: '/getLatestIncrementLetter',
          props: {
            filter: {
              _id: value?.employee,
              increment_letter_latest_from_date: { $lte: value.from_date },
              increment_letter_latest_to_date: { $gte: value.to_date },
            },
            fields: {
              _id: 1,
              increment_letter_latest: 1,
              // increment_letters: {
              //   _id: 1,
              //   from_date: 1,
              //   to_date: 1,
              //   increment_letter: 1,
              // },
            },
            only: true,
          },
        });
        // console.log('employeeData>>>>>>>>>>>>>', JSON.stringify(employeeData));
        if (employeeData?.increment_letter_latest) {
          return employeeData.increment_letter_latest;
        }

        // if (employeeData?.increment_letters?.length > 0) {
        //   employeeData.increment_letters =
        //     employeeData.increment_letters.reverse();
        //   for (let singleIncrementLetter of employeeData.increment_letters) {
        //     if (
        //       singleIncrementLetter.increment_letter &&
        //       singleIncrementLetter.from_date &&
        //       singleIncrementLetter.to_date &&
        //       getZeroTimeDate(singleIncrementLetter.from_date) <=
        //         getZeroTimeDate(value.from_date) &&
        //       getZeroTimeDate(singleIncrementLetter.to_date) >=
        //         getZeroTimeDate(value.from_date)
        //     ) {
        //       return singleIncrementLetter.increment_letter;
        //     } else if (
        //       singleIncrementLetter.increment_letter &&
        //       singleIncrementLetter.from_date &&
        //       singleIncrementLetter.to_date &&
        //       getZeroTimeDate(singleIncrementLetter.from_date) <=
        //         getZeroTimeDate(value.to_date) &&
        //       getZeroTimeDate(singleIncrementLetter.to_date) >=
        //         getZeroTimeDate(value.to_date)
        //     ) {
        //       return singleIncrementLetter.increment_letter;
        //     } else if (
        //       singleIncrementLetter.increment_letter &&
        //       singleIncrementLetter.from_date &&
        //       singleIncrementLetter.to_date &&
        //       getZeroTimeDate(singleIncrementLetter.from_date) >=
        //         getZeroTimeDate(value.from_date) &&
        //       getZeroTimeDate(singleIncrementLetter.to_date) <=
        //         getZeroTimeDate(value.to_date)
        //     ) {
        //       return singleIncrementLetter.increment_letter;
        //     }
        //   }
        // }
      }
    },
    dependencies: ['from_date', 'to_date'],
  },
  nestedComputations: {
    attendance_base_components: {
      'set from and to date': {
        compute: (value, { _parentValues }) => {
          const response = {};
          if (!value.from_date) {
            response['from_date'] = _parentValues.from_date;
          }
          if (!value.to_date) {
            response['to_date'] = _parentValues.to_date;
          }
          if(original_to_date && value.to_date){
            if (
              moment(value.to_date).isSame(
                moment(original_to_date),
              )
            ) {
              response['to_date'] = _parentValues.to_date;
            }
          }
          return response;
        },
        multi: true,
        dependencies: [
          '_id',
          '_parentValues.from_date',
          '_parentValues.to_date',
        ],
      },
    },
    deductions_components: {
      'set from and to date': {
        compute: (value, { _parentValues }) => {
          const response = {};
          if (!value.from_date) {
            response['from_date'] = _parentValues.from_date;
          }
          if (!value.to_date) {
            response['to_date'] = _parentValues.to_date;
          }
          return response;
        },
        multi: true,
        dependencies: [
          '_id',
          '_parentValues.from_date',
          '_parentValues.to_date',
        ],
      },
    },
    fix_base_components: {
      'set from and to date': {
        compute: (value, { _parentValues }) => {
          const response = {};
          if (!value.from_date) {
            response['from_date'] = _parentValues.from_date;
          }
          if (!value.to_date) {
            response['to_date'] = _parentValues.to_date;
          }
          return response;
        },
        multi: true,
        dependencies: [
          '_id',
          '_parentValues.from_date',
          '_parentValues.to_date',
        ],
      },
      'set pay on confirmation': {
        compute: (value) => {
          const response = {};
          if (!value.pay_on_confirmation) {
            response['pay_on_confirmation'] = true;
          }
          return response;
        },
        multi: true,
        dependencies: [
          'amount'
        ],
      },
    },
    performance_base_components: {
      amount: {
        compute: value => {
          if (value.level_details) {
            let amount = value.level_details.reduce(
              (accum, doc) => accum + Number(doc.amount) || 0,
              0,
            );
            return amount;
          }
        },
        dependencies: ['level_details'],
      },
      'set from and to date': {
        compute: (value, { _parentValues }) => {
          const response = {};
          if (!value.from_date) {
            response['from_date'] = _parentValues.from_date;
          }
          if (!value.to_date) {
            response['to_date'] = _parentValues.to_date;
          }
          return response;
        },
        multi: true,
        dependencies: [
          '_id',
          '_parentValues.from_date',
          '_parentValues.to_date',
        ],
      },
      nestedComputations: {
        level_details: {
          per_head_amount: {
            compute: value => {
              if (value.rate && value.target) {
                let amount = value.rate * value.target || 0;
                return amount;
              }
            },
            dependencies: ['target', 'rate'],
          },
          amount: {
            compute: value => {
              if (value.per_head_amount && value.no_of_resources) {
                let amount =
                  Math.floor(value.per_head_amount * value.no_of_resources) ||
                  0;
                return amount;
              }
            },
            dependencies: ['no_of_resources', 'per_head_amount'],
          },
        },
      },
    },
  },
});

export const EmployeeSalaryDetail = props => {
  const { route: { params } = {} } = props;
  const { row } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/EmployeeSalaryComponents',
    eventSourceId: 'employeeSalaryComponent',
  });
  const { fetch, user } = useAppStateContext();
  return (
    <Form
      api={`/employeesalarycomponent/${row?._id}`}
      beforeSubmit={({ data }) => {
        const { artificialTotalAmount = 0, ctc_amount = 0 } = data;
        if (
          artificialTotalAmount < ctc_amount ||
          artificialTotalAmount > ctc_amount
        ) {
          throw new Error(
            'CTC Amount should be same as Total Amount Calculated',
          );
        }
        delete data.artificialTotalAmount;
        return {
          data: {
            ...data,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        employee: row._id,
        from_date: getZeroTimeDate(new Date()),
        working_days: row.working_days,
        variable_perc: row?.employee_level?.variable_perc,
        artificialTotalAmount: row?.ctc_amount,
        mark_salary: true,
        created_by: user._id,
        created_on: new Date(),
      }}
      computations={{ ...PackageDetailComputation(fetch, row) }}
      layoutFields={[
        {
          label: 'Basic Details',
          fields: [
            {
              type: 'text',
              placeholder: 'Name',
              field: 'name',
              label: 'Package structure name',
              // required: true,
              size: 4,
            },
            {
              label: 'Salary Template',
              field: 'parent',
              type: 'autoComplete',
              api: '/employeesalarycomponents',
              suggestionField: 'name',
              valueField: 'name',
              filter: {
                status: "active",
              },
              size: 8,
            },
            {
              field: 'ctc_amount_per_year',
              label: 'CTC(Per Year)',
              type: 'number',
              size: 4,
            },
            {
              field: 'ctc_amount',
              label: 'CTC(Per Month)',
              type: 'number',
              placeholder: 'CTC(Per Month)',
              size: 4,
            },
            {
              label: 'Total Amount Calculated',
              type: 'number',
              field: 'artificialTotalAmount',
              size: 4,
              readOnly: true,
            },
            {
              label: 'From Date',
              type: 'date',
              field: 'from_date',
              size: 4,
            },
            {
              label: 'To Date',
              type: 'date',
              field: 'to_date',
              size: 4,
            },
            {
              label: 'Package Review Date',
              type: 'date',
              field: 'package_review_date',
              size: 4,
            },
            {
              label: 'Is PF Included',
              field: 'is_pf_included',
              type: 'checkbox',
              size: 4,
            },
            {
              label: 'Working Days',
              field: 'working_days',
              type: 'autoComplete',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              options: [
                { value: 5, label: '5' },
                { value: 5.5, label: '5.5' },
                { value: 6, label: '6' },
              ],
              size: 4,
              placeholder: 'Select',
              readOnly: true,
            },
            {
              label: 'Variable %',
              field: 'variable_perc',
              type: 'number',
              size: 4,
            },
            {
              label: 'Incentive',
              type: 'number',
              field: 'incentive_salary',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Fixed',
              type: 'number',
              field: 'fixed_salary',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Get Last Deduction Components',
              field: 'copy_last_deduction_component',
              type: 'checkbox',
              size: 4,
            },
            {
              label: 'Upload Offer Letter Copy',
              type: 'file',
              field: 'offer_letter_copy_package',
              options: { bucketName: 'manaze' },
            },
            {
              type: 'number',
              label: 'Sales Incentive Amount',
              field: 'sales_incentive',
              size: 4,
            },
            {
              field: 'sales_incentive_clause',
              type: 'text',
              label: 'Sales Incentive Clause',
              size: 4,
            },
            // {
            //   label: 'Copy Deduction Components',
            //   field: 'copy_deduction_component',
            //   type: 'checkbox',
            //   size: 4,
            // },
          ],
        },
        {
          label: 'Earning',
          fields: [
            {
              field: 'attendance_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    key={values.to_date}
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 200,
                        align: 'center',
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 200,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 200,
                      },
                      {
                        header: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 250,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 100,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          label: 'Performance',
          fields: [
            {
              field: 'performance_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        field: 'link_with',
                        type: 'autoComplete',
                        suggestionField: 'label',
                        keyField: 'value',
                        valueField: 'label',
                        options: [
                          { value: 'delivery', label: 'Delivery' },
                          { value: 'manual', label: 'Manual' },
                          { value: 'project', label: 'Project' },
                          { value: 'manager', label: 'Manager' },
                        ],
                        size: 6,
                        label: 'Link with',
                      },
                      {
                        type: 'date',
                        label: 'From Date',
                        field: 'from_date',
                        width: 250,
                      },
                      {
                        type: 'date',
                        label: 'To Date',
                        field: 'to_date',
                        width: 250,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 300,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 150,
                      },
                      {
                        field: 'link_with',
                        type: 'text',
                        header: 'Link with',
                        suggestionField: 'label',
                      },
                      {
                        innerNested: true,
                        header: 'Level Detail',
                        label: 'Level Details',
                        fields: [
                          {
                            type: 'text',
                            field: 'level_details',
                            nested: true,
                            addInBottom: true,

                            render: props => {
                              return (
                                <NestedTable
                                  {...props}
                                  columns={[
                                    {
                                      field: 'level.name',
                                      header: 'Level.',
                                      width: 100,
                                    },
                                    {
                                      field: 'no_of_resources',
                                      header: 'No of Resources',
                                      type: 'number',
                                      width: 100,
                                    },
                                    {
                                      field: 'amount',
                                      header: 'Amount',
                                      type: 'number',
                                      width: 80,
                                    },
                                    {
                                      field: 'per_head_amount',
                                      header: 'Per Head Amount',
                                      type: 'number',
                                      width: 150,
                                    },
                                    {
                                      field: 'rate',
                                      header: 'Rate',
                                      type: 'number',
                                      width: 80,
                                    },
                                    {
                                      field: 'target',
                                      header: 'Target',
                                      type: 'number',
                                      width: 80,
                                    },
                                  ]}
                                  fields={[
                                    {
                                      field: 'level',
                                      label: 'Level',
                                      suggestionField: 'name',
                                      valueField: 'name',
                                      api: '/leveltypes',
                                      type: 'autoComplete',
                                    },
                                    {
                                      field: 'no_of_resources',
                                      label: 'No of Resources',
                                      type: 'number',
                                    },
                                    {
                                      field: 'rate',
                                      label: 'Rate',
                                      type: 'number',
                                    },
                                    {
                                      field: 'target',
                                      label: 'Target',
                                      type: 'number',
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                        width: 200,
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 200,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          label: 'Deduction',
          fields: [
            {
              field: 'deductions_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 200,
                        align: 'center',
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 200,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        type: 'date',
                        width: 200,
                      },
                      {
                        header: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 250,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 100,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          label: 'Bonus',
          fields: [
            {
              field: 'fix_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Locking Date',
                        field: 'locking_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        type: 'autoComplete',
                        field: 'pay_on',
                        label: 'Pay On',
                        header: 'Pay On',
                        placeholder: 'Pay On',
                        valueField: 'label',
                        suggestionField: 'label',
                        keyField: 'value',
                        options: [
                          { value: 'onJoining', label: 'On Joining' },
                          { value: 'Yearly', label: 'Yearly' },
                        ],
                        width: 220,
                      },
                      {
                        label: 'Pay On Confirmation',
                        type: 'checkbox',
                        field: 'pay_on_confirmation',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 100,
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        type: 'date',
                        width: 100,
                      },
                      {
                        header: 'Locking Date',
                        field: 'locking_date',
                        type: 'date',
                        width: 150,
                      },
                      {
                        header: 'Pay On',
                        type: 'text',
                        field: 'pay_on',
                        width: 100,
                      },
                      {
                        header: 'Pay On Confirmation',
                        type: 'checkbox',
                        field: 'pay_on_confirmation',
                        width: 100,
                      },
                      {
                        header: 'Remark',
                        type: 'text',
                        field: 'remarks',
                        width: 150,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeSalaryDetail = props => {
  return <EmployeeSalaryDetail header="Add Salary Details" {...props} />;
};

export const EditEmployeeSalaryDetail = props => {
  const { route: { params = {} } = {} } = props;
  const {row, formHr} = params
  const { noneditable } = params;
  let readOnly = false;
  if (noneditable) {
    readOnly = true;
  }
  if(formHr && !row?.mark_salary){
    readOnly = true;
  }
  return (
    <EmployeeSalaryDetail
      mode="edit"
      readOnly={readOnly}
      header={'Edit Salary Details'}
      {...props}
    />
  );
};


export const UploadPackageForm = props => {
  const { route: { params } = {} } = props;
  const { _id } = params;
  let { onSubmit } = useFormSubmit({
    uri: `/employeesalarycomponents`,
    eventSourceId: 'EmployeePackageDetails',
  });
  return (
    <Form
      api={`/employeesalarycomponents/${_id}`}
      fields={
        { offer_letter_copy_package: 1 }
      }
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Upload Offer Letter Copy',
          type: 'file',
          field: 'offer_letter_copy_package',
          options: { bucketName: 'manaze' },
        },
      ]}
      {...props}
    />
  );
};

export const UploadPackage = props => {
  return <UploadPackageForm header="Upload Package" mode="edit" {...props} />;
};


export const ChangePackageReviewForm = props => {
  const { route: { params } = {} } = props;
  const { _id } = params;
  let { onSubmit } = useFormSubmit({
    uri: `/employeesalarycomponents`,
    eventSourceId: 'EmployeePackageDetails',
  });
  return (
    <Form
      api={`/employeesalarycomponents/${_id}`}
      fields={
        { package_review_date: 1 }
      }
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Package Review Date',
          type: 'date',
          field: 'package_review_date',
        },
      ]}
      {...props}
    />
  );
};

export const ChangePackageReview = props => {
  return <ChangePackageReviewForm header="Change Package Review Date" mode="edit" {...props} />;
};


export const RenamePackageForm = props => {
  const { route: { params } = {} } = props;
  const { _id } = params;
  let { onSubmit } = useFormSubmit({
    uri: `/employeesalarycomponents`,
    eventSourceId: 'EmployeePackageDetails',
  });
  return (
    <Form
      api={`/employeesalarycomponents/${_id}`}
      fields={
        { name: 1 }
      }
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Package Name',
          type: 'text',
          field: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const RenamePackage = props => {
  return <RenamePackageForm header="Rename Package" mode="edit" {...props} />;
};


export const UpdateCTCAmount = props => {
  const { route: { params } = {} } = props;
  const { _id } = params;
  let { onSubmit } = useFormSubmit({
    uri: `/employeesalarycomponents`,
    eventSourceId: 'EmployeePackageDetails',
  });
  return (
    <Form
      api={`/employeesalarycomponents/${_id}`}
      fields={
        { ctc_amount: 1, ctc_amount_per_year: 1 }
      }
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'CTC Amount',
          type: 'number',
          field: 'ctc_amount',
        },
        {
          label: 'CTC Amount per year',
          type: 'number',
          field: 'ctc_amount_per_year',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateCTCAmountForm = props => {
  return <UpdateCTCAmount header="Update CTC Amount" mode="edit" {...props} />;
};


export const AddYearlyIncentive = props => {
  const { route: { params } = {} } = props;
  const { component } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/EmployeeSalaryComponents',
    eventSourceId: 'employeeSalaryComponent',
  });
  const { fetch, user } = useAppStateContext();
  return (
    <Form
      api={`/employeesalarycomponent/${component?._id}`}
      beforeSubmit={({ data }) => {
        const { artificialTotalAmount = 0, ctc_amount = 0 } = data;
        if (
          artificialTotalAmount < ctc_amount ||
          artificialTotalAmount > ctc_amount
        ) {
          throw new Error(
            'CTC Amount should be same as Total Amount Calculated',
          );
        }
        delete data.artificialTotalAmount;
        return {
          data: {
            ...data,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      computations={{ ...PackageDetailComputation(fetch, component) }}
      layoutFields={[
        {
          label: 'Basic Details',
          fields: [
            {
              type: 'text',
              placeholder: 'Name',
              field: 'name',
              label: 'Package structure name',
              // required: true,
              size: 4,
              readOnly: true,
            },
            {
              field: 'ctc_amount_per_year',
              label: 'CTC(Per Year)',
              type: 'number',
              size: 4,
              readOnly: true,
            },
            {
              field: 'ctc_amount',
              label: 'CTC(Per Month)',
              type: 'number',
              placeholder: 'CTC(Per Month)',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Total Amount Calculated',
              type: 'number',
              field: 'artificialTotalAmount',
              size: 4,
              readOnly: true,
            },
            {
              label: 'From Date',
              type: 'date',
              field: 'from_date',
              size: 4,
              readOnly: true,
            },
            {
              label: 'To Date',
              type: 'date',
              field: 'to_date',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Package Review Date',
              type: 'date',
              field: 'package_review_date',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Variable %',
              field: 'variable_perc',
              type: 'number',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Incentive',
              type: 'number',
              field: 'incentive_salary',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Fixed',
              type: 'number',
              field: 'fixed_salary',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Working Days',
              field: 'working_days',
              type: 'autoComplete',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              options: [
                { value: 5, label: '5' },
                { value: 5.5, label: '5.5' },
                { value: 6, label: '6' },
              ],
              size: 4,
              placeholder: 'Select',
              readOnly: true,
            },
            {
              type: 'number',
              label: 'Sales Incentive Amount',
              field: 'sales_incentive',
              size: 4,
              readOnly: true,
            },
            {
              field: 'sales_incentive_clause',
              type: 'text',
              label: 'Sales Incentive Clause',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Unapprove Package',
              type: 'checkbox',
              field: 'mark_salary',
              readOnly: true,
              size: 4,
            },
            {
              label: 'Is PF Included',
              field: 'is_pf_included',
              type: 'checkbox',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Upload Offer Letter Copy',
              type: 'file',
              field: 'offer_letter_copy_package',
              options: { bucketName: 'manaze' },
              readOnly: true,
            },
          ],
        },
        {
          label: 'Bonus',
          fields: [
            {
              field: 'fix_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Locking Date',
                        field: 'locking_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        type: 'autoComplete',
                        field: 'pay_on',
                        label: 'Pay On',
                        header: 'Pay On',
                        placeholder: 'Pay On',
                        valueField: 'label',
                        suggestionField: 'label',
                        keyField: 'value',
                        options: [
                          { value: 'onJoining', label: 'On Joining' },
                          { value: 'Yearly', label: 'Yearly' },
                        ],
                        width: 220,
                      },
                      {
                        label: 'Pay On Confirmation',
                        type: 'checkbox',
                        field: 'pay_on_confirmation',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 100,
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        type: 'date',
                        width: 100,
                      },
                      {
                        header: 'Locking Date',
                        field: 'locking_date',
                        type: 'date',
                        width: 150,
                      },
                      {
                        header: 'Pay On',
                        type: 'text',
                        field: 'pay_on',
                        width: 100,
                      },
                      {
                        header: 'Pay On Confirmation',
                        type: 'checkbox',
                        field: 'pay_on_confirmation',
                        width: 100,
                      },
                      {
                        header: 'Remark',
                        type: 'text',
                        field: 'remarks',
                        width: 150,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddYearlyIncentiveForm = props => {
  return <AddYearlyIncentive header="Package Details" mode="edit" {...props} />;
};

export const DuplicateEmployeeSalaryDetailForm = props => {
  const { route: { params } = {} } = props;
  const { component } = params;
  const { data : employeeComponentData } = useFetchData({
    api: `/employeesalarycomponent/${component?._id}`,
  });
  if (!employeeComponentData) {
    return null;
  }
  let { onSubmit } = useFormSubmit({
    uri: '/EmployeeSalaryComponents',
    eventSourceId: 'employeeSalaryComponent',
  });
  const { fetch, user } = useAppStateContext();
  return (
    <Form
      beforeSubmit={({ data }) => {
        const { artificialTotalAmount = 0, ctc_amount = 0 } = data;
        if (
          artificialTotalAmount < ctc_amount ||
          artificialTotalAmount > ctc_amount
        ) {
          throw new Error(
            'CTC Amount should be same as Total Amount Calculated',
          );
        }
        delete data.artificialTotalAmount;
        delete data._id;
        return {
          data: {
            ...data,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        ...employeeComponentData,
        mark_salary: true,
        created_by: user._id,
        created_on: new Date(),
      }}
      computations={{ ...PackageDetailComputation(fetch, component) }}
      layoutFields={[
        {
          label: 'Basic Details',
          fields: [
            {
              type: 'text',
              placeholder: 'Name',
              field: 'name',
              label: 'Package structure name',
              // required: true,
              size: 4,
            },
            {
              field: 'ctc_amount_per_year',
              label: 'CTC(Per Year)',
              type: 'number',
              size: 4,
            },
            {
              field: 'ctc_amount',
              label: 'CTC(Per Month)',
              type: 'number',
              placeholder: 'CTC(Per Month)',
              size: 4,
            },
            {
              label: 'Total Amount Calculated',
              type: 'number',
              field: 'artificialTotalAmount',
              size: 4,
              readOnly: true,
            },
            {
              label: 'From Date',
              type: 'date',
              field: 'from_date',
              size: 4,
            },
            {
              label: 'To Date',
              type: 'date',
              field: 'to_date',
              size: 4,
            },
            {
              label: 'Package Review Date',
              type: 'date',
              field: 'package_review_date',
              size: 4,
            },
            {
              label: 'Variable %',
              field: 'variable_perc',
              type: 'number',
              size: 4,
            },
            {
              label: 'Incentive',
              type: 'number',
              field: 'incentive_salary',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Fixed',
              type: 'number',
              field: 'fixed_salary',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Working Days',
              field: 'working_days',
              type: 'autoComplete',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              options: [
                { value: 5, label: '5' },
                { value: 5.5, label: '5.5' },
                { value: 6, label: '6' },
              ],
              size: 4,
              placeholder: 'Select',
              readOnly: true,
            },
            {
              type: 'number',
              label: 'Sales Incentive Amount',
              field: 'sales_incentive',
              size: 4,
            },
            {
              field: 'sales_incentive_clause',
              type: 'text',
              label: 'Sales Incentive Clause',
              size: 4,
            },
            {
              label: 'Unapprove Package',
              type: 'checkbox',
              field: 'mark_salary',
              readOnly: true,
              size: 4,
            },
            {
              label: 'Is PF Included',
              field: 'is_pf_included',
              type: 'checkbox',
              size: 4,
            },
            {
              label: 'Upload Offer Letter Copy',
              type: 'file',
              field: 'offer_letter_copy_package',
              options: { bucketName: 'manaze' },
            },
          ],
        },
        {
          label: 'Earning',
          fields: [
            {
              field: 'attendance_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    key={values.to_date}
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 200,
                        align: 'center',
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 200,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 200,
                      },
                      {
                        header: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 250,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 100,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          label: 'Performance',
          fields: [
            {
              field: 'performance_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        field: 'link_with',
                        type: 'autoComplete',
                        suggestionField: 'label',
                        keyField: 'value',
                        valueField: 'label',
                        options: [
                          { value: 'delivery', label: 'Delivery' },
                          { value: 'manual', label: 'Manual' },
                          { value: 'project', label: 'Project' },
                          { value: 'manager', label: 'Manager' },
                        ],
                        size: 6,
                        label: 'Link with',
                      },
                      {
                        type: 'date',
                        label: 'From Date',
                        field: 'from_date',
                        width: 250,
                      },
                      {
                        type: 'date',
                        label: 'To Date',
                        field: 'to_date',
                        width: 250,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 300,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 150,
                      },
                      {
                        field: 'link_with',
                        type: 'text',
                        header: 'Link with',
                        suggestionField: 'label',
                      },
                      {
                        innerNested: true,
                        header: 'Level Detail',
                        label: 'Level Details',
                        fields: [
                          {
                            type: 'text',
                            field: 'level_details',
                            nested: true,
                            addInBottom: true,

                            render: props => {
                              return (
                                <NestedTable
                                  {...props}
                                  columns={[
                                    {
                                      field: 'level.name',
                                      header: 'Level.',
                                      width: 100,
                                    },
                                    {
                                      field: 'no_of_resources',
                                      header: 'No of Resources',
                                      type: 'number',
                                      width: 100,
                                    },
                                    {
                                      field: 'amount',
                                      header: 'Amount',
                                      type: 'number',
                                      width: 80,
                                    },
                                    {
                                      field: 'per_head_amount',
                                      header: 'Per Head Amount',
                                      type: 'number',
                                      width: 150,
                                    },
                                    {
                                      field: 'rate',
                                      header: 'Rate',
                                      type: 'number',
                                      width: 80,
                                    },
                                    {
                                      field: 'target',
                                      header: 'Target',
                                      type: 'number',
                                      width: 80,
                                    },
                                  ]}
                                  fields={[
                                    {
                                      field: 'level',
                                      label: 'Level',
                                      suggestionField: 'name',
                                      valueField: 'name',
                                      api: '/leveltypes',
                                      type: 'autoComplete',
                                    },
                                    {
                                      field: 'no_of_resources',
                                      label: 'No of Resources',
                                      type: 'number',
                                    },
                                    {
                                      field: 'rate',
                                      label: 'Rate',
                                      type: 'number',
                                    },
                                    {
                                      field: 'target',
                                      label: 'Target',
                                      type: 'number',
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                        width: 200,
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 200,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          label: 'Deduction',
          fields: [
            {
              field: 'deductions_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 200,
                        align: 'center',
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 200,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        type: 'date',
                        width: 200,
                      },
                      {
                        header: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 250,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 100,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          label: 'Bonus',
          fields: [
            {
              field: 'fix_base_components',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    {...props}
                    fields={[
                      {
                        label: 'Salary Component',
                        field: 'salary_component_id',
                        type: 'autoComplete',
                        api: '/salarycomponents',
                        suggestionField: 'name',
                        valueField: 'name',
                      },
                      {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 220,
                      },
                      {
                        label: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'To Date',
                        field: 'to_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        label: 'Locking Date',
                        field: 'locking_date',
                        type: 'date',
                        width: 220,
                      },
                      {
                        type: 'autoComplete',
                        field: 'pay_on',
                        label: 'Pay On',
                        header: 'Pay On',
                        placeholder: 'Pay On',
                        valueField: 'label',
                        suggestionField: 'label',
                        keyField: 'value',
                        options: [
                          { value: 'onJoining', label: 'On Joining' },
                          { value: 'Yearly', label: 'Yearly' },
                        ],
                        width: 220,
                      },
                      {
                        label: 'Pay On Confirmation',
                        type: 'checkbox',
                        field: 'pay_on_confirmation',
                        width: 220,
                      },
                      {
                        label: 'Remarks',
                        field: 'remarks',
                        type: 'text',
                        width: 350,
                      },
                      {
                        label: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                    columns={[
                      {
                        header: 'Salary Component',
                        field: 'salary_component_id.name',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        width: 100,
                      },
                      {
                        header: 'From Date',
                        field: 'from_date',
                        type: 'date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        width: 150,
                      },
                      {
                        header: 'To Date',
                        field: 'to_date',
                        formatOptions: {
                          format: 'DD MMM YY',
                        },
                        type: 'date',
                        width: 100,
                      },
                      {
                        header: 'Locking Date',
                        field: 'locking_date',
                        type: 'date',
                        width: 150,
                      },
                      {
                        header: 'Pay On',
                        type: 'text',
                        field: 'pay_on',
                        width: 100,
                      },
                      {
                        header: 'Pay On Confirmation',
                        type: 'checkbox',
                        field: 'pay_on_confirmation',
                        width: 100,
                      },
                      {
                        header: 'Remark',
                        type: 'text',
                        field: 'remarks',
                        width: 150,
                      },
                      {
                        header: 'NIC',
                        type: 'checkbox',
                        field: 'is_not_ctc_included',
                        width: 50,
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const DuplicateEmployeeSalaryDetail = props => {
  return <DuplicateEmployeeSalaryDetailForm header="Duplicate Salary Package" {...props} />;
};
