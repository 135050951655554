import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import { SubmitButton } from '@unthinkable/react-form';
import { useStyles } from '@unthinkable/react-theme';
import React from 'react';
import { OutlineButtonStyle } from '../../../components/button/theme';
import { Form } from '../../../components/form/Form';
import { useInvoke } from '../../../controllers/useInvoke';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const AddCommunicationForm = props => {
  const {navigation} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/communications',
    ...props,
    eventSourceId: 'communicationAdded',
  });

  const params = props.route.params;
  const {row} = params;
  const loginUser = useAppStateContext();
  let defaultValues = {
    potential_customer: row?._id,
    campaign_id: row?.campaign_id,
    campaign_type: row?.campaign_type,
    owner: loginUser?.user.employee,
    date: getZeroTimeDate(new Date()),
  };
  if (params?.props?.fromOpportunity) {
    defaultValues = {
      opportunity_id: row?._id,
      owner: loginUser?.user.employee,
      customer_id: row?.customer_id?._id,
      potential_customer: row?.potential_customer?._id,
      campaign_id: row?.campaign_id,
      campaign_type: row?.campaign_type,
      date: getZeroTimeDate(new Date()),
    };
  }
  if (row?.fromCustomer) {
    defaultValues = {
      customer_id: row?._id,
      owner: row?.owner,
      date: getZeroTimeDate(new Date()),
      campaign_id: row?.campaign_id,
      campaign_type: row?.campaign_type,
    };
  }
  const styles = useStyles(OutlineButtonStyle);
  return (
    <Form
      onSubmit={onSubmit}
      header={{
        title: 'Add Communication',
        secondaryTitle: row?.name,
      }}
      footer={{
        actions: [<SubmitButton styles={styles} type="saveAndClose" />],
      }}
      submitAction={{
        label: 'Save and Schedule Task',
        type: 'saveAndNext',
        onNext: ({data}) => {
          navigation.replace(`add-schedule-task`, {data});
        },
      }}
      defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Channel',
          field: 'communication_mode',
          type: 'autoComplete',
          required: true,
          options: [
            'Inbound Call',
            'Outbound Call',
            'Video Conference',
            'In Person Meeting',
            'Inmail',
            'Email',
            'Chat',
          ],
        },
        {
          placeholder: 'To',
          field: 'contact_id',
          label: 'To',
          type: 'autoComplete',
          suggestionField: 'first_name',
          valueField: 'first_name',
          api: '/potentialcustomercontacts',
          filter: ({values}) => {
            return {
              potential_customer: values.potential_customer,
            };
          },
          visible: ({values}) => {
            return (
              values?.communication_mode == 'Inbound Call' ||
              values?.communication_mode == 'Outbound Call'
            );
          },
          size: 6,
        },
        {
          placeholder: 'From',
          field: 'owner',
          label: 'From',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) => {
            return (
              values?.communication_mode == 'Inbound Call' ||
              values?.communication_mode == 'Outbound Call'
            );
          },
          size: 6,
        },
        {
          placeholder: 'Initiator',
          field: 'initiator',
          type: 'autoComplete',
          options: ['Client', 'Us'],
          size: 4,
          visible: ({values}) => {
            return (
              values?.communication_mode == 'Video Conference' ||
              values?.communication_mode == 'In Person Meeting' ||
              values?.communication_mode == 'Inmail' ||
              values?.communication_mode == 'Email'
            );
          },
        },
        {
          placeholder: 'Client Attendees',
          field: 'client_attendees',
          label: 'Client Attendees',
          type: 'multiAutoComplete',
          suggestionField: 'first_name',
          valueField: 'first_name',
          api: '/potentialcustomercontacts',
          filter: ({values}) => {
            return {
              potential_customer: values.potential_customer,
            };
          },
          visible: ({values}) => {
            return (
              values?.communication_mode == 'Video Conference' ||
              values?.communication_mode == 'In Person Meeting' ||
              values?.communication_mode == 'Inmail' ||
              values?.communication_mode == 'Email'
            );
          },
          size: 4,
        },
        {
          placeholder: 'Our Attendees',
          field: 'our_attendes',
          label: 'Our Attendees',
          type: 'multiAutoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) => {
            return (
              values?.communication_mode == 'Video Conference' ||
              values?.communication_mode == 'In Person Meeting' ||
              values?.communication_mode == 'Inmail' ||
              values?.communication_mode == 'Email'
            );
          },
          size: 4,
        },
        {
          placeholder: 'Communication',
          field: 'description',
          type: 'textArea',
          required: true,
        },
        {
          label: 'Sentiment',
          field: 'sentiment',
          type: 'radioGroup',
          required: true,
          options: [
            {label: 'Positive', value: 'positive'},
            {label: 'Neutral', value: 'neutral'},
            {label: 'Negative', value: 'negative'},
            {label: 'No Response', value: 'no response'},
          ],
          searchField: 'label',
          valueField: 'label',
          suggestionField: 'label',
          keyField: 'value',
          // rules: {
          //   required: true,
          // },
          size: 9,
        },
        {
          placeholder: 'Date',
          field: 'date',
          type: 'date',
          required: true,
          size: 3,
        },
        {
          type: 'file',
          field: 'attachments',
          header: 'Upload Document',
        },
        // {
        //   groups: [
        //     {
        //       label: 'Add Time',
        //       fields: [
        //         {
        //           placeholder: 'Time',
        //           field: 'time',
        //           type: 'text',
        //         },
        //       ],
        //     },
        //   ],
        // },
      ]}
    />
  );
};

export const AddScheduleTaskForm = props => {
  const {
    route: {params},
  } = props;
  const {data} = params;
  let defaultValues = {};
  let uri = '';
  const {post, user} = useAppStateContext();
  let filter = {
    owner: data?.owner?._id,
  };

  if (data?.opportunity_id) {
    uri = '/opportunities';
    defaultValues = {
      _id: data?.opportunity_id,
    };
    // filter = {
    //   opportunity_id: {_id: data?.opportunity_id},
    // };
  }

  if (data?.potential_customer && !data?.opportunity_id) {
    uri = '/potentialcustomers';
    defaultValues = {
      _id: data?.potential_customer,
    };
    // filter = {
    //   potential_customer: {_id: data?.potential_customer},
    // };
  }
  let {onSubmit} = useFormSubmit({
    uri: uri,
    ...props,
    eventSourceId: 'communicationAdded',
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'communicationAdded',
  });

  const {fetch} = useAppStateContext();

  // let {onSubmit} = useFormSubmit({
  //   uri: `/communications/${data?._id}`,
  //   ...props,
  //   eventSourceId: 'communicationAdded',
  // });

  // let defaultValues = {
  //   // _id: data?.id,
  //   potential_customer: data?.potential_customer,
  //   opportunity_id: data?.opportunity_id,
  // };

  return (
    <Form
      onSubmit={onSubmit}
      header={'Schedule Task'}
      submitAction={'Save'}
      defaultValues={defaultValues}
      computations={{
        next_task_count: [
          async value => {
            if (value?.next_task_date) {
              const filters = {
                ...filter,
                next_task_date: value?.next_task_date,
              };
              const {data} = await fetch({
                uri: '/potentialcustomers',
                props: {
                  filter: filters,
                },
              });
              console.log('>> data', data, data?.length);
              return data?.length || 0;
            }
          },
          ['next_task_date'],
        ],
      }}
      afterSubmit={props => {
        invoke({
          uri: `/communications/${data?._id}`,
          props: {
            next_task_date: props?.data?.next_task_date,
            next_task: props?.data?.next_task,
          },
        });
      }}
      layoutFields={[
        {
          placeholder: 'Next Task Date',
          field: 'next_task_date',
          type: 'date',
          required: true,
        },
        {
          placeholder: 'Next Task',
          field: 'next_task',
          type: 'textArea',
          required: true,
        },
        {
          placeholder: 'Task Count',
          field: 'next_task_count',
          type: 'number',
          // required: true,
        },
      ]}
    />
  );
};
