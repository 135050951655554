import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab/TabView';

export const ProspectListForMessage = props => {
  const {
    route: {params},
    campaignProspectsfilter,
  } = props;
  return (
    <Table
      {...campaignProspectsfilter}
      fields={{
        potential_customer: {
          name: 1,
          last_communication_date: 1,
          last_communication: 1,
          lead_status: 1,
          industry: {name: 1},
          employee_count: {number: 1},
          country: {name: 1},
          stage: {name: 1},
        },
      }}
      columns={[
        {
          field: 'potential_customer.name',
          header: 'Name',
        },
        {
          field: 'potential_customer.industry.name',
          header: 'Industry',
        },
        {
          field: 'potential_customer.employee_count.number',
          header: 'Employee Count',
        },
        {
          field: 'potential_customer.lead_status',
          header: 'Status',
        },
        {
          field: 'potential_customer.country.name',
          header: 'Country',
        },
        {
          field: 'potential_customer.stage.name',
          header: 'Stage',
        },
        {
          type: 'date',
          field: 'potential_customer.last_communication_date',
          header: 'Communication Date',
        },
        {
          type: 'text',
          field: 'potential_customer.last_communication',
          header: 'Communication',
        },
      ]}
    />
  );
};

export const ProspectListForMessageTab = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignProspectsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      campaign_message: row?._id,
    },
  };

  const tabs = {
    active: {
      label: 'Prospects',
      view: <ProspectListForMessage />,
      ...campaignProspectsfilter,
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      campaignProspectsfilter={campaignProspectsfilter}
    />
  );
};
