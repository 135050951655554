import { Text } from '@unthinkable/react-core-components';
import { getPreviousMonth, useFilter } from '@unthinkable/react-filters';
import {
    GroupFilter,
    PeriodRangeFilter,
} from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

export const MonthlyAttendanceTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  return (
    <Table
      api={'/fixedsalary/all'}
      eventSourceId={'monthlyAttendance'}
      renderHeader={() => (
        <TableHeader
          title="Monthly Attendance"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Employee',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'employee',
                  secondarySuggestionField: 'official_email_id',
                  searchFields: ['name', 'official_email_id', 'employee_code'],
                },
              ]}
            />,
          ]}
        />
      )}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('edit-monthly-attendances', {
          row,
          uri: '/monthlyattendances',
          api: `/fixedsalary/all`,
        });
      }}
      params={{...filterValues.params}}
      addOnFilter={{...filterValues.filter}}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month & year',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Total',
          field: 'total_days_id_month',
          type: 'number',
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'number',
        },
        {
          header: 'LOP',
          field: 'details.un_paid_leaves',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText, color: '#FF3B30'}}>
                {row?.details.un_paid_leaves ||
                  0 + row?.details?.absent_days ||
                  0}
              </Text>
            );
          },
        },
      ]}
    />
  );
};
export const FixedSalarySetupTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  return (
    <Table
      api={'/bonus/all'}
      eventSourceId={'fixedSalary'}
      renderHeader={() => (
        <TableHeader
          title="Fixed Salary"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Employee',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'employee',
                  secondarySuggestionField: 'official_email_id',
                  searchFields: ['name', 'official_email_id', 'employee_code'],
                },
              ]}
            />,
          ]}
        />
      )}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('edit-monthly-attendances', {
          row,
          uri: '/fixedsalaries',
          api: '/bonus/all',
        });
      }}
      params={{...filterValues.params}}
      addOnFilter={{...filterValues.filter}}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month & year',
          width: 150,
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Gross',
          field: 'gross_amount',
          type: 'number',
          width: 150,
        },
        {
          header: 'Payable',
          field: 'payable_amount',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};

export const PerformanceSalarySetupTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  return (
    <Table
      api={'/incentiveSalary/all'}
      eventSourceId={'performanceSalary'}
      renderHeader={() => (
        <TableHeader
          title="Performance Salary"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Employee',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'employee',
                  secondarySuggestionField: 'official_email_id',
                  searchFields: ['name', 'official_email_id', 'employee_code'],
                },
              ]}
            />,
          ]}
        />
      )}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('edit-monthly-attendances', {
          row,
          uri: '/performancesalaries',
          api: '/incentiveSalary/all',
        });
      }}
      params={{...filterValues.params}}
      addOnFilter={{...filterValues.filter}}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month & year',
          width: 150,
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Gross',
          field: 'gross_amount',
          type: 'number',
          width: 150,
        },
        {
          header: 'Payable',
          field: 'payable_amount',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};
