import React from 'react';
import {
  Checkbox as CheckboxComponent,
  CheckboxRenderer as CheckboxRendererComponent,
} from '@unthinkable/react-checkbox';
import {useStyles} from '@unthinkable/react-theme';
import {CheckboxStyles} from './theme';

export const Checkbox = props => {
  const styles = useStyles(CheckboxStyles);
  const {checkedIcon, uncheckedIcon, iconStyle} = styles || {};
  return (
    <CheckboxComponent
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      iconStyle={iconStyle}
      {...props}
    />
  );
};

export const CheckboxRenderer = props => {
  const styles = useStyles(CheckboxStyles);

  return <CheckboxRendererComponent {...props} styles={styles} />;
};
