import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const ProjectJiraCredentialForm = props => {
  const {
    route: {params},
    showBoards = true,
  } = props;

  const {project_id, row, showToken = true} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/projectjiracredentials',
    eventSourceId: 'ProjectJiraCredential',
  });

  const defaultValues = {
    project_id,
  };

  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/projectjiracredentials/${row?._id}`}
      fields={{
        email: 1,
        jira_key: 1,
        project_domain: 1,
        board_type: 1,
        project_type: 1,
        project_board_type: {name: 1},
      }}
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Email',
          type: 'text',
          field: 'email',
          required: true,
          helperText:
            'Enter the email address used to create your Jira account.',
        },
        showToken && {
          label: 'Token',
          type: 'text',
          field: 'token',
          required: true,
          helperText: `Enter your API authentication token for secure access. You can generate this token from your account's security settings.`,
        },
        {
          label: 'Jira Key',
          type: 'text',
          field: 'jira_key',
          required: true,
          helperText:
            "Enter the unique key associated with your Jira project (e.g., 'PROJ').",
        },
        {
          label: 'Project Domain',
          type: 'text',
          field: 'project_domain',
          required: true,
          helperText:
            "Enter the domain URL where your Jira project is hosted (e.g., 'name.atlassian.net').",
        },
        {
          label: 'Board Type',
          type: 'radioGroup',
          field: 'board_type',
          required: true,
          options: [
            {
              label: 'Scrum',
              value: 'scrum',
            },
            {
              label: 'Kanban',
              value: 'kanban',
            },
          ],
          helperText:
            "Select the board type associated with your Jira project (e.g., 'scrum' or 'kanban').",
        },
        {
          label: 'Project Type',
          type: 'radioGroup',
          field: 'project_type',
          required: true,
          options: [
            {
              label: 'Team Managed',
              value: 'team-managed',
            },
            {
              label: 'Company Managed',
              value: 'company-managed',
            },
          ],
          helperText:
            "Select the project type associated with your Jira project (e.g., 'team-managed' or 'company-managed').",
        },
        showBoards && {
          label: 'Project Boards',
          field: 'project_board_type',
          type: 'autoComplete',
          api: `/projectboards`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'project_board_type.name',
          filter: {project_id: row?.project_id},
          size: 'small',
          helperText:
            'Select the project board type associated with your Jira project.',
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectJiraCredentialForm = props => {
  return (
    <ProjectJiraCredentialForm
      {...props}
      showBoards={false}
      header="Add Jira Credential"
    />
  );
};

export const EditProjectJiraCredentialForm = props => {
  return (
    <ProjectJiraCredentialForm
      {...props}
      mode="edit"
      header="Edit Jira Credential"
      showBoards={true}
    />
  );
};
