import { Confirm } from '@unthinkable/react-confirm';
import { Image, Text, View } from '@unthinkable/react-core-components';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { useTheme } from '@unthinkable/react-theme';
import { differenceInBusinessDays } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { TextRenderer } from '../../../components/form/Renderers';
import { useAppStateContext } from '../../../providers/AppState';

const {useInvoke} = require('../../../controllers/useInvoke');

export const TemporarilyClosedForm = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'tickets',
    method: 'put',
  });

  const {ticketDetails, status} = params;

  const onSubmit = data => {
    return invoke({
      uri: `/tickets/${ticketDetails?._id}`,
      props: {
        ...data,
        status,
        temporarily_closed_on: new Date(),
      },
    });
  };

  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      header="Temporarily Closed"
      eventSourceId="tickets"
      layoutFields={[
        {
          type: 'text',
          field: 'temporarily_closed_reason',
          label: 'Reason',
        },
      ]}
      {...props}
    />
  );
};

export const ChangeAgentForm = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'tickets',
    method: 'put',
  });

  const {ticketDetails} = params;

  const onSubmit = data => {
    return invoke({
      uri: `/tickets/${ticketDetails?._id}`,
      props: {
        ...data,
      },
    });
  };

  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/tickets/${ticketDetails?._id}`}
      fields={{
        agent: {
          name: 1,
        },
      }}
      mode="edit"
      header="Assign Agent"
      eventSourceId="tickets"
      layoutFields={[
        {
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          field: 'agent',
          label: 'Agent',
          secondarySuggestionField: 'official_email_id',
        },
      ]}
      {...props}
    />
  );
};

export const ChangeDepartmentForm = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'tickets',
    method: 'put',
  });

  const {ticketDetails} = params;

  const onSubmit = data => {
    return invoke({
      uri: `/tickets/${ticketDetails?._id}`,
      props: {
        ...data,
        agent: null,
      },
    });
  };

  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/tickets/${ticketDetails?._id}`}
      fields={{
        employee_team: {
          name: 1,
        },
      }}
      mode="edit"
      header="Change Team"
      eventSourceId="tickets"
      layoutFields={[
        {
          type: 'autoComplete',
          api: `/employeeTeams`,
          suggestionField: 'name',
          keyField: 'name',
          field: 'employee_team',
          label: 'Team',
          filter: {
            is_ticket_enabled: true,
          },
        },
      ]}
      {...props}
    />
  );
};

export const SetDependencyForm = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'tickets',
    method: 'put',
  });

  const {ticketDetails} = params;

  const onSubmit = data => {
    if (data.dependency_status == 'No Dependency') {
      data.dependency_status_off = new Date();
    } else {
      data.dependency_status_on = new Date();
    }
    return invoke({
      uri: `/tickets/${ticketDetails?._id}`,
      props: {
        ...data,
      },
    });
  };

  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/tickets/${ticketDetails?._id}`}
      fields={{
        dependency_status: 1,
      }}
      mode="edit"
      header="Set Dependency"
      eventSourceId="tickets"
      layoutFields={[
        {
          type: 'autoComplete',
          options: [
            'No Dependency',
            'Awaiting Vendor Reply',
            'User Response Awaited',
            'Under Observation',
            'Manager Approval',
          ],
          field: 'dependency_status',
          label: 'Reason',
        },
      ]}
      {...props}
    />
  );
};

export const ChangeCategoryForm = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'tickets',
    method: 'put',
  });

  const {ticketDetails} = params;

  const onSubmit = data => {
    return invoke({
      uri: `/tickets/${ticketDetails?._id}`,
      props: {
        ...data,
      },
    });
  };

  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/tickets/${ticketDetails?._id}`}
      fields={{
        category: {
          name: 1,
        },
        sub_category: {
          name: 1,
        },
      }}
      mode="edit"
      header="Change Category"
      eventSourceId="tickets"
      layoutFields={[
        {
          type: 'autoComplete',
          api: `/ticketCategories`,
          filter: {
            employee_team: ticketDetails?.employee_team?._id,
          },
          suggestionField: 'name',
          valueField: 'name',
          field: 'category',
          label: 'Category',
          dependentFields: ['sub_category'],
        },
        {
          type: 'autoComplete',
          api: `/ticketSubcategories`,
          filter: ({values}) => {
            return {
              parent_category: values?.category?._id,
            };
          },
          suggestionField: 'name',
          valueField: 'name',
          field: 'sub_category',
          label: 'Subcategory',
        },
      ]}
      {...props}
    />
  );
};

export const RenderTicketChatHeader = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {
    user: {employee = {}},
  } = useAppStateContext();
  const {row, title} = params;
  const invoke = useInvoke({
    eventSourceId: 'tickets',
    method: 'put',
  });

  const onSubmit = data => {
    invoke({
      uri: `/tickets/${row?._id}`,
      props: {
        reopened_on: new Date(),
        reopened: true,
        status: 'Reopen',
      },
    });
    navigation.goBack();
  };
  const colors = useTheme('colors');
  const {EstChange} = useTheme('icons');
  const {CAPTION_SMALL} = useTheme('fonts');
  return (
    <View>
      <Text>{title}</Text>
      <Text style={{fontSize: 10, color: '#808080'}}>
        #{row?.ticket_number} | {row?.raised_by?.employee_code} |
        {row?.raised_by?.official_email_id}
      </Text>
      {(row?.status == 'Closed' || row?.status == 'Temporarily Closed') &&
      row?.raised_by?._id == employee?._id &&
      differenceInBusinessDays(
        new Date(),
        new Date(row?.closed_on || row?.temporarily_closed_on),
      ) <= 2 ? (
        <View
          style={{
            backgroundColor: '#A3A3A311',
            borderRadius: 8,
            flexDirection: 'row',
            padding: 12,
            gap: 8,
          }}>
          <Image
            source={EstChange}
            style={{
              height: 24,
              width: 24,
            }}
          />
          <View
            style={{
              flex: 1,
              gap: 4,
            }}>
            <RowWithSeparator
              style={{
                flexDirection: 'row',
                gap: 8,
                alignItems: 'center',
              }}>
              <TextRenderer value={` Ticket Status `} />
              <TextRenderer
                style={{
                  color: colors.SUCCESS_HIGH,
                }}
                value={row.status}
              />
              <TextRenderer
                value={`${moment(
                  row?.closed_on || row?.temporarily_closed_on,
                ).format('DD MMM YY')} ${moment(
                  row?.closed_on || row?.temporarily_closed_on,
                ).format('hh:mm A')}.`}
              />
            </RowWithSeparator>
            <TextRenderer
              style={{...CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM}}
              value={`If you are not satisfied with the resolution you can reopen it
            within 2 working days.`}
            />
          </View>
          <Confirm
            style={{alignSelf: 'flex-end'}}
            title="Reopen Ticket"
            message="Do you want to Reopen this ticket ?"
            confirmText="Reopen"
            onConfirm={onSubmit}>
            <TextRenderer
              style={{color: colors.INFORMATION_HIGH}}
              value="Reopen Ticket"
            />
          </Confirm>
        </View>
      ) : (
        void 0
      )}
    </View>
  );
};
