import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const CompleteEmployeeTraining = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/employeeTrainings',
    eventSourceId: 'EmployeeTrainings',
  });
  const {
    route: {params},
  } = props;
  const {employee_training} = params;
  return (
    <Form
      header={{
        title: 'Complete Training',
        secondaryTitle: employee_training?.employee?.name || '',
      }}
      onSubmit={onSubmit}
      submitAction="Complete"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          status: 'completed',
        },
      })}
      mode="edit"
      api={'/employeeTrainings/' + employee_training._id}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'deployability',
          label: 'Deployability',
          options: [
            'Deployable',
            'Needs More Training',
            'Ready for OTJ',
            'Ready for Project',
            'Reinstate Training',
            'Not Trainable',
          ],
          required: true,
          size: 'medium',
        },
        {
          type: 'autoComplete',
          field: 'proficiency',
          label: 'Proficiency',
          options: [
            'Expert - A',
            'Intermediate - B',
            'Beginner - C',
            'Novice - D',
          ],
          required: true,
          size: 'medium',
        },
        {
          type: 'textArea',
          field: 'subjective_feedback',
          label: 'Subjective Feedback',
        },
        {
          type: 'date',
          field: 'completed_on',
          label: 'Completed On',
        },
      ]}
    />
  );
};
