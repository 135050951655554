import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

export const AccessGroupTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'accessGroupRemoved',
  });
  const deleteAccessGroup = ({row}) => {
    deleteInvoke({
      uri: `/accessgroups/${row?._id}`,
      props: {},
    });
  };
  return (
    <Table
      eventSourceId={['accessGroupAdded', 'accessGroupRemoved']}
      api={`/accessGroups`}
      fields={{name: 1, parent: {name: 1}}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-access-group`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'parent.name',
          header: 'Parent',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: deleteAccessGroup,
        },
      ]}
    />
  );
};
