import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {TabView} from '../../../components/tab/TabView';
import {Image, Row, Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '../../../components/chip/Chip';
import moment from 'moment';
import {leadControllers} from '../controllers/leadControllers';
import {useFilter} from '../../../controllers/useFilter';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

const ContactCampaignRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'potential_customer.website',
        type: 'link',
      },
      {field: 'contact_id.email', type: 'text'},
    ]}
  />
);

const ContactRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'website',
        type: 'text',
      },
      {field: 'email', type: 'text'},
    ]}
  />
);

const DesignationRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_id?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
          numberOfLines: 1,
        }}>
        {row?.contact_id?.designation}
      </Text>
    </Row>
  );
};

const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  const last_communication_date =
    row?.['potential_customer']?.['last_communication_date'];
  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (last_communication_date &&
          moment(last_communication_date).format('DD MMM, YYYY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.next_task', type: 'text'},
        {
          field: 'potential_customer.next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const NAME = {
  field: 'potential_customer.name',
  header: 'Name',
};

const LINKEDIN = {
  field: 'contact_id.linkedin_url',
  header: 'LinkedIn URL',
  type: 'link',
};

const DESIGNATION = {
  field: 'contact_id.designation',
  header: 'Designation',
};

const RESQUESTED_DATE = {
  type: 'date',
  field: 'connection_request_date',
  header: 'Requested Date',
};

const ACCEPTED_DATE = {
  type: 'date',
  field: 'connection_accept_date',
  header: 'Accepted Date',
};

const MESSAGE_SEND_DATE = {
  type: 'date',
  field: 'message_send_date',
  header: 'Message Send Date',
};

const RESPONSE_DATE = {
  type: 'date',
  field: 'response_date',
  header: 'Message Response Date',
};

const NEXT_REQUEST_DATE = {
  type: 'date',
  field: 'next_request_date',
  header: 'Next Request Date',
};

const LAST_COMMUNICATION = communications => ({
  header: 'Communication',
  render: CommunicationRender,
  onPress: communications,
});

export const CampaignProspectList = props => {
  const {campaignContactsfilter, searchValue} = props;
  const {communicationsForContact} = leadControllers(props);
  return (
    <Table
      eventSourceId="communicationAdded"
      search={searchValue}
      searchFields={['website']}
      {...campaignContactsfilter}
      // fields={{
      //   message_send_date: 1,
      //   connection_request_date: 1,
      //   potential_customer: {
      //     name: 1,
      //     website: 1,
      //     email: 1,
      //     last_communication: 1,
      //     last_communication_date: 1,
      //     next_task: 1,
      //     next_task_date: 1,
      //   },
      //   contact_id: {
      //     designation: 1,
      //     linkedin_url: 1,
      //     email: 1,
      //   },
      //   expected_connection_date: 1,
      //   connection_accept_date: 1,
      // }}
      columns={[
        NAME,
        {
          header: 'Contact',
          render: ({row}) => {
            if (row?.potential_customer?.website) {
              return (
                <PrimaryColumnCard
                  primaryTitle={row?.potential_customer?.website}
                />
              );
            } else if (row?.contact_id?.linkedin_url) {
              return (
                <PrimaryColumnCard
                  primaryTitle={row?.contact_id?.linkedin_url}
                />
              );
            }
          },
        },
        // LINKEDIN,
        // DESIGNATION,
        {
          header: 'Designation',
          render: DesignationRender,
        },
        RESQUESTED_DATE,
        {
          type: 'date',
          field: 'expected_connection_date',
          header: 'Expected Connection Date',
        },
        ACCEPTED_DATE,
        LAST_COMMUNICATION(communicationsForContact),
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};

export const CampaignProspectForMessageSendTable = props => {
  const {
    route: {params},
    campaignContactsfilter,
    filterParams = {},
    searchValue,
  } = props;
  const {communicationsForContact} = leadControllers(props);
  return (
    <Table
      eventSourceId="communicationAdded"
      addOnFilter={filterParams}
      search={searchValue}
      searchFields={['website']}
      {...campaignContactsfilter}
      fields={{
        message_send_date: 1,
        next_request_date: 1,
        potential_customer: {
          name: 1,
          website: 1,
          email: 1,
          last_communication: 1,
          last_communication_date: 1,
          next_task: 1,
          next_task_date: 1,
        },
        contact_id: {
          designation: 1,
          linkedin_url: 1,
          email: 1,
        },
      }}
      columns={[
        NAME,
        {
          header: 'Contact',
          render: ContactCampaignRender,
        },
        // LINKEDIN,
        // DESIGNATION,
        {
          header: 'Designation',
          render: DesignationRender,
        },
        MESSAGE_SEND_DATE,
        NEXT_REQUEST_DATE,
        LAST_COMMUNICATION(communicationsForContact),
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};

export const CampaignProspectForPositiveTable = props => {
  const {
    route: {params},
    campaignContactsfilter,
    searchValue,
  } = props;
  const {communicationsForContact} = leadControllers(props);
  return (
    <Table
      eventSourceId="communicationAdded"
      {...campaignContactsfilter}
      search={searchValue}
      searchFields={['website', 'email', 'name', 'linkedin_url']}
      fields={{
        message_send_date: 1,
        response_date: 1,
        potential_customer: {
          name: 1,
          website: 1,
          email: 1,
          last_communication: 1,
          last_communication_date: 1,
          next_task: 1,
          next_task_date: 1,
        },
        contact_id: {
          designation: 1,
          linkedin_url: 1,
          email: 1,
        },
      }}
      columns={[
        NAME,
        {
          header: 'Contact',
          render: ContactCampaignRender,
        },
        // LINKEDIN,
        // DESIGNATION,
        {
          header: 'Designation',
          render: DesignationRender,
        },
        MESSAGE_SEND_DATE,
        RESPONSE_DATE,
        LAST_COMMUNICATION(communicationsForContact),
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};

export const CampaignProspectListTab = props => {
  const {route: {params} = {}} = props;
  let {filter, row} = params;

  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();
  let {filter: filters = {}} = filterValues || {};
  filter = {...filter, ...filters};

  // const campaignContactsfilter = {
  //   api: '/campaignactions',
  //   filter: {
  //     ...filter,
  //     campaign_id: row?._id,
  //   },
  // };
  const campaignContactsfilter = {
    api: `/campaign/${row?._id}/campaign-actions`,
    filter: filter,
    params,
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectList
          campaignContactsfilter={campaignContactsfilter}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AutoCompleteFilter
          field="potential_customer"
          api="/potentialCustomers"
          searchField="name"
          suggestionField="name"
          placeholder="Select Potential Customer"
          valueField="name"
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CampaignProspectForMessageSendTab = props => {
  const {route: {params} = {}} = props;
  let {filter, row} = params;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  let {filter: filters = {}} = filterValues || {};
  const addOnFilter = {
    ...filter,
    ...filters,
  };
  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...addOnFilter,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectForMessageSendTable
          campaignContactsfilter={campaignContactsfilter}
          filterParams={addOnFilter}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AutoCompleteFilter
          field="potential_customer"
          api="/potentialCustomers"
          searchField="name"
          suggestionField="name"
          placeholder="Select Potential Customer"
          valueField="name"
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CampaignProspectForPositiveTab = props => {
  const {route: {params} = {}} = props;
  let {filter, row} = params;

  const {onChangeFilter, searchValue, filterValues} = useFilter();
  let {filter: filters = {}} = filterValues || {};
  filter = {...filter, ...filters};

  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      campaign_id: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectForPositiveTable
          campaignContactsfilter={campaignContactsfilter}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AutoCompleteFilter
          field="potential_customer"
          api="/potentialCustomers"
          searchField="name"
          suggestionField="name"
          placeholder="Select Potential Customer"
          valueField="name"
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const ContactListForCampaignMessageSendTab = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      campaign_message: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectList campaignContactsfilter={campaignContactsfilter} />
      ),
      ...campaignContactsfilter,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const MessageSentForCampaignMessageTab = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      campaign_message: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectForMessageSendTable
          campaignContactsfilter={campaignContactsfilter}
        />
      ),
      ...campaignContactsfilter,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const PositiveForCampaignMessageTab = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      campaign_message: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectForPositiveTable
          campaignContactsfilter={campaignContactsfilter}
        />
      ),
      ...campaignContactsfilter,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CampaignProspectListTabForOutreachBatch = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;
  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      batch_id: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectList campaignContactsfilter={campaignContactsfilter} />
      ),
      ...campaignContactsfilter,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const MessageSendTabForOutreachBatch = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;
  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      batch_id: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectForMessageSendTable
          campaignContactsfilter={campaignContactsfilter}
        />
      ),
      ...campaignContactsfilter,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const PositiveTabForOutreachBatch = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignContactsfilter = {
    api: '/campaignactions',
    filter: {
      ...filter,
      batch_id: row?._id,
    },
  };
  const tabs = {
    active: {
      label: 'Contacts',
      view: (
        <CampaignProspectForPositiveTable
          campaignContactsfilter={campaignContactsfilter}
        />
      ),
      ...campaignContactsfilter,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
