import React from 'react';
import {Row, Wrap} from '@unthinkable/react-core-components';
import {DotSeparator} from './DotSeparator';

export const RowWithSeparator = ({
  wrap = false,
  children,
  separator = <DotSeparator />,
  ...props
}) => {
  let childrenArray = React.Children.toArray(children);
  childrenArray = childrenArray.filter(child => child);
  childrenArray = childrenArray.reduce((acc, child, index) => {
    acc.push(child);
    if (index !== childrenArray.length - 1) {
      acc.push(separator);
    }
    return acc;
  }, []);
  const Component = wrap ? Wrap : Row;
  return <Component {...props}>{childrenArray}</Component>;
};
