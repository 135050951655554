import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  SearchFilter as SearchFilterComponent,
  DateFilter as DateFilterComponent,
  GroupFilter as GroupFilterComponent,
  useFieldFilter,
} from '@unthinkable/react-filters';
import {
  AutoCompleteFilterStyles,
  DateFilterStyles,
  PeriodRangeFilterStyles,
  GroupFilterStyles,
  MultiAutoCompleteFilterStyles,
  SearchFilterStyles,
} from './theme';
import {
  AutoComplete,
  MultiAutoComplete,
} from '../form-editors/autocomplete/AutoComplete';
import {View} from '@unthinkable/react-core-components';
import {PeriodRangeSelector} from '@unthinkable/react-date-picker';
import {Badge} from '../badge/Badge';
import {IconButton} from '../button/IconButton';

export const SearchFilter = ({onChangeFilter, ...props}) => {
  const styles = useStyles(SearchFilterStyles);
  const onChangeValue = _value => {
    onChangeFilter && onChangeFilter({type: 'search', value: _value});
  };
  return (
    <SearchFilterComponent
      {...props}
      styles={styles}
      onChangeValue={onChangeValue}
    />
  );
};

export const DateFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  dependentFilterFields,
  ...props
}) => {
  const styles = useStyles(DateFilterStyles);
  const {value, onChangeValue} = useFieldFilter({
    type: 'date',
    field,
    onChangeFilter,
    asParam,
    filterValues,
    dependentFilterFields,
  });
  return (
    <DateFilterComponent
      {...props}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

const PeriodRangeFilterComponent = ({
  onChangeFilter,
  filterValues,
  field = 'period',
  asParam = true,
  dependentFilterFields,
  options = [
    {
      label: 'Last 30 days',
      value: 'last30Days',
    },
    {
      label: 'Last 7 days',
      value: 'last7Days',
    },
  ],
  ...props
}) => {
  const styles = useStyles(PeriodRangeFilterStyles);
  const {value, onChangeValue} = useFieldFilter({
    type: 'date',
    field,
    onChangeFilter,
    asParam,
    filterValues,
    dependentFilterFields,
  });

  return (
    <PeriodRangeSelector
      {...props}
      value={value}
      options={options}
      onChangeValue={onChangeValue}
      styles={styles}
    />
  );
};

export const PeriodRangeFilter = props => {
  return (
    <PeriodRangeFilterComponent
      options={[
        {label: 'Day', value: 'day'},
        {label: 'Week', value: 'week'},
        {
          label: 'Last 30 days',
          value: 'last30Days',
        },
        {
          label: 'Last 7 days',
          value: 'last7Days',
        },
      ]}
      {...props}
    />
  );
};

export const PeriodRangeMonthWiseBreakupFilter = props => {
  return (
    <PeriodRangeFilterComponent
      options={[
        {
          label: 'Last 30 days',
          value: 'last30Days',
        },
        {
          label: 'Last 7 days',
          value: 'last7Days',
        },
      ]}
      monthWiseBreakup
      {...props}
    />
  );
};

export const BooleanFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  dependentFilterFields,
  options = [
    {label: 'True', value: true},
    {label: 'False', value: false},
  ],
  ...props
}) => {
  const styles = useStyles(AutoCompleteFilterStyles);
  const {value, onChangeValue} = useFieldFilter({
    asParam,
    field,
    onChangeFilter,
    filterValues,
    dependentFilterFields,
    type: 'boolean',
  });
  return (
    <AutoComplete
      keyField="value"
      suggestionField="label"
      placeholder="Select"
      {...props}
      options={options}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

export const AutoCompleteFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  params,
  dependentFilterFields,
  ...props
}) => {
  const styles = useStyles(AutoCompleteFilterStyles);
  if (typeof params === 'function') {
    params = params(filterValues);
  }
  const {value, onChangeValue} = useFieldFilter({
    asParam,
    field,
    onChangeFilter,
    filterValues,
    dependentFilterFields,
    type: 'autoComplete',
  });
  return (
    <AutoComplete
      {...props}
      params={params}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

export const MultiAutoCompleteFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  params,
  dependentFilterFields,
  ...props
}) => {
  const styles = useStyles(MultiAutoCompleteFilterStyles);
  if (typeof params === 'function') {
    params = params(filterValues);
  }
  const {value, onChangeValue} = useFieldFilter({
    asParam,
    field,
    onChangeFilter,
    filterValues,
    dependentFilterFields,
    type: 'multiAutoComplete',
  });
  return (
    <MultiAutoComplete
      {...props}
      params={params}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

const GroupPeriodFilter = ({
  field = 'period',
  asParam = true,
  options = [
    {label: 'Month', value: 'Month'},
    {label: 'Week', value: 'Week'},
  ],
  ...props
}) => {
  return (
    <DateFilter
      direction="column"
      {...props}
      options={options}
      field={field}
      asParam={asParam}
    />
  );
};

const GroupDateFilter = props => {
  return <DateFilter {...props} direction="column" />;
};
export const SpanFilter = ({
  field = 'span',
  placeholder = 'Select Span',
  asParam = true,
  options = ['Weekly', 'Monthly'],
  ...props
}) => {
  return (
    <View style={{width: 122}}>
      <AutoCompleteFilter
        field={field}
        placeholder={placeholder}
        options={options}
        asParam
        allowClear={false}
        {...props}
      />
    </View>
  );
};

const filterTypes = {
  autoComplete: AutoCompleteFilter,
  date: GroupDateFilter,
  period: GroupPeriodFilter,
  multiAutoComplete: MultiAutoCompleteFilter,
  boolean: BooleanFilter,
};

export const GroupFilter = ({isLast, title = 'Filters', ...props}) => {
  let styles = useStyles(GroupFilterStyles);
  let filterComponent = (
    <GroupFilterComponent
      styles={styles}
      filterTypes={filterTypes}
      title={title}
      {...props}>
      {({count}) => {
        return (
          <Badge top={0} right={-3} value={count || null}>
            <IconButton title={title} icon={styles?.filterIcon} />
          </Badge>
        );
      }}
    </GroupFilterComponent>
  );
  if (isLast) {
    filterComponent = <View style={{paddingRight: 12}}>{filterComponent}</View>;
  }
  return filterComponent;
};
