import React from 'react';
import {Table} from '../../../components/table/Table';

const NAME = {
  field: 'name',
  header: 'Name',
  responsive: 'sm',
};

const JOBTITLE = {
  field: 'job_title',
  header: 'Job Title',
};

const PAINPOINT = {
  field: 'pain_points',
  header: 'Pain Points',
};

const OWNER = {
  field: 'assigned_to.name',
  header: 'Owner',
};

const STATUS = {
  field: 'status',
  header: 'Status',
};
export const BuyerPersonaActiveTable = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const {communicationFilter} = params;
  return (
    <Table
      search={searchValue}
      searchFields={'name'}
      api={`/buyer-personas`}
      addOnFilter={{
        ...communicationFilter,
      }}
      params={params}
      onRowPress={row => {
        navigation.navigate('edit-buyer-persona', row);
      }}
      columns={[
        NAME,
        JOBTITLE,
        PAINPOINT,
        OWNER,
        STATUS,
      ]}
    />
  );
};
