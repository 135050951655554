import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import AllProducts from '../../../modules/department/views/ProductOutput';
import {useAppStateContext} from '../../../providers/AppState';
import {NonEnggTeams} from '../../employee/views/NonEnggTeams';
import {JobOpeningTab} from '../../recruitment/views/JobOpeningsTab';
import {
  ActiveCandidateTabs,
  AllCandidateTabs,
  ClosedCandidateTabs,
} from '../../recruitment/views/AllCandidateTabs';
import {HrRecruitmentTab} from '../../recruitment/views/HrRecruitmentTab';
import {StrategicTaskTabs} from '../../task/views/StrategicTaskTabs';
import {ClosedCandidate} from '../../recruitment/views/CandidateTable';
import {ReferralTabs} from '../../recruitment/views/RefferalTabs';

const OrganizationGroup = createMenuNavigator();
const OrganizationGroupNavigator = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {user} = useAppStateContext();
  return (
    <OrganizationGroup.Navigator data-navigator-id="OrganizationGroup">
      {/* <OrganizationGroup.Screen
        data-resource-id="Organization"
        component={OrganizationDashboard}
        options={{
          label: 'Organization',
        }}
      /> */}
      <OrganizationGroup.Screen
        data-resource-id="Product"
        component={AllProducts}
        options={{
          label: 'Products',
        }}
      />
      <OrganizationGroup.Screen
        data-resource-id="Employee"
        options={{
          label: 'Employees',
          api: '/employees/active',
          limit: 5000,
          onPress: () => {
            navigation.navigate('employee-new-menu', {
              ...params,
            });
          },
        }}
      />
      {/* <OrganizationGroup.Screen
        data-resource-id="Employee"
        options={{
          label: 'Employees old',
          api: '/employees/active',
          limit: 5000,
          onPress: () => {
            navigation.navigate('employee-menu', {
              ...params,
            });
          },
        }}
      /> */}
      <OrganizationGroup.Screen
        data-resource-id="FunctionalTeam"
        name="Functional Team"
        component={NonEnggTeams}
        props={{user: user, departmentTitle: 'Functional Team', ...props}}
        options={{
          label: 'Functions',
          api: '/employees/nonprojectteams',
          limit: 5000,
        }}
      />
      <OrganizationGroup.Screen
        data-resource-id="StrategicTask"
        name="Strategic Tasks"
        component={StrategicTaskTabs}
        props={{...props, skipAdd: true}}
        options={{
          label: 'Strategic Tasks',
        }}
      />
      <OrganizationGroup.Screen
        data-resource-id="Customer"
        options={{
          label: 'Customers',
          onPress: () => {
            props.navigation.navigate('customer-dashboard', {
              ...params,
            });
          },
        }}
      />
      <OrganizationGroup.Screen
        data-resource-id="Vendor"
        options={{
          label: 'Vendors',
          onPress: () => {
            props.navigation.navigate('vendor-dashboard', {
              ...params,
            });
          },
        }}
      />
      <OrganizationGroup.Screen
        data-resource-id="ITAsset"
        options={{
          label: 'IT Asset',
          onPress: () => {
            navigation.navigate('it-asset-menu', {
              params: {
                ...params,
              },
            });
          },
        }}
      />
      <OrganizationGroup.Group>
        <OrganizationGroup.Screen
          data-resource-id="SkillMatrixAdmin"
          options={{
            label: 'Skill & Courses',
            onPress: () => {
              props.navigation.navigate('skills-and-training-hr', {
                ...props?.route?.params,
              });
            },
          }}
        />
        <OrganizationGroup.Screen
          data-resource-id="Finance"
          options={{
            label: 'Finance',
            onPress: () => {
              props.navigation.navigate('finance-dashboard', {
                ...params,
              });
            },
          }}
        />
      </OrganizationGroup.Group>
      {/* <OrganizationGroup.Screen
        data-resource-id="SkillMatrixAdmin"
        options={{
          label: 'Skill & Courses-Admin',
          onPress: () => {
            navigation.navigate('skills-and-training-hr', {
              ...params,
            });
          },
        }}
      /> */}
      {/* <OrganizationGroup.Screen
        data-resource-id="SkillMatrixOperations"
        options={{
          label: 'Skill & Courses-Ops',
          onPress: () => {
            navigation.navigate('skills-and-training-skill-leader', {
              params: {
                ...params,
              },
            });
          },
        }}
      /> */}
      {/* <OrganizationGroup.Group>
        <OrganizationGroup.Screen
          data-resource-id="Setup"
          options={{
            label: 'Admin Setup',
            onPress: () => {
              navigation.navigate('setup', {
                ...params,
              });
            },
          }}
        />
        <OrganizationGroup.Screen
          data-resource-id="MasterSetup"
          options={{
            label: 'Technical Setup',
            onPress: () => {
              navigation.navigate('company-setup', {
                ...params,
              });
            },
          }}
        />
      </OrganizationGroup.Group> */}
    </OrganizationGroup.Navigator>
  );
};

export default OrganizationGroupNavigator;
