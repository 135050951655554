import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {EmployeeTabs} from './EmployeeList';
import {NonEnggTeams} from './NonEnggTeams';
import {ProjectWiseAllocationTab} from '../../order/views/ProjectWiseAllocationsTab';
import {AllocatedResourceTab} from '../../order/views/AllocatedResourceTab';
import {ResourceAllocationTab} from '../../order/views/ResourceAllocationTab';
import {OrderWiseAllocationsTab} from '../../order/views/FixedOrderWiseAllocations';
import {MarkIdentifyForSwappingTabs} from './MarkIdentifyForSwapping';
import {PipResourceTabs} from './PipResourceTabs';
import {JobOpeningTab} from '../../recruitment/views/JobOpeningsTab';
import {AllCandidateTabs} from '../../recruitment/views/AllCandidateTabs';
import {HrRecruitmentTab} from '../../recruitment/views/HrRecruitmentTab';
import {ManagementCostTable} from './ManagementCostReportTable';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {ReplacementTabs} from './ReplacementTab';
import {BenchCostReportTab} from './BenchCostReport';
import {NonEnggTeamReport} from './AllNonEnggReport';
import {EngGrossMarginTab} from './EnggGrossMarginTab';
import {EmployeeTurnoverReport} from './EmployeeTurnoverReport';
import {AttendanceTab} from '../../leadGeneration/views/attendance-views/AttendanceTab';
import {EmployeeWithPackageTabs} from './EmployeeWithPackage';

const EmployeeGroup = createMenuNavigator();

const EmployeeNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  const {email: employeeEmail} = user;

  return (
    <EmployeeGroup.Navigator
      canAccess={canAccess}
      data-navigator-id="EmployeeGroup"
      {...props}>
      <EmployeeGroup.Screen
        data-resource-id="AllEngineers"
        name="All"
        component={EmployeeTabs}
        props={{user: user, ...props}}
        options={{
          label: 'All',
          api: '/employees/active',
          limit: 5000,
        }}
      />
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="NonEngineers"
          name="Non Engineers Team"
          component={NonEnggTeams}
          props={{user: user, ...props}}
          options={{
            label: 'Non Engineers Team',
            api: '/employees/nonprojectteams',
            limit: 5000,
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="Engineers"
          name="Engineers"
          options={{
            label: 'Engineers',
            onPress: () => {
              props.navigation.navigate('engineering-menu', {});
            },
            api: '/employees-team/workload',
            limit: 5000,
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Section
          data-resource-id="CurrentAllocations"
          options={{label: 'Current Allocations'}}>
          <EmployeeGroup.Screen
            data-resource-id="CurrentAllocations"
            name="Order Wise"
            component={OrderWiseAllocationsTab}
            props={{user: user, ...props}}
            options={{
              label: 'Order Wise',
            }}
          />
          {/* <EmployeeGroup.Screen
            data-resource-id="CurrentAllocations"
            name="Project Wise"
            component={ProjectWiseAllocationTab}
            props={{user: user, ...props}}
            options={{
              label: 'Project Wise',
            }}
          /> */}
          <EmployeeGroup.Screen
            data-resource-id="CurrentAllocations"
            name="Employee Wise"
            component={AllocatedResourceTab}
            props={{user: user, ...props}}
            options={{
              label: 'Employee Wise',
            }}
          />
          <EmployeeGroup.Screen
            data-resource-id="CurrentAllocations"
            name="Allocation Report"
            component={ResourceAllocationTab}
            props={{user: user, ...props}}
            options={{
              label: 'Allocation Report',
            }}
          />
        </EmployeeGroup.Section>
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="AnchorResources"
          name="Anchor Resources"
          component={MarkIdentifyForSwappingTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Anchor Resources',
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="PIPResources"
          name="PIP Resources"
          component={PipResourceTabs}
          props={{user: user, ...props}}
          options={{
            label: 'PIP Resources',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="Resignations"
          name="Resignations"
          options={{
            label: 'Resignations',
            onPress: () => {
              props.navigation.navigate('resignation-menu', {});
            },
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="Replacement"
          name="Replacement"
          component={ReplacementTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Replacement',
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="SkillMatrixAdmin"
          options={{
            label: 'Skill & Courses-Admin',
            onPress: () => {
              props.navigation.navigate('skills-and-training-hr', {
                ...props?.route?.params,
              });
            },
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="EngGrossMarginsReport"
          name="Eng. gross margins report"
          component={EngGrossMarginTab}
          props={{user: user, ...props}}
          options={{
            label: 'Eng. gross margins report',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="ManagementCostReport"
          name="Management cost report"
          component={ManagementCostTable}
          props={{user: user, ...props}}
          options={{
            label: 'Management cost report',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="BenchCostReport"
          name="Bench Cost"
          component={BenchCostReportTab}
          props={{user: user, ...props}}
          options={{
            label: 'Bench Cost',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="Non engg team report"
          name="Non engg team cost"
          component={NonEnggTeamReport}
          props={{user: user, ...props}}
          options={{
            label: 'Non engg team cost',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="Employee Turnover"
          name="Employee Turnover"
          component={EmployeeTurnoverReport}
          props={{user: user, ...props}}
          options={{
            label: 'Employee Turnover',
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="EmployeeWithPackage"
          name="Employee(Hr)"
          component={EmployeeWithPackageTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Employee',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeAttendances"
          options={{
            label: 'Employee Attendance(Hr)',
            onPress: () => {
              props.navigation.navigate('employee-setup', {
                ...props?.route?.params,
              });
            },
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeSalaryHr"
          options={{
            label: 'Employee Salary(Hr)',
            onPress: () => {
              props.navigation.navigate('employee-salary-hr', {
                ...props?.route?.params,
              });
            },
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeSetupHr"
          options={{
            label: 'Setup',
            onPress: () => {
              props.navigation.navigate('employee-setup-navigator', {
                ...props?.route?.params,
              });
            },
          }}
        />

        {/* <EmployeeGroup.Screen
          data-resource-id="Employee Salary"
          name="Employee Salary"
          options={{
            label: 'Employee Salary',
            onPress: () => {
              props.navigation.navigate('employee-salary-menu', {});
            },
          }}
        /> */}
      </EmployeeGroup.Group>
    </EmployeeGroup.Navigator>
  );
};
export default EmployeeNavigator;
