import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { useFilter } from '../../../controllers/useFilter';
import {
    ReimbursementPracticeApprovedByRmTable,
    ReimbursementPracticeApprovedTable,
    ReimbursementPracticePendingTable,
    ReimbursementPracticeRejectTable,
} from './ReimbursementPracticeTable';
import {
    ReimbursementRmApprovedTable,
    ReimbursementRmPendingTable,
    ReimbursementRmRejectTable,
} from './ReimbursementRmTable';
import {
    ReimbursementSelfApprovedTable,
    ReimbursementSelfNewTable,
    ReimbursementSelfPendingTable,
    ReimbursementSelfProcessedTable,
    ReimbursementSelfRejectTable,
    ReimbursementSelfReopenTable,
} from './ReimbursementSelfTable';
import {
    ReimbursementCOOApprovedTable,
    ReimbursementCOOReferredTable,
    ReimbursementCOORejectedTable,
    ReimbursementTeamApprovedTable,
    ReimbursementTeamPendingTable,
    ReimbursementTeamReferredTable,
    ReimbursementTeamRejectTable,
} from './ReimbursementTeamTable';
import {
    ReimbursementAccountApprovedTable,
    ReimbursementAccountPendingTable,
    ReimbursementAccountProcessedTable,
    ReimbursementAccountReOpenTable,
    ReimbursementAccountRefferedPendingTable,
    ReimbursementAccountRejectedTable,
} from './ReimbursmentAccountTable';

export const SelfReimbursementTab = props => {
  let {route: {params} = {}} = props;

  const tabs = {
    new: {
      label: 'New',
      view: <ReimbursementSelfNewTable {...props} />,
      api: `/reimbursement/self/New`,
      eventSourceId: 'ReimbursementSelfNew',
      actions: [
        <AddButton
          title=" Reimbursement"
          view="add-reimbursement-request"
          params={params}
        />,
      ],
    },
    pending: {
      label: 'Pending',
      eventSourceId: 'ReimbursementSelfPending',
      view: <ReimbursementSelfPendingTable {...props} />,
      api: `/reimbursement/self/pending&partial`,
      actions: [
        <AddButton
          title=" Reimbursement"
          view="add-reimbursement-request"
          params={params}
        />,
      ],
    },
    approved: {
      label: 'Approved',
      api: `/reimbursement/self/Approved`,
      view: <ReimbursementSelfApprovedTable {...props} />,
    },
    rejected: {
      label: 'Rejected',
      api: `/reimbursement/self/Rejected`,
      view: <ReimbursementSelfRejectTable {...props} />,
    },
    processed: {
      label: 'Processed',
      api: `/reimbursement/self/Processed`,
      view: <ReimbursementSelfProcessedTable {...props} />,
    },
    reopen: {
      label: 'Re-open',
      api: `/reimbursement/self/ReOpen`,
      view: <ReimbursementSelfReopenTable {...props} />,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TeamReimbursementTab = props => {
  let {route: {params} = {}} = props;
  const tabs = {
    pending: {
      label: 'Pending',
      view: <ReimbursementTeamPendingTable {...props} />,
      eventSourceId: 'ReimbursementTeamPending',
      api: `/reimbursement/team/Pending`,
    },
    approved: {
      label: 'Approved',
      eventSourceId: 'ReimbursementTeamApprove',
      api: `/reimbursement/team/Approved`,
      view: <ReimbursementTeamApprovedTable {...props} />,
    },
    referred: {
      label: 'Referred',
      eventSourceId: 'ReimbursementTeamReferred',
      api: `/reimbursement/team/RefferedPending`,
      view: <ReimbursementTeamReferredTable {...props} />,
    },
    rejected: {
      label: 'Rejected',
      api: `/reimbursement/team/Rejected`,
      eventSourceId: 'ReimbursementTeamReject',
      view: <ReimbursementTeamRejectTable {...props} />,
    },
    reOpen: {
      label: 'Re-Open',
      eventSourceId: 'ReimbursementAccountReopen',
      api: `/reimbursement/team/ReOpen`,
      // addOnFilter: {organization: organization?._id},
      view: <ReimbursementAccountReOpenTable {...props} />,
    },
    // referred: {
    //   label: 'Reffered',
    //   eventSourceId: 'ReimbursementTeamReferred',
    //   api: `/reimbursement/team/ReferredPending`,
    //   addOnFilter: {referred: true},
    //   params: params,
    //   view: <ReimbursementTeamReferredTable {...props} />,
    //   visible: ({row}) => {
    //     if(employee?.official_mail_id)
    //   }
    // },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const COORferredReimbursement = props => {
  let {route: {params} = {}} = props;
  const tabs = {
    referred: {
      label: 'Reffered',
      eventSourceId: 'ReimbursementTeamReferred',
      api: `/reimbursement/COO/ReferredPending`,
      // addOnFilter: {referred: true},
      params: params,
      view: <ReimbursementCOOReferredTable {...props} />,
    },
    approved: {
      label: 'Approved',
      api: `/reimbursement/COO/Approved`,
      view: <ReimbursementCOOApprovedTable {...props} />,
    },
    rejected: {
      label: 'Rejected',
      api: `/reimbursement/COO/Rejected`,
      view: <ReimbursementCOORejectedTable {...props} />,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const PracticeReimbursementTab = props => {
  let {route: {params} = {}} = props;

  const tabs = {
    pending: {
      label: 'Pending',
      eventSourceId: 'ReimbursementPracticePending',
      view: <ReimbursementPracticePendingTable {...props} />,
      api: `/reimbursement/practice/Pending`,
    },
    approved: {
      label: 'Approved',
      eventSourceId: 'ReimbursementPracticePartiallyPending',
      api: `/reimbursement/practice/Partially Pending`,
      view: <ReimbursementPracticeApprovedTable {...props} />,
    },
    approvedByRm: {
      label: 'Approved By Rm',
      eventSourceId: 'ReimbursementPracticeReject',
      api: `/reimbursement/practice/Approved`,
      params: params,
      view: <ReimbursementPracticeApprovedByRmTable {...props} />,
    },
    rejected: {
      label: 'Rejected',
      eventSourceId: 'ReimbursementPracticeApprovedByRm',
      api: `/reimbursement/practice/Rejected`,
      view: <ReimbursementPracticeRejectTable {...props} />,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const RmReimbursementTab = props => {
  let {route: {params} = {}} = props;

  const tabs = {
    pending: {
      label: 'Pending',
      eventSourceId: [
        'ReimbursementRmPartiallyPending',
        'ReimbursementRmApproved',
        'ReimbursementRmReject',
      ],
      view: <ReimbursementRmPendingTable {...props} />,
      api: `/reimbursement/rm/Partially Pending`,
    },
    approved: {
      label: 'Approved',
      eventSourceId: [
        'ReimbursementRmPartiallyPending',
        'ReimbursementRmApproved',
        'ReimbursementRmReject',
      ],
      api: `/reimbursement/rm/Approved`,
      view: <ReimbursementRmApprovedTable {...props} />,
    },
    rejected: {
      label: 'Rejected',
      eventSourceId: [
        'ReimbursementRmPartiallyPending',
        'ReimbursementRmApproved',
        'ReimbursementRmReject',
      ],
      api: `/reimbursement/rm/Rejected`,
      view: <ReimbursementRmRejectTable {...props} />,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const AccountReimbursementTab = props => {
  let {route: {params} = {}} = props;
  let {organization = {}} = params || {};
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    pending: {
      label: 'Pending',
      eventSourceId: 'ReimbursementAccountPending',
      view: (
        <ReimbursementAccountPendingTable
          filterValues={filterValues}
          {...props}
        />
      ),
      addOnFilter: {organization: organization?._id, ...filterValues.filter},
      api: `/reimbursement/account/Pending`,
    },
    refferedPending: {
      label: 'Referred Pending',
      eventSourceId: 'ReimbursementAccountRefferedPending',
      api: `/reimbursement/account/RefferedPending`,
      addOnFilter: {
        referred: true,
        organization: organization?._id,
        ...filterValues.filter,
      },
      view: (
        <ReimbursementAccountRefferedPendingTable
          filterValues={filterValues}
          {...props}
        />
      ),
    },
    approved: {
      label: 'Approved',
      eventSourceId: 'ReimbursementAccountApproved',
      api: `/reimbursement/account/Approved`,
      addOnFilter: {organization: organization?._id, ...filterValues.filter},
      view: (
        <ReimbursementAccountApprovedTable
          filterValues={filterValues}
          {...props}
        />
      ),
    },
    processed: {
      label: 'Processed',
      eventSourceId: 'ReimbursementAccountProcessed',
      api: `/reimbursement/account/Processed`,
      addOnFilter: {organization: organization?._id, ...filterValues.filter},
      view: (
        <ReimbursementAccountProcessedTable
          filterValues={filterValues}
          {...props}
        />
      ),
    },
    rejected: {
      label: 'Rejected',
      api: `/reimbursement/account/Rejected`,
      eventSourceId: 'ReimbursementAccountReject',
      view: (
        <ReimbursementAccountRejectedTable
          filterValues={filterValues}
          {...props}
        />
      ),
      addOnFilter: {organization: organization?._id, ...filterValues.filter},
    },
    reOpen: {
      label: 'Re-Open',
      eventSourceId: 'ReimbursementAccountReopen',
      api: `/reimbursement/account/ReOpen`,
      addOnFilter: {organization: organization?._id, ...filterValues.filter},
      view: (
        <ReimbursementAccountReOpenTable
          filterValues={filterValues}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
