import React from 'react';
import {Form} from '../../../../components/form/Form';
import {PROJECT_VIEW_TYPE_ID} from '../../../common/constants/SourceConstants';
import {useInvoke} from '../../../../controllers/useInvoke';

export const ScreenLinkedToForm = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {
    project_id,
    source,
    source_id,
    versionId,
    linked_to_screens,
    secondaryTitle,
    readOnly,
  } = params;

  const invoke = useInvoke({method: 'put', eventSourceId: ['View']});

  const submit = props => {
    const {linked_to_screens} = props;
    invoke({
      uri: `/edit-asset-version/${versionId}`,
      props: {
        linked_to_screens,
        source,
        source_id,
      },
    });
  };

  return (
    <Form
      {...props}
      mode="edit"
      header={{title: 'Link Screen', secondaryTitle: secondaryTitle}}
      data={{linked_to_screens}}
      onSubmit={submit}
      submitAction={'Save'}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Linked Screens',
          field: 'linked_to_screens',
          type: 'multiAutoComplete',
          api: `/projects/${project_id?._id}/assets/${PROJECT_VIEW_TYPE_ID}/suggestions`,
          filter: ({values: {linked_to_screens = []} = {}}) => {
            let sourceIds = linked_to_screens?.map(doc => doc._id) || [];
            return {_id: {$nin: [...sourceIds, params?._id]}};
          },
          suggestionField: 'view',
          valueField: 'view',
          searchFields: ['view'],
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate(`add-view`, {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
        },
      ]}
    />
  );
};
