// show all unique selling point list - Parveen 13-02-2024
import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  RenderLinkedInUrl,
  RenderName,
} from '../outreachcampaign-views/CustomRendersCollection';
import {leadControllers} from '../controllers/leadControllers';

export const PerosnalizationErrorsList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  const {deleteandRegenerateAction} = leadControllers(props);

  return (
    <Table
      eventSourceId={['deleteandRegenerate']}
      api={`/campaignactions`}
      selection={{
        actions: [
          {
            title: 'Delete & Regenerate',
            onPress: deleteandRegenerateAction,
            confirm: {
              title: 'Delete and Regenerate',
              message:
                'Are you sure you want to delete and regenerate these actions?',
              confirmText: 'Confirm',
            },
          },
        ],
      }}
      fields={{
        _id: 1,
        name: 1,
        sequence_display: 1,
        action: 1,
        personalize_body: 1,
        contact_id: {
          linkedin_url: 1,
          name: 1,
          first_name: 1,
          last_name: 1,
          designation: 1,
        },
        potential_customer: {
          name: 1,
          website: 1,
          country: {name: 1, code: 1},
          website_summary: 1,
          industry: {name: 1},
        },
        campaign_id: {name: 1},
        verified: 1,
        createdAt: 1,
        campaign_contact_id: 1,
      }}
      sort={{createdAt: -1}}
      filter={{
        personalize_with_ai: true,
        status: 'pending',
        $or: [
          {personalize_body: {$exists: false}},
          {personalize_body: null},
          {personalize_body: ''},
        ],
      }}
      columns={[
        {
          header: '',
          type: 'number',
          field: 'sequence_display',
          width: 30,
        },
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
        },
        {
          header: 'Website',
          render: RenderName,
        },
        {
          field: 'action',
          type: 'text',
          header: 'Action',
        },
        {
          field: 'campaign_id.name',
          type: 'text',
          header: 'Campaign',
        },
        {
          field: 'createdAt',
          type: 'date',
          header: 'Date',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
      ]}
      moreActions={[
        {
          title: 'Delete & Regenerate',
          onPress: deleteandRegenerateAction,
          confirm: {
            title: 'Delete and Regenerate',
            message:
              'Are you sure you want to delete and regenerate these actions?',
            confirmText: 'Confirm',
          },
        },
      ]}
    />
  );
};
