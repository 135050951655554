import React from 'react';
import {Image, View} from '@unthinkable/react-core-components';
import {useComponentTheme, useTheme} from '@unthinkable/react-theme';
import {Action} from './Action';

export const AddAction = ({icon, ...props}) => {
  const icons = useTheme('icons');
  return (
    <Action {...props}>
      <Image source={icon || icons?.Add} />
    </Action>
  );
};

export const AddFilledAction = ({icon, ...props}) => {
  let {addPlus, iconStyle, iconContainerStyle} = useComponentTheme(
    'PlusColumnRenderStyles',
  );
  return (
    <Action {...props}>
      <View style={iconContainerStyle}>
        <Image source={addPlus} style={iconStyle} />
      </View>
    </Action>
  );
};
