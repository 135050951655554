export default {
  date: {
    width: 120,
    variant: 'secondary',
  },
  number: {
    width: 120,
  },
  moreActions: {
    width: 40,
  },
};
