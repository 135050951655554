import React, {useState} from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {BorderedGroupTable} from '../../../components/table/Table';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {Row, Text} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form-editors/Editors';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';

export const AmPerformanceTable = props => {
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {
    params: {period},
  } = filterValues;
  const {navigation} = props;
  const [isComparative, setIsComparative] = useState(false);

  return (
    <BorderedGroupTable
      {...props}
      {...filterValues}
      variant={'bordered'}
      renderHeader={props => (
        <TableHeader
          title="AM Performance"
          actions={[
            <Row style={{gap: 4}}>
              <TextRenderer value={'Static'} />
              <Switch onChangeValue={setIsComparative} value={isComparative} />
              <TextRenderer value={'Comparative'} />
            </Row>,
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              asParam
            />,
          ]}
          {...props}
        />
      )}
      api="/amPerformancesMonthWise"
      groupRow={{
        data: '_children.data',
        header: true,
        defaultExpanded: true,
        aggregate: '_children.aggregates',
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                }}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: period?.monthWiseBreakup
                    ? 'Month'
                    : period?.filterOf,
                })}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          type: 'userAvatarChip',
          field: 'employee',
          header: 'Name',
        },
        {
          header: 'RFQ',
          children: [
            {
              field: 'won_rfq',
              header: 'Won',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfq', {
                  label: 'Won RFQ',
                  filters: {
                    customer_id: {$exists: true},
                    status: 'Won',
                    opportunity_type: 'CustomPlatformOpportunity',
                    rfr_type: 'Customer',
                    outcome_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              visible: !isComparative,
              aggregate: true,
            },
            {
              field: 'comparative_won_rfq',
              header: 'Won',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfq', {
                  label: 'Won RFQ',
                  filters: {
                    customer_id: {$exists: true},
                    status: 'Won',
                    opportunity_type: 'CustomPlatformOpportunity',
                    rfr_type: 'Customer',
                    creation_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    outcome_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              visible: !!isComparative,
              aggregate: true,
            },
            {
              field: 'drop_rfq',
              header: 'Dropped',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfq', {
                  label: 'Dropped RFQ',
                  filters: {
                    customer_id: {$exists: true},
                    status: {$in: ['invalidRfq']},
                    opportunity_type: 'CustomPlatformOpportunity',
                    rfr_type: 'Customer',
                    outcome_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              aggregate: true,
            },
            {
              field: 'all_rfq',
              header: 'All',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfq', {
                  label: 'All RFQ',
                  filters: {
                    customer_id: {$exists: true},
                    opportunity_type: 'CustomPlatformOpportunity',
                    rfr_type: 'Customer',
                    creation_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              aggregate: true,
            },
          ],
        },
        {
          header: 'RFR',
          children: [
            {
              field: 'won_rfr',
              header: 'Won',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfr', {
                  label: 'Won RFR',
                  filters: {
                    customer_id: {$exists: true},
                    status: 'Won',
                    opportunity_type: 'StaffAugmentationOpportunity',
                    rfr_type: 'Customer',
                    outcome_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              visible: !isComparative,
              aggregate: true,
            },
            {
              field: 'comparative_won_rfr',
              header: 'Won',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfr', {
                  label: 'Won RFR',
                  filters: {
                    customer_id: {$exists: true},
                    status: 'Won',
                    opportunity_type: 'StaffAugmentationOpportunity',
                    rfr_type: 'Customer',
                    creation_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    outcome_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              visible: !!isComparative,
              aggregate: true,
            },
            {
              field: 'drop_rfr',
              header: 'Dropped',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfr', {
                  label: 'Dropped RFR',
                  filters: {
                    customer_id: {$exists: true},
                    is_drop: true,
                    opportunity_type: 'StaffAugmentationOpportunity',
                    rfr_type: 'Customer',
                    outcome_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              aggregate: true,
            },
            {
              field: 'all_rfr',
              header: 'All',
              type: 'number',
              onPress: ({row}) => {
                navigation.navigate('channel-wise-rfr', {
                  label: 'All RFR',
                  filters: {
                    customer_id: {$exists: true},
                    opportunity_type: 'StaffAugmentationOpportunity',
                    rfr_type: 'Customer',
                    creation_date: {
                      $lte: period?.to,
                      $gte: period?.from,
                    },
                    owner: row?.employeeId,
                  },
                });
              },
              aggregate: true,
            },
          ],
        },
      ]}
    />
  );
};
