import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useFetchData} from '../../../controllers/useFetchData';

const ToolTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {data} = useFetchData({
    api: `/skillmetrics`,
    filter: {
      _id: params.skill_metrics?._id,
    },
    fields: {
      tools: {name: 1},
    },
    only: true,
    eventSourceId: 'skillMetricsAdded',
  });
  return (
    <Table
      data={data?.tools}
      renderHeader={() => {
        return <TableHeader title="Tools/Languages" />;
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-tools', {row});
      }}
      params={params}
      columns={[
        {
          field: 'name',
          header: 'Tool Name',
        },
      ]}
    />
  );
};

export default ToolTable;
