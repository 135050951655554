import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';
import { renderLocale } from './OrderTable';
import { AmountRender } from './components/AmountRender';
import { Button } from '../../../components/button/Button';


const InvoiceReportTable = props => {
    const { filterParams, searchValue, params } = props;
    return (
        <Table
            {...props}
            api={'/revenuereportall'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="AllInvoices"
            searchFields={['invoice_number']}
            search={searchValue}
            limit={50}
            columns={[
                {
                    field: 'account_owner_id',
                    header: 'Relation',
                    type: 'userAvatarChip',
                    width: 240,
                },
                {
                    type: 'userAvatarChip',
                    field: 'customer',
                    header: 'Customer',
                    minWidth: 240,
                },
                {
                    field: 'invoice_number',
                    header: 'Invoice No.',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    field: 'invoice_date',
                    header: 'Invoice Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 140,
                },
                {
                    type: 'text',
                    field: 'invoice_type',
                    header: 'Type',
                    width: 140,
                    value: ({ row }) => {
                        if (row?.invoice_type) {
                            let result = "";
                            if (row.invoice_type === "a") {
                                result = "ADV-S";
                            } else if (row.invoice_type === "i") {
                                result = "ACT-S";
                            } else if (row.invoice_type === "c") {
                                result = "CN";
                            } else if (row.invoice_type === "d") {
                                result = "Debit";
                            }
                            return result;
                        }
                    },
                },
                {
                    header: 'Amount',
                    field: 'invoice_amount',
                    type: 'currency',
                    width: 160,
                    align: 'center',
                    render: ({ row, styles }) => (
                        <AmountRender
                            row={row}
                            styles={styles}
                            amount={row?.invoice_amount || 0}
                            base_amount={row?.base_invoice_amount || 0}
                            currency={row?.currency?.currency}
                        />
                    ),
                    formatOptions: ({ row }) => {
                        return {
                            locale: renderLocale(row?.currency?.currency),
                            currency: row?.currency?.currency,
                        };
                    },
                },
                {
                    field: 'irn',
                    header: 'IRN',
                },
            ]}
        />
    );
};

export const InvoiceReportTab = props => {
    let { route: { params } = {} } = props;
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        Invoice: {
            label: 'Invoice',
            view: (
                <InvoiceReportTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'GST',
            api: '/revenuereportall',
            addOnFilter: filter,
            search: searchValue,
            searchFields: ['invoice_number'],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <SearchFilter
                    value={searchValue}
                    onChangeFilter={onChangeFilter}
                    placeholder="Search Invoice Number"
                />,
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'invoice_date',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Customer',
                            api: '/customers',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'customer',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Invoice Type',
                            options: [
                                { label: "Advance", value: "a" },
                                { label: "Invoice", value: "i" },
                                { label: "Credit", value: "c" },
                                { label: "Debit", value: "d" },
                            ],
                            placeholder: 'Select',
                            field: 'invoice_type',
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
                <Button
                    text="IRN Report"
                    onPress={() => {
                        download({
                            uri: `/exportIrnreport`,
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
                <Button
                    text="EDPMS Report"
                    onPress={() => {
                        download({
                            uri: `/exportepdmsreport`,
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
                <Button
                text="Invoice Report"
                onPress={() => {
                    download({
                        uri: `/exportepdmsreport`,
                        props: {
                            addOnFilter: { ...filter },
                            call_from : "Invoice"
                        },
                    });
                }}
            />,
                <Button
                    text="Customer Statement"
                    onPress={() => {
                        download({
                            uri: `/exportcustomerstatement`,
                            props: {
                                filter: {
                                    ...filter
                                },
                            },
                        });
                    }}
                />,
            ]}
        />
    );
};
