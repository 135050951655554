import React from 'react';
import {View} from '@unthinkable/react-core-components';

import useRealTimeSync from '../../../../components/customHooks/useRealTimeSync';
import {InlineForm} from '../../../../components/form/Form';
import {Table} from '../../../../components/table/Table';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_QA_TEST_CASE_TYPE_ID} from '../../../common/constants/SourceConstants';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {SplitScreen} from '../../../../components/SplitScreen';
import {UpdateQATestCaseForm} from './QATestCaseForm';
import {QaTestCaseTable, qaTestCasesExportParams} from './QATestCasesScreen';
import {TabView} from '../../../../components/tab';
import {TableHeader} from '../../../../components/table/Headers';
import {useFilter} from '../../../../controllers/useFilter';
import {SearchFilter} from '../../../../components/filter/Filters';
import {useFetchData} from '../../../../controllers/useFetchData';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {GroupBy} from '../../../../components/table/GroupBy';

const QATestCaseInlineForm = props => {
  const {
    navigation,
    route: {params = {}},
    sourceIds,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'TestCase',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, module} = params;

  const defaultValues = {
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
    feature_id: feature?._id,
    project_id: project?._id,
    module_id: module?._id,
    is_changed: false,
  };

  return (
    <View style={{flex: 1}}>
      <InlineForm
        onSubmit={onSubmit}
        submitAction="Save"
        defaultValues={defaultValues}
        layoutFields={[
          {
            label: 'Title',
            field: 'source_id',
            type: 'autoComplete',
            api: `/projects/${project?._id}/assets/${PROJECT_QA_TEST_CASE_TYPE_ID}/suggestions`,
            suggestionField: 'name',
            valueField: 'name',
            searchFields: ['name'],
            required: true,
            filter: {_id: {$nin: sourceIds}},
            onCreate: ({searchValue}) => {
              navigation.navigate(`add-qa-test-case`, {
                ...params,
                searchValue,
              });
            },
          },
        ]}
      />
    </View>
  );
};

export const FeatureQATestCasesTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
    selectedId,
  } = props;

  const {feature} = params;

  const tableProps = {
    api: `/features/${feature?._id}/assets/${PROJECT_QA_TEST_CASE_TYPE_ID}/asset-data`,
  };

  const EVENT_SOURCE_ID = ['TestCase', 'Task', 'MapUserstory'];

  const {
    copyTestcase,
    assetDetail,
    tasks,
    removeFromFeature,
    versions,
    changeModule,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
    api: '/qaTestCases',
    source: PROJECT_QA_TEST_CASE_TYPE_ID,
  });

  const {
    setData,
    data = [],
    ...restFetchProps
  } = useFetchData({
    api: `/features/${feature?._id}/assets/${PROJECT_QA_TEST_CASE_TYPE_ID}/asset-data`,
    eventSourceId: EVENT_SOURCE_ID,
  });

  useRealTimeSync({
    event: 'QAStatusNotify',
    group: 'QATL',
    callBack: socketData => {
      if (socketData) {
        const socketDataMap = new Map(
          socketData.map(socket => [socket?._id.toString(), socket]),
        );

        const newData =
          data?.map(item => {
            const res = socketDataMap.get(item?._id.toString());
            return {
              ...item,
              execution_status: res?.execution_status,
              error_message: res?.error_message,
              last_executed_on: res?.last_executed_on,
            };
          }) || [];

        if (newData && newData.length > 0) {
          setData({data: newData});
        }
      }
    },
  });

  return (
    <Table
      data={data}
      {...restFetchProps}
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_QA_TEST_CASE_TYPE_ID}
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={qaTestCasesExportParams({
            ...tableProps,
            fromFeature: true,
          })}
          {...headerProps}
          {...props}
        />
      )}
      selection
      columns={[
        {type: 'Code', field: 'code', header: 'Code', width: 150},
        {type: 'text', field: 'name', header: 'Title'},
        {
          type: 'text',
          field: 'type',
          header: 'Type',
          width: 120,
        },
        !selectedId && assetColumns.userstory_count,
        !selectedId && assetColumns.tasks({onPress: tasks}),
        !selectedId && assetColumns.est_hrs,
        !selectedId && assetColumns.assignees,
        assetColumns.testcase_status,
      ]}
      onRowPress={assetDetail}
      moreActions={[copyTestcase, changeModule, removeFromFeature]}
    />
  );
};

const FeatureQATestCasesScreen = props => {
  return (
    <SplitScreen splitScreen={<UpdateQATestCaseForm {...props} />}>
      <FeatureQATestCasesTable {...props} />
    </SplitScreen>
  );
};

export const AllQaTestCaseScreen = props => {
  const {
    route: {params},
    api,
    groupBy,
  } = props;

  const {project, feature, module} = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField = assetMappings[PROJECT_QA_TEST_CASE_TYPE_ID].sourceField;

  const tableProps = {
    api:
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_QA_TEST_CASE_TYPE_ID}/all-data`,
    addOnFilter: filterValues.filter,
    search: searchValue,
    searchFields: sourceField,
    filter: {
      module_id: module?._id,
    },
    params: {
      show_in_development: !feature?._id,
    },
    limit: groupBy ? 10000 : 50,
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          !module?._id && <GroupBy {...groupBy} />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<UpdateQATestCaseForm {...props} />}>
      <QaTestCaseTable
        {...props}
        renderHeader={renderHeader}
        groupBy={groupBy}
        {...tableProps}
        fromFeature
      />
    </SplitScreen>
  );
};

export const FeatureQATestCasesTab = props => {
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'In Development',
          view: <FeatureQATestCasesScreen {...props} />,
        },
        all: {
          label: 'All',
          view: <AllQaTestCaseScreen {...props} />,
        },
      }}
    />
  );
};
