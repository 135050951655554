export const CardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    BlueTickIcon: icons.BlueTick,
    RolesMembersIcon: icons.RolesMembersIcon,
    RightArrow: icons.RightArrow,
    CardContainer: {
      paddingTop: 12,
      paddingRight: 16,
      paddingBottom: 12,
      paddingLeft: 16,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      alignItems: 'center',
    },
    CardTextStyle: {
      flex: 1,
      overflow: 'hidden',
      justifyContent: 'center',
    },
    HeaderStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    SubheadingStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    IconStyle: {
      height: 30,
      width: 30,
    },
    RightArrowStyle: {
      height: 16,
      width: 16,
    },
    icons,
  };
};
