import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';
import { randomColor } from '../../../utils/util';


const PFReportTable = props => {
    const { filterParams, params } = props;
    return (
      <Table
        {...props}
        api={'/getpfreport'}
        addOnFilter={filterParams}
        params={params}
        eventSourceId="pfreport"
        limit={50}
        columns={[
          {
            type: 'text',
            field: 'employee.name',
            header: 'Employee',
            minWidth: 200,
          },
          {
            field: 'employee.employee_code',
            header: 'Employee Code.',
            type: 'colorTag',
            colorField: randomColor,
            width: 160,
          },
          {
            field: 'invoiceDate',
            header: 'Invoice Date',
            type: 'date',
            formatOptions: {
              format: 'DD MMM YY',
            },
            width: 160,
          },
          {
            field: 'employee.adhar_number',
            header: 'Adhaar umber',
            type: 'text',
            width: 140,
          },
          {
            type: 'text',
            header: 'UAN Number',
            field: 'employee.uan_number',
            width: 140,
          },
          {
            field: 'gross_amount',
            type: 'currency',
            header: 'Gross Amount',
            width: 140,
          },
          {
            field: 'payable_amount',
            type: 'currency',
            header: 'Payable Amount',
            width: 140,
          },
          {
            field: 'total_payable_sum',
            type: 'currency',
            header: 'Amount',
            width: 140,
          },
          {
            field: 'pf_employee',
            header: 'PF Employee',
            type: 'text',
            width: 180,
          },
          {
            field: 'pf_employer',
            header: 'PF Employer',
            type: 'text',
            width: 180,
          },
          {
            field: 'gross_vpf',
            header: 'Gross VPF',
            type: 'text',
            width: 180,
            // visible: ({row}) => {
            //   if (!row?.gross_vpf || row?.gross_vpf === 0) {
            //     return false;
            //   } else {
            //     return true;
            //   }
            // },
          },
          {
            field: 'payable_vpf',
            header: 'Payble VPF',
            type: 'text',
            width: 180,
            // visible: ({row}) => {
            //   if (!row?.payable_vpf || row?.payable_vpf === 0) {
            //     return false;
            //   } else {
            //     return true;
            //   }
            // },
          },
          {
            field: 'employee.joining_date',
            header: 'Joining Date',
            type: 'date',
            formatOptions: {
              format: 'DD MMM YY',
            },
            width: 180,
          },
          {
            field: 'employee.dob',
            header: 'DOB',
            type: 'date',
            formatOptions: {
              format: 'DD MMM YY',
            },
            width: 180,
          },
          {
            field: 'employee.gender',
            header: 'Gender',
            type: 'text',
            width: 180,
          },
        ]}
      />
    );
};

export const PFReportTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'PF Report',
            view: (
                <PFReportTable filterParams={filter} {...props} />
            ),
            eventSourceId: 'PFReport',
            api: '/getpfreport',
            addOnFilter: filter,
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportpfreport',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Employee',
                            api: '/employeeSuggestions',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'employee',
                            searchFields: ['name', 'official_email_id', 'employee_code'],
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Month',
                            api: '/monthtypes',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'month_id',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Year',
                            api: '/yeartypes',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'year_id',
                        },
                    ]}
                />,
            ]}
        />
    );
};
