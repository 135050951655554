import React from 'react';
import {Table} from '../../../components/table/Table';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {TabView} from '../../../components/tab';
import {
  Image,
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';
import Barcode from 'react-jsbarcode';
const JsBarcode = require('jsbarcode');
const {createCanvas} = require('canvas');

const ASSIGNDATE = {
  header: 'Assigned On',
  type: 'date',
  field: 'assigned_from_date',
  formatOptions: {
    format: 'DD MMM YY',
  },
  width: 150,
};

const SERIELNUMBER = {
  header: 'Serial Number',
  field: 'serial_no',
};

const ASSETANDCODE = {
  header: 'Asset tag',
  align: 'center',
  width: 180,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.code}
        item={row?.purchase_category?.name}
      />
    );
  },
};

const BRAND = {
  header: 'Brand',
  field: 'brand',
  type: 'text',
};

const MODEL = {
  header: 'Model',
  field: 'model',
};

const SPECIFICATION = {
  header: 'Specification',
  field: 'specification',
};

const BARCODE = resourceUrl => ({
  header: 'Bar Code',
  align: 'center',
  width: 250,
  render: ({row, styles: {rowText = {}}}) => {
    return (
      <TouchableOpacity
        onPress={() => {
          const url = resourceUrl(row?.code);
          Linking.openURL(url);
        }}>
        <Barcode
          value={row?.code}
          renderer="image"
          style={{
            height: 80,
          }}
        />
      </TouchableOpacity>
    );
  },
});

const AGGREGATE = {
  header: 'Aggregate',
  render: ({row, styles: {rowText = {}}}) => {
    return <Text style={{...rowText}}>{row?.is_aggregate ? 'Yes' : 'No'}</Text>;
  },
};

const AssignedITAsset = props => {
  const {route: {params = {}} = {}, addOnFilter = {}, navigation} = props;
  const {user, resourceUrl} = useAppStateContext();
  const {employee = {}} = user;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['MyITAsset'],
  });
  return (
    <Table
      eventSourceId={['MyITAsset']}
      addOnFilter={{...addOnFilter}}
      api={`/my-active-it-assets`}
      columns={[
        ASSIGNDATE,
        ASSETANDCODE,
        BRAND,
        MODEL,
        SERIELNUMBER,
        SPECIFICATION,
        {
          type: 'text',
          header: 'Verification status',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.is_verified}
                items={[{value: row?.verified_on}]}
              />
            );
          },
        },
        // BARCODE(resourceUrl),
        // {
        //   type: 'custome',
        //   header: 'Bar Code',
        //   render: ({row}) => {
        //     var canvas = createCanvas({
        //       type: 'svg',
        //     });

        //     JsBarcode(canvas, row.code, {
        //       background: '#f5f5f5',
        //       width: 1,
        //       displayValue: true,
        //     });
        //     const url = canvas.toDataURL('image/jpeg');

        //     return (
        //       <View>
        //         <a href={url} download>
        //           <Image
        //             source={url}
        //             style={{height: 80, width: 150, objectFit: 'cover'}}
        //           />
        //         </a>
        //       </View>
        //     );
        //   },
        //   width: 200,
        //   height: 50,
        // },
        // AGGREGATE,
      ]}
      moreActions={[
        {
          title: 'Tagged Asset',
          onPress: ({row}) => {
            navigation.navigate('tagged-asset', {row});
          },
        },
        {
          title: 'Mark Return',
          confirm: {
            title: 'Mark Return',
            message: 'Are you sure you want to mark return this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/return/${row._id}`,
              props: {
                status: 'Returned',
                asset_status: 'Inactive',
                asset_returned_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },
        {
          title: 'Upload Asset Photo',
          onPress: ({row}) => {
            navigation.navigate('upload-asset-photo', {row});
          },
        },
        {
          title: 'Verification Status',
          onPress: ({row}) => {
            navigation.navigate('verification-asset-status', {row});
          },
          visible: ({row}) => {
            if (
              row?.is_verified == 'Accepted' ||
              row?.is_verified == 'Rejected'
            ) {
              return false;
            } else {
              return true;
            }
          },
        },
      ]}
    />
  );
};

const ReturnedITAsset = props => {
  const {route: {params = {}} = {}, addOnFilter = {}} = props;
  return (
    <Table
      eventSourceId={['MyITAsset']}
      addOnFilter={{...addOnFilter}}
      api={`/inactive-it-assets`}
      columns={[
        ASSIGNDATE,
        ASSETANDCODE,
        BRAND,
        MODEL,
        SPECIFICATION,
        {
          header: 'Returned on',
          type: 'date',
          field: 'asset_returned_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
    />
  );
};

export const MyITAssetTab = props => {
  const {route: {params = {}} = {}, navigation} = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  const tabs = {
    all: {
      label: 'Assigned Assets',
      view: (
        <AssignedITAsset
          tab={'assigned'}
          addOnFilter={{assigned_to: employee?._id}}
          {...props}
        />
      ),
      eventSourceId: 'MyITAsset',
      api: '/my-active-it-assets',
      addOnFilter: {
        assigned_to: employee?._id,
      },
      limit: 1000,
    },

    returned: {
      label: 'Returned Assets',
      view: (
        <ReturnedITAsset
          tab={'returned'}
          addOnFilter={{
            status: 'Returned',
            assigned_to: employee?._id,
          }}
          {...props}
        />
      ),
      eventSourceId: 'MyITAsset',
      api: '/inactive-it-assets',
      addOnFilter: {
        status: 'Returned',
        assigned_to: employee?._id,
      },
      limit: 1000,
    },
  };

  return <TabView {...props} tabs={tabs} params={params} />;
};
