import React from 'react';
import {Table} from '../../../components/table/Table';
import {MailContentRender} from '../components/ExpandableRowComponent';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';
import {Text, View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeFilter} from '../../../components/filter/Filters';

const NAME = {
  field: 'name',
  header: 'Name',
  type: 'text',
  responsive: 'sm',
};

const RenderType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (!row?.type) return null;
  let backgroundColors;
  if (row?.type == 'Message Series') {
    backgroundColors = colors['ACCENT5_MEDIUM'];
  } else if (row?.type == 'Connection Message') {
    backgroundColors = colors['ACCENT6_MEDIUM'];
  }
  return (
    <Text
      style={{
        ...rowText,
        width: 150,
        backgroundColor: backgroundColors,
        padding: 2,
        borderRadius: 5,
        textAlign: 'center',
      }}>
      {row?.type}
    </Text>
  );
};

const ViewBody = ({styles}) => {
  const colors = useTheme('colors');
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      <Text style={{...styles.rowText, color: colors?.['ACCENT1_HIGH']}}>
        View Body
      </Text>
    </View>
  );
};

export const DripInmailTable = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const {filter} = params;

  let invokeDelete = useInvoke({
    method: 'delete',
    eventSourceId: 'dripInmailAdded',
  });

  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  return (
    <Table
      search={searchValue}
      eventSourceId={['dripInmailAdded']}
      searchFields={['name', 'inmail_body']}
      api={`/drip-inmail-contents`}
      params={{
        period,
        value_proposition: filter?.value_proposition,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
            ]}
          />
        );
      }}
      renderExpandRow={({row}) => {
        return <MailContentRender message={row?.inmail_body} />;
      }}
      columns={[
        NAME,
        {
          field: 'send_action_count',
          header: 'Send Action',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-actions', {
              addOnFilter: {
                createdAt: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                inmail_message_id: row?._id,
                actionType: 'inmail-actions',
              },
            });
          },
        },
        {
          field: 'replied_count',
          header: 'Replied',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-communications', {
              addOnFilter: {
                date: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                drip_inmail_content_id: row?._id,
              },
              title: row?.name,
            });
          },
        },
        {
          render: ViewBody,
          expandable: true,
          responsive: 'sm',
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: row => {
            navigation.navigate('edit-drip-inmail-new', row);
          },
        },
        {
          title: 'Delete',
          onPress: ({row}) => {
            invokeDelete({uri: `/dripInmailContents/${row?._id}`});
          },
        },
      ]}
    />
  );
};
