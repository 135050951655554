import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const BlogForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Blog',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/blogs',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/blogs/${row?._id}`}
      fields={{
        blog_title: 1,
        blog_content: 1,
        blog_url: 1,
        marketing_concept: {concept_name: 1},
        attachments: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
      }}
      layoutFields={[
        {
          placeholder: 'Title',
          field: 'blog_title',
          type: 'text',
        },
        {
          placeholder: 'Description',
          field: 'blog_content',
          type: 'text',
        },
        {
          placeholder: 'Blog URL',
          field: 'blog_url',
          type: 'text',
        },
        {
          placeholder: 'Marketing Concept',
          field: 'marketing_concept',
          type: 'autoComplete',
          api: `/marketingconcepts`,
          suggestionField: 'concept_name',
          valueField: 'concept_name',
        },
        {
          field: 'attachments',
          type: 'file',
          placeholder: 'Upload file*',
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditBlogForm = props => {
  return <BlogForm mode="edit" header={'Edit Blog'} {...props} />;
};
