import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useFetchData} from '../../../controllers/useFetchData';

export const MilestoneForm = props => {
  const {
    defaultValues,
    route: {params},
  } = props;

  const {project} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Milestone',
    uri: '/projectmilestones',
    ...props,
  });

  return (
    <>
      <Form
        fields={{
          milestone: 1,
          due_date: 1,
          completed_on: 1,
        }}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        submitAction="Save"
        layoutFields={[
          {
            label: 'Milestone',
            type: 'text',
            field: 'milestone',
            required: true,
          },
          {
            label: 'Owner',
            field: 'owner_id',
            type: 'autoComplete',
            api: `/projects/${project._id}/members`,
            suggestionField: 'name',
            secondarySuggestionField: 'email',
            colorField: 'color',
            avatar: true,
            valueField: 'name',
          },
          {
            label: 'Start Date',
            type: 'date',
            field: 'start_date',
            size: 'medium',
          },
          {
            label: 'Target Date',
            type: 'date',
            field: 'due_date',
            readOnly: !!defaultValues?.due_date,
            size: 'medium',
          },
          {
            label: 'Description',
            type: 'textArea',
            field: 'desc',
            inputProps: {
              minRows: 3,
              maxRows: 6,
            },
          },
        ]}
        {...props}
      />
    </>
  );
};

export const AddMilestoneForm = props => {
  return <MilestoneForm header={'Add Milestone'} {...props} />;
};

export const UpdateMilestoneForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  const {data} = useFetchData({
    api: '/projectmilestones',
    filter: {
      _id: row?._id,
    },
    only: true,
    fields: {
      milestone: 1,
      due_date: 1,
      start_date: 1,
      completed_on: 1,
      status: 1,
      desc: 1,
      owner_id: {name: 1},
    },
  });

  if (!data) {
    return null;
  }

  return (
    <MilestoneForm
      header={'Update Milestone'}
      defaultValues={data}
      {...props}
    />
  );
};

export const UpdateMilestoneCompletedDate = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Milestone',
    uri: '/projectmilestones',
    ...props,
  });
  return (
    <Form
      fields={{
        completed_on: 1,
      }}
      header={'Update Completed Date'}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      api={`/projectmilestones/${row?._id}`}
      layoutFields={[
        {
          label: 'Completed Date',
          field: 'completed_on',
          type: 'date',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
