import {ButtonStyle, OutlineButtonStyle} from '../button/theme';
import {AutoCompleteStyles} from '../form-editors/autocomplete/theme';
import {DateInputStyles} from '../form-editors/date/theme';
import {RadioGroupStyles} from '../form-editors/radio/theme';

export const DateFilterStyles = ({theme}) => {
  const {fonts, colors, icons, shadows} = theme;
  return {
    previousIcon: icons.DatePrevious,
    nextIcon: icons.DateNext,
    gap: 12,
    datePicker: DateInputStyles({theme}),
    iconContainerStyle: {
      height: 20,
      width: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 12,
      width: 12,
    },
    containerStyle: {
      height: 32,
      borderRadius: 8,
      alignItems: 'center',
      flexDirection: 'row',
      borderColor: colors.NEUTRAL_MEDIUM,
      borderWidth: 1,
      width: 185,
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    textStyle: {
      ...fonts.ACTION,
      color: colors.NEUTRAL_HIGH,
      paddingLeft: 8,
      paddingRight: 8,
      textAlign: 'center',
      flex: 1,
    },
    calendarStyle: {
      dropdownStyle: {
        // marginTop: 8,
        // marginBottom: 8,
        padding: 6,
        width: 560 + 48,
        height: 350,
        padding: 10,
        borderRadius: 4,
        backgroundColor: 'white',
        ...shadows.M,
      },
      header: {
        containerStyle: {
          flexDirection: 'row',
        },
        prevContainerStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        },
        nextContainerStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        },
        titleTextStyle: {
          color: colors.NEUTRAL_HIGH,
          ...fonts.BODY1,
        },
        prev: {
          prevIcon: icons.DatePrevious,
          imageStyle: {},
          viewStyle: {
            width: 40,
            height: 20,
            // backgroundColor: colors.ACCENT1_LOW,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            // borderRadius: 4,
          },
          textStyle: {
            color: colors.NEUTRAL_HIGH,
          },
        },
        next: {
          nextIcon: icons.DateNext,
          imageStyle: {},
          viewStyle: {
            width: 40,
            height: 20,
            // backgroundColor: colors.ACCENT1_LOW,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            // borderRadius: 4,
          },
          textStyle: {
            color: colors.NEUTRAL_HIGH,
          },
        },
      },
      footer: {
        containerStyle: {
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          flex: 1,
        },
      },

      dateStyle: {
        viewStyle: {
          height: 40,
          width: 40,
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        },
        deActiveViewStyle: {},
        selectedViewStyle: {
          backgroundColor: colors.SECONDARY_HIGH,
          borderRadius: 40,
        },
        inBetweenStyle: {
          backgroundColor: '#F8F8FB',
        },
        textStyle: {
          whiteSpace: 'pre-wrap',
          ...fonts.BODY3,
          color: colors.NEUTRAL_HIGH,
        },
        deActiveTextStyle: {
          color: colors.NEUTRAL_LOW,
        },
        selectedTextStyle: {
          color: colors.BACKGROUND,
          ...fonts.BODY1,
        },
      },
      weekContainerStyle: {
        flexDirection: 'row',
        paddingTop: 5,
        alignItems: 'center',
      },
      weekComponentStyle: {
        viewStyle: {
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          padding: 5,
          cursor: 'pointer',
        },
        textStyle: {
          textAlign: 'center',
          color: colors.NEUTRAL_MEDIUM,
          ...fonts.BODY1,
        },
        primaryViewStyle: {},
        primaryTextStyle: {
          // color: colors.ERROR_HIGH,
        },
      },
    },

    autoCompleteTheme: {
      ...AutoCompleteStyles({theme}),
      container: {
        borderRadius: 8,
        borderColor: colors.NEUTRAL_MEDIUM,
        backgroundColor: colors.BACKGROUND,
        minHeight: 36,
      },
      input: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_HIGH,
        overflow: 'hidden',
      },
    },
  };
};

export const SearchFilterStyles = ({
  theme: {colors, fonts, icons, shadows},
}) => {
  return {
    SearchIcon: icons.MagnifyingGlass,
    CrossIcon: icons.Cross,
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 100,
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 12,
      paddingRight: 12,
      width: 177,
    },
    selectedContainerStyle: {
      borderColor: colors.UPPER_OUTLINE,
      ...shadows.S,
    },
    inputContainerStyle: {flex: 1, overflow: 'hidden'},
    inputStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      overflow: 'hidden',
    },

    iconStyle: {height: 16, width: 16},
  };
};

export const GroupFilterStyles = ({theme}) => {
  const {fonts, colors, icons, shadows} = theme;
  return {
    filterIcon: icons.Filter,
    badgeBackgroundColor: colors.NEUTRAL_MEDIUM,
    badgeTextColor: colors.BACKGROUND,
    buttonContainer: {
      height: 32,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: 'center',
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 6,
    },
    buttonIcon: {height: 20, width: 20},
    filterCountText: {
      ...fonts.SIDE_NAVIGATION,
      color: colors.NEUTRAL_MEDIUM,
    },
    headerContainerStyle: {
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: 10,
      paddingTop: 10,
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
    },
    titleStyle: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
      flex: 1,
    },
    crossIcon: icons['Cross'],
    iconContainerStyle: {
      height: 32,
      width: 32,
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    tabContainerStyle: {
      width: 150,
      backgroundColor: colors.SURFACE1,
    },
    tabStyle: {
      height: 36,
      paddingLeft: 12,
      paddingRight: 12,
      flexDirection: 'row',
      alignItems: 'center',
    },
    tabCrossIconStyle: {
      height: 16,
      width: 16,
    },
    selectedTabStyle: {
      backgroundColor: colors.BACKGROUND,
    },
    labelStyle: {
      ...fonts.SIDE_NAVIGATION,
      color: colors.NEUTRAL_MEDIUM,
      flex: 1,
    },
    selectedLabelStyle: {
      color: colors.BRAND_UPPER_MEDIUM,
    },
    selectedIndicatorStyle: {
      height: 5,
      width: 5,
      borderRadius: 3,
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
    },
    filterContainerStyle: {flex: 1, padding: 12, overflow: 'hidden'},
    footerContainerStyle: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: 50,
      borderTopWidth: 1,
      borderTopColor: colors.OUTLINE,
      paddingLeft: 12,
      paddingRight: 12,
    },
    modal: {
      sm: {
        container: {
          height: 370,
          backgroundColor: colors.BACKGROUND,
        },
      },
      md: {
        container: {
          height: 400,
          width: 470,
          backgroundColor: colors.BACKGROUND,
          borderRadius: 12,
          ...shadows.L,
        },
      },
    },
    resetButton: OutlineButtonStyle({theme}),
    applyButton: ButtonStyle({theme}),
  };
};

export const AutoCompleteFilterStyles = ({theme}) => {
  const {colors, fonts, icons} = theme;
  const styles = AutoCompleteStyles({theme});
  return {
    ...styles,
    container: {
      ...styles.container,
      minHeight: 36,
      paddingTop: 2,
      paddingBottom: 2,
    },
    floatingContainer: {
      ...styles.floatingContainer,
      top: 8,
    },
    activeFloatingContainer: {
      ...styles.activeFloatingContainer,
      top: 2,
    },
  };
};

export const MultiAutoCompleteFilterStyles = ({theme}) => {
  const {fonts, colors} = theme;
  const styles = AutoCompleteStyles({theme});
  return {
    ...styles,
    input: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      minWidth: 200,
    },
    container: {
      ...styles.container,
      minHeight: 36,
      paddingTop: 6,
      paddingBottom: 6,
    },
    floatingContainer: {
      ...styles.floatingContainer,
      top: 8,
    },
    activeFloatingContainer: {
      ...styles.activeFloatingContainer,
      top: 2,
    },
  };
};

export const PeriodRangeFilterStyles = ({theme}) => {
  const {colors, fonts, shadows} = theme;
  return {
    datePicker: DateInputStyles({theme}),
    container: {
      borderColor: colors.OUTLINE,
    },
    dateRange: {},
    selectedLabelContainer: {
      backgroundColor: colors.SURFACE2,
    },
    selectedLabel: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.ACTION,
    },
    selectedValue: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.SIDE_NAVIGATION,
    },
    prevNextButton: {},
    previousIconStyle: {},
    nextIconStyle: {},
    modal: {
      container: {
        width: 400,
        margin: 10,
        backgroundColor: colors.BACKGROUND,
        ...shadows.L,
      },
    },
    modalContainer: {},
    modalHeader: {
      borderBottomColor: colors.OUTLINE,
    },
    headerTitleContainer: {},
    headerText: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.HEADING1,
    },
    switchLabel: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.ACTION,
    },
    switchInput: {
      track: {
        height: 14,
        width: 26,
      },
      thumb: {
        height: 12,
        width: 12,
      },
    },
    modalBody: {},
    hoverColor: colors.OUTLINE,
    primaryTitle: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.BODY1,
    },
    secondaryTitle: {
      color: colors.NEUTRAL_LOW,
      ...fonts.BODY1,
    },
    yearContainer: {
      borderColor: colors.OUTLINE,
    },
    yearRow: {},
    yearText: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY2,
    },
    yearPrevNextButton: {},
    yearPreviousIconStyle: {},
    yearNextIconStyle: {},
    quarterContainer: {
      backgroundColor: colors.SURFACE3,
    },
    quarterRow: {},
    quarterItemContainer: {},
    quarterLabel: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY2,
    },
    monthWiseContainer: {
      backgroundColor: colors.SURFACE3,
    },
    separator: {
      backgroundColor: colors.OUTLINE,
    },
    monthContainer: {
      backgroundColor: colors.SURFACE3,
    },
    monthItemContainer: {},
    monthLabel: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY2,
    },
    otherContainer: {
      backgroundColor: colors.SURFACE3,
    },
    radioGroup: RadioGroupStyles({theme}),
    modalFooter: {
      borderTopColor: colors.OUTLINE,
    },
    cancelText: {
      color: colors.INFORMATION_HIGH,
      ...fonts.ACTION,
    },
  };
};
