import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import moment from 'moment';
import {TabView} from '../../../components/tab';
import {useInvoke} from '../../../controllers/useInvoke';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {Confirm} from '../../../components/confirm/Confirm';
import {Chip} from '../../../components/chip/Chip';
import {useTheme} from '@unthinkable/react-theme';
import {Image} from '@unthinkable/react-core-components';
import {Switch} from '../../../components/form-editors/switch/Switch';

const filters = {
  repeat: {
    type: 'autoComplete',
    placeholder: 'Select Frequency',
    label: 'Repeat',
    options: ['Daily', 'Weekly', 'Biweekly', 'Monthly', 'Quarterly', 'Yearly'],
    field: 'frequency_mode',
  },
  assigned_to: {
    type: 'autoComplete',
    api: '/usersuggestions',
    field: 'team',
    label: 'Assignee',
    valueField: 'name',
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    placeholder: 'Select Assignee',
  },
  reviewer: {
    type: 'autoComplete',
    api: '/usersuggestions',
    field: 'reviewer',
    label: 'Reviewer',
    valueField: 'name',
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    placeholder: 'Select Reviewer',
  },
};

const EmployeeTeamTaskAssignmentTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
    archivedTab,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'MasterTaskAssignment',
  });

  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'MasterTaskAssignment',
  });

  return (
    <Table
      eventSourceId={['MasterTaskAssignment']}
      api={`/mastertaskassignments`}
      filter={{
        employee_team: params?.employee_team?._id,
        parent_model_relation: 'Lifetime',
      }}
      search={searchValue}
      searchFields={'task'}
      sort={{_id: -1}}
      fields={{
        review_not_required: 1,
        team: {
          name: 1,
          color: 1,
        },
        task: 1,
        type: 1,
        frequency_mode: 1,
        parent_model: {
          label: 1,
        },
        parent_model_relation: 1,
        start_date: 1,
        est_hours: 1,
        recur_count: 1,
        acceptance_criteria: 1,
        due_days: 1,
        reviewer: {
          name: 1,
          color: 1,
        },
        owner_wise_estimates: {
          owner: {
            name: 1,
          },
          est_hours: 1,
          recur_count: 1,
        },
        output_model: {
          name: 1,
          color: 1,
          label: 1,
        },
        primary_source: {
          name: 1,
          color: 1,
          label: 1,
        },
        primary_source_id: {
          name: 1,
        },
        parent_model: {
          label: 1,
        },
        end_date: 1,
        status: 1,
        archive_disabled: 1,
        assignee_mode: 1,
        sub_task_assignment_count: {_id: 1},
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-employee-team-master-task-assignment`, {
          ...params,
          row,
        });
      }}
      columns={[
        {
          header: 'Tasks',
          type: 'text',
          render: ({row}) => {
            let {task, start_date, end_date} = row;
            start_date = start_date
              ? moment(start_date).format('DD MMM YY')
              : void 0;
            end_date = end_date ? moment(end_date).format('DD MMM YY') : void 0;
            return (
              <PrimaryColumnCard
                numberOfLines={2}
                primaryTitle={task}
                items={[
                  {
                    value: start_date ? `Start Date: ${start_date}` : void 0,
                  },
                  {
                    value: end_date ? `End Date: ${end_date}` : void 0,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Configuration',
          type: 'text',
          render: ({row}) => {
            let {frequency_mode, due_days, assignee_mode} = row;

            let assigneeMode =
              assignee_mode &&
              (assignee_mode === 'Common' ? 'Open Task' : 'Assigned Task');

            return (
              <PrimaryColumnCard
                items={[
                  {
                    value: frequency_mode,
                  },
                  {
                    value: assigneeMode,
                  },
                  {
                    value: due_days ? `${due_days} days` : void 0,
                  },
                ]}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Assignees',
          field: 'team',
          type: 'userAvatarGroup',
          width: 110,
          align: 'center',
        },
        {
          header: 'Reviewers',
          field: 'reviewer',
          type: 'userAvatarGroup',
          width: 110,
          align: 'center',
        },
        {
          header: 'Not Archivable',
          width: 100,
          visible: !archivedTab,
          align: 'center',
          render: ({row}) => {
            return (
              <Confirm
                disabled={row?.status !== 'Active'}
                title={
                  row?.archive_disabled
                    ? 'Mark Task Archive Enabled'
                    : 'Mark Task Archive Disabled'
                }
                secondaryTitle={row?.task}
                message={
                  row?.archive_disabled
                    ? 'Tasks of this assignment can be mark archived!'
                    : 'Tasks of this assignment can not be mark archived!'
                }
                onConfirm={() => {
                  if (row?.archive_disabled) {
                    invoke({
                      uri: `/mastertaskassignments/${row?._id}`,
                      props: {
                        archive_disabled: false,
                      },
                    });
                  } else {
                    invoke({
                      uri: `/mastertaskassignments/${row?._id}`,
                      props: {
                        archive_disabled: true,
                      },
                    });
                  }
                }}>
                <Switch value={row?.archive_disabled} />
              </Confirm>
            );
          },
        },
        {
          visible: !params?.parent_task_assignment,
          width: 75,
          render: ({row}) => {
            const {SubTaskBlue} = useTheme('icons');
            const {sub_task_assignment_count} = row;
            if (
              row?.assignee_mode === 'Common' &&
              (!row?.recur_count || row?.recur_count === 1)
            ) {
              return (
                <Chip
                  gap={4}
                  prefix={
                    <Image
                      style={{height: 16, width: 16}}
                      source={SubTaskBlue}
                    />
                  }
                  color={'INFORMATION_LOW'}
                  textColor={'INFORMATION_HIGH'}
                  value={sub_task_assignment_count}
                />
              );
            } else {
              return null;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('sub-task-assignment-table', {
              parent_task_assignment: row,
              ...params,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Mark Archive',
          confirm: {
            title: 'Mark Archive',
            message:
              'Archived Assignments will not be added to workload from upcoming month.',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/mastertaskassignments/${row?._id}`,
              props: {
                status: 'Completed',
              },
            });
          },
          visible: ({row}) =>
            row?.status == 'Active' || row?.status == 'InActive',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/mastertaskassignments/${row?._id}`,
              props: {
                status: 'Active',
                end_date: null,
              },
            });
          },
          visible: ({row}) =>
            row?.status == 'Completed' &&
            params?.parent_task_assignment?.status !== 'Completed',
        },
        {
          title: 'Update Team',
          onPress: ({row}) => {
            navigation.navigate('update-assignment-employee-team', {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Duplicate Task',
          onPress: ({row}) => {
            const {_id, status, ...rest} = row;
            navigation.navigate('add-recurring-task-assignment', {
              ...params,
              row: {...rest},
            });
          },
          visible: ({row}) =>
            row?.status == 'Active' || row?.status == 'InActive',
        },
        {
          title: 'Delete Workload',
          variant: 'error',
          confirm: {
            title: 'Delete Workload',
            message: 'Are you sure you want delete workload for this task?',
          },
          onPress: ({row}) => {
            postInvoke({
              uri: `/deleteWorkloads`,
              method: 'post',
              props: {
                master_task_assignment_id: row?._id,
              },
            });
          },
          visible: ({row}) => row?.status == 'Completed',
        },
      ]}
      {...props}
    />
  );
};

export const RecurringTaskAssignmentTabs = props => {
  const {
    route: {params},
  } = props;

  const {employee_team, parent_task_assignment, thread = {}} = params;
  const threadFilter = thread?._id ? {thread: thread._id} : {};

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {filter: extraFilter} = filterValues;

  let addOnFilter = {
    parent_model_relation: 'Lifetime',
    employee_team: employee_team?._id,
    ...extraFilter,
    ...threadFilter
  };
  if (parent_task_assignment) {
    addOnFilter.parent_task_assignment =
      parent_task_assignment._id || parent_task_assignment;
  } else {
    addOnFilter.parent_task_assignment = null;
  }

  return (
    <TabView
      tabs={{
        active: {
          actions: [
            parent_task_assignment?.status !== 'Completed' && (
              <AddButton
                title={'Task'}
                view={'add-recurring-task-assignment'}
                params={params}
              />
            ),
          ],
          label: 'Active',
          api: '/mastertaskassignments',
          eventSourceId: 'MasterTaskAssignment',
          filter: {
            status: 'Active',
            ...addOnFilter,
          },
          search: searchValue,
          searchFields: 'task',
          view: (
            <EmployeeTeamTaskAssignmentTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Active',
                ...addOnFilter,
              }}
            />
          ),
        },
        completed: {
          label: 'Archived ',
          api: '/mastertaskassignments',
          eventSourceId: 'MasterTaskAssignment',
          filter: {
            status: 'Completed',
            ...addOnFilter,
          },
          search: searchValue,
          searchFields: 'task',
          view: (
            <EmployeeTeamTaskAssignmentTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'Completed',
                ...addOnFilter,
              }}
              archivedTab={true}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[filters.repeat, filters.assigned_to, filters.reviewer]}
        />,
      ]}
      {...props}
    />
  );
};
