import React from 'react';
import {Table} from '../../../components/table/Table';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const CountryTable = props => {
  const {navigation} = props;
  return (
    <Table
    eventSourceId="Country"
    onRowPress={props => {
        navigation.navigate(`country-detail`, {
          ...props,
        });
      }}
      api={`/countries`}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'code',
          header: 'Code',
        },
      ]}
    />
  );
};

export const CountryForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/countries',
    eventSourceId: 'Country',
  });
  return (
    <Form
      api={`/countries/${row?._id}`}
      fields={{
        name: 1,
        code: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
        },
        {
          type: 'text',
          field: 'code',
          label: 'Code',
        },
      ]}
      {...props}
    />
  );
};


export const EditCountryForm = props => {
  return <CountryForm mode="edit" header={'Edit'} {...props} />;
};
