import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Table} from '../../../components/table/Table';

const ScheduleActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 180,
  header: 'Schedule Count',
  align: 'right',
  count_field: 'scheduled_on_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-email-profile-grouped', {
      ...row,
      verified: true,
      status: 'pending',
    });
  },
});
const CompletedActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 180,
  header: 'Completed Count',
  align: 'right',

  count_field: 'completed_on_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-email-profile-grouped', {
      ...row,
      verified: true,
      status: 'completed',
      sent_action: true,
    });
  },
});

const ErrorCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 180,
  header: 'Error Count',
  align: 'right',

  count_field: 'error_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-email-profile-grouped', {
      ...row,
      status: 'error',
    });
  },
});

const InProcessCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 180,
  header: 'In Process Count',
  align: 'right',

  count_field: 'inprocess_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-email-profile-grouped', {
      ...row,
      status: 'inprocess',
    });
  },
});

const EmailProfileList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['profileAdded']}
      api={`/emailprofiles/active`}
      fields={{
        email: 1,
        scheduled_on_count: 1,
        completed_on_count: 1,
      }}
      columns={[
        {
          field: 'email',
          header: 'Email Profile',
          type: 'text',
        },
        ScheduleActionCount({navigation, params}),
        CompletedActionCount({navigation, params}),
        ErrorCount({navigation, params}),
        InProcessCount({navigation, params}),
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('email-profile-edit-form', {row});
          },
        },
      ]}
    />
  );
};

export default EmailProfileList;
