import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const DataEntityViewForm = props => {
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'DataEntityView',
    uri: '/dataentityviews',
  });
  return (
    <Form
      onSubmit={onSubmit}
      layoutFields={[
        {field: 'label', type: 'text', label: 'Label', required: true, size: 6},
        {field: 'view', type: 'text', label: 'View', required: true, size: 6},
        {
          field: 'type',
          type: 'autoComplete',
          label: 'Type',
          options: ['List', 'Form'],
          size: 6,
        },
        {field: 'filter_api', type: 'text', label: 'Filter API', size: 6},
      ]}
      {...props}
    />
  );
};

export const DataEntityViewEditForm = props => {
  const {
    route: {
      params: {
        dataEntityView: {_id},
      },
    },
  } = props;
  return (
    <DataEntityViewForm
      api={`/dataentityviews/${_id}`}
      header="Edit view"
      mode="edit"
      {...props}
    />
  );
};
