import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  Image,
  Platform,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Swiper} from '@unthinkable/react-swiper';
import {LoginFormMobile} from './LoginFormMobile';
import {Loader} from './Loader';
import {theme} from './theme';

const LoginMobile = props => {
  const {
    LoginMobile: {
      Banner1Mobile,
      NextGenIcon,
      Banner2Mobile,
      TrackableIcon,
      loaderIcon,
      containerStyle,
      mainContainerStyle,
      nextIconStyle,
      loaderStyle,
    },
  } = theme;

  const [swiperComplete, setSwiperComplete] = useState(false);

  let images = [
    {mainIcon: Banner1Mobile, textIcon: NextGenIcon},
    {mainIcon: Banner2Mobile, textIcon: TrackableIcon},
  ];

  let screens = [];

  const NextButton = ({clickHandler}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (progress < 100) {
          setProgress(prevProgress => prevProgress + 1);
        } else if (progress === 100) {
          setProgress(0);
        } else {
          clearInterval(interval);
        }
      }, 130);

      return () => clearInterval(interval);
    }, [progress]);

    return (
      <TouchableOpacity
        style={{
          position: Platform.OS === 'web' ? 'fixed' : 'absolute',

          right: 0,
          bottom: 0,
        }}
        onPress={() => {
          clickHandler(+1);
          setProgress(0);
        }}>
        <View style={loaderStyle}>
          <Loader progress={progress} />
        </View>
        <View
          style={{
            ...nextIconStyle,
            position: 'absolute',
          }}>
          <View
            style={{
              height: 100,
              width: 100,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image source={loaderIcon} />
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  images.map((image, index) => {
    screens.push(
      <View
        key={index}
        style={{
          flex: 1,
          width: Dimensions.get('window').width,
        }}>
        <View
          style={{
            backgroundColor: '#ffffff',
            flex: 1,
            overflow: 'hidden',
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
          }}>
          <Image
            source={image?.mainIcon}
            style={{
              width: '100%',
              height: '100%',
            }}
            resizeMode="contain"
          />
        </View>
        <View style={{paddingLeft: 32, paddingTop: 30}}>
          <Image source={image.textIcon} />
        </View>
      </View>,
    );
  });

  return swiperComplete ? (
    <View style={mainContainerStyle}>
      <LoginFormMobile {...props} />
    </View>
  ) : (
    <View style={containerStyle}>
      <Swiper
        buttonGroupStyle={{position: 'absolute'}}
        paginationContainerStyle={{
          alignItems: 'flex-start',
          paddingLeft: 16,
          paddingTop: 35,
          paddingBottom: 35,
          justifyContent: 'center',
        }}
        setSwiperComplete={setSwiperComplete}
        width={Dimensions.get('window').width}
        showsPagination
        showsScrollBar={false}
        showsButtons={true}
        renderNextButtonOnly={true}
        renderNextButton={NextButton}
        autoPlay={true}
        loop={false}
        autoPlayDuration={15000}>
        {screens}
      </Swiper>
    </View>
  );
};

export default LoginMobile;
