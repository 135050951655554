import {getCurrentMonth} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {GroupBy} from '../../../components/table/GroupBy';
import {useFilter} from '../../../controllers/useFilter';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useAppStateContext} from '../../../providers/AppState';
import {
  PendingInvoiceAllTandMAndFixedTable,
  PendingInvoiceAllTandMAndFixedTableOvedue,
  PendingInvoiceFixedTable,
  PendingInvoiceFixedTableAm,
  PendingInvoiceSupportTable,
  PendingInvoiceTAndMTable,
  PendingInvoiceTAndMTableAm,
} from './PendingInvoiceTable';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },

    {
      type: 'autoComplete',
      label: 'Order',
      api: '/orders',
      placeholder: 'Select',
      suggestionField: 'order_number',
      valueField: 'order_number',
      field: 'order',
    },
    {
      label: 'Advance Due',
      type: 'date',
      field: 'advance_due_date',
    },
    {
      label: 'Due Date',
      type: 'date',
      field: 'due_date',
    },
    {
      type: 'autoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'organization',
    },
    {
      type: 'multiAutoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'autoComplete',
      label: 'Locations',
      api: '/offices',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'location',
    },
    tab === 'All' && {
      type: 'autoComplete',
      label: 'Order Type',
      placeholder: 'Select',
      options: [
        {label: 'T&M', value: 'r'},
        {label: 'Fixed', value: 'ot'},
      ],
      field: 'order_type',
      suggestionField: 'label',
      keyField: 'value',
      valueField: 'label',
    },
    {
      type: 'autoComplete',
      label: 'CSM',
      api: '/employeeSuggestions',
      searchFields: ['name', 'official_email_id', 'employee_code'],
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'delivery_owner_id',
    },
    {
      type: 'multiAutoComplete',
      label: 'AM',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      searchFields: ['name', 'official_email_id', 'employee_code'],
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'account_owner_id',
    },
  ];
};

const commonFilters = ({
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'All',
  params = {},
  groupBy = {},
  showGroupBy = false,
}) => {
  return [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search Invoice Number"
    />,
    showGroupBy && <GroupBy {...groupBy} />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
  ];
};

export const PendingInvoiceTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const groupBy = useGroupBy({
    options: [
      {
        label: 'AM',
        field: 'account_owner_id',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const tabs = {
    allDeliveries: {
      label: 'All',
      view: (
        <PendingInvoiceAllTandMAndFixedTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      limit: 2000,
      eventSourceId: 'revenue-invoice',
      api: `/pending-tandm-and-fixed-invoices`,
      addOnFilter: {...filter},
      search: searchValue,
      searchFields: 'delivery_number',
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    tAndMPending: {
      label: 'T&M',
      view: (
        <PendingInvoiceTAndMTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-tandm-invoices`,
      addOnFilter: {...filter},
      search: searchValue,
      searchFields: 'delivery_number',
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'T&M',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    fixedPending: {
      label: 'Fixed',
      view: (
        <PendingInvoiceFixedTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-fixed-invoices`,
      addOnFilter: {...filter},
      search: searchValue,
      searchFields: 'delivery_number',
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Fixed',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    supportPending: {
      label: 'Support',
      view: (
        <PendingInvoiceSupportTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-support-invoices`,
      search: searchValue,
      searchFields: 'delivery_number',
      addOnFilter: {...filter},
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Support',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },
    overdueDeliveries: {
      label: 'Overdue',
      view: (
        <PendingInvoiceAllTandMAndFixedTableOvedue
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-tandm-and-fixed-invoices/overdue`,
      addOnFilter: {...filter},
      search: searchValue,
      searchFields: 'delivery_number',
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const PendingInvoiceTabAm = props => {
  let {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {period: params.period || getCurrentMonth()},
  });
  let {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const groupBy = useGroupBy({
    options: [
      // {
      //   label: 'AM',
      //   field: 'account_owner_id',
      //   aggregates: {
      //     base_amount: 'sum',
      //   },
      //   groupRow: {
      //     data: '_children',
      //     defaultExpanded: true,
      //     leftContent: ({row}) => {
      //       return (
      //         <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
      //           <GroupContentItem value={row.name} />
      //           <GroupContentItem value={row.base_amount.toFixed(2)} />
      //         </RowWithSeparator>
      //       );
      //     },
      //   },
      // },
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const tabs = {
    allDeliveries: {
      label: 'All',
      view: (
        <PendingInvoiceAllTandMAndFixedTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            $or: [
              {account_owner_id: employeeId},
              {
                account_owner_id: {$in: employeeTeam},
              },
            ],
            ...filter,
          }}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-tandm-and-fixed-invoices`,
      addOnFilter: {
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {$in: employeeTeam},
          },
        ],
        ...filter,
      },
      search: searchValue,
      searchFields: 'delivery_number',
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    tAndMPending: {
      label: 'T&M',
      view: (
        <PendingInvoiceTAndMTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-tandm-invoices`,
      addOnFilter: {
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {$in: employeeTeam},
          },
        ],
        ...filter,
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'T&M',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    fixedPending: {
      label: 'Fixed',
      view: (
        <PendingInvoiceFixedTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-fixed-invoices`,
      addOnFilter: {
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {$in: employeeTeam},
          },
        ],
        ...filter,
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Fixed',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    overdueDeliveries: {
      label: 'Overdue',
      view: (
        <PendingInvoiceAllTandMAndFixedTableOvedue
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            $or: [
              {account_owner_id: employeeId},
              {
                account_owner_id: {$in: employeeTeam},
              },
            ],
            ...filter,
          }}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-tandm-and-fixed-invoices/overdue`,
      addOnFilter: {
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {$in: employeeTeam},
          },
        ],
        ...filter,
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Overdue',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
