import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const ExpenseApproverLimit = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/expenseapproverlimits',
    eventSourceId: 'ExpenseApproverLimit',
  });
  return (
    <Form
      api={`/expenseapproverlimits/${row?._id}`}
      onSubmit={onSubmit}
      fields={{
        employee: {name: 1},
        amount: 1,
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          type: 'autoComplete',
          field: 'employee',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddExpenseApproverLimit = props => {
  return (
    <ExpenseApproverLimit header="Add Expense Approver Limit" {...props} />
  );
};

export const EditExpenseApproverLimit = props => {
  return (
    <ExpenseApproverLimit
      mode="edit"
      header={'Update Expense Approver Limit'}
      {...props}
    />
  );
};
