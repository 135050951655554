import moment from 'moment';
import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const SalaryForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  return (
    <Form
      api={`/monthlyAttendance/all/${row?._id}`}
      header={{title: `${row?.month_id?.name} ${row?.year_id?.name}`}}
      layoutFields={[
        {
          fields: [
            {
              label: 'Total Cost To Company (CTC)',
              field: 'gross_amount',
              type: 'text',
              size: 6,
            },
            {
              label: 'Total Fixed Pay',
              field: 'payable_amount',
              type: 'text',
              size: 6,
            },
            {
              label: 'Latest Salary Revision Date',
              field: 'invoice_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Salary Component',
              fields: [
                {
                  field: 'salary_details.salary_component_details',
                  nested: true,
                  render: props => (
                    <NestedTable
                      readOnly={true}
                      {...props}
                      columns={[
                        {
                          type: 'text',
                          field: 'salary_component_id.name',
                          header: 'Salary Component',
                          minWidth: 200,
                        },
                        {
                          type: 'text',
                          field: 'gross_amount',
                          header: 'Gross',
                          width: 150,
                        },
                        {
                          type: 'text',
                          field: 'payable_amount',
                          header: 'payable',
                          width: 150,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const SalaryDetailForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  return <SalaryForm readOnly={true} mode="edit" {...props} />;
};

const IncentiveForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  return (
    <Form
      api={`/getPerformamceSalaryDetails/${row?._id}`}
      header={'Showing all component of Incentive Salary'}
      layoutFields={[
        {
          label: 'Salary Component',
          fields: [
            {
              field: 'salary_details.salary_component_details',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  columns={[
                    {
                      type: 'text',
                      field: 'salary_component_id.name',
                      header: 'Salary component',
                      minWidth: 200,
                    },
                    {
                      type: 'text',
                      header: 'Gross',
                      field: 'gross_amount',
                      width: 150,
                    },
                    {
                      type: 'text',
                      header: 'Payable',
                      field: 'payable_amount',
                      width: 150,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const IncentiveDetailForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  return <IncentiveForm readOnly={true} mode="edit" {...props} />;
};

const BonusForm = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  return (
    <Form
      api={`/getFixedSalaryDetails/${row?._id}`} 
      header={'Showing all component of Bonus salary'}
      layoutFields={[
        {
          label: 'Salary Component',
          fields: [
            {
              field: 'salary_details.salary_component_details',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  columns={[
                    {
                      header: 'Salary component',
                      field: 'salary_component_id.name',
                      type: 'text',
                      minWidth: 200,
                    },
                    {
                      field: 'gross_amount',
                      header: 'Gross',
                      type: 'text',
                      width: 150,
                    },
                    {
                      header: 'Payable',
                      field: 'payable_amount',
                      type: 'text',
                      width: 150,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const BonusDetailForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  return (
    <BonusForm
      readOnly={true}
      mode="edit"
      fields={{
        salary_details: {
          name: 1,
          type: 1,
          invoice_date: 1,
          gross_salary: 1,
          payable_salary: 1,
          salary_type: 1,
          salary_generation_type: 1,
          salary_component_details: {salary_component_id: {_id: 1, name: 1}},
        },
      }}
      //   layoutFields={SalaryDetailFields}
      {...props}
    />
  );
};

const CompensationStructure = props => {
  const {
    route: {params},
    title,
    secondaryTitle,
  } = props;
  let {row} = params;
  return (
    <Form
      api={`/employeesalarycomponent/${row?._id}`}
      header={{
        title,
        secondaryTitle: `${moment(row.from_date).format(
          'DD MMM YY',
        )} - ${moment(row.to_date).format('DD MMM YY')}`,
      }}
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee.name',
          type: 'text',
          size: 6,
        },
        {
          label: 'Tax Amount',
          type: 'text',
          field: 'total_deductions_salary',
          size: 6,
        },
        // {
        //   label: 'From Date',
        //   field: 'from_date',
        //   type: 'date',
        //   size: 6,
        // },
        {
          label: 'Package review date',
          type: 'date',
          field: 'package_review_date',
          size: 6,
        },
        {
          label: 'CTC (Per Month)',
          field: 'ctc_amount',
          type: 'text',
          size: 6,
        },
        {
          label: 'Fixed',
          field: 'fixed_salary',
          type: 'text',
          size: 6,
        },
        {
          label: 'Incentive',
          type: 'text',
          field: 'incentive_salary',
          size: 6,
        },
        {
          label: 'Salary',
          fields: [
            {
              field: 'attendance_base_components',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  columns={[
                    {
                      header: 'Salary Component',
                      field: 'salary_component_id.name',
                      type: 'text',
                    },
                    {
                      header: 'From date',
                      field: 'from_date',
                      type: 'date',
                    },
                    {
                      header: 'To date',
                      field: 'to_date',
                      type: 'date',
                    },
                    {
                      header: 'Amount',
                      field: 'amount',
                      toFixed: 2,
                      type: 'number',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Performance',
          fields: [
            {
              field: 'performance_base_components',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  columns={[
                    {
                      header: 'Salary Component',
                      field: 'salary_component_id.name',
                      type: 'text',
                    },
                    {
                      header: 'From date',
                      field: 'from_date',
                      type: 'date',
                    },
                    {
                      header: 'To date',
                      field: 'to_date',
                      type: 'date',
                    },
                    {
                      header: 'Amount',
                      field: 'amount',
                      type: 'number',
                      toFixed: 2,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Deduction',
          fields: [
            {
              field: 'deductions_components',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  columns={[
                    {
                      header: 'Salary Component',
                      field: 'salary_component_id.name',
                      type: 'text',
                    },
                    {
                      header: 'From date',
                      field: 'from_date',
                      type: 'date',
                    },
                    {
                      header: 'To date',
                      field: 'to_date',
                      type: 'date',
                    },
                    {
                      header: 'Amount',
                      field: 'amount',
                      type: 'number',
                      toFixed: 2,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Bonus',
          fields: [
            {
              field: 'fix_base_components',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  columns={[
                    {
                      header: 'Salary Component',
                      field: 'salary_component_id.name',
                      type: 'text',
                    },
                    {
                      header: 'From date',
                      field: 'from_date',
                      type: 'date',
                    },
                    {
                      header: 'To date',
                      field: 'to_date',
                      type: 'date',
                    },
                    {
                      header: 'Amount',
                      field: 'amount',
                      type: 'number',
                      toFixed: 2,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const CompensationStructureDetailForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  return (
    <CompensationStructure
      readOnly={true}
      mode="edit"
      {...props}
      title="Compensation structure details"
      // secondaryTitle="hello"
    />
  );
};

const InvoiceForm = props => {
  const {
    route: {params},
    title,
  } = props;
  let {row} = params;
  return (
    <Form
      api={`/vendorinvoice/${row?._id}`}
      header={{
        title,
        secondaryTitle: `${moment(row.from_date).format('DD MMM YY')}`,
      }}
      layoutFields={[
        {
          fields: [
            {
              label: 'Payment date',
              field: 'invoice_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Tax amount',
              type: 'date',
              field: 'deduction_amount',
              size: 6,
            },
            {
              label: 'Net amount',
              type: 'text',
              field: 'payable_amount',
              size: 6,
            },
            {
              label: 'Gross amount',
              type: 'text',
              field: 'exp_amount',
              size: 6,
            },
            {
              fields: [
                {
                  field: 'exp_details',
                  nested: true,
                  render: props => (
                    <NestedTable
                      readOnly={true}
                      {...props}
                      columns={[
                        {
                          header: 'Purchase',
                          field: 'purchase_category.name',
                          type: 'text',
                        },
                        {
                          header: 'Quantity',
                          field: 'quantity',
                          type: 'text',
                        },
                        {
                          header: 'Rate',
                          field: 'rate',
                          type: 'text',
                        },
                        {
                          header: 'Amount',
                          field: 'purchase_amount',
                          type: 'text',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const InvoiceDetailForm = props => {
  let {
    route: {
      params: {row},
    },
  } = props;
  return (
    <InvoiceForm
      readOnly={true}
      mode="edit"
      {...props}
      title={'Invoice details'}
    />
  );
};

export {
  SalaryDetailForm,
  IncentiveDetailForm,
  BonusDetailForm,
  InvoiceDetailForm,
  CompensationStructureDetailForm,
};
