import React from 'react';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';

import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

const GrowthAndBillingTAndMTable = props => {
  const {
    route: {params} = {},
    searchValue,
    addOnFilter = {},
    period = {},
  } = props;
  return (
    <Table
      api={`/projectResourceMarkBackup`}
      search={searchValue}
      addOnFilter={addOnFilter}
      params={{...params, period}}
      variant={'bordered'}
      limit={2000}
      columns={[
        {
          header: 'Customer',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.customer?.name}
                items={[
                  {
                    value: row?.delivery_number,
                  },
                ]}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Potential',
          field: 'variable_potential',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
        },
        {
          header: 'Pre',
          field: 'previous_month_billing_hrs',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
        },
        // {
        //   header: 'Pre Diff',
        //   field: 'previous_month_diff',
        //   type: 'number',
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   aggregate: true,
        // },
        {
          header: 'Current',
          field: 'current_month_billing_hrs',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
        },
        {
          header: 'Pot. Diff',
          field: 'variable_potential_diff',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
        },
      ]}
    />
  );
};

export const GrowthAndBillingComparsionTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: filterParam = {}} = filterValues || {};
  const {period = {}} = filterParam;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    growth_billing_comparsion_tandm: {
      label: 'T&M',
      view: (
        <GrowthAndBillingTAndMTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{...filter, order_type: 'r'}}
          period={period}
          filterParam={filterParam}
        />
      ),
      eventSourceId: 'GrowthBillingComparsionT&M',
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          skipQuarter
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/projectResourceMarkBackupReoprtDownload`,
              props: {
                ...params,
                period,
                addOnFilter: {
                  ...filter,
                  order_type: 'r',
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
