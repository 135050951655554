import React from 'react';
import {UnderlineButtonStyle} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {Button} from '@unthinkable/react-button';

export const UnderlineButton = props => {
  const style = useStyles(UnderlineButtonStyle);

  return <Button {...props} styles={style} />;
};
