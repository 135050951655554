import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import { View } from '@unthinkable/react-core-components';
import { OutlineButton } from '../../../components/button/OutlineButton';

export const FinetuneAddDocument = props => {
  const {
    route: {params},
    navigation,
    mode,
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/finetunedocuments',
    eventSourceId: 'addDocument',
    ...props,
  });

  let {row} = params;

  return (
    <Form
      mode={mode}
      fields={{
        messages: 1,
      }}
      onSubmit={onSubmit}
      defaultValues={{
        usecase_id: row._id,
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Messages',
          field: 'messages',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  field: 'role',
                  placeholder: 'role',
                  header: 'Role',
                  type: 'text',
                },
                {
                  field: 'content',
                  placeholder: 'content',
                  header: 'Content',
                  type: 'textArea',
                },
                {
                  render: props => {
                    let {row} = props || {};
                    let {role} = row || {};
                    return role === 'assistant' ? (
                      <View style={{alignItems: 'flex-end'}}>
                        <OutlineButton
                          text={'view'}
                          onPress={() => {
                            navigation.navigate('view-assitant-response', {
                              row,
                            });
                          }}
                        />
                      </View>
                    ) : null;
                  },
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};

export const FinetuneEditDocument = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <FinetuneAddDocument
      mode="edit"
      api={`/finetunedocuments/${_id}`}
      {...props}
    />
  );
};
