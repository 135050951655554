import React, {useState} from 'react';
import {FeatureTypeDesignPreviewer} from './FeatureTypeDesignPreviewer';
import {useFetchData} from '../../../controllers/useFetchData';

export const FeatureMultipleTypesImagePreviewer = props => {
  const {
    route: {params: {row, feature} = {}},
  } = props;

  const {data = []} = useFetchData({
    api: `/features/${row._id}/designs`,
  });

  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);
  const typesCount = data?.length;
  return (
    <FeatureTypeDesignPreviewer
      typesCount={typesCount}
      type={data ? data[currentTypeIndex] : void 0}
      showTypeSelector={true}
      currentTypeIndex={currentTypeIndex}
      setCurrentTypeIndex={setCurrentTypeIndex}
      feature={feature}
    />
  );
};
