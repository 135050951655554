import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {Row} from '@unthinkable/react-core-components';
import {LinkRenderer} from '../../../components/form/Renderers';
import {useTheme} from '@unthinkable/react-theme';
import {FormHeader} from '../../../components/form/FormHeaders';

const libraryWiseTemplate = {
  'nodejs-mongoose': [
    'Authentication',
    // 'Role',
    'Decryption',
    'Route Versioning',
    'Test Cases',
    'Activity Logs',
    'Caching',
    // 'Socket',
    'File Upload/Download',
    'Api Documentation (Swagger)',
    // 'DbConnect',
    'Transaction',
  ],
  // react: ['Authentication', 'Localization', 'Encryption'],
  // reactNative: ['Authentication', 'Localization', 'Encryption'],
  // 'react-reactNative': ['Authentication', 'Localization', 'Encryption'],
};

const components = [
  'Button',
  'Avatar',
  // 'Chip',
  'Table',
  'Form',
  'Text Input',
  // 'Checkbox',
  // 'AutoComplete',
  'Modal',
  'Toast',
];

export const repositoryFrameworks = {
  JavaScript: {
    Frontend: [
      'React',
      'React Native',
      'React & React Native',
      'Angular',
      'Vue',
      'Next.js',
    ],
    'UI Components': [
      'React',
      'React Native',
      'React & React Native',
      'Angular',
      'Vue',
      'Next.js',
    ],
    Backend: ['Node.js'],
  },
  TypeScript: {
    Frontend: [
      'React',
      'React Native',
      'React & React Native',
      'Angular',
      'Vue',
      'Next.js',
    ],
    'UI Components': [
      'React',
      'React Native',
      'React & React Native',
      'Angular',
      'Vue',
      'Next.js',
    ],
    Backend: ['Node.js'],
  },
  Python: {
    Frontend: ['Django', 'Flask', 'Dash'],
    'UI Components': ['Django', 'Flask', 'Dash'],
    Backend: ['Django', 'Flask', 'FastAPI'],
  },
  Java: {
    Frontend: ['GWT', 'JSF'],
    'UI Components': ['GWT', 'JSF'],
    Backend: ['Spring Boot', 'Spring MVC', 'Hibernate', 'Quarkus'],
  },
};

const dataServiceLibraries = {
  MongoDB: ['Mongoose', 'DBConnect'],
  PostgreSQL: ['Sequelize', 'TypeORM', 'Knex'],
  MySQL: ['Sequelize', 'TypeORM', 'Knex'],
};

const CreateRepositoryAction = ({type, workspace = 'daffodilsoftware'}) => {
  let repositoryUrl = void 0;
  if (type === 'bitbucket') {
    repositoryUrl = `https://bitbucket.org/${workspace}/workspace/create/repository`;
  } else if (type === 'github') {
    repositoryUrl = 'https://github.com/new';
  }
  if (!repositoryUrl) {
    return null;
  }
  return (
    <Row style={{flex: 1, paddingTop: 16, justifyContent: 'flex-end'}}>
      <LinkRenderer linkText="Create Repository" value={repositoryUrl} />
    </Row>
  );
};

const RepositoryAccessToken = ({
  type,
  workspace = 'daffodilsoftware',
  repository,
  icon,
}) => {
  let repositoryUrl = void 0;
  if (type === 'bitbucket') {
    repositoryUrl = `https://bitbucket.org/${workspace}/${repository}/admin/access-tokens`;
  } else if (type === 'github') {
    repositoryUrl = 'https://github.com/settings/personal-access-tokens/new';
  }

  if (!repositoryUrl) {
    return null;
  }

  return (
    <Row style={{flex: 1, paddingTop: 16, justifyContent: 'flex-end'}}>
      <LinkRenderer value={repositoryUrl} icon={icon} />
    </Row>
  );
};

const isJenkinDeploymentPlatform = deployment_platform => {
  return deployment_platform?.platform === 'Jenkins';
};

const RepositoryForm = props => {
  const {
    navigation,
    route: {params = {}},
    mode,
  } = props;

  const {
    project,
    repository,
    type,
    is_deployable = true,
    vcs,
    searchValue,
    afterSubmit,
    is_dependent_repository,
    category,
  } = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Repository',
    uri: '/repositories',
    ...props,
  });

  const defaultValues = {
    root_folder_path: '/src',
    type,
    is_deployable,
    vcs,
    repository: searchValue,
  };

  if (category) {
    defaultValues.category = category;
  } else {
    defaultValues.project_id = project;
  }

  if (type === 'Frontend') {
    defaultValues.screens_folder_path = '/modules/{module-name}/screens';
  } else if (type === 'Backend') {
    defaultValues.api_folder_path = '/modules/{module-name}/controllers';
    defaultValues.schema_folder_path = '/modules/{module-name}/models';
  }

  const {ArrowSquareOut} = useTheme('icons');

  return (
    <Form
      api={`/repositories/${repository?._id}`}
      afterSubmit={afterSubmit}
      fields={{
        vcs: {type: 1, workspace: 1},
        type: 1,
        repository: 1,
        project_id: 1,
        frameworks: 1,
        language: 1,
        framework_version: 1,
        database_id: {database: 1},
        db_service_library: 1,
        root_folder_path: 1,
        screens_folder_path: 1,
        schema_folder_path: 1,
        api_folder_path: 1,
        documentation_file_path: 1,
        dependent_repositories: {repository: 1},
        db_service_library: 1,
        app_name: 1,
        package_name: 1,
        is_deployable: 1,
        url: 1,
        destination_folder: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'VCS',
          type: 'autoComplete',
          api: `/projectversioncontrols`,
          field: 'vcs',
          suggestionField: 'type',
          secondarySuggestionField: 'workspace',
          valueField: 'type',
          filter: {project_id: project?._id},
          fields: {
            type: 1,
            workspace: 1,
          },
          required: true,
          size: 6,
          helperText: `VCS linked to your project (e.g., GitHub, Bitbucket)`,
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('add-vcs', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
        },
        {
          render: (_, {values}) => {
            let {type, workspace} = values?.vcs || {};
            return <CreateRepositoryAction type={type} workspace={workspace} />;
          },
          size: 6,
        },
        {
          label: 'Name',
          type: 'text',
          field: 'repository',
          required: true,
          helperText: `Use the same repository name as in your VCS, e.g., 'my-repo'.`,
          size: 6,
        },
        {
          label: 'Type',
          field: 'type',
          type: 'autoComplete',
          required: true,
          options: ['Frontend', 'Backend'],
          onChangeValue: (value, e, {setFieldValue}) => {
            [
              'frameworks',
              'framework_version',
              'app_name',
              'package_name',
            ].forEach(doc => {
              setFieldValue(doc, void 0);
            });
            if (value === 'Frontend') {
              setFieldValue(
                'screens_folder_path',
                '/modules/{module-name}/screens',
              );
            } else if (value === 'Backend') {
              setFieldValue(
                'api_folder_path',
                '/modules/{module-name}/controllers',
              );
              setFieldValue(
                'schema_folder_path',
                '/modules/{module-name}/models',
              );
            }
          },
          size: 6,
        },
        {
          label: 'Repository Url',
          field: 'url',
          // helperText:
          //   'Note: https://monu_daffodilsw@bitbucket.org/daffodilsoftware/locomo-ui-v6.git',
          type: 'text',
        },
        [
          {
            label: 'Access Token',
            type: 'text',
            field: 'access_token',
            required: true,
            visible: ({values}) => {
              if (mode !== 'edit' && values?.vcs?.type === 'github') {
                return true;
              }
              return false;
            },
            helperText:
              'Note: Grant permissions for Commit statuses, Contents, Pull requests, Webhooks. Include README.',
            size: 11,
          },
          {
            label: 'Access Token',
            type: 'text',
            field: 'access_token',
            required: true,
            visible: ({values}) => {
              if (mode !== 'edit' && values?.vcs?.type === 'bitbucket') {
                return true;
              }
              return false;
            },
            helperText:
              'Note: Grant write permissions for Repositories, Pull requests, Webhooks, Pipelines. ',
            size: 11,
          },
          {
            render: (_, {values}) => {
              const {vcs: {type, workspace} = {}, repository} = values || {};
              return (
                <RepositoryAccessToken
                  type={type}
                  workspace={workspace}
                  repository={repository}
                  icon={ArrowSquareOut}
                />
              );
            },
            size: 1,
            visible: ({values}) => {
              if (mode !== 'edit' && values?.vcs && values.repository) {
                return true;
              }
              return false;
            },
          },
        ],
        {
          label: 'Dependent Repositories',
          type: 'multiAutoComplete',
          field: 'dependent_repositories',
          api: `/projectRepositories`,
          size: 10,
          visible: () => !is_dependent_repository && !category,
          filter: ({values}) => {
            const filter = {
              project_id: project?._id,
            };
            if (values._id) {
              filter['_id'] = {$ne: values._id};
            }
            return filter;
          },
          suggestionField: 'repository',
          valueField: 'repository',
          onCreate: ({form: {values}, searchValue, onChange}) => {
            const {type, vcs} = values;
            navigation.navigate(`add-repository-sec`, {
              ...params,
              type,
              vcs,
              is_deployable: false,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
              is_dependent_repository: true,
            });
          },
        },
        {
          label: 'Destination Path',
          visible: is_dependent_repository,
          type: 'text',
          field: 'destination_folder',
        },
        {
          label: 'Language & Frameworks',
          fields: [
            [
              {
                label: 'Language',
                type: 'autoComplete',
                field: 'language',
                options: ['JavaScript', 'TypeScript', 'Python', 'Java'],
                size: 4,
                onChangeValue: (value, e, {setFieldValue}) => {
                  setFieldValue('frameworks', void 0);
                  setFieldValue('framework_version', void 0);
                },
              },
              {
                label: 'Frameworks/Libraries',
                type: 'multiAutoComplete',
                field: 'frameworks',
                options: ({values}) => {
                  if (values?.language && values?.type) {
                    return repositoryFrameworks?.[values?.language][
                      values?.type
                    ];
                  }
                  return [];
                },
                visible: ({values}) => !!values?.language,
                size: 4,
              },
              {
                label: 'Framework Version',
                type: 'text',
                field: 'framework_version',
                visible: ({values}) => !!values?.frameworks,
                size: 4,
              },
            ],
            // {
            //   label: 'Boilerplate Information',
            //   fields: [
            //     {
            //       label: 'Boilerplate Name',
            //       type: 'autoComplete',
            //       field: 'boilerplate_repository_id',
            //       api: `/boilerplateRepositories`,
            //       filter: ({values}) => {
            //         return {
            //           repository_type: values.type,
            //         };
            //       },
            //       suggestionField: 'label',
            //       secondarySuggestionField: 'description',
            //       valueField: 'label',
            //     },
            //     {
            //       label: 'App Name',
            //       field: 'app_name',
            //       type: 'text',
            //       size: 6,
            //       validate: (field, {values}) => {
            //         if (/[*#&!()]/.test(field)) {
            //           return 'The name should not contain special characters.';
            //         }
            //       },
            //       required: true,
            //       visible: ({values}) =>
            //         values.boilerplate_repository_id &&
            //         ['JavaScript', 'TypeScript'].includes(values.language),
            //       helperText: `App Name is your project name which would be reflect in your package.json file.`,
            //     },
            //     {
            //       label: 'Package Name',
            //       field: 'package_name',
            //       type: 'text',
            //       size: 6,
            //       validate: (field, {values}) => {
            //         if (values.frameworks === 'react') {
            //           if (/[A-Z-*#&!()]/.test(field)) {
            //             return 'The name should not contain capital letters and special characters.';
            //           }
            //         }
            //         if (values.frameworks === 'reactNative') {
            //           if (/[0-9-*#&!()]/.test(field)) {
            //             return 'The name should not contain number and special characters.';
            //           }
            //         }
            //       },
            //       required: true,
            //       visible: ({values}) => {
            //         return (
            //           values.boilerplate_repository_id &&
            //           ['React Native', 'React & React Native'].includes(
            //             values.boilerplate_repository_id.label,
            //           )
            //         );
            //       },
            //       placeholder: 'com.example.myapp',
            //       helperText: `Enter a unique Bundle ID in reverse domain format (e.g., com.example.myapp) to identify your app on app stores.`,
            //     },
            //   ],
            // },
          ],
        },
        {
          visible: ({values}) =>
            values.type === 'Backend' && !is_dependent_repository && !category,
          label: 'Database & Services',
          fields: [
            {
              label: 'Database',
              type: 'autoComplete',
              field: 'database_id',
              api: `/projectDatabases`,
              filter: {
                project_id: project?._id,
              },
              suggestionField: 'database',
              secondarySuggestionField: 'version',
              valueField: 'database',
              size: 6,
              onChangeValue: (value, e, {setFieldValue}) => {
                setFieldValue('db_service_library', void 0);
              },
              onCreate: () => {
                navigation.navigate('project-databases', {
                  ...params,
                });
              },
            },
            {
              label: 'Data Service Library (ORM)',
              type: 'autoComplete',
              field: 'db_service_library',
              options: ({values}) => {
                let databaseType = values?.database_id?.database;
                return (
                  (databaseType && dataServiceLibraries[databaseType]) || []
                );
              },
              size: 6,
            },
          ],
        },

        {
          defaultHidden: true,
          label: 'Paths',
          visible: !is_dependent_repository && !category,
          fields: [
            {
              type: 'text',
              label: 'Root Folder',
              field: 'root_folder_path',
              size: 6,
              helperText: 'Path to root folder, e.g., /src',
            },
            // {
            //   type: 'text',
            //   label: 'Documentation File',
            //   field: 'documentation_file_path',
            //   size: 6,
            //   helperText:
            //     'Path to the documentation JSON file, e.g., /docs/documentation.json',
            // },
            {
              type: 'text',
              label: 'Screens Folder',
              field: 'screens_folder_path',
              size: 6,
              visible: ({values: {type}}) => type === 'Frontend',
              helperText:
                'Generic path to screens folder after the root, e.g., /modules/{module-name}/screens',
            },
            {
              type: 'text',
              label: 'Schema Folder',
              field: 'schema_folder_path',
              size: 6,
              visible: ({values: {type}}) => type === 'Backend',
              helperText:
                'Generic path to schemas folder after the root, e.g., /modules/{module-name}/models',
            },
            {
              type: 'text',
              label: 'Controller Folder',
              field: 'api_folder_path',
              size: 6,
              visible: ({values: {type}}) => type === 'Backend',
              helperText:
                'Generic path to controllers folder after the root, e.g., /modules/{module-name}/controllers',
            },
          ],
        },
        {
          label: 'Deployable',
          type: 'switch',
          field: 'is_deployable',
          visible: !category,
        },
      ]}
      {...props}
    />
  );
};

const RepositoryDeploymentForm = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;

  const {project, repository} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: ['Repository', 'RepositoryEnvironmentConfigurations'],
    uri: '/repositories',
    ...props,
  });

  const defaultValues = {
    project_id: project,
  };

  return (
    <Form
      api={`/repositories/${repository?._id}`}
      fields={{
        deployment_platform: {
          platform: 1,
          url: 1,
        },
        url: 1,
        jenkins_user_credential_id: 1,
        jenkins_user_credential_name: 1,
        jenkins_user_credential_value: 1,
        jenkins_user_credential_desc: 1,
        jenkins_domain: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Platform',
          type: 'autoComplete',
          field: 'deployment_platform',
          api: `/deploymentplatforms`,
          suggestionField: 'platform',
          secondarySuggestionField: 'url',
          valueField: 'platform',
          filter: {project_id: project?._id},
          helperText:
            'Select the platform to automate your deployment process.',
          size: 6,
          fields: {
            platform: 1,
            url: 1,
          },
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('deployment-platform-add-form', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
          onChangeValue: (value, e, {setFieldValue}) => {
            if (isJenkinDeploymentPlatform(value)) {
              setFieldValue('jenkins_domain', 'system');
            }
          },
        },
        // {
        //   type: 'autoComplete',
        //   field: 'jenkins_domain',
        //   options: [
        //     {value: 'system', label: 'System'},
        //     {value: 'folder', label: 'Folder'},
        //   ],
        //   suggestionField: 'label',
        //   valueField: 'label',
        //   keyField: 'value',
        //   label: 'Domain Scope',
        //   required: true,
        //   size: 6,
        //   visible: ({values}) =>
        //     isJenkinDeploymentPlatform(values?.deployment_platform),
        //   helperText: `Select 'System' for settings that apply to the entire Jenkins instance or 'Folder' to limit the scope to specific projects.`,
        // },
        // {
        //   label: 'Jenkins Credentials (Token & Repository Clone Access)',
        //   visible: ({values}) =>
        //     isJenkinDeploymentPlatform(values?.deployment_platform),
        //   fields: [
        //     {
        //       label: 'Repository Url',
        //       type: 'text',
        //       field: 'url',
        //       required: true,
        //       size: 6,
        //       helperText: `Enter the Git repository URL for cloning. Exclude credentials for security.Format: https://user-name@bitbucket.org/namespace/repository.git`,
        //     },
        //     {
        //       label: 'Token ID',
        //       type: 'text',
        //       field: 'jenkins_user_credential_id',
        //       required: true,
        //       size: 6,
        //       helperText: 'Jenkins Unique API token ID for secure integration',
        //     },
        //     {
        //       label: 'User Name',
        //       type: 'text',
        //       field: 'jenkins_user_credential_name',
        //       required: true,
        //       size: 6,
        //       helperText: 'Username for the repository with clone permissions.',
        //     },
        //     {
        //       label: 'Access Token',
        //       type: 'password',
        //       field: 'jenkins_user_credential_value',
        //       required: true,
        //       size: 6,
        //       helperText: 'Access token associated with the username.',
        //     },
        //     {
        //       label: 'Description (Optional)',
        //       type: 'textArea',
        //       field: 'jenkins_user_credential_desc',
        //       inputProps: {
        //         minRows: 2,
        //       },
        //       helperText:
        //         'Add a description for identification and reference purposes.',
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

export const AddRepositoryForm = props => {
  return <RepositoryForm header="Add Repository" {...props} />;
};

const RepositoryDetailHeader = props => {
  const {
    route: {params},
  } = props;
  const {repository} = params;

  const vcsType = repository?.vcs?.type;
  const workspace = repository?.vcs?.workspace;

  let repositoryUrl = void 0;
  if (workspace && repository.repository) {
    if (vcsType === 'bitbucket') {
      repositoryUrl = `https://bitbucket.org/${workspace}/${repository.repository}`;
    } else if (vcsType === 'github') {
      repositoryUrl = `https://github.com/${workspace}/${repository.repository}`;
    }
  }
  return (
    <FormHeader
      title={'Repository Detail'}
      actions={[
        repositoryUrl && (
          <LinkRenderer linkText="Clone" value={repositoryUrl} />
        ),
      ]}
    />
  );
};

export const RepositoryDetail = props => {
  return (
    <>
      <RepositoryDetailHeader {...props} />
      <RepositoryForm
        mode="edit"
        skipHeader={true}
        submitAction={{type: 'save'}}
        {...props}
      />
    </>
  );
};

export const UpdateRepositoryDeploymentForm = props => {
  return (
    <RepositoryDeploymentForm
      mode="edit"
      skipHeader={true}
      submitAction={{type: 'save'}}
      {...props}
    />
  );
};

export const UpdateRepositoryAccessToken = props => {
  const {
    route: {params = {}},
  } = props;

  const {repository} = params || {};

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Repository',
    uri: '/projectrepositories',
    ...props,
  });

  return (
    <Form
      {...props}
      fields={{
        _id: 1,
      }}
      onSubmit={onSubmit}
      mode="edit"
      header={'Update Access Token'}
      api={`/projectrepositories/${repository?._id}`}
      layoutFields={[
        {
          label: 'Access Token',
          type: 'password',
          field: 'access_token',
          required: true,
          visible: repository?.vcs?.type === 'github',
          helperText:
            'Note: Grant permissions for Commit statuses, Contents, Pull requests, Webhooks. Include README.',
        },
        {
          label: 'Access Token',
          type: 'password',
          field: 'access_token',
          required: true,
          visible: repository?.vcs?.type === 'bitbucket',
          helperText:
            'Note: Grant write permissions for Repositories, Pull requests, Webhooks, Pipelines. ',
        },
      ]}
    />
  );
};
