import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const CaseStudyForm = props => {
  const {
    route: {params},
    header = 'Add Case Study',
    defaultValue,
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/casestudies',
    eventSourceId: 'caseStudyAdded',
    ...props,
  });
  const {department, product, row, marketing_theme} = params;
  return (
    <Form
      api={`/casestudies/${row?._id}`}
      fields={{
        case_study_title: 1,
        case_study_url: 1,
        attachments: 1,
        marketing_concept: {concept_name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
        marketing_theme,
      }}
      layoutFields={[
        {
          placeholder: 'Topic',
          field: 'case_study_title',
          type: 'text',
        },
        {
          placeholder: 'Link',
          field: 'case_study_url',
          type: 'text',
        },
        // {
        //   placeholder: 'Marketing Concept',
        //   field: 'marketing_concept',
        //   type: 'autoComplete',
        //   api: `/marketingconcepts`,
        //   suggestionField: 'concept_name',
        //   valueField: 'concept_name',
        // },
        {
          field: 'attachments',
          type: 'file',
          placeholder: 'Upload file*',
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditCaseStudyForm = props => {
  return <CaseStudyForm mode="edit" header={'Edit Case Study'} {...props} />;
};
