import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {BudgetRequestAccountTable, BudgetTable} from './BudgetRequestTables';
import {useAppStateContext} from '../../../providers/AppState';

export const BudgetRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Requested: {
      label: 'Requested',
      view: (
        <BudgetTable
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{status: 'Requested', requested_by: employee._id}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: '/budget/budgetdetail',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Requested',
        ...filterValues.filter,
      },
      limit: 500,
    },
    Approved: {
      label: 'Approved',
      view: (
        <BudgetTable
          tab={'Approved'}
          filterValues={filterValues}
          addOnFilter={{status: 'Approved', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/budget/budgetdetail',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Approved',
        ...filterValues.filter,
      },
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <BudgetTable
          tab={'Rejected'}
          filterValues={filterValues}
          addOnFilter={{status: 'Rejected', requested_by: employee._id}}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      api: '/budget/budgetdetail',
      eventSourceId: 'budget',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Rejected',
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
          ]}
        />,
        <AddButton title="Request budget" view={'add-budget-request'} />,
      ]}
    />
  );
};

export const BudgetRequestAccountTab = props => {
  let {routes: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let currentdate = new Date();
  let monthFirstDate = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth(),
    1,
  );

  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <BudgetRequestAccountTable
          addOnFilter={{
            status: 'Approved',
            from_date: {$gte: monthFirstDate},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/budget/budgetdetail',
      addOnFilter: {
        status: 'Approved',
        from_date: {$gte: monthFirstDate},
        ...filterValues.filter,
      },
      limit: 500,
    },
    Expired: {
      label: 'Expired',
      view: (
        <BudgetRequestAccountTable
          addOnFilter={{
            status: 'Requested',
            from_date: {$lt: monthFirstDate},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      api: '/budget/budgetdetail',
      addOnFilter: {
        status: 'Requested',
        from_date: {$lt: monthFirstDate},
        ...filterValues.filter,
      },
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
          ]}
        />,
      ]}
    />
  );
};
