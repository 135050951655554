import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {useAppStateContext} from '../providers/AppState';
import {View} from '@unthinkable/react-core-components';
import Login from '../modules/login/views/Login';
import routes from './index';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import ModalView from '../components/modal/ModalView';
import {EmailForm} from '@unthinkable/react-login/src/EmailForm';
import {OtpForm} from '@unthinkable/react-login/src/OtpForm';
import {PasswordForm} from '@unthinkable/react-login/src/PasswordForm';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {SetPassword} from '../modules/login/views/ChangePassword';
import {AppModuleProvider} from '../providers/AppModuleProvider';
import {RoleProvider} from './RoleContext';
import EmailLogin from '@unthinkable/react-login/src/EmailLogin';
import CreatePassword from '@unthinkable/react-login/src/CreatePassword';
import EnterPassword from '@unthinkable/react-login/src/EnterPassword';

const Stack = createNativeStackNavigator();

const {stackScreens, leftMenuScreens, modalScreens, formModalScreens} = routes;

const ScreenStyles = ({theme: {colors}}) => {
  return {
    leftMenu: {
      flex: 1,
      backgroundColor: colors.SURFACE3,
      overflow: 'hidden',
    },
    sm: {
      stack: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: colors.BACKGROUND,
        padding: 16,
      },
    },
    md: {
      stack: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: colors.BACKGROUND,
        padding: 20,
      },
    },
    modalContainer: {
      backgroundColor: colors.MODAL_BACKGROUND,
    },
  };
};

const getInitialRouteName = ({user, navigationType}) => {
  let initialRouteName = void 0;
  if (!user) {
    initialRouteName = 'login';
  } else if (user.userType == 'pre employee') {
    initialRouteName = 'add-employee-parichay-form-screen';
  } else if (
    user.externalUser &&
    !user.mobile &&
    user.userType !== 'employee'
  ) {
    initialRouteName = 'profile-detail';
  } else if (navigationType === 'new' || user?.externalUser) {
    initialRouteName = 'app-module';
  } else {
    initialRouteName = 'dashboard';
  }
  return initialRouteName;
};

const LoadingComponent = ({loading, children}) => {
  if (loading) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator />
      </View>
    );
  } else {
    return children;
  }
};

export const Home = () => {
  const styles = useStyles(ScreenStyles);
  const theme = useTheme();
  const {user, navigationType, status, loading} = useAppStateContext();

  if (status === 'initializing' || (loading && navigationType !== 'new')) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <AppModuleProvider user={user}>
      <RoleProvider>
        <Stack.Navigator
          key={user ? 'dashboard' : 'login'}
          initialRouteName={getInitialRouteName({user, navigationType})}>
          {!user ? (
            <>
              <Stack.Screen
                name="login"
                component={Login}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="email-login"
                component={EmailLogin}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="create-password"
                component={CreatePassword}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="enter-password"
                component={EnterPassword}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="forgotPassword"
                component={EmailForm}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="set-password"
                component={SetPassword}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="confirm-otp"
                component={OtpForm}
                options={{headerShown: false}}
              />
              <Stack.Screen
                name="password-form"
                component={PasswordForm}
                options={{headerShown: false}}
              />
            </>
          ) : (
            <>
              <Stack.Group
                screenOptions={{
                  contentStyle: styles?.leftMenu,
                  headerShown: false,
                }}>
                {leftMenuScreens?.map(
                  ({component: Component, options, ...screen}, index) => {
                    return (
                      <Stack.Screen key={index} {...screen}>
                        {props => {
                          return (
                            <LoadingComponent loading={loading}>
                              <Component {...options} {...props} />
                            </LoadingComponent>
                          );
                        }}
                      </Stack.Screen>
                    );
                  },
                )}
              </Stack.Group>
              <Stack.Group
                screenOptions={{
                  contentStyle: styles?.stack,
                }}>
                {stackScreens.map((screen, index) => (
                  <Stack.Screen key={index} {...screen} />
                ))}
              </Stack.Group>
              <Stack.Group
                screenOptions={{
                  headerShown: false,
                  presentation: 'transparentModal',
                  contentStyle: styles?.modalContainer,
                }}>
                {modalScreens.map(
                  (
                    {component: Component, componentProps, options, ...screen},
                    index,
                  ) => {
                    if (typeof options === 'function') {
                      options = options({theme});
                    }
                    return (
                      <Stack.Screen key={index} {...screen}>
                        {props => {
                          return (
                            <ModalView {...props} {...options}>
                              <Component {...props} {...componentProps} />
                            </ModalView>
                          );
                        }}
                      </Stack.Screen>
                    );
                  },
                )}

                {formModalScreens.map(
                  (
                    {component: Component, componentProps, options, ...screen},
                    index,
                  ) => (
                    <Stack.Screen key={index} {...screen}>
                      {props => {
                        return (
                          <ModalView
                            {...props}
                            {...options}
                            variant={'form'}
                            closeable={false}>
                            <Component {...props} {...componentProps} />
                          </ModalView>
                        );
                      }}
                    </Stack.Screen>
                  ),
                )}
              </Stack.Group>
            </>
          )}
        </Stack.Navigator>
      </RoleProvider>
    </AppModuleProvider>
  );
};
