import React from 'react';
import {MenuItem} from '@unthinkable/react-menus';
import {useAppStateContext} from '../../providers/AppState';
import {useFetchCount} from '@unthinkable/react-data-handler';
import {removeRouteParams} from './Utility';
import {useModuleGlobalParams} from '../../controllers/useModuleGlobalParams';

const MenuItemWithCount = ({menuItem, countProps, count, ...props}) => {
  const {fetch} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);

  if (typeof countProps === 'function') {
    countProps = countProps(props);
  }
  let {params, ...restProps} = props;
  let {params: itemParams, ...restItem} = menuItem;
  params = removeRouteParams({
    ...props,
    params: itemParams || params,
  });

  let itemFetchCount = useFetchCount({
    fetch,
    params,
    globalParams,
    ...restItem,
    ...countProps,
  });

  return (
    <MenuItem
      {...restProps}
      params={params}
      menuItem={{...menuItem, count: count || itemFetchCount}}
      count={count || itemFetchCount}
    />
  );
};

export const renderMenuItem = props => {
  return <MenuItemWithCount {...props} />;
};
