import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinetuneJobForm = props => {
  const {
    route: {params},
    header = 'Edit Job',
  } = props;

  const {row: {_id} = {}} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: `/finetunejobs`,
    eventSourceId: ['editJob'],
  });
  const {defaultValues} = params;

  return (
    <Form
      mode="edit"
      api={`/finetunejobs/${_id}`}
      fields={{
        name: 1,
        file_id: 1,
        job_id: 1,
        status: 1,
        output_model: 1,
        error: 1,
        reference_model: 1,
        created_at: 1,
        finished_at: 1,
        trained_tokens: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        ...defaultValues,
      }}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
        {
          field: 'file_id',
          type: 'text',
          label: 'File id',
        },
        {
          field: 'job_id',
          type: 'text',
          label: 'Job id',
        },
        {
          field: 'status',
          type: 'text',
          label: 'Status',
        },
        {
          field: 'output_model',
          type: 'text',
          label: 'Ouput Model',
        },
        {
          field: 'error',
          type: 'text',
          label: 'Error',
          visible: ({values}) => values.status === 'failed',
        },
        {
          field: 'reference_model',
          type: 'autoComplete',
          label: 'Reference Model',
          placeholder: 'Reference Model',
          options: ['gpt-3.5-turbo-1106', 'gpt-3.5-turbo-0613', 'gpt-4-0613'],
        },
        {
          field: 'created_at',
          type: 'date',
          label: 'Created At',
        },
        {
          field: 'finished_at',
          type: 'date',
          label: 'Finished At',
          visible: ({values}) => values.status !== 'failed',
        },
        {
          field: 'trained_tokens',
          type: 'number',
          label: 'Trained Tokens',
          visible: ({values}) => values.status !== 'failed',
        },
      ]}
      {...props}
    />
  );
};
