import {Row, Text} from '@unthinkable/react-core-components';
import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TextRenderer} from '../../../components/form/Renderers';
import {TabView} from '../../../components/tab/TabView';
import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {
  ExitCategoryAvatar,
  LegalStatusAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import {employeeController} from '../controllers/employeeController';
import {AddAction} from '../../../components/action/AddAction';
import {Button} from '../../../components/button/Button';

export const RenderViewAllocations = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="View Allocations"
      style={{...rowText, color: colors['ACCENT1_HIGH']}}
    />
  );
};

const RenderProject = props => {
  const {
    row,
    styles: {rowText = {}},
  } = props;
  const projectName = row?.project
    ?.map(element => {
      return element?.project;
    })
    .join(', ');
  if (!projectName) return null;
  return (
    <PrimaryColumnCard
      primaryTitle={projectName}
      items={[
        {
          value: row?.order?.[0]?.order_type == 'r' ? 'T&M' : 'Fixed',
        },
        {
          value: `${row?.totalActualWorkingHours} h`,
        },
      ]}
    />
  );
};

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const EmployeeWithPackage = props => {
  let {route, navigation, filter, params, search} = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {
    ...filter,
    employee_status: 'Active',
    legal: {$in: [null, false]},
  };
  if (_id) {
    addOnFilter = {
      ...addOnFilter,
      _id: _id,
    };
  }
  const {
    markPotentialPip,
    startNodues,
    EmployeeDetails,
    holdUnholdSalary,
    markInactive,
    markLegal,
    markRecoveryPending,
    promoteEmployee,
    changeEmployeeDepartment,
    downloadpromotionLetter,
    deactivateUser,
    hrUploadLetters,
    EmployeeDetailsEditable,
  } = employeeController(props);
  const colors = useTheme('colors');
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'EmployeeAssignment',
    method: 'post',
  });
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: api ? api : `/employees/active`,
  };
  const bankaccfetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
  };
  return (
    <Table
      {...props}
      {...fetchProps}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      Address: 'address_line_1',
                      City: 'city',
                      Country: 'country',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Address 1': 'address_line_2',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current Country': 'country_current',
                      'Current State': 'state_current',
                      'College/Institution Name': 'graduation_institute_name',
                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Marital Status': 'marital_status',
                      'Spouse Name': 'spouse_name',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      'Category of reward': 'category_of_reward',
                      'Package Review Date': 'package_review_date',
                      'Nominee Name': 'nominee_name',
                      'Secondary Nominee Name': 'secondary_nominee_name',
                      'Nominee Relation': 'nominee_relation.relation',
                      'Secondary Nominee Relation':
                        'secondary_nominee_relation.relation',
                      'Nominee Address': 'nominee_address',
                      'Secondary Nominee Address': 'secondary_nominee_address',
                      'ESI Number': 'esi_number',
                      'Incerament Type': 'increment_type',
                      'Agreement End Date': 'agreement_end_date',
                    },
                  },
                },
              }),
          },
          {
            title: 'Bank details',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/download-employee-bank-acc-details',
                    },
                    column: {
                      'Employee name': 'Name',
                      'Employee code': 'EmployeeCode',
                      'Bank name': 'Bank name',
                      'Account Number': 'Account Number',
                      'Name in Bank': 'Name in Bank',
                      'IFSC Code': 'IFSC Code',
                      'Bank Name 2': 'Bank Name 2',
                      'Account Number 2': 'Account Number 2',
                      'Name in Bank 2': 'Name in Bank 2',
                      'IFSC Code 2': 'IFSC Code 2',
                    },
                    filename: 'Bank Details',
                  },
                },
              }),
          },
          // {
          //   title: 'Bank details',
          //   onPress: props =>
          //     download({
          //       uri: '/exportExcelHelper',
          //       props: {
          //         props: {
          //           ...props,
          //           fetchProps: {
          //             ...bankaccfetchProps,
          //             api: '/download-employee-bank-acc-details',
          //           },
          //           column: {
          //             Name: 'name',
          //           },
          //         },
          //       },
          //     }),
          // },
          {
            title: 'Generate Attendance Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generateAttendanceSalary',
                key: 'Attendance',
              });
            },
          },
          {
            title: 'Generate Fixed Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generateFixedSalary',
                key: 'Fixed',
              });
            },
          },
          {
            title: 'Generate Performance Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generatePerformanceSalary',
                key: 'Performance',
              });
            },
          },
          {
            title: 'Populate Income Tax',
            onPress: ({selectedIds}) => {
              invoke({
                uri: '/populateIncomeTax',
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Download Package',
            onPress: ({selectedIds}) => {
              download({
                uri: '/employeePackageDataDownload',
                props: {
                  selectedIds: props.selectedIds,
                  fetchProps: {
                    ...fetchProps,
                    api: api ? api : `/employees/active`,
                  },
                },
              });
            },
          },
          {
            title: 'Download Latest Package Details',
            onPress: ({selectedIds}) => {
              download({
                uri: '/downloadEmployeePackageDetails',
                props: {
                  selectedIds: selectedIds,
                  latest_package : true,
                  fetchProps: {
                    ...fetchProps,
                    api: api ? api : `/employees/active`,
                  },
                },
              });
            },
          },
          {
            title: 'Download First Package Details',
            onPress: ({selectedIds}) => {
              download({
                uri: '/downloadEmployeePackageDetails',
                props: {
                  selectedIds: selectedIds,
                  first_package : true,
                  fetchProps: {
                    ...fetchProps,
                    api: api ? api : `/employees/active`,
                  },
                },
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 80,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
          width: 200,
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Project Reportee',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 200,
          onPress: ({row}) => {
            navigation.navigate('employee-project-reportee-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-project-reportee', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'Primary Reportee',
          align: 'center',
          field: 'delivery_owner',
          type: 'userAvatar',
          width: 200,
          onPress: ({row}) => {
            navigation.navigate('employee-delivery-owner-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-delivery-owner', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('employee-package-details', {
                    row,
                  });
                }}
                style={{...rowText, color: colors.INFORMATION_HIGH}}>
                + Package
              </Text>
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          // onPress: EmployeeDetails,
          onPress: EmployeeDetailsEditable,
        },
        {
          title: 'Create user',
          confirm: {
            title: 'Create user',
            message: 'Are you sure you want to create user of this employee?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/create-employee-user/${row._id}`,
              message: 'User created successfully',
            });
          },
        },
        {
          title: 'Mark Potential PIP',
          onPress: markPotentialPip,
        },
        {
          title: 'Change Resource Skill',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-skill', {row: row});
          },
        },
        {
          title: 'View Allocation',
          onPress: ({row}) => {
            navigation.navigate('resource-allocations-list', {
              ...params,
              employeeFilter: {
                employee: row?._id,
              },
            });
          },
        },
        {
          title: 'Start Nodues',
          onPress: startNodues,
        },
        {
          title: 'Mark Inactive',
          onPress: markInactive,
        },
        {
          title: 'Hold/Unhold Salary',
          onPress: holdUnholdSalary,
        },
        markLegal({navigation}),
        markRecoveryPending({navigation}),
        {
          title: 'Download Bond Letter',
          onPress: ({row}) => {
            const employeeId = row?._id;
            download({
              uri: `/downloadbondletter`,
              props: {
                employeeId,
              },
            });
          },
        },
        {
          title: 'Download Promotion Letter',
          onPress: downloadpromotionLetter,
        },
        {
          title: 'Promote employee',
          onPress: promoteEmployee,
        },
        {
          title: 'Upload Letters',
          onPress: hrUploadLetters,
        },
        {
          title: 'Change department',
          onPress: changeEmployeeDepartment,
        },
        deactivateUser({invoke}),
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeWithPackageForHr = props => {
  let {route, navigation, filter, params, search} = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {
    ...filter,
    employee_status: 'Active',
    legal: {$in: [null, false]},
  };
  if (_id) {
    addOnFilter = {
      ...addOnFilter,
      _id: _id,
    };
  }
  const {
    markPotentialPip,
    startNodues,
    EmployeeDetails,
    EmployeeDetailsEditable,
    holdUnholdSalary,
    markInactive,
    markLegal,
    markRecoveryPending,
    promoteEmployee,
    changeEmployeeDepartment,
    downloadpromotionLetter,
    deactivateUser,
    hrUploadLetters,
    downloadAppointmentLetter,
    closeBackgroundVerification,
  } = employeeController(props);
  const colors = useTheme('colors');
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'EmployeeAssignment',
    method: 'post',
  });
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: api ? api : `/employees/active`,
  };
  return (
    <Table
      {...props}
      {...fetchProps}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      Address: 'address_line_1',
                      City: 'city',
                      Country: 'country',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Address 1': 'address_line_2',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current Country': 'country_current',
                      'Current State': 'state_current',
                      'Secondary Institution':
                        'secondary_qualification_institute_name',
                      'Secondary Institution Board':
                        'secondary_university_name',
                      'Secondary Institution year of passing':
                        'secondary_year_of_passing',
                      'Secondary Institution grade': 'secondary_grade',
                      'Senior Secondary Institution':
                        'sr_secondary_institute_name',
                      'Secondary Institution board':
                        'sr_secondary_university_name',
                      'Secondary Institution year of passing':
                        'sr_secondary_year_of_passing',
                      'Secondary Institution grade': 'sr_secondary_grade',

                      'College/Institution Name': 'graduation_institute_name',
                      'Graduation qualification':
                        'graduation_qualification_name',
                      'Graduation specilisation': 'graduation_course_name',
                      'Graduation university': 'graduation_university_name',
                      'Graduation year of passing':
                        'graduation_year_of_passing',
                      'Graduation grade': 'graduation_grade',

                      'Post College/Institution Name':
                        'post_graduation_institute_name',
                      'Post Graduation qualification':
                        'post_graduation_qualification_name',
                      'Post Graduation specilisation':
                        'post_graduation_course_name',
                      'Post Graduation university':
                        'post_graduation_university_name',
                      'Post Graduation year of passing':
                        'post_graduation_year_of_passing',
                      'Post Graduation grade': 'post_graduation_grade',

                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Marital Status': 'marital_status',
                      'Spouse Name': 'spouse_name',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      'Category of reward': 'category_of_reward',
                      'Package Review Date': 'package_review_date',
                      'Nominee Name': 'nominee_name',
                      'Secondary Nominee Name': 'secondary_nominee_name',
                      'Nominee Relation': 'nominee_relation.relation',
                      'Secondary Nominee Relation':
                        'secondary_nominee_relation.relation',
                      'Nominee Address': 'nominee_address',
                      'Secondary Nominee Address': 'secondary_nominee_address',
                      'ESI Number': 'esi_number',
                      'Incerament Type': 'increment_type',
                      'Agreement End Date': 'agreement_end_date',
                      Practice: 'primary_practice.name',
                      'Practice head': 'primary_practice.owner.name',
                    },
                  },
                },
              }),
          },
          {
            title: 'Download Package',
            onPress: ({selectedIds}) => {
              download({
                uri: '/employeePackageDataDownload',
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Add To Appraisal',
            onPress: ({selectedIds}) => {
              navigation.navigate(`add-to-appraisal`, {
                ...params,
                selectedIds,
                fetchProps,
                api: '/appraisalGeneration',
              });
            },
          },
          {
            title: 'Set Exceptional Shift',
            onPress: ({selectedIds}) => {
              navigation.navigate(`update-exceptional-shift`, {
                ...params,
                selectedIds,
                fetchProps,
                api: '/updateExceptionShiftOnEmployee',
              });
            },
          },
          {
            title: 'Update Leave Policy',
            onPress: ({selectedIds}) => {
              navigation.navigate(`update-employee-leave-policy`, {
                ...params,
                selectedIds,
              });
            },
          },
          {
            title: 'Update Holiday Calendar',
            onPress: ({selectedIds}) => {
              navigation.navigate(`update-employee-holiday-calendar`, {
                ...params,
                selectedIds,
              });
            },
          },
          {
            title: 'Update Roster Policy',
            onPress: ({selectedIds}) => {
              navigation.navigate(`update-employee-roster-policy`, {
                ...params,
                selectedIds,
              });
            },
          },
          {
            title: 'Update Schedule Information',
            onPress: ({selectedIds}) => {
              navigation.navigate(`update-employee-schedule-information`, {
                ...params,
                selectedIds,
              });
            },
          },
          {
            title: 'Update Shift Information',
            onPress: ({selectedIds}) => {
              navigation.navigate(`update-employee-shift-information`, {
                ...params,
                selectedIds,
              });
            },
          },
          {
            title: 'Download Latest Package Details',
            onPress: ({selectedIds}) => {
              download({
                uri: '/downloadEmployeePackageDetails',
                props: {
                  selectedIds: selectedIds,
                  latest_package : true,
                  fetchProps: {
                    ...fetchProps,
                    api: api ? api : `/employees/active`,
                  },
                },
              });
            },
          },
          {
            title: 'Download First Package Details',
            onPress: ({selectedIds}) => {
              download({
                uri: '/downloadEmployeePackageDetails',
                props: {
                  selectedIds: selectedIds,
                  first_package : true,
                  fetchProps: {
                    ...fetchProps,
                    api: api ? api : `/employees/active`,
                  },
                },
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          minWidth: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          align: 'center',
          width: 150,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
        },
        // {
        //   header: 'Secondary Skill',
        //   field: 'seconday_resource_language.name',
        //   type: 'colorTag',
        //   colorField: 'seconday_resource_language.color',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Project Reportee',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 200,
          onPress: ({row}) => {
            navigation.navigate('employee-project-reportee-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-project-reportee', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'Primary Reportee',
          align: 'center',
          field: 'delivery_owner',
          type: 'userAvatar',
          width: 200,
          onPress: ({row}) => {
            navigation.navigate('employee-delivery-owner-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-delivery-owner', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('employee-package-details', {
                    row,
                    formHr: true,
                  });
                }}
                style={{...rowText, color: colors.INFORMATION_HIGH}}>
                + Package
              </Text>
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: EmployeeDetailsEditable,
        },
        {
          title: 'Create user',
          confirm: {
            title: 'Create user',
            message: 'Are you sure you want to create user of this employee?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/create-employee-user/${row._id}`,
              message: 'User created successfully',
            });
          },
        },
        {
          title: 'Mark Potential PIP',
          onPress: markPotentialPip,
        },
        {
          title: 'Change Resource Skill',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-skill', {row: row});
          },
        },
        {
          title: 'View Allocation',
          onPress: ({row}) => {
            navigation.navigate('resource-allocations-list', {
              ...params,
              employeeFilter: {
                employee: row?._id,
              },
            });
          },
        },
        {
          title: 'Start Nodues',
          onPress: startNodues,
        },
        {
          title: 'Mark Inactive',
          onPress: markInactive,
        },
        {
          title: 'Hold/Unhold Salary',
          onPress: holdUnholdSalary,
        },
        markLegal({navigation}),
        markRecoveryPending({navigation}),
        {
          title: 'Download Bond Letter',
          onPress: ({row}) => {
            const employeeId = row?._id;
            download({
              uri: `/downloadbondletter`,
              props: {
                employeeId,
              },
            });
          },
        },
        {
          title: 'Download Service Agreement',
          onPress: ({row}) => {
            const employeeId = row?._id;
            download({
              uri: `/serviceAggreementDownload`,
              props: {
                employeeId,
              },
            });
          },
        },
        {
          title: 'Download Promotion Letter',
          onPress: downloadpromotionLetter,
        },
        {
          title: 'Download New Appointment Letter',
          onPress: ({row}) => {
            download({
              uri: `/appointmentletterDownload`,
              props: {
                employeeId: row._id,
              },
            });
          },
        },
        {
          title: 'Download Engagement Letter',
          onPress: ({row}) => {
            download({
              uri: `/appointmentletterDownloadforfresher`,
              props: {
                employeeId: row._id,
              },
            });
          },
        },
        {
          title: 'Download Appointment Letter',
          onPress: downloadAppointmentLetter,
        },
        {
          title: 'Upload Letters',
          onPress: hrUploadLetters,
        },
        {
          title: 'Promote employee',
          onPress: promoteEmployee,
        },
        {
          title: 'Change department',
          onPress: changeEmployeeDepartment,
        },
        deactivateUser({invoke}),
        {
          title: 'Close BVG',
          onPress: closeBackgroundVerification,
          visible: ({row}) => row?.is_background_verification_required,
        },
        {
          title: 'Assign Department Role',
          onPress: ({row}) => {
            navigation.navigate(`assign-department-role-on-employee`, {
              employee: row,
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const EmployeePendingForApproval = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  const {markPotentialPip, EmployeeDetails, EmployeeDetailsEditable} =
    employeeController(props);
  const colors = useTheme('colors');
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['EmployeeAssignment'],
  });
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={api ? api : `/employees/pending-for-approval`}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          // width: 250,
        },
        {
          header: 'Skill',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Approve',
          confirm: {
            title: 'Approve',
            message: 'Are you sure you want to approve this employee?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/employees/${row._id}`,
              props: {
                employee_status: 'Active',
              },
            });
          },
        },
        {
          title: 'Details',
          onPress: EmployeeDetailsEditable,
        },
      ]}
    />
  );
};

export const InActiveEmployeeWithPackageGroupTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  return (
    <GroupTable
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/inactive`}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          width: 260,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Service Duration',
          render: ({row}) => {
            return (
              <ServiceDurationAvatar
                service_duration={row?.service_duration}
                color={row?.color}
                relieving_date={row?.relieving_date}
                joining_date={row?.joining_date}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Exit Category',
          render: ({row}) => {
            return (
              <ExitCategoryAvatar
                exit_type={row?.exit_type}
                color={row?.color}
                desireable_status={row?.desireable_status}
              />
            );
          },
          width: 130,
          align: 'left',
        },
      ]}
      {...props}
    />
  );
};

export const EmployeeWithPackageTabs = props => {
  let {route: {params} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.new = {
    label: 'Pending',
    api: '/employees/pending-for-approval',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeePendingForApproval
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <AddButton
          title="Employee"
          view="add-employee-form"
          params={props?.route?.params}
        />
      </Row>,
    ],
  };
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        filter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <Button
        text="Import Food Coupon"
        onPress={() => {
          navigation.navigate('import-list', {
            source: 'foodcoupon',
            model: 'Employee',
          });
        }}
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },

          {
            type: 'autoComplete',
            label: 'Project Reportee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Primary Reportee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'delivery_owner',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'multiAutoComplete',
            label: 'Sitting Location',
            field: 'sitting_location',
            api: '/offices',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
          },
          {
            type: 'multiAutoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'multiAutoComplete',
            field: 'organization',
            api: '/organizations',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            label: 'Organization',
          },
          {
            type: 'autoComplete',
            label: 'Is Recovery Pending',
            options: [
              {label: 'True', value: true},
              {label: 'False', value: false},
            ],
            field: 'training_cost_recovery_pending',
            placeholder: 'Select',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Is BTB',
            options: [
              {label: 'True', value: true},
              {label: 'False', value: false},
            ],
            field: 'is_btb',
            placeholder: 'Select',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Package status',
            options: [
              {value: true, label: 'Unapproved'},
              {value: false, label: 'Approved'},
            ],
            placeholder: 'Select',
            field: 'is_package_data',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'date',
            field: 'package_review_date',
            label: 'Package review date',
          },
          {
            type: 'date',
            field: 'package_expire_date',
            label: 'Package expire date',
          },
          {
            type: 'date',
            field: 'joining_date',
            label: 'Joining date',
          },
          {
            type: 'autoComplete',
            label: 'Fnf status',
            options: [
              {value: true, label: 'F&F Settled'},
              {value: false, label: 'F&F Unsettled'},
            ],
            placeholder: 'Select',
            field: 'fnf_status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Is Package Added',
            options: [
              {value: {$exists: true}, label: 'True'},
              {value: {$exists: false}, label: 'False'},
            ],
            placeholder: 'Select',
            field: 'package_review_date',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'On Maternity Leave',
            options: [
              {value: true, label: 'True'},
              {value: {$in: [null, false]}, label: 'False'},
            ],
            placeholder: 'Select',
            field: 'on_maternity_leave',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };

  // tabs.inactive = {
  //   label: 'Inactive',
  //   api: '/employees/inactive',
  //   params: {
  //     ...params,
  //   },
  //   addOnFilter: filter,
  //   limit: 5000,
  //   view: (
  //     <InActiveEmployeeWithPackageGroupTable
  //       params={{...params, ...filterParams}}
  //       search={searchValue}
  //       addOnFilter={filter}
  //     />
  //   ),
  //   actions: [
  //     <SearchFilter
  //       value={searchValue}
  //       onChangeFilter={onChangeFilter}
  //       placeholder="Search"
  //     />,
  //     <PeriodRangeFilter
  //       onChangeFilter={onChangeFilter}
  //       filterValues={filterValues}
  //       asParam
  //     />,
  //     <GroupFilter
  //       filterValues={filterValues}
  //       applyFilter={applyFilter}
  //       filters={[
  //         {
  //           type: 'autoComplete',
  //           label: 'Department',
  //           api: '/departments',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'department',
  //         },
  //         {
  //           type: 'autoComplete',
  //           label: 'Product',
  //           api: '/products',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'product',
  //         },
  //         {
  //           type: 'autoComplete',
  //           label: 'Reporting To',
  //           api: '/employeeSuggestions',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'reporting_to',
  //           filter: {employee_status: 'Active'},
  //         },
  //       ]}
  //     />,
  //   ],
  // };

  tabs.inactive = {
    label: 'Inactive',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <InActiveEmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };

  tabs.onhold = {
    label: 'On hold',
    api: '/employees/onhold-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <OnHoldEmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };
  tabs.legal = {
    label: 'Legal',
    api: '/employees/legal-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <LegalEmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };
  return <TabView tabs={tabs} {...props} />;
};

export const EmployeeWithPackageForHrTabs = props => {
  let {route: {params} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.new = {
    label: 'Pending',
    api: '/employees/pending-for-approval',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeePendingForApproval
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <AddButton
          title="Employee"
          view="add-employee-form"
          params={props?.route?.params}
        />
      </Row>,
    ],
  };
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeWithPackageForHr
        params={{...params, ...filterParams}}
        search={searchValue}
        filter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      //   <Button
      //   text="Import Food Coupon"
      //   onPress={() => {
      //     navigation.navigate('import-list', {
      //       source: 'foodcoupon',
      //       model: 'Employee',
      //     });
      //   }}
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },

          {
            type: 'autoComplete',
            label: 'Project Reportee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Primary Reportee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'delivery_owner',
            filter: {employee_status: 'Active'},
          },
          // {
          //   type: 'autoComplete',
          //   label: 'People Manager',
          //   api: '/employeeSuggestions',
          //   placeholder: 'Select',
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   field: 'functional_manager',
          //   filter: {employee_status: 'Active'},
          // },
          {
            type: 'multiAutoComplete',
            label: 'Sitting Location',
            field: 'sitting_location',
            api: '/offices',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
          },
          {
            type: 'multiAutoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'multiAutoComplete',
            field: 'organization',
            api: '/organizations',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            label: 'Organization',
          },
          {
            type: 'autoComplete',
            label: 'Is Recovery Pending',
            options: [
              {label: 'True', value: true},
              {label: 'False', value: false},
            ],
            field: 'training_cost_recovery_pending',
            placeholder: 'Select',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Is BTB',
            options: [
              {label: 'True', value: true},
              {label: 'False', value: false},
            ],
            field: 'is_btb',
            placeholder: 'Select',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Package status',
            options: [
              {value: true, label: 'Unapproved'},
              {value: false, label: 'Approved'},
            ],
            placeholder: 'Select',
            field: 'is_package_data',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'date',
            field: 'package_review_date',
            label: 'Package review date',
          },
          {
            type: 'date',
            field: 'package_expire_date',
            label: 'Package expire date',
          },
          {
            type: 'date',
            field: 'joining_date',
            label: 'Joining date',
          },
          {
            type: 'autoComplete',
            label: 'Fnf status',
            options: [
              {value: true, label: 'F&F Settled'},
              {value: false, label: 'F&F Unsettled'},
            ],
            placeholder: 'Select',
            field: 'fnf_status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Is Package Added',
            options: [
              {value: {$exists: true}, label: 'True'},
              {value: {$exists: false}, label: 'False'},
            ],
            placeholder: 'Select',
            field: 'package_review_date',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'On Maternity Leave',
            options: [
              {value: true, label: 'True'},
              {value: {$in: [null, false]}, label: 'False'},
            ],
            placeholder: 'Select',
            field: 'on_maternity_leave',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };
  tabs.inactive = {
    label: 'Inactive',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <InActiveEmployeeWithPackageForHr
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Marked legal',
            options: [{label: 'True', value: true}],
            placeholder: 'Select',
            field: 'is_marked_legal',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'date',
            field: 'relieving_date',
            label: 'Inactive Date',
          },
        ]}
      />,
    ],
  };

  // tabs.onhold = {
  //   label: 'On hold',
  //   api: '/employees/onhold-with-package',
  //   params: {
  //     ...params,
  //   },
  //   search: searchValue,
  //   searchFields: ['name', 'employee_code'],
  //   addOnFilter: filter,
  //   limit: 5000,
  //   view: (
  //     <OnHoldEmployeeWithPackage
  //       params={{...params, ...filterParams}}
  //       search={searchValue}
  //       addOnFilter={filter}
  //     />
  //   ),
  //   actions: [
  //     <SearchFilter
  //       value={searchValue}
  //       onChangeFilter={onChangeFilter}
  //       placeholder="Search"
  //     />,
  //     // <PeriodRangeFilter
  //     //   onChangeFilter={onChangeFilter}
  //     //   filterValues={filterValues}
  //     //   asParam
  //     // />,
  //     <GroupFilter
  //       filterValues={filterValues}
  //       applyFilter={applyFilter}
  //       filters={[
  //         {
  //           type: 'autoComplete',
  //           label: 'Department',
  //           api: '/departments',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'department',
  //         },
  //         {
  //           type: 'autoComplete',
  //           label: 'Product',
  //           api: '/products',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'product',
  //         },
  //         {
  //           type: 'autoComplete',
  //           label: 'Reporting To',
  //           api: '/employeeSuggestions',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'reporting_to',
  //           filter: {employee_status: 'Active'},
  //         },
  //       ]}
  //     />,
  //   ],
  // };
  // tabs.legal = {
  //   label: 'Legal',
  //   api: '/employees/legal-with-package',
  //   params: {
  //     ...params,
  //   },
  //   search: searchValue,
  //   searchFields: ['name', 'employee_code'],
  //   addOnFilter: filter,
  //   limit: 5000,
  //   view: (
  //     <LegalEmployeeWithPackage
  //       params={{...params, ...filterParams}}
  //       search={searchValue}
  //       addOnFilter={filter}
  //     />
  //   ),
  //   actions: [
  //     <SearchFilter
  //       value={searchValue}
  //       onChangeFilter={onChangeFilter}
  //       placeholder="Search"
  //     />,
  //     // <PeriodRangeFilter
  //     //   onChangeFilter={onChangeFilter}
  //     //   filterValues={filterValues}
  //     //   asParam
  //     // />,
  //     <GroupFilter
  //       filterValues={filterValues}
  //       applyFilter={applyFilter}
  //       filters={[
  //         {
  //           type: 'autoComplete',
  //           label: 'Department',
  //           api: '/departments',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'department',
  //         },
  //         {
  //           type: 'autoComplete',
  //           label: 'Product',
  //           api: '/products',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'product',
  //         },
  //         {
  //           type: 'autoComplete',
  //           label: 'Reporting To',
  //           api: '/employeeSuggestions',
  //           placeholder: 'Select',
  //           suggestionField: 'name',
  //           valueField: 'name',
  //           field: 'reporting_to',
  //           filter: {employee_status: 'Active'},
  //         },
  //       ]}
  //     />,
  //   ],
  // };
  return <TabView tabs={tabs} {...props} />;
};

export const InActiveEmployeeWithPackage = props => {
  let {
    route: {params},
    navigation,
    search,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const {EmployeeDetails} = employeeController(props);
  const invoke = useInvoke({
    eventSourceId: 'EmployeeAssignment',
    method: 'post',
  });
  const {user, download} = useAppStateContext();
  const {
    holdUnholdSalary,
    settleFnfStatus,
    deactivateUser,
    downloadrelievingLetter,
    downloadinternshipLetter,
    hrUploadLetters,
    EmployeeDetailsEditable,
  } = employeeController(props);
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: '/employees/inactive-with-package-nongrp',
  };
  return (
    <Table
      // params={params}
      // limit={5000}
      // searchFields={['name']}
      // api={`/employees/inactive-with-package-nongrp`}
      {...fetchProps}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      Address: 'address_line_1',
                      City: 'city',
                      Country: 'country',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Address 1': 'address_line_2',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current Country': 'country_current',
                      'Current State': 'state_current',
                      'Secondary Institution':
                        'secondary_qualification_institute_name',
                      'Secondary Institution Board':
                        'secondary_university_name',
                      'Secondary Institution year of passing':
                        'secondary_year_of_passing',
                      'Secondary Institution grade': 'secondary_grade',
                      'Senior Secondary Institution':
                        'sr_secondary_institute_name',
                      'Secondary Institution board':
                        'sr_secondary_university_name',
                      'Secondary Institution year of passing':
                        'sr_secondary_year_of_passing',
                      'Secondary Institution grade': 'sr_secondary_grade',

                      'College/Institution Name': 'graduation_institute_name',
                      'Graduation qualification':
                        'graduation_qualification_name',
                      'Graduation specilisation': 'graduation_course_name',
                      'Graduation university': 'graduation_university_name',
                      'Graduation year of passing':
                        'graduation_year_of_passing',
                      'Graduation grade': 'graduation_grade',

                      'Post College/Institution Name':
                        'post_graduation_institute_name',
                      'Post Graduation qualification':
                        'post_graduation_qualification_name',
                      'Post Graduation specilisation':
                        'post_graduation_course_name',
                      'Post Graduation university':
                        'post_graduation_university_name',
                      'Post Graduation year of passing':
                        'post_graduation_year_of_passing',
                      'Post Graduation grade': 'post_graduation_grade',

                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Marital Status': 'marital_status',
                      'Spouse Name': 'spouse_name',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      'Category of reward': 'category_of_reward',
                      'Package Review Date': 'package_review_date',
                      'Nominee Name': 'nominee_name',
                      'Secondary Nominee Name': 'secondary_nominee_name',
                      'Nominee Relation': 'nominee_relation.relation',
                      'Secondary Nominee Relation':
                        'secondary_nominee_relation.relation',
                      'Nominee Address': 'nominee_address',
                      'Secondary Nominee Address': 'secondary_nominee_address',
                      'ESI Number': 'esi_number',
                      'Incerament Type': 'increment_type',
                      'Agreement End Date': 'agreement_end_date',
                      'Exit type': 'exit_type',
                      'Desirable status': 'desireable_status',
                      'Resign date': 'resign_date_new',
                    },
                  },
                },
              }),
          },
          {
            title: 'Generate Attendance Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generateAttendanceSalary',
                key: 'Attendance',
              });
            },
          },
          {
            title: 'Generate Fixed Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generateFixedSalary',
                key: 'Fixed',
              });
            },
          },
          {
            title: 'Generate Performance Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generatePerformanceSalary',
                key: 'Performance',
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          minWidth: 260,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          align: 'center',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Service Duration',
          render: ({row}) => {
            return (
              <ServiceDurationAvatar
                service_duration={row?.service_duration}
                color={row?.color}
                relieving_date={row?.relieving_date}
                joining_date={row?.joining_date}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Exit Category',
          render: ({row}) => {
            return (
              <ExitCategoryAvatar
                exit_type={row?.exit_type}
                color={row?.color}
                desireable_status={row?.desireable_status}
              />
            );
          },
          width: 130,
          align: 'left',
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('employee-package-details', {
                    row,
                  });
                }}
                style={{...rowText, color: colors.INFORMATION_HIGH}}>
                + Package
              </Text>
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Hold/Unhold Salary',
          onPress: holdUnholdSalary,
        },
        {
          title: 'Details',
          // onPress: EmployeeDetails,
          onPress: EmployeeDetailsEditable,
        },
        {
          title: 'Settle FnF',
          onPress: settleFnfStatus,
        },
        {
          title: 'Upload Letters',
          onPress: hrUploadLetters,
        },
        {
          title: 'Download Releiving Letter',
          onPress: downloadrelievingLetter,
        },
        {
          title: 'Download Internship Letter',
          onPress: downloadinternshipLetter,
        },
        deactivateUser({invoke}),
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const InActiveEmployeeWithPackageForHr = props => {
  let {
    route: {params},
    navigation,
    search,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const {EmployeeDetails} = employeeController(props);
  const invoke = useInvoke({
    eventSourceId: 'EmployeeAssignment',
    method: 'post',
  });
  const {user, download} = useAppStateContext();
  const {
    holdUnholdSalary,
    EmployeeDetailsEditable,
    settleFnfStatus,
    deactivateUser,
    downloadrelievingLetter,
    downloadinternshipLetter,
    hrUploadLetters,
  } = employeeController(props);
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: '/employees/inactive-with-package-nongrp',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      Address: 'address_line_1',
                      City: 'city',
                      Country: 'country',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Address 1': 'address_line_2',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current Country': 'country_current',
                      'Current State': 'state_current',
                      'Secondary Institution':
                        'secondary_qualification_institute_name',
                      'Secondary Institution Board':
                        'secondary_university_name',
                      'Secondary Institution year of passing':
                        'secondary_year_of_passing',
                      'Secondary Institution grade': 'secondary_grade',
                      'Senior Secondary Institution':
                        'sr_secondary_institute_name',
                      'Secondary Institution board':
                        'sr_secondary_university_name',
                      'Secondary Institution year of passing':
                        'sr_secondary_year_of_passing',
                      'Secondary Institution grade': 'sr_secondary_grade',

                      'College/Institution Name': 'graduation_institute_name',
                      'Graduation qualification':
                        'graduation_qualification_name',
                      'Graduation specilisation': 'graduation_course_name',
                      'Graduation university': 'graduation_university_name',
                      'Graduation year of passing':
                        'graduation_year_of_passing',
                      'Graduation grade': 'graduation_grade',

                      'Post College/Institution Name':
                        'post_graduation_institute_name',
                      'Post Graduation qualification':
                        'post_graduation_qualification_name',
                      'Post Graduation specilisation':
                        'post_graduation_course_name',
                      'Post Graduation university':
                        'post_graduation_university_name',
                      'Post Graduation year of passing':
                        'post_graduation_year_of_passing',
                      'Post Graduation grade': 'post_graduation_grade',

                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Marital Status': 'marital_status',
                      'Spouse Name': 'spouse_name',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      'Category of reward': 'category_of_reward',
                      'Package Review Date': 'package_review_date',
                      'Nominee Name': 'nominee_name',
                      'Secondary Nominee Name': 'secondary_nominee_name',
                      'Nominee Relation': 'nominee_relation.relation',
                      'Secondary Nominee Relation':
                        'secondary_nominee_relation.relation',
                      'Nominee Address': 'nominee_address',
                      'Secondary Nominee Address': 'secondary_nominee_address',
                      'ESI Number': 'esi_number',
                      'Incerament Type': 'increment_type',
                      'Agreement End Date': 'agreement_end_date',
                      'Exit type': 'exit_type',
                      'Desirable status': 'desireable_status',
                      'Resign date': 'resign_date_new',
                    },
                  },
                },
              }),
          },
        ],
      }}
      // params={params}
      // limit={5000}
      // searchFields={['name']}
      // api={`/employees/inactive-with-package-nongrp`}
      {...fetchProps}
      // limit={5000}

      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'ResignationActive',
      ]}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          minWidth: 260,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          align: 'center',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Service Duration',
          render: ({row}) => {
            return (
              <ServiceDurationAvatar
                service_duration={row?.service_duration}
                color={row?.color}
                relieving_date={row?.relieving_date}
                joining_date={row?.joining_date}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Exit Category',
          render: ({row}) => {
            return (
              <ExitCategoryAvatar
                exit_type={row?.exit_type}
                color={row?.color}
                desireable_status={row?.desireable_status}
              />
            );
          },
          width: 130,
          align: 'left',
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('employee-package-details', {
                    row,
                  });
                }}
                style={{...rowText, color: colors.INFORMATION_HIGH}}>
                + Package
              </Text>
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Hold/Unhold Salary',
          onPress: holdUnholdSalary,
        },
        {
          title: 'Details',
          // onPress: EmployeeDetails,
          onPress: EmployeeDetailsEditable,
        },
        {
          title: 'Settle FnF',
          onPress: settleFnfStatus,
        },
        {
          title: 'Upload Letters',
          onPress: hrUploadLetters,
        },
        {
          title: 'Download Releiving Letter',
          onPress: downloadrelievingLetter,
        },
        {
          title: 'Download Internship Letter',
          onPress: downloadinternshipLetter,
        },
        deactivateUser({invoke}),
        {
          title: 'Update Exit Status',
          onPress: ({row}) => {
            navigation.navigate(`update-exit-status`, {row: row});
          },
        },
        {
          title: 'Update Relieving Date',
          onPress: ({row}) => {
            navigation.navigate(`update-inactive-employee-relieving-date`, {row: row});
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const OnHoldEmployeeWithPackage = props => {
  let {
    route: {params},
    navigation,
    search,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  const {user, download} = useAppStateContext();
  params = {
    ...rest,
    product,
    organization,
  };
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: '/employees/onhold-with-package',
  };
  return (
    <Table
      // params={params}
      // limit={5000}
      // searchFields={['name']}
      // api={`/employees/onhold-with-package`}
      {...fetchProps}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      Address: 'address_line_1',
                      City: 'city',
                      Country: 'country',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Address 1': 'address_line_2',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current Country': 'country_current',
                      'Current State': 'state_current',
                      'Secondary Institution':
                        'secondary_qualification_institute_name',
                      'Secondary Institution Board':
                        'secondary_university_name',
                      'Secondary Institution year of passing':
                        'secondary_year_of_passing',
                      'Secondary Institution grade': 'secondary_grade',
                      'Senior Secondary Institution':
                        'sr_secondary_institute_name',
                      'Secondary Institution board':
                        'sr_secondary_university_name',
                      'Secondary Institution year of passing':
                        'sr_secondary_year_of_passing',
                      'Secondary Institution grade': 'sr_secondary_grade',

                      'College/Institution Name': 'graduation_institute_name',
                      'Graduation qualification':
                        'graduation_qualification_name',
                      'Graduation specilisation': 'graduation_course_name',
                      'Graduation university': 'graduation_university_name',
                      'Graduation year of passing':
                        'graduation_year_of_passing',
                      'Graduation grade': 'graduation_grade',

                      'Post College/Institution Name':
                        'post_graduation_institute_name',
                      'Post Graduation qualification':
                        'post_graduation_qualification_name',
                      'Post Graduation specilisation':
                        'post_graduation_course_name',
                      'Post Graduation university':
                        'post_graduation_university_name',
                      'Post Graduation year of passing':
                        'post_graduation_year_of_passing',
                      'Post Graduation grade': 'post_graduation_grade',

                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Marital Status': 'marital_status',
                      'Spouse Name': 'spouse_name',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      'Category of reward': 'category_of_reward',
                      'Package Review Date': 'package_review_date',
                      'Nominee Name': 'nominee_name',
                      'Secondary Nominee Name': 'secondary_nominee_name',
                      'Nominee Relation': 'nominee_relation.relation',
                      'Secondary Nominee Relation':
                        'secondary_nominee_relation.relation',
                      'Nominee Address': 'nominee_address',
                      'Secondary Nominee Address': 'secondary_nominee_address',
                      'ESI Number': 'esi_number',
                      'Incerament Type': 'increment_type',
                      'Agreement End Date': 'agreement_end_date',
                      'Exit type': 'exit_type',
                      'Desirable status': 'desireable_status',
                      'Resign date': 'resign_date_new',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          minWidth: 260,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          align: 'center',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Service Duration',
          render: ({row}) => {
            return (
              <ServiceDurationAvatar
                service_duration={row?.service_duration}
                color={row?.color}
                relieving_date={row?.relieving_date}
                joining_date={row?.joining_date}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Exit Category',
          render: ({row}) => {
            return (
              <ExitCategoryAvatar
                exit_type={row?.exit_type}
                color={row?.color}
                desireable_status={row?.desireable_status}
              />
            );
          },
          width: 130,
          align: 'left',
        },
      ]}
      moreActions={() => [
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const RecoveryPendingEmployeesTable = props => {
  let {
    route: {params},
    navigation,
    search,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  const {user, download} = useAppStateContext();
  params = {
    ...rest,
    product,
    organization,
  };
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: '/employees/recovery-pending',
  };
  return (
    <Table
      // params={params}
      // limit={5000}
      // searchFields={['name']}
      // api={`/employees/recovery-pending`}
      {...fetchProps}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      Address: 'address_line_1',
                      City: 'city',
                      Country: 'country',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Address 1': 'address_line_2',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current Country': 'country_current',
                      'Current State': 'state_current',
                      'Secondary Institution':
                        'secondary_qualification_institute_name',
                      'Secondary Institution Board':
                        'secondary_university_name',
                      'Secondary Institution year of passing':
                        'secondary_year_of_passing',
                      'Secondary Institution grade': 'secondary_grade',
                      'Senior Secondary Institution':
                        'sr_secondary_institute_name',
                      'Secondary Institution board':
                        'sr_secondary_university_name',
                      'Secondary Institution year of passing':
                        'sr_secondary_year_of_passing',
                      'Secondary Institution grade': 'sr_secondary_grade',

                      'College/Institution Name': 'graduation_institute_name',
                      'Graduation qualification':
                        'graduation_qualification_name',
                      'Graduation specilisation': 'graduation_course_name',
                      'Graduation university': 'graduation_university_name',
                      'Graduation year of passing':
                        'graduation_year_of_passing',
                      'Graduation grade': 'graduation_grade',

                      'Post College/Institution Name':
                        'post_graduation_institute_name',
                      'Post Graduation qualification':
                        'post_graduation_qualification_name',
                      'Post Graduation specilisation':
                        'post_graduation_course_name',
                      'Post Graduation university':
                        'post_graduation_university_name',
                      'Post Graduation year of passing':
                        'post_graduation_year_of_passing',
                      'Post Graduation grade': 'post_graduation_grade',

                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Marital Status': 'marital_status',
                      'Spouse Name': 'spouse_name',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      'Category of reward': 'category_of_reward',
                      'Package Review Date': 'package_review_date',
                      'Nominee Name': 'nominee_name',
                      'Secondary Nominee Name': 'secondary_nominee_name',
                      'Nominee Relation': 'nominee_relation.relation',
                      'Secondary Nominee Relation':
                        'secondary_nominee_relation.relation',
                      'Nominee Address': 'nominee_address',
                      'Secondary Nominee Address': 'secondary_nominee_address',
                      'ESI Number': 'esi_number',
                      'Incerament Type': 'increment_type',
                      'Agreement End Date': 'agreement_end_date',
                      'Exit type': 'exit_type',
                      'Desirable status': 'desireable_status',
                      'Resign date': 'resign_date_new',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          minWidth: 260,
          
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Recovery Pending',
          type: 'date',
          field: 'training_cost_recovery_pending_date',
          width: 200,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Project Reportee',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 200,
          onPress: ({row}) => {
            navigation.navigate('employee-project-reportee-list', {
              row,
            });
          },
          // fallbackRender: ({row}) => {
          //   const {GreenBackGroundAdd} = useTheme('icons');
          //   return (
          //     <AddAction
          //       icon={GreenBackGroundAdd}
          //       onPress={() => {
          //         navigation.navigate('add-project-reportee', {
          //           row,
          //         });
          //       }}
          //     />
          //   );
          // },
        },
        {
          header: 'Primary Reportee',
          align: 'center',
          field: 'delivery_owner',
          type: 'userAvatar',
          width: 200,
          onPress: ({row}) => {
            navigation.navigate('employee-delivery-owner-list', {
              row,
            });
          },
          // fallbackRender: ({row}) => {
          //   const {GreenBackGroundAdd} = useTheme('icons');
          //   return (
          //     <AddAction
          //       icon={GreenBackGroundAdd}
          //       onPress={() => {
          //         navigation.navigate('add-delivery-owner', {
          //           row,
          //         });
          //       }}
          //     />
          //   );
          // },
        },
        {
          header: 'Product',
          field: 'product.name',
          type: 'text'
        }
      ]}
      moreActions={() => [
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};


export const LegalEmployeeWithPackage = props => {
  let {
    route: {params},
    navigation,
    search,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: '/employees/legal-with-package',
  };
  const {unmarkLegal, withdrawLegalProceedings, EmployeeDetailsEditable} =
    employeeController(props);
  return (
    <Table
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee Code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father Name': 'father_name',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      Experience: 'Experience',
                      'Personal Email': 'email_id',
                      Hobbies: 'hobbies.name',
                      'Reporting To': 'reporting_to.name',
                      'Functional Manager': 'functional_manager.name',
                      DOB: 'dob',
                      'Blood Group': 'blood_group.name',
                      'Joining Date': 'joining_date',
                      'Employee Type': 'employee_type.name',
                      'Marital Status': 'marital_status',
                      'Anniversary Date': 'anniversary_date',
                      'Releiving Date': 'relieving_date',
                      'Resign Date': 'resign_date',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'Legal Marked On': 'legal_marked_on',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      Address: 'address_line_1',
                      'Address 1': 'address_line_2',
                      City: 'city',
                      State: 'state',
                      'Current Address': 'address_line_1_current',
                      'Current Address 1': 'address_line_2_current',
                      'Current City': 'city_current',
                      'Current State': 'state_current',
                      'Legal Status': 'legal_status',
                    },
                  },
                },
              }),
          },
          {
            title: 'Generate Attendance Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generateAttendanceSalary',
                key: 'Attendance',
              });
            },
          },
          {
            title: 'Generate Fixed Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generateFixedSalary',
                key: 'Fixed',
              });
            },
          },
          {
            title: 'Generate Performance Salary',
            onPress: ({selectedIds}) => {
              navigation.navigate('generate-attendance-base-salary', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/generatePerformanceSalary',
                key: 'Performance',
              });
            },
          },
        ],
      }}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      columns={[
        {
          header: 'Since',
          type: 'date',
          field: 'legal_marked_on',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'text',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 200,
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 350,
        },
        {
          header: 'Service Duration',
          render: ({row}) => {
            return (
              <ServiceDurationAvatar
                service_duration={row?.service_duration}
                color={row?.color}
                relieving_date={row?.relieving_date}
                joining_date={row?.joining_date}
              />
            );
          },
          width: 160,
        },
        {
          header: 'Legal Status',
          render: ({row}) => {
            return (
              <LegalStatusAvatar
                legal_status={row?.legal_status}
                color={row?.color}
              />
            );
          },
          width: 130,
          align: 'left',
        },
        // {
        //   header: 'Exit Category',
        //   render: ({row}) => {
        //     return (
        //       <ExitCategoryAvatar
        //         exit_type={row?.exit_type}
        //         color={row?.color}
        //         desireable_status={row?.desireable_status}
        //       />
        //     );
        //   },
        //   width: 130,
        //   align: 'left',
        // },
      ]}
      moreActions={[
        withdrawLegalProceedings({navigation}),
        unmarkLegal({navigation}),
        {
          title: 'Details',
          onPress: EmployeeDetailsEditable,
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};
export const LegalEmployees = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.legal = {
    label: 'Legal',
    api: '/employees/legal-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <LegalEmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };
  return <TabView tabs={tabs} {...props} />;
};


export const OnHoldEmployeeTabs = props => {
  let {route: {params} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};


  tabs.onhold = {
    label: 'On hold',
    api: '/employees/onhold-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <OnHoldEmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };

  return <TabView tabs={tabs} {...props} />;
};

export const RecoveryPendingEmployees = props => {
  let {route: {params} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};


  tabs.recovery_pending = {
    label: 'Recovery Pending',
    api: '/employees/recovery-pending',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <RecoveryPendingEmployeesTable
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      // <PeriodRangeFilter
      //   onChangeFilter={onChangeFilter}
      //   filterValues={filterValues}
      //   asParam
      // />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };

  return <TabView tabs={tabs} {...props} />;
};