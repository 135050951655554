import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';

export const SocialMediaWebpageTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;

  return (
    <Table
      {...props}
      params={params}
      eventSourceId="SocialMediaWebpage"
      renderHeader={() => {
        return (
          <TableHeader
            title="Webpage"
            actions={[
              <ObjectWiseTasks
                {...props}
                params={{...props?.route?.params}}
                object={{
                  _id: '654b4b821ba8cc7cf9f371f8',
                  name: 'SocialMediaWebpage',
                }}
              />,
              <AddButton
                title="Add"
                view="add-social-media-webpage"
                {...props}
              />,
            ]}
          />
        );
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-social-media-webpage', {
          socialMediaWebpage: row,
        });
      }}
      fields={{
        name: 1,
        content: 1,
        value_proposition: 1,
      }}
      api={`/socialMediaWebpages`}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'content',
          header: 'Content',
        },
        {
          type: 'number',
          field: 'value_proposition',
          header: 'Value Proposition',
          width: 200,
        },
      ]}
    />
  );
};
