import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const DataMiningForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/dataminings',
    ...props,
  });
  const {department, product} = params;
  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Data Mining'}
      submitAction={'Save'}
      defaultValues={{
        product: product?._id,
      }}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          placeholder: 'Target',
          field: 'target',
          type: 'text',
        },
        {
          placeholder: 'Est',
          field: 'est_hours',
          type: 'text',
        },
      ]}
    />
  );
};
