import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {
  ExpenseApproverTable,
  ExpenseRequestedAccountsTable,
  InvoiceGenerationTable,
} from './ExpenseApproverTable';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

export const ExpenseApproverTab = props => {
  let {route: {params} = {}, reffer_to_coo, fromMyView} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <ExpenseApproverTable
          addOnFilter={{
            status: 'Requested',
          }}
          searchValue={searchValue}
          reffer_to_coo={reffer_to_coo}
          fromMyView={fromMyView}
          tab={'ExpenseApproverActive'}
          {...props}
        />
      ),
    },
    close: {
      label: 'Closed',
      view: (
        <ExpenseApproverTable
          addOnFilter={{
            status: {
              $in: ['Approved', 'Rejected', 'Transaction_completed', 'Booked'],
            },
          }}
          searchValue={searchValue}
          reffer_to_coo={reffer_to_coo}
          fromMyView={fromMyView}
          tab={'ExpenseApproverClosed'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={
            [
              // {
              //   type: 'autoComplete',
              //   label: 'Payee',
              //   api: '/vendors',
              //   placeholder: 'Select',
              //   suggestionField: 'name',
              //   valueField: 'name',
              //   field: 'payee_id',
              // },
            ]
          }
        />,
      ]}
    />
  );
};

export const ExpenseApproverInvoiceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    invoice_generation_due: {
      label: 'Invoice Generation Due',
      view: (
        <InvoiceGenerationTable
          tab={'InvoiceGenerationDue'}
          addOnFilter={{
            status: 'Approved',
            // invoice_number: {$exists: false},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: `/expenserequest/finance`,
      addOnFilter: {
        status: 'Approved',
        // invoice_number: {$exists: false},
        ...filterValues.filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportdraftexpense',
              props: {
                addOnFilter: {
                  status: 'Approved',
                  // invoice_number: {$exists: false},
                  ...filterValues.filter,
                },
              },
            });
          }}
        />,
      ],
    },
    invoice_generation_done: {
      label: 'Invoice Generation Done',
      view: (
        <InvoiceGenerationTable
          tab={'InvoiceGenerationDone'}
          addOnFilter={{
            // invoice_number: {$exists: true},
            status: 'Booked',
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: `/expenserequest/finance`,
      addOnFilter: {
        status: 'Booked',
        // invoice_number: {$exists: true},
        ...filterValues.filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportdraftexpense',
              props: {
                addOnFilter: {
                  status: 'Booked',
                  // invoice_number: {$exists: true},
                  ...filterValues.filter,
                },
              },
            });
          }}
        />,
      ],
    },
    requested: {
      label: 'Requested',
      view: (
        <ExpenseRequestedAccountsTable
          addOnFilter={{...filterValues.filter}}
          {...props}
        />
      ),
      api: '/expenserequest/all',
      addOnFilter: {...filterValues.filter, status: 'Requested'},
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportdraftexpense',
              props: {
                addOnFilter: {...filterValues.filter, status: 'Requested'},
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
          ]}
        />,
      ]}
    />
  );
};
