import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const CampaignGlobalSettingForm = props => {
  const {
    route: {params},
    header = 'Add Campaign Global Setting',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignglobalsettings',
    eventSourceId: 'add-campaign-global-setting',
  });

  return (
    <Form
      fields={{
        business_summary_prompt: {name: 1},
        business_summary_finetune_model: {name: 1},
        auto_verified_prompt: {name: 1},
        auto_verification_use_case: {name: 1},
        auto_verification_finetune_model: {name: 1},
        personalization_prompt: {name: 1},
        manual_personalization_prompt: {name: 1},
        manual_linkedin_personalization_prompt: {name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      layoutFields={[
        {
          label: 'Business Summary Prompt',
          field: 'business_summary_prompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Business Summary Finetune Model',
          field: 'business_summary_finetune_model',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Auto Verified Email Prompt',
          field: 'auto_verified_prompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true, status: 'active'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Auto Verification Use Case',
          field: 'auto_verification_use_case',
          type: 'autoComplete',
          api: '/finetuneusecases',
          filter: {for_crm: true, status: 'active'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Auto Verification Finetune Model',
          field: 'auto_verification_finetune_model',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Personalization Prompt',
          field: 'personalization_prompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Manual Personalization Prompt',
          field: 'manual_personalization_prompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Manual Linkedin Personalization Prompt',
          field: 'manual_linkedin_personalization_prompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const EditCampaignGlobalSettingForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <CampaignGlobalSettingForm
      mode="edit"
      header={'Update Campaign Global Setting'}
      api={`/campaignglobalsettings/${_id}`}
      {...props}
    />
  );
};
