import React from 'react';
import {Table} from '../../../components/table/Table';
import moment from 'moment';
import {Text} from '@unthinkable/react-core-components';

const getStartAndEndOfDay = dateString => {
  if (!dateString) {
    throw new Error('No date string provided');
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string provided');
  }

  const startOfDay = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
      0,
    ),
  );

  const endOfDay = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59,
      59,
      999,
    ),
  );

  return {
    from: startOfDay.toISOString(),
    to: endOfDay.toISOString(),
  };
};

export const OutreachCampaignTableEmailProfileGrouped = props => {
  let {
    route: {params},
    navigation,
  } = props;

  let {verified, status, sent_action, _id} = params;

  let filter = {verified, status, email_profile: _id};

  return (
    <Table
      api={`/campaignactions/OutreachCampaignActionsEmailProfileGroupped`}
      params={{filter, sent_action}}
      onRowPress={({row}) => {
        let {completedOn, scheduledOn, actualScheduledOn, error_on} = row || {};
        let period = completedOn && getStartAndEndOfDay(completedOn);

        let scheduled_period = scheduledOn && getStartAndEndOfDay(scheduledOn);
        let error_period = error_on && getStartAndEndOfDay(error_on);

        let actual_scheduled_period =
          actualScheduledOn && getStartAndEndOfDay(actualScheduledOn);

        let extraFilter = {};
        let extraFields = {};

        if (sent_action && period) {
          extraFilter = {
            ...extraFilter,
            completedOn: {
              $gt: period.from,
              $lt: period.to,
            },
          };
          extraFields = {
            sort: {completedOn: 1},
          };
        } else {
          if (actual_scheduled_period) {
            extraFilter = {
              ...extraFilter,
              actualScheduledOn: {
                $gt: actual_scheduled_period.from,
                $lt: actual_scheduled_period.to,
              },
            };
          } else if (scheduled_period) {
            extraFilter = {
              ...extraFilter,
              scheduledOn: {
                $gt: scheduled_period.from,
                $lt: scheduled_period.to,
              },
            };
          }
          extraFields = {
            sort: {actualScheduledOn: 1},
          };
        }

        if (filter.status === 'error') {
          navigation.navigate('show-outreach-campaign-error', {
            addOnFilter: {
              email_profile: _id,
              error_on: {
                $gt: error_period.from,
                $lt: error_period.to,
              },
            },
          });
        } else {
          navigation.navigate('show-actions', {
            row,
            addOnFilter: {
              ...filter,
              ...extraFilter,
            },
            sent_action,
            ...extraFields,
            status,
          });
        }
      }}
      columns={[
        {
          header: 'Schedule On',
          field: 'scheduledOn',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {moment.utc(row?.scheduledOn).format('D MMM')}
              </Text>
            );
          },
          visible: () => {
            return filter.status === 'inprocess';
          },
        },
        {
          header: 'Error On',
          field: 'error_on',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {moment.utc(row?.error_on).format('D MMM')}
              </Text>
            );
          },
          visible: () => {
            return filter.status === 'error';
          },
        },
        {
          header: 'Scheduled On',
          field: 'actualScheduledOn',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {moment.utc(row?.actualScheduledOn).format('D MMM')}
              </Text>
            );
          },
          visible: () => {
            return (
              !sent_action &&
              filter.status != 'error' &&
              filter.status != 'inprocess'
            );
          },
        },
        {
          header: 'Completed On',
          field: 'completedOn',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {moment.utc(row?.completedOn).format('D MMM')}
              </Text>
            );
          },
          visible: () => {
            return (
              sent_action &&
              filter.status != 'error' &&
              filter.status != 'inprocess'
            );
          },
        },

        {header: 'Count', field: 'count', type: 'text', width: 150},
      ]}
      moreActions={[
        {
          title: 'Email Stats',
          onPress: ({row}) => {
            let {completedOn} = row || {};
            let period = completedOn && getStartAndEndOfDay(completedOn);
            navigation.navigate(`email-profile-stats-list`, {
              email_profile: _id,
              period,
              row,
            });
          },
          visible: () => {
            return filter.status === 'completed';
          },
        },
      ]}
    />
  );
};
