import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  ProbationConfirmationHrTabs,
  ProbationConfirmationManagerTabs,
} from './ProbationConfirmationTabs';
import {
  InternshipConfirmationHrTabs,
  InternshipConfirmationManagerTabs,
} from './InternshipConfirmationTabs';

const confirmatonNav = [
  {
    label: 'Probation',
    view: <ProbationConfirmationHrTabs />,
  },

  {
    label: 'Internship',
    view: <InternshipConfirmationHrTabs />,
  },
];

const confirmatonManagerNav = [
  {
    label: 'Probation',
    view: <ProbationConfirmationManagerTabs />,
  },

  {
    label: 'Internship',
    view: <InternshipConfirmationManagerTabs />,
  },
];

export const ConfirmationHrMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={confirmatonNav} />;
};

export const ConfirmationManagerMenu = props => {
  const {route: {params} = {}} = props;
  return (
    <LeftMenuView {...props} params={params} menus={confirmatonManagerNav} />
  );
};
