import React from 'react';
import {TabView} from '../../../components/tab';
import {ProjectThemeFontTable} from './ProjectThemeFontTable';
import {ProjectThemeColorTable} from './ProjectThemeColorTable';

export const ThemeLibraryVersionTab = props => {
  return (
    <TabView
      tabs={{
        colors: {
          label: 'Colors',
          view: <ProjectThemeColorTable {...props} />,
        },
        fonts: {
          label: 'Fonts',
          view: <ProjectThemeFontTable {...props} />,
        },
      }}
    />
  );
};
