import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {View, Text, Col} from '@unthinkable/react-core-components';

import {Form} from '../../../../components/form/Form';

export const AiRoleForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {role} = params;
  const {colors, fonts} = useTheme();

  console.log('role = ', role);

  let {description, model, user_stories, ...restRoles} = role;

  const formData = {
    description,
    model,
    user_stories,
    restRoles,
  };

  return (
    <Form
      {...props}
      data={formData}
      mode="edit"
      header="Screen IA Detail"
      readOnly={true}
      layoutFields={[
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
        },
        {
          label: 'Model',
          type: 'text',
          field: 'model',
        },
        {
          label: 'User Stories',
          field: 'user_stories',
          render: props => {
            const {value, label} = props;
            return (
              <Col gap={8}>
                <Text
                  style={{
                    ...fonts.CAPTION_SMALL,
                    color: colors.NEUTRAL_MEDIUM,
                  }}>
                  {label}
                </Text>
                <View>
                  {value.map((userStory, index) => (
                    <Text style={{...fonts.BODY3, color: colors.NEUTRAL_HIGH}}>
                      {`${index + 1}. ${userStory}`}
                    </Text>
                  ))}
                </View>
              </Col>
            );
          },
        },
        {
          label: 'Other Details',
          field: 'restRoles',
          type: 'json',
        },
      ]}
    />
  );
};
