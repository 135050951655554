import React from 'react';
import {Text, Col} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {getTime} from '../../modules/pmt/utility';

const ColorDiffCellRender = ({row}) => {
  let {name = '', last_activity_on} = row || {};

  let {latestActivityTextStyle, latestDateTextStyle} = useComponentTheme(
    'CountCellRenderStyles',
  );
  let isLastActive;

  if (last_activity_on) {
    let currentDate = new Date();

    currentDate.setDate(currentDate.getDate() - 30);

    let date1 = new Date(last_activity_on);

    let date2 = new Date(currentDate);

    if (date1 < date2) {
      isLastActive = last_activity_on;
    }
  }

  return (
    <Col style={latestActivityTextStyle}>
      <Text as="span">
        {name}
        {isLastActive ? (
          <Text as="span" style={latestDateTextStyle}>
            {' '}
            (Last activity {getTime(last_activity_on)})
          </Text>
        ) : null}
      </Text>
    </Col>
  );
};

export default ColorDiffCellRender;
