import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

export const ReimbursementCategoryTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="ReimbursementCategory"
      api={`/reimbursementCategory`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-category`, {
          reimbursement_category: row?._id,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Reimbursement Category"
            actions={[
              <AddButton
                title="Reimbursement Category"
                view="add-reimbursement-category"
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'type',
          header: 'Type',
        },
        {
          type: 'text',
          field: 'account.name',
          header: 'Account',
        },
      ]}
    />
  );
};
