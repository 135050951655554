import React, {useEffect} from 'react';
import {useSocketContext} from '../../providers/SocketProvider';

// Make a custom hook to listen for socket events. - Rajnish Trikaldarshi 17-02-2024
const useRealTimeSync = ({group, event, callBack}) => {
  const {socket, connect} = useSocketContext();
  useEffect(() => {
    if (socket) {
      group && socket.emit('subscribeGroup', {group});
      socket.on(event, callBack);
      return () => {
        socket.off(event, callBack);
      };
    } else {
      connect();
    }
  }, [callBack, socket, connect]);
};

export default useRealTimeSync;
