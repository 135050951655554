import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {Text, TouchableOpacity, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useFetchData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';
import moment from 'moment';
import {useState} from 'react';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  UserAvatarWithDateChip,
  UserAvatarWithNameAndEmail,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {AddButton} from '../../../components/button/AddButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import { UserAvatarChip } from '../../../components/avatar/UserAvatar';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const RenderStatus = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'pendingTimesheetteamreq',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  console.log('loading>', loading);
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/timesheets/${row._id}`,
                props: {
                  status: 'Log Approved',
                  approved_by_manager: true,
                  approved_on: new Date(),
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/timesheets/${row._id}`,
                props: {
                  status: 'Log Rejected',
                  rejected_on: new Date(),
                  rejected_by_manager: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </>
      )}
    </View>
  );
};

export const ProjectTimesheetTable = props => {
  let {
    user,
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      api={'/timesheets/project'}
      columns={[
        {header: 'Project', field: 'project'},
        {header: 'Customer', field: 'customer.name'},
        {header: 'Description', field: 'desc'},
      ]}
      moreActions={[
        {
          title: 'Add Timesheet',
          onPress: ({row}) => {
            navigation.navigate('add-timesheet', {
              ...params,
              row,
              user,
            });
          },
        },
        {
          title: 'Timesheets',
          onPress: ({row}) => {
            navigation.navigate('view-timesheets', {
              ...params,
              row,
              user,
            });
          },
        },
      ]}
      params={params}
    />
  );
};

export const NonProjectTimesheetTable = props => {
  let {
    route: {params},
  } = props;
  return (
    <Table
      eventSourceId={['nonProjectTimesheet']}
      api={'/timesheets/nonproject'}
      columns={[
        {header: 'Project', field: 'project.project'},
        {header: 'Customer', field: 'customer.name'},
        {header: 'Date', field: 'date', type: 'date'},
        {header: 'Hours', field: 'timesheet_hours'},
        {header: 'Description', field: 'description'},
      ]}
      params={params}
    />
  );
};

export const TimesheetsTable = props => {
  let {
    route: {params},
  } = props;
  const {row, user} = params;
  return (
    <Table
      renderHeader={() => {
        return <TableHeader title={'Timesheets'} />;
      }}
      api={'/timesheets'}
      filter={{
        project: row?._id,
        employee: row?.owner_id?._id,
      }}
      fields={{
        project: {project: 1},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1},
        date: 1,
        timesheet_hours: 1,
        description: 1,
      }}
      columns={[
        {header: 'Project', field: 'project.project'},
        {header: 'Customer', field: 'customer.name'},
        {header: 'Date', field: 'date', type: 'date'},
        {header: 'Hours', field: 'timesheet_hours'},
        {header: 'Description', field: 'description'},
      ]}
      params={params}
    />
  );
};

export const Timesheet = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;

  const {date, employee} = row;
  const rowDate = new Date(date);
  const currentdate = new Date();
  const colors = useTheme('colors');

  const {fetch} = useAppStateContext();

  const renderText = (text, color, onPress) => (
    <View>
      {onPress ? (
        <TouchableOpacity onPress={onPress}>
          <Text style={{...rowText, overflow: 'hidden', color}}>{text}</Text>
        </TouchableOpacity>
      ) : (
        <Text style={{...rowText, overflow: 'hidden', color}}>{text}</Text>
      )}
    </View>
  );

  const renderLoggedText = onPress => (
    <View>
      <TouchableOpacity onPress={onPress}>
        <Text style={{...rowText, overflow: 'hidden', color: '#39e75f'}}>
          Logged
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              overflow: 'hidden',
            }}>
            {row?.timesheet_hours ? `${row?.timesheet_hours}h(Attendance)` : ''}
          </Text>
          <View
            style={{
              margin: 5,
              height: 5,
              width: 5,
              backgroundColor: colors.NEUTRAL_LOW,
              borderRadius: 50,
            }}></View>
          {row?.billable_to_customer_hours ? (
            <>
              <Text
                numberOfLines={1}
                style={{
                  ...rowText,
                  fontSize: 12,
                  color: colors.NEUTRAL_MEDIUM,
                  overflow: 'hidden',
                }}>
                {row?.billable_to_customer_hours
                  ? `${row?.billable_to_customer_hours}h(Billable)`
                  : ''}
              </Text>
              <View
                style={{
                  margin: 5,
                  height: 5,
                  width: 5,
                  backgroundColor: colors.NEUTRAL_LOW,
                  borderRadius: 50,
                }}></View>
            </>
          ) : (
            void 0
          )}

          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              overflow: 'hidden',
            }}>
            {row?.description || ''}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );

  const renderLoggedRejectedText = () => (
    <View>
      <Text style={{...rowText, overflow: 'hidden', color: '#FF5247'}}>
        Rejected
      </Text>
    </View>
  );

  if (['New', 'Log Approved'].includes(row.status)) {
    return renderText('Log due', '#007AFF', async () => {
      const {data: employeeProject} = await fetch({
        uri: '/employeeassignments/projects',
        props: {
          filter: {
            employee: row?.employee?._id,
            team_id: {$exists: false},
            to_date: {
              $gte: date,
            },
          },
        },
      });

      const view =
        employeeProject && employeeProject.length > 0
          ? 'log-project-timesheet'
          : 'log-non-project-timesheet';
      navigation.navigate(view, {row});
    });
  } else if (row.status === 'Overdue') {
    return renderText('Request', '#007AFF', () =>
      navigation.navigate('timesheet-request-form-row-action', {row}),
    );
  } else if (row.status === 'Log Requested') {
    return renderText('Requested', '#f8952d');
  } else if (row.status === 'Log Done') {
    return renderLoggedText(async () => {
      const {data: employeeProject} = await fetch({
        uri: '/employeeassignments/projects',
        props: {
          filter: {
            employee: row?.employee?._id,
            team_id: {$exists: false},
            to_date: {
              $gte: date,
            },
          },
        },
      });

      const detailView =
        employeeProject && employeeProject.length > 0
          ? 'project-timesheet-progress'
          : 'timesheet-non-project-detail';
      navigation.navigate(detailView, {row});
    });
  } else if (row.status === 'Log Rejected') {
    return renderLoggedRejectedText();
  }

  return null;
};

export const TimesheetRequest = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  return (
    <View>
      <Text
        style={{...rowText, overflow: 'hidden', color: colors.NEUTRAL_MEDIUM}}>
        {row?.request_reason}
      </Text>
    </View>
  );
};

export const TimesheetsRequestTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {row, user} = params;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api={'/timesheets'}
      filter={{
        employee: employeeId,
      }}
      fields={{
        project: {project: 1},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1, project: 1},
        date: 1,
        timesheet_hours: 1,
        description: 1,
        status: 1,
        billable_to_customer_hours: 1,
        log_more_than_nine_hrs: 1,
        attatchment: 1,
        request_reason: 1,
        request_date: 1,
      }}
      sort={{date: -1}}
      eventSourceId={['nonProjectTimesheet', 'projectTimesheet']}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 200,
          render: ({row, styles}) => {
            const date = new Date(row?.date);
            const dayOfWeek = daysOfWeek[date.getUTCDay()];
            return (
              <PrimaryColumnCard
                primaryTitle={row.date && moment(row.date).format('DD MMM YY')}
                items={[{value: dayOfWeek}]}
              />
            );
          },
        },
        {
          header: 'Projects',
          field: 'project',
          labelField: 'project',
          colorField: 'color',
          titleField: 'project',
          render: MultiColorTag,
        },
        {
          header: 'Timesheet',
          render: props => {
            return (
              <Timesheet {...props} params={params} navigation={navigation} />
            );
          },
        },
      ]}
      // moreActions={[
      //   {
      //     title: 'Add More Progress',
      //     onPress: ({row}) => {
      //       navigation.navigate('project-timesheet-progress', {
      //         ...params,
      //         row,
      //       });
      //     },
      //   },
      // ]}
      params={params}
    />
  );
};

export const PendingTimesheetRequests = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  let addOnFilter = {...filter, status: 'Log Requested'};
  return (
    <Table
      api={'/timesheets/team-request'}
      params={params}
      eventSourceId={'pendingTimesheetteamreq'}
      addOnFilter={addOnFilter}
      search={searchValue}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {row?.request_date && moment(row?.request_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          width: 300,
          type: 'text',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Duration',
          type: 'date',
          width: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.date && moment(row?.date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'right',
          width: 200,
          render: ({row, styles}) => <RenderStatus row={row} styles={styles} />,
        },
      ]}
    />
  );
};

export const ApprovedTeamTimesheetRequests = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {
    ...filter,
    status: {$in: ['Log Approved', 'Log Done', 'Log Rejected']},
    approved_by_manager: true,
  };
  return (
    <Table
      api={'/timesheets/team-request'}
      params={params}
      eventSourceId={'acceptedtimesheetteamreq'}
      addOnFilter={addOnFilter}
      search={searchValue}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {row?.request_date && moment(row?.request_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          width: 300,
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Duration',
          width: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.date && moment(row?.date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Status',
          type: 'text',
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            if (row?.status === 'Log Rejected') {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItem: 'center',
                    color: colors.NEUTRAL_MEDIUM,
                  }}>
                  <Text
                    style={{...rowText, overflow: 'hidden', color: '#FF5247'}}>
                    Rejected
                  </Text>
                </View>
              );
            } else {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItem: 'center',
                    color: colors.NEUTRAL_MEDIUM,
                  }}>
                  <Text
                    style={{...rowText, overflow: 'hidden', color: '#39e75f'}}>
                    {row?.status}
                  </Text>
                </View>
              );
            }
          },
        },
      ]}
    />
  );
};

export const RejectedTeamTimesheetRequests = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {...filter, status: 'Log Rejected'};
  return (
    <Table
      api={'/timesheets/team-request'}
      params={params}
      eventSourceId={'rejectedtimesheetteamreq'}
      addOnFilter={addOnFilter}
      search={searchValue}
      columns={[
        {
          header: 'Employee',
          width: 300,
          render: props => (
            <UserAvatarWithDateChip
              field={'employee'}
              dateField={'request_date'}
              requested
              {...props}
            />
          ),
        },
        {
          header: 'Requested For',
          type: 'date',
          width: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.date && moment(row?.date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Reason',
          render: props => {
            return (
              <TimesheetRequest
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
        },
        {
          header: 'Rejected On',
          width: 200,
          render: ({row, styles}) => {
            let {rowText} = styles;
            if (row && row.rejected_on) {
              return (
                <Text style={{...rowText}}>
                  {row.rejected_on && moment(row.rejected_on).format('DD MMM YY')}
                </Text>
              );
            }
          },
        },
      ]}
    />
  );
};

export const TimesheetsProgressTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {row} = params;
  const {user} = useAppStateContext();
  return (
    <Table
      api={'/timesheetprogresses'}
      filter={{
        employee: row?.employee?._id,
        timesheet: row?._id,
      }}
      renderHeader={() => (
        <TableHeader
          title="Timesheet Progress"
          actions={[
            <AddButton
              title="Add Progress"
              view="log-project-timesheet"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        project: {project: 1},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1, project: 1},
        date: 1,
        timesheet_hours: 1,
        description: 1,
        status: 1,
        billable_to_customer_hours: 1,
        log_more_than_nine_hrs: 1,
        attatchment: 1,
        timesheet: 1,
      }}
      sort={{date: -1}}
      eventSourceId={['nonProjectTimesheet', 'projectTimesheet']}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Project',
          field: 'project.project',
          type: 'colorTag',
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
        },
        {
          header: 'Billable Hours',
          field: 'billable_to_customer_hours',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'Edit Progress',
          onPress: ({row}) => {
            navigation.navigate('edit-timesheet-progress', {
              ...params,
              row,
              user,
            });
          },
        },
      ]}
      params={params}
    />
  );
};

export const TimesheetsTandMProgressTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {row, period, employeeFilter = {}} = params;

  const {user} = useAppStateContext();
  let addOnFilter = {};
  if (row?.project_resource_employee) {
    addOnFilter = {
      ...addOnFilter,
      employee: {$in: row.project_resource_employee},
    };
  }
  if (row?.recur_id) {
    addOnFilter = {
      ...addOnFilter,
      skill_requirement: row.recur_id,
    };
  }
  if (period) {
    addOnFilter = {
      ...addOnFilter,
      date: {$gte: period.from, $lte: period.to},
    };
  }

  if (Object.keys(employeeFilter)?.length) {
    addOnFilter = {
      ...addOnFilter,
      ...employeeFilter,
    };
  }

  return (
    <Table
      api={'/timesheetprogresses'}
      renderHeader={() => <TableHeader title="Timesheet Progress" />}
      fields={{
        project: {project: 1},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1, project: 1},
        date: 1,
        timesheet_hours: 1,
        description: 1,
        status: 1,
        billable_to_customer_hours: 1,
        log_more_than_nine_hrs: 1,
        attatchment: 1,
        timesheet: 1,
      }}
      sort={{date: -1}}
      eventSourceId={['nonProjectTimesheet', 'projectTimesheet']}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Project',
          field: 'project.project',
          type: 'colorTag',
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
        },
        {
          header: 'Billable Hours',
          field: 'billable_to_customer_hours',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'Edit Progress',
          onPress: ({row}) => {
            navigation.navigate('edit-timesheet-progress', {
              ...params,
              row,
              user,
            });
          },
        },
      ]}
      params={params}
    />
  );
};

export const SelfPendingTimesheetRequests = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  let addOnFilter = {...filter, status: 'Log Requested'};
  return (
    <Table
      api={'/timesheets/self-request'}
      params={params}
      eventSourceId={'pendingTimesheetteamreq'}
      addOnFilter={addOnFilter}
      search={searchValue}
      sort={{request_date: -1}}
      columns={[
        {
          header: 'Requested On',
          minWidth: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.request_date && moment(row?.request_date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Duration',
          type: 'number',
          width: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.date && moment(row?.date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
      ]}
    />
  );
};

export const SelfApprovedTeamTimesheetRequests = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {
    ...filter,
    status: {$in: ['Log Approved', 'Log Done', 'Log Rejected']},
    approved_by_manager: true,
  };
  return (
    <Table
      api={'/timesheets/self-request'}
      params={params}
      eventSourceId={'acceptedtimesheetteamreq'}
      addOnFilter={addOnFilter}
      search={searchValue}
      sort={{approved_on: -1}}
      columns={[
        {
          header: 'Requested On',
          minWidth: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.request_date && moment(row?.request_date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Duration',
          type: 'number',
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.date && moment(row?.date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Status',
          type: 'number',
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            if (row?.status === 'Log Rejected') {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItem: 'center',
                    color: colors.NEUTRAL_MEDIUM,
                  }}>
                  <Text
                    style={{...rowText, overflow: 'hidden', color: '#FF5247'}}>
                    Rejected
                  </Text>
                </View>
              );
            } else {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItem: 'center',
                    color: colors.NEUTRAL_MEDIUM,
                  }}>
                  <Text
                    style={{...rowText, overflow: 'hidden', color: '#39e75f'}}>
                    {row?.status}
                  </Text>
                </View>
              );
            }
          },
        },
      ]}
    />
  );
};
