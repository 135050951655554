import React from 'react';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {useFetchData} from '../../../controllers/useFetchData';
import {Chip} from '@unthinkable/react-chip';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {TableHeader} from '../../../components/table/Headers';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

export const TeamAllocationsRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={row?.allocations || 0}
      borderRadius={6}
    />
  );
};

const RenderEff = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            color: colors.INFORMATION_HIGH,
            overflow: 'hidden',
            fontSize: 15,
            fontFamily: 'Inter-Regular',
          }}>
          View Efficiency
        </Text>
      </>
    );
  }
};

const RenderSkills = props => {
  const {row} = props;
  const skillName = row?.skill_tool
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

const RenderProjectType = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.projecttype) {
    if (row.projecttype == 'f') {
      return (
        <>
          <Tag value={'Fixed'} color={'#DAFDFE'} />
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {row?.project_sub_category || ''}
          </Text>
        </>
      );
    } else if (row.projecttype == 'tm') {
      return (
        <>
          <Tag value={'T&M'} color={'#FCF4D4'} />
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {row?.project_sub_category || ''}
          </Text>
        </>
      );
    }
  }
  if (!row?.projecttype && row?.project_sub_category) {
    return (
      <>
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {row?.project_sub_category || ''}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

export const CustomerProjectTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  let {data: projectData} = useFetchData({
    api: `/customer/active-projects`,
    params: {
      customerProjects: true,
    },
    filter: {
      project_status: 'a',
      customer: row?._id,
    },
  });

  return (
    <Table
      data={projectData}
      columns={[
        {
          header: 'Project',
          field: 'project',
          // width: 400
        },

        {
          header: 'Type',
          render: RenderProjectType,
          width: 150,
        },
        {
          header: 'PM',
          field: 'owner_id',
          type: 'userAvatar',
          align: 'center',
          width: 150,
        },
        {
          header: 'Teams',
          render: TeamAllocationsRender,
          onPress: ({row}) => {
            navigation.navigate('project-wise-team-efficiency', {
              project: {_id: row?._id},
            });
          },
        },

        {
          header: 'Avg. Exp',
          field: 'avg_exp',
          width: 100,
        },
        {
          header: 'Efficiency',
          render: RenderEff,
          onPress: ({row}) => {
            navigation.navigate('project-wise-feature-efficiency', {
              project: {_id: row?._id},
            });
          },
        },
      ]}
    />
  );
};

export const CustomerInactiveProjectTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  let {data: projectData} = useFetchData({
    api: `/customer/active-projects`,
    params: {
      customerProjects: true,
    },
    filter: {
      project_status: 'c',
      customer: row?._id,
    },
  });

  return (
    <Table
      data={projectData}
      columns={[
        {
          header: 'Project',
          field: 'project',
        },

        {
          header: 'Type',
          render: RenderProjectType,
          width: 150,
        },
        {
          header: 'PM',
          field: 'owner_id',
          type: 'userAvatar',
          align: 'center',
          width: 150,
        },
        {
          header: 'Teams',
          render: TeamAllocationsRender,
          onPress: ({row}) => {
            navigation.navigate('project-wise-team-efficiency', {
              project: {_id: row?._id},
            });
          },
        },

        {
          header: 'Avg. Exp',
          field: 'avg_exp',
          width: 100,
        },
        {
          header: 'Efficiency',
          render: RenderEff,
          onPress: ({row}) => {
            navigation.navigate('project-wise-feature-efficiency', {
              project: {_id: row?._id},
            });
          },
        },
      ]}
    />
  );
};

export const CustomerProjectTeam = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const date = getZeroTimeDate(new Date());
  return (
    <Table
      api={`/customer/active-projects/teams`}
      renderHeader={() => <TableHeader title={'Team'} />}
      filter={{
        project_id: row,
        from_date: {
          $lte: date,
        },
        to_date: {
          $gte: date,
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: props => {
            return (
              <UserAvatarWithNameAndEmail
                name={props?.row?.name}
                color={props?.row?.color}
                official_email_id={props?.row?.official_email_id}
              />
            );
          },
          width: 300,
        },
        {
          header: 'Exp',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 350,
        },
        {
          header: 'Skills',
          render: RenderSkills,
        },
      ]}
    />
  );
};

export const CustomerProjectTabs = props => {
  const {route: {params} = {}} = props;
  const filtersInfo = useFilter();
  const {row} = params;

  const {onChangeFilter, searchValue, filterValues = {}} = filtersInfo;
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        active: {
          label: 'Active',
          api: '/projects',
          filter: {
            project_status: 'a',
            customer: row?._id,
          },
          view: (
            <CustomerProjectTable
              params={params}
              searchValue={searchValue}
              {...filterValues}
            />
          ),
        },
        completed: {
          label: 'Inactive',
          api: '/projects',
          filter: {
            project_status: 'c',
            customer: row?._id,
          },
          view: (
            <CustomerInactiveProjectTable
              params={params}
              searchValue={searchValue}
              {...filterValues}
            />
          ),
        },
      }}
    />
  );
};
