import React, {useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Col} from '@unthinkable/react-core-components';
import {useToast} from '@unthinkable/react-toast';
import {useStyles} from '@unthinkable/react-theme';
import {TextInput} from '../../../components/form-editors/Editors';

import {EmailFormStyles} from './theme';
import {useResetPassword} from '../../../modules/login/controllers';
import {ResetPasswordForm} from './ResetPasswordForm';

export const EmailForm = props => {
  const {route: {params} = {}} = props || {};
  const {email} = params || {};
  const navigation = useNavigation();
  const {onForgotPassword} = useResetPassword({
    forgotPasswordApi: '/users/forgot-password',
  });
  const toast = useToast();

  const {inputContainerStyle} = useStyles(EmailFormStyles);

  const [data, updateData] = useState({email});

  const validateEmail = async () => {
    const result = data.email?.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    if (result) {
      const apiResult = await onForgotPassword(data);

      if (apiResult === true) {
        navigation.navigate('confirm-otp', {email: data?.email});
      } else if (apiResult?.error) {
        toast({message: apiResult.error, type: 'Error'});
      }
    } else {
      toast({
        message: 'Please enter a valid email address.',
        type: 'Error',
      });
    }
  };

  return (
    <ResetPasswordForm
      heading={'Reset Password'}
      subHeading={`No worries! Enter your email address below and we will send you a
    code to reset password.`}
      buttonText={`Send Link`}
      onButtonPress={validateEmail}
      disabled={!data.email}>
      <Col
        gap={16}
        style={[inputContainerStyle, {jusifyContent: 'flex-start'}]}>
        <TextInput
          label="Email"
          value={data['email']}
          onChangeText={text => {
            updateData({...data, email: text});
          }}
          onSubmitEditing={validateEmail}
          inputProps={{autoFocus: true}}
          required
        />
      </Col>
    </ResetPasswordForm>
  );
};
