import React from 'react';
import {Table} from '../../../components/table/Table';
import { useInvoke } from '../../../controllers/useInvoke';

export const AccessResourceTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'accessResourceRemoved',
  });
  const deleteTask = ({row}) => {
    deleteInvoke({
      uri: `/accessresources/${row?._id}`,
      props: {},
    });
  };
  return (
    <Table
      eventSourceId={['accessResourceAdded', 'accessResourceRemoved']}
      api={`/accessresources`}
      fields={{resource: 1}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-access-resource`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'resource',
          header: 'Resource',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: deleteTask,
        },
      ]}
    />
  );
};
