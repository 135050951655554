import React from 'react';
import {Table} from '../../../components/table/Table';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import moment from 'moment';
import {TextRenderer} from '../../../components/form/Renderers';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter} from '../../../components/filter/Filters';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';

const RenderDuration = ({row, rest}) => {
  const {
    client_feedback: {client_feedback_from_date, client_feedback_to_date} = {},
  } = row;
  return (
    <TextRenderer
      {...rest}
      title={
        (client_feedback_from_date
          ? moment(client_feedback_from_date).format('DD MMM YY')
          : '') +
        ' - ' +
        (client_feedback_to_date
          ? moment(client_feedback_to_date).utc().format('DD MMM YY')
          : '')
      }
      value={
        (client_feedback_from_date
          ? moment(client_feedback_from_date).format('DD MMM YY')
          : '') +
        ' - ' +
        (client_feedback_to_date
          ? moment(client_feedback_to_date).utc().format('DD MMM YY')
          : '')
      }
    />
  );
};

export const ResourceWiseClientFeedbackTable = props => {
  const {
    route: {params},
  } = props;
  const {project} = params;
  return (
    <Table
      api="/resourceWiseClientFeedbacks"
      filter={{
        project: project?._id,
      }}
      sort={{_id: -1}}
      fields={{
        rating: 1,
        comment: 1,
        name: 1,
        resource: {
          name: 1,
          color: 1,
          officilal_email_id: 1,
        },
        client_feedback: {
          date: 1,
          client_feedback_from_date: 1,
          client_feedback_to_date: 1,
          request_date: 1,
          client: {
            name: 1,
            color: 1,
            email: 1,
          },
        },
      }}
      eventSourceId="ResourceWiseClientFeedback"
      columns={[
        {
          type: 'date',
          field: 'client_feedback.request_date',
          header: 'Requested On',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Resource',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.name}
              color={row?.color}
              official_email_id={row?.officilal_email_id}
            />
          ),
          width: 200,
        },
        {
          render: RenderDuration,
          header: 'Duration',
          width: 180,
        },
        {
          header: 'CSAT',
          render: ({row}) => <RatingRenderer value={row?.rating} />,
          width: 120,
        },
        {
          header: 'Remarks',
          field: 'comment',
          type: 'text',
        },
        {
          header: 'Submitted On',
          field: 'client_feedback.date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Reviewer',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.client_feedback?.client?.name}
              color={row?.client_feedback?.client?.color}
              official_email_id={row?.client_feedback?.client?.email}
            />
          ),
          width: 220,
        },
      ]}
    />
  );
};

export const ClientFeedbackReportTable = props => {
  const {navigation} = props;
  const {filterValues, applyFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {params: filterParams, filter: addOnFilter = {}} = filterValues;
  const {period} = filterParams;
  const {from, to} = period;
  return (
    <Table
      addOnFilter={addOnFilter}
      renderHeader={() => (
        <TableHeader
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'date',
                  field: 'period',
                  asParam: true,
                  label: 'Period',
                },
                {
                  type: 'multiAutoComplete',
                  field: 'account_owner',
                  label: 'Account Owner',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                },
              ]}
            />,
          ]}
        />
      )}
      params={filterParams}
      api="/getClientFeedbackReport"
      columns={[
        {
          header: 'Type',
          field: row => (row['projecttype'] == 'tm' ? 'T&M' : 'Fixed'),
          type: 'text',
        },
        {
          header: 'Total',
          field: 'total',
          type: 'number',
          width: 120,
          onPress: ({row}) =>
            navigation.navigate('client-feedback-project-overview', {
              row,
              ...filterParams,
              filter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            }),
        },
        {
          field: 'feedback_requested',
          header: 'Feedback Requested',
          type: 'number',
          width: 160,
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'feedback_given',
          header: 'Feedback Given',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
                feedback_requested: false,
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'feedback_not_requested',
          header: 'Feedback Not Requested',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('no-client-feedback-projects', {
              row,
              ...filterParams,
              filter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'feedback_requested_but_not_given',
          header: 'Feedback Requested But Not Given',
          type: 'number',
          width: 250,
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
                feedback_requested: true,
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'avg_client_score',
          header: 'Avg. CSAT',
          type: 'number',
          width: 100,
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'CSAT < 4',
          type: 'number',
          field: 'client_score_lt4',
          width: 80,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
                client_score: {
                  $lt: 4,
                },
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
      ]}
    />
  );
};

export const AMClientFeedbackReportTable = props => {
  const {navigation} = props;
  const {filterValues, applyFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {params: filterParams} = filterValues;
  const {period} = filterParams;
  const {from, to} = period;

  const {user = {}} = useAppStateContext();
  const {employee = {}} = user;
  const addOnFilter = {
    account_owner: employee?._id,
  };

  return (
    <Table
      addOnFilter={addOnFilter}
      renderHeader={() => (
        <TableHeader
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'date',
                  field: 'period',
                  asParam: true,
                  label: 'Period',
                },
              ]}
            />,
          ]}
        />
      )}
      params={filterParams}
      api="/getClientFeedbackReport"
      columns={[
        {
          header: 'Type',
          field: row => (row['projecttype'] == 'tm' ? 'T&M' : 'Fixed'),
          type: 'text',
        },
        {
          header: 'Total',
          field: 'total',
          type: 'number',
          width: 120,
          onPress: ({row}) =>
            navigation.navigate('client-feedback-project-overview', {
              row,
              ...filterParams,
              filter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            }),
        },
        {
          field: 'feedback_requested',
          header: 'Feedback Requested',
          type: 'number',
          width: 160,
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'feedback_given',
          header: 'Feedback Given',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
                feedback_requested: false,
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'feedback_not_requested',
          header: 'Feedback Not Requested',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('no-client-feedback-projects', {
              row,
              ...filterParams,
              filter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'feedback_requested_but_not_given',
          header: 'Feedback Requested But Not Given',
          type: 'number',
          width: 250,
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
                feedback_requested: true,
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
        {
          field: 'avg_client_score',
          header: 'Avg. CSAT',
          type: 'number',
          width: 100,
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'CSAT < 4',
          type: 'number',
          field: 'client_score_lt4',
          width: 80,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          onPress: ({row}) => {
            navigation.navigate('project-feedback-report-overview', {
              row,
              ...filterParams,
              filter: {
                client_feedback_to_date: {
                  $gte: new Date(from),
                  $lte: new Date(to),
                },
                client_score: {
                  $lt: 4,
                },
              },
              projectFilter: {
                $and: [
                  {
                    $or: [
                      {
                        project_status: 'c',
                        completed_on: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'h',
                        on_hold_date: {
                          $gte: new Date(from),
                        },
                      },
                      {
                        project_status: 'a',
                      },
                    ],
                  },
                  {
                    projecttype: row.projecttype,
                    ...(row.projecttype == 'f'
                      ? {
                          new_project_type: 'sdlcplus',
                        }
                      : {}),
                  },
                  {
                    $or: [
                      {
                        created_on: {
                          $lte: new Date(to),
                        },
                      },
                      {
                        project_start_date: {
                          $lte: new Date(to),
                        },
                      },
                    ],
                  },
                  addOnFilter,
                ],
              },
            });
          },
        },
      ]}
    />
  );
};
