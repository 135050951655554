import {IconButton} from '@unthinkable/react-button';
import {TouchableOpacity, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {UserAvatarGroup} from '../../../components/avatar/UserAvatar';
import {Tag} from '../../../components/chip/Chip';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {Form, InlineForm} from '../../../components/form/Form';
import {TextRenderer} from '../../../components/form/Renderers';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

const RenderAssignmentType = ({row}) => {
  const {employee} = row;
  const {employee_current_non_training_assignment = {}} = employee;
  const {assigned_type = 'Bench'} =
    employee_current_non_training_assignment || {};
  return <TextRenderer value={assigned_type} />;
};

const RenderStatus = ({row}) => {
  const statusConsts = {
    active: 'Ongoing',
    extended: 'Extended',
    completed: 'Completed',
    dropped: 'Dropped',
  };

  const value =
    new Date(row?.from_date) < new Date()
      ? statusConsts[row?.['status']]
      : 'Not started';

  const colorMap = {
    active: '#E0F5FF',
    extended: '#FFCC00',
    completed: '#E0FFE8',
  };

  const color =
    new Date(row?.from_date) < new Date()
      ? colorMap[row?.['status']]
      : '#EDEDED';

  return <Tag value={value} color={color} />;
};

const RenderFeedback = ({row, ...rest}) => {
  const {training_feedbacks = []} = row;
  training_feedbacks.filter = feed => feed.overall_score;
  const sum = training_feedbacks.reduce((acc, one) => {
    return acc + (one.overall_score || 0);
  }, 0);
  const avg_rating = sum / (training_feedbacks.length || 1);
  return (
    <View
      style={{
        alignItems: 'center',
      }}>
      <RatingRenderer value={avg_rating} />
      <TextRenderer
        {...rest}
        value={Number.parseFloat((avg_rating / 5) * 100).toFixed(2) + '%'}
      />
    </View>
  );
};

const AddTraineeHeader = props => {
  const {mentors, mentorship_type, navigation, defaultValues, training_batch} =
    props;
  const {Upload} = useTheme('icons');
  return (
    <View
      style={{
        backgroundColor: '#FAFAFA',
        flexDirection: 'row',
        gap: 4,
        padding: 12,
        marginBottom: 12,
      }}>
      <TextRenderer value={`${mentorship_type}: `} />
      <UserAvatarGroup value={mentors} />
      <TouchableOpacity
        style={{
          flex: 1,
          alignItems: 'flex-end',
        }}
        onPress={() => {
          navigation.navigate('import-trainees', {
            defaultValues,
            training_batch: training_batch,
          });
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 4,
          }}>
          <IconButton icon={Upload} />
          <TextRenderer value={'Trainees'} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const EmployeeTrainingThroughBatchTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_batch} = params;

  let {
    training_program,
    mentor,
    mentors: mentorsSuggestion = [],
    topic_wise_mentors = [],
  } = training_batch || {};
  let defaultValues = {
    training_program,
    training_batch,
  };

  const oneOnOneInlineForm = props => {
    const {onSubmit} = useFormSubmit({
      eventSourceId: 'EmployeeTrainingsFromBatch',
      uri: '/addEmployeeTraining',
      ...props,
    });
    return (
      <InlineForm
        onSubmit={onSubmit}
        submitAction="Save"
        defaultValues={defaultValues}
        layoutFields={[
          {
            label: 'Trainer',
            placeholder: 'Trainer',
            field: 'mentor',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            required: true,
            secondarySuggestionField: 'official_email_id',
            filter: {_id: {$in: mentorsSuggestion.map(i => i._id)}},
            size: 4,
          },
          {
            label: 'Trainee',
            placeholder: 'Trainee',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            secondarySuggestionField: 'official_email_id',
            size: 4,
          },
          {
            label: 'Per Day Allocation',
            field: 'per_day_allocation',
            type: 'number',
            required: true,
            size: 4,
          },
        ]}
      />
    );
  };

  const singleTrainerInlineForm = props => {
    let {onSubmit} = useFormSubmit({
      eventSourceId: 'EmployeeTrainingsFromBatch',
      uri: '/addEmployeeTraining',
      ...props,
    });

    defaultValues['mentor'] = mentor;
    defaultValues['mentorship_type'] = training_batch?.mentorship_type;

    return (
      <InlineForm
        onSubmit={onSubmit}
        submitAction="Save"
        defaultValues={defaultValues}
        layoutFields={[
          {
            label: 'Trainee',
            placeholder: 'Trainee',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            secondarySuggestionField: 'official_email_id',
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            size: 6,
          },
          {
            label: 'Per Day Allocation',
            field: 'per_day_allocation',
            type: 'number',
            required: true,
            size: 6,
          },
        ]}
      />
    );
  };

  const topicTrainerInlineForm = props => {
    let {onSubmit} = useFormSubmit({
      eventSourceId: 'EmployeeTrainingsFromBatch',
      uri: '/addEmployeeTraining',
      ...props,
    });

    defaultValues['mentor'] = topic_wise_mentors[0].mentor;
    defaultValues['skill_topic'] = topic_wise_mentors[0].skill_topic;
    defaultValues['mentorship_type'] = 'Topic-wise Trainers';
    defaultValues['from_date'] = topic_wise_mentors[0].from_date;
    defaultValues['to_date'] = topic_wise_mentors[0].to_date;
    defaultValues['feedback_cycle'] = topic_wise_mentors[0].feedback_cycle;

    return (
      <InlineForm
        onSubmit={onSubmit}
        submitAction="Save"
        defaultValues={defaultValues}
        layoutFields={[
          {
            label: 'Trainee',
            placeholder: 'Trainee',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            secondarySuggestionField: 'official_email_id',
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            size: 6,
          },
          {
            label: 'Per Day Allocation',
            field: 'per_day_allocation',
            type: 'number',
            required: true,
            size: 6,
          },
        ]}
      />
    );
  };

  let inlineForm = singleTrainerInlineForm;

  if (training_batch?.mentorship_type == 'Resource-wise Trainers')
    inlineForm = oneOnOneInlineForm;

  if (training_batch?.mentorship_type == 'Topic-wise Trainers')
    inlineForm = topicTrainerInlineForm;

  let invoke = useInvoke({
    method: 'put',
    eventSourceId: ['EmployeeTrainingsFromBatch'],
  });

  let mentors = [mentor];

  if (training_batch?.mentorship_type == 'Resource-wise Trainers') {
    mentors = [...mentorsSuggestion];
  }

  if (training_batch?.mentorship_type == 'Topic-wise Trainers') {
    mentors = [...topic_wise_mentors.map(item => item.mentor)];
  }

  return (
    <>
      <TableHeader title="Trainees" secondaryTitle={training_batch.name} />
      <AddTraineeHeader
        mentorship_type={training_batch?.mentorship_type}
        mentors={mentors}
        defaultValues={defaultValues}
        navigation={navigation}
        training_batch={training_batch}
      />
      {inlineForm(props)}
      <Table
        fields={{
          employee: {
            name: 1,
            color: 1,
            employee_current_non_training_assignment: {
              _id: 1,
              assigned_type: 1,
            },
          },
          mentor: {
            name: 1,
            color: 1,
          },
          from_date: 1,
          to_date: 1,
          next_feedback_date: 1,
          extended_date: 1,
          status: 1,
          training_batch: {
            training_program: {
              name: 1,
            },
            batch_number: 1,
            name: 1,
          },
          skill_topic: {
            name: 1,
          },
          training_session: {
            name: 1,
          },
          training_feedbacks: {
            _id: 1,
            overall_score: 1,
          },
        }}
        api="/employeeTrainings"
        eventSourceId={['EmployeeTrainingsFromBatch', 'EmployeeTrainings']}
        filter={{
          training_batch,
          status: {$ne: 'deleted'},
        }}
        columns={[
          {
            header: 'Trainer',
            field: 'mentor',
            type: 'userAvatarChip',
            visible:
              training_batch.mentorship_type == 'Resource-wise Trainers' ||
              training_batch.mentorship_type == 'Topic-wise Trainers',
          },
          {
            header: 'Topic',
            field: 'skill_topic.name',
            type: 'text',
            visible: training_batch.mentorship_type == 'Topic-wise Trainers',
          },
          {
            header: 'Trainee',
            field: 'employee',
            type: 'userAvatarChip',
          },
          {
            header: 'Billing Status',
            type: 'text',
            render: RenderAssignmentType,
          },
          {
            header: 'Status',
            render: RenderStatus,
            type: 'text',
            width: 160,
          },
          {
            header: 'Feedback',
            render: RenderFeedback,
            onPress: ({row}) => {
              navigation.navigate('my-feedbacks', {
                employee_training: {
                  _id: row._id,
                  employee: row.employee,
                },
                training_batch: row?.training_batch,
              });
            },
            align: 'center',
          },
        ]}
        moreActions={[
          {
            title: 'Drop',
            confirm: {
              title: 'Drop Trainee',
              message: 'Do you really want to drop this trainee?',
              confirmText: 'Drop',
            },
            onPress: ({row}) => {
              invoke({
                uri: '/employeeTrainings/' + row?._id,
                props: {
                  status: 'dropped',
                },
              });
            },
          },
          {
            title: 'Extend',
            onPress: ({row}) => {
              navigation.navigate('extend-training', {
                ...props.route.params,
                employee_training: row,
              });
            },
            visible: ({row}) => row?.status !== 'completed',
          },
          {
            title: 'Complete',
            onPress: ({row}) => {
              navigation.navigate('complete-employee-training', {
                employee_training: row,
              });
            },
            visible: ({row}) => row?.status !== 'completed',
          },
          {
            title: 'Feedback',
            onPress: ({row}) => {
              navigation.navigate('training-feedbacks', {
                ...props.route.params,
                employee_training: row,
              });
            },
          },
          {
            title: 'Delete',
            confirm: {
              title: 'Delete Trainee',
              message:
                'Training is already in progress. Are you sure you want to delete trainee?',
            },
            onPress: ({row}) => {
              return invoke({
                uri: `/employeeTrainings/${row?._id}`,
                props: {
                  status: 'deleted',
                },
              });
            },
            variant: 'error',
          },
        ]}
        {...props}
      />
    </>
  );
};

export const MentorBatchTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {mentor, name} = params;
  const mentorIds = mentor?.length && mentor.map(one => one._id);
  return (
    <>
      <TableHeader title="Trainers" secondaryTitle={name} />
      <Table
        api="/employees"
        eventSourceId={['EmployeeTrainingsFromBatch', 'EmployeeTrainings']}
        filter={{
          _id: {$in: mentorIds},
        }}
        fields={{
          name: 1,
          color: 1,
          official_email_id: 1,
          experience: 1,
          designation: {name: 1},
        }}
        columns={[
          {
            header: 'Trainer',
            render: ({row}) => {
              return (
                <UserAvatarWithNameAndEmail
                  name={row?.name}
                  color={row?.color}
                  official_email_id={row?.official_email_id}
                />
              );
            },
          },
          {
            header: 'Experience',
            field: 'experience',
            type: 'text',
          },
          {
            header: 'Designation',
            field: 'designation.name',
            type: 'text',
          },
        ]}
        {...props}
      />
    </>
  );
};

export const AssignEmployeeOnTraining = props => {
  const {
    route: {params},
  } = props;
  const {employee} = params;
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'EmployeeTrainingsFromBatch',
    uri: '/addEmployeeTraining',
    ...props,
  });

  return (
    <Form
      header={{
        title: 'Assign Employee on Training',
        secondaryTitle: employee?.name,
      }}
      onSubmit={onSubmit}
      submitAction="Assign"
      defaultValues={{
        employee,
      }}
      layoutFields={[
        {
          label: 'Training Batch',
          field: 'training_batch',
          type: 'autoComplete',
          api: '/trainingBatches',
          suggestionField: 'name',
          secondarySuggestionField: 'training_program.name',
          valueField: 'name',
          required: true,
          size: 6,
          fields: {
            name: 1,
            training_program: {
              name: 1,
            },
            mentor: 1,
            mentors: 1,
            topic_wise_mentors: 1,
            mentorship_type: 1,
            from_date: 1,
            to_date: 1,
            feedback_cycle: 1,
            is_attendence_required: 1,
          },
          required: true,
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue('training_program', value?.training_program);
            setFieldValue('mentorship_type', value?.mentorship_type);
            setFieldValue('from_date', value?.from_date);
            setFieldValue('to_date', value?.to_date);
            setFieldValue('feedback_cycle', value?.feedback_cycle);
            setFieldValue(
              'is_attendence_required',
              value?.is_attendence_required,
            );
          },
          filter: {
            status: 'active',
          },
        },
        {
          label: 'Topic',
          field: 'skill_topic',
          type: 'autoComplete',
          api: '/skillTopics',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          visible: ({values}) =>
            values?.training_batch?.mentorship_type === 'Topic-wise Trainers',
        },
        {
          label: 'Mentor',
          field: 'mentor',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          secondarySuggestionField: 'official_email_id',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          filter: ({values}) => {
            return {
              _id: {
                $in: [
                  ...values?.training_batch?.topic_wise_mentors?.map(
                    i => i.mentor,
                  ),
                  values?.training_batch?.mentor,
                  ...values?.training_batch?.mentors,
                ],
              },
            };
          },
        },
        {
          label: 'Trainee',
          placeholder: 'Trainee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          secondarySuggestionField: 'official_email_id',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          readOnly: true,
        },
        {
          label: 'Per Day Allocation',
          field: 'per_day_allocation',
          type: 'number',
          required: true,
          size: 6,
        },
      ]}
    />
  );
};

export default EmployeeTrainingThroughBatchTable;
