import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {AddButton} from '../../../components/button/AddButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {
  Image,
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Tag} from '../../../components/chip/Chip';

const RenderFaultType = props => {
  const {row} = props;
  const faultType = row?.fault_type?.join(', ');
  if (!faultType) return null;
  return <Tag value={faultType} />;
};

export const AssetAssignmentHistoryTable = props => {
  const {route: {params = {}} = {}} = props;
  const {row} = params;
  return (
    <Table
      eventSourceId={['NewITAsset', 'AssetRegister']}
      renderHeader={() => {
        return <TableHeader title="Assignment History" />;
      }}
      api={`/asset-assignment-history`}
      addOnFilter={{
        asset: row?._id,
      }}
      columns={[
        {
          header: 'Assignee',
          type: 'userAvatarChip',
          field: 'assigned_to',
        },
        {
          header: 'From',
          field: 'assigned_from_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'To',
          field: 'assigned_to_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Asset type & Code',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.asset?.asset_type}
                items={[{value: row?.asset?.code}]}
              />
            );
          },
        },
        {
          header: 'Asset Aggrement',
          field: 'asset_aggrement_document',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.asset_aggrement_document ? 'Yes' : 'No'}
              </Text>
            );
          },
        },
        {
          header: 'Assigned By',
          type: 'userAvatarChip',
          field: 'assigned_by',
        },
        {
          header: 'Recieved By',
          type: 'userAvatarChip',
          field: 'recieved_by',
        },
      ]}
    />
  );
};

export const AssetRepairHistoryTable = props => {
  const {route: {params = {}} = {}, navigation} = props;
  const {row} = params;
  const {download, resourceUrl} = useAppStateContext();
  const fetchProps = {
    api: `/asset-repair-history`,
  };
  return (
    <Table
      eventSourceId={['NewITAsset', 'AssetRegister']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Repair History"
            actions={
              <AddButton
                title=" Add repair history"
                view="asset-repair-history-form"
                asset={row}
                params={props?.route?.params}
              />
            }
          />
        );
      }}
      api={`/asset-repair-history`}
      addOnFilter={{
        asset: row?._id,
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Repair date': 'repair_date',
                      'Ticket Id': 'ticket_id',
                      Vendor: 'vendor.name',
                      'Fault type': 'fault_type',
                      'Repair status': 'repair_status',
                      'Repair cost': 'total_repair_cost',
                      'Insurance claim': 'insurance_coverage',
                      'Warranty stauts': 'warranty_status',
                      Description: 'description',
                      Invoice: 'invoice.filename',
                    },
                    filename: 'Repair History',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Repair date',
          field: 'repair_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Ticket ID',
          type: 'text',
          field: 'ticket_id',
        },
        {
          header: 'Vendor',
          type: 'userAvatarChip',
          field: 'vendor',
        },
        {
          header: 'Fault type',
          render: RenderFaultType,
        },
        {
          header: 'Repair status',
          type: 'text',
          field: 'repair_status',
        },
        {
          header: 'Repair cost',
          type: 'currency',
          field: 'total_repair_cost',
        },
        {
          header: 'Insurance claim',
          type: 'text',
          field: 'insurance_coverage',
        },
        {
          header: 'Warranty status',
          type: 'text',
          field: 'warranty_status',
        },
        {
          header: 'Invoice',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {Reimbursement} = useTheme('icons');
            const {invoice} = row || {};
            if (!invoice) return null;

            return (
              <TouchableOpacity
                onPress={() => {
                  const url = resourceUrl(invoice);
                  Linking.openURL(url, '_blank');
                }}>
                <Image source={Reimbursement} width={18} height={18} />
              </TouchableOpacity>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('asset-repair-history-edit-from', {row: row});
          },
        },
      ]}
    />
  );
};
