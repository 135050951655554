import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

export const CurrencyTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="Currency"
      api={`/currencies`}
      renderHeader={() => {
        return (
          <TableHeader
            title="Currencies"
            actions={[<AddButton title={'Currency'} view={'add-currency'} />]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`currency-detail`, {currencyId: row._id});
      }}
      columns={[
        {
          type: 'text',
          field: 'currency',
          header: 'Currency',
        },
      ]}
    />
  );
};
