import {Col, Styled, Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

import PlusMultipleTextRender from '../cellrenders/PlusMultipleTextRender';
import {InlineForm} from '../../../components/form/Form';
import {Table} from '../../../components/table/Table';
import {FeatureColumnCard} from '../../../components/card/FeatureColumnCard';
import {AttachmentCountRender} from '../../../components/table/Renders';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import {getTime} from '../utility';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {Labels} from '../constants/Label';
import {AddFilledAction} from '../../../components/action/AddAction';
import {Tag} from '../../../components/chip/Chip';
import {SplitScreen, useSplitScreen} from '../../../components/SplitScreen';
import {UpdateIssueForm} from './IssueForm';
import {MapObjective} from './UserstoryTable';
import {PROJECT_ISSUE_TYPE_ID} from '../../common/constants/SourceConstants';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {useToast} from '@unthinkable/react-toast';
import {Tooltip} from '../../../components/modal/Tooltip';

const issueInlineForm = props => {
  const {
    route: {params},
    type,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Issue',
    uri: '/projectIssues',
    ...props,
  });

  const {project, feature_id, module, projectlibrary_id} = params || {};

  const defaultValues = {
    project_id: project?._id,
    module_id: module?._id,
    type,
  };

  if (feature_id) {
    defaultValues.objective_ids = [feature_id];
  }

  if (projectlibrary_id) {
    defaultValues.library_id = projectlibrary_id;
  }

  const priorityOptions = [
    {
      label: 'URGENT',
      value: 'URGENT',
      chip: 'ERROR',
    },
    {label: 'HIGH', value: 'HIGH', chip: 'ACCENT4'},
    {
      label: 'MEDIUM',
      value: 'MEDIUM',
      chip: 'ACCENT1',
    },
    {label: 'LOW', value: 'LOW', chip: 'ACCENT3'},
  ];

  return (
    <InlineForm
      key={type}
      onSubmit={onSubmit}
      eventSourceId={'Issue'}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: type === 'Bug' ? 'Issue' : 'Improvement',
          field: 'issue',
          type: 'textArea',
          required: true,
          inputProps: {
            maxRows: 2,
          },
        },
        {
          label: 'Priority',
          type: 'autoComplete',
          field: 'priority',
          width: 150,
          options: priorityOptions,
          colorField: 'chip',
          valueField: 'label',
          keyField: 'value',
          suggestionField: 'label',
          displayChipTextColor: true,
          showChip: true,
        },
        {
          field: 'file',
          inline: true,
          type: 'file',
          width: 170,
          multiple: true,
        },
      ]}
    />
  );
};

const deleteIssue = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete Issue',
    message: 'Are you sure you want to delete issue?',
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/projectIssues/${row?._id}`,
      props: {},
    });
  },
});

const mapFeature = ({navigation, params, issueType}) => ({
  title: `Map ${Labels.Feature}`,
  onPress: props => {
    const {row} = props;
    navigation.navigate(`map-${Labels.Feature_lower}`, {
      ...params,
      row,
      type: 'issue',
      featureType: issueType === 'Bug' ? 'issue' : 'feature',
    });
  },
});

const mapMultipleFeature = ({navigation, params, issueType}) => {
  return {
    title: `Map ${Labels.Features}`,
    onPress: ({selectedIds, resetSelection}) => {
      navigation.navigate(`map-multiple-${Labels.Features_lower}`, {
        ...params,
        selectedIds,
        resetSelection,
        type: 'issue',
        featureType: issueType === 'Bug' ? 'issue' : 'feature',
      });
    },
  };
};

const deleteMultipleIssues = ({deleteInvoke}) => {
  return {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete',
      message: 'Are you sure you want to delete all selected items?',
      confirmText: 'Delete',
    },
    onPress: ({selectedIds, resetSelection}) => {
      deleteInvoke({
        uri: `/issues/batchRemove`,
        props: {
          selectedIds,
        },
      });
      resetSelection?.();
    },
  };
};

const StyledText = Styled(Text)`
  font-size: 12px;
  font-family: 'Inter-Medium';
  line-height: 16px;
  text-align-vertical: center;
  ${props => props.styles?.text}
  &: hover {
    ${props => {
      return {
        ...props.styles?.textHover,
      };
    }}
`;

export const CopyTextComponent = ({code}) => {
  const {colors} = useTheme();
  const toast = useToast();
  return (
    <Tooltip width={'150'} delay={'200'} content="Click to copy code">
      <StyledText
        styles={{
          text: {color: colors.NEUTRAL_LOW},
          textHover: {color: colors.NEUTRAL_MEDIUM},
        }}
        onPress={e => {
          e.stopPropagation();
          navigator.clipboard.writeText(code);
          toast({message: 'Code copied to clipboard'});
        }}>
        {code}
      </StyledText>
    </Tooltip>
  );
};

const issueColumn = ({issueType}) => ({
  header: issueType === 'Bug' ? 'Issue' : 'Improvement',
  responsive: 'sm',
  render: ({row}) => {
    let {createdAt, issue, createdBy, code} = row;

    if (createdAt) {
      createdAt = `On ${getTime(createdAt)}`;
    }

    if (createdBy?.name) {
      createdBy = `By : ${createdBy?.name}`;
    }

    const {colors} = useTheme();

    return (
      <FeatureColumnCard
        primaryTitle={issue}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            renderComponent: <CopyTextComponent code={code} />,
          },
          {
            value: createdBy,
          },
          {
            value: createdAt,
          },
        ]}
        row={row}
        isIssue={true}
      />
    );
  },
});

const issueType2 = {
  header: 'Type',
  field: 'type',
  type: 'text',
  width: 120,
};

export const moduleColumn = ({navigation, params, api}) => ({
  header: 'Module',
  width: 150,
  render: ({row}) => {
    const {module_id} = row;

    if (!module_id) {
      return (
        <Col style={{alignItems: 'flex-start'}}>
          <AddFilledAction />
        </Col>
      );
    }

    return <Tag value={module_id?.module} color={module_id?.color} />;
  },

  onPress: ({row}) => {
    navigation.navigate('change-module', {
      ...params,
      row,
      api,
      secondaryTitle: row['issue'],
    });
  },
});

const issueAttachments = {
  render: AttachmentCountRender,
  type: 'file',
  field: 'file',
  header: 'Refer',
  showImage: true,
  count: true,
  width: 80,
  align: 'center',
};

const removeFromFeature = ({putInvoke, params}) => ({
  title: `Remove from ${Labels.Feature}`,
  variant: 'error',
  confirm: {
    title: 'Remove Issue',
    message: `Are you sure you want to remove this issue from this release unit?`,
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    putInvoke({
      uri: `/issues/removeObjective`,
      props: {
        objective_id: params?.feature?._id,
        issue_id: row?._id,
      },
    });
  },
  visible: params?.feature?._id,
});

const showFeatures = ({navigation, params, isPending}) => ({
  title: `View ${Labels.Feature}`,
  onPress: props => {
    const {row} = props;
    navigation.navigate(`display-${Labels.Features_lower}`, {
      ...params,
      row,
      secondaryTitle: `Issue : ${row?.issue}`,
      keyField: 'issue',
    });
  },
  visible: () => !isPending,
});

const IssueCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_ISSUE_TYPE_ID, label: 'Issue'},
      comment_source_id: {_id: row._id, name: row.issue},
    }),
  });
};

const IssueTableHeader = ({searchValue, onChangeFilter, ...props}) => {
  const {
    route: {params},
    selectedTab,
    type,
  } = props;

  const {feature} = params || {};
  return (
    <>
      {feature?._id ? (
        <TableHeader
          title={type === 'Bug' ? `Issues` : 'Improvements'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
          ]}
        />
      ) : null}
      {(selectedTab === 'backlog' || feature?._id) && issueInlineForm(props)}
    </>
  );
};

export const CommonIssueTable = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filter = {},
    isPending,
    selectedTab,
    api = `/projectissues`,
    isRowActive,
    selectedId,
    type: issueType,
    filterParams,
    groupBy,
    status,
    subStatus,
  } = props;

  const {module, feature} = params;
  const {toggleSplitScreen} = useSplitScreen();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Issue',
  });

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'Issue',
  });

  if (feature) {
    filter.objective_ids = feature?._id;
    filter.type = issueType;
  } else if (module) {
    filter.module_id = module?._id;
  }

  const {onChangeFilter, searchValue: featureSearchValue} = useFilter({});

  return (
    <>
      <Table
        isRowActive={isRowActive}
        renderHeader={_props => {
          return (
            <IssueTableHeader
              {...props}
              {..._props}
              searchValue={featureSearchValue}
              onChangeFilter={onChangeFilter}
            />
          );
        }}
        allowDynamicGrouping={groupBy?.allowDynamicGrouping}
        addonGroupBy={groupBy?.addonGroupBy}
        groupRow={groupBy?.groupRow}
        eventSourceId={['Issue', 'RemoveMappedFeature', 'Module', 'Comment']}
        api={api}
        search={searchValue || featureSearchValue}
        searchFields={['issue', 'code']}
        sort={{index: 1}}
        filter={filter}
        fields={{
          issue: 1,
          type: 1,
          code: 1,
          objective_ids: {
            feature: 1,
            createdAt: 1,
            status: 1,
            sub_status: 1,
            releasedOn: 1,
          },
          priority: 1,
          comments_count: {
            count: 1,
          },
          last_comment: {
            createdAt: 1,
          },
          createdAt: 1,
          createdBy: {name: 1},
          file: 1,
          index: 1,
          module_id: {module: 1, color: 1},
        }}
        onRowPress={({row, rowIndex}) => {
          toggleSplitScreen({
            ...params,
            issue: row,
            row,
            rowIndex,
          });
        }}
        selection={{
          actions: [
            mapMultipleFeature({navigation, params, issueType}),
            deleteMultipleIssues({deleteInvoke}),
          ],
        }}
        isDragDisabled={groupBy?.allowDynamicGrouping}
        draggable={
          !!groupBy?.allowDynamicGrouping
            ? null
            : {
                sort: 'asc',
                updateIndexOnDragEnd: ({updatedIndex, row}) => {
                  putInvoke({
                    uri: `/projectIssues/${row?._id}`,
                    props: {
                      index: updatedIndex,
                    },
                  });
                },
              }
        }
        columns={[
          issueColumn({issueType}),
          // issueType2,
          !groupBy?.allowDynamicGrouping &&
            !selectedId &&
            !module?._id &&
            moduleColumn({navigation, params, api: '/projectissues'}),
          issueAttachments,
          !selectedId && {
            header: {
              label: Labels.Feature,
              textAlign: 'right',
            },
            render: ({row}) => {
              let {objective_ids = [], type} = row;
              if (selectedTab !== 'all' && objective_ids?.length) {
                objective_ids = objective_ids.filter(item => {
                  if (item.status !== status) {
                    return false;
                  }
                  if (type === 'Enhancement' && status === 'backlog') {
                    return item.sub_status == subStatus;
                  }
                  return true;
                });
              }
              objective_ids = objective_ids.map(item => item.feature);
              return objective_ids?.length ? (
                <PlusMultipleTextRender
                  row={row}
                  maxWidth={200}
                  value={objective_ids}
                />
              ) : (
                <MapObjective />
              );
            },
            align: 'center',
            width: 250,
            onPress: ({row}) => {
              row?.objective_ids?.length
                ? navigation.navigate(`display-${Labels.Features_lower}`, {
                    ...params,
                    displayRespectiveFeatures: !['all'].includes(selectedTab),
                    row,
                    secondaryTitle: `Issue : ${row?.issue}`,
                    keyField: 'issue',
                    status,
                  })
                : navigation.navigate(`map-${Labels.Feature_lower}`, {
                    ...params,
                    row,
                    type: 'issue',
                    featureType: issueType === 'Bug' ? 'issue' : 'feature',
                  });
            },
            visible: () => !feature,
          },
          IssueCommentColumn({navigation, params}),
        ]}
        moreActions={[
          selectedTab !== 'released' &&
            mapFeature({navigation, params, issueType}),
          showFeatures({
            navigation,
            params,
            isPending,
          }),
          feature?._id
            ? removeFromFeature({putInvoke, params})
            : deleteIssue({deleteInvoke}),
        ]}
        params={filterParams}
      />
    </>
  );
};

export const IssueScreen = props => {
  return (
    <SplitScreen splitScreen={<UpdateIssueForm {...props} />}>
      <CommonIssueTable {...props} />
    </SplitScreen>
  );
};
