import { Col } from '@unthinkable/react-core-components';
import { useFilter } from '@unthinkable/react-filters';
import { RowWithSeparator } from '@unthinkable/react-layout';
import moment from 'moment';
import React from 'react';
import { GroupContentItem } from '../../../components/card/GroupContentItem';
import { GroupFilter } from '../../../components/filter/Filters';
import { TextRenderer } from '../../../components/form/Renderers';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

const getResolvedProductiveValue = value => {
  if (value === 'Yes') {
    return 'Productive';
  }
  if (value === 'No') {
    return 'Not Productive';
  }
  return value;
};

export const ProductivitySurveyTableManager = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {user} = useAppStateContext();
  const {filterValues, applyFilter} = useFilter({
    filter: {
      feedback_date: {
        $gte: moment().startOf('year').toDate(),
        $lte: moment().endOf('year').toDate(),
      },
    },
    plainFilter: {
      feedback_date: {
        filterOf: 'Year',
        from: moment().utc().startOf('year').toDate(),
        to: moment().utc().endOf('year').toDate(),
      },
    },
  });
  return (
    <Table
      renderHeader={props => (
        <TableHeader
          title={'Productivity Feedbacks'}
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  label: 'Employee',
                  type: 'multiAutoComplete',
                  field: 'employee',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  placeholder: 'Select Employee',
                  searchFields: ['name', 'official_email_id', 'employee_code'],
                },
                {
                  type: 'date',
                  field: 'feedback_date',
                  label: 'Feedback Date',
                  options: [
                    {label: 'Month', value: 'Month'},
                    {
                      label: 'Year',
                      value: 'Year',
                    },
                    {
                      label: 'Custom',
                      value: 'Custom',
                    },
                  ],
                },
                {
                  type: 'autoComplete',
                  field: 'productive',
                  label: 'Productivity',
                  suggestionField: 'label',
                  keyField: 'value',
                  valueField: 'label',
                  placeholder: 'Select Productivity',
                  options: [
                    {label: 'Yes', value: 'Productive'},
                    {label: 'No', value: 'Not Productive'},
                    {
                      label: 'Sometimes Yes, Sometimes No',
                      value: 'Sometimes Productive',
                    },
                  ],
                },
                {
                  type: 'autoComplete',
                  field: 'rating',
                  label: 'Rating',
                  placeholder: 'Select Rating',
                  options: ['Awesome', 'Just Okay', 'Unsatisfied'],
                },
                {
                  type: 'autoComplete',
                  field: 'status',
                  label: 'Status',
                  placeholder: 'Select Status',
                  options: ['OK', 'Pending'],
                },
              ]}
              {...props}
            />,
          ]}
        />
      )}
      api="/monthWiseProductivitySurveys"
      sort={{feedback_date: -1}}
      eventSourceId="productivitySurvey"
      filter={{'employee.reporting_to': user.employee}}
      addOnFilter={filterValues.filter}
      fields={{
        feedback_date: 1,
        productive: 1,
        productive_points: 1,
        productive_suggestion: 1,
        employee: {
          name: 1,
          official_email_id: 1,
          color: 1,
        },
        rating: 1,
        rating_feedback: 1,
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <GroupContentItem
              value={moment(row.feedback_date).utc().format('MMMM YYYY')}
            />
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          render: ({value}) => <UserAvatarWithNameAndEmail {...value} />,
          width: 250,
        },
        {
          header: 'Productivity',
          render: ({row}) => {
            const {productive_points, productive_suggestion, productive} = row;
            return (
              <Col style={{gap: 4, alignItems: 'center'}}>
                <TextRenderer value={getResolvedProductiveValue(productive)} />
                <RowWithSeparator
                  style={{
                    gap: 4,
                    alignItems: 'center',
                  }}>
                  {productive == 'Productive' ? (
                    productive_points?.map(item => (
                      <GroupContentItem value={item} title={item} />
                    ))
                  ) : (
                    <GroupContentItem
                      value={productive_suggestion}
                      title={productive_suggestion}
                    />
                  )}
                </RowWithSeparator>
              </Col>
            );
          },
          align: 'center',
        },
        {
          header: 'Rating',
          render: ({row}) => {
            const {rating_feedback, rating} = row;
            return (
              <Col style={{gap: 4, alignItems: 'center'}}>
                <TextRenderer
                  value={`Work Experience (${rating})`}
                  title={`Work Experience (${rating})`}
                />
                <GroupContentItem
                  value={rating_feedback}
                  title={rating_feedback}
                />
              </Col>
            );
          },
          align: 'center',
          width: 250,
        },
      ]}
      moreActions={[
        {
          title: 'Add Remarks',
          onPress: ({row}) => {
            navigation.navigate('managers-remark-on-productivity', {
              ...params,
              productivity_survey: row,
              editable: true,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
