import {ModalViewStyles} from '../../modal/theme';
import {InputStyles} from '../text/theme';

export const AutoCompleteStyles = ({theme}) => {
  const {colors, fonts, icons, shadows} = theme;
  return {
    ...InputStyles({theme}),
    chip: {
      container: {
        borderRadius: 4,
        paddingTop: 1,
        paddingRight: 6,
        paddingBottom: 1,
        paddingLeft: 6,
      },
      text: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
      },
    },
    createIconContainer: {backgroundColor: colors.BRAND_UPPER_LOW},
    modal: {
      ...ModalViewStyles({theme}),
      sm: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 250,
          ...shadows.S,
        },
      },
      md: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 260,
          minWidth: 200,
          borderColor: colors.UPPER_OUTLINE,
          borderWidth: 1,
          ...shadows.S,
        },
      },
    },
    dropdownInputContainer: {
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 6,
      paddingTop: 12,
      paddingRight: 16,
      paddingBottom: 12,
      paddingLeft: 16,
    },
    dropdownInput: {
      borderRadius: 0,
      borderWidth: 0,
      flex: 1,
      overflow: 'hidden',
      padding: 0,
    },
    dropdownList: {padding: 4},
    inputStyle: {...fonts.BODY3, color: colors.NEUTRAL_HIGH},
    inputImage: icons.SearchIcon,
    rowContainer: {
      backgroundColor: colors.BACKGROUND,
      borderRadius: 4,
      paddingTop: 8,
      paddingRight: 12,
      paddingBottom: 8,
      paddingLeft: 12,
      borderBottomWidth: 0,
    },
    rowContainerHoverColor: colors.SURFACE2,
    rowText: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    rowSecondaryText: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
export const AutoCompleteLinkStyles = ({theme}) => {
  const {colors, fonts, icons, shadows} = theme;
  return {
    ...AutoCompleteStyles({theme}),
    DownArrow: icons.BreadCrumbDropdownFilled,
    containerHoverColor: colors.SURFACE2,
    itemHoverColor: colors.OUTLINE,
    rowText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    rowSecondaryText: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const GooglePlaceStyles = ({theme}) => {
  const {icons} = theme;
  const autoCompleteStyles = AutoCompleteStyles({theme});
  return {
    ...autoCompleteStyles,
    selectorIcon: icons.SearchIcon,
  };
};

export const CascaderStyles = ({theme}) => {
  const {colors, fonts, icons, shadows} = theme;
  return {
    ...InputStyles({theme}),
    separator: {height: 0},
    dropdownList: {},
    dropdownInputContainer: {
      padding: 0,
      borderBottomWidth: 0,
      paddingBottom: 12,
    },
    rowContainer: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 6,
      marginBottom: 4,
      borderBottomWidth: 0,
    },
    rowContent: {},
    rowText: {color: colors.NEUTRAL_HIGH, ...fonts.BODY3},
    cascadeHeaderRowGap: 16,
    cascadeHeaderContainer: {
      borderBottomWidth: 0,
      padding: 0,
      paddingBottom: 12,
    },
    cascadingIcon: {},
    cascadeHeaderText: {...fonts.SECTION_HEADING2, color: colors.NEUTRAL_HIGH},
    modal: {
      ...ModalViewStyles({theme}),
      sm: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 250,
          padding: 12,
          ...shadows.M,
        },
      },
      md: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 250,
          minWidth: 300,
          padding: 12,
          ...shadows.S,
        },
      },
    },
  };
};
