const Banner1Web = '/images/Banner1Web.png';
const Banner2Web = '/images/Banner2Web.png';
const Banner1Mobile = '/images/Banner1Mobile.png';
const Banner2Mobile = '/images/Banner2Mobile.png';
const LOCOMOWebLogin = '/images/LOCOMOWebLogin.svg';
const UnthinkableLoginWeb = '/images/UnthinkableLoginWeb.svg';
const LOCOMOmobileIcon = '/images/LOCOMOmobileIcon.svg';
const WelcomeBackToLocomo = '/images/WelcomeBackToLocomo.svg';
const GoogleLogo = '/images/GoogleLogo.svg';
const NextGenIcon = '/images/NextGenIcon.svg';
const TrackableIcon = '/images/TrackableIcon.svg';
const RightArrow = '/images/RightArrow.svg';
const DatePrevious = '/images/DatePrevious.svg';
const DateNext = '/images/DateNext.svg';
const ExpandSmall = '/images/ExpandSmall.svg';
const Cross = '/images/Cross.svg';
const CrossAI = '/images/CrossAI.svg';
const LOCOMOMenuBarIcon = '/images/LOCOMOMenuBarIcon.svg';
const Back = '/images/Back.svg';
const Add = '/images/Add.svg';
const Add24 = '/images/Add24.svg';
const GreenAdd = '/images/GreenAdd.svg';
const ThreeDotVertical = '/images/ThreeDotVertical.svg';
const ThreeDotVerticalWhite = '/images/ThreeDotVerticalWhite.svg';
const ChevronRight = '/images/ChevronRight.svg';
const Plus = '/images/Plus.svg';
const MagnifyingGlass = '/images/MagnifyingGlass.svg';
const CalendarCheck = '/images/CalendarCheck.svg';
const Profit = '/images/Profit.svg';
const Loss = '/images/Loss.svg';
const Filter = '/images/Filter.svg';
const MessageArrow = '/images/MessageArrow.svg';
const VerbeeIcon = '/images/verbeeicon.svg';
const StarIcon = '/images/staricon.svg';
const UpArrow = '/images/uparrow1.svg';
const AddPlus = '/images/addplus.svg';
const GreyTickIcon = '/images/greytick.svg';
const GreenOutlineTick = '/images/GreenOutlineTick.svg';
const GreenTickIcon = '/images/greentick.svg';
const BacklogIcon = '/images/backlogIcon.svg';
const FilledTickIcon = '/images/filledTick.svg';
const LockIcon = '/images/Lock.svg';
const Close = '/images/Close.svg';
const VideoContentType = '/images/VideoContentType.svg';
const ApplicationContentType = '/images/ApplicationContentType.svg';
const ImageContentType = '/images/ImageContentType.svg';
const ZoomInIcon = '/images/ZoomInIcon.svg';
const ZoomOutIcon = '/images/ZoomOutIcon.svg';
const RefreshIcon = '/images/RefreshIcon.svg';
const ResetIcon = '/images/ResetIcon.svg';
const CaretLeft = '/images/CaretLeft.svg';
const CaretRight = '/images/CaretRight.svg';
const LockGrey = '/images/LockGrey.svg';
const CommunicationBlue = '/images/CommunicationBlue.svg';
const Message = '/images/Message.svg';
const GreenBackGroundAdd = '/images/GreenBackGroundAdd.svg';
const LogOut = '/images/LogOut.svg';
const DesignImage = '/images/DesignImage.svg';
const RolesMembersIcon = '/images/RolesMembersIcon.svg';
const CheckboxIcon = '/images/CheckboxIcon.svg';
const UnCheckBoxIcon = '/images/UnCheckBoxIcon.svg';
const DeSelectBox = '/images/DeSelectBox.svg';
const SearchIcon = '/images/Search.svg';
const BrushIcon = '/images/Brush.svg';
const BrushGrey = '/images/brush.svg';
const DownArrowIcon = '/images/DownArrow.svg';
const UpArrowIcon = '/images/UpArrow.svg';
const RolesConfiguration = '/images/RolesConfiguration.svg';
const Repositories = '/images/Repositories.svg';
const VersionControls = '/images/VersionControls.svg';
const AskAILogo = '/images/AskAILogo.svg';
const Resources = '/images/Resources.svg';
const Tasks = '/images/Tasks.svg';
const BlueStarIcon = '/images/BlueStarIcon.svg';
const NoData = '/images/NoData.svg';
const NoDataGIF = '/images/NoDataFound.gif';
const MessageTyping = '/images/Messagetyping.gif';
const DeploymentIcon = '/images/DeploymentIcon.svg';
const Description = '/images/description.svg';
const ArrowSquareOut = '/images/ArrowSquareOut.svg';
const Copy = '/images/Copy.svg';
const CopyBlack = '/images/CopyBlack.svg';
const EstChange = '/images/EstChange.svg';
const Link = '/images/Link.svg';
const InactiveModule = '/images/InactiveModule.svg';
const EstHours = '/images/EstHours.svg';
const Download = '/images/Download.svg';
const Owner = '/images/Owner.svg';
const StageCircle = '/images/StageCircle.svg';
const Upload = '/images/Upload.svg';
const Bug = '/images/Bug.svg';
const Users = '/images/Users.svg';
const Edit = '/images/Edit.svg';
const BranchBlue = '/images/BranchBlue.svg';
const BranchGreen = '/images/BranchGreen.svg';
const BranchGrey = '/images/BranchGrey.svg';
const BranchRed = '/images/BranchRed.svg';
const PullRequest = '/images/PullRequest.svg';
const GroupBy = '/images/GroupBy.svg';
const Trash = '/images/trash.svg';
const Reimbursement = '/images/Reimbursement.svg';
const DownloadBlue = '/images/DownloadBlue.svg';
const Export = '/images/Export.svg';
const Folder = '/images/Folder.svg';
const Issue = '/images/Issue.svg';
const BlueSandClock = '/images/BlueSandClock.svg';
const RedCircleIssue = '/images/RedCircleIssue.svg';
const locomo2 = '/images/locomo2.svg';
const Rocket = '/images/Rocket.svg';
const ShapeIcon = '/images/ShapeIcon.svg';
const DeviceSetup = '/images/DeviceSetup.svg';
const Environment = '/images/Environment.svg';
const Chat = '/images/ChatIcon.svg';
const ChatBlue = '/images/ChatBlueIcon.svg';
const Notification = '/images/Notification.svg';
const Disqualified = '/images/Disqualified.svg';
const TableEdit = '/images/TableEdit.svg';
const TableDelete = '/images/TableDelete.svg';
const NewFeature = '/images/NewFeature.svg';
const FeatureImprovement = '/images/FeatureImprovement.svg';
const WhitePlus = '/images/WhitePlus.svg';
const Boilerplate = '/images/Boilerplate.svg';
const DescIcon = '/images/DescIcon.svg';
const JiraIcon = '/images/JiraIcon.svg';
const BlueTick = '/images/BlueTickIcon.svg';
const IssueWarning = '/images/IssueWarning.svg';
const DisqualifiedBlack = '/images/DisqualifiedBlack.svg';
const DisqualifiedRed = '/images/DisqualifiedRed.svg';
const Reopen = '/images/Reopen.svg';
const EstChangeGrey = '/images/EstChangeGrey.svg';
const TickReviewGreen = '/images/TickReviewGreen.svg';
const LocomoTool = '/images/LocomoTool.svg';
const OtherTool = '/images/OtherTool.svg';
const BlackTick = '/images/BlackTick.svg';
const GreyTick = '/images/GreyTick.svg';
const Reply = '/images/Reply.svg';
const SubTaskBlue = '/images/SubTaskBlue.svg';
const SubTaskGreen = '/images/SubTaskGreen.svg';
const CustomerOpportunity = '/images/CustomerOpp.svg';
const Feature = '/images/Feature.svg';
const IssueMinus = '/images/IssueMinus.svg';
const Module = '/images/Module.svg';
const Project = '/images/Project.svg';
const ObjectsPMT = '/images/ObjectsPMT.svg';
const OtherObjects = '/images/OtherObjects.svg';
const RedBug = '/images/RedBug.svg';
const Task = '/images/Task.svg';
const FlagBrand = '/images/FlagBrand.svg';
const Calendar = '/images/Calendar.svg';
const atTheRate = '/images/atTheRate.svg';
const phone = '/images/phone.svg';
const StarGreen = '/images/StarGreen.svg';
const StarGray = '/images/StarGray.svg';
const ArchiveIcon = '/images/archiveIcon.svg';
const ProspectIcon = '/images/Prospect.svg';
const CustomerNew = '/images/CustomerNew.svg';
const RecursiveExpand = '/images/RecursiveExpand.svg';
const RecursiveCollapse = '/images/RecursiveCollapse.svg';
const RecursiveExpandGreen = '/images/RecursiveExpandGreen.svg';
const RecursiveCollapseGreen = '/images/RecursiveCollapseGreen.svg';
const BlueCrossIcon = '/images/BlueCrossIcon.svg';
const FocusGreyIcon = '/images/FocusGrey.svg';
const FocusGreenIcon = '/images/FocusGreen.svg';
const BlurGreyIcon = '/images/BlurGrey.svg';
const BlurGreenIcon = '/images/BlurGreen.svg';
const Code = '/images/Code.svg';
const AISuggested = '/images/AISuggested.svg';
const ViewSuggestion = '/images/ViewSuggestion.svg';
const AI = '/images/AI.svg';
const Sparkle = '/images/Sparkle.svg';
const ClockWise = '/images/ClockWise.svg';
const PrimaryKey = '/images/PrimaryKeyIcon.svg';
const AcceptAI = '/images/acceptAI.svg';
const ChatWithAI = '/images/chatWithAI.svg';
const GenerateAI = '/images/GenerateAI.svg';
const loadingAI = '/images/loadingAI.svg';
const Regenerate = '/images/regenerate.svg';
const UnthinkableFlag = '/images/UnthinkableFlag.svg';
const BreadCrumbDropdown = '/images/breadCrumbDropdown.svg';
const BreadCrumbDropdownFilled = '/images/BreadCrumbDropdownFilled.svg';
const ThreeDotHorizontal = '/images/threeDotHorizontal.svg';
const GitCommit = '/images/GitCommit.svg';
const Info = '/images/Info.svg';
const OrganizationModule = '/images/OrganizationModule.svg';
const AttendanceModule = '/images/AttendanceModule.svg';
const EmployeeModule = '/images/EmployeeModule.svg';
const TaskModule = '/images/TaskModule.svg';
const RecruitmentModule = '/images/RecruitmentModule.svg';
const ChatModule = '/images/ChatModule.svg';
const TicketModule = '/images/TicketModule.svg';
const PayrollModule = '/images/PayrollModule.svg';
const UserModule = '/images/UserModule.svg';
const CustomerModule = '/images/CustomerModule.svg';
const ExpenseModule = '/images/ExpenseModule.svg';
const RevenueModule = '/images/RevenueModule.svg';
const FinanceModule = '/images/FinanceModule.svg';
const AssetModule = '/images/AssetModule.svg';
const ProjectModule = '/images/ProjectModule.svg';
const SkillModule = '/images/SkillModule.svg';
const HelpModule = '/images/HelpModule.svg';
const ExpandCollapse = '/images/ExpandCollapse.svg';
const PlayIcon = '/images/Play.svg';
const EmptyStateIcon = '/images/EmptyIcon.svg';
const HelpTickIcon = '/images/HelpTick.svg';
const HelpIcon = '/images/HelpIcon.svg';
const SelectedHelpIcon = '/images/SelectedHelpIcon.svg';
const ReportIcon = '/images/ReportIcon.svg';
const GroupExpand = '/images/GroupExpand.svg';
const GroupCollapse = '/images/GroupCollapse.svg';
const InfoGrey = '/images/InfoGrey.svg';
const HelpArrowIcon = '/images/HelpArrow.svg';
const NotDeployed = '/images/NotDeployed.svg';
const DeploymentStatusSuccess = '/images/DeploymentStatusSuccess.svg';
const DeploymentStatusFailed = '/images/DeploymentStatusFailed.svg';
const DeploymentStatusAborted = '/images/DeploymentStatusAborted.svg';
const HelpIcons = '/images/Help.svg';
const BluePlus = '/images/BluePlus.svg';
const HelpCharacterIcon = '/images/HelpCharacter.svg';
const HelpGuideCharacterIcon = '/images/HelpGuideCharacter.svg';
const Document = '/images/document.svg';
const AlertCircleGreen = '/images/AlertCircleGreen.svg';
const AlertCircleGray = '/images/AlertCircleGray.svg';
const RocketBlue = '/images/RocketBlue.svg';
const RocketPurple = '/images/RocketPurple.svg';
const RocketPink = '/images/RocketPink.svg';
const AINew = '/images/AINew.svg';
const AIAcceptAll = '/images/AIAcceptAll.svg';
const AIRejectAll = '/images/AIRejectAll.svg';
const AISuggestNew = '/images/AISuggestNew.svg';
const AIRefresh = '/images/AIRefresh.svg';
const AILoader = '/images/AILoader.svg';

export default {
  RocketBlue,
  RocketPurple,
  RocketPink,
  AlertCircleGreen,
  AlertCircleGray,
  Document,
  BluePlus,
  Banner1Web,
  Banner2Web,
  Banner1Mobile,
  Banner2Mobile,
  LOCOMOWebLogin,
  UnthinkableLoginWeb,
  LOCOMOmobileIcon,
  WelcomeBackToLocomo,
  GoogleLogo,
  NextGenIcon,
  TrackableIcon,
  DatePrevious,
  DateNext,
  ExpandSmall,
  Cross,
  CrossAI,
  LOCOMOMenuBarIcon,
  Back,
  Add,
  Add24,
  ChevronRight,
  Plus,
  ThreeDotVertical,
  ThreeDotVerticalWhite,
  GreenAdd,
  MagnifyingGlass,
  CalendarCheck,
  Profit,
  Loss,
  Filter,
  MessageArrow,
  VerbeeIcon,
  StarIcon,
  UpArrow,
  AddPlus,
  GreyTickIcon,
  GreenOutlineTick,
  GreenTickIcon,
  BacklogIcon,
  LockIcon,
  Close,
  VideoContentType,
  ApplicationContentType,
  ImageContentType,
  ZoomInIcon,
  ZoomOutIcon,
  RefreshIcon,
  ResetIcon,
  CaretLeft,
  CaretRight,
  LockGrey,
  CommunicationBlue,
  Message,
  GreenBackGroundAdd,
  LogOut,
  DesignImage,
  RolesMembersIcon,
  RightArrow,
  CheckboxIcon,
  UnCheckBoxIcon,
  DeSelectBox,
  SearchIcon,
  BrushIcon,
  BrushGrey,
  DownArrowIcon,
  UpArrowIcon,
  RolesConfiguration,
  Repositories,
  VersionControls,
  AskAILogo,
  Resources,
  Tasks,
  BlueStarIcon,
  NoData,
  NoDataGIF,
  MessageTyping,
  DeploymentIcon,
  Description,
  ArrowSquareOut,
  Copy,
  CopyBlack,
  EstChange,
  FilledTickIcon,
  Link,
  InactiveModule,
  EstHours,
  Owner,
  StageCircle,
  Download,
  Upload,
  Bug,
  Users,
  Edit,
  BranchBlue,
  BranchGreen,
  BranchGrey,
  BranchRed,
  PullRequest,
  GroupBy,
  Trash,
  Reimbursement,
  DownloadBlue,
  Export,
  Folder,
  Issue,
  BlueSandClock,
  RedCircleIssue,
  locomo2,
  Rocket,
  ShapeIcon,
  DeviceSetup,
  Environment,
  Chat,
  ChatBlue,
  Notification,
  Disqualified,
  TableEdit,
  TableDelete,
  NewFeature,
  FeatureImprovement,
  WhitePlus,
  Boilerplate,
  DescIcon,
  JiraIcon,
  BlueTick,
  IssueWarning,
  DisqualifiedBlack,
  EstChangeGrey,
  DisqualifiedRed,
  Reopen,
  TickReviewGreen,
  LocomoTool,
  OtherTool,
  BlackTick,
  GreyTick,
  Reply,
  SubTaskBlue,
  SubTaskGreen,
  CustomerOpportunity,
  Feature,
  IssueMinus,
  Module,
  Project,
  ObjectsPMT,
  OtherObjects,
  RedBug,
  Task,
  FlagBrand,
  Calendar,
  atTheRate,
  phone,
  StarGreen,
  StarGray,
  ArchiveIcon,
  ProspectIcon,
  CustomerNew,
  RecursiveExpand,
  RecursiveCollapse,
  RecursiveExpandGreen,
  RecursiveCollapseGreen,
  BlueCrossIcon,
  FocusGreyIcon,
  FocusGreenIcon,
  BlurGreyIcon,
  BlurGreenIcon,
  Code,
  AISuggested,
  ViewSuggestion,
  AI,
  Sparkle,
  ClockWise,
  PrimaryKey,
  AcceptAI,
  ChatWithAI,
  GenerateAI,
  loadingAI,
  Regenerate,
  UnthinkableFlag,
  BreadCrumbDropdown,
  BreadCrumbDropdownFilled,
  ThreeDotHorizontal,
  GitCommit,
  Info,
  OrganizationModule,
  AttendanceModule,
  EmployeeModule,
  TaskModule,
  RecruitmentModule,
  ChatModule,
  TicketModule,
  PayrollModule,
  UserModule,
  CustomerModule,
  ExpenseModule,
  RevenueModule,
  FinanceModule,
  AssetModule,
  ProjectModule,
  SkillModule,
  HelpModule,
  ExpandCollapse,
  PlayIcon,
  EmptyStateIcon,
  HelpTickIcon,
  HelpIcon,
  SelectedHelpIcon,
  ReportIcon,
  GroupExpand,
  GroupCollapse,
  InfoGrey,
  HelpArrowIcon,
  NotDeployed,
  DeploymentStatusSuccess,
  DeploymentStatusFailed,
  DeploymentStatusAborted,
  HelpIcons,
  HelpCharacterIcon,
  HelpGuideCharacterIcon,
  AINew,
  AIAcceptAll,
  AIRejectAll,
  AISuggestNew,
  AIRefresh,
  AILoader,
};
