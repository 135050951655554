import React, {useState} from 'react';
import {
  Col,
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import GoogleLoginButton from './GoogleLoginButton';
import WelcomeBackToLocomo from './images/WelcomeBackToLocomo.svg';
import {theme} from './theme';
import {
  TextInput,
  PasswordInput,
} from '../../../components/form-editors/Editors';
import {useNavigation} from '@react-navigation/native';

export const LoginForm = props => {
  const navigation = useNavigation();
  const {onLogin, onGoogleLogin} = props;
  const {LoginForm} = theme;
  const {
    containerStyle,
    headerContainerStyle,
    headerTextStyle,
    googleLoginContainerStyle,
    saparatorContainerStyle,
    separatorTextStyle,
    inputContainerStyle,
    loginButtonStyle,
    loginTextStyle,
    FormTextContainerStyle,
    forgotPasswordTextStyle,
  } = LoginForm;

  const [data, updateData] = useState({});

  return (
    <View style={containerStyle}>
      <View style={headerContainerStyle}>
        <Text style={headerTextStyle}>Log in </Text>
        <Image source={WelcomeBackToLocomo} />
      </View>
      <View style={googleLoginContainerStyle}>
        <GoogleLoginButton onSubmit={onGoogleLogin} />
      </View>
      <View style={saparatorContainerStyle}>
        <Text style={separatorTextStyle}>or</Text>
      </View>
      <Col gap={16} style={inputContainerStyle}>
        <TextInput
          placeholder="Sign in with Email"
          value={data['email']}
          onChangeText={text => {
            updateData({...data, email: text});
          }}
          required
        />
        <PasswordInput
          placeholder="Password"
          value={data['password']}
          onSubmitEditing={() => {
            onLogin(data);
          }}
          onChangeText={text => {
            updateData({...data, password: text});
          }}
          required
        />
      </Col>
      <View style={FormTextContainerStyle}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('forgotPassword');
          }}>
          <Text style={forgotPasswordTextStyle}>Forgot Password?</Text>
        </TouchableOpacity>
      </View>
      <View
        style={loginButtonStyle}
        onPress={() => {
          onLogin(data);
        }}>
        <Text style={loginTextStyle}>Login</Text>
      </View>
    </View>
  );
};
