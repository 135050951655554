import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinetuneUsecaseSelectForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/finetunedocuments/get-about-us-link',
  });
  const {defaultValues, potential_customer} = params || {};

  return (
    <Form
      onSubmit={onSubmit}
      header={'Select Usecase'}
      defaultValues={{
        ...defaultValues,
        potential_customer,
      }}
      layoutFields={[
        {
          label: 'Usecase',
          field: 'usecase_id',
          type: 'autoComplete',
          api: '/finetuneusecases',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
