import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';

export const AcceptProbationAction = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ProbationconfirmationFeedback
      header={{
        title: 'Give feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const ProbationconfirmationFeedback = props => {
  const {
    route: {params},
  } = props;
  const {row, forAccept, forReject} = params;
  let onSubmit;
  if (forAccept) {
    ({onSubmit} = useFormSubmit({
      uri: `/approve-probation-request/${row?._id}/${row?.employee?._id}`,
      eventSourceId: 'Probation',
      ...props,
    }));
  } else if (forReject) {
    ({onSubmit} = useFormSubmit({
      uri: `/reject-probation-request/${row?._id}/${row?.employee?._id}`,
      eventSourceId: 'Probation',
      ...props,
    }));
  }

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['Probation'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Feedback',
              field: 'probation_feedback',
              type: 'textArea',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const RejectProbationAction = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ProbationconfirmationFeedback
      header={{
        title: 'Give feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const ProbationExtendForm = props => {
  const {
    route: {params},
  } = props;
  const {row, forAccept, forReject} = params;
  let onSubmit;

  ({onSubmit} = useFormSubmit({
    uri: `/extend-probation-request/${row?._id}/${row?.employee?._id}`,
    eventSourceId: 'Probation',
    ...props,
  }));

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['Probation'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{
        probationEndDate: row?.employee?.probation_end_date,
      }}
      layoutFields={[
        {
          fields: [
            {
              label: 'Reason',
              field: 'extend_reason',
              type: 'textArea',
              required: true,
            },
            {
              label: 'Extend date',
              field: 'extend_date',
              type: 'date',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const ExtendProbationAction = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ProbationExtendForm
      header={{
        title: 'Extend probation',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};
//======================================================================================
export const AcceptInternshipAction = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <InternshipconfirmationFeedback
      header={{
        title: 'Give feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const InternshipconfirmationFeedback = props => {
  const {
    route: {params},
  } = props;
  const {row, forAccept, forReject} = params;
  let onSubmit;
  ({onSubmit} = useFormSubmit({
    uri: `/approve-internship-request/${row?._id}/${row?.employee?._id}`,
    eventSourceId: 'Internship',
    ...props,
  }));

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['Internship'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              type: 'checkbox',
              label: 'Is examination completed',
              field: 'is_examination_completed',
              size: 6,
            },
            {
              type: 'checkbox',
              label: 'Is feedback positive',
              field: 'is_positive_feedback_recieved',
              size: 6,
            },
            {
              label: 'Feedback',
              field: 'internship_feedback',
              type: 'textArea',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const RejectInternshipAction = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <InternshipRejectForm
      header={{
        title: 'Give feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const InternshipRejectForm = props => {
  const {
    route: {params},
  } = props;
  const {row, forAccept, forReject} = params;
  let onSubmit;

  ({onSubmit} = useFormSubmit({
    uri: `/reject-internship-request/${row?._id}/${row?.employee?._id}`,
    eventSourceId: 'Internship',
    ...props,
  }));

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['Internship'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Feedback',
              field: 'internship_feedback',
              type: 'textArea',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const InternshipExtendForm = props => {
  const {
    route: {params},
  } = props;
  const {row, forAccept, forReject} = params;
  console.log('row', row);
  let onSubmit;

  ({onSubmit} = useFormSubmit({
    uri: `/extend-internship-request/${row?._id}/${row?.employee?._id}`,
    eventSourceId: 'Internship',
    ...props,
  }));

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['Internship'],
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{
        internshipEndDate: row?.employee?.internship_end_date,
      }}
      layoutFields={[
        {
          fields: [
            {
              label: 'Reason',
              field: 'extend_reason',
              type: 'textArea',
              required: true,
            },
            {
              label: 'Extend date',
              field: 'extend_date',
              type: 'date',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const ExtendInternshipAction = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <InternshipExtendForm
      header={{
        title: 'Extend internship',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};
