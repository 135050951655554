import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const HelpItemInputForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/helpitems',
    eventSourceId: ['helpitems', 'helpscreens'],
    ...props,
  });

  const defaultValue = {helpScreenId: params?.row?._id};

  return (
    <Form
      defaultValues={defaultValue}
      api={`/helpitems/${row?._id}`}
      fields={{
        title: 1,
        desc: 1,
        videoUrl: 1,
        why_title: 1,
        how_title: 1,
        why_pointers: 1,
        how_pointers: 1,
        detail_title: 1,
        index: 1,
        image: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Help Item'}}
      layoutFields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'desc',
          type: 'text',
        },
        {
          label: 'Detail Title',
          field: 'detail_title',
          type: 'text',
        },
        {
          label: 'Video URL',
          field: 'videoUrl',
          type: 'text',
        },
        {
          label: 'Why Title',
          field: 'why_title',
          type: 'text',
        },
        {
          label: 'Why Pointers',
          field: 'why_pointers',
          type: 'multiText',
        },
        {
          label: 'How Title',
          field: 'how_title',
          type: 'text',
        },

        {
          label: 'How Pointers',
          field: 'how_pointers',
          type: 'multiText',
        },

        {
          label: 'Index',
          field: 'index',
          type: 'number',
        },
        {
          field: 'image',
          type: 'file',
        },
      ]}
      {...props}
    />
  );
};

export const EditHelpItemInputForm = props => {
  return (
    <HelpItemInputForm
      header={{title: 'Edit Help Item'}}
      mode="edit"
      {...props}
    />
  );
};
