import React from 'react';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EmployeeDetailReview = props => {
  const {onClose} = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  const {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'Employees',
    afterSubmit: onClose,
  });
  return (
    <Form
      header="Details required for Health Insurance Renewal"
      mode="edit"
      api={'/employees/' + employee?._id}
      onSubmit={onSubmit}
      fields={{
        marital_status: 1,
        spouse_name: 1,
        spouse_dob: 1,
        spouse_gender: 1,
        have_child: 1,
        first_child_name: 1,
        first_child_dob: 1,
        first_child_gender: 1,
        second_child_name: 1,
        second_child_dob: 1,
        second_child_gender: 1,
        mother_name: 1,
        mother_date_of_birth: 1,
        father_name: 1,
        father_date_of_birth: 1,
        have_parents_insurance: 1,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            detail_review_pending: false,
          },
        };
      }}
      layoutFields={[
        {
          label: 'Marital Status',
          fields: [
            {
              type: 'radioGroup',
              field: 'marital_status',
              options: [
                {value: 'Married', label: 'Married'},
                {value: 'Single', label: 'Single'},
              ],
            },
          ],
        },
        {
          label: 'Spouse Details(as per aadhar card)',
          visible: ({values}) =>
            values &&
            values.marital_status &&
            values.marital_status === 'Married',
          fields: [
            {
              label: 'Name',
              field: 'spouse_name',
              type: 'text',
              required: true,
            },
            {
              label: 'Date Of Birth',
              field: 'spouse_dob',
              type: 'date',
              required: true,
            },
            {
              label: 'Gender',
              field: 'spouse_gender',
              type: 'autoComplete',
              options: ['Male', 'Female'],
            },
            {
              fields: [
                {
                  label: 'Have Child',
                  field: 'have_child',
                  type: 'checkbox',
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Child 1 Details(as per aadhar card)',
          visible: ({values}) =>
            values &&
            values.marital_status &&
            values.marital_status === 'Married' &&
            values.have_child,
          fields: [
            {
              label: 'Name',
              field: 'first_child_name',
              type: 'text',
              required: true,
            },
            {
              label: 'Date Of Birth',
              field: 'first_child_dob',
              type: 'date',
              required: true,
            },
            {
              label: 'Gender',
              field: 'first_child_gender',
              type: 'autoComplete',
              options: ['Male', 'Female'],
              required: true,
            },
          ],
        },
        {
          label: 'Child 2 Details(as per aadhar card)',
          visible: ({values}) =>
            values &&
            values.marital_status &&
            values.marital_status === 'Married' &&
            values.have_child,
          fields: [
            {
              label: 'Name',
              field: 'second_child_name',
              type: 'text',
              // required: true,
            },
            {
              label: 'Date Of Birth',
              field: 'second_child_dob',
              type: 'date',
              // required: true,
            },
            {
              label: 'Gender',
              field: 'second_child_gender',
              type: 'autoComplete',
              options: ['Male', 'Female'],
              // required: true,
            },
          ],
        },
        {
          label: 'Parent details',
          fields: [
            {
              fields: [
                {
                  label: 'Have Parents Insurance',
                  field: 'have_parents_insurance',
                  type: 'checkbox',
                  // required: true,
                },
              ],
            },
            {
              label: 'Mothers name',
              field: 'mother_name',
              type: 'text',
              size: 6,
              visible: ({values}) => values && values.have_parents_insurance,
              // required: true,
            },
            {
              label: 'Mothers DOB',
              field: 'mother_date_of_birth',
              type: 'date',
              size: 6,
              visible: ({values}) => values && values.have_parents_insurance,
              // required: true,
            },
            {
              label: 'Fathers name',
              field: 'father_name',
              type: 'text',
              size: 6,
              visible: ({values}) => values && values.have_parents_insurance,
              // required: true,
            },
            {
              label: 'Fathers DOB',
              field: 'father_date_of_birth',
              type: 'date',
              size: 6,
              visible: ({values}) => values && values.have_parents_insurance,
              // required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
