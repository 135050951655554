import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {GSTTab} from './GSTTable';
import {SoftexTab} from './SoftexTable';
import {InvoiceReportTab} from './InvoiceReportTable';
import {CustomerGrossMarginTab} from '../../customer/views/CustomerGrossMarginTab';
import {AMReportTab} from '../../customer/views/AMReport';
import {CustomerRevenueTabs} from '../../customer/views/CustomerRevenueReport';
import {CustomerChurnReportTabs} from '../../customer/views/CustomerChurnReport';
import {OverduePaymentReportTable} from './OverduePaymentReport';
import {ClientFeedbackReportTable} from '../../pmt/views/ResourceWiseProjectFeedback';
import {DeliveryVsInvoiceReport} from '../../customer/views/DeliveryVsInvoiceReport';

export const CustomerReportsMenu = props => {
  const menus = [
    {
      label: 'Invoices',
      view: <InvoiceReportTab />,
    },
    {
      label: 'GST',
      view: <GSTTab />,
    },
    {
      label: 'Softex',
      view: <SoftexTab />,
    },
    {
      label: 'Customer Gross Margin Report',
      view: <CustomerGrossMarginTab />,
    },
    {
      label: 'AM Performance',
      view: <AMReportTab />,
    },
    {
      label: 'Customer Wise Revenue',
      view: <CustomerRevenueTabs />,
    },
    {
      label: 'Customer Churn Report',
      view: <CustomerChurnReportTabs />,
    },
    {
      label: 'Overdue Payment',
      view: <OverduePaymentReportTable />,
    },
    {
      label: 'Customer Feedbacks',
      view: <ClientFeedbackReportTable />,
    },
    {
      label: 'Delivery Vs Invoice',
      view: <DeliveryVsInvoiceReport />,
    },
  ];
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
