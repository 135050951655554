import React from 'react';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFilter} from '@unthinkable/react-filters';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {Text} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';

const DUE_ALLOCATIONS = ({navigation}) => ({
  render: CountCellRender,
  count_field: 'due_allocation_count',
  field: 'due_allocation_count',
  header: 'Requests',
  styleForCountField: true,
  align: 'right',
  //   onPress: ({row}) => {
  //     navigation.navigate('order-resource-request-table', {
  //       data: {order: row._id},
  //     });
  //   },
  aggregate: true,
});

export const OrderTableWithDueAllocationsForAM = props => {
  const {navigation, route: {params} = {}, addOnFilter, searchValue} = props;
  return (
    <Table
      api={'/getProjectDueAllocations'}
      addOnFilter={{...addOnFilter}}
      params={params}
      search={searchValue}
      searchFields="order_number"
      eventSourceId={[
        'skillRequirement',
        'employeeAssigned',
        'orderresourcerequests',
      ]}
      columns={[
        {header: 'Project', field: 'project', type: 'text', minWidth: 200},
        {header: 'Type', field: 'project_sub_category'},
        {
          header: 'Cusotmer ',
          field: 'customer.name',
          type: 'colorTag',
          color: '#F5E0FF',
          minWidth: 240,
        },
        {
          header: 'CSM/PM ',
          align: 'center',
          render: ({row}) => {
            return <UserAvatar value={row?.csm || row?.owner_id} />;
          },
        },
        {
          header: 'Team ',
          render: CountCellRender,
          count_field: 'allocations',
          align: 'right',
        },
        {
          header: 'Avg Exp. ',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#737373'}}>
                {row?.avg_exp}y
              </Text>
            );
          },
        },
        DUE_ALLOCATIONS({navigation}),
      ]}
    />
  );
};

export const PendingProjectOrderForAM = props => {
  const {navigation, route: {params} = {}, addOnFilter, searchValue} = props;
  return (
    <Table
      api={'/pending-project-order-mapping'}
      addOnFilter={{...addOnFilter}}
      params={params}
      search={searchValue}
      searchFields="order_number"
      columns={[
        {
          header: 'Order No.',
          field: 'order_number',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Cusotmer ',
          field: 'customer_id.name',
          type: 'colorTag',
          color: '#F5E0FF',
          minWidth: 240,
        },
        {
          header: 'Type',
          field: 'order_type',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#737373'}}>
                {row?.order_type == 'ot' ? 'Fixed' : 'T&M'}
              </Text>
            );
          },
        },
        {
          header: 'Account Owner',
          field: 'account_owner_id',
          type: 'userAvatarChip',
        },
      ]}
    />
  );
};

export const AllocationPendingForAMTab = props => {
  const {route: {params} = {}} = props;
  const filterInfo = useFilter();
  const {
    searchValue,
    onChangeFilter,
    filterValues: {filter = {}},
  } = filterInfo;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  let tabs = {};
  tabs.pending_allocation = {
    apiKey: 'active',
    label: 'Pending Requests',
    api: '/getProjectDueAllocations',
    view: (
      <OrderTableWithDueAllocationsForAM
        addOnFilter={{...filter, account_owner: employeeId?._id}}
        searchValue={searchValue}
      />
    ),
    addOnFilter: {
      ...filter,
      account_owner: employeeId?._id,
    },
  };
  
  // after disscss with amit sir this view is remove
  // tabs.project_mapping_pending = {
  //   apiKey: 'active',
  //   label: 'Pending Order Mapping',
  //   api: '/pending-project-order-mapping',
  //   view: (
  //     <PendingProjectOrderForAM
  //       addOnFilter={{...filter, account_owner_id: employeeId?._id}}
  //       searchValue={searchValue}
  //     />
  //   ),
  //   addOnFilter: {
  //     ...filter,
  //     account_owner_id: employeeId?._id,
  //   },
  // };

  return <TabView tabs={tabs} params={params} {...props} />;
};
