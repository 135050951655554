import React from 'react';
import {Table} from '../../../components/table/Table';
import moment from 'moment';
import {Text} from '@unthinkable/react-core-components';

const getStartAndEndOfDay = dateString => {
  if (!dateString) {
    throw new Error('No date string provided');
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string provided');
  }

  const startOfDay = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
      0,
    ),
  );

  const endOfDay = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59,
      59,
      999,
    ),
  );

  return {
    from: startOfDay.toISOString(),
    to: endOfDay.toISOString(),
  };
};

export const OutreachCampaignTableGrouped = props => {
  let {
    route: {params},
    navigation,
    auto_verified,
    auto_verified_error,
  } = props;

  let {
    _id,
    verified,
    sent_action,
    status,
    showUnverified,
    name: campaign_name,
    filter,
  } = params;

  let addOnFilter = {
    campaign_id: _id,
    status,
    auto_verified,
    auto_verified_error,
  };

  if (verified) {
    addOnFilter = {...addOnFilter, verified};
  }

  return (
    <Table
      eventSourceId={['outreachAdded', 'indexUpdated', 'actionVerified']}
      api={`/campaignactions/OutreachCampaignActionsGrouppedPending`}
      params={{
        verified,
        campaign_id: _id,
        sent_action,
        status,
        filter: {...filter, auto_verified, auto_verified_error},
      }}
      onRowPress={({row}) => {
        let {
          sequence_display,
          action,
          completedOn,
          scheduledOn,
          actualScheduledOn,
        } = row || {};
        let period = completedOn && getStartAndEndOfDay(completedOn);

        let scheduled_period = scheduledOn && getStartAndEndOfDay(scheduledOn);

        let actual_scheduled_period =
          actualScheduledOn && getStartAndEndOfDay(actualScheduledOn);

        let extraFilter = {};
        let extraFields = {};

        if (sent_action && period) {
          extraFilter = {
            ...extraFilter,
            completedOn: {
              $gt: period.from,
              $lt: period.to,
            },
          };
        } else if (!showUnverified) {
          if (actual_scheduled_period) {
            extraFilter = {
              ...extraFilter,
              actualScheduledOn: {
                $gt: actual_scheduled_period.from,
                $lt: actual_scheduled_period.to,
              },
            };
          } else {
            extraFilter = {
              ...extraFilter,
              actualScheduledOn: null,
            };
          }
          extraFields = {
            sort: {actualScheduledOn: 1},
          };
        } else if (showUnverified && scheduled_period) {
          extraFilter = {
            ...extraFilter,
            scheduledOn: {
              $gt: scheduled_period.from,
              $lt: scheduled_period.to,
            },
          };
        }
        navigation.navigate('show-actions', {
          row,
          addOnFilter: {
            ...addOnFilter,
            action,
            sequence_display,
            ...extraFilter,
          },
          showUnverified,
          campaign_name,
          sent_action,
          ...extraFields,
        });
      }}
      columns={[
        {
          header: 'Scheduled On',
          field: 'scheduledOn',
          width: 300,
          type: 'date',
          visible: () => {
            return !sent_action && showUnverified;
          },
        },
        {
          header: 'Scheduled On',
          field: 'actualScheduledOn',
          width: 300,
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {moment.utc(row.actualScheduledOn).format('D MMM')}
              </Text>
            );
          },
          visible: () => {
            return !sent_action && !showUnverified;
          },
        },
        {
          header: 'Completed On',
          field: 'completedOn',
          width: 300,
          type: 'date',
          visible: () => {
            return sent_action;
          },
        },
        {header: 'Name', field: 'name', type: 'text'},
        {header: 'Action', field: 'action', type: 'text'},
        {header: 'Sequence', field: 'sequence_display', type: 'text'},
        {header: 'Count', field: 'count', type: 'text', width: 150},
      ]}
    />
  );
};
