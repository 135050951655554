import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AddMessageForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Message',
    readOnly = false,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignmessages',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/campaignmessages/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
      }}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Response Status',
          field: 'when',
          type: 'autoComplete',
          options: ['No Response', 'Positive', 'Negative'],
          size: 6,
        },
        {
          label: 'Message Number',
          field: 'send_after',
          type: 'text',
          size: 6,
        },
        {
          label: 'Subject',
          field: 'subject',
          type: 'text',
        },
        {
          label: 'Body',
          field: 'body',
          type: 'richText',
        },
      ]}
      {...props}
    />
  );
};

export const EditMessageForm = props => {
  return <AddMessageForm header={'Edit Message'} mode="edit" {...props} />;
};

export const MessageFormDetail = props => {
  return (
    <AddMessageForm
      header={'Message Detail'}
      mode="edit"
      {...props}
      readOnly={true}
    />
  );
};
