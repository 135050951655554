import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import NestedTable from '../../../components/form/NestedTable';

const EmployeeRequestForFixedOders = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'orderresourcerequests',
    throwError: true,
  });

  return (
    <Form
      onSubmit={data => {
        invoke({uri: `/createOrderRequests`, props: {data: [data]}});
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          placeholder: 'From Date',
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          placeholder: 'To Date',
        },
        {
          type: 'number',
          field: 'no_of_resources_requested',
          label: 'No. of Resources ',
          placeholder: 'Resources',
          required: true,
          // onChangeValue: (value, _, {setFieldValue}) => {
          //   setFieldValue('no_of_resources_left', value);
          // },
        },
        {
          placeholder: 'Experience',
          field: 'experience',
          type: 'autoComplete',
          api: `/employeeexperiences`,
          suggestionField: 'experience',
          valueField: 'experience',
          required: true,
        },
        {
          label: 'Skills',
          placeholder: 'Skills',
          field: 'skill_required',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Tool',
          placeholder: 'Tool',
          field: 'tool',
          type: 'autoComplete',
          api: ({values = {}}) => {
            const {skill_required} = values;
            if (!skill_required) return `/tools`;
            return `/skillMetrics/toolsSuggestion/${skill_required?._id}`;
          },
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          type: 'number',
          field: 'hours',
          label: 'Hours per Day',
          placeholder: 'Hours',
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeRequestForm = props => {
  const {route: {params} = {}} = props || {};
  const {row = {}} = params || {};
  const {user} = useAppStateContext();
  const {employee} = user;
  let defaultValues = {
    status: 'pending',
    order_type: 'ot',
    requested_from: employee?._id,
    customer: row?.customer_id,
    requested_date: row?.order_date,
  };
  if (row?.order_date) {
    defaultValues['from_date'] = row?.order_date;
  }
  if (row?.order_end_date) {
    defaultValues['to_date'] = row?.order_end_date;
  }
  if (row?._id) {
    defaultValues['order'] = row?._id;
  }

  return (
    <EmployeeRequestForFixedOders
      header="Resource Request"
      defaultValues={defaultValues}
      {...props}
    />
  );
};

export default EmployeeRequestForFixedOders;

export const ProjectwiseEmployeeRequestForFixedOders = props => {
  const {route: {params} = {}} = props || {};
  const {row = {}} = params || {};
  const {user} = useAppStateContext();
  const {employee} = user;
  let defaultValues = {
    status: 'pending',
    order_type: 'ot',
    requested_from: employee?._id,
    customer: row?.customer,
  };
  if (row?._id) {
    defaultValues['project'] = row?._id;
  }
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'orderresourcerequests',
    throwError: true,
  });

  return (
    <Form
      header="Resource Request"
      onSubmit={data => {
        invoke({
          uri: `/createOrderRequests`,
          props: {data: data?.orderResourceRequestData},
        });
      }}
      submitAction="Save"
      layoutFields={[
        {
          field: 'orderResourceRequestData',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              defaultValues={defaultValues}
              inlineAddForm
              fields={[
                {
                  field: 'no_of_resources_requested',
                  header: 'Count',
                  placeholder: 'Count',
                  type: 'number',
                },
                {
                  field: 'tool',
                  placeholder: 'Skill',
                  header: 'Skill',
                  type: 'autoComplete',
                  api: `/tools`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                },
                {
                  field: 'experience',
                  label: 'Experience',
                  type: 'autoComplete',
                  api: '/experiences',
                  suggestionField: 'experience',
                  valueField: 'experience',
                  required: true,
                },
                {
                  field: 'order',
                  label: 'Order',
                  type: 'autoComplete',
                  api: '/orders',
                  suggestionField: 'order_number',
                  valueField: 'order_number',
                  filter: {
                    customer_id: row?.customer,
                    order_status: 'Active',
                  },
                  fields: {
                    _id: 1,
                    order_date: 1,
                    order_end_date: 1,
                    order_number: 1,
                  },
                  required: true,
                },
                {
                  field: 'milestone_id',
                  label: 'Milestone',
                  type: 'autoComplete',
                  api: '/ordermilestones',
                  suggestionField: 'order_milestone_no',
                  valueField: 'order_milestone_no',
                  filter: ({values}) => {
                    return {
                      order: values?.order?._id,
                    };
                  },
                  fields: {
                    order: {_id: 1, order_date: 1, order_end_date: 1},
                    order_milestone_no: 1,
                  },
                  onChangeValue: (value, e, {setFieldValue}) => {
                    setFieldValue('from_date', value?.order?.order_date);
                    setFieldValue('to_date', value?.order?.order_end_date);
                    setFieldValue('requested_date', value?.order?.order_date);
                  },
                  required: true,
                },
                {
                  type: 'date',
                  field: 'from_date',
                  label: 'From',
                  placeholder: 'From',
                },
                {
                  type: 'date',
                  field: 'to_date',
                  label: 'To',
                  placeholder: 'To',
                },
                {
                  type: 'number',
                  field: 'hours',
                  label: 'Hrs/Day',
                  placeholder: 'Hrs/Day',
                },
              ]}
              columns={[
                {
                  field: 'no_of_resources_requested',
                  header: 'Count',
                },
                {
                  field: 'tool.name',
                  header: 'Skill',
                },
                {
                  field: 'experience.experience',
                  header: 'Experience',
                },
                {
                  field: 'order.order_number',
                  header: 'Order',
                },
                {
                  field: 'milestone_id.order_milestone_no',
                  header: 'Milestone',
                },
                {
                  type: 'date',
                  field: 'from_date',
                  header: 'From',
                  formatOptions: {
                    format: 'DD MMM YY',
                  },
                },
                {
                  type: 'date',
                  field: 'to_date',
                  header: 'To',
                  formatOptions: {
                    format: 'DD MMM YY',
                  },
                },
                {
                  type: 'number',
                  field: 'hours',
                  header: 'Hrs/Day',
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};
