import {Chip} from '@unthinkable/react-chip';
import {Image, Text} from '@unthinkable/react-core-components';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';

const ModeRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'mode', type: 'text'},
        {
          field: 'opportunity_id.work_location',
          type: 'text',
          variant: 'subtle',
        },
      ]}
    />
  );
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (
    row?.outcome &&
    (row?.outcome == 'Rejected' ||
      row?.outcome == 'Drop' ||
      row?.outcome == 'Denied by Resource' ||
      row?.outcome == 'Lost')
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.outcome}
        </Text>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          {row?.outcome_description}
        </Text>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Selected') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.outcome}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Shift To Another Project') {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          fontSize: 13,
          color: '#30839f',
          overflow: 'hidden',
        }}>
        {row?.outcome}
      </Text>
    );
  } else if (row?.interview_date && row?.interview_time && !row.outcome) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          Interview Scheduled
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.interview_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const LostRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description', type: 'text'},
        {
          field: 'outcome_date',
          type: 'date',
        },
      ]}
    />
  );
};

const EmployeeCountRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.employee_count.number', type: 'text'},
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
        },
      ]}
    />
  );
};

const CountryRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.country.name', type: 'text'},
        {
          field: 'customer_id.country.name',
          type: 'text',
        },
      ]}
    />
  );
};

const RESOURCE_NAME = {
  field: 'resource',
  header: 'Interviewee',
  type: 'userAvatarChip',
};

const EXPERIENCE = {
  field: 'resource.experience',
  header: 'Experience',
};

const ASSIGNED_DATE = {
  type: 'date',
  field: 'rfr_assigned_date',
  header: 'Assigned Date',
};

const REQUIRMENT = {
  field: 'opportunity_id.requirement',
  header: 'Requirement',
  type: 'chip',
};

const MENTOR = {
  field: 'mentor.name',
  colorField: 'mentor.color',
  header: 'Trainer',
  type: 'colorTag',
};

const MODE = {
  field: 'mode',
  header: 'Mode',
};

const INTERVIEW_DATE = {
  type: 'date',
  field: 'interview_date',
  header: 'Interview Date',
};

const OUTCOME = {
  field: 'outcome',
  header: 'Outcome',
};

const OUTCOME_DESCRIPTION = {
  field: 'outcome_description',
  header: 'Outcome Description',
};

const REJECTION_REASON = {
  field: 'outcome_rejection_reason',
  header: 'Rejection Reason',
};

const OUTCOME_DATE = {
  field: 'outcome_date',
  header: 'Outcome Date',
  type: 'date',
};

const MODEResources = {
  header: 'Mode',
  width: 100,
  render: ModeRender,
};

const STATUS = {
  header: 'Status',
  render: RenderStatus,
};

const LOST_REASON = {
  header: 'Lost Reason',
  render: LostRender,
};

const DROP_REASON = {
  header: 'Drop Reason',
  render: LostRender,
};

const INDUSTRY = {
  header: 'Industry',
  field: 'potential_customer.industry.name',
  colorField: 'potential_customer.industry.color',
  type: 'colorTag',
};


const EMPLOYEE_COUNT = {
  header: 'Size',
  render: EmployeeCountRender,
  responsive: 'sm',
};
const COUNTRY = {
  header: 'Country',
  render: CountryRender,
  responsive: 'sm',
};

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const RenderSkills = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const skillName = row?.opportunity_id?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return (
    <Text
      style={{
        ...rowText,
        backgroundColor: colors['ACCENT1_MEDIUM'],
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 5,
      }}>
      {skillName}
    </Text>
  );
};

export const UnPlannedInterviewTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    filter,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {scheduleResourceInterview} = leadControllers(props);

  return (
    <Table
      {...props}
      api={`/unplannedinterviewresourcesWithEntity`}
      eventSourceId={[
        'StaffAugmentation',
        'communicationAdded',
        'CustomPlatformOpportunity',
      ]}
      params={params}
      moreActions={[{title: 'Schedule', onPress: scheduleResourceInterview}]}
      columns={[
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        EMPLOYEE_COUNT,
        {
          field: 'opportunity_id.industry.name',
          colorField: 'opportunity_id.industry.color',
          type: 'colorTag',
          header: 'Industry',
        },
        COUNTRY,
        RESOURCE_NAME,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 120,
        },
        EXPERIENCE,
        MODEResources,
        {
          header: 'Communication',
          // onPress: ({row}) => {
          //   POCCommunications({row});
          // },
          render: LastCommunicationRender,
        },
      ]}
    />
  );
};

export const PlannedInterviewTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    filter,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {scheduleResourceInterview, markInterviewResourceOutcome} =
    leadControllers(props);

  return (
    <Table
      api={`/plannedinterviewresourcesWithEntity`}
      eventSourceId={[
        'CustomPlatformOpportunity',
        'StaffAugmentation',
        'communicationAdded',
      ]}
      params={params}
      sort={{interview_date: -1}}
      moreActions={[
        {
          title: 'Mark Outcome',
          onPress: markInterviewResourceOutcome,
        },
        {
          title: 'Correct Interview Schedule',
          onPress: scheduleResourceInterview,
        },
      ]}
      columns={[
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        EMPLOYEE_COUNT,
        {
          field: 'opportunity_id.industry.name',
          colorField: 'opportunity_id.industry.color',
          type: 'colorTag',
          header: 'Industry',
        },
        COUNTRY,
        RESOURCE_NAME,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 120,
        },
        EXPERIENCE,
        MODEResources,
        {
          header: 'Communication',
          // onPress: ({row}) => {
          //   POCCommunications({row});
          // },
          render: LastCommunicationRender,
        },
        STATUS,
      ]}
      {...props}
    />
  );
};

export const WonInterviewTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    filter,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {outcomeDateCorrection} = leadControllers(props);

  return (
    <Table
      api={`/woninterviewresourcesWithEntity`}
      eventSourceId={[
        'StaffAugmentation',
        'communicationAdded',
        'CustomPlatformOpportunity',
      ]}
      moreActions={[
        {title: 'Correct Outcome Date', onPress: outcomeDateCorrection},
      ]}
      params={params}
      sort={{interview_date: -1}}
      columns={[
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        EMPLOYEE_COUNT,
        {
          field: 'opportunity_id.industry.name',
          colorField: 'opportunity_id.industry.color',
          type: 'colorTag',
          header: 'Industry',
        },
        COUNTRY,
        RESOURCE_NAME,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 120,
        },
        EXPERIENCE,
        MODEResources,
        {
          header: 'Communication',
          render: LastCommunicationRender,
        },
        STATUS,
      ]}
      {...props}
    />
  );
};

export const LostInterviewTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    filter,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {outcomeDateCorrection} = leadControllers(props);

  return (
    <Table
      api={`/lostinterviewresourcesWithEntity`}
      eventSourceId={[
        'StaffAugmentation',
        'communicationAdded',
        'CustomPlatformOpportunity',
      ]}
      moreActions={[
        {title: 'Correct Outcome Date', onPress: outcomeDateCorrection},
      ]}
      sort={{interview_date: -1}}
      params={params}
      columns={[
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        EMPLOYEE_COUNT,
        {
          field: 'opportunity_id.industry.name',
          colorField: 'opportunity_id.industry.color',
          type: 'colorTag',
          header: 'Industry',
        },
        COUNTRY,
        RESOURCE_NAME,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 120,
        },
        EXPERIENCE,
        MODEResources,
        {
          header: 'Communication',
          // onPress: ({row}) => {
          //   POCCommunications({row});
          // },
          render: LastCommunicationRender,
        },

        LOST_REASON,
      ]}
      {...props}
    />
  );
};

export const DropInterviewTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    filter,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {outcomeDateCorrection} = leadControllers(props);
  return (
    <Table
      api={`/dropInterviewResourcesWithEntity`}
      eventSourceId={[
        'StaffAugmentation',
        'communicationAdded',
        'CustomPlatformOpportunity',
      ]}
      moreActions={[
        {title: 'Correct Outcome Date', onPress: outcomeDateCorrection},
      ]}
      params={params}
      sort={{interview_date: -1}}
      columns={[
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        EMPLOYEE_COUNT,
        {
          field: 'opportunity_id.industry.name',
          colorField: 'opportunity_id.industry.color',
          type: 'colorTag',
          header: 'Industry',
        },
        COUNTRY,
        RESOURCE_NAME,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 120,
        },
        EXPERIENCE,
        MODEResources,
        {
          header: 'Communication',
          // onPress: ({row}) => {
          //   POCCommunications({row});
          // },
          render: LastCommunicationRender,
        },

        DROP_REASON,
      ]}
      {...props}
    />
  );
};
