import React from 'react';

import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

export const TrainingMaterialTable = props => {
  const {
    route: {params},
    navigation
  } = props;
  const {skill_topic,skill_metrics} = params;
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <AddButton
                title="Training Material"
                view="add-training-material"
                params={params}
              />,
            ]}
          />
        );
      }}
      eventSourceId={["TrainingMaterials"]}
      api="/trainingMaterials"
      fields={{
        name: 1,
        days: 1,
        url: 1,
        material_type: {
            name:1,
            color:1
        },
      }}
      filter={{
        skill_topic,
        skill_metrics
      }}
      onRowPress= {
        ({row})=>{
        navigation.navigate('edit-training-material', {
            ...params,
            training_material: row,
          });
        }
      }
      columns={[
        {
          field: 'name',
          header: 'Material',
          type: 'text',
        },
        {
          field: 'url',
          header: 'Link',
          type: 'text',
        },
        {
          field: 'material_type.name',
          header: 'Category',
          type: 'colorTag',
          colorField:'material_type.color'
        },
      ]}
      {...props}
    />
  );
};
