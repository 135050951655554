import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  ActiveProspectTable,
  AllProspectTable,
  FreeProspectTable,
  LostProspectTable,
} from './ProspectTable';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';

export const ProspectsTableTabView = props => {
  let {route: {params} = {}, navigation} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });

  let {filter, params: filterParams = {}} = filterValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const tabs = {
    activeProspectTable: {
      label: 'Active',
      view: (
        <ActiveProspectTable
          addOnFilter={filter}
          search={searchValue}
          searchFields={['name', 'email', 'website']}
          filterParams={filterParams}
        />
      ),
      api: '/prospect/active',
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    freeProspectTable: {
      label: 'Free',
      view: (
        <FreeProspectTable
          addOnFilter={filter}
          search={searchValue}
          searchFields={['name', 'email', 'website']}
          filterParams={filterParams}
        />
      ),
      api: '/prospect/free',
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    lostProspectTable: {
      label: 'Lost',
      view: (
        <LostProspectTable
          addOnFilter={filter}
          search={searchValue}
          searchFields={['name', 'email', 'website']}
          filterParams={filterParams}
        />
      ),
      api: '/prospect/lost',
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    allProspectTable: {
      label: 'All',
      view: (
        <AllProspectTable
          addOnFilter={filter}
          search={searchValue}
          searchFields={['name', 'email', 'website']}
          filterParams={filterParams}
        />
      ),
      api: '/prospect/all',
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    // activityProspectTable: {
    //   label: 'Activity',
    //   view: (
    //     <ActivityProspectTable
    //       addOnFilter={filter}
    //       search={searchValue}
    //       searchFields={['name', 'email', 'website']}
    //       filterParams={filterParams}
    //     />
    //   ),
    //   api: '/prospect/active',
    //   params: {
    //     period: filterParams.period,
    //     ...params,
    //   },
    //   addOnFilter: filter,
    //   eventSourceId: ['Lead', 'communicationAdded'],
    // },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },
            // {
            //   label: 'Campaign',
            //   placeholder: 'Select',
            //   field: 'campaign_id',
            //   type: 'autoComplete',
            //   api: '/sourceSuggestions',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   params: ({filter: {campaign_type} = {}}) => {
            //     return {
            //       source: campaign_type,
            //     };
            //   },
            // },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'created_on',
              asParam: true,
              label: 'Created On',
            },
            {
              type: 'date',
              field: 'active_on',
              asParam: true,
              label: 'Active On',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'last_communication_date',
              asParam: true,
              label: 'Last Comm Date',
            },

            {
              type: 'autoComplete',
              label: 'Is System Free',
              options: [
                {label: 'True', value: 'true'},
                {label: 'False', value: 'false'},
              ],
              placeholder: 'Select',
              field: 'is_system_free',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Status',
              options: ['Active', 'DNC', 'Free', 'Lost', 'Unqualified'],
              placeholder: 'Select',
              field: 'lead_status',
            },
          ]}
        />,
        // <ObjectWiseTasks
        //   {...props}
        //   params={{...props?.route?.params}}
        //   object={{
        //     _id: '643e9e0483dd7120a07d3e9a',
        //     name: 'PotentialCustomer',
        //   }}
        // />,
        <AddButton
          title="Prospect"
          view="add-prospect"
          params={props?.route?.params}
        />,
      ]}
      {...props}
    />
  );
};

export const AllProspectTableTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  params = {...params, ...filterParams};
  const {parent_task_id = ''} = params;

  // Akshat Garg 24/02/24 - created two new tab views new and From Free

  const allProspectApiNew = {
    api: `/prospect/all`,
    params: {...params, created: true},
  };

  const allProspectApiFromFree = {
    api: `/prospect/all`,
    params: {...params, created: false},
  };

  const allProspectApi = {
    api: `/prospect/all`,
  };

  let tabs = {};

  if (parent_task_id) {
    tabs = {
      new: {
        label: 'New',
        view: (
          <AllProspectTable
            searchValue={searchValue}
            filterValues={filterValues}
            allProspectApiNew={allProspectApiNew}
            created={true}
          />
        ),
        ...allProspectApiNew,
      },
      fromFree: {
        label: 'From Free',
        view: (
          <AllProspectTable
            searchValue={searchValue}
            filterValues={filterValues}
            allProspectApiFromFree={allProspectApiFromFree}
            created={false}
          />
        ),
        ...allProspectApiFromFree,
      },
    };
  } else {
    tabs = {
      prospects: {
        label: 'Prospects',
        view: (
          <AllProspectTable
            searchValue={searchValue}
            filterValues={filterValues}
            allProspectApi={allProspectApi}
          />
        ),
        ...allProspectApi,
      },
    };
  }

  return (
    <TabView
      tabs={tabs}
      params={params}
      onChangeFilter={onChangeFilter}
      search={searchValue}
      searchFields={['name']}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
    />
  );
};

// Akshat Garg - 21/03/24 - creating new screen for a single tab of prospects table
export const ProspectContactedTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {params: filterParams = {}} = filterValues;
  params = {...params, ...filterParams};

  const allProspectApi = {
    api: `/prospect/all`,
  };

  let tabs = {
    prospects: {
      label: 'Prospects',
      view: (
        <AllProspectTable
          searchValue={searchValue}
          filterValues={filterValues}
          allProspectApi={allProspectApi}
        />
      ),
      ...allProspectApi,
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      onChangeFilter={onChangeFilter}
      search={searchValue}
      searchFields={['name']}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
    />
  );
};

export const ProspectsTabForFollowUp = props => {
  let {route: {params} = {}, navigation, path = ''} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });

  let {filter, params: filterParams = {}} = filterValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const tabs = {
    activeProspectTable: {
      label: 'Active',
      view: (
        <ActiveProspectTable
          filter={filter}
          search={searchValue}
          searchFields={['name', 'email', 'website']}
          filterParams={filterParams}
          path={path}
        />
      ),
      api: '/prospect/active',
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: {
        ...filter,
        next_task_date: getZeroTimeDate(new Date()),
        owner: employeeId?._id,
      },
      eventSourceId: ['Lead', 'communicationAdded'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },
            // {
            //   label: 'Campaign',
            //   placeholder: 'Select',
            //   field: 'campaign_id',
            //   type: 'autoComplete',
            //   api: '/sourceSuggestions',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   params: ({filter: {campaign_type} = {}}) => {
            //     return {
            //       source: campaign_type,
            //     };
            //   },
            // },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'created_on',
              asParam: true,
              label: 'Created On',
            },
            {
              type: 'date',
              field: 'active_on',
              asParam: true,
              label: 'Active On',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'last_communication_date',
              asParam: true,
              label: 'Last Comm Date',
            },

            {
              type: 'autoComplete',
              label: 'Is System Free',
              options: [
                {label: 'True', value: 'true'},
                {label: 'False', value: 'false'},
              ],
              placeholder: 'Select',
              field: 'is_system_free',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },

            // {
            //   type: 'autoComplete',
            //   label: 'Response Status',
            //   options: ['Positive', 'Negative'],
            //   placeholder: 'Select',
            //   field: 'response_status',
            // },
          ]}
        />,
        // <ObjectWiseTasks
        //   {...props}
        //   params={{...props?.route?.params}}
        //   object={{
        //     _id: '643e9e0483dd7120a07d3e9a',
        //     name: 'PotentialCustomer',
        //   }}
        // />,
        // <AddButton
        //   title="Prospect"
        //   view="add-prospect"
        //   params={props?.route?.params}
        // />,
      ]}
      {...props}
    />
  );
};
