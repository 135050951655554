export const PROJECT_TYPES = {
  staffaugmentation: {
    label: 'Staff Augmentation',
    color: 'Accent1',
  },
  managedteam: {
    label: 'Managed Team',
    color: 'Accent2',
  },
  sdlc: {
    label: 'SDLC',
    color: 'Accent3',
  },
  sdlcplus: {
    label: 'SDLC+',
    color: 'Accent4',
  },
  i: {
    label: 'Internal',
    color: 'Accent5',
  },
};
