import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const Pagination = ({
  imageIndex,
  setImageIndex,
  attachmentCount,
  ImagePaginationStyle,
}) => {
  const {
    LeftCaret,
    RightCaret,
    textContainerstyle,
    textStyle,
    paginationAbsoluteContainerStyle,
    paginationContainerStyle,
    moveIconContainerStyle,
    disabeMoveIconContainerStyle,
  } = ImagePaginationStyle || {};
  return (
    <View style={paginationAbsoluteContainerStyle}>
      <Row gap={8} style={paginationContainerStyle}>
        <TouchableOpacity
          disabled={imageIndex === 0}
          onPress={() => {
            if (imageIndex > 0) {
              setImageIndex(imageIndex - 1);
            }
          }}
          style={{
            ...moveIconContainerStyle,
            ...(imageIndex === 0 ? disabeMoveIconContainerStyle : {}),
          }}>
          <Image source={LeftCaret} />
        </TouchableOpacity>
        <Row style={textContainerstyle}>
          <Text style={textStyle}>{`${
            imageIndex + 1
          }/${attachmentCount}`}</Text>
        </Row>
        <TouchableOpacity
          disabled={imageIndex === attachmentCount - 1}
          onPress={() => {
            if (imageIndex < attachmentCount - 1) {
              setImageIndex(imageIndex + 1);
            }
          }}
          style={{
            ...moveIconContainerStyle,
            ...(imageIndex === attachmentCount - 1
              ? disabeMoveIconContainerStyle
              : {}),
          }}>
          <Image source={RightCaret} />
        </TouchableOpacity>
      </Row>
    </View>
  );
};
