import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AssignMentorWiseTraineeOnBatch = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/assignMentorWiseEmployeesOnBatch',
    eventSourceId:'TrainingBatches'
  });
  const {
    route: {
      params
    }
  } = props;
  const {training_batch} = params;
  return (
    <Form
      defaultValues={{
        training_program: training_batch?.training_program,
        training_batch: training_batch?._id,
      }}
      onSubmit={onSubmit}
      submitAction="Assign"
      layoutFields={[
        {
          field: 'mentor_wise_mentees',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              columns={[
                {
                  header: 'Trainer',
                  field: 'mentor',
                  type: 'userAvatarChip',
                },
                {
                  header: 'Trainer',
                  field: 'mentee',
                  type: 'userAvatarChip',
                },
              ]}
              fields={[
                {
                  field: 'mentor',
                  placeholder: 'Trainer',
                  label: 'Trainer',
                  type: 'autoComplete',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  fields: {
                    name: 1,
                    official_email_id: 1,
                    color: 1,
                  },
                },
                {
                  field: 'mentee',
                  placeholder: 'Trainee',
                  label: 'Trainee',
                  type: 'autoComplete',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  fields: {
                    name: 1,
                    official_email_id: 1,
                    color: 1,
                  },
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};
