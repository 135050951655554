import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {KPIIncentiveTable} from '../../department/views/EmployeeSalaryTable';
import {
  BonusSalaryHrTable,
  FixedSalaryHrTable,
  IncentiveSalaryHrTable,
  KPIIncentiveHRTable,
  TaxDeclarationHrTab,
} from './EmployeeSalaryHrTable';
import {EmployeeSelfLevelHrTab} from './EmployeeSelfLevelHrTab';
import {ShiftAllowanceHrTab} from './ShiftAllowanceHr';

export const EmployeeSalaryHrMenu = props => {
  const menus = [
    {
      label: 'Fixed',
      view: <FixedSalaryHrTable {...props} />,
    },
    // {
    //   label: 'IC Incentinus',
    //   view: <IncentiveSalaryHrTable {...props} />,
    // },
    {
      label: 'Bonus',
      view: <BonusSalaryHrTable {...props} />,
    },
    {
      label: 'KPI Incentive',
      view: <KPIIncentiveHRTable {...props} />,
    },
    {
      label: 'Tax Declarations',
      view: <TaxDeclarationHrTab {...props} />,
    },
    {
      label: 'Shift Allowance',
      view: <ShiftAllowanceHrTab {...props} />,
    },
    {
      label: 'Self L1 L2 L3',
      view: <EmployeeSelfLevelHrTab {...props} />,
    },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
