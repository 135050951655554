import React from 'react';
import {getToday, useFilter} from '@unthinkable/react-filters';

import {TabView} from '../../../components/tab';
import {GroupFilter} from '../../../components/filter/Filters';

import {ActiveTasksTable, BacklogTasksTable, CompletedTasksTable} from './TasksTable';
import {taskFilters} from './TaskActions';

export const OrganizationTasksTab = props => {
  let {filterValues = {}, ...filterProps} = useFilter({});

  const {
    route: {params},
  } = props;
  const {all_tasks, overdueTasks} = params;

  const {from: startOfToday} = getToday();

  let addOnFilter = overdueTasks ? {
    ...filterValues?.filter, type: { $in: ['Task', 'Continuous'] },
    due_date: { $lt: startOfToday }
  } : filterValues?.filter;
  
  // Akshat Garg - 22/08/24 - adding backlogtab for all tasks
  const backLogTab = all_tasks ? {
    backlog: {
      label: 'Plan',
      view: (
        <BacklogTasksTable
          skipAddTask={!params?.source_id}
          filterValues={{filter: {...filterValues?.filter, ...addOnFilter}}}
        />
      ),
      eventSourceId: 'Task',
      actions: [
        <GroupFilter
          {...filterProps}
          filterValues={filterValues}
          filters={[taskFilters.source, taskFilters.custom]}
        />,
      ],
    },
  } : {};

  return (
    <TabView
      {...props}
      tabs={{
        ...backLogTab,
        active: {
          label: 'Active',
          view: (
            <ActiveTasksTable
              parentSourceView="MyTeam"
              skipAddTask
              filterValues={{filter: {...filterValues?.filter, ...addOnFilter}}}
            />
          ),
          eventSourceId: 'Task',
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.source, taskFilters.custom]}
            />,
          ],
        },
        completed: {
          label: 'Completed',
          view: (
            <CompletedTasksTable
              parentSourceView="MyTeam"
              defaultFilter={filterValues.filter}
            />
          ),
          eventSourceId: 'Task',
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.source, taskFilters.custom]}
            />,
          ],
        },
      }}
    />
  );
};
