import React, {useState} from 'react';
import {SketchPicker} from './ColorPicker';
import {
  Dimensions,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {isMobile} from '@unthinkable/react-utils';

export const ColorPickerModal = ({
  onChangeValue,
  hide,
  modalContainerStyle,
  actionContainerStyle,
  actionTextStyle,
}) => {
  const [sketchPickerColor, setSketchPickerColor] = useState('#4276ec');
  return (
    <TouchableOpacity
      style={modalContainerStyle}
      activeOpacity={1}
      onPress={e => {
        e?.stopPropagation();
      }}>
      <SketchPicker
        {...(isMobile ? {width: Dimensions.get('screen').width - 24} : {})}
        onChange={color => {
          setSketchPickerColor(color.hex);
        }}
        color={sketchPickerColor}
      />
      <Row gap={6} style={actionContainerStyle}>
        <TouchableOpacity
          style={{cursor: 'pointer'}}
          onPress={() => {
            onChangeValue(sketchPickerColor);
            hide();
          }}>
          <Text style={actionTextStyle}>Save</Text>
        </TouchableOpacity>
      </Row>
    </TouchableOpacity>
  );
};
