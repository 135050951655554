import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  RadioGroup as RadioGroupComponent,
  RadioGroupRenderer as RGComponent,
} from '@unthinkable/react-radio-input';
import {RadioGroupStyles, RadioStyles} from './theme';

const RadioGroup = ({options, ...props}) => {
  const styles = useStyles(RadioGroupStyles);
  if (options && typeof options === 'function') {
    options = options(props?.form);
  }

  return <RadioGroupComponent styles={styles} options={options} {...props} />;
};

export const RadioGroupRenderer = props => {
  const styles = useStyles(RadioStyles);
  return <RGComponent styles={styles} {...props} />;
};

export default RadioGroup;
