import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { UserAvatarChip } from '../../../components/avatar/UserAvatar';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useInvoke } from '../../../controllers/useInvoke';

const RenderName = props => {
  const {row} = props;
  const {name, color} = row;
  return <UserAvatarChip value={{name: name, color: color}} />;
};

export const AssignTraineeOnBatch = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter();
  const {filter} = filterValues;
  const {addOnFilter, training_batch} = params;

  const assignTraineeInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'TrainingBatches',
    close: true,
  });

  const assignTrainees = ({selectedIds}) => {
    assignTraineeInvoke({
      uri: '/assignEmployeeOnBatch',
      props: {
        selectedIds,
        training_program: training_batch?.training_program,
        training_batch: training_batch?._id,
        mentor: training_batch.mentor
      },
    });
  };

  return (
    <Table
      params={params}
      renderHeader={() => (
        <TableHeader
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Department',
                  api: '/departments',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'department',
                },
                {
                  type: 'autoComplete',
                  label: 'Product',
                  api: '/products',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'product',
                },
                {
                  type: 'autoComplete',
                  label: 'Reporting To',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'reporting_to',
                  filter: {employee_status: 'Active'},
                },
              ]}
            />,
          ]}
        />
      )}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      search={searchValue}
      searchFields="name"
      api={`/employees/all`}
      addOnFilter={addOnFilter}
      filter={filter}
      selection={{
        actions: [{title: 'Add Trainees', onPress: assignTrainees}],
      }}
      columns={[
        {
          header: 'Resource',
          render: RenderName,
        },
        {
          header: 'Email',
          field: 'official_email_id',
          type: 'text',
        },
        {
          header: 'Experience',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          render: CountCellRender,
          count_field: 'skills_count',
          header: 'Skills',
          align: 'right',
          width: 150,
          onPress: ({row: employeeData}) => {
            const skillIds = employeeData['skill_metrics'];
            navigation.navigate('employee-skills-table', {
              employeeData,
              addOnFilter: {
                _id: {$in: skillIds?.length ? skillIds : null},
              },
            });
          },
        },
        {
          header: 'Allocations',
          field: 'allocations',
          width: 150,
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          width: 150,
        },
      ]}
      {...props}
    />
  );
};
