import { Image, Linking, TouchableOpacity } from '@unthinkable/react-core-components';
import { getZeroTimeDate } from '@unthinkable/react-filters/src/DateUtility';
import { useTheme } from '@unthinkable/react-theme';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';
import { Durations } from '../../order/views/components/Durations';

const reimbursementComputations = fetch => ({
  'amount total in reimbursement': {
    compute: async value => {
      let totalAmount = 0;
      if (
        value?.reimbursement_type &&
        value.reimbursement_type == 'Regular' &&
        value.reimbursement_detail.length > 0
      ) {
        value.reimbursement_detail.forEach(size => {
          if (size.amount) {
            totalAmount += size.amount;
          }
        });
      } else if (value && value.reimbursement_type === 'Certificate') {
        totalAmount = value.certification_cost;
      }
      return {total_amount: totalAmount.toFixed(2)};
    },
    multi: true,
    dependencies: ['reimbursement_detail'],
  },
  nestedComputations: {
    reimbursement_detail: {
      rate: {
        compute: values => {
          if (
            values.category &&
            (values.category.name === 'Local Conveyance (Self Driven)' ||
              values.category.name === 'Local Conveyance (Taxi)')
          ) {
            if (values.vehicle_type === '2 Wheeler') {
              return 3;
            } else if (values.vehicle_type === '4 Wheeler') {
              return 7;
            }
          }
        },
        dependencies: ['_id', 'vehicle_type', 'category'],
      },
      amount: {
        compute: values => {
          if (
            values.category &&
            (values.category.name === 'Local Conveyance (Self Driven)' ||
              values.category.name === 'Local Conveyance (Taxi)')
          ) {
            // if (values.category && values.category.name === 'Regular Test') {
            if (values.no_of_kms && values.rate) {
              return (values.rate * values.no_of_kms).toFixed(2);
            }
          }
        },
        dependencies: ['_id', 'no_of_kms', 'rate', 'category'],
      },
    },
  },
});

const reimbursementTypeCheck = ({values = {}, type}) =>
  values.reimbursement_type == type;

const Reimbursement = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {reimbursement} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/reimbursements',
    eventSourceId: [
      'ReimbursementSelfNew',
      'ReimbursementSelfPending',
      'ReimbursementSelfReopen',
    ],
  });

  const {user = {}, resourceUrl, fetch} = useAppStateContext();
  const {employee} = user;
  const defaultValues = {
    employee,
    status: 'New',
    organization: employee?.organization?._id,
    reimbursement_type: 'Regular',
    approved_by: employee && employee.reporting_to && employee.reporting_to[0],
  };
  return (
    <Form
      api={`/reimbursement/${reimbursement}`}
      onSubmit={onSubmit}
      successMessage="Reimburesment Created Successfully"
      submitAction="Save"
      defaultValues={defaultValues}
      computations={{...reimbursementComputations(fetch)}}
      layoutFields={[
        // {
        //   label: 'Organization',
        //   field: 'organization',
        //   type: 'autoComplete',
        //   api: `/organizations`,
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        //   size: 6,
        // },
        {
          // type: 'autoComplete',
          type: 'text',
          field: 'reimbursement_type',
          label: 'Reimbursement Type',
          readOnly: true,
          // options: ['Regular', 'Certificate'],
          required: true,
          size: 6,
          onChangeValue: async (value, e, {setFieldValue}) => {
            const {data: employeeData} = await fetch({
              uri: `/employees/${employee?._id}`,
              props: {
                fields: {
                  reporting_to: {_id: 1, name: 1},
                  primary_practice: {_id: 1},
                },
              },
            });
            if (value == 'Regular') {
              setFieldValue('approved_by', employeeData?.reporting_to[0]);
            }
            if (value == 'Certificate') {
              if (employeeData?.primary_practice?._id) {
                const {data: practiceData} = await fetch({
                  uri: `/practices/${employeeData?.primary_practice?._id}`,
                  props: {
                    fields: {
                      owner: {_id: 1, name: 1},
                    },
                  },
                });

                setFieldValue('approved_by', practiceData?.owner || null);
              } else {
                setFieldValue('approved_by', void 0);
              }
            }
          },
        },
        {
          placeholder: 'Name',
          field: 'approved_by',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          label: 'Approver',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          readOnly: true,
          size: 6,
          // visible: ({values}) =>
          //   reimbursementTypeCheck({values, type: 'Certificate'}),
        },

        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          placeholder: 'From Date',
          required: true,
          size: 4,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          placeholder: 'To Date',
          required: true,
          size: 4,
        },
        // {
        //   type: 'number',
        //   field: 'total_amount',
        //   label: 'Total amt',
        // },
        {
          type: 'textArea',
          field: 'description',
          label: 'Description',
          required: true,
          size: 12,
        },
        {
          type: 'file',
          field: 'attachment',
          // required: true,
          size: 12,
          // visible: ({values}) =>
          //   reimbursementTypeCheck({values, type: 'Certificate'}),
        },
        //certificate
        {
          label: 'Reimbursement Details',
          visible: ({values}) =>
            reimbursementTypeCheck({values, type: 'Certificate'}),
          fields: [
            {
              placeholder: 'Name',
              field: 'proposed_by',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              label: 'Proposed By',
              secondarySuggestionField: 'official_email_id',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // visible: ({values}) =>
              //   reimbursementTypeCheck({values, type: 'Certificate'}),
            },
            {
              placeholder: 'Practice',
              field: 'practice',
              type: 'autoComplete',
              api: `/practices`,
              label: 'Practice',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // visible: ({values}) =>
              //   reimbursementTypeCheck({values, type: 'Certificate'}),
            },

            {
              placeholder: 'Name',
              field: 'certificate_name',
              type: 'autoComplete',
              api: `/reimbursementcategories`,
              label: 'Certificate ',
              suggestionField: 'name',
              filter: {type: 'Certificate'},
              valueField: 'name',
              size: 6,
              required: true,
              onCreate: () => {
                navigation.navigate(`add-reimbursement-category`, {...params});
              },
              // visible: ({values}) =>
              //   reimbursementTypeCheck({values, type: 'Certificate'}),
            },
            {
              field: 'certification_cost',
              type: 'number',
              label: 'Certificate Cost',
              placeholder: 'Amount',
              size: 6,
              required: true,
              // visible: ({values}) =>
              //   reimbursementTypeCheck({values, type: 'Certificate'}),
            },
            {
              type: 'file',
              field: 'attachment',
              required: true,
              size: 12,
              // visible: ({values}) =>
              //   reimbursementTypeCheck({values, type: 'Certificate'}),
            },
          ],
        },

        {
          label: 'Reimbursement Details',
          visible: ({values}) =>
            reimbursementTypeCheck({values, type: 'Regular'}),

          fields: [
            {
              field: 'reimbursement_detail',
              nested: true,
              addInBottom: true,

              render: props => (
                <NestedTable
                  {...props}
                  columns={[
                    {
                      field: 'category.name',
                      header: 'Category',
                      width: 200,
                    },
                    // {
                    //   field: 'from_date',
                    //   header: 'From Date',
                    //   type: 'date',
                    // },
                    // {
                    //   field: 'to_date',
                    //   header: 'To Date',
                    //   type: 'date',
                    // },
                    {
                      header: 'Duration',
                      align: 'center',
                      width: 200,

                      render: ({row}) => {
                        return Durations({
                          fromDate: row?.from_date,
                          toDate: row?.to_date,
                        });
                      },
                    },
                    {
                      field: 'quantity',
                      header: 'Quantity',
                      type: 'number',
                      width: 100,
                      align: 'center',
                    },
                    {
                      field: 'amount',
                      header: 'Amount',
                      type: 'number',
                      width: 150,
                      align: 'center',
                    },
                    {
                      field: 'vehicle_type',
                      header: 'Vehicle Type',
                      width: 150,
                    },
                    {
                      field: 'no_of_kms',
                      header: 'Distance(KM)',
                      width: 150,
                      type: 'number',
                      align: 'center',
                    },
                    {
                      field: 'rate',
                      header: 'Rate',
                      type: 'number',
                      width: 150,
                      align: 'center',
                    },

                    {
                      render: ({row}) => {
                        const {Reimbursement} = useTheme('icons');
                        const {detail_attachment} = row || {};
                        if (!detail_attachment) return null;

                        return (
                          <TouchableOpacity
                            onPress={() => {
                              const url = resourceUrl(detail_attachment);
                              Linking.openURL(url, '_blank');
                            }}>
                            <Image
                              source={Reimbursement}
                              width={20}
                              height={20}
                            />
                          </TouchableOpacity>
                        );
                      },
                    },
                  ]}
                  fields={[
                    {
                      placeholder: 'Name',
                      field: 'category',
                      type: 'autoComplete',
                      api: `/reimbursementcategories`,
                      label: 'Category ',
                      suggestionField: 'name',
                      filter: {type: 'Regular'},
                      valueField: 'name',
                      // size: 6,
                      required: true,
                    },
                    {
                      type: 'date',
                      field: 'from_date',
                      label: 'From Date',
                      placeholder: 'From Date',
                      required: true,
                      size: 6,
                    },
                    {
                      type: 'date',
                      field: 'to_date',
                      label: 'To Date',
                      placeholder: 'To Date',
                      required: true,
                      size: 6,
                    },
                    {
                      type: 'autoComplete',
                      field: 'vehicle_type',
                      label: 'Vehicle Type',
                      options: ['2 Wheeler', '4 Wheeler'],
                      // size: 4,
                    },
                    {
                      field: 'quantity',
                      type: 'number',
                      label: 'Quantity',
                      placeholder: 'Quantity',
                      size: 4,
                    },

                    {
                      field: 'no_of_kms',
                      type: 'number',
                      label: 'Distance(KM)',
                      placeholder: 'Distance',
                      size: 4,
                    },
                    {
                      field: 'rate',
                      type: 'number',
                      label: 'Rate',
                      placeholder: 'Rate',
                      size: 4,
                    },
                    {
                      field: 'amount',
                      type: 'number',
                      label: 'Amount',
                      placeholder: 'Amount',
                      size: 6,
                    },
                    {
                      field: 'purpose',
                      type: 'textArea',
                      label: 'Purpose',
                      placeholder: 'Purpose',
                      size: 6,
                    },
                    {
                      type: 'file',
                      field: 'detail_attachment',
                      label: 'Document',
                      required: true,
                      size: 12,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const RemarkReimbursement = props => {
  const {
    route: {params},
  } = props;
  const {reimbursement} = params;
  const {user = {}} = useAppStateContext();
  const {employee} = user;

  const {onSubmit} = useFormSubmit({
    uri: '/reimbursements',
    eventSourceId: [
      'ReimbursementAccountApproved',
      'ReimbursementAccountReopen',
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
    ],
  });

  return (
    <Form
      header="Re-open Request"
      mode="edit"
      api={`/reimbursement/${reimbursement}`}
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'ReOpen', reOpen_by: employee?._id}};
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'textArea',
          field: 'remark',
          label: 'Remark',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const RejectReimbursementForm = props => {
  const {
    route: {params},
  } = props;
  const {reimbursement} = params;
  const {user = {}} = useAppStateContext();
  const {employee} = user;

  const {onSubmit} = useFormSubmit({
    uri: '/reimbursements',
    eventSourceId: [
      'ReimbursementAccountApproved',
      'ReimbursementAccountReopen',
      'ReimbursementAccountReject',
      'ReimbursementTeamReferred',
    ],
  });

  return (
    <Form
      header="Reject Request"
      mode="edit"
      api={`/reimbursement/${reimbursement}`}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_on: getZeroTimeDate(new Date()),
            rejected_by: employee?._id,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'textArea',
          field: 'reject_reason',
          // label: 'Reject',
          placeholder: 'Reason',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
export const EditReimbursementRequest = props => {
  return <Reimbursement mode="edit" header="Update Reimbursement" {...props} />;
};
export const AddReimbursementRequest = props => {
  return <Reimbursement header="Request Reimbursement" {...props} />;
};

export const NonEditableReimbursementRequest = props => {
  return (
    <Reimbursement
      readOnly={true}
      mode="edit"
      header="Update Reimbursement"
      {...props}
    />
  );
};

export const EditableReopenReimbursementRequest = props => {
  return (
    <Reimbursement
      mode="edit"
      header="Update Reimbursement"
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'Approved'}};
      }}
      {...props}
    />
  );
};
