import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ProjectTaskAssignmentTeamForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: ['MasterTaskAssignment'],
    uri: '/mastertaskassignments',
    ...props,
  });
  return (
    <Form
      header={{title: 'Team', secondaryTitle: row?.task}}
      submitAction="Save"
      defaultValues={{_id: row._id, team: row?.team}}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Select Team',
          field: 'team',
          type: 'multiAutoComplete',
          api: `/projects/${params?.project?._id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const ProjectTaskAssignmentReviewerForm = props => {
  const {
    route: {params},
  } = props;
  const {row, project} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: ['MasterTaskAssignment'],
    uri: '/mastertaskassignments',
    ...props,
  });
  return (
    <Form
      header={{title: 'Reviewers', secondaryTitle: row?.task}}
      submitAction="Save"
      defaultValues={{_id: row._id, reviewer: row?.reviewer}}
      onSubmit={onSubmit}
      layoutFields={[
        {
          type: 'multiAutoComplete',
          field: 'reviewer',
          api: `/projects/${project?._id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          colorField: 'color',
          valueField: 'name',
          avatar: true,
          label: 'Reviewer',
        },
      ]}
      {...props}
    />
  );
};

