import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {RecursiveRender} from '@unthinkable/react-table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {RecursiveTable} from '../../../components/table/Table';
import {TextRenderer} from '../../../components/form/Renderers';
import {SearchFilter} from '../../../components/filter/Filters';
import CountCellRender from '../../../app-components/renders/CountCellRender';

export const RenderTool = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value=" + Skill"
      style={{...rowText, color: colors['ACCENT1_HIGH']}}
    />
  );
};

const ToolRecusiveTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {onChangeFilter, searchValue} = useFilter({});
  const deleteTool = useInvoke({
    method: 'delete',
    eventSourceId: ['Tools'],
  });

  return (
    <RecursiveTable
      api={`/toolsWithChildren`}
      eventSourceId={['Tools','StudyMaterials']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Skill Matrix"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <AddButton title="Skills" view="add-tools" params={params} />,
            ]}
          />
        );
      }}
      addOnFilter={{
        parent: {$eq: null},
      }}
      fields={{
        name: 1,
        parent: 1,
        practice: {name: 1},
        training_material_count: {
          _id: 1,
        },
      }}
      search={searchValue}
      searchFields="name"
      params={params}
      columns={[
        {
          field: 'name',
          header: 'Skill',
          render: RecursiveRender,
          indent: false,
        },
        {
          render: CountCellRender,
          header: 'Study Material',
          align: 'right',
          count_field: 'training_material_count',
          responsive: 'sm',
          width: 120,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`training-material-inline-list`, {
              ...params,
              tool: {
                _id: row?._id,
              },
              label: row?.name,
            });
          },
          onPress: props => {
            const {row} = props;
            navigation.navigate(`training-material-inline-list`, {
              ...params,
              tool: {
                _id: row?._id,
              },
              label: row?.name,
            });
          },
        },
        {
          render: RenderTool,
          onPress: ({row}) => {
            navigation.navigate('add-tools', {
              parent: row,
              practice: row.practice,
            });
          },
          align: 'right',
          responsive: 'sm',
          width: 100,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-tools', {row});
          },
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteTool({
              uri: '/tools/' + row?._id,
            });
          },
        },
      ]}
    />
  );
};

export default ToolRecusiveTable;
