import React from 'react';
import {NavHeader} from '../../../components/nav/NavHeader';
import {Table} from '../../../components/table/Table';
import {STATUS} from './TargetAudienceTable';

export const PaidAdCampaignHeader = props => {
  const {
    route: {params},
  } = props;
  return (
    <NavHeader
      title="Paid Ads"
      {...props}
      // actions={[
      //   <AddButton
      //     title="Paid Ad"
      //     view="add-paidadcampaign"
      //     params={props?.route?.params}
      //   />,
      // ]}
    />
  );
};

export const PaidAdCampaignTable = props => {
  const {
    route: {params},
    navigation,
    searchValue,
    filterParams = {},
    tabFilter: {status = ''} = {},
  } = props;
  const {period} = filterParams;
  const {product} = params;
  return (
    <Table
      api={`/product/${product?._id}/paid-ads`}
      params={{
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      eventSourceId="paidAdCampaign"
      search={searchValue}
      searchFields={'name'}
      addOnFilter={{status: status}}
      columns={[
        {
          type: 'date',
          field: 'date',
          header: 'Date',
        },
        {
          type: 'text',
          field: 'name',
          header: 'Ad Name',
        },
        {
          type: 'text',
          field: 'adId',
          header: 'Ad Id',
        },
        {
          type: 'number',
          field: 'est_hours',
          header: 'Hours',
          align: 'center',
          width: 150,
        },
        {
          field: 'owner',
          header: 'Owner',
          type: 'userAvatar',
          width: 200,
        },
        {
          field: 'cpl',
          type: 'number',
          header: 'CPL',
          visible: () => status == STATUS.CONFIRMED,
          align: 'center',
        },
        {
          field: 'cur',
          type: 'number',
          header: 'CUR',
          visible: () => status == STATUS.CONFIRMED,
          align: 'center',
        },
        {
          field: 'ctr',
          type: 'number',
          header: 'CTR',
          visible: () => status == STATUS.CONFIRMED,
          align: 'center',
        },
        {
          field: 'cpm',
          type: 'number',
          header: 'CPM',
          visible: () => status == STATUS.CONFIRMED,
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-paid-ad', {...params, row});
          },
        },
        {
          title: 'Confirm',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Confirmed Date',
              header: 'Confirmed Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['paidAdCampaign'],
              field: 'confirmed_on',
              status: STATUS.CONFIRMED,
            });
          },
          visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['paidAdCampaign'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        // {
        //   title: 'Fail',
        //   onPress: ({row}) => {
        //     navigation.navigate('dialogue-box-for-date', {
        //       label: 'Failed Date',
        //       header: 'Failed Date',
        //       uri: `/campaigns/${row?._id}`,
        //       eventSourceId: ['paidAdCampaign'],
        //       field: 'failed_on',
        //       status: STATUS.FAILED,
        //     });
        //   },
        //   visible: ({row}) => row?.status != STATUS.FAILED,
        // },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['paidAdCampaign'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/campaigns`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        // {
        //   title: 'Show Tasks',
        //   onPress: showTasks,
        //   visible: ({row}) => row?.status != STATUS.FAILED,
        // },
      ]}
    />
  );
};
