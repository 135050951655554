import { getPreviousMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { EmailCampaignTable } from './EmailCampaignTable';

export const EmailCampaignTabs = props => {
  const {
    route: {params},
  } = props;
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams} = filterValues || {};
  const {period} = filterParams;
  const tabs = {
    confirmed: {
      label: 'Confirmed',
      view: (
        <EmailCampaignTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          tabFilter={{
            status: 'Confirmed',
            $and: [
              {
                confirmed_on: {$lte: period?.to},
              },
              {
                $or: [{retired_on: {$gte: period?.to}}, {retired_on: null}],
              },
            ],
          }}
        />
      ),
    },
    inexperiment: {
      label: 'In Experiment',
      view: (
        <EmailCampaignTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          tabFilter={{
            status: 'In Experiment',
            $and: [
              {
                experiment_on: {$lte: period?.to},
              },
              {
                $or: [{confirmed_on: {$gte: period?.to}}, {confirmed_on: null}],
              },
              {
                $or: [{failed_on: {$gte: period?.to}}, {failed_on: null}],
              },
            ],
          }}
        />
      ),
    },
    retired: {
      label: 'Retired',
      view: (
        <EmailCampaignTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          tabFilter={{status: 'Retired', retired_on: {$lte: period.to}}}
        />
      ),
    },
    failed: {
      label: 'Failed',
      view: (
        <EmailCampaignTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          tabFilter={{status: 'Failed', failed_on: {$lte: period.to}}}
        />
      ),
    },
  };

  const actions = [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search"
    />,
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        {
          type: 'autoComplete',
          label: 'Owner',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'assigned_to',
        },
        {
          type: 'autoComplete',
          label: 'Target Audience',
          api: '/buyerPersonas',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'target_audience',
        },
        {
          type: 'autoComplete',
          label: 'Is Nurturable',
          options: [
            {label: 'True', value: 'true'},
            {label: 'False', value: 'false'},
          ],
          placeholder: 'Select',
          field: 'is_nurtuable',
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
      ]}
    />,
  ];
  return <TabView {...props} tabs={tabs} actions={actions} />;
};
