import {singular} from 'pluralize';
import {screenPaths} from '../../routes';

export const getEntities = (menus, parentPath = []) => {
  if (!menus?.length || !parentPath?.length) return menus;
  const [firstPart, ...remainingPath] = parentPath;
  for (const item of menus) {
    if (!item) {
      continue;
    }
    let result = void 0;
    if (Array.isArray(item)) {
      result = getEntities(item, parentPath);
    } else if (item.menus?.length) {
      result = getEntities(item.menus, parentPath);
    } else if (item.menuItems?.length) {
      if (item.entityKey === firstPart || item.id === firstPart) {
        result = getEntities(item.menuItems, remainingPath);
      }
    }
    if (result?.length) {
      return result;
    }
  }
  return [];
};

export const fetchRequiredFieldData = async ({route, fetch, navigation}) => {
  let newParams = {};
  let idPattern = /^[a-f0-9]{24}$/;
  let routePathArr = route.path.split('/');
  let idIndex;
  let _id;
  routePathArr.forEach((item, index) => {
    if (idPattern.test(item)) {
      idIndex = index;
      _id = item;
    }
  });
  let {parentTabApiKey} = route.params || {};
  if (_id) {
    let parentItemId = parentTabApiKey
      ? routePathArr[idIndex - 2]
      : routePathArr[idIndex - 1];
    let parentItems = [parentItemId];
    newParams.parentItems = parentItems;
    newParams.breadcrumb = {
      dropdown: {
        field: singular(parentItemId),
      },
    };
    let data;
    if (parentItemId) {
      if (parentTabApiKey && parentTabApiKey !== 'undefined') {
        let result = await fetch({
          uri: `/${parentItemId}/${parentTabApiKey}`,
          props: {
            addOnFilter: {
              _id,
            },
          },
        });
        data = result?.data || [];
      } else {
        let result = await fetch({
          uri: `/${parentItemId}`,
          props: {
            addOnFilter: {
              _id,
            },
          },
        });
        data = result?.data || [];
      }
    }
    if (Array.isArray(data)) {
      data = data[0];
    }
    if (data) {
      newParams[singular(parentItemId)] = {...data};
    }
  }
  if (screenPaths[route.name]?.pathParams) {
    let newPathParams = await screenPaths[route.name].pathParams({
      route,
      fetch,
      newParams,
    });
    newParams = {...newParams, ...newPathParams};
  }

  navigation.setParams(newParams);
};

export const getSelectedMenu = (menus, checkDefault) => {
  for (let menu of menus) {
    if (!menu) {
      continue;
    }
    if (menu.section) {
      let selectedMenu = getSelectedMenu(menu.menus, checkDefault);
      if (selectedMenu) {
        return {menu: selectedMenu, section: menu};
      }
    }
    if (Array.isArray(menu)) {
      let selectedMenu = getSelectedMenu(menu, checkDefault);
      if (selectedMenu) {
        return selectedMenu;
      }
    } else if (menu.view && (!checkDefault || menu.defaultSelected)) {
      return menu;
    }
  }
};

export const getScreenId = ({screens, itemId}) => {
  let screenId;
  screens.forEach(screen => {
    if (screen.menuItemId === itemId) {
      if (!(Array.isArray(screen?.views) || screen?.views?.length)) {
        screenId = void 0;
      }
      let view = screen?.views[0];

      if (Array.isArray(view) && view?.length) {
        // in case of grouped views
        screenId = view[0]?.id;
      } else if (
        view?.section &&
        Array.isArray(view?.menus) &&
        view?.menus?.length
      ) {
        // in case of section
        screenId = view.menus[0]?.id;
      } else {
        screenId = view?.id;
      }
    }
  });
  return screenId;
};

export const filterMenus = (menus, routeParams, user) => {
  if (!menus?.length) {
    return [];
  }
  const isVisible = ({menu}) => {
    let {visible = true} = menu || {};
    if (typeof visible === 'function') {
      visible = visible({
        params: routeParams,
        user,
      });
    }
    return visible;
  };

  return menus?.reduce((acc, menu) => {
    if (Array.isArray(menu)) {
      const arrayMenus = menu.filter(_menu => isVisible({menu: _menu}));
      if (arrayMenus.length > 0) {
        acc.push(arrayMenus);
      }
    } else if (menu.section) {
      const sectionMenus = menu?.menus?.filter(_menu =>
        isVisible({menu: _menu}),
      );
      menu.menus = sectionMenus;
      acc.push(menu);
    } else if (isVisible({menu})) {
      acc.push(menu);
    }
    return acc;
  }, []);
};

export const filterScreens = (screens, routeParams, user) => {
  if (!screens?.length) {
    return [];
  }
  const isVisible = ({screen}) => {
    let {visible = true} = screen || {};
    if (typeof visible === 'function') {
      visible = visible({
        params: routeParams,
        user,
      });
    }

    return visible;
  };

  let updatedScreens = [];

  screens.forEach(screen => {
    let screenViews = screen?.views;
    if (typeof screenViews === 'function') {
      screenViews = screenViews({user});
    }

    let views = screenViews?.reduce((acc, view) => {
      if (Array.isArray(view)) {
        const arrayScreens = view.filter(_view => isVisible({screen: _view}));
        if (arrayScreens.length > 0) {
          acc.push(arrayScreens);
        }
      } else if (view.section) {
        const sectionMenus = view?.menus?.filter(_menu =>
          isVisible({screen: _menu}),
        );
        view.menus = sectionMenus;
        acc.push(view);
      } else if (isVisible({screen: view})) {
        acc.push(view);
      }
      return acc;
    }, []);
    updatedScreens.push({...screen, views});
  });
  return updatedScreens;
};

export const findMenuItem = (menus, itemId) => {
  for (const menu of menus) {
    if (Array.isArray(menu)) {
      const result = findMenuItem(menu, itemId);
      if (result) {
        return result;
      }
    }
    if (menu.section && Array.isArray(menu.menus) && menu.menus.length) {
      const result = findMenuItem(menu.menus, itemId);
      if (result) {
        return result;
      }
    }
    if (Array.isArray(menu.menuItems)) {
      const result = findMenuItem(menu.menuItems, itemId);
      if (result) {
        return result;
      }
    }
    if (menu.id === itemId) {
      return menu;
    }
  }
  return null;
};

export const findScreen = (screens, screenId) => {
  for (const screen of screens) {
    if (Array.isArray(screen)) {
      const result = findScreen(screen, screenId);
      if (result) {
        return result;
      }
    } else if (screen.section) {
      const result = findScreen(screen.menus, screenId);
      if (result) {
        return result;
      }
    } else if (screen.id === screenId) {
      return screen;
    }
  }
  return null;
};

export const findMenuId = (menus, params, user) => {
  if (!(Array.isArray(menus) && menus.length)) {
    return null;
  }

  let findDefaultSelectedMenu = menus => {
    let selectedMenu = void 0;
    for (const menu of menus) {
      let {defaultSelected = false} = menu;
      if (typeof defaultSelected === 'function') {
        defaultSelected = defaultSelected({params, user});
      }
      if (Array.isArray(menu)) {
        selectedMenu = findDefaultSelectedMenu(menu);
      } else if (Array.isArray(menu.menus) && menu.menus.length) {
        selectedMenu = findDefaultSelectedMenu(menu.menus);
      } else if (defaultSelected) {
        selectedMenu = {itemId: menu.id, entityKey: menu.entityKey};
      }
      if (selectedMenu) {
        break;
      }
    }
    return selectedMenu;
  };

  let result = findDefaultSelectedMenu(menus);
  if (result) {
    return result;
  }

  let initialMenu = menus[0];
  if (Array.isArray(initialMenu)) {
    return findMenuId(initialMenu);
  } else if (Array.isArray(initialMenu.menus) && initialMenu.menus.length) {
    return findMenuId(initialMenu.menus);
  } else {
    return {itemId: initialMenu.id, entityKey: initialMenu.entityKey};
  }
};

export const updateEntities = (entities, screens) => {
  const updateEntity = (entity, screens) => {
    if (!entity?.showSingleView) {
      screens.forEach(screen => {
        if (screen.menuItemId === entity?.id) {
          let views = screen?.views;

          if (typeof views === 'function') {
            views = views({user});
          }
          if (
            views?.length === 1 &&
            !Array.isArray(views?.[0]) && // view is not a group
            !views[0]?.section
          ) {
            const {label, id, view, ...rest} = views[0];
            Object.keys(rest).forEach(key => {
              entity[key] = rest[key];
            });
          }
        }
      });
    }

    return entity;
  };
  return entities.map(entity => {
    if (Array.isArray(entity)) {
      return entity.map(item => updateEntity(item, screens));
    } else {
      return updateEntity(entity, screens);
    }
  });
};

export const removeRouteParams = props => {
  let {route: {params: routeParams} = {}, params} = props;
  params = params || routeParams;
  if (!params || !Object.keys(params)?.length) {
    return params;
  }
  const {
    appModule,
    appModule_id,
    breadcrumb,
    parentItems,
    parentItemId,
    parentScreenId,
    parentTabId,
    parentTabApiKey,
    itemId,
    entityKey,
    screenId,
    tabId,
    tabApiKey,
    ...restParams
  } = params;

  return restParams;
};

export const getSelectedSectionMenu = ({menu, routeParams}) => {
  if (!menu) {
    return;
  }
  const {section, menus} = menu;
  if (section && Array.isArray(menus) && menus?.length) {
    for (let _menu of menus) {
      if (_menu?.id === routeParams?.itemId) {
        return menu;
      }
    }
  }
};
