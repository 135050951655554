export const CommentStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    ReplyIcon: icons.Reply,
    section: {
      container: {
        marginBottom: 16,
        gap: 8,
      },
      headerContainer: {},
      commentContainer: {
        marginLeft: 36,
      },
      richTextContainer: {
        backgroundColor: colors.BACKGROUND,
        borderRadius: 6,
      },
    },
    actions: {
      justifyContent: 'flex-end',
    },
    cancelButton: {
      container: {
        backgroundColor: 'transparent',
      },
      text: {
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    commentBox: {
      textContainer: {
        height: 56,
        borderWidth: 1,
        borderRadius: 6,
        borderColor: colors.OUTLINE,
        padding: 12,
        backgroundColor: colors.BACKGROUND,
        justifyContent: 'center',
      },
      text: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    commenter: {
      container: {
        alignItems: 'center',
        flex: 1,
        gap: 8,
      },
      text: {
        fontSize: 14,
        fontFamily: 'Inter-Medium',
        color: colors.NEUTRAL_HIGH,
        flex: 1,
      },
    },
    date_time: {
      fontSize: 12,
      fontFamily: 'Inter-Regular',
      color: colors.NEUTRAL_MEDIUM,
    },
    separator: {
      height: 4,
      width: 4,
      backgroundColor: colors.OUTLINE,
      borderRadius: 4,
    },
  };
};
