import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {
  AddButton,
  BorderLessButton,
} from '../../../components/button/AddButton';
import {REPOSITORY_CODE_SAMPLE_TYPE_ID} from '../../common/constants/SourceConstants';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

export const RepositoryCodeSamplesTable = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;

  const {project, repository_id} = params;
  const {icons} = useTheme();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'CodeSample',
  });

  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'CodeSample',
  });

  return (
    <Table
      api={`/repositoryCodeSamples`}
      filter={{
        project_id: project?._id,
        repository_id,
      }}
      sort={{createdAt: 1}}
      eventSourceId={['CodeSample', 'DataImport']}
      selection={{
        actions: [
          {
            title: 'Delete',
            variant: 'error',
            confirm: {
              title: 'Delete',
              message: 'Are you sure you want to Delete?',
              confirmText: 'Delete',
            },
            onPress: ({selectedIds}) => {
              postInvoke({
                uri: `/repositoryCodeSamples/batchRemove`,
                props: {selectedIds},
              });
            },
          },
        ],
      }}
      fields={{
        project_id: 1,
        repository_id: 1,
        name: 1,
        type: 1,
        template: 1,
        example: 1,
        description: 1,
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <BorderLessButton
              onPress={() => {
                navigation.navigate('project-import-data', {
                  ...params,
                  source: REPOSITORY_CODE_SAMPLE_TYPE_ID,
                });
              }}
              text={'Import'}
              icon={icons.DownloadBlue}
            />,
            <AddButton
              title="Sample"
              view="add-code-architecture-form"
              params={params}
            />,
          ]}
        />
      )}
      onRowPress={({row}) => {
        navigation.navigate('edit-code-architecture-form', {
          ...params,
          codeSampleId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          width: 150,
        },
        {
          header: 'Type',
          type: 'type',
          field: 'type',
          width: 150,
        },
        {
          header: 'Template',
          type: 'text',
          field: 'template',
          width: 300,
        },
        {
          header: 'Example',
          type: 'text',
          field: 'example',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/repositoryCodeSamples/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
