import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {useFetchData} from '../../../controllers/useFetchData';
import {CountButton} from '../../../app-components/renders/countButton/CountButton';

export const ObjectWiseResources = props => {
  const {navigation, object, params} = props;
  const {product, period} = params;
  const icons = useTheme('icons');
  const resourceIcon = icons['Resources'];
  const {data: employeeAssignments} = useFetchData({
    api: '/employeeAssignments',
    filter: {
      product: product?._id,
      object: object?._id,
      from_date: {$lte: period.from},
      $or: [
        {to_date: {$exists: false}},
        {to_date: {$eq: null}},
        {to_date: {$gte: period.to}},
      ],
    },
    count: true,
  });
  const count = employeeAssignments?.count || 0;
  const onPress = () => {
    navigation.navigate('employee-allocation', {
      addOnFilter: {
        product: product?._id,
        object: object?._id,
        from_date: {$lte: period.from},
        $or: [
          {to_date: {$exists: false}},
          {to_date: {$eq: null}},
          {to_date: {$gte: period.to}},
        ],
      },
    });
  };
  return (
    <CountButton
      onPress={onPress}
      icon={resourceIcon}
      count={count}
      {...props}
    />
  );
};
