import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ProjectMasterTaskAssignmentForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/mastertaskassignments',
    eventSourceId: ['MasterTaskAssignment'],
    ...props,
  });
  const {
    route: {params},
  } = props;

  // Akshat Garg - 21/03/24 - extracting the project_id from the params, then passing it on to newly created api of /mastertaskassignments/${row?._id}/formData, which modifies the data to be showed if front face resource exists
  const {row, project_id} = params;

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      api={`/mastertaskassignments/${row?._id}/formData`}
      params={{project_id}}
      fields={{
        team: {
          name: 1,
          color: 1,
        },
        reviewer: {
          name: 1,
          color: 1,
        },
        est_hours: 1,
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Select Team',
          field: 'team',
          type: 'multiAutoComplete',
          api: `/projects/${project_id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
        },
        {
          type: 'multiAutoComplete',
          field: 'reviewer',
          api: `/projects/${project_id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
          label: 'Reviewers',
        },
      ]}
      {...props}
    />
  );
};

export const EditPmtMasterTaskAssignmentForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <ProjectMasterTaskAssignmentForm
      header={() => {
        return {
          title: `Edit Task Assignment`,
          secondaryTitle: `${params?.row?.task}`,
        };
      }}
      mode="edit"
      {...props}
    />
  );
};
