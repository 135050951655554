export const UploadButtonStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    uploadIcon: icons.Upload,
    buttonContainer: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 8,
      padding: 8,
    },
  };
};
