import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AnchorResourceActionForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'AnchorResourceAction',
    ...props,
  });

  return (
    <Form
      api={`/employees/${params.row._id}`}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      header="Actions"
      fields={{
        actions_date: 1,
        actions_description: 1,
        action_taken_by: {_id: 1, name: 1},
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'actions_date',
          placeholder: 'Action Date',
          label: 'Action Date',
          required: true,
        },
        {
          type: 'text',
          field: 'actions_description',
          placeholder: 'Action',
          label: 'Action',
          required: true,
        },
        {
          placeholder: 'Action Taken By',
          label: 'Action Taken By',
          field: 'action_taken_by',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
