import React from 'react';
import ReactPlayer from 'react-player';

export const VideoPlayer = ({videoUrl}) => {
  return (
    <ReactPlayer
      url={videoUrl}
      controls
      width={'100%'}
      height={'auto'}
      style={{flex: 1}}
    />
  );
};
