import React from 'react';
import {MultiChip as ReactMultiChipComponent} from '@unthinkable/react-chip';
import {MultiAvatarChip as MultiAvatarChipComponent} from '@unthinkable/react-chip';
import {useStyles} from '@unthinkable/react-theme';
import {AvatarChipStyles, ChipStyles, TagStyles} from './theme';
import {useAccentColor} from '../../controllers/useAccentColor';

const useChipColor = ({
  color: colorProp,
  textColor: textColorProp,
  displayTextColor,
}) => {
  const {color, LOW, HIGHEST} = useAccentColor(colorProp);
  const {color: textColor} = useAccentColor(textColorProp);
  if (color) {
    return {color, textColor};
  }
  return {color: LOW, textColor: HIGHEST};
};

export const MultiChipComponent = ({
  color,
  textColor,
  displayTextColor,
  ...props
}) => {
  const chipColorProps = useChipColor({color, textColor, displayTextColor});
  return <ReactMultiChipComponent {...props} chip={chipColorProps} />;
};

export const MultiChip = props => {
  const styles = useStyles(ChipStyles);
  return <MultiChipComponent {...props} styles={styles} />;
};

export const MultiTag = props => {
  const styles = useStyles(TagStyles);
  return <MultiChipComponent {...props} styles={styles} />;
};

export const MultiAvatarChip = props => {
  const styles = useStyles(AvatarChipStyles);
  return <MultiAvatarChipComponent {...props} styles={styles} />;
};
