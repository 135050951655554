import React, {forwardRef, memo} from 'react';
import {Wrap} from '@unthinkable/react-core-components';
import Chip from './Chip';
import {RemainingValueText} from './Styles';

export const MultiChip = forwardRef(
  ({children, styles, values, maxChips = 1, gap = 4, maxWidth, chip}, ref) => {
    if (values) {
      if (!Array.isArray(values)) {
        values = [values];
      }
    } else if (children) {
      values = React.Children.toArray(children);
    }
    values = values ? values.filter(value => !!value) : values;
    if (!values || !values.length) {
      return null;
    }
    let remainingValues = [];
    if (maxChips && maxChips < values?.length) {
      remainingValues = values.slice(maxChips);
      values = values.slice(0, maxChips);
    }

    let chips = values.map((_value, index) => {
      const chipProps = {
        ...chip,
        key: index,
      };
      if (maxWidth) {
        chipProps.maxWidth = maxWidth;
      }
      if (React.isValidElement(_value)) {
        return React.cloneElement(_value, {
          ...chipProps,
          ..._value.props,
        });
      } else {
        _value = typeof _value === 'string' ? {value: _value} : _value;
        return <Chip styles={styles} {...chipProps} {..._value} />;
      }
    });
    remainingValues?.forEach((_remainingValue, index) => {
      if (React.isValidElement(_remainingValue)) {
        remainingValues[index] = _remainingValue?.props?.value;
      }
    });
    return (
      <Wrap style={{alignItems: 'center'}} gap={gap} ref={ref}>
        {chips}
        {remainingValues?.length ? (
          <RemainingValueText
            title={remainingValues}
            styles={styles}>{`+ ${remainingValues.length}`}</RemainingValueText>
        ) : (
          void 0
        )}
      </Wrap>
    );
  },
);

export default memo(MultiChip);
