import React from 'react';
import {FontView} from './FeatureFontTheme';
import {useFetchData} from '../../../controllers/useFetchData';

export const ProjectThemeFontTable = props => {
  const {
    route: {params},
    version,
  } = props;

  const {projectlibrary_id, integratedProject, project} = params;
  const {deviceTypes, primaryDeviceType} = project;

  const {data} = useFetchData({
    api: `/projectthemes`,
    filter: {
      library_id: projectlibrary_id,
      library_version_id: version
        ? version?._id
        : integratedProject
        ? integratedProject.library_version_id
        : null,
    },
    eventSourceId: ['fonts', 'theme'],
  });
  return (
    <FontView
      deviceTypes={deviceTypes}
      primaryDeviceType={primaryDeviceType}
      readOnly
      fonts={data?.[0]?.fonts}
    />
  );
};
