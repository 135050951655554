import React from 'react';
import {Row, ScrollView} from '@unthinkable/react-core-components';
import {ColorsView, getLightAndDarkColors} from './FeatureColorTheme';
import {useFetchData} from '../../../controllers/useFetchData';

export const ProjectThemeColorTable = props => {
  const {
    route: {params},
    version,
  } = props;

  const {projectlibrary_id, integratedProject} = params;
  const {data} = useFetchData({
    api: `/projectthemes`,
    filter: {
      library_id: projectlibrary_id,
      library_version_id: version
        ? version?._id
        : integratedProject
        ? integratedProject.library_version_id
        : null,
    },
    eventSourceId: ['colors', 'theme'],
  });

  let row = data?.[0];

  const {lightColors, darkColors} = getLightAndDarkColors({
    colors: row?.colors,
  });

  return (
    <ScrollView>
      <Row gap={20}>
        <ColorsView
          readOnly
          colors={lightColors}
          row={row}
          colorMode="light"
          header="Light Mode"
        />
        <ColorsView
          readOnly
          colors={darkColors}
          row={row}
          colorMode="dark"
          header="Dark Mode"
        />
      </Row>
    </ScrollView>
  );
};
