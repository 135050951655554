import React from 'react';
import {
  MyAttendanceRequestTab,
  MyAttendanceTab,
  MyEWDRequestTab,
  MyLeaveRequestTab,
  MyTimesheetRequestTab,
  MyWFHRequestTab,
  OfficeTrackingReportTabs,
} from '../department/views/EmployeeAttendanceTab';
import {TeamTimesheetRequestTab} from '../department/views/TeamTimesheetRequestTabs';
import {
  MenteeAttendanceTab,
  TeamAttendanceTab,
} from '../department/views/TeamAttendanceTab';
import {TeamRequestTab} from '../department/views/TeamRequestTab';
import {TeamWFHRequestTab} from '../department/views/TeamWfhRequestTabs';
import {
  TeamAttendanceRequestTab,
  TeamEWDRequestTab,
} from '../department/views/TeamAttendanceRequestTabs';
import {
  AttendanceChangeRequestHrTabs,
  EWDRequestHrTabs,
  LeaveRequestHrTabs,
  TimesheetRequestHrTabs,
  WFHRequestHrTabs,
} from './views/EmployeeSetupTabs';
import {AttendanceTab} from '../leadGeneration/views/attendance-views/AttendanceTab';
import {
  FreezeRangeTab,
  FreezeRangeTable,
} from '../common/views/FreezeRangeTable';
import {AttendanceTabView} from '../leadGeneration/views/AttendanceTabView';
import {LeaveRegisterTable} from './views/LeaveRegisterTable';
import {MonthlyAttendanceTable} from './views/AttendanceSalaryTable';
import {OfficeTimeTrackingReport} from '../leadGeneration/views/EmployeeOfficeTrackingReport';
import {TeamOfficeTrackingReport} from '../leadGeneration/views/TeamOfficeTrackingReport';

export const ScreenMapping = [
  {
    id: 'selfoverviewattendance',
    view: ({user}) => {
      return <MyAttendanceTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'selfleaverequest',
    view: ({user}) => {
      return <MyLeaveRequestTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'selfewdrequest',
    view: ({user}) => {
      return <MyEWDRequestTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'selfwfhrequest',
    view: ({user}) => {
      return <MyWFHRequestTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'selftimesheetrequest',
    view: ({user}) => {
      return <MyTimesheetRequestTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'selfattendancechangerequest',
    view: ({user}) => {
      return <MyAttendanceRequestTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'teammonthlyattendance',
    view: ({user}) => {
      return <TeamAttendanceTab tab="project" user={user} />;
    },
  },
  {
    id: 'projectteammonthlyattendance',
    view: ({user}) => {
      return <TeamAttendanceTab tab="primary" user={user} />;
    },
  },
  {
    id: 'menteemonthlyattendance',
    view: ({user}) => {
      return <MenteeAttendanceTab user={user} />;
    },
  },
  {
    id: 'teamleaverequest',
    view: ({user}) => {
      return <TeamRequestTab user={user} />;
    },
  },
  {
    id: 'teamewdequest',
    view: ({user}) => {
      return <TeamEWDRequestTab user={user} />;
    },
  },
  {
    id: 'attendencechangerequest',
    view: ({user}) => {
      return <TeamAttendanceRequestTab user={user} />;
    },
  },
  {
    id: 'teamtimesheetrequest',
    view: ({user}) => {
      return <TeamTimesheetRequestTab user={user} />;
    },
  },
  {
    id: 'wfhrequest',
    view: ({user}) => {
      return <TeamWFHRequestTab user={user} />;
    },
  },
  {
    id: 'monthlyattendenceforhr',
    view: ({user}) => {
      return <AttendanceTab user={user} />;
    },
  },
  {
    id: 'leaverequestforhr',
    view: ({user}) => {
      return <LeaveRequestHrTabs user={user} />;
    },
  },
  {
    id: 'wfhrequestforhr',
    view: ({user}) => {
      return <WFHRequestHrTabs user={user} />;
    },
  },
  {
    id: 'ewdrequestforhr',
    view: ({user}) => {
      return <EWDRequestHrTabs user={user} />;
    },
  },
  {
    id: 'attendencechangerequestforhr',
    view: ({user}) => {
      return <AttendanceChangeRequestHrTabs user={user} />;
    },
  },
  {
    id: 'timesheetrequestforhr',
    view: ({user}) => {
      return <TimesheetRequestHrTabs user={user} />;
    },
  },
  {
    id: 'attendenceforsetup',
    view: ({user}) => {
      return <AttendanceTabView user={user} />;
    },
  },
  {
    id: 'leaveregister',
    view: ({user}) => {
      return <LeaveRegisterTable user={user} />;
    },
  },
  {
    id: 'freezesalaryrange',
    view: ({user}) => {
      return <FreezeRangeTab user={user} />;
    },
  },
  {
    id: 'officetrackingreport',
    view: ({user}) => {
      return <OfficeTimeTrackingReport user={user} />;
    },
  },
  {
    id: 'teamOfficeTimeTrackingReport',
    view: ({user}) => {
      return <TeamOfficeTrackingReport tab="project" user={user} />;
    },
  },
  {
    id: 'primaryteamOfficeTimeTracking',
    view: ({user}) => {
      return <TeamOfficeTrackingReport tab="primary" user={user} />;
    },
  },
  {
    id: 'selfOfficeTimeTrackingReport',
    view: ({user}) => {
      return <OfficeTrackingReportTabs user={user} />;
    },
  },
];

// export const Screens = [
//   {
//     menuItemId: 'attendance',
//     views: [
//       {
//         id: 'myattendance',
//         label: 'Attendance',
//         view: ({user}) => {
//           return <MyAttendanceTab user={user} fromMyView={true} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'teamattendance',
//     views: [
//       {
//         id: 'teammonthlyattendance',
//         label: 'Attendance',
//         view: ({user}) => {
//           return <TeamAttendanceTab user={user} />;
//         },
//       },
//       {
//         id: 'teamleaverequest',
//         label: 'Leave Requests',
//         view: ({user}) => {
//           return <TeamRequestTab user={user} />;
//         },
//       },
//       {
//         id: 'attendencechangerequest',
//         label: 'Attendence Requests',
//         view: ({user}) => {
//           return <TeamAttendanceRequestTab user={user} />;
//         },
//       },
//       {
//         id: 'teamtimesheetrequest',
//         label: 'Timesheet Requests',
//         view: ({user}) => {
//           return <TeamTimesheetRequestTab user={user} />;
//         },
//       },
//       {
//         id: 'wfhrequest',
//         label: 'WFH Requests',
//         view: ({user}) => {
//           return <TeamWFHRequestTab user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'allattendance',
//     views: [
//       {
//         id: 'monthlyattendenceforhr',
//         label: 'Attendence',
//         view: ({user}) => {
//           return <AttendanceTab user={user} />;
//         },
//       },
//       {
//         id: 'leaverequestforhr',
//         label: 'Leave Request',
//         view: ({user}) => {
//           return <LeaveRequestHrTabs user={user} />;
//         },
//       },
//       {
//         id: 'wfhrequestforhr',
//         label: 'WFH Request',
//         view: ({user}) => {
//           return <WFHRequestHrTabs user={user} />;
//         },
//       },
//       {
//         id: 'attendencechangerequestforhr',
//         label: 'Attendence Request',
//         view: ({user}) => {
//           return <AttendanceChangeRequestHrTabs user={user} />;
//         },
//       },
//       {
//         id: 'timesheetrequestforhr',
//         label: 'Timesheet Requests',
//         view: ({user}) => {
//           return <TimesheetRequestHrTabs user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'setup',
//     views: [
//       // {
//       //   id: 'monthlyattendance',
//       //   label: 'Monthly Attendance',
//       //   view: props => {
//       //     return <MonthlyAttendanceTable {...props} />;
//       //   },
//       // },
//       {
//         id: 'attendenceforsetup',
//         label: 'Attendence',
//         view: ({user}) => {
//           return <AttendanceTabView user={user} />;
//         },
//       },
//       {
//         id: 'leaveregister',
//         label: 'Leave Register',
//         view: ({user}) => {
//           return <LeaveRegisterTable user={user} />;
//         },
//       },
//       {
//         id: 'freezesalaryrange',
//         label: 'Freeze Salary Range',
//         view: ({user}) => {
//           return <FreezeRangeTable user={user} />;
//         },
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'allattendance',
//     label: 'All Attendance',
//   },
//   {
//     id: 'teamattendance',
//     label: 'Team Attendance',
//   },
//   {
//     id: 'attendance',
//     label: 'My Attendance',
//   },
//   {
//     id: 'setup',
//     label: 'Setup',
//   },
// ];
