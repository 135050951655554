import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ModuleMenuForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {product, department, row, target_audience, organization} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/modulemenus',
    eventSourceId: 'moduleMenu',
    ...props,
  });

  return (
    <Form
      api={`/modulemenus/${row?._id}`}
      fields={{
        menu: 1,
        screen: 1,
        scope: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Module Menu'}}
      defaultValues={{
        module: row?._id,
      }}
      layoutFields={[
        {
          label: 'Menu',
          type: 'text',
          field: 'menu',
          required: true,
        },
        {
          label: 'Screen',
          type: 'text',
          field: 'screen',
        },
        {
          label: 'Scope',
          type: 'text',
          field: 'scope',
        },
      ]}
      {...props}
    />
  );
};

export const EditModuleMenuForm = props => {
  return (
    <ModuleMenuForm
      header={{title: 'Edit Module Menu'}}
      mode="edit"
      {...props}
    />
  );
};
