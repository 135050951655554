import React from 'react';
import {LeftMenuView as LeftMenuViewComponent} from '@unthinkable/react-menus';
import {useStyles} from '@unthinkable/react-theme';
import {LeftMenuViewStyles} from './theme';
import {getRenderComponent} from '@unthinkable/react-utils';
import {Tooltip} from '../modal/Tooltip';
import {renderMenuItem} from './MenuItemWithCount';
import {renderSectionItem} from './SectionItemWithCount';

export const LeftMenuView = ({header, ...props}) => {
  const styles = useStyles(LeftMenuViewStyles);

  let headerComponent = header && getRenderComponent(header, {...props});

  const renderHeader = props => {
    return React.cloneElement(headerComponent, props);
  };

  const renderTooltip = ({tooltip, ...props}) => {
    if (typeof tooltip === 'string') {
      tooltip = {content: tooltip};
    }
    if (!tooltip?.content) {
      return props.children;
    }
    return <Tooltip position="right" {...props} {...tooltip} />;
  };

  return (
    <LeftMenuViewComponent
      renderHeader={headerComponent && renderHeader}
      {...props}
      styles={styles}
      renderTooltip={renderTooltip}
      renderMenuItem={renderMenuItem}
      renderSectionItem={renderSectionItem}
    />
  );
};
