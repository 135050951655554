import React from 'react';
import {SectionItem} from '@unthinkable/react-menus';
import {useAppStateContext} from '../../providers/AppState';
import {useFetchCount} from '@unthinkable/react-data-handler';
import {removeRouteParams} from './Utility';
import {useModuleGlobalParams} from '../../controllers/useModuleGlobalParams';

const SectionItemWithCount = ({menuItem, countProps, count, ...props}) => {
  const {fetch} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);

  if (typeof countProps === 'function') {
    countProps = countProps(props);
  }

  let {params, ...restProps} = props;
  let {params: itemParams, ...restItem} = menuItem;
  params = removeRouteParams({
    ...props,
    params: itemParams || params,
  });

  let itemFetchCount = useFetchCount({
    fetch,
    globalParams,
    params,
    ...restItem,
    ...countProps,
  });

  return (
    <SectionItem
      {...restProps}
      params={params}
      menuItem={menuItem}
      count={count || itemFetchCount}
    />
  );
};

export const renderSectionItem = props => {
  return <SectionItemWithCount {...props} />;
};
