import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const AddOutreachContent = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/campaignMessages',
    eventSourceId: 'outreachAdded',
    ...props,
  });
  const {
    route: {params},
  } = props;
  const {product, department, row, campaign_type, marketingThemeFilter} =
    params;
  return (
    <Form
      header={{title: 'Add Outreach Content'}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{
        product,
        department,
        campaign_id: row,
        campaign_type,
        created_on: getZeroTimeDate(new Date()),
        status: 'active',
      }}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Subject',
          field: 'subject',
          type: 'text',
          visible: ({values}) => values?.subject,
        },
        {
          label: 'Body',
          field: 'body',
          type: 'richText',
        },
        {
          label: 'Send After',
          field: 'send_after',
          type: 'number',
        },
        {
          label: 'Marketing Theme',
          field: 'marketing_theme',
          type: 'autoComplete',
          api: '/marketingThemes',
          suggestionField: 'theme_value_proposition',
          valueField: 'theme_value_proposition',
          filter: marketingThemeFilter,
        },
      ]}
      {...props}
    />
  );
};

export const EditOutreach = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <AddOutreachContent
      mode="edit"
      api={'/campaignmessages/' + row?._id}
      fields={{
        name: 1,
        subject: 1,
        body: 1,
        send_after: 1,
        marketing_theme: {
          theme_value_proposition: 1,
        },
      }}
      header={'Edit Series'}
      {...props}
    />
  );
};
