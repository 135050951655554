import React from 'react';
import {TabView} from '../../../components/tab';
import {IntegratedProjectComponentScreen} from './assets/ComponentScreen';
import {IntegratedProjectStyledComponentScreen} from './assets/StyledComponentScreen';
import {ThemeLibraryVersionTab} from './ThemeLibraryVersionTab';
import {IntegratedProjectComposedComponentScreen} from './assets/ComposedComponentScreen';

export const UILibraryVersionTab = props => {
  return (
    <TabView
      tabs={{
        uiComponents: {
          label: 'UI Components',
          view: <IntegratedProjectComponentScreen {...props} />,
        },
        styledComponents: {
          label: 'Styled Components',
          view: <IntegratedProjectStyledComponentScreen {...props} />,
        },
        composedComponents: {
          label: 'Composed Components',
          view: <IntegratedProjectComposedComponentScreen {...props} />,
        },
        theme: {
          label: 'Theme',
          view: <ThemeLibraryVersionTab {...props} />,
        },
      }}
    />
  );
};
