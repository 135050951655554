import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {CommunicationRender} from '../../customer/views/CustomersTable';
import {leadControllers} from '../../leadGeneration/controllers/leadControllers';
import { leadToCustomerFilter } from './CampaignCommonFilter';

const CUSTOMER_NAME = {
  header: 'Customer Name',
  field: 'name',
};

const EMPLOYEE_COUNT = {
  header: 'Size',
  field: 'employee_count.number',
};

const COUNTRY = {
  header: 'Country',
  field: 'country.name',
  colorField: 'country.color',
  type: 'colorTag',
};

const INDUSTRY = {
  header: 'Industry',
  field: 'industry.name',
  colorField: 'industry.color',
  type: 'colorTag',
};

const STATUS = {
  header: 'Status',
  field: 'active_status',
};

const LEAD_VALUE = {
  header: 'Value',
  field: 'lead_score',
  toFixed: 2,
  align: 'right',
};

const LAST_COMMUNICATION = customerCommunications => ({
  header: 'Communication',
  render: CommunicationRender,
  onPress: customerCommunications,
});

const ChannelwiseCustomerTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {filters} = params || {};
  params = {...params, ...filterParams};
  const {product} = params;
  const {customerCommunications} = leadControllers(props);

  return (
    <Table
      api={`/product/${product?._id}/allCustomer`}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      params={params}
      searchFields="name"
      addOnFilter={{...filter, ...filters}}
      search={searchValue}
      columns={[
        CUSTOMER_NAME,
        EMPLOYEE_COUNT,
        COUNTRY,
        INDUSTRY,
        STATUS,
        LEAD_VALUE,
        LAST_COMMUNICATION(customerCommunications),
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`customer-detail`, {
              ...params,
              customerId: row._id,
              row,
            });
          },
        },
      ]}
    />
  );
};

export const ChannelWiseCustomerTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: {filterParams, period: periods} = {}} = filterValues;
  let {
    filters,
    addOnFilter = {},
    row,
    organization,
    product,
    period,
    ...rest
  } = params;
  addOnFilter = {...addOnFilter, ...leadToCustomerFilter(row)};
  filter = {...filter, ...addOnFilter};

  params = {
    ...filterValues,
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    customer: {
      label: 'Customer',
      api: `/product/${product?._id}/allCustomer`,
      view: (
        <ChannelwiseCustomerTable
          searchValue={searchValue}
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'channelwisecustomer',
      addOnFilter: filters,
      params: {...params},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
