import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  PRLinkRender,
  PRStatusChipRender,
  PrNumberLinkRender,
} from '../../../app-components/renders/PRRenders';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {Avatar} from '../../../components/avatar/Avatar';

export const FeaturePullRequestTable = props => {
  const {
    route: {params},
    level,
  } = props;

  const {secondaryTitle, filter} = params;

  const extraColumns = [];

  if (level) {
    extraColumns.push(
      {
        header: 'Branch',
        field: 'branch_id.branch_name',
        type: 'text',
      },
      {
        header: 'Target Branch',
        field: 'parent_branch_id.branch_name',
        type: 'text',
      },
    );
  }

  const {searchValue, filterValues, onChangeFilter} = useFilter({});

  if (filterValues?.filter?.owner_name) {
    filterValues.filter.owner_name = filterValues.filter.owner_name._id;
  }

  return (
    <Table
      eventSourceId={['PullRequest']}
      search={searchValue}
      searchFields="title"
      renderHeader={() => {
        return (
          <TableHeader
            title={'Pull Requests'}
            secondaryTitle={secondaryTitle}
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <AutoCompleteFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                field={'owner_name'}
                api={`/projects/${params.project._id}/pullRequestUsers`}
                suggestionField={'owner_name'}
                valueField={'owner_name'}
                placeholder={'Author'}
              />,
            ]}
          />
        );
      }}
      api={`/projectpullrequests`}
      filter={{...filterValues.filter, ...filter}}
      fields={{
        createdAt: 1,
        updatedAt: 1,
        owner_name: 1,
        state: 1,
        title: 1,
        pullrequest_id: 1,
        pullrequest_link: 1,
        branch_id: {branch_name: 1},
        parent_branch_id: {branch_name: 1},
        repository_id: {type: 1, label: 1},
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          header: 'ID',
          width: 80,
          render: PrNumberLinkRender,
        },
        {
          header: 'Author',
          render: Avatar,
          field: 'owner_name',
          align: 'center',
          width: 70,
          size: 30,
        },
        {
          header: 'Title',
          field: 'title',
          type: 'text',
          render: ({row}) => {
            const {title, branch_id, parent_branch_id} = row;
            const branch = `${branch_id?.branch_name} --> ${parent_branch_id?.branch_name}`;
            return (
              <PrimaryColumnCard
                numberOfLines={1}
                primaryTitle={title}
                items={[
                  {
                    value: branch,
                    numberOfLines: 1,
                  },
                ]}
              />
            );
          },
        },
        ...extraColumns,
        {
          header: 'Status',
          render: PRStatusChipRender,
          width: 100,
        },
        {
          header: 'Updated On',
          field: 'updatedAt',
          type: 'date',
          formatOptions: {fromNow: true},
        },
        {
          render: PRLinkRender,
          width: 50,
        },
      ]}
    />
  );
};
