import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ReactFlow, {
  Controls,
  Background,
  useReactFlow,
  ReactFlowProvider,
  MarkerType,
  ControlButton,
  Panel,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {MoreActions} from '../moreAction/MoreAction';
import {CustomNodeComponentStyles} from './theme';

const CustomNodeComponent = ({
  item,
  onPress,
  onToggleCollapse,
  isCollapsed,
  onToggleFocus,
  isFocused,
  // duplicateNode,
  moreActions = [],
  keyField,
  selectedElement,
  // copyNumber /*** duplicate node ****/,
  nodeField,
  nodeId,
  hasChildren,
  collapsable,
  focusable,
  setHoveredElement,
}) => {
  let text = item[nodeField];

  const isNodeSelected = item[keyField] === selectedElement;

  // /**********************duplicate Node Case **********************************/
  // const isVirtual = duplicateNode ? duplicateNode?.is_virtual : item?.is_virtual;

  // const isNodeSelected =
  //   !isVirtual && duplicateNode
  //     ? duplicateNode[keyField] === selectedElement
  //     : item[keyField] === selectedElement;

  // if (duplicateNode) {
  //   text = `${duplicateNode[nodeField]} (${copyNumber})`;
  // }

  const {
    ExpandGreenIcon,
    FocusGreenIcon,
    BlurGreenIcon,
    nodeContainer,
    selectedNodeContainer,
    focusedNodeContainer,
    textStyle,
    iconContainerStyle,
    iconStyle,
    expandedIconStyle,
    moreActionAbsoluteContainer,
  } = useStyles(CustomNodeComponentStyles);

  const handleCollapseToggle = () => {
    onToggleCollapse(nodeId);
  };
  const handleFocusToggle = () => {
    onToggleFocus(nodeId);
  };

  return (
    <>
      <TouchableOpacity
        onMouseEnter={() => {
          setHoveredElement(nodeId);
        }}
        onMouseLeave={() => {
          setHoveredElement(void 0);
        }}
        style={
          isNodeSelected
            ? {...nodeContainer, ...selectedNodeContainer}
            : isFocused
            ? {...nodeContainer, ...focusedNodeContainer}
            : nodeContainer
        }
        onPress={onPress}>
        <Row gap={10} style={{alignItems: 'center'}}>
          <View style={{flex: 1, overflow: 'hidden'}}>
            <Text style={textStyle}>{text}</Text>
          </View>
          {collapsable && hasChildren ? (
            <TouchableOpacity
              title={isCollapsed ? 'expand' : 'collapse'}
              onPress={handleCollapseToggle}
              style={iconContainerStyle}>
              <Image
                style={
                  isCollapsed
                    ? {...iconStyle}
                    : {...iconStyle, ...expandedIconStyle}
                }
                source={ExpandGreenIcon}
              />
            </TouchableOpacity>
          ) : (
            void 0
          )}
          {focusable && hasChildren ? (
            <TouchableOpacity
              title={isFocused ? 'Unfocus' : 'focus'}
              onPress={handleFocusToggle}
              style={iconContainerStyle}>
              <Image
                style={iconStyle}
                source={isFocused ? BlurGreenIcon : FocusGreenIcon}
              />
            </TouchableOpacity>
          ) : (
            void 0
          )}
        </Row>
      </TouchableOpacity>
      {moreActions?.length ? (
        <View style={moreActionAbsoluteContainer}>
          <MoreActions actions={moreActions} row={item} />
        </View>
      ) : (
        void 0
      )}
    </>
  );
};

const directionConfig = {
  'top-to-bottom': {
    rankdir: 'TB',
    sourcePosition: 'bottom',
    targetPosition: 'top',
  },
  'bottom-to-top': {
    rankdir: 'BT',
    sourcePosition: 'top',
    targetPosition: 'bottom',
  },
  'left-to-right': {
    rankdir: 'LR',
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  'right-to-left': {
    rankdir: 'RL',
    sourcePosition: 'left',
    targetPosition: 'right',
  },
};

const ReactFlowWrappedComponent = ({
  data = [],
  onNodePress,
  moreActions,
  selectedId,
  keyField,
  direction = 'top-to-bottom',
  relationshipKey,
  excludeRelationKey,
  nodeField = 'name',
  mapKey = '_id',
  collapsable,
  focusable,
  node: customNode,
  renderHeader,
}) => {
  const [elements, setElements] = useState({nodes: [], edges: []});
  const [collapsedNodes, setCollapsedNodes] = useState(new Map());
  const [focusedNodes, setFocusedNodes] = useState(new Map());
  const [showIndependentNodes, setShowIndependentNodes] = useState(true);
  const [selectedElement, setSelectedElement] = useState(null);
  const [hoveredElement, setHoveredElement] = useState(null);
  const reactFlowRef = useRef(null);
  const {fitView} = useReactFlow();
  const {BRAND_HIGH, OUTLINE, BRAND_UPPER_MEDIUM, NEUTRAL_LOW} =
    useTheme('colors');
  const {ResetIcon} = useTheme('icons');
  const [width, setWidth] = useState(0);
  const [nodesWithChildren, setNodesWithChildren] = useState(new Map());

  const toggleCollapse = nodeId => {
    setCollapsedNodes(prev => {
      const newCollapsed = new Map(prev);
      // If the nodeId is not found in the map, default to false (not collapsed)
      const isCurrentlyCollapsed = newCollapsed.get(nodeId);
      if (isCurrentlyCollapsed) {
        newCollapsed.delete(nodeId);
      } else {
        newCollapsed.set(nodeId, true);
      }
      return newCollapsed;
    });
  };

  const toggleFocus = nodeId => {
    setFocusedNodes(prev => {
      const newFocused = new Map(prev);
      // If the nodeId is not found in the map, default to false (not collapsed)
      const isCurrentlyFocused = newFocused.get(nodeId);
      if (isCurrentlyFocused) {
        newFocused.delete(nodeId);
      } else {
        newFocused.set(nodeId, true);
      }
      return newFocused;
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === reactFlowRef.current) {
          setWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(reactFlowRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Effect runs only once on mount

  useLayoutEffect(() => {
    let {edges} = elements;
    edges = edges.map(edge => {
      if (
        edge.source === selectedElement ||
        edge.source === hoveredElement ||
        (edge.source === Array.from(focusedNodes.keys()).pop() &&
          !edge.source === selectedElement)
      ) {
        edge.style = {stroke: BRAND_UPPER_MEDIUM, strokeWidth: 1.5};
      } else {
        edge.style = {stroke: NEUTRAL_LOW, strokeWidth: 1};
      }
      return edge;
    });
    setElements({nodes: elements?.nodes, edges});
  }, [
    elements?.nodes?.length,
    elements?.edges?.length,
    hoveredElement,
    selectedElement,
    focusedNodes,
  ]);

  useEffect(() => {
    setSelectedElement(selectedId);
  }, [selectedId]);

  useEffect(() => {
    setTimeout(() => {
      fitView();
    }, 100);
  }, [width, data, focusedNodes, showIndependentNodes]);

  useLayoutEffect(() => {
    const {nodes: screenNodes, edges: screenEdges} = convertDataToGraph(data);

    let connectedNodesObj = {};

    let connectedNodes = [];
    let independentNodes = [];

    screenEdges.forEach(edge => {
      if (!connectedNodesObj[edge.source]) {
        connectedNodesObj[edge.source] = true;
      }
      if (!connectedNodesObj[edge.target]) {
        connectedNodesObj[edge.target] = true;
      }
    });

    screenNodes.forEach(node => {
      if (!connectedNodesObj[node.id]) {
        independentNodes.push(node);
      } else {
        connectedNodes.push(node);
      }
    });

    // Apply graph layout
    const {nodes: layoutedNodes, edges: layoutedEdges} = applyGraphLayout({
      nodes: connectedNodes,
      independentNodes: showIndependentNodes ? independentNodes : [],
      screenEdges,
    });

    const findAllDescendants = (nodeId, visited = new Set()) => {
      if (visited.has(nodeId)) {
        return [];
      }
      visited.add(nodeId);
      const directChildren = layoutedEdges
        .filter(edge => edge.source === nodeId)
        .map(edge => edge.target);
      const allDescendants = directChildren.flatMap(childId =>
        findAllDescendants(childId, visited),
      );
      return [...directChildren, ...allDescendants];
    };

    // Function to check if a node has alternative parents
    const hasAlternativeParents = ({
      descendantNodeIds,
      descendantNodeId,
      collapsedNodeId,
    }) => {
      // Function to recursively find all parent nodes of a given node
      const findAllParents = (nodeId, visited = new Set()) => {
        if (visited.has(nodeId)) {
          return [];
        }
        visited.add(nodeId);
        const parents = layoutedEdges
          .filter(
            edge =>
              edge.target === nodeId &&
              edge.source !== collapsedNodeId &&
              !collapsedNodes.get(edge.source),
          )
          .map(edge => edge.source);
        const allParents = parents.flatMap(parentId =>
          findAllParents(parentId, visited),
        );
        return [...parents, ...allParents];
      };

      // Find all parents of the descendant node
      const allParents = findAllParents(descendantNodeId);

      // Check if any of the parents are not in the list of collapsed nodes and not in the list of descendant node IDs
      return allParents.some(
        parentId =>
          !collapsedNodes?.get(parentId) &&
          !descendantNodeIds.includes(parentId),
      );
    };
    // Set of visible nodes and edges
    const visibleNodes = new Set(layoutedNodes.map(node => node.id));
    const visibleEdges = new Set(layoutedEdges.map(edge => edge.id));

    // Iterate over collapsed nodes and update visibility of their descendants
    collapsedNodes.forEach((isCollapsed, collapsedNodeId) => {
      if (isCollapsed) {
        // Get all descendant nodes of the collapsed node
        const descendantNodeIds = findAllDescendants(collapsedNodeId);

        // Convert the array to a Set to remove duplicates, then convert it back to an array
        const uniqueDescendantNodeIds = [...new Set(descendantNodeIds)];

        let descendentNodesWithAlternativeParents = [];

        uniqueDescendantNodeIds.forEach(descendantId => {
          if (
            hasAlternativeParents({
              descendantNodeIds: uniqueDescendantNodeIds,
              descendantNodeId: descendantId,
              collapsedNodeId,
            })
          ) {
            descendentNodesWithAlternativeParents.push(descendantId);
          }
        });

        let descendentNodeIdsExcludesDescendentNodesWithAlternativeParents =
          uniqueDescendantNodeIds.filter(
            id => !descendentNodesWithAlternativeParents.includes(id),
          );

        // Hide all descendant nodes
        uniqueDescendantNodeIds.forEach(descendantId => {
          if (
            !hasAlternativeParents({
              descendantNodeIds:
                descendentNodeIdsExcludesDescendentNodesWithAlternativeParents,
              descendantNodeId: descendantId,
              collapsedNodeId,
            })
          ) {
            visibleNodes.delete(descendantId);
          }
        });

        // Remove edges that are from the collapsed node or to any descendant node without an alternative parent
        layoutedEdges.forEach(edge => {
          if (
            edge.source === collapsedNodeId ||
            (uniqueDescendantNodeIds.includes(edge.target) &&
              !hasAlternativeParents({
                descendantNodeIds:
                  descendentNodeIdsExcludesDescendentNodesWithAlternativeParents,
                descendantNodeId: edge.target,
                collapsedNodeId,
              }))
          ) {
            visibleEdges.delete(edge.id);
          }
        });
      }
    });
    focusedNodes.forEach((isFocused, focusedNodeId) => {
      if (isFocused) {
        // Get all descendant nodes of the collapsed node
        const descendantNodeIds = findAllDescendants(focusedNodeId);

        // Convert the array to a Set to remove duplicates, then convert it back to an array
        const uniqueDescendantNodeIds = [...new Set(descendantNodeIds)];

        layoutedNodes.forEach(node => {
          if (
            node.id === focusedNodeId ||
            uniqueDescendantNodeIds.includes(node.id)
          ) {
          } else {
            visibleNodes.delete(node.id);
          }
        });
      }
    });
    layoutedEdges.forEach(edge => {
      if (
        edge.source === selectedElement ||
        edge.source === hoveredElement ||
        edge.source === Array.from(focusedNodes.keys()).pop()
      ) {
        edge.style = {stroke: BRAND_UPPER_MEDIUM, strokeWidth: 1.5};
      }
    });

    // Filter out nodes and edges that are not visible
    const finalNodes = layoutedNodes.filter(node => visibleNodes.has(node.id));
    const finalEdges = layoutedEdges.filter(edge => visibleEdges.has(edge.id));

    // Update the elements state with the layouted visible nodes and edges
    setElements({nodes: finalNodes, edges: finalEdges});
  }, [
    data,
    selectedElement,
    collapsedNodes,
    focusedNodes,
    showIndependentNodes,
  ]);

  const getNestedProperty = (obj, path, excludeRelationKey) => {
    if (typeof path === 'string') {
      path = path.split('.');
    }

    const getValue = (currentObj, currentPath) => {
      if (!currentObj || currentPath.length === 0) return currentObj;
      const [firstPart, ...remainingPath] = currentPath;

      if (Array.isArray(currentObj)) {
        let result = currentObj
          .filter(item => !item[excludeRelationKey])
          .flatMap(item => getValue(item?.[firstPart], remainingPath))
          .filter(item => !!item && !item[excludeRelationKey]);
        return result;
      } else {
        return getValue(currentObj?.[firstPart], remainingPath);
      }
    };
    return getValue(obj, path);
  };

  // Converts data to graph format using a relationship configuration
  const convertDataToGraph = (data = []) => {
    const nodes = [];
    const edges = [];
    const nodeLookup = new Map();
    const edgesMap = {};

    data?.forEach(item => {
      const nodeId = item?.[mapKey];
      nodeLookup.set(nodeId, nodeId);
    });

    // First pass: Create edges and handle parents/children relationships
    data?.forEach(item => {
      const sourceId = item?.[mapKey]; // Map according to mapKey to node instead of _id
      let relatedItems =
        getNestedProperty(item, relationshipKey, excludeRelationKey) || [];

      relatedItems.forEach(relatedItem => {
        const relatedItemId = relatedItem[mapKey] || relatedItem;
        let targetId = nodeLookup.get(relatedItemId);

        if (targetId) {
          if (!nodesWithChildren.has(sourceId)) {
            setNodesWithChildren(nodesWithChildren.set(sourceId, [targetId]));
          } else {
            nodesWithChildren.get(sourceId).push(targetId);
          }

          const edgeId = sourceId + '-' + targetId;

          if (!edgesMap[edgeId]) {
            edges.push({
              id: edgeId,
              source: sourceId,
              target: targetId,
              animated: true,
              // type: 'smoothstep',
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: BRAND_HIGH,
                height: 20,
                width: 20,
              },
            });
            edgesMap[edgeId] = 1;
          }
        }
      });
    });

    // Second pass: Create nodes
    data?.forEach(item => {
      const nodeId = nodeLookup.get(item?.[mapKey]);
      const isNodeCollapsed = collapsedNodes.get(nodeId) || false;
      const isNodeFocused = focusedNodes.get(nodeId) || false;
      const hasChildren = nodesWithChildren.has(nodeId); // Check if the node has children
      nodes.push({
        id: nodeId,
        data: {
          item,
          label: React.isValidElement(customNode) ? (
            React.cloneElement(customNode, {
              focusable,
              collapsable,
              hasChildren,
              isCollapsed: isNodeCollapsed,
              isFocused: isNodeFocused,
              onToggleCollapse: toggleCollapse,
              onToggleFocus: toggleFocus,
              setHoveredElement,
              hoveredElement,
              item,
              keyField,
              selectedElement,
              nodeField,
              nodeId,
            })
          ) : (
            <CustomNodeComponent
              focusable={focusable}
              collapsable={collapsable}
              hasChildren={hasChildren} // Pass the hasChildren prop
              isCollapsed={isNodeCollapsed}
              isFocused={isNodeFocused}
              hoveredElement={hoveredElement}
              setHoveredElement={setHoveredElement}
              moreActions={moreActions}
              onPress={() => {
                onNodePress({item});
              }}
              onToggleCollapse={toggleCollapse}
              onToggleFocus={toggleFocus}
              item={item}
              keyField={keyField}
              selectedElement={selectedElement}
              nodeField={nodeField}
              nodeId={nodeId}
            />
          ),
        },
        position: {x: 0, y: 0}, // Initial position
        sourcePosition: directionConfig[direction].sourcePosition,
        targetPosition: directionConfig[direction].targetPosition,
      });
    });

    return {nodes, edges};
  };

  const applyGraphLayout = ({
    nodes = [],
    independentNodes = [],
    screenEdges = [],
  }) => {
    const g = new dagre.graphlib.Graph();
    g.setGraph({rankdir: directionConfig[direction]?.rankdir});
    g.setDefaultEdgeLabel(() => ({}));

    // Set nodes in the graph with width and height
    nodes.forEach(node => {
      g.setNode(node?.id, {width: 172, height: 36}); // Set your desired node width and height
    });

    // Set edges in the graph
    screenEdges.forEach(edge => {
      g.setEdge(edge?.source, edge?.target);
    });

    // Apply dagre layout to connected nodes
    if (nodes.length > 0) {
      dagre.layout(g);
    }

    // Calculate positions for connected nodes if any
    nodes.forEach(node => {
      const nodeWithPosition = g.node(node?.id);
      node.position = {
        x: nodeWithPosition?.x * 1.5 - nodeWithPosition?.width / 2,
        y: nodeWithPosition?.y * 1.5 - nodeWithPosition?.height / 2,
      };
    });

    // Calculate the bounding box of the connected nodes group if any
    const connectedNodesBBox =
      nodes.length > 0
        ? nodes.reduce(
            (box, node) => {
              return {
                minX: Math.min(box.minX, node?.position?.x),
                minY: Math.min(box.minY, node?.position?.y),
                maxX: Math.max(box.maxX, node?.position?.x + 172), // Width of the node
                maxY: Math.max(box.maxY, node?.position?.y + 36), // Height of the node
              };
            },
            {minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity},
          )
        : {minX: 0, minY: 0, maxX: 0, maxY: 0};

    if (independentNodes.length > 0) {
      // Position alone nodes vertically on the left
      const offsetY = 80; // Space between alone nodes
      const aloneNodesStartX =
        nodes.length > 0 ? connectedNodesBBox.minX - 200 : 0; // Space between connected and alone nodes groups
      let aloneNodesY = nodes.length > 0 ? connectedNodesBBox.maxY + 200 : 0; // Start alone nodes at the bottom

      independentNodes.forEach(node => {
        node.position = {
          x: aloneNodesStartX,
          y: aloneNodesY,
        };
        aloneNodesY -= offsetY; // Decrement Y position for next node (bottom to top)
      });

      if (nodes.length > 0) {
        // Find the middle Y position of alone nodes
        const middleAloneNodeY =
          (independentNodes[0]?.position?.y +
            independentNodes[independentNodes.length - 1]?.position?.y) /
          2;

        // Adjust connected nodes position to align with the middle of alone nodes
        const connectedNodesOffsetX =
          connectedNodesBBox.minX - aloneNodesStartX;

        nodes.forEach(node => {
          node.position.x += connectedNodesOffsetX + 200; // Move connected nodes to the right of alone nodes
          node.position.y +=
            middleAloneNodeY -
            (connectedNodesBBox.minY + connectedNodesBBox.maxY) / 2; // Align vertically with the middle of alone nodes
        });
      }
    } else {
      // If aloneNodes is empty, no need to adjust positions, just use the calculated positions
      nodes.forEach(node => {
        node.position = {
          x: node?.position?.x,
          y: node?.position?.y,
        };
      });
    }

    // Return the positioned nodes and edges
    return {nodes: [...independentNodes, ...nodes], edges: screenEdges};
  };

  if (React.isValidElement(renderHeader)) {
    renderHeader = React.cloneElement(renderHeader, {
      showIndependentNodes,
      setShowIndependentNodes,
    });
  }

  const resetState = () => {
    setCollapsedNodes(new Map());
    setFocusedNodes(new Map());
  };

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
      ref={reactFlowRef}>
      <ReactFlow
        zoomOnScroll={false}
        panOnScroll={true}
        nodes={elements.nodes}
        edges={elements.edges}
        minZoom={0} // Allow more zooming out
        maxZoom={4} // Optional: Control maximum zoom level
        fitView>
        <Controls showInteractive={false} fitViewOptions={{duration: 100}}>
          <ControlButton
            disabled={!(focusedNodes?.size || collapsedNodes?.size)}
            title={'reset'}
            onClick={resetState}>
            <Image style={{height: 16, width: 16}} source={ResetIcon} />
          </ControlButton>
        </Controls>
        <Panel position="top">{renderHeader}</Panel>
        <Background variant="lines" color={OUTLINE} gap={12} size={1} />
      </ReactFlow>
    </View>
  );
};

export const ReactFlowComponent = ({loading = true, ...props}) => {
  const {OUTLINE, SURFACE1} = useTheme('colors');

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <View
      style={{
        backgroundColor: SURFACE1,
        borderColor: OUTLINE,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        flex: 1,
      }}>
      <ReactFlowProvider>
        <ReactFlowWrappedComponent {...props} />
      </ReactFlowProvider>
    </View>
  );
};
