import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const DeploymentPlatformForm = props => {
  const {
    route: {params},
    mode,
  } = props;
  const {project, afterSubmit} = params || {};
  const defaultValues = {
    project_id: project?._id,
    platform: 'Jenkins',
    managed_by: 'Daffodil',
  };


  let {onSubmit} = useFormSubmit({
    uri: '/deploymentplatforms',
    eventSourceId: 'DeploymentPlatform',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      afterSubmit={afterSubmit}
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Platform',
          type: 'radioGroup',
          field: 'platform',
          options: [/* 'Bitbucket',  */ 'Jenkins'],
          required: true,
          onChangeValue: (value, e, {values, setFieldValue}) => {
            if (value === 'Bitbucket') {
              if (values.url) {
                setFieldValue('url', null);
              }
              if (values.userName) {
                setFieldValue('userName', null);
              }
              if (values.accessToken) {
                setFieldValue('accessToken', null);
              }
            }
          },
        },
        {
          label: 'Managed By',
          type: 'radioGroup',
          field: 'managed_by',
          options: ['Daffodil', 'Client'],
          required: true,
        },
        {
          type: 'text',
          field: 'url',
          label: 'URL',
          required: true,
          visible: ({values}) =>
            values.platform === 'Jenkins' && values.managed_by === 'Client',
        },
        {
          type: 'text',
          field: 'user_name',
          label: 'User Name',
          visible: ({values}) =>
            values.platform === 'Jenkins' && values.managed_by === 'Client',
          required: true,
        },
        {
          type: mode === 'edit' ? 'password' : 'text',
          field: 'access_token',
          label: 'Access Token',
          visible: ({values}) =>
            values.platform === 'Jenkins' && values.managed_by === 'Client',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const DeploymentPlatformAddForm = props => {
  return <DeploymentPlatformForm header="Add platform" {...props} />;
};

export const DeploymentPlatformUpdateForm = props => {
  const {
    route: {params},
  } = props;

  const {deployment} = params;

  return (
    <DeploymentPlatformForm
      mode="edit"
      header="Update platform"
      api={`/deploymentplatforms/${deployment?._id}`}
      fields={{
        platform: 1,
        url: 1,
        userName: 1,
        managed_by: 1,
      }}
      {...props}
    />
  );
};
