import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {MailContentRender} from '../components/ExpandableRowComponent';
import {Header} from '@unthinkable/react-screen-components';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {Checkbox} from '../../../components/form-editors/checkbox/Checkbox';
import {ExpandRowIconRender} from '../../../components/table/Renders';
import {Button} from '../../../components/button/Button';

const NAME = {
  field: 'name',
  header: 'Message Name',
  type: 'text',
  responsive: 'sm',
};

export const LoadEmailMessages = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {marketing_theme, formProps} = params;
  const {values, setFieldValue} = formProps;
  let {loaded_messages = []} = values;
  let marketingWiseSelectedMessage;
  loaded_messages.forEach(item => {
    if (item.marketing_theme == marketing_theme?._id) {
      marketingWiseSelectedMessage = item;
    }
  });
  const [selectedMessage, setSelectedMessage] = useState(
    marketingWiseSelectedMessage,
  );
  const {notify} = useEventHandler();

  return (
    <Table
      renderHeader={() => (
        <Header
          title={'Messages'}
          actions={[
            <Button
              text="Load"
              onPress={() => {
                loaded_messages = loaded_messages.filter(
                  item => item.marketing_theme !== marketing_theme?._id,
                );
                if (selectedMessage != null) {
                  loaded_messages.push({
                    ...selectedMessage,
                    marketing_theme: marketing_theme?._id,
                  });
                }
                setFieldValue('loaded_messages', [...loaded_messages]);
                notify('dataUpdated', {id: 'loadEmailMessages'});
                navigation.goBack();
              }}
            />,
          ]}
        />
      )}
      eventSourceId="dripEmailAdded"
      searchFields={'email_subject'}
      api={`/drip-emails-with-response-rate`}
      filter={{
        marketing_theme,
      }}
      renderExpandRow={({row}) => {
        return (
          <MailContentRender
            subject={row.email_subject}
            message={row?.email_body}
          />
        );
      }}
      columns={[
        {
          render: ({row}) => (
            <Checkbox
              value={row?._id == selectedMessage?._id}
              onChangeValue={() => {
                if (row?._id == selectedMessage?._id) {
                  setSelectedMessage(null);
                } else {
                  setSelectedMessage(row);
                }
              }}
            />
          ),
          width: 50,
        },
        NAME,
        {
          width: 200,
          header: 'Response Rate',
          field: 'response',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          render: ExpandRowIconRender,
          expandable: true,
          responsive: 'sm',
          width: 50,
        },
      ]}
    />
  );
};
