import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {AllCustomerTable} from '../../customer/views/CustomersTable';
import {CampaignContactDetails} from '../outreachcampaign-views/CampaignContactDetails';
import {CampaignMessagesTab} from '../outreachcampaign-views/CampaignMessagesTab';
import {ActiveQualifiedLeadTable, AllLeadTableMenu} from './LeadMenuTables';

export const CampaignActionsMenu = props => {
  const {
    route: {params},
  } = props;

  const menus = [
    {
      label: 'Actions',
      view: <CampaignMessagesTab />,
    },
    {
      label: 'Prospects',
      section: true,
      menus: [
        {
          label: 'Pipeline',
          view: (
            <CampaignContactDetails
              addOnFilter={{
                campaign_id: params?.campaign_id?._id,
                status: 'new',
              }}
            />
          ),
          api: '/campaigncontacts',
          filter: {
            campaign_id: params?.campaign_id?._id,
            status: 'new',
          },
        },
        {
          label: 'Active',
          view: (
            <CampaignContactDetails
              addOnFilter={{
                campaign_id: params?.campaign_id?._id,
                showActive: true,
                status: {
                  $in: ['connected', 'connection_request_sent', 'nurturing'],
                },
                api: `/outreachcampaigns/${params?.campaign_id?._id}/contacts`,
              }}
            />
          ),
          api: `/outreachcampaigns/${params?.campaign_id?._id}/contacts`,
          filter: {
            campaign_id: params?.campaign_id?._id,
            status: {
              $in: ['connected', 'connection_request_sent', 'nurturing'],
            },
          },
        },
        {
          label: 'Dropped',
          view: (
            <CampaignContactDetails
              addOnFilter={{
                campaign_id: params?.campaign_id?._id,
                status: 'dropped',
              }}
            />
          ),
          api: '/campaigncontacts',
          filter: {
            campaign_id: params?.campaign_id?._id,
            status: 'dropped',
          },
        },
      ],
    },
    {
      label: 'Leads',
      section: true,
      menus: [
        {
          label: 'Leads',
          view: (
            <AllLeadTableMenu
              addOnFilter={{
                campaign_id: params?.campaign_id?._id,
              }}
            />
          ),
          api: `/potentialcustomers/allLeads`,
          addOnFilter: {
            campaign_id: params?.campaign_id?._id,
          },
        },
        {
          label: 'QL',
          view: (
            <ActiveQualifiedLeadTable
              addOnFilter={{
                campaign_id: params?.campaign_id?._id,
              }}
            />
          ),
          api: `/potentialcustomers/activeLeads`,
          addOnFilter: {
            campaign_id: params?.campaign_id?._id,
          },
        },
        {
          label: 'Customers',
          view: (
            <AllCustomerTable
              addOnFilter={{
                campaign_id: params?.campaign_id?._id,
              }}
            />
          ),
          api: `/product/${params?.product?._id}/allCustomer`,
          addOnFilter: {
            campaign_id: params?.campaign_id?._id,
          },
        },
      ],
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
