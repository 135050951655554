import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {CheckoutBranchActionStyles} from '../headers/theme';
import {useNavigation} from '@react-navigation/native';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';

export const BranchCheckout = props => {
  const {
    route: {params},
  } = props;

  const theme = useStyles(CheckoutBranchActionStyles);
  const {checkoutContainerStyle, branchIcon, checkoutTextStyle} = theme;
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('feature-branch-table', params);
      }}
      style={checkoutContainerStyle}>
      <Image source={branchIcon} />
      {/* <Text style={checkoutTextStyle}>Get Branch</Text> */}
    </TouchableOpacity>
  );
};
