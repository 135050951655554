import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

export const RenderRejection = ({row, styles}) => {
  const {rejected_in, rejected_date} = row;
  const colors = useTheme('colors');
  return (
    <View
      style={{
        margin: -12,
        padding: 12,
        backgroundColor: colors['ERROR_LOW'],
      }}>
      <Text
        style={{
          ...styles.text,
          color: colors['ERROR_HIGH'],
        }}>
        {rejected_in}
      </Text>
      <GroupContentItem
        value={rejected_date ? moment(rejected_date).format('DD MMM YY') : '-'}
      />
    </View>
  );
};
