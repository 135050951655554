import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {AllLeadTable} from './LeadTable';
import {
  AllLeadTableMenu,
  LostLeadsTabls,
  NewLeadsTables,
} from './LeadMenuTables';
import {
  ActiveLeadTabs,
  AllLeadTabs,
  LostLeadTabs,
  NewLeadTabs,
} from './LeadTableTabView';
import {RPSLeadTabs} from './RPSLeadTabs';
import {ConversionTabView} from './ConversionTabView';

const LeadMenu = createMenuNavigator();

const LeadNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});

  return (
    <LeadMenu.Navigator
      //   canAccess={canAccess}
      data-navigator-id="LeadGroup"
      {...props}>
      <LeadMenu.Screen
        data-resource-id="NewLeads"
        component={NewLeadTabs}
        options={{
          label: 'New',
          api: '/potentialcustomers/active',
          addOnFilter: {
            pipeline_type: 'Prospect',
            lead_status: 'Active',
            stage: {
              $in: [
                '5b56cf2421799051029c4522',
                '5530f027a814a8f057123166',
                '53bbd36a79df44bac9b12f39',
              ],
            },
            // product: props?.route?.params?.product?._id,
          },
        }}
      />
      <LeadMenu.Screen
        data-resource-id="ActiveLeads"
        component={ActiveLeadTabs}
        options={{
          label: 'Active',
          api: '/potentialcustomers/active',
          addOnFilter: {
            pipeline_type: 'Prospect',
            lead_status: 'Active',
            stage: {
              $in: [
                '5b56cf2421799051029c4522',
                '5530f027a814a8f057123166',
                '53bbd36a79df44bac9b12f39',
              ],
            },
            // product: props?.route?.params?.product?._id,
          },
        }}
      />
      {/* <LeadMenu.Screen
        data-resource-id="RPSLeads"
        component={RPSLeadTabs}
        options={{
          label: 'RPS',
          api: '/opportunities',
          addOnFilter: {
            opportunity_type: 'RPS',
          },
        }}
      /> */}
      <LeadMenu.Screen
        data-resource-id="LostLeads"
        component={LostLeadsTabls}
        options={{
          label: 'Lost',
          api: '/potentialcustomers/active',
          addOnFilter: {
            lead_status: 'Lost',
            pipeline_type: 'Prospect',
            // product: props?.route?.params?.product?._id,
          },
        }}
      />
      <LeadMenu.Screen
        data-resource-id="AllLeads"
        component={AllLeadTabs}
        options={{
          label: 'All',
          api: '/potentialcustomers/active',
          addOnFilter: {
            pipeline_type: 'Prospect',
            // product: props?.route?.params?.product?._id,
            $or: [
              {lead_status: 'Lost'},
              {
                stage: {
                  $in: [
                    '5b56cf2421799051029c4522',
                    '5530f027a814a8f057123166',
                    '53bbd36a79df44bac9b12f39',
                  ],
                },
                lead_status: 'Active',
              },
            ],
          },
        }}
      />
      <LeadMenu.Screen
        data-resource-id="Conversions"
        name="Conversion"
        component={ConversionTabView}
        options={{
          label: 'Conversion Ratio',
        }}
      />

      {/* <LeadMenu.Screen
        data-resource-id="RPS"
        component={RPSTableTabView}
        options={{
          label: 'RPS',
        }}
      />
      <LeadMenu.Screen
        data-resource-id="CustomPlatform"
        component={CustomPlatformMergedTabs}
        options={{
          label: 'Custom Platform',
        }}
      />
      <LeadMenu.Screen
        data-resource-id="StaffAgumentation"
        component={EngineeringOpportunitiesTabView}
        options={{
          label: 'Staff agumentation',
        }}
      />
      <LeadMenu.Screen
        data-resource-id="Analytics"
        component={OpportunityAnalyticsTable}
        options={{
          label: 'Analytics',
        }}
      />
      <LeadMenu.Screen
        data-resource-id="Projections"
        component={OpportunityProjectionListTeam}
        options={{
          label: 'Projections',
        }}
      /> */}
    </LeadMenu.Navigator>
  );
};
export default LeadNavigator;
