const Platform = () => {
  return null; // This component does not render anything
};

// Static properties and methods
Platform.OS = 'web';

Platform.select = config => {
  if (config) {
    return config.web;
  }
};

export default Platform;
