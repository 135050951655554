import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {CustomPlatformOpportunityActiveOverview} from '../../leadGeneration/views/CustomPlatformOpportunityTable';
import { leadToCustomerFilter } from './CampaignCommonFilter';

const ChannelwiseCustomPlatformTab = props => {
  let params = props?.route?.params;
  let {filters = {}, addOnFilter = {}, row} = params || {};

  const {applyFilter, searchValue, filterValues} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  addOnFilter = {...addOnFilter, ...leadToCustomerFilter(row)};

  filter = {...filter, ...addOnFilter};

  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  let tabs = {};
  tabs.all = {
    label: 'Custom Platform Opportunities',
    view: (
      <CustomPlatformOpportunityActiveOverview
        {...props}
        search={searchValue}
        addOnFilter={{...filter, ...filters}}
        filterParams={filterParams}
      />
    ),
    api: '/allOpportunities',
    addOnFilter: {...filter, ...filters},
    eventSourceId: 'CustomPlatformOpportunity',
    params: {
      period: filterParams.period,
      ...params,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },
          ]}
        />,
      ]}
    />
  );
};

export default ChannelwiseCustomPlatformTab;
