import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {plural} from 'pluralize';

export const RolePermissionForm = props => {
  const {
    route: {params},
    mode,
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: mode && mode === 'edit' ? '/rolepermissions' : '/addRolePermission',
    eventSourceId: 'rolePermissionAdded',
    ...props,
  });

  return (
    <Form
      api={`/rolepermissions/${row?._id}`}
      fields={{
        app_role_id: {display_label: 1},
        app_resource_id: {
          display_label: 1,
          type: 1,
          id: 1,
          parent: {type: 1, id: 1},
        },
        scope: 1,
        dataRole: 1,
        exclude_resource: 1,
      }}
      defaultValues={{app_role_id: row}}
      onSubmit={onSubmit}
      header={{title: 'Add Role Permission'}}
      layoutFields={[
        {
          label: 'App Role',
          type: 'autoComplete',
          field: 'app_role_id',
          api: '/approles',
          suggestionField: 'display_label',
          valueField: 'display_label',
          required: true,
        },
        {
          label: 'App Resource',
          type: mode && mode === 'edit' ? 'autoComplete' : 'multiAutoComplete',
          field: 'app_resource_id',
          api: '/appresources',
          fields: {
            display_label: 1,
            type: 1,
            id: 1,
            parent: {type: 1, id: 1},
          },
          filter: {type: {$nin: ['DataEntity', 'DataView']}},
          suggestionField: 'display_label',
          valueField: 'display_label',
          required: true,
        },
        {
          label: 'Scope',
          type: 'autoComplete',
          field: 'scope',
          options: ['full'],
        },
        {
          type: 'checkbox',
          field: 'exclude_resource',
          label: 'Exclude',
        },
      ]}
      {...props}
    />
  );
};

export const EditRolePermissionForm = props => {
  return (
    <RolePermissionForm
      header={{title: 'Edit Role Permission'}}
      mode="edit"
      {...props}
    />
  );
};
