import React, {forwardRef} from 'react';
import {useColorMode} from '@unthinkable/react-theme';
import {InputContainer, InputWrapper} from '@unthinkable/react-input';
import {Editor} from '@monaco-editor/react';

export const CodeEditorInput = forwardRef(({mention, ...props}, ref) => {
  const {
    value,
    onChangeValue,
    options,
    defaultLanguage = 'javascript',
    height=400,
    width,
  } = props;

  const {colorMode} = useColorMode();

  return (
    <InputWrapper {...props}>
      <InputContainer {...props} inputRef={ref}>
        <Editor
          defaultLanguage={defaultLanguage}
          value={value}
          onChange={onChangeValue}
          theme={colorMode === 'dark' ? 'vs-dark' : 'vs-light'}
          options={{
            minimap: {
              enabled: false,
            },
            ...options,
          }}
          width={width}
          height={height}
        />
      </InputContainer>
    </InputWrapper>
  );
});


