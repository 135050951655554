import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';

const RenderURL = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {properties: {hs_url = ''} = {}} = row || {};
  const underline = hs_url ? {textDecoration: 'underline'} : void 0;

  hs_url =
    hs_url && (hs_url.startsWith('https') ? hs_url : `https://${hs_url}`);

  return (
    <TouchableOpacity
      onPress={() => {
        hs_url && Linking.openURL(hs_url, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {hs_url}
      </Text>
    </TouchableOpacity>
  );
};

const RenderReferrer = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {properties: {hs_referrer = ''} = {}} = row || {};
  const underline = hs_referrer ? {textDecoration: 'underline'} : void 0;

  hs_referrer =
    hs_referrer &&
    (hs_referrer.startsWith('https') ? hs_referrer : `https://${hs_referrer}`);

  return (
    <TouchableOpacity
      onPress={() => {
        hs_referrer && Linking.openURL(hs_referrer, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {hs_referrer}
      </Text>
    </TouchableOpacity>
  );
};

export const PotentialCustomersHubspotActivitiyTable = props => {
  let {
    route: {params},
  } = props;

  let {hubspot_contact_id} = params || {};

  return (
    <Table
      api={`/hubspot/pagevisits/${hubspot_contact_id}`}
      params={params}
      columns={[
        {
          field: 'eventType',
          header: 'Event',
          type: 'text',
          width: 250,
        },
        {
          field: 'properties.hs_url',
          header: 'URL',
          render: RenderURL,
        },
        {
          field: 'properties.hs_referrer',
          header: 'Referrer',
          type: 'text',
          render: RenderReferrer,
        },
        {
          field: 'occurredAt',
          header: 'VisitedAt',
          type: 'date',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
      ]}
    />
  );
};
