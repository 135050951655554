import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {Text, View} from '@unthinkable/react-core-components';
import {
  getDateRange,
  getZeroTimeDate,
} from '@unthinkable/react-date-picker/src/DateUtility';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const PaymentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
  });
  const {params: filterParams = {}} = filterValues;

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 10,
        }}>
        <Text
          style={{
            padding: 10,
            color: '#19191A',
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Inter-Regular',

            lineHeight: '22px',
          }}>
          Invoices
        </Text>
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />
      </View>
      <Table
        api={`/customer/${row?._id}/payments`}
        params={filterParams}
        columns={[
          {
            header: 'Invoice ID',
            field: 'invoice_number',
            // width: 300
          },
          {
            header: 'Invoice Date',
            field: 'invoice_date',
            type: 'date',
            // width: 300,
          },
          {
            header: 'Invoice Amount',
            field: 'base_total_invoice_amount',
            type: 'currency',
            aggregate: true,
            // width: 200,
          },
          {
            header: 'Pending Amount',
            field: 'base_pending_receipt_amount',
            aggregate: true,
            type: 'currency',
            // width: 200,
          },
          {
            header: 'Status',
            field: 'status',
            type: 'text',
            width: 200,
          },
        ]}
      />
    </>
  );
};
