import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {useTheme} from '@unthinkable/react-theme';

import Images from '../../../images';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {TableHeader} from '../../../components/table/Headers';

const {Owner} = Images;

export const ModulesArchiveTable = props => {
  const {
    route: {params},
  } = props;
  const navigation = useNavigation();
  const {project} = params || {};
  const {navigateTo} = useLeftMenuContext() || {};
  const {colors} = useTheme();

  const deleteModuleinvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Module',
  });

  const updateModuleInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'Module',
  });

  return (
    <Table
      renderHeader={() => {
        return <TableHeader title={'Archived modules'} />;
      }}
      eventSourceId={['Module', 'Feature']}
      api={`/projects/${project?._id}/modules/archived`}
      onRowPress={props => {
        const {row} = props;

        if (navigateTo) {
          navigateTo(`module-detail-new`, {
            module: row,
            project: project,
            project_id: project?._id,
            module_id: row._id,
            breadcrumb: {
              dropdown: {
                field: 'module',
              },
            },
          });
        } else {
          navigation.navigate(`module-detail`, {
            module: row,
            project: project,
            project_id: project?._id,
            module_id: row._id,
          });
        }
      }}
      columns={[
        {
          type: 'text',
          field: 'module',
          header: 'Module Name',
        },
        {
          type: 'colorTag',
          header: 'Identifier',
          field: 'identifier',
          width: 150,
          colorField: 'color',
        },
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: 'Owner',
          width: 160,
        },
        {
          type: 'text',
          value: 'Mark Active',
          color: colors.INFORMATION_HIGH,
          onPress: ({row}) => {
            updateModuleInvoke({
              uri: `/projectmodules/${row?._id}`,
              props: {
                status: 'active',
              },
            });
          },
          width: 150,
          align: 'right',
        },
      ]}
      moreActions={() => [
        {
          title: 'Detail',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`module-edit`, {
              ...params,
              module: row,
            });
          },
        },
        // {
        //   title: 'Delete',
        //   variant: 'error',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to delete this module?',
        //   },
        //   onPress: ({row}) => {
        //     deleteModuleinvoke({
        //       uri: `/modules/${row?._id}/delete`,
        //       props: {},
        //     });
        //   },
        // },
      ]}
    />
  );
};
