import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

export const RadioGroupRenderer = ({
  value,
  options,
  valueField = 'label',
  keyField = 'value',
  color,
  ...props
}) => {
  let displayValue = value;
  if (value !== undefined && value !== null && options?.length) {
    for (let option of options) {
      if (
        typeof option === 'object' &&
        option?.[keyField] === value &&
        option?.[valueField]
      ) {
        displayValue = option[valueField];
        if (option?.color) {
          color = option.color;
        }
        break;
      }
    }
  }

  return (
    <InputLabel {...props}>
      <BaseTextRender value={displayValue} color={color} />
    </InputLabel>
  );
};
