import {
  getDateRange,
  getZeroTimeDate,
} from '@unthinkable/react-date-picker/src/DateUtility';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {RowWithSeparator} from '@unthinkable/react-layout';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {Button} from '../../../components/button/Button';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {
  GroupFilter,
  PeriodRangeFilter,
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {GroupBy} from '../../../components/table/GroupBy';
import {useFilter} from '../../../controllers/useFilter';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useAppStateContext} from '../../../providers/AppState';
import {UploadButton} from '../buttons/Upload';
import {
  ActiveOpportunityLeadTable,
  ActiveOpportunityToCustomerLeadTable,
  ActiveQualifiedLeadTable,
  ActiveQualifiedLeadTableForSolCon,
  AllLeadTableMenu,
  NewLeadsTables,
  NewOpportunityLeadsTables,
  NewQualifiedLeadsTables,
  UQLTableMenu,
} from './LeadMenuTables';
import {
  ActiveLeadTable,
  ActivityLeadTable,
  AllLeadTable,
  LostLeadTable,
  NurturableLeadTable,
  RQLTable,
  UQLTable,
  UnsubscribedTable,
} from './LeadTable';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const LeadTableTabView = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const tabs = {
    qualifiedLeadTable: {
      label: 'Active',
      view: (
        <ActiveLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/activeLeads`,
      addOnFilter: filter,
      params: {
        // period: filterParams.period,
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
    },
    // proposalSubmitted: {
    //   label: 'Proposal Submitted',
    //   view: (
    //     <ProposalSubmittedTable filterParams={filterParams} params={params} />
    //   ),
    //   eventSourceId: ['Lead', 'Customer'],
    //   api: `/potentialcustomers/proposalSubmitted`,
    // },
    nurturableTable: {
      label: 'Nurturable',
      view: (
        <NurturableLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/nurturableLeads`,
      // addOnFilter: filter,
      params: {
        // period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    lostTable: {
      label: 'Lost',
      view: (
        <LostLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/lostLeads`,
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    rqlTable: {
      label: 'RQL',
      view: (
        <RQLTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/rql`,
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    unQualifiedLeadTable: {
      label: 'UQL',
      view: (
        <UQLTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/uql`,
      addOnFilter: filter,
      params: {
        // period: filterParams.period,
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    allLeadTable: {
      label: 'All',
      view: (
        <AllLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/allLeads`,
      params: {
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    unsubscribed: {
      label: 'Unsubscribed',
      view: (
        <UnsubscribedTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/unsubscribed`,
      params: {
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    activityLeadTable: {
      label: 'Activity',
      view: (
        <ActivityLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/activeLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },
            {
              type: 'autoComplete',
              label: 'Stage',
              api: '/stages',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'stage',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'UQL Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Acitivity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'date',
              field: 'expected_ql_date',
              label: 'Expected Ql Date',
            },
          ]}
        />,
        <AddButton
          title="Lead"
          view="add-lead"
          params={props?.route?.params}
        />,
        <Button
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ]}
    />
  );
};

export const AllLeadTableTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  params = {...params, ...filterParams};
  const {product, period, parent_task_id} = params;

  let allLeadApi = {
    api: `/potentialcustomers`,
    filter: {
      product: product?._id,
      pipeline_type: 'Prospect',
      sql_stage_date: {
        $gte: period?.from,
        $lte: period?.to,
      },
    },
  };

  if (parent_task_id) {
    allLeadApi = {
      api: `/potentialcustomers/allLeads`,
      filter: {
        parent_task_id: parent_task_id,
      },
    };
  }
  const tabs = {
    allLead: {
      label: 'All Leads',
      view: (
        <AllLeadTable
          searchValue={searchValue}
          filterValues={filterValues}
          allLeadApi={allLeadApi}
        />
      ),
      ...allLeadApi,
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
    />
  );
};

export const NewLeadTabs = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Owner',
        field: 'owner',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Channel',
        field: 'channel',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const tabs = {
    unQualifiedLeadTable: {
      label: 'Lead',
      view: (
        <NewLeadsTables
          {...props}
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      // api: `/potentialcustomers/uql`,
      params: params,
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },

    qualifiedLeadTable: {
      label: 'Qualified Lead',
      view: (
        <NewQualifiedLeadsTables
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      // api: `/potentialcustomers/activeLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
    },
    opportunityLeadTable: {
      label: 'Opportunity Lead',
      view: (
        <NewOpportunityLeadsTables
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      // api: `/potentialcustomers/opportunityLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <AddButton
          title="Lead"
          view="add-lead"
          params={props?.route?.params}
        />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ]}
    />
  );
};

export const ActiveLeadTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Owner',
        field: 'owner',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Channel',
        field: 'channel',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const tabs = {
    unQualifiedLeadTable: {
      label: 'Lead',
      view: (
        <UQLTableMenu
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      api: `/potentialcustomers/uql`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <AddButton
          title="Lead"
          view="add-lead-uql-active"
          params={props?.route?.params}
        />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },

    qualifiedLeadTable: {
      label: 'Qualified Lead',
      view: (
        <ActiveQualifiedLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      api: `/potentialcustomers/activeLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <AddButton title="Lead" view="add-QL" params={props?.route?.params} />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },
    opportunityLeadTable: {
      label: 'Opportunity Lead',
      view: (
        <ActiveOpportunityLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      api: `/potentialcustomers/opportunityLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      // actions={[
      //   <SearchFilter
      //     value={searchValue}
      //     onChangeFilter={onChangeFilter}
      //     placeholder="Search"
      //   />,
      //   <GroupFilter
      //     filterValues={filterValues}
      //     applyFilter={applyFilter}
      //     filters={[
      //       {
      //         type: 'autoComplete',
      //         label: 'Employee Size',
      //         api: '/employeesizes',
      //         placeholder: 'Select',
      //         suggestionField: 'number',
      //         valueField: 'number',
      //         field: 'employee_count',
      //       },
      //       {
      //         type: 'autoComplete',
      //         label: 'Country',
      //         api: '/countries',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'country',
      //       },
      //       {
      //         type: 'autoComplete',
      //         label: 'Sentiment',
      //         suggestionField: 'label',
      //         valueField: 'label',
      //         keyField: 'value',
      //         options: [
      //           {label: 'Positive', value: 'positive'},
      //           {label: 'Neutral', value: 'neutral'},
      //           {label: 'Negative', value: 'negative'},
      //           {label: 'No Response', value: 'no response'},
      //         ],
      //         placeholder: 'Select',
      //         field: 'last_sentiment',
      //       },
      //       {
      //         label: 'Technical Consultant',
      //         field: 'tech_consultant',
      //         type: 'multiAutoComplete',
      //         api: `/employeeSuggestions`,
      //         suggestionField: 'name',
      //         secondarySuggestionField: 'official_email_id',
      //         valueField: 'name',
      //       },
      //       {
      //         label: 'Solution Consultant',
      //         field: 'solution_consultant',
      //         type: 'autoComplete',
      //         api: `/employeeSuggestions`,
      //         suggestionField: 'name',
      //         secondarySuggestionField: 'official_email_id',
      //         valueField: 'name',
      //       },
      //       {
      //         label: 'Business Analyst',
      //         field: 'business_analyst',
      //         type: 'multiAutoComplete',
      //         api: `/employeeSuggestions`,
      //         suggestionField: 'name',
      //         secondarySuggestionField: 'official_email_id',
      //         valueField: 'name',
      //       },
      //       {
      //         label: 'Business Consultant',
      //         field: 'business_consultant',
      //         type: 'multiAutoComplete',
      //         api: `/employeeSuggestions`,
      //         suggestionField: 'name',
      //         secondarySuggestionField: 'official_email_id',
      //         valueField: 'name',
      //       },
      //       {
      //         type: 'autoComplete',
      //         label: 'Industries',
      //         api: '/industries',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'industry',
      //       },
      //       {
      //         type: 'autoComplete',
      //         label: 'Channel',
      //         api: '/types/suggestionsForLead',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'campaign_type',
      //       },
      //       {
      //         label: 'Campaign',
      //         placeholder: 'Select',
      //         field: 'campaign_id',
      //         type: 'autoComplete',
      //         api: '/sourceSuggestions',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         params: ({filter: {campaign_type} = {}}) => {
      //           return {
      //             source: campaign_type,
      //           };
      //         },
      //       },
      //       {
      //         type: 'autoComplete',
      //         label: 'Owner',
      //         api: '/employeeSuggestions',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         secondarySuggestionField: 'official_email_id',
      //         field: 'owner',
      //       },

      //       {
      //         type: 'autoComplete',
      //         label: 'Inside Sales',
      //         api: '/employeeSuggestions',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         secondarySuggestionField: 'official_email_id',
      //         field: 'inside_sales',
      //       },

      //       {
      //         type: 'autoComplete',
      //         label: 'Status',
      //         options: [
      //           'Active',
      //           'DNC',
      //           'Free',
      //           'Lost',
      //           'Nurturable',
      //           'Unqualified',
      //           'Unsubscribe',
      //         ],
      //         placeholder: 'Select',
      //         field: 'lead_status',
      //       },
      //       {
      //         type: 'autoComplete',
      //         label: 'Communication',
      //         placeholder: 'Select',
      //         field: 'last_communication_date',
      //         options: ['Exists', 'Not Exists'],
      //         asParam: true,
      //       },
      //       {
      //         type: 'date',
      //         field: 'sql_date',
      //         asParam: true,
      //         label: 'QL Creation Date',
      //       },
      //       {
      //         type: 'date',
      //         field: 'uql_date',
      //         asParam: true,
      //         label: 'Lead Creation Date',
      //       },
      //       {
      //         type: 'date',
      //         field: 'next_task_date',
      //         asParam: true,
      //         label: 'Next Task Date',
      //       },
      //       {
      //         type: 'date',
      //         field: 'activity_date',
      //         asParam: true,
      //         label: 'Activity Date',
      //       },
      //       {
      //         type: 'date',
      //         field: 'nurturable_date',
      //         asParam: true,
      //         label: 'Nurturable Date',
      //       },
      //       {
      //         type: 'date',
      //         field: 'lost_date',
      //         asParam: true,
      //         label: 'Lost Date',
      //       },
      //     ]}
      //   />,
      //   <AddButton
      //     title="Lead"
      //     view="add-lead"
      //     params={props?.route?.params}
      //   />,
      //   <UploadButton
      //     text="Import Leads"
      //     onPress={() => {
      //       navigation.navigate('import-list', {
      //         source: 'leads',
      //         model: 'PotentialCustomer',
      //       });
      //     }}
      //   />,
      // ]}
    />
  );
};

export const WonLeadTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const tabs = {
    customerLeadTable: {
      label: 'Won',
      view: (
        <ActiveOpportunityToCustomerLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/opportunitytoCustomerLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
          ]}
        />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const ActiveLeadTabsForSolCon = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });
  const {filter = {}, params: filterParams = {}} = filterValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();

  const leadStatusFilter = status => ({
    $or: [
      {solution_consultant: employeeId?._id, lead_status: status},
      {tech_consultant: employeeId?._id, lead_status: status},
    ],
  });

  const tabs = {
    activeLeadTable: {
      label: 'Active',
      filter: {
        ...filter,
        ...leadStatusFilter('Active'),
      },
      view: (
        <ActiveQualifiedLeadTableForSolCon
          searchValue={searchValue}
          filter={{...filter, ...leadStatusFilter('Active')}}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/activeLeads/sol-con`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
    },
    closedLeadTable: {
      label: 'Closed',
      filter: {
        ...filter,
        ...leadStatusFilter('Lost'),
      },
      view: (
        <ActiveQualifiedLeadTableForSolCon
          searchValue={searchValue}
          filter={{...filter, ...leadStatusFilter('Lost')}}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/activeLeads/sol-con`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Business Consultant', //pre sales
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'business_consultant',
            },
            {
              type: 'multiAutoComplete',
              label: 'Tech. Consultant', //sol
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'tech_consultant',
            },
            {
              type: 'multiAutoComplete',
              label: 'Business Analyst', //sol
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'business_analyst',
            },
            {
              type: 'multiAutoComplete',
              label: 'Sol.Consultant', //primary
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'solution_consultant',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
          ]}
        />,
        // <AddButton
        //   title="Lead"
        //   view="add-lead"
        //   params={props?.route?.params}
        // />,
        // <Button
        //   text="Import Leads"
        //   onPress={() => {
        //     navigation.navigate('import-list', {
        //       source: 'leads',
        //       model: 'PotentialCustomer',
        //     });
        //   }}
        // />,
      ]}
    />
  );
};

export const LostLeadTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const tabs = {
    nurturableTable: {
      label: 'Nurturable',
      view: (
        <NurturableLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/nurturableLeads`,
      // addOnFilter: filter,
      params: {
        // period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
    lostTable: {
      label: 'Lost',
      view: (
        <LostLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/lostLeads`,
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },

    rqlTable: {
      label: 'RQL',
      view: (
        <RQLTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: `/potentialcustomers/rql`,
      params: {
        period: filterParams.period,
        ...params,
      },
      addOnFilter: filter,
      eventSourceId: ['Lead', 'communicationAdded'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'UQL Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Acitivity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
          ]}
        />,
        <AddButton
          title="Lead"
          view="add-lead"
          params={props?.route?.params}
        />,
        <Button
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ]}
    />
  );
};

export const AllLeadTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );

  let {filter, params: filterParams = {}} = filterValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Owner',
        field: 'owner',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Channel',
        field: 'channel',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={`Total ${row?.count}`} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });

  const tabs = {
    unQualifiedLeadTable: {
      label: 'All Lead',
      view: (
        <AllLeadTableMenu
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          groupBy={groupBy}
        />
      ),
      api: `/potentialcustomers/allLeads`,
      addOnFilter: filter,
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Organization Category',
              api: '/organizationcategories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization_category',
            },
            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                // 'Nurturable',
                // 'Unqualified',
                // 'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Acitivity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
            {
              type: 'autoComplete',
              label: 'Lost Stage',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Unsubscribed', value: 'Unsubscribed'},
                // {label: 'Nurturable', value: 'Nurturable'},
                {label: 'Rejected', value: 'Rejected'},
              ],
              placeholder: 'Select',
              field: 'lead_sub_stage',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/channels',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'channel',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Employee Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
        <AddButton
          title="Lead"
          view="add-lead"
          params={props?.route?.params}
        />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ]}
    />
  );
};

export const LeadsTabsForFollowUp = props => {
  let params = props?.route?.params;
  const {path} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const tabs = {
    active_lead: {
      label: 'Lead',
      view: (
        <UQLTableMenu
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          path={path}
        />
      ),
      api: `/potentialcustomers/uql`,
      addOnFilter: {
        ...filter,
        next_task_date: getZeroTimeDate(new Date()),
        owner: employeeId?._id,
      },
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
          ]}
        />,
        // <AddButton
        //   title="Lead"
        //   view="add-lead-uql-active"
        //   params={props?.route?.params}
        // />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const QualifiedLeadsTabsForFollowUp = props => {
  let params = props?.route?.params;
  const {path} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const tabs = {
    qualifiedLeadTable: {
      label: 'Qualified Lead',
      view: (
        <ActiveQualifiedLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          path={path}
        />
      ),
      api: `/potentialcustomers/activeLeads`,
      addOnFilter: {
        ...filter,
        next_task_date: getZeroTimeDate(new Date()),
        owner: employeeId?._id,
      },
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
          ]}
        />,

        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OpportunityLeadsTabsForFollowUp = props => {
  let params = props?.route?.params;
  const {path} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const tabs = {
    opportunityLeadTable: {
      label: 'Opportunity Lead',
      view: (
        <ActiveOpportunityLeadTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
          path={path}
        />
      ),
      api: `/potentialcustomers/opportunityLeads`,
      addOnFilter: {
        ...filter,
        next_task_date: getZeroTimeDate(new Date()),
        owner: employeeId?._id,
      },
      params: {
        ...params,
      },
      eventSourceId: ['Lead', 'communicationAdded', 'Customer'],
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee Size',
              api: '/employeesizes',
              placeholder: 'Select',
              suggestionField: 'number',
              valueField: 'number',
              field: 'employee_count',
            },
            {
              type: 'autoComplete',
              label: 'Country',
              api: '/countries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'country',
            },
            {
              type: 'autoComplete',
              label: 'Sentiment',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Positive', value: 'positive'},
                {label: 'Neutral', value: 'neutral'},
                {label: 'Negative', value: 'negative'},
                {label: 'No Response', value: 'no response'},
              ],
              placeholder: 'Select',
              field: 'last_sentiment',
            },
            {
              label: 'Technical Consultant',
              field: 'tech_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Solution Consultant',
              field: 'solution_consultant',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Analyst',
              field: 'business_analyst',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              label: 'Business Consultant',
              field: 'business_consultant',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Industries',
              api: '/industries',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'industry',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },

            {
              type: 'autoComplete',
              label: 'Inside Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'inside_sales',
            },

            {
              type: 'autoComplete',
              label: 'Status',
              options: [
                'Active',
                'DNC',
                'Free',
                'Lost',
                'Nurturable',
                'Unqualified',
                'Unsubscribe',
              ],
              placeholder: 'Select',
              field: 'lead_status',
            },
            {
              type: 'autoComplete',
              label: 'Communication',
              placeholder: 'Select',
              field: 'last_communication_date',
              options: ['Exists', 'Not Exists'],
              asParam: true,
            },
            {
              type: 'date',
              field: 'sql_date',
              asParam: true,
              label: 'QL Creation Date',
            },
            {
              type: 'date',
              field: 'uql_date',
              asParam: true,
              label: 'Lead Creation Date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task Date',
            },
            {
              type: 'date',
              field: 'activity_date',
              asParam: true,
              label: 'Activity Date',
            },
            {
              type: 'date',
              field: 'nurturable_date',
              asParam: true,
              label: 'Nurturable Date',
            },
            {
              type: 'date',
              field: 'lost_date',
              asParam: true,
              label: 'Lost Date',
            },
          ]}
        />,
        <UploadButton
          text="Import Leads"
          onPress={() => {
            navigation.navigate('import-list', {
              source: 'leads',
              model: 'PotentialCustomer',
            });
          }}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
