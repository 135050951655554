import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {
  ExperinceRegrettableAttritionTable,
  PracticeRegrettableAttritionTable,
  ReasonRegrettableAttritionTable,
} from './AttritionTable';

export const RegrettableVoluntaryTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: params.periods || getCurrentMonth(),
    },
  });
  const {params: {period: periods} = {}} = filterValues;
  const tabs = {
    Reason: {
      label: 'Reason-wise',
      view: <ReasonRegrettableAttritionTable {...props} periods={periods} />,
      eventSourceId: 'JobOpeningActive',
    },
    Experience: {
      label: 'Experience-wise',
      view: <ExperinceRegrettableAttritionTable {...props} periods={periods} />,
      eventSourceId: 'JobOpeningHold',
    },
    practice: {
      label: 'Practice-wise',
      view: <PracticeRegrettableAttritionTable {...props} periods={periods} />,
      eventSourceId: 'JobOpeningClosed',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      headerComponent={
        <TableHeader
          title="Regrettable Voluntary Attrition"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
          ]}
        />
      }
      {...props}
    />
  );
};
