import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFetchData} from '../../../controllers/useFetchData';
import NestedTable from '../../../components/form/NestedTable';
export const AiPromptForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, defaultValues: _defaultValues} = params;

  let defaultValues = {};

  if (_defaultValues) {
    defaultValues = {...defaultValues, ..._defaultValues};
  }

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'AI Propmt',
    uri: '/aiprompts',
    ...props,
  });

  let generalFields = [
    {
      label: 'Name',
      placeholder: 'Name',
      type: 'text',
      field: 'name',
      size: 6,
      // required: true,
    },
    {
      label: 'Status',
      type: 'radioGroup',
      field: 'status',
      options: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Archived',
          value: 'archived',
        },
      ],
      size: 6,
    },
    // {
    //   label: 'Desc',
    //   placeholder: 'Desc',
    //   field: 'desc',
    //   type: 'textArea',
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    // {
    //   label: 'Prompt',
    //   placeholder: 'Prompt',
    //   field: 'prompt',
    //   type: 'textArea',
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    // {
    //   label: 'Master Prompt',
    //   placeholder: 'Master Prompt',
    //   field: 'genericPrompt',
    //   type: 'autoComplete',
    //   api: `/masterprompts`,
    //   suggestionField: 'prompt',
    //   valueField: 'prompt',
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    // {
    //   label: 'Revised Propmt',
    //   placeholder: 'Revised Propmt',
    //   field: 'revisedPrompt',
    //   type: 'textArea',
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    // {
    //   label: 'Screen Type',
    //   placeholder: 'Screen Type',
    //   type: 'text',
    //   field: 'screenType',
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    // {
    //   label: 'Type',
    //   placeholder: 'Type',
    //   field: 'type',
    //   type: 'autoComplete',
    //   api: `/types`,
    //   suggestionField: 'name',
    //   valueField: 'name',
    //   filter: {project_id: project?._id},
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    // {
    //   label: 'OutFields',
    //   placeholder: 'OutFields',
    //   type: 'text',
    //   field: 'outFields',
    //   visible: ({values}) => values?.for_crm !== true,
    // },
    {
      label: 'Generate Prompt',
      field: 'generatePrompt',
      nested: true,
      render: props => (
        <NestedTable
          {...props}
          fields={[
            {
              field: 'role',
              placeholder: 'role',
              header: 'Role',
              type: 'text',
            },
            {
              field: 'content',
              placeholder: 'content',
              header: 'Content',
              type: 'textArea',
              inputProps: {
                minRows: 5,
                maxRows: 10,
              },
            },
          ]}
        />
      ),
    },
    // {
    //   label: 'RevisedGenerate Prompt',
    //   field: 'revisedGeneratePrompt',
    //   nested: true,
    //   render: props => (
    //     <NestedTable
    //       {...props}
    //       fields={[
    //         {
    //           field: 'role',
    //           placeholder: 'role',
    //           header: 'Role',
    //           type: 'text',
    //         },
    //         {
    //           field: 'content',
    //           placeholder: 'content',
    //           header: 'Content',
    //           type: 'textArea',
    //           inputProps: {
    //             minRows: 5,
    //             maxRows: 10,
    //           },
    //         },
    //       ]}
    //     />
    //   ),
    //   visible: ({values}) => values?.for_crm !== true,
    // },
  ];

  return (
    <Form
      fields={{
        name: 1,
        outFields: 1,
        prompt: 1,
        type: 1,
        generatePrompt: 1,
        screenType: 1,
        for_crm: 1,
      }}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitAction="Save"
      layoutFields={[...generalFields]}
      {...props}
    />
  );
};

export const AiPromptViewForm = props => {
  return <AiPromptForm header="Add Prompt" {...props} />;
};

export const UpdateAiPromptForm = props => {
  const {
    route: {params},
  } = props;
  let {project, row} = params;
  const {data} = useFetchData({
    api: '/aiprompts',
    filter: {
      _id: row?._id,
    },
    only: true,
    fields: {
      name: 1,
      desc: 1,
      prompt: 1,
      apis: 1,
      // populate: 1,
      outFields: 1,
      type: {name: 1},
      generatePrompt: {role: 1, content: 1},
      revisedPrompt: 1,
      revisedGeneratePrompt: {role: 1, content: 1},
      genericPrompt: {prompt: 1},
      screenType: 1,
      for_crm: 1,
      status: 1,
    },
  });

  if (!data) {
    return null;
  }
  return (
    <AiPromptForm header={'Update Prompt'} defaultValues={data} {...props} />
  );
};
