import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UserRoleForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/userroles',
    eventSourceId: 'userRoleAdded',
    ...props,
  });

  return (
    <Form
      api={`/userroles/${row?._id}`}
      fields={{
        app_role_id: {display_label: 1},
        user_id: {name: 1},
      }}
      onSubmit={onSubmit}
      header={{title: 'Add App Role'}}
      layoutFields={[
        {
          label: 'App Role',
          type: 'autoComplete',
          field: 'app_role_id',
          api: '/approles',
          suggestionField: 'display_label',
          valueField: 'display_label',
        },
        {
          label: 'User',
          type: 'autoComplete',
          field: 'user_id',
          api: '/users',
          filter: {
            status: 'a',
          },
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditUserRoleForm = props => {
  return (
    <UserRoleForm header={{title: 'Edit User Role'}} mode="edit" {...props} />
  );
};
