import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateFeatureStageForm = props => {
  const {
    route: {params},
  } = props;
  const {project_id, row, stage} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Feature',
    uri: `/projectfeatures/${row._id}`,
    method: 'put',
    ...props,
  });

  const stageMap = {
    development: 'active',
    qa: 'qa',
    production: 'released',
  };

  return (
    <Form
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            status:
              stageMap[data.status.environment] || data.status.environment,
          },
        };
      }}
      header={{title: 'Update Stage', secondaryTitle: row?.feature}}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Stage',
          field: 'status',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          keyField: 'value',
          suggestionField: 'label',
          valueField: 'label',
          api: '/projectEnvironments',
          filter: {
            project_id,
            status: 'active',
            environment: {$ne: stage},
          },
          fields: {
            environment: 1,
            label: 1,
          },
        },
      ]}
      {...props}
    />
  );
};
