import React from 'react';
import {LeftMenuView} from './LeftMenuView';
const Screen = () => {};
const Group = () => {};
const Section = () => {};
const canAccess = ({dataNavigatorId, dataResourceId, _canAccess}) => {
  if (!dataNavigatorId || !_canAccess) {
    return true;
  }

  const accessible = _canAccess({
    navigator: dataNavigatorId,
    resource: dataResourceId,
  });

  return accessible;
};
const getMenusFromChildren = ({
  accessLevel,
  children,
  dataNavigatorId: parentNavigatorId,
  canAccess: _canAccess,
  props,
}) => {
  let canAccessNavigator = canAccess({accessLevel, _canAccess});

  if (!canAccessNavigator) {
    return [];
  }

  let topLevelMenus = [];
  React.Children.map(children, screen => {
    if (!screen) {
      return;
    }
    let {
      options,
      component: Component,
      name,
      children: screenChildren,
      props: screenProps,
    } = screen.props;
    const dataNavigatorId =
      screen.props['data-navigator-id'] || parentNavigatorId;
    const dataResourceId = screen.props['data-resource-id'];

    if (screen.type === Group) {
      const groupMenus = getMenusFromChildren({
        children: screenChildren,
        dataNavigatorId,
        canAccess: _canAccess,
        props,
      });

      if (groupMenus && groupMenus.length) {
        topLevelMenus.push(groupMenus);
      }
    } else if (screen.type === Section) {
      const canAccessGroupNavigator = canAccess({
        dataNavigatorId,
        _canAccess,
      });

      if (!canAccessGroupNavigator) {
        return null;
      }
      const children = (Component && Component(props)) || screenChildren;

      const sectionMenus = getMenusFromChildren({
        children,
        dataNavigatorId,
        canAccess: _canAccess,
        props,
      });

      if (sectionMenus && sectionMenus.length) {
        topLevelMenus.push({...options, menus: sectionMenus, section: true});
      }

      Component = null;
    } else if (screen.type === Navigator) {
      const canAccessNavigator = canAccess({
        dataNavigatorId,

        _canAccess,
      });
      if (!canAccessNavigator) {
        return null;
      }
      const navigatorMenus = getMenusFromChildren({
        children: screenChildren,
        dataNavigatorId,
        canAccess: _canAccess,
        props,
      });
      if (navigatorMenus && navigatorMenus.length) {
        topLevelMenus.push(...navigatorMenus);
      }
    } else if (screen.type === Screen) {
      const canAccessScreen = canAccess({
        dataNavigatorId,
        dataResourceId,
        _canAccess,
      });
      if (!canAccessScreen) {
        return null;
      }

      topLevelMenus.push({
        view: Component ? <Component {...screenProps} /> : undefined,
        id: name,
        ...options,
      });
    }
  });

  return topLevelMenus;
};
const Navigator = props => {
  let {name, menus, children, options, canAccess, accessLevel, ...rest} = props;
  const dataNavigatorId = props['data-navigator-id'];
  const newMenus = getMenusFromChildren({
    dataNavigatorId,
    children,
    canAccess,
    props: rest,
  });
  if (!newMenus?.length) {
    return null;
  }
  return <LeftMenuView {...options} {...rest} menus={newMenus} />;
};

export const createMenuNavigator = () => {
  return {
    Screen,
    Navigator,
    Group,
    Section,
  };
};
