import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {FormErrorConfirmWrapper} from '../../../components/form/FormWrapper';
import {useAppStateContext} from '../../../providers/AppState';

const resourceAssignmentComputations = fetch => ({
  addProjectModuleOwner: {
    compute: async value => {
      if (value?.project_id?._id) {
        const {data: projectModuleData = []} = await fetch({
          uri: '/projectmodules',
          props: {
            filter: {
              project_id: value?.project_id?._id,
            },
            fields: {_id: 1, owner_id: {_id: 1, name: 1}},
          },
        });
        console.log('projectModuleData', projectModuleData);
        // return ;
      }
    },
    dependencies: ['project_id'],
  },
});

export const OrderResourceAssignmentForm = props => {
  const {
    route: {params},
  } = props;
  const {order_allocations = {}, order_resource_request} = params;
  let incentiveOptions = [];
  let incentiveModelDefaultValue = {};
  if (
    order_allocations?.new_project_type == 'staffaugmentation' ||
    order_allocations?.new_project_type == 'managedteam'
  ) {
    incentiveOptions = [
      {
        label: 'Reporting To',
        value: 'Reporting To',
      },
      {
        label: 'Sharing With PM',
        value: 'Sharing With PM',
      },
      {
        label: 'Pay To Skill Manager',
        value: 'Pay To Skill Manager',
      },
      {label: 'No Mentorship', value: 'No Mentorship'},
    ];
    incentiveModelDefaultValue = {
      incentive_mode: 'Reporting To',
    };
  }
  if (
    order_allocations?.new_project_type == 'sdlc' ||
    order_allocations?.new_project_type == 'sdlcplus'
  ) {
    incentiveOptions = [
      {
        label: 'Pay To Module Lead',
        value: 'Pay To Module Lead',
      },
      {
        label: 'Sharing With Skill Manager',
        value: 'Sharing With Skill Manager',
      },
      {
        label: 'Pay To Skill Manager',
        value: 'Pay To Skill Manager Sdlc',
      },
      {label: 'No Mentorship', value: 'No Mentorship'},
    ];
    incentiveModelDefaultValue = {
      incentive_mode: 'Pay To Module Lead',
    };
  }

  let {onSubmit} = useFormSubmit({
    uri: '/assign/orderResourceRequirement',
    eventSourceId: 'employeeAssigned',
  });
  const {fetch} = useAppStateContext();
  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        onSubmit={onSubmit}
        computations={{...resourceAssignmentComputations(fetch)}}
        submitAction={'Save'}
        header={{
          title: 'Allocate Resource',
          secondaryTitle: order_allocations.order?.order_number,
        }}
        defaultValues={{
          skill_metrics: order_allocations?.skill_required?._id,
          order: order_allocations.order?._id,
          product: order_allocations.product?._id,
          skill_requirement: order_allocations?.skill_requirement,
          from_date: order_allocations?.order_date,
          to_date: order_allocations?.recurring_till,
          hours: order_allocations?.employee_per_day_working_hour,
          project_id: order_allocations?.project,
          status: 'Assigned',
          tool: order_allocations?.tool,
          order_resource_request,
          is_billable: true,
          is_locomo: true,
          role: 'IC',
          ...incentiveModelDefaultValue,
        }}
        layoutFields={[
          {
            label: 'Role',
            type: 'radioGroup',
            field: 'role',
            options: ['IC', 'Manager'],
            onChangeValue: (value, e, {setFieldValue}) => {
              if (value == 'Manager') {
                setFieldValue('is_manager', true);
              } else {
                setFieldValue('is_manager', void 0);
              }
            },
            required: true,
            size: 4,
          },
          {
            label: 'Billable',
            type: 'switch',
            field: 'is_billable',
            size: 4,
          },
          {
            label: 'Is fornt facing?',
            type: 'switch',
            field: 'is_front_facing_shadow',
            size: 4,
          },
          {
            label: 'Resource',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
            fields: {
              _id: 1,
              name: 1,
              official_email_id: 1,
            },
            onChangeValue: (value, e, {setFieldValue}) => {
              setFieldValue('front_facing_resource', {
                _id: value?._id,
                name: value?.name,
              });
            },
          },
          {
            label: 'Front Facing Resource',
            field: 'front_facing_resource',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            required: true,
            visible: ({values = {}}) => values?.is_front_facing_shadow,
            size: 6,
          },
          {
            fields: [
              {
                label: 'Start Date',
                placeholder: 'Start Date',
                field: 'from_date',
                type: 'date',
                required: true,
                size: 4,
              },
              {
                label: 'End Date',
                placeholder: 'End Date',
                field: 'to_date',
                type: 'date',
                required: true,
                size: 4,
              },
              {
                label: 'Hours',
                placeholder: 'Hours',
                field: 'hours',
                type: 'number',
                required: true,
                size: 4,
              },
            ],
          },
          {
            fields: [
              {
                label: 'Project',
                placeholder: 'Project',
                field: 'project_id',
                type: 'autoComplete',
                api: `/projects`,
                filter: {
                  project_status: 'a',
                },
                suggestionField: 'project',
                valueField: 'project',
                required: true,
                size: 12,
              },
            ],
          },
          {
            fields: [
              {
                label: 'Project Skill',
                placeholder: 'Project Skill',
                field: 'tool',
                type: 'autoComplete',
                api: `/tools`,
                filter: {
                  status: 'active',
                },
                suggestionField: 'name',
                valueField: 'name',
                required: true,
                size: 6,
              },
              {
                label: 'Resource Skill',
                placeholder: 'Resource Skill',
                field: 'resource_tool',
                type: 'autoComplete',
                api: `/tools`,
                filter: {
                  status: 'active',
                },
                suggestionField: 'name',
                valueField: 'name',
                required: true,
                size: 6,
              },
            ],
          },
          // {
          //   label: 'Anchor Resource',
          //   placeholder: 'Anchor Resource',
          //   field: 'anchor_resource',
          //   type: 'autoComplete',
          //   api: `/employeeSuggestions`,
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   size: 6,
          // },
          // {
          //   label: 'Tech Lead',
          //   placeholder: 'Tech Lead',
          //   field: 'tech_lead',
          //   type: 'autoComplete',
          //   api: `/employeeSuggestions`,
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   size: 6,
          // },
          // {
          //   label: 'Delivery Owner',
          //   placeholder: 'Delivery Owner',
          //   field: 'delivery_owner',
          //   type: 'autoComplete',
          //   api: `/employeeSuggestions`,
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   size: 6,
          // },
          {
            type: 'switch',
            label: 'Shift allowance',
            field: 'applicable_for_daily_incentive',
            size: 6,
          },
          {
            type: 'number',
            label: 'Amount',
            field: 'daily_incentive',
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            required: true,
            size: 6,
          },
          {
            groups: [
              {
                defaultHidden: true,
                label: 'Leader',
                fields: [
                  {
                    label: 'Skill Leader',
                    placeholder: 'Skill Leader',
                    field: 'level_one_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                  {
                    label: 'Project Owner',
                    placeholder: 'Project Owner',
                    field: 'level_two_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                  {
                    label: 'Level 3 Owner',
                    placeholder: 'Level 3 Owner',
                    field: 'level_three_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                ],
              },
              {
                defaultHidden: true,
                label: 'Incentive Mode',
                fields: [
                  {
                    label: 'Incentive Mode',
                    type: 'radioGroup',
                    field: 'incentive_mode',
                    options: incentiveOptions,
                    required: true,
                  },
                  {
                    label: 'Incentive Shared With',
                    placeholder: 'Incentive Shared With',
                    field: 'incentive_shared_with',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    visible: ({values = {}}) =>
                      values?.incentive_mode === 'Sharing With PM' ||
                      values?.incentive_mode === 'Pay To Skill Manager Sdlc' ||
                      values?.incentive_mode === 'Pay To Skill Manager' ||
                      values?.incentive_mode === 'Sharing With Skill Manager',
                    required: true,
                  },
                  {
                    type: 'switch',
                    label: 'Is Self/Front Facing Not Allow',
                    field: 'self_front_facing_not_allow',
                  },
                ],
              },
              // {
              //   defaultHidden: true,
              //   label: 'Project',
              //   fields: [
              //     {
              //       label: 'Project',
              //       placeholder: 'Project',
              //       field: 'project_id',
              //       type: 'autoComplete',
              //       api: `/projects`,
              //       suggestionField: 'project',
              //       valueField: 'project',
              //       size: 4,
              //     },
              //   ],
              // },
            ],
          },
        ]}
        {...props}
      />
    </FormErrorConfirmWrapper>
  );
};

//employee allocation form from request list
export const ProjectOrderResourceAssignmentForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}, OrderResourceRequest = {}} = params;
  let incentiveOptions = [];
  let incentiveModelDefaultValue = {};
  if (
    OrderResourceRequest?.project?.new_project_type == 'staffaugmentation' ||
    OrderResourceRequest?.project?.new_project_type == 'managedteam'
  ) {
    incentiveOptions = [
      {
        label: 'Reporting To',
        value: 'Reporting To',
      },
      {
        label: 'Sharing With PM',
        value: 'Sharing With PM',
      },
      {
        label: 'Pay To Skill Manager',
        value: 'Pay To Skill Manager',
      },
      {label: 'No Mentorship', value: 'No Mentorship'},
    ];
    incentiveModelDefaultValue = {
      incentive_mode: 'Reporting To',
    };
  }
  if (
    OrderResourceRequest?.project?.new_project_type == 'sdlc' ||
    OrderResourceRequest?.project?.new_project_type == 'sdlcplus'
  ) {
    incentiveOptions = [
      {
        label: 'Pay To Module Lead',
        value: 'Pay To Module Lead',
      },
      {
        label: 'Sharing With Skill Manager',
        value: 'Sharing With Skill Manager',
      },
    ];
    incentiveModelDefaultValue = {
      incentive_mode: 'Pay To Module Lead',
    };
  }

  let {onSubmit} = useFormSubmit({
    uri: '/assign/orderResourceRequirement',
    eventSourceId: 'employeeAssigned',
  });
  const {fetch} = useAppStateContext();
  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        onSubmit={onSubmit}
        computations={{...resourceAssignmentComputations(fetch)}}
        submitAction={'Save'}
        header={{
          title: 'Allocate Resource',
          secondaryTitle: row?.assigned_to?.name,
        }}
        defaultValues={{
          employee: row?.assigned_to?._id,
          order: OrderResourceRequest.order?._id,
          product: OrderResourceRequest.order?.product?._id,
          project_id: OrderResourceRequest?.project,
          skill_requirement: OrderResourceRequest?.skill_requirement,
          from_date: OrderResourceRequest?.from_date,
          to_date: OrderResourceRequest?.to_date,
          hours: OrderResourceRequest?.hours,
          status: 'Assigned',
          tool: OrderResourceRequest?.tool,
          resource_tool: row?.assigned_to?.resource_language,
          order_resource_request: OrderResourceRequest?._id,
          level_one_owner: row?.assigned_to?.skill_leader,
          level_two_owner: OrderResourceRequest?.project?.owner_id,
          milestone_id: OrderResourceRequest?.milestone_id,
          is_billable: true,
          is_locomo: true,
          role: 'IC',
          ...incentiveModelDefaultValue,
        }}
        layoutFields={[
          {
            label: 'Role',
            type: 'radioGroup',
            field: 'role',
            options: ['IC', 'Manager'],
            onChangeValue: (value, e, {setFieldValue}) => {
              if (value == 'Manager') {
                setFieldValue('is_manager', true);
              } else {
                setFieldValue('is_manager', void 0);
              }
            },
            required: true,
            size: 4,
          },
          {
            label: 'Billable',
            type: 'switch',
            field: 'is_billable',
            onChangeValue: (value, e, {setFieldValue}) => {
              if (!value) {
                setFieldValue('is_shadow', true);
              } else {
                setFieldValue('is_shadow', void 0);
                setFieldValue('shadow_type', void 0);
              }
            },
            size: 4,
          },
          {
            label: 'Shadow',
            type: 'radioGroup',
            field: 'shadow_type',
            options: [
              {label: 'Fixed', value: 'fixed'},
              {label: 'Learning', value: 'learning'},
            ],
            required: true,
            size: 4,
            visible: ({values = {}}) => !values?.is_billable,
          },
          {
            label: 'Is fornt facing?',
            type: 'switch',
            field: 'is_front_facing_shadow',
            onChangeValue: (value, e, {setFieldValue}) => {
              if (!value) {
                setFieldValue('front_facing_resource', row?.assigned_to?._id);
              } else {
                setFieldValue('front_facing_resource', void 0);
              }
            },
            size: 4,
          },
          {
            label: 'Start Date',
            placeholder: 'Start Date',
            field: 'from_date',
            type: 'date',
            required: true,
            size: 5,
          },
          {
            label: 'End Date',
            placeholder: 'End Date',
            field: 'to_date',
            type: 'date',
            required: true,
            size: 5,
          },
          {
            label: 'Hours',
            placeholder: 'Hours',
            field: 'hours',
            type: 'number',
            required: true,
            size: 2,
          },
          {
            label: 'Resource Skill',
            placeholder: 'Resource Skill',
            field: 'resource_tool',
            type: 'autoComplete',
            api: `/tools`,
            filter: {
              status: 'active',
            },
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            size: 6,
          },
          {
            label: 'Project Skill',
            placeholder: 'Project Skill',
            field: 'tool',
            type: 'autoComplete',
            api: `/tools`,
            filter: {
              status: 'active',
            },
            suggestionField: 'name',
            valueField: 'name',
            required: true,
            size: 6,
          },
          {
            label: 'Front Facing Resource',
            field: 'front_facing_resource',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            required: true,
            visible: ({values = {}}) => values?.is_front_facing_shadow,
          },
          // {
          //   type: 'switch',
          //   label: 'Allow More Than 9 Hours',
          //   field: 'allow_allocation_more_than_100',
          //   size: 6,
          // },
          // {
          //   label: 'Anchor Resource',
          //   placeholder: 'Anchor Resource',
          //   field: 'anchor_resource',
          //   type: 'autoComplete',
          //   api: `/employeeSuggestions`,
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   size: 6,
          // },
          // {
          //   label: 'Tech Lead',
          //   placeholder: 'Tech Lead',
          //   field: 'tech_lead',
          //   type: 'autoComplete',
          //   api: `/employeeSuggestions`,
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   size: 6,
          // },
          // {
          //   label: 'Delivery Owner',
          //   placeholder: 'Delivery Owner',
          //   field: 'delivery_owner',
          //   type: 'autoComplete',
          //   api: `/employeeSuggestions`,
          //   suggestionField: 'name',
          //   secondarySuggestionField: 'official_email_id',
          //   valueField: 'name',
          //   size: 6,
          // },
          {
            type: 'switch',
            label: 'Shift allowance',
            field: 'applicable_for_daily_incentive',
            size: 6,
          },
          {
            type: 'number',
            label: 'Amount',
            field: 'daily_incentive',
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            required: true,
            size: 6,
          },
          {
            groups: [
              {
                defaultHidden: true,
                label: 'Leader',
                fields: [
                  {
                    label: 'Skill Leader',
                    placeholder: 'Skill Leader',
                    field: 'level_one_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                  {
                    label: 'Project Owner',
                    placeholder: 'Project Owner',
                    field: 'level_two_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                  {
                    label: 'Level 3 Owner',
                    placeholder: 'Level 3 Owner',
                    field: 'level_three_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 6,
                  },
                ],
              },
              {
                defaultHidden: true,
                label: 'Project',
                fields: [
                  {
                    label: 'Project',
                    placeholder: 'Project',
                    field: 'project_id',
                    type: 'autoComplete',
                    api: `/projects`,
                    suggestionField: 'project',
                    valueField: 'project',
                    size: 4,
                  },
                ],
              },
              {
                defaultHidden: true,
                label: 'Incentive Mode',
                fields: [
                  {
                    label: 'Incentive Mode',
                    type: 'radioGroup',
                    field: 'incentive_mode',
                    options: incentiveOptions,
                    required: true,
                  },
                  {
                    label: 'Incentive Shared With',
                    placeholder: 'Incentive Shared With',
                    field: 'incentive_shared_with',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    visible: ({values = {}}) =>
                      values?.incentive_mode === 'Sharing With PM' ||
                      values?.incentive_mode === 'Pay To Skill Manager' ||
                      values?.incentive_mode === 'Sharing With Skill Manager',
                    required: true,
                  },
                ],
              },
            ],
          },
        ]}
        {...props}
      />
    </FormErrorConfirmWrapper>
  );
};
