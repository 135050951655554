import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';

export const LedgerDownload = props => {
  const {route: {params} = {}, navigation} = props;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['LedgerDownloadForm'],
  });
  return (
    <Form
      onSubmit={data => {
        invoke({
          uri: '/getAllLeadgerExcel',
          props: {addOnFilter: {...data}},
        });
      }}
      defaultValues={{
        isExcel: true,
      }}
      submitAction="saveAndClose"
      layoutFields={[
        {
          type: 'multiAutoComplete',
          label: 'Organization',
          api: '/organizations',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'organization',
          size: 6,
          required: true,
        },
        {
          type: 'date',
          label: 'From Date',
          field: 'from_date',
          required: true,
        },
        {
          type: 'date',
          label: 'To Date',
          field: 'to_date',
          required: true,
        },
        {
          type: 'multiAutoComplete',
          field: 'account',
          api: '/accounts',
          suggestionField: 'name',
          valueField: 'name',
          label: 'Account',
        },
        {
          type: 'multiAutoComplete',
          label: 'Entity',
          api: '/getentities',
          suggestionField: 'name',
          valueField: 'name',
          field: 'entity',
        },
        {
          type: 'radioGroup',
          label: 'Download Type',
          field: 'isExcel',
          required: true,
          options: [
            {
              label: 'Excel',
              value: true,
            },
            {
              label: 'Pdf',
              value: false,
            },
          ],
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddLedgerDownload = props => {
  return <LedgerDownload header="Download Ledger" {...props} />;
};
