import React from 'react';
import {Form} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';

export const ImportModuleForm = props => {
  const {
    route: {params},
  } = props;
  const {project_id} = params;

  let {onSubmit} = useFormSubmit({
    uri: `/project/${project_id}/import-module`,
    eventSourceId: 'Module',
  });
  return (
    <Form
      header="Import Module"
      submitAction={'Save'}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Module',
          field: 'modules',
          type: 'multiAutoComplete',
          api: `/projectModules`,
          filter: {is_published: true},
          suggestionField: 'module',
          valueField: 'module',
          required: true,
        },
      ]}
    />
  );
};
