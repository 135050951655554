import React from 'react';
import {Col, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {FontThemeStyles} from '../cards/FontViewTheme';
import {TabView} from '../../../components/tab';
import {FontNameColumn} from '../cellrenders/FontThemeComponent';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';
import {useNavigation} from '@react-navigation/native';
import {useInvoke} from '../../../controllers/useInvoke';
import {Tag} from '../../../components/chip/Chip';
import {PROJECT_THEME_TYPE_ID} from '../../common/constants/SourceConstants';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

export const fontsColumns = [
  {
    header: 'Name',
    render: FontNameColumn,
  },
  {
    header: 'Font Family',
    field: 'fontFamily',
    type: 'text',
    width: 150,
  },
  {
    header: 'Font Size',
    field: 'fontSize',
    type: 'number',
    width: 90,
  },
  {
    header: 'Line Height',
    field: 'lineHeight',
    type: 'number',
    width: 90,
  },
  {
    header: 'Letter Space %',
    field: 'letterSpacing',
    type: 'number',
    width: 120,
  },
  {
    header: 'Font Weight',
    field: 'fontWeight',
    type: 'number',
    width: 90,
  },
  {
    width: 120,
    field: 'updated',
    align: 'center',
    render: () => {
      return <Tag value={'Updated'} />;
    },
  },
];

const FontTable = ({
  fonts,
  readOnly,
  themeAssetVersion,
  defaultFonts,
  feature,
  ...props
}) => {
  const navigation = useNavigation();
  const deleteInvoke = useInvoke({method: 'put', eventSourceId: 'fonts'});

  return (
    <Table
      eventSourceId={'fonts'}
      onRowPress={props => {
        const {row} = props;
        let font;
        defaultFonts.forEach(_font => {
          if (_font?._id === row?._id) font = _font;
        });
        !readOnly &&
          font &&
          navigation.navigate('edit-font', {
            font,
            row,
            themeAssetVersion,
            feature,
          });
      }}
      data={fonts}
      columns={fontsColumns}
      moreActions={({row}) => [
        {
          visible: !readOnly,
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
          },
          onPress: () => {
            let font;
            defaultFonts.forEach(_font => {
              if (_font?._id === row?._id) font = _font;
            });
            deleteInvoke({
              uri: `/projectAssetVersions/${themeAssetVersion?.versionId}/fonts/${row?._id}/delete`,
              props: {font, feature: {_id: feature?._id}},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const FontView = ({
  fonts,
  deviceTypes = [],
  primaryDeviceType,
  ...props
}) => {
  let desktopFonts = [];
  let mobileFonts = [];
  let tabletFonts = [];

  fonts?.forEach((font, index) => {
    desktopFonts[index] = {
      fontName: font?.font_name,
      fontSize: font?.font_size,
      lineHeight: font?.line_height,
      letterSpacing: font?.letter_spacing,
      fontWeight: font?.font_weight,
      italic: font?.italic,
      fontFamily: font?.font_family,
      _id: font?._id,
      updated: font?.updated,
    };
    mobileFonts[index] = {
      fontName: font?.font_name,
      fontSize: font?.mobile_font_size,
      lineHeight: font?.mobile_line_height,
      letterSpacing: font?.mobile_letter_spacing,
      fontWeight: font?.mobile_font_weight,
      italic: font?.mobile_italic,
      fontFamily: font?.mobile_font_family,
      _id: font?._id,
      updated: font?.updated,
    };
    tabletFonts[index] = {
      fontName: font?.font_name,
      fontSize: font?.tablet_font_size,
      lineHeight: font?.tablet_line_height,
      letterSpacing: font?.tablet_letter_spacing,
      fontWeight: font?.tablet_font_weight,
      italic: font?.tablet_italic,
      fontFamily: font?.tablet_font_family,
      _id: font?._id,
      updated: font?.updated,
    };
  });

  const {mainContainer, titleText, descriptionText} =
    useStyles(FontThemeStyles);

  const tabs = {};
  if (!deviceTypes.length || deviceTypes.includes('Mobile')) {
    tabs.mobileFonts = {
      label:
        primaryDeviceType && primaryDeviceType === 'Mobile'
          ? 'Mobile*'
          : 'Mobile',
      view: <FontTable defaultFonts={fonts} fonts={mobileFonts} {...props} />,
    };
  }
  if (!deviceTypes.length || deviceTypes.includes('Tab')) {
    tabs.tabletFonts = {
      label:
        primaryDeviceType && primaryDeviceType === 'Tab' ? 'Tablet*' : 'Tablet',
      view: <FontTable defaultFonts={fonts} fonts={tabletFonts} {...props} />,
    };
  }
  if (!deviceTypes.length || deviceTypes.includes('Desktop')) {
    tabs.desktopFonts = {
      label:
        primaryDeviceType && primaryDeviceType === 'Desktop'
          ? 'Desktop*'
          : 'Desktop',
      view: <FontTable defaultFonts={fonts} fonts={desktopFonts} {...props} />,
    };
  }
  return (
    <Col gap={20} style={mainContainer}>
      <View>
        <Text style={titleText}>Typography and fonts</Text>
        <Text
          style={
            descriptionText
          }>{`You can set the font size & style for each platform (screensize) types so that your styles are responsive`}</Text>
      </View>
      <TabView tabItemWidth={150} tabs={tabs} />
    </Col>
  );
};

export const FeatureFontTheme = ({route: {params}}) => {
  const {readOnly, project, feature} = params;

  const {deviceTypes, primaryDeviceType} = project;
  const {data} = useFetchData({
    api: `/features/${feature?._id}/assets/${PROJECT_THEME_TYPE_ID}/asset-data`,
    eventSourceId: ['fonts'],
  });

  return (
    <>
      <TableHeader
        title={'Fonts'}
        actions={[
          <AddButton
            title="Add Font"
            view={'add-font'}
            params={{...params, row: data?.[0], feature}}
          />,
        ]}
      />
      <FontView
        deviceTypes={deviceTypes}
        primaryDeviceType={primaryDeviceType}
        readOnly={readOnly}
        fonts={data?.[0]?.fonts}
        themeAssetVersion={data?.[0]}
        feature={feature}
      />
    </>
  );
};
