import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import NestedTable from '../../../components/form/NestedTable';
import {EMAIL_CAMPAIGN_TYPE_ID} from '../../common/constants/SourceConstants';

const previewComputations = post => ({
  set_preview_data: {
    multi: true,
    compute: async value => {
      const {data = []} = await post({
        uri: '/importhistory/preview',
        props: {
          ...value,
        },
      });

      let maxKeysObj = data.reduce((max, obj) => {
        return Object.keys(obj).length > Object.keys(max).length ? obj : max;
      }, {});

      let keys = Object.keys(maxKeysObj);

      let nestedFields = [];
      for (let item of keys) {
        nestedFields.push({
          header: item,
          field: item,
          label: item,
          type: 'text',
        });
      }

      return {
        preview: {data, nestedFields},
      };
    },
    dependencies: ['mapping_id'],
  },
});
export const ImportHistoryLogPreviewForm = props => {
  const {post} = useAppStateContext();
  const {
    route: {params},
  } = props;
  const {source, model, emailProspect = false} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/ImportHistoryLogs',
    eventSourceId: 'reloadImportHistoryLogs',
    ...props,
  });

  let defaultValues = {
    importDate: getZeroTimeDate(new Date()),
    source,
    model,
  };
  let layoutFields = [
    {
      placeholder: 'File',
      field: 'file',
      type: 'file',
      size: 6,
    },
    {
      label: 'Mapping',
      placeholder: 'mapping',
      field: 'mapping_id',
      type: 'autoComplete',
      api: `/importmappings`,
      suggestionField: 'name',
      valueField: 'name',
      required: true,
    },
    {
      label: 'Preview',
      field: 'preview',
      nested: true,
      render: props => {
        let {value: {data = [], nestedFields = []} = {}} = props || {};
        return <NestedTable {...props} fields={nestedFields} value={data} />;
      },
      visible: ({values}) =>
        values?.preview?.data && values?.preview?.data.length,
    },
  ];

  if (emailProspect) {
    defaultValues = {
      ...defaultValues,
      campaign_type: EMAIL_CAMPAIGN_TYPE_ID,
    };
    layoutFields.push({
      label: 'Campaign name',
      field: 'campaign_id',
      type: 'autoComplete',
      api: `/campaigns`,
      suggestionField: 'name',
      valueField: 'name',
      filter: {
        status: {$in: ['Confirmed', 'In Experiment']},
      },
    });
  }

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      header={'Import'}
      layout="ImportLeads"
      submitAction={'Upload'}
      defaultValues={defaultValues}
      computations={{...previewComputations(post)}}
      layoutFields={layoutFields}
    />
  );
};
