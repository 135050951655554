import React, {useState} from 'react';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {BorderLessButton} from '../../../../components/button/AddButton';
import {DataEntityDetailForm} from './DataEntityForm';
import {SplitScreen} from '../../../../components/SplitScreen';
import {
  AssetColumns,
  DataEntityExportActions,
  ToggleGraphView,
  assetFilters,
} from './AssetUtility';
import {useAssetActions} from './AssetUtility';
import {useFetchData} from '../../../../controllers/useFetchData';
import {
  PROJECT_MODEL_IMPORT_SAMPLE_ID,
  PROJECT_MODEL_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {AllDataEntitiesScreen} from './FeatureDataEntitiesScreen';
import {TabView} from '../../../../components/tab';
import {AITable} from '../../../../components/table/AITable';
import {AISuggestionRender} from '../../cellrenders/AISuggestionRenders';
import {useTheme} from '@unthinkable/react-theme';
import {DataEntityGraphView} from '../../../../components/graphflow/DataEntityGraphView';
import {GraphHeader} from '../../headers/Header';
import {
  GraphExportActions,
  GraphMoreActions,
} from '../../../../app-components/renders/GraphActions';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupBy} from '../../../../components/table/GroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {Table} from '../../../../components/table/Table';
import {useAppStateContext} from '../../../../providers/AppState';
import { useToast } from '@unthinkable/react-toast';

export const dataEntityExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';

  return {
    addOnFilter,
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: 'Model',
      columns: [
        {header: 'Model', key: 'model', width: 15},
        {header: 'Module', key: 'module_id.module', width: 20},
        {header: 'Owner', key: 'owner_id.name', width: 20},
        {header: 'Description', key: 'desc', width: 40},
        {header: 'Remarks', key: remarksKey, width: 40, isHtml: true},
      ],
    },
  };
};

const DataEntityHeader = ({
  params,
  navigation,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  excelParams,
  selectedIds,
  groupBy,
}) => {
  const {project, module_id} = params;

  const filters = assetFilters({project});
  const {icons} = useTheme();

  return (
    <TableHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[!module_id && filters.module, filters.owner]}
        />,
        !module_id && <GroupBy {...groupBy} />,
        <BorderLessButton
          onPress={() => {
            navigation.navigate('project-import-data', {
              ...params,
              source: PROJECT_MODEL_TYPE_ID,
              sampleId: PROJECT_MODEL_IMPORT_SAMPLE_ID,
            });
          }}
          text={'Import'}
          icon={icons.DownloadBlue}
        />,
        <DataEntityExportActions
          selectedIds={selectedIds}
          params={excelParams}
        />,
      ]}
    />
  );
};
const DataEntityGraphHeader = ({
  params,
  navigation,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  ...props
}) => {
  const {project, module_id} = params;
  const filters = assetFilters({project});
  const {download} = useAppStateContext();
  const toast = useToast();

  return (
    <GraphHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[!module_id && filters.module, filters.owner]}
        />,
        <GraphExportActions
          actions={[
            {
              title: 'Export in JSON',
              onPress: () => {
                download({
                  uri: `/generateModelJson`,
                  props: props?.excelParams,
                });
                toast({message: 'Exporting...'});
              },
            },
          ]}
          fileName={'Models'}
          {...props}
        />,
        <GraphMoreActions
          {...props}
          actions={[
            {
              title: 'Import Models',
              onPress: () => {
                navigation.navigate('project-import-data', {
                  ...params,
                  source: PROJECT_MODEL_TYPE_ID,
                  sampleId: PROJECT_MODEL_IMPORT_SAMPLE_ID,
                });
              },
            },
          ]}
        />,
      ]}
    />
  );
};

const EVENT_SOURCE_ID = [
  'Model',
  'DataImport',
  'ProjectAI',
  'DataEntityAi',
  'MapUserstory',
  'ProjectModelView',
];

export const DataEntityTable = props => {
  const {
    navigation,
    route: {params},
    fromAllView,
    status,
    selectedId,
  } = props;
  const {colors} = useTheme();
  let {download} = useAppStateContext();
  const {groupBy, ...restProps} = props;

  const {project, feature, module} = params;

  const {
    assetDetail,
    mergeReferences,
    changeModule,
    changeDatabase,
    deleteAsset,
    deleteMultipleAssets,
    updateModuleMultipleAssets,
    updateDatabaseMultipleAssets,
    versions,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_MODEL_TYPE_ID,
    eventSourceId: ['DataEntityAi', 'Model'],
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({
    params,
    navigation,
    fromAllView,
    api: '/projectModels',
    source: PROJECT_MODEL_TYPE_ID,
  });

  const {allowDynamicGrouping} = groupBy || {};

  let TableComponent = Table;
  if (module) {
    TableComponent = AITable;
  }
  const aiEnabled = feature?._id;

  return (
    <TableComponent
      {...restProps}
      allowDynamicGrouping={allowDynamicGrouping}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      secondaryApiProps={
        feature?._id && {
          api: `/projectModels/ai`,
          params: {
            parent_entity: 'Module',
            parent_entity_id: module?._id,
          },
          eventSourceId: 'DataEntityAi',
        }
      }
      aiEnabled={aiEnabled}
      aiProps={
        aiEnabled
          ? {
              buttonText: 'Ask AI to create models',
              entityName: 'projectmodel',
              params: {
                parent_entity: 'Module',
                parent_entity_id: module?._id,
                project_id: project?._id,
                module_id: module?._id,
                label: 'Model',
                entity_category_prompts: [
                  {
                    category: 'Detail',
                    prompt_name: 'NewDetailSuggestion',
                    label: 'Model Detail',
                  },
                ],
              },
              initialValues: {
                status: 'active',
                project_id: project?._id,
                module_id: module?._id,
                source: PROJECT_MODEL_TYPE_ID,
                feature_id: feature?._id,
              },
              eventSourceId: 'DataEntityAi',
            }
          : void 0
      }
      eventSourceId={EVENT_SOURCE_ID}
      columns={[
        !selectedId && !allowDynamicGrouping && assetColumns.module,
        {
          colspan: ({row}) => (row.aiGenerated ? 6 : void 0),
          header: 'Model',
          render: _props => {
            const {row} = _props;
            let primaryTitleColor = void 0;

            if (!row.aiGenerated && feature?._id && !row.in_development) {
              primaryTitleColor = colors.NEUTRAL_MEDIUM;
            }
            return (
              <AISuggestionRender
                {..._props}
                params={params}
                primaryField="model"
                primaryTitleColor={primaryTitleColor}
                entityName="ProjectModel"
                eventSourceId="DataEntityAi"
                secondaryField="desc"
              />
            );
          },
        },
        !selectedId && assetColumns.userstory_count,
        assetColumns.api_count,
        assetColumns.data_service_count,
        assetColumns.model_view_count,
        !selectedId && assetColumns.owner,
        !selectedId && assetColumns.code_editor,
      ]}
      selection={{
        actions: [
          updateDatabaseMultipleAssets,
          updateModuleMultipleAssets,
          {
            title: `Download model statement`,
            confirm: {
              title: 'Download model statement',
              message: `Are you sure you want to download statement for selected models?`,
            },
            onPress: ({selectedIds, resetSelection}) => {
              download({
                uri: `/downloadSQLCode`,
                props: {
                  modelIds: selectedIds,
                  fromReleased: status === 'released',
                },
              });
              resetSelection();
            },
          },
          deleteMultipleAssets,
        ],
      }}
      onRowPress={assetDetail}
      moreActions={() => [
        versions,
        changeModule,
        changeDatabase,
        mergeReferences({
          targetApi: '/projectmodels',
        }),
        {
          title: `Download model statement`,
          confirm: {
            title: 'Download model statement',
            message: `Are you sure you want to download statement for selected model?`,
          },
          onPress: ({row}) => {
            download({
              uri: `/downloadSQLCode`,
              props: {
                modelIds: [row._id],
                fromReleased: status === 'released',
              },
            });
          },
        },
        deleteAsset,
      ]}
    />
  );
};

const ProjectDataEntityGraph = ({renderHeader, ...props}) => {
  const {
    navigation,
    route: {params},
  } = props;

  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_MODEL_TYPE_ID,
  });

  return (
    <DataEntityGraphView
      renderHeader={renderHeader?.(props)}
      onNodePress={({item}) => {
        assetDetail({row: item});
      }}
      {...props}
    />
  );
};

export const DataEntityScreen = props => {
  const {
    navigation,
    route: {params},
    isGraphView,
    groupBy,
  } = props;

  const {project, module_id} = params;

  const filterProps = useFilter();
  const {searchValue, filterValues} = filterProps;

  const tableProps = {
    api: `/projectModels/released`,
    addOnFilter: {
      project_id: project?._id,
      module_id,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'model',
    eventSourceId: EVENT_SOURCE_ID,
  };

  const fetchDataProps = useFetchData({
    ...tableProps,
    limit: 10000,
  });

  const renderHeader = props => {
    if (isGraphView) {
      return (
        <DataEntityGraphHeader
          {...props}
          params={params}
          navigation={navigation}
          {...filterProps}
          excelParams={dataEntityExportParams({
            ...tableProps,
            ids: props?.selectedIds,
          })}
        />
      );
    }
    return (
      <DataEntityHeader
        {...props}
        params={params}
        groupBy={groupBy}
        navigation={navigation}
        {...filterProps}
        excelParams={dataEntityExportParams(tableProps)}
      />
    );
  };
  const screenProps = isGraphView ? fetchDataProps : tableProps;
  const ScreenView = isGraphView ? ProjectDataEntityGraph : DataEntityTable;
  return (
    <SplitScreen splitScreen={<DataEntityDetailForm {...props} />}>
      <ScreenView
        {...props}
        {...screenProps}
        groupBy={groupBy}
        renderHeader={renderHeader}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const DataEntitiesTab = props => {
  const {route: {params} = {}} = props;
  const {project, module} = params;

  const [isGraphView, setGraphView] = useState();

  let groupBy = useGroupBy({
    defaultSelected: module?._id ? void 0 : 'module_id',
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          exclusiveExpand: true,
          defaultExpandedIndex: 0,
          leftContent: ({row}) => {
            const moduleName = row?.module || 'No module';
            return <GroupContentItem value={moduleName + ` (${row.count})`} />;
          },
        },
      },
    ],
  });

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: (
            <DataEntityScreen
              isReleased={true}
              isGraphView={isGraphView}
              groupBy={groupBy}
              {...props}
            />
          ),
          api: '/projectmodels',
          filter: {
            status: 'released',
            project_id: project?._id,
            module_id: module?._id,
          },
          eventSourceId: 'Model',
        },
        all: {
          label: 'In Development',
          view: (
            <AllDataEntitiesScreen
              api={`/projects/${project?._id}/asset/${PROJECT_MODEL_TYPE_ID}/all-data`}
              isGraphView={isGraphView}
              groupBy={groupBy}
              {...props}
            />
          ),
          params: {module_id: module?._id},
          api: `/projects/${project?._id}/asset/${PROJECT_MODEL_TYPE_ID}/in-development/count`,
          eventSourceId: 'Model',
        },
      }}
      actions={[
        <ToggleGraphView
          isGraphView={isGraphView}
          setGraphView={setGraphView}
        />,
      ]}
    />
  );
};
