import React from 'react';
import {Table} from '../../../components/table/Table';
import ActivityLogRender from '../../../app-components/renders/ActivityLogRender';

export const ActivityLogTable = ({displayFields, ...props}) => {
  return (
    <Table
      {...props}
      eventSourceId={['Project', 'Employee']}
      columns={[
        {
          render: ActivityLogRender,
          displayFields,
        },
      ]}
      limit={-1}
    />
  );
};

export const ActivityLogScreen = props => {
  const {
    route: {params},
  } = props;

  let {api, displayFields, ...restParams} = params || {};

  return (
    <ActivityLogTable
      params={restParams}
      api={api}
      displayFields={displayFields}
    />
  );
};
