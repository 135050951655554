import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

import {
  OrganizationDisqualifiedTasksTable,
  ProjectDisqualifiedTasksTable,
} from './DisqualifiedTasksTable';
import {taskFilters} from './TaskActions';

export const DisqualifiedTasksTab = props => {
  const {
    route: {params},
  } = props;
  const {filterValues, ...filterProps} = useFilter({});

  return (
    <TabView
      params={params}
      tabs={{
        feature: {
          label: 'Feature',
          view: <ProjectDisqualifiedTasksTable filterValues={filterValues} />,
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.disqualified_by]}
            />,
          ],
        },
        organization: {
          label: 'Organization',
          view: (
            <OrganizationDisqualifiedTasksTable filterValues={filterValues} />
          ),
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.disqualified_by]}
            />,
          ],
        },
      }}
      {...props}
    />
  );
};
