import React from 'react';
import {AutoComplete} from '@unthinkable/react-autocomplete';
import {useStyles} from '@unthinkable/react-theme';
import {GooglePlaceStyles} from './theme';

export const GooglePlaceInput = ({value, onChangeValue}) => {
  const {fetch} = useAppStateContext();
  const styles = useStyles(GooglePlaceStyles);

  return (
    <AutoComplete
      placeholder={'Search...'}
      onChangeValue={onChangeValue}
      valueField="description"
      suggestionField="structured_formatting.main_text"
      secondarySuggestionField="structured_formatting.secondary_text"
      styles={styles}
      value={value}
      fetch={fetch}
      api="/googlePlaces"
      minChar={3}
    />
  );
};
