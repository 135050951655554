import {Avatar} from '@unthinkable/react-avatar';
import moment from 'moment';
import React from 'react';
import {
  MessageBoxWrapper,
  MessageWrapper,
  Sender,
  TextMessage,
  Time,
  TimeContainer,
} from '@unthinkable/react-chat/src/Styles';
import {AttachmentCountRender} from '../../../components/table/Renders';

export const TicketHistoryMessage = ({
  styles,
  position = 'left',
  color,
  background,
  message,
  sender,
  time,
  attachment,
}) => {
  const avatar = sender ? <Avatar value={sender} color={color} /> : void 0;
  time = time ? moment(time).format('hh:mm A') : void 0;
  if (!message) return null;
  const messageBox = (
    <MessageBoxWrapper
      gap={8}
      position={position}
      backgroundColor={background}
      styles={styles}>
      <Sender color={color} styles={styles}>
        {sender}
      </Sender>
      <TextMessage styles={styles?.message} message={message} />
      <AttachmentCountRender
        value={attachment}
        count={attachment?.length}
        suffix={attachment?.length > 1 ? 'attachments' : 'attachment'}
      />
      <TimeContainer position={position}>
        <Time styles={styles}>{time}</Time>
      </TimeContainer>
    </MessageBoxWrapper>
  );
  return (
    <MessageWrapper gap={8} position={position} styles={styles}>
      {avatar}
      {messageBox}
    </MessageWrapper>
  );
};
