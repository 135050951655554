import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {Text} from '@unthinkable/react-core-components';

export const RMFeedbackTable = props => {
  let {
    navigation,
    route: {params} = {},
    filter,
    addOnFilter,
    tab,
    fromClosedRmFeedbackTab = false,
  } = props;
  // const {tab} = params;
  return (
    <Table
      eventSourceId={['RMFeedback']}
      api={'/employeeprojectfeedback'}
      addOnFilter={{...filter, ...addOnFilter}}
      columns={[
        {header: 'Employee Code', field: 'employee.employee_code'},
        {header: 'Employee Name', field: 'employee.name'},
        {
          header: 'Increment Type',
          field: 'quater',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.quater + ' (' + row?.financial_year?.financial_year + ')'}
              </Text>
            );
          },
        },
        fromClosedRmFeedbackTab && {
          header: 'Closed On',
          field: 'rm_feedback_submitted_on',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        !fromClosedRmFeedbackTab && {
          header: 'Increment Due Date',
          field: 'assesment_id.effective_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
          width: 200,
        },
      ]}
      onRowPress={({row}) =>
        navigation.navigate('rm-feedback-detail', {
          row,
          tab,
          fromClosedRmFeedbackTab,
        })
      }
      // params={params}
    />
  );
};

const RMFeedbackTab = props => {
  let {user = {}, route: {params = {}} = {}, ...rest} = props || {};
  const {department} = params;
  let departmentFilter = {};
  if (department) {
    departmentFilter = {
      "employee.department": department,
      "employee.employee_status": "Active"
    }
  }
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {...departmentFilter},
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  const addOnFilter = {};
  let tabs = {};
  tabs['PendingProjectFeedback'] = {
    label: 'Pending',
    eventSourceId: 'PendingProjectFeedback',
    view: (
      <RMFeedbackTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          is_processed: {$in: [null, false]},
          is_rm_feedback_given: {$in: [null, false]},
          is_not_relevent: {$in: [null, false]},
          rm_feedback_generated: true,
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      is_processed: {$in: [null, false]},
      is_rm_feedback_given: {$in: [null, false]},
      is_not_relevent: {$in: [null, false]},
      rm_feedback_generated: true,
    },
    api: '/employeeprojectfeedback',
  };
  tabs['ProcessedProjectFeedback'] = {
    label: 'Closed',
    eventSourceId: 'ProcessedProjectFeedback',
    view: (
      <RMFeedbackTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          is_rm_feedback_given: true,
          rm_feedback_generated: true,
        }}
        user={user}
        fromClosedRmFeedbackTab={true}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      is_rm_feedback_given: true,
      rm_feedback_generated: true,
    },
    api: '/employeeprojectfeedback',
  };

  return (
    <TabView
      tabs={tabs}
      // params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'employee',
            },
            {
              type: 'autoComplete',
              label: 'Financial Year',
              api: '/FinancialYears',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'financial_year',
            },
          ]}
        />,
      ]}
    />
  );
};

export default RMFeedbackTab;
