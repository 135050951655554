import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EditTraineeToTrainerFeedbackForm = props => {
  const {
    route: {params},
  } = props;
  const {feedback, training_batch} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/traineeToTrainerFeedbacks',
    eventSourceId: 'traineeToTrainerFeedbacks',
  });
  return (
    <Form
      header={{
        title: 'Trainer Feedback',
        secondaryTitle: training_batch.name,
      }}
      mode="edit"
      api={`/traineeToTrainerFeedbacks/${feedback?._id}`}
      onSubmit={onSubmit}
      layoutFields={[
        {
          fields: [
            {
              label: 'Subject Matter Knowledge',
              field: 'subject_matter_knowledge',
              type: 'rating',
              required: true,
              size: 'medium',
            },
            {
              label: 'Clarity in communication and explanation of concepts',
              field: 'communication_clarity',
              type: 'rating',
              required: true,
              size: 'medium',
            },
            {
              label: 'Responsiveness to questions and concerns',
              field: 'responsiveness',
              type: 'rating',
              required: true,
              size: 'medium',
            },
            {
              label:
                'Ability to create a positive and engaging learning environment',
              field: 'learning_environment_ability',
              type: 'rating',
              required: true,
              size: 'medium',
            },
            {
              label: 'Time management during the training session',
              field: 'time_management',
              type: 'rating',
              required: true,
              size: 'medium',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const TraineeToTrainerFeedbackDetailRM = props => {
  return (
    <EditTraineeToTrainerFeedbackForm
      header={{
        title: 'Trainer Feedback',
      }}
      readOnly
      {...props}
    />
  );
};
