import React, {useEffect, useState} from 'react';
import {useToast} from '@unthinkable/react-toast';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
} from '../../reimbursement/components/ReimbursementColumnRenders';

import {AddButton} from '../../../components/button/AddButton';
import {
  ActivityIndicator,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {employeeController} from '../controllers/employeeController';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';

const RenderInternshipRequestActions = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('accept-internship-confirmation', {
              row,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.SUCCESS_HIGH,
              overflow: 'hidden',
            }}>
            Accept
          </Text>
        </TouchableOpacity>
        <LineSeparator />

        <TouchableOpacity
          onPress={() => {
            navigation.navigate('reject-internship-confirmation', {
              row,
              forReject: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.ERROR_HIGH,
              overflow: 'hidden',
            }}>
            Reject
          </Text>
        </TouchableOpacity>
        <LineSeparator />

        <TouchableOpacity
          onPress={() => {
            navigation.navigate('extend-internship-confirmation', {
              row,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.INFORMATION_HIGH,
              overflow: 'hidden',
            }}>
            Extend
          </Text>
        </TouchableOpacity>
      </Row>
    </>
  );
};

const RenderDueDate = props => {
  const {row, navigation} = props;
  const icons = useTheme('icons');
  const fonts = useTheme('fonts');
  const colors = useTheme('colors');
  let extendDate = new Date();
  if (row?.extended_details) {
    extendDate =
      row?.extended_details[row?.extended_details.length]?.extended_date;
  }
  if (row?.extended_by) {
    return (
      <>
        <Row style={{alignItems: 'center'}} gap={10}>
          <Text
            numberOfLines={1}
            style={{
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${moment(row?.employee?.probation_end_date).format(
              'DD MMM, YY',
            )}`}
          </Text>
          <Image source={icons.EstChange} style={{height: 14, width: 14}} />
        </Row>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            // overflow: 'hidden',
          }}>
          {`Ext by ${row?.extended_by}d`}
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            // overflow: 'hidden',
          }}>
          {row?.employee?.probation_end_date
            ? `${moment(row?.employee?.probation_end_date).format(
                'DD MMM, YY',
              )}`
            : ''}
        </Text>
      </>
    );
  }
};

const RenderInternshipDueDate = props => {
  const {row, navigation} = props;
  const icons = useTheme('icons');
  const fonts = useTheme('fonts');
  const colors = useTheme('colors');
  let extendDate = new Date();
  if (row?.extended_details) {
    extendDate =
      row?.extended_details[row?.extended_details.length]?.extended_date;
  }
  if (row?.extended_by) {
    return (
      <>
        <Row style={{alignItems: 'center'}} gap={10}>
          <Text
            numberOfLines={1}
            style={{
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              // overflow: 'hidden',
            }}>
            {`${moment(row?.employee?.internship_end_date).format(
              'DD MMM YY',
            )}`}
          </Text>
          <Image source={icons.EstChange} style={{height: 14, width: 14}} />
        </Row>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            // overflow: 'hidden',
          }}>
          {`Ext by ${row?.extended_by}d`}
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            // overflow: 'hidden',
          }}>
          {row?.employee?.internship_end_date
            ? `${moment(row?.employee?.internship_end_date).format(
                'DD MMM YY',
              )}`
            : ''}
        </Text>
      </>
    );
  }
};

export const PendingInternshipRequestsHr = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['Internship']}
      searchFields={['name']}
      api={'/internship-confirmation/hr-pending'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.key}(${row.count})`} />
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'employee.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          field: 'employee.resource_language.name',
          type: 'colorTag',
          colorField: 'employee.resource_language.color',
          // width: 180,
        },
        {
          header: 'Designation',
          // width: 220,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.employee?.designation?.name}
                items={[
                  {
                    value: row?.employee?.employee_team?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Due on',
          width: 110,
          render: RenderInternshipDueDate,
        },
        {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderInternshipRequestActions
                {...props}
                navigation={navigation}
              />
            );
          },
          width: 200,
        },
      ]}
    />
  );
};

export const ClosedInternshipRequestsHr = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['Internship']}
      searchFields={['name']}
      api={'/internship-confirmation/closed-hr'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.key}(${row.count})`} />
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'employee.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          field: 'employee.resource_language.name',
          type: 'colorTag',
          colorField: 'employee.resource_language.color',
          // width: 180,
        },
        {
          header: 'Designation',
          // width: 220,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.employee?.designation?.name}
                items={[
                  {
                    value: row?.employee?.employee_team?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Due on',
          width: 120,
          render: RenderDueDate,
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.status == 'approve') {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Accepted'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.confirmation).format('DD MMM, YY')}`}
                  </Text>
                </>
              );
            } else if (row?.status == 'Cancelled') {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Rejected'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.confirmation).format('DD MMM, YY')}`}
                  </Text>
                </>
              );
            }
          },
        },
        {
          header: 'Feedback',
          type: 'text',
          field: 'internship_feedback',
        },
      ]}
    />
  );
};

export const PendingInternshipRequestsManager = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <GroupTable
      {...props}
      params={params}
      eventSourceId={['Internship']}
      searchFields={['name']}
      api={'/internship-confirmation/team-pending'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.key}(${row.count})`} />
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Exp',
          field: 'employee.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          field: 'employee.resource_language.name',
          type: 'colorTag',
          colorField: 'employee.resource_language.color',
          // width: 180,
        },
        {
          header: 'Designation',
          // width: 220,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.employee?.designation?.name}
                items={[
                  {
                    value: row?.employee?.employee_team?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Due on',
          width: 120,
          render: RenderDueDate,
        },
        {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderInternshipRequestActions
                {...props}
                navigation={navigation}
              />
            );
          },
          // width: 300,
        },
      ]}
    />
  );
};

export const ClosedInternshipRequestsManager = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['Probation']}
      searchFields={['name']}
      api={'/internship-confirmation/team-closed'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <Row style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={`${row.key}(${row.count})`} />
            </Row>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'employee.experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          field: 'employee.resource_language.name',
          type: 'colorTag',
          colorField: 'employee.resource_language.color',
          // width: 180,
        },
        {
          header: 'Designation',
          // width: 220,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.employee?.designation?.name}
                items={[
                  {
                    value: row?.employee?.employee_team?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Due on',
          width: 120,
          render: RenderDueDate,
        },
        {
          header: 'Status',
          render: ({row}) => {
            if (row?.status == 'approve') {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Accepted'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.confirmation).format('DD MMM, YY')}`}
                  </Text>
                </>
              );
            } else if (row?.status == 'Cancelled') {
              return (
                <>
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'Rejected'}
                    borderRadius={6}
                  />
                  <Text
                    numberOfLines={1}
                    style={{
                      fontSize: 12,
                      color: colors.NEUTRAL_MEDIUM,
                      // overflow: 'hidden',
                    }}>
                    {`${moment(row.confirmation).format('DD MMM, YY')}`}
                  </Text>
                </>
              );
            }
          },
        },
        {
          header: 'Feedback',
          type: 'text',
          field: 'internship_feedback',
        },
      ]}
    />
  );
};
