import React from 'react';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {Table} from '../../../components/table/Table';
import {Chip, Tag} from '../../../components/chip/Chip';
import {useTheme} from '@unthinkable/react-theme';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Row, Text} from '@unthinkable/react-core-components';
import {RenderBenchSince} from './UnavailableList';

const lastRating = ({row = {}}) => {
  const colors = useTheme('colors');
  if (row?.project_feedback_rating) {
    const {project_feedback_rating: project_rating} = row;
    if (project_rating == 'A+') {
      return (
        <Chip
          color={colors.SUCCESS_LOW}
          textColor={colors.SUCCESS_HIGH}
          value={'CE'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'A') {
      return (
        <Chip
          color={colors.ACCENT6_LOW}
          textColor={colors.ACCENT6_HIGH}
          value={'OE'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'B+') {
      return (
        <Chip
          color={colors.ACCENT1_LOW}
          textColor={colors.ACCENT1_HIGH}
          value={'ME'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'B') {
      return (
        <Chip
          color={colors.WARNING_LOW}
          textColor={colors.WARNING_HIGH}
          value={'PE'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'C') {
      return (
        <Chip
          color={colors.ERROR_LOW}
          textColor={colors.ERROR_HIGH}
          value={'DE'}
          borderRadius={6}
        />
      );
    }
  }
};

const PROJECT = {
  header: 'Project',
  render: ({row}) => {
    const colors = useTheme('colors');
    const {CAPTION_LARGE, BODY2} = useTheme('fonts');
    const projectLength =
      (row?.billed_projects && row?.billed_projects.length) || 0;
    const projectSubCategory =
      row?.billed_projects &&
      row?.billed_projects[0] &&
      row?.billed_projects[0].project_sub_category;
    const projectName =
      row?.billed_projects &&
      row?.billed_projects[0] &&
      row?.billed_projects[0].project;
    if (projectLength > 1) {
      return (
        <>
          <Row gap={8} style={{alignItems: 'center'}}>
            <Tag
              value={projectName}
              color={
                row?.billed_projects &&
                row?.billed_projects[0] &&
                row?.billed_projects[0].color
              }
            />
            <Text style={{...BODY2}}>+{projectLength - 1}</Text>
          </Row>

          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {projectSubCategory}
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Tag
            value={projectName}
            color={row?.project && row?.project[0] && row?.project[0].color}
          />
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {projectSubCategory}
          </Text>
        </>
      );
    }
  },
};

const renderStatus = ({row = {}, styles = {}}) => {
  let {billing_status} = row || {};
  if (!billing_status) return null;
  if (billing_status === 'Billed') {
    return <Tag value={billing_status} color={'#E0EFFF'} />;
  }

  if (billing_status === 'Unbilled') {
    return <Tag value={billing_status} color={'#E0EFFF'} />;
  }

  if (billing_status === 'Unplanned') {
    return <Tag value={billing_status} color={'#DBFFFE'} />;
  }
  if (billing_status === 'Training') {
    return <Tag value={billing_status} color={'#E0EFFF'} />;
  }

  if (billing_status === 'Module Development') {
    return <Tag value={billing_status} color={'#E0EFFF'} />;
  }

  if (billing_status === 'Shadow') {
    return <Tag value={billing_status} color={'#DBFFFE'} />;
  }
  if (billing_status === 'Planned') {
    return <Tag value={billing_status} color={'#E0EFFF'} />;
  }

  if (billing_status === 'Not Available') {
    return <Tag value={billing_status} color={'#E0EFFF'} />;
  }
};

const EmployeeTagsWiseTable = props => {
  const {extraFilters = {}, searchValue, tag, navigation} = props;
  return (
    <Table
      {...props}
      limit={2000}
      sort={tag?.name === 'Billed' ? {billed_hours: -1} : {unbilled_hours: -1}}
      eventSourceId={['BillingStatusUpdate']}
      api={`/tagswiseemployees`}
      addOnFilter={{...extraFilters}}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Project',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE, BODY2} = useTheme('fonts');
            const projectLength =
              (row?.billed_projects && row?.billed_projects.length) || 0;
            const projectSubCategory =
              row?.billed_projects &&
              row?.billed_projects[0] &&
              row?.billed_projects[0].project_sub_category;
            const projectName =
              row?.billed_projects &&
              row?.billed_projects[0] &&
              row?.billed_projects[0].project;
            if (projectLength > 1) {
              return (
                <>
                  <Row gap={8} style={{alignItems: 'center'}}>
                    <Tag
                      value={projectName}
                      color={
                        row?.billed_projects &&
                        row?.billed_projects[0] &&
                        row?.billed_projects[0].color
                      }
                    />
                    <Text style={{...BODY2}}>+{projectLength - 1}</Text>
                  </Row>

                  <Text
                    style={{
                      ...CAPTION_LARGE,
                      color: colors.NEUTRAL_MEDIUM,
                      paddingLeft: 10,
                    }}>
                    {projectSubCategory}
                  </Text>
                </>
              );
            } else {
              return (
                <>
                  <Tag
                    value={projectName}
                    color={
                      row?.project && row?.project[0] && row?.project[0].color
                    }
                  />
                  <Text
                    style={{
                      ...CAPTION_LARGE,
                      color: colors.NEUTRAL_MEDIUM,
                      paddingLeft: 10,
                    }}>
                    {projectSubCategory}
                  </Text>
                </>
              );
            }
          },
          visible: () => tag?.name === 'Billed',
        },
        {
          header: 'Engineer',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          minWidth: 240,
        },
        {
          header: 'Exp.',
          align: 'right',
          field: 'experience',
          width: 80,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          width: 120,
        },
        {
          header: 'Skill Mentor',
          width: 160,
          type: 'userAvatarChip',
          field: 'skill_leader',
        },
        {
          header: 'Plan Deployment Date',
          type: 'date',
          field: 'proposed_deployment_date',
          formatOptions: {format: 'DD MMM YY'},
          visible: () => tag?.name === 'Planned',
          width: 120,
        },
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
          visible: () => tag?.name !== 'Billed' && tag?.name !== 'All',
        },
        {
          header: 'Allocated Hrs',
          align: 'right',
          field: 'billed_hours',
          width: 120,
          visible: () => tag?.name === 'Billed',
        },
        {
          header: 'Avl. Hrs',
          align: 'right',
          field: 'unbilled_hours',
          width: 120,
          visible: () => tag?.name !== 'Billed' && tag?.name !== 'All',
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Update Billing Status',
          onPress: ({row}) => {
            navigation.navigate('update-billing-status-manual-form', {
              row,
            });
          },
          visible: () => tag?.name !== 'Billed' && tag?.name !== 'All',
        },
      ]}
    />
  );
};

const EmployeeBillingStatusTable = props => {
  const {extraFilters = {}, searchValue, tag, navigation} = props;
  return (
    <Table
      {...props}
      limit={2000}
      eventSourceId={['BillingStatusUpdate']}
      api={`/tagswiseemployees`}
      addOnFilter={{...extraFilters}}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Exp.',
          align: 'right',
          field: 'experience',
          width: 60,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          width: 160,
        },
        {
          header: 'Primary Reportee',
          width: 160,
          type: 'userAvatarChip',
          field: 'delivery_owner',
        },
        PROJECT,
        // {
        //   header: 'Status',
        //   render: renderStatus,
        // },
        // {
        //   header: 'Plan Deployment Date',
        //   type: 'date',
        //   field: 'proposed_deployment_date',
        //   formatOptions: {format: 'DD MMM YY'},
        //   width: 120,
        // },
        // {
        //   header: 'Allocation Hrs',
        //   align: 'right',
        //   field: 'billed_hours',
        //   width: 140,
        // },
        {
          header: 'Avl. Hrs',
          align: 'right',
          field: 'unbilled_hours',
          width: 100,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
    />
  );
};

export const EmployeeTagWiseStatusTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  let customfilter = {};
  if (params?.name === 'Billed') {
    customfilter = {billed_hours: {$gt: 0}};
  }
  if (params?.name === 'Unbilled') {
    customfilter = {
      billing_status: {
        $in: [
          'Training',
          'Module Development',
          'Shadow',
          'Planned',
          'Not Available',
          'Unplanned',
        ],
      },
    };
  }
  if (params?.name === 'Unplanned') {
    customfilter = {
      billing_status: 'Unplanned',
    };
  }
  if (params?.name === 'Training') {
    customfilter = {
      billing_status: 'Training',
    };
  }
  if (params?.name === 'Module Development') {
    customfilter = {
      billing_status: 'Module Development',
    };
  }
  if (params?.name === 'Shadow') {
    customfilter = {
      billing_status: 'Shadow',
    };
  }
  if (params?.name === 'Planned') {
    customfilter = {
      billing_status: 'Planned',
    };
  }
  if (params?.name === 'Not Available') {
    customfilter = {
      billing_status: 'Not Available',
    };
  }
  const tabs = {
    employee: {
      label: 'Resources',
      view: (
        <EmployeeTagsWiseTable
          extraFilters={{...filterValues?.filter, ...customfilter}}
          searchValue={searchValue}
          tag={params?.tag}
          {...props}
        />
      ),
      api: '/tagswiseemployees',
      addOnFilter: {...filterValues?.filter, ...customfilter},
      limit: 2000,
      eventSourceId: ['BillingStatusUpdate'],
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Skill',
              field: 'resource_language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              type: 'multiAutoComplete',
              label: 'Projects',
              field: 'billed_projects',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const EmployeeBillingStatusTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {employeeFilter = {}} = params;
  const tabs = {
    resources: {
      label: 'Resources',
      view: (
        <EmployeeBillingStatusTable
          extraFilters={{...filterValues?.filter, ...employeeFilter}}
          searchValue={searchValue}
          tag={params?.tag}
          {...props}
        />
      ),
      api: '/tagswiseemployees',
      addOnFilter: {...filterValues?.filter, ...employeeFilter},
      limit: 2000,
      eventSourceId: ['BillingStatusUpdate'],
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Skill',
              field: 'resource_language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              type: 'multiAutoComplete',
              label: 'Projects',
              field: 'billed_projects',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
