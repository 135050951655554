import React from 'react';
import {Table} from '../../../components/table/Table';
import {RecursiveRender} from '@unthinkable/react-table';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {TableHeader} from '../../../components/table/Headers';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';

export const OrganizationDashboard = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const filterProps = useFilter({
    filterKey: 'organizationDashboardFilter',
    params: {
      period: getPreviousMonth(),
    },
    filter: {},
  });
  const {filterValues = {}} = filterProps;
  const {params: filterParams} = filterValues || {};

  const onSalaryClick = ({row}) => {
    const params = {};
    if (row?.organization) {
      params.product = row?._id;
      params.organization = row?.organization;
    } else {
      params.product = {$in: row?.products?.map(p => p._id)};
      params.organization = row?._id;
    }
    row.salaryCost &&
      navigation.navigate(`salary-list`, {
        ...filterParams,
        ...params,
        row,
      });
  };

  const onDirectExpenseClick = ({row}) => {
    const params = {};
    if (row?.organization) {
      params.product = row?._id;
      params.organization = row?.organization;
    } else {
      params.product = {$in: row?.products?.map(p => p._id)};
      params.organization = row?._id;
    }
    row?.directCost &&
      navigation.navigate(`vendor-invoice-direct-expense-table`, {
        ...filterParams,
        ...params,
        row,
      });
  };

  const onRevenueClick = ({row}) => {
    const params = {};
    if (row?.organization) {
      params.product = row?._id;
      params.organization = row?.organization;
    } else {
      params.product = {$in: row?.products?.map(p => p._id)};
      params.organization = row?._id;
    }
    row.revenue &&
      navigation.navigate(`customers-table`, {
        ...filterParams,
        ...params,
        row,
      });
  };

  const onRowPress = ({row}) => {
    if (row?.organization) {
      navigation.navigate(`all-departments-dashboard`, {
        ...filterParams,
        ...params,
        product: row,
        role: row?.role,
      });
    } else {
      navigation.navigate(`organization-navigator`, {
        ...filterParams,
        ...params,
        organization: row,
        products: row?.products,
        role: row?.role,
      });
    }
  };

  return (
    <Table
      {...props}
      renderHeader={() => {
        return (
          <TableHeader
            title=" "
            actions={[<PeriodRangeFilter {...filterProps} />]}
          />
        );
      }}
      params={{period: getPreviousMonth(), ...params, ...filterParams}}
      api="/organizations-dashboard"
      fields={{name: 1, products: {name: 1}}}
      recursiveKey={'products'}
      onRowPress={onRowPress}
      columns={[
        {
          field: 'name',
          header: 'Name',
          render: RecursiveRender,
          responsive: 'sm',
        },
        // {
        //   type: 'currency',
        //   field: 'revenue',
        //   header: 'Revenue',
        //   compareField: 'previousRevenue',
        //   render: ComparisonRender,
        //   onPress: onRevenueClick,
        //   width: 250,
        //   toFixed: 2,
        //   aggregate: {
        //     render: AggregateComparisonRender,
        //   },
        // },
        // {
        //   type: 'currency',
        //   field: 'salaryCost',
        //   header: 'Salary Cost',
        //   compareField: 'previousSalaryCost',
        //   render: ComparisonRender,
        //   onPress: onSalaryClick,
        //   width: 250,
        //   toFixed: 2,
        //   aggregate: {
        //     render: AggregateComparisonRender,
        //   },
        // },
        // {
        //   type: 'currency',
        //   field: 'directCost',
        //   header: 'Direct Cost',
        //   compareField: 'previousDirectCost',
        //   render: ComparisonRender,
        //   onPress: onDirectExpenseClick,
        //   width: 250,
        //   toFixed: 2,
        //   aggregate: {
        //     render: AggregateComparisonRender,
        //   },
        // },
      ]}
    />
  );
};
