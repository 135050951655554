import {useEffect, useState} from 'react';
import {
  useFilterKeyValues,
  useFilterContext,
} from '../providers/FilterProvider';
import {populateFilter} from '../util';

export const useFilter = ({
  filterKey: propsFilterKey,
  filter: defaultFilter,
  params: defaultParams,
  plainFilter,
  isGlobalFilter,
} = {}) => {
  let filterKey = propsFilterKey;

  if (isGlobalFilter) {
    filterKey = 'global';
  }

  const {setFilter, clearFilter, clearSearchValue} = useFilterContext();
  const filter = useFilterKeyValues(filterKey);

  const [localFilter, setLocalFilter] = useState({
    filterValues: {filter: defaultFilter, params: defaultParams, plainFilter},
  });

  useEffect(() => {
    if (filterKey && (defaultFilter || defaultParams || plainFilter)) {
      setFilter(filterKey, {
        filterValues: {
          filter: defaultFilter,
          params: defaultParams,
          plainFilter,
        },
      });
    }
    return () => {
      if (filterKey && !isGlobalFilter) {
        clearSearchValue && clearSearchValue(filterKey);
        clearFilter && clearFilter(filterKey);
      }
    };
  }, []);

  const resetFilter = () => {
    filterKey
      ? setFilter(filterKey, {
          filterValues: {
            filter: defaultFilter,
            params: defaultParams,
            plainFilter,
          },
        })
      : setLocalFilter({
          filterValues: {
            filter: defaultFilter,
            params: defaultParams,
            plainFilter,
          },
        });
  };

  const applyFilter = filterInfo => {
    filterKey ? setFilter(filterKey, filterInfo) : setLocalFilter(filterInfo);
  };

  const onChangeFilter = props => {
    let filterInfo = filterKey ? {...filter} : {...localFilter};
    filterInfo = populateFilter(filterInfo, props);
    filterKey ? setFilter(filterKey, filterInfo) : setLocalFilter(filterInfo);
  };

  return {
    ...(filterKey ? filter : localFilter),
    onChangeFilter,
    applyFilter,
    resetFilter,
    clearSearch: clearSearchValue,
  };
};

export const useGlobalFilter = props => {
  return useFilter({isGlobalFilter: true, ...props});
};

export const useGlobalParams = props => {
  const {filterValues} = useFilter({isGlobalFilter: true, ...props});
  return filterValues?.params;
};
