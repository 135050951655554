import {useEffect, useRef} from 'react';
import {initializeApp} from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import {useEventHandler} from '@unthinkable/react-event-handler';

const firebaseConfig = {
  apiKey: 'AIzaSyDYbTYF6VWQiyoiPRvI2gdGCKMl3tIPnVc',
  authDomain: 'locomo-f797d.firebaseapp.com',
  projectId: 'locomo-f797d',
  storageBucket: 'locomo-f797d.appspot.com',
  messagingSenderId: '162486916210',
  appId: '1:162486916210:web:275fe0a3dd88079f445eeb',
  measurementId: 'G-1YFWBE86N9',
};

export const app = initializeApp(firebaseConfig);

export const useNotificationHandler = ({
  setDeviceToken,
  toast,
  navigateToScreen,
}) => {
  const messaging = useRef(null);

  const {notify} = useEventHandler();

  // when app is in foreground it lisetens the event when notification is clicked and navigate to specific screen - Ali 09/01/24
  const handleForegroundMessaging = () => {
    onMessage(messaging.current, payload => {
      // console.log('Message received. ', payload);
      const {title, body} = payload.data;

      notify('notificationCountUpdated', {_id: 'onMessage'});

      const notification = new Notification(title, {body, data: payload.data});
      notification.onclick = async function (event) {
        // Prevent the default action when the notification is clicked
        event.preventDefault();

        // Focus on the tab or open a new one if necessary
        window.focus();

        const screenData = event?.currentTarget?.data || {};

        await navigateToScreen(screenData);
      };
    });
  };

  const requestPermission = () => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');

        navigator.serviceWorker.ready
          .then(serviceWorkerRegistration => {
            getToken(messaging.current, {
              serviceWorkerRegistration,
              vapidKey:
                'BIFUNzx3kMrL1sDlgoq5cUqxa52oBJl2_JavwTh0uUE3R9xGJNC4-UntRHLPNs8bRI3eQtkhy3ubIm6RWmOfL-4',
            })
              .then(async currentToken => {
                if (currentToken) {
                  setDeviceToken(currentToken);
                } else {
                  console.log(
                    'No registration token available. Request permission to generate one.',
                  );
                }
              })
              .catch(err => {
                console.log('An error occurred while retrieving token. ', err);
                toast({message: err.message, type: 'Error'});
              });
          })
          .catch(err => {
            console.log('error', err);
            toast({message: err.message, type: 'Error'});
          });
      } else {
        console.log('Notification permission not granted.');
      }
    });
  };

  const initializeMessaging = async () => {
    const isSupportingBrowser = await isSupported();
    if (!isSupportingBrowser) {
      return;
    }
    messaging.current = getMessaging(app);
    handleForegroundMessaging();
    requestPermission();
  };

  useEffect(() => {
    initializeMessaging();
  }, []);

  // when app is in background it lisetens the event when notification is clicked and navigate to specific screen - Ali 09/01/24
  useEffect(() => {
    // Handler for service worker messages
    const handleServiceWorkerMessage = async event => {
      const {screenData} = event.data;
      await navigateToScreen(screenData);
    };

    // Request any pending notification data from the service worker
    if (navigator.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'REQUEST_NOTIFICATION_DATA',
      });
    }

    // Register the message listener
    navigator.serviceWorker?.addEventListener(
      'message',
      handleServiceWorkerMessage,
    );

    // Clean up the listener when the component unmounts
    return () => {
      navigator.serviceWorker?.removeEventListener(
        'message',
        handleServiceWorkerMessage,
      );
    };
  }, []);
};
