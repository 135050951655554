import React, {useEffect, useState} from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {
  Row,
  Text,
  View,
  Image,
  TouchableOpacity,
  Col,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';
import {useToast} from '@unthinkable/react-toast';
import {vendorController} from '../../vendor/controller/VendorController';
import {useInvoke} from '../../../controllers/useInvoke';
import moment from 'moment';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

const ContactRegionRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');

  return (
    <Col>
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          // color: colors.ERROR_HIGH,
        }}>
        {row.contact_person}
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {`${row?.corresponding_address_city || ''}, ${
          row?.correspondenceState || ''
        }`}
      </Text>
    </Col>
  );
};

const BlackListReasonRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');

  return (
    <>
      <Col>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            // color: colors.ERROR_HIGH,
          }}>
          {row.blacklist_reason}
        </Text>
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
          }}>
          Blacklisted for: {moment(row.blacklist_date).format('DD MMM, YY')}
        </Text>
      </Col>
    </>
  );
};

const VendorTableView = props => {
  const {
    filter,
    searchValue,
    route: {params = {}},
    navigation,
  } = props;
  const {organization} = params;
  return (
    <Table
      eventSourceId="VendorView"
      api={`/vendors`}
      limit={100}
      search={searchValue}
      searchFields="name"
      filter={{...filter /* organization: organization */}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`vendor-view-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Registration Date',
          field: 'created_on',
          type: 'date',
          width: 150,
        },
        {
          header: 'Vendor Name',
          field: 'name',
          width: 250,
        },
        {
          header: 'Vendor Category',
          field: 'vendor_category',
          width: 150,
        },
        {
          header: 'Email',
          field: 'email',
          width: 250,
        },
        {
          header: 'Region',
          //   render: ({row}) => {
          //     let region =
          //       (row?.correspondenceState || '') +
          //       ' ' +
          //       (row?.correspondenceCountry?.name || '');
          //     region = region.trim();
          //     return region.length ? <Text>{region}</Text> : '';
          //   },
          field: 'correspondenceState',
          width: 150,
        },
        {
          header: 'GSTIN',
          field: 'gstin',
          width: 150,
        },
        {
          header: 'Pan No.',
          field: 'pan_no',
          width: 150,
        },
        {
          header: 'Contact',
          field: 'contact_number',
          width: 150,
        },
      ]}
    />
  );
};

export const NewVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
    tabName,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, download} = useAppStateContext();

  const {
    updateNewVendorStatus,
    updateVerifiedVendorStatus,
    activityLogVendor,
    vendorEditableDetailForm,
    vendorNonEditableDetailForm,
  } = vendorController(props);

  const fetchProps = {
    params,
    search: searchValue,
    searchFields: ['name'],
    api: '/vendor/vendor-data/employee',
    ...props,
  };

  return (
    <Table
      eventSourceId="VendorView"
      api={`/vendor/vendor-data/employee`}
      limit={100}
      search={searchValue}
      searchFields="name"
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'name',
                      'Vendor Category': 'vendor_category',
                      'Vendor Type': 'vendor_catgory_name',
                      Email: 'email',
                      Status: 'status',
                      Contact: 'contact_number',
                      Pan: 'pan_no',
                      Gst: 'gstin',
                      State: 'correspondenceState',
                      Country: 'correspondenceCountry.name',
                      Code: 'correspondencePinCode',
                      'Pan Copy': 'has_pan_copy',
                      'Cheque Copy': 'has_cheque_copy',
                      'Address Proof': 'has_address_proof',
                      'Contact Person': 'contact_person',
                      'Corresponding Address City':
                        'corresponding_address_city',
                      'Correspondence Address': 'correspondenceAddress',
                      'Account Number': 'account_number',
                      'Ifsc Code': 'ifsc_code',
                      'Account Type': 'account_type',
                      'Msme Certificate': 'has_msme_certificate',
                      'lut copy': 'has_lut_copy',
                      'GST State Code': 'gst_state_code',
                      'GST State': 'gst_state',
                      'GST Certificate': 'has_gst_certificate',
                      VAT: 'vat_number',
                      'Supply Type': 'supply_type',
                      'Lut Number': 'lut_number',
                      'ITR Attachment': 'has_ITR_attachment',
                      Unit: 'product.name',
                      Department: 'department.name',
                      Location: 'location_id.name',
                      'Payable Account': 'payable_account.name',
                      'Recoverable Account': 'pre_payment_account.name',
                    },
                    filename: 'Internal Vendors',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
        },
        {
          field: 'purpose',
          type: 'text',
          header: 'Purpose',
          width: 160,
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },

        {
          header: 'Contact & Region',
          render: ContactRegionRender,
          width: 190,
        },
        // {
        //   header: 'Email',
        //   field: 'email',
        //   width: 250,
        // },
        // {
        //   header: 'Region',
        //   //   render: ({row}) => {
        //   //     let region =
        //   //       (row?.correspondenceState || '') +
        //   //       ' ' +
        //   //       (row?.correspondenceCountry?.name || '');
        //   //     region = region.trim();
        //   //     return region.length ? <Text>{region}</Text> : '';
        //   //   },
        //   field: 'correspondenceState',
        //   width: 150,
        // },
        {
          field: 'email',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  copyText({isEmail: true, email: row?.email});
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image source={icon.atTheRate} />
              </TouchableOpacity>
            );
          },
        },
        {
          field: 'contact_number',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.contact_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: vendorEditableDetailForm,
        },
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },
        tabName == 'new' && {
          title: 'Update Status',
          onPress: updateNewVendorStatus,
        },
        tabName == 'verified' && {
          title: 'Update Status',
          onPress: updateVerifiedVendorStatus,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVendor,
        },
      ]}
    />
  );
};

export const InactiveVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  const invoke = useInvoke({
    eventSourceId: 'VendorView',
    method: 'put',
  });

  const {markVendorBlacklist, activityLogVendor, vendorNonEditableDetailForm} =
    vendorController(props);

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, user} = useAppStateContext();
  return (
    <Table
      eventSourceId="VendorView"
      api={`/vendor/vendor-data/employee`}
      limit={100}
      filter={{status: 'In Active'}}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
        },
        {
          field: 'purpose',
          type: 'text',
          header: 'Purpose',
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },

        {
          type: 'date',
          field: 'inactive_date',
          header: 'Inactive from',
          width: 120,
        },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },
        {
          title: 'Mark New',
          confirm: {
            title: 'Mark New',
            message: 'Are you sure you want to mark this vendor as new?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/vendors/${row?._id}`,
              message: 'Vendor marked new successfully',
              props: {
                new_by: user?.employee?._id,
                new_date: new Date(),
                status: 'New',
              },
            });
          },
        },
        {
          title: 'Mark Blacklist',
          onPress: markVendorBlacklist,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVendor,
        },
      ]}
    />
  );
};

export const BlacklistVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  const invoke = useInvoke({
    eventSourceId: 'Vendor',
    method: 'put',
  });

  const {markVendorBlacklist} = vendorController(props);

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, user} = useAppStateContext();
  return (
    <Table
      eventSourceId="Vendor"
      api={`/vendor/vendor-data/employee`}
      limit={100}
      search={searchValue}
      searchFields="name"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`vendor-view-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
        },
        {
          field: 'purpose',
          type: 'colorTag',
          header: 'Purpose',
          width: 160,
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },

        {
          field: 'verified_on',
          header: 'Reason for blacklist',
          width: 220,
          render: BlackListReasonRender,
        },
      ]}
      {...props}
    />
  );
};

export const ExternalVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
    tabName,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, download} = useAppStateContext();

  const {
    updateNewVendorStatus,
    updateVerifiedVendorStatus,
    updateVendorAccountDetails,
    updateVendorContactDetails,
    activityLogVendor,
    vendorNonEditableDetailForm,
    vendorEditableDetailForm,
  } = vendorController(props);

  const fetchProps = {
    params,
    search: searchValue,
    searchFields: ['name'],
    api: '/vendor/vendor-data/external',
    ...props,
  };

  return (
    <Table
      eventSourceId="VendorView"
      api={`/vendor/vendor-data/external`}
      limit={100}
      search={searchValue}
      searchFields="name"
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'name',
                      'Vendor Category': 'vendor_category',
                      'Vendor Type': 'vendor_catgory_name',
                      Email: 'email',
                      Status: 'status',
                      Contact: 'contact_number',
                      Pan: 'pan_no',
                      Gst: 'gstin',
                      State: 'correspondenceState',
                      Country: 'correspondenceCountry.name',
                      Code: 'correspondencePinCode',
                      'Pan Copy': 'has_pan_copy',
                      'Cheque Copy': 'has_cheque_copy',
                      'Address Proof': 'has_address_proof',
                      'Contact Person': 'contact_person',
                      'Corresponding Address City':
                        'corresponding_address_city',
                      'Correspondence Address': 'correspondenceAddress',
                      'Account Number': 'account_number',
                      'Ifsc Code': 'ifsc_code',
                      'Account Type': 'account_type',
                      'Msme Certificate': 'has_msme_certificate',
                      'lut copy': 'has_lut_copy',
                      'GST State Code': 'gst_state_code',
                      'GST State': 'gst_state',
                      'GST Certificate': 'has_gst_certificate',
                      VAT: 'vat_number',
                      'Supply Type': 'supply_type',
                      'Lut Number': 'lut_number',
                      'ITR Attachment': 'has_ITR_attachment',
                      Unit: 'product.name',
                      Department: 'department.name',
                      Location: 'location_id.name',
                      'Payable Account': 'payable_account.name',
                      'Recoverable Account': 'pre_payment_account.name',
                    },
                    filename: 'External Vendors',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
          // width: 200
        },
        {
          field: 'purpose',
          type: 'text',
          header: 'Purpose',
          width: 140,
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },
        {
          header: 'Service type',
          field: 'service_type',
          render: ({row}) => {
            if (row?.service_type == 'Adhoc') {
              return (
                <Tag value={row?.service_type} color={colors.ACCENT4_MEDIUM} />
              );
            } else if (row?.service_type == 'Recurring') {
              return (
                <Tag value={row?.service_type} color={colors.ACCENT1_MEDIUM} />
              );
            }
          },
          width: 120,
        },

        {
          header: 'Contact & Region',
          render: ContactRegionRender,
          width: 190,
        },
        // {
        //   header: 'Email',
        //   field: 'email',
        //   width: 250,
        // },
        // {
        //   header: 'Region',
        //   //   render: ({row}) => {
        //   //     let region =
        //   //       (row?.correspondenceState || '') +
        //   //       ' ' +
        //   //       (row?.correspondenceCountry?.name || '');
        //   //     region = region.trim();
        //   //     return region.length ? <Text>{region}</Text> : '';
        //   //   },
        //   field: 'correspondenceState',
        //   width: 150,
        // },
        {
          field: 'email',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  copyText({isEmail: true, email: row?.email});
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image source={icon.atTheRate} />
              </TouchableOpacity>
            );
          },
        },
        {
          field: 'contact_number',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.contact_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
      ]}
      {...props}
      moreActions={() => [
        // tabName == 'new' && {
        //   title: 'Update Status',
        //   onPress: updateNewVendorStatus,
        // },
        {
          title: 'Edit',
          onPress: vendorEditableDetailForm,
        },
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },
        tabName == 'verified' && {
          title: 'Update Status',
          onPress: updateVerifiedVendorStatus,
        },
        tabName == 'verified' && {
          title: 'Update Account Number',
          onPress: updateVendorAccountDetails,
        },
        tabName == 'verified' && {
          title: 'Update Contact Details',
          onPress: updateVendorContactDetails,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVendor,
        },
      ]}
    />
  );
};

export const UnapprovedVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
    tabName,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, download} = useAppStateContext();

  const fetchProps = {
    params,
    search: searchValue,
    searchFields: ['name'],
    api: '/vendor/vendor-data/external',
    ...props,
  };

  const {
    updateNewVendorStatus,
    updateVerifiedVendorStatus,
    activityLogVendor,
    vendorEditableDetailForm,
    vendorNonEditableDetailForm,
  } = vendorController(props);

  return (
    <Table
      eventSourceId="VendorView"
      api={`/vendor/vendor-data/external`}
      limit={100}
      search={searchValue}
      searchFields="name"
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Name: 'name',
                      'Vendor Category': 'vendor_category',
                      'Vendor Type': 'vendor_catgory_name',
                      Email: 'email',
                      Status: 'status',
                      Contact: 'contact_number',
                      Pan: 'pan_no',
                      Gst: 'gstin',
                      State: 'correspondenceState',
                      Country: 'correspondenceCountry.name',
                      Code: 'correspondencePinCode',
                      'Pan Copy': 'has_pan_copy',
                      'Cheque Copy': 'has_cheque_copy',
                      'Address Proof': 'has_address_proof',
                      'Contact Person': 'contact_person',
                      'Corresponding Address City':
                        'corresponding_address_city',
                      'Correspondence Address': 'correspondenceAddress',
                      'Account Number': 'account_number',
                      'Ifsc Code': 'ifsc_code',
                      'Account Type': 'account_type',
                      'Msme Certificate': 'has_msme_certificate',
                      'lut copy': 'has_lut_copy',
                      'GST State Code': 'gst_state_code',
                      'GST State': 'gst_state',
                      'GST Certificate': 'has_gst_certificate',
                      VAT: 'vat_number',
                      'Supply Type': 'supply_type',
                      'Lut Number': 'lut_number',
                      'ITR Attachment': 'has_ITR_attachment',
                      Unit: 'product.name',
                      Department: 'department.name',
                      Location: 'location_id.name',
                      'Payable Account': 'payable_account.name',
                      'Recoverable Account': 'pre_payment_account.name',
                    },
                    filename: 'Unapproved Vendors',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
          // width: 200
        },
        {
          field: 'purpose',
          type: 'text',
          header: 'Purpose',
          width: 140,
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },
        {
          header: 'Service type',
          field: 'service_type',
          render: ({row}) => {
            if (row?.service_type == 'Adhoc') {
              return (
                <Tag value={row?.service_type} color={colors.ACCENT4_MEDIUM} />
              );
            } else if (row?.service_type == 'Recurring') {
              return (
                <Tag value={row?.service_type} color={colors.ACCENT1_MEDIUM} />
              );
            }
          },
          width: 120,
        },

        {
          header: 'Contact & Region',
          render: ContactRegionRender,
          width: 190,
        },
        // {
        //   header: 'Email',
        //   field: 'email',
        //   width: 250,
        // },
        // {
        //   header: 'Region',
        //   //   render: ({row}) => {
        //   //     let region =
        //   //       (row?.correspondenceState || '') +
        //   //       ' ' +
        //   //       (row?.correspondenceCountry?.name || '');
        //   //     region = region.trim();
        //   //     return region.length ? <Text>{region}</Text> : '';
        //   //   },
        //   field: 'correspondenceState',
        //   width: 150,
        // },
        {
          field: 'email',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  copyText({isEmail: true, email: row?.email});
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image source={icon.atTheRate} />
              </TouchableOpacity>
            );
          },
        },
        {
          field: 'contact_number',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.contact_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: vendorEditableDetailForm,
        },
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },
        {
          title: 'Update Status',
          onPress: updateNewVendorStatus,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVendor,
        },
      ]}
    />
  );
};

export const ExternalInactiveVendorList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  const invoke = useInvoke({
    eventSourceId: 'VendorView',
    method: 'put',
  });

  const {
    markVendorBlacklist,
    activityLogVendor,
    vendorEditableDetailForm,
    vendorNonEditableDetailForm,
  } = vendorController(props);

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, user} = useAppStateContext();
  return (
    <GroupTable
      {...props}
      eventSourceId="VendorView"
      api={`/vendor/vendor-data/inactive-external`}
      limit={100}
      // search={searchValue}
      params={params}
      searchFields="name"
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} inactive`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
        },
        {
          field: 'purpose',
          type: 'text',
          header: 'Purpose',
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },

        {
          type: 'date',
          field: 'inactive_date',
          header: 'Inactive from',
          width: 120,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: vendorEditableDetailForm,
        },
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },
        {
          title: 'Mark New',
          confirm: {
            title: 'Mark New',
            message: 'Are you sure you want to mark this vendor as new?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/vendors/${row?._id}`,
              message: 'Vendor marked new successfully',
              props: {
                new_by: user?.employee?._id,
                new_date: new Date(),
                status: 'New',
              },
            });
          },
        },
        {
          title: 'Mark Blacklist',
          onPress: markVendorBlacklist,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVendor,
        },
      ]}
    />
  );
};

export const ExternalBlacklistVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  const invoke = useInvoke({
    eventSourceId: 'Vendor',
    method: 'put',
  });

  const {markVendorBlacklist, vendorNonEditableDetailForm} =
    vendorController(props);

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch, user} = useAppStateContext();
  return (
    <Table
      eventSourceId="Vendor"
      api={`/vendor/vendor-data/external`}
      limit={100}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
        },
        {
          field: 'purpose',
          type: 'colorTag',
          header: 'Purpose',
          width: 160,
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },

        {
          field: 'verified_on',
          header: 'Reason for blacklist',
          width: 220,
          render: BlackListReasonRender,
        },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },
      ]}
    />
  );
};

export const AllVendorList = props => {
  const {
    searchValue,
    route: {params = {}},
    navigation,
    tabName,
  } = props;
  const {organization} = params;
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {download} = useAppStateContext();

  const {
    updateNewVendorStatus,
    vendorEditableDetailForm,
    vendorNonEditableDetailForm,
    updateVerifiedVendorStatus,
    activityLogVendor,
  } = vendorController(props);
  const fetchProps = {
    params,
    search: searchValue,
    searchFields: ['name'],
  };
  return (
    <Table
      eventSourceId="VendorView"
      api={`/vendor/vendor-data`}
      limit={100}
      search={searchValue}
      // addOnFilter = {{...filterParams}}
      searchFields="name"
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/vendor/vendor-data',
                    },
                    column: {
                      Vendor: 'name',
                      'Registered On': 'created_on',
                      Purpose: 'purpose',
                      'Vendor Category': 'vendor_category',
                      'Vendor Type': 'vendor_catgory_name',
                      Email: 'email',
                      Status: 'status',
                      Contact: 'contact_number',
                      Pan: 'pan_no',
                      Gst: 'gstin',
                      State: 'correspondenceState',
                      Country: 'correspondenceCountry.name',
                      Code: 'correspondencePinCode',
                      'Pan Copy': 'has_pan_copy',
                      'Cheque Copy': 'has_cheque_copy',
                      'Address Proof': 'has_address_proof',
                      'Contact Person': 'contact_person',
                      'Corresponding Address City':
                        'corresponding_address_city',
                      'Correspondence Address': 'correspondenceAddress',
                      'Account Number': 'account_number',
                      'Ifsc Code': 'ifsc_code',
                      'Account Type': 'account_type',
                      'Msme Certificate': 'has_msme_certificate',
                      'lut copy': 'has_lut_copy',
                      'GST State Code': 'gst_state_code',
                      'GST State': 'gst_state',
                      'GST Certificate': 'has_gst_certificate',
                      VAT: 'vat_number',
                      'Supply Type': 'supply_type',
                      'Lut Number': 'lut_number',
                      'ITR Attachment': 'has_ITR_attachment',
                      Unit: 'product.name',
                      Department: 'department.name',
                      Location: 'location_id.name',
                      'Payable Account': 'payable_account.name',
                      'Recoverable Account': 'pre_payment_account.name',
                    },
                    filename: 'All Vendors',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Registered On',
          field: 'created_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Vendor',
          field: 'name',
        },
        {
          field: 'purpose',
          type: 'text',
          header: 'Purpose',
          width: 160,
        },
        {
          header: 'Category',
          field: 'vendor_category',
          render: ({row}) => {
            if (row?.vendor_category == 'Domestic') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT6_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'Online') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT5_MEDIUM}
                />
              );
            } else if (row?.vendor_category == 'International') {
              return (
                <Tag
                  value={row?.vendor_category}
                  color={colors.ACCENT2_MEDIUM}
                />
              );
            }
          },
          width: 120,
        },

        {
          header: 'Contact & Region',
          render: ContactRegionRender,
          width: 190,
        },
        // {
        //   header: 'Email',
        //   field: 'email',
        //   width: 250,
        // },
        // {
        //   header: 'Region',
        //   //   render: ({row}) => {
        //   //     let region =
        //   //       (row?.correspondenceState || '') +
        //   //       ' ' +
        //   //       (row?.correspondenceCountry?.name || '');
        //   //     region = region.trim();
        //   //     return region.length ? <Text>{region}</Text> : '';
        //   //   },
        //   field: 'correspondenceState',
        //   width: 150,
        // },
        {
          field: 'email',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  copyText({isEmail: true, email: row?.email});
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image source={icon.atTheRate} />
              </TouchableOpacity>
            );
          },
        },
        {
          field: 'contact_number',
          align: 'center',
          width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.contact_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: vendorEditableDetailForm,
        },
        {
          title: 'Details',
          onPress: vendorNonEditableDetailForm,
        },

        tabName == 'new' && {
          title: 'Update Status',
          onPress: updateNewVendorStatus,
        },
        tabName == 'verified' && {
          title: 'Update Status',
          onPress: updateVerifiedVendorStatus,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVendor,
        },
      ]}
    />
  );
};

export default VendorTableView;
