import React from 'react';
import {Table} from '../../../components/table/Table';
import {TextRenderer} from '../../../components/form/Renderers';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';

const formatNumberWithCommas = num => {
  const integerPart = Math.round(num).toString();
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, integerPart.length - 3);
  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
    (otherDigits ? ',' : '') +
    lastThreeDigits;
  return formattedIntegerPart;
};

export const DepartmentPerformaceDetailsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  let {
    organizations = [],
    products = [],
    departmentId,
    period,
    addOnFilter,
    invoice_type,
  } = params;
  if (organizations?.length > 0) {
    addOnFilter = {
      ...addOnFilter,
      organization: {$in: organizations?.map(item => item._id)},
    };
  }
  if (products?.length > 0) {
    addOnFilter = {
      ...addOnFilter,
      product: {$in: products?.map(item => item._id)},
    };
  }
  const {colors} = useTheme();

  return (
    <Table
      api="/departmentWisePerformanceOverview"
      limit={50000}
      params={{
        departmentId,
        period: {
          from: period.from,
          to: period.to,
        },
        invoice_type,
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Purchase Category',
          field: 'purchase_category.name',
          type: 'text',
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview', {
              ...params,
              purchaseCategoryId: row?.purchase_category?._id,
              period: {
                from: moment(period.from).subtract(1, 'months').toDate(),
                to: moment(period.to).subtract(1, 'months').toDate(),
              },
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview', {
              ...params,
              purchaseCategoryId: row?.purchase_category?._id,
              invoice_type,
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },

        {
          header: 'Difference',
          type: 'text',
          align: 'left',
          field: 'expense_diff',
          formatOptions: {
            maximumFractionDigits: 0,
          },
          width: 200,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                formatNumberWithCommas(Math.abs(row.expense_diff)) +
                '(' +
                Number(Math.abs(row.expense_diff_percentage).toFixed(2)) +
                '%)'
              }
            />
          ),
          aggregate: true,
        },
      ]}
    />
  );
};

export const DepartmentPerformaceDetailsTableForSalary = props => {
  const {
    route: {params},
    navigation,
  } = props;

  let {
    organizations = [],
    products = [],
    departmentId,
    period,
    addOnFilter,
    invoice_type,
  } = params;

  if (organizations?.length > 0) {
    addOnFilter = {
      ...addOnFilter,
      organization: {$in: organizations?.map(item => item._id)},
    };
  }
  if (products?.length > 0) {
    addOnFilter = {
      ...addOnFilter,
      product: {$in: products?.map(item => item._id)},
    };
  }

  const {colors} = useTheme();

  return (
    <Table
      api="/departmentWisePerformanceOverviewForSalary"
      limit={50000}
      params={{
        departmentId,
        period: {
          from: period.from,
          to: period.to,
        },
        invoice_type,
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Employee',
          field: 'payee_id',
          type: 'userAvatarChip',
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview-salary', {
              ...params,
              vendorId: row?.payee_id?._id,
              period: {
                from: moment(period.from).subtract(1, 'months').toDate(),
                to: moment(period.to).subtract(1, 'months').toDate(),
              },
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview-salary', {
              ...params,
              vendorId: row?.payee_id?._id,
              invoice_type,
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },

        {
          header: 'Difference',
          type: 'text',
          align: 'left',
          field: 'expense_diff',
          formatOptions: {
            maximumFractionDigits: 0,
          },
          width: 200,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                formatNumberWithCommas(Math.abs(row.expense_diff)) +
                '(' +
                Number(Math.abs(row.expense_diff_percentage).toFixed(2)) +
                '%)'
              }
            />
          ),
          aggregate: true,
        },
      ]}
    />
  );
};

export const DepartmentPerformaceDirectDetailsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  let {
    organizations = [],
    products = [],
    departmentId,
    period,
    addOnFilter,
    invoice_type,
  } = params;
  if (organizations?.length > 0) {
    addOnFilter = {
      ...addOnFilter,
      organization: {$in: organizations?.map(item => item._id)},
    };
  }
  if (products?.length > 0) {
    addOnFilter = {
      ...addOnFilter,
      product: {$in: products?.map(item => item._id)},
    };
  }
  const {colors} = useTheme();

  return (
    <Table
      api="/getDepartmentDirectOverview"
      limit={50000}
      params={{
        departmentId,
        period: {
          from: period.from,
          to: period.to,
        },
        invoice_type,
        addOnFilter,
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Purchase Category',
          field: 'account.name',
          type: 'text',
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-direct-overview', {
              ...params,
              purchaseCategoryId: row?.account?._id,
              period: {
                from: moment(period.from).subtract(1, 'months').toDate(),
                to: moment(period.to).subtract(1, 'months').toDate(),
              },
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-direct-overview', {
              ...params,
              purchaseCategoryId: row?.account?._id,
              invoice_type,
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },

        {
          header: 'Difference',
          type: 'text',
          align: 'left',
          field: 'expense_diff',
          formatOptions: {
            maximumFractionDigits: 0,
          },
          width: 200,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                formatNumberWithCommas(Math.abs(row.expense_diff)) +
                '(' +
                Number(Math.abs(row.expense_diff_percentage).toFixed(2)) +
                '%)'
              }
            />
          ),
          aggregate: true,
        },
      ]}
    />
  );
};
