import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import {
  NewFixedProjectTable,
  NewProjectUnBilledTable,
  NewTMProjectTable,
} from './NewProjectTable';

const groupfiltersArray = [
  {
    type: 'autoComplete',
    label: 'Customer',
    api: '/customers',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'customer',
  },
  {
    type: 'autoComplete',
    options: [
      'Client Managed',
      'Daffodil Managed',
      'Hybrid Managed',
      'Daffodil Managed - Support',
    ],
    field: 'project_sub_category',
    label: 'Managed By',
  },
  {
    label: 'Tool',
    field: 'tool',
    placeholder: 'Select Tool',
    type: 'autoComplete',
    options: ['Locomo', 'Other'],
  },
  {
    label: 'Feedback Mode',
    field: 'feedback_mode',
    placeholder: 'Select Feedback Mode',
    type: 'autoComplete',
    options: ['Automatic', 'Manual'],
  },
  {
    label: 'Last automatic feedback sucess',
    field: 'automatic_feedback_request_success_date',
    type: 'date',
  },
  {
    label: 'Last automatic feedback failure',
    field: 'automatic_feedback_request_failure_date',
    type: 'date',
  },
];

export const NewProjectTab = props => {
  const {route: {params} = {}, addOnParamFilter = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {period = {}} = filterParams;
  const tabs = {
    t_and_m: {
      label: 'T&M',
      api: `/newBilledProject`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        projecttype: 'tm',
        createdAt: {
          $gte: period?.from,
          $lte: period?.to,
        },
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewTMProjectTable
          addOnFilter={{
            ...filter,
            projecttype: 'tm',
            createdAt: {
              $gte: period?.from,
              $lte: period?.to,
            },
          }}
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          tabName={'t&m'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id}}
        />,
      ],
    },

    fixed: {
      label: 'Fixed',
      api: `/newBilledProject`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        projecttype: 'f',
        createdAt: {
          $gte: period?.from,
          $lte: period?.to,
        },
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewFixedProjectTable
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          addOnFilter={{
            ...filter,
            projecttype: 'f',
            createdAt: {
              $gte: period?.from,
              $lte: period?.to,
            },
          }}
          tabName={'fixed'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlcplus'}}
        />,
      ],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Project"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupfiltersArray}
        />,
      ]}
    />
  );
};

export const NewProjectTandMTab = props => {
  const {route: {params} = {}, addOnParamFilter = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {period = {}} = filterParams;
  const tabs = {
    t_and_m: {
      apiKey: 'active',
      label: 'New',
      api: `/newBilledProject`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        projecttype: 'tm',
        createdAt: {
          $gte: period?.from,
          $lte: period?.to,
        },
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewTMProjectTable
          addOnFilter={{
            ...filter,
            projecttype: 'tm',
            createdAt: {
              $gte: period?.from,
              $lte: period?.to,
            },
          }}
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          tabName={'t&m'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id}}
        />,
      ],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Project"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupfiltersArray}
        />,
      ]}
    />
  );
};

export const NewProjectFixedTab = props => {
  const {route: {params} = {}, addOnParamFilter = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {period = {}} = filterParams;
  const tabs = {
    fixed: {
      apiKey: 'active',
      label: 'New',
      api: `/newBilledProject`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        projecttype: 'f',
        createdAt: {
          $gte: period?.from,
          $lte: period?.to,
        },
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewFixedProjectTable
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          addOnFilter={{
            ...filter,
            projecttype: 'f',
            createdAt: {
              $gte: period?.from,
              $lte: period?.to,
            },
          }}
          tabName={'fixed'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlcplus'}}
        />,
      ],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Project"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupfiltersArray}
        />,
      ]}
    />
  );
};

export const NewProjectUnbilledTab = props => {
  const {route: {params} = {}, addOnParamFilter = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {period = {}} = filterParams;
  const tabs = {
    internal: {
      label: 'Unbilled',
      api: `/newBilledProject`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        projecttype: {$in: ['i']},
        createdAt: {
          $gte: period?.from,
          $lte: period?.to,
        },
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewProjectUnBilledTable
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          addOnFilter={{
            ...filter,
            projecttype: {$in: ['i']},
            createdAt: {
              $gte: period?.from,
              $lte: period?.to,
            },
          }}
          tabName={'fixed'}
        />
      ),
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Project"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupfiltersArray}
        />,
      ]}
    />
  );
};
