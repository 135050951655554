import React from 'react';
import {AllUsersTabs, ClientUserTabs} from './views/ClientUserTabs';
import AppRoleTable from '../userManagement/views/AppRoleTable';
import UserRoleTable from '../userManagement/views/UserRoleTable';
import {AppResourceTabs} from '../userManagement/views/AppResourceTable';
import RolePermissionTable from '../userManagement/views/RolePermissionTable';
import RoleTable from '../userManagement/views/RoleTable';
import UserAccessTable from '../userManagement/views/UserAccessTable';
import {UserSetupTable} from '../common/views/UserSetupTable';
import {ClientUserTable} from './views/ClientUserTableView';
import DepartmentRoleTab from '../department/views/DepartmentRoleTabs';

export const ScreenMapping = [
  {
    id: 'clientUsers',
    view: ({user}) => {
      return <ClientUserTable user={user} />;
    },
  },
  {
    id: 'roles',
    view: <RoleTable />,
  },
  {
    id: 'appRole',
    view: <AppRoleTable />,
  },
  {
    id: 'appResource',
    view: <AppResourceTabs />,
  },
  {
    id: 'resourcePermission',
    view: <RolePermissionTable />,
  },
  {
    id: 'userRole',
    view: <UserRoleTable />,
  },
  {
    id: 'userResource',
    view: <UserAccessTable />,
  },
  {
    id: 'adminUserScreens',
    view: ({user}) => {
      return <UserSetupTable user={user} />;
    },
  },
  {
    id: 'allUsersScreen',
    view: ({user}) => {
      return <AllUsersTabs user={user} />;
    },
  },
  {
    id: 'departmentroles',
    view: <DepartmentRoleTab />,
  },
];

// export const Screens = [
//   {
//     menuItemId: 'users',
//     views: [
//       {
//         id: 'allUsers',
//         label: 'All Users',
//         view: ({user}) => {
//           return <ClientUserTabs user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'setup',
//     views: [
//       {
//         id: 'roles',
//         label: 'Role',
//         view: <RoleTable />,
//       },
//       {
//         id: 'appRole',
//         label: 'App Roles',
//         view: <AppRoleTable />,
//       },

//       {
//         id: 'appResource',
//         label: 'App Resource',
//         view: <AppResourceTable />,
//       },
//       {
//         id: 'resourcePermission',
//         label: 'Role Permission',
//         view: <RolePermissionTable />,
//       },
//       {
//         id: 'userRole',
//         label: 'User Role',
//         view: <UserRoleTable />,
//       },
//       {
//         id: 'userResource',
//         label: 'User Resource',
//         view: <UserAccessTable />,
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'users',
//     label: 'Users',
//   },
//   {
//     id: 'setup',
//     label: 'Setup',
//     // visible: ({user}) => {
//     //   return (
//     //     user?.email === 'sushil.nagvan@daffodilsw.com' ||
//     //     user?.email === 'amit.singh@daffodildb.com' ||
//     //     user?.email === 'amit@daffodilsw.com' ||
//     //     user?.email === 'rohit.bansal@daffodilsw.com' ||
//     //     user?.email === 'aswathy.krishnankutty@unthinkable.co'
//     //   );
//     // },
//   },
// ];
