// Creating the table for campaign global setting - Akshat Garg - 23/02/24
import React from 'react';
import {Table} from '../../../components/table/Table';

export const CampaignGlobalSettingList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  return (
    <Table
      api={`/campaignglobalsettings`}
      eventSourceId={'add-campaign-global-setting'}
      fields={{
        business_summary_prompt: {
          name: 1,
        },
        business_summary_finetune_model: {
          name: 1,
        },
        auto_verified_prompt: {
          name: 1,
        },
        auto_verification_use_case: {
          name: 1,
        },
        auto_verification_finetune_model: {
          name: 1,
        },
        personalization_prompt: {name: 1},
        manual_personalization_prompt: {name: 1},
        manual_linkedin_personalization_prompt: {name: 1},
      }}
      columns={[
        {
          header: 'Business Summary Prompt',
          type: 'text',
          field: 'business_summary_prompt.name',
        },
        {
          header: 'Business Summary Finetune Model',
          type: 'text',
          field: 'business_summary_finetune_model.name',
        },
        {
          header: 'Auto Verification Email Prompt',
          type: 'text',
          field: 'auto_verified_prompt.name',
        },
        {
          header: 'Auto Verification Use Case',
          type: 'text',
          field: 'auto_verification_use_case.name',
        },
        {
          header: 'Auto Verification Finetune Model',
          type: 'text',
          field: 'auto_verification_finetune_model.name',
        },
        {
          header: 'Personalization Prompt',
          type: 'text',
          field: 'personalization_prompt.name',
        },
        {
          header: 'Manual Personalization Prompt',
          type: 'text',
          field: 'manual_personalization_prompt.name',
        },
        {
          header: 'Manual Linkedin Personalization Prompt',
          type: 'text',
          field: 'manual_linkedin_personalization_prompt.name',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-campaign-global-setting', {
              row: {_id: row._id},
            });
          },
        },
      ]}
    />
  );
};
