import React from 'react';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

export const ProjectTeamTable = props => {
  const {
    navigation,
    route: {params},
    source,
    isClient,
  } = props;
  const {project} = params;

  const addOnFilter = {};
  const date = getZeroTimeDate(new Date());

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: ['Client', 'Project'],
  });

  switch (source) {
    case 'History':
      addOnFilter.to_date = {$lt: date};
      addOnFilter.from_date = {$lt: date};
      break;
    case 'Active':
      addOnFilter.to_date = {$gte: date};
      addOnFilter.from_date = {$lte: date};
      break;
    case 'Upcoming':
      addOnFilter.to_date = {$gt: date};
      addOnFilter.from_date = {$gt: date};
      break;
  }

  const api = isClient ? '/clientassignments' : '/employeeassignments';
  const otherFields = isClient ? {client: {name: 1}} : {employee: {name: 1}};

  return (
    <Table
      eventSourceId={['Employee', 'Client']}
      api={api}
      sort={{to_date: -1}}
      filter={{
        project_id: project._id,
        is_wrong_input: {$in: [null, false]},
        ...addOnFilter,
      }}
      fields={{
        ...otherFields,
        from_date: 1,
        to_date: 1,
        percentage: 1,
        hours: 1,
      }}
      columns={[
        isClient
          ? {
              type: 'text',
              field: 'client.name',
              header: 'Client',
            }
          : {
              type: 'text',
              field: 'employee.name',
              header: 'Employee',
            },
        {
          type: 'date',
          field: 'from_date',
          header: 'From',
          width: 100,
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        {
          type: 'date',
          field: 'to_date',
          header: 'To',
          width: 100,
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        {
          type: 'number',
          field: 'hours',
          header: 'Allocation (in hrs)',
          width: 150,
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          type: 'number',
          field: 'percentage',
          header: 'Percentage',
          width: 100,
          value: ({row}) => parseInt(((row.hours || 0) / 9) * 100),
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`project-client-member-detail`, {
              ...params,
              client_assignment_id: {_id: row?._id},
            });
          },
          visible: () => isClient,
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete client?',
            confirmText: 'Delete',
          },
          onPress: props => {
            const {row} = props;
            deleteInvoke({
              uri: `/clientassignments/${row?._id}`,
              props: {},
            });
          },
          visible: () => isClient,
        },
      ]}
    />
  );
};
