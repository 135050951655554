import React from 'react';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Chip} from '../../../components/chip/Chip';
import {
  Col,
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Table} from '../../../components/table/Table';
import {customDigitalPlatformOppControllers} from '../controllers/customDigitalPlatformControllers';
import {leadControllers} from '../controllers/leadControllers';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {useProjectActions} from '../../pmt/actions/ProjectActions';

const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const ProposalRender = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: CountCellRender,
          responsive: 'sm',
          align: 'right',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-proposal`, {
              opportunity: row,
            });
          },
          visible: ({row}) => !row?.quoted_amount && !row?.proposal_status,
        },
        {
          render: CountCellRender,
          responsive: 'sm',
          align: 'right',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-proposal`, {
              opportunity: row,
            });
          },
          visible: ({row}) =>
            !row?.quoted_amount &&
            row?.proposal_status &&
            row?.proposal_status != 'Re-Estimation',
        },
        {
          render: props => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  View
                </Text>
              </View>
            );
          },
          align: 'right',
          visible: ({row}) =>
            (row?.quoted_amount &&
              row?.proposal_status &&
              row?.proposal_status != 'Re-Estimation') ||
            (row?.quoted_amount && !row?.proposal_status),
          onPress: () => {
            const {row} = props;
            console.log('row>>>>>>>>>.', row);
            navigation.navigate(`opportunity-show-submit-proposal`, {
              opportunity: row,
            });
          },
        },
        {
          render: props => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  Update
                </Text>
              </View>
            );
          },
          // align: 'right',
          visible: ({row}) =>
            row?.quoted_amount && row?.proposal_status == 'Re-Estimation',
          onPress: () => {
            const {row} = props;
            navigation.navigate(
              `opportunity-show-submit-proposal-restimation`,
              {
                opportunity: row,
              },
            );
          },
        },
        {
          render: CountCellRender,
          responsive: 'sm',
          align: 'right',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(
              `opportunity-show-submit-proposal-restimation`,
              {
                opportunity: row,
              },
            );
          },
          // align: 'right',
          visible: ({row}) =>
            !row?.quoted_amount && row?.proposal_status == 'Re-Estimation',
        },
      ]}
    />
  );
};

const StatusRender = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  if (row?.status == 'invalidRfq') {
    return (
      <Text
        style={{
          ...rowText,
        }}>
        Drop
      </Text>
    );
  } else {
    return (
      <Text
        style={{
          ...rowText,
        }}>
        {row?.status}
      </Text>
    );
  }
};
const MoveToProposalRender = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: CountCellRender,
          responsive: 'sm',
          align: 'right',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-quote`, {
              opportunity: row,
            });
          },
          visible: ({row}) => {
            if (!row?.is_proposal_added) {
              return true;
            }
          },
        },
        {
          render: props => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  View
                </Text>
              </View>
            );
          },
          visible: ({row}) => {
            if (row?.is_proposal_added) {
              return true;
            }
          },

          onPress: () => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-quote`, {
              opportunity: row,
            });
          },
        },
      ]}
    />
  );
};
const ProposalRenderForWon = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: () => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  View
                </Text>
              </View>
            );
          },
          align: 'right',
          visible: ({row}) => row?.quoted_amount,
          onPress: () => {
            const {row} = props;
            navigation.navigate(`opportunity-show-submit-proposal`, {
              // ...params,
              opportunity: row,
            });
          },
        },
      ]}
    />
  );
};

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

// const leadScoreRender = props => {
//   return (
//     <CompositeFieldsRender
//       {...props}
//       fields={[
//         {field: 'customer_id.lead_score', type: 'number'},
//         {
//           field: 'potential_customer.lead_score',
//           type: 'number',
//         },
//       ]}
//     />
//   );
// };


const leadScoreRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.rfr_type == 'PotentailCustomer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.potential_customer?.lead_score}
        </Text>
      </>
    );
  } else if (row?.rfr_type == 'Customer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.customer_id?.lead_score}
        </Text>
      </>
    );
  }
};

const RenderPratice = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  // const colors = useTheme('colors');
  const {INFORMATION_MEDIUM, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  const practiceName = row?.practice
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!practiceName) return null;
  return (
    // <Text
    //   style={{
    //     ...rowText,
    //     backgroundColor: colors['ACCENT1_MEDIUM'],
    //     paddingRight: 8,
    //     paddingLeft: 8,
    //     paddingTop: 4,
    //     paddingBottom: 4,
    //     borderRadius: 5,
    //     overflow: 'hidden',
    //   }}>
    //   {practiceName}
    // </Text>
    <Chip
      color={INFORMATION_MEDIUM}
      textColor={INFORMATION_HIGH}
      value={practiceName}
      borderRadius={6}
    />
  );
};

const RejectRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description_comment', type: 'text'},
        {
          field: 'outcome_date',
          type: 'date',
        },
      ]}
    />
  );
};

const SubStageRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  let newDate = new Date(row?.sub_stage_date).toLocaleTimeString('en-US');
  if (newDate != 'Invalid Date') {
    return (
      <>
        <Text
          title={row?.sub_stage}
          numberOfLines={1}
          style={{
            ...rowText,
            overflow: 'hidden',
          }}>
          {row?.sub_stage}
        </Text>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          {newDate}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const ProposalStatusRender = ({row}) => {
  if (
    row?.proposal_status &&
    (row?.proposal_status == 'Negotiation' ||
      row?.proposal_status == 'Walkthorugh done' ||
      row?.proposal_status == 'Client Review')
  ) {
    return (
      <Chip
        textColor="#62D06D"
        color="#F3FCF0"
        value={row?.proposal_status}
        borderRadius={6}
      />
    );
  } else if (
    row?.proposal_status &&
    (row?.proposal_status == 'Proposal Walkthrough' ||
      row?.proposal_status == 'Re-Estimation' ||
      row?.proposal_status == 'Revise Proposal')
  ) {
    return (
      <Chip
        textColor="#f99b2e"
        color="#FFF9F1"
        value={row?.proposal_status}
        borderRadius={6}
      />
    );
  } else {
    return null;
  }
};

const QuoteRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[{field: 'quote_status', type: 'text'}]}
    />
  );
};

const LostRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description', type: 'text'},
        {
          field: 'outcome_date',
          type: 'date',
        },
      ]}
    />
  );
};

const WinabilityRender = ({row}) => {
  if (row?.winning_chance && row?.winning_chance == 'High') {
    return (
      <Chip
        color="#DAFDFE"
        textColor="BLACK"
        value={row?.winning_chance}
        borderRadius={6}
      />
    );
  } else if (row?.winning_chance && row?.winning_chance == 'Mid') {
    return (
      <Chip
        textColor="Black"
        color="#FEEFE0"
        value={row?.winning_chance}
        borderRadius={6}
      />
    );
  } else if (row?.winning_chance && row?.winning_chance == 'Low') {
    return (
      <Chip
        textColor="Black"
        color="#FADFEF"
        value={row?.winning_chance}
        borderRadius={6}
      />
    );
  } else {
    return null;
  }
};

const EmployeeCountRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.employee_count.number', type: 'text'},
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
        },
      ]}
    />
  );
};

const CountryRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.country.name', type: 'text'},
        {
          field: 'customer_id.country.name',
          type: 'text',
        },
      ]}
    />
  );
};

const CUSTOMER = {
  header: 'Opportunity',
  render: CustomerRender,
  responsive: 'sm',
};

const LEADSCORERENDER = {
  header: 'Lead Score',
  render: leadScoreRender,
  responsive: 'sm',
  align: 'right',
};

const REJECTION = {
  header: 'Lost Reason',
  render: RejectRender,
};

const DROP_REJECTION = {
  header: 'Drop Reason',
  render: RejectRender,
};

const PROPOSAL_STATUS = {
  render: ProposalStatusRender,
  header: 'Proposal Status',
};

const WINABILITY = {
  render: WinabilityRender,
  header: 'Winnablitiy',
  // width: 80,
};

const SUBSTAGERENDER = {
  render: SubStageRender,
  header: 'Sub Stage',
  // responsive: 'sm',
  width: 150,
  // width:100
};
const OWNER = {
  field: 'owner',
  width: 75,
  header: 'Sales',
  type: 'userAvatar',
};
const PRIMARYOWNER = {
  field: 'primary_owner',
  width: 75,
  header: 'PSC',
  type: 'userAvatar',
};
const PRESALES = {
  field: 'rfq_owner',
  width: 100,
  header: 'Pre Sales',
  type: 'userAvatarGroup',
};

const QUOTE = {
  header: 'Quote',
  render: QuoteRender,
};

const OUTCOMEDATE = {
  type: 'date',
  field: 'outcome_date',
  header: 'Outcome Date',
};

const LOSTREASON = {
  header: 'Reason for Lost',
  render: LostRender,
};

const DROPREASON = {
  header: 'Reason for Drop',
  render: LostRender,
};

const CREATEDON = {
  header: 'Created On',
  field: 'creation_date',
  type: 'date',
};
const EMPLOYEE_COUNT = {
  header: 'Size',
  render: EmployeeCountRender,
  responsive: 'sm',
  width: 160,
};
const COUNTRY = {
  header: 'Country',
  render: CountryRender,
  responsive: 'sm',
  width: 160,
};

export const PreSalesUnassignedTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {assignPreSales, customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/unassigned"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      // selection={{
      //   actions: [{title: 'Assign Pre Sales', onPress: assignPreSales}],
      // }}
      searchFields="requirement"
      columns={[
        {...CREATEDON, width: 120},

        {...CUSTOMER, width: 300},
        ,
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },

        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
        },
        {
          header: 'Practice',
          render: RenderPratice,
          width: 150,
        },
        {
          render: CountCellRender,
          header: 'Owner',
          align: 'right',
          width: 150,
          responsive: 'sm',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`assign-tech-person`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {...LEADSCORERENDER, width: 100},

        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesUnassignedTableForSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  const {assignPreSales, customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/unassigned"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      // selection={{
      //   actions: [{title: 'Assign Pre Sales', onPress: assignPreSales}],
      // }}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      }}
      columns={[
        {...CUSTOMER, width: 250},
        ,
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },

        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 650,
        },
        {
          header: 'Practice',
          render: RenderPratice,
          width: 320,
        },
        {
          render: CountCellRender,
          header: 'Owner',
          align: 'right',
          width: 150,
          responsive: 'sm',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`assign-tech-person`, {
              ...params,
              opportunity: row,
            });
          },
        },
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
        CREATEDON,
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesActiveTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    assignToTechPerson,
    assignTechPersonOnActive,
    updateSubStage,
    updatePractice,
    UpdateSolutionConsultant,
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    UpdatePreSales,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['CustomPlatformOpportunity'],
  });
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      source: '64e06ebc94f415bdfd0a4aef',
      label: 'Custom Platform',
    });
  };

  const moveToProposal = ({row}) => {
    invoke({
      uri: `/opportunities/${row?._id}`,
      props: {
        quote_status: 'Submitted',
        sub_stage: 'Sales Review',
        sub_stage_date: new Date(),
        quote_date: new Date(),
      },
    });
  };
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/active"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      selection={{
        actions: [
          {
            title: 'Update Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
          {
            title: 'Update Pre Sales',
            onPress: UpdatePreSales,
          },
          {title: 'Update Sub Stage', onPress: updateSubStage},
          // {
          //   title: 'Update Solution Consultant',
          //   onPress: UpdateSolutionConsultant,
          // },
          {title: 'Update Practice', onPress: updatePractice},
          // {title: 'Mark Won', onPress: opportunityMarkWon},
          // {title: 'Mark Lost', onPress: opportunityMarkLost},
          // {title: 'Drop RFQ', onPress: opportunityDropRfq},
        ],
      }}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      searchFields="requirement"
      columns={[
        {...CREATEDON, width: 120},
        {...CUSTOMER, width: 300},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        //   width: 240,
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
        },
        {
          header: 'Practice',
          render: RenderPratice,
          width: 150,
        },
        {
          header: 'Quotation',
          render: props => {
            return (
              <MoveToProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          align: 'right',
          width: 100,
          // responsive: 'sm',
        },
        {...PRESALES, width: 150},
        {...PRIMARYOWNER, width: 80},
        {...OWNER, width: 80},
        {...LEADSCORERENDER, width: 100},

        SUBSTAGERENDER,
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 150,
        // },
      ]}
      moreActions={({row}) => [
        {
          title: 'Tasks',
          onPress: showTasks,
        },
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
        ...(row.sub_stage == 'Proposal Drafting'
          ? [
              {
                title: 'Move To Proposal',
                onPress: moveToProposal,
              },
            ]
          : []),
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};

export const PreSalesActiveTableSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {
    assignToTechPerson,
    assignTechPersonOnActive,
    updateSubStage,
    updatePractice,
    UpdateSolutionConsultant,
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['CustomPlatformOpportunity'],
  });

  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      source: '64e06ebc94f415bdfd0a4aef',
      label: 'Custom Platform',
    });
  };

  const moveToProposal = ({row}) => {
    invoke({
      uri: `/opportunities/${row?._id}`,
      props: {
        quote_status: 'Submitted',
        sub_stage: 'Sales Review',
        sub_stage_date: new Date(),
      },
    });
  };
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/active/solution-consultant"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Update Solution Consultant',
      //       onPress: assignTechPersonOnActive,
      //     },
      //     {title: 'Update Sub Stage', onPress: updateSubStage},
      //     // {
      //     //   title: 'Update Solution Consultant',
      //     //   onPress: UpdateSolutionConsultant,
      //     // },
      //     {title: 'Update Practice', onPress: updatePractice},
      //     // {title: 'Mark Won', onPress: opportunityMarkWon},
      //     // {title: 'Mark Lost', onPress: opportunityMarkLost},
      //     // {title: 'Drop RFQ', onPress: opportunityDropRfq},
      //   ],
      // }}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        {...CUSTOMER, width: 250},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        //   width: 240,
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          width: 400,
        },
        {
          header: 'Practice',
          render: RenderPratice,
          width: 200,
        },
        SUBSTAGERENDER,
        {
          header: 'Quotation',
          render: props => {
            return (
              <MoveToProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          align: 'right',
          // width:100,
          responsive: 'sm',
        },
        OWNER,
        PRESALES,
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 150,
        // },
        CREATEDON,
      ]}
      moreActions={({row}) => [
        {
          title: 'Tasks',
          onPress: showTasks,
        },
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
        // ...(row.sub_stage == 'Proposal Drafting'
        //   ? [
        //       {
        //         title: 'Move To Proposal',
        //         onPress: moveToProposal,
        //       },
        //     ]
        //   : []),
      ]}
    />
  );
};

export const PreSalesProposalSubTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  console.log({params});
  const {
    assignToTechPerson,
    customPlatformOpportunityCommunications,
    updateProposalStatusPreSales,
    opportunityMarkWonRowActive,
    opportunityDropRfqRowAction,
    opportunityMarkLostRowAction,
    assignTechPersonOnActive,
  } = customDigitalPlatformOppControllers(props);

  return (
    <Table
      {...props}
      params={params}
      selection={{
        actions: [
          {
            title: 'Update Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
        ],
      }}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      api="/opportunities/proposal"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {...CUSTOMER, width: 300},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
        },
        {
          header: 'Quotation',
          render: props => {
            return (
              <MoveToProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          align: 'right',
          width: 120,
          // responsive: 'sm',
        },
        // {...SUBSTAGERENDER, width: 100},
        {
          header: 'Proposal Val.',
          render: props => {
            return (
              <ProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },

          width: 120,
          align: 'right',
        },

        {...PROPOSAL_STATUS, width: 150},
        {...WINABILITY, width: 100},
        {...PRESALES, width: 100},
        {...PRIMARYOWNER, width: 80},
        {...OWNER, width: 80},
        {...LEADSCORERENDER, width: 100},

        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Change Status & Win-ability',
          onPress: updateProposalStatusPreSales,
        },
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};
export const PreSalesProposalSubTableSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  console.log({params});
  const {
    assignToTechPerson,
    customPlatformOpportunityCommunications,
    updateProposalStatusPreSales,
    opportunityMarkWonRowActive,
    opportunityDropRfqRowAction,
    opportunityMarkLostRowAction,
    assignTechPersonOnActive,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Update Solution Consultant',
      //       onPress: assignTechPersonOnActive,
      //     },
      //   ],
      // }}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      api="/opportunities/proposal/solution-consultant"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        {...CUSTOMER, width: 250},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          width: 460,
        },
        // {...SUBSTAGERENDER, width: 100},
        {
          header: 'Quotation',
          render: props => {
            return (
              <MoveToProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          align: 'right',
          // width:100,
          responsive: 'sm',
        },
        {
          header: 'Proposal Val.',
          render: props => {
            return (
              <ProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 100,
          align: 'right',
        },
        PROPOSAL_STATUS,
        WINABILITY,
        OWNER,
        PRESALES,

        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
      ]}
      moreActions={() => [
        // {
        //   title: 'Change Status & Win-ability',
        //   onPress: updateProposalStatusPreSales,
        // },
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesProposalSubmittedWonTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {addCustomerFromCustomPlatformOpportunity} = leadControllers(props);
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/won"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {...CUSTOMER, width: 400},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 680,
        },

        {...PRESALES, width: 120},
        {...PRIMARYOWNER, width: 80},
        {...OWNER, width: 80},
        {...LEADSCORERENDER, width: 100},
        {
          field: 'quote_date',
          header: 'Quote Date',
          type: 'date',
          width: 120,
        },
        {
          header: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal Value',
          field: 'quoted_amount',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },

        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 200,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesProposalSubmittedWonTableSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  const {addCustomerFromCustomPlatformOpportunity} = leadControllers(props);
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/won/solution-consultant"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        {...CUSTOMER, width: 300},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 680,
        },
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        OWNER,
        PRESALES,
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 200,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesProposalSubmittedLostTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/lost"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {
          field: 'creation_date',
          header: 'Created On',
          type: 'date',
          width: 120,
        },
        {...CUSTOMER, width: 250},

        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          width: 250,
        },

        PRESALES,
        PRIMARYOWNER,
        OWNER,
        {...LEADSCORERENDER, width: 100},

        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
        {...REJECTION, width: 250},

        {
          field: 'quote_date',
          header: 'Quote Date',
          type: 'date',
          width: 120,
        },
        {
          header: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal Value',
          field: 'quoted_amount',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesProposalSubmittedLostTableSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/lost/solution-consultant"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        {...CUSTOMER, width: 250},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 160,
        },
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        OWNER,
        PRESALES,
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
        REJECTION,
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesProposalSubmittedDropTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;

  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/drop"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {
          field: 'creation_date',
          header: 'Created On',
          type: 'date',
          width: 120,
        },
        {...CUSTOMER, width: 250},

        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 160,
        },

        PRESALES,
        PRIMARYOWNER,
        OWNER,
        {...LEADSCORERENDER, width: 100},

        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 200,
        // },
        {...DROP_REJECTION, width: 150},

        {
          field: 'quote_date',
          header: 'Quote Date',
          type: 'date',
          width: 120,
        },
        {
          header: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal Value',
          field: 'quoted_amount',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 100,
          align: 'right',
        },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};
export const PreSalesProposalSubmittedDropTableSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;

  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/drop/solution-consultant"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        {...CUSTOMER, width: 250},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 160,
        },
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        OWNER,
        PRESALES,
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 200,
        // },
        {...DROP_REJECTION, width: 150},
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesAllTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;

  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {assignPreSales, customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/all"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      // selection={{
      //   actions: [{title: 'Assign Pre Sales', onPress: assignPreSales}],
      // }}
      searchFields={['requirement']}
      columns={[
        {...CREATEDON, width: 120},
        {...CUSTOMER, width: 300},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          // width: 560,
        },

        {
          header: 'Practice',
          render: RenderPratice,
          width: 150,
        },
        // {
        //   render: CountCellRender,
        //   header: 'Owner',
        //   align: 'right',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`assign-tech-person`, {
        //       ...params,
        //       opportunity: row,
        //     });
        //   },
        // },
        {...PRESALES, width: 100},
        {...PRIMARYOWNER, width: 80},
        {...OWNER, width: 80},
        {...LEADSCORERENDER, width: 100},
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
        {
          header: 'Quote',
          field: 'quote_status',
          width: 100,
        },
        {
          header: 'Quote Date',
          field: 'quote_status_date',
          type: 'date',
          width: 120,
        },
        {
          header: 'Status',
          // field: 'status',
          render: StatusRender,
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};

export const PreSalesAllTableForSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;

  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  console.log('employeeId>>>>>>>>', employeeId);

  const {assignPreSales, customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/all"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('presales-opportunity-details', row);
      }}
      // selection={{
      //   actions: [{title: 'Assign Pre Sales', onPress: assignPreSales}],
      // }}
      searchFields={['requirement']}
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId._id}],
      }}
      columns={[
        {...CUSTOMER, width: 250},
        // EMPLOYEE_COUNT,
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        // COUNTRY,
        {
          header: 'Requirement',
          field: 'requirement',
          width: 560,
        },

        {
          header: 'Practice',
          render: RenderPratice,
          width: 120,
        },
        // {
        //   render: CountCellRender,
        //   header: 'Owner',
        //   align: 'right',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`assign-tech-person`, {
        //       ...params,
        //       opportunity: row,
        //     });
        //   },
        // },
        OWNER,
        PRESALES,
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     customPlatformOpportunityCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        // },
        CREATEDON,
        {
          header: 'Quote',
          field: 'quote_status',
          width: 100,
        },
        {
          header: 'Quote Date',
          field: 'quote_status_date',
          type: 'date',
        },
        {
          header: 'Status',
          // field: 'status',
          render: StatusRender,
          // width: 50
        },
      ]}
      moreActions={() => [
        {
          title: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
        },
      ]}
    />
  );
};
