import React from 'react';
import {Table} from '../../../components/table/Table';

export const ErrorLogList = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {filter} = params || {};


  return (
    <Table
      api={`/errorlogs`}
      fields={{
        error: 1,
        type: 1,
        source_id: 1,
        created_by: {name: 1},
        extra_data: 1,
        createdAt: 1,
      }}
      sort={{createdAt: -1}}
      params={params}
      filter={filter}
      columns={[
        {
          field: 'error',
          type: 'textArea',
          header: 'Error',
        },
        {
          field: 'type',
          type: 'text',
          header: 'Type',
          width:250
        },
        {
          field: 'extra_data',
          type: 'textArea',
          header: 'Extra Data',
        },
        {
          field: 'createdAt',
          type: 'date',
          header: 'Date',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
      ]}
      {...props}
    />
  );
};
