import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const MarkTrainerInactiveForm = props => {
  const {
    route: {params},
  } = props;

  const {mentor} = params || {};
  
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
  });
  return (
    <Form
      header={{
        title: 'Mark Trainer Inactive',
        secondaryTitle: mentor?.name,
      }}
      defaultValues={{
        _id: mentor?._id,
        is_trainer_inactive: true,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Reason',
          field: 'trainer_inactive_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
