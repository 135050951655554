import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {useFetchData} from '../../../controllers/useFetchData';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

export const ClientProjectForm = props => {
  let {
    route: {params = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'ClientProject',
    ...props,
  });

  let {project, new_project_sub_type} = params || {};
  const {user} = useAppStateContext();
  const {employee = {}, org} = user;

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        user_organization: org,
        project_status: 'a',
        owner_id: employee?._id && employee,
        created_on: getZeroTimeDate(new Date()),
        tool: 'Locomo',
        new_project_sub_type: new_project_sub_type,
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'project',
          label: 'Project Name',
          required: true,
        },
        {
          label: 'Customer',
          field: 'customer',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: () => {
            if (org?.is_service_industry) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          type: 'aiTextArea',
          field: 'desc',
          required: true,
          label: 'Description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        // {
        //   label: 'PM',
        //   field: 'owner_id',
        //   type: 'autoComplete',
        //   api: `/employeeSuggestions`,
        //   suggestionField: 'name',
        //   secondarySuggestionField: 'official_email_id',
        //   valueField: 'name',
        //   size: 6,
        //   filter: {
        //     user_organization: org,
        //   },
        // },
        {
          label: 'Label',
          field: 'new_project_sub_type',
          type: 'autoComplete',
          api: `/projectsubtypes`,
          visible: () => {
            if (org?.manual_labeling && !new_project_sub_type) {
              return true;
            } else {
              return false;
            }
          },
          suggestionField: 'project_sub_type',
          valueField: 'project_sub_type',
          keyField: 'project_sub_type',
          // required: true,
        },
      ]}
    />
  );
};

export const ClientProjectEditableForm = props => {
  let {
    route: {params = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'ClientProject',
    ...props,
  });

  let {project, new_project_sub_type} = params || {};
  const {user} = useAppStateContext();
  const {employee = {}, org} = user;

  return (
    <Form
      {...props}
      api={`/projects/${project?._id}`}
      fields={{
        project: 1,
        code: 1,
        desc: 1,
        owner_id: {name: 1},
        new_project_sub_type: {project_sub_type: 1},
        customer: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      // defaultValues={defaultValues}
      layoutFields={[
        {
          type: 'text',
          field: 'project',
          label: 'Project Name',
          required: true,
        },
        {
          label: 'Customer',
          field: 'customer',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: () => {
            if (org?.is_service_industry) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          type: 'aiTextArea',
          field: 'desc',
          required: true,
          label: 'Description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'PM',
          field: 'owner_id',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          filter: {
            user_organization: org,
          },
          // required: true,
        },
        {
          label: 'Label',
          field: 'new_project_sub_type',
          type: 'autoComplete',
          api: `/projectsubtypes`,
          visible: () => {
            if (org?.manual_labeling) {
              return true;
            } else {
              return false;
            }
          },
          suggestionField: 'project_sub_type',
          valueField: 'project_sub_type',
          keyField: 'project_sub_type',
          // required: true,
        },
      ]}
    />
  );
};

export const ClientAddProjectForm = props => {
  return (
    <ClientProjectForm
      header={{
        title: 'Add Project',
      }}
      {...props}
    />
  );
};

export const ClientEditProjectForm = props => {
  return (
    <ClientProjectEditableForm
      mode="edit"
      header={{
        title: 'Edit Project',
      }}
      {...props}
    />
  );
};

export const ClientDetailProjectForm = props => {
  return (
    <ClientProjectEditableForm
      mode="edit"
      readOnly={true}
      header={{
        title: 'Detail Project',
      }}
      {...props}
    />
  );
};
