import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {
  COORferredReimbursement,
  PracticeReimbursementTab,
  RmReimbursementTab,
} from '../../reimbursement/views/ReimbursementTabs';
import {ProjectTabs} from '../../pmt/views/ProjectTabs';
import {OutreachTargetAudienceTabs} from '../../leadGeneration/outreachcampaign-views/OutreachTargetAudienceTabs';
import {AssignedTicketTabs} from '../../tickets/views/AssignedTicketsTabs';
import {useAppStateContext} from '../../../providers/AppState';
import {
  ManPowerRequisitionTab,
  MrfApproverTab,
} from '../../recruitment/views/ManPowerRequisitionTab';
import {OpportunityProjectionList} from '../../opportunityProjection/views/opportunityProjectionLists';
import {MyOrdersTable} from './MyOrdersTable';
import {NonEnggTeams} from '../../employee/views/NonEnggTeams';
import {MyEfficiencyTable} from '../../task/views/EfficiencyTable';
import {
  EngineeringOpportunitiesTabView,
  EngineeringOpportunitiesTabViewForDeliveryOwners,
} from '../../engineeringOpportunities/views/EngineeringOpportunitiesTabs';
import {
  NoDuesAccountsTabs,
  NoDuesAdminTabs,
  NoDuesHRTabs,
  NoDuesITNetworkTabs,
} from '../../employee/views/NoduesTabs';
import {ExpenseApproverTab} from '../../Expense/view/ExpenseApproverTab';
import {QuotationApproverTab} from '../../Expense/view/QuotationApproverTab';
import {FnFAccountsTabs} from '../../employee/views/F&FTabView';
import {BudgetApproverTab} from '../../Expense/view/BudgetApproverTab';
import {MyCandidateFeedbackTabs} from '../../recruitment/views/MyCandidateFeedbacks';
import {TransactionCOOTabs} from './TransactionCOOTabs';
import TeamProjectFeedbackTab from '../../department/views/TeamProjectFeedbackTabs';
import RMFeedbackTab from '../../department/views/ResourceManagementFeedbackTab';
import PHAppraisalTab from '../../department/views/PHAppraisalTab';

const WorkSpaceGroup = createMenuNavigator();

const WorkSpaceNavigator = props => {
  const {user} = useAppStateContext();
  const {email: employeeEmail} = user;
  const accessRightMenus = [];

  if (
    employeeEmail == 'amit.singh@daffodildb.com' ||
    employeeEmail == 'sushil.nagvan@daffodilsw.com'
  ) {
    accessRightMenus
      .push
      // <WorkSpaceGroup.Screen
      //   name="Products_old_View"
      //   options={{
      //     label: 'Products old View',
      //     onPress: () => {
      //       props.navigation.navigate('all-departments', {});
      //     },
      //   }}
      // />,
      ();

    // accessRightMenus.push(
    //   <WorkSpaceGroup.Screen
    //     data-resource-id="PracticeHeadReimbursement"
    //     component={PracticeReimbursementTab}
    //     options={{
    //       label: 'Practice Reimbursement',
    //     }}
    //   />,
    // );

    // accessRightMenus.push(
    //   <WorkSpaceGroup.Screen
    //     data-resource-id="ResourceManagerReimbursement"
    //     component={RmReimbursementTab}
    //     options={{
    //       label: 'RM Reimbusrsement',
    //     }}
    //   />,
    // );

    // accessRightMenus.push(
    //   <WorkSpaceGroup.Screen
    //     name="ManPowerRequisition"
    //     component={ManPowerRequisitionTab}
    //     props={{user: user, fromMyView: true}}
    //     options={{
    //       label: 'Man Power Requisition',
    //     }}
    //   />,
    // );

    // accessRightMenus.push(
    //   <WorkSpaceGroup.Screen
    //     name="MrfApprover"
    //     data-resource-id="MrfApprover"
    //     component={MrfApproverTab}
    //     props={{user: user, fromMyView: true}}
    //     options={{
    //       label: 'Mrf Approver',
    //     }}
    //   />,
    // );

    accessRightMenus
      .push
      // <WorkSpaceGroup.Screen
      //   data-resource-id="HrRecruitment"
      //   props={{user: user, fromMyView: true}}
      //   options={{
      //     label: 'Hr Recruitment',
      //     onPress: () => {
      //       props.navigation.navigate('hr-recruitment-menu', {
      //         params: {
      //           ...props?.route?.params,
      //         },
      //       });
      //     },
      //   }}
      // />,
      ();
  }
  return (
    <WorkSpaceGroup.Navigator data-navigator-id="Workspace">
      <WorkSpaceGroup.Group name="about_me">
        <WorkSpaceGroup.Screen
          data-resource-id="My tasks"
          name="Project Tasks"
          options={{
            onPress: () => {
              props.navigation.navigate('my-project-task-menu', {
                ...props?.route?.params,
                userId: user?._id,
                fromMyView: true,
              });
            },
            label: 'Tasks (Project)',
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My tasks"
          name="Custom Tasks"
          options={{
            onPress: () => {
              props.navigation.navigate('my-organization-task-menu', {
                ...props?.route?.params,
                userId: user?._id,
                fromMyView: true,
              });
            },
            label: 'Tasks (Custom)',
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My efficiency"
          name="My output"
          component={MyEfficiencyTable}
          props={{user, fromMyView: true}}
          options={{
            label: 'Output',
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          name="MrfApprover"
          data-resource-id="MrfApprover"
          component={MrfApproverTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Talent Requisition Requests',
          }}
        />
        {/* <WorkSpaceGroup.Screen
          // data-resource-id="My Trainer"
          options={{
            label: 'Trainees',
            onPress: () => {
              props.navigation.navigate('self-trainer-for-workspace', {});
            },
          }}
        /> */}
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          data-resource-id="My Target Audience"
          props={{user: user}}
          component={OutreachTargetAudienceTabs}
          options={{
            label: 'Target Audience',
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My Campaign"
          options={{
            label: 'Campaigns',
            onPress: () => {
              props.navigation.navigate('outreach-campaign-menu', {user});
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="Prospects"
          options={{
            label: 'Prospects',
            onPress: () => {
              props.navigation.navigate('prospects', {});
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My Leads"
          props={{user: user}}
          options={{
            label: 'Leads',
            onPress: () => {
              props.navigation.navigate('leads', {});
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="OpportunityProjection"
          name="My Pipeline Projections"
          component={OpportunityProjectionList}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Pipeline Projections',
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My Customers"
          props={{user: user}}
          options={{
            label: 'Customers',
            onPress: () => {
              props.navigation.navigate('customer-dashboard', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My projects"
          props={{user: user}}
          options={{
            label: 'Customers(AM)',
            onPress: () => {
              props.navigation.navigate('customer-dashboard-am', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="FnfAccounts"
          component={FnFAccountsTabs}
          options={{
            label: 'Fnf(Accounts)',
          }}
          data-resource-id="FnfAccounts"
        />
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="NoduesAccounts"
          component={NoDuesAccountsTabs}
          options={{
            label: 'Nodues(Accounts)',
          }}
          data-resource-id="NoduesAccounts"
        />
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="NoduesHR"
          component={NoDuesHRTabs}
          options={{
            label: 'Nodues(HR)',
          }}
          data-resource-id="NoduesHR"
        />
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="NoduesAdmin"
          component={NoDuesAdminTabs}
          options={{
            label: 'Nodues(Admin)',
          }}
          data-resource-id="NoduesAdmin"
        />
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="NoduesIT"
          component={NoDuesITNetworkTabs}
          options={{
            label: 'Nodues(IT)',
          }}
          data-resource-id="NoduesIT"
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="My Projects"
          component={ProjectTabs}
          options={{
            label: 'Projects',
          }}
          data-resource-id="My projects"
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="DeliveryOwnerRfr"
          // component={EngineeringOpportunitiesTabViewForDeliveryOwners}
          options={{
            label: 'Staff augmentation',
            onPress: () => {
              props.navigation.navigate(
                'staffaugmentation-menu-delivery-owners',
                {},
              );
            },
          }}
          data-resource-id="DeliveryOwnerRfr"
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          props={{scope: 'product'}}
          name="My Active Fixed Orders"
          component={MyOrdersTable}
          options={{
            label: 'Active Fixed Orders',
          }}
          data-resource-id="MyActiveFixedOrders"
        />
        <WorkSpaceGroup.Screen
          data-resource-id="Order"
          options={{
            label: 'Orders',
            onPress: () => {
              props.navigation.navigate('orders', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My Deliveries"
          options={{
            label: 'Deliveries',
            onPress: () => {
              props.navigation.navigate('deliveries', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          data-resource-id="My Invoices"
          options={{
            label: 'Invoices',
            onPress: () => {
              props.navigation.navigate('invoices', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My Receipts"
          options={{
            label: 'Receipts',
            onPress: () => {
              props.navigation.navigate('receipts', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Screen
        name="My Team"
        component={NonEnggTeams}
        data-resource-id="Team"
        options={{
          label: 'Team',
        }}
        props={{user: user, fromMyView: true}}
      />
      {accessRightMenus.map(screen => screen)}
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          data-resource-id="My Outreach Campaign"
          options={{
            label: 'Outreach Campaigns',
            onPress: () => {
              props.navigation.navigate('outreach-campaign-menu', {user});
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="My Unlock Requests"
          options={{
            label: 'Unlock Requests',
            onPress: () => {
              props.navigation.navigate('unlock-requests', {user});
            },
          }}
        />
        {/* <WorkSpaceGroup.Screen
          data-resource-id="PracticeHeadReimbursement"
          component={PracticeReimbursementTab}
          options={{
            label: 'PH Reimbursement',
          }}
        /> */}
        {/* <WorkSpaceGroup.Screen
          data-resource-id="ResourceManagerReimbursement"
          name="ResourceManagerReimbursement"
          component={RmReimbursementTab}
          options={{
            label: 'RM Reimbusrsement',
          }}
        /> */}
        <WorkSpaceGroup.Screen
          data-resource-id="SolutionConsultant"
          options={{
            label: 'Solution Consutant',
            onPress: () => {
              props.navigation.navigate(
                'pre-sales-menu-for-consultant-workspace',
                {},
              );
            },
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="Projections"
          options={{
            label: 'Projections',
            onPress: () => {
              props.navigation.navigate('projections-menu', {...props});
            },
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          // data-resource-id="My Trainer"
          options={{
            label: 'Trainees',
            onPress: () => {
              props.navigation.navigate('self-trainer-for-workspace', {});
            },
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Screen
        name="Assigned Tickets"
        component={AssignedTicketTabs}
        data-resource-id="Assigned Tickets"
        options={{
          label: 'Assigned Tickets',
        }}
        props={{user: user, fromMyView: true}}
      />
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          props={{fromMyView: true}}
          data-resource-id="QuotationApprover"
          component={TransactionCOOTabs}
          options={{label: 'Transactions'}}
        />
        <WorkSpaceGroup.Screen
          props={{fromMyView: true}}
          data-resource-id="QuotationApprover"
          component={QuotationApproverTab}
          options={{label: 'Quotation Approver'}}
        />
        <WorkSpaceGroup.Screen
          props={{fromMyView: true}}
          data-resource-id="ExpenseApprover"
          component={ExpenseApproverTab}
          options={{label: 'Expense Request (Approver)'}}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="BudgetApprover"
          component={BudgetApproverTab}
          options={{label: 'Budget Request (Approver)'}}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Screen
          props={{reffer_to_coo: true}}
          data-resource-id="QuotationApproverCOO"
          component={QuotationApproverTab}
          options={{label: 'Quotation Approver(COO)'}}
        />
        <WorkSpaceGroup.Screen
          props={{reffer_to_coo: true}}
          data-resource-id="ExpenseApproverCOO"
          component={ExpenseApproverTab}
          options={{label: 'Expense Request (COO)'}}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="ReferredReimbursement"
          component={COORferredReimbursement}
          options={{
            label: 'Reimbursements(COO)',
          }}
        />
        <WorkSpaceGroup.Screen
          data-resource-id="Interviewer"
          name="Candidate Feedbacks"
          component={MyCandidateFeedbackTabs}
          options={{
            label: 'Candidate Feedbacks',
          }}
        />
        <WorkSpaceGroup.Screen
          data-navigator-id="ExpenseRequester"
          options={{
            label: 'Expense',
            onPress: () => {
              props.navigation.navigate('expense-menu', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
      </WorkSpaceGroup.Group>
      <WorkSpaceGroup.Group>
        <WorkSpaceGroup.Section
          options={{
            label: 'Projects(PM)',
          }}>
          <WorkSpaceGroup.Screen
            props={{scope: 'product'}}
            name="Billed"
            options={{
              label: 'Billed',
              onPress: () => {
                props.navigation.navigate('project-menu-for-pm', {
                  params: {
                    ...props?.route?.params,
                  },
                });
              },
            }}
            data-resource-id="My projects"
          />
          <WorkSpaceGroup.Screen
            props={{scope: 'product'}}
            name="Unbilled"
            options={{
              label: 'Unbilled',
              onPress: () => {
                props.navigation.navigate('project-unbilled-menu-for-pm', {
                  params: {
                    ...props?.route?.params,
                  },
                });
              },
            }}
            data-resource-id="My projects"
          />
        </WorkSpaceGroup.Section>
      </WorkSpaceGroup.Group>
      {/* <WorkSpaceGroup.Screen
        data-resource-id="Project Feedback(PH)"
        props={{
          "practice_head": user && user.employee && user.employee._id,
          tab: "Practice"
        }}
        component={TeamProjectFeedbackTab}
        options={{
          label: 'Project Feedback(PH)'
        }}
      /> */}
       {/* <WorkSpaceGroup.Screen
        data-resource-id="Appraisal(PH)"
        props={{
          "practice_head": user && user.employee && user.employee._id,
          tab: "Practice"
        }}
        component={PHAppraisalTab}
        options={{
          label: 'Appraisal(PH)'
        }}
      /> */}
      {/* <WorkSpaceGroup.Screen
        data-resource-id="Project Feedback(RM)"
        props={{
          tab: "RM"
        }}
        component={TeamProjectFeedbackTab}
        options={{
          label: 'Project Feedback(RM)'
        }}
      />
       <WorkSpaceGroup.Screen
        data-resource-id="Resource Management Feedback(RM)"
        props={{
          tab: "RM"
        }}
        component={RMFeedbackTab}
        options={{
          label: 'Resource Management Feedback(RM)'
        }}
      /> */}
    </WorkSpaceGroup.Navigator>
  );
};
export default WorkSpaceNavigator;
