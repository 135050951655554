// Analytics table which show campaign, value proposition and sequens count - Parveen 01-02-2024
import {getToday} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {GroupTable} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';

export const AnalyticsTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {
    row: {
      _id: {action},
    },
  } = params || {};
  const {row: {campaignAnalytics} = {},fromRow=false} = params;

  const {
    onChangeFilter,
    filterValues = {},
    applyFilter,
  } = useFilter({
    params: {
      period: params.period || getToday(),
    },
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  const generateAddOnFilter = (row, additionalFields) => {
    if (campaignAnalytics?.campaign_id) {
      return {
        action: row?.action,
        campaign_name: row?.campaign_name,
        ...additionalFields,
      };
    }

    return {
      completedOn: {
        $gte: period?.from,
        $lte: period?.to,
      },
      action: row?.action,
      campaign_name: row?.campaign_name,
      ...additionalFields,
    };
  };

  const onPressHandler = (row, additionalFields) => {
    const addOnFilter = generateAddOnFilter(row, additionalFields);
    navigation.navigate('show-analytics-actions', {addOnFilter});
  };
  return (
    <>
      <GroupTable
        api={`/campaign-analytics`}
        params={{
          period,
          action,
          campaignAnalytics,
          fromRow
        }}
        renderHeader={props => (
          <TableHeader
            title={action}
            actions={[
              !campaignAnalytics?.campaign_id ? (
                <PeriodRangeFilter
                  onChangeFilter={onChangeFilter}
                  filterValues={filterValues}
                  options={[
                    {
                      label: 'Week',
                      value: 'week',
                    },
                    {
                      label: 'Today',
                      value: 'day',
                    },
                  ]}
                />
              ) : null,
            ]}
            {...props}
          />
        )}
        groupRow={{
          data: 'children',
          title: 'label',
          defaultExpandedIndex: 0,
          hideRow: ({row}) => !row?.label,
        }}
        columns={[
          {
            type: 'text',
            field: 'campaign_name',
          },
          {
            type: 'text',
            header: 'Unique Prospects',
            field: 'unique_prospect_count',
            width: 150,
            visible: props => {
              return campaignAnalytics?.campaign_id;
            },
          },
          {
            type: 'text',
            header: 'Sent',
            field: 'count',
            width: 150,
            onPress: ({row}) =>
              onPressHandler(row, {
                title: 'Sent',
                ...(row?.campaign_id?._id && {
                  campaign_id: row.campaign_id._id,
                }),
                ...(row?.usp_id && {
                  usp_id: row.usp_id,
                }),
                ...(row?.sequence_display && {
                  sequence_display: row.sequence_display,
                }),
                ...(row?.industry && {
                  industry: row.industry,
                }),
              }),
          },
          {
            type: 'text',
            header: 'Positive response',
            field: 'positive_response_count',
            width: 150,
            onPress: ({row}) =>
              onPressHandler(row, {
                title: 'Positive response',
                positive_response_on: {$exists: true},
                ...(row?.campaign_id?._id && {
                  campaign_id: row.campaign_id._id,
                }),
                ...(row?.usp_id && {
                  usp_id: row.usp_id,
                }),
                ...(row?.sequence_display && {
                  sequence_display: row.sequence_display,
                }),
                ...(row?.industry && {
                  industry: row.industry,
                }),
              }),
          },
          {
            type: 'text',
            header: 'Bounce',
            field: 'email_bounce_count',
            width: 150,
            onPress: ({row}) =>
              onPressHandler(row, {
                title: 'Bounce',
                email_bounce_on: {$exists: true},
                ...(row?.campaign_id?._id && {
                  campaign_id: row.campaign_id._id,
                }),
                ...(row?.usp_id && {
                  usp_id: row.usp_id,
                }),
                ...(row?.sequence_display && {
                  sequence_display: row.sequence_display,
                }),
                ...(row?.industry && {
                  industry: row.industry,
                }),
              }),
          },
          {
            type: 'text',
            header: 'Unsubscribe',
            field: 'email_unsubscribe_count',
            width: 150,
            onPress: ({row}) =>
              onPressHandler(row, {
                title: 'Unsubscribe',
                email_unsubscribe_on: {$exists: true},
                ...(row?.campaign_id?._id && {
                  campaign_id: row.campaign_id._id,
                }),
                ...(row?.usp_id && {
                  usp_id: row.usp_id,
                }),
                ...(row?.sequence_display && {
                  sequence_display: row.sequence_display,
                }),
                ...(row?.industry && {
                  industry: row.industry,
                }),
              }),
          },
          {
            type: 'text',
            header: 'Open',
            field: 'email_open_count',
            width: 150,
            onPress: ({row}) =>
              onPressHandler(row, {
                title: 'Open',
                email_open_on: {$exists: true},
                ...(row?.campaign_id?._id && {
                  campaign_id: row.campaign_id._id,
                }),
                ...(row?.usp_id && {
                  usp_id: row.usp_id,
                }),
                ...(row?.sequence_display && {
                  sequence_display: row.sequence_display,
                }),
                ...(row?.industry && {
                  industry: row.industry,
                }),
              }),
          },
          {
            type: 'text',
            header: 'Click',
            field: 'email_click_count',
            width: 150,
            onPress: ({row}) =>
              onPressHandler(row, {
                title: 'Click',
                email_clicked_on: {$exists: true},
                ...(row?.campaign_id?._id && {
                  campaign_id: row.campaign_id._id,
                }),
                ...(row?.usp_id && {
                  usp_id: row.usp_id,
                }),
                ...(row?.sequence_display && {
                  sequence_display: row.sequence_display,
                }),
                ...(row?.industry && {
                  industry: row.industry,
                }),
              }),
          },
        ]}
        {...props}
      />
    </>
  );
};
