import {Button} from '@unthinkable/react-button';
import {MoreActions} from '../../components/moreAction/MoreAction';
import {useStyles} from '@unthinkable/react-theme';
import {exportActionsButtonStyles} from './theme';
import {useAppStateContext} from '../../providers/AppState';
import {useReactFlow} from 'reactflow';
import {exportImageToPNG} from '../../components/graphflow/ExportActions';
import {useToast} from '@unthinkable/react-toast';

export const GraphMoreActions = ({
  actions = [],
  showIndependentNodes,
  setShowIndependentNodes,
}) => {
  const style = useStyles(exportActionsButtonStyles);
  return (
    <MoreActions
      actions={[
        ...actions,
        {
          title: showIndependentNodes
            ? 'Hide Independent Models'
            : 'Show Independent Models',
          onPress: () => setShowIndependentNodes(!showIndependentNodes),
        },
      ]}>
      <Button
        styles={style?.button}
        text="Actions"
        gap={8}
        icon={style?.DownArrowIcon}
        iconPosition="right"
      />
    </MoreActions>
  );
};

export const ExportActions = ({actions}) => {
  const styles = useStyles(exportActionsButtonStyles);
  return (
    <MoreActions actions={actions}>
      <Button
        styles={styles?.button}
        text="Export"
        gap={8}
        iconPosition="right"
        icon={styles?.DownloadIcon}
      />
    </MoreActions>
  );
};

export const GraphExportActions = ({
  fileName,
  excelParams,
  actions = [],
  ...props
}) => {
  const {download} = useAppStateContext();
  const {getNodes} = useReactFlow();
  const toast = useToast();

  return (
    <ExportActions
      {...props}
      actions={[
        {
          title: 'Export as PNG',
          onPress: () => {
            setTimeout(_ => {
              exportImageToPNG({getNodes, fileName});
            }, 100);
            toast({message: 'Exporting...'});
          },
        },
        {
          title: 'Export in Excel',
          onPress: () => {
            download({
              uri: `/generateExcel`,
              props: excelParams,
            });
            toast({message: 'Exporting...'});
          },
        },
        ...actions,
      ]}
    />
  );
};
