import React, {useCallback} from 'react';
import {Table} from '../../../../components/table/Table';
import {InlineForm} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {
  ImportStatus,
  FileFieldRender,
} from '../../cellrenders/DataEntityImportRender';
import useRealTimeSync from '../../../../components/customHooks/useRealTimeSync';
import {useFetchData} from '../../../../controllers/useFetchData';
import {updateStatusByIdInData} from '../../utility';
import {
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_MODULE_TYPE_ID,
  PROJECT_REPOSITORY_TYPE_ID,
  PROJECT_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {useTheme} from '@unthinkable/react-theme';

export const ImportDataTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project_id, module_id, repository_id, feature_id, source} = params;

  let library_id =
    params?.projectlibrary_id || params?.feature?.library_id?._id;

  let parent_source, parent_source_id;

  if (feature_id) {
    parent_source = PROJECT_FEATURE_TYPE_ID;
    parent_source_id = feature_id;
  } else if (repository_id) {
    parent_source = PROJECT_REPOSITORY_TYPE_ID;
    parent_source_id = repository_id;
  } else if (module_id) {
    parent_source = PROJECT_MODULE_TYPE_ID;
    parent_source_id = module_id;
  } else if (project_id) {
    parent_source = PROJECT_TYPE_ID;
    parent_source_id = project_id;
  }

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['DataImport'],
    uri: `/projects/${project_id}/import-data`,
    ...props,
  });

  const {
    setData,
    data = [],
    ...restFetchProps
  } = useFetchData({
    api: '/importHistories',
    fields: {
      status: 1,
      file: 1,
      type: 1,
      error: 1,
      uploaded_by: {name: 1},
      createdAt: 1,
      error_messages: 1,
      success_count: 1,
      failure_count: 1,
      total_count: 1,
      format: 1,
    },
    filter: {
      parent_source,
      parent_source_id,
      source,
    },
    eventSourceId: ['DataImport'],
    sort: {createdAt: -1},
  });
  useRealTimeSync({
    group: 'importDataGroup',
    event: 'importDataStatus',
    callBack: useCallback(
      socketData => {
        if (socketData) {
          let newData = updateStatusByIdInData({data, socketData});
          if (newData && newData.length > 0) {
            setData(newData);
          }
        }
      },
      [data, updateStatusByIdInData],
    ),
  });
  const {colors} = useTheme();

  return (
    <Table
      renderHeader={() => {
        return (
          <>
            <InlineForm
              onSubmit={onSubmit}
              defaultValues={{
                parent_source,
                parent_source_id,
                source,
                project_id,
                module_id:
                  source === PROJECT_COMPONENT_TYPE_ID ? void 0 : module_id,
                feature_id,
                repository_id,
                library_id,
                type: 'JSON',
              }}
              submitAction="Save"
              successMessage={'Uploading'}
              layoutFields={[
                {
                  label: 'File type',
                  field: 'type',
                  type: 'radioGroup',
                  options: [
                    {label: 'JSON', value: 'JSON'},
                    {label: 'YAML', value: 'YAML'},
                  ],
                  keyField: 'value',
                  required: true,
                  size: 2,
                },
                (source === PROJECT_CONTROLLER_TYPE_ID ||
                  source === PROJECT_MODEL_TYPE_ID) && {
                  label: 'Format',
                  field: 'format',
                  type: 'autoComplete',
                  options: () => {
                    const arr = ['Swagger'];
                    if (source === PROJECT_CONTROLLER_TYPE_ID) {
                      arr.push('Postman');
                    }
                    return arr;
                  },
                  size: 2,
                },
                {
                  field: 'file',
                  type: 'file',
                  placeholder: 'Upload JSON file',
                  inline: true,
                  required: true,
                  size: 3,
                  inputProps: {
                    accept: '.json',
                  },
                  visible: ({values}) => values.type === 'JSON',
                },
                {
                  field: 'file',
                  type: 'file',
                  placeholder: 'Upload YAML file',
                  inline: true,
                  required: true,
                  size: 3,
                  inputProps: {
                    accept: '.yaml,.yml',
                  },
                  visible: ({values}) => values.type === 'YAML',
                },
              ]}
            />
          </>
        );
      }}
      data={data}
      {...restFetchProps}
      columns={[
        {
          field: 'uploaded_by',
          type: 'userAvatar',
          width: 50,
          align: 'center',
        },
        {
          header: 'File name',
          render: FileFieldRender,
          width: 200,
        },
        {
          header: 'Type',
          field: 'type',
          type: 'chip',
          width: 100,
          align: 'center',
        },
        (source === PROJECT_CONTROLLER_TYPE_ID ||
          source === PROJECT_MODEL_TYPE_ID) && {
          header: 'Format',
          field: 'format',
          type: 'chip',
          width: 100,
          align: 'center',
        },
        {
          header: 'Total',
          field: 'total_count',
          type: 'text',
          width: 70,
          align: 'center',
        },
        {
          header: 'Success',
          field: 'success_count',
          type: 'text',
          width: 70,
          align: 'center',
          color: colors.SUCCESS_HIGH,
        },
        {
          header: 'Failed',
          field: 'failure_count',
          type: 'text',
          width: 70,
          align: 'center',
          color: colors.ERROR_HIGH,
          onPress: ({row}) => {
            navigation.navigate('project-import-errors', {row});
          },
        },
        {
          header: 'Status',
          render: ImportStatus,
          onPress: ({row}) => {
            navigation.navigate('project-import-errors', {row});
          },
        },

        {
          header: 'Uploaded on',
          field: 'createdAt',
          type: 'date',
          width: 120,
        },
      ]}
    />
  );
};

export const ImportErrorMessagesTable = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  const {error_messages} = row || {};

  return (
    <Table
      data={error_messages}
      noDataText="All items imported successfully."
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Error Message',
          field: 'message',
          type: 'text',
          numberOfLines: 2,
        },
      ]}
    />
  );
};
