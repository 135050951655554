import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';


const PaymentTdsTable = props => {
  const { filterParams, searchValue, params } = props;
  return (
    <Table
      {...props}
      api={'/paymenttdsInvoices'}
      addOnFilter={filterParams}
      params={params}
      eventSourceId="GST"
      searchFields={['payment_no']}
      search={searchValue}
      limit={50}
      columns={[
        {
          type: 'userAvatarChip',
          field: 'payee_id',
          header: 'Vendor',
          minWidth: 200,
        },
        {
          field: 'payment_no',
          header: 'Payment No.',
          type: 'colorTag',
          colorField: randomColor,
          width: 160,
        },
        {
          field: "payment_type.name",
          header: 'Type',
          width: 160
        },
        {
          field: 'payment_date',
          header: 'Payment Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          type: 'text',
          header: "PAN Number",
          field: "payee_id.pan_no",
          width: 140,
        },
        {
          aggregate: true,
          field: 'advance_amount',
          type: 'currency',
          header: 'Advance Amount',
          width: 140
        },
        {
          aggregate: true,
          field: 'total_payment_amount',
          type: 'currency',
          header: 'Amount',
          width: 140,
        },
        {
          aggregate: true,
          field: 'tds_amount',
          type: 'currency',
          header: 'TDS Amount',
          width: 140,
        },
        {
          field: 'tds_account.name',
          header: 'TDS Account',
          width: 180,
        },
      ]}
    />
  );
};

export const PaymentTdsTab = props => {
  let { route: { params } = {} } = props;
  const { Download } = useTheme('icons');
  const { download } = useAppStateContext();
  const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let { filter } = filterValues;
  const tabs = {
    tds: {
      label: 'Pyament TDS',
      view: (
        <PaymentTdsTable filterParams={filter} searchValue={searchValue} {...props} />
      ),
      eventSourceId: 'TDS',
      api: '/paymenttdsInvoices',
      addOnFilter: filter,
      search: searchValue,
      searchFields: ['payment_no'],
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportpaymenttds',
              props: {
                addOnFilter: { ...filter },
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Payment Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'payment_date',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              field: 'tds_account',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
      ]}
    />
  );
};
