import {
  AutoComplete,
  AutoCompleteRenderer,
  Cascader,
  CascaderRenderer,
  MultiAutoComplete,
  MultiAutoCompleteRenderer,
} from '../form-editors/autocomplete/AutoComplete';
import {Checkbox} from '../form-editors/checkbox/Checkbox';
import {ColorInput, ColorRenderer} from '../form-editors/color/ColorInput';
import {DateInput} from '../form-editors/date/DateInput';
import {
  DurationInput,
  DurationRenderer,
} from '../form-editors/duration/DurationInput';
import {FileInput, FileRenderer} from '../form-editors/file/FileInput';
import RadioGroup, {RadioGroupRenderer} from '../form-editors/radio/RadioGroup';
import {Switch, SwitchRenderer} from '../form-editors/switch/Switch';
import {
  DateRenderer,
  LinkRenderer,
  NumberInput,
  NumberRenderer,
  PasswordInput,
  PasswordRenderer,
  TextAreaInput,
  TextAreaRenderer,
  TextInput,
  TextRenderer as TextRendererComponent,
} from '../form-editors/Editors';
import MultiCheckbox, {
  MultiCheckboxRenderer,
} from '../form-editors/checkbox/MultiCheckbox';
import {
  CurrencyInput,
  CurrencyRenderer,
} from '../form-editors/currency/CurrencyInput';
import {GooglePlaceInput} from '../form-editors/autocomplete/GooglePlace';
import {MentionsInput} from '../form-editors/mentions/MentionsInput';
import {Rating} from '@unthinkable/react-star-rating';
import {RatingRenderer} from '../form-editors/rating/Rating';
import {RichTextAreaInput} from '../form-editors/text/RichTextAreaInput';
import {RichTextRenderer} from '../form-editors/text/RichTextAreaInput';
import {MultiTextInput} from '../form-editors/text/MultiTextInput';
import {AITextAreaInput} from './AITextAreaInput';
import {CodeEditorInput} from '../form-editors/text/CodeEditorInput';
import {AiCodeEditorInput} from '../form-editors/text/AiCodeEditorInput';
import {JsonFormComponent} from '../form-editors/jsonInput/JsonInput';

export default {
  text: {
    Editor: TextInput,
    Renderer: TextRendererComponent,
  },
  link: {
    Editor: TextInput,
    Renderer: LinkRenderer,
  },
  mention: {
    Editor: MentionsInput,
  },
  googlePlace: {
    Editor: GooglePlaceInput,
  },
  aiTextArea: {
    Editor: AITextAreaInput,
    Renderer: TextAreaRenderer,
  },
  textArea: {
    Editor: TextAreaInput,
    Renderer: TextAreaRenderer,
  },
  number: {
    Editor: NumberInput,
    Renderer: NumberRenderer,
  },
  password: {
    Editor: PasswordInput,
    Renderer: PasswordRenderer,
  },
  richText: {
    Editor: RichTextAreaInput,
    Renderer: RichTextRenderer,
  },
  date: {
    Editor: DateInput,
    Renderer: DateRenderer,
  },
  autoComplete: {
    Editor: AutoComplete,
    Renderer: AutoCompleteRenderer,
  },
  cascader: {
    Editor: Cascader,
    Renderer: CascaderRenderer,
  },
  multiAutoComplete: {
    Editor: MultiAutoComplete,
    Renderer: MultiAutoCompleteRenderer,
  },
  file: {
    Editor: FileInput,
    Renderer: FileRenderer,
  },
  checkbox: {
    Editor: Checkbox,
    Renderer: Checkbox,
  },
  multiCheckbox: {
    Editor: MultiCheckbox,
    Renderer: MultiCheckboxRenderer,
  },
  switch: {
    Editor: Switch,
    Renderer: SwitchRenderer,
  },
  radioGroup: {
    Editor: RadioGroup,
    Renderer: RadioGroupRenderer,
  },
  duration: {
    Editor: DurationInput,
    Renderer: DurationRenderer,
  },
  currency: {
    Editor: CurrencyInput,
    Renderer: CurrencyRenderer,
  },
  color: {
    Editor: ColorInput,
    Renderer: ColorRenderer,
  },
  multiText: {
    Editor: MultiTextInput,
  },
  rating: {
    Editor: Rating,
    Renderer: RatingRenderer,
  },
  code: {
    Editor: CodeEditorInput,
    Renderer: TextAreaRenderer,
  },
  aiCode: {
    Editor: AiCodeEditorInput,
    Renderer: TextAreaRenderer,
  },
  json: {
    Editor: JsonFormComponent,
    Renderer: TextAreaRenderer,
  },
};
