import { Text } from '@unthinkable/react-core-components';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const MarkEmployeePIP = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  let employeeFilter = row?._id;

  return (
    <Form
      defaultValues={{_id: row?._id, is_pip: true}}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'PIP Marked On',
          field: 'pip_marked_on',
          type: 'date',
          size: 4,
          onChangeValue: async (value, e, {setFieldValue}) => {
            let nextFeedbackDueDate = getZeroTimeDate(value);

            if (value) {
              nextFeedbackDueDate = getZeroTimeDate(
                nextFeedbackDueDate.setDate(nextFeedbackDueDate.getDate() + 6),
              );
              setFieldValue('next_feedback_due_date', nextFeedbackDueDate);
            }
          },
        },
        {
          placeholder: 'PIP Estimated End Date',
          field: 'pip_estimated_end_date',
          type: 'date',
          size: 4,
        },
        {
          placeholder: 'PIP Reason',
          field: 'pip_reason',
          type: 'textArea',
          size: 4,
        },
        // {
        //   placeholder: 'Project',
        //   field: 'pip_project',
        //   type: 'autoComplete',
        //   api: `/employeeassignments/projects`,
        //   label: 'Project',
        //   fields: {project: 1},
        //   filter: {
        //     employee: employeeFilter,
        //   },
        //   suggestionField: 'project',
        //   valueField: 'project',
        //   secondarySuggestionField: 'order_number',
        //   required: true,
        //   size: 4,
        // },
        {
          type: 'autoComplete',
          label: 'Mentor',
          api: '/employeeSuggestions',
          filter: {
            status: 'Active',
          },
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          field: 'pip_mentor',
          size: 4,
        },
        {
          type: 'autoComplete',
          label: 'Training SPOC',
          api: '/employeeSuggestions',
          filter: {
            status: 'Active',
          },
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          field: 'training_spoc',
          size: 4,
        },
      ]}
      {...props}
    />
  );
};

export const MarkPotentialPIP = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  let employeeFilter = row?._id;
  const {user = {}} = useAppStateContext();
  const {employee = {}} = user;
  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        potential_pip: true,
        pip_identified_on: getZeroTimeDate(new Date()),
        pip_owner: {name: employee?.name, _id: employee?._id},
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        // {
        //   placeholder: 'Project',
        //   field: 'pip_project',
        //   type: 'autoComplete',
        //   api: `/employeeassignments/projects`,
        //   label: 'Project',
        //   fields: {project: 1},
        //   filter: {
        //     employee: employeeFilter,
        //   },
        //   suggestionField: 'project',
        //   valueField: 'project',
        //   secondarySuggestionField: 'order_number',
        //   required: true,
        // },
        {
          type: 'autoComplete',
          label: 'Owner',
          api: '/employeeSuggestions',
          filter: {
            status: 'Active',
          },
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          field: 'pip_owner',
          required: true,
        },
      ]}
    />
  );
};

export const UnmarkEmployeePIP = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  let employeeFilter = row?._id;

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        is_pip: null,
        next_feedback_due_date: null,
        potential_pip: null,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Status',
          field: 'pip_status',
          type: 'autoComplete',
          options: ['Resignation', 'Terminate', 'Success'],
        },
        {
          placeholder: 'PIP End Date',
          field: 'pip_end_on',
          type: 'date',
          onChangeValue: async (value, e, {setFieldValue}) => {
            if (value) {
              setFieldValue('next_feedback_due_date', null);
            }
          },
        },
      ]}
      {...props}
    />
  );
};

export const ExtendEmployeePIP = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const {user = {}, resourceUrl, fetch} = useAppStateContext();
  const {employee} = user;

  let employeeFilter = row?._id;

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        pip_extended_on: new Date(),
        pip_extended_by: employee?._id,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Reason',
          field: 'pip_extended_reason',
          type: 'textArea',
        },
        {
          placeholder: 'New est. end date',
          field: 'pip_extension_date',
          type: 'date',
        },
      ]}
      {...props}
    />
  );
};

export const UpdatePIPMentor = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const {user = {}, resourceUrl, fetch} = useAppStateContext();
  const {employee} = user;

  let employeeFilter = row?._id;

  return (
    <Form
      defaultValues={{
        ...row,
      }}
      onSubmit={onSubmit}
      header={'Update Mentor'}
      submitAction={'Update'}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Mentor',
          api: '/employeeSuggestions',
          filter: {
            status: 'Active',
          },
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          field: 'pip_mentor',
          size: 6,
        },
      ]}
    />
  );
};

export const PotentialPIP = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkPotentialPIP
      header={{
        title: 'Potential PIP',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const StartPIP = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkEmployeePIP
      header={{
        title: 'Initiate PIP',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const ExtendPIP = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ExtendEmployeePIP
      header={{
        title: 'Extend PIP',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const FinishPIP = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UnmarkEmployeePIP
      header={{
        title: 'Finish PIP',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const PIPNextActionForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      data={{_id: row?._id}}
      header={'PIP Next Action Form'}
      mode="edit"
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Next Action Date',
          field: 'pip_next_action_date',
          type: 'date',
          label: 'Next Action Date',
          required: true,
        },
        {
          placeholder: 'Next Action Description',
          field: 'pip_next_action_description',
          type: 'textArea',
          label: 'Next Action Description',
          required: true,
        },
      ]}
    />
  );
};
