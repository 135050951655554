import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';
import {useFilterKeyValues} from '../../../providers/FilterProvider';
import { SearchFilter } from '../../../components/filter/Filters';

const MarketingConceptTableHeader = props => {
  const {route: {params} = {}} = props;
  const {onChangeFilter, searchValue} = useFilter({
    filterKey: 'marketingConceptFilter',
  });
  return (
    <TableHeader
      title="Marketing Concepts"
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};

export const MarketingConceptTable = props => {
  const {
    route: {params},
  } = props;
  const {searchValue} = useFilterKeyValues('marketingConceptFilter');
  return (
    <Table
      renderHeader={() => {
        return <MarketingConceptTableHeader />;
      }}
      search={searchValue}
      searchFields={'concept_name'}
      api={`/marketing-concepts`}
      params={{params}}
      onRowPress={row => {
        props.navigation.navigate('edit-marketing-concepts', row);
      }}
      columns={[
        {
          type: 'text',
          field: 'concept_name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'concept_details',
          header: 'Details',
        },
      ]}
    />
  );
};
