import React, {forwardRef, useState} from 'react';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {ThreeDotLoader} from '@unthinkable/react-activity-indicator';
import {TextInput} from '@unthinkable/react-text-input';
import {
  BasicTextAreaInput,
  TextAreaInput,
} from '@unthinkable/react-text-area-input';
import {
  ActionContainer,
  ImproviseButton,
  ImproviseIcon,
  ImproviseRow,
  ImproviseText,
  OriginalButton,
  OriginalText,
  RegenerateButton,
  RegenerateIcon,
  RegenerateText,
  Separator,
} from './styles';

const AITextAreaInputComponent = props => {
  const {value, onChangeValue, styles, getSuggestion} = props;
  const {aiStyles} = styles;
  const [state, setState] = useState({
    originalValue: '',
    aiValue: '',
    mode: 'original',
    aiSuggested: false,
  });
  const [loading, setLoading] = useState(false);

  const [showSuggestion, updateShowSuggestion] = useState(false);
  const [improvementText, updateImprovementText] = useState();

  const {originalValue, aiValue, mode, aiSuggested} = state;

  const fetchSuggestions = async () => {
    setLoading(true);
    const response = await getSuggestion?.({
      value,
      aiValue,
      originalValue,
      mode,
      aiSuggested,
      improvementText,
    });
    setLoading(false);
    updateShowSuggestion(false);
    return response;
  };

  const onChangeMode = () => {
    if (mode === 'ai') {
      setState({
        ...state,
        mode: 'original',
      });
      onChangeValue(originalValue);
    } else {
      setState({
        ...state,
        mode: 'ai',
      });
      onChangeValue(aiValue);
    }
  };

  const onPressImprovise = async () => {
    if (loading || !value?.trim()) return;
    const response = await fetchSuggestions();
    setState({
      ...state,
      originalValue: value,
      aiValue: response,
      aiSuggested: true,
      mode: 'ai',
    });
    onChangeValue(response);
  };

  const onPressRegenerate = async () => {
    if (loading || !originalValue?.trim()) return;
    const response = await fetchSuggestions();
    setState({
      ...state,
      aiValue: response,
      mode: 'ai',
    });
    onChangeValue(response);
  };

  const onChange = value => {
    if (state?.mode === 'ai') {
      setState({
        ...state,
        aiValue: value,
      });
    } else {
      setState({
        ...state,
        originalValue: value,
      });
    }
    onChangeValue(value);
  };

  return (
    <>
      <BasicTextAreaInput {...props} onChangeValue={onChange} />
      {showSuggestion && (
        <View style={{marginTop: 10}}>
          <TextAreaInput
            label="Improvement Text"
            placeholder={'Write your suggestion here'}
            value={improvementText}
            onChangeValue={text => {
              updateImprovementText(text);
            }}
            inputProps={{minRows: 3, maxRows: 6}}
          />
        </View>
      )}
      <ActionContainer styles={aiStyles}>
        {aiSuggested ? (
          <Row>
            <OriginalButton onPress={onChangeMode} styles={aiStyles}>
              <OriginalText styles={aiStyles}>
                {mode === 'ai' ? 'View Original' : 'View Suggestion'}
              </OriginalText>
            </OriginalButton>
            <Separator styles={aiStyles} />
            {!showSuggestion ? (
              <RegenerateButton
                onPress={() => {
                  updateShowSuggestion(true);
                }}
                styles={aiStyles}>
                <RegenerateIcon styles={aiStyles} loading={loading} />
                <RegenerateText styles={aiStyles}>Regenerate</RegenerateText>
              </RegenerateButton>
            ) : (
              <RegenerateButton
                onPress={onPressRegenerate}
                disabled={!value?.trim()}
                styles={aiStyles}>
                <RegenerateIcon styles={aiStyles} loading={loading} />
                <RegenerateText styles={aiStyles}>{'Submit'}</RegenerateText>
              </RegenerateButton>
            )}
          </Row>
        ) : (
          <ImproviseRow styles={aiStyles}>
            {!showSuggestion ? (
              <ImproviseButton
                // onPress={onPressImprovise}
                onPress={() => {
                  updateShowSuggestion(true);
                }}
                disabled={!value?.trim()}
                styles={aiStyles}>
                <ImproviseIcon styles={aiStyles} />
                <Text style={aiStyles?.normalAIText}>
                  {loading ? 'Improvising' : 'Improvise'}
                </Text>
                {loading ? <ThreeDotLoader /> : void 0}
              </ImproviseButton>
            ) : (
              <ImproviseButton
                onPress={onPressImprovise}
                disabled={!value?.trim()}
                styles={aiStyles}>
                <ImproviseIcon styles={aiStyles} />
                <Text style={aiStyles?.normalAIText}> {'Submit'}</Text>
                {loading ? <ThreeDotLoader /> : void 0}
              </ImproviseButton>
            )}
          </ImproviseRow>
        )}
      </ActionContainer>
    </>
  );
};

const AITextAreaInput = forwardRef((props, ref) => {
  return <TextInput as={AITextAreaInputComponent} {...props} ref={ref} />;
});

export default AITextAreaInput;
