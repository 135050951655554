// Show campaign analytics by type like (email,direct_message) - Parveen -01-02-2024
import {getToday} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';

export const CampaignActionsAnalyticsByTypeTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {
      period: getToday(),
    },
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  return (
    <Table
      {...props}
      api={`/campaign-analytics-by-type`}
      params={{
        period,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`show-outreach-analytics`, {
          row,
          period,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                options={[
                  {
                    label: 'Week',
                    value: 'week',
                  },
                  {
                    label: 'Today',
                    value: 'day',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: '_id.action',
          header: 'Type',
          //   width: 200,
        },
        {
          type: 'number',
          field: 'count',
          header: 'Count',
          //   width: 100,
        },
        {
          type: 'number',
          field: 'positive_response_count',
          header: 'Positive outcomes',
          width: 300,
          onPress: ({row}) => {
            const addOnFilter = {
              completedOn: {
                $gte: period?.from,
                $lte: period?.to,
              },
              action: row?._id?.action,
              positive_response_on: {$exists: true},
            };
            navigation.navigate('show-analytics-actions', {
              addOnFilter,
            });
          },
        },
      ]}
    />
  );
};
