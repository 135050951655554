import React from 'react';
import {Tooltip as TooltipComponent} from '@unthinkable/react-popper';
import {TooltipStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';

export const Tooltip = props => {
  const styles = useStyles(TooltipStyles);
  const {contentRow, infoContainer, infoText, info, infoIcon} = styles;
  return (
    <TooltipComponent
      styles={styles}
      width={250}
      withArrow
      delay={700}
      renderContent={() => {
        return (
          <Row style={contentRow}>
            {/* <View style={infoContainer}>
              <Image source={info} style={infoIcon} />
            </View> */}
            <Text style={infoText}>{props.content}</Text>
          </Row>
        );
      }}
      {...props}
    />
  );
};
