import React from 'react';
import {Table} from '../../../components/table/Table';
import {TextRenderer} from '../../../components/form/Renderers';
import moment from 'moment';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useAppStateContext} from '../../../providers/AppState';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {useTheme} from '@unthinkable/react-theme';
import {Image, Row, View} from '@unthinkable/react-core-components';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {TableHeader} from '../../../components/table/Headers';
import {IconButton} from '../../../components/button/IconButton';
import {IconWithBackground} from '../../../components/icons/IconWithBackground';

const RenderDuration = ({row, rest}) => {
  const {client_feedback_from_date, client_feedback_to_date} = row;
  return (
    <TextRenderer
      {...rest}
      title={
        (client_feedback_from_date
          ? moment(client_feedback_from_date).format('DD MMM YY')
          : '') +
        ' - ' +
        (client_feedback_to_date
          ? moment(client_feedback_to_date).utc().format('DD MMM YY')
          : '')
      }
      value={
        (client_feedback_from_date
          ? moment(client_feedback_from_date).format('DD MMM YY')
          : '') +
        ' - ' +
        (client_feedback_to_date
          ? moment(client_feedback_to_date).utc().format('DD MMM YY')
          : '')
      }
    />
  );
};

const RenderProject = ({showProject}) => {
  return {
    header: 'Project',
    minWidth: 300,
    render: ({row}) => {
      let icon;
      let color = 'BRAND_UPPER_LOW';
      if (row?.project?.tool === 'Locomo') {
        icon = 'LocomoTool';
      } else if (row?.project?.tool === 'Jira') {
        icon = 'JiraIcon';
        color = 'SECONDARY_MEDIUM';
      } else {
        icon = 'OtherTool';
      }
      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <IconWithBackground icon={icon} color={color} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project.project}
            items={[
              {
                value: row?.project.code,
              },
              {
                value: `${
                  row?.project.project_start_date
                    ? moment(row?.project.project_start_date).format(
                        'DD MMM YY',
                      )
                    : ''
                } - ${
                  row?.project.project_end_date
                    ? moment(row?.project.project_end_date).format('DD MMM YY')
                    : ''
                }`,
              },
            ]}
          />
        </Row>
      );
    },
  };
};

export const ProjectFeedbackTable = props => {
  const {
    route: {params},
    navigation,
    showProject,
  } = props;
  const {project} = params;
  const {user} = useAppStateContext();
  return (
    <Table
      api="/clientFeedbacks"
      filter={{project: project?._id}}
      eventSourceId="ClientFeedback"
      sort={{client_feedback_from_date: -1}}
      fields={{
        request_date: 1,
        client_feedback_from_date: 1,
        client_feedback_to_date: 1,
        client_score: 1,
        description: 1,
        client: {
          name: 1,
          color: 1,
          email: 1,
        },
        date: 1,
        project: {
          project: 1,
          code: 1,
          tool: 1,
          project_start_date: 1,
          project_end_date: 1,
          customer: {
            name: 1,
          },
          account_owner: {
            name: 1,
            color: 1,
          },
        },
      }}
      onRowPress={({row}) => {
        navigation.navigate(`client-feedback-detail`, {
          feedback: row,
        });
      }}
      columns={[
        RenderProject({showProject}),
        {
          header: 'Customer',
          field: 'project.customer.name',
          type: 'text',
          minWidth: 250,
          visible: !!showProject,
        },
        {
          header: 'AM',
          field: 'project.account_owner',
          type: 'userAvatar',
          width: 80,
          visible: !!showProject,
        },
        {
          type: 'date',
          field: 'request_date',
          header: 'Requested On',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          render: RenderDuration,
          header: 'Duration',
          width: 180,
        },
        {
          header: 'CSAT',
          render: ({row}) => <RatingRenderer value={row?.client_score} />,
          width: 120,
        },
        {
          header: 'Remarks',
          field: 'description',
          type: 'text',
        },
        {
          header: 'Reviewer',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.client?.name}
              color={row?.client?.color}
              official_email_id={row?.client?.email}
            />
          ),
          width: 220,
        },
        {
          header: 'Submitted On',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) =>
            navigation.navigate('edit-client-feedback', {feedback: row}),
          visible: [
            'sushil.nagvan@daffodilsw.com',
            'amit.singh@daffodildb.com',
            'aswathy.krishnankutty@unthinkable.co',
          ].includes(user?.email?.toLowerCase()),
        },
      ]}
      {...props}
    />
  );
};

export const ProjectFeedbackReportOverview = props => {
  const {
    route: {params},
  } = props;
  const {filter, period, projectFilter} = params;
  const {filterValues} = useFilter({
    params: {
      period,
      projectFilter,
    },
    filter,
  });

  const {download} = useAppStateContext();
  const icons = useTheme('icons');
  const tabs = {
    project: {
      label: 'Project Feedbacks',
      view: (
        <ProjectFeedbackTable
          {...filterValues}
          showProject
          sort={{
            project: -1,
            client_feedback_from_date: -1,
          }}
          api={'/clientFeedbackReportOverview'}
        />
      ),
      api: '/clientFeedbackReportOverview',
      ...filterValues,
      eventSourceId: 'ClientFeedback',
    },
  };

  return (
    <TabView
      tabs={tabs}
      actions={[
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/clientFeedbackReportOverview',
                    ...filterValues,
                  },
                  column: {
                    Project: 'project.project',
                    'Request Date': 'request_date',
                    From: 'client_feedback_from_date',
                    To: 'client_feedback_to_date',
                    Score: 'client_score',
                    Description: 'description',
                    Client: 'client.name',
                    'Submitted On': 'date',
                  },
                },
              },
            });
          }}
        />,
      ]}
      {...props}
    />
  );
};
