import React, {useMemo} from 'react';
import NestedTable from '../../../../components/form/NestedTable';
import {Form} from '../../../../components/form/Form';
import {
  LOCOMO_PROJECT_ID,
  PROJECT_MODEL_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';
import {Image, Row, Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../../components/chip/Chip';
import {MultiTag} from '../../../../components/chip/MultiChip';

const fieldTypes = [
  'string',
  'number',
  'int',
  'bigint',
  'smallint',
  'double',
  'float',
  'decimal',
  'boolean',
  'uuid',
  'objectId',
  'date',
  'time',
  'datetime',
  'timestamp',
  'json',
  'mixed',
  'blob',
  'nested',
  'enum',
  'other',
];

const dataEntityFields = ({navigation, params}) => {
  const {project} = params;
  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    entity: {
      label: 'Name',
      type: 'text',
      field: 'model',
      required: true,
      size: 6,
    },
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      size: 6,
      readOnly,
    }),
    database: ({readOnly} = {}) => ({
      label: 'Database Name',
      field: 'database_id',
      type: 'autoComplete',
      api: '/projectdatabasenames',
      suggestionField: 'name',
      valueField: 'name',
      filter: {project_id: project?._id},
      fields: {
        name: 1,
        database_id: {
          database: 1,
          version: 1,
        },
      },
      secondarySuggestionField: ({database_id} = {}) =>
        `${database_id?.database}${
          database_id?.version ? ' - ' + database_id?.version : ''
        }`,
      size: 6,
      readOnly,
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-database-name`, {
          ...params,
          project_id: project?._id,
          searchValue,
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
    }),
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    description: {
      label: 'Description',
      type: 'aiTextArea',
      field: 'desc',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    remarks: {
      label: 'Remarks',
      field: remarksField,
      placeholder: 'Write remarks here...',
      type: 'richText',
      minHeight: 200,
    },
    folder_path: {
      field: 'folder_path_id',
      label: 'Folder Path',
      type: 'autoComplete',
      api: `/repositoryFolderPaths`,
      suggestionField: 'path',
      secondarySuggestionField: 'name',
      valueField: 'path',
      filter: {
        type: 'model',
        project_id: project?._id,
      },
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-repository-folder-path`, {
          ...params,
          repositoryType: 'Backend',
          defaultValues: {
            type: 'model',
            project_id: project?._id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      helperText: 'Select path on which file is placed',
    },
    file_name: {
      type: 'autoComplete',
      field: 'file_name',
      label: 'File Name',
      api: `/folderFiles`,
      suggestionField: 'name',
      valueField: 'name',
      filter: ({values}) => {
        return {
          folder_path_id: values?.folder_path_id,
        };
      },
      onCreate: ({searchValue, onChange, form: {values}}) => {
        navigation.navigate(`add-file-name`, {
          ...params,
          defaultValues: {
            folder_path_id: values?.folder_path_id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      visible: ({values}) => values?.folder_path_id,
    },
    collection_name: {
      label: 'Collection Name',
      type: 'text',
      field: 'collection_name',
    },
    schema_options: {
      label: 'Options',
      type: 'textArea',
      field: 'schema_options',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    timestamp: {
      label: 'Timestamp',
      type: 'checkbox',
      field: 'timestamp',
    },
    trigger_name: {
      label: 'Name',
      type: 'text',
      field: 'name',
      required: true,
    },
    trigger_operation: {
      label: 'Operation',
      type: 'multiAutoComplete',
      field: 'event_operation',
      options: props => {
        const {values} = props;
        const {event_operation} = values || {};
        const list = ['insert', 'update', 'delete', 'validate'];
        const finalValues = list.filter(
          item => !event_operation?.includes(item),
        );
        return finalValues;
      },
    },
    trigger_event_on: {
      label: 'Event On',
      type: 'autoComplete',
      field: 'event_on',
      options: ['before', 'after'],
    },
    trigger_event_condition: {
      label: 'Condition',
      type: 'textArea',
      field: 'event_condition',
      inputProps: {
        minRows: 2,
        maxRows: 6,
      },
    },
  };
};

const FieldNameRender = ({row, styles}) => {
  const {name, required, primary} = row;
  const {ERROR_HIGH} = useTheme('colors');
  const {PrimaryKey} = useTheme('icons');
  return (
    <Row gap={4} style={{alignItems: 'center'}}>
      <Text title={name} style={styles?.rowText} numberOfLines={1}>
        {name}
      </Text>
      {required ? (
        <Text style={{...styles?.rowText, color: ERROR_HIGH}}>*</Text>
      ) : (
        void 0
      )}
      {primary ? (
        <Image style={{height: 12, width: 12}} source={PrimaryKey} />
      ) : (
        void 0
      )}
    </Row>
  );
};

const FieldTypeRender = ({row, styles}) => {
  const {type, enum: rowEnum} = row;
  return (
    <Row gap={4} style={{alignItems: 'center'}}>
      <Text title={type} style={styles?.rowText}>
        {type}
      </Text>
      {Array.isArray(rowEnum) && rowEnum.length ? (
        <Tag title={rowEnum.toString()} value={'E'} />
      ) : (
        void 0
      )}
    </Row>
  );
};

const FieldsNestedTable = props => {
  const {project} = props;

  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={({values}) => {
        const {name} = values;
        return {
          title: name ? 'Add Nested Field' : 'Add Field',
          secondaryTitle: name,
        };
      }}
      editFormHeader={({values}) => {
        const {name} = values;
        return {
          title: 'Edit Field',
          secondaryTitle: name,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      inlineAddForm
      addInBottom
      inlineFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
          size: 6,
        },
        {
          required: true,
          label: 'Type',
          type: 'autoComplete',
          options: fieldTypes,
          field: 'type',
          onChangeValue: (value, _, {setFieldValue}) => {
            if (value !== 'nested') {
              setFieldValue('fields', void 0);
            }
          },
          size: 6,
        },
      ]}
      fields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
          size: 6,
        },
        {
          required: true,
          label: 'Type',
          type: 'autoComplete',
          options: fieldTypes,
          field: 'type',
          onChangeValue: (value, _, {setFieldValue}) => {
            if (value !== 'nested') {
              setFieldValue('fields', void 0);
            }
          },
          size: 6,
        },
        {
          label: 'Max Length',
          type: 'number',
          field: 'maxLength',
        },
        {
          label: 'Reference Detail',
          fields: [
            [
              {
                label: 'Table',
                type: 'autoComplete',
                field: 'reference_table',
                api: `/projectModels`,
                suggestionField: 'model',
                valueField: 'model',
                secondarySuggestionField: 'module_id.module',
                fields: {
                  model: 1,
                  module_id: {
                    module: 1,
                    identifier: 1,
                  },
                },
                filter: {project_id: project?._id},
                onChangeValue: (value, _, {setFieldValue}) => {
                  setFieldValue('reference_module', value?.module_id);
                  setFieldValue('reference_table_column', void 0);
                },
                size: 6,
              },
              {
                label: 'Module',
                type: 'autoComplete',
                field: 'reference_module',
                api: `/projectModules`,
                suggestionField: 'module',
                valueField: 'module',
                filter: {project_id: project?._id},
                size: 6,
                readOnly: true,
                visible: ({values}) => values?.reference_table,
              },
            ],
            {
              visible: ({values}) => values?.reference_table,
              label: 'Reference Column',
              type: 'text',
              field: 'reference_table_column',
              size: 6,
            },
            {
              visible: ({values}) => values?.reference_table,
              field: 'is_secondary_reference',
              label: 'Secondary Reference',
              type: 'checkbox',
              size: 6,
            },
          ],
        },
        {
          field: 'primary',
          label: 'Primary',
          type: 'checkbox',
          size: 6,
        },
        {
          field: 'required',
          label: 'Required',
          type: 'checkbox',
          size: 6,
        },
        {
          field: 'multiple',
          label: 'Multiple',
          type: 'checkbox',
          size: 6,
        },
        {
          field: 'unique',
          label: 'Unique',
          type: 'checkbox',
          size: 6,
        },
        {
          type: 'multiText',
          field: 'enum',
          label: 'Enum',
          helperText: 'Note: Type value and press enter to add it in the array',
        },
        {
          field: 'default_value',
          label: 'Default Value',
          type: 'text',
          size: 4,
        },
        {
          label: 'Alias',
          type: 'text',
          field: 'alias',
          size: 4,
        },
        {
          field: 'example_value',
          label: 'Example',
          type: 'text',
          size: 4,
        },
        {
          visible: ({values}) => values?.type == 'nested',
          label: 'Fields',
          fields: [
            {
              nested: true,
              field: 'fields',
              render: props => (
                <FieldsNestedTable {...props} project={project} />
              ),
            },
          ],
        },
      ]}
      columns={[
        {
          header: 'Name',
          render: props => {
            return <FieldNameRender {...props} />;
          },
          minWidth: 80,
        },
        {
          header: 'Type',
          render: props => {
            return <FieldTypeRender {...props} />;
          },
          width: 90,
        },
        {
          header: 'Reference',
          type: 'text',
          minWidth: 80,
          value: ({row}) => {
            const {reference_table} = row;
            if (!reference_table) {
              return;
            }

            if (typeof reference_table === 'object') {
              return reference_table?.model;
            } else {
              return reference_table;
            }
          },
        },
      ]}
    />
  );
};

const TriggersNestedTable = props => {
  const {params, navigation} = props;
  const fields = dataEntityFields({params, navigation});
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={({values}) => {
        const {name} = values;
        return {
          title: 'Add Trigger',
          secondaryTitle: name,
        };
      }}
      editFormHeader={({values}) => {
        const {name} = values;
        return {
          title: 'Edit Trigger',
          secondaryTitle: name,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '70%',
      }}
      fields={[
        fields.trigger_name,
        fields.trigger_operation,
        fields.trigger_event_on,
        fields.trigger_event_condition,
      ]}
      columns={[
        {
          header: 'Name',
          render: props => {
            return <FieldNameRender {...props} />;
          },
          minWidth: 80,
        },
        {
          header: 'Operation',
          render: ({row}) => (
            <MultiTag values={row.event_operation} maxWidth={120} />
          ),
        },
        {
          header: 'Event On',
          field: 'event_on',
          type: 'text',
        },
        {
          header: 'Condition',
          field: 'event_condition',
          type: 'text',
        },
      ]}
    />
  );
};

export const DataEntityForm = props => {
  const {
    navigation,
    route: {params},
    mode,
  } = props;

  const {project} = params;

  const fields = dataEntityFields({params, navigation});

  return (
    <Form
      type={'tab'}
      submitAction="Save"
      tabs={{
        basic_info: {
          label: 'Basic Info',
          layoutFields: [
            fields.entity,
            fields.database({readOnly: mode}),
            fields.folder_path,
            fields.file_name,
            {
              collapsedFields: [
                fields.owner,
                fields.module({readOnly: mode}),
                fields.description,
                fields.collection_name,
                fields.timestamp,
                fields.schema_options,
              ],
            },
          ],
        },
        fields: {
          label: 'Fields',
          layoutFields: [
            {
              nested: true,
              field: 'fields',
              render: props => (
                <FieldsNestedTable {...props} project={project} />
              ),
            },
          ],
        },
        triggers: {
          label: 'Triggers',
          layoutFields: [
            {
              nested: true,
              field: 'triggers',
              render: props => (
                <TriggersNestedTable
                  {...props}
                  navigation={navigation}
                  params={params}
                  project={project}
                />
              ),
            },
          ],
        },
        remarks: {
          visible: !!mode,
          label: 'Remarks',
          layoutFields: [fields.remarks],
        },
      }}
      {...props}
    />
  );
};

export const AddDataEntityForm = props => {
  const {
    route: {params},
  } = props;
  const {project, module, feature, searchValue, afterSubmit, row} = params;

  const database_id = useMemo(() => {
    const {project_databases = []} = project;
    if (project_databases.length === 1) {
      return project_databases[0];
    } else if (project_databases.length > 1) {
      let moduleMap = {};
      project_databases.forEach(db => {
        const {module_ids = []} = db;
        module_ids.forEach(module_id => {
          if (moduleMap[module_id._id]) {
            moduleMap[module_id._id].count++;
          } else {
            moduleMap[module_id._id] = {
              count: 1,
              database_id: db,
            };
          }
        });
      });
      if (moduleMap[module?._id] && moduleMap[module?._id].count === 1) {
        return moduleMap[module?._id].database_id;
      }
    }
  }, [project]);

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_MODEL_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    model: searchValue,
    project_id: project?._id,
    module_id: module,
    source: PROJECT_MODEL_TYPE_ID,
    feature_id: feature?._id,
    database_id,
  };

  return (
    <DataEntityForm
      key={row?._id}
      header="Add Model"
      onSubmit={createAsset}
      beforeSubmit={requiredValidation}
      afterSubmit={afterSubmit}
      defaultValues={initialValues}
      {...props}
    />
  );
};

export const DataEntityDetailForm = props => {
  const {
    route: {params},
    isReleased,
  } = props;

  const {row, feature, readOnly} = params;

  const {updateHistory, updateAsset, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_MODEL_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  let isReadOnly = readOnly;
  let isDualMode = true;

  if (params?.project?._id === LOCOMO_PROJECT_ID && isReleased) {
    isReadOnly = false;
    isDualMode = false;
  }

  return (
    <DataEntityForm
      key={row._id}
      mode="edit"
      selectedTab={'fields'}
      header={{title: 'Model Detail', secondaryTitle: row?.model}}
      isDualMode={isDualMode}
      readOnly={isReadOnly || row?.aiGenerated}
      data={row}
      onSubmit={isReleased ? updateAsset : updateHistory}
      submitAction="Save"
      beforeSubmit={assetBeforeSubmit({data: row})}
      {...props}
    />
  );
};
