import {useTheme} from '@unthinkable/react-theme';
import {
  Row,
  TouchableOpacity,
  Linking,
  Image,
} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';

const multipleFileRenderer = ({row, field}) => {
  const {resourceUrl} = useAppStateContext();
  const {Reimbursement} = useTheme('icons');
  const data = row[field];

  if (!data || !data.length) return null;

  return (
    <Row gap={6}>
      {data.map(item => {
        return (
          <TouchableOpacity
            onPress={() => {
              const url = resourceUrl(item);
              Linking.openURL(url, '_blank');
            }}>
            <Image source={Reimbursement} width={20} height={20} />
          </TouchableOpacity>
        );
      })}
    </Row>
  );
};

export {multipleFileRenderer};
