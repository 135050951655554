import React from 'react';

export const Loader = ({
  progress,
  color = 'rgba(178, 238, 124, 0.4)',
  progressedColor = 'rgba(178, 238, 124, 1)',
}) => {
  const backgroundCircleStyle = {
    stroke: color,
    strokeWidth: '8',
  };

  const progressCircleStyle = {
    stroke: progressedColor,
    strokeWidth: '8',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  };

  const radius = 32;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = circumference;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div>
      <svg width="100" height="100">
        <circle
          cx="50"
          cy="50"
          r={radius}
          style={backgroundCircleStyle}
          fill="transparent"
          strokeLinecap="round"
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          style={progressCircleStyle}
          fill="transparent"
          strokeLinecap="round"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </div>
  );
};
