import React from 'react';
import {OutlineButtonStyle} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {Button as ButtonComponent} from '@unthinkable/react-button';

export const OutlineButton = ({
  borderColor,
  hoverContainerStyle,
  textColor,
  ...props
}) => {
  const style = useStyles(OutlineButtonStyle);

  if (borderColor) {
    style.container.borderColor = borderColor;
  }

  if (hoverContainerStyle) {
    style.hoverContainer = {...style.hoverContainer, ...hoverContainerStyle};
  }

  if (textColor) {
    style.text.color = textColor;
  }

  return <ButtonComponent styles={style} {...props} />;
};
