import React from 'react';
import {Table} from '../../../components/table/Table';
import {EmployeeSize} from '../controllers/EmployeeSize';

export const ActiveEmployeeSizeTable = props => {
  const {navigation} = props;
  const {markEmployeeSizeInactive} = EmployeeSize(props);
  return (
    <Table
      eventSourceId="EmployeeSize"
      api={`/employeeSize/active`}
      onRowPress={props => {
        navigation.navigate(`employee-size-detail`, {
          ...props,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'number',
          header: 'Number',
          responsive: 'sm',
        },
        {
          type: 'number',
          field: 'score',
          header: 'Score',
          responsive: 'sm',
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark InActive',
          confirm: {
            title: 'Mark InActive',
            message: 'Are you sure you want to Mark InActive?',
          },
          onPress: markEmployeeSizeInactive,
        },
      ]}
    />
  );
};
