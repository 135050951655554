import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const OrderExtendedForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}, formPartialExtend = false} = params;
  let {onSubmit} = useFormSubmit({
    uri: formPartialExtend
      ? `/tandm-order/partial-extend/${row?.orderId}`
      : `/tandm-order/extend/${row?._id}`,
    eventSourceId: ['t&m', 'orderTable', 'fixed'],
  });

  return (
    <Form
      header={{
        title: 'Extend order',
        secondaryTitle: `${row?.order_number}`,
      }}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            skill_requirements: formPartialExtend ? row?._id : void 0,
          },
        };
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'order_extend_date',
          placeholder: 'Extend till',
          label: 'Extend till',
          validate: value => {
            if (
              !formPartialExtend &&
              new Date(value) <= new Date(row.order_end_date)
            ) {
              return "Extend Date can't be less than or equal to Previous";
            }
            if (formPartialExtend && new Date(value) <= new Date(row.to_date)) {
              return "Extend Date can't be less than or equal to Previous";
            }
          },
          required: true,
        },
      ]}
      {...props}
    />
  );
};
