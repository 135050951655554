import { Text, TouchableOpacity } from '@unthinkable/react-core-components';
import { useFormContext } from '@unthinkable/react-form/src/FormContext';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

import { useTheme } from '@unthinkable/react-theme';

export const AddPoc = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'POC',
    uri: '/opportunities',
    ...props,
  });

  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  const defaultValues = {
    rfr_type: 'Customer',
    opportunity_type: 'POC',
    owner: employeeId,
    organization: params?.organization || employee?.organization,
    product: params?.product || employee?.product,
    creation_date: new Date(),
    status: 'Active',
    type: 'Proof of Concept',
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      header={'Create POC'}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'type',
          options: [
            {
              label: 'Customer',
              value: 'Customer',
            },
            {
              label: 'Potential Customer',
              value: 'PotentialCustomer',
            },
          ],
        },
        {
          type: 'textArea',
          placeholder: 'POC Name',
          field: 'requirement',
          rules: {required: true},
        },
        {
          placeholder: 'Customer',
          field: 'customer_id',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) =>
            values && values.rfr_type && values.rfr_type === 'Customer',
        },

        {
          placeholder: 'Potentail Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: `/potentialcustomers`,
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) =>
            values &&
            values.rfr_type &&
            values.rfr_type === 'PotentailCustomer',
        },
        {
          placeholder: 'Skill',
          field: 'skill',
          type: 'multiAutoComplete',
          api: `/skills`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Account Owner',
          placeholder: 'Account Owner',
          field: 'account_owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          size: 4,
        },
        {
          type: 'number',
          placeholder: 'Estimated(hours)',
          field: 'est_hr',
          size: 4,
        },
        {
          type: 'textArea',
          placeholder: 'Paste Link',
          field: 'poc_link',
          size: 4,
        },
        {
          field: 'requirement_document',
          type: 'file',
          placeholder: 'File',
          size: 4,
          multiple: true,
        },
        {
          placeholder: 'Product',
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },

        {
          field: 'description',
          render: props => {
            console.log('---propos--');
            const {value, values} = props;
            const {setFieldValue} = useFormContext();
            const colors = useTheme('colors');
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('add-poc-description', {
                    value,
                    setFieldValue,
                    // selectedIds: value,
                  });
                }}>
                <Text
                  style={{
                    color: colors['ACCENT1_HIGH'],
                    paddingTop: 4,
                    paddingBottom: 4,
                    textDecoration: 'underline',
                  }}>
                  Add Description
                </Text>
              </TouchableOpacity>
            );
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddDescription = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {setFieldValue, value} = params;
  console.log('props>>>>>>', props);
  let onSubmit = values => {
    console.log('values>>>>>', values);
    setFieldValue('description', values.description);
    return {};
  };

  const defaultValues = {
    description: value,
  };
  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      header={'Create POC'}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'textArea',
          placeholder: 'Description',
          field: 'description',
          rules: {required: true},
        },
      ]}
    />
  );
};
