import {useState} from 'react';
import {populateFilter} from '../util';

export const useGroupFilter = ({filterValues} = {}) => {
  const [groupFilter, setGroupFilter] = useState({filterValues});

  const onChangeFilter = props => {
    let newGroupFilter = populateFilter({...groupFilter}, props);
    setGroupFilter(newGroupFilter);
  };

  return {
    ...groupFilter,
    onChangeFilter,
  };
};
