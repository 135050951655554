import React from 'react';
import {GraphView} from './GraphView';

export const ScreenGraphView = props => {
  return (
    <GraphView
      collapsable={true}
      type="screens"
      direction="top-to-bottom"
      relationshipKey="linked_to_screens"
      nodeField="view"
      {...props}
    />
  );
};
