import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

export const ClientProjectEmployeeAssignmentTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {project} = params;

  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Employee'});

  const projectMemberDetail = ({row}) => {
    navigation.navigate(`project-member-detail`, {
      ...params,
      employee_assignment_id: {_id: row?._id},
    });
  };

  return (
    <Table
      eventSourceId={['Employee']}
      api={`/employeeassignments`}
      sort={{createdAt: -1}}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <AddButton
                title={'Add Member'}
                view={'add-client-project-member'}
                params={params}
              />,
            ]}
          />
        );
      }}
      filter={{
        project_id: project?._id,
      }}
      fields={{
        employee: {name: 1, color: 1},
        from_date: 1,
        to_date: 1,
        percentage: 1,
      }}
      onRowPress={projectMemberDetail}
      columns={[
        {
          type: 'userAvatarChip',
          field: 'employee',
          header: 'Member Name',
          minWidth: 300,
        },
        {
          type: 'date',
          field: 'from_date',
          header: 'From',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          type: 'date',
          field: 'to_date',
          header: 'To',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          type: 'number',
          field: 'percentage',
          header: '%',
          width: 100,
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: props => {
            const {row} = props;
            deleteInvoke({
              uri: `/employeeassignments/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
