import {Styled, View} from '@unthinkable/react-core-components';

export const RichTextWrapper = Styled(View)`
border-width: 1px;
border-style: solid;
border-color: #EEEEED;
border-radius: 6px;
background-color: #fff;
min-height: 150px;
${({styles}) => styles?.wrapper}
`;
