import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import moment from 'moment';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../constants/SourceConstants';

export const EmployeeAssignmentForm = props => {
  const {
    route: {params},
  } = props;
  const {EmployeeAssignmentId, skill_metrics, product, department, object} =
    params;
  let {onSubmit} = useFormSubmit({
    uri: '/EmployeeAssignments',
    eventSourceId: 'EmployeeAssignment',
  });
  const defaultValues = {
    skill_metrics,
    product,
    department,
    object,
  };
  if (params?.project?._id) {
    defaultValues.project_id = params?.project?._id;
  }

  return (
    <Form
      api={`/EmployeeAssignments/${EmployeeAssignmentId}`}
      fields={{
        from_date: 1,
        to_date: 1,
        percentage: 1,
        hours: 1,
        employee: {name: 1},
        department: {name: 1},
        product: {name: 1},
        object: {name: 1},
        skill_metrics: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          type: 'date',
          field: 'from_date',
          label: 'From',
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To',
        },
        {
          type: 'number',
          field: 'percentage',
          label: 'Percentage',
        },
        {
          type: 'number',
          field: 'hours',
          label: 'Hours',
        },
        // {
        //   label: 'Department',
        //   field: 'department',
        //   type: 'autoComplete',
        //   api: `/departments`,
        //   suggestionField: 'name',
        //   valueField: 'name',
        // },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          visible: () => !params.product,
        },
        // {
        //   label: 'Type',
        //   field: 'object',
        //   type: 'autoComplete',
        //   api: '/types',
        //   filter: {
        //     departments: params?.department?._id,
        //     is_show: true,
        //   },
        //   suggestionField: 'name',
        //   valueField: 'name',
        // },
        {
          label: 'Skill Matrix',
          field: 'skill_metrics',
          type: 'autoComplete',
          api: '/skillMetrics',
          suggestionField: 'name',
          valueField: 'name',
          visible: () => !params.skill_metrics,
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeAssignmentForm = props => {
  return <EmployeeAssignmentForm header="Add Employee Assignment" {...props} />;
};

export const UpdateEmployeeAssignmentForm = props => {
  return (
    <EmployeeAssignmentForm
      mode="edit"
      header="Update Employee Assignment"
      {...props}
    />
  );
};

export const AddEmployeeSkill = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {employeeSkillId, skill_metrics, product} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/EmployeeAssignments',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      api={`/EmployeeAssignments/${employeeSkillId}`}
      fields={{
        from_date: 1,
        to_date: 1,
        percentage: 1,
        hours: 1,
        employee: {name: 1},
        department: {name: 1},
        product: {name: 1},
        object: {name: 1},
        skill_metrics: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        from_date: getZeroTimeDate(new Date()),
        to_date: getZeroTimeDate(moment().add('y', 1).toDate()),
        percentage: 100,
        product,
        skill_metrics,
      }}
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          label: 'Object',
          field: 'object',
          type: 'cascader',
          cascades: [
            {
              api: '/types',
              field: 'object',
              fields: {name: 1, display_field: 1},
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              placeholder: 'Source Id',
              field: 'object_id',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({values}) => {
                const {source} = values || {};
                return {
                  source,
                };
              },
              onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                navigation,
                params,
              }),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateDeliveryHours = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/EmployeeAssignments',
    eventSourceId: 'allocatedResource',
  });
  return (
    <Form
      api={`/employeeassignments/${row?._id}`}
      fields={{
        employee_delivered_hours: 1,
      }}
      onSubmit={onSubmit}
      mode="edit"
      header="Update Delivered Hours"
      submitAction="Save"
      layoutFields={[
        {
          type: 'number',
          field: 'employee_delivered_hours',
          label: 'Delivered Hours',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
