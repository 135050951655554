import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {AddButton} from '../../../components/button/AddButton';
import {
  NonProjectTimesheetTable,
  ProjectTimesheetTable,
} from './TimesheetTable';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';

const TimesheetRequestTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  let {employee = {}} = user || {};

  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  params = {...params, user};

  let tabs = {};
  tabs['projectTimesheet'] = {
    label: 'Project Timesheet',
    eventSourceId: 'projectTimesheet',
    view: <ProjectTimesheetTable user={user} {...props} />,
    api: '/timesheets/project',
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
    ],
  };

  tabs['nonProjectTimesheet'] = {
    label: 'Non Project Timesheet',
    eventSourceId: 'nonProjectTimesheet',
    view: <NonProjectTimesheetTable user={user} {...props} />,
    // api: '/timesheets',
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <AddButton
        title=" Timesheet"
        eventSourceId="nonProjectTimesheet"
        view="add-timesheet"
        params={params}
      />,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export default TimesheetRequestTab;
