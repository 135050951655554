import React from 'react';
import { Table } from '../../../components/table/Table';

const FixedOrderAssignedResourcesTable = props => {
  let {navigation = {}, route = {}} = props || {};

  let {params = {}} = route || {};
  let {row = {}} = params || {};
  let filterParams={}
  if(row?._id)filterParams['order']=row._id
  return (
    <Table
      api={`/orderassignments`}
      params={params}
      filter={filterParams}
      fields={{
        
        hours: 1,
        to_date: 1,
        from_date: 1,
        employee: {
          name: 1,
        },
        order: {
          order_number: 1,
        },
        skill_required:{
          name:1
        }
      }}
      columns={[
        {
          field: 'employee',
          header: 'Name',
          type:'userAvatarChip'
        },
        {
          field: 'skill_required.name',
          header: 'Skill',
        },
        {
          field: 'from_date',
          header: 'From Date',
          type: 'date',
        },
        {
          field: 'to_date',
          header: 'To Date',
          type: 'date',
        },
        {
          field: 'hours',
          header: 'Hours',
          type: 'number',
        },
      ]}
    />
  );
};

export default FixedOrderAssignedResourcesTable;
