import React from 'react';
import {TabView} from '../../../components/tab';
import {SLAConfigurationList, WorkingCycleScreen} from './EmployeeTicketsForms';
import {TicketCategoryList} from './TicketCategoryList';
import {TicketSubCategoryList} from './TicketSubCategoryList';
import {TicketOwnerList} from './TicketOwnerList';

export const TicketSetupScreen = props => {
  return (
    <TabView
      tabs={{
        SLAConfiguration: {
          label: 'SLA Configuration',
          view: <SLAConfigurationList {...props} />,
        },
        ticketWorkingCycle: {
          label: 'Working Cycle',
          view: <WorkingCycleScreen {...props} />,
        },
        ticketCategories: {
          label: 'Categories',
          view: <TicketCategoryList {...props} />,
        },
        ticketSubCategories: {
          label: 'Sub Categories',
          view: <TicketSubCategoryList {...props} />,
        },
        ticketOwners: {
          label: 'Owners',
          view: <TicketOwnerList {...props} />,
        },
      }}
    />
  );
};
