import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
import {useAccentColor} from '../../../controllers/useAccentColor';
import moment from 'moment';
import {Row, View} from '@unthinkable/react-core-components';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Avatar} from '../../../components/avatar/Avatar';
import {ColorTag} from '../../../components/chip/ColorChip';
import {GroupRowText} from '@unthinkable/react-table';

export const UserAvatarWithDateChip = ({
  field,
  dateField,
  approve,
  requested,
  referred,
  size = 27,
  ...props
}) => {
  const {row} = props;
  let value = row[field];
  let dateValue = row[dateField];
  if (!dateValue) dateValue = void 0;
  else dateValue = moment(dateValue).format('DD MMM ');

  if (!value) {
    return null;
  }
  let subTitle = `Rejected on ${dateValue}`;
  if (approve) subTitle = `Approved on ${dateValue}`;
  if (requested) subTitle = `Requested on ${dateValue}`;
  if (referred) subTitle = `Referred on ${dateValue}`;

  if (!dateValue) subTitle = '';

  const {name, color} = value;
  const {HIGH, MEDIUM, LOW} = useAccentColor(color);
  return (
    <Row gap={7} style={{alignItems: 'center'}}>
      <Avatar value={name} color={HIGH} borderColor={LOW} size={size} />
      <PrimaryColumnCard
        primaryTitle={name}
        items={[
          {
            value: subTitle,
          },
        ]}
      />
    </Row>
  );
};

export const renderType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  let backgroundColor = '#F5E0FF';

  const type = row?.reimbursement_type;
  if (type == 'Certificate') backgroundColor = '#FFF7D6';

  if (!type) return null;
  return <Tag value={type} color={backgroundColor} />;
};

export const UserAvatarWithNameAndEmail = props => {
  const {
    name,
    color,
    official_email_id,
    primarySubTitle,
    primarySubTitleColor,
    values = [],
  } = props;
  const {HIGH, LOW} = useAccentColor(color);
  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={name} color={HIGH} brorderColor={LOW} size={27} />
      <PrimaryColumnCard
        primaryTitle={name}
        primarySubTitleColor={primarySubTitleColor}
        primarySubTitle={primarySubTitle}
        items={
          values?.length
            ? values
            : [
                {
                  value: official_email_id,
                },
              ]
        }
      />
    </Row>
  );
};

export const UserAvatarWithNameAndEmployeeCode = props => {
  const {
    name,
    color,
    employee_code,
    employee_skills,
    primarySubTitle,
    primarySubTitleColor,
  } = props;
  const {HIGH, LOW} = useAccentColor(color);
  const skills = employee_skills
    ?.map(skill => {
      return skill?.skill_metrics?.name;
    })
    .join(', ');

  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={name} color={HIGH} brorderColor={LOW} size={27} />
      <PrimaryColumnCard
        primaryTitle={name}
        primarySubTitleColor={primarySubTitleColor}
        primarySubTitle={primarySubTitle}
        items={[
          {
            value: employee_code,
          },
          {
            value: skills,
          },
        ]}
      />
    </Row>
  );
};

export const UserAvatarWithNameCodeJoiningDate = props => {
  const {
    name,
    color,
    employee_code,
    joining_date,
    primarySubTitle,
    primarySubTitleColor,
  } = props;
  const {HIGH, LOW} = useAccentColor(color);
  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={name} color={HIGH} brorderColor={LOW} size={27} />
      <PrimaryColumnCard
        primaryTitle={name}
        primarySubTitleColor={primarySubTitleColor}
        primarySubTitle={primarySubTitle}
        items={[
          {
            value: employee_code,
          },
          {
            value:
              joining_date &&
              `Joined : ${moment(joining_date).format('DD MMM YY')}`,
          },
        ]}
      />
    </Row>
  );
};

export const UserAvatarForParichayWithNameDOB = props => {
  const {name, color, dob, primarySubTitle, primarySubTitleColor} = props;
  const {HIGH, LOW} = useAccentColor(color);
  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={name} color={HIGH} brorderColor={LOW} size={27} />
      <PrimaryColumnCard
        primaryTitle={name}
        primarySubTitleColor={primarySubTitleColor}
        primarySubTitle={primarySubTitle}
        items={[
          {
            value: `DOB : ${moment(dob).format('DD MMM, YY')}`,
          },
        ]}
      />
    </Row>
  );
};

export const UserAvatarForEmployeeUser = props => {
  const {name, color, dob, primarySubTitle, primarySubTitleColor} = props;
  const {HIGH, LOW} = useAccentColor(color);
  return (
    // <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={name} color={HIGH} brorderColor={LOW} size={27} />
      <PrimaryColumnCard
        primaryTitle={name}
        primarySubTitleColor={primarySubTitleColor}
        primarySubTitle={primarySubTitle}
        // items={[
        //   {
        //     value: `DOB : ${moment(dob).format('DD MMM, YY')}`,
        //   },
        // ]}
      />
    </Row>
  );
};

export const ServiceDurationAvatar = props => {
  const {
    service_duration,
    joining_date,
    relieving_date,
    color,
    primarySubTitleColor,
    primarySubTitle,
  } = props;
  const {HIGH, LOW} = useAccentColor(color);
  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <PrimaryColumnCard
        primaryTitle={service_duration}
        primarySubTitleColor={primarySubTitleColor}
        primarySubTitle={primarySubTitle}
        items={[
          {
            value: `${moment(joining_date).format('DD MMM YY')}`,
          },
          {
            value: `${moment(relieving_date).format('DD MMM YY')}`,
          },
        ]}
      />
    </Row>
  );
};

export const ExitCategoryAvatar = props => {
  const colors = useTheme('colors');
  const {ACCENT6_LOW, ACCENT5_LOW, ACCENT3_LOW, ACCENT2_LOW} = colors;
  const {exit_type, desireable_status} = props;

  const colorMap = {
    Voluntary: '#FFF7D6',
    Involuntary: '#F5E0FF',
    Shorcoming: '#DBFFFE',
    Transfered: '#FFE0F0',
  };

  return (
    <View
      style={{
        flexDirection: 'columns',
        alignItems: 'left',
        gap: 4,
      }}>
      <Tag {...props} value={exit_type} color={colorMap[exit_type]} />
      <GroupRowText
        styles={{
          groupRowText: {
            overflow: 'hidden',
            justifyContent: 'center',
            color: '#737373',
            fontSize: 12,
            lineHeight: '16px',
            fontWeight: 500,
            paddingLeft: 12,
          },
        }}>
        {desireable_status}
      </GroupRowText>
    </View>
  );
};

export const LegalStatusAvatar = props => {
  const colors = useTheme('colors');
  const {ACCENT6_LOW, ACCENT5_LOW, ACCENT3_LOW, ACCENT2_LOW} = colors;
  const {legal_status, legal_marked_on} = props;

  const colorMap = {
    ' SA Breach': '#FFF7D6',
    'Discplinary issues': '#F5E0FF',
    Other: '#DBFFFE',
    'Unautorised absence': '#FFE0F0',
  };

  return (
    <View
      style={{
        flexDirection: 'columns',
        alignItems: 'left',
        gap: 4,
      }}>
      <Tag {...props} value={legal_status} color={colorMap[legal_status]} />
      <GroupRowText
        styles={{
          groupRowText: {
            overflow: 'hidden',
            justifyContent: 'center',
            color: '#737373',
            fontSize: 12,
            lineHeight: '16px',
            fontWeight: 500,
            paddingLeft: 12,
          },
        }}>
        {legal_marked_on && moment(legal_marked_on).format('DD MMM YY')}
      </GroupRowText>
    </View>
  );
};
