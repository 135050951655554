import React from 'react';
import {TasksTable} from './TasksTable';
import {AdhocTaskInlineForm} from './TaskForm';
import {useTaskActions} from './TaskActions';

export const SubTaskTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {parent_task, fromMyView, parentSourceView, employee_team} = params;

  const {
    activityLogs,
    updateDueDate,
    archive,
    updatePlan,
    deleteTask,
    moveToBacklog,
    markDisqualified,
    markQualified,
    showOutput,
    unarchive,
    markIncomplete,
  } = useTaskActions({navigation, params, fromMyView});

  const subtaskMyView =
    fromMyView ||
    ['Overdue', 'MyTeam', 'EmployeeEfficiency'].includes(parentSourceView);

  const showAddSubtask = !parent_task.est_hours;

  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
    });
  };

  return (
    <TasksTable
      {...props}
      onRowPress={taskDetail}
      isSubTaskTable
      showMarkComplete={!subtaskMyView}
      subtaskMyView={subtaskMyView}
      parentSourceView={parentSourceView}
      isActive={true}
      sort={{due_date: 1, createdAt: -1}}
      addOnFilter={{
        parent_task: parent_task?._id,
      }}
      renderHeader={
        showAddSubtask
          ? () => {
              return (
                <AdhocTaskInlineForm
                  defaultValues={{
                    status: 'backlog',
                    employee_team: employee_team,
                  }}
                  params={params}
                />
              );
            }
          : void 0
      }
      moreActions={[
        archive,
        updatePlan,
        updateDueDate,
        moveToBacklog,
        markDisqualified,
        markQualified,
        showOutput,
        unarchive,
        // markIncomplete,
        activityLogs,
        deleteTask,
      ]}
    />
  );
};
