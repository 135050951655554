import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from './components/Durations';
import moment from 'moment';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';
import {UserAvatarGroupChip} from '../../../components/avatar/UserAvatar';
import {TableHeader} from '../../../components/table/Headers';

const RenderDeliveryID = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  if (row?.delivery_number) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_HIGH,
          }}>
          {row.delivery_number}
        </Text>
        <Text
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_LOW,
          }}>
          {moment(row.delivery_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

const markApprove = ({navigation, params}) => ({
  title: 'Mark Approve',
  onPress: props => {
    const {row} = props;

    navigation.navigate('project-resource-working-data-from', {
      row,
    });
  },
});

export const ProjectResourceWorkingDataTable = props => {
  let {
    filterParams = {},
    route: {params},
    navigation,
  } = props;

  return (
    <Table
      filter={filterParams}
      eventSourceId="projectResourceWorking"
      api={'/ProjectResourceWorkingData'}
      fields={{
        project: {project: 1},
        employee: {name: 1, color: 1},
        billing_start_date: 1,
        billing_end_date: 1,
        billed_hours: 1,
        total_leave_hours: 1,
        total_ewd_hours: 1,
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-project-resource-working', {row});
      }}
      params={params}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
        },
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
        },
        {
          header: 'Billing Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.billing_end_date,
              fromDate: row?.billing_start_date,
            });
          },
        },
        {
          header: 'Bil. Hrs.',
          field: 'billed_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Total Leave Hrs.',
          field: 'total_leave_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Total EWD Hrs.',
          field: 'total_ewd_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
      ]}
    />
  );
};

export const UnapprovedProjectResourceWorkingDataTable = props => {
  let {
    filterParams = {},
    route: {params},
    navigation,
  } = props;

  return (
    <Table
      filter={filterParams}
      eventSourceId="projectResourceWorking"
      api={'/ProjectResourceWorkingData'}
      fields={{
        project: {project: 1},
        employee: {name: 1, color: 1},
        billing_start_date: 1,
        billing_end_date: 1,
        billed_hours: 1,
        total_leave_hours: 1,
        total_ewd_hours: 1,
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-project-resource-working', {row});
      }}
      params={params}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
        },
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
        },
        {
          header: 'Billing Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.billing_end_date,
              fromDate: row?.billing_start_date,
            });
          },
        },
        {
          header: 'Bil. Hrs.',
          field: 'billed_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Total Leave Hrs.',
          field: 'total_leave_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Total EWD Hrs.',
          field: 'total_ewd_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
      ]}
      moreActions={() => [markApprove({navigation, params})]}
    />
  );
};

export const ManMonthCustomerOverviewList = props => {
  const {
    route: {params},
  } = props;
  const {filter, row, title, period} = params;
  const previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = moment(period.from)
      .subtract(1, 'M')
      .endOf('M')
      .toDate();
  }
  return (
    <Table
      filter={{
        customer: row,
        due_date: {
          $gte: period.from,
          $lte: period.to,
        },
      }}
      eventSourceId="projectResourceWorking"
      api={'/manMonthCustomerOverview'}
      fields={{
        project: {project: 1},
        employee: {name: 1, color: 1},
        billing_start_date: 1,
        billing_end_date: 1,
        billed_hours: 1,
        total_leave_hours: 1,
        total_ewd_hours: 1,
      }}
      params={params}
      columns={[
        {
          header: 'Delivery ID',
          render: RenderDeliveryID,
        },
        {
          header: 'Man Months',
          type: 'number',
          aggregate: true,
          field: 'billing_hrs',
          toFixed: 2,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
      ]}
    />
  );
};

export const ProjectResourceWorkingDataForSkillRequirementTable = props => {
  let {
    route: {params},
  } = props;
  const {addOnfilter = {}} = params;
  return (
    <Table
      filter={{...addOnfilter}}
      renderHeader={() => <TableHeader title="Project Resource Working" />}
      eventSourceId="projectResourceWorking"
      api={'/ProjectResourceWorkingData'}
      fields={{
        project: {project: 1},
        project_resource_users: {name: 1, color: 1},
        billing_start_date: 1,
        billing_end_date: 1,
        variable_potential: 1,
        delivery_owner_id: {name: 1, color: 1},
        project_manager: {name: 1, color: 1},
        delivery_number: 1,
      }}
      params={params}
      columns={[
        {
          header: 'Delivery No.',
          field: 'delivery_number',
        },
        {
          header: 'Project',
          field: 'project.project',
        },
        {
          header: 'Resource',
          field: 'project_resource_users',
          render: UserAvatarGroupChip,
          width: 250,
        },
        {
          field: 'delivery_owner_id',
          header: 'DO',
          type: 'userAvatarChip',
          align: 'center',
        },
        {
          field: 'project_manager',
          header: 'PM',
          type: 'userAvatarChip',
          align: 'center',
        },
        {
          header: 'Billing Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.billing_end_date,
              fromDate: row?.billing_start_date,
            });
          },
          width: 180,
        },
        {
          header: 'Var. Potential',
          field: 'variable_potential',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};
