import React from 'react';
import {ActiveTargetList} from './ActiveTargetList';
import {CompletedTargetList} from './CompletedTargetList';
import {TabView} from '../../../components/tab/TabView';

export const AllTargetsTabView = props => {
  const {
    route: {params},
  } = props;
  const tabs = {
    allRPSTable: {
      label: 'Active',
      view: <ActiveTargetList />,
      api: `/products/${params?.product?._id}/departments/${params?.department?._id}/activeTargetsCount`,
    },
    unassignedRPSTable: {
      label: 'Completed',
      view: <CompletedTargetList />,
      api: `/products/${params?.product?._id}/departments/${params?.department?._id}/completedTargetsCount`,
    },
  };
  return <TabView tabs={tabs} {...props} />;
};
