import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const PodcastForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/podcasts',
        eventSourceId: 'podcast',
    });
    return (
        <Form
            api={`/podcasts/${row?._id}`}
            fields={{
                domain: {
                    domain: 1,
                },
                image: 1,
                title: 1,
                description: 1,
                time: 1,
                podcast_url: 1,
                index: 1,
            }}
            onSubmit={onSubmit}
            layoutFields={[
                {
                    label: 'Domain',
                    type: 'autoComplete',
                    api: '/domains',
                    suggestionField: 'domain',
                    valueField: 'domain',
                    field: 'domain',
                },
                {
                    label: 'Title',
                    type: 'textArea',
                    field: 'title',
                },
                {
                    label: 'Description',
                    type: 'textArea',
                    field: 'description',
                },
                {
                    label: 'Podcast Link',
                    type: 'text',
                    field: 'podcast_url'
                },
                {
                    label: 'Time',
                    type: 'text',
                    field: 'time'
                },
                {
                    label: 'Index',
                    type: 'number',
                    field: 'index',
                },
                {
                    field: 'image',
                    type: 'file',
                    label: 'Image',
                    required: true,
                },
            ]}
            {...props}
        />
    );
};

export const AddPodcastForm = props => {
    return <PodcastForm header="Add Podcast" {...props} />;
};

export const UpdatePodcastForm = props => {
    return (
        <PodcastForm mode="edit" header="Update Podcast" {...props} />
    );
};
