import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  ManPowerRequisitionApprovedTable,
  ManPowerRequisitionPendingTable,
} from './ManPowerRequisitionTable';
import {
  MrfApproverApprovedTable,
  MrfApproverPendingTable,
} from './MrfApproverTable';
import {useAppStateContext} from '../../../providers/AppState';

const {TabView} = require('../../../components/tab');

export const ManPowerRequisitionTab = props => {
  let {
    route: {params},
  } = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  const tabs = {
    pending: {
      label: 'Pending',
      view: <ManPowerRequisitionPendingTable {...props} />,
      api: `/manpowerrequisitions`,
      filter: {
        status: 'Pending',
        requested_by: employee._id,
      },
      eventSourceId: 'ManPowerRequisitionPending',
      actions: [
        <AddButton
          disabled={false}
          title="Request talent requisition"
          view="add-mrf"
          params={params}
        />,
      ],
    },
    approved: {
      label: 'Closed',
      view: <ManPowerRequisitionApprovedTable {...props} />,
      api: `/manpowerrequisitions`,
      filter: {
        status: {
          $in: ['Approved', 'Rejected', 'Processed'],
        },
        requested_by: employee?._id,
      },
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const MrfApproverTab = props => {
  let {
    route: {params},
  } = props;

  const tabs = {
    pending: {
      label: 'Pending',
      view: <MrfApproverPendingTable {...props} />,
      api: `/manpowerrequisitions`,
      filter: {
        status: 'Pending',
      },
      eventSourceId: 'MrfApproverPending',
    },
    approved: {
      label: 'Closed',
      view: <MrfApproverApprovedTable {...props} />,
      eventSourceId: 'MrfApproverApproved',
      api: `/manpowerrequisitions`,
      filter: {
        status: {
          $in: ['Rejected', 'Approved', 'Processed'],
        },
      },
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
