import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FetchPaidAdAnalyticsForm = props => {
  const params = props.route.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/fetch/${row?._id}/paid-ads-metrics`,
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Fetch PaidAds Analytics'}
      submitAction={'Fetch'}
      defaultValues={{
        campaign_id: row?.adId,
        type: row?.ad_type,
        paidAdId: row?._id,
      }}
      layoutFields={[
        {
          placeholder: 'Date',
          field: 'date',
          type: 'date',
        },
      ]}
    />
  );
};
