import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ImportTraineeForm = props => {
  const {
    route: {params},
  } = props;
  const {training_batch, defaultValues} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/importTrainee',
    eventSourceId: 'EmployeeTrainingsFromBatch',
  });
  return (
    <Form
      {...props}
      header={{title: 'Import Trainee', secondaryTitle: training_batch?.name}}
      submitAction="Import"
      onSubmit={onSubmit}
      defaultValues={{defaultValues}}
      layoutFields={[
        {
          type: 'file',
          field: 'file',
          label: 'Trainee File',
          required: true,
        },
      ]}
    />
  );
};
