import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const CategoryTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      eventSourceId={['category']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Payment Category"
            actions={[
              <AddButton
                title="Add Payment Category"
                view="add-category"
                params={params}
              />,
            ]}
          />
        );
      }}
      api={`/categories`}
      fields={{name: 1}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-category`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
      ]}
    />
  );
};
