import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {plural} from 'pluralize';

export const RolePermissionFormDataEntity = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/rolepermissions',
    eventSourceId: 'rolePermissionAdded',
    ...props,
  });

  return (
    <Form
      api={`/rolepermissions/${row?._id}`}
      fields={{
        app_role_id: {display_label: 1},
        app_resource_id: {
          display_label: 1,
          type: 1,
          id: 1,
          parent: {type: 1, id: 1},
        },
        scope: 1,
        dataRole: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Role Permission'}}
      layoutFields={[
        {
          label: 'App Role',
          type: 'autoComplete',
          field: 'app_role_id',
          api: '/approles',
          suggestionField: 'display_label',
          valueField: 'display_label',
          required: true,
        },
        {
          label: 'App Resource',
          type: 'autoComplete',
          field: 'app_resource_id',
          api: '/appresources',
          fields: {
            display_label: 1,
            type: 1,
            id: 1,
            parent: {type: 1, id: 1},
          },
          filter: {type: {$in: ['DataEntity', 'DataView']}},
          suggestionField: 'display_label',
          valueField: 'display_label',
          required: true,
        },
        {
          label: 'Data Role',
          type: 'autoComplete',
          field: 'dataRole',
          api: props => {
            const {
              values: {
                app_resource_id: {type = '', id = '', parent = ''} = {},
              } = {},
            } = props;

            let dataEntity = type === 'DataEntity' ? id : parent.id;
            return `/${plural(dataEntity)}/metadata/roles`;
          },
          params: props => {
            const {values: {app_resource_id} = {}} = props;
            const {type = '', id = ''} = app_resource_id || {};
            if (type === 'DataView') {
              return {
                view: id,
              };
            }
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditRolePermissionFormDataEntity = props => {
  return (
    <RolePermissionFormDataEntity
      header={{title: 'Edit Role Permission'}}
      mode="edit"
      {...props}
    />
  );
};
