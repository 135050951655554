import React from 'react';

import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ChangeApiForm = props => {
  const {
    route: {params},
  } = props;

  const {row, api, project, title, secondaryTitle, readOnly} = params;

  const {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: ['Feature', 'View', 'DataService', 'Controller', 'Model'],
    ...props,
  });

  return (
    <Form
      defaultValues={{_id: row?._id, controller_ids: row?.controller_ids}}
      readOnly={readOnly}
      header={{
        title: `${
          title
            ? title
            : row?.model_id?.model
            ? `Change API (${row?.model_id?.model})`
            : 'Change API'
        }`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'API',
          field: 'controller_ids',
          type: 'multiAutoComplete',
          api: `/projectcontrollers`,
          suggestionField: 'controller',
          valueField: 'controller',
          params: ({values}) => {
            const {controller_ids} = values;
            const controllerIdList = controller_ids?.map(item => item._id);
            return {_id: {$nin: controllerIdList}};
          },
          filter: ({params}) => {
            return {
              project_id: project?._id,
              ...params,
            };
          },
          required: true,
        },
      ]}
      {...props}
    />
  );
};
