import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssetAggrementForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/assetinventories`,
    eventSourceId: 'AssetAssign',
  });
  return (
    <Form
      mode="edit"
      api={`/assetinventories/${row?._id}`}
      header={{
        title: 'Asset Aggerement',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            asset_aggrement_signed: true,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          placeholder: 'Aggrement doc.',
          field: 'asset_aggrement_document',
          type: 'file',
          required: true,
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};
