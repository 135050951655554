import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { UserAvatarWithNameCodeJoiningDate } from '../../reimbursement/components/ReimbursementColumnRenders';
import { Table } from '../../../components/table/Table';
import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';

const referralCommonFilters = [
    {
        type: 'multiAutoComplete',
        label: 'Referred To',
        api: '/employeeSuggestions',
        placeholder: 'Select',
        suggestionField: 'name',
        secondarySuggestionField: 'official_email_id',
        valueField: 'name',
        field: 'referred_to',
        searchFields: ['name', 'official_email_id', 'employee_code'],

    },
    {
        type: 'multiAutoComplete',
        label: 'Referred By',
        api: '/employeeSuggestions',
        placeholder: 'Select',
        suggestionField: 'name',
        secondarySuggestionField: 'official_email_id',
        valueField: 'name',
        field: 'employee',
        searchFields: ['name', 'official_email_id', 'employee_code'],

    },
]

const ReferralBenifitTab = props => {
    console.log("referralbenifit>>>>>>>>>>>>>>>>", JSON.stringify(props))
    let { user = {}, route: { params = {} } = {}, tab, forUH, ...rest } = props || {};
    const { applyFilter, filterValues, searchValue, onChangeFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    let tabs = {};
    tabs['Open'] = {
        label: 'Open',
        eventSourceId: 'ReferralBenifit',
        view: <ReferralBenifitTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: 'Pending'
            }}
            api='/referralbenifit'
            user={user}
            {...rest}
            tab="Open"
        />,
        addOnFilter: {
            ...filter,
            status: 'Pending'
        },
        api: '/referralbenifit',
    }

    tabs['Approved'] = {
        label: 'Approved',
        eventSourceId: 'ReferralBenifit',
        view: <ReferralBenifitTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: 'Approved'
            }}
            api='/referralbenifit'
            user={user}
            {...rest}
            tab="Approved"
        />,
        addOnFilter: {
            ...filter,
            status: 'Approved'
        },
        api: '/referralbenifit',
    }

    tabs['Processed'] = {
        label: 'Processed',
        eventSourceId: 'ReferralBenifit',
        view: <ReferralBenifitTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: 'Processed'
            }}
            api='/referralbenifit'
            user={user}
            {...rest}
            tab="Processed"
        />,
        addOnFilter: {
            ...filter,
            status: 'Processed'
        },
        api: '/referralbenifit',
    }

    tabs['Rejected'] = {
        label: 'Rejected',
        eventSourceId: 'ReferralBenifit',
        view: <ReferralBenifitTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
                status: 'Rejected'
            }}
            api='/referralbenifit'
            user={user}
            {...rest}
            tab="Rejected"
        />,
        addOnFilter: {
            ...filter,
            status: 'Rejected'
        },
        api: '/referralbenifit',
    }

    return <TabView
        tabs={tabs}
        // params={params}
        {...props}
        actions={[
            <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                    ...referralCommonFilters
                ]}
            />,
        ]}
    />;
};

export const ReferralBenifitTable = props => {
    let { navigation, route: { params } = {}, filter, addOnFilter, tab, api } = props;
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'ReferralBenifit',
    });
    const { post, download } = useAppStateContext();
    const fetchProps = {
        params,
        addOnFilter: { ...filter, ...addOnFilter },
        api: '/referralbenifit',
    };
    return (
        <Table
            eventSourceId={['ReferralBenifit']}
            api='/referralbenifit'
            addOnFilter={{ ...filter, ...addOnFilter }}
            selection={{
                actions: [
                    {
                        title: 'Download',
                        onPress: props =>
                            download({
                                uri: '/exportExcelHelper',
                                props: {
                                    props: {
                                        // ...props,
                                        selectedIds: props.selectedIds,
                                        fetchProps,
                                        column: {
                                            'Referred By': 'employee.name',
                                            'Referred By Code': 'employee.employee_code',
                                            'Referred To': 'referred_to.name',
                                            'Referred To Code': 'referred_to.employee_code',
                                            Amount: 'amount',
                                            'Payout Date': 'payout_date',
                                        },
                                    },
                                },
                            }),
                    },
                    {
                        title: 'Approve',
                        onPress: ({ selectedIds }) => {
                            invoke({
                                uri: `/onapproverefferalbenifit`,
                                props: {
                                    selectedIds,
                                },
                            });
                        },
                        visible: tab && tab === "Open"
                    },
                    {
                        title: 'Reject',
                        onPress: ({ selectedIds }) => {
                            invoke({
                                uri: `/onrejectrefferalbenifit`,
                                props: {
                                    selectedIds,
                                },
                            });
                        },
                        visible: tab && tab === "Open"
                    },
                    {
                        title: 'Add To Component',
                        onPress: ({ selectedIds }) => {
                            navigation.navigate(`add-to-component`, {
                                ...params,
                                selectedIds,
                                fetchProps,
                                api: '/addreferralbenefitcomponentinsalary',
                            });
                        },
                        visible : tab && tab === "Approved"
                    },
                ],
            }}
            columns={[
                {
                    header: 'Referred By',
                    render: ({ row }) => {
                        return (
                            <UserAvatarWithNameCodeJoiningDate
                                name={row?.employee?.name}
                                color={row?.employee?.color}
                                employee_code={row?.employee?.employee_code}
                                joining_date={row?.employee?.joining_date}
                                primarySubTitle={row?.employee?.experience}
                            />
                        );
                    },
                    minWidth: 260,
                },
                {
                    header: 'Referred To',
                    render: ({ row }) => {
                        return (
                            <UserAvatarWithNameCodeJoiningDate
                                name={row?.referred_to?.name}
                                color={row?.referred_to?.color}
                                employee_code={row?.referred_to?.employee_code}
                                joining_date={row?.referred_to?.joining_date}
                                primarySubTitle={row?.referred_to?.experience}
                            />
                        );
                    },
                    minWidth: 260,
                },
                {
                    header: 'Amount',
                    type: 'text',
                    field: 'amount',
                    width: 200,
                },
                {
                    header: 'Payout Date',
                    type: 'date',
                    field: 'payout_date',
                    width: 200,
                    formatOptions: { format: 'DD MMM YY' },
                },
            ]}
        />
    );
};


export default ReferralBenifitTab;
