import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const WorkloadAssignShadowForm = props => {
  const {
    route: {params},
  } = props;
  let {row = {}, type} = params;
  let {
    project = [],
    employeeLastAssignProject = {},
    assigned_to = {},
    period = {},
  } = row || {};

  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'workload-billable',
  });

  const {fetch} = useAppStateContext();

  let defaultValues = {
    employee: {
      _id: assigned_to?._id,
      name: assigned_to?.name,
    },
    is_shadow: true,
  };

  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Assign Shadow"
      defaultValues={defaultValues}
      beforeSubmit={({data}) => {
        let {project_resource, ...rest} = data || {};
        return {data: rest};
      }}
      layoutFields={[
        {
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          required: true,
          size: 6,
        },
        {
          label: 'Project Resource',
          field: 'project_resource',
          type: 'autoComplete',
          api: '/employeeSuggestionProjectWise',
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => {
            let {project_id} = values;
            return {project_id};
          },
          size: 6,
          onChangeValue: async (value, _, {values, setFieldValue}) => {
            let {project_id} = values || {};
            const {data: employeeAssignmentData = {}} = await fetch({
              uri: '/employeeassignments',
              props: {
                filter: {
                  project_id: project_id?._id,
                  employee: value?._id,
                },
                fields: {
                  employee: {name: 1},
                  hours: 1,
                  milestone_id: {_id: 1, start_date: 1, end_date: 1},
                  skill_requirement: {_id: 1, from_date: 1, to_date: 1},
                  project_skill: {_id: 1, name: 1},
                  resource_skill: {_id: 1, name: 1},
                  level_one_owner: {_id: 1, name: 1},
                  level_two_owner: {_id: 1, name: 1},
                  level_three_owner: {_id: 1, name: 1},
                  is_applicable_for_ma: 1,
                },
                sort: {to_date: -1},
                only: true,
              },
            });
            let from_date;
            let to_date;
            setFieldValue('front_facing_resource', value);
            if (employeeAssignmentData?.skill_requirement) {
              setFieldValue(
                'skill_requirement',
                employeeAssignmentData?.skill_requirement?._id,
              );
              from_date = employeeAssignmentData?.skill_requirement?.from_date;
              to_date = employeeAssignmentData?.skill_requirement?.to_date;
            } else if (employeeAssignmentData?.milestone_id) {
              setFieldValue(
                'milestone_id',
                employeeAssignmentData?.milestone_id?._id,
              );
              from_date = employeeAssignmentData?.milestone_id?.start_date;
              to_date = employeeAssignmentData?.milestone_id?.end_date;
            }

            setFieldValue('from_date', from_date);
            setFieldValue('to_date', to_date);

            if (!to_date && employeeAssignmentData?.to_date) {
              setFieldValue('to_date', employeeAssignmentData?.to_date);
            }

            if (!from_date && employeeAssignmentData?.from_date) {
              setFieldValue('to_date', employeeAssignmentData?.from_date);
            }
            setFieldValue('tool', employeeAssignmentData?.tool);
            setFieldValue(
              'resource_tool',
              employeeAssignmentData?.resource_tool,
            );
            setFieldValue(
              'is_applicable_for_ma',
              employeeAssignmentData?.is_applicable_for_ma,
            );
            setFieldValue('hours', employeeAssignmentData?.hours);
          },
        },
        {
          label: 'Shadow',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Front Facing Resource',
          field: 'front_facing_resource',
          type: 'autoComplete',
          api: '/employeeSuggestionProjectWise',
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => {
            let {project_id} = values;
            return {project_id};
          },
          size: 6,
        },
        {
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          size: 4,
        },
        {
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          size: 4,
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          required: true,
          size: 4,
        },
        {
          label: 'Project Skill',
          field: 'tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 4,
        },
        {
          label: 'Resource Skill',
          field: 'resource_tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 4,
        },
        {
          type: 'switch',
          label: 'Shift allowance',
          field: 'applicable_for_daily_incentive',
          size: 6,
        },
        {
          type: 'number',
          label: 'Amount',
          field: 'daily_incentive',
          visible: ({values = {}}) => values?.applicable_for_daily_incentive,
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export default WorkloadAssignShadowForm;
