import {isAccessible} from './common/constants/SourceConstants';

export const appModuleMapping = {
  crm: {
    icon: 'CustomerModule',
    desc: 'Handle customer support tickets and service requests',
    filters: ['organization', 'product'],
  },
  user: {
    icon: 'UserModule',
    desc: 'Manage user credentials, roles, and access permissions',
  },
  organization: {
    icon: 'OrganizationModule',
    desc: 'Encompass all the functions of the organization',
  },
  attendancemanagement: {
    icon: 'AttendanceModule',
    desc: 'Monitor and record employee attendance and leaves',
  },
  recruitment: {
    icon: 'RecruitmentModule',
    desc: 'Hiring process management, job postings, candidate tracking',
  },
  hrm: {
    icon: 'EmployeeModule',
    desc: 'Employee allocations, operations, exit management',
  },
  finance: {
    icon: 'FinanceModule',
    desc: 'Oversee financial health, budgeting, and reporting',
    filters: ['organizations', 'products', 'period'],
  },
  skill: {
    icon: 'SkillModule',
    desc: 'Skills & up-skilling courses with study resources',
  },
  asset: {
    icon: 'AssetModule',
    desc: 'Track and manage physical and digital assets inventory',
  },
  payrollmanagement: {
    icon: 'PayrollModule',
    desc: 'Employee compensation, benefits, and salary processing',
  },
  revenuemanagement: {
    icon: 'RevenueModule',
    desc: 'Monitor and manage income, sales, and revenue streams',
  },
  expensemanagement: {
    icon: 'ExpenseModule',
    desc: 'Track and manage organizational expenses and costs',
  },
  task: {
    icon: 'TaskModule',
    desc: 'Assign, track, and manage tasks within the organization',
  },
  pmt: {
    icon: 'ProjectModule',
    desc: 'Plan, execute, and monitor projects and deliverables',
  },
  helpmanagement: {
    icon: 'HelpModule',
    desc: 'Encompass all the functions for the help management',
  },
};

export const AppModules = [
  {
    id: 'task',
    // name: 'Task Management',
    // icon: 'TaskModule',
    // desc: 'Assign, track, and manage tasks within the organization',
    // visible: isAccessible,
  },
  {
    id: 'pmt',
    // name: 'Project Management',
    // icon: 'ProjectModule',
    // desc: 'Plan, execute, and monitor projects and deliverables',
    // visible: isAccessible,
  },
  // {
  //   id: 'chat',
  //   name: 'Chat',
  //   icon: 'ChatModule',
  //   desc: 'Facilitate internal and external communication and collaboration',
  // },
  {
    id: 'ticket',
    name: 'Tickets',
    icon: 'TicketModule',
    desc: 'Handle customer support tickets and service requests',
    visible: isAccessible,
  },
  {
    id: 'crm',
  },
  {
    id: 'hrm',
    // name: 'Employee Management',
    // icon: 'EmployeeModule',
    // desc: 'Employee allocations, operations, exit management',
    // visible: isAccessible,
  },
  {
    id: 'attendancemanagement',
    // name: 'Attendance Management',
    // icon: 'AttendanceModule',
    // desc: 'Monitor and record employee attendance and leaves',
    // visible: isAccessible,
  },
  {
    id: 'payrollmanagement',
    // name: 'Payroll Management',
    // icon: 'PayrollModule',
    // desc: 'Employee compensation, benefits, and salary processing',
    // visible: isAccessible,
  },
  {
    id: 'recruitment',
    // name: 'Recruitment',
    // icon: 'RecruitmentModule',
    // desc: 'Hiring process management, job postings, candidate tracking',
    // visible: isAccessible,
  },
  {
    id: 'skill',
    // name: 'Skill Management',
    // icon: 'SkillModule',
    // desc: 'Skills & up-skilling courses with study resources',
    // visible: isAccessible,
  },
  {
    id: 'expensemanagement',
    // name: 'Expense Management',
    // icon: 'ExpenseModule',
    // desc: 'Track and manage organizational expenses and costs',
    // visible: isAccessible,
  },
  {
    id: 'revenuemanagement',
    // name: 'Revenue Management',
    // icon: 'RevenueModule',
    // desc: 'Monitor and manage income, sales, and revenue streams',
    // visible: isAccessible,
  },
  {
    id: 'finance',
    // name: 'Finance Management',
    // icon: 'FinanceModule',
    // desc: 'Oversee financial health, budgeting, and reporting',
    // // filters: ['organization', 'product'],
    // visible: isAccessible,
  },
  {
    id: 'asset',
    // name: 'Asset Management',
    // icon: 'AssetModule',
    // desc: 'Track and manage physical and digital assets inventory',
    // visible: isAccessible,
  },
  {
    id: 'user',
    // name: 'User Management',
    // icon: 'UserModule',
    // desc: 'Manage user credentials, roles, and access permissions',
    // visible: ({user}) => {
    //   return (
    //     (user?.externalUser && user?.org_admin) ||
    //     user?.email === 'sushil.nagvan@daffodilsw.com' ||
    //     user?.email === 'amit.singh@daffodildb.com' ||
    //     user?.email === 'amit@daffodilsw.com' ||
    //     user?.email === 'rohit.bansal@daffodilsw.com' ||
    //     user?.email === 'aswathy.krishnankutty@unthinkable.co' ||
    //     user?.email === 'faiz.saeed@daffodilsw.com' ||
    //     user?.email === 'akshat.garg@unthinkable.co'
    //   );
    // },
  },
  {
    id: 'organization',
    // name: 'Organization',
    // icon: 'OrganizationModule',
    // desc: 'Encompass all the functions of the organization',
    // visible: isAccessible,
  },
  {id: 'helpmanagement'},
];
