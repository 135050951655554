import {Col, Image, Row, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {CommentCellStyles} from './theme';
import {Badge} from '../../components/badge/Badge';
import moment from 'moment';
import {generatePeriodLabel, generateViewId} from '../../utils/util';

export const CommentRender = props => {
  let {
    row,
    countField,
    count,
    lastCommentField = 'last_comment',
    isAbsolute,
  } = props;
  const styles = useStyles(CommentCellStyles);
  if (row?.[countField]) {
    count = row?.[countField];
  }
  let last_comment = lastCommentField && row?.[lastCommentField];

  let chatComponent = isAbsolute ? (
    <Badge top={0} right={-6} value={count}>
      <View style={styles.chatIconContainer}>
        <Image source={styles.ChatIcon} style={styles.icon} />
      </View>
    </Badge>
  ) : (
    <Row gap={4} style={{alignItems: 'center'}}>
      <Image source={styles.ChatIcon} style={styles.icon} />
      <Text style={styles.commentCountText}>{count > 99 ? '+99' : count}</Text>
    </Row>
  );

  return (
    <Col gap={3} style={styles.container}>
      {chatComponent}
      {last_comment?.createdAt ? (
        <Text style={styles.lastCommentDate}>
          {moment(last_comment.createdAt).format('DD MMM')}
        </Text>
      ) : (
        void 0
      )}
    </Col>
  );
};

export const onCommentPress =
  ({navigation, params, tabName, view}) =>
  props => {
    let commentParams =
      typeof params === 'function'
        ? params(props)
        : {
            ...params,
          };

    if (view) {
      const period = view?.view_metadata?.params?.period;
      const view_id = generateViewId({view_id: view?.view, tabName, period});
      const periodLabel = generatePeriodLabel({period});

      commentParams.view = {
        ...view,
        view_id,
        view_source: 'comments',
        view_metadata: {
          ...view.view_metadata,
          periodLabel,
        },
      };
    }
    if (navigation) {
      navigation.navigate('comment-table', commentParams);
    }
  };

export const CommentColumn = props => {
  const {
    width = 60,
    countField = 'comments_count',
    lastCommentField = 'last_comment',
    onPress,
    ...restProps
  } = props;
  return {
    width,
    render: CommentRender,
    onPress: onPress || onCommentPress(restProps),
    countField,
    lastCommentField,
  };
};

export const CommentMoreAction = props => {
  const {title = 'Comments', onPress, ...restProps} = props;
  return {
    title,
    onPress: onPress || onCommentPress(restProps),
  };
};
