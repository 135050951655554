import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ProposedDeploymentDateForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });

  return (
    <Form
      api={`/employees/${params.row._id}`}
      mode="edit"
      fields={{
        proposed_deployment_date: 1,
        proposed_deployment_comment: 1,
        project: {project: 1},
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            is_proposed_deployment: true,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Plan For Deployment"
      layoutFields={[
        {
          type: 'date',
          field: 'proposed_deployment_date',
          placeholder: 'Proposed Date',
          required: true,
        },
        {
          type: 'text',
          field: 'proposed_deployment_comment',
          placeholder: 'Comment',
          required: true,
        },
        {
          type: 'autoComplete',
          field: 'proposed_deployment_type',
          placeholder: 'Type',
          options: ['New', 'Existing'],
          required: true,
        },
        {
          placeholder: 'Potential Customer',
          label: 'Potential Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: `/potentialcustomers`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          visible: ({values}) => {
            return values && values.proposed_deployment_type === 'New';
          },
          size: 6,
        },
        {
          placeholder: 'Project',
          label: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          required: true,
          visible: ({values}) => {
            return values && values.proposed_deployment_type === 'Existing';
          },
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const ProposedCompleteDateForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeProposedCompleteDate',
    ...props,
  });

  return (
    <Form
      api={`/employees/${params.row._id}`}
      mode="edit"
      fields={{
        proposed_deployment_date: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Proposed complete Date"
      layoutFields={[
        {
          type: 'date',
          field: 'proposed_deployment_date',
          placeholder: 'Proposed complete date',
        },
      ]}
      {...props}
    />
  );
};
