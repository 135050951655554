import {useCallback, useState} from 'react';
import {useForm} from './useForm';

export const useStep = ({steps}) => {
  const [selectedStepIndex, setSelectedStepIndex] = useState('0');

  const nextStep = useCallback(
    () => setSelectedStepIndex((Number(selectedStepIndex) + 1).toString()),
    [selectedStepIndex, setSelectedStepIndex],
  );
  const backStep = useCallback(
    () => setSelectedStepIndex((Number(selectedStepIndex) - 1).toString()),
    [selectedStepIndex, setSelectedStepIndex],
  );

  return {
    steps,
    nextStep,
    backStep,
    selectedStepIndex,
    setSelectedStepIndex,
    selectedStep: steps[selectedStepIndex],
    isFirstStep: !Number(selectedStepIndex),
    isLastStep: Number(selectedStepIndex) === steps.length - 1,
  };
};

export const useStepForm = ({steps, ...props}) => {
  const formContext = useForm(props);
  const stepsContext = useStep({steps});
  return {
    ...formContext,
    ...stepsContext,
  };
};
