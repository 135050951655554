import {Form} from '../../../components/form/Form';

export const ImproveSuggestionForm = props => {
  const {route: {params: {onSubmit, options} = {}} = {}} = props;
  return (
    <Form
      onSubmit={onSubmit}
      header={{
        title: 'Let me know, what have I missed?',
      }}
      submitAction={'Submit'}
      onSubmitSuccess={void 0}
      layoutFields={[
        {
          visible: options?.length > 0,
          type: 'autoComplete',
          field: 'improvement_props',
          label: 'Select the area of improvement',
          options,
          required: true,
          valueField: 'label',
          keyField: 'value',
          suggestionField: 'label',
        },
        {
          field: 'improvement_text',
          type: 'textArea',
          placeholder: 'Give inputs here...',
          inputProps: {
            minRows: 8,
          },
          required: true,
        },
      ]}
    />
  );
};
