import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useStyles} from '@unthinkable/react-theme';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {CustomerDashboardOverview} from '../../leadGeneration/components/CustomerDashboardTheme';
import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import {Tag} from '../../../components/chip/Chip';
import {
  CustomerTableTabView,
  CustomerTableTabViewAm,
} from './CustomerTableTabView';
import {useFetchData} from '../../../controllers/useFetchData';

export const CustomerInsightCardComponent = ({value}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {cardStyle, viewContainer, topSourceStyle, customerConvertedStyle} =
    theme;
  return (
    <View style={cardStyle}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Tag value={value?.label} color={value?.color} style={{height: 24}} />
          <Text style={topSourceStyle}>Top Source</Text>
          <Text
            style={
              customerConvertedStyle
            }>{`${value?.length} customers converted`}</Text>
        </Col>
      </View>
    </View>
  );
};
export const CustomerCamapignCardComponent = ({value}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {cardStyle, viewContainer, topSourceStyle, customerConvertedStyle} =
    theme;
  return (
    <View style={cardStyle}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Tag value={value?.label} color={value?.color} style={{height: 24}} />
          <Text style={topSourceStyle}>Top Campaign</Text>
          <Text
            style={
              customerConvertedStyle
            }>{`${value?.length} customers converted`}</Text>
        </Col>
      </View>
    </View>
  );
};
export const CustomerCountryCardComponent = ({value}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {cardStyle, viewContainer, topSourceStyle, customerConvertedStyle} =
    theme;
  return (
    <View style={cardStyle}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Tag value={value?.label} color={value?.color} style={{height: 24}} />
          <Text style={topSourceStyle}>Top Country</Text>
          <Text
            style={
              customerConvertedStyle
            }>{`${value?.length} customers converted`}</Text>
        </Col>
      </View>
    </View>
  );
};
export const CustomerIndustryCardComponent = ({value}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {cardStyle, viewContainer, topSourceStyle, customerConvertedStyle} =
    theme;
  return (
    <View style={cardStyle}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Tag value={value?.label} color={value?.color} style={{height: 24}} />
          <Text style={topSourceStyle}>Top Industry</Text>
          <Text
            style={
              customerConvertedStyle
            }>{`${value?.length} customers converted`}</Text>
        </Col>
      </View>
    </View>
  );
};
export const ActiveProjectsCard = ({value}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {
    containerStyle,
    cardStyle,
    viewContainer,
    topSourceStyle,
    customerConvertedStyle,
    activeProjectCountStyle,
  } = theme;
  return (
    <View style={cardStyle}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Text style={activeProjectCountStyle}>
            {value?.project?.length || 0}
          </Text>
          <Text style={topSourceStyle}>Active Projects</Text>
          <Text style={customerConvertedStyle}>{`${
            value?.tm?.length || 0
          } TM | ${value?.fixed?.length || 0} Fixed`}</Text>
        </Col>
      </View>
    </View>
  );
};
export const CustomerOverviewDashboard = props => {
  let {route: {params} = {}} = props;
  const theme = useStyles(CustomerDashboardOverview);
  const {containerStyle, viewContainer} = theme;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });

  const {data: countryData = {}} = useFetchData({
    api: `/toSourceCustomerDashboard`,
    only: true,
    params: {
      filter: filterValues?.params,
      group: '$country',
    },
  });

  const {data: channelData = {}} = useFetchData({
    api: `/toSourceCustomerDashboard`,
    only: true,
    params: {
      filter: filterValues?.params,
      group: '$campaign_type',
    },
  });

  const {data: campaignData = {}} = useFetchData({
    api: `/toSourceCustomerDashboard`,
    only: true,
    params: {
      filter: filterValues?.params,
      group: '$campaign_id',
    },
  });

  const {data: industryData = {}} = useFetchData({
    api: `/toSourceCustomerDashboard`,
    only: true,
    params: {
      filter: filterValues?.params,
      group: '$industry',
    },
  });

  const {data: projectData = []} = useFetchData({
    api: `/projects`,
    filter: {
      project_status: 'a',
      projecttype: {$in: ['f', 'tm']},
    },
    limit: 10000,
  });

  const {data: fixedProjectData = []} = useFetchData({
    api: `/projects`,
    filter: {
      projecttype: 'f',
      project_status: 'a',
    },
    limit: 10000,
  });

  const {data: tmProjectData = []} = useFetchData({
    api: `/projects`,
    filter: {
      projecttype: 'tm',
      project_status: 'a',
    },
    limit: 10000,
  });
  const projctOrderObject = {
    project: projectData,
    fixed: fixedProjectData,
    tm: tmProjectData,
  };

  // return (
  //   <Col gap={16} style={{flex: 1, overflow: 'hidden'}}>
  //     <View style={containerStyle}>
  //       <View style={viewContainer}>
  //         <TableHeader
  //           title={'Customer Insights (By Period)'}
  //           actions={[
  //             <PeriodRangeFilter
  //               onChangeFilter={onChangeFilter}
  //               filterValues={filterValues}
  //             />,
  //           ]}
  //         />
  //         <Row gap={12}>
  //           <CustomerInsightCardComponent value={channelData} />
  //           <CustomerCamapignCardComponent value={campaignData} />
  //           <CustomerCountryCardComponent value={countryData} />
  //           <CustomerIndustryCardComponent value={industryData} />
  //           <ActiveProjectsCard value={projctOrderObject} />
  //         </Row>
  //       </View>
  //     </View>
  //     <View style={{flex: 1, overflow: 'hidden'}}>
  //       <CustomerTableTabView
  //         {...props}
  //         params={{...params, ...filterValues.params}}
  //       />
  //     </View>
  //   </Col>
  // );
  return (
    <CustomerTableTabView
      {...props}
      params={{...params, ...filterValues.params}}
    />
  );
};

export const CustomerOverviewDashboardForAm = props => {
  let {route: {params} = {}} = props;
  const theme = useStyles(CustomerDashboardOverview);
  const {containerStyle, viewContainer} = theme;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  return (
    <CustomerTableTabViewAm
      {...props}
      params={{...params, ...filterValues.params}}
    />
  );
};
