import React from 'react';

import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';
import {Image} from '@unthinkable/react-core-components';
import {useFetchData} from '../../../controllers/useFetchData';
import {getTime} from '../utility';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Labels} from '../constants/Label';
import {Confirm} from '../../../components/confirm/Confirm';

const mappings = ({id}) => ({
  userstory: {
    uri: `/userstories/removeObjective`,
    fetchApi: `/userstories/${id}/features`,
  },
  issue: {
    uri: `/issues/removeObjective`,
    fetchApi: `/issues/${id}/features`,
  },
});

const CloseRender = props => {
  const {Close} = useTheme('icons');
  const {row} = props;

  if (row?.status === 'released') {
    return null;
  }

  return <Image source={Close} style={{height: 20, width: 20}} />;
};

const StatusRender = ({row}) => {
  const {colors} = useTheme();
  let textcolor = colors.INFORMATION_HIGH;
  const items = [];

  let displayStatus = row?.status;

  if (row.status === 'released') {
    textcolor = colors.SUCCESS_HIGH;
    items.push({
      value: 'Dated: ' + getTime(row?.releasedOn),
    });
  } else if (row.status === 'backlog') {
    textcolor = colors.ACCENT4_HIGH;
    if (row.sub_status === 'Architecture') {
      displayStatus = 'architecture';
      textcolor = colors.ACCENT2_HIGH;
    }
  } else if (row.status === 'active') {
    displayStatus = 'development';
  }

  return (
    <PrimaryColumnCard
      primaryTitle={displayStatus}
      primaryTitleColor={textcolor}
      items={items}
    />
  );
};

export const MappedFeaturesTable = props => {
  const {
    route: {params},
  } = props;

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'RemoveMappedFeature',
  });

  const {row, keyField, displayRespectiveFeatures, status, subStatus} = params;

  const fetchApi = mappings({id: row?._id})[keyField]?.fetchApi;

  const {data: featuresList = []} = useFetchData({
    api: fetchApi,
    filter: {_id: row?._id},
    fields: {
      objective_ids: {
        feature: 1,
        status: 1,
        sub_status: 1,
        releasedOn: 1,
        createdAt: 1,
      },
    },
    params: {status, subStatus, type: row?.type},
    only: true,
    eventSourceId: ['RemoveMappedFeature'],
  });

  featuresList?.sort((a, b) => {
    if (a.releasedOn > b.releasedOn) {
      return -1;
    } else if (a.createdAt < b.createdAt) {
      return 1;
    } else {
      return -1;
    }
  });

  const showCross = displayRespectiveFeatures;

  return (
    <Table
      eventSourceId={['RemoveMappedFeature']}
      data={featuresList}
      columns={[
        {
          header: Labels.Feature,
          field: 'feature',
          type: 'text',
        },
        {
          header: 'Status',
          field: 'status',
          render: StatusRender,
          width: 150,
        },
        showCross && {
          align: 'center',
          width: 60,
          render: ({row}) => {
            const id = params.row._id;
            const feature_id = row?._id;
            const uri = mappings({id})[keyField]?.uri;

            const {status} = row;

            if (status === 'released') {
              return;
            }

            return (
              <Confirm
                title="Remove from Userstory"
                message={`Are you sure you want to remove this ${Labels.Feature.toLowerCase()} from this ${
                  keyField === 'issue' ? 'issue' : 'userstory'
                }?`}
                confirmText="Remove"
                variant="error"
                onConfirm={() => {
                  const restProps = {};
                  if (keyField === 'issue') {
                    restProps.issue_id = id;
                  } else {
                    restProps.userstory_id = id;
                  }
                  putInvoke({
                    uri,
                    props: {
                      objective_id: feature_id,
                      ...restProps,
                    },
                  });
                }}>
                <CloseRender />
              </Confirm>
            );
          },
        },
      ]}
    />
  );
};
