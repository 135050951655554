import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const ModuleDescriptionForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Module',
    uri: '/projectmodules',
    ...props,
  });

  return (
    <Form
      api={`/projectmodules/${row?._id}`}
      fields={{
        desc: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          field: 'desc',
          type: 'textArea',
          placeholder: 'Write description here...',
        },
      ]}
      {...props}
    />
  );
};

export const AddModuleDescriptionForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  return (
    <ModuleDescriptionForm
      mode="edit"
      header={{title: 'Module Description', secondaryTitle: `${row?.module}`}}
      {...props}
    />
  );
};

export const UpdateModuleDescriptionForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;

  return (
    <ModuleDescriptionForm
      mode="edit"
      header={{title: 'Module Description', secondaryTitle: `${row?.module}`}}
      isDualMode={true}
      {...props}
    />
  );
};
