import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {Text} from '@unthinkable/react-core-components';
import {renderLocale} from './OrderTable';
import {AmountRender} from './components/AmountRender';
import {useAppStateContext} from '../../../providers/AppState';

const CUSTOMER = {
  header: 'Customer',
  field: 'customer.name',
  minWidth: 240,
};

const ACCOUNT_OWNER = {
  header: 'AM',
  field: 'account_owner_id',
  type: 'userAvatar',
  align: 'center',
  width: 80,
};

const RECEIPTNO = {
  header: 'Receipt No.',
  field: 'receipt_number',
  width: 120,
};

const RECEIPTS_TYPE = {
  header: 'Type',
  field: 'receipt_type',
  width: 180,
  align: 'center',
  render: ({row, styles}) => {
    if (!row?.receipt_type) return null;
    if (row?.receipt_type == 'a') {
      return <Text style={{...styles.rowText}}>Advance</Text>;
    }
    if (row?.receipt_type == 'r') {
      return <Text style={{...styles.rowText}}>Revenue</Text>;
    }
  },
};

const RECEIPTS_DATE = {
  header: 'Receipt Date',
  field: 'receipts_date',
  type: 'date',
  align: 'center',
  formatOptions: {
    format: 'DD MMM YY',
  },
  width: 140,
};

const AMOUNT_INR = {
  header: 'Amount(INR)',
  field: 'base_amount',
  type: 'currency',
  width: 160,
  align: 'center',
};

const AMOUNT_CC = {
  header: 'Amount(INR)',
  field: 'amount',
  type: 'currency',
  width: 160,
  align: 'center',
  render: ({row, styles}) => (
    <AmountRender
      row={row}
      styles={styles}
      amount={row?.amount || 0}
      base_amount={row?.base_amount || 0}
      currency={row?.currency?.currency}
    />
  ),
  formatOptions: ({row}) => {
    return {
      locale: renderLocale(row?.currency?.currency),
      currency: row?.currency?.currency,
    };
  },
  // children: [
  //   {
  //     header: 'Customer currency',
  //   },
  // ],
};

const AMOUNT_RECEIVED = {
  header: 'Amount Received',
  field: 'base_fund_received',
  type: 'currency',
  width: 160,
  align: 'center',
  render: ({row, styles}) => (
    <AmountRender
      row={row}
      styles={styles}
      amount={row?.fund_received || 0}
      base_amount={row?.base_fund_received || 0}
      currency={row?.currency?.currency}
    />
  ),
  formatOptions: ({row}) => {
    return {
      locale: renderLocale(row?.currency?.currency),
      currency: row?.currency?.currency,
    };
  },
};

const UNSETTLED_AMOUNT = {
  header: 'Unsettled Amount',
  field: 'pending_advance_amount',
  type: 'currency',
  width: 160,
  align: 'center',
};

const PAYMENT_MODE = {
  header: 'Payment Mode',
  field: 'payment_mode',
  width: 160,
};

export const RecieptAllTable = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filterValues,
    groupBy = {},
  } = props || {};

  let filterParams = {};
  let addOnFilter = {};
  if (params.invoice) {
    addOnFilter = {
      receipt_details: {
        $elemMatch: {
          invoice_id: params.invoice,
        },
      },
    };
  }
  const {user, download} = useAppStateContext();
  if (params?.customerId) {
    filterParams['customer'] = params?.customerId;
  }
  if (params?.organization) {
    filterParams['organization'] = params?.organization;
  }
  if (params?.product) {
    filterParams['product'] = params?.product;
  }

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['revenue-receipt', 'receipt'],
  });
  const fetchProps = {
    params,
    // search,
    addOnFilter: {...filterValues?.filter, ...addOnFilter},
    searchFields: ['receipt_number'],
    api: '/receipt/all',
  };
  return (
    <Table
      // {...props}
      limit={50}
      variant={'bordered'}
      api={`/receipt/all`}
      params={params}
      addOnFilter={{...filterValues?.filter, ...addOnFilter}}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields={['receipt_number']}
      search={searchValue}
      eventSourceId={['revenue-receipt', 'receipt']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`details-revenue-receipt`, {
          row,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Relation: 'account_owner_id.name',
                      Customer: 'customer.name',
                      Date: 'receipts_date',
                      'Fund Received': 'fundReceived',
                      Bank: 'fund_id.name',
                      'Base Fund Received': 'base_fund_received',
                      'Invoice Amount': 'amount',
                      'Base Invoice Amount': 'base_amount',
                      'Transaction Id': 'transaction_id',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        RECEIPTNO,
        RECEIPTS_TYPE,
        RECEIPTS_DATE,
        // AMOUNT_INR,
        AMOUNT_CC,
        AMOUNT_RECEIVED,
        PAYMENT_MODE,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-revenue-receipt', {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {receipt_id: row._id},
            });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this receipt?',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/receipt/delete-receipt`,
              props: {_id: row._id},
            });
          },
          visible: () => {
            if (
              user.email === 'sushil.nagvan@daffodilsw.com' ||
              user.email === 'amit.singh@daffodildb.com'
            ) {
              return true;
            }
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate(`activity-logs`, {
              _id: row._id,
              populate: [
                {path: 'customer', select: {name: 1}},
                {
                  path: 'account_owner_id',
                  select: {name: 1},
                },
              ],
              api: `/receipts/${row?._id}/activityLogs`,
              displayFields: {
                customer: {
                  label: 'Customer',
                  field: 'customer.name',
                },
                account_owner_id: {
                  label: 'Account Owner Id',
                  field: 'account_owner_id.name',
                },
                receipt_no: {
                  label: 'Receipt Number',
                  field: 'receipt_number',
                },
                receipt_type: {
                  label: 'Receipt Type',
                  field: 'receipt_type',
                },
                receipt_date: {
                  label: 'Receipt Date',
                  field: 'receipts_date',
                },
                amount: {
                  label: 'Amount',
                  field: 'amount',
                },
                Amount_received: {
                  label: 'Amount Received',
                  field: 'base_fund_received',
                },
                payment_mode: {
                  label: 'Payment Mode',
                  field: 'payment_mode',
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const RecieptAllTableAm = props => {
  const params = props.route.params || {};
  let {navigation, searchValue, filterValues, groupBy = {}} = props || {};

  let filterParams = {};
  if (params?.customerId) {
    filterParams['customer'] = params?.customerId;
  }
  if (params?.organization) {
    filterParams['organization'] = params?.organization;
  }
  if (params?.product) {
    filterParams['product'] = params?.product;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['revenue-receipt', 'receipt'],
  });

  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();

  return (
    <Table
      // {...props}
      limit={2000}
      variant={'bordered'}
      api={`/receipt/all`}
      params={params}
      searchFields={['receipt_number']}
      search={searchValue}
      eventSourceId={['revenue-receipt', 'receipt']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`details-revenue-receipt`, {
          row,
        });
      }}
      filter={{
        ...filterValues?.filter,
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        RECEIPTNO,
        RECEIPTS_TYPE,
        RECEIPTS_DATE,
        // AMOUNT_INR,
        AMOUNT_CC,
        AMOUNT_RECEIVED,
        PAYMENT_MODE,
      ]}
      // moreActions={[
      //   {
      //     title: 'Edit',
      //     onPress: ({row}) => {
      //       navigation.navigate('update-revenue-receipt', {
      //         row,
      //       });
      //     },
      //   },
      //   {
      //     title: 'Voucher',
      //     onPress: ({row}) => {
      //       navigation.navigate(`open-voucher`, {
      //         receipt: row._id,
      //       });
      //     },
      //   },
      //   {
      //     title: 'Delete Receipt',
      //     confirm: {
      //       title: 'Delete Receipt',
      //       message: 'Are you sure you want to delete this receipt?',
      //     },
      //     onPress: props => {
      //       const {row} = props;
      //       invoke({
      //         uri: `/receipt/delete-receipt`,
      //         props: {_id: row._id},
      //       });
      //     },
      //   },
      // ]}
    />
  );
};

export const RecieptAdvanceTable = props => {
  let {
    navigation,
    searchValue,
    route: {params},
    filterValues,
    groupBy = {},
  } = props || {};

  let filterParams = {};
  if (params?.customerId) {
    filterParams['customer'] = params?.customerId;
  }
  if (params?.organization) {
    filterParams['organization'] = params?.organization;
  }
  if (params?.product) {
    filterParams['product'] = params?.product;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['revenue-receipt', 'receipt'],
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {...filterValues?.filter},
    searchFields: ['receipt_number'],
    api: '/receipt/advance',
  };

  return (
    <Table
      // {...props}
      limit={50}
      variant={'bordered'}
      api={`/receipt/advance`}
      params={params}
      addOnFilter={{...filterValues.filter}}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields={['receipt_number']}
      search={searchValue}
      eventSourceId={['revenue-receipt', 'receipt']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`details-revenue-receipt`, {
          row,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Relation: 'account_owner_id.name',
                      Customer: 'customer.name',
                      Date: 'receipts_date',
                      'Fund Received': 'fundReceived',
                      Bank: 'fund_id.name',
                      'Base Fund Received': 'base_fund_received',
                      'Invoice Amount': 'amount',
                      'Base Invoice Amount': 'base_amount',
                      'Transaction Id': 'transaction_id',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        RECEIPTNO,
        RECEIPTS_DATE,
        // AMOUNT_INR,
        AMOUNT_CC,
        UNSETTLED_AMOUNT,
        AMOUNT_RECEIVED,
        PAYMENT_MODE,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-revenue-receipt', {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {receipt: row._id},
            });
          },
        },
        // {
        //   title: 'Delete',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to delete this receipt?',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/receipt/delete-receipt`,
        //       props: {_id: row._id},
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const RecieptAdvanceTableAm = props => {
  const params = props.route.params || {};
  let {navigation, searchValue, filterValues, groupBy = {}} = props || {};

  let filterParams = {};
  if (params?.customerId) {
    filterParams['customer'] = params?.customerId;
  }
  if (params?.organization) {
    filterParams['organization'] = params?.organization;
  }
  if (params?.product) {
    filterParams['product'] = params?.product;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['revenue-receipt', 'receipt'],
  });
  const {
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();

  return (
    <Table
      // {...props}
      limit={2000}
      variant={'bordered'}
      api={`/receipt/advance`}
      params={params}
      searchFields={['receipt_number']}
      search={searchValue}
      eventSourceId={['revenue-receipt', 'receipt']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`details-revenue-receipt`, {
          row,
        });
      }}
      filter={{
        ...filterValues?.filter,
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        RECEIPTNO,
        RECEIPTS_DATE,
        // AMOUNT_INR,
        AMOUNT_CC,
        UNSETTLED_AMOUNT,
        AMOUNT_RECEIVED,
        PAYMENT_MODE,
      ]}
      // moreActions={[
      //   {
      //     title: 'Edit',
      //     onPress: ({row}) => {
      //       navigation.navigate('update-revenue-receipt', {
      //         row,
      //       });
      //     },
      //   },
      //   {
      //     title: 'Voucher',
      //     onPress: ({row}) => {
      //       navigation.navigate(`open-voucher`, {
      //         receipt: row._id,
      //       });
      //     },
      //   },
      //   {
      //     title: 'Delete Receipt',
      //     confirm: {
      //       title: 'Delete Receipt',
      //       message: 'Are you sure you want to delete this receipt?',
      //     },
      //     onPress: props => {
      //       const {row} = props;
      //       invoke({
      //         uri: `/receipt/delete-receipt`,
      //         props: {_id: row._id},
      //       });
      //     },
      //   },
      // ]}
    />
  );
};

export const AgeingReportReceipt = props => {
  const {navigation, route} = props || {};
  const {params} = route || {};
  return (
    <Table
      {...props}
      api={`/receipt/all`}
      addOnFilter={params?.filter}
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Receipt No',
          field: 'receipt_number',
          type: 'text',
          width: 200,
        },
        {
          header: 'Advance Amount',
          aggregate: true,
          count_field: 'base_pending_advance_amount',
          field: 'base_pending_advance_amount',
          type: 'currency',
          width: 200,
        },
      ]}
    />
  );
};
