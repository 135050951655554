import React, {
  useMemo,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import io from 'socket.io-client';
import {useAppStateContext} from './AppState';
const SocketContext = React.createContext();

// Make provider to make socket connection which return socket and connect functions. - Rajnish Trikaldarshi 18-07-2024
export const SocketContextProvider = ({children}) => {
  const {
    config: {socketUrl},
    access_token,
  } = useAppStateContext();

  const [socket, setSocket] = useState(null);

  const connect = useCallback(() => {
    if (access_token && socketUrl) {
      try {
        const newSocket = io(socketUrl, {query: {token: access_token}});
        setSocket(newSocket);
        return () => {
          newSocket.disconnect();
          setSocket(null);
        };
      } catch (error) {
        // console.error('Failed to connect to socket:', error);
      }
    }
  }, [access_token, socketUrl]);

  const socketContextValue = useMemo(
    () => ({socket, connect}),
    [socket, connect],
  );

  useEffect(() => {
    connect();
  }, [connect]);

  return (
    <SocketContext.Provider value={socketContextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  return useContext(SocketContext) || {};
};
