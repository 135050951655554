import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const LeaveAllowedForm = props => {
  const {
    route: {params},
  } = props;
  const {leaveAllowed} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/leavealloweds',
    eventSourceId: 'LeaveAllowed',
  });
  return (
    <Form
      api={`/leavealloweds/${leaveAllowed}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          required: true,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          required: true,
        },
        {
          type: 'text',
          field: 'leave_allowed',
          label: 'Leave Allowed',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditLeaveAllowed = props => {
  return (
    <LeaveAllowedForm mode="edit" header="Update Leave Allowed" {...props} />
  );
};
export const AddLeaveAllowed = props => {
  return <LeaveAllowedForm header="Add Leave Allowed" {...props} />;
};
