import { getPreviousMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { CloseOrderTable, FixedOrderTable, TMOrderTable } from './OrderTable';

export const OrderTableTabView = props => {
  const {route: {params} = {}} = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }
  let tabs = {
    all: {
      label: 'T & M',
      view: (
        <TMOrderTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          params={params}
        />
      ),
      api: `/t&m/order`,
      params: {
        period: filterParams.period,
        product,
        ...params,
      },
      actions: [
        <AddButton
          title="Add T&M Order"
          view="add-tm-order"
          params={props?.route?.params}
        />,
      ],
    },
    active: {
      label: 'Fixed',
      api: `/fixed/order`,
      params: {
        period: filterParams.period,
        product,
        ...params,
      },
      view: (
        <FixedOrderTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          params={params}
        />
      ),
      actions: [
        <AddButton
          title="Add Fixed Order"
          view="add-fixed-order"
          params={props?.route?.params}
        />,
      ],
    },
    lost: {
      label: 'Close',
      view: (
        <CloseOrderTable
          filter={filter}
          filterParams={filterParams}
          searchValue={searchValue}
          params={params}
        />
      ),
      api: `/close/order`,
      params: {
        period: filterParams.period,
        product,
        ...params,
      },
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
          ]}
        />,
      ]}
    />
  );
};
