import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const ChannelsTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={'channelstable'}
      api={`/channels`}
      sort={{_id: -1}}
      renderHeader={() => (
        <TableHeader
          title="Channels"
          actions={[
            <AddButton
              title="Add"
              view="add-channel-table"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        name: 1,
        api: 1,
        view: 1,
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
          responsive: 'sm',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-channel-table', {row});
          },
        },
      ]}
      {...props}
    />
  );
};

export default ChannelsTable;
