import {useInput} from '@unthinkable/react-input';
import {useState} from 'react';

export const useMultiTextInput = ({
  ref,
  onClearValue: onClearValueProp,
  onEnter,
  value,
  onChangeValue,
  ...props
}) => {
  if (value && !Array.isArray(value)) {
    value = [value];
  }

  props = useInput({
    ...props,
    ref,
    value,
  });
  const {inputProps} = props;
  const {onFocus: onFocusProp, onBlur: onBlurProp} = inputProps;
  const [searchValue, setSearchValue] = useState();

  const onChangeSearch = searchText => {
    setSearchValue(searchText);
  };

  const handleFocus = e => {
    onFocusProp?.(e);
  };

  const handleBlur = e => {
    onBlurProp?.(e);
  };

  const onFocus = e => {
    handleFocus(e);
  };

  const onBlur = e => {
    handleBlur(e);
  };

  const blurInput = e => {
    props?.inputRef?.current?.blur();
  };

  const onRemove = ({index: _index}) => {
    if (!value) {
      return;
    }
    let newValue = value.filter((_, index) => {
      return _index !== index;
    });
    onChangeValue?.(newValue?.length ? newValue : null);
  };

  const onKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        let newValue = searchValue;
        newValue = value ? [...value] : [];
        newValue.push(searchValue);
        onChangeValue?.(newValue);
        onEnter && onEnter({searchValue});
        setSearchValue(void 0);
        break;
    }
  };

  return {
    ...props,
    inputProps: {
      ...inputProps,
      onFocus,
      onBlur,
      onKeyDown,
    },
    value,
    blurInput,
    onChangeSearch,
    searchValue,
    onRemove,
  };
};
