import React from 'react';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';
import {PotentialCustomerForm} from './PotentialCustomerForm';

export const AddProposalSubmittedForm = props => {
  const {user} = useAppStateContext();
  const {
    employee: {department, product},
  } = user;
  return (
    <PotentialCustomerForm
      {...props}
      add
      header={'Add Proposal Submitted'}
      defaultValues={{
        lead_status: 'Active',
        pipeline_type: 'Prospect',
        created_on: getZeroTimeDate(new Date()),
        sql_stage_date: getZeroTimeDate(new Date()),
        type: 'Organization',
        stage: {_id: '53bbd36a79df44bac9b12f39', name: 'Proposal'},
        product,
        department,
      }}
    />
  );
};

export const EditProposalSubmittedForm = props => {
  const {route: {params} = {}} = props;
  const {leadId} = params;
  return (
    <PotentialCustomerForm
      {...props}
      api={`/potentialCustomers/${leadId}`}
      fields={{
        type: 1,
        email: 1,
        website: 1,
        name: 1,
        country: {name: 1},
        industry: {name: 1},
        owner: {name: 1},
        phone: 1,
        employee_count: 1,
        exact_employee_count: 1,
        campaign_type: 1,
        campaign_id: {name: 1},
        description: 1,
        manual_description: 1,
        product: {name: 1},
        department: {name: 1},
        organization: {name: 1},
      }}
      mode="edit"
      header={'Edit Proposal Submitted'}
    />
  );
};
