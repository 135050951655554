import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';

export const UserAccessTable = props => {
  const {navigation} = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter = {}} = filterValues || {};

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'useAccessRemoved',
  });
  const deleteUserAccess = ({row}) => {
    deleteInvoke({
      uri: `/useraccesses/${row?._id}`,
      props: {},
    });
  };

  return (
    <Table
      eventSourceId={['useAccessAdded', 'useAccessRemoved']}
      addOnFilter={filter}
      renderHeader={() => {
        return (
          <TableHeader
            title="User Access"
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Employee',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'employee',
                    secondarySuggestionField: 'official_email_id',
                    searchFields: [
                      'name',
                      'official_email_id',
                      'employee_code',
                    ],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      api={`/userAccesses`}
      fields={{
        employee: {name: 1},
        user: {name: 1},
        access_groups: {
          access_group: {name: 1},
          resources: {name: 1},
        },
        access_level: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-user-access`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'employee.name',
          header: 'Employee',
        },
        {
          type: 'text',
          field: 'user.name',
          header: 'User',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: deleteUserAccess,
        },
      ]}
    />
  );
};
