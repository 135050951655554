import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

// const options = ['pending'];
const options2 = ['Pending', 'Hold', 'Complete', 'Drop'];

export const UpdateAnchorResourceStatus = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'allocatedResource',
    ...props,
  });
  return (
    <Form
      api={`/employees/${params.row._id}`}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      header="Update Status"
      fields={{
        mark_identify_for_swapping: 1,
        anchor_resource_on_hold_date: 1,
      }}
      layoutFields={[
        {
          label: 'Status',
          field: 'mark_identify_for_swapping',
          type: 'autoComplete',
          options: options2,
          required: true,
        },
        {
          label: 'On Hold Date',
          field: 'anchor_resource_on_hold_date',
          type: 'date',
          required: true,
          visible: ({values}) => values?.mark_identify_for_swapping === 'Hold',
        },
        {
          label: 'Drop Date',
          field: 'anchor_resource_drop_date',
          type: 'date',
          required: true,
          visible: ({values}) => values?.mark_identify_for_swapping === 'Drop',
        },
        {
          label: 'Completed On',
          field: 'anchor_resource_complete_on_date',
          type: 'date',
          required: true,
          visible: ({values}) =>
            values?.mark_identify_for_swapping === 'Complete',
        },
        {
          label: params?.tab === 'Hold' ? 'Reason for Hold' : 'Remarks',
          field: 'actions_description',
          type: 'text',
          required: true,
          visible: ({values}) =>
            values?.mark_identify_for_swapping === 'Complete' ||
            values?.mark_identify_for_swapping === 'Drop' ||
            values?.mark_identify_for_swapping === 'Hold',
        },
      ]}
      {...props}
    />
  );
};
