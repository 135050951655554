import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {TalentRequestTable} from './TalentRequestTable';

const menus = [
  {
    label: 'Talent Request',
    view: <TalentRequestTable />,
  },
];

const TalentManagement = props => {
  const {route: {params} = {}} = props;
  return (
    <LeftMenuView
      {...props}
      params={params}
      menus={menus}
    />
  );
};

export default TalentManagement;
