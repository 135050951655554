import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const ScheduledAction = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/campaignactions',
  });
  const {
    route: {params},
    defaultValues: oldDefaultValues,
  } = props;

  const {row = {}} = params;

  const defaultValues = {...oldDefaultValues};

  return (
    <Form
      onSubmit={onSubmit}
      api={`/campaignactions/${row?._id}`}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Schedule Date',
          placeholder: 'Schedule Date',
          field: 'scheduledOn',
          type: 'date',
        },
      ]}
      {...props}
    />
  );
};

export const ScheduledActionForm = props => {
  return <ScheduledAction mode="edit" header={'Schedule Action'} {...props} />;
};
