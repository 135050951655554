import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const CodeSampleForm = props => {
  return (
    <Form
      header={'Code Sample'}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
          size: 6,
        },
        {
          label: 'Type',
          field: 'type',
          type: 'autoComplete',
          required: true,
          options: [
            'Data Services',
            'Response',
            'Error',
            'Import Statement',
            'Export Statement',
            'Comprehensive Code',
            'Route Definition',
            'Data Fetching Services',
            'State Management',
            'Hooks',
          ],
          size: 6,
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textArea',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Template',
          field: 'template',
          type: 'textArea',
          labelPosition: 'top',
          height: 200,
        },
        {
          label: 'Example',
          field: 'example',
          type: 'textArea',
          labelPosition: 'top',
          height: 200,
        },
      ]}
      {...props}
    />
  );
};

export const RepositoryCodeSampleAddForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, repository_id} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'CodeSample',
    uri: '/repositoryCodeSamples',
    ...props,
  });

  const defaultValues = {
    project_id: project?._id,
    repository_id,
  };

  return (
    <CodeSampleForm
      {...props}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
    />
  );
};

export const RepositoryCodeSampleEditForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {codeSampleId} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'CodeSample',
    uri: '/repositoryCodeSamples',
    ...props,
  });

  return (
    <CodeSampleForm
      {...props}
      api={`/repositoryCodeSamples/${codeSampleId}`}
      fields={{
        project_id: 1,
        repository_id: 1,
        name: 1,
        type: 1,
        description: 1,
        template: 1,
        example: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      mode="edit"
    />
  );
};
