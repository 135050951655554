import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import moment from 'moment';
import React from 'react';
import {Form, MultiStepForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const ProductivitySurveyForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/productivitysurveys',
    eventSourceId: 'productivitySurvey',
    ...props,
  });
  const {route: {params = {}} = {}, onClose} = props;
  const {productivity_survey} = params;
  const {user} = useAppStateContext();
  return (
    <MultiStepForm
      skipNumbering
      header="Productivity Survey"
      api={`/productivitysurveys/${productivity_survey?._id}`}
      defaultValues={{
        user: user?._id,
        employee: user?.employee?._id,
        feedback_date: getZeroTimeDate(
          moment().subtract(1, 'month').endOf('month').toDate(),
        ),
      }}
      closable={new Date().getDate() <= 5}
      onSubmit={onSubmit}
      afterSubmit={onClose}
      onClose={onClose}
      steps={[
        {
          title: 'Step 1',
          fields: [
            {
              label: 'Were you productive as per your full potential?',
              fields: [
                {
                  type: 'radioGroup',
                  options: [
                    {label: 'Yes', value: 'Productive'},
                    {label: 'No', value: 'Not Productive'},
                    {
                      label: 'Sometimes Yes, Sometimes No',
                      value: 'Sometimes Productive',
                    },
                  ],
                  field: 'productive',
                  required: true,
                  styles: {
                    container: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 16,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Step 2',
          fields: [
            {
              visible: ({values}) => values?.productive === 'Productive',
              label:
                'What do you think helped you in reaching your full potential?',
              fields: [
                {
                  type: 'multiCheckbox',
                  options: [
                    'Good Environment',
                    'Good Team',
                    'Good Communication with Manager',
                    'Challenging/Interesting Work',
                    'End-to-End clarity of work',
                    'Others',
                  ],
                  field: 'productive_points',
                  required: true,
                  styles: {
                    container: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 16,
                    },
                  },
                },
                {
                  placeholder: 'Please specify',
                  field: 'other_productive_point',
                  type: 'textArea',
                  required: true,
                  visible: ({values}) =>
                    values?.productive_points?.includes('Others'),
                },
              ],
            },
            {
              label: 'What can help you to be more productive?',
              field: 'productive_suggestion',
              type: 'textArea',
              required: true,
              styles: {
                container: {},
                input: {
                  minHeight: 200,
                },
              },
              visible: ({values}) => values?.productive != 'Productive',
            },
          ],
        },
        {
          title: 'Step 3',
          fields: [
            {
              label:
                'How do you rate your experience of working with us in the previous month?',
              fields: [
                {
                  type: 'radioGroup',
                  field: 'rating',
                  options: ['Awesome', 'Just Okay', 'Unsatisfied'],
                  required: true,
                  styles: {
                    container: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 16,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Step 4',
          fields: [
            {
              label:
                'We are glad that you loved the experience. Would you love to share what you like the most?',
              type: 'textArea',
              placeholder: 'Please give your feedback (optional)',
              field: 'rating_feedback',
              styles: {
                container: {},
                input: {
                  minHeight: 200,
                },
              },
            },
          ],
        },
      ]}
    />
  );
};

export const ManagersRemarkOnProductivity = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/productivitysurveys',
    eventSourceId: 'productivitySurvey',
    ...props,
  });
  const {route: {params = {}} = {}} = props;
  const {productivity_survey, editable} = params;
  return (
    <Form
      header={{
        title: 'Add Remarks',
        secondaryTitle: productivity_survey?.employee?.name,
      }}
      mode="edit"
      api={`/productivitysurveys/${productivity_survey?._id}`}
      readOnly={!editable}
      onSubmit={onSubmit}
      fields={{
        manager_remarks: 1,
      }}
      layoutFields={[
        {
          type: 'textArea',
          field: 'manager_remarks',
          label: 'Remarks',
          placeholder: 'Please give your remark',
          required: true,
        },
      ]}
    />
  );
};

export const MarkProductivitySurveyClosed = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/productivitysurveys',
    eventSourceId: 'productivitySurvey',
    ...props,
  });
  const {route: {params = {}} = {}} = props;
  const {productivity_survey} = params;
  return (
    <Form
      header={{
        title: 'Add Remarks',
        secondaryTitle: productivity_survey?.employee?.name,
      }}
      mode="edit"
      api={`/productivitysurveys/${productivity_survey?._id}`}
      onSubmit={onSubmit}
      fields={{
        action_taken: 1,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {...data, status: 'OK'},
        };
      }}
      layoutFields={[
        {
          type: 'textArea',
          field: 'action_taken',
          label: 'Action Taken',
          placeholder: 'Action Taken',
          required: true,
        },
      ]}
    />
  );
};
