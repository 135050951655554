import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

import {InteractiveCommentBox} from './InteractiveCommentBox';
import {CardTable} from '../table/Table';
import {CommentRow, useComment} from './CommentRow';

const CommentHeader = props => {
  const {data} = props;
  const commentProps = useComment({...props, maxHeight: 250});
  const colors = useTheme('colors');
  return (
    <>
      <InteractiveCommentBox
        actionContainerStyle={{paddingTop: 8, paddingBottom: 8}}
        {...commentProps}
      />
      {data?.length ? (
        <View
          style={{
            height: 1,
            backgroundColor: colors.OUTLINE,
          }}
        />
      ) : (
        void 0
      )}
    </>
  );
};

export const CommentList = props => {
  const {comment_source, comment_source_id, eventSourceId = [], view} = props;

  if (!comment_source || !comment_source_id) {
    return null;
  }
  if (!Array.isArray(eventSourceId)) {
    eventSourceId = [eventSourceId];
  }
  return (
    <CardTable
      autoHeight
      eventSourceId={['Comment', ...eventSourceId]}
      api={`/projectcomments/all`}
      skipNoData={true}
      params={{
        source_id: comment_source_id._id,
        view_id: view?.view_id,
      }}
      renderHeader={({data}) => <CommentHeader data={data} {...props} />}
      renderRow={({row, index, level}) => (
        <CommentRow row={row} index={index} level={level} {...props} />
      )}
    />
  );
};
