import React, {useState, useEffect, useCallback} from 'react';
import {
  Image,
  Row,
  TouchableOpacity,
  TextInput,
  View,
} from '@unthinkable/react-core-components';
import _ from 'lodash';

export const SearchFilter = ({
  value,
  onChangeValue,
  placeholder = 'Search',
  width,
  styles,
  debounceTime = 300,
}) => {
  let {
    SearchIcon,
    CrossIcon,
    containerStyle,
    selectedContainerStyle,
    inputContainerStyle,
    inputStyle,
    iconStyle,
  } = styles;

  const [inputValue, setInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  // Create a stable debounced function using useCallback
  const debouncedOnChangeValue = useCallback(
    _.debounce(onChangeValue, debounceTime),
    [onChangeValue, debounceTime],
  );

  // Ensure the debounced function is cancelled when the component is unmounted
  useEffect(() => {
    return () => {
      debouncedOnChangeValue.cancel();
    };
  }, [debouncedOnChangeValue]);

  const handleInputChange = text => {
    setInputValue(text);
    debouncedOnChangeValue(text);
  };

  const handleClearValue = () => {
    setInputValue('');
    onChangeValue('');
    debouncedOnChangeValue.cancel();
  };

  if (isFocused || inputValue) {
    containerStyle = {...containerStyle, ...selectedContainerStyle};
  }

  return (
    <Row style={{...containerStyle, width}}>
      <View style={inputContainerStyle}>
        <TextInput
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholder={placeholder}
          onChangeValue={handleInputChange}
          value={inputValue}
          style={inputStyle}
        />
      </View>
      <TouchableOpacity
        style={{cursor: inputValue ? 'pointer' : 'auto'}}
        onPress={() => {
          if (inputValue) {
            handleClearValue();
          }
        }}>
        <Image style={iconStyle} source={inputValue ? CrossIcon : SearchIcon} />
      </TouchableOpacity>
    </Row>
  );
};
