import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

const ResourcesTable = props => {
  const {header, filter, period, moreActions} = props;
  return (
    <Table
      api={`/organizations-resource`}
      renderHeader={() => <TableHeader title={header} />}
      addOnFilter={filter}
      params={{
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      moreActions={moreActions}
      columns={[
        {
          field: 'name',
          header: 'Resources',
        },
        {
          field: 'department.name',
          header: 'Department',
        },
        {
          field: 'allocated_objectproduct.name',
          header: 'Object Type',
        },
        {
          field: 'skills.name',
          header: 'Skillset',
        },
        {
          field: 'experience',
          header: 'Exp (yrs)',
        },
        {
          type: 'percentage',
          field: 'percentage',
          header: 'Availability',
        },
      ]}
    />
  );
};

export const MyResources = props => {
  const params = props.route.params;
  return (
    <ResourcesTable
      header="Organizations Resources"
      period={params.period}
      moreActions={[
        {
          title: 'Request Talent',
          onPress: ({row}) => {
            props.navigation.navigate('add-request-talent-employee', {
              row,
              ...params,
            });
          },
        },
      ]}
    />
  );
};

export const OrganizationResources = props => {
  const params = props.route.params;
  return (
    <>
      <ResourcesTable
        header="Organizations Resources"
        period={params.period}
        moreActions={[
          {
            title: 'Allocate',
            onPress: ({row}) => {
              props.navigation.navigate('request-talent-assignment', {
                ...row,
                ...params,
              });
            },
          },
        ]}
      />
    </>
  );
};
