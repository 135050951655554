import React from 'react';
import {Table} from '../../../components/table/Table';

export const CustomerTable = props => {
  const params = props.route.params;
  return (
    <Table
      {...props}
      params={params}
      api={`/customers`}
      fields={{
        created_on: 1,
        name: 1,
        description: 1,
        active_status: 1,
        first_order_date: 1,
        location_id: {_id: 1, name: 1},
      }}
      sort={{creation_date: -1}}
      columns={[
        {
          type: 'date',
          field: 'created_on',
          header: 'Created On',
        },

        {
          type: 'text',
          field: 'name',
          header: 'Customer Name',
        },
        //   {
        //     type: 'text',
        //     field: 'description',
        //     header: 'Description',
        //   },
        {
          type: 'text',
          field: 'active_status',
          header: 'Status',
        },
        {
          type: 'date',
          field: 'first_order_date',
          header: 'First Order Date',
        },
        {
          type: 'text',
          field: 'location_id.name',
          header: 'Location',
        },
      ]}
    />
  );
};
