import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const PotentialCustomerContactForm = props => {
  const {header = 'Potential Customer'} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomercontacts',
    eventSourceId: ['potentialcustomeradded'],
  });

  return (
    <Form
      fields={{
        first_name: 1,
        last_name: 1,
        designation: 1,
        email: 1,
        linkedin_url: 1,
        duplicate_linkedin_url: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'first_name',
          type: 'text',
          label: 'First Name',
          placeholder: 'First Name',
        },
        {
          field: 'last_name',
          type: 'text',
          label: 'Last Name',
          placeholder: 'Last Name',
        },

        {
          field: 'email',
          type: 'text',
          label: 'Email',
          placeholder: 'Email',
        },
        {
          field: 'linkedin_url',
          type: 'text',
          label: 'LinkedIn Url',
          placeholder: 'LinkedIn Url',
        },
        {
          field: 'designation',
          type: 'text',
          label: 'Designation',
          placeholder: 'Designation',
        },

        {
          field: 'duplicate_linkedin_url',
          type: 'text',
          label: 'Duplicate LinkedIn Url',
          placeholder: 'Duplicate LinkedIn Url',
        },
      ]}
      {...props}
    />
  );
};

export const EditPotentialCustomerContactForm = props => {
  const {
    route: {
      params: {contact_id},
    },
  } = props;
  return (
    <PotentialCustomerContactForm
      mode="edit"
      api={`/potentialcustomercontacts/${contact_id?._id}`}
      header={'Edit Potential Customer'}
      {...props}
    />
  );
};
