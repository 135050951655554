export const useOrderActions = ({navigation}) => {
  const orderActivityLogs = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      api: `/orders/${row?._id}/activityLogs`,
      displayFields: {
        orderNumber: {
          label: 'Order No.',
          field: 'order_number',
        },
        order_date: {
          label: 'Order Date',
          field: 'order_date',
        },
        order_end_date: {
          label: 'Order End Date',
          field: 'order_end_date',
        },
        account_owner_id: {
          label: 'Account Owner',
          field: 'account_owner_id.name',
        },
        CSM: {
          label: 'CSM',
          field: 'delivery_owner_id.name',
        },
        amount: {
          label: 'Amount',
          field: 'amount',
        },
        est_order_hrs: {
          label: 'Est. Order Hrs',
          field: 'est_order_hrs',
        },
      },
    });
  };

  return {
    orderActivityLogs,
  };
};
