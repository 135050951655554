import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const TaskFieldForm = props => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Task',
    uri: '/projecttasks',
    ...props,
  });

  const {
    route: {params},
  } = props;

  const {fields = []} = params || {};

  const defaultValues = {};

  if (params?.task_id) {
    defaultValues._id = params?.task_id;
  }

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[...fields]}
      {...props}
    />
  );
};

export const AddTaskFieldForm = props => {
  return (
    <TaskFieldForm
      header={props.route.params.header || 'Add Task'}
      {...props}
    />
  );
};
