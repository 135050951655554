import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AddNewTicketForm = props => {
  const {
    user: {
      employee: {_id: employeeId},
    },
  } = useAppStateContext();

  const {onSubmit} = useFormSubmit({
    uri: '/tickets',
    eventSourceId: 'tickets',
  });

  return (
    <Form
      defaultValues={{
        raised_by: employeeId,
        status: 'Draft',
      }}
      header={'New Ticket'}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Subject',
          type: 'text',
          field: 'subject',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          required: true,
        },
        {
          label: 'Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: '/employeeTeams',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
          filter: {
            is_ticket_enabled: 1,
          },
        },
        {
          label: 'Category',
          field: 'category',
          type: 'autoComplete',
          api: '/ticketCategories',
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => {
            return {
              employee_team: values?.employee_team,
            };
          },
          size: 'medium',
          required: true,
          visible:({values}) => !!values?.employee_team
        },
        {
          label: 'Sub Category',
          field: 'sub_category',
          type: 'autoComplete',
          api: '/ticketSubCategories',
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => {
            return {
              parent_category: values?.category,
            };
          },
          size: 'medium',
          visible:({values}) => !!values?.category
        },
        {
          label: 'Category Owner',
          field: 'agent',
          type: 'autoComplete',
          api: '/getTicketOwners',
          filter: ({values}) => {
            return {
              category: values?.category?._id,
            };
          },
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          fields: {
            name: 1,
            official_email_id: 1,
            owner: 1,
          },
          size: 'medium',
          visible:({values}) => !!values?.category
        },
        {
          label: 'Seat No.',
          type: 'text',
          field: 'seat_number',
          size: 6,
        },
        {
          label: 'Priority',
          field: 'priority',
          type: 'autoComplete',
          options: ['Urgent', 'High', 'Medium', 'Low'],
          size: 6,
          required: true,
        },
        {
          label: 'Attachment',
          type: 'file',
          field: 'attachment',
          multiple: true,
          options: {bucketName: 'manaze'},
        },
      ]}
      {...props}
    />
  );
};

export const TicketDetailForm = props => {
  const {route} = props;
  const {params} = route;
  const {ticketId} = params;
  return (
    <AddNewTicketForm
      {...props}
      header={'Ticket Details'}
      api={'/tickets/' + ticketId}
      fields={{
        subject: 1,
        description: 1,
        employee_team: {
          name: 1,
        },
        category: {
          name: 1,
        },
        sub_category: {
          name: 1,
        },
        priority: 1,
        attachment: 1,
        seat_number: 1,
        agent: {
          name: 1,
        },
      }}
      mode="edit"
      readOnly
    />
  );
};

export const EditNewTicketForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {ticketDetails} = params;
  return (
    <AddNewTicketForm
      {...props}
      header={'Ticket Details'}
      api={`/tickets/${ticketDetails?._id}`}
      fields={{
        subject: 1,
        description: 1,
        employee_team: {
          name: 1,
        },
        priority: 1,
        seat_number: 1,
        category: {
          name: 1,
        },
        attachment: 1,
      }}
      mode="edit"
    />
  );
};
