const activityLogDetailForVoucher = ({row, navigation}) => {
  navigation.navigate(`activity-logs`, {
    _id: row._id,
    populate: [
      {path: 'entity', select: {name: 1}},
      {path: 'currency', select: {currency: 1}},
    ],
    api: `/vouchers/${row?._id}/activityLogs`,
    displayFields: {
      //   description: {
      //     label: 'Description',
      //   },
      cr_amount: {
        label: 'Cr Amount',
      },
      entity: {
        label: 'Entity',
        field: 'entity.name',
      },
      currency: {
        label: 'Currency',
        field: 'currency.currency',
      },
      reimbursement_type: {
        label: 'Type',
      },
      dr_amount: {
        label: 'Dr amount',
      },
      email_attachment: {
        label: 'File',
      },
      voucher_date: {
        label: 'Voucher date',
      },
    },
  });
};

const activityLogVoucher = ({navigation, params}) => ({
  title: 'Activity Logs',
  onPress: ({row}) =>
    activityLogDetailForVoucher({row, navigation, params}),
});

export {activityLogVoucher};
