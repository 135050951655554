export const CheckboxStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    checkedIcon: icons.CheckboxSelected,
    uncheckedIcon: icons.Checkbox,
    iconStyle: {
      height: 16,
      width: 16,
    },
    labelStyle: {
      marginLeft: 12,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const MultiCheckboxStyles = ({theme: {colors, fonts, icons}}) => {
  return {
    container: {
      columnGap: 36,
      rowGap: 8,
    },
    label: {...fonts.BODY3, color: colors.NEUTRAL_HIGH},
    secondaryLabel: {...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM},
  };
};
