import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssignEstHours = ({
  defaultValues,
  navigation,
  route: {params},
  ...props
}) => {
  let {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    ...props,
  });
  return (
    <Form
      {...props}
      header={'Assign Owner'}
      mode="edit"
      onSubmit={onSubmit}
      api={`/projecttasks`}
      filter={{
        _id: params?.row?._id,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Assigned To',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
        },
        {
          label: 'Plan Date',
          field: 'plan_date',
          type: 'date',
        },
        {
          label: 'Estimate Hours',
          field: 'est_hours',
          type: 'number',
        },
      ]}
    />
  );
};
