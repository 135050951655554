import React from 'react';
import {ScrollView, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {ChatComponentStyles} from '../../../components/chat/theme';

export const ShowIgnoreChats = props => {
  const {
    route: {params},
  } = props;
  let {data} = params;
  let {post, answer} = data || {};
  let {titleTextStyle, inputTextStyle} = useStyles(ChatComponentStyles);
  return (
    <ScrollView style={{padding: 24}}>
      <Text>
        <Text style={titleTextStyle}>Post:</Text>
        <Text style={inputTextStyle}>{post}</Text>
      </Text>
      <Text>
        <Text style={titleTextStyle}>Answer:</Text>
        <Text style={inputTextStyle}>{answer}</Text>
      </Text>
    </ScrollView>
  );
};
