import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EditDepartmentTag = props => {
  const {
    route: {params},
  } = props;
  const {departmentTag} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/departmenttags`,
    eventSourceId: 'DepartmentTag',
  });

  return (
    <Form
      header={{
        title: 'Edit Department Tag',
        secondaryTitle: departmentTag?.name,
      }}
      mode="edit"
      api={`/departmenttags/${departmentTag._id}`}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
        {
          field: 'path',
          type: 'text',
          label: 'Path',
        },
        {
          field: 'parent',
          type: 'autoComplete',
          label: 'Parent',
          api: `/departmentTags`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      onSubmit={onSubmit}
      submitAction="Update"
    />
  );
};
