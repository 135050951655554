import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';

export const CsatSalaryManual = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  return (
    <Form
      api={`/csatsalarymanual/setup/${row?._id}`}
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Employee',
          field: 'employee_id',
          api: '/employees',
          suggestionField: 'name',
          valueField: 'name',
          placeholder: 'Select',
          size: 6,
        },
        {
          type: 'date',
          label: 'From Date',
          field: 'from_date',
          size: 6,
        },
        {
          type: 'date',
          label: 'To Date',
          field: 'to_date',
          size: 6,
        },
        {
          fields: [
            {
              label: 'Level 1 Acheivement',
              field: 'level1_achievement',
              type: 'number',
              size: 6,
            },
            {
              label: 'Level 2 Acheivement',
              field: 'level2_achievement',
              type: 'number',
              size: 6,
            },
            {
              label: 'Level 3 Acheivement',
              field: 'level3_achievement',
              type: 'number',
              size: 6,
            },
            {
              label: 'Self Acheivement',
              field: 'self_achievement',
              type: 'number',
              size: 6,
            },
          ],
        },
        {
          label: 'Variable Details',
          fields: [
            {
              field: 'variable_detail',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  columns={[
                    {
                      header: 'Date',
                      field: 'date',
                      type: 'date',
                      minWidth: 150,
                    },
                    {
                      header: 'Varibale',
                      field: 'variable_object.variable',
                      type: 'number',
                      width: 150,
                    },
                    {
                      header: 'Total Hours',
                      field: 'variable_object.totalHours',
                      type: 'number',
                      width: 150,
                    },
                    {
                      header: 'level 1 Deliveries',
                      field: 'level_1_deliveries',
                      type: 'text',
                      width: 200,
                    },
                    {
                      header: 'Level 2 Deliveries',
                      field: 'level_2_deliveries',
                      type: 'text',
                      width: 200,
                    },
                    {
                      header: 'Level 3 Deliveries',
                      field: 'level_3_deliveries',
                      type: 'text',
                      width: 200,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Date',
                      field: 'date',
                      type: 'date',
                      size: 6,
                    },
                    {
                      label: 'Variable',
                      field: 'variable_object.variable',
                      type: 'number',
                      size: 6,
                    },
                    {
                      label: 'Total Hours',
                      field: 'variable_object.totalHours',
                      type: 'number',
                      size: 6,
                    },
                    {
                      label: 'Level 1 Deliveries',
                      field: 'level_1_deliveries',
                      type: 'text',
                      size: 6,
                    },
                    {
                      label: 'Level 2 Deliveries',
                      field: 'level_2_deliveries',
                      type: 'text',
                      size: 6,
                    },
                    {
                      label: 'Level 3 Deliveries',
                      field: 'level_3_deliveries',
                      type: 'text',
                      size: 6,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditCsatSalaryManual = props => {
  console.log('🚀 ~ EditCsatSalaryManual ~ props:', props);
  return (
    <CsatSalaryManual
      mode="edit"
      header={'CSAT Salary Manual'}
      readOnly={true}
      {...props}
    />
  );
};
