import React from 'react';
import moment from 'moment';
import {View, Text, TouchableOpacity} from '@unthinkable/react-core-components';

const Week = ({children, primary, theme}) => {
  const {viewStyle, primaryViewStyle, textStyle, primaryTextStyle} =
    theme?.weekComponentStyle || {};

  const _viewStyle = primary
    ? {...viewStyle, ...primaryViewStyle}
    : {...viewStyle};

  const _textStyle = primary
    ? {...textStyle, ...primaryTextStyle}
    : {...textStyle};

  return (
    <View style={_viewStyle}>
      <Text style={_textStyle}>{children}</Text>
    </View>
  );
};

const DateView = ({
  children,
  deActive,
  onChange,
  date,
  month,
  year,
  renderDate,
  theme,
  value,
  clickEvent,
  toggleClickEvent,
  ...rest
}) => {
  const createDate = ({date, month, year}) => {
    const newDate = new Date();
    newDate.setFullYear(year);
    newDate.setMonth(month, date);
    return newDate;
  };

  const onChangeHandler = e => {
    e.preventDefault();
    const {isDisabled} = getSelectionState();
    if (isDisabled) {
      return;
    }

    const newDate = createDate({date, month, year});
    const {from, to} = value;

    if (clickEvent === 'start') {
      value = {from: newDate, to: void 0};
    } else {
      value = {from, to: newDate};
    }
    onChange && onChange(value);
    toggleClickEvent && toggleClickEvent();
  };

  const getSelectionState = () => {
    const {from, to} = value || {};
    const newDate = createDate({date, month, year});
    let inBetween = false;
    let isSelected = false;
    let isDisabled = false;

    if (!from && !to) {
      return {inBetween, isSelected, isDisabled};
    }

    // Create UTC moment objects from Date objects
    const momentFrom = from && moment.utc(from);
    const momentTo = to && moment.utc(to);
    const momentNewDate = moment.utc(newDate);

    if (
      (momentFrom && momentNewDate.isSame(momentFrom, 'day')) ||
      (momentTo && momentNewDate.isSame(momentTo, 'day'))
    ) {
      isSelected = true;
    } else if (
      momentFrom &&
      momentTo &&
      momentNewDate.isBetween(momentFrom, momentTo)
    ) {
      inBetween = true;
    }

    if (momentFrom && !momentTo && momentNewDate.isBefore(momentFrom)) {
      isDisabled = true;
    }

    return {inBetween, isSelected, isDisabled};
  };

  const {
    viewStyle = {},
    textStyle = {},
    selectedTextStyle = {},
    selectedViewStyle = {},
    deActiveTextStyle = {},
    deActiveViewStyle = {},
    inBetweenStyle = {},
  } = theme.dateStyle || {};

  const {inBetween, isSelected, isDisabled} = getSelectionState();

  if (isDisabled) {
    deActive = true;
  }

  let _viewStyle = deActive
    ? {...viewStyle, ...deActiveViewStyle}
    : isSelected
    ? {...viewStyle, ...selectedViewStyle}
    : {...viewStyle};

  let _textStyle = deActive
    ? {...textStyle, ...deActiveTextStyle}
    : isSelected
    ? {...textStyle, ...selectedTextStyle}
    : {...textStyle};

  if (inBetween) {
    _viewStyle = {..._viewStyle, ...inBetweenStyle};
  }

  return (
    <TouchableOpacity style={_viewStyle} onClick={onChangeHandler}>
      {renderDate || (
        <Text style={{..._textStyle}} {...rest}>
          {children > 9 ? children : `0${children}`}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const RangeSelectDate = ({
  weekNames,
  monthNames,
  month,
  year,
  onChange,
  value,
  renderDate,
  theme,
  clickEvent,
  toggleClickEvent,
}) => {
  weekNames = weekNames || ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  monthNames = monthNames || [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    '',
    'September',
    'October',
    'November',
    'December',
  ];
  const getDates = () => {
    let dates = [];
    const currMonLstDtObj = new Date(year, month + 1, 0);
    const currMonLstDt = currMonLstDtObj.getDate();
    let currMonLstDay = currMonLstDtObj.getDay();
    const prevMonlstDtObj = new Date(year, month, 0);
    let prevMonLstDt = prevMonlstDtObj.getDate();
    let prevMonLstDay = prevMonlstDtObj.getDay();
    let perRowDate = 0;
    let dateToRender = [];

    // Previous month's dates
    while (prevMonLstDay >= 0 && prevMonLstDay < 6) {
      dates.unshift(
        <DateView
          theme={theme}
          deActive
          key={'prev' + prevMonLstDt}
          date={prevMonLstDt}
          renderDate={<></>}>
          {prevMonLstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.unshift(
          <View
            style={{flexDirection: 'row', flex: 1}}
            key={`prev-row-${prevMonLstDt}`}>
            {dates}
          </View>,
        );
        dates = [];
      }
      prevMonLstDt--;
      prevMonLstDay--;
    }

    // Current month's dates
    for (let currMonFstDt = 1; currMonFstDt <= currMonLstDt; currMonFstDt++) {
      dates.push(
        <DateView
          theme={theme}
          key={currMonFstDt}
          date={currMonFstDt}
          month={month}
          year={year}
          value={value}
          onChange={onChange}
          toggleClickEvent={toggleClickEvent}
          clickEvent={clickEvent}
          renderDate={renderDate}>
          {currMonFstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(
          <View style={{flexDirection: 'row'}} key={`curr-row-${currMonFstDt}`}>
            {dates}
          </View>,
        );
        dates = [];
      }
    }

    // Next month's dates
    currMonLstDay = 7 - currMonLstDay;
    for (let i = 1; i < currMonLstDay; i++) {
      dates.push(
        <DateView
          theme={theme}
          deActive
          key={'next' + i}
          date={i}
          renderDate={<></>}
        />,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(
          <View style={{flexDirection: 'row'}} key={`next-row-${i}`}>
            {dates}
          </View>,
        );
        dates = [];
      }
    }

    return <>{dateToRender}</>;
  };

  const {containerStyle = {}, weekContainerStyle = {}} = theme || {};

  return (
    <View style={containerStyle}>
      <View style={weekContainerStyle}>
        {weekNames.map((name, index) => (
          <Week key={index} theme={theme} primary={index === 0}>
            {name}
          </Week>
        ))}
      </View>
      <View style={{cursor: 'pointer'}}>{getDates()}</View>
    </View>
  );
};

export default RangeSelectDate;
