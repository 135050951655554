import React from 'react';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {Form} from '../../../components/form/Form';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ClosePartialEngagementForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/orders/skillrequirements`,
    eventSourceId: 't&m',
    method: 'post',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {data: {...data, fromOrder: false, order_id: row?.orderId}};
      }}
      header={{
        title: 'Close Partial Engagement',
        secondaryTitle: `${row?.order_number}`,
      }}
      defaultValues={{
        ...row,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Skill',
          placeholder: 'Skill',
          field: 'tool.name',
          type: 'text',
          readOnly: true,
          size: 3,
        },
        {
          label: 'Engg.',
          placeholder: 'Engg.',
          field: 'employee',
          valueField: 'name',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          readOnly: true,
          size: 3,
        },
        {
          label: 'Experince',
          placeholder: 'Experince',
          field: 'experience',
          valueField: 'experience',
          type: 'autoComplete',
          api: `/experience`,
          suggestionField: 'experience',
          readOnly: true,
          size: 3,
        },
        {
          label: 'Rate',
          placeholder: 'Rate',
          field: 'rate',
          type: 'text',
          readOnly: true,
          size: 3,
        },
        {
          label: 'Start Date',
          placeholder: 'Start Date',
          field: 'from_date',
          type: 'date',
          readOnly: true,
          size: 4,
        },
        {
          label: 'End Date',
          placeholder: 'End Date',
          field: 'to_date',
          type: 'date',
          validate: value => {
            if (new Date(value) > new Date(row.to_date)) {
              return "End Date can't be greater than Previous";
            }
          },
          size: 4,
        },
        {
          label: 'Hrs/day',
          placeholder: 'Hrs/day',
          field: 'employee_per_day_working_hour',
          type: 'text',
          readOnly: true,
          size: 4,
        },
      ]}
      {...props}
    />
  );
};

export const ClosePartialEngagementTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {row: order = {}} = params;
  return (
    <Table
      api={`/orders/skillrequirements/${order?._id}`}
      renderHeader={() => {
        return (
          <TableHeader
            title="Engagement"
            secondaryTitle={order?.order_number}
          />
        );
      }}
      eventSourceId={['t&m', 'orderTable', 'fixed']}
      columns={[
        {
          header: 'Skill',
          field: 'tool.name',
          type: 'colorTag',
          colorField: 'tool.color',
          width: 150,
        },
        {
          header: 'Engg.',
          render: ({row}) => {
            return (
              <UserAvatarChip value={row?.employeeAssignment?.[0]?.employee} />
            );
          },
        },
        {
          field: 'experience.experience',
          header: 'Exp.',
          type: 'text',
        },
        {
          field: 'rate',
          header: 'Rate/unit',
          type: 'text',
        },
        {
          field: 'from_date',
          header: 'Start',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'to_date',
          header: 'End',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'employee_per_day_working_hour',
          header: 'Hrs/day',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'Close',
          onPress: ({row}) => {
            navigation.navigate('order-partial-mark-closed-form', {
              row: {
                ...row,
                order_number: order?.order_number,
                orderId: order?._id,
                employee: row?.employeeAssignment?.[0]?.employee,
              },
            });
          },
        },
        {
          title: 'Extend',
          onPress: ({row}) => {
            navigation.navigate('order-extend-form', {
              row: {
                ...row,
                order_number: order?.order_number,
                orderId: order?._id,
                order_end_date: order?.order_end_date,
              },
              formPartialExtend: true,
            });
          },
        },
      ]}
    />
  );
};
