// show all unique selling point list - Parveen 13-02-2024
import React from 'react';
import {Table} from '../../../components/table/Table';

export const UniqueSellingPointList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['uniqueSellingPointAdded']}
      api={`/uniquesellingpoints`}
      fields={{
        name: 1,
        sample: 1,
        use_case: 1,
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
        },
        {
          field: 'sample',
          header: 'Sample',
          type: 'text',
        },
        {
          field: 'use_case',
          header: 'Use Case',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('unique-selling-point-edit-form', {row});
          },
        },
      ]}
    />
  );
};
