import React from 'react';
import {CompactAddButton} from '../../../components/button/AddButton';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {MarketingConceptTable} from './MarketingConceptTable';
import {DripEmailTabView} from '../../leadGeneration/views/DripEmailTabView';
import {DripInmailTabView} from '../../leadGeneration/views/DripInmailTabView';
import {CreativeAdTabView} from '../../leadGeneration/views/CreativeAdTabView';
import {BlogTabView} from '../../leadGeneration/views/BlogTabView';
import {BuyerPersonaTabView} from '../../leadGeneration/views/BuyerPersonaTabView';
import {CarouselAdTabView} from '../../leadGeneration/views/CarouselAdTabView';
import {CaseStudyTabView} from '../../leadGeneration/views/CaseStudyTabView';
import {ImageTabView} from '../../leadGeneration/views/ImageTabView';
import {SingleScreenMessageTabView} from '../../leadGeneration/views/SingleScreenMessageTabView';
import {VideoTabView} from '../../leadGeneration/views/VideoTabView';
import {WebPageTabView} from '../../leadGeneration/views/WebPageTabView';
import {PlatformTable} from './PlatformTable';

const menus = [
  [
    {
      label: 'Marketing Concepts',
      view: <MarketingConceptTable />,
      actions: [
        <CompactAddButton
          title={'Marketing Concept'}
          view={'add-marketing-concept'}
        />,
      ],
    },
  ],
  [
    {
      label: 'Ad Creative',
      view: <CreativeAdTabView />,
      actions: [
        <CompactAddButton title={'Ad Creative'} view={'add-adcreatives'} />,
      ],
    },
    {
      label: 'Carousel Ad',
      view: <CarouselAdTabView />,
      actions: [
        <CompactAddButton title={'Carousel Ad'} view={'add-carouselad'} />,
      ],
    },
    {
      label: 'Image Ad',
      view: <ImageTabView />,
      actions: [<CompactAddButton title={'Image Ad'} view={'add-image'} />],
    },
    {
      label: 'Video Ad',
      view: <VideoTabView />,
      actions: [<CompactAddButton title={'Video Ad'} view={'add-video'} />],
    },
  ],
  [
    {
      label: 'Drip Email',
      view: <DripEmailTabView />,
      actions: [
        <CompactAddButton title={'Drip Email'} view={'add-dripemailcontent'} />,
      ],
    },
    {
      label: 'Drip Inmail',
      view: <DripInmailTabView />,
      actions: [
        <CompactAddButton
          title={'Drip Inmail'}
          view={'add-dripinmailcontent'}
        />,
      ],
    },
  ],
  [
    {
      label: 'Blog',
      view: <BlogTabView />,
      actions: [<CompactAddButton title={'Blog'} view={'add-blog'} />],
    },
    {
      label: 'Buyer Persona',
      view: <BuyerPersonaTabView />,
      actions: [
        <CompactAddButton title={'Buyer Persona'} view={'add-buyerpersona'} />,
      ],
    },
    {
      label: 'Case Study',
      view: <CaseStudyTabView />,
      actions: [
        <CompactAddButton title={'Case Study'} view={'add-casestudy'} />,
      ],
    },
    {
      label: 'Platform',
      view: <PlatformTable />,
      actions: [<CompactAddButton title={'Platform'} view={'add-platform'} />],
    },
    {
      label: 'Single Screen Message',
      view: <SingleScreenMessageTabView />,
      actions: [
        <CompactAddButton
          title={'Employee Assignment'}
          view={'add-singlescreenmessage'}
        />,
      ],
    },
    {
      label: 'Web Page',
      view: <WebPageTabView />,
      actions: [<CompactAddButton title={'Web Page'} view={'add-webpage'} />],
    },
  ],
];

const CreativeMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export default CreativeMenu;
