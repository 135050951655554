import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UploadForm16 = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  //   const {user} = useAppStateContext();
  //   const {
  //     employee: {_id /* reporting_to */},
  //   } = user;
  let {onSubmit} = useFormSubmit({
    uri: '/Form16BatchDocuments',
    eventSourceId: ['bulkform16'],
    ...props,
  });
  return (
    <Form
      api={`/Form16BatchDocuments/${row?._id}`}
      fields={{
        document: 1,
        _created_on: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{}}
      layoutFields={[
        {
          label: 'Upload Attachment',
          placeholder: 'Attachment',
          field: 'document',
          type: 'file',
          required: true,
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};

export const UploadForm16DocumentForm = props => {
  return <UploadForm16 header={'Upload Form 16'} {...props} />;
};
