import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AssetAssigneeForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  const {user} = useAppStateContext();
  const {employee} = user;
  let {onSubmit} = useFormSubmit({
    uri: `/assetinventories/assign/${row?._id}`,
    eventSourceId: 'AssetAssign',
  });
  return (
    <Form
      header={{
        title: 'Asset assignment',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Assigned',
            assigned_from_date: data?.assigned_from_date,
            assigned_to_date: data?.assigned_to_date,
            asset: row?._id,
            asset_aggrement_document: data?.asset_aggrement_document,
            assigned_by: employee,
            parent_asset: row?.parent_asset?._id,
            asset_aggrement_signed: data?.asset_aggrement_signed,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Assignee',
          field: 'assigned_to',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          valueField: 'name',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
        {
          label: 'Assign from',
          field: 'assigned_from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        // {
        //   label: 'Assign to',
        //   field: 'assigned_to_date',
        //   type: 'date',
        //   required: true,
        //   size: 6,
        // },
        {
          fields: [
            {
              label: 'Aggrement',
              field: 'asset_aggrement_signed',
              type: 'checkbox',
            },
            {
              placeholder: 'Aggrement doc.',
              field: 'asset_aggrement_document',
              type: 'file',
              visible: ({values}) => values?.asset_aggrement_signed,
              options: {bucketName: 'manaze'},
              required: true,
            },
          ],
        },
      ]}
    />
  );
};
