import React from 'react';
import {EmployeeRequestTable} from './EmployeeRequestTable';
import {TabView} from '../../../components/tab';
import {AddButton} from '../../../components/button/AddButton';

const FixedOrderEmployeeRequestTab = props => {
  let {route: {params} = {}} = props || {};
  let {row = {}} = params || {};
  let filterParams = {
    order: row?._id,
  };
  const tabs = {
    fixed_order_assignment: {
      label: 'Resource Request',
      view: (
        <EmployeeRequestTable
          isResourceRequest
          filterParams={filterParams}
          {...props}
        />
      ),
      eventSourceId: 'orderresourcerequests',
      api: `/orderRequest/pending/ot`,
      filter: filterParams,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <AddButton
          title="Resource Request"
          eventSourceId="orderresourcerequests"
          view="create-employee-request"
          params={params}
        />,
      ]}
    />
  );
};

export default FixedOrderEmployeeRequestTab;
