import React from 'react';
import {Table} from '../../../components/table/Table';

export const LocomoWaitlistTable = props => {
  let {
    route: {params},
  } = props;

  return (
    <Table
      api={`/waitlistmembers`}
      sort={{createdAt: -1}}
      params={params}
      columns={[
        {
          header: 'Date',
          field: 'createdAt',
          type: 'date',
        },
        {
          field: 'name',
          header: 'Name',
          type: 'text',
        },
        {
          field: 'email',
          header: 'Email',
          type: 'text',
        },
        {
          field: 'mobile',
          header: 'Mobile',
          type: 'text',
        },
      ]}
    />
  );
};
