import React from 'react';
import {Table} from '../../../components/table/Table';

const assignResource = ({navigation, params, filterParams, milestone}) => ({
  title: 'Allocate Resource',
  onPress: props => {
    const {row} = props;
    navigation.navigate('fix-order-resource-assignment-for-rm', {
      row,
      filterParams: {...filterParams, _id: row?._id},
      milestone,
    });
  },
});

const FixOrderResourcesAssignForRmList = props => {
  let {navigation = {}, route = {}} = props || {};
  let {params = {}} = route || {};
  let {milestone = {}} = params || {};
  let filterParams = {};

  if (milestone?._id) filterParams['order'] = milestone?.order._id;
  return (
    <Table
      api={`/orderassignments`}
      params={params}
      filter={filterParams}
      fields={{
        hours: 1,
        to_date: 1,
        from_date: 1,
        employee: {
          name: 1,
        },
        order: {
          order_number: 1,
        },
        skill_required: {
          name: 1,
        },
      }}
      columns={[
        {
          field: 'employee',
          header: 'Name',
          type: 'userAvatarChip',
        },
        {
          field: 'skill_required.name',
          header: 'Skill',
        },
        {
          field: 'from_date',
          header: 'From Date',
          type: 'date',
        },
        {
          field: 'to_date',
          header: 'To Date',
          type: 'date',
        },
        {
          field: 'hours',
          header: 'Hours',
          type: 'number',
        },
      ]}
      moreActions={() => [
        assignResource({navigation, params, filterParams, milestone}),
      ]}
    />
  );
};

export default FixOrderResourcesAssignForRmList;
