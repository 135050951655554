import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const ScheduleInterview = props => {
  const {route} = props;
  const {params} = route;
  const {candidate, field, round} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: ['candidate'],
  });

  return (
    <Form
      header={{title: 'Schedule Interview', secondaryTitle: candidate?.name}}
      defaultValues={{
        _id: candidate?._id,
      }}
      beforeSubmit={({data}) => ({
        data: {
          _id: data._id,
          [field]: [
            {
              interviewer: data.interviewer,
              interview_date: new Date(
                new Date(data.interview_date).setHours(
                  data.hour,
                  data.minute,
                  0,
                ),
              ),
              status: 'Active',
            },
          ],
          current_round: round,
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'interviewer',
          label: 'Interviewer',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          valueField: 'name',
          suggestionField: 'name',
          required: true,
        },
        {
          label: 'Interview Date & Time',
          fields: [
            {
              type: 'date',
              field: 'interview_date',
              label: 'Date',
              required: true,
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'hour',
              options: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                18, 19, 20, 21, 22, 23,
              ],
              size: 4,
              label: 'Hr',
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'minute',
              options: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
                50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
              ],
              size: 4,
              label: 'Minutes',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};
