import React from 'react';
import {Table} from '../../../components/table/Table';

export const PermanentTeamTable = props => {
  let {
    route: {params},
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const {department, role} = params;
  return (
    <Table
      {...props}
      params={params}
      api={`/departments/${department?._id}/employeeCost`}
      sort={{monthly_cost: -1}}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Members',
        },
        // {
        //   type: 'text',
        //   field: 'role',
        //   header: 'Role',
        // },
        // {
        //   type: 'text',
        //   field: row =>
        //     row?.employee_assignments
        //       ?.map(item => item?.object?.name)
        //       .join(','),
        //   header: 'Project',
        // },
        // {
        //   type: 'number',
        //   field: 'plan',
        //   header: 'Plan',
        // },
        {
          type: 'percentage',
          field: 'percentage',
          header: 'Percentage',
        },
        ...(role == 'admin'
          ? [
              {
                type: 'currency',
                field: 'ctc_amount',
                header: 'Cost',
              },
            ]
          : []),
      ]}
      moreActions={[
        {
          title: 'Change Allocation',
          onPress: ({row}) => {
            props.navigation.navigate('change-allocation', {
              row,
              ...params,
            });
          },
        },
        {
          title: 'Allocate On inputs',
          onPress: ({row}) => {
            props.navigation.navigate('allocate-on-inputs', {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Show Allocation',
          onPress: ({row}) => {
            props.navigation.navigate('employee-allocation', {
              row,
              ...params,
            });
          },
        },
      ]}
      rowActions={[
        {
          title: 'Edit Allocation',
          onPress: () => console.log('Edit Details'),
        },
        {
          title: 'Remove Allocation',
          onPress: () => console.log('Remove Details'),
        },
        {},
      ]}
    />
  );
};
