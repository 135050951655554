import React, {useState} from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {useInvoke} from '../../controllers/useInvoke';
import {OutlineButton} from '../../components/button/OutlineButton';
import {MailContentRender} from '../../modules/leadGeneration/components/ExpandableRowComponent';
import {TestProfileSampleStyle} from '../../modules/leadGeneration/outreachcampaign-views/theme';
import {useStyles} from '@unthinkable/react-theme';

const GenerateSubjectMessageCellRender = ({campaignMessage_id, value}) => {
  const [data, setData] = useState({});
  const invoke = useInvoke({method: 'post'});
  const generateMessageAi = async doc => {
    if (doc?._id) {
      let resp = await invoke({
        uri: `/generate-ai-message`,
        props: {
          campaign_contact_id: doc?._id,
          campaign_message_id: campaignMessage_id,
        },
      });
      if (resp?.data) {
        data[doc?._id] = resp?.data;
        let oldData = {...data};
        setData({...oldData, ...data});
      }
    }
  };

  const styles = useStyles(TestProfileSampleStyle);

  let {containerStyle, textStyle} = styles || {};

  return (
    <View>
      {value?.map(doc => {
        return (
          <>
            <View style={containerStyle}>
              <Text style={textStyle}>{doc.name}</Text>
              <OutlineButton
                disabled={!campaignMessage_id ? true : false}
                text={'Generate'}
                onPress={() => generateMessageAi(doc)}
              />
            </View>
            {data[doc?._id] ? (
              <MailContentRender
                subject={data[doc?._id]?.subject}
                message={data[doc?._id]?.body}
              />
            ) : null}
          </>
        );
      })}
    </View>
  );
};

export default GenerateSubjectMessageCellRender;
