import React from 'react';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import moment from 'moment';
import {TextRenderer} from '../../../components/form/Renderers';
import {Col, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useInvoke} from '../../../controllers/useInvoke';
import {chatScreen} from './AssignedTicketsView';
import {Rating} from '@unthinkable/react-star-rating';
import {
  UserAvatar,
  UserAvatarGroup,
} from '../../../components/avatar/UserAvatar';

const RenderRaisedOn = ({row}) => {
  const {ERROR_HIGH, SUCCESS_HIGH, WARNING_HIGH, ACCENT5_HIGH} =
    useTheme('colors');
  const PRIORITY_WISE_COLOR = {
    Urgent: ERROR_HIGH,
    High: WARNING_HIGH,
    Medium: ACCENT5_HIGH,
    Low: SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        flexDirection: 'columns',
        gap: 8,
        borderLeftColor: PRIORITY_WISE_COLOR[row.priority],
        borderLeftWidth: 2,
        padding: 12,
        margin: -8,
      }}>
      <TextRenderer value={moment(row.raised_on_date).format('DD MMM YY')} />
      <GroupContentItem value={moment(row.raised_on_date).format('hh:mm a')} />
    </View>
  );
};

const RatingRenderer = ({row, ...rest}) => {
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'tickets',
  });
  const updateFeedback = value => {
    invoke({
      uri: '/tickets/' + row?._id,
      props: {
        feedback: value,
      },
    });
  };

  return <Rating {...rest} value={row['feedback']} onChange={updateFeedback} />;
};
const RenderDate = ({value}) => {
  return (
    <View style={{gap: 8, flexDirection: 'column'}}>
      <TextRenderer value={moment(value).format('DD MMM YY')} />
      <GroupContentItem value={moment(value).format('hh:mm A')} />
    </View>
  );
};

const RenderName = ({row}) => {
  return (
    <View style={{gap: 8}}>
      <TextRenderer value={row.subject} style={{numberOfLines: 1}} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={`#${row?.ticket_number}`} />
        <GroupContentItem value={row?.employee_team?.name} />
        <GroupContentItem value={row?.category?.name} />
      </RowWithSeparator>
    </View>
  );
};

const RenderClosedStatus = ({row}) => {
  const {is_delayed, delay_time = 0, early_time = 0} = row;
  const IsDelay = is_delayed ? 'Delayed' : 'On Time';
  let DelayText =
    'By ' +
    (Number.parseInt(delay_time / 60)
      ? Number.parseInt(delay_time / 60) + ' hrs '
      : '') +
    (delay_time % 60) +
    ' mins';
  if (isNaN(delay_time)) {
    DelayText = 'By ' + delay_time;
  }
  const EarlyText =
    (Number.parseInt(early_time / 60)
      ? Number.parseInt(early_time / 60) + ' hrs '
      : '') +
    (early_time % 60) +
    ' mins' +
    ' early';
  const {SUCCESS_HIGH, SUCCESS_LOW, ERROR_HIGH, ERROR_LOW} = useTheme('colors');
  const backgroundColorsMap = {
    Delayed: ERROR_LOW,
    'On Time': SUCCESS_LOW,
  };
  const textColorsMap = {
    Delayed: ERROR_HIGH,
    'On Time': SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        backgroundColor: backgroundColorsMap[IsDelay],
        padding: 12,
        margin: -12,
        gap: 8,
      }}>
      <TextRenderer
        value={IsDelay}
        style={{
          color: textColorsMap[IsDelay],
        }}
      />
      <GroupContentItem value={is_delayed ? DelayText : EarlyText} />
    </View>
  );
};

const AssignedToWithOld = ({row}) => {
  const {agent, old_agents} = row;
  return (
    <Col gap={8}>
      <UserAvatar value={agent} />
      <UserAvatarGroup value={old_agents} />
    </Col>
  );
};

export const EmployeeTicketList = props => {
  const {
    navigation,
    isClosed,
    isTempClosed,
    route: {params = {}},
  } = props;
  const {
    user: {
      employee: {_id: employeeId},
    },
  } = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'tickets',
  });
  const markClose = ({row}) => {
    invoke({
      uri: '/tickets/' + row?._id,
      props: {
        status: 'Closed',
      },
    });
  };

  return (
    <Table
      sort={{raised_on_date: -1}}
      api="/tickets"
      eventSourceId={['tickets']}
      onRowPress={({row}) => {
        chatScreen({row, navigation, params});
      }}
      fields={{
        subject: 1,
        description: 1,
        agent: {name: 1, color: 1},
        raised_on_date: 1,
        expected_close: 1,
        status: 1,
        category: {
          name: 1,
        },
        priority: 1,
        employee_team: {
          name: 1,
        },
        ticket_number: 1,
        closed_on: 1,
        raised_by: {
          name: 1,
          employee_code: 1,
          official_email_id: 1,
        },
        feedback: 1,
        is_delayed: 1,
        delay_time: 1,
        early_time: 1,
        attachment: 1,
        dependency_status: 1,
        temporarily_closed_on: 1,
        old_agents: {
          name: 1,
          color: 1,
        },
      }}
      filter={{
        raised_by: employeeId,
      }}
      columns={[
        {
          render: RenderRaisedOn,
          header: 'Raised On',
          width: 100,
        },
        {
          header: 'Description',
          render: RenderName,
          minWidth: 250,
        },
        {
          field: 'agent',
          header: 'Assigned To',
          type: 'userAvatar',
          width: 120,
          align: 'center',
          visible: !isClosed,
        },
        {
          render: AssignedToWithOld,
          header: 'Assigned To',
          width: 120,
          visible: !!isClosed,
        },
        {
          render: RenderDate,
          field: 'temporarily_closed_on',
          header: 'Under Observation Since',
          width: 210,
          visible: !!isTempClosed,
        },
        {
          render: RenderDate,
          field: 'expected_close',
          header: 'Target Closure',
          width: 160,
          visible: !isClosed && !isTempClosed,
        },
        {
          render: RenderDate,
          field: 'closed_on',
          header: 'Closed On',
          width: 160,
          visible: !!isClosed,
        },
        {
          type: 'colorTag',
          field: 'dependency_status',
          header: 'Dependency Status',
          width: 210,
          visible: !isClosed && !isTempClosed,
        },
        {
          render: RatingRenderer,
          header: 'Feedback',
          width: 150,
          visible: !!isClosed,
          onPress: () => undefined,
        },
      ]}
      moreActions={[
        {
          title: 'Close',
          onPress: markClose,
          visible: !isClosed,
        },
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('ticket-detail', {
              ticketId: row._id,
              ticket: row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
