import React from 'react';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

export const AccountWiseBalanceTable = props => {
  const {
    filterParams,
    searchValue,
    route: {params = {}},
  } = props;
  return (
    <Table
      {...props}
      api={'/getaccountwisebalance'}
      addOnFilter={{...filterParams}}
      params={params}
      eventSourceId="balance"
      search={searchValue}
      limit={50}
      columns={[
        {
          field: 'account.name',
          header: 'Account',
          type: 'text',
        },
        {
          field: 'entity.name',
          header: 'Entity',
          type: 'text',
        },
        {
          type: 'currency',
          header: 'Credit',
          field: 'cr_amount',
        },
        {
          field: 'dr_amount',
          type: 'currency',
          header: 'Debit',
        },
        {
          field: 'dr_amount',
          type: 'currency',
          header: 'Balance',
          value: ({row}) => {
            let cr = row.cr_amount || 0;
            let dr = row.dr_amount || 0;
            let value = Math.abs(cr - dr);
            value = value && value.toFixed(2);
            return value;
          },
        },
      ]}
    />
  );
};

export const AccountwiseBalanceTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    tds: {
      label: 'Accountwise Balance',
      view: (
        <AccountWiseBalanceTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'reconcilation',
      addOnFilter: filter,
      api: '/getaccountwisebalance',
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportaccountwisebalance',
              props: {
                addOnFilter: {...filter},
                params: {...params, ...filterValues?.params},
                globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            // {
            //     type: 'date',
            //     label: 'Date',
            //     field: 'date',
            // },
            {
              type: 'autoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account',
            },
            {
              type: 'autoComplete',
              label: 'Entity',
              api: '/getentities',
              suggestionField: 'name',
              valueField: 'name',
              field: 'entity',
            },
            // {
            //     type: 'multiAutoComplete',
            //     label: 'Organization',
            //     api: '/organizations',
            //     placeholder: 'Select',
            //     suggestionField: 'name',
            //     valueField: 'name',
            //     field: 'organization',
            // },
            // {
            //     type: 'autoComplete',
            //     label: 'Product',
            //     api: '/products',
            //     placeholder: 'Select',
            //     suggestionField: 'name',
            //     valueField: 'name',
            //     field: 'product',
            // },
          ]}
        />,
      ]}
    />
  );
};
