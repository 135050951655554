import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const PotentialCustomersHubspotLeadForm = props => {
  let {
    route: {params: {_id} = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: `/hubspotleads`,
    eventSourceId: 'updateHubspotContact',
  });

  return (
    <Form
      mode="edit"
      fields={{
        first_name: 1,
        last_name: 1,
        email: 1,
        phone: 1,
        country: 1,
        website: 1,
        createdate: 1,
        requirements: 1,
        first_url: 1,
        utm_source: 1,
        form_fill_url: 1,
        contact_properties: 1,
        form_submissions: 1,
        uqlUpdateStatus: 1,
      }}
      api={`/hubspotleads/${_id}`}
      onSubmit={onSubmit}
      header={'Hubspot'}
      submitAction={'Save'}
      layoutFields={[
        [
          {
            field: 'first_name',
            type: 'text',
            label: 'First Name',
            placeholder: 'First Name',
            size: 3,
          },
          {
            field: 'last_name',
            type: 'text',
            label: 'Last Name',
            placeholder: 'Last Name',
            size: 3,
          },
          {
            field: 'email',
            type: 'text',
            label: 'Email',
            placeholder: 'Email',
            size: 3,
          },
        ],
        [
          {
            field: 'phone',
            type: 'text',
            label: 'Phone',
            placeholder: 'Phone',
            size: 3,
          },
          {
            field: 'country',
            type: 'text',
            label: 'Country',
            placeholder: 'Country',
            size: 3,
          },
          {
            field: 'website',
            type: 'text',
            label: 'Website',
            placeholder: 'Website',
            size: 3,
          },
          {
            field: 'createdate',
            type: 'date',
            label: 'Added At',
            placeholder: 'Added At',
            size: 3,
          },
        ],
        {
          field: 'requirements',
          type: 'textArea',
          label: 'Requirements',
          placeholder: 'Requirements',
        },
        {
          field: 'first_url',
          type: 'text',
          label: 'First URL',
          placeholder: 'First URL',
        },
        {
          field: 'utm_source',
          type: 'text',
          label: 'UTM Source',
          placeholder: 'UTM Source',
        },
        {
          field: 'form_fill_url',
          type: 'text',
          label: 'Form fill URL',
          placeholder: 'Form fill URL',
        },
        {
          field: 'contact_properties',
          type: 'textArea',
          label: 'Contact Properties',
          placeholder: 'Contact Properties',
        },
        {
          field: 'form_submissions',
          type: 'textArea',
          label: 'Form Submissions',
          placeholder: 'Form Submissions',
        },
      ]}
      {...props}
    />
  );
};
