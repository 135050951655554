import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const ReimbursementCategory = props => {
  const {
    route: {params},
  } = props;
  const {reimbursement_category} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/reimbursementcategories',
    eventSourceId: 'ReimbursementCategory',
  });

  return (
    <Form
      api={`/reimbursementCategory/${reimbursement_category}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          required: true,
        },
        {
          type: 'autoComplete',
          field: 'type',
          label: 'Type',
          options: ['Regular', 'Certificate'],
          required: true,
        },
        {
          label: 'Account',
          field: 'account',
          type: 'autoComplete',
          api: `/accounts`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditReimbursementCategory = props => {
  return (
    <ReimbursementCategory
      mode="edit"
      header="Update Reimbursement Category"
      {...props}
    />
  );
};
export const AddReimbursementCategory = props => {
  return (
    <ReimbursementCategory header="Add Reimbursement Category" {...props} />
  );
};
