import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text} from '@unthinkable/react-core-components';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {getCurrentMonth} from '@unthinkable/react-filters';

export const EmployeeListToBeFree = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, _id, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      searchFields={['name', 'employee_code']}
      api={`/employees/active`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
                primarySubTitle={row?.type}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 80,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Designation',
          width: 220,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.designation?.name}
                items={[
                  {
                    value: row?.employee_team?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Skill',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Latest Project',
          field: 'latest_project.project',
          type: 'text',
        },
        {
          header: 'To Be Free Date',
          field: 'latest_assignment_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
      ]}
    />
  );
};

export const EmployeeToBeFreeTabs = props => {
  let {route: {params} = {}, navigation} = props;
  const {department} = params;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {
      department: department,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const {period: filterPeriod = {}} = filterParams;
  let tabs = {};
  tabs.employee_to_be_free = {
    label: 'Employee To Be Free',
    api: '/employees/active',
    params: {
      ...params,
    },
    addOnFilter: {
      ...filter,
      latest_assignment_date: {
        $gte: filterPeriod.from,
        $lte: filterPeriod.to,
      },
    },
    limit: 5000,
    view: (
      <EmployeeListToBeFree
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          latest_assignment_date: {
            $gte: filterPeriod.from,
            $lte: filterPeriod.to,
          },
        }}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Project',
            api: '/projects',
            placeholder: 'Select',
            suggestionField: 'project',
            valueField: 'project',
            field: 'latest_project',
          },
        ]}
      />,
    ],
  };
  return <TabView tabs={tabs} {...props} />;
};
