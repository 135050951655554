import React from 'react';
import { createMenuNavigator } from '../../../components/menu/MenuNavigator';
import { useAppStateContext } from '../../../providers/AppState';
import { PandLTabs } from './PandLReportTable';
import { TrailBalanceTab } from './TrailBalanceTable';
import { VoucherLedgerTab } from './VoucherLedgerTable';
import { BalanceSheetTabs } from './BalanceSheetTable';
import {
    BankBalanceTab,
    BankReconcilationTab,
    NotBankReconcilationTab,
} from './ReconcilationTables';
import {
    CustomerBalanceTab,
    VendorAdvanceTab,
    VendorBalanceTab,
} from './EntityBalanceTab';
import { AccountwiseBalanceTab } from './AccountwiseBalanceTable';
import VoucherTab from '../../order/views/VoucherTab';
import { VoucherTrailReport } from './VourcherTrailReport';
import { FundTranferTab } from './FundTransferTab';
import {FreezeRangeTable} from '../../common/views/FreezeRangeTable';

const FinanceMenu = createMenuNavigator();

const FinanceNavigator = props => {
    const { route: { params } = {} } = props;
    const { user, globalData: { accessRights: userAccessRights } = {} } =
        useAppStateContext();
    const { email: employeeEmail } = user;
    //   const canAccess = useAccessRight({accessRights: userAccessRights});

    return (
        <FinanceMenu.Navigator
            //   canAccess={canAccess}
            data-navigator-id="FinanceGroup"
            {...props}>
            <FinanceMenu.Screen
                data-resource-id="PandLTabs"
                name="Profit and Loss"
                component={PandLTabs}
                options={{
                    label: 'Profit and Loss',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="Trial"
                name="Trial"
                component={TrailBalanceTab}
                options={{
                    label: 'Trial',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="Ledger"
                name="Ledger"
                component={VoucherLedgerTab}
                options={{
                    label: 'Ledger',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="Balancesheet"
                name="Balance Sheet"
                component={BalanceSheetTabs}
                options={{
                    label: 'Balance Sheet',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="Accountwisebalance"
                name="Account Wise Balance"
                component={AccountwiseBalanceTab}
                options={{
                    label: 'Account Wise Balance',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="Accountwisebalance"
                name="Voucher Trial Report"
                component={VoucherTrailReport}
                options={{
                    label: 'Voucher Trial Report',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="Reconcilation"
                name="Reconcilation"
                component={BankBalanceTab}
                options={{
                    label: 'Reconcilation',
                }}
            />
            {/* <FinanceMenu.Group>
                <FinanceMenu.Section options={{ label: "Reconcilation" }}>
                    <FinanceMenu.Screen
                        data-resource-id="Reconcilation"
                        name="Bank Reconcilation"
                        props={{ user: user, ...props }}
                        component={BankReconcilationTab}
                        options={{
                            label: 'Bank Reconcilation',
                        }}
                    />
                    <FinanceMenu.Screen
                        data-resource-id="Reconcilation"
                        name="Not Bank Reconcilation"
                        props={{ user: user, ...props }}
                        component={NotBankReconcilationTab}
                        options={{
                            label: 'Not Bank Reconcilation',
                        }}
                    />
                    <FinanceMenu.Screen
                        data-resource-id="Reconcilation"
                        name="Bank Balance"
                        props={{ user: user, ...props }}
                        component={BankBalanceTab}
                        options={{
                            label: 'Bank Balance',
                        }}
                    />
                </FinanceMenu.Section>
            </FinanceMenu.Group> */}
            <FinanceMenu.Group>
                <FinanceMenu.Section options={{ label: "Entity Balance" }}>
                    <FinanceMenu.Screen
                        data-resource-id="EntityBalance"
                        name="Vendor Advances"
                        props={{ user: user, ...props }}
                        component={VendorAdvanceTab}
                        options={{
                            label: 'Vendor Advances',
                        }}
                    />
                    <FinanceMenu.Screen
                        data-resource-id="EntityBalance"
                        name="Customer Balance"
                        props={{ user: user, ...props }}
                        component={CustomerBalanceTab}
                        options={{
                            label: 'Customer Balance',
                        }}
                    />
                    <FinanceMenu.Screen
                        data-resource-id="EntityBalance"
                        name="Vendor Balance"
                        props={{ user: user, ...props }}
                        component={VendorBalanceTab}
                        options={{
                            label: 'Vendor Balance',
                        }}
                    />
                </FinanceMenu.Section>
            </FinanceMenu.Group>
            <FinanceMenu.Screen
                data-resource-id="Vouchers"
                name="Vouchers"
                component={VoucherTab}
                options={{
                    label: 'Vouchers',
                }}
            />
            <FinanceMenu.Screen
                data-resource-id="FundTranfer"
                name="Fund Tranfer"
                component={FundTranferTab}
                options={{
                    label: 'Fund Tranfer',
                }}
            />
            {employeeEmail == 'sushil.nagvan@daffodilsw.com' ||
                employeeEmail == 'rakesh.kumar@daffodilsw.com' ? (
                <FinanceMenu.Screen
                    data-resource-id="FreezeRange"
                    name="FreezeRange"
                    component={FreezeRangeTable}
                    options={{
                        label: 'Freeze Range',
                    }}
                />
            ) : (
                void 0
            )}
        </FinanceMenu.Navigator>
    );
};

export default FinanceNavigator;
