import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {MultiStepForm} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {AssignInmailMessageSequence} from './AssignInmailMessageSequence';
import {LoadInmailThemes} from './LoadInmailThemes';
import {LoadProspectsTable} from './LoadProspectList';

export const AddInmailCampaign = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/campaigns',
    eventSourceId: 'inmailCampaignAdded',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
  } = props;
  const {product, row, afterSubmit, searchValue} = params;
  return (
    <MultiStepForm
      api={`/campaigns/${row?._id}`}
      computations={{
        employee_team: [
          value => value?.assigned_to?.employee_team,
          ['assigned_to'],
        ],
      }}
      fields={{
        name: 1,
        date: 1,
        inmail: {
          inmail_subject: 1,
        },
        profile_owner: {
          name: 1,
        },
        assigned_to: {
          name: 1,
          employee_team: 1,
        },
        communication_type: 1,
        target_audience: {
          name: 1,
          industry: 1,
        },
        channel: {name: 1},
        employee_team: {name: 1},
        organization: {name: 1},
        is_mr: 1,
      }}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
      header={'Add Inmail Outreach Campaign'}
      submitAction={'Save Setup'}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        experiment_on: getZeroTimeDate(new Date()),
        communication_type: 'Automatic',
        name: searchValue,
        is_mr: false,
        status: 'In Experiment',
        campaign_type: '64d0fd55ab78dfead48c144b',
      }}
      steps={[
        {
          title: 'About',
          fields: [
            {
              label: 'Target Audience',
              field: 'target_audience',
              type: 'autoComplete',
              api: '/buyerPersonas',
              suggestionField: 'name',
              valueField: 'name',
              size: 'medium',
              fields: {
                name: 1,
                industry: 1,
              },
              required: true,
            },
            {
              label: 'Name',
              field: 'name',
              type: 'text',
              size: 'medium',
              required: true,
            },
            {
              label: 'Ownership',
              fields: [
                {
                  label: 'Owner',
                  field: 'assigned_to',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 'medium',
                  fields: {
                    name: 1,
                    official_email_id: 1,
                    employee_team: 1,
                  },
                  required: true,
                },
                {
                  label: 'Linkedin Profile',
                  field: 'profile_owner',
                  type: 'autoComplete',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  size: 'medium',
                  required: true,
                },
              ],
            },
            {
              fields: [
                {
                  label: 'Channel',
                  field: 'channel',
                  type: 'autoComplete',
                  api: `/channels`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  filter: {
                    _id: {
                      $nin: ['66d2ec16f897151a53daf08b'],
                    },
                  },
                  required: true,
                },
                {
                  label: 'Employee Team',
                  field: 'employee_team',
                  type: 'autoComplete',
                  api: `/employeeteams`,
                  filter: {
                    _id: {
                      $in: [
                        '654c6ebd64126a8c199e9537',
                        '655b1c352722272f13e98953',
                      ],
                    },
                  },
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
              ],
            },
            {
              fields: [
                {
                  type: 'radioGroup',
                  field: 'communication_type',
                  label: 'Communication Type',
                  options: ['Automatic', 'Manual'],
                  dependentFields: ['loaded_prospects', 'loaded_messages'],
                },
              ],
            },
            {
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_mr',
                  label: 'Is MR',
                  size: 6,
                },
              ],
            },
          ],
          saveOnNext: true,
          // onNext: async ({values}) => {
          //   if (values.communication_type == 'Automatic') {
          //     navigation.goBack();
          //   }
          // },
        },
        {
          title: 'Load Prospects',
          render: formProps => (
            <LoadProspectsTable
              {...props}
              formProps={formProps}
              source={'prospects'}
            />
          ),
          onNext: async ({values}) => {
            await invoke({
              uri: '/load-prospects-on-inmail-campaign',
              props: values,
            });
          },
        },
        {
          title: 'Select Message',
          render: formProps => (
            <LoadInmailThemes {...props} formProps={formProps} />
          ),
          onNext: async ({values}) => {
            await invoke({
              uri: '/load-messages-on-inmail-campaign',
              props: values,
            });
          },
        },
        {
          title: 'Assign Sequence',
          fields: [
            {
              render: (_, formProps) => (
                <AssignInmailMessageSequence {...props} formProps={formProps} />
              ),
            },
          ],
          onNext: _ => {
            navigation.goBack();
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditInmailCampaign = props => {
  return (
    <AddInmailCampaign
      mode="edit"
      header={'Inmail Campaign Detail'}
      {...props}
    />
  );
};
