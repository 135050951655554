import {
    Col,
    Row,
    Text,
    TouchableOpacity,
    View,
} from '@unthinkable/react-core-components';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { useStyles, useTheme } from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import { UserAvatarGroup } from '../../../components/avatar/UserAvatar';
import { ComparisonIndicator } from '../../../components/card/ComparisionCard';
import { Tag } from '../../../components/chip/Chip';
import { InlineForm } from '../../../components/form/Form';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFetchData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { CustomerDashboardOverview } from '../../leadGeneration/components/CustomerDashboardTheme';
import { CommunicationListTableCustomerOverview } from '../../leadGeneration/views/CommunicationListTable';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

export const numDifferentiation = value => {
  if (value >= 10000000) {
    value = (value / 10000000).toFixed(2) + ' CR';
  } else if (value >= 100000) {
    value = (value / 100000).toFixed(2) + ' L';
  } else if (value >= 10000) {
    value = (value / 10000).toFixed(2) + ' K';
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(2) + ' K';
  } else if (value > 0) {
    value = value.toFixed(0);
  } else if (value < 0) {
    value = value.toFixed(0);
  }
  return value;
};

function getMonthFromDate(dateString) {
  const date = new Date(dateString);
  const month = date.toLocaleDateString('en-US', {month: 'long'});
  return month;
}

export const CustomerGrowthComponent = ({invoice, mm, current_mm, month}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {detailcardStyle, viewContainer} = theme;
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');
  const colors = useTheme('colors');
  const amt = numDifferentiation(invoice || 0);
  return (
    <View style={{...detailcardStyle}}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Row gap={12}>
            <Text
              style={{
                ...BODY2,
                color: colors.NEUTRAL_HIGH,
              }}>{`${current_mm.toFixed(2)}MM`}</Text>
            <ComparisonIndicator compareValue={mm} value={current_mm} />
            <Text style={{color: colors.NEUTRAL_MEDIUM}}>|</Text>
            <Text style={{...BODY2, color: colors.NEUTRAL_HIGH}}>{amt}</Text>
          </Row>

          <Row style={{alignItems: 'center'}} gap={5}>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_HIGH,

                paddingTop: 5,
              }}>
              Customer Growth
            </Text>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingTop: 5,
              }}>
              ({month})
            </Text>
          </Row>
        </Col>
      </View>
    </View>
    // </TouchableOpacity>
  );
};

export const OverduePendingInvoiceCard = ({value = {}, navigation, diff}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {detailcardStyle, viewContainer} = theme;
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');
  const colors = useTheme('colors');
  const amt = numDifferentiation(value?.base_pending_receipt_amount || void 0);
  if (amt) {
    return (
      <>
        <TouchableOpacity
          style={detailcardStyle}
          onPress={() => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: value?.customer,
            });
          }}>
          <View style={{...viewContainer, borderColor: 'green'}}>
            <Col gap={12}>
              <Text style={{color: 'Red', ...BODY2, fontSize: 16}}>₹{amt}</Text>
              <Row style={{alignItems: 'center'}} gap={5}>
                <Text
                  style={{
                    ...CAPTION_LARGE,
                    color: colors.NEUTRAL_HIGH,
                    fontSize: 13,
                    paddingTop: 5,
                  }}>
                  Overdue Invoices
                </Text>
                <Text
                  style={{
                    ...CAPTION_LARGE,
                    color: colors.NEUTRAL_MEDIUM,
                    paddingTop: 5,
                    fontSize: 13,
                  }}>
                  {`(Max age: ${diff} d)`}
                </Text>
              </Row>
            </Col>
          </View>
        </TouchableOpacity>
      </>
    );
  } else {
    return (
      <>
        <View style={detailcardStyle}>
          <View style={{...viewContainer, borderColor: 'green'}}>
            <Col gap={12}>
              <Text style={{color: 'Red', ...BODY2, fontSize: 16}}>₹{amt}</Text>
              <Row style={{alignItems: 'center'}} gap={5}>
                <Text
                  style={{
                    ...CAPTION_LARGE,
                    color: colors.NEUTRAL_HIGH,
                    fontSize: 13,
                    paddingTop: 5,
                  }}>
                  Overdue Invoices
                </Text>
                <Text
                  style={{
                    ...CAPTION_LARGE,
                    color: colors.NEUTRAL_MEDIUM,
                    paddingTop: 5,
                    fontSize: 13,
                  }}>
                  {`(Max aging: ${diff} d)`}
                </Text>
              </Row>
            </Col>
          </View>
        </View>
      </>
    );
  }
};

export const CustomerProjections = ({value, fixed, month}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {detailcardStyle, viewContainer} = theme;
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <View style={{...detailcardStyle}}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Row style={{flex: 1, overflow: 'hidden'}} gap={12}>
            <Row gap={6}>
              <Text style={{...BODY2, fontSize: 16}}>{`${
                value ? value.toFixed(2) : 0
              }`}</Text>
              <Text
                style={{
                  ...BODY2,
                  color: colors.NEUTRAL_MEDIUM,
                  fontSize: 16,
                }}>
                T&M
              </Text>
            </Row>

            <Text style={{color: colors.NEUTRAL_MEDIUM}}>|</Text>
            <Row gap={6}>
              <Text style={{...BODY2, color: colors.NEUTRAL_HIGH}}>{`${
                fixed?.target_hours ? (fixed?.target_hours).toFixed(2) : 0
              }`}</Text>
              <Text
                style={{
                  ...BODY2,
                  color: colors.NEUTRAL_MEDIUM,
                  fontSize: 16,
                }}>
                Fixed
              </Text>
            </Row>
          </Row>

          <Row
            style={{alignItems: 'center', flex: 1, overflow: 'hidden'}}
            gap={5}>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_HIGH,
                fontSize: 13,
                paddingTop: 5,
              }}>
              Projections(MM)
            </Text>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingTop: 5,
                fontSize: 13,
              }}>
              ({month})
            </Text>
          </Row>
        </Col>
      </View>
    </View>
  );
};

export const CustomerActiveProjectCard = ({value, month}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {cardStyle, viewContainer} = theme;
  const {CAPTION_LARGE} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <View style={{...cardStyle}}>
      <View style={viewContainer}>
        <Col gap={12}>
          <Row style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text style={{...CAPTION_LARGE, fontSize: 16}}>
              {value?.project_length}
            </Text>
            <UserAvatarGroup value={value?.employee_data} />
          </Row>
          <Row style={{alignItems: 'center'}} gap={5}>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_HIGH,
                fontSize: 13,
                paddingTop: 5,
              }}>
              Active Projects
            </Text>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingTop: 5,
                fontSize: 13,
              }}>
              ({month})
            </Text>
          </Row>
        </Col>
      </View>
    </View>
  );
};

export const CustomerChannelCard = ({channel, campaign, created_on}) => {
  const theme = useStyles(CustomerDashboardOverview);
  const {detailcardStyle, viewContainer} = theme;
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');
  const colors = useTheme('colors');
  return (
    <View style={{...detailcardStyle}}>
      <View style={{...viewContainer, flex: 1, overflow: 'hidden'}}>
        <Col style={{flex: 1, overflow: 'hidden'}}>
          <Row
            style={{alignItems: 'center', flex: 1, overflow: 'hidden'}}
            gap={12}>
            <Tag value={channel?.label} color={channel?.color} />
            <Tag value={campaign?.label} color={campaign?.color} />
          </Row>

          <Row
            style={{
              alignItems: 'flex-end',
              flex: 1,
              overflow: 'hidden',
              justifyContent: 'flex-start',
            }}
            gap={5}>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_HIGH,
                fontSize: 13,
              }}>
              Source
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                fontSize: 13,
              }}>
              {`Converted On ${moment(created_on).format('DD MMM, YY')}`}
            </Text>
          </Row>
        </Col>
      </View>
    </View>
  );
};

const BasicInformationTable = props => {
  const {data} = props;
  return (
    <>
      <View style={{height: 100, paddingBottom: 12}}>
        <Table
          data={data}
          columns={[
            {
              header: 'Company',
              field: 'name',
              type: 'text',
              numberOfLines: 2,
            },
            {
              header: 'Size',
              field: 'employee_count.number',
            },
            {
              header: 'Industry',
              field: 'industry.name',
              colorField: 'industry.color',
              type: 'colorTag',
            },
            {
              header: 'Country',
              field: 'country.name',
              colorField: 'country.color',
              type: 'colorTag',
            },
            {
              header: 'Project',
              field: 'project.name',
            },
            {
              header: 'Revenue',
              field: 'total_revenue_amount',
              type: 'currency',
            },
          ]}
        />
      </View>
      <View
        style={{
          borderWidth: 1,
          borderColor: '#EEEEED',
          borderRadius: 5,
        }}>
        <Text
          style={{padding: 10, color: '#9E9E9E', fontFamily: 'Inter-Regular'}}>
          Customer Description
        </Text>
        <Text
          style={{
            padding: 10,
            color: '#19191A',
            fontSize: '14px',
            fontFamily: 'Inter-Regular',
            lineHeight: '20px',
          }}>
          {data?.[0]?.description}
        </Text>
      </View>
    </>
  );
};

const RelationRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  if (row?.designation) {
    return <Tag value={row?.designation?.name} />;
  } else {
    return void 0;
  }
};

export const CustomerContactTable = props => {
  const {
    route: {params},
  } = props;
  const {data = []} = params;
  return (
    <View style={{paddingTop: 8, paddingBottom: 20, flex: 1}}>
      <Table
        renderHeader={() => {
          return <TableHeader title="Client POCs" />;
        }}
        data={data}
        columns={[
          {
            header: 'Job Title',
            field: 'designation',
          },
          {
            header: 'Name',
            field: 'first_name',
          },
          {
            header: 'Email',
            field: 'email',
          },
          {
            header: 'Phone',
            type: 'text',
            field: 'phone_no',
          },
        ]}
      />
    </View>
  );
};

const customerContactInlineForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Customer',
    uri: '/potentialcustomercontacts',
    ...props,
  });
  const {customer} = params || {};
  const defaultValues = {
    is_internal_poc: true,
    customer: customer,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      eventSourceId={'Customers'}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Relation',
          label: 'Relation',
          field: 'designation_poc',
          type: 'autoComplete',
          api: `/designations`,
          suggestionField: 'name',
          required: true,
        },
        {
          placeholder: 'Employee',
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
        {
          placeholder: 'Phone',
          label: 'Phone',
          type: 'text',
          field: 'phone_no',
        },
      ]}
    />
  );
};

export const CustomerContactTableWithInlineForm = props => {
  const {
    route: {params},
  } = props;
  const {data = []} = params;
  return (
    <View style={{paddingTop: 8, paddingBottom: 20, flex: 1}}>
      {customerContactInlineForm(props)}
      <Table
        data={data}
        eventSourceId="Customer"
        columns={[
          {
            header: 'Relation',
            render: RelationRender,
          },
          {
            header: 'Name',
            render: ({row}) => {
              return (
                <UserAvatarWithNameAndEmail
                  name={row?.employee?.name}
                  color={row?.employee?.color}
                  official_email_id={row?.employee?.official_email_id}
                />
              );
            },
            width: 240,
          },
          {
            header: 'Phone',
            field: 'phone_no',
            type: 'textArea',
          },
        ]}
      />
    </View>
  );
};

export const InternalPOCList = props => {
  const {
    route: {params},
    data,
  } = props;
  const {row = {}} = params;
  return (
    <View style={{paddingTop: 8, paddingBottom: 20, flex: 1}}>
      <Table
        data={data}
        columns={[
          {
            header: 'Relation',
            render: RelationRender,
          },
          {
            header: 'Name',
            render: ({row}) => {
              return (
                <UserAvatarWithNameAndEmail
                  name={row?.employee?.name}
                  color={row?.employee?.color}
                  official_email_id={row?.employee?.official_email_id}
                />
              );
            },
          },

          {
            header: 'Phone',
            field: 'phone_no',
            type: 'text',
            width: 250,
          },
        ]}
      />
    </View>
  );
};

export const CustomerOverview = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  let {data} = useFetchData({
    eventSourceId: ['Customer'],
    api: '/product/activeCustomerDetails',
    params: {
      customer: row?._id,
      period: params?.period,
    },
  });
  if (data && data.length > 0) {
    data = data[0];
  }
  let {data: channelData} = useFetchData({
    eventSourceId: ['Customer'],
    api: '/customer/customer-wise-campaign',
    params: {
      customer: row?._id,
      group: '$campaign_type',
    },
  });

  let {data: campaignData} = useFetchData({
    eventSourceId: ['Customer'],
    api: '/customer/customer-wise-campaign',
    params: {
      customer: row?._id,
      group: '$campaign_id',
    },
  });
  // let {data: projectData} = useFetchData({
  //   api: `/customer/active-projects`,
  //   filter: {
  //     project_status: 'a',
  //     customer: data?._id,
  //   },
  // });

  let {data: tmProjectionData} = useFetchData({
    api: `/customer-overview/tandmprojection`,
    params: {
      period: params?.period,
      customer: data?._id,
    },
  });
  let {data: fixedProjectionData} = useFetchData({
    api: `/ordertargets`,
    filter: {
      from_date: {$gte: params?.period?.from},
      to_date: {$lte: params?.period?.to},
      customer: data?._id,
    },
    only: true,
  });
  const theme = useStyles(CustomerDashboardOverview);
  const colors = useTheme('colors');

  const {containerStyle, viewContainer} = theme;
  if (!data) {
    return null;
  }
  const previousPeriod = {};
  if (params?.period?.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(params?.period?.from).subtract(1, 'M').startOf('M').toDate(),
    );
  }
  const previousMonth = getMonthFromDate(previousPeriod?.from);
  const currentMonth = getMonthFromDate(params?.period?.from);
  return (
    <Col gap={16} style={{flex: 1, overflow: 'hidden'}}>
      <View style={containerStyle}>
        <View style={viewContainer}>
          <Row style={{display: 'flex', justifyContent: 'space-between'}}>
            {/* <TableHeader title={data?.name} /> */}
            <TableHeader title={'Customer Insights'} />
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('customer-description-pop-up', {
                  data,
                });
              }}>
              <Text
                style={{
                  padding: 10,
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: 'Inter-Regular',
                  lineHeight: '22px',
                  color: colors.INFORMATION_HIGH,
                }}>
                View Description
              </Text>
            </TouchableOpacity>
          </Row>
          <Col gap={8}>
            <Row gap={12}>
              <CustomerGrowthComponent
                invoice={data?.revenue_amt?.base_total_invoice_amount || 0}
                mm={data?.total_mm_two_months || 0}
                current_mm={data?.total_mm_one_month || 0}
                month={previousMonth}
              />
              <OverduePendingInvoiceCard
                value={data?.overdue_amt_pending}
                navigation={navigation}
                diff={data.days_difference || 0}
              />
              <CustomerProjections
                value={tmProjectionData}
                fixed={fixedProjectionData}
                month={currentMonth}
              />
              <CustomerChannelCard
                channel={channelData}
                campaign={campaignData}
                created_on={data?.created_on}
              />
              {/* <ActiveProjectsCard /> */}
            </Row>
            {/* <Col gap={10}> */}
            {/* <Row gap={12}> */}
            {/* <CustomerActiveProjectCard
                  value={projectData}
                  month={previousMonth}
                /> */}

            {/* <CustomerInsightCardComponent /> */}
            {/* </Row> */}
            <RowWithSeparator style={{alignItems: 'center'}} gap={12}>
              <Tag
                value={data?.employee_count?.number}
                color={colors.SURFACE2}
              />
              <Tag value={data?.industry?.name} color={data?.industry?.color} />
              <Tag value={data?.country?.name} color={data?.country?.color} />
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('client-poc-detail-customer-overview', {
                    data: data?.contact_detail,
                  });
                }}>
                <Text
                  style={{
                    padding: 10,
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Inter-Regular',
                    lineHeight: '22px',
                    color: colors.INFORMATION_HIGH,
                  }}>
                  {`${data?.contact_detail?.length || 0} Client POCs`}
                </Text>
              </TouchableOpacity>
            </RowWithSeparator>
            {/* </Col> */}
          </Col>
        </View>
      </View>
      <View style={{...containerStyle, padding: 12}}>
        <Row style={{display: 'flex', justifyContent: 'space-between'}}>
          <Text
            style={{
              padding: 10,
              color: '#19191A',
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Inter-Regular',
              lineHeight: '22px',
            }}>
            Internal POCs
          </Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('contact-detail-customer-overview', {
                data: data?.internal_poc,
                customer: data?._id,
              });
            }}>
            <Text
              style={{
                padding: 10,
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: 'Inter-Regular',
                lineHeight: '22px',
                color: colors.INFORMATION_HIGH,
              }}>
              + Add
            </Text>
          </TouchableOpacity>
        </Row>
        <InternalPOCList {...props} data={data?.internal_poc} />
      </View>
      <View style={{...containerStyle, padding: 12}}>
        <Row style={{display: 'flex', justifyContent: 'space-between'}}>
          <Text
            style={{
              padding: 10,
              color: '#19191A',
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'Inter-Regular',
              lineHeight: '22px',
            }}>
            Latest Communication
          </Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('show-communication-customer-overview', {
                row: {_id: data?._id},
                fromCustomer: true,
              });
            }}>
            <Text
              style={{
                padding: 10,
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: 'Inter-Regular',
                lineHeight: '22px',
                color: colors.INFORMATION_HIGH,
              }}>
              View All
            </Text>
          </TouchableOpacity>
        </Row>
        <CommunicationListTableCustomerOverview
          {...props}
          customerOverview={true}
        />
      </View>
    </Col>
  );
};
