import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const RecurringTaskForm = ({
  defaultValues,
  header = 'Add Recurring Task',
  navigation,
  ...props
}) => {
  let {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: 'reloadRecurringTask',
    ...props,
  });

  const {params} = props.route || {};
  const {source, generic_initiative, strategic_initiative} = params;
  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{
        status: 'active',
        type: 'Recurring',
        department: params?.department?._id,
        product: params?.product?._id,
        source,
        source_id: generic_initiative || strategic_initiative,
      }}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Output',
          placeholder: 'Source',
          field: 'source',
          type: 'cascader',
          cascades: [
            {
              label: 'Output',
              field: 'source',
              api: '/types',
              fields: {name: 1, display_field: 1},
              filter: {
                departments: params?.department?._id,
                is_show: true,
              },
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              label: 'Source Value',
              placeholder: 'Source Id',
              field: 'source_id',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                navigation,
                params,
              }),
            },
          ],
        },
        {
          label: 'Recurring Task',
          placeholder: 'Recurring Task',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Description',
          placeholder: 'Description',
          field: 'description',
          type: 'textArea',
        },
        {
          label: 'Estimate Hours',
          placeholder: 'Estimate Hours',
          field: 'est_hours',
          type: 'number',
        },
        {
          label: 'Assigned To',
          placeholder: 'Assigned To',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
        },
        {
          label: 'Frequency',
          field: 'frequency_mode',
          type: 'autoComplete',
          options: [
            'Daily',
            'Weekly',
            'Biweekly',
            'Monthly',
            'Quarterly',
            'Yearly',
          ],
          // chip: true,
        },
        {
          label: 'Start Date',
          field: 'start_date',
          type: 'date',
        },
        {
          label: 'Due Days',
          field: 'due_days',
          type: 'number',
        },
      ]}
      {...props}
    />
  );
};

export const EditRecurringTaskForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <RecurringTaskForm
      header={'Edit RecurringTask'}
      mode="edit"
      api={`/department/${params?.department?._id}/tasks`}
      filter={{
        _id: params?.row?._id,
      }}
      {...props}
    />
  );
};
