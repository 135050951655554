import {useNavigation} from '@react-navigation/native';
import {useCallback} from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';

export const useTaskSourceDynamicAddForm = ({route: {params}}) => {
  const navigation = useNavigation();

  return useCallback(
    ({row, params: rowParams}) => {
      let newParams = {
        ...rowParams,
        ...params,
        type: row,
      };
      let source = rowParams?.source?.name || '';
      source = source?.toLowerCase();
      navigation.navigate(`add-${source}`, {
        ...newParams,
      });
    },
    [navigation, params],
  );
};

export const useEditKpis = props => {
  const {params, navigation} = props;
  return useCallback(
    ({row}) => {
      let newParams = {
        ...params,
        row: row,
      };
      navigation.navigate(`edit-kpis`, {
        ...newParams,
      });
    },
    [params, navigation],
  );
};

export const useMarkStrategicInitiativeInactive = props => {
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'reloadStrategicInitiative',
  });
  return useCallback(
    ({row}) => {
      invoke({
        uri: `/strategicinitiatives/` + row?._id,
        props: {
          status: 'Inactive',
        },
      });
    },
    [invoke],
  );
};

export const useShowStrategicInvitees = props => {
  const {navigation, params} = props;
  return useCallback(
    ({row}) => {
      navigation.navigate('show-invitees-on-strategic-initiative', {
        ...params,
        row: row,
      });
    },
    [navigation, params],
  );
};

export const useJoinStrategicInitiative = props => {
  const {navigation, params} = props;
  return useCallback(
    ({row}) => {
      navigation.navigate('join-strategic-initiative', {
        ...params,
        row: row,
      });
    },
    [navigation, params],
  );
};

export const useRejectStrategicInvitee = props => {
  const {post} = useAppStateContext();
  const {
    route: {params},
  } = props;
  const {row: strategic_initiative} = params;
  return useCallback(
    ({row}) => {
      post({
        method: 'put',
        uri: `/rejectStrategicInitiatives`,
        props: {
          strategic_initiative,
          resource_interest: row,
        },
        eventSourceId: 'reloadStrategicInitiatives',
      });
    },
    [post, params],
  );
};
