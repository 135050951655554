import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';

export const ExtendTrainingForm = props => {
  let invoke = useInvoke({
    method: 'post',
    eventSourceId: 'EmployeeTrainings',
  });
  const {
    route: {params},
  } = props;
  let {employee_training = {}} = params;
  let {employee} = employee_training || {};
  return (
    <Form
      header={`Extend Course (${employee?.name ?? ''})`}
      submitAction="Extend"
      defaultValues={{
        ...employee_training,
        status: 'extended',
      }}
      onSubmit={data => {
        invoke({uri: '/employeeTrainings/extended-details', props: {data}});
      }}
      layoutFields={[
        {
          label: 'Original End Date',
          field: 'to_date',
          type: 'date',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Extended End Date',
          field: 'extended_date',
          type: 'date',
          size: 6,
        },
        {
          label: 'Reason of Extension',
          field: 'reason_of_extension',
          type: 'textArea',
        },
      ]}
      {...props}
    />
  );
};
