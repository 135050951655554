import React from 'react';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';
import {AddButton} from '../../../components/button/AddButton';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {getCurrentMonth} from '@unthinkable/react-filters';

export const NonEngineerEmployeeTeamList = props => {
  const {params, navigation, fromMyView, user, searchValue, filterValues} =
    props;
  let filter = {};
  const {params: dateParams = {}} = filterValues;
  if (fromMyView) {
    filter = {
      performance_manager: user?.employee?._id,
    };
  }
  const {period} = params || {};
  const {from: start_date, to: end_date} = period || {};
  params.period = dateParams.period;
  return (
    <Table
      params={params}
      addOnFilter={{...filter}}
      eventSourceId={['EmployeeTeam']}
      search={searchValue}
      searchFields="name"
      api={`/employees/nonenggteams`}
      columns={[
        {
          header: 'Name',
          field: 'name',
          minWidth: 200,
        },
        {
          render: CountCellRender,
          count_field: 'resource_count',
          width: 100,
          header: 'Team size',
          field: 'resource_count',
          aggregate: true,
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate(`resource-allocations-list`, {
              employee_team: {_id: row?._id, name: row.name},
              fromNonProject: true,
              fromReport: true,
              date: dateParams,
              api: '/employees/nonenggreportdetail',
              employeeFilter: {
                from_date: {$lte: end_date},
                assigned_type: 'Non-Billable',
                team_id: row?._id,
                $or: [
                  {to_date: {$gte: start_date}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
          width: 100,
        },
        {
          header: 'Performance Manager',
          type: 'userAvatarGroup',
          field: 'performance_manager',
          width: 200,
        },
        {
          render: CountCellRender,
          count_field: 'avg_experience',
          header: 'Avg Exp',
          align: 'right',
          width: 100,
        },
        {
          count_field: 'salary',
          aggregate: true,
          header: 'Total Cost',
          field: 'salary',
          type: 'currency',
          width: 150,
          align: 'center',
        },
        {
          header: 'Avg cost/person',
          field: 'avg_cost',
          type: 'currency',
          width: 150,
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-employee-team', {row});
          },
        },
      ]}
      {...props}
    />
  );
};

export const NonEnggTeamReport = props => {
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: getCurrentMonth(),
  });

  const {params} = filterValues;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();

  return (
    <>
      <TableHeader
        title={'Non Engg. Team Report'}
        actions={[
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
            placeholder="Search"
          />,
          <IconButton
            icon={Download}
            onPress={() => {
              download({
                uri: '/exportNonEnggTeamReport',
                props: {
                  uri: '/employees/nonenggteams',
                  parameters: {
                    params,
                  },
                },
              });
            }}
          />,
          !props?.fromMyView && (
            <AddButton {...props} title={'Team'} view="add-employee-team" />
          ),
        ]}
      />
      <NonEngineerEmployeeTeamList
        searchValue={searchValue}
        params={{...params}}
        filterValues={filterValues}
        {...props}
      />
    </>
  );
};
