import React, {useState} from 'react';
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';
import {Button} from '@unthinkable/react-button';
import {useGroupFilter} from './hooks/useGroupFilter';
import FilterIcon from '../images/Filter.svg';
import CrossIcon from '../images/Cross.svg';

const getSelectedFilterCount = ({filters, filter, params}) => {
  let count = 0;
  filters?.map(value => {
    const {field, asParam} = value;
    if (asParam && params?.[field] !== undefined && params?.[field] !== null) {
      count++;
    } else if (filter?.[field] !== undefined && filter?.[field] !== null) {
      count++;
    }
  });

  return count;
};

const RenderFilters = ({
  styles,
  filters,
  filterValues: filterValuesProp,
  applyFilter,
  hide,
  filterTypes,
  title = 'Filters',
}) => {
  const {onChangeFilter, filterValues} = useGroupFilter({
    filterValues: filterValuesProp,
  });
  const {params: groupParamsState = {}, filter: groupFilterState = {}} =
    filterValues || {};

  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const onReset = () => {
    let modifiedFilters = {...groupFilterState};
    let modifiedParamValues = {...groupParamsState};
    filters?.map(_filter => {
      if (_filter.asParam) {
        delete modifiedParamValues[_filter.field];
      } else {
        delete modifiedFilters[_filter.field];
      }
    });
    applyFilter({
      filterValues: {filter: modifiedFilters, params: modifiedParamValues},
    });
    hide();
  };

  const onApply = () => {
    applyFilter({filterValues});
    hide();
  };

  const onClose = () => {
    filters?.map(_filter => {
      onChangeFilter({..._filter, value: void 0});
    });
    hide();
  };

  const {
    headerContainerStyle,
    titleStyle,
    crossIcon,
    iconContainerStyle,
    iconStyle,
    tabContainerStyle,
    tabStyle,
    tabCrossIcon,
    tabCrossIconStyle,
    selectedTabStyle,
    labelStyle,
    selectedLabelStyle,
    selectedIndicatorStyle,
    filterContainerStyle,
    footerContainerStyle,
  } = styles;

  let {type, label, ...selectedFilter} = filters[selectedFilterIndex];
  let FilterComponent = type ? filterTypes[type] : void 0;

  let count = getSelectedFilterCount({
    filters,
    ...filterValuesProp,
  });

  const isFilterAvailable = ({asParam, field}) => {
    if (asParam) {
      return (
        groupParamsState?.[field] !== undefined &&
        groupParamsState?.[field] !== null
      );
    } else {
      return (
        groupFilterState?.[field] !== undefined &&
        groupFilterState?.[field] !== null
      );
    }
  };

  return (
    <>
      <View style={headerContainerStyle}>
        <Text style={titleStyle}>{title}</Text>
        <TouchableOpacity style={iconContainerStyle} onPress={onClose}>
          <Image source={crossIcon || CrossIcon} style={iconStyle} />
        </TouchableOpacity>
      </View>
      <Row style={{flex: 1, overflow: 'hidden'}}>
        <View style={tabContainerStyle}>
          <ScrollView>
            {filters.map((filterInfo, index) => {
              let hasValue = isFilterAvailable(filterInfo);
              return (
                <Row
                  onPress={() => {
                    setSelectedFilterIndex(index);
                  }}
                  gap={5}
                  style={[
                    {cursor: 'pointer'},
                    tabStyle,
                    selectedFilterIndex === index ? selectedTabStyle : {},
                  ]}>
                  <Text
                    style={[
                      labelStyle,
                      selectedFilterIndex === index ? selectedLabelStyle : {},
                    ]}>
                    {filterInfo.label}
                  </Text>
                  {hasValue ? <View style={selectedIndicatorStyle} /> : void 0}
                  {hasValue ? (
                    <TouchableOpacity
                      style={{padding: 8, margin: -8}}
                      onPress={() => {
                        onChangeFilter({...filterInfo, value: void 0});
                      }}>
                      <Image
                        source={tabCrossIcon || CrossIcon}
                        style={tabCrossIconStyle}
                      />
                    </TouchableOpacity>
                  ) : (
                    void 0
                  )}
                </Row>
              );
            })}
          </ScrollView>
        </View>
        <View style={filterContainerStyle}>
          {FilterComponent ? (
            <FilterComponent
              key={selectedFilterIndex}
              filterValues={filterValues}
              onChangeFilter={onChangeFilter}
              {...selectedFilter}
            />
          ) : (
            <Text>filter not registered</Text>
          )}
        </View>
      </Row>
      <Row style={footerContainerStyle} gap={10}>
        <Button
          disabled={count === 0}
          text="Reset"
          onPress={onReset}
          styles={styles?.resetButton}
        />
        <Button text="Apply" onPress={onApply} styles={styles?.applyButton} />
      </Row>
    </>
  );
};

export const GroupFilter = ({
  filterValues,
  applyFilter,
  filters,
  styles,
  filterTypes,
  modalPosition,
  children,
  title,
}) => {
  const {buttonContainer, buttonIcon, filterCountText} = styles;

  filters = filters?.filter(filter => (filter ? true : false));

  if (!filters?.length) {
    return null;
  }

  let count = getSelectedFilterCount({
    filters,
    ...filterValues,
  });

  if (children) {
    if (typeof children === 'function') {
      children = children({count});
    }
  } else {
    children = (
      <Row gap={10} style={buttonContainer}>
        <Image source={FilterIcon} style={buttonIcon} />
        <Text style={filterCountText}>
          Filters{count > 0 ? ` (${count})` : ''}
        </Text>
      </Row>
    );
  }

  return (
    <WithModal
      placement="start"
      styles={styles?.modal}
      position={modalPosition}
      autoHide={false}
      renderModal={({hide}) => {
        return (
          <RenderFilters
            styles={styles}
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={filters}
            hide={hide}
            filterTypes={filterTypes}
            title={title}
          />
        );
      }}>
      {children}
    </WithModal>
  );
};
