import React from 'react';
import {Table} from '../../../components/table/Table';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {randomColor} from '../../../utils/util';
import {Tag} from '../../../components/chip/Chip';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useTheme} from '@unthinkable/react-theme';
import {AmountRender} from '../../order/views/components/AmountRender';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {IconButton} from '../../../components/button/IconButton';
import {useAppStateContext} from '../../../providers/AppState';
import {Efficiency} from '../../../components/efficiency/Efficiency';
import {IconWithBackground} from '../../../components/icons/IconWithBackground';

const DURATION = {
  header: 'Duration',
  width: 180,
  align: 'center',
  render: ({row, styles}) => {
    return (
      <Text style={{...styles.rowText, color: '#737373'}}>
        {row.project_start_date &&
          moment(row.project_start_date).format('DD MMM YY')}
        -
        {row.project_end_date &&
          moment(row.project_end_date).format('DD MMM YY')}
      </Text>
    );
  },
};

const CSM = {
  header: 'CSM/PM',
  field: 'owner_id',
  type: 'userAvatar',
  align: 'center',
  width: 80,
};

const AMOUNT = {
  header: 'Total Value (₹)',
  type: 'currency',
  aggregate: true,
  align: 'right',
  field: 'amount',
};

const TYPE = {
  header: 'Type',
  render: ({row}) => {
    let projectType = '';
    if (row?.new_project_type == 'staffaugmentation') {
      projectType = 'Staff Augmentation';
    } else if (row?.new_project_type == 'managedteam') {
      projectType = 'Managed Team';
    } else if (row?.new_project_type == 'sdlc') {
      projectType = 'SDLC';
    } else if (row?.new_project_type == 'sdlcplus') {
      projectType = 'SDLC+';
    } else if (row?.projecttype == 'i') {
      projectType = 'Internal';
    }
    return <Tag value={projectType} color={randomColor()} />;
  },
};

const ORDERENGG = {
  render: CountCellRender,
  count_field: 'orderEngg',
  // released_count_field: 'totalPotential',
  type: 'chip',
  header: 'Order Engg.',
  aggregate: true,
  field: 'orderEngg',
  align: 'right',
  width: 130,
  formatOptions: {
    maximumFractionDigits: 2,
  },
};

const project = () => {
  return {
    header: 'Project',
    minWidth: 300,
    render: ({row}) => {
      let icon;
      let color = 'BRAND_UPPER_LOW';

      if (row.tool === 'Locomo') {
        icon = 'LocomoTool';
      } else if (row.tool === 'Jira') {
        icon = 'JiraIcon';
        color = 'SECONDARY_MEDIUM';
      } else {
        icon = 'OtherTool';
      }

      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <IconWithBackground icon={icon} color={color} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project}
            items={[
              {
                value: row?.code,
              },
              {
                value: `${
                  row?.project_start_date
                    ? moment(row?.project_start_date).format('DD MMM YY')
                    : ''
                } - ${
                  row?.project_end_date
                    ? moment(row?.project_end_date).format('DD MMM YY')
                    : ''
                }`,
              },
            ]}
          />
        </Row>
      );
    },
  };
};

const ACTIVE_SINCE = {
  header: 'Since',
  field: 'createdAt',
  type: 'date',
  formatOptions: {format: 'DD MMM YY'},
  width: 120,
};

const CUSTOMER = {
  header: 'Customer',
  width: 200,
  render: ({row, styles: {rowText = {}}}) => {
    return <Text style={{...rowText}}>{row?.customer?.name}</Text>;
  },
};

const teamAllocation = ({tabName, navigation}) => {
  return {
    render: CountCellRender,
    count_field: 'orderEngg',
    type: 'chip',
    header: 'Allocated',
    aggregate: true,
    field: 'team',
    align: 'right',
    width: 100,
  };
};

const lastEfficiency = ({tabName}) => {
  return {
    header: 'Eff.(Last week)',
    width: 140,
    align: 'right',
    render: ({row, styles}) => {
      return <Efficiency value={row?.efficiency_percentage} />;
    },
  };
};

const totalPotential = () => {
  return {
    header: 'Total Pot.',
    aggregate: true,
    field: 'totalFixedProjectPotential',
    type: 'number',
    render: ({row, styles: {rowText = {}}}) => {
      return (
        <Text
          style={{
            ...rowText,
          }}>
          {row?.totalFixedProjectPotential &&
            `${row?.totalFixedProjectPotential} MM`}
        </Text>
      );
    },
  };
};

const remainingPotential = () => {
  return {
    header: 'Remaining Pot.',
    aggregate: true,
    field: 'remaining_hours',
    width: 140,
    type: 'number',
    render: ({row, styles: {rowText = {}}}) => {
      return (
        <Text
          style={{
            ...rowText,
          }}>
          {row?.remaining_hours && `${row?.remaining_hours} MM `}
        </Text>
      );
    },
  };
};

export const NewTMProjectTable = props => {
  let {
    route: {params},
    addOnFilter,
    navigation,
    searchValue,
    addOnParams,
  } = props;
  return (
    <Table
      eventSourceId={['performance-report']}
      api={'/newBilledProject'}
      params={{...params, ...addOnParams}}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      search={searchValue}
      searchFields="project"
      limit={5000}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        ACTIVE_SINCE,
        project({navigation}),
        CUSTOMER,
        TYPE,
        CSM,
        ORDERENGG,
      ]}
    />
  );
};

export const ClientFeedbackProjectOverviewTable = props => {
  const {filterValues, navigation} = props;
  return (
    <Table
      eventSourceId={['performance-report']}
      api={'/getNoClientFeedbackProjects'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      searchFields="project"
      limit={5000}
      columns={[
        project({navigation}),
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'text',
          minWidth: 250,
        },
        TYPE,
        {
          header: 'AM',
          type: 'userAvatarChip',
          field: 'account_owner',
        },
        {
          header: 'PM',
          type: 'userAvatarChip',
          field: 'owner_id',
        },
      ]}
      {...props}
      {...filterValues}
    />
  );
};

export const ClientFeedbackProjectOverview = props => {
  const {
    route: {params},
  } = props;
  const {filter, period} = params;

  const {filterValues} = useFilter({
    params: {
      period,
    },
    filter,
  });

  const tabs = {
    project: {
      label: 'Project',
      view: (
        <ClientFeedbackProjectOverviewTable
          filterValues={filterValues}
          api={'/allFeedbackProjects'}
          fields={{
            project: 1,
            code: 1,
            projecttype: 1,
            project_start_date: 1,
            project_end_date: 1,
            account_owner: {
              name: 1,
              color: 1,
            },
            owner_id: {
              name: 1,
              color: 1,
            },
            tools: 1,
            order_number: 1,
            project_sub_category: 1,
            new_project_type: 1,
            customer: {
              name: 1,
            },
          }}
        />
      ),
      api: '/allFeedbackProjects',
      ...filterValues,
      eventSourceId: 'performance-report',
    },
  };
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <TabView
      {...props}
      actions={[
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {api: '/projects', ...filterValues},
                  column: {
                    Project: 'project',
                    Type: 'projecttype',
                    'Start Date': 'project_start_date',
                    'End Date': 'project_end_date',
                    'Account Owner': 'account_owner.name',
                    'Project Manager': 'owner_id.name',
                    Tools: 'tools',
                    'Order Number': 'order_number',
                    'Sub Category': 'project_sub_category',
                  },
                },
              },
            });
          }}
        />,
      ]}
      tabs={tabs}
    />
  );
};

export const NoClientFeedbackProjects = props => {
  const {
    route: {params},
  } = props;
  const {filter, period} = params;

  const {filterValues} = useFilter({
    params: {
      period,
    },
    filter,
  });

  const tabs = {
    project: {
      label: 'Project',
      view: <ClientFeedbackProjectOverviewTable filterValues={filterValues} />,
      api: '/getNoClientFeedbackProjects',
      ...filterValues,
      eventSourceId: 'performance-report',
    },
  };

  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <TabView
      {...props}
      tabs={tabs}
      actions={[
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/getNoClientFeedbackProjects',
                    ...filterValues,
                  },
                  column: {
                    Project: 'project',
                    Type: 'projecttype',
                    'Start Date': 'project_start_date',
                    'End Date': 'project_end_date',
                    'Account Owner': 'account_owner.name',
                    'Project Manager': 'owner_id.name',
                    Tools: 'tools',
                    'Order Number': 'order_number',
                    'Sub Category': 'project_sub_category',
                  },
                },
              },
            });
          }}
        />,
      ]}
    />
  );
};

export const NewFixedProjectTable = props => {
  let {
    route: {params},
    navigation,
    searchValue,
    addOnFilter,
    addOnParams,
    tabName,
  } = props;
  return (
    <Table
      api={'/newBilledProject'}
      params={{...params, ...addOnParams}}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      addOnFilter={{
        ...addOnFilter,
      }}
      eventSourceId="fixed"
      search={searchValue}
      searchFields="project"
      limit={5000}
      columns={[
        ACTIVE_SINCE,
        project({navigation}),
        CUSTOMER,
        CSM,
        TYPE,
        totalPotential(),
        remainingPotential(),
        // ORDERENGG,
        // DURATION,
      ]}
    />
  );
};

export const NewProjectUnBilledTable = props => {
  let {
    route: {params},
    navigation,
    searchValue,
    addOnFilter,
    addOnParams,
    tabName,
  } = props;
  return (
    <Table
      api={'/newBilledProject'}
      params={{...params, ...addOnParams}}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      addOnFilter={{
        ...addOnFilter,
      }}
      eventSourceId="fixed"
      search={searchValue}
      searchFields="project"
      limit={5000}
      columns={[
        project({navigation}),
        {
          header: 'For',
          field: 'customer.name',
        },
        {
          header: 'Category',
          field: 'project_category',
          type: 'colorTag',
          width: 150,
          colorField: randomColor,
        },
        DURATION,
        {
          header: 'Hours',
          field: 'totalPotential',
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.totalPotential && `${row?.totalPotential} MM`}
              </Text>
            );
          },
        },
        {
          header: 'Man Month Estimate',
          type: 'number',
          width: 150,
          align: 'center',
          render: ({row, styles}) => {
            const {
              is_estimate_revised,
              estimate_revision_history,
              man_month_estimate,
            } = row || {};
            let NewEstimate = 0;
            if (is_estimate_revised) {
              NewEstimate =
                estimate_revision_history[estimate_revision_history.length - 1]
                  ?.new_estimate;
            } else {
              NewEstimate = man_month_estimate;
            }
            return <Text style={{...styles.rowText}}>{NewEstimate}</Text>;
          },
        },
        CSM,
        teamAllocation({tabName, navigation}),
        lastEfficiency({tabName}),
      ]}
    />
  );
};
