import React from 'react';
import {Table} from '../../../../components/table/Table';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {AssetColumns, assetFilters} from './AssetUtility';
import {ThirdPartyIntegrationDetailForm} from './ThirdPartyIntegrationForm';
import {SplitScreen} from '../../../../components/SplitScreen';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID} from '../../../common/constants/SourceConstants';
import {TabView} from '../../../../components/tab';
import {AllThirdPartyIntegrationScreen} from './FeatureThirdPartyIntegrationScreen';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupBy} from '../../../../components/table/GroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';

const ThirdPartyIntegrationHeader = ({
  params,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  selectedIds,
  excelParams,
  groupBy,
}) => {
  const {project, module_id} = params;
  const filters = assetFilters({project});

  return (
    <TableHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[!module_id && filters.module, filters.owner]}
        />,
        !module_id && <GroupBy {...groupBy} />,
        <ExportExcelAction selectedIds={selectedIds} params={excelParams} />,
      ]}
    />
  );
};

export const thirdPartyIntergrationExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';

  return {
    addOnFilter,
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: 'ThirdParty Integration',
      columns: [
        {header: 'Name', key: 'name', width: 15},
        {header: 'Module', key: 'module_id.module', width: 15},
        {header: 'Owner', key: 'owner_id.name', width: 20},
        {header: 'Description', key: 'desc', width: 40},
        {header: 'Remarks', key: remarksKey, width: 50, isHtml: true},
      ],
    },
  };
};

export const ThirdPartyIntegrationTable = props => {
  const {
    navigation,
    route: {params},
    status,
    selectedId,
  } = props;
  const {groupBy, ...restProps} = props;

  const {
    assetDetail,
    mergeReferences,
    changeModule,
    deleteAsset,
    deleteMultipleAssets,
    versions,
    updateModuleMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({
    navigation,
    params,
    api: '/projectThirdPartyIntegrations',
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  });

  const {allowDynamicGrouping} = groupBy || {};

  return (
    <Table
      {...restProps}
      allowDynamicGrouping={allowDynamicGrouping}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId={['ThirdPartyIntegration', 'MapUserstory']}
      columns={[
        !allowDynamicGrouping && assetColumns.module,
        assetColumns.integration,
        !selectedId && assetColumns.userstory_count,
        !selectedId && assetColumns.owner,
      ]}
      selection={{
        actions: [updateModuleMultipleAssets, deleteMultipleAssets],
      }}
      onRowPress={assetDetail}
      moreActions={() => [
        versions,
        changeModule,
        mergeReferences({
          targetApi: '/projectThirdPartyIntegrations',
        }),
        deleteAsset,
      ]}
    />
  );
};

export const ThirdPartyIntegrationSplitScreen = props => {
  const {
    route: {params},
    groupBy,
  } = props;

  const filterProps = useFilter();
  const {project, module_id} = params;
  const {searchValue, filterValues} = filterProps;

  const tableProps = {
    api: `/projectthirdpartyintegrations/released`,
    addOnFilter: {
      project_id: project?._id,
      module_id,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'name',
  };

  const renderHeader = props => {
    return (
      <ThirdPartyIntegrationHeader
        {...props}
        {...filterProps}
        params={params}
        groupBy={groupBy}
        excelParams={thirdPartyIntergrationExportParams(tableProps)}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<ThirdPartyIntegrationDetailForm {...props} />}>
      <ThirdPartyIntegrationTable
        {...props}
        groupBy={groupBy}
        renderHeader={renderHeader}
        {...tableProps}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const ThirdPartyIntegrationsTab = props => {
  const {route: {params} = {}} = props;
  const {project, module} = params;

  let groupBy = useGroupBy({
    defaultSelected: module?._id ? void 0 : 'module_id',
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          exclusiveExpand: true,
          defaultExpandedIndex: 0,
          leftContent: ({row}) => {
            const moduleName = row?.module || 'No module';
            return <GroupContentItem value={moduleName + ` (${row.count})`} />;
          },
        },
      },
    ],
  });

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: (
            <ThirdPartyIntegrationSplitScreen {...props} groupBy={groupBy} />
          ),
          api: '/projectthirdpartyintegrations',
          filter: {
            status: 'released',
            project_id: project?._id,
            module_id: module?._id,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllThirdPartyIntegrationScreen
              groupBy={groupBy}
              api={`/projects/${project?._id}/asset/${PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID}/all-data`}
              {...props}
            />
          ),
          params: {module_id: module?._id},
          api: `/projects/${project?._id}/asset/${PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID}/in-development/count`,
        },
      }}
    />
  );
};
