import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {ZoomImage} from './ZoomImage';
import {Pagination} from './Pagination';
import {OpenInBrowseButton} from './OpenInBrowseButton';
import {ZoomInAndOut} from './ZoomInAndOut';
import {FileRender} from './FileRender';

export const ImageView = ({
  imageContainerStyle,
  deviceTypeFileNameTextStyle,
  centerContainerStyle,
  imageSource,
  resourceUrl,
  openInBrowserAbsoluteContainerStyle,
  VideoContentTypeIcon,
  ApplicationContentTypeIcon,
  ImageContentTypeIcon,
  ZoomImageStyle,
  ...props
}) => {
  const url = resourceUrl(imageSource);

  return (
    <View style={{flex: 1, overflow: 'hidden'}}>
      <ZoomImage
        ZoomComponent={props => {
          return <ZoomInAndOut {...props} ZoomImageStyle={ZoomImageStyle} />;
        }}>
        <View style={[{overflow: 'hidden'}, centerContainerStyle]}>
          <FileRender
            value={imageSource}
            resourceUrl={resourceUrl}
            containerStyle={imageContainerStyle}
            textStyle={deviceTypeFileNameTextStyle}
            openInNewTab={false}
            defaultContentTypeIcons={{
              application: ApplicationContentTypeIcon,
              video: VideoContentTypeIcon,
              image: ImageContentTypeIcon,
            }}
          />
        </View>
      </ZoomImage>
      <Pagination {...props} />
      <OpenInBrowseButton
        url={url}
        openInBrowserAbsoluteContainerStyle={
          openInBrowserAbsoluteContainerStyle
        }
        {...props}
      />
    </View>
  );
};
