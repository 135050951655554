import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const OfferJobForm = props => {
  const {
    route: {params},
  } = props;
  const {candidate} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  return (
    <Form
      header={{
        title: 'Offer Job',
        secondaryTitle: candidate?.name,
      }}
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          status: 'Offered',
        },
      })}
      onSubmit={onSubmit}
      mode="edit"
      api={'/candidates/' + candidate?._id}
      fields={{
        hr_round_evaluation: 1,
        hr_round_remarks: 1,
        joining_mode: 1,
        offer_raised_date: 1,
        designation: {
          name: 1,
        },
        candidate_manager: {
          name: 1,
        },
        status: 1,
        organization: {
          name: 1,
        },
        tentative_joining_date: 1,
        working_days: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'offer_raised_date',
          label: 'Offer Raised Date',
          size: 'medium',
        },
        {
          field: 'joining_mode',
          label: 'Mode of Joining',
          type: 'autoComplete',
          options: ['Work From Home', 'Work From Office', 'Hybrid'],
          size: 'medium',
        },
        {
          field: 'organization',
          label: 'Organization',
          type: 'autoComplete',
          api: '/organizations',
          suggestionField: 'name',
          size: 'medium',
        },
        {
          field: 'designation',
          label: 'Designation',
          type: 'autoComplete',
          api: '/designations',
          suggestionField: 'name',
          size: 'medium',
        },
        {
          field: 'candidate_manager',
          label: 'Reporting Manager',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 'medium',
        },
        {
          type: 'date',
          field: 'tentative_joining_date',
          label: 'Tentative Joining Date',
          size: 'medium',
        },
        {
          type: 'number',
          field: 'working_days',
          label: 'Working Days',
          size: 'medium',
        },
      ]}
    />
  );
};
