import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {PROJECT_MILESTONE_TYPE_ID} from '../../common/constants/SourceConstants';
import {ComprehensiveTasks} from '../../task/views/TasksTable';
import {ProjectReleasedFeaturesTable} from './FeaturesTable';
import {ComprehensiveTaskTab} from './ComprehensiveTaskTab';
import {RoadmapTabs} from './RoadmapTabs';
import getPmtTooltip from '../constants/PmtTooltip';
import {Labels} from '../constants/Label';
export const MilestoneMenu = props => {
  const {route: {params} = {}} = props;
  const {milestone} = params;
  const PmtTooltip = getPmtTooltip({params});
  const menus = [
    {
      label: Labels.Features,
      view: (
        <RoadmapTabs addOnFilter={{milestone_id: milestone?._id}} {...params} />
      ),
      api: '/projectfeatures',
      filter: {
        milestone_id: milestone?._id,
        status: {$in: ['backlog', 'active']},
      },
      eventSourceId: 'Feature',
      tooltip: PmtTooltip.FEATURES,
    },
    {
      label: 'Tasks',
      view: (
        <ComprehensiveTaskTab {...params} typeId={PROJECT_MILESTONE_TYPE_ID} />
      ),
      tooltip: PmtTooltip.TASKS,
    },
  ];

  const completedMilestoneMenus = [
    {
      label: Labels.Features,
      view: (
        <ProjectReleasedFeaturesTable
          showHeader
          filter={{milestone_id: milestone?._id}}
        />
      ),
      api: '/projectfeatures',
      filter: {
        milestone_id: milestone?._id,
        status: 'released',
      },
      tooltip: PmtTooltip.FEATURES,
    },
    {
      label: 'Tasks',
      view: (
        <ComprehensiveTasks
          typeId={PROJECT_MILESTONE_TYPE_ID}
          title={'Completed Tasks'}
          showHeader
          addOnParams={{status: 'completed'}}
          {...params}
        />
      ),
      tooltip: PmtTooltip.TASKS,
    },
  ];

  return (
    <LeftMenuView
      {...props}
      params={params}
      menus={
        milestone?.status === 'completed' ? completedMilestoneMenus : menus
      }
    />
  );
};
