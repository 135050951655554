import moment from 'moment';
import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';

export const appraisalController = props => {
    console.log("props>>>>>>>>>>>>", props)
    const { params, navigation, tab } = props;
    const { post, user } = useAppStateContext();
    const invoke = useInvoke({ method: 'put' });
    const invokePost = useInvoke({
        method: 'post',
        eventSourceId: ['Appraisal'],
    });
    const period = params?.period;
    let previousPeriod = {};
    if (period?.from) {
        previousPeriod.from = getZeroTimeDate(
            moment(period?.from).subtract(1, 'M').startOf('M').toDate(),
        );
        previousPeriod.to = getZeroTimeDate(
            moment(period?.from).subtract(1, 'M').endOf('M').toDate(),
        );
    }
    const periodFilter = period
        ? {
            $lte: period?.to,
            $gte: period?.from,
        }
        : void 0;

    const preViousPeriodFilter = previousPeriod
        ? {
            $lte: previousPeriod?.to,
            $gte: previousPeriod?.from,
        }
        : void 0;
    const inactiveAppraisal = () => ({
        title: 'Set Appraisal Inactive',
        confirm: {
            title: 'Set Appraisal Inactive',
            message: 'Are you sure you want to inactive this appraisal?',
        },
        visible: () => tab && tab === "Pending",
        onPress: props => {
            const { row } = props;
            invoke({
                uri: `/appraisals/${row?._id}`,
                message: 'Inactive successfully',
                props: {
                    status: 'Inactive',
                },
            });
        },
    });

    const repopulateAppraisal = () => ({
        title: 'Repopulate Appraisal',
        confirm: {
            title: 'Repopulate Appraisal',
            message: 'Are you sure you want to repopulate this appraisal?',
        },
        visible: () => tab && (tab === "Pending" || tab === "TeamPending" || tab === "Awaiting Response" || tab === "Pending Approval"),
        onPress: props => {
            const { row } = props;
            invokePost({
                uri: `/repopoulateappraisaldata`,
                message: 'Repopulate successfully',
                props: {
                    data: row,
                },
            });
        },
    });

    const recalculateAppraisal = () => ({
        title: 'Recalculate Appraisal',
        confirm: {
            title: 'Recalculate Appraisal',
            message: 'Are you sure you want to Recalculate this appraisal?',
        },
        visible: () => tab && (tab === "Pending" || tab === "TeamPending" || tab === "Awaiting Response" || tab === "Pending Approval"),
        onPress: props => {
            const { row } = props;
            invokePost({
                uri: `/reCalculateBilledHours`,
                message: 'Recalculate successfully',
                props: {
                    _id: row?._id,
                },
            });
        },
    });


    const activeAppraisal = ({ row }) => ({
        title: 'Set Appraisal Active',
        confirm: {
            title: 'Set Appraisal Active',
            message: 'Are you sure you want to active this appraisal?',
        },
        visible: () => tab && tab === "Inactive Appraisal",
        onPress: props => {
            const { row } = props;
            invoke({
                uri: `/appraisals/${row?._id}`,
                message: 'active successfully',
                props: {
                    status: 'Active',
                },
            });
        },
    });


    const processExtendAppraisal = ({ row }) => ({
        title: 'Process',
        confirm: {
            title: 'Process Request',
            message: 'Are you sure you want to process this appraisal?',
        },
        visible: () => tab && tab === "Extended",
        onPress: props => {
            const { row } = props;
            invoke({
                uri: `/appraisals/${row?._id}`,
                message: 'Processed successfully',
                props: {
                    manually_processed_onhold_extend: true,
                },
            });
        },
    });

    const processOnHoldAppraisal = ({ row }) => ({
        title: 'Process',
        confirm: {
            title: 'Process Request',
            message: 'Are you sure you want to process this appraisal?',
        },
        visible: () => tab && tab === "On Hold",
        onPress: props => {
            const { row } = props;
            invoke({
                uri: `/appraisals/${row?._id}`,
                message: 'Processed successfully',
                props: {
                    ifIncrement_asPer_aggrement: "AsPerAggrement",
                    offer_accepted_by_employee: true,
                    manually_processed_onhold_extend: true,
                },
            });
        },
    });

    // const extendAppraisal = ({ row }) => ({
    //     title: 'Mark Extend',
    //     confirm: {
    //         title: 'Mark Extend',
    //         message: 'Are you sure you want to extend this appraisal?',
    //     },
    //     visible: ({ row }) => tab && tab === "Pending" && row && (!row.ifIncrement_asPer_aggrement || (row.ifIncrement_asPer_aggrement && row.ifIncrement_asPer_aggrement !== "Extend")),
    //     onPress: props => {
    //         const { row } = props;
    //         invoke({
    //             uri: `/appraisals/${row?._id}`,
    //             message: 'Request Submitted Successfully',
    //             props: {
    //                 ifIncrement_asPer_aggrement: 'Extend',
    //             },
    //         });
    //     },
    // });
    const changePeopleManager = ({ row }) => {
        navigation.navigate(`update-people-manager`, {
            ...params,
            row,
        });
    };

    const extendAppraisal = ({ row }) => {
        navigation.navigate(`extend-appraisal`, {
            ...params,
            row,
        });
    };
    

    const changePracticeHead = ({ row }) => {
        navigation.navigate(`update-practice-head`, {
            ...params,
            row,
        });
    };

    const sendAppraisalLetter = ({ row }) => {
        navigation.navigate(`send-appraisal-letter`, {
            ...params,
            row,
        });
    };

    const processManuallyAmountAccepted = ({ row }) => {
        navigation.navigate(`process-appraisal-manually`, {
            ...params,
            row,
        });
    };

    const closeManuallyAppraisal = ({ row }) => {
        navigation.navigate(`close-appraisal-manually`, {
            ...params,
            row,
        });
    };

    const closeAppraisalRequest = ({ row }) => {
        navigation.navigate(`close-appraisal-request`, {
            ...params,
            row,
        });
    };


    const resendAppraisalLetter = ({ row }) => {
        navigation.navigate(`resend-appraisal-letter`, {
            ...params,
            row,
        });
    };

    const addRemark = ({ row }) => {
        navigation.navigate(`add-remark`, {
            ...params,
            row,
        });
    };

    const addAppraisalAttachment = ({ row }) => {
        navigation.navigate(`add-attachment`, {
            ...params,
            row,
        });
    };

    const changeCloseDate = ({ row }) => {
        navigation.navigate(`update-close-date`, {
            ...params,
            row,
        });
    };

    const editIncrementDetail = ({ row }) => {
        navigation.navigate(`edit-increment-details`, {
            ...params,
            row,
        });
    };

    const firstSixMonthPackage = ({ row }) => {
        navigation.navigate(`hr-prepare-appraisal-package-details`, {
            ...params,
            row,
            formName: "first-biannual-form"
        });
    };

    const nextSixMonthPackage = ({ row }) => {
        navigation.navigate(`hr-prepare-appraisal-package-details`, {
            ...params,
            row,
            formName: "second-biannual-form"
        });
    };

    const preparePackage = ({ row }) => {
        navigation.navigate(`hr-prepare-appraisal-package-details`, {
            ...params,
            row,
        });
    };

    const downloadAnnualPackage = ({ row }) => {
        navigation.navigate(`annual-letter-download`, {
            ...params,
            row,
        });
    };

    const downloadBiannualPackage = ({ row }) => {
        navigation.navigate(`biannual-letter-download`, {
            ...params,
            row,
        });
    };

    const uploadAppraisalLetter = ({ row }) => {
        navigation.navigate(`upload-appraisal-letter`, {
            ...params,
            row,
        });
    };

    const showPackage = ({ row }) => {
        navigation.navigate(`hr-show-appraisal-package-details`, {
            ...params,
            row,
        });
    };

    const showEmployeePackageForPH = ({ row }) => {
        navigation.navigate(`ph-show-package-details`, {
            ...params,
            employee: row?.employee?._id,
        });
    };


    const showPreviousAppraisal = ({ row }) => {
        navigation.navigate(`show-employee-previous-appraisal`, {
            ...params,
            employee: row?.employee?._id,
        });
    };

    const showProjectFeedback = ({ row }) => {
        navigation.navigate('show-employee-project-feedback', {
            ...params,
            assesment_id: row?._id
        })
    }
    const offerAcceptedByEmployee = ({ row }) => ({
        title: 'Offer Accepted By Employee',
        confirm: {
            title: 'Offer Accepted By Employee',
            message: 'Is the offer Accepted By Employee?',
        },
        visible: ({ row }) => tab && tab === "In Discussion" && row && (!row.increment_recommendation),
        onPress: props => {
            const { row } = props;
            invokePost({
                uri: `/hrappraisalemails`,
                message: 'Offer Accepted By Employee',
                props: {
                    appraisalDetail: row,
                },
            });
        },
    });
    const offerAcceptedByEmployeeForm = ({ row }) => {
        navigation.navigate('appraisal-offer-acceptance-form', {
            ...params,
            row
        })
    }
    return {
        inactiveAppraisal,
        extendAppraisal,
        changePeopleManager,
        processManuallyAmountAccepted,
        closeManuallyAppraisal,
        closeAppraisalRequest,
        resendAppraisalLetter,
        addRemark,
        addAppraisalAttachment,
        processExtendAppraisal,
        changeCloseDate,
        editIncrementDetail,
        processOnHoldAppraisal,
        activeAppraisal,
        repopulateAppraisal,
        firstSixMonthPackage,
        nextSixMonthPackage,
        preparePackage,
        downloadAnnualPackage,
        downloadBiannualPackage,
        recalculateAppraisal,
        sendAppraisalLetter,
        uploadAppraisalLetter,
        showPackage,
        showEmployeePackageForPH,
        showPreviousAppraisal,
        showProjectFeedback,
        offerAcceptedByEmployee,
        offerAcceptedByEmployeeForm,
        changePracticeHead
    };
};
