import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { useInvoke } from '../../../controllers/useInvoke';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const keyPillarFeatureCountRender = ({ navigation }) => ({
  render: CountCellRender,
  header: 'Key Pillar Features',
  align: 'center',
  count_field: 'keyPillarFeaturesCount',
  responsive: 'sm',
  onPlusPress: props => {
    const { row } = props;
    navigation.navigate(`add-key-pillar-feature`, {
      keyPillar: row?._id,
    });
  },
  onPress: props => {
    const { row } = props;
    navigation.navigate(`key-pillar-feature-table`, {
      name: row?.title,
      filter: {
        keyPillar: row?._id,
      },
    });
  },
});

export const KeyPillarTable = () => {
  const navigation = useNavigation();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyPillar',
  });

  return (
    <Table
      eventSourceId={["keyPillar", "keyPillarFeature"]}
      api={`/keypillars`}
      fields={{
        title: 1,
        type: 1,
        tags: 1,
        header: 1,
        index: 1,
        keyPillarFeatures: { _id: 1 },
        keyPillarFeaturesCount: { _id: 1 },
      }}
      sort={{index: 1}}
      renderHeader={() => (
        <TableHeader
          title="Key Pillar"
          actions={[
            <AddButton
              title="Add Key Pillar"
              view="add-key-pillar"
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-key-pillar', { row });
      }}
      columns={[
        {
          type: 'text',
          field: 'type',
          header: 'Type',
        },
        {
          type: 'text',
          field: 'title',
          header: 'Title',
        },
        keyPillarFeatureCountRender({ navigation }),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-key-pillar', { row });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deleteInvoke({
              uri: `/keypillars/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
