import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const SendEmailTestForm = props => {
  const {
    route: {params},
    header = 'Test Email Form',
  } = props;

  let {onSubmit} = useFormSubmit({
    uri: `/campaignaction/${params?._id}/send`,
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{campaign_action_id: params?._id, sandbox: true}}
      layoutFields={[
        {
          placeholder: 'Test Email',
          field: 'test_email',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const EditSendEmailTestForm = props => {
  return (
    <SendEmailTestForm mode="edit" header={'Edit Test Email Form'} {...props} />
  );
};
