// Form which is used to merge the selected data into the industry and country.- Rajnish Trikaldarshi 16-03-2024
import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const IndustrySelectorForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params || {};
  let {_id, industry = '', count = 0} = row || {};
  const {onSubmit} = useFormSubmit({
    eventSourceId: ['mergeIndustryData'],
    uri: '/merge-industry-data',
    ...props,
  });
  return (
    <Form
      header={`Select Industry(${industry}-${count})`}
      onSubmit={onSubmit}
      submitAction="Merge"
      defaultValues={{selectedId: _id}}
      layoutFields={[
        {
          label: 'Industry',
          field: 'industry',
          type: 'autoComplete',
          api: `/industry/active`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'name',
          filter: {
            _id: {$ne: row?._id},
            target_industry: {$exists: false},
          },
          size: 'medium',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const CountrySelectorForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params || {};
  let {_id, country = '', country_code = '', count = 0} = row || {};
  const {onSubmit} = useFormSubmit({
    eventSourceId: ['mergeCountryData'],
    uri: '/merge-country-data',
    ...props,
  });
  return (
    <Form
      header={`Select Country (${country_code}-${country}-${count})`}
      onSubmit={onSubmit}
      submitAction="Merge"
      defaultValues={{selectedId: _id}}
      layoutFields={[
        {
          label: 'Country',
          field: 'country',
          type: 'autoComplete',
          api: `/countries`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'name',
          filter: {
            _id: {$ne: row?._id},
            target_country: {$exists: false},
          },
          size: 'medium',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
