import {useGlobalParams} from '@unthinkable/react-filters';

export const useModuleGlobalParams = (props = {}) => {
  const _globalParams = useGlobalParams();
  const {route: {params: routeParams} = {}, params} = props;
  const appModule = routeParams?.appModule || params?.appModule;
  let globalParams = {};
  if (appModule?.filters?.length) {
    appModule.filters.forEach(filter => {
      if (_globalParams?.[filter]) {
        globalParams[filter] = _globalParams?.[filter];
      }
    });
  }
  if (Object.keys(globalParams).length) {
    return globalParams;
  }
};
