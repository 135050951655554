import React from 'react';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';

export const MyFeedbacksTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_batch, employee_training} = params;
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            title={`${training_batch.name} (${employee_training?.employee?.name})`}
          />
        );
      }}
      api="/trainingFeedbacks"
      filter={{
        employee_training: employee_training._id,
      }}
      fields={{
        skill_topic: {
          name: 1,
        },
        mentor: {
          name: 1,
        },
        training_feedback_date: 1,
        overall_score: 1,
        mentor_subjective_feedback: 1,
      }}
      params={params}
      columns={[
        {
          field: 'skill_topic.name',
          header: 'Skill Topic',
          type: 'text',
          visible: training_batch.mentorship_type == 'Topic-wise Trainers',
        },
        {
          header: 'Trainer',
          type: 'userAvatar',
          field: 'mentor',
          visible: training_batch.mentorship_type == 'Topic-wise Trainers',
        },
        {
          header: 'Feedback Date',
          type: 'date',
          field: 'training_feedback_date',
          width: 150,
        },
        {
          header: 'Overall Score',
          type: 'rating',
          field: 'overall_score',
          onPress: ({row}) => {
            navigation.navigate('my-feedback-details', {
              feedback: row,
              employee_training,
            });
          },
          width: 120,
        },
        {
          header: 'Remarks',
          type: 'text',
          field: 'mentor_subjective_feedback',
        },
      ]}
    />
  );
};
