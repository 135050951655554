import {
  AddButton,
  BorderLessButton,
} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const UIComponents = props => {
  const {navigation} = props;
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              //   <BorderLessButton text={'Import'} />,
              <AddButton title={'Component'} view={'add-ui-component'} />,
            ]}
          />
        );
      }}
      eventSourceId={'UIComponents'}
      api="/uicomponents"
      onRowPress={({row}) => {
        navigation.navigate('ui-component-detail', {row});
      }}
      columns={[
        {
          type: 'text',
          field: 'component',
          header: 'Component',
        },
      ]}
    />
  );
};
