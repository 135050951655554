import React from 'react';

import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ChangeModelForm = props => {
  const {
    route: {params},
  } = props;

  const {row, api, project, secondaryTitle} = params;

  const {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: [
      'Feature',
      'View',
      'DataService',
      'Controller',
      'Model',
    ],
    ...props,
  });

  return (
    <Form
      defaultValues={{_id: row?._id}}
      header={{
        title: `${
          row?.model_id?.model
            ? `Change Model (${row.model_id.model})`
            : 'Change Model'
        }`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Model',
          field: 'model_id',
          type: 'autoComplete',
          api: `/projectmodels`,
          suggestionField: 'model',
          valueField: 'model',
          filter: {project_id: project?._id, _id: {$nin: [row?.model_id?._id]}},
          required: true,
        },
      ]}
      {...props}
    />
  );
};
