import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AddOpportunityForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/Opportunities',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Opportunity'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Requirement',
          field: 'requirement',
          type: 'text',
        },
      ]}
    />
  );
};
