import {
  BUYER_PERSONA_TYPE_ID,
  PROJECT_BUG_TYPE_ID,
  PROJECT_COMMENT_TYPE_ID,
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
} from './SourceConstants';

export const NAVIGATE_TO = {
  [BUYER_PERSONA_TYPE_ID]: 'marketing-themes',
  [PROJECT_TASK_TYPE_ID]: 'task-edit',
  [PROJECT_FEATURE_TYPE_ID]: 'feature-detail-screen',
  [PROJECT_BUG_TYPE_ID]: 'feature-detail-screen',
  [PROJECT_COMMENT_TYPE_ID]: 'comment-table',
};

export const FIELDS = {
  [BUYER_PERSONA_TYPE_ID]: {
    industry: 1,
    employee_size: 1,
    country: 1,
    designation: 1,
    nurturing_count: 1,
    outreach_count: 1,
    status: 1,
    theme_count: 1,
    'value-proposition-count': 1,
    product: 1,
  },
  [PROJECT_TASK_TYPE_ID]: {
    name: 1,
  },
  [PROJECT_FEATURE_TYPE_ID]: {
    feature: 1,
    type: 1,
    module_id: 1,
    project_id: 1,
    status: 1,
    sub_status: 1,
  },
  [PROJECT_BUG_TYPE_ID]: {
    bug: 1,
    feature_id: 1,
  },
};

export const POPULATE = {
  [BUYER_PERSONA_TYPE_ID]: [
    {
      path: 'product',
      select: {name: 1, color: 1},
    },
  ],
  [PROJECT_FEATURE_TYPE_ID]: [
    {
      path: 'project_id',
      select: {project: 1},
    },
    {
      path: 'module_id',
      select: {module: 1},
    },
  ],
  [PROJECT_BUG_TYPE_ID]: [
    {
      path: 'feature_id',
      select: {
        feature: 1,
        type: 1,
        status: 1,
        sub_status: 1,
        module_id: 1,
        project_id: 1,
      },
      populate: [
        {
          path: 'project_id',
          select: {project: 1},
        },
        {
          path: 'module_id',
          select: {module: 1},
        },
      ],
    },
  ],
};

export const getParams = ({source, source_id, data}) => {
  switch (source?._id) {
    case BUYER_PERSONA_TYPE_ID:
      const {product, ...target_audience} = data;
      return {product, target_audience};
    case PROJECT_TASK_TYPE_ID:
      return {row: {_id: source_id?._id}};
    case PROJECT_FEATURE_TYPE_ID: {
      const {
        project_id,
        module_id,
        feature,
        status,
        sub_status,
        type,
      } = data;
      const params = {};
      return {
        ...params,
        feature: {feature: feature, _id: data?._id, status, sub_status},
        feature_id: data?._id,
        featureType: type,
        project: project_id,
        project_id: project_id?._id,
        module: module_id,
        module_id: module_id?._id,
        clickedSource: PROJECT_FEATURE_TYPE_ID,
      };
    }
    case PROJECT_BUG_TYPE_ID: {
      const {feature_id} = data;
      const {
        project_id,
        module_id,
        type,
        status,
        sub_status,
        feature,
      } = feature_id;

      const params = {};
      return {
        ...params,
        feature: {feature: feature, _id: feature_id?._id, status, sub_status},
        feature_id: data?._id,
        featureType: type,
        project: project_id,
        project_id: project_id?._id,
        module: module_id,
        module_id: module_id?._id,
        clickedSource: PROJECT_BUG_TYPE_ID,
      };
    }
    case PROJECT_COMMENT_TYPE_ID: {
      return {
        comment_source: {_id: source_id?.source?._id},
        comment_source_id: {
          _id: source_id?.source_id?._id,
          name: source_id?.source_id?.name,
        },
      };
    }
  }
};
