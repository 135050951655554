export const ReviewStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    link: icons.Link,
    title: {
      ...fonts.HEADING2,
      color: colors.NEUTRAL_HIGH,
    },
    duration: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    label: {
      ...fonts.ACTION,
      color: colors.NEUTRAL_MEDIUM,
    },
    note: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    lightTags: {
      ...fonts.ACTION,
      color: colors.NEUTRAL_LOW,
    },
    separator: {
      height: 4,
      width: 4,
      borderRadius: 4,
      backgroundColor: colors.OUTLINE,
    },
    subheader: {
      ...fonts.SECTION_HEADING2,
      color: colors.NEUTRAL_HIGH,
    },
    action: {
      ...fonts.ACTION,
      color: colors.INFORMATION_HIGH,
    },
  };
};
