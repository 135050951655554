import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const OfficeForm = props => {
  const {route: {params} = {}} = props;
  const {officeId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/offices',
    eventSourceId: 'Office',
  });
  return (
    <Form
      api={`/offices/${officeId}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          size: 6,
          type: 'text',
        },
        {
          label: 'Code',
          field: 'code',
          size: 6,
          type: 'text',
        },
        {
          label: 'PIN Code',
          field: 'pin_code',
          size: 6,
          type: 'number',
        },
        {
          label: 'Address',
          field: 'address1',
          size: 6,
          type: 'text',
        },
        {
          type: 'text',
          field: 'CDKey',
          size: 6,
          label: 'CD Key',
        },
        {
          type: 'text',
          field: 'EInvUserName',
          size: 6,
          label: 'E-Invoice User Name',
        },
        {
          type: 'text',
          field: 'EInvPassword',
          size: 6,
          label: 'E-Invoice Password',
        },
        {
          type: 'text',
          field: 'EFPassword',
          size: 6,
          label: 'EF Password',
        },
        {
          type: 'text',
          field: 'gstin_no',
          size: 6,
          label: 'GSTIN No',
        },
        {
          type: 'text',
          field: 'gst_state',
          size: 6,
          label: 'GST State',
        },
        {
          type: 'text',
          field: 'gst_state_code',
          size: 6,
          label: 'GST State Code',
        },
        {
          type: 'text',
          field: 'lut_no',
          size: 6,
          label: 'LUT No',
        },
      ]}
      {...props}
    />
  );
};

export const AddOfficeForm = props => {
  return <OfficeForm header="Add Office" {...props} />;
};

export const UpdateOfficeForm = props => {
  return <OfficeForm mode="edit" header="Update Office" {...props} />;
};
