import React from 'react';
import {MoreActions as MoreActionsComponent} from '@unthinkable/react-actions';
import {MoreActionStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';

export const MoreActions = ({icon, ...props}) => {
  const styles = useStyles(MoreActionStyles);
  if (icon) {
    styles['moreActionIcon'] = icon;
  }
  return (
    <MoreActionsComponent modalPlacement="end" styles={styles} {...props} />
  );
};
