import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const CampaignContactStatusForm = props => {
  let {
    route: {params: {_id} = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: `/campaigncontacts`,
    eventSourceId: ['updateStatus'],
  });

  return (
    <Form
      mode="edit"
      fields={{
        status: 1,
        connection_request_sent_on: 1,
        connection_request_accepted_on: 1,
        reason: 1,
        first_reply_received_on: 1,
        first_reply: 1,
      }}
      api={`/campaigncontacts/${_id}`}
      onSubmit={onSubmit}
      header={'Update Status'}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'status',
          type: 'autoComplete',
          label: 'Status',
          placeholder: 'Status',
          options: [
            'new',
            'connection_request_sent',
            'connected',
            'nurturing',
            'dropped',
            'reply_received',
          ],
        },
        {
          field: 'first_reply_received_on',
          type: 'date',
          label: 'Replied On',
          placeholder: 'Replied On',
          visible: ({values}) => {
            return values?.status === 'reply_received';
          },
        },
        {
          field: 'first_reply',
          type: 'textArea',
          label: 'Reply',
          placeholder: 'Reply',
          visible: ({values}) => {
            return values?.status === 'reply_received';
          },
        },
        {
          field: 'connection_request_sent_on',
          type: 'date',
          label: 'Connection request sent on',
          placeholder: 'Connection request sent on',
          visible: ({values}) => {
            return values?.status === 'connection_request_sent';
          },
        },
        {
          field: 'connection_request_accepted_on',
          type: 'date',
          label: 'Connection request accepted on',
          placeholder: 'Connection request accepted on',
          visible: ({values}) => {
            return values?.status === 'connected';
          },
        },
        {
          field: 'reason',
          type: 'textArea',
          label: 'Dropped Reason',
          placeholder: 'Dropped Reason',
          visible: ({values}) => {
            return values?.status === 'dropped';
          },
        },
      ]}
      {...props}
    />
  );
};
