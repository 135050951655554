import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {CustomerOverviewDashboardForAm} from './CustomerDashboardOverview';
import {RecieptTabAm} from '../../order/views/RecieptTab';
import {PendingInvoiceTabAm} from '../../order/views/DueInvoiceTab';
import {
  FixedOpportunityProjectionTab,
  TnMOpportunityProjectionTab,
} from '../../opportunityProjection/views/opportunityProjectionTabs';
import {GrowthAndBillingTab} from '../../order/views/CurrentBusinessTAndM';
import {ClientFeedbackReportTable} from '../../pmt/views/ResourceWiseProjectFeedback';

const CustomerMenu = createMenuNavigator();

const CustomerNavigatorAm = props => {
  const {route: {params} = {}} = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});

  return (
    <CustomerMenu.Navigator
      // canAccess={canAccess}
      data-navigator-id="CustomerGroup"
      {...props}>
      <CustomerMenu.Screen
        data-resource-id="CustomerOverview"
        name="CustomerOverview"
        props={{user: user, ...props}}
        component={CustomerOverviewDashboardForAm}
        options={{
          label: 'Overview',
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="Orders"
        name="Orders"
        props={{user: user, ...props}}
        options={{
          label: 'Orders',
          onPress: () => {
            props.navigation.navigate('order-menu-am', {
              ...params,
            });
          },
        }}
      />
      {/* <CustomerMenu.Screen
        data-resource-id="OrdersAndDeliveries"
        name="OrdersAndDeliveries"
        component={OrderTableTabView}
        options={{
          label: 'Orders & Deliveries',
        }}
      /> */}
      {/* <CustomerMenu.Screen
        data-resource-id="InvoiceDue"
        name="InvoiceDue"
        component={PendingInvoiceTabAm}
        options={{
          label: 'Due Invoicing',
        }}
      /> */}
      <CustomerMenu.Screen
        data-resource-id="RevenueInvoice"
        name="RevenueInvoice"
        options={{
          label: 'Invoices',
          onPress: () => {
            props.navigation.navigate('invoice-menu-am', {
              ...params,
            });
          },
        }}
      />
      <CustomerMenu.Screen
        data-resource-id="Receipts"
        name="Receipts"
        component={RecieptTabAm}
        options={{
          label: 'Receipts',
        }}
      />
      <CustomerMenu.Section options={{label: 'Projects'}}>
        <CustomerMenu.Screen
          data-resource-id="MyProjects"
          props={{scope: 'product'}}
          name="Billed"
          options={{
            label: 'Billed',
            onPress: () => {
              props.navigation.navigate('project-menu-for-am', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <CustomerMenu.Screen
          data-resource-id="MyProjects"
          props={{scope: 'product'}}
          name="Unbilled"
          options={{
            label: 'Unbilled',
            onPress: () => {
              props.navigation.navigate('project-unbilled-menu-for-am', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
      </CustomerMenu.Section>
      <CustomerMenu.Section options={{label: 'Pipeline'}}>
        <CustomerMenu.Screen
          data-resource-id="T&MPipeline"
          props={{
            user: user,
            extraAddOnFilter: {owner: user?.employee?._id},
            ...props,
          }}
          name="T&M Pipeline"
          component={TnMOpportunityProjectionTab}
          options={{
            label: 'T&M',
          }}
        />
        <CustomerMenu.Screen
          data-resource-id="FixedPipeline"
          props={{
            user: user,
            extraAddOnFilter: {owner: user?.employee?._id},
            ...props,
          }}
          name="Fixed Pipeline"
          component={FixedOpportunityProjectionTab}
          options={{
            label: 'Fixed',
          }}
        />
      </CustomerMenu.Section>
      <CustomerMenu.Screen
        data-resource-id="GrowthAndBilling"
        props={{
          user: user,
          ...props,
        }}
        name="GrowthAndBilling"
        component={GrowthAndBillingTab}
        options={{
          label: 'Growth And Billing',
        }}
      />
    </CustomerMenu.Navigator>
  );
};

export default CustomerNavigatorAm;
