import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const RecurringTaskAssignmentForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/mastertaskassignments',
    eventSourceId: ['MasterTaskAssignment'],
    ...props,
  });
  const {
    route: {params},
  } = props;

  const {employee_team, row = {}, parent_task_assignment, thread={}} = params;

  const isActive = row.status === 'Active';
  const readOnly = row.status === 'Completed';

  return (
    <Form
      defaultValues={{
        parent_task_assignment: parent_task_assignment?._id,
        frequency_mode: parent_task_assignment?.frequency_mode,
        start_date: parent_task_assignment?.start_date,
        employee_team: employee_team?._id,
        status: 'Active',
        parent_model_relation: 'Lifetime',
        primary_source: {
          _id: '651d19c6e7b7a33ee66b4336',
          label: 'Product',
        },
        primary_source_id: {
          _id: '53a42e38a22d3a020035f672',
          name: 'Software Services',
        },
        thread: thread?._id,
        ...row,
      }}
      beforeSubmit={({data}) => {
        if (
          data?.assignee_mode === 'IndividualWithEstimate' &&
          !data?.owner_wise_estimates?.length
        ) {
          throw new Error('Please Select Assignee');
        }
        return {
          ...data,
        };
      }}
      readOnly={readOnly}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Task',
          field: 'task',
          type: 'textArea',
          required: true,
        },
        [
          {
            label: 'Repeat',
            field: 'frequency_mode',
            type: 'autoComplete',
            options: [
              'Daily',
              'Weekly',
              'Biweekly',
              'Monthly',
              'Quarterly',
              'Yearly',
            ],
            size: 4,
            required: true,
            readOnly: parent_task_assignment,
          },
          {
            label: 'Start Date',
            field: 'start_date',
            type: 'date',
            size: 4,
            required: true,
            readOnly: parent_task_assignment,
          },
          {
            type: 'number',
            field: 'due_days',
            label: 'Due After (in days)',
            size: 4,
            editorAsRenderer: true,
          },
        ],
        {
          label: 'Assignee Details',
          fields: [
            {
              field: 'assignee_mode',
              type: 'radioGroup',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Open Task', value: 'Common'},
                {
                  label: 'Assigned Task',
                  value: 'IndividualWithEstimate',
                },
              ],
              readOnly: readOnly || isActive,
              required: true,
              editorAsRenderer: true,
              onChangeValue: (value, _, {setFieldValue}) => {
                if (value === 'IndividualWithEstimate') {
                  setFieldValue('team', void 0);
                  setFieldValue('est_hours', void 0);
                } else {
                  setFieldValue('owner_wise_estimates', void 0);
                }
              },
            },
            {
              visible: ({values}) => values.assignee_mode === 'Common',
              fields: [
                {
                  label: 'Assignees',
                  field: 'team',
                  type: 'multiAutoComplete',
                  api: `/usersuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  colorField: 'color',
                  avatar: true,
                  valueField: 'name',
                  readOnly: readOnly || isActive,
                  required: true,
                  size: 9,
                },
                {
                  type: 'number',
                  field: 'est_hours',
                  label: 'Est. Hrs',
                  onChangeValue: (value, _, {setFieldValue}) => {
                    if (isActive) {
                      return;
                    }
                    if (value) {
                      setFieldValue('owner_wise_estimates', void 0);
                    } else {
                      setFieldValue('team', void 0);
                    }
                  },
                  size: 3,
                  editorAsRenderer: true,
                  required: true,
                },
              ],
            },
            {
              visible: ({values}) =>
                values.assignee_mode === 'IndividualWithEstimate',
              fields: [
                {
                  field: 'owner_wise_estimates',
                  required: true,
                  nested: true,
                  render: props => {
                    const {
                      form: {values},
                    } = props;
                    return (
                      <NestedTable
                        key={values?.parent_model_relation}
                        skipAdd={readOnly || isActive}
                        skipDelete={readOnly || isActive}
                        inlineAddForm={true}
                        modalProps={{size: 'small'}}
                        {...props}
                        inlineFields={[
                          {
                            label: 'Select Assignee',
                            field: 'owner',
                            type: 'autoComplete',
                            api: `/usersuggestions`,
                            suggestionField: 'name',
                            secondarySuggestionField: 'email',
                            colorField: 'color',
                            avatar: true,
                            valueField: 'name',
                            readOnly: readOnly || isActive,
                            required: true,
                            editorAsRenderer: true,
                            size: 9,
                          },
                          {
                            label: 'Est Hrs',
                            field: 'est_hours',
                            type: 'number',
                            required: true,
                            editorAsRenderer: true,
                            size: 2,
                          },
                        ]}
                        fields={[
                          {
                            label: 'Select Assignee',
                            field: 'owner',
                            type: 'autoComplete',
                            api: `/usersuggestions`,
                            suggestionField: 'name',
                            secondarySuggestionField: 'email',
                            colorField: 'color',
                            avatar: true,
                            valueField: 'name',
                            readOnly: readOnly || isActive,
                            required: true,
                            editorAsRenderer: true,
                          },
                          {
                            label: 'Est Hrs',
                            field: 'est_hours',
                            type: 'number',
                            required: true,
                            editorAsRenderer: true,
                          },
                        ]}
                        columns={[
                          {
                            type: 'text',
                            field: 'owner.name',
                            header: 'Assignee',
                          },
                          {
                            field: 'est_hours',
                            type: 'number',
                            header: 'Est Hrs',
                            width: 150,
                          },
                        ]}
                      />
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'Review Details',
          fields: [
            {
              type: 'checkbox',
              field: 'review_not_required',
              label: 'Not Reviewable',
              onChangeValue: (value, _, {setFieldValue, values}) => {
                if (value) {
                  setFieldValue('reviewer', values?.reviewer ? [] : void 0);
                }
              },
              size: 2,
            },
            {
              field: 'reviewer',
              type: 'multiAutoComplete',
              api: `/usersuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              colorField: 'color',
              avatar: true,
              valueField: 'name',
              label: 'Reviewers',
              required: true,
              visible: ({values}) => !values?.review_not_required,
              size: 10,
            },
          ],
        },
        {
          collapsedFields: [
            {
              label: 'Source',
              field: 'primary_source_id',
              type: 'autoComplete',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
              readOnly: readOnly || isActive,
              defaultHidden: true,
            },
            {
              label: 'Checklist',
              field: 'acceptance_criteria',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  skipAdd={readOnly || isActive}
                  skipDelete={readOnly || isActive}
                  fields={[
                    {
                      type: 'text',
                      field: 'name',
                      placeholder: 'Add checklist item',
                      readOnly: readOnly || isActive,
                    },
                  ]}
                  columns={[
                    {
                      type: 'text',
                      field: 'name',
                      header: 'Checklist',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddRecurringTaskAssignmentForm = props => {
  return (
    <RecurringTaskAssignmentForm
      {...props}
      header={{
        title: 'Add Recurring Task',
        secondaryTitle: props?.route?.params?.parent_task_assignment?.task,
      }}
    />
  );
};

export const EditRecurringTaskAssignmentForm = props => {
  return (
    <RecurringTaskAssignmentForm
      {...props}
      header={{
        title: 'Edit Recurring Task',
      }}
    />
  );
};
