export const CountButtonStyles = ({theme: {colors, fonts, icons}}) => {
  return {
    container: {
      backgroundColor: colors.INFORMATION_LOW,
      paddingRight: 14,
      paddingLeft: 14,
      height: 32,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.ACTION,
    },
    count: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.ACTION,
    },
  };
};
