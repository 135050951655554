import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {BilledList} from '../../order/views/TAndMBilled';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'autoComplete',
      label: 'Orders',
      api: '/orders',
      placeholder: 'Select',
      suggestionField: 'order_number',
      valueField: 'order_number',
      field: 'order',
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project_id',
    },
    {
      label: 'From date',
      type: 'date',
      field: 'period',
      // asParam: true,
    },
    {
      type: 'autoComplete',
      options: [
        'Client Managed',
        'Daffodil Managed',
        'Hybrid Managed',
        'Daffodil Managed - Support',
      ],
      field: 'project_sub_category',
      label: 'Project SubCategory',
    },
    {
      type: 'autoComplete',
      label: 'Skill',
      api: '/skillmetrics',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'skill_metrics',
    },
    {
      type: 'autoComplete',
      label: 'Tools',
      api: '/tools',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'tool',
    },
    {
      type: 'autoComplete',
      placeholder: 'Select',
      options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
      label: 'Experience Range',
      field: 'experience_range',
    },
    {
      type: 'autoComplete',
      placeholder: 'Select',
      options: [
        {label: 'CE', value: 'A+'},
        {label: 'OE', value: 'A'},
        {label: 'ME', value: 'B+'},
        {label: 'PE', value: 'B'},
        {label: 'DE', value: 'C'},
      ],
      field: 'project_feedback_rating',
      label: 'Last Rating',
      suggestionField: 'label',
      keyField: 'value',
      valueField: 'label',
    },
    {
      type: 'autoComplete',
      options: [
        {label: 'True', value: true},
        {label: 'False', value: false},
      ],
      field: 'is_manager',
      label: 'Is Manager',
      suggestionField: 'label',
      keyField: 'value',
      valueField: 'label',
    },
  ];
};

const enggBilledFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'T&M',
  searchValue,
  params,
  period,
}) => {
  return [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search By Resource Name"
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
  ];
};

export const EnggBilledTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {department} = params;
  const defaultFilter = {
    "employee.department": department,
    "employee.employee_status": "Active",
  };
  // if (fromICMenu) {
  //   defaultFilter.is_manager = false;
  // }

  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {...defaultFilter},
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    tandm: {
      label: 'T&M',
      view: (
        <BilledList
          filterParams={{...filter, project_type: 'tm'}}
          period={period}
          searchValue={searchValue}
          tab="T&M"
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        project_type: 'tm',
      },
      api: '/employees/internalresources',
      params: {...params, period},
    },

    fixed: {
      label: 'Fixed',
      view: (
        <BilledList
          searchValue={searchValue}
          filterParams={{...filter, project_type: 'f'}}
          period={period}
          tab="Fixed"
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        project_type: 'f',
      },
      api: '/employees/internalresources',
      params: {...params, period},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={enggBilledFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Fixed',
        params,
        period,
      })}
    />
  );
};
