import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';

const WFHRequestForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  const {user} = useAppStateContext();
  const {
    employee: {_id},
  } = user;
  let {onSubmit} = useFormSubmit({
    uri: '/wfhrequests',
    eventSourceId: ['wfhRequest'],
    ...props,
  });
  return (
    <Form
      api={`/wfhrequests/${row?._id}`}
      fields={{
        from_date: 1,
        to_date: 1,
        duration_of_leave: 1,
        type_of_half_day: 1,
        leave_type_id: {name: 1},
        remarks: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{
        requested_on: getZeroTimeDate(new Date()),
        from_date: row?.attendance_date
          ? new Date(row?.attendance_date)
          : getZeroTimeDate(new Date()),
        to_date: row?.attendance_date
          ? new Date(row?.attendance_date)
          : getZeroTimeDate(new Date()),
        employee: _id,
        is_approved: false,
        status: 'unapproved',
      }}
      layoutFields={[
        {
          label: 'From Date',
          placeholder: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          placeholder: 'To Date',
          required: true,
          size: 6,
        },
        {
          label: 'Duration',
          placeholder: 'Duration',
          field: 'duration_of_leave',
          type: 'autoComplete',
          options: ['Full Day', 'Half Day'],
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue(
              'type_of_half_day',
              value === 'Full Day' ? null : 'First Half',
            );
          },
          size: 12,
        },
        {
          label: 'First Half/Second Half',
          placeholder: 'First Half/Second Half',
          field: 'type_of_half_day',
          type: 'autoComplete',
          options: ['First Half', 'Second Half'],
          visible: ({values}) => {
            if (values && values.duration_of_leave === 'Half Day') {
              return true;
            } else {
              return false;
            }
          },
          size: 6,
        },
        {
          label: 'Reason',
          type: 'text',
          placeholder: 'Reason',
          field: 'remarks',
        },
      ]}
      {...props}
    />
  );
};

export const AddWFHRequestForm = props => {
  return <WFHRequestForm header={'Add WFH Request'} {...props} />;
};
