import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const GoalForm = props => {
  let {
    navigation,
    route: {params},
    mode,
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/threadgoals',
    eventSourceId: 'threadGoal',
    ...props,
  });
  const {user} = useAppStateContext();

  return (
    <Form
      api={`/threadgoals/${row?._id}`}
      fields={{
        goal: 1,
        project: {project: 1},
        owner: {name: 1},
        members: {name: 1},
        kpi: {
          kpi: 1,
        },
      }}
      beforeSubmit={({data}) => {
        if (mode) {
          return data;
        } else {
          return {
            data: {
              ...data,
              status: 'Plan',
              owner: user?._id,
            },
          };
        }
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Goal'}}
      layoutFields={[
        {
          label: 'Goal',
          type: 'text',
          field: 'goal',
          required: true,
        },
        {
          label: 'KPI',
          type: 'autoComplete',
          field: 'kpi',
          api: '/kpis',
          suggestionField: 'kpi',
          valueField: 'kpi',
        },
        {
          label: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
        },
        {
          label: 'Memebers',
          field: 'members',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const EditGoalForm = props => {
  return <GoalForm header={{title: 'Edit Goal'}} mode="edit" {...props} />;
};
