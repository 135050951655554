import React, {useState} from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {Button} from '../../../components/button/Button';
import {View} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {OutlineButton} from '../../../components/button/OutlineButton';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

const RetrieveRender = ({setFieldValue, values}) => {
  let invoke = useInvoke({method: 'post'});

  const [loading, setLoading] = useState();

  const retrievePrompt = async props => {
    try {
      setLoading(true);
      let {data = {}} = await invoke({
        uri: `/finetune/generate-message-from-ai`,
        props: {
          ...props,
          model: 'gpt-4-1106-preview',
          response_format: {response_format: {type: 'json_object'}},
          parseJSON: true,
          useGPT4onContextExceeded:true
        },
      });

      let {airesponse} = data || {};
      setLoading(false);
      let {messages} = values || {};
      messages = [...messages];
      messages.push(airesponse);

      setFieldValue('messages', messages);
    } catch (error) {
      throw error;
    }
  };
  return (
    <View style={{alignItems: 'flex-end'}}>
      <Button
        text={'Generate Response'}
        onPress={() => {
          return retrievePrompt(values);
        }}
        loading={loading}
      />
    </View>
  );
};

const promptComputations = post => ({
  set_system_message: {
    multi: true,
    compute: async value => {
      const {usecase_id, aiprompt, potential_customer, campaign_id} = value;

      const {
        data: {prompt = []},
      } = await post({
        uri: `/potentialcustomers/${potential_customer}/business_summary_prompt`,
        props: {
          usecase_id,
          aiprompt,
          campaign_id,
        },
      });
      return {
        messages: prompt,
      };
    },
    dependencies: ['aiprompt'],
  },
});

export const PotenitalCustomerBusinessSummaryForm = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/campaignActions/saveDocumentandSummary',
    eventSourceId: 'addDocument',
    ...props,
  });

  let {potential_customer, campaign_id} = params || {};
  const {post} = useAppStateContext();
  return (
    <Form
      header={'Add Summary Document'}
      onSubmit={onSubmit}
      defaultValues={{
        potential_customer,
        campaign_id,
      }}
      computations={{...promptComputations(post)}}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Usecase',
          field: 'usecase_id',
          type: 'autoComplete',
          api: '/finetuneusecases',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'AIprompt',
          field: 'aiprompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Model',
          field: 'fine_tune_model',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Messages',
          field: 'messages',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  field: 'role',
                  type: 'autoComplete',
                  placeholder: 'role',
                  header: 'Role',
                  options: ['system', 'user', 'assistant'],
                },
                {
                  field: 'content',
                  placeholder: 'content',
                  header: 'Content',
                  type: 'textArea',
                },
                {
                  render: props => {
                    let {row} = props || {};
                    let {role} = row || {};
                    return role === 'assistant' ? (
                      <View style={{alignItems: 'flex-end'}}>
                        <OutlineButton
                          text={'view'}
                          onPress={() => {
                            navigation.navigate('view-assitant-response', {
                              row,
                            });
                          }}
                        />
                      </View>
                    ) : null;
                  },
                },
              ]}
            />
          ),
        },
        {
          render: () => {
            return (
              <View style={{alignItems: 'center', justiyContent: 'center'}}>
                <ActivityIndicator />
              </View>
            );
          },
          visible: ({status}) => status === 'computing',
        },
        {
          render: (props, {setFieldValue, values}) => {
            return (
              <RetrieveRender setFieldValue={setFieldValue} values={values} />
            );
          },
          visible: ({values}) => values.messages && values.messages.length,
        },
      ]}
      {...props}
    />
  );
};
