import React from 'react';
import {Table} from '../../../components/table/Table';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab';
import {useInvoke} from '../../../controllers/useInvoke';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import moment from 'moment';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Text, View} from '@unthinkable/react-core-components';
import {MultiTag} from '../../../components/chip/MultiChip';

const unMarkIdentifyForSwapping = ({invoke}) => ({
  title: 'Un-Mark Anchor Resource',
  confirm: {
    title: 'Un-Mark Anchor Resource',
    message: 'Do you want un-mark anchor resource of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        mark_identify_for_swapping: 'Unmark',
      },
    });
  },
});

const markIdentifyCompleteForSwapping = ({navigation, params, tab}) => ({
  title: 'Update Status',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-anchor-resource-status', {
      row,
      tab,
    });
  },
});

// const swapping = ({navigation, params}) => ({
//   title: 'Swapping with',
//   onPress: props => {
//     const {row} = props;
//     navigation.navigate('identify-swapping', {
//       row,
//     });
//   },
// });

const proposedDeploymentDate = ({navigation, params}) => ({
  title: 'Update completion date',
  onPress: props => {
    const {row} = props;
    navigation.navigate('proposed-complete-deployment-date', {
      row,
    });
  },
});

const actions = ({navigation, params}) => ({
  title: 'Update Action',
  onPress: props => {
    const {row} = props;
    navigation.navigate('anchor-resource-actions', {
      row,
    });
  },
});

const updateSwappingWith = ({navigation, params}) => ({
  title: 'Update Swapped',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-swapped-with', {
      row,
    });
  },
});

const RenderSwappingWith = props => {
  const {navigation, params, tab} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        // {
        //   render: CountCellRender,
        //   onPlusPress: props => {
        //     const {row = {}} = props;
        //     navigation.navigate(`identify-swapping`, {
        //       row,
        //     });
        //   },
        //   visible: ({row}) => {
        //     if (!row?.replaced_with?.length && tab !== 'In Transit') {
        //       return true;
        //     }
        //   },
        // },
        {
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.replaced_with?.[0]?.name}
                color={row?.replaced_with?.[0]?.color}
                official_email_id={row?.replaced_with?.[0]?.official_email_id}
              />
            );
          },
          visible: ({row}) => {
            if (row?.replaced_with?.length) {
              return true;
            }
          },
        },
      ]}
    />
  );
};

export const MarkIdentifyForSwapping = props => {
  let {
    navigation,
    route: {params},
    tab,
    addOnFilter,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'allocatedResource',
  });
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId={[
        'allocatedResource',
        'EmployeeProposedCompleteDate',
        'AnchorResourceAction',
      ]}
      limit={2000}
      searchFields="name"
      api={`/employee/identifyForSwapping`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Anchor',
          minWidth: 250,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Exp.',
          width: 80,
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          header: 'Project/Allocation',
          minWidth: 200,
          render: ({row, styles}) => {
            const {project_id = {}, uniqueProject = []} = row;
            let type = '';
            if (project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <View>
                <MultiTag values={row.uniqueProject} maxChips={1} gap={8} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: type,
                    },
                    {
                      value: `${row?.hours || 0}h/day`,
                    },
                  ]}
                />
              </View>
            );
          },
        },
        {
          header: 'Project Skill',
          field: 'tool.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Release',
        },
        tab !== 'Hold' &&
          tab !== 'Identified' && {
            header: 'Swapped With',
            width: 250,
            render: props => {
              return (
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <RenderSwappingWith
                    {...props}
                    navigation={navigation}
                    tab={tab}
                  />
                </View>
              );
            },
          },
        {
          header: 'Transition Period',
          width: 200,
          visible: () => {
            if (tab === 'In Transit') {
              return true;
            } else return false;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#7F7F7F'}}>
                {row.from_date && moment(row?.from_date).format('DD MMM YY')} -{' '}
                {row.to_date && moment(row.to_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'identified On',
          width: 150,
          field: 'anchor_identified_on',
          type: 'date',
          visible: () => {
            if (tab === 'Identified') {
              return true;
            } else return false;
          },
        },
        {
          header: 'Owner',
          field: 'action_taken_by',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Status',
          width: 150,
          field: 'mark_identify_for_swapping',
          type: 'colorTag',
          visible: () => {
            if (tab === 'Closed') {
              return true;
            } else return false;
          },
        },
        {
          header: 'Remark',
          width: 200,
          field: 'actions_description',
          visible: () => {
            if (tab === 'Closed') {
              return true;
            } else return false;
          },
        },
        tab !== 'Hold' &&
          tab !== 'Closed' && {
            header: 'Next Action',
            width: 200,
            render: ({row}) => {
              const date = row?.actions_date
                ? moment(row?.actions_date)?.format('DD MMM YY')
                : void 0;
              return (
                <PrimaryColumnCard
                  numberOfLines={1}
                  primaryTitle={row?.actions_description}
                  items={[
                    {
                      value: date,
                    },
                  ]}
                />
              );
            },
          },
        tab === 'In Transit' && {
          header: 'Completion Date',
          width: 150,
          field: 'proposed_deployment_date',
          type: 'date',
          align: 'center',
          width: 150,
          visible: () => {
            if (tab === 'Complete') {
              return true;
            } else return false;
          },
        },
        tab === 'Complete' && {
          header: 'Completion On',
          field: 'anchor_resource_complete_on_date',
          type: 'date',
          align: 'center',
          width: 150,
          visible: () => {
            if (tab === 'Complete') {
              return true;
            } else return false;
          },
        },
        tab === 'Hold' && {
          header: 'On Hold Since',
          field: 'anchor_resource_on_hold_date',
          type: 'date',
          align: 'center',
          width: 150,
        },
        // tab === 'Closed' && {
        //   header: 'Drop Date',
        //   field: 'anchor_resource_drop_date',
        //   type: 'date',
        //   align: 'center',
        //   width: 150,
        // },
        tab === 'Hold' && {
          header: 'Reason For Hold',
          field: 'actions_description',
          // type: 'date',
          width: 150,
        },
      ]}
      moreActions={({row}) => [
        row?.mark_identify_for_swapping !== 'Closed' &&
          unMarkIdentifyForSwapping({invoke}),
        // row?.mark_identify_for_swapping !== 'Closed' &&
        //   swapping({navigation, params}),
        row?.mark_identify_for_swapping !== 'Closed' &&
          markIdentifyCompleteForSwapping({navigation, params, tab}),
        row?.mark_identify_for_swapping !== 'Closed' &&
          row?.mark_identify_for_swapping !== 'Hold' &&
          proposedDeploymentDate({navigation, params}),
        row?.mark_identify_for_swapping !== 'Closed' &&
          row?.mark_identify_for_swapping !== 'Hold' &&
          actions({navigation, params}),
        tab == 'Closed' && updateSwappingWith({navigation, params}),
      ]}
    />
  );
};

export const MarkIdentifyForSwappingTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const tabs = {
    mark_identify_unplanned_for_swapping: {
      label: 'Identified',
      view: (
        <MarkIdentifyForSwapping
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={{
            mark_identify_for_swapping: 'Pending',
            ...filter,
          }}
          tab="Identified"
        />
      ),
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Status',
              options: ['Pending'],
              field: 'mark_identify_for_swapping',
              placeholder: 'Select',
            },
          ]}
        />,
      ],
      api: '/employee/identifyForSwapping',
      limit: 2000,
      addOnFilter: {
        ...filter,
        mark_identify_for_swapping: 'Pending',
      },
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'allocatedResource',
      params: {...params},
    },

    mark_identify_ongoing_for_swapping: {
      label: 'Matched',
      view: (
        <MarkIdentifyForSwapping
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            mark_identify_for_swapping: 'In Progress',
          }}
          tab="In Transit"
        />
      ),
      api: '/employee/identifyForSwapping',
      limit: 2000,
      addOnFilter: {
        ...filter,
        mark_identify_for_swapping: 'In Progress',
      },
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'allocatedResource',
      params: {...params},
    },

    mark_identify_hold_for_swapping: {
      label: 'On Hold',
      view: (
        <MarkIdentifyForSwapping
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            mark_identify_for_swapping: 'Hold',
          }}
          tab="Hold"
        />
      ),
      api: '/employee/identifyForSwapping',
      limit: 2000,
      addOnFilter: {...filter, mark_identify_for_swapping: 'Hold'},
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'allocatedResource',
      params: {...params},
    },

    mark_identify_complete_for_swapping: {
      label: 'Closed',
      view: (
        <MarkIdentifyForSwapping
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={{
            mark_identify_for_swapping: {$in: ['Complete', 'Drop']},
            ...filter,
          }}
          tab="Closed"
        />
      ),
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Status',
              options: ['Drop', 'Complete'],
              field: 'mark_identify_for_swapping',
              placeholder: 'Select',
            },
          ]}
        />,
      ],
      api: '/employee/identifyForSwapping',
      limit: 2000,
      addOnFilter: {
        mark_identify_for_swapping: {$in: ['Complete', 'Drop']},
        ...filter,
      },
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'allocatedResource',
      params: {...params},
    },
    // mark_identify_drop_for_swapping: {
    //   label: 'Drop',
    //   view: (
    //     <MarkIdentifyForSwapping
    //       filterParams={filterParams}
    //       params={params}
    //       search={searchValue}
    //       addOnFilter={{
    //         ...filter,
    //         mark_identify_for_swapping: 'Drop',
    //       }}
    //       tab="Drop"
    //     />
    //   ),
    //   api: '/employee/identifyForSwapping',
    //   limit: 2000,
    //   addOnFilter: {...filter, mark_identify_for_swapping: 'Drop'},
    //   search: searchValue,
    //   searchFields: 'name',
    //   eventSourceId: 'allocatedResource',
    //   params: {...params},
    // },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
