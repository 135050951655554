import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useFetchData} from '../../../controllers/useFetchData';

export const DesignationForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    uri: '/designations',
    eventSourceId: 'designation',
  });
  const {data: designationData} = useFetchData({
    api: '/designations',
    filter: {
      _id: params?._id,
    },
    fields: {
      _id: 1,
      name: 1,
      type: 1,
      status: 1,
      from: 1,
      to: 1,
      employee_level: {name: 1},
    },
    only: true,
  });
  return (
    <Form
      api={`/designations/${params?._id}`}
      fields={{
        name: 1,
        type: 1,
        status: 1,
        from: 1,
        to: 1,
        employee_level: {name: 1},
      }}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          required: true,
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'employee_level',
          label: 'Level',
          api: '/levels',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          type: 'text',
          field: 'type',
          label: 'Type',
          size: 6,
        },
        {
          type: 'text',
          field: 'status',
          label: 'Status',
          size: 6,
        },
        {
          label: 'From',
          field: 'from',
          type: 'date',
          size: 6,
        },
        {
          label: 'To',
          field: 'to',
          type: 'date',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddDesignationForm = props => {
  return <DesignationForm header="Add Designation" {...props} />;
};

export const EditDesignationForm = props => {
  return (
    <DesignationForm
      mode="edit"
      isDualMode
      header={'Update Designation'}
      {...props}
    />
  );
};
