import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const KeyResponsibilityAreaTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyresponsibilityareas',
  });

  return (
    <Table
      eventSourceId={'keyresponsibilityareas'}
      api={`/keyresponsibilityareas`}
      renderHeader={() => (
        <TableHeader
          title="Key Responsibility Area"
          actions={[
            <AddButton
              title="Add"
              view="add-key-responsiblity-area"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        name: 1,
        description: 1,
        department: {name: 1},
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
          responsive: 'sm',
        },
        {
          field: 'department.name',
          header: 'Department',
          responsive: 'sm',
        },
        {
          field: 'description',
          header: 'Description',
          responsive: 'sm',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-key-responsiblity-area', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/keyresponsibilityareas/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default KeyResponsibilityAreaTable;
