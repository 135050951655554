import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const ModuleMenu = props => {
  const {
    route: {params},
    navigation,
    searchValue,
    tabFilter = {},
  } = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'moduleMenu',
  });

  return (
    <Table
      eventSourceId={'moduleMenu'}
      api={`/modulemenuData`}
      fields={{
        module: {
          label: 1,
        },
        menu: 1,
        screen: 1,
        scope: 1,
        menu_rights: 1,
      }}
      onRowPress={({row}) => {
        navigation.navigate('view-module-menu-rights', {row});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Module Menu"
              view="add-module-menu"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      filter={{
        module: params?.row?._id,
      }}
      columns={[
        {
          header: 'Menu',
          field: 'menu',
          type: 'text',
          width: 300,
        },
        {
          header: 'Screen',
          field: 'screen',
          type: 'text',
          width: 200,
        },
        {
          header: 'Scope',
          field: 'scope',
          type: 'text',
          width: 200,
        },
        {
          header: 'Access',
          field: 'menu_rights',
          type: 'text',
          numberOfLines: 2,
        },
      ]}
      moreActions={() => [
        {
          title: 'View Module Menu Rights',
          onPress: ({row}) => {
            navigation.navigate('view-module-menu-rights', {row});
          },
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-module-menu', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/modulemenus/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default ModuleMenu;
