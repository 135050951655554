import React from 'react';
import {Table} from '../../../components/table/Table';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import moment from 'moment';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';

export const CustomerRevenueTable = props => {
  const params = props?.route?.params;
  const {period} = params;

  let previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').endOf('M').toDate(),
    );
  }

  return (
    <Table
      api={`/customerInvoices`}
      params={params}
      limit={5000}
      columns={[
        {
          field: 'customer.name',
          header: 'Name',
          onPress: ({row}) => {
            props.navigation.navigate('customer-details', {row});
          },
        },
        {
          width: 300,
          type: 'currency',
          field: 'base_invoice_amount',
          compareField: 'pre_base_invoice_amount',
          header: 'Amount',
          aggregate: {
            render:AggregateComparisonRender
          },
          onPress: ({row}) => {
            props.navigation.navigate('customer-invoices', {
              ...params,
              customer: row.customer?._id,
            });
          },
          onComparePress: ({row}) => {
            props.navigation.navigate('customer-invoices', {
              ...params,
              customer: row.customer?._id,
              period: previousPeriod,
            });
          },
          render: ComparisonRender,
        },
      ]}
    />
  );
};
