import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {leadControllers} from '../controllers/leadControllers';
import {Tag} from '../../../components/chip/Chip';

const RenderName = ({row}) => {
  return <Tag value={row?.name} />;
};

export const ChannelTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {channelLead, channelPreLead, channelQl, channelPreQl} =
    leadControllers({...props, params});

  return (
    <Table
      api={'/types/channelTypes'}
      eventSourceId={'channel'}
      addOnFilter={{...filter}}
      renderHeader={() => (
        <TableHeader
          title="Channel"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
          ]}
        />
      )}
      columns={[
        {header: 'Name', type: 'colorTag', field: 'name', colorField: 'color'},
        {
          type: 'number',
          field: 'lead_count',
          header: 'Lead',
          compareField: 'previous_lead_count',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: channelLead,
          onComparePress: channelPreLead,
        },
        {
          type: 'number',
          field: 'ql_count',
          header: 'QL',
          compareField: 'previous_ql_count',
          render: ComparisonRender,
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: channelQl,
          onComparePress: channelPreQl,
        },
      ]}
      params={params}
    />
  );
};
