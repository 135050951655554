import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const DomainForm = props => {
  const {
    route: { params },
  } = props;
  const { row } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/domains',
    eventSourceId: 'domain',
  });
  return (
    <Form
      api={`/domains/${row?._id}`}
      fields={{
        domain: 1,
        icon: 1,
        active_icon: 1,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Domain',
          type: 'text',
          field: 'domain',
          required: true,
        },
        {
          label: 'Icon',
          type: 'file',
          field: 'icon',
          size: 6,
        },
        {
          label: 'Active Icon',
          type: 'file',
          field: 'active_icon',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddDomainForm = props => {
  return <DomainForm header="Add Domain" {...props} />;
};

export const UpdateDomainForm = props => {
  return (
    <DomainForm mode="edit" header="Update Domain" {...props} />
  );
};
