import React from 'react';
import {Table} from '../../../components/table/Table';

export const OutreachAnalyticsTableGrouped = props => {
  let {
    filterParams,
    route: {params},
    navigation,
    auto_verified,
    auto_verified_error,
  } = props;

  let {_id, verified, sent_action, status, filter} = params;

  let addOnFilter = {
    campaign_id: _id,
    status,
    auto_verified,
    auto_verified_error,
  };

  if (verified) {
    addOnFilter = {...addOnFilter, verified};
  }

  return (
    <Table
      eventSourceId={['outreachAdded', 'indexUpdated', 'actionVerified']}
      api={`/outreachcampaigns-sequence-analytics`}
      params={{
        verified,
        campaign_id: _id,
        sent_action,
        status,
        filter: {
          ...filter,
          auto_verified,
          auto_verified_error,
        },
        ...filterParams,
      }}
      columns={[
        {
          header: 'Sequence',
          field: 'sequence_display',
          type: 'text',
          width: 100,
        },
        {
          header: 'Subject',
          field: 'personalize_subject',
          type: 'textArea',
          onPress: ({row}) => {
            navigation.navigate(`edit-campaign-message-form`, {
              row: {_id: row?.campaign_message},
              dualMode: true,
            });
          },
        },
        {
          header: 'Prospect touched',
          field: 'unique_prospect_count',
          width: 150,
          type: 'text',
        },
        {
          header: 'Sent',
          field: 'sent_actions_count',
          type: 'text',
          width: 150,
          onPress: ({row}) => {
            let addOnFilter = {
              title: 'Sent',
              campaign_id: row?.campaign_id,
              campaign_name: row?.campaign_id?.name,
              sequence_display: row?.sequence_display,
              action: row?.action,
            };
            navigation.navigate('show-analytics-actions', {addOnFilter});
          },
        },
        {
          header: 'Unsubscibe',
          field: 'email_unsubscribe_count',
          type: 'text',
          width: 200,
          onPress: ({row}) => {
            let addOnFilter = {
              title: 'Unsubscribe',
              campaign_id: row?.campaign_id,
              campaign_name: row?.campaign_id?.name,
              sequence_display: row?.sequence_display,
              action: row?.action,
              email_unsubscribe_on: {$exists: true},
            };
            navigation.navigate('show-analytics-actions', {addOnFilter});
          },
        },
        {
          header: 'Response',
          field: 'positive_response_count',
          type: 'text',
          width: 200,
          onPress: ({row}) => {
            let addOnFilter = {
              title: 'Positive response',
              campaign_id: row?.campaign_id,
              campaign_name: row?.campaign_id?.name,
              sequence_display: row?.sequence_display,
              action: row?.action,
              positive_response_on: {$exists: true},
            };
            navigation.navigate('show-analytics-actions', {addOnFilter});
          },
        },
      ]}
    />
  );
};
