import React from 'react';
import './ThreeDotLoader.css';
const ThreeDotLoader = ({size = 5, color, styles = {}} = {}) => {
  const dotStyle = {
    height: size,
    width: size,
    backgroundColor: color,
    marginLeft: size / 2,
    marginRight: size / 2,
    ...styles?.dot,
  };
  return (
    <div className="three-dots-loader" style={styles?.container}>
      <div className="dot" style={dotStyle}></div>
      <div className="dot" style={dotStyle}></div>
      <div className="dot" style={dotStyle}></div>
    </div>
  );
};

export default ThreeDotLoader;
