import React from 'react';
import Card from '../cards/Card';
import {ScrollView} from '@unthinkable/react-core-components';
import {Grid, GridItem} from '@unthinkable/react-layout';

const ModuleAboutTable = props => {
  const {
    navigation,
    route: {params},
  } = props;


  const cardDetails = [
    {
      name: 'Asset & auto-task assignment',
      subheading: 'Setup team, reviewers and repositories for asset',
      icon: 'RolesConfiguration',
      onPress: () => {
        navigation.navigate('pmt-task-assignment-table', {
          ...params,
          fromModule: true,
          secondaryTitle: params.module?.module,
        });
      },
    },
  ];

  return (
    <ScrollView>
      <Grid columns={12} colGap={8} rowGap={8}>
        {cardDetails.map(item => {
          let {
            name,
            subheading,
            onPress,
            icon,
            visible = true,
            size,
          } = item || {};
          if (!visible) {
            return;
          }
          return (
            <GridItem size={size}>
              <Card
                name={name}
                subheading={subheading}
                onPress={onPress}
                icon={icon}
              />
            </GridItem>
          );
        })}
      </Grid>
    </ScrollView>
  );
};

export default ModuleAboutTable;
