import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {CompositeFieldsRender} from '@unthinkable/react-table';

import moment from 'moment';
import {Chip} from '@unthinkable/react-chip';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useInvoke} from '../../../controllers/useInvoke';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';
import {useProjectActions} from '../../pmt/actions/ProjectActions';

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      title={row?.last_communication}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const ContactRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'website',
          type: 'text',
        },
        {field: 'email', type: 'text'},
      ]}
    />
  );
};

const LostReasonRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'lost_description_comment', type: 'text'},
        {
          field: 'lost_date',
          type: 'date',
        },
      ]}
    />
  );
};

const POCRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_detail?.[0]?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
          numberOfLines: 1,
        }}>
        {row?.contact_detail?.[0]?.designation}
      </Text>
    </Row>
  );
};

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const underline = row?.website ? {textDecoration: 'underline'} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.website &&
          Linking.openURL(
            row?.website.startsWith('https')
              ? row?.website
              : `https://${row?.website}`,
            '_blank',
          );
      }}>
      <Text
        title={row?.name}
        style={{
          ...rowText,
          ...underline,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};

const NextTaskRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (row?.last_communication) {
    return (
      <>
        <Text
          numberOfLines={1}
          title={row?.next_task}
          style={{
            ...rowText,
            color: '#30839f',
            overflow: 'hidden',
          }}>
          {row?.next_task}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.next_task_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const NAME = {
  field: 'name',
  header: 'Lead',
  type: 'text',
};

const INDUSTRY = {
  field: 'industry.name',
  colorField: 'industry.color',
  header: 'Industry',
  type: 'colorTag',
};

const CONTACT = {
  header: 'Contact',
  render: ContactRender,
};

const EMPLOYEE_COUNT = {
  field: 'employee_count.number',
  header: 'Size',
};

const LEAD_STATUS = props => {
  const {row} = props;
  return <Chip value={row?.lead_status} />;
};

const STAGE_DATE = {
  field: 'sql_stage_date',
  header: 'SQL Stage Date',
  type: 'date',
};

const Ql_DATE = {
  field: 'sql_stage_date',
  header: 'QL Date',
  type: 'date',
};

const LOST_DATE = {
  field: 'lost_date',
  header: 'Lost Date',
  type: 'date',
};

const EXCEPTED_QL_DATE = {
  field: 'expected_ql_date',
  header: 'Excepted QL Date',
  type: 'date',
};

const OPPORTUNITY_DATE = {
  field: 'opportunity_date',
  header: 'Opportunity Date',
  type: 'date',
};

const UQL_STAGE_DATE = {
  field: 'uql_stage_date',
  header: 'UQL Date',
  type: 'date',
};

const LEAD_DATE = {
  field: 'uql_stage_date',
  header: 'Lead Date',
  type: 'date',
};

const STAGE = {
  field: 'stage.name',
  colorField: 'stage.color',
  header: 'Stage',
  type: 'colorTag',
};

const CAMPAIGN = {
  field: 'campaign_id.name',
  header: 'Campaign',
  type: 'text',
};

const COUNTRY = {
  field: 'country.name',
  colorField: 'country.color',
  header: 'Country',
  type: 'colorTag',
};

const LAST_COMMUNICATION = communications => ({
  header: 'Communication',
  width: 150,
  render: CommunicationRender,
  onPress: communications,
});

const OWNER = {
  header: 'Owner',
  field: 'owner',
  type: 'userAvatar',
  width: 80,
};

const DATE = {
  header: 'Date',
  field: 'sql_stage_date',
  type: 'date',
};

const CHANNEL = {
  header: 'Channel',
  field: 'campaign_type.label',
  colorField: 'campaign_type.color',
  type: 'colorTag',
};

const LEAD_SCORE = {
  header: 'Value',
  field: 'lead_score',
  toFixed: 2,
  width: 100,
  align: 'right',
};

const NEXT_TASK = {
  header: 'Next Task',
  render: NextTaskRender,
  // width: 300
};

const markLeadWon = ({invoke}) => ({
  title: 'Mark Won',
  confirm: {
    title: 'Mark Won',
    message: 'Do you want mark as won?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/customers`,
      props: {
        ...row,
        stage: '53a44d07faba6a0200b67fb5',
        potential_customer: row?._id,
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
      },
    });
  },
});

export const AllLeadTable = props => {
  let {
    route: {params},
    filter,
    navigation,
    searchValue,
    filterParams,
    allLeadApi,
  } = props;
  const {communications, updateUqlDate, updateOwnerRowAction} =
    leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  return (
    <Table
      params={params}
      // {...allLeadApi}
      api={`/potentialcustomers/allLeads`}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      eventSourceId={['Lead', 'communicationAdded']}
      // fields={{
      //   name: 1,
      //   email: 1,
      //   website: 1,
      //   lead_score: 1,
      //   industry: {name: 1},
      //   employee_count: {number: 1},
      //   country: {name: 1},
      //   stage: {name: 1},
      //   last_communication_date: 1,
      //   last_communication: 1,
      //   lead_status: 1,
      //   campaign_id: {name: 1},
      //   sql_stage_date: 1,
      //   created_on: 1,
      //   uql_stage_date: 1
      // }}
      sort={{created_on: -1}}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
          fromAllLead: true,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        // INDUSTRY,
        {...CONTACT, width: 300},
        {...EMPLOYEE_COUNT, width: 150},
        // STAGE_DATE,
        // CHANNEL,
        {...COUNTRY, width: 300},
        {...CAMPAIGN},
        {...STAGE, width: 100},
        {...UQL_STAGE_DATE, width: 100},
        OWNER,
        // LEAD_SCORE,
        {header: 'Status', render: LEAD_STATUS, width: 120},
        // LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Update UQL Date',
          onPress: updateUqlDate,
        },
        {
          title: 'Update Owner',
          onPress: updateOwnerRowAction,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const ActiveLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {
    user: {email: employeeEmail},
  } = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    addCommunication,
    updateStage,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
    moveToRql,
    moveToProposalSubmitted,
    markNurturable,
    moveToOpportunity,
    moveToRPS,
    makeCustomer,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  const invoke = useInvoke({method: 'post', eventSourceId: 'Lead'});
  return (
    <Table
      api={`/potentialcustomers/activeLeads`}
      params={params}
      sort={{
        activity_date: -1,
      }}
      eventSourceId={['Lead', 'communicationAdded', 'Customer']}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        {...CHANNEL, width: 150},
        OWNER,
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        // {
        //   title: 'Mark Nurturable',
        //   onPress: markNurturable,
        // },
        {
          title: 'Mark Lost',
          onPress: markLost,
        },
        ...(employeeEmail === 'amit.singh@daffodildb.com' ||
        employeeEmail === 'sushil.nagvan@daffodilsw.com'
          ? [
              {
                title: 'Mark RQL',
                onPress: moveToRql,
              },
            ]
          : []),
        {
          title: 'Move to Opportunity',
          onPress: moveToOpportunity,
        },
        {
          title: 'Move to RPS',
          onPress: moveToRPS,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Make Customer',
          onPress: makeCustomer,
        },
        markLeadWon({invoke}),
      ]}
    />
  );
};

const markProposalNurturable = ({invoke}) => ({
  title: 'Mark Nurturablee',
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/potentialcustomers/${row?._id}`,
      props: {
        lead_status: 'Nurturable',
        nurturable_date: getZeroTimeDate(new Date()),
      },
    });
  },
});

export const ProposalSubmittedTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  const {product} = params;
  params = {
    ...rest,
  };

  const invoke = useInvoke({method: 'put', eventSourceId: 'Lead'});

  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    addCommunication,
    updateStage,
    markNurturable,
    makeCustomer,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
  } = leadControllers(props);

  params = {...params, ...filterParams};

  return (
    <Table
      api={`/potentialcustomers/proposalSubmitted`}
      eventSourceId={['Lead', 'Customer']}
      params={params}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      searchFields="name"
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`proposal-submitted-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          width: 300,
        },
        EMPLOYEE_COUNT,
        INDUSTRY,
        COUNTRY,
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        CHANNEL,
        OWNER,
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Mark Lost',
          onPress: markLost,
        },
        markProposalNurturable({invoke}),
        {
          title: 'Make Customer',
          onPress: makeCustomer,
        },
        {
          title: 'Make Custome Platform Opportunity',
          onPress: makeCustomeOpportunity,
        },
        {
          title: 'Make Staff Augmentation Opportunity',
          onPress: makeStaffAugmentationOpportunity,
        },
        {
          title: 'Make RPS',
          onPress: makeRps,
        },
      ]}
    />
  );
};

export const NurturableLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {markLost, createDuplicateLead, communications} =
    leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  return (
    <Table
      api={`/potentialcustomers/nurturableLeads`}
      eventSourceId={['Lead', 'communicationAdded']}
      params={params}
      addOnFilter={filter}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      // addOnFilter={filter}
      searchFields={['name', 'website', 'email']}
      search={searchValue}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        {...CHANNEL, width: 150},
        OWNER,
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Mark Lost',
          onPress: markLost,
        },
        {
          title: 'Create New',
          onPress: createDuplicateLead,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const LostLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {markNurturable, createDuplicateLead, communications, showContacts} =
    leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      api={`/potentialcustomers/lostLeads`}
      eventSourceId={['Lead', 'communicationAdded']}
      params={params}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      addOnFilter={filter}
      searchFields={['name', 'website', 'email']}
      search={searchValue}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        {...CHANNEL, width: 150},
        OWNER,
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
        {
          header: 'Reason for Lost',
          render: LostReasonRender,
          width: 150,
        },
      ]}
      moreActions={() => [
        // {
        //   title: 'Mark Nurturable',
        //   onPress: markNurturable,
        // },
        {
          title: 'Create New',
          onPress: createDuplicateLead,
        },
        {
          title: 'Show Contacts',
          onPress: showContacts,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const PointOfContactTable = props => {
  const params = props.route.params;
  return (
    <Table
      api={`/lead/pointOfContact`}
      params={params}
      columns={[
        {
          header: 'First Name',
          field: 'first_name',
          width: 150,
        },
        {
          field: 'last_name',
          header: 'Last Name',
          width: 100,
        },
        {
          field: 'designation',
          header: 'Designation',
          width: 200,
        },
        {
          field: 'email',
          header: 'Email',
          width: 250,
        },
        {
          field: 'linkedin_url',
          header: 'Linkedin URL',
          // width: 250
        },
        {
          field: 'phone_no',
          header: 'Phone No',
        },
      ]}
    />
  );
};

export const RQLTable = props => {
  let {
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {communications} = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      params={params}
      api={`/potentialcustomers/rql`}
      eventSourceId={['Lead', 'communicationAdded']}
      addOnFilter={filter}
      search={searchValue}
      searchFields={['name', 'website', 'email']}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        {...CHANNEL, width: 150},
        OWNER,
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
        {
          header: 'Reason for Rejection',
          render: LostReasonRender,
          // width: 300 ///////////??? width stuff
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const UQLTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    markNurturable,
    makeLead,
    markUnsubscribe,
    moveToRPS,
    pageVisits,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  return (
    <Table
      api={`/potentialcustomers/uql`}
      params={params}
      eventSourceId={['Lead', 'communicationAdded']}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        {...CHANNEL, width: 150},
        OWNER,
        {header: 'Status', render: LEAD_STATUS, width: 100},
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        // {
        //   title: 'Mark Nurturable',
        //   onPress: markNurturable,
        // },
        {
          title: 'Mark Lost',
          onPress: markLost,
        },
        {
          title: 'Move to QL',
          onPress: makeLead,
        },
        {
          title: 'Move to RPS',
          onPress: moveToRPS,
        },
        {
          title: 'Mark Unsubscribe',
          confirm: {
            title: 'Mark Unsubscribe',
            message: 'Are you sure you want to mark this lead as unsubscribe?',
          },
          onPress: markUnsubscribe,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
        {
          title: 'Page Visits',
          onPress: pageVisits,
          visible: ({row}) => {
            return row?.hubspot_contact_id;
          },
        },
        // {
        //   title: 'Mark RQL',
        //   onPress: moveToRql,
        // },
        // {
        //   title: 'Move to Opportunity',
        //   onPress: moveToOpportunity,
        // },
      ]}
    />
  );
};

export const UnsubscribedTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {communications} = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });

  params = {...params, ...filterParams};
  return (
    <Table
      api={`/potentialcustomers/unsubscribed`}
      params={params}
      eventSourceId={['Lead', 'communicationAdded']}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        {...CHANNEL, width: 150},
        OWNER,
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const ActivityLeadTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  const {
    user: {email: employeeEmail},
  } = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    markLost,
    updateLeadStatus,
    updateOwner,
    communications,
    moveToRql,
    markNurturable,
    moveToOpportunity,
    moveToRPS,
  } = leadControllers(props);
  const {activityLogDetailForLead} = useProjectActions({
    navigation,
    params,
  });
  params = {...params, ...filterParams};
  return (
    <Table
      api={`/potentialcustomers/activeLeads`}
      params={params}
      eventSourceId={['Lead', 'communicationAdded']}
      sort={{
        activity_date: -1,
      }}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      searchFields={['name', 'website', 'email']}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          // width: 220,
        },
        // EMPLOYEE_COUNT,
        // INDUSTRY,
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 300},
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
        {...NEXT_TASK, width: 150},
      ]}
      moreActions={() => [
        // {
        //   title: 'Mark Nurturable',
        //   onPress: markNurturable,
        // },
        {
          title: 'Mark Lost',
          onPress: markLost,
        },
        ...(employeeEmail === 'amit.singh@daffodildb.com' ||
        employeeEmail === 'sushil.nagvan@daffodilsw.com' ||
        employeeEmail === 'aditya.mehrotra@unthinkable.co' ||
        employeeEmail === 'aswathy.krishnankutty@unthinkable.co'
          ? [
              {
                title: 'Mark RQL',
                onPress: moveToRql,
              },
            ]
          : []),
        {
          title: 'Move to Opportunity',
          onPress: moveToOpportunity,
        },
        {
          title: 'Move to RPS',
          onPress: moveToRPS,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForLead,
        },
      ]}
    />
  );
};

export const PotentialCustomerForLeadToQl = props => {
  let {
    route: {params},
    navigation,
    filter = {},
    search,
  } = props;
  return (
    <Table
      api={`/potentialcustomers`}
      searchFields={['name', 'website', 'email']}
      addOnFilter={{...filter}}
      search={search}
      eventSourceId={['Lead', 'communicationAdded']}
      fields={{
        name: 1,
        email: 1,
        website: 1,
        lead_score: 1,
        industry: {name: 1},
        employee_count: {number: 1},
        country: {name: 1},
        stage: {name: 1},
        last_communication_date: 1,
        last_communication: 1,
        lead_status: 1,
        campaign_id: {name: 1},
        sql_stage_date: 1,
        created_on: 1,
        uql_stage_date: 1,
        lost_date: 1,
        expected_ql_date: 1,
        opportunity_date: 1,
        owner: {name: 1, color: 1},
      }}
      sort={{created_on: -1}}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
          row,
          fromAllLead: true,
        });
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          width: 180,
        },
        {...CONTACT, width: 200},
        {...EMPLOYEE_COUNT, width: 150},
        {...COUNTRY, width: 130},
        {...CAMPAIGN},
        {...STAGE, width: 100},
        {...LEAD_DATE, width: 100},
        {...Ql_DATE, width: 100},
        {...LOST_DATE, width: 100},
        {...EXCEPTED_QL_DATE, width: 120},
        {...OPPORTUNITY_DATE, width: 120},
        OWNER,
        {header: 'Status', render: LEAD_STATUS, width: 120},
      ]}
    />
  );
};
