export const ToastStyles = ({theme: {colors, fonts}}) => {
    return {
      success: {
        containerStyle: {
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 30,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        },
        textContainerStyle: {
          backgroundColor: colors.SUCCESS_HIGH,
          paddingTop: 8,
          paddingRight: 16,
          paddingBottom: 8,
          paddingLeft: 16,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
        },
        textStyle: {color: colors.SUCCESS_LOW, ...fonts.CAPTION_LARGE},
      },
      error: {
        containerStyle: {
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 30,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        },
        textContainerStyle: {
          backgroundColor: colors.ERROR_HIGH,
          paddingTop: 8,
          paddingRight: 16,
          paddingBottom: 8,
          paddingLeft: 16,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
        },
        textStyle: {color: colors.ERROR_LOW, ...fonts.CAPTION_LARGE},
      },
    };
  };
  