import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {
  AboutMeAccount,
  AboutMeEducation,
  AboutMePersonal,
  AboutMePreviousExperience,
  AboutMeProfile,
  AboutMeStatutory,
} from './AboutMeViews';

export const AboutMeTab = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    // product,
    organization,
  };
  let tabs = {};
  tabs.profile = {
    label: 'Profile',
    view: <AboutMeProfile {...props} params={params} />,
  };
  tabs.personal = {
    label: 'Personal',
    view: <AboutMePersonal {...props} params={params} />,
  };

  tabs.education = {
    label: 'Education',
    view: <AboutMeEducation {...props} params={params} />,
  };
  tabs.account = {
    label: 'Account',
    view: <AboutMeAccount {...props} params={params} />,
  };
  tabs.statutory = {
    label: 'Statutory',
    view: <AboutMeStatutory {...props} params={params} />,
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
