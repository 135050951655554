import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  AllocationsStatus,
  AnchorBillingStatus,
  BillingStatus,
  DeliveryOwnerBillingStatus,
  DeliveryOwnerWise,
  InternalBilling,
  ModuleOwnerBillingStatus,
  SelfBillingStatus,
  TechBillingStatus,
  TechnicalLeadWise,
} from './SkillLeaderDashboard';
import {useAppStateContext} from '../../../providers/AppState';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';

export const BillingStatusReportTab = props => {
  const {route: {params} = {}, fromICMenu, fromDepartment = false} = props;
  const {
    extraAddOnFilterDeliveryOwner = {},
    extraAddOnFilterSkillLeader = {},
    department,
  } = params || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    // filter: {
    //   'delivery_owner.department': department,
    //   'delivery_owner.employee_status': 'Active',
    // },
  });

  const {
    filterValues: filterValuesTechinalLead,
    searchValue: searchValueTechinalLead,
    onChangeFilter: onChangeFilterTechinalLead,
    applyFilter: applyFilterTechinalLead,
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      department,
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    filter: filterTechinalLead,
    params: {period: techLeadPeriod = {}} = {},
  } = filterValuesTechinalLead || {};

  const tabs = {
    delivery_owner_wise: {
      label: fromDepartment ? 'By Lead' : 'Delivery Owner Wise',
      view: (
        <DeliveryOwnerWise
          filterParams={{...filter, ...extraAddOnFilterDeliveryOwner}}
          period={period}
          searchValue={searchValue}
          tab="Delivery Owner Wise"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              valueField: 'name',
              field: 'delivery_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ],
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        ...extraAddOnFilterDeliveryOwner,
      },
      params: {...params, period},
    },

    //   technical_lead_wise: {
    //     label: 'Technical Lead Wise',
    //     view: (
    //       <TechnicalLeadWise
    //         searchValue={searchValueTechinalLead}
    //         filterParams={{...filterTechinalLead, ...extraAddOnFilterSkillLeader}}
    //         period={techLeadPeriod}
    //         tab="Technical Lead Wise"
    //         {...props}
    //       />
    //     ),
    //     actions: [
    //       <PeriodRangeMonthWiseBreakupFilter
    //         onChangeFilter={onChangeFilterTechinalLead}
    //         filterValues={filterValuesTechinalLead}
    //       />,
    //       <GroupFilter
    //         filterValues={filterValuesTechinalLead}
    //         applyFilter={applyFilterTechinalLead}
    //         filters={[
    //           {
    //             type: 'autoComplete',
    //             label: 'Technical Lead',
    //             api: '/employees',
    //             placeholder: 'Select',
    //             suggestionField: 'name',
    //             secondarySuggestionField: 'official_email_id',
    //             valueField: 'name',
    //             field: 'skill_leader',
    //           },
    //         ]}
    //       />,
    //     ],
    //     eventSourceId: 'allocatedResource',
    //     addOnFilter: {
    //       ...filterTechinalLead,
    //       ...extraAddOnFilterSkillLeader,
    //     },
    //     params: {...params, period},
    //   },
    // };

    // allocation_billing_status: {
    //   label: 'Allocations',
    //   view: (
    //     <AllocationsStatus
    //       searchValue={searchValueTechinalLead}
    //       filterParams={{...filterTechinalLead, ...extraAddOnFilterSkillLeader}}
    //       period={techLeadPeriod}
    //       tab="Allocations"
    //       {...props}
    //     />
    //   ),
    //   actions: [
    //     <PeriodRangeMonthWiseBreakupFilter
    //       onChangeFilter={onChangeFilterTechinalLead}
    //       filterValues={filterValuesTechinalLead}
    //     />,
    //     <GroupFilter
    //       filterValues={filterValuesTechinalLead}
    //       applyFilter={applyFilterTechinalLead}
    //       filters={[
    //         {
    //           type: 'autoComplete',
    //           label: 'Technical Lead',
    //           api: '/employeeSuggestions',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           secondarySuggestionField: 'official_email_id',
    //           valueField: 'name',
    //           field: 'skill_leader',
    //         },
    //       ]}
    //     />,
    //   ],
    //   eventSourceId: 'allocatedResource',
    //   addOnFilter: {
    //     ...filterTechinalLead,
    //     ...extraAddOnFilterSkillLeader,
    //   },
    //   params: {...params, period},
    // },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const AnchorBillingStatusReportTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {extraAddOnFilterSkillLeader = {}} = params || {};
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {
    filterValues: filterValuesTechinalLead,
    searchValue: searchValueTechinalLead,
    onChangeFilter: onChangeFilterTechinalLead,
    applyFilter: applyFilterTechinalLead,
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    filter: filterTechinalLead,
    params: {period: techLeadPeriod = {}} = {},
  } = filterValuesTechinalLead || {};

  const tabs = {
    billing_status: {
      label: 'Anchor Billing Status',
      view: (
        <AnchorBillingStatus
          searchValue={searchValueTechinalLead}
          filterParams={{...filterTechinalLead, ...extraAddOnFilterSkillLeader}}
          period={techLeadPeriod}
          tab="Anchor Billing Status"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilterTechinalLead}
          filterValues={filterValuesTechinalLead}
        />,
        <GroupFilter
          filterValues={filterValuesTechinalLead}
          applyFilter={applyFilterTechinalLead}
          filters={[
            {
              type: 'autoComplete',
              label: 'Anchor Resource',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    skipFields: true,
                    api: '/allocation/anchorBillingStatusreports',
                    addOnFilter: {
                      ...filterTechinalLead,
                      ...extraAddOnFilterSkillLeader,
                    },
                    params: {
                      period: techLeadPeriod,
                      fromAnchorResource: true,
                    },
                  },
                  column: {
                    Employee: 'employee.name',
                    Email: 'employee.official_email_id',
                    Experience: 'employee.experience',
                    Allocation: 'anchorresourceCount',
                    'Delivered Hours': 'anchorResourceDeliveredHours',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TechBillingStatusReportTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {extraAddOnFilterSkillLeader = {}} = params || {};
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {
    filterValues: filterValuesTechinalLead,
    searchValue: searchValueTechinalLead,
    onChangeFilter: onChangeFilterTechinalLead,
    applyFilter: applyFilterTechinalLead,
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    filter: filterTechinalLead,
    params: {period: techLeadPeriod = {}} = {},
  } = filterValuesTechinalLead || {};

  const tabs = {
    billing_status: {
      label: 'Tech Billing Status',
      view: (
        <TechBillingStatus
          searchValue={searchValueTechinalLead}
          filterParams={{...filterTechinalLead, ...extraAddOnFilterSkillLeader}}
          period={techLeadPeriod}
          tab="Billing Status"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilterTechinalLead}
          filterValues={filterValuesTechinalLead}
        />,
        <GroupFilter
          filterValues={filterValuesTechinalLead}
          applyFilter={applyFilterTechinalLead}
          filters={[
            {
              type: 'autoComplete',
              label: 'Technical Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    skipFields: true,
                    api: '/allocation/techLeadBillingStatusreports',
                    addOnFilter: {
                      ...filterTechinalLead,
                      ...extraAddOnFilterSkillLeader,
                    },
                    params: {
                      period: techLeadPeriod,
                      fromTechLead: true,
                    },
                  },
                  column: {
                    Employee: 'employee.name',
                    Email: 'employee.official_email_id',
                    Experience: 'employee.experience',
                    Allocation: 'techleadCount',
                    'Delivered Hours': 'teachLeadDeliveredHours',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const InternalBillingTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const tabs = {
    internalBilling: {
      label: 'Internal Billing',
      view: (
        <InternalBilling
          filterValues={filterValues}
          period={filterValues?.params}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Module Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'project_module_owner',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const DeliveryOwnerBillingStatusReportTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {extraAddOnFilterSkillLeader = {}} = params || {};
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {
    filterValues: filterValuesTechinalLead,
    searchValue: searchValueTechinalLead,
    onChangeFilter: onChangeFilterTechinalLead,
    applyFilter: applyFilterTechinalLead,
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    filter: filterTechinalLead,
    params: {period: techLeadPeriod = {}} = {},
  } = filterValuesTechinalLead || {};

  const tabs = {
    billing_status: {
      label: 'Delivery Billing Status',
      view: (
        <DeliveryOwnerBillingStatus
          searchValue={searchValueTechinalLead}
          filterParams={{...filterTechinalLead, ...extraAddOnFilterSkillLeader}}
          period={techLeadPeriod}
          tab="Delivery Billing Status"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilterTechinalLead}
          filterValues={filterValuesTechinalLead}
        />,
        <GroupFilter
          filterValues={filterValuesTechinalLead}
          applyFilter={applyFilterTechinalLead}
          filters={[
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    skipFields: true,
                    api: '/allocation/deliveryOwnerBillingStatusreports',
                    addOnFilter: {
                      ...filterTechinalLead,
                      ...extraAddOnFilterSkillLeader,
                    },
                    params: {
                      period: techLeadPeriod,
                      fromDeliveryOwner: true,
                    },
                  },
                  column: {
                    Employee: 'employee.name',
                    Email: 'employee.official_email_id',
                    Experience: 'employee.experience',
                    Allocation: 'deliveryOwnerCount',
                    'Delivered Hours': 'deliveryOnwerDeliveredHours',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const SelfBillingStatusReportTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const {extraAddOnFilterDeliveryOwner = {}, extraAddOnFilterSkillLeader = {}} =
    params || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    delivery_owner_wise: {
      label: 'Self Billing Status',
      view: (
        <SelfBillingStatus
          filterParams={{...filter, ...extraAddOnFilterDeliveryOwner}}
          period={period}
          searchValue={searchValue}
          tab="Self Billing Status"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    skipFields: true,
                    api: '/selfbillingStatusDeliveryOwnerWiseReport',
                    addOnFilter: {
                      ...filter,
                      ...extraAddOnFilterSkillLeader,
                    },
                    params: {
                      period,
                    },
                  },
                  column: {
                    'Delivery Owner': 'name',
                    Email: 'official_email_id',
                    Experience: 'experienceInYears',
                    'Self Billing': 'self_billed',
                    'With Shadow': 'selfBilledWithShadowCount',
                    'Without Shadow': 'selfBilledWithoutShadowCount',
                    'Allocation Hrs.': 'selfBilledAllocationHours',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const ModuleOwnerBillingStatusReportTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const {extraAddOnFilterSkillLeader = {}} = params || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {
    filterValues: filterValuesTechinalLead,
    searchValue: searchValueTechinalLead,
    onChangeFilter: onChangeFilterTechinalLead,
    applyFilter: applyFilterTechinalLead,
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    filter: filterTechinalLead,
    params: {period: techLeadPeriod = {}} = {},
  } = filterValuesTechinalLead || {};

  const tabs = {
    module_owner_billing_status: {
      label: 'Module Owner Billing Status',
      view: (
        <ModuleOwnerBillingStatus
          searchValue={searchValueTechinalLead}
          filterParams={{...filterTechinalLead, ...extraAddOnFilterSkillLeader}}
          period={techLeadPeriod}
          tab="Module Owner Billing Status"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilterTechinalLead}
          filterValues={filterValuesTechinalLead}
        />,
        <GroupFilter
          filterValues={filterValuesTechinalLead}
          applyFilter={applyFilterTechinalLead}
          filters={[
            {
              type: 'autoComplete',
              label: 'Module Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'project_module_owner',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    skipFields: true,
                    api: '/projectModuleOwnerDeliveryPhases',
                    addOnFilter: {
                      ...filterTechinalLead,
                      ...extraAddOnFilterSkillLeader,
                    },
                    params: {
                      period: techLeadPeriod,
                      fromAnchorResource: true,
                    },
                  },
                  column: {
                    Employee: 'owner.name',
                    Email: 'owner.official_email_id',
                    Experience: 'owner.experience',
                    Allocation: 'totalWorkload',
                    'Delivered Hours': 'totalDelivered',
                    'Project Delivered': 'delivedHours',
                    'Total Project Delivered': 'totalDelivedHours',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const BillingStatusReportSelfTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {
    filterValues: filterValuesTechinalLead,
    searchValue: searchValueTechinalLead,
    onChangeFilter: onChangeFilterTechinalLead,
    applyFilter: applyFilterTechinalLead,
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    filter: filterTechinalLead,
    params: {period: techLeadPeriod = {}} = {},
  } = filterValuesTechinalLead || {};

  const {user} = useAppStateContext();
  const {employee = {}} = user || {};
  const tabs = {
    delivery_owner_wise: {
      label: 'Delivery Owner Wise',
      view: (
        <DeliveryOwnerWise
          filterParams={{...filter, delivery_owner: employee?._id}}
          period={period}
          searchValue={searchValue}
          tab="Delivery Owner Wise"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ],
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
      },
      params: {...params, period},
    },

    technical_lead_wise: {
      label: 'Technical Lead Wise',
      view: (
        <TechnicalLeadWise
          searchValue={searchValueTechinalLead}
          filterParams={{...filterTechinalLead, skill_leader: employee?._id}}
          period={techLeadPeriod}
          tab="Technical Lead Wise"
          {...props}
        />
      ),
      actions: [
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilterTechinalLead}
          filterValues={filterValuesTechinalLead}
        />,
        <GroupFilter
          filterValues={filterValuesTechinalLead}
          applyFilter={applyFilterTechinalLead}
          filters={[
            {
              type: 'autoComplete',
              label: 'Technical Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ],
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filterTechinalLead,
      },
      params: {...params, period},
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
