import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {dateFormatter} from '@unthinkable/react-date-picker';

export const TimeDuration = ({start, end}) => {
  if (!start || !end) return null;
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text
        style={{
          color: '#A3A3A3',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
        }}>
        {start._i} - {end._i}
      </Text>
    </View>
  );
};

export const Durations = ({styles, toDate, fromDate, subTitle = ''}) => {
  if (!toDate || !fromDate) return null;
  const isZeroTime = true;
  const format = 'DD MMM YY';
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text
        style={
          styles
            ? {...styles, color: '#A3A3A3'}
            : {
                color: '#A3A3A3',
                fontSize: '14px',
                fontFamily: 'Inter-Regular',
                lineHeight: '20px',
              }
        }>
        {dateFormatter(fromDate, format, isZeroTime)} -{' '}
        {dateFormatter(toDate, format, isZeroTime)}
      </Text>
      {subTitle.length ? (
        <Text
          style={{
            color: '#A3A3A3',
            fontSize: '10px',
            fontFamily: 'Inter-Regular',
            lineHeight: '16px',
          }}>
          {subTitle}
        </Text>
      ) : (
        void 0
      )}
    </View>
  );
};
