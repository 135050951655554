import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const VerificationAssetStatus = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'ActiveITAsset',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      defaultValues={{
        verified_on: new Date(),
      }}
      mode="edit"
      fields={{
        is_verified: 1,
      }}
      header={{
        title: 'Please Update The Verification Status',
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Verification Status',
          field: 'is_verified',
          type: 'autoComplete',
          options: ['Accepted', 'Rejected'],
          required: true,
        },
      ]}
    />
  );
};
