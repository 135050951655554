import React from 'react';
import {Table} from '../../../components/table/Table';

export const ImportMappingList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  return (
    <Table
      api={`/importmappings`}
      eventSourceId={'mappingAdded'}
      fields={{
        name: 1,
        mapping: 1,
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
        },
        {
          header: 'Mapping',
          type: 'textArea',
          field: 'mapping',
          value: ({row}) => {
            let {mapping = []} = row || {};

            let result = mapping.reduce((acc, curr) => {
              let {header, field} = curr || {};
              acc[field] = header;

              return acc;
            }, {});
            return result;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-mapping', {row: {_id: row._id}});
          },
        },
      ]}
    />
  );
};
