import {
  Login,
  LoginMobile,
  LoginForm,
  LoginFormMobile,
  GoogleLoginButton,
  GoogleLoginButtonMobile,
} from '../modules/login/theme';
import pmtTheme from '../modules/pmt/theme';
import leadTheme from '../modules/leadGeneration/theme';

export default {
  ...pmtTheme,
  ...leadTheme,
  Login,
  LoginMobile,
  LoginForm,
  LoginFormMobile,
  GoogleLoginButton,
  GoogleLoginButtonMobile,
};
