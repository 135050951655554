//14-02-2024 Rohit Bansal  - open route passed in url

import {
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
} from '../modules/common/constants/SourceConstants';

export const DIRECT_ACCESS_URIS = {
  FEATURE_DETAIL: '/projects/{projectCode}/features/{featureCode}',
  FEATURE_COMMENTS: '/projects/{projectCode}/features/{featureCode}/comments',
  TASK_DETAIL: '/tasks/{taskCode}',
  TASK_COMMENTS: '/tasks/{taskCode}/comments',
  SET_PASSWORD: 'users/{password_token}/set-password',
};

export const DIRECT_ACCESS_URLS = {
  [DIRECT_ACCESS_URIS.FEATURE_DETAIL]: {
    link: ({params, row}) =>
      `/projects/${params.project.code}/features/${row?.code}`,
    paramsApi: '/screens/feature-detail-screen/params',
    onParams: params => {
      return {
        screen: 'project-home',
        params: {
          _id: params.project._id,
          project: {...params.project},
          __navigate: {
            screen: 'feature-detail-screen',
            params: {...params},
          },
        },
      };
    },
  },
  [DIRECT_ACCESS_URIS.FEATURE_COMMENTS]: {
    link: ({params, row}) =>
      `/projects/${params.project.code}/features/${row?.code}/comments`,
    paramsApi: '/screens/feature-detail-screen/params',
    onParams: params => {
      const {feature} = params;
      return {
        screen: 'project-home',
        params: {
          _id: params.project._id,
          project: {...params.project},
          __navigate: {
            screen: 'comment-table',
            params: {
              ...params,
              comment_source: {
                _id: PROJECT_FEATURE_TYPE_ID,
                label: 'Feature',
              },
              comment_source_id: {
                _id: feature._id,
                name: feature.feature,
              },
            },
          },
        },
      };
    },
  },
  [DIRECT_ACCESS_URIS.TASK_DETAIL]: {
    link: ({row}) => `/tasks/${row?.code}`,
    paramsApi: '/screens/task-detail-screen/params',
    onParams: params => {
      const {row, ...featureParams} = params;
      return {
        screen: 'project-home',
        params: {
          _id: params.project._id,
          project: {...params.project},
          __navigate: {
            screen: 'feature-detail-screen',
            params: {
              clickedSource: PROJECT_FEATURE_TYPE_ID,
              ...featureParams,
              __navigate: {
                screen: 'task-edit',
                params: {...params},
              },
            },
          },
        },
      };
    },
  },
  [DIRECT_ACCESS_URIS.TASK_COMMENTS]: {
    link: ({row}) => `/tasks/${row?.code}/comments`,
    paramsApi: '/screens/task-detail-screen/params',
    onParams: params => {
      const {row, ...featureParams} = params;
      return {
        screen: 'project-home',
        params: {
          _id: params.project._id,
          project: {...params.project},
          __navigate: {
            screen: 'feature-detail-screen',
            params: {
              clickedSource: PROJECT_FEATURE_TYPE_ID,
              ...featureParams,
              __navigate: {
                screen: 'comment-table',
                params: {
                  project: params?.project,
                  comment_source: {_id: PROJECT_TASK_TYPE_ID, label: 'Task'},
                  comment_source_id: {_id: row._id, name: row.name},
                },
              },
            },
          },
        },
      };
    },
  },
  [DIRECT_ACCESS_URIS.SET_PASSWORD]: {
    link: ({params}) => `/users/${params.token}/set-password`,
    paramsApi: '/verifyToken',
    isPublic: true,
    onParams: params => {
      return {
        screen: 'set-password',
        params: {
          password_token: params.password_token,
        },
      };
    },
  },
};
