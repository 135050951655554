import React, {useState} from 'react';

export const useGroupBy = ({options, defaultSelected} = {}) => {
  const defaultValue = options?.find(
    option => option.field === defaultSelected,
  );

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const onChangeGroupValue = value => {
    setSelectedValue(value);
  };

  const resetGroupBy = () => {
    setSelectedValue();
  };

  let addonGroupBy = void 0;
  let groupRow = void 0;

  if (selectedValue) {
    addonGroupBy = {
      _id: selectedValue.field,
      aggregates: {
        [selectedValue?.field]: 'first',
        count: 'count',
        ...selectedValue.aggregates,
      },
    };
    groupRow = selectedValue.groupRow;
  }

  return {
    value: selectedValue,
    onChangeGroupValue,
    resetGroupBy,
    allowDynamicGrouping: !!selectedValue,
    options,
    addonGroupBy,
    groupRow,
  };
};
