import {Table} from '../../../components/table/Table';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {TabView} from '../../../components/tab/TabView';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {Chip} from '../../../components/chip/Chip';
import {leadControllers} from '../controllers/leadControllers';
import React from 'react';
import {
  AutoCompleteFilter,
  GroupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {AddButton} from '../../../components/button/AddButton';

const ContactRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {field: 'website', type: 'text'},
      {
        field: 'contact_email',
        type: 'text',
      },
    ]}
  />
);

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  const last_communication_date =
    row?.['potential_customer']?.['last_communication_date'];
  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (last_communication_date &&
          moment(last_communication_date).format('DD MMM, YYYY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.next_task', type: 'text'},
        {
          field: 'potential_customer.next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const DesignationRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_id?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
          numberOfLines: 1,
        }}>
        {row?.contact_id?.designation}
      </Text>
    </Row>
  );
};

const POCDesignationRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
          numberOfLines: 1,
        }}>
        {row?.designation}
      </Text>
    </Row>
  );
};

const RenderContactName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {contact_linkedIn_url = '', website = ''} = row;
  const underline =
    contact_linkedIn_url || website ? {textDecoration: 'underline'} : void 0;

  contact_linkedIn_url =
    contact_linkedIn_url &&
    (contact_linkedIn_url.startsWith('https')
      ? contact_linkedIn_url
      : `https://${contact_linkedIn_url}`);

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  return (
    <TouchableOpacity
      onPress={() => {
        (contact_linkedIn_url || website) &&
          Linking.openURL(contact_linkedIn_url || website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {row?.potential_customer?.name}
      </Text>
    </TouchableOpacity>
  );
};

const NAME = {
  field: 'potential_customer.name',
  header: 'Name',
};

const DESIGNATION = {
  field: 'contact_id.designation',
  header: 'Designation',
};

const LINKEDIN = {
  type: 'link',
  field: 'contact_linkedIn_url',
  header: 'LinkedIn URL',
  linkText: 'LinkedIn Id',
};

const CREATED_ON = {
  type: 'date',
  field: 'added_on',
  header: 'Created On',
};

const REQUEST_SEND_DATE = {
  type: 'date',
  field: row => row?.campaign_connected_at?.connection_request_date,
  header: 'Requested On',
};

const EXPECTED_CONNECTION_DATE = {
  type: 'date',
  header: 'Outreach Duration Ends',
  field: 'campaign_connected_at.expected_connection_date',
};

const LAST_COMMUNICATION = communications => ({
  header: 'Communication',
  render: CommunicationRender,
  onPress: communications,
});

const ContactEmailRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'contact_email', type: 'text'},
        {
          field: 'contact_linkedIn_url',
          type: 'text',
        },
      ]}
    />
  );
};

export const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {potential_customer = {}} = row || {};
  let {name = '', website = ''} = potential_customer || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  let displayName = name ? name : website ? website : '-';

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};

const CampaignContactsList = props => {
  const {
    route: {params},
    campaignContactsfilter,
    searchValue,
  } = props;
  const {fromInmailCampaign, fromEmailCampaign} = params;
  const {
    communicationsForContact,
    makeLeadFromCampaignContact,
    makeUqlFromCampaignContact,
    viewProspect,
    messageSentDetails,
    viewUpdatePotentialCustomer,
  } = leadControllers(props);
  return (
    <Table
      eventSourceId="communicationAdded"
      search={searchValue}
      searchFields={['contact_linkedIn_url', 'contact_email', 'website']}
      {...campaignContactsfilter}
      columns={[
        {
          header: 'Name',
          render: RenderContactName,
          width: 250,
        },
        {header: 'Contact', render: ContactEmailRender, width: 250},
        {
          header: 'Designation',
          render: DesignationRender,
          width: 250,
        },
        REQUEST_SEND_DATE,
        EXPECTED_CONNECTION_DATE,
        {
          header: 'Connected At',
          field: 'campaign_connected_at.connection_accept_date',
          type: 'date',
        },
        {
          header: 'First Message Date',
          field: 'campaign_connected_at.first_message_date',
          type: 'date',
        },
        {
          header: 'Message',
          field: 'campaign_message_count',
          type: 'number',
        },
        {
          header: 'Message Sent',
          field: 'message_sent_count',
          type: 'number',
          onPress: messageSentDetails,
        },
        fromEmailCampaign && {
          header: 'Message Duration',
          field: ' email_message_gap.message_duration',
          type: 'number',
        },
        fromEmailCampaign && {
          header: 'Outreach Duration',
          field: 'email_message_gap.outreach_duration',
          type: 'number',
        },
        fromInmailCampaign && {
          header: 'Message Duration',
          field: 'inmail_message_gap.message_duration',
          type: 'number',
        },
        fromInmailCampaign && {
          header: 'Outreach Duration',
          field: 'inmail_message_gap.outreach_duration',
          type: 'number',
        },
        {
          header: 'Lifecycle Start Date',
          field: 'lifecycle_start',
          type: 'date',
          width: 150,
        },
        {
          header: 'Lifecycle End Date',
          field: 'lifecycle',
          type: 'date',
          width: 150,
        },
        {
          header: 'Lead Date',
          field: 'uql_stage_date',
          type: 'date',
        },
      ]}
      moreActions={[
        {
          title: 'Move to QL',
          onPress: makeLeadFromCampaignContact,
        },
        {
          title: 'Move to UQL',
          onPress: makeUqlFromCampaignContact,
        },
        {
          title: 'Prospect',
          onPress: viewProspect,
        },
        {
          title: 'Update Prospect',
          onPress: viewUpdatePotentialCustomer,
        },
      ]}
      {...props}
    />
  );
};

const SalesCampaignContactsList = props => {
  const {
    route: {params},
    salesCampaignContactsfilter,
    searchValue,
  } = props;
  const {row} = params || {};
  return (
    <Table
      eventSourceId="communicationAdded"
      search={searchValue}
      searchFields={['website']}
      api={`/campaign/${row?._id}/sales-campaign-contacts`}
      {...salesCampaignContactsfilter}
      columns={[
        {
          header: 'Name',
          field: 'first_name',
          type: 'text',
        },
        {
          header: 'Designation',
          render: POCDesignationRender,
        },
        {
          header: 'Email',
          field: 'email',
          type: 'text',
        },
        {
          header: 'Website',
          render: RenderName,
        },
      ]}
      {...props}
    />
  );
};

export const ContactListForCampaign = props => {
  const {route: {params} = {}} = props;
  let {filter = {}, row} = params;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  let {filter: filters = {}} = filterValues || {};
  filter = {...filter, ...filters};
  const campaignContactsfilter = {
    api: `/campaign/${row?._id}/campaign-contacts`,
    filter: filter,
    params,
  };

  const tabs = {
    active: {
      label: `Contacts`,
      view: (
        <CampaignContactsList
          campaignContactsfilter={campaignContactsfilter}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AutoCompleteFilter
          field="potential_customer"
          api="/potentialCustomers"
          searchField="name"
          suggestionField="name"
          placeholder="Select Potential Customer"
          valueField="name"
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const SalesContactListForCampaign = props => {
  const {route: {params} = {}} = props;
  let {filter = {}, row} = params;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  let {filter: filters = {}} = filterValues || {};
  filter = {...filter, ...filters};
  const salesCampaignContactsfilter = {
    // api: `/campaign/${row?._id}/sales-campaign-contacts`,
    filter: filter,
    params,
  };
  const tabs = {
    active: {
      label: `Contacts`,
      view: (
        <SalesCampaignContactsList
          salesCampaignContactsfilter={salesCampaignContactsfilter}
          searchValue={searchValue}
        />
      ),
      ...salesCampaignContactsfilter,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AutoCompleteFilter
          field="potential_customer"
          api="/potentialCustomers"
          searchField="name"
          suggestionField="name"
          placeholder="Select Potential Customer"
          valueField="name"
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const ContactListForCampaignForOutreachBatch = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignContactsfilter = {
    api: '/campaigncontacts',
    filter: {
      ...filter,
      batch_id: row?._id,
    },
  };

  const tabs = {
    active: {
      label: `Contacts (${row?.name})`,
      view: (
        <CampaignContactsList campaignContactsfilter={campaignContactsfilter} />
      ),
      ...campaignContactsfilter,
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const AllCampaignContactList = props => {
  const {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  let {filter: filters = {}} = filterValues || {};
  let filter = {...filters};
  const campaignContactsfilter = {
    api: `/allcampaigncontacts`,
    params,
    filter,
  };

  const tabs = {
    active: {
      label: `All Contacts`,
      view: (
        <CampaignContactsList
          campaignContactsfilter={campaignContactsfilter}
          searchValue={searchValue}
          filter={filter}
        />
      ),
      ...campaignContactsfilter,
      actions: [
        <AddButton
          title=" Campaign Contact"
          view="add-all-campaign-contacts"
          params={props?.route?.params}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialCustomers',
              placeholder: 'Potential Customer',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Campaign Type',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'label',
              valueField: 'label',
              field: 'campaign_type',
            },
            {
              label: 'Campaign',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
