import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {Chip} from '../../../components/chip/Chip';

const deleted = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message: 'Are you sure you want to delete cloud?',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/cloudInfrastructures/${row?._id}`,
      props: {},
    });
  },
});

const Status = ({row}) => {
  return (
    <Chip
      displayTextColor
      value={row.status}
      title={row.status === 'Failed' ? row.error : ''}
      color={
        row.status === 'Success'
          ? 'success'
          : row.status === 'InProgress'
          ? 'information'
          : 'error'
      }
    />
  );
};

export const CloudInfraTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'CloudInfra',
  });

  return (
    <Table
      eventSourceId="CloudInfra"
      renderHeader={() => (
        <TableHeader
          title="Cloud Infrastructure"
          actions={[
            <AddButton
              title="Cloud Infrastructure"
              view="cloud-infra-add-form"
              params={params}
            />,
          ]}
        />
      )}
      api={`/cloudInfrastructures`}
      filter={{project_id: project._id}}
      onRowPress={({row}) => {
        navigation.navigate(`cloud-infra-detail`, {
          ...params,
          cloud: row,
        });
      }}
      columns={[
        {
          header: 'Configuration',
          field: 'setup_type',
          type: 'text',
          width: 150,
        },
        {
          header: 'Cloud',
          field: 'cloud',
          type: 'text',
        },
        {
          header: 'AWS service',
          field: 'cloud_service',
          type: 'text',
        },
        {
          render: Status,
          width: 150,
          align: 'right',
        },
      ]}
      moreActions={[deleted({deleteInvoke})]}
    />
  );
};
