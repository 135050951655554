import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {Col, Row, Text} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {LinkRenderer} from '../../../components/form/Renderers';
import {DeploymentPlatformCardStyle} from '../cellrenders/theme';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

const deleted = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message: 'Are you sure you want to Delete?',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/deploymentplatforms/${row?._id}`,
      props: {},
    });
  },
});

const DeploymentPlatformCard = ({row}) => {
  const {label, separator, title, secondaryTitle} = useStyles(
    DeploymentPlatformCardStyle,
  );
  return (
    <Row>
      <Col gap={4} style={{width: 100}}>
        <Text style={label}>{`Platform`}</Text>
        {row.url ? <Text style={label}>{`Url`}</Text> : void 0}
        {row.userName ? <Text style={label}>{`User`}</Text> : void 0}
      </Col>
      <Col gap={4} style={{paddingRight: 6}}>
        <Text style={separator}>{`:`}</Text>
        {row.url ? <Text style={separator}>{`:`}</Text> : void 0}
        {row.userName ? <Text style={separator}>{`:`}</Text> : void 0}
      </Col>
      <Col gap={4}>
        <Text style={title}>{`${row.platform}`}</Text>
        {row.url ? <LinkRenderer value={row.url} /> : void 0}
        {row.userName ? (
          <Text style={secondaryTitle}>{`${row.userName}`}</Text>
        ) : (
          void 0
        )}
      </Col>
    </Row>
  );
};

export const DeploymentPlatformsTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'DeploymentPlatform',
  });

  return (
    <Table
      eventSourceId="DeploymentPlatform"
      renderHeader={() => (
        <TableHeader
          title="Platforms"
          actions={[
            <AddButton
              title="Platform"
              view="deployment-platform-add-form"
              params={params}
            />,
          ]}
        />
      )}
      api={`/projects/${project?._id}/deploymentPlatforms`}
      variant={'none'}
      columns={[
        {
          render: props => {
            return <DeploymentPlatformCard {...props} />;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`deployment-platform-detail`, {
              ...params,
              deployment: {_id: row?._id},
            });
          },
        },
        deleted({deleteInvoke}),
      ]}
    />
  );
};
