import React from 'react';
import {Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {resolveExp} from '@unthinkable/react-utils';

export const FeaturesColumnRender = ({
  totalCountField,
  completedCountField,
  row,
}) => {
  let totalCount = resolveExp(row, totalCountField);
  let completedCount = resolveExp(row, completedCountField);

  const {colors, fonts} = useTheme();
  if (!totalCount) return null;

  let color = colors.NEUTRAL_HIGH;

  if (totalCount != 0 && completedCount != 0 && totalCount === completedCount) {
    color = colors.SUCCESS_HIGH;
  }

  let value = `${completedCount || 0}/${totalCount}`;

  return <Text style={{color, ...fonts.BODY3}}>{value}</Text>;
};
