import React from 'react';
import {Table} from '../../../components/table/Table';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {Tag} from '../../../components/chip/Chip';
import {TabView} from '../../../components/tab';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useInvoke} from '../../../controllers/useInvoke';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';

const updateResourceSkill = ({navigation, params}) => ({
  title: 'Update Resource Language',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-resource-skill', {
      row,
    });
  },
});

const SKILLS = {
  header: 'Language',
  field: 'resource_language.name',
  type: 'colorTag',
  colorField: 'resource_language.color',
  width: 200,
};

const RenderProject = props => {
  const {row} = props;
  let {
    project = {},
    potential_customer = {},
    proposed_deployment_type,
  } = row || {};
  return (
    <Tag
      value={
        proposed_deployment_type === 'New'
          ? potential_customer?.name
          : project.project
      }
    />
  );
};

const unMarkStatus = ({invoke}) => ({
  title: 'Un-Mark Status',
  confirm: {
    title: 'Un-Mark Status',
    message: 'Do you want un-mark the status of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        proposed_deployment_date: null,
        proposed_deployment_comment: null,
        is_proposed_deployment: null,
        bench_training_type: null,
        is_from_bench_resource: null,
        is_from_batch_trainee: null,
      },
    });
  },
});

export const PlanForDeployment = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      api={`/employee/planForDeployment`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        SKILLS,
        {
          header: 'Delivery Owner',
          width: 180,
          type: 'userAvatarChip',
          field: 'delivery_owner',
        },
        {
          header: 'Skill Mentor',
          width: 180,
          type: 'userAvatarChip',
          field: 'skill_leader',
        },
        {
          header: 'Project/Customer',
          render: RenderProject,
        },
        {
          header: 'Date',
          field: 'proposed_deployment_date',
          type: 'date',
        },
        {
          header: 'Comment',
          field: 'proposed_deployment_comment',
        },
      ]}
      moreActions={() => [
        unMarkStatus({invoke}),
        updateResourceSkill({navigation, params}),
      ]}
      params={params}
      {...props}
    />
  );
};

export const PlanForDeploymentTab = props => {
  let {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const tabs = {
    plan_deployment: {
      label: 'Plan For Deployment',
      view: (
        <PlanForDeployment
          filterParams={filterParams}
          params={params}
          search={searchValue}
          addOnFilter={filter}
        />
      ),
      api: '/employee/planForDeployment',
      limit: 2000,
      addOnFilter: filter,
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {...params},
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Proposed Deployment Date',
              field: 'proposed_deployment_date',
            },
          ]}
        />,
      ]}
    />
  );
};
