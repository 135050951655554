import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const ModuleForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {project, module} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Module',
    uri: '/projectmodules',
  });

  const data = {
    _id: module._id,
    project_id: module.project_id,
    owner_id: module.owner_id,
    module: module.module,
    desc: module.desc,
    dependencies: module.dependencies,
    scope: module.scope,
    color: module.color,
    identifier: module.identifier,
    status: module.status || 'active',
  };

  return (
    <Form
      readOnly={module.aiGenerated}
      onSubmit={onSubmit}
      submitAction="Save"
      data={data}
      mode={'edit'}
      layoutFields={[
        {
          label: 'Module Name',
          type: 'text',
          field: 'module',
          required: true,
          size: 6,
        },
        {
          label: 'Identifier',
          type: 'text',
          field: 'identifier',
          size: 6,
          // readOnly: true,
        },
        {
          label: 'Owner',
          field: 'owner_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          size: 6,
        },
        {
          collapsedFields: [
            {
              label: 'Referred Modules',
              type: 'multiAutoComplete',
              field: 'dependencies',
              api: `/projectmodules`,
              suggestionField: 'module',
              valueField: 'module',
              filter: {
                project_id: project?._id,
                _id: {$nin: [module?._id]},
                status: {$ne: 'archived'},
              },
            },
            {
              label: 'Description',
              type: 'aiTextArea',
              field: 'desc',
              inputProps: {
                minRows: 3,
                maxRows: 6,
              },
            },
            {
              label: 'Scope',
              type: 'aiTextArea',
              field: 'scope',
              inputProps: {
                minRows: 3,
                maxRows: 6,
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateModuleForm = props => {
  return <ModuleForm header="Module Detail" {...props} />;
};
