import React from 'react';
import moment from 'moment';
import {useFilter} from '@unthinkable/react-filters';

import {TabView} from '../../../components/tab';
import {AdHocTasksTable, CompletedTeamCustomTasksTable} from './TasksTable';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {taskFilters, useTaskActions} from './TaskActions';

export const AdHocTaskTabs = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {employee_team, assigned_to} = params;

  const {activityLogs, markDisqualified, markQualified} = useTaskActions({
    navigation,
    params,
  });

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {params: filterParams, filter: extraFilter} = filterValues;

  const addOnFilter = {
    is_ad_hoc: true,
    employee_team: employee_team,
    parent_task: {$exists: false},
    ...extraFilter,
  };

  if (filterParams.is_open == 'Yes') {
    addOnFilter.assigned_to = null;
  } else if (filterParams.is_open == 'No') {
    addOnFilter.assigned_to = {$exists: true};
  }

  const overdueTasksFilter = {
    status: 'active',
    ...addOnFilter,
    $and: [
      {
        due_date: {$lt: moment().utc().startOf('day').toDate()},
      },
    ],
  };

  return (
    <TabView
      selectedTab={'active'}
      params={params}
      tabs={{
        backlog: {
          label: 'Backlog',
          api: '/projecttasks',
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'backlog',
            ...addOnFilter,
          },
          view: (
            <AdHocTasksTable
              isBacklog
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={{
                status: 'backlog',
                ...addOnFilter,
              }}
            />
          ),
          actions: [
            <AddButton
              title="Task"
              view="add-ad-hoc-team-task"
              params={params}
            />,
          ],
        },
        active: {
          api: '/projecttasks',
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'active',
            ...addOnFilter,
          },
          label: 'Active',
          view: (
            <AdHocTasksTable
              isActive
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={{
                status: 'active',
                ...addOnFilter,
              }}
            />
          ),
          actions: [
            <AddButton
              title="Task"
              view="add-ad-hoc-team-task"
              params={{...params, status: 'active'}}
            />,
          ],
        },
        overdue: {
          api: '/projecttasks',
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: overdueTasksFilter,
          label: 'Overdue',
          view: (
            <AdHocTasksTable
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={overdueTasksFilter}
            />
          ),
        },
        completed: {
          label: 'Completed',
          api: '/projecttasks',
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'completed',
            ...addOnFilter,
          },
          view: (
            <CompletedTeamCustomTasksTable
              isCompleted
              parentSourceView="AdhocTask"
              filter={{
                is_ad_hoc: true,
                assigned_to,
              }}
              onRowPress={({row}) => {
                navigation.navigate('ad-hoc-task-edit', {
                  ...params,
                  row,
                  readOnly: true,
                });
              }}
              sort={{completed_on: -1}}
              employee_team={employee_team}
              searchValue={searchValue}
              addOnFilter={{
                status: 'completed',
                ...addOnFilter,
              }}
              moreActions={[markDisqualified, markQualified, activityLogs]}
              {...props}
            />
          ),
        },
        archived: {
          label: 'Archived',
          api: '/projecttasks',
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'archived',
            ...addOnFilter,
          },
          view: (
            <AdHocTasksTable
              searchValue={searchValue}
              addOnFilter={{
                status: 'archived',
                ...addOnFilter,
              }}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            taskFilters.assigned_to,
            taskFilters.reviewer,
            taskFilters.source,
            taskFilters.is_open,
            taskFilters.due_date,
            taskFilters.completed_on,
          ]}
        />,
      ]}
      {...props}
    />
  );
};
