import React from 'react';
import {Table} from '../../../components/table/Table';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {leadControllers} from '../controllers/leadControllers';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {customDigitalPlatformOppControllers} from '../controllers/customDigitalPlatformControllers';

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <>
      <View
        style={{
          borderRadius: 6,
          height: 10,
          width: 10,
          backgroundColor: INFORMATION_LOW,
        }}>
        <Image
          source={CommunicationBlue}
          style={{paddingLeft: 4, lineHeight: '18px', height: 15, width: 15}}
        />
      </View>
    </>
  );
};

const POCStatusRender = ({row}) => {
  if (row?.poc_status && row?.poc_status == 'POC Successful') {
    return (
      <Chip
        textColor="#62D06D"
        color="#F3FCF0"
        value={row?.poc_status}
        borderRadius={6}
      />
    );
  } else if (row?.poc_status && row?.poc_status == 'POC Fail') {
    return (
      <Chip
        textColor="#f99b2e"
        color="#FFF9F1"
        value={row?.poc_status}
        borderRadius={6}
      />
    );
  } else {
    return null;
  }
};

const RenderSkill = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const skillName = row?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return (
    <Text
      style={{
        ...rowText,
        backgroundColor: colors['ACCENT1_MEDIUM'],
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 4,
        paddingBottom: 4,
        overflow: 'hidden',
        borderRadius: 5,
      }}>
      {skillName}
    </Text>
  );
};

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

const LostRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description', type: 'text'},
        {
          field: 'outcome_date',
          type: 'date',
        },
      ]}
    />
  );
};

const POC_STATUS = {
  render: POCStatusRender,
  header: 'Status',
  widht: 60,
};

const LOST_REASON = {
  header: 'Reason for Lost',
  render: LostRender,
};

export const PocActiveTable = props => {
  const {
    route: {params},
  } = props;
  const {
    POCCommunications,
    updatePOCStatus,
    opportunityMarkWonRowActive,
    opportunityMarkLostRowAction,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      searchFields="requirement"
      params={{
        period: params.period,
        ...params,
      }}
      api="/opportunity/poc/active"
      eventSourceId={'POC'}
      columns={[
        // {
        //   type: 'date',
        //   field: 'creation_date',
        //   header: 'Created On',
        // },
        {
          field: 'requirement',
          header: 'POC title',
        },
        {
          header: 'Customer/Entity',
          render: CustomerRender,
          responsive: 'sm',
        },

        {
          header: 'Skill',
          render: RenderSkill,
          width: 120,
        },
        {
          header: 'Est',
          field: 'est_hr',
          width: 100
        },
        // {
        //   field: 'owner',
        //   header: 'Owner',
        //   type: 'userAvatarChip',
        // },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        POC_STATUS,
        {
          header: 'Last Communication',
          onPress: ({row}) => {
            POCCommunications({row});
          },
          render: CommunicationRender,
        },
      ]}
      moreActions={() => [
        {
          title: 'Change Status',
          onPress: updatePOCStatus,
        },
        {
          title: 'Mark Won',
          onPress: opportunityMarkWonRowActive,
        },
        {
          title: 'Mark Lost',
          onPress: opportunityMarkLostRowAction,
        },
        // {
        //   title: 'Mark Won',
        //   onPress: opportunityMarkWonRowActive,
        // },
        // {
        //   title: 'Mark Lost',
        //   onPress: opportunityMarkLostRowAction,
        // },
        // {
        //   title: 'Mark Drop',
        //   onPress: opportunityDropRfqRowAction,
        // },
      ]}
    />
  );
};

export const PocWonTable = props => {
  const {
    route: {params},
  } = props;
  const {POCCommunications} = customDigitalPlatformOppControllers(props);

  return (
    <Table
      {...props}
      searchFields="requirement"
      params={{
        period: params.period,
        ...params,
      }}
      api="/opportunity/poc/won"
      eventSourceId={'POC'}
      columns={[
        // {
        //   type: 'date',
        //   field: 'creation_date',
        //   header: 'Created On',
        // },
        {
          field: 'requirement',
          header: 'POC title',
        },
        {
          header: 'Customer/Entity',
          render: CustomerRender,
          responsive: 'sm',
        },
        {
          header: 'Last Communication',
          onPress: ({row}) => {
            POCCommunications({row});
          },
          render: CommunicationRender,
        },
        {
          header: 'Skill',
          render: RenderSkill,
          width: 120,
        },
        {
          header: 'Est',
          field: 'est_hr',
        },
        // {
        //   field: 'owner',
        //   header: 'Owner',
        //   type: 'userAvatarChip',
        // },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        POC_STATUS,
      ]}
      moreActions={() => [
        // {
        //   title: 'Change Status',
        //   onPress: updatePOCStatus,
        // },
        // {
        //   title: 'Mark Won',
        //   onPress: opportunityMarkWonRowActive,
        // },
        // {
        //   title: 'Mark Lost',
        //   onPress: opportunityMarkLostRowAction,
        // },
        // {
        //   title: 'Mark Won',
        //   onPress: opportunityMarkWonRowActive,
        // },
        // {
        //   title: 'Mark Lost',
        //   onPress: opportunityMarkLostRowAction,
        // },
        // {
        //   title: 'Mark Drop',
        //   onPress: opportunityDropRfqRowAction,
        // },
      ]}
    />
  );
};

export const PocLostTable = props => {
  const {
    route: {params},
  } = props;
  const {POCCommunications} = customDigitalPlatformOppControllers(props);

  return (
    <Table
      {...props}
      params={{
        period: params.period,
        ...params,
      }}
      searchFields="requirement"
      api="/opportunity/poc/lost"
      eventSourceId={'POC'}
      columns={[
        // {
        //   type: 'date',
        //   field: 'creation_date',
        //   header: 'Created On',
        // },
        {
          field: 'requirement',
          header: 'POC title',
        },
        {
          header: 'Customer/Entity',
          render: CustomerRender,
          responsive: 'sm',
        },
        {
          header: 'Last Communication',
          onPress: ({row}) => {
            POCCommunications({row});
          },
          render: CommunicationRender,
        },
        {
          header: 'Skill',
          render: RenderSkill,
          width: 120,
        },
        {
          header: 'Est',
          field: 'est_hr',
        },
        // {
        //   field: 'owner',
        //   header: 'Owner',
        //   type: 'userAvatarChip',
        // },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        LOST_REASON,
      ]}
    />
  );
};
