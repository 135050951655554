import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';

export const OutreachCampaignSelectNewCampaign = props => {
  const {
    route: {
      params: {
        api,
        moveAllProspects = false,
        campaign_id: {_id: campaignId} = {},
      } = {},
    },
  } = props;

  let {onSubmit} = useFormSubmit({
    uri: api,
    method: 'post',
    eventSourceId: 'moveToNewCampaign',
    ...props,
  });

  const {user} = useAppStateContext();

  let {employee: {_id: employeeId} = {}} = user;

  const {
    route: {params},
  } = props;
  const {row, selectedIds = []} = params || {};

  let extraFields = {};
  if (selectedIds.length) {
    extraFields.selectedIds = selectedIds;
  }
  if (moveAllProspects) {
    extraFields.moveAllProspects = moveAllProspects;
  }
  return (
    <Form
      defaultValues={{
        old_campaign_id: row ? row._id : campaignId,
        ...extraFields,
      }}
      onSubmit={onSubmit}
      header={'Move to New Campaign'}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'new_campaign_id',
          type: 'autoComplete',
          api: `/campaigns`,
          filter: {system_controlled: true, assigned_to: employeeId},
          label: 'Campaign',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const EditOutreachCampaignSelectNewCampaign = props => {
  return (
    <OutreachCampaignSelectNewCampaign
      mode="edit"
      header={'Move to New Campaign'}
      {...props}
    />
  );
};
