import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form, MultiStepForm} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {Text, TouchableOpacity, View} from '@unthinkable/react-core-components';
import {useFormContext} from '@unthinkable/react-form/src/FormContext';
import {useTheme} from '@unthinkable/react-theme';
import {
  useFetchFormData,
  useFetchData,
} from '../../../controllers/useFetchData';

import {useInvoke} from '../../../controllers/useInvoke';
import NestedTable from '../../../components/form/NestedTable';
import {useAppStateContext} from '../../../providers/AppState';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

const EmployeeParichayComputation = props => ({
  'set user type': {
    compute: async value => {
      if (
        value &&
        value.user &&
        value.user.userType &&
        value.user.userType == 'pre employee'
      ) {
        return {
          user: {_id: value?.user?._id, userType: 'employee'},
        };
      }
    },
    multi: true,
    dependencies: ['_id'],
  },
  'set permanent address as current address': {
    compute: async value => {
      let totalAmount = 0;
      if (value?.sameAsCurrent) {
        return {
          address_line_1: value?.address_line_1_current,
          address_line_2: value?.address_line_2_current,
          city: value?.city_current,
          state: value?.state_current,
          country: value?.country_current,
          zip: value?.zip_current,
        };
      }
    },
    multi: true,
    dependencies: ['sameAsCurrent'],
  },
});

export const EmployeeParichayForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/employeeparichays',
    eventSourceId: 'employeeparichay',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  let defaultValues = {};
  if (userData) {
    const {gender, email, name, marital_status} = userData;
    if (gender == 'female') {
      defaultValues.gender = 'Female';
    }
    if (gender == 'male') {
      defaultValues.gender = 'Male';
    }
    if (marital_status == 'unmarried') {
      defaultValues.marital_status = 'Single';
    }
    if (marital_status == 'married') {
      defaultValues.marital_status = 'Married';
    }
    defaultValues.email_id = email;
    defaultValues.full_name = name;
    defaultValues.user = userData?._id;
  }
  // if(mode?.length) return;
  return (
    <MultiStepForm
      {...props}
      closable={false}
      api={`/employee/employee-parichay/${parichayId ? parichayId : row?._id}`}
      onSubmit={onSubmit}
      header={'Parichay Form'}
      submitAction={'Save'}
      defaultValues={{...defaultValues}}
      saveOnNext={false}
      computations={{...EmployeeParichayComputation()}}
      afterSubmit={() => {
        location.reload();
      }}
      steps={[
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'full_name',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',
                  required: true,
                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },

                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',
                  required: true,
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mobile_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        email_id: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',
                  required: true,
                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',
                  required: true,
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',
                  size: 3,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip_current: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        father_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  required: true,
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',
                  required: true,

                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mother_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  field: 'mother_date_of_birth',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',
                  size: 4,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',
                  required: true,

                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',
                  required: true,

                  field: 'emergency_contact_persion_number',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Medical insurance policy details',
              visible: ({values}) =>
                values.marital_status && values.marital_status == 'Married',
              fields: [
                {
                  label: 'Spouse Details(as per aadhar card)',
                  fields: [
                    {
                      label: 'Spouse name',
                      field: 'spouse_name',
                      type: 'textArea',

                      // required: ({values}) => {
                      //   if (values?.marital_status == 'Married') {
                      //     return true;
                      //   }
                      // },
                      size: 6,
                    },
                    {
                      label: 'Date Of Birth',
                      field: 'spouse_dob',
                      type: 'date',
                      size: 6,
                      // required: ({values}) => {
                      //   if (values?.marital_status == 'Married') {
                      //     return true;
                      //   }
                      // },
                    },
                    {
                      label: 'Gender',
                      field: 'spouse_gender',
                      size: 6,
                      type: 'autoComplete',
                      options: ['Male', 'Female'],
                    },
                    {
                      fields: [
                        {
                          label: 'Have Child',
                          field: 'have_child',
                          type: 'checkbox',
                          // required: true,
                        },
                      ],
                    },
                  ],
                },
                {
                  label: 'Child 1 Details(as per aadhar card)',
                  visible: ({values}) =>
                    values &&
                    values.marital_status &&
                    values.marital_status === 'Married' &&
                    values.have_child,
                  fields: [
                    {
                      label: 'Name',
                      field: 'first_child_name',
                      type: 'text',
                      // required: true,
                    },
                    {
                      label: 'Date Of Birth',
                      field: 'first_child_dob',
                      type: 'date',
                      // required: true,
                    },
                    {
                      label: 'Gender',
                      field: 'first_child_gender',
                      type: 'autoComplete',
                      options: ['Male', 'Female'],
                      // required: true,
                    },
                  ],
                },
                {
                  label: 'Child 2 Details(as per aadhar card)',
                  visible: ({values}) =>
                    values &&
                    values.marital_status &&
                    values.marital_status === 'Married' &&
                    values.have_child,
                  fields: [
                    {
                      label: 'Name',
                      field: 'second_child_name',
                      type: 'text',
                      // required: true,
                    },
                    {
                      label: 'Date Of Birth',
                      field: 'second_child_dob',
                      type: 'date',
                      // required: true,
                    },
                    {
                      label: 'Gender',
                      field: 'second_child_gender',
                      type: 'autoComplete',
                      options: ['Male', 'Female'],
                      // required: true,
                    },
                  ],
                },
                {
                  label: 'Parent details',
                  fields: [
                    {
                      fields: [
                        {
                          label: 'Parents have insurance',
                          field: 'have_parents_insurance',
                          type: 'checkbox',
                          // required: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          validate: async fieldValue => {
                            const {data} = await post({
                              uri: `/employeeParichayValidations`,
                              method: 'post',
                              props: {
                                name: fieldValue,
                              },
                            });
                            if (data) {
                              return data;
                            }
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_qualification_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  required: true,

                  type: 'text',
                  size: 3,
                },
                {
                  placeholder: 'Secondary education proof',
                  label: 'Secondary education proof',
                  field: 'secondary_education_proofs',
                  required: true,

                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  required: true,

                  type: 'text',
                  size: 3,
                },
                {
                  placeholder: 'Sr. secondary education proof',
                  label: 'Sr. secondary education proof',
                  required: true,
                  field: 'sr_secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Graduation proofs',
                  label: 'Graduation proofs',
                  field: 'graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Post graduation proofs',
                  label: 'Post graduation proofs',
                  field: 'post_graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Employement History',
          fields: [
            {
              label: 'I have past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                      required: true,

                      // validate: async fieldValue => {
                      //   const accnoReg = /^[0-9]{9,18}$/;
                      //   if (fieldValue && !accnoReg.test(fieldValue)) {
                      //     return 'Account Number Not Valid';
                      //   }
                      // },
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                      required: true,
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                      required: true,
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                      required: true,

                      // validate: async fieldValue => {
                      //   if (fieldValue && fieldValue.length !== 11) {
                      //     return 'ONLY 11 CHARACTERS ALLOWED';
                      //   }
                      // },
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Nominee',
          fields: [
            {
              label: 'Primary Nominee',
              fields: [
                {
                  label: 'Primary Nominee',
                  field: 'nominee_name',
                  type: 'text',
                  size: 4,
                  required: true,
                },
                {
                  label: 'Relation',
                  field: 'nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'nominee_address',
                  type: 'textArea',
                  size: 4,
                  required: true,
                },
              ],
            },
            {
              label: 'Secondary Nominee',
              fields: [
                {
                  label: 'Secondary Nominee',
                  field: 'secondary_nominee_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'secondary_nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'secondary_nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              placeholder: 'Nominee declaration form',
              field: 'nominee_declaration_form',
              type: 'file',
              options: {bucketName: 'manaze'},
              required: true,
            },
          ],
        },
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  required: true,

                  size: 6,
                  validate: async fieldValue => {
                    const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                    if (fieldValue && !regexp.test(fieldValue)) {
                      return 'INVALID ADHAR CARD NUMBER';
                    }
                  },
                },
                {
                  placeholder: 'Adhaar card copy',
                  required: true,

                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                  validate: async fieldValue => {
                    let regpan = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                    if (fieldValue && !regpan.test(fieldValue)) {
                      return 'First 5 Character "Alphabet(Upper Case)" next 4 Character "Numeric" last Character "Alphabet(Upper Case)"';
                    }
                  },
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  required: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const EmployeeParichayFormForHr = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/employeeparichays',
    eventSourceId: 'employeeparichay',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  let defaultValues = {};
  if (userData) {
    const {gender, email, name, marital_status} = userData;
    if (gender == 'female') {
      defaultValues.gender = 'Female';
    }
    if (gender == 'male') {
      defaultValues.gender = 'Male';
    }
    defaultValues.email_id = email;
    defaultValues.full_name = name;
    defaultValues.marital_status = marital_status;
    defaultValues.user = userData?._id;
  }
  // if(mode?.length) return;
  return (
    <MultiStepForm
      {...props}
      closable={true}
      api={`/employee/employee-parichay/${parichayId ? parichayId : row?._id}`}
      onSubmit={onSubmit}
      header={'Parichay Form'}
      submitAction={'Save'}
      defaultValues={{...defaultValues}}
      saveOnNext={false}
      computations={{...EmployeeParichayComputation()}}
      afterSubmit={() => {
        location.reload();
      }}
      steps={[
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'full_name',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',
                  required: true,
                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },
                // {
                //   label: 'Spouse name',
                //   field: 'spouse_name',
                //   type: 'textArea',
                //   visible: ({values}) => {
                //     if (values?.marital_status == 'Married') {
                //       return true;
                //     }
                //   },
                //   required: ({values}) => {
                //     if (values?.marital_status == 'Married') {
                //       return true;
                //     }
                //   },
                //   size: 3,
                // },
                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',
                  required: true,
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mobile_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        email_id: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',
                  required: true,
                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',
                  required: true,
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',
                  size: 3,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip_current: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        father_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  required: true,
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',
                  required: true,
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mother_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  required: ({values}) =>
                    values.marital_status && values.marital_status == 'Married',
                  field: 'mother_date_of_birth',
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',
                  size: 4,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',
                  required: true,

                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',
                  required: true,

                  field: 'emergency_contact_persion_number',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Medical insurance policy details',
              visible: ({values}) =>
                values.marital_status && values.marital_status == 'Married',
              fields: [
                {
                  label: 'Spouse Details(as per aadhar card)',
                  fields: [
                    {
                      label: 'Spouse name',
                      field: 'spouse_name',
                      type: 'textArea',

                      // required: ({values}) => {
                      //   if (values?.marital_status == 'Married') {
                      //     return true;
                      //   }
                      // },
                      size: 6,
                    },
                    {
                      label: 'Date Of Birth',
                      field: 'spouse_dob',
                      type: 'date',
                      size: 6,
                      // required: ({values}) => {
                      //   if (values?.marital_status == 'Married') {
                      //     return true;
                      //   }
                      // },
                    },
                    {
                      label: 'Gender',
                      field: 'spouse_gender',
                      size: 6,
                      type: 'autoComplete',
                      options: ['Male', 'Female'],
                    },
                    {
                      fields: [
                        {
                          label: 'Have Child',
                          field: 'have_child',
                          type: 'checkbox',
                          // required: true,
                        },
                      ],
                    },
                  ],
                },
                {
                  label: 'Child 1 Details(as per aadhar card)',
                  visible: ({values}) =>
                    values &&
                    values.marital_status &&
                    values.marital_status === 'Married' &&
                    values.have_child,
                  fields: [
                    {
                      label: 'Name',
                      field: 'first_child_name',
                      type: 'text',
                      // required: true,
                    },
                    {
                      label: 'Date Of Birth',
                      field: 'first_child_dob',
                      type: 'date',
                      // required: true,
                    },
                    {
                      label: 'Gender',
                      field: 'first_child_gender',
                      type: 'autoComplete',
                      options: ['Male', 'Female'],
                      // required: true,
                    },
                  ],
                },
                {
                  label: 'Child 2 Details(as per aadhar card)',
                  visible: ({values}) =>
                    values &&
                    values.marital_status &&
                    values.marital_status === 'Married' &&
                    values.have_child,
                  fields: [
                    {
                      label: 'Name',
                      field: 'second_child_name',
                      type: 'text',
                      // required: true,
                    },
                    {
                      label: 'Date Of Birth',
                      field: 'second_child_dob',
                      type: 'date',
                      // required: true,
                    },
                    {
                      label: 'Gender',
                      field: 'second_child_gender',
                      type: 'autoComplete',
                      options: ['Male', 'Female'],
                      // required: true,
                    },
                  ],
                },
                {
                  label: 'Parent details',
                  fields: [
                    {
                      fields: [
                        {
                          label: 'Parents have insurance',
                          field: 'have_parents_insurance',
                          type: 'checkbox',
                          // required: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          validate: async fieldValue => {
                            const {data} = await post({
                              uri: `/employeeParichayValidations`,
                              method: 'post',
                              props: {
                                name: fieldValue,
                              },
                            });
                            if (data) {
                              return data;
                            }
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_qualification_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  required: true,

                  type: 'text',
                  size: 3,
                },
                {
                  placeholder: 'Secondary education proof',
                  label: 'Secondary education proof',
                  field: 'secondary_education_proofs',
                  required: true,

                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  required: true,

                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  required: true,

                  type: 'text',
                  size: 3,
                },
                {
                  placeholder: 'Sr. secondary education proof',
                  label: 'Sr. secondary education proof',
                  required: true,
                  field: 'sr_secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Graduation proofs',
                  label: 'Graduation proofs',
                  field: 'graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Post graduation proofs',
                  label: 'Post graduation proofs',
                  field: 'post_graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Employement History',
          fields: [
            {
              label: 'I have past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                      required: ({values}) =>
                        values && values.have_past_experience,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                      required: true,

                      validate: async fieldValue => {
                        const accnoReg = /^[0-9]{9,18}$/;
                        if (fieldValue && !accnoReg.test(fieldValue)) {
                          return 'Account Number Not Valid';
                        }
                      },
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                      required: true,
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                      required: true,
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                      required: true,

                      validate: async fieldValue => {
                        if (fieldValue && fieldValue.length !== 11) {
                          return 'ONLY 11 CHARACTERS ALLOWED';
                        }
                      },
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Nominee',
          fields: [
            {
              label: 'Primary Nominee',
              fields: [
                {
                  label: 'Primary Nominee',
                  field: 'nominee_name',
                  type: 'text',
                  size: 4,
                  required: true,
                },
                {
                  label: 'Relation',
                  field: 'nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'nominee_address',
                  type: 'textArea',
                  size: 4,
                  required: true,
                },
              ],
            },
            {
              label: 'Secondary Nominee',
              fields: [
                {
                  label: 'Secondary Nominee',
                  field: 'secondary_nominee_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'secondary_nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'secondary_nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              placeholder: 'Nominee declaration form',
              field: 'nominee_declaration_form',
              type: 'file',
              options: {bucketName: 'manaze'},
              required: true,
            },
          ],
        },
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  required: true,

                  size: 6,
                  validate: async fieldValue => {
                    const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                    if (fieldValue && !regexp.test(fieldValue)) {
                      return 'INVALID ADHAR CARD NUMBER';
                    }
                  },
                },
                {
                  placeholder: 'Adhaar card copy',
                  required: true,

                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                  validate: async fieldValue => {
                    let regpan = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                    if (fieldValue && !regpan.test(fieldValue)) {
                      return 'First 5 Character "Alphabet(Upper Case)" next 4 Character "Numeric" last Character "Alphabet(Upper Case)"';
                    }
                  },
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  required: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const EmployeeParichayDetailsForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/employeeparichays',
    eventSourceId: 'employeeparichay',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
  } = props;
  const {row = {}, parichayId} = params || {};
  const {post, user} = useAppStateContext();
  return (
    <MultiStepForm
      {...props}
      api={`/employee/employee-parichay/${parichayId}`}
      onSubmit={onSubmit}
      header={'Parichay Form'}
      submitAction={'Save'}
      defaultValues={{
        user: user,
      }}
      // readOnly={true}
      // mode={'edit'}
      saveOnNext={false}
      steps={[
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'full_name',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',
                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',
                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',
                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },
                {
                  label: 'Spouse name',
                  field: 'spouse_name',
                  type: 'textArea',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mobile_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        email_id: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip_current: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        father_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mother_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  field: 'mother_date_of_birth',
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',
                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',
                  field: 'emergency_contact_persion_number',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          validate: async fieldValue => {
                            const {data} = await post({
                              uri: `/employeeParichayValidations`,
                              method: 'post',
                              props: {
                                name: fieldValue,
                              },
                            });
                            if (data) {
                              return data;
                            }
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_qualification_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  type: 'text',
                  size: 3,
                },
                {
                  placeholder: 'Secondary education proof',
                  label: 'Secondary education proof',
                  field: 'secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  type: 'text',
                  size: 3,
                },
                {
                  placeholder: 'Sr. secondary education proof',
                  label: 'Sr. secondary education proof',
                  field: 'sr_secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Graduation proofs',
                  label: 'Graduation proofs',
                  field: 'graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Post graduation proofs',
                  label: 'Post graduation proofs',
                  field: 'post_graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Employement History',
          fields: [
            {
              label: 'I have past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Nominee',
          fields: [
            {
              label: 'Primary Nominee',
              fields: [
                {
                  label: 'Primary Nominee',
                  field: 'nominee_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              label: 'Secondary Nominee',
              fields: [
                {
                  label: 'Secondary Nominee',
                  field: 'secondary_nominee_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'secondary_nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'secondary_nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              placeholder: 'Nominee declaration form',
              field: 'nominee_declaration_form',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  size: 6,
                },
                {
                  placeholder: 'Adhaar card copy',
                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const EmployeeParichayDocForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/parichaymaildocuments',
    eventSourceId: 'employeeparichay',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
  } = props;
  const {row = {}, parichayId} = params || {};
  const {post, user} = useAppStateContext();
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      header={'Parichay docs'}
      submitAction={'Save'}
      saveOnNext={false}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Consent doc',
              label: 'Consent doc',
              field: 'consent_document',
              size: 6,
              type: 'file',
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'Maternity',
              label: 'Maternity',
              field: 'maternity_document',
              size: 6,
              type: 'file',
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'PF',
              label: 'PF',
              field: 'pf_document',
              size: 6,
              type: 'file',
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'EPF form 2',
              label: 'EPF form 2',
              field: 'epf_form_2',
              size: 6,
              type: 'file',
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'EPF form 11',
              label: 'EPF form 11',
              field: 'epf_form_11',
              type: 'file',
              size: 6,
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'Gratuity',
              label: 'Gratuity',
              field: 'gratuity_document',
              type: 'file',
              size: 6,
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'Nominee',
              label: 'Nominee',
              field: 'nominee_delaration_document',
              type: 'file',
              size: 6,
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const EmployeeParichayDetailForm = props => {
  return (
    <EmployeeParichayForm
      mode="edit"
      isDualMode
      header={'Employee Parichay Detail'}
      {...props}
    />
  );
};

export const EmployeeParichayUpdateDetailForm = props => {
  return (
    <EmployeeParichayDetailsForm
      mode="edit"
      isDualMode
      header={'Employee Parichay Detail'}
      {...props}
    />
  );
};

export const EmployeeParichayDocumentForm = props => {
  return (
    <EmployeeParichayDocForm header={'Employee Parichay Detail'} {...props} />
  );
};

export const AddEmployeeParichayDetailForm = props => {
  const {
    route: {params},
  } = props;
  const {_id} = params || {};

  const {post, user} = useAppStateContext();
  const {data, loading} = useFetchData({
    api: '/employeeparichays',
    filter: {
      user: user?._id,
    },
    fields: {
      _id: 1,
    },
    only: true,
  });
  const {data: userData, loading: userLoading} = useFetchFormData({
    api: '/users',
    filter: {
      _id: user?._id,
    },
    fields: {
      _id: 1,
      gender: 1,
      marital_status: 1,
      email: 1,
      name: 1,
    },
  });
  if (loading !== false || userLoading !== false) {
    return null;
  }
  if (data?._id) {
    return (
      <EmployeeParichayForm
        header={'Employee Parichay Detail'}
        parichayId={data?._id}
        mode={'edit'}
        readOnly={true}
        {...props}
      />
    );
  } else {
    return (
      <EmployeeParichayForm
        header={'Employee Parichay'}
        userData={userData}
        {...props}
      />
    );
    // }
  }
};
