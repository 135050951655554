import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { Button } from '../../../components/button/Button';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { EmployeeWorkloadTable } from '../../department/views/EmployeeWorkloadTable';
import EmployeeWorkload from './EmployeeWorkLoad';

export const EmployeeWorkloadTabs = props => {
  const {route: {params} = {}, fromEmployeeMenu} = props;
  const {assigned_type} = params;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {download} = useAppStateContext();
  const {params: filterParams} = filterValues;
  let filter = {...params, ...filterParams};
  let tabs = {};

  if (assigned_type == 'Billable') {
    tabs = {
      employee: {
        label: 'Billable',
        view: (
          <EmployeeWorkload
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            searchValue={searchValue}
            params={{filter}}
          />
        ),
        actions: [
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
          />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                label: 'Employee',
                api: '/employeeSuggestions',
                placeholder: 'Select',
                suggestionField: 'name',
                secondarySuggestionField: 'official_email_id',
                valueField: 'name',
                field: 'employee',
              },
            ]}
          />,
        ],
        api: '/employees-team/workload',
        params: filter,
        search: searchValue,
        limit: 2000,
        searchFields: ['name'],
        addOnFilter: {...filterValues},
      },
    };
  } else {
    tabs = {
      organization: {
        label: 'Non-Billable',
        view: (
          <EmployeeWorkloadTable
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            searchValue={searchValue}
            params={{filter}}
          />
        ),
        actions: [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        ],
        api: '/employee-workload-from-tasks',
        params: filter,
        limit: 2000,
        search: searchValue,
        searchFields: ['name'],
        addOnFilter: {...filterValues},
      },
    };
  }

  if (fromEmployeeMenu) {
    tabs = {
      employee: {
        label: 'Billable',
        view: (
          <EmployeeWorkload
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            searchValue={searchValue}
          />
        ),
        api: '/employees-team/workload',
        addOnFilter: filterValues?.filter || {},
        search: searchValue,
        searchFields: ['name'],
        actions: [
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
          />,
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                label: 'Reporting To',
                api: '/employeeSuggestions',
                placeholder: 'Select',
                suggestionField: 'name',
                secondarySuggestionField: 'official_email_id',
                valueField: 'name',
                field: 'reporting_to',
              },
              {
                type: 'autoComplete',
                label: 'Practice',
                api: '/practices',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'practice',
              },
              {
                type: 'autoComplete',
                label: 'Product',
                api: '/products',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'product',
              },
              {
                type: 'autoComplete',
                label: 'Is CV Added',
                options: [
                  {label: 'True', value: true},
                  {label: 'False', value: false},
                ],
                field: 'is_cv_added',
                placeholder: 'Select',
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
              },
              // {
              //   type: 'date',
              //   label: 'Joining Date',
              //   field: 'joining_date',
              //   asParam: true,
              // },
              {
                type: 'autoComplete',
                api: '/offices',
                suggestionField: 'name',
                valueField: 'name',
                field: 'sitting_location',
                label: 'Location',
                size: 6,
              },
              {
                type: 'autoComplete',
                options: [
                  'Client Managed',
                  'Daffodil Managed',
                  'Hybrid Managed',
                  'Daffodil Managed - Support',
                ],
                field: 'project_sub_category',
                label: 'Managed By',
              },
              {
                type: 'autoComplete',
                options: [
                  {label: 'True', value: true},
                  {label: 'False', value: false},
                ],
                field: 'is_manager',
                label: 'Is Manager',
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
              },
            ]}
          />,
          <Button
            text="Download"
            onPress={() => {
              download({
                uri: `/employeeassignment/workload/export`,
                props: {
                  ...params,
                },
              });
            }}
          />,
        ],
        params: {...params, ...filter},
      },
      organization: {
        label: 'Non-Billable',
        view: (
          <EmployeeWorkloadTable
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            searchValue={searchValue}
          />
        ),
        api: '/employee-workload-from-tasks',
        addOnFilter: filterValues?.filter || {},
        search: searchValue,
        searchFields: ['name'],
        params: params,

        actions: [
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
          />,
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                api: '/offices',
                suggestionField: 'name',
                valueField: 'name',
                field: 'sitting_location',
                label: 'Location',
                size: 6,
              },
              {
                type: 'autoComplete',
                options: [
                  'Client Managed',
                  'Daffodil Managed',
                  'Hybrid Managed',
                  'Daffodil Managed - Support',
                ],
                field: 'project_sub_category',
                label: 'Managed By',
              },
              {
                type: 'autoComplete',
                label: 'Reporting To',
                api: '/employeeSuggestions',
                placeholder: 'Select',
                suggestionField: 'name',
                secondarySuggestionField: 'official_email_id',
                valueField: 'name',
                field: 'reporting_to',
              },
              {
                type: 'autoComplete',
                label: 'Practice',
                api: '/practices',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'practice',
              },
              {
                type: 'autoComplete',
                label: 'Product',
                api: '/products',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'product',
              },
              {
                type: 'autoComplete',
                label: 'Is CV Added',
                options: [
                  {label: 'True', value: true},
                  {label: 'False', value: false},
                ],
                field: 'is_cv_added',
                placeholder: 'Select',
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
              },
              {
                type: 'date',
                label: 'Joining Date',
                field: 'joining_date',
                asParam: true,
              },
            ]}
          />,
        ],
      },
    };
  }

  return <TabView tabs={tabs} {...props} />;
};
