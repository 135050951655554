import React, {useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Col, View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/table/Headers';
import {TableFooter} from '../../../components/table/Footers';
import {useAppStateContext} from '../../../providers/AppState';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {Table} from '../../../components/table/Table';
import {Checkbox} from '@unthinkable/react-checkbox';
import {isMobile} from '@unthinkable/react-utils';
import { Button } from '../../../components/button/Button';

export const SelectKpis = ({route: {params}}) => {
  const [kpis, setKpis] = useState({});
  const {post} = useAppStateContext();
  const {searchValue, onChangeFilter} = useFilter();
  const navigation = useNavigation();

  const onSelect = ({row}) => {
    let id = row._id;
    setKpis({
      ...kpis,
      [id]: kpis[id] !== undefined ? !kpis[id] : !row.primary,
    });
  };

  const getSelected = ({row}) => {
    return kpis[row._id] !== undefined ? kpis[row._id] : row.primary;
  };

  const renderList = (
    <Col style={{height: 300, padding: 8}} gap={8}>
      <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />
      <Table
        api={'/kpis'}
        filter={{department: params?.department?._id}}
        fields={`kpi,primary`}
        onRowPress={onSelect}
        search={searchValue}
        searchFields="kpi"
        columns={[
          {
            width: 30,
            render: props => {
              const selected = getSelected(props);
              return (
                <Checkbox
                  onChangeValue={() => onSelect(props)}
                  value={selected}
                />
              );
            },
            responsive: 'sm',
          },
          {
            field: 'kpi',
            header: 'Kpi',
            responsive: 'sm',
          },
        ]}
      />
    </Col>
  );

  return (
    <>
      <TableHeader
        title={'Select Primary KPIs'}
        {...(!isMobile && {
          actions: [
            <Button
              text="Save"
              onPress={() => {
                post({
                  uri: `/markKpiPrimary`,
                  props: {kpis},
                });
                navigation.goBack();
              }}
            />,
          ],
        })}
      />

      {renderList}

      {isMobile ? (
        <TableFooter
          actions={[
            <Button
              text="Save"
              onPress={() => {
                post({
                  uri: `/markKpiPrimary`,
                  props: {kpis},
                });
                navigation.goBack();
              }}
            />,
          ]}
        />
      ) : null}
    </>
  );
};
