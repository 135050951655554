import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const TaggedWithAssetForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'ActiveITAsset',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      fields={{
        taged_with: {_id: 1, code: 1},
      }}
      header={{
        title: 'Tagged with asset',
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Tagged With',
          field: 'taged_with',
          type: 'autoComplete',
          api: '/assetinventories',
          suggestionField: 'code',
          labelField: 'code',
          required: true,
          filter: {
            asset_type: 'Ready To Use',
          },
        },
      ]}
    />
  );
};
