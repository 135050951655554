import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const days = [
  {label: '0 Days', value: 0},
  {label: '1 Days', value: 1},
  {label: '2 Days', value: 2},
  {label: '3 Days', value: 3},
  {label: '4 Days', value: 4},
  {label: '5 Days', value: 5},
  {label: '6 Days', value: 6},
  {label: '7 Days', value: 7},
  {label: '8 Days', value: 8},
  {label: '9 Days', value: 9},
  {label: '10 Days', value: 10},
  {label: '11 Days', value: 11},
  {label: '12 Days', value: 12},
  {label: '13 Days', value: 13},
  {label: '14 Days', value: 14},
  {label: '15 Days', value: 15},
  {label: '16 Days', value: 16},
  {label: '17 Days', value: 17},
  {label: '18 Days', value: 18},
  {label: '19 Days', value: 19},
  {label: '20 Days', value: 20},
  {label: '21 Days', value: 21},
  {label: '22 Days', value: 22},
  {label: '23 Days', value: 23},
  {label: '24 Days', value: 24},
  {label: '25 Days', value: 25},
  {label: '26 Days', value: 26},
  {label: '27 Days', value: 27},
  {label: '28 Days', value: 28},
  {label: '29 Days', value: 29},
  {label: '30 Days', value: 30},
  {label: '31 Days', value: 31},
];

const orderComputations = fetch => ({
  basecurrency_id: {
    compute: async value => {
      if (value?.organization?._id) {
        const {data: organizationData} = await fetch({
          uri: '/organizations',
          props: {
            filter: {
              _id: value?.organization?._id,
            },
            fields: {_id: 1, currency: {_id: 1, currency: 1}},
            only: true,
          },
        });
        return organizationData?.currency;
      }
    },
    dependencies: ['organization'],
  },

  'set sales owner,account manager, csm from customer': {
    compute: async value => {
      if (value?.customer_id?._id) {
        const {data: customerData} = await fetch({
          uri: '/customers',
          props: {
            filter: {
              _id: value?.customer_id?._id,
            },
            fields: {
              _id: 1,
              sales_owner: {_id: 1, name: 1},
              account_owner: {_id: 1, name: 1},
              csm: {_id: 1, name: 1},
              location_id: {_id: 1, name: 1},
              organization: {_id: 1, name: 1},
              product: {_id: 1, name: 1},
            },
            only: true,
          },
        });
        return {
          sales_owner: customerData?.sales_owner,
          account_owner_id: customerData?.account_owner,
          delivery_owner_id: customerData?.csm,
          location_id: customerData?.location_id,
          organization: customerData?.organization,
          product: customerData?.product,
        };
      }
    },
    multi: true,
    dependencies: ['customer_id'],
  },
  'set fix amount': {
    compute: value => {
      const {exchange_rate = 1, est_order_hrs, delivery_rate} = value || {};
      if (est_order_hrs && delivery_rate) {
        const amount = est_order_hrs * delivery_rate;
        const base_amount = est_order_hrs * delivery_rate * exchange_rate;
        return {amount, base_amount};
      }
    },
    multi: true,
    dependencies: [
      'est_order_hrs',
      'est_order_hrs',
      'delivery_rate',
      'exchange_rate',
    ],
  },
  currency_id: {
    compute: async value => {
      if (value?.customer_id?._id) {
        const {data: customerData} = await fetch({
          uri: '/customers',
          props: {
            filter: {
              _id: value?.customer_id?._id,
            },
            fields: {_id: 1, base_currency: {_id: 1, currency: 1}},
            only: true,
          },
        });
        return customerData?.base_currency;
      }
    },
    dependencies: ['customer_id'],
  },
  exchange_rate: {
    compute: async value => {
      if (value?.currency_id?._id && value.order_date) {
        const {data: exchangeData} = await fetch({
          uri: '/daywisecurrencies',
          props: {
            filter: {
              currency: value?.currency_id?._id,
              from_date: {$lte: value.order_date},
              $or: [{to_date: null}, {to_date: {$gte: value.order_date}}],
            },
            fields: {_id: 1, exchange_rate: 1},
            only: true,
          },
        });
        return exchangeData?.exchange_rate || 1;
      }
    },
    dependencies: ['currency_id'],
  },
  nestedComputations: {
    requirement_detail: {
      order_date: {
        compute: ({}, {_parentValues}) => {
          return _parentValues?.order_date;
        },
        dependencies: ['_parentValues.order_date'],
      },
      base_amount: {
        compute: (value, {_parentValues}) => {
          if (_parentValues?.exchange_rate && value?.amount) {
            return value?.amount * _parentValues?.exchange_rate;
          }
        },
        dependencies: ['_parentValues.exchange_rate', 'amount'],
      },

      recurring_till: {
        compute: ({}, {_parentValues}) => {
          return _parentValues?.order_end_date;
        },
        dependencies: ['_parentValues.order_end_date'],
      },
    },
  },
});

export const AddTMOrderform = props => {
  const {
    route: {params = {}},
    header = 'Add T&M Order',
    formCopyOrder = false,
  } = props;
  const {department, product, row} = params;

  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  const {onSubmit} = useFormSubmit({
    uri: '/saveOrder',
    eventSourceId: 't&m',
    ...props,
  });
  return (
    <Form
      api={formCopyOrder ? `/copyorderdata/${row?._id}` : `/orders/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        organization: employeeData?.organization,
        product: employeeData?.product,
        basecurrency_id: employeeData?.organization?.currency,
        currency_id: employeeData?.organization?.currency,
        order_type: 'r',
        order_date: getZeroTimeDate(new Date()),
        status: 'New',
      }}
      submitAction={'Save'}
      computations={{...orderComputations(fetch)}}
      layoutFields={[
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              label: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              titleField: 'name',
              size: 4,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'order_exists_type',
              label: 'Order Type',
              placeholder: 'Order Type',
              options: ['New', 'Extension', 'Support'],
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'billing_type',
              label: 'Biling Model',
              header: 'Biling Model',
              placeholder: 'Repeats',
              // options: ['Hourly', 'Daily', 'Monthly', 'Manual'],
              options: ['Hourly', 'Monthly', 'Manual'],
              size: 4,
              required: true,
            },
            {
              type: 'date',
              field: 'order_date',
              label: 'From Date',
              placeholder: 'Start Date',
              size: 3,
              required: true,
            },
            {
              type: 'date',
              field: 'order_end_date',
              label: 'To Date',
              placeholder: 'End Date',
              size: 3,
              // validate: (value, formStates) => {
              //   const {values} = formStates;
              //   if (new Date(value) < new Date(values.order_date)) {
              //     return "To Date can't be less than From Date";
              //   }
              // },
              required: true,
            },
            // {
            //   type: 'number',
            //   field: 'advance_man_hrs',
            //   label: 'Man Hrs',
            //   placeholder: 'Man Hrs',
            //   size: 'small',
            // },
            {
              label: 'Customer Currency',
              placeholder: 'Currency',
              field: 'currency_id',
              type: 'autoComplete',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 3,
              required: true,
            },
            {
              type: 'number',
              field: 'exchange_rate',
              label: 'Exchange Rate',
              placeholder: 'Exchange Rate',
              size: 3,
              required: true,
            },
            // {                                    // commented because of new design
            //   label: 'Base Currency',
            //   placeholder: 'Currency',
            //   field: 'basecurrency_id',
            //   type: 'autoComplete',
            //   api: `/currencies`,
            //   suggestionField: 'currency',
            //   valueField: 'currency',
            //   size: 4,
            //   required: true,
            // },
            // {                                     // commented before
            //   type: 'number',
            //   field: 'base_amount',
            //   label: 'Base Amount',
            //   placeholder: 'Base Amount',
            //   size: 'small',
            //   readOnly: true,
            // },
          ],
        },
        {
          label: 'Requirement Details',
          fields: [
            {
              field: 'requirement_detail',
              nested: true,
              addInBottom: true,
              render: props => (
                <NestedTable
                  {...props}
                  defaultValues={({values}) => {
                    return {
                      order_date: values?.order_date,
                      recurring_till: values?.order_end_date,
                      repeats: 'Monthly',
                    };
                  }}
                  columns={[
                    // {
                    //   field: 'skill_required.name',
                    //   header: 'Skills',
                    //   width: 100,
                    // },
                    {
                      field: 'tool.name',
                      header: 'Skills',
                      width: 100,
                    },
                    {
                      field: 'no_of_resource',
                      header: 'Engg',
                      type: 'number',
                      width: 120,
                    },
                    {
                      header: 'Exp',
                      field: 'experience.experience',
                      width: 100,
                    },
                    {
                      field: 'order_date',
                      header: 'Start',
                      type: 'date',
                      width: 150,
                    },
                    {
                      field: 'recurring_till',
                      header: 'End',
                      type: 'date',
                      width: 150,
                    },
                    {
                      field: 'rate',
                      header: 'Rate/unit',
                      type: 'number',
                      width: 150,
                    },
                    {
                      field: 'amount',
                      header: 'Amount',
                      type: 'currency',
                      width: !50,
                    },

                    {
                      field: 'employee_per_day_working_hour',
                      header: 'Working Hrs/day',
                      type: 'number',
                      width: 200,
                    },
                    // {
                    //   field: 'due_on_after',
                    //   header: 'Invoice Due',
                    //   type: 'number',
                    // },
                    // {
                    //   field: 'advance_due',
                    //   header: 'Advance Due',
                    //   type: 'number',
                    // },
                    {
                      field: 'notes',
                      header: 'Notes',
                      width: 150,
                    },
                    // {
                    //   field: 'repeats',
                    //   header: 'Repeats',
                    //   width: 100,
                    // },
                  ]}
                  fields={[
                    // {
                    //   label: 'Skills',
                    //   placeholder: 'Skills',
                    //   field: 'skill_required',
                    //   type: 'autoComplete',
                    //   api: `/skillmetrics`,
                    //   suggestionField: 'name',
                    //   valueField: 'name',
                    //   required: true,
                    //   size: 6,
                    // },
                    {
                      label: 'Skills',
                      placeholder: 'Skills',
                      field: 'tool',
                      type: 'autoComplete',
                      api: ({values = {}}) => {
                        const {skill_required} = values;
                        if (!skill_required) return `/tools`;
                        return `/skillMetrics/toolsSuggestion/${skill_required?._id}`;
                      },
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      size: 6,
                    },
                    {
                      field: 'no_of_resource',
                      placeholder: 'Engg',
                      header: 'Engg',
                      type: 'number',
                      required: true,
                      size: 'small',
                    },
                    {
                      placeholder: 'Exp',
                      field: 'experience',
                      type: 'autoComplete',
                      api: `/employeeexperiences`,
                      suggestionField: 'experience',
                      valueField: 'experience',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'order_date',
                      placeholder: 'Start',
                      header: 'Start',
                      type: 'date',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'recurring_till',
                      placeholder: 'End',
                      header: 'End',
                      type: 'date',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'rate',
                      placeholder: 'Rate/Unit',
                      header: 'Rate/Unit',
                      type: 'number',
                      // required: true,
                      size: 'small',
                    },
                    {
                      field: 'amount',
                      placeholder: 'Amount',
                      header: 'Amount',
                      type: 'number',
                      // required: true,
                      size: 'small',
                    },
                    // {
                    //   field: 'base_amount',
                    //   placeholder: 'Base Amount',
                    //   header: 'Base Amount',
                    //   type: 'number',
                    //   size: 'small',
                    // },

                    {
                      field: 'employee_per_day_working_hour',
                      placeholder: 'Working hrs/day',
                      header: 'Working hrs/day',
                      type: 'number',
                      size: 'small',
                      required: true,
                    },
                    // {
                    //   field: 'due_on_after',
                    //   placeholder: 'Invoice Due',
                    //   header: 'Invoice Due',
                    //   keyField: 'value',
                    //   valueField: 'label',
                    //   suggestionField: 'label',
                    //   options: days,
                    //   type: 'autoComplete',
                    // },
                    // {
                    //   field: 'advance_due',
                    //   placeholder: 'Advance Due',
                    //   header: 'Advance Due',
                    //   keyField: 'value',
                    //   valueField: 'label',
                    //   suggestionField: 'label',
                    //   options: days,
                    //   type: 'autoComplete',
                    // },
                    {
                      field: 'notes',
                      header: 'Notes',
                      placeholder: 'Notes',
                      type: 'text',
                      // required: true,
                    },
                    // {
                    //   type: 'autoComplete',
                    //   field: 'repeats',
                    //   label: 'Repeats',
                    //   header: 'Repeats',
                    //   placeholder: 'Repeats',
                    //   options: ['Weekly', 'Monthly', 'Quarterly', 'Yearly'],
                    //   required: true,
                    // },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Billing Details',
          fields: [
            {
              type: 'number',
              field: 'no_of_leave',
              label: 'No.of leaves Allowed/month',
              placeholder: 'No of Leaves(In Days)',
              size: 3,
              // visible: ({values}) => {
              //   return (
              //     values.leave_model === 'Yearly' ||
              //     values.leave_model === 'Monthly'
              //   );
              // },
            },
            {
              type: 'autoComplete',
              field: 'ewd_allowed',
              label: 'EWD(allowed / no)',
              // header: 'EWD',
              placeholder: 'EWD(allowed / no)',
              options: ['Yes', 'No'],
              size: 3,
            },
            // {
            //   type: 'autoComplete',
            //   field: 'leave_model',
            //   label: 'Leave Model',
            //   header: 'Leave Model',
            //   placeholder: 'Leave Model',
            //   options: ['Yearly', 'Monthly', 'Not Allowed'],
            //   size: 'small',
            // },
            {
              type: 'autoComplete',
              field: 'schedule',
              label: 'Working day/week',
              // header: 'Schedule',
              placeholder: 'Working day/week',
              api: `/schedules`,
              suggestionField: 'name',
              valueField: 'name',
              size: 3,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'holiday_calender',
              label: 'Holiday Calender',
              placeholder: 'Holiday Calender',
              api: `/holidaycalendars`,
              suggestionField: 'name',
              valueField: 'name',
              size: 3,
              required: true,
            },
          ],
        },
        // {
        //   label: 'Terms & Conditions',
        //   fields: [
        //     {
        //       type: 'file',
        //       field: 'tech_document',
        //       placeholder: 'Tech Attachment',
        //       multiple: true,
        //     },
        //   ],
        // },
        // {
        //   label: 'MSA Details',
        //   fields: [
        //     {
        //       field: 'msa_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'msa_name',
        //               header: 'Name',
        //               type: 'text',
        //               width: '50%',
        //             },
        //             {
        //               field: 'msa_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'msa_name',
        //               type: 'autoComplete',
        //               header: 'name',
        //               api: '/customer/msa_detail',
        //               filter: {_id: props?.form?.values?.customer_id?._id},
        //             },
        //             {
        //               field: 'msa_attachment',
        //               placeholder: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        // {
        //   label: 'SOW Details',
        //   fields: [
        //     {
        //       field: 'sow_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'sow_name',
        //               type: 'text',
        //               header: 'Name',
        //               width: '50%',
        //             },
        //             {
        //               field: 'sow_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'sow_name',
        //               type: 'autoComplete',
        //               header: 'name',
        //               api: '/customer/sow_detail',
        //               filter: {_id: props?.form?.values?.customer_id?._id},
        //             },
        //             {
        //               field: 'sow_attachment',
        //               placeholder: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        {
          label: 'Internal StakeHolder',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'Sales Owner',
                  placeholder: 'Sales Owner',
                  field: 'sales_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 4,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Account Manager',
                  placeholder: 'Account Manager',
                  field: 'account_owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 4,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'CSM',
                  placeholder: 'CSM',
                  field: 'delivery_owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  secondarySuggestionField: 'official_email_id',
                  suggestionField: 'name',
                  valueField: 'name',
                  // required: true,
                  size: 4,
                },
              ],
            },
          ],
        },
        {
          groups: [
            {
              label: 'Accounting Details',
              defaultHidden: true,
              fields: [
                {
                  label: 'Supplying Organization',
                  placeholder: 'Supplying Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 'small',
                  required: true,
                },
                {
                  label: 'Product',
                  placeholder: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 'small',
                  required: true,
                },
                {
                  label: 'Tax Location',
                  placeholder: 'Tax Location',
                  field: 'location_id',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 'small',
                  required: true,
                },
              ],
            },
          ],
        },

        {
          label: 'Supporting documents',
          fields: [
            {
              field: 'msa_attachment',
              placeholder: 'MSA',
              header: 'Attachment',
              type: 'file',
              size: 6,
            },
            {
              field: 'sow_attachment',
              placeholder: 'Scope of Work',
              header: 'Attachment',
              type: 'file',
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const DetailTMOrderform = props => {
  const {
    route: {params = {}},
    header = 'T & M Order Details',
  } = props;
  const {department, product, row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/saveOrder',
    eventSourceId: 't&m',
    ...props,
  });
  return (
    <Form
      api={`/order-t&m/detail/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      mode="edit"
      readOnly={true}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              label: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'order_exists_type',
              label: 'Order Type',
              placeholder: 'Order Type',
              options: ['New', 'Extension'],
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'billing_type',
              label: 'Biling Model',
              header: 'Biling Model',
              placeholder: 'Repeats',
              // options: ['Hourly', 'Daily', 'Monthly', 'Manual'],
              options: ['Hourly', 'Monthly'],
              size: 4,
              required: true,
            },
            {
              type: 'date',
              field: 'order_date',
              label: 'From Date',
              placeholder: 'Start Date',
              size: 3,
              required: true,
            },
            {
              type: 'date',
              field: 'order_end_date',
              label: 'To Date',
              placeholder: 'End Date',
              size: 3,
              required: true,
            },
            // {
            //   type: 'number',
            //   field: 'advance_man_hrs',
            //   label: 'Man Hrs',
            //   placeholder: 'Man Hrs',
            //   size: 'small',
            // },
            {
              label: 'Customer Currency',
              placeholder: 'Currency',
              field: 'currency_id',
              type: 'autoComplete',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 3,
              required: true,
            },
            {
              type: 'number',
              field: 'exchange_rate',
              label: 'Exchange Rate',
              placeholder: 'Exchange Rate',
              size: 3,
              required: true,
            },
            // {                                    // commented because of new design
            //   label: 'Base Currency',
            //   placeholder: 'Currency',
            //   field: 'basecurrency_id',
            //   type: 'autoComplete',
            //   api: `/currencies`,
            //   suggestionField: 'currency',
            //   valueField: 'currency',
            //   size: 4,
            //   required: true,
            // },
            // {                                     // commented before
            //   type: 'number',
            //   field: 'base_amount',
            //   label: 'Base Amount',
            //   placeholder: 'Base Amount',
            //   size: 'small',
            //   readOnly: true,
            // },
          ],
        },
        {
          label: 'Requirement Details',
          fields: [
            {
              field: 'requirement_detail',
              nested: true,
              // addInBottom: true,
              render: props => (
                <NestedTable
                  {...props}
                  defaultValues={({values}) => {
                    return {
                      order_date: values?.order_date,
                      recurring_till: values?.order_end_date,
                      repeats: 'Monthly',
                    };
                  }}
                  columns={[
                    // {
                    //   field: 'skill_required.name',
                    //   header: 'Skills',
                    //   width: 100,
                    // },
                    {
                      field: 'tool.name',
                      header: 'Skills',
                      width: 100,
                    },
                    {
                      field: 'no_of_resource',
                      header: 'Engg',
                      type: 'number',
                      width: 120,
                    },
                    {
                      header: 'Exp',
                      field: 'experience.experience',
                      width: 100,
                    },
                    {
                      field: 'order_date',
                      header: 'Start',
                      type: 'date',
                      width: 150,
                    },
                    {
                      field: 'recurring_till',
                      header: 'End',
                      type: 'date',
                      width: 150,
                    },
                    {
                      field: 'rate',
                      header: 'Rate/unit',
                      type: 'number',
                      width: 150,
                    },
                    {
                      field: 'amount',
                      header: 'Amount',
                      type: 'currency',
                      width: !50,
                    },

                    {
                      field: 'employee_per_day_working_hour',
                      header: 'Working Hrs/day',
                      type: 'number',
                      width: 200,
                    },
                    // {
                    //   field: 'due_on_after',
                    //   header: 'Invoice Due',
                    //   type: 'number',
                    // },
                    // {
                    //   field: 'advance_due',
                    //   header: 'Advance Due',
                    //   type: 'number',
                    // },
                    {
                      field: 'notes',
                      header: 'Notes',
                      width: 150,
                    },
                    // {
                    //   field: 'repeats',
                    //   header: 'Repeats',
                    //   width: 100,
                    // },
                  ]}
                  fields={[
                    // {
                    //   label: 'Skills',
                    //   placeholder: 'Skills',
                    //   field: 'skill_required',
                    //   type: 'autoComplete',
                    //   api: `/skillmetrics`,
                    //   suggestionField: 'name',
                    //   valueField: 'name',
                    //   required: true,
                    //   size: 6,
                    // },
                    {
                      label: 'Skills',
                      placeholder: 'Skills',
                      field: 'tool',
                      type: 'autoComplete',
                      api: ({values = {}}) => {
                        const {skill_required} = values;
                        if (!skill_required) return `/tools`;
                        return `/skillMetrics/toolsSuggestion/${skill_required?._id}`;
                      },
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      size: 6,
                    },
                    {
                      field: 'no_of_resource',
                      placeholder: 'Engg',
                      header: 'Engg',
                      type: 'number',
                      required: true,
                      size: 'small',
                    },
                    {
                      placeholder: 'Exp',
                      field: 'experience',
                      type: 'autoComplete',
                      api: `/employeeexperiences`,
                      suggestionField: 'experience',
                      valueField: 'experience',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'order_date',
                      placeholder: 'Start',
                      header: 'Start',
                      type: 'date',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'recurring_till',
                      placeholder: 'End',
                      header: 'End',
                      type: 'date',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'rate',
                      placeholder: 'Rate/Unit',
                      header: 'Rate/Unit',
                      type: 'number',
                      // required: true,
                      size: 'small',
                    },
                    {
                      field: 'amount',
                      placeholder: 'Amount',
                      header: 'Amount',
                      type: 'number',
                      // required: true,
                      size: 'small',
                    },
                    // {
                    //   field: 'base_amount',
                    //   placeholder: 'Base Amount',
                    //   header: 'Base Amount',
                    //   type: 'number',
                    //   size: 'small',
                    // },

                    {
                      field: 'employee_per_day_working_hour',
                      placeholder: 'Working hrs/day',
                      header: 'Working hrs/day',
                      type: 'number',
                      size: 'small',
                      required: true,
                    },
                    // {
                    //   field: 'due_on_after',
                    //   placeholder: 'Invoice Due',
                    //   header: 'Invoice Due',
                    //   keyField: 'value',
                    //   valueField: 'label',
                    //   suggestionField: 'label',
                    //   options: days,
                    //   type: 'autoComplete',
                    // },
                    // {
                    //   field: 'advance_due',
                    //   placeholder: 'Advance Due',
                    //   header: 'Advance Due',
                    //   keyField: 'value',
                    //   valueField: 'label',
                    //   suggestionField: 'label',
                    //   options: days,
                    //   type: 'autoComplete',
                    // },
                    {
                      field: 'notes',
                      header: 'Notes',
                      placeholder: 'Notes',
                      type: 'text',
                      required: true,
                    },
                    // {
                    //   type: 'autoComplete',
                    //   field: 'repeats',
                    //   label: 'Repeats',
                    //   header: 'Repeats',
                    //   placeholder: 'Repeats',
                    //   options: ['Weekly', 'Monthly', 'Quarterly', 'Yearly'],
                    //   required: true,
                    // },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Billing Details',
          fields: [
            {
              type: 'number',
              field: 'no_of_leave',
              label: 'No.of leaves Allowed/month',
              placeholder: 'No of Leaves(In Days)',
              size: 3,
              // visible: ({values}) => {
              //   return (
              //     values.leave_model === 'Yearly' ||
              //     values.leave_model === 'Monthly'
              //   );
              // },
            },
            {
              type: 'autoComplete',
              field: 'ewd_allowed',
              label: 'EWD(allowed / no)',
              // header: 'EWD',
              placeholder: 'EWD(allowed / no)',
              options: ['Yes', 'No'],
              size: 3,
            },
            // {
            //   type: 'autoComplete',
            //   field: 'leave_model',
            //   label: 'Leave Model',
            //   header: 'Leave Model',
            //   placeholder: 'Leave Model',
            //   options: ['Yearly', 'Monthly', 'Not Allowed'],
            //   size: 'small',
            // },
            {
              type: 'autoComplete',
              field: 'schedule',
              label: 'Working day/week',
              // header: 'Schedule',
              placeholder: 'Working day/week',
              api: `/schedules`,
              suggestionField: 'name',
              valueField: 'name',
              size: 3,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'holiday_calender',
              label: 'Holiday Calender',
              placeholder: 'Holiday Calender',
              api: `/holidaycalendars`,
              suggestionField: 'name',
              valueField: 'name',
              size: 3,
              required: true,
            },
          ],
        },
        // {
        //   label: 'Terms & Conditions',
        //   fields: [
        //     {
        //       type: 'file',
        //       field: 'tech_document',
        //       placeholder: 'Tech Attachment',
        //       multiple: true,
        //     },
        //   ],
        // },
        // {
        //   label: 'MSA Details',
        //   fields: [
        //     {
        //       field: 'msa_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'msa_name',
        //               header: 'Name',
        //               type: 'text',
        //               width: '50%',
        //             },
        //             {
        //               field: 'msa_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'msa_name',
        //               type: 'autoComplete',
        //               header: 'name',
        //               api: '/customer/msa_detail',
        //               filter: {_id: props?.form?.values?.customer_id?._id},
        //             },
        //             {
        //               field: 'msa_attachment',
        //               placeholder: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        // {
        //   label: 'SOW Details',
        //   fields: [
        //     {
        //       field: 'sow_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'sow_name',
        //               type: 'text',
        //               header: 'Name',
        //               width: '50%',
        //             },
        //             {
        //               field: 'sow_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'sow_name',
        //               type: 'autoComplete',
        //               header: 'name',
        //               api: '/customer/sow_detail',
        //               filter: {_id: props?.form?.values?.customer_id?._id},
        //             },
        //             {
        //               field: 'sow_attachment',
        //               placeholder: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        {
          label: 'Internal StakeHolder',
          fields: [
            {
              label: 'Sales Owner',
              placeholder: 'Sales Owner',
              field: 'sales_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              label: 'Account Manager',
              placeholder: 'Account Manager',
              field: 'account_owner_id',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              label: 'CSM',
              placeholder: 'CSM',
              field: 'delivery_owner_id',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              secondarySuggestionField: 'official_email_id',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
            // {                                  // commented because of new design
            //   label: 'Practice Head',
            //   placeholder: 'Practice Head',
            //   field: 'practice_head',
            //   type: 'autoComplete',
            //   api: `/employeeSuggestions`,
            //   suggestionField: 'name',
            //   secondarySuggestionField: 'official_email_id',
            //   valueField: 'name',
            //   size: 'small',
            //   required: true,
            // },
          ],
        },
        {
          label: 'Accounting Details',
          fields: [
            {
              label: 'Supplying Organization',
              placeholder: 'Supplying Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 'small',
              required: true,
            },
            {
              label: 'Product',
              placeholder: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 'small',
              required: true,
            },
            {
              label: 'Tax Location',
              placeholder: 'Tax Location',
              field: 'location_id',
              type: 'autoComplete',
              api: `/offices`,
              suggestionField: 'name',
              valueField: 'name',
              size: 'small',
              required: true,
            },
          ],
        },

        {
          label: 'Supporting documents',
          fields: [
            {
              field: 'msa_attachment',
              placeholder: 'MSA',
              header: 'MSA',
              type: 'file',
              label: 'MSA',
              size: 6,
            },
            {
              field: 'sow_attachment',
              placeholder: 'Scope of Work',
              header: 'Scope of Work',
              type: 'file',
              label: 'Scope of Work',
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditTMOrderForm = props => {
  return (
    <AddTMOrderform
      mode="edit"
      fields={{
        customer_id: {_id: 1, name: 1},
        order_exists_type: 1,
        order_end_date: 1,
        order_date: 1,
        exchange_rate: 1,
        currency_id: {_id: 1, currency: 1},
        basecurrency_id: {_id: 1, currency: 1},
        amount: 1,
        requirement_detail: {
          skill_required: {name: 1},
          no_of_resource: 1,
          experience: {experience: 1},
          rate: 1,
          amount: 1,
          order_date: 1,
          recurring_till: 1,
          employee_per_day_working_hour: 1,
          due_on_after: 1,
          advance_due: 1,
          repeats: 1,
          base_amount: 1,
          tool: {name: 1},
          notes: 1,
        },
        no_of_leave: 1,
        tech_document: 1,
        msa_attachment: 1,
        sow_attachment: 1,
        msa_detail: {_id: 1, msa_name: 1, msa_attachment: 1},
        sow_detail: {_id: 1, sow_name: 1, sow_attachment: 1},
        organization: {_id: 1, name: 1},
        product: {_id: 1, name: 1},
        location_id: {_id: 1, name: 1},
        sales_owner: {_id: 1, name: 1},
        practice_head: {_id: 1, name: 1},
        account_owner_id: {_id: 1, name: 1},
        delivery_owner_id: {_id: 1, name: 1},
        billing_type: 1,
        ewd_allowed: 1,
        leave_model: 1,
        schedule: {_id: 1, name: 1},
        holiday_calender: {_id: 1, name: 1},
      }}
      header={'Edit T&M Order'}
      {...props}
    />
  );
};

export const CopyTMOrderForm = props => {
  return (
    <AddTMOrderform
      mode="edit"
      formCopyOrder={true}
      header={'Copy T&M Order'}
      {...props}
    />
  );
};

export const AddFixedOrderform = props => {
  const {
    route: {params = {}},
    header = 'Add Fixed Order',
  } = props;
  const {department, product, row, key} = params;
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  const {onSubmit} = useFormSubmit({
    uri: '/saveOrder',
    eventSourceId: 'fixed',
    ...props,
  });
  let defaultValues = {};
  if (key && key.is_internal === true) {
    defaultValues = key;
  }

  return (
    <Form
      api={`/orders/${row?._id}`}
      onSubmit={onSubmit}
      fields={{
        customer_id: {_id: 1, name: 1},
        order_exists_type: 1,
        order_end_date: 1,
        order_date: 1,
        exchange_rate: 1,
        is_internal: 1,
        delivery_rate: 1,
        est_order_hrs: 1,
        account_owner_id: {_id: 1, name: 1},
        currency_id: {_id: 1, currency: 1},
        basecurrency_id: {_id: 1, currency: 1},
        order_source: 1,
        amount: 1,
        requirement_detail: {
          skill_required: {name: 1},
          no_of_resource: 1,
          experience: {experience: 1},
          rate: 1,
          amount: 1,
          order_date: 1,
          recurring_till: 1,
          employee_per_day_working_hour: 1,
          due_on_after: 1,
          advance_due: 1,
          repeats: 1,
          base_amount: 1,
        },
        no_of_leave: 1,
        tech_document: 1,
        msa_detail: {_id: 1, msa_name: 1, msa_attachment: 1},
        sow_detail: {_id: 1, sow_name: 1, sow_attachment: 1},
        proposal: 1,
        organization: {_id: 1, name: 1},
        product: {_id: 1, name: 1},
        location_id: {_id: 1, name: 1},
        sales_owner: {_id: 1, name: 1},
        practice_head: {_id: 1, name: 1},
        account_owner_id: {_id: 1, name: 1},
        delivery_owner_id: {_id: 1, name: 1},
        billing_type: 1,
        ewd_allowed: 1,
        leave_model: 1,
        schedule: {_id: 1, name: 1},
        holiday_calender: {_id: 1, name: 1},
        description: 1,
      }}
      header={header}
      defaultValues={{
        // organization: employeeData?.organization,
        order_type: 'ot',
        basecurrency_id: employeeData?.organization?.currency,
        currency_id: employeeData?.organization?.currency,
        // product: employeeData?.product,
        order_date: getZeroTimeDate(new Date()),
        status: 'New',
        ...defaultValues,
      }}
      computations={{...orderComputations(fetch)}}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              titleField: 'name',
              required: true,
              size: 3,
            },
            // {
            //   type: 'checkbox',
            //   field: 'is_internal',
            //   placeholder: 'Internal Customer',
            //   label: 'Internal Customer',
            //   size: 3,
            // },
            {
              type: 'autoComplete',
              field: 'order_exists_type',
              label: 'Order Type',
              placeholder: 'Order Type',
              options: ['New', 'CR', 'Support'],
              required: true,
              size: 3,
            },
            // {
            //   type: 'autoComplete',
            //   field: 'order_source',
            //   label: 'Source',
            //   placeholder: 'Source',
            //   options: ['Direct', 'Upwork', 'RFQ'],
            //   size: 3,
            // },
            // {
            //   type: 'checkbox',
            //   field: 'is_internal',
            //   placeholder: 'Billed',
            //   label: 'Billed',
            //   size: 3,
            // },
            {
              type: 'date',
              field: 'order_date',
              label: 'From Date',
              placeholder: 'From Date',
              required: true,
              size: 3,
            },
            {
              type: 'date',
              field: 'order_end_date',
              label: 'To Date',
              placeholder: 'To Date',
              // validate: (value, formStates) => {
              //   const {values} = formStates;
              //   if (new Date(value) < new Date(values.order_date)) {
              //     return "To Date can't be less than From Date";
              //   }
              // },
              required: true,
              size: 3,
            },
            {
              label: 'Customer Currency',
              placeholder: 'Customer Currency',
              field: 'currency_id',
              type: 'autoComplete',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 3,
              required: true,
            },
            {
              type: 'number',
              field: 'exchange_rate',
              label: 'Exchange Rate',
              placeholder: 'Exchange Rate',
              required: true,
              size: 3,
            },
          ],
        },
        {
          label: 'Requirements',
          fields: [
            {
              type: 'number',
              field: 'est_order_hrs',
              label: 'Estimated Man Hour',
              placeholder: 'Estimated Man Hour',
              required: true,
              size: 4,
            },
            {
              type: 'number',
              field: 'delivery_rate',
              label: 'Per Hour Rate',
              placeholder: 'Per Hour Rate',
              required: true,
              size: 4,
            },
            // {
            //   label: 'Base Currency',
            //   placeholder: 'Base Currency',
            //   field: 'basecurrency_id',
            //   type: 'autoComplete',
            //   api: `/currencies`,
            //   suggestionField: 'currency',
            //   valueField: 'currency',
            //   size: 'small',
            //   required: true,
            // },

            // {
            //   type: 'text',
            //   field: 'description',
            //   label: 'Description',
            //   placeholder: 'Description',
            //   size: 'small',
            //   required: true,
            // },
            {
              type: 'number',
              field: 'amount',
              label: 'Amount',
              placeholder: 'Amount',
              size: 4,
              readOnly: true,
            },
            // {
            //   type: 'number',
            //   field: 'base_amount',
            //   label: 'Base Amount',
            //   placeholder: 'Base Amount',
            //   size: 'small',
            //   readOnly: true,
            // },
            // {
            //   label: 'Milestone',
            //   fields: [
            //     {
            //       field: 'milestone_detail',
            //       nested: true,
            //       addInBottom: true,
            //       render: props => (
            //         <NestedTable
            //           {...props}
            //           columns={[
            //             {
            //               field: 'milestone_number',
            //               header: 'M No.',
            //               width: 120,
            //             },
            //             {
            //               field: 'description',
            //               header: 'Description',
            //               type: 'text',
            //               minWidth: 300,
            //             },
            //             {
            //               field: 'start_date',
            //               header: 'Start Date',
            //               type: 'date',
            //               width: 200,
            //             },
            //             {
            //               field: 'due_date',
            //               header: 'End Date',
            //               type: 'date',
            //               width: 200,
            //             },
            //             {
            //               field: 'billing_hrs',
            //               header: 'Est hrs',
            //               width: 100,
            //             },
            //             {
            //               header: 'Amount',
            //               field: 'amount',
            //               width: 100,
            //             },
            //             // {
            //             //   field: 'invoice_due_date',
            //             //   header: 'Invoice Due',
            //             //   type: 'date',
            //             // },
            //             // {
            //             //   field: 'advance_due_date',
            //             //   header: 'Advance Due',
            //             //   type: 'date',
            //             // },
            //             // {
            //             //   field: 'delivery_owner_id.name',
            //             //   header: 'Delivery Owner',
            //             //   width: 100,
            //             // },
            //             // {
            //             //   field: 'variable_potential',
            //             //   header: 'Potential',
            //             //   width: 100,
            //             // },
            //           ]}
            //           fields={[
            //             {
            //               field: 'milestone_number',
            //               label: 'M No.',
            //               type: 'text',
            //               size: 3,
            //             },
            //             {
            //               field: 'description',
            //               label: 'Description',
            //               type: 'text',
            //               size: 9,
            //             },
            //             {
            //               field: 'start_date',
            //               label: 'Start Date',
            //               type: 'date',
            //               size: 6,
            //             },
            //             {
            //               field: 'due_date',
            //               label: 'End Date',
            //               type: 'date',
            //               size: 6,
            //             },
            //             {
            //               field: 'billing_hrs',
            //               label: 'Est hrs',
            //               type: 'text',
            //               size: 6,
            //             },
            //             {
            //               label: 'Amount',
            //               field: 'amount',
            //               type: 'number',
            //               size: 6,
            //             },
            //           ]}
            //         />
            //       ),
            //     },
            //   ],
            // },
          ],
        },
        // {
        //   label: 'MSA Details',
        //   fields: [
        //     {
        //       field: 'msa_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'msa_name',
        //               header: 'Name',
        //               width: '50%',
        //             },
        //             {
        //               field: 'msa_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'msa_name',
        //               placeholder: 'Name',
        //               label: 'Name',
        //               header: 'Name',
        //               type: 'text',
        //             },
        //             {
        //               field: 'msa_attachment',
        //               placeholder: 'Attachment',
        //               label: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        // {
        //   label: 'SOW Details',
        //   fields: [
        //     {
        //       field: 'sow_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'sow_name',
        //               header: 'Name',
        //               width: '50%',
        //             },
        //             {
        //               field: 'sow_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'sow_name',
        //               placeholder: 'Name',
        //               label: 'Name',
        //               header: 'Name',
        //               type: 'text',
        //             },
        //             {
        //               field: 'sow_attachment',
        //               placeholder: 'Attachment',
        //               label: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        {
          label: 'Internal Stakeholder',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'Sales Owner',
                  placeholder: 'Sales Owner',
                  field: 'sales_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 4,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Account Manager',
                  placeholder: 'Account Manager',
                  field: 'account_owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 4,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'CSM',
                  placeholder: 'CSM',
                  field: 'delivery_owner_id',
                  type: 'autoComplete',
                  secondarySuggestionField: 'official_email_id',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                },
              ],
            },
          ],
        },
        {
          groups: [
            {
              label: 'Accounting Details',
              defaultHidden: true,
              fields: [
                {
                  label: 'Supplying Organization',
                  placeholder: 'Supplying Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
                {
                  label: 'Product',
                  placeholder: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                },
                {
                  label: 'Tax Location',
                  placeholder: 'Tax Location',
                  field: 'location_id',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Supporting Documents',
          fields: [
            // {
            //   field: 'msa_attachment',
            //   placeholder: 'MSA',
            //   label: 'MSA',
            //   header: 'MSA',
            //   type: 'file',
            //   size: 3,
            // },
            {
              field: 'sow_attachment',
              placeholder: 'Scope of Work',
              label: 'Scope of Work',
              header: 'Scope of Work',
              type: 'file',
              size: 3,
            },
            {
              type: 'file',
              field: 'proposal',
              multiple: true,
              placeholder: 'Proposal & Estimate',
              label: 'Proposal & Estimate',
              size: 3,
            },
            // {
            //   type: 'file',
            //   field: 'tech_document',
            //   multiple: true,
            //   placeholder: 'Other',
            //   label: 'Other',
            //   size: 3,
            // },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditFixedOrderForm = props => {
  return (
    <AddFixedOrderform mode="edit" header={'Edit Fixed Order'} {...props} />
  );
};

export const DetailFixedOrderForm = props => {
  const {
    route: {params},
  } = props;
  const {department, product, row} = params;
  const {user: {employee: employeeData = {}} = {}} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    uri: '/saveOrder',
    eventSourceId: 'fixed',
    ...props,
  });
  return (
    <Form
      api={`/order-fixed/detail/${row?._id}`}
      mode="edit"
      readOnly={true}
      onSubmit={onSubmit}
      header={'Fixed Order Details'}
      defaultValues={{
        department: department?._id,
        organization: employeeData?.organization,
        product: params?.product,
        order_type: 'ot',
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 3,
            },
            // {
            //   type: 'checkbox',
            //   field: 'is_internal',
            //   placeholder: 'Internal Customer',
            //   label: 'Internal Customer',
            //   size: 3,
            // },
            {
              type: 'autoComplete',
              field: 'order_exists_type',
              label: 'Order Type',
              placeholder: 'Order Type',
              options: ['New', 'CR', 'Support'],
              required: true,
              size: 3,
            },
            // {
            //   type: 'autoComplete',
            //   field: 'order_source',
            //   label: 'Source',
            //   placeholder: 'Source',
            //   options: ['Direct', 'Upwork', 'RFQ'],
            //   size: 3,
            // },
            // {
            //   type: 'checkbox',
            //   field: 'is_internal',
            //   placeholder: 'Billed',
            //   label: 'Billed',
            //   size: 3,
            // },
            {
              type: 'date',
              field: 'order_date',
              label: 'From Date',
              placeholder: 'From Date',
              required: true,
              size: 3,
            },
            {
              type: 'date',
              field: 'order_end_date',
              label: 'To Date',
              placeholder: 'To Date',
              required: true,
              size: 3,
            },
            {
              label: 'Customer Currency',
              placeholder: 'Customer Currency',
              field: 'currency_id',
              type: 'autoComplete',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 3,
              required: true,
            },
            {
              type: 'number',
              field: 'exchange_rate',
              label: 'Exchange Rate',
              placeholder: 'Exchange Rate',
              required: true,
              size: 3,
            },
          ],
        },
        {
          label: 'Requirements',
          fields: [
            {
              type: 'number',
              field: 'est_order_hrs',
              label: 'Estimated Man Hour',
              placeholder: 'Estimated Man Hour',
              required: true,
              size: 4,
            },
            {
              type: 'number',
              field: 'delivery_rate',
              label: 'Per Hour Rate',
              placeholder: 'Per Hour Rate',
              required: true,
              size: 4,
            },
            // {
            //   label: 'Base Currency',
            //   placeholder: 'Base Currency',
            //   field: 'basecurrency_id',
            //   type: 'autoComplete',
            //   api: `/currencies`,
            //   suggestionField: 'currency',
            //   valueField: 'currency',
            //   size: 'small',
            //   required: true,
            // },

            // {
            //   type: 'text',
            //   field: 'description',
            //   label: 'Description',
            //   placeholder: 'Description',
            //   size: 'small',
            //   required: true,
            // },
            {
              type: 'number',
              field: 'amount',
              label: 'Amount',
              placeholder: 'Amount',
              size: 4,
              readOnly: true,
            },
            // {
            //   type: 'number',
            //   field: 'base_amount',
            //   label: 'Base Amount',
            //   placeholder: 'Base Amount',
            //   size: 'small',
            //   readOnly: true,
            // },
            // {
            //   label: 'Milestone',
            //   fields: [
            //     {
            //       field: 'milestone_detail',
            //       nested: true,
            //       addInBottom: true,
            //       render: props => (
            //         <NestedTable
            //           {...props}
            //           columns={[
            //             {
            //               field: 'milestone_number',
            //               header: 'M No.',
            //               width: 120,
            //             },
            //             {
            //               field: 'description',
            //               header: 'Description',
            //               type: 'text',
            //               minWidth: 300,
            //             },
            //             {
            //               field: 'start_date',
            //               header: 'Start Date',
            //               type: 'date',
            //               width: 200,
            //             },
            //             {
            //               field: 'due_date',
            //               header: 'End Date',
            //               type: 'date',
            //               width: 200,
            //             },
            //             {
            //               field: 'billing_hrs',
            //               header: 'Est hrs',
            //               width: 100,
            //             },
            //             {
            //               header: 'Amount',
            //               field: 'amount',
            //               width: 100,
            //             },
            //             // {
            //             //   field: 'invoice_due_date',
            //             //   header: 'Invoice Due',
            //             //   type: 'date',
            //             // },
            //             // {
            //             //   field: 'advance_due_date',
            //             //   header: 'Advance Due',
            //             //   type: 'date',
            //             // },
            //             // {
            //             //   field: 'delivery_owner_id.name',
            //             //   header: 'Delivery Owner',
            //             //   width: 100,
            //             // },
            //             // {
            //             //   field: 'variable_potential',
            //             //   header: 'Potential',
            //             //   width: 100,
            //             // },
            //           ]}
            //           fields={[
            //             {
            //               field: 'milestone_number',
            //               label: 'M No.',
            //               type: 'text',
            //               size: 3,
            //             },
            //             {
            //               field: 'description',
            //               label: 'Description',
            //               type: 'text',
            //               size: 9,
            //             },
            //             {
            //               field: 'start_date',
            //               label: 'Start Date',
            //               type: 'date',
            //               size: 6,
            //             },
            //             {
            //               field: 'due_date',
            //               label: 'End Date',
            //               type: 'date',
            //               size: 6,
            //             },
            //             {
            //               field: 'billing_hrs',
            //               label: 'Est hrs',
            //               type: 'text',
            //               size: 6,
            //             },
            //             {
            //               label: 'Amount',
            //               field: 'amount',
            //               type: 'number',
            //               size: 6,
            //             },
            //           ]}
            //         />
            //       ),
            //     },
            //   ],
            // },
          ],
        },
        // {
        //   label: 'MSA Details',
        //   fields: [
        //     {
        //       field: 'msa_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'msa_name',
        //               header: 'Name',
        //               width: '50%',
        //             },
        //             {
        //               field: 'msa_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'msa_name',
        //               placeholder: 'Name',
        //               label: 'Name',
        //               header: 'Name',
        //               type: 'text',
        //             },
        //             {
        //               field: 'msa_attachment',
        //               placeholder: 'Attachment',
        //               label: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        // {
        //   label: 'SOW Details',
        //   fields: [
        //     {
        //       field: 'sow_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'sow_name',
        //               header: 'Name',
        //               width: '50%',
        //             },
        //             {
        //               field: 'sow_attachment',
        //               header: 'Attachment',
        //               width: '50%',
        //               type: 'file',
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'sow_name',
        //               placeholder: 'Name',
        //               label: 'Name',
        //               header: 'Name',
        //               type: 'text',
        //             },
        //             {
        //               field: 'sow_attachment',
        //               placeholder: 'Attachment',
        //               label: 'Attachment',
        //               header: 'Attachment',
        //               type: 'file',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        {
          label: 'Internal Stakeholder',
          fields: [
            {
              label: 'Sales Owner',
              placeholder: 'Sales Owner',
              field: 'sales_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              label: 'Account Manager',
              placeholder: 'Account Manager',
              field: 'account_owner_id',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              label: 'CSM',
              placeholder: 'CSM',
              field: 'delivery_owner_id',
              type: 'autoComplete',
              secondarySuggestionField: 'official_email_id',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
          ],
        },
        {
          label: 'Accounting Details',
          fields: [
            {
              label: 'Supplying Organization',
              placeholder: 'Supplying Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              label: 'Product',
              placeholder: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Tax Location',
              placeholder: 'Tax Location',
              field: 'location_id',
              type: 'autoComplete',
              api: `/offices`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              required: true,
            },
          ],
        },
        {
          label: 'Supporting Documents',
          fields: [
            // {
            //   field: 'msa_attachment',
            //   placeholder: 'MSA',
            //   label: 'MSA',
            //   header: 'MSA',
            //   type: 'file',
            //   size: 3,
            // },
            {
              field: 'sow_attachment',
              placeholder: 'Scope of Work',
              label: 'Scope of Work',
              header: 'Scope of Work',
              type: 'file',
              size: 3,
            },
            {
              type: 'file',
              field: 'proposal',
              multiple: true,
              placeholder: 'Proposal & Estimate',
              label: 'Proposal & Estimate',
              size: 3,
            },
            // {
            //   type: 'file',
            //   field: 'tech_document',
            //   multiple: true,
            //   placeholder: 'Other',
            //   label: 'Other',
            //   size: 3,
            // },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddSupportOrderform = props => {
  const {
    route: {params = {}},
    header = 'Add Support Order',
  } = props;
  const {department, product, row} = params;

  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  const {onSubmit} = useFormSubmit({
    uri: '/saveOrder',
    eventSourceId: 'support',
    ...props,
  });
  return (
    <Form
      api={`/orders/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        organization: employeeData?.organization,
        product: employeeData?.product,
        basecurrency_id: employeeData?.organization?.currency,
        currency_id: employeeData?.organization?.currency,
        order_type: 'r',
        order_date: getZeroTimeDate(new Date()),
        status: 'New',
        order_exists_type: 'Support',
        billing_type: 'Manual',
      }}
      submitAction={'Save'}
      computations={{...orderComputations(fetch)}}
      layoutFields={[
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              label: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              titleField: 'name',
              size: 12,
              required: true,
            },
            // {
            //   type: 'autoComplete',
            //   field: 'billing_type',
            //   label: 'Biling Model',
            //   header: 'Biling Model',
            //   placeholder: 'Repeats',
            //   options: ['Hourly', 'Monthly'],
            //   size: 6,
            //   required: true,
            // },
            {
              type: 'date',
              field: 'order_date',
              label: 'From Date',
              placeholder: 'Start Date',
              size: 3,
              required: true,
            },
            {
              type: 'date',
              field: 'order_end_date',
              label: 'To Date',
              placeholder: 'End Date',
              size: 3,
              required: true,
            },
            {
              label: 'Customer Currency',
              placeholder: 'Currency',
              field: 'currency_id',
              type: 'autoComplete',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 3,
              required: true,
            },
            {
              type: 'number',
              field: 'exchange_rate',
              label: 'Exchange Rate',
              placeholder: 'Exchange Rate',
              size: 3,
              required: true,
            },
          ],
        },
        {
          label: 'Requirements',
          fields: [
            {
              field: 'requirement_detail',
              nested: true,
              addInBottom: true,
              render: props => (
                <NestedTable
                  {...props}
                  defaultValues={({values}) => {
                    return {
                      order_date: values?.order_date,
                      recurring_till: values?.order_end_date,
                    };
                  }}
                  columns={[
                    {
                      field: 'tool.name',
                      header: 'Skills',
                      width: 100,
                    },
                    {
                      field: 'no_of_resource',
                      header: 'Engg',
                      type: 'number',
                      width: 120,
                    },
                    {
                      header: 'Exp',
                      field: 'experience.experience',
                      width: 100,
                    },
                    {
                      field: 'order_date',
                      header: 'Start',
                      type: 'date',
                      width: 150,
                    },
                    {
                      field: 'recurring_till',
                      header: 'End',
                      type: 'date',
                      width: 150,
                    },
                    {
                      field: 'hours',
                      header: 'Hours',
                      type: 'number',
                      width: 150,
                    },
                    {
                      field: 'amount',
                      header: 'Amount',
                      type: 'currency',
                      width: 150,
                    },
                    {
                      field: 'notes',
                      header: 'Notes',
                      width: 150,
                    },
                    {
                      field: 'repeats',
                      header: 'Repeats',
                      width: 100,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Skills',
                      placeholder: 'Skills',
                      field: 'tool',
                      type: 'autoComplete',
                      api: ({values = {}}) => {
                        const {skill_required} = values;
                        if (!skill_required) return `/tools`;
                        return `/skillMetrics/toolsSuggestion/${skill_required?._id}`;
                      },
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      size: 6,
                    },
                    {
                      field: 'no_of_resource',
                      placeholder: 'Engg',
                      header: 'Engg',
                      type: 'number',
                      required: true,
                      size: 'small',
                    },
                    {
                      placeholder: 'Exp',
                      field: 'experience',
                      type: 'autoComplete',
                      api: `/employeeexperiences`,
                      suggestionField: 'experience',
                      valueField: 'experience',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'order_date',
                      placeholder: 'Start',
                      header: 'Start',
                      type: 'date',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'recurring_till',
                      placeholder: 'End',
                      header: 'End',
                      type: 'date',
                      required: true,
                      size: 'small',
                    },
                    {
                      field: 'hours',
                      placeholder: 'Hours',
                      header: 'Hours',
                      type: 'number',
                      size: 'small',
                    },
                    {
                      field: 'amount',
                      placeholder: 'Amount',
                      header: 'Amount',
                      type: 'number',
                      size: 'small',
                    },
                    {
                      field: 'notes',
                      header: 'Notes',
                      placeholder: 'Notes',
                      type: 'text',
                      required: true,
                    },
                    {
                      type: 'autoComplete',
                      field: 'repeats',
                      label: 'Repeats',
                      header: 'Repeats',
                      placeholder: 'Repeats',
                      options: ['Weekly', 'Monthly', 'Quarterly', 'Yearly'],
                      required: true,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Billing Details',
          fields: [
            {
              type: 'number',
              field: 'no_of_leave',
              label: 'No of Leaves allowed',
              placeholder: 'No of Leaves(In Days)',
              size: 3,
            },
            {
              type: 'autoComplete',
              field: 'ewd_allowed',
              label: 'EWD(allowed / no)',
              placeholder: 'EWD(allowed / no)',
              options: ['Yes', 'No'],
              size: 3,
            },
            {
              type: 'autoComplete',
              field: 'schedule',
              label: 'Working day/week',
              placeholder: 'Working day/week',
              api: `/schedules`,
              suggestionField: 'name',
              valueField: 'name',
              size: 3,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'holiday_calender',
              label: 'Holiday Calender',
              placeholder: 'Holiday Calender',
              api: `/holidaycalendars`,
              suggestionField: 'name',
              valueField: 'name',
              size: 3,
              required: true,
            },
          ],
        },
        {
          label: 'Internal Stack Holder',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'Sales Owner',
                  placeholder: 'Sales Owner',
                  field: 'sales_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 4,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Account Manager',
                  placeholder: 'Account Manager',
                  field: 'account_owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 4,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'CSM',
                  placeholder: 'CSM',
                  field: 'delivery_owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  secondarySuggestionField: 'official_email_id',
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 4,
                },
              ],
            },
          ],
        },
        {
          groups: [
            {
              label: 'Accounting Details',
              fields: [
                {
                  label: 'Supplying Organization',
                  placeholder: 'Supplying Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 'small',
                  required: true,
                },
                {
                  label: 'Product',
                  placeholder: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 'small',
                  required: true,
                },
                {
                  label: 'Tax Location',
                  placeholder: 'Tax Location',
                  field: 'location_id',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 'small',
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Supporting documents',
          fields: [
            {
              field: 'msa_attachment',
              placeholder: 'MSA',
              header: 'Attachment',
              type: 'file',
              size: 6,
              required: true,
            },
            {
              field: 'sow_attachment',
              placeholder: 'Scope of Work',
              header: 'Attachment',
              type: 'file',
              size: 6,
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditSupportOrderForm = props => {
  return (
    <AddSupportOrderform
      mode="edit"
      fields={{
        customer_id: {_id: 1, name: 1},
        order_exists_type: 1,
        order_end_date: 1,
        order_date: 1,
        exchange_rate: 1,
        currency_id: {_id: 1, currency: 1},
        basecurrency_id: {_id: 1, currency: 1},
        amount: 1,
        requirement_detail: {
          skill_required: {name: 1},
          no_of_resource: 1,
          experience: {experience: 1},
          rate: 1,
          amount: 1,
          order_date: 1,
          recurring_till: 1,
          employee_per_day_working_hour: 1,
          due_on_after: 1,
          advance_due: 1,
          repeats: 1,
          base_amount: 1,
          tool: {name: 1},
          notes: 1,
          hours: 1,
        },
        no_of_leave: 1,
        tech_document: 1,
        msa_detail: {_id: 1, msa_name: 1, msa_attachment: 1},
        sow_detail: {_id: 1, sow_name: 1, sow_attachment: 1},
        organization: {_id: 1, name: 1},
        product: {_id: 1, name: 1},
        location_id: {_id: 1, name: 1},
        sales_owner: {_id: 1, name: 1},
        practice_head: {_id: 1, name: 1},
        account_owner_id: {_id: 1, name: 1},
        delivery_owner_id: {_id: 1, name: 1},
        billing_type: 1,
        ewd_allowed: 1,
        leave_model: 1,
        schedule: {_id: 1, name: 1},
        holiday_calender: {_id: 1, name: 1},
      }}
      header={'Edit Support Order'}
      {...props}
    />
  );
};
