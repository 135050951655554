import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const LeaveCredit = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/leavecredits',
    eventSourceId: 'leaveCredit',
  });
  return (
    <Form
      api={`/leavecredit/setup/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          type: 'autoComplete',
          field: 'employee_id',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Month',
          field: 'month_id',
          type: 'autoComplete',
          api: '/monthtypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Year',
          field: 'year_id',
          type: 'autoComplete',
          api: '/yeartypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          size: 6,
          required: true,
        },
        {
          label: 'Leave Credit Code',
          field: 'leave_credit_code',
          type: 'text',
          size: 6,
          required: true,
        },
        {
          label: 'Leave Type',
          field: 'leave_type_id',
          type: 'autoComplete',
          api: '/leavetypes',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddLeaveCreditForm = props => {
  return <LeaveCredit header="Add Leave Credit" {...props} />;
};

export const EditLeaveCreditForm = props => {
  return <LeaveCredit mode="edit" header={'Update Leave Credit'} {...props} />;
};

export const DeclarationSections = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/taxdeclarationsections',
    eventSourceId: 'taxsetup',
  });
  return (
    <Form
      api={`/taxdeclarationsections`}
      onSubmit={onSubmit}
      defaultValues={{_id: row?._id, name: row?.name}}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddTaxDeclarationSection = props => {
  return <DeclarationSections header="Add Declaration Section" {...props} />;
};

export const EditTaxDeclarationSection = props => {
  return (
    <DeclarationSections
      // mode="edit"
      header="Edit Declaration Section"
      {...props}
    />
  );
};

export const EsiCycle = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/esicycles',
    eventSourceId: 'esicycle',
  });
  return (
    <Form
      api={`/esi/cycle/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const TaxSlab = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/taxslabs',
    eventSourceId: 'taxsetup',
  });
  return (
    <Form
      api={`/tax-slabs/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Financial Year',
          field: 'finantial_year_id',
          type: 'autoComplete',
          api: '/financialyears',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Tax %',
          field: 'tax_percentage',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'From Amount',
          field: 'from_amount',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'To Amount',
          field: 'to_amount',
          type: 'number',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const TaxSlabNewRegime = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/taxslabnewregimes',
    eventSourceId: 'taxsetup',
  });
  return (
    <Form
      api={`/tax-slabs-new-regime/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Financial Year',
          field: 'finantial_year_id',
          type: 'autoComplete',
          api: '/financialyears',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Tax %',
          field: 'tax_percentage',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'From Amount',
          field: 'from_amount',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'To Amount',
          field: 'to_amount',
          type: 'number',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddEsiCycleForm = props => {
  return <EsiCycle header={'Add Esi Cycle'} {...props} />;
};

export const EditEsiCycleForm = props => {
  return <EsiCycle mode="edit" header={'Update Esi Cycle'} {...props} />;
};

export const AddSavingHeadForm = props => {
  return <SavingHeads header={'Add Saving head '} {...props} />;
};

export const EditSavingHeadForm = props => {
  return <SavingHeads mode="edit" header={'Edit Saving head'} {...props} />;
};

export const AddTaxSlabForm = props => {
  return <TaxSlab header={'Add Tax Slab'} {...props} />;
};

export const EditTaxSlabForm = props => {
  return <TaxSlab mode="edit" header={'Edit Tax Slab'} {...props} />;
};

export const AddTaxSlabNewRegimeForm = props => {
  return <TaxSlabNewRegime header={'Add Tax Slab'} {...props} />;
};

export const EditTaxSlabNewRegimeForm = props => {
  return <TaxSlabNewRegime mode="edit" header={'Edit Tax Slab'} {...props} />;
};

export const SavingHeads = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/savingheads',
    eventSourceId: 'taxsetup',
  });
  return (
    <Form
      api={`/saving-heads/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Section',
          field: 'section',
          type: 'autoComplete',
          api: '/taxdeclarationsections',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          size: 6,
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
          size: 6,
        },
        {
          label: 'Max limit',
          field: 'max_limit',
          type: 'number',
          size: 6,
        },
        {
          label: 'Salary base paid',
          field: 'is_salary_paid_base',
          type: 'checkbox',
          size: 6,
        },
        {
          label: 'Benefit %',
          field: 'benefit_percent',
          type: 'number',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const SalaryCycle = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/salarycycles',
    eventSourceId: 'salarycycle',
  });
  return (
    <Form
      api={`/salary/cycle/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: '/organizations',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Active From',
          field: 'active_from',
          type: 'date',
          size: 6,
          required: true,
        },
        {
          label: 'From',
          field: 'from',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'To',
          field: 'to',
          type: 'number',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddSalaryCycleForm = props => {
  return <SalaryCycle header="Add Leave Credit" {...props} />;
};

export const EditSalaryCycleForm = props => {
  return <SalaryCycle mode="edit" header={'Update Leave Credit'} {...props} />;
};
