import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {NestedTableModalRender} from '../../../app-components/renders/NestedTableModalRender';
import {Text} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';

const ReceiptComputations = fetch => ({
  'set organzation,product and currency': {
    compute: async value => {
      const {data: employeeData} = await fetch({
        uri: '/customers',
        props: {
          fields: {
            organization: {_id: 1, name: 1},
            product: {_id: 1, name: 1},
            location_id: {_id: 1, name: 1},
            // currency_id: {_id: 1, name: 1},
          },
          filter: {_id: value?.customer},
          only: true,
        },
      });
      return {
        organization: employeeData?.organization,
        product: employeeData?.product,
        location: employeeData?.location_id,
        // currency: employeeData?.organization?.currency,
      };
    },
    multi: true,
    dependencies: ['customer'],
  },
  'set exchange_rate & currency_rate': {
    compute: async value => {
      if (value?.currency?._id && value.receipts_date) {
        const {data: exchangeData} = await fetch({
          uri: '/daywisecurrencies',
          props: {
            filter: {
              currency: value?.currency?._id,
              from_date: {$lte: value.receipts_date},
              $or: [{to_date: null}, {to_date: {$gte: value.receipts_date}}],
            },
            fields: {_id: 1, exchange_rate: 1},
            only: true,
          },
        });
        if (exchangeData && exchangeData.exchange_rate) {
          return {currency_rate: exchangeData?.exchange_rate};
        }
      }
    },
    multi: true,
    dependencies: ['currency', 'receipts_date'],
  },
  'set customer details': {
    compute: async value => {
      const {customer, receipts_date} = value;
      if (customer?._id && receipts_date) {
        const {data: customerData} = await fetch({
          uri: `/customer/taxdetails/${customer?._id}`,
          props: {
            date: value.receipts_date,
          },
        });
        const {
          product,
          receivable_account,
          advanced_account,
          foreigner,
          owner,
          location_id,
          base_currency,
          customer_tds_rate,
          customer_vat_rate,
          customer_gst_rate,
        } = customerData || {};
        const response = {};
        if (base_currency) {
          response.currency = base_currency;
        }
        if (owner) {
          response.account_owner_id = owner;
        }
        if (receivable_account) {
          response.customer_account_id = receivable_account;
        }
        if (advanced_account) {
          response.customer_advance_account_id = advanced_account;
        }
        if (location_id) {
          response.location = location_id;
        }
        if (product) {
          response.product = product;
        }
        if (customer_tds_rate && customer_tds_rate.length > 0) {
          response.tds_account_id = customer_tds_rate[0].account;
          response.customer_tds_rate = customer_tds_rate[0];
        }
        return {
          ...response,
          product,
        };
      }
    },
    multi: true,
    dependencies: ['customer', 'receipts_date'],
  },
  'set pending invoices': {
    compute: async values => {
      const {customer, receipt_type, currency_rate, customer_tds_rate} =
        values || {};
      if (customer && receipt_type === 'r') {
        const {data: pendingInvoices} = await fetch({
          uri: '/revenueInvoices/all',
          props: {
            filter: {
              customer: customer._id,
              invoice_type: {$in: ['i', 'a']},
              is_fluctuated: null,
              pending_receipt_amount: {$gt: 0},
            },
          },
        });
        if (pendingInvoices) {
          const receipt_details = pendingInvoices.map(invoices => {
            const {base_pending_receipt_amount, pending_receipt_amount} =
              invoices;
            const rate = Number(
              base_pending_receipt_amount / pending_receipt_amount,
            ).toFixed(5);
            const base_receipt_amount = pending_receipt_amount * currency_rate;
            let tds_amount = 0;
            if (customer_tds_rate && customer_tds_rate.rate_percent) {
              tds_amount = Number(
                (
                  pending_receipt_amount *
                  (customer_tds_rate.rate_percent / 100)
                ).toFixed(2),
              );
            }
            return {
              invoice_amount: pending_receipt_amount,
              base_invoice_amount: base_pending_receipt_amount,
              invoice_id: {
                _id: invoices._id,
                invoice_number: invoices.invoice_number,
              },
              invoice_rate: Number(rate),
              base_receipt_amount,
              fluctuated_amount:
                base_receipt_amount - base_pending_receipt_amount,
              tds_amount,
            };
          });
          return {receipt_details};
        } else {
          return {receipt_details: []};
        }
      } else {
        return {receipt_details: []};
      }
    },
    multi: true,
    dependencies: ['customer', 'receipt_type'],
  },
  'amount aggregate in receipt': {
    compute: async values => {
      let invoice_amount = 0;
      let fluctuated_amount = 0;
      let tds_amount = 0;
      if (
        values &&
        values.receipt_type &&
        values.receipt_type.toString() === 'o'
      ) {
        invoice_amount = values.other_receipt_amount;
      } else {
        if (values.receipt_details && values.receipt_details.length > 0) {
          values.receipt_details.forEach(size => {
            if (size.invoice_amount) {
              invoice_amount += size.invoice_amount;
            }
            if (size.fluctuated_amount) {
              fluctuated_amount += size.fluctuated_amount;
            }
            if (size.tds_amount) {
              tds_amount += size.tds_amount;
            }
          });
        }
      }
      return {
        amount: invoice_amount,
        tds_amount: tds_amount,
        total_fluctuated_amount: fluctuated_amount,
      };
    },
    multi: true,
    dependencies: ['receipt_details', 'other_receipt_amount'],
  },
  'set is fluctuated in receipt': {
    multi: true,
    dependencies: ['total_fluctuated_amount'],
    compute: values => {
      let isFluctuated = false;
      if (values?.total_fluctuated_amount != 0) {
        isFluctuated = true;
      }
      return {is_fluctuated: isFluctuated};
    },
  },
  'set deduction total in receipt': {
    compute: async values => {
      let baseTotalAmount = 0;
      let totalAmount = 0;
      if (values.deduction && values.deduction.length > 0) {
        values.deduction.forEach(size => {
          if (size.base_amount) {
            baseTotalAmount += size.base_amount;
          }
          if (size.amount) {
            totalAmount += size.amount;
          }
        });
      }
      return {
        base_deduction_amount: baseTotalAmount,
        deduction_amount: totalAmount,
      };
    },
    multi: true,
    dependencies: ['deduction', 'amount'],
  },
  'base_amount aggregate in receipt': {
    compute: async values => {
      let base_amount = 0;
      if (
        values.receipt_type &&
        values.receipt_type === 'r' &&
        values.receipt_details
      ) {
        if (values.receipt_details && values.receipt_details.length > 0) {
          values.receipt_details.forEach(size => {
            if (size.base_receipt_amount) {
              base_amount += size.base_receipt_amount;
            }
          });
        }
      } else {
        base_amount = values.amount * values.currency_rate;
      }
      return {
        base_amount: base_amount,
      };
    },
    multi: true,
    dependencies: ['receipt_details', 'amount', 'currency_rate'],
  },
  'base_advance_amount in receipt': {
    compute: async values => {
      const advanceAmount = values.advance_amount * values.currency_rate;
      return {base_advance_amount: advanceAmount};
    },
    multi: true,
    dependencies: ['advance_amount', 'currency_rate'],
  },
  'base_other_amount in receipt': {
    compute: async values => {
      const advanceAmount = values.other_amount * values.currency_rate;
      return {base_other_amount: advanceAmount};
    },
    multi: true,
    dependencies: ['other_amount', 'currency_rate'],
  },

  'set credit_notes total in invoice': {
    compute: values => {
      let totalAmount = 0;
      let basetotalAmount = 0;
      if (values.credit_notes && values.credit_notes.length > 0) {
        values.credit_notes.forEach(size => {
          if (size.invoice_amount) {
            totalAmount += size.invoice_amount;
            basetotalAmount += size.base_invoice_amount;
          }
        });
      }
      return {
        credit_note_base_amount: basetotalAmount,
        credit_note_amount: totalAmount,
      };
    },
    multi: true,
    dependencies: ['credit_notes'],
  },
  'set advance_amount total in receipt': {
    compute: async values => {
      let totalAmount = 0;
      let baseAdvanceAmount = 0;
      if (values.advance_details && values.advance_details.length > 0) {
        values.advance_details.forEach(size => {
          if (size.adjusted_amount) {
            totalAmount += size.adjusted_amount;
          }
          if (size.base_adjusted_amount) {
            baseAdvanceAmount += size.base_adjusted_amount;
          }
        });
      }
      return {
        advance_amount: totalAmount,
        base_advance_amount: baseAdvanceAmount,
      };
    },
    multi: true,
    dependencies: ['advance_details'],
  },
  'fund_received in receipt': {
    compute: async values => {
      let total = 0;
      if (values.interest_amount) {
        total = values.interest_amount;
      }
      if (values.amount) {
        total += values.amount;
      }
      if (values.tds_amount) {
        total -= values.tds_amount;
      }
      if (values.other_amount) {
        total -= values.other_amount;
      }
      if (values.advance_amount) {
        total -= values.advance_amount;
      }
      if (values.deduction_amount) {
        total -= values.deduction_amount;
      }
      if (values.credit_note_amount) {
        total -= values.credit_note_amount;
      }
      return {fund_received: total};
    },
    multi: true,
    dependencies: [
      'interest_amount',
      'amount',
      'tds_amount',
      'other_amount',
      'advance_amount',
      'deduction_amount',
      'credit_note_amount',
    ],
  },
  'base_fund_received in receipt': {
    compute: async values => {
      let total = 0;
      if (values.interest_amount) {
        total = values.interest_amount * values.currency_rate;
      }
      if (values.base_amount) {
        total += values.base_amount;
      }
      if (values.tds_amount) {
        total -= values.tds_amount * values.currency_rate;
      }
      if (values.base_other_amount) {
        total -= values.base_other_amount;
      }
      if (values.base_advance_amount) {
        total -= values.base_advance_amount;
      }
      if (values.base_deduction_amount) {
        total -= values.base_deduction_amount;
      }
      if (values.credit_note_base_amount) {
        total -= values.credit_note_base_amount;
      }
      return {base_fund_received: total};
    },
    multi: true,
    dependencies: [
      'currency_rate',
      'interest_amount',
      'base_amount',
      'tds_amount',
      'base_advance_amount',
      'base_other_amount',
      'base_deduction_amount',
      'credit_note_base_amount',
    ],
  },
  'credit_note_base_amount in receipt': {
    compute: async values => {
      const credit_note_base_amount =
        values.credit_note_amount * values.currency_rate;
      return {credit_note_base_amount: credit_note_base_amount};
    },
    multi: true,
    dependencies: ['credit_note_amount', 'currency_rate'],
  },
  'set credit_notes total in receipt': {
    compute: async values => {
      let totalAmount = 0;
      if (values.credit_notes && values.credit_notes.length > 0) {
        values.credit_notes.forEach(size => {
          if (size.invoice_amount) {
            totalAmount += size.invoice_amount;
          }
        });
      }
      return {
        credit_note_amount: totalAmount,
      };
    },
    multi: true,
    dependencies: ['credit_notes'],
  },

  'receipt_credit_note_amount aggregate in receipt': {
    compute: async values => {
      let base_amount = 0;
      if (
        values.receipt_type &&
        values.receipt_type === 'r' &&
        values.receipt_details
      ) {
        if (values.receipt_details && values.receipt_details.length > 0) {
          values.receipt_details.forEach(size => {
            if (size.credit_note_amount) {
              base_amount += size.credit_note_amount;
            }
          });
        }
        return {
          receipt_credit_note_amount: base_amount,
        };
      }
    },
    multi: true,
    dependencies: ['receipt_details'],
  },
  'set receipt_details': {
    compute: async values => {
      let credit_note_amount = values.credit_note_amount || 0;
      if (
        values.receipt_details &&
        values.receipt_details.length > 0 &&
        credit_note_amount > 0
      ) {
        let details = values.receipt_details.map(invoice => {
          invoice = {...invoice};
          if (invoice.invoice_amount && credit_note_amount) {
            if (invoice.invoice_amount >= credit_note_amount) {
              invoice.credit_note_amount = credit_note_amount;
              credit_note_amount = 0;
            } else {
              invoice.credit_note_amount = invoice.invoice_amount;
              credit_note_amount =
                credit_note_amount - invoice.credit_note_amount;
            }
          }
          return invoice;
        });
        if (details && details.length > 0) {
          return {receipt_details: details};
        }
      }
      if (credit_note_amount === 0) {
        let details =
          values.receipt_details &&
          values.receipt_details.map(invoice => {
            invoice = {...invoice};
            if (invoice.invoice_amount) {
              invoice.credit_note_amount = 0;
            }
            return invoice;
          });
        if (details && details.length > 0) {
          return {receipt_details: details};
        }
      }
    },
    multi: true,
    dependencies: ['credit_note_amount', 'currency_rate'],
  },
  credit_notes: {
    compute: async values => {
      if (
        values.customer &&
        values.customer._id &&
        values.receipt_type &&
        values.receipt_type === 'r'
      ) {
        let {data: invoicesResult} = await fetch({
          uri: '/revenueInvoices/all',
          props: {
            filter: {
              customer: {_id: values.customer._id},
              is_fluctuated: null,
              invoice_type: 'c',
              pending_receipt_amount: {$lte: -1},
            },
          },
        });
        if (invoicesResult) {
          if (invoicesResult && invoicesResult.length > 0) {
            invoicesResult = invoicesResult.map(row => {
              row.invoice = {
                _id: row._id,
                invoice_number: row.invoice_number,
              };
              row.invoice_amount = -1 * row.pending_receipt_amount;
              row.account = row.clinet_account_id;
              delete row.clinet_account_id;
              delete row.pending_receipt_amount;
              delete row.invoice_number;
              delete row._id;
              return row;
            });
          }
          return {credit_notes: invoicesResult};
        }
      } else {
        return {credit_notes: []};
      }
    },
    multi: true,
    dependencies: ['customer', 'receipt_type'],
  },
  advance_details: {
    compute: async values => {
      const {customer, receipt_type} = values || {};
      if (customer && customer._id && receipt_type === 'r') {
        const {data: advanceData} = await fetch({
          uri: '/receipts',
          props: {
            filter: {
              customer: customer._id,
              receipt_type: 'a',
              pending_advance_amount: {$gt: 0},
            },
            fields: {
              _id: 1,
              receipt_number: 1,
              pending_advance_amount: 1,
              base_pending_advance_amount: 1,
              currency_rate: 1,
            },
          },
        });
        if (advanceData && advanceData.length > 0) {
          const advanceDetails = advanceData.map(invoice => {
            const advanceLineItem = {
              _id: invoice._id,
              advance_number: {
                _id: invoice._id,
                receipt_number: invoice.receipt_number,
              },
              adjusted_amount: invoice.pending_advance_amount,
              base_adjusted_amount: invoice.base_pending_advance_amount,
              conversion_rate: invoice.currency_rate,
            };
            return advanceLineItem;
          });
          if (advanceDetails && advanceDetails.length) {
            return {advance_details: advanceDetails};
          }
        }
      }
      if (customer && (receipt_type === 'a' || receipt_type === 'o')) {
        return {advance_details: []};
      }
    },
    multi: true,
    dependencies: ['customer', 'invoice_type'],
  },
  nestedComputations: {
    advance_details: {
      'set adjusted_amount & conversion_rate': {
        compute: async values => {
          if (values.advance_number && values.advance_number._id) {
            let {data: result} = await fetch({
              uri: '/receipts',
              props: {
                number:
                  values && values.advance_number && values.advance_number._id,
              },
            });
            if (result.pending_advance_amount) {
              return {
                adjusted_amount: result.pending_advance_amount,
                conversion_rate: result.currency_rate,
              };
            }
          }
        },
        multi: true,
        dependencies: ['advance_number'],
      },
      base_adjusted_amount: {
        compute: async values => {
          if (values.adjusted_amount && values.conversion_rate) {
            return {
              base_adjusted_amount:
                values.adjusted_amount * values.conversion_rate,
            };
          }
        },
        multi: true,
        dependencies: ['adjusted_amount', 'conversion_rate'],
      },
    },
    deduction: {
      'base_amount in deduction': {
        compute: (values, {_parentValues}) => {
          if (_parentValues && _parentValues.currency_rate && values.amount) {
            return {base_amount: _parentValues.currency_rate * values.amount};
          }
        },
        multi: true,
        dependencies: ['amount', '_parentValues.currency_rate'],
      },
    },
    credit_notes: {
      'base_invoice_amount in credit note': {
        compute: async values => {
          if (values.invoice_amount && values.exchange_rate) {
            return {
              base_invoice_amount: values.invoice_amount * values.exchange_rate,
            };
          }
        },
        multi: true,
        dependencies: ['invoice_amount', 'exchange_rate'],
      },
    },
    receipt_details: {
      'base_invoice_amount in receipt details': {
        compute: async values => {
          if (values.invoice_amount && values.invoice_rate) {
            return {
              base_invoice_amount: values.invoice_amount * values.invoice_rate,
            };
          }
        },
        multi: true,
        dependencies: ['invoice_amount', 'invoice_rate'],
      },
      'base_receipt_amount in receipt Details': {
        compute: async (values, {_parentValues}) => {
          if (values.invoice_amount && _parentValues.currency_rate) {
            return {
              base_receipt_amount:
                values.invoice_amount * _parentValues.currency_rate,
            };
          }
        },
        multi: true,
        dependencies: [
          'invoice_rate',
          'invoice_amount',
          '_parentValues.currency_rate',
        ],
      },
      fluctuated_amount: {
        compute: async (values, {_parentValues}) => {
          if (
            values.base_receipt_amount &&
            values.base_invoice_amount &&
            values.invoice_amount
          ) {
            let invoiceAmount = values.invoice_amount;
            if (values.credit_note_amount) {
              invoiceAmount = invoiceAmount - values.credit_note_amount;
            }
            let baseInvoiceAmount = invoiceAmount * (values.invoice_rate || 1);
            let baseRecAmount =
              invoiceAmount * (_parentValues.currency_rate || 1);
            return {fluctuated_amount: baseRecAmount - baseInvoiceAmount};
          }
        },
        multi: true,
        dependencies: [
          'invoice_rate',
          'base_receipt_amount',
          'base_invoice_amount',
          'invoice_amount',
          '_parentValues.currency_rate',
          'credit_note_amount',
        ],
      },

      product: {
        compute: async (values, {_parentValues}) => {
          if (values.product && _parentValues && _parentValues.currency_rate) {
            return values.product;
          }
        },
        multi: true,
        dependencies: ['_parentValues.product'],
      },
      tds_amount: {
        compute: (values, {_parentValues}) => {
          let amount = values.invoice_amount || 0;
          let info = _parentValues.customer_tax_details || {};
          if (
            info.CustomerTdsRateDated &&
            info.CustomerTdsRateDated.length &&
            info.CustomerTdsRateDated[0].account &&
            info.CustomerTdsRateDated[0].rate_percent &&
            info.CustomerTdsRateDated[0].rate_percent > 0
          ) {
            let tds =
              (Number(info.CustomerTdsRateDated[0].rate_percent) / 100) *
              amount;
            return tds;
          }
        },
        multi: true,
        dependencies: ['_parentValues.customer_tax_details', 'invoice_amount'],
      },
    },
  },
});

const AdvanceFieldNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Advance Details'}}
      field={'advance_details'}
      columns={[
        {
          field: 'advance_number.receipt_number',
          label: 'Invoice',
          type: 'text',
        },
        {
          field: 'adjusted_amount',
          header: 'Adjusted Amount',
          toFixed: 2,
          type: 'number',
          width: 200,
        },
        {
          field: 'conversion_rate',
          header: 'Conversion Rate',
          type: 'number',
          width: 200,
        },
      ]}
      fields={[
        {
          field: 'advance_number',
          label: 'Invoice',
          suggestionField: 'receipt_number',
          valueField: 'receipt_number',
          api: '/receipts',
          filter: {
            pending_advance_amount: {$gt: 0},
          },
          type: 'autoComplete',
        },
        {
          field: 'adjusted_amount',
          label: 'Adjusted Amount',
          toFixed: 2,
          type: 'number',
        },
        {
          field: 'conversion_rate',
          label: 'Conversion Rate',
          type: 'number',
        },
      ]}
    />
  );
};

const DeductionNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Deduction'}}
      field={'deduction'}
      columns={[
        {
          field: 'account.name',
          header: 'Account',
          type: 'text',
        },
        {
          field: 'amount',
          header: 'Amount',
          toFixed: 2,
          type: 'number',
          width: 200,
        },
        {
          field: 'base_amount',
          header: 'Base Amount',
          type: 'number',
          toFixed: 2,
          width: 200,
        },
      ]}
      fields={[
        {
          field: 'account',
          label: 'Account',
          suggestionField: 'name',
          valueField: 'name',
          api: '/accounts',
          type: 'autoComplete',
        },
        {
          field: 'amount',
          label: 'Amount',
          type: 'number',
          toFixed: 2,
        },
        {
          field: 'base_amount',
          label: 'Base Amount',
          toFixed: 2,
          type: 'number',
        },
      ]}
    />
  );
};

const CreditNoteNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Credit Note'}}
      field={'credit_notes'}
      columns={[
        {
          field: 'account.name',
          header: 'Account',
          type: 'text',
        },
        {
          field: 'invoice.invoice_number',
          header: 'Invoice',
          type: 'text',
        },
        {
          field: 'invoice_amount',
          header: 'Invoice Amount',
          type: 'number',
          toFixed: 2,
        },
        {
          type: 'number',
          placeholder: 'Exchange Rate',
          field: 'exchange_rate',
          header: 'Exchange Rate',
        },
      ]}
      fields={[
        {
          field: 'account',
          label: 'Account',
          suggestionField: 'name',
          valueField: 'name',
          api: '/accounts',
          type: 'autoComplete',
        },
        {
          field: 'invoice',
          label: 'Invoice',
          type: 'autoComplete',
          api: '/revenueInvoiceSuggestions',
          filter: {
            pending_receipt_amount: {$lte: -1},
          },
          suggestionField: 'invoice_number',
          valueField: 'invoice_number',
        },
        {
          field: 'invoice_amount',
          label: 'Invoice Amount',
          type: 'number',
          toFixed: 2,
        },
        {
          type: 'number',
          placeholder: 'Exchange Rate',
          field: 'exchange_rate',
          label: 'Exchange Rate',
        },
      ]}
    />
  );
};

const ReceiptAmountSummary = props => {
  const {values} = props;
  const {INFORMATION_HIGH} = useTheme('colors');

  const data = [
    {
      key: 'invoice_amount',
      name: 'Invoice amount',
      amount: values?.amount || 0,
    },
    {
      key: 'total_tax_amount',
      name: 'Total tax amount',
      amount: values?.tds_amount || 0,
    },
    {
      key: 'advance_details',
      name: 'Advances',
      amount: values?.advance_amount || 0,
    },
    {
      key: 'deduction',
      name: 'Deductions',
      amount: values?.deduction_amount || 0,
    },
    {
      key: 'CreditNote',
      name: 'Credit Note',
      amount: values?.credit_note_amount || 0,
    },
    {
      key: 'total',
      name: 'Total',
      amount: values?.fund_received || 0,
    },
  ];
  return (
    <Table
      data={data}
      columns={[
        {
          render: ({row, styles}) => {
            return <Text style={styles?.groupRowText}>{row?.name}</Text>;
          },
        },
        {
          render: ({row}) => {
            let renderNestedTable = null;
            if (row?.key === 'advance_details') {
              renderNestedTable = <AdvanceFieldNestedTable {...props} />;
            } else if (row?.key === 'deduction') {
              renderNestedTable = <DeductionNestedTable {...props} />;
            } else if (row?.key === 'CreditNote') {
              renderNestedTable = <CreditNoteNestedTable {...props} />;
            }
            return renderNestedTable;
          },
        },
        {
          align: 'right',
          field: 'amount',
          render: ({row, ...rest}) => {
            const {styles} = rest;
            let amount = (
              <Text style={styles?.rowText}>{`${row?.amount} INR`}</Text>
            );
            if (row?.key === 'total') {
              amount = (
                <Text
                  style={{
                    ...styles?.rowText,
                    color: INFORMATION_HIGH,
                  }}>{`${row?.amount} INR`}</Text>
              );
            }
            return amount;
          },
        },
      ]}
    />
  );
};

export const ReceiptForm = props => {
  const {
    route: {params},
    header = 'Add Receipt',
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/receipts',
    eventSourceId: 'receipt',
  });
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();
  return (
    <Form
      header={header}
      defaultValues={{
        receipt_type: 'r',
        organization: employeeData?.organization,
        product: employeeData?.product,
        receipts_date: getZeroTimeDate(new Date()),
        status: 'New',
        currency_rate: 1,
        base_currency: employeeData?.organization?.currency,
        currency: employeeData?.organization?.currency,
        account_owner_id: {_id: employeeData._id, name: employeeData.name},
        owner: {_id: employeeData._id, name: employeeData.name},
      }}
      api={`/receipt/create/${row?._id}`}
      computations={{...ReceiptComputations(fetch)}}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Receipt Type',
          type: 'radioGroup',
          field: 'receipt_type',
          valueField: 'label',
          keyField: 'value',
          options: [
            {label: 'Revenue', value: 'r'},
            {label: 'Advance', value: 'a'},
            // {label: 'FD', value: 'o'},
          ],
          required: true,
        },
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              label: 'Customer',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              type: 'date',
              field: 'receipts_date',
              label: 'Date',
              placeholder: 'Date',
              size: 6,
              required: true,
            },
            {
              placeholder: 'Account',
              field: 'fund_id',
              type: 'autoComplete',
              api: `/banks`,
              label: 'Account',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
            {
              placeholder: 'Payment Mode',
              type: 'autoComplete',
              field: 'payment_mode',
              label: 'Payment Mode',
              options: ['Cash', 'Cheque', 'RTGS/NEFT', 'Transfer Wise'],
              visible: ({values}) =>
                values?.receipt_type === 'r' || values?.receipt_type === 'a',
              size: 4,
              required: true,
            },
            {
              field: 'transaction_id',
              placeholder: 'Transaction Id',
              label: 'Transaction Id',
              type: 'text',
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'currency',
              label: 'Currency',
              placeholder: 'Currency',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 6,
            },
            {
              type: 'number',
              field: 'currency_rate',
              label: 'Exchange Rate',
              placeholder: 'Exchange Rate',
              visible: ({values}) =>
                values?.receipt_type === 'r' || values?.receipt_type === 'a',
              size: 6,
            },
            {
              type: 'number',
              field: 'amount',
              label: 'Amount',
              placeholder: 'Amount',
              size: 4,
              toFixed: 2,
              visible: ({values}) =>
                values?.receipt_type === 'r' || values?.receipt_type === 'o',
              readOnly: true,
            },
            {
              type: 'number',
              field: 'amount',
              label: 'Amount',
              placeholder: 'Amount',
              size: 4,
              toFixed: 2,
              visible: ({values}) => values?.receipt_type === 'a',
            },
            {
              type: 'number',
              field: 'base_fund_received',
              label: 'Fund Received in INR',
              placeholder: 'Amount in INR',
              visible: ({values}) =>
                values?.receipt_type === 'r' || values?.receipt_type === 'a',
              size: 4,
              toFixed: 2,
              readOnly: true,
            },
            {
              type: 'number',
              field: 'fund_received',
              label: 'Fund Received',
              placeholder: 'Fund Received',
              visible: ({values}) =>
                values?.receipt_type === 'r' || values?.receipt_type === 'a',
              readOnly: true,
              toFixed: 2,
              size: 4,
            },
          ],
        },
        // {
        //   field: 'fund_received',
        //   placeholder: 'Fund Recieved',
        //   label: 'Fund Recieved',
        //   type: 'text',
        //   visible: ({values}) =>
        //     values?.receipt_type === 'r' || values?.receipt_type === 'o',
        //   readOnly: true,
        //   size: 4,
        // },
        // {
        //   field: 'customer_advance_account_id',
        //   placeholder: 'Customer Account',
        //   label: 'Customer Account',
        //   type: 'autoComplete',
        //   api: `/accounts`,
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   size: 6,
        //   visible: ({values}) => values?.receipt_type === 'a',
        // },
        // {
        //   field: 'bank_statement',
        //   placeholder: 'Bank Statement',
        //   label: 'Bank Statement',
        //   type: 'file',
        //   size: 6,
        // },
        {
          label: 'Invoice Details',
          visible: ({values}) => values?.receipt_type === 'r',
          fields: [
            {
              field: 'receipt_details',
              nested: true,
              addInBottom: true,
              render: props => (
                <NestedTable
                  {...props}
                  selection={{
                    deleteAction: true,
                  }}
                  columns={[
                    {
                      field: 'invoice_id.invoice_number',
                      type: 'text',
                      header: 'Invoice',
                    },
                    {
                      field: 'invoice_amount',
                      header: 'Invoice Amount',
                      type: 'number',
                      toFixed: 2,
                      width: 150,
                    },
                    {
                      header: 'Credit Note',
                      field: 'credit_note_amount',
                      type: 'number',
                      toFixed: 2,
                      width: 150,
                    },
                    {
                      field: 'tds_amount',
                      header: 'TDS',
                      type: 'number',
                      toFixed: 2,
                      width: 150,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Invoice',
                      placeholder: 'Invoice',
                      field: 'invoice_id',
                      type: 'autoComplete',
                      api: `/revenueInvoiceSuggestions`,
                      suggestionField: 'invoice_number',
                      valueField: 'invoice_number',
                    },
                    {
                      field: 'invoice_amount',
                      placeholder: 'Invoice Amount',
                      label: 'Invoice Amount',
                      toFixed: 2,
                      type: 'number',
                    },
                    {
                      field: 'credit_note_amount',
                      placeholder: 'Credit Note',
                      label: 'Credit Note Amount',
                      toFixed: 2,
                      type: 'number',
                    },
                    {
                      field: 'tds_amount',
                      placeholder: 'TDS Amount',
                      label: 'TDS Amount',
                      type: 'number',
                      toFixed: 2,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          visible: ({values}) => values?.receipt_type === 'r',
          render: (_, props) => {
            return <ReceiptAmountSummary {...props} />;
          },
        },
        {
          groups: [
            {
              label: 'Accounting',
              fields: [
                {
                  placeholder: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  label: 'Organization',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  placeholder: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  label: 'Product',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  placeholder: 'Location',
                  field: 'location',
                  type: 'autoComplete',
                  api: `/offices`,
                  label: 'Location',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  placeholder: 'Department',
                  field: 'department',
                  type: 'autoComplete',
                  api: `/departments`,
                  label: 'Department',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                // {
                //   placeholder: 'Project',
                //   field: 'project',
                //   type: 'autoComplete',
                //   api: `/projects`,
                //   label: 'Project',
                //   suggestionField: 'project',
                //   valueField: 'project',
                //   size: 4,
                // },
                // {
                //   placeholder: 'Impact To',
                //   type: 'autoComplete',
                //   field: 'impact_to',
                //   label: 'Impact To',
                //   options: [
                //     'Employee',
                //     'Office Employee',
                //     'Organization Employee',
                //     'Common Department',
                //     'Exclusive Department',
                //   ],
                //   size: 4,
                // },
              ],
            },
          ],
        },
        // {
        //   label: 'FD Details',
        //   visible: ({values}) => values?.receipt_type === 'o',
        //   fields: [
        //     {
        //       placeholder: 'FD Accounts',
        //       field: 'customer_advance_account_id',
        //       type: 'autoComplete',
        //       api: `/accounts`,
        //       label: 'FD Accounts',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       size: 6,
        //     },
        //     {
        //       field: 'other_receipt_amount',
        //       placeholder: 'FD Amount',
        //       label: 'FD Amount',
        //       type: 'number',
        //       size: 6,
        //     },
        //     {
        //       placeholder: 'TDS Accounts',
        //       field: 'tds_account_id',
        //       type: 'autoComplete',
        //       api: `/accounts`,
        //       label: 'TDS Accounts',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       size: 6,
        //     },
        //     {
        //       field: 'tds_amount',
        //       placeholder: 'TDS Amount',
        //       label: 'TDS Amount',
        //       type: 'number',
        //       size: 6,
        //     },
        //     {
        //       placeholder: 'Interest Accounts',
        //       field: 'interest_account_id',
        //       type: 'autoComplete',
        //       api: `/accounts`,
        //       label: 'Interest Accounts',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       size: 6,
        //     },
        //     {
        //       field: 'interest_amount',
        //       placeholder: 'Interest Amount',
        //       label: 'Interest Amount',
        //       type: 'number',
        //       size: 6,
        //     },
        //   ],
        // },
        //   {
        //     label: 'Advance Details',
        //     visible: ({values}) => values?.receipt_type === 'r',
        //     fields: [
        //       {
        //         field: 'advance_details',
        //         nested: true,
        //         addInBottom: true,
        //         render: props => (
        //           <NestedTable
        //             {...props}
        //             columns={[
        //               {
        //                 field: 'advance_number.receipt_number',
        //                 label: 'Invoice',
        //                 type: 'text',
        //               },
        //               {
        //                 field: 'adjusted_amount',
        //                 header: 'Adjusted Amount',
        //                 type: 'number',
        //                 width: 200,
        //               },
        //               {
        //                 field: 'conversion_rate',
        //                 header: 'Conversion Rate',
        //                 type: 'number',
        //                 width: 200,
        //               },
        //             ]}
        //             fields={[
        //               {
        //                 field: 'advance_number',
        //                 label: 'Invoice',
        //                 suggestionField: 'receipt_number',
        //                 valueField: 'receipt_number',
        //                 api: '/receipts',
        //                 filter: {
        //                   pending_advance_amount: {$gt: 0},
        //                 },
        //                 type: 'autoComplete',
        //               },
        //               {
        //                 field: 'adjusted_amount',
        //                 label: 'Adjusted Amount',
        //                 type: 'number',
        //               },
        //               {
        //                 field: 'conversion_rate',
        //                 label: 'Conversion Rate',
        //                 type: 'number',
        //               },
        //             ]}
        //           />
        //         ),
        //       },
        //     ],
        //   },
        //   {
        //     label: 'Deduction Details',
        //     visible: ({values}) => values?.receipt_type === 'r',
        //     fields: [
        //       {
        //         field: 'deduction',
        //         nested: true,
        //         addInBottom: true,
        //         render: props => (
        //           <NestedTable
        //             {...props}
        //             columns={[
        //               {
        //                 field: 'account.name',
        //                 header: 'Account',
        //                 type: 'text',
        //               },
        //               {
        //                 field: 'amount',
        //                 header: 'Amount',
        //                 type: 'number',
        //                 width: 200,
        //               },
        //               {
        //                 field: 'base_amount',
        //                 header: 'Base Amount',
        //                 type: 'number',
        //                 width: 200,
        //               },
        //             ]}
        //             fields={[
        //               {
        //                 field: 'account',
        //                 label: 'Account',
        //                 suggestionField: 'name',
        //                 valueField: 'name',
        //                 api: '/accounts',
        //                 type: 'autoComplete',
        //               },
        //               {
        //                 field: 'amount',
        //                 label: 'Amount',
        //                 type: 'number',
        //               },
        //               {
        //                 field: 'base_amount',
        //                 label: 'Base Amount',
        //                 type: 'number',
        //               },
        //             ]}
        //           />
        //         ),
        //       },
        //     ],
        //   },
        //   {
        //     label: 'Credit Note',
        //     visible: ({values}) => values?.receipt_type === 'r',
        //     fields: [
        //       {
        //         field: 'credit_notes',
        //         nested: true,
        //         addInBottom: true,
        //         render: props => (
        //           <NestedTable
        //             {...props}
        //             columns={[
        //               {
        //                 field: 'account.name',
        //                 header: 'Account',
        //                 type: 'text',
        //               },
        //               {
        //                 field: 'invoice.invoice_number',
        //                 header: 'Invoice',
        //                 type: 'text',
        //               },
        //               {
        //                 field: 'invoice_amount',
        //                 header: 'Invoice Amount',
        //                 type: 'number',
        //               },
        //               {
        //                 type: 'number',
        //                 placeholder: 'Exchange Rate',
        //                 field: 'exchange_rate',
        //                 header: 'Exchange Rate',
        //               },
        //             ]}
        //             fields={[
        //               {
        //                 field: 'account',
        //                 label: 'Account',
        //                 suggestionField: 'name',
        //                 valueField: 'name',
        //                 api: '/accounts',
        //                 type: 'autoComplete',
        //               },
        //               {
        //                 field: 'invoice',
        //                 label: 'Invoice',
        //                 type: 'autoComplete',
        //                 api: '/revenueinvoiceSuggestions',
        //                 filter: {
        //                   pending_receipt_amount: {$lte: -1},
        //                 },
        //                 suggestionField: 'invoice_number',
        //                 valueField: 'invoice_number',
        //               },
        //               {
        //                 field: 'invoice_amount',
        //                 label: 'Invoice Amount',
        //                 type: 'number',
        //               },
        //               {
        //                 type: 'number',
        //                 placeholder: 'Exchange Rate',
        //                 field: 'exchange_rate',
        //                 label: 'Exchange Rate',
        //               },
        //             ]}
        //           />
        //         ),
        //       },
        //     ],
        //   },
      ]}
      {...props}
    />
  );
};

export const EditRevenueReceipt = props => {
  return (
    <ReceiptForm
      mode="edit"
      header={{
        title: 'Update Receipt',
        secondaryTitle: props?.route?.params?.row?.receipt_number || '',
      }}
      {...props}
    />
  );
};

export const RevenueReceiptDetail = props => {
  return (
    <ReceiptForm
      mode="edit"
      readOnly={true}
      header={{
        title: 'Detail Receipt',
        secondaryTitle: props?.route?.params?.row?.receipt_number || '',
      }}
      {...props}
    />
  );
};
