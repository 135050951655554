export const HeaderStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    textStyle: {...fonts.HEADING1, color: colors.NEUTRAL_HIGH},

    containerStyle: {padding: 12, alignSelf: 'flex-start'},
  };
};

export const CheckoutBranchActionStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    branchIcon: icons.BranchBlue,
    checkoutContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    checkoutTextStyle: {
      marginLeft: 8,
      ...fonts.ACTION,
      color: colors.INFORMATION_HIGH,
    },
  };
};

export const BranchStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    copyIcon: icons.Copy,
    headerContainerStyle: {marginBottom: 8},
    headerTextStyle: {...fonts.CAPTION_LARGE, color: colors.NEUTRAL_MEDIUM},
    branchText: {
      padding: 8,
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    prCountText: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    repositoryText: {...fonts.BODY2, color: colors.NEUTRAL_MEDIUM},
    buttonContainer: {
      marginLeft: 12,
      padding: 6,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.INFORMATION_LOW,
    },
  };
};

export const graphHeaderStyle = ({theme: {colors, fonts, icons, shadows}}) => {
  return {
    container: {
      paddingTop: 8,
      paddingRight: 12,
      paddingBottom: 8,
      paddingLeft: 12,
      flex: 1,
      overflow: 'hidden',
      backgroundColor: colors.SURFACE1,
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
    },
    title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
    secondaryTitle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
