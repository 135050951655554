import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {PaymentTabs} from './VendorPaymentTabs';
import {VendorInvoiceTab} from './VendorInvoiceTab';
import {ExpenseApproverInvoiceTab} from '../../Expense/view/ExpenseApproverTab';
import VendorTabView from '../../department/views/VendorTabView';
import {BudgetRequestAccountTab} from '../../Expense/view/BudgetRequestTab';
import {QuotationApproverFinanceTab} from '../../Expense/view/QuotationApproverTab';
import {PurchaseOrderTab} from '../../Expense/view/PurchaseOrderTab';
import {RegisterITAssetsTab} from '../../Expense/view/RegisterITAssetTable';
import {ExpenseApproverLimitTab} from '../../common/views/ExpenseApproverLimitTable';
import {AccountReimbursementTab} from '../../reimbursement/views/ReimbursementTabs';
import {OnlinePaymentTabs} from './OnlinePaymentTabs';
import {BankTransactionTabs} from './TransactionTabs';
import {BankTables} from './BankTables';
import {FundTranferTab} from './FundTransferTab';
import {AccountsTabView} from '../../common/views/AccountTable';
import {CategoryTable} from '../../common/views/CategoryTable';
import {OnlineFundTransferTabs} from './OnlineFundTransferTab';

const VendorMenu = createMenuNavigator();

const VendorNavigator = props => {
  const {route: {params} = {}} = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  //   const canAccess = useAccessRight({accessRights: userAccessRights});

  return (
    <VendorMenu.Navigator
      //   canAccess={canAccess}
      data-navigator-id="VendorGroup"
      {...props}>
      <VendorMenu.Screen
        data-resource-id="VendorTabs"
        name="Vendors"
        // component={VendorTabView}
        // options={{
        //   label: 'Vendors',
        // }}
        options={{
          label: 'Vendors',
          onPress: () => {
            props.navigation.navigate('vendor-bifurcated-menu', {
              ...params,
            });
          },
        }}
      />
      <VendorMenu.Screen
        data-resource-id="BudgetRequestAccount"
        name="Budgets"
        component={BudgetRequestAccountTab}
        options={{
          label: 'Budgets',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="ApprovedExpenses"
        name="Expenses"
        component={ExpenseApproverInvoiceTab}
        options={{
          label: 'Expenses',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="QuotationApprover"
        name="Quotations"
        component={QuotationApproverFinanceTab}
        options={{
          label: 'Quotations',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="PurchaseOrder"
        name="Purchase Order"
        component={PurchaseOrderTab}
        options={{
          label: 'Purchase Order',
        }}
      />
      <VendorMenu.Screen
        name="AccountReimbursements"
        component={AccountReimbursementTab}
        props={{user: user, ...props}}
        options={{
          label: 'Reimbursements',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="VendorTable"
        name="VendorInvoices"
        component={VendorInvoiceTab}
        options={{
          label: 'Invoices',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="Payment"
        name="Payment"
        component={PaymentTabs}
        options={{
          label: 'Payment',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="FundTransfer"
        name="Fund Transfer"
        component={FundTranferTab}
        options={{
          label: 'Fund Transfer',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="Account"
        name="Account"
        component={AccountsTabView}
        options={{
          label: 'Account',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="Banks"
        name="Banks"
        component={BankTables}
        options={{
          label: 'Banks',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="OnlinePayment"
        name="Online Payment"
        component={OnlinePaymentTabs}
        options={{
          label: 'Online Payment',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="OnlineFundTransfer"
        name="Online Fund Transfer"
        component={OnlineFundTransferTabs}
        options={{
          label: 'Online Fund Transfer',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="BankTransaction"
        name="Bank Transaction"
        component={BankTransactionTabs}
        options={{
          label: 'Bank Transaction',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="RegisterITAssets"
        name="Register IT Assets"
        component={RegisterITAssetsTab}
        options={{
          label: 'Register IT Assets',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="Reports"
        name="Reports"
        props={{user: user, ...props}}
        options={{
          label: 'Reports',
          onPress: () => {
            props.navigation.navigate('vendor-reports-menu', {
              ...params,
            });
          },
        }}
      />
      <VendorMenu.Screen
        data-resource-id="Category"
        name="Payment Category"
        component={CategoryTable}
        options={{
          label: 'Payment Category',
        }}
      />
      <VendorMenu.Screen
        data-resource-id="RegisterITAssets"
        name="Expense Approver Limit"
        component={ExpenseApproverLimitTab}
        options={{
          label: 'Expense Approver Limit',
        }}
      />
    </VendorMenu.Navigator>
  );
};

export default VendorNavigator;
