import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';

export const OfficeTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="Office"
      api={`/offices`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`office-detail`, {officeId: row._id});
      }}
      renderHeader={() => (
        <TableHeader
          title="Office"
          actions={[
            <AddButton
              title="Add Office"
              view="add-office"
              // params={params}
            />,
          ]}
        />
      )}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
          minWidth: 150,
        },
        {
          type: 'text',
          field: 'code',
          header: 'Code',
          width: 100,
        },
        {
          type: 'text',
          field: 'pin_code',
          header: 'PIN Code',
          width: 100,
        },
        {
          type: 'text',
          field: 'address1',
          header: 'Address',
          width: 300,
        },
        {
          type: 'text',
          field: 'CDKey',
          header: 'CD Key',
          width: 100,
        },
        {
          type: 'text',
          field: 'EInvUserName',
          header: 'E-Invoice User Name',
          width: 150,
        },
        {
          type: 'text',
          field: 'EInvPassword',
          header: 'E-Invoice Password',
          width: 150,
        },
        {
          type: 'text',
          field: 'EFPassword',
          header: 'EF Password',
          width: 150,
        },
        {
          type: 'text',
          field: 'gstin_no',
          header: 'GSTIN No',
          width: 150,
        },
        {
          type: 'text',
          field: 'gst_state',
          header: 'GST State',
          width: 150,
        },
        {
          type: 'text',
          field: 'gst_state_code',
          header: 'GST State Code',
          width: 150,
        },
        {
          type: 'text',
          field: 'lut_no',
          header: 'LUT No',
          width: 150,
        },
      ]}
    />
  );
};
