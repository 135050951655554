import {resolveExp} from '@unthinkable/react-utils';

export const LOCOMO_PROJECT_ID = '6505943a66793c01172ac896';
export const PROJECT_VIEW_TYPE_ID = '64f5ceedc02ac6fe9bbbe777';
export const PROJECT_FEATURE_TYPE_ID = '64f58de16a0d14bfd9ca526a';
export const PROJECT_MILESTONE_TYPE_ID = '65f819605def883d21715c9e';
export const PROJECT_USER_STORY_TYPE_ID = '64f58e046a0d14bfd9ca526b';
export const PROJECT_COMPONENT_TYPE_ID = '64f5ceb1c02ac6fe9bbbe773';
export const PROJECT_THEME_TYPE_ID = '66cebac31c852d897cefac26';
export const PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID = '66cc39d9aef5c6a9025b0a82';
export const PROJECT_CONTROLLER_TYPE_ID = '64f5cebbc02ac6fe9bbbe774';
export const PROJECT_MODEL_TYPE_ID = '64f5cec2c02ac6fe9bbbe775';
export const PROJECT_TASK_TYPE_ID = '64f5cecfc02ac6fe9bbbe776';
export const PROJECT_BUG_TYPE_ID = '6515467ce1c10c3c9faaedae';
export const PROJECT_ISSUE_TYPE_ID = '64f85daa1c10880f7f8b09fe';
export const PROJECT_DATA_SERVICE_TYPE_ID = '650d8dc48665c6f8f739c942';
export const PROJECT_QA_TEST_CASE_TYPE_ID = '658fe8e3ba8f56db46659fc0';
export const PROJECT_MODULE_TYPE_ID = '650d712e28db77322b7ff5a8';
export const PROJECT_SUB_MODULE_TYPE_ID = '655c5f779e39e66d08148a75';
export const PROJECT_TYPE_ID = '651c0a8726cdd97dac96d461';
export const PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID =
  '650d8dfa8665c6f8f739c944';
export const PROJECT_COMMENT_TYPE_ID = '65d0886d43a50270d56993da';
export const PROJECT_REPOSITORY_TYPE_ID = '66990a72eba650bf30cfc2bc';
export const REPOSITORY_CODE_SAMPLE_TYPE_ID = '6686aa4021eba0bbb9c34be4';

export const MASTER_TASK_VIEW_TYPE_ID = '6554c2cd9757f16bcecb419a';
export const MASTER_TASK_USER_STORY_TYPE_ID = '6554c2b89757f16bcecb4165';
export const MASTER_TASK_CONTROLLER_TYPE_ID = '6554c3059757f16bcecb41ee';
export const MASTER_TASK_MODEL_TYPE_ID = '6554c2e79757f16bcecb41ad';
export const MASTER_TASK_DATA_SERVICE_TYPE_ID = '6554c3299757f16bcecb4294';

export const EMAIL_CAMPAIGN_TYPE_ID = '64d0fd55ab78dfead48c144a';
export const INMAIL_CAMPAIGN_TYPE_ID = '64d0fd55ab78dfead48c144b';
export const POTENTIAL_CUSTOMER_TYPE_ID = '643e9e0483dd7120a07d3e9a';
export const OPPORTUNITY_TYPE_ID = '64e06ebb94f415bdfd0a4aed';
export const STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID =
  '64e06ebc94f415bdfd0a4aee';
export const TARGET_TYPE_ID = '64e9917aef609b65cf14d98e';
export const COMMUNICATION_TYPE_ID = '64e9c2e1d429638aa33fde5a';
export const CUSTOMER_TYPE_ID = '64e9ad68e0c0e3639244d2d6';
export const ORDER_TYPE_ID = '64ec67c242adb2661796cbf8';
export const REVENUE_INVOICE_TYPE_ID = '64ec708cb748beb7ab4e638c';
export const REQUEST_TALENT_TYPE_ID = '64edbc327b3693a735fba141';
export const DATA_MINING_TYPE_ID = '6481c0cc1e9a54052c224d34';
export const WEB_PAGE_TYPE_ID = '64ddbdfdc04e3fa90747b2fa';
export const WEB_CAMPAIGN_TYPE_ID = '64884b6bd40198f62b283007';
export const EXPENSE_REQUEST_TYPE_ID = '66d86104afd2c49c13d15cdd';

export const PAID_AD_CAMPAIGN_TYPE_ID = '64be7476e4ba09d3df7b3a6d';
export const OTHER_CAMPAIGN_TYPE_ID = '64db3dc4334a9d2f6766ded2';
export const AD_CREATIVE_TYPE_ID = '64ddbdfcc04e3fa90747b2f0';
export const BLOG_TYPE_ID = '64ddbdfcc04e3fa90747b2f1';
export const BUYER_PERSONA_TYPE_ID = '64ddbdfcc04e3fa90747b2f2';
export const CAROUSEL_AD_TYPE_ID = '64ddbdfcc04e3fa90747b2f3';
export const CASE_STUDY_TYPE_ID = '64ddbdfcc04e3fa90747b2f4';
export const DRIP_EMAIL_TYPE_ID = '64ddbdfcc04e3fa90747b2f5';
export const DRIP_INMAIL_TYPE_ID = '64ddbdfcc04e3fa90747b2f6';
export const IMAGE_TYPE_ID = '64ddbdfcc04e3fa90747b2f7';
export const SINGLE_SCREEN_MESSAGE_TYPE_ID = '64ddbdfcc04e3fa90747b2f8';
export const VIDEO_TYPE_ID = '64ddbdfcc04e3fa90747b2f9';
export const MARKETING_CREATIVE_TYPE_ID = '64ddbe8dc04e3fa90747b2fb';
export const CUSTOM_PLATFORM_OPPORTUNITY_TYPE_ID = '64e06ebc94f415bdfd0a4aef';
export const DEFINE_USER_STORY = 'define_user_stories';
export const MAP_VIEWS = 'map_Views';
export const DEFINE_CONTROLLERS = 'define_controllers';
export const DEFINE_MODELS = 'define_models';
export const DESIGN_UI = 'design_ui';
export const DEFINE_DATA_SERVICES = 'define_data_services';
export const DEFINE_THIRD_PARTY_INTEGRATIONS =
  'define_third_party_integrations';
export const PROJECT_PULL_REQUEST_TYPE_ID = '65165f4e06ec0e641c9de004';

// download file sample -> file_id
export const PROJECT_MODEL_IMPORT_SAMPLE_ID = '6696621980ff91bae34d9f4a';
export const PROJECT_CONTROLLER_IMPORT_SAMPLE_ID = '66a79ce25131a0e5dda4fe26';
export const PROJECT_COMPONENT_IMPORT_SAMPLE_ID = '6698ce6480ff91bae34da4c8';

// Akshat Garg - 23/08/24 - thread model
export const THREAD_ID = '66c9cd8e6a88da2973c00e7e';
// Akshat Garg - 1/10/24 - thread goal model
export const THREAD_GOAL_ID = '66fbbf9e40a1db71c0964033';
const getStartAndEndOfDay = dateString => {
  if (!dateString) {
    throw new Error('No date string provided');
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string provided');
  }

  const startOfDay = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
      0,
    ),
  );

  const endOfDay = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59,
      59,
      999,
    ),
  );

  return {
    from: startOfDay.toISOString(),
    to: endOfDay.toISOString(),
  };
};
const views = {
  [PROJECT_VIEW_TYPE_ID]: 'add-view',
  [PROJECT_FEATURE_TYPE_ID]: 'add-feature',
  [PROJECT_COMPONENT_TYPE_ID]: 'add-component',
  [PROJECT_CONTROLLER_TYPE_ID]: 'add-controller',
  [PROJECT_MODEL_TYPE_ID]: 'add-model',
  [PROJECT_DATA_SERVICE_TYPE_ID]: 'add-data-service',
  [PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID]: 'add-third-party-integration',
  [PROJECT_BUG_TYPE_ID]: 'add-bug',
  [PROJECT_ISSUE_TYPE_ID]: 'add-issue',
  [EMAIL_CAMPAIGN_TYPE_ID]: 'add-emailcampaign',
  [INMAIL_CAMPAIGN_TYPE_ID]: 'add-inmailcampaign',
  [POTENTIAL_CUSTOMER_TYPE_ID]: 'add-potentialcustomer',
  [OPPORTUNITY_TYPE_ID]: 'add-opportunity',
  [STAFF_AUGMENTATION_OPPORTUNITY_TYPE_ID]: 'add-staffaugmentationopportunity',
  [TARGET_TYPE_ID]: 'add-target',
  [COMMUNICATION_TYPE_ID]: 'add-communication',
  [CUSTOMER_TYPE_ID]: 'add-customer',
  [REQUEST_TALENT_TYPE_ID]: 'add-request-talent',
  [DATA_MINING_TYPE_ID]: 'add-datamining',
  [WEB_PAGE_TYPE_ID]: 'add-webpage',
  [WEB_CAMPAIGN_TYPE_ID]: 'add-webcampaign',

  [PAID_AD_CAMPAIGN_TYPE_ID]: 'add-paidadcampaign',
  [OTHER_CAMPAIGN_TYPE_ID]: 'add-othercampaign',
  [AD_CREATIVE_TYPE_ID]: 'add-adcreatives',
  [BLOG_TYPE_ID]: 'add-blog',
  [BUYER_PERSONA_TYPE_ID]: 'add-buyerpersona',
  [CAROUSEL_AD_TYPE_ID]: 'add-carouselad',
  [CASE_STUDY_TYPE_ID]: 'add-casestudy',
  [DRIP_EMAIL_TYPE_ID]: 'add-dripemailcontent',
  [DRIP_INMAIL_TYPE_ID]: 'add-dripinmailcontent',
  [IMAGE_TYPE_ID]: 'add-image',
  [SINGLE_SCREEN_MESSAGE_TYPE_ID]: 'add-singlescreenmessage',
  [VIDEO_TYPE_ID]: 'add-video',
  [MARKETING_CREATIVE_TYPE_ID]: 'add-marketingcreative',
  [CUSTOM_PLATFORM_OPPORTUNITY_TYPE_ID]: 'add-customplatformopportunity',
};

export const SHORT_NAMES = {
  [PROJECT_VIEW_TYPE_ID]: 'Screen',
  [PROJECT_FEATURE_TYPE_ID]: 'Feature',
  [PROJECT_USER_STORY_TYPE_ID]: 'User Story',
  [PROJECT_COMPONENT_TYPE_ID]: 'Component',
  [PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID]: 'Data Service',
  [PROJECT_THEME_TYPE_ID]: 'Theme',
  [PROJECT_CONTROLLER_TYPE_ID]: 'API',
  [PROJECT_MODEL_TYPE_ID]: 'Model',
  [PROJECT_DATA_SERVICE_TYPE_ID]: 'Service',
  [PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID]: 'Third Party Integration',
  [PROJECT_BUG_TYPE_ID]: 'Bug',
  [PROJECT_TASK_TYPE_ID]: 'Task',
  [PROJECT_ISSUE_TYPE_ID]: 'Issue',
  [PROJECT_PULL_REQUEST_TYPE_ID]: 'Pull Request',
};

export const NAVIGATE_TO_ADD_VIEW =
  ({sourceField, navigation, params}) =>
  props => {
    const {
      form: {values},
    } = props;
    let sourceValue = values[sourceField];
    if (!sourceValue) {
      return;
    }
    if (sourceValue._id) {
      sourceValue = sourceValue._id;
    }
    const mappedView = views[sourceValue];

    if (!mappedView) {
      return;
    }
    navigation.push(mappedView, {...params});
  };

export const CASCADER_NAVIGATE_TO_ADD_VIEW =
  ({navigation, params}) =>
  props => {
    const {item, onChange, searchValue} = props;
    let {add_view: mappedView} = item;

    if (!mappedView) {
      mappedView = views[item._id];
    }

    if (!mappedView) {
      return;
    }
    navigation.push(mappedView, {
      ...params,
      searchValue,
      afterSubmit: ({data}) => onChange && onChange(data),
    });
  };

export const navigateToListView = props => {
  const {
    sourceField,
    sourceIdField,
    target_view_field,
    navigation,
    params,
    row,
  } = props;

  let sourceValue = row[sourceField];
  let sourceIdValue = row?.[sourceIdField];

  let targetScreen = resolveExp(row, target_view_field);

  if (!sourceValue && !sourceIdValue) {
    return;
  }
  const listView = sourceValue?.list_view;

  if (sourceValue._id) {
    sourceValue = sourceValue._id;
  }
  if (sourceIdValue?._id) {
    sourceIdValue = sourceIdValue?._id;
  }

  const mappedView = targetScreen || listView;

  if (!mappedView) {
    return;
  }

  const extraData = {};
  if (row?.feature_id) {
    extraData.feature_id = row?.feature_id;
  }
  if (row?.module_id) {
    extraData.module_id = row?.module_id;
  }
  if (row?.project_id) {
    extraData.project_id = row?.project_id;
  }

  let extraParams = {};
  if (row.due_date) {
    let previousDate = new Date(row.due_date);
    previousDate.setDate(previousDate.getDate() - 1);

    const period = getStartAndEndOfDay(row.due_date);
    const previousPeriod = getStartAndEndOfDay(previousDate);

    extraParams.period = period;
    extraParams.previousPeriod = previousPeriod;
  }
  const sourceIdName = row?.source_id?.name;
  navigation.push(mappedView, {
    ...params,
    source_id: sourceIdValue,
    sourceIdName,
    extraData,
    parent_task_id: row._id,
    ...extraParams,
  });
};

export const flattenConversations = arr => {
  return arr.reduce((flat, item) => {
    if (Array.isArray(item.conversations) && item.conversations.length > 0) {
      return [...flat, item, ...flattenConversations(item.conversations)];
    } else {
      return [...flat, item];
    }
  }, []);
};

export const termsOfServiceURL = 'https://locomo.io/terms-of-service';

export const isPMTAccessible = props => {
  const {user} = props;
  if (!user) {
    return false;
  }
  if (
    user.externalUser ||
    user.userType == 'Client' ||
    user.userType == 'client'
  ) {
    return true;
  }
  return isAccessible(props);
};
export const isAccessible = props => {
  const {
    user,
    module,
    menu,
    menuSection,
    screen,
    screenSection,
    fromMenu,
    fromScreen,
    fromModule,
  } = props || {};
  const {accessiblemodules = {}, email} = user || {};

  const appModule_id = module?.id;
  const menuId = menu?.id;
  const menuSectionId = menuSection?.id;
  const screenSectionId = screenSection?.id;

  if (
    email === 'sushil.nagvan@daffodilsw.com' ||
    email === 'amit.singh@daffodildb.com' ||
    email === 'amit@daffodilsw.com' ||
    email === 'aswathy.krishnankutty@unthinkable.co' ||
    email === 'yogesh@daffodilsw.com'
  ) {
    return true;
  }

  let fullPath = '';

  let hasAccess = false;
  let hasRestricted = false;

  if (module && module.id && fromModule) {
    fullPath = `module_${module.id}`;

    if (accessiblemodules[fullPath]) {
      hasAccess = true;
    }
  } else if (menu && menu.id && fromMenu) {
    if (appModule_id) {
      fullPath = `module_${appModule_id}`;
    }
    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.scope === 'full'
    ) {
      hasAccess = true;
    }

    if (menuSectionId) {
      fullPath += `_menu_${menuSectionId}`;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.exclude_resource
    ) {
      hasRestricted = true;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.scope === 'full'
    ) {
      hasAccess = true;
    }

    if (menu && menu.id) {
      fullPath += `_menu_${menu.id}`;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.exclude_resource
    ) {
      hasRestricted = true;
    }

    if (accessiblemodules[fullPath]) {
      hasAccess = true;
    }
  } else if (screen && screen.id && fromScreen) {
    if (appModule_id) {
      fullPath = `module_${appModule_id}`;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.scope === 'full'
    ) {
      hasAccess = true;
    }
    if (menuSectionId) {
      fullPath += `_menu_${menuSectionId}`;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.exclude_resource
    ) {
      hasRestricted = true;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.scope === 'full'
    ) {
      hasAccess = true;
    }

    if (menuId) {
      fullPath += `_menu_${menuId}`;
    }
    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.scope === 'full'
    ) {
      hasAccess = true;
    }
    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.exclude_resource
    ) {
      hasRestricted = true;
    }

    if (screenSectionId) {
      fullPath += `_screen_${screenSectionId}`;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.scope === 'full'
    ) {
      hasAccess = true;
    }

    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.exclude_resource
    ) {
      hasRestricted = true;
    }

    if (screen && screen.id) {
      fullPath += `_screen_${screen.id}`;
    }

    if (accessiblemodules[fullPath]) {
      hasAccess = true;
    }
    if (
      accessiblemodules[fullPath] &&
      accessiblemodules[fullPath]?.exclude_resource
    ) {
      hasRestricted = true;
    }
  }
  if (hasRestricted) {
    return false;
  } else if (hasAccess) {
    return true;
  } else {
    return false;
  }
};
