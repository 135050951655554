import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

const TITLE = {
  field: 'blog_title',
  header: 'Title',
  responsive: 'sm',
};

const URL = {
  field: 'blog_url',
  header: 'URL',
};

const CREATEDON = {
  type: 'date',
  field: 'created_on',
  header: 'Created On',
};

const COMPLETEDON = {
  type: 'date',
  field: 'completed_date',
  header: 'Completed On',
};
export const BlogActiveTable = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const {communicationFilter} = params;
  const invoke = useInvoke({method: 'put'});
  const markComplete = ({row}) => {
    invoke({
      uri: `/blogs/${row?._id}`,
      props: {
        status: 'completed',
        completed_date: new Date(),
      },
    });
  };
  return (
    <Table
      search={searchValue}
      searchFields={'blog_title'}
      api={`/blog-lists`}
      addOnFilter={{
        status: 'active',
        ...communicationFilter,
      }}
      params={params}
      onRowPress={row => {
        navigation.navigate('edit-blog', row);
      }}
      columns={[
        TITLE,
        URL,
        CREATEDON,
      ]}
      moreActions={[
        {
          title: 'Mark Completed',
          onPress: markComplete,
        },
      ]}
    />
  );
};

export const BlogCompletedTable = props => {
  const {
    route: {params},
    searchValue,
  } = props;
  const {communicationFilter} = params;
  return (
    <Table
      search={searchValue}
      searchFields={'blog_title'}
      api={`/blog-lists`}
      addOnFilter={{
        status: 'completed',
        ...communicationFilter,
      }}
      params={params}
      columns={[TITLE, URL, CREATEDON, COMPLETEDON]}
    />
  );
};
