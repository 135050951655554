const {InlineForm, Form} = require('../../../components/form/Form');
const {useFormSubmit} = require('../../../controllers/useSubmitForm');

export const AddTicketCategory = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/ticketcategories',
    eventSourceId: 'TicketCategory',
  });
  const {
    route: {params},
  } = props;
  const {employee_team} = params;
  return (
    <InlineForm
      header="Add Ticket Category"
      onSubmit={onSubmit}
      defaultValues={{
        employee_team: employee_team?._id,
      }}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
    />
  );
};

export const EditTicketCategory = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/ticketcategories',
    eventSourceId: 'TicketCategory',
  });
  const {
    route: {params},
  } = props;
  const {employee_team, ticket_category} = params;
  return (
    <Form
      header="Edit Ticket Category"
      onSubmit={onSubmit}
      defaultValues={{
        employee_team: employee_team?._id,
      }}
      api={'/ticketCategories/' + ticket_category?._id}
      mode="edit"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
    />
  );
};
