import {IconButton} from '@unthinkable/react-button';
import {Col, Row, Styled, Text, View} from '@unthinkable/react-core-components';
import {MessageRender} from '@unthinkable/react-mentions-input';

export const MessageWrapper = Styled(Row)`
padding:4px;
${({position, styles}) => {
  return {
    ...(position == 'right' ? {flexDirection: 'row-reverse'} : {}),
    ...styles?.container,
  };
}}
`;

export const MessageBoxWrapper = Styled(Col)`
        border-width: 1px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        max-width: 70%;
        border-color: #f7f7f7;
${({styles, position, backgroundColor}) => {
  return {
    ...(position == 'left'
      ? {
          borderTopRightRadius: 12,
          ...styles?.messageBox?.leftContainer,
        }
      : {
          borderTopLeftRadius: 12,
          ...styles?.messageBox?.rightContainer,
        }),
    backgroundColor,
  };
}}
`;

export const Sender = Styled(Text)`
        font-size: 14px;
        font-family: Inter-Medium;
${({color, styles}) => {
  return {
    ...styles?.messageBox?.sender,
    color,
  };
}}
`;

export const TextMessage = Styled(MessageRender)`
        color: #1a1a1a;
        font-size: 14px;
        font-family: Inter-Regular;
`;

export const TimeContainer = Styled(View)`
${({position}) => (position == 'right' ? {alignItems: 'flex-end'} : void 0)}
`;

export const Time = Styled(Text)`
color: #737373;
 font-size: 10px;
  font-family: Inter-Regular;
${({styles}) => {
  return {
    ...styles?.messageBox?.time,
  };
}}
`;

export const DateChipContainer = Styled(Row)`
align-items: center;
${({styles}) => styles?.dateChip?.container}
`;

export const Line = Styled(View)`
flex: 1;
height: 2px;
background-color: #F0F0F0;
${({styles}) => styles?.dateChip?.line}`;

export const DateText = Styled(Text)`
color: #72777A;
font-size: 12px;
 font-family: Inter-Regular; 
${({styles}) => styles?.dateChip?.text}`;

export const SendIcon = Styled(IconButton)`
height: 32px;
 width: 32px;
 background-color:#F3FEF0;
 padding: 6px;
 border-radius:6px;
${({styles}) => styles?.sendButton}
`;

export const InputWrapper = Styled(Row)`
border-width: 1px;
border-radius: 6px;
border-color: #eeeeed;
padding: 12px;
align-items: center;
${({styles}) => styles?.inputWrapper}
`;
