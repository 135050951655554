import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {CompactAddButton} from '../../../components/button/AddButton';
import {CustomerOverview} from './CustomerOverview';
import {SalesTable} from './SalesTable';
import {CustomerProjectTabs} from './CustomerProjectTable';
import {PaymentTable} from './PaymentTable';

const menus = [
  [
    // {
    //   label: 'Overview',
    //   view: <CustomerOverview />,
    // },
    {
      label: 'Overview',
      view: <CustomerOverview />,
    },
    {
      label: 'Projects',
      view: <CustomerProjectTabs />,
    },
    {
      label: 'Opportunity',
      view: <SalesTable />,
    },
    {
      label: 'Invoices',
      view: <PaymentTable />,
    },
  ],
];

const CustomerDetailDashboard = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export default CustomerDetailDashboard;
