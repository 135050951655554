import React from 'react';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {getTime} from '../../modules/pmt/utility';
import {useStyles} from '@unthinkable/react-theme';
import {resolveExp} from '@unthinkable/react-utils';
import {Avatar} from '../../components/avatar/Avatar';
import {ActivityLogStyles} from './theme';
import moment from 'moment';

const isDate = dateString => {
  if (typeof dateString !== 'string') {
    return false;
  }

  // This regex roughly validates the ISO 8601 date format
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/;

  if (!isoDateRegex.test(dateString)) {
    return false;
  }

  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};
// Make a function to resolve the object to get fileName. - Rajnish Trikaldarshi 16-02-2024
const getFilenameFromValues = values => {
  if (!values || typeof values !== 'object') {
    return values;
  }
  return values['filename'];
};

// Make a function to change the field in to uppercase. - Rajnish Trikaldarshi 22-02-2024
function processString(label) {
  if (label && /_id\s*$/i.test(label)) {
    label = label.replace(/_id\s*$/i, '');
  }

  return label?.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

// Extract values from the input object excluding the key '_id'. - Rajnish Trikaldarshi 17-02-2024
function getNonIdKeyValue(values) {
  if (values) {
    return Object.entries(values)
      .filter(([key, value]) => key !== '_id')
      .map(([key, value]) => value)
      .join(',');
  }
}

const ActivityLogRender = ({row, displayFields}) => {
  let {textStyle, fontWeightStyle, dateText, changesText} =
    useStyles(ActivityLogStyles);
  let field = displayFields && displayFields[row.field];
  let fieldLabel = '';
  let oldValueText = '';
  let newValueText = '';
  let changedByText = '';

  if (field) {
    fieldLabel += field.label || processString(row.field);

    if (row.old) {
      if (typeof row.old === 'object') {
        if (Array.isArray(row.old)) {
          oldValueText += row.old
            .map(item => {
              let newItem = {};
              newItem[row.field] = item;
              if (field.type === 'file') {
                return getFilenameFromValues(item);
              } else if (!field.field) {
                return getNonIdKeyValue(item);
              }
              return resolveExp(newItem, field.field);
            })
            .join(', ');
        } else {
          let newOld = {};
          newOld[row.field] = row.old;
          if (!field.field) {
            oldValueText += getNonIdKeyValue(row.old);
          } else {
            oldValueText += resolveExp(newOld, field.field);
          }
        }
      } else {
        oldValueText += isDate(row.old) ? getTime(row.old) : row.old;
      }
    } else {
      oldValueText += 'N/A';
    }

    if (row.newValue) {
      if (typeof row.newValue === 'object') {
        if (Array.isArray(row.newValue)) {
          newValueText += row.newValue
            .map(item => {
              let newItem = {};
              newItem[row.field] = item;
              if (field.type === 'file') {
                return getFilenameFromValues(item);
              } else if (!field.field) {
                return getNonIdKeyValue(item);
              }
              return resolveExp(newItem, field.field);
            })
            .join(', ');
        } else {
          let newNewValue = {};
          newNewValue[row.field] = row.newValue;
          if (!field.field) {
            newValueText += getNonIdKeyValue(row.newValue);
          } else {
            newValueText += resolveExp(newNewValue, field.field);
          }
        }
      } else {
        newValueText += isDate(row.newValue)
          ? getTime(row.newValue)
          : row.newValue;
      }
    } else {
      newValueText += 'N/A';
    }
    if (row?.changedBy?.name) {
      changedByText += row.changedBy.name;
    }
  } else {
    if (row?.field) {
      fieldLabel += processString(row.field);
    }

    if (row.old) {
      if (typeof row.old === 'object') {
        if (Array.isArray(row.old)) {
          oldValueText += row.old.map(item => getNonIdKeyValue(item));
        } else {
          oldValueText += getNonIdKeyValue(row.old);
        }
      } else {
        oldValueText += isDate(row.old) ? getTime(row.old) : row.old;
      }
    } else {
      oldValueText += 'N/A';
    }

    if (row.newValue) {
      if (typeof row.newValue === 'object') {
        if (Array.isArray(row.newValue)) {
          newValueText += row.newValue
            .map(item => getNonIdKeyValue(item))
            .join(', ');
        } else {
          newValueText += getNonIdKeyValue(row.newValue);
        }
      } else {
        newValueText += isDate(row.newValue)
          ? getTime(row.newValue)
          : row.newValue;
      }
    } else {
      newValueText += 'N/A';
    }
    if (row?.changedBy?.name) {
      changedByText += row.changedBy.name;
    }
  }

  return (
    <Row
      gap={8}
      style={{
        paddingTop: 4,
        paddingBottom: 4,
        ...(row?.op === 'insert' ? {alignItems: 'center'} : {}),
      }}>
      <Avatar value={changedByText || '-'} />
      <View style={{gap: 4, flex: 1}}>
        <Text as="span" style={textStyle}>
          {changedByText ? (
            <Text as="span" style={fontWeightStyle}>
              {changedByText}
            </Text>
          ) : (
            void 0
          )}
          <Text as="span">
            {row?.op === 'insert' ? ` Created ` : ` changed the `}
          </Text>
          {row?.op !== 'insert' ? (
            <Text as="span" style={fontWeightStyle}>
              {`${fieldLabel} `}
            </Text>
          ) : (
            void 0
          )}
          <Text as="span">{` on `}</Text>
          <Text as="span" style={dateText}>
            {moment(row.createdAt).format('DD MMMM YY [at] HH:mm')}
          </Text>
        </Text>
        {row?.op !== 'insert' ? (
          <Row style={{alignItems: 'center'}}>
            <Text style={changesText}>{`${oldValueText || 'N/A'}`}</Text>
            <View style={{width: 50, alignItems: 'center'}}>
              <Text style={{...textStyle}}>{` --> `}</Text>
            </View>
            <Text style={changesText}>{`${newValueText}`}</Text>
          </Row>
        ) : (
          void 0
        )}
      </View>
    </Row>
  );
};

export default ActivityLogRender;
