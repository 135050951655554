import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const MarkOpportunityPositonLost = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'StaffAugmentation',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Lost Position'}
      submitAction={'Save'}
      defaultValues={{
        _id: row?._id,
        // lost_position_date: new Date(),
      }}
      layoutFields={[
        {
          type: 'number',
          field: 'lost_position_count',
          label: 'Lost Count',
          required: true,
        },
        {
          field: 'lost_position_reason',
          placeholder: 'Reason',
          // label: 'Output',
          type: 'textArea',
          required: true,
        },
        {
          field: 'lost_position_date',
          placeholder: 'Date',
          type: 'date',
          required: true,
        },
      ]}
    />
  );
};

export const MarkOpportunityPositonDrop = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'StaffAugmentation',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Drop Position'}
      submitAction={'Save'}
      defaultValues={{
        _id: row?._id,
        // drop_position_date: new Date(),
      }}
      layoutFields={[
        {
          type: 'number',
          field: 'drop_position_count',
          label: 'Drop Count',
          required: true,
        },
        {
          field: 'drop_position_reason',
          placeholder: 'Reason',
          type: 'textArea',
          required: true,
        },
        {
          field: 'drop_position_date',
          placeholder: 'Date',
          type: 'date',
          required: true,
        },
      ]}
    />
  );
};
