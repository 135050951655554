import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {useInvoke} from '../../../controllers/useInvoke';

export const UpdateIncomeTaxValue = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/incometaxes`,
    eventSourceId: 'taxDeclarationAccounts',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Nps And Tds Values'}
      api={`/employee/taxDeclaration/${row?._id}`}
      fields = {
        {
          additional_income : {
            component : 1,
            amount : 1
          },
          additional_exemption : {
            component : 1,
            amount : 1
          },
          mannual_tds : 1,
          mannual_nps : 1,
          mannual_tax_sheet : 1
        }
      }
      mode={'edit'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'Additional Income Component',
          fields: [
            {
              field: 'additional_income',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Additional Income Component',
                      field: 'component',
                      header: 'Additional Income Component',
                    },
                    {
                      type: 'number',
                      placeholder: 'Amount',
                      field: 'amount',
                      header: 'Amount',
                    },
                  ]}
                  columns={[
                    {
                      header: 'Additional Income Component',
                      field: 'component',
                      type: 'text',
                    },
                    {
                      type: 'number',
                      field: 'amount',
                      header: 'Amount',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Additional Exemption Component',
          fields: [
            {
              field: 'additional_exemption',
              nested: true,
              render: props => (
                <NestedTable
                  readOnly={true}
                  {...props}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Additional Exemption Component',
                      field: 'component',
                      header: 'Additional Exemption Component',
                    },
                    {
                      type: 'number',
                      placeholder: 'Amount',
                      field: 'amount',
                      header: 'Amount',
                    },
                  ]}
                  columns={[
                    {
                      header: 'Additional Exemption Component',
                      field: 'component',
                      type: 'text',
                    },
                    {
                      type: 'number',
                      field: 'amount',
                      header: 'Amount',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          type: 'number',
          field: 'mannual_tds',
          placeholder: 'Update TDS Value',
        },
        {
          type: 'number',
          field: 'mannual_nps',
          placeholder: 'Update NPS Value',
        },
        {
          type: 'file',
          field: 'mannual_tax_sheet',
          placeholder: 'Update Excel',
        },
      ]}
    />
  );
};

export const UpdateTDSMonthAndYear = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    method: 'post',
  });
  return (
    <Form
      {...props}
      onSubmit={data => {
        invoke({
          uri: '/incometax/updatetdsmonthandyear',
          props: {data: {...params, ...data}},
        });
      }}
      header={'Update TDS month and year'}
      submitAction={'Update'}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Month',
          api: '/monthtypes',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'tds_month_id',
          required: true,
        },
        {
          type: 'autoComplete',
          label: 'Year',
          api: '/yeartypes',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'tds_year_id',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateTaxCalculationDate = props => {
  const {
    route: {params},
  } = props;
  const invoke = useInvoke({
    method: 'post',
  });
  return (
    <Form
      {...props}
      onSubmit={data => {
        invoke({
          uri: '/incometax/updatetaxcalculationdate',
          props: {data: {...params, ...data}},
        });
      }}
      header={'Update tax calculation date'}
      submitAction={'Update'}
      layoutFields={[
        {
          type: 'date',
          placeholder: 'Tax Calculation Date',
          field: 'tax_calculation_date',
          required: true,
        },
      ]}
    />
  );
};
