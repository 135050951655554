import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {ScrollView} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';
import {isMobile} from '@unthinkable/react-utils';
import {RevisionStyles} from './theme';
import {AttachmentHistory} from './AttachmentHistory';
import {Button} from '../../../components/button/Button';

export const Revision = props => {
  const {modalContainer, collapse} = useStyles(RevisionStyles);

  const renderModal = ({hide}) => {
    return (
      <ScrollView>
        <AttachmentHistory {...props} hide={hide} />
      </ScrollView>
    );
  };

  return (
    <WithModal
      styles={{container: modalContainer}}
      renderModal={renderModal}
      position={isMobile ? 'screenBottom' : 'bottom'}
      placement="end"
      withArrow>
      <Button
        text={'Revisions'}
        icon={collapse}
        gap={10}
        iconPosition={'right'}
      />
    </WithModal>
  );
};
