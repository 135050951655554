import { Col, Text } from '@unthinkable/react-core-components';
import { RowWithSeparator } from '@unthinkable/react-layout';
import React from 'react';
import { Chip, Tag } from '../../../components/chip/Chip';
import { GroupTable, Table } from '../../../components/table/Table';
import { useInvoke } from '../../../controllers/useInvoke';
import {
    UserAvatarWithNameAndEmployeeCode,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import { GroupContentItem } from '../../../components/card/GroupContentItem';
import { useTheme } from '@unthinkable/react-theme';
import moment from 'moment';
import { useAppStateContext } from '../../../providers/AppState';


export const AttendanceAllGroupTable = props => {
    let {
        navigation,
        route: { params },
        filter,
        searchValue,
        filterParams,
        periods
    } = props;

    const { period, ...rest } = params;

    params = {
        periods,
        ...rest,
    };
    params = { ...params, ...filterParams }
    //
    const invoke = useInvoke({
        method: 'post',
        eventSourceId: ['EmployeeAssignment'],
    });
    const { user, download } = useAppStateContext();
    const fetchProps = {
        params,
        api: `/dailyattendance/all`,
        fromExport: true,
    };
    return (
        <GroupTable
            {...props}
            params={params}
            eventSourceId={'alllop'}
            filter={filter}
            search={searchValue}
            api={`/dailyattendance/all`}
            groupRow={{
                data: '_children',
                leftContent: ({ row }) => {
                    return (
                        <RowWithSeparator style={{ gap: 8, alignItems: 'center' }}>
                            <GroupContentItem value={row.name} />
                            <GroupContentItem value={`${row.employeeCount} Employee`} />
                        </RowWithSeparator>
                    );
                },
            }}
            // selection={{
            //     actions: [
            //         {
            //             title: 'Start no dues',
            //             onPress: props => {
            //                 const { selectedIds } = props;
            //                 invoke({
            //                     uri: '/resignation15DaysBefore',
            //                     message: 'No dues started successfully',
            //                     props: {
            //                         ManualProcessStart: selectedIds,
            //                     },
            //                 });
            //             },
            //         },
            //         {
            //             title: 'Download',
            //             onPress: props =>
            //                 download({
            //                     uri: '/exportExcelHelper',
            //                     props: {
            //                         props: {
            //                             // ...props,
            //                             selectedIds: props.selectedIds,
            //                             fetchProps,
            //                             column: {
            //                                 'Employee code': 'employee_code',
            //                                 Name: 'name',
            //                                 Gender: 'gender',
            //                                 'Father name': 'father_name',
            //                                 Experience: 'experience',
            //                                 Designation: 'designation.name',
            //                                 'Employee Level': 'employee_level.name',
            //                                 'Mobile No': 'mobile_number',
            //                                 'Official Email Id': 'official_email_id',
            //                                 'Personal Email': 'email_id',
            //                                 'Joining date': 'joining_date',
            //                                 Resign_date: 'resign_date_new',
            //                                 'Relieving date': 'relieving_date',
            //                                 'Expected relieving date': 'expected_relieving_date',
            //                                 'Exit type': 'exit_type',
            //                                 'Desirable stauts': 'desireable_status',
            //                                 Status: 'employee_resignation_status',
            //                                 Reason: 'resign_reason.reason',
            //                                 'Reporting To': 'reporting_to.name',
            //                                 'Reporting To Code': 'reporting_to.employee_code',
            //                             },
            //                         },
            //                     },
            //                 }),
            //         },
            //     ],
            // }}
            columns={[
                {
                    header: 'Employee',
                    render: ({ row }) => {
                        return (
                            <UserAvatarWithNameAndEmployeeCode
                                name={row?.employee?.name}
                                color={row?.employee?.color}
                                employee_code={row?.employee?.employee_code}
                            />
                        );
                    },
                    onPress: ({ row }) => {
                        navigation.navigate('employee-attendance', {
                            daywisefilter: {
                                employee : row.employee._id
                            },
                            fromDepartmentReport : true,
                            period : periods,
                        });
                    },
                },
                {
                    header: 'Joining Date',
                    field: 'employee.joining_date',
                    type: 'date',
                    render: ({ row = {}, styles: { rowText = {} } }) => {
                        return (
                            <Text style={{ ...rowText }}>
                                {moment(row?.employee?.joining_date).format('DD MMM YY')}
                            </Text>
                        );
                    },
                },
                {
                    header: 'Leaves',
                    field: 'leaveCount',
                    type: 'colorTag',
                },
                {
                    header: 'EWD',
                    field: 'ewdCount',
                    type: 'colorTag',
                },
                {
                    header: 'Attendance%',
                    field: 'attPerc',
                    type: 'colorTag',
                },
                
            ]}
        />
    );
};
