import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {useComponentTheme} from '@unthinkable/react-theme';
import {TextRenderer} from '../../../components/form/Renderers';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';

const extendedDateInfoRender = props => {
  let {row, onIconPress} = props;
  let {to_date, extended_date} = row || {};
  let {columnTextStyle, estChange, estChangeStyle} = useComponentTheme(
    'PlusColumnRenderStyles',
  );

  return (
    <Row gap={1}>
      <Text
        style={{
          ...columnTextStyle,
          color: '#929DA9',
        }}>
        {moment(extended_date || to_date).format('DD MMM')}
      </Text>
      {extended_date ? (
        <View
          onPress={() => onIconPress({row})}
          style={{marginLeft: 4, height: 16, width: 16}}>
          <Image source={estChange} />
        </View>
      ) : (
        void 0
      )}
    </Row>
  );
};

const RenderBatch = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer value={row.training_batch?.name} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={row?.training_batch?.training_program?.name} />
        <GroupContentItem
          value={`Batch ${row?.training_batch?.batch_number}`}
        />
      </RowWithSeparator>
    </View>
  );
};

const RenderOverAllScore = props => {
  const {row} = props;
  const {training_feedbacks = []} = row;
  const sum = training_feedbacks.reduce((acc, one) => {
    return acc + one.overall_score;
  }, 0);
  const avg_rating = sum / (training_feedbacks.length || 1);
  if (avg_rating) {
    return <RatingRenderer value={avg_rating} />;
  }
  return (
    <TextRenderer {...props} value={'Feedback'} style={{color: '#007AFF'}} />
  );
};

const RenderAssignmentType = ({row}) => {
  const {employee} = row;
  const {employee_current_non_training_assignment = {}} = employee;
  const {assigned_type = 'Bench'} =
    employee_current_non_training_assignment || {};
  return <TextRenderer value={assigned_type} />;
};

export const EmployeeTrainingList = props => {
  const {
    navigation,
    route: {params},
    isExtended,
    myMenteesTabs,
  } = props;
  const {training_batch} = params;
  const {user} = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'EmployeeTrainings',
    method: 'put',
  });
  return (
    <Table
      fields={{
        employee: {
          name: 1,
          color: 1,
          employee_current_non_training_assignment: {
            _id: 1,
            assigned_type: 1,
          },
        },
        mentor: {
          name: 1,
          color: 1,
        },
        from_date: 1,
        to_date: 1,
        extended_date_details: 1,
        next_feedback_date: 1,
        extended_date: 1,
        status: 1,
        training_batch: {
          training_program: {
            name: 1,
          },
          batch_number: 1,
          name: 1,
        },
        training_feedbacks: {
          overall_score: 1,
        },
      }}
      api="/employeeTrainings"
      eventSourceId={['EmployeeTrainings']}
      filter={{
        training_batch,
        ...(myMenteesTabs
          ? {
              myMenteesTabs: user?.employee?._id,
            }
          : {}),
      }}
      columns={[
        {
          header: 'Trainee',
          field: 'employee',
          type: 'userAvatarChip',
          minWidth: 150,
        },
        {
          header: 'Billing Status',
          render: RenderAssignmentType,
          width: 120,
        },
        {
          header: 'Trainer',
          field: 'mentor',
          type: 'userAvatar',
          visible: !myMenteesTabs,
        },
        {
          header: 'Batch',
          render: RenderBatch,
          visible: !!myMenteesTabs,
          minWidth: 250,
        },
        {
          header: 'Start',
          field: 'from_date',
          type: 'date',
        },
        {
          render: extendedDateInfoRender,
          header: 'End',
          onIconPress: ({row}) => {
            navigation.navigate('extended_date_details', {
              data: row?.extended_date_details || [],
              header: {
                name: row?.employee?.name,
              },
              training_batch: row.training_batch,
            });
          },
        },
        {
          header: 'Extended Date',
          field: 'extended_date',
          type: 'date',
          width: 150,
          visible: !!isExtended,
        },
        {
          header: 'Next Feedback Date',
          field: 'next_feedback_date',
          type: 'date',
          width: 180,
        },
        {
          render: RenderOverAllScore,
          onPress: ({row}) => {
            props.navigation.navigate('training-feedbacks', {
              ...props.route.params,
              employee_training: row,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Drop',
          confirm: {
            title: 'Drop Trainee',
            message: 'Do you really want to drop this trainee?',
            confirmText: 'Drop',
          },
          onPress: ({row}) => {
            invoke({
              uri: '/employeeTrainings/' + row?._id,
              props: {
                status: 'dropped',
              },
            });
          },
        },
        {
          title: 'Extend',
          onPress: ({row}) => {
            props.navigation.navigate('extend-training', {
              ...props.route.params,
              employee_training: row,
            });
          },
          visible: ({row}) => row?.status !== 'completed',
        },
        {
          title: 'Complete',
          onPress: ({row}) => {
            navigation.navigate('complete-employee-training', {
              employee_training: row,
            });
          },
          visible: ({row}) => row?.status !== 'completed',
        },
        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('training-feedbacks', {
              ...props.route.params,
              employee_training: row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
