import React from 'react';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {IconButton} from '../../../components/button/IconButton';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';

export const EmployeeTurnoverReport = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <Table
      {...props}
      params={filterValues.params}
      variant={'bordered'}
      eventSourceId={['benchCostReport']}
      limit={2000}
      renderHeader={() => (
        <TableHeader
          title="Employee Turnover"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <IconButton
              icon={icons.Download}
              onPress={() => {
                download({
                  uri: '/exportEmployeeTurnOverReport',
                  props: {
                    uri: '/employees/employeeTurnoverReport',
                    parameters: {
                      params: {...filterValues.params},
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      searchFields="name"
      api={`/employees/employeeTurnoverReport`}
      columns={[
        {
          header: 'Type',
          field: 'category',
        },
        {
          render: CountCellRender,
          aggregate: true,
          count_field: 'initial_employees',
          header: 'Opening',
          field: 'initial_employees',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate('employees-list', {
              _id: {$in: row.initial_employees_id},
              api: '/allemployees',
            });
          },
        },
        {
          render: CountCellRender,
          aggregate: true,
          count_field: 'joined_employees',
          align: 'right',
          header: 'Joining',
          field: 'joined_employees',
          onPress: ({row}) => {
            navigation.navigate('employees-list', {
              _id: {$in: row?.joined_employees_id},
              api: '/allemployees',
            });
          },
        },
        {
          render: CountCellRender,
          aggregate: true,
          count_field: 'voluntary_exit_employee',
          align: 'right',
          header: 'Voluntary Exit',
          field: 'voluntary_exit_employee',
          onPress: ({row}) => {
            navigation.navigate('employees-list', {
              _id: {$in: row?.voluntary_exit_employee_id},
              api: '/allemployees',
            });
          },
        },
        {
          render: CountCellRender,
          aggregate: true,
          count_field: 'involuntary_exit_employee',
          align: 'right',
          header: 'Involuntary Exit',
          field: 'involuntary_exit_employee',
          onPress: ({row}) => {
            navigation.navigate('employees-list', {
              _id: {$in: row?.involuntary_exit_employee_id},
              api: '/allemployees',
            });
          },
        },
        {
          render: CountCellRender,
          aggregate: true,
          count_field: 'final_count',
          align: 'right',
          header: 'Closing',
          field: 'final_count',
          onPress: ({row}) => {
            navigation.navigate('employees-list', {
              _id: {$in: row?.final_employee_ids},
              api: '/allemployees',
            });
          },
        },
        {
          header: 'Turnover Rate',
          align: 'right',
          aggregate: true,
          field: 'trunOverRate',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};
