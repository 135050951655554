import React, {useEffect, useState} from 'react';
import {useToast} from '@unthinkable/react-toast';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
} from '../../reimbursement/components/ReimbursementColumnRenders';

import {AddButton} from '../../../components/button/AddButton';
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {employeeController} from '../controllers/employeeController';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';

export const BvgDueThisMonthList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['bvg']}
      searchFields={['name']}
      api={'/background-verification/due-this-month'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} due`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Due on',
          field: 'background_verification_due_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
      ]}
    />
  );
};

export const BvgPendingThisMonthList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <GroupTable
      {...props}
      params={params}
      //   addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['bvg']}
      searchFields={['name']}
      api={'/background-verification/pending-this-month'} //query on backend
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} due`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Due on',
          field: 'background_verification_due_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
      ]}
    />
  );
};

export const BVGReport = props => {
  let {
    navigation,
    route: {params},
    filter = {},
    filterParams = {},
  } = props;
  params = {...params, ...filterParams};
  const colors = useTheme('colors');

  return (
    <Table
      params={params}
      api={`/background-verification/due-this-month`}
      addOnFilter={{...filter}}
      columns={[
        {
          header: 'Month',
          minWidth: 160,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: params?.period?.monthWiseBreakup
                    ? 'Month'
                    : params?.period?.filterOf,
                })}
              </Text>
            );
          },
        },
        {
          header: 'Pending previous month',
          field: 'pending_previous_month_count',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('bvg-report-list', {
              filter: {
                _id: {$in: row.pending_previous_month_ids},
              },
            });
          },
        },
        {
          header: 'Due in current month',
          field: 'due_in_current_month_count',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('bvg-report-list', {
              filter: {
                _id: {$in: row.due_in_current_month_ids},
              },
            });
          },
        },
        {
          header: 'Amber report',
          field: 'amber_report_count',
          type: 'number',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: colors.WARNING_HIGH}}>
                {row?.amber_report_count}
              </Text>
            );
          },
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('bvg-report-list', {
              filter: {
                _id: {$in: row.amber_report_ids},
              },
            });
          },
        },
        {
          header: 'Red report',
          field: 'red_report_count',
          type: 'number',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: colors.ERROR_HIGH}}>
                {row?.red_report_count}
              </Text>
            );
          },
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('bvg-report-list', {
              filter: {
                _id: {$in: row.red_report_ids},
              },
            });
          },
        },
        {
          header: 'Closed',
          field: 'closed_report_count',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('bvg-report-list', {
              filter: {
                _id: {$in: row.closed_report_ids},
              },
            });
          },
        },
        {
          header: 'Pending months end',
          field: 'pending_at_months_end_count',
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('bvg-report-list', {
              filter: {
                _id: {$in: row.pending_at_months_end_ids},
              },
            });
          },
        },
        // {
        //   header: 'Pending',
        //   field: 'pending_count',
        //   type: 'number',
        //   width: 180,
        //   onPress: ({row}) => {
        //     navigation.navigate('bvg-report-list', {
        //       filter: {
        //         _id: {$in: row.pending_ids},
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const BvgList = props => {
  let {
    route: {params},
    navigation,
    filter = {},
    search,
  } = props;

  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {clearTeamNoDues} = employeeController(props);

  return (
    <Table
      searchFields={['name', 'website', 'email']}
      addOnFilter={{...filter}}
      search={search}
      api={'/getBgvResources'} //query on backend
      fields={{
        _id: 1,
        name: 1,
        background_verification_due_date: 1,
        background_verification_completed: 1,
        background_verification_outcome: 1,
        background_verification_completed_date: 1,
        joining_date: 1,
        employee_code: 1,
        color: 1,
        designation: {name: 1},
        reporting_to: {name: 1, color: 1},
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Due on',
          field: 'background_verification_due_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
        },
      ]}
    />
  );
};
