import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddUserSegmentForm = props => {
  const {
    route: {params},
    header = 'Add Batch',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/usersegments',
    eventSourceId: 'segmentAdded',
  });

  return (
    <Form
      fields={{
        name: 1,
        entry_criteria: 1,
        exit_criteria: 1,
        observer: {name: 1},
        owner: {name: 1},
        api: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
        // {
        //   label: 'Entry Criteria',
        //   field: 'entry_criteria',
        //   nested: true,
        //   render: props => (
        //     <NestedTable
        //       {...props}
        //       fields={[
        //         {
        //           field: 'logical_operator',
        //           placeholder: 'Logical Operator',
        //           header: 'Logical Operator',
        //           type: 'autoComplete',
        //           options: ['AND', 'OR'],
        //         },
        //         {
        //           field: 'field',
        //           placeholder: 'Field',
        //           header: 'Field',
        //           type: 'autoComplete',
        //           options: [
        //             'website',
        //             'lead_status',
        //             'uql_stage_date',
        //             'stage',
        //             'sql_stage_date',
        //             'mql_stage_date',
        //             'is_free',
        //           ],
        //         },
        //         {
        //           field: 'op',
        //           placeholder: 'Operation',
        //           header: 'Operation',
        //           type: 'autoComplete',
        //           options: ['eq', 'ne', 'exists', 'gt', 'lt'],
        //         },
        //         {
        //           field: 'value',
        //           placeholder: 'Value',
        //           header: 'Value',
        //           type: 'textArea',
        //         },
        //       ]}
        //     />
        //   ),
        // },
        // {
        //   label: 'Exit Criteria',
        //   field: 'exit_criteria',
        //   nested: true,
        //   render: props => (
        //     <NestedTable
        //       {...props}
        //       fields={[
        //         {
        //           field: 'logical_operator',
        //           placeholder: 'Logical Operator',
        //           header: 'Logical Operator',
        //           type: 'autoComplete',
        //           options: ['AND', 'OR'],
        //         },
        //         {
        //           field: 'field',
        //           placeholder: 'Field',
        //           header: 'Field',
        //           type: 'autoComplete',
        //           options: [
        //             'website',
        //             'lead_status',
        //             'uql_stage_date',
        //             'stage',
        //             'sql_stage_date',
        //             'mql_stage_date',
        //             'is_free',
        //           ],
        //         },
        //         {
        //           field: 'op',
        //           placeholder: 'Operation',
        //           header: 'Operation',
        //           type: 'autoComplete',
        //           options: ['eq', 'ne', 'exists', 'gt', 'lt'],
        //         },
        //         {
        //           field: 'value',
        //           placeholder: 'Value',
        //           header: 'Value',
        //           type: 'textArea',
        //         },
        //       ]}
        //     />
        //   ),
        // },
        // {
        //   label: 'Entry Criteria',
        //   field: 'entry_criteria',
        //   required: true,
        //   nested: true,
        //   render: props => (
        //     <NestedTable
        //       {...props}
        //       columns={[
        //         {
        //           header: 'Country',
        //           field: 'country.name',
        //           type: 'text',
        //         },
        //         {
        //           header: 'Industry',
        //           field: 'industry.name',
        //           type: 'text',
        //         },
        //         {
        //           header: 'Stage',
        //           field: 'stage.name',
        //           type: 'text',
        //         },
        //       ]}
        //       fields={[
        //         {
        //           field: 'country',
        //           placeholder: 'Country',
        //           label: 'Country',
        //           type: 'autoComplete',
        //           api: `/countries`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //         },
        //         {
        //           field: 'industry',
        //           placeholder: 'Industry',
        //           label: 'Industry',
        //           type: 'autoComplete',
        //           api: `/industries`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //         },
        //         {
        //           field: 'stage',
        //           placeholder: 'Stage',
        //           label: 'Stage',
        //           type: 'autoComplete',
        //           api: `/stages`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //         },
        //       ]}
        //     />
        //   ),
        // },
        // {
        //   label: 'Exit Criteria',
        //   field: 'exit_criteria',
        //   nested: true,
        //   render: props => (
        //     <NestedTable
        //       {...props}
        //       columns={[
        //         {
        //           header: 'Country',
        //           field: 'country.name',
        //           type: 'text',
        //         },
        //         {
        //           header: 'Industry',
        //           field: 'industry.name',
        //           type: 'text',
        //         },
        //         {
        //           header: 'Stage',
        //           field: 'stage.name',
        //           type: 'text',
        //         },
        //       ]}
        //       fields={[
        //         {
        //           field: 'country',
        //           placeholder: 'Country',
        //           label: 'Country',
        //           type: 'autoComplete',
        //           api: `/countries`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //         },
        //         {
        //           field: 'industry',
        //           placeholder: 'Industry',
        //           label: 'Industry',
        //           type: 'autoComplete',
        //           api: `/industries`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //         },
        //         {
        //           field: 'stage',
        //           placeholder: 'Stage',
        //           label: 'Stage',
        //           type: 'autoComplete',
        //           api: `/stages`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //         },
        //       ]}
        //     />
        //   ),
        // },
        // {
        //   label: 'Observers',
        //   field: 'observer',
        //   placeholder: 'Assign observer',
        //   type: 'multiAutoComplete',
        //   api: `/employeeSuggestions`,
        //   suggestionField: 'name',
        //   secondarySuggestionField: 'official_email_id',
        //   valueField: 'name',
        // },
        // {
        //   label: 'Owner',
        //   field: 'owner',
        //   placeholder: 'Assign owner',
        //   type: 'multiAutoComplete',
        //   api: `/employeeSuggestions`,
        //   suggestionField: 'name',
        //   secondarySuggestionField: 'official_email_id',
        //   valueField: 'name',
        // },
        {
          label: 'Api',
          field: 'api',
          placeholder: 'Select',
          type: 'autoComplete',
          options: ['Active Leads Unthinkable', 'Active Leads Daffodil'],
        },
      ]}
      {...props}
    />
  );
};

export const EditUserSegmentForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <AddUserSegmentForm
      mode="edit"
      header={'Edit Batch'}
      api={`/usersegments/${_id}`}
      {...props}
    />
  );
};
