import DepartmentTable from '../department/views/DepartmentTable';
import {FinancialYearTable} from './views/FinancialYearTable';
import {OfficeTable} from './views/OfficeTable';
import {OrganizationTable} from './views/OrganizationTable';
import ProductTable from './views/ProductTable';
import DesignationTable from '../department/views/DesignationTable';

import {SlowQueryView} from '../logs/views/SlowQueryView';
import {UserOrganizationTable} from './views/UserOrganizationTable';
import DepartmentTab from '../department/views/DepartmentTabs';
import {NonEnggTeams} from '../employee/views/NonEnggTeams';
import {SkillTypesTable} from './views/SkillTypesTable';
import {KeyPillarTable} from './views/KeyPillarTable';
import {BlogsTable} from './views/BlogsTable';
import {TestimonialsTable} from './views/TestimonialsTable';
import {LeadCaptureTable} from './views/LeadCaptureTable';
import {UnthinkablePages} from './views/UnthinkablePages';
import {DomainTable} from './views/DomainTable';
import {FeatureSuggestionTable} from './views/FeatureSuggestionTables';
import {PodcastsTable} from './views/PodcastTable';
import {CaseStudyTable} from './views/CaseStudyTable';
import {OrganizationThreadTable} from '../task/views/OrganizationThreadTable';
import {OrganizationGoalTable} from '../task/views/OrganizationGoalTable';

export const ScreenMapping = [
  {
    id: 'organization',
    view: props => {
      return <OrganizationTable {...props} />;
    },
  },
  // {
  //   id: 'userorganization',
  //   view: props => {
  //     return <UserOrganizationTable {...props} />;
  //   },
  // },
  {
    id: 'product',
    view: props => {
      return <ProductTable {...props} />;
    },
  },
  {
    id: 'department',
    view: props => {
      return <DepartmentTab {...props} />;
    },
  },
  {
    id: 'financialyear',
    view: props => {
      return <FinancialYearTable {...props} />;
    },
  },
  {
    id: 'office',
    view: props => {
      return <OfficeTable {...props} />;
    },
  },
  {
    id: 'designation',
    view: props => {
      return <DesignationTable {...props} />;
    },
  },

  {
    id: 'slowquery',
    view: props => {
      return <SlowQueryView {...props} />;
    },
  },
  {
    id: 'departmentWiseTeam',
    view: props => {
      return (
        <NonEnggTeams key="task" departmentTitle="Department Wise" {...props} />
      );
    },
  },
  {
    id: 'skilltypes',
    view: props => {
      return <SkillTypesTable {...props} />;
    },
  },
  {
    id: 'KeyPillar',
    view: <KeyPillarTable />,
  },
  {
    id: 'unthinkableBlog',
    view: <BlogsTable />,
  },
  {
    id: 'unthinkableTestimonials',
    view: <TestimonialsTable />,
  },
  {
    id: 'unthinkableContacts',
    view: <LeadCaptureTable />,
  },
  {
    id: 'featuresuggestions',
    view: <FeatureSuggestionTable />,
  },
  {
    id: 'unthinkablePages',
    view: <UnthinkablePages />,
  },
  {
    id: 'unthinkableDomains',
    view: <DomainTable />,
  },
  {
    id: 'unthinkablePodcast',
    view: <PodcastsTable />,
  },
  {
    id: 'unthinkableCaseStudy',
    view: <CaseStudyTable />,
  },
  {
    id: 'organizationgoals',
    view: <OrganizationGoalTable />,
  },
  {
    id: 'organizationthreads',
    view: <OrganizationThreadTable />,
  },
];

// export const Screens = [
//   {
//     menuItemId: 'organizations',
//     views: [
//       {
//         id: 'organization',
//         label: 'Organization',
//         view: props => {
//           return <OrganizationTable {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'products',
//     views: [
//       {
//         id: 'product',
//         label: 'Product',
//         view: props => {
//           return <ProductTable {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'departments',
//     views: [
//       {
//         id: 'department',
//         label: 'Department',
//         view: props => {
//           return <DepartmentTable {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'financialyears',
//     views: [
//       {
//         id: 'financialyear',
//         label: 'Financial Year',
//         view: props => {
//           return <FinancialYearTable {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'offices',
//     views: [
//       {
//         id: 'office',
//         label: 'Office Locations',
//         view: props => {
//           return <OfficeTable {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'designations',
//     views: [
//       {
//         id: 'designation',
//         label: 'Designation',
//         view: props => {
//           return <DesignationTable {...props} />;
//         },
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'organizations',
//     label: 'Organizations',
//   },
//   {
//     id: 'products',
//     label: 'Products',
//   },
//   {
//     id: 'departments',
//     label: 'Departments',
//   },
//   {
//     id: 'financialyears',
//     label: 'Financial Years',
//   },
//   {
//     id: 'offices',
//     label: 'Office Locations',
//   },
//   {
//     id: 'designations',
//     label: 'Designations',
//   },
// ];
