import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ParameterizedDeploymentForm = props => {
  let {
    route: {params},
  } = props;
  let {environment} = params;

  if (
    !Array.isArray(environment?.jenkins_parameters) ||
    !environment?.jenkins_parameters?.length
  ) {
    return;
  }

  let defaultValues = {};

  environment?.jenkins_parameters?.forEach(param => {
    defaultValues[param.key] = param.value;
  });

  let layoutFields = [];

  environment?.jenkins_parameters?.forEach(param => {
    layoutFields.push({
      label: param.key,
      type: 'text',
      field: param.key,
      required: true,
    });
  });

  let {onSubmit} = useFormSubmit({
    uri: `/repositoryEnvironments/${environment._id}/deployOnJenkin`,
    eventSourceId: 'Deployment',
  });

  return (
    <Form
      defaultValues={defaultValues}
      header={'Parameters'}
      onSubmit={onSubmit}
      layoutFields={layoutFields}
    />
  );
};
