import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const SelfCheckListForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/selfchecklists',
    eventSourceId: 'selfCheckListAdded',
  });
  return (
    <Form
      api={`/selfchecklists/${row?._id}`}
      fields={{
        name: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const AddSelfCheckList = props => {
  return <SelfCheckListForm header={'Add Self-CheckList'} {...props} />;
};

export const EditSelfCheckList = props => {
  return (
    <SelfCheckListForm header={'Edit Self-CheckList'} mode="edit" {...props} />
  );
};
