import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const DebitNoteForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['VendorInvoiceView'],
    throwError: true
  });

  return (
    <Form
      api={`/vendorInvoice/${row?._id}`}
      mode="edit"
      onSubmit={async data => {
        await invoke({
          uri: `/vendor/debit-note/${row._id}`,
          props: {
            ...data,
          },
        });
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Invoice No',
          field: 'invoice_no',
          type: 'text',
          size: 3,
          readOnly: true,
        },
        {
          label: 'Description',
          field: 'purpose',
          type: 'text',
          size: 3,
          readOnly: true,
        },
        {
          label: 'Invoice Amount',
          field: 'payable_amount',
          type: 'number',
          size: 3,
          readOnly: true,
        },
        {
          label: 'Revised Amount',
          field: 'pending_amount',
          type: 'number',
          size: 3,
          readOnly: true,
        },
        {
          label: 'Debit Account',
          field: 'vendor_account',
          type: 'autoComplete',
          api: '/accounts',
          suggestionField: 'name',
          valueField: 'name',
          size: 4,
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          size: 4,
        },
        {
          label: 'Amount',
          field: 'other_amount',
          type: 'number',
          size: 4,
        },
        {
          label: 'Remark',
          field: 'remarks',
          type: 'text',
          size: 6,
        },
        {
          field: 'document',
          label: 'Invoice',
          type: 'file',
          multiple: true,
          size: 6,
          options: {bucketName: 'manaze'},
        },
      ]}
      {...props}
    />
  );
};

export const AddDebitNoteForm = props => {
  return <DebitNoteForm header="Add Debit Note" {...props} />;
};
