import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateProjectOnSkillRequirementForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/skillrequirments',
    eventSourceId: ['skillRequirement'],
  });

  return (
    <Form
      mode="edit"
      api={`/skillrequirments/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      header={{
        title: 'Update Project',
      }}
      fields={{
        project: {_id: 1, project: 1},
      }}
      layoutFields={[
        {
          label: 'Project',
          placeholder: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          filter: {
            project_status: 'a',
            customer: row?.order?.customer_id,
          },
          suggestionField: 'project',
          valueField: 'project',
          required: true,
        },
      ]}
    />
  );
};
