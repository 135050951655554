import {
  ActivityIndicator,
  Text,
  View,
} from '@unthinkable/react-core-components';
import moment from 'moment';
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';
import {useAppStateContext} from '../../../providers/AppState';

const RenderStatus = ({row, styles, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/automaticpayments/${row?._id}`,
                props: {
                  status: 'Approved',
                  approved_by_head_date: new Date(),
                  approver_status: 'Finance',
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Approve </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              navigation.navigate('reject-automatic-payment-request', {row});
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </View>
      )}
    </>
  );
};

export const AutomaticPaymentRequesterTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  return (
    <Table
      eventSourceId={['budget']}
      api={`/automaticpayment/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      onRowPress={({row}) => {
        if (tab === 'Approved' || tab === 'Rejected') {
          navigation.navigate('edit-automatic-payment-request', {
            row,
            readOnly: true,
          });
        } else {
          navigation.navigate('edit-automatic-payment-request', {row});
        }
      }}
      columns={[
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Term',
          field: 'term',
          type: 'text',
          width: 100,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 200,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.amount}
                currency={row?.currency?.currency}
              />
            );
          },
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        tab === 'Requested' && {
          header: 'Pending by',
          field: 'approver_status',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
          align: 'center',
        },
        {
          header: 'Head Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Active') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_head_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Finance Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Active') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_finance_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Rejected',
          type: 'date',
          visible: ({values}) => {
            if (tab === 'Rejected') return true;
          },
          render: ({row, styles}) => {
            return (
              <View>
                <Text style={{...styles.rowText, color: '#FF3B30'}}>
                  {moment(row.rejected_date).format('DD MMM YY')}
                </Text>
                <Text style={{...styles.rowText, color: '#737373'}}>
                  {row.reject_reason}
                </Text>
              </View>
            );
          },
          width: 200,
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: ({row}) => {
            invoke({
              uri: `/automaticpayments/delete/${row._id}`,
              props: {
                ...row,
              },
            });
          },
        },
        tab === 'Active' && {
          title: 'Stop Payment',
          onPress: ({row}) => {
            invoke({
              uri: `/automaticpayments/${row._id}`,
              props: {
                status: 'Inactive',
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const AutomaticPaymentApproverTable = props => {
  const {navigation, tab, addOnFilter, orgView} = props;
  let {
    user: {employee},
  } = useAppStateContext();
  let filter = {approver: employee._id};
  if (orgView) {
    filter = {};
  }
  const UpdateInvoke = useInvoke({
    method: 'put',
    eventSourceId: ['budget'],
  });
  return (
    <Table
      eventSourceId={['budget']}
      api={`/automaticpayment/details`}
      addOnFilter={{...addOnFilter, ...filter}}
      onRowPress={({row}) => {
        navigation.navigate('edit-automatic-payment-request', {
          row,
          readOnly: true,
        });
      }}
      columns={[
        {
          header: 'Requested On',
          field: 'request_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Term',
          field: 'term',
          type: 'text',
          width: 100,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.amount}
                currency={row?.currency?.currency}
              />
            );
          },
        },
        {
          header: 'Duration',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          align: 'center',
          width: 200,
        },

        {
          header: 'Requester',
          field: 'requested_by',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Action',
          visible: ({}) => {
            if (tab === 'ApproverActive') return true;
          },
          align: 'center',

          render: ({row, styles}) => {
            return (
              <RenderStatus row={row} styles={styles} navigation={navigation} />
            );
          },
        },
        {
          header: 'Status',
          visible: ({}) => {
            if (tab === 'ApproverClosed') return true;
          },
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (
              row?.status === 'Approved' ||
              row?.status == 'Active' ||
              row?.status == 'Inactive'
            ) {
              color = '#34C759';
              status = 'Approved';
            } else if (row?.status === 'Rejected') {
              color = '#FF5247';
              status = 'Rejected';
            }
            return (
              <Text style={{...styles.rowText, color: color}}>{status}</Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
        {
          title: 'Approve',
          onPress: ({row}) => {
            UpdateInvoke({
              uri: `/automaticpayments/${row._id}`,
              props: {
                status: 'Approve',
              },
            });
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            UpdateInvoke({
              uri: `/automaticpayments/${row._id}`,
              props: {
                status: 'Rejected',
              },
            });
          },
        },
      ]}
    />
  );
};

export const AutomaticPaymentFinanceApproverTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });

  return (
    <Table
      eventSourceId={['budget']}
      api={`/automaticpayment/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      // onRowPress={({row}) => {
      //   navigation.navigate('edit-automatic-payment-request', {row});
      // }}
      columns={[
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Term',
          field: 'term',
          type: 'text',
          minwidth: 200,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 200,
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        {
          header: 'Status',
          visible: ({}) => {
            if (tab === 'ApproverClosed') return true;
          },
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (
              row?.status === 'Approved' ||
              row?.status == 'Active' ||
              row?.status == 'Inactive'
            ) {
              color = '#34C759';
              status = 'Approved';
            } else if (row?.status === 'Rejected') {
              color = '#FF5247';
              status = 'Rejected';
            }
            return (
              <Text style={{...styles.rowText, color: color}}>{status}</Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
        tab != 'ApproverClosed' && {
          title: 'Add Automatic Payment',
          // onPress: ({row}) => {
          //   invoke({
          //     uri: `/automaticpayments/${row._id}`,
          //     props: {
          //       status: 'Active',
          //       approved_by_finance_date: new Date(),
          //     },
          //   });
          // },
          onPress: ({row}) => {
            navigation.navigate('add-payment-card', {row});
          },
        },
        tab != 'ApproverClosed' && {
          title: 'Reject Automatic Payment',
          onPress: ({row}) => {
            invoke({
              uri: `/automaticpayments/${row._id}`,
              props: {
                status: 'Rejected',
                rejected_by_finance_date: new Date(),
              },
            });
          },
        },
      ]}
    />
  );
};
