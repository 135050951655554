// List to show the country of potential customer. - Rajnish Trikaldarshi 15-03-2024
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';

export const CountryList = props => {
  let {params, navigation} = props || {};

  const {user} = useAppStateContext();
  let {email} = user || {};
  // const showAction =
  //   email === 'rohit.bansal@daffodilsw.com' ||
  //   email === 'kanika.kashyap@unthinkable.co' ||
  //   email === 'sushil.nagvan@daffodilsw.com';

  return (
    <Table
      api={`/potentialCustomers/countryData`}
      eventSourceId={['mergeCountryData']}
      sort={{createdAt: -1}}
      limit={-1}
      columns={[
        {
          header: 'Country Code',
          type: 'text',
          field: 'country_code',
        },
        {
          header: 'Country Name',
          type: 'text',
          field: 'country',
        },
        {
          header: 'Count',
          type: 'text',
          field: 'count',
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Select',
          onPress: ({row}) => {
            navigation.navigate(`select-country-to-merge`, {
              row,
            });
          },
        },
      ]}
    />
  );
};
