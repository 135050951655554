import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

export const LeadKpiTable = props => {
  let {
    route: {params},
  } = props || {};
  let {outCome} = params || {};
  let employeeData = outCome?.opportunityId || [];
  let resourceType = outCome?.ResourceType || 'FrontFace';
  return (
    <Table
      eventSourceId={'LeadKpiTable'}
      // renderHeader={() => (
      //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
      // )}
      data={employeeData}
      columns={[
        {
          field: 'name',
          header: 'Employee',
          responsive: 'sm',
          type: 'text',
        },
        {
          field: 'tech_lead_name',
          header: `${resourceType}`,
          responsive: 'sm',
          type: 'text',
        },
        {
          field: 'project_name',
          header: 'Project',
          responsive: 'sm',
          type: 'text',
        },
        {
          field: 'from_date',
          header: 'Start Date',
          responsive: 'sm',
          type: 'date',
          align: 'right',
        },
        {
          field: 'to_date',
          header: 'End Date',
          responsive: 'sm',
          type: 'date',
          align: 'right',
        },
        {
          field: 'hours',
          header: 'Delivered',
          responsive: 'sm',
          type: 'text',
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export const LeadKpiDetailTable = props => {
  let {
    route: {params},
  } = props || {};
  let {outCome, skillLeader, api} = params || {};
  const {api: variableApi} = api;
  let employeeData = outCome?.opportunityId || [];
  let resourceType = outCome?.ResourceType || 'FrontFace';
  return (
    <Table
      api={variableApi ? variableApi : '/anchor-resource-variable'}
      params={{period: params?.period, detailView: true}}
      addOnFilter={{skill_leader: {_id: skillLeader}}}
      eventSourceId={'LeadKpiTable'}
      // renderHeader={() => (
      //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
      // )}
      // data={employeeData}
      columns={[
        {
          header: 'Project',
          field: 'project',
          minWidth: 200,
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.project?.project || ''}
                items={[
                  {
                    value: row?.delivery_id?.delivery_number || '',
                  },
                  {
                    value: row?.CSAT || '',
                  },
                  {
                    value: row?.employee?.resource_language?.name || '',
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'employee.name',
          header: 'Employee',
          responsive: 'sm',
          type: 'text',
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.employee?.name || ''}
                items={[
                  {
                    value: `${row?.employee?.experience} yrs` || '',
                  },
                ]}
              />
            );
          },
        },
        // {
        //   field: 'tech_lead_name',
        //   header: `${resourceType}`,
        //   responsive: 'sm',
        //   type: 'text',
        // },
        {
          field: 'variable_potential',
          header: 'Potential',
          responsive: 'sm',
          type: 'text',
          align: 'center',
        },
        {
          field: 'deliveredHours',
          header: 'Delivered',
          responsive: 'sm',
          type: 'text',
          align: 'center',
        },
        {
          field: 'amount',
          header: 'Amount',
          responsive: 'sm',
          type: 'number',
          align: 'center',
          aggregate: true,
        },
      ]}
      {...props}
    />
  );
};

// export const SelfKpiDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/self-resource-variable'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//         },
//       ]}
//       {...props}
//     />
//   );
// };

// export const SkillLeadDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/skill-lead-resource-variable'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//         },
//       ]}
//       {...props}
//     />
//   );
// };

// export const ResourceManagerDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/resource-manager-variable'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//         },
//       ]}
//       {...props}
//     />
//   );
// };

// export const SharingWithSkillManagerDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/sharing-with-skill-manager-tm'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//         },
//       ]}
//       {...props}
//     />
//   );
// };

// export const ModuleLeadVariableTmDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/module-lead-variable-tm'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//           aggregate: true,
//         },
//       ]}
//       {...props}
//     />
//   );
// };

// export const ModuleLeadVariableFixedDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/module-lead-variable-fixed'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//           aggregate: true,
//         },
//       ]}
//       {...props}
//     />
//   );
// };

// export const SharingWithSkillManagerFixedDetailTable = props => {
//   let {
//     route: {params},
//   } = props || {};
//   let {outCome, skillLeader} = params || {};
//   let employeeData = outCome?.opportunityId || [];
//   let resourceType = outCome?.ResourceType || 'FrontFace';
//   return (
//     <Table
//       api={'/sharing-with-skill-manager-fixed'}
//       params={{period: params?.period, detailView: true}}
//       addOnFilter={{skill_leader: {_id: skillLeader}}}
//       eventSourceId={'LeadKpiTable'}
//       // renderHeader={() => (
//       //   <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
//       // )}
//       // data={employeeData}
//       columns={[
//         {
//           header: 'Project',
//           field: 'project.project',
//           minWidth: 200,
//           render: ({row, styles}) => {
//             return (
//               <PrimaryColumnCard
//                 primaryTitle={row?.project.project || ''}
//                 items={[
//                   {
//                     value: row?.delivery_id.delivery_number || '',
//                   },
//                   {
//                     value: row?.CSAT || '',
//                   },
//                 ]}
//               />
//             );
//           },
//         },
//         {
//           field: 'employee.name',
//           header: 'Employee',
//           responsive: 'sm',
//           type: 'text',
//         },
//         // {
//         //   field: 'tech_lead_name',
//         //   header: `${resourceType}`,
//         //   responsive: 'sm',
//         //   type: 'text',
//         // },
//         {
//           field: 'variable_potential',
//           header: 'Potential',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'deliveredHours',
//           header: 'Delivered',
//           responsive: 'sm',
//           type: 'text',
//           align: 'center',
//         },
//         {
//           field: 'amount',
//           header: 'Amount',
//           responsive: 'sm',
//           type: 'number',
//           align: 'center',
//           aggregate: true,
//         },
//       ]}
//       {...props}
//     />
//   );
// };
