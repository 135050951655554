import React, {forwardRef} from 'react';
import SelectorList from './SelectorList';
import {
  CascadeHeaderRow,
  CascadeBackIconContainer,
  CascadeBackIcon,
  CascadeHeaderText,
} from './Styles';
import {getCascaderDisplayValue, getValueToDisplay} from './Utility';
import CaretLeft from '../images/CaretLeft.svg';
import {AddNewAction} from './Actions';
import {useCascader} from './hooks/useCascader';
import BaseAutoComplete from './BaseAutocomplete';


const CascadeHeader = ({
  styles,
  cascades,
  currentIndex,
  currentCascade,
  selectedValues,
  onBack,
  onCreate,
  ...restProps
}) => {
  const prevCascade = cascades[currentIndex - 1];
  const {valueField, suggestionField, keyField, options} = prevCascade || {};
  const prevCascadeValue = selectedValues?.[currentIndex - 1];

  return (
    <CascadeHeaderRow styles={styles}>
      {currentIndex === 0 ? (
        void 0
      ) : (
        <CascadeBackIconContainer onPress={onBack} styles={styles}>
          <CascadeBackIcon source={CaretLeft} styles={styles} />
        </CascadeBackIconContainer>
      )}
      <CascadeHeaderText styles={styles}>
        {currentIndex === 0
          ? currentCascade?.label
          : `Choose ${currentCascade?.label} for ${prevCascade?.label} (${getValueToDisplay({
              value: prevCascadeValue,
              valueField: valueField || suggestionField,
              keyField,
              options,
            })})`}
      </CascadeHeaderText>
      {onCreate ? (
        <AddNewAction
          {...restProps}
          styles={styles}
          item={prevCascadeValue}
          onCreate={onCreate}
        />
      ) : (
        void 0
      )}
    </CascadeHeaderRow>
  );
};

const Cascader = (props, ref) => {
  props = useCascader({
    ...props,
    ref,
    searchingInModal: true,
  });

  const {
    styles = {},
    values,
    cascades = [],
    currentIndex,
    currentCascade,
    selectedValues,
    onBack,
    cascadeExtraProps,
    searching,
    onCreate,
  } = props;

  const renderHeader = headerProps => {
    return (
      <CascadeHeader
        {...headerProps}
        onCreate={onCreate}
        styles={styles}
        cascades={cascades}
        currentIndex={currentIndex}
        currentCascade={currentCascade}
        selectedValues={selectedValues}
        onBack={onBack}
      />
    );
  };

  const renderModal = () => {
    return (
      <SelectorList
        styles={styles}
        key={currentIndex}
        {...props}
        {...currentCascade}
        {...cascadeExtraProps}
        isCascade={currentIndex < cascades.length - 1}
        searching={searching}
        renderHeader={renderHeader}
      />
    );
  };
  return (
    <BaseAutoComplete
      {...props}
      renderModalHeader={renderHeader}
      renderModal={renderModal}
      getDisplayValue={() => getCascaderDisplayValue({values, cascades})}
    />
  );
};

export default forwardRef(Cascader);
