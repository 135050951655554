import React from 'react';
import {useFetchData} from '../../../controllers/useFetchData';
import Card from '../../pmt/cards/Card';
import {ScrollView} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {Grid, GridItem} from '@unthinkable/react-layout';
import {DescCard} from '../component/DescCard';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {useInvoke} from '../../../controllers/useInvoke';

const ProjectAboutTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {
    user: {email, userType, org, externalUser},
  } = useAppStateContext();

  let invoke = useInvoke({method: 'put', eventSourceId: 'Project'});

  const {data = {}} = useFetchData({
    api: `/projects`,
    eventSourceId: [
      'Project',
      'DeploymentEnvironment',
      'DeploymentPlatform',
      'VersionControl',
      'Repository',
      'ProjectDatabase',
    ],
    filter: {_id: params?.project?._id},
    fields: {
      deviceTypes: 1,
      project: 1,
      desc: 1,
      project_group: 1,
      is_review_mandatory: 1,
      database_count: {count: 1},
      version_control_count: {count: 1},
      deployment_platform_count: {count: 1},
      cloud_infra_count: {count: 1},
    },
    only: true,
  });

  const {data: apiConfigurationData = {}} = useFetchData({
    api: `/apiconfigurations`,
    eventSourceId: 'Swagger',
    filter: {project_id: params?.project?._id},
    only: true,
  });

  const cardDetails = [
    {
      size: 12,
      name: 'Members',
      subheading: 'View members',
      icon: 'RolesMembersIcon',
      iconBackgroundColor: 'ACCENT1_MEDIUM',
      onPress: () => {
        navigation.navigate('project-members', {...params});
      },
      visible:
        userType === 'employee' &&
        (data?.project_group === 'Testing' ||
          data?.project_group === 'Product' ||
          email === 'amit.singh@daffodildb.com' ||
          email === 'rohit.bansal@daffodilsw.com' ||
          email === 'sachin.bansal@daffodilsw.com' ||
          email === 'sushil.nagvan@daffodilsw.com'),
    },
    {
      size: 12,
      name: 'Asset & auto-task assignment',
      subheading: 'Setup team and reviewers for asset',
      icon: 'RolesConfiguration',
      iconBackgroundColor: 'ACCENT3_MEDIUM',
      onPress: () => {
        navigation.navigate('pmt-task-assignment-table', {
          ...params,
          secondaryTitle: params.project?.project,
        });
      },
    },
    {
      size: 12,
      name: 'Make task reviews compulsory to release feature',
      subheading: 'Mandate reviewing for all tasks within a feature',
      visible: !externalUser,
      icon: 'TickReviewGreen',
      iconBackgroundColor: 'BRAND_UPPER_LOW',
      actions: [
        <Switch
          value={data?.is_review_mandatory}
          onChangeValue={async () =>
            await invoke({
              uri: `/projects/${params?.project?._id}`,
              props: {
                is_review_mandatory: !data?.is_review_mandatory,
              },
            })
          }
        />,
      ],
    },
    {
      name: 'Supporting devices for design',
      subheading: 'Product designer will provide designs for enabled devices',
      icon: 'DeviceSetup',
      iconBackgroundColor: 'WARNING_MEDIUM',
      onPress: () => {
        navigation.navigate('supported-devices', {...params});
      },
      blueTick: data?.deviceTypes?.length,
    },
    // {
    //   name: 'API Configuration & Swagger Environments',
    //   subheading: 'Api configuration & swagger environments setup',
    //   icon: 'ShapeIcon',
    // iconBackgroundColor: 'WARNING_MEDIUM',
    //   onPress: () => {
    //     if (apiConfigurationData?._id) {
    //       navigation.navigate('edit-api-configuration-form', {
    //         row: apiConfigurationData,
    //         ...params,
    //       });
    //     } else {
    //       navigation.navigate('add-api-configuration-form', {...params});
    //     }
    //   },
    //   blueTick: apiConfigurationData?.swagger_environments?.length,
    // },
    {
      size: 12,
      name: 'Databases',
      subheading: 'Configure databases',
      icon: 'Environment',
      iconBackgroundColor: 'ACCENT1_MEDIUM',
      onPress: () => {
        navigation.navigate('project-databases', {...params});
      },
      count: data?.database_count,
    },
    {
      name: 'Version Control System',
      subheading: 'VCS and workspace setup',
      icon: 'VersionControls',
      iconBackgroundColor: 'ACCENT2_MEDIUM',
      onPress: () => {
        navigation.navigate('project-vcs', {...params});
      },
      count: data?.version_control_count,
    },
    {
      name: 'CI/CD Platforms',
      subheading: 'Configure deployment platforms',
      icon: 'Environment',
      iconBackgroundColor: 'ACCENT1_MEDIUM',
      onPress: () => {
        navigation.navigate('deployment-platforms', {...params});
      },
      count: data?.deployment_platform_count,
    },
    {
      name: 'Cloud Infrastructure Configuration',
      subheading: 'Configure cloud configurations',
      visible: !externalUser,
      icon: 'Environment',
      iconBackgroundColor: 'ACCENT1_MEDIUM',
      onPress: () => {
        navigation.navigate('cloud-infra-configuration', {...params});
      },
      count: data?.cloud_infra_count,
    },
    {
      name: 'Generate Swagger Docs',
      subheading: 'Generate Swagger for project APIS',
      visible: !externalUser,
      icon: 'Environment',
      iconBackgroundColor: 'ACCENT1_MEDIUM',
      onPress: () => {
        navigation.navigate('swagger-config', {
          ...params,
          fromProject: true,
        });
      },
    },
    {
      name: 'Jira Configuration',
      subheading: 'Configure Jira Credential',
      visible: !externalUser,
      icon: 'JiraIcon',
      iconBackgroundColor: 'SECONDARY_MEDIUM',
      onPress: () => {
        navigation.navigate('project-jira-credential-table', {...params});
      },
    },
  ];
  return (
    <ScrollView>
      <Grid columns={12} colGap={8} rowGap={8}>
        {/* <GridItem size={12}>
          <DescCard title="Description" data={data} />
        </GridItem> */}
        {cardDetails.map(item => {
          let {
            name,
            subheading,
            onPress,
            icon,
            iconBackgroundColor,
            visible = true,
            size,
            blueTick,
            count,
            actions,
          } = item || {};
          if (!visible) {
            return;
          }
          return (
            <GridItem size={size}>
              <Card
                name={name}
                subheading={subheading}
                onPress={onPress}
                icon={icon}
                iconBackgroundColor={iconBackgroundColor}
                blueTick={blueTick}
                count={count}
                actions={actions}
              />
            </GridItem>
          );
        })}
      </Grid>
    </ScrollView>
  );
};

export default ProjectAboutTable;
