import {StyleSheet, Text, View} from '@unthinkable/react-core-components';
import {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';

const styles = StyleSheet.create({
  card: {
    margin: 10,
    height: 150,
    padding: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#EEEEED',
    transition: 'box-shadow 0.3s ease',
    boxShadow: 'none',
  },
  cardHovered: {
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.1)',
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  amountContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flex: 1,
    overflow: 'hidden',
  },
  amount: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const Card = props => {
  const {name, amount, navigation, row, component_key, tab, readOnly, self} =
    props;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <View
      style={[styles.card, isHovered && styles.cardHovered]}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={() => {
        navigation.navigate('tax-declaration-forms', {
          row,
          component_key,
          name,
          tab,
          readOnly,
          self,
        });
      }}>
      <Text style={styles.name} numberOfLines={1}>
        {name}
      </Text>
      <View style={styles.amountContainer}>
        <Text style={styles.amount}>{amount}</Text>
        <Text style={styles.amount}>Amount</Text>
      </View>
    </View>
  );
};

export const TaxDeclarationCard = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row, tab, readOnly, self} = params;

  let {data: incomeTaxResult} = useFetchData({
    api: `/incometax/card-detail/${row._id}`,
  });
  if (!incomeTaxResult) {
    return;
  }

  return (
    <Table
      data={incomeTaxResult}
      numColumns={3}
      renderRow={({row: item, index}) => (
        <Card
          component_key={item?._id}
          name={item?.name}
          amount={item?.actualAmount}
          navigation={navigation}
          row={row}
          index={index}
          tab={tab}
          readOnly={readOnly}
          self={self}
        />
      )}
    />
  );

  // return (
  // <ScrollView
  //   style={{
  //     flex: 1,
  //   }}>
  //   <Grid columns={3} colGap={30} rowGap={30}>
  //     {incomeTaxResult.map(item => (
  //       <GridItem size={1}>
  //         <Card
  //           component_key={item?._id}
  //           name={item?.name}
  //           amount={item?.actualAmount}
  //           navigation={navigation}
  //           row={row}
  //         />
  //       </GridItem>
  //     ))}
  //   </Grid>
  // </ScrollView>
  // );
};
