import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const EmployeeSkillMetricsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {addOnFilter} = params;
  return (
    <Table
      api={`/skill-metric-with-aggregates`}
      eventSourceId={['skillMetricsAdded', 'Task']}
      addOnFilter={{...addOnFilter}}
      params={params}
      columns={[
        {
          field: 'name',
          header: 'Skill',
        },
        {
          render: CountCellRender,
          count_field: 'task_count',
          header: 'Tasks',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate('tasks-table', {
              ...params,
              source_id: row?._id,
              assigned_to: params?._id,
              skill_metrics: {_id: row?._id, name: row?.name},
              label: row?.name,
            });
          },
        },
      ]}
    />
  );
};

export default EmployeeSkillMetricsTable;
