import React, {useEffect} from 'react';
import {Chat} from '../../../app-components/renders/chat/Chat';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';
import {useEventHandler} from '@unthinkable/react-event-handler';

export const NewChat = props => {
  const {
    route: {
      params: {row, chatId, source},
    },
  } = props;

  let chatUri = `/source/${source}/sourceId/${row._id}/getChats`;
  let markReadUri = `/chats/source/${source}/sourceId/${row._id}/markRead`;
  if (chatId) {
    chatUri = `/chats/${chatId}/messages`;
    markReadUri = `/chats/${chatId}/markRead`;
  }

  let {data} = useFetchData({
    api: chatUri,
    eventSourceId: ['MsgAdded'],
    filter: {},
  });

  let {data: readData} = useFetchData({
    api: markReadUri,
    filter: {},
  });

  const {notify} = useEventHandler();

  useEffect(() => {
    if (readData?.length) notify('dataUpdated', {id: 'TriggerOnData'});
  }, [readData]);

  let invoke = useInvoke({method: 'post', eventSourceId: 'MsgAdded'});
  let sendUri = `/source/${source}/sourceId/${row._id}/postChats`;
  if (chatId) sendUri = `/chat/${chatId}/addChatMessages`;
  const onSend = async ({message, mentions}) => {
    let resp = await invoke({
      uri: sendUri,
      props: {
        message,
        mentions,
      },
    });
  };

  return <Chat data={data} timeField="createdAt" onSend={onSend} />;
};
