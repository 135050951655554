import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {TextRenderer} from '../../../components/form/Renderers';
import {Tag} from '../../../components/chip/Chip';
import {useAppStateContext} from '../../../providers/AppState';

const RenderStatus = props => {
  const {
    row: {present_count = 0, absent_count = 0, total_count = 0},
  } = props;

  // if (present_count + absent_count == total_count) {
  //   return <Tag {...props} value={'Marked'} color={'SUCCESS'} textColor={'#34C759'}/>;
  // }

  return (
    <TextRenderer
      {...props}
      value={'Mark'}
      style={{
        color: '#007AFF',
      }}
    />
  );
};
const RenderPresentCount = props => {
  return <TextRenderer {...props} style={{color: '#34C759'}} />;
};

const RenderAbsentCount = props => {
  return <TextRenderer {...props} style={{color: '#FF3B30'}} />;
};

export const DayWiseAttendance = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_batch,employee} = params;
  // const {
  //   user: {employee},
  // } = useAppStateContext();
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Attendance"
          secondaryTitle={
            training_batch?.name + ' - Batch ' + training_batch.batch_number
          }
        />
      )}
      api="/getDayWiseAttendance"
      filter={{
        training_batch: training_batch._id,
      }}
      eventSourceId={'TrainingAttendance'}
      onRowPress={({row}) =>
        navigation.navigate('mark-training-batch-attendance', {
          training_batch,
          addOnFilter: {
            training_batch: training_batch._id,
            mentor: employee?._id,
            date: row.date,
          },
        })
      }
      columns={[
        {
          header:'Session',
          field: 'training_session.name',
          type: 'text',
          visible:training_batch.mentorship_type == 'Session-wise Trainers',
        },
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          // width: 300,
        },
        {
          render: RenderStatus,
          header: 'Status',
          width: 110,
        },
        {
          header: 'Total',
          field: 'total_count',
          type: 'number',
        },
        {
          header: 'Present',
          field: 'present_count',
          type: 'number',
          render: RenderPresentCount,
        },
        {
          header: 'Absent',
          field: 'absent_count',
          type: 'number',
          render: RenderAbsentCount,
        },
      ]}
    />
  );
};
