import React, {useState} from 'react';
import {Form} from '../../../../components/form/Form';
import {
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_VIEW_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';
import {SubmitButton} from '@unthinkable/react-form';
import {Button} from '../../../../components/button/Button';
import {useInvoke} from '../../../../controllers/useInvoke';

const bucketName = process.env.REACT_APP_BUCKET_NAME;

const screenFields = ({params, navigation}) => {
  const {project} = params;
  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    name: {
      label: 'Name',
      type: 'text',
      field: 'view',
      required: true,
    },
    dataEntity: ({readOnly} = {}) => ({
      label: 'Model',
      field: 'model_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
      suggestionField: 'model',
      valueField: 'model',
      searchFields: ['model'],
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-model`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      readOnly,
    }),
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      size: 6,
      readOnly,
    }),
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    designOwner: {
      label: 'Design Owner',
      field: 'design_owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    description: {
      label: 'Description',
      type: 'textArea',
      field: 'desc',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    remarks: {
      label: 'Remarks',
      type: 'richText',
      field: remarksField,
      placeholder: 'Write remarks here...',
      minHeight: 200,
    },
    components: {
      label: 'Components',
      field: 'components',
      type: 'multiAutoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_COMPONENT_TYPE_ID}/suggestions`,
      suggestionField: 'component',
      valueField: 'component',
      searchFields: ['component'],
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-component`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
    },
    linked_to: {
      label: 'Linked Screens',
      field: 'linked_to_screens',
      type: 'multiAutoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_VIEW_TYPE_ID}/suggestions`,
      filter: ({values: {linked_to_screens = []} = {}}) => {
        let sourceIds = linked_to_screens?.map(doc => doc._id) || [];
        return {_id: {$nin: [...sourceIds, params?.row?._id]}};
      },
      suggestionField: 'view',
      valueField: 'view',
      searchFields: ['view'],
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-view`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
    },
    folder_path: {
      field: 'folder_path_id',
      label: 'Folder Path',
      type: 'autoComplete',
      api: `/repositoryFolderPaths`,
      suggestionField: 'path',
      secondarySuggestionField: 'name',
      valueField: 'path',
      filter: {
        type: 'screen',
        project_id: project?._id,
      },
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-repository-folder-path`, {
          ...params,
          repositoryType: 'Frontend',
          defaultValues: {
            type: 'screen',
            project_id: project?._id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      helperText: 'Select path on which file is placed',
    },
    file_name: {
      type: 'autoComplete',
      field: 'file_name',
      label: 'File Name',
      api: `/folderFiles`,
      suggestionField: 'name',
      valueField: 'name',
      filter: ({values}) => {
        return {
          folder_path_id: values?.folder_path_id,
        };
      },
      onCreate: ({searchValue, onChange, form: {values}}) => {
        navigation.navigate(`add-file-name`, {
          ...params,
          defaultValues: {
            folder_path_id: values?.folder_path_id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      visible: ({values}) => values?.folder_path_id,
    },
    query: {
      type: 'textArea',
      field: 'query',
      label: 'Query',
    },
  };
};

const designFields = {
  Desktop: [
    {
      type: 'file',
      field: 'desktop_attachments',
      label: 'Attachments',
      placeholder: 'Add Images',
      multiple: true,
      options: {bucketName: bucketName},
    },
    {
      label: 'Figma link',
      field: 'desktop_link',
      type: 'link',
    },
  ],
  Mobile: [
    {
      type: 'file',
      field: 'mobile_attachments',
      label: 'Attachments',
      placeholder: 'Add Images',
      multiple: true,
      options: {bucketName: bucketName},
    },
    {
      label: 'Figma link',
      field: 'mobile_link',
      type: 'link',
    },
  ],
  Tab: [
    {
      type: 'file',
      field: 'tab_attachments',
      label: 'Attachments',
      placeholder: 'Add Images',
      multiple: true,
      options: {bucketName: bucketName},
    },
    {
      label: 'Figma link',
      field: 'tab_link',
      type: 'link',
    },
  ],
};

export const populateDesignFields = ({row, params}) => {
  const project = row?.project_id || params?.project;
  let {deviceTypes, primaryDeviceType = 'Desktop'} = project || {};
  if (!deviceTypes?.length) {
    deviceTypes = ['Mobile', 'Tab', 'Desktop'];
  }

  const remainingDeviceTypes = deviceTypes
    .filter(deviceType => deviceType !== primaryDeviceType)
    .map(deviceType => {
      return {
        label: deviceType,
        fields: designFields[deviceType],
      };
    });

  return [
    {
      label: primaryDeviceType,
      fields: designFields[primaryDeviceType],
    },
    {
      groups: remainingDeviceTypes,
    },
  ];
};

export const AddAssetDesignForm = props => {
  const {
    route: {params},
  } = props;

  const {row, secondaryTitle, source} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source,
    row,
  });

  const designFields = populateDesignFields({row, params});

  return (
    <Form
      {...props}
      mode={'edit'}
      header={{title: 'Add Design', secondaryTitle}}
      data={{_id: row?._id}}
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({
        data: row,
        skipRequiredValidation: true,
      })}
      submitAction="Save"
      layoutFields={designFields}
    />
  );
};

const ScreenForm = props => {
  const {
    navigation,
    route: {params},
    mode,
    data,
  } = props;

  const designFields = populateDesignFields({row: data, params});

  const fields = screenFields({params, navigation});

  const tabs = {
    basic_info: {
      label: 'Basic Info',
      layoutFields: [
        fields.name,
        fields.folder_path,
        fields.file_name,
        {
          collapsedFields: [
            fields.module({readOnly: mode}),
            fields.dataEntity({readOnly: mode}),
            fields.owner,
            fields.designOwner,
            fields.description,
            fields.query,
          ],
        },
      ],
    },
    designs: {
      label: 'Designs',
      layoutFields: designFields,
    },
    components: {
      label: 'Components',
      layoutFields: [fields.components],
    },
    linked_to: {
      visible: !!mode,
      label: 'Linked To',
      layoutFields: [fields.linked_to],
    },
    props: {
      label: 'Props',
      layoutFields: [{label: 'Props', field: 'screen_json_config', type: 'textArea'}],
    },
    // code: {
    //   visible: () => row?.folder_path_id && row?.file_name,
    //   label: 'Code',
    //   layoutFields: [
    //     {
    //       field: 'screen_code',
    //       placeholder: 'Write your screen code here...',
    //       type: 'aiCode',
    //       height: 500,
    //       params: ({model_id, components, _id: view_id}) => {
    //         return {
    //           model_id: model_id?._id,
    //           project_id,
    //           module_id,
    //           dependentComponentIds: components?.map(doc => doc?._id),
    //           view_id,
    //         };
    //       },
    //       entityName: 'projectView',
    //     },
    //   ],
    // },
    remarks: {
      visible: !!mode,
      label: 'Remarks',
      layoutFields: [fields.remarks],
    },
  };

  return <Form type={'tab'} submitAction="Save" tabs={tabs} {...props} />;
};

export const AddScreenForm = (props = {}) => {
  const {route: {params = {}} = {}} = props;

  const {project, module, feature, searchValue, afterSubmit} = params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_VIEW_TYPE_ID,
  });

  const initialValues = {
    view: searchValue,
    project_id: project?._id,
    module_id: module,
    feature_id: feature?._id,
    source: PROJECT_VIEW_TYPE_ID,
    status: 'active',
  };

  return (
    <ScreenForm
      {...props}
      header="Add Screen"
      onSubmit={createAsset}
      afterSubmit={afterSubmit}
      submitAction="Save"
      defaultValues={initialValues}
      beforeSubmit={requiredValidation}
    />
  );
};

export const ScreenDetailForm = props => {
  const {
    route: {params},
  } = props;

  const {readOnly, row, feature, project} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_VIEW_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  return (
    <ScreenForm
      selectedTab={project?.ai_enabled ? 'basic_info' : 'designs'}
      mode="edit"
      header={{title: 'Screen Detail', secondaryTitle: row?.view}}
      isDualMode
      data={row}
      readOnly={readOnly || row?.aiGenerated}
      onSubmit={updateHistory}
      submitAction="Save"
      beforeSubmit={assetBeforeSubmit({data: row})}
      {...props}
    />
  );
};

export const ScreenCodeEditorForm = props => {
  const {
    route: {params},
  } = props;
  const [loading, setLoading] = useState(false);
  const {row, project_id, module_id, readOnly, feature} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_VIEW_TYPE_ID,
    feature_id: feature?._id,
    row,
  });
  const postInvoke = useInvoke({
    method: 'post',
  });

  return (
    <Form
      data={row}
      mode="edit"
      header={{title: 'Screen Code', secondaryTitle: row?.view}}
      skipDefaultSave
      footer={({styles, handleSubmit, values, onClose}) => {
        return {
          actions: [
            <SubmitButton label="Save" styles={styles.secondaryButton} />,
            <Button
              loading={loading}
              text="Save and Commit"
              onPress={async () => {
                setLoading(true);
                const {message} = await postInvoke({
                  uri: '/commitGitFile',
                  props: {
                    ...params,
                    fileContent: values?.screen_code,
                    folder_path_id: values?.folder_path_id?._id,
                    file_name: values?.file_name?.name,
                    feature_id: values?.feature_id?._id,
                  },
                });
                if (message === 'Success') {
                  handleSubmit?.();
                  onClose?.();
                  setLoading(false);
                }
              }}
            />,
          ],
        };
      }}
      readOnly={readOnly || row?.aiGenerated}
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      layoutFields={[
        {
          field: 'screen_code',
          placeholder: 'Write your screen code here...',
          type: 'aiCode',
          height: 280,
          is_committable: false,
          params: ({model_id, components, _id: view_id}) => {
            return {
              model_id: model_id?._id,
              project_id,
              module_id,
              dependentComponentIds: components?.map(doc => doc?._id),
              view_id,
            };
          },
          entityName: 'projectView',
        },
      ]}
      {...props}
    />
  );
};
