import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateEmployeeTeamForm = props => {
  const {route: {params} = {}, headerTitle} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'employeeassociation',
  });
  return (
    <Form
      header={{
        title: 'Update Employee Team',
        secondaryTitle: `${row?.name}`,
      }}
      mode="edit"
      api={`/employees/${row?._id}`}
      fields={{employee_team: row?.employee_team}}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Team',
          field: 'employee_team',
          type: 'autoComplete',
          filter: {
            project: {$exists: false},
            team_type: 'Department',
            status: 'Active',
          },
          api: `/employeeteams`,
          suggestionField: 'name',
          valueField: 'name',
          size: 4,
          required: true,
        },
      ]}
      {...props}
    />
  );
};
