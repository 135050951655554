import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import { BulkForm16Table } from './Form16Tables';

export const Form16Tabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});

  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    BulkForm16: {
      label: 'Bulk Form 16',
      view: <BulkForm16Table {...props} />,
      eventSourceId: 'bulkform16',
      actions:[
        <AddButton title={'Upload Form 16'} view={'upload-form-16'} />,
      ]
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};