import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';

export const KpiVariable = props => {
  const {route: {params} = {}} = props;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'KpiVariableForm',
  });
  return (
    <Form
      onSubmit={data => {
        const {from_date, to_date, subsidiary, ...rest} = data;
        invoke({
          uri: '/sendVariableMail',
          props: {
            period: {
              from: from_date,
              to: to_date,
            },
            subsidiary: {_id: {$in: subsidiary.map(item => item._id)}},
            ...rest,
          },
        });
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          required: true,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          required: true,
        },
        {
          type: 'multiAutoComplete',
          field: 'subsidiary',
          label: 'Organization',
          api: '/organizations',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const SendVariableMail = props => {
  return <KpiVariable header="Kpi Variable" {...props} />;
};
