import React from 'react';

import ModuleAboutTable from './ModuleAbout';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {ScreensTab} from './assets/ScreensTable';
import {DataEntitiesTab} from './assets/DataEntityScreen';
import {ThirdPartyIntegrationsTab} from './assets/ThirdPartyIntegrationScreen';
import {QaTestCasesTab} from './assets/QATestCasesScreen';
import {DataServicesTab} from './assets/DataServiceScreen';
import {ApiEndpointsTab} from './assets/ApiEndPointsScreen';
import {IssueTabs} from './IssueTab';
import {ModuleRoleTable} from './ModuleRoleTable';
import {UserStoryScreen} from './UserstoryTable';
import getPmtTooltip from '../constants/PmtTooltip';
import {RoadmapTabs} from './RoadmapTabs';
import {Labels} from '../constants/Label';
const Module = createMenuNavigator();
const ModuleDetail = props => {
  const {route: {params} = {}} = props;
  const PmtTooltip = getPmtTooltip({params});
  return (
    <Module.Navigator {...props}>
      <Module.Group>
        <Module.Screen
          name={`${Labels.Requirements}`}
          component={UserStoryScreen}
          options={{
            label: `${Labels.Requirements}`,
            api: `/projectUserStories`,
            filter: {
              module_id: params.module._id,
            },
            eventSourceId: ['Feature'],
            tooltip: PmtTooltip.REQUIREMENTS,
          }}
        />
        <Module.Screen
          name="Issues"
          component={IssueTabs}
          options={{
            label: 'Issues',
            api: `/projectissues`,
            filter: {
              module_id: params.module._id,
              status: {$ne: 'resolved'},
            },
            eventSourceId: ['Issue'],
            tooltip: PmtTooltip.ISSUES,
          }}
        />
      </Module.Group>
      <Module.Group>
        <Module.Screen
          name="features"
          component={RoadmapTabs}
          props={{fromModule: true}}
          options={{
            label: Labels.Features,
            api: '/features/count',
            params: {
              module_id: params?.module?._id,
              status: {$in: ['backlog', 'active']},
            },
            eventSourceId: 'Feature',
            tooltip: PmtTooltip.FEATURES,
          }}
        />
      </Module.Group>
      <Module.Group>
        <Module.Screen
          name="Data Entities"
          component={DataEntitiesTab}
          options={{
            label: 'Models',
            api: '/projectmodels/released',
            addOnFilter: {
              module_id: params?.module?._id,
            },
            eventSourceId: 'Model',
            tooltip: PmtTooltip.SCHEMA,
          }}
        />
        <Module.Screen
          name="API Endpoints"
          component={ApiEndpointsTab}
          options={{
            label: 'APIs',
            api: '/projectcontrollers/released',
            addOnFilter: {
              module_id: params?.module?._id,
            },
            eventSourceId: 'Controller',
            tooltip: PmtTooltip.APIs,
          }}
        />
        <Module.Screen
          name="Screens"
          component={ScreensTab}
          options={{
            label: 'Screens',
            api: '/projectviews/released',
            addOnFilter: {
              module_id: params?.module?._id,
            },
            eventSourceId: 'View',
            tooltip: PmtTooltip.SCREENS,
          }}
        />
        <Module.Screen
          name="Services"
          component={DataServicesTab}
          options={{
            label: 'Services',
            api: '/projectdataservices/released',
            addOnFilter: {
              module_id: params?.module?._id,
            },
            eventSourceId: 'DataService',
            // tooltip: PmtTooltip.DATA_SERVICES,
          }}
        />
        <Module.Screen
          name="Third Party Integrations"
          component={ThirdPartyIntegrationsTab}
          options={{
            label: 'Integrations',
            api: '/projectthirdpartyintegrations/released',
            addOnFilter: {
              module_id: params?.module?._id,
            },
            eventSourceId: 'ThirdPartyIntegration',
            tooltip: PmtTooltip.INTEGRATIONS,
          }}
        />
        <Module.Screen
          name="TestCases"
          component={QaTestCasesTab}
          options={{
            label: 'TestCases',
            api: '/qatestcases/released',
            addOnFilter: {
              module_id: params?.module?._id,
            },
            eventSourceId: 'TestCase',
            tooltip: PmtTooltip.TEST_CASES,
          }}
        />
      </Module.Group>
      <Module.Group>
        <Module.Screen
          name="Roles"
          component={ModuleRoleTable}
          options={{
            label: 'Roles',
            api: `/projectroles`,
            filter: {module_id: params?.module?._id},
            eventSourceId: 'Role',
            tooltip: PmtTooltip.ROLES,
          }}
        />
      </Module.Group>
      <Module.Group>
        <Module.Screen
          name="Settings"
          component={ModuleAboutTable}
          options={{label: 'Settings', tooltip: PmtTooltip.SETTINGS}}
        />
      </Module.Group>
    </Module.Navigator>
  );
};

export default ModuleDetail;
