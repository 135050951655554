import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';

export const SwaggerRedirectURL = ({onPress}) => {
  const {ArrowSquareOut} = useTheme('icons');
  return (
    <TouchableOpacity
      style={{alignItems: 'center', justifyContent: 'center'}}
      onPress={onPress}>
      <Image source={ArrowSquareOut} />
    </TouchableOpacity>
  );
};
