import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';

import LoginPageComponent from './LoginPageComponent.';
import {ResetPasswordFormStyles} from './theme';
import {Button} from '../../../components/button/Button';

export const ResetPasswordForm = props => {
  const navigation = useNavigation();

  const {
    heading,
    subHeading,
    buttonText,
    onButtonPress,
    disabled = false,
  } = props;

  const {
    containerStyle,
    headerContainerStyle,
    headerRowStyle,
    headerTextStyle,
    buttonStyle,
    subHeadingTextStyle,
    buttonTextStyle,
    backIcon,
    disabledButtonStyle,
    disabledButtonTextStyle,
    childContainerStyle,
    buttonStyles,
  } = useStyles(ResetPasswordFormStyles);

  return (
    <LoginPageComponent>
      <View style={containerStyle}>
        <View style={headerContainerStyle}>
          <Row gap={12} style={headerRowStyle}>
            <TouchableOpacity
              onPress={() => {
                navigation.goBack();
              }}>
              <Image source={backIcon} />
            </TouchableOpacity>
            <Text style={headerTextStyle}>{heading}</Text>
          </Row>
          <Text style={subHeadingTextStyle}>{subHeading}</Text>
        </View>
        <View style={childContainerStyle}>{props.children}</View>
        {/* <TouchableOpacity
          style={[buttonStyle, disabled ? disabledButtonStyle : null]}
          onPress={onButtonPress}
          disabled={disabled}>
          <Text
            style={[
              buttonTextStyle,
              disabled ? disabledButtonTextStyle : null,
            ]}>
            {buttonText}
          </Text>
        </TouchableOpacity> */}
        <View style={{flex: 1, marginTop: 28}}>
          <Button
            onPress={onButtonPress}
            text={buttonText}
            styles={buttonStyles}
            gap={8}
            disabled={disabled}
          />
        </View>
      </View>
    </LoginPageComponent>
  );
};
