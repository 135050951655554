import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import PerformanceTable from '../../leadGeneration/views/PerformanceTable';
import {AddCard} from '../../../components/card/AddCard';
import pluralize from 'pluralize';
import {useFetchData} from '../../../controllers/useFetchData';

export const ModuleOverview = props => {
  const {params, navigation} = props;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {params: filterParams = {}} = filterValues;
  const mergedParams = {...params, ...filterParams};

  const {data: activeElements} = useFetchData({
    api: `/department/${params?.department?._id}/active-element`,
  });
  const dynamicCards = [];
  if (activeElements?.length) {
    activeElements.forEach(activeElement => {
      let {label, model, filter, on_press_url} = activeElement;
      if (filter) {
        filter = JSON.parse(filter);
      }
      dynamicCards.push(
        <AddCard
          title={label}
          api={`/${pluralize.plural(model?.name || '')}`}
          filter={filter}
          params={params}
          action={() =>
            navigation.navigate(`${on_press_url?.toLowerCase()}`, params)
          }
        />,
      );
    });
  }
  return (
    <View style={{flex: 1, overflow: 'hidden'}}>
      <TableHeader
        leftActions={[
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
          />,
        ]}
      />
      <PerformanceTable
        {...props}
        params={mergedParams}
        filterParams={filterParams}
      />
    </View>
  );
};
