import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {TableHeader} from '../../../components/table/Headers';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';

export const MySkillsTable = props => {
  const {
    route: {params = {}},
    navigation,
    status = 'active',
    user = {},
  } = props;

  const {employeeData} = params;
  let addOnFilter = {};
  if (user?.employee) {
    const {employee} = user;
    addOnFilter.employee_id = employee?._id;
  } else if (employeeData && employeeData._id) {
    addOnFilter.employee_id = employeeData._id;
  }

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'MySkill',
  });
  return (
    <Table
      params={params}
      {...props}
      api={`/employeeskills`}
      addOnFilter={{status, ...addOnFilter}}
      eventSourceId={'MySkill'}
      onRowPress={({row}) => {
        navigation.navigate('edit-my-skills', {
          employeeSkill: row,
        });
      }}
      renderHeader={() => {
        return <TableHeader title="Skills" />;
      }}
      fields={{
        skill_metrics: {
          name: 1,
        },
        child_skill_metrics: {
          name: 1,
          color: 1,
        },
        tools: {
          name: 1,
        },
        skill_rating: 1,
        reviewer_rating: 1,
      }}
      columns={[
        {
          field: 'skill_metrics.name',
          type: 'text',
          header: 'Skill',
        },
        {
          field: 'child_skill_metrics',
          render: MultiColorTag,
          header: 'Children Skill',
          labelField: 'name',
          colorField: 'color',
          maxChips:3
        },
        {
          field: 'tools',
          render: MultiColorTag,
          header: 'Tools',
          labelField: 'name',
          colorField: 'color',
          maxChips:3
        },
        {
          field: 'skill_rating',
          type: 'number',
          header: 'Skill Rating',
        },
        {
          field: 'reviewer_rating',
          type: 'number',
          header: 'Reviewer Rating',
        },
      ]}
      moreActions={[
        {
          title: 'Mark Archive',
          confirm: {
            title: 'Mark Archive',
            message: 'Are you sure you want to Mark Archive?',
          },
          visible: status === 'active' && (user.employee ? true : false),
          onPress: ({employeeData}) => {
            invoke({
              uri: `/employeeskills/${employeeData?._id}`,
              props: {
                status: 'archive',
              },
            });
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          visible: status === 'archive' && (user.employee ? true : false),
          onPress: ({employeeData}) => {
            invoke({
              uri: `/employeeskills/${employeeData?._id}`,
              props: {
                status: 'active',
              },
            });
          },
        },
      ]}
    />
  );
};
