import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const RPSCloseForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'rps',
    uri: '/opportunities',
    ...props,
  });
  return (
    <Form
      defaultValues={{
        status: 'Closed',
        outcome_date: getZeroTimeDate(new Date()),
        _id: row?._id,
      }}
      onSubmit={onSubmit}
      header={'Mark RPS Close'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Close Reason',
          field: 'outcome_description',
          type: 'autoComplete',
          options: [
            'Client not interested anymore',
            'No response from client',
            'Other',
          ],
        },
        {
          placeholder: 'Comment',
          field: 'outcome_description_comment',
          type: 'text',
        },
      ]}
    />
  );
};
