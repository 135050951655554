import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const LearningPathForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/learningpaths',
    eventSourceId: 'learningPathsAdded',
  });
  return (
    <Form
      api={`/learningpaths/${row?._id}`}
      fields={{
        name: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const AddLearningPath = props => {
  return <LearningPathForm header={'Add Learning Path'} {...props} />;
};

export const EditLearningPath = props => {
  return (
    <LearningPathForm header={'Edit Learning Path'} mode="edit" {...props} />
  );
};
