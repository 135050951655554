import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useTheme} from '@unthinkable/react-theme';
import {Image, Text} from '@unthinkable/react-core-components';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const RenderWonStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.won_count) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row?.won_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderLostStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.lost_count) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {row?.lost_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderComparitiveLostStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.comparitive_lost_count) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {row?.comparitive_lost_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderSuccess = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.success_percentage && row?.success_percentage == 100) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row?.success_percentage}
        </Text>
      </>
    );
  }
  if (row?.success_percentage && row?.success_percentage >= 50) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.WARNING_HIGH,
            align: 'center',
          }}>
          {(row?.success_percentage).toFixed(2)}
        </Text>
      </>
    );
  }
  if (row?.success_percentage && row?.success_percentage < 50) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {(row?.success_percentage).toFixed(2)}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderComparitiveSuccess = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (
    row?.comparitive_success_percentage &&
    row?.comparitive_success_percentage == 100
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row?.comparitive_success_percentage}
        </Text>
      </>
    );
  }
  if (
    row?.comparitive_success_percentage &&
    row?.comparitive_success_percentage >= 50
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.WARNING_HIGH,
            align: 'center',
          }}>
          {(row?.comparitive_success_percentage).toFixed(2)}
        </Text>
      </>
    );
  }
  if (
    row?.comparitive_success_percentage &&
    row?.comparitive_success_percentage < 50
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {(row?.comparitive_success_percentage).toFixed(2)}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderComparitiveWonStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.comparitive_won_count) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row?.comparitive_won_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const WON_COUNT = {
  header: 'Won',
  render: RenderWonStatus,
  width: 150,
};

const COM_WON_COUNT = {
  header: 'Won',
  render: RenderComparitiveWonStatus,
  width: 150,
};

const COM_LOST_COUNT = {
  header: 'Lost',
  render: RenderComparitiveLostStatus,
  width: 150,
};

const LOST_COUNT = {
  header: 'Lost',
  render: RenderLostStatus,
  width: 150,
};

const SUCCESS = {
  header: 'Success %',
  render: RenderSuccess,
  width: 150,
};

const COM_SUCCESS = {
  header: 'Success %',
  render: RenderComparitiveSuccess,
  width: 150,
};

export const PracticeWiseOpportunityList = props => {
  let {
    params = {},
    navigation,
    filterParams,
    searchValue,
    isComparative,
  } = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/opportunity/successreport'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      sort={{success_percentage: -1}}
      searchFields="name"
      columns={[
        {header: 'Name', field: 'name'},
        // {
        //   header: 'Interview Scheduled',
        //   field: 'interview_scheduled',
        //   type: 'number',
        //   width: 150,
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         invalid_interview: {$exists: false},
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        {
          ...WON_COUNT,
          align: 'center',
          type: 'number',
          aggregate: true,
          field: 'won_count',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-closed-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Selected',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
                mentor: {$exists: true, $ne: null},
                delivery_owner: {$exists: true, $ne: null},
                process: {$ne: 'Direct'},
                invalid_interview: {$exists: false},
              },
            });
          },
          visible: !isComparative,
        },
        // {
        //   ...COM_WON_COUNT,
        //   align: 'center',
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-closed-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: 'Selected',
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        //   visible: isComparative,
        // },
        {
          ...LOST_COUNT,
          align: 'center',
          type: 'number',
          aggregate: true,
          field: 'lost_count',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-closed-opportunity-table', {
              row,
              fromPractice: true,
              addOnFilter: {
                outcome: 'Rejected',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
                mentor: {$exists: true, $ne: null},
                delivery_owner: {$exists: true, $ne: null},
                process: {$ne: 'Direct'},
              },
            });
          },
          visible: !isComparative,
        },
        // {
        //   ...COM_LOST_COUNT,
        //   align: 'center',
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-closed-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: 'Rejected',
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        //   visible: isComparative,
        // },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-closed-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Drop',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
                mentor: {$exists: true, $ne: null},
                delivery_owner: {$exists: true, $ne: null},
                process: {$ne: 'Direct'},
              },
            });
          },
          visible: !isComparative,
        },
        // {
        //   header: 'Drop',
        //   field: 'comparitive_drop_count',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: 'Drop',
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        //   visible: isComparative,
        // },
        // {
        //   header: 'Pending',
        //   field: 'pending_count',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        // {
        //   header: 'Pending',
        //   field: 'comparitive_pending_count',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        {
          ...SUCCESS,
          align: 'center',
          visible: !isComparative,
        },
        // {
        //   ...COM_SUCCESS,
        //   align: 'center',
        //   visible: isComparative,
        // },
      ]}
    />
  );
};

export const PracticeWiseActiveOpportunityList = props => {
  let {
    params = {},
    navigation,
    filterParams,
    searchValue,
    isComparative,
  } = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/opportunity/practice-wise-active'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {header: 'Practice', field: 'name'},
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Total',
          align: 'center',
          aggregate: true,
          field: 'profile_shared_count',
          count_field: 'profile_shared_count',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                // practice: row?._id,
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Screening',
          align: 'center',
          aggregate: true,
          field: 'screening_count',
          count_field: 'screening_count',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: false},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Interview',
          align: 'center',
          aggregate: true,
          field: 'interview_scheduled',
          count_field: 'interview_scheduled',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: true},
                interview_date: {
                  $gte: new Date(),
                },
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Result Due',
          align: 'center',
          aggregate: true,
          field: 'results_due_count',
          count_field: 'results_due_count',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: true},
                interview_date: {
                  $lt: new Date(),
                },
                opportunity_status: 'Active',
              },
            });
          },
        },
      ]}
    />
  );
};
export const PracticeWiseScheduledOpportunityList = props => {
  let {
    params = {},
    navigation,
    filterParams,
    searchValue,
    isComparative,
  } = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/opportunity/successreport'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {header: 'Name', field: 'name'},
        {
          header: 'Interview Scheduled',
          field: 'interview_scheduled',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                invalid_interview: {$exists: false},
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {
          header: 'Interview Conducted',
          field: 'interview_conducted',
          aggregate: true,
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                outcome: {$exists: false},
                invalid_interview: {$exists: false},
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {
          ...COM_WON_COUNT,
          align: 'center',
          aggregate: true,
          field: 'comparitive_won_count',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Selected',
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {
          ...COM_LOST_COUNT,
          aggregate: true,
          field: 'comparitive_lost_count',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Rejected',
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {
          header: 'Drop',
          field: 'comparitive_drop_count',
          aggregate: true,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Drop',
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          header: 'Pending',
          field: 'pending_count',
          aggregate: true,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('practice-wise-opportunity-table', {
              row,
              addOnFilter: {
                outcome: null,
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        // {
        //   header: 'Pending',
        //   field: 'comparitive_pending_count',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('practice-wise-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },

        {
          ...COM_SUCCESS,
          align: 'center',
        },
      ]}
    />
  );
};
