import React from 'react';
import {
  Col,
  Image,
  Linking,
  Text,
  View,
} from '@unthinkable/react-core-components';
import {SignupPageStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import LoginButton from './LoginButton';
import {useNavigation} from '@react-navigation/native';
import {termsOfServiceURL} from '../../../modules/common/constants/SourceConstants';

const Signup = props => {
  const {onGoogleLogin, onLogin} = props || {};
  const {
    LOCOMOIcon,
    containerStyle,
    mainContainerStyle,
    iconContainerStyle,
    subContainerStyle,
    textStyle,
    secondaryTextStyle,
    spanTextStyle,
    UnthinkableIcon,
  } = useStyles(SignupPageStyles);

  const navigation = useNavigation();

  return (
    <View style={containerStyle}>
      <View style={subContainerStyle}>
        <Image source={LOCOMOIcon} style={iconContainerStyle} />
        <View style={mainContainerStyle}>
          <Text style={textStyle}>
            Use your email or another service to continue with Locomo!
          </Text>
          <Col gap={12} style={{width: '100%', marginBottom: 30}}>
            <LoginButton
              onSubmit={onGoogleLogin}
              icon={'google'}
              text={'Continue with Google'}
            />
            <LoginButton
              onSubmit={() => {
                navigation.navigate('email-login', {onLogin});
              }}
              icon={'email'}
              text={'Continue with Email'}
            />
          </Col>
          <Text style={secondaryTextStyle}>
            By signing up, you agree to our{' '}
            <Text
              as={'span'}
              style={spanTextStyle}
              onPress={() => Linking.openURL(termsOfServiceURL)}>
              Terms of Service
            </Text>{' '}
            {/* and{' '}
            <Text as={'span'} style={spanTextStyle}>
              Data processing Agreement
            </Text> */}
          </Text>
        </View>
        <Image source={UnthinkableIcon} />
      </View>
    </View>
  );
};

export default Signup;
