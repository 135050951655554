import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddMarketingTheme = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department, product, campaign_type, target_audience, theme_type} =
    params;
  const {onSubmit} = useFormSubmit({
    uri: '/marketingThemes',
    eventSourceId: 'marketingThemeAdded',
    ...props,
  });
  return (
    <Form
      header="Add Message Category"
      onSubmit={onSubmit}
      defaultValues={{
        department: department?._id,
        product: product?._id,
        campaign_type,
        target_audience,
        theme_type,
        industry: target_audience?.industry,
      }}
      layoutFields={[
        {
          field: 'theme_value_proposition',
          type: 'text',
          label: 'Value Proposition',
        },
        {
          label: 'Category',
          field: 'type',
          type: 'autoComplete',
          api: '/messagecategorytypes',
          suggestionField: 'name',
          valueField: 'name',
          onCreate: () => {
            navigation.navigate(`add-message-category-type`, {...params});
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditMarketingTheme = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <AddMarketingTheme
      header="Edit Theme"
      mode="edit"
      api={'/marketingThemes/'}
      filter={{
        _id: row?._id,
      }}
      fields={{
        name: 1,
        type: {name: 1},
        theme_value_proposition: 1,
        target_audience: {
          name: 1,
        },
        campaign_type: {
          name: 1,
        },
        theme_type: 1,
      }}
      {...props}
    />
  );
};
