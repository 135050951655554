import React from 'react';
import {useFilter} from '@unthinkable/react-filters';

import {TabView} from '../../../components/tab';
import {
  CompletedTeamStrategicTasksTable,
  StrategicTasksTable,
} from './TasksTable';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {taskFilters, useTaskActions} from './TaskActions';

export const StrategicTaskTabs = props => {
  const {
    route: {params},
    navigation,
    skipAdd,
  } = props;
  const {employee_team, userId} = params;

  const {activityLogs, markDisqualified, markQualified, markIncomplete} =
    useTaskActions({
      navigation,
      params,
    });

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {params: filterParams, filter: addOnFilter = {}} = filterValues;
  addOnFilter.is_strategic = true;
  if (filterParams.is_open == 'Yes') {
    addOnFilter.assigned_to = null;
  } else if (filterParams.is_open == 'No') {
    addOnFilter.assigned_to = {$exists: true};
  }
  const tabParams = {teamId: employee_team?._id, userId};

  return (
    <TabView
      selectedTab={'active'}
      params={params}
      tabs={{
        backlog: {
          label: 'Backlog',
          api: '/organizationtasks',
          params: tabParams,
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'backlog',
            ...addOnFilter,
          },
          view: (
            <StrategicTasksTable
              isBacklog
              skipAdd={skipAdd}
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={{
                status: 'backlog',
                ...addOnFilter,
              }}
            />
          ),
        },
        active: {
          api: '/organizationtasks',
          params: tabParams,
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'active',
            ...addOnFilter,
          },
          label: 'Active',
          view: (
            <StrategicTasksTable
              skipAdd={skipAdd}
              isActive
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={{
                status: 'active',
                ...addOnFilter,
              }}
            />
          ),
        },
        completed: {
          label: 'Completed',
          api: '/organizationtasks',
          params: tabParams,
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'completed',
            ...addOnFilter,
          },
          view: (
            <CompletedTeamStrategicTasksTable
              isCompleted
              parentSourceView="StrategicTask"
              onRowPress={({row}) => {
                navigation.navigate('strategic-task-edit', {
                  ...params,
                  row,
                  readOnly: true,
                });
              }}
              sort={{completed_on: -1}}
              employee_team={employee_team}
              searchValue={searchValue}
              addOnFilter={addOnFilter}
              moreActions={[
                // markIncomplete,
                markDisqualified,
                markQualified,
                activityLogs,
              ]}
              {...props}
            />
          ),
        },
        archived: {
          label: 'Archived',
          api: '/organizationtasks',
          params: tabParams,
          eventSourceId: 'Task',
          search: searchValue,
          searchFields: 'name',
          filter: {
            status: 'archived',
            ...addOnFilter,
          },
          view: (
            <StrategicTasksTable
              skipAdd={skipAdd}
              searchValue={searchValue}
              addOnFilter={{
                status: 'archived',
                ...addOnFilter,
              }}
            />
          ),
        },
      }}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            taskFilters.assigned_to,
            taskFilters.reviewer,
            taskFilters.favorite_by,
            taskFilters.source,
            taskFilters.is_open,
            taskFilters.due_date,
            taskFilters.completed_on,
          ]}
        />,
      ]}
      {...props}
    />
  );
};
