import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useRejectStrategicInvitee} from '../controllers';

export const ShowStrategicInvitees = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {resource_interests} = row;
  const rejectStrategicInvitee = useRejectStrategicInvitee(props);
  return (
    <Table
      renderHeader={() => <TableHeader title="Invitees" />}
      data={resource_interests}
      moreActions={[
        {
          title: 'Reject',
          onPress: rejectStrategicInvitee,
          visible: ({row}) => row?.status != 'Rejected',
        },
      ]}
      columns={[
        {
          type: 'date',
          field: 'date',
          header: 'Date',
        },
        {type: 'userAvatarChip', field: 'resource', header: 'Resource'},
        {
          type: 'textArea',
          field: 'interest',
          header: 'Interest',
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
          width:100
        },
      ]}
    />
  );
};
