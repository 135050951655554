import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';

export const ExpenseApproverLimitTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={['ExpenseApproverLimit']}
      api={`/expenseapproverlimits`}
      fields={{
        amount: 1,
        employee: {_id: 1, name: 1, color: 1, official_email_id: 1},
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-expense-budget`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          type: 'userAvatar',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Amount',
          type: 'currency',
          field: 'amount',
        },
      ]}
    />
  );
};

export const ExpenseApproverLimitTab = props => {
  let {route: {params} = {}} = props;
  const tabs = {
    tds: {
      label: 'Expense Approver Limit',
      view: <ExpenseApproverLimitTable {...props} />,
      eventSourceId: 'ExpenseApproverLimit',
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      actions={[
        <AddButton
          title=" Add Expense Approver Limit"
          view="add-expense-budget"
          params={params}
        />,
      ]}
    />
  );
};
