import React from 'react';
import {Table} from '../../../components/table/Table';
import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import StatusIconRender from '../../pmt/cellrenders/StatusIconRender';
import {EstRender} from '../../pmt/cellrenders/EstRender';
import {FieldAddFallbackRender} from '../../../components/table/Renders';
import images from '../../../images';
import {useTheme} from '@unthinkable/react-theme';
import {AddAction} from '../../../components/action/AddAction';
import {TaskWithActionRenderer} from '../../pmt/cellrenders/TaskWithActionRenderer';

const {Owner} = images;

export const AddTaskInlineForm = ({defaultValues}) => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Task',
    uri: '/projecttasks',
  });
  return (
    <InlineForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Task',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          label: 'Frequency',
          field: 'frequency_mode',
          type: 'autoComplete',
          options: ['Daily', 'Weekly', 'Monthly'],
          onChangeValue: (value, e, {setFieldValue}) => {
            if (value) {
              setFieldValue('type', 'Recurring');
              setFieldValue('status', 'active');
            } else {
              setFieldValue('type', 'Task');
              setFieldValue('status', 'backlog');
            }
          },
        },
        {
          label: 'Skills',
          field: 'skill_metrics',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          width: 200,
        },
      ]}
    />
  );
};

export const AddTaskTableHeader = props => {
  const {params} = props;
  const {
    source,
    department,
    product,
    project,
    source_id,
    assigned_to,
    skill_metrics,
  } = params || {};

  const defaultValues = {
    status: 'backlog',
    product: product?._id,
    department: department?._id,
    project_id: project?._id,
    source,
    source_id,
    assigned_to,
    skill_metrics,
  };
  return <AddTaskInlineForm defaultValues={defaultValues} />;
};

const DueDateRender = props => {
  const {row, params} = props;
  return (
    <FieldAddFallbackRender
      {...props}
      fieldDef={{
        field: 'due_date',
        type: 'date',
      }}
      addAction={{
        view: 'task-field',
        params: {
          ...params,
          task_id: row._id,
          fields: [
            {
              label: 'Due Date',
              placeholder: 'Due Date',
              field: 'due_date',
              type: 'date',
              required: true,
            },
          ],
          header: 'Add Due Date',
        },
      }}
    />
  );
};

const status = ({invoke}) => ({
  header: 'Status',
  render: StatusIconRender,
  iconField: 'status',
  onIconPress: ({row}) => {
    invoke({
      uri: `/projecttasks/${row?._id}`,
      props: {
        status: 'completed',
      },
    });
  },
  width: 50,
  disabled: ({row}) => {
    return !row?.assigned_to;
  },
  responsive: 'sm',
});

export const AddTaskTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project, source_id, feature_id, module, feature, assigned_to} =
    params || {};
  const invoke = useInvoke({method: 'put', eventSourceId: 'Task'});
  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Task'});

  const editTask = props => {
    const {row} = props;
    navigation.navigate('task-edit', {
      ...params,
      row,
    });
  };

  const deleteTask = ({row}) => {
    deleteInvoke({
      uri: `/projecttasks/${row?._id}`,
      props: {},
    });
  };

  return (
    <Table
      addOnFilter={{assigned_to}}
      renderHeader={props => <AddTaskTableHeader {...props} params={params} />}
      eventSourceId={'Task'}
      api={`/projecttasks/all`}
      params={{
        source_id,
        project_id: project?._id,
        module_id: module?._id,
        feature_id: feature?._id || feature_id,
      }}
      columns={[
        status({invoke}),
        {
          render: TaskWithActionRenderer,
          taskField: 'name',
          actionField: 'master_task_assignment.task',
          colorField: 'master_task_assignment.color',
          responsive: 'sm',
        },
        {
          render: EstRender,
          width: 100,
          align: 'center',
          header: 'Est.',
          columnField: 'est_hours',
          onPress: ({row}) => {
            navigation.navigate('update-est-hours', {
              row,
            });
          },
          onAdd: ({row}) => {
            navigation.navigate('update-est-hours', {
              row,
            });
          },
        },
        {
          width: 100,
          align: 'center',
          header: {icon: Owner},
          field: 'assigned_to',
          type: 'userAvatar',
          onPress: ({row}) => {
            navigation.navigate('update-assignee', {
              row,
              task_id: row?._id,
              project: row?.project_id,
            });
          },
          fallbackRender: ({row}) => {
            const {LockGrey} = useTheme('icons');
            return (
              <AddAction
                icon={LockGrey}
                onPress={() => {
                  navigation.navigate('update-assignee', {
                    row,
                    task_id: row?._id,
                    project: row?.project_id,
                  });
                }}
              />
            );
          },
        },
        {
          width: 90,
          header: 'Due Date',
          render: props => {
            return <DueDateRender {...props} params={params} />;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editTask,
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: deleteTask,
        },
      ]}
    />
  );
};
