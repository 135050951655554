// @ Adit Verma 11/01/2024
//  Api Logs Details Screen
import React from 'react';
import {Form} from '../../../components/form/Form';

//  View for Api Logs Details
export const ApiLogsDetailsForm = props => {
  const {
    route: {
      params: {apiId},
    },
  } = props;
  return (
    <Form
      header="Api Logs Details"
      mode="edit"
      api="/apiLogsDetails"
      skipDefaultSave
      fields={{
        user: 1,
        path: 1,
        params: 1,
        query: 1,
        body: 1,
        error: 1,
        routePath: 1,
        logs: 1,
      }}
      filter={{apiId}}
      layoutFields={[
        {
          label: 'User',
          field: 'user.name',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Api-Path',
          field: 'path',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Api-routePath',
          field: 'routePath',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Logs',
          field: 'logs',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Params',
          field: 'params',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Query',
          field: 'query',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Body',
          field: 'body',
          type: 'text',
          readOnly: true,
        },
        {
          label: 'Error',
          field: 'error',
          type: 'text',
          readOnly: true,
        },
      ]}
      {...props}
    />
  );
};
