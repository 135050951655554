import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {ChannelTable} from './ChannelTable';
import {OutreachBatchTabViewBeta} from '../../leadGeneration/views/OutreachBatchTabView';
import {LeadToCustomerTable} from './LeadToCustomerTable';
import { AmPerformanceTable } from './AmPerformance';

export const PerformanceMenu = props => {
  const {route: {params} = {}} = props;

  const menus = [
    [
      {
        label: 'Channel',
        view: <ChannelTable />,
      },
      {
        label: 'Outreach Campaigns(Beta)',
        view: <OutreachBatchTabViewBeta />,
      },
      {
        label: 'Lead To Customer',
        view: <LeadToCustomerTable />,
      },
      {
        label: 'AM Performance',
        view: <AmPerformanceTable />,
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
