import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {TableHeader} from '../../../components/table/Headers';

export const DepartmentRolesTable = ({navigation, filter, tab}) => {
  return (
    <Table
      eventSourceId={['Department', 'DepartmentRole', 'DepartmentTag']}
      api={`/departmentWiseRoles`}
      addOnFilter={{...filter}}
      fields={{
        name: 1,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'department.name',
          header: 'Department',
        },
        {
          header: 'Skills',
          align: 'right',
          width: 150,
          render: props => {
            const newProps = {
              ...props,
              count_field: 'skill_count',
            };
            return <CountCellRender {...newProps} />;
          },
          onPress: ({row}) => {
            const toolIds = row?.skills?.map(skill => skill?._id);
            if (toolIds?.length) {
              navigation.navigate('department-roles-skill', {
                filter: {
                  _id: {$in: toolIds},
                },
              });
            }
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: props => {
            const {row} = props;
            navigation.navigate('edit-department-role-with-skill', {
              departmentRole: row,
            });
          },
        },
      ]}
    />
  );
};

export const DepartmentRolesSkillTable = props => {
  const {navigation, route: {params} = {}} = props;
  const {filter} = params;
  return (
    <Table
      renderHeader={() => <TableHeader title="Department Skills" />}
      eventSourceId={['Department', 'DepartmentRole', 'DepartmentTag']}
      api={`/tools`}
      addOnFilter={{...filter}}
      fields={{
        name: 1,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
      ]}
    />
  );
};
