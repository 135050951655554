import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {Text, View} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';

const TextRender = {
  income_from_house_property:
    'Maximum limit upto ₹ 30000/- if the house is Self-Occupied and the loan was taken before 1st April 1999 and upto ₹ 200000/- if the house is Self-Occupied and the loan was taken after 1st April 1999',
  other_deductions:
    'Includes Own Medical Insurance Premium (Limit - ₹ 25,000), For Parents (Limit - ₹ 25,000 (if age is <60 yrs) or ₹ 50,000 (if age is >60 yrs)).',
  deduction_under_section_chapterVIA:
    'Includes House Loan Principal,Includes Children Education Fees,Includes Tax Saving FD(5 Years),Includes Insurance Premium Paid,Includes PPF,Includes Voluntary PF,Includes Sukanya Samriddhi Yojana,Includes NPS,Includes ELSS,Includes NSC,Includes Others,',
  income_on_higher_education_loan:
    'This section covers only the interest of the education loan, not the principal amount.',
  donations:
    'The qualifying limit u/s 80 G is 10% of the adjusted gross total income',
  rent_recipts: `1. Only Delhi, Mumbai, Chennai and Kolkata are Metro cities, All other cities are Non-Metro.\n2. Bangalore, Gurugram and Noida are Non-Metro cities.\n3. If you have joined in the mid of the financial year, please enter the rent for the months after your date of joining.\n4. If monthly rent is above 8,333.00 INR, Landlord details & PAN is mandatory.`,
  flexi_component_details: `1.Children Education Allowance upto ₹ 100 per month per child up to a maximum of 2 children \n2.Leave Travel Allowance covers only the actual travel cost and only two journeys can be performed in a block year.\n3.Only the allowances in the compensation structure can be added.`,
};

const Secondarytitle = props => {
  const {value} = props;
  const text = TextRender[value?.component_key];

  return (
    <View>
      {text?.split('\n')?.map((line, index) => (
        <Text key={index}>
          {line}
          {'\n'}
        </Text>
      ))}
    </View>
  );
};

export const TaxDeclarationForms = props => {
  const {
    route: {params},
  } = props;
  const {
    row,
    component_key,
    name,
    tab,
    readOnly = false,
    self = false,
  } = params;
  let {onSubmit} = useFormSubmit({
    uri: '/incometaxes',
  });
  return (
    <Form
      api={`/employee/TaxDeclaration/${row._id}`}
      mode="edit"
      header={{
        title: `${name}`,
        secondaryTitle: <Secondarytitle value={{component_key}} />,
      }}
      submitAction="Save"
      onSubmit={onSubmit}
      readOnly={readOnly}
      layoutFields={[
        {
          fields: [
            {
              visible: () => {
                if (component_key === 'income_from_house_property') return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_income_from_house_property',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'income_from_house_property',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Lender Name',
                          field: 'lender_name',
                          type: 'text',
                        },
                        {
                          label: 'Lender PAN',
                          field: 'lender_pan',
                          type: 'text',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 400,
                        },
                        {
                          header: 'Lender Name',
                          field: 'lender_name',
                          type: 'text',
                          width: 200,
                        },
                        {
                          header: 'Lender PAN',
                          field: 'lender_pan',
                          type: 'text',
                          width: 150,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'other_deductions') return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_other_deductions',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'other_deductions',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 500,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'deduction_under_section_chapterVIA')
                  return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_deduction_under_section_chapterVIA',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'deduction_under_section_chapterVIA',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 400,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'income_on_higher_education_loan')
                  return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_income_on_higher_education_loan',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'income_on_higher_education_loan',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 400,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Rent Recipts',
              visible: () => {
                if (component_key === 'rent_recipts') return true;
              },
              fields: [
                {
                  type: 'text',
                  placeholder: 'Landlord Name',
                  label: 'Landlord Name',
                  field: 'land_lord_name',
                  size: 6,
                },
                {
                  type: 'text',
                  placeholder: 'Landlord Pan No',
                  label: 'Landlord Pan No',
                  field: 'landlord_pan_no',
                  size: 6,
                },
                {
                  type: 'textArea',
                  placeholder: 'Landlord Address',
                  label: 'Landlord Address',
                  field: 'landlord_address',
                  size: 6,
                },
                {
                  label: 'Is Metrocity HRA',
                  fields: [
                    {
                      type: 'checkbox',
                      field: 'is_metrocity_hra',
                    },
                  ],
                },
                {
                  type: 'number',
                  field: 'total_rent_recipts',
                  label: 'Total',
                  readOnly: true,
                },
                {
                  field: 'rent_recipts',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Month',
                          field: 'month',
                          type: 'autoComplete',
                          api: `/monthtypes`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Month',
                          field: 'month.name',
                          type: 'text',
                          minWidth: 200,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'flexi_component_details') return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_flexi_component_details',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'flexi_component_details',
                  nested: true,
                  addInBottom: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Salary Component',
                          field: 'salary_component_id',
                          type: 'autoComplete',
                          api: `/salarycomponent/employee`,
                          suggestionField: 'name',
                          valueField: 'name',
                          params: props => {
                            return {
                              employeeId: row.employee_id._id,
                              type: row.calculation_type,
                            };
                          },
                        },
                        {
                          label: 'January',
                          field: 'jan_amount',
                          type: 'number',
                        },
                        {
                          label: 'February',
                          field: 'feb_amount',
                          type: 'number',
                        },
                        {
                          label: 'March',
                          field: 'mar_amount',
                          type: 'number',
                        },
                        {
                          label: 'April',
                          field: 'apr_amount',
                          type: 'number',
                        },
                        {
                          label: 'May',
                          field: 'may_amount',
                          type: 'number',
                        },
                        {
                          label: 'June',
                          field: 'jun_amount',
                          type: 'number',
                        },
                        {
                          label: 'July',
                          field: 'jul_amount',
                          type: 'number',
                        },
                        {
                          label: 'August',
                          field: 'aug_amount',
                          type: 'number',
                        },
                        {
                          label: 'September',
                          field: 'sep_amount',
                          type: 'number',
                        },
                        {
                          label: 'October',
                          field: 'oct_amount',
                          type: 'number',
                        },
                        {
                          label: 'November',
                          field: 'nov_amount',
                          type: 'number',
                        },
                        {
                          label: 'December',
                          field: 'dec_amount',
                          type: 'number',
                        },
                        {
                          label: 'Attachment',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Salary Component',
                          field: 'salary_component_id.name',
                          type: 'text',
                          minWidth: 300,
                        },
                        {
                          type: 'number',
                          field: 'annual_eligibilty',
                          header: 'Annual Eligibility',
                          width: 120,
                          readOnly: true,
                        },
                        {
                          header: 'January',
                          type: 'number',
                          field: 'jan_amount',
                          width: 100,
                        },
                        {
                          header: 'February',
                          type: 'number',
                          field: 'feb_amount',
                          width: 100,
                        },
                        {
                          header: 'March',
                          type: 'number',
                          field: 'mar_amount',
                          width: 100,
                        },
                        {
                          header: 'April',
                          type: 'number',
                          field: 'apr_amount',
                          width: 100,
                        },
                        {
                          header: 'May',
                          type: 'number',
                          field: 'may_amount',
                          width: 100,
                        },
                        {
                          header: 'June',
                          type: 'number',
                          field: 'jun_amount',
                          width: 100,
                        },
                        {
                          header: 'July',
                          type: 'number',
                          field: 'jul_amount',
                          width: 100,
                        },
                        {
                          header: 'August',
                          type: 'number',
                          field: 'aug_amount',
                          width: 100,
                        },
                        {
                          header: 'September',
                          type: 'number',
                          field: 'sep_amount',
                          width: 100,
                        },
                        {
                          header: 'October',
                          type: 'number',
                          field: 'oct_amount',
                          width: 100,
                        },
                        {
                          header: 'November',
                          type: 'number',
                          field: 'nov_amount',
                          width: 100,
                        },
                        {
                          header: 'December',
                          type: 'number',
                          field: 'dec_amount',
                          width: 100,
                        },
                        {
                          header: 'Attachment',
                          type: 'file',
                          field: 'supporting_document',
                          width: 250,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'previous_employer_income') return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_tds_previous_employer_income',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'previous_employer_income',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Income',
                          field: 'income',
                          type: 'number',
                        },
                        {
                          label: 'TDS',
                          field: 'tds_deducted',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Income',
                          field: 'income',
                          type: 'number',
                          minWidth: 200,
                        },
                        {
                          type: 'number',
                          field: 'declared_amount',
                          header: 'TDS Deducted',
                          readOnly: true,
                          width: 150,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'tds_deducted',
                          width: 200,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'lta') return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_lta',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'lta',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 400,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'donations') return true;
              },
              fields: [
                {
                  label: 'Total',
                  field: 'total_donations',
                  type: 'number',
                  readOnly: true,
                },
                {
                  field: 'donations',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          field: 'type',
                          label: 'Donation Type',
                          type: 'autoComplete',
                          options: [
                            'PM Care Fund',
                            'National Defence Fund set up by the Central Government',
                            'Prime Minister’s National Relief Fund',
                            ' National Foundation for Communal Harmony',
                            'An approved university/educational institution of National eminence',
                            'Zila Saksharta Samiti constituted in any district under the chairmanship of the Collector of that district',
                            'Fund set up by a State Government for the medical relief to the poor',
                            'National Illness Assistance Fund',
                            'National Blood Transfusion Council or to any State Blood Transfusion Council',
                            'National Trust for Welfare of Persons with Autism, Cerebral Palsy, Mental Retardation, and Multiple Disabilities',
                            'National Sports Fund',
                            'National Cultural Fund',
                            'Fund for Technology Development and Application',
                            'National Children’s Fund',
                            'Chief Minister’s Relief Fund or Lieutenant Governor’s Relief Fund with respect to any State or Union Territory',
                            'The Army Central Welfare Fund or the Indian Naval Benevolent Fund or the Air Force Central Welfare Fund, Andhra Pradesh Chief Minister’s Cyclone Relief Fund, 1996',
                            'The Maharashtra Chief Minister’s Relief Fund during October 1, 1993 and October 6, 1993',
                            'Chief Minister’s Earthquake Relief Fund, Maharashtra',
                            'Any fund set up by the State Government of Gujarat exclusively for providing relief to the victims of the earthquake in Gujarat',
                            'Any trust, institution or fund to which Section 80G(5C) applies for providing relief to the victims of the earthquake in Gujarat (contribution made during January 26, 2001, and September 30, 2001) or Prime Minister’s Armenia Earthquake Relief Fund',
                            'Africa (Public Contributions – India) Fund',
                            'Swachh Bharat Kosh (applicable from FY 2014-15)',
                            'Clean Ganga Fund (applicable from FY 2014-15)',
                            'Others',
                          ],
                        },
                        {
                          type: 'text',
                          field: 'pan_no',
                          label: 'PAN',
                        },
                        {
                          type: 'text',
                          field: 'address',
                          label: 'Address',
                        },
                        {
                          type: 'number',
                          field: 'amount',
                          label: 'Amount',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 200,
                        },
                        {
                          header: 'Donation Type',
                          type: 'text',
                          field: 'type',
                          width: 300,
                        },
                        {
                          type: 'text',
                          field: 'pan_no',
                          header: 'PAN',
                          width: 200,
                        },
                        {
                          type: 'text',
                          field: 'address',
                          header: 'Address',
                          width: 200,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          type: 'number',
                          field: 'amount',
                          header: 'Amount',
                          width: 200,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              visible: () => {
                if (component_key === 'nps') return true;
              },
              fields: [
                {
                  type: 'number',
                  field: 'total_nps',
                  label: 'Total NPS',
                  readOnly: true,
                },
                {
                  field: 'nps',
                  nested: true,
                  skipAdd: self ? true : false,
                  skipDelete: self ? true : false,
                  addInBottom: self ? false : true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Saving Head',
                          field: 'saving_heads',
                          type: 'autoComplete',
                          api: `/savingheads`,
                          suggestionField: 'name',
                          valueField: 'name',
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                        },
                        {
                          label: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Saving Head',
                          field: 'saving_heads.name',
                          type: 'text',
                          minWidth: 400,
                        },
                        {
                          header: 'Declared Amount',
                          field: 'declared_amount',
                          type: 'number',
                          width: 150,
                          visible: ({values}) => {
                            if (tab === 'submitted') return true;
                          },
                        },
                        {
                          header: 'Amount',
                          type: 'number',
                          field: 'amount',
                          width: 150,
                        },
                        {
                          header: 'Supporting Document',
                          field: 'supporting_document',
                          type: 'file',
                          width: 200,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const IncomeTaxCalculate = props => {
  const {route: {params} = {}} = props;
  const {selectedIds} = params;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['taxDeclaration'],
  });
  return (
    <Form
      header="Calculate IncomeTax"
      onSubmit={data => {
        invoke({
          uri: '/incomeTaxCalculate',
          props: {
            selectedIds,
            ...data,
          },
        });
      }}
      submitAction="Save"
      layoutFields={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          required: true,
          placeholder: 'Select',
        },
      ]}
      {...props}
    />
  );
};
