import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from './components/Durations';
import {TableHeader} from '../../../components/table/Headers';
import {randomColor} from '../../../utils/util';

const updateProject = ({navigation, params}) => ({
  title: 'Update Project',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-project-on-skill-requirement', {
      row,
    });
  },
});

const employeeAllocation = ({navigation, params}) => ({
  title: 'Allocations',
  onPress: ({row}) => {
    navigation.navigate(`all-resource-allocations-list`, {
      employeeassignmentFilter: {skill_requirement: row?._id},
      fromBillingStatus: true,
    });
  },
});

const projectresourceworking = ({navigation, params}) => ({
  title: 'Project Resource Working',
  onPress: ({row}) => {
    navigation.navigate(`skillwise-deliveries`, {
      extraFilter: {recur_id: row?._id},
    });
  },
});

export const OrderSkillRequirementTable = props => {
  let {route: {params = {}} = {}, navigation} = props || {};
  let {row = {}} = params;
  let {_id} = row;
  let filter = {
    order: _id,
  };
  return (
    <Table
      renderHeader={() => {
        return <TableHeader title="Skill Requirements" />;
      }}
      api={'/skillrequirments'}
      eventSourceId={['skillRequirement', 'employeeAssigned']}
      addOnFilter={filter}
      fields={{
        order: {
          order_number: 1,
          product: {_id: 1, name: 1},
          recurring_till: 1,
          order_date: 1,
          customer_id: 1,
        },
        tool: {
          name: 1,
        },
        project: {
          project: 1,
        },
        skill_requirement_no: 1,
        from_date: 1,
        to_date: 1,
        employee_per_day_working_hour: 1,
        status: 1,
        notes: 1,
      }}
      columns={[
        {header: 'Req', field: 'skill_requirement_no', type: 'text'},
        {
          header: 'Order',
          field: 'order.order_number',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Project',
          field: 'project.project',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Notes',
          field: 'notes',
          type: 'text',
          width: 160,
        },
        {header: 'Skill ', field: 'tool.name', type: 'text'},
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 180,
        },
        {
          header: 'Working Hrs',
          field: 'employee_per_day_working_hour',
          type: 'number',
        },
      ]}
      moreActions={() => [
        updateProject({navigation, params}),
        employeeAllocation({navigation, params}),
        projectresourceworking({navigation, params}),
      ]}
    />
  );
};
