import {Table} from '../../../components/table/Table';

const EmailSignatureList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['signaturesAdded']}
      api={`/emailsignatures`}
      fields={{
        name: 1,
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-email-signature-add-form', {row});
          },
        },
      ]}
    />
  );
};

export default EmailSignatureList;
