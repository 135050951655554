import React, {useState} from 'react';
import {View} from '@unthinkable/react-core-components';
import {RichText} from '../form-editors/text/RichTextAreaInput';
import {CommentBox} from './CommentBox';
import {useStyles} from '@unthinkable/react-theme';
import {CommentStyle} from './theme';
import {CardTable} from '../table/Table';
import {CommentRow} from './CommentRow';

export const CommentCard = props => {
  const {value, header: Header, field = 'comment'} = props;
  const [isFocused, setIsFocused] = useState();
  const [replyEnabled, setReplyEnabled] = useState(false);
  const {
    ReplyIcon,
    section: {container, headerContainer, commentContainer, richTextContainer},
  } = useStyles(CommentStyle);

  const handleOnReply = () => {
    setReplyEnabled(true);
  };

  return (
    <>
      <View style={container}>
        <View style={headerContainer}>
          <Header
            {...props}
            ReplyIcon={ReplyIcon}
            handleOnReply={handleOnReply}
            setIsFocused={setIsFocused}
          />
        </View>
        <View style={commentContainer}>
          {isFocused ? (
            <CommentBox
              {...props}
              defaultValues={value}
              successMessage={false}
              setIsFocused={setIsFocused}
            />
          ) : (
            <View style={richTextContainer}>
              <RichText value={props.row[field]} />
            </View>
          )}
        </View>
      </View>
      <View style={{marginLeft: 40}}>
        <CardTable
          data={props?.value?.children}
          renderRow={({row, index, level}) => (
            <CommentRow {...props} row={row} index={index} level={void 0} />
          )}
        />
        {replyEnabled ? (
          <CommentBox
            {...props}
            defaultValues={{...props?.defaultValues, parent_comment: value}}
            successMessage={false}
            setIsFocused={setReplyEnabled}
          />
        ) : (
          void 0
        )}
      </View>
    </>
  );
};
