import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EmployeeLevel = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/levels',
    eventSourceId: 'employeelevel',
  });
  return (
    <Form
      api={`/levels/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          size: 6,
        },
        {
          label: 'Level',
          type: 'number',
          field: 'level',
          size: 6,
        },
        {
          label: 'Uniform Allowance',
          type: 'number',
          field: 'uniform_allowance',
          size: 6,
        },
        {
          label: 'Child Education',
          type: 'number',
          field: 'child_education',
          size: 6,
        },
        {
          label: 'Medical Reimbursement',
          type: 'number',
          field: 'medical_reimbersement',
          size: 6,
        },
        {
          label: 'Sedexho Meal Voucher',
          type: 'number',
          field: 'sodexho_meal_vouchers',
          size: 6,
        },
        {
          label: 'Gift Item',
          type: 'number',
          field: 'gift_item',
          size: 6,
        },
        {
          label: 'LTA',
          type: 'number',
          field: 'lta',
          size: 6,
        },
        {
          label: 'Telephone and Internet',
          type: 'number',
          field: 'telephone_internet',
          size: 6,
        },
        {
          label: 'Professional Development',
          type: 'number',
          field: 'professional_development',
          size: 6,
        },
        {
          label: 'Books And Periodicals',
          type: 'number',
          field: 'books_and_periodicals',
          size: 6,
        },
        {
          label: 'Insurance Daffodil',
          type: 'number',
          field: 'insurance_daffodil',
          size: 6,
        },
        {
          label: 'Insurance Unthinkable',
          type: 'number',
          field: 'insurance_unthinkable',
          size: 6,
        },
        {
          label: 'Variable Perc',
          type: 'number',
          field: 'variable_perc',
          size: 6,
        },
        {
          label: 'Basic Perc',
          type: 'number',
          field: 'basic_perc',
          size: 6,
        },
        {
          label: 'HRA Perc',
          type: 'number',
          field: 'hra_perc',
          size: 6,
        },
        {
          label: 'Gratuity Perc',
          type: 'number',
          field: 'gratuity_perc',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeLevel = props => {
  return <EmployeeLevel header="Add Breakup" {...props} />;
};

export const EditEmployeeLevel = props => {
  return (
    <EmployeeLevel
      mode="edit"
      header={'Update Level Package Breakup'}
      {...props}
    />
  );
};

export const PromoteEmployeeForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/promoteemployee/${row?._id}`,
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        designation: row?.designation,
        old_designation: row?.designation,
        employee_level: row?.employee_level,
      }}
      layoutFields={[
        {
          label: 'Designation',
          field: 'designation',
          type: 'autoComplete',
          api: `/designations`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Effective from',
          field: 'promotion_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Comments',
          field: 'comments',
          type: 'textArea',
          required: true,
        },
        {
          label: 'Level',
          field: 'employee_level',
          type: 'autoComplete',
          api: `/levels`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Variable Percent',
          field: 'variable_perc',
          type: 'number',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const PromoteEmployee = props => {
  return <PromoteEmployeeForm header="Promote" {...props} />;
};

export const ChangeEmployeeDepartmentForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/changeemployeedepartment/${row?._id}`,
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        department: row?.department,
        old_department: row?.department,
      }}
      layoutFields={[
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Effective from',
          field: 'department_change_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Comments',
          field: 'department_change_comments',
          type: 'textArea',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ChangeEmployeeDepartment = props => {
  return <ChangeEmployeeDepartmentForm header="Change department" {...props} />;
};
