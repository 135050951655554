import {useEventHandler} from '@unthinkable/react-event-handler';
import {useAppStateContext} from '../providers/AppState';

const formSubmitEvents = ({eventSourceId, notify, isNew}) => {
  if (typeof eventSourceId === 'string') {
    eventSourceId = [eventSourceId];
  }
  if (!notify || !eventSourceId?.length) {
    return;
  }
  eventSourceId.forEach(eventId => {
    notify(isNew ? 'dataInserted' : 'dataUpdated', {id: eventId});
  });
};

export const useFormSubmit = ({
  uri,
  postfixUri,
  eventSourceId = [],
  method,
  ids,
  idField = '_id',
  route,
  afterSubmit,
  encryption,
}) => {
  const {post} = useAppStateContext();
  const params = route?.params || {};
  const {notify} = useEventHandler();

  if (!ids && params?.selectedIds) {
    ids = params.selectedIds;
  }

  const onSubmit = async (data, {setFieldValue} = {}) => {
    data = {...data};
    let postUri = uri;
    if (typeof postUri === 'function') {
      postUri = postUri(params, data);
      if (postUri.uri) {
        postUri = postUri.uri;
      }
    }

    let updateId;
    if (idField && data[idField]) {
      updateId = data[idField];
      delete data[idField];
    }
    const isNew =
      !updateId ||
      (typeof updateId === 'string' && updateId.startsWith('new_'));

    let defaultMethod = 'post';
    let body = data;

    if (ids) {
      body = {ids, updates: data};
      defaultMethod = 'put';
      postUri = `${postUri}/batchUpdate`;
    } else {
      if (!isNew && updateId) {
        defaultMethod = 'put';
        postUri = `${postUri}/${updateId}`;
      }
    }
    const result =
      (await post({
        uri: `${postUri}${postfixUri || ''}`,
        props: body,
        method: method || defaultMethod,
        encryption,
      })) || {};
    const {data: updatedData} = result;

    if (
      idField &&
      updatedData?.[idField] &&
      data[idField] !== updatedData?.[idField]
    ) {
      data[idField] = updatedData?.[idField];
      setFieldValue?.(idField, updatedData?.[idField]);
    }
    formSubmitEvents({eventSourceId, notify, isNew});
    afterSubmit && (await afterSubmit({resp: updatedData, ...params}));
    return {...result, data};
  };

  return {
    onSubmit,
  };
};
