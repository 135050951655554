import React from 'react';
import {
  Container,
  SecondaryTitleText,
  TitleContainer,
  TitleText,
} from './styles/HeaderStyles';

export const Header = ({
  title,
  secondaryTitle,
  actions,
  leftActions,
  gap = 8,
  styles,
  closeAction,
  ...props
}) => {
  if (typeof title === 'function') {
    title = title(props);
  }
  if (typeof secondaryTitle === 'function') {
    secondaryTitle = secondaryTitle(props);
  }
  if (typeof actions === 'function') {
    actions = actions(props);
  }

  if (Array.isArray(actions)) {
    actions = actions.filter(action => !!action);
  }

  return (
    <Container gap={gap} styles={styles}>
      {typeof leftActions === 'function' ? leftActions(props) : leftActions}
      <TitleContainer>
        <TitleText numberOfLines={2} styles={styles}>
          {title || ''}
        </TitleText>
        {secondaryTitle ? (
          <SecondaryTitleText numberOfLines={2} styles={styles}>
            {secondaryTitle}
          </SecondaryTitleText>
        ) : (
          void 0
        )}
      </TitleContainer>
      {Array.isArray(actions)
        ? actions.map((action, index) => {
            let isFirst = index === 0;
            let isLast = index === actions.length - 1;
            if (action && React.isValidElement(action) && (isFirst || isLast)) {
              let cloneProps = {};
              if (action.props?.isFirst === undefined) {
                cloneProps.isFirst = isFirst;
              }
              if (action.props?.isLast === undefined) {
                cloneProps.isLast = isLast;
              }
              return React.cloneElement(action, cloneProps);
            } else {
              return action;
            }
          })
        : actions}
      {closeAction}
    </Container>
  );
};
