import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const CategoryForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/categories',
    eventSourceId: 'category',
  });
  return (
    <Form
      api={`/categories/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddCategoryForm = props => {
  return <CategoryForm header="Add Category" {...props} />;
};

export const EditCategoryForm = props => {
  return <CategoryForm mode="edit" header={'Update Category'} {...props} />;
};
