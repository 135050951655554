import {useFilter} from '@unthinkable/react-filters';
import {Text} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {GroupFilter} from '../../../components/filter/Filters';

export const EmployeeGratuityTable = props => {
  let {addOnFilter, params, navigation} = props;
  const {tab} = params;
  return (
    <Table
      addOnFilter={addOnFilter}
      params={params}
      api="/employee/gratuities"
      eventSourceId={['EmployeeGratuity']}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Email',
          field: 'employee.official_email_id',
        },
        {
          header: 'Exp',
          type: 'number',
          width: 80,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Joining Date',
          field: 'joining_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Relieving Date',
          field: 'relieving_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Pay Gratuity',
          visible: () => tab == 'due' || tab == 'overdue',
          message: 'Paid successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`mark-pay-gratuity`, {
              ...params,
              row,
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeGratuityTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter, params: filterParams = {}} = filterValues;

  let tabs = {};
  tabs.due = {
    label: 'Due',
    api: '/employee/gratuities',
    eventSourceId: 'EmployeeGratuity',
    params: {
      ...params,
      tab: 'due',
    },
    addOnFilter: {...filter, status: 'Due'},
    limit: 5000,
    view: (
      <EmployeeGratuityTable
        params={{...params, ...filterParams, tab: 'due'}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          status: 'Due',
        }}
      />
    ),
  };

  tabs.overdue = {
    label: 'Overdue',
    api: '/employee/gratuities',
    eventSourceId: 'EmployeeGratuity',
    params: {
      ...params,
      tab: 'overdue',
    },
    addOnFilter: {...filter, status: 'Overdue'},
    limit: 5000,
    view: (
      <EmployeeGratuityTable
        params={{...params, ...filterParams, tab: 'overdue'}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          status: 'Overdue',
        }}
      />
    ),
  };

  tabs.paid = {
    label: 'Paid',
    api: '/employee/gratuities',
    eventSourceId: 'EmployeeGratuity',
    params: {
      ...params,
      tab: 'paid',
    },
    addOnFilter: {...filter, status: 'Paid'},
    limit: 5000,
    view: (
      <EmployeeGratuityTable
        params={{...params, ...filterParams, tab: 'paid'}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          status: 'Paid',
        }}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              field: 'employee',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'Employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              filter: {
                employee_status: 'Inactive',
              },
            },
            {
              type: 'multiAutoComplete',
              field: 'organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Organization',
            },
          ]}
        />,
      ]}
    />
  );
};
