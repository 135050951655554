import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {AttritionTab} from '../../recruitment/views/AttritionTabs';
import {EnggResginationTabs} from './EnggResignationTabs';
import {ResignationEnggTable} from './ResignationTables';
import {AllResginationTabs} from './ResignationTabs';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {useAppStateContext} from '../../../providers/AppState';

const ResignationMenus = [
  {
    label: 'All',
    view: <AllResginationTabs />,
    api: '/employees/active-resignations',
    limit: 100,
  },
  {
    label: 'Engg Resignation',
    view: <EnggResginationTabs />,
    api: '/employees/active-resignations',
    addOnFilter: {
      department: {
        $in: [
          '6128b78a6f91a06849407634',
          '5848ffe17d89cd3f17c7335c',
          '589e9ea16a39a6208813d617',
          '5849228434d56b42ec1aabd2',
          '599c0536ac02221f8db97504',
        ],
      },
    },
    limit: 100,
  },
  {
    label: 'Resignation Analytics',
    view: <AttritionTab />,
  },
];

export const ResignationMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={ResignationMenus} />;
};

const ResignationGroup = createMenuNavigator();

const ResignationNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <ResignationGroup.Navigator
      // canAccess={canAccess}
      data-navigator-id="ResignationGroup"
      {...props}>
      <ResignationGroup.Screen
        data-resource-id="AllResignation"
        name="AllResignation"
        component={AllResginationTabs}
        props={{user: user, ...props}}
        options={{
          label: 'All',
          api: '/employees/active',
          limit: 5000,
        }}
      />
      <ResignationGroup.Screen
        data-resource-id="EngineerResignation"
        name="EnggResignation"
        component={EnggResginationTabs}
        props={{user: user, ...props, departmentTitle: 'Engg Resignation'}}
        options={{
          label: 'Engg Resignation',
        }}
      />
      <ResignationGroup.Screen
        data-resource-id="ResignationAnalysis"
        name="ResignationAnalysis"
        component={AttritionTab}
        options={{
          label: 'Resignation Analysis',
        }}
      />
    </ResignationGroup.Navigator>
  );
};
export default ResignationNavigator;
