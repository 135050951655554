import { Form } from "../../../components/form/Form";
import { useInvoke } from "../../../controllers/useInvoke";

export const AddToComponentForm = props => {
    const { route: { params } = {} } = props;

    const { row, key, ...rest } = params;

    const invoke = useInvoke({
        method: 'post',
        eventSourceId: 'ReferralBenifit',
    });
    return (
        <Form
            onSubmit={data => {
                invoke({
                    uri: '/invoke',
                    props: { data, from_employee: true, ...rest },
                });
            }}
            submitAction="Save"
            layoutFields={[
                {
                    type: 'autoComplete',
                    field: 'salary_component_id',
                    label: 'Salary Component',
                    api: '/salarycomponents',
                    suggestionField: 'name',
                    valueField: 'name',
                    required: true,
                  },
                {
                    type: "date",
                    placeholder: "From",
                    field: "from_date",
                    header: "From",
                    required: true,
                },
                {
                    type: "date",
                    placeholder: "To",
                    field: "to_date",
                    header: "To",
                    required: true,
                },
                {
                    type: "text",
                    placeholder: "Remark",
                    field: "remark",
                    header: "Remark",
                    required: true,
                },
            ]}
            {...props}
        />
    );
};

export const AddToComponent = props => {
    const { route = {} } = props;
    return (
        <AddToComponentForm
            header="Add Component"
            {...props}
        />
    );
};