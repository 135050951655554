export const ModalViewStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    header: {
      close: {
        icon: icons.Close,
        container: {
          padding: 6,
          borderRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
        },
        hoverContainer: {
          backgroundColor: colors.SURFACE2,
        },
      },
      container: {
        padding: 0,
        marginBottom: 16,
      },
      title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
      secondaryTitle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    container: {
      backgroundColor: colors.BACKGROUND,
      padding: 20,
      borderColor: colors.OUTLINE,
      borderWidth: 1,
    },
    unStyled: {
      container: {},
    },
    crossAbsoluteContainer: {
      padding: 4,
      position: 'absolute',
      top: 0,
      right: 0,
    },
    form: {
      container: {
        backgroundColor: colors.BACKGROUND,
      },
    },
    crossIcon: icons.Cross,
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    sm: {
      screenCenter: {
        width: '90%',
      },
    },
    md: {
      size: {
        auto: {maxWidth: '90%', maxHeight: '90%'},
        extraSmall: {width: 360, maxHeight: '60%'},
        small: {width: 480, maxHeight: '75%'},
        medium: {width: 720, minHeight: '60%'},
        large: {width: 960, minHeight: '70%'},
        extraLarge: {width: 1080, minHeight: '80%'},
        max: {width: '90%', height: '90%'},
      },
    },
  };
};

export const TooltipStyles = ({theme: {colors, fonts, icons, shadows}}) => {
  return {
    arrowColor: colors.NEUTRAL_HIGH,
    container: {
      backgroundColor: colors.NEUTRAL_HIGH,
      padding: 16,
      borderRadius: 8,
    },
    contentRow: {
      alignItems: 'center',
      gap: 8,
    },
    info: icons.Info,
    infoContainer: {
      padding: 6,
      backgroundColor: colors.BRAND_LOW,
      borderRadius: 28,
    },
    infoIcon: {
      height: 16,
      width: 16,
    },
    infoText: {
      ...fonts.CAPTION_SMALL,
      color: colors.SURFACE3,
    },
  };
};
