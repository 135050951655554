export const QAStages = [
  {label: 'Re-QA', value: 'Re-QA'},
  {label: 'QA Passed', value: 'Passed'},
  {label: 'QA Failed', value: 'Failed'},
  {label: 'UAT', value: 'UAT'},
];

export const FeatureStatus = {
  backlog: {label: 'Backlog', value: 'backlog'},
  architecture: {label: 'Architecture', value: 'architecture'},
  active: {label: 'Development', value: 'active'},
  qa: {label: 'QA', value: 'qa'},
  released: {label: 'Released', value: 'released'},
  archived: {label: 'Archived', value: 'archived'},
};
