import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {VendorTdsTab} from './VendorTdsReportTable';
import {PaymentTdsTab} from './PaymentTdsTable';
import {GSTTab, VendorGSTTab} from './VendorGstReport';
import {VoucherTdsTab} from './VoucherTdsTable';
import {SalaryTdsTab} from './SalaryTdsTable';
import {ExpenseAgeingTab} from '../../employee/views/ExpenseAgeing';
import {
  VendorBulkform16Table,
  VendorForm16Table,
} from './VendorBulkForm16Table';

export const VendorReportsMenu = props => {
  const menus = [
    {
      label: 'Vendor TDS Report',
      view: <VendorTdsTab />,
    },
    {
      label: 'GST Input',
      view: <VendorGSTTab />,
    },
    {
      label: 'TDS',
      view: <GSTTab />,
    },
    {
      label: 'Voucher TDS',
      view: <VoucherTdsTab />,
    },
    {
      label: 'Payment TDS Report',
      view: <PaymentTdsTab />,
    },
    {
      label: 'Salary TDS Report',
      view: <SalaryTdsTab />,
    },
    {
      label: 'Ageing',
      view: <ExpenseAgeingTab />,
    },
    {
      label: 'Vendor Bulk form 16',
      view: <VendorBulkform16Table />,
    },
    {
      label: 'Vendor Form 16',
      view: <VendorForm16Table />,
    },
  ];
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
