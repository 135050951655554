export const useAssetActions = ({navigation}) => {
  const activityLogDetailForAsset = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [{path: 'assigned_to', select: {name: 1}}],
      api: `/assetinventories/${row?._id}/activityLogs`,
      displayFields: {
        status: {
          label: 'Status',
        },
        assigned_to: {
          label: 'Assigned To',
          field: 'assigned_to.name',
        },
      },
    });
  };

  return {
    activityLogDetailForAsset,
  };
};
