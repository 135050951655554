import React from 'react';
import {Form} from '../../../../components/form/Form';
import {PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';

const thirdPartyIntegrationFields = ({params}) => {
  const {project} = params;

  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
    },
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      size: 6,
      readOnly,
    }),
    description: {
      label: 'Description',
      type: 'textArea',
      field: 'desc',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    thirdPartyIntegration: {
      label: 'Name',
      type: 'text',
      field: 'name',
      required: true,
    },
    remarks: {
      label: 'Remarks',
      field: remarksField,
      placeholder: 'Write remarks here...',
      type: 'richText',
      minHeight: 200,
    },
  };
};

export const AddThirdPartyIntegrationForm = props => {
  const {
    route: {params},
  } = props;
  const {feature, project, module, searchValue, afterSubmit} = params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    name: searchValue,
    project_id: project?._id,
    module_id: module,
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
    feature_id: feature?._id,
  };

  const fields = thirdPartyIntegrationFields({params});

  return (
    <Form
      {...props}
      header="Add Integration"
      onSubmit={createAsset}
      defaultValues={initialValues}
      afterSubmit={afterSubmit}
      beforeSubmit={requiredValidation}
      submitAction="Save"
      layoutFields={[
        fields.thirdPartyIntegration,
        fields.module(),
        {
          collapsedFields: [fields.owner, fields.description],
        },
      ]}
    />
  );
};

export const ThirdPartyIntegrationDetailForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {row, readOnly, feature} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    row,
    source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
    feature_id: feature?._id,
  });

  const fields = thirdPartyIntegrationFields({params});

  return (
    <Form
      {...props}
      key={row?._id}
      header={{
        title: 'Integration Detail',
        secondaryTitle: row?.name,
      }}
      mode="edit"
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      data={row}
      isDualMode
      readOnly={readOnly}
      type={'tab'}
      tabs={{
        detail: {
          label: 'Detail',
          layoutFields: [
            fields.thirdPartyIntegration,
            fields.module({readOnly: true}),
            fields.owner,
            fields.description,
          ],
        },
        remarks: {
          label: 'Remarks',
          layoutFields: [fields.remarks],
        },
      }}
    />
  );
};
