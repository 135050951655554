import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const TraineeToTrainerFeedbackForm = props => {
  const {
    route: {params},
  } = props;
  const {training_batch, feedback} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/traineeToTrainingFeedbacks',
    eventSourceId: 'traineeToTrainingFeedbacks',
  });
  return (
    <Form
      header={{
        title: 'Add Training Feedback',
        secondaryTitle: training_batch.name,
      }}
      api={'/traineeToTrainingFeedbacks/' + feedback}
      mode="edit"
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'most_valuable_aspect',
          type: 'textArea',
          label: 'What aspects of the training did you find most valuable?',
          required: true,
        },
        {
          field: 'improvable_aspect',
          type: 'textArea',
          label: 'What aspects of the training could be improved?',
          required: true,
        },
        {
          field: 'additional_comment',
          type: 'textArea',
          label: 'Any additional comments or suggestions for your mentor(s)?',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const TraineeToTrainerFeedbackDetail = props => {
  const {
    route: {params},
  } = props;

  const {training_batch} = params;

  return (
    <TraineeToTrainerFeedbackForm
      header={{
        title: 'Training Feedback',
        secondaryTitle: training_batch.name,
      }}
      readOnly
      {...props}
    />
  );
};
