import React from 'react';
import {Table} from '../../../components/table/Table';
import {Chip, Tag} from '../../../components/chip/Chip';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text, Row} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

const employeeStatusColors = {
  Available: 'information',
  'Partial Available': 'accent6',
  'Under Traning': 'accent6',
  Fixed: 'accent6',
  'T&M': 'accent6',
  'KT For Swapping': 'accent4',
  'KT For Resignation': 'accent3',
  Offline: 'accent3',
  Reserved: 'accent3',
  Unavailable: 'accent2',
  Billed: 'success',
  Bench: 'error',
  'Partialy billed': 'accent1',
};

const renderStatus = ({row = {}}) => {
  let {status} = row || {};
  if (!status) return null;
  return <Tag value={status} color={employeeStatusColors[status]} />;
};

export const EmployeeMonthWiseWorkLoadForProjectTeam = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
    benchComparision,
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');
  const {formProjectAllocation = false} = params || {};
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {from: start_date, to: end_date} = period || {};
  let datefilter = {};
  if (!benchComparision) {
    datefilter = {
      from_date: {$lte: end_date},
      $or: [{to_date: {$gte: start_date}}, {to_date: {$exists: false}}],
    };
  } else {
    datefilter = {};
  }
  return (
    <Table
      {...props}
      api={'/employees-monthwise/workload'}
      eventSourceId={['allocatedResource', 'workload-billable']}
      search={searchValue}
      addOnFilter={filter}
      limit={2000}
      searchFields={['name']}
      onRowPress={props => {
        const {row} = props;
        !formProjectAllocation &&
          navigation.navigate(`resource-allocations-list`, {
            row,
            employeeFilter: {
              employee: row?._id,
              is_shadow: {$in: [null, false]},
              is_internal: {$in: [null, false]},
              ...datefilter,
            },
          });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
                primarySubTitle={row?.types}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Language',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          width: 160,
        },
        {
          header: 'Skill Mentor',
          type: 'userAvatarChip',
          field: 'skill_leader',
          width: 180,
        },
        {
          header: 'Status',
          width: 120,
          render: renderStatus,
        },
        {
          header: 'Project',
          render: ({row}) => {
            const projectLength = (row?.project && row?.project.length) || 0;
            const projectSubCategory =
              row?.project &&
              row?.project[0] &&
              row?.project[0].project_sub_category;
            const projectName =
              row?.project && row?.project[0] && row?.project[0].project;
            if (row?.status == 'Billed' || row?.status == 'Partial Available') {
              if (projectLength > 1) {
                return (
                  <>
                    <Row gap={8} style={{alignItems: 'center'}}>
                      <Tag
                        value={projectName}
                        color={
                          row?.project &&
                          row?.project[0] &&
                          row?.project[0].color
                        }
                      />

                      <Text style={{...BODY2}}>+{projectLength - 1}</Text>
                    </Row>

                    <Text
                      style={{
                        ...CAPTION_LARGE,
                        color: colors.NEUTRAL_MEDIUM,
                        paddingLeft: 10,
                      }}>
                      {projectSubCategory}
                    </Text>
                  </>
                );
              } else {
                return (
                  <>
                    <Tag
                      value={projectName}
                      color={
                        row?.project && row?.project[0] && row?.project[0].color
                      }
                    />
                    <Text
                      style={{
                        ...CAPTION_LARGE,
                        color: colors.NEUTRAL_MEDIUM,
                        paddingLeft: 10,
                      }}>
                      {projectSubCategory}
                    </Text>
                  </>
                );
              }
            }
          },
        },
        {
          header: 'Allc. Hrs.',
          align: 'center',
          width: 80,
          render: ({row, styles: {rowText = {}}}) => {
            const {totalActualWorkingHours} = row;
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {totalActualWorkingHours} h
              </Text>
            );
          },
        },
        {
          header: 'Last Rating',
          align: 'center',
          render: ({row}) => {
            const projectRatingDetail = {
              'A+': {
                color: 'success',
                value: 'CE',
              },
              A: {
                color: 'accent6',
                value: 'OE',
              },
              'B+': {
                color: 'accent1',
                value: 'ME',
              },
              B: {
                color: 'warning',
                value: 'PE',
              },
              C: {
                color: 'error',
                value: 'DE',
              },
            };
            const project_rating = row?.assigned_to?.project_rating;
            if (project_rating) {
              const projectRatingInfo = projectRatingDetail[project_rating];
              return (
                <Chip
                  color={projectRatingInfo?.color}
                  displayTextColor
                  value={projectRatingInfo?.value}
                  borderRadius={6}
                />
              );
            }
          },
          width: 100,
        },
      ]}
      params={params}
    />
  );
};
