import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {OnlinePaymentTable} from './OnlinePaymentTables';

export const OnlinePaymentTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    ToApprove: {
      label: 'To approve',
      view: (
        <OnlinePaymentTable
          addOnFilter={{
            approval_status: 'new',
            payment_mode: {$in: ['NEFT', 'INTRABANK', 'RTGS']},
            ...filterValues.filter,
          }}
          searchValue={searchValue}
          tab="ToApprove"
          {...props}
        />
      ),
      api: '/payment/online',
      addOnFilter: {
        payment_mode: {$in: ['NEFT', 'INTRABANK', 'RTGS']},
        approval_status: 'new',
        ...filterValues.filter,
      },
      eventSourceId: 'OnlinePayment',
    },
    Approved: {
      label: 'Approved',
      view: (
        <OnlinePaymentTable
          addOnFilter={{
            approval_status: 'sent for approval',
            ...filterValues.filter,
          }}
          searchValue={searchValue}
          tab="Approved"
          {...props}
        />
      ),
      api: '/payment/online',
      addOnFilter: {
        approval_status: 'sent for approval',
        ...filterValues.filter,
      },
      eventSourceId: 'OnlinePayment',
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <OnlinePaymentTable
          addOnFilter={{
            approval_status: 'Rejected',
            ...filterValues.filter,
          }}
          searchValue={searchValue}
          tab="Rejected"
          {...props}
        />
      ),
      api: '/payment/online',
      addOnFilter: {
        approval_status: 'Rejected',
        ...filterValues.filter,
      },
      eventSourceId: 'OnlinePayment',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Payment No"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Payee',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
            {
              type: 'date',
              field: 'payment_date',
              label: 'Payment Date',
            },
            {
              type: 'autoComplete',
              label: 'Payment Type',
              options: [
                {label: 'Pre-payment', value: 'Prepayment'},
                {label: 'Post-payment', value: 'Bill'},
                {label: 'Loan', value: 'Loan'},
                {label: 'Direct', value: 'Direct'},
                {label: 'Other', value: 'Other'},
              ],
              placeholder: 'Select',
              field: 'payment_type',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              field: 'fund_center',
              label: 'Bank',
              api: '/banks',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Category',
              api: '/categories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'category',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportpayments',
              props: {
                addOnFilter: {...filterValues.filter},
              },
            });
          }}
        />,
      ]}
      {...props}
    />
  );
};
