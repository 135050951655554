import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';
import {Row, Styled, View} from '@unthinkable/react-core-components';

const StyledView = Styled(View)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  ${({value}) => (value ? {backgroundColor: value} : {})}
`;

const ColorRenderer = props => {
  return (
    <InputLabel {...props}>
      <Row gap={6} style={{alignItems: 'center'}}>
        <StyledView {...props} />
        <BaseTextRender {...props} />
      </Row>
    </InputLabel>
  );
};

export default ColorRenderer;
