export const AllNotificationStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    noDataIcon: icons.NoData,
    container: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: colors.BACKGROUND,
      borderColor: colors.OUTLINE,
      borderWidth: 1,
    },
    headerContainer: {
      paddingTop: 20,
      paddingRight: 20,
      paddingBottom: 4,
      paddingLeft: 20,
    },
    bodyContainer: {
      flex: 1,
      overflow: 'hidden',
      padding: 10,
      backgroundColor: colors.SURFACE2,
    },
  };
};

export const NotificationIconStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    notificationIcon: icons.Notification,
    badgeBackgroundColor: colors.BRAND_UPPER_MEDIUM,
    badgeTextColor: colors.BACKGROUND,
  };
};

export const NotificationCardStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      padding: 16,
      alignItems: 'flex-start',
      backgroundColor: colors.BACKGROUND,
      borderRadius: 6,
      overflow: 'hidden',
    },
    notepadEmojiStyle: {
      ...fonts.BODY2,
      fontSize: 18,
      lineHeight: '20px',
    },
    avatarContainer: {
      height: 40,
      width: 40,
      borderRadius: 100,
      borderWidth: 1,
      borderColor: colors.SURFACE2,
      backgroundColor: colors.SURFACE3,
      alignItems: 'center',
      justifyContent: 'center',
    },
    commentContainer: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 6,
      padding: 8,
    },
    highText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    mediumText: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    mediumTextCaption: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_MEDIUM,
    },
    lowTextFootnote: {
      ...fonts.FOOTNOTE,
      color: colors.NEUTRAL_LOW,
      paddingTop: 4,
    },
    hasNotSeenMessageDotStyle: {
      height: 8,
      width: 8,
      borderRadius: '100%',
      backgroundColor: colors.INFORMATION_HIGH,
      marginTop: 7,
    },
    hasSeenMessageDotStyle: {
      height: 8,
      width: 8,
      borderRadius: '100%',
      borderColor: colors.NEUTRAL_MEDIUM,
      borderWidth: 1,
      marginTop: 7,
    },
    indicatorContainerStyle: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};
