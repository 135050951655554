import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from '../../order/views/components/Durations';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  approveReimbursement,
  approveReimbursementCOO,
  dropReimbursement,
  forwardReimbursementToCOO,
  rejectReimbursement,
  sendForApproval,
} from '../controllers/StatusController';
import {activityLogReimbursement} from '../controllers/ReimbursementActivityLogController';
import {
  UserAvatarWithDateChip,
  renderType,
} from '../components/ReimbursementColumnRenders';

const repenReimbursement = ({navigation, params}) => ({
  title: 'Re-open ',
  onPress: props => {
    const {row} = props;
    navigation.navigate('add-remark-reimbursement', {
      reimbursement: row?._id,
    });
  },
});

const TeamReimbursementColumns = [
  {
    render: props => (
      <UserAvatarWithDateChip
        field={'employee'}
        dateField={'createdAt'}
        requested
        {...props}
      />
    ),
    header: 'Employee',
  },
  {
    align: 'center',
    render: renderType,
    header: 'Type',
  },
  {
    field: 'description',
    type: 'text',
    header: 'Description',
  },
  {
    header: 'Duration',
    align: 'center',
    render: ({row}) => {
      return Durations({
        toDate: row?.to_date,
        fromDate: row?.from_date,
      });
    },
  },

  {
    field: 'total_amount',
    type: 'number',
    header: 'Amount',
  },
];

export const ReimbursementTeamPendingTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
      'ReimbursementTeamReject',
    ],
  });

  return (
    <Table
      eventSourceId={[
        'ReimbursementTeamPending',
        'ReimbursementTeamApprove',
        'ReimbursementTeamReject',
        'ReimbursementTeamReferred',
      ]}
      api={`/reimbursement/team/Pending`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={TeamReimbursementColumns}
      moreActions={() => [
        approveReimbursement({invoke}),
        forwardReimbursementToCOO({invoke}),
        activityLogReimbursement({navigation, params}),
        rejectReimbursement({navigation}),
      ]}
    />
  );
};

export const ReimbursementTeamApprovedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/reimbursement/team/Approved`}
      eventSourceId={[
        'ReimbursementTeamPending',
        'ReimbursementTeamApprove',
        'ReimbursementTeamReject',
        'ReimbursementTeamReferred',
        ''
      ]}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          type: 'date',
          header: 'Approved On',
          field: 'approved_on',
        },
      ]}
      moreActions={() => [
        repenReimbursement({navigation, params}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementTeamRejectTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      eventSourceId={[
        'ReimbursementTeamPending',
        'ReimbursementTeamApprove',
        'ReimbursementTeamReject',
        'ReimbursementTeamReferred',
      ]}
      api={`/reimbursement/team/Rejected`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          type: 'date',
          header: 'Rejected On',
          field: 'rejected_on',
        },
        {
          field: 'reject_reason',
          type: 'text',
          header: 'Reason',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementTeamReOpenTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
      'ReimbursementTeamReject',
      'ReimbursementTeamReferred',
    ],
  });

  return (
    <Table
      api={`/reimbursement/team/ReOpen`}
      eventSourceId={[
        'ReimbursementTeamPending',
        'ReimbursementTeamApprove',
        'ReimbursementTeamReject',
        'ReimbursementTeamReferred',
      ]}
      // addOnFilter={{organization: organization?._id}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          field: 'remark',
          type: 'text',
          header: 'Reason',
        },
      ]}
      moreActions={() => [
        // alreadyProcessed({invoke}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementTeamReferredTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
      'ReimbursementTeamReject',
      'ReimbursementTeamReferred',
    ],
  });

  return (
    <Table
      eventSourceId={'ReimbursementTeamReferred'}
      api={`/reimbursement/team/RefferedPending`}
      // addOnFilter={{referred: true}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          header: 'Referred By',
          render: props => (
            <UserAvatarWithDateChip
              field={'referred_by'}
              dateField={'referred_on'}
              referred
              {...props}
            />
          ),
        },
      ]}
      moreActions={() => [
        // approveReimbursementCOO({invoke, visible: true}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementCOOReferredTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
      'ReimbursementTeamReject',
      'ReimbursementTeamReferred',
    ],
  });

  return (
    <Table
      eventSourceId={'ReimbursementTeamReferred'}
      api={`/reimbursement/COO/RefferedPending`}
      // addOnFilter={{referred: true}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          header: 'Referred By',
          render: props => (
            <UserAvatarWithDateChip
              field={'referred_by'}
              dateField={'referred_on'}
              referred
              {...props}
            />
          ),
        },
      ]}
      moreActions={() => [
        approveReimbursementCOO({invoke, visible: true}),
        rejectReimbursement({navigation}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementCOOApprovedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
      'ReimbursementTeamReject',
      'ReimbursementTeamReferred',
    ],
  });

  return (
    <Table
      eventSourceId={'ReimbursementTeamReferred'}
      api={`/reimbursement/COO/Approved`}
      // addOnFilter={{referred: true}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          header: 'Referred By',
          render: props => (
            <UserAvatarWithDateChip
              field={'referred_by'}
              dateField={'referred_on'}
              referred
              {...props}
            />
          ),
        },
      ]}
      moreActions={() => [
        approveReimbursementCOO({invoke, visible: true}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementCOORejectedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementTeamPending',
      'ReimbursementTeamApprove',
      'ReimbursementTeamReject',
      'ReimbursementTeamReferred',
    ],
  });

  return (
    <Table
      eventSourceId={'ReimbursementTeamReferred'}
      api={`/reimbursement/COO/Rejected`}
      // addOnFilter={{referred: true}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...TeamReimbursementColumns,
        {
          header: 'Referred By',
          render: props => (
            <UserAvatarWithDateChip
              field={'referred_by'}
              dateField={'referred_on'}
              referred
              {...props}
            />
          ),
        },
      ]}
      moreActions={() => [
        approveReimbursementCOO({invoke, visible: true}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};
