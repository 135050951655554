import React from 'react';

import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  ClosedProbationRequestsHr,
  ClosedProbationRequestsManager,
  PendingProbationRequestsHr,
  PendingProbationRequestsManager,
} from './ProbationList';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const ProbationConfirmationHrTabs = props => {
  let {route: {params} = {}} = props;
  const {department} = params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
    filter: {
      "employee.department": department,
      "employee.employee_status": 'Active',
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.pending = {
    label: 'Due',
    params: {
      ...params,
    },
    limit: 5000,
    eventSourceId: 'Probation',
    view: (
      <PendingProbationRequestsHr
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            searchFields: ['name', 'official_email_id', 'employee_code'],
            field: 'employee',
          },
        ]}
      />,
    ],
  };

  tabs.closed = {
    label: 'Closed',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'Probation',
    view: (
      <ClosedProbationRequestsHr
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            asParam
          />,
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'employee',
          },
        ]}
      />,
    ],
  };
  return (
    <TabView
      tabs={tabs}
      {...props}
      // actions={[
      //   <SearchFilter
      //     value={searchValue}
      //     onChangeFilter={onChangeFilter}
      //     placeholder="Search"
      //   />,
      //   <PeriodRangeFilter
      //     onChangeFilter={onChangeFilter}
      //     filterValues={filterValues}
      //     asParam
      //   />,
      //   <GroupFilter
      //     filterValues={filterValues}
      //     applyFilter={applyFilter}
      //     filters={[
      //       // {
      //       //   type: 'date',
      //       //   field: 'resign_date_new',
      //       //   label: 'Resign Date',
      //       // },
      //       {
      //         type: 'autoComplete',
      //         placeholder: 'Select',
      //         options: [
      //           {label: 'Regrettable', value: 'Regrettable'},
      //           {label: 'Desirable', value: 'Desirable'},
      //         ],
      //         field: 'desireable_status',
      //         label: 'Desireable Status',
      //         suggestionField: 'label',
      //         keyField: 'value',
      //         valueField: 'label',
      //       },
      //       {
      //         type: 'autoComplete',
      //         field: 'exit_type',
      //         label: 'Type',
      //         options: ['Involuntary', 'Voluntary'],
      //       },
      //     ]}
      //   />,
      // ]}
    />
  );
};

export const ProbationConfirmationManagerTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.pending = {
    label: 'Due',
    params: {
      ...params,
    },
    limit: 5000,
    eventSourceId: 'Probation',
    view: (
      <PendingProbationRequestsManager
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'employee',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };

  tabs.closed = {
    label: 'Closed',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'Probation',
    view: (
      <ClosedProbationRequestsManager
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          <PeriodRangeFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            asParam
          />,
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'employee',
          },
        ]}
      />,
    ],
  };
  return (
    <TabView
      tabs={tabs}
      {...props}
      // actions={[
      //   <SearchFilter
      //     value={searchValue}
      //     onChangeFilter={onChangeFilter}
      //     placeholder="Search"
      //   />,
      //   <PeriodRangeFilter
      //     onChangeFilter={onChangeFilter}
      //     filterValues={filterValues}
      //     asParam
      //   />,
      //   <GroupFilter
      //     filterValues={filterValues}
      //     applyFilter={applyFilter}
      //     filters={[
      //       // {
      //       //   type: 'date',
      //       //   field: 'resign_date_new',
      //       //   label: 'Resign Date',
      //       // },
      //       {
      //         type: 'autoComplete',
      //         placeholder: 'Select',
      //         options: [
      //           {label: 'Regrettable', value: 'Regrettable'},
      //           {label: 'Desirable', value: 'Desirable'},
      //         ],
      //         field: 'desireable_status',
      //         label: 'Desireable Status',
      //         suggestionField: 'label',
      //         keyField: 'value',
      //         valueField: 'label',
      //       },
      //       {
      //         type: 'autoComplete',
      //         field: 'exit_type',
      //         label: 'Type',
      //         options: ['Involuntary', 'Voluntary'],
      //       },
      //     ]}
      //   />,
      // ]}
    />
  );
};
