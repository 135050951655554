export const ChatComponentStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    AddIcon: icons.Add,
    MessageTyping: icons.MessageTyping,
    chatContainerStyle: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flex: 1,
    },
    generatedTextContainerStyle: {
      alignSelf: 'center',
      marginBottom: 12,
      padding: 8,
      borderColor: colors.OUTLINE,
      borderWidth: 1,
      borderRadius: 8,
    },
    generatedTextStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    loadingTextStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      textAlign: 'center',
      marginBottom: 4,
    },
    titleTextStyle: {...fonts.HEADING1, color: colors.NEUTRAL_MEDIUM},
    countTextStyle: {...fonts.TITLE, color: colors.ACCENT3_HIGH},
    inputTextStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
      maxHeight: 500,
      minHeight: 24,
      outline: 'none',
      resize: 'none',
      overflow: 'hidden',
    },
    commentBoxContainerStyle: {
      padding: 16,
      backgroundColor: colors.SURFACE2,
    },
    stopButtonContainerStyle: {
      position: 'absolute',
      bottom: 88,
      alignSelf: 'center',
    },
    loadingIconStyle: {
      alignSelf: 'center',
      height: 100,
    },
  };
};

export const ChatHeaderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    starIcon: icons.StarIcon,
    iconSizeStyle: {width: 24, height: 24},
    iconContainerStyle: {
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
      alignSelf: 'flex-start',
      padding: 6,
      borderRadius: 8,
    },
    headerContainerStyle: {
      padding: 16,
      //   backgroundColor: colors.BACKGROUND,
      overflow: 'hidden',
      marginBottom: 12,
      borderBottomWidth: 1,
      borderColor: colors.SURFACE2,
    },
    titleTextStyle: {...fonts.BODY1, color: colors.BRAND_UPPER_MEDIUM},
    subTitleTextStyle: {...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM},
  };
};

export const PreText = ({theme: {}}) => {
  return {
    sm: {
      tableRowStyle: {flex: 1, flexDirection: 'row'},
      cellStyle: {
        flex: 1,
        borderWidth: 1,
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    md: {
      tableRowStyle: {flex: 1, flexDirection: 'row'},
      cellStyle: {
        flex: 1,
        borderWidth: 1,
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  };
};
