import {InputStyles} from '../text/theme';

export const MentionsInputStyles = ({theme}) => {
  const {shadows, fonts, colors} = theme;
  const styles = InputStyles({theme});
  return {
    ...styles,
    list: {
      ...shadows.L,
      borderRadius: 4,
      backgroundColor: theme.colors.BACKGROUND,
      borderWidth: 1,
      borderColor: theme.colors.OUTLINE,
    },
    inputContainer: {},
    mentionsText: {...fonts.BODY3, color: colors.ACCENT1_HIGH},
  };
};

export const MentionTextStyles = ({theme}) => {
  const {colors, fonts} = theme;
  return {
    message: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    mentionsText: {
      ...fonts.BODY3,
      color: colors.ACCENT1_HIGH,
    },
  };
};
