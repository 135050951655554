import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

export const OrganizationThreadTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {row = {}} = params;
  return (
    <Table
      {...props}
      params={params}
      api={`/threads`}
      filter={{project: row?._id}}
      eventSourceId={['performance-report', 'threadAdded']}
      renderHeader={() => {
        return <TableHeader title="Threads" />;
      }}
      fields={{
        name: 1,
        project_owner_id: {name: 1, color: 1},
        project: {project: 1},
      }}
      sort={{plan_project_audit: -1}}
      columns={[
        {
          field: 'name',
          type: 'text',
          header: 'Name',
        },
        {
          field: 'project.project',
          type: 'text',
          header: 'Project',
        },
        {
          field: 'project_owner_id.name',
          type: 'userAvatarChip',
          header: 'PM',
        },
      ]}
    />
  );
};
