import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const ReimbursementHeadTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['reimbursementhead']}
      api={`/reimbursementhead`}
      renderHeader={() => {
        return (
          <TableHeader
            title="Reimbursement Head"
            actions={[
              <AddButton
                title="Reimbursement Head"
                view="add-reimbursement-head"
                params={params}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-head`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          header: 'Name',
          field: 'name',
        },
        {
          type: 'text',
          header: 'Type',
          field: 'type',
        },
        {
          type: 'text',
          field: 'account.name',
          header: 'Account',
        },
      ]}
    />
  );
};
