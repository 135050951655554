import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import { CommunicationRender } from './LeadTable';

const ContactRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'website',
          type: 'text',
        },
        {field: 'email', type: 'text'},
      ]}
    />
  );
};

const LastCommunicationRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'last_communication',
          type: 'text',
        },
        {
          field: 'last_communication_date',
          type: 'date',
        },
      ]}
    />
  );
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'next_task', type: 'text'},
        {
          field: 'next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

export const ActiveProposalSubmittedTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {product} = params;

  const {
    markLost,
    updateLeadStatus,
    markNurturable,
    updateOwner,
    makeCustomer,
    communications
  } = leadControllers(props);

  return (
    <Table
      api={`/potentialcustomers`}
      filter={{
        product: product?._id,
        lead_status: 'Active',
        pipeline_type: 'Prospect',
        stage: {$in: ['53bbd36a79df44bac9b12f39']},
      }}
      fields={{
        owner: {name: 1, color:1},
        name: 1,
        sql_stage_date: 1,
        email: 1,
        website: 1,
        campaign_id: {name: 1},
        last_communication: 1,
        last_communication_date: 1,
        next_task: 1,
        next_task_date: 1,
      }}
      sort={{sql_stage_date: -1}}
      selection={{
        actions: [
          {title: 'Update Status', onPress: updateLeadStatus},
          {title: 'Update Owner', onPress: updateOwner},
        ],
      }}
      onRowPress={({row}) => {
        navigation.navigate(`proposal-submitted-detail`, {
          leadId: row._id,
        });
      }}
      columns={[
        {
          header: 'Owner',
          field: 'owner',
          type: 'userAvatarChip',
        },
        {
          header: 'Name',
          field: 'name',
        },
        {
          header: 'Date',
          field: 'sql_stage_date',
          type: 'date',
        },
        {
          header: 'Email',
          field: 'email',
        },
        {
          header: 'Website',
          field: 'website',
        },
        {
          header: 'Campaign',
          field: 'campaign_id.name',
        },
        {
          header: 'Contact',
          render: ContactRender,
        },
        {
          field: 'campaign_id.name',
          header: 'Campaign',
        },
        {
          header: 'Last Communication',
          onPress: communications,
          render: CommunicationRender,
          width:170
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Lost',
          onPress: markLost,
        },
        // {
        //   title: 'Mark Nurturable',
        //   onPress: markNurturable,
        // },
        {
          title: 'Make Customer',
          onPress: makeCustomer,
        },
      ]}
    />
  );
};
