import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {PocActiveTable, PocLostTable, PocWonTable} from './PocTable';
import {
  AutoCompleteFilter,
  SearchFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {Row} from '@unthinkable/react-core-components';
import {AddButton} from '../../../components/button/AddButton';

export const POCOppTabView = props => {
  const params = props?.route?.params;
  const {filterValues, onChangeFilter, searchValue} = useFilter({});
  const {filter} = filterValues;
  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/opportunity/poc/active',
    eventSourceId: 'POC',
    params: {
      ...params,
    },
    view: (
      <PocActiveTable
        // sort={{creation_date: -1}}
        {...props}
        search={searchValue}
        addOnFilter={filter}
        params={params}
      />
    ),
  };
  tabs.won = {
    label: 'Won',
    // api: '/opportunity/poc/won',
    eventSourceId: 'POC',
    params: {
      ...params,
    },
    view: (
      <PocWonTable
        // sort={{outcome_date: -1}}
        {...props}
        search={searchValue}
        addOnFilter={filter}
        params={params}
      />
    ),
  };
  tabs.lost = {
    label: 'Lost',
    api: '/opportunity/poc/lost',
    params: {
      ...params,
    },
    eventSourceId: 'POC',
    view: (
      <PocLostTable
        // sort={{outcome_date: -1}}
        {...props}
        search={searchValue}
        addOnFilter={filter}
        params={params}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter onChangeFilter={onChangeFilter} />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title=" POC"
            view="add-poc"
            params={props?.route?.params}
          />
        </Row>,
      ]}
    />
  );
};
