import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  AccpetedTaxDeclarationTable,
  OpenTaxDeclarationTable,
  ReopenTaxDeclarationTable,
  SubmittedTaxDeclarationTable,
} from './EmployeeSalaryTable';

export const TaxDeclarationTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});

  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    Open: {
      label: 'Open',
      view: <OpenTaxDeclarationTable filter={filter} {...props} />,
      eventSourceId: 'taxDeclaration',
      api: '/incometax/self',
      addOnFilter: {status: 'Open'},
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yearTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    Submitted: {
      label: 'Submitted',
      view: <SubmittedTaxDeclarationTable filter={filter} {...props} />,
      eventSourceId: ['taxDeclaration'],
      api: '/incometax/self',
      addOnFilter: {status: 'Submitted'},
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yearTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    Accepted: {
      label: 'Accepted',
      view: <AccpetedTaxDeclarationTable filter={filter} {...props} />,
      eventSourceId: 'taxDeclaration',
      api: '/incometax/self',
      addOnFilter: {status: 'Accepted'},
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Month',
              api: '/monthTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'month_id',
            },
            {
              type: 'autoComplete',
              label: 'Year',
              api: '/yearTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'year_id',
            },
          ]}
        />,
      ],
    },
    Reopen: {
      label: 'Reopen',
      view: <ReopenTaxDeclarationTable filter={filter} {...props} />,
      api: '/incometax/self',
      addOnFilter: {status: 'ReOpen'},
      eventSourceId: 'taxDeclaration',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
