import React, {useState} from 'react';
import {FeatureTypeDesignPreviewer} from './FeatureTypeDesignPreviewer';
import {useFetchData} from '../../../controllers/useFetchData';

export const AssetDesignsViewer = props => {
  let {
    route: {
      params: {
        data: propsData = [],
        feature,
        source_id,
        history_id,
        currentFeatureDesign,
      } = {},
    },
  } = props;

  if (!propsData.length && history_id) {
    const {data = []} = useFetchData({
      api: `/version/${history_id}/designs`,
    });
    propsData = data;
  }
  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);
  const typesCount = propsData?.length;
  let type = propsData[currentTypeIndex];

  return (
    <FeatureTypeDesignPreviewer
      typesCount={typesCount}
      history_id={history_id}
      sourceId={source_id}
      type={type}
      showTypeSelector={false}
      currentTypeIndex={currentTypeIndex}
      setCurrentTypeIndex={setCurrentTypeIndex}
      feature={feature}
      currentFeatureDesign={currentFeatureDesign}
    />
  );
};
