import React from 'react';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {useFetchData} from '../../../controllers/useFetchData';

const AllProducts = props => {
  const {user} = useAppStateContext();

  const {navigation} = props;

  const {
    filterValues: {params},
  } = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });

  const onRowPress = ({row}) => {
    navigation.navigate(`all-departments-dashboard`, {
      ...params,
      product: row.product,
      role: row?.role,
    });
  };

  let {data} = useFetchData({
    api: `/employees/${user.employee?._id}/accessDepartmentsOutput`,
    params: {
      ...params,
    },
    eventSourceId: 'Product',
    limit: 5000,
  });

  data = data?.filter(item => item?._id != 'noProductId');

  return (
    <Table
      data={data}
      params={params}
      onRowPress={onRowPress}
      columns={[
        {
          type: 'colorTag',
          field: 'product.name',
          colorField: 'product.color',
          header: 'Name',
          responsive: 'sm',
        },
      ]}
      {...props}
    />
  );
};

export default AllProducts;
