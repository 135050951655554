import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const PositiveResponse = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/campaignactions',
  });
  const {
    route: {params},
  } = props || {};
  const {row = {}} = params;

  return (
    <Form
      onSubmit={onSubmit}
      api={`/campaignactions/${row?._id}`}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Positive Response Date',
          placeholder: 'Positive Response Date',
          field: 'positive_response_on',
          type: 'date',
        },
      ]}
      {...props}
    />
  );
};

export const PositiveResponseForm = props => {
  return (
    <PositiveResponse mode="edit" header={'Positive Response'} {...props} />
  );
};
