import React from 'react';
import {MyCourseTabs} from './views/MyCourses';
import {MyMenteesTabs} from './views/EmployeeTrainingTabs';
import {TrainingBatchTabs} from './views/TrainingBatchTable';
import {SelfPipResourceTabs} from '../employee/views/PipResourceTabs';
import {TrainingProgramTab} from './views/TrainingProgramTabView';
import {AllTraineesTabs} from './views/AllTraineeTabs';
import {AllTrainerTabs} from './views/AllTrainersTabs';
import {AllStudyMaterailTabs, MyStudyMaterials} from './views/MyStudyMaterials';
import {TrainingGapReport} from './views/TrainingGapReport';
import ToolRecusiveTable from './views/ToolsRecursiveTable';
import {MockInterviewTabs, MockInterviewTabsCandidate, MockInterviewTabsInterviewer} from './views/MockInternviews';

export const ScreenMapping = [
  {
    id: 'trainee',
    view: props => {
      return <MyCourseTabs {...props} />;
    },
  },
  {
    id: 'mockinterviews',
    view: props => {
      return <MockInterviewTabs {...props} />;
    },
  },
  {
    id: 'mockinterviewsinterviewer',
    view: props => {
      return <MockInterviewTabsInterviewer {...props} />;
    },
  },
  {
    id: 'mockinterviewscandidate',
    view: props => {
      return <MockInterviewTabsCandidate {...props} />;
    },
  },
  {
    id: 'mytrainees',
    view: props => {
      return <MyMenteesTabs {...props} />;
    },
  },
  {
    id: 'coursebatches',
    view: props => {
      const {user} = props;
      const {employee} = user;
      return (
        <TrainingBatchTabs
          fromTrainerCourseBatches
          api="/getTrainerBatches"
          extraFilter={{
            $or: [
              {
                mentor: employee?._id,
              },
              {mentors: employee?._id},
              {
                'topic_wise_mentors.mentor': employee?._id,
              },
            ],
          }}
          moreActions={[
            {
              title: 'View Attendance',
              onPress: ({row}) => {
                navigation.navigate('view-training-batch-attendance', {
                  training_batch: row,
                  employee,
                });
              },
            },
            {
              title: 'Mark Attendance',
              onPress: ({row}) => {
                navigation.navigate('mark-training-batch-attendance', {
                  training_batch: row,
                  addOnFilter: {
                    mentor: employee?._id,
                  },
                });
              },
            },
          ]}
        />
      );
    },
  },
  {
    id: 'pip',
    view: props => {
      const {user} = props;
      const {employee} = user;
      <SelfPipResourceTabs
        myMenteesTabs
        addOnFilter={{status: 'completed', mentor: employee?._id}}
      />;
    },
  },
  {
    id: 'skill',
    view: props => {
      return <ToolRecusiveTable {...props} />;
    },
  },
  {
    id: 'coursesetup',
    view: props => {
      return <TrainingProgramTab {...props} />;
    },
  },
  {
    id: 'coursebatchesTraining',
    view: props => {
      return <TrainingBatchTabs {...props} />;
    },
  },
  {
    id: 'trainers',
    view: props => {
      return <AllTrainerTabs {...props} />;
    },
  },
  {
    id: 'trainees',
    view: props => {
      return <AllTraineesTabs {...props} />;
    },
  },
  {
    id: 'studymaterials',
    view: props => {
      return <AllStudyMaterailTabs {...props} />;
    },
  },
  {
    id: 'traininggapreport',
    view: props => {
      return <TrainingGapReport {...props} />;
    },
  },
];

// export const Screens = [
//   {
//     menuItemId: 'mytraining',
//     views: [
//       {
//         id: 'trainee',
//         label: 'Trainee',
//         view: props => {
//           return <MyCourseTabs {...props} />;
//         },
//       },
//       {
//         id: 'trainer',
//         label: 'Trainer',
//         section: true,
//         menus: [
//           {
//             id: 'mytrainees',
//             label: 'My Trainees',
//             view: props => {
//               return <MyMenteesTabs {...props} />;
//             },
//           },
//           {
//             id: 'coursebatches',
//             label: 'Course Batches',
//             view: props => {
//               const {user} = props;
//               const {employee} = user;
//               return (
//                 <TrainingBatchTabs
//                   fromTrainerCourseBatches
//                   api="/getTrainerBatches"
//                   extraFilter={{
//                     $or: [
//                       {
//                         mentor: employee?._id,
//                       },
//                       {mentors: employee?._id},
//                       {
//                         'topic_wise_mentors.mentor': employee?._id,
//                       },
//                     ],
//                   }}
//                   moreActions={[
//                     {
//                       title: 'View Attendance',
//                       onPress: ({row}) => {
//                         navigation.navigate('view-training-batch-attendance', {
//                           training_batch: row,
//                           employee,
//                         });
//                       },
//                     },
//                     {
//                       title: 'Mark Attendance',
//                       onPress: ({row}) => {
//                         navigation.navigate('mark-training-batch-attendance', {
//                           training_batch: row,
//                           addOnFilter: {
//                             mentor: employee?._id,
//                           },
//                         });
//                       },
//                     },
//                   ]}
//                 />
//               );
//             },
//           },
//           {
//             id: 'pip',
//             label: 'PIP',
//             view: props => {
//               const {user} = props;
//               const {employee} = user;
//               <SelfPipResourceTabs
//                 myMenteesTabs
//                 addOnFilter={{status: 'completed', mentor: employee?._id}}
//               />;
//             },
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menuItemId: 'skills',
//     views: [
//       {
//         id: 'skill',
//         label: 'Skill',
//         view: props => {
//           return <SkillMetricsTable fromHr={true} {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'courses',
//     views: [
//       {
//         id: 'coursesetup',
//         label: 'Course',
//         view: props => {
//           return <TrainingProgramTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'batches',
//     views: [
//       {
//         id: 'coursebatchesTraining',
//         label: 'Batches',
//         view: props => {
//           return <TrainingBatchTabs {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'trainers',
//     views: [
//       {
//         id: 'trainers',
//         label: 'Trainers',
//         view: props => {
//           return <AllTrainerTabs {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'trainees',
//     views: [
//       {
//         id: 'trainees',
//         label: 'Trainees',
//         view: props => {
//           return <AllTraineesTabs {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'studymaterials',
//     views: [
//       {
//         id: 'studymaterials',
//         label: 'Study Materials',
//         view: props => {
//           return <MyStudyMaterials {...props} />;
//         },
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'mytraining',
//     label: 'My Training',
//   },
//   // {
//   //   id: 'MyTrainees',
//   //   label: 'My Trainees',
//   // },
//   {
//     id: 'skills',
//     label: 'Skill',
//   },
//   {
//     id: 'courses',
//     label: 'Courses',
//   },
//   {
//     id: 'batches',
//     label: 'Batches',
//   },
//   {
//     id: 'trainers',
//     label: 'Trainers',
//   },
//   {
//     id: 'trainees',
//     label: 'Trainees',
//   },
//   {
//     id: 'studymaterials',
//     label: 'Study Materials',
//   },
// ];
