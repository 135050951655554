import React, {useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Col} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {PasswordInput} from '../../../components/form-editors/Editors';

import {EmailFormStyles} from './theme';
import {ResetPasswordForm} from './ResetPasswordForm';
import {useResetPassword} from '../../../modules/login/controllers';
import {useToast} from '@unthinkable/react-toast';

export const PasswordForm = props => {
  const {
    route: {params},
  } = props;
  const {email, otp} = params;
  const navigation = useNavigation();
  const toast = useToast();

  const {inputContainerStyle} = useStyles(EmailFormStyles);

  const {onResetForgotPassword} = useResetPassword({
    resetForgotPasswordApi: '/users/reset-forgot-password',
  });

  const [data, updateData] = useState({});

  const resetPassword = async () => {
    const result = await onResetForgotPassword({...data, otp, email});
    if (result) {
      navigation.navigate('login');
      toast({
        message: 'Password updated successfully.',
      });
    }
  };

  const confirmError =
    data.confirmPassword &&
    data.password &&
    data.password !== data.confirmPassword;

  return (
    <ResetPasswordForm
      heading={'Create New Password'}
      subHeading={`Please enter and confirm your new password. You will need to login after you reset.`}
      buttonText={`Reset Password`}
      onButtonPress={resetPassword}
      disabled={!data.password || !data.confirmPassword || confirmError}>
      <Col gap={16} style={inputContainerStyle}>
        <PasswordInput
          label="New Password"
          value={data['password']}
          onChangeText={text => {
            updateData({...data, password: text});
          }}
          required
        />
        <PasswordInput
          label="Confirm New Password"
          value={data['confirmPassword']}
          onChangeText={text => {
            updateData({...data, confirmPassword: text});
          }}
          onSubmitEditing={resetPassword}
          required
          error={confirmError ? 'Confirm password does not match' : void 0}
        />
      </Col>
    </ResetPasswordForm>
  );
};
