import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useAppStateContext} from '../../../providers/AppState';

const ComponentsRender = props => {
  const {field} = props;
  return (
    <NestedTable
      {...props}
      key={field}
      fields={[
        {
          type: 'autoComplete',
          field: 'salary_component_id',
          label: 'Salary Component',
          api: '/salaryComponents',
          suggestionField: 'name',
        },
        {
          type: 'number',
          field: 'amount',
          label: 'Amount',
        },
        {
          label: 'NIC',
          type: 'checkbox',
          field: 'isNotCtcIncluded',
        },
      ]}
      columns={[
        {
          header: 'Salary Component',
          field: 'salary_component_id.name',
          type: 'text',
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};

const PackageDetailComputation = fetch => {
  return {
    'set data of attendance based components': {
      compute: async data => {
        if (!data?.employee_level) {
          return;
        }
        const {
          ctc_per_month,
          working_days,
          variable_percentage,
          organization,
          employee_level,
          is_pf_included,
        } = data || {};

        if (!data.ctc_per_month) {
          return;
        }
        const {data: employeeLevelData} = await fetch({
          uri: '/levels',
          props: {
            filter: {
              _id: employee_level,
            },
            fields: {
              insurance_unthinkable: 1,
              insurance_daffodil: 1,
              books_and_periodicals: 1,
              professional_development: 1,
              telephone_internet: 1,
              lta: 1,
              gift_item: 1,
              sodexho_meal_vouchers: 1,
              medical_reimbersement: 1,
              child_education: 1,
              uniform_allowance: 1,
              hra_perc: 1,
              basic_perc: 1,
              gratuity_perc: 1,
            },
            only: true,
          },
        });
        if (!employeeLevelData) {
          return;
        }
        const {
          insurance_unthinkable,
          insurance_daffodil,
          books_and_periodicals,
          professional_development,
          telephone_internet,
          lta,
          gift_item,
          sodexho_meal_vouchers,
          medical_reimbersement,
          child_education,
          uniform_allowance,
          hra_perc,
          basic_perc,
          gratuity_perc,
        } = employeeLevelData || {};
        const saturdayWorkingAllowanceFor55Wd = ((ctc_per_month * 1) / 22) * 2;
        const saturdayWorkingAllowanceFor6Wd = ((ctc_per_month * 1) / 22) * 4;
        let artificialTotalAmount = 0;
        let ctcAmountVariableDeduction =
          ctc_per_month -
          ((variable_percentage &&
            ((ctc_per_month * variable_percentage) / 100).toFixed(0) * 1) ||
            0);
        const ctcAmountVariableDeductionForBasic =
          ctc_per_month -
          ((variable_percentage &&
            ((ctc_per_month * variable_percentage) / 100).toFixed(0) * 1) ||
            0);
        let noChangeHardComponent = [
          '53ae736b79df44bac9b0b9b6', //Basic
          '53ae738479df44bac9b0b9b9', //HRA
          '5bb72626d79fbb677289b7f2', //Saturday Working Allowance
          '53ae738479df44bac9b0b9bc', //Transport Allowance
          '53ae738f79df44bac9b0b9da', //PF Employer //Deductions
          '53ae73a779df44bac9b0ba0a', //Gratuity //Deductions
          '53ae739d79df44bac9b0b9f7', //Health Insurance //Deductions
          '53ae738879df44bac9b0b9c6', //PF Employer
          '53ae738879df44bac9b0b9c9', //Labour welfare Fund
        ];
        let changeableHardComponent = [
          '55926c8e75d1f19476f9176c', //Uniform Allowance
          '55926ce675d1f19476f91800', //Children Education Allowance
          '53ae73a079df44bac9b0b9fd', //Medical Reimbursement
          '55addc9c99e5d54e393e8420', //Sodexho Meal Voucher
          '55926e6875d1f19476f91cef', //Gift Allowance
          '55926fec75d1f19476f9270c', //leave trave allowance
          '55926d5075d1f19476f91935', //Telephone & Internet Allowance
          '55926d9a75d1f19476f91a10', //Professional Development Allowance
          '55926dd775d1f19476f91b83', //Books & periodicals Allowance
          '54097fe45d20628a1de84d23', //Special Allowance
          '53ae760779df44bac9b0ba10', // Delivery incentive Project
        ];
        const components = {};
        let insuranceAmount = 0;
        let amountToSeperate = 0;
        const variableAmount =
          (
            (variable_percentage &&
              (ctc_per_month * variable_percentage) / 100) ||
            0
          ).toFixed(0) * 1;
        if (working_days === 5) {
          ctcAmountVariableDeduction =
            ctc_per_month -
            ((variable_percentage &&
              ((ctc_per_month * variable_percentage) / 100).toFixed(0) * 1) ||
              0);
        } else if (working_days === 5.5) {
          ctcAmountVariableDeduction =
            ctc_per_month -
            ((variable_percentage &&
              ((ctc_per_month * variable_percentage) / 100).toFixed(0) * 1) ||
              0) -
            saturdayWorkingAllowanceFor55Wd.toFixed(0) * 1;
        } else if (working_days === 6) {
          ctcAmountVariableDeduction =
            ctc_per_month -
            ((variable_percentage &&
              ((ctc_per_month * variable_percentage) / 100).toFixed(0) * 1) ||
              0) -
            saturdayWorkingAllowanceFor6Wd.toFixed(0) * 1;
        }
        const {data: salaryComponentData} = await fetch({
          uri: '/salarycomponents',
          props: {
            filter: {
              _id: {
                $in: [...noChangeHardComponent, ...changeableHardComponent],
              },
            },
          },
        });
        if (salaryComponentData?.length > 0) {
          for (const salaryComponent of salaryComponentData) {
            if (salaryComponent?._id) {
              components[salaryComponent._id] = {
                _id: salaryComponent._id,
                name: salaryComponent.name,
              };
            }
          }
        }
        const basicTemporaryAmount =
          (
            (ctcAmountVariableDeductionForBasic * (basic_perc || 0)) /
            100
          ).toFixed(0) * 1;
        const gratuityPerc = gratuity_perc || 0;
        const hraAmount =
          ((basicTemporaryAmount * hra_perc) / 100).toFixed(0) * 1;

        const attendance_base_components = [
          {
            salary_component_id: {
              ...components['53ae736b79df44bac9b0b9b6'],
            },
            ctc_percentage: basic_perc,
            amount: basicTemporaryAmount,
          },
          {
            salary_component_id: {
              ...components['53ae738479df44bac9b0b9b9'],
            },
            ctc_percentage: hra_perc,
            amount: hraAmount,
          },
          {
            salary_component_id: {...components['53ae738479df44bac9b0b9bc']},
            amount: 1600,
          },
        ];
        const deductions_components = [
          {
            salary_component_id: {...components['53ae73a779df44bac9b0ba0a']},
            amount:
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1,
          },
          {
            salary_component_id: {...components['53ae738879df44bac9b0b9c9']},
            amount: 31,
          },
        ];

        const performance_base_components = [];

        if (
          organization == '5a3d06900731bf3c3668c6a5' ||
          organization == '60476b96a4e05f4e4e3e7455'
        ) {
          insuranceAmount = insurance_daffodil || 0;
        } else if (
          organization == '5a3d06b90731bf3c3668c6a6' ||
          organization == '60476bcbcada254e24c91e56'
        ) {
          insuranceAmount = insurance_unthinkable || 0;
        }
        if (is_pf_included) {
          deductions_components.push({
            salary_component_id: {...components['53ae738f79df44bac9b0b9da']},
            amount: 1800,
          });
          deductions_components.push({
            salary_component_id: {...components['53ae738879df44bac9b0b9c6']},
            amount: 1800,
          });
        }
        if (working_days === 5) {
          artificialTotalAmount =
            1600 +
            (is_pf_included ? 1800 : 0) +
            basicTemporaryAmount * 1 +
            ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
            ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
            insuranceAmount * 1 +
            (variableAmount || 0);
          amountToSeperate =
            ctcAmountVariableDeduction -
            (1600 +
              (is_pf_included ? 1800 : 0) +
              basicTemporaryAmount * 1 +
              ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
              insuranceAmount * 1);
        } else if (working_days === 5.5) {
          amountToSeperate =
            ctcAmountVariableDeduction -
            (1600 +
              (is_pf_included ? 1800 : 0) +
              basicTemporaryAmount * 1 +
              ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
              insuranceAmount * 1);
          attendance_base_components.push({
            salary_component_id: {...components['5bb72626d79fbb677289b7f2']},
            amount: saturdayWorkingAllowanceFor55Wd.toFixed(0) * 1,
          });
        } else if (working_days === 6) {
          amountToSeperate =
            ctcAmountVariableDeduction -
            (1600 +
              (is_pf_included ? 1800 : 0) +
              basicTemporaryAmount * 1 +
              ((basicTemporaryAmount * 1 * hra_perc) / 100).toFixed(0) * 1 +
              ((basicTemporaryAmount * 1 * gratuityPerc) / 100).toFixed(0) * 1 +
              insuranceAmount * 1);
          attendance_base_components.push({
            salary_component_id: {...components['5bb72626d79fbb677289b7f2']},
            amount: saturdayWorkingAllowanceFor6Wd.toFixed(0) * 1,
          });
        }
        if (amountToSeperate < 0) {
          amountToSeperate = 0;
        }
        if (insuranceAmount) {
          deductions_components.push({
            salary_component_id: {...components['53ae739d79df44bac9b0b9f7']},
            amount: insuranceAmount,
          });
        }
        let handleCheck = ({componentDetails, componentAmount}) => {
          amountToSeperate = amountToSeperate * 1;
          if (componentAmount && amountToSeperate > componentAmount) {
            amountToSeperate = (amountToSeperate - componentAmount) * 1;
            artificialTotalAmount += componentAmount || 0;
            attendance_base_components.push({
              salary_component_id: {...componentDetails},
              amount: componentAmount,
            });
          } else {
            if (componentDetails?._id == '54097fe45d20628a1de84d23') {
              artificialTotalAmount +=
                (amountToSeperate && amountToSeperate * 1) || 0;
              attendance_base_components.splice(3, 0, {
                salary_component_id: {...componentDetails},
                amount: amountToSeperate && amountToSeperate * 1,
              });
            }
          }
        };
        for (let component of changeableHardComponent) {
          if (component == '55926c8e75d1f19476f9176c') {
            handleCheck({
              componentDetails: components['55926c8e75d1f19476f9176c'],
              componentAmount: uniform_allowance || 0,
            });
          } else if (component == '55926ce675d1f19476f91800') {
            handleCheck({
              componentDetails: components['55926ce675d1f19476f91800'],
              componentAmount: child_education || 0,
            });
          } else if (component == '53ae73a079df44bac9b0b9fd') {
            handleCheck({
              componentDetails: components['53ae73a079df44bac9b0b9fd'],
              componentAmount: medical_reimbersement || 0,
            });
          } else if (component == '55addc9c99e5d54e393e8420') {
            handleCheck({
              componentDetails: components['55addc9c99e5d54e393e8420'],
              componentAmount: sodexho_meal_vouchers || 0,
            });
          } else if (component == '55926e6875d1f19476f91cef') {
            handleCheck({
              componentDetails: components['55926e6875d1f19476f91cef'],
              componentAmount: gift_item || 0,
            });
          } else if (component == '55926fec75d1f19476f9270c') {
            handleCheck({
              componentDetails: components['55926fec75d1f19476f9270c'],
              componentAmount: lta || 0,
            });
          } else if (component == '55926d5075d1f19476f91935') {
            handleCheck({
              componentDetails: components['55926d5075d1f19476f91935'],
              componentAmount: telephone_internet || 0,
            });
          } else if (component == '55926d9a75d1f19476f91a10') {
            handleCheck({
              componentDetails: components['55926d9a75d1f19476f91a10'],
              componentAmount: professional_development || 0,
            });
          } else if (component == '55926dd775d1f19476f91b83') {
            handleCheck({
              componentDetails: components['55926dd775d1f19476f91b83'],
              componentAmount: books_and_periodicals || 0,
            });
          } else if (component == '54097fe45d20628a1de84d23') {
            handleCheck({
              componentDetails: components['54097fe45d20628a1de84d23'],
              componentAmount: amountToSeperate || 0,
            });
          } else if (component == '53ae760779df44bac9b0ba10') {
            if (variableAmount) {
              performance_base_components.push({
                salary_component_id: {
                  ...components['53ae760779df44bac9b0ba10'],
                },
                amount: variableAmount,
              });
            }
          }
        }
        return {
          earnings: attendance_base_components,
          performance: performance_base_components,
          deductions: deductions_components,
        };
      },
      dependencies: [
        'ctc_per_month',
        'working_days',
        'variable_percentage',
        'organization',
        'employee_level',
        'is_pf_included',
      ],
      multi: true,
    },
    variable_percentage: {
      compute: ({employee_level}) => {
        return employee_level?.variable_perc;
      },
      dependencies: ['employee_level'],
    },
    total_amount_calculated: {
      compute: data => {
        let response = {};
        let linkStatus = false;
        let result = 0;
        for (let rs = 0; rs <= data.performance.length; rs++) {
          if (data?.performance?.[rs]?.link_to_project === true) {
            linkStatus = true;
            if (!data?.performance[rs]?.isNotCtcIncluded) {
              result += data?.performance[rs]?.amount || 0;
            }
          }
        }
        if (!linkStatus && !data?.performance?.[0]?.isNotCtcIncluded) {
          result = data?.performance?.[0]?.amount || 0;
        }
        response =
          (data?.earnings.reduce((accum, doc) => {
            if (!doc.isNotCtcIncluded) {
              accum = accum + (doc.amount || 0);
            }
            return accum;
          }, 0) || 0) +
          result +
          (data?.deductions.reduce((accum, doc) => {
            let total = 0;
            //Esi employer,gratuity, pf employer ,health insurance
            if (
              doc?.salary_component_id?._id.toString() ===
                '53ae739a79df44bac9b0b9f1' ||
              doc?.salary_component_id?._id.toString() ===
                '53ae73a779df44bac9b0ba0a' ||
              doc?.salary_component_id?._id.toString() ===
                '53ae738f79df44bac9b0b9da' ||
              doc?.salary_component_id?._id.toString() ===
                '6124d7ff9b6ed50d4422690a' ||
              doc?.salary_component_id?._id.toString() ===
                '53ae739d79df44bac9b0b9f7'
            ) {
              if (!doc.isNotCtcIncluded) {
                total = doc.amount || 0;
              }
            }
            return accum + total;
          }, 0) || 0);
        return response.toFixed(0) * 1;
      },
      dependencies: ['earnings', 'performance', 'deductions', 'bonus'],
    },
  };
};

export const CheckPackageBreakdown = props => {
  const {download} = useAppStateContext();
  const onSubmit = data => {
    if (data.ctc_per_month != data.total_amount_calculated) {
      throw new Error('Total monthly ctc and calculated amount not match');
    }
    download({
      uri: '/hrRecruitmentPackageDownload',
      props: {
        ...data,
      },
    });
  };
  const {
    route: {params},
  } = props;
  const {candidate} = params;
  const {fetch} = useAppStateContext();
  return (
    <Form
      {...props}
      header={{
        title: 'Check Package Breakdown',
        secondaryTitle: 'You can check Package Breakdown',
      }}
      onSubmit={onSubmit}
      submitAction={'Download'}
      mode="edit"
      api={'/candidates/' + candidate._id}
      fields={{
        organization: 1,
        working_days: 1,
        department: {
          working_days: 1,
        },
      }}
      defaultValues={{
        organization: candidate?.organization,
        working_days: candidate?.department?.working_days,
        department: candidate?.department?._id,
      }}
      computations={{...PackageDetailComputation(fetch)}}
      layoutFields={[
        {
          label: 'Basic Details',
          fields: [
            {
              type: 'number',
              field: 'ctc_per_month',
              label: 'CTC Per Month',
              size: 'small',
            },
            {
              type: 'number',
              field: 'total_amount_calculated',
              label: 'Total Amount Calculated',
              readOnly: true,
              size: 'small',
            },
            {
              type: 'autoComplete',
              label: 'Employee Level',
              field: 'employee_level',
              api: '/levels',
              suggestionField: 'level',
              fields: {
                level: 1,
                variable_perc: 1,
              },
              size: 'small',
            },
            {
              type: 'autoComplete',
              label: 'Working Days',
              field: 'working_days',
              options: [5, 5.5, 6],
              size: 'small',
            },
            {
              type: 'checkbox',
              label: 'Is PF Included',
              field: 'is_pf_included',
              size: 'small',
            },
            {
              type: 'number',
              label: 'Variable Percentage',
              field: 'variable_percentage',
              size: 'small',
            },
          ],
        },
        {
          groups: [
            {
              label: 'Earnings',
              fields: [
                {
                  field: 'earnings',
                  nested: true,
                  render: ComponentsRender,
                },
              ],
            },
            {
              label: 'Performance',
              fields: [
                {
                  field: 'performance',
                  nested: true,
                  render: ComponentsRender,
                },
              ],
            },
            {
              label: 'Deductions',
              fields: [
                {
                  field: 'deductions',
                  nested: true,
                  render: ComponentsRender,
                },
              ],
            },
            {
              label: 'Bonus',
              fields: [
                {
                  field: 'bonus',
                  nested: true,
                  render: ComponentsRender,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};
