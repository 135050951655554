import React from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {TeamTasksTable} from '../../task/views/TasksTable';
import {AboutMe} from '../../common/views/AboutMe';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {useFetchData} from '../../../controllers/useFetchData';
import OrganizationGroupNavigator from './OrganizationGroupNavigator';
import {useAccessRight} from '../../../controllers/useAccessRight';
import SetupNavigator from '../../common/views/SetupNavigator';
import MasterSetupNavigator from '../../common/views/MasterSetupNavigator';
import {MyAttendanceTab} from './EmployeeAttendanceTab';
import {TeamAttendanceTab} from './TeamAttendanceTab';
import {TeamRequestTab} from './TeamRequestTab';
import TimesheetRequestTab from './TimesheetRequestTab';
import TimesheetVerifyRequestTab from './TimesheetVerifyTab';

import {MySkillsTabView} from '../../pmt/views/MySkillsTab';
// import {EmployeeTeamList} from '../../employee/views/EmployeeTeamsList';

import {MyCourseTabs, MyCourses} from '../../skills/views/MyCourses';
import {MyStudyMaterials} from '../../skills/views/MyStudyMaterials';
import CampaignSetupNavigator from '../../common/views/CampaignSetupNavigator';

import {
  SelfReimbursementTab,
  TeamReimbursementTab,
} from '../../reimbursement/views/ReimbursementTabs';

import {TeamAttendanceRequestTab} from './TeamAttendanceRequestTabs';
import WorkSpaceNavigator from '../../common/views/WorkSpaceNavigator';
import {ProjectTabs} from '../../pmt/views/ProjectTabs';
import {TeamWFHRequestTab} from './TeamWfhRequestTabs';
import {TeamTimesheetRequestTab} from './TeamTimesheetRequestTabs';
import {EmployeeTicketTabs} from '../../tickets/views/EmployeeTicketsTabs';
import {ExitManagementTab} from './ExitManagementTab';
import {TeamExitManagementTab} from './TeamExitManagementTab';
import {MyReferralTabs} from '../../recruitment/views/MyReferralTabs';
import {
  MyEfficiencyTable,
  MyTeamEfficiencyTable,
} from '../../task/views/EfficiencyTable';
import {TeamSkillLeaderDashboard} from '../../employee/views/SkillLeaderDashboard';
import {BudgetApproverTab} from '../../Expense/view/BudgetApproverTab';
import {ExpenseApproverTab} from '../../Expense/view/ExpenseApproverTab';
import {QuotationApproverTab} from '../../Expense/view/QuotationApproverTab';
import {MyITAssetTab} from '../../Expense/view/MyITAsset';
import {BillingStatusReportSelfTab} from '../../employee/views/BillingStatusReportTab';
import {NoDuesTeamTabs} from '../../employee/views/NoduesTabs';
import {ManPowerRequisitionTab} from '../../recruitment/views/ManPowerRequisitionTab';
import {ConfirmationManagerMenu} from '../../employee/views/ConfirmationMenu';
import {ProductivitySurveyTableSelf} from '../../employee/views/ProductivitySurveyTableSelf';
import {ProductivitySurveyTableManager} from '../../employee/views/ProductivitySurveyTableManager';
import {AboutMeTab} from '../../common/views/AboutMeTabs';
import TeamProjectFeedbackTab from './TeamProjectFeedbackTabs';
import SelfProjectFeedbackTab from './SelfManagerFeedbackTab';
import EmployeeAppraisalTab from './EmployeeAppraisalTabs';
import { SelfAppraisal, SelfAppraisalForm } from './SelfAppraisalForm';
import { EmployeeSelfAppraisalTable } from './EmployeeAppraisalTable';

const UserHome = createMenuNavigator();

const InactiveUserDashboardCamponent = ({user, ...props}) => {
  return (
    <UserHome.Navigator
      route={{
        ...props.route,
        params: {
          ...props?.route?.params,
        },
      }}>
      <UserHome.Section options={{label: 'Me'}}>
        <UserHome.Screen
          data-resource-id="My Salary"
          options={{
            label: 'Salary',
            onPress: () => {
              props.navigation.navigate('salary-menu', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <UserHome.Screen
          name="My Tickets"
          component={EmployeeTicketTabs}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Tickets',
          }}
        />
        <UserHome.Screen
          name="Exit Management"
          component={ExitManagementTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Exit Management',
          }}
        />
      </UserHome.Section>
    </UserHome.Navigator>
  );
};

const UserDashboardComponent = ({
  accessRights = [],
  maxLength,
  user,
  ...props
}) => {
  return (
    <UserHome.Navigator
      {...props}
      route={{
        ...props.route,
        params: {
          ...props?.route?.params,
        },
      }}>
      <UserHome.Section
        data-navigator-id="Workspace"
        component={WorkSpaceNavigator}
        options={{label: 'Workspace'}}
      />
      <UserHome.Section options={{label: 'Me'}}>
        <UserHome.Screen
          props={{fromMyView: true}}
          name="About me"
          // component={AboutMe}
          component={AboutMeTab}
          options={{
            label: 'About Me',
            // onPress: () => {
            //   props.navigation.navigate('about-me-tabs', {
            //     params: {
            //       ...props?.route?.params,
            //     },
            //   });
            // },
            actions: [<UserAvatar value={user} />],
          }}
        />
        <UserHome.Screen
          name="My skills"
          component={MySkillsTabView}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Skills',
          }}
        />
        <UserHome.Screen
          name="My Courses"
          component={MyCourseTabs}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Courses',
          }}
        />
        <UserHome.Screen
          name="Study Materials"
          component={MyStudyMaterials}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Study Materials',
          }}
        />

        <UserHome.Screen
          name="My attendance"
          component={MyAttendanceTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Attendance',
          }}
        />
        <UserHome.Screen
          data-resource-id="My Salary"
          options={{
            label: 'Salary',
            onPress: () => {
              props.navigation.navigate('salary-menu', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <UserHome.Screen
          data-resource-id="My Feedbacks"
          options={{
            label: 'Feedbacks',
            onPress: () => {
              props.navigation.navigate('self-feedbacks-personal-info', {
                params: {
                  ...props?.route?.params,
                },
              });
            },
          }}
        />
        <UserHome.Screen
          name="Productivity Feedbacks"
          component={ProductivitySurveyTableSelf}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Productivity Feedbacks',
          }}
          />
         {/* <UserHome.Screen
          props={{user: user,fromMyView: true}}
          name="Manager Feedback"
          component={SelfProjectFeedbackTab}
          options={{
            label: 'Manager Feedback',
          }}
        /> */}
         {/* <UserHome.Screen
          props={{user: user,fromMyView: true}}
          options={{
            label: 'Appraisal',
            onPress: () => {
              props.navigation.navigate('self-appraisal', {
                ...props?.route?.params,
              });
            },
          }}
        /> */}
        {/* <UserHome.Screen
          name="Timesheet"
          component={TimesheetRequestTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Timesheet',
          }}
        /> */}
        <UserHome.Screen
          name="SelfReimbursement"
          component={SelfReimbursementTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Reimbursements',
          }}
        />
        <UserHome.Screen
          name="MyReferrals"
          component={MyReferralTabs}
          options={{
            label: 'Referrals',
          }}
        />
        <UserHome.Screen
          name="My Tickets"
          component={EmployeeTicketTabs}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Tickets',
          }}
        />
        <UserHome.Screen
          name="Asset"
          component={MyITAssetTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Asset',
          }}
        />
        <UserHome.Screen
          name="Exit Management"
          component={ExitManagementTab}
          props={{user: user, fromMyView: true}}
          options={{
            label: 'Exit Management',
          }}
        />
      </UserHome.Section>
      <UserHome.Section options={{label: 'Team'}}>
        <UserHome.Group>
          <UserHome.Screen
            name="Team Tasks"
            component={TeamTasksTable}
            props={{user}}
            options={{
              label: 'Tasks',
            }}
          />
          <UserHome.Screen
            name="Output"
            component={MyTeamEfficiencyTable}
            props={{user, showOnlyDisqualified: false}}
            options={{
              label: 'Output',
            }}
          />
        </UserHome.Group>
        <UserHome.Group>
          <UserHome.Screen
            name="Attendance"
            component={TeamAttendanceTab}
            props={{user}}
            options={{
              label: 'Attendance',
            }}
          />
          <UserHome.Screen
            name="Attendance Request"
            component={TeamAttendanceRequestTab}
            props={{user}}
            options={{
              label: 'Attendance Requests',
            }}
          />
          <UserHome.Screen
            name="Timesheet Request"
            component={TeamTimesheetRequestTab}
            props={{user}}
            options={{
              label: 'Timesheet Requests',
            }}
          />
          <UserHome.Screen
            name="Request"
            component={TeamRequestTab}
            props={{user: user}}
            options={{
              label: 'Leave Requests',
            }}
          />
          <UserHome.Screen
            name="WFH Request"
            component={TeamWFHRequestTab}
            props={{user: user}}
            options={{
              label: 'WFH Requests',
            }}
          />
        </UserHome.Group>
        <UserHome.Group>
          <UserHome.Screen
            name="SkillLeader"
            component={TeamSkillLeaderDashboard}
            props={{user: user}}
            options={{
              label: 'Skill Team',
            }}
          />
          <UserHome.Screen
            data-resource-id="BillingStatusReport"
            name="Billing Status Report"
            component={BillingStatusReportSelfTab}
            props={{user: user, ...props}}
            options={{
              label: 'Billing Status Report',
            }}
          />
          <UserHome.Screen
            name="Resignation"
            component={TeamExitManagementTab}
            props={{user: user}}
            options={{
              label: 'Resignations Requests',
            }}
          />
          <UserHome.Screen
            name="ProductivitySurvey"
            component={ProductivitySurveyTableManager}
            props={{user: user}}
            options={{
              label: 'Productivity Feedbacks',
            }}
          />
          <UserHome.Screen
            name="TeamNodues"
            component={NoDuesTeamTabs}
            props={{user: user}}
            options={{
              label: 'No dues',
            }}
          />
          <UserHome.Screen
            name="Reimbursement"
            component={TeamReimbursementTab}
            props={{user: user}}
            options={{
              label: 'Reimbursement Requests',
            }}
          />
          <UserHome.Screen
            name="TeamConfirmations"
            props={{user: user}}
            options={{
              label: 'Confirmations',
              onPress: () => {
                props.navigation.navigate('confirmation-team-menu', {
                  params: {
                    ...props?.route?.params,
                  },
                });
              },
            }}
          />
          {/* <UserHome.Screen
            name="ProjectFeedback"
            props={{
              reporting_to: user && user.employee && user.employee._id,
              tab : "team"
            }}
            component={TeamProjectFeedbackTab}
            options={{
              label: 'Project Feedback',
            }}
          /> */}
           {/* <UserHome.Screen
            name="Performance"
            props={{
              reporting_to: user && user.employee && user.employee._id,
            }}
            component={EmployeeAppraisalTab}
            options={{
              label: 'Performance',
            }}
          /> */}
          {/* <UserHome.Screen
            name="Talent Requisition Approvals"
            component={MrfApproverTab}
            props={{user: user, fromMyView: true}}
            options={{
              label: 'Talent Requisition Approvals',
            }}
          /> */}

          {/* <UserHome.Screen
            name="Timesheet verify"
            component={TimesheetVerifyRequestTab}
            props={{user: user}}
            options={{
              label: 'Timesheet verify',
            }}
          /> */}
          <UserHome.Screen
            name="ManPowerRequisition"
            data-resource-id="ManPowerRequisition"
            component={ManPowerRequisitionTab}
            props={{user: user, fromMyView: true}}
            options={{
              label: 'Talent Requisition Requests',
            }}
          />
          {user?.employee?.ticket_team?.length && (
            <UserHome.Screen
              name="TeamTickets"
              props={{user: user}}
              options={{
                label: 'Tickets',
                onPress: () => {
                  props.navigation.navigate('ticket-menus');
                },
              }}
            />
          )}
        </UserHome.Group>
      </UserHome.Section>
      <UserHome.Section
        data-navigator-id="OrganizationGroup"
        component={OrganizationGroupNavigator}
        options={{label: 'Organization'}}
      />
      {/* <UserHome.Screen
        data-navigator-id="BudgetApprover"
        component={BudgetApproverTab}
        options={{label: 'Budget Request (Approver)'}}
      /> */}
      {/* <UserHome.Screen
        props={{fromMyView: true}}
        data-navigator-id="ExpenseApprover"
        component={ExpenseApproverTab}
        options={{label: 'Expense Request (Approver)'}}
      />
      <UserHome.Screen
        props={{reffer_to_coo: true}}
        data-navigator-id="ExpenseApproverCOO"
        component={ExpenseApproverTab}
        options={{label: 'Expense Request (COO)'}}
      />
      <UserHome.Screen
        props={{fromMyView: true}}
        data-navigator-id="QuotationApprover"
        component={QuotationApproverTab}
        options={{label: 'Quotation Approver'}}
      />
      <UserHome.Screen
        props={{reffer_to_coo: true}}
        data-navigator-id="QuotationApproverCOO"
        component={QuotationApproverTab}
        options={{label: 'Quotation Approver(COO)'}}
      /> */}
      <UserHome.Section data-navigator-id="Setup" options={{label: 'Setup'}}>
        <UserHome.Group>
          <UserHome.Screen
            data-resource-id="Employee"
            options={{
              label: 'Employees old',
              api: '/employees/active',
              limit: 5000,
              onPress: () => {
                props.navigation.navigate('employee-menu', {
                  ...props?.route?.params,
                });
              },
            }}
          />
          <UserHome.Screen
            data-navigator-id="Setup"
            options={{
              label: 'Admin Setup',
              onPress: () => {
                props.navigation.navigate('setup', {
                  ...props?.route?.params,
                });
              },
            }}
          />
          <UserHome.Screen
            data-navigator-id="MasterSetup"
            options={{
              label: 'Technical Setup',
              onPress: () => {
                props.navigation.navigate('company-setup', {
                  ...props?.route?.params,
                });
              },
            }}
          />
        </UserHome.Group>
      </UserHome.Section>
      <UserHome.Section
        data-navigator-id="CampaignSetup"
        component={CampaignSetupNavigator}
        options={{
          label: 'Campaign Setup',
        }}
      />
    </UserHome.Navigator>
  );
};

const ClientDashboard = props => {
  const {user} = useAppStateContext();

  return (
    <UserHome.Navigator {...props}>
      <UserHome.Screen
        props={{fromMyView: true}}
        name="My projects"
        component={ProjectTabs}
        options={{
          label: 'Projects',
        }}
        data-resource-id="My projects"
      />
      <UserHome.Screen
        data-resource-id="My efficiency"
        name="My output"
        component={MyEfficiencyTable}
        props={{user, fromMyView: true}}
        options={{
          label: 'Output',
        }}
      />
    </UserHome.Navigator>
  );
};

const UserDashboard = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();

  if (user?.employee?.employee_status == 'Inactive') {
    return <InactiveUserDashboardCamponent {...props} user={user} />;
  }

  const {data: accessRights = [], loading} = useFetchData({
    api: `/employees/${user?.employee?._id}/accessDetails`,
  });

  const canAccess = useAccessRight({accessRights: userAccessRights});

  if (loading !== false) {
    return null;
  }
  return (
    <UserDashboardComponent
      accessRights={accessRights}
      user={user}
      maxLength={5}
      canAccess={canAccess}
      {...props}
    />
  );
};

const Dashboard = props => {
  const {user} = useAppStateContext();
  if (user?.userType === 'employee') {
    return <UserDashboard {...props} />;
  }
  return <ClientDashboard {...props} />;
};

export default Dashboard;
