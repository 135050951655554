import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

export const AccessRoleTable = props => {
  const {navigation} = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'accessRoleRemoved',
  });
  const deleteTask = ({row}) => {
    deleteInvoke({
      uri: `/accessroles/${row?._id}`,
      props: {},
    });
  };

  return (
    <Table
      eventSourceId={['accessRoleAdded', 'accessRoleRemoved']}
      api={`/accessroles`}
      fields={{
        role: 1,
        access_groups: {
          access_group: {name: 1},
          resources: {resource: 1},
        },
        access_level: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-access-role`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'role',
          header: 'Role',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: deleteTask,
        },
      ]}
    />
  );
};
