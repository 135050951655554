import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AggregateCard} from '../../../components/card/AggregateCard';
import {useNavigation} from '@react-navigation/native';
import {useFetchData} from '../../../controllers/useFetchData';

const PerformanceHeader = ({outputs, params}) => {
  const navigation = useNavigation();
  const aggregates = outputs?.map(output => {
    return (
      <AggregateCard
        value={output.output}
        label={output.label}
        color={'ACCENT3_HIGH'}
        onPress={() => {
          navigation.navigate(`${output?.on_press_url}`, {...params});
        }}
      />
    );
  });
  return <TableHeader title="Performance" actions={aggregates} />;
};

const PerformanceTable = props => {
  let {
    route: {params},
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const navigation = useNavigation();
  const {role, period} = params;
  const {data: {inputs, outputs} = {}, loading} = useFetchData({
    api: `/departments/${params?.department?._id}/input-output`,
    params,
  });
  const inputDetail = ({row}) => {
    navigation.navigate(`${row?.on_press_url}`, {
      ...row,
      ...params,
      inputId: row._id,
      communicationFilter: {
        created_on: {
          $gte: period?.from,
          $lte: period?.to,
        },
      },
    });
  };
  return (
    <Table
      data={inputs}
      renderHeader={props => (
        <PerformanceHeader outputs={outputs} params={params} />
      )}
      loading={loading}
      onRowPress={inputDetail}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Type',
          responsive: 'sm',
        },
        {
          type: 'text',
          field: 'kpi',
          header: 'KPI',
        },
        {
          type: 'number',
          field: 'output',
          header: 'Output',
        },
        ...(role == 'admin' && false
          ? [
              {
                type: 'currency',
                field: 'direct_cost',
                header: 'Direct Cost',
              },
              {
                type: 'currency',
                field: 'salary_cost',
                header: 'Salary Cost',
              },
            ]
          : []),
      ]}
    />
  );
};

export default PerformanceTable;
