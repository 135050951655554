import { getPreviousMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {
    PeriodRangeFilter
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { LeadToQl, OpportunityToCustomer, QlToOpportunity } from './LeadToQl';

export const ConversionTabView = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });


  let {filter, params: filterParams = {}} = filterValues;

  const tabs = {
    leadToQl: {
      label: 'Lead To QL',
      view: (
        <LeadToQl
          filter={filter}
          filterParams={filterParams}
          params={{params}}
        />
      ),
    },

    leadToOpportunity: {
      label: 'QL to Opportunity',
      view: (
        <QlToOpportunity
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
    },

    opportunityToCustomer: {
      label: 'Opportunity to Customer',
      view: (
        <OpportunityToCustomer
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          monthWiseBreakup
        />,
        // <GroupFilter
        //   filterValues={filterValues}
        //   applyFilter={applyFilter}
        //   filters={[
        //     {
        //       type: 'autoComplete',
        //       label: 'Employee Size',
        //       api: '/employeesizes',
        //       placeholder: 'Select',
        //       suggestionField: 'number',
        //       valueField: 'number',
        //       field: 'employee_count',
        //     },
        //     {
        //       type: 'autoComplete',
        //       label: 'Country',
        //       api: '/countries',
        //       placeholder: 'Select',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       field: 'country',
        //     },
        //     {
        //       type: 'autoComplete',
        //       label: 'Industries',
        //       api: '/industries',
        //       placeholder: 'Select',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       field: 'industry',
        //     },
        //     {
        //       type: 'autoComplete',
        //       label: 'Channel',
        //       api: '/types/suggestionsForLead',
        //       placeholder: 'Select',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       field: 'campaign_type',
        //     },
        //     {
        //       label: 'Campaign',
        //       placeholder: 'Select',
        //       field: 'campaign_id',
        //       type: 'autoComplete',
        //       api: '/sourceSuggestions',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       params: ({filter: {campaign_type} = {}}) => {
        //         return {
        //           source: campaign_type,
        //         };
        //       },
        //     },
        //     {
        //       type: 'autoComplete',
        //       label: 'Owner',
        //       api: '/employeeSuggestions',
        //       placeholder: 'Select',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       secondarySuggestionField: 'official_email_id',
        //       field: 'owner',
        //     },

        //     {
        //       type: 'autoComplete',
        //       label: 'Inside Sales',
        //       api: '/employeeSuggestions',
        //       placeholder: 'Select',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       secondarySuggestionField: 'official_email_id',
        //       field: 'inside_sales',
        //     },

        //     {
        //       type: 'autoComplete',
        //       label: 'Status',
        //       options: [
        //         'Active',
        //         'DNC',
        //         'Free',
        //         'Lost',
        //         'Nurturable',
        //         'Unqualified',
        //         'Unsubscribe',
        //       ],
        //       placeholder: 'Select',
        //       field: 'lead_status',
        //     },
        //     {
        //       type: 'autoComplete',
        //       label: 'Communication',
        //       placeholder: 'Select',
        //       field: 'last_communication_date',
        //       options: ['Exists', 'Not Exists'],
        //       asParam: true,
        //     },
        //     {
        //       type: 'date',
        //       field: 'sql_date',
        //       asParam: true,
        //       label: 'QL Date',
        //     },
        //     {
        //       type: 'date',
        //       field: 'uql_date',
        //       asParam: true,
        //       label: 'UQL Date',
        //     },
        //     {
        //       type: 'date',
        //       field: 'next_task_date',
        //       asParam: true,
        //       label: 'Next Task Date',
        //     },
        //     {
        //       type: 'date',
        //       field: 'activity_date',
        //       asParam: true,
        //       label: 'Acitivity Date',
        //     },
        //     {
        //       type: 'date',
        //       field: 'nurturable_date',
        //       asParam: true,
        //       label: 'Nurturable Date',
        //     },
        //     {
        //       type: 'date',
        //       field: 'lost_date',
        //       asParam: true,
        //       label: 'Lost Date',
        //     },
        //   ]}
        // />,
      ]}
    />
  );
};
