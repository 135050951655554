import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {SubmitButton} from '@unthinkable/react-form';
import LoginPageComponent from '@unthinkable/react-login/src/LoginPageComponent.';
import {View} from '@unthinkable/react-core-components';
import {SetPasswordStyles} from '@unthinkable/react-login/src/theme';
import {useStyles} from '@unthinkable/react-theme';

export const ChangePassword = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/change-password',
  });

  return (
    <Form
      {...props}
      skipDefaultSave
      header="Change Password"
      successMessage="Password changed successfully"
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        if (data.newPassword !== data.confirmPassword) {
          throw new Error('New password and confirm password does not match');
        }
      }}
      layoutFields={[
        {
          type: 'password',
          field: 'currentPassword',
          label: 'Old Password',
          required: true,
        },
        {
          type: 'password',
          field: 'newPassword',
          label: 'New Password',
          required: true,
        },
        {
          type: 'password',
          field: 'confirmPassword',
          label: 'Confirm New Password',
          validate: (value, {values} = {}) => {
            if (value !== values.newPassword) {
              return 'Confirm password does not match';
            }
          },
          required: true,
        },
        {
          render: () => {
            return <SubmitButton label="Update" />;
          },
        },
      ]}
    />
  );
};

export const SetPassword = props => {
  const {navigation, route} = props;
  const password_token = route?.params?.password_token;
  const {onSubmit} = useFormSubmit({
    uri: '/new-user-password',
  });

  const {container} = useStyles(SetPasswordStyles);

  return (
    <LoginPageComponent>
      <View style={container}>
        <Form
          {...props}
          defaultValues={{password_token}}
          afterSubmit={() => {
            navigation.replace('login');
          }}
          skipDefaultSave
          header={{
            title: 'Set Password',
            secondaryTitle: 'Set your new password',
          }}
          successMessage="Password set successfully"
          onSubmit={onSubmit}
          footer={{
            actions: [
              <SubmitButton
                styles={{
                  container: {flex: 1},
                }}
                label="Set password"
              />,
            ],
          }}
          beforeSubmit={({data}) => {
            if (data.newPassword !== data.confirmPassword) {
              throw new Error(
                'New password and confirm password does not match',
              );
            }
          }}
          layoutFields={[
            {
              type: 'password',
              field: 'newPassword',
              label: 'New Password',
              required: true,
            },
            {
              type: 'password',
              field: 'confirmPassword',
              label: 'Confirm New Password',
              required: true,
            },
          ]}
        />
      </View>
    </LoginPageComponent>
  );
};
