import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';


const GSTTable = props => {
  const { filterParams, searchValue, params } = props;
  return (
    <Table
      {...props}
      api={'/gstInvoices'}
      addOnFilter={filterParams}
      params={params}
      eventSourceId="GST"
      searchFields={['invoice_number']}
      search={searchValue}
      limit={50}
      columns={[
        {
          type: 'userAvatarChip',
          field: 'customer',
          header: 'Customer',
          minWidth: 350,
        },
        {
          field: 'invoice_number',
          header: 'Invoice No.',
          type: 'colorTag',
          colorField: randomColor,
          width: 160,
        },
        {
          field: 'invoice_date',
          header: 'Invoice Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 140,
        },
        {
          type: 'text',
          field: 'invoice_type',
          header: 'Type',
          colorField: randomColor,
          value: ({ row }) => {
            if (row?.invoice_type) {
              let result = "";
              if (row.invoice_type === "a") {
                result = "Advance";
              } else if (row.invoice_type === "i") {
                result = "Invoice";
              } else if (row.invoice_type === "c") {
                result = "Credit";
              } else if (row.invoice_type === "d") {
                result = "Debit";
              }
              return result;
            }
          },
        },
        {
          field: 'customer.gstin',
          header: 'GSTIN',
          width: 180,
        },
        {
          field: 'hsn_no',
          header: 'HSN Code',
        },
        {
          field: 'invoice_amount',
          type: 'currency',
          header: 'Amount',
          width: 140
        },
        {
          field: 'total_tax_amount',
          type: 'currency',
          header: 'Taxable Amount',
          width: 140,
        },
        {
          field: 'sgst_amount',
          type: 'currency',
          header: 'SGST Amount',
          width: 140,
        },
        {
          field: 'cgst_amount',
          type: 'currency',
          header: 'CGST Amount',
          width: 140,
        },
        {
          field: 'igst_amount',
          type: 'currency',
          header: 'IGST Amount',
          width: 140,
        },
        {
          field: 'place_of_supply',
          header: 'Place Of Supply',
          width: 160,
        },
      ]}
    />
  );
};

export const GSTTab = props => {
  let { route: { params } = {} } = props;
  const { Download } = useTheme('icons');
  const { download } = useAppStateContext();
  const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let { filter } = filterValues;
  const tabs = {
    GST: {
      label: 'GST',
      view: (
        <GSTTable filterParams={filter} searchValue={searchValue} {...props} />
      ),
      eventSourceId: 'GST',
      api: '/gstInvoices',
      addOnFilter: filter,
      search: searchValue,
      searchFields: ['invoice_number'],
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportgst',
              props: {
                filter: { invoice_type: { $ne: "b" }, ...filter },
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Invoice Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'invoice_date',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Invoice Type',
              options: [
                { label: "Advance", value: "a" },
                { label: "Invoice", value: "i" },
                { label: "Credit", value: "c" },
                { label: "Debit", value: "d" },
                { label: "Bad Debt", value: "b" },
              ],
              placeholder: 'Select',
              field: 'invoice_type',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
      ]}
    />
  );
};
