import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const CustomerCurrencyTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      eventSourceId={['Currency']}
      api={`/daywisecurrencies`}
      fields={{
        currency: {currency: 1},
        exchange_rate: 1,
        from_date: 1,
        to_date: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Exchange Rate"
            actions={[
              <AddButton
                title="Exchange Rate"
                view="add-currency-exchange-rate"
                params={params}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-currency-exchange_rate`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Currency',
          field: 'currency.currency',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Exchange Rate',
          type: 'text',
          field: 'exchange_rate',
          width: 200,
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          width: 200,
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          width: 200,
        },
      ]}
    />
  );
};
