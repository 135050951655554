import {LeftMenuView} from '../../components/menu/LeftMenuView';
import {BudgetRequestTab} from './view/BudgetRequestTab';
import {ExpenseRequestTab} from './view/ExpenseRequestTab';
import {PurchaseOrderTabDepartment} from './view/PurchaseOrderTab';
import {PurchaseOrderTableDepartment} from './view/PurchaseOrderTable';
import {QuotationRequestTab} from './view/QuotationRequestTab';

export const ExpenseMenu = props => {
  const menus = [
    {
      label: 'Budgets',
      view: <BudgetRequestTab {...props} />,
    },
    {
      label: 'Expense requests',
      view: <ExpenseRequestTab {...props} />,
    },
    {
      label: 'Quotations',
      view: <QuotationRequestTab {...props} />,
    },
    {
      label: 'Purchase order',
      view: <PurchaseOrderTabDepartment {...props} />,
    },
    // {
    //   label: 'Expenses',
    // },
    // {
    //   label: 'Payments',
    // },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
