import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {PROJECT_THEME_TYPE_ID} from '../../common/constants/SourceConstants';
import {useAssetFormActions} from './assets/AssetUtility';
import {useInvoke} from '../../../controllers/useInvoke';

const ColorForm = props => {
  return (
    <Form
      layoutFields={[
        {
          label: 'Category',
          type: 'autoComplete',
          options: ['Brand', 'Neutral', 'Semantic', 'Custom'],
          field: 'category',
        },
        {
          label: 'Name',
          field: 'color_name',
          type: 'text',
        },
        {
          label: 'Light Mode Color',
          type: 'color',
          field: 'light_mode_color_code',
          size: 6,
        },
        {
          label: 'Dark Mode Color',
          type: 'color',
          field: 'dark_mode_color_code',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddColorForm = props => {
  const {
    route: {params},
  } = props;
  const {feature, row} = params;

  const {assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_THEME_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'colors',
  });

  const onSubmit = props => {
    putInvoke({
      uri: `/projectAssetVersions/${row?.versionId}/addColor`,
      props: {
        color: props,
        feature: {_id: feature?._id, feature: feature?.feature},
      },
    });
  };

  return (
    <ColorForm
      onSubmit={onSubmit}
      beforeSubmit={assetBeforeSubmit({
        data: row,
        skipRequiredValidation: true,
      })}
      submitAction={'Submit'}
      header={{
        title: 'Add Color',
      }}
      {...props}
    />
  );
};

export const UpdateColorForm = props => {
  const {
    route: {params},
  } = props;
  const {row, color, feature} = params;

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'colors',
  });

  const onSubmit = props => {
    putInvoke({
      uri: `/projectAssetVersions/${row?.versionId}/updateColor`,
      props: {
        color: props,
        feature: {_id: feature?._id, feature: feature?.feature},
      },
    });
  };
  return (
    <ColorForm
      onSubmit={onSubmit}
      defaultValues={color}
      submitAction={'Submit'}
      header={{
        title: 'Update Color',
      }}
      {...props}
    />
  );
};
