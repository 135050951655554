import {Styled, TouchableOpacity} from '@unthinkable/react-core-components';

export const EmptyStateStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    containerStyle: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12,
      paddingTop: '10%',
      paddingBottom: '10%',
    },
    playIcon: icons.PlayIcon,
    emptyStateIcon: icons.EmptyStateIcon,
    textStyle: {
      color: colors.BRAND_MEDIUM,
      fontSize: 16,
      fontFamily: 'PaytoneOne-Regular',
      lineHeight: '20px',
      fontWeight: 400,
    },
    subContainerStyle: {
      backgroundColor: colors.BACKGROUND,
      borderRadius: 12,
      padding: 12,
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)',
      alignItems: 'center',
    },
    subTextStyle: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.CAPTION_SMALL,
    },
  };
};

export const HelpGuideStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    helpTickIcon: icons.HelpTickIcon,
    helpArrowIcon: icons.HelpArrowIcon,
    containerStyle: {
      flex: 1,
      paddingTop: 20,
      paddingLeft: '10%',
      paddingRight: '10%',
      gap: 8,
    },
    headerTextStyle: {
      color: colors.NEUTRAL_HIGH,
      padding: '4px 32px 4px 32px',
      fontFamily: 'PaytoneOne-Regular',
      lineHeight: '24px',
      fontSize: 17,
    },
    itemContainerStyle: {
      gap: 8,
    },
    subContainerStyle: {
      padding: '24px 32px 24px 32px',
      gap: 8,
      borderRadius: 12,
    },
    titleContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    learnMoreContainerStyle: {
      flex: 1,
      // gap: 10,
      paddingLeft: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      // position: 'relative',
    },
    lineStyle: {
      flex: 1,
      height: 1,
      backgroundColor: colors.BRAND_MEDIUM,
      display: 'none',
      opacity: '50%',
      marginRight: 10,
    },
    learnMoreTextStyle: {
      fontSize: 13,
      color: colors.BRAND_UPPER_MEDIUM,
      lineHeight: '18px',
      fontFamily: 'PaytoneOne-Regular',
      display: 'none',
      paddingBottom: 4,
    },
    imageContainerStyle: {
      display: 'none',
      paddingTop: 3,
    },
    textStyle: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.SECTION_HEADING1,
    },
    descStyle: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.BODY3,
    },
  };
};

export const HelpItemContainer = Styled(TouchableOpacity).withConfig()`
  ${({styles}) => {
    return {...styles};
  }}
  ${props => `
  &:hover {
        box-shadow:0px 0px 12px rgba(0, 0, 0, 0.08);
       .childContainer {
          display: inline-block;
      }
   `}
  }
`;
export const HelpTemplateStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    containerStyle: {
      flex: 1,
      borderTopWidth: 1,
      borderColor: colors.OUTLINE,
    },
    subContainerStyle: {
      gap: 12,
    },
    titleStyle: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.BODY1,
    },
    pointersubContainerStyle: {
      padding: '12px 14px 14px 28px',
      gap: 12,
    },
    pointerStyle: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY3,
    },
    videoContainerStyle: {
      flex: 1,
      paddingTop: 26,
    },
  };
};
