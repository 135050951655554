import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ArchiveTaskForm = props => {
  const {
    route: {params},
  } = props;
  const {task} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: 'Task',
  });
  return (
    <Form
      header={{
        title: 'Archive Task',
        secondaryTitle: task?.name || '',
      }}
      submitAction="Archive"
      mode="edit"
      api={'/projectTasks/' + task?._id}
      fields={{
        _id: 1,
      }}
      beforeSubmit={({data}) => {
        if (task?.active_and_backlog_sub_task_count) {
          throw new Error(
            'Please mark all subtasks either complete or archive',
          );
        }
        return {
          data: {
            status: 'archived',
            ...data,
          },
        };
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'archive_reason',
          type: 'textArea',
          label: 'Reason',
          required: true,
        },
      ]}
    />
  );
};
