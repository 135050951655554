import React from 'react';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { Form } from '../../../components/form/Form';
import { useTheme } from '@unthinkable/react-theme';
import { Text, View } from '@unthinkable/react-core-components';
import { useAppStateContext } from '../../../providers/AppState';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import NestedTable from '../../../components/form/NestedTable';
import { useFetchData } from '../../../controllers/useFetchData';

export const NewAppraisalChipsOptions = [
    {
        value: "A+",
        label: "CE",
    },
    {
        value: "A",
        label: "OE",
    },
    {
        value: "B+",
        label: "ME",
    },
    {
        value: "B",
        label: "PE",
    },
    {
        value: "C",
        label: "DE",
    },
];


export const SelfAppraisalForm = props => {
    const {
        route: { params },
        defaultValues
    } = props;
    const row = defaultValues;
    const { ERROR_HIGH } = useTheme('colors');
    let { onSubmit } = useFormSubmit({
        uri: '/appraisals',
        eventSourceId: ['PendingProjectFeedback', 'ProcessedProjectFeedback'],
        ...props,
    });
    return (
        <Form
            api={`/selfappraisaldetail`}
            beforeSubmit={({data}) => {
                const { question_1_feedback, question_2_feedback, question_3_feedback, question_4_feedback } = data;
                return {
                    data: {
                        _id: data?._id,
                        question_1_feedback,
                        question_2_feedback,
                        question_3_feedback,
                        question_4_feedback,
                        self_appraisal_status: true,
                    },
                };
            }}
            onSubmit={onSubmit}
            submitAction={'Save'}
            // mode="edit"
            layoutFields={[
                {
                    label: 'Employee Details',
                    fields: [
                        {
                            placeholder: 'Name',
                            label: 'Name',
                            field: "employee.name",
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Experience',
                            label: 'Experience',
                            field: 'employee.experience',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            label: 'Joining Date',
                            placeholder: 'Joining Date',
                            field: "employee.joining_date",
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Appraisal Due Date',
                            label: 'Appraisal Due Date',
                            field: 'effective_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            placeholder: 'Appraisal Closure Date',
                            label: 'Appraisal Closure Date',
                            field: 'closed_date',
                            type: 'date',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'multiAutoComplete',
                            field: 'employee.reporting_to',
                            label: 'Reporting to',
                            api: '/employeeSuggestions',
                            suggestionField: 'name',
                            secondarySuggestionField: 'official_email_id',
                            valueField: 'name',
                            readOnly: true,
                            size: 6,
                          },
                        {
                            placeholder: 'Primary Manager',
                            label: 'Primary Manager',
                            field: 'employee.functional_manager.name',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                        },
                        {
                            type: 'autoComplete',
                            field: 'people_manager_appraisal_status',
                            label: 'Primary Manager Feedback Received',
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            size: 6,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: true,
                        },
                        {
                            type: 'autoComplete',
                            field: 'manager_appraisal_status',
                            label: 'Project Manager Feedback Received',
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            size: 6,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: true,
                        },
                        {
                            type: 'autoComplete',
                            field: 'unit_appraisal_status',
                            label: 'Unit Head Feedback Received',
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            size: 6,
                            suggestionField: 'label',
                            keyField: 'value',
                            valueField: 'label',
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "from_date",
                            label: 'Increment From Date',
                            placeholder: 'From Date',
                            readOnly: true,
                        },
                        {
                            type: 'date',
                            field: "to_date",
                            label: 'IncrementTo Date',
                            placeholder: 'To Date',
                            readOnly: true,
                        },
                    ],
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    label: 'OVERALL RATING',
                    fields: [
                        {
                            type: 'radioGroup',
                            label: 'Overall Rating',
                            placeholder: 'Overall Rating',
                            field: "overall_rating",
                            readOnly: true,
                            size: 6,
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            placeholder: 'Utilisation Percentage',
                            label: 'Utilisation Percentage',
                            field: 'utilisation',
                            type: 'text',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            render: ({ values }) => {
                                let {
                                    total_billed_hours = 0,
                                    total_available_hours = 0,
                                } = row || {};
                                let utilisation =
                                    (total_available_hours && total_billed_hours / total_available_hours) ||
                                    0;
                                utilisation *= 100;
                                let Format = value => (value && value.toFixed(2)) || 0;
                                total_billed_hours = Format(total_billed_hours || 0);
                                total_available_hours = Format(total_available_hours || 0);
                                utilisation = Format(utilisation || 0);
                                return (
                                    <>
                                        <Text style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            color: "#8E8E8E"
                                        }}>
                                            Utilisation
                                        </Text>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: "#4B4B4B",
                                            }}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'stretch',
                                                    minWidth: 20,
                                                    margin: 2,
                                                }}>
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_billed_hours && Number(total_billed_hours).toFixed(2)}
                                                </Text>
                                                <View style={{ height: 1, background: "#4B4B4B", }} />
                                                <Text style={{ textAlign: 'center' }}>
                                                    {total_available_hours}
                                                </Text>
                                            </View>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                }}>
                                                =
                                            </Text>
                                            <Text
                                                style={{
                                                    margin: 2,
                                                    fontFamily: 'Quicksand-Regular',
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                }}>
                                                {utilisation && Number(utilisation).toFixed(2)} %
                                            </Text>
                                        </View>
                                    </>
                                );
                            },
                        },
                        {
                            type: 'radioGroup',
                            label: 'Manager Rating',
                            placeholder: 'Manager Rating',
                            field: "pm_overall_rating",
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                            options: [
                                {
                                    value: "A+",
                                    label: 'CE',
                                },
                                {
                                    value: "A",
                                    label: 'OE',
                                },
                                {
                                    value: "B+",
                                    label: 'ME',
                                },
                                {
                                    value: "B",
                                    label: 'PE',
                                },
                                {
                                    value: "C",
                                    label: 'DE',
                                },
                            ],
                            editorAsRenderer: true,
                        },
                        {
                            field: 'rm_overall_score',
                            placeholder: "RM Feedback",
                            label: 'RM Feedback',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'mentorship_score',
                            placeholder: "Mentorship",
                            label: 'Mentorship',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            label: 'Innovation Score',
                            type: 'number',
                            size: 6,
                            readOnly: true,
                            visible: ({ values }) => {
                                if (values?.employee?.experience && Number(values?.employee?.experience) > 1.4) {
                                    return true
                                }
                            },
                        },
                    ],
                },
                {
                    label: 'Employee Self Assessment',
                    fields: [
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                            marginTop: "20px",
                                        }}> 1. Describe your significant accomplishments or contributions during this appraisal period.</Text>
                                );
                            }
                        },
                        {
                            type: 'textArea',
                            field: 'question_1_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 2. Have you successfully performed any new tasks or additional duties outside the scope of your regular responsibilities during the appraisal period? If so, please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_2_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 3. Innovation is one of our core values.Please share an instance where you have delivered an innovative solution at work during this appraisal period.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_3_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                        {
                            style: { flex: 1, justifyContent: 'left' },
                            render: ({ data = {} }) => {
                                return (
                                    <Text
                                        style={{
                                            fontFamily: 'Quicksand-Regular',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            marginLeft: "10px",
                                        }}> 4. Have you been deployed in any new technology or completed any certifications during the appraisal period?If yes,please specify.</Text>
                                );
                            },
                        },
                        {
                            type: 'textArea',
                            field: 'question_4_feedback',
                            placeholder: "Answer",
                            label: "Answer",
                            required: true,
                        },
                    ],
                },
                // {
                //     label: 'Project Manager Feedback',
                //     visible: ({ values }) => {
                //         if (values?.department_type != "Non Technical" && !values?.is_feedback_process_new) {
                //             return true
                //         }
                //     },
                //     fields: [
                //         {
                //             field: 'reporting_assessment_details',
                //             nested: true,
                //             readOnly: true,
                //             render: props => {
                //                 const {
                //                     form: { values = {} },
                //                 } = props;
                //                 return (
                //                     <NestedTable
                //                         {...props}
                //                         fields={[
                //                             {
                //                                 type: 'text',
                //                                 label: 'Quarter',
                //                                 header: 'Quarter',
                //                                 field: 'quater',
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Manager',
                //                                 header: 'Manager',
                //                                 field: 'reporting_to.name',
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Project',
                //                                 header: 'Project',
                //                                 field: 'project.project',
                //                             },
                //                             {
                //                                 field: 'overall_rating',
                //                                 label: 'Overall Rating',
                //                                 header: 'Overall Rating',
                //                                 width: 150,
                //                                 type: 'text'
                //                             },
                //                             {
                //                                 type: 'number',
                //                                 placeholder: 'Project Time Perc',
                //                                 field: 'project_time_perc',
                //                                 header: 'Project Time(DAYS)',
                //                                 width: 160,
                //                             },
                //                             {
                //                                 field: 'cooprative',
                //                                 label: 'Cooperative',
                //                                 header: 'Cooperative',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'delivery_quality',
                //                                 label: 'Delivery Quality',
                //                                 header: 'Delivery Quality',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'punctuality_rating',
                //                                 label: 'Punctuality',
                //                                 header: 'Punctuality',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'following_processes_rating',
                //                                 label: 'Following Processes',
                //                                 header: 'Following Processes',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'communucations_rating',
                //                                 label: 'Communications',
                //                                 header: 'Communications',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'responsible_rating',
                //                                 label: 'Responsibility',
                //                                 header: 'Responsibility',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'given_training_rating',
                //                                 label: 'Given Training',
                //                                 header: 'Given Training',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'attitude',
                //                                 label: 'Attitude',
                //                                 header: 'Attitude',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'taken_training_rating',
                //                                 label: 'Taken Training',
                //                                 header: 'Taken Training',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 field: 'certifications_rating',
                //                                 label: 'Certifications',
                //                                 header: 'Certifications',
                //                                 type: 'text',
                //                                 width: 150,
                //                             },
                //                             {
                //                                 type: 'text',
                //                                 label: 'Comments',
                //                                 field: 'comments',
                //                                 header: 'Comments',
                //                             },
                //                         ]}
                //                     />
                //                 );
                //             },
                //         },
                //     ]
                // },
                {
                    label: 'Project Manager Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical" && values?.is_feedback_process_new) {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'reporting_assessment_details_new',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Quarter',
                                                header: 'Quarter',
                                                field: 'quater',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Manager',
                                                header: 'Manager',
                                                field: 'reporting_to.name',
                                            },
                                            {
                                                type: 'text',
                                                label: 'Project',
                                                header: 'Project',
                                                field: 'project.project',
                                            },
                                            {
                                                field: 'overall_rating',
                                                label: 'Overall Rating',
                                                header: 'Overall Rating',
                                                width: 160,
                                                type: 'text',
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                type: 'number',
                                                placeholder: 'Project Time Perc',
                                                field: 'project_time_perc',
                                                header: 'Project Time(DAYS)',
                                                width: 150,
                                            },
                                            {
                                                field: 'delivery_quality',
                                                label: 'Delivery Quality',
                                                header: 'Delivery Quality',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                field: 'communucations_rating',
                                                label: 'Communications',
                                                header: 'Communications',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                field: 'punctuality_rating',
                                                label: 'Cultural & Behavioural',
                                                header: 'Cultural & Behavioural',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                field: 'attitude',
                                                label: 'Learning & Sharing',
                                                header: 'Learning & Sharing',
                                                type: 'text',
                                                width: 150,
                                                // value: ({ row }) => {
                                                //     for (const rating of NewAppraisalChipsOptions) {
                                                //         const { value, label } = rating;
                                                //         if (row && row.overall_rating && row.overall_rating === value) {
                                                //             return label
                                                //         }
                                                //     }
                                                // }
                                            },
                                            {
                                                type: 'text',
                                                label: 'Comments',
                                                field: 'comments',
                                                header: 'Comments',
                                            },
                                            //   {
                                            //     type : 'action',
                                            //     header:'Action',
                                            //     text : "View Detail",
                                            //     action: ({item}) => ({
                                            //       onPress : ({navigation}) => {
                                            //         navigation.navigate({ view: 'pm-edit-appraisal-requests', params: { item: item , isEditable : false} })
                                            //       }
                                            //     })

                                            //   }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical") {
                            return true
                        }
                    },
                    style: { flex: 1, justifyContent: 'left' },
                    render: ({ data = {} }) => {
                        return (
                            <View>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*CE: Consistently Exceeds Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*OE : Occasionally Exceeds Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*ME : Meet Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*PE : Partially Meet Expectation </Text>
                                <Text
                                    style={{
                                        fontFamily: 'Quicksand-Regular',
                                        fontSize: '10px',
                                        lineHeight: '13px',
                                        color: '#F3423D',
                                        marginLeft: "10px",
                                    }}>*DE : Does not Meet Expectation </Text>
                            </View>
                        );
                    },
                },
                {
                    label: 'Resource Management Feedback',
                    visible: ({ values }) => {
                        if (values?.department_type != "Non Technical" && values.rmFeedbackGeneratedNew) {
                            return true
                        }
                    },
                    fields: [
                        {
                            field: 'resourceManagementDetails',
                            nested: true,
                            readOnly: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                type: 'number',
                                                label: 'Training Given',
                                                header: 'Training Given',
                                                field: 'rm_training_given_score',
                                                editable: false,
                                                width: 120,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Certification',
                                                header: 'Certification',
                                                field: 'certification_score',
                                                editable: false,
                                                width: 120,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Employee Rejection',
                                                header: 'Employee Rejection',
                                                field: 'number_of_rejection',
                                                editable: false,
                                                width: 120,
                                            },
                                            {
                                                type: 'number',
                                                label: 'Interview Rejection',
                                                header: 'Interview Rejection',
                                                field: 'number_of_interview_rejection',
                                                editable: false,
                                                width: 120,
                                                visible: ({ values }) => {
                                                    const item = values;
                                                    if (item && item.employee && item.employee.employee_level && item.employee.employee_level.level && item.employee.employee_level.level < 4) {
                                                        return true
                                                    }
                                                }
                                            },
                                            {
                                                type: 'number',
                                                label: 'Mentorship',
                                                field: 'mentorship',
                                                header: 'Mentorship',
                                                editable: false
                                            }
                                        ]}
                                    />
                                );
                            },
                        },
                    ]
                },
                {
                    label: 'People Manager Overall Rating',
                    visible: ({ values }) => values && values.department_type != "Non Technical",
                    fields: [
                        {
                            field: 'people_manager_innovation_score',
                            placeholder: "Innovation Score",
                            size: 6,
                            label: 'Innovation Score',
                            type: 'number',
                            readOnly: true
                        },
                        {
                            type: 'textArea',
                            field: 'people_manager_comment',
                            placeholder: "Comment",
                            label: "Comments",
                            size: 6,
                            readOnly: true
                        },
                        {
                            render: (params) => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of 10`}
                                    </Text>
                                );
                            },
                            visible: ({ values }) => values?.employee?.employee_level?.level >= 4
                        },
                        {
                            render: (params) => {
                                return (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            color: ERROR_HIGH,
                                        }}>
                                        {`Out of 5`}
                                    </Text>
                                );
                            },
                            visible: ({ values }) => values?.employee?.employee_level?.level < 4
                        }
                    ],
                },
            ]}
            {...props}
        />
    );
};


export const SelfAppraisal = props => {
    let {
        route: { params },
    } = props;
    let readOnly = true
    const { data } = useFetchData({
        api: `/selfappraisaldetail`,
    });
    if (!data) {
        return null;
    }
    if (data?._id) {
        readOnly = false
    }
    return (
        <SelfAppraisalForm
            header={{
                title: 'Self Assessment'
            }}
            defaultValues={{
                people_manager_appraisal_status: false,
                unit_appraisal_status: false,
                manager_appraisal_status: false,
                ...data,
            }}
            readOnly={readOnly}
            {...props}
        />
    );
};
