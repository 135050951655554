import React from 'react';
import {Table} from '../../../components/table/Table';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import moment from 'moment';
import {Text} from '@unthinkable/react-core-components';
import {renderLocale} from './OrderTable';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';
import {useProjectResourceWorkingDataActions} from '../actions/ProjectResourceWorkingDataActions';
import {UserAvatarGroupChip} from '../../../components/avatar/UserAvatar';
import {TableHeader} from '../../../components/table/Headers';

const ACCOUNT_OWNER = {
  field: 'account_owner_id',
  header: 'AM',
  type: 'userAvatar',
  align: 'center',
  width: 60,
};

const DELIVERY_OWNER = {
  field: 'delivery_owner_id',
  header: 'DO',
  type: 'userAvatar',
  align: 'center',
  width: 60,
};

const VARIABLE_POTENTIAL = {
  field: 'variable_potential',
  header: 'Potential',
  type: 'number',
  width: 100,
};

const DELIVERIED_HOURS_AND_DATE = {
  type: 'date',
  header: 'Delivered',
  width: 150,
  align: 'center',
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={`${row?.billed_hours ? row?.billed_hours : 0} h`}
        items={[
          {
            value:
              row?.delivered_date &&
              moment(row?.delivered_date).format('DD MMM YYYY'),
          },
        ]}
      />
    );
  },
};

const MONTH = {
  header: 'Month',
  align: 'center',
  render: ({row, styles}) => {
    return (
      <Text style={{...styles.rowText, color: '#737373'}}>
        {row?.due_date && moment(row?.due_date).format('MMMM')}
      </Text>
    );
  },
  width: 130,
};

const SKILL = {
  field: 'skill_required.name',
  header: 'Skill',
  width: 150,
};

const TANDMPROJECT = {
  header: 'Project',
  minWidth: 200,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.project?.project}
        items={[{value: row?.delivery_number}]}
      />
    );
  },
};

const TANDMENGG = {
  header: 'Engg.',
  minWidth: 200,
  render: ({row}) => {
    return (
      <>
        <UserAvatarGroupChip value={row?.project_resource_users} />
        <PrimaryColumnCard items={[{value: row?.delivery_number}]} />
      </>
    );
  },
};

const ALLPROJECT = {
  header: 'Project',
  minWidth: 200,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.project?.project}
        items={[{value: row?.delivery_number}]}
      />
    );
  },
};

const DELIVERY_NO = {
  header: 'Delivery',
  type: 'text',
  field: 'delivery_number',
};

const FIXEDPROJECT = {
  header: 'Project',
  minWidth: 200,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.project?.project}
        items={[{value: row?.delivery_number}]}
      />
    );
  },
};

const CUSTOMER = {
  type: 'userAvatarChip',
  field: 'customer',
  header: 'Customer',
  minWidth: 220,
};

const TOTAL_AMOUNT = {
  field: 'base_amount',
  header: 'Total Amt.',
  type: 'currency',
  width: 150,
  aggregate: true,
};

const INVOICE_DUE_ON = {
  field: 'due_date',
  header: 'Invoice Due On',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YYYY',
  },
  align: 'center',
  width: 130,
};

const DUE_ON = {
  field: 'due_date',
  header: 'Due On',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YYYY',
  },
  align: 'center',
  width: 130,
};

const APPROVED = {
  field: 'due_date',
  header: 'Approved(by and Date)',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YYYY',
  },
  align: 'center',
  width: 160,
};

const PENDING_AMOUNT = {
  field: 'base_pending_amount',
  header: 'Pending Amt.',
  type: 'currency',
  width: 120,
  aggregate: true,
};

const AMOUNT_INR = {
  field: 'base_amount',
  header: 'Amount(INR)',
  type: 'currency',
  width: 200,
};

const AMOUNT_CC = {
  field: 'amount',
  header: 'Amount(CC)',
  type: 'number',
  formatOptions: ({row}) => {
    return {
      locale: renderLocale(row?.basecurrency_id?.currency),
      currency: row?.basecurrency_id?.currency,
    };
  },
};

const ORDER_NUMBER = {
  field: 'order.order_number',
  header: 'Order No.',
  width: 140,
  align: 'center',
};

const MILESTONE = {
  field: 'milestone.order_milestone_no',
  header: 'Milestone',
};

const MILESTONEWITHDELIVERYNO = {
  header: 'Milestone',
  width: 200,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.milestone?.order_milestone_no}
        items={[
          {value: row?.order?.order_number},
          {value: row?.delivery_number},
        ]}
      />
    );
  },
};

const CREDIT_NOTE_NO = {
  field: 'credit_note_no',
  header: 'Cr. Note No.',
};

const CREATED_ON = {
  field: '  _createdOn',
  header: 'Created On',
};

const DESCRIPTION = {
  field: 'description',
  header: 'Description',
  width: 200,
};

const markAdjusted = ({selectedIds, invoke}) => {
  invoke({
    uri: `/mark-delivery-adjusted`,
    method: 'put',
    props: {
      selectedIds: selectedIds,
    },
  });
};

export const PendingInvoiceTAndMTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;

  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-tandm-invoices`}
      search={searchValue}
      addOnFilter={addOnFilter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="delivery_number"
      params={params}
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
          {
            title: 'Cancel',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/cancelMultipleDeliveries`,
                props: {
                  selectedIds,
                },
              });
            },
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        {
          header: 'Engg',
          field: 'project_resource_users',
          render: UserAvatarGroupChip,
          width: 200,
        },
        TANDMPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        TANDMENGG,
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
        {
          title: 'Cancel',
          onPress: ({row}) => {
            invoke({
              uri: `/cancelDelivery/${row?._id}`,
            });
          },
        },
        {
          title: 'Update variable potential',
          onPress: ({row}) => {
            navigation.navigate('adjust-potential', {row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceAllTandMAndFixedTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  const {user, download} = useAppStateContext();

  const fetchProps = {
    search: searchValue,
    addOnFilter,
    addonGroupBy: groupBy?.addonGroupBy,
    groupRow: groupBy?.groupRow,
    params,
    searchFields: 'delivery_number',
    api: `/pending-tandm-and-fixed-invoices`,
  };
  return (
    <Table
      limit={100}
      variant={'bordered'}
      // api={`/pending-tandm-and-fixed-invoices`}
      // search={searchValue}
      // addOnFilter={addOnFilter}
      // addonGroupBy={groupBy?.addonGroupBy}
      // groupRow={groupBy?.groupRow}
      // searchFields="delivery_number"
      // params={params}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
          {
            title: 'Cancel',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/cancelMultipleDeliveries`,
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Update Delivery Owner',
            onPress: ({selectedIds}) => {
              navigation.navigate('udpate-delivery-owner', {selectedIds});
            },
          },
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Project: 'project.project',
                      Owner: 'account_owner_id.name',
                      'Delivery Owner': 'delivery_owner_id.name',
                      Employee: 'project_resource_users.name',
                      Tool: 'tool.name',
                      Type: 'order_type_export',
                      Delivery: 'delivery_number',
                      Customer: 'customer.name',
                      'Delivery Date': 'delivery_date',
                      'Due Date': 'due_date',
                      'Variable Potential': 'variable_potential',
                      currency: 'order.basecurrency_id.currency',
                      Amount: 'amount',
                      'Base Amount': 'base_amount',
                      Pending: 'pending_amount',
                      'Base Pending': 'base_pending_amount',
                      Status: 'status',
                      'Billing Hrs': 'billing_hrs',
                      Rate: 'rate',
                      Description: 'description',
                      'Delivered Hrs': 'billed_hours_export',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        DELIVERY_NO,
        {
          header: 'Type',
          width: 80,
          align: 'center',
          render: ({row, styles: {rowText}}) => {
            if (row?.order_type == 'r') {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  T & M
                </Text>
              );
            } else {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  Fixed
                </Text>
              );
            }
          },
        },
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-project-resource-working', {row});
          },
        },
        {
          title: 'Cancel',
          confirm: {
            title: 'Mark Cancel',
            message: 'Are you sure you want to cancel this delivery?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/cancelDelivery/${row?._id}`,
            });
          },
        },
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
        {
          title: 'Update variable potential',
          onPress: ({row}) => {
            navigation.navigate('adjust-potential', {row});
          },
        },
        {
          title: 'Mark Unadjust',
          onPress: ({row}) => {
            navigation.navigate('mark-unadjust', {row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceAllTandMAndFixedTableOvedue = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  const {user, download} = useAppStateContext();

  const fetchProps = {
    search: searchValue,
    addOnFilter,
    addonGroupBy: groupBy?.addonGroupBy,
    groupRow: groupBy?.groupRow,
    params,
    searchFields: 'delivery_number',
    api: `/pending-tandm-and-fixed-invoices/overdue`,
  };
  return (
    <Table
      limit={100}
      variant={'bordered'}
      // api={`/pending-tandm-and-fixed-invoices`}
      // search={searchValue}
      // addOnFilter={addOnFilter}
      // addonGroupBy={groupBy?.addonGroupBy}
      // groupRow={groupBy?.groupRow}
      // searchFields="delivery_number"
      // params={params}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
          {
            title: 'Cancel',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/cancelMultipleDeliveries`,
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Update Delivery Owner',
            onPress: ({selectedIds}) => {
              navigation.navigate('udpate-delivery-owner', {selectedIds});
            },
          },
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      Project: 'project.project',
                      'Delievery number': 'delivery_number',
                      Customer: 'customer.name',
                      'Account Owner': 'account_owner_id.name',
                      'Order type': 'order_type',
                      'Billed hrs': 'billed_hours',
                      'Delivered date': 'delivered_date',
                      'Total amt': 'base_amount',
                      'Pending amt': 'base_pending_amount',
                      'Due on': 'due_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        ALLPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        {
          header: 'Type',
          width: 80,
          align: 'center',
          render: ({row, styles: {rowText}}) => {
            if (row?.order_type == 'r') {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  T & M
                </Text>
              );
            } else {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  Fixed
                </Text>
              );
            }
          },
        },
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        INVOICE_DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-project-resource-working', {row});
          },
        },
        {
          title: 'Cancel',
          confirm: {
            title: 'Mark Cancel',
            message: 'Are you sure you want to cancel this delivery?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/cancelDelivery/${row?._id}`,
            });
          },
        },
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
        {
          title: 'Update variable potential',
          onPress: ({row}) => {
            navigation.navigate('adjust-potential', {row});
          },
        },
        {
          title: 'Mark Unadjust',
          onPress: ({row}) => {
            navigation.navigate('mark-unadjust', {row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceAllTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {download} = useAppStateContext();
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter,
    addonGroupBy: groupBy?.addonGroupBy,
    groupRow: groupBy?.groupRow,
    searchFields: 'delivery_number',
    api: `/pending-all-invoices`,
  };
  return (
    <Table
      limit={100}
      variant={'bordered'}
      {...fetchProps}
      // api={`/pending-all-invoices`}
      // search={searchValue}
      // addOnFilter={addOnFilter}
      // searchFields="delivery_number"
      // params={params}
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
          {
            title: 'Cancel',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/cancelMultipleDeliveries`,
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Correct Delivery Description',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/correctdeliverydescription`,
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Update Delivery Owner',
            onPress: ({selectedIds}) => {
              navigation.navigate('udpate-delivery-owner', {selectedIds});
            },
          },
          {
            title: 'Download',
            onPress: props => {
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    selectedIds: props.selectedIds,
                    column: {
                      Owner: 'account_owner_id.name',
                      'Delivery Owner': 'delivery_owner_id.name',
                      Employee: 'project_resource_users.name',
                      Tool: 'tool.name',
                      Type: 'order_type_export',
                      Delivery: 'delivery_number',
                      Customer: 'customer.name',
                      'Delivery Date': 'delivery_date',
                      'Due Date': 'due_date',
                      'Variable Potential': 'variable_potential',
                      currency: 'order.currency_id.currency',
                      Amount: 'amount',
                      'Base Amount': 'base_amount',
                      Pending: 'pending_amount',
                      'Base Pending': 'base_pending_amount',
                      Status: 'status',
                      'Billing Hrs': 'billing_hrs',
                      Rate: 'rate',
                      'Delivered Hrs': 'billed_hours',
                      Description: 'description',
                    },
                  },
                },
              });
            },
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        {
          header: 'Engg',
          field: 'project_resource_users',
          render: UserAvatarGroupChip,
          width: 250,
        },
        ALLPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        DELIVERY_OWNER,
        {
          header: 'Type',
          width: 80,
          align: 'center',
          render: ({row, styles: {rowText}}) => {
            if (row?.order_type == 'r') {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  T & M
                </Text>
              );
            } else {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  Fixed
                </Text>
              );
            }
          },
        },
        {
          header: 'Skill',
          field: 'tool.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Variable Potential',
          field: 'variable_potential',
          type: 'number',
          width: 150,
        },
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        {
          header: 'Status',
          field: 'status',
          width: 200,
          type: 'text',
        },
        INVOICE_DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-project-resource-working', {row});
          },
        },
        {
          title: 'Cancel',
          confirm: {
            title: 'Mark Cancel',
            message: 'Are you sure you want to cancel this delivery?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/cancelDelivery/${row?._id}`,
            });
          },
        },
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
        {
          title: 'Update variable potential',
          onPress: ({row}) => {
            navigation.navigate('adjust-potential', {row});
          },
        },
        {
          title: 'Mark Unadjust',
          onPress: ({row}) => {
            navigation.navigate('mark-unadjust', {row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceCancelTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  return (
    <Table
      limit={100}
      variant={'bordered'}
      api={`/cancelled-invoices`}
      search={searchValue}
      addOnFilter={addOnFilter}
      searchFields="delivery_number"
      params={params}
      eventSourceId="revenue-invoice"
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        ALLPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        {
          header: 'Type',
          width: 80,
          align: 'center',
          render: ({row, styles: {rowText}}) => {
            if (row?.order_type == 'r') {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  T & M
                </Text>
              );
            } else {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  Fixed
                </Text>
              );
            }
          },
        },
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        INVOICE_DUE_ON,
        {
          header: 'Cancelled On',
          type: 'date',
          width: 120,
          field: 'cancelled_on',
          formatOptions: {
            format: 'DD MMM YYYY',
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceAllTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter: {account_owner_id: employeeId, ...addOnFilter},
    searchFields: 'delivery_number',
    api: `/pending-all-invoices`,
  };
  return (
    <Table
      limit={100}
      variant={'bordered'}
      {...fetchProps}
      // api={`/pending-all-invoices`}
      // search={searchValue}
      // addOnFilter={{account_owner_id: employeeId, ...addOnFilter}}
      // searchFields="delivery_number"
      // params={params}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props => {
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    selectedIds: props.selectedIds,
                    column: {
                      Owner: 'account_owner_id.name',
                      'Delivery Owner': 'delivery_owner_id.name',
                      Employee: 'project_resource_users.name',
                      Type: 'order_type',
                      Delivery: 'delivery_number',
                      Customer: 'customer.name',
                      'Delivery Date': 'delivery_date',
                      'Due Date': 'due_date',
                      'Variable Potential': 'variable_potential',
                      currency: 'basecurrency_id.currency',
                      Amount: 'amount',
                      'Base Amount': 'base_amount',
                      Pending: 'pending_amount',
                      'Base Pending': 'base_pending_amount',
                      Status: 'status',
                      'Billing Hrs': 'billing_hrs',
                      Rate: 'rate',
                      Description: 'description',
                      'Delivered Hrs': 'billed_hours',
                    },
                  },
                },
              });
            },
          },
        ],
      }}
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Mark Adjusted',
      //       confirm: {
      //         title: 'Mark Adjusted',
      //         message: 'Are you sure you want to cancle this delivery?',
      //       },
      //       onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
      //     },
      //   ],
      // }}
      eventSourceId="revenue-invoice"
      columns={[
        ALLPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        VARIABLE_POTENTIAL,
        // MONTH,
        {
          header: 'Type',
          render: ({row, styles: {rowText}}) => {
            if (row?.order_type == 'r') {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  T & M
                </Text>
              );
            } else {
              return (
                <Text
                  style={{
                    ...rowText,
                  }}>
                  Fixed
                </Text>
              );
            }
          },
        },
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        INVOICE_DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Cancel',
          onPress: ({row}) => {
            invoke({
              uri: `/cancelDelivery/${row?._id}`,
            });
          },
        },
        {
          title: 'Update variable potential',
          onPress: ({row}) => {
            navigation.navigate('adjust-potential', {row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        // {
        //   title: 'Mark Unadjust',
        //   onPress: ({row}) => {
        //     navigation.navigate('mark-unadjust', {row});
        //   },
        // },
      ]}
    />
  );
};

export const PendingInvoiceTAndMTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-tandm-invoices`}
      search={searchValue}
      addOnFilter={{
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {$in: employeeTeam},
          },
        ],
        ...addOnFilter,
      }}
      searchFields="delivery_number"
      params={params}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Mark Adjusted',
      //       confirm: {
      //         title: 'Mark Adjusted',
      //         message: 'Are you sure you want to cancle this delivery?',
      //       },
      //       onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
      //     },
      //   ],
      // }}
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      selection={{
        actions: [
          {
            title: 'Create Invoice',
            onPress: data => {
              const {selectedData = [], selectedIds = []} = data;
              let deliveryDefaultData = {};
              if (selectedData.length > 0) {
                deliveryDefaultData.customer = selectedData[0].customer;
                deliveryDefaultData.exchange_rate =
                  selectedData[0].exchange_rate;
                deliveryDefaultData.pricing_type = selectedData[0].order_type;
                deliveryDefaultData.deliveryId = selectedIds;
              }
              navigation.navigate('create-draft-invoice', {
                ...props,
                deliveryDefaultData: deliveryDefaultData,
              });
            },
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        TANDMENGG,
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Create Invoice',
          onPress: ({row}) => {
            let deliveryDefaultData = {};
            if (row && row.customer?._id) {
              if (row?.customer?._id) {
                deliveryDefaultData.customer = row?.customer;
              }
              if (row?.order_type) {
                deliveryDefaultData.pricing_type = row?.order_type;
              }
              if (row?.exchange_rate) {
                deliveryDefaultData.exchange_rate = row?.exchange_rate;
              }
              if (row?._id) {
                deliveryDefaultData.deliveryId = [row?._id];
              }
            }
            navigation.navigate('create-draft-invoice', {
              deliveryDefaultData: deliveryDefaultData,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        // {
        //   title: 'Mark Unadjust',
        //   onPress: ({row}) => {
        //     navigation.navigate('mark-unadjust', {row});
        //   },
        // },
      ]}
    />
  );
};

export const PendingInvoiceFixedTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-fixed-invoices`}
      params={params}
      search={searchValue}
      addOnFilter={addOnFilter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="delivery_number"
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        MILESTONEWITHDELIVERYNO,
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceFixedTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-fixed-invoices`}
      params={params}
      search={searchValue}
      addOnFilter={{
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {$in: employeeTeam},
          },
        ],
        ...addOnFilter,
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="delivery_number"
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Mark Adjusted',
      //       confirm: {
      //         title: 'Mark Adjusted',
      //         message: 'Are you sure you want to cancle this delivery?',
      //       },
      //       onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
      //     },
      //   ],
      // }}
      selection={{
        actions: [
          {
            title: 'Create Invoice',
            onPress: data => {
              const {selectedData = [], selectedIds = []} = data;
              let deliveryDefaultData = {};
              if (selectedData.length > 0) {
                deliveryDefaultData.customer = selectedData[0].customer;
                deliveryDefaultData.exchange_rate =
                  selectedData[0].exchange_rate;
                deliveryDefaultData.pricing_type = selectedData[0].order_type;
                deliveryDefaultData.deliveryId = selectedIds;
              }
              navigation.navigate('create-draft-invoice', {
                ...props,
                deliveryDefaultData: deliveryDefaultData,
              });
            },
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        MILESTONEWITHDELIVERYNO,
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Create Invoice',
          onPress: ({row}) => {
            let deliveryDefaultData = {};
            if (row && row.customer?._id) {
              if (row?.customer?._id) {
                deliveryDefaultData.customer = row?.customer;
              }
              if (row?.order_type) {
                deliveryDefaultData.pricing_type = row?.order_type;
              }
              if (row?.exchange_rate) {
                deliveryDefaultData.exchange_rate = row?.exchange_rate;
              }
              if (row?._id) {
                deliveryDefaultData.deliveryId = [row?._id];
              }
            }
            navigation.navigate('create-draft-invoice', {
              deliveryDefaultData: deliveryDefaultData,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        // {
        //   title: 'Mark Unadjust',
        //   onPress: ({row}) => {
        //     navigation.navigate('mark-unadjust', {row});
        //   },
        // },
      ]}
    />
  );
};

export const PendingInvoiceSupportTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({navigation});
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-support-invoices`}
      params={params}
      search={searchValue}
      addOnFilter={addOnFilter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="invoice_number"
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        FIXEDPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        MILESTONEWITHDELIVERYNO,
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        INVOICE_DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
        {
          title: 'Mark Unadjust',
          onPress: ({row}) => {
            navigation.navigate('mark-unadjust', {row});
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
      ]}
    />
  );
};

export const PendingInvoiceSupportTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  const invoke = useInvoke({
    eventSourceId: 'revenue-invoice',
    method: 'put',
  });
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-support-invoices`}
      params={params}
      search={searchValue}
      addOnFilter={{account_owner_id: employeeId, ...addOnFilter}}
      searchFields="invoice_number"
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      selection={{
        actions: [
          {
            title: 'Mark Adjusted',
            confirm: {
              title: 'Mark Adjusted',
              message: 'Are you sure you want to cancle this delivery?',
            },
            onPress: ({selectedIds}) => markAdjusted({selectedIds, invoke}),
          },
        ],
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        SKILL,
        MONTH,
        DELIVERIED_HOURS_AND_DATE,
        TOTAL_AMOUNT,
        PENDING_AMOUNT,
        INVOICE_DUE_ON,
      ]}
      moreActions={[
        {
          title: 'Update variable potential',
          onPress: ({row}) => {
            navigation.navigate('adjust-potential', {row});
          },
        },
      ]}
    />
  );
};

export const PendingInvoiceCreditNotesTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
  } = props;
  return (
    <Table
      limit={2000}
      variant={'bordered'}
      api={`/pending-credit-note-invoices`}
      params={params}
      search={searchValue}
      addOnFilter={addOnFilter}
      searchFields="invoice_number"
      // onRowPress={({row}) => {
      //   navigation.navigate(`non-editable-revenue-invoice`, {
      //     row,
      //   });
      // }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        ACCOUNT_OWNER,
        CREDIT_NOTE_NO,
        CREATED_ON,
        DESCRIPTION,
        AMOUNT_INR,
        AMOUNT_CC,
        APPROVED,
      ]}
    />
  );
};

export const skillWiseDeliveryTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {extraFilter = {}} = params;
  return (
    <Table
      limit={2000}
      renderHeader={() => <TableHeader title="Project Resource Working" />}
      variant={'bordered'}
      api={`/skillwiseDeliveries`}
      search={searchValue}
      addOnFilter={{...addOnFilter, ...extraFilter}}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="delivery_number"
      params={params}
      eventSourceId="revenue-invoice"
      columns={[
        {
          header: 'Engg',
          field: 'project_resource_users',
          render: UserAvatarGroupChip,
          width: 200,
        },
        TANDMPROJECT,
        CUSTOMER,
        ACCOUNT_OWNER,
        TANDMENGG,
        DELIVERIED_HOURS_AND_DATE,
        DUE_ON,
      ]}
    />
  );
};
