import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useInvoke } from '../../../controllers/useInvoke';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';
import { TabView } from '../../../components/tab';
import moment from 'moment';
import { useTheme } from '@unthinkable/react-theme';
import { Text } from '@unthinkable/react-core-components';
import { useFilter } from '@unthinkable/react-filters';

export const FreezeRangeTable = props => {
  let {
    navigation,
    route: { params },
    filter,
  } = props;

  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'freeze',
  });
  const deleteFreeze = ({ uri }) => {
    invoke({
      uri,
    });
  };

  return (
    <Table
      api={'/freezes'}
      params={params}
      addOnFilter={filter}
      eventSourceId={'freeze'}
      renderHeader={() => {
        return (
          <TableHeader
            title="Freeze Range"
            actions={[
              <AddButton
                title="Freeze Range"
                view="add-freeze-range"
                params={params}
              />,
            ]}
          />
        );
      }}
      fields={{
        employee: { _id: 1, name: 1, official_email_id: 1 },
        from_date: 1,
        to_date: 1,
        type: 1,
        organization: {
          name: 1,
        },
      }}
      columns={[
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
          width : 300
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          render: ({ row,styles: {rowText}, }) => {
            const colors = useTheme('colors');
            return <Text style={{ ...rowText, fontSize: 13, color: colors.NEUTRAL_LOW }}>
              {moment(row?.from_date).format('DD MMM YYYY')}
            </Text>
          },
          width : 200

        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          render: ({ row,styles: {rowText}, }) => {
            const colors = useTheme('colors');
            return <Text style={{ ...rowText, fontSize: 13, color: colors.NEUTRAL_LOW }}>
              {moment(row?.to_date).format('DD MMM YYYY')}
            </Text>
          },
          width : 200

        },
        {
          header: 'By',
          field: 'employee.name',
          type: 'text',
          render: ({ row, styles }) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          width : 300
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate(`edit-freeze-range`, {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete Freeze Range',
            message: 'Are you sure you want to delete this freeze range?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deleteFreeze({
              uri: `/freezes/${row?._id}`,
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              api: `/freezes/${row?._id}/activitylogs`,
              populate: [
                {
                  path: 'employee',
                  select: {
                    name: 1,
                  },
                },
              ],
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'employee.name',
                },
                type: {
                  label: 'Type',
                  field: 'type',
                },
                from_date: {
                  label: 'From Date',
                  field: 'from_date',
                },
                to_date: {
                  label: 'To Date',
                  field: 'to_date',
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const FreezeRangeTab = props => {
  let { route: { params } = {} } = props;
  let { filterValues, searchValue, onChangeFilter, applyFilter } = useFilter();
  let { filter, params: { filterParams, period: periods } = {} } = filterValues;
  let { organization, product, period, ...rest } = params;

  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    order: {
      label: 'Order',
      view: (
        <FreezeRangeTable
        filter={{ ...filter, type : "Order"}} params={params} {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    attendance: {
      label: 'Attendance',
      view: (
        <FreezeRangeTable
        filter={{ ...filter, type : "Attendance"}} params={params} {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    salary: {
      label: 'Salary',
      view: (
        <FreezeRangeTable
        filter={{ ...filter, type : "Salary"}} params={params} {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    voucher: {
      label: 'Voucher',
      view: (
        <FreezeRangeTable
        filter={{ ...filter, type : "Voucher"}} params={params} {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};