import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import {Linking, Platform} from '@unthinkable/react-core-components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useToast} from '@unthinkable/react-toast';
import {googleSignOut} from '@unthinkable/react-login';
import {getEncryptedProps} from '../Encryption';
import {useNotificationHandler} from '../firebase';

const AppStateContext = React.createContext();

// clears all the calls and make a refresh_token call after delay of 500 seconds after that all pending calls are triggered
export const debouncePromise = (fn, ms = 0) => {
  let timeoutId;
  const pending = [];
  return (...args) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentPending = [...pending];
        pending.length = 0;
        Promise.resolve(fn.apply(this, args)).then(
          data => {
            currentPending.forEach(({resolve}) => resolve(data));
          },
          error => {
            currentPending.forEach(({reject}) => reject(error));
          },
        );
      }, ms);
      pending.push({resolve: res, reject: rej});
    });
};

const useAppState = ({setUserInApp, ...props}) => {
  let [state, setAppState] = useState(props);
  const toast = useToast();
  const {
    config: {
      fetchUrl,
      postUrl,
      uploadUrl,
      downloadUrl,
      fetchSecondaryServerUrl,
      postSecondaryServerUrl,
      clientBucket,
    },
    api,
    initialApis,
    access_token,
    device_token,
    navigationRef,
    handleInitialRoute,
    initialPath,
    initialPathState,
  } = state;

  const externalUser = state?.externalUser;
  const setState = useCallback(
    value => {
      setAppState(prevState => ({...prevState, ...value}));
    },
    [state],
  );

  const setDeviceToken = token => {
    setState({
      device_token: token,
    });
  };

  const setToken = async result => {
    let {access_token, refresh_token} = result;
    if (access_token) {
      await AsyncStorage.setItem('token', access_token);
    }
    if (refresh_token) {
      await AsyncStorage.setItem('refresh_token', refresh_token);
    }
  };

  const setUser = useCallback(
    result => {
      setToken(result);
      setState({
        status: 'initialized',
        ...result,
        externalUser: result?.user?.externalUser,
      });
      setUserInApp(result?.user);
      if (result?.user?.externalUser) {
        AsyncStorage.setItem('externalUser', result?.user?.externalUser); // set the external user in the local storage @adit 13 july 2024
      }
      handleInitialRoute &&
        handleInitialRoute({
          initialPath,
          initialPathState,
          navigationRef,
          state: {
            ...state,
            status: 'initialized',
            ...result,
          },
          setState,
          post,
          toast,
          fetch,
        });
    },
    [state],
  );

  const loadUser = async ({
    access_token,
    refresh_token,
    userRefreshTokenCall,
    externalUser,
  }) => {
    return await fetch({
      uri: api,
      props: {
        token: access_token,
        refreshToken: refresh_token,
      },
      newAccessToken: access_token,
      // this param is to differentiate between normal fetch call and getUserByToken call
      userRefreshTokenCall,
      externalUser,
    });
  };

  const upload = useCallback(
    async (file, options = {}, userAccessToken = access_token) => {
      let name = file.name.replace(/[&\/\\#,^@!+()$~%" "'":*?<>{}-]/g, '_');
      if (Platform.OS === 'web') {
        file = new File([file], name, {type: file.type});
      } else {
        file.name = name;
      }
      let {multiPart = true, uri = '/upload', bucketName} = options;

      // @adit use clientPmt bucket for external users- 10 july 2024
      if (externalUser) {
        bucketName = clientBucket;
      }

      if (multiPart) {
        let headers = {};
        if (!userAccessToken) {
          userAccessToken = await AsyncStorage.getItem('token');
        }
        if (userAccessToken) {
          headers['Authorization'] = `Bearer ${userAccessToken}`;
        }
        let formData = new FormData();
        formData.append('file', file);
        return axios
          .post(`${uploadUrl}${uri}?bucketName=${bucketName}`, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              ...headers,
            },
          })
          .then(res => {
            let result = res.data;
            result = result.data;
            return result;
          })
          .catch(e => {
            console.log('@@@@ error in file upload', e);
          });
      } else {
        console.warn(new Error('Upload not supported without multiPart'));
      }
    },
    [uploadUrl, access_token, externalUser],
  );

  const resourceUrl = useCallback(
    (file, {download} = {}) => {
      if (!file || !file.id) {
        return;
      }
      let {metadata = {}} = file || {};

      let bucket = metadata && metadata.bucketName;

      let url = `${downloadUrl}/files/${file.id}?bucketName=${bucket}`;
      if (download) {
        url += '?download=true';
      }

      return url;
    },
    [downloadUrl],
  );

  const navigateToScreen = async screenData => {
    const {screen_path} = screenData || {};

    if (screen_path) {
      handleInitialRoute &&
        handleInitialRoute({
          initialPath: screen_path,
          initialPathState,
          navigationRef,
          state,
          setState,
          post,
          toast,
          fetch,
        });
    }
  };

  const logout = useCallback(async () => {
    const refresh_token = await AsyncStorage.getItem('refresh_token');

    if (device_token) {
      await post({
        uri: '/userdevices/update-status',
        props: {
          deviceToken: device_token,
        },
      });
    }

    if (refresh_token) {
      await post({
        uri: '/logout',
        props: {
          refreshToken: refresh_token,
        },
      });
    }
    AsyncStorage.removeItem('token');
    AsyncStorage.removeItem('refresh_token');
    AsyncStorage.removeItem('externalUser'); // remove external user from local storage -@adit 13 july 2024
    googleSignOut();
    setState({
      status: 'anonyms',
      user: null,
      access_token: null,
      globalData: undefined,
      device_token: null,
      externalUser: null,
    });
  }, [setState]);

  const getNewAccessToken = debouncePromise(async () => {
    const refresh_token = await AsyncStorage.getItem('refresh_token');
    if (!refresh_token) {
      return;
    }
    let response = await post({
      uri: '/refresh-token',
      props: {
        refreshToken: refresh_token,
      },
    });
    const result = response?.data;
    if (result) {
      setToken(result);
      setState(result);
    }
    return result;
  }, 100);

  const errorHandling = async (err, apiCallback, apiProps) => {
    const statusCode = err.response?.status || 500;
    if (err?.response?.data?.error) {
      err = err.response.data.error || 'Error';
    }
    if (statusCode === 401) {
      if (err.message === 'jwt expired') {
        const {access_token, refresh_token} = (await getNewAccessToken()) || {};
        if (apiProps.userRefreshTokenCall) {
          return await loadUser({
            access_token: access_token,
            refresh_token: refresh_token,
          });
        } else if (access_token) {
          return await apiCallback({
            ...apiProps,
            newAccessToken: access_token,
          });
        }
      } else {
        const refresh_token = await AsyncStorage.getItem('refresh_token');
        if (refresh_token) {
          await logout();
        }
        throw err;
      }
    } else {
      throw err;
    }
  };

  // the newAccessToken is the token coming from debouncing callback
  const fetch = useCallback(
    async apiProps => {
      let {
        uri,
        props = {},
        newAccessToken = access_token,
        encryption = false,
        externalUser: _externalUser,
      } = apiProps;

      // Convert props to string and check its length
      const propsString = JSON.stringify(props);
      const propsLength = propsString.length;
      const maxAllowedSize = 1024;
      if (propsLength <= maxAllowedSize) {
        encryption = true;
      }

      // Check if NODE_ENV is local
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        encryption = false;
      }
      try {
        // Determine the URL based on externalUser and state.user.externalUser @adit 13 july 2024
        let baseUrl = fetchUrl;
        if (_externalUser || externalUser ) {
          baseUrl = fetchSecondaryServerUrl;
        }
        let url = baseUrl + '' + uri;

        let headers = {};
        if (!newAccessToken) {
          newAccessToken = await AsyncStorage.getItem('token');
        }
        if (newAccessToken) {
          headers['Authorization'] = `Bearer ${newAccessToken}`;
        }
        // pass Ngrok-Skip-Browser-Warning in headers if REACT_APP_NGROK_ENABLE | Adit 20/01/24
        if (process.env.REACT_APP_NGROK_ENABLE) {
          headers['Ngrok-Skip-Browser-Warning'] = 'true';
        }
        if (encryption) {
          props = getEncryptedProps(props);
        }
        let resp = await axios.get(url, {params: props, headers});
        resp = resp.data;
        return resp;
      } catch (err) {
        try {
          return await errorHandling(err, fetch, apiProps);
        } catch (err) {
          console.log('!!!!Error  ', err);
          toast({message: err.message, type: 'Error'});
        }
      }
    },
    [fetchUrl, access_token, fetchSecondaryServerUrl, externalUser],
  );

  const download = useCallback(
    async apiProps => {
      let {uri, newAccessToken = access_token, props} = apiProps;
      try {
        let baseUrl = fetchUrl;
        if (externalUser) {
          baseUrl = fetchSecondaryServerUrl;
        }
        let url = baseUrl + '' + uri;
        if (!newAccessToken) {
          newAccessToken = await AsyncStorage.getItem('token');
        }
        if (newAccessToken) {
          url =
            url +
            '?' +
            `accessToken=Bearer ${newAccessToken}&${
              props ? `params=${encodeURIComponent(JSON.stringify(props))}` : ''
            }`;
        }
        Linking.openURL(url, '_blank');
      } catch (err) {
        return errorHandling(err, post, apiProps);
      }
    },
    [postUrl, access_token],
  );

  const post = useCallback(
    async apiProps => {
      let {
        uri,
        props,
        method = 'post',
        newAccessToken = access_token,
        encryption = true,
      } = apiProps;
      try {
        // Check if NODE_ENV is local
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          encryption = false;
        }
        let headers = {};
        if (!newAccessToken) {
          newAccessToken = await AsyncStorage.getItem('token');
        }
        if (newAccessToken) {
          headers['Authorization'] = `Bearer ${newAccessToken}`;
        }
        // pass Ngrok-Skip-Browser-Warning in headers if REACT_APP_NGROK_ENABLE | Adit 20/01/24
        if (process.env.REACT_APP_NGROK_ENABLE) {
          headers['Ngrok-Skip-Browser-Warning'] = 'true';
        }
        let resp = void 0;

        // Determine the URL based on externalUser and state.user.externalUser @adit 13 july 2024
        let baseUrl = postUrl;
        if (externalUser) {
          baseUrl = postSecondaryServerUrl;
        }
        const postApi = `${baseUrl}${uri}`;
        if (encryption) {
          props = getEncryptedProps(props);
        }
        if (method?.toLowerCase() === 'delete') {
          resp = await axios[method](postApi, {
            params: props,
            headers,
          });
        } else {
          resp = await axios[method](postApi, props, {
            headers,
          });
        }
        resp = resp.data;
        return resp;
      } catch (err) {
        return errorHandling(err, post, apiProps);
      }
    },
    [postUrl, access_token, postSecondaryServerUrl, externalUser],
  );


  useEffect(() => {
    let localStorageData = localStorage.getItem('externalUser');
    if (localStorageData) {
      setState({externalUser: true});
    }
  }, []);

  useEffect(() => {
    const _fetch = async () => {
      let externalUser = localStorage.getItem('externalUser');
      let access_token = await AsyncStorage.getItem('token');
      if (access_token) {
        const refresh_token = await AsyncStorage.getItem('refresh_token');
        let result = await loadUser({
          access_token: access_token,
          refresh_token: refresh_token,
          userRefreshTokenCall: true,
          externalUser,
        });
        if (result?.data) {
          access_token = await AsyncStorage.getItem('token');
          setUser({...result?.data, access_token});
        }
      } else {
        await logout();
      }
    };
    _fetch();
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      const initialData = {};
      for (let api in initialApis) {
        const {uri, params} = initialApis[api];
        try {
          const result = await fetch({uri, props: params});
          if (result?.data) {
            initialData[api] = result.data;
          }
        } catch (err) {
          // do nothing
        }
      }
      setState({globalData: initialData});
    };
    if (!state.access_token) {
      return;
    }
    loadInitialData();
  }, [state.access_token]);

  const registerDeviceToken = async ({user, token}) => {
    if (user && token) {
      await post({
        uri: `/userdevices/register`,
        props: {
          deviceToken: token,
          platform: Platform.OS === 'web' ? 'web' : Platform.OS,
          last_access_on: new Date(),
        },
      });
    }
  };

  useEffect(() => {
    registerDeviceToken({
      user: state.user,
      token: device_token,
    });
  }, [state.user, device_token]);

  useNotificationHandler({
    navigateToScreen,
    setDeviceToken,
  });

  const value = useMemo(() => {
    return {
      post,
      fetch,
      upload,
      resourceUrl,
      setUser,
      logout,
      download,
      ...state,
    };
  }, [state]);

  return value;
};

export const AppStateProvider = ({children, ...props}) => {
  let appState = useAppState({
    status: 'initializing',
    ...props,
  });
  return (
    <AppStateContext.Provider value={appState}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppStateContext = () => {
  return useContext(AppStateContext);
};
