import React from 'react';
import {TabView} from '../../../components/tab';
import {EmployeeTrainingList} from './EmployeeTrainingList';
import {useAppStateContext} from '../../../providers/AppState';
import {SelfPipResourceTabs} from '../../employee/views/PipResourceTabs';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {TrainingBatchTabs} from './TrainingBatchTable';

export const EmployeeTrainingTabs = props => {
  return (
    <TabView
      tabs={{
        active: {
          label: 'Ongoing',
          view: (
            <EmployeeTrainingList
              addOnFilter={{status: {$in: ['active', 'extended']}}}
            />
          ),
        },
        // extended: {
        //   label: 'Extended',
        //   view: (
        //     <EmployeeTrainingList
        //       isExtended
        //       addOnFilter={{status: {$in: ['extended']}}}
        //     />
        //   ),
        // },
        completed: {
          label: 'Completed',
          view: <EmployeeTrainingList addOnFilter={{status: 'completed'}} />,
        },
      }}
      {...props}
    />
  );
};

export const MyMenteesTabs = props => {
  const {
    user: {employee},
  } = useAppStateContext();
  const {
    route: {params},
  } = props;
  const {addOnFilter} = params;
  return (
    <TabView
      tabs={{
        active: {
          label: 'Ongoing',
          view: (
            <EmployeeTrainingList
              myMenteesTabs
              addOnFilter={{
                status: {$in: ['active', 'extended']},
                mentor: employee?._id,
                ...addOnFilter,
              }}
            />
          ),
          api: '/employeeTrainings',
          filter: {
            status: {$in: ['active', 'extended']},
            mentor: employee?._id,
            ...addOnFilter,
          },
        },
        completed: {
          label: 'Completed',
          view: (
            <EmployeeTrainingList
              myMenteesTabs
              addOnFilter={{
                status: 'completed',
                mentor: employee?._id,
                ...addOnFilter,
              }}
            />
          ),
          api: '/employeeTrainings',
          filter: {
            status: 'completed',
            mentor: employee?._id,
            ...addOnFilter,
          },
        },
      }}
      {...props}
    />
  );
};

export const MyMenteesForWorkspace = props => {
  const {route: {params} = {}, navigation} = props;
  const {
    user: {employee},
  } = useAppStateContext();

  const menus = [
    [
      {
        label: 'My Trainees',
        view: <MyMenteesTabs />,
        api: '/employeeTrainings',
        filter: {
          mentor: employee?._id,
        },
      },
      {
        label: 'Course Batches',
        view: (
          <TrainingBatchTabs
            fromTrainerCourseBatches
            api="/getTrainerBatches"
            extraFilter={{
              $or: [
                {
                  mentor: employee?._id,
                },
                {mentors: employee?._id},
                {
                  'topic_wise_mentors.mentor': employee?._id,
                },
              ],
            }}
            moreActions={[
              {
                title: 'View Attendance',
                onPress: ({row}) => {
                  navigation.navigate('view-training-batch-attendance', {
                    training_batch: row,
                    employee
                  });
                },
              },
              {
                title: 'Mark Attendance',
                onPress: ({row}) => {
                  navigation.navigate('mark-training-batch-attendance', {
                    training_batch: row,
                    addOnFilter: {
                      mentor: employee?._id,
                    },
                  });
                },
              },
            ]}
          />
        ),
        api: '/getTrainerBatches',
        filter: {
          $or: [
            {
              mentor: employee?._id,
            },
            {mentors: employee?._id},
            {
              'topic_wise_mentors.mentor': employee?._id,
            },
          ],
        },
      },
      {
        label: 'PIP',
        view: (
          <SelfPipResourceTabs
            myMenteesTabs
            addOnFilter={{status: 'completed', mentor: employee?._id}}
          />
        ),
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
