import {
  Image,
  LinearGradientText,
  Platform,
  Row,
  Styled,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const ActionContainer = Styled(View)`
    overflow: hidden;
    border-top-color:#EEEEED;
    border-top-width:1px;
    margin-top: 6px;
    ${({styles}) => styles?.actionContainer}
`;

export const ImproviseRow = Styled(Row)`
    justify-content: flex-end;
    ${({styles}) => styles?.improviseRow}
`;

export const ImproviseButton = Styled(TouchableOpacity)`
    flex-direction: row;
    padding-top: 6px;
    padding-bottom: 6px;
    align-items: center;
    gap: 6px;
    ${({styles}) => styles?.improviseButton}
    ${({disabled}) => (disabled ? 'opacity: 0.5;' : '')}
`;

export const ImproviseIcon = Styled(Image).attrs(({styles}) => {
  return {
    source: styles?.ImproviseIcon,
  };
})`
    height: 16px;
    width: 16px;
    ${({styles}) => styles?.improviseIconStyle}
`;

export const ImproviseText = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    ${({styles}) => styles?.improviseText}
`;

export const OriginalButton = Styled(TouchableOpacity)`
    padding-top: 6px;
    padding-bottom: 6px;
    flex: 1;
    align-items: center;
    gap: 6px;
    ${({styles}) => styles?.originalButton}
`;

export const OriginalText = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #007AFF;
    ${({styles}) => styles?.originalText}
`;

export const Separator = Styled(View)`
    background-color: #EEEEED;
    width: 1px;
    ${({styles}) => styles?.separator}
`;

export const RegenerateButton = Styled(TouchableOpacity)`
    padding-top: 6px;
    padding-bottom: 6px;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    ${({styles}) => styles?.regenerateButton}
`;

export const RegenerateIcon = Styled(Image)
  .attrs(({styles}) => {
    return {
      source: styles?.RegenerateIcon,
    };
  })
  .withConfig({
    shouldForwardProp: prop => {
      return !['loading'].includes(prop);
    },
  })`
    height: 16px;
    width: 16px;
    ${({loading}) => {
      if (Platform.OS === 'web' && loading) {
        return {
          animation: `rotate 1.5s infinite linear`,
        };
      }
    }} 
    ${({styles}) => styles?.regenerateIconStyle}
`;

export const CommitIcon = Styled(Image)
  .attrs(({styles}) => {
    return {
      source: styles?.GitCommit,
    };
  })
  .withConfig({
    shouldForwardProp: prop => {
      return !['loading'].includes(prop);
    },
  })`
    height: 16px;
    width: 16px;
    ${({styles}) => styles?.regenerateIconStyle}
`;

export const RegenerateText = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #7DCD28;
    ${({styles}) => styles?.regenerateText}
`;

export const CommitText = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #7D7D82;
    ${({styles}) => styles?.regenerateText}
`;
