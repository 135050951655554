import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

export const percentageToHour = value => {
  if (value === undefined || value === null) {
    return '';
  }
  if (typeof value === 'string') {
    value = Number(value);
  }
  const hr = Math.floor(value);
  return hr > 0
    ? `${value !== Math.floor(value) ? value.toFixed(1) : value} hr`
    : `${Math.round((value - Math.floor(value)) * 60)} min`;
};

const DurationRenderer = ({value, ...props}) => {
  if (value) {
    value = percentageToHour(value);
  }

  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} value={value} />
    </InputLabel>
  );
};

export default DurationRenderer;
