import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const fileNameFields = () => {
  return {
    name: {
      field: 'name',
      type: 'text',
      label: 'Name',
      required: true,
    },
  };
};

export const AddFileNameForm = props => {
  const {route: {params} = {}} = props;
  const {afterSubmit, defaultValues} = params;
  const {onSubmit} = useFormSubmit({
    eventSourceId: ['FileName'],
    uri: '/folderfiles',
  });

  const fields = fileNameFields({params});

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      afterSubmit={afterSubmit}
      header="Add File Name"
      layoutFields={[fields.name]}
    />
  );
};

export const UpdateFileNameForm = props => {
  const {
    route: {params},
  } = props;

  const {onSubmit} = useFormSubmit({
    eventSourceId: ['FileName'],
    uri: '/folderfiles',
  });

  const fields = fileNameFields({params});

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={params?.row}
      header="Update File Name"
      layoutFields={[fields.name]}
    />
  );
};
