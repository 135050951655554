import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {
  useDeleteImportExcel,
  useExportErrorLogs,
  useImportExcel,
  useReImportExcel,
  useTestExcel,
} from '../controllers/ImportExportControllers';
export const ImportHistoryLogList = props => {
  const {navigation, route} = props;
  const {params} = route;
  const {
    source,
    model,
    woodpeckerEnabled = false,
    emailProspect = false,
  } = params;
  const importHistoryLogDetail = ({row}) => {
    navigation.navigate(`import-history-log-detail`, {
      ...params,
      importHistoryLogId: row?._id,
      source,
      model,
    });
  };
  const {download, user} = useAppStateContext();
  const importExcel = useImportExcel({params});
  const reImportExcel = useReImportExcel({params});
  const testExcel = useTestExcel({params});
  const exportErrorLogs = useExportErrorLogs();
  const deleteImportExcel = useDeleteImportExcel();
  return (
    <Table
      {...props}
      api={`/importhistorylogs`}
      filter={{
        source,
        model,
      }}
      sort={{createdAt: -1}}
      fields={{
        file: 1,
        importDate: 1,
        source: 1,
        status: 1,
        total: 1,
        success: 1,
        error: 1,
        model: 1,
        campaign_id: 1,
        mapping_id: 1,
        campaign_type: 1,
      }}
      eventSourceId="reloadImportHistoryLogs"
      renderHeader={() => {
        return (
          <TableHeader
            title="Imports"
            actions={[
              !woodpeckerEnabled && (
                <AddButton
                  title="Create New Import"
                  view={
                    source === 'segmentation' || emailProspect
                      ? 'import-preview-form'
                      : 'import-form'
                  }
                  params={{...params, source, model}}
                />
              ),
            ]}
          />
        );
      }}
      onRowPress={importHistoryLogDetail}
      columns={[
        {
          type: 'text',
          field: data => data['file']?.[0]?.filename,
          header: 'File',
          width: 300,
          visible: props => {
            return !woodpeckerEnabled;
          },
        },
        {
          type: 'date',
          field: 'importDate',
          header: 'Date',
        },
        {
          type: 'text',
          field: 'source',
          header: 'Source',
          visible: props => {
            return !woodpeckerEnabled;
          },
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
          ...(woodpeckerEnabled ? {} : {width: 100}),
        },

        {
          type: 'number',
          field: 'total',
          header: 'Total',
        },
        {
          type: 'number',
          field: 'success',
          header: 'Success',
        },
        {
          type: 'number',
          field: 'error',
          header: 'Error',
          onPress: ({row}) => {
            download({uri: `/export-error-logs/${row._id}`});
          },
        },
      ]}
      moreActions={[
        {
          title: 'Import',
          visible: props => {
            return !woodpeckerEnabled;
          },
          onPress: importExcel,
        },
        {
          title: 'Re-Import',
          visible: props => {
            const {email} = user || {};
            return email === 'rohit.bansal@daffodilsw.com' && !woodpeckerEnabled;
          },
          onPress: reImportExcel,
        },
        {
          title: 'Test',
          visible: props => {
            const {email} = user || {};
            return email === 'rohit.bansal@daffodilsw.com' && !woodpeckerEnabled;
          },
          onPress: testExcel,
        },
        // {
        //   title: 'View Activity Logs',
        //   onPress: ({row}) => {
        //     navigation.navigate('import-history-logs-activity', {
        //       row: row,
        //     });
        //   },
        // },
        {
          title: 'Delete',
          onPress: deleteImportExcel,
        },
        {
          title: 'Download Error Logs',
          onPress: ({row}) => {
            download({uri: `/export-error-logs/${row._id}`});
          },
        },
      ]}
    />
  );
};
