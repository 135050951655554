import React from 'react';
import {Col, Text, TouchableOpacity} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {DataEntityStatusStyle, FileFieldRenderStyle} from './theme';
import {useAppStateContext} from '../../../providers/AppState';

export const FileFieldRender = ({row: {file}}) => {
  if (!file) {
    return null;
  }
  const {filename, id: fileId} = file;
  const {download} = useAppStateContext();
  let {textStyle} = useStyles(FileFieldRenderStyle);

  return (
    <TouchableOpacity
      onPress={() => {
        download({
          uri: `/files/${fileId}`,
        });
      }}>
      <Text style={textStyle}>{filename}</Text>
    </TouchableOpacity>
  );
};

export const ImportStatus = ({row: {status, error}}) => {
  let {statusStyle, statusText, errorText} = useStyles(DataEntityStatusStyle);
  const statusColor = statusStyle[status || 'In Progress'] || {};
  return (
    <Col gap={7}>
      <Text style={{...statusText, ...statusColor}}>{status}</Text>
      {error ? <Text style={errorText}>{error}</Text> : void 0}
    </Col>
  );
};
