import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {useAppStateContext} from '../../../providers/AppState';
import {FreezeRangeTable} from '../../common/views/FreezeRangeTable';
import {AttendanceTabView} from '../../leadGeneration/views/AttendanceTabView';
import {BlopdGroupTable} from './BloodGroupTable';
import {EmployeeLevelTable} from './EmployeeLevelTable';
import {EmployeeSetupTab} from './EmployeeSetupTabs';
import {EmployeeTypeTable} from './EmployeeTypeTable';
import {ManualVariableTable} from './ManualVariableTable';
import {NomineeRelationTable} from './NomineeRelationTable';
import {ReimbursementHeadTable} from './ReimbursementHeadTable';
import {SalaryComponentTabs} from './SalaryComponentTabs';
import {SalarySetupTab} from './SalarySetupTab';
import {CSATSalaryManualTable} from './CsatSalaryManualTable';
import {
  AllSalaryComponentTable,
  AttendanceSalaryComponentTable,
  DeductionSalaryComponentTable,
  FixedSalaryComponentTable,
  PerformanceSalaryComponentTable,
} from './SalaryComponentTable';
import {EmployeeUserTabs} from './EmployeeUserTabs';
import {
  FixedSalarySetupTable,
  MonthlyAttendanceTable,
  PerformanceSalarySetupTable,
  performanceSalarySetupTable,
} from './AttendanceSalaryTable';
import {LeaveRegisterTable} from './LeaveRegisterTable';
import { PackageStructureTable } from './PackageStructureTable';

const EmployeeSetup = createMenuNavigator();

export const EmployeeSetupNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <EmployeeSetup.Navigator
      canAccess={canAccess}
      data-navigator-id="HrSetupGroup"
      {...props}>
      <EmployeeSetup.Screen
        data-resource-id="EmployeeSetup"
        name="Employee"
        props={{user: user, ...props}}
        component={EmployeeSetupTab}
        options={{
          label: 'Employee',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="AttendanceSetup"
        name="Attendance"
        props={{user: user, ...props}}
        component={AttendanceTabView}
        options={{
          label: 'Attendance',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="SalarySetup"
        name="Salary"
        props={{user: user, ...props}}
        component={SalarySetupTab}
        options={{
          label: 'Salary',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="FreezeRange"
        name="Freeze Range"
        props={{user: user, ...props}}
        component={FreezeRangeTable}
        options={{
          label: 'Freeze Range',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="LeaveRegister"
        name="Leave Register"
        props={{user: user, ...props}}
        component={LeaveRegisterTable}
        options={{
          label: 'Leave Register',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="ManualVariable"
        name="Manual Variable"
        props={{user: user, ...props}}
        component={ManualVariableTable}
        options={{
          label: 'Manual Variable',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="CSATSalaryManual"
        name="CSAT Salary Manual"
        props={{user: user, ...props}}
        component={CSATSalaryManualTable}
        options={{
          label: 'CSAT Salary Manual',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="ReimbursementHead"
        name="Reimbursement Head"
        props={{user: user, ...props}}
        component={ReimbursementHeadTable}
        options={{
          label: 'Reimbursement Head',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="EmployeeUser"
        name="EmployeeUser"
        component={EmployeeUserTabs}
        props={{user: user, ...props}}
        options={{
          label: 'User',
        }}
      />
      <EmployeeSetup.Group>
        <EmployeeSetup.Section options={{label: 'Salary Component'}}>
          <EmployeeSetup.Screen
            data-resource-id="AttendanceBased"
            name="Attendance"
            component={AttendanceSalaryComponentTable}
            options={{
              label: 'Attendance',
              limit: 5000,
            }}
          />
          <EmployeeSetup.Screen
            data-resource-id="PerformanceBased"
            name="Performance"
            component={PerformanceSalaryComponentTable}
            options={{
              label: 'Performance',
              limit: 5000,
            }}
          />
          <EmployeeSetup.Screen
            data-resource-id="FixedBased"
            name="Fixed"
            component={FixedSalaryComponentTable}
            options={{
              label: 'Fixed',
              limit: 5000,
            }}
          />
          <EmployeeSetup.Screen
            data-resource-id="Deduction"
            name="Deduction"
            component={DeductionSalaryComponentTable}
            options={{
              label: 'Deduction',
              limit: 5000,
            }}
          />
          <EmployeeSetup.Screen
            data-resource-id="All"
            name="All"
            component={AllSalaryComponentTable}
            options={{
              label: 'All',
              limit: 5000,
            }}
          />
        </EmployeeSetup.Section>
      </EmployeeSetup.Group>
      <EmployeeSetup.Screen
        data-resource-id="LevelPackageBreakup"
        name="Level Package Breakup"
        props={{user: user, ...props}}
        component={EmployeeLevelTable}
        options={{
          label: 'Level Package Breakup',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="BloodGroup"
        name="Blood Group"
        props={{user: user, ...props}}
        component={BlopdGroupTable}
        options={{
          label: 'Blood Group',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="NomineeRelation"
        name="Nominee Relations"
        props={{user: user, ...props}}
        component={NomineeRelationTable}
        options={{
          label: 'Relations',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="EmployeeType"
        name="Employee Type"
        props={{user: user, ...props}}
        component={EmployeeTypeTable}
        options={{
          label: 'Employee Type',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="EmployeeType"
        name="Monthly Attendance"
        props={{user: user, ...props}}
        component={MonthlyAttendanceTable}
        options={{
          label: 'Monthly Attendance',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="EmployeeType"
        name="Fixed Salary"
        props={{user: user, ...props}}
        component={FixedSalarySetupTable}
        options={{
          label: 'Fixed Salary',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="EmployeeType"
        name="Performance Salary"
        props={{user: user, ...props}}
        component={PerformanceSalarySetupTable}
        options={{
          label: 'Performance Salary',
        }}
      />
      <EmployeeSetup.Screen
        data-resource-id="packageStructure"
        name="Package Structure"
        props={{user: user, ...props}}
        component={PackageStructureTable}
        options={{
          label: 'Package Structure',
        }}
      />
    </EmployeeSetup.Navigator>
  );
};
