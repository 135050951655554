import React from 'react';
import {EmptyStateStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const EmptyState = ({title, subText, onPlay}) => {
  const {
    containerStyle,
    textStyle,
    subContainerStyle,
    playIcon,
    subTextStyle,
    emptyStateIcon,
  } = useStyles(EmptyStateStyles);

  return (
    <View style={containerStyle}>
      <Image source={emptyStateIcon} />
      <Text style={textStyle}>{title}</Text>
      <Row gap={12} style={subContainerStyle}>
        <Text style={subTextStyle}>{subText}</Text>
        <TouchableOpacity onPress={onPlay}>
          <Image source={playIcon} />
        </TouchableOpacity>
      </Row>
    </View>
  );
};
