import React from 'react';
import {TabView} from '../../../components/tab/TabView';

import {AddButton} from '../../../components/button/AddButton';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {Row} from '@unthinkable/react-core-components';
import {ObjectWiseResources} from '../../common/actions/ObjectWiseResources';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';
import {OutreachInmailCampaignTabs} from './OutreachInmailCampaignTabs';
import {EmailCampaignTabs} from '../views/EmailCampaignTabs';
import {NavHeader} from '../../../components/nav/NavHeader';

export const OutreachCampaignHeader = props => {
  let title =
    props?.route?.params?.product?.name ||
    props?.route?.params?.organization?.name ||
    props?.params?.name;
  return (
    <NavHeader
      title={
        title ? `Outreach Campaigns Auto (${title})` : 'Outreach Campaigns Auto'
      }
      {...props}
    />
  );
};

export const OutreachCampaignsTab = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const tabs = {
    active: {
      label: 'Inmail',
      view: <OutreachInmailCampaignTabs />,
      actions: [
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <ObjectWiseTasks
            {...props}
            params={params}
            object={{
              _id: '64d0fd55ab78dfead48c144b',
            }}
          />
          <AddButton
            title="Inmail Campaign"
            view="add-outreachcampaign"
            params={props?.route?.params}
          />
          <MoreActions
            actions={[
              {
                title: 'Import Inmail Prospects',
                onPress: () => {
                  navigation.navigate('import-list', {
                    ...props?.route?.params,
                    source: 'linkedin prospects',
                    model: 'PotentialCustomer',
                  });
                },
              },
              {
                title: 'Import Inmail Responses',
                onPress: () => {
                  navigation.navigate('import-list', {
                    ...props?.route?.params,
                    source: 'linkedin responses',
                    model: 'PotentialCustomer',
                  });
                },
              },
            ]}
          />
        </Row>,
      ],
    },
    completed: {
      label: 'Email',
      view: <EmailCampaignTabs />,
      actions: [
        <ObjectWiseResources
          {...props}
          params={params}
          object={{
            _id: '64d0fd55ab78dfead48c144a',
          }}
        />,
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64d0fd55ab78dfead48c144a',
          }}
        />,
        <AddButton
          title="Email Campaign"
          view="add-emailcampaign"
          params={props?.route?.params}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
