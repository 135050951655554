import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';

export const CaseStudyMarketingTheme = props => {
  const {params, navigation} = props;
  const {target_audience} = params;
  return (
    <Table
      api={`/marketingThemes`}
      fields={{
        name: 1,
        theme_value_proposition: 1,
        type: {name: 1},
        target_audience: {
          name: 1,
        },
        drip_inmail_count: {
          _id: 1,
        },
        campaign_type: {
          name: 1,
          list_view: 1,
          add_view: 1
        },
        case_study_count: {
          _id: 1,
        },
      }}
      filter={{
        theme_type: 'caseStudy',
        target_audience,
      }}
      eventSourceId={['marketingThemeAdded', 'caseStudyAdded']}
      onRowPress={({row}) => {
        navigation.navigate('edit-marketing-theme', {...params, row});
      }}
      columns={[
        {
          header: 'Value Proposition',
          field: 'theme_value_proposition',
          type: 'text',
        },
        {
          header: 'Category',
          field: 'type.name',
        },
        {
          header: 'Case Study',
          align: 'right',
          onPlusPress: ({row}) => {
            navigation.navigate('add-casestudy', {
              ...params,
              marketing_theme: row,
            });
          },
          render: CountCellRender,
          count_field: 'case_study_count',
          onPress: ({row}) => {
            navigation.navigate('case-study-list', {
              ...params,
              marketing_theme: row,
              themeFilter: {
                marketing_theme: row,
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};
