import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const KPIPermissionTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'kpipermissions',
  });

  return (
    <Table
      eventSourceId={'kpipermissions'}
      api={`/kpipermissions`}
      fields={{
        entity: {name: 1},
        entity_id: {name: 1},
        role: 1,
        display_label: 1,
        user_id: {name: 1},
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-kpi-permissions', {row});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Add"
              view="add-kpi-permissions"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Entity',
          field: 'entity.name',
          type: 'text',
          responisve: 'sm',
        },
        {
          header: 'Entity Value',
          field: 'entity_id.name',
          type: 'text',
          responisve: 'sm',
        },
        {
          header: 'Role',
          field: 'role',
          type: 'text',
          responisve: 'sm',
        },
        {
          header: 'User',
          field: 'user_id.name',
          type: 'text',
          responisve: 'sm',
        },
        {
          header: 'Label',
          field: 'display_label',
          type: 'text',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-kpi-permissions', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/kpipermissions/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default KPIPermissionTable;
