import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const HelpItemTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: ['helpitems', 'helpscreens'],
  });

  return (
    <Table
      eventSourceId={'helpitems'}
      api={`/helpitems`}
      filter={{helpScreenId: params?.row?._id}}
      fields={{
        title: 1,
        desc: 1,
        videoUrl: 1,
        why_title: 1,
        how_title: 1,
        why_pointers: 1,
        how_pointers: 1,
        detail_title: 1,
        index: 1,
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-home-item', {row});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Add"
              view="add-home-item"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },
        {
          header: 'Description',
          field: 'desc',
          type: 'text',
          required: true,
        },
        {
          header: 'Video URL',
          field: 'videoUrl',
          type: 'text',
        },
        {
          header: 'Why Title',
          field: 'why_title',
          type: 'text',
        },
        {
          header: 'How Title',
          field: 'how_title',
          type: 'text',
        },
        {
          header: 'Why Pointers',
          field: 'why_pointers',
          type: 'multiText',
        },
        {
          header: 'How Pointers',
          field: 'how_pointers',
          type: 'multiText',
        },
        {
          header: 'Detail Title',
          field: 'detail_title',
          type: 'text',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-home-item', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/helpitems/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default HelpItemTable;
