import {getZeroTimeDate} from '@unthinkable/react-filters/src/DateUtility';
import {useAppStateContext} from '../../../providers/AppState';

const sendForApproval = ({invoke}) => ({
  title: 'Send For Approval',
  confirm: {
    title: 'Approve Request',
    message: 'Are you sure you want to send for Approval?',
  },

  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/reimbursements/${row?._id}`,
      message: 'Sent for Approval Successfully',
      props: {
        status: 'Pending',
      },
    });
  },
});

const dropReimbursement = ({invoke}) => ({
  title: 'Drop',
  variant: 'error',
  confirm: {
    title: 'Drop Request',
    message: 'Are you sure you want to Drop?',
  },
  message: 'Reimbursement Dropped Successfully',
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/reimbursements/${row?._id}`,
      props: {
        status: 'Drop',
      },
    });
  },
});

const approveReimbursementCOO = ({invoke, visible = false}) => {
  const {user = {}} = useAppStateContext();
  const {employee} = user;
  return {
    title: 'Approve',
    confirm: {
      title: 'Approve Request',
      message: 'Are you sure you want to Approve?',
    },
    message: 'Reimbursement Approved Successfully',

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/reimbursements/${row?._id}`,
        props: {
          status: 'Approved',
          approver: '53a437e96dc89c02007cbcc7',
          referred_status: 'Complete',
          approved_on: getZeroTimeDate(new Date()),
        },
      });
    },
    visible: ({row}) => visible || row?.total_amount <= 25000,
  };
};

const approveReimbursement = ({invoke, visible = false}) => {
  const {user = {}} = useAppStateContext();
  const {employee} = user;
  return {
    title: 'Approve',
    confirm: {
      title: 'Approve Request',
      message: 'Are you sure you want to Approve?',
    },
    message: 'Reimbursement Approved Successfully',

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/reimbursements/${row?._id}`,
        props: {
          approver: employee?._id,
          status: 'Approved',
          approved_on: getZeroTimeDate(new Date()),
        },
      });
    },
    visible: ({row}) => visible || row?.total_amount <= 25000,
  };
};

const rejectReimbursement = ({navigation}) => ({
  title: 'Reject',
  variant: 'error',
  // confirm: {
  //   title: 'Reject Request',
  //   message: 'Are you sure you want to Drop?',
  // },
  message: 'Reimbursement Rejected Successfully',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`reject-reimbursement`, {
      reimbursement: row?._id,
    });
    // invoke({
    //   uri: `/reimbursements/${row?._id}`,
    //   props: {
    //     status: 'Rejected',
    //     rejected_on:getZeroTimeDate(new Date())
    //   },
    // });
  },
});

const forwardReimbursementToCOO = ({invoke}) => {
  const {user = {}} = useAppStateContext();
  const {employee} = user;
  return {
    title: 'Refer to COO',
    confirm: {
      title: 'Forward Request (COO)',
      message: 'Are you sure you want to forward?',
    },
    message: 'Reimbursement forwarded to COO Successfully',

    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/reimbursements/${row?._id}`,
        props: {
          referred: true,
          referred_by: employee?._id,
          status: 'RefferedPending',
          // referred_status: 'Partial',
          referred_on: getZeroTimeDate(new Date()),
          // approved_by: '53a437e96dc89c02007cbcc7', //COO id to be replaced }
          approver: '53a437e96dc89c02007cbcc7', //COO id to be replaced }
        },
      });
    },
    visible: ({row}) => row?.total_amount > 25000,
  };
};

const partiallyPendingReimbursement = ({invoke, visible = false}) => ({
  title: 'Approve',
  confirm: {
    title: 'Approve Request',
    message: 'Are you sure you want to Approve?',
  },
  message: 'Reimbursement Approved Successfully',

  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/reimbursements/${row?._id}`,
      props: {
        status: 'Partially Pending',
        approved_on: getZeroTimeDate(new Date()),
      },
    });
  },
  visible: ({row}) => visible || row?.total_amount <= 25000,
});

const approvedByRm = ({invoke, visible = false}) => {
  const {user = {}} = useAppStateContext();
  const {employee} = user;

  return {
    title: 'Approve',
    confirm: {
      title: 'Approve Request',
      message: 'Are you sure you want to Approve?',
    },
    message: 'Reimbursement Approved Successfully',
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/reimbursements/${row?._id}`,
        props: {
          status: 'Approved',
          approved_by_rm: employee?._id,
          approved_on_rm: getZeroTimeDate(new Date()),
        },
      });
    },
  };
};

const alreadyProcessed = ({invoke}) => ({
  title: 'Create invoice',
  confirm: {
    title: 'Processed Request',
    message: 'Are you sure you want to mark Processed?',
  },
  message: 'Reimbursement Processed Successfully',

  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/reimbursements/${row?._id}`,
      props: {
        status: 'Processed',
        processed_on: getZeroTimeDate(new Date()),
      },
    });
  },
});

export {
  sendForApproval,
  dropReimbursement,
  approveReimbursement,
  rejectReimbursement,
  forwardReimbursementToCOO,
  partiallyPendingReimbursement,
  approvedByRm,
  alreadyProcessed,
  approveReimbursementCOO,
};
