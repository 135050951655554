import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const WorkloadAssignReplacementForm = props => {
  const {
    route: {params},
  } = props;
  let {row = {}, type} = params;
  let {
    project = [],
    employeeLastAssignProject = {},
    assigned_to = {},
    period = {},
  } = row || {};
  let projectSuggestion = [employeeLastAssignProject];
  if (type == 'workload') {
    projectSuggestion = project;
  }

  let {onSubmit} = useFormSubmit({
    uri: '/changeWorkloadResource',
    eventSourceId: 'workload-billable',
  });

  const {fetch} = useAppStateContext();

  let defaultValues = {
    front_facing_resource: {
      _id: assigned_to?._id,
      name: assigned_to?.name,
    },
    employee: {
      _id: assigned_to?._id,
      name: assigned_to?.name,
    },
    from_date: new Date(),
  };

  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Assign Replacement"
      defaultValues={defaultValues}
      beforeSubmit={({data})=>{
        let {project_resource,...rest}=data || {}
        return {data:rest}
      }}
      layoutFields={[
        {
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          required: true,
          size: 6,
         
        },
        {
          label: 'Project Resource',
          field: 'project_resource',
          type: 'autoComplete',
          api: '/employeeSuggestionProjectWise',
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => {
            let {project_id} = values;
            return {project_id};
          },
          size: 6,
          onChangeValue: async (value, _, {values, setFieldValue}) => {
            let {project_id} = values || {};

            const {data: employeeAssignmentData = {}} = await fetch({
              uri: '/employeeassignments',
              props: {
                filter: {
                  project_id: project_id?._id,
                  employee: value?._id,
                },
                fields: {
                  _id: 1,
                  to_date: 1,
                  employee: {name: 1},
                  milestone_id: {_id: 1, start_date: 1, end_date: 1},
                  skill_requirement: {_id: 1, from_date: 1, to_date: 1},
                  hours: 1,
                  project_skill: {_id: 1, name: 1},
                  resource_skill: {_id: 1, name: 1},
                  level_one_owner: {_id: 1, name: 1},
                  level_two_owner: {_id: 1, name: 1},
                  level_three_owner: {_id: 1, name: 1},
                  is_applicable_for_ma: 1,
                  is_shadow: 1,
                  is_internal: 1,
                },
                sort: {to_date: -1},

                only: true,
              },
            });
            let to_date;
            setFieldValue(
              'skill_requirement',
              employeeAssignmentData?.skill_requirement?._id,
            );
            setFieldValue(
              'milestone_id',
              employeeAssignmentData?.milestone_id?._id,
            );
            if (employeeAssignmentData?.skill_requirement) {
              to_date = employeeAssignmentData?.skill_requirement?.to_date;
            } else if (employeeAssignmentData?.milestone_id) {
              to_date = employeeAssignmentData?.milestone_id?.end_date;
            }
            setFieldValue('to_date', to_date);

            if(!to_date && employeeAssignmentData?.to_date)setFieldValue('to_date',employeeAssignmentData?.to_date)

              setFieldValue('hours', employeeAssignmentData?.hours);
            
              setFieldValue(
                'project_skill',
                employeeAssignmentData?.project_skill,
              );
           
              setFieldValue(
                'resource_skill',
                employeeAssignmentData?.resource_skill,
              );
          
              setFieldValue(
                'level_one_owner',
                employeeAssignmentData?.level_one_owner,
              );
           
              setFieldValue(
                'level_two_owner',
                employeeAssignmentData?.level_two_owner,
              );
           
              setFieldValue(
                'level_three_owner',
                employeeAssignmentData?.level_three_owner,
              );
            
              setFieldValue(
                'is_applicable_for_ma',
                employeeAssignmentData?.is_applicable_for_ma,
              );
            
              setFieldValue('is_shadow', employeeAssignmentData?.is_shadow);
            
              setFieldValue('is_internal', employeeAssignmentData?.is_internal);

            setFieldValue('updated_data', {
              _id: employeeAssignmentData?._id,
              to_date,
            });
          },
        },
        {
          label: 'Resource',
          field: 'employee',
          type: 'autoComplete',
          //   api: '/employeeSuggestionProjectWise',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          size: 6,
          readOnly: true,
          valueField: 'name',
         
        },
        {
          label: 'Front Facing Resource',
          field: 'front_facing_resource',
          type: 'autoComplete',
          //   api: '/employeeSuggestionProjectWise',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          //   filter: ({values}) => {
          //     let {project_id} = values;
          //     return {project_id};
          //   },
          size: 6,
          // readOnly: true,
        },
        {
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          //   required: true,
          size: 4,
        },
        {
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          //   required: true,
          size: 4,
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          //   required: true,
          size: 4,
        },
        {
          label: 'Project Skill',
          field: 'project_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },

        {
          label: 'Resource Skill',
          field: 'resource_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },

        {
          label: 'Level 1 Owner',
          field: 'level_one_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
        },
        {
          label: 'Level 2 Owner',
          field: 'level_two_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
        },
        {
          label: 'Level 3 Owner',
          field: 'level_three_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
        },
        {
          type: 'checkbox',
          field: 'is_applicable_for_ma',
          label: 'Applicable for MA',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export default WorkloadAssignReplacementForm;
