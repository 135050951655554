import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {assetMappings} from './assets/AssetUtility';
import {PROJECT_MODEL_TYPE_ID} from '../../common/constants/SourceConstants';

export const ChangeDatabaseNameForm = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {row, project} = params;
  const assetMapping = assetMappings[PROJECT_MODEL_TYPE_ID] || {};
  const {onSubmit} = useFormSubmit({
    uri: '/projectmodels',
    eventSourceId: assetMapping.eventSourceId,
    ...props,
  });

  return (
    <Form
      defaultValues={{_id: row?._id, database_id: row?.database_id}}
      header={{
        title: 'Update Database Name',
        secondaryTitle: row?.model,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Database Name',
          field: 'database_id',
          type: 'autoComplete',
          api: '/projectdatabasenames',
          suggestionField: 'name',
          valueField: 'name',
          filter: {project_id: project?._id},
          required: true,
          fields: {
            name: 1,
            database_id: {
              database: 1,
              version: 1,
            },
          },
          secondarySuggestionField: ({database_id} = {}) =>
            `${database_id?.database}${
              database_id?.version ? ' - ' + database_id?.version : ''
            }`,
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate(`add-database-name`, {
              ...params,
              project_id: project?._id,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
        },
      ]}
      {...props}
    />
  );
};
