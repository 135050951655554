import React from 'react';

import {TabView} from '../../../components/tab';
import {VoucherTable} from './VoucherTable';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {Row} from '@unthinkable/react-core-components';

const VoucherTab = props => {
  let {route: {params} = {}, navigation} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: params.period || getPreviousMonth(),
    // },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  const tabs = {
    all: {
      label: 'Voucher',
      view: (
        <VoucherTable
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            // {
            //   type: 'multiAutoComplete',
            //   label: 'Product',
            //   api: '/products',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'product',
            // },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            // {
            //   type: 'multiAutoComplete',
            //   label: 'Organization',
            //   api: '/organizations',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'organization',
            // },
            // {
            //   type: 'date',
            //   field: 'period',
            //   asParam: true,
            //   label: 'Voucher Date',
            // },
          ]}
        />,
      ],
      eventSourceId: 'voucher',
    },
  };

  return (
    <TabView
      {...props}
      search={searchValue}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton title=" Voucher" view="add-voucher" params={params} />,
      ]}
    />
  );
};

export default VoucherTab;
