import {Text} from '@unthinkable/react-core-components';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useAppStateContext} from '../../../providers/AppState';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';

const filters = ({tab}) => {
  return [
    {
      type: 'multiAutoComplete',
      label: 'Reporting To',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'reporting_to',
      searchFields: ['name', 'official_email_id', 'employee_code'],
    },
    {
      type: 'autoComplete',
      label: 'Practice',
      api: '/practices',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'primary_practice',
      searchFields: ['name'],
    },
  ];
};

const employeesFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'Active',
  params,
  period,
  download = () => {},
  icons,
}) => {
  return [
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    <IconButton
      icon={icons.Download}
      onPress={() => {
        download({
          uri: '/exportExcelHelper',
          props: {
            props: {
              fetchProps: {
                skipFields: true,
                api: '/employeesReports',
                addOnFilter: {
                  ...filterValues?.filter,
                },
                params: {
                  period,
                },
              },
              column: {
                Employee: 'name',
                Email: 'official_email_id',
                'Employee Code': 'employee_code',
                Experience: 'experience',
                Designation: 'designation.name',
                'Current Skill': 'resource_language.name',
                Practice: 'primary_practice.name',
                'Reporting To': 'reporting_to.name',
                Organizations: 'organizations.name',
              },
            },
          },
        });
      }}
    />,
  ];
};

const EmployeesReport = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    tab,
  } = props;
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/employeesReports`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      params={{...params, period}}
      limit={1000}
      eventSourceId={['employeeReport']}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          minWidth: 260,
        },
        {
          header: 'Employee Code',
          field: 'employee_code',
        },
        {
          header: 'Experience',
          render: ({row, styles: {rowText = {}}}) => {
            return <Text style={{...rowText}}>{row?.experience} yrs</Text>;
          },
          width: 100,
        },
        {
          header: 'Designation',
          field: 'designation.name',
          width: 180,
        },
        {
          header: 'Current Skill',
          field: 'resource_language.name',
          width: 180,
        },
        {
          header: 'Practice',
          field: 'primary_practice.name',
          width: 180,
        },
        {
          header: 'Reporting To',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
        },
        {
          header: 'Organisation',
          field: 'organization.name',
          width: 180,
        },
      ]}
    />
  );
};

export const EmployeesReportTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {download} = useAppStateContext();
  const icons = useTheme('icons');
  const tabs = {
    shift_allowance_reports: {
      label: 'Employees',
      view: (
        <EmployeesReport
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
          }}
          period={period}
          tab="employeeReport"
        />
      ),
      api: '/employeesReports',
      limit: 1000,
      eventSourceId: ['employeeReport'],
      addOnFilter: {
        ...filter,
      },
      params: {...params, period},
      actions: employeesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'employeeReport',
        params,
        period,
        download,
        icons,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
