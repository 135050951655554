import {useTheme} from '@unthinkable/react-theme';
import tinycolor from 'tinycolor2';

export const getAccentColor = (color, colors) => {
  color = color || 'ACCENT1';
  const colorInstance = tinycolor(color);

  if (colorInstance.isValid()) {
    return {color: colorInstance.toHexString()};
  } else if (colors[color]) {
    return {color: colors[color]};
  }

  color = color.toUpperCase().split(' ').join('');

  const LOW = colors[`${color}_LOW`];
  const UPPER_LOW = colors[`${color}_UPPER_LOW`];
  const MEDIUM = colors[`${color}_MEDIUM`];
  const UPPER_MEDIUM = colors[`${color}_UPPER_MEDIUM`];
  const HIGH = colors[`${color}_HIGH`];
  const HIGHEST = colors[`${color}_HIGHEST`];

  return {LOW, UPPER_LOW, MEDIUM, UPPER_MEDIUM, HIGH, HIGHEST};
};

export const useAccentColor = color => {
  const colors = useTheme('colors');
  return getAccentColor(color, colors);
};
