import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchFormData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { CASCADER_NAVIGATE_TO_ADD_VIEW } from '../../common/constants/SourceConstants';
import { CreateCustomerFormNew } from './CustomerFormNew';

export const CustomerForm = props => {
  const {
    route: {params},
    navigation,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Customer',
    uri: '/customers',
    ...props,
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          size: 6,
          required: true,
        },
        {
          label: 'Account Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'text',
          field: 'website',
          label: 'Website',
          // size: 6,
        },
        {
          label: 'Sales Owner',
          field: 'sales_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'text',
          field: 'client_rating',
          type: 'autoComplete',
          options: ['A+', 'A', 'B', 'C', 'D', 'E'],
          label: 'Client Rating',
          size: 6,
          required: true,
        },
        {
          type: 'richText',
          field: 'description',
          label: 'Description',
          required: true,
        },
        {
          field: 'country',
          label: 'Country',
          type: 'autoComplete',
          api: `/countries`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          type: 'autoComplete',
          field: 'industry',
          api: `/industries`,
          suggestionField: 'name',
          valueField: 'name',
          label: 'Industry',
          size: 6,
          required: true,
        },
        {
          type: 'text',
          field: 'billing_contact_person',
          label: 'Billing Contact Person',
          size: 6,
        },
        {
          type: 'text',
          field: 'billing_email_id',
          label: 'Billing Email Id',
          size: 6,
        },
        {
          type: 'text',
          field: 'place_of_supply',
          label: 'Place to Supply',
          required: true,
          size: 6,
        },
        {
          type: 'number',
          field: 'credit_days',
          label: 'Credit Days',
          required: true,
          size: 6,
        },
        {
          type: 'text',
          field: 'type_of_supply',
          label: 'Type of Supply',
          type: 'autoComplete',
          options: ['B2B', 'B2C', 'SEZWOP'],
          size: 6,
          required: true,
        },
        {
          type: 'text',
          field: 'customer_category',
          type: 'autoComplete',
          options: ['Domestic', 'International', 'Upwork'],
          label: 'Customer Category',
          size: 6,
          required: true,
        },
        {
          type: 'text',
          field: 'gstin',
          label: 'GST',
          size: 4,
        },
        {
          type: 'text',
          field: 'pan_no',
          label: 'PAN',
          size: 4,
        },
        {
          type: 'text',
          field: 'tan_no',
          label: 'TAN',
          size: 4,
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'product',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          label: 'Product',
          size: 6,
          required: true,
        },
        {
          type: 'autoComplete',
          field: 'organization',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          label: 'Organization',
          size: 6,
          required: true,
        },

        {
          label: 'Base Currency',
          field: 'base_currency',
          type: 'autoComplete',
          api: `/currencies`,
          suggestionField: 'currency',
          valueField: 'currency',
          size: 6,
        },
        {
          type: 'text',
          field: 'source',
          type: 'autoComplete',
          options: ['Marketing', 'Inside Sales', 'Bidding', 'Tender'],
          label: 'Source',
          required: true,
        },
        {
          label: 'Campaign Details',
          fields: [
            {
              placeholder: 'Channel & Campaign',
              label: 'Channel & Campaign',
              field: 'campaign_type',
              type: 'cascader',
              required: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'campaign_type',
                  api: '/types/suggestionsForLead',
                  suggestionField: 'label',
                  valueField: 'label',
                  required: true,
                },
                {
                  label: 'Campaign Value',
                  placeholder: 'Campaign Id',
                  field: 'campaign_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  params: ({values: {campaign_type} = {}}) => {
                    return {
                      source: campaign_type,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
          ],
        },

        {
          type: 'file',
          field: 'gst_certificate',
          placeholder: 'GST Certificate',
          size: 6,
        },
        {
          type: 'file',
          field: 'pan_certificate',
          placeholder: 'PAN Certificate',
          size: 6,
        },
        {
          type: 'file',
          field: 'tan_certificate',
          placeholder: 'TAN Certificate',
          size: 6,
        },
        {
          type: 'file',
          field: 'tax_identification_certificate',
          placeholder: 'Tax Identification Certificate',
          size: 6,
        },
      ]}
    />
  );
};

export const AddCustomerForm = props => {
  const {
    route: {params},
  } = props;

  const {department, row} = params;
  const {data: potentialCustomerData} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: row?.potential_customer?._id,
    },
    fields: {
      campaign_type: {_id: 1, name: 1},
      campaign_id: {_id: 1, name: 1},
      employee_count: {_id: 1, number: 1},
      organization_category: {_id: 1, name: 1},
      geography_location: {_id: 1, name: 1},
      lead_source: {_id: 1, name: 1},
      industry: {_id: 1, name: 1},
      country: {_id: 1, name: 1},
      lead_score: 1,
      website: 1,
      email: 1,
    },
  });

  const {data: opportunityData} = useFetchFormData({
    api: '/opportunities',
    filter: {
      _id: row?._id,
    },
    fields: {
      type: 1,
    },
  });

  if (!potentialCustomerData) {
    return null;
  }

  if (!opportunityData) {
    return null;
  }
  return (
    <CustomerForm
      {...props}
      header={'Add Customer'}
      defaultValues={{
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
        product: params?.product,
        organization: params?.organization,
        department,
        source: opportunityData?.type,
        owner: row?.owner,
        name: row?.potential_customer?.name,
        employee_count: potentialCustomerData?.employee_count,
        organization_category: potentialCustomerData?.organization_category,
        geography_location: potentialCustomerData?.geography_location,
        lead_source: potentialCustomerData?.lead_source,
        industry: potentialCustomerData?.industry,
        country: potentialCustomerData?.country,
        lead_score: potentialCustomerData?.lead_score,
        website: potentialCustomerData?.website || potentialCustomerData?.email,

        campaign_type: potentialCustomerData?.campaign_type,
        campaign_id: potentialCustomerData?.campaign_id,
        organization: row?.organization,
        channel: potentialCustomerData?.campaign_type?._id,
        last_communication_date: row?.last_communication_date,
        last_communication: row?.last_communication,
        potential_customer: row?.potential_customer,
        next_task_date: row?.next_task_date,
        next_task: row?.next_task,
        opportunity: row?._id,
      }}
    />
  );
};

export const AddCustomerFormForOpportunity = props => {
  const {
    route: {params},
  } = props;

  const {department, row} = params;
  const {data: potentialCustomerData} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: row?.potential_customer?._id,
    },
    fields: {
      campaign_type: {_id: 1, name: 1},
      campaign_id: {_id: 1, name: 1},
      employee_count: {_id: 1, number: 1},
      organization_category: {_id: 1, name: 1},
      geography_location: {_id: 1, name: 1},
      lead_source: {_id: 1, name: 1},
      industry: {_id: 1, name: 1},
      country: {_id: 1, name: 1},
      lead_score: 1,
      website: 1,
      email: 1,
    },
  });

  const {data: opportunityData} = useFetchFormData({
    api: '/opportunities',
    filter: {
      _id: row?._id,
    },
    fields: {
      type: 1,
    },
  });

  if (!potentialCustomerData) {
    return null;
  }

  if (!opportunityData) {
    return null;
  }
  return (
    <CreateCustomerFormNew
      {...props}
      header={'Add Customer'}
      defaultValues={{
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
        product: params?.product,
        organization: params?.organization,
        department,
        source: opportunityData?.type,
        owner: row?.owner,
        name: row?.potential_customer?.name,
        employee_count: potentialCustomerData?.employee_count,
        organization_category: potentialCustomerData?.organization_category,
        geography_location: potentialCustomerData?.geography_location,
        lead_source: potentialCustomerData?.lead_source,
        industry: potentialCustomerData?.industry,
        country: potentialCustomerData?.country,
        lead_score: potentialCustomerData?.lead_score,
        website: potentialCustomerData?.website || potentialCustomerData?.email,

        campaign_type: potentialCustomerData?.campaign_type,
        campaign_id: potentialCustomerData?.campaign_id,
        organization: row?.organization,
        channel: potentialCustomerData?.campaign_type?._id,
        last_communication_date: row?.last_communication_date,
        last_communication: row?.last_communication,
        potential_customer: row?.potential_customer,
        next_task_date: row?.next_task_date,
        next_task: row?.next_task,
        opportunity: row?._id,
      }}
    />
  );
};

export const AddCustomerFormForCustomer = props => {
  const {
    route: {params},
  } = props;

  const {department, row} = params;
  return (
    <CustomerForm
      {...props}
      header={'Add Customer'}
      defaultValues={{
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
        product: params?.product,
        organization: params?.organization,
        department,
      }}
    />
  );
};

export const EditCustomerForm = props => {
  const {route: {params} = {}} = props;
  const {customerId} = params;
  const {data} = useFetchFormData({
    api: '/customers',
    filter: {
      _id: customerId,
    },
    fields: {
      name: 1,
      billing_contact_person: 1,
      billing_email_id: 1,
      place_of_supply: 1,
      credit_days: 1,
      type_of_supply: 1,
      customer_category: 1,
      gstin: 1,
      pan_no: 1,
      tan_no: 1,
      owner: {name: 1, color: 1},
      campaign_type: {name: 1},
      campaign_id: {name: 1},
      employee_count: {number: 1},
      country: {name: 1},
      industry: {name: 1},
      department: {name: 1},
      product: {name: 1},
      organization: {name: 1},
      account_manager: {name: 1},
      potential_customer: {name: 1},
      base_currency: {currency: 1},
      channel: {name: 1},
      sales_owner: {name: 1},
      website: 1,
      source: 1,
      description: 1,
      tax_identification_certificate: 1,
      tan_certificate: 1,
      pan_certificate: 1,
      gst_certificate: 1,
      client_rating: 1,
    },
  });
  if (!data) {
    return null;
  }
  return (
    <CustomerForm {...props} header={'Edit Customer'} defaultValues={data} />
  );
};

export const MakeCustomerForm = props => {
  const {route: {params} = {}} = props;
  const {leadId} = params;
  const {data} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: leadId,
    },
    fields: {
      name: 1,
      owner: {_id: 1, name: 1},
      campaign_type: {_id: 1, name: 1},
      campaign_id: {_id: 1, name: 1},
      employee_count: {_id: 1, number: 1},
      organization_category: {_id: 1, name: 1},
      geography_location: {_id: 1, name: 1},
      lead_source: {_id: 1, name: 1},
      country: {_id: 1, name: 1},
      industry: {_id: 1, name: 1},
      department: {_id: 1, name: 1},
      product: {_id: 1, name: 1},
      organization: {_id: 1, name: 1},
      lead_score: 1,
      website: 1,
      email: 1,
    },
  });
  if (!data) {
    return null;
  }
  const {_id, ...restdata} = data;
  return (
    <CreateCustomerFormNew
      {...props}
      header={'Make Customer'}
      defaultValues={{
        ...restdata,
        potential_customer: leadId,
        active_status: 'Active',
        created_on: getZeroTimeDate(new Date()),
      }}
    />
  );
};
