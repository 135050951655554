import React, {useState} from 'react';
import {
  Image,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {MultipleFileRender} from '@unthinkable/react-file-upload';
import {ImageView} from './ImageView';

export const ImageViewer = ({
  attachments,
  showPreview = true,
  closeable = true,
  onClose,
  renderHeader,
  containerStyle,
  footerContainerStyle,
  closeContainerStyle,
  closeIconStyle,
  CloseIcon,
  VideoContentTypeIcon,
  ApplicationContentTypeIcon,
  ImageContentTypeIcon,
  multipleAttachmentsPreviewTheme,
  resourceUrl,
  ...props
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  const attachmentCount = attachments?.length || 0;

  if (!attachmentCount) {
    return null;
  }

  const imageSource = attachments[imageIndex];
  return (
    <View style={[{flex: 1, overflow: 'hidden'}, containerStyle]}>
      {renderHeader ? renderHeader({attachment: imageSource}) : void 0}
      <ImageView
        key={imageIndex}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
        imageSource={imageSource}
        resourceUrl={resourceUrl}
        attachmentCount={attachmentCount}
        VideoContentTypeIcon={VideoContentTypeIcon}
        ApplicationContentTypeIcon={ApplicationContentTypeIcon}
        ImageContentTypeIcon={ImageContentTypeIcon}
        {...props}
      />
      {showPreview ? (
        <View style={footerContainerStyle}>
          <MultipleFileRender
            onSelectImage={setImageIndex}
            selectedIndex={imageIndex}
            selectable={true}
            resourceUrl={resourceUrl}
            values={attachments}
            defaultContentTypeIcons={{
              application: ApplicationContentTypeIcon,
              video: VideoContentTypeIcon,
              image: ImageContentTypeIcon,
            }}
            {...multipleAttachmentsPreviewTheme}
            {...props}
          />
        </View>
      ) : (
        void 0
      )}
      {closeable && onClose ? (
        <TouchableOpacity
          onPress={() => {
            onClose();
          }}
          style={closeContainerStyle}>
          <Image style={closeIconStyle} source={CloseIcon} />
        </TouchableOpacity>
      ) : (
        void 0
      )}
    </View>
  );
};
