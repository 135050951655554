import React from 'react';
import {Table} from '../../../components/table/Table';

export const CommunicationTable = props => {
  return (
    <Table
      {...props}
      api={`/communications`}
      fields={{
        date: 1,
        owner: { name: 1},
        description: 1,
        communication_mode: 1,
        communication_type: 1,
        next_task_date: 1,
        next_task: 1,
      }}
      columns={[
        {
          type: 'date',
          field: 'date',
          header: 'Date',
        },
        {
          type: 'text',
          field: 'owner.name',
          header: 'Owner',
        },
        {
          type: 'text',
          field: 'description',
          header: 'Description',
        },
        {
          type: 'text',
          field: 'communication_mode',
          header: 'Communication Mode',
        },
        {
          type: 'text',
          field: 'communication_type',
          header: 'Communication Type',
        },
        {
          type: 'date',
          field: 'next_task_date',
          header: 'Next Task Date',
        },
        {
          type: 'text',
          field: 'next_task',
          header: 'Next Task',
        },
      ]}
    />
  );
};
