import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import {
    ApprovedLeaveRequestTable,
    RejectedLeaveRequestTable,
    TeamLeaveRequestTable,
} from './TeamRequestTable';

export const TeamRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;

  params = {
    ...filterValues,
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <TeamLeaveRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ],
    },
    approved: {
      label: 'Closed',
      view: (
        <ApprovedLeaveRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ],
    },
    // rejected: {
    //   label: 'Rejected',
    //   view: (
    //     <RejectedLeaveRequestTable
    //       filterParams={filterParams}
    //       filter={filter}
    //       params={params}
    //       {...props}
    //     />
    //   ),
    //   actions: [
    //     <GroupFilter
    //       filterValues={filterValues}
    //       applyFilter={applyFilter}
    //       filters={[
    //         {
    //           type: 'autoComplete',
    //           label: 'Employee',
    //           api: '/employeeSuggestions',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'employee',
    //           searchFields: ['name', 'official_email_id', 'employee_code'],
    //         },
    //       ]}
    //     />,
    //   ],
    // },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
