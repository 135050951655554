// Akshat Garg - 11/03/24 - Creating new view of communication analytics for view communications tab in campaign setup navigator
import {getToday} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';

export const EmailSyncCommunicationView = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {
      period: getToday(),
    },
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  return (
    <Table
      {...props}
      api={`/email-sync-communication`}
      params={{
        period,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('email-sync-communication-table', {
            addOnFilter: {
              owner: row?._id?.owner,
              date: {$gte: period?.from, $lte: period?.to},
              thread_id: {$exists: true}
            },
          });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                options={[
                  {
                    label: 'Week',
                    value: 'week',
                  },
                  {
                    label: 'Today',
                    value: 'day',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'owner.official_email_id',
          header: 'Email',
        },
        {
          type: 'number',
          field: 'count',
          header: 'Count',
        },
      ]}
    />
  );
};
