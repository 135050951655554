import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const KeyPillarFeatureForm = props => {
  const {
    route: { params },
  } = props;
  
  const { keyPillar, row } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/keypillarfeatures',
    eventSourceId: 'keyPillarFeature',
  });

  return (
    <Form
      api={`/keypillarfeatures/${row?._id}`}
      fields={{
        title: 1,
        subTitle: 1,
        image: 1,
        description: {
          text: 1,
          color: 1,
        },
        index: 1,
        keyPillar: 1,
      }}
      beforeSubmit={({ data }) => {
        return {
          data: {
            keyPillar,
            ...data,
          }
        };
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },
        {
          label: 'Sub Title',
          type: 'text',
          field: 'subTitle',
        },
        {
          label: 'Description',
          field: 'description',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  header: 'Text',
                  field: 'text',
                  type: 'text',
                  placeholder: 'text',
                  required: true,
                },
                {
                  label: 'Color',
                  field: 'color',
                  placeholder: 'Color',
                  type: 'autoComplete',
                  options: ['black', 'green'],
                  required: true,
                },
              ]}
              columns={[
                {
                  header: 'Text',
                  field: 'text',
                  placeholder: 'Text',
                  type: 'text',
                },
                {
                  header: 'Color',
                  field: 'color',
                  placeholder: 'Color',
                },
              ]}
            />
          ),
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
        {
          field: 'image',
          type: 'file',
          label: 'Image',
        },
      ]}
      {...props}
    />
  );
};

export const AddKeyPillarFeatureForm = props => {
  return <KeyPillarFeatureForm header="Add Key Pillar Feature" {...props} />;
};

export const UpdateKeyPillarFeatureForm = props => {
  return (
    <KeyPillarFeatureForm mode="edit" header="Update Key Pillar Feature" {...props} />
  );
};
