export const ChatMessageStyles = ({theme: {colors, fonts, icons}}) => {
  return {
    primaryBubble: colors.SURFACE2,
    secondaryBubble: colors.BACKGROUND,
    message: {
      message: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
      },
      mentionsText: {
        ...fonts.BODY3,
        color: colors.ACCENT1_HIGH,
      },
    },
  };
};
