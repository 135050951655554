import React from 'react';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {Tag} from '../../../components/chip/Chip';
import {Col, Image, Text, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {useState} from 'react';

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

const RenderType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  if (row?.projection_type && row?.projection_type == 'tm') {
    return (
      <>
        <Tag value={'T&M'} color={'#FCF4D4'} />
      </>
    );
  } else if (row?.projection_type && row?.projection_type == 'fixed') {
    return (
      <>
        <Tag value={'Fixed'} color={'#DAFDFE'} />
      </>
    );
  } else {
    return 0;
  }
};

const RenderOppType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  if (row?.type && row?.type == 'PotentialCustomer') {
    return (
      <>
        <Tag value={'Prospect'} color={'#FCF4D4'} />
      </>
    );
  } else if (row?.type && row?.type == 'Customer') {
    return (
      <>
        <Tag value={'Customer'} color={'#DAFDFE'} />
      </>
    );
  } else {
    return 0;
  }
};

const RenderClosureProb = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');

  return (
    <>
      <Tag value={row?.probability_of_close} />
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_MEDIUM,
          paddingLeft: 10,
        }}>
        {moment(row?.expected_close_date).format('DD MMM, YY')}
      </Text>
    </>
  );
};

const ManMonthRender = ({row = {}, styles = {}}) => {
  const [loading] = useState(false);
  const colors = useTheme('colors');

  let {rowText = {}} = styles;
  if (!row?.man_months) {
    return (
      <View style={{flexDirection: 'row'}}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}>
              <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
                Define
              </Text>
            </View>
          </>
        )}
      </View>
    );
  }
  if (row?.man_months) {
    return <Text style={{...rowText}}>{row?.man_months}</Text>;
  }
};

const CUSTOMER = {
  header: 'Opportunity',
  render: CustomerRender,
  responsive: 'sm',
};

const PROJECTION_TYPE = {
  header: 'Type',
  render: RenderType,
  align: 'center',
  width: 150,
};

const TYPE = {
  header: 'Type',
  render: RenderOppType,
  align: 'center',
  width: 150,
};

const RenderSkills = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  const skillName = row?.opportunity?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

export const OpportunityProjectionListTnM = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  return (
    <Table
      {...props}
      params={{...params, ...filterParams}}
      eventSourceId={['OpportunityProjection']}
      searchFields={['type']}
      api={`/pm/opportunity-projections/t&m`}
      variant={'bordered'}
      columns={[
        CUSTOMER,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 110,
        },
        {
          header: 'Sales/AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'PM',
          field: 'pm',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'CSM',
          field: 'csm',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'Opportunity No.',
          field: 'opportunity_number',
          type: 'number',
          align: 'center',
        },
        {
          header: 'Closure Probability',
          align: 'center',
          render: RenderClosureProb,
          width: 150,
        },
        TYPE,
        {
          header: 'Actions',
          align: 'center',
          aggregate: true,
          field: 'man_months',
          // width: 200,
          render: ({row, styles}) => (
            <ManMonthRender row={row} styles={styles} />
          ),
          onPress: ({row}) => {
            navigation.navigate(`add-projected-billing-mm`, {
              row: row,
            });
          },
        },
      ]}
    />
  );
};

export const OpportunityProjectionListFixed = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  return (
    <Table
      {...props}
      params={{...params, ...filterParams}}
      eventSourceId={['OpportunityProjection']}
      searchFields={['type']}
      api={`/pm/opportunity-projections/fixed`}
      variant={'bordered'}
      columns={[
        CUSTOMER,
        {
          header: 'Sales/AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'PM',
          field: 'pm',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'CSM',
          field: 'csm',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'Quoted Hrs.',
          field: 'quoted_hours',
          type: 'number',
          align: 'center',
        },
        {
          header: 'Closure Probability',
          align: 'center',
          render: RenderClosureProb,
        },
        TYPE,
        {
          header: 'Actions',
          align: 'center',
          aggregate: true,
          field: 'man_months',
          render: ({row, styles}) => (
            <ManMonthRender row={row} styles={styles} />
          ),
          onPress: ({row}) => {
            navigation.navigate(`add-projected-billing-mm`, {
              row: row,
            });
          },
        },
      ]}
    />
  );
};

export const OpportunityProjectionList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      params={params}
      eventSourceId={['OpportunityProjection']}
      searchFields={['type']}
      api={`/am/opportunity-projections`}
      renderHeader={() => (
        <TableHeader
          title="My Opportunities"
          actions={[
            <AddButton
              title="Projection"
              view="add-opportunityprojection-t&m"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      onRowPress={({row}) => {
        console.log('row-', row);
        navigation.navigate(`opportunity-projection-details`, {
          row,
          fromOppProjection: true,
        });
      }}
      filter={{
        owner: employeeId,
      }}
      columns={[
        CUSTOMER,
        PROJECTION_TYPE,
        {
          header: 'Exp. Closure Date',
          field: 'expected_close_date',
          type: 'date',
          width: 200,
          align: 'center',
        },
        {
          header: 'Opportunity No.',
          field: 'opportunity_number',
          type: 'number',
          width: 200,
          align: 'center',
        },
        {
          header: 'Quote Hrs',
          field: 'quoted_hours',
          type: 'number',
          width: 200,
          align: 'center',
        },
        {
          header: 'Closure Probability',
          field: 'probability_of_close',
          type: 'colorTag',
          width: 200,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export const OpportunityProjectionListTeam = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      params={params}
      eventSourceId={['OpportunityProjection']}
      searchFields={['type']}
      api={`/team/opportunity-projections`}
      renderHeader={() => (
        <TableHeader
          title="My Opportunities"
          actions={[
            <AddButton
              title="Projection"
              view="add-opportunityprojection-t&m"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      onRowPress={({row}) => {
        console.log('row-', row);
        navigation.navigate(`opportunity-projection-details`, {
          row,
          fromOppProjection: true,
        });
      }}
      columns={[
        CUSTOMER,
        {
          header: 'Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 150,
        },
        PROJECTION_TYPE,
        {
          header: 'Exp. Closure Date',
          field: 'expected_close_date',
          type: 'date',
          width: 200,
          align: 'center',
        },
        {
          header: 'Opportunity No.',
          field: 'opportunity_number',
          type: 'number',
          width: 200,
          align: 'center',
        },
        {
          header: 'Quote Hrs',
          field: 'quoted_hours',
          type: 'number',
          width: 200,
          align: 'center',
        },
        {
          header: 'Closure Probability',
          field: 'probability_of_close',
          type: 'colorTag',
          width: 200,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};
