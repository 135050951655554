import {Platform} from '@unthinkable/react-core-components';

import {CopyLink} from '../../../components/copyLink/CopyLink';

export const FeatureCopyLink = ({params}) => {
  if (Platform.OS !== 'web') {
    return null;
  }
  const projectCode = params?.project?.code;
  const featureCode = params?.feature?.code;

  if (!projectCode || !featureCode) {
    return null;
  }
  let baseURL = window.location.origin;
  const linkToCopy = `${baseURL}/projects/${projectCode}/features/${featureCode}`;
  return (
    <CopyLink
      link={linkToCopy}
      message="Item link has been copied to your clipboard."
    />
  );
};
