import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

const ProductTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      params={params}
      eventSourceId="Product"
      api={`/products`}
      fields={{
        name: 1,
        description: 1,
        services: 1,
        differentiator: 1,
        status: 1,
        product_type: 1,
        managers: {name: 1},
      }}
      filter={{
        _id: 'params?.source_id',
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`product-detail`, {productId: row._id});
      }}
      renderHeader={() => (
        <TableHeader
          title="Product"
          actions={[
            <AddButton
              title="Add Product"
              view="add-product"
              // params={params}
            />,
          ]}
        />
      )}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'description',
          header: 'Description',
        },
        {
          type: 'text',
          field: 'services',
          header: 'Services',
        },
        {
          type: 'text',
          field: 'differentiator',
          header: 'Differentiator',
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
        },
        {
          type: 'text',
          field: 'product_type',
          header: 'Product Type',
        },
        {
          type: 'text',
          field: 'managers.name',
          header: 'Managers',
        },
      ]}
      {...props}
    />
  );
};

export default ProductTable;
