import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {Form} from '../../../components/form/Form';
import {View} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {
  INMAIL_CAMPAIGN_TYPE_ID,
  OTHER_CAMPAIGN_TYPE_ID,
} from '../../common/constants/SourceConstants';
export const ImportLeads = props => {
  const {post} = useAppStateContext();
  const {
    route: {params},
  } = props;
  const {source, model, inmailProspect, inreachProspects} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/ImportHistoryLogs',
    eventSourceId: 'reloadImportHistoryLogs',
    ...props,
  });
  // Akshat Garg - 07/03/24 adding a new field of Campaign name which is dropdown menu of all inmail campaign names, plus also sending a default campaign_type value of inmail campaign when then isInmailProspect exists or true.

  let defaultValues = {
    importDate: getZeroTimeDate(new Date()),
    source,
    model,
  };
  let layoutFields = [
    {
      placeholder: 'File',
      field: 'file',
      type: 'file',
      size: 6,
    },
  ];

  if (inmailProspect || inreachProspects) {
    defaultValues = {
      ...defaultValues,
      campaign_type: inmailProspect
        ? INMAIL_CAMPAIGN_TYPE_ID
        : OTHER_CAMPAIGN_TYPE_ID,
    };
    layoutFields.push({
      label: 'Campaign name',
      field: 'campaign_id',
      type: 'autoComplete',
      api: `/campaigns`,
      suggestionField: 'name',
      valueField: 'name',
      filter: {
        status: {$in: ['Confirmed', 'In Experiment']},
      },
    });
  }

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      header={'Import'}
      layout="ImportLeads"
      submitAction={'Upload'}
      defaultValues={defaultValues}
      layoutFields={layoutFields}
    />
  );
};
