import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {TableHeader} from '../../../components/table/Headers';

export const TeamEfficiencyReport = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const {forDrill = false, employeeId: empId} = params;
  const employeeId = user?.employee?._id;

  return (
    <Table
      params={{...params, forDrill}}
      renderHeader={() => forDrill && <TableHeader title={'Team'} />}
      eventSourceId={'Task'}
      api={`/employees/${forDrill ? empId : employeeId}/teamEfficiencyReport`}
      columns={[
        {
          header: 'Member',
          field: 'member',
          render: UserAvatarChip,
        },
        {
          header: 'Reporting Team',
          field: 'reporting_team_size',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(`employee-wise-team-efficiency`, {
              ...params,
              employeeId: row._id,
              row,
              forDrill: true,
            });
          },
        },
        {
          header: 'Unallocated',
          field: 'bench',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('employee-skill-wise-billing-status', {
              employeeFilter: {
                _id: {
                  $in: row.benchEmployeesIds,
                },
              },
            });
          },
        },
        {
          header: 'Managed Projects',
          field: 'managed_project_count',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Managed Team Size',
          field: 'managed_team_size',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Managed Proj. Eff.',
          field: 'managed_project_efficiency',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Planned Tasks',
          field: 'managed_project_active_task_estimated_hours',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
      ]}
    />
  );
};

export const AllEmployeeTeamEfficiencyReport = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  return (
    <Table
      params={{...params, forDrill: false}}
      eventSourceId={'Task'}
      api={`/allEmployeeTeamEfficiencyReport`}
      columns={[
        {
          header: 'Member',
          field: 'member',
          render: UserAvatarChip,
        },
        {
          header: 'Reporting Team',
          field: 'reporting_team_size',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(`employee-wise-team-efficiency`, {
              ...params,
              employeeId: row._id,
              row,
              forDrill: true,
            });
          },
        },
        {
          header: 'Unallocated',
          field: 'bench',
          type: 'colorChip',
          aggregate: true,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('employee-skill-wise-billing-status', {
              employeeFilter: {
                _id: {
                  $in: row.benchEmployeesIds,
                },
              },
            });
          },
        },
        {
          header: 'Managed Projects',
          field: 'managed_project_count',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Managed Team Size',
          field: 'managed_team_size',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Managed Proj. Eff.',
          field: 'managed_project_efficiency',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Planned Tasks',
          field: 'managed_project_active_task_estimated_hours',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
      ]}
    />
  );
};
