import React from 'react';
import {InlineForm} from '../../../../components/form/Form';
import {Table} from '../../../../components/table/Table';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID} from '../../../common/constants/SourceConstants';
import {SplitScreen} from '../../../../components/SplitScreen';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {TabView} from '../../../../components/tab';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {SearchFilter} from '../../../../components/filter/Filters';
import {
  dataServiceLibraryExportParams,
  DataServiceLibraryTable,
} from './DataServiceLibraryScreen';
import {DataServiceLibraryDetailForm} from './DataServiceLibraryForm';

const EVENT_SOURCE_ID = ['Feature', 'Task', 'DataServiceLibrary', 'DataImport'];

const DataServiceLibraryInlineForm = props => {
  const {
    navigation,
    route: {params},
    sourceIds,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'DataServiceLibrary',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, projectlibrary_id} = params;

  const defaultValues = {
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    project_id: project?._id,
    feature_id: feature?._id,
    is_changed: false,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      eventSourceId={'DataServiceLibrary'}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Name',
          placeholder: 'Data Service Name',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID}/suggestions`,
          suggestionField: 'name',
          valueField: 'name',
          searchFields: ['name'],
          required: true,
          filter: {
            _id: {$nin: sourceIds},
            library_id: projectlibrary_id,
            library_version_id: null,
          },
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-data-service-library`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

const name = {
  type: 'text',
  field: 'name',
  header: 'Name',
};

const FeaturesDataServiceLibraryTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
  } = props;

  const {feature} = params;

  const tableProps = {
    api: `/features/${feature?._id}/assets/${PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID}/asset-data`,
  };

  const {assetDetail, tasks, removeFromFeature, versions} = useAssetActions({
    navigation,
    params,
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
  });

  return (
    <Table
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID}
          assetInlineForm={
            feature?.library_id ? (
              <DataServiceLibraryInlineForm {...props} />
            ) : (
              void 0
            )
          }
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={dataServiceLibraryExportParams({
            ...tableProps,
            fromFeature: true,
          })}
          navigation={navigation}
          showImportData={true}
          route={props.route}
          {...headerProps}
        />
      )}
      eventSourceId={EVENT_SOURCE_ID}
      selection
      onRowPress={assetDetail}
      columns={[
        name,
        assetColumns.tasks({onPress: tasks}),
        assetColumns.est_hrs,
        assetColumns.assignees,
      ]}
      moreActions={[versions, removeFromFeature]}
      {...tableProps}
    />
  );
};

const FeatureDataServiceLibraryScreen = props => {
  return (
    <SplitScreen splitScreen={<DataServiceLibraryDetailForm {...props} />}>
      <FeaturesDataServiceLibraryTable {...props} />
    </SplitScreen>
  );
};

export const AllDataServiceLibraryScreen = props => {
  const {
    route: {params},
    api,
  } = props;

  const {project, feature, projectlibrary_id, integratedProject} = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField =
    assetMappings[PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID].sourceField;

  const tableProps = {
    api:
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID}/all-data`,
    addOnFilter: {
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: sourceField,
    filter: {
      library_id: projectlibrary_id,
      library_version_id: integratedProject
        ? integratedProject.library_version_id
        : null,
    },
    params: {
      show_in_development: !feature?._id,
    },
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={dataServiceLibraryExportParams({
              ...tableProps,
              fromFeature: true,
            })}
          />,
          feature?.library_id ? (
            <AssetAddButton
              title={'Data Service'}
              view={'add-data-service-library'}
              params={params}
            />
          ) : (
            void 0
          ),
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<DataServiceLibraryDetailForm {...props} />}>
      <DataServiceLibraryTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        fromFeature
      />
    </SplitScreen>
  );
};

export const FeatureDataServiceLibraryTab = props => {
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'In Development',
          view: <FeatureDataServiceLibraryScreen {...props} />,
        },
        all: {
          label: 'All',
          view: <AllDataServiceLibraryScreen {...props} />,
        },
      }}
    />
  );
};
