import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {Chip, Tag} from '../../../components/chip/Chip';
import {TabView} from '../../../components/tab';
import {LineSeparator} from '@unthinkable/react-layout';
import {useTheme} from '@unthinkable/react-theme';
import {
  Col,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Avatar} from '../../../components/avatar/Avatar';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {useAppStateContext} from '../../../providers/AppState';
import {leadControllers} from '../controllers/leadControllers';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useInvoke} from '../../../controllers/useInvoke';
import moment from 'moment';

const RenderInterviewActions = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('schedule-resource-interview', {
              row,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.INFORMATION_HIGH,
              overflow: 'hidden',
            }}>
            Schedule Interview
          </Text>
        </TouchableOpacity>

        <LineSeparator />
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('mark-interview-outcome', {
              row,
              forDrop: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.WARNING_HIGH,
              overflow: 'hidden',
            }}>
            Drop
          </Text>
        </TouchableOpacity>
        {row?.opportunity_id?.process == 'Direct' ? (
          <>
            <LineSeparator />
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('mark-interview-outcome', {
                  row,
                  forLost: true,
                });
              }}>
              <Text
                numberOfLines={1}
                style={{
                  ...rowText,
                  color: colors.ERROR_HIGH,
                  overflow: 'hidden',
                }}>
                Rejected
              </Text>
            </TouchableOpacity>
          </>
        ) : null}

        {row?.opportunity_id?.process == 'Direct' ? (
          <>
            <LineSeparator />
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('mark-interview-outcome', {
                  row,
                  forWon: true,
                });
              }}>
              <Text
                numberOfLines={1}
                style={{
                  ...rowText,
                  color: colors.SUCCESS_HIGH,
                  overflow: 'hidden',
                }}>
                Won
              </Text>
            </TouchableOpacity>
          </>
        ) : null}
      </Row>
    </>
  );
};

const RenderMarkOutcomrActions = props => {
  const {
    row,
    navigation,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  return (
    <>
      <Row gap={8}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('mark-interview-outcome', {
              row,
              forWon: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.SUCCESS_HIGH,
              overflow: 'hidden',
            }}>
            Won
          </Text>
        </TouchableOpacity>

        <LineSeparator />
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('mark-interview-outcome', {
              row,
              forDrop: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.WARNING_HIGH,
              overflow: 'hidden',
            }}>
            Drop
          </Text>
        </TouchableOpacity>
        <LineSeparator />
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('mark-interview-outcome', {
              row,
              forLost: true,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.ERROR_HIGH,
              overflow: 'hidden',
            }}>
            Rejected
          </Text>
        </TouchableOpacity>
      </Row>
    </>
  );
};

const RenderEmailWithExp = props => {
  const {row} = props;
  const {resource} = row;
  const {HIGH, LOW} = useAccentColor(resource?.color);

  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={resource?.name} color={HIGH} brorderColor={LOW} />
      <PrimaryColumnCard
        primaryTitle={resource?.name}
        items={[
          {
            value: resource?.employee_code,
          },
          {
            value: resource?.experience,
          },
        ]}
      />
    </Row>
  );
};
const RenderSkills = props => {
  const {row} = props;
  const skillName = row?.opportunity_id?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

const RenderReason = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <>
      <Chip value={row?.outcome_description} />
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_MEDIUM,
          paddingLeft: 10,
        }}>
        {moment(row?.outcome_date).format('DD MMM')}
      </Text>
    </>
  );
};

const WonRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, BODY2} = useTheme('fonts');
  if (row?.outcome == 'Selected') {
    return (
      <>
        <Col>
          <Text
            style={{
              ...BODY2,
              color: colors.SUCCESS_HIGH,
              paddingLeft: 10,
            }}>
            Selected
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_MEDIUM,
              paddingLeft: 10,
            }}>
            {moment(row?.outcome_date).format('DD MMM')}
          </Text>
        </Col>
      </>
    );
  }
};

const RenderModeWithInterviewDate = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const {CAPTION_LARGE} = useTheme('fonts');

  const colors = useTheme('colors');

  if (row?.mode == 'Online' || row?.mode == 'Test') {
    return (
      <>
        <Chip value={row?.mode} color={'Accent1'} displayTextColor={true} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
            paddingLeft: 10,
          }}>
          {moment(row?.interview_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.mode == 'Face to Face' || row?.mode == 'Phone') {
    return (
      <>
        <Chip value={row?.mode} color={'Accent2'} displayTextColor={true} />
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
            paddingLeft: 10,
          }}>
          {moment(row?.interview_date).format('DD MMM')}
        </Text>
        ;
      </>
    );
  } else {
    return (
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
          paddingLeft: 10,
        }}>
        {moment(row?.interview_date).format('DD MMM')}
      </Text>
    );
  }
};

const RESUME = resourceUrl => ({
  header: 'Resume',
  align: 'center',
  width: 150,
  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    return (
      <TouchableOpacity
        onPress={() => {
          const url = resourceUrl(row?.profile);
          Linking.openURL(url, '_blank');
        }}>
        <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
      </TouchableOpacity>
    );
  },
  width: 100,
});

const RESOURCE_NAME = {
  field: 'resource',
  header: 'Resource Name',
  type: 'userAvatarChip',
};

export const StaffAugmentationResourceTable = props => {
  const params = props.route.params;
  const {row, filter} = params;
  return (
    <Table
      renderHeader={() => <TableHeader title="Staff Augmentation Resource" />}
      api={`/staffaugmentationopportunities`}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1},
        mentor: {name: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}},
      }}
      filter={{
        ...filter,
      }}
      columns={[
        RESOURCE_NAME,
        RESUME,
        {
          header: 'Skill',
          render: RenderSkills,
          width: 130,
        },
        {
          field: 'resource.experience',
          header: 'Experience',
          width: 80,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 110,
        },
        // {
        //   field: 'name',
        //   header: 'Requirement',
        // },
        {
          field: 'mentor.name',
          header: 'Trainer',
        },
        {
          field: 'outcome_description',
          header: 'Trainer Comment',
        },
        {
          field: 'mode',
          header: 'Mode',
        },
      ]}
    />
  );
};

export const ActiveCandidateTableWithoutInterview = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <Table
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}, process: 1},
        interview_date: 1,
        process: 1,
      }}
      filter={{
        ...filter,
        interview_date: {$exists: false},
      }}
      columns={[
        // RESOURCE_NAME,
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          // width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        // {
        //   field: 'outcome_description',
        //   header: 'Trainer Comment',
        //   // width: 200,
        // },

        {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderInterviewActions {...props} navigation={navigation} />
            );
          },
          // width: 300,
        },
      ]}
    />
  );
};

export const ActiveCandidateTableWithoutInterviewDeliveryOnwer = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl, user} = useAppStateContext();
  return (
    <Table
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}, process: 1},
        interview_date: 1,
        process: 1,
      }}
      filter={{
        ...filter,
        interview_date: {$exists: false},
      }}
      columns={[
        // RESOURCE_NAME,
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          // width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        // {
        //   field: 'outcome_description',
        //   header: 'Trainer Comment',
        //   // width: 200,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Update Mentor',
          visible: () => {
            if (
              user?.email === 'amit.singh@daffodildb.com' ||
              user?.email === 'sushil.nagvan@daffodilsw.com'
            ) {
              return true;
            } else {
              return false;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('update-resource-mentor', {row: row});
          },
        },
      ]}
    />
  );
};

export const LostCandidateTable = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <Table
      // renderHeader={() => <TableHeader title="Staff Augmentation Resource" />}
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}},
      }}
      filter={{
        ...filter,
      }}
      columns={[
        // RESOURCE_NAME,
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          header: 'Reason',
          render: RenderReason,
        },
      ]}
    />
  );
};

export const DropCandidateTable = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <Table
      // renderHeader={() => <TableHeader title="Staff Augmentation Resource" />}
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}},
      }}
      filter={{
        ...filter,
      }}
      columns={[
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          header: 'Reason',
          render: RenderReason,
        },
      ]}
    />
  );
};

export const WonCandidateTable = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <Table
      // renderHeader={() => <TableHeader title="Staff Augmentation Resource" />}
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        outcome: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}},
      }}
      filter={{
        ...filter,
      }}
      columns={[
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          header: 'Status',
          render: WonRender,
        },
      ]}
    />
  );
};

export const ActiveCandidateTableWithInterview = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl, user} = useAppStateContext();
  const {
    scheduleResourceInterview,
    outcomeDateCorrection,
    markInterviewResourceOutcome,
  } = leadControllers(props);
  const invoke = useInvoke({
    eventSourceId: 'StaffAugmentation',
    method: 'put',
  });

  return (
    <Table
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}},
        interview_date: 1,
      }}
      filter={{
        ...filter,
        interview_date: {$exists: true},
        interview_date: {
          $gte: new Date(),
        },
      }}
      columns={[
        // RESOURCE_NAME,
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          // width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        // {
        //   field: 'outcome_description',
        //   header: 'Trainer Comment',
        //   // width: 200,
        // },
        {
          header: 'Interview Date',
          // field: 'interivew_date',
          render: RenderModeWithInterviewDate,
          type: 'date',
          width: 180,
        },

        // {
        //   header: 'Actions',
        //   align: 'right',
        //   render: props => {
        //     return (
        //       <RenderMarkOutcomrActions {...props} navigation={navigation} />
        //     );
        //   },
        //   width: 250,
        // },
      ]}
      moreActions={[
        {
          title: 'Correct Interview Schedule',
          onPress: scheduleResourceInterview,
        },
        {
          title: 'Shift to Another Project',
          onPress: ({row}) => {
            navigation.navigate(`mark-interview-outcome`, {
              ...params,
              row,
              forShiftToAnotherProject: true,
            });
          },
        },
        {
          title: 'Denied by Resource',
          onPress: ({row}) => {
            navigation.navigate(`mark-interview-outcome`, {
              ...params,
              row,
              forDeniedByResource: true,
            });
          },
        },
        {
          title: 'Update Mentor',
          visible: () => {
            if (
              user?.email === 'amit.singh@daffodildb.com' ||
              user?.email === 'sushil.nagvan@daffodilsw.com'
            ) {
              return true;
            } else {
              return false;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('update-resource-mentor', {row: row});
          },
        },
        // {title: 'Correct Outcome Date', onPress: outcomeDateCorrection},
      ]}
    />
  );
};

export const ActiveCandidateTableResultDue = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {row, filter} = params;
  const {resourceUrl, user} = useAppStateContext();
  const {
    scheduleResourceInterview,
    outcomeDateCorrection,
    markInterviewResourceOutcome,
  } = leadControllers(props);
  const invoke = useInvoke({
    eventSourceId: 'StaffAugmentation',
    method: 'put',
  });
  return (
    <Table
      api={`/staffaugmentationopportunities`}
      eventSourceId={'StaffAugmentation'}
      fields={{
        mode: 1,
        outcome_description: 1,
        outcome_date: 1,
        profile: 1,
        rfr_assigned_date: 1,
        resource: {_id: 1, name: 1, experience: 1, color: 1, employee_code: 1},
        mentor: {name: 1, color: 1},
        skill: {name: 1},
        opportunity_id: {_id: 1, skill: {name: 1}},
        interview_date: 1,
      }}
      filter={{
        ...filter,
        interview_date: {$exists: true},
        interview_date: {
          $lt: new Date(),
        },
      }}
      columns={[
        // RESOURCE_NAME,
        {
          header: 'Resource',
          render: RenderEmailWithExp,
          // width: 250,
        },
        {
          type: 'date',
          field: 'rfr_assigned_date',
          header: 'Assigned Date',
          width: 150,
        },
        RESUME(resourceUrl),

        {
          field: 'mentor',
          header: 'Mentor',
          type: 'userAvatarChip',
          width: 250,
        },
        // {
        //   field: 'outcome_description',
        //   header: 'Trainer Comment',
        //   // width: 200,
        // },
        {
          header: 'Interview Date',
          // field: 'interivew_date',
          render: RenderModeWithInterviewDate,
          type: 'date',
          width: 180,
        },

        {
          header: 'Actions',
          align: 'right',
          render: props => {
            return (
              <RenderMarkOutcomrActions {...props} navigation={navigation} />
            );
          },
          width: 250,
        },
      ]}
      moreActions={[
        // {
        //   title: 'Correct Interview Schedule',
        //   onPress: scheduleResourceInterview,
        // },
        {
          title: 'Shift to Another Project',
          onPress: ({row}) => {
            navigation.navigate(`mark-interview-outcome`, {
              ...params,
              row,
              forShiftToAnotherProject: true,
            });
          },
        },
        {
          title: 'Denied by Resource',
          onPress: ({row}) => {
            navigation.navigate(`mark-interview-outcome`, {
              ...params,
              row,
              forDeniedByResource: true,
            });
          },
        },
        {
          title: 'Update Mentor',
          visible: () => {
            if (
              user?.email === 'amit.singh@daffodildb.com' ||
              user?.email === 'sushil.nagvan@daffodilsw.com'
            ) {
              return true;
            } else {
              return false;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('update-resource-mentor', {row: row});
          },
        },
        // {title: 'Correct Outcome Date', onPress: outcomeDateCorrection},
      ]}
    />
  );
};

export const ActiveCandidateTabView = props => {
  const params = props.route.params;
  const {row, filter} = params;
  let tabs = {};

  tabs.active = {
    label: 'Resume Stage',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {...filter, interview_date: {$exists: false}},
    eventSourceId: 'StaffAugmentation',
    view: (
      <ActiveCandidateTableWithoutInterview
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };

  tabs.interview = {
    label: 'Interview Stage',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {
      ...filter,
      interview_date: {$exists: true},
      interview_date: {
        $gte: new Date(),
      },
    },
    eventSourceId: 'StaffAugmentation',
    view: (
      <ActiveCandidateTableWithInterview
        sort={{interview_date: -1}}
        {...props}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };

  tabs.resultDue = {
    label: 'Result Awaited',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {
      ...filter,
      interview_date: {$exists: true},
      interview_date: {
        $lt: new Date(),
      },
    },
    eventSourceId: 'StaffAugmentation',
    view: (
      <ActiveCandidateTableResultDue
        sort={{interview_date: -1}}
        {...props}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };
  return <TabView tabs={tabs}></TabView>;
};

export const ActiveCandidateTabViewDeliveryOwner = props => {
  const params = props.route.params;
  const {row, filter} = params;
  let tabs = {};

  tabs.active = {
    label: 'Resume Stage',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {...filter, interview_date: {$exists: false}},
    eventSourceId: 'StaffAugmentation',
    view: (
      <ActiveCandidateTableWithoutInterviewDeliveryOnwer
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };

  tabs.interview = {
    label: 'Interview Stage',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {
      ...filter,
      interview_date: {$exists: true},
      interview_date: {
        $gte: new Date(),
      },
    },
    eventSourceId: 'StaffAugmentation',
    view: (
      <ActiveCandidateTableWithInterview
        sort={{interview_date: -1}}
        {...props}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };

  tabs.resultDue = {
    label: 'Result Awaited',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {
      ...filter,
      interview_date: {$exists: true},
      interview_date: {
        $lt: new Date(),
      },
    },
    eventSourceId: 'StaffAugmentation',
    view: (
      <ActiveCandidateTableResultDue
        sort={{interview_date: -1}}
        {...props}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };
  return <TabView tabs={tabs}></TabView>;
};

export const LostCandidateTabView = props => {
  const params = props.route.params;
  const {row, filter} = params;
  let tabs = {};

  tabs.active = {
    label: 'Lost Candidates',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {...filter},
    eventSourceId: 'StaffAugmentation',
    view: (
      <LostCandidateTable
        {...props}
        sort={{outcome_date: -1}}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };
  return <TabView tabs={tabs}></TabView>;
};

export const DropCandidateTabView = props => {
  const params = props.route.params;
  const {row, filter} = params;
  let tabs = {};

  tabs.active = {
    label: 'Drop Candidates',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {...filter},
    eventSourceId: 'StaffAugmentation',
    view: (
      <DropCandidateTable
        {...props}
        sort={{outcome_date: -1}}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };
  return <TabView tabs={tabs}></TabView>;
};

export const WonCandidateTabView = props => {
  const params = props.route.params;
  const {row, filter} = params;
  let tabs = {};

  tabs.active = {
    label: 'Won Candidates',
    api: '/staffAugmentationOpportunities',
    params: params,
    addOnFilter: {...filter},
    eventSourceId: 'StaffAugmentation',
    view: (
      <WonCandidateTable
        {...props}
        sort={{outcome_date: -1}}
        addOnFilter={{...filter}}
        params={params}
      />
    ),
  };
  return <TabView tabs={tabs}></TabView>;
};
