import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {ActiveITAssetTab} from './ActiveITAsset';
import {InActiveITAssetTab} from './InActiveITAsset';
import {
  AllITAssetTab,
  DeletedITAssetTab,
  NewITAssetTable,
} from './NewITAssetTable';

export const ITAssetMenu = props => {
  const menus = [
    {
      label: 'New',
      view: <NewITAssetTable {...props} />,
    },
    {
      label: 'Active',
      view: <ActiveITAssetTab {...props} />,
    },
    {
      label: 'Inactive',
      view: <InActiveITAssetTab {...props} />,
    },
    {
      label: 'Archived',
      view: <DeletedITAssetTab {...props} />,
    },
    {
      label: 'All',
      view: <AllITAssetTab {...props} />,
    },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
