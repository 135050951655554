//Faiz Saeed 23/12/23
import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {OutreachInmailCampaignTabs} from './OutreachInmailCampaignTabs';
import {OutreachCampaignActivityTabs} from './OutreachCampaignActivityTabs';
import {CampaignActionsAnalyticsByTypeTable} from './CampaignActionsAnalyticsByTypeTable';
import {CampaignActionsAnalytics} from './CampaignActionsAnalytics';

const menus = [
  {
    label: 'Activity',
    view: <OutreachCampaignActivityTabs />,
  },
  {
    label: 'Performance',
    view: <OutreachInmailCampaignTabs />,
  },
  {
    label: 'Analytics',
    view: <CampaignActionsAnalyticsByTypeTable />,
  },
  {
    label: 'Actions',
    view: <CampaignActionsAnalytics />,
  },
];

export const OutreachCampaignNavigator = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
