import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const CreateMockInterviews = props => {
  const params = props?.route?.params;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'MockInterviews',
    uri: '/mockinterviews',
    ...props,
  });
  let defaultValues = {};
  if (row && row?._id) {
    defaultValues = {
      _id: row?._id,
      candidate: row?.candidate,
      interviewer: row?.interviewer,
      mock_interview_date: row?.mock_interview_date,
    };
  } else {
    defaultValues = {
      mock_interview_date: new Date(),
    };
  }

  return (
    <Form
      {...props}
      api={`/mockinterviews/${row?._id}`}
      fields={{
        candidate: {
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        interviewer: {
          name: 1,
          color: 1,
          official_email_id: 1,
        },
        mock_interview_date: 1,
        review_completed: 1,
        introductin_and_domanin_project: 1,
        problem_solving_skills: 1,
        coding_ability: 1,
        api_implementation: 1,
        db_knowledge: 1,
        error_handling: 1,
        architectural_understanding: 1,
        frontend_navigation: 1,
        frontend_state_management: 1,
        frontend_page_speed: 1,
        caching: 1,
        search_architecture: 1,
        clarity_of_expression: 1,
        confidence_in_speech: 1,
        comprehension: 1,
        engagement_with_interviewer: 1,
        questioning_skills: 1,
        overall_impression: 1,
        strenghts: 1,
        area_of_improvements: 1,
        actionable_recommendations: 1,
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      header={'Add Mock Interview'}
      submitAction={'Add'}
      layoutFields={[
        {
          placeholder: 'Candidate',
          label: 'Candidate',
          field: 'candidate',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          placeholder: 'Interviewer',
          label: 'Interviewer',
          field: 'interviewer',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {label: 'Interview Date', field: 'mock_interview_date', type: 'date'},
      ]}
    />
  );
};

export const EditMockInterviews = props => {
  return (
    <CreateMockInterviews
      header={'Edit Mock Interviews'}
      mode={'edit'}
      isDualMode
      {...props}
    />
  );
};

export const AddMockInterviewFeedback = props => {
  const params = props?.route?.params;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'MockInterviews',
    uri: '/mockinterviews',
    ...props,
  });
  console.log('row>>>>>', row);

  let defaultValues = {};
  if (row && row?._id) {
    defaultValues = {
      _id: row?._id,
      candidate: row?.candidate,
      interviewer: row?.interviewer,
      mock_interview_date: row?.mock_interview_date,
      review_completed: true,
    };
  }

  return (
    <Form
      {...props}
      api={`/mockinterviews/${row?._id}`}
      fields={{
        candidate: {
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        interviewer: {
          name: 1,
          color: 1,
          official_email_id: 1,
        },
        mock_interview_date: 1,
        review_completed: 1,
        introductin_and_domanin_project: 1,
        problem_solving_skills: 1,
        coding_ability: 1,
        api_implementation: 1,
        db_knowledge: 1,
        error_handling: 1,
        architectural_understanding: 1,
        frontend_navigation: 1,
        frontend_state_management: 1,
        frontend_page_speed: 1,
        caching: 1,
        search_architecture: 1,
        clarity_of_expression: 1,
        confidence_in_speech: 1,
        comprehension: 1,
        engagement_with_interviewer: 1,
        questioning_skills: 1,
        overall_impression: 1,
        strenghts: 1,
        area_of_improvements: 1,
        actionable_recommendations: 1,
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      header={'Feedback'}
      submitAction={'Add'}
      layoutFields={[
        {
          label: 'Technical Feedback',
          fields: [
            {
              label: 'Introduction and Understanding of Executed Projects',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  required: true,
                  field: 'introductin_and_domanin_project',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Problem-Solving Skills',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'problem_solving_skills',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Coding Ability ',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'coding_ability',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'API Implementation and Design ',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'api_implementation',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Database Knowledge',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'db_knowledge',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Error Handling and Logging',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'error_handling',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Architectural Understanding',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'architectural_understanding',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Frontend Skills - Navigation Design',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'frontend_navigation',
                  size: 4,
                },
              ],
              size: 4,
            },

            {
              label: 'Frontend Skills - State Management',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'frontend_state_management',
                  size: 4,
                },
              ],
              size: 4,
            },

            {
              label: 'Frontend Skills - Page Speed',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'frontend_page_speed',
                  size: 4,
                },
              ],
              size: 4,
            },

            {
              label: 'Caching Understanding',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'caching',
                  size: 4,
                },
              ],
              size: 4,
            },

            {
              label: 'Search Architecture',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'search_architecture',
                  size: 4,
                },
              ],
              size: 4,
            },
          ],
        },

        {
          label: 'Behavioural Skills Assessment',
          fields: [
            {
              label: 'Clarity of Expression',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'clarity_of_expression',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Confidence in Speech',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'confidence_in_speech',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Comprehension and Relevance',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'comprehension',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Engagement with Interviewer',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'engagement_with_interviewer',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              label: 'Questioning Skills',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'questioning_skills',
                  size: 4,
                },
              ],
              size: 4,
            },
          ],
        },

        {
          label: 'Overall Performance',
          fields: [
            {
              label: 'Overall Impression',
              fields: [
                {
                  type: 'rating',
                  required: true,
                  field: 'overall_impression',
                  size: 4,
                },
              ],
              size: 4,
            },
            {
              field: 'strenghts',
              type: 'textArea',
              required: true,
              label: 'Strenghts',
            },
            {
              field: 'area_of_improvements',
              type: 'textArea',
              required: true,
              label: 'Area of Improvements',
            },
            {
              field: 'actionable_recommendations',
              required: true,
              type: 'textArea',
              label: 'Actionable Recommendations',
            },
          ],
        },
      ]}
    />
  );
};

export const ViewMockInterviewFeedback = props => {
  return (
    <AddMockInterviewFeedback
      header={'Edit Mock Interviews'}
      mode={'edit'}
      readOnly={true}
      isDualMode
      {...props}
    />
  );
};
