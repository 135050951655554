import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const CLOUD_FIELDS = {
  account_id: {
    label: 'Account Id',
    field: 'account_id',
    type: 'text',
    required: true,
  },
  region: {
    label: 'Region',
    field: 'region',
    type: 'text',
    required: true,
  },
  access_key: {
    label: 'Access Key',
    field: 'access_key',
    type: 'password',
    required: true,
  },
  secret_key: {
    label: 'Secret Access Key',
    field: 'secret_key',
    type: 'password',
    required: true,
  },
  user_name: {
    label: 'Username',
    field: 'user_name',
    type: 'text',
    required: true,
  },
  ip_address: {
    label: 'IP address',
    field: 'ip_address',
    type: 'text',
    required: true,
  },
  private_key: {
    label: 'Private Key',
    field: 'private_key',
    type: 'text',
    required: true,
  },
  cluster_name: {
    label: 'Cluster Name',
    field: 'cluster_name',
    type: 'text',
    required: true,
  },
  namespace: {
    label: 'Namespace',
    field: 'namespace',
    type: 'text',
    required: true,
  },
  password: {
    label: 'Password',
    field: 'password',
    type: 'text',
    required: true,
  },
  config_file_url: {
    label: 'Config File',
    field: 'config_file_url',
    type: 'file',
    required: true,
  },
};

const EC2 = [
  'account_id',
  'region',
  'access_key',
  'secret_key',
  'user_name',
  'ip_address',
  'private_key',
];
const EKS = [
  'account_id',
  'region',
  'access_key',
  'secret_key',
  'cluster_name',
  'namespace',
];
const VM = ['account_id', 'region', 'user_name', 'ip_address', 'password'];
const LKE = ['region', 'config_file_url', 'cluster_name', 'namespace'];

const E2E_VM = ['account_id', 'region', 'ip_address', 'user_name', 'password'];
const E2E_kuber = ['region', 'namespace', 'cluster_name', 'config_file_url'];

const CloudInfraForm = props => {
  const {
    route: {params},
    mode,
  } = props;
  const {project} = params || {};

  const defaultValues = {
    project_id: project?._id,
  };

  let {onSubmit} = useFormSubmit({
    uri: '/CloudInfrastructures',
    eventSourceId: 'CloudInfra',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      successMessage={({data}) => {
        if (data.setup_type === 'Default') {
          return 'Setup is currently in progress and will take some time.';
        }
        return 'Data Saved.';
      }}
      layoutFields={[
        {
          field: 'setup_type',
          type: 'radioGroup',
          label: 'Setup Configuration',
          options: ['Default', 'Custom'],
          required: true,
        },
        {
          visible: ({values}) => values.setup_type === 'Custom',
          variant: 'none',
          fields: [
            {
              label: 'Cloud',
              type: 'autoComplete',
              field: 'cloud',
              options: ['AWS', 'Linode', 'E2E', 'GCP', 'On-Prem', 'Azure'],
              required: true,
            },
            {
              visible: ({values}) => values.cloud === 'AWS',
              variant: 'none',
              fields: [
                {
                  label: 'AWS Service',
                  field: 'cloud_service',
                  type: 'radioGroup',
                  options: ['EC2', 'EKS'],
                  required: true,
                },
                // {
                //   label: 'EKS Type',
                //   field: 'eks_type',
                //   type: 'radioGroup',
                //   options: ['Daffodil', 'Client'],
                //   required: true,
                //   visible: ({values}) => values.cloud_service === 'EKS',
                // },
                {
                  visible: ({values}) => values.cloud_service === 'EC2',
                  variant: 'none',
                  fields: EC2.reduce((acc, doc) => {
                    acc.push(CLOUD_FIELDS[doc]);
                    return acc;
                  }, []),
                },
                {
                  visible: ({values}) => values.cloud_service === 'EKS',
                  variant: 'none',
                  fields: EKS.reduce((acc, doc) => {
                    acc.push(CLOUD_FIELDS[doc]);
                    return acc;
                  }, []),
                },
              ],
            },
            {
              visible: ({values}) => values.cloud === 'Linode',
              variant: 'none',
              fields: [
                {
                  label: 'Linode Service',
                  field: 'cloud_service',
                  type: 'radioGroup',
                  options: ['VM', 'LKE'],
                  required: true,
                },
                {
                  visible: ({values}) => values.cloud_service === 'LKE',
                  variant: 'none',
                  fields: LKE.reduce((acc, doc) => {
                    acc.push(CLOUD_FIELDS[doc]);
                    return acc;
                  }, []),
                },
                {
                  visible: ({values}) => values.cloud_service === 'VM',
                  variant: 'none',
                  fields: VM.reduce((acc, doc) => {
                    acc.push(CLOUD_FIELDS[doc]);
                    return acc;
                  }, []),
                },
              ],
            },
            {
              visible: ({values}) => values.cloud === 'E2E',
              variant: 'none',
              fields: [
                {
                  label: 'E2E Service',
                  field: 'cloud_service',
                  type: 'radioGroup',
                  options: ['VM', 'Kubernetes'],
                  required: true,
                },
                {
                  visible: ({values}) => values.cloud_service === 'VM',
                  fields: E2E_VM.reduce((acc, doc) => {
                    acc.push(CLOUD_FIELDS[doc]);
                    return acc;
                  }, []),
                },
                {
                  visible: ({values}) => values.cloud_service === 'Kubernetes',
                  fields: E2E_kuber.reduce((acc, doc) => {
                    acc.push(CLOUD_FIELDS[doc]);
                    return acc;
                  }, []),
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const CloudInfraAddForm = props => {
  return <CloudInfraForm header="Add Cloud Information" {...props} />;
};

export const CloudInfraDetail = props => {
  const {
    route: {params},
  } = props;

  const {cloud} = params;

  return (
    <CloudInfraForm
      mode="edit"
      header="Update Cloud Information"
      api={`/CloudInfrastructures/${cloud?._id}`}
      {...props}
    />
  );
};
