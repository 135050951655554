import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {ActiveProposalSubmittedTable} from './ActiveProposalSubmittedTable';
import {LostProposalSubmittedTable} from './LostProposalSubmittedTable';

const tabs = {
  active: {
    label: 'Active',
    view: <ActiveProposalSubmittedTable />,
  },
  lost: {
    label: 'Lost',
    view: <LostProposalSubmittedTable />,
  },
};
export const ProposalSubmittedTableTabView = props => {
  const {route: {params} = {}} = props;
  return <TabView tabs={tabs} params={params} {...props} />;
};
