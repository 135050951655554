import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {Button} from '../../../components/button/Button';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import EmployeeListWithDetailAllocation from './EmployeeListWithLatestAllocation';

export const ResourceAllocationTab = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {download} = useAppStateContext();
  const tabs = {
    resourceAllocation: {
      label: 'Resource',
      view: (
        <EmployeeListWithDetailAllocation
          filterValues={filterValues}
          params={params}
        />
      ),
      api: '/resourceallocation',
      addOnFilter: {...filter},
      params: {...params, ...filterValues.params},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Orders',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project_id',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'project_id.customer',
            },
            {
              label: 'From date',
              type: 'date',
              field: 'period',
              asParam: true,
            },
            {
              label: 'Type',
              field: 'project_type',
              placeholder: 'Type',
              type: 'autoComplete',
              options: [
                {value: 'f', label: 'Fixed'},
                {value: 'i', label: 'Internal'},
                {value: 'tm', label: 'T & M'},
                {value: 'tr', label: 'Training'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Project SubCategory',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skillmetrics',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill_metrics',
            },
            {
              type: 'autoComplete',
              label: 'Tools',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'tool',
            },
            {
              type: 'autoComplete',
              label: 'Level 1 Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'level_one_owner',
            },
            {
              type: 'autoComplete',
              label: 'Level 2 Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'level_two_owner',
            },
            {
              type: 'autoComplete',
              label: 'Level 3 Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'level_three_owner',
            },
          ]}
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/resouceallocation/export`,
              props: {
                ...params,
                ...filterValues.params,
                addOnFilter: {...filter},
              },
            });
          }}
        />,
      ]}
    />
  );
};

export const ResourceAllocationTabDO = props => {
  let {route: {params} = {}} = props;
  const {user} = useAppStateContext();
  const {employee} = user;

  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {
      $or: [{level_one_owner: employee?._id}, {level_two_owner: employee?._id}],
    },
  });

  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  const {download} = useAppStateContext();
  const tabs = {
    resourceAllocation: {
      label: 'Resource',
      view: (
        <EmployeeListWithDetailAllocation
          filterValues={filterValues}
          params={params}
        />
      ),
      api: '/resourceallocation',
      addOnFilter: {...filter},
      params: {...params, ...filterValues.params},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Orders',
              api: '/orders',
              placeholder: 'Select',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project_id',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'project_id.customer',
            },
            {
              label: 'From date',
              type: 'date',
              field: 'period',
              asParam: true,
            },
            {
              label: 'Type',
              field: 'project_type',
              placeholder: 'Type',
              type: 'autoComplete',
              options: [
                {value: 'f', label: 'Fixed'},
                {value: 'i', label: 'Internal'},
                {value: 'tm', label: 'T & M'},
                {value: 'tr', label: 'Training'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Project SubCategory',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skillmetrics',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill_metrics',
            },
            {
              type: 'autoComplete',
              label: 'Tools',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'tool',
            },
            {
              type: 'autoComplete',
              label: 'Level 1 Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'level_one_owner',
            },
            {
              type: 'autoComplete',
              label: 'Level 2 Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'level_two_owner',
            },
            {
              type: 'autoComplete',
              label: 'Level 3 Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'level_three_owner',
            },
          ]}
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/resouceallocation/export`,
              props: {
                ...params,
                ...filterValues.params,
                addOnFilter: {...filter},
              },
            });
          }}
        />,
      ]}
    />
  );
};
