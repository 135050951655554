import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const UpdateInsideSales = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        onSubmit(props);
      }}
      header={'Update Inside Sales'}
      submitAction={'Update'}
      // defaultValues={defaultValues}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Inside Sales',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          field: 'inside_sales',
        },
      ]}
    />
  );
};
