import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {TableHeader} from '../../../components/table/Headers';
import {ExitManagementForm} from './ExitManagementForm';
import {EmployeeNoDuesList} from '../../employee/views/NoduesLists';

export const ExitManagementTab = props => {
  let {route: {params} = {}} = props;
  let {organization, product, period, ...rest} = params;
  const tabs = {
    ExitRequest: {
      label: 'Exit Request',
      view: <ExitManagementForm {...props} />,
      eventSourceId: 'ExitRequest',
    },
    // noDues: {
    //   label: 'No Dues',
    //   view: <EmployeeNoDuesList params={params} {...props} />,
    //   eventSourceId: 'myAttendance',
    // },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      headerComponent={<TableHeader title="Exit Management" />}
    />
  );
};

export const SelfNoduesTab = props => {
  let {route: {params} = {}} = props;
  let {organization, product, period, ...rest} = params;
  const tabs = {
    // ExitRequest: {
    //   label: 'Exit Request',
    //   view: <ExitManagementForm {...props} />,
    //   eventSourceId: 'ExitRequest',
    // },
    noDues: {
      label: 'No Dues',
      view: <EmployeeNoDuesList params={params} {...props} />,
      eventSourceId: 'myAttendance',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      headerComponent={<TableHeader title="No dues" />}
    />
  );
};
