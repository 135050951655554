import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const OpportunityDropRfq = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });
  const {opportunity} = params;
  return (
    <Form
      defaultValues={{
        ...opportunity,
        status: 'invalidRfq',
        outcome_date: getZeroTimeDate(new Date()),
      }}
      onSubmit={onSubmit}
      header={'Drop RFQ'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Lost Reason',
          field: 'outcome_description_comment',
          type: 'autoComplete',
          required: true,
          options: [
            'Budget Issue',
            'Unavailability of technical expertise',
            'Client is not responsive',
            'Project is on hold or deferred',
            'Drop due to non-aggreement in T&C in MSA & SOW',
            'No clarity in requirement',
            'Business model changed',
            'Drop due to timeline',
          ],
        },
        {
          placeholder: 'Lost Description',
          field: 'outcome_description',
          required: true,
          type: 'text',
        },
      ]}
    />
  );
};
