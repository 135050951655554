import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const SocialMediaPostsForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {socialMediaPost, product} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/socialMediaPosts',
    eventSourceId: 'SocialMediaPost',
  });

  return (
    <Form
      params={params}
      {...props}
      onSubmit={onSubmit}
      submitAction="save"
      api={`/socialMediaPosts/${socialMediaPost?._id}`}
      defaultValues={{
        product,
      }}
      fields={{
        name: 1,
        value_proposition: 1,
        content: 1,
        design_file: 1,
        design_url: 1,
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'name',
          size: 6,
        },
        {
          type: 'text',
          field: 'value_proposition',
          label: 'Value Proposition',
          size: 6,
        },
        {
          type: 'textArea',
          field: 'content',
          label: 'Content',
        },
        {
          type: 'text',
          field: 'design_url',
          label: 'Design Url',
        },
        {
          type: 'file',
          field: 'design_file',
          label: 'Design File',
        },
      ]}
    />
  );
};

export const AddSocialMediaPosts = props => {
  return <SocialMediaPostsForm header="Add Posts" {...props} />;
};

export const EditSocialMediaPosts = props => {
  return <SocialMediaPostsForm header="Edit Posts" mode="edit" {...props} />;
};

const KeywordForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {keyword, product} = params;
  console.log(props);
  const {onSubmit} = useFormSubmit({
    uri: '/keywords',
    eventSourceId: 'Keyword',
  });

  return (
    <Form
      params={params}
      {...props}
      onSubmit={onSubmit}
      submitAction="save"
      api={`/keywords/${keyword?._id}`}
      computations={{
        date_added: [
          value => {
            return new Date();
          },
          ['_id'],
        ],
      }}
      defaultValues={{
        product,
      }}
      fields={{
        webpage_id: {
          name: 1,
        },
        keyword: 1,
        search_volume: 1,
        keyword_ranking: 1,
        date_added: 1,
        last_checked: 1,
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'webpage_id',
          label: 'WebPage',
          api: '/socialMediaWebPages',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
        },
        {
          type: 'text',
          field: 'keyword',
          label: 'Keyword',
          size: 6,
        },
        {
          type: 'number',
          field: 'search_volume',
          label: 'Search Volume',
          size: 6,
        },
        {
          type: 'text',
          field: 'keyword_ranking',
          label: 'Keyword Ranking',
          size: 6,
        },
        {
          type: 'date',
          field: 'date_added',
          label: 'Date Added',
          size: 6,
        },
        {
          type: 'date',
          field: 'last_checked',
          label: 'Last Added',
          size: 6,
        },
      ]}
    />
  );
};

export const AddKeywordForm = props => {
  return <KeywordForm header="Add Keyword" {...props} />;
};

export const EditKeywordForm = props => {
  return <KeywordForm header="Edit Keyword" mode="edit" {...props} />;
};

const SocialMediaWebpageForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {socialMediaWebpage, product} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/socialMediaWebpages',
    eventSourceId: 'SocialMediaWebpage',
  });

  return (
    <Form
      params={params}
      {...props}
      onSubmit={onSubmit}
      submitAction="save"
      defaultValues={{
        product,
      }}
      api={`/socialMediaWebpages/${socialMediaWebpage?._id}`}
      fields={{
        name: 1,
        value_proposition: 1,
        content: 1,
        design_file: 1,
        design_url: 1,
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'name',
          size: 6,
        },
        {
          type: 'text',
          field: 'value_proposition',
          label: 'Value Proposition',
          size: 6,
        },
        {
          type: 'textArea',
          field: 'content',
          label: 'Content',
        },
        {
          type: 'text',
          field: 'design_url',
          label: 'Design Url',
        },
        {
          type: 'file',
          field: 'design_file',
          label: 'Design File',
        },
      ]}
    />
  );
};

export const AddSocialMediaWebpage = props => {
  return <SocialMediaWebpageForm header="Add Webpage" {...props} />;
};

export const EditSocialMediaWebpage = props => {
  return (
    <SocialMediaWebpageForm header="Edit Webpage" mode="edit" {...props} />
  );
};

const BackLinkForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {backLink, product} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/backLinks',
    eventSourceId: 'BackLink',
  });

  return (
    <Form
      params={params}
      {...props}
      onSubmit={onSubmit}
      submitAction="save"
      defaultValues={{
        product,
      }}
      api={`/backLinks/${backLink?._id}`}
      fields={{
        source_url: 1,
        destination_url: 1,
        anchor_text: 1,
        backlink_type: 1,
        date_discovered: 1,
        last_checked: 1,
        status: 1,
        domain_authority: 1,
        page_authority: 1,
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'source_url',
          label: 'Source Url',
          size: 6,
        },
        {
          type: 'text',
          field: 'destination_url',
          label: 'Destination Url',
          size: 6,
        },
        {
          type: 'textArea',
          field: 'anchor_text',
          label: 'Anchor Text',
        },
        {
          type: 'autoComplete',
          options: ['Do Follow', 'No Follow', 'UGC', 'Sponsored'],
          field: 'backlink_type',
          label: 'BackLink Type',
          size: 6,
        },
        {
          type: 'autoComplete',
          options: ['active', 'broken', 'removed'],
          field: 'status',
          label: 'Status',
          size: 6,
        },
        {
          type: 'date',
          field: 'date_discovered',
          label: 'Date Discovered',
          size: 6,
        },
        {
          type: 'date',
          field: 'last_checked',
          label: 'Last Checked',
          size: 6,
        },
        {
          type: 'text',
          field: 'domain_authority',
          label: 'Domain Authority',
          size: 6,
        },
        {
          type: 'text',
          field: 'page_authority',
          label: 'Page Authority',
          size: 6,
        },
      ]}
    />
  );
};

export const AddBackLinkForm = props => {
  return <BackLinkForm header="Add Webpage" {...props} />;
};

export const EditBackLinkForm = props => {
  return <BackLinkForm header="Edit Webpage" mode="edit" {...props} />;
};
