import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const OpportunityMarkLost = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: ['CustomPlatformOpportunity', 'POC'],
    ...props,
  });
  const {opportunity} = params;
  return (
    <Form
      defaultValues={{
        ...opportunity,
        status: 'Lost',
      }}
      onSubmit={onSubmit}
      header={'Mark Lost'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Lost Reason',
          field: 'outcome_description_comment',
          type: 'autoComplete',
          required: true,
          options: [
            'Lost to Competition',
            'Lost due to gaps in the proposal quality',
            'Lost due to delay in sharing proposal',
            'Proposal walkthrogh issues/quality',
            'Lack of confidence building with client',
          ],
        },
        {
          placeholder: 'Lost Description',
          field: 'outcome_description',
          type: 'text',
          required: true,
        },
        {
          placeholder: 'Date',
          field: 'outcome_date',
          type: 'date',
          required: true,
        },
      ]}
    />
  );
};
