import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import { CommunicationRender } from './LeadTable';

export const LostProposalSubmittedTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {product} = params;

  const {markNurturable, createDuplicateLead,communications} = leadControllers(props);

  return (
    <Table
      api={`/potentialcustomers`}
      filter={{
        product: product?._id,
        lead_status: 'Lost',
        pipeline_type: 'Prospect',
        stage: {$in: ['53bbd36a79df44bac9b12f39']},
      }}
      fields={{
        owner: {name: 1},
        name: 1,
        email: 1,
        website: 1,
        campaign_id: {name: 1},
        lead_source: {name: 1},
        last_communication: 1,
        last_communication_date: 1,
        lost_description_comment: 1,
        lost_date: 1,
      }}
      sort={{lost_date: -1}}
      onRowPress={({row}) => {
        navigation.navigate(`lead-detail`, {
          leadId: row._id,
        });
      }}
      columns={[
        {
          header: 'Owner',
          field: 'owner.name',
          type: 'text',
        },
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Lost Date',
          field: 'lost_date',
          type: 'date',
        },
        {
          header: 'Email',
          field: 'email',
          type: 'text',
        },
        {
          header: 'Website',
          field: 'website',
          type: 'text',
        },
        {
          header: 'Campaign',
          field: 'campaign_id.name',
          type: 'text',
        },
        {
          header: 'Lead Source',
          field: 'lead_source.name',
          type: 'text',
        },
        {
          header:'Last Communication',
          onPress:communications,
          render:CommunicationRender,
          width:170,
        },
        {
          header: 'Lost Reason',
          field: 'lost_description_comment',
          type: 'text',
        },
      ]}
      moreActions={() => [
        // {
        //   title: 'Mark Nurturable',
        //   onPress: markNurturable,
        // },
        {
          title: 'Create New',
          onPress: createDuplicateLead,
        },
      ]}
    />
  );
};
