import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {
  TeamResignedApprovedTable,
  TeamResignedTable,
  TeamResignedwithdrawTable,
} from './TeamExitManagementTables';

export const TeamExitManagementTab = props => {
  let {route: {params} = {}} = props;
  let {organization, product, period, ...rest} = params;
  const tabs = {
    Resigned: {
      label: 'Submitted',
      view: <TeamResignedTable {...props} />,
      eventSourceId: 'TeamResignedEmployee',
    },
    approved: {
      label: 'Approved',
      view: <TeamResignedApprovedTable {...props} />,
      eventSourceId: 'TeamResignedApprovedEmployee',
    },
    withdraw: {
      label: 'Withdraw',
      view: <TeamResignedwithdrawTable {...props} />,
      eventSourceId: 'TeamResignedwithdrawnEmployee',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
