import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { useTheme } from '@unthinkable/react-theme';


export const SalarySheetTable = props => {
    const { filterParams, searchValue, route: { params = {} } } = props;
    const {download} = useAppStateContext();
    return (
        <Table
            {...props}
            api={'/getmonthyear'}
            addOnFilter={{ ...filterParams }}
            params={params}
            eventSourceId="monthyear"
            search={searchValue}
            limit={50}
            columns={[
                {
                    field: 'month_id.name',
                    header: 'Month',
                    type: 'text',
                },
                {
                    field: 'year_id.name',
                    header: 'Year',
                    type: 'text',
                },
            ]}
            moreActions={[
                {
                    title: 'Download Salary Sheet',
                    onPress: ({ row }) => {
                        download({ uri: `/salarysheet/${row._id}` , props : {...row} });
                    },
                },
                {
                    title: 'Variable Salary',
                    onPress: ({ row }) => {
                        download({ uri: `/variablereport/${row._id}` , props : {...row} });
                    },
                }
            ]}
        />
    );
};

export const SalarySheetTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Salary Sheet',
            view: (
                <SalarySheetTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'monthyear',
            addOnFilter: filter,
            api: '/getmonthyear',
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'autoComplete',
                            label: 'Year',
                            api: '/yeartypes',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'year_id',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Month',
                            api: '/monthtypes',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'month_id',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                    ]}
                />,
            ]}
        />
    );
};
