import {useAppStateContext} from '../../../providers/AppState';

export const Industry = ({route: {params}, navigation} = {}) => {
  const {post} = useAppStateContext();

  const markIndustryInactive = ({row}) => {
    post({
      uri: `/industries/${row?._id}`,
      method: 'put',
      evenSourceId: 'Industry',
      props: {
        closed: true,
      },
    });
  };
  const markIndustryActive = ({row}) => {
    post({
      uri: `/industries/${row?._id}`,
      method: 'put',
      evenSourceId: 'Industry',
      props: {
        closed: false,
      },
    });
  };

  return {
    markIndustryInactive,
    markIndustryActive,
  };
};
