import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const Testimonials = props => {
  const {
    route: { params },
  } = props;
  const { row } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/testimonials',
    eventSourceId: 'testimonial',
  });
  return (
    <Form
      api={`/testimonials/${row?._id}`}
      fields={{
        orgIcon: 1,
        activeOrgIcon: 1,
        videoUrl: 1,
        ownerName: 1,
        ownerRole: 1,
        ownerReview: 1,
        companyName: 1,
        performance: {
          percentage: 1,
          title: 1,
        },
        index: 1,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Company Icon',
          type: 'file',
          field: 'orgIcon',
          size: 6,
          required: true,
        },
        {
          label: 'Company Active Icon',
          type: 'file',
          field: 'activeOrgIcon',
          size: 6,
          required: true,
        },
        {
          label: 'Company',
          type: 'text',
          field: 'companyName',
          required: true,
        },
        {
          label: 'Owner',
          type: 'text',
          field: 'ownerName',
          required: true,
          size: 6,
        },
        {
          label: 'Designation',
          type: 'text',
          field: 'ownerRole',
          required: true,
          size: 6,
        },
        {
          label: 'Review',
          type: 'textArea',
          field: 'ownerReview',
          required: true,
        },
        {
          label: 'Video Url',
          type: 'text',
          field: 'videoUrl',
          required: true,
        },
        {
          label: 'Performance',
          field: 'performance',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  header: 'Percentage',
                  field: 'percentage',
                  type: 'number',
                  placeholder: 'Percentage',
                  required: true,
                },
                {
                  label: 'Title',
                  field: 'title',
                  placeholder: 'Title',
                  type: 'textArea',
                  required: true,
                },
              ]}
              columns={[
                {
                  header: 'Percentage',
                  field: 'percentage',
                  placeholder: 'Percentage',
                },
                {
                  header: 'Title',
                  field: 'title',
                  placeholder: 'Title',
                },
              ]}
            />
          ),
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
      ]}
      {...props}
    />
  );
};

export const AddTestimonialsForm = props => {
  return <Testimonials header="Add Testimonials" {...props} />;
};

export const UpdateTestimonialsForm = props => {
  return (
    <Testimonials mode="edit" header="Update Testimonials" {...props} />
  );
};
