export const parseMessage = message => {
  const regex = /@\[(.*?)\]\((.*?)\)/g;
  let match;
  let parts = [];
  let mentions = [];
  let plainText = '';
  let lastIndex = 0;

  while ((match = regex.exec(message)) != null) {
    // Add the text before the mention
    if (match.index !== lastIndex) {
      const text = message.substring(lastIndex, match.index);
      parts.push({partType: 'text', text});
      plainText += text;
    }

    // Add the mention
    const username = match[1];
    const id = match[2];
    parts.push({partType: 'mention', text: username});
    mentions.push({name: username, id});
    plainText += '@' + username;

    lastIndex = regex.lastIndex;
  }

  // Add the remaining text
  if (lastIndex !== message.length) {
    const text = message.substring(lastIndex);
    parts.push({partType: 'text', text});
    plainText += text;
  }

  return {parts, plainText, mentions};
};

export const extractMentions = message => {
  const regex = /@\[(.*?)\]\((.*?)\)/g;
  let match;
  let mentions = [];

  while ((match = regex.exec(message)) != null) {
    mentions.push({name: match[1], id: match[2]});
  }

  return mentions;
};
