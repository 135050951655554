import React from 'react';
import {MultiTextInput as MultiTextInputComponent} from '@unthinkable/react-text-input';
import {AutoCompleteStyles} from '../autocomplete/theme';
import {useStyles} from '@unthinkable/react-theme';

export const MultiTextInput = props => {
  const styles = useStyles(AutoCompleteStyles);

  return <MultiTextInputComponent {...props} styles={styles} />;
};
