import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {getCurrentDay} from '../../employee/views/EmployeeTeamTabs';
import {useTheme} from '@unthinkable/react-theme';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupBy} from '../../../components/table/GroupBy';
import {
  AllActiveProjects,
  AllClosedProjects,
} from './AllProjectsAndClientTable';

const groupFilterArray = [
  {
    label: 'Tool',
    field: 'tool',
    placeholder: 'Select Tool',
    type: 'autoComplete',
    options: ['Locomo', 'Other'],
  },
  {
    type: 'date',
    field: 'current_date',
    asParam: true,
    label: 'Date',
  },
  {
    label: 'Feedback Mode',
    field: 'feedback_mode',
    placeholder: 'Select Feedback Mode',
    type: 'autoComplete',
    options: ['Automatic', 'Manual'],
  },
  {
    label: 'Last automatic feedback request',
    field: 'automatic_feedback_request_success_date',
    type: 'date',
  },
  {
    label: 'Last automatic feedback failure',
    field: 'automatic_feedback_request_failure_date',
    type: 'date',
  },
];

const groupFilterClosedArray = [
  {
    type: 'autoComplete',
    label: 'Customer',
    api: '/customers',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'customer',
  },
  {
    type: 'autoComplete',
    label: 'Type',
    placeholder: 'Select Type',
    field: 'new_project_type',
    options: [
      {
        label: 'Staff Augmentation',
        value: 'staffaugmentation',
      },
      {
        label: 'Managed Team',
        value: 'managedteam',
      },
      {
        label: 'SDLC',
        value: 'sdlc',
      },
    ],
    suggestionField: 'label',
    keyField: 'value',
  },
  {
    type: 'date',
    field: 'completed_on',
    asParam: true,
    label: 'Closed Date',
  },
];

export const AllActiveProjectsTab = props => {
  let {
    route: {params} = {},
    user: propsUser = {},
    addOnParamFilter: propsAddOnParams,
  } = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {user} = useAppStateContext();
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;
  let filterParams = {...params, ...filterValues?.params};
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const tabs = {
    all_active: {
      apiKey: 'active',
      label: 'All',
      view: (
        <AllActiveProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{
            project_status: 'a',
            projecttype: {$nin: ['tr', 'dpt']},
            ...addOnParamFilter,
          }}
          user={user}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        project_status: 'a',
        projecttype: {$nin: ['tr', 'dpt']},
        ...addOnParamFilter,
      },
      filter: filterValues?.filter,
      params: filterParams,
      limit: 500,
    },
  };

  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <AddButton
          title={'Project'}
          view={'client-add-project'}
          params={{
            user,
            new_project_sub_type: addOnParamFilter?.new_project_sub_type_id,
            ...params,
          }}
        />,
        showCustomer || showLabel ? (
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            // filters={groupFilterArray}
            filters={[
              showCustomer && {
                type: 'autoComplete',
                label: 'Customer',
                api: '/customers',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'customer',
              },

              showLabel && {
                type: 'autoComplete',
                label: 'Label',
                api: '/projectsubtypes',
                placeholder: 'Select',
                suggestionField: 'project_sub_type',
                valueField: 'project_sub_type',
                field: 'new_project_sub_type',
              },
              {
                type: 'autoComplete',
                label: 'Parent Project',
                options: [
                  {value: {$exists: true}, label: 'Exists'},
                  {value: {$exists: false}, label: 'Dosent Exists'},
                ],
                placeholder: 'Select',
                field: 'parent',
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
              },
            ]}
          />
        ) : (
          void 0
        ),
        // <GroupBy {...groupBy} />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const AllClosedProjectsTab = props => {
  let {route: {params} = {}, extraAddOnfilter = {}, label} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    filter: {
      ...extraAddOnfilter,
    },
  });
  const {user, download} = useAppStateContext();
  let filterParams = {...params, ...filterValues?.params};
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const icons = useTheme('icons');
  const tabs = {
    closed_project: {
      apiKey: 'active',
      label: label,
      view: (
        <AllClosedProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{project_status: {$in: ['c', 'h']}}}
        />
      ),
      actions: [
        showCustomer || showLabel ? (
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            // filters={groupFilterArray}
            filters={[
              showCustomer && {
                type: 'autoComplete',
                label: 'Customer',
                api: '/customers',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'customer',
              },

              showLabel && {
                type: 'autoComplete',
                label: 'Label',
                api: '/projectsubtypes',
                placeholder: 'Select',
                suggestionField: 'project_sub_type',
                valueField: 'project_sub_type',
                field: 'new_project_sub_type',
              },
            ]}
          />
        ) : (
          void 0
        ),
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.closed_project,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {project_status: 'c'},
      filter: filterValues?.filter,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        // <GroupFilter
        //   filterValues={filterValues}
        //   applyFilter={applyFilter}
        //   filters={groupFilterClosedArray}
        // />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const AllActiveProjectsTabPm = props => {
  let {
    route: {params} = {},
    user: propsUser = {},
    addOnParamFilter: propsAddOnParams,
  } = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {user} = useAppStateContext();
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;
  let filterParams = {...params, ...filterValues?.params};
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const tabs = {
    all_active: {
      apiKey: 'active',
      label: 'All',
      view: (
        <AllActiveProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{
            project_status: 'a',
            projecttype: {$nin: ['tr', 'dpt']},
            owner_id: user?.employee?._id,
            ...addOnParamFilter,
          }}
          user={user}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        project_status: 'a',
        projecttype: {$nin: ['tr', 'dpt']},
        owner_id: user?.employee?._id,
        ...addOnParamFilter,
      },
      filter: filterValues?.filter,
      params: filterParams,
      limit: 500,
    },
  };

  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <AddButton
          title={'Project'}
          view={'client-add-project'}
          params={{
            user,
            new_project_sub_type: addOnParamFilter?.new_project_sub_type_id,
            ...params,
          }}
        />,
        showCustomer || showLabel ? (
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            // filters={groupFilterArray}
            filters={[
              showCustomer && {
                type: 'autoComplete',
                label: 'Customer',
                api: '/customers',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'customer',
              },

              showLabel && {
                type: 'autoComplete',
                label: 'Label',
                api: '/projectsubtypes',
                placeholder: 'Select',
                suggestionField: 'project_sub_type',
                valueField: 'project_sub_type',
                field: 'new_project_sub_type',
              },
              {
                type: 'autoComplete',
                label: 'Parent Project',
                options: [
                  {value: {$exists: true}, label: 'Exists'},
                  {value: {$exists: false}, label: 'Dosent Exists'},
                ],
                placeholder: 'Select',
                field: 'parent',
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
              },
            ]}
          />
        ) : (
          void 0
        ),
        // <GroupBy {...groupBy} />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const AllClosedProjectsTabPm = props => {
  let {route: {params} = {}, extraAddOnfilter = {}, label} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    filter: {
      ...extraAddOnfilter,
    },
  });
  const {user, download} = useAppStateContext();
  let filterParams = {...params, ...filterValues?.params};
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const icons = useTheme('icons');
  const tabs = {
    closed_project: {
      apiKey: 'active',
      label: 'Closed',
      view: (
        <AllClosedProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{
            owner_id: user?.employee?._id,
            project_status: {$in: ['c', 'h']},
          }}
        />
      ),
      actions: [
        showCustomer || showLabel ? (
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            // filters={groupFilterArray}
            filters={[
              showCustomer && {
                type: 'autoComplete',
                label: 'Customer',
                api: '/customers',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'customer',
              },

              showLabel && {
                type: 'autoComplete',
                label: 'Label',
                api: '/projectsubtypes',
                placeholder: 'Select',
                suggestionField: 'project_sub_type',
                valueField: 'project_sub_type',
                field: 'new_project_sub_type',
              },
            ]}
          />
        ) : (
          void 0
        ),
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.closed_project,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        owner_id: user?.employee?._id,
        project_status: {$in: ['c', 'h']},
      },
      filter: filterValues?.filter,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        // <GroupFilter
        //   filterValues={filterValues}
        //   applyFilter={applyFilter}
        //   filters={groupFilterClosedArray}
        // />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const AllActiveProjectsTabAm = props => {
  let {
    route: {params} = {},
    user: propsUser = {},
    addOnParamFilter: propsAddOnParams,
  } = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {user} = useAppStateContext();
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;
  let filterParams = {...params, ...filterValues?.params};
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const tabs = {
    all_active: {
      apiKey: 'active',
      label: 'All',
      view: (
        <AllActiveProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{
            project_status: 'a',
            projecttype: {$nin: ['tr', 'dpt']},
            account_owner: user?.employee?._id,
            ...addOnParamFilter,
          }}
          user={user}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        project_status: 'a',
        projecttype: {$nin: ['tr', 'dpt']},
        account_owner: user?.employee?._id,
        ...addOnParamFilter,
      },
      filter: filterValues?.filter,
      params: filterParams,
      limit: 500,
    },
  };

  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <AddButton
          title={'Project'}
          view={'client-add-project'}
          params={{
            user,
            new_project_sub_type: addOnParamFilter?.new_project_sub_type_id,
            ...params,
          }}
        />,
        showCustomer || showLabel ? (
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            // filters={groupFilterArray}
            filters={[
              showCustomer && {
                type: 'autoComplete',
                label: 'Customer',
                api: '/customers',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'customer',
              },

              showLabel && {
                type: 'autoComplete',
                label: 'Label',
                api: '/projectsubtypes',
                placeholder: 'Select',
                suggestionField: 'project_sub_type',
                valueField: 'project_sub_type',
                field: 'new_project_sub_type',
              },
              {
                type: 'autoComplete',
                label: 'Parent Project',
                options: [
                  {value: {$exists: true}, label: 'Exists'},
                  {value: {$exists: false}, label: 'Dosent Exists'},
                ],
                placeholder: 'Select',
                field: 'parent',
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
              },
            ]}
          />
        ) : (
          void 0
        ),
        // <GroupBy {...groupBy} />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const AllClosedProjectsTabAm = props => {
  let {route: {params} = {}, extraAddOnfilter = {}, label} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    filter: {
      ...extraAddOnfilter,
    },
  });
  const {user, download} = useAppStateContext();
  let filterParams = {...params, ...filterValues?.params};
  const {
    is_service_industry: showCustomer = false,
    manual_labeling: showLabel = false,
  } = user?.org || {};
  const icons = useTheme('icons');
  const tabs = {
    closed_project: {
      apiKey: 'active',
      label: 'Closed',
      view: (
        <AllClosedProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{
            account_owner: user?.employee?._id,
            project_status: {$in: ['c', 'h']},
          }}
        />
      ),
      actions: [
        showCustomer || showLabel ? (
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            // filters={groupFilterArray}
            filters={[
              showCustomer && {
                type: 'autoComplete',
                label: 'Customer',
                api: '/customers',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'customer',
              },

              showLabel && {
                type: 'autoComplete',
                label: 'Label',
                api: '/projectsubtypes',
                placeholder: 'Select',
                suggestionField: 'project_sub_type',
                valueField: 'project_sub_type',
                field: 'new_project_sub_type',
              },
            ]}
          />
        ) : (
          void 0
        ),
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.closed_project,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        account_owner: user?.employee?._id,
        project_status: {$in: ['c', 'h']},
      },
      filter: filterValues?.filter,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        // <GroupFilter
        //   filterValues={filterValues}
        //   applyFilter={applyFilter}
        //   filters={groupFilterClosedArray}
        // />,
      ]}
      params={params}
      {...props}
    />
  );
};
