import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchFormData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const CreateRFRForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'rps',
    ...props,
  });

  const {data} = useFetchFormData({
    api: '/opportunities',
    filter: {
      _id: row?._id,
    },
    fields: {
      potential_customer: {name: 1},
      customer_id: {name: 1},
      requirement: 1,
      requirement_description: 1,
      rfq_owner: {name: 1},
      due_date: 1,
      budget: 1,
      skill: {name: 1},
      account_owner: {name: 1},
      type: 1,
      priority: 1,
      rfq_value: 1,
      practice: 1,
      tech_person: {name: 1},
      rfq_type: 1,
      industry: {name: 1},
      probability: 1,
      rfr_engagement_type: 1,
      work_location: 1,
      no_of_resources: 1,
      rfr_type: 1,
      experience: 1,
      rfq_created_on: 1,
      rfr_created_on: 1,
      outcome_description: 1,
      outcome_date: 1,
      owner: {name: 1},
      status: 1,
      organization: {name: 1},
      product: {name: 1},
    },
  });
  if (!data) {
    return null;
  }
  const {_id, opportunity_type, rfq_owner, ...rest} = data;

  return (
    <Form
      api={`/opportunity/${row?._id}/rps`}
      header={'Create RFR'}
      defaultValues={{
        ...rest,
        opportunity_type: 'StaffAugmentationOpportunity',
        creation_date: getZeroTimeDate(new Date()),
        rps: _id,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Customer',
          label: 'Customer',
          field: 'customer_id',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.rfr_type && values.rfr_type === 'Customer',
        },
        {
          label: 'Potentail Customer',
          placeholder: 'Potentail Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: `/potentialcustomers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values &&
            values.rfr_type &&
            values.rfr_type === 'PotentailCustomer',
        },
        {
          label: 'Account Owner',
          placeholder: 'Account Owner',
          field: 'account_owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          field: 'industry',
          placeholder: 'Industry',
          label: 'Industry',
          type: 'autoComplete',
          api: '/industries',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          type: 'number',
          field: 'probability',
          placeholder: 'Probability',
          label: 'Probability',
        },
        {
          field: 'priority',
          placeholder: 'Priority',
          label: 'Priority',
          type: 'autoComplete',
          options: ['High', 'Medium', 'Low'],
        },
        {
          label: 'Engaement Type',
          placeholder: 'Engaement Type',
          field: 'rfr_engagement_type',
          type: 'autoComplete',
          options: ['Trial Engagement', 'Billable Engagement'],
        },
        {
          type: 'text',
          field: 'requirement',
          placeholder: 'Requirement',
          label: 'Requirement',
        },
        {
          type: 'textArea',
          field: 'requirement_description',
          placeholder: 'Requirement Description',
          label: 'Requirement Description',
        },
        {
          placeholder: 'Work Location',
          label: 'Work Location',
          field: 'work_location',
          type: 'autoComplete',
          options: ['OffSite', 'OnSite'],
        },
        {
          type: 'date',
          field: 'due_date',
          placeholder: 'Due Date',
          label: 'Due Date',
        },
        {
          type: 'number',
          field: 'no_of_resources',
          placeholder: 'Number of Resources',
          label: 'Number of Resources',
        },
        {
          field: 'skill',
          placeholder: 'Skills',
          label: 'Skills',
          type: 'autoComplete',
          api: '/skills',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          field: 'practice',
          placeholder: 'Practice',
          label: 'Practice',
          type: 'autoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          placeholder: 'RFR Type',
          label: 'RFR Type',
          field: 'rfr_type',
          type: 'autoComplete',
          options: ['Customer', 'PotentailCustomer'],
        },
        {
          field: 'experience',
          placeholder: 'Experience',
          label: 'Experience',
          type: 'autoComplete',
          api: '/experiences',
          suggestionField: 'experience',
          valueField: 'experience',
        },
        {
          field: 'tech_person',
          placeholder: 'Tech Person',
          label: 'Tech Person',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Attachment',
          field: 'requirement_document',
          multiple: true,
          type: 'file',
        },
        //no to or from date, client rating, mode of screening
      ]}
    />
  );
};
