import React from 'react';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {useTheme} from '@unthinkable/react-theme';
import {TextRenderer} from '../../../components/form/Renderers';
import {OrderWiseAllocations} from './OrderWiseAllocationsTab';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {randomColor} from '../../../utils/util';

export const RenderViewAllocations = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="View Allocations"
      style={{...rowText, color: colors['ACCENT1_HIGH']}}
    />
  );
};

const DATE = {
  type: 'date',
  field: 'order_date',
  header: 'Date',
  width: 150,
  formatOptions: {
    format: 'DD MMM ,YYYY',
  },
};

const CUSTOMER = {
  type: 'colorTag',
  field: 'customer_id.name',
  colorField: 'customer_id.color',
  header: 'Customer',
};

const AMOUNT = {
  type: 'currency',
  field: 'amount',
  header: 'Amount (₹)',
  aggregate: true,
  width: 150,
};

const ORDER = {
  field: 'order_number',
  header: 'Order',
  type: 'colorTag',
  colorField: randomColor,
  width: 200,
};

const navigateToEmployeeRequest = ({navigation, params}) => ({
  title: 'Resource Request',
  onPress: props => {
    const {row} = props;
    navigation.navigate('fixed-employee-request-tab', {
      row,
    });
  },
});

// const navigateToAssignedResources = ({navigation, params}) => ({
//   title: 'Resources',
//   onPress: props => {
//     const {row} = props;
//     navigation.navigate('fixed-order-assigned-resources', {
//       row,
//     });
//   },
// });

const assignedProject = ({navigation, params}) => ({
  title: 'Assign Project',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-project', {
      row,
    });
  },
});
export const FixedOrderWiseAllocations = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
    fixOrderForRm,
  } = props;
  params = {...params, ...filterParams};
  return (
    <Table
      api={'/fixed/order'}
      params={params}
      addOnFilter={filter}
      eventSourceId="fixed"
      search={searchValue}
      searchFields="order_number"
      onRowPress={({row}) => {
        navigation.navigate('show-milestones', {
          row: {...row, fromFixedOrderForRm: true},
        });
      }}
      columns={[
        ORDER,
        CUSTOMER,
        {
          header: 'AM',
          field: 'account_owner_id',
          type: 'userAvatarChip',
        },
        {
          header: 'CSM/PM',
          field: 'delivery_owner_id',
          type: 'userAvatarChip',
        },
        // DATE,
        {
          render: CountCellRender,
          count_field: 'allocationHourWise',
          header: 'Allocations',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate('resource-allocations-list', {
              ...params,
              fixedOrderFilter: {
                order: row?._id,
              },
            });
          },
        },
      ]}
      moreActions={() => [
        navigateToEmployeeRequest({navigation, params}),
        // navigateToAssignedResources({navigation, params}),
        assignedProject({navigation, params}),
      ]}
    />
  );
};

export const FixedOrderWiseAllocationsTab = props => {
  const {route: {params} = {}} = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};

  let tabs = {};
  tabs.fixed = {
    label: 'Fixed',
    api: `/fixed/order`,
    params: {
      period: filterParams.period,
      product,
      ...params,
    },
    view: (
      <FixedOrderWiseAllocations
        filter={filter}
        filterParams={filterParams}
        searchValue={searchValue}
        params={params}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
          ]}
        />,
      ]}
    />
  );
};

export const OrderWiseAllocationsTab = props => {
  const {route: {params} = {}} = props;
  const {product, department} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
    filter: {
      'employee.department': department,
      'employee.employee_status': 'Active',
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};

  let tabs = {};
  tabs.fixed = {
    label: 'Fixed',
    params: {
      period: filterParams.period,
      product,
      ...params,
    },
    view: (
      <FixedOrderWiseAllocations
        filter={filter}
        filterParams={filterParams}
        searchValue={searchValue}
        params={params}
      />
    ),
  };

  tabs.order_wise_allocation_tm = {
    label: 'T&M Order Allocations',
    view: (
      <OrderWiseAllocations
        filter={filter}
        searchValue={searchValue}
        filterParams={{...filterParams, order_type: 'r'}}
        {...props}
      />
    ),
    eventSourceId: 'allocatedResource',
    limit: 2000,
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
          ]}
        />,
      ]}
    />
  );
};
