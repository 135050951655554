import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const StopAllocationInWorkLoad = props => {
  const {
    route: {params},
  } = props;
  let {row = {}, type} = params;
  let {
    project = [],
    employeeLastAssignProject = {},
    assigned_to = {},
    period = {},
  } = row || {};
  let projectSuggestion = [employeeLastAssignProject];
  if (type == 'workload') {
    projectSuggestion = project;
  }

  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'workload-billable',
  });

  const {fetch} = useAppStateContext();


  let defaultValues={
    employee:{
        _id:assigned_to?._id,
        name:assigned_to?.name
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Stop Alocation"
      defaultValues={defaultValues}
      beforeSubmit={({data})=>{
        if(!data?._id)throw new Error("Employee is not Assigned in this project")
        return {data}
      }}
      layoutFields={[
        {
          // label: 'Project',
          // field: 'project_id',
          // type: 'autoComplete',
          // valueField: 'project',
          // keyField: '_id',
          // suggestionField: 'project',
          // options: projectSuggestion,
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          required: true,
          //   required: true,
          size: 12,
          onChangeValue: async (value, _, {values, setFieldValue}) => {
            const {data: employeeAssignmentData = {}} = await fetch({
              uri: '/employeeassignments',
              props: {
                filter: {
                  project_id: value?._id,
                  employee: assigned_to?._id,
                },
                fields: {
                  _id: 1,
                  to_date: 1,
                  from_date:1,
                  employee: {name: 1},
                  milestone_id: {_id: 1, start_date: 1, end_date: 1},
                  skill_requirement: {_id: 1, from_date: 1, to_date: 1},
                  hours: 1,
                  project_skill: {_id: 1, name: 1},
                  resource_skill: {_id: 1, name: 1},
                  level_one_owner: {_id: 1, name: 1},
                  level_two_owner: {_id: 1, name: 1},
                  level_three_owner: {_id: 1, name: 1},
                  is_applicable_for_ma: 1,
                  is_shadow: 1,
                  is_internal: 1,
                  front_facing_resource:{_id:1,name:1}
                },
                sort: {to_date: -1},

                only: true,
              },
            });
          
            // if (employeeAssignmentData?._id)
              setFieldValue('_id',employeeAssignmentData?._id );
            // if (employeeAssignmentData?.to_date)
              setFieldValue('to_date', employeeAssignmentData?.to_date);
            // if (employeeAssignmentData?.from_date)
              setFieldValue('from_date', employeeAssignmentData?.from_date);

            // if (employeeAssignmentData?.hours) {
              setFieldValue('hours', employeeAssignmentData?.hours);
            // }
            // if (employeeAssignmentData?.project_skill) {
              setFieldValue(
                'project_skill',
                employeeAssignmentData?.project_skill,
              );
            // }
            // if (employeeAssignmentData?.resource_skill) {
              setFieldValue(
                'resource_skill',
                employeeAssignmentData?.resource_skill,
              );
            // }
            // if (employeeAssignmentData?.level_one_owner) {
              setFieldValue(
                'level_one_owner',
                employeeAssignmentData?.level_one_owner,
              );
            // }
            // if (employeeAssignmentData?.level_two_owner) {
              setFieldValue(
                'level_two_owner',
                employeeAssignmentData?.level_two_owner,
              );
            // }
            // if (employeeAssignmentData?.level_three_owner) {
              setFieldValue(
                'level_three_owner',
                employeeAssignmentData?.level_three_owner,
              );
            // }
            // if (employeeAssignmentData?.is_applicable_for_ma) {
              setFieldValue(
                'is_applicable_for_ma',
                employeeAssignmentData?.is_applicable_for_ma,
              );
            // }

            // if (employeeAssignmentData?.front_facing_resource) {
                setFieldValue(
                  'front_facing_resource',
                  employeeAssignmentData?.front_facing_resource,
                );
              // }
          },
        },

        {
          label: 'Resource',
          field: 'employee',
          type: 'autoComplete',
          //   api: '/employeeSuggestionProjectWise',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',

          size: 6,
          //   filter: ({values}) => {
          //     let {project_id} = values;
          //     return {project_id};
          //   },
      
          readOnly: true,
        },
        {
          label: 'Front Facing Resource',
          field: 'front_facing_resource',
          type: 'autoComplete',
          //   api: '/employeeSuggestionProjectWise',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField:'name',
          //   filter: ({values}) => {
          //     let {project_id} = values;
          //     return {project_id};
          //   },
          size: 6,
          readOnly: true,
        },
        {
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          //   required: true,
          size: 4,
          readOnly: true,
        },
        {
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          //   required: true,
          size: 4,
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          //   required: true,
          size: 4,
          readOnly: true,
        },
        {
          label: 'Project Skill',
          field: 'project_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          readOnly: true,
        },

        {
          label: 'Resource Skill',
          field: 'resource_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          readOnly: true,
        },

        {
          label: 'Level 1 Owner',
          field: 'level_one_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
          readOnly: true,
        },
        {
          label: 'Level 2 Owner',
          field: 'level_two_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
          readOnly: true,
        },
        {
          label: 'Level 3 Owner',
          field: 'level_three_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 4,
          readOnly: true,
        },
        {
          type: 'checkbox',
          field: 'is_applicable_for_ma',
          label: 'Applicable for MA',
          size: 6,
          readOnly: true,
        },
      ]}
      {...props}
    />
  );
};

export default StopAllocationInWorkLoad;
