import React from 'react';
import {EnggBilledTab} from './EnggBilledTab';
import {InternalTab, SocialTab, TainingTab} from './EnggNonBilledTab';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {getCurrentMonth} from '@unthinkable/react-filters';

const EngineeringProjectWiseGroup = createMenuNavigator();
export const EngineeringProjectWiseNavigator = props => {
  let {route: {params} = {}} = props;
  params.period = getCurrentMonth();
  const {period = {}} = params;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <EngineeringProjectWiseGroup.Navigator
      canAccess={canAccess}
      data-navigator-id="EngineeringProjectWiseGroup"
      {...props}>
      <EngineeringProjectWiseGroup.Screen
        data-resource-id="IcsBilled"
        name="Customer"
        component={EnggBilledTab}
        props={{user: user, fromICMenu: true, ...props}}
        options={{
          label: 'Customer',
          api: '/employees/internalresources',
          addOnFilter: {
            //  is_manager: false,
            project_type: {$in: ['tm', 'f']},
          },
          limit: 5000,
        }}
      />
      <EngineeringProjectWiseGroup.Screen
        data-resource-id="Social"
        name="Social"
        component={SocialTab}
        props={{user: user, ...props}}
        options={{
          label: 'Social',
          api: '/employees/bench',
          addOnFilter: {
            // is_manager: false,
            is_proposed_deployment: {$in: [null, false]},
          },
          limit: 5000,
          params: {...params, fromUnbilledDeploymentsTab: true},
        }}
      />
      <EngineeringProjectWiseGroup.Screen
        data-resource-id="Internal"
        name="Internal"
        component={InternalTab}
        props={{user: user, fromICMenu: true, ...props}}
        options={{
          label: 'Internal',
          api: '/employees/bench',
          addOnFilter: {
            // is_manager: false,
            is_proposed_deployment: {$in: [null, false]},
          },
          limit: 5000,
          params: {...params, forInternalTab: true},
        }}
      />
      <EngineeringProjectWiseGroup.Screen
        data-resource-id="Training"
        name="Training"
        component={TainingTab}
        props={{user: user, fromICMenu: true, ...props}}
        options={{
          label: 'Training',
          api: '/employees/internalresources',
          addOnFilter: {
            // is_manager: false,
            bench_training_type: {$in: ['Offline', 'RFR']},
            is_actual_allocation: {$in: [null, false, true]},
          },
          params: {...params, period, isUnderTraining: true},
          limit: 5000,
        }}
      />
      <EngineeringProjectWiseGroup.Screen
        data-resource-id="Bench"
        name="Bench"
        props={{user: user, ...props}}
        options={{
          label: 'Bench',
          onPress: () => {
            props.navigation.navigate('bench-menu', {});
          },
        }}
      />
    </EngineeringProjectWiseGroup.Navigator>
  );
};
