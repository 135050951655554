export const RenderCommitColumnStyles = ({theme: {colors, fonts}}) => ({
  text: {
    ...fonts.BODY2,
    color: colors.NEUTRAL_MEDIUM,
  },
  textDisabled: {
    ...fonts.BODY2,
    color: colors.NEUTRAL_LOW,
  },
  viewAllText: {
    ...fonts.BODY2,
    color: colors.INFORMATION_HIGH,
  },
});

export const BoilerplateCloneRepoAndCheckoutBranchStyle = ({
  theme: {colors, icons},
}) => ({
  copy: icons.Copy,
  link: icons.ArrowSquareOut,
  IconStyle: {
    padding: 13,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.OUTLINE,
    backgroundColor: colors.SURFACE3,
  },
});

export const CommentScreenStyles = ({theme: {colors}}) => ({
  container: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: colors.SURFACE3,
    padding: 16,
    borderRadius: 12,
    gap: 16,
  },
});

export const FormCommentTableStyles = ({theme: {colors}}) => ({
  container: {
    backgroundColor: colors.SURFACE3,
    padding: 16,
    borderRadius: 12,
    gap: 16,
  },
});

export const MyCommentsTableStyles = ({theme: {colors}}) => ({
  container: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.OUTLINE,
    padding: 16,
  },
  textWrapperView: {
    flex: 1,
    overflow: 'hidden',
    padding: 4,
    backgroundColor: colors.SURFACE2,
  },
  dateContainer: {
    minWidth: 176,
    alignItems: 'flex-end',
  },
});
