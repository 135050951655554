import React, {forwardRef} from 'react';
import {
  StyledMentionsInput,
  MentionsInput as MentionsInputComponent,
  MessageRender,
} from '@unthinkable/react-mentions-input';
import {useAppStateContext} from '../../../providers/AppState';
import {useStyles} from '@unthinkable/react-theme';
import {MentionTextStyles, MentionsInputStyles} from './theme';

export const BaseMentionsInput = forwardRef((props, ref) => {
  const {fetch} = useAppStateContext();
  return <MentionsInputComponent {...props} ref={ref} fetch={fetch} />;
});

export const MentionsInput = forwardRef((props, ref) => {
  const {fetch} = useAppStateContext();
  const styles = useStyles(MentionsInputStyles);
  return (
    <StyledMentionsInput {...props} ref={ref} styles={styles} fetch={fetch} />
  );
});

export const MentionRenderer = props => {
  const styles = useStyles(MentionTextStyles);
  return <MessageRender {...props} styles={styles} message={props?.value} />;
};
