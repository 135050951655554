import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {
  QuotationApproverTable,
  QuotationfinanceTable,
} from './QuotationApproverTable';

export const QuotationApproverTab = props => {
  let {
    route: {params},
    reffer_to_coo,
    fromMyView,
  } = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <QuotationApproverTable
          addOnFilter={{status: 'Requested', ...filterValues.filter}}
          reffer_to_coo={reffer_to_coo}
          searchValue={searchValue}
          fromMyView={fromMyView}
          tab={'QuotationApproverActive'}
          {...props}
        />
      ),
    },
    Closed: {
      label: 'Closed',
      view: (
        <QuotationApproverTable
          tab={'QuotationApproverClosed'}
          reffer_to_coo={reffer_to_coo}
          searchValue={searchValue}
          fromMyView={fromMyView}
          addOnFilter={{
            status: {$in: ['Approved', 'Rejected']},
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search PO Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const QuotationApproverFinanceTab = props => {
  let {
    route: {params},
  } = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    Approved: {
      label: 'Approved',
      view: (
        <QuotationfinanceTable
          searchValue={searchValue}
          addOnFilter={{
            status: 'Approved',
            po_created: {$in: [null, false]},
            ...filterValues.filter,
          }}
          tab={'Approved'}
          {...props}
        />
      ),
    },
    Requested: {
      label: 'Requested',
      view: (
        <QuotationfinanceTable
          searchValue={searchValue}
          addOnFilter={{status: 'Requested', ...filterValues.filter}}
          tab={'Requested'}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
