import React from 'react';
import {Form} from '../../../../components/form/Form';
import {PROJECT_COMPONENT_TYPE_ID} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';
import {populateDesignFields} from './ScreenForm';
import NestedTable from '../../../../components/form/NestedTable';
import {MultiTag} from '../../../../components/chip/MultiChip';

const componentFields = ({params}) => {
  const {project, projectlibrary_id} = params;

  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    name: {
      label: 'Component',
      type: 'text',
      field: 'component',
      required: true,
    },
    childComponent: {
      label: 'Composed Components',
      type: 'multiAutoComplete',
      field: 'composite_components',
      api: `/projectComponents`,
      filter: ({values}) => {
        const selectedIds = values['composite_components']?.map(doc => doc._id);
        return {
          project_id: project?._id,
          library_version_id: null,
          library_id: projectlibrary_id,
          _id: {$nin: selectedIds || []},
          $or: [
            {component_type: {$in: ['styled', 'composed']}},
            {component_type: null},
          ],
        };
      },
      suggestionField: 'component',
      valueField: 'component',
    },
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    designOwner: {
      label: 'Design Owner',
      field: 'design_owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    description: {
      label: 'Description',
      type: 'textArea',
      field: 'desc',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    documentation: {
      label: 'Demo Link',
      type: 'link',
      field: 'demo_link',
    },
    remarks: {
      label: 'Remarks',
      type: 'richText',
      field: remarksField,
      placeholder: 'Write remarks here...',
      minHeight: 200,
    },
  };
};

const RenderTypesNestedTable = props => {
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={({values}) => {
        const {name} = values;
        return {
          title: 'Add Render Types',
          secondaryTitle: name,
        };
      }}
      editFormHeader={({values}) => {
        const {type} = values;
        return {
          title: 'Edit Render Types',
          secondaryTitle: type,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '40%',
      }}
      inlineAddForm
      inlineFields={[
        {
          label: 'Type',
          type: 'text',
          field: 'type',
          required: true,
          size: 6,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'desc',
          size: 6,
        },
      ]}
      fields={[
        {
          label: 'Type',
          type: 'text',
          field: 'type',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'desc',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
      ]}
      columns={[
        {
          header: 'Type',
          type: 'text',
          field: 'type',
          width: 150,
        },
        {
          header: 'Description',
          type: 'text',
          field: 'desc',
        },
      ]}
    />
  );
};

const PropsNestedTable = props => {
  const {is_side_view} = props;
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={({values}) => {
        const {name} = values;
        return {
          title: 'Add Prop Types',
          secondaryTitle: name,
        };
      }}
      editFormHeader={({values}) => {
        const {name} = values;
        return {
          title: 'Edit Prop Types',
          secondaryTitle: name,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      inlineAddForm
      inlineFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Type',
          type: 'multiText',
          field: 'type',
          size: 6,
        },
      ]}
      fields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          size: 6,
          required: true,
        },
        {
          label: 'Type',
          type: 'multiText',
          field: 'type',
          size: 6,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Options',
          type: 'multiText',
          field: 'options',
        },
        {
          collapsedFields: [
            {
              label: 'Default Value',
              type: 'textArea',
              field: 'default_value',
              inputProps: {
                minRows: 3,
                maxRows: 6,
              },
            },
            {
              label: 'Example Value',
              type: 'textArea',
              field: 'example_value',
              inputProps: {
                minRows: 3,
                maxRows: 6,
              },
            },
            {
              label: 'Control',
              type: 'text',
              field: 'control',
              size: 6,
            },
            {
              label: 'Category',
              type: 'text',
              field: 'category',
              size: 6,
            },
            {
              label: 'Render Types',
              nested: true,
              field: 'render_types',
              render: props => <RenderTypesNestedTable {...props} />,
            },
          ],
        },
      ]}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
          width: 200,
        },
        {
          header: 'Type',
          field: 'type',
          width: is_side_view ? void 0 : 150,
          render: ({row}) => {
            return <MultiTag values={row.type} />;
          },
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
          visible: !is_side_view,
        },
      ]}
    />
  );
};

const CodeSampleNestedTable = props => {
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={() => {
        return {
          title: 'Add Code Sample',
        };
      }}
      editFormHeader={({values}) => {
        const {title} = values;
        return {
          title: 'Edit Code Sample',
          secondaryTitle: title,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      fields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          field: 'args',
          label: 'Args',
          placeholder: 'Enter arguments here...',
          type: 'textArea',
          size: 6,
          inputProps: {
            minRows: 4,
            maxRows: 8,
          },
        },
        {
          field: 'parameters',
          label: 'Parameters',
          placeholder: 'Enter parameters here...',
          type: 'textArea',
          size: 6,
          inputProps: {
            minRows: 4,
            maxRows: 8,
          },
        },
        {
          label: 'Code Sample',
          field: 'sample_code',
          placeholder: 'Write your Component sample code here...',
          type: 'textArea',
          height: 300,
        },
      ]}
      columns={[
        {
          header: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
        },
      ]}
    />
  );
};

export const ComposedComponentForm = props => {
  const {
    route: {params},
    mode,
    data,
  } = props;

  const {is_side_view} = params;

  const designFields = populateDesignFields({row: data, params});

  const fields = componentFields({params});

  const tabs = {
    basic_info: {
      label: 'Basic Info',
      layoutFields: [
        fields.name,
        fields.childComponent,
        {
          collapsedFields: [
            fields.owner,
            fields.designOwner,
            fields.description,
            fields.documentation,
          ],
        },
      ],
    },
    fields: {
      label: 'Prop Types',
      layoutFields: [
        {
          nested: true,
          field: 'props',
          render: props => (
            <PropsNestedTable {...props} is_side_view={is_side_view} />
          ),
        },
      ],
    },
    designs: {
      label: 'Designs',
      layoutFields: designFields,
    },
    code: {
      label: 'Sample Code',
      layoutFields: [
        {
          nested: true,
          field: 'sample_codes',
          render: props => <CodeSampleNestedTable {...props} />,
        },
      ],
    },
    remarks: {
      visible: !!mode,
      label: 'Remarks',
      layoutFields: [fields.remarks],
    },
  };

  return <Form type={'tab'} submitAction="Save" tabs={tabs} {...props} />;
};

export const AddComposedComponentForm = props => {
  const {
    route: {params},
  } = props;

  const {project, feature, model, searchValue, afterSubmit, projectlibrary_id} =
    params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_COMPONENT_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    component: searchValue,
    project_id: project?._id,
    model_id: model?._id,
    source: PROJECT_COMPONENT_TYPE_ID,
    feature_id: feature?._id,
    component_type: 'composed',
  };
  if (projectlibrary_id) {
    initialValues.library_id = projectlibrary_id;
  }

  return (
    <ComposedComponentForm
      header="Add Composed Component"
      onSubmit={createAsset}
      afterSubmit={afterSubmit}
      beforeSubmit={requiredValidation}
      defaultValues={initialValues}
      eventSourceId={['Feature', 'ComposedComponent']}
      {...props}
    />
  );
};

export const ComposedComponentDetailForm = props => {
  const {
    route: {params},
  } = props;

  const {row, feature, readOnly} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_COMPONENT_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  return (
    <ComposedComponentForm
      key={row._id}
      selectedTab={'designs'}
      mode="edit"
      isDualMode
      header={{
        title: 'Composed Component Detail',
        secondaryTitle: row?.component,
      }}
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      readOnly={readOnly}
      data={row}
      {...props}
    />
  );
};
