import React from 'react';

import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
const ProductMenu = createMenuNavigator();

import pluralize from 'pluralize';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';

import StrategicInitiativesTable from './StrategicInitiativesTable';
import {useFilter} from '../../../controllers/useFilter';
import {useFetchData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {AccountReimbursementTab} from '../../reimbursement/views/ReimbursementTabs';
import {AttendanceTab} from '../../leadGeneration/views/attendance-views/AttendanceTab';

const OrganizationNavigator = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {filterValues} = useFilter({
    params: {
      period: params?.period || getPreviousMonth(),
    },
  });
  const {params: filterParams = {}} = filterValues;
  const {globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  const {product} = params;
  const {data: activeElements} = useFetchData({
    api: `/activeElements`,
    filter: {product: product?._id},
    fields: {
      label: 1,
      model: 1,
      filter: 1,
      on_press_url: 1,
      department: {
        name: 1,
      },
      product: {
        name: 1,
      },
    },
    sort: {
      sequence: 1,
    },
  });
  const mergedParams = {
    ...params,
    ...filterParams,
  };
  const dynamicMenuParams = [];
  if (activeElements?.length) {
    activeElements.forEach(activeElement => {
      let {
        label,
        model,
        filter,
        on_press_url = 'default',
        department,
        product,
      } = activeElement;
      const menuParams = {
        label,
        onPress: () => {
          navigation.navigate(`${on_press_url?.toLowerCase()}`, {
            department,
            product,
            ...params,
          });
        },
      };
      if (filter) {
        filter = JSON.parse(filter);
        if (model?.name) menuParams.api = `/${pluralize.plural(model?.name)}`;
        menuParams.filter = filter;
        menuParams.params = params;
      }
      dynamicMenuParams.push({...menuParams});
    });
  }

  if (dynamicMenuParams?.length) {
    dynamicMenuParams[0].defaultSelected = true;
  }

  return (
    <ProductMenu.Navigator
      data-navigator-id="Product"
      canAccess={canAccess}
      {...props}
      params={params}>
      {/* <ProductMenu.Screen
        data-resource-id="BuyerPersona"
        component={TargetAudienceTable}
        options={{
          label: 'Target Audience',
          // onPress: () => {
          //   props.navigation.navigate('target-audience-list', {
          //     ...params,
          //   });
          // },
        }}
      /> */}
      {/* <ProductMenu.Screen
        data-resource-id="BuyerPersona"
        component={MarketingThemeTabs}
        options={{
          label: 'Marketing Message',
        }}
      /> */}
      {/* <ProductMenu.Screen
        data-resource-id="MarketingCreative"
        // component={CreativeMenu}
        options={{
          label: 'Creative',
          onPress: () => {
            props.navigation.navigate('creative', {props});
          },
        }}
      /> */}
      {/* <ProductMenu.Screen
        data-resource-id="Outreachbatch"
        // component={OutreachBatchTabView}
        options={{
          label: 'Outreach Campaigns', //actions given in the tab itself
          onPress: () => {
            props.navigation.navigate('outreach-batch', {...params});
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="PotentialCustomer"
        // component={ProspectsTableTabView}
        options={{
          label: 'Prospects',
          onPress: () => {
            props.navigation.navigate('prospects', {...params});
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="PotentialCustomer"
        // component={LeadTableTabView}
        options={{
          label: 'Leads',
          onPress: () => {
            props.navigation.navigate('leads', {...params});
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="Opportunity"
        // component={CustomDigitalPlatformOppTabView}
        options={{
          label: 'CustomPlatform',
          onPress: () => {
            props.navigation.navigate('custome-platform-opportunity-list', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="Opportunity"
        // component={StaffAugmentationOppTabView}
        options={{
          label: 'StaffAugmentation',
          onPress: () => {
            props.navigation.navigate('staff-augmentation-opportunity-list', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="Opportunity"
        // component={RPSTableTabView}
        options={{
          label: 'RPS',
          onPress: () => {
            props.navigation.navigate('rps-tabs', {
              ...params,
            });
          },
        }}
      /> */}
      <ProductMenu.Screen
        data-resource-id="Employees"
        options={{
          label: 'Employee',
          onPress: () => {
            props.navigation.navigate('employee-tab-view', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Screen
        name="Attendance"
        component={AttendanceTab}
        props={{params: mergedParams}}
        options={{
          label: 'Attendance',
        }}
      />
      <ProductMenu.Screen
        data-resource-id="Customer"
        // component={CustomerTableTabView}
        options={{
          label: 'Customers',
          onPress: () => {
            props.navigation.navigate('customers', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="Order"
        // component={OrderTableTabView}
        options={{
          label: 'Order',
          onPress: () => {
            props.navigation.navigate('order', {
              ...params,
            });
          },
        }}
      />

      {/* <ProductMenu.Screen
        data-resource-id="RequestTalent"
        component={TalentManagement}
        options={{
          label: 'Request',
          onPress: () => {
            props.navigation.navigate('request-talent');
          },
        }}
      /> */}
      {/*   <ProductMenu.Screen
        data-resource-id="RevenueInvoice"
        component={InvoiceTable}
        props={{params: mergedParams}}
        options={{
          label: 'Invoice',
         onPress: () => {
            props.navigation.navigate('invoice-menu', {
              ...params,
            });
          },
        }}

      /> */}
      {/* <ProductMenu.Screen
        data-resource-id="Vendor"
        component={VendorTabView}
        options={{
          label: 'Vendors',
        }}
      /> */}
      {/* <ProductMenu.Screen
        data-resource-id="Vendor-Invoice"
        component={VendorInvoiceTabView}
        options={{
          label: 'Vendor Invoices',
        }}
      /> */}
      {/* <ProductMenu.Screen
        data-resource-id="Opportunity"
        // component={POCOppTabView}
        options={{
          label: 'Poc',
          onPress: () => {
            props.navigation.navigate('poc-tab-view', {
              ...params,
            });
          },
        }}
      /> */}

      {/* {dynamicMenuParams.map(menu => (
        <ProductMenu.Screen
          name={menu.label}
          props={{...menu}}
          options={{
            ...menu,
          }}
        />
      ))} */}

      <ProductMenu.Screen
        name="Custom Object"
        component={StrategicInitiativesTable}
        props={{params: mergedParams}}
        options={{
          label: 'Custom Object',
        }}
      />
      <ProductMenu.Screen
        name="Setup"
        props={{params: mergedParams}}
        options={{
          label: 'Setup',
          onPress: () => {
            navigation.navigate('organization-setup', {});
          },
        }}
      />
      <ProductMenu.Screen
        name="AccountReimbursements"
        component={AccountReimbursementTab}
        props={{params: mergedParams}}
        options={{
          label: 'Reimbursements',
        }}
      />
    </ProductMenu.Navigator>
  );
};

export default OrganizationNavigator;
