import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Checkbox} from '@unthinkable/react-checkbox';
import {useFormContext} from '@unthinkable/react-form/src/FormContext';
import {TextRenderer} from '../../../components/form/Renderers';
export const LoadEmailThemes = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const formProps = useFormContext();
  const {values, setFieldValue} = formProps;
  const {loaded_themes, target_audience, loaded_messages} = values;
  const loadedInmailThemeMap = {};
  if (Array.isArray(loaded_themes)) {
    loaded_themes?.forEach(item => {
      loadedInmailThemeMap[item] = 1;
    });
  }
  const [loadedInmailThemes, setLoadedInmailThemes] =
    useState(loadedInmailThemeMap);
  return (
    <Table
      eventSourceId={['loadEmailMessages']}
      api={`/marketingThemes`}
      fields={{
        name: 1,
        type: {name: 1},
        theme_value_proposition: 1,
        target_audience: {
          name: 1,
        },
        drip_inmail_count: {
          _id: 1,
        },
        drip_email_count: {
          _id: 1,
        },
      }}
      filter={{
        theme_type: 'outreach',
        target_audience,
      }}
      columns={[
        {
          render: ({row}) => (
            <Checkbox
              value={loadedInmailThemes[row?._id]}
              onChangeValue={() => {
                setLoadedInmailThemes(prev => {
                  if (prev[row?._id]) {
                    delete prev[row?._id];
                  } else {
                    prev[row?._id] = 1;
                  }
                  return prev;
                });
                setFieldValue('loaded_themes', [
                  ...Object.keys(loadedInmailThemes),
                ]);
              }}
            />
          ),
          width: 50,
        },
        {
          header: 'Value Proposition',
          field: 'theme_value_proposition',
          type: 'text',
          responsive: 'sm',
        },
        {
          header: 'Type',
          field: 'type.name',
          colorField: 'type.color',
          type: 'colorTag',
          responsive: 'sm',
          width: 150,
        },
        {
          render: CountCellRender,
          header: 'Messages',
          align: 'right',
          count_field: 'drip_email_count',
          responsive: 'sm',
          onPress: ({row}) => {
            navigation.navigate('load-email-messages', {
              ...params,
              marketing_theme: row,
              formProps,
            });
          },
          width: 100,
        },
        {
          header: 'Selected',
          align: 'center',
          render: ({row, ...rest}) => {
            const message = loaded_messages?.find(
              item => item.marketing_theme == row?._id,
            );
            return (
              <TextRenderer
                {...rest}
                value={message?.name}
                style={{
                  textAlign: 'center',
                }}
              />
            );
          },
        },
      ]}
      {...props}
    />
  );
};
