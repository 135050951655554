import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {DraftInvoiceTab, DraftInvoiceTabAM} from './DraftInvoiceTab';
import {PendingInvoiceTab, PendingInvoiceTabAm} from './PendingInvoicesTabs';
import {RevenueInvoiceTab, RevenueInvoiceTabAm} from './RevenueInvoiceTab';

export const InvoiceMenu = props => {
  const {route: {params} = {}} = props;

  const menus = [
    [
      {
        label: 'Invoice',
        view: <RevenueInvoiceTab {...props} />,
      },
      {
        label: 'Draft Invoice',
        view: <DraftInvoiceTab {...props} />,
      },
      {
        label: 'Unpaid Invoice',
        view: <PendingInvoiceTab {...props} />,
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const InvoiceMenuAm = props => {
  const {route: {params} = {}} = props;

  const menus = [
    [
      {
        label: 'Draft Invoice',
        view: <DraftInvoiceTabAM {...props} />,
      },
      {
        label: 'Invoice',
        view: <RevenueInvoiceTabAm {...props} />,
      },
      {
        label: 'Unpaid Invoice',
        view: <PendingInvoiceTabAm {...props} />,
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
