import React from 'react';

import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {useAppStateContext} from '../../../providers/AppState';

import {EmployeeProjectTasks} from '../../task/views/TasksTable';
import {DisqualifiedTasksTab} from '../../task/views/DisqualifiedTasksTab';
import {ProjectDisqualifiedTasksTable} from '../../task/views/DisqualifiedTasksTable';

export const EmployeeEfficiencyIssueDetail = props => {
  const {
    route: {params},
  } = props;

  const {fromProject} = params;
  const {user} = useAppStateContext();

  return (
    <LeftMenuView
      {...props}
      menus={[
        {
          label: 'Issues',
          view: <EmployeeProjectTasks featureType="issue" />,
        },
        {
          label: 'Bugs',
          view: (
            <EmployeeProjectTasks
              filterValues={{filter: {bug_id: {$exists: true}}}}
            />
          ),
        },
        fromProject || user.userType != 'employee'
          ? {
              label: 'Disqualified tasks',
              view: <ProjectDisqualifiedTasksTable showHeader={true} />,
            }
          : {
              label: 'Disqualified tasks',
              view: <DisqualifiedTasksTab />,
            },
      ]}
    />
  );
};
