import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const OutreachCampaignContactMessageFormNew = props => {
  const {
    header = 'Add Message',
    route: {params: {row: {actualScheduledOn} = {}} = {}} = {},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignactions',
    eventSourceId: ['campaignActionEdited'],
  });

  let field = 'scheduledOn';
  if (actualScheduledOn) {
    field = 'actualScheduledOn';
  }

  return (
    <Form
      fields={{
        name: 1,
        personalize_subject: 1,
        subject: 1,

        personalize_body: 1,
        body: 1,
        scheduledOn: 1,
        send_after: 1,
        send_after_span: 1,
        actualScheduledOn: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
          placeholder: 'Name',
          size: 6,
        },
        {
          field: field,
          type: 'date',
          label: 'Scheduled on',
          placeholder: 'Scheduled on',
          size: 6,
          format: 'DD MMM YYYY HH:mm',
          zeroTimeDate: false,
          typecast: value => {
            if (value) {
              return new Date(value);
            }
          },
        },
        {
          field: 'personalize_subject',
          type: 'textArea',
          label: 'Personalize subject',
          placeholder: 'Personalize subject',
        },
        {
          field: 'personalize_body',
          type: 'textArea',
          label: 'Personalize body',
          placeholder: 'Personalize body',
        },
        {
          field: 'subject',
          type: 'textArea',
          label: 'Subject',
          placeholder: 'Subject',
        },

        {
          field: 'body',
          type: 'textArea',
          label: 'Body',
          placeholder: 'Body',
        },
        {
          field: 'send_after',
          type: 'number',
          label: 'Delay',
          placeholder: 'Delay',
          size: 6,
        },
        {
          field: 'send_after_span',
          type: 'autoComplete',
          label: 'Span',
          placeholder: 'Span',
          options: ['Minutes', 'Hours', 'Days'],
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const OutreachCampaignContactMessageFormEdit = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <OutreachCampaignContactMessageFormNew
      mode="edit"
      api={`/campaignactions/${_id}`}
      header={'Edit Campaign Message'}
      {...props}
    />
  );
};
