import React from 'react';
import './PulseLoader.css';

const PulseLoader = ({children, style}) => {
  return (
    <div className="pulse" style={style}>
      {children}
    </div>
  );
};

export default PulseLoader;
