import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const KpiApiForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/apis',
    eventSourceId: 'kpiapitable',
    ...props,
  });
  return (
    <Form
      api={`/apis/${row?._id}`}
      fields={{
        name: 1,
        api: 1,
        view: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Api'}}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          placeholder: 'API',
          field: 'api',
          type: 'text',
        },
        {
          placeholder: 'View',
          field: 'view',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const EditKpiApiForm = props => {
  return <KpiApiForm header={{title: 'Edit Api'}} mode="edit" {...props} />;
};
