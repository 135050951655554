import React from 'react';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import NestedTable from '../../../components/form/NestedTable';

export const AllocateOnInputs = props => {
  const {
    route: {params},
    navigation,
  } = props;
  console.log(params);
  const {row: employee, department, product} = params;
  const {post} = useAppStateContext();
  const onSubmit = values => {
    post({
      uri: '/allocate-on-inputs',
      props: values,
    }).then(() => {
      navigation.goBack();
    });
  };
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      header={{title: 'Allocate On Inputs'}}
      defaultValues={{employee, department, product}}
      layoutFields={[
        {
          field: 'allocations',
          nested: true,
          render: props => {
            return (
              <NestedTable
                {...props}
                fields={[
                  {
                    header: 'Object',
                    field: 'object',
                    placeholder: 'Object',
                    type: 'autoComplete',
                    api: '/types',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                  {
                    header: 'Percentage',
                    field: 'percentage',
                    placeholder: 'Percentage',
                    type: 'number',
                  },
                  {
                    header: 'From Date',
                    field: 'from_date',
                    placeholder: 'From Date',
                    type: 'date',
                  },
                  {
                    header: 'To Date',
                    field: 'to_date',
                    placeholder: 'To Date',
                    type: 'date',
                  },
                ]}
              />
            );
          },
        },
      ]}
    />
  );
};
