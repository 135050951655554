import {getZeroTimeDate} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const JobOpening = props => {
  const {
    route: {params},
  } = props;
  const {jobId} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/jobopenings',
    eventSourceId: ['HrMrfApproved'],
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      api={`/jobopenings/${jobId}`}
      fields={{
        position_title: {
          name: 1,
        },
        department: {
          name: 1,
        },
        position_type: 1,
        no_of_positions: 1,
        experience_required: {
          experience: 1,
        },
        job_created_on: 1,
        priority: 1,
        hiring_manager: {
          name: 1,
        },
        job_description: 1,
        status: 1,
        must_have_skills: {
          name: 1,
        },
        good_to_have_skills: {
          name: 1,
        },
        requested_by: {
          name: 1,
        },
        educational_requirement: 1,
        salary_offered: 1,
        not_visible_in_referrals: 1,
        nature_of_request: 1,
        description: 1,
        illustration: 1,
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'position_title',
          label: 'Designation',
          placeholder: 'Designation',
          size: 6,
          api: '/designations',
          suggestionField: 'name',
          required: true,
          valueField: 'name',
        },
        {
          type: 'autoComplete',
          field: 'department',
          label: 'Department',
          api: '/departments',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
        },
        {
          type: 'multiAutoComplete',
          field: 'must_have_skills',
          label: 'Must Have Skills',
          api: '/skillmetrics',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
          required: true,
          fields: {
            name: 1,
            color: 1,
          },
        },
        {
          type: 'multiAutoComplete',
          field: 'good_to_have_skills',
          label: 'Good to Have Skills',
          api: '/skillmetrics',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
          fields: {
            name: 1,
            color: 1,
          },
        },
        {
          placeholder: 'Experience Range',
          field: 'experience_required',
          label: 'Experience Range',
          type: 'autoComplete',
          api: '/employeeexperiences',
          suggestionField: 'experience',
          valueField: 'experience',
          size: 6,
          required: true,
        },
        {
          label: 'Minimum Eductional Requirement',
          type: 'text',
          field: 'educational_requirement',
          size: 6,
          required: true,
        },
        {
          field: 'no_of_positions',
          type: 'number',
          label: 'Position Count',
          placeholder: 'Position Count',
          size: 6,
        },
        {
          field: 'requested_by',
          type: 'autoComplete',
          label: 'Requested By',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondaySuggestionField: 'official_email_id',
          size: 6,
          valueField: 'name',
        },
        {
          type: 'autoComplete',
          field: 'nature_of_request',
          label: 'Nature Of Request',
          options: ['Addition', 'Replacement'],
          size: 6,
          required: true,
        },
        {
          type: 'autoComplete',
          field: 'priority',
          label: 'Priority',
          options: ['Urgent', 'High', 'Medium', 'Low'],
          size: 6,
          required: true,
        },
        {
          type: 'textArea',
          field: 'description',
          label: 'Description',
        },
        {
          field: 'not_visible_in_referrals',
          type: 'checkbox',
          label: 'Hide from refferrals ',
          size: 6,
        },
        {
          type: 'number',
          field: 'salary_offered',
          label: 'Salary Budget',
          size: 6,
        },
        {
          type: 'autoComplete',
          label: 'Owner',
          field: 'hiring_manager',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondaySuggestionField: 'official_email_id',
          size: 6,
          valueField: 'name',
        },
        {
          field: 'illustration',
          type: 'file',
        },
      ]}
      {...props}
    />
  );
};

export const CreateJobOpening = props => {
  const {
    route: {params},
  } = props;

  const {mrf: mrfId} = params || {};

  const {user = {}} = useAppStateContext();
  const {employee = {}} = user;
  const {data: mrfData} = useFetchFormData({
    api: `/manpowerrequisition/${mrfId}`,
  });
  if (!mrfData) return null;

  const {
    _id: mrf,
    position_title,
    priority,
    no_of_positions,
    position_type,
    description,
    department,
    hiring_manager,
    skill_required,
    tool,
    experience_required,
    salary_offered,
  } = mrfData;
  const defaultValues = {
    mrf,
    position_title,
    position_type,
    no_of_positions,
    experience_required,
    priority,
    hiring_manager,
    department,
    salary_offered,
    skill_required,
    tool,
    description,
    job_created_on: getZeroTimeDate(new Date()),
    status: 'Active',
    organization: employee?.organization,
    product: employee?.product,
  };
  return (
    <JobOpening
      header="Add Job Opening"
      defaultValues={defaultValues}
      {...props}
    />
  );
};

export const EditJobOpening = props => {
  return <JobOpening mode="edit" header="Job Details" {...props} />;
};

export const NonEditableJobOpening = props => {
  return (
    <JobOpening
      mode="edit"
      header="Job Details"
      readOnly={true}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'position_title',
          label: 'Designation',
          placeholder: 'Designation',
          size: 6,
          api: '/designations',
          suggestionField: 'name',
          required: true,
          valueField: 'name',
        },
        {
          type: 'autoComplete',
          field: 'department',
          label: 'Department',
          api: '/departments',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
        },
        {
          type: 'multiAutoComplete',
          field: 'must_have_skills',
          label: 'Must Have Skills',
          api: '/skillmetrics',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
          required: true,
          fields: {
            name: 1,
            color: 1,
          },
        },
        {
          type: 'multiAutoComplete',
          field: 'good_to_have_skills',
          label: 'Good to Have Skills',
          api: '/skillmetrics',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
          fields: {
            name: 1,
            color: 1,
          },
        },
        {
          placeholder: 'Experience Range',
          field: 'experience_required',
          label: 'Experience Range',
          type: 'autoComplete',
          api: '/employeeexperiences',
          suggestionField: 'experience',
          valueField: 'experience',
          size: 6,
          required: true,
        },
        {
          label: 'Minimum Eductional Requirement',
          type: 'text',
          field: 'educational_requirement',
          size: 6,
          required: true,
        },
        {
          field: 'no_of_positions',
          type: 'number',
          label: 'Position Count',
          placeholder: 'Position Count',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'priority',
          label: 'Priority',
          options: ['Urgent', 'High', 'Medium', 'Low'],
          size: 6,
          required: true,
        },
        {
          type: 'autoComplete',
          label: 'Recruiter',
          field: 'hiring_manager',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondaySuggestionField: 'official_email_id',
          size: 6,
          valueField: 'name',
        },
        {
          type: 'textArea',
          field: 'description',
          label: 'Description',
        },
      ]}
      {...props}
    />
  );
};

export const AddJobOwner = props => {
  const {
    route: {params},
  } = props;
  const {jobId} = params || {};

  const {onSubmit} = useFormSubmit({
    uri: '/jobopenings',
    eventSourceId: ['HrMrfApproved'],
  });

  return (
    <Form
      header={'Add Job Owner'}
      onSubmit={onSubmit}
      submitAction="Save"
      mode="edit"
      api={`/jobopenings/${jobId}`}
      fields={{
        hiring_manager: {
          name: 1,
        },
      }}
      layoutFields={[
        {
          label: 'Owner',
          placeholder: 'Owner',
          field: 'hiring_manager',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
