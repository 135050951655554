import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useNavigation} from '@react-navigation/native';
import {useEditKpis} from '../controllers';
import {Button} from '../../../components/button/Button';

const KpiHeader = props => {
  const {navigation, params, fromMyView} = props;
  return (
    <TableHeader
      title="KPI"
      actions={[
        ...(!fromMyView
          ? [
              <Button
                text={'Select Active KPIs'}
                onPress={() => {
                  navigation.navigate(`kpi-selection-list`, {...params});
                }}
              />,
            ]
          : []),
      ]}
    />
  );
};

const KpiTable = props => {
  const {params} = props;
  const navigation = useNavigation();
  const editKpi = useEditKpis({...props, navigation});
  return (
    <Table
      api={
        params?.department
          ? `/departments/${params?.department?._id}/kpis`
          : `/myKpis`
      }
      renderHeader={_ => (
        <KpiHeader {...props} params={params} navigation={navigation} />
      )}
      onRowPress={editKpi}
      params={params}
      columns={[
        {
          type: 'text',
          field: 'kpi',
          header: `Main KPI's`,
          responsive: 'sm',
        },
        {
          type: {
            field: 'amount_type',
          },
          field: 'previous_value',
          header: 'Last Month',
          width: 200,
          align: 'right',
        },
        {
          type: {
            field: 'amount_type',
          },
          field: 'current_value',
          header: 'Current Value',
          width: 200,
          align: 'right',
          responsive: 'sm',
        },
      ]}
    />
  );
};

export default KpiTable;
