import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const RoleTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'roleRemoved',
  });

  return (
    <Table
      eventSourceId={['roleAdded','roleRemoved']}
      api={`/roles`}
      fields={{
        role: 1,
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Add Role"
              view="add-roles"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Role',
          field: 'role',
          type: 'text',
          width: 200,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-roles', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/roles/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default RoleTable;
