import React from 'react';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { useInvoke } from '../../../controllers/useInvoke';

export const KeyPillarFeaturesTable = props => {

  const {route: {params}, navigation} = props;
  const {filter} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyPillarFeature',
  });

  return (
    <Table
      eventSourceId={["keyPillar", "keyPillarFeature"]}
      api={`/keypillarfeatures`}
      fields={{
        title: 1,
        subTitle: 1,
        image: 1,
        description: {
          text: 1,
          color: 1,
        },
        index: 1,
      }}
      sort={{index: 1}}
      filter={filter}
      renderHeader={() => (
        <TableHeader
          title="Key Pillar Feature"
          actions={[
            <AddButton
              title="Add Key Pillar"
              view="add-key-pillar-feature"
              params={{keyPillar:filter?.keyPillar}}
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-key-pillar-feature', { row });
      }}
      columns={[
        {
          type: 'text',
          field: 'title',
          header: 'Title',
        },
        {
          type: 'text',
          field: 'subTitle',
          header: 'Sub Title',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-key-pillar-feature', { row });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deleteInvoke({
              uri: `/keypillarfeatures/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
