import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateEmployeeTeam = props => {
  const {
    route: {params},
  } = props;
  let {employeeIds} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/updateEmployeeTeam',
    eventSourceId: 'EmployeeTeam',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header="Assign Team"
      defaultValues={{
        employeeIds,
        hours: 9,
      }}
      layoutFields={[
        {
          placeholder: 'Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: `/employeeteams`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          placeholder: 'Date',
          required: true,
          size: 6,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          placeholder: 'Date',
          required: true,
          size: 6,
        },
        {
          label: 'Hours',
          placeholder: 'Hours',
          field: 'hours',
          type: 'number',
          required: true,
          size: 4,
        },
      ]}
      {...props}
    />
  );
};
