import React from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {ProjectEfficiencyTabPM, ProjectTabsPm} from '../../pmt/views/ProjectTabsForPM';
import {PendingProjectApprovalsTablePm} from '../../pmt/views/PendingProjectApprovals';
import {OrdersAndDeliveriesForTAndMTabForPM} from '../../order/views/OrdersAndDeliveriesForTAndM';
import {OrdersAndDeliveriesForFixedTabForPM} from '../../order/views/OrdersAndDeliveriesForFixed';
import {NewProjectTab} from './NewProjectTab';
import {ProjectEfficiencyTab} from '../../pmt/views/ProjectEfficiencyTab';

const ProjectGroup = createMenuNavigator();
export const organizationProjectNavigatorPM = props => {
  const {
    route: {params},
  } = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const {employee} = user;
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <ProjectGroup.Navigator
      // canAccess={canAccess}
      data-navigator-id="ProjectGroup"
      {...props}>
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledNew"
        name="New"
        props={{
          user: user,
          addOnParamFilter: {
            owner_id: employee?._id,
          },
          ...props,
        }}
        component={NewProjectTab}
        options={{
          label: 'New',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledUnassigned"
        name="Unassigned"
        component={ProjectTabsPm}
        props={{
          user: user,
          project_status: 'a',
          is_project_assign: {$in: [null, false]},
          tab: 'Unassigned',
          ...props,
        }}
        options={{
          label: 'Unassigned(Active)',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledAssigned"
        name="Assigned"
        component={ProjectTabsPm}
        props={{
          user: user,
          project_status: 'a',
          tab: 'Assigned',
          is_project_assign: true,
          addOnParamFilter: {
            owner_id: employee?._id,
          },
          ...props,
        }}
        options={{
          label: 'Assigned(Active)',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectHold"
        name="On-Hold"
        props={{project_status: 'h', tab: 'On-hold'}}
        component={ProjectTabsPm}
        options={{
          label: 'On-Hold',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectCompleted"
        name="Completed"
        props={{project_status: 'c', tab: 'Completed'}}
        component={ProjectTabsPm}
        options={{
          label: 'Closed',
        }}
      />
      <ProjectGroup.Group>
        <ProjectGroup.Screen
          data-resource-id="ProjectPendingApprovals"
          name="Pending Approvals"
          component={PendingProjectApprovalsTablePm}
          options={{
            label: 'Pending Approvals',
          }}
        />
      </ProjectGroup.Group>
      <ProjectGroup.Screen
        data-resource-id="OrderAndDeliveriesT&M"
        name="T&M"
        component={OrdersAndDeliveriesForTAndMTabForPM}
        options={{
          label: 'T&M Output',
        }}
        props={{user: user, ...props}}
      />
      <ProjectGroup.Screen
        data-resource-id="OrderAndDeliveriesFixed"
        name="Fixed"
        component={OrdersAndDeliveriesForFixedTabForPM}
        options={{
          label: 'Fixed Output',
        }}
        props={{user: user, ...props}}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectsEfficiencyReport"
        name="Project Billing Efficiency"
        component={ProjectEfficiencyTabPM}
        props={{user: user}}
        options={{
          label: 'Project Billing Efficiency',
        }}
      />
      {/* <ProjectGroup.Screen
          data-resource-id="OrderAndDeliveriesOverDuePayment"
          name="Overdue Payment"
          component={OverduePaymentReportTable}
          options={{
            label: 'Overdue Payment',
          }}
        /> */}
      {/* </ProjectGroup.Section> */}
    </ProjectGroup.Navigator>
  );
};
