import React from 'react';
import {Col, Text} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import DynamicFKRender from './DynamicFKRender';

const MultiDynamicFKRender = props => {
  const {row} = props;
  let {project_id, module_id, feature_id, source_id} = row || {};
  let {textStyle} = useComponentTheme('DynamicFkColumnStyles');

  let subText = '';
  if (feature_id?.feature && feature_id?._id != source_id?._id) {
    subText += feature_id.feature + '  -  ';
  }
  if (module_id?.module) {
    subText += module_id?.module + '  -  ';
  }
  if (project_id?.project) {
    subText += project_id?.project;
  }

  return (
    <Col>
      <DynamicFKRender {...props} />
      <Text title={subText} style={textStyle} numberOfLines={1}>
        {subText}
      </Text>
    </Col>
  );
};

export default MultiDynamicFKRender;
