import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

import {useFetchFormData} from '../../../controllers/useFetchData';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const AddCampaignContact = props => {
  const {route} = props;
  const {route: {params} = {}, header = 'Add Campaign Contact'} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaigncontacts',
    ...props,
  });
  const {row} = params;
  const {user, fetch} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Potentail Customer',
              label: 'Potential Customer',
              field: 'potential_customer',
              type: 'autoComplete',
              api: `/potentialcustomers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              onChangeValue: async (value, e, {setFieldValue}) => {
                if (value?._id) {
                  const {data: potentialCustomerData} = await fetch({
                    uri: `/potentialCustomers/${value?._id}`,
                    props: {
                      fields: {
                        campaign_type: {_id: 1, name: 1},
                        campaign_id: {_id: 1, name: 1},
                        employee_count: {_id: 1, number: 1},
                        organization_category: {_id: 1, name: 1},
                        geography_location: {_id: 1, name: 1},
                        lead_source: {_id: 1, name: 1},
                        industry: {_id: 1, name: 1},
                        country: {_id: 1, name: 1},
                        lead_score: 1,
                        website: 1,
                        email: 1,
                      },
                    },
                  });
                  setFieldValue('website', potentialCustomerData?.website);
                  setFieldValue(
                    'campaign_type',
                    potentialCustomerData?.campaign_type,
                  );
                  setFieldValue(
                    'campaign_id',
                    potentialCustomerData?.campaign_id,
                  );
                }
              },
            },
            {
              type: 'text',
              field: 'website',
              placeholder: 'Website',
              label: 'Website',
            },
            {
              label: 'Campaign Details',
              fields: [
                {
                  placeholder: 'Channel & Campaign',
                  label: 'Channel & Campaign',
                  field: 'campaign_type',
                  type: 'cascader',
                  cascades: [
                    {
                      label: 'Select Source',
                      field: 'campaign_type',
                      api: '/types/suggestionsForLead',
                      suggestionField: 'label',
                      valueField: 'label',
                    },
                    {
                      label: 'Campaign Value',
                      placeholder: 'Campaign Id',
                      field: 'campaign_id',
                      type: 'autoComplete',
                      api: '/sourceSuggestions',
                      suggestionField: 'name',
                      valueField: 'name',
                      params: ({values: {campaign_type} = {}}) => {
                        return {
                          source: campaign_type,
                        };
                      },
                      onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                        navigation,
                        params,
                      }),
                    },
                  ],
                },
              ],
            },
            {
              placeholder: 'Contact',
              label: 'Contact',
              field: 'contact_id',
              type: 'autoComplete',
              api: `/potentialcustomercontacts`,
              suggestionField: 'first_name',
              valueField: 'first_name',
              secondarySuggestionField: 'last_name',
              size: 6,
              filter: ({values}) => {
                return {
                  potential_customer: values?.potential_customer,
                };
              },
              onChangeValue: async (value, e, {setFieldValue}) => {
                if (value?._id) {
                  const {data: potentialCustomerData} = await fetch({
                    uri: `/potentialcustomercontacts/${value?._id}`,
                    props: {
                      fields: {
                        email: 1,
                        linkedin_url: 1,
                      },
                    },
                  });
                  setFieldValue('contact_email', potentialCustomerData?.email);
                  setFieldValue(
                    'contact_linkedIn_url',
                    potentialCustomerData?.linkedin_url,
                  );
                }
              },
            },
            {
              type: 'text',
              field: 'contact_email',
              placeholder: 'Contact Email',
              label: 'Contact Email',
            },
            {
              type: 'text',
              field: 'contact_linkedIn_url',
              placeholder: 'Contact LinkedIn',
              label: 'Contact LinkedIn',
            },
            // {
            //   type: 'date',
            //   field: 'connection_request_sent_on',
            //   placeholder: 'Request On',
            //   label: 'Request On',
            //   size: 6,
            // },
            // {
            //   type: 'date',
            //   field: 'connection_request_accepted_on',
            //   placeholder: 'Connected At',
            //   label: 'Connected At',
            //   size: 6,
            // },
            {
              type: 'date',
              field: 'lifecycle_start',
              placeholder: 'LifeCycle Start Date',
              label: 'LifeCycle Start Date',
              size: 6,
            },
            {
              type: 'date',
              field: 'lifecycle',
              placeholder: 'LifeCycle End Date',
              label: 'LifeCycle End Date',
              size: 6,
            },
          ],
        },
      ]}
    />
  );
};
