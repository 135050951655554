import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const SkillTypeForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/skillTypes',
    eventSourceId: 'SkillTypes',
  });

  const {
    route: {params},
  } = props;
  const {skill_type} = params;

  return (
    <Form
      api={`/skillTypes/${skill_type?._id}`}
      fields={{
        name: 1,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Skill Type',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddSkillTypeForm = props => {
  return <SkillTypeForm header={'Add Skill Type'} {...props} />;
};

export const EditSkillTypeForm = props => {
  return (
    <SkillTypeForm
      header={'Edit Skill Type'}
      mode="edit"
      fields={{
        name: 1,
      }}
      {...props}
    />
  );
};
