import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const EditPlanProjectDetailsForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/ordermilestones',
    eventSourceId: 'Task',
  });

  return (
    <Form
      header={{
        title: 'Edit Project Details',
        secondaryTitle: row?.order_milestone_no,
      }}
      onSubmit={onSubmit}
      onBeforeSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Unapproved',
          },
        };
      }}
      mode="edit"
      api={`/ordermilestones/${row?._id}`}
      submitAction="Save"
      layoutFields={[
        {
          label: 'M.No.',
          placeholder: 'M.No.',
          field: 'order_milestone_no',
          type: 'text',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Description',
          placeholder: 'Description',
          field: 'description',
          type: 'textArea',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Start Date',
          placeholder: 'Start Date',
          field: 'start_date',
          type: 'date',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Update End Date',
          placeholder: 'Update End Date',
          field: 'update_end_date',
          type: 'date',
          size: 6,
        },
        {
          label: 'Est. Hrs.',
          placeholder: 'Est. Hrs.',
          field: 'est_man_hour',
          type: 'number',
          readOnly: true,
        },
      ]}
      {...props}
    />
  );
};
