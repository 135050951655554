import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {PreSalesTabView, PreSalesTabViewForSolCon} from './PreSalesTabView';
import {
  RPSTableTabView,
  RPSTableTabViewForPreSales,
  RPSTableTabViewForPreSalesSolCon,
} from './RPSTableTabView';
import {CustomPlatformMergedTabsForSolCon} from '../../customPlatformNewView/view/customPlatformMergedTab';
import { ActiveLeadTabsForSolCon } from './LeadTableTabView';

export const PreSalesMenu = props => {
  const {route: {params} = {}} = props;

  const menus = [
    [
      {
        label: 'Custom Platform',
        view: <PreSalesTabView />,
      },
      {
        label: 'RPS',
        view: <RPSTableTabViewForPreSales />,
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

// export const PreSalesMenuForSolConsultant = props => {
//   const {route: {params} = {}} = props;

//   const menus = [
//     [
//       {
//         label: 'Custom Platform',
//         view: <PreSalesTabViewForSolCon />,
//       },
//       {
//         label: 'RPS',
//         view: <RPSTableTabViewForPreSalesSolCon />,
//       },
//     ],
//   ];

//   return <LeftMenuView {...props} params={params} menus={menus} />;
// };

export const PreSalesMenuForSolConsultant = props => {
  const {route: {params} = {}} = props;

  const menus = [
    [
      {
        label: 'Custom Platform',
        view: <CustomPlatformMergedTabsForSolCon />,
      },
      {
        label: 'Leads',
        view: <ActiveLeadTabsForSolCon />,
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
