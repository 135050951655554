import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AssignAsReplacement = props => {
  const {
    route: {params},
  } = props;
  const {row, formResign, replacement_id} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });
  let defaultValues = {};
  if (formResign) {
    const {_id, employee = {}, assignment = {}, type, ...rest} = row;
    const {_id: assignmentId, ...assignmentrest} = assignment;
    if (type == 'Bandwidth Release') {
      rest.bench_training_type = 'Project Shadow';
    } else {
      rest.bench_training_type = 'KT For Resignation';
    }
    defaultValues = {
      ...assignmentrest,
      ...rest,
      is_shadow: true,
      training_with_whom: employee,
      is_from_bench_resource: true,
      replacement_id,
      allow_allocation_more_than_100: true,
    };
  } else {
    defaultValues = {
      is_shadow: true,
      bench_training_type: formResign ? 'KT For Resignation' : 'Project Shadow',
      employee: {_id: row?._id, name: row.name},
      is_from_bench_resource: true,
    };
  }
  return (
    <Form
      defaultValues={{
        ...defaultValues,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Assign as Replacement"
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'bench_training_type',
          options: [
            {value: 'Project Shadow', label: 'Bandwidth Release'},
            {value: 'KT For Swapping', label: 'Swapping'},
            {value: 'KT For Resignation', label: 'Resignation'},
          ],
          visible: () => !formResign,
          required: true,
        },
        {
          placeholder: 'Releasing Bandwidth For',
          label: 'Releasing Bandwidth For',
          field: 'training_with_whom',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: ({values}) =>
            values &&
            values.bench_training_type &&
            values.bench_training_type === 'Project Shadow',
          size: 6,
          visible: ({values}) =>
            values &&
            values.bench_training_type &&
            values.bench_training_type === 'Project Shadow',
        },
        {
          placeholder: 'Swapping For',
          label: 'Swapping For',
          field: 'training_with_whom',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: ({values}) =>
            values &&
            values.bench_training_type &&
            values.bench_training_type === 'KT For Swapping',
          size: 6,
          visible: ({values}) =>
            values &&
            values.bench_training_type &&
            values.bench_training_type === 'KT For Swapping',
        },
        {
          placeholder: 'Replacing Employee',
          label: 'Replacing Employee',
          field: formResign ? 'employee' : 'training_with_whom',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: ({values}) =>
            values &&
            values.bench_training_type &&
            values.bench_training_type === 'KT For Resignation',
          size: 6,
          visible: ({values}) =>
            values &&
            values.bench_training_type &&
            values.bench_training_type === 'KT For Resignation',
        },
        {
          placeholder: 'Project',
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/employeeassignments/projects`,
          suggestionField: 'project',
          valueField: 'project',
          secondarySuggestionField: 'order_number',
          required: true,
          filter: ({values}) => {
            return {
              employee: values?.training_with_whom?._id,
            };
          },
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue('order', value?.order);
            setFieldValue('skill_requirement', value?.skill_requirement);
            setFieldValue('milestone_id', value?.milestone_id);
            setFieldValue('order_assignment', value?.order_assignment);
            setFieldValue('resource_tool', value?.order_assignment);
            setFieldValue('tool', value?.order_assignment);
            setFieldValue('project_skill', value?.order_assignment);
            setFieldValue('resource_skill', value?.order_assignment);
            setFieldValue('to_date', value?.to_date);
            setFieldValue('from_date', getZeroTimeDate(new Date()));
          },
          size: 6,
        },
        {
          placeholder: 'Order',
          label: 'Order',
          field: 'order',
          type: 'autoComplete',
          api: `/orders`,
          suggestionField: 'order_number',
          valueField: 'order_number',
          required: true,
          readOnly: true,
          size: 6,
        },
        {
          placeholder: 'Hours',
          label: 'Hours',
          field: 'hours',
          type: 'number',
          size: 6,
        },

        {
          placeholder: 'Start Date',
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          size: 6,
        },
        {
          placeholder: 'End Date',
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          size: 6,
        },
        {
          label: 'Project Skill',
          placeholder: 'Project Skill',
          field: 'project_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          filter: {
            status: 'active',
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Project Tool',
          placeholder: 'Project Tool',
          field: 'tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Resource Skill',
          placeholder: 'Resource Skill',
          field: 'resource_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          filter: {
            status: 'active',
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Resource Tool',
          placeholder: 'Resource Tool',
          field: 'resource_tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'allow_allocation_more_than_100',
          type: 'switch',
          label: 'Allow more than 100% allocation',
        },
      ]}
      {...props}
    />
  );
};
