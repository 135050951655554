import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {employeeController} from '../controllers/employeeController';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {useState} from 'react';
import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Durations} from '../../order/views/components/Durations';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {UserAvatarGroupChip} from '../../../components/avatar/UserAvatar';

const RenderPIP = ({row = {}, styles = {}}) => {
  const [loading] = useState(false);
  const colors = useTheme('colors');

  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}>
            <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
              Initiate
            </Text>
          </View>
        </>
      )}
    </View>
  );
};
const RenderEndStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  if (!row || !row.pip_status) {
    return null;
  }
  const colors = useTheme('colors');

  const getColorAndTextStyle = () => {
    switch (row.pip_status) {
      case 'Resignation':
        return {color: colors.WARNING_HIGH, fontSize: 13};
      case 'Success':
        return {color: '#39e75f', fontSize: 13};
      case 'Terminate':
        return {color: colors.ERROR_HIGH, fontSize: 13};
      default:
        return null;
    }
  };

  const {color, fontSize} = getColorAndTextStyle();

  if (!color) {
    return null;
  }

  return (
    <>
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          color,
          overflow: 'hidden',
        }}>
        {row.pip_status}
      </Text>
      <Text
        style={{
          ...rowText,
          fontSize,
          color: colors.NEUTRAL_MEDIUM,
        }}>
        {moment(row.pip_end_on).format('DD MMM, YY')}
      </Text>
    </>
  );
};

const pipReasonRender = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, ACTION} = useTheme('fonts');
  return (
    <Text
      style={{
        ...ACTION,
        paddingLeft: 10,
      }}>
      {row?.pip_reason}
    </Text>
  );
};

const pipReasonCompleteRender = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, ACTION} = useTheme('fonts');

  return (
    <>
      <Text
        style={{
          ...ACTION,
          paddingLeft: 10,
        }}>
        {row?.pip_reason}
      </Text>
      {row?.pip_extension_date ? (
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.pip_marked_on).format('DD MMM, YY')} -
          {moment(row?.pip_extension_date).format('DD MMM, YY')}
        </Text>
      ) : (
        <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.pip_marked_on).format('DD MMM, YY')} -
          {moment(row?.pip_estimated_end_date).format('DD MMM, YY')}
        </Text>
      )}
    </>
  );
};
const pipExtendRender = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, ACTION} = useTheme('fonts');

  return (
    <>
      <Text
        style={{
          ...ACTION,
          paddingLeft: 10,
        }}>
        {row?.pip_extended_reason}
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_MEDIUM,
          paddingLeft: 10,
        }}>
        {moment(row?.pip_extension_date).format('DD MMM, YY')}
      </Text>
    </>
  );
};

const RenderSkills = props => {
  const {row} = props;
  return (
    <PrimaryColumnCard
      primaryTitle={row?.resource_language?.name}
      items={[{value: row?.seconday_resource_language?.name}]}
    />
  );
};

const RenderProject = props => {
  const {row} = props;
  const {pip_project} = row;
  let projectType = '';
  if (pip_project?.projecttype == 'f') {
    projectType = 'Fixed';
  }
  if (pip_project?.projecttype == 'tm') {
    projectType = 'T&M';
  }
  if (pip_project?.projecttype == 'i') {
    projectType = 'Internal';
  }
  if (pip_project?.projecttype == 'tr') {
    projectType = 'Training';
  }

  return (
    <>
      <Tag value={row?.pip_project?.project} />
      <PrimaryColumnCard
        items={[
          {
            value: projectType,
          },
          {
            value: row?.working_hours && `${row?.working_hours}h/day`,
          },
        ]}
      />
    </>
  );
};

const PIPReasonRender = {
  header: 'Reason',
  render: pipReasonRender,
  width: 200,
};

const PIPExtendedRender = {
  header: 'Extension Reason & Date',
  render: pipExtendRender,
};

const PIPCompletedRender = {
  header: 'Status & End Date',
  render: RenderEndStatus,
};
const PIPCompletedReasonRender = {
  header: 'Reason (start-est end)',
  render: pipReasonCompleteRender,
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'pip_next_action_description', type: 'text'},
        {
          field: 'pip_next_action_date',
          type: 'date',
        },
      ]}
    />
  );
};

const RESOURCE = {
  header: 'Employee',
  minWidth: 240,
  render: ({row}) => {
    return (
      <UserAvatarWithNameAndEmail
        name={row?.name}
        color={row?.color}
        values={[
          {value: row?.official_email_id},
          {value: `${row?.experience?.toFixed(1)} yr`, width: 100},
        ]}
      />
    );
  },
};

const EXPERIENCE = {
  header: 'Exp',
  type: 'number',
  render: ({row, styles: {rowText = {}}}) => {
    return (
      <Text style={{...rowText}}>{`${row?.experience?.toFixed(1)} yr`}</Text>
    );
  },
  width: 100,
};

const TEAM = {
  header: 'Team',
  field: 'employee_team.name',
  type: 'colorTag',
  width: 120,
};

const SKILLS = {
  header: 'Skills',
  render: RenderSkills,
  width: 120,
};

const PROJECT = {
  header: 'Project/Allocation',
  render: RenderProject,
  width: 180,
};

const MENTOR = {
  header: 'Mentor',
  field: 'pip_mentor',
  type: 'userAvatar',
  align: 'center',
  width: 80,
};

const PIPOWNER = {
  header: 'Owner',
  field: 'pip_owner',
  type: 'userAvatar',
  align: 'center',
  width: 80,
};

const IDENTIFIEDON = {
  header: 'Since',
  field: 'pip_identified_on',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
  width: 120,
};

const DURATION = {
  header: 'Duration',
  align: 'center',
  render: ({row}) => {
    const start = moment(row?.pip_estimated_end_date);
    const end = moment(row?.pip_extension_date);
    return Durations({
      fromDate: row?.pip_marked_on,
      toDate: row?.pip_estimated_end_date,
      subTitle:
        row?.pip_extension_date &&
        `Extended by ${end.diff(start, 'days')} days`,
    });
  },
  width: 180,
};

const DESIGNATION = {
  header: 'Designation',
  width: 180,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.designation?.name}
        // items={[{value: row?.employee_team?.name}]}
      />
    );
  },
};

export const ActivePIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {unmarkPip, extendPip, updatePipMentor} = employeeController(props);
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/active-pip`}
      columns={[
        RESOURCE,
        DESIGNATION,
        SKILLS,
        PIPReasonRender,
        MENTOR,
        DURATION,
        {
          header: 'Feedback',
          align: 'center',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {row?.latest_feedback_overall_score || 0}/40
              </Text>
            );
          },
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
              show_feedback_only: true,
            });
          },
        },
        PIPOWNER,
        // {
        //   header: 'Next Action',
        //   render: NextTaskRender,
        // },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Update Mentor',
          onPress: ({row}) => {
            updatePipMentor({row});
          },
        },
        {
          title: 'Extend PIP',
          onPress: extendPip,
        },
        {
          title: 'Finish PIP',
          onPress: unmarkPip,
        },

        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
              show_feedback_only: true,
            });
          },
        },
        {
          title: 'Next Action',
          onPress: ({row}) => {
            props.navigation.navigate('pip-next-action-form', {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const PotentialPIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {unmarkPip, extendPip, updatePipMentor, markPip} =
    employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/potential-pip`}
      columns={[
        IDENTIFIEDON,
        RESOURCE,
        TEAM,
        DESIGNATION,
        SKILLS,
        {
          header: 'Reporting To',
          render: ({row}) => {
            return (
              <UserAvatarGroupChip value={row?.reporting_to} />
            );
          },
          width:180,
        },
        PIPOWNER,
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => <RenderPIP row={row} styles={styles} />,
          onPress: markPip,
          width: 80,
        },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Remove',
          variant: 'success',
          confirm: {
            title: 'Remove Potential PIP',
            message:
              'Are you sure you want to remove this resource from potential PIP?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/employees/${row?._id}`,
              props: {
                potential_pip: null,
              },
            });
          },
        },
      ]}
    />
  );
};

export const SelfActivePIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const {unmarkPip, extendPip, updatePipMentor} = employeeController(props);
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/active-pip/trainer`}
      filter={{
        pip_mentor: employeeId,
      }}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Skills',
          render: RenderSkills,
        },
        {
          header: 'Project',
          render: RenderProject,
        },
        PIPReasonRender,
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
            });
          },
        },
      ]}
    />
  );
};

export const ExtendedPIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {unmarkPip, updatePipMentor, extendPip} = employeeController(props);
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/extended-pip`}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Skills',
          render: RenderSkills,
          // width: 150
        },
        {
          header: 'Project',
          render: RenderProject,
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.pip_mentor?.name}
                color={row?.pip_mentor?.color}
                official_email_id={row?.pip_mentor?.official_email_id}
              />
            );
          },
          width: 250,
        },
        PIPReasonRender,
        PIPExtendedRender,
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Update Mentor',
          onPress: ({row}) => {
            updatePipMentor({row});
          },
        },
        {
          title: 'Extend PIP',
          onPress: extendPip,
        },
        {
          title: 'Finish PIP',
          onPress: unmarkPip,
        },

        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
              show_feedback_only: true,
            });
          },
        },
      ]}
    />
  );
};

export const SelfExtendedPIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {unmarkPip, updatePipMentor} = employeeController(props);
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/extended-pip/trainer`}
      filter={{
        pip_mentor: employeeId,
      }}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          header: 'Skills',
          render: RenderSkills,
          // width: 150
        },
        {
          header: 'Project',
          render: RenderProject,
        },
        PIPReasonRender,
        PIPExtendedRender,
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
            });
          },
        },
      ]}
    />
  );
};

export const CompletedPIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
    groupBy,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {unmarkPip, extendPip} = employeeController(props);
  return (
    <Table
      params={params}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/completed-pip`}
      columns={[
        RESOURCE,
        DESIGNATION,
        SKILLS,
        MENTOR,
        DURATION,
        {
          header: 'Status',
          width: 180,
          render: ({row}) => {
            const {pip_status, pip_marked_on, pip_end_on} = row;
            const start = pip_marked_on && moment(pip_marked_on);
            const end = pip_end_on && moment(pip_end_on);
            if (pip_status == 'Terminate' || pip_status == 'Resignation')
              return (
                <>
                  <Tag value="Unsuccessful" color="#FFE2E0" />
                  <PrimaryColumnCard
                    items={[
                      {
                        value:
                          pip_end_on && moment(pip_end_on).format('DD MMM YY'),
                      },
                      // {value: `${end.diff(start, 'days')} days`},
                    ]}
                  />
                </>
              );
            if (pip_status == 'Success')
              return (
                <>
                  <Tag value="Successful" color="#E0FFE8" />
                  <PrimaryColumnCard
                    items={[
                      {
                        value:
                          pip_end_on && moment(pip_end_on).format('DD MMM YY'),
                      },
                      // {value: `${end.diff(start, 'days')} days`},
                    ]}
                  />
                </>
              );
          },
        },
        // {
        //   header: 'Feedback',
        //   align: 'center',
        //   render: ({row, styles}) => {
        //     return (
        //       <Text style={{...styles.rowText}}>
        //         {row?.latest_feedback_overall_score || 0}/40
        //       </Text>
        //     );
        //   },
        //   onPress: ({row}) => {
        //     props.navigation.navigate('pip-training-feedbacks', {
        //       ...props.route.params,
        //       employee_training: row,
        //       show_feedback_only: true,
        //     });
        //   },
        // },
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
              show_feedback_only: true,
            });
          },
        },
      ]}
    />
  );
};

export const SelfCompletedPIPEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {unmarkPip, extendPip} = employeeController(props);
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/completed-pip/trainer`}
      filter={{
        pip_mentor: employeeId,
      }}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          toFixed: 2,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          field: 'designation.name',
          header: 'Designation',
          type: 'text',
        },
        {
          field: 'primary_practice.name',
          header: 'Practice',
          type: 'colorTag',
        },
        {
          field: 'pip_project.project',
          header: 'Project',
          type: 'colorTag',
        },
        {
          field: 'pip_marked_on',
          header: 'Start Date',
          type: 'date',
        },
        {
          field: 'pip_extension_date',
          header: 'Est End Date',
          type: 'date',
        },
        {
          field: 'pip_end_on',
          header: 'End Date',
          type: 'date',
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.pip_mentor?.name}
                color={row?.pip_mentor?.color}
                official_email_id={row?.pip_mentor?.official_email_id}
              />
            );
          },
          width: 250,
        },
        PIPCompletedRender,
      ]}
      {...props}
      moreActions={() => [
        {
          title: 'Feedback',
          onPress: ({row}) => {
            props.navigation.navigate('pip-training-feedbacks', {
              ...props.route.params,
              employee_training: row,
              show_feedback_only: true,
            });
          },
        },
      ]}
    />
  );
};
