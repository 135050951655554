// Show total communications which is verified and completed - Parveen 01-2-2024
import React from 'react';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';
import {useFilter} from '../../../controllers/useFilter';
import {leadControllers} from '../controllers/leadControllers';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {RenderLinkedInUrl, RenderName} from './CustomRendersCollection';

export const CampaignActionsAnalytics = props => {
  const {
    route: {params: {_id, addOnFilter: _addOnFilter} = {}},
    navigation,
  } = props;
  let addOnFilter = {
    completedOn: {$exists: true},
    verified: true,
  };
  if (_addOnFilter) {
    addOnFilter = {...addOnFilter, ..._addOnFilter};
  }

  const {searchValue, onChangeFilter} = useFilter({});
  const {data = [], ...restFetchProps} = useFetchData({
    api: '/campaignactions',
    filter: addOnFilter,
    fields: {
      _id: 1,
      name: 1,
      body: 1,
      sequence_display: 1,
      action: 1,
      personalize_body: 1,
      contact_id: {
        email: 1,
        name: 1,
        first_name: 1,
        last_name: 1,
        linkedin_url: 1,
        designation: 1,
      },
      potential_customer: {
        name: 1,
        website: 1,
        country: {name: 1, code: 1},
        website_summary: 1,
        industry: {name: 1},
      },
      status: 1,
      completedOn: 1,
      personalize_subject: 1,
      subject: 1,
      campaign_id: {name: 1},
      value_proposition_id: {name: 1},
      positive_response_on: 1,
      email_unsubscribe_on: 1,
      campaign_contact_id: 1,
    },
    sort: {completedOn: -1},
    onChangeFilter: onChangeFilter,
    search: searchValue,
    searchFields: ['prospect_website_domain', 'prospect_name', 'contact_name'],
  });

  const {
    markAsPositiveResponse,
    makeLeadFromCampaignContact,
    makeUqlFromCampaignContact,
    markAsUnsubscribe,
    editCampaignContactProspect,
  } = leadControllers({
    ...props,
  });
  const {editCampaignContactMessage} = messageControllers(props);
  return (
    <Table
      {...props}
      renderHeader={() => (
        <TableHeader
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
          ]}
        />
      )}
      onRowPress={({row}) => {
        navigation.navigate('action-summary-form', {
          ...row,
          data,
        });
      }}
      data={data}
      {...restFetchProps}
      columns={[
        {
          type: 'text',
          render: RenderLinkedInUrl,
          header: 'Contact Name',
          width: 200,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 200,
        },
        {
          type: 'text',
          field: 'sequence_display',
          header: 'Sequence',
          width: 150,
        },
        {
          type: 'text',
          field: 'value_proposition_id.name',
          header: 'Value Proposition',
          width: 200,
        },
        {
          type: 'date',
          field: 'completedOn',
          header: 'Completed On',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editCampaignContactMessage,
        },
        {
          title: 'Edit Contact',
          onPress: ({row}) => {
            navigation.navigate('edit-potential-customer', row);
          },
        },
        {
          title: 'View prospect',
          onPress: editCampaignContactProspect,
        },
        {
          title: 'Mark as positive response',
          onPress: markAsPositiveResponse,
        },
        {
          title: 'Update Contact Status',
          onPress: ({row}) => {
            navigation.navigate('update-status', {
              _id: row?.campaign_contact_id,
            });
          },
        },
        {
          title: 'Move to QL',
          onPress: makeLeadFromCampaignContact,
        },
        {
          title: 'Move to Lead',
          onPress: makeUqlFromCampaignContact,
        },
        {
          title: 'View Communication',
          onPress: ({row}) => {
            navigation.navigate('show-communications', {
              addOnFilter: {
                contact_id: row?.contact_id?._id,
              },
              hide_contact_detail: true,
            });
          },
        },
        {
          title: 'Mark as Unsubscribe',
          visible: ({row}) => {
            const {email_unsubscribe_on} = row || {};
            return !email_unsubscribe_on;
          },
          onPress: markAsUnsubscribe,
        },
      ]}
    />
  );
};
