import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import NestedTable from '../../../components/form/NestedTable';
import { useTheme } from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';

const ProjectAuditComputation = (fetch) => ({
  'set audit parameters' : {
    compute : async value => {
      const {
        project
      } = value;
      const response = {}
      const auditScore = [];
      if(project){
        const { data: projectData } = await fetch({
          uri: '/projects',
          props: {
            filter: {
              _id: project,
            },
            fields: {
              _id : 1,
              new_project_sub_type: {_id : 1 ,project_sub_type : 1 },
            },
            only: true,
          },
        });
        if(projectData?.new_project_sub_type){
          const { data: auditParameterData } = await fetch({
            uri: '/auditparameters',
            props: {
              filter: {
                project_type: projectData?.new_project_sub_type,
                status : "Active"
              },
              fields: {
                name: 1,
                max_score : 1
              },
            },
          });
          for(const param of auditParameterData){
            auditScore.push({
              audit_parameter : {...param}
            })
          }
        }
        response["audit_score"] = auditScore
      }
      return response;
    },
    multi: true,
    dependencies: ['audit_date', 'project'],
  },
  'set audit Score' : {
    compute : async value => {
      const {
        audit_score = []
      } = value;
      const response = {};
      let totalScore = 0;
      let maxScore = 0;
      for(const auditscore of audit_score){
          const {audit_parameter , score = 0} = auditscore;
          const {max_score = 0} = audit_parameter
          totalScore = totalScore + score;
          maxScore = maxScore + max_score;
      }
      response['total_score'] = totalScore;
      response['max_score'] = maxScore;
      return response;
    },
    multi: true,
    dependencies: ['audit_score'],
  }
})

export const PlanProjectAuditForm = props => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'performance-report',
    uri: '/projectaudits',
    ...props,
  });
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {row = {}, fromHistory} = params;
  const colors = useTheme('colors');
  const {user, fetch} = useAppStateContext();
  const {employee} = user;
  return (
    <Form
      header={{title: 'Project Audit', secondaryTitle: row?.project}}
      computations={{ ...ProjectAuditComputation(fetch) }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project: row?._id,
        auditer: employee?._id,
      }}
      layoutFields={[
        {
          label: 'Audit Date',
          type: 'date',
          field: 'audit_date',
          required: true,
          size : 6
        },
        {
          label: 'Next Audit Date',
          type: 'date',
          field: 'next_audit_date',
          size : 6
        },
        {
          type: 'number',
          label: 'Total Score',
          field: 'total_score',
          size : 6,
          readOnly : true
        },
        {
          type: 'number',
          label: 'Max Score',
          field: 'max_score',
          size : 6,
          readOnly : true
        },
        {
          type: 'textArea',
          label: 'Comments',
          field: 'audit_comments',
        },
        {
          label: 'Audit Parameter',
          fields: [
            {
              field: 'audit_score',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    skipAdd={true}
                    {...props}
                    fields={[
                      {
                        label: 'Parameter',
                        field: 'audit_parameter',
                        type: 'autoComplete',
                        api: '/auditparameters',
                        suggestionField: 'name',
                        valueField: 'name',
                        readOnly : true,
                        size : 5
                      },
                      {
                        render: (_, {values}) => {
                          const {audit_parameter} = values
                          return (
                            <Text
                              style={{
                                fontSize: 14,
                                color: colors.INFORMATION_HIGH,
                              }}>
                              {`Please give score b/w 0 to  ${
                                audit_parameter?.max_score || ''
                              } `}
                            </Text>
                          );
                        },
                        size : 7
                      },
                      {
                        label: 'Score',
                        field: 'score',
                        type: 'number',
                      },
                      {
                        label: 'Comment',
                        field: 'comment',
                        type: 'text',
                      },
                    ]}
                    columns={[
                      {
                        header: 'Audit Parameter',
                        field: 'audit_parameter.name',
                        type: 'text',
                      },
                      {
                        label: 'Max Score',
                        field: 'audit_parameter.max_score',
                        type: 'number',
                      },
                      {
                        label: 'Score',
                        field: 'score',
                        type: 'number',
                      },
                      {
                        label: 'Comment',
                        field: 'comment',
                        type: 'text',
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdatePlanProjectAuditForm = props => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'performance-report',
    uri: '/projectaudits',
    ...props,
  });
  const {
    route: {params = {}},
  } = props;
  let readOnly = false;
  const {row = {} ,fromHistory} = params;
  if(fromHistory){
    readOnly = true
  }
  return (
    <Form
      api={`/projectaudits/${row?._id}`}
      mode="edit"
      computations={{ ...ProjectAuditComputation(fetch) }}
      header={{title: 'Update Project Audit', secondaryTitle: row?.project}}
      onSubmit={onSubmit}
      readOnly = {readOnly}
      submitAction="Save"
      fields={{
        next_audit_date: 1,
        audit_date: 1,
        auditer: {name: 1},
        total_score : 1,
        max_score : 1,
        audit_comments : 1,
        audit_score : {
          audit_parameter : {name : 1, max_score : 1},
          score : 1,
          comment : 1
        }
      }}
      layoutFields={[
        {
          label: 'Audit Date',
          type: 'date',
          field: 'audit_date',
          required: true,
          size : 6
        },
        {
          label: 'Next Audit Date',
          type: 'date',
          field: 'next_audit_date',
          size : 6
        },
        {
          type: 'number',
          label: 'Total Score',
          field: 'total_score',
          size : 6,
          readOnly : true
        },
        {
          type: 'number',
          label: 'Max Score',
          field: 'max_score',
          size : 6,
          readOnly : true
        },
        {
          type: 'textArea',
          label: 'Comments',
          field: 'audit_comments',
        },
        {
          label: 'Audit Parameter',
          fields: [
            {
              field: 'audit_score',
              nested: true,
              render: props => {
                const {
                  form: { values = {} },
                } = props;
                return (
                  <NestedTable
                    skipAdd={true}
                    {...props}
                    fields={[
                      {
                        label: 'Parameter',
                        field: 'audit_parameter',
                        type: 'autoComplete',
                        api: '/auditparameters',
                        suggestionField: 'name',
                        valueField: 'name',
                        readOnly : true,
                        size : 5
                      },
                      {
                        render: (_, {values}) => {
                          const {audit_parameter} = values
                          return (
                            <Text
                              style={{
                                fontSize: 14,
                                color: colors.INFORMATION_HIGH,
                              }}>
                              {`Please give score b/w 0 to  ${
                                audit_parameter?.max_score || ''
                              } `}
                            </Text>
                          );
                        },
                        size : 7
                      },
                      {
                        label: 'Score',
                        field: 'score',
                        type: 'number',
                      },
                      {
                        label: 'Comment',
                        field: 'comment',
                        type: 'text',
                      },
                    ]}
                    columns={[
                      {
                        header: 'Audit Parameter',
                        field: 'audit_parameter.name',
                        type: 'text',
                      },
                      {
                        label: 'Max Score',
                        field: 'audit_parameter.max_score',
                        type: 'number',
                      },
                      {
                        label: 'Score',
                        field: 'score',
                        type: 'number',
                      },
                      {
                        label: 'Comment',
                        field: 'comment',
                        type: 'text',
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
