// Rajnish Trikaldarshi 22/12/2023 - Make CampaignProspectsEdit form to markVerify and drop the ptotential customer
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import React, {useState, useEffect, useCallback} from 'react';
import {leadControllers} from '../controllers/leadControllers';
import {MarkVerifiedActionStyle} from './theme';
import {RenderName} from './CustomRendersCollection';
import PreText from '../../../components/chat/Pretext';
import {OutlineButton} from '../../../components/button/OutlineButton';
import { Button } from '../../../components/button/Button';
import { RecursiveComponent } from './ViewAssistantResponse';

const CampaignProspectMarkVerifyForm = props => {
  let {data, potentialCustomer, onDataChange, params, campaign_id} =
    props || {};

  const [activeIdx, setActiveIdx] = useState(-1);
  const [activeItem, setActiveItem] = useState({});

  useEffect(() => {
    setActiveIdx(data.findIndex(item => item._id == potentialCustomer._id));
  }, [potentialCustomer]);

  useEffect(() => {
    setActiveItem(data[activeIdx]);
  }, [activeIdx, data]);

  const handleTogglePrevious = useCallback(() => {
    let desiredIdx = activeIdx - 1;
    if (desiredIdx >= 0) setActiveIdx(desiredIdx);
  }, [activeIdx]);

  const handleToggleNext = useCallback(() => {
    let desiredIdx = activeIdx + 1;
    if (desiredIdx < data.length) setActiveIdx(desiredIdx);
  }, [activeIdx]);

  const handleRemove = useCallback(() => {
    const updatedData = data.filter((_, index) => index !== activeIdx);
    onDataChange(updatedData);
    if (activeIdx >= updatedData.length - 1) {
      setActiveIdx(0);
    }
  }, [data, activeIdx]);

  const styles = useStyles(MarkVerifiedActionStyle);
  const {campaignProspectMarkDropped, campaignProspectMarkVerified} =
    leadControllers(props);

  const {
    containerStyle,
    headerStyle,
    lineStyle,
    summaryContainerStyle,
    dotStyle,
    subHeadingStyle,
    subHeaderTextStyle,
    subHeaderStyle,
    summaryHeadingStyle,
    summaryTextStyle,
    industryNameStyle,
    imageContainerStyle,
    caretLeft,
    caretRight,
  } = styles || {};

  if (data && data.length === 0) {
    return (
      <View>
        <Text style={headerStyle}>{params?.title} Prospects</Text>
        <View style={lineStyle} />
        <Text style={{...summaryTextStyle, marginTop: 12, textAlign: 'center'}}>
          No Data Found
        </Text>
      </View>
    );
  }

  return (
    <View style={{...containerStyle}}>
      <Text style={headerStyle}>{params?.title} Prospects</Text>
      <View style={lineStyle} />
      <View
        style={{
          flexDirection: 'row',
          padding: 12,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <TouchableOpacity
          onPress={handleTogglePrevious}
          style={{
            ...imageContainerStyle,
            marginLeft: 8,
          }}>
          <Image source={caretLeft} style={{height: 16, width: 16}} />
        </TouchableOpacity>
        <Text style={industryNameStyle}>
          {activeItem?.potential_customer?.name}
        </Text>
        <TouchableOpacity
          onPress={handleToggleNext}
          style={{
            ...imageContainerStyle,
            marginRight: 8,
          }}>
          <Image source={caretRight} style={{height: 16, width: 16}} />
        </TouchableOpacity>
      </View>
      <View style={lineStyle} />
      <View
        style={{
          flex: 1,
          padding: 20,
          overflow: 'hidden',
          backgroundColor: '#FAFAFA',
        }}>
        <View style={subHeadingStyle}>
          <View style={{...subHeaderStyle, backgroundColor: '#E0F5FF'}}>
            <Text style={subHeaderTextStyle}>
              {activeItem?.potential_customer?.industry?.name}
            </Text>
          </View>
          <View style={dotStyle} />
          <Text style={{...subHeaderTextStyle, color: '#007AFF'}}>
            <RenderName row={activeItem} />
          </Text>
        </View>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <ScrollView style={summaryContainerStyle}>
            <Text style={summaryHeadingStyle}>
              Summary of {activeItem?.potential_customer?.name} platform
            </Text>
            <Text style={summaryTextStyle}>
              {/* {PreText({
                text: activeItem?.potential_customer?.website_summary,
                isAnswer: true,
              })} */}
               <RecursiveComponent data={activeItem?.potential_customer?.website_summary} />
            </Text>
          </ScrollView>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 12,
        }}>
        <Row gap={10}>
          <OutlineButton
            text={'Drop'}
            onPress={() => {
              campaignProspectMarkDropped({
                activeItem,
                campaign_id,
                handleRemove,
              });
            }}
          />
          <Button
            text={'Mark Verified'}
            disabled={params?.title === 'Verified' ? true : false}
            onPress={() => {
              campaignProspectMarkVerified({
                potential_customer: activeItem?.potential_customer?._id,
                _id: campaign_id,
              });
              handleRemove();
            }}
          />
        </Row>
      </View>
    </View>
  );
};

export const CampaignProspectsMarkVerifyForm = props => {
  let {navigation, route: {params} = {}} = props || {};
  let {potential_customer, campaign_id, data: propData} = params || {};

  const [data, setData] = useState(propData);
  return (
    <CampaignProspectMarkVerifyForm
      data={data}
      onDataChange={data => setData(data)}
      potentialCustomer={potential_customer}
      navigation={navigation}
      campaign_id={campaign_id}
      params={params}
    />
  );
};
