import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddMAForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    uri: '/dailyincentives',
    eventSourceId: ['shiftallowance'],
  });

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{mannual_process_from_client: true, status: 'new'}}
      submitAction="Save"
      header={{
        title: 'Add MA',
      }}
      layoutFields={[
        {
          label: 'Resource',
          placeholder: 'Resource',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Project',
          placeholder: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          // filter: {
          //   project_status: 'a',
          // },
          suggestionField: 'project',
          valueField: 'project',
          required: true,
        },
        {
          label: 'Start Date',
          placeholder: 'Start Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 4,
        },
        {
          label: 'End Date',
          placeholder: 'End Date',
          field: 'to_date',
          type: 'date',
          required: true,
          size: 4,
        },
        {
          label: 'Allocation Hrs',
          placeholder: 'Allocation Hrs',
          field: 'allocation_hours',
          type: 'number',
          required: true,
          size: 4,
        },
        {
          label: 'Actual Amount',
          placeholder: 'Actual Amount',
          field: 'actual_ma',
          type: 'number',
          required: true,
        },
        {
          label: 'MA Amount',
          placeholder: 'MA Amount',
          field: 'amount',
          type: 'number',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
