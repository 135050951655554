import React from 'react';
import {Form} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';

export const StopNonProjectAllocation = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });

  return (
    <Form
      header={{title:"Stop Allocation",secondaryTitle:row?.employee?.name || ""}}
      api={`/employeeassignments/${row._id}`}
      fields={{
        hours: 1,
        percentage: 1,
        is_applicable_for_ma: 1,
        employee: {_id: 1, name: 1, color: 1},
        from_date: 1,
        to_date: 1,
        object: 1,
        product: 1,
        project_id: {_id: 1, project: 1},
        department: {_id: 1, name: 1},
        skill_metrics: {_id: 1, name: 1},
        project_skill: {_id: 1, name: 1},
        resource_skill: {_id: 1, name: 1},
        front_facing_resource: {_id: 1, name: 1},
        level_one_owner: {_id: 1, name: 1},
        level_two_owner: {_id: 1, name: 1},
        level_three_owner: {_id: 1, name: 1},
        order: {_id: 1, order_number: 1},
      }}
      mode="edit"
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'End Date',
          placeholder: 'End Date',
          field: 'to_date',
          type: 'date',
          size: 4,
        },
        
      ]}
      eventSourceId={['allocatedResource']}
      {...props}
    />
  );
};
