import {RecursiveRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {AddButton} from '../../../components/button/AddButton';
import {SearchFilter} from '../../../components/filter/Filters';
import {TextRenderer} from '../../../components/form/Renderers';
import {TableHeader} from '../../../components/table/Headers';
import {RecursiveTable} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';

const toolRender = ({params, navigation, fromHr}) => ({
  render: CountCellRender,
  header: 'Tools & Languages',
  align: 'right',
  count_field: 'tools.length',
  isNestedObject: true,
  responsive: 'sm',
  width: 150,
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`add-skill-tool`, {
      ...params,
      row,
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`tool-list`, {
      ...params,
      skill_metrics: row,
    });
  },
});

export const RenderSkill = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value=" + Skill"
      style={{...rowText, color: colors['ACCENT1_HIGH']}}
    />
  );
};

const SkillMetricsTable = props => {
  const {
    route: {params},
    navigation,
    fromHr,
    fromSkillLeader,
  } = props;
  const {onChangeFilter, searchValue} = useFilter({});
  const deleteSkill = useInvoke({
    method: 'put',
    eventSourceId: 'skillMetricsAdded',
  });
  const extraFilter = {};
  const {
    user: {employee},
  } = useAppStateContext();
  if (fromSkillLeader) {
    extraFilter['leader'] = employee?._id;
  }
  return (
    <RecursiveTable
      api={`/skill-metric`}
      eventSourceId={[
        'skillMetricsAdded',
        'EmployeeSkills',
        'SkillTopics',
        'StudyMaterials',
      ]}
      renderHeader={() => {
        return (
          <TableHeader
            title="Skill Matrix"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                placeholder="Search"
              />,
              <AddButton
                title="Skills"
                view="add-skill-metrics-new"
                params={params}
              />,
            ]}
          />
        );
      }}
      addOnFilter={{
        parent: {$eq: null},
        status: 'active',
        product: 'params?.product?',
        ...extraFilter,
      }}
      search={searchValue}
      searchFields="name"
      params={params}
      columns={[
        {
          field: 'name',
          header: 'Skill',
          render: RecursiveRender,
          indent: false,
        },
        {
          field: 'leader',
          type: 'userAvatar',
          header: 'Leader',
          width: 80,
        },
        // toolRender({params, navigation, fromHr}),
        // {
        //   render: CountCellRender,
        //   header: 'Topics',
        //   align: 'right',
        //   count_field: 'topic_count',
        //   responsive: 'sm',
        //   width: 120,
        //   onPlusPress: fromHr
        //     ? void 0
        //     : props => {
        //         const {row} = props;
        //         navigation.navigate(`add-skill-topic`, {
        //           ...params,
        //           skill_metrics: row,
        //         });
        //       },
        //   onPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`skill-topic-list`, {
        //       ...params,
        //       skill_metrics: row,
        //       label: row?.name,
        //     });
        //   },
        // },
        {
          render: CountCellRender,
          header: 'Tools & Languages',
          align: 'right',
          count_field: 'toolsCount',
          responsive: 'sm',
          width: 150,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`add-skill-tool`, {
              ...params,
              row,
            });
          },
          onPress: props => {
            const {row} = props;
            navigation.navigate(`tool-list`, {
              ...params,
              skill_metrics: row,
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Study Material',
          align: 'right',
          count_field: 'training_material_count',
          responsive: 'sm',
          width: 120,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`training-material-inline-list`, {
              ...params,
              skill_metrics: row,
              label: row?.name,
            });
          },
          onPress: props => {
            const {row} = props;
            navigation.navigate(`training-material-inline-list`, {
              ...params,
              skill_metrics: row,
              label: row?.name,
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Resources',
          align: 'right',
          count_field: 'resource_count',
          responsive: 'sm',
          width: 120,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`assign-resource-on-skill`, {
              ...params,
              skill_metrics: row,
            });
          },
          onPress: props => {
            const {row} = props;
            navigation.navigate(`employee-skills-overview`, {
              ...params,
              skill_metrics: row,
              label: row?.name,
            });
          },
        },
        {
          type: 'text',
          render: RenderSkill,
          onPress: ({row}) => {
            navigation.navigate('add-skill-metrics-new', {skill_metrics: row});
          },
          align: 'right',
          responsive: 'sm',
          width: 100,
        },
      ]}
      moreActions={[
        {
          title: 'Materials',
          onPress: ({row}) => {
            navigation.navigate('training-material-list', {skill_metrics: row});
          },
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-skill-metrics-new', {row});
          },
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteSkill({
              uri: '/skillMetrics/' + row?._id,
              props: {
                status: 'deleted',
              },
            });
          },
        },
      ]}
    />
  );
};

export default SkillMetricsTable;
