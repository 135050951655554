import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {ActiveIndustryTable} from './ActiveIndustryTable';
import {InActiveIndustryTable} from './InActiveIndustryTable';
import { ToolList } from './ToolList';

const tabs = {
  tool: {
    label: 'Tools',
    view: <ToolList />,
    eventSourceId: 'Industry',
    api: '/industry/active',
  },
};

export const ToolTabs = props => {
  return <TabView tabs={tabs} {...props} />;
};
