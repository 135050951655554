import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const FixedOrderAssignProjectForm = props => {
  const {route: {params} = {}} = props;
  const {row = {}} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/orders',
    eventSourceId: ['orderresourcerequests', 'fixed'],
  });

  return (
    <Form
      api={`/orders/${row._id}`}
      mode="edit"
      fields={{
        project: {project: 1},
      }}
      header={`Assign Project`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Project',
          field: 'project',
          type: 'multiAutoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
        },
      ]}
      {...props}
    />
  );
};

export const MilestoneAssignProjectForm = props => {
  const {route: {params} = {}} = props;
  const {row = {}, fixedOrder} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/ordermilestones',
  });

  return (
    <Form
      api={`/ordermilestones/${row._id}`}
      mode="edit"
      fields={{
        project: {project: 1},
      }}
      header={`Assign Project`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Project',
          field: 'project',
          filter: {
            _id: {$in: fixedOrder?.project?.map(item => item._id)},
          },
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
        },
      ]}
      {...props}
    />
  );
};
