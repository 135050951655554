import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Checkbox} from '@unthinkable/react-checkbox';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import NestedTable from '../../../components/form/NestedTable';

export const MarkGenericInitiativeCompleteForm = props => {
  const {route = {}} = props;
  const {params} = route;
  let {onSubmit} = useFormSubmit({
    uri: '/genericInitiatives',
    eventSourceId: 'reloadGenericInitiative',
    ...props,
  });

  return (
    <Form
      mode="edit"
      api={`/genericInitiatives/${params?.row?._id}`}
      fields={{
        task_list: {name: 1, is_completed: 1},
      }}
      onSubmit={onSubmit}
      header={'Complete Generic Initiative'}
      defaultValues={{
        status: 'Complete',
        completed_on: getZeroTimeDate(new Date()),
        ...data,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'task_list',
          label: 'Task List',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  type: 'text',
                  field: 'name',
                  label: 'Task',
                },
                {
                  width: 100,
                  field: 'is_completed',
                  type: 'checkbox',
                  label: 'Completed',
                },
              ]}
              columns={[
                {
                  type: 'text',
                  field: 'name',
                  header: 'Name',
                },
                {
                  width: 100,
                  render: ({row}) => {
                    return <Checkbox value={row?.is_completed} />;
                  },
                  field: 'is_completed',
                  header: 'Completed',
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};
