import {
  Image,
  Text,
  TouchableOpacity,
  Styled,
  Row,
  View,
} from '@unthinkable/react-core-components';

export const Label = Styled(Text).withConfig({
  shouldForwardProp: prop => !['disabled'].includes(prop),
})`
    font-size: 14px;
    font-family: Inter-Medium;
    line-height: 18px;
    color: #FFFFFF;
  ${props => props.styles?.text} 
  ${({styles, disabled}) =>
    disabled
      ? {
          color: '#A3A3A3',
          ...styles?.disabledText,
        }
      : {}}
      `;

export const StyledButton = Styled(TouchableOpacity)`
   cursor: pointer;
   padding-top: 7px;
   padding-bottom: 7px;
   padding-left: 28px;
   padding-right: 28px;
   border-radius: 6px;
   align-items: center;
   justify-content: center;
   background-color: #19191A;
   ${props => props.styles?.container}
   ${({styles, disabled}) =>
     disabled
       ? {
           backgroundColor: '#EEEEED',
           cursor: 'not-allowed',
           ...styles?.disabledContainer,
         }
       : {}}
    &:hover {
         ${({styles, disabled}) => {
           if (!disabled) {
             return {
               ...styles?.hoverContainer,
             };
           }
         }}
         .button-text {
            ${({styles, disabled}) => {
              if (!disabled) {
                return {
                  ...styles?.hoverText,
                };
              }
            }}
    }
`;

export const ContentContainer = Styled(Row).withConfig({
  shouldForwardProp: prop => !['loading'].includes(prop),
})`
 align-items: center;
 ${({loading}) => (loading ? {opacity: 0} : {})}
`;

export const LoaderAbsoluteContainer = Styled(View)`
position: absolute
`;

export const Icon = Styled(Image)`${({styles}) => styles?.iconStyle}`;

export const StyledIconButton = Styled(TouchableOpacity)`
  cursor: pointer;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  ${props => props.styles?.container}
  ${({styles, disabled}) =>
    disabled
      ? {
          cursor: 'not-allowed',
          ...styles?.disabledContainer,
        }
      : {}}
  &:hover {
    ${({styles, disabled}) =>
      disabled
        ? {}
        : {
            backgroundColor: '#F7F7F7',
            ...styles?.hoverContainer,
          }}

  }    
 `;
