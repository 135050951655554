import React, {useEffect} from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {AllCampaignContactList} from './CampaignContactsList';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {OutreachBatchTabView} from './OutreachBatchTabView';
import {InreachCampaignTabs} from './OtherCampaignTabView';
import {PaidCampaignTabView} from './PaidAdsCampaignTabView';
import {WebPageTable} from './WebPageTable';
import {PotentialCustomersHubspotLeadsTable} from '../outreachcampaign-views/PotentialCustomersHubspotLeadsTable';
import {OutreachTargetAudienceTabs} from '../outreachcampaign-views/OutreachTargetAudienceTabs';
import {OutreachCampaignTabs} from '../outreachcampaign-views/OutreachCampaignTabs';
import {useGlobalParams} from '@unthinkable/react-filters';

const CampaignMenu = createMenuNavigator();

const CampaignNavigator = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});

  const globalParams = useGlobalParams();

  useEffect(() => {
    navigation.setParams({
      ...globalParams,
    });
  }, []);

  const {fromNewNav} = params || {};
  let extraProps = {'data-navigator-id': 'CampaignGroup'};

  if (fromNewNav) {
    extraProps = {};
  }

  return (
    <CampaignMenu.Navigator
      canAccess={canAccess}
      {...extraProps}
      {...props}>
      <CampaignMenu.Section
        data-resource-id="Manual"
        options={{label: 'Manual'}}>
        <CampaignMenu.Screen
          data-resource-id="Outreachbatch"
          component={OutreachBatchTabView}
          options={{
            label: 'Outreach Campaigns',
          }}
        />
        <CampaignMenu.Screen
          data-resource-id="InreachCampaign"
          component={InreachCampaignTabs}
          options={{
            label: 'Inreach Campaigns',
          }}
        />
        {/* <CampaignMenu.Screen
          data-resource-id="PaidAddCampaign"
          component={PaidCampaignTabView}
          options={{
            label: 'Paid Ad Campaigns',
          }}
        /> */}
        <CampaignMenu.Screen
          data-resource-id="WebCampaign"
          component={WebPageTable}
          options={{
            label: 'Web Campaigns',
          }}
        />
        {/* <CampaignMenu.Screen
          data-resource-id="CampaignContacts"
          component={AllCampaignContactList}
          options={{
            label: 'All Campaign Contacts',
          }}
        /> */}
        <CampaignMenu.Screen
          data-resource-id="AllHubSpotContacts"
          component={PotentialCustomersHubspotLeadsTable}
          options={{
            label: 'All HubSpot Contacts',
          }}
        />
        <CampaignMenu.Screen
          data-resource-id="Performance"
          options={{
            label: 'Performance',
            onPress: () => {
              navigation.navigate('performance', {...props.route.params});
            },
          }}
        />
      </CampaignMenu.Section>
      <CampaignMenu.Section options={{label: 'TA Auto'}}>
        <CampaignMenu.Screen
          data-resource-id="BuyerPersona"
          component={OutreachTargetAudienceTabs}
          options={{
            label: 'Target Audience Auto',
          }}
        />
        <CampaignMenu.Screen
          data-resource-id="Outreachbatch"
          options={{
            label: 'Outreach Campaigns Auto', //actions given in the tab itself
            onPress: () => {
              navigation.navigate('outreach-campaign-menu', {
                ...params,
              });
            },
          }}
        />
        <CampaignMenu.Screen
          data-resource-id="Outreachbatch"
          options={{
            label: 'Unlock Requests',
            onPress: () => {
              navigation.navigate('unlock-requests', {...params});
            },
          }}
        />
      </CampaignMenu.Section>
      <CampaignMenu.Screen
        data-resource-id="Outreachbatch"
        component={OutreachCampaignTabs}
        options={{
          label: 'Campaigns',
        }}
      />
      <CampaignMenu.Screen
        data-resource-id="Outreachbatch"
        options={{
          label: 'Batch',
          onPress: () => {
            navigation.navigate('segment-list', {});
          },
        }}
      />
      <CampaignMenu.Screen
        data-resource-id="Outreachbatch"
        options={{
          label: 'AI Prompt',
          onPress: () => {
            navigation.navigate('ai-prompts-table', {
              addOnFilter: {for_crm: true},
            });
          },
        }}
      />
    </CampaignMenu.Navigator>
  );
};
export default CampaignNavigator;
