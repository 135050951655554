import React from 'react';
import {
  Avatar as AvatarComponent,
  AvatarGroup as AvatarGroupComponent,
} from '@unthinkable/react-avatar';
import {useStyles} from '@unthinkable/react-theme';
import {AvatarStyles} from './theme';

export const Avatar = props => {
  const styles = useStyles(AvatarStyles);
  return <AvatarComponent {...props} styles={styles} />;
};

export const AvatarGroup = props => {
  return <AvatarGroupComponent {...props} styles={{}} />;
};
