import React, {useState} from 'react';
import ActiveSend from '../images/ActiveSend.svg';
import InactiveSend from '../images/InactiveSend.svg';
import {InputWrapper, SendIcon} from './Styles';
import {View} from '@unthinkable/react-core-components';
import {Avatar} from '@unthinkable/react-avatar';
import {MentionsInput} from '@unthinkable/react-mentions-input';

const SendButton = ({
  disabled,
  activeIcon = ActiveSend,
  inactiveIcon = InactiveSend,
  onPress,
  styles,
}) => {
  return (
    <SendIcon
      icon={disabled ? inactiveIcon : activeIcon}
      disabled={disabled}
      onPress={onPress}
      styles={styles}
    />
  );
};

export const InputBox = props => {
  const [value, onChangeValue] = useState({message: '', mentions: []});
  const {
    inputComponent,
    styles,
    sender,
    sendButton,
    onPress,
    placeholder = 'Type a message...',
    mentionField = 'id',
    ...rest
  } = props;
  const buttonProps = {
    ...props,
    disabled: !value?.message?.length,
    onPress: () => {
      onPress && onPress(value);
      onChangeValue({message: '', mentions: []});
    },
  };
  const inputProps = {
    ...rest,
    value: value?.message,
    onChangeValue: (e, _, {mentions}) => {
      const mentioned = mentions.map(mention => mention?.[mentionField]);
      onChangeValue({
        message: e,
        mentions: mentioned,
      });
    },
    placeholder,
    styles: styles?.input,
  };
  return (
    <InputWrapper gap={8} styles={styles}>
      {sender ? <Avatar value={sender} /> : void 0}
      {inputComponent ? (
        inputComponent(inputProps)
      ) : (
        <View style={{flex: 1}}>
          <MentionsInput {...inputProps} />
        </View>
      )}
      {sendButton ? sendButton(buttonProps) : <SendButton {...buttonProps} />}
    </InputWrapper>
  );
};
