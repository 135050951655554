import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const VendorForm16 = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/vendorform16documents',
    eventSourceId: 'vendorBulkform',
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        date: new Date(),
      }}
      layoutFields={[
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: '/organizations',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 12,
        },
        {
          field: 'document',
          type: 'file',
          placeholder: 'upload file',
          multiple: true,
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const UploadVendorForm16 = props => {
  return <VendorForm16 header="Upload Vendor Form 16" {...props} />;
};

export const VendorBulkDetailScreen = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  return (
    <Form
      submitAction="Save"
      api={`/vendorform16document/${row?._id}`}
      mode="edit"
      readOnly={true}
      layoutFields={[
        {
          type: 'text',
          field: 'organization.name',
          label: 'Organization',
          size: 6,
        },
        {
          type: 'text',
          placeholder: 'Status',
          field: 'status',
          size: 6,
          label: 'Status',
        },
        {
          label: 'Total',
          size: 6,
          type: 'number',
          placeholder: 'Total',
          field: 'document.length',
          // render: ({row}) => {
          //   return <Text>{row?.documents?.length || 0}</Text>;
          // },
        },
        {
          type: 'number',
          placeholder: 'Success',
          field: 'success_count',
          size: 6,
          label: 'Success',
        },
        {
          label: 'Error',
          size: 6,
          type: 'number',
          placeholder: 'Error',
          field: 'error.length',
          // render: ({row}) => {
          //   return <Text>{row?.errors?.length || 0}</Text>;
          // },
        },
        {
          label: 'Error',
          fields: [
            {
              field: 'error',
              nested: true,
              addInBottom: false,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      label: 'Index',
                      field: 'index',
                      type: 'number',
                    },
                    {
                      label: 'Error',
                      field: 'error',
                      type: 'text',
                    },
                  ]}
                  columns={[
                    {
                      header: 'Index',
                      field: 'index',
                      type: 'text',
                      minWidth: 100,
                    },
                    {
                      header: 'Error',
                      field: 'error',
                      type: 'text',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const VendorSelfForm16 = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/vendoritrs',
    eventSourceId: 'vendorForm16',
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        date: new Date(),
      }}
      layoutFields={[
        {
          label: 'Vendor',
          field: 'vendor',
          type: 'autoComplete',
          api: '/vendors',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'organization',
          api: '/organizations',
          suggestionField: 'name',
          valueField: 'name',
          label: 'Organization',
          size: 6,
        },
        {
          label: 'Quarter',
          field: 'quarter',
          type: 'autoComplete',
          options: ['Q1', 'Q2', 'Q3', 'Q4'],
          size: 6,
        },
        {
          label: 'Financial Year',
          field: 'financial_year',
          type: 'autoComplete',
          api: '/financialyears',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Document',
          field: 'document',
          type: 'file',
          placeholder: 'upload file',
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const UploadVendorSelfForm16 = props => {
  return <VendorSelfForm16 header="Upload Vendor Form 16" {...props} />;
};
