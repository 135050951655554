import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';
import {Chip} from '@unthinkable/react-chip';
import {Wrap} from '@unthinkable/react-core-components';

export const CheckboxRenderer = props => {
  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} />
    </InputLabel>
  );
};

export const MultiCheckboxRenderer = ({value, chipColor, ...props}) => {
  const {styles} = props;

  let display = value?.map((doc, index) => {
    return (
      <Chip styles={styles?.chip} value={doc} key={index} color={chipColor} />
    );
  });

  return (
    <InputLabel {...props}>
      <Wrap gap={6}>{display}</Wrap>
    </InputLabel>
  );
};
