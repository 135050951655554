import React from 'react';
import {useFetchData} from '../../../../controllers/useFetchData';
import {SplitScreen} from '../../../../components/SplitScreen';
import {useAssetActions} from './AssetUtility';
import {Image, View} from '@unthinkable/react-core-components';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {VersionList} from '../../../../components/card/VersionCard';
import {useTheme} from '@unthinkable/react-theme';
import {ApiEndpointDetailForm} from './ApiEndPointForm';
import {ComponentDetailForm} from './ComponentForm';
import {DataEntityDetailForm} from './DataEntityForm';
import {DataServiceDetailForm} from './DataServiceForm';
import {
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
  PROJECT_DATA_SERVICE_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_QA_TEST_CASE_TYPE_ID,
  PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  PROJECT_VIEW_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {ScreenDetailForm} from './ScreenForm';
import {UpdateQATestCaseForm} from './QATestCaseForm';
import {ThirdPartyIntegrationDetailForm} from './ThirdPartyIntegrationForm';
import {DataServiceLibraryDetailForm} from './DataServiceLibraryForm';

const VersionView = props => {
  const {
    navigation,
    route: {params},
    data,
    selectedId,
    eventSourceId,
    sourceField,
    type_id,
    titleField,
    currentId,
  } = props;

  const {feature} = params;

  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: type_id,
    sourceField,
    feature_id: feature?._id,
    eventSourceId,
  });

  return (
    <VersionList
      data={data}
      currentId={currentId}
      onRowPress={({row}) => {
        assetDetail({row});
      }}
      titleField={titleField}
      selectedId={selectedId}
    />
  );
};

const assetVersionMap = {
  [PROJECT_CONTROLLER_TYPE_ID]: {
    DetailForm: ApiEndpointDetailForm,
    type_id: PROJECT_CONTROLLER_TYPE_ID,
    eventSourceId: ['Controller', 'Feature'],
    titleField: 'controller',
    sourceField: 'controller',
  },
  [PROJECT_COMPONENT_TYPE_ID]: {
    DetailForm: ComponentDetailForm,
    eventSourceId: ['Component', 'Feature'],
    type_id: PROJECT_COMPONENT_TYPE_ID,
    titleField: 'component',
    sourceField: 'component',
  },
  [PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID]: {
    DetailForm: DataServiceLibraryDetailForm,
    eventSourceId: ['DataServiceLibrary', 'Feature'],
    type_id: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    titleField: 'name',
    sourceField: 'name',
  },
  [PROJECT_MODEL_TYPE_ID]: {
    DetailForm: DataEntityDetailForm,
    eventSourceId: ['Feature', 'Task', 'Model'],
    type_id: PROJECT_MODEL_TYPE_ID,
    titleField: 'model',
    sourceField: 'model',
  },
  [PROJECT_DATA_SERVICE_TYPE_ID]: {
    DetailForm: DataServiceDetailForm,
    eventSourceId: ['DataService', 'Feature'],
    type_id: PROJECT_DATA_SERVICE_TYPE_ID,
    titleField: 'name',
    sourceField: 'name',
  },
  [PROJECT_VIEW_TYPE_ID]: {
    DetailForm: ScreenDetailForm,
    eventSourceId: ['View', 'Feature'],
    type_id: PROJECT_VIEW_TYPE_ID,
    titleField: 'view',
    sourceField: 'view',
  },
  [PROJECT_QA_TEST_CASE_TYPE_ID]: {
    DetailForm: UpdateQATestCaseForm,
    eventSourceId: ['Feature', 'TestCase'],
    type_id: PROJECT_QA_TEST_CASE_TYPE_ID,
    titleField: 'name',
    sourceField: 'name',
  },
  [PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID]: {
    DetailForm: ThirdPartyIntegrationDetailForm,
    eventSourceId: ['ThirdPartyIntegration', 'Feature'],
    type_id: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
    titleField: 'name',
    sourceField: 'name',
  },
};

export const VersionScreen = props => {
  const {
    route: {params},
  } = props;

  const {type_id} = params;

  return <VersionViewSplitScreen {...assetVersionMap[type_id]} {...props} />;
};

const VersionViewSplitScreen = ({DetailForm, ...props}) => {
  const {
    route: {params},
    eventSourceId,
    type_id,
  } = props;

  const {row, source_id, fromReleased} = params;

  const icons = useTheme('icons');

  const fetchParams = {
    api: `/assets/${type_id}/${source_id}/versions`,
    filter: !fromReleased ? {} : {status: 'released'},
    eventSourceId,
  };

  const {data, loading} = useFetchData(fetchParams);

  let selectRowIndex = data?.findIndex(item =>
    row.version
      ? row.version === item.version
      : row.status === 'released'
      ? row.released_version_id?._id === item._id
      : void 0,
  );
  if (!selectRowIndex || selectRowIndex < 0) {
    selectRowIndex = 0;
  }

  let defaultSelectedData = data?.length
    ? {
        selectedId: data?.[selectRowIndex]?._id,
        ...params,
        row: data?.[selectRowIndex],
      }
    : void 0;

  const currentId = defaultSelectedData?.selectedId;

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ActivityIndicator />
      </View>
    );
  }
  if (!data?.length) {
    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image source={icons.NoData} />
      </View>
    );
  }

  return (
    <SplitScreen
      defaultSelectedData={defaultSelectedData}
      sideScreenWidth={'75%'}
      splitScreen={
        <DetailForm
          {...props}
          skipHeader={true}
          closable={false}
          readOnly={true}
        />
      }>
      <VersionView
        data={data}
        loading={loading}
        {...props}
        currentId={currentId}
      />
    </SplitScreen>
  );
};
