import React from 'react';
import {Message, DateChip, ChatList} from '@unthinkable/react-chat';
import {useAppStateContext} from '../../../providers/AppState';
import {useStyles} from '@unthinkable/react-theme';
import {ChatMessageStyles} from './theme';

export const Chat = ({
  data,
  timeField = 'createdAt',
  senderBubbleColor,
  receiverBubbleColor,
  ...rest
}) => {
  const styles = useStyles(ChatMessageStyles);
  const {primaryBubble, secondaryBubble} = styles;
  const {user, fetch} = useAppStateContext();
  const renderRow = ({item}) => {
    const {type, user_id, ...rest} = item || {};

    return type == 'message' ? (
      <Message
        styles={styles}
        {...(user?._id == user_id?._id
          ? {
              position: 'right',
              background: senderBubbleColor || primaryBubble,
            }
          : {
              sender: item.name,
              position: 'left',
              background: receiverBubbleColor || secondaryBubble,
            })}
        time={item?.[timeField]}
        color={item.color}
        message={item.message}
      />
    ) : (
      <DateChip date={item?.date} />
    );
  };
  return (
    <ChatList
      {...rest}
      styles={styles}
      data={data}
      renderRow={renderRow}
      timeField={timeField}
      fetch={fetch}
      api={`/users`}
      suggestionField="name"
      secondarySuggestionField="email"
      mentionField="id"
    />
  );
};

export const GenericChat = ({
  data,
  timeField = 'createdAt',
  colorField = 'color',
  messageField = 'message',
  nameField = 'name',
  userIdField = 'user_id',
  senderBubbleColor,
  receiverBubbleColor,
  isSelfCondition,
  ...rest
}) => {
  const styles = useStyles(ChatMessageStyles);
  const {primaryBubble, secondaryBubble} = styles;
  const {user, fetch} = useAppStateContext();
  const renderRow = ({item}) => {
    const {type, ...rest} = item || {};

    return type == 'message' ? (
      <Message
        styles={styles}
        {...(isSelfCondition?isSelfCondition(item) : (user?._id == item?.[userIdField]?._id)
          ? {
              position: 'right',
              background: senderBubbleColor || primaryBubble,
            }
          : {
              sender: item?.[nameField],
              position: 'left',
              background: receiverBubbleColor || secondaryBubble,
            })}
        time={item?.[timeField]}
        color={item?.[colorField]}
        message={item?.[messageField]}
      />
    ) : (
      <DateChip date={item?.date} />
    );
  };
  return (
    <ChatList
      {...rest}
      styles={styles}
      data={data}
      renderRow={renderRow}
      timeField={timeField}
      fetch={fetch}
      api={`/users`}
      suggestionField="name"
      secondarySuggestionField="email"
      mentionField="id"
    />
  );
};