export const SwitchStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    trackStyle: {
      height: 20,
      width: 40,
      backgroundColor: colors.NEUTRAL_LOW,
      borderRadius: 14,
    },
    ActiveTrackStyle: {
      height: 20,
      width: 40,
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
      borderRadius: 14,
    },
    thumbStyle: {
      height: 18,
      width: 18,
      borderRadius: 9,
      backgroundColor: colors.BACKGROUND,
      position: 'absolute',
      transition: 'left 0.5s',
      top: 1,
      bottom: 1,
    },
  };
};
