import {resolveExp} from '@unthinkable/react-utils';
import {
  RowContainer,
  RowContent,
  RowPrimaryText,
  RowSecondaryText,
} from './Styles';

export const SuggestionRow = ({
  styles,
  secondarySuggestionField,
  row,
  index,
  search,
}) => {
  let rowSecondaryText = resolveExp(row, secondarySuggestionField);
  return (
    <RowContainer styles={styles} gap={8} key={index}>
      <RowContent styles={styles}>
        <RowPrimaryText styles={styles}>{row?.name}</RowPrimaryText>
        {secondarySuggestionField && (
          <RowSecondaryText styles={styles}>
            {rowSecondaryText}
          </RowSecondaryText>
        )}
      </RowContent>
    </RowContainer>
  );
};
