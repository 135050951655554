import {TabView} from '../../../components/tab/TabView';
import {Table} from '../../../components/table/Table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {Image, Text} from '@unthinkable/react-core-components';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useFetchData} from '../../../controllers/useFetchData';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {Chip} from '@unthinkable/react-chip';

const QuoteRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'quote_status',
        type: 'text',
      },
      {field: 'quote_date', type: 'date'},
    ]}
  />
);

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (row?.status == 'Lost') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.is_drop || row?.status == 'invalidRfq') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          Drop
        </Text>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            fontSize: 12,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'Won') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RenderSkills = props => {
  const {row} = props;
  const skillName = row?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

const RenderPratice = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const {INFORMATION_MEDIUM, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  const practiceName = row?.practice
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!practiceName) return null;
  return (
    <Chip
      color={INFORMATION_MEDIUM}
      textColor={INFORMATION_HIGH}
      value={practiceName}
      borderRadius={6}
    />
  );
};

const ModeRender = props => {
  const {row} = props;
  return (
    <PrimaryColumnCard
      primaryTitle={row?.process}
      items={[
        {
          value: row?.work_location,
        },
        {
          value: moment(row?.creation_date)?.format('DD MMM, YY'),
        },
      ]}
    />
  );
};

const LastCommunicationRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'last_communication',
        type: 'text',
      },
      {
        field: 'last_communication_date',
        type: 'date',
      },
    ]}
  />
);

const CustomerOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {filter, api} = props;
  return (
    <Table
      // api={api}
      {...props}
      params={params}
      filter={filter}
      data={params?.row}
      columns={[
        {
          field: 'requirement',
          header: 'Requirement',
        },
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 150,
        },
        {
          type: 'date',
          field: 'due_date',
          header: 'Due Date',
          width: 250,
        },
        {
          header: 'Quote',
          render: QuoteRender,
          width: 300,
        },
        {
          header: 'Status',
          render: RenderStatus,
          width: 300,
        },
      ]}
    />
  );
};

const CustomerAllRFROpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {filter, api} = props;
  let IdArray = [];
  for (const data of params?.row) {
    if (data && data?._id) {
      IdArray.push(data?._id);
    }
  }
  const {data = []} = useFetchData({
    api: '/opportunities',
    fields: {
      _id: 1,
      customer_id: {
        _id: 1,
        name: 1,
        employee_count: {number: 1},
        country: {name: 1},
      },
      owner: {name: 1, color: 1},
      status: 1,
      outcome_date: 1,
      is_drop: 1,
      process: 1,
      work_location: 1,
      creation_date: 1,
      industry: {_id: 1, name: 1},
      skill: {_id: 1, name: 1},
    },
    filter: {
      _id: {$in: IdArray},
    },
  });
  return (
    <Table
      // api={api}
      {...props}
      params={params}
      filter={filter}
      data={data}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          type: 'text',
          field: 'customer_id.name',
          header: 'Customer',
        },
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
          header: 'Size',
          width: 150,
        },
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
          width: 300,
        },
        {
          field: 'customer_id.country.name',
          type: 'text',
          header: 'Country',
          width: 200,
        },
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 150,
        },
        {
          header: 'Skill',
          render: RenderSkills,
        },
        {
          header: 'Mode',
          render: ModeRender,
          width: 150,
        },
        {
          header: 'Status',
          render: RenderStatus,
          // width: 300,
        },
      ]}
    />
  );
};

const CustomerAllRFQOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {filter, api} = props;
  let IdArray = [];
  for (const data of params?.row) {
    if (data && data?._id) {
      IdArray.push(data?._id);
    }
  }
  const {data = []} = useFetchData({
    api: '/opportunities',
    fields: {
      _id: 1,
      customer_id: {
        _id: 1,
        name: 1,
        employee_count: {number: 1},
        country: {name: 1},
      },
      owner: {name: 1, color: 1},
      status: 1,
      outcome_date: 1,
      is_drop: 1,
      process: 1,
      work_location: 1,
      creation_date: 1,
      industry: {_id: 1, name: 1},
      practice: {_id: 1, name: 1},
    },
    filter: {
      _id: {$in: IdArray},
    },
  });
  return (
    <Table
      // api={api}
      {...props}
      params={params}
      filter={filter}
      data={data}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          type: 'text',
          field: 'customer_id.name',
          header: 'Customer',
        },
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
          header: 'Size',
          width: 150,
        },
        {
          field: 'industry.name',
          colorField: 'industry.color',
          header: 'Industry',
          type: 'colorTag',
          width: 300,
        },
        {
          field: 'customer_id.country.name',
          type: 'text',
          header: 'Country',
          width: 200,
        },
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 150,
        },
        {
          header: 'Practice',
          render: RenderPratice,
          width: 120,
        },

        {
          header: 'Status',
          render: RenderStatus,
          // width: 300,
        },
      ]}
    />
  );
};

export const CustomerProposalList = props => {
  const {route: {params} = {}} = props;
  const {period, row, status} = params;
  const tabs = {};

  tabs.all = {
    label: 'All',
    view: <CustomerOpportunityTable {...props} />,
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerAllRFRList = props => {
  const {route: {params} = {}} = props;
  const {period, row, status} = params;
  const tabs = {};

  tabs.all = {
    label: 'All RFR',
    view: <CustomerAllRFROpportunityTable {...props} />,
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerAllRFQList = props => {
  const {route: {params} = {}} = props;
  const {period, row, status} = params;
  const tabs = {};

  tabs.all = {
    label: 'All RFQ',
    view: <CustomerAllRFQOpportunityTable {...props} />,
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerRFRList = props => {
  const {route: {params} = {}} = props;
  const {period, row} = params;
  const tabs = {};
  const CustomerRFRTableApi = {
    api: '/opportunities',
    filter: {
      customer_id: row?.customer?._id,
      rfr_type: 'Customer',
      creation_date: {
        $gte: period?.from,
        $lte: period?.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...CustomerRFRTableApi,
    view: <CustomerOpportunityTable {...props} {...CustomerRFRTableApi} />,
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerRPSList = props => {
  const {route: {params} = {}} = props;

  const {period, row} = params;
  const tabs = {};
  const CustomerRPSTableApi = {
    api: '/opportunities',
    filter: {
      customer_id: row?.customer?._id,
      rfr_type: 'RPS',
      creation_date: {
        $gte: period?.from,
        $lte: period?.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...CustomerRPSTableApi,
    view: <CustomerOpportunityTable {...props} {...CustomerRPSTableApi} />,
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
