import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinetuneJobModelForm = props => {
  const {
    route: {params},
    header = 'Select Refrence Model',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/finetunejobs/create',
    eventSourceId: ['useCaseAdded'],
  });
  const {defaultValues, usecase_id} = params;

  return (
    <Form
      fields={{
        name: 1,
        reference_model: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        usecase_id,
        ...defaultValues,
      }}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
        {
          label: 'Finetune Model',
          field: 'fine_tune_model',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          field: 'reference_model',
          type: 'autoComplete',
          label: 'Reference Model',
          placeholder: 'Reference Model',
          options: ['gpt-3.5-turbo-1106', 'gpt-3.5-turbo-0613', 'gpt-4-0613'],
        },
      ]}
      {...props}
    />
  );
};
