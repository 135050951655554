import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useAppStateContext } from '../../../providers/AppState';
import { CASCADER_NAVIGATE_TO_ADD_VIEW } from '../../common/constants/SourceConstants';

export const MakeUqlForm = props => {
  const params = props.route.params;
  const {row} = params;
  const {post} = useAppStateContext();
  const makeUQl = data => {
    post({
      uri: `/update-potential-customer/${row?._id}`,
      method: 'put',
      props: {
        ...data,
        lead_status: 'Active',
        stage: {
          _id: '5b56cf2421799051029c4522',
          name: 'Lead',
        },
        pipeline_type: 'Prospect',
      },
      eventSourceId: 'Lead',
    });
  };

  return (
    <Form
      api={`/potentialcustomers/${row?._id}`}
      fields={{
        name: 1,
        stage: {name: 1},
        geography_location: {name: 1},
        organization_category: {name: 1},
        employee_count: {number: 1},
        industry: {name: 1},
        country: {
          name: 1,
        },
        owner: {name: 1},
        phone: 1,
        email: 1,
        website: 1,
        description: 1,
        manual_description: 1,
        created_on: 1,
        type: 1,
        lead_status: 1,
        pipeline_type: 1,
        stage_date: 1,
        uql_stage_date: 1,
        activity_date: 1,
        added_on: 1,
        modified_on: 1,
        modified_by: 1,
        created_by: 1,
        campaign_type: {
          label: 1,
          name: 1,
        },
        campaign_id: {
          name: 1,
        },
        product: {
          name: 1,
        },
        organization: {
          name: 1,
        },
        exact_employee_count: 1,
        buyer_persona: {
          name: 1,
        },
        inside_sales: {
          name: 1,
        },
        contact_detail: {
          first_name: 1,
          last_name: 1,
          designation: 1,
          email: 1,
          linkedin_url: 1,
          phone_no: 1,
        },
      }}
      mode="edit"
      onSubmit={makeUQl}
      header={'Make UQL'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'type',
          options: ['Individual', 'Organization'],
          dependentFields: ['email', 'website'],
        },
        {
          fields: [
            {
              type: 'text',
              field: 'name',
              placeholder: 'Name',
              label: 'Name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'phone',
              placeholder: 'Phone',
              label: 'Phone',
              // required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'email',
              placeholder: 'Email',
              label: 'Email',
              // required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'designation',
              placeholder: 'Designation',
              label: 'Designation',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'name',
              placeholder: 'Organization name',
              label: 'Organization name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'text',
              field: 'website',
              placeholder: 'Website',
              label: 'Website',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: `/industries`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Industry',
              label: 'Industry',
              label: 'Industry',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              field: 'country',
              placeholder: 'Country',
              label: 'Country',
              type: 'autoComplete',
              api: `/countries`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'geography_location',
              placeholder: 'Geography Location',
              label: 'Geography Location',
              type: 'autoComplete',
              api: `/geographylocations`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'organization_category',
              placeholder: 'Organization Category',
              label: 'Organization Category',
              type: 'autoComplete',
              api: `/organizationcategories`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'employee_count',
              placeholder: 'Employee Count',
              label: 'Employee Count',
              type: 'autoComplete',
              api: `/employeeSize/active`,
              suggestionField: 'number',
              valueField: 'number',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'exact_employee_count',
              placeholder: 'Exact Employee Count',
              label: 'Exact Employee Count',
              type: 'number',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              label: 'Target Audience',
              placeholder: 'Target Audience',
              field: 'buyer_persona',
              type: 'autoComplete',
              api: `/buyerpersonas`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Inside Sales',
              placeholder: 'Inside Sales',
              field: 'inside_sales',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Owner',
              placeholder: 'Owner',
              field: 'owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 6,
            },
            {
              placeholder: 'Product',
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Stage',
              field: 'stage',
              type: 'autoComplete',
              api: `/stages/lead-creation`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value?.name == 'Lead') {
                  setFieldValue('uql_stage_date', new Date());
                }
              },
            },
            {
              type: 'date',
              field: 'uql_stage_date',
              label: 'Uql Date',
              placeholder: 'Uql Date',
              size: 6,
              visible: ({values}) => {
                return values?.stage?.name == 'Lead';
              },
            },
          ],
        },
        {
          label: 'Campaign Details',
          fields: [
            {
              placeholder: 'Channel & Campaign',
              label: 'Channel & Campaign',
              field: 'campaign_type',
              type: 'cascader',
              required: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'campaign_type',
                  api: '/types/suggestionsForLead',
                  suggestionField: 'label',
                  valueField: 'label',
                },
                {
                  label: 'Campaign Value',
                  placeholder: 'Campaign Id',
                  field: 'campaign_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  params: ({values: {campaign_type} = {}}) => {
                    return {
                      source: campaign_type,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
            {
              type: 'textArea',
              field: 'description',
              placeholder: 'Description',
              label: 'Description',
            },
          ],
        },
        {
          groups: [
            {
              label: 'Organization Details',
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              fields: [
                {
                  type: 'text',
                  field: 'company_name',
                  placeholder: 'Name',
                  label: 'Name',
                  required: true,
                  size: 4,
                },
                {
                  type: 'text',
                  field: 'website',
                  placeholder: 'Website',
                  // required: true,
                  size: 4,
                },
                {
                  type: 'autoComplete',
                  field: 'industry',
                  api: `/industries`,
                  suggestionField: 'name',
                  valueField: 'name',
                  placeholder: 'Industry',
                  label: 'Industry',
                  required: true,
                  size: 4,
                },
                {
                  field: 'country',
                  placeholder: 'Country',
                  label: 'Country',
                  type: 'autoComplete',
                  api: `/countries`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 6,
                },
                {
                  field: 'geography_location',
                  placeholder: 'Location',
                  label: 'Geography Location',
                  type: 'autoComplete',
                  api: `/geographylocations`,
                  suggestionField: 'name',
                  required: true,
                  valueField: 'name',
                  size: 6,
                },
                {
                  field: 'employee_count',
                  placeholder: 'Employee Count',
                  label: 'Employee Count',
                  type: 'autoComplete',
                  api: `/employeeSize/active`,
                  suggestionField: 'number',
                  valueField: 'number',
                  required: true,
                  size: 6,
                },
                {
                  field: 'exact_employee_count',
                  placeholder: 'Exact Employee Count',
                  label: 'Exact Employee Count',
                  type: 'number',
                  size: 6,
                },
                {
                  field: 'organization_category',
                  placeholder: 'Organization Category',
                  label: 'Organization Category',
                  type: 'autoComplete',
                  api: `/organizationcategories`,
                  suggestionField: 'name',
                  required: true,
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
            {
              label: 'Point of Contact',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              fields: [
                {
                  field: 'contact_detail',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          header: 'First Name',
                          type: 'text',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          header: 'Last Name',
                          type: 'text',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          header: 'Designation',
                          type: 'text',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          header: 'Email',
                          type: 'text',
                        },
                        {
                          field: 'linkedin_url',
                          placeholder: 'Linkedin',
                          header: 'Linkedin',
                          type: 'text',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Phone',
                          header: 'Phone',
                          type: 'text',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};
