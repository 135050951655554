import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddCustomObjectType = props => {
  const {
    route: {params},
    header = 'Add Custom Object Type',
  } = props;
  const {row} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/customobjecttypes',
    ...props,
  });
  return (
    <Form
      api={`/customobjecttypes/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const EditCustomObjectType = props => {
  return (
    <AddCustomObjectType
      mode="edit"
      header={'Edit Custom Object Type'}
      {...props}
    />
  );
};
