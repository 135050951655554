import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';

export const SocialMediaPostTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;

  return (
    <Table
      {...props}
      params={params}
      eventSourceId="SocialMediaPost"
      onRowPress={({row}) => {
        navigation.navigate('edit-social-media-post', {
          socialMediaPost: row,
        });
      }}
      renderHeader={props => {
        return (
          <TableHeader
            title="SocialMedia Posts"
            actions={[
              <ObjectWiseTasks
                {...props}
                params={{...props?.route?.params}}
                object={{
                  _id: '654de2740ac185c7cfd943a4',
                  name: 'SocialMediaPost',
                }}
              />,
              <AddButton
                title="Add Post"
                view="add-social-media-post"
                {...props}
              />,
            ]}
          />
        );
      }}
      fields={{
        name: 1,
        content: 1,
        value_proposition: 1,
      }}
      api={`/socialMediaPosts`}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'content',
          header: 'Content',
        },
        {
          type: 'number',
          field: 'value_proposition',
          header: 'Value Proposition',
          width: 200,
        },
      ]}
    />
  );
};
