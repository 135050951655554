import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const OpportunityMarkOwnRFQFetaure = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: ['CustomPlatformOpportunity', 'POC'],
    uri: '/opportunities',
    ...props,
  });

  const {opportunity} = params;
  let {status, ...rest} = opportunity;

  return (
    <Form
      {...props}
      defaultValues={{
        ...rest,
      }}
      onSubmit={onSubmit}
      header={'Close Opportunity'}
      submitAction={'Close'}
      layoutFields={[
        {
          placeholder: 'Outcome',
          type: 'autoComplete',
          field: 'status',
          options: [
            {label: 'Won', value: 'Won'},
            {label: 'Lost', value: 'Lost'},
            {label: 'Drop', value: 'invalidRfq'},
          ],
          required: true,
          keyField: 'value',
          suggestionField: 'label',
          valueField: 'label',
        },
        {
          placeholder: 'Date',
          label: 'Date',
          field: 'outcome_date',
          type: 'date',
          required: true,
        },
        {
          placeholder: 'Proposal Hours',
          label: 'Proposal Hours',
          field: 'proposal_hours',
          type: 'number',
          visible: ({values}) =>
            values && values.status && values.status === 'Won',
          required: ({values}) =>
            values && values.status && values.status === 'Won',
        },

        {
          placeholder: 'Lost Reason',
          field: 'outcome_description_comment',
          type: 'autoComplete',
          visible: ({values}) =>
            values && values.status && values.status === 'Lost',
          required: ({values}) =>
            values && values.status && values.status === 'Lost',
          options: [
            'Lost to Competition',
            'Lost due to gaps in the proposal quality',
            'Lost due to delay in sharing proposal',
            'Proposal walkthrogh issues/quality',
            'Lack of confidence building with client',
          ],
        },
        {
          placeholder: 'Lost Description',
          field: 'outcome_description',
          type: 'text',
          visible: ({values}) =>
            values && values.status && values.status === 'Lost',
          required: ({values}) =>
            values && values.status && values.status === 'Lost',
        },
        {
          placeholder: 'Drop Reason',
          field: 'outcome_description_comment',
          type: 'autoComplete',
          visible: ({values}) =>
            values && values.status && values.status === 'invalidRfq',
          required: ({values}) =>
            values && values.status && values.status === 'invalidRfq',
          options: [
            'Budget Issue',
            'Unavailability of technical expertise',
            'Client is not responsive',
            'Project is on hold or deferred',
            'Drop due to non-aggreement in T&C in MSA & SOW',
            'No clarity in requirement',
            'Business model changed',
            'Drop due to timeline',
            'No communication in the past 30 days',
          ],
        },
        {
          placeholder: 'Drop Description',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'invalidRfq',
          required: ({values}) =>
            values && values.status && values.status === 'invalidRfq',
          type: 'text',
        },
      ]}
    />
  );
};

export const OpportunityMarkOwn = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: ['CustomPlatformOpportunity', 'POC'],
    uri: '/opportunities',
    ...props,
  });

  const {opportunity} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        ...opportunity,
        status: 'Won',
      }}
      onSubmit={onSubmit}
      header={'Mark Won'}
      submitAction={'Won'}
      layoutFields={[
        {
          placeholder: 'Date',
          label: 'Date',
          field: 'outcome_date',
          type: 'date',
          required: true,
        },
        {
          placeholder: 'Proposal Hours',
          label: 'Proposal Hours',
          field: 'proposal_hours',
          type: 'number',
          required: true,
        },
      ]}
    />
  );
};
