import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AcceptShiftAllowanceForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/dailyincentives',
    eventSourceId: ['shiftallowance'],
  });

  return (
    <Form
      mode="edit"
      api={`/dailyincentives/${row?._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'accept',
            accepted_date: getZeroTimeDate(new Date()),
          },
        };
      }}
      submitAction="Accept"
      header={{
        title: 'Accept MA Amount',
        secondaryTitle: `${row?.employee?.name} Monthly Allowance`,
      }}
      layoutFields={[
        {
          label: 'Actual MA',
          placeholder: 'Actual MA',
          field: 'actual_ma',
          type: 'number',
          required: true,
        },
        {
          label: 'Payable MA',
          placeholder: 'Payable MA',
          field: 'amount',
          type: 'number',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const RejectShiftAllowanceForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyincentives',
    eventSourceId: ['shiftallowance'],
  });

  return (
    <Form
      mode="edit"
      api={`/dailyincentives/${row?._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'reject',
            rejected_date: getZeroTimeDate(new Date()),
          },
        };
      }}
      submitAction="Reject"
      header={{
        title: 'Give Reason For Rejection',
        secondaryTitle: `${row?.employee?.name} Monthly Allowance`,
      }}
      layoutFields={[
        {
          label: 'Reason',
          placeholder: 'Reason',
          field: 'reason',
          type: 'textArea',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateActualShiftAllowanceForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyincentives',
    eventSourceId: ['shiftallowance'],
  });

  return (
    <Form
      mode="edit"
      api={`/dailyincentives/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      header={{
        title: 'Accept MA Amount',
        secondaryTitle: `${row?.employee?.name} Monthly Allowance`,
      }}
      layoutFields={[
        {
          label: 'Actual MA',
          placeholder: 'Actual MA',
          field: 'actual_ma',
          type: 'number',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ProcessedShiftAllowanceForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/addDailyIncentiveComponent',
    eventSourceId: ['shiftallowance'],
    method: 'post',
  });

  return (
    <Form
      mode="edit"
      api={`/dailyincentives/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      beforeSubmit={({data}) => {
        return {
          data: {
            selectedIds: [row?._id],
          },
        };
      }}
      header={{
        title: 'Processed MA Amount',
        secondaryTitle: `${row?.employee?.name} Monthly Allowance`,
      }}
      layoutFields={[
        {
          label: 'Add Components',
          placeholder: 'Add Components',
          field: 'salary_component',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          api: '/salarycomponents',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ProcessedShiftAllowanceMultipleForm = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds = []} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/addDailyIncentiveComponent',
    eventSourceId: ['shiftallowance'],
    method: 'post',
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            selectedIds,
          },
        };
      }}
      header={{
        title: 'Processed MA Amount',
      }}
      layoutFields={[
        {
          label: 'Add Components',
          placeholder: 'Add Components',
          field: 'salary_component',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          api: '/salarycomponents',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const GenerateShiftAllowanceFormForRM = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/dailyIncentiveMethod',
    eventSourceId: ['shiftallowance'],
    method: 'post',
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header={{
        title: 'Generate MA Amount',
      }}
      layoutFields={[
        {
          label: 'Month',
          placeholder: 'Month',
          field: 'month_id',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          api: '/monthtypes',
          required: true,
        },
        {
          label: 'Year',
          placeholder: 'Year',
          field: 'year_id',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          api: '/yeartypes',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
