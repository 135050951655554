import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinetuneUsecasesForm = props => {
  const {
    route: {params},
    header = 'Add Usecase',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/finetuneusecases',
    eventSourceId: ['useCaseAdded'],
  });
  const {defaultValues} = params;

  return (
    <Form
      mode={mode}
      fields={{
        name: 1,
        reference_model: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        ...defaultValues,
      }}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
        {
          field: 'reference_model',
          type: 'autoComplete',
          label: 'Reference Model',
          placeholder: 'Reference Model',
          options: ['gpt-3.5-turbo-1106', 'gpt-3.5-turbo-0613', 'gpt-4-0613'],
        },
      ]}
      {...props}
    />
  );
};

export const FinetuneUsecasesFormEdit = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <FinetuneUsecasesForm
      mode="edit"
      api={`/finetuneusecases/${_id}`}
      header={'Edit Usecase'}
      {...props}
    />
  );
};
