export const FormStyles = ({theme: {fonts, colors, shadows}}) => {
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
    },
    body: {
      padding: 20,
    },
    header: {
      container: {
        backgroundColor: colors.SURFACE1,
        borderBottomWidth: 1,
        borderColor: colors.OUTLINE,
        paddingTop: 12,
        paddingRight: 20,
        paddingBottom: 12,
        paddingLeft: 20,
      },
      title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
      secondaryTitle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    footer: {
      container: {
        backgroundColor: colors.SURFACE3,
        paddingTop: 12,
        paddingRight: 20,
        paddingBottom: 12,
        paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: colors.OUTLINE,
        justifyContent: 'flex-end',
      },
    },
    primaryButton: {
      container: {
        backgroundColor: colors.BRAND_UPPER_MEDIUM,
      },
      disabledContainer: {
        backgroundColor: colors.OUTLINE,
      },
      text: {
        ...fonts.ACTION,
        color: colors.BACKGROUND,
      },
      disabledText: {color: colors.NEUTRAL_LOW},
    },
    secondaryButton: {
      container: {
        paddingTop: 6,
        paddingRight: 28,
        paddingBottom: 6,
        paddingLeft: 28,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: colors.NEUTRAL_MEDIUM,
      },
      disabledContainer: {
        borderColor: colors.NEUTRAL_LOW,
        backgroundColor: 'transparent',
      },
      text: {
        color: colors.NEUTRAL_MEDIUM,
        ...fonts.ACTION,
      },
      disabledText: {color: colors.NEUTRAL_LOW},
    },
    iconButton: {
      container: {
        padding: 6,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
      },
      hoverContainer: {
        backgroundColor: colors.SURFACE2,
      },
    },
    formGroup: {
      backgroundColor: colors.SURFACE2,
      oddBackgroundColor: colors.BACKGROUND,
      borderRadius: 12,
    },
    formGroupRow: {},
    formGroupLabel: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    groupButtonRow: {},
    groupButton: {
      borderBottomColor: colors.NEUTRAL_HIGH,
    },
    groupActiveButton: {
      borderBottomColor: colors.INFORMATION_HIGH,
    },
    groupButtonText: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_HIGH,
    },
    groupActiveButtonText: {
      color: colors.INFORMATION_HIGH,
    },
    groupButtonIcon: {},
    tabs: {
      primary: {
        container: {
          backgroundColor: colors.BRAND_UPPER_MEDIUM,
        },
        disabledContainer: {
          backgroundColor: colors.OUTLINE,
        },
        text: {
          ...fonts.ACTION,
          color: colors.BACKGROUND,
        },
        disabledText: {color: colors.NEUTRAL_LOW},
      },
      secondary: {
        container: {
          paddingTop: 6,
          paddingRight: 28,
          paddingBottom: 6,
          paddingLeft: 28,
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderColor: colors.NEUTRAL_MEDIUM,
        },
        disabledContainer: {
          borderColor: colors.NEUTRAL_LOW,
          backgroundColor: 'transparent',
        },
        text: {
          color: colors.NEUTRAL_MEDIUM,
          ...fonts.ACTION,
        },
        disabledText: {color: colors.NEUTRAL_LOW},
      },
      footer: {
        paddingTop: 12,
        paddingRight: 20,
        paddingBottom: 12,
        paddingLeft: 20,
      },
      tabBarRowStyle: {
        containerStyle: {
          paddingTop: 12,
          paddingRight: 20,
          paddingBottom: 12,
          paddingLeft: 20,
        },
      },
      tabItemStyle: {
        itemWrapperStyle: {
          backgroundColor: colors.OUTLINE,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        },
        firstItemWrapperStyle: {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },
        lastItemWrapperStyle: {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
        itemStyle: {
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 10,
          paddingRight: 10,
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 1,
          borderColor: 'transparent',
        },
        activeItemStyle: {
          borderRadius: 5,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          ...shadows.Text,
        },
        labelStyle: {
          color: colors.NEUTRAL_MEDIUM,
          ...fonts.SIDE_NAVIGATION,
        },
        activeLabelStyle: {
          color: colors.NEUTRAL_HIGH,
          ...fonts.SIDE_NAVIGATION,
        },
      },
    },
    tabView: {
      tabBarRowStyle: {
        containerStyle: {
          marginBottom: 0,
          marginLeft: 12,
          marginRight: 12,
          borderRadius: 0,
          borderBottomColor: colors.OUTLINE,
          borderBottomWidth: 1,
        },
      },
      tabItemStyle: {
        itemWrapperStyle: {
          padding: 0,
          backgroundColor: 'transparent',
        },
        firstItemWrapperStyle: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
        lastItemWrapperStyle: {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        itemStyle: {
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 12,
          paddingBottom: 12,
          borderWidth: 0,
          borderColor: 'transparent',
          borderRadius: 0,
          borderBottomColor: 'transparent',
          borderBottomWidth: 1,
        },
        activeItemStyle: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderBottomColor: colors.NEUTRAL_HIGH,
          borderBottomWidth: 1,
          borderRadius: 0,
        },
        labelStyle: {
          color: colors.NEUTRAL_MEDIUM,
          ...fonts.BODY2,
        },
        activeLabelStyle: {
          color: colors.NEUTRAL_HIGH,
          ...fonts.BODY2,
        },
      },
    },
  };
};

export const InlineFormStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      marginBottom: 12,
    },
  };
};

export const TableRowInlineFormStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      marginBottom: 12,
      flex: 1,
    },
  };
};

export const NestedActionStyles = ({theme: {colors, fonts, icons}}) => {
  return {
    deleteIcon: icons.TableDelete,
    editIcon: icons.TableEdit,
    detailIcon: icons.CaretRight,
    iconContainer: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    buttonText: {
      color: colors.INFORMATION_HIGH,
      ...fonts.ACTION,
    },
  };
};

export const FileRendererStyles = ({theme: {colors, fonts}}) => {
  return {
    textStyle: {
      color: colors.INFORMATION_HIGH,
      ...fonts.BODY2,
    },
  };
};

export const LinkRendererStyles = ({theme: {colors, fonts}}) => {
  return {
    textStyle: {
      color: colors.INFORMATION_HIGH,
      textDecoration: 'underline',
      ...fonts.BODY2,
    },
  };
};

export const TextRendererStyles = ({theme: {colors, fonts}}) => {
  return {
    textStyle: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY2,
    },
  };
};

export const FormHeaderStyle = ({theme: {colors, fonts}}) => {
  return {
    container: {
      paddingTop: 12,
      paddingRight: 20,
      paddingLeft: 20,
    },
    title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
    secondaryTitle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
