import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const MarkOrderClosedForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/tandm-order/closed/${row?._id}`,
    eventSourceId: ['t&m', 'orderTable', 'fixed'],
  });

  return (
    <Form
      header={{
        title: 'Close order',
        secondaryTitle: `${row?.order_number}`,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            fromOrder: row?.fromOrder,
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'order_close_date',
          placeholder: 'Closing Date',
          label: 'Closing Date',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
