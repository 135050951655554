import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';

export const FinetuneTrainingSampleList = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter: filterGroup = {}} = filterValues || {};

  const {filter} = params || {};

  const {useAddFinetuneFollowupMessage} = leadControllers(props);

  return (
    <Table
      api={`/finetunetrainingsamples`}
      eventSourceId={['addDocument']}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Usecase',
                    api: '/finetuneusecases',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'usecase_id',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      fields={{
        value_proposition: {name: 1},
        potential_customer: {name: 1},
        contact_id: 1,
        target_audience: 1,
        email_profile: 1,
        document_id: 1,
        createdAt: 1,
        follow_up_value_proposition: {name: 1},
        usecase_id: {name: 1},
      }}
      sort={{createdAt: -1}}
      params={params}
      filter={{...filter, ...filterGroup}}
      columns={[
        {
          field: 'usecase_id.name',
          type: 'text',
          header: 'UseCase',
          width: 250,
        },
        {
          field: 'potential_customer.name',
          type: 'text',
          header: 'Prospect',
          width: 250,
        },
        {
          field: 'value_proposition.name',
          type: 'text',
          header: 'USP',
          width: 250,
        },
        {
          field: 'follow_up_value_proposition.name',
          type: 'text',
          header: 'Follow up',
        },
        {
          field: 'createdAt',
          type: 'date',
          header: 'Date',
          width: 150,
        },
      ]}
      // moreActions={[
      //   {
      //     title: 'Add Follow-up Message',
      //     onPress: useAddFinetuneFollowupMessage,
      //   },
      // ]}
      {...props}
    />
  );
};
