import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const EscalationForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projectescalations',
    eventSourceId: ['projectEscalations', 'performance-report'],
  });
  const {user} = useAppStateContext();
  return (
    <Form
      api={`/projectescalations/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        project: params._id,
        raised_on: new Date(),
        status: 'Active',
        raised_by: user._id,
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Reason',
          field: 'reason',
          type: 'autoComplete',
          api: '/projectescalationreasons',
          valueField: 'reason',
          suggestionField: 'reason',
          required: true,
        },
        {
          label: 'Description',
          field: 'description',
          type: 'text',
          required: true,
        },
        {
          label: 'Assigness',
          type: 'autoComplete',
          api: '/users',
          suggestionField: 'name',
          valueField: 'name',
          field: 'assigness',
          multiple: true,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddEscalationForm = props => {
  return <EscalationForm header="Add Escalation " {...props} />;
};

export const EditEscalationForm = props => {
  return (
    <EscalationForm mode="edit" header={'Update Escalation '} {...props} />
  );
};

export const resolveEscalation = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/projectescalations`,
    eventSourceId: 'projectEscalations',
  });
  const {user} = useAppStateContext();
  return (
    <Form
      api={`/projectescalations/${row?._id}`}
      header="Mark Resolved"
      mode="edit"
      onSubmit={onSubmit}
      eventSourceId="projectEscalations"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            resolved_by: user?._id,
            status: 'Resolved',
          },
        };
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Resolution Remark',
          field: 'resolution_remark',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
