export const Login = ({theme: {fonts, colors, icons}}) => {
  return {
    LOCOMOIcon: icons.LOCOMOWebLogin,
    UnthinkableIcon: icons.UnthinkableLoginWeb,
    containerStyle: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
    },
    mainContainerStyle: {
      backgroundColor: colors.SURFACE2,
      width: '40%',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    iconContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    formContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    LOCOMOIconStyle: {margin: 12},
    textStyle: {...fonts.BODY2, color: colors.NEUTRAL_MEDIUM},
  };
};
export const LoginMobile = ({theme: {fonts, colors, icons}}) => {
  return {
    LOCOMOIcon: icons.LOCOMOmobileIcon,
    containerStyle: {
      backgroundColor: colors.BRAND_HIGH,
      flex: 1,
    },
    mainContainerStyle: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: colors.SURFACE2,
    },
    textStyle: {...fonts.BODY2, color: colors.NEUTRAL_MEDIUM},
    nextIconStyle: {},
    loaderStyle: {},
  };
};

export const LoginForm = ({theme: {fonts, colors, icons}}) => {
  return {
    WelcomeBackIcon: icons.WelcomeBackToLocomo,
    containerStyle: {
      justifyContent: 'space-around',
      backgroundColor: colors.BACKGROUND,
      padding: 30,
      borderRadius: 20,
    },
    headerContainerStyle: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginBottom: 16,
    },
    headerTextStyle: {
      ...fonts.TITLE,
      color: colors.NEUTRAL_MEDIUM,
      marginBottom: 5,
    },
    googleLoginContainerStyle: {
      marginTop: 16,
      marginBottom: 16,
      alignItems: 'center',
    },
    saparatorContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E5E5EA',
      height: 1,
      marginTop: 30,
      marginBottom: 30,
    },
    separatorTextStyle: {
      padding: 10,
      backgroundColor: '#FCFCFC',
      ...fonts.BODY3,
      color: colors.NEUTRAL_LOW,
    },
    inputContainerStyle: {marginTop: 8, marginBottom: 8},
    FormTextContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    radioButtonStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
      cursor: 'pointer',
    },
    forgotPasswordTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.SECONDARY_HIGH,
      cursor: 'pointer',
    },
    loginButtonStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 16,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: colors.BRAND_MEDIUM,
      borderRadius: 8,
      cursor: 'pointer',
    },
    loginTextStyle: {...fonts.ACTION, color: colors.BRAND_HIGH},
  };
};
export const LoginFormMobile = ({theme: {fonts, colors, icons}}) => {
  return {
    WelcomeBackIcon: icons.WelcomeBackToLocomo,
    UnthinkableIcon: icons.UnthinkableLoginWeb,
    iconContainerStyle: {
      marginTop: 25,
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerStyle: {
      justifyContent: 'center',
      backgroundColor: colors.BACKGROUND,
      padding: 30,
      borderRadius: 20,
      marginTop: 16,
      marginRight: 16,
      marginBottom: 16,
      marginLeft: 16,
      flex: 1,
    },
    headerContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 50,
    },
    headerTextStyle: {
      ...fonts.TITLE,
      color: colors.NEUTRAL_MEDIUM,
      marginBottom: 5,
    },
    googleLoginContainerStyle: {
      marginTop: 25,
      marginBottom: 25,
      alignItems: 'center',
    },
    saparatorContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E5E5EA',
      height: 1,
      marginTop: 40,
      marginBottom: 40,
    },
    separatorTextStyle: {
      height: 23,
      width: 30,
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    inputContainerStyle: {marginTop: 20, marginBottom: 20},
    FormTextContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    radioButtonStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
      cursor: 'pointer',
    },
    forgotPasswordTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.SECONDARY_HIGH,
      cursor: 'pointer',
    },
    loginButtonStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 25,
      paddingTop: '6%',
      paddingBottom: '6%',
      backgroundColor: colors.BRAND_MEDIUM,
      borderRadius: 8,
      cursor: 'pointer',
    },
    loginTextStyle: {...fonts.ACTION, color: colors.BRAND_HIGH},
  };
};

export const GoogleLoginButton = ({theme: {fonts, colors, icons}}) => {
  return {
    googleIcon: icons.GoogleLogo,
    containerStyle: {
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.SECONDARY_UPPER_MEDIUM,
      height: 48,
      width: '100%',
    },
    textContainerStyle: {
      backgroundColor: colors.SECONDARY_UPPER_MEDIUM,
      height: '100%',
      flex: 1,
      justifyContent: 'center',
    },
    iconContainerStyle: {
      width: 46,
      alignItems: 'center',
      justifyContent: 'center',
    },
    textStyle: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
      marginLeft: 52,
      marginRight: 52,
    },
  };
};
export const GoogleLoginButtonMobile = ({theme: {fonts, colors, icons}}) => {
  return {
    googleIcon: icons.GoogleLogo,
    containerStyle: {
      cursor: 'pointer',
      paddingTop: 11,
      paddingBottom: 11,
      marginTop: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.BRAND_HIGH,
    },
    iconStyle: {height: 20, width: 20},
    textStyle: {
      ...fonts.ACTION,
      color: '#133D00',
      marginLeft: 8,
    },
  };
};
