import React from 'react';
import {Table} from '../../../components/table/Table';

export const AllocatedTeamTable = props => {
  let {
    route: {params},
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const {role} = params;
  return (
    <Table
      params={params}
      api={`/temporaryAssignmentWithPlanAndCost`}
      columns={[
        {
          type: 'text',
          field: 'employee.name',
          header: 'Members',
        },
        // {
        //   type: 'text',
        //   field: 'role',
        //   header: 'Role',
        // },
        // {
        //   type: 'text',
        //   field: 'object.name',
        //   header: 'Project',
        // },
        // {
        //   type: 'number',
        //   field: 'plan',
        //   header: 'Plan',
        // },
        {
          type: 'percentage',
          field: 'percentage',
          header: 'Percentage',
        },
        ...(role == 'admin'
          ? [
              {
                type: 'currency',
                field: 'ctc_amount',
                header: 'Cost',
              },
            ]
          : []),
      ]}
    />
  );
};
