import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  CurrencyInput as CurrencyInputComponent,
  CurrencyRenderer as CurrencyRendererComponent,
} from '@unthinkable/react-currency-input';
import {CurrencyInput as CurrencyInputStyle} from './theme';

export const CurrencyInput = props => {
  const styles = useStyles(CurrencyInputStyle);
  return <CurrencyInputComponent styles={styles} {...props} />;
};

export const CurrencyRenderer = props => {
  const styles = useStyles(CurrencyInputStyle);
  return <CurrencyRendererComponent styles={styles} {...props} />;
};
