import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab/TabView';
import {TableHeader} from '../../../components/table/Headers';
import {
  ActiveProjectEscalationTable,
  ResolvedProjectEscalationTable,
} from './ProjectEscalationTable';

export const ProjectEscalationTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {params: filterParams} = filterValues;
  let filter = {...params, ...filterParams};
  const addOnFilter = {
    project: params._id,
  };
  const tabs = {
    active: {
      label: 'Active',
      view: (
        <ActiveProjectEscalationTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),
      eventSourceId: 'projectescalations',
      actions: [
        <AddButton
          title=" Add Escalation"
          eventSourceId="projectescalations"
          view="add-escalation"
          params={params}
        />,
      ],
    },
    resolved: {
      label: 'Resolved',
      view: (
        <ResolvedProjectEscalationTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={addOnFilter}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title={`Escalation (${params.project})`} />}
      tabs={tabs}
      params={params}
      {...props}
    />
  );
};
