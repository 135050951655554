import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

const updateStatus = ({row, status, invoke}) => {
  invoke({
    uri: `/backLinks/${row?._id}`,
    props: {
      status: status,
    },
  });
};

export const BackLinkTable = props => {
  const {
    route: {params = {}},
    navigation,
    status,
  } = props;

  const invoke = useInvoke({
    eventSourceId: 'BackLink',
    method: 'put',
  });

  return (
    <Table
      {...props}
      params={params}
      eventSourceId="BackLink"
      onRowPress={({row}) => {
        navigation.navigate('edit-back-link', {
          backLink: row,
        });
      }}
      addOnFilter={{status}}
      fields={{
        source_url: 1,
        destination_url: 1,
        anchor_text: 1,
        backlink_type: 1,
        date_discovered: 1,
        last_checked: 1,
        status: 1,
        domain_authority: 1,
        page_authority: 1,
      }}
      api={`/backlinks`}
      columns={[
        {
          type: 'text',
          field: 'anchor_text',
          header: 'Anchor Text',
        },
        {
          type: 'text',
          field: 'backlink_type',
          header: 'Type',
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
        },
      ]}
      moreActions={[
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          visible: status != 'active',
          onPress: ({row}) => updateStatus({row, status: 'active', invoke}),
        },
        {
          title: 'Mark Broken',
          confirm: {
            title: 'Mark Broken',
            message: 'Are you sure you want to Mark Broken?',
          },
          visible: status != 'broken',
          onPress: ({row}) => updateStatus({row, status: 'broken', invoke}),
        },
        {
          title: 'Mark Removed',
          confirm: {
            title: 'Mark Removed',
            message: 'Are you sure you want to Mark Removed?',
          },
          visible: status != 'removed',
          onPress: ({row}) => updateStatus({row, status: 'removed', invoke}),
        },
      ]}
    />
  );
};
