import React from 'react';
import {View} from '@unthinkable/react-core-components';

export const DotSeparator = ({size = 4, color = '#A3A3A3'}) => {
  return (
    <View
      style={{
        height: size,
        width: size,
        borderRadius: size,
        backgroundColor: color,
      }}
    />
  );
};
