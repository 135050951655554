import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {OrdersAndDeliveriesForTAndMTab} from './OrdersAndDeliveriesForTAndM';
import {OrdersAndDeliveriesForFixedTab} from './OrdersAndDeliveriesForFixed';
import { OverduePaymentReportTable } from './OverduePaymentReport';

export const OrdersAndDeliveriesMenu = props => {
  const menus = [
    {
      label: 'Orders And Deliveries',
      section: true,
      menus: [
        {
          label: 'T&M',
          view: <OrdersAndDeliveriesForTAndMTab />,
        },
        {
          label: 'Fixed',
          view: <OrdersAndDeliveriesForFixedTab />,
        },
        {
          label: 'Overdue Payment',
          view: <OverduePaymentReportTable />,
        },
      ],
    },
  ];
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
