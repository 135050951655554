import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';

export const MergeForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {
    targetField,
    uri,
    source,
    eventSourceId,
    title,
    secondaryTitle,
  } = params || {};

  const defaultValues = {source, delete: true};

  const invoke = useInvoke({method: 'post', eventSourceId});
  const onSubmit = props => {
    invoke({
      uri: uri,
      props: {
        source: props?.source,
        target: props?.target,
        deleteSource: props?.delete,
      },
    });
  };

  return (
    <Form
      header={{title, secondaryTitle}}
      onSubmit={onSubmit}
      submitAction="Merge"
      defaultValues={defaultValues}
      successMessage="Merged successfully"
      layoutFields={[
        {
          label: ' Target',
          field: 'target',
          type: 'autoComplete',
          required: true,
          ...targetField,
        },
        {
          type: 'checkbox',
          label: 'Do you want to delete the source object after merging?',
          field: 'delete',
        },
      ]}
      {...props}
    />
  );
};
