import {useCallback} from 'react';

export const useAccessRight = ({accessRights} = {}) => {
  return useCallback(
    props => {
      const {navigator, resource} = props;
      if (!accessRights || !accessRights.length) {
        return false;
      }

      accessRights = accessRights.filter(doc => doc.access_group);

      const accessRight = accessRights.find(
        ({access_group: {name: accessNavigator} = {}}) =>
          accessNavigator === navigator,
      );
      if (!accessRight) {
        return false;
      }
      if (!resource) {
        return true;
      }
      const {resources = [], access_level} = accessRight;
      if (access_level === 'full') {
        return true;
      }

      const found = resources.find(doc => {
        let {model: {name: resourceName} = {}, resource: accessResource} =
          doc || {};
        return resourceName === resource || accessResource === resource;
      });

      if (!found) {
        return false;
      }
      return true;
    },
    [accessRights],
  );
};
