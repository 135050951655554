import React, {useMemo} from 'react';
import {MultiCheckboxColumnContainer, MultiCheckboxContainer} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';
import {BaseCheckbox} from './Checkbox';

const MultiCheckbox = ({
  options = [],
  onChangeValue,
  value: defaultValue = [],
  direction = 'column',
  ...props
}) => {
  const {styles} = props;
  const updatedOptions = useMemo(() => {
    return options.map(option => {
      if (typeof option === 'string') {
        return {label: option, value: option};
      }
      return option;
    });
  }, [options]);

  const handleCheckboxChange = value => {
    const newOptions = [...defaultValue];
    let index = defaultValue?.findIndex(val => value === val);
    if (index >= 0) {
      newOptions.splice(index, 1);
    } else {
      newOptions.push(value);
    }
    onChangeValue(newOptions);
  };

  const WrapperComponent =
    direction === 'column'
      ? MultiCheckboxColumnContainer
      : MultiCheckboxContainer;

  return (
    <InputWrapper {...props}>
      <WrapperComponent styles={styles}>
        {updatedOptions.map(option => {
          let isSelected = defaultValue?.includes(option.value);
          return (
            <BaseCheckbox
              {...props}
              key={option.value}
              value={!!isSelected}
              option={option}
              styles={styles}
              onChangeValue={() => {
                handleCheckboxChange(option.value);
              }}
            />
          );
        })}
      </WrapperComponent>
    </InputWrapper>
  );
};

export default MultiCheckbox;
