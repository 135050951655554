import React from 'react';
import {Table} from '../../../components/table/Table';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import moment from 'moment';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';

export const SalaryTable = props => {
  const params = props?.route?.params;
  const {period} = params;

  let previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('Month').toDate(),
    );
    previousPeriod.to = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').endOf('Month').toDate(),
    );
  }
  return (
    <Table
      api={`/vendorinvoices/direct-expense`}
      params={{...params, invoice_type: 'Salary'}}
      limit={5000}
      columns={[
        {
          type: 'text',
          field: 'payee_id.name',
          header: 'Name',
        },
        {
          width: 300,
          type: 'currency',
          field: 'voucher_amount',
          compareField: 'pre_voucher_amount',
          header: 'Amount',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: ({row}) => {
            props.navigation.navigate('vendor-invoices', {
              ...params,
              payee_id: row.payee_id?._id,
            });
          },
          onComparePress: ({row}) => {
            props.navigation.navigate('vendor-invoices', {
              ...params,
              payee_id: row.payee_id?._id,
              period: previousPeriod,
            });
          },
          render: ComparisonRender,
        },
      ]}
    />
  );
};
