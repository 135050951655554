import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddTrainingMaterialTypes = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/trainingMaterialTypes',
    ...props,
  });
  return (
    <Form
      header="Add Training Material Type"
      api={'/trainingMaterialTypes'}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
