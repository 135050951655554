import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {ExpenseRequestTable} from './ExpenseRequestTable';
import {useAppStateContext} from '../../../providers/AppState';

export const ExpenseRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {user} = useAppStateContext();
  const {employee} = user;
  const {expense_request_team = []} = employee;
  let expense_filter = {};
  let team_array = [];
  for (const team of expense_request_team) {
    const {requester = []} = team;
    if (requester.includes(employee?._id)) {
      team_array.push(team?._id);
    }
  }
  if (team_array.length > 0) {
    expense_filter = {
      team: {$in: team_array},
    };
  } else if (employee?.id) {
    expense_filter = {
      requester: employee._id,
    };
  }
  const tabs = {
    Draft: {
      label: 'Draft',
      view: (
        <ExpenseRequestTable
          addOnFilter={{status: 'Draft', ...expense_filter}}
          filterValues={filterValues}
          searchValue={searchValue}
          tab={'Draft'}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: '/expenserequest/all',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        ...expense_filter,
        status: 'Draft',
        ...filterValues.filter,
      },
      limit: 500,
      actions: [
        <AddButton title="Request Expense" view={'add-expense-request'} />,
      ],
    },
    Requested: {
      label: 'Requested',
      view: (
        <ExpenseRequestTable
          addOnFilter={{status: 'Requested', ...expense_filter}}
          filterValues={filterValues}
          searchValue={searchValue}
          tab={'Requested'}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: '/expenserequest/all',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        ...expense_filter,
        status: 'Requested',
        ...filterValues.filter,
      },
      limit: 500,
      // actions: [
      //   <AddButton title="Request Expense" view={'add-expense-request'} />,
      // ],
    },
    Approved: {
      label: 'Approved',
      view: (
        <ExpenseRequestTable
          addOnFilter={{status: 'Approved', ...expense_filter}}
          filterValues={filterValues}
          searchValue={searchValue}
          tab={'Approved'}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: '/expenserequest/all',
      limit: 500,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        ...expense_filter,
        ...filterValues.filter,
        status: 'Approved',
      },
    },
    Rejected: {
      label: 'Rejected',
      view: (
        <ExpenseRequestTable
          addOnFilter={{status: 'Rejected', ...expense_filter}}
          filterValues={filterValues}
          tab={'Rejected'}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: '/expenserequest/all',
      limit: 500,
      addOnFilter: {
        ...expense_filter,
        ...filterValues.filter,
        status: 'Rejected',
      },
    },
    TransactionCompleted: {
      label: 'Transaction Completed',
      view: (
        <ExpenseRequestTable
          tab={'Transaction_completed'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Booked',
            ...expense_filter,
          }}
          {...props}
        />
      ),
      eventSourceId: 'expenserequest',
      api: '/expenserequest/all',
      limit: 500,
      addOnFilter: {
        ...expense_filter,
        ...filterValues.filter,
        status: 'Booked',
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
            {
              type: 'date',
              field: 'bill_date',
              // asParam: true,
              label: 'Invoice date',
            },
          ]}
        />,
      ]}
    />
  );
};
