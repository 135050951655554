import React from 'react';
import {Table} from '../../../components/table/Table';
import {useTheme} from '@unthinkable/react-theme';
import {TableHeader} from '../../../components/table/Headers';
import {TextRenderer} from '../../../components/form/Renderers';
import {Text} from '@unthinkable/react-core-components';

export const RenderViewDetails = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="View Detail"
      style={{...rowText, color: colors['ACCENT1_HIGH']}}
    />
  );
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (!row?.when) return null;
  let backgroundColors;
  if (row?.when == 'Positive') {
    backgroundColors = colors['SUCCESS_HIGH'];
  } else if (row?.when == 'No Response' || row?.when == 'Negative') {
    backgroundColors = colors['ERROR_HIGH'];
  }
  return (
    <Text
      style={{
        ...rowText,
        width: 100,
        backgroundColor: backgroundColors,
        color: colors['BACKGROUND'],
        padding: 2,
        borderRadius: 5,
        textAlign: 'center',
      }}>
      {row?.when}
    </Text>
  );
};



export const CampaignMessageTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department, product, row} = params;
  return (
    <Table
      renderHeader={() => {
        return <TableHeader title="Messages" />;
      }}
      searchFields={'name'}
      api={`/campaignMessages`}
      fields={{
        send_after: 1,
        subject: 1,
        when: 1,
      }}
      filter={{
        campaign_id: row?._id,
      }}
      params={params}
      onRowPress={row => {
        navigation.navigate('edit-message', row);
      }}
      columns={[
        {
          header: 'Sequence',
          field: 'send_after',
        },
        {
          header: 'Subject',
          field: 'subject',
        },
        {
          header: 'Response Status',
          render: RenderStatus,
        },
        {
          type: 'text',
          render: RenderViewDetails,
          onPress: ({row}) => {
            navigation.navigate('message-detail', row);
          },
          width: 120,
          align: 'right',
          responsive: 'sm',
        },
      ]}
      {...props}
    />
  );
};

