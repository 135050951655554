import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import moment from 'moment';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const getStartAndEnd = date => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0); // set to start of day
  const endDate = new Date(date);
  endDate.setHours(23, 59, 59, 999); // set to end of day
  return {
    start: startDate,
    end: endDate,
  };
};

const TimesheetForm = props => {
  const {
    route: {params},
  } = props;
  let {user = {}, row = {}} = params;
  let {employee = {}, employee: {product = {}} = {}} = user || {};
  let {onSubmit} = useFormSubmit({
    uri: '/timesheets',
    eventSourceId: ['projectTimesheet', 'nonProjectTimesheet'],
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      api={`/timesheets/${row?._id}`}
      submitAction="Save"
      defaultValues={{
        status: 'Unapproved',
        employee: {_id: employee._id, name: employee.name},
        project: {_id: row?._id, project: row?.project},
        customer: {_id: row?.customer?._id, name: row?.customer?.name},
      }}
      eventSourceId={'timesheet'}
      fields={{
        project: {project: 1, customer: {_id: 1, name: 1}},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1},
        date: 1,
        timesheet_hours: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          size: 6,
          required: true,
        },
        {
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          label: 'Employee',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          readOnly: true,
          size: 6,
        },
        {
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          label: 'Project',
          fields: {project: 1, customer: {_id: 1, name: 1}},
          suggestionField: 'project',
          valueField: 'project',
          required: true,
          size: 6,
          onChangeValue: (value, e, {setFieldValue}) => {
            let {customer = {}} = value || {};
            setFieldValue('customer', customer);
          },
        },
        {
          field: 'customer',
          type: 'autoComplete',
          api: `/customers`,
          label: 'Customer',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          readOnly: true,
          size: 6,
        },

        {
          field: 'timesheet_hours',
          label: 'Hours',
          type: 'number',
          size: 6,
          required: true,
        },
        {
          field: 'description',
          placeholder: 'Description',
          label: 'Description',
          type: 'textArea',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

const TimesheetRequestForm = props => {
  let {user, post} = useAppStateContext();
  let {employee} = user || {};
  let {onSubmit} = useFormSubmit({
    uri: '/timesheets',
    eventSourceId: ['projectTimesheet', 'nonProjectTimesheet'],
    ...props,
  });
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        from_date: getZeroTimeDate(new Date()),
        to_date: getZeroTimeDate(new Date()),
        status: 'New',
        request_date: getZeroTimeDate(new Date()),
        employee: employee,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          placeholder: 'Date',
          width: 300,
          required: true,
          validate: async fieldValue => {
            const {data} = await post({
              uri: `/timesheets/date-validation`,
              method: 'post',
              props: {
                employee: employee?._id,
                date: fieldValue,
              },
            });
            if (data) {
              return data;
            }
          },
          onChangeValue: async (value, e, {setFieldValue}) => {
            let currentdate = new Date();
            if (value) {
              let diff = Number(
                (currentdate - new Date(value)) / (1000 * 60 * 60 * 24),
              ).toFixed();
              if (diff > 3) {
                setFieldValue('status', 'Overdue');
              } else {
                setFieldValue('status', 'New');
              }
            }
          },
        },
        {
          field: 'request_reason',
          placeholder: 'Reason',
          label: 'Reason',
          type: 'textArea',
          // required: true,
        },
      ]}
    />
  );
};

const TimesheetRequestFormRowAction = props => {
  const {
    route: {params},
  } = props;
  let {row = {}} = params;
  let {user} = useAppStateContext();
  let {employee} = user || {};
  let {onSubmit} = useFormSubmit({
    uri: '/timesheets',
    eventSourceId: ['projectTimesheet', 'nonProjectTimesheet'],
    ...props,
  });
  const {status, ...rest} = row;
  row = {...rest};
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        status: 'Log Requested',
        request_date: new Date(),
        ...row,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          placeholder: 'Date',
          size: 6,
          readOnly: true,
        },
        {
          field: 'request_reason',
          placeholder: 'Reason',
          label: 'Reason',
          type: 'textArea',
          // required: true,
        },
      ]}
    />
  );
};

const NonProjectTimesheetLogForm = props => {
  const {
    route: {params},
    readOnly,
  } = props;
  let {user = {}, row = {}} = params;
  let {employee = {}, employee: {product = {}} = {}} = user || {};
  let {onSubmit} = useFormSubmit({
    uri: `/timesheets`,
    eventSourceId: ['projectTimesheet', 'nonProjectTimesheet'],
    ...props,
  });
  const {status, ...rest} = row;
  row = {...rest};
  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      readOnly={readOnly}
      eventSourceId={'timesheet'}
      defaultValues={{
        date: row?.date,
        status: 'Log Done',
        ...row,
      }}
      fields={{
        project: {project: 1, customer: {_id: 1, name: 1}},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1},
        date: 1,
        timesheet_hours: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          placeholder: 'Date',
          size: 6,
          required: true,
          readOnly: true,
        },
        {
          field: 'timesheet_hours',
          placeholder: 'Hours',
          label: 'Hours',
          type: 'number',
          size: 6,
          required: true,
        },
        {
          field: 'description',
          placeholder: 'Write output here',
          // label: 'Output',
          type: 'textArea',
          required: true,
        },
        {
          placeholder: 'Upload Document',
          label: 'Upload Document',
          field: 'attatchment',
          type: 'file',
          options: {bucketName: 'manaze'},
        },
      ]}
      {...props}
    />
  );
};
const ProjectTimesheetLogForm = props => {
  const {
    route: {params},
    readOnly = false,
    api = 'xxxxxx',
  } = props;
  let {row = {}} = params;
  const employeeFilter = row.employee._id;
  let {onSubmit} = useFormSubmit({
    uri: readOnly ? `/timesheets` : `/timesheetprogresses`,
    eventSourceId: ['projectTimesheet', 'nonProjectTimesheet'],
    ...props,
  });
  let defaultValues = {};
  if (readOnly) {
    defaultValues = {
      date: row?.date,
      status: 'Log Done',
      ...row,
    };
  } else {
    defaultValues = {
      date: row?.date,
      status: 'Log Done',
      timesheet: row?._id,
      employee: row?.employee,
    };
  }

  const {fetch} = useAppStateContext();
  const {status, ...rest} = row;
  row = {...rest};
  return (
    <Form
      api={api}
      onSubmit={onSubmit}
      submitAction="Save"
      eventSourceId={'timesheet'}
      readOnly={readOnly}
      defaultValues={{...defaultValues}}
      fields={{
        project: {project: 1, customer: {_id: 1, name: 1}},
        customer: {_id: 1, name: 1},
        employee: {_id: 1, name: 1},
        date: 1,
        timesheet_hours: 1,
        log_more_than_nine_hrs: 1,
        billable_to_customer_hours: 1,
        description: 1,
        attatchment: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'date',
          label: 'Date',
          placeholder: 'Date',
          size: 6,
          required: true,
          readOnly: true,
        },
        {
          placeholder: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/employeeassignments/projects`,
          label: 'Project',
          fields: {project: 1, customer: {_id: 1, name: 1}},
          filter: ({values}) => {
            return {
              to_date: {$gte: values.date},
              from_date: {$lte: values.date},
              is_wrong_input: {$in: [null, false]},
              employee: employeeFilter,
            };
          },
          suggestionField: 'project',
          valueField: 'project',
          secondarySuggestionField: 'order_number',
          // required: true,
          size: 6,
          onChangeValue: async (value, e, {setFieldValue, values}) => {
            const date = getStartAndEnd(new Date(values?.date));
            const {start: start_date, end: end_date} = date;
            if (value?._id) {
              const {data: projectCustomerData} = await fetch({
                uri: `/projects/${value?._id}`,
                props: {
                  customer: {_id: 1, name: 1},
                },
              });
              const {data: employeeAssignmentData} = await fetch({
                uri: '/employeeassignments',
                props: {
                  filter: {
                    is_wrong_input: {$in: [null, false]},
                    is_actual_allocation: true,
                    project_id: value?._id,
                    from_date: {$lte: end_date},
                    to_date: {$gte: start_date},
                    employee: employeeFilter,
                  },
                  fields: {
                    skill_requirement: 1,
                    milestone_id: 1,
                    order: 1,
                    from_date: 1,
                    to_date: 1,
                  },
                  only: true,
                },
              });
              setFieldValue('customer', projectCustomerData?.customer);
              setFieldValue(
                'skill_requirement',
                employeeAssignmentData?.skill_requirement
                  ? employeeAssignmentData?.skill_requirement
                  : void 0,
              );
              setFieldValue(
                'milestone',
                employeeAssignmentData?.milestone_id
                  ? employeeAssignmentData?.milestone_id
                  : void 0,
              );
              setFieldValue(
                'employeeassignment',
                employeeAssignmentData?._id
                  ? employeeAssignmentData?._id
                  : void 0,
              );
            }
          },
        },
        {
          field: 'timesheet_hours',
          placeholder: 'Attendance Hours',
          label: 'Attendance Hours',
          type: 'number',
          size: 6,
          required: true,
        },
        {
          field: 'billable_to_customer_hours',
          placeholder: 'Billable to customer hours',
          label: 'Billable to customer hours',
          type: 'number',
          size: 6,
          required: true,
        },
        {
          field: 'description',
          placeholder: 'Write output here',
          label: 'Description',
          type: 'textArea',
          required: true,
        },
        {
          placeholder: 'Upload Document',
          label: 'Upload Document',
          field: 'attatchment',
          type: 'file',
          options: {bucketName: 'manaze'},
        },
        {
          type: 'checkbox',
          field: 'log_more_than_nine_hrs',
          label: 'Are you sure you want to log progress of more that 9 hours?',
          editorAsRenderer: true,
          visible: ({values}) =>
            values.timesheet_hours && values.timesheet_hours > 9,
        },
      ]}
      {...props}
    />
  );
};

export const AddTimesheetForm = props => {
  return (
    <TimesheetRequestFormRowAction
      attendancetForm
      header="Create Timesheet"
      {...props}
    />
  );
};

export const EditTimesheetForm = props => {
  return <TimesheetForm header="Update Timesheet" mode="edit" {...props} />;
};

export const AddTimesheetRequestForm = props => {
  return <TimesheetRequestForm header={'Add Timesheet'} {...props} />;
};

export const LogTimesheetNonProjectForm = props => {
  return <NonProjectTimesheetLogForm header={'Log Timesheet'} {...props} />;
};
export const LogTimesheetProjectForm = props => {
  return <ProjectTimesheetLogForm header={'Log Timesheet'} {...props} />;
};

export const EditLogTimesheetProjectForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  return (
    <ProjectTimesheetLogForm
      header={'Log Timesheet'}
      mode="edit"
      api="/timesheetprogresses"
      filter={{
        _id: row?._id,
      }}
      {...props}
    />
  );
};

export const AddTimesheetRequestFormRowAction = props => {
  return (
    <TimesheetRequestFormRowAction header={'Request Timesheet'} {...props} />
  );
};

export const LogTimesheetProjectFormDetails = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  return (
    <ProjectTimesheetLogForm
      header={{
        title: 'Timesheet Log',
        secondaryTitle: moment(row?.date).format('DD MMM YY'),
      }}
      readOnly={true}
      {...props}
    />
  );
};

export const LogTimesheetNonProjectFormDetails = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  return (
    <NonProjectTimesheetLogForm
      header={{
        title: 'Timesheet Log',
        secondaryTitle: moment(row?.date).format('DD MMM YY'),
      }}
      readOnly={true}
      {...props}
    />
  );
};
