import {
  Col,
  Row,
  Styled,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const TabBarRowColStyled = Styled(Col)`
${props => props.styles?.tabBarRowStyle?.containerStyle}
`;

export const TabBarRowStyled = Styled(Row)`
align-items: center;
margin-bottom: 12px;
${props => props.styles?.tabBarRowStyle?.containerStyle}
`;

export const TabContainer = Styled(View)`
  flex: 1;
  overflow: hidden;
`;

export const TabBarContainer = Styled(View)`
  flex-direction: row;
`;

export const TabItemWrapper = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['fullWidth', 'width', 'minWidth', 'index', 'isLast'].includes(prop),
})`
justify-content:center;
background-color:#EEEEED;
overflow:hidden;
flex-direction:row;
${props => props.styles?.tabItemStyle?.itemWrapperStyle}
${props =>
  props.width
    ? {width: props?.width}
    : props?.fullWidth
    ? {flex: 1, overflow: 'hidden'}
    : void 0}
${props => (props.minWidth ? {minWidth: props?.minWidth} : void 0)}
${props =>
  props.index === 0
    ? {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        ...props.styles?.tabItemStyle?.firstItemWrapperStyle,
      }
    : void 0}
${props =>
  props?.isLast
    ? {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        ...props.styles?.tabItemStyle?.lastItemWrapperStyle,
      }
    : void 0}
`;

export const TabItemStyled = Styled(TouchableOpacity).withConfig({
  shouldForwardProp: prop =>
    !['fullWidth', 'width', 'minWidth', 'selected'].includes(prop),
})`
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: transparent;
  flex-direction: row;
  overflow:hidden;
  flex:1;
  height:100%;
${props => props.styles?.tabItemStyle?.itemStyle}
${props =>
  props.selected
    ? {
        borderRadius: 6,
        backgroundColor: '#FFFFFF',
        borderColor: '#EEEEED',
        ...props.styles?.tabItemStyle?.activeItemStyle,
      }
    : void 0}
`;

export const TabLabel = Styled(Text)`
  color: #7D7D82;
  fontSize: 13px;
  lineHeight: 18px;
${props => props.styles?.tabItemStyle?.labelStyle}
${props =>
  props.selected ? props.styles?.tabItemStyle?.activeLabelStyle : void 0}
  ${props => (props.color ? {color: props.color} : void 0)}
`;
