import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchFormData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const RPSForm = props => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'rps',
    uri: '/opportunities',
    ...props,
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'rfr_type',
          options: [
            {
              label: 'Customer',
              value: 'Customer',
            },
            {
              label: 'Potential Customer',
              value: 'PotentailCustomer',
            },
          ],
        },
        {
          placeholder: 'Customer',
          label: 'Customer',
          field: 'customer_id',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.rfr_type && values.rfr_type === 'Customer',
        },
        {
          label: 'Potential Customer',
          placeholder: 'Potential Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: `/potentialcustomers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values &&
            values.rfr_type &&
            values.rfr_type === 'PotentailCustomer',
        },
        {
          type: 'textArea',
          field: 'requirement',
          label: 'Requirement',
        },
        {
          type: 'richText',
          field: 'requirement_description',
          label: 'Requirement Description',
        },
        {
          field: 'skill',
          label: 'Skills',
          type: 'autoComplete',
          api: '/tools',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'date',
          field: 'due_date',
          label: 'Due Date',
          required: true,
          size: 6,
        },
        {
          type: 'number',
          field: 'budget',
          label: 'Budget',
          size: 6,
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Technical Consultant',
          field: 'tech_person',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Solution Consultant',
          field: 'primary_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Business Analyst',
          field: 'buisness_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Buisness Consultant',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          placeholder: 'Attachment',
          label: 'Attachment',
          field: 'requirement_document',
          multiple: true,
          type: 'file',
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const AddRPSForm = props => {
  const {
    route: {params},
  } = props;
  const {route} = props;
  const {product} = params;
  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {
    owner: employeeId,
    opportunity_type: 'RPS',
    product: product,
    organization: params?.organization,
    rfr_type: 'Customer',
    status: 'Active',
    creation_date: getZeroTimeDate(new Date()),
  };

  if (route?.name == 'add-rps-proposal') {
    console.log('came till here');
    const {leadId} = params;
    const {data} = useFetchFormData({
      api: '/potentialCustomers',
      filter: {
        _id: leadId,
      },
      fields: {
        name: 1,
        rfr_type: 1,
        owner: {_id: 1, name: 1},
        industry: {_id: 1, name: 1},
        department: {_id: 1, name: 1},
        product: {_id: 1, name: 1},
        organization: {_id: 1, name: 1},
      },
    });
    if (!data) {
      return null;
    }
    const {_id, owner, department, product, organization, name, ...restdata} =
      data;

    defaultValues = {
      ...restdata,
      account_owner: owner,
      potential_customer: {_id: leadId, name: name},
      rfr_type: 'PotentailCustomer',
      opportunity_type: 'RPS',
      owner: employeeId,
      organization,
      product,
      status: 'Active',
      creation_date: getZeroTimeDate(new Date()),
    };
  }

  return (
    <RPSForm {...props} header={'Add RPS'} defaultValues={defaultValues} />
  );
};

export const EditRPSForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;

  const {data} = useFetchFormData({
    api: `/opportunity/${row?._id}/rps`,
  });

  if (!data) {
    return null;
  }
  return <RPSForm {...props} header={'Edit RPS'} defaultValues={data} />;
};
