import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ReimbursementHead = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/reimbursementheads',
    eventSourceId: 'reimbursementhead',
  });
  return (
    <Form
      api={`/reimbursementhead/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        {
          label: 'Type',
          field: 'type',
          type: 'autoComplete',
          options: [
            {label: 'Certificate', value: 'Certificate'},
            {label: 'Regular', value: 'Regular'},
          ],
          suggestionField: 'label',
          valueField: 'value',
          keyField: 'value',
        },
        {
          label: 'Account',
          type: 'autoComplete',
          field: 'account',
          api: '/accounts',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddReimbursementHead = props => {
  return <ReimbursementHead header="Add Reimbursement Head" {...props} />;
};

export const EditReimbursementHead = props => {
  return (
    <ReimbursementHead
      mode="edit"
      header={'Update Reimbursement Head'}
      {...props}
    />
  );
};
