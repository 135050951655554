import {Styled, View} from '@unthinkable/react-core-components';

export const StyledPopperContainer = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['width', 'height', 'maxWidth', 'maxHeight', 'borderRadius'].includes(
      prop,
    ),
})`
  background-color: #FFFFFF;
  overflow:hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  ${({width, height, maxWidth, maxHeight, borderRadius = 8}) => {
    return {
      width,
      height,
      borderRadius,
      maxWidth,
      maxHeight,
    };
  }}   
  ${props => props.styles?.container}
`;
