import React from 'react';
import moment from 'moment';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {
  FileRendererStyles,
  LinkRendererStyles,
  TextRendererStyles,
} from './theme';
import {useToast} from '@unthinkable/react-toast';

export const TextRenderer = ({value, style}) => {
  const {textStyle} = useStyles(TextRendererStyles);
  if (value !== undefined && value !== null && typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  return <Text style={{...textStyle, ...style}}>{value || ''}</Text>;
};

export const PasswordRenderer = ({value}) => {
  if (value?.length) {
    value = value?.length ? Array(value.length).fill('*').join('') : '';
  }
  return <TextRenderer value={value} />;
};

export const DateRenderer = ({value}) => {
  if (value) {
    value = moment(value).format('DD MMM YY');
  }
  return <TextRenderer value={value} />;
};

export const ColorRenderer = ({value}) => {
  if (!value) {
    return null;
  }
  return (
    <Row gap={6} style={{alignItems: 'center'}}>
      <View
        style={{
          height: 20,
          width: 20,
          backgroundColor: value,
          borderRadius: 4,
        }}
      />
      <TextRenderer value={value} />
    </Row>
  );
};

export const AutoCompleteRenderer = props => {
  const {chip} = props;
  const displayValue = getValueToDisplay(props);
  if (chip) {
    return <ChipRender value={displayValue} {...props} />;
  } else {
    return <TextRenderer value={displayValue} />;
  }
};

export const MultiAutoCompleteRenderer = props => {
  let {value} = props;
  if (!value) {
    return null;
  }
  if (!Array.isArray(value)) {
    value = [value];
  }
  return (
    <Wrap gap={8}>
      {value.map((doc, index) => {
        const displayValue = getValueToDisplay({...props, value: doc});
        return <ChipRender {...props} value={displayValue} />;
      })}
    </Wrap>
  );
};

export const FileRenderer = ({value, fileText}) => {
  if (!value) {
    return null;
  }
  let {resourceUrl} = useAppStateContext();

  const styles = useStyles(FileRendererStyles);
  const {textStyle} = styles;

  let Render = ({value: valueObj}) => {
    return (
      <TouchableOpacity
        onPress={() => {
          const url = resourceUrl(valueObj);
          Linking.openURL(url, '_blank');
        }}>
        <TextRenderer
          style={textStyle}
          numberOfLines={1}
          value={
            valueObj?.filename
              ? fileText
                ? fileText
                : valueObj?.filename
              : '-'
          }
        />
      </TouchableOpacity>
    );
  };

  if (Array.isArray(value)) {
    Render = value.map(valueObj => <Render value={valueObj} />);
    return <Row gap={12}>{Render}</Row>;
  }

  return <Render value={value} />;
};

export const LinkRenderer = ({value, title, linkText, icon}) => {
  if (!value) return null;

  const toast = useToast();

  const styles = useStyles(LinkRendererStyles);
  const {textStyle} = styles;

  return (
    <TouchableOpacity
      title={title}
      onPress={() => {
        try {
          Linking.openURL(value, '_blank');
        } catch (err) {
          toast({message: 'Invalid URL', type: 'Error'});
        }
      }}>
      <Row gap={8} style={{alignItems: 'center'}}>
        {linkText || !icon ? (
          <TextRenderer
            style={textStyle}
            numberOfLines={1}
            value={linkText || value}
          />
        ) : (
          void 0
        )}
        {icon ? (
          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <Image source={icon} />
          </View>
        ) : (
          void 0
        )}
      </Row>
    </TouchableOpacity>
  );
};
