import React from 'react';
import {getCurrentMonth} from '@unthinkable/react-filters';
import {
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {BVGReport, BvgList} from './BackgroundVerificationTables';

export const BackgroundVerificationTabs = props => {
  let {
    route: {params},
  } = props;
  const {department} = params;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {
      'department': department,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.due = {
    label: 'BVG',
    params: {
      ...params,
    },
    limit: 5000,
    eventSourceId: 'bvg',
    view: (
      <BVGReport
        {...props}
        search={searchValue}
        filter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
      ]}
      {...props}
    />
  );
};

export const BackgroundVerificationRepotTabs = props => {
  const {route: {params} = {}, navigation} = props;
  const {filter = {}} = params;
  const {filterValues, onChangeFilter, searchValue} = useFilter({});
  let tabs = {};

  tabs.due = {
    label: 'Data',
    api: '/employeeSuggestions',
    // api: '/background-verification/due-this-month',
    addOnFilter: filter,

    view: (
      <BvgList
        {...props}
        search={searchValue}
        filter={filter}
        params={params}
      />
    ),
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
