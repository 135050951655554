import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AttachmentCountRender} from '../../../components/table/Renders';

const issueAttachments = {
  render: AttachmentCountRender,
  type: 'file',
  field: 'file',
  header: 'Refer',
  showImage: true,
  count: true,
  width: 150,
  align: 'center',
};

export const FeatureSuggestionTable = () => {
  return (
    <Table
      api={`/featuresuggestions`}
      fields={{
        issue: 1,
        priority: 1,
        createdAt: 1,
        file: 1,
      }}
      sort={{createdAt: -1}}
      renderHeader={() => <TableHeader title="Feature Suggestions" />}
      columns={[
        {
          type: 'textArea',
          field: 'issue',
          header: 'Suggestion',
        },
        {
          type: 'text',
          field: 'priority',
          header: 'Priority',
          width: 150,
        },
        issueAttachments,
        {
          header: 'Created On',
          field: 'createdAt',
          align: 'center',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 200,
        },
      ]}
    />
  );
};
