export const useVoucherActions = ({ navigation }) => {
    const activityLogDetailForVoucher = ({ row }) => {
        navigation.navigate(`activity-logs`, {
            _id: row._id,
            populate: [
                { path: 'product', select: { name: 1 } },
                { path: 'organization', select: { name: 1 } },
                { path: 'location', select: { name: 1 } },
                { path: 'voucher_type_id', select: { name: 1 } },
            ],
            api: `/vouchers/${row?._id}/activityLogs`,
            displayFields: {
                narration: {
                    label: 'Narration',
                },
                voucher_date: {
                    label: 'Vouchcer Date',
                },
                cr_amount: {
                    label: 'Cr Amount',
                },
                dr_amount: {
                    label: 'Dr Amount',
                },
                product: {
                    label: 'Product',
                    field: 'product.name',
                },
                organization: {
                    label: 'Organization',
                    field: 'organization.name',
                },
                location: {
                    label: 'Location',
                    field: 'location.name',
                },
                voucher_type_id: {
                    label: 'Voucher Type',
                    field: 'voucher_type_id.name'
                }
            }
        });
    };

    return {
        activityLogDetailForVoucher,
    };
};