import React from 'react';
import {
  PerformanceAllTab,
  PerformanceTab,
  ProjectUnassignedTab,
} from '../../pmt/views/PerformanceTab';
import {ProjectGrossMarginTab} from '../../pmt/views/ProjectGrossMarginTable';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {
  OrderTableWithDueAllocationsTab,
  OrderTableWithInternalDueAllocationsTab,
} from '../../order/views/OrderTable';
import {AllocationOnOrderTypeBasisTab} from '../../order/views/AllocationTab';
import {ProjectWiseAllocationDueTab} from '../../order/views/ProjectDueAllocationsTable';
import {PendingProjectApprovalsTable} from '../../pmt/views/PendingProjectApprovals';
import {ProjectEfficiencyTab} from '../../pmt/views/ProjectEfficiencyTab';
import {OrdersAndDeliveriesForTAndMTab} from '../../order/views/OrdersAndDeliveriesForTAndM';
import {OrdersAndDeliveriesForFixedTab} from '../../order/views/OrdersAndDeliveriesForFixed';
import {NewProjectTab} from './NewProjectTab';
import {OrderWiseAllocationsTab} from '../../order/views/FixedOrderWiseAllocations';
import {AllocatedResourceTab} from '../../order/views/AllocatedResourceTab';
import {FixedOrderTabForRM, TMOrderTabRM} from '../../order/views/OrderTab';
import {GrowthAndBillingTab} from '../../order/views/CurrentBusinessTAndM';
import {
  PerformanceCompletedAllTab,
  PerformanceCompletedTab,
} from '../../pmt/views/PerformanceCompletedTab';
import {ProjectWiseAllocationTab} from '../../order/views/ProjectWiseAllocationsTab';
import {ShiftAllowanceForRMTab} from '../../order/views/ShiftAllowanceForRM';
import {ClientFeedbackReportTable} from '../../pmt/views/ResourceWiseProjectFeedback';

const ProjectGroup = createMenuNavigator();
export const organizationProjectNavigator = props => {
  const {
    route: {params},
  } = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <ProjectGroup.Navigator
      canAccess={canAccess}
      data-navigator-id="ProjectGroup"
      {...props}>
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledNew"
        name="New"
        props={{user: user, ...props}}
        component={NewProjectTab}
        options={{
          label: 'New',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledUnassigned"
        name="Unassigned"
        component={ProjectUnassignedTab}
        props={{user: user, project_status: 'a', tab: 'Unassigned', ...props}}
        options={{
          label: 'Unassigned(Active)',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledAssigned"
        name="Assigned"
        component={PerformanceTab}
        props={{
          user: user,
          project_status: 'a',
          tab: 'Assigned',
          is_project_assign: true,
          ...props,
        }}
        options={{
          label: 'Assigned(Active)',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectAllActive"
        name="All Active"
        component={PerformanceAllTab}
        props={{
          user: user,
          project_status: 'a',
          tab: 'All Active',
          is_project_assign: true,
          ...props,
        }}
        options={{
          label: 'All (Active)',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectHold"
        name="On-Hold"
        props={{project_status: 'h', tab: 'On-hold'}}
        component={PerformanceTab}
        options={{
          label: 'On-Hold',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectCompleted"
        name="Completed"
        props={{project_status: 'c', tab: 'Completed'}}
        component={PerformanceCompletedTab}
        options={{
          label: 'Closed',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectAllCompleted"
        name="All Completed"
        props={{project_status: 'c', tab: 'Completed'}}
        component={PerformanceCompletedAllTab}
        options={{
          label: 'All Closed',
        }}
      />
      <ProjectGroup.Group>
        <ProjectGroup.Screen
          data-resource-id="ProjectPendingApprovals"
          name="Pending Approvals"
          component={PendingProjectApprovalsTable}
          options={{
            label: 'Pending Approvals',
          }}
        />
      </ProjectGroup.Group>
      <ProjectGroup.Group>
        <ProjectGroup.Section
          data-resource-id="AllocationsDue"
          options={{label: 'Allocations Due'}}>
          <ProjectGroup.Screen
            data-resource-id="AllocationsDue"
            name="Billed Requests"
            component={OrderTableWithDueAllocationsTab}
            props={{user: user, ...props}}
            options={{
              label: 'Billed Requests',
            }}
            key="billed"
          />
          <ProjectGroup.Screen
            data-resource-id="AllocationsDue"
            name="Internal Requests"
            component={OrderTableWithInternalDueAllocationsTab}
            props={{user: user, ...props}}
            options={{
              label: 'Internal Requests',
              api: '/getOrdersWithDueAllocations',
              addOnFilter: {order_type: 'ot', is_internal: true},
            }}
          />
          <ProjectGroup.Screen
            data-resource-id="AllocationsDue"
            name="Skill-wise"
            component={AllocationOnOrderTypeBasisTab}
            key="internal"
            employeeMenu={true}
            props={{user: user, ...props}}
            options={{
              label: 'Skill-wise Requests',
            }}
          />
          <ProjectGroup.Screen
            data-resource-id="AllocationsDue"
            name="Project Wise"
            component={ProjectWiseAllocationDueTab}
            options={{
              label: 'Project-wise Requests',
            }}
          />
        </ProjectGroup.Section>
      </ProjectGroup.Group>
      <ProjectGroup.Section
        data-resource-id="CurrentAllocations"
        options={{label: 'Allocations Completed'}}>
        <ProjectGroup.Screen
          data-resource-id="CurrentAllocations"
          name="Order Wise"
          component={OrderWiseAllocationsTab}
          props={{user: user, ...props}}
          options={{
            label: 'Order Wise',
          }}
        />
        <ProjectGroup.Screen
          data-resource-id="CurrentAllocations"
          name="Project Wise"
          component={ProjectWiseAllocationTab}
          props={{user: user, ...props}}
          options={{
            label: 'Project Wise',
          }}
        />
        <ProjectGroup.Screen
          data-resource-id="CurrentAllocations"
          name="Employee Wise"
          component={AllocatedResourceTab}
          props={{user: user, ...props}}
          options={{
            label: 'Employee Wise',
          }}
        />
        <ProjectGroup.Screen
          data-resource-id="CurrentAllocations"
          name="ShiftAllowance"
          component={ShiftAllowanceForRMTab}
          props={{user: user, ...props}}
          options={{
            label: 'Shift Allowance',
          }}
        />
      </ProjectGroup.Section>
      <ProjectGroup.Group>
        <ProjectGroup.Screen
          data-resource-id="OrderAndDeliveriesT&M"
          name="T&M"
          component={OrdersAndDeliveriesForTAndMTab}
          options={{
            label: 'T&M Output',
          }}
        />
        <ProjectGroup.Screen
          data-resource-id="OrderAndDeliveriesFixed"
          name="Fixed"
          component={OrdersAndDeliveriesForFixedTab}
          options={{
            label: 'Fixed Output',
          }}
        />
        <ProjectGroup.Screen
          data-resource-id="GrowthAndBillingFixed"
          name="GrowthAndBilling"
          component={GrowthAndBillingTab}
          options={{
            label: 'Growth & Billing',
          }}
        />
      </ProjectGroup.Group>
      <ProjectGroup.Group>
        <ProjectGroup.Section options={{label: 'Order'}}>
          <ProjectGroup.Screen
            data-resource-id="FixedOrder"
            name="FixedOrder"
            component={FixedOrderTabForRM}
            options={{
              label: 'Fixed',
            }}
            props={{
              user: user,
              addOnFilter: {is_internal: {$in: [null, false]}},
              ...props,
            }}
          />
          <ProjectGroup.Screen
            data-resource-id="T&MOrder"
            name="T&MOrder"
            component={TMOrderTabRM}
            options={{
              label: 'T&M',
            }}
            props={{user: user, ...props}}
          />
          <ProjectGroup.Screen
            data-resource-id="InternalOrder"
            name="InternalOrder"
            component={FixedOrderTabForRM}
            options={{
              label: 'Internal',
            }}
            props={{
              user: user,
              addOnFilter: {is_internal: true},
              ...props,
            }}
          />
        </ProjectGroup.Section>
      </ProjectGroup.Group>
      <ProjectGroup.Group>
        <ProjectGroup.Section options={{label: 'Report'}}>
          <ProjectGroup.Screen
            data-resource-id="ProjectsGrossMarginReport"
            name="Gross Margin Report"
            component={ProjectGrossMarginTab}
            options={{
              label: 'Gross Margin Report',
            }}
          />
          <ProjectGroup.Screen
            data-resource-id="ProjectsEfficiencyReport"
            name="Project Billing Efficiency"
            component={ProjectEfficiencyTab}
            options={{
              label: 'Project Billing Efficiency',
            }}
          />
          <ProjectGroup.Screen
            data-resource-id="CustomerFeedbackReport"
            name="Customer Feedback Report"
            component={ClientFeedbackReportTable}
            options={{
              label: 'Customer Feedback Report',
            }}
          />
        </ProjectGroup.Section>
      </ProjectGroup.Group>
      {/* <ProjectGroup.Screen
          data-resource-id="OrderAndDeliveriesOverDuePayment"
          name="Overdue Payment"
          component={OverduePaymentReportTable}
          options={{
            label: 'Overdue Payment',
          }}
        /> */}
    </ProjectGroup.Navigator>
  );
};
