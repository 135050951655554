import {
  View,
  Styled,
  Text,
  Image,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import MoreIcon from '../../images/ThreeDotVertical.svg';

export const ActionContainer = Styled(TouchableOpacity)`
  padding: 12px;
  ${props =>
    props.index > 0 ? {borderTopWidth: 1, borderColor: '#EEEEED'} : {}}
  ${props => props.styles?.actionContainer}
  ${props => `
  &:hover {
    background-color: ${props?.styles?.actionContainerHoverColor};
   `}
  }
`;

export const ActionText = Styled(Text).withConfig({
  shouldForwardProp: prop => !['type', 'variant'].includes(prop),
})`
  font-size: 14px;
  line-height: 20px;
  color: #19191A;
  ${props => props.styles?.actionText}
  ${({type, variant}) =>
    type === 'error' || variant === 'error' ? {color: '#FF3B30'} : {}}
  ${({type, variant, styles}) =>
    styles?.[variant]?.actionText || styles?.[type]?.actionText}
`;

export const SecondaryTitleText = Styled(Text)`
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #7D7D82;
  ${props => props.styles?.secondaryText}
`;

export const MoreActionImageContainer = Styled(View)`
${props => props.styles?.moreActionImageContainer}
`;

export const MoreActionImage = Styled(Image).attrs(props => ({
  source: props?.styles?.moreActionIcon || MoreIcon,
  resizeMode: 'contain',
}))`
  ${props => props.styles?.moreActionImage}
`;
