// This component displays an action summary with toggle functionality and personalized data rendering - Parveen 05-02-2024
import React, {useCallback, useEffect, useState} from 'react';
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {UnverifiedActionStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {RenderName} from './CustomRendersCollection';
import PreText from '../../../components/chat/Pretext';
import {RecursiveComponent} from './ViewAssistantResponse';

export const ActionSummaryFormTable = props => {
  let {data, campaignData} = props || {};

  const [activeIdx, setActiveIdx] = useState(-1);
  const [activeItem, setActiveItem] = useState({});

  let [showWebsiteSummary, setShowWebsiteSummary] = useState(false);

  let [personalizedData, setPersonalizedData] = useState({});

  useEffect(() => {
    setActiveIdx(data.findIndex(item => item._id == campaignData._id));
  }, [campaignData]);

  useEffect(() => {
    setActiveItem(data[activeIdx]);
  }, [activeIdx, data]);

  const handleTogglePrevious = useCallback(() => {
    let desiredIdx = activeIdx - 1;
    if (desiredIdx >= 0) setActiveIdx(desiredIdx);
    setPersonalizedData({});
  }, [activeIdx]);

  const handleToggleNext = useCallback(() => {
    let desiredIdx = activeIdx + 1;
    if (desiredIdx < data.length) setActiveIdx(desiredIdx);
    setPersonalizedData({});
  }, [activeIdx]);

  const styles = useStyles(UnverifiedActionStyles);

  const {
    containerStyle,
    bodyContainer,
    subjectContainer,
    subjectLabel,
    subjectText,
    messageText,
    headerStyle,
    nameHeaderStyle,
    contentStyle,
    lineStyle,
    dotStyle,
    subHeaderTextStyle,
    headerSubTextStyle,
    subContainerStyle,
    headerContainerStyle,
    secondHeaderStyle,
    summaryContainerStyle,
    caretLeft,
    caretRight,
    smallDotstyle,
    smallSubHeaderStyle,
    summaryTextStyle,
    downArrowIcons,
    bodyScrollViewStyle,
    toggleSubContainerStyle,
    websiteSummaryStyle,
    renderBodyContainerStyle,
    imageContainerStyle,
  } = styles || {};

  let {
    _id,
    name,
    description,
    potential_customer,
    contact_id,
    subject,
    personalize_subject,
    personalize_body: body,
  } = activeItem || {};
  if (description) {
    body = description;
  }
  subject = personalize_subject ? personalize_subject : subject;
  let {
    name: contactName,
    designation,
    first_name,
    last_name,
  } = contact_id || {};
  let {website_summary, industry} = potential_customer || {};
  let {name: industryName} = industry || {};

  contactName = contactName
    ? contactName
    : `${first_name || ''} ${last_name || ''}`;
  contactName = contactName.trim();

  const renderBody = ({subject, body}) => {
    return (
      <View style={bodyContainer}>
        <View style={{flexDirection: 'row'}}>
          <Text style={headerStyle}>Message</Text>
          <View style={dotStyle} />
          <Text style={nameHeaderStyle}>{name}</Text>
        </View>
        <ScrollView style={bodyScrollViewStyle}>
          {subject ? (
            <View style={subjectContainer}>
              <Text style={subjectLabel}>Subject</Text>
              <Text style={subjectText}>{subject}</Text>
            </View>
          ) : null}
          <Text style={contentStyle}>
            {PreText({text: body, isAnswer: true})}
          </Text>
        </ScrollView>
      </View>
    );
  };

  if (data && data.length === 0) {
    return (
      <View>
        <Text style={summaryTextStyle}>No Data Found</Text>
      </View>
    );
  }
  return (
    <>
      <View style={containerStyle}>
        <View style={subContainerStyle}>
          <View style={headerContainerStyle}>
            <Text style={subHeaderTextStyle}>{contactName}</Text>
            <View style={smallDotstyle} />
            {designation ? (
              <Text style={smallSubHeaderStyle}>{designation}</Text>
            ) : null}
          </View>
          <View style={dotStyle} />
          <View style={secondHeaderStyle}>
            <Text style={subHeaderTextStyle}>{industryName}</Text>
          </View>
        </View>
        <View style={subContainerStyle}>
          <TouchableOpacity
            style={{...imageContainerStyle, marginRight: 8}}
            onPress={handleTogglePrevious}>
            <Image source={caretLeft} style={{height: 16, width: 16}} />
          </TouchableOpacity>
          <TouchableOpacity
            style={imageContainerStyle}
            onPress={handleToggleNext}>
            <Image source={caretRight} style={{height: 16, width: 16}} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={lineStyle} />

      <View style={{flex: 1, overflow: 'hidden'}}>
        <View
          style={{
            ...toggleSubContainerStyle,
            borderBottomLeftRadius: !showWebsiteSummary ? 8 : 0,
            borderBottomRightRadius: !showWebsiteSummary ? 8 : 0,
            borderWidth: !showWebsiteSummary ? 1 : null,
          }}>
          <View style={summaryContainerStyle}>
            <View style={{flexDirection: 'row'}}>
              <Text style={headerStyle}>Summary</Text>
              <View style={{...dotStyle, marginBottom: 4}} />
              <Text style={headerSubTextStyle}>
                <RenderName row={activeItem} />
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => setShowWebsiteSummary(!showWebsiteSummary)}>
              <Image source={downArrowIcons} style={{height: 16, width: 16}} />
            </TouchableOpacity>
          </View>
        </View>
        {showWebsiteSummary ? (
          <View style={websiteSummaryStyle}>
            <ScrollView>
              <Text style={contentStyle}>
                <RecursiveComponent data={website_summary} />
              </Text>
            </ScrollView>
          </View>
        ) : null}

        <View style={renderBodyContainerStyle}>
          {renderBody({subject, body})}
        </View>
      </View>
    </>
  );
};

export const ActionSummaryForm = props => {
  let {navigation, route: {params} = {}} = props || {};
  let {data: paramsData = []} = params || {};

  const [data, setData] = useState(paramsData);
  return (
    <ActionSummaryFormTable
      data={data}
      campaignData={params}
      navigation={navigation}
      onDataChange={data => setData(data)}
    />
  );
};
