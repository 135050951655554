import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ApiForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/apis',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Api'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          placeholder: 'API',
          field: 'api',
          type: 'text',
        },
      ]}
    />
  );
};
