//Parveen 04/04/24
import {TabView} from '../../../components/tab/TabView';
import React from 'react';
import {OutreachCampaignTableGrouped} from './OutreachCampaignTableGrouped';

export const OutreachCampaignVerifiedTab = props => {
  let {
    route: {params},
  } = props;
  let {_id, verified, sent_action, status} = params;
  let campaignContactsfilter = {
    api: `/campaignactions/OutreachCampaignActionsGrouppedPending`,
    eventSourceId: [
      'outreachAdded',
      'indexUpdated',
      'actionVerified',
      'regenrateMessage',
    ],
  };

  let addOnFilter = {
    verified,
    campaign_id: _id,
    sent_action,
    $or: [
      {
        personalize_with_ai: true,
        personalize_body: {$exists: true},
      },
      {
        personalize_with_ai: {$in: [false, null]},
      },
    ],
  };

  const tabs = {
    all: {
      label: `All`,
      view: <OutreachCampaignTableGrouped />,
      api: '/campaignactions',
      filter: {
        status,
        ...addOnFilter,
      },
    },
    verified: {
      label: 'Auto-Verified',
      view: <OutreachCampaignTableGrouped auto_verified={true} />,
      api: '/campaignactions',
      filter: {
        auto_verified: true,
        ...addOnFilter,
      },
    },
    rejected: {
      label: 'Auto-Rejected',
      view: (
        <OutreachCampaignTableGrouped
          auto_verified={false}
          title={'rejected'}
        />
      ),
      api: '/campaignactions',
      filter: {
        auto_verified: false,
        ...addOnFilter,
      },
    },
    error: {
      label: 'Error',
      view: (
        <OutreachCampaignTableGrouped
          auto_verified_error={{$exists: true}}
          title={'error'}
        />
      ),
      api: '/campaignactions',
      filter: {
        auto_verified_error: {$exists: true},
        ...addOnFilter,
      },
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
