import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {MenuBar} from '@unthinkable/react-menus';
import {useNavigation} from '@react-navigation/native';
import {View} from '@unthinkable/react-core-components';
import {isMobile} from '@unthinkable/react-utils';
import {useAppStateContext} from '../../providers/AppState';
import {Tooltip} from '../modal/Tooltip';
import {
  CollapseView,
  MenuBarContainer,
  ToggleButton,
  ToggleButtonIcon,
} from './Styles';
import {getScreenId, updateEntities} from './Utility';
import {renderMenuItem} from './MenuItemWithCount';
import {renderSectionItem} from './SectionItemWithCount';

export const MenuItems = props => {
  return (
    <MenuBar
      {...props}
      skipRightArrow
      renderSectionItem={renderSectionItem}
      renderMenuItem={renderMenuItem}
    />
  );
};

export const ScreenMenus = props => {
  return (
    <MenuBar
      {...props}
      skipRightArrow
      renderSectionItem={renderSectionItem}
      renderMenuItem={renderMenuItem}
    />
  );
};

export const Menus = ({
  selectedItem,
  selectedView,
  styles,
  entities,
  itemScreens,
  screens,
  selectedScreenSection,
  setSelectedScreenSection,
  selectedMenuSection,
  setSelectedMenuSection,
  isCollapsable = true,
  isOpen,
  setIsOpen,
  isMenuVisible,
  setMenuVisible,
  toggleMenu,
  ...props
}) => {
  const {params} = props;
  const {shadows} = useTheme();
  const navigation = useNavigation();
  const {user} = useAppStateContext();
  let filteredEntities = [];

  if (Array.isArray(entities)) {
    filteredEntities = entities.filter(entity => !entity.hidden);
  }

  const handleItemPress = item => {
    let screenId = getScreenId({screens, itemId: item?.id});

    const newParams = {
      itemId: item?.id,
      entityKey: item?.entityKey,
      screenId,
      tabId: '',
    };

    navigation.setParams(newParams);
  };

  const handleViewPress = view => {
    if (view.onNavigate) {
      view.onNavigate({view, navigation, params});
    } else {
      const newParams = {
        screenId: view.id,
        tabId: '',
      };

      navigation.setParams(newParams);
    }
  };

  updateEntities(entities, screens);

  const renderTooltip = ({tooltip, ...props}) => {
    if (typeof tooltip === 'string') {
      tooltip = {content: tooltip};
    } else if (typeof tooltip === 'function') {
      tooltip = tooltip({params});
      tooltip = tooltip ? {content: tooltip} : void 0;
    }
    if (!tooltip?.content) {
      return props.children;
    }
    return <Tooltip position="right" {...props} {...tooltip} />;
  };

  let {view} = selectedView || {};

  let showScreenMenus =
    selectedItem?.showSingleView ||
    itemScreens?.length > 1 ||
    Array.isArray(itemScreens?.[0]) ||
    itemScreens?.[0]?.section;

  return (
    <MenuBarContainer styles={styles} isOpen={isOpen} hidden={isMobile && view}>
      <ToggleButton
        styles={styles}
        hidden={isMobile || !isCollapsable}
        onPress={toggleMenu}>
        <ToggleButtonIcon styles={styles} isOpen={isOpen} />
      </ToggleButton>
      <View
        style={
          showScreenMenus
            ? {
                flex: 1,
                overflow: 'hidden',
                paddingTop: 6,
                paddingBottom: 6,
                borderRadius: 4,
                backgroundColor: 'transparent',
                ...shadows.M,
              }
            : {flex: 1, overflow: 'hidden'}
        }>
        <MenuItems
          onPress={handleItemPress}
          selectedMenu={selectedItem}
          menus={filteredEntities}
          styles={styles}
          renderTooltip={renderTooltip}
          selectedSection={selectedMenuSection}
          onSelectSection={parentSection => {
            setSelectedMenuSection(parentSection);
          }}
          extraParams={{user}}
          {...props}
        />
      </View>
      {showScreenMenus ? (
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 4,
            backgroundColor: 'transparent',
            ...shadows.M,
          }}>
          <ScreenMenus
            onPress={handleViewPress}
            selectedMenu={selectedView}
            selectedSection={selectedScreenSection}
            onSelectSection={parentSection => {
              setSelectedScreenSection(parentSection);
            }}
            menus={itemScreens}
            styles={styles}
            renderTooltip={renderTooltip}
            extraParams={{user}}
            {...props}
          />
        </View>
      ) : (
        void 0
      )}
      <CollapseView styles={styles} isCollapsed={!isOpen || !isMenuVisible} />
    </MenuBarContainer>
  );
};
