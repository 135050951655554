import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

const DesignationTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId="designation"
      api={`/designations`}
      fields={{
        name: 1,
        type: 1,
        status: 1,
        from: 1,
        to: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`designation-detail`, row);
      }}
      renderHeader={() => (
        <TableHeader
          title="Designation"
          actions={[
            <AddButton
              title="Add Designation"
              view="add-designation"
              // params={params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Type',
          field: 'type',
          type: 'text',
          width: 200,
        },
        {
          header: 'Status',
          field: 'status',
          type: 'text',
          width: 200,
        },
        {
          header: 'From',
          field: 'from',
          type: 'date',
          width: 200,
        },
        {
          header: 'To',
          field: 'to',
          type: 'date',
          width: 200,
        },
      ]}
    />
  );
};

export default DesignationTable;
