import React from 'react';
import {
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/table/Headers';
import {ExpandRowIconRender} from '../../../components/table/Renders';
import {GroupTable, Table} from '../../../components/table/Table';
import {useTheme} from '@react-navigation/native';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import { PrimaryColumnCard } from '../../../components/card/PrimaryColumnCard';
import { TabView } from '../../../components/tab';
import { useInvoke } from '../../../controllers/useInvoke';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const {colors} = useTheme('colors');
  const color = row?.url ? {color: colors['primary']} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.url &&
          Linking.openURL(
            row?.url.startsWith('https') ? row?.url : `https://${row?.url}`,
            '_blank',
          );
      }}>
      <Text
        title={row?.name}
        style={{
          ...rowText,
          ...color,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};

export const MyStudyMaterials = props => {
  const {filterValues, applyFilter} = useFilter();
  const invoke = useInvoke({
    eventSourceId: ['studyMaterial'],
    method: 'put',
  });
  return (
    <GroupTable
      api="/studyMaterialsData"
      filter={{...filterValues.filter , 'status' : "Active"}}
      groupRow={{
        data: 'materials',
        defaultExpanded: false,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.tool?.name} />
              <GroupContentItem value={(row?.materials?.length || 0) +" Materials"} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          field: 'name',
          header: 'Material',
          render: RenderName,
        },
        {
          field: 'tool.name',
          colorField: 'tool.color',
          header: 'Skill',
          type: 'colorChip',
        },
        {
          field: 'createdon',
          header: 'Published On',
          type: 'date',
        },
      ]}
      moreActions={[
        {
          title: 'Archived',
          confirm: {
            title: 'Archived',
            message: 'Do you really want to archive this material?',
            confirmText: 'Archived',
          },
          onPress: ({row}) => {
            invoke({
              uri: '/trainingmaterials/' + row?._id,
              props: {
                status: 'Inactive',
                inactive_on : getZeroTimeDate(new Date())
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const MyArchivedStudyMaterials = props => {
  const {filterValues, applyFilter} = useFilter();
  return (
    <GroupTable
    api="/studyMaterialsData"
    filter={{...filterValues.filter , 'status' : "Inactive"}}
      groupRow={{
        data: 'materials',
        defaultExpanded: false,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.tool?.name} />
              <GroupContentItem value={(row?.materials?.length || 0) +" Materials"} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          field: 'name',
          header: 'Material',
          render: RenderName,
        },
        {
          field: 'tool.name',
          header: 'Skill',
          type: 'colorChip',
          colorField: 'tool.color',
        },
        {
          field: 'createdon',
          header: 'Published On',
          type: 'date',
        },
        {
          field: 'inactive_on',
          header: 'Created On',
          type: 'date',
        },
      ]}
      {...props}
    />
  );
};



export const AllStudyMaterailTabs = props => {
  const filterProps = useFilter();
  const {
    filterValues: {filter},
  } = filterProps;

  const tabs = {
    active: {
      label: 'Active',
      view: <MyStudyMaterials />,
      api: '/studyMaterialsData',
      filter: {
        ...filter,
        status : "Active"
      },
    },
    dropped: {
      label: 'Archived',
      view: <MyArchivedStudyMaterials />,
      api: '/studyMaterialsData',
      filter: {
        ...filter,
        status : "Inactive"
      },
    },
  };
  return (
    <TabView
      headerComponent={
        <TableHeader
          title="Study Materials"
          actions={[
            <GroupFilter
            {...filterProps}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Skill',
                  api: '/skillMetrics',
                  placeholder: 'Select Skill',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: '_id',
                },
              ]}
            />,
          ]}
        />
      }
      tabs={tabs}
      extraFilter={filter}
      {...props}
    />
  );
};