import React from 'react';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {IconButton} from '../../../components/button/IconButton';
import {Tag} from '../../../components/chip/Chip';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';

export const BenchComparisionReport = props => {
  let {navigation} = props;
  const {route: {params} = {}} = props;
  const {department} = params;
  const {filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {
      "department": department,
    },
  });
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <Table
      {...props}
      filter={filter}
      api={'/employees/bench-comparision'}
      params={filterParams}
      variant={'bordered'}
      limit={2000}
      renderHeader={() => (
        <TableHeader
          title="Bench Comparison"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <IconButton
              icon={icons.Download}
              onPress={() => {
                download({
                  uri: '/exportBenchComparisionReport',
                  props: {
                    uri: '/employees/bench-comparision',
                    parameters: {
                      params: {...filterParams},
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Skill',
          minWidth: 150,
          render: ({row}) => {
            return <Tag value={row.skill} color={row.skill_color} />;
          },
        },
        {
          render: CountCellRender,
          aggregate: true,
          count_field: 'openings',
          header: 'Openings',
          align: 'right',
          field: 'openings',
          minWidth: 150,
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              employeeFilter: {
                _id: {$in: row?.openings_employeeId},
                employee_status: {$in: ['Active', 'Inactive']},
              },
              period,
            });
          },
        },
        {
          header: 'Organization',
          children: [
            {
              render: CountCellRender,
              aggregate: true,
              field: 'newJoinee',
              count_field: 'newJoinee',
              align: 'right',
              header: 'New Joinees',
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('employee-workload', {
                  employeeFilter: {
                    _id: {$in: row?.new_joinee_employeeId},
                    employee_status: {$in: ['Active', 'Inactive']},
                  },
                  period,
                });
              },
            },
            {
              render: CountCellRender,
              aggregate: true,
              field: 'exit',
              count_field: 'exit',
              align: 'right',
              header: 'Exits',
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('employee-workload', {
                  employeeFilter: {
                    _id: {$in: row?.exit_employeeId},
                    employee_status: {$in: ['Active', 'Inactive']},
                  },
                  period,
                });
              },
            },
          ],
        },
        {
          header: 'Projects',
          children: [
            {
              render: CountCellRender,
              aggregate: true,
              field: 'initiation',
              header: 'Initiations',
              width: 150,
              count_field: 'initiation',
              align: 'right',
              onPress: ({row}) => {
                const employeeId = row?.initiation_employee?.map(
                  item => item.assigned_to._id,
                );
                navigation.navigate('employee-workload', {
                  employeeFilter: {
                    _id: {$in: employeeId},
                    employee_status: {$in: ['Active', 'Inactive']},
                  },
                  period,
                  benchComparision: true,
                });
              },
            },
            {
              render: CountCellRender,
              aggregate: true,
              field: 'release',
              count_field: 'release',
              align: 'right',
              header: 'Release',
              width: 150,
              onPress: ({row}) => {
                const employeeId = row?.release_employee?.map(
                  item => item.assigned_to._id,
                ) || ['xxxx'];
                navigation.navigate('employee-workload', {
                  employeeFilter: {
                    _id: {$in: employeeId},
                    employee_status: {$in: ['Active', 'Inactive']},
                  },
                  period,
                  benchComparision: true,
                });
              },
            },
          ],
        },
        {
          render: CountCellRender,
          aggregate: true,
          field: 'closing',
          count_field: 'closing',
          header: 'Closing',
          align: 'right',
          width: 150,
        },
        // {
        //   render: CountCellRender,
        //   aggregate: true,
        //   field: 'previous_month_opening',
        //   count_field: 'previous_month_opening',
        //   header: 'Closing',
        //   align: 'right',
        //   width: 150,
        // },
      ]}
    />
  );
};
