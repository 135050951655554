import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AddMarketingCreativeForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/MarketingCreatives',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Marketing Creative'}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Creative Title',
          field: 'creative_title',
          type: 'text',
        },
      ]}
    />
  );
};
