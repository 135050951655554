import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const BankForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/Banks',
    eventSourceId: 'Banks',
  });
  return (
    <Form
      api={`/bank/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          size: 6,
        },
        {
          label: 'Account Name',
          field: 'account_id',
          type: 'autoComplete',
          api: '/accounts',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Account Number',
          field: 'account_number',
          type: 'text',
          size: 6,
        },
        {
          label: 'IFSC Code',
          field: 'ifsc_code',
          type: 'text',
          size: 6,
        },
        {
          label: 'Corp ID',
          field: 'corp_id',
          type: 'text',
          size: 6,
        },
        {
          label: 'User ID',
          field: 'user_id',
          type: 'text',
          size: 6,
        },
        {
          label: 'aggr Name',
          field: 'aggr_name',
          type: 'text',
          size: 6,
        },
        {
          label: 'Aggr ID',
          field: 'aggr_id',
          type: 'text',
          size: 6,
        },
        {
          label: 'URN',
          field: 'urn',
          type: 'text',
          size: 6,
        },
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          size: 6,
        },
        {
          label: 'Currency',
          field: 'currency_',
          type: 'autoComplete',
          api: '/currencies',
          suggestionField: 'currency',
          valueField: 'currency',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddBankForm = props => {
  return <BankForm header="Add Bank" {...props} />;
};

export const EditBankForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <BankForm
      mode="edit"
      readOnly={params.readOnly || false}
      header={'Update Bank'}
      {...props}
    />
  );
};
