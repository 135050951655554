import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {CountButtonStyles} from './theme';

export const CountButton = ({onPress, icon, label, count = 0}) => {
  const styles = useStyles(CountButtonStyles);

  let value = <Text style={styles.count}>{count}</Text>;
  if (label) {
    value = (
      <>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.count}>{`(${count})`}</Text>
      </>
    );
  }
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Row gap={8} style={{alignItems: 'center'}}>
        <Image source={icon} />
        {value}
      </Row>
    </TouchableOpacity>
  );
};
