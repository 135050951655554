import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Durations} from '../../order/views/components/Durations';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

const RenderOrder = ({row}) => {
  return (
    <PrimaryColumnCard
      primaryTitle={row?.order_number}
      items={[
        {
          value: row?.customer?.name,
        },
      ]}
    />
  );
};

export const EmployeeSkillRequest = props => {
  const {
    route: {params},
    navigation,
    filterValues = {},
  } = props;
  const {filter = {}} = filterValues;
  return (
    <Table
      addOnFilter={{...filter}}
      {...props}
      params={params}
      eventSourceId={[
        'EmployeeAssignment',
        'skillMetricsAdded',
        't&m',
        'employeeAssigned',
      ]}
      api={'/orderRequest/pending/r'}
      columns={[
        {
          field: 'tool.name',
          header: 'Tool',
          type: 'colorTag',
          colorField: 'tool.color',
        },
        {
          render: CountCellRender,
          count_field: 'count',
          header: 'Due Allocations',
          styleForCountField: true,
          aggregate: true,
          field: 'count',
          align: 'right',
          onPress: ({row = {}}) => {
            if (row?.count) {
              navigation.navigate('order-resource-request-table', {
                data: row,
              });
            }
          },
        },
      ]}
    />
  );
};
