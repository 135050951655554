import React from 'react';
import {HelpTemplateStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {
  Image,
  Row,
  ScrollView,
  Text,
  View,
} from '@unthinkable/react-core-components';
import {VideoPlayer} from './VideoPlayer';
import {useAppStateContext} from '../../providers/AppState';

export const HelpTemplateVideoPlayer = props => {
  const {
    containerStyle,
    subContainerStyle,
    titleStyle,
    pointerContainerStyle,
    pointerStyle,
    videoContainerStyle,
    titleContainerStyle,
    pointersubContainerStyle,
  } = useStyles(HelpTemplateStyles);

  const {
    navigation,
    route: {params},
  } = props;

  const {resourceUrl} = useAppStateContext();

  const {videoContent} = params;

  let {why_title, how_title, why_pointers, how_pointers, videoUrl, image} =
    videoContent || {};

  let imageUrl = image && resourceUrl && resourceUrl(image);

  const renderWhyHowComponent = ({title, pointers}) => {
    return title && pointers && pointers.length ? (
      <View>
        <Text style={titleStyle}>{title}</Text>
        <View style={pointersubContainerStyle} as={'ul'}>
          {pointers &&
            pointers.map(item => (
              <Text style={pointerStyle} as={'li'}>
                {item}
              </Text>
            ))}
        </View>
      </View>
    ) : null;
  };

  let extraStyle = {flex: 1, maxWidth: 400};

  let extraVideoStyle = {maxWidth: '100%'};

  if (videoUrl || imageUrl) {
    extraStyle = {maxWidth: '25%'};
  }

  let rowStyle = {flex: 1, justifyContent: 'center'};

  if (videoUrl && videoUrl.includes('locomo')) {
    rowStyle = {...rowStyle, alignItems: 'center'};
  }

  if ((how_title || why_title) && (videoUrl || imageUrl)) {
    rowStyle = {flex: 1};
    extraVideoStyle = {maxWidth: '75%'};
  }

  return (
    <View style={{...containerStyle, minHeight: 200}}>
      <Row gap={12} style={{...rowStyle, minHeight: 200}}>
        {why_title || how_title ? (
          <View
            style={{
              ...subContainerStyle,
              ...extraStyle,
              minHeight: 200,
            }}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{
                flex: 1,
                paddingTop: 24,
                minHeight: 200,
                gap: 12,
                boxShadow: '6px 0 4px -4px rgba(0, 0, 0, 0.1)',
              }}>
              {renderWhyHowComponent({
                title: why_title,
                pointers: why_pointers,
              })}
              {renderWhyHowComponent({
                title: how_title,
                pointers: how_pointers,
              })}
            </ScrollView>
          </View>
        ) : null}
        {videoUrl || imageUrl ? (
          <View
            style={{
              ...videoContainerStyle,
              ...extraVideoStyle,
              overflow: 'hidden',
            }}>
            {videoUrl ? (
              <VideoPlayer videoUrl={videoUrl} />
            ) : (
              <Image source={imageUrl} />
            )}
          </View>
        ) : null}
      </Row>
    </View>
  );
};
