var Crypt = require('hybrid-crypto-js').Crypt;
import {v4 as uuid} from 'uuid';
var {decode} = require('js-base64');
import Config from './Config';
const {en_k1, en_k2} = Config;
const ENC_V = 'hybrid-crypto-js_0.2.4'; // enc_v = encrypted version

export const getEncryptedProps = props => {
  try {
    let entropy = 10;
    let crypt = new Crypt({entropy: entropy});

    let message = JSON.stringify(props);
    // decode key and merge
    let k1 = decode(en_k1);
    let k2 = decode(en_k2);
    let kToEnc = `-----BEGIN PUBLIC KEY-----\r\n${k1}${k2}\r\n-----END PUBLIC KEY-----\r\n`;

    if (kToEnc) {
      let encrypted = crypt.encrypt(kToEnc, message);
      encrypted = JSON.parse(encrypted);
      if (encrypted && encrypted.v == ENC_V) {
        delete encrypted.v;
        encrypted['v_rem'] = uuid();
      }
      encrypted = JSON.stringify(encrypted);
      return {fep: encrypted};
    } else {
      return props;
    }
  } catch (e) {
    return props;
  }
};

export const key = '--navigation-state-key--';

// Function to convert a Uint8Array to string
function uint8ArrayToString(uint8Array) {
  let result = '';
  for (let i = 0; i < uint8Array.length; i++) {
    result += String.fromCharCode(uint8Array[i]);
  }
  return result;
}

// Function to convert a binary string to a Uint8Array
function stringToUint8Array(str) {
  const result = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    result[i] = str.charCodeAt(i);
  }
  return result;
}

// Function to XOR a Uint8Array with a key
function xorUint8Array(uint8Array, key) {
  const keyUint8Array = new TextEncoder().encode(key);
  const result = new Uint8Array(uint8Array.length);
  for (let i = 0; i < uint8Array.length; i++) {
    result[i] = uint8Array[i] ^ keyUint8Array[i % keyUint8Array.length];
  }
  return result;
}

// Function to encode data
export const encodeData = (data, key) => {
  const jsonUint8Array = new TextEncoder().encode(data);

  // XOR the Uint8Array with the key
  const obfuscatedUint8Array = xorUint8Array(jsonUint8Array, key);

  // Convert the Uint8Array to a string (not base64) for transmission/storage
  return uint8ArrayToString(obfuscatedUint8Array);
};

// Function to decode JSON data
export const decodeData = (encoded, key) => {
  // Convert the string back to a Uint8Array
  const encodedUint8Array = stringToUint8Array(encoded);

  // XOR again with the key to deobfuscate
  const deobfuscatedUint8Array = xorUint8Array(encodedUint8Array, key);

  // Decode the deobfuscated Uint8Array back to a string
  return new TextDecoder().decode(deobfuscatedUint8Array);
};
