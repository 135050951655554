import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const PotentialCustomerFormUpdateBusinessSummary = props => {
  const {
    route: {
      params: {potential_customer},
    },
  } = props;
  const mode = 'edit';
  const api = `/potentialcustomers/${potential_customer?._id}`;
  const header = 'Update business summary';

  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: ['potentialcustomerupdated'],
  });

  return (
    <Form
      mode={mode}
      api={api}
      header={header}
      fields={{
        first_name: 1,
        last_name: 1,
        designation: 1,
        email: 1,
        linkedin_url: 1,
        duplicate_linkedin_url: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'website_summary',
          type: 'textArea',
          label: 'Business Summary',
          placeholder: 'Business Summary',
        },
      ]}
      {...props}
    />
  );
};
