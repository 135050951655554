import React from 'react';
import {Table} from '../../../components/table/Table';
import {activityLogVoucher} from '../controller/VoucherActivityLogsController';

export const VoucherTable = props => {
  const {navigation, route: {params} = {}} = props;
  const {voucherfilter = {}} = params;
  return (
    <Table
      {...props}
      limit={50}
      addOnFilter={{...voucherfilter}}
      api={`/voucher/voucher-details`}
      onRowPress={({row}) => {
        navigation.navigate(`edit-voucher`, {
          row,
          readOnly: true,
        });
      }}
      searchFields={['voucher_no']}
      eventSourceId="voucher"
      columns={[
        {
          field: 'voucher_no',
          header: 'Number',
        },
        {
          type: 'date',
          field: 'voucher_date',
          header: 'Date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 200,
        },

        {
          field: 'narration',
          header: 'Narration',
          width: 250,
          // width: 200,
        },
        {
          field: 'cr_amount',
          header: 'Cr',
          // width: 120,
          type: 'currency',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          field: 'dr_amount',
          header: 'Dr',
          type: 'currency',
          // width: 120,
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`edit-voucher`, {
              row,
              readOnly: false,
            });
          },
        },
        activityLogVoucher({navigation, params}),
      ]}
    />
  );
};
