import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {Durations} from './components/Durations';
import {useInvoke} from '../../../controllers/useInvoke';
import {Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useProjectResourceWorkingDataActions} from '../actions/ProjectResourceWorkingDataActions';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useAppStateContext} from '../../../providers/AppState';
import {useFilter} from '@unthinkable/react-filters';
import {SearchFilter} from '../../../components/filter/Filters';
import {randomColor} from '../../../utils/util';
import {Chip} from '../../../components/chip/Chip';
import {
  UserAvatarChip,
  UserAvatarGroupChip,
} from '../../../components/avatar/UserAvatar';

const calculateBilledHours = ({invoke, period = {}, formFixed = false}) => ({
  title: 'Recalculate billings hours',
  confirm: {
    title: 'Calculate Billed Hours',
    message: 'Do you want calculate billed hours for this data?',
  },
  onPress: props => {
    const {row = {}} = props;
    invoke({
      uri: `/markDeliveredForTandMDelivery/${row?._id}`,
      props: {
        period,
      },
    });
  },
  visible: !formFixed,
});

const unmarkTandMDelivered = ({invoke, user}) => ({
  title: 'Unmark Delivered',
  confirm: {
    title: 'Unmark Delivered',
    message: 'Do you want unmark delivered for this data?',
  },
  visible: () => {
    if (
      user.email === 'sushil.nagvan@daffodilsw.com' ||
      user.email === 'amit.singh@daffodildb.com' ||
      user.email === 'vanshika.dixit@unthinkable.co' ||
      user.email === 'aswathy.krishnankutty@unthinkable.co' ||
      user.email === 'manoj.kaushik@daffodilsw.com' ||
      user.email === 'gaurav.sharma@daffodilsw.com' ||
      user.email === 'amitkr.singh@daffodilsw.com'
    ) {
      return true;
    }
  },
  onPress: props => {
    const {row = {}} = props;
    invoke({
      uri: `/unmarkTandMdelivered/${row?._id}`,
      props: {
        deliveryData: row,
      },
    });
  },
});

export const ActiveOrderOfOrdersAndDeliveries = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {
    addOnFilter = {},
    period = {},
    formFixed = false,
    project_type,
    row = {},
  } = params || {};
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({
      navigation,
      params,
    });
  const color = useTheme('colors');
  const {filterValues, applyFilter, searchValue, onChangeFilter} = useFilter();
  return (
    <Table
      variant={'bordered'}
      search={searchValue}
      searchFields={'delivery_number'}
      renderHeader={() => (
        <TableHeader
          title={`Add WIP ${row?.project?.project}`}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
          ]}
        />
      )}
      addOnFilter={{
        approx_billed_hours: {$exists: false},
        approval_status: {$in: [null, 'Pending', 'Partially Delivered']},
        ...filterValues.filter,
        ...addOnFilter,
      }}
      params={params}
      eventSourceId={['ActiveOrderOfOrdersAndDeliveries', 'allocatedResource']}
      api={`/projectResourceWorking/activeOrders`}
      columns={[
        {
          header: 'Engineer',
          minWidth: 200,
          render: ({row}) => {
            return (
              <UserAvatarGroupChip
                maxWidth={200}
                value={row?.project_resource_employee}
              />
            );
          },
        },
        {
          header: 'Delivery No.',
          field: 'delivery_number',
          type: 'text',
          width: 200,
        },
        {
          header: 'L1',
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={row?.employee_assignment_id?.[0]?.level_one_owner}
              />
            );
          },
          width: 160,
        },
        {
          header: 'L2',
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={row?.employee_assignment_id?.[0]?.level_two_owner}
              />
            );
          },
          width: 160,
        },
        {
          field: 'variable_potential',
          header: 'Working Pot.',
          type: 'number',
          width: 120,
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.variable_potential && `${row?.variable_potential}`}
              </Text>
            );
          },
        },
        {
          header: 'Approved',
          children: [
            {
              header: 'EWD',
              align: 'right',
              width: 100,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${(row?.approve_ewd_days || 0).toFixed(1)} d`}
                  </Text>
                );
              },
              onPress: ({row}) => {
                navigation.navigate('attendance-verified-order', {
                  row,
                  period,
                  attendanceFilter: {
                    first_attendance_type_id: '5382ea3470f5d40200321611',
                    second_attendance_type_id: '5382ea3470f5d40200321611',
                  },
                });
              },
            },
            {
              header: 'Leaves',
              align: 'right',
              width: 100,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${Number(row?.approve_leave_days).toFixed(1) || 0} d`}
                  </Text>
                );
              },
              onPress: ({row}) => {
                navigation.navigate('attendance-verified-order', {
                  row,
                  period,
                  attendanceFilter: {
                    $or: [
                      {
                        first_attendance_type_id: '53744b5b3eabca020078060b',
                      },
                      {
                        second_attendance_type_id: '53744b5b3eabca020078060b',
                      },
                    ],
                  },
                });
              },
            },
          ],
        },
        {
          header: 'Unapproved',
          children: [
            {
              header: 'EWD',
              width: 100,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${(row?.unapprove_ewd_days || 0).toFixed(1)} d`}
                  </Text>
                );
              },
            },
            {
              header: 'Absence',
              width: 100,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${(row?.absence || 0).toFixed(1)} d`}
                  </Text>
                );
              },
            },
          ],
        },
        {
          header: 'Efforts',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.billing_hrs && `${(row?.billing_hrs || 0).toFixed(1)} h`}
              </Text>
            );
          },
        },
        {
          header: 'Output',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.billing_hrs &&
                  `${(row?.approx_billed_hours || 0).toFixed(1)} h`}
              </Text>
            );
          },
        },
        {
          header: 'Action',
          align: 'right',
          width: 150,
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('active-order-mark-delivered', {
                    row,
                    period,
                  });
                }}
                style={{...rowText, color: color?.INFORMATION_HIGH}}>
                Mark delivered
              </Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity history',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        calculateBilledHours({invoke, period, formFixed}),
        {
          title: 'View allocations',
          onPress: ({row}) => {
            navigation.navigate('active-order-allocations', {
              addOnFilter: {
                skill_requirement: row?.recur_id ? row?.recur_id : void 0,
                milestone_id: row?.milestone?._id
                  ? row?.milestone?._id
                  : void 0,
                project_type,
                employee: {
                  $in: row?.project_resource_employee.map(({_id}) => _id),
                },
              },
              period,
            });
          },
        },
        {
          title: 'Progress',
          onPress: ({row}) => {
            navigation.navigate('timesheet-progress-tandm', {
              row,
              period: params.period,
            });
          },
        },
      ]}
    />
  );
};

export const TandMOrdersAndDelivered = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {addOnFilter = {}, period = {}, row = {}} = params || {};
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({
      navigation,
      params,
    });
  const color = useTheme('colors');
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ActiveOrderOfOrdersAndDeliveries',
      'OrdersAndDeliveriesT&M',
    ],
    throwError: true,
  });
  const {user} = useAppStateContext();
  return (
    <Table
      variant={'bordered'}
      addOnFilter={{
        approval_status: {$in: ['Delivered', 'Partially Delivered']},
        ...addOnFilter,
      }}
      renderHeader={() => (
        <TableHeader title={`View WIP ${row?.project?.project}`} />
      )}
      params={params}
      eventSourceId={'ActiveOrderOfOrdersAndDeliveries'}
      api={`/projectResourceWorking/activeOrders`}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarGroupChip
                maxWidth={200}
                value={row?.project_resource_employee}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Delivery No.',
          field: 'delivery_number',
          type: 'text',
          width: 200,
        },
        {
          field: 'variable_potential',
          header: 'Working Pot.',
          type: 'number',
          width: 120,
        },
        {
          header: 'Approved',
          children: [
            {
              header: 'EWD',
              align: 'right',
              width: 100,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.approve_ewd_days || 0} d`}
                  </Text>
                );
              },
              onPress: ({row}) => {
                navigation.navigate('attendance-verified-order', {
                  row,
                  period,
                  attendanceFilter: {
                    first_attendance_type_id: '5382ea3470f5d40200321611',
                    second_attendance_type_id: '5382ea3470f5d40200321611',
                  },
                });
              },
            },
            {
              header: 'Leaves',
              align: 'right',
              width: 100,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.approve_leave_days || 0} d`}
                  </Text>
                );
              },
              onPress: ({row}) => {
                navigation.navigate('attendance-verified-order', {
                  row,
                  period,
                  attendanceFilter: {
                    first_attendance_type_id: '53744b5b3eabca020078060b',
                    second_attendance_type_id: '53744b5b3eabca020078060b',
                  },
                });
              },
            },
          ],
        },
        {
          header: 'Unapproved',
          children: [
            {
              header: 'EWD',
              width: 100,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.unapprove_ewd_days || 0} d`}
                  </Text>
                );
              },
            },
            {
              header: 'Absence',
              width: 100,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.absence || 0} d`}
                  </Text>
                );
              },
            },
          ],
        },
        {
          header: 'Efforts',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.actual_hours && `${row?.actual_hours} h`}
              </Text>
            );
          },
        },
        {
          header: 'Output',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.billed_hours && `${row?.billed_hours} h`}
              </Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity history',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        {
          title: 'View allocations',
          onPress: ({row}) => {
            navigation.navigate('active-order-allocations', {
              addOnFilter: {
                skill_requirement: row?.recur_id,
                project_type: 'tm',
              },
              period,
            });
          },
        },
        unmarkTandMDelivered({invoke, user}),
      ]}
    />
  );
};

export const ActiveOrderOfOrdersAndDeliveriesForFixed = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {
    addOnFilter = {},
    period = {},
    formFixed = false,
    row = {},
  } = params || {};
  const {project = {}} = row;
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({
      navigation,
      params,
    });
  const color = useTheme('colors');
  return (
    <Table
      addOnFilter={{
        ...addOnFilter,
        // approx_billed_hours: {$exists: false},
      }}
      renderHeader={() => <TableHeader title={`Verify ${project?.project}`} />}
      params={params}
      eventSourceId={['ActiveOrderOfOrdersAndDeliveries', 'allocatedResource']}
      api={`/projectResourceWorkingActiveOrdersForFixed`}
      columns={[
        {
          header: 'Milestone',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.milestone?.order_milestone_no}
                items={[
                  {
                    value: row?.delivery_number,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return Durations({
              toDate: row?.milestone?.end_date,
              fromDate: row?.milestone?.start_date,
              styles: {...rowText},
            });
          },
          width: 250,
        },
        {
          header: 'Descriptions',
          field: 'milestone.description',
          minWidth: 180,
        },
        {
          header: 'Total',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.billing_hrs && `${row?.billing_hrs} h`}
              </Text>
            );
          },
        },
        {
          header: 'Delivered',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.totalBilledHours && `${row?.totalBilledHours} h`}
              </Text>
            );
          },
        },
        {
          header: 'Rem. Delivery',
          width: 120,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.unDeliveredHours && `${row?.unDeliveredHours} h`}
              </Text>
            );
          },
        },
        {
          header: 'Approval Status',
          width: 160,
          align: 'right',
          type: 'colorTag',
          field: 'approval_status',
          colorField: randomColor,
        },
        {
          header: 'Action',
          align: 'right',
          width: 120,
          render: ({row, styles: {rowText = {}} = {}}) => {
            return row?.unDeliveredHours > 0 ? (
              <Text
                onPress={() => {
                  navigation.navigate('add-work-in-progress', {
                    row,
                    period,
                  });
                }}
                style={{...rowText, color: color?.INFORMATION_HIGH}}>
                Submit WIP
              </Text>
            ) : (
              void 0
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity history',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        {
          title: 'View allocations',
          onPress: ({row}) => {
            navigation.navigate('active-order-allocations-fixed', {
              addOnFilter: {
                milestone_id: row?.milestone?._id,
                project_type: 'f',
              },
              period,
            });
          },
        },
        {
          title: 'Delivery Progress',
          onPress: ({row}) => {
            navigation.navigate('delivery-progress', {
              deliveryProgressFilter: {
                project_resource_working_data: row?._id,
                order_type: 'ot',
                status: {$in: ['Delivered', 'Fully Delivered']},
              },
              period,
            });
          },
        },
      ]}
    />
  );
};

export const ActiveOrderAllocations = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row, period, addOnFilter = {}} = params || {};
  return (
    <Table
      {...props}
      variant={'bordered'}
      addOnFilter={{
        ...addOnFilter,
      }}
      params={{period, projectResourceWorkingData: row}}
      api={`/projectResourceWorking/activeOrders/allocations`}
      renderHeader={() => <TableHeader title="Allocations" />}
      columns={[
        {
          field: 'employee',
          header: 'Resource',
          type: 'userAvatarChip',
          minWidth: 200,
        },
        {
          field: 'assigned_type',
          header: 'Resource Type',
          width: 140,
        },
        {
          header: 'Duration',
          align: 'center',
          width: 180,
          render: ({row, styles: {rowText = {}} = {}}) => {
            return Durations({
              styles: rowText,
              fromDate: row?.from_date,
              toDate: row?.to_date,
            });
          },
        },
        {
          header: 'Skill',
          children: [
            {
              field: 'tool.name',
              header: 'Project Skill',
              width: 140,
            },
            {
              field: 'resource_tool.name',
              header: 'Resource Skill',
              width: 140,
            },
          ],
        },
        {
          header: 'Approved',
          children: [
            {
              header: 'EWD',
              field: 'totalEwdHours',
              type: 'number',
              width: 100,
            },
            {
              header: 'Leaves',
              field: 'totalLeaveHours',
              type: 'number',
              width: 100,
            },
          ],
        },
        {
          header: 'Effort',
          field: 'totalActualWorkingHours',
          type: 'number',
          width: 90,
        },
        {
          header: 'Output',
          field: 'totalWorkingHours',
          type: 'number',
          width: 90,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.totalWorkingHours && row?.totalWorkingHours}
                items={[
                  {
                    value: row?.hours && `${row?.hours} h`,
                  },
                ]}
              />
            );
          },
        },
      ]}
    />
  );
};

export const ActiveOrderAllocationsFixed = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row, period, addOnFilter = {}} = params || {};
  const colors = useTheme('colors');
  return (
    <Table
      {...props}
      variant={'bordered'}
      addOnFilter={{
        ...addOnFilter,
      }}
      params={{period, projectResourceWorkingData: row}}
      api={`/allocatedList`}
      renderHeader={() => <TableHeader title="Allocations" />}
      columns={[
        {
          field: 'employee',
          header: 'Engineer',
          type: 'userAvatarChip',
          minWidth: 200,
        },
        {
          field: 'employee.experience',
          header: 'Exp.',
          align: 'right',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>{row?.employee?.experience} yrs</Text>
            );
          },
          width: 140,
        },
        {
          header: 'Skill',
          children: [
            {
              field: 'tool.name',
              header: 'Project Skill',
              width: 140,
            },
            {
              field: 'resource_tool.name',
              header: 'Resource Skill',
              width: 140,
            },
          ],
        },
        {
          header: 'Allocated Hours',
          field: 'hours',
          align: 'right',
          render: ({row, styles: {rowText = {}}}) => {
            const {hours} = row;
            const convertIntoPercentage = (hours / 9) * 100;
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {convertIntoPercentage.toFixed(0)}%
              </Text>
            );
          },
          width: 140,
        },
        {
          header: 'Last Rating',
          align: 'center',
          render: ({row}) => {
            if (row?.employee?.project_feedback_rating) {
              const project_rating = row?.employee?.project_feedback_rating;
              if (project_rating == 'A+') {
                return (
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'CE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'A') {
                return (
                  <Chip
                    color={colors.ACCENT6_LOW}
                    textColor={colors.ACCENT6_HIGH}
                    value={'OE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'B+') {
                return (
                  <Chip
                    color={colors.ACCENT1_LOW}
                    textColor={colors.ACCENT1_HIGH}
                    value={'ME'}
                    borderRadius={6}
                  />
                );
              }

              if (project_rating == 'B') {
                return (
                  <Chip
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'PE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'C') {
                return (
                  <Chip
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'DE'}
                    borderRadius={6}
                  />
                );
              }
            }
          },
          width: 100,
        },
      ]}
    />
  );
};
