import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const DripEmailForm = props => {
  const {
    route: {params},
    defaultValue,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/dripEmailContents',
    eventSourceId: ['dripEmailAdded'],
  });
  const {department, product, marketing_theme, row} = params;
  return (
    <Form
      api={`/dripEmailContents/${row?._id}`}
      fields={{
        email_subject: 1,
        email_body: 1,
        drip_sequence: 1,
        send_after: 1,
        when: 1,
        value_proposition: 1,
        tags: 1,
        type: 1,
        name: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{
        created_on: getZeroTimeDate(new Date()),
        department: department?._id,
        product: product?._id,
        status: 'active',
        marketing_theme: marketing_theme?._id,
        target_audience: marketing_theme?.target_audience,
        ...defaultValue,
      }}
      layoutFields={[
        // {
        //   field: 'type',
        //   type: 'autoComplete',
        //   placeholder: 'Type',
        //   label: 'Type',
        //   options: ['Message Series', 'Connection Message'],
        // },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Subject',
          field: 'email_subject',
          type: 'text',
          size: 12,
        },
        {
          label: 'Body',
          field: 'email_body',
          type: 'richText',
        },
      ]}
      {...props}
    />
  );
};

export const AddDripEmailForm = props => {
  return (
    <DripEmailForm
      header={'Add Drip Email'}
      submitAction={{label: 'Save & New', type: 'saveAndNew'}}
      {...props}
    />
  );
};

export const EditDripEmailForm = props => {
  return <DripEmailForm mode="edit" header={'Edit Drip Email'} {...props} />;
};

export const DetailDripEmailForm = props => {
  return (
    <DripEmailForm
      mode="edit"
      readOnly={true}
      header={'Detail Drip Email'}
      {...props}
    />
  );
};
