import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const EmailProviderAddForm = props => {
  const {header = 'Add Providers'} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/emailproviders',
    eventSourceId: ['providersAdded'],
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
          placeholder: 'Name',
          required: true,
        },
        {
          field: 'type',
          type: 'autoComplete',
          label: 'Type',
          placeholder: 'Type',
          options: ['sendgrid'],
          required: true,
        },
        {
          field: 'credentials',
          type: 'password',
          label: 'Credentials',
          placeholder: 'Credentials',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditEmailProviderAddForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <EmailProviderAddForm
      mode="edit"
      api={`/emailproviders/${_id}`}
      header={'Edit Provider'}
      {...props}
    />
  );
};
