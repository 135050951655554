import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ArchiveThreadGoalForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/threadgoals',
    eventSourceId: ['threadGoal', 'markCompleted'],
  });

  return (
    <Form
      header={{
        title: 'Archive Goal',
        secondaryTitle: row?.goal || '',
      }}
      submitAction="Archive"
      mode="edit"
      api={'/threadgoals/' + row?._id}
      fields={{
        _id: 1,
      }}
      beforeSubmit={({data}) => {
        return {
          data: {
            status: 'Archive',
            ...data,
          },
        };
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'archive_reason',
          type: 'textArea',
          label: 'Reason',
          required: true,
        },
      ]}
    />
  );
};
