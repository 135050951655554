import React from 'react';
import {Table} from '../../../components/table/Table';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';
import {Text} from '@unthinkable/react-core-components';

export const LeadToQl = props => {
  let {
    navigation,
    route: {params},
    filter = {},
    filterParams = {},
  } = props;
  params = {...params, ...filterParams};
  return (
    <Table
      params={params}
      api={`/monthWise/lead-to-ql`}
      addOnFilter={{...filter}}
      searchFields={['name', 'website', 'email']}
      columns={[
        {
          header: 'Month',
          minWidth: 160,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: params?.period?.monthWiseBreakup
                    ? 'Month'
                    : params?.period?.filterOf,
                })}
              </Text>
            );
          },
        },
        {
          header: 'Carry Over Leads',
          field: 'carry_over_leads',
          type: 'number',
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              // filter: {
              //   _id: {$in: row.carry_over_leads_ids},
              // },
              filter: {
                ...row?.carryOverLeadsFilter,
              },
              // filter: {
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   pipeline_type: 'Prospect',
              //   uql_stage_date: {
              //     $lt: row?.startDate,
              //   },
              //   expected_ql_date: {$gte: row?.startDate},
              //   $and: [
              //     {
              //       $or: [
              //         {
              //           lost_date: null,
              //         },
              //         {
              //           lost_date: {
              //             $gte: row?.startDate,
              //           },
              //         },
              //       ],
              //     },
              //     {
              //       $or: [
              //         {
              //           sql_stage_date: null,
              //         },
              //         {
              //           sql_stage_date: {
              //             $gte: row?.startDate,
              //           },
              //         },
              //       ],
              //     },
              //   ],
              // },
            });
          },
        },
        {
          header: 'New Leads',
          field: 'new_leads',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            console.log('🚀 ~ LeadToQl ~ row:', row);
            navigation.navigate('potential-customer-for-lead-to-ql', {
              // filter: {
              //   _id: {$in: row.new_leads_ids},
              // },
              filter: {
                ...row?.newLeadsFilter,
              },
              // filter: {
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   pipeline_type: 'Prospect',
              //   uql_stage_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              // },
            });
          },
        },
        {
          header: 'Converted QL',
          field: 'converted_to_ql',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#07C838'}}>
                {row?.converted_to_ql}
              </Text>
            );
          },
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.convertedToQlFilter,
              },
              // filter: {
              //   _id: {$in: row.converted_to_ql_ids},
              // },
              // filter: {
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   pipeline_type: 'Prospect',
              //   sql_stage_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              // },
            });
          },
        },
        {
          header: 'Lost',
          field: 'lost_leads',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#FF3B30'}}>
                {row?.lost_leads}
              </Text>
            );
          },
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.lostLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.lost_leads_ids},
              // },
              // filter: {
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   pipeline_type: 'Prospect',
              //   lost_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              //   sql_stage_date: null,
              // },
            });
          },
        },
        {
          header: 'Deemed Lost',
          field: 'deemed_lost_leads',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              // filter: {
              //   _id: {$in: row.deemed_lost_leads_ids},
              // },
              filter: {
                ...row?.deemedLostLeadsFilter,
              },
              // filter: {
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   pipeline_type: 'Prospect',
              //   expected_ql_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              //   sql_stage_date: null,
              // },
            });
          },
        },
        {
          header: 'Cycle in Progress',
          field: 'cycleInProgress',
          type: 'number',
          width: 180,
        },
        {
          header: 'Conversion %',
          field: 'conversionPercentage',
          type: 'number',
          width: 140,
        },
        {
          header: 'Avg. Time to QL',
          field: 'avg_time_to_ql',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {`${row?.avg_time_to_ql?.toFixed(0)} d`}
              </Text>
            );
          },
          width: 140,
        },
      ]}
      moreActions={[
        {
          title: 'Open Qls',
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                product: {
                  $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
                },
                pipeline_type: 'Prospect',
                uql_stage_date: {
                  $lt: row?.startDate,
                },
                $and: [
                  {
                    $or: [
                      {
                        lost_date: null,
                      },
                      {
                        lost_date: {
                          $gte: row?.startDate,
                        },
                      },
                    ],
                  },
                  {
                    $or: [
                      {
                        sql_stage_date: null,
                      },
                      {
                        sql_stage_date: {
                          $gte: row?.startDate,
                        },
                      },
                    ],
                  },
                ],
              },
            });
          },
        },
      ]}
    />
  );
};

export const QlToOpportunity = props => {
  let {
    navigation,
    route: {params},
    filter = {},
    filterParams = {},
  } = props;
  params = {...params, ...filterParams};
  return (
    <Table
      params={params}
      api={`/monthWise/ql-to-opportunity`}
      addOnFilter={{...filter}}
      searchFields={['name', 'website', 'email']}
      columns={[
        {
          header: 'Month',
          minWidth: 160,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: params?.period?.monthWiseBreakup
                    ? 'Month'
                    : params?.period?.filterOf,
                })}
              </Text>
            );
          },
        },
        {
          header: 'Carry over QLs',
          field: 'carry_over_leads',
          type: 'number',
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.carryOverLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.carry_over_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   sql_stage_date: {
              //     $lt: row?.startDate,
              //   },
              //   expected_opportunity_date: {$gte: row?.startDate},
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   $and: [
              //     {
              //       $or: [
              //         {
              //           lost_date: null,
              //         },
              //         {
              //           lost_date: {
              //             $gte: row?.startDate,
              //           },
              //         },
              //       ],
              //     },
              //     {
              //       $or: [
              //         {
              //           opportunity_date: null,
              //         },
              //         {
              //           opportunity_date: {
              //             $gte: row?.startDate,
              //           },
              //         },
              //       ],
              //     },
              //   ],
              // },
            });
          },
        },
        {
          header: 'New QLs',
          field: 'new_leads',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.newLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.new_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   sql_stage_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              // },
            });
          },
        },
        {
          header: 'Converted to Opp.',
          field: 'converted_to_ql',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#07C838'}}>
                {row?.converted_to_ql}
              </Text>
            );
          },
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.convertedToOppFilter,
              },
              // filter: {
              //   _id: {$in: row.converted_to_ql_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   opportunity_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              // },
            });
          },
        },
        {
          header: 'Lost',
          field: 'lost_leads',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#FF3B30'}}>
                {row?.lost_leads}
              </Text>
            );
          },
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.lostLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.lost_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   lost_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              //   sql_stage_date: null,
              // },
            });
          },
        },
        {
          header: 'Deemed Lost',
          field: 'deemed_lost_leads',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.deemedLostLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.deemed_lost_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   expected_opportunity_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              //   // sql_stage_date: null,
              // },
            });
          },
        },
        {
          header: 'Cycle in Progress',
          field: 'cycleInProgress',
          type: 'number',
          width: 180,
        },
        {
          header: 'Conversion %',
          field: 'conversionPercentage',
          type: 'number',
          width: 140,
        },
        {
          header: 'Avg. Time to Opportunity',
          field: 'avg_time_to_ql',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {`${row?.avg_time_to_ql?.toFixed(0)} d`}
              </Text>
            );
          },
          width: 180,
        },
      ]}
    />
  );
};

export const OpportunityToCustomer = props => {
  let {
    navigation,
    route: {params},
    filter = {},
    filterParams = {},
  } = props;
  params = {...params, ...filterParams};
  return (
    <Table
      params={params}
      api={`/monthWise/opp-to-customer`}
      addOnFilter={{...filter}}
      searchFields={['name', 'website', 'email']}
      columns={[
        {
          header: 'Month',
          minWidth: 160,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: params?.period?.monthWiseBreakup
                    ? 'Month'
                    : params?.period?.filterOf,
                })}
              </Text>
            );
          },
        },
        {
          header: 'Carry over Opps.',
          field: 'carry_over_leads',
          type: 'number',
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.carryOverLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.carry_over_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   sql_stage_date: {
              //     $lt: row?.startDate,
              //   },
              //   expected_opportunity_date: {$gte: row?.startDate},
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   $and: [
              //     {
              //       $or: [
              //         {
              //           lost_date: null,
              //         },
              //         {
              //           lost_date: {
              //             $gte: row?.startDate,
              //           },
              //         },
              //       ],
              //     },
              //     {
              //       $or: [
              //         {
              //           opportunity_date: null,
              //         },
              //         {
              //           opportunity_date: {
              //             $gte: row?.startDate,
              //           },
              //         },
              //       ],
              //     },
              //   ],
              // },
            });
          },
        },
        {
          header: 'New Opps',
          field: 'new_leads',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.newLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.new_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   sql_stage_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              // },
            });
          },
        },
        {
          header: 'Converted to Customer',
          field: 'converted_to_ql',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#07C838'}}>
                {row?.converted_to_ql}
              </Text>
            );
          },
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.convertedToCustomerFilter,
              },
              // filter: {
              //   _id: {$in: row.converted_to_ql_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   opportunity_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              // },
            });
          },
        },
        {
          header: 'Lost',
          field: 'lost_leads',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText, color: '#FF3B30'}}>
                {row?.lost_leads}
              </Text>
            );
          },
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.lostLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.lost_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   lost_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              //   sql_stage_date: null,
              // },
            });
          },
        },
        {
          header: 'Deemed Lost',
          field: 'deemed_lost_leads',
          type: 'number',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('potential-customer-for-lead-to-ql', {
              filter: {
                ...row?.deemedLostLeadsFilter,
              },
              // filter: {
              //   _id: {$in: row.deemed_lost_leads_ids},
              // },
              // filter: {
              //   pipeline_type: 'Prospect',
              //   product: {
              //     $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
              //   },
              //   expected_opportunity_date: {
              //     $lte: row?.endDate,
              //     $gte: row?.startDate,
              //   },
              //   // sql_stage_date: null,
              // },
            });
          },
        },
        {
          header: 'Cycle in Progress',
          field: 'cycleInProgress',
          type: 'number',
          width: 180,
        },
        {
          header: 'Conversion %',
          field: 'conversionPercentage',
          type: 'number',
          width: 140,
        },
        {
          header: 'Avg. Time to Customer ',
          field: 'avg_time_to_ql',
          align: 'right',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text style={{...rowText}}>
                {`${row?.avg_time_to_ql?.toFixed(0)} d`}
              </Text>
            );
          },
          width: 180,
        },
      ]}
    />
  );
};
