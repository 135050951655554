import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import {leadControllers} from '../../leadGeneration/controllers/leadControllers';

export const ProjectJiraCredentialTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {project_id} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'ProjectJiraCredential',
  });
  const {syncLocomoWithJira} = leadControllers(props);

  return (
    <>
      <Table
        eventSourceId={['ProjectJiraCredential', 'syncOnJira']}
        renderHeader={() => (
          <TableHeader
            title={'Jira Credential Configuration'}
            actions={[
              <AddButton
                title="Jira Credential"
                view="add-project-jira-credential-form"
                params={props.route.params}
              />,
            ]}
          />
        )}
        api={`/projectjiracredentials`}
        fields={{
          email: 1,
          jira_key: 1,
          sync_on: 1,
          project_domain: 1,
          project_id: 1,
        }}
        filter={{project_id}}
        onRowPress={props => {
          navigation.navigate(`edit-project-jira-credential-form`, {
            ...props,
            showToken: false,
          });
        }}
        columns={[
          {
            header: 'Email',
            type: 'text',
            field: 'email',
          },
          {
            header: 'Jira Key',
            type: 'text',
            field: 'jira_key',
          },
          {
            header: 'Project Domain',
            type: 'text',
            field: 'project_domain',
          },
        ]}
        moreActions={[
          {
            title: 'Edit Token',
            onPress: ({row}) => {
              navigation.navigate('edit-project-jira-credential-token', {
                row,
                project_id,
              });
            },
          },
          {
            title: 'Sync with Jira',
            visible: ({row}) => !row?.sync_on,
            confirm: {
              title: 'Sync with Jira',
              message: 'Are you sure you want to sync locomo with jira?',
              confirmText: 'Sync',
            },
            onPress: ({row}) => {
              syncLocomoWithJira({row});
            },
          },
          {
            title: 'Delete',
            variant: 'error',
            confirm: {
              title: 'Delete',
              message: 'Are you sure you want to Delete?',
              confirmText: 'Delete',
            },
            onPress: ({row}) => {
              deleteInvoke({
                uri: `/projectjiracredentials/${row?._id}`,
              });
            },
          },
        ]}
      />
    </>
  );
};
