import React from 'react';
import moment from 'moment';

import {TabView} from '../../../components/tab';
import {TasksTable} from './TasksTable';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {taskFilters, useTaskActions} from './TaskActions';
import {OrganizationActiveTasksGroupByPlan} from './OrganizationActiveTasksGroupByPlan';

export const TeamTaskTabs = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {employee_team} = params;
  const {activityLogs, archive, updateDueDate, moveToBacklog, updatePlan} =
    useTaskActions({
      navigation,
      params,
    });

  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
    });
  };

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {params: filterParams, filter: extraFilter} = filterValues;

  const addOnFilter = {
    type: {
      $in: ['Task', 'Continuous'],
    },
    ...extraFilter,
  };

  if (filterParams.is_open == 'Yes') {
    addOnFilter.assigned_to = null;
  } else if (filterParams.is_open == 'No') {
    addOnFilter.assigned_to = {$exists: true};
  }

  const overdueTasksFilter = {
    status: 'active',
    ...addOnFilter,
    $and: [
      {
        due_date: {$lt: moment().utc().startOf('day').toDate()},
      },
    ],
  };

  return (
    <TabView
      selectedTab={'overdue'}
      api={`/employeeTeam/${employee_team?._id}/tasks`}
      tabs={{
        overdue: {
          label: 'Overdue',
          view: (
            <TasksTable
              isActive
              onRowPress={taskDetail}
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={{...overdueTasksFilter, status: 'backlog'}}
              moreActions={[
                updateDueDate,
                archive,
                moveToBacklog,
                activityLogs,
                updatePlan,
              ]}
            />
          ),
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                taskFilters.assigned_to,
                taskFilters.reviewer,
                taskFilters.custom,
                taskFilters.strategic,
                taskFilters.due_date,
                taskFilters.is_open,
                taskFilters.source,
              ]}
            />,
          ],
        },
        planned: {
          label: 'Planned',
          view: (
            <OrganizationActiveTasksGroupByPlan
              addOnFilter={addOnFilter}
              searchValue={searchValue}
              {...props}
            />
          ),
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[taskFilters.assigned_to]}
            />,
          ],
        },
        later: {
          label: 'Later',
          view: (
            <TasksTable
              isActive
              onRowPress={taskDetail}
              searchValue={searchValue}
              sort={{due_date: 1, createdAt: -1}}
              addOnFilter={{
                status: 'active',
                plan_date: null,
                ...addOnFilter,
              }}
              moreActions={[
                updateDueDate,
                archive,
                moveToBacklog,
                activityLogs,
                updatePlan,
              ]}
            />
          ),
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                taskFilters.assigned_to,
                taskFilters.reviewer,
                taskFilters.custom,
                taskFilters.strategic,
                taskFilters.due_date,
                taskFilters.is_open,
                taskFilters.source,
              ]}
            />,
          ],
        },
      }}
      {...props}
    />
  );
};
