import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const BugCommentForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {project, row, status} = params;

  const defaultValues = {status, project_id: project?._id};

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['Bug'],
    uri: `/projectbugs/updateBugStatus/${row._id}`,
    ...props,
  });

  return (
    <Form
      fields={{
        desc: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          placeholder: 'Write your comment here...',
          type: 'textArea',
          field: 'comment',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddBugCommentForm = props => {
  const {route: {params: {row} = {}} = {}} = props;

  return (
    <BugCommentForm
      header={{title: 'Add Comment', secondaryTitle: row?.bug ?? ''}}
      {...props}
    />
  );
};
