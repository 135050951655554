import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { GroupFilter, PeriodRangeFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import {
    OpportunityProjectionListFixed,
    OpportunityProjectionListTnM,
} from './opportunityProjectionLists';

export const TnMOpportunityProjectionTab = props => {
  const {extraAddOnFilter = {}} = props;
  let params = props?.route?.params;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues || {};
  const {organization, product, ...rest} = params;
  params = {
    ...rest,
    organization,
  };
  let tabs = {};

  tabs.tnm = {
    label: 'T&M',
    view: (
      <OpportunityProjectionListTnM
        {...props}
        searchValue={searchValue}
        params={params}
        addOnFilter={{...filter, ...extraAddOnFilter}}
        filterParams={filterParams}
      />
    ),
    api: '/pm/opportunity-projections/t&m',
    eventSourceId: ['OpportunityProjection'],
    addOnFilter: {...filter, ...extraAddOnFilter},
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          skipQuarter
          asParams
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },

            {
              type: 'autoComplete',
              label: 'Type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Customer',
                  value: 'Customer',
                },
                {
                  label: 'Prospect',
                  value: 'PotentialCustomer',
                },
              ],
              placeholder: 'Select',
              field: 'type',
            },
          ]}
        />,
      ]}
    />
  );
};

export const FixedOpportunityProjectionTab = props => {
  let params = props?.route?.params;
  const {extraAddOnFilter = {}} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues || {};
  const {organization, product, ...rest} = params;
  params = {
    ...rest,
    organization,
  };
  let tabs = {};

  tabs.fixed = {
    label: 'Fixed',
    view: (
      <OpportunityProjectionListFixed
        {...props}
        searchValue={searchValue}
        params={params}
        addOnFilter={{...filter, ...extraAddOnFilter}}
        filterParams={filterParams}
      />
    ),
    api: '/pm/opportunity-projections/fixed',
    eventSourceId: ['OpportunityProjection'],
    addOnFilter: {...filter, ...extraAddOnFilter},
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          skipQuarter
          asParams
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },

            {
              type: 'autoComplete',
              label: 'Type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Customer',
                  value: 'Customer',
                },
                {
                  label: 'Prospect',
                  value: 'PotentialCustomer',
                },
              ],
              placeholder: 'Select',
              field: 'type',
            },
          ]}
        />,
      ]}
    />
  );
};
