import React from 'react';
import {Text, TouchableOpacity} from '@unthinkable/react-core-components';
import {ActionTextStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';

export const ActionText = ({text, onPress}) => {
  const styles = useStyles(ActionTextStyles);
  return (
    <TouchableOpacity onPress={onPress}>
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};
