import React from 'react';
import {MultiStepForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddValueProposition = props => {
  const {
    route: {params},
  } = props;
  const {product, department, row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/valuepropositions',
    eventSourceId: 'valuePropositionAdded',
    ...props,
  });
  return (
    <MultiStepForm
      api={`/valuepropositions/${row?._id}`}
      fields={{
        name: 1,
        use_case: 1,
        problem: 1,
        current_way: 1,
        limitations_of_current_way: 1,
        our_prospect_capability: 1,
        our_products_feature: 1,
        benefit: 1,
      }}
      onSubmit={onSubmit}
      defaultValues={{
        target_audience: row?._id,
        product: product?._id,
        department: department?._id,
        status: 'active',
      }}
      header={{
        title: 'Add Value Proposition',
        secondaryTitle: row?.name,
      }}
      clickableTabs
      steps={[
        {
          title: 'Use Case',
          fields: [
            {
              label: 'Value Proposition',
              placeholder: 'Value Proposition',
              field: 'name',
              type: 'textArea',
            },
            {
              label: 'Use Case',
              placeholder: 'Use Case',
              field: 'use_case',
              type: 'textArea',
            },
            {
              label: 'Problem',
              placeholder: 'Problem',
              field: 'problem',
              type: 'textArea',
            },
          ],
        },
        {
          title: 'Current scenario',
          fields: [
            {
              label: 'Current Way',
              placeholder: 'Current Way',
              field: 'current_way',
              type: 'textArea',
            },
            {
              label: 'Limitations Of Current Way',
              placeholder: 'Limitations Of Current Way',
              field: 'limitations_of_current_way',
              type: 'textArea',
            },
          ],
        },
        {
          title: 'Our product & benefit',
          fields: [
            {
              label: "Our Products's capability",
              placeholder: "Our Products's capability",
              field: 'our_prospect_capability',
              type: 'textArea',
            },
            {
              field: 'our_products_feature',
              type: 'textArea',
              label: 'Our Products Feature',
              placeholder: 'Our Products Feature',
            },
            {
              field: 'benefit',
              type: 'textArea',
              label: 'Benefit',
              placeholder: 'Benefit',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditValueProposition = props => {
  return (
    <AddValueProposition
      header={{title: 'Edit Value Proposition'}}
      mode="edit"
      {...props}
    />
  );
};
