import {Button} from '@unthinkable/react-button';
import {
  View,
  Styled,
  Text,
  Image,
  Row,
} from '@unthinkable/react-core-components';

export const ConfirmContainer = Styled(View)`
    padding:16px;
    gap:8px;
    ${props => props.styles?.container}
`;

export const TitleContainer = Styled(View)`
    gap:3px;
    ${props => props.styles?.titleContainer}
`;

export const TitleText = Styled(Text)`
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    color: #19191A;
    ${props => props.styles?.title}
`;

export const SecondaryTitleText = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #7D7D82;
    ${props => props.styles?.secondaryTitle}
`;

export const MessageText = Styled(Text)`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #7D7D82;
    ${props => props.styles?.messageText}
`;

export const FooterContainer = Styled(Row)`
    align-items:center;
    justify-content:flex-end;
    gap:12px;
    ${props => props?.styles?.footerContainer}
`;

export const CancelButton = Styled(Button)`
    ${props => props?.styles}
`;

export const ConfirmButton = Styled(Button)`
    ${props => props?.styles}
`;
