import { Chip } from '@unthinkable/react-chip';
import { Text } from '@unthinkable/react-core-components';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { UserAvatar } from '../../../components/avatar/UserAvatar';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';
import {
    assignAsLearning,
    assignAsReplacement,
    assignTraining,
    markNotAvailable,
    markUnBillable,
    proposedDeploymentDate,
    RenderBenchSince,
    renderExperience,
    unMarkTraining,
    updateCV,
    updateResourceSkill
} from './UnavailableList';

const EXPERIENCE = {
  header: 'Exp.',
  render: renderExperience,
  width: 80,
  type: 'number',
};

const SKILLS = {
  header: 'Skill',
  field: 'assigned_to.resource_language.name',
  type: 'colorTag',
  colorField: 'assigned_to.resource_language.color',
  width: 160,
};

export const BenchCostReport = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    searchValue,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'benchCostReport',
  });
  return (
    <Table
      {...props}
      params={{...params, is_AllBenchEmployee: true}}
      eventSourceId={['benchCostReport']}
      limit={2000}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="name"
      api={`/employees/benchcost`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Engineer',
          minWidth: 300,
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                values={[
                  {value: assigned_to?.official_email_id},
                  {value: `${row?.experience} yr`},
                ]}
                primarySubTitle={assigned_to?.types}
                primarySubTitleColor={assigned_to?.types_color}
              />
            );
          },
        },
        SKILLS,
        {
          header: 'DO',
          type: 'userAvatar',
          field: 'assigned_to.delivery_owner',
          width: 60,
          align: 'center',
        },
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
        },
        // {
        //   header: 'Hrs/Day',
        //   align: 'right',
          // render: renderAvailablitity,
        //   width: 70,
        // },
        {
          header: 'Hrs/Day',
          align: 'center',
          width: 80,
          render: ({row, styles: {rowText = {}}}) => {
            const colors = useTheme('colors');
            const {totalActualWorkingHours} = row;
            let freeHours = 0;
            freeHours = 9 - Number(totalActualWorkingHours);
            const convertIntoPercentage = (freeHours / 9) * 100;
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {convertIntoPercentage.toFixed(0)}%
              </Text>
            );
          },
        },
        // {
        //   header: 'Status',
        //   render: renderStatusForAll,
        //   width: 150,
        // },
        {
          header: 'Skill Leader',
          align: 'right',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          render: CountCellRender,
          header: 'Cost',
          field: 'bench_cost',
          type: 'currency',
          width: 120,
          count_field: 'bench_cost',
          aggregate: true,
        },
        {
          header: 'Last Rating',
          render: ({row}) => {
            const colors = useTheme('colors');
            if (row?.assigned_to?.project_rating) {
              const {project_rating} = row?.assigned_to;
              if (project_rating == 'A+') {
                return (
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'CE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'A') {
                return (
                  <Chip
                    color={colors.ACCENT6_LOW}
                    textColor={colors.ACCENT6_HIGH}
                    value={'OE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'B+') {
                return (
                  <Chip
                    color={colors.ACCENT1_LOW}
                    textColor={colors.ACCENT1_HIGH}
                    value={'ME'}
                    borderRadius={6}
                  />
                );
              }

              if (project_rating == 'B') {
                return (
                  <Chip
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'PE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'C') {
                return (
                  <Chip
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'DE'}
                    borderRadius={6}
                  />
                );
              }
            }
          },
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
      ]}
    />
  );
};

export const BenchCostReportTab = props => {
  const {download} = useAppStateContext();
  const icons = useTheme('icons');
  const {route: {params} = {}} = props;
  let defaultFilter = {is_manager: false};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  let addOnFilter = {...filterValues.filter};
  const tabs = {
    bench_cost_report: {
      label: 'Bench cost report',
      view: (
        <BenchCostReport
          {...props}
          addOnFilter={addOnFilter}
          searchValue={searchValue}
        />
      ),
      eventSourceId: 'benchCostReport',
      addOnFilter: {...addOnFilter},
      params: {...params, is_AllBenchEmployee: true},
      limit: 2000,
      api: `/employees/benchcost`,
      search: searchValue,
      searchFields: 'name',
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: `/employees/benchcostReportExport`,
              props: {
                params: {...params, is_AllBenchEmployee: true},
                addOnFilter: {
                  ...filterValues?.filter,
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
