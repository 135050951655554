import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const ThemeForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, theme, searchValue, afterSubmit, projectlibrary_id} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/projectThemes',
    eventSourceId: ['Theme'],
    ...props,
  });

  let defaultValues = {name: searchValue};

  if (project?._id) {
    defaultValues.project_id = project?._id;
  }

  if (projectlibrary_id) {
    defaultValues.library_id = projectlibrary_id;
  }

  return (
    <Form
      api={`/projectThemes/${theme?._id}`}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Name',
          placeholder: 'Name',
          type: 'textArea',
          field: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddThemeForm = props => {
  return <ThemeForm header="Add Theme" {...props} />;
};

export const UpdateThemeForm = props => {
  return <ThemeForm mode="edit" header="Theme Detail" {...props} />;
};
