import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssessmentForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/assessments',
    eventSourceId: 'assessmentsAdded',
  });
  return (
    <Form
      api={`/assessments/${row?._id}`}
      fields={{
        name: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const AddAssessement = props => {
  return <AssessmentForm header={'Add Assessment'} {...props} />;
};

export const EditAssessment = props => {
  return <AssessmentForm header={'Edit Assessment'} mode="edit" {...props} />;
};
