import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {Text, View} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {
  getDateRange,
  getZeroTimeDate,
} from '@unthinkable/react-date-picker/src/DateUtility';

const RenderProposalWonStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row.won_proposal_count && row?.won_proposal_count != 0) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row.won_proposal_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};
const RenderRFRWonStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row.won_rfr_count && row?.won_rfr_count != 0) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row.won_rfr_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderRFQWonStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log('row--------------------', row);
  const colors = useTheme('colors');
  if (row.won_rfq_count && row?.won_rfq_count != 0) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row.won_rfq_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderProposalLostStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.drop_proposal_count && row?.drop_proposal_count != 0) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {row?.drop_proposal_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderRFQLostStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.drop_rfq_count && row?.drop_rfq_count != 0) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {row?.drop_rfq_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderRFRLostStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.drop_rfr_count && row?.drop_rfr_count != 0) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {row?.drop_rfr_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const SalesTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;

  const {onChangeFilter, filterValues} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
  });
  const {params: filterParams = {}} = filterValues;

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 10,
        }}>
        <Text
          style={{
            // padding: 10,
            color: '#19191A',
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Inter-Regular',
            lineHeight: '22px',
          }}>
          Opportunity
        </Text>
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />
      </View>
      <Table
        api={`/customer/${row?._id}/sales`}
        params={filterParams}
        variant={'bordered'}
        columns={[
          {
            header: 'Month',
            field: 'month_name',
          },
          // {
          //   header: 'Proposal',
          //   children: [
          //     {
          //       type: 'number',
          //       field: 'won_proposal_count',
          //       render: RenderProposalWonStatus,
          //       header: 'Won',
          //       width: 150,
          //       onPress: ({row}) => {
          //         navigation.navigate('customer-proposal-list', {
          //           row: row?.all_proposal,
          //         });
          //       },
          //     },
          //     {
          //       type: 'number',
          //       field: 'drop_proposal_count',
          //       header: 'Dropped',
          //       render: RenderProposalLostStatus,
          //       onPress: ({row}) => {
          //         navigation.navigate('customer-proposal-list', {
          //           row: row?.drop_proposal,
          //         });
          //       },
          //       width: 150,
          //     },
          //     {
          //       type: 'number',
          //       field: 'proposal_count',
          //       header: 'All',
          //       onPress: ({row}) => {
          //         navigation.navigate('customer-proposal-list', {
          //           row: row?.all_proposal,
          //         });
          //       },
          //       width: 150,
          //     },
          //   ],
          // },
          {
            header: 'RFQ',
            children: [
              {
                type: 'number',
                field: 'won_rfq_count',
                render: RenderRFQWonStatus,
                header: 'Won',
                width: 100,
                onPress: ({row}) => {
                  navigation.navigate('customer-all-rfq-list', {
                    row: row?.won_rfq,
                  });
                },
              },
              {
                type: 'number',
                field: 'drop_rfq_count',
                render: RenderRFQLostStatus,
                header: 'Dropped',
                onPress: ({row}) => {
                  navigation.navigate('customer-all-rfq-list', {
                    row: row?.drop_rfq,
                  });
                },
                width: 100,
              },
              {
                type: 'number',
                field: 'all_rfq_count',
                header: 'All',
                onPress: ({row}) => {
                  navigation.navigate('customer-all-rfq-list', {
                    row: row?.all_rfq,
                  });
                },
                width: 100,
              },
            ],
          },
          {
            header: 'RFR',
            children: [
              {
                type: 'number',
                field: 'won_rfr_count',
                render: RenderRFRWonStatus,
                header: 'Won',
                width: 100,
                onPress: ({row}) => {
                  navigation.navigate('customer-all-rfr-list', {
                    row: row?.won_rfr,
                  });
                },
              },
              {
                type: 'number',
                field: 'drop_rfr_count',
                render: RenderRFRLostStatus,
                header: 'Dropped',
                onPress: ({row}) => {
                  navigation.navigate('customer-all-rfr-list', {
                    row: row?.drop_rfr,
                  });
                },
                width: 100,
              },
              {
                type: 'number',
                field: 'all_rfr_count',
                header: 'All',
                onPress: ({row}) => {
                  navigation.navigate('customer-all-rfr-list', {
                    row: row?.all_rfr,
                  });
                },
                width: 100,
              },
            ],
          },
        ]}
      />
    </>
  );
};
