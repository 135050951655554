import React from 'react';
import {Row, Text} from '@unthinkable/react-core-components';

import NestedTable from '../../../../components/form/NestedTable';
import {Form} from '../../../../components/form/Form';
import {PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';
import {Tag} from '../../../../components/chip/Chip';
import {MultiTag} from '../../../../components/chip/MultiChip';

const dataServiceLibraryFields = ({params}) => {
  const {project} = params;

  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    name: {
      label: 'Name',
      type: 'text',
      field: 'name',
      required: true,
    },
    signature: {
      label: 'Signature',
      type: 'text',
      field: 'signature',
    },
    return_type: {
      label: 'Return Type',
      type: 'text',
      field: 'return_type',
    },
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    description: {
      label: 'Description',
      type: 'textArea',
      field: 'desc',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    remarks: {
      label: 'Remarks',
      type: 'richText',
      field: remarksField,
      placeholder: 'Write remarks here...',
      minHeight: 200,
    },
  };
};

const ParametersNestedTable = props => {
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={({values}) => {
        const {name} = values;
        return {
          title: 'Add Parameters',
          secondaryTitle: name,
        };
      }}
      editFormHeader={({values}) => {
        const {name} = values;
        return {
          title: 'Edit Parameters',
          secondaryTitle: name,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      inlineAddForm
      addInBottom
      inlineFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Type',
          type: 'multiText',
          field: 'type',
          size: 6,
        },
      ]}
      fields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Type',
          type: 'multiText',
          field: 'type',
          size: 6,
        },
        {
          label: 'Default Value',
          type: 'text',
          field: 'default_value',
        },
      ]}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
        },
        {
          header: 'Type',
          field: 'type',
          width: 120,
          render: ({row}) => {
            return <MultiTag values={row.type} />;
          },
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
        },
      ]}
    />
  );
};

const CodeSampleNestedTable = props => {
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={() => {
        return {
          title: 'Add Code Sample',
        };
      }}
      editFormHeader={({values}) => {
        const {title} = values;
        return {
          title: 'Edit Code Sample',
          secondaryTitle: title,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      fields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Example Code',
          field: 'example_code',
          placeholder: 'Write your Service code here...',
          type: 'textArea',
          height: 500,
        },
        {
          field: 'expected_output',
          label: 'Expected Output',
          placeholder: 'Enter expected output here...',
          type: 'textArea',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
      ]}
      columns={[
        {
          header: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
        },
      ]}
    />
  );
};

export const DataServiceLibraryForm = props => {
  const {
    route: {params},
    mode,
  } = props;

  const fields = dataServiceLibraryFields({params});

  const tabs = {
    basic_info: {
      label: 'Basic Info',
      layoutFields: [
        fields.name,
        fields.signature,
        fields.return_type,
        {
          collapsedFields: [fields.owner, fields.description],
        },
      ],
    },
    api_parameters: {
      label: 'Parameters',
      layoutFields: [
        {
          nested: true,
          field: 'api_parameters',
          render: props => <ParametersNestedTable {...props} />,
        },
      ],
    },
    example: {
      label: 'Examples',
      layoutFields: [
        {
          nested: true,
          field: 'examples',
          render: props => <CodeSampleNestedTable {...props} />,
        },
      ],
    },
    remarks: {
      visible: !!mode,
      label: 'Remarks',
      layoutFields: [fields.remarks],
    },
  };

  return <Form type={'tab'} submitAction="Save" tabs={tabs} {...props} />;
};

export const AddDataServiceLibraryForm = props => {
  const {
    route: {params},
  } = props;

  const {project, feature, model, searchValue, afterSubmit, projectlibrary_id} =
    params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    name: searchValue,
    project_id: project?._id,
    model_id: model?._id,
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    feature_id: feature?._id,
  };
  if (projectlibrary_id) {
    initialValues.library_id = projectlibrary_id;
  }

  return (
    <DataServiceLibraryForm
      header="Add Data Service"
      onSubmit={createAsset}
      afterSubmit={afterSubmit}
      beforeSubmit={requiredValidation}
      defaultValues={initialValues}
      eventSourceId={['Feature', 'DataServiceLibrary']}
      {...props}
    />
  );
};

export const DataServiceLibraryDetailForm = props => {
  const {
    route: {params},
  } = props;

  const {row, feature, readOnly} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  return (
    <DataServiceLibraryForm
      key={row._id}
      mode="edit"
      isDualMode
      header={{title: 'Data Service Detail', secondaryTitle: row?.name}}
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      readOnly={readOnly}
      data={row}
      {...props}
    />
  );
};
