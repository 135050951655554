import {useState} from 'react';
import {Col, View} from '@unthinkable/react-core-components';
import {QAFeaturesTable} from './FeaturesTable';
import {TableHeader} from '../../../components/table/Headers';
import {RepositoriesEnvironmentTable} from './RepositoriesEnvironmentTable';
import {Button} from '../../../components/button/Button';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';

export const RepositoryEnvironmentWithFeatures = props => {
  const {
    route: {params = {}},
  } = props;
  const {repository} = params;
  const [loading, setLoading] = useState(false);

  const {data} = useFetchData({
    api: `/repositories/${repository?._id}`,
    only: true,
    fields: {
      lock_for_qa: 1,
    },
    eventSourceId: 'LOCK_FOR_QA',
  });

  const putInvoke = useInvoke({
    method: 'put',
    setLoading,
    eventSourceId: 'LOCK_FOR_QA',
  });

  const isLocked = data?.lock_for_qa;

  return (
    <Col gap={12} style={{flex: 1}}>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <TableHeader
          title="Unreleased"
          actions={[
            <Button
              text={isLocked ? 'Unlock QA' : 'Lock QA'}
              onPress={() =>
                putInvoke({
                  uri: `/repositories/${repository?._id}`,
                  props: {lock_for_qa: !isLocked},
                })
              }
              loading={loading}
            />,
          ]}
        />
        <View style={{flex: 1, overflow: 'hidden'}}>
          <QAFeaturesTable {...props} />
        </View>
      </View>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <TableHeader title="Environments" />
        <View style={{flex: 1, overflow: 'hidden'}}>
          <RepositoriesEnvironmentTable {...props} />
        </View>
      </View>
    </Col>
  );
};
