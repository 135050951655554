export const RadioStyles = ({theme: {colors, fonts, icons}}) => {
  return {
    container: {
      borderRadius: 100,
      width: 16,
      height: 16,
      borderWidth: 1,
      borderColor: colors.BRAND_UPPER_MEDIUM,
      justifyContent: 'center',
      alignItems: 'center',
    },
    innerContainer: {
      borderRadius: 100,
      width: 10,
      height: 10,
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
    },
    label: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const RadioGroupStyles = ({theme}) => {
  return {
    container: {
      columnGap: 36,
      rowGap: 8,
    },
    radio: {
      ...RadioStyles({theme}),
    },
  };
};
