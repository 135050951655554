import React, {forwardRef, useState} from 'react';
import {useColorMode, useStyles} from '@unthinkable/react-theme';
import {InputContainer, InputWrapper} from '@unthinkable/react-input';
import {Editor} from '@monaco-editor/react';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {AITextAreaInputStyles} from './theme';
import {
  ActionContainer,
  CommitIcon,
  CommitText,
  ImproviseButton,
  ImproviseIcon,
  ImproviseRow,
  ImproviseText,
  RegenerateButton,
  RegenerateIcon,
  RegenerateText,
  Separator,
} from '@unthinkable/react-text-area-input/src/styles';
import {ThreeDotLoader} from '@unthinkable/react-activity-indicator';
import {TextAreaInput} from '@unthinkable/react-text-area-input';
import pluralize from 'pluralize';
import {useInvoke} from '../../../controllers/useInvoke';

export const AiCodeEditorInput = forwardRef((props, ref) => {
  let {
    value,
    onChangeValue,
    generateUrl,
    entityName,
    prompt_name,
    entity_category,
    params = {},
    form: {values, setFieldValue},
    field,

    width,
    height,
    options,
    defaultLanguage = 'javascript',
    is_committable = true,
  } = props;

  const {folder_path_id, file_name, feature_id} = values;

  if (typeof generateUrl === 'function') {
    generateUrl = generateUrl(values);
  }

  if (typeof params === 'function') {
    params = params(values);
  }

  const {colorMode} = useColorMode();

  const [loading, setLoading] = useState(false);
  const [isCommitLoading, setCommitLoading] = useState(false);

  const postInvoke = useInvoke({
    method: 'post',
  });

  const [improvementText, updateImprovementText] = useState();

  generateUrl = generateUrl || `/${pluralize.plural(entityName)}/ai`;

  const {aiStyles} = useStyles(AITextAreaInputStyles);

  const onPressImprovise = async () => {
    setLoading(true);
    let response = await postInvoke({
      uri: generateUrl,
      props: {
        ...params,
        improvementText,
        prompt_name: prompt_name || 'EntityCodeGeneration',
        entity: entityName,
        entity_category: entity_category || 'Code',
      },
    });
    response = response?.data?.content || response?.data || response;
    onChangeValue(response?.code || '');
    setLoading(false);
  };

  const onPressCommit = async () => {
    if (isCommitLoading) {
      return true;
    }

    setCommitLoading(true);
    await postInvoke({
      uri: '/commitGitFile',
      props: {
        ...params,
        fileContent: values[field],
        folder_path_id: folder_path_id?._id,
        file_name: file_name?.name,
        feature_id: feature_id?._id,
      },
    });
    setCommitLoading(false);
  };

  return (
    <InputWrapper {...props}>
      <InputContainer {...props} inputRef={ref}>
        <Editor
          defaultLanguage={defaultLanguage}
          value={value}
          onChange={onChangeValue}
          theme={colorMode === 'dark' ? 'vs-dark' : 'vs-light'}
          options={{
            minimap: {
              enabled: false,
            },
            ...options,
          }}
          width={width}
          height={height}
        />

        {values[field] ? (
          <View style={{marginTop: 10}}>
            <TextAreaInput
              label="Improvement Text"
              placeholder={'Write your suggestion here'}
              value={improvementText}
              onChangeValue={text => {
                updateImprovementText(text);
              }}
              inputProps={{minRows: 3, maxRows: 6}}
            />
          </View>
        ) : (
          void 0
        )}

        <ActionContainer styles={aiStyles}>
          {values[field] ? (
            <Row>
              <RegenerateButton onPress={onPressImprovise} styles={aiStyles}>
                <RegenerateIcon styles={aiStyles} loading={loading} />
                <RegenerateText styles={aiStyles}>Regenerate</RegenerateText>
              </RegenerateButton>
              {is_committable && folder_path_id && file_name && feature_id && (
                <>
                  <Separator styles={aiStyles} />
                  <RegenerateButton onPress={onPressCommit} styles={aiStyles}>
                    {isCommitLoading ? (
                      <ThreeDotLoader />
                    ) : (
                      <>
                        <CommitIcon styles={aiStyles} />
                        <CommitText styles={aiStyles}>Commit</CommitText>
                      </>
                    )}
                  </RegenerateButton>
                </>
              )}
            </Row>
          ) : (
            <ImproviseRow
              styles={{
                improviseRow: {
                  ...aiStyles.improviseRow,
                  justifyContent: 'center',
                },
              }}>
              <ImproviseButton onPress={onPressImprovise} styles={aiStyles}>
                <ImproviseIcon styles={aiStyles} />
                <Text style={aiStyles?.normalAIText}>
                  {loading ? 'Generating' : 'Generate'}
                </Text>
                {loading ? <ThreeDotLoader /> : void 0}
              </ImproviseButton>
            </ImproviseRow>
          )}
        </ActionContainer>
      </InputContainer>
    </InputWrapper>
  );
});
