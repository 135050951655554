import {
  Image,
  Row,
  ScrollView,
  Styled,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import ExpandCollapseIcon from './images/ExpandCollapse.svg';

export const Container = Styled(View).withConfig({
  shouldForwardProp: prop => !['direction'].includes(prop),
})`
  flex:1;
  overflow:hidden;
  ${props => props.styles?.container}
  ${({direction}) => (direction ? {flexDirection: direction} : void 0)}
`;

export const MenuBarContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['isOpen', 'hidden'].includes(prop),
})`
  height:100%;
  transition: width 0.3s ease;
  ${props => props.styles?.menuBarContainer}
  ${props => {
    const {menuBarWidth, collapsedMenuBarWidth} = props.styles || {};
    if (menuBarWidth && collapsedMenuBarWidth) {
      return {
        width: props.isOpen ? menuBarWidth : collapsedMenuBarWidth,
      };
    }
  }}
  ${({hidden}) => (hidden ? {display: 'none'} : void 0)}
`;

export const MenuBarScrollView = Styled(ScrollView)`
  gap: 2px;
  ${props => props.styles?.menuBarScrollView}
`;

export const CollapseView = Styled(View).withConfig({
  shouldForwardProp: prop => !['isCollapsed'].includes(prop),
})`
  opacity: ${props => (props.isCollapsed ? 1 : 0)};
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 0;
  background-color: #FFFFFF;
  ${({isCollapsed}) => (!isCollapsed ? {display: 'none'} : {})}
  ${props => props.styles?.collapseView}
`;

export const ToggleButton = Styled(TouchableOpacity).withConfig({
  shouldForwardProp: prop => !['hidden'].includes(prop),
})`
  position: absolute;
  bottom: 12px;
  right: 1px;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  ${props => props.styles?.toggleButton}
  ${props => (props?.hidden ? {display: 'none'} : void 0)}
`;

export const ToggleButtonIcon = Styled(Image)
  .attrs(props => ({
    source: props.styles?.toggleIcon || ExpandCollapseIcon,
  }))
  .withConfig({
    shouldForwardProp: prop => !['isOpen'].includes(prop),
  })`
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease;
  transform: rotate(${props => (props.isOpen ? 180 : 0)}deg);
  ${props => props.styles?.toggleIconStyle}
`;

export const ViewContainer = Styled(View)`
 flex:1;
 overflow:hidden;
${props => props.styles?.viewContainer}
`;

export const ItemContainer = Styled(TouchableOpacity).withConfig({
  shouldForwardProp: prop =>
    !['isSelected', 'expanded', 'isSubMenu'].includes(prop),
})`
${props => props.styles?.menuItemContainer}
${props =>
  props.isSelected ? props.styles?.selectedMenuItemContainer : void 0}
${props => (props.isSubMenu ? props?.styles?.sectionMenuItemContainer : void 0)}
${props => {
  if (!props.isSelected) {
    return `&:hover {
      background-color: ${
        props.styles?.menuItemContainerHoverColor || '#FCFCFC'
      };
      & ${MenuDot} {
        background-color: ${props.styles?.menuDotHoverColor || '#A3A3A3'};
      }
      & ${ItemLabel} {
        color: ${props.styles?.itemLabelHoverColor || '#A3A3A3'};
      }
      & ${ItemCountText} {
        color: ${props.styles?.hoverCountTextColor || '#A3A3A3'};
      }
    }`;
  }
}}
`;

export const ItemRow = Styled(Row)`
  gap: 8px;
  ${props => props.styles?.menuItemRowContainer}
`;

export const ItemIconContainer = Styled(View)`
  height:16px;
  width:16px;
  justify-content:center;
  align-items:center;
  ${props => props.styles?.menuItemIconContainer}
`;

export const ItemIcon = Styled(Image)`
  height:16px;
  width:16px;
  ${props => props.styles?.menuItemIcon}
`;

export const MenuDot = Styled(View)`
  height:6px;
  width:6px;
  border-radius:3px;
  background-color:#EDEDED;
  ${props => props.styles?.menuDot}
  ${props =>
    props.isSelected
      ? {backgroundColor: '#A3A3A3', ...props.styles?.selectedMenuDot}
      : void 0}
`;

export const ItemLabel = Styled(Text).withConfig({
  shouldForwardProp: prop => !['isSelected'].includes(prop),
})`
flex:1;
overflow:hidden;
${props => props.styles?.menuItemLabel}
${props => (props.isSelected ? props.styles?.selectedMenuItemLabel : void 0)}
`;

export const GroupContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['index', 'isSubMenu'].includes(prop),
})`
  gap: 2px;
  padding-top: 2px;
  ${props =>
    props.index === undefined || props.index > 0
      ? props.styles?.groupContainer
      : void 0}
`;

export const TopGroupContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['index', 'isSubMenu'].includes(prop),
})`
  gap: 2px;
  ${props => props.styles?.topGroupContainer}
`;

export const SectionContainer = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['index', 'isSelected', 'expanded'].includes(prop),
})`
  gap: 2px;
  ${props => props.styles?.sectionContainer}
  ${props =>
    !props?.expandable && (props.index === undefined || props.index > 0)
      ? {marginTop: 10}
      : void 0}
  ${props =>
    props.isSelected && !props.expanded
      ? props.styles?.selectedSectionContainer
      : void 0}
`;

export const SectionItemContainer = Styled(Row).withConfig({
  shouldForwardProp: prop => !['isSelected', 'expanded'].includes(prop),
})`
  gap: 8px;
  ${props => props.styles?.sectionItemContainer}
  ${props =>
    props.isSelected && !props.expanded
      ? props.styles?.selectedSectionItemContainer
      : void 0}
  ${props => {
    if (!props.isSelected && !props.expanded) {
      return `&:hover {
            background-color:${
              props.styles?.sectionItemContainerHoverColor || '#FCFCFC'
            };
            & ${SectionItemLabel} {
              color: ${props.styles?.sectionItemLabelHoverColor || '#A3A3A3'};
            }
          }`;
    }
  }}
`;

export const SectionItemLabel = Styled(Text)`
  flex: 1;
  ${props => props.styles?.sectionItemLabel}
  ${props =>
    props.isSelected && !props.expanded
      ? props.styles?.selectedSectionItemLabel
      : void 0}
`;

export const ItemCountContainer = Styled(Text)`
  ${props => props.styles?.countContainer}
`;

export const ItemCountText = Styled(Text)`
  ${props => props.styles?.countText}
  ${props => (props.isSelected ? props.styles?.selectedCountText : void 0)}
`;

export const EmptyMenuContainer = Styled(View)`
  ${props => props.styles?.menuItemContainer}
  ${props => props.styles?.groupContainer}
  flex:1;
  overflow:hidden;
  border-width:0px;
`;
