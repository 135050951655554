import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Row, Text} from '@unthinkable/react-core-components';
import {LineSeparator} from '@unthinkable/react-layout';
import {UserAvatarGroup} from '../../../components/avatar/UserAvatar';
import {MoreActions} from '../../../components/moreAction/MoreAction';

export const InmailCampaignHeader = props => {
  return (
    <NavHeader
      title="Inmail Campaign"
      actions={[
        <AddButton
          title="Inmail Campaign"
          view="add-inmailcampaign"
          params={props?.route?.params}
        />,
        <MoreActions
          actions={[
            {
              title: 'Import Inmail Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'linkedin prospects',
                  model: 'PotentialCustomer',
                });
              },
            },
            {
              title: 'Import Inmail Responses',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'linkedin responses',
                  model: 'PotentialCustomer',
                });
              },
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

const RendersAcceptedPercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.accepted_percentage
          ? row?.accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.accepted_percentage || row?.overall_accepted_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_accepted_percentage
          ? row?.overall_accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const RendersPositivePercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.positive_result_percentage
          ? row?.positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.positive_result_percentage ||
      row?.overall_positive_result_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_positive_result_percentage
          ? row?.overall_positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const LinkedInCampaignOwner = ({row}) => {
  const owners = [];
  if (row.profile_owner) {
    owners.push(row.profile_owner);
  }
  if (row.assigned_to) {
    owners.push(row.assigned_to);
  }
  return <UserAvatarGroup value={owners} />;
};

export const InmailCampaignTableBeta = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    searchValue,
    filter,
  } = props;
  params = {...params, ...filterParams};
  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64d0fd55ab78dfead48c144b',

      label: row?.name,
    });
  };
  const {
    acceptedProspects,
    requestSentProspects,
    inmailMessages,
    uqls,
    preuqls,
    qualifiedLeads,
    prequalifiedLeads,
    CampaignProspectOnClickMessageSend,
    CampaignProspectOnClickPostive,
    editInmailCampaign,
    prospectsCountForCampaign,
    CampaignProspectOnClickLifecycleCompleted,
    CampaignProspectOnClickQLInCycle,
    CampaignProspectOnClickLeadLifecycle,
    connectionCycleCompleted,
    onPressLeadsInCycle,
  } = leadControllers({
    ...props,
    params: {...params, fromInmailCampaign: true},
  });
  return (
    <Table
      eventSourceId={['outreachAdded', 'inmailCampaignAdded', 'Task']}
      variant={'bordered'}
      api={`/inmail-campaign`}
      params={params}
      addOnFilter={filter}
      limit={5000}
      search={searchValue}
      searchFields={'name'}
      onRowPress={inmailMessages}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
          width: 250,
          // responsive: 'sm',
        },
        {
          width: 80,
          header: 'Owner',
          field: 'assigned_to',
          type: 'userAvatar',
        },
        {
          width: 120,
          header: 'Profile',
          field: 'assigned_to',
          type: 'userAvatar',
        },
        // {
        //   render: CountCellRender,
        //   width: 120,
        //   header: 'Outreach Contents',
        //   align: 'right',
        //   count_field: 'campaign_message_count',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`add-outreach-content`, {
        //       ...params,
        //       row,
        //       campaign_type: '64d0fd55ab78dfead48c144b',
        //     });
        //   },
        //   onPress: inmailMessages,
        // },
        // {
        //   width: 100,
        //   type: 'number',
        //   field: 'prospect_count',
        //   header: 'Prospect',
        //   aggregate: true,
        //   onPress: prospectsCountForCampaign,
        // },
        // {
        //   type: 'number',
        //   field: 'request_send_count',
        //   header: 'Requests',
        //   aggregate: true,
        //   onPress: requestSentProspects,
        // },
        {
          header: 'Connection Cycle',
          children: [
            {
              type: 'number',
              field: 'connection_duration_completed',
              header: 'Request',
              aggregate: true,
              onPress: connectionCycleCompleted,
              // onPress: requestSentProspects,
            },
            {
              type: 'number',
              header: 'Accepted %',
              field: 'new_accepted_percentage',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              onPress: acceptedProspects,
              // render: RendersAcceptedPercentage,
            },
          ],
        },

        // {
        //   type: 'number',
        //   header: 'Connections In  cycle',
        //   field: 'connections_in_cycle',
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   // render: RendersAcceptedPercentage,
        //   onPress: acceptedProspects,
        //   aggregate: true,
        // },
        {
          header: 'Prospect To Lead Cycle',
          children: [
            {
              type: 'number',
              field: 'lifecycle_completed',
              header: 'Leads',
              onPress: CampaignProspectOnClickLifecycleCompleted,
              aggregate: true,
            },
            {
              type: 'number',
              field: 'message_send_count',
              header: 'Message Sent',
              aggregate: true,
              onPress: CampaignProspectOnClickMessageSend,
              width:120,
            },
            {
              type: 'number',
              header: 'Conversion %',
              field: 'new_positive_result_percentage',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              onPress: onPressLeadsInCycle,
              // render: RendersPositivePercentage,
              // onPress: CampaignProspectOnClickPostive,
              width: 110,
            },
          ],
        },

        // {
        //   type: 'number',
        //   field: 'uql_in_cycle',
        //   header: 'Leads in cycle',
        //   onPress: onPressLeadsInCycle,
        //   aggregate: true,
        // },
        {
          header: 'Lead To QL cycle',
          children: [
            {
              type: 'number',
              field: 'expected_ql_count',
              header: 'Leads',
              width: 110,
              aggregate: true,
              onPress: CampaignProspectOnClickLeadLifecycle,
            },
            {
              type: 'number',
              field: 'ql_conversion_rate',
              header: 'Conversion %',
              width: 110,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              onPress: CampaignProspectOnClickQLInCycle,
            },
          ],
        },

        // {
        //   type: 'number',
        //   field: 'leads_in_cycle',
        //   header: 'QL in Cycle',
        //   width: 110,
        //   aggregate: true,
        //   onPress: CampaignProspectOnClickQLInCycle,
        // },

        // {
        //   type: 'number',
        //   field: 'lead_count',
        //   compareField: 'previous_lead_count',
        //   header: 'Lead',
        //   // aggregate: {
        //   //   render: AggregateComparisonRender,
        //   // },
        //   aggregate: true,
        //   onPress: uqls,
        //   onComparePress: preuqls,
        //   // render: ComparisonRender,
        //   width: 110,
        // },
        // {
        //   type: 'number',
        //   field: 'ql_count',
        //   compareField: 'previous_ql_count',
        //   header: 'QL',
        //   // aggregate: {
        //   //   render: AggregateComparisonRender,
        //   // },
        //   aggregate: true,
        //   onPress: qualifiedLeads,
        //   onComparePress: prequalifiedLeads,
        //   // render: ComparisonRender,
        //   width: 110,
        // },
      ]}
      moreActions={[
        {title: 'Edit', onPress: editInmailCampaign},
        {
          title: 'Show Tasks',
          onPress: showTasks,
        },
      ]}
    />
  );
};
