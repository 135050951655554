import React from 'react';
import {Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ChangeModuleForm = props => {
  const {
    route: {params},
  } = props;

  const {row, api, project, module, secondaryTitle} = params;
  const {feature, module_id} = row || {};

  const fonts = useTheme('fonts');
  const colors = useTheme('colors');

  const {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: [
      'Feature',
      'Module',
      'Task',
      'View',
      'DataService',
      'Controller',
      'Model',
      'ThirdPartyIntegration',
    ],
    ...props,
  });

  return (
    <Form
      defaultValues={{_id: row?._id}}
      header={{
        title: `${
          module_id?.module
            ? `Update Module (${module_id.module})`
            : 'Update Module'
        }`,
        secondaryTitle,
      }}
      onSubmit={onSubmit}
      submitAction="Update"
      layoutFields={[
        {
          label: 'Module',
          field: 'module_id',
          type: 'autoComplete',
          api: `/projectmodules`,
          suggestionField: 'module',
          valueField: 'module',
          filter: {
            project_id: project?._id,
            _id: {$nin: [module_id]},
            status: {$ne: 'archived'},
          },
          required: true,
        },
        {
          render: () => {
            return (
              <Text style={{...fonts.CAPTION, color: colors.INFORMATION_HIGH}}>
                NOTE: All the assets in this item will now belong to the new
                module.
              </Text>
            );
          },
          visible: !!feature,
        },
      ]}
      {...props}
    />
  );
};
