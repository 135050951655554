import React from 'react';
import {SplitScreen, useSplitScreen} from '../../../../components/SplitScreen';
import {Table} from '../../../../components/table/Table';
import {EditModelViewForm} from './ModelViewForm';
import {useInvoke} from '../../../../controllers/useInvoke';

const ModelViewTable = props => {
  const {
    route: {params},
    isRowActive,
  } = props;

  const {dataEntity} = params;
  const {toggleSplitScreen} = useSplitScreen();

  const markDelete = useInvoke({
    method: 'delete',
    eventSourceId: 'ProjectModelView',
  });

  return (
    <Table
      isRowActive={isRowActive}
      api={'/projectmodelviews'}
      eventSourceId={'ProjectModelView'}
      filter={{
        model_id: dataEntity?._id,
      }}
      onRowPress={({row, rowIndex}) => {
        toggleSplitScreen({
          ...params,
          row,
          rowIndex,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'path',
          header: 'Path',
        },
        {
          type: 'text',
          field: 'query',
          header: 'Query',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete this view?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            markDelete({
              uri: `/projectmodelviews/${row._id}`,
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const ModelViewScreen = props => {
  return (
    <SplitScreen splitScreen={<EditModelViewForm {...props} />}>
      <ModelViewTable {...props} />
    </SplitScreen>
  );
};
