import React, {useEffect, useState} from 'react';
import {plural} from 'pluralize';
import {useStyles} from '@unthinkable/react-theme';
import {Col, Image, ScrollView, View} from '@unthinkable/react-core-components';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useAppStateContext} from '../../../providers/AppState';
import {useFetchData} from '../../../controllers/useFetchData';
import {NotificationCard} from '../component/NotificationCard';
import {TableHeader} from '../../../components/table/Headers';
import {AllNotificationStyles} from '../component/theme';
import {useInvoke} from '../../../controllers/useInvoke';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {ActionText} from '../../../components/action/ActionText';
import {
  PROJECT_COMMENT_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {
  FIELDS,
  NAVIGATE_TO,
  POPULATE,
  getParams,
} from '../../common/constants/ScreenConstants';

export const AllNotifications = props => {
  const {
    route: {params},
    navigation,
  } = props;

  let {user, fetch} = useAppStateContext();
  const {notify, listen, unlisten} = useEventHandler();
  const [loading, setLoading] = useState({id: null, value: false});

  const {noDataIcon, container, headerContainer, bodyContainer} = useStyles(
    AllNotificationStyles,
  );

  const [notificationCount, setNotificationCount] = useState(
    params?.notificationCount,
  );

  const readInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'notificationCount',
  });

  const readAllInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'notificationCount',
  });

  const markNotificationRead = async () => {
    await readAllInvoke({
      uri: `/markNotificationRead`,
      props: {
        filter: {user: user?._id, read_on: {$exists: false}},
      },
    });
    notify('notificationMarkedRead', {_id: 'markNotificationRead'});
  };

  const fetchNotificationCount = async () => {
    const result = await fetch({
      uri: '/notificationcount',
      props: {
        filter: {user: user?._id, read_on: {$exists: false}},
        count: true,
      },
    });
    setNotificationCount(result?.data?.count);
  };

  useEffect(() => {
    listen('notificationCountUpdated', fetchNotificationCount);
    listen('notificationMarkedRead', fetchNotificationCount);

    return () => {
      unlisten('notificationCountUpdated', fetchNotificationCount);
      unlisten('notificationMarkedRead', fetchNotificationCount);
    };
  }, [listen]);

  const {data = [], loading: dataLoading} = useFetchData({
    eventSourceId: ['notificationCount'],
    api: `/myNotifications`,
    filter: {user: user?._id},
    sort: {createdAt: -1},
    fields: {
      read_on: 1,
      source: {
        label: 1,
        color: 1,
      },
      source_id: {
        name: 1,
        source: 1,
        source_id: {
          name: 1,
        },
        project_id: 1,
        module_id: 1,
        type: 1,
      },
      payload: 1,
      createdAt: 1,
      createdBy: {
        name: 1,
        color: 1,
      },
    },
  });

  return (
    <View style={container}>
      <View style={headerContainer}>
        <TableHeader
          closeable
          title={
            notificationCount
              ? `Notifications (${notificationCount})`
              : 'Notifications'
          }
          actions={[
            data?.length ? (
              <ActionText
                onPress={markNotificationRead}
                text={'Mark all read'}
              />
            ) : (
              void 0
            ),
          ]}
        />
      </View>
      <View style={bodyContainer}>
        {dataLoading ? (
          <View
            style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <ActivityIndicator />
          </View>
        ) : data?.length ? (
          <ScrollView>
            <Col gap={6}>
              {data?.map(_data => {
                return (
                  <NotificationCard
                    data={_data}
                    loading={_data?._id === loading?.id && loading?.value}
                    onPress={async () => {
                      const {source, source_id} = _data;
                      readInvoke({
                        uri: `/usernotifications/${_data?._id}`,
                        props: {
                          read_on: new Date(),
                        },
                      });
                      notify('notificationMarkedRead', {
                        _id: 'markNotificationRead',
                      });
                      if (
                        source?._id === PROJECT_COMMENT_TYPE_ID &&
                        source_id?.source?._id === PROJECT_TASK_TYPE_ID
                      ) {
                        navigation.pop();
                        navigation.navigate('task-edit', {
                          row: {_id: source_id?.source_id?._id},
                        });
                      } else {
                        const api = `/${plural(source?.name).toLowerCase()}`;

                        try {
                          if (NAVIGATE_TO[source?._id]) {
                            setLoading(prevVal => ({
                              ...prevVal,
                              id: _data?._id,
                              value: true,
                            }));

                            const {data} = await fetch({
                              uri: api,
                              props: {
                                fields: FIELDS[source?._id],
                                filter: {_id: source_id?._id},
                                populate: POPULATE[source?._id],
                                only: true,
                              },
                            });

                            setLoading(prevVal => ({
                              ...prevVal,
                              id: null,
                              value: false,
                            }));
                            const params = getParams({source, source_id, data});

                            if (params) {
                              navigation.pop();
                              navigation.navigate(
                                `${NAVIGATE_TO[source?._id]}`,
                                params,
                              );
                            }
                          }
                        } catch (err) {
                          console.log('error caught in new function = ', err);
                        }
                      }
                    }}
                  />
                );
              })}
            </Col>
          </ScrollView>
        ) : (
          <View
            style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <Image source={noDataIcon} />
          </View>
        )}
      </View>
    </View>
  );
};
