import React from 'react';
import {
  Text,
  Row,
  TouchableOpacity,
  Col,
  Image,
  View,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';

const CountCellRender = ({
  count_field,
  released_count_field,
  row,
  onPlusPress,
  isNestedObject,
  styleForCountField,
  sub_count_field,
}) => {
  let releasedCountValue;

  if (typeof released_count_field === 'function') {
    releasedCountValue = released_count_field({values: row});
  } else {
    releasedCountValue = row[released_count_field];
  }
  let {
    columnTextStyle,
    addPlus,
    iconStyle,
    iconContainerStyle,
    rowStyle,
    containerStyle,
    countFieldStyle,
    countFieldContainer,
    columnFieldContainer,
    successBackgroundStyle,
    successTextStyle,
    subCountStyle,
  } = useComponentTheme('CountCellRenderStyles');

  let countValue;
  let subCountValue;
  // updated logic > count_field can have nested data in the form key1.key2.key3
  if (isNestedObject) {
    let fields = count_field.split('.');
    // let sub_fields = sub_count_field && sub_count_field.split('.');
    let value = row;
    // let sub_value = row;
    for (let i = 0; i < fields.length; i++) {
      value = value?.[fields?.[i]];
    }
    // for (let i = 0; i < sub_fields?.length; i++) {
    //   sub_value = sub_value?.[fields?.[i]];
    // }
    countValue = value;
    // subCountValue = sub_value;
  } else {
    countValue = row[count_field];
    subCountValue = row[sub_count_field];
  }

  if (
    countValue != 0 &&
    releasedCountValue != 0 &&
    countValue === releasedCountValue
  ) {
    containerStyle = {
      ...containerStyle,
      backgroundColor: successBackgroundStyle,
    };
  }

  if (
    countValue != 0 &&
    releasedCountValue != 0 &&
    countValue === releasedCountValue
  ) {
    columnTextStyle = {
      ...columnTextStyle,
      color: successTextStyle,
    };
  }
  return (
    <Row style={rowStyle}>
      {released_count_field && count_field ? (
        countValue != 0 &&
        releasedCountValue != 0 &&
        countValue === releasedCountValue ? (
          <Row style={containerStyle}>
            <Text style={columnTextStyle}>{countValue}</Text>
          </Row>
        ) : (
          <Row style={containerStyle}>
            <Text style={columnTextStyle}>{releasedCountValue}</Text>
            <Text style={columnTextStyle}>/</Text>
            <Text style={columnTextStyle}>{countValue}</Text>
          </Row>
        )
      ) : count_field ? (
        subCountValue && subCountValue != 0 ? (
          <Col>
            <View
              style={
                styleForCountField ? countFieldContainer : columnFieldContainer
              }>
              <Text
                style={styleForCountField ? countFieldStyle : columnTextStyle}>
                {countValue}
              </Text>
            </View>
            <Text style={subCountStyle}>{subCountValue}</Text>
          </Col>
        ) : (
          <View
            style={
              styleForCountField ? countFieldContainer : columnFieldContainer
            }>
            <Text
              style={styleForCountField ? countFieldStyle : columnTextStyle}>
              {countValue}
            </Text>
          </View>
        )
      ) : null}
      {onPlusPress ? (
        <TouchableOpacity
          onPress={() => {
            onPlusPress({row});
          }}>
          <Col gap={2} style={iconContainerStyle}>
            <Image source={addPlus} style={iconStyle} />
          </Col>
        </TouchableOpacity>
      ) : null}
    </Row>
  );
};

export default CountCellRender;
