import React, {useState} from 'react';
import {ConfirmPopup} from '@unthinkable/react-confirm';
import {useToast} from '@unthinkable/react-toast';

export const FormErrorConfirmWrapper = ({children, confirm, onError}) => {
  const [confirmInfo, setConfirmInfo] = useState();

  const toast = useToast();

  const onSubmitError = (err, formContext) => {
    if (onError) {
      let confirmInfo = onError(err);
      if (confirmInfo) {
        setConfirmInfo({
          ...confirmInfo,
          formContext,
        });
      } else {
        toast({message: err.message, type: 'Error'});
      }
    }
  };

  const {updates, formContext} = confirmInfo || {};

  return (
    <>
      <ConfirmPopup
        {...confirm}
        {...confirmInfo?.confirm}
        onConfirm={async () => {
          for (let key in updates) {
            formContext?.setFieldValue(key, updates[key]);
          }
          setTimeout(_ => {
            formContext?.handleSubmit?.();
          });
        }}
        onHide={() => {
          setConfirmInfo();
        }}
        isVisible={confirmInfo}
      />
      {React.cloneElement(children, {onSubmitError})}
    </>
  );
};
