import React from 'react';
import {useTaskSourceDynamicAddForm} from '../../department/controllers';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateTaskOutputForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId:"Task",
  });

  const addForm = useTaskSourceDynamicAddForm({...props});
  return (
    <Form
      mode="edit"
      api={'/projectTasks/'+row?._id}
      fields={
        {
          source:{
            label:1,
            add_view:1,
            name:1
          },
          source_id:{
            name:1
          }
        }
      }
      onSubmit={onSubmit}
      header={'Align Work'}
      submitAction={'Align'}
      layoutFields={[
        {
          label: 'Source',
          placeholder: 'Source',
          field: 'source',
          type: 'autoComplete',
          api: '/types',
          suggestionField: 'label',
          valueField: 'label',
        },
        {
          label: 'Source Value',
          placeholder: 'Source Id',
          field: 'source_id',
          type: 'autoComplete',
          api: '/sourceSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          params: ({values}) => {
            const {source} = values || {};
            return {
              source,
            };
          },
          onCreate: addForm,
        },
      ]}
    />
  );
};
