import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import {
    DropInterviewTable,
    LostInterviewTable,
    PlannedInterviewTable,
    UnPlannedInterviewTable,
    WonInterviewTable,
} from './InterviewTable';

export const InterviewTabView = props => {
  let {route: {params} = {}} = props;
  const {searchValue, applyFilter, filterValues} = useFilter({});
  const {product, period, ...rest} = params;
  const {filter, params: filterParams = {}} = filterValues;
  params = {
    ...filterParams,
    ...rest,
    product,
  };

  let tabs = {
    unplanned: {
      label: 'Unplanned',
      view: <UnPlannedInterviewTable filter={filter} />,
      api: `/interviewresources/all`,
      addOnFilter: {
        interview_date: {$exists: false},
        outcome: {$eq: null},
        status: 'Active',
      },
    },
    planned: {
      label: 'Planned',
      view: <PlannedInterviewTable filter={filter} />,
      api: `/interviewresources/all`,
      addOnFilter: {
        interview_date: {$exists: true},
        outcome: {$eq: null},
        status: 'Active',
      },
    },
    won: {
      label: 'Won',
      view: <WonInterviewTable filter={filter} />,
      api: `/interviewresources/all`,
      addOnFilter: {
        outcome: 'Selected',
      },
    },
    lost: {
      label: 'Lost',
      view: <LostInterviewTable filter={filter} />,
      api: `/interviewresources/all`,
      addOnFilter: {
        outcome: 'Rejected',
      },
    },
    drop: {
      label: 'Drop',
      view: <DropInterviewTable filter={filter} />,
      api: `/interviewresources/all`,
      addOnFilter: {
        outcome: 'Drop',
      },
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Resource',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'resource',
            },
            {
              type: 'autoComplete',
              label: 'Trainer',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'mentor',
            },
          ]}
        />,
      ]}
    />
  );
};
