import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {randomColor} from '../../../utils/util';
import {Text} from '@unthinkable/react-core-components';

const ProjectWiseAllocations = props => {
  let {
    filterParams = {},
    route: {params},
    navigation,
    searchValue,
    period,
  } = props;

  return (
    <Table
      api={'/projectAllocations'}
      eventSourceId={'allocatedResource'}
      searchFields="project"
      search={searchValue}
      limit={2000}
      addOnFilter={{...filterParams}}
      columns={[
        {
          header: 'Project',
          field: 'project',
          type: 'colorTag',
          colorField: randomColor,
          minWidth: 280,
        },
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          colorField: randomColor,
          width: 220,
        },
        {
          header: 'Type',
          field: 'projecttype',
          render: ({row, styles: {rowText = {}}}) => {
            let projectType = '';
            if (row?.new_project_type == 'staffaugmentation') {
              projectType = 'Staff Augmentation';
            }
            if (row?.new_project_type == 'managedteam') {
              projectType = 'Managed Team';
            }
            if (row?.new_project_type == 'sdlc') {
              projectType = 'SDLC';
            }
            if (row?.new_project_type == 'sdlcplus') {
              projectType = 'SDLC+';
            }
            return <Text style={{...rowText}}>{projectType}</Text>;
          },
        },
        {
          header: 'CSM/PM',
          field: 'owner_id',
          type: 'userAvatarChip',
        },
        {
          render: CountCellRender,
          count_field: 'allocations',
          header: 'Allocations',
          align: 'right',
          onPress: ({row}) => {
            const employeeIds = row?.allocations_data?.map(
              allocation => allocation?.employee,
            );
            navigation.navigate('project-allocations-tab', {
              addOnFilter: {
                project_id: row?._id,
                employee: {$in: employeeIds},
              },
              navigationParams: {period},
            });
          },
        },
      ]}
      params={params}
    />
  );
};

export default ProjectWiseAllocations;
