import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateEmployeeTeamInAssignmentForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/mastertaskassignments',
    eventSourceId: 'MasterTaskAssignment',
  });

  return (
    <Form
      data={{_id: row?._id}}
      mode={'edit'}
      onSubmit={onSubmit}
      header={'Update Team'}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: '/employeeteams',
          filter: {project: null},
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};
