import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { UserAvatarWithNameCodeJoiningDate } from '../../reimbursement/components/ReimbursementColumnRenders';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';

const PromotionTab = props => {
    let { user = {}, route: { params = {} } = {}, tab, forUH, ...rest } = props || {};
    const { applyFilter, filterValues, searchValue, onChangeFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { filter, params: { period = {} } = {} } = filterValues || {};
    params = { ...params, user };
    let tabs = {};

    tabs['Promotion'] = {
        label: 'Promotion Detail',
        eventSourceId: 'Promotion',
        view: <EmployeePromotionTable
            searchValue={searchValue}
            filterValues={filterValues}
            addOnFilter={{
                ...filterValues.filter,
            }}
            api='/promotionDetails'
            user={user}
            {...rest}
        />,
        addOnFilter: {
            ...filter,
        },
        api: '/promotionDetails',
    }


    return <TabView
        tabs={tabs}
        // params={params}
        {...props}
        actions={[
            <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                    {
                        type: 'multiAutoComplete',
                        label: 'Designation',
                        api: '/designations',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        valueField: 'name',
                        field: 'designation',
                    },
                    {
                        type: 'multiAutoComplete',
                        label: 'Old Designation',
                        api: '/designations',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        valueField: 'name',
                        field: 'old_designation',
                    },
                    {
                        type: 'multiAutoComplete',
                        label: 'Department',
                        api: '/departments',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        valueField: 'name',
                        field: 'department',
                    },
                    {
                        type: 'multiAutoComplete',
                        label: 'Employee Level',
                        api: '/employeelevels',
                        placeholder: 'Select',
                        suggestionField: 'name',
                        valueField: 'name',
                        field: 'level',
                    },
                    {
                        type: "date",
                        label: 'Promotion Closed On',
                        field: 'promotion_closed_on'
                    }
                ]}
            />,
        ]}
    />;
};

export const EmployeePromotionTable = props => {
    let { navigation, route: { params } = {}, filter, addOnFilter, tab, api } = props;
    const { post, download } = useAppStateContext();
    const fetchProps = {
        params,
        addOnFilter: { ...filter, ...addOnFilter },
        api: '/promotionDetails',
    };
    return (
        <Table
            eventSourceId={['Promotion']}
            api='/promotionDetails'
            addOnFilter={{ ...filter, ...addOnFilter }}
            selection={{
                actions: [
                    {
                        title: 'Download',
                        onPress: props =>
                            download({
                                uri: '/exportExcelHelper',
                                props: {
                                    props: {
                                        selectedIds: props.selectedIds,
                                        fetchProps,
                                        column: {
                                            Name: "name",
                                            Code: 'employee_code',
                                            Experience: "experience",
                                            'Joining Date': 'joining_date',
                                            Department: 'department.name',
                                            'Old Designation': 'old_designation.name',
                                            'New Designation': 'designation.name',
                                            'Variable Perc': 'variable_perc',
                                            'Employee Level': 'employee_level.name',
                                            'Promotion Date': 'promotion_date',
                                            'Closed on': 'promotion_closed_on'
                                        },
                                    },
                                },
                            }),
                    },
                ],
            }}
            columns={[
                {
                    header: 'Employee',
                    render: ({ row }) => {
                        return (
                            <UserAvatarWithNameCodeJoiningDate
                                name={row?.name}
                                color={row?.color}
                                employee_code={row?.employee_code}
                                joining_date={row?.joining_date}
                                primarySubTitle={row?.experience}
                            />
                        );
                    },
                    minWidth: 260,
                },
                {
                    header: 'Department',
                    type: 'text',
                    field: 'department.name',
                    width: 200,
                },
                {
                    header: 'Old Designation',
                    type: 'text',
                    field: 'old_designation.name',
                    width: 200,
                },
                {
                    header: 'New Designation',
                    type: 'text',
                    field: 'designation.name',
                    width: 200,
                },
                {
                    header: 'Variable Perc',
                    type: 'text',
                    field: 'variable_perc',
                    width: 200,
                },
                {
                    header: 'Employee Level',
                    type: 'text',
                    field: 'employee_level.name',
                    width: 200,
                },
                {
                    header: 'Promotion Date',
                    type: 'date',
                    field: 'promotion_date',
                    width: 200,
                    formatOptions: { format: 'DD MMM YY' },
                },
                {
                    header: 'Closed on',
                    type: 'date',
                    field: 'promotion_closed_on',
                    width: 200,
                    formatOptions: { format: 'DD MMM YY' },
                },
            ]}
        />
    );
};


export default PromotionTab;