import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {useNavigation} from '@react-navigation/native';
import {ModalView as ModalViewComponent} from '@unthinkable/react-modal';
import {ModalViewStyles} from './theme';

const ModalView = props => {
  let styles = useStyles(ModalViewStyles);
  const navigation = useNavigation();
  const hideModal = () => {
    navigation.goBack();
  };

  return (
    <ModalViewComponent
      autoHide={false}
      closeable={true}
      hideModal={hideModal}
      {...props}
      styles={styles}
    />
  );
};

export default ModalView;
