import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const NomineeRelation = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    console.log("row>>>>>>",row)
    let { onSubmit } = useFormSubmit({
        uri: '/nomineerelations',
        eventSourceId: 'nomineerelation',
    });
    return (
        <Form
            api={`/nomineerelations/${row?._id}`}
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    label: 'Relation',
                    type: 'text',
                    field: 'relation',
                },
            ]}
            {...props}
        />
    );
};

export const AddNomineeRelation = props => {
    return <NomineeRelation header="Add Nominee Relation" {...props} />;
};

export const EditNomineeRelation = props => {
    return (
        <NomineeRelation
            mode="edit"
            header={'Update Nominee Relation'}
            {...props}
        />
    );
};
