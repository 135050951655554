import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';

export const PackageStructureTable = props => {
    const {
        route: { params },
        navigation,
    } = props;
    return (
        <Table
            eventSourceId={['packageStructure']}
            api={`/packagestructures`}
            renderHeader={() => {
                return (
                    <TableHeader
                        title="Package Structure"
                        actions={[
                            <AddButton
                                title="Add Package Structure"
                                view="add-package-structure"
                                params={params}
                            />,
                        ]}
                    />
                );
            }}
            onRowPress={props => {
                const { row } = props;
                navigation.navigate(`edit-package-structure`, {
                    row,
                });
            }}
            columns={[
                {
                    type: 'text',
                    header: 'Name',
                    field: 'name',
                },
                {
                    type: 'text',
                    header: 'Description',
                    field: 'salary_stipend_description',
                },
                {
                    type: 'number',
                    header: 'Stipend',
                    field: 'stipend',
                },
            ]}
        />
    );
};
