import React, {useEffect, useState} from 'react';
import {useToast} from '@unthinkable/react-toast';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
} from '../../reimbursement/components/ReimbursementColumnRenders';

import {AddButton} from '../../../components/button/AddButton';
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View,
  Linking,
} from '@unthinkable/react-core-components';
import {employeeController} from '../controllers/employeeController';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

const FNFFile = resourceUrl => ({
  header: 'FNF File',
  align: 'center',
  width: 100,
  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.fnf_file && row?.fnf_file != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.fnf_file);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
  width: 100,
});

export const FnfHrDraftList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    filterParams,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  params = {...params, ...filterParams};

  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, HoldNodues} = employeeController(props);
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Un-Paid',
      is_cancelled: {$in: [null, false]},
      fnf_file: {$exists: false},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };

  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Un-Paid',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // width: 230,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 200,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 250,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.expected_relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'FNF Start',
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
      ]}
      moreActions={() => [UpdateFnfRemark({navigation})]}
    />
  );
};

export const FnfToBeSharedList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Un-Paid',
      fnf_file: {$exists: true},
      is_cancelled: {$in: [null, false]},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Shared',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // minWidth: 220,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 160,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF Start',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        FNFFile(resourceUrl),
        // {
        //   header: 'FNF Shared',
        //   width: 120,
        //   field: 'fnf_provided_date',
        //   type: 'date',
        //   formatOptions: {
        //     format: 'DD MMM YY',
        //   },
        // },
      ]}
      moreActions={() => [
        {
          title: 'Download F&F Excel',
          onPress: ({row}) => {
            const employeeId = row?.employee?._id;
            download({
              uri: `/fnfReport`,
              props: {
                employeeId,
              },
            });
          },
          visible: () => tab == 'accounts',
        },
        {
          title: 'Download F&F PDF',
          onPress: ({row}) => {
            const employeeId = row?.employee?._id;
            download({
              uri: `/fnfReport`,
              props: {
                employeeId,
                callFor: 'fnfPDF',
              },
            });
          },
          visible: () => tab == 'accounts',
        },
        UpdateFnfRemark({navigation}),

        {
          title: 'Share FNF',
          confirm: {
            title: 'Share FNF',
            message: 'Mark this Fnf as shared?',
          },

          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/nodues/${row?._id}`,
              message: 'Fnf marked shared successfully',
              props: {
                fnf_status: 'Shared',
                fnf_provided_date: getZeroTimeDate(new Date()),
              },
            });
          },
          visible: () => tab == 'hr',
        },
        // {
        //   title: 'Reshare FNF',
        //   message: 'Fnf re-shared successfully',
        //   onPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`share-fnf`, {
        //       ...params,
        //       row,
        //     });
        //   },
        //   visible: () => tab == 'accounts',
        // },
        {
          title: 'Update file',
          message: 'Fnf re-shared successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-fnf-file`, {
              ...params,
              row,
            });
          },
          visible: () => tab == 'accounts',
        },
      ]}
    />
  );
};

export const FnfSharedWithList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Shared',
      recovery_pending: {$in: [null, false]},
      is_cancelled: {$in: [null, false]},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Shared',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 160,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF Start',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        FNFFile(resourceUrl),
        {
          header: 'FNF Shared',
          width: 120,
          field: 'fnf_provided_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Download F&F Excel',
          onPress: ({row}) => {
            const employeeId = row?.employee?._id;
            download({
              uri: `/fnfReport`,
              props: {
                employeeId,
              },
            });
          },
          visible: () => tab == 'accounts',
        },
        {
          title: 'Download F&F PDF',
          onPress: ({row}) => {
            const employeeId = row?.employee?._id;
            download({
              uri: `/fnfReport`,
              props: {
                employeeId,
                callFor: 'fnfPDF',
              },
            });
          },
          visible: () => tab == 'accounts',
        },
        UpdateFnfRemark({navigation}),
        // {
        //   title: 'Reshare FNF',
        //   message: 'Fnf re-shared successfully',
        //   onPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`share-fnf`, {
        //       ...params,
        //       row,
        //     });
        //   },
        //   visible: () => tab == 'accounts',
        // },
        {
          title: 'Update file',
          message: 'Fnf re-shared successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-fnf-file`, {
              ...params,
              row,
            });
          },
          visible: () => tab == 'accounts',
        },
        {
          title: 'Pay FNF',
          message: 'Payment pending marked successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`payment-pending-fnf`, {
              ...params,
              row,
            });
          },
        },
        {
          title: 'Mark recovery pending',
          confirm: {
            title: 'Mark pending',
            message: 'Do you want to mark this as recovery pending?',
          },
          visible: () => tab == 'hr',

          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/nodues/${row?._id}`,
              message: 'Mark recovery pending successfully',
              props: {
                recovery_pending: true,
                fnf_payment_status: 'Pending',
                recovery_pending_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },
      ]}
    />
  );
};

export const FnfRecoveryPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Shared',
      recovery_pending: true,
      is_cancelled: {$in: [null, false]},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Shared',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF Start',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Payment status',
          field: 'fnf_payment_status',
          type: 'colorTag',
          width: 150,
        },
        FNFFile(resourceUrl),
      ]}
      moreActions={() => [
        {
          title: 'Upload payment details',
          message: 'Details uploaded successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`upload-fnf-recovery-details`, {
              ...params,
              row,
            });
          },
          visible: ({row}) =>
            tab == 'hr' && row?.fnf_payment_status == 'Pending',
        },
        {
          title: 'View payment details',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`view-fnf-recovery-details`, {
              ...params,
              row,
              from: 'accounts',
            });
          },
          visible: ({row}) =>
            tab == 'accounts' &&
            row?.fnf_payment_status == 'Payment done by employee',
        },
        {
          title: 'Confirm payment',
          confirm: {
            title: 'Confirm payment',
            message: 'Do you want to confirm the payment?',
          },
          visible: ({row}) =>
            tab == 'accounts' &&
            row?.fnf_payment_status == 'Payment done by employee',

          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/nodues/${row?._id}`,
              message: 'Payment confirmed successfully',
              props: {
                fnf_payment_status: 'Confirmed by finance',
                fnf_payment_confirmed_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },
        {
          title: 'FNF Paid',
          message: 'Fnf paid successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`paid-fnf`, {
              ...params,
              row,
            });
          },
          visible: ({row}) =>
            tab == 'hr' && row?.fnf_payment_status == 'Confirmed by finance',
        },
        {
          title: 'Close FNF',
          message: 'Fnf closed successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`paid-fnf-overdue`, {
              ...params,
              row,
            });
          },
          visible: ({row}) => tab == 'hr',
        },
      ]}
    />
  );
};

export const FnfOverdueNewList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/overdue',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Shared',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // minWidth: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF Start',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        // {
        //   header: 'Payment status',
        //   field: 'fnf_payment_status',
        //   type: 'colorTag',
        //   width: 150
        // },
        FNFFile(resourceUrl),
      ]}
      moreActions={() => [
        {
          title: 'Upload payment details',
          message: 'Details uploaded successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`upload-fnf-recovery-details`, {
              ...params,
              row,
            });
          },
          visible: ({row}) =>
            tab == 'hr' && row?.fnf_payment_status == 'Pending',
        },
        {
          title: 'View payment details',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`view-fnf-recovery-details`, {
              ...params,
              row,
              from: 'accounts',
            });
          },
          visible: ({row}) =>
            tab == 'accounts' &&
            row?.fnf_payment_status == 'Payment done by employee',
        },
        {
          title: 'Confirm payment',
          confirm: {
            title: 'Confirm payment',
            message: 'Do you want to confirm the payment?',
          },
          visible: ({row}) =>
            tab == 'accounts' &&
            row?.fnf_payment_status == 'Payment done by employee',

          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/nodues/${row?._id}`,
              message: 'Payment confirmed successfully',
              props: {
                fnf_payment_status: 'Confirmed by finance',
                fnf_payment_confirmed_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },
        {
          title: 'FNF Paid',
          message: 'Fnf paid successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`paid-fnf`, {
              ...params,
              row,
            });
          },
          visible: ({row}) =>
            tab == 'hr' && row?.fnf_payment_status == 'Confirmed by finance',
        },
        {
          title: 'Close FNF',
          message: 'Fnf closed successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`paid-fnf-overdue`, {
              ...params,
              row,
            });
          },
          visible: ({row}) => tab == 'hr',
        },
      ]}
    />
  );
};

export const FnfAccountsDraftList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, ShareFnf} = employeeController(props);
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Un-Paid',
      fnf_file: {$exists: false},
      is_cancelled: {$in: [null, false]},
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };
  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Un-Paid',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // minWidth: 230,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 180,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF Start',
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Download F&F Excel',
          onPress: ({row}) => {
            const employeeId = row?.employee?._id;
            download({
              uri: `/fnfReport`,
              props: {
                employeeId,
              },
            });
          },
          visible: () => tab == 'accounts',
        },
        {
          title: 'Download F&F PDF',
          onPress: ({row}) => {
            const employeeId = row?.employee?._id;
            download({
              uri: `/fnfReport`,
              props: {
                employeeId,
                callFor: 'fnfPDF',
              },
            });
          },
          visible: () => tab == 'accounts',
        },
        UpdateFnfRemark({navigation}),
        // ShareFnf({navigation}),
        {
          title: 'Add file',
          message: 'File added successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`add-fnf-file`, {
              ...params,
              row,
            });
          },
          visible: () => tab == 'accounts',
        },
      ]}
    />
  );
};

export const FnfPaymentPendingList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const {UpdateFnfRemark, HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Payment Pending',
      is_cancelled: {$in: [null, false]},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };

  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Payment Pending',
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'Resign',
          type: 'date',
          field: 'employee.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF Start',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        FNFFile(resourceUrl),
        {
          header: 'Acceptance',
          type: 'date',
          field: 'payment_acceptance_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'FNF Paid',
          message: 'Fnf paid successfully',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`paid-fnf`, {
              ...params,
              row,
            });
          },
          visible: () => tab == 'accounts',
        },
      ]}
    />
  );
};

const FnfClosed = props => {
  const {row} = props;
  return (
    <PrimaryColumnCard
      primaryTitle={moment(row?.payment_date)?.format('DD MMM, YY')}
      items={[
        {
          value: row?.fnf_tat,
        },
        // {
        //   value: moment(row?.creation_date)?.format('DD MMM, YY'),
        // },
      ]}
    />
  );
};

export const FnfPaymentClosedList = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
    ...filterParams,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {UpdateFnfRemark, HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      fnf_status: 'Paid',
      isOverdue: {$in: [null, false]},
      is_cancelled: {$in: [null, false]},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };
  const listProps = {
    params,
    // search,
    addOnFilter: {
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/closed',
  };

  //clearHRNoDues
  return (
    <GroupTable
      {...props}
      {...listProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   fnf_status: 'Paid',
      // }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} processed`} />
            </RowWithSeparator>
          );
        },
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          // minWidth: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          // width: 120,
        },
        {
          header: 'Reporting',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          width: 220,
        },
        {
          header: 'FNF Start',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Exit',
          type: 'date',
          field: 'employee.relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF closed',
          render: FnfClosed,
          width: 120,
          // type: 'date',
          // field: 'payment_date',
          // formatOptions: {
          //   format: 'DD MMM YY',
          // },
        },
        FNFFile(resourceUrl),
      ]}
      moreActions={() => [UpdateFnfRemark({navigation})]}
    />
  );
};

export const FnfOverdueList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    tab,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  const {HoldNodues, ReShareFnf} = employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['NoduesHr'],
  });
  const {resourceUrl, download} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    addOnFilter: {
      isOverdue: true,
      is_cancelled: {$in: [null, false]},
      recovery_pending: {$in: [null, false]},
      ...addOnFilter,
    },
    searchFields: ['name'],
    api: '/fnf/hr',
  };

  //clearHRNoDues
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}
      eventSourceId={['NoduesHr']}
      // searchFields={['name']}
      // api={'/fnf/hr'} //query on backend
      // filter={{
      //   isOverdue: true,
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      'Fnf date': 'fnf_date',
                      Email: 'employee.official_email_id',
                      name: 'employee.name',
                      Designation: 'designation',
                      Product: 'product',
                      'Reporting manager': 'reportingTo',
                      'Joining date': 'employee.joining_date',
                      'Resign date': 'employee.resign_date_new',
                      'Relieving date': 'employee.relieving_date',
                      // 'FNF Date': 'fnf_date',
                      'FNF Provided Date': 'fnf_provided_date',
                      Reason: 'fnf_reason',
                      'Final Status': 'fnf_status',
                      'Payment Date': 'payment_date',
                      'Closing Date': 'nodue_closing_date',
                      Status: 'status',
                    },
                  }, // api={`/pending-all-invoices`}
                  // search={searchValue}
                  // addOnFilter={{account_owner_id: employeeId, ...addOnFilter}}
                  // searchFields="delivery_number"
                  // params={params}
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'FNF begin',
          width: 120,
          field: 'fnf_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                // joining_date={row?.employee?.joining_date}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 120,
        },
        {
          header: 'Reporting',
          align: 'center',
          render: ({row}) => {
            return <UserAvatarChip value={row?.employee.reporting_to[0]} />;
          },
          // width: 100,
        },
        {
          header: 'Relieving',
          type: 'date',
          field: 'employee.expected_relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'FNF closed',
          type: 'date',
          field: 'fnf_close_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        FNFFile(resourceUrl),
      ]}
      // moreActions={() => [
      //   {
      //     title: 'FNF Paid',
      //     message: 'Fnf paid successfully',
      //     onPress: props => {
      //       const {row} = props;
      //       navigation.navigate(`paid-fnf-overdue`, {
      //         ...params,
      //         row,
      //       });
      //     },
      //     visible: () => tab == 'hr',
      //   },
      // ]}
    />
  );
};
