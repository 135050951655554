import React from 'react';
import {Table} from '../../../components/table/Table';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';

const ProjectResourceWorkingDataForOrders = props => {
  let {
    route: {params = {}},
    navigation,
  } = props || {};
  const {row = {}} = params;
  const {filter, onChangeFilter, searchValue} = useFilter();
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Project Resource Working"
          actions={
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search Delivery No."
            />
          }
        />
      )}
      api={`/projectresourceworkingdata`}
      eventSourceId={['t&m', 'fixed', 'projectResourceWorking']}
      addOnFilter={{...filter, order: row?._id, status: {$ne: 'Cancelled'}}}
      searchFields="delivery_number"
      search={searchValue}
      fields={{
        _id: 1,
        delivery_number: 1,
        billing_hrs: 1,
        rate: 1,
        amount: 1,
        description: 1,
        start_date: 1,
        due_date: 1,
        invoice_due_date: 1,
        advance_due_date: 1,
        delivery_owner_id: {name: 1, color: 1},
        variable_potential: 1,
        status: 1,
      }}
      columns={[
        {
          field: 'delivery_number',
          header: 'Delivery No.',
          width: 140,
        },
        {
          field: 'billing_hrs',
          header: 'Est. Hrs',
          type: 'number',
        },
        {
          field: 'rate',
          header: 'Rate',
          type: 'currency',
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 100,
        },
        {
          field: 'description',
          header: 'Description',
          width: 180,
        },
        {
          field: 'start_date',
          header: 'Start Date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          field: 'due_date',
          header: 'End Date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          field: 'invoice_due_date',
          header: 'Inv. Due',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          field: 'advance_due_date',
          header: 'Adv. Due',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          field: 'delivery_owner_id',
          header: 'Delivery Owner',
          type: 'userAvatarChip',
          width: 160,
        },
        {
          field: 'variable_potential',
          header: 'Potential',
          width: 100,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('update-project-resource-working', {row});
          },
        },
        {
          title: 'Invoices',
          onPress: ({row}) => {
            navigation.navigate('revenue-invoice-table', {
              invoiceFilter: {
                invoice_details: {$elemMatch: {delivery_id: row._id}},
              },
            });
          },
        },
      ]}
    />
  );
};

export default ProjectResourceWorkingDataForOrders;
