export const useFeatureActions = ({navigation, params}) => {
  const featureActivityLog = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row?._id,
      api: `/projectfeatures/${row?._id}/activities`,
      title: row?.feature ? `${row?.feature}` : '',
      displayFields: {
        sub_status: {label: 'Stage'},
      },
      excludeFields: ['created_at'],
    });
  };

  return {
    featureActivityLog,
  };
};
