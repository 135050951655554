import React from 'react';
import { Form, MultiStepForm } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const SkillMetricsForm = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row, skill_metrics, formSkillList, product} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/skillmetrics',
    eventSourceId: 'skillMetricsAdded',
  });
  return (
    <MultiStepForm
      api={`/skillmetrics/${row?._id}`}
      fields={{
        name: 1,
        description: 1,
        tools: {name: 1},
        parent: 1,
        acceptance_criteria: 1,
        learning_path: 1,
        assessment: 1,
        self_check_list: 1,
        leader: {
          name: 1,
        },
      }}
      defaultValues={{
        parent: skill_metrics?._id,
        product: product?._id,
        type: 'Technical',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      steps={[
        {
          title: 'Skill Overview',
          fields: [
            {
              placeholder: 'Skill Name',
              field: 'name',
              type: 'text',
              required: true,
              visible: !formSkillList,
            },
            {
              label: 'Parent Skill',
              placeholder: 'Parent Skill',
              field: 'parent',
              type: 'autoComplete',
              api: '/skillmetrics',
              suggestionField: 'name',
              valueField: 'name',
              visible: !skill_metrics?._id,
            },
            {
              label: 'Tools',
              placeholder: 'Tools',
              field: 'tools',
              type: 'multiAutoComplete',
              api: '/tools',
              suggestionField: 'name',
              valueField: 'name',
              onCreate: () => {
                navigation.navigate('add-tools');
              },
            },

            {
              placeholder: 'Description',
              field: 'description',
              type: 'textArea',
              visible: !formSkillList,
            },
            {
              field: 'leader',
              label: 'Leader',
              type: 'autoComplete',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              visible: !formSkillList,
            },
            {
              field: 'skill_type',
              label: 'Type',
              type: 'radioGroup',
              options: ['Technical', 'Non Technical'],
              visible: !formSkillList,
            },
            {
              groups: [
                {
                  label: 'Acceptance Criteria',
                  fields: [
                    {
                      field: 'acceptance_criteria',
                      nested: true,
                      render: props => (
                        <NestedTable
                          {...props}
                          inlineAddForm
                          fields={[
                            {
                              type: 'textArea',
                              field: 'name',
                              placeholder: 'Checklist Item',
                            },
                          ]}
                          columns={[
                            {
                              type: 'text',
                              field: 'name',
                              header: 'Checklist Item',
                              numberOfLines: 3,
                            },
                          ]}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Learning Paths',
          fields: [
            {
              nested: true,
              field: 'learning_path',
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm
                  fields={[
                    {
                      type: 'text',
                      field: 'name',
                      label: 'Add Learning path',
                    },
                  ]}
                  columns={[
                    {
                      type: 'text',
                      field: 'name',
                      header: 'Learning Path',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Assessments',
          fields: [
            {
              nested: true,
              field: 'assessment',
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm
                  fields={[
                    {
                      label: 'Assessment',
                      placeholder: 'Assessment',
                      field: 'name',
                      type: 'text',
                    },
                  ]}
                  columns={[
                    {
                      label: 'Assessment',
                      field: 'name',
                      header: 'Assessment',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Self-Checklist',
          fields: [
            {
              nested: true,
              field: 'self_check_list',
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm
                  fields={[
                    {
                      label: 'Self-Checklist',
                      placeholder: 'Self-Checklist',
                      field: 'name',
                      type: 'text',
                      required: true,
                    },
                  ]}
                  columns={[
                    {
                      label: 'Self-Checklist',
                      field: 'name',
                      header: 'Self-Checklist',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddSkillMetricsNew = props => {
  const {
    route: {params},
  } = props;
  const {row, skill_metrics, product} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/skillmetrics',
    eventSourceId: 'skillMetricsAdded',
  });
  return (
    <Form
      header="Add Skill"
      api={`/skillmetrics/${row?._id}`}
      fields={{
        name: 1,
        description: 1,
        leader: {
          name: 1,
        },
        skill_type: 1,
      }}
      defaultValues={{
        parent: skill_metrics?._id,
        product: product?._id,
        skill_type: skill_metrics?.skill_type || 'Technical',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Skill Name',
          field: 'name',
          type: 'text',
          required: true,
          size: 'medium',
        },
        {
          field: 'leader',
          label: 'Leader',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 'medium',
        },
        {
          placeholder: 'Description',
          field: 'description',
          type: 'textArea',
        },
        {
          field: 'skill_type',
          label: 'Type',
          type: 'radioGroup',
          options: ['Technical', 'Non Technical'],
        },
      ]}
      {...props}
    />
  );
};

export const EditSkillMetricsNew = props => {
  return <AddSkillMetricsNew header={'Edit Skill'} mode="edit" {...props} />;
};

export const AddSkillToolForm = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/skillmetrics',
    eventSourceId: 'skillMetricsAdded',
  });
  return (
    <Form
      header="Tools/Languages"
      api={`/skillmetrics/${row?._id}`}
      mode="edit"
      fields={{
        tools: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Tools',
          placeholder: 'Tools',
          field: 'tools',
          type: 'multiAutoComplete',
          api: '/tools',
          suggestionField: 'name',
          valueField: 'name',
          onCreate: () => {
            navigation.navigate('add-tools');
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddSkillMetricsForm = props => {
  return <SkillMetricsForm header={'Add Skills'} {...props} />;
};

export const EditSkillMetricsForm = props => {
  return <SkillMetricsForm header={'Edit Skills'} mode="edit" {...props} />;
};

export const SkillMetricsDetiails = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row, skill_metrics, formSkillList, product} = params;
  return (
    <Form
      header={`${row.name} Skills Details`}
      api={`/skillmetrics/${row?._id}`}
      fields={{
        name: 1,
        description: 1,
        tools: {name: 1},
        parent: 1,
        acceptance_criteria: 1,
        learning_path: 1,
        assessment: 1,
        self_check_list: 1,
      }}
      defaultValues={{
        parent: skill_metrics?._id,
        product: product?._id,
      }}
      mode="edit"
      readOnly
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Skill Name',
          label: 'Skill Name',
          field: 'name',
          type: 'text',
          required: true,
          visible: !formSkillList,
        },
        {
          label: 'Parent Skill',
          placeholder: 'Parent Skill',
          field: 'parent',
          type: 'autoComplete',
          api: '/skillmetrics',
          suggestionField: 'name',
          valueField: 'name',
          visible: !skill_metrics?._id,
        },
        {
          label: 'Tools',
          placeholder: 'Tools',
          field: 'tools',
          type: 'multiAutoComplete',
          api: '/tools',
          suggestionField: 'name',
          valueField: 'name',
          onCreate: () => {
            navigation.navigate('add-tools');
          },
        },
        {
          placeholder: 'Description',
          label: 'Description',
          field: 'description',
          type: 'textArea',
          visible: !formSkillList,
        },
        {
          label: 'Acceptance Checklist',
          field: 'acceptance_criteria',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm
              fields={[
                {
                  type: 'textArea',
                  field: 'name',
                  placeholder: 'Checklist Item',
                },
              ]}
              columns={[
                {
                  type: 'text',
                  field: 'name',
                  header: 'Checklist Item',
                  numberOfLines: 3,
                },
              ]}
            />
          ),
        },
        {
          nested: true,
          field: 'learning_path',
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm
              fields={[
                {
                  type: 'text',
                  field: 'name',
                  label: 'Add Learning path',
                },
              ]}
              columns={[
                {
                  type: 'text',
                  field: 'name',
                  header: 'Learning Path',
                },
              ]}
            />
          ),
        },
        {
          nested: true,
          field: 'assessment',
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm
              fields={[
                {
                  label: 'Assessment',
                  placeholder: 'Assessment',
                  field: 'name',
                  type: 'text',
                },
              ]}
              columns={[
                {
                  label: 'Assessment',
                  field: 'name',
                  header: 'Assessment',
                },
              ]}
            />
          ),
        },
        {
          nested: true,
          field: 'self_check_list',
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm
              fields={[
                {
                  label: 'Self-Checklist',
                  placeholder: 'Self-Checklist',
                  field: 'name',
                  type: 'text',
                  required: true,
                },
              ]}
              columns={[
                {
                  label: 'Self-Checklist',
                  field: 'name',
                  header: 'Self-Checklist',
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};
