import LOCOMOWebLogin from './images/LOCOMOWebLogin.svg';
import UnthinkableLoginWeb from './images/UnthinkableLoginWeb.svg';
import WelcomeBackToLocomo from './images/WelcomeBackToLocomo.svg';
import rightArrow from './images/rightArrow.svg';
import GoogleLogo from './images/GoogleLogo.svg';
import Banner1Mobile from './images/Banner1Mobile.png';
import Banner2Mobile from './images/Banner2Mobile.png';
import NextGenIcon from './images/NextGenIcon.svg';
import TrackableIcon from './images/TrackableIcon.svg';
import Banner1Web from './images/Banner1Web.png';
import Banner2Web from './images/Banner2Web.png';
import backButton from './images/backButton.svg';
import LocomoLogo from './images/LocomoLogo.svg';
import MailLogo from './images/MailLogo.svg';
import UnthinkableLoginLogo from './images/UnthinkableLoginLogo.svg';

export const theme = {
  Login: {
    Banner1Web: Banner1Web,
    Banner2Web: Banner2Web,
    LOCOMOIcon: LOCOMOWebLogin,
    UnthinkableIcon: UnthinkableLoginWeb,
    containerStyle: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
    },
    mainContainerStyle: {
      backgroundColor: '#F7F7F7',
      width: '40%',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    iconContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    formContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    LOCOMOIconStyle: {margin: 12},
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#7D7D82',
    },
  },

  LoginMobile: {
    Banner1Mobile: Banner1Mobile,
    NextGenIcon: NextGenIcon,
    Banner2Mobile: Banner2Mobile,
    TrackableIcon: TrackableIcon,
    loaderIcon: rightArrow,
    containerStyle: {
      backgroundColor: '#103300',
      flex: 1,
    },
    mainContainerStyle: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: '#F7F7F7',
    },
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#7D7D82',
    },
    nextIconStyle: {},
    loaderStyle: {},
  },

  LoginForm: {
    WelcomeBackIcon: WelcomeBackToLocomo,
    containerStyle: {
      justifyContent: 'space-around',
      backgroundColor: '#FFFFFF',
      padding: 30,
      borderRadius: 20,
    },
    headerContainerStyle: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginBottom: 16,
    },
    headerTextStyle: {
      fontSize: 17,
      fontFamily: 'Inter-Bold',
      lineHeight: '20px',
      color: '#7D7D82',
      marginBottom: 5,
    },
    googleLoginContainerStyle: {
      marginTop: 16,
      marginBottom: 16,
      alignItems: 'center',
    },
    saparatorContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E5E5EA',
      height: 1,
      marginTop: 30,
      marginBottom: 30,
    },
    separatorTextStyle: {
      padding: 10,
      backgroundColor: '#FCFCFC',
      fontSize: 14,
      fontFamily: 'Inter-Regular',
      lineHeight: '20px',
      color: '#A3A3A3',
    },
    inputContainerStyle: {marginTop: 8, marginBottom: 8},
    FormTextContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    radioButtonStyle: {
      fontSize: 12,
      fontFamily: 'Inter-Regular',
      lineHeight: '16px',
      color: '#7D7D82',
      cursor: 'pointer',
    },
    forgotPasswordTextStyle: {
      fontSize: 12,
      fontFamily: 'Inter-Regular',
      lineHeight: '16px',
      color: '#3562FF',
      cursor: 'pointer',
    },
    loginButtonStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 16,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: '#B2EE7C',
      borderRadius: 8,
      cursor: 'pointer',
    },
    loginTextStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#103300',
    },
  },
  LoginFormMobile: {
    WelcomeBackIcon: WelcomeBackToLocomo,
    UnthinkableIcon: UnthinkableLoginWeb,
    iconContainerStyle: {
      marginTop: 25,
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerStyle: {
      justifyContent: 'center',
      backgroundColor: '#FFFFFF',
      padding: 30,
      borderRadius: 20,
      marginTop: 16,
      marginRight: 16,
      marginBottom: 16,
      marginLeft: 16,
      flex: 1,
    },
    headerContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 50,
    },
    headerTextStyle: {
      fontSize: 17,
      fontFamily: 'Inter-Bold',
      lineHeight: '20px',
      color: '#7D7D82',
      marginBottom: 5,
    },
    googleLoginContainerStyle: {
      marginTop: 25,
      marginBottom: 25,
      alignItems: 'center',
    },
    saparatorContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E5E5EA',
      height: 1,
      marginTop: 40,
      marginBottom: 40,
    },
    separatorTextStyle: {
      height: 23,
      width: 30,
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
      fontSize: 14,
      fontFamily: 'Inter-Regular',
      lineHeight: '20px',
      color: '#19191A',
    },
    inputContainerStyle: {marginTop: 20, marginBottom: 20},
    FormTextContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    radioButtonStyle: {
      fontSize: 12,
      fontFamily: 'Inter-Regular',
      lineHeight: '16px',
      color: '#7D7D82',
      cursor: 'pointer',
    },
    forgotPasswordTextStyle: {
      fontSize: 12,
      fontFamily: 'Inter-Regular',
      lineHeight: '16px',
      color: '#3562FF',
      cursor: 'pointer',
    },
    loginButtonStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 25,
      paddingTop: '6%',
      paddingBottom: '6%',
      backgroundColor: '#B2EE7C',
      borderRadius: 8,
      cursor: 'pointer',
    },
    loginTextStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#103300',
    },
  },
  GoogleLoginButton: {
    googleIcon: GoogleLogo,
    containerStyle: {
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: '#6585F5',
      height: 48,
      width: '100%',
    },
    textContainerStyle: {
      backgroundColor: '#6585F5',
      height: '100%',
      flex: 1,
      justifyContent: 'center',
    },
    iconContainerStyle: {
      width: 46,
      alignItems: 'center',
      justifyContent: 'center',
    },
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#FFFFFF',
      marginLeft: 52,
      marginRight: 52,
    },
  },
  GoogleLoginButtonMobile: {
    googleIcon: GoogleLogo,
    containerStyle: {
      cursor: 'pointer',
      paddingTop: 11,
      paddingBottom: 11,
      marginTop: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: '#103300',
    },
    iconStyle: {height: 20, width: 20},
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#133D00',
      marginLeft: 8,
    },
  },
  LoginButton: {
    googleIcon: GoogleLogo,
    mailIcon: MailLogo,
    containerStyle: {
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: '#A3A3A3',
      height: 48,
      width: '100%',
    },
    textContainerStyle: {
      // backgroundColor: '#6585F5',
      height: '100%',
      flex: 1,
      justifyContent: 'center',
    },
    iconContainerStyle: {
      width: 46,
      alignItems: 'center',
      justifyContent: 'center',
    },
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter-Medium',
      lineHeight: '18px',
      color: '#737373',
      marginLeft: 12,
      marginRight: 12,
    },
  },
};

export const SignupPageStyles = ({theme: {fonts, colors}}) => ({
  LOCOMOIcon: LocomoLogo,
  UnthinkableIcon: UnthinkableLoginWeb,
  containerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.BACKGROUND,
  },
  subContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 400,
  },
  mainContainerStyle: {
    alignItems: 'center',
    padding: 30,
    borderRadius: 12,
    backgroundColor: colors.BACKGROUND,
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
    marginBottom: 40,
  },
  iconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
    height: 42,
    width: 250,
  },
  textStyle: {
    ...fonts.BODY3,
    color: '#737373',
    marginBottom: 30,
  },
  secondaryTextStyle: {
    ...fonts.CAPTION_SMALL,
    color: colors.NEUTRAL_MEDIUM,
  },
  spanTextStyle: {
    color: colors.NEUTRAL_HIGH,
    cursor: 'pointer',
  },
});

export const EmailPageStyles = ({theme: {fonts, colors}}) => ({
  LOCOMOIcon: LocomoLogo,
  UnthinkableIcon: UnthinkableLoginWeb,
  backButtonIcon: backButton,
  containerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.BACKGROUND,
  },
  subContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 400,
  },
  mainContainerStyle: {
    // alignItems: 'center',
    padding: 30,
    borderRadius: 12,
    backgroundColor: colors.BACKGROUND,
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
    marginBottom: 40,
  },
  iconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
    height: 42,
    width: 250,
  },
  headingTextStyle: {
    ...fonts.TITLE,
    color: colors.NEUTRAL_HIGH,
  },
  textStyle: {
    ...fonts.BODY3,
    color: '#737373',
    marginBottom: 30,
  },
  secondaryTextStyle: {
    ...fonts.CAPTION_SMALL,
    color: colors.NEUTRAL_MEDIUM,
  },
  spanTextStyle: {
    color: colors.NEUTRAL_HIGH,
    cursor: 'pointer',
  },
  buttonStyles: {
    container: {
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 28,
      paddingRight: 28,
      marginBottom: 10,
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  },
  forgotPasswordTextStyle: {
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    lineHeight: '16px',
    color: '#3562FF',
    cursor: 'pointer',
  },
  FormTextContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 50,
  },
});

export const ProfileDetailPageStyles = ({theme: {fonts, colors}}) => ({
  LOCOMOIcon: LocomoLogo,
  containerStyle: {
    flex: 1,
    backgroundColor: colors.BACKGROUND,
    paddingTop: 24,
    paddingLeft: 21,
    paddingRight: 21,
  },
  subContainerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainerStyle: {
    padding: 30,
    borderRadius: 12,
    backgroundColor: colors.BACKGROUND,
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
    width: 400,
  },
  textStyle: {
    ...fonts.TITLE,
    color: '#19191A',
    marginBottom: 28,
  },
  buttonStyles: {
    container: {
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 28,
      paddingRight: 28,
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  },
});

export const HeaderStyle = ({theme: {fonts, colors}}) => ({
  UnthinkableIcon: UnthinkableLoginLogo,
  headerTextStyle: {
    ...fonts.CAPTION_LARGE,
    color: '#737373',
  },
  emailStyle: {...fonts.BODY2, color: '#1A1A1A'},
  logOutStyle: {
    ...fonts.ACTION,
    color: '#007AFF',
  },
});

export const LoginPageComponentStyles = ({theme: {fonts, colors}}) => ({
  LOCOMOIcon: LocomoLogo,
  containerStyle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.BACKGROUND,
  },
  innerContainerStyle: {
    width: 400,
    alignSelf: 'center',
  },
  headerContainerStyle: {alignItems: 'center'},
  headerTextStyle: {
    ...fonts.BODY2,
    color: colors.NEUTRAL_MEDIUM,
  },
  footerContainerStyle: {alignItems: 'center'},
  footerTextStyle: {
    ...fonts.BODY3,
    color: colors.NEUTRAL_LOW,
  },
});

export const ResetPasswordFormStyles = ({theme: {fonts, colors}}) => ({
  backIcon: backButton,
  containerStyle: {
    justifyContent: 'space-between',
    backgroundColor: colors.BACKGROUND,
    padding: 30,
    borderRadius: 12,
    minHeight: 400,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)',
  },
  headerContainerStyle: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  headerRowStyle: {
    alignItems: 'center',
  },
  headerTextStyle: {
    ...fonts.TITLE,
    color: colors.NEUTRAL_HIGH,
  },
  subHeadingTextStyle: {
    ...fonts.BODY3,
    color: colors.NEUTRAL_MEDIUM,
    maxWidth: '340px',
    paddingTop: 8,
    paddingBottom: 8,
  },
  inputContainerStyle: {marginTop: 8, marginBottom: 8},
  buttonTextStyle: {
    ...fonts.BODY2,
    color: colors.BACKGROUND,
  },
  disabledButtonTextStyle: {
    color: colors.NEUTRAL_LOW,
  },
  buttonStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: colors.NEUTRAL_HIGH,
    borderRadius: 8,
    cursor: 'pointer',
  },
  disabledButtonStyle: {backgroundColor: colors.OUTLINE},
  childContainerStyle: {
    minHeight: 172,
  },
  buttonStyles: {
    container: {
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 28,
      paddingRight: 28,
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  },
});

export const EmailFormStyles = ({theme: {fonts, colors}}) => ({
  inputContainerStyle: {marginTop: 8, marginBottom: 8},
  resendCodeTextStyle: {
    ...fonts.CAPTION_LARGE,
    color: colors.NEUTRAL_MEDIUM,
  },
  resendTimerTextStyle: {
    color: colors.NEUTRAL_LOW,
  },
  resendButtonTextStyle: {
    color: colors.INFORMATION_HIGH,
  },
});

export const OTPInputStyles = ({theme: {fonts, colors}}) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
  },
  cell: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 52,
    borderWidth: 1,
    borderColor: colors.OUTLINE,
    borderRadius: 6,
  },
  activeCell: {
    borderColor: colors.BRAND_MEDIUM,
  },
  cellText: {
    ...fonts.HEADING_1,
    color: colors.OUTLINE,
  },
  activeCellText: {
    color: colors.NEUTRAL_HIGH,
  },
});

export const SetPasswordStyles = ({theme: {fonts, colors, shadows}}) => ({
  container: {
    flex: 1,
    backgroundColor: colors.BACKGROUND,
    padding: 30,
    borderRadius: 12,
    minHeight: 400,
    ...shadows.S,
  },
});
