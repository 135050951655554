import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {FormErrorConfirmWrapper} from '../../../components/form/FormWrapper';

export const ModuleDevelopmentAssignmentForm = props => {
  const params = props?.route?.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'orderresourcerequests',
    ...props,
  });

  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        onSubmit={onSubmit}
        header="Module Development Allocation"
        submitAction="Save"
        defaultValues={{
          is_actual_allocation: true,
          employee: {_id: row?.assigned_to?._id, name: row?.assigned_to?.name},
        }}
        layoutFields={[
          {
            label: 'Project',
            field: 'project_id',
            type: 'autoComplete',
            api: `/projects`,
            filter: {
              projecttype: 'i',
              project_status: 'a',
              is_self_module: true,
            },
            suggestionField: 'project',
            valueField: 'project',
            size: 6,
          },
          {
            label: 'Employee',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            type: 'date',
            field: 'from_date',
            label: 'Start Date',
            size: 6,
          },
          {
            type: 'date',
            field: 'to_date',
            label: 'End Date',
            size: 6,
          },
          {
            type: 'number',
            field: 'hours',
            label: 'Hours',
          },
        ]}
      />
    </FormErrorConfirmWrapper>
  );
};
