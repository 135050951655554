import React from 'react';
import {Form, MultiStepForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const OrganizationForm = props => {
  const {
    route: {params},
  } = props;
  const {organizationId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/organizations',
    eventSourceId: 'Organization',
  });
  return (
    <MultiStepForm
      api={`/organizations/${organizationId}`}
      fields={{
        name: 1,
        email: 1,
        type: 1,
        url: 1,
        code: 1,
        fax: 1,
        start_date: 1,
        address: 1,
        phone_no: 1,
        beneficiary_name: 1,
        deputation_address: 1,
        departments: {name: 1},
        currency: {currency: 1},
        depreciation_type: {name: 1},
        logo: 1,
        registration_details: {
          registration_for: {name: 1},
          registration_no: 1,
        },
        bank_account_details: {
          bank_name: 1,
          arn_no: 1,
          bank_address: 1,
          account_no: 1,
          nostro_account_no: 1,
          iban_no: 1,
          ifsc_code: 1,
          swift_code: 1,
          currency: {currency: 1},
          correspondent_bank: 1,
          correspondent_bank_swift_code: 1,
          correspondent_bank_nostro_account_no: 1,
          correspondent_bank_ABA_code: 1,
          purpose_code: 1,
          remark: 1,
          is_new: 1,
        },
        wire_instructions: {
          bank_name: 1,
          account_name: 1,
          account_no: 1,
          swift_code: 1,
          remarks: 1,
          wire_transfer_routing_no: 1,
          ifsc_code: 1,
        },
        ach_instructions: {
          bank_name: 1,
          account_name: 1,
          account_no: 1,
          ach_transfer_routing_no: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      steps={[
        {
          title: 'Basic Information',
          fields: [
            {
              label: 'Organization Details',
              fields: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Name',
                  required: true,
                  size: 6,
                },
                {
                  field: 'type',
                  type: 'autoComplete',
                  options: [
                    'Public Limited Company.',
                    'Private Limited Company.',
                    'Joint-Venture Company.',
                    'Partnership Firm.',
                  ],
                  size: 6,
                  required: true,
                  label: 'Type',
                },
                {
                  type: 'text',
                  field: 'beneficiary_name',
                  label: 'Beneficiary Name',
                  size: 6,
                },
                {
                  field: 'currency',
                  type: 'autoComplete',
                  api: `/currencies`,
                  label: 'Currency',
                  suggestionField: 'currency',
                  valueField: 'currency',
                  size: 6,
                },
                {
                  type: 'number',
                  field: 'phone_no',
                  label: 'Phone No.',
                  size: 4,
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'Email',
                  size: 4,
                },
                {
                  type: 'text',
                  field: 'fax',
                  label: 'Fax',
                  size: 4,
                },
                {
                  type: 'text',
                  field: 'url',
                  label: 'URL',
                  size: 6,
                },
                {
                  type: 'text',
                  field: 'code',
                  label: 'Code',
                  size: 6,
                },
                {
                  type: 'text',
                  field: 'address',
                  label: 'Address',
                  size: 6,
                },

                {
                  type: 'text',
                  field: 'deputation_address',
                  label: 'Deputation Address',
                  size: 6,
                },
                {
                  type: 'date',
                  field: 'start_date',
                  label: 'Start Date',
                },
                {
                  field: 'depreciation_type',
                  type: 'multiAutoComplete',
                  api: `/depreciationtypes`,
                  label: 'Deprication type',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                // {
                //   label: 'Department',
                //   field: 'departments',
                //   type: 'multiAutoComplete',
                //   api: `/departments`,
                //   suggestionField: 'name',
                //   valueField: 'name',
                // },

                {
                  placeholder: 'Logo',
                  label: 'Logo',
                  field: 'logo',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Registration details',
          fields: [
            {
              label: 'Registration information',
              fields: [
                {
                  field: 'registration_details',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm
                      fields={[
                        {
                          field: 'registration_for',
                          type: 'autoComplete',
                          api: `/currencies`,
                          label: 'Registration for',
                          suggestionField: 'name',
                          valueField: 'name',
                          // size: 6,
                        },
                        {
                          type: 'text',
                          placeholder: 'Registration No.',
                          field: 'registration_no',
                          header: 'Registration No.',
                        },
                      ]}
                      columns={[
                        {
                          field: 'registration_for.name',
                          type: 'text',
                          header: 'Registration for',
                        },
                        {
                          type: 'text',
                          placeholder: 'Registration No.',
                          field: 'registration_no',
                          header: 'Registration No.',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              label: 'Bank information',
              fields: [
                {
                  field: 'bank_account_details',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      // inlineAddForm
                      fields={[
                        {
                          label: 'Bank Name',
                          field: 'bank_name',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Bank Address',
                          field: 'bank_address',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Account No.',
                          field: 'account_no',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'IFSC No.',
                          field: 'ifsc_code',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Swift No.',
                          field: 'swift_code',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'ABA Code',
                          field: 'correspondent_bank_ABA_code',
                          type: 'text',
                          size: 6,
                        },
                        // {
                        //   placeholder: 'Nostro Acc. No.',
                        //   label: 'Nostro Acc. No.',
                        //   field: 'nostroAccountNo',
                        //   type: 'text',
                        //   width: 150
                        // },
                        {
                          label: 'IBAN No.',
                          field: 'iban_no',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Correspondent/Intermediary Bank',
                          field: 'correspondent_bank',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Correspondent/Intermediary Swift Code',
                          field: 'correspondent_bank_swift_code',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Correspondent/Intermediary Nostro Acc. No',
                          field: 'correspondent_bank_nostro_account_no',
                          type: 'text',
                          size: 6,
                        },
                        {
                          field: 'currency',
                          type: 'autoComplete',
                          api: `/currencies`,
                          label: 'Currency',
                          suggestionField: 'currency',
                          valueField: 'currency',
                          size: 6,
                        },
                        {
                          label: 'Purpose Code.',
                          field: 'purpose_code',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Remark.',
                          field: 'remark',
                          type: 'text',
                          size: 6,
                        },
                        {
                          label: 'Is New.',
                          field: 'is_new',
                          type: 'checkbox',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Bank Name',
                          field: 'bank_name',
                          type: 'text',
                          size: 6,
                        },
                        {
                          header: 'Bank Address',
                          field: 'bank_address',
                          type: 'text',
                          size: 6,
                        },
                        {
                          header: 'Account No.',
                          field: 'account_no',
                          type: 'text',
                          size: 6,
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Wire & ACH details(For US entity)',
          fields: [
            {
              label: 'Wire information',
              fields: [
                {
                  field: 'wire_instructions',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm
                      fields={[
                        {
                          label: 'Bank Name',
                          field: 'bank_name',
                          type: 'text',
                        },
                        {
                          label: 'Account Name',
                          field: 'account_name',
                          type: 'text',
                        },
                        {
                          label: 'Account No.',
                          field: 'account_no',
                          type: 'text',
                        },
                        {
                          label: 'Swift No.',
                          field: 'swift_code',
                          type: 'text',
                        },
                        {
                          label: 'Remarks',
                          field: 'remarks',
                          type: 'text',
                        },
                        {
                          label: 'Wire Transfer Routing No.',
                          field: 'wire_transfer_routing_no',
                          type: 'text',
                        },
                        {
                          label: 'IFSC Code.',
                          field: 'ifsc_code',
                          type: 'text',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Bank Name',
                          field: 'bank_name',
                          type: 'text',
                        },
                        {
                          header: 'Account Name',
                          field: 'account_name',
                          type: 'text',
                        },
                        {
                          header: 'Account No.',
                          field: 'account_no',
                          type: 'text',
                        },
                        {
                          header: 'Swift No.',
                          field: 'swift_code',
                          type: 'text',
                        },
                        {
                          header: 'Remarks',
                          field: 'remarks',
                          type: 'text',
                        },
                        {
                          header: 'Wire Transfer Routing No.',
                          field: 'wire_transfer_routing_no',
                          type: 'text',
                        },
                        {
                          header: 'IFSC Code.',
                          field: 'ifsc_code',
                          type: 'text',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'ACH information',
              fields: [
                {
                  field: 'ach_instructions',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm
                      fields={[
                        {
                          label: 'Bank Name',
                          field: 'bank_name',
                          type: 'text',
                        },
                        {
                          label: 'Account Name',
                          field: 'account_name',
                          type: 'text',
                        },
                        {
                          label: 'Account No.',
                          field: 'account_no',
                          type: 'text',
                        },
                        {
                          label: 'ACH Transfer Routing No.',
                          field: 'ach_transfer_routing_no',
                          type: 'text',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Bank Name',
                          field: 'bank_name',
                          type: 'text',
                        },
                        {
                          header: 'Account Name',
                          field: 'account_name',
                          type: 'text',
                        },
                        {
                          header: 'Account No.',
                          field: 'account_no',
                          type: 'text',
                        },
                        {
                          header: 'ACH Transfer Routing No.',
                          field: 'ach_transfer_routing_no',
                          type: 'text',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddOrganizationForm = props => {
  return <OrganizationForm header="Add Organization" {...props} />;
};

export const UpdateOrganizationForm = props => {
  return (
    <OrganizationForm mode="edit" header="Update Organization" {...props} />
  );
};
