import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const KpiForm = props => {
  const {
    route: {params},
  } = props;
  const navigation = useNavigation();
  let {onSubmit} = useFormSubmit({
    uri: '/kpis',
    ...props,
  });
  const {department, product, organization} = params;

  let {user} = useAppStateContext();

  let {
    employee: {
      department: employeeDepartment,
      product: employeeProduct,
      organization: employeeOrganization,
    } = {},
  } = user;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Add KPI'}
      submitAction={'Save'}
      defaultValues={{
        department: department ? department?._id : employeeDepartment?._id,
        product: product ? product?._id : employeeProduct?._id,
        organization: organization
          ? organization?._id
          : employeeOrganization?._id,
      }}
      layoutFields={[
        {
          label: 'KPI',
          field: 'kpi',
          type: 'text',
        },
        {
          label: 'Parent',
          field: 'parent',
          type: 'autoComplete',
          api: `/kpis`,
          filter: {
            department: department?._id,
          },
          suggestionField: 'kpi',
          valueField: 'kpi',
        },
        {
          label: 'Description',
          field: 'kpi_description',
          type: 'text',
        },
        {
          label: 'Complete date',
          field: 'completed_on',
          type: 'date',
        },
        {
          label: 'Formula',
          field: 'formula',
          type: 'text',
        },
        {
          label: 'Filter',
          field: 'filter',
          type: 'text',
        },
        {
          label: 'Apis',
          field: 'apis',
          type: 'multiAutoComplete',
          suggestionHeader: 'Select an option',
          valueField: 'name',
          api: '/apis',
          suggestionField: 'name',
          onCreate: () => {
            navigation.navigate(`add-apis`, {...params});
          },
        },
        {
          label: 'Resources',
          field: 'resources',
          type: 'multiAutoComplete',
          suggestionHeader: 'Select an option',
          valueField: 'name',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          api: '/employeeSuggestions',
        },
        {
          label: 'Leaders',
          field: 'leaders',
          type: 'multiAutoComplete',
          suggestionHeader: 'Select an option',
          valueField: 'name',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          api: '/employeeSuggestions',
        },
      ]}
      {...props}
    />
  );
};

export const EditKpiForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <KpiForm
      header={'Edit Kpi'}
      params={params}
      mode="edit"
      api={`/kpis/${params.row._id}`}
      {...props}
    />
  );
};
