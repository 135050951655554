import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import moment from 'moment';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {FeedbackForm} from './EditFeedback';
import {useState} from 'react';
import {TextRenderer} from '../../../components/form/Renderers';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';

const FeedbackRender = ({row = {}, styles = {}, show_feedback_only}) => {
  const [loading] = useState(false);
  const colors = useTheme('colors');

  let {rowText = {}} = styles;
  console.log('loading>', loading);
  if (row?.status == 'due' && !show_feedback_only) {
    return (
      <View style={{flexDirection: 'row'}}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}>
              <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
                Give Feedback
              </Text>
            </View>
          </>
        )}
      </View>
    );
  }
};

const pipFeedbackRender = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, ACTION} = useTheme('fonts');

  if (row?.overall_score) {
    return (
      <>
        <Text
          style={{
            ...ACTION,
            color: colors.NEUTRAL_MEDIUM,
          }}>
          {moment(row?.training_feedback_date).format('DD MMM, YY')}
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Text
          style={{
            ...ACTION,
            color: colors.NEUTRAL_MEDIUM,
          }}>
          Due: {moment(row?.next_feedback_date).format('DD MMM, YY')}{' '}
        </Text>
      </>
    );
  }
};

const pipOverallRender = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE, ACTION} = useTheme('fonts');

  if (row?.overall_score) {
    return (
      <>
        <Text
          style={{
            ...ACTION,
          }}>
          {row?.overall_score}/40
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const PIPFeedbackDate = {
  header: 'Date',
  render: pipFeedbackRender,
  width: 130,
};

const PIPOverallRender = {
  header: 'Overall Score',
  render: pipOverallRender,
  align: 'center',
  // width: 200,
};

const RenderOverAllScore = props => {
  const {row} = props;
  const {overall_score} = row;
  if (overall_score) {
    return <RatingRenderer value={overall_score} />;
  }
  return (
    <TextRenderer {...props} value={'Feedback'} style={{color: '#007AFF'}} />
  );
};

export const TrainingFeedback = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {employee_training} = params;
  const filter = employee_training?.pip_marked_on
    ? {pip_resource: employee_training._id}
    : {employee_training: employee_training?._id};
  return (
    <Table
      renderHeader={() => <TableHeader title="Feedbacks" />}
      filter={{...filter}}
      api="/trainingFeedbacks"
      eventSourceId={['TrainingFeedbacks']}
      columns={[
        {
          field: 'overdue_date',
          header: 'Overdue Date',
          type: 'date',
          width: 120,
        },
        {
          field: 'training_feedback_date',
          header: 'Date',
          type: 'date',
          width: 120,
        },
        {
          render: RenderOverAllScore,
          header: 'Overall Score',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('edit-training-feedback', {
              ...params,
              training_feedback: row,
            });
          },
        },
        {
          field: 'next_feedback_date',
          header: 'Next Feedback Date',
          type: 'date',
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export const PIPTrainingFeedback = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {employee_training, show_feedback_only} = params;
  const filter = employee_training?.pip_marked_on
    ? {pip_resource: employee_training._id}
    : {employee: employee_training?.employee?._id};
  return (
    <Table
      filter={{...filter}}
      api="/trainingFeedbacks"
      eventSourceId={['TrainingFeedbacks']}
      columns={[
        PIPFeedbackDate,
        {
          type: 'rating',
          field: 'tech_knowledge',
          header: 'Tech Knowledge',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 160,
        },
        {
          type: 'rating',
          field: 'code_quality',
          header: 'Code Quality',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 140,
        },
        {
          type: 'rating',
          field: 'ensure_timeline',
          header: 'Ensure Timeline',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 160,
        },
        {
          type: 'rating',
          field: 'communication',
          header: 'Communication',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 140,
        },
        {
          type: 'rating',
          field: 'punctuality',
          header: 'Punctuality',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 130,
        },
        {
          type: 'rating',
          field: 'following_processes',
          header: 'Following Process',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 160,
        },
        {
          type: 'rating',
          field: 'ownership',
          header: 'Ownership',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 130,
        },
        {
          type: 'rating',
          field: 'adoptability',
          header: 'Adoptability',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 130,
        },
        {
          ...PIPOverallRender,
          width: 150,
        },
        {
          field: 'mentor_subjective_feedback',
          type: 'textArea',
          header: 'Remarks',
        },
        {
          header: 'Actions',
          align: 'center',
          // width: 200,
          render: ({row, styles}) => (
            <FeedbackRender
              row={row}
              styles={styles}
              show_feedback_only={show_feedback_only}
            />
          ),
          onPress: ({row}) => {
            navigation.navigate('pip-training-feedback-form', {
              ...params,
              training_feedback: row,
            });
          },
          // visible: (props) =>{ console.log("values>>>>>>>>...",props)},
        },
      ]}
      // moreActions={[
      //   {
      //     title: 'Give Feedback',
      //     onPress: ({row}) => {
      //       navigation.navigate('edit-training-feedback', {
      //         ...params,
      //         training_feedback: row,
      //       });
      //     },
      //     visible: ({row}) => row.status == 'due' && !show_feedback_only,
      //   },
      // ]}
      {...props}
    />
  );
};

export const PIPSelfTrainingFeedback = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {employee_training, show_feedback_only} = params;
  return (
    <Table
      renderHeader={() => <TableHeader title="Feedbacks" />}
      sort={{_id: 1}}
      api={'/trainingfeedbacks'}
      eventSourceId={['TrainingFeedbacks']}
      filter={{
        pip_resource: employeeId,
      }}
      fields={{
        tech_knowledge: 1,
        code_quality: 1,
        ensure_timeline: 1,
        communication: 1,
        punctuality: 1,
        following_processes: 1,
        ownership: {name: 1},
        adoptability: 1,
        mentor_subjective_feedback: 1,
        overall_score: 1,
        pip_resource: {
          name: 1,
          pip_mentor: {
            name: 1,
          },
        },
      }}
      // filter={{
      //   pip_resource: employee?._id,
      // }}
      columns={[
        PIPFeedbackDate,
        {
          header: 'Mentor',
          field: 'pip_resource.pip_mentor',
          type: 'userAvatar',
          width: 95,
        },
        {
          type: 'rating',
          field: 'tech_knowledge',
          header: 'Tech Knowledge',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 160,
        },
        {
          type: 'rating',
          field: 'code_quality',
          header: 'Code Quality',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 140,
        },
        {
          type: 'rating',
          field: 'ensure_timeline',
          header: 'Ensure Timeline',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 160,
        },
        {
          type: 'rating',
          field: 'communication',
          header: 'Communication',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 140,
        },
        {
          type: 'rating',
          field: 'punctuality',
          header: 'Punctuality',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 130,
        },
        {
          type: 'rating',
          field: 'following_processes',
          header: 'Following Process',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 160,
        },
        {
          type: 'rating',
          field: 'ownership',
          header: 'Ownership',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 130,
        },
        {
          type: 'rating',
          field: 'adoptability',
          header: 'Adoptability',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 130,
        },
        {
          ...PIPOverallRender,
          width: 150,
        },
        {
          field: 'mentor_subjective_feedback',
          type: 'textArea',
          header: 'Remarks',
        },
      ]}
      {...props}
    />
  );
};
