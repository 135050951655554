import {Image, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import React from 'react';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import images from '../../../images';

const {GreenBackGroundAdd} = images;

export const ReplacementForm = props => {
  const {route: {params} = {}, navigation} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/employeereplacement/save',
    eventSourceId: ['ResignationActive', 'ResignationEngg', 'replacement'],
    ...props,
  });
  return (
    <Form
      mode="edit"
      api={`/employeeassignment/replacement/${params?.assigned_to?._id}`}
      params={{...params}}
      beforeSubmit={({data}) => {
        if (data.projects.length) {
          data.projects.forEach(project => {
            if (!project.owner_id) {
              throw new Error(
                `Please select owner for project ${project?.project_id?.project}`,
              );
            }
          });
        }
        let typeWiseDefaultData = {};
        if (data?.type == 'Negative Margin') {
          typeWiseDefaultData = {
            status: 'due',
            type: 'Negative Margin',
          };
        }
        if (data?.type == 'Anchor') {
          typeWiseDefaultData = {
            status: 'due',
            type: 'Anchor',
          };
        }
        if (data?.type == 'Long Leave') {
          typeWiseDefaultData = {
            status: 'due',
            type: 'Long Leave',
          };
        }
        return {
          data: {
            ...data,
            ...params.defaultValues,
            ...typeWiseDefaultData,
            employee: params.assigned_to._id,
            date: new Date(),
          },
        };
      }}
      onSubmit={onSubmit}
      header="Replacement requirements"
      submitAction="Save"
      eventSourceId="replacement"
      layoutFields={[
        {
          label: 'Type',
          placeholder: 'Select',
          field: 'type',
          options: ['Long Leave', 'Negative Margin', 'Anchor'],
          type: 'autoComplete',
          size: 6,
          visible: !params?.fromResignation,
          required: true,
        },
        {
          label: 'Target release date',
          field: 'target_release_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          field: 'projects',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              skipAdd={true}
              readOnly={true}
              columns={[
                {
                  header: 'Project',
                  field: 'project_id.project',
                  type: 'text',
                  width: 200,
                },
                {
                  header: 'Skills',
                  width: 150,
                },
                {
                  header: 'Allocation/Day',
                  width: 250,
                  render: ({row}) => {
                    return (
                      <PrimaryColumnCard
                        primaryTitle={`${row.hours}h`}
                        items={[
                          {
                            value: `${moment(row.from_date).format(
                              'DD MMM YY',
                            )} - ${moment(row.to_date).format('DD MMM YY')}`,
                          },
                        ]}
                      />
                    );
                  },
                },
                {
                  header: 'Replacement required',
                  align: 'center',
                  width: 150,
                  required: true,
                  render: ({row, index}) => {
                    return (
                      <Switch
                        value={!!row?.replacement_required}
                        onChangeValue={value => {
                          props.replace(index, {
                            ...row,
                            replacement_required: value,
                          });
                        }}
                      />
                    );
                  },
                },
                {
                  width: 150,
                  align: 'center',
                  header: 'Owner',
                  required: true,
                  columnField: 'owner_id',
                  render: ({row, index}) => {
                    return row.owner_id ? (
                      <UserAvatar value={row.owner_id} />
                    ) : (
                      <View
                        onPress={() => {
                          navigation.navigate('add-owner', {
                            row,
                            onChangeValue: data => {
                              props.replace(index, {
                                ...row,
                                ...data,
                              });
                            },
                          });
                        }}>
                        <Image source={GreenBackGroundAdd}></Image>
                      </View>
                    );
                  },
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};

export const AddReplacementForm = props => {
  return <ReplacementForm header="Add Replacement" {...props} />;
};

const AddOwner = props => {
  const {route: {params} = {}} = props;

  const {row, onChangeValue} = params;
  return (
    <Form
      onSubmit={data => {
        onChangeValue(data);
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'owner_id',
          label: 'Owner',
          required: true,
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
        },
      ]}
      {...props}
    />
  );
};
export const AddOwnerForm = props => {
  return <AddOwner header="Add Owner" {...props} />;
};

export const updateStatusForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/employeereplacements`,
    eventSourceId: 'replacement',
    ...props,
  });
  return (
    <Form
      data={{_id: row?._id, status: 'completed', type: row?.type}}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      header="Mark Completed"
      layoutFields={[
        {
          label: 'Completed on',
          field: 'completed_on',
          type: 'date',
          required: true,
        },
        {
          type: 'autoComplete',
          label: 'Status',
          options: ['Successful', 'Failed'],
          placeholder: 'Select',
          field: 'completed_status',
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
        {
          type: 'textArea',
          label: 'Reason',
          field: 'reason',
          placeholder: 'Reason',
          visible: ({values}) => {
            return values?.completed_status === 'Failed';
          },
        },
      ]}
      {...props}
    />
  );
};

export const UpdateReplacementEmployee = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/employeereplacements`,
    eventSourceId: 'replacement',
    ...props,
  });
  return (
    <Form
      api={'/employeereplacements/' + row?._id}
      fields={{
        replacement: {
          name: 1,
          color: 1,
          official_email_id: 1,
        },
        target_release_date: 1,
        assign_date: 1,
      }}
      mode="edit"
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {...data, status: 'inprogress'},
        };
      }}
      submitAction="Save"
      header="Update Employee"
      layoutFields={[
        {
          label: 'Replacement Employee',
          field: 'replacement',
          type: 'multiAutoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
        {
          label: 'Assign Date',
          field: 'assign_date',
          type: 'date',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateReplacementType = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/employeereplacements`,
    eventSourceId: 'replacement',
    ...props,
  });
  return (
    <Form
      api={'/employeereplacements/' + row?._id}
      fields={{
        type: 1,
      }}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      header="Update Type"
      layoutFields={[
        {
          label: 'Replacement Type',
          field: 'type',
          type: 'autoComplete',
          options: [
            'Negative Margin',
            'Swapping',
            'Resignation',
            'Anchor',
            'Long Leave',
          ],
          required: true,
        },
      ]}
      {...props}
    />
  );
};
