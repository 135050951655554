import { useFilter } from '@unthinkable/react-filters';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { ReplacementTable } from './ReplacementTables';

export const ReplacementTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter({});
  const tabs = {
    Due: {
      label: 'Due',
      view: (
        <ReplacementTable
          filterValues={filterValues}
          status="due"
          {...props}
          tab="Due"
        />
      ),
      api: '/employeereplacement',
      addOnFilter: {status: 'due', ...filterValues?.filter},
    },
    InProgress: {
      label: 'In Progress',
      view: (
        <ReplacementTable
          filterValues={filterValues}
          status="inprogress"
          {...props}
          tab="InProgress"
        />
      ),
      api: '/employeereplacement',
      addOnFilter: {status: 'inprogress', ...filterValues?.filter},
    },
    Completed: {
      label: 'Closed',
      view: (
        <ReplacementTable
          filterValues={filterValues}
          status="completed"
          {...props}
          tab="Completed"
        />
      ),
      api: '/employeereplacement',
      addOnFilter: {status: 'completed', ...filterValues?.filter},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Type',
              options: [
                'Bandwidth Release',
                'Negative Margin',
                'Swapping',
                'Resignation',
                'Anchor',
              ],
              field: 'type',
              placeholder: 'Select',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              field: 'assignment.resource_tool',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoComplete',
              label: 'Status',
              field: 'completed_status',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              options: ['Successful', 'Failed'],
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
