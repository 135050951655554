import React, {useEffect, useState} from 'react';
import {Row, View} from '@unthinkable/react-core-components';
import {AutoComplete} from '@unthinkable/react-autocomplete';
import {InputWrapper} from '@unthinkable/react-input';
import {NumberInput} from '@unthinkable/react-number-input';

const CurrencyInput = props => {
  const {
    error,
    amountPlaceholder = 'Amount',
    currencyPlaceholder = 'Currency',
    value = {},
    currencyOptions = [],
    onChangeValue,
    ...restProps
  } = props;
  const {styles} = props;
  const [currencyAmount, setCurrencyAmount] = useState(value);

  useEffect(() => {
    onChangeValue && onChangeValue(currencyAmount);
  }, [currencyAmount]);

  const onChangeAmount = (value, e) => {
    if (value === '') {
      setCurrencyAmount({
        ...currencyAmount,
        amount: '',
      });
      return;
    }
    if (isNaN(value)) {
      setCurrencyAmount({
        ...currencyAmount,
        amount:
          currencyAmount?.amount === undefined ? '' : currencyAmount?.amount,
      });
      return;
    }
    setCurrencyAmount({
      ...currencyAmount,
      amount: value,
    });
  };

  const onChangeCurrency = (value, e) => {
    setCurrencyAmount({
      ...currencyAmount,
      currency: value,
    });
  };

  return (
    <InputWrapper {...restProps}>
      <Row gap={12}>
        <View style={{flex: 1}}>
          <NumberInput
            {...restProps}
            styles={styles}
            placeholder={amountPlaceholder}
            value={currencyAmount.amount}
            onChangeValue={onChangeAmount}
          />
        </View>
        <View style={{flex: 1}}>
          <AutoComplete
           styles={styles}
            placeholder={currencyPlaceholder}
            options={currencyOptions}
            onChangeValue={onChangeCurrency}
            value={currencyAmount.currency}
          />
        </View>
      </Row>
    </InputWrapper>
  );
};

export default CurrencyInput;
