export const ColorThemeCardStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    deleteIcon: icons.Trash,
    UpdateIcon: icons.EstChange,
    container: {
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#EEEEED',
      height: 100,
      width: 170,
      overflow: 'hidden',
    },
    darkContainer: {
      borderColor: '#38383A',
    },
    upperPartContainer: {
      backgroundColor: '#F7F7F7',
      justifyContent: 'center',
      paddingRight: 12,
      paddingLeft: 12,
    },
    darkUpperPartContainer: {
      backgroundColor: '#262626',
    },
    colorNameTextContainer: {flex: 1, overflow: 'hidden'},
    colorNameText: {
      ...fonts.BODY2,
      color: '#19191A',
      numberOfLines: 1,
    },
    darkColorNameText: {color: '#FAFAFA'},
    colorNameCode: {...fonts.CAPTION_LARGE, color: '#9E9E9E'},
    darkColorNameCode: {color: '#7D7D82'},
  };
};
export const ColorThemeStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    mainContainer: {
      borderRadius: 20,
      borderWidth: 1,
      padding: 20,
      borderColor: '#EEEEED',
      backgroundColor: '#FFFFFF',
    },
    darkmainContainer: {
      backgroundColor: '#1A1A1A',
      borderColor: '#38383A',
    },
    container: {marginBottom: 30},
    separator: {
      height: 1,
      backgroundColor: '#EEEEED',
      marginBottom: 16,
      borderRadius: 100,
    },
    darkModeSeparator: {
      backgroundColor: '#38383A',
    },
    text: {
      ...fonts.HEADING1,
      color: '#19191A',
      marginBottom: 16,
    },
    darkModeText: {
      color: '#FAFAFA',
    },
  };
};
