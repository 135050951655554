import React from 'react';
import {useGlobalFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TextRenderer} from '../../../components/form/Renderers';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import moment from 'moment';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';

const formatNumberWithCommas = num => {
  const integerPart = Math.round(num).toString();
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, integerPart.length - 3);
  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
    (otherDigits ? ',' : '') +
    lastThreeDigits;
  return formattedIntegerPart;
};
export const DepartmentPerformanceReportTable = props => {
  const {navigation, invoice_type, filterProps} = props;

  const {filterValues = {}} = filterProps;

  const {colors} = useTheme();
  let api = '/departmentWisePerformance';
  if (invoice_type === 'Direct') {
    api = '/departmentWiseDirectReport';
  }
  return (
    <Table
      params={{...filterValues.params, invoice_type}}
      addOnFilter={filterValues.filter}
      api={api}
      limit={5000}
      columns={[
        {
          header: 'Department',
          field: 'department.name',
          type: 'text',
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          width: 150,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 0,
          },
          onPress: ({row}) => {
            if (invoice_type === 'Direct') {
              navigation.navigate('purchase-category-direct-overview', {
                ...filterValues.params,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                period: {
                  from: moment(filterValues.params.period.from)
                    .subtract(1, 'months')
                    .toDate(),
                  to: moment(filterValues.params.period.to)
                    .subtract(1, 'months')
                    .toDate(),
                },
              });
            } else if (invoice_type === 'Expense') {
              navigation.navigate('purchase-category-overview', {
                ...filterValues.params,
                invoice_type,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                period: {
                  from: moment(filterValues.params.period.from)
                    .subtract(1, 'months')
                    .toDate(),
                  to: moment(filterValues.params.period.to)
                    .subtract(1, 'months')
                    .toDate(),
                },
              });
            } else {
              navigation.navigate('purchase-category-overview-salary', {
                ...filterValues.params,
                invoice_type,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                period: {
                  from: moment(filterValues.params.period.from)
                    .subtract(1, 'months')
                    .toDate(),
                  to: moment(filterValues.params.period.to)
                    .subtract(1, 'months')
                    .toDate(),
                },
              });
            }
          },
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          width: 150,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 0,
          },
          onPress: ({row}) => {
            if (invoice_type === 'Direct') {
              navigation.navigate('purchase-category-direct-overview', {
                ...filterValues.params,
                invoice_type,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
              });
            } else if (invoice_type === 'Expense') {
              navigation.navigate('purchase-category-overview', {
                ...filterValues.params,
                invoice_type,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
              });
            } else {
              navigation.navigate('purchase-category-overview-salary', {
                ...filterValues.params,
                invoice_type,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
              });
            }
          },
        },
        {
          header: 'Difference',
          type: 'number',
          width: 200,
          field: 'expense_diff',
          aggregate: true,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                formatNumberWithCommas(Math.abs(row.expense_diff)) +'('+
                Number(Math.abs(row.expense_diff_percentage)).toFixed(2) +
                '%)'
              }
            />
          ),
          onPress: ({row}) => {
            if (invoice_type == 'Expense') {
              navigation.navigate('department-performance-details', {
                ...filterValues.params,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                invoice_type,
              });
            } else if (invoice_type == 'Direct') {
              navigation.navigate('department-overview-direct', {
                ...filterValues.params,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                invoice_type,
              });
            } else {
              navigation.navigate('department-performance-details-salary', {
                ...filterValues.params,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                invoice_type,
              });
            }
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
          align: 'right',
        },
      ]}
      {...props}
    />
  );
};

export const DepartmentPerformanceReportTabs = props => {
  const filterProps = useGlobalFilter();
  const {download} = useAppStateContext();
  return (
    <TabView
      headerComponent={<TableHeader title="Department Expense Analysis" />}
      tabs={{
        salary: {
          label: 'Salary',
          view: (
            <DepartmentPerformanceReportTable
              {...props}
              invoice_type="Salary"
              filterProps={filterProps}
              key="salary"
            />
          ),
          actions: [
            <Button
              text="Download"
              onPress={() => {
                download({
                  uri: '/downloadExpenseReport',
                  props: {
                    api: '/departmentWisePerformance',
                    params: {
                      ...filterProps.filterValues.params,
                      invoice_type: 'Salary',
                    },
                    addOnFilter: filterProps.filterValues.filter,
                    globalParams: filterProps.filterValues.params,
                  },
                });
              }}
            />,
          ],
        },
        expense: {
          label: 'Expense',
          view: (
            <DepartmentPerformanceReportTable
              {...props}
              invoice_type="Expense"
              filterProps={filterProps}
              key="expense"
            />
          ),
          actions: [
            <Button
              text="Download"
              onPress={() => {
                download({
                  uri: '/downloadExpenseReport',
                  props: {
                    api: '/departmentWisePerformance',
                    params: {
                      ...filterProps.filterValues.params,
                      invoice_type: 'Expense',
                    },
                    addOnFilter: filterProps.filterValues.filter,
                    globalParams: filterProps.filterValues.params,
                  },
                });
              }}
            />,
          ],
        },
        direct: {
          label: 'Direct',
          view: (
            <DepartmentPerformanceReportTable
              {...props}
              invoice_type="Direct"
              filterProps={filterProps}
              key="Direct"
            />
          ),
          actions: [
            <Button
              text="Download"
              onPress={() => {
                download({
                  uri: '/downloadExpenseReport',
                  props: {
                    api: '/departmentWiseDirectReport',
                    params: {
                      ...filterProps.filterValues.params,
                      invoice_type: 'Direct',
                    },
                    addOnFilter: filterProps.filterValues.filter,
                    globalParams: filterProps.filterValues.params,
                  },
                });
              }}
            />,
          ],
        },
      }}
      actions={[
        // <PeriodRangeFilter
        //   skipQuarter
        //   monthWiseBreakup={false}
        //   options={[]}
        //   {...filterProps}
        // />,
        <GroupFilter
          {...filterProps}
          filters={[
            // {
            //   type: 'multiAutoComplete',
            //   label: 'Organization',
            //   api: '/organizations',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'organization',
            // },
            // {
            //   type: 'autoComplete',
            //   label: 'Product',
            //   api: '/products',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'product',
            // },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
          ]}
        />,
      ]}
    />
  );
};
