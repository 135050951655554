import React from 'react';
import {
    GroupFilter,
    PeriodRangeFilter
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import {
    CustomPlatformOppProposalSubTable,
    ProposalSubmittedDropTable,
    ProposalSubmittedLostTable,
    ProposalSubmittedWonTable
} from './CustomPlatformOpportunityTable';
export const ProposalTabView = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );

  const {filter, params: filterParams = {}} = filterValues;
  const {product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
  };

  let tabs = {};
  tabs.active = {
    label: 'Proposal',
    view: (
      <CustomPlatformOppProposalSubTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
      />
    ),
    api: '/opportunities/proposal',
    eventSourceId: 'CustomPlatformOpportunity',
    params: {
      period: filterParams.period,
      ...params,
    },
  };
  tabs.won = {
    label: 'Won',
    view: (
      <ProposalSubmittedWonTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
      />
    ),
    api: '/opportunities/won',
    eventSourceId: 'CustomPlatformOpportunity',
    params: {
      period: filterParams.period,
      ...params,
    },
  };

  tabs.lost = {
    label: 'Lost',
    view: (
      <ProposalSubmittedLostTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
      />
    ),
    api: '/opportunities/lost',
    eventSourceId: 'CustomPlatformOpportunity',
    params: {
      period: filterParams.period,
      ...params,
    },
  };

  tabs.drop = {
    label: 'Drop',
    view: (
      <ProposalSubmittedDropTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
      />
    ),
    api: '/opportunities/drop',
    eventSourceId: 'CustomPlatformOpportunity',
    params: {
      period: filterParams.period,
      ...params,
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        // <SearchFilter
        //   value={searchValue}
        //   onChangeFilter={onChangeFilter}
        //   placeholder="Search"
        // />,
        <PeriodRangeFilter onChangeFilter={onChangeFilter} filterValues={filterValues} />,
        <GroupFilter
          filter={filter}
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },
          ]}
        />,
      ]}
    />
  );
};
