import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddNewValueProposition = props => {
  const {
    route: {params},
  } = props;
  const {product, department, row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/valuepropositions',
    eventSourceId: 'valuePropositionAdded',
    ...props,
  });
  return (
    <Form
      api={`/valuepropositions/${row?._id}`}
      fields={{
        name: 1,
        use_case: 1,
        problem: 1,
        current_way: 1,
        limitations_of_current_way: 1,
        our_prospect_capability: 1,
        our_products_feature: 1,
        benefit: 1,
        our_way: 1,
        sample: 1,
        sample_followup: 1,
        usp_id: {name: 1},
      }}
      onSubmit={onSubmit}
      defaultValues={{
        target_audience: row?._id,
        product: product?._id,
        department: department?._id,
        status: 'active',
      }}
      header={{
        title: 'Add Value Proposition',
        secondaryTitle: row?.name,
      }}
      layoutFields={[
        {
          label: 'Title',
          placeholder: 'Title',
          field: 'name',
          type: 'textArea',
        },
        {
          label: 'Unique Selling Point',
          field: 'usp_id',
          type: 'autoComplete',
          api: '/uniquesellingpoints',
          filter: {product: product?._id},
          suggestionField: 'name',
          valueField: 'name',
          size: 'medium',
        },
        {
          label: 'Use Case',
          placeholder: 'Use Case',
          field: 'use_case',
          type: 'textArea',
        },
        {
          label: 'Sample',
          placeholder: 'Sample',
          field: 'sample',
          type: 'textArea',
        },
        {
          label: 'Follow-up Sample',
          placeholder: 'Follow-up Sample',
          field: 'sample_followup',
          type: 'textArea',
        },
        {
          label: 'Current approach',
          fields: [
            {
              label: 'Current Way',
              placeholder: 'Current Way',
              field: 'current_way',
              type: 'textArea',
            },

            {
              label: 'Problem',
              placeholder: 'Problem',
              field: 'problem',
              type: 'textArea',
            },
          ],
        },
        {
          label: 'Our approach',
          fields: [
            {
              label: 'Our way',
              placeholder: 'Our way',
              field: 'our_way',
              type: 'textArea',
            },
            {
              field: 'benefit',
              type: 'textArea',
              label: 'Benefit',
              placeholder: 'Benefit',
            },
            {
              field: 'our_products_feature',
              type: 'textArea',
              label: 'Feature',
              placeholder: 'Feature',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditNewValuePropositionForm = props => {
  return (
    <AddNewValueProposition
      header={'Edit Value Proposition'}
      mode="edit"
      {...props}
    />
  );
};
