import React from 'react';

import {TabView} from '../../../components/tab/TabView';
import {AddButton} from '../../../components/button/AddButton';
import {ProjectTeamTable} from './ProjectTeamTable';

export const ProjectTeamTab = props => {
  const tabs = {
    active: {
      label: 'Active',
      view: <ProjectTeamTable key={'Active'} source={'Active'} {...props} />,
    },
    upcoming: {
      label: 'Upcoming',
      view: (
        <ProjectTeamTable key={'Upcoming'} source={'Upcoming'} {...props} />
      ),
    },
    history: {
      label: 'History',
      view: <ProjectTeamTable key={'History'} source={'History'} {...props} />,
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export const ProjectClientTab = props => {
  const {
    route: {params},
  } = props;

  const tabs = {
    active: {
      label: 'Active',
      view: <ProjectTeamTable key={'Active'} source={'Active'} {...props} />,
    },
    history: {
      label: 'History',
      view: <ProjectTeamTable key={'History'} source={'History'} {...props} />,
    },
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <AddButton
          title={'Client'}
          view={'add-project-client-member'}
          params={params}
        />,
      ]}
    />
  );
};
