import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {randomColor} from '../../../utils/util';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {TableHeader} from '../../../components/table/Headers';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Vendor',
      api: '/vendors',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'payee_id',
    },
    {
      type: 'multiAutoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'organization',
    },
    {
      type: 'autoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
  ];
};

const expenseAgeingFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'ageing',
  params,
  period,
}) => {
  return [
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
  ];
};

export const ExpenseAgeingDetails = props => {
  const {route: {params} = {}} = props;
  const {row = {}} = params;
  const {searchValue, onChangeFilter} = useFilter({});
  return (
    <Table
      search={searchValue}
      searchFields="invoice_no"
      api={`/expenseAgeingDetails`}
      addOnFilter={{
        payee_id: row?.payee_id?._id,
      }}
      renderHeader={() => (
        <TableHeader
          title="Expense Ageing Details"
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search Invoice No."
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Name',
          field: 'payee_id.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Number',
          field: 'invoice_no',
          type: 'text',
        },
        {
          header: 'Date',
          field: 'invoice_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Amount',
          field: 'payable_amount',
          type: 'currency',
        },
        {
          header: 'Pending Amount',
          field: 'base_pending_amount',
          type: 'currency',
        },
      ]}
    />
  );
};

const ExpenseAgeingTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    tab,
  } = props;
  return (
    <Table
      api={`/expenseAgeing`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      variant={'bordered'}
      params={{...params, period}}
      limit={1000}
      eventSourceId={['expenseAgeing']}
      columns={[
        {
          header: 'Vendor',
          field: 'payee_id.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: '30-90',
          field: 'limit_30_90',
          type: 'currency',
          render: CountCellRender,
          count_field: 'limit_30_90',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('expense-ageing-details', {row});
          },
        },
        {
          header: '91-270',
          field: 'limit_91_270',
          type: 'currency',
          render: CountCellRender,
          count_field: 'limit_91_270',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('expense-ageing-details', {row});
          },
        },
        {
          header: '270+',
          field: 'limit_270_setupDate',
          type: 'currency',
          render: CountCellRender,
          count_field: 'limit_270_setupDate',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('expense-ageing-details', {row});
          },
        },
        {
          header: 'Total',
          field: 'total_amount',
          width: 200,
          align: 'left',
          type: 'currency',
          aggregate: true,
          render: ({row}) => {
            let {total_amount, max_ageing} = row;
            let maxAgeing = 0;
            if (max_ageing) {
              let date1 = new Date(max_ageing);
              let date2 = new Date();
              let diffTime = Math.abs(date2 - date1);
              let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              maxAgeing = diffDays;
            }
            return (
              <PrimaryColumnCard
                primaryTitle={total_amount}
                primaryTitleColor="#FF3B30"
                items={[
                  {
                    value: `Max Ageing:${maxAgeing} Days`,
                  },
                ]}
              />
            );
          },
          onPress: ({row}) => {
            navigation.navigate('expense-ageing-details', {row});
          },
        },
      ]}
    />
  );
};

export const ExpenseAgeingTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};

  const tabs = {
    ageing: {
      label: 'Ageing',
      view: (
        <ExpenseAgeingTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
          }}
          period={period}
          tab="ageing"
        />
      ),
      actions: expenseAgeingFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'ageing',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
