import React from 'react';
import {Row, Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {resolveExp} from '@unthinkable/react-utils';

const StatusIconRender = ({iconField, row, onIconPress, disabled}) => {
  let sourceValue = resolveExp(row, iconField);

  let {columnTextStyle, greyTickIcon, greenTickIcon, iconStyle} =
    useComponentTheme('StatusIconRenderStyles');
  let completed = sourceValue === 'completed';
  let icon = completed ? greenTickIcon : greyTickIcon;

  if (typeof disabled === 'function') {
    disabled = disabled({row});
  }

  if (disabled) {
    iconStyle = {...iconStyle, opacity: 0.5};
  }

  return (
    <Row style={columnTextStyle}>
      <TouchableOpacity
        disabled={disabled}
        onPress={!completed ? () => onIconPress({row}) : void 0}>
        <Image source={icon} style={iconStyle} />
      </TouchableOpacity>
    </Row>
  );
};

export default StatusIconRender;
