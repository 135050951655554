import {Text} from '@unthinkable/react-core-components';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';

export const PurchaseOrderTable = props => {
  const {navigation, addOnFilter, tab, searchValue} = props;
  const invoke = useInvoke({
    eventSourceId: 'purchaseOrder',
    method: 'put',
  });
  const mailinvoke = useInvoke({
    eventSourceId: 'purchaseOrder',
    method: 'post',
  });
  const {download} = useAppStateContext();
  const fetchProps = {
    search: searchValue,
    searchFields: ['name'],
    addOnFilter : addOnFilter
  };
  return (
    <Table
      eventSourceId={['purchaseOrder']}
      search={searchValue}
      searchFields="po_no"
      api={`/purchaseorder/all`}
      addOnFilter={addOnFilter}
      onRowPress={({row}) => {
        if (tab === 'Processed' || tab === 'Approved') {
          navigation.navigate('edit-purchase-order', {row});
        } else {
          navigation.navigate('edit-purchase-order', {row, readOnly: true});
        }
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/purchaseorder/all',
                    },
                    column: {
                      'Supplier Name': 'payee_id.name',
                      'PO Number': 'po_no',
                      'PO Date': 'po_date',
                      Description: 'purpose',
                      'Approver': 'approver.name',
                      'Approved On' : 'approved_on'
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Supplier Name',
          field: 'payee_id.name',
          type: 'text',
          minWidth: 150,
        },
        {
          field: 'po_no',
          header: 'PO Number',
          type: 'text',
          width: 250,
        },
        {
          header: 'PO Date',
          field: 'po_date',
          type: 'date',
          width: 250,
        },
        {
          header: 'Description',
          field: 'purpose',
          type: 'text',
          width: 250,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Approved On',
          field: 'approved_on',
          type: 'date',
          width: 150,
          visible: ({value}) => {
            if (tab === 'Approved') return true;
          },
        },
        {
          header: 'Invoice',
          visible: ({values}) => {
            if (tab === 'Approved') return true;
          },
          align: 'center',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#007AFF'}}>Create</Text>
            );
          },
          onPress: ({row}) => {
            navigation.navigate('add-vendor-invoice', {
              row: {
                // payment_type: row.mode_of_payment,
                payee_id: row.payee_id,
                purpose: row.purpose,
                // bill_number: row.bill_number,
                // bill_date: row.bill_date,
                document: row.document,
                approved_mail: row.approved_mail,
                hsn_number: row.hsn_number,
                hsn_description: row.hsn_description,
                team: row.team,
                expense_approved_by: row.approver,
                organization: row.organization,
                product: row.product,
                exp_details: row.exp_details,
                location: row.location_id,
                approved_amount: row.payable_amount,
                status: 'Approved',
                // accrued_type: row.expense_across,
                // schedule_from_date: row.provision_from_date,
                // schedule_to_date: row.provision_to_date,
                purchase_order_id: row._id,
              },
              expense_view: true,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Approve',
          visible: ({value}) => {
            if (tab === 'Processed') return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/purchaseorders/${row._id}`,
              props: {
                is_approved: true,
                approved_on: new Date(),
              },
            });
          },
        },
        {
          title: 'Print Po',
          onPress: ({row}) => {
            download({
              uri: `/purchaseorder/print/${row._id}`,
            });
          },
        },
        {
          title: 'Send Mail To Vendor',
          visible: ({value}) => {
            if (tab === 'Approved') return true;
          },
          onPress: ({row}) => {
            mailinvoke({
              uri: `/sendMailToVendor`,
              props: {
                ...row,
              },
            });
          },
        },
      ]}
    />
  );
};

export const PurchaseOrderTableDepartment = props => {
  const {navigation, addOnFilter, tab, searchValue} = props;
  const invoke = useInvoke({
    eventSourceId: 'purchaseOrder',
    method: 'put',
  });
  const mailinvoke = useInvoke({
    eventSourceId: 'purchaseOrder',
    method: 'post',
  });
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      eventSourceId={['purchaseOrder']}
      search={searchValue}
      searchFields="po_no"
      api={`/purchaseorder/all`}
      addOnFilter={addOnFilter}
      onRowPress={({row}) => {
        if (tab === 'Processed' || tab === 'Approved') {
          navigation.navigate('edit-purchase-order', {row});
        } else {
          navigation.navigate('edit-purchase-order', {row, readOnly: true});
        }
      }}
      columns={[
        {
          header: 'Supplier Name',
          field: 'payee_id.name',
          type: 'text',
          minWidth: 150,
        },
        {
          field: 'po_no',
          header: 'PO Number',
          type: 'text',
          width: 250,
        },
        {
          header: 'PO Date',
          field: 'po_date',
          type: 'date',
          width: 250,
        },
        {
          header: 'Description',
          field: 'purpose',
          type: 'text',
          width: 250,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Approved On',
          field: 'approved_on',
          type: 'date',
          width: 150,
          // visible: ({value}) => {
          //   if (tab === 'Approved') return true;
          // },
        },
      ]}
      moreActions={[
        {
          title: 'Print Po',
          onPress: ({row}) => {
            download({
              uri: `/purchaseorder/print/${row._id}`,
            });
          },
        },
      ]}
    />
  );
};
