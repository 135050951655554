import {
  View,
  TouchableOpacity,
  Image,
  TextInput,
  Styled,
  Text,
  FlatList,
  Row,
} from '@unthinkable/react-core-components';
import CreateNewIcon from '../images/Plus_Green.svg';
import ArrowDownIcon from '../images/CaretDown.svg';
import CrossIcon from '../images/ChipCross.svg';

export const CreateIconContainer = Styled(TouchableOpacity)`
  height: 24px;
  width: 24px;
  background-color: #F3FEF0;
  border-radius: 46px;
  justify-content: center;
  align-items: center;
  ${({styles}) => styles?.createIconContainer}
`;

export const CreateIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.createNewIcon || CreateNewIcon,
}))`
  height: 12px; 
  width: 12px;
  ${({styles}) => styles?.createIcon}
`;

export const CrossIconContainer = Styled(TouchableOpacity)`
  padding:8px;
  margin:-8px;
  ${({styles}) => styles?.crossContainer}
`;

export const CrossIconImage = Styled(Image).attrs(props => ({
  source: props?.styles?.crossIcon || CrossIcon,
}))`
  height: 16px;
  width: 16px;
  ${({styles}) => styles?.crossIconStyle}
`;

export const SelectorContainer = Styled(View)`
  padding:8px;
  margin:-8px;
  ${({styles}) => styles?.selectorContainer}
`;

export const SelectorIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.selectorIcon || ArrowDownIcon,
}))`
  height: 16px;
  width: 16px;
`;

export const HeaderContainer = Styled(View)`
  background-color: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #EEEEED;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden;
  ${({styles}) => styles?.headerContainer}
`;

export const HeaderText = Styled(Text)`
  font-size: 13px;
  font-family: Inter-Italic;
  line-height: 16px;
  color: #7D7D82;
  ${({styles}) => styles?.headerTitle}
`;

export const DropDownList = Styled(FlatList)`
  ${({styles}) => styles?.dropdownList}
`;

export const DropdownInputContainer = Styled(View)`
  padding: 8px 12px;
  border-bottom: 1px solid #EEEEED;
  ${({styles}) => styles?.dropdownInputContainer}
`;

export const DropdownInput = Styled(TextInput)`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  border: 1px solid #EEEEED;
  ${({styles}) => styles?.dropdownInput}
`;

export const DropdownSearchIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.inputImage,
}))`
  height: 16px;
  width: 16px;
  ${({styles}) => styles?.inputImageStyle}
`;

export const RowContainer = Styled(Row)`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #EEEEED;
  align-items: center;
  ${({styles}) => styles?.rowContainer}
  ${({backgroundColor}) => (backgroundColor ? {backgroundColor} : void 0)}
  ${props => `
  &:hover {
    background-color: ${props?.styles?.rowContainerHoverColor};
   `}
  }
`;

export const RowContent = Styled(View)`
  flex: 1;
  overflow: hidden;
  ${({styles}) => styles?.rowContent}
`;

export const RowPrimaryText = Styled(Text)`
  font-size: 14px;
  font-family: Inter-Regular;
  line-height: 20px;
  color: #19191A;
  text-align-vertical: center;
  ${({styles}) => styles?.rowText}
  ${({color}) => (color ? {color} : void 0)}

`;

export const RowSecondaryText = Styled(Text)`
  font-size: 12px;
  font-family: Inter-Regular;
  line-height: 16px;
  color: #7D7D82;
  text-align-vertical: center;
  ${({styles}) => styles?.rowSecondaryText}
  ${({color}) => (color ? {color} : void 0)}
`;

export const CascadingIcon = Styled(Image)`
  ${({styles}) => styles?.cascadingIcon}
`;

export const RowSeparator = Styled(View)`
  height: 1px;
  background-color: #EEEEED;
  ${({styles}) => styles?.separator}
`;

export const RowCreateContainer = Styled(TouchableOpacity)`
  flex-direction: row;
  background-color: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #EEEEED;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  ${({styles}) => styles?.rowCreateContainer}
`;

export const RowCreateLabel = Styled(Text)`
  font-size: 15px;
  font-family: Inter-Medium;
  color: #7D7D82;
  padding-right: 8px;
  ${({styles}) => styles?.rowCreateLabel}
`;

export const RowCreateValue = Styled(Text)`
  font-size: 13px;
  font-family: Inter-Italic;
  line-height: 16px;
  color: #7D7D82;
  flex: 1;
  overflow: hidden;
  ${({styles}) => styles?.rowCreateText}
`;

export const ChipContainer = Styled(View)`
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #E3E8FC;
  border-radius: 100px;
  ${({styles}) => styles?.chipContainer}
  ${({color}) => (color ? {backgroundColor: color} : void 0)}
`;

export const ChipLabel = Styled(Text)`
  font-size: 12px;
  font-family: 'Inter-Regular';
  line-height: 16px;
  color: #19191A;
  ${({styles}) => styles?.chipText}
  ${({color}) => (color ? {color} : void 0)}
`;

export const ChipIconContainer = Styled(TouchableOpacity)`
  cursor: pointer;
  padding-left: 4px;
  ${({styles}) => styles?.crossContainer}
`;

export const ChipIcon = Styled(Image)`
  ${({styles}) => styles?.crossIcon}
`;

export const CascadeHeaderRow = Styled(Row).attrs(props => {
  return {gap: props.styles?.cascadeHeaderRowGap || 8};
})`
  padding: 10px 0px;
  border-bottom-width: 1px;
  border-bottom-color: #EEEEED;
  align-items: center;
  ${({styles}) => styles?.cascadeHeaderContainer}
`;

export const CascadeBackIconContainer = Styled(TouchableOpacity)`
  height: 26px;
  width: 26px;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F7;
  border-radius: 6px;
  ${({styles}) => styles?.cascadeBackIconContainer}
`;

export const CascadeBackIcon = Styled(Image)`
  height: 20px;
  ${({styles}) => styles?.cascadeBackIcon}
`;

export const CascadeHeaderText = Styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: #19191A;
  flex: 1;
  ${({styles}) => styles?.cascadeHeaderText}

`;
