import {useToast} from '@unthinkable/react-toast';
import {useAppStateContext} from '../../../providers/AppState';

export const useAuthenticate = ({loginApi, googleLoginApi, appleLoginApi}) => {
  const {post, setUser} = useAppStateContext();
  const toast = useToast();
  const onSubmit = async ({uri, props}, e) => {
    try {
      const {data: result} = await post({uri, props, method: 'post'});
      setUser(result);
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  const onLogin = data => {
    return onSubmit({uri: loginApi, props: data});
  };

  const onGoogleLogin = data => {

    // make origin from  "window.location"   | Adit 19/01/24 
    let origin = window.location.origin;

    return onSubmit({
      uri: googleLoginApi,
      props: {...data, origin},
    });
  };

  const onAppleLogin = data => {
    return onSubmit({uri: appleLoginApi, props: data});
  };

  return {
    onLogin,
    onGoogleLogin,
    onAppleLogin,
  };
};

export const useResetPassword = ({
  forgotPasswordApi,
  verifyOtpApi,
  resetForgotPasswordApi,
}) => {
  const {post} = useAppStateContext();
  const toast = useToast();
  const onSubmit = async ({uri, props, method}, e) => {
    try {
      const {data: result} = await post({uri, props, method});
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  const onForgotPassword = data => {
    return onSubmit({uri: forgotPasswordApi, props: data, method: 'post'});
  };

  const onVerifyOtp = data => {
    return onSubmit({uri: verifyOtpApi, props: data, method: 'post'});
  };

  const onResetForgotPassword = data => {
    return onSubmit({uri: resetForgotPasswordApi, props: data, method: 'post'});
  };

  return {
    onForgotPassword,
    onVerifyOtp,
    onResetForgotPassword,
  };
};
