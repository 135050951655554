import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const UserSetupForm = props => {
  const {
    route: {params = {}},
  } = props;

  let {
    row: {_id},
  } = params;
  const {onSubmit} = useFormSubmit({
    uri: '/users',
    eventSourceId: 'usersetup',
    ...props,
  });

  return (
    <Form
      api={`/users/${_id}`}
      fields={{
        name: 1,
        password: 1,
        email: 1,
        mobile: 1,
        employee: {name: 1},
        externalUser: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          editable: false,
        },
        {
          label: 'Email',
          type: 'text',
          field: 'email',
          // editable: false,
        },
        {
          label: 'Phone number',
          type: 'text',
          field: 'mobile',
        },
        {
          label: 'Password',
          type: 'text',
          field: 'password',
        },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          // required: true,
          // size: 6,
        },
        // {
        //   size: 6,
        //   field: 'externalUser',
        //   type: 'checkbox',
        //   label: 'External user',
        // },
      ]}
      {...props}
    />
  );
};

export const AddUserSetupFormHOC = props => {
  const {
    route: {params = {}},
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/users',
    eventSourceId: 'usersetup',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        password:
          'ef797c8118f02dfb649607dd5d3f8c7623048c9c063d532cc95c5ed7a898a64f',
        userType: 'employee',
        status: 'a',
      }}
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          // editable: false,
        },
        {
          label: 'Email',
          type: 'text',
          field: 'email',
          // editable: false,
        },
        {
          label: 'Phone number',
          type: 'text',
          field: 'mobile',
        },
        // {
        //   label: 'Password',
        //   type: 'text',
        //   field: 'password',
        // },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          // required: true,
          // size: 6,
        },
        // {
        //   size: 6,
        //   field: 'externalUser',
        //   type: 'checkbox',
        //   label: 'External user',
        // },
      ]}
      {...props}
    />
  );
};

export const UpdateUserSetupForm = props => {
  return <UserSetupForm mode="edit" header="Update Password" {...props} />;
};

export const AddUserSetupForm = props => {
  return <AddUserSetupFormHOC header="Add user" {...props} />;
};
