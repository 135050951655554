import {InlineForm} from '../../../components/form/Form';
import {AttachmentCountRender} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import AddCountCellRender from '../../../app-components/renders/AddCountCellRender';
import {PROJECT_BUG_TYPE_ID} from '../../common/constants/SourceConstants';
import {BugStatusChangeRender} from '../cellrenders/BugStatusChangeRender';
import {getTime} from '../utility';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useBugActions} from '../actions/BugActions';
import {Col, Text} from '@unthinkable/react-core-components';
import {ClosedByBugTableStyle} from '../cellrenders/theme';
import {useStyles} from '@unthinkable/react-theme';
import {CommentMoreAction} from '../../../app-components/renders/CommentRender';

const inlineForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['Bug', 'Task'],
    uri: '/projectbugs',
    ...props,
  });

  const {project, module, feature} = params;

  const defaultValues = {
    status: 'Open',
    project_id: project?._id,
    module_id: module?._id,
    feature_id: feature?._id,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Bug',
          field: 'bug',
          type: 'text',
          required: true,
        },
        {
          placeholder: 'Upload',
          field: 'file',
          type: 'file',
          width: 250,
          multiple: true,
          inline: true,
        },
        {
          label: 'Owner',
          field: 'owner_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          width: 250,
        },
      ]}
    />
  );
};

const tasks = ({params, navigation, feature_id}) => {
  return {
    render: AddCountCellRender,
    width: 100,
    header: 'Tasks',
    align: 'center',
    totalCountField: 'feature_task_count',
    completedCountField: 'feature_completed_task_count',
    onPress: ({row}) => {
      navigation.navigate(`feature-tasks-list`, {
        ...params,
        source: PROJECT_BUG_TYPE_ID,
        source_id: row?._id,
        feature_id: feature_id,
        row,
        title: row?.bug,
        bug: {_id: row._id, bug: row.bug},
      });
    },
  };
};

const bugColumn = {
  header: 'Bug',
  responsive: 'sm',
  render: ({row}) => {
    let {createdAt, bug, code, created_by} = row;
    return (
      <PrimaryColumnCard
        primaryTitle={bug}
        items={[
          {
            value: code,
          },
          {
            value: created_by ? `By : ${created_by?.name}` : void 0,
          },
          {
            value: createdAt ? `Created on ${getTime(createdAt)}` : void 0,
          },
        ]}
      />
    );
  },
};

const StatusChangeAction = props => ({
  render: ({row}) => {
    const {columnStyle, textStyle, reviewTextStyle} = useStyles(
      ClosedByBugTableStyle,
    );

    return (
      <Col style={columnStyle} gap={8}>
        <BugStatusChangeRender row={row} {...props} />
        {row?.closed_by?.name ? (
          <Text numberOfLines={1} style={[textStyle, reviewTextStyle]}>
            By: {row.closed_by.name}
          </Text>
        ) : null}
      </Col>
    );
  },
  header: 'Status',
  align: 'center',
  width: 150,
});

const attachmentsCount = {
  type: 'file',
  field: 'file',
  header: 'References',
  render: AttachmentCountRender,
  width: 100,
};

const bugCommentColumn = ({navigation, params}) => {
  return CommentMoreAction({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_BUG_TYPE_ID, label: 'Bug'},
      comment_source_id: {_id: row._id, name: row.bug},
    }),
  });
};

const markResolved = ({navigation, params}) => ({
  title: 'Mark resolved',
  onPress: ({row}) => {
    navigation.navigate('add-bug-comment', {
      ...params,
      row,
      status: 'Resolved',
    });
  },
  visible: ({row: {status}}) => status === 'Open' || status === 'Failed',
});

const markIgnored = ({navigation, params}) => ({
  title: 'Mark Ignored',
  onPress: ({row}) => {
    navigation.navigate('add-bug-comment', {
      ...params,
      row,
      status: 'Ignored',
    });
  },
  visible: ({row: {status}}) => status === 'Open',
});

const deleted = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message: 'Are you sure you want to Delete?',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/projectbugs/${row?._id}`,
      props: {},
    });
  },
});

const edit = ({navigation, params, readOnly}) => ({
  title: readOnly ? 'Details' : 'Edit',
  onPress: props => {
    const {row} = props;
    navigation.navigate('bug-detail', {
      ...params,
      bug: {_id: row._id},
      readOnly,
    });
  },
});

const totalEstHrs = {
  header: 'Est.',
  field: 'assignees_est_hours',
  type: 'duration',
  width: 100,
  align: 'right',
};

const totalAssignees = {
  header: 'Assignees',
  field: 'assignees',
  type: 'userAvatarGroup',
  width: 120,
  align: 'center',
  maxAvatars: 3,
};

const FeatureBugsTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  const {feature} = params;

  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Bug'});
  const invoke = useInvoke({method: 'put', eventSourceId: 'Bug'});
  const {bugActivityLog} = useBugActions({navigation, params});
  return (
    <>
      {inlineForm(props)}
      <Table
        search={searchValue}
        searchFields={['bug']}
        api={`/projectbugs/open`}
        eventSourceId={['Bug', 'Task']}
        params={{
          feature_id: feature._id,
        }}
        draggable={{
          sort: 'desc',
          updateIndexOnDragEnd: ({updatedIndex, row}) => {
            invoke({
              uri: `/projectbugs/${row?._id}`,
              props: {
                index: updatedIndex,
              },
            });
          },
        }}
        columns={[
          bugColumn,
          attachmentsCount,
          tasks({
            params,
            navigation,
            feature_id: feature?._id,
          }),
          totalEstHrs,
          totalAssignees,
          StatusChangeAction({navigation, params, invoke}),
        ]}
        moreActions={() => [
          edit({navigation, params}),
          markResolved({navigation, params}),
          markIgnored({navigation, params}),
          bugCommentColumn({navigation, params}),
          {
            title: 'Activity Logs',
            onPress: bugActivityLog,
          },
          deleted({deleteInvoke}),
        ]}
      />
    </>
  );
};

const ClosedFeatureBugsTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  const {feature} = params;

  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Bug'});
  const {bugActivityLog} = useBugActions({navigation, params});

  return (
    <>
      <Table
        eventSourceId={['Bug', 'Task']}
        api={`/projectbugs/closed`}
        params={{
          feature_id: feature._id,
        }}
        search={searchValue}
        searchFields={['bug']}
        columns={[
          bugColumn,
          tasks({
            params,
            navigation,
            feature_id: feature?._id,
          }),
          attachmentsCount,
          totalEstHrs,
          totalAssignees,
          StatusChangeAction({navigation, params, readOnly: true}),
        ]}
        moreActions={() => [
          edit({navigation, params, readOnly: true}),
          bugCommentColumn({navigation, params}),
          {
            title: 'Activity Logs',
            onPress: bugActivityLog,
          },
          deleted({deleteInvoke}),
        ]}
      />
    </>
  );
};

export {FeatureBugsTable, ClosedFeatureBugsTable};
