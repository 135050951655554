import React from 'react';
import {Row, Text} from '@unthinkable/react-core-components';
import {LineSeparator} from '@unthinkable/react-layout';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';

export const EmailCampaignHeader = props => {
  const {
    route: {params},
  } = props;
  return (
    <NavHeader
      title="Email Campaign"
      {...props}
      actions={[
        <AddButton
          title="Email Campaign"
          view="add-emailcampaign"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

const RendersPositivePercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.positive_result_percentage?.toFixed(2)}
      </Text>
      {row?.positive_result_percentage ||
      row?.overall_positive_result_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_positive_result_percentage
          ? row?.overall_positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

export const EmailCampaignTableBeta = props => {
  let {
    route: {params},
    filterParams,
    searchValue,
    filter,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64d0fd55ab78dfead48c144a',
      label: row?.name,
    });
  };
  const {
    emailMessages,
    uqls,
    preuqls,
    qualifiedLeads,
    prequalifiedLeads,
    CampaignProspectOnClickLeadLifecycle,
    CampaignProspectOnClickMessageSend,
    editEmailCampaign,
    CampaignProspectOnClickLifecycleCompleted,
    onPressLeadsInCycle,
    CampaignProspectOnClickQLInCycle,
  } = leadControllers({...props, params: {...params, fromEmailCampaign: true}});
  return (
    <Table
      eventSourceId={['outreachAdded', 'emailCampaignAdded', 'Task']}
      onRowPress={emailMessages}
      api={`/emails-campaign`}
      params={params}
      addOnFilter={filter}
      search={searchValue}
      limit={1000}
      searchFields={'name'}
      variant={'bordered'}
      columns={[
        {
          field: 'name',
          header: 'Name',
          width: 250,
        },
        {
          width: 80,
          field: 'assigned_to',
          header: 'Owner',
          type: 'userAvatar',
        },
        // {
        //   render: CountCellRender,
        //   width: 120,
        //   header: 'Outreach Contents',
        //   align: 'right',
        //   count_field: 'campaign_message_count',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`add-outreach-content`, {
        //       ...params,
        //       row,
        //       campaign_type: '64d0fd55ab78dfead48c144a',
        //     });
        //   },
        //   onPress: emailMessages,
        // },
        // taskRender({params, navigation, showTasks}),
        // {
        //   type: 'number',
        //   field: 'prospect_count',
        //   header: 'Prospect',
        //   aggregate: true,
        //   onPress: prospectsCountForCampaign,
        // },
        {
          header: 'Prospect To Lead Cycle',
          children: [
            {
              type: 'number',
              field: 'lifecycle_completed',
              header: 'Leads',
              onPress: CampaignProspectOnClickLifecycleCompleted,
              aggregate: true,
            },
            {
              type: 'number',
              field: 'message_send_count',
              header: 'Message Sent',
              aggregate: true,
              onPress: CampaignProspectOnClickMessageSend,
            },

            {
              type: 'number',
              field: 'new_positive_result_percentage',
              // render: RendersPositivePercentage,
              header: 'Conversion %',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              onPress: onPressLeadsInCycle,
              width: 110,
            },
          ],
        },
        // {
        //   type: 'number',
        //   field: 'uql_in_cycle',
        //   header: 'Leads in cycle',
        //   aggregate: true,
        //   onPress: onPressLeadsInCycle,
        // },

        // {
        //   type: 'number',
        //   field: 'lead_count',
        //   compareField: 'previous_lead_count',
        //   header: 'Lead',
        //   aggregate: {
        //     render: AggregateComparisonRender,
        //   },
        //   onPress: uqls,
        //   onComparePress: preuqls,
        //   render: ComparisonRender,
        //   width: 110,
        // },
        {
          header: 'Lead To QL Cycle',
          children: [
            {
              type: 'number',
              field: 'expected_ql_count',
              header: 'Leads',
              width: 110,
              onPress: CampaignProspectOnClickLeadLifecycle,
              aggregate: true,
            },
            {
              type: 'number',
              field: 'ql_conversion_rate',
              header: 'Conversion %',
              width: 110,
              formatOptions: {
                maximumFractionDigits: 2,
              },
              onPress: CampaignProspectOnClickQLInCycle,
            },
          ],
        },
        // {
        //   type: 'number',
        //   field: 'leads_in_cycle',
        //   header: 'QL in cycle',
        //   width: 110,
        //   aggregate: true,
        // },
        // {
        //   type: 'number',
        //   field: 'lead_count',
        //   compareField: 'previous_lead_count',
        //   header: 'Lead',
        //   // aggregate: {
        //   //   render: AggregateComparisonRender,
        //   // },
        //   aggregate: true,
        //   onPress: uqls,
        //   onComparePress: preuqls,
        //   // render: ComparisonRender,
        //   width: 110,
        // },
        // {
        //   type: 'number',
        //   field: 'ql_count',
        //   compareField: 'previous_ql_count',
        //   header: 'QL',
        //   aggregate: true,
        //   // aggregate: {
        //   //   render: AggregateComparisonRender,
        //   // },
        //   onPress: qualifiedLeads,
        //   onComparePress: prequalifiedLeads,
        //   // render: ComparisonRender,
        //   width: 110,
        // },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editEmailCampaign,
        },
        {
          title: 'Show Tasks',
          onPress: showTasks,
          navigation,
          params,
        },
      ]}
    />
  );
};
