import {Col, Image, View} from '@unthinkable/react-core-components';
import React from 'react';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import images from '../../../images';

const {GreenBackGroundAdd} = images;

export const unMarkStatus = ({invoke}) => ({
  title: 'Un-Mark Status',
  confirm: {
    title: 'Un-Mark Status',
    message: 'Do you want un-mark the status of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employeereplacements/${row?._id}`,
      props: {
        status: 'unmarked',
        is_anchor_resource: null,
        target_release_date: null,
        reason: null,
      },
    });
  },
});

export const ReplacementTable = props => {
  const {navigation, filterValues, status, tab} = props;
  const {filter = {}} = filterValues || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'replacement',
    throwError: true,
  });
  return (
    <Table
      {...props}
      eventSourceId={['replacement']}
      api={`/employeereplacement`}
      addOnFilter={{...filter, status: status}}
      columns={[
        {
          header: 'Since',
          field: 'created_on',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
          width: 120,
          visible: ({}) => {
            if (tab === 'completed') {
              return false;
            } else return true;
          },
        },
        {
          header: 'Project',
          type: 'text',
          minWidth: 220,
          render: ({row}) => {
            const {project_id = {}} = row;
            let type = '';
            if (project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            if (row.hours) {
              return (
                <PrimaryColumnCard
                  primaryTitle={project_id?.project}
                  items={[
                    {
                      value: type,
                    },
                    {
                      value: row?.project_id?.customer?.name,
                    },
                  ]}
                />
              );
            } else {
              return '';
            }
          },
        },
        {
          header: 'Req Skill',
          field: 'assignment.resource_tool.name',
          minWidth: 150,
        },
        {
          header: 'Skill & Effort',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.employee?.resource_language?.name}
                items={[
                  {
                    value: `${row?.hours}h/day`,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'To Replace',
          minWidth: 180,
          render: ({row}) => {
            return (
              <Col style={{flexDirection: 'row'}} gap={10}>
                <UserAvatar value={row.employee} size={32} />
                <PrimaryColumnCard
                  primaryTitle={row?.employee.name}
                  items={[
                    {
                      value: row?.employee.employee_code,
                    },
                    {
                      value: `${row?.employee.experience} yr`,
                    },
                  ]}
                />
              </Col>
            );
          },
        },
        {
          header: 'Reason',
          field: 'type',
          type: 'colorTag',
          width: 100,
          visible: ({}) => {
            if (tab !== 'Completed') {
              return true;
            }
          },
        },
        {
          header: 'Replacement',
          minWidth: 160,
          render: ({row}) => {
            return row.replacement ? (
              <Col style={{flexDirection: 'row'}} gap={10}>
                <UserAvatar value={row.replacement} size={32} />
                <PrimaryColumnCard
                  primaryTitle={row?.replacement.name}
                  items={[
                    {
                      value: row?.replacement.employee_code,
                    },
                    {
                      value: `${row?.replacement.experience} yr`,
                    },
                  ]}
                />
              </Col>
            ) : (
              <Image
                source={GreenBackGroundAdd}
                style={{width: 36, height: 24}}
              />
            );
          },
          onPress: ({row}) => {
            if (row.status != 'completed')
              navigation.navigate(
                // row?.type === 'Anchor'
                //   ? 'identify-swapping'
                //   : 'update-employee-as-replacement',
                'update-employee-as-replacement',
                {
                  // row:
                  //   row?.type === 'Anchor'
                  //     ? {
                  //         ...row,
                  //         name: row?.employee?.name,
                  //         _id: row?.employee?._id,
                  //       }
                  //     : {
                  //         ...row,
                  //       },
                  row,
                  formResign: true,
                  replacement_id: row._id,
                },
              );
          },
        },
        {
          header: 'Target Date',
          field: 'target_release_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
          width: 120,
          visible: ({}) => {
            if (tab === 'InProgress') {
              return true;
            }
          },
        },
        {
          header: 'Closed On',
          field: 'completed_on',
          type: 'date',
          width: 100,
          visible: ({}) => {
            if (tab === 'Completed') {
              return true;
            }
          },
        },
        {
          header: 'Status',
          field: 'completed_status',
          type: 'text',
          width: 100,
          visible: ({}) => {
            if (tab === 'Completed') {
              return true;
            }
          },
        },
        // {
        //   header: 'Owner',
        //   field: 'owner_id',
        //   align: 'center',
        //   type: 'userAvatar',
        //   width: 60,
        // },
      ]}
      moreActions={() => [
        {
          title: 'Mark Complete',
          visible: ({row}) => {
            if (row.status === 'inprogress' || row.status === 'due') {
              return true;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('update-replacement-status', {row});
          },
        },
        unMarkStatus({invoke}),
      ]}
    />
  );
};
