import React from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {
  ProjectTabsAm,
  PendingSDLCProjectTabsAm,
  ProjectEfficiencyTabAM,
} from '../../pmt/views/ProjectTabsForAm';
import {AllocationPendingForAMTab} from './AllocationPendingForAM';
import {OrdersAndDeliveriesForFixedTabForAM} from '../../order/views/OrdersAndDeliveriesForFixed';
import {OrdersAndDeliveriesForTAndMTabForAM} from '../../order/views/OrdersAndDeliveriesForTAndM';
import {NewProjectTab} from './NewProjectTab';
import {PendingProjectApprovalsTableAm} from '../../pmt/views/PendingProjectApprovals';
import {DeliveredProjectResourceWorkingTab} from './DeliveredProjectResourceWorking';

const ProjectGroup = createMenuNavigator();
export const organizationProjectNavigatorAM = props => {
  const {
    route: {params},
  } = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const {employee} = user;
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <ProjectGroup.Navigator
      // canAccess={canAccess}
      data-navigator-id="ProjectGroup"
      {...props}>
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledNew"
        name="New"
        props={{
          user: user,
          addOnParamFilter: {
            account_owner: employee?._id,
          },
          ...props,
        }}
        component={NewProjectTab}
        options={{
          label: 'New',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectActive"
        name="Unassigned"
        props={{
          user: user,
          project_status: 'a',
          is_project_assign: {$in: [null, false]},
          tab: 'Unassigned',
          ...props,
        }}
        component={ProjectTabsAm}
        options={{
          label: 'Unassigned',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectActive"
        name="Assigned"
        props={{
          user: user,
          project_status: 'a',
          tab: 'Assigned',
          is_project_assign: true,
          addOnParamFilter: {
            owner_id: employee?._id,
          },
          ...props,
        }}
        component={ProjectTabsAm}
        options={{
          label: 'Assigned',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectHold"
        name="On-Hold"
        props={{project_status: 'h', tab: 'On-hold'}}
        component={ProjectTabsAm}
        options={{
          label: 'On-Hold',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectCompleted"
        name="Completed"
        props={{project_status: 'c', tab: 'Completed'}}
        component={ProjectTabsAm}
        options={{
          label: 'Closed',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectPendingApprovals"
        name="Pending Approvals"
        props={{user: user, project_status: 'a', tab: 'Active', ...props}}
        component={PendingProjectApprovalsTableAm}
        options={{
          label: 'Pending Approvals',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="PendingSDLCPlusProject"
        name="Pending Milestone"
        props={{user: user, project_status: 'a', tab: 'Active', ...props}}
        component={PendingSDLCProjectTabsAm}
        options={{
          label: 'Pending Milestone',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="AllocationPending"
        name="Allocation Pending"
        props={{user: user, ...props}}
        component={AllocationPendingForAMTab}
        options={{
          label: 'Allocation Pending',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="OrderAndDeliveriesT&M"
        name="T&M"
        component={OrdersAndDeliveriesForTAndMTabForAM}
        options={{
          label: 'T&M Output(AM)',
        }}
        props={{user: user, ...props}}
      />
      <ProjectGroup.Screen
        data-resource-id="OrderAndDeliveriesFixed"
        name="Fixed"
        component={OrdersAndDeliveriesForFixedTabForAM}
        options={{
          label: 'Fixed Output(AM)',
        }}
        props={{user: user, ...props}}
      />
      <ProjectGroup.Screen
        data-resource-id="DeliveredProjectResourceWorking"
        name="Delivered"
        component={DeliveredProjectResourceWorkingTab}
        options={{
          label: 'Delivered Project Resource Working(AM)',
        }}
        props={{user: user, ...props}}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectsEfficiencyReport"
        name="Project Billing Efficiency"
        component={ProjectEfficiencyTabAM}
        props={{user: user}}
        options={{
          label: 'Project Billing Efficiency',
        }}
      />
    </ProjectGroup.Navigator>
  );
};
