import { createMenuNavigator } from "../../../components/menu/MenuNavigator";
import { useAppStateContext } from "../../../providers/AppState";
import { ESIReportTab } from "../../vendor/view/ESIReport";
import { EmployeePackageTab } from "../../vendor/view/EmployeePackage";
import { FoodCouponReportTab } from "../../vendor/view/FoodCoupanReport";
import { LWFReportTab } from "../../vendor/view/LWFReport";
import { NPSReportTab } from "../../vendor/view/NPSReport";
import { PFReportTab } from "../../vendor/view/PFReport";
import { BulkForm16Tab } from "./BulkForm16Upload";
import { EmployeeForm16Tab } from "./EmployeeForm16";
import { SalarySheetTab } from "./SalarySheetTab";

const EmployeeReports = createMenuNavigator();

export const EmployeeReportNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  return (
    <EmployeeReports.Navigator
      data-navigator-id="HrReportsGroup"
      {...props}>
      <EmployeeReports.Screen
        data-resource-id="SalarySheet"
        name="Salary Sheet"
        props={{user: user, ...props}}
        component={SalarySheetTab}
        options={{
          label: 'Salary Sheet',
        }}
      />
      <EmployeeReports.Screen
        data-resource-id="PFReport"
        name="PF Report"
        props={{user: user, ...props}}
        component={PFReportTab}
        options={{
          label: 'PF Report',
        }}
      />
      <EmployeeReports.Screen
        data-resource-id="LWFReport"
        name="LWF Report"
        props={{user: user, ...props}}
        component={LWFReportTab}
        options={{
          label: 'LWF Report',
        }}
      />
       <EmployeeReports.Screen
        data-resource-id="foodcouponreport"
        name="Food Coupon"
        props={{user: user, ...props}}
        component={FoodCouponReportTab}
        options={{
          label: 'Food Coupon',
        }}
      />
      <EmployeeReports.Screen
        data-resource-id="ESIReport"
        name="ESI Report"
        props={{user: user, ...props}}
        component={ESIReportTab}
        options={{
          label: 'ESI Report',
        }}
      />
      <EmployeeReports.Screen
        data-resource-id="NPSReport"
        name="NPS Report"
        props={{user: user, ...props}}
        component={NPSReportTab}
        options={{
          label: 'NPS Report',
        }}
      />
       <EmployeeReports.Screen
        data-resource-id="employeepackage"
        name="Employee Package"
        props={{user: user, ...props}}
        component={EmployeePackageTab}
        options={{
          label: 'Employee Package',
        }}
      />
      <EmployeeReports.Screen
        data-resource-id="form16"
        name="Bulk Form16"
        props={{user: user, ...props}}
        component={BulkForm16Tab}
        options={{
          label: 'Bulk Form16',
        }}
      />
       <EmployeeReports.Screen
        data-resource-id="form16"
        name="Employee Form16"
        props={{user: user, ...props}}
        component={EmployeeForm16Tab}
        options={{
          label: 'Employee Form16',
        }}
      />
    </EmployeeReports.Navigator>
  );
};