//14-02-2024 Rohit Bansal  - open route passed in url

import {DIRECT_ACCESS_URLS} from './DirectRouteConstants';
import {screenPaths} from '.';
import {singular} from 'pluralize';

const isDirectAccessible = ({path}) => {
  const locationPath = path.split('/').filter(str => str);

  let mappedParams;
  const matchedUri = Object.keys(DIRECT_ACCESS_URLS).find(uri => {
    const slashes = uri
      .split('/')
      .filter(str => str)
      .map(str => {
        let indexOfParam = str.indexOf('{');
        if (indexOfParam > -1) {
          let indexOfLastParam = str.indexOf('}');
          if (indexOfLastParam === str.length - 1) {
            const paramName = str.substring(indexOfParam + 1, indexOfLastParam);
            return {
              type: 'param',
              paramName,
            };
          }
        }
        return {
          type: 'path',
          pathName: str,
        };
      });

    if (slashes.length !== locationPath.length) {
      return false;
    }
    let found = true;
    let params = {};
    for (let index = 0; index < slashes.length; index++) {
      const {type, paramName, pathName} = slashes[index];
      if (type === 'path') {
        if (pathName !== locationPath[index]) {
          found = false;
          break;
        }
      } else if (type === 'param') {
        // check if the param is valid
        params[paramName] = locationPath[index];
      } else {
        found = false;
        break;
      }
    }
    if (found) {
      mappedParams = params;
      return true;
    }
  });
  if (matchedUri) {
    return {
      ...DIRECT_ACCESS_URLS[matchedUri],
      resolvedParams: mappedParams,
    };
  }
};

const navigateToScreen = async ({
  matchedPath,
  setState,
  toast,
  navigationRef,
  post,
}) => {
  const {paramsApi, onParams, resolvedParams} = matchedPath;

  try {
    if (paramsApi) {
      setState({loading: true, initialPathConsumed: true});
      let {data} = await post({
        uri: paramsApi,
        props: {
          ...resolvedParams,
        },
      });

      if (onParams && data.params) {
        const {screen, params: screenParams} = onParams(data.params);
        setTimeout(() => {
          navigationRef.current?.navigate(screen, {...screenParams});
        }, 100);
      }
      setState({loading: false});
    }
  } catch (err) {
    setState({loading: false});
    console.log('err', err);
    toast({message: err.message, type: 'Error'});
  }
};

export const handleInitialRoute = async ({
  initialPath,
  navigationRef,
  state,
  setState,
  post,
  toast,
}) => {
  if (!state?.user) {
    return;
  }
  if (initialPath === '/') {
    return;
  }

  if (state.initialPathConsumed) {
    return;
  }

  try {
    const path = initialPath;
    const matchedPath = isDirectAccessible({path});
    if (!state?.user && !matchedPath?.isPublic) {
      return;
    }
    if (matchedPath) {
      window.history.pushState({}, '', '/');
      await navigateToScreen({
        matchedPath,
        setState,
        toast,
        navigationRef,
        post,
      });
    }
  } catch (err) {
    console.error('err in user load ', err);
    toast && toast({message: err.message, type: 'Error'});
  }
};
