import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';

const DrillByskillTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {filterValues = {}} = useFilter({
    params: {period: params.period || getCurrentMonth()},
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  return (
    <Table
      eventSourceId={'departments'}
      api={`${params?.row?.api?.api}`}
      params={{
        period: period,
        skill: true,
        currentUser: params?.currentUser,
        role: params?.row?.role,
        target: params?.row?.target_value,
        entity: params?.row?.kpi_id?.entity,
        resourceFullfillment: params?.resourceFullfillment,
        employee_level: params?.employee_level
      }}
      fields={{skill: 1, percentage: 1}}
      columns={[
        {
          field: 'skill',
          header: 'Skill',
          responsive: 'sm',
        },
        {
          field: 'target',
          header: 'Target',
          responsive: 'sm',
          align: 'right',
        },
        {
          field: 'percentage',
          header: 'Outcome',
          responsive: 'sm',
          align: 'right',
          onPress: ({row}) => {
            let outCome = row;
            params?.row?.api?.view
              ? navigation.navigate(params.row.api.view, {outCome})
              : null;
          },
        },
        {responsive: 'sm'},
        {
          field: 'owner',
          header: 'Owner',
          responsive: 'sm',
        },
      ]}
      limit={2000}
      {...props}
    />
  );
};

export default DrillByskillTable;
