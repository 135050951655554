import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';

export const CreditNoteTable = props => {
  const {navigation, addOnFilter, tab, approverView} = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['creditNoteAm'],
  });
  const {user} = useAppStateContext();
  return (
    <Table
      eventSourceId={['creditNoteAm']}
      api={['/creditNote/am']}
      onRowPress={props => {
        const {row} = props;
        if (tab === 'Draft') {
          navigation.navigate(`edit-credit-note-am`, {
            row,
          });
        } else {
          navigation.navigate(`edit-credit-note-am`, {
            row,
            readOnly: true,
          });
        }
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          minWidth: 250,
          colorField: randomColor,
        },
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 250,
          align: 'center',
        },
        {
          header: 'Amount',
          field: 'credit_note_amount',
          type: 'currency',
          width: 250,
          align: 'center',
        },
        {
          header: 'Currency',
          field: 'currency.currency',
          type: 'text',
          width: 250,
          align: 'center',
        },
        {
          header: 'Reason',
          field: 'reason',
          width: 250,
          align: 'center',
        },
        {
          header: 'Requested By',
          field: 'requested_by',
          type: 'userAvatar',
          width: 150,
          visible: () => {
            if (approverView) return true;
          },
        },
        {
          header: 'Approved By',
          field: 'approved_by',
          type: 'userAvatar',
          width: 150,
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
        {
          header: 'Approved On',
          field: 'approved_on',
          type: 'date',
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
        {
          header: 'Rejected By',
          field: 'approved_by',
          type: 'userAvatar',
          width: 150,
          visible: () => {
            if (tab === 'Rejected') return true;
          },
        },
        {
          header: 'Rejected On',
          field: 'approved_on',
          type: 'date',
          width: 150,
          visible: () => {
            if (tab === 'Rejected') return true;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Send For Approval',
          confirm: {
            title: 'Send For Approval',
            message: 'Are you sure you want to send this request for approval?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/creditnoteams/${row._id}`,
              props: {
                credit_note_status: 'Pending',
              },
            });
          },
          visible: () => {
            if (tab === 'Draft') return true;
          },
        },
        {
          title: 'Approve',
          confirm: {
            title: 'Approve',
            message: 'Are you sure you want to Approve this request?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/creditnoteams/${row._id}`,
              props: {
                credit_note_status: 'Approved',
                approved_by: user._id,
                approved_on: getZeroTimeDate(new Date()),
              },
            });
          },
          visible: () => {
            if (tab === 'Pending' && approverView) return true;
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('reject-credit-note-reason', {row});
          },
          visible: () => {
            if (tab === 'Pending' && approverView) return true;
          },
        },
        {
          title: 'Mark Billed',
          onPress: ({row}) => {
            navigation.navigate('mark-billed-credit-note', {row});
          },
        },
        // {
        //   title: 'Create Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate('create-revenue-invoice', {
        //       row: {
        //         invoice_type: 'c',
        //         invoice_amount: row.credit_note_amount,
        //         ...row,
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};
