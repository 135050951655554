import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateMilestoneTargetForm = props => {
  const {
    route: {params},
  } = props;

  const {row = {}, api, secondaryTitle} = params || {};

  let {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: ['Milestone'],
  });

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{_id: row._id}}
      header={{
        title: 'Update Target Date',
        secondaryTitle: secondaryTitle,
      }}
      layoutFields={[
        {
          label: 'Target Date',
          field: 'due_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Reason',
          field: 'due_date_reason',
          type: 'textArea',
          inputProps: {
            minRows: 2,
            maxRows: 5,
          },
          visible: !!row.due_date,
          required: true,
        },
      ]}
    />
  );
};
