import {Text} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {BorderedGroupTable, Table} from '../../../components/table/Table';

export const ProjectWiseFeatureTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {api, feature_id} = params || {};
  let filter = {};
  if (feature_id) {
    filter = {
      feature_id,
    };
  }
  return (
    <BorderedGroupTable
      api={api}
      filter={filter}
      renderHeader={() => <TableHeader title="Project Features" />}
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.name} `}
              </Text>
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 1, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.total_est_hours} `}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Feature',
          field: 'name',
          type: 'text',
          minWidth: 300,
        },
        {
          header: 'Total Est Hours',
          field: 'est_hours',
          type: 'number',
          width: 200,
        },
      ]}
    />
  );
};

export const ProjectFeatureTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {projectFeatureFilter = {}} = params || {};

  return (
    <Table
      eventSourceId={['employeetype']}
      api={api ? api : `/ownerwise/project-features`}
      renderHeader={() => {
        return <TableHeader title="Project Features" />;
      }}
      filter={{...projectFeatureFilter, ...filter}}
      columns={[
        {
          type: 'text',
          header: 'Feature',
          field: 'feature',
        },
        {
          type: 'text',
          header: 'Module',
          field: 'module_id.module',
        },
        {
          type: 'userAvatarChip',
          header: 'Owner',
          field: 'module_id.owner_id',
        },
      ]}
    />
  );
};
