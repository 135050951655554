import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';

export const ProjectedVsAchievedTable = props => {
  let {
    route: {params = {}},
  } = props;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {params: filterParams = {}} = filterValues;
  params = {...params, ...filterParams};
  return (
    <Table
      params={params}
      variant={'bordered'}
      api={`/projectedVsAchieved`}
      limit={1000}
      renderHeader={() => (
        <TableHeader
          title="Projected Vs Achieved"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              asParam
            />,
          ]}
        />
      )}
      columns={[
        {
          type: 'text',
          field: 'type',
          minWidth: 200,
        },
        {
          header: 'Projected',
          children: [
            {
              type: 'number',
              field: 'projectedBilling',
              header: 'IH',
            },
            {
              type: 'number',
              field: 'pipeline',
              header: 'Pipeline',
            },
            {
              type: 'number',
              field: 'total',
              header: 'Total',
            },
          ],
        },
        {
          type: 'number',
          field: 'achieved',
          header: 'Achieved',
        },
        {
          type: 'number',
          field: 'variation',
          header: 'Variation',
        },
      ]}
    />
  );
};
