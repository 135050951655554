export const MailContentRenderStyles = ({theme: {fonts, colors}}) => {
  return {
    container: {
      padding: 12,
      borderTopWidth: 1,
      borderColor: colors.OUTLINE,
    },
    subjectContainer: {
      marginBottom: 10,
    },
    subjectLabel: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_LOW,
      marginRight: 10,
    },
    subjectText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    messageContainer: {
      borderRadius: 8,
      padding: 12,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      whiteSpace: 'pre-wrap',
    },
    messageLabel: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_LOW,
      marginBottom: 10,
    },
  };
};
