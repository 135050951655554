import React from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {PIPSelfTrainingFeedback} from './TrainingFeedbackTable';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';

export const SelfFeedbacksMenu = props => {
  const {route: {params} = {}} = props;
  const menus = [
    [
      {
        label: 'PIP',
        view: <PIPSelfTrainingFeedback />,
      },
    ],
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
