import React from 'react';

import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import moment from 'moment';

export const UpdateReleasedOnForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projectfeatures',
    eventSourceId: 'Feature',
  });
  return (
    <Form
      mode="edit"
      header={{
        title: 'Update Released On',
        secondaryTitle: row?.feature,
      }}
      onSubmit={onSubmit}
      data={{_id: row._id}}
      layoutFields={[
        {
          label: 'Released On',
          field: 'releasedOn',
          type: 'date',
          required: true,
          validate: value => {
            if (
              value &&
              moment.utc(value).startOf('day').toDate() >
                moment.utc().startOf('day').toDate()
            ) {
              return 'Released date should be less than or equal to today';
            }
          },
        },
      ]}
    />
  );
};
