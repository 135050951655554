import React from 'react';

import {Table} from '../../../components/table/Table';

export const OutreachCampaignUSPTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {campaign_id} = params || {};

  return (
    <Table
      {...props}
      fields={{
        usp_id: {name: 1},
      }}
      api="/campaignusps"
      filter={{campaign_id}}
      eventSourceId={['campaignUSPAdded']}
      sort={{
        createdAt: -1,
      }}
      columns={[
        {
          field: 'usp_id.name',
          type: 'text',
          header: 'USP',
          width: 200,
        },
      ]}
    />
  );
};
