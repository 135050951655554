import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EmployeeSizeForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeSizes',
    eventSourceId: 'EmployeeSize',
  });
  return (
    <Form
      api={`/employeeSizes/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'number',
          label: 'Number',
        },
        {
          type: 'number',
          field: 'score',
          label: 'Score',
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeSizeForm = props => {
  return <EmployeeSizeForm header="Add Employee Size" {...props} />;
};

export const EditEmployeeSizeForm = props => {
  return (
    <EmployeeSizeForm mode="edit" header={'Update Employee Size'} {...props} />
  );
};
