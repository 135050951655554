import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const WebsiteSummaryTestForm = props => {
  const {
    route: {params},
    header = 'Website Summary Test',
  } = props;

  let {potential_customer} = params || {};

  let {onSubmit} = useFormSubmit({
    uri: `/potentialcustomer-business-summary`,
    eventSourceId: 'test website',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{potential_customer}}
      layoutFields={[
        {
          placeholder: 'Website Summary',
          field: 'test_website',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const EditWebsiteSummaryTestForm = props => {
  return (
    <WebsiteSummaryTestForm
      mode="edit"
      header={'Edit Website Summary Test Form'}
      {...props}
    />
  );
};
