import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {PROJECT_FEATURE_TYPE_ID} from '../../common/constants/SourceConstants';
import {useFetchData} from '../../../controllers/useFetchData';

export const UpdateProjectTaskTypeForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: 'Task',
  });

  const {project_id, module_id, feature_id} = row;

  const {data: assetData = []} = useFetchData({
    api: `/projectAssetVersions`,
    filter: {
      feature_id: feature_id?._id,
    },
    fields: {
      source_id: 1,
    },
  });

  const featureAssets = assetData.map(item => item.source_id);

  const {data = []} = useFetchData({
    api: `/projectBugs`,
    filter: {
      feature_id: row?.feature_id?._id,
    },
    fields: {
      _id: 1,
    },
  });

  const featureBugs = data.map(item => item._id);

  const {data: pullRequestsData = []} = useFetchData({
    api: `/projectpullrequests`,
    filter: {
      feature_id: row?.feature_id?._id,
    },
    fields: {
      _id: 1,
    },
  });

  const featurePRs = pullRequestsData.map(item => item._id);

  return (
    <Form
      mode="edit"
      api={'/projectTasks/' + row?._id}
      fields={{
        master_task_assignment: {
          task: 1,
        },
        parent_model: {
          label: 1,
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      header={{title: 'Update Task Type', secondaryTitle: row?.name}}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Task Type',
          field: 'master_task_assignment',
          type: 'autoComplete',
          api: `/projects/${project_id?._id}/mastertaskassignments`,
          params: () => {
            return {
              module_id: module_id?._id,
            };
          },
          suggestionField: 'task',
          valueField: 'task',
          onChangeValue: (value, _, {setFieldValue}) => {
            const {parent_model} = value || {};

            let parent_model_id;
            if (parent_model?._id === PROJECT_FEATURE_TYPE_ID) {
              parent_model_id = {
                _id: feature_id?._id,
                name: feature_id?.feature,
              };
            }
            setFieldValue('parent_model_id', parent_model_id);
            setFieldValue('parent_model', parent_model);
            setFieldValue('source', parent_model);
          },
          required: true,
        },
        {
          label: 'Source Value',
          field: 'parent_model_id',
          type: 'autoComplete',
          api: '/sourceSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          params: ({values}) => {
            const {parent_model} = values || {};

            return {
              source: parent_model,
            };
          },
          filter: {
            project_id,
            _id: {
              $in: [
                ...featureAssets,
                ...featureBugs,
                ...featurePRs,
                feature_id?._id,
              ],
            },
          },
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue('source_id', value);
          },
        },
      ]}
    />
  );
};
