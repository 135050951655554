import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {AllPendingInvoiceTab} from './AllDueInvoicingTab';
import {PendingInvoiceTab, PendingInvoiceTabAm} from './DueInvoiceTab';
import {NewOrderForAmTab, NewOrderTab} from './NewOrderTab';
import {OrderSupportTab, OrderSupportTabAm} from './OrderSupport';
import {
  FixedOrderTab,
  FixedOrderTabAM,
  TMOrderTab,
  TMOrderTabAm,
} from './OrderTab';

export const OrderMenu = props => {
  const menus = [
    {
      label: 'New',
      view: <NewOrderTab {...props} />,
    },
    {
      label: 'Fixed',
      view: (
        <FixedOrderTab
          addOnFilter={{is_internal: {$in: [false, null]}}}
          fromInternal={false}
          {...props}
        />
      ),
    },
    {
      label: 'T&M',
      view: <TMOrderTab {...props} />,
    },
    // {
    //   label: 'Support',
    //   view: <OrderSupportTab {...props} />,
    // },
    {
      label: 'Internal',
      view: (
        <FixedOrderTab
          addOnFilter={{is_internal: true}}
          fromInternal={true}
          {...props}
        />
      ),
    },
    {
      label: 'Due Invoicing',
      view: <PendingInvoiceTab {...props} />,
    },
    {
      label: 'All Due Invoicing',
      view: <AllPendingInvoiceTab {...props} />,
    },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const OrderMenuAM = props => {
  const menus = [
    {
      label: 'New',
      view: <NewOrderForAmTab {...props} />,
    },
    {
      label: 'Fixed',
      view: (
        <FixedOrderTabAM
          addOnFilter={{is_internal: {$in: [false, null]}}}
          fromInternal={false}
          {...props}
        />
      ),
    },
    {
      label: 'T&M',
      view: <TMOrderTabAm {...props} />,
    },
    // {
    //   label: 'Support',
    //   view: <OrderSupportTabAm {...props} />,
    // },
    {
      label: 'Internal',
      view: (
        <FixedOrderTabAM
          addOnFilter={{is_internal: true}}
          fromInternal={true}
          {...props}
        />
      ),
    },
    {
      label: 'Due Invoicing',
      view: <PendingInvoiceTabAm {...props} />,
    },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
