import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const EmployeeAccessForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/EmployeeAccesses',
    eventSourceId: 'EmployeeAccess',
  });
  return (
    <Form
      api={`/employeeaccesses/${params?.employeeAccessId}`}
      fields={{
        role: 1,
        department: {name: 1},
        product: {name: 1},
        employee: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Role',
          field: 'role',
          type: 'text',
        },
        {
          label: 'Department',
          field: 'department',
          type: 'autoComplete',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeAccessForm = props => {
  return <EmployeeAccessForm header="Add Employee Access" {...props} />;
};

export const UpdateEmployeeAccessForm = props => {
  return (
    <EmployeeAccessForm
      mode="edit"
      header="Update Employee Access"
      {...props}
    />
  );
};
