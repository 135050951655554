import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddUserOrganization = props => {
  const {route} = props;
  const {params} = route;
  const {user_organization} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/userorganizations',
    eventSourceId: ['UserOrganization'],
  });

  return (
    <Form
      header={{
        title: 'Project Configuration',
        secondaryTitle: user_organization?.name,
      }}
      api={'/userorganizations/' + user_organization?._id}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
        },
        {
          label: 'Domain',
          type: 'multiText',
          field: 'domain',
          size: 6,
        },
        {
          label: 'Hd',
          type: 'text',
          field: 'hd',
          size: 6,
        },
        {
          field: 'is_service_industry',
          type: 'checkbox',
          label: 'Service industry',
          size: 6,
        },
        {
          field: 'manual_labeling',
          type: 'checkbox',
          label: 'Manual Labeling',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const EditUserOrganization = props => {
  return <AddUserOrganization mode="edit" {...props} />;
};
