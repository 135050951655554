import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const EntityVariableForm = props => {
  const {
    route: {params},
  } = props;
  const {entityId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/entityvariables',
    eventSourceId: 'EntityVariable',
  });
  return (
    <Form
      api={`/entityvariables/${entityId}`}
      fields={{
        name: 1,
        label: 1,
        description: 1,
        output_fields: 1,
        example_output: 1,
        category: 1,
        response_format: 1,
      }}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const {response_format} = data;
        if (response_format) {
          try {
            JSON.parse(response_format);
          } catch (e) {
            throw new Error('Invalid JSON format for Response Format');
          }
        }
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          size: 4,
          required: true,
        },
        {
          type: 'text',
          field: 'label',
          label: 'Label',
          size: 4,
          required: true,
        },
        {
          type: 'text',
          field: 'category',
          label: 'Category',
          size: 4,
        },
        {
          type: 'textArea',
          field: 'description',
          label: 'Description',
          inputProps: {
            minRows: 5,
            maxRows: 10,
          },
        },
        {
          type: 'textArea',
          field: 'response_format',
          label: 'Response Format',
          inputProps: {
            minRows: 5,
            maxRows: 10,
          },
        },
        {
          type: 'multiText',
          field: 'output_fields',
          label: 'Output Fields',
        },
        {
          type: 'textArea',
          field: 'example_output',
          label: 'Example Output',
          inputProps: {
            minRows: 5,
            maxRows: 10,
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddEntityVariableForm = props => {
  return <EntityVariableForm header="Add Entity Variables" {...props} />;
};

export const UpdateEntityVariableForm = props => {
  return (
    <EntityVariableForm
      mode="edit"
      header="Update Entity Variables"
      {...props}
    />
  );
};
