import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {PotentialCustomerForLeadToQl} from './LeadTable';
import { SearchFilter } from '../../../components/filter/Filters';

export const PotentialCustomerTabForLeadToQl = props => {
  const {route: {params} = {}, navigation} = props;
  const {filter = {}} = params;
  const {filterValues, onChangeFilter, searchValue} = useFilter({});
  let tabs = {};

  tabs.active = {
    label: 'Potential Customer',
    api: '/potentialcustomers',
    addOnFilter: filter,
    view: (
      <PotentialCustomerForLeadToQl
        {...props}
        search={searchValue}
        filter={filter}
        params={params}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
