import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {INMAIL_CAMPAIGN_TYPE_ID} from '../../common/constants/SourceConstants';

export const FetchDataForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/import-potential-as-contact',
    eventSourceId: 'contactsImported',
  });
  const {_id: campaign_id} = params || {};

  return (
    <Form
      onSubmit={(data, {setFieldValue}) => {
        onSubmit(data, {setFieldValue});
      }}
      header={'Select Base Model'}
      defaultValues={{
        campaign_id,
        campaign_type: INMAIL_CAMPAIGN_TYPE_ID,
        for_finetune: true,
      }}
      layoutFields={[
        {
          label: 'Base Model',
          field: 'base_model',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
