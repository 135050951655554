export const UnverifiedActionStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    edit: icons.Edit,
    downArrowIcons: icons.DownArrowIcon,
    caretLeft: icons.CaretLeft,
    caretRight: icons.CaretRight,

    containerStyle: {
      flexDirection: 'row',
      marginBottom: 16,
      justifyContent: 'space-between',
    },
    subContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    secondHeaderStyle: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 4,
      backgroundColor: colors.ACCENT1_MEDIUM,
    },
    headerContainerStyle: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 4,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.WARNING_MEDIUM,
    },
    bodyContainer: {
      flex: 1,
      overflow: 'hidden',
      padding: 20,
      backgroundColor: colors.BACKGROUND,
    },
    lineStyle: {backgroundColor: colors.OUTLINE, height: 1, marginBottom: 12},
    subjectContainer: {marginBottom: 12, flexDirection: 'row'},
    subjectLabel: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_LOW,
      marginRight: 12,
      fontStyle: 'italic',
    },
    subjectText: {
      ...fonts.BODY2,
      marginBottom: 8,
      color: colors.NEUTRAL_MEDIUM,
    },
    messageLabel: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_LOW,
      marginBottom: 10,
    },
    messageText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
      marginBottom: 10,
      whiteSpace: 'pre-wrap',
    },
    headerStyle: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_MEDIUM,
      marginBottom: 10,
      marginTop: 2,
    },
    nameHeaderStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
      marginBottom: 10,
      marginTop: 2,
    },
    contentStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    headingDetailTextStyle: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_HIGH,
    },
    subHeaderTextStyle: {
      ...fonts.BODY3,
    },
    headerSubTextStyle: {
      ...fonts.BODY3,
      color: colors.INFORMATION_HIGH,
    },
    smallSubHeaderStyle: {
      ...fonts.CAPTION_LARGE,
      color: colors.WARNING_HIGH,
    },
    dotStyle: {
      backgroundColor: colors.NEUTRAL_LOW,
      height: 8,
      width: 8,
      borderRadius: 8,
      margin: 8,
    },
    summaryContainerStyle: {
      backgroundColor: colors.SURFACE3,
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 12,
      paddingBottom: 4,
      flexDirection: 'row',
    },
    buttonContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingTop: 12,
    },
    editBodyContainerStyle: {flexDirection: 'row', alignItems: 'center'},
    editScrollViewStyle: {
      padding: 20,
      borderRadius: 6,
      backgroundColor: colors.SURFACE3,
    },
    smallDotstyle: {
      backgroundColor: colors.WARNING_HIGH,
      height: 4,
      width: 4,
      borderRadius: 4,
      margin: 8,
    },
    summaryTextStyle: {
      ...fonts.BODY2,
      marginTop: 12,
      textAlign: 'center',
      color: colors.NEUTRAL_HIGH,
    },
    personalisedHeaderStyle: {
      ...fonts.BODY1,
      color: colors.BRAND_UPPER_MEDIUM,
      marginBottom: 10,
    },
    bodyScrollViewStyle: {
      borderRadius: 6,
      paddingRight: 20,
      paddingTop: 12,
      paddingBottom: 12,
    },
    toggleSubContainerStyle: {
      borderRadius: 8,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: '#EDEDED',
      overflow: 'hidden',
    },
    websiteSummaryStyle: {
      flex: 1,
      overflow: 'hidden',
      borderWidth: 1,
      borderTopWidth: 0,
      borderColor: '#EDEDED',

      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    renderBodyContainerStyle: {
      flex: 1,
      flexDirection: 'row',
      marginTop: 12,
      overflow: 'hidden',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#EDEDED',
    },
    imageContainerStyle: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 6,
      padding: 4,
    },
  };
};

export const MarkVerifiedActionStyle = ({theme: {fonts, icons, colors}}) => {
  return {
    caretLeft: icons.CaretLeft,
    caretRight: icons.CaretRight,

    containerStyle: {
      flex: 1,
      overflow: 'hidden',
    },
    headerStyle: {
      textAlign: 'center',
      color: colors.NEUTRAL_HIGH,
      ...fonts.TITLE,
      marginBottom: 8,
    },
    lineStyle: {backgroundColor: colors.OUTLINE, height: 1},
    subHeadingStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 12,
    },
    summaryContainerStyle: {
      backgroundColor: colors.BACKGROUND,
      padding: 20,
    },
    dotStyle: {
      backgroundColor: colors.NEUTRAL_LOW,
      height: 8,
      width: 8,
      borderRadius: 8,
      margin: 8,
    },
    subHeaderStyle: {
      backgroundColor: colors.ACCENT2_MEDIUM,
      paddingVertical: 4,
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 4,
    },
    subHeaderTextStyle: {
      ...fonts.BODY3,
    },
    summaryHeadingStyle: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_MEDIUM,
      marginBottom: 16,
    },
    summaryTextStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    industryNameStyle: {
      marginLeft: 20,
      marginRight: 20,
      textAlign: 'center',
      minWidth: 350,
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    imageContainerStyle: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 6,
      padding: 4,
    },
  };
};

export const TestProfileSampleStyle = ({theme: {fonts, colors}}) => {
  return {
    containerStyle: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    textStyle: {
      padding: 10,
      ...fonts.BODY1,
    },
  };
};
