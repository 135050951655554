import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useProjectResourceWorkingDataActions} from '../actions/ProjectResourceWorkingDataActions';
import {Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import { TableHeader } from '../../../components/table/Headers';

const calculateBilledHours = ({invoke, period = {}}) => ({
  title: 'Cal. Billed Hrs',
  confirm: {
    title: 'Calculate Billed Hours',
    message: 'Do you want calculate billed hours for this data?',
  },
  onPress: props => {
    const {row = {}} = props;
    invoke({
      uri: `/markDeliveredForTandMDelivery/${row?._id}`,
      props: {
        period,
      },
    });
  },
});

export const VerifiedOrderOfOrdersAndDeliveries = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {projectresourceWorking, period = {}} = params || {};
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
  });
  const {activityLogVerifiedOrdersAndDeliveries} =
    useProjectResourceWorkingDataActions({
      navigation,
      params,
    });
  const color = useTheme('colors');
  return (
    <Table
      {...props}
      variant={'bordered'}
      addOnFilter={{
        _id: {$in: projectresourceWorking?._children?.map(({_id}) => _id)},
        approx_billed_hours: {$exists: true},
        approval_status: {$ne: 'Delivered'},
      }}
      renderHeader={() => <TableHeader title="Verified Orders" />}
      params={params}
      eventSourceId={'ActiveOrderOfOrdersAndDeliveries'}
      api={`/projectResourceWorking/activeOrders`}
      columns={[
        {
          field: 'project_resource_employee',
          header: 'Resource',
          type: 'userAvatarGroup',
          minWidth: 200,
        },
        {
          field: 'variable_potential',
          header: 'Working Pot.',
          type: 'number',
          width: 120,
        },
        {
          header: 'Approved',
          children: [
            {
              header: 'EWD',
              align: 'right',
              width: 100,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.approve_ewd_days || 0} h`}
                  </Text>
                );
              },
            },
            {
              header: 'Leaves',
              align: 'right',
              width: 100,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.approve_leave_days || 0} h`}
                  </Text>
                );
              },
            },
          ],
        },
        {
          header: 'Unapproved',
          children: [
            {
              header: 'EWD',
              width: 100,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.unapprove_ewd_days || 0} h`}
                  </Text>
                );
              },
            },
            {
              header: 'Absence',
              width: 100,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.absence || 0} h`}
                  </Text>
                );
              },
            },
          ],
        },
        {
          header: 'Efforts',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.billing_hrs && `${row?.billing_hrs} h`}
              </Text>
            );
          },
        },
        {
          header: 'Output',
          width: 100,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.billing_hrs && `${row?.billing_hrs} h`}
              </Text>
            );
          },
        },
        {
          header: 'Action',
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('active-order-mark-delivered', {
                    row,
                    period,
                  });
                }}
                style={{...rowText, color: color?.INFORMATION_HIGH}}>
                Mark delivered
              </Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: activityLogVerifiedOrdersAndDeliveries,
        },
        calculateBilledHours({invoke, period}),
        {
          title: 'Allocations',
          onPress: ({row}) => {
            navigation.navigate('active-order-allocations', {
              row,
              period,
            });
          },
        },
      ]}
    />
  );
};
