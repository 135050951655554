import React from 'react';
import {DurationRenderer} from '../form-editors/duration/DurationInput';
import {Chip} from './Chip';
import {Image} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

const DurationChip = ({value, showIcon, ...props}) => {
  if (!value) {
    return null;
  }
  const {EstHours} = useTheme('icons');
  return (
    <Chip
      prefix={
        showIcon ? (
          <Image style={{height: 18, width: 18}} source={EstHours} />
        ) : (
          void 0
        )
      }
      {...props}>
      <DurationRenderer value={value} />
    </Chip>
  );
};

export default DurationChip;
