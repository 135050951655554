import React from 'react';
import {EnggAnalyticsTabs} from './EngineeringEmployeeAnalyticsTabs';
import SkillLeaderDashboard from './SkillLeaderDashboard';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {BenchComparisionReport} from './BenchComparisionReport';
import {BenchTab, DeliveryOwnersBenchTab} from './EnggNonBilledTab';
import {BillingStatusReportTab} from './BillingStatusReportTab';
import {EmployeeToBeFreeTabs} from './EmployeeToBeFree';

const BenchGroup = createMenuNavigator();
export const BenchNavigator = props => {
  let {route: {params} = {}} = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <BenchGroup.Navigator
      canAccess={canAccess}
      data-navigator-id="BenchGroup"
      {...props}>
      <BenchGroup.Screen
        data-resource-id="BenchResource"
        name="Bench"
        component={BenchTab}
        props={{user: user, fromICMenu: true, ...props}}
        options={{
          label: 'Bench',
        }}
      />
      <BenchGroup.Screen
        data-resource-id="DeliveryOwnersBench"
        name="DeliveryOwnersBench"
        component={DeliveryOwnersBenchTab}
        props={{user: user, fromICMenu: true, ...props}}
        options={{
          label: 'DO Bench',
        }}
      />
      <BenchGroup.Group>
        <BenchGroup.Screen
          data-resource-id="Analytics"
          name="Analytics"
          component={EnggAnalyticsTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Analytics',
          }}
        />
      </BenchGroup.Group>
      <BenchGroup.Screen
        data-resource-id="BenchComparison"
        name="Bench Comparison"
        component={BenchComparisionReport}
        props={{user: user, ...props}}
        options={{
          label: 'Bench Comparison',
        }}
      />
      {/* <BenchGroup.Screen
        data-resource-id="SkillLeader"
        name="Skill Leader"
        component={SkillLeaderDashboard}
        props={{user: user, ...props}}
        options={{
          label: 'Skill Leader',
        }}
      /> */}
      <BenchGroup.Screen
        data-resource-id="EmployeeToBeFree"
        name="Employee To Be Free"
        component={EmployeeToBeFreeTabs}
        props={{user: user, ...props}}
        options={{
          label: 'Employee To Be Free',
        }}
      />
      <BenchGroup.Screen
        data-resource-id="BillingStatusReport"
        name="Billing Status Report"
        component={BillingStatusReportTab}
        props={{user: user, ...props}}
        options={{
          label: 'Billing Status Report',
        }}
      />
    </BenchGroup.Navigator>
  );
};
