import {useTheme} from '@unthinkable/react-theme';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {ProjectDatabaseInlineForm} from './ProjectDatabaseForm';

export const ProjectDatabasesTable = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;
  const {project} = params;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'ProjectDatabase',
  });
  const colors = useTheme('colors');

  return (
    <Table
      renderHeader={() => <ProjectDatabaseInlineForm {...props} />}
      eventSourceId={['ProjectDatabase']}
      api={`/projectdatabases`}
      filter={{project_id: project?._id}}
      fields={{
        database: 1,
        version: 1,
        database_names_count: {
          count: 1,
        },
      }}
      columns={[
        {
          type: 'text',
          field: 'database',
          header: 'Database Type',
        },
        {
          type: 'text',
          field: 'version',
          header: 'Version',
        },
        {
          width: 150,
          type: 'text',
          field: 'database_names_count',
          header: 'Database Names',
          value: ({row}) => row?.database_names_count || 0,
          color: colors.INFORMATION_HIGH,
          onPress: ({row}) => {
            navigation.navigate('project-database-names', {
              ...params,
              database: row,
            });
          },
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this database type?',
          },
          onPress: ({row}) => {
            deleteInvoke({uri: `/projectdatabases/${row._id}`});
          },
        },
      ]}
      onRowPress={({row}) => {
        navigation.navigate('edit-database', {
          ...params,
          database: row,
        });
      }}
    />
  );
};
