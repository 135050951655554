import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AssignTraining = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: ['allocatedResource'],
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header="Training On Opportunity"
      defaultValues={{
        bench_training_type: 'RFR',
        shadow_type: 'learning',
        employee: row?._id,
        is_from_bench_resource: true,
        is_shadow: true,
        project_type: 'tr',
        rfr_type: 'Customer',
      }}
      layoutFields={[
        // {
        //   placeholder: 'Training Type',
        //   label: 'Training Type',
        //   type: 'radioGroup',
        //   field: 'bench_training_type',
        //   options: [
        //     {label: 'Opportuinity', value: 'RFR'},
        //     {label: 'Content', value: 'Offline'},
        //   ],
        //   onChangeValue: (value, e, {setFieldValue}) => {
        //     if (value == 'RFR') {
        //       setFieldValue('bench_training_type', 'RFR');
        //       setFieldValue('shadow_type', null);
        //       setFieldValue('is_shadow', null);
        //     }
        //     if (value == 'Offline') {
        //       setFieldValue('bench_training_type', 'Offline');
        //       setFieldValue('shadow_type', null);
        //       setFieldValue('is_shadow', null);
        //       setFieldValue('hours', Number(9));
        //     }
        //   },
        //   required: true,
        // },
        // {
        //   placeholder: 'Purpose',
        //   label: 'Purpose',
        //   type: 'radioGroup',
        //   field: 'purpose',
        //   options: ['Up-skill', 'Cross-skill'],
        //   visible: ({values}) => {
        //     return values.training_type === 'Offline';
        //   },
        //   required: true,
        // },
        // {
        //   placeholder: 'Training As',
        //   label: 'Training As',
        //   type: 'radioGroup',
        //   field: 'training_as',
        //   options: ['Batch', 'Individual'],
        //   visible: ({values}) => {
        //     return values.training_type === 'Offline';
        //   },
        //   required: true,
        // },
        // {
        //   placeholder: 'Course Name',
        //   label: 'Course Name',
        //   field: 'training_program',
        //   type: 'autoComplete',
        //   api: `/trainingprograms`,
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        //   visible: ({values}) => {
        //     return values.training_type === 'Offline';
        //   },
        //   size: 6,
        // },
        // {
        //   placeholder: 'Batch',
        //   label: 'Batch',
        //   field: 'training_batch',
        //   type: 'autoComplete',
        //   api: `/trainingbatches`,
        //   fields: {
        //     _id: 1,
        //     batch_number: 1,
        //     to_date: 1,
        //     from_date: 1,
        //   },
        //   suggestionField: 'batch_number',
        //   valueField: 'batch_number',
        //   required: true,
        //   filter: ({values}) => {
        //     return {training_program: values?.training_program?._id};
        //   },
        //   visible: ({values}) => {
        //     return (

        //       values.training_type === 'Offline'
        //     );
        //   },
        //   onChangeValue: (value, e, {setFieldValue, values}) => {
        //     if (values.training_as == 'Batch') {
        //       setFieldValue('from_date', value.from_date);
        //       setFieldValue('to_date', value.to_date);
        //     }
        //   },
        //   size: 6,
        // },
        {
          type: 'radioGroup',
          field: 'rfr_type',
          options: [
            {
              label: 'Customer',
              value: 'Customer',
            },
            {
              label: 'Potential Customer',
              value: 'PotentailCustomer',
            },
          ],
        },
        {
          placeholder: 'Customer',
          label: 'Customer',
          field: 'customer',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          visible: ({values}) =>
            values && values.rfr_type && values.rfr_type === 'Customer',
          size: 6,
        },
        {
          label: 'Potential Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: '/potentialcustomers',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          visible: ({values}) =>
            values &&
            values.rfr_type &&
            values.rfr_type === 'PotentailCustomer',
        },
        {
          placeholder: 'RFR',
          label: 'RFR',
          field: 'opportunity',
          type: 'autoComplete',
          api: `/opportunities`,
          filter: ({values}) => {
            return {
              customer_id: values?.customer?._id
                ? values?.customer?._id
                : void 0,
              potential_customer: values?.potential_customer?._id
                ? values?.potential_customer?._id
                : void 0,
              status: {$in: ['Active', 'New']},
              opportunity_type: 'StaffAugmentationOpportunity',
            };
          },
          fields: {
            requirement: 1,
          },
          suggestionField: 'requirement',
          valueField: 'requirement',
          required: true,
          size: 6,
        },
        {
          placeholder: 'Mentor',
          label: 'Mentor',
          field: 'training_with_whom',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          // size: 6,
        },
        // {
        //   placeholder: 'Interview Mode',
        //   label: 'Interview Mode',
        //   field: 'interview_mode',
        //   type: 'autoComplete',
        //   options: ['Online', 'Face to Face', 'Phone', 'Test'],
        //   required: true,
        //   size: 6,
        // },
        // {
        //   placeholder: 'Practice',
        //   label: 'Practice',
        //   field: 'practice',
        //   type: 'autoComplete',
        //   api: `/practices`,
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        //   size: 6,
        // },
        {
          placeholder: 'Start Date',
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          placeholder: 'End Date',
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
