import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';

const AcceptanceCriteriaRender = ({value, ...props}) => {
  const {form} = props;
  if (form?.readOnly) {
    value = value?.map(data => {
      if (typeof data === 'string') {
        return {
          criteria: data,
        };
      }
      return data;
    });
  }
  return (
    <NestedTable
      {...props}
      value={value}
      editFormHeader={'Acceptance Criteria'}
      inlineAddForm
      modalProps={{
        size: 'small',
        width: 600,
        minHeight: '30%',
      }}
      fields={[
        {
          type: 'textArea',
          field: 'criteria',
          placeholder: 'Write Criteria...',
        },
      ]}
      columns={[
        {
          type: 'text',
          field: 'criteria',
          header: 'Criteria',
          numberOfLines: 3,
        },
      ]}
    />
  );
};

const ExitCriteriaRender = ({value, ...props}) => {
  const {form} = props;
  if (form?.readOnly) {
    value = value?.map(data => {
      if (typeof data === 'string') {
        return {
          criteria: data,
        };
      }
      return data;
    });
  }
  return (
    <NestedTable
      {...props}
      value={value}
      editFormHeader={'Exit Criteria'}
      inlineAddForm
      modalProps={{
        size: 'small',
        width: 600,
        minHeight: '30%',
      }}
      fields={[
        {
          type: 'textArea',
          field: 'criteria',
          placeholder: 'Write Criteria...',
        },
      ]}
      columns={[
        {
          type: 'text',
          field: 'criteria',
          header: 'Criteria',
          numberOfLines: 3,
        },
      ]}
    />
  );
};

export const UpdateUserStoryForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, userstory, isReadOnly} = params;
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'UserStory',
    uri: '/projectUserstories',
  });

  const {
    _id,
    title,
    userstory: user_story,
    module_id,
    desc,
    acceptance_criteria,
  } = userstory || {};

  let initialValues = {
    _id,
    title,
    userstory: user_story,
    module_id,
    desc,
    acceptance_criteria,
  };

  return (
    <Form
      initialValues={initialValues}
      readOnly={isReadOnly || userstory?.aiGenerated}
      isDualMode={true}
      header="User Story Detail"
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          size: 8,
        },
        {
          label: 'Module',
          type: 'autoComplete',
          api: '/projectmodules',
          filter: {project_id: project?._id},
          placeholder: 'Select',
          suggestionField: 'module',
          valueField: 'module',
          field: 'module_id',
          size: 4,
        },
        {
          label: 'User Story',
          type: 'textArea',
          field: 'userstory',
          required: true,
          inputProps: {
            minRows: 2,
            maxRows: 5,
          },
        },

        {
          collapsedFields: [
            {
              label: 'Description',
              type: 'richText',
              field: 'desc',
              placeholder: 'Write description here...',
              minHeight: 200,
            },
            {
              label: 'Acceptance Criteria',
              field: 'acceptance_criteria',
              nested: true,
              render: AcceptanceCriteriaRender,
            },
            // {
            //   label: 'Exit Criteria',
            //   field: 'exit_criteria',
            //   nested: true,
            //   render: ExitCriteriaRender,
            //   visible: !!project?.ai_enabled,
            // },
          ],
        },
      ]}
      {...props}
    />
  );
};
