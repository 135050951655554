import React from 'react';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import {useComponentTheme, useTheme} from '@unthinkable/react-theme';
import DurationChip from '../../../components/chip/DurationChip';
import {resolveExp} from '@unthinkable/react-utils';
import Images from '../../../images';
import {toFixedDecimalValue} from '../../../utils/util';

const {AlertCircleGreen, AlertCircleGray} = Images;

export const EstHrsChipRender = ({count_field, released_count_field, row}) => {
  let releasedCountValue = resolveExp(row, released_count_field);
  let countValue = resolveExp(row, count_field);
  let {est_changed_count} = row || 0;

  let {estChange, estChangeStyle} = useComponentTheme('PlusColumnRenderStyles');

  let colorProps = {};
  if (
    countValue != 0 &&
    releasedCountValue != 0 &&
    countValue === releasedCountValue
  ) {
    colorProps.color = 'SUCCESS';
  }

  return (
    <View>
      <DurationChip showIcon={false} value={countValue} {...colorProps} />
      {est_changed_count ? (
        <View title={'Est effort has been updated'} style={estChangeStyle}>
          <Image source={estChange} />
        </View>
      ) : null}
    </View>
  );
};

export const EstHrsColumnRender = ({estHrsField, completedHrsField, row}) => {
  let estHrs = resolveExp(row, estHrsField);
  let completedHrs = resolveExp(row, completedHrsField);

  const {colors, fonts} = useTheme();
  const est_changed_count = row?.est_changed_count || 0;
  if (!estHrs) return null;

  let color = colors.NEUTRAL_MEDIUM;
  let isAllCompleted = false;
  if (estHrs != 0 && completedHrs != 0 && estHrs === completedHrs) {
    color = colors.SUCCESS_HIGH;
    isAllCompleted = true;
  }
  estHrs = toFixedDecimalValue(estHrs);

  const image = isAllCompleted ? AlertCircleGreen : AlertCircleGray;

  let value =
    isAllCompleted || !completedHrsField
      ? `${estHrs} h`
      : `${toFixedDecimalValue(completedHrs || 0)}/${estHrs} h`;

  return (
    <Row gap={4}>
      {est_changed_count ? (
        <View
          title={'Est effort has been updated'}
          style={{justifyContent: 'center'}}>
          <Image source={image} style={{height: 12, width: 12}} />
        </View>
      ) : null}
      <Text style={{color, ...fonts.BODY3}}>{value}</Text>
    </Row>
  );
};
