import React from 'react';

import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

import {TextRenderer} from '../../../components/form/Renderers';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {TableHeader} from '../../../components/table/Headers';
import {PeriodRangeFilter} from '../../../components/filter/Filters';

export const OutreachCampaignMessageTable = props => {
  const {editCampaignMessage, deleteCampaignMessage, markArchive} =
    messageControllers(props);
  const {
    route: {params},
    navigation,
  } = props;
  const {campaign_id} = params || {};

  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  const invoke = useInvoke({method: 'put', eventSourceId: ['indexUpdated']});
  return (
    <Table
      {...props}
      fields={{
        _id: 1,
        name: 1,
        body: 1,
        sequence: 1,
        send_after: 1,
        action: 1,
        personalize_with_ai: 1,
      }}
      api="/campaign-messages"
      params={{
        campaign_id: campaign_id._id,
        status: 'active',
        period,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
            ]}
          />
        );
      }}
      eventSourceId={[
        'indexUpdated',
        'campaignMessageAdded',
        'campaignMessageDeleted',
        'markArchive',
      ]}
      draggable={{
        indexField: 'sequence',
        updateIndexOnDragEnd: props => {
          const {updatedIndex, row} = props;
          invoke({
            uri: `/campaignMessages/${row?._id}`,
            props: {
              sequence: updatedIndex,
            },
          });
        },
      }}
      sort={{
        sequence: 1,
      }}
      columns={[
        {
          width: 30,
          render: props => <TextRenderer {...props} value={props.index + 1} />,
        },

        {
          field: 'name',
          type: 'text',
          header: 'Name',
          width: 200,
        },
        {
          field: 'action',
          type: 'text',
          header: 'Action',
          width: 150,
        },
        {
          field: 'body',
          type: 'textArea',
          header: 'Body',
        },
        {
          field: 'send_after',
          type: 'number',
          header: 'Delay',
          width: 100,
        },
        {
          field: 'personalize_with_ai',
          type: 'text',
          header: 'Personalize',
          width: 75,
        },
        {
          field: 'pipeline_action_count',
          header: 'Pipeline',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-actions', {
              addOnFilter: {
                createdAt: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                campaign_message: row?._id,
                status: 'pending',
                actionType: 'inmail-actions',
              },
            });
          },
        },
        {
          field: 'send_action_count',
          header: 'Send Action',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-actions', {
              addOnFilter: {
                createdAt: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                status: 'completed',
                campaign_message: row?._id,
                actionType: 'inmail-actions',
              },
            });
          },
        },
        {
          field: 'replied_count',
          header: 'Replied',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-communications', {
              addOnFilter: {
                date: {
                  $gte: filterParams?.period?.from,
                  $lte: filterParams?.period?.to,
                },
                initiator: 'Client',
                campaign_message_id: row?._id,
              },
              title: row?.name,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editCampaignMessage,
        },
        {
          title: 'Archive',
          confirm: {
            title: 'Archive',
            message: 'Are you sure you want to Archive?',
            confirmText: 'Archive',
          },
          onPress: markArchive,
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: deleteCampaignMessage,
        },
      ]}
    />
  );
};
