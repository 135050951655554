import React from 'react';
import {Table} from '../../../components/table/Table';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {EXPENSE_REQUEST_TYPE_ID} from '../../common/constants/SourceConstants';

export const featureCommentColumn = ({navigation}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      comment_source: {
        _id: EXPENSE_REQUEST_TYPE_ID,
        label: 'ExpenseRequest',
      },
      comment_source_id: {_id: row._id, amount: row.amount},
    }),
  });
};

export const ExpenseRequestTable = props => {
  let {
    navigation,
    addOnFilter,
    tab,
    filterValues,
    searchValue,
    route: {params},
  } = props;
  if (filterValues) {
    addOnFilter = {
      ...addOnFilter,
      ...filterValues.filter,
    };
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'expenserequest',
  });
  const {user, download} = useAppStateContext();

  const fetchProps = {
    // params,
    addOnFilter,
    search: searchValue,
    searchFields: ['invoice_number'],
    api: '/expenserequest/all',
  };
  return (
    <Table
      eventSourceId={['expenserequest']}
      // addOnFilter={addOnFilter}
      // search={searchValue}
      // searchFields="invoice_number"
      // api={`/expenserequest/all`}
      {...fetchProps}
      onRowPress={({row}) => {
        if (tab === 'Draft') {
          navigation.navigate('edit-expense-request', {row});
        } else {
          navigation.navigate('edit-expense-request', {row, readOnly: true});
        }
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    ...props,
                    fetchProps,
                    column: {
                      'Payment type': 'mode_of_payment',
                      'Invoice no.': 'bill_number',
                      Team: 'team.name',
                      Vendor: 'vendor.name',
                      Description: 'description',
                      Amount: 'amount',
                      Currency: 'currency.currency',
                      Requester: 'requester.name',
                      'Request date': 'request_date',
                      'Created on': 'created_on',
                      Approver: 'approver.name',
                      Approved: 'approved_date',
                      Rejected: 'rejected_on',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Payment type',
          field: 'mode_of_payment',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Invoice no.',
          field: 'bill_number',
          type: 'text',
          width: 180,
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 250,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          width: 250,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 230,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.amount}
                currency={row?.currency?.currency}
              />
            );
          },
        },
        {
          header: 'Duration',
          render: ({row}) => {
            return Durations({
              toDate: row?.provision_to_date,
              fromDate: row?.provision_from_date,
            });
          },
          align: 'center',
          width: 200,
        },
        {
          header: 'Requester',
          field: 'requester',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        tab == 'Requested' && {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 150,
        },
        tab == 'Draft' && {
          header: 'Created on',
          field: 'created_on',
          type: 'date',
          width: 150,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
        },

        {
          header: 'Approved',
          field: 'approved_date',
          type: 'date',
          visible: value => {
            if (tab === 'Approved') return true;
          },
          width: 150,
        },
        {
          header: 'Rejected',
          field: 'rejected_on',
          type: 'date',
          visible: ({values}) => {
            if (tab === 'Rejected') return true;
          },
          width: 150,
        },
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        {
          title: 'Request expense',
          confirm: {
            message: 'Are you sure you want to request this expense?',
            title: 'Request expense',
          },
          message: 'Expense requested successfully',
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/expenserequests/${row?._id}`,
              props: {
                status: 'Requested',
                // reffer_to_coo: true,
                request_date: new Date(),
              },
            });
          },
          visible: ({row}) => tab === 'Draft',
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {
                  path: 'vendor',
                  select: {name: 1},
                },
                {
                  path: 'requester',
                  select: {name: 1},
                },
                {
                  path: 'approver',
                  select: {name: 1},
                },
                {
                  path: 'currency',
                  select: {currency: 1},
                },
                {
                  path: 'created_by',
                  select: {name: 1},
                },
              ],
              api: `/expenserequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
