import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';


const NPSReportTable = props => {
    const { filterParams, params } = props;
    return (
        <Table
            {...props}
            api={'/getnpsreportdata'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="esireport"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'payee_id.employee_id.pran_id',
                    header: 'Pran Id',
                    minWidth: 200,
                },
                {
                    field: 'payee_id.employee_id.dob',
                    header: 'Date Of Birth',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 160
                },
                {
                    header: 'Tier Flag',
                    value: "T1",
                    type: 'text',
                    width: 160,
                },
                {
                    field: 'exp_details.base_purchase_amount',
                    type: 'currency',
                    header: 'Employer Contribution',
                    width: 200,
                },
                {
                    header: 'Subscriber Contribution',
                    value: 0,
                    type: 'number',
                    width: 200,
                },
                {
                    field: 'exp_details.base_purchase_amount',
                    type: 'currency',
                    header: 'Gross Contribution Amount',
                    width: 200,
                },
                {
                    field: 'month_id.name',
                    type: 'text',
                    header: 'Month',
                    width: 140,
                },
                {
                    field: 'year_id.name',
                    header: 'Year',
                    type: 'text',
                    width: 180,
                },
                {
                    field: 'payee_id.name',
                    header: 'Remarks (Self)',
                    type: 'text',
                    width: 180,
                },
            ]}
        />
    );
};

export const NPSReportTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'NPS Report',
            view: (
                <NPSReportTable filterParams={filter} {...props} />
            ),
            eventSourceId: 'npsreport',
            addOnFilter: filter,
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportnpsreportdata',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Vendor',
                            api: '/vendors',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'payee_id',
                        },
                    ]}
                />,
            ]}
        />
    );
};
