import React from 'react';
import {PrimaryColumnCard} from '../../components/card/PrimaryColumnCard';
import {Image, Row, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {IconWithBackground} from '../../components/icons/IconWithBackground';

export const ProjectDetailedName = ({row}) => {
  const projectTypeKeys = {
    i: 'Internal',
    f: 'Fixed',
    tm: 'T & M',
    tr: 'Training',
  };
  let icon;
  let color = 'BRAND_UPPER_LOW';
  if (row.tool === 'Locomo') {
    icon = 'LocomoTool';
  } else if (row.tool === 'Jira') {
    icon = 'JiraIcon';
    color = 'SECONDARY_MEDIUM';
  } else {
    icon = 'OtherTool';
  }

  return (
    <Row style={{alignItems: 'center', gap: 8}}>
      <IconWithBackground icon={icon} color={color} />
      <PrimaryColumnCard
        primaryTitle={row?.project}
        items={[{value: row?.project_sub_category}, {value: row?.code}]}
      />
    </Row>
  );
};
