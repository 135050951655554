import React, {useEffect, useRef, useState} from 'react';
import {TouchableOpacity} from '@unthinkable/react-core-components';
import {ResponsiveModal} from './ResponsiveModal';

const Tooltip = ({
  children,
  renderContent: renderModalProp,
  disabled,
  onPress,
  delay = 100,
  reset,
  ...props
}) => {
  const _ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const hoverTimeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout.current);
    };
  }, []);

  useEffect(() => {
    clearTimeout(hoverTimeout.current);
    setVisible(false);
  }, [reset]);

  return (
    <>
      <TouchableOpacity
        ref={_ref}
        disabled={disabled}
        onPress={onPress}
        onMouseEnter={() => {
          clearTimeout(hoverTimeout.current);
          hoverTimeout.current = setTimeout(() => {
            setVisible(true);
          }, delay);
        }}
        onMouseLeave={() => {
          clearTimeout(hoverTimeout.current);
          setVisible(false);
        }}>
        {children}
      </TouchableOpacity>
      <ResponsiveModal
        referenceElement={_ref.current}
        isVisible={visible}
        {...props}
        autoHide={false}
        backdrop={false}>
        {renderModalProp}
      </ResponsiveModal>
    </>
  );
};

export default Tooltip;
