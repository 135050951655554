export const UpdateQAStatusChipStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    gap: 12,
    passTextStyle: {
      ...fonts.ACTION,
      color: colors.SUCCESS_HIGH,
    },
    rejectTextStyle: {
      ...fonts.ACTION,
      color: colors.ERROR_HIGH,
    },
    resolveTextStyle: {
      ...fonts.ACTION,
      color: colors.INFORMATION_HIGH,
    },
    seperatorStyle: {
      ...fonts.ACTION,
      color: colors.OUTLINE,
    },
    actionStyle: {
      cursor: 'pointer',
      alignItems: 'center',
    },
  };
};
