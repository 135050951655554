import {Table} from '../../../components/table/Table';

const EmailProviderList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['providersAdded']}
      api={`/emailproviders`}
      fields={{
        name: 1,
      }}
      columns={[
        {
          field: 'name',
          header: 'Email Provider',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-email-provider-add-form', {row});
          },
        },
      ]}
    />
  );
};

export default EmailProviderList;
