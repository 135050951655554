import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';

const instanceTypes = [
  't2.small',
  't2.medium',
  't2.large',
  't3.micro',
  't3.small',
  't3.medium',
  'm5.large',
  'm5.xlarge',
  'm5.2xlarge',
  'm5.4xlarge',
  'm5.8xlarge',
  'c5.large',
  'c5.xlarge',
  'c5.2xlarge',
  'c5.4xlarge',
  'c5.9xlarge',
  'r5.large',
  'r5.xlarge',
  'r5.2xlarge',
  'r5.4xlarge',
  'r5.8xlarge',
  'i3.large',
  'i3.xlarge',
  'i3.2xlarge',
  'i3.4xlarge',
  'i3.8xlarge',
  'g4dn.xlarge',
  'g4dn.2xlarge',
  'g4dn.4xlarge',
  'g4dn.8xlarge',
];

const regions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-north-1',
  'me-south-1',
  'sa-east-1',
];

const AwsConfigForm = props => {
  const {
    route: {params},
  } = props;
  const {project, environment_id} = params || {};
  const {user} = useAppStateContext();

  let {onSubmit} = useFormSubmit({
    uri: `/projectawssetups`,
    eventSourceId: 'AWSSetup',
    ...props,
  });

  return (
    <Form
      header={{
        title: 'AWS Config',
        secondaryTitle:
          'Only single setup done for all repositories for one env.',
      }}
      beforeSubmit={({data}) => {
        if (!data?.repositories?.length) {
          throw new Error('Please select repositories for deployment');
        }
        return {
          ...data,
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        environment_id: environment_id?._id || environment_id,
        project_id: project?._id,
        created_by: user,
      }}
      fields={{
        environment_id: {environment: 1},
        region: 1,
        instance_type: 1,
        instance_ami: 1,
        ports: 1,
        repositories: {
          label: 1,
          type: 1,
          _id: 1,
        },
        setup_name: 1,
      }}
      api={`/projectawssetups/${params.row?._id}`}
      layoutFields={[
        {
          field: 'environment_id',
          label: 'Environment',
          type: 'autoComplete',
          api: '/deploymentenvironments',
          valueField: 'environment',
          suggestionField: 'environment',
          filter: {project_id: project?._id},
          required: true,
          visible: !environment_id,
          size: 6,
          helperText:
            'Select the deployment environment for repositories in AWS.',
          onChangeValue: (value, e, {setFieldValue}) => {
            if (value?.environment) {
              const projectName = project.project
                .toString()
                .split(' ')
                .join('-')
                .toLowerCase();

              setFieldValue('setup_name', projectName);
            } else {
              setFieldValue('setup_name', void 0);
            }
          },
        },
        {
          field: 'setup_name',
          label: 'Setup Name',
          type: 'text',
          size: 6,
          helperText: 'Setup name in AWS.',
          validate: (field, {values}) => {
            if (field?.includes('_')) {
              return '_ not allowed.';
            }
            if (/[A-Z]/.test(field)) {
              return 'Capital letters not allowed.';
            }
          },
        },
        {
          field: 'region',
          type: 'autoComplete',
          label: 'Region',
          options: regions,
          required: true,
          size: 6,
          helperText: `Choose the AWS region where your resources will be located`,
        },
        {
          field: 'instance_type',
          label: 'Instance type',
          type: 'autoComplete',
          options: instanceTypes,
          required: true,
          size: 6,
          helperText: `Select the type of EC2 instance based on the required memory, and storage capabilities for your application`,
        },
        {
          field: 'instance_ami',
          type: 'text',
          label: 'Instance AMI',
          required: true,
          size: 6,
          helperText: `Enter the Amazon Machine Image ID that will be used to launch your EC2 instances`,
        },
        {
          field: 'ports',
          label: 'Ports',
          type: 'text',
          placeholder: '80,22,5001',
          required: true,
          helperText: `List the network ports that need to be open for your application, separated by commas (e.g., '80,443'). Port 22 for SSH access is mandatory for remote management of your EC2 instances.`,
        },
        {
          label: 'Repositories ',
          field: 'repositories',
          type: 'multiAutoComplete',
          api: '/projectrepositories',
          valueField: 'repository',
          suggestionField: 'repository',
          filter: {
            project_id: project?._id,
            deployment_platform: {$ne: null},
            type: {$exists: true},
          },
          required: true,
          helperText: 'Specify repositories for deployment in this environment',
        },
      ]}
      {...props}
    />
  );
};

export const AWSConfigAddForm = props => {
  return <AwsConfigForm {...props} />;
};

export const AWSConfigEditForm = props => {
  return <AwsConfigForm {...props} mode={'edit'} isDualMode />;
};
