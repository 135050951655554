import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const KpiApiTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'kpiapitable',
  });

  return (
    <Table
      eventSourceId={'kpiapitable'}
      api={`/apis`}
      sort={{_id: -1}}
      renderHeader={() => (
        <TableHeader
          title="Apis"
          actions={[
            <AddButton
              title="Add"
              view="add-kpi-api-table"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        name: 1,
        api: 1,
        view: 1,
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
          responsive: 'sm',
        },
        {
          field: 'api',
          header: 'Api',
          responsive: 'sm',
        },
        {
          field: 'view',
          header: 'View',
          responsive: 'sm',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-kpi-api-table', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/apis/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default KpiApiTable;
