import React from 'react';
import {ExpandRowIconRender} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {MailContentRender} from '../components/ExpandableRowComponent';
import {TextRenderer} from '../../../components/form/Renderers';

export const AssignEmailMessageSequence = props => {
  const {formProps} = props;
  const {values = {}} = formProps;
  const {_id: campaign_id} = values;
  const invoke = useInvoke({method: 'put', eventSourceId: ['indexUpdated']});
  return (
    <Table
      {...props}
      renderExpandRow={({row}) => {
        return <MailContentRender subject={row?.subject} message={row?.body} />;
      }}
      fields={{
        subject: 1,
        body: 1,
        marketing_theme: {
          theme_value_proposition: 1,
          type: {
            name: 1,
          },
        },
        sequence: 1,
      }}
      api="/campaignMessages"
      filter={{
        campaign_id,
      }}
      eventSourceId={['indexUpdated']}
      draggable={{
        indexField: 'sequence',
        updateIndexOnDragEnd: props => {
          const {updatedIndex, row} = props;
          invoke({
            uri: `/campaignMessages/${row?._id}`,
            props: {
              sequence: updatedIndex,
            },
          });
        },
      }}
      sort={{
        sequence: 1,
      }}
      
      columns={[
        {
          width: 30,
          render: props => <TextRenderer {...props} value={props.index + 1} />,
        },
        {
          field: 'marketing_theme.theme_value_proposition',
          header: 'Value Proposition',
        },
        {
          field: 'marketing_theme.type.name',
          colorField: 'marketing_theme.type.color',
          header: 'Category',
          type: 'colorTag',
          width: 150,
        },
        {
          render: ExpandRowIconRender,
          expandable: true,
          width: 50,
        },
      ]}
    />
  );
};
