import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';

const EmployeeAccessTable = ({navigation}) => {
  const {applyFilter, filterValues} = useFilter({});
  const {filter={}} = filterValues || {};
  return (
    <Table
      eventSourceId="EmployeeAccess"
      api={`/employeeaccesses`}
      addOnFilter={filter}
      renderHeader={() => {
        return (
          <TableHeader
            title="Employee Access"
            actions={[
              <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[ {
                type: 'autoComplete',
                label: 'Employee',
                api: '/employeeSuggestions',
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'employee',
                searchFields: ['name', 'official_email_id', 'employee_code'],
              },]}
            />,
            ]}
          />
        );
      }}
      fields={{
        role: 1,
        department: {name: 1},
        product: {name: 1},
        employee: {name: 1},
      }}
      onRowPress={({row}) => {
        navigation.navigate(`employee-access-detail`, {
          employeeAccessId: row._id,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'employee.name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'role',
          header: 'Role',
        },
        {
          type: 'text',
          field: 'product.name',
          header: 'Product',
        },
        {
          type: 'text',
          field: 'department.name',
          header: 'Department',
        },
      ]}
    />
  );
};

export default EmployeeAccessTable;
