import {InputStyles} from '../text/theme';

export const DurationInputStyles = ({theme}) => {
  return {
    ...InputStyles({theme}),
    text: {
      ...theme.fonts.BODY3,
      color: theme.colors.NEUTRAL_HIGH,
    },
  };
};
