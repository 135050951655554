import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

const ExtendedDateDetails = props => {
  let {
    route: {params},
  } = props;
  let {data = {}, header = {}, training_batch} = params || {};
  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            title={`Course Extension(${header.name})`}
            secondaryTitle={training_batch.name}
          />
        );
      }}
      data={data}
      columns={[
        {
          field: 'extended_no',
          header: 'Extension',
        },
        {
          field: 'original_date',
          header: 'Original',
          type: 'date',
        },
        {
          field: 'extended_date',
          header: 'Extended',
          type: 'date',
        },
        {
          field: 'reason_of_extension',
          header: 'Reason',
        },
      ]}
      params={params}
    />
  );
};

export default ExtendedDateDetails;
