import React, {useState, useEffect, useRef} from 'react';
import Text from '../Text';
import View from '../View';
import Image from '../Image';
import ScrollView from '../ScrollView';
import StyleSheet from '../StyleSheet';

let uids = {};
let animatedValueListeners = {};

const addAnimationListener = ({value, uid, callback, viewName, key}) => {
  let valueUid = value.uid;
  animatedValueListeners[valueUid] = animatedValueListeners[valueUid] || [];
  let listener = animatedValueListeners[valueUid].find(doc => doc.uid === uid);
  if (!listener) {
    animatedValueListeners[valueUid].push({
      uid,
      callback,
      viewName,
      key,
      valueName: value.name,
    });
  }
};

const fireAnimationListener = ({value}) => {
  let valueUid = value.uid;
  animatedValueListeners[valueUid] = animatedValueListeners[valueUid] || [];
  animatedValueListeners[valueUid].forEach(doc => {
    doc.callback();
  });
};

const getUID = key => {
  uids[key] = uids[key] || 0;
  uids[key]++;
  return key + '_' + uids[key];
};

function Interpolate({value, inputRange, outputRange}) {
  this.value = value;
  this.inputRange = inputRange;
  this.outputRange = outputRange;
}

Interpolate.prototype.getValue = function ({viewName, key}) {
  let currentValue = this.value.getValue({
    viewName,
    key,
    interpolate: 'interpolate',
  });
  let outValue = currentValue;
  if (this.inputRange && this.outputRange) {
    for (let index = 0; index < this.inputRange.length; index++) {
      if (this.inputRange[index] === currentValue) {
        outValue = this.outputRange[index];
        break;
      }
    }
  }
  return outValue;
};

function Value(value, name) {
  this.name = name;
  this.uid = getUID('Value');
  this.value = value;
}

Value.prototype.setValue = function ({toValue}) {
  this.value = toValue;
  fireAnimationListener({value: this});
};

Value.prototype.interpolate = function ({inputRange, outputRange}) {
  return new Interpolate({value: this, inputRange, outputRange});
};

Value.prototype.getValue = function ({viewName, key, interpolate} = {}) {
  return this.value;
};

function ValueXY(value, name) {
  let {x, y} = value || {};
  this.valueX = new Value(x, name);
  this.valueY = new Value(y, name);
  this.xOffset = 0;
  this.yOffset = 0;
  this.x = this.xOffset + this.valueX.value;
  this.y = this.yOffset + this.valueY.value;
}

ValueXY.prototype.setValue = function ({x, y}) {
  this.valueX.setValue({toValue: x + this.xOffset});
  this.valueY.setValue({toValue: y + this.yOffset});
  fireAnimationListener({valueX: this.valueX});
  fireAnimationListener({valueY: this.valueY});
};

ValueXY.prototype.setOffset = function ({x, y}) {
  this.xOffset = x;
  this.yOffset = y;
};

ValueXY.prototype.getLayout = function () {
  return {top: this.x, left: this.y};
};

const AnimatedView = ({style: propStyle, name, ...rest}) => {
  const [toggled, setToggled] = useState(false);
  const uid = useRef(getUID('AnimatedView')).current; // Generate UID once
  let style = Array.isArray(propStyle)
    ? StyleSheet.flatten(propStyle)
    : propStyle; // Define style here

  const onValueChange = () => {
    setToggled(prevToggled => !prevToggled);
  };

  useEffect(() => {
    for (let k in style) {
      let styleValue = style[k];
      if (styleValue instanceof Value) {
        addAnimationListener({
          value: styleValue,
          uid,
          key: k,
          viewName: name,
          callback: onValueChange,
        });
        let animatedValue = styleValue.getValue({viewName: name, key: k});
        style[k] = animatedValue;
      } else if (styleValue instanceof Interpolate) {
        addAnimationListener({
          value: styleValue.value,
          uid,
          key: k,
          viewName: name,
          callback: onValueChange,
        });
        let animatedValue = styleValue.getValue({viewName: name, key: k});
        style[k] = animatedValue;
      }
    }

    // Cleanup function to remove listeners if necessary
    return () => {
      // Implement cleanup logic if needed
      // e.g., removeAnimationListener(uid);
    };
  }, [propStyle, name, uid, style]); // Make sure to include style in dependencies if it can change

  return <View {...rest} style={style} />;
};
const FlatList = ({
  data = [],
  renderItem,
  style,
  ListHeaderComponent,
  ListFooterComponent,
  horizontal,
  renderLoadMore,
}) => {
  if (horizontal) {
    style = {...style, flexDirection: 'row'};
  }
  const Component = style && !style.flex ? View : ScrollView;

  return (
    <Component style={style}>
      {ListHeaderComponent && ListHeaderComponent()}
      {data.map((item, index) => (
        <View key={index}>{renderItem({item, index})}</View>
      ))}
      {renderLoadMore && renderLoadMore()}
      {ListFooterComponent && ListFooterComponent()}
    </Component>
  );
};

const SectionList = ({
  sections = [],
  renderItem,
  style,
  renderSectionHeader,
  ListHeaderComponent,
}) => {
  return (
    <ScrollView style={style}>
      {ListHeaderComponent && ListHeaderComponent()}
      {sections.map((section, index) => {
        const {data = []} = section;
        return (
          <View key={index}>
            <View>{renderSectionHeader({section, index})}</View>
            {data.map((item, idx) => (
              <View key={idx}>{renderItem({item, section, index: idx})}</View>
            ))}
          </View>
        );
      })}
    </ScrollView>
  );
};

let event = () => {};
let timing = (value, config) => {
  const start = callback => {
    setTimeout(() => {
      let {toValue} = config;
      value.setValue({toValue});
      if (callback) {
        setTimeout(() => {
          callback({finished: true});
        }, 100);
      }
    }, 1000);
  };
  const stop = () => {};
  return {start, stop};
};
let parallel = () => {};
let diffClamp = () => {};
let add = () => {};
let spring = () => {
  return {
    stop: () => {},
  };
};

let createAnimatedComponent = Component => {
  return Component;
};

let Animated = {
  View: AnimatedView,
  Text,
  ScrollView,
  FlatList,
  SectionList,
  Image,
  Value,
  ValueXY,
  event,
  timing,
  parallel,
  spring,
  diffClamp,
  add,
  createAnimatedComponent,
};

export default Animated;
