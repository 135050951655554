import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const CurrencyForm = props => {
  const {
    route: {params},
  } = props;
  const {currencyId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/currencies',
    eventSourceId: 'Currency',
  });
  return (
    <Form
      api={`/currencies/${currencyId}`}
      onSubmit={onSubmit}
      header="Add Currency"
      submitAction={"Save"}
      layoutFields={[
        {
          label: 'Currency',
          field: 'currency',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const AddCurrencyForm = props => {
  return <CurrencyForm header="Add Currency" {...props} />;
};

export const UpdateCurrencyForm = props => {
  return <CurrencyForm mode="edit" header="Update Currency" {...props} />;
};
