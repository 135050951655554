import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AppModuleInputForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {product, department, row, target_audience, organization} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/appmodules',
    eventSourceId: 'appModules',
    ...props,
  });
  return (
    <Form
      api={`/appmodules/${row?._id}`}
      fields={{
        module: 1,
        label: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add App Module'}}
      layoutFields={[
        {
          label: 'Module',
          type: 'text',
          field: 'module',
          required: true,
        },
        {
          label: 'Label',
          type: 'text',
          field: 'label',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditAppModuleInputForm = props => {
  return (
    <AppModuleInputForm
      header={{title: 'Edit App Module'}}
      mode="edit"
      {...props}
    />
  );
};
