import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const BugForm = props => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Bug',
    uri: '/projectbugs',
    ...props,
  });

  const {
    route: {params = {}},
    navigation,
  } = props;
  const {project, module, feature, bug, readOnly} = params;

  const defaultValues = {
    status: 'Open',
    project_id: project?._id,
    module_id: module?._id,
    feature_id: feature?._id,
  };

  return (
    <Form
      api={`/projectbugs/${bug?._id}`}
      fields={{
        bug: 1,
        file: 1,
        owner_id: {name: 1},
        status: 1,
        desc: 1,
        source: {name: 1},
        source_id: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Bug',
          type: 'text',
          field: 'bug',
          mandatory: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'desc',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Source',
          field: 'source',
          type: 'cascader',
          cascades: [
            {
              label: 'Source',
              field: 'source',
              api: '/types/suggestions',
              filter: {pmt: true, visibility: {$in: ['bug']}},
              suggestionField: 'label',
              valueField: 'name',
            },
            {
              label: 'Source Value',
              field: 'source_id',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              filter: {project_id: project?._id},
              params: ({values}) => {
                const {source} = values || {};
                return {
                  source,
                };
              },
              createNew: true,
              onCreateNewClick: CASCADER_NAVIGATE_TO_ADD_VIEW({
                navigation,
                params,
              }),
            },
          ],
        },
        {
          label: 'File',
          field: 'file',
          type: 'file',
          multiple: true,
        },
        {
          label: 'Owner',
          field: 'owner_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddBugForm = props => {
  return <BugForm header="Add Bug" {...props} />;
};

export const UpdateBugForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {readOnly} = params;
  const header = readOnly ? 'Bug Details' : 'Update Bug';

  return <BugForm mode="edit" header={header} {...props} />;
};
