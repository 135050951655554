import React, {useState} from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {Button} from '../../../components/button/Button';
import {View} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {OutlineButton} from '../../../components/button/OutlineButton';

const RetrieveRender = ({setFieldValue, values}) => {
  let invoke = useInvoke({method: 'post'});

  const [loading, setLoading] = useState();

  const retrievePrompt = async props => {
    try {
      setLoading(true);
      let {data = {}} = await invoke({
        uri: `/finetune/generate-message-from-ai`,
        props: {
          ...props,
          model: 'gpt-4-1106-preview',
          response_format: {response_format: {type: 'json_object'}},
        },
      });

      let {airesponse} = data || {};
      setLoading(false);
      let {messages} = values || {};
      messages = [...messages];
      messages.push(airesponse);

      setFieldValue('messages', messages);
    } catch (error) {
      throw error;
    }
  };
  return (
    <View style={{alignItems: 'flex-end'}}>
      <Button
        text={'Generate Response'}
        onPress={() => {
          return retrievePrompt(values);
        }}
        loading={loading}
      />
    </View>
  );
};

const promptComputations = post => ({
  set_system_message: {
    multi: true,
    compute: async value => {
      const {
        target_audience,
        usecase_id,
        aiprompt,
        value_proposition,
        potential_customer,
        email_profile,
      } = value;

      const {
        data: {prompt = [], contact_id},
      } = await post({
        uri: '/finetune/generate-prompt',
        props: {
          target_audience,
          usecase_id,
          aiprompt,
          value_proposition,
          potential_customer,
          email_profile,
        },
      });
      return {
        messages: prompt,
        contact_id,
      };
    },
    dependencies: ['value_proposition'],
  },
});

export const FinetuneAddMessage = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/finetune/add-documents-training-data',
    eventSourceId: 'addDocument',
    ...props,
  });

  let {potential_customer} = params || {};
  const {post} = useAppStateContext();
  return (
    <Form
      header={'Add Message'}
      onSubmit={onSubmit}
      defaultValues={{
        potential_customer,
      }}
      computations={{...promptComputations(post)}}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Usecase',
          field: 'usecase_id',
          type: 'autoComplete',
          api: '/finetuneusecases',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'AIprompt',
          field: 'aiprompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Email',
          field: 'email_profile',
          type: 'autoComplete',
          api: '/emailprofiles',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Target Audience',
          field: 'target_audience',
          type: 'autoComplete',
          api: '/buyerpersonas',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Value Proposition',
          field: 'value_proposition',
          type: 'autoComplete',
          api: '/valuepropositions',
          suggestionField: 'name',
          valueField: 'name',
          filter: ({values}) => {
            return {
              target_audience: values?.target_audience?._id,
            };
          },
        },
        {
          label: 'Model',
          field: 'fine_tune_model',
          type: 'autoComplete',
          api: '/finetunejobs',
          filter: {status: 'succeeded'},
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Messages',
          field: 'messages',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  field: 'role',
                  type: 'autoComplete',
                  placeholder: 'role',
                  header: 'Role',
                  options: ['system', 'user', 'assistant'],
                },
                {
                  field: 'content',
                  placeholder: 'content',
                  header: 'Content',
                  type: 'textArea',
                },
                {
                  render: props => {
                    let {row} = props || {};
                    let {role} = row || {};
                    return role === 'assistant' ? (
                      <View style={{alignItems: 'flex-end'}}>
                        <OutlineButton
                          text={'view'}
                          onPress={() => {
                            navigation.navigate('view-assitant-response', {
                              row,
                            });
                          }}
                        />
                      </View>
                    ) : null;
                  },
                },
              ]}
            />
          ),
        },
        {
          render: (props, {setFieldValue, values}) => {
            return (
              <RetrieveRender setFieldValue={setFieldValue} values={values} />
            );
          },
        },
      ]}
      {...props}
    />
  );
};
