import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';

export const EmailProfileAddForm = props => {
  const {header = 'Add Profile', route: params = {}, navigation} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/emailprofiles',
    eventSourceId: ['profileAdded'],
  });

  return (
    <Form
      fields={{
        emal: 1,
        first_name: 1,
        last_name: 1,
        email: 1,
        email_provider_id: {name: 1},
        designation: 1,
        email_day_limit: 1,
        startup_email_day_limit: 1,
        secondary_profiles: {email_profile: {email: 1}, email_limit: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'first_name',
          type: 'text',
          label: 'First Name',
          placeholder: 'First Name',
          required: true,
        },
        {
          field: 'last_name',
          type: 'text',
          label: 'Last Name',
          placeholder: 'Last Name',
          required: true,
        },
        {
          field: 'email',
          type: 'text',
          label: 'Email',
          placeholder: 'Email',
          required: true,
        },
        {
          field: 'designation',
          type: 'text',
          label: 'Designation',
          placeholder: 'Designation',
          required: true,
        },
        {
          field: 'email_provider_id',
          type: 'autoComplete',
          label: 'Email Provider',
          placeholder: 'Email Provider',
          api: `/emailproviders`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          // onCreate: () => {
          //   navigation.navigate(`email-provider-add-form`, {
          //     ...params,
          //   });
          // },
        },
        {
          label: 'Email limit',
          field: 'email_day_limit',
          type: 'text',
        },
        {
          label: 'Startup Email limit',
          field: 'startup_email_day_limit',
          type: 'number',
        },
        {
          label: 'Secondary Profile',
          field: 'secondary_profiles',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  header: 'Email Profile',
                  field: 'email_profile',
                  placeholder: 'Email Profile',
                  type: 'autoComplete',
                  api: '/emailprofiles',
                  suggestionField: 'email',
                  valueField: 'email',
                  size: 2,
                },
                {
                  label: 'Email limit',
                  field: 'email_limit',
                  placeholder: 'Email limit',
                  type: 'number',
                },
              ]}
              columns={[
                {
                  header: 'Email Profile',
                  field: 'email_profile.email',
                  placeholder: 'Email Profile',
                  size: 2,
                },
                {
                  header: 'Email limit',
                  field: 'email_limit',
                  placeholder: 'Email limit',
                  type: 'number',
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};

export const EmailProfileEditForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <EmailProfileAddForm
      mode="edit"
      api={`/emailprofiles/${_id}`}
      header={'Edit Profile'}
      {...props}
    />
  );
};
