import { getZeroTimeDate } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const Candidate = props => {
  const {route: {params} = {}} = props;
  const {job_details} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: ['candidate', 'JobOpeningActive'],
  });

  const {user = {}} = useAppStateContext();
  const {employee = {}} = user;
  const defaultValues = {
    status: 'Sortlisted',
    candidate_created_by: user?._id,
    candidate_created_on: getZeroTimeDate(new Date()),
    organization: employee?.organization,
    product: employee?.product,
    candidate_manager: employee?._id,
    job_opening: job_details?._id,
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Personal Details',
          fields: [
            {
              type: 'text',
              field: 'name',
              label: 'Name',
              placeholder: 'Name',
              size: 6,
              required: true,
            },
            {
              placeholder: 'Experience',
              field: 'experience',
              label: 'Experience',
              type: 'autoComplete',
              api: '/employeeexperiences',
              suggestionField: 'experience',
              valueField: 'experience',
              size: 6,
              required: true,
            },
            {
              type: 'text',
              field: 'email',
              label: 'Email',
              placeholder: 'Email',
              size: 6,
              required: true,
            },
            {
              type: 'number',
              field: 'mobile',
              label: 'Mobile',
              placeholder: 'Mobile',
              size: 6,
              required: true,
            },
            {
              label: 'Recruiter',
              placeholder: 'Recruiter',
              field: 'recruiter',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              required: true,
            },
          ],
        },
        {
          label: 'CV & Portfolio',
          fields: [
            {
              type: 'autoComplete',
              field: 'candidate_source',
              label: 'Source',
              placeholder: 'Source of Candidate',
              options: [
                'Referral',
                'Job Portal',
                'Walk-in',
                'Advertisement/Promotions',
                'Consultant',
                'Social Media Platforms',
              ],
              size: 6,
            },
            {
              type: 'text',
              field: 'portfolio_link',
              label: 'Portfolio Link',
              placeholder: 'Link',
              size: 6,
            },
            {
              type: 'file',
              label: 'Resume',
              field: 'candidate_resume',
              size: 12,
              placeholder: 'Upload CV or Resume',
              multiple: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddCandidate = props => {
  return <Candidate header="Add new candidate" {...props} />;
};

export const EditCandidate = props => {
  const {
    route: {params},
  } = props;
  const {candidateId} = params || {};
  return (
    <Candidate
      header="Edit candidate"
      mode="edit"
      api={`/candidates/${candidateId}`}
      fields={{
        name: 1,
        experience: {
          experience: 1,
        },
        email: 1,
        mobile: 1,
        candidate_resume: 1,
        candidate_source: 1,
        portfolio_link: 1,
        recruiter: {
          name: 1,
        },
      }}
      {...props}
    />
  );
};
