import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const UpdateJiraCredentialTokenForm = props => {
  const {
    route: {params},
  } = props;

  const {project_id, row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/projectjiracredentials',
    eventSourceId: 'ProjectJiraCredential',
  });
  const defaultValues = {
    project_id,
  };
  return (
    <Form
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/projectjiracredentials/${row?._id}`}
      defaultValues={defaultValues}
      fields={{}}
      layoutFields={[
        {
          label: 'Token',
          type: 'text',
          field: 'token',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const EditProjectJiraCredentialTokenForm = props => {
  return (
    <UpdateJiraCredentialTokenForm
      {...props}
      mode="edit"
      header="Edit Jira Credentail Token"
    />
  );
};
