import React, {useEffect, useRef} from 'react';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {EmailPageStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {BaseForm} from '../../../components/form/Form';
import {Button} from '../../../components/button/Button';
import {useToast} from '@unthinkable/react-toast';
import {PasswordInput} from '../../../components/form-editors/Editors';
import { termsOfServiceURL } from '../../../modules/common/constants/SourceConstants';

const CreatePassword = props => {
  const {navigation, route: {params} = {}} = props || {};
  const {
    containerStyle,
    mainContainerStyle,
    subContainerStyle,
    buttonStyles,
    backButtonIcon,
    LOCOMOIcon,
    headingTextStyle,
    iconContainerStyle,
    textStyle,
    UnthinkableIcon,
    spanTextStyle,
    secondaryTextStyle,
  } = useStyles(EmailPageStyles);

  const inputRef = useRef(null);
  const input2Ref = useRef(null);

  useEffect(()=>{
    inputRef.current.focus();
  },[])

  const {email, onLogin} = params || {};

  const {post} = useAppStateContext();

  const toast = useToast();

  const onSubmit = async values => {
    try {
      const {data} = await post({
        uri: `/registerUser`,
        method: 'post',
        props: {
          ...values,
        },
      });
      if (data) {
        onLogin(values);
      }
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      input2Ref.current.focus();
    }
  };

  const handleKeySubmit = (e, {values}) => {
    let data = {...values, email};
    if (e.key === 'Enter') {
      onSubmit(data);
    }
  };

  return (
    <View style={containerStyle}>
      <View style={subContainerStyle}>
        <Image source={LOCOMOIcon} style={iconContainerStyle} />
        <View style={{...mainContainerStyle, marginBottom: 40}}>
          <Row gap={12} style={{alignItems: 'center', marginBottom: 12}}>
            <TouchableOpacity
              onPress={() => {
                navigation.goBack();
              }}>
              <Image source={backButtonIcon} />
            </TouchableOpacity>
            <Text style={headingTextStyle}>Continue with email</Text>
          </Row>
          <Text style={textStyle}>
            Seems like you are new here!
            <Text>Please set a password for your account</Text>
          </Text>
          <BaseForm
            skipHeader
            footer={({values}) => {
              let data = {...values, email};
              return {
                actions: [
                  <View style={{flex: 1, marginTop: 28}}>
                    <Button
                      onPress={() => {
                        onSubmit(data);
                      }}
                      text="Continue"
                      styles={buttonStyles}
                      gap={8}
                      disabled={
                        !(values && values.password && values.confirmPassword)
                      }
                    />
                  </View>,
                ],
              };
            }}
            skipDefaultSave
            layoutFields={[
              {
                render: (props, {values, setFieldValue}) => {
                  const inputProps = {
                    field: 'password',
                    label: 'Set Password',
                    required: true,
                  };

                  return (
                    <PasswordInput
                      {...inputProps}
                      {...props}
                      onKeyPress={events => {
                        handleKeyPress(events, {values});
                      }}
                      value={values.password}
                      onChangeText={text => {
                        setFieldValue('password', text);
                      }}
                      ref={inputRef}
                    />
                  );
                },
              },
              {
                render: (props, {values, setFieldValue}) => {
                  const inputProps = {
                    field: 'confirmPassword',
                    label: 'Confirm Password',
                    required: true,
                  };

                  return (
                    <PasswordInput
                      {...inputProps}
                      {...props}
                      onKeyPress={events => {
                        handleKeySubmit(events, {values});
                      }}
                      value={values.confirmPassword}
                      onChangeText={text => {
                        setFieldValue('confirmPassword', text);
                      }}
                      ref={input2Ref}
                    />
                  );
                },
              },
            ]}
            {...props}
          />
          <Text style={secondaryTextStyle}>
            By signing up, you agree to our{' '}
            <Text as={'span'} style={spanTextStyle}  onPress={() => Linking.openURL(termsOfServiceURL)}>
              Terms of Service
            </Text>{' '}
            {/* and{' '}
            <Text as={'span'} style={spanTextStyle}>
              Data processing Agreement
            </Text> */}
          </Text>
        </View>
        <Image source={UnthinkableIcon} />
      </View>
    </View>
  );
};

export default CreatePassword;
