import React from 'react';
import {
  Linking,
  Text,
  View,
  TouchableWithoutFeedback,
} from '@unthinkable/react-core-components';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {vscDarkPlus} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {useStyles} from '@unthinkable/react-theme';
import {PreText as PreTextStyle} from './theme';

const PreText = ({text, textStyle, isAnswer, answerGenerated, mode}) => {
  text = text || '';
  let splitText = text.split('```');
  const theme = useStyles(PreTextStyle);
  const {tableRowStyle, cellStyle} = theme;

  const splitDoc = sentence => {
    const splitArray = sentence.split(/\n+/);
    for (let i = 0; i < splitArray.length - 1; i++) {
      if (sentence.indexOf(splitArray[i] + '\n\n' + splitArray[i + 1]) !== -1) {
        splitArray[i] += '\n\n';
      }
    }
    return splitArray;
  };

  const parseStyledText = text => {
    const regex = /(\*\*\*|\*\*|__|\*|_)(.*?)\1/g;
    const regexLinks = /(https?:\/\/[^\s]+)/g;

    let parts = [];
    let lastIndex = 0;

    text.replace(regex, (match, delimiter, styledText, index) => {
      const normalText = text.substring(lastIndex, index);

      if (normalText) {
        parts.push({text: normalText});
      }

      const isBold = delimiter === '**' || delimiter === '***';
      const isItalic =
        delimiter === '*' || delimiter === '_' || delimiter === '***';
      // const isUnderline = delimiter === '__';

      // Currently 'underline' is not working. In verbee we are receiving <span> tag for underline.
      // Will add this functionality in future

      parts.push({text: styledText, isBold, isItalic});
      lastIndex = index + match.length;
    });

    const remainingText = text.substring(lastIndex);

    if (remainingText) {
      parts.push({text: remainingText});
    }

    parts = parts
      .map(part => {
        const linkMatches = part.text.match(regexLinks);
        if (linkMatches) {
          const newParts = [];
          let lastLinkEndIndex = 0;
          linkMatches.forEach(link => {
            const linkStartIndex = part.text.indexOf(link, lastLinkEndIndex);
            const normalText = part.text.substring(
              lastLinkEndIndex,
              linkStartIndex,
            );

            if (normalText) {
              newParts.push({...part, text: normalText});
            }

            newParts.push({text: link, isLink: true});
            lastLinkEndIndex = linkStartIndex + link.length;
          });

          const remainingText = part.text.substring(lastLinkEndIndex);
          if (remainingText) {
            newParts.push({...part, text: remainingText});
          }

          return newParts;
        }

        return part;
      })
      .flat();

    return parts;
  };

  return splitText.map((doc, index) => {
    let outerIndex = index;
    if (index % 2 === 1) {
      return (
        <>
          <SyntaxHighlighter
            language="javascript"
            style={vscDarkPlus}
            highlighter={'prism'}
            className="hide-horizontal-scroll-indicator">
            {doc}
          </SyntaxHighlighter>
          {!answerGenerated &&
            mode === 'insert' &&
            outerIndex === splitText.length - 1}
        </>
      );
    } else if (isAnswer) {
      const regex = /^(?:[^|]*\|){3,}/;
      const arrDoc = splitDoc(doc);
      let isFirstTime = true;

      return arrDoc.map((row, index) => {
        let arrDocIndex = index;
        const isTableFormat = regex.test(row);
        const hasDashes = /-{3,}/;

        if (hasDashes.test(row)) {
          return null;
        }

        if (isTableFormat) {
          const arrRow = row.split('|').filter(cell => cell.trim() !== '');

          const table = (
            <View style={tableRowStyle}>
              {arrRow.map((cell, cellIndex) => {
                return (
                  <View
                    key={cellIndex}
                    style={[cellStyle, isFirstTime ? {marginTop: 20} : {}]}>
                    <Text
                      style={[
                        {whiteSpace: 'pre-wrap'},
                        textStyle,
                        isFirstTime ? {fontWeight: 'bold'} : {},
                      ]}>
                      {cell}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
          if (isFirstTime) {
            isFirstTime = false;
          }
          return table;
        } else {
          const parsedText = parseStyledText(row);

          return (
            <Text style={{whiteSpace: 'pre-wrap', ...textStyle}}>
              {parsedText.map((part, index) => {
                return part.isLink ? (
                  <TouchableWithoutFeedback
                    onPress={() => {
                      Linking.openURL(part.text, '_blank');
                    }}>
                    <Text
                      as="span"
                      style={[
                        part.isLink
                          ? {
                              color: '#0645AD',
                              cursor: 'pointer',
                            }
                          : null,
                      ]}>
                      {part.text}
                      {!answerGenerated &&
                        mode === 'insert' &&
                        outerIndex === splitText.length - 1 &&
                        arrDocIndex === arrDoc.length - 1 &&
                        index === parsedText.length - 1}
                    </Text>
                  </TouchableWithoutFeedback>
                ) : (
                  <Text
                    as={'span'}
                    key={index}
                    style={[
                      part.isBold ? {fontWeight: 'bold'} : null,
                      part.isItalic ? {fontStyle: 'italic'} : null,
                      // part.isUnderline ? {textDecorationLine: 'underline'} : null,
                    ]}>
                    {part.text}
                    {!answerGenerated &&
                      mode === 'insert' &&
                      outerIndex === splitText.length - 1 &&
                      arrDocIndex === arrDoc.length - 1 &&
                      index === parsedText.length - 1}
                  </Text>
                );
              })}
            </Text>
          );
        }
      });
    } else {
      return <Text style={{whiteSpace: 'pre-wrap', ...textStyle}}>{doc}</Text>;
    }
  });

  // if (!Array.isArray(text)) {
  //   text = text.split('\n');
  // }

  // let followingText = [];
  // for (let i of text) {
  //   followingText.push(i || ' ');
  // }
};

export default PreText;
