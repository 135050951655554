import React from 'react';
import {Table} from '../../../components/table/Table';
import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {TableHeader} from '../../../components/table/Headers';
import {useInvoke} from '../../../controllers/useInvoke';

export const DataEntityViews = props => {
  const {
    route: {params},
  } = props;

  const {dataEntity} = params || {};

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'DataEntityView',
    uri: '/dataentityviews',
    ...props,
  });

  const markDelete = useInvoke({
    method: 'delete',
    eventSourceId: 'DataEntityView',
  });

  return (
    <Table
      renderHeader={() => {
        return (
          <>
            <TableHeader title="Views" secondaryTitle={dataEntity?.label} />
            <InlineForm
              onSubmit={onSubmit}
              defaultValues={{
                data_entity_id: dataEntity?._id,
              }}
              submitAction="Save"
              layoutFields={[
                {field: 'label', type: 'text', label: 'Label', required: true},
                {field: 'view', type: 'text', label: 'View', required: true},
                {
                  field: 'type',
                  type: 'autoComplete',
                  label: 'Type',
                  options: ['List', 'Form'],
                  width: 150,
                },
                {
                  field: 'filter_api',
                  type: 'text',
                  label: 'Filter API',
                },
              ]}
            />
          </>
        );
      }}
      api={`/dataentityviews`}
      eventSourceId={['DataEntityView']}
      filter={{
        data_entity_id: dataEntity?._id,
      }}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            props.navigation.navigate('data-entity-view-edit', {
              dataEntityView: row,
            });
          },
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete this view?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            markDelete({
              uri: `/dataentityviews/${row._id}`,
            });
          },
        },
      ]}
      columns={[
        {
          field: 'label',
          header: 'Label',
        },
        {
          field: 'view',
          header: 'View',
        },
        {
          field: 'type',
          header: 'Type',
          width: 150,
        },
        {
          field: 'filter_api',
          header: 'Filter API',
        },
      ]}
    />
  );
};
