import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {Table} from '@unthinkable/react-table';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';

const renderSkillRequirment = (values, props, onSelectionChange) => {
  const partialOrderIds = [];
  values?.order_details?.forEach(item => {
    if (item.consume === 'Partial') {
      partialOrderIds.push(item.order._id);
    }
  });

  const {data = [], ...restFetchProps} = useFetchData({
    api: `/skillrequirments`,
    filter: {order: {$in: partialOrderIds}, project: {$exists: false}},
    fields: {
      _id: 1,
      order: {_id: 1, order_number: 1},
      tool: {name: 1, color: 1},
      from_date: 1,
      to_date: 1,
      experience: {experience: 1},
      employee_per_day_working_hour: 1,
      order_resource_requirement: {notes: 1},
      skill_requirement_no: 1,
    },
  });

  return (
    <Table
      {...props}
      data={data}
      restFetchProps={restFetchProps}
      selection={{
        onSelectionChange,
      }}
      columns={[
        {
          field: 'order.order_number',
          header: 'Order',
          width: 120,
        },
        {
          field: 'skill_requirement_no',
          header: 'Skill Requirment',
          width: 150,
        },
        {
          field: 'tool.name',
          header: 'Skill',
          type: 'colorTag',
          colorField: 'tool.color',
          width: 100,
        },
        {
          field: 'from_date',
          header: 'Start',
          type: 'date',
          formatOptions: {format: 'DD MMM YYYY'},
          width: 120,
        },
        {
          field: 'to_date',
          header: 'End',
          type: 'date',
          formatOptions: {format: 'DD MMM YYYY'},
          width: 120,
        },
        {
          field: 'employee_per_day_working_hour',
          header: 'Hrs/day',
          width: 80,
        },
        {
          field: 'order_resource_requirement.notes',
          header: 'Name Resource',
          minWidth: 180,
        },
      ]}
    />
  );
};

export const TAndMOrderMapForm = props => {
  const {
    route: {params = {}},
    header = 'Map order',
  } = props;
  const {row, fromOrder} = params;
  const {orderDefaultValue = {}} = row;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['performance-report'],
    throwError: true,
  });
  let orderFilter = {};
  if (row?.projectTab === 'Billed') {
    orderFilter = {
      order_status: {$in: ['Active', null]},
      order_type: 'r',
      order_exists_type: {$in: ['New', 'Extension']},
      customer_id: row?.customer?._id,
    };
  }
  if (row?.projectTab === 'NonBilled') {
    orderFilter = {
      order_status: {$in: ['Active', null]},
      order_type: 'r',
      order_exists_type: {$in: ['Support']},
      customer_id: row?.customer?._id,
    };
  }

  return (
    <Form
      onSubmit={async data => {
        const projectId = data?.order_details?.[0]?.project?._id;
        await invoke({
          uri: '/tandm/mapOrders',
          props: {...data, ...{project: fromOrder ? projectId : row?._id}},
        });
      }}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'order_details',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              defaultValues={{...orderDefaultValue}}
              inlineAddForm
              fields={[
                {
                  field: 'order',
                  placeholder: 'Order Number',
                  label: 'Order Number',
                  header: 'Order Number',
                  type: 'autoComplete',
                  api: `/orders`,
                  suggestionField: 'order_number',
                  valueField: 'order_number',
                  filter: {
                    customer: row?.customer?._id,
                  },
                  size: 6,
                  required: true,
                },
                {
                  field: 'project',
                  placeholder: 'Project',
                  header: 'Project',
                  label: 'Project',
                  type: 'autoComplete',
                  api: `/projects`,
                  suggestionField: 'project',
                  valueField: 'project',
                  filter: {
                    customer: row?.customer?._id,
                    project_status: 'a',
                  },
                  size: 3,
                  required: true,
                },
                {
                  field: 'consume',
                  placeholder: 'Consume',
                  label: 'Consume',
                  header: 'Consume',
                  type: 'autoComplete',
                  options: ['Partial', 'Full', 'Update'],
                  size: 3,
                  required: true,
                },
              ]}
              columns={[
                {
                  field: 'order.order_number',
                  header: 'Order',
                  minWidth: 540,
                  type: 'colorTag',
                  color: '#F5E0FF',
                },
                {
                  field: 'consume',
                  header: 'Consume',
                },
              ]}
            />
          ),
        },
        {
          label: 'Select partial resource order for the project',
          fields: [
            {
              field: 'skill_requirements',
              render: (_, {values, setFieldValue}) => {
                return renderSkillRequirment(values, props, selectedIds => {
                  setFieldValue('skill_requirements', selectedIds);
                });
              },
            },
          ],
        },
      ]}
    />
  );
};

export const FixedOrderMapForm = props => {
  const {
    route: {params = {}},
    header = 'Map order',
  } = props;
  const {row, fromOrder, forProjectUpdate = false} = params;
  const {orderDefaultValue = {}} = row;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['performance-report'],
    throwError: true,
  });
  return (
    <Form
      onSubmit={async data => {
        const projectId = data?.order_details?.[0]?.project?._id;
        await invoke({
          uri: '/fixed/mapOrders',
          props: {
            ...data,
            ...{project: fromOrder ? projectId : row?._id, forProjectUpdate},
          },
        });
      }}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'order_details',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              defaultValues={{...orderDefaultValue}}
              inlineAddForm
              fields={[
                {
                  field: 'order',
                  placeholder: 'Order Number',
                  label: 'Order Number',
                  header: 'Order Number',
                  type: 'autoComplete',
                  api: `/orders`,
                  suggestionField: 'order_number',
                  valueField: 'order_number',
                  filter: {
                    order_type: 'ot',
                    customer_id: row?.customer?._id,
                  },
                  fields: {
                    order_number: 1,
                    order_date: 1,
                    order_end_date: 1,
                    est_order_hrs: 1,
                  },
                  onChangeValue: (value, _, {setFieldValue}) => {
                    setFieldValue('start_date', value?.order_date);
                    setFieldValue('end_date', value?.order_end_date);
                    setFieldValue('hours', value?.est_order_hrs);
                  },
                  size: 6,
                  required: true,
                },
                {
                  field: 'project',
                  placeholder: 'Project',
                  label: 'Project',
                  header: 'Project',
                  type: 'autoComplete',
                  api: `/projects`,
                  suggestionField: 'project',
                  valueField: 'project',
                  filter: {
                    customer: row?.customer?._id,
                    project_status: 'a',
                  },
                  size: 6,
                },
                {
                  field: 'start_date',
                  placeholder: 'Start Date',
                  header: 'Start Date',
                  label: 'Start Date',
                  type: 'date',
                  size: 3,
                  required: true,
                },
                {
                  field: 'end_date',
                  placeholder: 'End Date',
                  header: 'End Date',
                  label: 'End Date',
                  type: 'date',
                  size: 3,
                  required: true,
                },
                {
                  field: 'hours',
                  placeholder: 'Hrs',
                  header: 'Hrs',
                  label: 'Hrs',
                  type: 'number',
                  size: 3,
                  required: true,
                },
              ]}
              columns={[
                {
                  field: 'order.order_number',
                  header: 'Order',
                  type: 'colorTag',
                  color: '#F5E0FF',
                },
                {
                  field: 'start_date',
                  header: 'Start Date',
                  type: 'date',
                },
                {
                  field: 'end_date',
                  header: 'End Date',
                  type: 'date',
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};
