import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {TextRenderer} from '../../../components/form/Renderers';
import { useTheme } from '@unthinkable/react-theme';

export const RenderView = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="View"
      style={{...rowText, textAlign: 'center', color: colors['ACCENT1_HIGH']}}
    />
  );
};

export const TraineeToTrainingFeedbackTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_batch} = params;
  return (
    <Table
      renderHeader={() => <TableHeader title="Training Feedbacks" />}
      filter={{training_batch, status: 'active'}}
      api="/traineeToTrainingFeedbacks"
      fields={{
        feedback_date: 1,
        next_feedback_date: 1,
        overall_score: 1,
        employee: {
          name: 1,
          email: 1,
          phone: 1,
          color: 1,
        },
      }}
      eventSourceId={['TrainingFeedbacks']}
      columns={[
        {
          field: 'employee',
          header: 'Employee',
          type: 'userAvatarChip',
        },
        {
          field: 'feedback_date',
          header: 'Date',
          type: 'date',
          width: 120,
        },
        {
          render: RenderView,
          header: 'Feedbacks',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedback-detail', {
              ...params,
              feedback: row?._id,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
