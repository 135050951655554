import {Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Table} from '../../../components/table/Table';

const ResolveEscalation = props => {
  const {row, navigation, styles} = props;
  return (
    <View
      style={{...styles.rowText}}
      onPress={() => {
        navigation.navigate(`resolve-escalation`, {
          row,
        });
      }}>
      <Text style={{color: '#34C759'}}>Mark Resolved</Text>
    </View>
  );
};

export const renderStatus = ({row = {}, styles = {}, field}) => {
  let {status} = row;
  let updateColor = {color: '#34C759'};
  let updateValue = '';
  let background = '';
  let {rowText = {}} = styles;
  if (status === 'Active') {
    updateColor['color'] = '#007AFF';
    updateValue = 'Active';
    background = '#E0F5FF';
  } else if (status === 'Resolved') {
    updateColor['color'] = '#34C759';
    updateValue = 'Resolved';
    background = '#F0FFF4';
  }
  return (
    <View
      style={{
        backgroundColor: background,
        padding: 6,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText, ...updateColor}}>{updateValue || '-'}</Text>
    </View>
  );
};
export const ActiveProjectEscalationTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter,
  } = props;
  addOnFilter = {
    ...addOnFilter,
    raised_on: {
      $lte: filterParams.period?.to,
      $gte: filterParams.period?.from,
    },
  };
  return (
    <Table
      {...props}
      eventSourceId={['projectEscalations']}
      params={{...params, ...filterParams}}
      search={searchValue}
      searchFields={['description']}
      addOnFilter={{...addOnFilter, status: 'Active'}}
      api={'/projectescalation/data'}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-escalation`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Raised On',
          field: 'raised_on',
          type: 'date',
        },
        {
          header: 'Reason',
          field: 'reason.reason',
          type: 'colorTag',
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
        },
        {
          header: 'assigness',
          field: 'assigness',
          type: 'userAvatarGroup',
        },
        {
          header: 'Actions',
          align: 'center',
          render: ({row, styles}) => (
            <ResolveEscalation
              row={row}
              styles={styles}
              navigation={navigation}
            />
          ),
        },
      ]}
    />
  );
};
export const ResolvedProjectEscalationTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter,
  } = props;
  addOnFilter = {
    ...addOnFilter,
    raised_on: {
      $lte: filterParams.period?.to,
      $gte: filterParams.period?.from,
    },
  };
  return (
    <Table
      {...props}
      eventSourceId={['projectEscalations']}
      params={{...params, ...filterParams}}
      search={searchValue}
      searchFields={['description']}
      addOnFilter={{...addOnFilter, status: 'Resolved'}}
      api={'/projectescalation/data'}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`update-escalation`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Raised On',
          field: 'raised_on',
          type: 'date',
        },
        {
          header: 'Reason',
          field: 'reason.reason',
          type: 'colorTag',
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
        },
        {
          header: 'assigness',
          field: 'assigness',
          type: 'userAvatarGroup',
        },
        {
          header: 'Resolved By',
          field: 'resolved_by',
          type: 'userAvatarChip',
        },
        {
          header: 'Resolution Remark',
          field: 'resolution_remark',
          type: 'text',
        },
      ]}
    />
  );
};

export const ProjectEscalationTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const addOnFilter = {
    project: params?._id,
  };
  return (
    <Table
      {...props}
      eventSourceId={['projectEscalations']}
      params={{...params}}
      addOnFilter={addOnFilter}
      api={'/projectescalation/data'}
      columns={[
        {
          header: 'Raised By',
          field: 'resolved_by',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row.resolved_by.name}
                items={[
                  {
                    value: moment(row?.raised_on).format('DD MMM YY'),
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Reason',
          field: 'reason.reason',
          type: 'colorTag',
        },
        {
          header: 'Remarks',
          field: 'resolution_remark',
          type: 'text',
        },
        {
          header: 'Assigness',
          type: 'userAvatar',
          field: 'assigness',
        },
        {
          header: 'Resolution Status',
          field: 'status',
          type: 'status',
          render: renderStatus,
        },
      ]}
    />
  );
};
