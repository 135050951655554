import moment from 'moment';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {Col, Text, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {GroupTable, Table} from '../../../components/table/Table';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

import images from '../../../images';
import DynamicFKRender from '../../pmt/cellrenders/DynamicFKRender';
import TaskSourceRender from '../../pmt/cellrenders/TaskSourceRender';
import {EstRender} from '../../pmt/cellrenders/EstRender';
import {TaskWithActionRenderer} from '../../pmt/cellrenders/TaskWithActionRenderer';
import {TaskTypeRender} from '../../pmt/cellrenders/TaskTypeRender';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {taskFilters, useTaskActions} from './TaskActions';

const {EstHours} = images;

const estHrs = () => ({
  render: EstRender,
  align: 'center',
  width: 70,
  header: 'Est.',
  columnField: 'est_hours',
  readOnly: true,
});

const disqualifiedOn = () => ({
  render: ({row}) => {
    const {disqualify_date, disqualified_by} = row;
    const {fonts, colors} = useTheme();
    return (
      <Col gap={4} style={{alignItems: 'center'}}>
        <Text style={{...fonts.BODY2, color: colors.NEUTRAL_LOW}}>
          {moment(disqualify_date).format('DD MMM YY')}
        </Text>
        <Text style={{...fonts.FOOTNOTE, color: colors.NEUTRAL_MEDIUM}}>
          {disqualified_by?.name}
        </Text>
      </Col>
    );
  },
  align: 'center',
  width: 140,
  header: 'Disqualified on',
});

const reason = {
  header: 'Reason',
  field: 'disqualify_reason',
  render: ({row}) => {
    const {fonts, colors} = useTheme();
    return (
      <Text style={{...fonts.BODY2, color: colors.NEUTRAL_MEDIUM}}>
        {row.disqualify_reason}
      </Text>
    );
  },
  type: 'text',
  numberOfLines: 3,
  width: 250,
};

const taskDetail = ({navigation, params, row}) => {
  navigation.navigate('task-edit', {
    ...params,
    row,
    readOnly: true,
  });
};

const source = ({project, navigation}) => ({
  render: DynamicFKRender,
  width: 300,
  sourceField: 'parent_model',
  sourceIdField: 'parent_model_id',
  navigation,
  params: {project, project_id: project?._id},
  onPress: ({row}) => {
    const {
      feature_id,
      parent_model,
      project_id,
      module_id,
      master_task_assignment,
    } = row;
    if (feature_id?._id) {
      navigation.navigate(`feature-detail-screen`, {
        project: project_id,
        project_id: project_id?._id,
        module: module_id,
        module_id: module_id?._id,
        feature: feature_id,
        feature_id: feature_id?._id,
        featureType: 'feature',
        clickedSource: parent_model?._id,
        masterTaskId:
          master_task_assignment?.master_task?._id ||
          master_task_assignment?.master_task,
      });
    }
  },
});

export const ProjectDisqualifiedTasksTable = props => {
  const {
    navigation,
    route: {params} = {},
    user,
    filterValues,
    showHeader,
  } = props;

  const {
    project,
    userId = user?._id,
    start_date,
    end_date,
    span,
    source: _source,
  } = params;

  const paramsObj = {
    scope: 'self',
    span,
    start_date,
    end_date,
    source: _source,
  };

  const {filterValues: filterVal, ...filterProps} = useFilter({});

  const {markQualified} = useTaskActions({
    navigation,
    params,
  });

  let columns = [
    {
      header: 'Task',
      render: TaskWithActionRenderer,
      taskField: 'name',
      actionField: 'master_task_assignment.task',
      colorField: 'master_task_assignment.color',
      responsive: 'sm',
      hideDisqualifiedText: true,
    },
    source({params, project, navigation}),
    estHrs(),
    disqualifiedOn(),
    {
      header: 'Completed',
      type: 'date',
      width: 100,
      align: 'center',
      readOnly: true,
      field: 'completed_on',
    },
    reason,
  ];

  return (
    <GroupTable
      eventSourceId={'Task'}
      api={`/users/${userId}/featuretasks`}
      renderHeader={() => {
        if (!showHeader) {
          return null;
        }
        return (
          <TableHeader
            title={'Disqualified tasks'}
            actions={[
              <GroupFilter
                {...filterProps}
                filterValues={filterVal}
                filters={[taskFilters.disqualified_by]}
              />,
            ]}
          />
        );
      }}
      params={{
        ...params.filter,
        ...paramsObj,
        featureType: 'feature',
        ...filterValues?.filter,
        ...filterVal?.filter,
      }}
      filter={{
        disqualified: true,
        feature_id: {$exists: true},
      }}
      limit={-1}
      onRowPress={({row}) => {
        navigation.navigate('task-edit', {
          ...params,
          row,
          readOnly: true,
        });
      }}
      columns={columns}
      moreActions={[markQualified]}
      groupRow={{
        data: 'tasks',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {_id, feature, module_id, project_id, featureType, sub_status} =
            row;

          const {tasks, ...restRow} = row;
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                onPress={() => {
                  navigation.navigate(`feature-detail-screen`, {
                    feature: restRow,
                    project: project || project_id,
                    project_id: project?._id || project_id?._id,
                    feature_id: _id,
                    module_id: module_id?._id,
                    module: module_id,
                    sub_status,
                    featureType: 'feature',
                  });
                }}
                color="#007AFF"
                value={`${feature} ${
                  module_id?.module ? '(' + module_id.module + ')' : ''
                }`}
              />
              <GroupContentItem value={project_id?.project} />
              {featureType === 'issue' ? (
                <GroupContentItem value={'Issue'} color="Red" />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          let {aggregateEsthours} = row;
          aggregateEsthours = aggregateEsthours.toFixed(1);
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem
                value={`${aggregateEsthours} h`}
                icon={EstHours}
              />
            </RowWithSeparator>
          );
        },
      }}
    />
  );
};

export const OrganizationDisqualifiedTasksTable = props => {
  const {
    navigation,
    route: {params},
    filterValues,
  } = props;

  const {start_date, end_date, span, source: _source} = params;

  const {markQualified} = useTaskActions({
    navigation,
    params,
  });

  const paramsObj = {
    scope: 'self',
    span,
    start_date,
    end_date,
    source: _source,
  };
  return (
    <Table
      eventSourceId={['Feature', 'Task']}
      api={`/projecttasks/disqualified`}
      params={{
        ...paramsObj,
        ...filterValues?.filter,
      }}
      filter={{
        assigned_to: params.userId,
        feature_id: {$exists: false},
        disqualified: true,
        status: 'completed',
      }}
      columns={[
        {
          header: 'Task',
          render: props => {
            return (
              <View style={{gap: 4}}>
                <TaskWithActionRenderer
                  {...props}
                  numberOfLines={2}
                  actionField="master_task_assignment.task"
                  taskField="name"
                  colorField="master_task_assignment.color"
                />
                <TaskSourceRender
                  {...props}
                  sourceField="source"
                  sourceIdField="source_id"
                />
              </View>
            );
          },
        },
        {
          render: TaskTypeRender,
          columnField: 'recurring_task_id.frequency_mode',
          valueField: ({row}) => (row?.is_ad_hoc ? 'Custom' : null),
          width: 140,
          align: 'center',
        },
        estHrs(),
        disqualifiedOn(),
        {
          header: 'Completed',
          type: 'date',
          width: 100,
          align: 'center',
          readOnly: true,
          field: 'completed_on',
        },
        reason,
      ]}
      onRowPress={({row}) => taskDetail({params, navigation, row})}
      moreActions={[markQualified]}
    />
  );
};
