import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useInvoke} from '../../../controllers/useInvoke';

const DepartmentTable = ({navigation, filter, tab}) => {
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Department', 'DepartmentRole', 'DepartmentTag'],
  });
  return (
    <Table
      eventSourceId={['Department', 'DepartmentRole', 'DepartmentTag']}
      api={`/departments`}
      renderHeader={() => (
        <TableHeader
          title="Department"
          actions={[<AddButton title="Add Department" view="add-department" />]}
        />
      )}
      addOnFilter={{...filter}}
      fields={{
        name: 1,
        code: 1,
        parent: {name: 1},
        managers: {name: 1},
        department_role_count: {
          _id: 1,
        },
        department_tag_count: {
          _id: 1,
        },
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`department-detail`, {departmentId: row._id});
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          header: 'Tags',
          align: 'right',
          render: CountCellRender,
          count_field: 'department_tag_count',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('department-tags', {
              department: row,
            });
          },
        },
        {
          header: 'Roles',
          align: 'right',
          render: CountCellRender,
          count_field: 'department_role_count',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('department-roles', {
              department: row,
            });
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Inactive',
          confirm: {
            title: 'Mark Inactive',
            message: 'Are you sure you want to mark this department Inactive',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/departments/${row?._id}`,
              props: {
                is_active: false,
              },
            });
          },
          visible: () => {
            if (tab === 'Active') return true;
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to mark this department Active',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/departments/${row?._id}`,
              props: {
                is_active: true,
              },
            });
          },
          visible: () => {
            if (tab === 'Inactive') return true;
          },
        },
      ]}
    />
  );
};

export default DepartmentTable;
