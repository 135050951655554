import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

export const OrganizationGoalTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {row = {}} = params;
  return (
    <Table
      {...props}
      params={params}
      api={`/threadgoals`}
      filter={{project: row?._id}}
      eventSourceId={['performance-report', 'threadAdded']}
      renderHeader={() => {
        return <TableHeader title="Goals" />;
      }}
      fields={{
        goal: 1,
        owner: {name: 1, color: 1},
        kpi: {kpi: 1},
      }}
      sort={{plan_project_audit: -1}}
      columns={[
        {
          field: 'goal',
          type: 'text',
          header: 'Goal',
        },
        {
          field: 'kpi.kpi',
          type: 'text',
          header: 'Project',
        },
        {
          field: 'owner.name',
          type: 'userAvatarChip',
          header: 'PM',
        },
      ]}
    />
  );
};
