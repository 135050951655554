import React from 'react';
import {ScrollView, Text, View} from '@unthinkable/react-core-components';
import PreText from '../../../components/chat/Pretext';

export const RecursiveComponent = ({data, recursive}) => {
  let extraStyle = {fontFamily: 'Inter-SemiBold', fontSize: 18};

  let extraPadding = {paddingBottom: 8};
  if (recursive) {
    extraStyle = {};
    extraPadding = {};
  }

  const renderContent = content => {
    return Object.keys(content).map(key => {
      const value = content[key];

      // Skip rendering if the value is null
      if (value === null) {
        return null;
      }

      // Check if the object is non-empty and if all its properties are null
      if (typeof value === 'object' && value !== null) {
        const allPropertiesAreNull = Object.values(value).every(
          property => property === null,
        );
        if (allPropertiesAreNull) {
          return null;
        }

        // Render arrays and objects differently
        if (Array.isArray(value)) {
          // Render array elements without using index as key in the text
          return (
            <View key={key} style={{paddingBottom: 8}}>
              <Text
                style={{
                  paddingBottom: 8,
                  fontSize: 14,
                  fontFamily: 'Inter-SemiBold',
                  ...extraStyle,
                }}>
                {key}
              </Text>
              {value.map((item, index) => (
                <View
                  key={`${key}-${index}`}
                  style={{...extraPadding, flexDirection: 'row'}}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: 'Inter-SemiBold',
                      marginRight: 4,
                    }}>
                    {index + 1}.
                  </Text>
                  <Text>
                    {typeof item === 'string' ? item : JSON.stringify(item)}
                  </Text>
                </View>
              ))}
            </View>
          );
        } else if (typeof value === 'object') {
          // Render non-empty objects and objects with non-null properties
          return (
            <View key={key} style={{paddingBottom: 8}}>
              <Text
                style={{
                  paddingBottom: 8,
                  fontSize: 14,
                  fontFamily: 'Inter-SemiBold',
                  ...extraStyle,
                }}>
                {key}
              </Text>
              <RecursiveComponent data={value} recursive />
            </View>
          );
        }
      } else {
        // Handle primitive values and remove double quotes from strings
        return (
          <View key={key} style={{paddingBottom: 4, ...extraPadding}}>
            <Text
              style={{
                fontSize: 14,
                fontFamily: 'Inter-SemiBold',
                ...extraStyle,
              }}>
              {key}:{' '}
            </Text>
            <PreText
              text={typeof value === 'string' ? value : JSON.stringify(value)}
              isAnswer
            />
          </View>
        );
      }
    });
  };
  let content;
  try {
    content = JSON.parse(data);
  } catch (e) {
    content = data;
  }

  if (typeof content === 'object') {
    return <Text>{renderContent(content)}</Text>;
  } else {
    return <PreText text={content} isAnswer />;
  }
};
export const ViewAssistantResponse = props => {
  const {
    route: {params},
    navigation,
  } = props;

  let {row} = params || {};
  let {content} = row || {};

  return (
    <ScrollView>
      <RecursiveComponent data={content} />
    </ScrollView>
  );
};
