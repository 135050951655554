import React from 'react';
import {Table} from '../../../components/table/Table';
import {MailContentRender} from '../components/ExpandableRowComponent';
import {useInvoke} from '../../../controllers/useInvoke';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {Text, View} from '@unthinkable/react-core-components';

const INMAIL_SEQUENCE = {
  field: 'drip_sequence',
  header: 'Sequence',
  width: 150,
};

const INMAIL_SUBJECT = {
  field: 'inmail_subject',
  header: 'Subject',
  responsive: 'sm',
};

const NAME = {
  field: 'name',
  header: 'Name',
  type: 'text',
  responsive: 'sm',
};

const CREATEDON = {
  type: 'date',
  field: 'created_on',
  header: 'Created On',
};

const COMPLETEDON = {
  type: 'date',
  field: 'completed_date',
  header: 'Completed Date',
};

const RenderType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (!row?.type) return null;
  let backgroundColors;
  if (row?.type == 'Message Series') {
    backgroundColors = colors['ACCENT5_MEDIUM'];
  } else if (row?.type == 'Connection Message') {
    backgroundColors = colors['ACCENT6_MEDIUM'];
  }
  return (
    <Text
      style={{
        ...rowText,
        width: 150,
        backgroundColor: backgroundColors,
        padding: 2,
        borderRadius: 5,
        textAlign: 'center',
      }}>
      {row?.type}
    </Text>
  );
};

const ViewBody = ({styles}) => {
  const colors = useTheme('colors');
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      <Text style={{...styles.rowText, color: colors?.['ACCENT1_HIGH']}}>
        View Body
      </Text>
    </View>
  );
};

export const DripInmailActiveTable = props => {
  const {
    route: {params},
    navigation,
    searchValue,
  } = props;
  const {communicationFilter, marketingThemeFilter} = params;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'dripInmailAdded',
  });
  const markComplete = ({row}) => {
    invoke({
      uri: `/dripinmailcontents/${row?._id}`,
      props: {
        status: 'completed',
        completed_date: getZeroTimeDate(new Date()),
      },
    });
  };
  return (
    <Table
      search={searchValue}
      eventSourceId={['dripInmailAdded']}
      searchFields={['name', 'inmail_body']}
      api={`/drip-inmails-with-response-rate`}
      addOnFilter={{
        status: 'active',
        ...communicationFilter,
        ...marketingThemeFilter,
      }}
      params={params}
      renderExpandRow={({row}) => {
        return (
          <MailContentRender
            // subject={row.inmail_subject}
            message={row?.inmail_body}
          />
        );
      }}
      columns={[
        NAME,
        {
          width: 200,
          header: 'Response Rate',
          field: 'response',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
        {
          render: ViewBody,
          expandable: true,
          responsive: 'sm',
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: row => {
            navigation.navigate('edit-drip-inmail', row);
          },
        },
        {
          title: 'Mark Completed',
          onPress: markComplete,
        },
      ]}
    />
  );
};

export const DripInmailCompletedTable = ({route: {params}, searchValue}) => {
  const {communicationFilter} = params;
  return (
    <Table
      search={searchValue}
      searchFields={'subject'}
      api={`/drip-inmails-with-response-rate`}
      addOnFilter={{
        status: 'completed',
        ...communicationFilter,
      }}
      params={params}
      columns={[
        INMAIL_SEQUENCE,
        INMAIL_SUBJECT,
        {
          width: 200,
          header: 'Type',
          render: RenderType,
          align: 'center',
        },
        CREATEDON,
        COMPLETEDON,
        {
          width: 200,
          header: 'Response Rate',
          field: 'response',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};
