import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssetRepairHistoryForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'AssetRegister',
    uri: '/assetrepairhistories',
    ...props,
  });

  const {row} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        asset: row?._id,
        repair_status: 'Pending',
      }}
      onSubmit={onSubmit}
      header={'Add Repair History'}
      submitAction={'Add'}
      layoutFields={[
        {
          label: 'General details',
          fields: [
            {
              fields: [
                {
                  type: 'date',
                  field: 'repair_date',
                  label: 'Repair date',
                  required: true,
                  size: 6,
                },
                {
                  type: 'text',
                  field: 'ticket_id',
                  label: 'Ticket ID',
                  // required: true,
                  size: 6,
                },
                {
                  label: 'Vendor',
                  field: 'vendor',
                  type: 'autoComplete',
                  api: '/vendors',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                  size: 6,
                },
                {
                  field: 'fault_type',
                  type: 'multiAutoComplete',
                  options: [
                    'Screen',
                    'Battery',
                    'Motherboard',
                    'Keyboard',
                    'Top Panel',
                    'Bottom Panel',
                    'Ports',
                    'SSD',
                    'RAM',
                    'Camera',
                    'TopCase with Battery',
                    'A/B/C/D Cover',
                    'Fan',
                  ],
                  size: 6,
                  label: 'Fault type',
                  required: true,
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'Description',
                  required: true,
                },
                {
                  type: 'autoComplete',
                  options: [
                    'Pending',
                    'Out For Repair',
                    'Repaired Asset Recieved',
                    'Repair Completed',
                  ],
                  field: 'repair_status',
                  label: 'Repair status',
                  required: true,
                  readOnly: true,
                  size: 6,
                },
                {
                  type: 'number',
                  field: 'total_repair_cost',
                  label: 'Total repair cost',
                  required: true,
                  size: 6,
                },

                {
                  placeholder: 'Invoice',
                  field: 'invoice',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Recovery',
          fields: [
            {
              fields: [
                {
                  field: 'recovery_applicable',
                  type: 'checkbox',
                  label: 'Recovery applicable',
                },
                {
                  type: 'number',
                  label: 'Recovery amount',
                  visible: ({values}) => values.recovery_applicable,
                  required: ({values}) => values.recovery_applicable,
                  field: 'recovery_amount',
                  size: 6,
                },
                {
                  label: 'Recovered from',
                  field: 'recovered_from',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  visible: ({values}) => values.recovery_applicable,
                  required: ({values}) => values.recovery_applicable,
                },
                {
                  type: 'autoComplete',
                  field: 'recovery_status',
                  label: 'Recovery status',
                  visible: ({values}) => values.recovery_applicable,
                  required: ({values}) => values.recovery_applicable,
                  // required: true,
                  options: ['Pending', 'Done', 'Waived off'],
                  size: 6,
                },
                {
                  label: 'Waiver approver',
                  field: 'waivery_approver',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  visible: ({values}) =>
                    values.recovery_applicable &&
                    values.recovery_status == 'Waived off',
                  required: ({values}) =>
                    values.recovery_applicable &&
                    values.recovery_status == 'Waived off',
                },
              ],
            },
          ],
        },
        {
          label: 'Insurance',
          fields: [
            {
              fields: [
                {
                  field: 'insurance_coverage',
                  type: 'checkbox',
                  label: 'Insurance claim',
                },
                {
                  type: 'number',
                  label: 'Insurance claim amount',
                  visible: ({values}) => values.insurance_coverage,
                  required: ({values}) => values.insurance_coverage,
                  field: 'insurance_claim_amount',
                  size: 6,
                },
              ],
            },
          ],
        },
        {
          label: 'Warranty',
          fields: [
            {
              fields: [
                {
                  field: 'warranty_status',
                  type: 'checkbox',
                  label: 'Warranty status',
                },
                {
                  type: 'number',
                  label: 'Warranty claim amount',
                  visible: ({values}) => values.warranty_status,
                  required: ({values}) => values.warranty_status,
                  field: 'warranty_claim_amount',
                  size: 6,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const AssetRepairHistoryDetailForm = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'AssetRegister',
    uri: '/assetrepairhistories',
    ...props,
  });

  const {row} = params;

  return (
    <Form
      {...props}
      api={`/assetrepairhistories/${row?._id}`}
      fields={{
        repair_date: 1,
        description: 1,
        ticket_id: 1,
        fault_type: 1,
        repair_status: 1,
        total_repair_cost: 1,
        insurance_coverage: 1,
        insurance_claim_amount: 1,
        warranty_status: 1,
        warranty_claim_amount: 1,
        recovery_applicable: 1,
        recovery_amount: 1,
        recovered_from: {_id: 1, name: 1},
        recovery_status: 1,
        waivery_approver: {_id: 1, name: 1},
        invoice: 1,
        vendor: {_id: 1, name: 1},
      }}
      onSubmit={onSubmit}
      header={'Edit Repair History'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'General details',
          fields: [
            {
              fields: [
                {
                  type: 'date',
                  field: 'repair_date',
                  label: 'Repair date',
                  required: true,
                  readOnly: true,
                  size: 6,
                },
                {
                  type: 'text',
                  field: 'ticket_id',
                  label: 'Ticket ID',
                  readOnly: true,
                  // required: true,
                  size: 6,
                },
                {
                  label: 'Vendor',
                  field: 'vendor',
                  readOnly: true,
                  type: 'autoComplete',
                  api: '/vendors',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                  size: 6,
                },
                {
                  field: 'fault_type',
                  readOnly: true,
                  type: 'multiAutoComplete',
                  options: [
                    'Screen',
                    'Battery',
                    'Motherboard',
                    'Keyboard',
                    'Top Panel',
                    'Bottom Panel',
                    'Ports',
                    'SSD',
                    'RAM',
                    'Camera',
                    'TopCase with Battery',
                  ],
                  size: 6,
                  label: 'Fault type',
                  required: true,
                },
                {
                  type: 'textArea',
                  field: 'description',
                  readOnly: true,
                  label: 'Description',
                  required: true,
                },
                {
                  type: 'autoComplete',
                  field: 'repair_status',
                  label: 'Repair status',
                  options: [
                    'Pending',
                    'Out For Repair',
                    'Repaired Asset Recieved',
                    'Repair Completed',
                  ],
                  required: true,
                  size: 6,
                },
                {
                  type: 'number',
                  field: 'total_repair_cost',
                  label: 'Total repair cost',
                  readOnly: true,
                  required: true,
                  size: 6,
                },

                {
                  placeholder: 'Invoice',
                  readOnly: true,
                  field: 'invoice',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Recovery',
          fields: [
            {
              fields: [
                {
                  field: 'recovery_applicable',
                  type: 'checkbox',
                  label: 'Recovery applicable',
                  readOnly: true,
                },
                {
                  type: 'number',
                  label: 'Recovery amount',
                  visible: ({values}) => values.recovery_applicable,
                  required: ({values}) => values.recovery_applicable,
                  readOnly: true,
                  field: 'recovery_amount',
                  size: 6,
                },
                {
                  label: 'Recovered from',
                  field: 'recovered_from',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  readOnly: true,
                  size: 6,
                  visible: ({values}) => values.recovery_applicable,
                  required: ({values}) => values.recovery_applicable,
                },
                {
                  type: 'autoComplete',
                  field: 'recovery_status',
                  label: 'Recovery status',
                  visible: ({values}) => values.recovery_applicable,
                  required: ({values}) => values.recovery_applicable,
                  // required: true,
                  options: ['Pending', 'Done', 'Waived off'],
                  size: 6,
                },
                {
                  label: 'Waiver approver',
                  field: 'waivery_approver',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  visible: ({values}) =>
                    values.recovery_applicable &&
                    values.recovery_status == 'Waived off',
                  required: ({values}) =>
                    values.recovery_applicable &&
                    values.recovery_status == 'Waived off',
                },
              ],
            },
          ],
        },
        {
          label: 'Insurance',
          fields: [
            {
              fields: [
                {
                  field: 'insurance_coverage',
                  type: 'checkbox',
                  label: 'Insurance claim',
                  readOnly: true,
                },
                {
                  type: 'number',
                  label: 'Insurance claim amount',
                  readOnly: true,
                  visible: ({values}) => values.insurance_coverage,
                  required: ({values}) => values.insurance_coverage,
                  field: 'insurance_claim_amount',
                  size: 6,
                },
              ],
            },
          ],
        },
        {
          label: 'Warranty',
          fields: [
            {
              fields: [
                {
                  field: 'warranty_status',
                  type: 'checkbox',
                  label: 'Warranty status',
                  readOnly: true,
                },
                {
                  type: 'number',
                  label: 'Warranty claim amount',
                  readOnly: true,
                  visible: ({values}) => values.warranty_status,
                  required: ({values}) => values.warranty_status,
                  field: 'warranty_claim_amount',
                  size: 6,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const AssetRepairHistoryEditbalFormHOC = props => {
  return <AssetRepairHistoryDetailForm mode="edit" {...props} />;
};

// export const ClientDetailProjectForm = props => {
//   return (
//     <ClientProjectEditableForm
//       mode="edit"
//       readOnly={true}
//       header={{
//         title: 'Detail Project',
//       }}
//       {...props}
//     />
//   );
// };

export const AssetRepairHistoryDetailFormHOC = props => {
  return (
    <AssetRepairHistoryDetailForm mode="edit" readOnly={true} {...props} />
  );
};
