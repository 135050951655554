import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const MoveToOpportunityForm = props => {
  const {
    route: {params},
  } = props;
  const {leads, product} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Lead',
    uri: '/opportunities',
    ...props,
  });

  const {user} = useAppStateContext();
  const {employee} = user;

  return (
    <Form
      onSubmit={onSubmit}
      header={'Move to opportunity'}
      submitAction={'Save'}
      defaultValues={{
        opportunity_type: 'CustomPlatformOpportunity',
        potential_customer: {_id: leads?._id, name: leads?.name},
        rfr_type: 'PotentailCustomer',
        quote_status: 'Pending',
        rfr_status: 'Received',
        owner: leads?.owner,
        organization: params?.organization || employee?.organization,
        industry: leads?.industry,
        product: product,
        creation_date: new Date(),
        from_potential_customer: true,
      }}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'opportunity_type',
          options: [
            {
              label: 'Custom digital platform',
              value: 'CustomPlatformOpportunity',
            },
            {
              label: 'Staff augmentation',
              value: 'StaffAugmentationOpportunity',
            },
          ],
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue(
              'quote_status',
              value == 'CustomPlatformOpportunity' ? 'Pending' : void 0,
            );
            setFieldValue(
              'rfr_status',
              value == 'CustomPlatformOpportunity' ? 'Received' : void 0,
            );
          },
        },
        {
          fields: [
            {
              label: 'Industry',
              field: 'industry',
              type: 'autoComplete',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
            },
            {
              label: 'Client Rating',
              field: 'client_rating',
              type: 'autoComplete',
              options: ['A+', 'A', 'B', 'C', 'D', 'E'],
              size: 6,
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
            },
            {
              type: 'number',
              field: 'probability',
              label: 'Probability',
              size: 6,
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
            },
            {
              type: 'textArea',
              label: 'Requirement',
              field: 'requirement',
              required: true,
            },
            {
              type: 'richText',
              label: 'Description',
              field: 'description',
              required: true,
            },
            {
              label: 'Mode Of Screening',
              field: 'process',
              type: 'autoComplete',
              options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
              size: 6,
            },
            {
              type: 'number',
              field: 'no_of_resources',
              label: 'No. of Resources',
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
              required: true,
              size: 6,
            },
            {
              label: 'Skill',
              field: 'skill',
              type: 'multiAutoComplete',
              api: '/tools',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Practice',
              field: 'practice',
              type: 'multiAutoComplete',
              api: '/practices',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Type',
              field: 'type',
              type: 'autoComplete',
              options: [
                'Bidding',
                'Services',
                'Tender',
                'Fixed Cost',
                'Discovery and Framework',
                'Proof of Concept',
              ],
              size: 6,
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'CustomPlatformOpportunity',
            },
            {
              label: 'Priority',
              field: 'priority',
              type: 'autoComplete',
              options: ['High', 'Medium', 'Low'],
              size: 6,
              // visible: ({values}) =>
              //   values &&
              //   values.opportunity_type === 'CustomPlatformOpportunity',
            },
            {
              label: 'Work Location',
              field: 'work_location',
              type: 'autoComplete',
              options: ['OffSite', 'OnSite'],
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
              size: 6,
            },
            {
              type: 'date',
              field: 'due_date',
              // field: 'exp_closed_date',
              label: 'Due Date',
              label: 'Due Date',
              size: 6,
              // visible: ({values}) =>
              //   values &&
              //   values.opportunity_type === 'CustomPlatformOpportunity',
            },
            {
              type: 'text',
              field: 'budget',
              label: 'Budget',
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'CustomPlatformOpportunity',
              size: 6,
            },
            {
              type: 'number',
              visible: ({values}) =>
                values &&
                values.type &&
                values.type === 'Tender' &&
                values.opportunity_type === 'CustomPlatformOpportunity',
              field: 'emd',
              label: 'EMD',
              size: 6,
            },
            {
              type: 'number',
              visible: ({values}) =>
                values &&
                values.type &&
                values.type === 'Tender' &&
                values.opportunity_type === 'CustomPlatformOpportunity',
              field: 'tender_fee',
              label: 'Tender Fee',
              size: 6,
            },
            {
              type: 'date',
              visible: ({values}) =>
                values &&
                values.type &&
                values.type === 'Tender' &&
                values.opportunity_type === 'CustomPlatformOpportunity',
              field: 'bid_opening_date',
              label: 'Bid Opening Date',
              size: 6,
            },
            {
              type: 'date',
              visible: ({values}) =>
                values &&
                values.type &&
                values.type === 'Tender' &&
                values.opportunity_type === 'CustomPlatformOpportunity',
              field: 'pre_bid_meeting_date',
              label: 'Pre Bid Opening Date',
              size: 6,
            },
            {
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
            },
            {
              label: 'Experience',
              field: 'experience',
              type: 'autoComplete',
              api: '/experiences',
              suggestionField: 'experience',
              valueField: 'experience',
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
              size: 6,
            },
            {
              label: 'Work Location',
              field: 'work_location',
              type: 'autoComplete',
              options: ['OffSite', 'OnSite'],
              visible: ({values}) =>
                values &&
                values.opportunity_type === 'StaffAugmentationOpportunity',
              size: 6,
            },
          ],
        },

        {
          label: 'Account Owner',
          field: 'account_owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) =>
            values &&
            values.opportunity_type === 'StaffAugmentationOpportunity',
          required: true,
          size: 6,
        },
        {
          label: 'Engaement Type',
          field: 'rfr_engagement_type',
          type: 'autoComplete',
          options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
          visible: ({values}) =>
            values &&
            values.opportunity_type === 'StaffAugmentationOpportunity',
          required: true,
          size: 6,
        },
        {
          label: 'Technical Consultant',
          field: 'tech_person',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.opportunity_type === 'CustomPlatformOpportunity',
        },
        {
          label: 'Solution Consultant',
          field: 'primary_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.opportunity_type === 'CustomPlatformOpportunity',
        },
        {
          label: 'Business Analyst',
          field: 'buisness_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.opportunity_type === 'CustomPlatformOpportunity',
        },
        {
          label: 'Buisness Consultant',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.opportunity_type === 'CustomPlatformOpportunity',
        },
        {
          placeholder: 'Attachment',
          field: 'requirement_document',
          multiple: true,
          type: 'file',
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};
