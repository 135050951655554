import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const TeamLeaveRequestForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  const {user} = useAppStateContext();
  const {
    employee: {_id, reporting_to},
  } = user;
  let {onSubmit} = useFormSubmit({
    uri: '/Requests',
    eventSourceId: ['leaveManagement'],
    ...props,
  });
  return (
    <Form
      api={`/requests/${row?._id}`}
      fields={{
        employee: {name: 1},
        from_date: 1,
        to_date: 1,
        duration_of_leave: 1,
        type_of_half_day: 1,
        leave_type_id: {name: 1},
        remarks: 1,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      defaultValues={{
        duration_of_leave: 'Full Day',
        requested_on: getZeroTimeDate(new Date()),
        reporting_to: reporting_to[0]?._id,
        from_date: getZeroTimeDate(new Date()),
        to_date: getZeroTimeDate(new Date()),
        employee: _id,
      }}
      computations={{
        status: [
          value => {
            const {is_approved} = value || {};
            if (is_approved) return 'approve';
            else return 'unapproved';
          },
          ['is_approved'],
        ],
      }}
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          suggestionField: 'name',
          valueField: 'name',
          api: '/employeeSuggestions',
        },
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          required: true,
        },
        {
          label: 'First Half/Second Half',
          field: 'type_of_half_day',
          type: 'autoComplete',
          options: ['First Half', 'Second Half'],
          visible: ({values}) => {
            if (values && values.duration_of_leave === 'Full Day') {
              return false;
            } else {
              return true;
            }
          },
        },
        {
          label: 'Leave Type',
          field: 'leave_type_id',
          type: 'autoComplete',
          api: `/leavetypes`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateTeamLeaveRequestForm = props => {
  return (
    <TeamLeaveRequestForm
      mode="edit"
      header={'Update Leave Request'}
      {...props}
    />
  );
};
