import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';

const AppRoleTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {applyFilter, filterValues, onChangeFilter} = useFilter({});
  const {filter = {}, params: filterParams = {}} = filterValues || {};

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'appRoleRemoved',
  });

  return (
    <Table
      eventSourceId={['appRoleAdded', 'appRoleRemoved']}
      api={`/approles`}
      fields={{
        entity: {name: 1},
        entity_id: {name: 1},
        role: 1,
        display_label: 1,
        is_default: 1,
      }}
      filter={{...filter}}
      onRowPress={({row}) => {
         navigation.navigate('view-role-permission', {row, appRole: true});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'App Roles',
                  api: '/approles',
                  placeholder: 'Select',
                  suggestionField: 'display_label',
                  valueField: 'display_label',
                  field: '_id',
                },
              ]}
            />,
            <AddButton
              title="Add App Role"
              view="add-app-role"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Entity',
          field: 'entity.name',
          type: 'text',
          width: 300,
        },
        {
          header: 'Entity Value',
          field: 'entity_id.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Role',
          field: 'role',
          type: 'text',
          width: 200,
        },
        {
          header: 'Label',
          field: 'display_label',
          type: 'text',
        },
        {
          header: 'Default',
          field: 'is_default',
          type: 'text',
          width: 100
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-app-role', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/deleteAppRole/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default AppRoleTable;
