import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

const EmployeesBenchTables = props => {
  let {
    navigation,
    route: {params},
  } = props || {};
  let {outCome} = params || {};
  let employeeData = outCome?.opportunityId || [];
  return (
    <Table
      eventSourceId={'EmployeesBenchTables'}
      renderHeader={() => (
        <TableHeader title={`EmployeeDetails ( ${employeeData.length} )`} />
      )}
      data={employeeData}
      columns={[
        {
          field: 'employeeName',
          header: 'Name',
          responsive: 'sm',
        },
        {
          field: 'official_email_id',
          header: 'Email',
          responsive: 'sm',
        },
        {
          field: 'output',
          header: 'Output',
          responsive: 'sm',
        },
        {
          field: 'skill',
          header: 'Skill',
          responsive: 'sm',
        },
        {
          field: 'owner',
          header: 'SkillOwner',
          responsive: 'sm',
        },
      ]}
      {...props}
    />
  );
};

export default EmployeesBenchTables;
