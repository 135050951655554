import React from 'react';
import {Confirm as ConfirmComponent} from '@unthinkable/react-confirm';
import {ConfirmStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';

export const Confirm = props => {
  let styles = useStyles(ConfirmStyles);
  return (
    <ConfirmComponent
      {...props}
      modalProps={{borderRadius: 12}}
      styles={styles}
    />
  );
};
