import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateAssigneeForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projecttasks',
    eventSourceId: 'Task',
  });
  const {
    route: {params},
    defaultValues: oldDefaultValues,
  } = props;

  const {row = {}, project} = params;

  const defaultValues = {...oldDefaultValues};

  if (project?._id) {
    defaultValues.project_id = project?._id;
  }

  const title = row?.assigned_to ? 'Update Assignee' : 'Add Assignee';

  return (
    <Form
      onSubmit={onSubmit}
      fields={{
        assigned_to: {name: 1},
        reviewer: {name: 1},
      }}
      mode="edit"
      header={{title, secondaryTitle: row.name}}
      api={`/projecttasks/${row?._id}`}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Assigned To',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          params: {
            project_id: project?._id,
          },
          required: row.status === 'completed',
          validate: (value, {values} = {}) => {
            if (!value) {
              return;
            }

            let result;

            if (Array.isArray(values?.reviewer)) {
              result = values?.reviewer?.find(item => {
                return item?._id === value?._id;
              });
            } else {
              result = values?.reviewer?._id === value?._id;
            }
            if (result) {
              return 'Reviewer cannot be an assignee';
            }
          },
        },
      ]}
      {...props}
    />
  );
};
