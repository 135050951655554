import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const HelpScreenInputForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/helpscreens',
    eventSourceId: 'helpscreens',
    ...props,
  });
  return (
    <Form
      api={`/helpscreens/${row?._id}`}
      fields={{
        module_id: {label: 1},
        help_text_id: 1,
        title: 1,
        render: 1,
        video_title: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Screen'}}
      layoutFields={[
        {
          label: 'Module Id',
          field: 'module_id',
          type: 'autoComplete',
          api: `/appresources`,
          filter:{type:'Module'},
          suggestionField: 'label',
          valueField: 'label',
          displayField: 'label',
          size: 'medium',
          required: true,
        },
        {
          label: 'Help Text Id',
          type: 'text',
          field: 'help_text_id',
          required: true,
        },
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },

        {
          label: 'Render',
          field: 'render',
          type: 'autoComplete',
          options: ['list', 'detail'],
        },
        {
          label: 'Video Title',
          field: 'video_title',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const EditHelpScreenInputForm = props => {
  return (
    <HelpScreenInputForm
      header={{title: 'Edit Help Screen'}}
      mode="edit"
      {...props}
    />
  );
};