import {InputWrapper} from '@unthinkable/react-input';
import './rating.css';
import StarRating from 'react-stars';

export const Rating = props => {
  const {
    maxStars,
    starSize,
    color,
    emptyColor,
    value = 0,
    onChangeValue,
    readOnly,
    ...rest
  } = props;
  return (
    <InputWrapper {...rest}>
      <StarRating
        count={maxStars}
        size={starSize}
        color2={color}
        color1={emptyColor}
        onChange={onChangeValue}
        value={value}
        edit={!readOnly}
        {...rest}
      />
    </InputWrapper>
  );
};
