import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {SubmitButton} from '@unthinkable/react-form';
import {OutlineButtonStyle} from '../../../components/button/theme';
import {useStyles} from '@unthinkable/react-theme';

const ReviewForm = ({statusReadOnly, ...props}) => {
  const style = useStyles(OutlineButtonStyle);
  const {onSubmit} = useFormSubmit({
    uri: `/taskReviews`,
    eventSourceId: 'TaskReview',
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      beforeSubmit={props => {
        const {
          data,
          submitState: {variant},
          // formContext: {setFieldError},
        } = props;
        if (variant === 'success' && !data?.review_estimated_time) {
          // setTimeout(_ => {
          //   setFieldError(
          //     'review_estimated_time',
          //     'Please provide estimated time',
          //   );
          // }, 50);
          throw new Error('Please provide estimated time');
        } else if (variant === 'success' && !data?.review_quality_standards) {
          // setTimeout(_ => {
          //   setFieldError(
          //     'review_quality_standards',
          //     'Please provide quality standards',
          //   );
          // }, 50);
          throw new Error('Please provide quality standards');
        }

        return {
          data: {
            ...data,
            status: variant === 'success' ? 'accepted' : 'rejected',
          },
        };
      }}
      footer={{
        actions: [
          <SubmitButton styles={style} label="Reject" variant="error" />,
          <SubmitButton styles={style} label="Accept" variant="success" />,
        ],
      }}
      skipDefaultSave
      layoutFields={[
        [
          {
            field: 'review_estimated_time',
            type: 'rating',
            label: 'Estimated time',
            size: 6,
            starSize: 24,
          },
          {
            field: 'review_quality_standards',
            type: 'rating',
            label: 'Quality standards',
            size: 6,
            starSize: 24,
          },
        ],
        {
          field: 'comment',
          type: 'textArea',
          label: 'Remarks',
          required: true,
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
      ]}
    />
  );
};

export const AddReview = props => {
  const {route: {params: {row} = {}} = {}} = props;
  return (
    <ReviewForm
      {...props}
      defaultValues={{
        task_id: row?._id,
      }}
      header={'Add Review'}
    />
  );
};

export const EditReview = props => {
  const {route: {params: {row} = {}} = {}} = props;
  return (
    <ReviewForm
      {...props}
      defaultValues={row}
      statusReadOnly
      header="Edit Review"
    />
  );
};
