import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {View} from '@unthinkable/react-core-components';
import {CommunicationViewComponent} from '../components/CommunicationComponent';
// import { CommunicationViewComponent } from '../components/ExpandableRowComponent';

export const CommunicationListTable = props => {
  const params = props.route.params;
  const {product, row} = params;
  let filter = {
    potential_customer: row?._id,
  };
  if (params?.props?.fromOpportunity) {
    // opportunity_id: row?._id
    if (row?.rfr_type == 'Customer') {
      filter = {
        // $or: [{opportunity_id: row?._id},],
        customer_id: row?.customer_id?._id,
      };
    } else if (row?.rfr_type == 'PotentailCustomer') {
      filter = {
        potential_customer: row?.potential_customer?._id,
      };
    }
    //  {
    //   filter = {
    //     potential_customer: row?.potential_customer?._id,
    //   };
    // } else {
    //   filter = {
    //     customer_id: row?.customer_id?._id,
    //   };
    // }
  }
  if (row?.fromCustomer) {
    filter = {
      customer_id: row?._id,
    };
  }
  return (
    <Table
      eventSourceId="communicationAdded"
      api={`/communications`}
      sort={{
        date: -1,
      }}
      filter={filter}
      renderHeader={() => (
        <TableHeader
          title={'Communications'}
          actions={
            <AddButton
              title=" Communication"
              view="add-communication"
              params={props?.route?.params}
            />
          }
        />
      )}
      renderRow={({row}) => {
        return <CommunicationViewComponent value={row} />;
      }}
      variant="none"
      fields={{
        date: 1,
        description: 1,
        hours: 1,
        sentiment: 1,
        communication_mode: 1,
        lost_description: 1,
        lost_description_comment: 1,
        owner: {name: 1},
        contact_id: {first_name: 1, designation: 1, name: 1},
        initiator: 1,
        our_attendes: {name: 1, color: 1},
        time: 1,
        subject: 1,
        next_task_date: 1,
        next_task: 1,
        client_attendees: {first_name: 1, designation: 1},
        potential_customer: {
          name: 1,
          title: 1,
        },
      }}
      // {...renderRow}
      // columns={[
      //   {
      //     type: 'date',
      //     field: 'date',
      //     header: 'Date',
      //   },
      //   {
      //     field: 'hours',
      //     header: 'Hours',
      //   },
      //   {
      //     field: 'communication_mode',
      //     header: 'Communication Mode',
      //   },
      //   {
      //     field: 'description',
      //     header: 'Description',
      //   },
      //   {
      //     field: 'owner.name',
      //     header: 'Owner',
      //   },
      //   {
      //     field: 'lost_description',
      //     header: 'Lost Reasons',
      //   },
      //   {
      //     field: 'lost_description_comment',
      //     header: 'Lost Description',
      //   },
      // ]}
    />
  );
};

export const CommunicationListTableCustomerOverview = props => {
  const params = props.route.params;
  const {product, row} = params;
  let filter = {
    potential_customer: row?._id,
  };
  if (params?.props?.fromOpportunity) {
    filter = {
      opportunity_id: row?._id,
    };
  }
  if (row?.fromCustomer) {
    filter = {
      customer_id: row?._id,
    };
  }
  if (props?.customerOverview) {
    return (
      <Table
        eventSourceId="communicationAdded"
        api={`/communications`}
        sort={{
          date: -1,
        }}
        limit={1}
        filter={filter}
        renderRow={({row}) => {
          return <CommunicationViewComponent value={row} />;
        }}
        variant="none"
        fields={{
          date: 1,
          description: 1,
          hours: 1,
          sentiment: 1,
          communication_mode: 1,
          lost_description: 1,
          lost_description_comment: 1,
          owner: {name: 1},
          contact_id: {first_name: 1, designation: 1},
          initiator: 1,
          our_attendes: {name: 1, color: 1},
          time: 1,
          subject: 1,
          next_task_date: 1,
          next_task: 1,
          client_attendees: {first_name: 1, designation: 1},
          potential_customer: {
            name: 1,
            title: 1,
          },
        }}
      />
    );
  }
  if (params?.fromCustomer) {
    return (
      <Table
        eventSourceId="communicationAdded"
        api={`/communications`}
        sort={{
          date: -1,
        }}
        filter={filter}
        renderRow={({row}) => {
          return <CommunicationViewComponent value={row} />;
        }}
        variant="none"
        fields={{
          date: 1,
          description: 1,
          hours: 1,
          sentiment: 1,
          communication_mode: 1,
          lost_description: 1,
          lost_description_comment: 1,
          owner: {name: 1},
          contact_id: {first_name: 1, designation: 1},
          initiator: 1,
          our_attendes: {name: 1, color: 1},
          time: 1,
          subject: 1,
          next_task_date: 1,
          next_task: 1,
          client_attendees: {first_name: 1, designation: 1},
          potential_customer: {
            name: 1,
            title: 1,
          },
        }}
      />
    );
  }
};
