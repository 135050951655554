import React from 'react';
import { Form } from '../../../components/form/Form';
import { useAppStateContext } from '../../../providers/AppState';

export const MakeLeadForm = props => {
  const params = props.route.params;
  const {row} = params;
  const {post} = useAppStateContext();
  const makeQl = data => {
    post({
      uri: `/update-potential-customer/${row?._id}`,
      method: 'put',
      props: {
        ...data,
        lead_status: 'Active',
        stage: {
          _id: '5530f027a814a8f057123166',
          name: 'QL',
        },
        pipeline_type: 'Prospect',
        old_stage: row?.stage,
      },
      eventSourceId: 'Lead',
    });
  };

  return (
    <Form
      api={`/potentialcustomers/${row?._id}`}
      fields={{
        name: 1,
        stage: {name: 1},
        geography_location: {name: 1},
        organization_category: {name: 1},
        employee_count: {number: 1},
        industry: {name: 1},
        owner: {name: 1},
        inside_sales: {name: 1},
      }}
      mode="edit"
      onSubmit={makeQl}
      header={'Make Lead'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          type: 'date',
          field: 'sql_stage_date',
          label: 'QL Date',
          // size: 6,
          required: true,
        },
        {
          type: 'autoComplete',
          label: 'Owner',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'owner',
          size: 6,
        },
        {
          label: 'Inside Sales',
          placeholder: 'Inside Sales',
          field: 'inside_sales',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          field: 'geography_location',
          placeholder: 'Location',
          label: 'Geography Location',
          type: 'autoComplete',
          api: `/geographylocations`,
          suggestionField: 'name',
          required: true,
          valueField: 'name',
          size: 6,
        },
        {
          field: 'organization_category',
          placeholder: 'Organization Category',
          label: 'Organization Category',
          type: 'autoComplete',
          api: `/organizationcategories`,
          suggestionField: 'name',
          required: true,
          valueField: 'name',
          size: 6,
        },
        {
          field: 'employee_count',
          placeholder: 'Employee Count',
          label: 'Employee Count',
          type: 'autoComplete',
          api: `/employeeSize/active`,
          suggestionField: 'number',
          valueField: 'number',
          required: true,
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'industry',
          api: `/industries`,
          suggestionField: 'name',
          valueField: 'name',
          placeholder: 'Industry',
          label: 'Industry',
          required: true,
          size: 6,
        },
      ]}
    />
  );
};
