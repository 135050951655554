import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { NavHeader } from '../../../components/nav/NavHeader';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useFilterKeyValues } from '../../../providers/FilterProvider';
import { leadControllers } from '../controllers/leadControllers';

export const DataMiningHeader = props => {
  const {
    route: {params},
  } = props;
  return (
    <NavHeader
      title="Data Mining"
      {...props}
      actions={[
        <AddButton
          title="Data Mining"
          view="add-datamining"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

const DataMiningTableHeader = props => {
  const {
    route: {params},
  } = props;
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    filterKey: 'dataMiningFilter',
    params,
  });
  return (
    <TableHeader
      leftActions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'assigned_to',
            },
          ]}
        />,
      ]}
    />
  );
};

export const DataMiningTable = props => {
  const {navigation} = props;
  const params = props?.route?.params;
  const {product} = params;
  const {filterValues = {}, searchValue} =
    useFilterKeyValues('dataMiningFilter');
  const {filter, params: {period} = {}} = filterValues;
  const {prospectsCountForCampaign} = leadControllers({
    ...props,
    params: {...params, period},
  });
  return (
    <Table
      renderHeader={() => {
        return <DataMiningTableHeader {...props} />;
      }}
      addOnFilter={filter}
      search={searchValue}
      searchFields={'name'}
      api={`/product/${product?._id}/data-mining`}
      params={{
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
        },
        // {
        //   field: 'activity',
        //   header: 'Activity',
        // },
        {
          field: 'assigned_to.name',
          colorField: 'assigned_to.color',
          header: 'Owner',
          type: 'colorTag',
        },
        {
          type: 'date',
          field: 'created_on',
          header: 'Date',
        },
        {
          field: 'buyer_perosna',
          header: 'Buyer Persona',
        },
        {
          field: 'batch.name',
          header: 'Batch',
        },
        {
          type: 'number',
          field: 'prospect_count',
          header: 'Prospect',
          aggregate: true,
          onPress: prospectsCountForCampaign,
        },
      ]}
    />
  );
};
