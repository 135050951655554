import React from 'react';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {Button as ButtonComponent} from '@unthinkable/react-button';
import {AIButtonStyle, ButtonGroupStyle, ButtonStyle} from './theme';
import {Row, View, Wrap} from '@unthinkable/react-core-components';
import {MoreActions} from '../moreAction/MoreAction';

export const Button = props => {
  const styles = useStyles(ButtonStyle);
  return <ButtonComponent styles={styles} {...props} />;
};

const ButtonSeparator = ({width = 1, height = 20, color}) => {
  const {colors} = useTheme();
  if (!color) {
    color = colors.BACKGROUND;
  }
  return (
    <View
      style={{
        height: height,
        width: width,
        backgroundColor: color,
      }}
    />
  );
};

export const ButtonGroup = ({
  wrap = false,
  children,
  separator = <ButtonSeparator />,
  ...props
}) => {
  const styles = useStyles(ButtonGroupStyle);
  let childrenArray = React.Children.toArray(children);
  childrenArray = childrenArray.filter(child => child);
  childrenArray = childrenArray.reduce((acc, child, index) => {
    acc.push(child);
    if (index !== childrenArray.length - 1) {
      acc.push(separator);
    }
    return acc;
  }, []);
  const Component = wrap ? Wrap : Row;
  return (
    <Component style={styles.container} {...props}>
      {childrenArray}
    </Component>
  );
};

export const AIButton = props => {
  const style = useStyles(AIButtonStyle);
  return <ButtonComponent {...props} styles={style} />;
};
