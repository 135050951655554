import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateAssetPhotoForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'MyITAsset',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      fields={{
        asset_photo: 1,
      }}
      header={{
        title: 'Upload Asset Photo',
        secondaryTitle: `${row?.asset_type} - ${row?.code}`,
      }}
      onSubmit={onSubmit}
      submitAction="Upload"
      eventSourceId="MyITAsset"
      layoutFields={[
        {
          label: 'Upload Asset Photo',
          placeholder: 'Upload Asset Photo',
          field: 'asset_photo',
          type: 'file',
          required: true,
        },
      ]}
    />
  );
};
