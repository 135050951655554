import {useAppStateContext} from '../../../providers/AppState';

export const EmployeeSize = ({route: {params}, navigation} = {}) => {
  const {post} = useAppStateContext();

  const markEmployeeSizeInactive = ({row}) => {
    post({
      uri: `/employeeSizes/${row?._id}`,
      method: 'put',
      props: {
        status: 'Inactive',
      },
    });
  };
  const markEmployeeSizeActive = ({row}) => {
    post({
      uri: `/employeeSizes/${row?._id}`,
      method: 'put',
      props: {
        status: 'Active',
      },
    });
  };

  return {
    markEmployeeSizeInactive,
    markEmployeeSizeActive,
  };
};
