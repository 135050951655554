import React from 'react';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import './index.css';
import {View} from '@unthinkable/react-core-components';

export const ZoomImage = ({children, ZoomComponent}) => {
  return (
    <TransformWrapper>
      {({zoomIn, zoomOut, resetTransform}) => (
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <TransformComponent>{children}</TransformComponent>
          <ZoomComponent
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            resetTransform={resetTransform}
          />
        </View>
      )}
    </TransformWrapper>
  );
};
