export const ConfirmStyles = ({theme: {colors, fonts}}) => {
  return {
    modal: {
      sm: {
        container: {
          width: '90%',
          backgroundColor: colors.BACKGROUND,
        },
      },
      md: {
        container: {
          width: 480,
          backgroundColor: colors.BACKGROUND,
        },
      },
    },
    container: {
      padding: 20,
      gap: 16,
    },
    titleContainer: {gap: 4},
    title: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    messageText: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    footerContainer: {
      gap: 12,
    },
    cancelButton: {
      container: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.SURFACE3,
        flex: 1,
      },
      hoverContainer: {
        backgroundColor: colors.OUTLINE,
      },
      text: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    confirmButton: {
      container: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.SUCCESS_LOW,
        flex: 1,
      },
      hoverContainer: {
        backgroundColor: colors.SUCCESS_MEDIUM,
      },
      text: {
        ...fonts.ACTION,
        color: colors.SUCCESS_HIGH,
      },
    },
    error: {
      confirmButton: {
        container: {
          cursor: 'pointer',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.ERROR_LOW,
          flex: 1,
        },
        hoverContainer: {
          backgroundColor: colors.ERROR_MEDIUM,
        },
        text: {
          ...fonts.ACTION,
          color: colors.ERROR_HIGH,
        },
      },
    },
  };
};
