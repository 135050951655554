import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinancialYearForm = props => {
  const {
    route: {params},
  } = props; 
  const {financialYearId} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/FinancialYears',
    eventSourceId: 'FinancialYear',
  });
  return (
    <Form
      api={`/FinancialYears/${financialYearId}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Financial Year',
          field: 'financial_year',
          type: 'text',
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Start Date',
          field: 'start_date',
          type: 'date',
        },
        {
          label: 'End Date',
          field: 'end_date',
          type: 'date',
        },
      ]}
      {...props}
    />
  );
};

export const AddFinancialYearForm = props => {
  return <FinancialYearForm header="Add Financial Year" {...props} />;
};

export const UpdateFinancialYearForm = props => {
  return (
    <FinancialYearForm mode="edit" header="Update Financial Year" {...props} />
  );
};
