import React from 'react';
import {Chip, Tag} from './Chip';
import pluralizeText from '../../utils/pluralizeText';
import {resolveExp} from '@unthinkable/react-utils';
import {useStyles} from '@unthinkable/react-theme';
import {ErrorChipStyles} from './theme';

export const ColorChip = ({value, titleField, colorField, row, ...props}) => {
  const color = colorField ? resolveExp(row, colorField) : void 0;
  const title = titleField ? resolveExp(row, titleField) : void 0;
  return <Chip title={title || value} value={value} color={color} {...props} />;
};

export const ColorTag = ({value, titleField, colorField, row, ...props}) => {
  const color = colorField ? resolveExp(row, colorField) : void 0;
  const title = titleField ? resolveExp(row, titleField) : void 0;
  return <Tag title={title} value={value} color={color} {...props} />;
};

export const CountColorChip = ({
  value,
  color = 'INFORMATION',
  suffix,
  pluralize = true,
  ...props
}) => {
  if (!value) {
    return null;
  }
  if (suffix) {
    if (pluralize) {
      suffix = pluralizeText({count: value, text: suffix});
    }
    value = value + ' ' + suffix;
  }
  return <Tag {...props} value={value} color={color} />;
};

export const ErrorChip = ({value, ...props}) => {
  if (!value) {
    return null;
  }
  const theme = useStyles(ErrorChipStyles);
  return <Tag {...props} value={value} {...theme} />;
};
