import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const vendorController = props => {
  const {params, navigation} = props;
  const {post, user} = useAppStateContext();
  const invoke = useInvoke({method: 'put'});
  const invokePost = useInvoke({
    method: 'post',
    eventSourceId: [
      'popuateMessage',
      'populateBusiness',
      'sendAction',
      'updateBrowser',
      'UnlockRequest',
      'RemoveLocks',
      'removeError',
    ],
  });
  const invokeDelete = useInvoke({
    method: 'delete',
    eventSourceId: ['communicationAdded', 'indexUpdated'],
  });

  const updateNewVendorStatus = ({row}) => {
    navigation.navigate(`update-new-vendor-status`, {
      ...params,
      row,
    });
  };

  const updateVerifiedVendorStatus = ({row}) => {
    navigation.navigate(`update-verified-vendor-status`, {
      ...params,
      row,
    });
  };

  const updateVendorAccountDetails = ({row}) => {
    navigation.navigate(`update-vendor-account-details`, {
      ...params,
      row,
    });
  };

  const updateVendorContactDetails = ({row}) => {
    navigation.navigate(`update-vendor-contact-details`, {
      ...params,
      row,
    });
  };

  const markVendorBlacklist = ({row}) => {
    navigation.navigate(`mark-vendor-blacklist`, {
      ...params,
      row,
    });
  };

  const activityLogVendor = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [{path: 'owner', select: {name: 1}}],
      api: `/vendors/${row?._id}/activityLogs`,
      displayFields: {
        name: {
          label: 'Vendor',
        },
      },
    });
  };

  const vendorNonEditableDetailForm = ({row}) => {
    navigation.navigate(`vendor-view-non-editable-detail`, {
      ...params,
      row,
    });
  };
  const vendorEditableDetailForm = ({row}) => {
    navigation.navigate(`vendor-view-detail`, {
      ...params,
      row,
    });
  };

  return {
    updateNewVendorStatus,
    updateVerifiedVendorStatus,
    markVendorBlacklist,
    activityLogVendor,
    updateVendorAccountDetails,
    updateVendorContactDetails,
    vendorNonEditableDetailForm,
    vendorEditableDetailForm
  };
};
