import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {TabView} from '../../../components/tab';

export const DataEntityTaskAssignments = props => {
  const {
    route: {params},
    navigation,
    filter,
    searchValue,
    type,
  } = props;

  return (
    <Table
      searchFields="label"
      addOnFilter={filter}
      search={searchValue}
      eventSourceId={['Type', 'MasterTaskAssignment']}
      api={`/Types`}
      filter={{task_assignment_type: {$exists: true}}}
      fields={{
        name: 1,
        label: 1,
        display_field: 1,
        departments: {name: 1},
        views_count: {_id: 1},
        task_assignment_type: 1,
        master_task_count: {_id: 1},
        product_task_assignment_count: {_id: 1},
      }}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Data Entity',
        },
        {
          render: CountCellRender,
          header: 'Master Tasks',
          count_field: 'master_task_count',
          type: 'number',
          onPress: props => {
            const {row} = props;
            navigation.navigate('master-task-tabs', {
              ...params,
              row,
            });
          },
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate('add-master-task', {
              ...params,
              row,
            });
          },
          align: 'center',
          width: 250,
          visible: type === 'Project',
        },
        {
          render: CountCellRender,
          header: 'Task Assignments',
          count_field: 'product_task_assignment_count',
          type: 'number',
          onPress: props => {
            const {row} = props;
            navigation.navigate('event-task-assignment-tabs', {
              ...params,
              parent_model: row,
            });
          },
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate('add-event-task-assignment', {
              ...params,
              parent_model: row,
            });
          },
          align: 'center',
          width: 250,
          visible: type === 'Product',
        },
      ]}
    />
  );
};

export const DataEntityTaskAssignmentTabs = props => {
  const {filter, onChangeFilter, searchValue} = useFilter();
  return (
    <TabView
      {...props}
      tabs={{
        product: {
          label: 'Product',
          view: (
            <DataEntityTaskAssignments
              type={'Product'}
              filter={{...filter, task_assignment_type: 'Product'}}
              searchValue={searchValue}
            />
          ),
        },
        project: {
          label: 'Project',
          view: (
            <DataEntityTaskAssignments
              type={'Project'}
              filter={{...filter, task_assignment_type: 'Project'}}
              searchValue={searchValue}
            />
          ),
        },
      }}
      actions={
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />
      }
    />
  );
};
