import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import React from 'react';
import { PrimaryColumnCard } from '../../../components/card/PrimaryColumnCard';
import {
    GroupFilter,
    PeriodRangeFilter,
} from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

export const EmployeeVariableTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  return (
    <Table
      eventSourceId={['EmployeeVariable']}
      api={`/employeeVariable`}
      limit={5000}
      params={{...filterValues.params}}
      onRowPress={({row}) => {
        navigation.navigate('employee-variable-project', {
          row,
          filter: filterValues.params,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Employee Variable"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'multiAutoComplete',
                    field: 'employee',
                    api: '/employeeSuggestions',
                    placeholder: 'select',
                    suggestionField: 'name',
                    valueField: 'name',
                    label: 'Employee',
                    secondarySuggestionField: 'official_email_id',
                    asParam: true,
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee.name',
          type: 'text',
          render: ({row, styles}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Total',
          type: 'text',
          field: 'total_amount',
          toFixed: 1,
        },
        {
          header: 'Self',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.self_amount?.toFixed(0)}
                items={[{value: row?.self_hours?.toFixed(0)}]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Level 1',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.level_one_amount?.toFixed(0)}
                items={[{value: row?.level_one_hours?.toFixed(0)}]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Level 2',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.level_two_amount?.toFixed(0)}
                items={[{value: row?.level_two_hours?.toFixed(0)}]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'L1 T Amount',
          field: 'level_one_transfer_amount',
          type: 'text',
          toFixed: 0,
          width: 150,
        },

        {
          header: 'L2 T Amount',
          field: 'level_two_transfer_amount',
          type: 'text',
          toFixed: 0,
          width: 150,
        },
        {
          header: 'Transfer',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.transfer_employee_amount?.toFixed(0)}
                items={[{value: row?.transfer_employees?.toFixed(0)}]}
              />
            );
          },
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              employeeFilter: {
                _id: {$in: row?.transferredIds},
              },
              period: filterValues.params.period,
            });
          },
          width: 150,
        },
        {
          header: 'Experience',
          field: 'experienceWeightage',
          type: 'text',
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              employeeFilter: {
                _id: {$in: row?.teamIds},
              },
              period: filterValues.params.period,
            });
          },
        },
        {
          header: 'Efficiency',
          field: 'deliveryEfficiency',
          type: 'number',
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              employeeFilter: {
                _id: {$in: row?.teamIds},
              },
              period: filterValues.params.period,
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeVariableProjectTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row, filter} = params;
  return (
    <Table
      eventSourceId={['escalationreasons']}
      api={`/calculatevariable/${row?.employee?._id}`}
      params={{...filter, projectTable: true}}
      renderHeader={({}) => {
        return <TableHeader title="Project Variable" />;
      }}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'Resource',
          type: 'userAvatarGroup',
          field: 'employees',
          minWidth: 200,
        },
        {
          header: 'Total',
          field: 'total_amount',
          type: 'text',
          count_field: 'total_amount',
          aggregate: true,
          toFixed: 1,
          width: 150,
        },
        {
          header: 'Self',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.self_amount?.toFixed(1)}
                items={[{value: row?.self_hours?.toFixed(1)}]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Level 1',
          field: 'level_one_amount',
          count_field: 'level_one_amount',
          aggregate: true,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.level_one_amount?.toFixed(1)}
                items={[{value: row?.level_one_hours?.toFixed(1)}]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Level 2',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.level_two_amount?.toFixed(1)}
                items={[{value: row?.level_two_hours?.toFixed(1)}]}
              />
            );
          },
          field: 'level_two_amount',
          count_field: 'level_two_amount',
          aggregate: true,
          width: 150,
        },
        {
          header: 'L1 T Amount',
          field: 'level_one_transfer_amount',
          type: 'text',
          toFixed: 1,
          width: 150,
        },
        {
          header: 'L2 T Amount',
          field: 'level_two_transfer_amount',
          type: 'text',
          toFixed: 1,
          width: 150,
        },
        {
          header: 'Project Weightage',
          field: 'projectWeightage',
          type: 'text',
          width: 150,
        },
        {
          header: 'Billing Efficiency',
          field: 'billingEfficiency',
          type: 'text',
          width: 150,
        },
        {
          header: 'Transfer',
          field: 'is_transfered',
          type: 'checkbox',
          width: 150,
        },
      ]}
    />
  );
};
