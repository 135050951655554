import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {
  ActiveCandidate,
  ClosedCandidate,
  DroppedCandidates,
  InterviewCandidates,
  JoinedCandidates,
  OffereAccetedCandidates,
  OfferedCandidates,
  OnHoldCandidate,
  OnHoldCandidates,
  RejectedCandidates,
  ShortListedCandidates,
} from './CandidateTable';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';

export const ActiveCandidateTabs = props => {
  const {
    route: {params},
  } = props;
  const {job_details = {}} = params || {};
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter();
  const addOnFilter = {job_opening: job_details?._id, ...filterValues?.filter};

  const tabs = {
    shortlisted: {
      label: 'Shortlisted',
      view: (
        <ShortListedCandidates
          addOnFilter={addOnFilter}
          filter={{
            status: 'Sortlisted',
          }}
          fromAll
          {...props}
        />
      ),
      api: `/candidates`,
      filter: {
        status: 'Sortlisted',
      },
      addOnFilter,
      eventSourceId: 'candidate',
      search: searchValue,
      searchFields: ['name', 'email'],
    },
    interviews: {
      label: 'Interviews',
      view: (
        <InterviewCandidates
          addOnFilter={addOnFilter}
          filter={{
            status: 'Interview',
          }}
          {...props}
          fromAll
        />
      ),
      api: `/candidates`,
      filter: {
        status: 'Interview',
      },
      addOnFilter,
      eventSourceId: 'candidate',
      search: searchValue,
      searchFields: ['name', 'email'],
    },
    offered: {
      label: 'Offered',
      view: (
        <OfferedCandidates
          addOnFilter={addOnFilter}
          filter={{
            status: {$in: ['Offered']},
          }}
          {...props}
          fromAll
        />
      ),
      api: `/candidates`,
      filter: {
        status: {$in: ['Offered']},
      },
      addOnFilter,
      eventSourceId: 'candidate',
      search: searchValue,
      searchFields: ['name', 'email'],
    },
    offerAccepted: {
      label: 'Offer Accepted',
      view: (
        <OffereAccetedCandidates
          addOnFilter={addOnFilter}
          filter={{
            status: 'Offer Accepted',
          }}
          {...props}
          fromAll
        />
      ),
      api: `/candidates`,
      filter: {
        status: 'Offer Accepted',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
    onHold: {
      label: 'On Hold',
      view: (
        <OnHoldCandidates
          filter={{
            status: 'On Hold',
          }}
          addOnFilter={addOnFilter}
          {...props}
          fromAll
        />
      ),
      api: `/candidates`,
      filter: {
        status: 'On Hold',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
  };

  return (
    <TabView
      search={searchValue}
      searchFields={['name', 'email']}
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Candidate"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Job Opening',
              field: 'job_opening',
              api: '/jobOpenings',
              type: 'autoComplete',
              suggestionField: 'position_title.name',
              getDisplayValue: ({value}) => value?.position_title?.name,
              placeholder: 'Position',
              fields: {
                position_title: {
                  name: 1,
                },
              },
            },
          ]}
        />,
      ]}
    />
  );
};

export const ClosedCandidateTabs = props => {
  const {
    route: {params},
  } = props;
  const {job_details = {}} = params || {};
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter();
  const addOnFilter = {job_opening: job_details?._id, ...filterValues?.filter};

  const tabs = {
    joined: {
      label: 'Joined',
      view: <JoinedCandidates addOnFilter={addOnFilter} {...props} fromAll />,
      api: `/candidates`,
      filter: {
        status: 'Joined',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
    rejected: {
      label: 'Rejected',
      view: <RejectedCandidates addOnFilter={addOnFilter} {...props} fromAll />,
      api: `/candidates`,
      filter: {
        status: 'Rejected',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
    dropped: {
      label: 'Dropped',
      view: <DroppedCandidates addOnFilter={addOnFilter} {...props} fromAll />,
      api: `/candidates`,
      filter: {
        status: {$in: ['Dropped', 'Offer Rejected']},
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      search={searchValue}
      searchFields={['name', 'email']}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Candidate"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              label: 'Job Opening',
              field: 'job_opening',
              api: '/jobOpenings',
              type: 'autoComplete',
              suggestionField: 'position_title.name',
              getDisplayValue: ({value}) => value?.position_title?.name,
              placeholder: 'Position',
              fields: {
                position_title: {
                  name: 1,
                },
              },
            },
          ]}
        />,
      ]}
    />
  );
};

export const AllCandidateTabs = props => {
  const {
    route: {params},
  } = props;
  const {job_details = {}} = params || {};
  const addOnFilter = {job_opening: job_details?._id};

  const tabs = {
    active: {
      label: 'Active',
      view: <ActiveCandidate addOnFilter={addOnFilter} {...props} fromAll />,
      api: `/candidates`,
      filter: {
        status: 'Active',
      },
      addOnFilter,
      eventSourceId: 'candidate',
      actions: [
        <AddButton
          disabled={false}
          title=" Candidate"
          view="add-candidate"
          params={params}
        />,
      ],
    },
    closed: {
      label: 'Closed',
      view: <ClosedCandidate addOnFilter={addOnFilter} {...props} fromAll />,
    },
    onHold: {
      label: 'On Hold',
      view: <OnHoldCandidate addOnFilter={addOnFilter} {...props} fromAll />,
      api: `/candidate/OnHold`,
      addOnFilter,
      eventSourceId: 'OnHoldCandidate',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
