import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';

const RenderStatus = ({row, styles, reffer_to_coo, fromMyView, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'quotations',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  let {
    user: {employee},
  } = useAppStateContext();
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          {row.reffer_to_coo && fromMyView ? (
            <View
              style={{
                ...rowText,
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
                color: '#737373',
              }}>
              Forwarded
            </View>
          ) : (
            <>
              <View
                style={{
                  padding: 4,
                  alignItem: 'center',
                  textAlign: 'center',
                }}
                onPress={() => {
                  navigation.navigate('approve-quotaion-form', {
                    row,
                    reffer_to_coo,
                  });
                  // invoke({
                  //   uri: `/quotations/${row?._id}`,
                  //   props: {
                  //     status: 'Approved',
                  //     approved_on: new Date(),
                  //     approved_by: reffer_to_coo
                  //       ? '53a437e96dc89c02007cbcc7'
                  //       : employee._id,
                  //   },
                  // });
                }}>
                <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
              </View>
              <View
                style={{
                  padding: 4,
                  alignItem: 'center',
                  textAlign: 'center',
                }}
                onPress={() => {
                  navigation.navigate('reject-quotation-request', {
                    row,
                    reffer_to_coo,
                  });
                }}>
                <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
              </View>
            </>
          )}
        </View>
      )}
    </>
  );
};

export const QuotationApproverTable = props => {
  let {navigation, addOnFilter, tab, reffer_to_coo, fromMyView, searchValue} =
    props;
  let {
    user: {employee},
  } = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'quotations',
  });
  if (!reffer_to_coo) {
    addOnFilter = {
      ...addOnFilter,
      approved_by: employee._id,
    };
  } else {
    addOnFilter = {
      ...addOnFilter,
      reffer_to_coo: true,
    };
  }
  return (
    <Table
      {...props}
      eventSourceId={['quotations']}
      api={`/quotation/approver`}
      search={searchValue}
      searchFields="po_number"
      addOnFilter={{...addOnFilter}}
      onRowPress={({row}) => {
        if (tab === 'QuotationApproverActive') {
          navigation.navigate('edit-quotation-request', {row, readOnly: true});
        } else navigation.navigate('edit-quotation-request', {row});
      }}
      columns={[
        {
          header: 'Quot No',
          field: 'po_number',
          type: 'text',
          width: 150,
        },
        {
          header: 'PO Date',
          field: 'po_date',
          type: 'date',
          width: 150,
        },
        // {
        //   header: 'Payee',
        //   field: 'payee_id.name',
        //   type: 'text',
        //   width: 150,
        // },
        {
          header: 'Department',
          field: 'team.name',
          type: 'colorTag',
          width: 200,
        },
        {
          header: 'Description',
          field: 'purpose',
          type: 'text',
          minWidth: 200,
        },

        {
          header: 'Requested By',
          field: 'requested_by',
          type: 'userAvatar',
          width: 200,
        },
        {
          header: 'Approver',
          field: 'approved_by',
          type: 'userAvatar',
          width: 200,
        },
        {
          header: 'Action',
          visible: ({values}) => {
            if (tab === 'QuotationApproverActive') return true;
          },
          align: 'center',
          render: ({row, styles}) => {
            return (
              <RenderStatus
                row={row}
                styles={styles}
                navigation={navigation}
                reffer_to_coo={reffer_to_coo}
                fromMyView={fromMyView}
              />
            );
          },
          width: 200,
        },
        // {
        //   header: 'Status',
        //   visible: ({values}) => {
        //     if (tab === 'QuotationApproverClosed') return true;
        //   },
        //   render: ({row, styles}) => {
        //     let color = '';
        //     if (row?.status === 'Approved') {
        //       color = '#34C759';
        //     } else if (row?.status === 'Rejected') {
        //       color = '#FF5247';
        //     }
        //     return (
        //       <Text style={{...styles.rowText, color: color}}>
        //         {row.status}
        //       </Text>
        //     );
        //   },
        //   width: 200,
        // },
      ]}
      moreActions={[
        {
          title: 'Forward To COO',
          visible: ({values}) => {
            if (tab === 'QuotationApproverActive' && !reffer_to_coo)
              return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/quotations/${row._id}`,
              props: {
                reffer_to_coo: true,
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'requested_by', select: {name: 1}},
                {path: 'approved_by', select: {name: 1}},
              ],
              api: `/quotations/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const QuotationfinanceTable = props => {
  let {navigation, addOnFilter, searchValue, tab} = props;
  let {
    user: {employee},
  } = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'quotations',
  });
  return (
    <Table
      {...props}
      eventSourceId={['quotations']}
      search={searchValue}
      searchFields="po_number"
      api={`/quotation/all`}
      addOnFilter={{...addOnFilter}}
      onRowPress={({row}) => {
        navigation.navigate('edit-quotation-request', {row, readOnly: true});
      }}
      columns={[
        {
          header: 'Quot No',
          field: 'po_number',
          type: 'text',
          width: 200,
        },
        {
          header: 'Vendor',
          field: 'approve_vendor',
          type: 'text',
          width: 200,
        },
        {
          header: 'PO Date',
          field: 'po_date',
          type: 'date',
          width: 200,
        },
        // {
        //   header: 'Payee',
        //   field: 'payee_id.name',
        //   type: 'text',
        //   width: 150,
        // },
        {
          header: 'Department',
          field: 'team.name',
          type: 'colorTag',
          width: 300,
        },
        {
          header: 'Description',
          field: 'purpose',
          type: 'text',
          minWidth: 200,
        },

        {
          header: 'Requested By',
          field: 'requested_by',
          type: 'userAvatar',
          width: 200,
        },
        {
          header: 'Approver',
          field: 'final_approver',
          type: 'userAvatar',
          width: 200,
        },
      ]}
      moreActions={[
        {
          title: 'Add Purchase Order',
          visible: () => {
            if (tab === 'Approved') return true;
          },
          onPress: ({row}) => {
            navigation.navigate('add-purchase-order', {row});
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'requested_by', select: {name: 1}},
                {path: 'approved_by', select: {name: 1}},
              ],
              api: `/quotations/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
