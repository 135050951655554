import {
  formModalPmtScreens,
  pmtLeftMenuScreens,
  pmtModalScreens,
  pmtStackScreens,
} from './PmtRoutes';
import {
  formModalScreens,
  leftMenuScreens,
  modalScreens,
  stackScreens,
} from './Routes';

const screenPaths = {};

const resolvePath = ({path, pathParams, requiredFields, ...screen}) => {
  const {name} = screen;
  if (path) {
    screenPaths[name] = {
      path,
      pathParams,
      requiredFields,
    };
  }
  return screen;
};

const routes = {
  stackScreens: [...stackScreens, ...pmtStackScreens].map(resolvePath),
  leftMenuScreens: [...leftMenuScreens, ...pmtLeftMenuScreens].map(resolvePath),
  modalScreens: [...modalScreens, ...pmtModalScreens].map(resolvePath),
  formModalScreens: [...formModalScreens, ...formModalPmtScreens].map(
    resolvePath,
  ),
};

export default routes;

export {screenPaths};
