import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';
import { useFetchData } from '../../../controllers/useFetchData';

const SalaryTemplateComputation = (fetch) => ({
    artificialTotalAmount: {
        compute: value => {
            const {
                performance_base_components = [],
                attendance_base_components = [],
                deductions_components = [],
            } = value;
            const response = {};
            let linkStatus = false;
            let totalAmount = 0;
            if (performance_base_components?.length > 0) {
                for (const component of performance_base_components) {
                    if (component?.link_to_project) {
                        linkStatus = true;
                        if (!component?.isNotCtcIncluded) {
                            totalAmount += component?.amount * 1 || 0;
                        }
                    }
                    if (!component?.linkStatus && !component?.isNotCtcIncluded) {
                        totalAmount += component?.amount * 1 || 0;
                    }
                }
            }
            response['artificialTotalAmount'] =
                ((attendance_base_components?.length &&
                    attendance_base_components.reduce((amount, component) => {
                        if (!component.isNotCtcIncluded) {
                            amount =
                                amount + ((component.amount && component.amount * 1) || 0);
                        }
                        return amount;
                    }, 0)) ||
                    0) +
                totalAmount +
                ((deductions_components?.length &&
                    deductions_components.reduce((amount, component) => {
                        let total = 0;
                        if (
                            component?.salary_component_id?._id &&
                            (component.salary_component_id._id ===
                                '53ae739a79df44bac9b0b9f1' ||
                                component.salary_component_id._id ===
                                '53ae73a779df44bac9b0ba0a' ||
                                component.salary_component_id._id ===
                                '53ae738f79df44bac9b0b9da' ||
                                component.salary_component_id._id ===
                                '6124d7ff9b6ed50d4422690a' ||
                                component.salary_component_id._id ===
                                '53ae739d79df44bac9b0b9f7' ||
                                component.salary_component_id._id ===
                                '6295aaa19028f27e7e904a77')
                        ) {
                            if (!component?.isNotCtcIncluded) {
                                total = component?.amount * 1 || 0;
                            }
                        }
                        return amount + total;
                    }, 0)) ||
                    0);
            return { ...response };
        },
        multi: true,
        dependencies: [
            'attendance_base_components',
            'performance_base_components',
            'deductions_components',
            'fix_base_components',
            '_set_ctc',
        ],
    },
    nestedComputations: {
        performance_base_components: {
            amount: {
                compute: value => {
                    if (value.level_details) {
                        let amount = value.level_details.reduce(
                            (accum, doc) => accum + Number(doc.amount) || 0,
                            0,
                        );
                        return amount;
                    }
                },
                dependencies: ['level_details'],
            },
            nestedComputations: {
                level_details: {
                    per_head_amount: {
                        compute: value => {
                            if (value.rate && value.target) {
                                let amount = value.rate * value.target || 0;
                                return amount;
                            }
                        },
                        dependencies: ['target', 'rate'],
                    },
                    amount: {
                        compute: value => {
                            if (value.per_head_amount && value.no_of_resources) {
                                let amount =
                                    Math.floor(value.per_head_amount * value.no_of_resources) ||
                                    0;
                                return amount;
                            }
                        },
                        dependencies: ['no_of_resources', 'per_head_amount'],
                    },
                },
            },
        },
    },
});

export const SalaryTemplateDetail = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/EmployeeSalaryComponents',
        eventSourceId: 'employeeSalaryComponent',
    });
    const { fetch, user } = useAppStateContext();
    return (
        <Form
            api={`/employeesalarycomponent/${row?._id}`}
            beforeSubmit={({ data }) => {
                const { artificialTotalAmount = 0, ctc_amount = 0 } = data;
                if (
                    artificialTotalAmount < ctc_amount ||
                    artificialTotalAmount > ctc_amount
                ) {
                    throw new Error(
                        'CTC Amount should be same as Total Amount Calculated',
                    );
                }
                delete data.artificialTotalAmount;
                return {
                    data: {
                        ...data,
                    },
                };
            }}
            onSubmit={onSubmit}
            submitAction="Save"
            defaultValues={{
                is_master_component: true,
                status: 'draft',
                created_on: new Date(),
            }}
            computations={{ ...SalaryTemplateComputation(fetch) }}
            layoutFields={[
                {
                    label: 'Basic Details',
                    fields: [
                        {
                            type: 'text',
                            label: 'Name',
                            placeholder: 'Template Name',
                            field: 'name',
                            size: 4,
                        },
                        {
                            field: 'ctc_amount',
                            label: 'CTC Amount',
                            type: 'number',
                            placeholder: 'CTC Amount',
                            size: 4,
                        },
                        {
                            label: 'Total Amount Calculated',
                            type: 'number',
                            field: 'artificialTotalAmount',
                            size: 4,
                            readOnly: true,
                        },
                        {
                            type: 'richText',
                            field: 'description',
                            label: 'Description',
                            size: 6,
                        },
                    ],
                },
                {
                    label: 'Earning',
                    fields: [
                        {
                            field: 'attendance_base_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        key={values.to_date}
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                label: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 220,
                                            }
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 200,
                                                align: 'center',
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
                {
                    label: 'Performance',
                    fields: [
                        {
                            field: 'performance_base_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                field: 'link_with',
                                                type: 'autoComplete',
                                                suggestionField: 'label',
                                                keyField: 'value',
                                                valueField: 'label',
                                                options: [
                                                    { value: 'delivery', label: 'Delivery' },
                                                    { value: 'manual', label: 'Manual' },
                                                    { value: 'project', label: 'Project' },
                                                    { value: 'manager', label: 'Manager' },
                                                ],
                                                size: 6,
                                                label: 'Link with',
                                            },
                                            {
                                                label: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 300,
                                            },
                                            {
                                                label: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 150,
                                            },
                                            {
                                                field: 'link_with',
                                                type: 'text',
                                                header: 'Link with',
                                                suggestionField: 'label',
                                            },
                                            {
                                                innerNested: true,
                                                header: 'Level Detail',
                                                label: 'Level Details',
                                                fields: [
                                                    {
                                                        type: 'text',
                                                        field: 'level_details',
                                                        nested: true,
                                                        addInBottom: true,

                                                        render: props => {
                                                            return (
                                                                <NestedTable
                                                                    {...props}
                                                                    columns={[
                                                                        {
                                                                            field: 'level.name',
                                                                            header: 'Level.',
                                                                            width: 100,
                                                                        },
                                                                        {
                                                                            field: 'no_of_resources',
                                                                            header: 'No of Resources',
                                                                            type: 'number',
                                                                            width: 100,
                                                                        },
                                                                        {
                                                                            field: 'amount',
                                                                            header: 'Amount',
                                                                            type: 'number',
                                                                            width: 80,
                                                                        },
                                                                        {
                                                                            field: 'per_head_amount',
                                                                            header: 'Per Head Amount',
                                                                            type: 'number',
                                                                            width: 150,
                                                                        },
                                                                        {
                                                                            field: 'rate',
                                                                            header: 'Rate',
                                                                            type: 'number',
                                                                            width: 80,
                                                                        },
                                                                        {
                                                                            field: 'target',
                                                                            header: 'Target',
                                                                            type: 'number',
                                                                            width: 80,
                                                                        },
                                                                    ]}
                                                                    fields={[
                                                                        {
                                                                            field: 'level',
                                                                            label: 'Level',
                                                                            suggestionField: 'name',
                                                                            valueField: 'name',
                                                                            api: '/leveltypes',
                                                                            type: 'autoComplete',
                                                                        },
                                                                        {
                                                                            field: 'no_of_resources',
                                                                            label: 'No of Resources',
                                                                            type: 'number',
                                                                        },
                                                                        {
                                                                            field: 'rate',
                                                                            label: 'Rate',
                                                                            type: 'number',
                                                                        },
                                                                        {
                                                                            field: 'target',
                                                                            label: 'Target',
                                                                            type: 'number',
                                                                        },
                                                                    ]}
                                                                />
                                                            );
                                                        },
                                                    },
                                                ],
                                                width: 200,
                                            },
                                            {
                                                header: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 200,
                                            },
                                            {
                                                header: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
                {
                    label: 'Deduction',
                    fields: [
                        {
                            field: 'deductions_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                label: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 220,
                                            },
                                            {
                                                label: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 350,
                                            },
                                            {
                                                label: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 200,
                                                align: 'center',
                                            },
                                            {
                                                header: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 250,
                                            },
                                            {
                                                header: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 100,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
                {
                    label: 'Bonus',
                    fields: [
                        {
                            field: 'fix_base_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                label: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 220,
                                            },
                                            {
                                                label: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 350,
                                            },
                                            {
                                                label: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 100,
                                            },
                                            {
                                                header: 'Locking Date',
                                                field: 'locking_date',
                                                type: 'date',
                                                width: 150,
                                            },
                                            {
                                                header: 'Pay On',
                                                type: 'text',
                                                field: 'pay_on',
                                                width: 100,
                                            },
                                            {
                                                header: 'Pay On Confirmation',
                                                type: 'checkbox',
                                                field: 'pay_on_confirmation',
                                                width: 100,
                                            },
                                            {
                                                header: 'Remark',
                                                type: 'text',
                                                field: 'remarks',
                                                width: 150,
                                            },
                                            {
                                                header: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
            ]}
            {...props}
        />
    );
};

export const AddSalaryTemplateDetail = props => {
    return <SalaryTemplateDetail header="Add a Template" {...props} />;
};

export const SalaryTemplateDetailForm = props => {
    const { route: { params = {} } = {} } = props;
    return (
        <SalaryTemplateDetail
            mode="edit"
            readOnly={true}
            header={'Salary Template Details'}
            {...props}
        />
    );
};

export const SalaryTemplateEditDetailForm = props => {
    const { route: { params = {} } = {} } = props;
    return (
        <SalaryTemplateDetail
            mode="edit"
            header={'Salary Template Details'}
            {...props}
        />
    );
};


export const SalaryTemplateDuplicate = props => {
    const { route: { params } = {} } = props;
    const { row } = params;
    const { data: employeeComponentData } = useFetchData({
        api: `/employeesalarycomponent/${row?._id}`,
    });
    if (!employeeComponentData) {
        return null;
    }
    let { onSubmit } = useFormSubmit({
        uri: '/EmployeeSalaryComponents',
        eventSourceId: 'employeeSalaryComponent',
    });
    const { fetch, user } = useAppStateContext();
    return (
        <Form
            api={`/employeesalarycomponent/${row?._id}`}
            beforeSubmit={({ data }) => {
                const { artificialTotalAmount = 0, ctc_amount = 0 } = data;
                if (
                    artificialTotalAmount < ctc_amount ||
                    artificialTotalAmount > ctc_amount
                ) {
                    throw new Error(
                        'CTC Amount should be same as Total Amount Calculated',
                    );
                }
                delete data.artificialTotalAmount;
                delete data._id;
                return {
                    data: {
                        ...data,
                    },
                };
            }}
            onSubmit={onSubmit}
            submitAction="Save"
            defaultValues={{
                ...employeeComponentData,
                is_master_component: true,
                status: 'draft',
                created_on: new Date(),
            }}
            computations={{ ...SalaryTemplateComputation(fetch) }}
            layoutFields={[
                {
                    label: 'Basic Details',
                    fields: [
                        {
                            type: 'text',
                            label: 'Name',
                            placeholder: 'Template Name',
                            field: 'name',
                            size: 4,
                        },
                        {
                            field: 'ctc_amount',
                            label: 'CTC Amount',
                            type: 'number',
                            placeholder: 'CTC Amount',
                            size: 4,
                        },
                        {
                            label: 'Total Amount Calculated',
                            type: 'number',
                            field: 'artificialTotalAmount',
                            size: 4,
                            readOnly: true,
                        },
                        {
                            type: 'richText',
                            field: 'description',
                            label: 'Description',
                            size: 6,
                        },
                    ],
                },
                {
                    label: 'Earning',
                    fields: [
                        {
                            field: 'attendance_base_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        key={values.to_date}
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                label: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 220,
                                            }
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 200,
                                                align: 'center',
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
                {
                    label: 'Performance',
                    fields: [
                        {
                            field: 'performance_base_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                field: 'link_with',
                                                type: 'autoComplete',
                                                suggestionField: 'label',
                                                keyField: 'value',
                                                valueField: 'label',
                                                options: [
                                                    { value: 'delivery', label: 'Delivery' },
                                                    { value: 'manual', label: 'Manual' },
                                                    { value: 'project', label: 'Project' },
                                                    { value: 'manager', label: 'Manager' },
                                                ],
                                                size: 6,
                                                label: 'Link with',
                                            },
                                            {
                                                label: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 300,
                                            },
                                            {
                                                label: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 150,
                                            },
                                            {
                                                field: 'link_with',
                                                type: 'text',
                                                header: 'Link with',
                                                suggestionField: 'label',
                                            },
                                            {
                                                innerNested: true,
                                                header: 'Level Detail',
                                                label: 'Level Details',
                                                fields: [
                                                    {
                                                        type: 'text',
                                                        field: 'level_details',
                                                        nested: true,
                                                        addInBottom: true,

                                                        render: props => {
                                                            return (
                                                                <NestedTable
                                                                    {...props}
                                                                    columns={[
                                                                        {
                                                                            field: 'level.name',
                                                                            header: 'Level.',
                                                                            width: 100,
                                                                        },
                                                                        {
                                                                            field: 'no_of_resources',
                                                                            header: 'No of Resources',
                                                                            type: 'number',
                                                                            width: 100,
                                                                        },
                                                                        {
                                                                            field: 'amount',
                                                                            header: 'Amount',
                                                                            type: 'number',
                                                                            width: 80,
                                                                        },
                                                                        {
                                                                            field: 'per_head_amount',
                                                                            header: 'Per Head Amount',
                                                                            type: 'number',
                                                                            width: 150,
                                                                        },
                                                                        {
                                                                            field: 'rate',
                                                                            header: 'Rate',
                                                                            type: 'number',
                                                                            width: 80,
                                                                        },
                                                                        {
                                                                            field: 'target',
                                                                            header: 'Target',
                                                                            type: 'number',
                                                                            width: 80,
                                                                        },
                                                                    ]}
                                                                    fields={[
                                                                        {
                                                                            field: 'level',
                                                                            label: 'Level',
                                                                            suggestionField: 'name',
                                                                            valueField: 'name',
                                                                            api: '/leveltypes',
                                                                            type: 'autoComplete',
                                                                        },
                                                                        {
                                                                            field: 'no_of_resources',
                                                                            label: 'No of Resources',
                                                                            type: 'number',
                                                                        },
                                                                        {
                                                                            field: 'rate',
                                                                            label: 'Rate',
                                                                            type: 'number',
                                                                        },
                                                                        {
                                                                            field: 'target',
                                                                            label: 'Target',
                                                                            type: 'number',
                                                                        },
                                                                    ]}
                                                                />
                                                            );
                                                        },
                                                    },
                                                ],
                                                width: 200,
                                            },
                                            {
                                                header: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 200,
                                            },
                                            {
                                                header: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
                {
                    label: 'Deduction',
                    fields: [
                        {
                            field: 'deductions_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                label: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 220,
                                            },
                                            {
                                                label: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 350,
                                            },
                                            {
                                                label: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 200,
                                                align: 'center',
                                            },
                                            {
                                                header: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 250,
                                            },
                                            {
                                                header: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 100,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
                {
                    label: 'Bonus',
                    fields: [
                        {
                            field: 'fix_base_components',
                            nested: true,
                            render: props => {
                                const {
                                    form: { values = {} },
                                } = props;
                                return (
                                    <NestedTable
                                        {...props}
                                        fields={[
                                            {
                                                label: 'Salary Component',
                                                field: 'salary_component_id',
                                                type: 'autoComplete',
                                                api: '/salarycomponents',
                                                suggestionField: 'name',
                                                valueField: 'name',
                                            },
                                            {
                                                label: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 220,
                                            },
                                            {
                                                label: 'Remarks',
                                                field: 'remarks',
                                                type: 'text',
                                                width: 350,
                                            },
                                            {
                                                label: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                header: 'Salary Component',
                                                field: 'salary_component_id.name',
                                                type: 'text',
                                            },
                                            {
                                                header: 'Amount',
                                                field: 'amount',
                                                type: 'number',
                                                width: 100,
                                            },
                                            {
                                                header: 'Locking Date',
                                                field: 'locking_date',
                                                type: 'date',
                                                width: 150,
                                            },
                                            {
                                                header: 'Pay On',
                                                type: 'text',
                                                field: 'pay_on',
                                                width: 100,
                                            },
                                            {
                                                header: 'Pay On Confirmation',
                                                type: 'checkbox',
                                                field: 'pay_on_confirmation',
                                                width: 100,
                                            },
                                            {
                                                header: 'Remark',
                                                type: 'text',
                                                field: 'remarks',
                                                width: 150,
                                            },
                                            {
                                                header: 'NIC',
                                                type: 'checkbox',
                                                field: 'isNotCtcIncluded',
                                                width: 50,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                },
            ]}
            {...props}
        />
    );
};

export const DuplicateSalaryTemplate = props => {
    return <SalaryTemplateDuplicate header="Duplicate Template" {...props} />;
};