import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const ProjectMemberForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {employee_assignment_id} = params;
  const {user} = useAppStateContext();
  const {org} = user;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'Employee',
    ...props,
  });

  return (
    <Form
      api={`/employeeassignments/${employee_assignment_id?._id}`}
      fields={{
        employee: {name: 1},
        from_date: 1,
        to_date: 1,
        percentage: 1,
        allow_allocation_more_than_100: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project_id: params?.project?._id,
      }}
      layoutFields={[
        {
          label: 'Employee Name',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          filter: {
            user_organization: org,
          },
          required: true,
        },
        {
          label: 'Allocation from',
          type: 'date',
          field: 'from_date',
          required: true,
          size: 'medium',
          validate: (value, {values} = {}) => {
            if (!value) {
              return;
            }

            const {to_date} = values;
            const valueDate = new Date(value);
            const toDate = new Date(to_date);

            let result = valueDate >= toDate;

            if (result) {
              return 'from_date should be less than toDate.';
            }
          },
        },
        {
          label: 'Allocation till',
          type: 'date',
          field: 'to_date',
          required: true,
          size: 'medium',
          validate: (value, {values} = {}) => {
            if (!value) {
              return;
            }

            const {from_date} = values;
            const valueDate = new Date(value);
            const fromDate = new Date(from_date);

            let result = valueDate <= fromDate;

            if (result) {
              return 'to_date should be more than from_date.';
            }
          },
        },
        {
          label: 'Allocation Percentage',
          type: 'number',
          field: 'percentage',
          required: true,
          size: 'medium',
        },
        {
          label: 'Allow Allocation More than 100%',
          type: 'checkbox',
          field: 'allow_allocation_more_than_100',
          size: 'medium',
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectMemberForm = props => {
  return <ProjectMemberForm header="Add Member" {...props} />;
};

export const UpdateProjectMemberForm = props => {
  return <ProjectMemberForm mode="edit" header="Update Member" {...props} />;
};
