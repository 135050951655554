import { Row } from '@unthinkable/react-core-components';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import {
    ActiveRPSTable,
    ActiveRPSTableForPreSales,
    ActiveRPSTableForPreSalesSolCon,
    AllRPSTable,
    AllRPSTablePreSales,
    AllRPSTablePreSalesSolCon,
    ClosedRPSTable,
    ClosedRPSTableSolCon,
    RFQCreatedRPSTable,
    RFQCreatedRPSTableSolCon,
    RFRCreatedRPSTable,
    RFRCreatedRPSTableSolCon,
    UnassignedRPSTable,
    UnassignedRPSTablePreSales
} from './RPSTable';

export const RPSTableTabView = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;

  params = {
    ...rest,
    product,
    organization,
  };
  const tabs = {
    unassignedRPSTable: {
      label: 'Unassigned',
      view: (
        <UnassignedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/unAssigned',
      params: params,
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },
    activeRPSTable: {
      label: 'Active',
      view: (
        <ActiveRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/active',
      params: params,
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },
    // overdueRPSTable: {
    //   label: 'Overdue',
    //   view: (
    //     <OverdueRPSTable
    //       search={searchValue}
    //       addOnFilter={filter}
    //       filterParams={filterParams}
    //       params={params}
    //     />
    //   ),
    //   api: '/opportunity/rps/overdue',
    //   eventSourceId: 'rps',
    // },
    closedRPSTable: {
      label: 'Closed',
      view: (
        <ClosedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/closed',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },

    rfqCreatedRPSTable: {
      label: 'RFQ Created',
      view: (
        <RFQCreatedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/rfqCreated',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      addOnFilter: filter,
      search: props?.search,
    },
    rfrCreatedRPSTable: {
      label: 'RFR Created',
      view: (
        <RFRCreatedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/rfrCreated',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },
    allRPSTable: {
      label: 'All',
      view: (
        <AllRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/all',
      addOnFilter: filter,
      params: params,
      search: props?.search,
      eventSourceId: 'rps',
    },
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Requirement"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Pre Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'rfq_owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'multiAutoComplete',
              label: 'Sol. Consultant',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'tech_person',
            },
            {
              type: 'multiAutoComplete',
              label: 'Pri. Sol.Consultant',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'primary_owner',
            },
            // {
            //   type: 'date',
            //   field: 'creation_date',
            //   asParam: true,
            //   label: 'Created On',
            // },
            // {
            //   type: 'date',
            //   field: 'rfr_date',
            //   asParam: true,
            //   label: 'Rfr Date',
            // },
            // {
            //   type: 'date',
            //   field: 'rfq_date',
            //   asParam: true,
            //   label: 'Rfq Date',
            // },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton title="RPS" view="add-rps" params={props?.route?.params} />
        </Row>,
      ]}
    />
  );
};

export const RPSTableTabViewForPreSales = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;

  params = {
    ...rest,
    // product,
    organization,
  };
  const tabs = {
    unassignedRPSTable: {
      label: 'Unassigned',
      view: (
        <UnassignedRPSTablePreSales
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/unAssigned',
      params: params,
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },
    activeRPSTable: {
      label: 'Active',
      view: (
        <ActiveRPSTableForPreSales
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/active',
      params: params,
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },
    // overdueRPSTable: {
    //   label: 'Overdue',
    //   view: (
    //     <OverdueRPSTable
    //       search={searchValue}
    //       addOnFilter={filter}
    //       filterParams={filterParams}
    //       params={params}
    //     />
    //   ),
    //   api: '/opportunity/rps/overdue',
    //   eventSourceId: 'rps',
    // },
    closedRPSTable: {
      label: 'Closed',
      view: (
        <ClosedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/closed',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },

    rfqCreatedRPSTable: {
      label: 'RFQ Created',
      view: (
        <RFQCreatedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/rfqCreated',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      addOnFilter: filter,
      search: props?.search,
    },
    rfrCreatedRPSTable: {
      label: 'RFR Created',
      view: (
        <RFRCreatedRPSTable
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/rfrCreated',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
    },
    allRPSTable: {
      label: 'All',
      view: (
        <AllRPSTablePreSales
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/all',
      addOnFilter: filter,
      params: params,
      search: props?.search,
      eventSourceId: 'rps',
    },
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Requirement"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Status',
              options: ['Active', 'RFQ Created', 'RFR Created', 'Closed'],
              placeholder: 'Select',
              field: 'status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
              secondarySuggestionField: 'official_email_id',
            },
            {
              type: 'autoComplete',
              label: 'Pre Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'rfq_owner',
            },
            {
              type: 'multiAutoComplete',
              label: 'Sol. Consultant',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'tech_person',
            },
            {
              type: 'multiAutoComplete',
              label: 'Pri. Sol.Consultant',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'primary_owner',
            },
            // {
            //   type: 'date',
            //   field: 'creation_date',
            //   asParam: true,
            //   label: 'Created On',
            // },
            // {
            //   type: 'date',
            //   field: 'rfr_date',
            //   asParam: true,
            //   label: 'Rfr Date',
            // },
            // {
            //   type: 'date',
            //   field: 'rfq_date',
            //   asParam: true,
            //   label: 'Rfq Date',
            // },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton title="RPS" view="add-rps" params={props?.route?.params} />
        </Row>,
      ]}
    />
  );
};

export const RPSTableTabViewForPreSalesSolCon = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;

  params = {
    ...rest,
    // product,
    organization,
  };

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const tabs = {
    allRPSTable: {
      label: 'All',
      view: (
        <AllRPSTablePreSalesSolCon
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/all/solution-consultant',
      addOnFilter: filter,
      params: params,
      search: props?.search,
      filter: {
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      },
      eventSourceId: 'rps',
    },
    // unassignedRPSTable: {
    //   label: 'Unassigned',
    //   view: (
    //     <UnassignedRPSTablePreSalesSolCon
    //       search={searchValue}
    //       addOnFilter={filter}
    //       filterParams={filterParams}
    //       params={params}
    //     />
    //   ),
    //   api: '/opportunity/rps/unAssigned',
    //   params: params,
    //   eventSourceId: 'rps',
    //   search: props?.search,
    //   addOnFilter: filter,
    //   filter: {
    //     $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    //   },
    // },
    activeRPSTable: {
      label: 'Active',
      view: (
        <ActiveRPSTableForPreSalesSolCon
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/active/solution-consultant',
      params: params,
      eventSourceId: 'rps',
      search: props?.search,
      filter: {
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      },
      addOnFilter: filter,
    },
    // overdueRPSTable: {
    //   label: 'Overdue',
    //   view: (
    //     <OverdueRPSTable
    //       search={searchValue}
    //       addOnFilter={filter}
    //       filterParams={filterParams}
    //       params={params}
    //     />
    //   ),
    //   api: '/opportunity/rps/overdue',
    //   eventSourceId: 'rps',
    // },
    closedRPSTable: {
      label: 'Closed',
      view: (
        <ClosedRPSTableSolCon
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/closed/solution-consultant',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      filter: {
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      },
      search: props?.search,
      addOnFilter: filter,
    },

    rfqCreatedRPSTable: {
      label: 'RFQ Created',
      view: (
        <RFQCreatedRPSTableSolCon
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/rfqCreated/solution-consultant',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      addOnFilter: filter,
      search: props?.search,
      filter: {
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      },
    },
    rfrCreatedRPSTable: {
      label: 'RFR Created',
      view: (
        <RFRCreatedRPSTableSolCon
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      api: '/opportunity/rps/rfrCreated/solution-consultant',
      params: {
        ...params,
      },
      eventSourceId: 'rps',
      search: props?.search,
      addOnFilter: filter,
      filter: {
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      },
    },
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Requirement"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
              secondarySuggestionField: 'official_email_id',
            },
            {
              type: 'autoComplete',
              label: 'Pre Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'rfq_owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            // {
            //   type: 'date',
            //   field: 'creation_date',
            //   asParam: true,
            //   label: 'Created On',
            // },
            // {
            //   type: 'date',
            //   field: 'rfr_date',
            //   asParam: true,
            //   label: 'Rfr Date',
            // },
            // {
            //   type: 'date',
            //   field: 'rfq_date',
            //   asParam: true,
            //   label: 'Rfq Date',
            // },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton title="RPS" view="add-rps" params={props?.route?.params} />
        </Row>,
      ]}
    />
  );
};
