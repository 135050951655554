import React, {useEffect, useState} from 'react';
import {
  Col,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useInvoke} from '../../controllers/useInvoke';
import {TextInput} from '../../components/form-editors/Editors';
import {useAppStateContext} from '../../providers/AppState';
import {useNavigation} from '@react-navigation/native';
import {EmailPermissionStyle} from './theme';
import {useStyles} from '@unthinkable/react-theme';

import GoogleOAuthProvider from '../../providers/GoogleOAuthProvider';
import {useGoogleLogin} from '@react-oauth/google';
import {OutlineButton} from '../../components/button/OutlineButton';
import {toast} from '@unthinkable/react-toast';

const IDs = {
  daffodilsw_com:
    '882165799426-dcr7umivje99ca3o8e36kikqpjnn8ms3.apps.googleusercontent.com',
  unthinkable_co:
    '828671039860-ulm0dskmdautqjblpd4kqlv008gq0b83.apps.googleusercontent.com',
  unthinkable_email:
    '655409756637-hbgcmojd6r8fvul2jj71vchvp8a5stts.apps.googleusercontent.com',
  daffodildb_com:
    '170640571680-hdnjank273eruro52v9te937cib1ip1u.apps.googleusercontent.com',
};

const RequestEmailAccess = ({state, updateState}) => {
  const {user, setUser} = useAppStateContext();
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation();
  const invoke = useInvoke({
    method: 'post',
  });

  const requestEmailPermission = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
    access_type: 'offline',
    responseType: 'code',
    prompt: 'consent',
    onSuccess: async response => {
      try {
        setLoading(true);
        let res = await invoke({
          uri: `/users/${user._id}/giveEmailAccess`,
          props: {
            ...response,
            userEmail: user?.email,
            permissionEmail: state?.email,
          },
        });
        if (res) {
          setUser({...user, readMailAccess: true});
          toast({
            message: 'Email sync process initialized successfully',
            type: 'Success',
          });
        } else {
          toast({message: 'Unable to sync email', type: 'Error'});
        }
        setLoading(false);
        navigation.navigate('dashboard');
      } catch (err) {
        setLoading(false);
        toast({message: 'Unable to sync email', type: 'Error'});
      }
    },
    flow: 'auth-code',
    onError: err => {
      // console.log('onfailuer error::', err);
    },
  });

  return (
    <>
      {state?.email && state?.googleClientId && (
        <OutlineButton
          text={'Give the access to read email'}
          onPress={requestEmailPermission}
          loading={loading}
        />
      )}
    </>
  );
};

const EmailPermission = () => {
  const {user, setUser} = useAppStateContext();
  const [state, updateState] = useState({
    email: user?.email,
    googleClientId: '',
  });

  const styles = useStyles(EmailPermissionStyle);

  useEffect(() => {
    const userEmail = state?.email?.toString();
    const emailDomain = userEmail.split('@')?.[1]?.replace('.', '_');
    const googleClientId = IDs[emailDomain];

    if (googleClientId) {
      updateState({...state, googleClientId});
    } else {
      updateState({...state, googleClientId: ''});
    }
  }, [state.email]);

  return (
    <Col gap={18}>
      <Text style={styles.emailText}>Permission to read email</Text>
      <TextInput
        value={state.email}
        onChangeText={email => updateState({...state, email})}
        placeholder={'Email'}
      />

      {state?.googleClientId ? (
        <GoogleOAuthProvider clientId={state?.googleClientId}>
          <RequestEmailAccess state={state} updateState={updateState} />
        </GoogleOAuthProvider>
      ) : (
        void 0
      )}
    </Col>
  );
};

export default EmailPermission;
