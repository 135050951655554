import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const ModuleMenuRightsForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {product, department, row, target_audience, organization} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/modulemenurights',
    eventSourceId: 'moduleMenuRights',
    ...props,
  });

  return (
    <Form
      api={`/modulemenurights/${row?._id}`}
      fields={{
        entity: {
          name: 1,
        },
        entity_id: {
          name: 1,
        },
        roles: 1,
        scope: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Module Menu Rights'}}
      defaultValues={{
        module_menu: row?._id,
      }}
      layoutFields={[
        {
          label: 'Entity Details',
          fields: [
            {
              placeholder: 'Entity',
              label: 'Entity',
              field: 'entity',
              type: 'cascader',
              // required: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'entity',
                  api: '/types',
                  suggestionField: 'label',
                  valueField: 'label',
                  // required: true,
                },
                {
                  label: 'Entity Name',
                  placeholder: 'Entity_id',
                  field: 'entity_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  // required: true,
                  params: ({values: {entity} = {}}) => {
                    return {
                      source: entity,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
            {
              type: 'text',
              field: 'entity_id.name',
              placeholder: 'Entity Name',
              label: 'Entity Name',
              // required: true,
            },
          ],
        },
        {
          label: 'Roles',
          type: 'multiAutoComplete',
          api: '/approle/modifiedData',
          field: 'roles',
          required: true,
        },
        {
          label: 'Scope',
          type: 'text',
          field: 'scope',
        },
      ]}
      {...props}
    />
  );
};

export const EditModuleMenuRightsForm = props => {
  return (
    <ModuleMenuRightsForm
      header={{title: 'Edit Module Menu Rights'}}
      mode="edit"
      {...props}
    />
  );
};
