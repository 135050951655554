import React from 'react';
import {Table} from '../../../components/table/Table';
import {Industry} from '../controllers/Industry';

export const ActiveIndustryTable = props => {
  const {navigation} = props;
  const {markIndustryInactive} = Industry(props);
  return (
    <Table
      eventSourceId="Industry"
      api={`/industry/active`}
      onRowPress={props => {
        navigation.navigate(`industry-detail`, {
          ...props,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'code',
          header: 'Code',
        },
        {
          type: 'date',
          field: 'creation_date',
          header: 'Creation Date',
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark InActive',
          confirm: {
            title: 'Mark InActive',
            message: 'Are you sure you want to Mark InActive?',
          },
          onPress: markIndustryInactive,
        },
      ]}
    />
  );
};
