// view import history logs activity - Parveen 20-01-2024
import React from 'react';
import {Table} from '../../../components/table/Table';

export const ImportHistoryLogsActivityTable = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  let addOnFilter = {
    import_logs_id: _id,
  };
  return (
    <Table
      {...props}
      api={`/importhistorylogsactivities`}
      fields={{
        _id: 1,
        index: 1,
        import_logs_id: 1,
        campaign_contact_id: {name},
        potential_customer: {name},
        success: 1,
        campaign_id: {name: 1},
        data_logs: {first_name: 1, last_name: 1, email: 1},
        error: 1,
        potential_customer_status: 1,
        potential_customer_contact_status: 1,
        campaign_contact_status: 1,
      }}
      filter={addOnFilter}
      columns={[
        {
          type: 'text',
          field: 'index',
          header: 'Index',
          width: 70,
        },
        {
          type: 'text',
          field: 'data_logs.first_name',
          header: 'First Name',
          width: 100,
        },
        {
          type: 'text',
          field: 'data_logs.last_name',
          header: 'Last Name',
          width: 100,
        },
        {
          type: 'text',
          field: 'data_logs.email',
          header: 'Email',
          width: 150,
        },
        {
          type: 'text',
          field: 'campaign_id.name',
          header: 'Campaign',
          width: 250,
        },
        {
          type: 'text',
          field: 'potential_customer.name',
          header: 'Potential Customer',
          width: 150,
        },
        {
          type: 'text',
          field: 'potential_customer_status',
          header: 'Prospect',
          width: 100,
        },
        {
          type: 'text',
          field: 'potential_customer_contact_status',
          header: 'Contact Status',
          width: 150,
        },
        {
          type: 'text',
          field: 'campaign_contact_id.name',
          header: 'Campaign Contact',
          width: 150,
        },
        {
          type: 'text',
          field: 'campaign_contact_status',
          header: 'CMP Status',
          width: 150,
        },

        {
          type: 'text',
          field: 'success',
          header: 'Success',
          width: 100,
        },
        {
          type: 'textArea',
          field: 'error',
          header: 'Error',
        },
      ]}
    />
  );
};
