import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form, InlineForm} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';

export const RepositoryEnvironmentConfigurationForm = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;

  const {project, repository, row} = params;
  const {user} = useAppStateContext();

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'RepositoryEnvironmentConfigurations',
    uri: '/repositoryenvironmentconfigurations',
    ...props,
  });

  const defaultValues = {
    project_id: project,
    repository_id: repository?._id,
    created_by: user,
  };

  return (
    <Form
      api={`/repositoryenvironmentconfigurations/${row?._id}`}
      fields={{
        deployment_environment_id: {environment: 1},
        repository_id: {label: 1},
        deployment_platform_id: {platform: 1},
        pipeline: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Deployment Platform',
          type: 'autoComplete',
          field: 'deployment_platform_id',
          api: `/deploymentplatforms`,
          suggestionField: 'platform',
          secondarySuggestionField: 'url',
          valueField: 'platform',
          filter: {project_id: project?._id},
          fields: {
            platform: 1,
            url: 1,
          },
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('deployment-platform-add-form', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
        },
        {
          field: 'deployment_environment_id',
          type: 'autoComplete',
          fields: {
            environment: 1,
            parent: 1,
            branch: 1,
          },
          api: `/projects/${project._id}/deploymentEnvironments`,
          suggestionField: 'environment',
          valueField: 'environment',
          label: 'Environment',
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('branch_name', value?.branch);
          },
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('project-environment-add-form', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
          required: true,
        },
        {
          field: 'branch_name',
          type: 'text',
          label: 'Branch name',
          readOnly: true,
          visible: ({values}) => {
            if (values.branch_name) {
              return true;
            }
            return false;
          },
        },
        {
          type: 'text',
          field: 'pipeline',
          label: 'Job name',
          required: true,
          visible: () =>
            repository?.deployment_platform?.platform === 'Jenkins',
        },
        {
          type: 'text',
          field: 'pipeline',
          label: 'Pipeline',
          required: true,
          visible: () =>
            repository?.deployment_platform?.platform === 'Bitbucket',
        },
      ]}
      {...props}
    />
  );
};

export const AddRepositoryEnvironmentConfigurationInlineForm = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;

  const {project, repository} = params;
  const {user} = useAppStateContext();

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'RepositoryEnvironmentConfigurations',
    uri: '/repositoryEnvironmentConfigurations',
    ...props,
  });

  const defaultValues = {
    project_id: project,
    repository_id: repository?._id,
    created_by: user,
  };
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Deployment Platform',
          type: 'autoComplete',
          field: 'deployment_platform_id',
          api: `/deploymentplatforms`,
          suggestionField: 'platform',
          secondarySuggestionField: 'url',
          valueField: 'platform',
          filter: {project_id: project?._id},
          fields: {
            platform: 1,
            url: 1,
          },
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('deployment-platform-add-form', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
        },
        {
          field: 'deployment_environment_id',
          type: 'autoComplete',
          fields: {
            environment: 1,
            parent: 1,
            branch: 1,
          },
          api: `/projects/${project._id}/deploymentEnvironments`,
          suggestionField: 'environment',
          valueField: 'environment',
          label: 'Environment',
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue('branch_name', value?.branch);
          },
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('project-environment-add-form', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
          required: true,
        },
        {
          type: 'text',
          field: 'pipeline',
          label: 'Job name/Pipeline',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateRepositoryEnvironmentConfigurationForm = props => {
  return (
    <RepositoryEnvironmentConfigurationForm
      mode="edit"
      header="Deployment Platform Detail"
      {...props}
    />
  );
};
