import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

export const ProjectAuditTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {row = {}} = params;
  return (
    <Table
      {...props}
      params={params}
      api={`/projectaudits`}
      filter={{project: row?._id}}
      eventSourceId={'performance-report'}
      renderHeader={() => {
        return (
          <TableHeader title="Project Audits" secondaryTitle={row?.project} />
        );
      }}
      fields={{
        auditer: {
          name: 1,
          color: 1,
        },
        audit_comments: 1,
        audit_date: 1,
        next_audit_date: 1,
      }}
      sort={{plan_project_audit: -1}}
      onRowPress={({row}) => {
        navigation.navigate('update-project-audit-plan', {
          row,
          fromHistory : true
        });
      }}
      columns={[
        {
          field: 'auditer',
          type: 'userAvatarChip',
          header: 'Auditer',
        },
        {
          field: 'next_audit_date',
          type: 'date',
          header: 'Next Audit Date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'audit_date',
          type: 'date',
          header: 'Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'audit_comments',
          type: 'text',
          header: 'Comments',
        },
      ]}
    />
  );
};
