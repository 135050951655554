import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {CustomerTable} from './CustomerTable';

export const CustomerOppTabView = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  let tabs = {};
  tabs.all = {
    label: 'All',
    view: (
      <CustomerTable
        filter={{
          created_on: {
            $gte: period.from,
            $lte: period.to,
          },
        }}
        sort={{created_on: -1}}
        {...props}
      />
    ),
  };

  return <TabView tabs={tabs} {...props} />;
};
