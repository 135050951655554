import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {ProfileDetailPageStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {BaseForm} from '../../../components/form/Form';
import {Button} from '../../../components/button/Button';
import Header from './Header';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const ProfileDetail = props => {
  const {navigation} = props || {};
  const {
    containerStyle,
    mainContainerStyle,
    subContainerStyle,
    textStyle,
    buttonStyles,
  } = useStyles(ProfileDetailPageStyles);

  const {user, logout} = useAppStateContext();
  const [orgId, setOrgId] = React.useState(null);
  const {email} = user || {};

  const {onSubmit} = useFormSubmit({
    uri: `/saveuserdetails`,
    method: 'post',
  });

  return (
    <View style={containerStyle}>
      <Header email={email} logout={logout} />
      <View style={subContainerStyle}>
        <View style={mainContainerStyle}>
          <Text style={textStyle}>Please enter your profile details :</Text>
          <BaseForm
            mode="edit"
            api={`/users/${user._id}`}
            fields={{
              name: 1,
              org: {name: 1},
              mobile: 1,
            }}
            skipHeader
            footer={({values}) => {
              let data = values;
              if (
                values &&
                Object.keys(values).length &&
                values.org &&
                values.org._id === orgId
              ) {
                data.org_admin = true;
              } else {
                data.org_admin = null;
              }
              return {
                actions: [
                  <View style={{flex: 1, marginTop: 28}}>
                    <Button
                      onPress={async () => {
                        await onSubmit(data);
                        location.reload();
                      }}
                      text="Get Started"
                      styles={buttonStyles}
                      gap={8}
                      disabled={
                        !(
                          values &&
                          values.mobile &&
                          values.mobile.length === 10
                        )
                      }
                    />
                  </View>,
                ],
              };
            }}
            skipDefaultSave
            layoutFields={[
              {
                type: 'text',
                field: 'name',
                label: 'Full Name',
                required: true,
              },
              {
                label: "Company's Name",
                field: 'org',
                type: 'autoComplete',
                api: '/userorganizations',
                suggestionField: 'name',
                valueField: 'name',
                onCreate: ({searchValue, onChange}) => {
                  return navigation.navigate(`add-company`, {
                    defaultValues: {
                      name: searchValue,
                    },
                    afterSubmit: ({data}) => {
                      setOrgId(data._id);
                      onChange?.(data);
                    },
                  });
                },
                required: true,
              },
              {
                label: 'Mobile number',
                field: 'mobile',
                type: 'text',
                required: true,
              },
            ]}
            {...props}
          />
        </View>
      </View>
    </View>
  );
};

export default ProfileDetail;
