import {View, Styled, Text, Row} from '@unthinkable/react-core-components';

export const RowContainer = Styled(Row)`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #EEEEED;
  align-items: center;
  ${({styles}) => styles?.rowContainer}
`;

export const RowContent = Styled(View)`
  flex: 1;
  overflow: hidden;
  ${({styles}) => styles?.rowContent}
`;

export const RowPrimaryText = Styled(Text)`
  font-size: 14px;
  font-family: Inter-Regular;
  line-height: 20px;
  color: #19191A;
  text-align-vertical: center;
  ${({styles}) => styles?.rowText}
`;

export const RowSecondaryText = Styled(Text)`
  font-size: 12px;
  font-family: Inter-Regular;
  line-height: 16px;
  color: #7D7D82;
  text-align-vertical: center;
  ${({styles}) => styles?.rowSecondaryText}
`;
