import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import pluralize from 'pluralize';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {useFetchData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';
import {TargetAudienceTabs} from '../../leadGeneration/views/TargetAudienceTabs';

const ProductMenu = createMenuNavigator();
export const ProductNavigator = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  const {product} = params;
  const {data: activeElements} = useFetchData({
    api: `/activeElements`,
    filter: {product: product?._id},
    fields: {
      label: 1,
      model: 1,
      filter: 1,
      on_press_url: 1,
      department: {
        name: 1,
      },
      product: {
        name: 1,
      },
    },
    sort: {
      sequence: 1,
    },
  });
  const mergedParams = {
    ...params,
    period: params?.period || getPreviousMonth(),
  };
  const dynamicMenuParams = [];
  if (activeElements?.length) {
    activeElements.forEach(activeElement => {
      let {
        label,
        model,
        filter,
        on_press_url = 'default',
        department,
        product,
      } = activeElement;
      const menuParams = {
        label,
        onPress: () => {
          navigation.navigate(`${on_press_url?.toLowerCase()}`, {
            department,
            product,
            ...params,
          });
        },
      };
      if (filter) {
        filter = JSON.parse(filter);
        if (model?.name) menuParams.api = `/${pluralize.plural(model?.name)}`;
        menuParams.filter = filter;
        menuParams.params = params;
      }
      dynamicMenuParams.push({...menuParams});
    });
  }

  if (dynamicMenuParams?.length) {
    dynamicMenuParams[0].defaultSelected = true;
  }

  return (
    <ProductMenu.Navigator
      data-navigator-id="Product"
      canAccess={canAccess}
      {...props}
      params={params}>
      <ProductMenu.Screen
        data-resource-id="BuyerPersona"
        component={TargetAudienceTabs}
        options={{
          label: 'Target Audience',
        }}
      />
      <ProductMenu.Screen
        data-resource-id="CampaignGroup"
        options={{
          label: 'Campaigns',
          onPress: () => {
            props.navigation.navigate('campaigns-menu', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Group>
        <ProductMenu.Screen
          data-resource-id="PotentialCustomer"
          options={{
            label: 'Prospects',
            onPress: () => {
              props.navigation.navigate('prospects', {...params});
            },
          }}
        />
      </ProductMenu.Group>
      <ProductMenu.Screen
        data-resource-id="PotentialCustomer"
        options={{
          label: 'Leads',
          onPress: () => {
            props.navigation.navigate('leads-menu', {...params});
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="OpportunityGroup"
        options={{
          label: 'Opportunities',
          onPress: () => {
            props.navigation.navigate('opportunity-menu', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Screen
        data-resource-id="Customer"
        options={{
          label: 'Customers',
          onPress: () => {
            props.navigation.navigate('customer-dashboard', {
              ...params,
            });
          },
        }}
      />
      <ProductMenu.Section
        options={{
          label: 'Projects',
        }}>
        <ProductMenu.Screen
          data-resource-id="Billed"
          props={{...params, scope: 'product'}}
          options={{
            label: 'Billed',
            onPress: () => {
              navigation.navigate('organization-projects-menu', {
                ...params,
              });
            },
          }}
        />
        <ProductMenu.Screen
          data-resource-id="Non-Billed"
          props={{...params, scope: 'product'}}
          options={{
            label: 'Non-Billed',
            onPress: () => {
              navigation.navigate('projects-unbilled-menu', {
                ...params,
              });
            },
          }}
        />
      </ProductMenu.Section>
      <ProductMenu.Screen
        data-resource-id="Vendor"
        options={{
          label: 'Vendors',
          onPress: () => {
            props.navigation.navigate('vendor-dashboard', {
              ...params,
            });
          },
        }}
      />
      {/* <ProductMenu.Screen
          data-resource-id="Project"
          props={{...params, scope: 'product'}}
          options={{
            label: 'Old Projects',
            onPress: () => {
              navigation.navigate('organization-projects-menu', {
                ...params,
              });
            },
          }}
        /> */}
    </ProductMenu.Navigator>
  );
};

export default ProductNavigator;
