import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

const AgeingForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/invoicereceipthistories',
        eventSourceId: ['Ageing'],
    });

    return (
        <Form
            api={`/invoicereceipthistories/${row?._id}`}
            onSubmit={onSubmit}
            eventSourceId="Ageing"
            submitAction="Save"
            defaultValues={{
                type : 'Invoice'
             }}
            layoutFields={[
                {
                    placeholder: 'Customer',
                    field: 'customer',
                    type: 'autoComplete',
                    api: `/customers`,
                    label: 'Customer',
                    suggestionField: 'name',
                    valueField: 'name',
                    size : 6
                },
                {
                    type: 'date',
                    field: 'date',
                    placeholder: 'Date',
                    label: 'Date',
                    size : 6
                },
                {
                    field : 'number',
                    placeholder: 'Number',
                    label: 'Number',
                    type: 'text',
                    size : 6
                },
                {
                    field : 'base_invoice_pending_amount',
                    placeholder: 'Amount',
                    label: 'Amount',
                    type: 'number',
                    size : 6
                },
                {
                    placeholder: 'Organization',
                    field: 'organization',
                    type: 'autoComplete',
                    api: `/organizations`,
                    label: 'Organization',
                    suggestionField: 'name',
                    valueField: 'name',
                    size : 6
                },
            ]}
            {...props}
        />
    );
};

export const CreateAgeingForm = props => {
    return <AgeingForm header="Create Ageing" {...props} />;
};
// export const EditAccountForm = props => {
//     return <AgeingForm mode="edit" header="Update Account" {...props} />;
// };
