import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';

export const MyOrderTarget = props => {
  const {route: {params} = {}} = props;
  const {targetOrder} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/ordertargets',
    eventSourceId: 'OrderTarget',
  });
  const {user = {}} = useAppStateContext();
  const {employee = {}} = user;
  return (
    <Form
      api={`/ordertargets/${targetOrder?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        order: targetOrder?.order?._id,
        customer: targetOrder?.customer?._id,
        project_resource_working_data:
          targetOrder?.project_resource_working_data?.[0],
        employee: employee?._id,
        project: targetOrder?.project,
      }}
      fields={{
        project: {project: 1},
        from_date: 1,
        to_date: 1,
        target_hours: 1,
      }}
      layoutFields={[
        {
          label: 'Project',
          field: 'project',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          size: 12,
        },
        {
          label: 'Form Date',
          field: 'from_date',
          type: 'date',
          required: true,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Target Hours',
          field: 'target_hours',
          type: 'number',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddMyOrderTarget = props => {
  return <MyOrderTarget header="Add Order Target" {...props} />;
};

export const UpdateMyOrderTarget = props => {
  return <MyOrderTarget mode="edit" header="Update Order Target" {...props} />;
};
