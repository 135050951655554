import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';

export const DialogueBoxForm = props => {
  const {
    route: {params = {}},
  } = props;

  let {
    header = 'Date',
    field = 'confirmed_on',
    label = 'Date',
    uri = '',
    eventSourceId = '',
    status = 'In Experiment',
  } = params;
  if (uri == '') {
    return (
      <div>
        <h3>Please Contact the developer for the issue.</h3>
      </div>
    );
  }

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: eventSourceId,
  });

  const updateStatus = data => {
    invoke({
      uri: uri,
      props: {
        ...data,
        status,
      },
    });
  };

  return (
    <Form
      onSubmit={updateStatus}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        [field]: new Date(),
      }}
      layoutFields={[
        {
          type: 'date',
          placeholder: 'Date',
          field: field,
          label: label,
        },
      ]}
    />
  );
};
