import {InlineForm} from '../../../../components/form/Form';
import {AITable} from '../../../../components/table/AITable';
import {TableHeader} from '../../../../components/table/Headers';
import {Table} from '../../../../components/table/Table';
import {
  AISuggestionRender,
  AISuggestionsButton,
} from '../../cellrenders/AISuggestionRenders';
import {useFetchData} from '../../../../controllers/useFetchData';
import {useInvoke} from '../../../../controllers/useInvoke';
import {PROJECT_VIEW_TYPE_ID} from '../../../common/constants/SourceConstants';

export const MappedModelViewScreen = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row, readOnly, project, module, feature, ...rest} = params;

  let {data, ...fetchProps} = useFetchData({
    api: `/projects/${project?._id}/asset/${PROJECT_VIEW_TYPE_ID}/all-data`,
    eventSourceId: ['MapModelView', 'ProjectAI'],
    filter: {
      _id: row?.source_id,
    },
    fields: {
      model_view_ids: {name: 1, path: 1, query: 1},
    },
  });
  let TableComponent = Table;
  if (module) {
    TableComponent = AITable;
  }

  data = data?.[0] || {};

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'MapModelView',
  });

  const onSubmit = props => {
    putInvoke({
      uri: '/projectassetversions/mapmodelview',
      props,
    });
  };

  let viewIds = data?.model_view_ids?.map(m => m?._id);

  return (
    <>
      <TableHeader
        title={'Views'}
        actions={[
          project?.ai_enabled && module && feature && row?.model_id ? (
            <AISuggestionsButton
              {...props}
              entityName="projectmodelview"
              filter={{
                model_id: row?.model_id,
              }}
              eventSourceId="ProjectAI"
              params={{
                parent_entity: 'Model',
                parent_entity_id: row?.model_id?._id,
                label: 'ModelViews',
                model_id: row?.model_id?._id,
                view_id: row?._id,
                project_id: project?._id,
                module_id: module?._id,
                feature_id: feature?._id,
              }}
              initialValues={{
                model_id: row?.model_id,
              }}
            />
          ) : (
            void 0
          ),
        ]}
      />
      {row?.model_id && !readOnly ? (
        <InlineForm
          defaultValues={{
            source: row?.source,
            source_id: row?.source_id,
            view_id: row?._id,
          }}
          onSubmit={onSubmit}
          layoutFields={[
            {
              type: 'autoComplete',
              api: '/projectmodelviews',
              label: 'Model View',
              field: 'model_view_id',
              suggestionField: 'name',
              required: true,
              filter: {model_id: row?.model_id?._id, _id: {$nin: viewIds}},
              fields: {name: 1},
              onCreate: ({searchValue, onChange}) => {
                navigation.navigate(`add-project-model-view`, {
                  ...rest,
                  model: row?.model_id,
                  searchValue,
                  afterSubmit: ({data}) => {
                    onChange?.(data);
                  },
                });
              },
            },
          ]}
        />
      ) : (
        void 0
      )}
      <TableComponent
        {...fetchProps}
        data={data?.model_view_ids}
        fields={{
          name: 1,
          path: 1,
          query: 1,
        }}
        secondaryApiProps={{
          api: `/projectmodelviews/ai`,
          params: {
            parent_entity: 'Model',
            parent_entity_id: row?.model_id?._id,
          },
          eventSourceId: 'ProjectAI',
        }}
        moreActions={[
          {
            title: 'Remove',
            variant: 'error',
            confirm: {
              title: 'Remove Model View',
              message:
                'Are you sure you want to remove this model view from Screen?',
              confirmText: 'Delete',
            },
            onPress: async ({row: {_id}}) => {
              await putInvoke({
                uri: '/projectassetversions/unmapmodelview',
                props: {
                  model_view_id: _id,
                  view_id: row?._id,
                },
              });
            },
          },
        ]}
        columns={[
          {
            header: 'Name',
            colspan: ({row}) => (row.aiGenerated ? 2 : void 0),
            render: _props => {
              const {row} = _props;
              return (
                <AISuggestionRender
                  {..._props}
                  params={params}
                  primaryField="name"
                  entityName="projectmodelview"
                  eventSourceId="ProjectAI"
                  secondaryField={row?.aiGenerated ? 'query' : void 0}
                />
              );
            },
          },
          {
            header: 'Query',
            field: 'query',
          },
        ]}
      />
    </>
  );
};
