import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { NewFixedOrderTable, NewTMOrderTable } from './NewOrderTable';

export const NewOrderTab = props => {
  const {route: {params} = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;

  const tabs = {
    t_and_m: {
      label: 'T&M',
      api: `/tmNewOrder`,
      limit: 5000,
      addOnFilter: {...filter, order_status: {$in: ['Active']}},
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewTMOrderTable
          addOnFilter={{...filter, order_status: {$in: ['Active']}}}
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
        />
      ),
    },

    fixed: {
      label: 'Fixed',
      api: `/fixedNewOrder`,
      limit: 5000,
      addOnFilter: {...filter, order_status: {$in: ['Active']}},
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewFixedOrderTable
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          addOnFilter={{
            ...filter,
            order_status: {$in: ['Active']},
          }}
        />
      ),
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ]}
    />
  );
};

export const NewOrderForAmTab = props => {
  const {route: {params} = {}} = props;
  const {
    user: {employee},
  } = useAppStateContext();
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;

  const tabs = {
    t_and_m: {
      label: 'T&M',
      api: `/tmNewOrder`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        account_owner_id: employee?._id,
        order_status: {$in: ['Active']},
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewTMOrderTable
          addOnFilter={{
            ...filter,
            account_owner_id: employee?._id,
            order_status: {$in: ['Active']},
          }}
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
        />
      ),
    },

    fixed: {
      label: 'Fixed',
      api: `/fixedNewOrder`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        account_owner_id: employee?._id,
        order_status: {$in: ['Active']},
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewFixedOrderTable
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          addOnFilter={{
            ...filter,
            account_owner_id: employee?._id,
            order_status: {$in: ['Active']},
          }}
        />
      ),
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ]}
    />
  );
};
