import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {
  Col,
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';

import {VersionCardStyles} from './theme';

export const VersionCard = ({
  row,
  onPress,
  titleField,
  isActive,
  currentId,
}) => {
  const {
    featureIcon,
    container,
    activeContainer,
    boldTextStyle,
    textStyle,
    activeStatusStyle,
    releasedStatusStyle,
    dateStyle,
    currentTextStyle,
  } = useStyles(VersionCardStyles);
  let {version, status, released_on, feature_id} = row;

  const showCurrent = currentId === row?._id ? ' (Current)' : '';

  const {feature} = feature_id || {};

  if (released_on) {
    released_on = moment(released_on).format('DD MMM, YY');
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{...container, ...(isActive ? activeContainer : {})}}>
      <Col gap={6} style={{flex: 1, overflow: 'hidden'}}>
        <Row gap={4}>
          <Text style={boldTextStyle} numberOfLines={1}>
            {`V${version}`}
          </Text>
          {showCurrent ? (
            <Text style={currentTextStyle}>{showCurrent}</Text>
          ) : null}
        </Row>
        {feature ? (
          <Row gap={4} style={{alignItems: 'center'}}>
            <Image source={featureIcon} />
            <Text style={textStyle} numberOfLines={1} title={feature}>
              {feature}
            </Text>
          </Row>
        ) : (
          void 0
        )}
      </Col>
      <Col gap={6} style={{marginLeft: 12, alignItems: 'flex-end'}}>
        <Text
          style={
            status === 'active'
              ? activeStatusStyle
              : status === 'released'
              ? releasedStatusStyle
              : textStyle
          }>
          {status}
        </Text>
        {released_on && status === 'released' ? (
          <Text style={dateStyle}>{released_on}</Text>
        ) : (
          void 0
        )}
      </Col>
    </TouchableOpacity>
  );
};

export const VersionList = ({
  data,
  onRowPress,
  titleField,
  selectedId,
  currentId,
}) => {
  const [selectedRow, setSelectedRow] = useState({_id: selectedId});

  useEffect(() => {
    setSelectedRow({_id: selectedId});
  }, [selectedId]);

  return (
    <ScrollView style={{gap: 10}}>
      {data.map(row => {
        return (
          <VersionCard
            isActive={selectedRow?._id === row?._id}
            currentId={currentId}
            titleField={titleField}
            onPress={() => {
              setSelectedRow(row);
              onRowPress?.({row});
            }}
            row={row}
          />
        );
      })}
    </ScrollView>
  );
};
