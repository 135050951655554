import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';
import NestedTable from '../../../components/form/NestedTable';
import {Rating} from '@unthinkable/react-star-rating';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

const RatingRenderer = ({
  row,
  index,
  feedbacks = [],
  setFieldValue,
  ...rest
}) => {
  const updateFeedback = value => {
    const newFeedbacks = [...feedbacks];
    newFeedbacks[index].value = value;
    setFieldValue('feedbacks', newFeedbacks);
  };

  return <Rating {...rest} value={row['value']} onChange={updateFeedback} />;
};
export const EditFeedbackPIP = props => {
  const {
    route: {params},
  } = props;
  const {training_feedback} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/trainingFeedbacks',
    eventSourceId: 'TrainingFeedbacks',
  });
  return (
    <Form
      onSubmit={onSubmit} 
      defaultValues={{
        ...training_feedback,
        training_feedback_date: getZeroTimeDate(new Date()),
        status: 'active',
      }}
      api={`/trainingFeedbacks/${training_feedback._id}`}
      layoutFields={[
        {
          label: 'Tech Knowledge',
          fields: [
            {
              type: 'rating',
              field: 'tech_knowledge',
              size: 4,
            },
          ],
          size: 4,
        },
        {
          label: 'Code Quality',
          fields: [
            {
              type: 'rating',
              field: 'code_quality',
              size: 4,
            },
          ],
          size: 4,
        },
        {
          label: 'Ensure Timeline',
          fields: [
            {
              type: 'rating',
              field: 'ensure_timeline',
              size: 4,
            },
          ],
          size: 4,
        },

        {
          label: 'Communication',
          fields: [
            {
              type: 'rating',
              field: 'communication',
              size: 4,
            },
          ],
          size: 4,
        },

        {
          label: 'Punctuality',
          fields: [
            {
              type: 'rating',
              field: 'punctuality',
              size: 4,
            },
          ],
          size: 4,
        },
        {
          label: 'Following Process',
          fields: [
            {
              type: 'rating',
              field: 'following_processes',
              size: 4,
            },
          ],
          size: 4,
        },

        {
          label: 'Ownership',
          fields: [
            {
              type: 'rating',
              field: 'ownership',
              size: 4,
            },
          ],
          size: 4,
        },

        {
          label: 'Adoptability',
          fields: [
            {
              type: 'rating',
              field: 'adoptability',
              size: 4,
            },
          ],
          size: 4,
        },

        {
          field: 'mentor_subjective_feedback',
          type: 'textArea',
          label: 'Remarks',
        },
      ]}
      {...props}
    />
  );
};
export const EditFeedback = props => {
  const params = props?.route?.params || {};
  const {training_feedback = {}} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/trainingFeedbacks',
    eventSourceId: 'TrainingFeedbacks',
  });
  return (
    <Form
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          training_feedback_date: new Date(),
          status: 'active',
        },
      })}
      onSubmit={onSubmit}
      mode="edit"
      api={`/trainingFeedbacks/${training_feedback._id}`}
      fields={{
        feedbacks: {
          criteria: {
            name: 1,
          },
          value: 1,
          weightage: 1,
        },
        training_program: {
          course_type: 1,
        },
        mentor_subjective_feedback: 1,
      }}
      layoutFields={[
        {
          field: 'feedbacks',
          nested: true,
          render: props => {
            const {
              form: {values, setFieldValue},
            } = props;
            return (
              <NestedTable
                {...props}
                key={JSON.stringify(values.feedbacks)}
                readOnly={true}
                fields={[
                  {
                    field: 'criteria.name',
                    label: 'Criteria',
                    type: 'text',
                    size: 8,
                  },
                  {
                    field: 'value',
                    type: 'rating',
                    label: 'Rating',
                    size: 3,
                  },
                ]}
                columns={[
                  {
                    field: 'criteria.name',
                    type: 'text',
                    header: 'Criteria',
                  },
                  {
                    type: 'rating',
                    header: 'Rating',
                    render: RatingRenderer,
                    feedbacks: values.feedbacks,
                    setFieldValue,
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'mentor_subjective_feedback',
          type: 'textArea',
          label: 'Remarks',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const FeedbackForm = props => {
  const params = props?.route?.params;

  const {employee_training = {}} = params || {};
  return (
    <EditFeedback
      header={{
        title: 'Feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 3,
              }}>
              {employee_training?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const FeedbackFormPIP = props => {
  const params = props?.route?.params;

  const {employee_training = {}} = params || {};
  return (
    <EditFeedbackPIP
      header={{
        title: 'Feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 3,
              }}>
              {employee_training?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const MyFeedbackDetails = props => {
  const {
    route: {params},
  } = props;
  const {feedback, employee_training} = params;
  return (
    <Form
      readOnly
      header={{
        title: 'Feedback',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 3,
              }}>
              {employee_training?.name}
            </Text>
          );
        },
      }}
      mode="edit"
      api={`/trainingFeedbacks/${feedback?._id}`}
      fields={{
        feedbacks: {
          criteria: {
            name: 1,
          },
          value: 1,
          weightage: 1,
        },
        training_program: {
          course_type: 1,
        },
        mentor_subjective_feedback: 1,
      }}
      layoutFields={[
        {
          field: 'feedbacks',
          nested: true,
          render: props => {
            return (
              <NestedTable
                {...props}
                readOnly={true}
                fields={[
                  {
                    field: 'criteria.name',
                    label: 'Criteria',
                    type: 'text',
                    size: 8,
                  },
                  {
                    field: 'value',
                    type: 'rating',
                    label: 'Rating',
                    size: 3,
                  },
                ]}
                columns={[
                  {
                    field: 'criteria.name',
                    type: 'text',
                    header: 'Criteria',
                  },
                  {
                    field: 'value',
                    header: 'Rating',
                    type: 'rating',
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'mentor_subjective_feedback',
          type: 'textArea',
          label: 'Remarks',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
