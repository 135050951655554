import React from 'react';
import {Row, Text} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {aggregateCardStyles} from './theme';

export const AggregateCard = ({label, value, color, gap = 16, onPress}) => {
  const styles = useStyles(aggregateCardStyles);
  const colorStyle = color ? styles[color] : void 0;

  return (
    <Row gap={gap} style={styles.container} onPress={onPress}>
      <Text style={[styles.label, colorStyle]}>{label}</Text>
      {value ? (
        <Text style={[styles.aggregate, colorStyle]}>{value}</Text>
      ) : (
        void 0
      )}
    </Row>
  );
};
