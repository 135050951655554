import moment from 'moment';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {Chip} from '../../../components/chip/Chip';
import {leadControllers} from '../controllers/leadControllers';
import {useTheme} from '@unthinkable/react-theme';
import {TabView} from '../../../components/tab/TabView';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {leadToCustomerFilter} from './CampaignCommonFilter';

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YYYY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const POCRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_detail?.[0]?.designation) return null;
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
          numberOfLines: 1,
        }}>
        {row?.contact_detail?.[0]?.designation}
      </Text>
    </Row>
  );
};

const NAME = {
  field: 'name',
  header: 'Lead',
  type: 'text',
};

const INDUSTRY = {
  field: 'industry.name',
  colorField: 'industry.color',
  header: 'Industry',
  type: 'colorTag',
  width: 110,
};

const EMPLOYEE_COUNT = {
  field: 'employee_count.number',
  header: 'Size',
  width: 100,
};

const LAST_COMMUNICATION = communications => ({
  header: 'Communication',
  render: CommunicationRender,
  onPress: communications,
  width: 160,
});

const OWNER = {
  header: 'Owner',
  field: 'owner',
  type: 'userAvatar',
  width: 80,
};

const CHANNEL = {
  header: 'Channel',
  field: 'campaign_type.label',
  colorField: 'campaign_type.color',
  type: 'colorTag',
};

const LEAD_STATUS = props => {
  const {row} = props;
  return <Chip value={row?.lead_status} />;
};

const STAGE = {
  field: 'stage.name',
  colorField: 'stage.color',
  header: 'Stage',
  type: 'colorTag',
  width: 100,
};

const COUNTRY = {
  field: 'country.code',
  colorField: 'country.color',
  header: 'Country',
  type: 'colorTag',
  width: 110,
};

const LEAD_SCORE = {
  header: 'Value',
  field: 'lead_score',
  toFixed: 2,
  width: 100,
  align: 'right',
};

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const underline = row?.website ? {textDecoration: 'underline'} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.website &&
          Linking.openURL(
            row?.website.startsWith('https')
              ? row?.website
              : `https://${row?.website}`,
            '_blank',
          );
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};

const CampaignLeadList = props => {
  const {
    route: {params},
    campaignLeadfilter,
  } = props;
  const {communications, makeLead} = leadControllers(props);
  return (
    <Table
      {...campaignLeadfilter}
      onRowPress={({row}) => {
        props.navigation.navigate('lead-detail', {
          leadId: row._id,
          row,
        });
      }}
      fields={{
        name: 1,
        lead_status: 1,
        lead_score: 1,
        stage: {name: 1},
        industry: {name: 1},
        employee_count: {number: 1},
        last_communication_date: 1,
        last_communication: 1,
        contact_detail: {designation: 1, email: 1},
        campaign_type: {name: 1, color: 1},
        owner: {name: 1, color: 1},
        country: {name: 1, code: 1, color: 1},
        website: 1,
        uql_stage_date: 1,
        expected_ql_date: 1,
        sql_stage_date: 1,
        lifecycle: 1,
      }}
      columns={[
        {
          header: 'Lead',
          render: RenderName,
          width: 300,
        },
        EMPLOYEE_COUNT,
        INDUSTRY,
        COUNTRY,
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        // CHANNEL,
        OWNER,
        {header: 'Status', render: LEAD_STATUS, width: 100},
        STAGE,
        {
          header: 'Lifecycle Date',
          field: 'lifecycle',
          type: 'date',
        },
        {
          header: 'UQL Date',
          field: 'uql_stage_date',
          type: 'date',
        },
        {
          header: 'Expected Ql Date',
          field: 'expected_ql_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'QL Date',
          field: 'sql_stage_date',
          type: 'date',
        },
        LEAD_SCORE,
        LAST_COMMUNICATION(communications),
      ]}
      moreActions={() => [
        {
          title: 'Move to QL',
          onPress: makeLead,
        },
      ]}
    />
  );
};

export const CampaignLeadListTab = props => {
  const {route: {params} = {}} = props;
  let {
    filter,
    row,
    fromChannel,
    period = {},
    potential,
    previous_potential,
    addOnFilter = {},
    fromNurturable,
    fromOpportunityLead,
  } = params;

  addOnFilter = {...addOnFilter, ...leadToCustomerFilter(row)};

  filter = {...filter, ...addOnFilter};

  if (fromNurturable) {
    filter = {...filter, is_nurturable: true};
  }

  let campaignLeadfilter = {
    api: '/potentialcustomers',
    filter: {
      ...filter,
      campaign_id: row?._id,
    },
  };
  if (fromChannel) {
    campaignLeadfilter = {
      api: '/potentialcustomers',
      filter: {
        ...filter,
        channel: row?._id,
      },
    };
  }

  if (potential) {
    let periodFilter = {...period};
    if (previous_potential) {
      periodFilter.to = getZeroTimeDate(
        moment(periodFilter?.from).subtract(1, 'M').endOf('M').toDate(),
      );
      periodFilter.from = getZeroTimeDate(
        moment(periodFilter?.from).subtract(1, 'M').startOf('M').toDate(),
      );
    }
    campaignLeadfilter = {
      api: '/potential-customer-communication',
      filter: {
        period: periodFilter,
        campaign_type: row?._id,
        ...addOnFilter,
      },
    };
  }

  const tabs = {
    active: {
      label: potential
        ? 'Potential Customers'
        : fromNurturable
        ? 'Nurturable Leads'
        : fromOpportunityLead
        ? 'Opportunity Leads'
        : 'Leads',
      view: <CampaignLeadList campaignLeadfilter={campaignLeadfilter} />,
      ...campaignLeadfilter,
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CampaignLeadListTabForOutreachBatch = props => {
  const {route: {params} = {}} = props;
  const {filter, row} = params;

  const campaignLeadfilter = {
    api: '/potentialcustomers',
    filter: {
      ...filter,
      batch_id: row?._id,
    },
  };

  const tabs = {
    active: {
      label: 'Leads',
      view: <CampaignLeadList campaignLeadfilter={campaignLeadfilter} />,
      ...campaignLeadfilter,
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
