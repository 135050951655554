import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import moment from 'moment';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {useProjectActions} from '../../pmt/actions/ProjectActions';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';

const ContactRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {field: 'website', type: 'text'},
      {
        field: 'email',
        type: 'text',
      },
    ]}
  />
);

const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      title={row?.last_communication}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const LostReasonRender = props => {
  console.log('props>>>>>..', props);
  const {row, styles = {}} = props;
  return (
    <PrimaryColumnCard
      titleOnPress={() => {
        props.navigation.navigate('customer-description-pop-up', {
          row,
        });
      }}
      primaryTitle={row?.lost_description_comment}
      items={[
        {
          value: moment(row.lost_date).format('DD MMM, YY'),
        },
      ]}
      // {...props}
      // fields={[
      //   {field: 'lost_description_comment', type: 'text'},
      //   {
      //     field: 'lost_date',
      //     type: 'date',
      //   },
      // ]}
    />
  );
};

const POCRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_detail?.[0]?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
        }}>
        {row?.contact_detail?.[0]?.designation}
      </Text>
    </Row>
  );
};

const NextTaskRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (row?.last_communication) {
    return (
      <>
        <Text
          numberOfLines={1}
          title={row?.next_task}
          style={{
            ...rowText,
            color: '#30839f',
            overflow: 'hidden',
          }}>
          {row?.next_task}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {row?.next_task_date
            ? moment(row?.next_task_date).format('DD MMM')
            : null}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const NAME = {
  field: 'name',
  header: 'Prospects',
  type: 'text',
};

const INDUSTRY = {
  field: 'industry.name',
  colorField: 'industry.color',
  header: 'Industry',
  type: 'colorTag',
};

const EMPLOYEE_COUNT = {
  field: 'employee_count.number',
  header: 'Size',
  width: 100,
};

const OWNER = {
  header: 'Owner',
  field: 'owner',
  type: 'userAvatar',
  width: 80,
};

const CURRENTCAMPAIGN = {
  header: 'Current Campaign',
  field: 'campaign_id.name',
  type: 'colorTag',
  width: 80,
};

const LASTCAMPAIGN = {
  header: 'Last Campaign',
  field: 'campaign_id.name',
  type: 'colorTag',
  width: 80,
};

const CHANNEL = {
  header: 'Channel',
  field: 'channel.name',
  colorField: 'channel.color',
  type: 'colorTag',
  labelField: 'label',
  width: 150,
};

const COUNTRY = {
  type: 'colorTag',
  field: 'country.code',
  titleField: 'country.name',
  colorField: 'country.color',
  header: 'Country',
  width: 80,
};

const LEAD_SCORE = {
  header: 'Score',
  field: 'lead_score',
  toFixed: 2,
  width: 100,
  align: 'right',
};

const LEAD_STATUS = props => {
  const {row} = props;
  return <Chip value={row?.lead_status} />;
};

const NEXT_TASK = {
  header: 'Next Task',
  render: NextTaskRender,
  // width: 300
};

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const underline = row?.website ? {textDecoration: 'underline'} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.website &&
          Linking.openURL(
            row?.website.startsWith('https')
              ? row?.website
              : `https://${row?.website}`,
            '_blank',
          );
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};

export const AllProspectTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    created,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
    created,
  };
  const {activityLogDetailForProspect} = useProjectActions({
    navigation,
    params,
  });
  params = {...params, ...filterParams};
  const {product} = params;
  const {
    updateOwner,
    updateCampaign,
    communications,
    addCommunication,
    markFree,
    markLost,
    makeUql,
    makeLead,
    updateInsideSales,
  } = leadControllers(props);

  return (
    <Table
      api={`/prospect/all`}
      eventSourceId={['Lead', 'communicationAdded']}
      {...props}
      params={params}
      sort={{
        last_communication_date: -1,
      }}
      onRowPress={({row}) => {
        navigation.navigate(`prospect-detail`, {
          prospectId: row._id,
          row,
        });
      }}
      columns={[
        {header: 'Name', render: RenderName, width: 180},
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 100},
        LEAD_SCORE,
        CHANNEL,
        OWNER,
        {...CURRENTCAMPAIGN, width: 180},
        {header: 'Status', render: LEAD_STATUS, width: 120},
        {
          header: 'Communication',
          render: LastCommunicationRender,
          onPress: communications,
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForProspect,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const ActiveProspectTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter = {},
    addOnFilter: _addOnFilter,
    path,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  if (_addOnFilter) {
    filter = {...filter, ..._addOnFilter};
  }
  params = {...params, ...filterParams};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  let addOnFilter = {};
  if (path && path === 'task') {
    addOnFilter = {
      ...filter,
      next_task_date: getZeroTimeDate(new Date()),
      owner: employeeId?._id,
    };
  } else {
    addOnFilter = filter;
  }
  const {product} = params;
  const {
    updateOwner,
    updateCampaign,
    communications,
    addCommunication,
    markFree,
    markLost,
    makeUql,
    makeLead,
    updateInsideSales,
  } = leadControllers(props);
  const {activityLogDetailForProspect} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      api={`/prospect/active`}
      eventSourceId={['Lead', 'communicationAdded']}
      {...props}
      params={params}
      sort={{
        updatedAt: -1,
      }}
      addOnFilter={addOnFilter}
      onRowPress={({row}) => {
        navigation.navigate(`prospect-detail`, {
          prospectId: row._id,
          row,
        });
      }}
      columns={[
        {header: 'Name', render: RenderName},
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 200},
        {...COUNTRY, width: 80},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        // CHANNEL,
        LEAD_SCORE,
        OWNER,
        CHANNEL,
        {...CURRENTCAMPAIGN, width: 180},
        {
          header: 'Communication',
          render: LastCommunicationRender,
          onPress: communications,
          width: 150,
        },
        {...NEXT_TASK, width: 150},
      ]}
      selection={{
        actions: [
          {
            title: 'Update Owners',
            onPress: updateOwner,
          },
          {title: 'Update Campaign', onPress: updateCampaign},
          {title: 'Mark Free', onPress: markFree},
          {title: 'Update Inside Sales', onPress: updateInsideSales},
        ],
      }}
      moreActions={() => [
        {
          title: 'Move to QL',
          onPress: makeLead,
        },
        {
          title: 'Move to UQL',
          onPress: makeUql,
        },
        {title: 'Move to Lost', onPress: markLost},
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`prospect-detail`, {
              prospectId: row._id,
              row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForProspect,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const FreeProspectTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {product} = params;

  const {
    updateOwner,
    updateCampaign,
    communications,
    assignToMe,
    markLost,
    updateOwnerForFree,
    updateInsideSales,
  } = leadControllers(props);
  const {activityLogDetailForProspect} = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({method: 'put'});
  const markActive = ({row}) => {
    invoke({
      uri: `/potentialcustomers/${row?._id}`,
      props: {
        lead_status: 'Active',
        active_on: new Date(),
      },
      eventSourceId: 'Lead',
    });
  };

  return (
    <Table
      api={`/prospect/free`}
      eventSourceId={['Lead', 'communicationAdded']}
      {...props}
      params={params}
      sort={{
        last_communication_date: -1,
      }}
      onRowPress={({row}) => {
        navigation.navigate(`prospect-detail`, {
          prospectId: row._id,
          row,
        });
      }}
      columns={[
        {header: 'Name', render: RenderName},
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 180},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        // CHANNEL,
        LEAD_SCORE,
        // OWNER,
        // CHANNEL,
        {...LASTCAMPAIGN, width: 180},
        {
          header: 'Communication',
          render: LastCommunicationRender,
          onPress: communications,
          width: 150,
        },
      ]}
      selection={{
        actions: [
          {
            title: 'Update Owners',
            onPress: updateOwnerForFree,
          },
          {title: 'Update Campaign', onPress: updateCampaign},
          {title: 'Assign To Me', onPress: assignToMe},
          {title: 'Update Inside Sales', onPress: updateInsideSales},
        ],
      }}
      moreActions={[
        {
          title: 'Move to Active',
          onPress: markActive,
        },
        {
          title: 'Move to Lost',
          onPress: markLost,
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`prospect-detail`, {
              prospectId: row._id,
              row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForProspect,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const LostProspectTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    addOnFilter,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  const {activityLogDetailForProspect} = useProjectActions({
    navigation,
    params,
  });
  params = {...params, ...filterParams};
  const {product} = params;
  const {communications} = leadControllers(props);
  const invoke = useInvoke({method: 'put'});
  const markActive = ({selectedIds}) => {
    invoke({
      uri: `/potentialcustomers/batchUpdate`,
      props: {
        ids: selectedIds,
        updates: {
          lead_status: 'Active',
        },
      },
      eventSourceId: 'Lead',
    });
  };
  const markFree = ({row}) => {
    invoke({
      uri: `/potentialcustomers/${row?._id}`,
      props: {
        lead_status: 'Free',
      },
      eventSourceId: 'Lead',
    });
  };
  return (
    <Table
      api={`/prospect/lost`}
      eventSourceId={['Lead', 'communicationAdded']}
      onRowPress={({row}) => {
        navigation.navigate(`prospect-detail`, {
          prospectId: row._id,
          row,
        });
      }}
      sort={{
        lost_date: -1,
      }}
      addOnFilter={addOnFilter}
      {...props}
      params={params}
      selection={{
        actions: [
          {
            title: 'Mark Active',
            onPress: markActive,
          },
        ],
      }}
      columns={[
        {header: 'Name', render: RenderName, width: 180},
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 100},
        // {
        //   header: 'POC',
        //   render: POCRender,
        // },
        // CHANNEL,
        LEAD_SCORE,
        OWNER,
        CHANNEL,
        {...LASTCAMPAIGN, width: 180},
        {
          header: 'Lost Reason',
          // render: LostReasonRender,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                titleOnPress={() => {
                  navigation.navigate('lead-lost-description-pop-up', {
                    row,
                  });
                }}
                primaryTitle={row?.lost_description_comment}
                items={[
                  {
                    value: moment(row.lost_date).format('DD MMM, YY'),
                  },
                ]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'Communication',
          render: LastCommunicationRender,
          onPress: communications,
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Move to Active',
          onPress: markActive,
        },
        {
          title: 'Move to Free',
          onPress: markFree,
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`prospect-detail`, {
              prospectId: row._id,
              row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForProspect,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/potentialcustomers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const ActivityProspectTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {product} = params;
  const {
    updateOwner,
    updateCampaign,
    communications,
    markFree,
    markLost,
    makeUql,
    makeLead,
    updateInsideSales,
  } = leadControllers(props);
  const {activityLogDetailForProspect} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      api={`/prospect/active`}
      eventSourceId={['Lead', 'communicationAdded']}
      {...props}
      params={params}
      sort={{
        updatedAt: -1,
      }}
      onRowPress={({row}) => {
        navigation.navigate(`prospect-detail`, {
          prospectId: row._id,
          row,
        });
      }}
      columns={[
        {header: 'Name', render: RenderName},
        {...EMPLOYEE_COUNT, width: 150},
        {...INDUSTRY, width: 300},
        {...COUNTRY, width: 180},

        {...LEAD_SCORE},
        OWNER,
        {...CURRENTCAMPAIGN, width: 180},
        {
          header: 'Communication',
          render: LastCommunicationRender,
          onPress: communications,
          title: 'last_communication',
          width: 150,
        },
      ]}
      selection={{
        actions: [
          {
            title: 'Update Owners',
            onPress: updateOwner,
          },
          {title: 'Update Campaign', onPress: updateCampaign},
          {title: 'Mark Free', onPress: markFree},
          {title: 'Update Inside Sales', onPress: updateInsideSales},
        ],
      }}
      moreActions={() => [
        {
          title: 'Move to QL',
          onPress: makeLead,
        },
        {
          title: 'Move to UQL',
          onPress: makeUql,
        },
        {title: 'Move to Lost', onPress: markLost},
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`prospect-detail`, {
              prospectId: row._id,
              row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForProspect,
        },
      ]}
    />
  );
};
