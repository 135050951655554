import React from 'react';
import {Text, Col, Image, Row} from '@unthinkable/react-core-components';
import {objectTasksMoreAction} from '../../common/actions/ObjectWiseTasks';
import {GroupTable, Table} from '../../../components/table/Table';
import {leadControllers} from '../../leadGeneration/controllers/leadControllers';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {useTheme} from '@unthinkable/react-theme';
import {customDigitalPlatformOppControllers} from '../../leadGeneration/controllers/customDigitalPlatformControllers';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useInvoke} from '../../../controllers/useInvoke';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {useAppStateContext} from '../../../providers/AppState';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {OPPORTUNITY_TYPE_ID} from '../../common/constants/SourceConstants';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Tag} from '../../../components/chip/Chip';
import {UserAvatarGroup} from '../../../components/avatar/UserAvatar';
import {LastCommunicationRender} from '../../../utils/util';

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'next_task', type: 'text'},
        {
          field: 'next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row?.status && (row?.status == 'Drop' || row?.status == 'Lost')) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'invalidRfq') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          Drop
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'Won') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RenderCloseDate = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.creation_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.creation_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Open for: {row.days_difference} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderCloseDateForclosed = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.creation_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.creation_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Open for: {row.days_difference_for_open_since} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderTATDate = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.creation_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {row?.days_difference}d
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            {`${moment(row.creation_date).format('DD MMM')} -${moment(
              row.outcome_date,
            ).format('DD MMM')} `}
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderProposedEfforts = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {BODY2, CAPTION_LARGE} = useTheme('fonts');
  if (row?.proposal_hours) {
    return (
      <>
        <Col>
          <Text
            style={{
              ...BODY2,
              color: colors.INFORMATION_HIGH,
            }}>
            {(row.proposal_hours / 180).toFixed(2) || 0}MM
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            {moment(row.proposal_submit_date).format('DD MMM, YY')}
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderProposedEffortsForClosed = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {BODY2, CAPTION_LARGE} = useTheme('fonts');
  if (row?.proposal_hours) {
    return (
      <>
        <Text
          style={{
            ...BODY2,
            color: colors.INFORMATION_HIGH,
          }}>
          {(row.proposal_hours / 180).toFixed(2) || 0}MM
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};
// const CustomerRender = props => {
//   const {
//     row,
//     styles: {rowText},
//   } = props;

//   const colors = useTheme('colors');
//   const {CAPTION_LARGE} = useTheme('fonts');

//   if (row?.rfr_type == 'PotentailCustomer') {
//     const underline = row?.potential_customer?.website
//       ? {textDecoration: 'underline'}
//       : void 0;
//     return (
//       <>
//         <Col>
//           <TouchableOpacity
//             onPress={() => {
//               row?.potential_customer?.website &&
//                 Linking.openURL(
//                   row?.potential_customer?.website.startsWith('https')
//                     ? row?.potential_customer?.website
//                     : `https://${row?.potential_customer?.website}`,
//                   '_blank',
//                 );
//             }}>
//             <Text
//               numberOfLines={1}
//               style={{
//                 ...rowText,
//                 ...underline,
//               }}>
//               {row.potential_customer?.name}
//             </Text>
//             <Text
//               style={{
//                 ...CAPTION_LARGE,
//                 color: colors.NEUTRAL_LOW,
//               }}>
//               {row.industry?.name}
//             </Text>
//           </TouchableOpacity>
//         </Col>
//       </>
//     );
//   } else if (row?.rfr_type == 'Customer') {
//     const underline = row?.customer_id?.website
//       ? {textDecoration: 'underline'}
//       : void 0;
//     return (
//       <>
//         <Col>
//           <TouchableOpacity
//             onPress={() => {
//               row?.customer_id?.website &&
//                 Linking.openURL(
//                   row?.customer_id?.website.startsWith('https')
//                     ? row?.customer_id?.website
//                     : `https://${row?.customer_id?.website}`,
//                   '_blank',
//                 );
//             }}>
//             <Text
//               numberOfLines={1}
//               style={{
//                 ...rowText,
//                 ...underline,
//               }}>
//               {row.customer_id?.name}
//             </Text>
//             <Text
//               style={{
//                 ...CAPTION_LARGE,
//                 color: colors.NEUTRAL_LOW,
//               }}>
//               {row.industry?.name}
//             </Text>
//           </TouchableOpacity>
//         </Col>
//       </>
//     );
//   }
// };

// const CustomerRender = props => {
//   const {
//     row,
//     styles: {rowText},
//   } = props;

//   const colors = useTheme('colors');
//   const {CAPTION_LARGE} = useTheme('fonts');

//   if (row?.rfr_type == 'PotentailCustomer') {
//     const underline = row?.potential_customer?.website
//       ? {textDecoration: 'underline'}
//       : void 0;
//     return (
//       <>
//         <Col>
//           <TouchableOpacity
//             onPress={() => {
//               row?.potential_customer?.website &&
//                 Linking.openURL(
//                   row?.potential_customer?.website.startsWith('https')
//                     ? row?.potential_customer?.website
//                     : `https://${row?.potential_customer?.website}`,
//                   '_blank',
//                 );
//             }}>
//             <Text
//               numberOfLines={1}
//               style={{
//                 ...rowText,
//                 ...underline,
//               }}>
//               {row.potential_customer?.name}
//             </Text>
//             <Text
//               style={{
//                 ...CAPTION_LARGE,
//                 color: colors.NEUTRAL_LOW,
//               }}>
//               {row.industry?.name}
//             </Text>
//           </TouchableOpacity>
//         </Col>
//       </>
//     );
//   } else if (row?.rfr_type == 'Customer') {
//     const underline = row?.customer_id?.website
//       ? {textDecoration: 'underline'}
//       : void 0;
//     return (
//       <>
//         <Col>
//           <TouchableOpacity
//             onPress={() => {
//               row?.customer_id?.website &&
//                 Linking.openURL(
//                   row?.customer_id?.website.startsWith('https')
//                     ? row?.customer_id?.website
//                     : `https://${row?.customer_id?.website}`,
//                   '_blank',
//                 );
//             }}>
//             <Text
//               numberOfLines={1}
//               style={{
//                 ...rowText,
//                 ...underline,
//               }}>
//               {row.customer_id?.name}
//             </Text>
//             <Text
//               style={{
//                 ...CAPTION_LARGE,
//                 color: colors.NEUTRAL_LOW,
//               }}>
//               {row.industry?.name}
//             </Text>
//           </TouchableOpacity>
//         </Col>
//       </>
//     );
//   }
// };

const CustomerRender = props => {
  const {row} = props;
  const {CustomerOpportunity, ProspectIcon, CustomerNew} = useTheme('icons');

  if (row?.potential_customer) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.potential_customer?.name}
        // icon={ProspectIcon}
        renderIcon={() => {
          return (
            <Row gap={6}>
              <Tag
                color={'SURFACE2'}
                textColor={'NEUTRAL_LOW'}
                value={row.potential_customer?.lead_score}
              />
              <Image source={ProspectIcon} />
            </Row>
          );
        }}
        variant="secondary"
        items={[
          {
            value: row?.potential_customer?.employee_count?.number,
            backgroundColor: row?.potential_customer?.employee_count?.color,
            numberOfLines: 1,
          },
          {
            value: row?.potential_customer?.country?.name,
            backgroundColor: row?.potential_customer?.country?.color,
            numberOfLines: 1,
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  } else if (row?.customer_id) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.customer_id?.name}
        // icon={CustomerNew}
        variant="secondary"
        renderIcon={() => {
          return (
            <Row gap={6}>
              <Tag
                color={'SURFACE2'}
                textColor={'NEUTRAL_LOW'}
                value={row.customer_id?.lead_score}
              />
              <Image source={CustomerNew} />
            </Row>
          );
        }}
        items={[
          {
            value: row?.customer_id?.employee_count?.number,
            backgroundColor: row?.customer_id?.employee_count?.color,
            numberOfLines: 1,
            // onPress: () => {
            //   console.log(':::::::::::checking');
            // },
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
          {
            value: row?.customer_id?.country?.name,
            backgroundColor: row?.customer_id?.country?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  }
};

const SubStageRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  let newDate = new Date(row?.sub_stage_date).toLocaleTimeString('en-US');
  newDate = newDate.replace(/:\d{2}\s/, ' ');
  if (newDate != 'Invalid Date') {
    if (row?.sub_stage == 'New') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
            value={row?.sub_stage}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (row?.sub_stage == 'Submitted') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.SUCCESS_LOW}
            textColor={colors.SUCCESS_HIGH}
            value={row?.sub_stage}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (
      row?.sub_stage == 'Requirement gathering' ||
      row?.sub_stage == 'Business Requirement Document & Technical Assistance'
    ) {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT6_LOW}
            textColor={colors.ACCENT6_HIGH}
            value={'Req. Gathering'}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }

    if (row?.sub_stage == 'Scope Freezing and Estimation') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT6_LOW}
            textColor={colors.ACCENT6_HIGH}
            value={row?.sub_stage}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (row?.sub_stage == 'Proposal Drafting') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT2_LOW}
            textColor={colors.ACCENT2_HIGH}
            value={'Drafting'}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (row?.sub_stage == 'Sales Review') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT2_LOW}
            textColor={colors.ACCENT2_HIGH}
            value={'Sales Review'}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (row?.sub_stage == 'Proposal Walkthrough') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT2_LOW}
            textColor={colors.ACCENT2_HIGH}
            value={'Walkthrough'}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (row?.sub_stage == 'Re-Estimation') {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT2_LOW}
            textColor={colors.ACCENT2_HIGH}
            value={'Re-Estimation'}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
    if (
      row?.sub_stage == 'Negotiation' ||
      row?.sub_stage == 'Walkthrough done' ||
      row?.sub_stage == 'Revise Proposal' ||
      row?.sub_stage == 'Client Review'
    ) {
      return (
        <>
          <Chip
            // {...rest}
            color={colors.ACCENT4_LOW}
            textColor={colors.ACCENT4_HIGH}
            value={row?.sub_stage}
            borderRadius={6}
          />
          <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${moment(row.sub_stage_date).format('DD MMM, YY')}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                fontSize: 12,
                color: colors.NEUTRAL_MEDIUM,
                // overflow: 'hidden',
              }}>
              {`${newDate}`}
            </Text>
          </RowWithSeparator>
        </>
      );
    }
  } else {
    return null;
  }
};

const leadScoreRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.rfr_type == 'PotentailCustomer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.potential_customer?.lead_score}
        </Text>
      </>
    );
  } else if (row?.rfr_type == 'Customer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.customer_id?.lead_score}
        </Text>
      </>
    );
  }
};

const RenderConsultants = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log('row>>>>>>>', row);
  let {
    buisness_analyst = [],
    primary_owner = [],
    rfq_owner = [],
    tech_person = [],
  } = row;

  let finalValue = [];
  if (!Array.isArray(buisness_analyst)) {
    buisness_analyst = [buisness_analyst];
  }
  if (!Array.isArray(primary_owner)) {
    primary_owner = [primary_owner];
  }
  if (!Array.isArray(rfq_owner)) {
    rfq_owner = [rfq_owner];
  }
  if (!Array.isArray(tech_person)) {
    tech_person = [tech_person];
  }
  // buisness_analyst.forEach(data => {
  //   finalValue.push(data);
  // });
  // primary_owner.forEach(data => {
  //   finalValue.push(data);
  // });
  // tech_person.forEach(data => {
  //   finalValue.push(data);
  // });
  // rfq_owner.forEach(data => {
  //   finalValue.push(data);
  // });
  finalValue = [
    ...buisness_analyst,
    ...primary_owner,
    ...rfq_owner,
    ...tech_person,
  ];

  const uniqueData = finalValue.filter(
    (item, index, self) => index === self.findIndex(t => t?._id === item?._id),
  );
  console.log('>>>>>finalData', uniqueData);
  return <UserAvatarGroup value={uniqueData} />;
};

const EmployeeCountRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'potential_customer.employee_count.number',
          type: 'colorTag',
          titleField: 'potential_customer.employee_count.number',
          colorField: 'potential_customer.employee_count.color',
        },
        {
          field: 'customer_id.employee_count.number',
          titleField: 'customer_id.employee_count.number',
          colorField: 'customer_id.employee_count.color',
          type: 'colorTag',
        },
      ]}
    />
  );
};

const CountryRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'potential_customer.country.name',
          titleField: 'potential_customer.country.name',
          colorField: 'potential_customer.country.color',

          type: 'colorTag',
        },

        {
          titleField: 'customer_id.country.name',
          colorField: 'customer_id.country.color',
          field: 'customer_id.country.name',
          type: 'colorTag',
        },
      ]}
    />
  );
};

const CUSTOMER = {
  header: 'Organisation',
  render: CustomerRender,
  align: 'left',
  width: 300,
};

const CHANNEL = {
  header: 'Channel',
  field: 'channel.name',
  colorField: 'channel.color',
  type: 'colorTag',
  width: 150,
};

const COUNTRY = {
  header: 'Country',
  render: CountryRender,
  responsive: 'sm',
  width: 120,
};

const RequirementRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'requirement', type: 'colorTag'},
        // {
        //   field: 'due_date',
        //   type: 'date',
        // },
      ]}
    />
  );
};

export const CustomPlatformUnassignedMergedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  const {groupBy: _groupBy, ..._rest} = props;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'CustomPlatformOpportunity',
  });

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {..._rest}
      params={params}
      api="/opportunities/custom-platform/unassigned"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      searchFields="requirement"
      columns={[
        {
          header: 'Open Since',
          render: RenderCloseDate,
          field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},

        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          width: 110,
        },
        {
          header: 'Requirement',
          // field: 'requirement',
          render: RequirementRender,
          // type: 'text',
          width: 250,
        },

        // {
        //   render: CountCellRender,
        //   header: 'BC',
        //   align: 'right',
        //   width: 74,
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`assign-pre-sales-on-active`, {
        //       ...params,
        //       opportunity: row,
        //     });
        //   },
        // },

        // {
        //   render: CountCellRender,
        //   header: 'BA',
        //   align: 'right',
        //   width: 74,
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`assign-buisness-analyst-on-opportunity`, {
        //       ...params,
        //       opportunity: row,
        //     });
        //   },
        // },
        {
          render: CountCellRender,
          header: 'Consultants',
          align: 'right',
          width: 100,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(
              `assign-solution-consultants-on-unassigned-rfq`,
              {
                ...params,
                opportunity: row,
              },
            );
          },
        },
        // {
        //   header: 'Target Submission',
        //   type: 'date',
        //   // field: 'due_date',
        //   render: ({row}) => {
        //     const colors = useTheme('colors');
        //     if (row?.due_date && row?.due_date < row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.creation_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else if (row?.due_date && row?.due_date > row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.due_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else {
        //       return null;
        //     }
        //   },
        //   width: 160,
        // },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
          width: 180,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        // {
        //   title: 'Mark Inprogress',
        //   confirm: {
        //     title: 'Mark Inprogress',
        //     message:
        //       'Are you sure you want to mark this opportunity as inprogress?',
        //     confirmText: 'Confirm',
        //   },
        //   onPress: ({row}) => {
        //     invoke({
        //       uri: `/opportunities/${row?._id}`,
        //       props: {
        //         status: 'Active',
        //         sub_stage_date: new Date(),
        //         sub_stage: 'New',
        //       },
        //     });
        //   },
        //   visible: ({row}) => row?.rfq_owner && row.rfq_owner.length > 0,
        // },
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

export const CustomPlatformUnassignedMergedListSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'CustomPlatformOpportunity',
  });

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);

  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/custom-platform/unassigned"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      searchFields="requirement"
      columns={[
        {
          header: 'Open Since',
          render: RenderCloseDate,
          field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        CHANNEL,
        {
          header: 'Sales Owner',
          field: 'owner',
          type: 'userAvatar',
          width: 110,
        },
        // {
        //   header: 'Score',
        //   render: leadScoreRender,
        //   align: 'right',
        //   width: 68,
        // },
        {
          header: 'Requirement',
          field: 'requirement',
          type: 'text',
          width: 180,
        },

        // {
        //   render: CountCellRender,
        //   header: 'BC',
        //   align: 'right',
        //   width: 74,
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`assign-pre-sales-on-active`, {
        //       ...params,
        //       opportunity: row,
        //     });
        //   },
        // },

        // {
        //   render: CountCellRender,
        //   header: 'BA',
        //   align: 'right',
        //   width: 74,
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`assign-buisness-analyst-on-opportunity`, {
        //       ...params,
        //       opportunity: row,
        //     });
        //   },
        // },

        {
          render: CountCellRender,
          header: 'Consultants',
          align: 'right',
          width: 100,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(
              `assign-solution-consultants-on-unassigned-rfq`,
              {
                ...params,
                opportunity: row,
              },
            );
          },
        },
        // {
        //   header: 'Target Submission',
        //   type: 'date',
        //   // field: 'due_date',
        //   render: ({row}) => {
        //     const colors = useTheme('colors');
        //     if (row?.due_date && row?.due_date < row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.creation_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else if (row?.due_date && row?.due_date > row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.due_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else {
        //       return null;
        //     }
        //   },
        //   width: 160,
        // },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 180,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
          width: 180,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Mark Inprogress',
          confirm: {
            title: 'Mark Inprogress',
            message:
              'Are you sure you want to mark this opportunity as inprogress?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/opportunities/${row?._id}`,
              props: {
                status: 'Active',
                sub_stage_date: new Date(),
                sub_stage: 'New',
              },
            });
          },
          visible: ({row}) => row?.rfq_owner && row.rfq_owner.length > 0,
        },
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

export const CustomPlatformActiveMergedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  const {groupBy: _groupBy, ..._rest} = props;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    user: {employee},
  } = useAppStateContext();

  const {
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    assignTechPersonOnActive,
    updatePractice,
    addProjectionOnOpportunity,
    UpdatePreSales,
    UpdateOwner,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  return (
    <GroupTable
      {..._rest}
      params={params}
      limit={500}
      api="/opportunities/custom-platform/active"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      // groupRow={{
      //   data: 'children',
      //   defaultExpanded: true,
      //   leftContent: ({row}) => {
      //     return (
      //       <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
      //         <GroupContentItem value={row.name} />
      //       </RowWithSeparator>
      //     );
      //   },
      // }}
      selection={{
        actions: [
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
          {
            title: 'Update Technical Consultant',
            onPress: assignTechPersonOnActive,
          },
          {
            title: 'Update Pre Sales',
            onPress: UpdatePreSales,
          },
          {
            title: 'Update Owner',
            onPress: UpdateOwner,
            visible: () => employee?.name == 'Sushil Nagvan',
          },

          //   {title: 'Update Sub Stage', onPress: updateSubStage},
          {title: 'Update Practice', onPress: updatePractice},
        ],
      }}
      searchFields="requirement"
      columns={[
        {
          header: 'Open Since',
          render: RenderCloseDate,
          field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},

        CHANNEL,
        {
          header: 'Requirement',
          // field: 'requirement',
          render: RequirementRender,
          // type: 'text',
          width: 250,
        },
        {
          field: 'owner',
          width: 80,
          header: 'Sales',
          type: 'userAvatar',
        },
        {
          field: 'rfq_owner',
          width: 80,
          header: 'BC',
          type: 'userAvatarGroup',
        },
        {
          field: 'buisness_analyst',
          width: 100,
          header: 'BA',
          type: 'userAvatarGroup',
        },
        {
          field: 'primary_owner',
          width: 100,
          header: 'SC',
          type: 'userAvatar',
        },
        {
          field: 'tech_person',
          width: 100,
          header: 'TC',
          type: 'userAvatarGroup',
        },
        {
          header: 'Stage',
          render: SubStageRender,
          width: 160,
          // width: 180,
        },
        {
          header: 'Proposed Effort',
          field: 'proposal_hours_features',
          render: RenderProposedEfforts,
          aggregate: true,
          width: 130,
          // align: 'right',
          // width: 120,
        },

        // {
        //   header: 'Target Submission',
        //   type: 'date',
        //   // field: 'due_date',
        //   render: ({row}) => {
        //     const colors = useTheme('colors');
        //     if (row?.due_date && row?.due_date < row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.creation_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else if (row?.due_date && row?.due_date > row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.due_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else {
        //       return null;
        //     }
        //   },
        //   width: 160,
        // },

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
          width: 180,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Update Stage',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-combined-substage`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Timeline',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-timeline-report`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
        {
          title: 'Close Opportunity',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`closed-feature-opportunity`, {
              ...params,
              opportunity: row,
            });
          },
        },
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

export const CustomPlatformActiveMergedListSolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    user: {employee},
  } = useAppStateContext();

  const {
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    assignTechPersonOnActive,
    updatePractice,
    addProjectionOnOpportunity,
    UpdatePreSales,
    UpdateOwner,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/custom-platform/active"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      filter={{
        $or: [{primary_owner: employee?._id}, {tech_person: employee?._id}],
      }}
      selection={{
        actions: [
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
          {
            title: 'Update Technical Consultant',
            onPress: assignTechPersonOnActive,
          },
          {
            title: 'Update Pre Sales',
            onPress: UpdatePreSales,
          },
          {
            title: 'Update Owner',
            onPress: UpdateOwner,
            visible: () => employee?.name == 'Sushil Nagvan',
          },

          //   {title: 'Update Sub Stage', onPress: updateSubStage},
          {title: 'Update Practice', onPress: updatePractice},
        ],
      }}
      searchFields="requirement"
      columns={[
        {
          header: 'Open Since',
          render: RenderCloseDate,
          field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        {
          header: 'Score',
          render: leadScoreRender,
          align: 'right',
          width: 68,
        },
        CHANNEL,
        {
          header: 'Requirement',
          field: 'requirement',
          type: 'text',
          width: 180,
        },
        {
          field: 'owner',
          width: 100,
          header: 'Sales',
          type: 'userAvatar',
        },
        {
          field: 'rfq_owner',
          width: 100,
          header: 'BC',
          type: 'userAvatarGroup',
        },
        {
          field: 'buisness_analyst',
          width: 100,
          header: 'BA',
          type: 'userAvatarGroup',
        },
        {
          field: 'tech_person',
          width: 100,
          header: 'TC',
          type: 'userAvatarGroup',
        },

        {
          header: 'Proposed Effort',
          field: 'proposal_hours_features',
          render: RenderProposedEfforts,
          aggregate: true,
          width: 130,
          // align: 'right',
          // width: 120,
        },
        {
          header: 'Stage',
          render: SubStageRender,
          width: 160,
          // width: 180,
        },
        {
          header: 'Target Submission',
          type: 'date',
          // field: 'due_date',
          render: ({row}) => {
            const colors = useTheme('colors');
            if (row?.due_date && row?.due_date < row?.creation_date) {
              return (
                <Text
                  style={{
                    fontSize: 15,
                    color: colors.NEUTRAL_LOW,
                  }}>
                  {moment(row?.creation_date).format('DD MMM')}
                </Text>
              );
            } else if (row?.due_date && row?.due_date > row?.creation_date) {
              return (
                <Text
                  style={{
                    fontSize: 15,
                    color: colors.NEUTRAL_LOW,
                  }}>
                  {moment(row?.due_date).format('DD MMM')}
                </Text>
              );
            } else {
              return null;
            }
          },
          width: 160,
        },

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
          width: 180,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Stage',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-combined-substage`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
        {
          title: 'Close Opportunity',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`closed-feature-opportunity`, {
              ...params,
              opportunity: row,
            });
          },
        },
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

// export const CustomPlatformActiveMergedClosed = props => {
//   let {
//     route: {params},
//     filterParams,
//     navigation,
//   } = props;
//   const {period, ...rest} = params;
//   params = {
//     ...rest,
//   };
//   params = {...params, ...filterParams};

//   const {customPlatformOpportunityCommunications} =
//     customDigitalPlatformOppControllers(props);

//   const {updateProposalHours, addCustomerFromCustomPlatformOpportunity} =
//     leadControllers(props);
//   return (
//     <Table
//       {...props}
//       params={params}
//       limit={5000}
//       api="/opportunities/custom-platform/closed"
//       eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
//       onRowPress={row => {
//         navigation.navigate('edit-customplatformopportunity', row);
//       }}
//       searchFields="requirement"
//       columns={[
//         {
//           header: 'Open Since',
//           render: RenderCloseDateForclosed,
//           // field: 'days_difference',
//           align: 'center',
//           width: 112,
//         },
//         {...CUSTOMER},
//         {
//           header: 'Score',
//           render: leadScoreRender,
//           align: 'right',
//           width: 68,
//         },
//         CHANNEL,
//         {
//           header: 'Requirement',
//           field: 'requirement',
//           type: 'text',
//           width: 180,
//         },
//         {
//           field: 'owner',
//           width: 100,
//           header: 'Sales',
//           type: 'userAvatar',
//         },
//         {
//           field: 'rfq_owner',
//           width: 100,
//           header: 'BC',
//           type: 'userAvatarGroup',
//         },
//         {
//           field: 'buisness_analyst',
//           width: 100,
//           header: 'BA',
//           type: 'userAvatarGroup',
//         },
//         {
//           field: 'tech_person',
//           width: 100,
//           header: 'TC',
//           type: 'userAvatarGroup',
//         },
//         {
//           header: 'Proposed Effort',
//           field: 'proposal_hours_features',
//           render: RenderProposedEffortsForClosed,
//           aggregate: true,
//           align: 'center',
//           // width: 120,
//           width: 130,
//         },
//         {
//           header: 'Status',
//           render: RenderStatus,
//           width: 110,
//         },
//         {
//           header: 'Lost/Drop Reason',
//           type: 'colorTag',
//           field: 'outcome_description_comment',
//           // width: 150,
//           width: 180,
//         },
//         {
//           header: 'Closure TAT',
//           render: RenderTATDate,
//           field: 'days_difference',
//           aggregate: true,
//           // align: 'right',
//           width: 120,
//         },
//         {
//           header: 'Communication',
//           onPress: ({row}) => {
//             customPlatformOpportunityCommunications({row});
//           },
//           render: LastCommunicationRender,
//           width: 132,
//         },
//       ]}
//       moreActions={({row}) => [
//         objectTasksMoreAction({
//           navigation,
//           params,
//           titleField: 'requirement',
//           source: OPPORTUNITY_TYPE_ID,
//         }),
//         {
//           title: 'Update Proposal Hrs.',
//           onPress: updateProposalHours,
//           visible: ({row}) => row && row.status && row.status == 'Won',
//         },
//         {
//           title: 'Estimates',
//           onPress: props => {
//             const {row} = props;
//             navigation.navigate(`opportunity-quotation-rfq-feature`, {
//               ...params,
//               opportunity: row,
//               fromClosed: true,
//             });
//           },
//         },
//         {
//           title: 'Proposals',
//           onPress: props => {
//             const {row} = props;
//             navigation.navigate(`opportunity-proposal-rfq-feature`, {
//               ...params,
//               opportunity: row,
//               fromClosed: true,
//             });
//           },
//         },
//         ...(row.potential_customer?._id && row.status == 'Won'
//           ? [
//               {
//                 title: 'Make Customer',
//                 onPress: addCustomerFromCustomPlatformOpportunity,
//               },
//             ]
//           : []),
//         // {
//         //   title: 'Communications',
//         //   onPress: customPlatformOpportunityCommunications,
//         // },
//       ]}
//     />
//   );
// };

export const CustomPlatformActiveMergedClosed = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  const {groupBy: _groupBy, ..._rest} = props;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  if (groupBy?.addonGroupBy) {
    params = {...params, addonGroupBy: true};
  }

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);

  const {
    updateProposalHours,
    addCustomerFromCustomPlatformOpportunity,
    opportunityMarkActive,
  } = leadControllers(props);
  return (
    <GroupTable
      {..._rest}
      params={params}
      limit={5000}
      api="/opportunities/custom-platform/closed"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={
        groupBy?.groupRow
          ? groupBy.groupRow
          : {
              data: 'children',
              defaultExpanded: true,
              leftContent: ({row}) => {
                return (
                  <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                    <GroupContentItem value={row.key} />
                    <GroupContentItem value={`${row.count} closed`} />
                  </RowWithSeparator>
                );
              },
            }
      }
      allowDynamicGrouping={true}
      searchFields="requirement"
      columns={[
        {
          header: 'Created On',
          render: RenderCloseDateForclosed,
          // field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        CHANNEL,
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 68,
        },
        {
          header: 'Requirement',
          // field: 'requirement',
          render: RequirementRender,
          // type: 'text',
          width: 250,
        },
        {
          // field: 'owner',
          width: 120,
          header: 'Consultants',
          render: RenderConsultants,
          type: 'userAvatar',
        },
        // {
        //   field: 'rfq_owner',
        //   width: 80,
        //   header: 'BC',
        //   type: 'userAvatarGroup',
        // },
        // {
        //   field: 'buisness_analyst',
        //   width: 100,
        //   header: 'BA',
        //   type: 'userAvatarGroup',
        // },
        // {
        //   field: 'tech_person',
        //   width: 100,
        //   header: 'TC',
        //   type: 'userAvatarGroup',
        // },
        {
          header: 'Proposed Efforts',
          field: 'proposal_hours_features',
          render: RenderProposedEffortsForClosed,
          aggregate: true,
          align: 'center',
          // width: 120,
          width: 130,
        },
        {
          header: 'Status',
          render: RenderStatus,
          width: 110,
        },
        {
          header: 'Reason',
          type: 'colorTag',
          field: 'outcome_description_comment',
          // width: 150,
          width: 180,
        },
        // {
        //   header: 'Closed in',
        //   render: RenderTATDate,
        //   field: 'days_difference',
        //   aggregate: true,
        //   // align: 'right',
        //   width: 120,
        // },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={({row}) => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Proposal Hrs.',
          onPress: updateProposalHours,
          visible: ({row}) => row && row.status && row.status == 'Won',
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        {
          title: 'Mark Active',
          onPress: opportunityMarkActive,
          visible: ({row}) => row && row.status && row.status === 'invalidRfq',
        },

        ...(row.potential_customer?._id && row.status == 'Won'
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

export const CustomPlatformActiveMergedNew = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    groupBy,
  } = props;
  const {period, ...rest} = params;
  const {groupBy: _groupBy, ..._rest} = props;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  if (groupBy?.addonGroupBy) {
    params = {...params, addonGroupBy: true};
  }
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);

  const {updateProposalHours, addCustomerFromCustomPlatformOpportunity} =
    leadControllers(props);
  return (
    <GroupTable
      {..._rest}
      params={params}
      limit={5000}
      api="/opportunities/custom-platform/new"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={
        groupBy?.groupRow
          ? groupBy.groupRow
          : {
              data: 'children',
              defaultExpanded: true,
              leftContent: ({row}) => {
                return (
                  <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                    <GroupContentItem value={row.key} />
                    <GroupContentItem value={`${row.count} created`} />
                    <GroupContentItem value={`${row?.activeCount} Active`} />
                    <GroupContentItem value={`${row?.closedCount} Closed`} />
                  </RowWithSeparator>
                );
              },
            }
      }
      allowDynamicGrouping={true}
      searchFields="requirement"
      columns={[
        {
          header: 'Created On',
          render: RenderCloseDateForclosed,
          // field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},

        CHANNEL,
        {
          field: 'owner',
          width: 80,
          header: 'Sales',
          type: 'userAvatar',
        },
        {
          header: 'Requirement',
          // field: 'requirement',
          render: RequirementRender,
          // type: 'text',
          width: 250,
        },
        // {
        //   header: 'Target Submission',
        //   type: 'date',
        //   // field: 'due_date',
        //   render: ({row}) => {
        //     const colors = useTheme('colors');
        //     if (row?.due_date && row?.due_date < row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.creation_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else if (row?.due_date && row?.due_date > row?.creation_date) {
        //       return (
        //         <Text
        //           style={{
        //             fontSize: 15,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           {moment(row?.due_date).format('DD MMM')}
        //         </Text>
        //       );
        //     } else {
        //       return null;
        //     }
        //   },
        //   width: 160,
        // },
        {
          header: 'Status',
          render: RenderStatus,
          width: 110,
        },
        {
          header: 'Reason',
          type: 'colorTag',
          field: 'outcome_description_comment',
          // width: 150,
          width: 180,
        },

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={({row}) => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Proposal Hrs.',
          onPress: updateProposalHours,
          visible: ({row}) => row && row.status && row.status == 'Won',
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        ...(row.potential_customer?._id && row.status == 'Won'
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const CustomPlatformActiveMergedClosedsolCon = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);

  const {updateProposalHours, addCustomerFromCustomPlatformOpportunity} =
    leadControllers(props);
  return (
    <GroupTable
      {...props}
      params={params}
      limit={5000}
      api="/opportunities/custom-platform/closed"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} closed`} />
            </RowWithSeparator>
          );
        },
      }}
      searchFields="requirement"
      columns={[
        {
          header: 'Created On',
          render: RenderCloseDateForclosed,
          // field: 'days_difference',
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        // {
        //   header: 'Score',
        //   render: leadScoreRender,
        //   align: 'right',
        //   width: 68,
        // },
        CHANNEL,
        {
          field: 'owner',
          width: 100,
          header: 'Sales',
          type: 'userAvatar',
        },
        {
          header: 'Requirement',
          field: 'requirement',
          type: 'text',
          width: 180,
        },
        {
          render: CountCellRender,
          header: 'Consultants',
          align: 'right',
          width: 100,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(
              `assign-solution-consultants-on-unassigned-rfq`,
              {
                ...params,
                opportunity: row,
              },
            );
          },
        },
        // {
        //   field: 'rfq_owner',
        //   width: 100,
        //   header: 'BC',
        //   type: 'userAvatarGroup',
        // },
        // {
        //   field: 'buisness_analyst',
        //   width: 100,
        //   header: 'BA',
        //   type: 'userAvatarGroup',
        // },
        // {
        //   field: 'tech_person',
        //   width: 100,
        //   header: 'TC',
        //   type: 'userAvatarGroup',
        // },
        {
          header: 'Proposed Efforts',
          field: 'proposal_hours_features',
          render: RenderProposedEffortsForClosed,
          aggregate: true,
          align: 'center',
          // width: 120,
          width: 130,
        },
        {
          header: 'Status',
          render: RenderStatus,
          width: 110,
        },
        {
          header: 'Reason',
          type: 'colorTag',
          field: 'outcome_description_comment',
          // width: 150,
          width: 180,
        },
        // {
        //   header: 'Closure TAT',
        //   render: RenderTATDate,
        //   field: 'days_difference',
        //   aggregate: true,
        //   // align: 'right',
        //   width: 120,
        // },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={({row}) => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Proposal Hrs.',
          onPress: updateProposalHours,
          visible: ({row}) => row && row.status && row.status == 'Won',
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        ...(row.potential_customer?._id && row.status == 'Won'
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

export const CustomPlatformActiveMergedListForAnalytics = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    assignTechPersonOnActive,
    updatePractice,
    addProjectionOnOpportunity,
    UpdatePreSales,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/custom-platform/active-analytics"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      filter={{
        _id: {$in: params?.row},
      }}
      selection={{
        actions: [
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
          {
            title: 'Update Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
          {
            title: 'Update Pre Sales',
            onPress: UpdatePreSales,
          },
          //   {title: 'Update Sub Stage', onPress: updateSubStage},
          {title: 'Update Practice', onPress: updatePractice},
        ],
      }}
      searchFields="requirement"
      columns={[
        {...CUSTOMER, width: 250},
        {...COUNTRY, width: 150},
        {
          header: 'Lead Score',
          width: 90,
          render: leadScoreRender,
          align: 'right',
        },

        {
          field: 'owner',
          width: 70,
          header: 'Owner',
          type: 'userAvatar',
        },
        {
          field: 'rfq_owner',
          width: 120,
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'primary_owner',
          width: 70,
          header: 'Pr SC',
          type: 'userAvatar',
        },
        {
          field: 'tech_person',
          width: 120,
          header: 'SC',
          type: 'userAvatarGroup',
        },

        {
          header: 'Proposed Eff.',
          field: 'proposal_hours_features',
          render: RenderProposedEfforts,
          aggregate: true,
          // align: 'right',
          width: 120,
        },
        {
          header: 'Stage',
          render: SubStageRender,
          width: 180,
        },
        {
          header: 'Target Submission',
          type: 'date',
          field: 'due_date',
          width: 120,
        },
        {
          header: 'Open Since',
          render: RenderCloseDate,
          field: 'days_difference',
          aggregate: true,
          align: 'center',
          width: 110,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Stage',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`update-combined-substage`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
        {
          title: 'Close Opportunity',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`closed-feature-opportunity`, {
              ...params,
              opportunity: row,
            });
          },
        },
      ]}
    />
  );
};

export const CustomPlatformFeatureClosedForAnalytics = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {updateProposalHours} = leadControllers(props);
  return (
    <Table
      {...props}
      params={params}
      filter={{
        _id: {$in: params?.row},
      }}
      api="/opportunities/custom-platform/closed"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {...CUSTOMER, width: 250},
        {...COUNTRY, width: 150},
        {
          header: 'Lead Score',
          width: 90,
          render: leadScoreRender,
          align: 'right',
        },

        {
          field: 'owner',
          width: 70,
          header: 'Owner',
          type: 'userAvatar',
        },
        {
          field: 'rfq_owner',
          width: 100,
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'primary_owner',
          width: 70,
          header: 'Pr SC',
          type: 'userAvatar',
        },
        {
          field: 'tech_person',
          width: 120,
          header: 'SC',
          type: 'userAvatarGroup',
        },

        {
          header: 'Proposed Eff.',
          field: 'proposal_hours_features',
          render: RenderProposedEfforts,
          aggregate: true,
          align: 'center',
          width: 120,
        },
        {
          header: 'Stage',
          render: RenderStatus,
          // width: 100
        },
        {
          header: 'Lost Reason',
          type: 'colorTag',
          field: 'outcome_description_comment',
          // width: 150,
          width: 200,
        },
        {
          header: 'Closure TAT',
          render: RenderTATDate,
          field: 'days_difference',
          aggregate: true,
          align: 'right',
          width: 120,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Proposal Hrs.',
          onPress: updateProposalHours,
          visible: ({row}) => row && row.status && row.status == 'Won',
        },
        {
          title: 'Estimates',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-quotation-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
        {
          title: 'Proposals',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-proposal-rfq-feature`, {
              ...params,
              opportunity: row,
              fromClosed: true,
            });
          },
        },
      ]}
    />
  );
};
