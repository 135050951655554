import React, {useState} from 'react';
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {GroupByStyles} from './theme';
import {WithModal} from '@unthinkable/react-popper';
import {OutlineButton} from '../button/OutlineButton';
import {Button} from '../button/Button';
import {Badge} from '../badge/Badge';
import {IconButton} from '../button/IconButton';

const GroupByModal = ({
  hide,
  options,
  value,
  onChangeGroupValue,
  resetGroupBy,
}) => {
  const styles = useStyles(GroupByStyles);
  const [state, setState] = useState(value);
  const apply = () => {
    onChangeGroupValue(state);
    hide();
  };
  return (
    <>
      <Row style={styles.headerContainer}>
        <Image source={styles.groupByIcon} style={styles.headerIcon} />
        <Text style={styles.headerText}>Group by</Text>
        <TouchableOpacity
          onPress={() => {
            hide();
          }}>
          <Image source={styles.crossIcon} style={styles.headerIcon} />
        </TouchableOpacity>
      </Row>
      <ScrollView style={styles.bodyContainer}>
        {options.map((option, index) => (
          <TouchableOpacity
            key={index}
            style={{
              ...styles.itemContainer,
              ...(state?.field === option?.field
                ? styles.activeItemContainer
                : void 0),
            }}
            onPress={() => {
              setState(option);
            }}>
            <Text style={styles.itemLabel}>{option?.label}</Text>
            {state?.field === option?.field ? (
              <Image source={styles.tickIcon} />
            ) : (
              void 0
            )}
          </TouchableOpacity>
        ))}
      </ScrollView>
      <Row style={styles.footerContainer}>
        <OutlineButton
          disabled={!value}
          onPress={() => {
            resetGroupBy();
            hide();
          }}>
          Reset
        </OutlineButton>
        <Button onPress={apply}>Apply</Button>
      </Row>
    </>
  );
};

export const GroupBy = ({
  title = 'Group By',
  options,
  value,
  onChangeGroupValue,
  resetGroupBy,
}) => {
  const styles = useStyles(GroupByStyles);
  options = options?.filter(option => !!option);
  if (!options?.length) {
    return null;
  }
  return (
    <WithModal
      styles={styles?.modal}
      renderModal={({hide}) => {
        return (
          <GroupByModal
            options={options}
            value={value}
            onChangeGroupValue={onChangeGroupValue}
            resetGroupBy={resetGroupBy}
            hide={hide}
          />
        );
      }}>
      <Badge top={0} right={-5} value={value ? 1 : null}>
        <IconButton
          title={title}
          style={styles.icon}
          icon={styles.groupByIcon}
        />
      </Badge>
    </WithModal>
  );
};
