import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {CustomNodeComponentStyles} from './theme';
import {ReactFlowComponent} from './ReactFlowComponent';
import {useAccentColor} from '../../controllers/useAccentColor';

const GraphNode = ({
  item,
  onToggleCollapse,
  isCollapsed,
  onToggleFocus,
  isFocused,
  keyField,
  selectedElement,
  setHoveredElement,
  hoveredElement,
  nodeField,
  nodeId,
  hasChildren,
  collapsable,
  focusable,
  onNodePress,
  fromAll,
}) => {
  let text = item[nodeField];

  const isVirtual = item?.is_virtual;
  const isNodeSelected = item[keyField] === selectedElement;

  let color = 'Brand';

  const {UPPER_MEDIUM, MEDIUM, UPPER_LOW} = useAccentColor(color);
  const {NEUTRAL_LOW, OUTLINE} = useTheme('colors');

  let borderColor = MEDIUM;
  let selectedNodeBorderColor = UPPER_MEDIUM;
  let iconContainerColor = UPPER_LOW;

  if (isVirtual || (fromAll && !item?.in_development)) {
    borderColor = OUTLINE;
    selectedNodeBorderColor = NEUTRAL_LOW;
    iconContainerColor = UPPER_LOW;
  }

  const {
    AIIcon,
    ExpandGreenIcon,
    FocusGreenIcon,
    BlurGreenIcon,
    ExpandGreyIcon,
    FocusGreyIcon,
    BlurGreyIcon,
    nodeContainer,
    selectedNodeContainer,
    textStyle,
    iconContainerStyle,
    iconStyle,
    expandedIconStyle,
  } = useStyles(CustomNodeComponentStyles);

  const handleCollapseToggle = () => {
    onToggleCollapse(nodeId);
  };
  const handleFocusToggle = () => {
    onToggleFocus(nodeId);
  };

  return (
    <TouchableOpacity
      onMouseEnter={() => {
        setHoveredElement(nodeId);
      }}
      onMouseLeave={() => {
        setHoveredElement(void 0);
      }}
      style={
        isNodeSelected ||
        (isFocused && !selectedElement) ||
        hoveredElement === nodeId
          ? {
              ...nodeContainer,
              ...selectedNodeContainer,
              borderColor: selectedNodeBorderColor,
            }
          : {
              ...nodeContainer,
              borderColor,
            }
      }
      onPress={() => {
        onNodePress({item, readOnly: item?.is_virtual});
      }}>
      <Row gap={10} style={{alignItems: 'center'}}>
        {item?.aiGenerated ? (
          <Image style={iconStyle} source={AIIcon} />
        ) : (
          void 0
        )}
        <View title={text} style={{flex: 1, overflow: 'hidden'}}>
          <Text numberOfLines={1} style={textStyle}>
            {text}
          </Text>
        </View>
        {collapsable && hasChildren ? (
          <TouchableOpacity
            title={isCollapsed ? 'expand' : 'collapse'}
            onPress={handleCollapseToggle}
            style={{
              ...iconContainerStyle,
              backgroundColor: iconContainerColor,
            }}>
            <Image
              style={
                isCollapsed
                  ? {...iconStyle}
                  : {...iconStyle, ...expandedIconStyle}
              }
              source={isVirtual ? ExpandGreyIcon : ExpandGreenIcon}
            />
          </TouchableOpacity>
        ) : (
          void 0
        )}
        {focusable && hasChildren ? (
          <TouchableOpacity
            title={isFocused ? 'Unfocus' : 'focus'}
            onPress={handleFocusToggle}
            style={{
              ...iconContainerStyle,
              backgroundColor: iconContainerColor,
            }}>
            <Image
              style={iconStyle}
              source={
                isVirtual
                  ? isFocused
                    ? BlurGreyIcon
                    : FocusGreyIcon
                  : isFocused
                  ? BlurGreenIcon
                  : FocusGreenIcon
              }
            />
          </TouchableOpacity>
        ) : (
          void 0
        )}
      </Row>
    </TouchableOpacity>
  );
};

export const GraphView = props => {
  return (
    <ReactFlowComponent
      node={
        <GraphNode fromAll={props.fromAll} onNodePress={props.onNodePress} />
      }
      {...props}
    />
  );
};
