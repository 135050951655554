import React from 'react';
import {DashboardGroupTable, Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {ComparisonCard} from '../../../components/card/ComparisionCard';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {Col} from '@unthinkable/react-core-components';

const Aggregates = ({navigation, params, row}) => {
  let aggregates = [];
  const {revenue, directCost, salaryCost, product, departmentIds} = row || {};
  if (revenue) {
    aggregates.push(
      <ComparisonCard
        label="Revenue"
        value={revenue}
        compareValue={0}
        onPress={() => {
          navigation.navigate(`customers-table`, {
            ...params,
            product: product?._id,
          });
        }}
      />,
    );
  }
  if (salaryCost) {
    aggregates.push(
      <ComparisonCard
        label="Salary"
        value={salaryCost}
        compareValue={0}
        onPress={() => {
          navigation.navigate(`salary-list`, {
            ...params,
            product: product?._id,
            department: {$in: departmentIds},
          });
        }}
      />,
    );
  }
  if (directCost) {
    aggregates.push(
      <ComparisonCard
        label="Direct"
        value={directCost}
        compareValue={0}
        onPress={() => {
          navigation.navigate(`vendor-invoice-direct-expense-table`, {
            ...params,
            product: product?._id,
            department: {$in: departmentIds},
          });
        }}
      />,
    );
  }

  return aggregates;
};

const OverallCost = ({data}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Overall',
          responsive: 'sm',
        },
        {
          type: 'currency',
          field: 'revenue',
          header: 'Revenue',
          responsive: 'sm',
        },
        {
          type: 'currency',
          field: 'salaryCost',
          header: 'Salary',
        },
        {
          type: 'currency',
          field: 'directCost',
          header: 'Direct Expense',
        },
      ]}
    />
  );
};

const AllDepartments = props => {
  const {user} = useAppStateContext();
  const {navigation} = props;

  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });

  let {params = {}} = filterValues;

  const PeriodFilterAction = (
    <PeriodRangeFilter onChangeFilter={onChangeFilter} filterValues={filterValues} />
  );

  const invoiceDateFilter = params?.period
    ? {
        invoice_date: {
          $lte: params.period.to,
          $gte: params.period.from,
        },
      }
    : void 0;

  const onRowPress = ({row}) => {
    navigation.navigate(`department-dashboard`, {
      ...params,
      department: row,
      departmentId: row._id,
      product: row.product,
      role: row?.role,
    });
  };

  const onGroupRowPress = ({row}) => {
    navigation.navigate(`all-departments-dashboard`, {
      ...params,
      // department: row,
      // departmentId: row._id,
      product: row.product,
      role: row?.role,
    });
  };

  const onExpenseClick = ({row}) => {
    navigation.navigate(`show-expense-list`, {
      ...params,
      row,
      filter: invoiceDateFilter,
    });
  };

  const onDirectExpenseClick = ({row}) => {
    navigation.navigate(`show-direct-expense-list`, {
      ...params,
      row,
      filter: invoiceDateFilter,
    });
  };

  return (
    <DashboardGroupTable
      api={`/employees/${user.employee?._id}/accessDepartmentsOutput`}
      params={params}
      groupRow={{
        title: 'product.name',
        data: 'children',
        header: true,
        actions: props => Aggregates({...props, navigation, params}),
        onPress: onGroupRowPress,
      }}
      renderHeader={({aggregates}) => {
        return (
          <Col gap={16} style={{paddingBottom: 12}}>
            {PeriodFilterAction}
            <OverallCost data={[{name: 'All Departments', ...aggregates}]} />
          </Col>
        );
      }}
      defaultExpanded={true}
      onRowPress={onRowPress}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Departments',
          responsive: 'sm',
        },
        // {
        //   type: 'currency',
        //   field: 'output',
        //   header: 'Output',
        //   responsive: 'sm',
        // },
        {
          type: 'currency',
          field: 'salaryCost',
          header: 'Salary',
          onPress: onExpenseClick,
        },
        {
          type: 'currency',
          field: 'directCost',
          header: 'Direct Expense',
          onPress: onDirectExpenseClick,
        },
      ]}
    />
  );
};

export default AllDepartments;
