import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';

export const EmployeeTypeTable = props => {
    const {
        route: { params },
        navigation,
    } = props;
    return (
        <Table
            eventSourceId={['employeetype']}
            api={`/employeetypes`}
            renderHeader={() => {
                return (
                    <TableHeader
                        title="Employee Type"
                        actions={[
                            <AddButton
                                title="Employee Type"
                                view="add-employee-type"
                                params={params}
                            />,
                        ]}
                    />
                );
            }}
            onRowPress={props => {
                const {row} = props;
                navigation.navigate(`edit-employee-type`, {
                  row,
                });
              }}
            columns={[
                {
                    type: 'text',
                    header: 'Name',
                    field: 'name',
                }
            ]}
        />
    );
};
