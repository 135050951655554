import {
  getCurrentMonth,
  getPreviousMonth,
} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  PeriodRangeMonthWiseBreakupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {NewFixedOrderTable, NewTMOrderTable} from './NewOrderTable';
import {
  FixedOrderCloseTable,
  FixedOrderCloseTableAm,
  FixedOrderTable,
  FixedOrderTableAm,
  FixedOrderTablePM,
  TMOrderCloseTable,
  TMOrderCloseTableAm,
  TMOrderTable,
  TMOrderTableAm,
  TMOrderTablePM,
} from './OrderTable';

export const FixedOrderTab = props => {
  let {
    route: {params} = {},
    extraAddOnFilter = {},
    fromInternal = false,
  } = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: ['53a42e38a22d3a020035f672', '53a42e37a22d3a020035f657'],
      },
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }
  let tabs = {
    new_fixed_order: {
      label: 'New',
      view: (
        <NewFixedOrderTable
          searchValue={searchValue}
          addOnFilter={{
            order_status: {$in: ['Active']},
            ...extraAddOnFilter,
            ...filter,
          }}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          tmOrder={false}
        />
      ),
      api: `/fixedNewOrder`,
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        ...extraAddOnFilter,
        ...filter,
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },

    active_unassigned: {
      label: 'Active unassigned',
      api: `/fixed/order`,
      eventSourceId: ['fixed', 'performance-report'],
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        ...extraAddOnFilter,
        ...filter,
      },
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: true,
        ...params,
      },
      view: (
        <FixedOrderTable
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: true}}
          searchValue={searchValue}
          params={params}
          addOnFilter={extraAddOnFilter}
          fromInternal={fromInternal}
          showViewAssignedEnggineer={false}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add Fixed Order"
          view="add-fixed-order"
          params={{
            ...props?.route?.params,
            key: extraAddOnFilter,
            is_internal: fromInternal ? true : void 0,
          }}
        />,
      ],
    },

    active_assigned: {
      label: 'Active assigned',
      api: `/fixed/order`,
      eventSourceId: ['fixed', 'performance-report'],
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        ...extraAddOnFilter,
        ...filter,
      },
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: false,
        ...params,
      },
      view: (
        <FixedOrderTable
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: false}}
          searchValue={searchValue}
          params={params}
          addOnFilter={extraAddOnFilter}
          assignTab={true}
          fromInternal={fromInternal}
          showViewAssignedEnggineer={true}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add Fixed Order"
          view="add-fixed-order"
          params={{
            ...props?.route?.params,
            key: extraAddOnFilter,
            is_internal: fromInternal ? true : void 0,
          }}
        />,
      ],
    },

    close: {
      label: 'Closed',
      view: (
        <FixedOrderCloseTable
          filterValues={filterValues}
          searchValue={searchValue}
          params={params}
          addOnFilter={extraAddOnFilter}
          fromInternal={fromInternal}
          showViewAssignedEnggineer={true}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
      // api: '/t&m/order/close',
      // params: {...params, ...filterValues.params},
      // search: {searchValue},
      // searchFields: 'order_number',
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const FixedOrderTabAM = props => {
  const {
    route: {params} = {},
    addOnFilter,
    fromInternal = false,
    extraAddOnFilter = {},
  } = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }
  const {
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();

  let tabs = {
    fixed_new: {
      label: 'New',
      api: `/fixedNewOrder`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        order_status: {$in: ['Active']},
        ...extraAddOnFilter,
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewFixedOrderTable
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          addOnFilter={{
            ...filter,
            $or: [
              {account_owner_id: employeeId},
              {account_owner_id: {$in: employeeTeam}},
            ],
            order_status: {$in: ['Active']},
            ...extraAddOnFilter,
          }}
          tmOrder={false}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },

    unassinged: {
      label: 'Unassigned',
      api: `/fixed/order`,
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        ...extraAddOnFilter,
        ...filter,
      },
      params: {
        period: filterParams.period,
        fromActiveUnassigned: true,
        product,
        ...params,
      },
      view: (
        <FixedOrderTableAm
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: true}}
          searchValue={searchValue}
          params={params}
          addOnFilter={extraAddOnFilter}
          fromInternal={fromInternal}
          showViewAssignedEnggineer={false}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add Fixed Order"
          view="add-fixed-order"
          params={{...props?.route?.params, key: extraAddOnFilter}}
        />,
      ],
    },

    assigned: {
      label: 'Assigned',
      api: `/fixed/order`,
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        ...extraAddOnFilter,
        ...filter,
      },
      params: {
        period: filterParams.period,
        fromActiveUnassigned: false,
        product,
        ...params,
      },
      view: (
        <FixedOrderTableAm
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: false}}
          searchValue={searchValue}
          params={params}
          addOnFilter={extraAddOnFilter}
          assignTab={true}
          fromInternal={fromInternal}
          showViewAssignedEnggineer={true}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add Fixed Order"
          view="add-fixed-order"
          params={{...props?.route?.params, key: extraAddOnFilter}}
        />,
      ],
    },
    close: {
      label: 'Closed',
      view: (
        <FixedOrderCloseTableAm
          filterValues={filterValues}
          searchValue={searchValue}
          params={params}
          addOnFilter={extraAddOnFilter}
          fromInternal={fromInternal}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
      // api: '/t&m/order/close',
      // params: {...params, ...filterValues.params},
      // search: {searchValue},
      // searchFields: 'order_number',
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TMOrderTab = props => {
  const {route: {params} = {}} = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }

  let tabs = {
    t_and_m_new: {
      label: 'New',
      api: `/tmNewOrder`,
      limit: 5000,
      addOnFilter: {...filter, order_status: {$in: ['Active']}},
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewTMOrderTable
          addOnFilter={{...filter, order_status: {$in: ['Active']}}}
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          tmOrder={true}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },

    active_unassigned: {
      label: 'Active unassigned',
      view: (
        <TMOrderTable
          filter={{...filter}}
          filterParams={{...filterParams, fromActiveUnassigned: true}}
          searchValue={searchValue}
          params={params}
          showViewAssignedEnggineer={false}
        />
      ),
      api: `/t&m/order`,
      eventSourceId: ['performance-report'],
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: true,
        ...params,
      },
      addOnFilter: {
        ...filter,
        order_status: {$in: ['Active']},
        order_exists_type: {$in: ['New', 'Extension', 'Support']},
      },
      limit: 5000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              // asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add T&M Order"
          view="add-tm-order"
          params={props?.route?.params}
        />,
      ],
    },

    active_assigned: {
      label: 'Active assigned',
      view: (
        <TMOrderTable
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: false}}
          searchValue={searchValue}
          params={{...params}}
          assignTab={true}
          showViewAssignedEnggineer={true}
        />
      ),
      api: `/t&m/order`,
      eventSourceId: ['performance-report'],
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: false,
        ...params,
      },
      addOnFilter: {
        ...filter,
        order_status: {$in: ['Active']},
        order_exists_type: {$in: ['New', 'Extension', 'Support']},
      },
      limit: 5000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add T&M Order"
          view="add-tm-order"
          params={props?.route?.params}
        />,
      ],
    },

    close: {
      label: 'Closed',
      view: (
        <TMOrderCloseTable
          filterValues={filterValues}
          searchValue={searchValue}
          params={params}
          showViewAssignedEnggineer={false}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TMOrderTabAm = props => {
  const {route: {params} = {}} = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }
  const {
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();
  let tabs = {
    t_and_m_new: {
      label: 'New',
      api: `/tmNewOrder`,
      limit: 5000,
      addOnFilter: {
        ...filter,
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        order_status: {$in: ['Active']},
      },
      params: {
        ...params,
        period: filterParams.period,
      },
      view: (
        <NewTMOrderTable
          addOnFilter={{
            ...filter,
            $or: [
              {account_owner_id: employeeId},
              {account_owner_id: {$in: employeeTeam}},
            ],
            order_status: {$in: ['Active']},
          }}
          searchValue={searchValue}
          addOnParams={{
            ...params,
            period: filterParams.period,
          }}
          tmOrder={true}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },

    unassinged: {
      label: 'Unassigned',
      view: (
        <TMOrderTableAm
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: true}}
          searchValue={searchValue}
          params={params}
          showViewAssignedEnggineer={false}
        />
      ),
      api: `/t&m/order`,
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: true,
        ...params,
      },
      addOnFilter: {
        ...filter,
        order_status: {$in: ['Active']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        // order_exists_type: {$in: ['New', 'Extension']},
      },
      limit: 5000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add T&M Order"
          view="add-tm-order"
          params={props?.route?.params}
        />,
      ],
    },

    assigned: {
      label: 'Assigned',
      view: (
        <TMOrderTableAm
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: false}}
          searchValue={searchValue}
          params={params}
          showViewAssignedEnggineer={true}
        />
      ),
      api: `/t&m/order`,
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: false,
        ...params,
      },
      addOnFilter: {
        ...filter,
        order_status: {$in: ['Active']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        // order_exists_type: {$in: ['New', 'Extension']},
      },
      limit: 5000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
        <AddButton
          title="Add T&M Order"
          view="add-tm-order"
          params={props?.route?.params}
        />,
      ],
    },

    close: {
      label: 'Closed',
      view: (
        <TMOrderCloseTableAm
          filterValues={filterValues}
          searchValue={searchValue}
          params={params}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const FixedOrderTabForRM = props => {
  const {route: {params} = {}, addOnFilter} = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();

  let tabs = {
    unassinged: {
      label: 'Unassigned',
      api: `/fixed/order`,
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        ...addOnFilter,
      },
      params: {
        period: filterParams.period,
        fromActiveUnassigned: true,
        product,
        ...params,
      },
      view: (
        <FixedOrderTablePM
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: true}}
          searchValue={searchValue}
          params={params}
          addOnFilter={addOnFilter}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },

    assigned: {
      label: 'Assigned',
      api: `/fixed/order`,
      limit: 5000,
      addOnFilter: {
        order_status: {$in: ['Active']},
        ...addOnFilter,
      },
      params: {
        period: filterParams.period,
        fromActiveUnassigned: false,
        product,
        ...params,
      },
      view: (
        <FixedOrderTablePM
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: false}}
          searchValue={searchValue}
          params={params}
          addOnFilter={addOnFilter}
          assignTab={true}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TMOrderTabRM = props => {
  const {route: {params} = {}} = props;
  const {product} = params;
  let {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const mergePeriod = {...filterParams.period, ...filterParams.order_date};
  filterParams = {period: mergePeriod};
  if (filter && filter.customer) {
    let {customer, ...rest} = filter;
    filter = {...rest, customer_id: filter.customer._id};
  }
  const {
    user: {employee},
  } = useAppStateContext();
  let tabs = {
    unassinged: {
      label: 'Unassigned',
      view: (
        <TMOrderTablePM
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: true}}
          searchValue={searchValue}
          params={params}
        />
      ),
      api: `/t&m/order`,
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: true,
        ...params,
      },
      addOnFilter: {
        ...filter,
        order_status: {$in: ['Active']},
      },
      limit: 5000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              suggestionField: 'name',
              placeholder: 'Select',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },

    assigned: {
      label: 'Assigned',
      view: (
        <TMOrderTablePM
          filter={filter}
          filterParams={{...filterParams, fromActiveUnassigned: false}}
          searchValue={searchValue}
          params={params}
        />
      ),
      api: `/t&m/order`,
      params: {
        period: filterParams.period,
        product,
        fromActiveUnassigned: false,
        ...params,
      },
      addOnFilter: {
        ...filter,
        order_status: {$in: ['Active']},
      },
      limit: 5000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Order Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Account Owner',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'date',
              field: 'order_date',
              asParam: true,
              label: 'Order date',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
