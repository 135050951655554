import React from 'react';

import {TabView} from '../../../components/tab/TabView';
import { OutreachTargetAudienceTable } from './OutreachTargetAudienceTable';


export const OutreachTargetAudienceTabs = props => {
  const tabs = {
    in_experiment: {
      label: 'In Experiment',
      view: <OutreachTargetAudienceTable tabFilter={{status: 'In Experiment'}} />,
    },
    confirmed: {
      label: 'Confirmed',
      view: <OutreachTargetAudienceTable tabFilter={{status: 'Confirmed'}} />,
    },

    retired: {
      label: 'Retired',
      view: <OutreachTargetAudienceTable tabFilter={{status: 'Retired'}} />,
    },
    failed: {
      label: 'Failed',
      view: <OutreachTargetAudienceTable tabFilter={{status: 'Failed'}} />,
    },
  };
  return <TabView {...props} tabs={tabs} />;
};
