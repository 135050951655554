import {getNodesBounds, getViewportForBounds} from 'reactflow';
import {toPng} from 'html-to-image';

export const exportImageToPNG = ({
  getNodes,
  imageHeight = 768,
  imageWidth = 1024,
  fileName = 'Untitled.png',
}) => {
  // we calculate a transform for the nodes so that all nodes are visible
  // we then overwrite the transform of the `.react-flow__viewport` element
  // with the style option of the html-to-image library
  const nodesBounds = getNodesBounds(getNodes());
  const viewport = getViewportForBounds(
    nodesBounds,
    imageWidth,
    imageHeight,
    0.5,
    2,
  );

  toPng(document.querySelector('.react-flow__viewport'), {
    backgroundColor: '#FFF',
    width: imageWidth,
    height: imageHeight,
    style: {
      width: imageWidth,
      height: imageHeight,
      transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
    },
  }).then(dataUrl => {
    const a = document.createElement('a');

    a.setAttribute('download', fileName);
    a.setAttribute('href', dataUrl);
    a.click();
  });
};
