import React from 'react';

import {Table} from '../../../components/table/Table';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useTheme} from '@unthinkable/react-theme';
import {useProjectActions} from '../actions/ProjectActions';
import {Tag} from '../../../components/chip/Chip';
import {randomColor} from '../../../utils/util';
import {useInvoke} from '../../../controllers/useInvoke';
import {IconWithBackground} from '../../../components/icons/IconWithBackground';

const renderProject = () => {
  return {
    header: 'Project',
    minWidth: 260,
    render: ({row}) => {
      let icon;
      let color = 'BRAND_UPPER_LOW';
      if (row.tool === 'Locomo') {
        icon = 'LocomoTool';
      } else if (row.tool === 'Jira') {
        icon = 'JiraIcon';
        color = 'SECONDARY_MEDIUM';
      } else {
        icon = 'OtherTool';
      }
      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <IconWithBackground icon={icon} color={color} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project}
            items={[
              {
                value: row?.code,
              },
            ]}
          />
        </Row>
      );
    },
  };
};

const OWNER = {
  header: 'PM',
  field: 'owner_id',
  align: 'center',
  type: 'userAvatarChip',
};

const editProject = ({row, navigation}) => {
  navigation.navigate(`client-edit-project`, {
    project: {_id: row?._id},
  });
};

const markProjectActive = ({invoke}) => {
  return {
    title: 'Mark Active',
    confirm: {
      title: 'Mark Project Active',
      message: 'Are you sure you want to mark this project active?',
      confirmText: 'Mark Active',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/projects/${row?._id}`,
        props: {
          project_status: 'a',
        },
      });
    },
  };
};

const markProjectCompleted = ({invoke}) => {
  return {
    title: 'Mark Complete',
    confirm: {
      title: 'Mark Project Complete',
      message: 'Are you sure you want to mark this project complete?',
      confirmText: 'Mark Complete',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/projects/${row?._id}`,
        props: {
          project_status: 'c',
        },
      });
    },
  };
};

const ALLOCATION = {
  header: 'Allocation',
  field: 'allocations',
  type: 'number',
};

export const ClientAllProjectTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
    selectedTab,
    addOnFilter = {},
  } = props;
  const {projectDetail} = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ClientProject'],
  });
  return (
    <Table
      params={{...params, fromAllProjects: true}}
      // {...props}
      addOnFilter={addOnFilter}
      searchFields={'project'}
      onRowPress={projectDetail}
      eventSourceId={['ClientProject']}
      api={`/clientProjects`}
      columns={[
        renderProject({projectDetail}),
        OWNER,
        selectedTab === 'active' && ALLOCATION,
      ]}
      moreActions={[
        {
          title: 'Edit Project',
          onPress: ({row}) => editProject({row, navigation}),
        },
        selectedTab === 'active' && {
          title: 'Mark AI Enabled',
          confirm: {
            title: 'AI Enabled',
            message: 'Are you sure you want to mark ai enabled this project?',
            confirmText: 'AI Enabled',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/projects/${row?._id}`,
              props: {
                ai_enabled: true,
              },
            });
          },
          visible: ({row}) => !row.ai_enabled,
        },
        selectedTab === 'active' && {
          title: 'Mark AI Disabled',
          confirm: {
            title: 'AI Disabled',
            message: 'Are you sure you want to mark ai disabled this project?',
            confirmText: 'AI Disabled',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/projects/${row?._id}`,
              props: {
                ai_enabled: false,
              },
            });
          },
          visible: ({row}) => row.ai_enabled,
        },
        selectedTab === 'completed' && markProjectActive({invoke}),
        selectedTab === 'active' && markProjectCompleted({invoke}),
      ]}
    />
  );
};

export const ClientActiveProjectTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;
  const {projectDetail} = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ClientProject'],
    throwError: true,
  });
  return (
    <Table
      {...props}
      search={searchValue}
      searchFields={'project'}
      onRowPress={projectDetail}
      eventSourceId={['ClientProject']}
      api={`/clientProjects`}
      columns={[renderProject({projectDetail}), OWNER, ALLOCATION]}
      moreActions={[
        {
          title: 'Edit Project',
          onPress: ({row}) => editProject({row, navigation}),
        },
        markProjectCompleted({invoke}),
      ]}
    />
  );
};

export const ClientCompletedProjectTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;
  const {projectDetail} = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ClientProject'],
    throwError: true,
  });
  return (
    <Table
      {...props}
      search={searchValue}
      searchFields={'project'}
      onRowPress={projectDetail}
      eventSourceId={['ClientProject']}
      api={`/clientProjects`}
      columns={[renderProject({projectDetail}), OWNER]}
      moreActions={[markProjectActive({invoke})]}
    />
  );
};
