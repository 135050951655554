import moment from 'moment';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {Text} from '@unthinkable/react-core-components';

const EmailStatsList = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  const {count} = row || {};

  const date = row?.completedOn
    ? moment.utc(row?.completedOn).format('D MMM')
    : null;

  return (
    <Table
      api={`/emailprofiles/emailcount`}
      renderHeader={() => (
        <TableHeader title={`Email Stats (${date ? date : ''})`} />
      )}
      params={params}
      columns={[
        {
          header: 'Sent',
          render: ({styles}) => {
            return <Text style={{...styles.rowText}}>{count}</Text>;
          },
        },
        {
          field: 'email_delivered_on_count',
          header: 'Delievered',
          type: 'number',
        },
        {
          field: 'email_opened_on_count',
          header: 'Open',
          type: 'number',
        },
        {
          field: 'email_bounce_on_count',
          header: 'Bounce',
          type: 'number',
        },
        {
          field: 'email_unsubscribed_on_count',
          header: 'Unsubscribe',
          type: 'number',
        },
      ]}
    />
  );
};

export default EmailStatsList;
