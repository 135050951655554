import React, {useState} from 'react';
import {useFilter} from '../../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {TableHeader} from '../../../../components/table/Headers';
import {SplitScreen} from '../../../../components/SplitScreen';
import {ScreenDetailForm} from './ScreenForm';
import {AssetColumns, ToggleGraphView, assetFilters} from './AssetUtility';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {useFetchData} from '../../../../controllers/useFetchData';
import {PROJECT_VIEW_TYPE_ID} from '../../../common/constants/SourceConstants';
import {AllScreen} from './FeatureScreens';
import {TabView} from '../../../../components/tab';
import {ScreenGraphView} from '../../../../components/graphflow/ScreenGraphView';
import {AITable} from '../../../../components/table/AITable';
import {useTheme} from '@unthinkable/react-theme';
import {AISuggestionRender} from '../../cellrenders/AISuggestionRenders';
import {Col, Text, View} from '@unthinkable/react-core-components';
import {GraphHeader} from '../../headers/Header';
import {
  GraphExportActions,
  GraphMoreActions,
} from '../../../../app-components/renders/GraphActions';
import {GroupBy} from '../../../../components/table/GroupBy';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {Table} from '../../../../components/table/Table';

const ScreenHeader = ({
  params,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  selectedIds,
  excelParams,
  groupBy,
}) => {
  const {project, module_id} = params;
  const filters = assetFilters({project});
  return (
    <TableHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            !module_id && filters.module,
            filters.dataEntity,
            filters.owner,
            filters.design_owner,
          ]}
        />,
        !module_id && <GroupBy {...groupBy} />,
        <ExportExcelAction selectedIds={selectedIds} params={excelParams} />,
      ]}
    />
  );
};

const ScreenGraphHeader = ({
  params,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  ...props
}) => {
  const {project, module_id} = params;
  const filters = assetFilters({project});

  return (
    <GraphHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            !module_id && filters.module,
            filters.owner,
            filters.design_owner,
          ]}
        />,
        <GraphExportActions fileName={'Screens'} {...props} />,
        <GraphMoreActions {...props} />,
      ]}
    />
  );
};

export const screenExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';

  return {
    addOnFilter,
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: 'Screens',
      columns: [
        {header: 'Name', key: 'view', width: 15},
        {header: 'Module', key: 'module_id.module', width: 15},
        {header: 'Model', key: 'model_id.model', width: 15},
        {
          header: 'Mobile Attachments',
          key: 'mobile_attachments',
          isImage: true,
          width: 25,
        },
        {header: 'Mobile Link', key: 'mobile_link', width: 25, link: true},
        {
          header: 'Desktop Attachment',
          key: 'desktop_attachments',
          isImage: true,
          width: 25,
        },
        {header: 'Desktop Link', key: 'desktop_link', width: 25, link: true},
        {
          header: 'Tab Attachment',
          key: 'tab_attachments',
          isImage: true,
          width: 25,
        },
        {header: 'Tab Link', key: 'tab_link', width: 25, link: true},
        {
          header: 'Components',
          key: 'components',
          objectField: 'component',
          width: 15,
        },
        {
          header: 'Linked to Screens',
          key: 'linked_to_screens',
          objectField: 'view',
          width: 15,
        },
        {header: 'Owner', key: 'owner_id.name', width: 15},
        {header: 'Design Owner', key: 'design_owner_id.name', width: 15},
        {header: 'Description', key: 'desc', width: 20},
        {header: 'Remarks', key: remarksKey, width: 50, isHtml: true},
      ],
    },
  };
};

const EVENT_SOURCE_ID = ['View', 'ProjectAI', 'MapUserstory'];

export const ScreenTable = props => {
  const {
    navigation,
    route: {params},
    selectedId,
    status,
  } = props;
  const {groupBy, ...restProps} = props;

  const {
    assetDetail,
    mergeReferences,
    changeModule,
    changeDataEntity,
    deleteAsset,
    deleteMultipleAssets,
    versions,
    updateModuleMultipleAssets,
    updateDataEntityMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_VIEW_TYPE_ID,
    fromReleased: status === 'released',
  });

  const {colors, fonts} = useTheme();

  const assetColumns = AssetColumns({
    navigation,
    params,
    api: '/projectViews',
    source: PROJECT_VIEW_TYPE_ID,
  });
  const {feature, module} = params;

  const {allowDynamicGrouping} = groupBy || {};

  let TableComponent = Table;
  if (module) {
    TableComponent = AITable;
  }

  const aiEnabled = module?._id && !selectedId;

  return (
    <TableComponent
      {...restProps}
      allowDynamicGrouping={allowDynamicGrouping}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      selection={{
        actions: [
          updateModuleMultipleAssets,
          updateDataEntityMultipleAssets,
          deleteMultipleAssets,
        ],
      }}
      eventSourceId={EVENT_SOURCE_ID}
      columns={[
        !allowDynamicGrouping && !selectedId && assetColumns.module,
        {
          header: 'Name',
          render: _props => {
            const {row, styles} = _props;
            let primaryTitleColor = void 0;
            if (feature?._id && !row.in_development) {
              primaryTitleColor = colors.NEUTRAL_MEDIUM;
            }
            const linked_to_screens = row?.linked_to_screens?.join(', ');
            const components = row?.components
              ?.map(item => item?.component)
              ?.join(', ');
            return (
              <Col gap={4}>
                <AISuggestionRender
                  {..._props}
                  params={params}
                  primaryTitleColor={primaryTitleColor}
                  secondaryField="desc"
                  numberOfLines={3}
                  primaryField="view"
                  entityName="ProjectView"
                  eventSourceId="ProjectAI"
                />
                {row?.aiGenerated ? (
                  <>
                    {linked_to_screens ? (
                      <Text style={styles?.rowText}>
                        Linked To :
                        <Text
                          as="span"
                          style={{
                            color: colors.NEUTRAL_MEDIUM,
                          }}>
                          {` ${linked_to_screens}`}
                        </Text>
                      </Text>
                    ) : (
                      void 0
                    )}
                    {components ? (
                      <Text style={styles?.rowText}>
                        Components :
                        <Text
                          as="span"
                          style={{
                            color: colors.NEUTRAL_MEDIUM,
                          }}>
                          {` ${components}`}
                        </Text>
                      </Text>
                    ) : (
                      void 0
                    )}
                    {row?.folder_path_id ? (
                      <Text style={styles?.rowText}>
                        Folder Path :
                        <Text
                          as="span"
                          style={{
                            color: colors.NEUTRAL_MEDIUM,
                          }}>
                          {` ${row.folder_path_id}`}
                        </Text>
                      </Text>
                    ) : (
                      void 0
                    )}
                    {row?.file_name ? (
                      <Text style={styles?.rowText}>
                        File Name :
                        <Text
                          as="span"
                          style={{
                            color: colors.NEUTRAL_MEDIUM,
                          }}>
                          {` ${row.file_name}`}
                        </Text>
                      </Text>
                    ) : (
                      void 0
                    )}
                    {row?.user_story_ids?.length ? (
                      <View
                        style={{
                          paddingTop: 6,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          backgroundColor: colors?.SURFACE3,
                          borderRadius: 12,
                        }}>
                        <Text
                          style={{
                            ...fonts.CAPTION_SMALL,
                            color: colors.NEUTRAL_LOW,
                            whiteSpace: 'pre-wrap',
                          }}>
                          {'User Stories:\n'}
                          <Text style={{color: colors.NEUTRAL_MEDIUM}}>
                            {row.user_story_ids.map((item, index) => {
                              return `${index + 1}. ${item?.userstory}\n`;
                            })}
                          </Text>
                        </Text>
                      </View>
                    ) : (
                      void 0
                    )}
                  </>
                ) : (
                  void 0
                )}
              </Col>
            );
          },
          colspan: ({row}) => (row.aiGenerated ? 10 : void 0),
        },
        !selectedId && assetColumns.modelView,
        !selectedId && assetColumns.api,
        !selectedId && assetColumns.userstory_count,
        assetColumns.linked_to,
        assetColumns.designs,
        !selectedId && assetColumns.owner,
        !selectedId && assetColumns.designOwner,
        !selectedId && assetColumns.code_editor,
      ]}
      onRowPress={assetDetail}
      moreActions={[
        versions,
        changeModule,
        changeDataEntity,
        mergeReferences({
          targetApi: `/projectviews`,
        }),
        {
          title: 'Generate Code',
          visible: ({row}) => row?.folder_path_id && row?.file_name,
          onPress: ({row}) => {
            navigation.navigate('screen-code-editor', {
              ...params,
              row,
              readOnly: !feature || feature.status === 'released',
            });
          },
        },
        deleteAsset,
      ]}
    />
  );
};

const ProjectScreenGraph = ({renderHeader, ...props}) => {
  const {
    navigation,
    route: {params},
  } = props;

  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_VIEW_TYPE_ID,
  });

  return (
    <>
      <ScreenGraphView
        renderHeader={renderHeader?.(props)}
        onNodePress={({item}) => {
          assetDetail({row: item});
        }}
        {...props}
      />
    </>
  );
};

export const ProjectScreensScreen = props => {
  const {
    route: {params},
    isGraphView,
    groupBy,
  } = props;

  const filterProps = useFilter();
  const {searchValue, filterValues} = filterProps;

  const {project, module_id} = params;

  const tableProps = {
    api: `/projectviews/released`,
    addOnFilter: {
      project_id: project?._id,
      module_id,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'view',
    eventSourceId: EVENT_SOURCE_ID,
  };

  const fetchDataProps = useFetchData({
    ...tableProps,
    limit: 10000,
  });

  const renderHeader = props => {
    if (isGraphView) {
      return (
        <ScreenGraphHeader
          {...props}
          {...filterProps}
          params={params}
          excelParams={screenExportParams(tableProps)}
        />
      );
    }
    return (
      <ScreenHeader
        {...props}
        params={params}
        groupBy={groupBy}
        {...filterProps}
        excelParams={screenExportParams(tableProps)}
      />
    );
  };

  const screenProps = isGraphView ? fetchDataProps : tableProps;

  const ScreenView = isGraphView ? ProjectScreenGraph : ScreenTable;
  return (
    <SplitScreen splitScreen={<ScreenDetailForm {...props} />}>
      <ScreenView
        {...props}
        {...screenProps}
        groupBy={groupBy}
        renderHeader={renderHeader}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const ScreensTab = props => {
  const {route: {params} = {}} = props;
  const {project, module} = params;
  const [isGraphView, setGraphView] = useState();

  let groupBy = useGroupBy({
    defaultSelected: module?._id ? void 0 : 'module_id',
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          exclusiveExpand: true,
          defaultExpandedIndex: 0,
          leftContent: ({row}) => {
            const moduleName = row?.module || 'No module';
            return <GroupContentItem value={moduleName + ` (${row.count})`} />;
          },
        },
      },
    ],
  });

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: (
            <ProjectScreensScreen
              isGraphView={isGraphView}
              groupBy={groupBy}
              {...props}
            />
          ),
          api: '/projectviews',
          filter: {
            status: 'released',
            project_id: project?._id,
            module_id: module?._id,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllScreen
              api={`/projects/${project?._id}/asset/${PROJECT_VIEW_TYPE_ID}/all-data`}
              isGraphView={isGraphView}
              groupBy={groupBy}
              {...props}
            />
          ),
          params: {module_id: module?._id},
          api: `/projects/${project?._id}/asset/${PROJECT_VIEW_TYPE_ID}/in-development/count`,
        },
      }}
      actions={[
        <ToggleGraphView
          isGraphView={isGraphView}
          setGraphView={setGraphView}
        />,
      ]}
    />
  );
};
