import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const PackageStructure = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/packagestructures',
        eventSourceId: 'packageStructure',
    });
    return (
        <Form
            api={`/packagestructure/${row?._id}`}
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    type: "text",
                    placeholder: "Name",
                    field: "name",
                    label: "Package Structure Name",
                    required: true,
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Stipend",
                    field: "stipend",
                    label: "Stipend",
                    required: true,
                    size: 6
                },
                {
                    type: "textArea",
                    placeholder: "Salary Stipend Description",
                    field: "salary_stipend_description",
                    label: "Package Structure Description",
                    required: true,
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Yearly Incentive",
                    field: "yearly_incentive",
                    label: "Yearly Incentive",
                    size: 6
                },
                {
                    type: "textArea",
                    placeholder: "Yearly Incentive Duration",
                    field: "yearly_incentive_duration",
                    label: "Yearly Incentive Duration",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Monthly CTC (0-6Months)",
                    field: "monthly_ctc_six_months",
                    label: "Monthly CTC (0-6Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Monthly CTC (7-12Months)",
                    field: "monthly_ctc_seven_months",
                    label: "Monthly CTC (7-12Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Monthly CTC (13-18Months)",
                    field: "monthly_ctc_thirteen_months",
                    label: "Monthly CTC (13-18Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Monthly CTC (19-30Months)",
                    field: "montly_ctc_ninteen_months",
                    label: "Monthly CTC (19-30Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: 'Expected In Hand Salary(pre-Tds)(0-6Months)',
                    field: "pre_tds_six_months",
                    label: 'Expected In Hand Salary(pre-Tds)(0-6Months)',
                    size: 6
                },
                {
                    type: "number",
                    placeholder: 'Expected In Hand Salary(pre-Tds)(7-12Months)',
                    field: "pre_tds_seven_months",
                    label: "Expected In Hand Salary(pre-Tds)(7-12Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: 'Expected In Hand Salary(pre-Tds)(13-18Months)',
                    field: "pre_tds_thirteen_months",
                    label: "Expected In Hand Salary(pre-Tds)(13-18Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: 'Expected In Hand Salary(pre-Tds)(19-30Months)',
                    field: "pre_tds_ninteen_months",
                    label: "Expected In Hand Salary(pre-Tds)(19-30Months)",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Total Annual CTC",
                    field: "total_annual_ctc",
                    label: "Total Annual CTC",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Yearly CTC First",
                    field: "yearly_ctc_first",
                    label: "Yearly CTC First",
                    size: 6
                },
                {
                    type: "number",
                    placeholder: "Yearly CTC Second",
                    field: "yearly_ctc_second",
                    label: "Yearly CTC Second",
                    size: 6
                },
                {
                    label: 'Performance',
                    fields: [
                        {
                            field: 'details',
                            nested: true,
                            render: props => (
                                <NestedTable
                                    {...props}
                                    selection={{
                                        deleteAction: true,
                                    }}
                                    columns={[
                                        {
                                            type: "text",
                                            placeholder: "Duration",
                                            field: "duration",
                                            label: "Duration",
                                            required: true
                                        },
                                        {
                                            innerNested: true,
                                            header: 'Package Details',
                                            label: 'Package Details',
                                            fields: [
                                                {
                                                    type: 'text',
                                                    field: 'package_details',
                                                    nested: true,
                                                    render: props => {
                                                        return (
                                                            <NestedTable
                                                                {...props}
                                                                columns={[
                                                                    {
                                                                        field: 'salary_component.name',
                                                                        header: 'Salary Component',
                                                                    },
                                                                    {
                                                                        type: "number",
                                                                        placeholder: "Amount",
                                                                        field: "amount",
                                                                        header: "amount",
                                                                    }
                                                                ]}
                                                                fields={[
                                                                    {
                                                                        label: 'Salary Component',
                                                                        field: 'salary_component',
                                                                        type: 'autoComplete',
                                                                        api: '/salarycomponents',
                                                                        suggestionField: 'name',
                                                                        valueField: 'name',
                                                                    },
                                                                    {
                                                                        type: "number",
                                                                        placeholder: "Amount",
                                                                        field: "amount",
                                                                        header: "amount",
                                                                    }

                                                                ]}
                                                            />
                                                        );
                                                    },
                                                },
                                            ],
                                            width: 150,
                                        },
                                    ]}
                                    fields={[
                                        {
                                            type: "text",
                                            placeholder: "Duration",
                                            field: "duration",
                                            label: "Duration",
                                            required: true
                                        },
                                    ]}
                                />
                            ),
                        },
                    ],
                },
            ]}
            {...props}
        />
    );
};

export const AddPackageStructure = props => {
    return <PackageStructure header="Add Structure" {...props} />;
};

export const EditPackageStructure = props => {
    return (
        <PackageStructure
            mode="edit"
            header={'Update Package Structure'}
            {...props}
        />
    );
};
