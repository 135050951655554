import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const PotentialCustomerLockRejectForm = props => {
  let {
    route: {params: {row} = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: `/potentialcustomercontactlocks`,
    eventSourceId: ['AddReason'],
  });

  const defaultValue = {...row, status: 'Rejected'};

  return (
    <Form
      defaultValues={defaultValue}
      onSubmit={onSubmit}
      header={'Add Reason'}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'reason',
          type: 'textArea',
          label: 'Reason',
          placeholder: 'Rejection Reason',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
