import React from 'react';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';

export const LeadCaptureTable = () => {

  return (
    <Table
      api={`/leadcaptures`}
      fields={{
        name: 1,
        email: 1,
        mobile: 1,
        requirement: 1,
        page: {
          url: 1,
        }
      }}
     renderHeader={() => (
        <TableHeader
          title="Lead Capture"
        />
      )}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
          width: 150,
        },
        {
          type: 'text',
          field: 'email',
          header: 'Email',
          align: 'center',
          width: 275,
        },
        {
          type: 'text',
          field: 'mobile',
          header: 'Phone',
          width: 150,
        },
        {
          type: 'text',
          field: 'page.url',
          header: 'Page',
        },
        {
          type: 'textArea',
          field: 'requirement',
          header: 'Requirement',
        },
      ]}
    />
  );
};
