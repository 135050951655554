import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import {
    ApprovedAttendanceRequestTable,
    ClosedEWDRequestTable,
    RejectedAttendanceRequestTable,
    TeamAttendanceRequestTable,
    TeamEWDRequestTable,
} from './TeamAttendanceRequestTable';

export const TeamAttendanceRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;

  params = {
    ...filterValues,
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <TeamAttendanceRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ],
    },
    approved: {
      label: 'Closed',
      view: (
        <ApprovedAttendanceRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    rejected: {
      label: 'Rejected',
      view: (
        <RejectedAttendanceRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};


export const TeamEWDRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;

  params = {
    ...filterValues,
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    pending: {
      label: 'Open',
      view: (
        <TeamEWDRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'First Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'first_attendance_type_id',
            },
            {
              type: 'autoComplete',
              label: 'Second Half',
              api: '/DailyAttendanceTypes',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'second_attendance_type_id',
            },
            {
              type: 'date',
              field: 'period',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ],
    },
    approved: {
      label: 'Closed',
      view: (
        <ClosedEWDRequestTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    }
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
