import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchFormData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const AddressVerificationForm = props => {
  const {onClose} = props;
  const {user = {}, resourceUrl, fetch} = useAppStateContext();
  const {employee} = user;
  const {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'EmployeeAssignment',
    afterSubmit: onClose,
    ...props,
  });

  console.log('employee', employee);

  const {data} = useFetchFormData({
    api: '/employeeSuggestions',
    filter: {
      _id: employee?._id,
    },
    fields: {
      address_line_1_current: 1,
      address_line_2_current: 1,
      city_current: 1,
      state_current: 1,
      zip_current: 1,
      mobile_number: 1,
    },
  });
  console.log('data', data);
  if (!data) {
    return null;
  }
  const defaultValues = {
    _id: employee?._id,
    address_line_2_current: data?.address_line_2_current,
    address_line_1_current: data?.address_line_1_current,
    city_current: data?.city_current,
    state_current: data?.state_current,
    zip_current: data?.zip_current,
    mobile_number: data?.mobile_number,
    address_review_pending: false,
  };
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitAction={'Save'}
      closable={false}
      // afterSubmit={onClose}
      layoutFields={[
        {
          label: 'Current address details',
          fields: [
            {
              type: 'textArea',
              field: 'address_line_1_current',
              label: 'Address line 1',
              required: true,
            },
            {
              // type: 'richText',
              type: 'textArea',
              field: 'address_line_2_current',
              label: 'Address line 2',
              required: true,
            },
            {
              type: 'textArea',
              field: 'city_current',
              label: 'City',
              required: true,
              size: 6,
            },
            {
              type: 'textArea',
              field: 'state_current',
              label: 'State',
              required: true,
              size: 6,
            },
            {
              type: 'textArea',
              field: 'zip_current',
              label: 'Zip',
              required: true,
              size: 6,
            },

            {
              type: 'textArea',
              field: 'mobile_number',
              label: 'Contact no.',
              required: true,
              size: 6,
            },
          ],
        },
      ]}
    />
  );
};

export const AddressVerification = props => {
  console.log('props>>>>>', props);
  return (
    <AddressVerificationForm
      header={{
        title: 'Please review these details before moving forward',
        // secondaryTitle: () => {
        //   const colors = useTheme('colors');
        //   const {CAPTION_LARGE} = useTheme('fonts');
        //   return (
        //     <Text
        //       style={{
        //         ...CAPTION_LARGE,
        //         color: colors.NEUTRAL_MEDIUM,
        //         paddingLeft: 5,
        //       }}>
        //       {row?.name}
        //     </Text>
        //   );
        // },
      }}
      {...props}
    />
  );
};
