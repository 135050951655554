import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  MultiCheckbox as MultiCheckboxComponent,
  MultiCheckboxRenderer as MultiCheckboxRendererComponent,
} from '@unthinkable/react-checkbox';
import {CheckboxStyles, MultiCheckboxStyles} from './theme';

const MultiCheckbox = ({options, ...props}) => {
  const styles = useStyles(MultiCheckboxStyles);
  if (options && typeof options === 'function') {
    options = options(props?.form);
  }
  return (
    <MultiCheckboxComponent styles={styles} options={options} {...props} />
  );
};

export const MultiCheckboxRenderer = props => {
  const styles = useStyles(CheckboxStyles);
  return <MultiCheckboxRendererComponent styles={styles} {...props} />;
};

export default MultiCheckbox;
