import {MoreActionStyles} from '../moreAction/theme';

export const TableStyles = ({theme}) => {
  const {fonts, colors, icons, shadows} = theme;
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
    },
    variant: {
      bordered: {
        container: {
          borderColor: colors.OUTLINE,
          borderWidth: 1,
          borderRadius: 8,
        },
      },
      outline: {
        container: {
          borderColor: colors.OUTLINE,
          borderWidth: 1,
          borderRadius: 8,
        },
      },
    },
    header: {
      backgroundColor: colors.SURFACE3,
    },
    column: {
      padding: 8,
    },
    headerAggregate: {
      backgroundColor: colors.SURFACE2,
    },
    columnGroupText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    headerText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    headerAggregateText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    row: {
      borderColor: colors.OUTLINE,
    },
    rowHoverColor: colors.SURFACE1,
    activeRow: {
      backgroundColor: colors.OUTLINE,
    },
    rowHover: {
      ...shadows.M,
      backgroundColor: 'transparent',
    },
    rowText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    text: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    columnVariant: {
      subtle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_LOW,
      },
      secondary: {
        color: colors.NEUTRAL_LOW,
      },
    },
    moreActions: MoreActionStyles({theme}),
    groupRowText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    groupItemsContainer: {
      borderColor: colors.OUTLINE,
      borderRadius: 6,
    },
    groupRow: {
      backgroundColor: colors.SURFACE2,
      borderColor: colors.OUTLINE,
    },
    groupRowContent: {
      gap: 10,
    },
    checkbox: {
      checkedIcon: icons.CheckboxIcon,
      uncheckedIcon: icons.UnCheckBoxIcon,
      deSelectIcon: icons.DeSelectBox,
    },
  };
};

export const RecursiveTableStyles = ({theme}) => {
  const {fonts, colors} = theme;
  return {
    ...TableStyles({theme}),
    topGap: 8,
    header: {
      backgroundColor: colors.SURFACE1,
      borderRadius: 6,
    },
    row: {
      borderWidth: 1,
      borderRadius: 6,
      marginTop: 8,
      borderColor: colors.OUTLINE,
      overflow: 'hidden',
    },
  };
};

export const RecursiveTableV2Styles = ({theme}) => {
  const {colors, icons} = theme;
  return {
    ...TableStyles({theme}),
    recursiveExpandIcon: icons.RecursiveExpand,
    recursiveCollapseIcon: icons.RecursiveCollapse,
    topGap: 0,
    header: {
      backgroundColor: colors.SURFACE1,
      borderRadius: 6,
    },
    row: {
      borderWidth: 0,
      borderRadius: 6,
      marginTop: 0,
      borderColor: colors.OUTLINE,
      overflow: 'hidden',
    },
    recursiveIconContainer: {
      width: 16,
      height: 16,
    },
  };
};

export const DashboardGroupTableStyles = ({theme}) => {
  const {fonts, colors} = theme;
  return {
    ...TableStyles({theme}),
    groupContainer: {
      marginTop: 8,
    },
    groupRow: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottomWidth: 0,
      backgroundColor: colors.SURFACE2,
      borderColor: colors.OUTLINE,
    },
    groupRowText: {
      ...fonts.TITLE,
    },
    groupItemsContainer: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 6,
    },
  };
};

export const TableHeaderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    close: {
      icon: icons.Close,
      container: {
        padding: 6,
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
      },
      hoverContainer: {
        backgroundColor: colors.SURFACE2,
      },
    },
    container: {
      padding: 0,
      marginBottom: 16,
    },
    title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
    secondaryTitle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const TableFooterStyles = ({theme: {fonts, colors}}) => {
  return {
    container: {
      padding: 0,
      marginTop: 16,
      justifyContent: 'flex-end',
    },
  };
};

export const AttachmentCountRenderStyle = ({theme: {fonts, colors}}) => {
  return {
    rowText: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.ACTION,
      textAlign: 'right',
    },
  };
};

export const CardTableStyles = ({theme}) => {
  const {colors} = theme;
  return {
    ...TableStyles({theme}),
    topGap: 8,
    container: {
      backgroundColor: 'transparent',
    },
    header: {
      backgroundColor: colors.SURFACE1,
      borderRadius: 6,
    },
    row: {
      borderWidth: 1,
      borderRadius: 6,
      marginTop: 8,
      borderColor: colors.OUTLINE,
      overflow: 'hidden',
    },
  };
};

export const GroupTableStyles = ({theme}) => {
  const {colors, icons} = theme;
  return {
    ...TableStyles({theme}),
    expandIcon: icons.GroupExpand,
    collapseIcon: icons.GroupCollapse,
    headerWrapper: {
      borderRadius: 6,
      borderWidth: 0,
      borderColor: colors.OUTLINE,
      marginBottom: 16,
    },
    header: {
      backgroundColor: colors.SURFACE3,
    },
    groupRow: {
      backgroundColor: colors.SURFACE2,
      borderWidth: 0,
    },
    groupContainer: {
      overflow: 'hidden',
      marginBottom: 12,
      borderColor: colors.OUTLINE,
      borderWidth: 1,
      borderRadius: 8,
    },
  };
};

export const BorderedGroupTableStyles = ({theme}) => {
  const {colors, fonts} = theme;
  return {
    ...TableStyles({theme}),
    variant: {
      bordered: {
        container: {
          borderWidth: 0,
        },
      },
    },
    groupContainer: {
      marginTop: 8,
      borderColor: colors.OUTLINE,
      borderWidth: 1,
      borderRadius: 8,
      overflow: 'hidden',
    },
    groupRow: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottomWidth: 0,
      backgroundColor: colors.SURFACE2,
      overflow: 'hidden',
    },
    groupRowText: {
      ...fonts.TITLE,
    },
    groupItemsContainer: {
      borderRadius: 6,
    },
  };
};

export const GroupByStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    groupByIcon: icons.GroupBy,
    crossIcon: icons.Cross,
    tickIcon: icons.BlackTick,
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 5,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
    },
    icon: {
      height: 20,
      width: 20,
    },
    indicator: {
      backgroundColor: colors.SUCCESS_HIGH,
      height: 8,
      width: 8,
      borderRadius: 4,
      position: 'absolute',
      top: 0,
      right: 0,
    },
    modal: {
      sm: {
        container: {},
      },
      md: {
        container: {
          marginLeft: 12,
          marginRight: 12,
          width: 300,
          maxHeight: 300,
          borderWidth: 1,
          borderColor: colors.OUTLINE,
          backgroundColor: colors.BACKGROUND,
        },
      },
    },
    headerContainer: {
      gap: 8,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
      alignItems: 'center',
    },
    headerIcon: {
      height: 16,
      width: 16,
    },
    headerText: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_HIGH,
      flex: 1,
    },
    footerContainer: {
      justifyContent: 'flex-end',
      gap: 8,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      borderTopWidth: 1,
      borderTopColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE3,
    },
    bodyContainer: {
      padding: 8,
    },
    itemContainer: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 6,
      paddingBottom: 6,
      borderRadius: 8,
      gap: 10,
      alignItems: 'center',
      flexDirection: 'row',
    },
    activeItemContainer: {
      backgroundColor: colors.SURFACE2,
    },
    itemLabel: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      flex: 1,
    },
  };
};
export const NoDataFoundStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    NoDataFoundIcon: icons.NoDataGIF,
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      paddingBottom: 40,
    },
    text: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const AIIconButtonStyle = ({theme: {fonts,colors}}) => {
  return {
    container: {
      backgroundColor: 'transparent',
    },
    disabledContainer: {
      backgroundColor: 'transparent',
    },
    text: {
      ...fonts.ACTION,
      color: colors.SUCCESS_HIGH,
    },
    hoverContainer: {
      backgroundColor: colors.SUCCESS_LOW,
    },
  };
};

export const AIIconRejectButtonStyle = ({theme: {fonts,colors}}) => {
  return {
    container: {
      backgroundColor: 'transparent',
    },
    disabledContainer: {
      backgroundColor: 'transparent',
    },
    text: {
      ...fonts.ACTION,
      color: colors.ERROR_HIGH,
    },
    hoverContainer: {
      backgroundColor: colors.ERROR_LOW,
    },
  };
};
