import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const NomineeRelationTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['nomineerelation']}
      api={`/nomineerelations`}
      renderHeader={() => {
        return (
          <TableHeader
            title="Nominee Relation"
            actions={[
              <AddButton
                title="Nominee Relation"
                view="add-nominee-relation"
                params={params}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-nominee-relation`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          header: 'Relation',
          field: 'relation',
        },
      ]}
    />
  );
};
