import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {CampaignMessageTableNew} from './CampaignMessageTableNew';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';

export const CampaignMessagesTab = props => {
  const {
    route: {params},
  } = props;

  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {params: filterParams} = filterValues || {};
  const tabs = {
    Active_Message: {
      label: 'Active',
      view: <CampaignMessageTableNew filterParams={filterParams} />,
    },
    UnActive_Message: {
      label: 'Inactive',
      view: (
        <CampaignMessageTableNew
          filterParams={filterParams}
          status={'inactive'}
        />
      ),
    },
  };
  const actions = [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
    />,
  ];

  return <TabView {...props} tabs={tabs} actions={actions} />;
};
