import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

export const CurrentBussinessResourceDeployableList = props => {
  const {
    route: {params},
  } = props;
  const {filter = {}} = params;
  return (
    <Table
      api={`/employeeAssignments`}
      fields={{
        from_date: 1,
        to_date: 1,
        hours: 1,
        employee: {name: 1, color: 1, official_email_id: 1},
        project_id: {project: 1},
        order: {order_number: 1},
        customer: {name: 1},
      }}
      renderHeader={() => {
        return <TableHeader title="Resource Deployable" />;
      }}
      filter={{...filter}}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          type: 'text',
          field: 'project_id.project',
          header: 'Project',
          width: 120,
        },
        {
          header: 'Customer',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.customer?.name}
                items={[
                  {
                    value: row?.order?.order_number,
                  },
                ]}
              />
            );
          },
        },
        {
          width: 80,
          type: 'date',
          field: 'from_date',
          header: 'From',
        },
        {
          width: 80,
          type: 'date',
          field: 'to_date',
          header: 'To',
        },
        {
          width: 80,
          type: 'number',
          field: 'hours',
          header: 'Hours',
        },
      ]}
    />
  );
};
