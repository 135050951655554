import React from 'react';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {EmailPageStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {BaseForm} from '../../../components/form/Form';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';
import {useToast} from '@unthinkable/react-toast';
import {termsOfServiceURL} from '../../../modules/common/constants/SourceConstants';

const EmailLogin = props => {
  const {navigation, route: {params} = {}} = props || {};
  const {
    LOCOMOIcon,
    containerStyle,
    mainContainerStyle,
    iconContainerStyle,
    subContainerStyle,
    textStyle,
    secondaryTextStyle,
    spanTextStyle,
    UnthinkableIcon,
    headingTextStyle,
    backButtonIcon,
    buttonStyles,
  } = useStyles(EmailPageStyles);

  const {onLogin} = params || {};

  const {post} = useAppStateContext();

  const onSubmit = async values => {
    try {
      const {data} = await post({
        uri: `/validate-email`,
        method: 'post',
        props: {
          email: values.email,
        },
      });

      if (data && data._id) {
        navigation.navigate('enter-password', {email: values.email, onLogin});
      } else {
        throw new Error('User not found');
        // navigation.navigate('create-password', {email: values.email, onLogin});
      }
    } catch (e) {
      toast({message: e.message, type: 'Error'});
    }
  };

  const toast = useToast();

  const validateEmail = email => {
    const result = email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/);
    return result;
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onSubmit({email: e.target.value});
    }
  };
  return (
    <View style={containerStyle}>
      <View style={subContainerStyle}>
        <Image source={LOCOMOIcon} style={iconContainerStyle} />
        <View style={mainContainerStyle}>
          <Row gap={12} style={{alignItems: 'center', marginBottom: 12}}>
            <TouchableOpacity
              onPress={() => {
                navigation.goBack();
              }}>
              <Image source={backButtonIcon} />
            </TouchableOpacity>
            <Text style={headingTextStyle}>Continue with email</Text>
          </Row>
          <Text style={textStyle}>
            We’ll check if you have an account, and help create one if you
            don’t.
          </Text>
          <BaseForm
            skipHeader
            footer={({values}) => {
              return {
                actions: [
                  <View style={{flex: 1, marginTop: 28}}>
                    <Button
                      onPress={() => {
                        onSubmit(values);
                      }}
                      text="Continue"
                      styles={buttonStyles}
                      gap={8}
                      disabled={
                        !(values && values.email && values.email.trim())
                      }
                    />
                  </View>,
                ],
              };
            }}
            skipDefaultSave
            layoutFields={[
              {
                type: 'text',
                field: 'email',
                label: 'Professional email',
                required: true,
                onKeyPress: handleKeyPress,
                validate: email => {
                  let result = validateEmail(email);
                  return result ? '' : 'Invalid Email';
                },
                autoFocus: true,
              },
            ]}
            {...props}
          />
          <Text style={secondaryTextStyle}>
            By signing up, you agree to our{' '}
            <Text
              as={'span'}
              style={spanTextStyle}
              onPress={() => Linking.openURL(termsOfServiceURL)}>
              Terms of Service
            </Text>{' '}
            {/* and{' '}
            <Text as={'span'} style={spanTextStyle}>
              Data processing Agreement
            </Text> */}
          </Text>
        </View>
        <Image source={UnthinkableIcon} />
      </View>
    </View>
  );
};

export default EmailLogin;
