import React from 'react';
import {TabView} from '../../../components/tab';
import {
  ActiveMilestoneTable,
  BacklogMilestoneTable,
  CompletedMilestoneTable,
} from './ProjectMilestoneTableView';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';

export const ProjectMilestoneTabs = props => {
  const {
    route: {params},
  } = props;
  const {project} = params || {};

  const {searchValue, onChangeFilter} = useFilter({});
  return (
    <TabView
      selectedTab={'active'}
      tabs={{
        backlog: {
          label: 'Backlog',
          view: <BacklogMilestoneTable {...props} searchValue={searchValue} />,
          api: `/projectmilestones`,
          filter: {
            status: 'backlog',
            project_id: project?._id,
          },
          eventSourceId: 'Milestone',
        },
        active: {
          label: 'Active',
          view: <ActiveMilestoneTable {...props} searchValue={searchValue} />,
          api: `/projectmilestones`,
          filter: {
            status: 'active',
            project_id: project?._id,
          },
          eventSourceId: 'Milestone',
        },
        completed: {
          label: 'Completed',
          view: (
            <CompletedMilestoneTable {...props} searchValue={searchValue} />
          ),
          api: `/projectmilestones`,
          filter: {
            status: {$in: ['completed']},
            project_id: project?._id,
          },
          eventSourceId: 'Milestone',
        },
      }}
      {...props}
      actions={() => {
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        ];
      }}
    />
  );
};
