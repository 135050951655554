import React from 'react';
import {FormHeader} from './FormStyles';
import {ScrollView, View} from '@unthinkable/react-core-components';
import {Button} from '@unthinkable/react-button';
import {ActionWrapper, StepHeader, StepItem, VerifiedIcon} from './Styles';
import {TabBarRow} from '@unthinkable/react-tabs';
import {SubmitButton} from './FormActions';
import Check from '../images/Check.svg';
import {LayoutFieldsRender, useResolveFormProps} from './FormLayout';
import {useFormContext} from './FormContext';

export const StepsTabBar = ({
  steps,
  styles,
  selected,
  setSelected,
  clickableTabs,
  isValid,
  skipNumbering,
  ...props
}) => {
  let tabs = {};
  steps?.map(({title}, key) => {
    tabs[key] = {};
    tabs[key].label = (
      <StepItem>
        <StepHeader styles={styles}>
          {skipNumbering ? title : `${key + 1}. ${title}`}
        </StepHeader>
        {!clickableTabs && isValid && Number(key) < Number(selected) ? (
          <VerifiedIcon source={Check} />
        ) : (
          void 0
        )}
      </StepItem>
    );
  });
  return (
    <TabBarRow
      styles={styles}
      tabs={tabs}
      selectedTab={selected}
      onSelectTab={clickableTabs ? setSelected : void 0}
      {...props}
    />
  );
};

export const StepsFooter = ({
  isLast,
  isFirst,
  next,
  back,
  isValid,
  styles,
  selectedStep,
  values,
  saveOnNext,
  readOnly,
}) => {
  if (selectedStep?.saveOnNext !== undefined) {
    saveOnNext = selectedStep?.saveOnNext;
  }

  const onNextPress = async () => {
    await selectedStep?.onNext?.({values});
    next?.();
  };

  return (
    <ActionWrapper styles={styles}>
      {!isFirst ? (
        <Button styles={styles?.secondary} text="Back" onPress={back} />
      ) : (
        <View style={styles?.empty} />
      )}
      {!isLast ? (
        saveOnNext && !readOnly ? (
          <SubmitButton
            styles={styles?.primary}
            disabled={!isValid}
            type={'saveAndNext'}
            label={'Save and Next'}
            onNext={onNextPress}
          />
        ) : (
          <Button
            styles={styles?.primary}
            disabled={!isValid}
            text="Next"
            onPress={onNextPress}
          />
        )
      ) : (
        <SubmitButton
          styles={styles?.primary}
          disabled={!isValid || readOnly}
          label="Submit"
          type="saveAndClose"
        />
      )}
    </ActionWrapper>
  );
};

export const MultiStepFormLayout = ({
  steps,
  selectedStepIndex,
  setSelectedStepIndex,
  isFirstStep,
  isLastStep,
  nextStep,
  backStep,
  ...props
}) => {
  const {styles} = props;
  const selectedStep = steps[selectedStepIndex];
  const {fields, render} = selectedStep;

  const {header, footer, ...restProps} = useResolveFormProps(props);
  const {isValid} = useFormContext();

  return (
    <>
      {header ? (
        <FormHeader gap={12} styles={styles?.header} {...header} />
      ) : (
        void 0
      )}
      <StepsTabBar
        {...restProps}
        styles={styles?.tabs}
        isValid={isValid}
        steps={steps}
        selected={selectedStepIndex}
        setSelected={setSelectedStepIndex}
      />
      {fields ? (
        <ScrollView style={{paddingLeft: 20, paddingRight: 20}}>
          <LayoutFieldsRender {...restProps} layoutFields={fields} />
        </ScrollView>
      ) : (
        render?.({
          ...restProps,
        })
      )}
      <StepsFooter
        {...restProps}
        styles={styles?.tabs}
        selectedStep={selectedStep}
        isValid={isValid}
        isLast={isLastStep}
        isFirst={isFirstStep}
        next={nextStep}
        back={backStep}
      />
    </>
  );
};
