import React from 'react';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {resolveExp} from '@unthinkable/react-utils';
import {useComponentTheme} from '@unthinkable/react-theme';
import {DurationRenderer} from '../../../components/form-editors/duration/DurationInput';
import {AddFilledAction} from '../../../components/action/AddAction';

export const EstRender = props => {
  let {columnField, onAdd, row, readOnly, colVisible} = props;
  let {columnTextStyle, estChange, estChangeStyle} = useComponentTheme(
    'PlusColumnRenderStyles',
  );
  if (typeof colVisible === 'function') {
    colVisible = colVisible({row});
  }

  if (colVisible === false) {
    return;
  }

  let {est_changed} = row || 0;

  let compareValue = resolveExp(row, columnField);

  if (!compareValue && !readOnly) {
    return (
      <AddFilledAction
        onPress={() => {
          onAdd && onAdd(props);
        }}
      />
    );
  }

  return (
    <View>
      <Text style={columnTextStyle}>
        <DurationRenderer value={compareValue} />
      </Text>
      {est_changed ? (
        <View title={'Est effort has been updated'} style={estChangeStyle}>
          <Image source={estChange} />
        </View>
      ) : null}
    </View>
  );
};
