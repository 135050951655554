import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {ActiveEmployeeSizeTable} from './ActiveEmployeeSizeTable';
import {InActiveEmployeeSizeTable} from './InActiveEmployeeSizeTable';

const tabs = {
  active: {
    label: 'Active',
    view: <ActiveEmployeeSizeTable />,
    api: '/employeeSize/active',
  },
  inActive: {
    label: 'InActive',
    view: <InActiveEmployeeSizeTable />,
    api: '/employeeSize/inActive',
  },
};

export const EmployeeSizeTab = props => {
  return <TabView tabs={tabs} {...props} />;
};
