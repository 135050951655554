// Rajnish Trikaldarshi 08-01-2024 - Make Screen to show the cron logs.
import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const CronLogsTable = props => {
  return (
    <Table
      renderHeader={() => <TableHeader title="Cron Logs" />}
      api="/cronlogs"
      fields={{
        cron: 1,
        createdAt: 1,
        completed_at: 1,
        error: 1,
        total_time: 1,
        status: 1,
      }}
      sort={{_id: -1}}
      columns={[
        {
          header: 'Name',
          field: 'cron',
          type: 'text',
          width: 250,
        },
        {
          header: 'Start Date',
          field: 'createdAt',
          type: 'date',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
        {
          header: 'End Date',
          field: 'completed_at',
          type: 'date',
          width: 150,
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
        },
        {
          header: 'Total time',
          field: 'total_time',
          type: 'text',
          width: 100,
        },
        {
          header: 'Status',
          field: 'status',
          type: 'text',
          width: 100,
        },
        {
          header: 'Error',
          field: 'error',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
