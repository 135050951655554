import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';

export const ActiveOrderMarkDeliveredForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}, period = {}} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/markDeliveredDelivery',
    eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
  });

  const {data: projectResourceWorkingData} = useFetchFormData({
    api: `/projectresourceworkingdata`,
    filter: {
      _id: row?._id,
    },
  });

  if (!projectResourceWorkingData) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      header={'Mark Delivered'}
      submitAction={'Update'}
      defaultValues={{
        ...projectResourceWorkingData,
        approval_status: 'Delivered',
        billed_hours: projectResourceWorkingData?.approx_billed_hours,
        is_locomo: true,
      }}
      layoutFields={[
        {
          label: 'Delivered Date',
          field: 'delivered_date',
          type: 'date',
        },
        {
          label: 'Delivered Hours',
          field: 'billed_hours',
          type: 'number',
        },
      ]}
    />
  );
};
