import React from 'react';
import {
  Text,
  View,
} from '@unthinkable/react-core-components';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';
import { useTheme } from '@react-navigation/native';
import moment from 'moment';
import { useInvoke } from '../../../controllers/useInvoke';

export const HRAppraisalPackageDetailsTable = props => {
  const { route: { params = {} } = {}, navigation } = props;
  const { row } = params;
  const color = useTheme('colors');

  const invokeDelete = useInvoke({
    method: 'delete',
    eventSourceId: ['EmployeePackageDetails'],
});

  return (
    <Table
      eventSourceId={['EmployeePackageDetails']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Package Details"
          />
        );
      }}
      api={`/artificialsalarycomponent/appraisal/${row?._id}`}
      onRowPress={props => {
        const { row } = props;
        navigation.navigate(`edit-appraisal-package-details`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Duration',
          type: 'date',
          render: ({ row, styles }) => {
            let { rowText } = styles;
            return (
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ ...rowText, marginRight: 3, color: '#737373' }}>
                  {moment(row?.from_date).format('DD MMM YY')}
                </Text>
                <Text>-</Text>
                <Text style={{ ...rowText, marginLeft: 3, color: '#737373' }}>
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
          width: 300,
        },
        {
          header: 'CTC(Per Month)',
          field: 'ctc_amount',
          type: 'colorTag',
          colorField: color.ACCENT6_MEDIUM,
        },
      ]}
      moreActions={[
        {
          title: 'Remove',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this?',
          },
          onPress: ({row}) => {
            invokeDelete({
              uri: `/artificialsalarycomponents/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
