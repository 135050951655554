import React, {useMemo, useContext, useRef} from 'react';
import {View} from '@unthinkable/react-core-components';
import html2canvas from 'html2canvas';
const CaptureContext = React.createContext();

export const CaptureContextProvider = ({children}) => {
  const captureRef = useRef(null);

  const capture = async () => {
    const canvas = await html2canvas(captureRef.current);
    const imgData = canvas.toDataURL('image/png');

    return imgData;

    const img = new window.Image();
    img.src = imgData;
    return img;
  };

  const captureContextValue = useMemo(
    () => ({captureRef, capture}),
    [captureRef, capture],
  );

  return (
    <CaptureContext.Provider value={captureContextValue}>
      <View style={{flex: 1}} ref={captureRef}>
        {children}
      </View>
    </CaptureContext.Provider>
  );
};

export const useCaptureContext = () => {
  return useContext(CaptureContext) || {};
};
