export const Labels = {
  Features: 'Release Units',
  Feature: 'Release Unit',
  Features_lower: 'release-units',
  Feature_lower: 'release-unit',
  Feature_lower_module: 'module-release-unit',
  Requirements: 'User Stories',
  Requirements_lower: 'user-stories',
  Requirement_lower: 'user-story',
};
