import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  ActivityIndicator,
  Text,
  View,
} from '@unthinkable/react-core-components';
import {IconButton} from '@unthinkable/react-button';
import {useTheme} from '@unthinkable/react-theme';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import { Form } from '../../../components/form/Form';

const RenderStatus = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'TrainingAttendance',
    close: false,
    setLoading,
  });
  let {status: attendance} = row;
  let {rowText = {}} = styles;
  if (!attendance) {
    return (
      <>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={{flexDirection: 'row'}}>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/trainingAttendances/${row?._id}`,
                  props: {
                    status: 'Present',
                  },
                });
              }}>
              <Text style={{...rowText, color: '#34C759'}}>Present </Text>
            </View>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/trainingAttendances/${row?._id}`,
                  props: {
                    status: 'Absent',
                  },
                });
              }}>
              <Text style={{...rowText, color: '#FF5247'}}>Absent</Text>
            </View>
          </View>
        )}
      </>
    );
  }

  let updateColor = {color: '#34C759'};
  let background = '';
  if (attendance === 'Present') {
    updateColor['color'] = '#34C759';
    background = '#F0FFF4';
  } else if (attendance === 'Absent') {
    updateColor['color'] = '#FF3B30';
    background = '#FFF1F0';
  }

  return (
    <View
      style={{
        backgroundColor: background,
        padding: 6,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText, ...updateColor}}>{attendance}</Text>
    </View>
  );
};

const EditAttendance = props => {
  const {Edit} = useTheme('icons');
  return <IconButton icon={Edit} />;
};

export const TrainingAttendanceTable = props => {
  const {
    route: {params},
    navigation
  } = props;
  const {training_batch, addOnFilter} = params;
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Attendance"
          secondaryTitle={`${training_batch?.name} - Batch ${training_batch?.batch_number}`}
        />
      )}
      api="/trainingAttendances"
      filter={{training_batch: training_batch._id}}
      addOnFilter={{
        ...addOnFilter,
      }}
      fields={{
        employee: {
          name: 1,
          color: 1,
        },
        status: 1,
        date: 1,
      }}
      eventSourceId={'TrainingAttendance'}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Trainee',
          field: 'employee',
          type: 'userAvatarChip',
        },
        {
          header: 'Attendance',
          render: RenderStatus,
          align: 'center',
        },
        {
          render: EditAttendance,
          width: 50,
          onPress: ({row}) => {
            navigation.navigate('edit-training-attendance', {
              training_attendance: row,
            });
          },
        },
      ]}
    />
  );
};

export const EditTrainingAttendance = props => {
  const {
    route: {params},
  } = props;
  const {training_attendance} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/trainingAttendances',
    eventSourceId: 'TrainingAttendance',
  });
  return (
    <Form
      header={{
        title: 'Edit Attendance',
        secondaryTitle: training_attendance?.employee?.name,
      }}
      onSubmit={onSubmit}
      mode="edit"
      api={'/trainingAttendances/' + training_attendance?._id}
      fields={{
        status: 1,
      }}
      layoutFields={[
        {
          field: 'status',
          type: 'autoComplete',
          label: 'Attendance',
          options: ['Absent', 'Present'],
        },
      ]}
    />
  );
};

const RenderMyAttendance = ({row, styles}) => {
  let {rowText = {}} = styles;
  return (
    <Text
      style={{
        ...rowText,
        color: row.status == 'Absent' ? '#FF5247' : '#34C759',
      }}>
      {row.status}
    </Text>
  );
};

export const MyAttendanceTable = props => {
  const {
    route: {params},
  } = props;
  const {employee_training, training_batch} = params;
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="My Attendance"
          secondaryTitle={`${training_batch?.name} - Batch ${training_batch?.batch_number}`}
        />
      )}
      api="/trainingAttendances"
      filter={{training_batch: training_batch._id}}
      addOnFilter={{
        employee_training,
      }}
      fields={{
        employee: {
          name: 1,
          color: 1,
        },
        status: 1,
        date: 1,
      }}
      eventSourceId={'TrainingAttendance'}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Attendance',
          render: RenderMyAttendance,
          align: 'center',
        },
      ]}
    />
  );
};
