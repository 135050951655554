import React, {useState} from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Table} from '../../../components/table/Table';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import {useProjectActions} from '../actions/ProjectActions';
import {useInvoke} from '../../../controllers/useInvoke';
import {Tag} from '../../../components/chip/Chip';
import moment from 'moment';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {CommentMoreAction} from '../../../app-components/renders/CommentRender';
import {PROJECT_TYPE_ID} from '../../common/constants/SourceConstants';
import {TableHeader} from '../../../components/table/Headers';
import {IconWithBackground} from '../../../components/icons/IconWithBackground';

const RenderActions = ({row = {}, styles = {}, navigation}) => {
  const [loading, setLoading] = useState(false);
  const colors = useTheme('colors');
  const {user} = useAppStateContext();
  const {employee = {}} = user || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'performance-report',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row', gap: 20}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/projects/${row._id}`,
                props: {
                  project_status: 'a',
                  approved_on: getZeroTimeDate(new Date()),
                  approved_by: employee?._id,
                },
              });
            }}>
            <Text style={{...rowText, color: colors.SUCCESS_HIGH}}>Accept</Text>
          </View>
        </>
      )}
    </View>
  );
};

const project = ({projectDetail}) => {
  return {
    header: 'Project',
    minWidth: 230,
    render: ({row}) => {
      const {project_start_date, completed_on} = row;
      let icon;
      let color = 'BRAND_UPPER_LOW';
      if (row.tool === 'Locomo') {
        icon = 'LocomoTool';
      } else if (row.tool === 'Jira') {
        icon = 'JiraIcon';
        color = 'SECONDARY_MEDIUM';
      } else {
        icon = 'OtherTool';
      }
      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <IconWithBackground icon={icon} color={color} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project}
            items={[
              {
                value: row?.code,
              },
              {
                value:
                  project_start_date &&
                  moment(project_start_date).format('DD MMM YY'),
              },
            ]}
          />
        </Row>
      );
    },
    onPress: projectDetail,
  };
};

const PROJECTTYPE = {
  header: 'Type',
  width: 160,
  align: 'center',
  render: ({row}) => {
    const {
      is_estimate_revised,
      estimate_revision_history,
      man_month_estimate,
      new_project_type,
    } = row || {};
    let projectType = '';
    let NewEstimate = 0;
    if (
      new_project_type === 'managedteam' ||
      new_project_type === 'sdlc' ||
      new_project_type === 'staffaugmentation'
    ) {
      projectType = 'T&M';
    }
    if (new_project_type === 'sdlcplus') {
      projectType = 'Fixed';
    }
    if (new_project_type === 'departmentproject') {
      projectType = 'Department Project';
    }
    if (!new_project_type) {
      projectType = 'Internal';
    }
    if (is_estimate_revised) {
      NewEstimate =
        estimate_revision_history[estimate_revision_history.length - 1]
          ?.new_estimate;
    } else {
      NewEstimate = man_month_estimate;
    }
    return (
      <PrimaryColumnCard
        primaryTitle={<Tag value={projectType} color={randomColor()} />}
        items={[
          {
            value: NewEstimate,
          },
        ]}
      />
    );
  },
};

const CUSTOMER = {
  header: 'Customer',
  field: 'customer.name',
  type: 'colorTag',
  minWidth: 230,
  align: 'center',
};

const CSM = {
  header: 'CSM/PM',
  field: 'owner_id',
  type: 'userAvatar',
  align: 'center',
  width: 80,
};

const comments = props => {
  return CommentMoreAction({
    ...props,
    params: ({row}) => ({
      comment_source: {_id: PROJECT_TYPE_ID, label: 'Project'},
      comment_source_id: {_id: row?._id, name: row?.project},
    }),
  });
};

export const PendingProjectApprovalsTable = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    addOnFilter = {},
  } = props;
  const deleteProject = useInvoke({
    method: 'delete',
    eventSourceId: 'performance-report',
  });
  const {projectDetail, editProject, activityLogDetail} = useProjectActions({
    navigation,
    params,
  });

  return (
    <Table
      {...props}
      limit={500}
      search={searchValue}
      addOnFilter={addOnFilter}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/pending-approvals'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      sort={{createdAt: -1}}
      columns={[
        project({projectDetail}),
        PROJECTTYPE,
        CUSTOMER,
        // {
        //   header: 'Man Month Estimate',
        //   type: 'number',
        //   width: 150,
        //   align: 'center',
        //   render: ({row, styles}) => {
        //     const {
        //       is_estimate_revised,
        //       estimate_revision_history,
        //       man_month_estimate,
        //     } = row || {};
        //     let NewEstimate = 0;
        //     if (is_estimate_revised) {
        //       NewEstimate =
        //         estimate_revision_history[estimate_revision_history.length - 1]
        //           ?.new_estimate;
        //     } else {
        //       NewEstimate = man_month_estimate;
        //     }
        //     return <Text style={{...styles.rowText}}>{NewEstimate}</Text>;
        //   },
        // },
        CSM,
        {
          header: 'Action',
          width: 100,
          align: 'left',
          render: ({row, styles}) => (
            <RenderActions row={row} styles={styles} />
          ),
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProject,
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete Project',
            message: 'Are you sure you want to delete this project?',
            confirmText: 'Save',
          },
          onPress: ({row}) => {
            deleteProject({
              uri: '/projects/' + row?._id,
            });
          },
        },
        comments({
          navigation,
          params,
        }),
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
      ]}
    />
  );
};

export const PendingProjectApprovalsTablePm = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    addOnFilter = {},
  } = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {
    projectDetail,
    editProject,
    editProjectRouteForNonAdmin,
    activityLogDetail,
  } = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      {...props}
      limit={500}
      search={searchValue}
      addOnFilter={{owner_id: employeeId?._id, ...addOnFilter}}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/pending-approvals'}
      sort={{createdAt: -1}}
      columns={[project({projectDetail}), PROJECTTYPE, CUSTOMER, CSM]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProjectRouteForNonAdmin,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
      ]}
    />
  );
};

export const PendingProjectApprovalsTableAm = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    addOnFilter = {},
  } = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const {
    projectDetail,
    editProject,
    editProjectRouteForNonAdmin,
    activityLogDetail,
  } = useProjectActions({
    navigation,
    params,
  });

  return (
    <Table
      {...props}
      limit={500}
      search={searchValue}
      addOnFilter={{account_owner: employeeId?._id, ...addOnFilter}}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/pending-approvals'}
      sort={{createdAt: -1}}
      columns={[project({projectDetail}), PROJECTTYPE, CUSTOMER, CSM]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProjectRouteForNonAdmin,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
      ]}
    />
  );
};
