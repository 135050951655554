import React from 'react';

import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {Button} from '../../../components/button/Button';
import {
  BacklogFeaturesTable,
  ProjectReleasedFeaturesTable,
  RoadMapTable,
} from './FeaturesTable';
import {GroupBy} from '../../../components/table/GroupBy';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {Labels} from '../constants/Label';
import {FeatureStatus, QAStages} from '../constants/FeatureStages';
import {BorderLessButton} from '../../../components/button/AddButton';
import {useFetchData} from '../../../controllers/useFetchData';
import {filterProjectEnvironments} from '../utility';
import {OutlineButton} from '../../../components/button/OutlineButton';
import {useTheme} from '@unthinkable/react-theme';

export const RoadmapTabs = props => {
  const {
    route: {params} = {},
    navigation,
    addOnFilter = {},
    fromProject,
    fromModule,
  } = props;
  const {project, milestone, module, projectlibrary_id} = params;
  const {project_environments} = project;

  const {colors} = useTheme();

  const filteredEnvironments = filterProjectEnvironments({
    project_environments,
  });

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );
  const groupBy = useGroupBy({
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          title: 'module',
          data: '_children',
          defaultExpanded: true,
        },
      },
      !params?.milestone?._id && {
        label: 'Milestone',
        field: 'milestone_id',
        aggregates: {index: 'sum'},
        groupRow: {
          title: 'milestone',
          data: '_children',
          defaultExpanded: true,
        },
      },
    ],
  });
  let {filter, params: {sub_status, ...filterParams} = {}} = filterValues || {};

  const countFilter = {
    project_id: project?._id,
    milestone_id: milestone?._id,
    module_id: module?._id,
    status: 'active',
    library_id: projectlibrary_id,
    ...filter,
    ...addOnFilter,
  };

  filter = {
    ...filter,
    ...addOnFilter,
  };

  const countProps = {
    api: `/features/count`,
    search: searchValue,
    searchFields: ['feature', 'code'],
    params: filterParams,
    eventSourceId: 'Feature',
  };

  const environmentTabs = {};

  filteredEnvironments?.forEach(element => {
    environmentTabs[element.environment] = {
      apiKey: 'active',
      label: element.label,
      view: (
        <RoadMapTable
          groupBy={groupBy}
          stage={element.environment}
          searchValue={searchValue}
          filter={{
            ...filter,
            status:
              element.environment === 'development'
                ? 'active'
                : element.environment,
            library_id: projectlibrary_id,
          }}
          filterParams={filterParams}
        />
      ),
      ...countProps,
      filter: {
        ...countFilter,
        status:
          element.environment === 'development'
            ? 'active'
            : element.environment,
      },
    };
  });

  const tabs = {
    backlog: {
      label: FeatureStatus.backlog.label,
      view: (
        <BacklogFeaturesTable
          groupBy={groupBy}
          searchValue={searchValue}
          filter={{
            ...filter,
            status: FeatureStatus.backlog.value,
            sub_status: null,
            library_id: projectlibrary_id,
          }}
          filterParams={filterParams}
        />
      ),
      ...countProps,
      filter: {
        ...countFilter,
        status: FeatureStatus.backlog.value,
        sub_status: null,
      },
    },
    architecture: {
      label: FeatureStatus.architecture.label,
      view: (
        <BacklogFeaturesTable
          selectedTab={'architecture'}
          groupBy={groupBy}
          searchValue={searchValue}
          filter={{
            ...filter,
            status: FeatureStatus.backlog.value,
            sub_status: 'Architecture',
            library_id: projectlibrary_id,
          }}
          filterParams={filterParams}
        />
      ),
      ...countProps,
      filter: {
        ...countFilter,
        status: FeatureStatus.backlog.value,
        sub_status: 'Architecture',
      },
    },
    development: {
      apiKey: 'active',
      label: FeatureStatus.active.label,
      view: (
        <RoadMapTable
          groupBy={groupBy}
          stage={FeatureStatus.active.value}
          searchValue={searchValue}
          filter={{
            ...filter,
            status: FeatureStatus.active.value,
            library_id: projectlibrary_id,
          }}
          filterParams={filterParams}
        />
      ),
      ...countProps,
      filter: {
        ...countFilter,
        status: FeatureStatus.active.value,
      },
    },
    qa: {
      apiKey: 'active',
      label: FeatureStatus.qa.label,
      view: (
        <RoadMapTable
          groupBy={groupBy}
          stage={FeatureStatus.qa.value}
          searchValue={searchValue}
          filter={{
            ...filter,
            status: FeatureStatus.qa.value,
            library_id: projectlibrary_id,
          }}
          filterParams={filterParams}
        />
      ),
      ...countProps,
      filter: {
        ...countFilter,
        status: FeatureStatus.qa.value,
      },
    },

    ...environmentTabs,

    released: {
      label: FeatureStatus.released.label,
      view: (
        <ProjectReleasedFeaturesTable
          searchValue={searchValue}
          fromProject={fromProject}
          fromModule={fromModule}
          filter={{
            ...filter,
            status: FeatureStatus.released.value,
            library_id: projectlibrary_id,
          }}
          filterParams={filterParams}
        />
      ),
      ...countProps,
      filter: {
        ...countFilter,
        status: FeatureStatus.released.value,
      },
    },
  };

  const {data: archiveFeatureData} = useFetchData({
    api: '/features/archive',
    filter: {
      module_id: module?._id,
      project_id: project?._id,
      library_id: projectlibrary_id,
    },
    eventSourceId: 'Feature',
    count: true,
  });

  return (
    <TabView
      selectedTab={'development'}
      tabs={tabs}
      params={params}
      {...props}
      actions={({selectedTab}) => {
        let environment;
        if (selectedTab === 'released') {
          environment = 'production';
        } else {
          environment = selectedTab;
        }
        return [
          !(selectedTab === 'architecture' || selectedTab === 'backlog') && (
            <OutlineButton
              text={'Deploy'}
              borderColor={colors.OUTLINE}
              textColor={colors.NEUTRAL_MEDIUM}
              hoverContainerStyle={{borderColor: colors.UPPER_OUTLINE}}
              onPress={() => {
                navigation.navigate('repositories-list', {
                  ...params,
                  environment,
                });
              }}
            />
          ),
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          selectedTab !== 'released' && !projectlibrary_id && (
            <GroupBy {...groupBy} />
          ),
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                label: 'Assignee',
                field: 'assigned_to',
                api: `/projects/${project?._id}/members`,
                placeholder: 'Select',
                suggestionField: 'name',
                secondarySuggestionField: 'email',
                valueField: 'name',
                avatar: true,
                asParam: true,
              },
              !fromModule &&
                !projectlibrary_id && {
                  type: 'multiAutoComplete',
                  label: 'Module',
                  api: '/projectmodules',
                  placeholder: 'Select',
                  suggestionField: 'module',
                  valueField: 'module',
                  field: 'module_id',
                  filter: {project_id: project?._id, status: 'active'},
                },
              !fromModule &&
                !projectlibrary_id && {
                  type: 'autoComplete',
                  label: 'Module Owner',
                  api: `/projects/${project?._id}/members`,
                  placeholder: 'Select',
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                  field: 'module_owner',
                  avatar: true,
                  asParam: true,
                },
              !params?.milestone?._id &&
                !projectlibrary_id && {
                  type: 'autoComplete',
                  label: 'Milestone',
                  api: '/projectmilestones',
                  placeholder: 'Select',
                  suggestionField: 'milestone',
                  valueField: 'milestone',
                  field: 'milestone_id',
                  filter: {project_id: project?._id},
                },
              !module?._id &&
                !params?.milestone?._id &&
                !projectlibrary_id && {
                  type: 'autoComplete',
                  label: 'Type',
                  options: [
                    {label: 'Project', value: {$exists: false}},
                    {label: 'UI Library', value: 'ui-library'},
                    {label: 'Server Library', value: 'server-library'},
                  ],
                  keyField: 'value',
                  valueField: 'label',
                  placeholder: 'Select',
                  field: 'category',
                  suggestionField: 'label',
                },
              selectedTab === 'qa' && {
                type: 'autoComplete',
                label: 'Stage',
                placeholder: 'Select',
                options: QAStages,
                suggestionField: 'label',
                keyField: 'value',
                valueField: 'label',
                field: 'sub_status',
              },
            ]}
          />,
          selectedTab === 'backlog' && (
            <Button
              text={`Add ${Labels.Feature}`}
              onPress={() => {
                navigation.navigate('add-feature', {
                  ...params,
                  status: 'backlog',
                });
              }}
            />
          ),
          fromModule && (
            <BorderLessButton
              text={`Archived (${archiveFeatureData?.length || 0})`}
              onPress={() => {
                navigation.navigate('archived-feature-screen', {
                  ...params,
                });
              }}
            />
          ),
        ];
      }}
    />
  );
};
