import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {RPSTableTabView} from './RPSTableTabView';
import {CustomPlatformMergedTabs} from '../../customPlatformNewView/view/customPlatformMergedTab';
import {OpportunityAnalyticsTable} from '../../opportunityAnalytics/views/OpportunityAnalyticsDashboard';
import {EngineeringOpportunitiesTabView} from '../../engineeringOpportunities/views/EngineeringOpportunitiesTabs';
import {OpportunityProjectionListTeam} from '../../opportunityProjection/views/opportunityProjectionLists';
import {AllOpportunitiesTab} from '../../allOpportunities/views/AllOpportunitiesTab';
import {RfrTatTabs} from '../../employee/views/RfrTATTabs';
import {
  FixedOpportunityProjectionTab,
  TnMOpportunityProjectionTab,
} from '../../opportunityProjection/views/opportunityProjectionTabs';

const OpportunityMenu = createMenuNavigator();

const OpportunityNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});

  return (
    <OpportunityMenu.Navigator
      canAccess={canAccess}
      data-navigator-id="OpportunityGroup"
      {...props}>
      <OpportunityMenu.Screen
        data-resource-id="AllOpportunities"
        component={AllOpportunitiesTab}
        options={{
          label: 'All',
        }}
      />
      {/* <OpportunityMenu.Screen
        data-resource-id="RPS"
        component={RPSTableTabView}
        options={{
          label: 'RPS',
        }}
      /> */}
      <OpportunityMenu.Screen
        data-resource-id="CustomPlatform"
        component={CustomPlatformMergedTabs}
        options={{
          label: 'Custom Platform',
        }}
      />
      {/* <OpportunityMenu.Screen
        data-resource-id="StaffAgumentation"
        component={EngineeringOpportunitiesTabView}
        options={{
          label: 'Staff agumentation',
        }}
      /> */}
      <OpportunityMenu.Screen
        data-resource-id="StaffAgumentation"
        name="Staffaugmentation Opp"
        options={{
          label: 'Staff augmentation',
          onPress: () => {
            props.navigation.navigate('staffaugmentation-menu', {});
          },
        }}
      />
      {/* <OpportunityMenu.Screen
        data-resource-id="StaffAugmentationOpportunities"
        name="Interview Success"
        options={{
          label: 'Interview Success',
          onPress: () => {
            props.navigation.navigate('success-reports-menu', {});
          },
        }}
      />
      <OpportunityMenu.Screen
        data-resource-id="StaffAugmentationOpportunities"
        name="Interview Scheduled"
        options={{
          label: 'Interview Scheduled',
          onPress: () => {
            props.navigation.navigate('scheduled-success-reports-menu', {});
          },
        }}
      /> */}
      {/* <OpportunityMenu.Screen
        data-resource-id="StaffAugmentationOpportunities"
        name="Fulfilment TAT"
        component={RfrTatTabs}
        props={{user: user, ...props}}
        options={{
          label: 'Fulfilment TAT',
        }}
      /> */}
      <OpportunityMenu.Screen
        data-resource-id="Analytics"
        component={OpportunityAnalyticsTable}
        options={{
          label: 'Analytics',
        }}
      />
      {/* <OpportunityMenu.Screen
        data-resource-id="Projections"
        component={OpportunityProjectionListTeam}
        options={{
          label: 'Projections',
        }}
      /> */}
      <OpportunityMenu.Section
        options={{
          label: 'Projected Pipeline',
        }}>
        <OpportunityMenu.Screen
          data-resource-id="T&MPipeline"
          component={TnMOpportunityProjectionTab}
          options={{
            label: 'T&M',
          }}
        />
        <OpportunityMenu.Screen
          data-resource-id="FixedPipeline"
          component={FixedOpportunityProjectionTab}
          options={{
            label: 'Fixed',
          }}
        />
      </OpportunityMenu.Section>
    </OpportunityMenu.Navigator>
  );
};
export default OpportunityNavigator;
