import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {AttendanceTab} from '../../leadGeneration/views/attendance-views/AttendanceTab';
import {
  AttendanceChangeRequestHrTabs,
  LeaveRequestHrTabs,
  TimesheetRequestHrTabs,
  WFHRequestHrTabs,
} from './EmployeeSetupTabs';

export const EmployeeSetupMenu = props => {
  const menus = [
    {
      label: 'Attendance',
      view: <AttendanceTab />,
    },
    {
      label: 'Leave Request',
      view: <LeaveRequestHrTabs />,
    },
    {
      label: 'WFH Request',
      view: <WFHRequestHrTabs />,
    },
    {
      label: 'Attendance Change Request',
    view: <AttendanceChangeRequestHrTabs />,
    },
    {
      label: 'Timesheet Requests',
      view: <TimesheetRequestHrTabs />,
    },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
