import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const TaskDisqualificationForm = props => {
  const {
    route: {params},
  } = props;

  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projecttasks',
    eventSourceId: 'Task',
  });

  return (
    <Form
      header={{title: 'Task', secondaryTitle: row.name}}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{_id: row?._id, disqualified: true}}
      layoutFields={[
        {
          label: 'Disqualification Reason',
          field: 'disqualify_reason',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
