import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const KPIInputForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/keyperformanceindicators',
    eventSourceId: 'keyperformanceindicators',
    ...props,
  });
  return (
    <Form
      api={`/keyperformanceindicators/${row?._id}`}
      fields={{
        kra_id: {name: 1},
        name: 1,
        description: 1,
        api: {name: 1},
        entity: {label: 1},
        role: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add Key Performance Indicator'}}
      layoutFields={[
        {
          label: 'KRA',
          field: 'kra_id',
          type: 'autoComplete',
          api: `/keyresponsibilityareas`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'name',
          size: 'medium',
          required: true,
        },
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        {
          label: 'Description',
          type: 'text',
          field: 'description',
          required: true,
        },
        // {
        //   label: 'Entity',
        //   field: 'entity',
        //   type: 'autoComplete',
        //   api: `/types`,
        //   suggestionField: 'label',
        //   size: 'medium',
        // },
        // {
        //   label: 'Role',
        //   field: 'role',
        //   type: 'autoComplete',
        //   api: '/role/modifiedData',
        //   suggestionField: 'role',
        //   valueField: 'role',
        // },
        {
          label: 'API',
          field: 'api',
          type: 'autoComplete',
          api: `/apis`,
          suggestionField: 'name',
          valueField: 'name',
          displayField: 'name',
          size: 'medium',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditKPIInputForm = props => {
  return (
    <KPIInputForm
      header={{title: 'Edit Key Performance Indicator'}}
      mode="edit"
      {...props}
    />
  );
};
