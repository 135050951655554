import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const deleteDeliveryOwner = ({invoke}) => ({
  title: 'Delete',
  confirm: {
    title: 'Delete Delivery Owner',
    message: 'Do you want delete delivery owner for this data?',
  },
  onPress: props => {
    const {row = {}} = props;
    invoke({
      uri: `/employeeassociations/${row?._id}`,
    });
  },
});

export const EmployeeDeliveryOwnerTable = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'employeeassociation',
  });
  return (
    <Table
      eventSourceId={['employeeassociation']}
      api={`/employeeassociations`}
      fields={{
        delivery_owner: {name: 1, color: 1, official_email_id: 1},
        from_date: 1,
        to_date: 1,
      }}
      addOnFilter={{employee: row?._id, delivery_owner: {$exists: true}}}
      renderHeader={() => {
        return (
          <TableHeader
            title="Delivery Owner"
            secondaryTitle={`${row?.name}`}
            actions={[
              <AddButton
                title="Delivery owner"
                view="add-delivery-owner"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.delivery_owner?.name}
                color={row?.delivery_owner?.color}
                official_email_id={row?.delivery_owner?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Start Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'End Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
      ]}
      moreActions={[deleteDeliveryOwner({invoke})]}
    />
  );
};
