import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupTable} from '../../../components/table/Table';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/table/Headers';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {GroupFilter} from '../../../components/filter/Filters';

const employeeTypeFilters = [
  {
    label: 'Employee Type',
    field: 'employee_level',
    placeholder: 'Select Employee Type',
    type: 'autoComplete',
    options: [
      {value: 'include_manager', label: 'All'},
      {value: 'exclude_manager', label: 'Exclude Managers'},
      {value: 'only_manager', label: 'Only Managers'},
    ],
    suggestionField: 'label',
    keyField: 'value',
    valueField: 'label',
  },
];
let kpiName = [
  'Tech Lead',
  'Module Lead',
  'Anchor Resource',
  'Front Facing Resource',
];

const DepartmentKPITable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const filtersInfo = useFilter();
  let {filterValues: {filter = {}} = {}} = filtersInfo || {};
  const {onChangeFilter, filterValues = {}} = useFilter({
    params: {period: params.period || getPreviousMonth()},
  });
  const {params: filterParams} = filterValues || {};
  let {period} = filterParams || {};
  return (
    <GroupTable
      api={`/department-outcomes-api`}
      params={{
        period: period,
        department: params?.row?._id,
        employee_level: filter?.employee_level,
      }}
      renderHeader={props => (
        <TableHeader
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter {...filtersInfo} filters={employeeTypeFilters} />,
          ]}
          {...props}
        />
      )}
      defaultExpanded={true}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.role} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'KPI',
          type: 'text',
          field: 'kpi_id.name',
          responsive: 'sm',
        },
        {
          header: 'Target',
          type: 'text',
          field: 'outCome.target_value',
          responsive: 'sm',
          align: 'right',
        },
        {
          header: 'Outcome',
          type: 'text',
          field: 'outCome.outcome',
          responsive: 'sm',
          onPress: ({row}) => {
            row?.api?.view ? navigation.navigate(row.api.view, row) : null;
          },
          align: 'right',
        },
      ]}
      moreActions={({row}) => {
        return [
          !kpiName.includes(row?.kpi_id?.name) && {
            title: 'KPIs by Skill',
            onPress: ({row}) => {
              navigation.navigate('view-drill-by-skills', {
                row,
                period,
                resourceFullfillment: true,
                employee_level: filter?.employee_level,
              });
            },
          },
          {
            title: 'KPIs by Role',
            onPress: ({row}) => {
              navigation.navigate('view-drill-by-roles', {
                row,
                period,
                resourceFullfillment: true,
                employee_level: filter?.employee_level,
              });
            },
          },
        ];
      }}
    />
  );
};

export default DepartmentKPITable;
