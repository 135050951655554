import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {CountButton} from '../../../app-components/renders/countButton/CountButton';
import {useFetchData} from '../../../controllers/useFetchData';

export const ObjectWiseTasks = props => {
  const {navigation, object, params} = props;
  const {product} = params;
  const icons = useTheme('icons');
  const taskIcon = icons['Tasks'];
  const {data: tasks} = useFetchData({
    api: '/projectTasks',
    filter: {
      product: product?._id,
      source: object?._id,
    },
    count: true,
  });
  const count = tasks?.count || 0;
  const onPress = () => {
    navigation.navigate('tasks', {
      ...params,
      source: object,
      filter: {
        product: product?._id,
        source: object?._id,
      },
    });
  };
  return (
    <CountButton
      label="Tasks"
      onPress={onPress}
      icon={taskIcon}
      count={count}
      {...props}
    />
  );
};

export const objectTasksMoreAction = ({
  navigation,
  params,
  source,
  titleField,
}) => {
  return {
    title: 'Tasks',
    onPress: ({row}) => {
      navigation.navigate('custom-tasks', {
        ...params,
        source: source,
        source_id: row._id,
        secondaryTitle: row[titleField],
        filter: {
          product: params?.product?._id,
          source: source?._id,
          source_id: row._id,
        },
      });
    },
  };
};
