import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdatePassword = props => {
  const {header = 'Update Password', route: {params = {}} = {}} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/update-linkedin-profile-password',
    eventSourceId: ['updatePassword'],
  });

  return (
    <Form
      fields={{
        password: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{...params}}
      layoutFields={[
        {
          field: 'password',
          type: 'password',
          label: 'Password',
          placeholder: 'Password',
        },
      ]}
      {...props}
    />
  );
};
