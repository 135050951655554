import React from 'react';
import { Form } from '../../../../components/form/Form';
import { FormErrorConfirmWrapper } from '../../../../components/form/FormWrapper';
import { useFormSubmit } from '../../../../controllers/useSubmitForm';

const StopAllocation = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });

  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        header="Stop Allocation"
        api={`/employeeassignments/${row._id}`}
        fields={{
          hours: 1,
          percentage: 1,
          is_applicable_for_ma: 1,
          employee: {_id: 1, name: 1, color: 1},
          from_date: 1,
          to_date: 1,
          object: 1,
          product: 1,
          project_id: {_id: 1, project: 1},
          department: {_id: 1, name: 1},
          skill_metrics: {_id: 1, name: 1},
          project_skill: {_id: 1, name: 1},
          resource_skill: {_id: 1, name: 1},
          tool: {_id: 1, name: 1},
          resource_tool: {_id: 1, name: 1},
          front_facing_resource: {_id: 1, name: 1},
          level_one_owner: {_id: 1, name: 1},
          level_two_owner: {_id: 1, name: 1},
          level_three_owner: {_id: 1, name: 1},
          order: {_id: 1, order_number: 1},
          applicable_for_daily_incentive: 1,
          is_applicable_for_ma: 1,
          allow_allocation_more_than_100: 1,
          role: 1,
        }}
        mode="edit"
        onSubmit={onSubmit}
        submitAction={'Save'}
        layoutFields={[
          {
            label: 'Role',
            type: 'radioGroup',
            field: 'role',
            options: ['IC', 'Manager'],
            readOnly: true,
            size: 6,
          },
          {
            label: 'Front Facing Resource',
            placeholder: 'Front Facing Resource',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
            readOnly: true,
          },
          {
            label: 'Start Date',
            placeholder: 'Start Date',
            field: 'from_date',
            type: 'date',
            size: 4,
            readOnly: true,
          },
          {
            label: 'End Date',
            placeholder: 'End Date',
            field: 'to_date',
            type: 'date',
            size: 4,
            validate: value => {
              if (new Date(value) > new Date(row.to_date)) {
                return "End Date can't be greater than Previous";
              }
            },
          },
          {
            label: 'Hours',
            placeholder: 'Hours',
            field: 'hours',
            type: 'number',
            size: 4,
            readOnly: true,
          },
          {
            label: 'Project',
            placeholder: 'Project',
            field: 'project_id',
            type: 'autoComplete',
            api: `/projects`,
            suggestionField: 'project',
            valueField: 'project',
            size: 4,
            readOnly: true,
          },
          {
            label: 'Project Skill',
            placeholder: 'Project Skill',
            field: 'tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            size: 4,
            readOnly: true,
          },
          {
            label: 'Resource Skill',
            placeholder: 'Resource Skill',
            field: 'resource_tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            size: 4,
            readOnly: true,
          },
          {
            type: 'switch',
            label: 'Shift allowance',
            field: 'applicable_for_daily_incentive',
            size: 6,
            readOnly: true,
          },
          {
            type: 'number',
            label: 'Amount',
            field: 'daily_incentive',
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            readOnly: true,
            size: 6,
          },
          {
            type: 'switch',
            label: 'Allow More Than 9 Hours',
            field: 'allow_allocation_more_than_100',
            readOnly: true,
            size: 6,
          },
          {
            size: 6,
            groups: [
              {
                defaultHidden: true,
                label: 'Leader',
                fields: [
                  {
                    label: 'Skill Leader',
                    placeholder: 'Skill Leader',
                    field: 'level_one_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    readOnly: true,
                    size: 4,
                  },
                  {
                    label: 'Project Owner',
                    placeholder: 'Project Owner',
                    field: 'level_two_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    readOnly: true,
                    size: 4,
                  },
                ],
              },
            ],
          },
        ]}
        eventSourceId={['allocatedResource']}
        {...props}
      />
    </FormErrorConfirmWrapper>
  );
};

export default StopAllocation;
