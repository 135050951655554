import React from 'react';
import {Table} from '../../../components/table/Table';
import {useTheme} from '@unthinkable/react-theme';
import {TableHeader} from '../../../components/table/Headers';
import {TextRenderer} from '../../../components/form/Renderers';
import {Text} from '@unthinkable/react-core-components';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {leadControllers} from '../controllers/leadControllers';

const DocumentCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Documents',
  align: 'right',
  count_field: 'document_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate(`fine-tune-document-list`, {
      row,
    });
  },
});

const JobCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Jobs',
  align: 'right',
  count_field: 'job_count',
  responsive: 'sm',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`fine-tune-job-list`, {
      filter: {
        usecase_id: row?._id,
      },
    });
  },
});

export const FinetuneUsecasesList = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {
    editFinetuneUsecase,
    useJobCreate,
    useDuplicateUseCase,
    deleteUseCase,
  } = leadControllers(props);

  let {addOnFilter = {}} = params || {};

  return (
    <Table
      api={`/finetuneusecases/all`}
      eventSourceId={[
        'useCaseAdded',
        'addJob',
        'addDocument',
        'removeDocument',
        'removeUseCase',
      ]}
      params={params}
      filter={addOnFilter}
      columns={[
        {
          field: 'name',
          type: 'text',
          header: 'Name',
        },
        {
          field: 'reference_model',
          type: 'text',
          header: 'Reference Model',
        },
        DocumentCount({navigation, params}),
        JobCount({navigation, params}),
        {
          field: 'output_model',
          type: 'text',
          header: 'Output Model',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editFinetuneUsecase,
        },
        {
          title: 'Add Job',
          onPress: useJobCreate,
        },
        {
          title: 'Duplicate Usecase',
          onPress: useDuplicateUseCase,
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: deleteUseCase,
        },
      ]}
      {...props}
    />
  );
};
