import React from 'react';

import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {ComprehensiveTasks} from '../../task/views/TasksTable';

export const ComprehensiveTaskTab = props => {
  const {route: {params} = {}, typeId} = props;
  const {project} = params;

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );

  const tabs = {
    active: {
      label: 'Active',
      view: (
        <ComprehensiveTasks
          addOnParams={{
            status: 'active',
            searchValue,
            groupFilters: filterValues.filter,
          }}
          typeId={typeId}
        />
      ),
    },
    completed: {
      label: 'Completed',
      view: (
        <ComprehensiveTasks
          addOnParams={{
            status: 'completed',
            searchValue,
            groupFilters: filterValues.filter,
          }}
          typeId={typeId}
        />
      ),
    },
  };

  return (
    <TabView
      selectedTab={'active'}
      tabs={tabs}
      params={params}
      {...props}
      actions={() => {
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                label: 'Assignee',
                api: `/projects/${project._id}/members`,
                placeholder: 'Select',
                suggestionField: 'name',
                valueField: 'name',
                field: 'assigned_to',
              },
            ]}
          />,
        ];
      }}
    />
  );
};
