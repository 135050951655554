import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const OutreachCampaignUSPForm = props => {
  const {
    route: {params},
    header = 'Add USP',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignusps',
    eventSourceId: ['campaignUSPAdded'],
  });
  const {campaign_id} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      defaultValues={{campaign_id}}
      layoutFields={[
        {
          label: 'USP',
          field: 'usp_id',
          type: 'autoComplete',
          api: '/uniquesellingpoints',
          placeholder: 'USP',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
