import React from 'react';
import {Form, MultiStepForm} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddTrainingProgram = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/trainingPrograms',
    eventSourceId: 'TrainingPrograms',
    ...props,
  });

  const {
    route: {params},
  } = props;
  const {product} = params;

  return (
    <Form
      onSubmit={onSubmit}
      computations={{
        skill_leader: [
          value => {
            return value?.skill_metrics?.leader;
          },
          ['skill_metrics'],
        ],
      }}
      header="Add Skill Course"
      defaultValues={{
        product,
      }}
      layoutFields={[
        {
          label: 'Course Name',
          field: 'name',
          type: 'text',
          required: true,
        },

        {
          label: 'Course Duration (In days)',
          field: 'course_duration',
          type: 'number',
          required: true,
          size: 'medium',
        },
        {
          label: 'Skill Type',
          field: 'course_type',
          type: 'autoComplete',
          api: '/skillTypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 'medium',
        },
        {
          label: 'Skills',
          field: 'tools',
          type: 'multiAutoComplete',
          api: '/tools',
          suggestionField: 'name',
          valueField: 'name',
          size: 'medium',
          filter: ({values}) => {
            return {tool_type: values?.course_type?._id};
          },
        },
        {
          label: 'Objective',
          field: 'description',
          type: 'textArea',
        },
      ]}
      {...props}
    />
  );
};

export const DuplicateTrainingProgram = props => {
  const {
    route: {params},
  } = props;
  const {training_program, product} = params;
  const {_id, ...restTrainingProgram} = training_program;
  return (
    <AddTrainingProgram
      {...props}
      defaultValues={{
        ...restTrainingProgram,
        product,
      }}
    />
  );
};
export const EditTrainingProgram = props => {
  const {
    route: {params},
  } = props;
  const {training_program} = params;
  return (
    <AddTrainingProgram
      header={'Edit Skill Course'}
      fields={{
        name: 1,
        description: 1,
        skill_metrics: {
          name: 1,
          leader: {
            name: 1,
            official_email_id: 1,
          },
          tools: 1,
        },
        skill_leader: {
          name: 1,
          official_email_id: 1,
        },
        training_duration: 1,
        feedback_cycle: 1,
        course_type: {
          name: 1,
        },
        course_duration: 1,
        is_attendance_required: 1,
        tools: {
          name: 1,
        },
      }}
      mode="edit"
      api={'/trainingPrograms/' + training_program?._id}
      {...props}
    />
  );
};

const AddTopicWiseDuration = props => {
  return (
    <InlineForm
      layoutFields={[
        {
          field: 'topic',
          type: 'autoComplete',
          api: '/skillTopics',
          suggestionField: 'name',
          valueField: 'name',
          label: 'Topics',
          filter: ({values}) => {
            return {
              _id: {$in: values?.skill_metrics?.topics || []},
            };
          },
        },
        {
          field: 'training_duration',
          type: 'number',
          label: 'Training Hours',
          size: 'medium',
        },
      ]}
    />
  );
};

export const PlanSkillTraining = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {training_program} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/trainingPrograms',
    eventSourceId: 'TrainingPrograms',
    ...props,
  });

  return (
    <MultiStepForm
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'active'}};
      }}
      onSubmit={onSubmit}
      header="Plan Course"
      submitAction="Mark Active"
      mode="edit"
      api={'/trainingPrograms/' + training_program?._id}
      fields={{
        training_type: 1,
        training_source: 1,
        attachment: 1,
        skill_metrics: 1,
        training_roadmap: {
          skill_topic: {
            name: 1,
          },
          topic_duration: 1,
        },
        course_type: {
          name: 1,
        },
        feedback_criteria: {
          criteria: {name: 1},
          weightage: 1,
        },
      }}
      steps={[
        {
          title: 'Course Details',
          fields: [
            {
              type: 'text',
              field: 'training_source',
              label: 'Link To Course Content',
              size: 8,
            },
            {
              type: 'file',
              field: 'attachment',
              placeholder: 'Attachment',
              size: 4,
            },
            {
              fields: [
                {
                  field: 'training_roadmap',
                  nested: true,
                  render: props => {
                    const {
                      form: {values},
                    } = props;
                    return (
                      <NestedTable
                        key={values?.skill_metrics?._id}
                        inlineAddForm
                        fields={[
                          {
                            label: 'Topics',
                            field: 'skill_topic',
                            type: 'autoComplete',
                            api: '/skilltopics',
                            suggestionField: 'name',
                            valueField: 'name',
                            filter: () => {
                              return {
                                skill_metrics: values?.skill_metrics,
                              };
                            },
                            size: 8,
                            required: true,
                          },
                          {
                            label: 'Duration (In days)',
                            field: 'topic_duration',
                            type: 'number',
                            size: 3,
                          },
                        ]}
                        columns={[
                          {
                            header: 'Topics',
                            field: 'skill_topic.name',
                            type: 'text',
                          },
                          {
                            header: 'Duration',
                            field: 'topic_duration',
                            type: 'number',
                          },
                        ]}
                        {...props}
                      />
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Feedback Criteria',
          fields: [
            {
              field: 'feedback_criteria',
              nested: true,
              render: props => {
                const {
                  form: {values},
                } = props;
                return (
                  <NestedTable
                    key={values?.course_type}
                    inlineAddForm
                    fields={[
                      {
                        field: 'criteria',
                        type: 'autoComplete',
                        api: '/feedbackCriteria',
                        suggestionField: 'name',
                        label: 'Criteria',
                        placeholder: 'Criteria',
                        filter: {
                          type: values?.course_type,
                        },
                        size: 8,
                        onCreate: () => {
                          navigation.navigate('add-feedback-criteria', {
                            training_program: values,
                          });
                        },
                      },
                      {
                        field: 'weightage',
                        type: 'number',
                        label: 'Weightage',
                        size: 3,
                      },
                    ]}
                    columns={[
                      {
                        field: 'criteria.name',
                        type: 'text',
                        header: 'Criteria',
                      },
                      {
                        field: 'weightage',
                        type: 'number',
                        header: 'Weightage',
                      },
                    ]}
                    {...props}
                  />
                );
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
