import React from 'react';
import {GroupTable} from '../../../components/table/Table';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {LinkRenderer, TextRenderer} from '../../../components/form/Renderers';
import {PrNumberLinkRender} from '../../../app-components/renders/PRRenders';
import {Avatar} from '../../../components/avatar/Avatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {useTheme} from '@unthinkable/react-theme';

const NoOfFileChangeRender = ({row}) => {
  const {linesAdded, linesRemoved, noOfFiles} = row || {};

  return (
    <RowWithSeparator style={{gap: 6, alignItems: 'center'}}>
      <TextRenderer value={`Files : ${noOfFiles}`} />
      <TextRenderer value={`Lines added : ${linesAdded}`} />
      <TextRenderer value={`Lines removed : ${linesRemoved}`} />
    </RowWithSeparator>
  );
};

export const PullRequestRender = ({row = {}}) => {
  const {ArrowSquareOut} = useTheme('icons');
  return <LinkRenderer value={row.pullrequest_link} icon={ArrowSquareOut} />;
};

export const CodeAnalysisTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {
    feature: {_id: feature_id},
  } = params;

  return (
    <GroupTable
      api={`/features/${feature_id}/codeAnalysis`}
      onRowPress={({row}) => {
        const {branch_id, parent_branch_id} = row;
        const branch = `${branch_id?.branch_name} --> ${parent_branch_id?.branch_name}`;

        navigation.navigate('pr-file-changed-render', {
          ...params,
          fileChangedArray: row.files_changed,
          title: row.title,
          secondaryTitle: branch,
        });
      }}
      columns={[
        {
          render: PrNumberLinkRender,
          width: 80,
        },
        {
          render: Avatar,
          align: 'center',
          width: 70,
          size: 30,
        },
        {
          render: ({row}) => {
            const {title, branch_id, parent_branch_id} = row;
            const branch = `${branch_id?.branch_name} --> ${parent_branch_id?.branch_name}`;
            return (
              <PrimaryColumnCard
                numberOfLines={1}
                primaryTitle={title}
                items={[
                  {
                    value: branch,
                    numberOfLines: 1,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Files',
          field: 'noOfFiles',
          align: 'center',
          width: 80,
        },
        {
          header: 'Added',
          field: 'linesAdded',
          align: 'center',
          width: 120,
        },
        {
          header: 'Removed',
          field: 'linesRemoved',
          align: 'center',
          width: 100,
        },
        {
          render: PullRequestRender,
          width: 30,
        },
      ]}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {repositoryLabel} = row || {};
          return <TextRenderer value={repositoryLabel} />;
        },
        rightContent: NoOfFileChangeRender,
      }}
    />
  );
};
