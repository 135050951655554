import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AssignTask = props => {
  const {
    route: {params},
    fromMyView,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: ['Task'],
    ...props,
  });
  const {row, thread = {}, delegatedTask} = params;
  const {user} = useAppStateContext();
  return (
    <Form
      {...props}
      header={{title: 'Assign Task', secondaryTitle: row?.name}}
      onSubmit={onSubmit}
      defaultValues={{
        _id: row._id,
        reviewers: row.reviewers,
        due_date: row.due_date,
        assigned_to: {_id: user._id, name: user.name},
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Assigned To',
          placeholder: 'Assigned To',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          visible: () => !fromMyView,
          required: true,
          validate: (value, {values} = {}) => {
            if (!value) {
              return;
            }

            let result;

            if (Array.isArray(values?.reviewer)) {
              result = values?.reviewer?.find(item => {
                return item?._id === value?._id;
              });
            } else {
              result = values?.reviewer?._id === value?._id;
            }
            if (result) {
              return 'Reviewer cannot be an assignee';
            }
          },
        },
        (!thread?._id && !delegatedTask ? {
          label: 'Reviewers',
          field: 'reviewer',
          type: 'multiAutoComplete',
          api: `/usersuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          required: !row?.review_not_required,
          valueField: 'name',
          params: {project_id: params?.project_id || params?.project?._id},
          validate: (value, {values} = {}) => {
            const {assigned_to, team} = values;
            if (value?.length) {
              const result = value?.find(item => {
                if (item?._id) {
                  if (assigned_to?._id) {
                    return item?._id === assigned_to._id;
                  } else if (team?.length) {
                    return team.find(teamItem => teamItem?._id === item?._id);
                  }
                }
              });
              if (result) {
                return 'Assignee and reviewer must be different';
              }
            }
          },
          visible: !row?.reviewer?.length,
        }: void 0),
        {label: 'Due Date', type: 'date', field: 'due_date'},
      ]}
    />
  );
};
