import React, {useMemo} from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Table} from '../table/Table';
import {Form, InlineForm} from './Form';
import {WithModal} from '../modal/WithModal';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {NestedActionStyles} from './theme';
import {isMobile} from '@unthinkable/react-utils';
import {InputWrapper} from '@unthinkable/react-input';
import {useFormContext} from '@unthinkable/react-form';

const NestedEditForm = ({
  children,
  replace,
  index,
  row,
  fields,
  modalProps,
  computations,
  _parentValues: __parentValues,
  editFormHeader,
  readOnly,
}) => {
  let header = editFormHeader || (readOnly ? 'Detail' : 'Edit');
  if (typeof header === 'function') {
    header = header({values: row});
  }
  return (
    <WithModal
      variant="form"
      autoHide={false}
      position={isMobile ? 'screenBottom' : 'screenCenter'}
      {...modalProps}
      renderModal={({hide}) => {
        const {values: _parentValues} = useFormContext();
        return (
          <Form
            readOnly={readOnly}
            submitAction="Save"
            header={header}
            mode="edit"
            data={row}
            layoutFields={fields}
            onClose={() => hide && hide()}
            onSubmitSuccess={() => {}}
            computations={computations}
            _parentValues={{..._parentValues, _parentValues: __parentValues}}
            onSubmit={data => {
              replace(index, data);
            }}
          />
        );
      }}>
      {children}
    </WithModal>
  );
};

const NestedAddForm = ({
  hide,
  fields,
  push,
  defaultValues,
  computations,
  _parentValues: __parentValues,
  header = 'Add',
}) => {
  const formProps = useFormContext();
  const {values: _parentValues} = formProps;

  if (typeof defaultValues === 'function') {
    defaultValues = defaultValues({values: _parentValues});
  }
  if (typeof header === 'function') {
    header = header({values: _parentValues});
  }
  return (
    <Form
      submitAction="Save"
      header={header}
      layoutFields={fields}
      onClose={() => hide && hide()}
      onSubmitSuccess={() => {}}
      defaultValues={defaultValues}
      computations={computations}
      _parentValues={{..._parentValues, _parentValues: __parentValues}}
      onSubmit={data => {
        push(data);
      }}
    />
  );
};

export const NestedAddFormModal = ({
  push,
  fields,
  children,
  modalProps,
  defaultValues,
  computations,
  _parentValues,
  header,
}) => {
  return (
    <WithModal
      variant="form"
      autoHide={false}
      position={isMobile ? 'screenBottom' : 'screenCenter'}
      {...modalProps}
      renderModal={({hide}) => {
        return (
          <NestedAddForm
            hide={hide}
            fields={fields}
            push={push}
            defaultValues={defaultValues}
            computations={computations}
            _parentValues={_parentValues}
            header={header}
          />
        );
      }}>
      {children}
    </WithModal>
  );
};

const DeleteAction = ({remove, index, children}) => {
  return (
    <TouchableOpacity
      onPress={() => {
        remove(index);
      }}>
      {children}
    </TouchableOpacity>
  );
};

const NestedInlineAddForm = ({push, inlineFields, fields, defaultValues}) => {
  return (
    <InlineForm
      defaultValues={defaultValues}
      layoutFields={inlineFields || fields}
      onSubmitSuccess={() => {}}
      onSubmit={(data, form) => {
        push(data);
        form.resetForm();
      }}
    />
  );
};

const HeaderAddAction = props => {
  const icons = useTheme('icons');
  return (
    <NestedAddFormModal {...props}>
      <View style={{alignSelf: 'flex-end'}}>
        <Image source={icons?.Add24} style={{height: 24, width: 24}} />
      </View>
    </NestedAddFormModal>
  );
};

const AddAction = props => {
  const styles = useStyles(NestedActionStyles);
  return (
    <NestedAddFormModal {...props}>
      <View style={{paddingTop: 8}}>
        <Text style={styles?.buttonText}>+ Add</Text>
      </View>
    </NestedAddFormModal>
  );
};

const ActionColumn = ({skipDelete, readOnly, ...props}) => {
  const styles = useStyles(NestedActionStyles);
  if (readOnly && !props?.fields?.length) {
    return;
  }
  return (
    <Row gap={8} style={{alignItems: 'center', alignSelf: 'flex-end'}}>
      {readOnly ? (
        <NestedEditForm readOnly {...props}>
          <View style={styles?.iconContainer} title="Edit">
            <Image
              source={styles?.detailIcon}
              style={{...styles?.iconStyle, height: 16, width: 16}}
            />
          </View>
        </NestedEditForm>
      ) : (
        <>
          <NestedEditForm {...props}>
            <View style={styles?.iconContainer} title="Edit">
              <Image source={styles?.editIcon} style={styles?.iconStyle} />
            </View>
          </NestedEditForm>
          {skipDelete ? (
            void 0
          ) : (
            <DeleteAction {...props} styles={styles}>
              <View style={styles?.iconContainer} title="Delete">
                <Image source={styles?.deleteIcon} style={styles?.iconStyle} />
              </View>
            </DeleteAction>
          )}
        </>
      )}
    </Row>
  );
};

export const InnerNestedTable = props => {
  let {label, ...rest} = props;
  const styles = useStyles(NestedActionStyles);
  if (typeof label === 'function') {
    label = label(props);
  }
  return (
    <NestedEditForm {...rest}>
      <Text style={styles?.buttonText}>{label}</Text>
    </NestedEditForm>
  );
};

const NestedTable = ({
  value,
  field,
  readOnly,
  inlineAddForm,
  maxHeight,
  height,
  skipNoData = true,
  columns,
  moreActions,
  addInBottom,
  skipAdd,
  skipDelete,
  selection,
  columnVerticalAlignment,
  ...props
}) => {
  const {remove} = props;

  const onRemove = ({selectedIds, resetSelection}) => {
    if (selectedIds.length) {
      selectedIds.sort((a, b) => b - a).forEach(id => remove(id));
    }
    resetSelection?.();
  };

  if (selection && typeof selection === 'object') {
    let {deleteAction, actions, ...rest} = selection;
    if (typeof actions === 'function') {
      actions = actions({...props, onRemove, value, field});
    }

    if (deleteAction) {
      actions = actions && Array.isArray(actions) ? [...actions] : [];
      actions.push({
        title: 'Delete',
        onPress: onRemove,
        variant: 'error',
      });
    }
    selection = {
      selectionOnIndex: true,
      actions,
      ...rest,
    };
  }

  columns = useMemo(() => {
    let resolvedColumns = columns ? [...columns] : [...props.fields];
    resolvedColumns = resolvedColumns.map(column => {
      const {innerNested, render, ...rest} = column;
      if (innerNested) {
        column = {
          ...column,
          render: rowProps => {
            return render ? (
              render({...props, ...rowProps})
            ) : (
              <InnerNestedTable {...props} {...rowProps} {...rest} />
            );
          },
        };
      }
      return column;
    });
    if (readOnly) {
      resolvedColumns.push({
        width: 30,
        render: ({row, index}) => {
          return <ActionColumn {...props} row={row} index={index} readOnly />;
        },
      });
    } else {
      resolvedColumns.push({
        width: 80,
        header:
          !addInBottom && !skipAdd
            ? {
                render: () => <HeaderAddAction {...props} />,
              }
            : void 0,
        render: ({row, index}) => {
          return (
            <ActionColumn
              {...props}
              row={row}
              index={index}
              skipDelete={skipDelete}
            />
          );
        },
      });
    }
    return resolvedColumns;
  }, [readOnly]);

  return (
    <InputWrapper {...props}>
      <Table
        selection={readOnly ? void 0 : selection}
        renderHeader={
          inlineAddForm && !readOnly && !skipAdd
            ? () => <NestedInlineAddForm {...props} />
            : void 0
        }
        data={value}
        columns={columns}
        autoHeight={true}
        maxHeight={maxHeight}
        height={height}
        skipNoData={skipNoData}
        moreActions={moreActions}
        renderFooter={() =>
          addInBottom && !readOnly ? <AddAction {...props} /> : void 0
        }
        columnVerticalAlignment={columnVerticalAlignment}
      />
    </InputWrapper>
  );
};

export const NestedTableModal = ({children, modalProps, ...props}) => {
  return (
    <WithModal
      closeable
      autoHide={false}
      position={isMobile ? 'screenBottom' : 'screenCenter'}
      {...modalProps}
      renderModal={({hide}) => {
        return <NestedTable hide={hide} {...props} />;
      }}>
      {children}
    </WithModal>
  );
};

export default NestedTable;
