import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const MoveToBatchForm = props => {
  const {
    route: {params},
    header = 'Move to Batch',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/move-to-batch',
  });
  const {campaign_id} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        campaign_id,
      }}
      layoutFields={[
        {
          header: 'Batch',
          field: 'batch_id',
          placeholder: 'Batch',
          type: 'autoComplete',
          api: '/usersegments',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
