import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {leadControllers} from '../controllers/leadControllers';
import {useTheme} from '@unthinkable/react-theme';
import {TextRenderer} from '../../../components/form/Renderers';

export const NurturingMarketingTheme = props => {
  const {params, navigation} = props;
  const {target_audience} = params;

  const RenderView = ({row, params, navigation, ...restProps}) => {
    const {
      styles: {rowText},
    } = restProps;
    const colors = useTheme('colors');
    return (
      <TextRenderer
        value="View"
        style={{...rowText, color: colors['ACCENT1_HIGH']}}
      />
    );
  };

  return (
    <Table
      api={`/marketingThemes`}
      fields={{
        name: 1,
        theme_value_proposition: 1,
        type: 1,
        target_audience: {
          name: 1,
        },
        drip_inmail_count: {
          _id: 1,
        },
        campaign_type: {
          name: 1,
          list_view: 1,
        },
      }}
      filter={{
        theme_type: 'nurturing',
        target_audience,
      }}
      eventSourceId={['marketingThemeAdded', 'dripInmailAdded']}
      onRowPress={({row}) => {
        navigation.navigate('edit-marketing-theme', {...params, row});
      }}
      columns={[
        {
          header: 'Value Proposition',
          field: 'theme_value_proposition',
          type: 'text',
        },
        {
          header: 'Type',
          field: 'type',
          type: 'text',
        },
        {
          header: 'Type',
          field: 'campaign_type.name',
          colorField: 'campaign_type.color',
          type: 'colorTag',
          labelField: 'label',
        },
        {
          width: 80,
          navigation,
          params,
          render: RenderView,
          onPress: ({row}) => {
            navigation.navigate(row?.campaign_type?.list_view || '', {
              ...params,
              marketing_theme: row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
