import React from 'react';
import {
  BonusSalaryHrTable,
  FixedSalaryHrTable,
  HRIncentiveTabs,
  IncentiveSalaryHrTable,
  KPIIncentiveHRTable,
  PayslipHrTable,
  TaxDeclarationHrTab,
} from './views/EmployeeSalaryHrTable';
import {SalarySheetTab} from './views/SalarySheetTab';
import {PFReportTab} from '../vendor/view/PFReport';
import {FoodCouponReportTab} from '../vendor/view/FoodCoupanReport';
import {ESIReportTab} from '../vendor/view/ESIReport';
import {NPSReportTab} from '../vendor/view/NPSReport';
import {BulkForm16Tab} from './views/BulkForm16Upload';
import {
  EmployeeForm16Tab,
  EmployeeSelfForm16Table,
} from './views/EmployeeForm16';
import {ShiftAllowanceHrTab} from './views/ShiftAllowanceHr';
import {FreezeRangeTable} from '../common/views/FreezeRangeTable';
import {SalarySetupTab, TaxSetupTabs} from './views/SalarySetupTab';
import {ManualVariableTab} from './views/ManualVariableTable';
import {CSATSalaryManualTable} from './views/CsatSalaryManualTable';
import {
  AllSalaryComponentTable,
  AttendanceSalaryComponentTable,
  DeductionSalaryComponentTable,
  FixedSalaryComponentTable,
  PerformanceSalaryComponentTable,
} from './views/SalaryComponentTable';
import {EmployeeLevelTable} from './views/EmployeeLevelTable';
import {
  FixedSalarySetupTable,
  MonthlyAttendanceTable,
  PerformanceSalarySetupTable,
} from './views/AttendanceSalaryTable';
import {PackageStructureTable} from './views/PackageStructureTable';
import {
  BonusSalaryTable,
  CompensationStructureSalaryTable,
  FixedSalaryTable,
  ICIncentiveTable,
  IncentiveSalaryTable,
  InvoiceSalaryTable,
  KPIIncentiveTable,
  MyIncentiveTabs,
  SelfPayslipTable,
  ShiftAllowanceTable,
} from '../department/views/EmployeeSalaryTable';
import {TaxDeclarationTabs} from '../department/views/EmployeeTaxDeclarationTabs';
import {EmployeeSelfLevelTab} from '../department/views/EmployeeSalaryTab';
import {ShiftAllowanceForRMTab} from '../order/views/ShiftAllowanceForRM';
import {EmployeeSelfLevelHrTab} from './views/EmployeeSelfLevelHrTab';
import {SalaryReviewReport} from '../vendor/view/SalaryReviewReport';
import {EmployeeIncentiveScheduleTabs} from '../leadGeneration/views/EmployeeTabs';
import SalaryTemplateTab from './views/SalaryTemplateTable';
import { LWFReportTab } from '../vendor/view/LWFReport';

export const ScreenMapping = [
  {
    id: 'myfixed',
    view: props => <FixedSalaryTable props={props} />,
  },
  {
    id: 'mypayslips',
    view: props => <SelfPayslipTable props={props} />,
  },
  {
    id: 'myincentives',
    view: <IncentiveSalaryTable />,
  },
  {
    id: 'mybonus',
    view: <BonusSalaryTable />,
  },
  {
    id: 'mykpiincentive',
    view: <KPIIncentiveTable />,
  },
  {
    id: 'myshiftallowance',
    view: <ShiftAllowanceTable />,
  },
  {
    id: 'mycompensationstructure',
    view: props => <CompensationStructureSalaryTable props={props} />,
  },
  {
    id: 'myemployeeform16',
    view: <EmployeeSelfForm16Table />,
  },
  {
    id: 'mytaxdeclaration',
    view: <TaxDeclarationTabs />,
  },
  {
    id: 'myinvoice',
    view: <InvoiceSalaryTable />,
  },
  {
    id: 'myselfl1l2l3',
    view: <EmployeeSelfLevelTab />,
  },
  {
    id : "hrpayslip",
    view : <PayslipHrTable />
  },
  {
    id: 'fixed',
    view: props => {
      return <FixedSalaryHrTable {...props} />;
    },
  },
  {
    id : 'hrincentive',
    view : props => {
      return <HRIncentiveTabs {...props}/>
    }
  },
  {
    id: 'incentive',
    view: props => {
      return <IncentiveSalaryHrTable {...props} />;
    },
  },
  {
    id: 'bonus',
    view: props => {
      return <BonusSalaryHrTable {...props} />;
    },
  },
  {
    id: 'taxdeclaration',
    view: props => {
      return <TaxDeclarationHrTab {...props} />;
    },
  },
  {
    id: 'bulkform16',
    view: props => {
      return <BulkForm16Tab {...props} />;
    },
  },
  {
    id: 'employeeform16',
    view: props => {
      return <EmployeeForm16Tab {...props} />;
    },
  },
  {
    id: 'salarysheet',
    view: props => {
      return <SalarySheetTab {...props} />;
    },
  },
  {
    id: 'pfreport',
    view: props => {
      return <PFReportTab {...props} />;
    },
  },
  {
    id: 'lwfreport',
    view: props => {
      return <LWFReportTab {...props} />;
    },
  },
  {
    id: 'foodcoupon',
    view: props => {
      return <FoodCouponReportTab {...props} />;
    },
  },
  {
    id: 'salaryverification',
    view: props => {
      return <SalaryReviewReport {...props} />;
    },
  },
  {
    id: 'esireport',
    view: props => {
      return <ESIReportTab {...props} />;
    },
  },
  {
    id: 'npsreport',
    view: props => {
      return <NPSReportTab {...props} />;
    },
  },
  {
    id: 'shiftallowancehr',
    view: props => {
      return <ShiftAllowanceHrTab {...props} />;
    },
  },
  {
    id: 'shiftallowancerm',
    view: <ShiftAllowanceForRMTab />,
  },
  {
    id: 'salarysetup',
    view: props => {
      return <SalarySetupTab {...props} />;
    },
  },
  {
    id: 'taxsetup',
    view: props => {
      return <TaxSetupTabs {...props} />;
    },
  },
  {
    id: 'manualvariabledepartment',
    view: props => {
      return <ManualVariableTab {...props} fromHr = {false}/>;
    },
  },
  {
    id: 'manualvariable',
    view: props => {
      return <ManualVariableTab {...props} fromHr = {true} />;
    },
  },
  {
    id: 'csatsalarymanual',
    view: props => {
      return <CSATSalaryManualTable {...props} />;
    },
  },
  {
    id: 'salarytemplate',
    view: props => {
      return <SalaryTemplateTab {...props} />;
    },
  },
  {
    id: 'all',
    view: props => {
      return <AllSalaryComponentTable {...props} />;
    },
  },
  {
    id: 'attendancebased',
    view: props => {
      return <AttendanceSalaryComponentTable {...props} />;
    },
  },
  {
    id: 'performancebased',
    view: props => {
      return <PerformanceSalaryComponentTable {...props} />;
    },
  },
  {
    id: 'fixedbased',
    view: props => {
      return <FixedSalaryComponentTable {...props} />;
    },
  },
  {
    id: 'deduction',
    view: props => {
      return <DeductionSalaryComponentTable {...props} />;
    },
  },
  {
    id: 'monthlyattendance',
    view: props => {
      return <MonthlyAttendanceTable {...props} />;
    },
  },
  {
    id: 'fixedsalary',
    view: props => {
      return <FixedSalarySetupTable {...props} />;
    },
  },
  {
    id: 'performancesalary',
    view: props => {
      return <PerformanceSalarySetupTable {...props} />;
    },
  },
  {
    id: 'packagestructure',
    view: props => {
      return <PackageStructureTable {...props} />;
    },
  },
  {
    id: 'levelpackagebreakup',
    view: props => {
      return <EmployeeLevelTable {...props} />;
    },
  },
  {
    id: 'freezesalaryrangepayroll',
    view: props => {
      return <FreezeRangeTable {...props} />;
    },
  },
  {
    id: 'kpiIncentive',
    view: props => {
      return <KPIIncentiveHRTable {...props} />;
    },
  },
  {
    id: 'selfl1l2l3',
    view: props => {
      return <EmployeeSelfLevelHrTab {...props} />;
    },
  },
  {
    id: 'employeeincentiveschedule',
    view: props => {
      return <EmployeeIncentiveScheduleTabs {...props} />;
    },
  },
];

// export const Screens = [
//   {
//     menuItemId: 'mysalary',
//     views: [
//       {
//         id: 'myfixed',
//         label: 'Fixed',
//         view: props => <FixedSalaryTable props={props} />,
//       },
//       {
//         id: 'myincentives',
//         label: 'Incentives',
//         view: <IncentiveSalaryTable />,
//       },
//       {
//         id: 'mybonus',
//         label: 'Bonus',
//         view: <BonusSalaryTable />,
//       },
//       // {
//       //   id: 'myicincentive',
//       //   label: 'IC Incentive',
//       //   view: <ICIncentiveTable />,
//       //
//       // },
//       {
//         id: 'mykpiincentive',
//         label: 'KPI Incentive',
//         view: <KPIIncentiveTable />,
//       },
//       {
//         id: 'myshiftallowance',
//         label: 'Shift Allowance',
//         view: <ShiftAllowanceTable />,
//       },
//       {
//         id: 'mycompensationstructure',
//         label: 'Compensation Structure',
//         view: props => <CompensationStructureSalaryTable props={props} />,
//       },
//       {
//         id: 'myemployeeform16',
//         label: 'Employee Form 16',
//         view: <EmployeeSelfForm16Table />,
//       },
//       {
//         id: 'mytaxdeclaration',
//         label: 'Tax Declaration',
//         view: <TaxDeclarationTabs />,
//       },
//       {
//         id: 'myinvoice',
//         label: 'Invoice',
//         view: <InvoiceSalaryTable />,
//       },
//       {
//         id: 'myselfl1l2l3',
//         label: 'Self L1 L2 L3',
//         view: <EmployeeSelfLevelTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'salary',
//     views: [
//       {
//         id: 'fixed',
//         label: 'Fixed',
//         view: props => {
//           return <FixedSalaryHrTable {...props} />;
//         },
//       },
//       {
//         id: 'incentive',
//         label: 'Incentive',
//         view: props => {
//           return <IncentiveSalaryHrTable {...props} />;
//         },
//       },
//       {
//         id: 'bonus',
//         label: 'Bonus',
//         view: props => {
//           return <BonusSalaryHrTable {...props} />;
//         },
//       },
//       {
//         id: 'kpiIncentive',
//         label: 'Kpi Incentive',
//         view: props => {
//           return <KPIIncentiveHRTable {...props} />;
//         },
//       },
//       {
//         id: 'selfl1l2l3',
//         label: 'Manager Self L1 L2 L3',
//         view: props => {
//           return <EmployeeSelfLevelHrTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'taxcenter',
//     views: [
//       {
//         id: 'taxdeclaration',
//         label: 'Tax Declarations',
//         view: props => {
//           return <TaxDeclarationHrTab {...props} />;
//         },
//       },
//       {
//         id: 'form16',
//         label: 'Form 16',
//         section: true,
//         menus: [
//           {
//             id: 'bulkform16',
//             label: 'Bulk Form 16',
//             view: props => {
//               return <BulkForm16Tab {...props} />;
//             },
//           },
//           {
//             id: 'employeeform16',
//             label: 'Employee Form 16',
//             view: props => {
//               return <EmployeeForm16Tab {...props} />;
//             },
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menuItemId: 'reports',
//     views: [
//       {
//         id: 'salarysheet',
//         label: 'Salary Sheet',
//         view: props => {
//           return <SalarySheetTab {...props} />;
//         },
//       },
//       {
//         id: 'pfreport',
//         label: 'PF Report',
//         view: props => {
//           return <PFReportTab {...props} />;
//         },
//       },
//       {
//         id: 'foodcoupon',
//         label: 'Food Coupon',
//         view: props => {
//           return <FoodCouponReportTab {...props} />;
//         },
//       },
//       {
//         id: 'esireport',
//         label: 'ESI Report',
//         view: props => {
//           return <ESIReportTab {...props} />;
//         },
//       },
//       {
//         id: 'npsreport',
//         label: 'NPS Report',
//         view: props => {
//           return <NPSReportTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'shiftallowances',
//     views: [
//       {
//         id: 'shiftallowancehr',
//         label: 'Shift Allowance (HR)',
//         view: props => {
//           return <ShiftAllowanceHrTab {...props} />;
//         },
//       },
//       {
//         id: 'shiftallowancerm',
//         label: 'Shift Allowance (RM)',
//         view: <ShiftAllowanceForRMTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'setup',
//     views: [
//       {
//         id: 'salarysetup',
//         label: 'Salary',
//         view: props => {
//           return <SalarySetupTab {...props} />;
//         },
//       },
//       {
//         id: 'manualvariable',
//         label: 'Manual Variable',
//         view: props => {
//           return <ManualVariableTable {...props} />;
//         },
//       },
//       {
//         id: 'csatsalarymanual',
//         label: 'CSAT Salary Manual',
//         view: props => {
//           return <CSATSalaryManualTable {...props} />;
//         },
//       },
//       {
//         id: 'components',
//         label: 'Components',
//         section: true,
//         menus: [
//           [
//             {
//               id: 'all',
//               label: 'All',
//               view: props => {
//                 return <AllSalaryComponentTable {...props} />;
//               },
//             },
//             {
//               id: 'attendancebased',
//               label: 'Attendance Based',
//               view: props => {
//                 return <AttendanceSalaryComponentTable {...props} />;
//               },
//             },
//             {
//               id: 'performancebased',
//               label: 'Performance Based',
//               view: props => {
//                 return <PerformanceSalaryComponentTable {...props} />;
//               },
//             },
//             {
//               id: 'fixedbased',
//               label: 'Fixed Based',
//               view: props => {
//                 return <FixedSalaryComponentTable {...props} />;
//               },
//             },
//             {
//               id: 'deduction',
//               label: 'Deduction',
//               view: props => {
//                 return <DeductionSalaryComponentTable {...props} />;
//               },
//             },
//           ],
//         ],
//       },
//       [
//         {
//           id: 'monthlyattendance',
//           label: 'Monthly Attendance',
//           view: props => {
//             return <MonthlyAttendanceTable {...props} />;
//           },
//         },
//         {
//           id: 'fixedsalary"',
//           label: 'Fixed Salary',
//           view: props => {
//             return <FixedSalarySetupTable {...props} />;
//           },
//         },
//         {
//           id: 'performancesalary"',
//           label: 'Performance Salary',
//           view: props => {
//             return <PerformanceSalarySetupTable {...props} />;
//           },
//         },
//         {
//           id: 'packagestructure',
//           label: 'Package Structure',
//           view: props => {
//             return <PackageStructureTable {...props} />;
//           },
//         },
//         {
//           id: 'levelpackagebreakup',
//           label: 'Level Package Breakup',
//           view: props => {
//             return <EmployeeLevelTable {...props} />;
//           },
//         },
//       ],
//       [
//         {
//           id: 'freezesalaryrange',
//           label: 'Freeze Salary Range',
//           view: props => {
//             return <FreezeRangeTable {...props} />;
//           },
//         },
//       ],
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'mysalary',
//     label: 'My Salary',
//   },
//   {
//     id: 'salary',
//     label: 'Salary',
//   },
//   {
//     id: 'shiftallowances',
//     label: 'Shift Allowance',
//   },
//   {
//     id: 'taxcenter',
//     label: 'Tax Center',
//   },
//   {
//     id: 'reports',
//     label: 'Reports',
//   },
//   {
//     id: 'setup',
//     label: 'Setup',
//   },
// ];
