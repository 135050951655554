import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const TeamAttendanceRequestForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  const {user} = useAppStateContext();
  const {
    employee: {_id, reporting_to},
  } = user;
  let {onSubmit} = useFormSubmit({
    uri: '/DailyAttendance',
    eventSourceId: ['teamAttendance'],
    ...props,
  });
  return (
    <Form
      api={`/DailyAttendances/${row?._id}`}
      fields={{
        attendance_date: 1,
        in_time: 1,
        out_time: 1,
        time_in_office: 1,
        break_time: 1,
        employee: {name: 1},
        first_attendance_type_id: {name: 1},
        second_attendance_type_id: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      computations={{
        status: [
          value => {
            const {is_approved} = value || {};
            if (is_approved) return 'approve';
            else return 'unapproved';
          },
          ['is_approved'],
        ],
      }}
      layoutFields={[
        {
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          label: 'Employee',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          type: 'date',
          field: 'attendance_date',
          label: 'Attendance Date',
          size: 6,
        },
        {
          field: 'first_attendance_type_id',
          type: 'autoComplete',
          api: `/DailyAttendanceTypes`,
          label: 'First Half',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          field: 'second_attendance_type_id',
          type: 'autoComplete',
          api: `/DailyAttendanceTypes`,
          label: 'Second Half',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          field: 'in_time',
          placeholder: 'In Time(hh:mm)',
          label: 'In Time',
          type: 'text',
          size: 6,
        },
        {
          field: 'out_time',
          placeholder: 'Out Time(hh:mm)',
          label: 'Out Time',
          type: 'text',
          size: 6,
        },
        {
          field: 'time_in_office',
          placeholder: ' Time in Office(min)',
          label: 'Time in Office',
          type: 'text',
          size: 6,
        },
        {
          field: 'break_time',
          placeholder: 'Break Time(min)',
          label: 'Break Time',
          type: 'text',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateTeamAttendanceForm = props => {
  return (
    <TeamAttendanceRequestForm
      mode="edit"
      header={'Update Attendance Form'}
      {...props}
    />
  );
};

export const AttendanceRejectRequestForm = props => {
  const {
    route: {params},
    title,
  } = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/dailyattendances/${row?._id}`,
    method: 'put',
    eventSourceId: 'teamAttendance',
    props: {
      status: 'reject',
      is_approved: true,
      __v: 0,
      ...props,
    },
  });
  return (
    <Form
      header={{
        title,
        secondaryTitle: `${row?.employee?.name} (attendance request) `,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      eventSourceId="teamAttendance"
      defaultValues={{
        status: 'reject',
        is_approved: true,
        __v: 0,
      }}
      layoutFields={[
        {
          header: 'reason',
          type: 'text',
          field: 'remark',
          placeholder: 'Reason',
        },
      ]}
      {...props}
    />
  );
};

export const TeamAttendanceRejectRequestForm = props => {
  return (
    <AttendanceRejectRequestForm
      title={'Give reason for rejection'}
      {...props}
    />
  );
};
