import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';

const MarkCompletedRender = () => {
  const {GreyTickIcon} = useTheme('icons');
  return <Image style={{height: 20, width: 20}} source={GreyTickIcon} />;
};

const CompletedRender = () => {
  const {GreenTickIcon} = useTheme('icons');
  return <Image style={{height: 20, width: 20}} source={GreenTickIcon} />;
};

export const MarkCompleted = props => {
  const {row, onPress, disabled} = props;
  return (
    <TouchableOpacity onPress={() => onPress(props)} disabled={disabled}>
      {row?.is_completed ? (
        <CompletedRender {...props} />
      ) : (
        <MarkCompletedRender {...props} />
      )}
    </TouchableOpacity>
  );
};

const AcceptanceCriteriaRender = props => {
  const {
    value,
    form: {setFieldValue},
  } = props;
  return (
    <NestedTable
      key={value?.length}
      {...props}
      columns={[
        {
          render: props => {
            return (
              <MarkCompleted
                {...props}
                onPress={({index}) => {
                  if (value[index]?._id) {
                    value[index] = {
                      ...value[index],
                      is_completed: !value[index]?.is_completed,
                    };
                    setFieldValue('acceptance_criteria', value);
                  }
                }}
              />
            );
          },
          width: 36,
        },
        {
          field: 'name',
          header: 'Checklist Item',
          type: 'text',
          numberOfLines: 3,
        },
      ]}
    />
  );
};

export const MarkTaskCompleteForm = props => {
  const {
    route: {params},
  } = props;
  const {row, readOnly = false} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: ['Task'],
    ...props,
  });
  return (
    <Form
      {...props}
      readOnly={readOnly}
      header={'Task'}
      beforeSubmit={({data}) => {
        if (
          data.completed_on &&
          moment.utc(data.completed_on).startOf('day').toDate() >
            moment.utc().startOf('day').toDate()
        ) {
          throw new Error('Completed date should not be future date');
        }
        data?.acceptance_criteria?.forEach(criteria => {
          if (!criteria.is_completed) {
            throw new Error('Please Complete All Checklists ');
          }
        });
        if (row?.sub_task_count) {
          if (row?.sub_task_count !== row?.completed_sub_task_count) {
            throw new Error('Please Complete All Subtasks');
          }
        }
      }}
      onSubmit={onSubmit}
      updateDefaultValues={{
        status: 'completed',
        completed_on: moment.utc().startOf('day').toDate(),
      }}
      submitAction="Mark Complete"
      mode="edit"
      fields={{
        output_link: 1,
        output_remark: 1,
        output_attachment: 1,
        acceptance_criteria: 1,
        source: {
          name: 1,
        },
        source_id: {
          name: 1,
        },
        completed_on: 1,
      }}
      api={`/projectTasks/${row?._id}`}
      layoutFields={[
        {
          field: 'completed_on',
          type: 'date',
          label: 'Completed Date',
          size: 6,
          required: true,
        },
        {
          field: 'output_link',
          type: 'link',
          label: 'Link',
          size: 6,
          numberOfLines: 2,
        },
        {
          field: 'output_attachment',
          type: 'file',
          label: 'Attachment',
          multiple: true,
        },
        {
          field: 'output_remark',
          type: 'textArea',
          label: 'Remark',
        },
        {
          label: 'Work Reference',
          fields: [
            {
              field: 'source',
              label: 'Data Entity',
              type: 'autoComplete',
              api: '/types',
              filter: {
                departments: params?.department?._id,
                is_show: true,
              },
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              field: 'source_id',
              label: 'Data Entity Value',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({values}) => {
                const {source} = values || {};
                return {
                  source,
                };
              },
              size: 6,
            },
          ],
        },
        {
          field: 'acceptance_criteria',
          label: 'Acceptance Checklist',
          nested: true,
          readOnly: true,
          render: AcceptanceCriteriaRender,
        },
      ]}
    />
  );
};
