import {AllOpportunitiesTab} from '../allOpportunities/views/AllOpportunitiesTab';
import {
  CustomPlatformMergedTabs,
  CustomPlatformMergedTabsForSolCon,
} from '../customPlatformNewView/view/customPlatformMergedTab';
import {RfrTatTabs} from '../employee/views/RfrTATTabs';
import {
  EngineeringOpportunitiesTabView,
  EngineeringOpportunitiesTabViewForDeliveryOwners,
  StaffaugmentationOppResources,
} from '../engineeringOpportunities/views/EngineeringOpportunitiesTabs';
import { LocomoWaitlistTable } from '../leadGeneration/outreachcampaign-views/LocomoWaitlistTable';
import {ManualCampaignTabView} from '../leadGeneration/outreachcampaign-views/ManualCampaignTabView';
import {OutreachCampaignTabs} from '../leadGeneration/outreachcampaign-views/OutreachCampaignTabs';
import {OutreachTargetAudienceTabs} from '../leadGeneration/outreachcampaign-views/OutreachTargetAudienceTabs';
import {PotentialCustomersHubspotLeadsTable} from '../leadGeneration/outreachcampaign-views/PotentialCustomersHubspotLeadsTable';
import ChannelsTable from '../leadGeneration/views/ChannelsTable';
import {ConversionTabView} from '../leadGeneration/views/ConversionTabView';
import {CountryList} from '../leadGeneration/views/CountryList';
import {
  AllLeadTableMenu,
  LostLeadsTabls,
} from '../leadGeneration/views/LeadMenuTables';
import {
  ActiveLeadTabs,
  ActiveLeadTabsForSolCon,
  AllLeadTabs,
  NewLeadTabs,
  WonLeadTabs,
} from '../leadGeneration/views/LeadTableTabView';
import {ProspectsTableTabView} from '../leadGeneration/views/ProspectTableTabView';
import {TargetAudienceTabs} from '../leadGeneration/views/TargetAudienceTabs';
import {OpportunityAnalyticsTable} from '../opportunityAnalytics/views/OpportunityAnalyticsDashboard';
import {
  FixedOpportunityProjectionTab,
  TnMOpportunityProjectionTab,
} from '../opportunityProjection/views/opportunityProjectionTabs';
import {PracticeWiseOpportunityTabNew} from '../order/views/PracticeWiseOpportunityTab';
import {
  RFRDeliveryOwnerWiseNew,
  RFRMentorWiseNew,
  RFRResourceWiseNew,
} from '../order/views/ResourceWiseRFRSuccessTabs';
import {CustomerOverviewDashboard} from './views/CustomerDashboardOverview';

export const ScreenMapping = [
  {
    id: 'AllCustomers',
    view: <CustomerOverviewDashboard />,
  },
  {
    id: 'campaigns',
    onNavigate: ({view, params, navigation}) => {
      navigation.navigate('campaigns-menu', {
        ...params,
        fromNewNav: true,
      });
    },
  },
  {
    id: 'prospects',
    view: <ProspectsTableTabView />,
  },
  {
    id: 'New',
    view: <NewLeadTabs />,
  },
  {
    id: 'Active',
    view: <ActiveLeadTabs />,
  },
  {
    id: 'Lost',
    view: <LostLeadsTabls />,
  },
  {
    id: 'Won',
    view: <WonLeadTabs />,
  },
  {
    id: 'All',
    view: <AllLeadTabs />,
  },
  {
    id: 'ConversionRatio',
    view: <ConversionTabView />,
  },
  {
    id: 'leads_do',
    view: <ActiveLeadTabsForSolCon />,
  },
  {
    id: 'allOpportunities',
    view: <AllOpportunitiesTab />,
  },
  {
    id: 'customplatform',
    view: <CustomPlatformMergedTabs />,
  },
  {
    id: 'customplatform_do',
    view: <CustomPlatformMergedTabsForSolCon />,
  },
  {
    id: 'positions',
    view: <EngineeringOpportunitiesTabView />,
  },
  {
    id: 'positions_do',
    view: <EngineeringOpportunitiesTabViewForDeliveryOwners />,
  },
  {
    id: 'allCandidates',
    view: <StaffaugmentationOppResources />,
  },
  {
    id: 'practiceWise',
    view: <PracticeWiseOpportunityTabNew />,
  },
  {
    id: 'dowise',
    view: <RFRDeliveryOwnerWiseNew />,
  },
  {
    id: 'mentorwise',
    view: <RFRMentorWiseNew />,
  },
  {
    id: 'employeewise',
    view: <RFRResourceWiseNew />,
  },
  {
    id: 'analytic',
    view: <OpportunityAnalyticsTable />,
  },
  {
    id: 'rfr-tat',
    view: <RfrTatTabs />,
  },
  {
    id: 'projectedpipelinetandm',
    view: <TnMOpportunityProjectionTab />,
  },
  {
    id: 'projectedpipelinefixed',
    view: <FixedOpportunityProjectionTab />,
  },
  {
    id: 'targetAudience',
    view: <TargetAudienceTabs />,
  },
  {
    id: 'nurturable_leads',
    view: <AllLeadTableMenu isNurturable={true} />,
  },
  {
    id: 'channels',
    view: <ChannelsTable />,
  },
  {
    id: 'country_list',
    view: <CountryList />,
  },
  {
    id: 'manual_campaigns',
    view: <ManualCampaignTabView />,
  },
  {
    id: 'auto_campaigns',
    view: <OutreachCampaignTabs />,
  },
  {
    id: 'hubspot_contacts',
    view: <PotentialCustomersHubspotLeadsTable />,
  },
  {
    id: 'performance',
    onNavigate: ({view, params, navigation}) => {
      navigation.navigate('performance', {
        ...params,
      });
    },
  },
  {
    id: 'batch',
    onNavigate: ({view, params, navigation}) => {
      navigation.navigate('segment-list', {});
    },
  },

  {
    id: 'ai_prompt',
    onNavigate: ({view, params, navigation}) => {
      navigation.navigate('ai-prompts-table', {
        ...params,
        addOnFilter: {for_crm: true},
      });
    },
  },
  {
    id: 'target_audience_auto',
    view: <OutreachTargetAudienceTabs />,
  },
  {
    id: 'outreach_campaigns_auto',
    onNavigate: ({view, params, navigation}) => {
      navigation.navigate('outreach-campaign-menu', {
        ...params,
      });
    },
  },
  {
    id: 'unlock_requests',
    onNavigate: ({view, params, navigation}) => {
      navigation.navigate('unlock-requests', {
        ...params,
      });
    },
  },
  {
    id: 'locomo_waitlist',
    view: <LocomoWaitlistTable />,
  },
];

// export const Screens = [
//   {
//     menuItemId: 'customers',
//     views: [
//       {
//         id: 'AllCustomers',
//         label: 'All Customers',
//         view: <CustomerOverviewDashboard />,
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'campaigns',
//     views: [
//       {
//         id: 'campaigns',
//         label: 'Campaigns',
//         onNavigate: ({view, params, navigation}) => {
//           navigation.navigate('campaigns-menu', {
//             ...params,
//           });
//         },
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'prospects',
//     views: [
//       {
//         id: 'prospects',
//         label: 'All prospects',
//         view: <ProspectsTableTabView />,
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'leads',
//     views: [
//       {
//         id: 'New',
//         label: 'New',
//         view: <NewLeadTabs />,
//         role: ['Admin'],
//       },
//       {
//         id: 'Active',
//         label: 'Active',
//         view: <ActiveLeadTabs />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'Lost',
//         label: 'Lost',
//         view: <LostLeadsTabls />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'All',
//         label: 'All',
//         view: <AllLeadTabs />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'ConversionRatio',
//         label: 'Conversion Ratio',
//         view: <ConversionTabView />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'leads_do',
//         label: 'Leads DO',
//         view: <ActiveLeadTabsForSolCon />,
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'all',
//     views: [
//       {
//         id: 'all',
//         label: 'All',
//         view: <AllOpportunitiesTab />,
//         role: ['Admin'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'customplatform',
//     views: [
//       {
//         id: 'customplatform',
//         label: 'Custom Platform',
//         view: <CustomPlatformMergedTabs />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'customplatform_do',
//         label: 'Custom Platform DO',
//         view: <CustomPlatformMergedTabsForSolCon />,
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'staffAugmentation',
//     views: [
//       {
//         id: 'positions',
//         label: 'Positions',
//         view: <EngineeringOpportunitiesTabView />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'positions_do',
//         label: 'Positions DO',
//         view: <EngineeringOpportunitiesTabViewForDeliveryOwners />,
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'candidates',
//         label: 'Candidates',
//         section: true,
//         menus: [
//           {
//             id: 'all',
//             label: 'All',
//             view: <StaffaugmentationOppResources />,
//           },
//           {
//             id: 'practiceWise',
//             label: 'Practice-wise',
//             view: <PracticeWiseOpportunityTabNew />,
//           },
//           {
//             id: 'dowise',
//             label: 'DO-wise',
//             view: <RFRDeliveryOwnerWiseNew />,
//           },
//           {
//             id: 'mentorwise',
//             label: 'Mentor-wise',
//             view: <RFRMentorWiseNew />,
//           },
//           {
//             id: 'employeewise',
//             label: 'Employee-wise',
//             view: <RFRResourceWiseNew />,
//           },
//         ],
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'setup',
//     views: [
//       {
//         id: 'OrganisationCategory',
//         label: 'Organisation Category',
//         role: ['Admin'],
//       },
//       {
//         id: 'Geography',
//         label: 'Geography',
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'Country',
//         label: 'Country',
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'EmployeeSize',
//         label: 'Employee Size',
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'Industry',
//         label: 'Industry',
//         role: ['Admin', 'User'],
//       },
//     ],
//   },
//   {
//     menuItemId: 'analytics',
//     views: [
//       {
//         id: 'analytic',
//         label: 'Analytics',
//         view: <OpportunityAnalyticsTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'projectedpipeline',
//     views: [
//       {
//         id: 'projectedpipelinetandm',
//         label: 'T&M',
//         view: <TnMOpportunityProjectionTab />,
//       },
//       {
//         id: 'projectedpipelinefixed',
//         label: 'Fixed',
//         view: <FixedOpportunityProjectionTab />,
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'customers',
//     label: 'Customer',
//     showSingleView: true,
//   },
//   {
//     id: 'campaigns',
//     label: 'Campaigns',
//     showSingleView: true,
//   },
//   {
//     id: 'prospects',
//     label: 'Prospect',
//   },
//   {
//     id: 'leads',
//     label: 'Leads',
//     showSingleView: true,
//   },
//   {
//     id: 'opportunities',
//     label: 'Opportunities',
//     section: true,
//     menus: [
//       {
//         id: 'all',
//         label: 'All',
//         role: ['Admin'],
//       },
//       {
//         id: 'customplatform',
//         label: 'Custom Platform',
//         role: ['Admin', 'User'],
//       },
//       {
//         id: 'staffAugmentation',
//         label: 'Staff Augmentation',
//         role: ['Admin', 'User'],
//       },
// {
//   id: 'analytics',
//   label: 'Analytics',
// },
//       {
//         id: 'projectedpipeline',
//         label: 'Projected Pipeline',
//       },
//     ],
//   },
//   // {
//   //   id: 'setup',
//   //   label: 'setup',
//   // },
// ];
