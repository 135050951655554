const activityLogDetailForReimbursement = ({row, navigation}) => {
  navigation.navigate(`activity-logs`, {
    _id: row._id,
    populate: [
      {path: 'employee', select: {name: 1}},
      {path: 'approved_by', select: {name: 1}},
    ],
    api: `/reimbursements/${row?._id}/activityLogs`,
    displayFields: {
      //   description: {
      //     label: 'Description',
      //   },
      status: {
        label: 'Status',
      },
      employee: {
        label: 'Employee',
        field: 'approved_by.name',
      },
      approved_by: {
        label: 'Approver',
        field: 'employee.name',
      },
      reimbursement_type: {
        label: 'Type',
      },
      approved_on: {
        label: 'Approved On',
      },
      attachment: {
        label: 'File',
      },
      reimbursement_detail: {
        label: 'Reimbursement Detail',
      },
    },
  });
};

const activityLogReimbursement = ({navigation, params}) => ({
  title: 'Activity Logs',
  onPress: ({row}) =>
    activityLogDetailForReimbursement({row, navigation, params}),
});

export {activityLogReimbursement};
