import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const IndustryForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/industries',
    eventSourceId: 'Industry',
  });
  return (
    <Form
      api={`/industries/${row?._id}`}
      fields={{
        name: 1,
        creation_date: 1,
        product: {name: 1},
        code: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
        },
        {
          type: 'text',
          field: 'code',
          label: 'Code',
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddIndustryForm = props => {
  return (
    <IndustryForm
      defaultValues={{
        creation_date: getZeroTimeDate(new Date()),
      }}
      header="Add Industry"
      {...props}
    />
  );
};

export const EditIndustryForm = props => {
  return <IndustryForm mode="edit" header={'Edit'} {...props} />;
};
