import React from 'react';
import {Table} from '../../../components/table/Table';

export const EscalationReasonTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={['escalationreasons']}
      api={`/projectescalationreasons`}
      fields={{reason: 1}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-escalation-reason`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'reason',
          header: 'Reason',
        },
      ]}
    />
  );
};
