import React, {forwardRef} from 'react';
import {View} from '@unthinkable/react-core-components';
import {InputContainer, InputWrapper, useInput} from '@unthinkable/react-input';
import {MentionsInput} from './MentionsInput';

export const StyledMentionsInput = forwardRef((props, ref) => {
  props = useInput({...props, ref});
  const {inputProps, inputRef, isFloatingLabel} = props;
  return (
    <InputWrapper {...props}>
      <InputContainer {...props}>
        <View
          style={
            isFloatingLabel
              ? {
                  paddingTop: 12,
                }
              : {}
          }>
          <MentionsInput {...props} {...inputProps} inputRef={inputRef} />
        </View>
      </InputContainer>
    </InputWrapper>
  );
});
