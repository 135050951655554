import React from 'react';
import {Table} from '../../../../components/table/Table';
import {TableHeader} from '../../../../components/table/Headers';
import {
  AddButton,
  BorderLessButton,
} from '../../../../components/button/AddButton';

import {useLeftMenuContext} from '../../../../components/menu/LeftMenuContext';
import {Text} from '@unthinkable/react-core-components';
import {Chip} from '../../../../components/chip/Chip';

export const ProjectBoilerplateLibraryTable = ({
  route: {params},
  navigation,
  libraryType,
  title,
}) => {
  const {navigateTo} = useLeftMenuContext() || {};
  const {project_id} = params;

  const {breadcrumb, ...rest} = params;

  return (
    <Table
      eventSourceId="Library"
      api={`/projects/${project_id}/libraries`}
      filter={{
        type: libraryType,
      }}
      fields={{
        repository_id: {
          repository: 1,
          frameworks: 1,
          language: 1,
        },
        name: 1,
      }}
      renderHeader={props => {
        let {data} = props;
        const importedLibraryIds = data?.map(_data => {
          return _data?._id;
        });
        return (
          <TableHeader
            title={title}
            actions={[
              <BorderLessButton
                onPress={() => {
                  navigation.navigate('import-boilerplate', {
                    libraryType,
                    importedLibraryIds,
                    ...params,
                  });
                }}
                text={'Import Boilerplate'}
              />,
              <AddButton
                title={'Boilerplate'}
                view="add-boilerplate"
                params={{...params, libraryType, title}}
              />,
            ]}
          />
        );
      }}
      onRowPress={({row}) => {
        navigateTo('edit-boilerplate', {
          ...params,
          projectlibrary: row,
          projectlibrary_id: row?._id,
          libraryType,
          integratedProject: row.integrated_project,
          breadcrumb: {
            dropdown: {
              field: 'projectlibrary',
            },
          },
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Repository',
          field: 'repository_id.repository',
          type: 'text',
          width: 200,
        },
        {
          header: 'Language',
          field: 'repository_id.language',
          type: 'text',
          width: 200,
        },
        {
          header: 'Framework',
          field: 'repository_id.frameworks',
          type: 'text',
          render: ({row}) => {
            return row?.repository_id.frameworks.map(framework => {
              return <Chip title={framework} value={framework} />;
            });
          },
          width: 200,
        },
        {
          align: 'right',
          width: 80,
          header: 'Version',
          render: ({row, styles}) => {
            let {latest_version_id, integrated_project} = row || {};
            if (!latest_version_id) {
              return;
            }
            let version =
              integrated_project?.library_version_id?.version ||
              latest_version_id.version;
            return <Text style={styles?.rowText}>{version}</Text>;
          },
        },
        {
          header: 'Branch',
          type: 'branch',
          field: 'latest_version_id.branch',
        },
      ]}
      moreActions={[
        {
          title: 'Publish',
          onPress: ({row}) => {
            navigation.navigate('publish-library', {
              ...params,
              row,
              libraryType,
            });
          },
          visible: ({row}) => row?.project_id === project_id,
        },
        {
          title: 'All Versions',
          onPress: ({row}) => {
            navigation.navigate('project-library-versions', {
              ...rest,
              row,
            });
          },
          visible: ({row}) => row?.library_version_count > 0,
        },
      ]}
    />
  );
};
