import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useFilter} from '@unthinkable/react-filters';
import {useInvoke} from '../../../controllers/useInvoke';
import {GroupFilter} from '../../../components/filter/Filters';

const UserRoleTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {applyFilter, filterValues, onChangeFilter} = useFilter({});
  const {filter = {}, params: filterParams = {}} = filterValues || {};

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'userRoleRemoved',
  });

  return (
    <Table
      eventSourceId={['userRoleAdded', 'userRoleRemoved']}
      api={`/userroles`}
      fields={{
        app_role_id: {display_label: 1},
        user_id: {name: 1},
      }}
      filter={{is_system: {$in: [null, false]}, ...filter}}
      onRowPress={({row}) => {
        navigation.navigate('edit-user-role', {row});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Users',
                  api: '/users',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                  field: 'user_id',
                },
                {
                  type: 'autoComplete',
                  label: 'App Roles',
                  api: '/approles',
                  placeholder: 'Select',
                  suggestionField: 'display_label',
                  valueField: 'display_label',
                  field: 'app_role_id',
                },
              ]}
            />,
            <AddButton
              title="Add User Role"
              view="add-user-role"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'AppRole',
          field: 'app_role_id.display_label',
          type: 'text',
        },
        {
          header: 'User',
          field: 'user_id.name',
          type: 'text',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-user-role', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/userroles/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default UserRoleTable;
