import React from 'react';
import {TableHeader} from '../../../../components/table/Headers';
import {Table} from '../../../../components/table/Table';

export const MappedScreenList = props => {
  const {
    route: {params},
  } = props;
  const {data, component} = params || {};

  return (
    <>
      <TableHeader title={'Screens'} secondaryTitle={component} />
      <Table
        data={data}
        columns={[
          {
            type: 'text',
            field: 'view',
          },
        ]}
      />
    </>
  );
};
