import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {IconButton} from '../../../components/button/IconButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {GroupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';

export const OverduePaymentReportTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, searchValue, filterValues, onChangeFilter} = useFilter(
    {},
  );
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {download} = useAppStateContext();
  let invoiceFilter = {
    invoice_type: {$in: ['i', 'a', 'd', 'c']},
    $or: [
      {
        base_pending_receipt_amount: {$gt: 5},
      },
      {base_pending_receipt_amount: {$lt: -10}},
    ],
    adjust_type: {$in: [null, false]},
    is_fluctuated: {$in: [false, null]},
    status: {
      $in: [
        'New',
        'Adjusted',
        'Partial Paid',
        'Partilly Invoiced',
        'Credit Note',
      ],
    },
  };
  return (
    <Table
      {...props}
      params={filterParams}
      addOnFilter={filter}
      api={'/order/overdue-payment-report'}
      variant={'bordered'}
      renderHeader={() => (
        <TableHeader
          title="Overdue Payment"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'multiAutoComplete',
                  name: 'customer',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer',
                },
                {
                  type: 'multiAutoComplete',
                  name: 'Account Owner',
                  label: 'AM',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  field: 'account_owner_id',
                },
                {
                  type: 'multiAutoComplete',
                  name: 'organization',
                  label: 'Organization',
                  api: '/organizations',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'organization',
                },
                {
                  type: 'multiAutoComplete',
                  name: 'product',
                  label: 'Product',
                  api: '/products',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'product',
                },
              ]}
            />,
            <IconButton
              icon={icons.Download}
              onPress={() => {
                download({
                  uri: '/exportOverDuePaymentReport',
                  props: {
                    uri: '/order/overdue-payment-report',
                    parameters: {
                      params: {...filterParams},
                      addOnFilter: {...filter},
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      limit={2000}
      columns={[
        {
          header: 'Customer',
          field: 'name',
          minWidth: 200,
        },
        {
          header: 'AM',
          width: 100,
          type: 'userAvatar',
          field: 'account_manager',
        },
        {
          header: 'Overdue',
          children: [
            {
              render: CountCellRender,
              count_field: 'total_amount',
              aggregate: true,
              header: 'Total',
              field: 'total_amount',
              type: 'currency',
              width: 200,
              render: ({row}) => {
                let {total_amount = 0, max_ageing} = row;
                let maxAgeing = 0;
                if (max_ageing) {
                  let date1 = new Date(max_ageing);
                  let date2 = new Date();
                  let diffTime = Math.abs(date2 - date1);
                  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  maxAgeing = diffDays;
                }
                return (
                  <PrimaryColumnCard
                    primaryTitle={row.total_amount}
                    primaryTitleColor="#FF3B30"
                    items={[
                      {
                        value: `max ageing:${maxAgeing} Days`,
                      },
                    ]}
                  />
                );
              },
              onPress: ({row}) => {
                navigation.navigate('customer-ageing-invoices', {
                  row,
                  invoiceFilter: {
                    customer: row._id,
                    due_date: {$gte: '1/04/2012', $lte: new Date()},
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_0_30',
              aggregate: true,
              header: '0-31 days',
              field: 'limit_0_30',
              type: 'currency',
              width: 200,
              onPress: ({row}) => {
                navigation.navigate('customer-ageing-invoices', {
                  row,
                  invoiceFilter: {
                    customer: {$in: row?.limit0_30Ids},
                    due_date: row?.limit0_30Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_30_90',
              aggregate: true,
              header: '31-90 days',
              field: 'limit_30_90',
              type: 'currency',
              width: 200,
              onPress: ({row}) => {
                navigation.navigate('customer-ageing-invoices', {
                  row,
                  invoiceFilter: {
                    customer: {$in: row?.limit30_90Ids},
                    due_date: row?.limit30_90Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_91_270',
              aggregate: true,
              header: '91-270 days',
              field: 'limit_91_270',
              type: 'currency',
              width: 200,
              onPress: ({row}) => {
                navigation.navigate('customer-ageing-invoices', {
                  row,
                  invoiceFilter: {
                    customer: {$in: row?.limit90_270Ids},
                    due_date: row?.limit90_270Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_270_365',
              aggregate: true,
              header: '270-365 days',
              field: 'limit_270_365',
              type: 'currency',
              width: 200,
              onPress: ({row}) => {
                navigation.navigate('customer-ageing-invoices', {
                  row,
                  invoiceFilter: {
                    customer: {$in: row?.limit270_365Ids},
                    due_date: row?.limit270_365Date,
                    ...invoiceFilter,
                  },
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'limit_365',
              aggregate: true,
              header: '>365 days',
              field: 'limit_365',
              type: 'currency',
              width: 200,
              onPress: ({row}) => {
                navigation.navigate('customer-ageing-invoices', {
                  row,
                  invoiceFilter: {
                    customer: {$in: row?.limit365PlusIds},
                    due_date: row?.limit365PlusDate,
                    ...invoiceFilter,
                  },
                });
              },
            },
          ],
        },
      ]}
    />
  );
};
