import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

const checkCurlyBraces = sentence => {
  const regex = /\{{3}[^{}]*\}{3}/g;

  let processedSentence = sentence.replace(regex, '');

  if (processedSentence.includes('{{{') || processedSentence.includes('}}}')) {
    return false;
  }

  return true;
};

export const DripInmailFormNew = props => {
  const {
    route: {params},
    header = 'Add Drip Inmail',
    navigation,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/dripInmailContents',
    eventSourceId: ['dripInmailAdded'],
  });
  const {defaultValues} = params;
  return (
    <Form
      fields={{
        inmail_subject: 1,
        inmail_body: 1,
        drip_sequence: 1,
        send_after: 1,
        when: 1,
        value_proposition: 1,
        tags: 1,
        type: 1,
        name: 1,
        personalize_with_ai: 1,
        personalize_subject_with_ai: 1,
        email_signature: {name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        created_on: getZeroTimeDate(new Date()),
        status: 'active',
        ...defaultValues,
      }}
      layoutFields={[
        {
          field: 'type',
          type: 'autoComplete',
          label: 'Type',
          placeholder: 'Type',
          options: [
            'Message Series',
            'Connection Message',
            'Offer',
            'Email',
            'Inmail',
          ],
        },
        {
          label: 'Name',
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Subject',
          placeholder: 'Subject',
          field: 'inmail_subject',
          type: 'textArea',
          visible: ({values: {type} = {}}) => {
            return type === 'Email' || type === 'Inmail';
          },
          validate: value => {
            if (value) {
              const isValid = checkCurlyBraces(value);
              if (!isValid) {
                return 'Check curly braces';
              }
            }
          },
        },

        {
          label: 'Body',
          placeholder: 'Body',
          field: 'inmail_body',
          type: 'textArea',
          validate: value => {
            if (value) {
              const isValid = checkCurlyBraces(value);
              if (!isValid) {
                return 'Check curly braces';
              }
            }
          },
        },
        {
          field: 'email_signature',
          type: 'autoComplete',
          label: 'Email Signature',
          placeholder: 'Email Signature',
          api: `/emailsignatures`,
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values: {type} = {}}) => {
            return type === 'Email';
          },
          onCreate: () => {
            navigation.navigate(`email-signature-add-form`, {
              ...params,
            });
          },
        },
        {
          field: 'personalize_with_ai',
          type: 'checkbox',
          label: 'Personalize With AI',
        },
        {
          field: 'personalize_subject_with_ai',
          type: 'checkbox',
          label: 'Personalize Subject With AI',
          visible: ({values: {type} = {}}) => {
            return type === 'Email' || type === 'Inmail';
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditDripInmailFormNew = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <DripInmailFormNew
      mode="edit"
      api={`/dripInmailContents/${_id}`}
      header={'Edit Drip Inmail'}
      {...props}
    />
  );
};

export const DetailDripInmailFormNew = props => {
  return (
    <DripInmailFormNew
      mode="edit"
      readOnly={true}
      header={'Detail Drip Inmail'}
      {...props}
    />
  );
};
