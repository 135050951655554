export const ToggleStyles = ({theme: {colors, fonts, shadows}}) => {
  return {
    containerStyle: {
      backgroundColor: colors.OUTLINE,
      borderColor: colors.OUTLINE,
      borderRadius: 6,
    },
    itemStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 6,
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 16,
      paddingRight: 16,
      borderWidth: 1,
      borderColor: 'transparent',
      cursor: 'pointer',
    },
    activeItemStyle: {
      backgroundColor: colors.BACKGROUND,
      borderColor: colors.OUTLINE,
      ...shadows.Text,
    },
    labelStyle: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.SIDE_NAVIGATION,
    },
  };
};
