import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

const ProjectTablekpi = props => {
  let {
    route: {params},
  } = props || {};
  let {outCome} = params || {};
  let employeeData = outCome?.opportunityId || [];
  return (
    <Table
      eventSourceId={'ProjectTablekpi'}
      renderHeader={() => (
        <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
      )}
      data={employeeData}
      columns={[
        {
          field: 'projectName',
          header: 'Name',
          responsive: 'sm',
        },
        {
          field: 'output',
          header: 'Output',
          responsive: 'sm',
        },
      ]}
      {...props}
    />
  );
};

export default ProjectTablekpi;
