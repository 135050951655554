import {useEffect, useMemo, useState} from 'react';
import {useAutoComplete} from './useAutoComplete';

export const useCascader = ({
  setFieldValue,
  changeOnSelect,
  onChangeValue,
  ...props
}) => {
  let {values, cascades} = props;

  const [currentIndex, setIndex] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);

  const autoCompleteProps = useAutoComplete({
    ...props,
  });

  const {showModal, blurInput} = autoCompleteProps;

  const currentCascade = useMemo(() => {
    return cascades?.[currentIndex] || {};
  }, [currentIndex]);

  const cascadeExtraProps = useMemo(() => {
    let {params} = currentCascade || {};
    if (typeof params === 'function') {
      const stateParams = selectedValues.reduce((acc, item, index) => {
        const {field} = cascades[index];
        acc[field] = item;
        return acc;
      }, {});
      params = params({values: {...values, ...stateParams}});
    } else {
      const prevCascade = cascades?.[currentIndex - 1];
      const prevState = selectedValues?.[currentIndex - 1];
      const {field} = prevCascade || {};
      params = {
        ...params,
        [field]: prevState,
      };
    }
    return {params};
  }, [currentCascade, selectedValues, cascades, currentIndex]);

  const onSelect = (rowProps, e) => {
    const {item, index} = rowProps;
    if (changeOnSelect) {
      setFieldValue(currentCascade.field, item);
      cascades.slice(currentIndex + 1).forEach(cascade => {
        const {field} = cascade;
        setFieldValue(field, null);
      });
    }
    if (cascades?.length && currentIndex < cascades?.length - 1) {
      const newSelectedValues = [...selectedValues];
      newSelectedValues[currentIndex] = item;
      setSelectedValues(newSelectedValues);
      setIndex(currentIndex + 1);
    } else {
      if (!changeOnSelect) {
        [...selectedValues, item].forEach((item, index) => {
          const {field} = cascades[index];
          setFieldValue(field, item);
        });
      }
      currentCascade?.onSelect?.(rowProps);
      blurInput?.(e);
    }
  };

  const onCreate = currentCascade?.onCreate
    ? ({reloadData, searchValue, item}, e) => {
        currentCascade.onCreate({
          searchValue,
          item,
          onChange: newValue => {
            reloadData?.();
            onSelect({item: newValue}, e);
          },
        });
      }
    : void 0;

  const onBack = () => {
    if (currentIndex > 0) {
      const stateCopy = [...selectedValues];
      stateCopy.pop();
      setSelectedValues(stateCopy);
      setIndex(currentIndex - 1);
    }
  };

  const onClearValue = () => {
    cascades.forEach(cascade => {
      const {field} = cascade;
      setFieldValue(field, null);
    });
  };

  useEffect(() => {
    if (showModal === false) {
      setTimeout(_ => {
        setIndex(0);
        setSelectedValues([]);
      }, 500);
    }
  }, [showModal]);

  return {
    ...autoCompleteProps,
    currentIndex,
    currentCascade,
    selectedValues,
    onBack,
    onSelect,
    onCreate,
    onClearValue,
    cascadeExtraProps,
  };
};
