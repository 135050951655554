import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {potential_customer: {website = ''} = {}} = row || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {row?.potential_customer?.name}
      </Text>
    </TouchableOpacity>
  );
};

const RenderLinkedInUrl = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {contact_id: {linkedin_url = '', name, first_name, last_name} = {}} =
    row || {};
  const underline = linkedin_url ? {textDecoration: 'underline'} : void 0;

  // Akshat Garg 20/02/24 - checks the linkedin_url, sets it to empty string if it doesn't exists
  if (linkedin_url) {
    linkedin_url =
    linkedin_url &&
    (linkedin_url.startsWith('https') || linkedin_url.startsWith('http'))
      ? linkedin_url
      : `https://${linkedin_url}`;
  }

  const displayName = name ? name : `${first_name} ${last_name}`;

  return (
    <TouchableOpacity
      onPress={() => {
        linkedin_url && Linking.openURL(linkedin_url, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};

const EmailUnsubscribeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      api={`/campaignactions`}
      filter={{
        email_unsubscribe_on: {$exists: true},
      }}
      fields={{
        _id: 1,
        contact_id: {linkedin_url: 1, name: 1, first_name: 1, last_name: 1, designation: 1}, // Akshat Garg 20/02/24 - gets first_name, last_name
        potential_customer: {
          name: 1,
          website: 1,
          country: {name: 1, code: 1},
          website_summary: 1,
          industry: {name: 1},
        },
        campaign_id: {name: 1},
        email_unsubscribe_on: 1,
      }}
      sort={{
        email_unsubscribe_on: -1,
      }}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
        },
        {
          header: 'Website',
          render: RenderName,
        },
        {
          header: 'Campaign Name',
          field: 'campaign_id.name',
          type: 'text',
        },
        {
          header: 'Unsubscribe Date',
          field: 'email_unsubscribe_on',
          type: 'date',
          width: 200,
        },
      ]}
    />
  );
};

export default EmailUnsubscribeList;
