import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EscalationReasonForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projectescalationreasons',
    eventSourceId: 'escalationreasons',
  });
  return (
    <Form
      api={`/projectescalationreasons/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'reason',
          label: 'Reason',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddEscalationReasonForm = props => {
  return <EscalationReasonForm header="Add Escalation Reason" {...props} />;
};

export const EditEscalationReasonForm = props => {
  return (
    <EscalationReasonForm
      mode="edit"
      header={'Update Escalation Reason'}
      {...props}
    />
  );
};
