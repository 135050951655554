import React from 'react';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {AddButton} from '../../../components/button/AddButton';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';

export const UserOrganizationTable = props => {
  const {navigation} = props;
  const filterProps = useFilter({});
  const {searchValue} = filterProps;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'UserOrganization',
  });

  const deleteUserOrganization = ({row}) => {
    deleteInvoke({
      uri: `/userorganizations/${row?._id}`,
    });
  };

  return (
    <Table
      search={searchValue}
      eventSourceId={['UserOrganization']}
      searchFields={['name', 'domain', 'hd']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Project Configuration"
            actions={[
              <SearchFilter {...filterProps} />,
              <AddButton
                title={'Project Configuration'}
                view={'add-user-organization'}
              />,
            ]}
          />
        );
      }}
      api={`/userorganizations`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-user-organization`, {
          user_organization: row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          header: 'Domain',
          field: 'domain',
          maxChips: 5,
          render: MultiColorTag,
        },
        {
          type: 'text',
          header: 'Hd',
          field: 'hd',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: deleteUserOrganization,
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
        },
      ]}
    />
  );
};
