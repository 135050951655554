import React from 'react';
import { Form, MultiStepForm } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { getInterviewDetails } from '../controllers/CandidateController';

const roundDetails = {
  first: {
    time: 'first_round_interview_time',
    date: 'first_round_interview_date',
    interviewer: 'first_round_interview_panel_members',
    label: 'First Round',
    evaluation: 'first_round_evaluation',
    remarks: 'first_round_remarks',
  },
  second: {
    time: 'second_round_interview_time',
    date: 'second_round_interview_date',
    interviewer: 'second_round_interview_panel_members',
    label: 'Second Round',
    evaluation: 'second_round_evaluation',
    remarks: 'second_round_remarks',
  },
  third: {
    time: 'third_round_interview_time',
    date: 'third_round_interview_date',
    interviewer: 'third_round_interview_panel_members',
    label: 'Third Round',
    evaluation: 'third_round_evaluation',
    remarks: 'third_round_remarks',
  },
  fourth: {
    time: 'fourth_round_interview_time',
    date: 'fourth_round_interview_date',
    interviewer: 'fourth_round_interview_panel_members',
    label: 'Fourth Round',
    evaluation: 'fourth_round_evaluation',
    remarks: 'fourth_round_remarks',
  },
  fifth: {
    time: 'fifth_round_interview_time',
    date: 'fifth_round_interview_date',
    interviewer: 'fifth_round_interview_panel_members',
    evaluation: 'fifth_round_evaluation',
    label: 'Fifth Round',
    remarks: 'fifth_round_remarks',
  },
  hr: {
    time: 'hr_round_interview_time',
    date: 'hr_round_interview_date',
    interviewer: 'hr_round_interview_panel_members',
    label: 'Hr Round',
    evaluation: 'hr_round_evaluation',
    remarks: 'hr_round_remarks',
  },
};

export const DropCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, candidate_previous_status} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Dropping a Candidate',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={{
        _id: candidateId,
        dropped_in: candidate_previous_status,
        is_nurturable: false,
        status: 'Dropped',
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'dropping_reason',
          label: 'Select Reason For Dropping',
          options: [
            'Compensation Issue',
            'Location Issue',
            'Role Issue',
            'Health Issue',
            'Others',
          ],
        },
        {
          type: 'textArea',
          field: 'dropping_remarks',
          label: 'Dropping Remarks',
        },
        {
          label: 'Mark Nurturable',
          type: 'checkbox',
          field: 'is_nurturable',
        },
        {
          label: 'Nurturable Remarks',
          type: 'textArea',
          field: 'nurturable_remarks',
          visible: ({values} = {}) => {
            return values && values.is_nurturable;
          },
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const ChangeRecruiterOfCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, candidate_previous_recruiter} =
    params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Update Recruiter',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={{
        _id: candidateId,
        recruiter: candidate_previous_recruiter,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Recruiter',
          placeholder: 'Recruiter',
          field: 'recruiter',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const MovingCandidateToFirstRoundForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, candidate_resume} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Moving to Interview Rounds',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={{
        _id: candidateId,
        candidate_resume,
        status: 'InterviewRound',
        current_round: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Interview Panel Members',
          placeholder: 'Select',
          field: 'first_round_interview_panel_members',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 12,
        },
        {
          type: 'date',
          field: 'first_round_interview_date',
          label: 'Interview Date',
          placeholder: 'Interview Date',
          size: 12,
        },
        {
          type: 'duration',
          field: 'first_round_interview_time',
          label: 'Interview Duration',
          placeholder: 'Interview Duration',
          size: 6,
        },
        {
          type: 'file',
          label: 'Resume',
          field: 'candidate_resume',
          multiple: true,
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const ChangeInterviewDateOfCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, status, current_round} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  let defaultValues = {_id: candidateId};
  let currently_on;
  if (status == 'HrRound') {
    currently_on = 'hr';
  } else {
    if (current_round == 1) {
      currently_on = 'first';
    } else if (current_round == 2) {
      currently_on = 'second';
    } else if (current_round == 3) {
      currently_on = 'third';
    } else if (current_round == 4) {
      currently_on = 'fourth';
    } else if (current_round == 5) {
      currently_on = 'fifth';
    }
  }

  defaultValues['currently_on'] = currently_on;
  defaultValues['previous_interview_date'] =
    params[roundDetails[currently_on]?.date];

  return (
    <Form
      header={{
        title: 'Change Interview Date',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const {_id, current_interview_date, currently_on} = data || {};
        const current_field = roundDetails[currently_on]?.date;
        return {data: {_id, [current_field]: current_interview_date}};
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'previous_interview_date',
          label: 'Previous Interview Date',
          placeholder: 'Previous Interview Date',
          size: 12,
          readOnly: true,
        },
        {
          type: 'date',
          field: 'current_interview_date',
          label: 'Current Interview Date',
          placeholder: 'Current Interview Date',
          required: true,
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const ChangeInterviewerOfCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, status, current_round} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  let defaultValues = {_id: candidateId};
  let currently_on;
  if (status == 'HrRound') {
    currently_on = 'hr';
  } else {
    if (current_round == 1) {
      currently_on = 'first';
    } else if (current_round == 2) {
      currently_on = 'second';
    } else if (current_round == 3) {
      currently_on = 'third';
    } else if (current_round == 4) {
      currently_on = 'fourth';
    } else if (current_round == 5) {
      currently_on = 'fifth';
    }
  }

  defaultValues['currently_on'] = currently_on;
  defaultValues['previous_interviewer'] =
    params[roundDetails[currently_on]?.interviewer];

  return (
    <Form
      header={{
        title: 'Change Interviewer',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const {_id, current_interviewer, currently_on} = data || {};
        const current_field = roundDetails[currently_on]?.interviewer;
        return {data: {_id, [current_field]: current_interviewer}};
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Previous Interviewer',
          placeholder: 'Interviewer',
          field: 'previous_interviewer',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          readOnly: true,
          size: 12,
        },
        {
          label: 'Current Interviewer',
          placeholder: 'Interviewer',
          field: 'current_interviewer',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const ChangeInterviewTimeOfCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, status, current_round} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  let defaultValues = {_id: candidateId};
  let currently_on;
  if (status == 'HrRound') {
    currently_on = 'hr';
  } else {
    if (current_round == 1) {
      currently_on = 'first';
    } else if (current_round == 2) {
      currently_on = 'second';
    } else if (current_round == 3) {
      currently_on = 'third';
    } else if (current_round == 4) {
      currently_on = 'fourth';
    } else if (current_round == 5) {
      currently_on = 'fifth';
    }
  }

  defaultValues['currently_on'] = currently_on;
  defaultValues['previous_interview_time'] =
    params[roundDetails[currently_on]?.time];

  return (
    <Form
      header={{
        title: 'Change Interview Time',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const {_id, current_interview_time, currently_on} = data || {};
        const current_field = roundDetails[currently_on]?.time;
        return {data: {_id, [current_field]: current_interview_time}};
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'duration',
          field: 'previous_interview_time',
          label: ' Previous Interview Duration',
          placeholder: 'Duration',
          size: 12,
          readOnly: true,
        },
        {
          type: 'duration',
          field: 'current_interview_time',
          label: 'Current Interview Duration',
          placeholder: 'Duration',
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const HoldCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, candidate_previous_status} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Putting  a Candidate on Hold',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={{
        _id: candidateId,
        hold_in: candidate_previous_status,
        status: 'On Hold',
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'textArea',
          field: 'onhold_remarks',
          label: 'Remarks',
        },
      ]}
      {...props}
    />
  );
};

export const RejectCandidateForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, candidate_previous_status} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  let options = [
    'Technically Not Strong',
    'Logically Not Strong',
    'Communication Not Strong',
    'Attitude Issue',
    'Others',
  ];

  if (candidate_previous_status == 'HrRound') {
    options = [
      'No Clarity of Thought',
      'Logically Not Strong',
      'Communication Not Strong',
      'Attitude Issue',
      'Salary Issue',
      'Others',
    ];
  }
  return (
    <Form
      header={{
        title: 'Rejecting a Candidate',
        secondaryTitle: candidateName ?? '',
      }}
      defaultValues={{
        _id: candidateId,
        rejected_in: candidate_previous_status,
        is_nurturable: false,
        status: 'Rejected',
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'rejected_reason',
          label: 'Select Reason For Dropping',
          options,
        },
        {
          type: 'textArea',
          field: 'rejected_remarks',
          label: 'Rejected Remarks',
        },
        {
          label: 'Mark Nurturable',
          type: 'checkbox',
          field: 'is_nurturable',
        },
        {
          label: 'Nurturable Remarks',
          type: 'textArea',
          field: 'nurturable_remarks',
          visible: ({values} = {}) => {
            return values && values.is_nurturable;
          },
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const MovingCandidateToNextRoundForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, current_round, status} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  const {currentRound, nextRound} = getInterviewDetails({
    status,
    current_round,
  });

  const secondaryTitle = `You are moving ${candidateName} 
    from ${currentRound ?? ''} round to ${nextRound ?? ''} round
    `;

  let defaultValues = {
    _id: candidateId,
  };

  let currently_on, to_move_on;
  if (nextRound == 'Hr') {
    defaultValues['status'] = 'HrRound';
  } else {
    defaultValues['current_round'] = current_round + 1;
  }

  if (current_round == 1) {
    currently_on = 'first';
    to_move_on = 'second';
  } else if (current_round == 2) {
    currently_on = 'second';
    to_move_on = 'third';
  } else if (current_round == 3) {
    currently_on = 'third';
    to_move_on = 'fourth';
  } else if (current_round == 4) {
    currently_on = 'fourth';
    to_move_on = 'fifth';
  } else if (current_round == 5) {
    currently_on = 'fifth';
    to_move_on = 'hr';
  }

  const currentroundLabel = roundDetails[currently_on]?.label;
  const nextroundLabel = roundDetails[to_move_on]?.label;

  const evaluationField = roundDetails[currently_on]?.evaluation;
  const remarks_field = roundDetails[currently_on]?.remarks;
  const interview_panel_field = roundDetails[to_move_on]?.interviewer;
  const interview_date_field = roundDetails[to_move_on]?.date;
  const interview_time_field = roundDetails[to_move_on]?.time;

  return (
    <Form
      header={{
        title: 'Moving to Interview Rounds',
        secondaryTitle,
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'radioGroup',
          field: evaluationField,
          options: ['Deployable', 'Trainable'],
          label: `${currentroundLabel} Evaluation`,
        },
        {
          type: 'textArea',
          field: remarks_field,
          label: `${currentroundLabel} Remarks`,
        },
        {
          label: `${nextroundLabel} Interview Panel Members`,
          placeholder: 'Select',
          field: interview_panel_field,
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 12,
        },
        {
          type: 'date',
          field: interview_date_field,
          label: `${nextroundLabel} Interview Date`,
          placeholder: 'Interview Date',
          size: 12,
        },
        {
          type: 'duration',
          field: interview_time_field,
          label: `${nextroundLabel} Interview Duration`,
          placeholder: 'Interview Duration',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const MovingCandidateToHrRoundForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {candidateId, candidateName, current_round, status} = params || {};
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });

  const {currentRound} = getInterviewDetails({
    status,
    current_round,
  });

  const secondaryTitle = `You are moving ${candidateName} 
    from ${currentRound ?? ''} round to Hr round
    `;

  const defaultValues = {
    _id: candidateId,
    current_round,
    status: 'HrRound',
  };

  let currently_on;

  if (current_round == 1) {
    currently_on = 'first';
  } else if (current_round == 2) {
    currently_on = 'second';
  } else if (current_round == 3) {
    currently_on = 'third';
  } else if (current_round == 4) {
    currently_on = 'fourth';
  } else if (current_round == 5) {
    currently_on = 'fifth';
  }

  const currentroundLabel = roundDetails[currently_on]?.label;

  const evaluationField = roundDetails[currently_on]?.evaluation;
  const remarks_field = roundDetails[currently_on]?.remarks;

  return (
    <Form
      header={{
        title: 'Moving to Interview Rounds',
        secondaryTitle,
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'radioGroup',
          field: evaluationField,
          options: ['Deployable', 'Trainable'],
          label: `${currentroundLabel} Evaluation`,
        },
        {
          type: 'textArea',
          field: remarks_field,
          label: `${currentroundLabel} Remarks`,
        },
        {
          label: `Hr Round Interview Panel Members`,
          placeholder: 'Select',
          field: 'hr_round_interview_panel_members',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 12,
        },
        {
          type: 'date',
          field: 'hr_round_interview_date',
          label: `Hr Round Interview Date`,
          placeholder: 'Interview Date',
          size: 12,
        },
        {
          type: 'duration',
          field: 'hr_round_interview_time',
          label: `Hr Round Interview Duration`,
          placeholder: 'Interview Duration',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const DetailSummaryOfAllRoundsOfCandidate = props => {
  const {route: {params} = {}} = props;
  const {candidateId, candidateName} = params || {};
  const onSubmit = () => {};
  return (
    <MultiStepForm
      header={{
        title: 'Feedback',
        secondaryTitle: candidateName,
      }}
      onSubmit={onSubmit}
      api={`/candidate/detail/${candidateId}`}
      mode="edit"
      clickableTabs
      readOnly={true}
      steps={[
        {
          title: 'First Round',
          fields: [
            {
              label: 'Moved On',
              field: 'first_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Evaluation',
              field: 'first_round_evaluation',
              type: 'text',
              size: 6,
            },
            {
              label: 'Interview Date',
              field: 'first_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Interview Time',
              field: 'first_round_interview_time',
              type: 'number',
              size: 6,
            },
            {
              label: 'Remarks',
              field: 'first_round_remarks',
              type: 'text',
              size: 6,
            },
            {
              label: `Interview Panel Members`,
              placeholder: 'Select',
              field: 'first_round_interview_panel_members',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 12,
            },
            {
              label: 'Feedbacks',
              fields: [
                {
                  field: 'first_round_interview_feedback',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          header: 'Interviewer',
                          field: 'interviewer',
                          type: 'userAvatarChip',
                        },
                        {field: 'evaluation', type: 'text', header: 'Category'},
                        {
                          type: 'text',
                          header: 'Feedback',
                          field: 'feedback',
                        },
                        {
                          type: 'text',
                          header: 'Rating',
                          field: 'candidate_rating',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Second Round',
          fields: [
            {
              label: 'Moved On',
              field: 'second_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Evaluation',
              field: 'second_round_evaluation',
              type: 'text',
              size: 6,
            },
            {
              label: 'Interview Date',
              field: 'second_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Interview Time',
              field: 'second_round_interview_time',
              type: 'number',
              size: 6,
            },
            {
              label: 'Remarks',
              field: 'second_round_remarks',
              type: 'text',
              size: 6,
            },
            {
              label: `Interview Panel Members`,
              placeholder: 'Select',
              field: 'second_round_interview_panel_members',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 12,
            },
            {
              label: 'Feedbacks',
              fields: [
                {
                  field: 'second_round_interview_feedback',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          header: 'Interviewer',
                          field: 'interviewer',
                          type: 'userAvatarChip',
                        },
                        {field: 'evaluation', type: 'text', header: 'Category'},
                        {
                          type: 'text',
                          header: 'Feedback',
                          field: 'feedback',
                        },
                        {
                          type: 'text',
                          header: 'Rating',
                          field: 'candidate_rating',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Third Round',
          fields: [
            {
              label: 'Moved On',
              field: 'third_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Evaluation',
              field: 'third_round_evaluation',
              type: 'text',
              size: 6,
            },
            {
              label: 'Interview Date',
              field: 'third_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Interview Time',
              field: 'third_round_interview_time',
              type: 'number',
              size: 6,
            },
            {
              label: 'Remarks',
              field: 'third_round_remarks',
              type: 'text',
              size: 6,
            },
            {
              label: `Interview Panel Members`,
              placeholder: 'Select',
              field: 'third_round_interview_panel_members',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 12,
            },
            {
              label: 'Feedbacks',
              fields: [
                {
                  field: 'third_round_interview_feedback',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          header: 'Interviewer',
                          field: 'interviewer',
                          type: 'userAvatarChip',
                        },
                        {field: 'evaluation', type: 'text', header: 'Category'},
                        {
                          type: 'text',
                          header: 'Feedback',
                          field: 'feedback',
                        },
                        {
                          type: 'text',
                          header: 'Rating',
                          field: 'candidate_rating',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Fourth Round',
          fields: [
            {
              label: 'Moved On',
              field: 'fourth_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Evaluation',
              field: 'fourth_round_evaluation',
              type: 'text',
              size: 6,
            },
            {
              label: 'Interview Date',
              field: 'fourth_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Interview Time',
              field: 'fourth_round_interview_time',
              type: 'number',
              size: 6,
            },
            {
              label: 'Remarks',
              field: 'fourth_round_remarks',
              type: 'text',
              size: 6,
            },
            {
              label: `Interview Panel Members`,
              placeholder: 'Select',
              field: 'fourth_round_interview_panel_members',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 12,
            },
            {
              label: 'Feedbacks',
              fields: [
                {
                  field: 'fourth_round_interview_feedback',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          header: 'Interviewer',
                          field: 'interviewer',
                          type: 'userAvatarChip',
                        },
                        {field: 'evaluation', type: 'text', header: 'Category'},
                        {
                          type: 'text',
                          header: 'Feedback',
                          field: 'feedback',
                        },
                        {
                          type: 'text',
                          header: 'Rating',
                          field: 'candidate_rating',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Fifth Round',
          fields: [
            {
              label: 'Moved On',
              field: 'fifth_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Evaluation',
              field: 'fifth_round_evaluation',
              type: 'text',
              size: 6,
            },
            {
              label: 'Interview Date',
              field: 'fifth_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Interview Time',
              field: 'fifth_round_interview_time',
              type: 'number',
              size: 6,
            },
            {
              label: 'Remarks',
              field: 'fifth_round_remarks',
              type: 'text',
              size: 6,
            },
            {
              label: `Interview Panel Members`,
              placeholder: 'Select',
              field: 'fifth_round_interview_panel_members',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 12,
            },
            {
              label: 'Feedbacks',
              fields: [
                {
                  field: 'fifth_round_interview_feedback',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          header: 'Interviewer',
                          field: 'interviewer',
                          type: 'userAvatarChip',
                        },
                        {field: 'evaluation', type: 'text', header: 'Category'},
                        {
                          type: 'text',
                          header: 'Feedback',
                          field: 'feedback',
                        },
                        {
                          type: 'text',
                          header: 'Rating',
                          field: 'candidate_rating',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Hr Round',
          fields: [
            {
              label: 'Moved On',
              field: 'hr_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Evaluation',
              field: 'hr_round_evaluation',
              type: 'text',
              size: 6,
            },
            {
              label: 'Interview Date',
              field: 'hr_round_interview_date',
              type: 'date',
              size: 6,
            },
            {
              label: 'Interview Time',
              field: 'hr_round_interview_time',
              type: 'number',
              size: 6,
            },
            {
              label: 'Remarks',
              field: 'hr_round_remarks',
              type: 'text',
              size: 6,
            },
            {
              label: `Interview Panel Members`,
              placeholder: 'Select',
              field: 'hr_round_interview_panel_members',
              type: 'multiAutoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 12,
            },
            {
              label: 'Feedbacks',
              fields: [
                {
                  field: 'hr_round_interview_feedback',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      columns={[
                        {
                          header: 'Interviewer',
                          field: 'interviewer',
                          type: 'userAvatarChip',
                        },
                        {field: 'evaluation', type: 'text', header: 'Category'},
                        {
                          type: 'text',
                          header: 'Feedback',
                          field: 'feedback',
                        },
                        {
                          type: 'text',
                          header: 'Rating',
                          field: 'candidate_rating',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const MarkAccepted = props => {
  const {
    route: {params},
  } = props;
  const {candidate} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Mark Accepted',
        secondaryTitle: candidate.name,
      }}
      api={`/candidates/${candidate._id}`}
      mode="edit"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          status: 'Offer Accepted',
        },
      })}
      onSubmit={onSubmit}
      fields={{
        status: 1,
        offer_accepted_date: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'offer_accepted_date',
          label: 'Offer Accepted Date',
          placeholder: 'Offer Accepted Date',
          size: 6,
        },
      ]}
    />
  );
};

export const DetailReasonOfRejection = props => {
  const {
    route: {params},
  } = props;
  const {candidateId, candidateName} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      readOnly={true}
      header={{
        title: 'Detail Reason Of Rejection',
        secondaryTitle: candidateName,
      }}
      api={`/candidates/${candidateId}`}
      mode="edit"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Rejected Date',
          type: 'date',
          placeholder: 'Rejected',
          field: 'rejected_date',
          size: 6,
        },
        {
          label: 'Rejected In',
          type: 'text',
          field: 'rejected_in',
          size: 6,
        },
        {
          label: 'Rejected Reason',
          field: 'rejected_reason',
          type: 'text',
          size: 6,
        },
        {
          label: 'Remark',
          field: 'rejected_remarks',
          type: 'text',
          size: 6,
        },
      ]}
    />
  );
};

export const DetailReasonOfMakingNurturable = props => {
  const {
    route: {params},
  } = props;
  const {candidateId, candidateName} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      readOnly={true}
      header={{
        title: 'Detail Reason Of Making Nurturable',
        secondaryTitle: candidateName,
      }}
      api={`/candidates/${candidateId}`}
      mode="edit"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'nurturable_remarks',
          label: 'Nurturable Remarks',
          type: 'text',
          placeholder: 'Nurturable Remarks',
        },
      ]}
    />
  );
};

export const MoveToAnotherJobOpening = props => {
  const {
    route: {params},
  } = props;
  const {candidateId, candidateName} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Move To Another Job Opening',
        secondaryTitle: candidateName,
      }}
      api={`/candidates/${candidateId}`}
      mode="edit"
      fields={{
        job_opening: {
          name: 1,
        },
      }}
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          status: 'Sortlisted',
          candidate_created_on: new Date(),
          first_round_interview_feedback: null,
          second_round_interview_feedback: null,
          third_round_interview_feedback: null,
          fourth_round_interview_feedback: null,
          hr_round_interview_feedback: null,
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'job_opening',
          type: 'autoComplete',
          api: '/jobopenings',
          placeholder: 'Job Opening',
          suggestionField: 'position_title.name',
          getDisplayValue: ({value}) => {
            return value?.position_title?.name;
          },
          required: true,
          fields: {
            position_title: {
              name: 1,
            },
          },
        },
      ]}
    />
  );
};

export const DetailReasonOfDrop = props => {
  const {
    route: {params},
  } = props;
  const {candidateId, candidateName} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      readOnly={true}
      header={{
        title: 'Detail Reason Of Rejection',
        secondaryTitle: candidateName,
      }}
      api={`/candidates/${candidateId}`}
      mode="edit"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Drop Date',
          type: 'date',
          placeholder: 'Rejected',
          field: 'dropping_date',
          size: 6,
        },
        {
          label: 'Drop In',
          type: 'text',
          field: 'dropped_in',
          size: 6,
        },
        {
          label: 'Drop Reason',
          field: 'dropping_reason',
          type: 'text',
          size: 6,
        },
        {
          label: 'Drop Remark',
          field: 'dropping_remarks',
          type: 'text',
          size: 6,
        },
      ]}
    />
  );
};

export const MarkJoined = props => {
  const {
    route: {params},
  } = props;
  const {candidate} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Mark Accepted',
        secondaryTitle: candidate.name,
      }}
      api={`/candidates/${candidate._id}`}
      mode="edit"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          status: 'Joined',
        },
      })}
      onSubmit={onSubmit}
      fields={{
        status: 1,
        offer_accepted_date: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'joining_date',
          label: 'Joining Date',
          placeholder: 'Joining Date',
          size: 6,
        },
      ]}
    />
  );
};
export const MarkNurturable = props => {
  const {
    route: {params},
  } = props;
  const {candidate} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Mark Nurturable',
        secondaryTitle: candidate.name,
      }}
      api={`/candidates/${candidate._id}`}
      mode="edit"
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          is_nurturable: true,
          nurturable_date: new Date(),
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Remarks (optional)',
          type: 'textArea',
          field: 'nurturable_remarks',
        },
      ]}
    />
  );
};

export const ChangeCandidateOfferDate = props => {
  const {
    route: {params},
  } = props;
  const {candidate} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/candidates',
    eventSourceId: 'candidate',
  });
  return (
    <Form
      header={{
        title: 'Change Offer Date',
        secondaryTitle: candidate.name,
      }}
      api={'/candidates/' + candidate._id}
      mode="edit"
      onSubmit={onSubmit}
      fields={{
        offer_raised_date: 1,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'offer_raised_date',
          label: 'Offer Raised Date',
        },
      ]}
    />
  );
};
