import React from 'react';
import {Table} from '../../../components/table/Table';

export const ActiveElementsTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId="ActiveElement"
      api={`/ActiveElements`}
      fields={{label: 1, filter: 1, department: {name: 1}, model: {name: 1}}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`active-elements-detail`, {
          activeElementId: row._id,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Label',
        },
        {
          type: 'text',
          field: 'filter',
          header: 'Filter',
        },
        {
          type: 'text',
          field: 'department.name',
          header: 'Department',
        },
        {
          type: 'text',
          field: 'model.name',
          header: 'Model',
        },
      ]}
    />
  );
};
