import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';

export const StartNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    // uri: '/employees',
    uri: `/setNoduesData/${row?._id}`,
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });

  return (
    <Form
      {...props}
      // defaultValues={{
      //   _id: row?._id,
      //   applied_for_resignation: true,
      //   resign_status: 'Accepted',
      //   training_cost_recovery_pending: false,
      //   resign_type: 'layoff',
      // }}
      onSubmit={onSubmit}
      // afterSubmit={props => {
      //   invoke({
      //     uri: `/nodues`,
      //     props: {
      //       employee: row?._id,
      //       resign_type: props?.data?.resign_type,
      //       // fnf_date: getZeroTimeDate(new Date()),
      //       nodue_start_date: getZeroTimeDate(new Date()),
      //     },
      //   });
      // }}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'resign_type',
          required: true,
          options: [
            {value: 'layoff', label: 'Layoff'},
            {value: 'terminated', label: 'Terminated'},
            {value: 'internship completion', label: 'Internship Completion'},
            {value: 'bond breach', label: 'Bond Breach'},
            {value: 'manual', label: 'Manual'},
          ],
        },
        {
          label: 'Resign Date',
          field: 'resign_date_new',
          type: 'date',
          required: true,
        },
        {
          label: 'Expected Reliveing Date',
          field: 'expected_relieving_date',
          type: 'date',
          required: true,
        },
        {label: 'Remarks', field: 'remarks', type: 'text', required: true},
        {
          label: 'Upload document',
          field: 'resign_attachment',
          type: 'file',
          options: {bucketName: 'manaze'},
        },
        {
          label: 'Send Mail to Stakeholder',
          field: 'send_mail_to_stakeholder',
          type: 'checkbox',
        },
      ]}
    />
  );
};

export const StartNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <StartNoDues
      header={{
        title: 'Set Resign Type',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const ClearTeamNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row, fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesTeam',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesTeam'],
  });

  let readOnly = false;
  if (fromHr) {
    readOnly = true;
  }

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        reportingTo_DuesCleaared_Date: getZeroTimeDate(new Date()),
        reportingTo_DuesCleaared: true,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Attendance approval of last 3 months from the LWD',
          fields: [
            {
              type: 'radioGroup',
              field: 'attendance_approval',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'attendance_approval_comment',
              type: 'textArea',
              label: 'Comment',
              size: 4,
              collapsed: true,
            },
            {
              type: 'checkbox',
              field: 'attendance_approval_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Variable approved till LWD/ CSAT updated in Manaze',
          fields: [
            {
              type: 'radioGroup',
              field: 'variable_approval',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'variable_approval_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'variable_approval_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'KT handover',
          fields: [
            {
              type: 'radioGroup',
              field: 'kt_handover',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'kt_handover_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'kt_handover_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        // {
        //   label: 'Is incentive paid with avg csat',
        //   fields: [
        //     {
        //       type: 'radioGroup',
        //       field: 'is_incentive_paid_with_avg_cset',
        //       // required: true,
        //       options: [
        //         {value: true, label: 'Yes'},
        //         {value: false, label: 'No'},
        //       ],
        //     },
        //   ],
        // },
        {
          label: 'Email backup required',
          fields: [
            {
              type: 'radioGroup',
              field: 'email_backup_required',
              required: true,
              size: 6,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            // {
            //   type: 'checkbox',
            //   field: 'email_backup_required_not_applicable',
            //   label: 'Not applicable',
            //   size: 6,
            // },
          ],
        },
        {
          label: 'Format laptop',
          fields: [
            {
              type: 'radioGroup',
              field: 'format_laptop',
              required: true,
              size: 6,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            // {
            //   type: 'checkbox',
            //   field: 'format_laptop_not_applicable',
            //   label: 'Not applicable',
            //   size: 6,
            // },
          ],
        },
      ]}
    />
  );
};

export const TeamNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ClearTeamNoDues
      header={{
        title: 'No due clarification',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const AccountNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <AccountsTeamNoDues
      header={{
        title: 'No due clarification',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const AccountsTeamNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row, fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesAccounts',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesAccounts'],
  });

  let readOnly = false;
  if (fromHr) {
    readOnly = true;
  }

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        accounts_DuesCleaared_Date: getZeroTimeDate(new Date()),
        Accounts_DuesCleaared: true,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Receivables',
          fields: [
            {
              field: 'receivables_salary_advance',
              type: 'number',
              label: 'In receivables, salary advances',
              size: 6,
              required: true,
            },
            {
              field: 'receivables_salary_advance_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
            {
              field: 'receivables_interest',
              type: 'number',
              label: 'Interest',
              required: true,
              size: 6,
            },
            {
              field: 'receivables_interest_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
            {
              field: 'receivables_noticePayRecoverynumber',
              type: 'number',
              label: 'Notice pay recovery amount',
              required: true,
              size: 6,
            },
            {
              field: 'receivables_noticePayRecoverynumber_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
            {
              field: 'receivables_TDS',
              type: 'number',
              required: true,
              label: 'TDS',
              size: 6,
            },
            {
              field: 'receivables_TDS_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
          ],
        },
        {
          label: 'Payable',
          fields: [
            {
              field: 'payable_reimbersement',
              type: 'number',
              label: 'In payable, reimbursement',
              size: 6,
              required: true,
            },
            {
              field: 'payable_reimbersement_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
            {
              field: 'payable_performance_variable',
              type: 'number',
              label: 'Performance variable (if approved by manager)',
              required: true,
              size: 6,
            },
            {
              field: 'payable_performance_variable_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
            {
              field: 'payable_salary',
              type: 'number',
              label: 'Salary',
              required: true,
              size: 6,
            },
            {
              field: 'payable_salary_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
            {
              field: 'payable_food_coupon',
              type: 'number',
              required: true,
              label: 'Food coupon',
              size: 6,
            },
            {
              field: 'payable_food_coupon_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
          ],
        },
        {
          label: 'Investment declaration with proofs',
          fields: [
            {
              type: 'radioGroup',
              field: 'investment_declaration_with_proofs',
              required: true,
              size: 6,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'investment_declaration_comment',
              type: 'textArea',
              label: 'Comment',
              size: 6,
              collapsed: true,
            },
          ],
        },
      ]}
    />
  );
};

export const HRNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <HRNoDues
      header={{
        title: 'No due clarification',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const HRNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row, fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });
  let readOnly = false;
  if (fromHr) {
    readOnly = true;
  }
  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        hr_DuesCleaared_Date: getZeroTimeDate(new Date()),
        hr_DuesCleaared: true,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Service aggrement recovery',
          fields: [
            {
              type: 'radioGroup',
              field: 'service_aggrement_recovery',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'service_aggrement_recovery_comment',
              type: 'textArea',
              label: 'Comment',
              size: 4,
              collapsed: true,
            },
            {
              type: 'checkbox',
              field: 'service_aggrement_recovery_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Notice period waiver',
          fields: [
            {
              type: 'radioGroup',
              field: 'notice_period_waiver',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'notice_period_waiver_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'notice_period_waiver_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label:
            'Attendance verification on Manaze of last 3 months from the LWD',
          fields: [
            {
              type: 'radioGroup',
              field: 'attendance_verification',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'attendance_verification_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'attendance_verification_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Gratuity',
          fields: [
            {
              type: 'radioGroup',
              field: 'gratuity',

              size: 4,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'gratuity_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'gratuity_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Email backup required',
          fields: [
            {
              type: 'radioGroup',
              field: 'idCard',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'idCard_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'idCard_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
      ]}
    />
  );
};

export const AdminNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row, fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesAdmin',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesAdmin'],
  });

  let readOnly = false;
  if (fromHr) {
    readOnly = true;
  }

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        admin_DuesCleaared_Date: getZeroTimeDate(new Date()),
        admin_DuesCleaared: true,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={readOnly}
      layoutFields={[
        {
          label: 'Admin assets',
          fields: [
            {
              type: 'radioGroup',
              field: 'admin_assets',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'admin_assets_comment',
              type: 'textArea',
              label: 'Comment',
              size: 4,
              collapsed: true,
            },
            {
              type: 'checkbox',
              field: 'admin_assets_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'DLF access card',
          fields: [
            {
              type: 'radioGroup',
              field: 'dlf_access_card',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'dlf_access_card_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'dlf_access_card_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Storage key',
          fields: [
            {
              type: 'radioGroup',
              field: 'storage_key',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'storage_key_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'storage_key_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Recovery, if any in case of lost or damaged admin assets',
          fields: [
            {
              type: 'radioGroup',
              field: 'admin_asset_recovery',

              size: 4,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'admin_asset_recovery_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'admin_asset_recovery_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Laptop bag',
          fields: [
            {
              type: 'radioGroup',
              field: 'laptop_bag',
              size: 4,

              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              type: 'checkbox',
              field: 'laptop_bag_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
      ]}
    />
  );
};

export const AdminNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <AdminNoDues
      header={{
        title: 'No due clarification',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const ITNetworkNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row, fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesIt',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesIt'],
  });

  let readOnly = false;
  if (fromHr) {
    readOnly = true;
  }

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        itandnetworking_DuesCleaared_Date: getZeroTimeDate(new Date()),
        ITNetworking_DuesCleaared: true,
        ...row,
      }}
      readOnly={readOnly}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'IT Accessories',
          fields: [
            {
              type: 'radioGroup',
              field: 'it_assets',
              size: 4,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'it_assets_comment',
              type: 'textArea',
              label: 'Comment',
              size: 4,
              collapsed: true,
            },
            {
              type: 'checkbox',
              field: 'it_assets_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Laptop',
          fields: [
            {
              type: 'radioGroup',
              field: 'laptop',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'laptop_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'laptop_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Sim card',
          fields: [
            {
              type: 'radioGroup',
              field: 'sim_card',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'sim_card_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'sim_card_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Mobile bill',
          fields: [
            {
              type: 'radioGroup',
              field: 'mobile_bill',
              // required: true,
              size: 4,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'mobile_bill_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'mobile_bill_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Recovery if any in case of lost or damaged device',
          fields: [
            {
              type: 'radioGroup',
              field: 'other_assets',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'other_assets_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'other_assets_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Biometric disabled',
          fields: [
            {
              type: 'radioGroup',
              field: 'biometric_disable',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'biometric_disable_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'biometric_disable_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label:
            'Reset the password of mail, skype and others after LWD formalities',
          fields: [
            {
              type: 'radioGroup',
              field: 'password_asset',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'password_asset_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'password_asset_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Approval, if employee wants to retain the sim',
          fields: [
            {
              type: 'radioGroup',
              field: 'sim_retain',
              size: 4,
              // required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            {
              field: 'sim_retain_comment',
              type: 'textArea',
              label: 'Comment',
              collapsed: true,
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'sim_retain_not_applicable',
              label: 'Not applicable',
              size: 4,
            },
          ],
        },
        {
          label: 'Email backUp required',
          fields: [
            {
              type: 'radioGroup',
              field: 'email_backup_required',
              size: 4,
              readOnly: true,
              required: true,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            // {
            //   type: 'checkbox',
            //   field: 'email_backup_required_not_applicable',
            //   label: 'Not applicable',
            //   size: 4,
            // },
          ],
        },
        {
          label: 'Format laptop',
          fields: [
            {
              type: 'radioGroup',
              field: 'format_laptop',
              required: true,
              readOnly: true,
              size: 6,
              options: [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ],
            },
            // {
            //   type: 'checkbox',
            //   field: 'format_laptop_not_applicable',
            //   label: 'Not applicable',
            //   size: 6,
            // },
          ],
        },
      ]}
    />
  );
};

export const ITNetworkNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ITNetworkNoDues
      header={{
        title: 'No due clarification',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const EmployeeNoDues = props => {
  const {
    route: {params},
  } = props;
  const {row, fromHr} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesEmployee',
    ...props,
  });
  let employeeFilter = row?._id;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesEmployee'],
  });
  let readOnly = false;
  if (fromHr) {
    readOnly = true;
  }

  //feedback_form
  //self_declaration_form
  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        employee_feedback_date: getZeroTimeDate(new Date()),
        employee_feedback: true,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={readOnly}
      layoutFields={[
        {
          lebel: 'Exit interivew form',
          fields: [
            {
              placeholder: 'Upload attachment',
              label: 'Exit interview form',
              field: 'feedback_form',
              type: 'file',
              required: true,
              multi: true,
              options: {bucketName: 'manaze'},
            },
            // {
            //   placeholder: 'Self declaration form',
            //   label: 'Self declaration form',
            //   field: 'feedback_form',
            //   multiple: true,
            //   type: 'file',
            //   options: {bucketName: 'manaze'},
            // },
          ],
        },
        {
          lebel: 'Self delcaration form',
          fields: [
            {
              placeholder: 'Upload attachment',
              label: 'Self delcaration form',
              field: 'self_declaration_form',
              type: 'file',
              required: true,
              multi: true,
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const EmployeeNoduesEmployee = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <EmployeeNoDues
      header={{
        title: 'No due clarification',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateRemarkForFnf = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateEmployeeFnf
      header={{
        title: 'Update Remark',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateEmployeeFnf = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              type: 'textArea',
              field: 'fnf_reason',
              label: 'Remark',
            },
          ],
        },
      ]}
    />
  );
};
export const PaymentPendingFnf = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        fnf_status: 'Payment Pending',
        fnf_payment_start_date: getZeroTimeDate(new Date()),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              // placeholder: 'Acceptance date',
              label: 'Acceptance date',
              field: 'payment_acceptance_date',
              type: 'date',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const PayGratuityForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/gratuities',
    eventSourceId: 'EmployeeGratuity',
    ...props,
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        status: 'Paid',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Payment Date',
              field: 'gratuity_payment_date',
              type: 'date',
              required: true,
            },
            {
              placeholder: 'Gratuity doc',
              label: 'Upload document',
              field: 'gratuity_file',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const ShareEmployeeFnf = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        fnf_provided_date: getZeroTimeDate(new Date()),
        fnf_status: 'Shared',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Fnf file',
              label: 'Upload document',
              field: 'fnf_file',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const UpdateEmployeeFnfFile = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Fnf file',
              label: 'Upload document',
              field: 'fnf_file',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const AddEmployeeFnfFile = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        fnf_file_added_date: getZeroTimeDate(new Date()),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              placeholder: 'Fnf file',
              label: 'Upload document',
              field: 'fnf_file',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const PendingPaymentFnf = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <PaymentPendingFnf
      header={{
        title: 'Payment pending',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const PayGratuity = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <PayGratuityForm
      header={{
        title: 'Pay Gratuity',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const ShareFnf = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <ShareEmployeeFnf
      header={{
        title: 'Share',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateFnfFile = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateEmployeeFnfFile
      header={{
        title: 'Share',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const AddFnfFile = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <AddEmployeeFnfFile
      header={{
        title: 'Add file',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const PaidFnf = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <PaidEmployeeFnf
      header={{
        title: 'Complete Payment',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const PaidEmployeeFnf = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        fnf_status: 'Paid',
        payment_date: getZeroTimeDate(new Date()),
        fnf_tat: 'On time',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Payment Date',
              field: 'payment_date',
              type: 'date',
              required: true,
            },
            {
              label: 'Reason',
              field: 'fnf_reason',
              type: 'textArea',
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const PaidFnfOverdue = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <PaidEmployeeFnfOverdue
      header={{
        title: 'Complete Payment',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const FnfRecoveryPendingPaymentDetails = props => {
  let {
    route: {params},
  } = props;
  const {row, from = ''} = params;
  if (from && from === 'accounts') {
    return (
      <FnfRecoveryPendingPaymentDetailsNonEditHOC
        header={{
          title: 'Payment details',
          secondaryTitle: () => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            return (
              <Text
                style={{
                  ...CAPTION_LARGE,
                  color: colors.NEUTRAL_MEDIUM,
                  paddingLeft: 5,
                }}>
                {row?.employee?.name}
              </Text>
            );
          },
        }}
        {...props}
      />
    );
  } else {
    return (
      <FnfRecoveryPendingPaymentDetailsHOC
        header={{
          title: 'Payment details',
          secondaryTitle: () => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            return (
              <Text
                style={{
                  ...CAPTION_LARGE,
                  color: colors.NEUTRAL_MEDIUM,
                  paddingLeft: 5,
                }}>
                {row?.employee?.name}
              </Text>
            );
          },
        }}
        {...props}
      />
    );
  }
};

export const FnfRecoveryPendingPaymentDetailsHOC = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        fnf_payment_status: 'Payment done by employee',
        // fnf_status: 'Paid',
        // payment_date: getZeroTimeDate(new Date()),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Payment recived date',
              field: 'payment_recieved_date',
              type: 'date',
              required: true,
            },
            {
              label: 'Transaction ID',
              field: 'payment_txn_id',
              type: 'textArea',
              required: true,
            },
            {
              label: 'Description',
              field: 'recovery_pending_description',
              type: 'textArea',
            },
            {
              label: 'Attachment',
              field: 'payment_received_attachment',
              type: 'file',
              options: {bucketName: 'manaze'},
              required: true,
            },
          ],
        },
      ]}
    />
  );
};

export const FnfRecoveryPendingPaymentDetailsNonEditHOC = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      // mode="edit"
      readOnly={true}
      defaultValues={{
        _id: row?._id,
        ...row,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Payment recived date',
              field: 'payment_recieved_date',
              type: 'date',
              required: true,
              readOnly: true,
            },
            {
              label: 'Transaction ID',
              field: 'payment_txn_id',
              type: 'textArea',
              required: true,
              readOnly: true,
            },
            {
              label: 'Description',
              field: 'recovery_pending_description',
              type: 'textArea',
              readOnly: true,
            },
            {
              label: 'Attachment',
              field: 'payment_received_attachment',
              type: 'file',
              options: {bucketName: 'manaze'},
              required: true,
              readOnly: true,
            },
          ],
        },
      ]}
    />
  );
};

export const PaidEmployeeFnfOverdue = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      {...props}
      defaultValues={{
        ...row,
        fnf_status: 'Paid',
        payment_date: getZeroTimeDate(new Date()),
        isOverdue: null,
        fromOverDue: 'true',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              label: 'Reason',
              field: 'fnf_reason',
              type: 'textArea',
              required: true,
            },
            {
              placeholder: 'Attachment',
              label: 'Attachment',
              field: 'overdue_close_document',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const markProcessedForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/nodues',
    eventSourceId: 'NoduesHr',
    ...props,
  });
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['NoduesHr'],
  });

  return (
    <Form
      header={{
        title: 'Mark processed',
      }}
      defaultValues={{
        ...params,
        status: true,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              type: 'date',
              field: 'nodue_closing_date',
              label: 'Nodues closing date',
            },
          ],
        },
      ]}
    />
  );
};
