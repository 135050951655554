import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';

export const OpportunityTimelineReport = props => {
  const params = props.route.params;
  const {data} = useFetchData({
    api: '/opportunities',
    filter: {
      _id: params?.opportunity?._id,
    },
    fields: {
      _id: 1,
      rfq_owner_assign_date: 1,
      creation_date: 1,
      new_date: 1,
      requirement_gathering_date: 1,
      scoop_freezing_date: 1,
      proposal_drafting_date: 1,
      sales_review_date: 1,
      proposal_walkthrough_date: 1,
      re_estimation_date: 1,
      negotiation_date: 1,
      walkthrought_done_date: 1,
      revise_proposal_date: 1,
      quotation_submitted_date: 1,
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
  });
  return (
    <Table
      data={data}
      renderHeader={() => <TableHeader title={'Timeline'} />}
      columns={[
        {
          header: 'Created On',
          type: 'date',
          field: 'creation_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Assigned on',
          field: 'rfq_owner_assign_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'New',
          field: 'new_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Requirement',
          field: 'requirement_gathering_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Scope Freeze',
          field: 'scoop_freezing_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Drafting',
          field: 'proposal_drafting_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Submitted',
          field: 'quotation_submitted_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Sales Review',
          field: 'sales_review_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Walkthrough',
          field: 'proposal_walkthrough_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Re-Estimation',
          field: 're_estimation_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Negotiation',
          field: 'negotiation_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Walkthrough',
          field: 'walkthrought_done_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 120,
        },
        {
          header: 'Revise Proposal',
          field: 'revise_proposal_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 140,
        },
      ]}
    />
  );
};
