import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const ProjectClientForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {client_assignment_id} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/clientassignments',
    eventSourceId: ['Client', 'Project'],
    ...props,
  });

  return (
    <Form
      api={`/clientassignments/${client_assignment_id?._id}`}
      fields={{
        client: {name: 1},
        from_date: 1,
        to_date: 1,
        percentage: 1,
        allow_allocation_more_than_100: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project_id: params?.project?._id,
      }}
      layoutFields={[
        {
          label: 'Name',
          field: 'client',
          type: 'autoComplete',
          api: '/users',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'email',
          filter: {
            userType: {$in: ['client', 'Client']},
          },
          required: true,
        },
        {
          label: 'Allocation from',
          type: 'date',
          field: 'from_date',
          required: true,
          size: 'medium',
          validate: (value, {values} = {}) => {
            if (!value) {
              return;
            }

            const {to_date} = values;
            const valueDate = new Date(value);
            const toDate = new Date(to_date);

            let result = valueDate > toDate;

            if (result) {
              return 'from_date should be less than toDate.';
            }
          },
        },
        {
          label: 'Allocation till',
          type: 'date',
          field: 'to_date',
          required: true,
          size: 'medium',
          validate: (value, {values} = {}) => {
            if (!value) {
              return;
            }

            const {from_date} = values;
            const valueDate = new Date(value);
            const fromDate = new Date(from_date);

            let result = valueDate < fromDate;

            if (result) {
              return 'to_date should be more than from_date.';
            }
          },
        },
        {
          label: 'Allocation Percentage',
          type: 'number',
          field: 'percentage',
          required: true,
          size: 'medium',
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectClientForm = props => {
  return <ProjectClientForm header="Add Client" {...props} />;
};

export const UpdateProjectClientForm = props => {
  return <ProjectClientForm mode="edit" header="Update Client" {...props} />;
};
