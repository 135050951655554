import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateChannelForm = props => {
  const {
    route: {params},
  } = props;
  const {api, _id} = params;
  let {onSubmit} = useFormSubmit({
    uri: `${api}`,
    ...props,
  });
  return (
    <Form
      mode="edit"
      api={`${api}/${_id}`}
      fields={{
        _id: 1,
        channel: {name: 1},
        employee_team: {name: 1},
        organization: {name: 1},
      }}
      onSubmit={onSubmit}
      header={{title: 'Update Channel'}}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Channel',
          field: 'channel',
          type: 'autoComplete',
          api: `/channels`,
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            _id: {
              $nin: ['66d2ec16f897151a53daf08b'],
            },
          },
        },
        {
          label: 'Employee Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: `/employeeteams`,
          filter: {
            _id: {
              $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
            },
          },
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
