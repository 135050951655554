import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ResourceSkill = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      mode="edit"
      submitAction="Save"
      fields={{
        resource_language: {_id: 1, name: 1},
        seconday_resource_language: {_id: 1, name: 1},
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Resource Language',
          api: '/tools',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'resource_language',
        },
        {
          type: 'autoComplete',
          label: 'Secondary Resource Language',
          api: '/tools',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'seconday_resource_language',
        },
      ]}
      {...props}
    />
  );
};

export const ResourcePractice = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      mode="edit"
      submitAction="Save"
      fields={{
        primary_practice: {_id: 1, name: 1},
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Practice',
          api: '/practices',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'primary_practice',
        },
      ]}
      {...props}
    />
  );
};

export const EditResourceSkill = props => {
  return <ResourceSkill header={'Update Resource Skill'} {...props} />;
};

export const EditResourcePractice = props => {
  return <ResourcePractice header={'Update Resource Practice'} {...props} />;
};
