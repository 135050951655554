import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import React, {useState} from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {randomColor} from '../../../utils/util';

const RenderRequestAction = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['vendorPayment', 'OnlinePayment'],
    setLoading,
  });
  let {rowText = {}} = styles;
  if (row.transaction_status === 'Pending maker approval') {
    return (
      <View style={{flexDirection: 'row'}}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              const {_id} = row;
              let selectedIds = [];
              selectedIds.push(_id);
              invoke({
                uri: `/approvePaymentByPaymentMode`,
                props: {
                  selectedID: selectedIds,
                  fromRightAction: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>
              Req For Bank Transaction
            </Text>
          </View>
        )}
      </View>
    );
  } else
    return (
      <View>
        <Text style={{...rowText}}>{row.transaction_status}</Text>
      </View>
    );
};

const RenderAction = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'OnlinePayment',
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View
          style={{
            padding: 4,
            alignItem: 'center',
            textAlign: 'center',
          }}
          onPress={() => {
            const {_id} = row;
            let selectedIds = [];
            selectedIds.push(_id);
            invoke({
              uri: `/sendForApprovalByPaymentMode`,
              props: {
                selectedID: selectedIds,
              },
            });
          }}>
          <Text style={{...rowText, color: '#34C759'}}>Accept</Text>
        </View>
      )}
    </View>
  );
};

export const OnlinePaymentTable = props => {
  const {navigation, tab, addOnFilter, searchValue} = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['vendorPayment', 'OnlinePayment'],
  });
  return (
    <Table
      eventSourceId={['OnlinePayment']}
      api={`/payment/online`}
      search={searchValue}
      searchFields="payment_no"
      addOnFilter={addOnFilter}
      onRowPress={({row}) =>
        navigation.navigate('edit-vendor-payment-details', {
          row,
        })
      }
      columns={[
        {
          header: 'Created On',
          field: 'payment_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'payee_id.name',
          type: 'colorTag',
          minWidth: 200,
          colorField: randomColor,
        },
        {
          header: 'Category & Type',
          width: 200,

          render: ({row}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.category?.name || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.payment_type || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Payment No',
          field: 'payment_no',
          type: 'text',
          width: 200,
        },
        {
          header: 'Cr. Acc & IFSC',
          width: 250,

          render: ({row}) => {
            return (
              <>
                <Tag color={randomColor()} value={row?.accountNumber || ''} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.ifscCode || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Amount & MOP',
          width: 150,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.total_payment_amount || ''}
                items={[
                  {
                    value: row?.payment_mode || '',
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Subsidiary & unit',
          width: 250,
          render: ({row}) => {
            return (
              <>
                <Tag
                  color={randomColor()}
                  value={row?.organization?.name || ''}
                />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row?.product?.name || '',
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Approver',
          width: 100,
        },
        {
          header: 'Action',
          width: 100,
          align: 'center',
          render: ({row, styles}) => <RenderAction row={row} styles={styles} />,
          visible: () => {
            if (tab === 'ToApprove') return true;
          },
        },
        {
          header: 'Action',
          width: 300,
          align: 'center',
          render: ({row, styles}) => (
            <RenderRequestAction row={row} styles={styles} />
          ),
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Reject',
          confirm: {
            title: 'Reject',
            message: 'Are you sure you want to Reject?',
          },
          visible: ({row}) => {
            if (
              tab === 'ToApprove' ||
              (tab === 'Approved' && row.transaction_status === 'new')
            )
              return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/payments/${row?._id}`,
              props: {
                approval_status: 'Rejected',
              },
            });
          },
        },
        {
          title: 'Bank Transaction',
          onPress: ({row}) => {
            navigation.navigate('bank-transaction', {row});
          },
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
      ]}
    />
  );
};
