import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {customDigitalPlatformOppControllers} from '../controllers/customDigitalPlatformControllers';
import {useAppStateContext} from '../../../providers/AppState';
import {useProjectActions} from '../../pmt/actions/ProjectActions';

const CloseReasonRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {field: 'outcome_description', type: 'text'},
      {
        field: 'outcome_date',
        type: 'date',
      },
    ]}
  />
);

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

const CUSTOMER = {
  header: 'Opportunity',
  render: CustomerRender,
  // responsive: 'sm',
  width: 300,
};

const POTENTIALCUSTOMER = {
  header: 'Potential Customer',
  field: 'potential_customer.name',
};

const REQUIREMENT = {
  header: 'Requirement',
  field: 'requirement',
};

const PRESALES = {
  header: 'Pre Sales',
  field: 'rfq_owner',
  type: 'userAvatarGroup',
};

const SOULUTIONCONSULTANT = {
  header: 'Sol. Consultant',
  field: 'tech_person',
  type: 'userAvatarGroup',
};

const PRIMARYSOLUTIONCONSULTANT = {
  header: 'Prim. Sol. Consultant',
  field: 'primary_owner',
  type: 'userAvatar',
};

const SALES = {
  header: 'Sales',
  field: 'owner',
  type: 'userAvatar',
};

const DUEDATE = {
  header: 'Due Date',
  field: 'due_date',
  type: 'date',
};

const CREATIONDATE = {
  header: 'Date',
  field: 'creation_date',
  type: 'date',
};

const STATUS = {
  header: 'Status',
  field: 'status',
};

const RFQ_CREATED_ON = {
  header: 'RFQ Date',
  field: 'rfq_created_on',
  type: 'date',
  // width: 280,
};
const RFR_CREATED_ON = {
  header: 'RFR Date',
  field: 'rfr_created_on',
  type: 'date',
  // width: 280,
};

export const AllRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };

  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      api={`/opportunity/rps/all`}
      eventSourceId={'rps'}
      selection={{
        actions: [{title: 'Mark Close', onPress: markRPSClose}],
      }}
      columns={[
        {...CREATIONDATE, width: 100},
        CUSTOMER,
        REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
        {...STATUS, width: 100},
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const AllRPSTablePreSales = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };

  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      api={`/opportunity/rps/all`}
      eventSourceId={'rps'}
      // selection={{
      //   actions: [{title: 'Mark Close', onPress: markRPSClose}],
      // }}
      columns={[
        {...CREATIONDATE, width: 100},
        {...CUSTOMER, width: 250},
        {...REQUIREMENT},
        // REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...DUEDATE, width: 100},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...STATUS, width: 100},
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const AllRPSTablePreSalesSolCon = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      api={`/opportunity/rps/all/solution-consultant`}
      eventSourceId={'rps'}
      // selection={{
      //   actions: [{title: 'Mark Close', onPress: markRPSClose}],
      // }}
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        CREATIONDATE,
        {...CUSTOMER, width: 250},
        {...REQUIREMENT, width: 600},
        // REQUIREMENT,
        PRESALES,
        SALES,
        DUEDATE,
        SOULUTIONCONSULTANT,
        PRIMARYSOLUTIONCONSULTANT,
        STATUS,
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const UnassignedRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);
  const {assignTechPersonOnActive} = customDigitalPlatformOppControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/unAssigned`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markRPSClose},
          {
            title: 'Assign Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
        ],
      }}
      columns={[
        {...CREATIONDATE, width: 100},
        CUSTOMER,
        REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const UnassignedRPSTablePreSales = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;

  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);
  const {assignTechPersonOnActive} = customDigitalPlatformOppControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/unAssigned`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      selection={{
        actions: [
          {
            title: 'Assign Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
        ],
      }}
      columns={[
        {...CREATIONDATE, width: 100},

        {...CUSTOMER, width: 300},
        {...REQUIREMENT},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const UnassignedRPSTablePreSalesSolCon = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;

  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);
  const {assignTechPersonOnActive} = customDigitalPlatformOppControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/unAssigned`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      // selection={{
      //   actions: [
      //     {
      //       title: 'Assign Solution Consultant',
      //       onPress: assignTechPersonOnActive,
      //     },
      //   ],
      // }}
      filter={{
        $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
      }}
      columns={[
        CREATIONDATE,
        {...CUSTOMER, width: 250},
        {...REQUIREMENT, width: 600},
        SALES,
        SOULUTIONCONSULTANT,
        PRIMARYSOLUTIONCONSULTANT,
        DUEDATE,
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const ActiveRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);
  const {assignTechPersonOnActive} = customDigitalPlatformOppControllers(props);
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      {...props}
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/active`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      selection={{
        actions: [
          {title: 'Mark Close', onPress: markRPSClose},
          {
            title: 'Assign Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
        ],
      }}
      columns={[
        {...CREATIONDATE, width: 100},
        CUSTOMER,
        REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
      ]}
      moreActions={() => [
        {
          title: 'Create RFQ',
          onPress: createRFQ,
        },
        {
          title: 'Create RFR',
          onPress: createRFR,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};

export const ActiveRPSTableForPreSales = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);
  const {assignTechPersonOnActive} = customDigitalPlatformOppControllers(props);
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      {...props}
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/active`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      selection={{
        actions: [
          {
            title: 'Assign Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
        ],
      }}
      columns={[
        {...CREATIONDATE, width: 100},

        {...CUSTOMER, width: 300},
        {...REQUIREMENT},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
      ]}
      moreActions={() => [
        {
          title: 'Create RFQ',
          onPress: createRFQ,
        },
        {
          title: 'Create RFR',
          onPress: createRFR,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};

export const ActiveRPSTableForPreSalesSolCon = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);
  const {assignTechPersonOnActive} = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      api={`/opportunity/rps/active/solution-consultant`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      // selection={{
      //   actions: [
      //     {
      //       title: 'Assign Solution Consultant',
      //       onPress: assignTechPersonOnActive,
      //     },
      //   ],
      // }}
      columns={[
        CREATIONDATE,
        {...CUSTOMER, width: 250},
        {...REQUIREMENT, width: 600},
        PRESALES,
        SALES,
        SOULUTIONCONSULTANT,
        PRIMARYSOLUTIONCONSULTANT,
        DUEDATE,
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Create RFQ',
      //     onPress: createRFQ,
      //   },
      //   {
      //     title: 'Create RFR',
      //     onPress: createRFR,
      //   },
      // ]}
    />
  );
};

export const OverdueRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {markRPSClose, createRFQ, createRFR} = leadControllers(props);

  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/overdue`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      selection={{
        actions: [{title: 'Mark Close', onPress: markRPSClose}],
      }}
      columns={[POTENTIALCUSTOMER, REQUIREMENT, PRESALES, SALES, DUEDATE]}
      moreActions={() => [
        {
          title: 'Create RFQ',
          onPress: createRFQ,
        },
        {
          title: 'Create RFR',
          onPress: createRFR,
        },
      ]}
    />
  );
};

export const ClosedRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      api={`/opportunity/rps/closed`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      columns={[
        {...CREATIONDATE, width: 100},
        CUSTOMER,
        REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
        {
          header: 'Close Reason',
          render: CloseReasonRender,
          width: 150,
        },
      ]}
      moreActions={() => [
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};

export const ClosedRPSTableSolCon = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  params = {...params, ...filterParams};
  return (
    <Table
      onRowPress={props => {
        navigation.navigate(`rps-detail`, {...props});
      }}
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      api={`/opportunity/rps/closed/solution-consultant`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      columns={[
        CREATIONDATE,
        {...CUSTOMER, width: 250},
        {...REQUIREMENT, width: 600},
        PRESALES,
        SALES,
        SOULUTIONCONSULTANT,
        PRIMARYSOLUTIONCONSULTANT,
        DUEDATE,
        {
          header: 'Close Reason',
          render: CloseReasonRender,
        },
      ]}
    />
  );
};

export const RFQCreatedRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      api={`/opportunity/rps/rfqCreated`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      columns={[
        {...CREATIONDATE, width: 100},
        {...RFQ_CREATED_ON, width: 100},
        CUSTOMER,
        REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
      ]}
      moreActions={() => [
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};

export const RFQCreatedRPSTableSolCon = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  return (
    <Table
      api={`/opportunity/rps/rfqCreated/solution-consultant`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        CREATIONDATE,
        RFQ_CREATED_ON,
        {...CUSTOMER, width: 250},
        {...REQUIREMENT, width: 600},
        PRESALES,
        SALES,
        SOULUTIONCONSULTANT,
        PRIMARYSOLUTIONCONSULTANT,
        DUEDATE,
      ]}
    />
  );
};

export const RFRCreatedRPSTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {activityLogDetailForOpportunities} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      api={`/opportunity/rps/rfrCreated`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      columns={[
        {...CREATIONDATE, width: 100},
        {...RFR_CREATED_ON, width: 100},
        CUSTOMER,
        REQUIREMENT,
        {...PRESALES, width: 150},
        {...SALES, width: 80},
        {...SOULUTIONCONSULTANT, width: 150},
        {...PRIMARYSOLUTIONCONSULTANT, width: 200},
        {...DUEDATE, width: 100},
      ]}
      moreActions={({row}) => [
        {
          title: 'Activity Logs',
          onPress: activityLogDetailForOpportunities,
        },
      ]}
    />
  );
};

export const RFRCreatedRPSTableSolCon = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    search,
    filterParams,
  } = props;
  const {period, product, ...rest} = params;
  params = {
    ...rest,
  };

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {...params, ...filterParams};
  return (
    <Table
      api={`/opportunity/rps/rfrCreated/solution-consultant`}
      eventSourceId={'rps'}
      params={params}
      addOnFilter={addOnFilter}
      search={search}
      searchFields="requirement"
      filter={{
        $or: [{primary_owner: employeeId?._id}, {tech_person: employeeId?._id}],
      }}
      columns={[
        CREATIONDATE,
        RFR_CREATED_ON,
        {...CUSTOMER, width: 250},
        {...REQUIREMENT, width: 600},
        PRESALES,
        SALES,
        SOULUTIONCONSULTANT,
        PRIMARYSOLUTIONCONSULTANT,
        DUEDATE,
      ]}
    />
  );
};
