import React from 'react';
import {Col} from '@unthinkable/react-core-components';
import {InlineForm} from '../../../components/form/Form';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const DepartmentRoleTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department} = params;

  const {onSubmit} = useFormSubmit({
    uri: `/departmentroles`,
    eventSourceId: ['DepartmentRole'],
  });

  return (
    <Table
      eventSourceId="DepartmentRole"
      api={`/departmentroles`}
      renderHeader={() => (
        <Col>
          <TableHeader title="Department Roles" secondaryTitle={department?.name}/>
          <InlineForm
            layoutFields={[
              {
                field: 'name',
                type: 'text',
                label: 'Name',
              },
            ]}
            defaultValues={{
              department: department,
            }}
            onSubmit={onSubmit}
          />
        </Col>
      )}
      fields={{
        name: 1,
      }}
      filter={{
        department: department,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-department-role', {
              departmentRole: row,
            });
          },
        },
      ]}
    />
  );
};
