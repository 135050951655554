import {Col} from '@unthinkable/react-core-components';
import {TextRender, formats} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import {renderLocale} from '../OrderTable';

export const AmountRender = ({row, styles, amount, base_amount, currency}) => {
  const colors = useTheme('colors');
  return (
    <Col style={{alignItems: 'flex-end'}}>
      <TextRender
        value={base_amount}
        styles={styles}
        format={formats.formatCurrency}
        formatOptions={{
          maximumFractionDigits: 0,
        }}
      />
      <TextRender
        value={amount}
        styles={styles}
        format={formats.formatCurrency}
        formatOptions={{
          locale: renderLocale(currency),
          currency: currency,
          maximumFractionDigits: 0,
        }}
        color={colors.NEUTRAL_LOW}
      />
    </Col>
  );
};
