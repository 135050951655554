import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const EmployeeType = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/employeetypes',
        eventSourceId: 'employeetype',
    });
    return (
        <Form
            api = {`/employeetypes/${row?._id}`}
            onSubmit={onSubmit}
            submitAction="Save"
            layoutFields={[
                {
                    label: 'Name',
                    type: 'text',
                    field: 'name',
                },
            ]}
            {...props}
        />
    );
};

export const AddEmployeeType = props => {
    return <EmployeeType header="Add Employee Type" {...props} />;
};

export const EditEmployeeType = props => {
    return (
        <EmployeeType
            mode="edit"
            header={'Update Employee Type'}
            {...props}
        />
    );
};
