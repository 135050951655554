import {Table} from '../../../components/table/Table';

const TokenUsageList = props => {
  let {
    route: {params},
  } = props;
  return (
    <Table
      api={`/tokenusages/tokenUsageCount`}
      columns={[
        {
          header: 'Date',
          field: 'createdAt',
          type: 'date',
        },
        {
          header: 'Prompt Tokens',
          field: 'promptTokens',
          type: 'number',
          width: 200,
        },
        {
          header: 'Completion Tokens',
          field: 'completionTokens',
          type: 'number',
          width: 200,
        },
        {
          header: 'Total Tokens',
          field: 'totalTokens',
          type: 'number',
          width: 200,
        },
        {
          header: 'Count',
          field: 'count',
          type: 'number',
          width: 200,
        },
      ]}
    />
  );
};

export default TokenUsageList;
