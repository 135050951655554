import React from 'react';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const LeaveRegister = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/leaveregisters',
    eventSourceId: 'leaveRegister',
  });
  return (
    <Form
      api={`/leaveregister/${row?._id}`}
      onSubmit={onSubmit}
      eventSourceId={'leaveRegister'}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          size: 6,
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'Month',
          field: 'month_id',
          type: 'autoComplete',
          api: '/monthtypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Year',
          field: 'year_id',
          type: 'autoComplete',
          api: '/yeartypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Leave Type',
          fields: [
            {
              field: 'leaveData',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  columns={[
                    {
                      field: 'leave_type.name',
                      header: 'Leave Type',
                      type: 'text',
                    },
                    {
                      header: 'No Of Leaves',
                      field: 'no_of_leaves',
                      type: 'text',
                    },
                    {
                      header: 'Resons',
                      field: 'reason',
                      type: 'text',
                    },
                  ]}
                  fields={[
                    {
                      label: 'Leave Type',
                      field: 'leave_type',
                      type: 'autoComplete',
                      api: '/leavetypes',
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      size: 6,
                    },
                    {
                      label: 'No Of Leaves',
                      field: 'no_of_leaves',
                      type: 'number',
                      required: true,
                      size: 6,
                    },
                    {
                      label: 'Reason',
                      field: 'reason',
                      type: 'text',
                      required: true,
                      size: 6,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddLeaveRegister = props => {
  return <LeaveRegister header="Add Leave Register" {...props} />;
};

export const EditLeaveRegister = props => {
  return (
    <LeaveRegister mode="edit" header={'Update Leave Register'} {...props} />
  );
};
