import React, {useEffect, useState} from 'react';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {TextInput} from '../../../components/form-editors/Editors';
import {useStyles} from '@unthinkable/react-theme';
import {BoilerplateCloneRepoAndCheckoutBranchStyle} from './theme';
import {useToast} from '@unthinkable/react-toast';
import {LinkRenderer} from '../../../components/form/Renderers';

export const BoilerplateCloneAndCheckoutBranch = props => {
  const {
    route: {params},
  } = props;
  const {row} = params || {};
  const {repository_id} = row || {};
  const {vcs, repository} = repository_id || {};
  const [isCopied, setIsCopied] = useState(false);
  const toast = useToast();
  const {link, copy, IconStyle} = useStyles(
    BoilerplateCloneRepoAndCheckoutBranchStyle,
  );

  useEffect(() => {
    if (isCopied) {
      toast({message: 'copied successfully'});
      setIsCopied(false);
    }
  }, [isCopied]);

  let repositoryUrl = void 0;
  if (vcs?.workspace && repository) {
    if (vcs?.type === 'bitbucket') {
      repositoryUrl = `https://bitbucket.org/${vcs?.workspace}/${repository}`;
    } else if (vcs?.type === 'github') {
      repositoryUrl = `https://github.com/${vcs?.workspace}/${repository}`;
    }
  }

  return (
    <Col gap={16}>
      {repositoryUrl ? (
        <Row gap={8} style={{alignItems: 'center'}}>
          <View style={{flex: 1, overflow: 'hidden'}}>
            <TextInput value={`Clone Repository`} editable={false} />
          </View>
          <View style={IconStyle}>
            <LinkRenderer icon={link} value={repositoryUrl} />
          </View>
        </Row>
      ) : (
        void 0
      )}
      <Row gap={8} style={{alignItems: 'center'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <TextInput value={`Checkout Branch`} editable={false} />
        </View>
        <TouchableOpacity
          onPress={() => {
            navigator.clipboard.writeText(`git checkout boilerplate`);
            setIsCopied(true);
          }}
          style={IconStyle}>
          <Image source={copy} />
        </TouchableOpacity>
      </Row>
      <Row gap={8} style={{alignItems: 'center'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <TextInput value={`Initial Setup`} editable={false} />
        </View>
        <TouchableOpacity
          onPress={() => {
            navigator.clipboard.writeText(`npm run setup`);
            setIsCopied(true);
          }}
          style={IconStyle}>
          <Image source={copy} />
        </TouchableOpacity>
      </Row>
    </Col>
  );
};
