import {TextRenderer} from '../../../components/form/Renderers';
import {ExpandRowIconRender} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {MailContentRender} from '../components/ExpandableRowComponent';
import {leadControllers} from '../controllers/leadControllers';
import React from 'react';

export const EmailMessageLists = props => {
  const {route: {params = {}} = {}} = props;
  const {row, period} = params;
  const {
    positiveProspectsForMessageSend,
    negativeProspectsForMessage,
    prospectsListForEmailMessage,
    editOutreachContent,
  } = leadControllers({...props, params: {...params, period}});

  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'outreachAdded',
  });

  const deleteMessage = ({row}) => {
    invoke({
      uri: `/campaignMessages/${row?._id}`,
    });
  };

  return (
    <Table
      eventSourceId="outreachAdded"
      api={`/campaign-messages-list`}
      params={{
        ...params,
        campaign_id: row?._id,
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      renderExpandRow={({row}) => {
        return <MailContentRender subject={row?.subject} message={row?.body} />;
      }}
      sort={{
        index: 1,
      }}
      columns={[
        {
          render: ExpandRowIconRender,
          width: 50,
          expandable: true,
          responsive: 'sm',
        },
        {
          type: 'text',
          field: 'name',
          header: 'Name',
          responsive: 'sm',
        },
        {
          type: 'text',
          render: props => (
            <TextRenderer
              {...props}
              value={props?.index + 1}
              style={{
                textAlign: 'right',
              }}
            />
          ),
          header: 'Sequence',
          align: 'right',
          responsive: 'sm',
        },
        {
          type: 'text',
          field: 'message_send_count',
          header: 'Message Send',
          onPress: prospectsListForEmailMessage,
          align: 'right',
        },
        {
          type: 'text',
          field: 'positive_message_response_count',
          header: 'Positive',
          onPress: positiveProspectsForMessageSend,
          align: 'right',
        },
        {
          type: 'text',
          field: 'negative_response_count',
          header: 'Negative',
          onPress: negativeProspectsForMessage,
          align: 'right',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editOutreachContent,
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete Message',
            message: 'Do you want to delete this message?',
          },
          variant: 'error',
          onPress: deleteMessage,
          visible: ({row}) => !row.message_send_count,
        },
      ]}
    />
  );
};
