import React from 'react';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {EmailPageStyles} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {BaseForm} from '../../../components/form/Form';
import {Button} from '../../../components/button/Button';
import { termsOfServiceURL } from '../../../modules/common/constants/SourceConstants';

const EnterPassword = props => {
  const {navigation, route: {params} = {}} = props || {};
  const {
    containerStyle,
    mainContainerStyle,
    subContainerStyle,
    buttonStyles,
    backButtonIcon,
    LOCOMOIcon,
    headingTextStyle,
    iconContainerStyle,
    textStyle,
    UnthinkableIcon,
    spanTextStyle,
    secondaryTextStyle,
    FormTextContainerStyle,
    forgotPasswordTextStyle,
  } = useStyles(EmailPageStyles);

  const {email, onLogin} = params || {};

  const handleKeyPress = e => {
    let data = {email, password: e.target.value};
    if (e.key === 'Enter') {
      onLogin(data);
    }
  };

  return (
    <View style={containerStyle}>
      <View style={subContainerStyle}>
        <Image source={LOCOMOIcon} style={iconContainerStyle} />
        <View style={{...mainContainerStyle, marginBottom: 40}}>
          <Row gap={12} style={{alignItems: 'center', marginBottom: 12}}>
            <TouchableOpacity
              onPress={() => {
                navigation.goBack();
              }}>
              <Image source={backButtonIcon} />
            </TouchableOpacity>
            <Text style={headingTextStyle}>Continue with email</Text>
          </Row>
          <Text style={textStyle}>
            Your account exists with.
            <Text>Please share your password to login.</Text>
          </Text>
          <BaseForm
            skipHeader
            footer={({values}) => {
              let data = {...values, email};
              return {
                actions: [
                  <View style={{flex: 1, marginTop: 28}}>
                    <Button
                      onPress={() => {
                        onLogin(data);
                      }}
                      text="Continue"
                      styles={buttonStyles}
                      gap={8}
                      disabled={!(values && values.password)}
                    />
                  </View>,
                ],
              };
            }}
            skipDefaultSave
            layoutFields={[
              {
                label: 'Password',
                field: 'password',
                type: 'password',
                onKeyPress: handleKeyPress,
                required: true,
                autoFocus: true,
              },
            ]}
            {...props}
          />
          <View style={FormTextContainerStyle}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('forgotPassword',{email});
              }}>
              <Text style={forgotPasswordTextStyle}>Forgot Password?</Text>
            </TouchableOpacity>
          </View>
          <Text style={secondaryTextStyle}>
            By signing up, you agree to our{' '}
            <Text as={'span'} style={spanTextStyle}  onPress={() => Linking.openURL(termsOfServiceURL)}>
              Terms of Service
            </Text>{' '}
            {/* and{' '}
            <Text as={'span'} style={spanTextStyle}>
              Data processing Agreement
            </Text> */}
          </Text>
        </View>
        <Image source={UnthinkableIcon} />
      </View>
    </View>
  );
};

export default EnterPassword;
