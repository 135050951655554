import React from 'react';
import {TabView} from '../../../components/tab';
import {EmployeeProjectTasks} from './TasksTable';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getToday, getYesterday} from '@unthinkable/react-filters';
import {taskFilters} from './TaskActions';
import {useAppStateContext} from '../../../providers/AppState';

export const TeamTasksTab = props => {
  const {
    route: {params},
  } = props;
  const {fromProject, all_tasks, activeTask, overdueTasks} = params;
  const {user} = useAppStateContext();

  const {searchValue, filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      completed_on: getYesterday(),
    },
  });

  let {
    params: {completed_on},
    filter,
  } = filterValues;

  const {from: startOfToday} = getToday();
  let addOnFilter = {};
  addOnFilter = activeTask ? {...filter, status: 'active' } : filter;
  addOnFilter = overdueTasks ? {
    ...filter, type: { $in: ['Task', 'Continuous'] },
    due_date: { $lt: startOfToday }
  } : addOnFilter;

  const {from: start_date, to: end_date} = completed_on;

  // Akshat Garg - 22/08/24 - adding backlogtab for all tasks
  const backLogTab = all_tasks ? {
    backlog: {
      label: 'Plan',
      view: (
        <EmployeeProjectTasks
          {...props}
          searchValue={searchValue}
          filterValues={{
            filter: {
              ...addOnFilter, status: 'backlog',
            }
          }}
        />
      ),
      eventSourceId: 'Task',
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            !fromProject && taskFilters.project({ user }),
            taskFilters.due_date,
          ]}
        />,
      ],
    },
  } : {};

  return (
    <TabView
      {...props}
      tabs={{
        ...backLogTab,
        active: {
          label: 'Active',
          view: (
            <EmployeeProjectTasks
              {...props}
              searchValue={searchValue}
              filterValues={{
                filter: addOnFilter,
              }}
            />
          ),
          eventSourceId: 'Task',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                !fromProject && taskFilters.project({user}),
                taskFilters.due_date,
              ]}
            />,
          ],
        },
        completed: {
          label: 'Completed',
          apiKey: 'active',
          eventSourceId: 'Task',
          view: (
            <EmployeeProjectTasks
              {...props}
              searchValue={searchValue}
              addOnParams={{sort: {completed_on: -1, project_id: 1, index: 1}}}
              filterValues={{
                filter: {
                  ...filter,
                  status: 'completed',
                  completed_on: {
                    $gte: start_date,
                    $lte: end_date,
                  },
                },
              }}
            />
          ),
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[!fromProject && taskFilters.project({user})]}
            />,
            <PeriodRangeFilter
              field="completed_on"
              filterValues={filterValues}
              onChangeFilter={onChangeFilter}
            />,
          ],
        },
      }}
    />
  );
};
