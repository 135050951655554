import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AccessResourceForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/accessresources',
    eventSourceId: ['accessResourceAdded'],
  });
  return (
    <Form
      api={`/accessresources/${row?._id}`}
      fields={{
        resource: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'resource',
          label: 'Resource',
        },
      ]}
      {...props}
    />
  );
};

export const AccessResource = props => {
  return <AccessResourceForm header="Add Access Resource" {...props} />;
};

export const UpdateAccessResourceForm = props => {
  return (
    <AccessResourceForm
      mode="edit"
      header="Update Access Resource"
      {...props}
    />
  );
};
