import React from 'react';
import {Row, Image, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {resolveExp} from '@unthinkable/react-utils';
import {MultiTag} from '../../../components/chip/MultiChip';

const PlusMultipleTextRender = props => {
  let {row, value, valueField, readOnly, maxWidth = 200} = props;
  if (valueField) {
    value = resolveExp(row, valueField);
  }

  let {rowStyle, addPlus, iconStyle, iconContainerStyle} = useComponentTheme(
    'MultipleTextRenderStyle',
  );

  return (
    <Row style={rowStyle}>
      {value?.length ? (
        <MultiTag values={value} maxWidth={maxWidth} />
      ) : readOnly ? null : (
        <View style={iconContainerStyle}>
          <Image source={addPlus} style={iconStyle} />
        </View>
      )}
    </Row>
  );
};

export default PlusMultipleTextRender;
