import {Styled, TouchableOpacity} from '@unthinkable/react-core-components';

export const PositionContainer = Styled(TouchableOpacity)
  .withConfig({
    shouldForwardProp: prop => !['position'].includes(prop),
  })
  .attrs({
    activeOpacity: 1,
  })`
  flex: 1;
  overflow: hidden;
  ${({position}) => {
    switch (position) {
      case 'screenBottom':
        return {
          justifyContent: 'flex-end',
        };
      case 'screenTop':
        return {
          justifyContent: 'flex-start',
        };
      case 'screenRight':
        return {
          alignItems: 'flex-end',
        };
      case 'screenLeft':
        return {
          alignItems: 'flex-start',
        };
      case 'screenCenter':
        return {
          alignItems: 'center',
          justifyContent: 'center',
        };
    }
  }}
`;

export const ModalViewContainer = Styled(TouchableOpacity)
  .withConfig({
    shouldForwardProp: prop => !['position'].includes(prop),
  })
  .attrs({
    activeOpacity: 1,
  })`
  background-color: #FFFFFF;
  overflow:hidden;
  cursor: auto;
  ${props => props.styles?.container}
  ${({position, borderRadius = 8}) => {
    switch (position) {
      case 'fullScreen':
        return {
          width: '100%',
          height: '100%',
          borderRadius: 0,
        };
      case 'screenBottom':
        return {
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          maxHeight: '80%',
          width: '100%',
        };
      case 'screenTop':
        return {
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
          maxHeight: '80%',
          width: '100%',
        };
      case 'screenRight':
        return {
          borderBottomLeftRadius: borderRadius,
          borderTopLeftRadius: borderRadius,
          width: '40%',
          height: '100%',
        };
      case 'screenLeft':
        return {
          borderBottomRightRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          width: '40%',
          height: '100%',
        };
      default:
        return {
          maxWidth: '90%',
          maxHeight: '90%',
          borderRadius,
        };
    }
  }}
  ${({position, styles}) => styles[position]}
  ${({position, size, styles}) => {
    if (position === 'screenCenter') {
      return styles?.size?.[size || 'medium'];
    }
  }}
  ${props => (props.width ? {width: props.width} : void 0)}
  ${props => (props.height ? {height: props.height} : void 0)}
  ${props => (props.minWidth ? {minWidth: props.minWidth} : void 0)}
  ${props => (props.maxWidth ? {maxWidth: props.maxWidth} : void 0)}
  ${props => (props.minHeight ? {minHeight: props.minHeight} : void 0)}
  ${props => (props.maxHeight ? {maxHeight: props.maxHeight} : void 0)}
  ${props =>
    props.backgroundColor ? {backgroundColor: props.backgroundColor} : void 0}
`;
