export const userDetailStyle = ({theme: {fonts, colors}}) => {
  return {
    container: {paddingTop: 16, alignItems: 'center'},
    name: {color: colors.NEUTRAL_HIGH, ...fonts.BODY1},
    email: {color: colors.NEUTRAL_MEDIUM, ...fonts.CAPTION},
    code: {color: colors.NEUTRAL_MEDIUM, ...fonts.BODY2},
  };
};

export const EmailPermissionStyle = ({theme: {fonts, colors}}) => {
  return {
    emailText: {
      marginBottom: 10,
      marginTop: 20,
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.HEADING2,
    },
  };
};
