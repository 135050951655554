import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const PracticeForm = props => {
    const { route: { params } = {} } = props;

    const { row } = params;
    let { onSubmit } = useFormSubmit({
        uri: '/practices',
        eventSourceId: 'practice',
    });
    return (
        <Form
            api={`/practices/${row?._id}`}
            onSubmit={onSubmit}
            fields = {{
                name : 1,
                owner : {_id : 1, name : 1}
            }}
            submitAction="Save"
            layoutFields={[
                {
                    label: 'Name',
                    type: 'text',
                    field: 'name',
                },
                {
                    type: 'autoComplete',
                    field: 'owner',
                    label: 'Owner',
                    api: '/employeeSuggestions',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    searchFields: ['name', 'official_email_id', 'employee_code'],
                    required: true,
                },
            ]}
            {...props}
        />
    );
};

export const AddPractice = props => {
    return <PracticeForm header="Add Practice" {...props} />;
};

export const EditPractice = props => {
    return (
        <PracticeForm
            mode="edit"
            header={'Update Practice    '}
            {...props}
        />
    );
};
