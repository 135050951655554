import React from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {TableHeader} from '../../../../components/table/Headers';
import {AISuggestionsButton} from '../../cellrenders/AISuggestionRenders';
import {useMultiTableData} from '../../../../components/table/MultiDataTable';
import {NoDataFound} from '../../../../components/table/Table';
import {AITable} from '../../../../components/table/AITable';

export const ScreenIA = props => {
  const {navigation, route} = props;
  let {params} = route;

  let {row, project, module} = params;

  let fonts = useTheme('fonts');
  let colors = useTheme('colors');
  const {data, loading} = useMultiTableData({
    api: `/projectscreennavigations`,
    filter: {
      project_id: project?._id,
    },
    secondaryApiProps: {
      api: `/projectscreennavigations/ai`,
      params: {
        parent_entity: 'Module',
        parent_entity_id: module?._id,
      },
      eventSourceId: 'ProjectScreenAI',
    },
    eventSourceId: 'ProjectScreenAI',
  });

  const roleRowFunction = ({row: roleDetail}) => {
    return (
      <ScrollView style={{gap: 20}}>
        {roleDetail.map(item => {
          const [key, value] = item;
          if (value.user_stories) {
            const {description, user_stories} = value;
            return (
              <TouchableOpacity
                style={{
                  padding: 12,
                  borderRadius: 8,
                  backgroundColor: colors.OUTLINE,
                }}
                onPress={() => {
                  navigation.navigate('ai-role-detail', {role: value});
                }}>
                <Text style={{...fonts.HEADING1, color: colors.NEUTRAL_HIGH}}>
                  {key}
                </Text>
                <Text style={{...fonts.BODY3, color: colors.NEUTRAL_MEDIUM}}>
                  Description: {description}
                </Text>
                <Text style={{...fonts.BODY3, color: colors.NEUTRAL_MEDIUM}}>
                  User Stories:
                </Text>
                <View>
                  {user_stories.map((userStory, index) => (
                    <Text
                      style={{...fonts.BODY3, color: colors.NEUTRAL_MEDIUM}}>
                      {`${index + 1}. ${userStory}`}
                    </Text>
                  ))}
                </View>
              </TouchableOpacity>
            );
          } else if (typeof value === 'object') {
            return (
              <View style={{gap: 8}}>
                <Text style={{...fonts.TITLE, color: colors.NEUTRAL_HIGH}}>
                  {key}
                </Text>
                {roleRowFunction({row: Object.entries(value)})}
              </View>
            );
          }
        })}
      </ScrollView>
    );
  };

  let navigationData = data?.[0]?.navigation;
  if (typeof navigationData === 'string') {
    navigationData = JSON.parse(navigationData);
  }
  let newData = {
    ...navigationData,
  };

  newData = Object.entries(newData);
  return (
    <>
      <TableHeader
        closeable
        title={'Screen IA'}
        actions={[
          project?.ai_enabled && (
            <AISuggestionsButton
              {...props}
              newPromptName="EntityNewSuggestionsV2"
              entityName="projectscreennavigations"
              eventSourceId="ProjectScreenAI"
              filter={{
                module_id: module._id,
              }}
              params={{
                parent_entity: 'Module',
                parent_entity_id: module._id,
                project_id: project._id,
                module_id: module._id,
                label: 'ScreenNavigations',
                role: row?.role,
                // feature_id: feature?._id,
              }}
              initialValues={{
                project_id: project._id,
                module_id: module._id,
              }}
            />
          ),
        ]}
      />

      <View style={{flex: 1, overflow: 'hidden'}}>
        <AITable
          aiEnabled
          aiProps={{
            buttonText: 'Ask AI to generate Screen IA',
            promptName: 'EntityNewSuggestionsV2',
            entityName: 'projectscreennavigations',
            eventSourceId: 'ProjectScreenAI',
            filter: {
              module_id: module._id,
            },
            params: {
              parent_entity: 'Module',
              parent_entity_id: module._id,
              project_id: project._id,
              module_id: module._id,
              label: 'ScreenNavigations',
              role: row?.role,
            },
            initialValues: {
              project_id: project._id,
              module_id: module._id,
            },
          }}
          data={data?.length ? [newData] : []}
          loading={loading}
          renderRow={roleRowFunction}
          variant="none"
        />
      </View>
    </>
  );
};
