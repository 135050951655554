import React from 'react';
import {TabView} from '../../../components/tab';
import {BackLinkTable} from './BackLinkTable';
import {AddButton} from '../../../components/button/AddButton';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';
export const BackLinkTabs = props => {
  const {
    route: {params},
  } = props;

  const tabs = {
    active: {
      label: 'Active',
      view: <BackLinkTable {...props} params={params} status={'active'} />,
    },
    broken: {
      label: 'Broken',
      view: <BackLinkTable {...props} params={params} status={'broken'} />,
    },
    removed: {
      label: 'Removed',
      view: <BackLinkTable {...props} params={params} status={'removed'} />,
    },
  };

  let actions = [
    <ObjectWiseTasks
      {...props}
      params={{...props?.route?.params}}
      object={{
        _id: '654de3180ac185c7cfd943d3',
        name: 'BackLink',
      }}
    />,
    <AddButton title="Add" view="add-back-link" {...props} />,
  ];

  return <TabView tabs={tabs} actions={actions} {...props} />;
};
