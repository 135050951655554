import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const ImportEmployeeDeliveryAndTeamLead = props => {
  const {
    route: {params},
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/importExcel',
    eventSourceId: 'employeeassociation',
  });

  return (
    <Form
      {...props}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            model: 'EmployeeAssociation',
            source: 'employee_association',
          },
        };
      }}
      onSubmit={onSubmit}
      header={'Import Delivery Owner or Team Lead'}
      submitAction={'Upload'}
      layoutFields={[
        {
          placeholder: 'File',
          field: 'file',
          type: 'file',
          size: 6,
        },
      ]}
    />
  );
};
