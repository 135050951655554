import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';

export const customDigitalPlatformOppControllers = ({
  route: {params},
  navigation,
} = {}) => {
  const invoke = useInvoke({method: 'put'});
  const {post} = useAppStateContext();
  const period = params?.period;
  const periodFilter = period
    ? {
        $lte: period.to,
        $gte: period.from,
      }
    : void 0;

  const assignToTechPerson = ({selectedIds}) => {
    navigation.navigate(`assign-tech-person`, {
      ...params,
      selectedIds,
    });
  };

  const assignTechPersonOnActive = ({selectedIds}) => {
    navigation.navigate(`assign-tech-person-on-active`, {
      ...params,
      selectedIds,
    });
  };


  const updatePractice = ({selectedIds}) => {
    navigation.navigate(`update-practice-on-rfq`, {
      ...params,
      selectedIds,
    });
  };



  const UpdateSolutionConsultant = ({selectedIds}) => {
    navigation.navigate(`update-solution-consultant`, {
      ...params,
      selectedIds,
    });
  };

  const UpdatePreSales = ({selectedIds}) => {
    navigation.navigate(`update-pre-sales`, {
      ...params,
      selectedIds,
    });
  };
  const UpdateOwner = ({selectedIds}) => {
    navigation.navigate(`update-owner-for-rfq`, {
      ...params,
      selectedIds,
    });
  };

  const updateSubStage = ({selectedIds}) => {
    navigation.navigate(`update-sub-stage`, {
      ...params,
      selectedIds,
    });
  };

  const opportunityMarkWon = ({selectedIds}) => {
    navigation.navigate(`opportunity-mark-won`, {
      ...params,
      selectedIds,
    });
  };

  const opportunityMarkWonRowActive = ({row}) => {
    navigation.navigate(`opportunity-mark-won`, {
      ...params,
      opportunity: row,
    });
  };

  const assignPreSales = ({selectedIds}) => {
    navigation.navigate(`assign-pre-sales`, {
      ...params,
      selectedIds,
    });
  };

  const opportunityMarkLost = ({selectedIds}) => {
    navigation.navigate(`opportunity-mark-lost`, {
      ...params,
      selectedIds,
    });
  };

  const opportunityDropRfq = ({selectedIds}) => {
    navigation.navigate(`opportunity-drop-rfq`, {
      ...params,
      selectedIds,
    });
  };

  const opportunityMarkLostRowAction = ({row}) => {
    navigation.navigate(`opportunity-mark-lost`, {
      ...params,
      opportunity: row,
    });
  };

  const opportunityDropRfqRowAction = ({row}) => {
    navigation.navigate(`opportunity-drop-rfq`, {
      ...params,
      opportunity: row,
    });
  };

  const opportunityMarkActive = ({selectedIds}) => {
    invoke({
      uri: `/opportunities/batchUpdate`,
      props: {
        ids: selectedIds,
        updates: {
          status: 'Active',
        },
      },
    });
  };

  const opportunitySubmitProposal = ({row}) => {
    navigation.navigate(`opportunity-submit-proposal`, {
      ...params,
      opportunity: row,
    });
  };

  const opportunitySubmitQuote = ({row}) => {
    navigation.navigate(`opportunity-submit-quote`, {
      ...params,
      opportunity: row,
    });
  };

  const opportunityProposalPending = ({row}) => {
    post({
      uri: `/opportunities/${row?._id}`,
      method: 'put',
      props: {
        quote_status: 'Pending',
      },
    });
  };

  const customPlatformOpportunityCommunications = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row,
      props: {
        fromOpportunity: true,
      },
    });
  };


  const addProjectionOnOpportunity = ({row}) => {
    navigation.navigate(`add-projection-on-opportunity`, {
      ...params,
      row,
      props: {
        fromOpportunity: true,
      },
    });
  };

  const POCCommunications = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row,
      props: {
        fromOpportunity: true,
      },
    });
  };

  const updateProposalStatus = ({row}) => {
    navigation.navigate(`update-proposal-status`, {
      ...params,
      opportunity: row,
    });
  };

  const updateProposalStatusPreSales = ({row}) => {
    navigation.navigate(`update-proposal-status-pre-sales`, {
      ...params,
      opportunity: row,
    });
  };

  const updatePOCStatus = ({row}) => {
    navigation.navigate(`update-poc-status`, {
      ...params,
      opportunity: row,
    });
  };

  const updateWinningChance = ({row}) => {
    navigation.navigate(`update-winning-chance`, {
      ...params,
      opportunity: row,
    });
  };

  return {
    assignToTechPerson,
    updateSubStage,
    opportunityMarkWon,
    opportunityMarkLost,
    updateProposalStatusPreSales,
    opportunityDropRfq,
    opportunityMarkActive,
    opportunitySubmitProposal,
    opportunityProposalPending,
    customPlatformOpportunityCommunications,
    opportunitySubmitQuote,
    POCCommunications,
    updateProposalStatus,
    updateWinningChance,
    opportunityMarkWonRowActive,
    opportunityMarkLostRowAction,
    opportunityDropRfqRowAction,
    updatePOCStatus,
    assignPreSales,
    assignTechPersonOnActive,
    updatePractice,
    UpdateSolutionConsultant,
    UpdatePreSales,
    addProjectionOnOpportunity,
    UpdateOwner
  };
};
