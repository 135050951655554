import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AssignDepartmentRoleOnEmployee = props => {
  const {
    route: {params},
  } = props;
  const {employee} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'Employee',
  });
  return (
    <Form
      header="Assign Department Role"
      defaultValues={{
        _id: employee?._id,
        department_role: employee?.department_role,
      }}
      layoutFields={[
        {
          field: 'department_role',
          type: 'autoComplete',
          label: 'Department Role',
          api: `/departmentroles`,
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            department: employee?.department?._id,
          },
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};
