import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddTrainingPurpose = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/trainingPurposes',
    ...props,
  });

  const {
    route: {params},
  } = props;

  const {searchValue, afterSubmit} = params;

  return (
    <Form
      header="Add Training Purpose"
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      defaultValues={{
        name: searchValue,
      }}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
