import React from 'react';
import {Col, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {FontThemeStyles} from '../cards/FontViewTheme';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';
import {useNavigation} from '@react-navigation/native';
import {useInvoke} from '../../../controllers/useInvoke';
import {Tag} from '../../../components/chip/Chip';
import {fontsColumns} from './FeatureFontTheme';

const FontTable = ({fonts, readOnly, themeVersion, ...props}) => {
  const navigation = useNavigation();
  const deleteInvoke = useInvoke({method: 'put', eventSourceId: 'fonts'});

  return (
    <Table
      eventSourceId={'fonts'}
      onRowPress={props => {
        const {row} = props;
        !readOnly &&
          navigation.navigate('edit-font', {
            row,
            themeVersion,
          });
      }}
      data={fonts}
      columns={fontsColumns}
      moreActions={({row}) => [
        {
          visible: !readOnly,
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
          },
          onPress: () => {
            deleteInvoke({
              uri: `/projectThemeVersions/${themeVersion?._id}/fonts/${row?._id}/delete`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

const FontView = ({fonts, deviceTypes = [], primaryDeviceType, ...props}) => {
  let desktopFonts = [];
  let mobileFonts = [];
  let tabletFonts = [];

  fonts?.forEach((font, index) => {
    desktopFonts[index] = {
      fontName: font?.font_name,
      fontSize: font?.font_size,
      lineHeight: font?.line_height,
      letterSpacing: font?.letter_spacing,
      fontWeight: font?.font_weight,
      italic: font?.italic,
      fontFamily: font?.font_family,
      _id: font?._id,
      updated: font?.updated,
    };
    mobileFonts[index] = {
      fontName: font?.font_name,
      fontSize: font?.mobile_font_size,
      lineHeight: font?.mobile_line_height,
      letterSpacing: font?.mobile_letter_spacing,
      fontWeight: font?.mobile_font_weight,
      italic: font?.mobile_italic,
      fontFamily: font?.mobile_font_family,
      _id: font?._id,
      updated: font?.updated,
    };
    tabletFonts[index] = {
      fontName: font?.font_name,
      fontSize: font?.tablet_font_size,
      lineHeight: font?.tablet_line_height,
      letterSpacing: font?.tablet_letter_spacing,
      fontWeight: font?.tablet_font_weight,
      italic: font?.tablet_italic,
      fontFamily: font?.tablet_font_family,
      _id: font?._id,
      updated: font?.updated,
    };
  });

  const {mainContainer, titleText, descriptionText} =
    useStyles(FontThemeStyles);

  const tabs = {};
  if (!deviceTypes.length || deviceTypes.includes('Mobile')) {
    tabs.mobileFonts = {
      label:
        primaryDeviceType && primaryDeviceType === 'Mobile'
          ? 'Mobile*'
          : 'Mobile',
      view: <FontTable fonts={mobileFonts} {...props} />,
    };
  }
  if (!deviceTypes.length || deviceTypes.includes('Tab')) {
    tabs.tabletFonts = {
      label:
        primaryDeviceType && primaryDeviceType === 'Tab' ? 'Tablet*' : 'Tablet',
      view: <FontTable fonts={tabletFonts} {...props} />,
    };
  }
  if (!deviceTypes.length || deviceTypes.includes('Desktop')) {
    tabs.desktopFonts = {
      label:
        primaryDeviceType && primaryDeviceType === 'Desktop'
          ? 'Desktop*'
          : 'Desktop',
      view: <FontTable fonts={desktopFonts} {...props} />,
    };
  }
  return (
    <Col gap={20} style={mainContainer}>
      <View>
        <Text style={titleText}>Typography and fonts</Text>
        <Text
          style={
            descriptionText
          }>{`You can set the font size & style for each platform (screensize) types so that your styles are responsive`}</Text>
      </View>
      <TabView tabItemWidth={150} tabs={tabs} />
    </Col>
  );
};

export const FontTheme = ({route: {params}}) => {
  const {themeVersion, readOnly, project} = params;

  const {deviceTypes, primaryDeviceType} = project;
  const {data} = useFetchData({
    api: `/projectThemeVersions/${themeVersion?._id}`,
    eventSourceId: ['fonts'],
  });

  return (
    <FontView
      deviceTypes={deviceTypes}
      primaryDeviceType={primaryDeviceType}
      readOnly={readOnly}
      fonts={data?.fonts}
      themeVersion={themeVersion}
    />
  );
};
