import React from 'react';
import {SHORT_NAMES} from '../../common/constants/SourceConstants';
import {Tag} from '../../../components/chip/Chip';

const TaskSourceRender = ({sourceField, sourceIdField, row, onChipPress}) => {
  let sourceValue = row[sourceField];
  let sourceValueName = sourceValue?.name || '';
  let sourceValueColor = sourceValue?.color || '';
  let sourceValueId = sourceValue?._id || '';

  const sourceConstantValue = SHORT_NAMES[sourceValueId] || sourceValueName;

  let sourceIdValue = row[sourceIdField];
  sourceIdValue = sourceIdValue?.name || '';

  if (!sourceConstantValue && !sourceIdValue) {
    return null;
  }
  let textValue = '';
  if (sourceIdValue) {
    textValue += `${sourceIdValue}`;
  }
  textValue += ` (${sourceConstantValue})`;

  return (
    <Tag value={textValue} color={sourceValueColor} onPress={onChipPress} />
  );
};

export default TaskSourceRender;
