import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Col, Text, View} from '@unthinkable/react-core-components';
import {useFilter} from '@unthinkable/react-filters';
import {RowWithSeparator} from '@unthinkable/react-layout';
import React, {useState} from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Chip, ChipComponent, Tag} from '../../../components/chip/Chip';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {GroupTable, Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  UserAvatarWithNameAndEmail,
  UserAvatarWithNameAndEmployeeCode,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {useAppStateContext} from '../../../providers/AppState';

const RenderActions = ({row = {}, styles = {}, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ResignationEngg', 'replacement'],
    close: false,
    setLoading,
  });
  let color = '';
  if (row.replacement_required === true) {
    color = '#34C759';
  } else {
    color = '#FF9500';
  }
  let {rowText = {}} = styles;
  if (row.replacement_required === true && row.assigned_type === 'Billable') {
    return <Text style={{...rowText, color: color}}>{'Required'}</Text>;
  } else if (
    row.replacement_required === false &&
    row.assigned_type === 'Billable'
  ) {
    return <Text style={{...rowText, color: color}}>{'Not Required'}</Text>;
  } else if (!row.replacement_required && row.assigned_type === 'Billable') {
    return (
      <View style={{flexDirection: 'row', gap: 20}}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                navigation.navigate(`replacement-form`, {
                  ...row,
                  defaultValues: {status: 'due', type: 'Resignation'},
                  fromResignation: true,
                });
              }}>
              <Text style={{...rowText, color: '#007AFF'}}>Yes </Text>
            </View>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/employees/${row._id}`,
                  props: {
                    replacement_required: false,
                  },
                });
              }}>
              <Text style={{...rowText, color: '#007AFF'}}>No</Text>
            </View>
          </>
        )}
      </View>
    );
  }
};

export const ResignationActiveTable = props => {
  const {navigation, filterValues, searchValue} = props;
  const fetchProps = {
    // params,
    // search,
    // addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: `/employees/active-resignations`,
  };
  const {user, download} = useAppStateContext();

  return (
    <Table
      {...props}
      selection={{
        actions: [
          {
            title: 'Start no dues',
            onPress: props => {
              const {selectedIds} = props;
              invoke({
                uri: '/resignation15DaysBefore',
                message: 'No dues started successfully',
                props: {
                  ManualProcessStart: selectedIds,
                },
              });
            },
          },
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'assigned_to.employee_code',
                      Name: 'assigned_to.name',
                      Gender: 'assigned_to.gender',
                      'Father name': 'assigned_to.father_name',
                      Experience: 'experience',
                      'Official Email Id': 'assigned_to.official_email_id',
                      'Personal Email': 'email_id',
                      'Joining date': 'joining_date_download',
                      Resign_date: 'resign_date_new',
                      'Relieving date': 'relieving_date_download',
                      'Expected relieving date':
                        'expected_relieving_date_download',
                      'Exit type': 'assigned_to.exit_type',
                      'Desirable stauts': 'assigned_to.desireable_status',
                      Reason: 'assigned_to.resign_reason',
                      'Reporting To': 'assigned_to.reporting_to_name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId={['ResignationActive']}
      api={'/employees/active-resignations'}
      search={searchValue}
      params={filterValues?.filter}
      searchFields="name"
      columns={[
        {
          header: 'Resigned on',
          type: 'date',
          field: 'assigned_to.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 220,
        },
        {
          header: 'Exp',
          field: 'experience',
          width: 100,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
          render: ({row, styles}) => {
            const {experience} = row;
            return <Text style={{...styles.rowText}}>{experience} yr</Text>;
          },
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          minWidth: 140,
        },
        {
          header: 'Team',
          width: 140,
          render: ({row}) => {
            const {project} = row;
            return (
              <PrimaryColumnCard
                primaryTitle={
                  <Chip
                    value={
                      project?.project
                        ? 'Engineering'
                        : row?.employee_team?.name
                    }
                    color={
                      project?.project
                        ? 'Accent 4'
                        : row?.employee_team?.name
                        ? 'Accent 4'
                        : 'Accent 2'
                    }
                  />
                }
                // items={[
                //   {value: project?.project ? project?.code : ''},
                //   {
                //     value: project?.project
                //       ? `${row?.totalActualWorkingHours}h/day`
                //       : '',
                //   },
                // ]}
              />
            );
          },
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'assigned_to.reporting_to',
          align: 'center',
          width: 80,
        },
        {
          header: 'Type',
          width: 120,
          type: 'text',
          render: ({row, styles}) => {
            const {assigned_to} = row;
            let color = '';
            if (assigned_to?.exit_type === 'Voluntary') {
              color = 'Accent 2';
            } else if (assigned_to.exit_type === 'Involuntary') {
              color = 'Accent 1';
            }
            return (
              <Col>
                <Chip
                  value={assigned_to?.exit_type}
                  color={color}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {assigned_to?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Expected exit',
          type: 'date',
          field: 'assigned_to.expected_relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Reason',
          type: 'text',
          width: 200,
          render: ({row}) => {
            return <Chip value={row?.assigned_to?.resign_reason} />;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Update relieving date',
          onPress: ({row}) => {
            navigation.navigate(`update-relieving-date`, {row: row});
          },
        },
        {
          title: 'Update exit type',
          onPress: ({row}) => {
            navigation.navigate(`update-exit-type`, {row: row});
          },
        },
        {
          title: 'Update resign reason',
          onPress: ({row}) => {
            navigation.navigate(`update-resign-reason`, {row: row});
          },
        },
        {
          title: 'Update desirable status',
          onPress: ({row}) => {
            navigation.navigate(`update-desirable-type`, {row: row});
          },
        },
      ]}
    />
  );
};

export const ResignationAllGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  //
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: `/employees/all-resigned`,
    fromExport: true,
  };
  return (
    <GroupTable
      {...props}
      params={params}
      sort={{resign_date_new: -1}}
      addOnFilter={addOnFilter}
      search={searchValue}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/all-resigned`}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
              <GroupContentItem value={`${row.activeCount} Active`} />
              <GroupContentItem value={`${row.closedCount} Closed`} />
            </RowWithSeparator>
          );
        },
      }}
      selection={{
        actions: [
          {
            title: 'Start no dues',
            onPress: props => {
              const {selectedIds} = props;
              invoke({
                uri: '/resignation15DaysBefore',
                message: 'No dues started successfully',
                props: {
                  ManualProcessStart: selectedIds,
                },
              });
            },
          },
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      'Joining date': 'joining_date',
                      Resign_date: 'resign_date_new',
                      'Relieving date': 'relieving_date',
                      'Expected relieving date': 'expected_relieving_date',
                      'Exit type': 'exit_type',
                      'Desirable stauts': 'desireable_status',
                      Status: 'employee_resignation_status',
                      Reason: 'resign_reason.reason',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Resigned on',
          type: 'date',
          field: 'resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          minWidth: 140,
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'reporting_to',
          align: 'center',
          width: 80,
        },
        {
          header: 'Type',
          width: 120,
          type: 'text',
          render: ({row, styles}) => {
            const {ACCENT2_LOW, ACCENT2_HIGH, ACCENT1_LOW, ACCENT1_HIGH} =
              useTheme('colors');

            let color = '';
            let textColor = '';

            if (row?.exit_type === 'Voluntary') {
              color = ACCENT2_LOW;
              textColor = ACCENT2_HIGH;
            } else if (row.exit_type === 'Involuntary') {
              color = ACCENT1_LOW;
              textColor = ACCENT1_HIGH;
            }
            return (
              <Col>
                <Chip
                  color={color}
                  textColor={textColor}
                  value={row?.exit_type}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {row?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Expected exit',
          type: 'date',
          field: 'expected_relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Reason',
          type: 'text',
          width: 200,
          render: ({row}) => {
            return <Chip value={row?.resign_reason?.reason} />;
          },
        },
        {
          header: 'Status',
          width: 140,
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            const {employee_resignation_status} = row;
            if (employee_resignation_status == 'Active') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT3_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {`Exp. Exit ${moment(row?.expected_relieving_date).format(
                      'DD MMM,YY',
                    )}`}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Exit') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT5_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.relieving_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Withdrawn') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT6_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.resign_withdraw_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
          },
        },
        // {
        //   header: 'Service Duration',
        //   render: ({row}) => {
        //     return (
        //       <ServiceDurationAvatar
        //         service_duration={row?.service_duration}
        //         color={row?.color}
        //         relieving_date={row?.relieving_date}
        //         joining_date={row?.joining_date}
        //       />
        //     );
        //   },
        //   width: 180,
        // },
        // {
        //   header: 'Exit Category',
        //   render: ({row}) => {
        //     return (
        //       <ExitCategoryAvatar
        //         exit_type={row?.exit_type}
        //         color={row?.color}
        //         desireable_status={row?.desireable_status}
        //       />
        //     );
        //   },
        //   width: 130,
        //   align: 'left',
        // },
      ]}
      moreActions={() => [
        {
          title: 'Start no dues',
          confirm: {
            title: 'Start no dues',
            message: 'Are you sure you want to start no dues of this employee?',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: '/resignation15DaysBefore',
              message: 'No dues started successfully',
              props: {
                ManualProcessStart: row,
              },
            });
          },
        },
        {
          title: 'Send Exit Forms',
          confirm: {
            title: 'Send Exit Forms',
            message: 'Are you sure you want to send exit Forms to this employee?',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: '/sendExitFormInManualCase',
              message: 'Send Successfully',
              props: {
                employee: row,
              },
            });
          },
        },
      ]}
    />
  );
};

export const ResignationClosedGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  //

  const fetchProps = {
    params,
    // search,
    // addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: `/employees/closed-resigned`,
    fromExport: true,
  };
  const {user, download} = useAppStateContext();

  return (
    <GroupTable
      {...props}
      params={params}
      sort={{resign_date_new: -1}}
      addOnFilter={addOnFilter}
      search={searchValue}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/closed-resigned`}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      'Joining date': 'joining_date',
                      Resign_date: 'resign_date_new',
                      'Relieving date': 'relieving_date',
                      'Expected relieving date': 'expected_relieving_date',
                      'Exit type': 'exit_type',
                      'Desirable stauts': 'desireable_status',
                      Reason: 'resign_reason',
                      'Reporting To': 'reporting_to.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
              {/* <GroupContentItem value={`${row.activeCount} Active`} />
              <GroupContentItem value={`${row.closedCount} Closed`} /> */}
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Resigned on',
          type: 'date',
          field: 'resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          minWidth: 140,
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'reporting_to',
          align: 'center',
          width: 80,
        },
        {
          header: 'Type',
          width: 120,
          type: 'text',
          render: ({row, styles}) => {
            const {ACCENT2_LOW, ACCENT2_HIGH, ACCENT1_LOW, ACCENT1_HIGH} =
              useTheme('colors');

            let color = '';
            let textColor = '';

            if (row?.exit_type === 'Voluntary') {
              color = ACCENT2_LOW;
              textColor = ACCENT2_HIGH;
            } else if (row.exit_type === 'Involuntary') {
              color = ACCENT1_LOW;
              textColor = ACCENT1_HIGH;
            }
            return (
              <Col>
                <Chip
                  color={color}
                  textColor={textColor}
                  value={row?.exit_type}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {row?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Reason',
          type: 'text',
          // width: 140,
          render: ({row}) => {
            return <Chip value={row?.resign_reason?.reason} />;
          },
        },
        {
          header: 'Status',
          width: 140,
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            const {employee_resignation_status} = row;
            if (employee_resignation_status == 'Active') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT3_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {`Exp. Exit ${moment(row?.expected_relieving_date).format(
                      'DD MMM,YY',
                    )}`}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Exit') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT5_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.relieving_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Withdrawn') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT6_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.resign_withdraw_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
          },
        },
        // {
        //   header: 'Service Duration',
        //   render: ({row}) => {
        //     return (
        //       <ServiceDurationAvatar
        //         service_duration={row?.service_duration}
        //         color={row?.color}
        //         relieving_date={row?.relieving_date}
        //         joining_date={row?.joining_date}
        //       />
        //     );
        //   },
        //   width: 180,
        // },
        // {
        //   header: 'Exit Category',
        //   render: ({row}) => {
        //     return (
        //       <ExitCategoryAvatar
        //         exit_type={row?.exit_type}
        //         color={row?.color}
        //         desireable_status={row?.desireable_status}
        //       />
        //     );
        //   },
        //   width: 130,
        //   align: 'left',
        // },
      ]}
    />
  );
};

export const ResignationEnggTable = props => {
  const {navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter} = filterValues;
  return (
    <Table
      {...props}
      renderHeader={() => (
        <TableHeader
          title={'Engineers Resignations'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'date',
                  field: 'resign_date_new',
                  label: 'Resign Date',
                },
                {
                  type: 'autoComplete',
                  field: 'exit_type',
                  label: 'Type',
                  options: ['Involuntary', 'Voluntary'],
                  placeholder: 'Select Type',
                },
                {
                  type: 'autoComplete',
                  field: 'assigned_type',
                  placeholder: 'Select Billing Status',
                  label: 'Billing Status',
                  options: ['Billable', 'Non billable'],
                },
              ]}
            />,
          ]}
        />
      )}
      eventSourceId={['ResignationEngg']}
      search={searchValue}
      searchFields="name"
      addOnFilter={{
        department: {
          $in: [
            '6128b78a6f91a06849407634',
            '5848ffe17d89cd3f17c7335c',
            '589e9ea16a39a6208813d617',
            '5849228434d56b42ec1aabd2',
            '599c0536ac02221f8db97504',
          ],
        },
        ...filter,
      }}
      api={'/employees/active-resignations'}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
          width: 100,
        },
        {
          header: 'Skills',
          width: 150,
          render: ({row, styles}) => {
            return (
              <ChipComponent
                value={row?.resource_language?.name}
                color={row?.resource_language?.color}
              />
            );
          },
        },
        {
          header: 'Type',
          type: 'text',
          width: 150,
          render: ({row, styles}) => {
            const {assigned_to} = row;
            let color = '';
            if (assigned_to?.exit_type === 'Voluntary') {
              color = 'Accent 2';
            } else if (assigned_to.exit_type === 'Involuntary') {
              color = 'Accent 1';
            }
            return (
              <Col>
                <Chip
                  value={assigned_to?.exit_type}
                  color={color}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {assigned_to?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Billing status',
          width: 150,
          render: ({row, styles}) => {
            return (
              <Col>
                <Chip color="Accent 5">
                  <Text style={{...styles.rowText}}>
                    {row?.assigned_type ? row?.assigned_type : 'Non billable'}
                  </Text>
                </Chip>
              </Col>
            );
          },
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'assigned_to.reporting_to',
          width: 150,
        },
        {
          header: 'Resigned on',
          type: 'date',
          field: 'assigned_to.resign_date_new',
        },
        {
          header: 'Expected exit',
          type: 'date',
          field: 'assigned_to.expected_relieving_date',
        },
        {
          header: 'Resignation reason',
          type: 'text',
          render: ({row}) => {
            return <Chip value={row?.assigned_to?.resign_reason?.reason} />;
          },
        },
        {
          header: 'Replacement Required',
          width: 200,
          align: 'center',
          render: ({row, styles}) => (
            <RenderActions row={row} styles={styles} navigation={navigation} />
          ),
        },
      ]}
    />
  );
};

export const AllEnggResignationGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  //

  return (
    <GroupTable
      {...props}
      params={params}
      sort={{resign_date_new: -1}}
      addOnFilter={addOnFilter}
      search={searchValue}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/all-engineering-resigned`}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
              <GroupContentItem value={`${row.activeCount} Active`} />
              <GroupContentItem value={`${row.closedCount} Closed`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Resigned on',
          type: 'date',
          field: 'resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          width: 220,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          minWidth: 140,
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'reporting_to',
          align: 'center',
          width: 80,
        },
        {
          header: 'Type',
          width: 120,
          type: 'text',
          render: ({row, styles}) => {
            const {ACCENT2_LOW, ACCENT2_HIGH, ACCENT1_LOW, ACCENT1_HIGH} =
              useTheme('colors');

            let color = '';
            let textColor = '';

            if (row?.exit_type === 'Voluntary') {
              color = ACCENT2_LOW;
              textColor = ACCENT2_HIGH;
            } else if (row.exit_type === 'Involuntary') {
              color = ACCENT1_LOW;
              textColor = ACCENT1_HIGH;
            }
            return (
              <Col>
                <Chip
                  color={color}
                  textColor={textColor}
                  value={row?.exit_type}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {row?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Expected exit',
          type: 'date',
          field: 'expected_relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Reason',
          type: 'text',
          width: 200,
          render: ({row}) => {
            return <Chip value={row?.resign_reason?.reason} />;
          },
        },
        {
          header: 'Status',
          width: 140,
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            const {employee_resignation_status} = row;
            if (employee_resignation_status == 'Active') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT3_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {`Exp. Exit ${moment(row?.expected_relieving_date).format(
                      'DD MMM,YY',
                    )}`}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Exit') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT5_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.relieving_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Withdrawn') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT6_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.resign_withdraw_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
          },
        },
        // {
        //   header: 'Service Duration',
        //   render: ({row}) => {
        //     return (
        //       <ServiceDurationAvatar
        //         service_duration={row?.service_duration}
        //         color={row?.color}
        //         relieving_date={row?.relieving_date}
        //         joining_date={row?.joining_date}
        //       />
        //     );
        //   },
        //   width: 180,
        // },
        // {
        //   header: 'Exit Category',
        //   render: ({row}) => {
        //     return (
        //       <ExitCategoryAvatar
        //         exit_type={row?.exit_type}
        //         color={row?.color}
        //         desireable_status={row?.desireable_status}
        //       />
        //     );
        //   },
        //   width: 130,
        //   align: 'left',
        // },
      ]}
    />
  );
};

export const ActiveResignationEnggTable = props => {
  const {navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter} = filterValues;
  return (
    <Table
      {...props}
      eventSourceId={['ResignationEngg']}
      search={searchValue}
      searchFields="name"
      api={'/employees/engineering-active-resignations'}
      columns={[
        {
          header: 'Resigned on',
          type: 'date',
          field: 'assigned_to.resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 220,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
          width: 100,
        },
        {
          header: 'Skill',
          width: 150,
          render: ({row, styles}) => {
            return (
              <ChipComponent
                value={row?.resource_language?.name}
                color={row?.resource_language?.color}
              />
            );
          },
        },
        {
          header: 'Type',
          type: 'text',
          width: 150,
          render: ({row, styles}) => {
            const {assigned_to} = row;
            let color = '';
            if (assigned_to?.exit_type === 'Voluntary') {
              color = 'Accent 2';
            } else if (assigned_to.exit_type === 'Involuntary') {
              color = 'Accent 1';
            }
            return (
              <Col>
                <Chip
                  value={assigned_to?.exit_type}
                  color={color}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {assigned_to?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Billing status',
          width: 150,
          render: ({row, styles}) => {
            return (
              <Col>
                <Chip color="Accent 5">
                  <Text style={{...styles.rowText}}>
                    {row?.assigned_type ? row?.assigned_type : 'Non billable'}
                  </Text>
                </Chip>
              </Col>
            );
          },
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'assigned_to.reporting_to',
          align: 'center',
          width: 80,
        },
        {
          header: 'Expected exit',
          type: 'date',
          field: 'assigned_to.expected_relieving_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 100,
        },
        {
          header: 'Reason',
          type: 'text',
          width: 200,
          render: ({row}) => {
            return <Chip value={row?.assigned_to?.resign_reason} />;
          },
        },
        {
          header: 'Replacement Required',
          width: 200,
          align: 'center',
          render: ({row, styles}) => (
            <RenderActions row={row} styles={styles} navigation={navigation} />
          ),
        },
      ]}
      moreActions={[
        {
          title: 'Update Replacement',
          onPress: ({row}) => {
            navigation.navigate(`replacement-form`, {
              ...row,
              defaultValues: {status: 'due', type: 'Resignation'},
              fromResignation: true,
            });
          },
        },
      ]}
    />
  );
};

export const ClosedClosedGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  //

  return (
    <GroupTable
      {...props}
      params={params}
      sort={{resign_date_new: -1}}
      addOnFilter={addOnFilter}
      search={searchValue}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/closed-engineering-resigned`}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
              {/* <GroupContentItem value={`${row.activeCount} Active`} />
              <GroupContentItem value={`${row.closedCount} Closed`} /> */}
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Resigned on',
          type: 'date',
          field: 'resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Skill',
          field: 'resource_language.name',
          type: 'colorTag',
          colorField: 'resource_language.color',
          minWidth: 140,
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'reporting_to',
          align: 'center',
          width: 80,
        },
        {
          header: 'Type',
          width: 120,
          type: 'text',
          render: ({row, styles}) => {
            const {ACCENT2_LOW, ACCENT2_HIGH, ACCENT1_LOW, ACCENT1_HIGH} =
              useTheme('colors');

            let color = '';
            let textColor = '';

            if (row?.exit_type === 'Voluntary') {
              color = ACCENT2_LOW;
              textColor = ACCENT2_HIGH;
            } else if (row.exit_type === 'Involuntary') {
              color = ACCENT1_LOW;
              textColor = ACCENT1_HIGH;
            }
            return (
              <Col>
                <Chip
                  color={color}
                  textColor={textColor}
                  value={row?.exit_type}
                  displayTextColor={true}
                />
                <Text
                  style={{
                    ...styles.rowText,
                    fontSize: 11,
                    paddingLeft: 10,
                    color: '#A3A3A3',
                  }}>
                  {row?.desireable_status}
                </Text>
              </Col>
            );
          },
        },
        {
          header: 'Reason',
          type: 'text',
          width: 200,
          render: ({row}) => {
            return <Chip value={row?.resign_reason?.reason} />;
          },
        },
        {
          header: 'Status',
          width: 140,
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            const {employee_resignation_status} = row;
            if (employee_resignation_status == 'Active') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT3_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {`Exp. Exit ${moment(row?.expected_relieving_date).format(
                      'DD MMM,YY',
                    )}`}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Exit') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT5_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.relieving_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
            if (employee_resignation_status == 'Withdrawn') {
              return (
                <>
                  <Tag
                    value={employee_resignation_status}
                    color={colors.ACCENT6_MEDIUM}
                  />
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.NEUTRAL_LOW,
                      ...CAPTION_LARGE,
                    }}>
                    {moment(row?.resign_withdraw_date).format('DD MMM,YY')}
                  </Text>
                </>
              );
            }
          },
        },
        // {
        //   header: 'Service Duration',
        //   render: ({row}) => {
        //     return (
        //       <ServiceDurationAvatar
        //         service_duration={row?.service_duration}
        //         color={row?.color}
        //         relieving_date={row?.relieving_date}
        //         joining_date={row?.joining_date}
        //       />
        //     );
        //   },
        //   width: 180,
        // },
        // {
        //   header: 'Exit Category',
        //   render: ({row}) => {
        //     return (
        //       <ExitCategoryAvatar
        //         exit_type={row?.exit_type}
        //         color={row?.color}
        //         desireable_status={row?.desireable_status}
        //       />
        //     );
        //   },
        //   width: 130,
        //   align: 'left',
        // },
      ]}
    />
  );
};

export const ResignationWithdrawnGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  //
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['EmployeeAssignment'],
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: `/employees/all-resign-withdraw`,
    fromExport: true,
  };
  return (
    <GroupTable
      {...props}
      params={params}
      sort={{resign_date_new: -1}}
      addOnFilter={addOnFilter}
      search={searchValue}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/all-resign-withdraw`}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
            </RowWithSeparator>
          );
        },
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Gender: 'employee.gender',
                      'Father name': 'employee.father_name',
                      Experience: 'employee.experience',
                      Designation: 'employee.designation.name',
                      'Employee Level': 'employee.employee_level.name',
                      'Mobile No': 'employee.mobile_number',
                      'Official Email Id': 'employee.official_email_id',
                      'Personal Email': 'employee.email_id',
                      'Joining date': 'joining_date',
                      'Withdraw date': 'withdraw_date',
                      'Resign Date': 'resign_date_new',
                      'Relieving date': 'relieving_date',
                      'Withdraw reason': 'withdraw_resign_reason',
                      'Expected relieving date': 'expected_relieving_date',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                    },
                    fileName: 'Resign Withdraw Data',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Withdrew on',
          type: 'date',
          field: 'withdraw_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },

        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                employee_skills={row?.employee?.employee_skills}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          // field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Skill',
          field: 'employee.resource_language.name',
          type: 'colorTag',
          colorField: 'employee.resource_language.color',
          minWidth: 140,
        },
        {
          header: 'Team',
          field: 'employee.employee_team.name',
          type: 'colorTag',
          width: 200,
        },
        {
          header: 'Reporting',
          type: 'userAvatarGroup',
          field: 'reporting_to',
          align: 'center',
          width: 66,
        },
        {
          header: 'Resigned on',
          type: 'date',
          field: 'resign_date_new',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 100,
        },
        {
          header: 'Reason',
          type: 'text',
          // width: 360,
          render: ({row}) => {
            return <Chip value={row?.withdraw_resign_reason} />;
          },
        },
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Start no dues',
      //     confirm: {
      //       title: 'Start no dues',
      //       message: 'Are you sure you want to start no dues of this employee?',
      //     },
      //     onPress: props => {
      //       const {row} = props;
      //       invoke({
      //         uri: '/resignation15DaysBefore',
      //         message: 'No dues started successfully',
      //         props: {
      //           ManualProcessStart: row,
      //         },
      //       });
      //     },
      //   },
      // ]}
    />
  );
};
