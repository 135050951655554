import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const SwappingFrom = props => {
  const {
    route: {params},
  } = props;
  const {row, formResign, replacement_id} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });

  let defaultValues = {
    is_shadow: true,
    bench_training_type: 'KT For Swapping',
    training_with_whom: {_id: row._id, name: row.name},
    is_from_bench_resource: true,
  };
  if (formResign) {
    const {_id, employee = {}, assignment = {}, ...rest} = row;
    const {_id: assignmentId, ...assignmentrest} = assignment;

    defaultValues = {
      ...defaultValues,
      ...assignmentrest,
      ...rest,
      training_with_whom: employee,
      replacement_id,
      allow_allocation_more_than_100: true,
    };
  }

  return (
    <Form
      defaultValues={{
        ...defaultValues,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Swapping"
      layoutFields={[
        {
          placeholder: 'Swapping',
          label: 'Swapping',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 6,
        },
        {
          placeholder: 'Project',
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/employeeassignments/projects`,
          suggestionField: 'project',
          valueField: 'project',
          secondarySuggestionField: 'order_number',
          required: true,
          filter: () => {
            return {
              employee: row?._id,
            };
          },
          onChangeValue: (value, _, {setFieldValue}) => {
            setFieldValue('order', value?.order);
            setFieldValue('skill_requirement', value?.skill_requirement);
            setFieldValue('milestone_id', value?.milestone_id);
            setFieldValue('order_assignment', value?.order_assignment);
            setFieldValue('resource_tool', value?.resource_tool);
            setFieldValue('tool', value?.tool);
            setFieldValue('project_skill', value?.project_skill);
            setFieldValue('resource_skill', value?.resource_skill);
            setFieldValue('to_date', value?.to_date);
            setFieldValue('from_date', getZeroTimeDate(new Date()));
          },
          size: 6,
        },
        {
          placeholder: 'Order',
          label: 'Order',
          field: 'order',
          type: 'autoComplete',
          api: `/orders`,
          suggestionField: 'order_number',
          valueField: 'order_number',
          required: true,
          readOnly: true,
          size: 6,
        },
        {
          placeholder: 'Hours',
          label: 'Hours',
          field: 'hours',
          type: 'number',
          size: 6,
        },

        {
          placeholder: 'Start Date',
          label: 'Start Date',
          field: 'from_date',
          type: 'date',
          size: 6,
        },
        {
          placeholder: 'End Date',
          label: 'End Date',
          field: 'to_date',
          type: 'date',
          size: 6,
        },
        {
          label: 'Project Skill',
          placeholder: 'Project Skill',
          field: 'project_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Project Tool',
          placeholder: 'Project Tool',
          field: 'tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Resource Skill',
          placeholder: 'Resource Skill',
          field: 'resource_skill',
          type: 'autoComplete',
          api: `/skillmetrics`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Resource Tool',
          placeholder: 'Resource Tool',
          field: 'resource_tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
