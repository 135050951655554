import React from 'react';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  PeopleManagerLeaveRegisterTable,
  PeopleManagerTeamAttendance,
  PeopleManagerTeamTable,
  RecusiveTeamAttendanceTable,
  TeamAttendanceTable,
  TeamTable,
  TeamUnapprovedAttendanceTable,
} from './TeamAttendanceTable';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {TeamLeaveRegisterTable} from './EmployeeAttendanceTable';

export const TeamAttendanceTab = props => {
  let {route: {params} = {}, user, tab} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: {filterParams, period: periods} = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  if (tab === 'project') {
    filter = {
      ...filter,
      reporting_to: user?.employee?._id,
    };
  }
  if (tab === 'primary') {
    filter = {
      ...filter,
      functional_manager: user?.employee?._id,
    };
  }
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    direct: {
      label: 'Monthly Attendance',
      view: (
        <TeamAttendanceTable
          tab={tab}
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    // all: {
    //   label: 'Indirect team attendance',
    //   view: (
    //     <RecusiveTeamAttendanceTable
    //       filterParams={filterParams}
    //       filter={filter}
    //       params={params}
    //       periods={periods}
    //       {...props}
    //     />
    //   ),
    //   eventSourceId: 'teamAttendance',
    // },
    peopleManager: {
      label: 'Leave Register',
      view: (
        <TeamLeaveRegisterTable
          tab={tab}
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
    />
  );
};

export const MenteeAttendanceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: {filterParams, period: periods} = {}} = filterValues;
  let {organization, product, period, ...rest} = params;

  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    peopleManager: {
      label: 'Monthly attendance',
      view: (
        <PeopleManagerTeamAttendance
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    leaveregister: {
      label: 'Leave Register',
      view: (
        <PeopleManagerLeaveRegisterTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
    />
  );
};

export const TeamTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  let {filter, params: {filterParams, period: periods} = {}} = filterValues;
  let {organization, product, period, ...rest} = params;

  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    direct: {
      label: 'Team',
      api: '/team-direct',
      view: (
        <TeamTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
    peopleManager: {
      label: 'People manager',
      api: '/team/people-manager',
      view: (
        <PeopleManagerTeamTable
          filterParams={filterParams}
          filter={filter}
          params={params}
          periods={periods}
          {...props}
        />
      ),
      eventSourceId: 'teamAttendance',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      // actions={[
      //   <PeriodRangeFilter
      //     onChangeFilter={onChangeFilter}
      //     filterValues={filterValues}
      //   />,
      // ]}
    />
  );
};
