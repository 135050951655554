import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const CurrencyForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/daywisecurrencies',
    eventSourceId: 'Currency',
  });
  return (
    <Form
      api={`/daywisecurrencies/${row?._id}`}
      fields={{
        currency: {currency: 1},
        exchange_rate: 1,
        from_date: 1,
        to_date: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Currency',
          type: 'autoComplete',
          field: 'currency',
          api: `/currencies`,
          suggestionField: 'currency',
          valueField: 'currency',
          keyField: 'currency',
          size: 6,
          required: true,
        },
        {
          label: 'Exchange Rate',
          field: 'exchange_rate',
          type: 'number',
          size: 6,
          required: true,
        },
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddCurrencyExchangeRateForm = props => {
  return <CurrencyForm header="Add Exchange Rate" {...props} />;
};

export const EditCurrencyExchangeRateForm = props => {
  return (
    <CurrencyForm mode="edit" header={'Update Exchange Rate'} {...props} />
  );
};
