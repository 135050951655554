import {IconButton} from '@unthinkable/react-button';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {TextRenderer} from '../../../components/form/Renderers';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const RenderDuration = props => {
  const {row} = props;
  const {from_date, to_date} = row;
  const {icons, colors} = useTheme();
  const styles = {
    marginLeft: 12,
    padding: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.INFORMATION_LOW,
  };
  if (!from_date || !to_date)
    return <IconButton icon={icons['Plus']} styles={styles} />;
  return (
    <GroupContentItem
      value={`${moment(from_date).format('DD MMM YY')} - ${moment(
        to_date,
      ).format('DD MMM YY')}`}
    />
  );
};

const RenderMentor = props => {
  const {row, navigation, setFieldValue, topic_wise_mentors, index} = props;
  const {mentor} = row;
  const {icons, colors} = useTheme();
  const styles = {
    marginLeft: 12,
    padding: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.INFORMATION_LOW,
  };
  if (!mentor) return <IconButton icon={icons['Plus']} styles={styles} />;
  return <UserAvatar value={mentor} />;
};

export const RenderFeedbackCycle = props => {
  const {row} = props;
  const {feedback_cycle} = row;
  const {icons, colors} = useTheme();
  const styles = {
    marginLeft: 12,
    padding: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.INFORMATION_LOW,
  };
  if (!feedback_cycle)
    return <IconButton icon={icons['Plus']} styles={styles} />;
  return <TextRenderer value={feedback_cycle} />;
};

export const AddTrainingBatchForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/trainingBatches',
    eventSourceId: 'TrainingBatches',
  });
  const {
    route: {params},
    navigation,
  } = props;
  const {product} = params;
  const {fetch} = useAppStateContext();
  return (
    <Form
      header="Add Course Batch"
      computations={{
        skill_metrics: {
          compute: value => {
            return {
              skill_metrics: value.training_program?.skill_metrics,
              tools: value.training_program?.tools,
              skill_leader: value.training_program?.skill_leader,
            };
          },
          multi: true,
          dependencies: ['training_program'],
        },
        topic_wise_mentors: {
          compute: async value => {
            if (value.mentorship_type != 'Topic-wise Trainers') return void 0;
            const {data: training_program = {}} = await fetch({
              uri: '/trainingPrograms',
              props: {
                filter: {
                  _id: value?.training_program?._id,
                },
                fields: {
                  training_roadmap: {
                    skill_topic: {
                      name: 1,
                    },
                  },
                },
                only: true,
              },
            });
            const {training_roadmap = []} = training_program;
            return training_roadmap.map(item => {
              return {
                skill_topic: item?.skill_topic,
              };
            });
          },
          dependencies: ['mentorship_type', 'training_program'],
        },
      }}
      onSubmit={onSubmit}
      defaultValues={{
        mentorship_type: 'Single Trainer',
        product,
      }}
      layoutFields={[
        {
          fields: [
            {
              field: 'training_program',
              type: 'autoComplete',
              api: '/trainingPrograms',
              label: 'Course',
              suggestionField: 'name',
              valueField: 'name',
              fields: {
                name: 1,
                skill_metrics: {
                  name: 1,
                },
                feedback_cycle: 1,
                skill_leader: 1,
                tools: {
                  name: 1,
                },
              },
              required: true,
              filter: {
                status: 'active',
              },
              size: 'small',
            },
            {
              field: 'name',
              type: 'text',
              label: 'Batch Name',
              required: true,
              size: 'small',
            },
            {
              field: 'batch_number',
              type: 'autoComplete',
              label: 'Batch Number',
              api: '/batchNumberSuggestions',
              params: ({values}) => ({
                training_program_id: values.training_program?._id,
              }),
              size: 'small',
              required: true,
            },
            {
              field: 'batch_type',
              type: 'autoComplete',
              label: 'Type',
              options: ['Fresher', 'Lateral'],
              size: 'small',
              required: true,
            },
            {
              field: 'reason',
              type: 'autoComplete',
              label: 'Reason',
              required: true,
              options: [
                'Upskill',
                'Cross Skill',
                'Training Need Identification',
                'Others',
              ],
              size: 'small',
            },
            {
              field: 'purpose',
              type: 'autoComplete',
              label: 'Purpose',
              required: true,
              api: '/trainingPurposes',
              suggestionField: 'name',
              valueField: 'name',
              onCreate: ({searchValue, onChange}) => {
                navigation.navigate('add-training-purpose', {
                  searchValue,
                  afterSubmit: ({resp}) => {
                    onChange && onChange(resp);
                  },
                });
              },
              // options: [
              //   'Skill enhancement',
              //   'Technology Transition',
              //   'Project Requirement ',
              //   'RFR specific training',
              //   'Bench utilization',
              //   'Bench creation',
              //   'Self Initiative',
              //   'Business Requirement ',
              // ],
              size: 'small',
            },
            {
              field: 'is_on_job',
              type: 'switch',
              label: 'On Job',
            },
            {
              field: 'is_no_project_allocation',
              type: 'switch',
              label: 'Do Not Consider in Bench Training',
            },
          ],
        },
        {
          fields: [
            {
              field: 'is_attendance_required',
              label: 'Enable Attendance',
              type: 'switch',
            },
          ],
        },
        {
          fields: [
            {
              field: 'mentorship_type',
              type: 'radioGroup',
              options: [
                'Single Trainer',
                'Session-wise Trainers',
                'Topic-wise Trainers',
                'Resource-wise Trainers',
              ],
            },
          ],
        },
        {
          field: 'training_sessions',
          nested: true,
          visible: ({values}) =>
            values.mentorship_type == 'Session-wise Trainers',
          render: props => {
            return (
              <NestedTable
                inlineAddForm={true}
                {...props}
                fields={[
                  {
                    label: 'Session',
                    field: 'name',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Date',
                    type: 'date',
                    field: 'date',
                    required: true,
                  },
                  {
                    label: 'Duration(hrs)',
                    type: 'number',
                    field: 'duration',
                    required: true,
                  },
                ]}
                columns={[
                  {
                    header: 'Session',
                    field: 'name',
                    type: 'text',
                  },
                  {
                    header: 'Date',
                    type: 'date',
                    field: 'date',
                  },
                  {
                    header: 'Duration(hrs)',
                    type: 'number',
                    field: 'duration',
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'mentor',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          label: 'Trainer',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          visible: ({values}) =>
            values.mentorship_type == 'Single Trainer' ||
            values.mentorship_type == 'Session-wise Trainers',
          filter: {
            is_trainer_inactive: {$in: [null, false]},
          },
        },
        {
          field: 'mentors',
          type: 'multiAutoComplete',
          api: '/employeeSuggestions',
          label: 'Add Multiple Trainers',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          visible: ({values}) =>
            values.mentorship_type == 'Resource-wise Trainers',
          filter: {
            is_trainer_inactive: {$in: [null, false]},
          },
        },
        {
          field: 'from_date',
          type: 'date',
          label: 'Start Date',
          required: true,
          size: 'small',
          visible: ({values}) =>
            values.mentorship_type != 'Session-wise Trainers' &&
            values.mentorship_type != 'Topic-wise Trainers',
        },
        {
          field: 'to_date',
          type: 'date',
          label: 'End Date',
          required: true,
          size: 'small',
          visible: ({values}) =>
            values.mentorship_type != 'Session-wise Trainers' &&
            values.mentorship_type != 'Topic-wise Trainers',
        },
        {
          field: 'feedback_cycle',
          type: 'number',
          label: 'Feedback Cycle (In days)',
          size: 'small',
          required: true,
          visible: ({values}) =>
            values.mentorship_type != 'Topic-wise Trainers' &&
            values.mentorship_type != 'Session-wise Trainers',
        },
        {
          field: 'topic_wise_mentors',
          nested: true,
          visible: ({values}) =>
            values.mentorship_type == 'Topic-wise Trainers',
          render: props => {
            const {
              form: {setFieldValue, values},
            } = props;
            return (
              <NestedTable
                {...props}
                readOnly
                key={JSON.stringify(values?.topic_wise_mentors)}
                columns={[
                  {
                    header: 'Topic',
                    field: 'skill_topic.name',
                    type: 'text',
                  },
                  {
                    header: 'Duration',
                    render: RenderDuration,
                    align: 'center',
                    setFieldValue,
                    navigation,
                    onPress: ({row, index}) => {
                      navigation.navigate('add-topic-duration', {
                        skill_topic: row.skill_topic,
                        index,
                        setFieldValue,
                        topic_wise_mentors: values?.topic_wise_mentors,
                      });
                    },
                  },
                  {
                    header: 'Feedback Cycle',
                    render: RenderFeedbackCycle,
                    onPress: ({row, index}) => {
                      navigation.navigate('add-topic-wise-feedback-cycle', {
                        skill_topic: row.skill_topic,
                        index,
                        setFieldValue,
                        topic_wise_mentors: values?.topic_wise_mentors,
                      });
                    },
                    align: 'center',
                  },
                  {
                    header: 'Trainer',
                    render: RenderMentor,
                    navigation,
                    align: 'center',
                    onPress: ({row, index}) => {
                      navigation.navigate('add-topic-wise-trainer', {
                        skill_topic: row.skill_topic,
                        index,
                        setFieldValue,
                        topic_wise_mentors: values?.topic_wise_mentors,
                      });
                    },
                  },
                ]}
              />
            );
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditTrainingBatch = props => {
  const {
    route: {params},
  } = props;
  const {training_batch} = params;
  return (
    <AddTrainingBatchForm
      header={{title: 'Edit Course Batch', secondaryTitle: training_batch.name}}
      api={'/trainingBatches/' + training_batch?._id}
      fields={{
        name: 1,
        skill_metrics: {
          name: 1,
        },
        training_program: {
          name: 1,
          skill_metrics: {
            name: 1,
          },
        },
        mentorship_type: 1,
        mentor: {
          name: 1,
          color: 1,
        },
        mentors: {
          name: 1,
          color: 1,
        },
        from_date: 1,
        to_date: 1,
        feedback_cycle: 1,
        batch_number: 1,
        topic_wise_mentors: {
          mentor: {
            name: 1,
            color: 1,
          },
          skill_topic: {
            name: 1,
          },
          from_date: 1,
          to_date: 1,
        },
        batch_type: 1,
        reason: 1,
        purpose: {
          name: 1,
        },
        training_sessions: {
          name: 1,
          date: 1,
          duration: 1,
        },
        is_attendance_required: 1,
        is_on_job: 1,
        is_no_project_allocation: 1,
      }}
      mode="edit"
      {...props}
    />
  );
};

export const AddTopicDuration = props => {
  const {
    route: {params},
  } = props;
  const {skill_topic, setFieldValue, topic_wise_mentors = [], index} = params;
  return (
    <Form
      onSubmit={values => {
        const newTopicWiseMentors = [...topic_wise_mentors];
        newTopicWiseMentors[index]['from_date'] = values.from_date;
        newTopicWiseMentors[index]['to_date'] = values.to_date;
        setFieldValue('topic_wise_mentors', newTopicWiseMentors);
      }}
      header={{
        title: 'Add Duration',
        secondaryTitle: skill_topic.name,
      }}
      defaultValues={{
        ...topic_wise_mentors[index],
      }}
      layoutFields={[
        {
          field: 'from_date',
          type: 'date',
          label: 'Start Date',
          required: true,
        },
        {
          field: 'to_date',
          type: 'date',
          label: 'End Date',
          required: true,
        },
      ]}
    />
  );
};

export const AddTopicWiseTrainer = props => {
  const {
    route: {params},
  } = props;
  const {skill_topic, setFieldValue, topic_wise_mentors = [], index} = params;
  return (
    <Form
      onSubmit={values => {
        const newTopicWiseMentors = [...topic_wise_mentors];
        newTopicWiseMentors[index]['mentor'] = values.mentor;
        setFieldValue('topic_wise_mentors', newTopicWiseMentors);
      }}
      header={{
        title: 'Add Trainer',
        secondaryTitle: skill_topic.name,
      }}
      defaultValues={{
        ...topic_wise_mentors[index],
      }}
      layoutFields={[
        {
          field: 'mentor',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          placeholder: 'Select Trainer',
          filter: {
            is_trainer_inactive: {$in: [null, false]},
          },
        },
      ]}
    />
  );
};

export const AddTopicWiseFeedbackCycle = props => {
  const {
    route: {params},
  } = props;
  const {skill_topic, setFieldValue, topic_wise_mentors = [], index} = params;
  return (
    <Form
      onSubmit={values => {
        const newTopicWiseMentors = [...topic_wise_mentors];
        newTopicWiseMentors[index]['feedback_cycle'] = values.feedback_cycle;
        setFieldValue('topic_wise_mentors', newTopicWiseMentors);
      }}
      header={{
        title: 'Add Feedback Cycle',
        secondaryTitle: skill_topic.name,
      }}
      defaultValues={{
        ...topic_wise_mentors[index],
      }}
      layoutFields={[
        {
          field: 'feedback_cycle',
          type: 'number',
          label: 'Feedback Cycle',
        },
      ]}
    />
  );
};
