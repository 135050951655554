import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFetchData} from '../../../controllers/useFetchData';
import {Text, View} from '@unthinkable/react-core-components';
import {Chip} from '@unthinkable/react-chip';

const FormComponent = ({restProps, employeeResult, action}) => {
  let {onSubmit} = useFormSubmit({
    uri: '/employee/resigndetails',
    eventSourceId: 'ExitRequest',
  });
  return (
    <Form
      onSubmit={onSubmit}
      skipDefaultSave={employeeResult.applied_for_resignation ? true : false}
      submitAction={'Request Resignation'}
      eventSourceId={['ExitRequest']}
      defaultValues={{
        employee: employeeResult,
      }}
      header={{
        title: 'Resignation details',
        actions: action,
      }}
      layoutFields={[
        {
          fields: [
            {
              label: 'Resign Date',
              placeholder: 'Resign Date',
              field: 'employee.resign_date_new',
              type: 'date',
              size: 4,
              visible: ({values}) =>
                values.employee && values.employee.applied_for_resignation,
              readOnly: true,
            },
            {
              label: 'Resign Date',
              field: 'employee.volatile_resign_date_new',
              type: 'date',
              size: 2,
              placeholder: 'Resign Date',
              visible: ({values}) =>
                values.employee && !values.employee.applied_for_resignation,
              readOnly: true,
            },
            {
              label: 'Expected Releiving Date',
              placeholder: 'Expected Resign Date',
              field: 'employee.expected_relieving_date',
              type: 'date',
              size: 4,
              visible: ({values}) =>
                values.employee && values.employee.applied_for_resignation,
              readOnly: true,
            },
            {
              label: 'Resign Status',
              field: 'employee.resign_status',
              type: 'text',
              size: 4,
              visible: ({values}) => employeeResult.applied_for_resignation,
              render: ({value}) => {
                return (
                  <View>
                    <Text
                      style={{fontSize: 13, color: '#737373', marginBottom: 4}}>
                      Resignation Status
                    </Text>
                    <Chip color="#E0EFFF">
                      <Text style={{fontSize: 14, color: '#1A1A1A'}}>
                        {value}
                      </Text>
                    </Chip>
                  </View>
                );
              },
              readOnly: true,
            },
            {
              label: 'Reason',
              field: 'employee.resignation_description',
              type: 'textArea',
              size: 10,
              required: true,
              readOnly: employeeResult.applied_for_resignation ? true : false,
            },
            {
              label: 'Accepted On',
              field: 'employee.resign_accepted_on',
              type: 'text',
              size: 6,
              visible: ({values}) =>
                values.employee &&
                values.employee.applied_for_resignation &&
                values.employee.resign_accepted_on &&
                values.employee.resign_status &&
                values.employee.resign_status === 'Accepted',
              readOnly: true,
            },
            {
              label: 'Rejected On',
              field: 'employee.resign_rejected_on',
              type: 'text',
              size: 6,
              visible: ({values}) =>
                values.employee &&
                values.employee.applied_for_resignation &&
                values.employee.resign_rejected_on &&
                values.employee.resign_status &&
                values.employee.resign_status === 'Rejected',
              readOnly: true,
            },
            {
              label: 'Withdraw Reason',
              field: 'employee.resign_withdraw_reason',
              type: 'text',
              size: 6,
              visible: ({values}) =>
                values.employee &&
                values.employee.applied_for_resignation &&
                values.employee.resign_status &&
                values.employee.resign_status === 'Withdraw' &&
                values.employee.withdraw_resign_reason,
            },
          ],
        },
      ]}
      {...restProps}
    />
  );
};

export const ExitManagementForm = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {data: employeeResult} = useFetchData({
    api: `/employee/exitManagement/self`,
    eventSourceId: 'ExitRequest',
  });
  if (!employeeResult) return null;
  let action = [];
  if (employeeResult.applied_for_resignation) {
    action = [
      <Text
        style={{color: '#007AFF'}}
        onClick={() => {
          navigation.navigate(`withdraw-exit-management-request`, {...params});
        }}
        cursor="pointer">
        Withdraw
      </Text>,
    ];
  }
  return (
    <View style={{paddingTop: 10}}>
      <FormComponent
        key={JSON.stringify(employeeResult)}
        restProps={props}
        employeeResult={employeeResult}
        action={action}
      />
    </View>
  );
};

export const WithdrawExitManagementRequestForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: `/employee/exitManagement/withdraw`,
    eventSourceId: 'ExitRequest',
    method: 'post',
  });
  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Withdraw Request"
      defaultValues={{
        resign_status: 'Withdraw',
      }}
      layoutFields={[
        {
          label: 'Withdraw Reason',
          placeholder: 'Withdraw Reason',
          type: 'textArea',
          field: 'withdraw_resign_reason',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
