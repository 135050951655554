// Make a screen to show the campaign contact's connection list - Rajnish Trikaldarshi 12-01-2024
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';

const RenderLinkedInUrl = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {name, linkedin_url = ''} = row;
  const underline = linkedin_url ? {textDecoration: 'underline'} : void 0;
  linkedin_url =
    linkedin_url &&
    (linkedin_url.startsWith('https') || linkedin_url.startsWith('http'))
      ? linkedin_url
      : `https://${linkedin_url}`;

  const displayName = name ? name : first_name + last_name;

  return (
    <TouchableOpacity
      onPress={() => {
        linkedin_url && Linking.openURL(linkedin_url, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {displayName}
      </Text>
    </TouchableOpacity>
  );
};
export const CampaignContactConnectionList = props => {
  const {navigation, route: {params} = {}} = props;

  let {campaign_contact_id, campaign_id} = params || {};
  const {filterValues} = useFilter({});
  const {filter = {}} = filterValues || {};

  return (
    <Table
      addOnFilter={filter}
      api={`/campaigncontactconnections`}
      fields={{
        name: 1,
        linkedin_url: 1,
        linkedin_followers: 1,
        degree_of_connection: 1,
      }}
      filter={{
        campaign_contact_id,
        campaign_id,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
          width: 250,
        },
        {
          header: 'Contact',
          field: 'linkedin_url',
          render: RenderLinkedInUrl,
          width: 200,
        },
        {
          type: 'text',
          header: 'Connection',
          field: 'linkedin_followers',
        },
        {
          type: 'text',
          header: 'DOC',
          field: 'degree_of_connection',
        },
      ]}
    />
  );
};
