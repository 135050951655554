import {InlineForm} from '../../../../components/form/Form';
import {TableHeader} from '../../../../components/table/Headers';
import {Table} from '../../../../components/table/Table';
import {useFetchData} from '../../../../controllers/useFetchData';
import {useInvoke} from '../../../../controllers/useInvoke';
import {PROJECT_VIEW_TYPE_ID} from '../../../common/constants/SourceConstants';

export const ScreenMappedAPIScreen = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row, readOnly, project, ...rest} = params;

  let {data} = useFetchData({
    api: `/projects/${project?._id}/asset/${PROJECT_VIEW_TYPE_ID}/all-data`,
    eventSourceId: ['MapScreenAPI'],
    filter: {
      _id: row?.source_id,
    },
    fields: {
      model_view_ids: {name: 1, path: 1, query: 1},
    },
  });

  data = data?.[0] || {};

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'MapScreenAPI',
  });

  const onSubmit = props => {
    putInvoke({
      uri: '/projectassetversions/mapcontroller',
      props,
    });
  };

  let controllerIds = data?.controller_ids?.map(m => m?._id);

  return (
    <>
      <TableHeader title={'APIs'} />
      {row?.model_id && !readOnly ? (
        <InlineForm
          defaultValues={{
            source: row?.source,
            source_id: row?.source_id,
            view_id: row?._id,
          }}
          onSubmit={onSubmit}
          layoutFields={[
            {
              type: 'autoComplete',
              api: '/projectcontrollers',
              label: 'API',
              field: 'controller_id',
              suggestionField: 'controller',
              required: true,
              filter: {
                project_id: project?._id,
                _id: {$nin: controllerIds},
              },
              onCreate: ({searchValue, onChange}) => {
                navigation.navigate(`add-controller`, {
                  ...rest,
                  model: row?.model_id,
                  searchValue,
                  afterSubmit: ({data}) => {
                    onChange?.(data);
                  },
                });
              },
            },
          ]}
        />
      ) : (
        void 0
      )}
      <Table
        data={
          data?.controller_ids?.length
            ? data?.controller_ids
            : row?.controller_ids
        }
        moreActions={[
          {
            title: 'Remove',
            variant: 'error',
            confirm: {
              title: 'Remove Model View',
              message: 'Are you sure you want to remove this api from Screen?',
              confirmText: 'Delete',
            },
            onPress: async ({row: {_id}}) => {
              await putInvoke({
                uri: '/projectassetversions/unmapcontroller',
                props: {
                  controller_id: _id,
                  view_id: row?._id,
                },
              });
            },
            visible: !readOnly,
          },
        ]}
        columns={[
          {
            header: 'Name',
            field: 'controller',
          },
          {
            header: 'Path',
            field: 'uri',
          },
          {
            header: 'Method',
            field: 'method',
          },
        ]}
      />
    </>
  );
};
