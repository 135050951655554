import React from 'react';
import {useFetchSuggestions} from '@unthinkable/react-autocomplete';
import BaseMentionsInput from './BaseMentionsInput';
import {Platform, Text} from '@unthinkable/react-core-components';
import {SuggestionRow} from './SuggestionRow';
import {parseMessage} from './Utility';

const useMentionSuggestions = props => {
  const {suggestionField = 'name', rowKey = '_id', mentions} = props;

  const {fetchSuggestions} = useFetchSuggestions({...props, options: mentions});

  const fetchMentionSuggestions = async (searchValue, callback) => {
    try {
      const data = await fetchSuggestions({searchValue});
      const formattedData = data?.map(item => ({
        ...item,
        id: item[rowKey],
        display: item[suggestionField],
        name: item[suggestionField],
      }));
      callback?.(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  return {fetchSuggestions: fetchMentionSuggestions};
};

export const MentionsInput = ({
  onChangeValue,
  maxHeight = 150,
  minHeight,
  renderSuggestion: renderSuggestionProp = SuggestionRow,
  ...props
}) => {
  const {styles, secondarySuggestionField} = props;
  const {fetchSuggestions} = useMentionSuggestions(props);
  const onChange = (newValue, e, plainText, mentions) => {
    onChangeValue?.(newValue, e, {plainText, mentions});
  };

  const renderSuggestion = props => {
    return renderSuggestionProp({...props, styles, secondarySuggestionField});
  };

  return (
    <BaseMentionsInput
      maxHeight={maxHeight}
      minHeight={minHeight}
      fetchSuggestions={fetchSuggestions}
      renderSuggestion={renderSuggestion}
      placeholder="Type here..."
      {...props}
      onChange={onChange}
    />
  );
};

export const MessageRender = props => {
  const {message, styles} = props;
  if (!message) {
    return null;
  }
  const {parts} = parseMessage(message);

  return (
    <Text
      style={{
        whiteSpace: 'pre-wrap',
        fontSize: 14,
        lineHeight: '18px',
        color: '#19191A',
        ...styles?.message,
      }}>
      {parts.map((part, index) => {
        if (part.partType === 'text') {
          return part.text;
        } else if (part.partType === 'mention') {
          return (
            <Text
              {...(Platform.OS === 'web' ? {as: 'span'} : {})}
              key={index}
              style={{
                ...styles?.mentionsText,
              }}>
              @{part.text}
            </Text>
          );
        }
      })}
    </Text>
  );
};
