import {
    Row,
    Text,
    TouchableOpacity,
    View,
} from '@unthinkable/react-core-components';
import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import { CompositeFieldsRender } from '@unthinkable/react-table';
import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { PrimaryColumnCard } from '../../../components/card/PrimaryColumnCard';
import {
    GroupFilter,
    PeriodRangeFilter,
} from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

const TargetCurrentMonthRender = props => {
  const {navigation, params} = props;
  const {period = {}} = params;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: CountCellRender,
          responsive: 'sm',
          align: 'right',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`add-my-order-target`, {
              targetOrder: {...row, project: params?.row?.project},
            });
          },
          visible: ({row}) => {
            if (!row?.target_hours) {
              return true;
            }
          },
        },
        {
          render: props => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  {props?.row?.target_hours}
                </Text>
              </View>
            );
          },
          visible: ({row}) => {
            if (row?.target_hours) {
              return true;
            }
          },
          onPress: () => {
            const {row} = props;
            navigation.navigate(`order-target-list`, {
              project: params?.row?.project,
              filter: {
                order: row?.order?._id,
                from_date: {$gte: period.from},
                to_date: {$lte: period.to},
              },
            });
          },
        },
      ]}
    />
  );
};

export const MyOrdersTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {projectionfilter = {}} = params || {};
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter = {}, params: filterParams = {}} = filterValues || {};
  params = {...params, ...filterParams};

  return (
    <Table
      params={params}
      eventSourceId={['OrderTarget']}
      api={`/pmOrders`}
      limit={1000}
      addOnFilter={{...filter, ...projectionfilter}}
      renderHeader={() => {
        return (
          <TableHeader
            title="Active Fixed Orders"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                skipQuarter
                asParam
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Customer',
                    api: '/customers',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'customer',
                  },
                  {
                    type: 'autoComplete',
                    label: 'Delivery Owner',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    field: 'delivery_owner_id',
                    searchFields: [
                      'name',
                      'official_email_id',
                      'employee_code',
                    ],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Customer',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.customer?.name}
                items={[
                  {
                    value: row?.order?.order_number,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Delivery Owner',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.delivery_owner_id?.name}
                color={row?.delivery_owner_id?.color}
                official_email_id={row?.delivery_owner_id?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Finished/Total',
          type: 'number',
          width: 160,
          visible: () => !params?.fromFixedOutput,
          render: props => {
            const {
              row,
              styles: {rowText = {}},
            } = props;
            return (
              <Row>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('customer-finished-hours-list', {
                      filter: {
                        project_resource_working_data:
                          row?.project_resource_working_data,
                        status: {$in: ['Delivered', 'Fully Delivered']},
                      },
                    });
                  }}>
                  <Text style={{...rowText}}>{row?.billed_hours}</Text>
                </TouchableOpacity>
                <Text style={{...rowText}}>/</Text>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('customer-delivery-list', {
                      filter: {
                        _id: {$in: row?.project_resource_working_data},
                      },
                    });
                  }}>
                  <Text style={{...rowText}}>{row?.total_hours}</Text>
                </TouchableOpacity>
              </Row>
            );
          },
        },
        {
          field: 'remaining_hours',
          header: 'Remaining work',
          type: 'number',
          width: 150,
          color: '#FF9500',
        },
        {
          type: 'number',
          header: 'Targeted for current month',
          render: props => {
            return (
              <TargetCurrentMonthRender
                {...props}
                navigation={navigation}
                params={params}
              />
            );
          },
          width: 200,
        },
      ]}
    />
  );
};
