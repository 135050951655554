import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {
  ExperinceRegrettableAttritionTable,
  PracticeRegrettableAttritionTable,
  ReasonRegrettableAttritionTable,
  TotalAttritionTable,
} from './AttritionTable';

export const AttritionTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: params.periods || getCurrentMonth(),
    },
  });
  const {params: {period: periods} = {}} = filterValues;
  const tabs = {
    Total: {
      label: 'Overview',
      view: <TotalAttritionTable {...props} periods={periods} />,
      eventSourceId: 'TotalAttrition',
    },
    practice: {
      label: 'Primary skill-wise',
      view: <PracticeRegrettableAttritionTable {...props} periods={periods} />,
      eventSourceId: 'JobOpeningClosed',
    },
    Reason: {
      label: 'Reason-wise',
      view: <ReasonRegrettableAttritionTable {...props} periods={periods} />,
      eventSourceId: 'JobOpeningActive',
    },
    Experience: {
      label: 'Experience-wise',
      view: <ExperinceRegrettableAttritionTable {...props} periods={periods} />,
      eventSourceId: 'JobOpeningHold',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
      ]}
      {...props}
    />
  );
};
