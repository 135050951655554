import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';
import {plural} from 'pluralize';

export const EventTaskAssignmentForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/mastertaskassignments',
    eventSourceId: ['MasterTaskAssignment'],
    ...props,
  });
  const {
    route: {params},
  } = props;

  const {parent_model = {}, row = {}} = params;

  const isActive = row.status === 'Active';
  const readOnly = row.status === 'Completed';

  return (
    <Form
      defaultValues={{
        parent_model_relation: 'Event',
        parent_model: parent_model?._id,
        status: 'Active',
        primary_source: {
          _id: '651d19c6e7b7a33ee66b4336',
          label: 'Product',
        },
        primary_source_id: {
          _id: '53a42e38a22d3a020035f672',
          name: 'Software Services',
        },

        ...row,
      }}
      beforeSubmit={({data}) => {
        if (
          data?.assignee_mode === 'IndividualWithEstimate' &&
          !data?.owner_wise_estimates?.length
        ) {
          throw new Error('Please Select Assignee');
        }
        return {
          ...data,
        };
      }}
      readOnly={readOnly}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Task',
          type: 'text',
          field: 'task',
          required: true,
        },
        {
          label: 'Event',
          fields: [
            {
              label: 'Event On',
              type: 'radioGroup',
              field: 'event_on',
              required: true,
              options: ['Insert', 'Update'],
            },
            {
              label: 'Field',
              type: 'autoComplete',
              api: () => {
                let model = parent_model?.name;
                if (model.indexOf(' ') != -1) {
                  model = model.split(' ').join('');
                }
                return `/${plural(model)}/metadata/fields`;
              },
              field: 'event_field',
              size: 6,
              key: 'event_field_insert',
              visible: ({values}) => values?.event_on === 'Insert',
            },
            {
              label: 'Field',
              type: 'autoComplete',
              api: () => {
                let model = parent_model?.name;
                if (model.indexOf(' ') != -1) {
                  model = model.split(' ').join('');
                }
                return `/${plural(model)}/metadata/fields`;
              },
              field: 'event_field',
              size: 6,
              key: 'event_field_update',
              visible: ({values}) => values?.event_on === 'Update',
              required: true,
            },
            {
              label: 'Value',
              type: 'text',
              field: 'event_field_value',
              size: 6,
              visible: ({values}) => !!values?.event_field,
            },
          ],
        },
        {
          label: 'Output',
          fields: [
            {
              label: 'Model',
              field: 'output_model',
              type: 'cascader',
              changeOnSelect: true,
              cascades: [
                {
                  label: 'Output Model',
                  api: '/types',
                  type: 'autoComplete',
                  field: 'output_model',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: '_id',
                  fields: {
                    label: 1,
                    name: 1,
                    add_view: 1,
                  },
                },
                {
                  label: 'Output Model Id',
                  field: 'output_model_id',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  params: ({values}) => {
                    const {output_model} = values || {};
                    return {
                      source: output_model,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
              size: 6,
              required: true,
            },
            {
              type: 'number',
              field: 'due_days',
              label: 'Due After (in days)',
              size: 4,
            },
            {
              field: 'output_model_view',
              label: 'View',
              type: 'autoComplete',
              api: '/dataentityviews',
              suggestionField: 'label',
              valueField: 'label',
              filter: ({values}) => {
                return {
                  data_entity_id: values?.output_model?._id,
                };
              },
              visible: ({values}) => values?.output_model?._id,
              size: 6,
            },
          ],
        },
        {
          label: 'Assignee Details',
          fields: [
            {
              field: 'assignee_mode',
              type: 'radioGroup',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Open Task', value: 'Common'},
                {
                  label: 'Assigned Task',
                  value: 'IndividualWithEstimate',
                },
              ],
              readOnly: readOnly || isActive,
              editorAsRenderer: true,
              required: true,
              onChangeValue: (value, _, {setFieldValue}) => {
                if (value === 'IndividualWithEstimate') {
                  setFieldValue('team', void 0);
                  setFieldValue('est_hours', void 0);
                } else {
                  setFieldValue('owner_wise_estimates', void 0);
                }
              },
            },
            {
              visible: ({values}) => values.assignee_mode === 'Common',
              fields: [
                {
                  label: 'Assignees',
                  field: 'team',
                  type: 'multiAutoComplete',
                  api: `/usersuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  colorField: 'color',
                  avatar: true,
                  valueField: 'name',
                  readOnly: readOnly || isActive,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  field: 'auto_assignee',
                  api: `/types/${parent_model?._id}/autoAssignSuggestions`,
                  label: 'Auto Assignee',
                  visible: ({values}) => values.assignee_mode === 'Common',
                  size: 4,
                },
                {
                  type: 'number',
                  field: 'est_hours',
                  label: 'Est. Hrs',
                  onChangeValue: (value, _, {setFieldValue}) => {
                    if (isActive) {
                      return;
                    }
                    if (value) {
                      setFieldValue('owner_wise_estimates', void 0);
                    } else {
                      setFieldValue('team', void 0);
                    }
                  },
                  size: 4,
                  editorAsRenderer: true,
                  required: true,
                },
              ],
            },
            {
              visible: ({values}) =>
                values.assignee_mode === 'IndividualWithEstimate',
              fields: [
                {
                  field: 'owner_wise_estimates',
                  required: true,
                  nested: true,
                  render: props => {
                    return (
                      <NestedTable
                        skipAdd={readOnly || isActive}
                        skipDelete={readOnly || isActive}
                        inlineAddForm={true}
                        modalProps={{size: 'small'}}
                        {...props}
                        inlineFields={[
                          {
                            label: 'Select Assignee',
                            field: 'owner',
                            type: 'autoComplete',
                            api: `/usersuggestions`,
                            suggestionField: 'name',
                            secondarySuggestionField: 'email',
                            colorField: 'color',
                            avatar: true,
                            valueField: 'name',
                            readOnly: readOnly || isActive,
                            required: true,
                            editorAsRenderer: true,
                            size: 6,
                          },
                          {
                            label: 'Est Hrs',
                            field: 'est_hours',
                            type: 'number',
                            required: true,
                            editorAsRenderer: true,
                            size: 3,
                          },
                        ]}
                        fields={[
                          {
                            label: 'Select Assignee',
                            field: 'owner',
                            type: 'autoComplete',
                            api: `/usersuggestions`,
                            suggestionField: 'name',
                            secondarySuggestionField: 'email',
                            colorField: 'color',
                            avatar: true,
                            valueField: 'name',
                            readOnly: readOnly || isActive,
                            required: true,
                            editorAsRenderer: true,
                          },
                          {
                            label: 'Est Hrs',
                            field: 'est_hours',
                            type: 'number',
                            required: true,
                            editorAsRenderer: true,
                          },
                        ]}
                        columns={[
                          {
                            type: 'text',
                            field: 'owner.name',
                            header: 'Assignee',
                          },
                          {
                            field: 'est_hours',
                            type: 'number',
                            header: 'Est Hrs',
                            width: 200,
                          },
                        ]}
                      />
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'Review',
          fields: [
            {
              type: 'checkbox',
              field: 'review_not_required',
              label: 'Not Reviewable',
              onChangeValue: (value, _, {setFieldValue, values}) => {
                if (value) {
                  setFieldValue('reviewer', values?.reviewer ? [] : void 0);
                }
              },
              size: 2,
            },
            {
              field: 'reviewer',
              key: 'reviewer_not_auto_assign',
              type: 'multiAutoComplete',
              api: `/usersuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              colorField: 'color',
              avatar: true,
              valueField: 'name',
              label: 'Reviewers',
              required: true,
              visible: ({values}) =>
                !values?.review_not_required && !values?.auto_assignee,
              size: 10,
            },
            {
              key: 'reviewer_auto_assign',
              field: 'reviewer',
              type: 'multiAutoComplete',
              api: `/usersuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              colorField: 'color',
              avatar: true,
              valueField: 'name',
              label: 'Reviewers',
              visible: ({values}) =>
                !values?.review_not_required && values?.auto_assignee,
              size: 10,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddEventTaskAssignmentForm = props => {
  return (
    <EventTaskAssignmentForm
      {...props}
      header={{
        title: 'Add Task Assignment',
      }}
    />
  );
};

export const EditEventTaskAssignmentForm = props => {
  return (
    <EventTaskAssignmentForm
      {...props}
      header={{
        title: 'Edit Task Assignment',
      }}
    />
  );
};
