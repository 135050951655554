import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  FavoritesTaskTable,
  OpenTasksTable,
  ReviewTasksTable,
} from './TasksTable';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {taskFilters} from './TaskActions';
import {OrganizationActiveTasksGroupByPlan} from './OrganizationActiveTasksGroupByPlan';
import {BacklogOrganizationTaskTable} from './BacklogOrganizationTaskTable';
import {ArchivedOrganizationTaskTable} from './ArchivedOrganizationTaskTable';

export const OrganizationTaskReviewTab = props => {
  const {searchValue, onChangeFilter, applyFilter, filterValues} = useFilter({
    params: {span: 'Weekly'},
  });

  const {params: filterParams = {}, filter} = filterValues;

  const commonFilter = {
    ...filter,
    project_id: {$exists: false},
  };

  const filters = {
    pending: {
      ...commonFilter,
      review_pending: true,
      status: 'completed',
    },
    reviewed: {
      ...commonFilter,
      review_pending: false,
      recent_reviewed_status: 'accepted',
      status: 'completed',
    },
    rejected: {
      ...commonFilter,
      review_pending: false,
      status: 'active',
      recent_reviewed_status: 'rejected',
    },
    archived: {
      ...commonFilter,
      status: 'completed',
      is_review_archived: true,
    },
  };

  const actions = [
    <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[taskFilters.assigned_to, taskFilters.custom]}
    />,
  ];

  const tabs = {
    pending: {
      label: 'Pending',
      api: `/reviewTasks`,
      view: (
        <ReviewTasksTable
          key={'Pending'}
          source={'Pending'}
          filter={filters.pending}
          filterParams={filterParams}
          searchValue={searchValue}
          fromMyView={true}
          {...props}
        />
      ),
      actions,
      filter: filters.pending,
      eventSourceId: ['TaskReview', 'Task'],
    },
    reviewed: {
      label: 'Reviewed',
      view: (
        <ReviewTasksTable
          key={'Reviewed'}
          source={'Reviewed'}
          filter={filters.reviewed}
          filterParams={filterParams}
          searchValue={searchValue}
          fromMyView={true}
          {...props}
        />
      ),
      api: `/reviewTasks`,
      actions,
      filter: filters.reviewed,
      eventSourceId: ['TaskReview', 'Task'],
    },
    rejected: {
      label: 'Rejected',
      actions,
      view: (
        <ReviewTasksTable
          key={'Rejected'}
          source={'Rejected'}
          filter={filters.rejected}
          filterParams={filterParams}
          searchValue={searchValue}
          fromMyView={true}
          {...props}
        />
      ),
      api: `/reviewTasks`,
      filter: filters.rejected,
      eventSourceId: ['TaskReview', 'Task'],
    },
    archived: {
      label: 'Archived',
      actions,
      view: (
        <ReviewTasksTable
          key={'Archived'}
          source={'Archived'}
          filter={filters.archived}
          searchValue={searchValue}
          filterParams={filterParams}
          {...props}
        />
      ),
      api: `/reviewTasks`,
      filter: filters.archived,
      eventSourceId: ['TaskReview', 'Task'],
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export const MyOrganizationTaskMenus = props => {
  const {route: {params} = {}} = props;

  const menus = [
    {
      label: 'Open',
      view: <OpenTasksTable {...props} />,
    },
    {
      label: 'Backlog',
      view: <BacklogOrganizationTaskTable {...props} />,
    },
    {
      label: 'Active',
      view: <OrganizationActiveTasksGroupByPlan {...props} />,
    },
    {
      label: 'Favorites',
      view: <FavoritesTaskTable {...props} />,
    },
    {
      label: 'Reviews',
      view: <OrganizationTaskReviewTab {...params} />,
    },
    {
      label: 'Archived',
      view: <ArchivedOrganizationTaskTable {...props} />,
    },
  ];

  return <LeftMenuView {...props} menus={menus} />;
};
