import {useCallback} from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';

export const useImportExcel = ({params = {}} = {}) => {
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['reloadImportHistoryLogs'],
  });
  return useCallback(
    ({row}) => {
      invoke({
        uri: '/importExcel',
        props: {...row, params},
      });
    },
    [invoke],
  );
};

export const useReImportExcel = ({params = {}} = {}) => {
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['reloadImportHistoryLogs'],
  });
  return useCallback(
    ({row}) => {
      invoke({
        uri: '/importExcel',
        props: {...row, params, reImport: true},
      });
    },
    [invoke],
  );
};

export const useTestExcel = ({params = {}} = {}) => {
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['reloadImportHistoryLogs'],
  });
  return useCallback(
    ({row}) => {
      invoke({
        uri: '/importExcel',
        props: {...row, params, test: true},
      });
    },
    [invoke],
  );
};

export const useDeleteImportExcel = () => {
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['reloadImportHistoryLogs'],
  });
  return useCallback(
    ({row}) => {
      invoke({
        uri: `/ImportHistoryLogs/delete/${row?._id}`,
        props: row,
      });
    },
    [invoke],
  );
};

export const useExportExcel = () => {
  const {post} = useAppStateContext();
  return useCallback(
    ({row}) => {
      post({
        uri: '/exportExcel',
        data: {
          data: row.errorLogs.dataLogs,
        },
      });
    },
    [post],
  );
};

export const useExportErrorLogs = () => {
  const {post} = useAppStateContext();
  return useCallback(
    ({row}) => {
      post({
        uri: '/export-error-logs',
        props: row,
      });
    },
    [post],
  );
};
