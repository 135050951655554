import React from 'react';
import {InlineForm} from '../../../../components/form/Form';
import {Table} from '../../../../components/table/Table';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_COMPONENT_TYPE_ID} from '../../../common/constants/SourceConstants';
import {SplitScreen} from '../../../../components/SplitScreen';
import {ComponentDetailForm} from './ComponentForm';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {ComponentTable, componentExportParams} from './ComponentScreen';
import {TabView} from '../../../../components/tab';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {SearchFilter} from '../../../../components/filter/Filters';

const EVENT_SOURCE_ID = [
  'Feature',
  'Task',
  'Component',
  'Design',
  'DataImport',
];

const ComponentInlineForm = props => {
  const {
    navigation,
    route: {params},
    sourceIds,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Component',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, projectlibrary_id} = params;

  const defaultValues = {
    source: PROJECT_COMPONENT_TYPE_ID,
    project_id: project?._id,
    feature_id: feature?._id,
    is_changed: false,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      eventSourceId={'Component'}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Component',
          placeholder: 'Component Name',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_COMPONENT_TYPE_ID}/suggestions`,
          suggestionField: 'component',
          valueField: 'component',
          searchFields: ['component'],
          required: true,
          filter: {
            _id: {$nin: sourceIds},
            library_id: projectlibrary_id,
            library_version_id: null,
          },
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-component`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

const component = {
  type: 'text',
  field: 'component',
  header: 'Component',
};

const FeaturesComponentTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
  } = props;

  const {feature} = params;

  const tableProps = {
    api: `/features/${feature?._id}/assets/${PROJECT_COMPONENT_TYPE_ID}/asset-data`,
    filter: {
      $or: [{component_type: 'component'}, {component_type: {$exists: false}}],
    },
  };

  const {
    assetDetail,
    tasks,
    removeFromFeature,
    addDesign,
    viewDesign,
    versions,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_COMPONENT_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
  });

  return (
    <Table
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_COMPONENT_TYPE_ID}
          assetInlineForm={
            feature?.library_id ? <ComponentInlineForm {...props} /> : void 0
          }
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={componentExportParams({
            ...tableProps,
            fromFeature: true,
          })}
          navigation={navigation}
          showImportData={true}
          route={props.route}
          {...headerProps}
        />
      )}
      eventSourceId={EVENT_SOURCE_ID}
      selection
      onRowPress={assetDetail}
      columns={[
        component,
        assetColumns.designs({
          onUpload: addDesign,
          onView: viewDesign,
        }),
        assetColumns.tasks({onPress: tasks}),
        assetColumns.est_hrs,
        assetColumns.assignees,
      ]}
      moreActions={[versions, removeFromFeature]}
      {...tableProps}
    />
  );
};

const FeatureComponentScreen = props => {
  return (
    <SplitScreen splitScreen={<ComponentDetailForm {...props} />}>
      <FeaturesComponentTable {...props} />
    </SplitScreen>
  );
};

export const AllComponentScreen = props => {
  const {
    route: {params},
    api,
  } = props;

  const {project, feature, projectlibrary_id, integratedProject} = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField = assetMappings[PROJECT_COMPONENT_TYPE_ID].sourceField;

  const tableProps = {
    api:
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_COMPONENT_TYPE_ID}/all-data`,
    addOnFilter: {
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: sourceField,
    filter: {
      library_id: projectlibrary_id,
      library_version_id: integratedProject
        ? integratedProject.library_version_id
        : null,
    },
    $or: [{component_type: 'component'}, {component_type: {$exists: false}}],
    params: {
      show_in_development: !feature?._id,
    },
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={componentExportParams({...tableProps, fromFeature: true})}
          />,
          feature?.library_id ? (
            <AssetAddButton
              title={'Component'}
              view={'add-component'}
              params={params}
            />
          ) : (
            void 0
          ),
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<ComponentDetailForm {...props} />}>
      <ComponentTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        fromFeature
      />
    </SplitScreen>
  );
};

export const FeatureComponentTab = props => {
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'In Development',
          view: <FeatureComponentScreen {...props} />,
        },
        all: {
          label: 'All',
          view: <AllComponentScreen {...props} />,
        },
      }}
    />
  );
};
