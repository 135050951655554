// @ Adit Verma 15/01/2024
//  Slow Query Screen

import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';

//  View for Slow Query
export const SlowQueryView = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter = {}, params: filterParams} = filterValues || {};
  params = {...params, ...filterParams};
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Slow Query"
          actions={[
            <PeriodRangeFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'DocsExamined',
                  placeholder: 'DocsExamined',
                  suggestionField: 'value',
                  valueField: 'value',
                  keyField: 'value',
                  field: 'docsExamined',
                  options: [
                    {label: '1000', value: 1000},
                    {label: '2000', value: 2000},
                    {label: '3000', value: 3000},
                    {label: '4000', value: 4000},
                    {label: '5000', value: 5000},
                    {label: '10000', value: 10000},
                    {label: '20000', value: 20000},
                    {label: '50000', value: 50000},
                    {label: '100000', value: 100000},
                  ],
                },
                {
                  type: 'autoComplete',
                  label: 'PlanSummary',
                  placeholder: 'PlanSummary',
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                  field: 'planSummary',
                  options: [
                    {label: 'COLLSCAN', value: 'COLLSCAN'},
                    {label: 'IXSCAN', value: 'IXSCAN'},
                  ],
                },
              ]}
            />,
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
          ]}
        />
      )}
      search={searchValue}
      api="/slowQueryLogs"
      fields={{
        command: {find: 1, filter: 1, aggregate: 1, pipeline: 1},
        millis: 1,
        docsExamined: 1,
        planSummary: 1,
        ts: 1,
      }}
      filter={filter}
      columns={[
        {
          header: 'Command-operation',
          field: 'operation',
          type: 'text',
        },
        {
          header: 'Command-Find/Aggregate',
          field: 'operationValue',
          type: 'text',
        },
        {
          header: 'Command-Filter/Pipeline',
          field: 'filterOrPipeline',
          type: 'text',
        },
        {
          header: 'Millis',
          field: 'millis',
          type: 'text',
        },
        {
          header: 'Count',
          field: 'count',
          type: 'text',
        },
        {
          header: 'PlanSummary',
          field: 'planSummary',
          type: 'text',
        },
        {
          header: 'DocsExamined',
          field: 'docsExamined',
          type: 'number',
        },
        {
          header: 'CreatedAt',
          field: 'ts',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY HH:mm',
          },
          width: 150,
        },
      ]}
      {...props}
      params={params}
    />
  );
};
