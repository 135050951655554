import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from '../../order/views/components/Durations';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  forwardReimbursementToCOO,
  rejectReimbursement,
  partiallyPendingReimbursement,
} from '../controllers/StatusController';
import {activityLogReimbursement} from '../controllers/ReimbursementActivityLogController';
import {UserAvatarWithDateChip} from '../components/ReimbursementColumnRenders';

const PracticeReimbursementColumns = [
  {
    render: props => (
      <UserAvatarWithDateChip
        field={'employee'}
        dateField={'createdAt'}
        requested
        {...props}
      />
    ),
    header: 'Employee',
  },
  {
    field: 'description',
    type: 'text',
    header: 'Description',
  },
  {
    header: 'Duration',
    align: 'center',
    render: ({row}) => {
      return Durations({
        toDate: row?.to_date,
        fromDate: row?.from_date,
      });
    },
  },
  // {
  //   field: 'reimbursement_type',
  //   type: 'text',
  //   header: 'Reimbursement Type',
  //   width: 200,
  // },

  {
    field: 'total_amount',
    type: 'number',
    header: 'Amount',
  },
];

export const ReimbursementPracticePendingTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementPracticePending',
      'ReimbursementPracticePartiallyPending',
      'ReimbursementPracticeReject',
      'ReimbursementPracticeApprovedByRm',
    ],
  });

  return (
    <Table
      eventSourceId={[
        'ReimbursementPracticePending',
        'ReimbursementPracticePartiallyPending',
        'ReimbursementPracticeReject',
        'ReimbursementPracticeApprovedByRm',
      ]}
      api={`/reimbursement/practice/Pending`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={PracticeReimbursementColumns}
      moreActions={() => [
        partiallyPendingReimbursement({invoke}),
        forwardReimbursementToCOO({invoke}),
        activityLogReimbursement({navigation, params}),
        rejectReimbursement({navigation}),
      ]}
    />
  );
};

export const ReimbursementPracticeApprovedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/reimbursement/practice/Partially Pending`}
      eventSourceId={[
        'ReimbursementPracticePending',
        'ReimbursementPracticePartiallyPending',
        'ReimbursementPracticeReject',
        'ReimbursementPracticeApprovedByRm',
      ]}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...PracticeReimbursementColumns,
        {
          render: props => (
            <UserAvatarWithDateChip
              field={'approved_by'}
              dateField={'approved_on_rm'}
              approve
              {...props}
            />
          ),
          header: 'Approved By',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementPracticeRejectTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/reimbursement/practice/Rejected`}
      eventSourceId={[
        'ReimbursementPracticePending',
        'ReimbursementPracticePartiallyPending',
        'ReimbursementPracticeReject',
        'ReimbursementPracticeApprovedByRm',
      ]}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...PracticeReimbursementColumns,
        {
          type: 'date',
          field: 'rejected_on',
          header: 'Rejected On',
        },
        {
          field: 'reject_reason',
          type: 'text',
          header: 'Reason',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementPracticeApprovedByRmTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      eventSourceId={[
        'ReimbursementPracticePending',
        'ReimbursementPracticePartiallyPending',
        'ReimbursementPracticeReject',
        'ReimbursementPracticeApprovedByRm',
      ]}
      api={`/reimbursement/practice/Approved`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...PracticeReimbursementColumns,
        {
          type: 'date',
          field: 'approved_on',
          header: 'Approved On',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};
