import React, {useEffect, useState} from 'react';
import {Home} from './Home';
import {WithModal} from '../components/modal/WithModal';
import {useAppStateContext} from '../providers/AppState';
import {ProductivitySurveyForm} from '../modules/employee/views/ProductivityServeyForm';
import {AddressVerification} from '../modules/employee/views/AddressVerificationForm';
import {AssetVerification} from '../modules/employee/views/AssetVerificationForm';
import {EmployeeDetailReview} from '../modules/employee/views/EmployeeDetailReviewForm';

export const HomeWithPopup = props => {
  const {user} = useAppStateContext();
  const [mounted, setMounted] = useState(false);

  //Add modal components
  const modalComponents = {
    productivitySurvey: {
      component: ProductivitySurveyForm,
      options: {
        size: 'large',
        height: 520,
      },
    },
    addressVerification: {
      component: AddressVerification,
      options: {
        size: 'medium',
      },
    },
    assetVerification: {
      component: AssetVerification,
      options: {
        size: 'large',
      },
    },
    detailReview: {
      component: EmployeeDetailReview,
      options: {
        size: 'large',
      },
    },
  };

  //Add conditions to render modal
  const getModalComponent = () => {
    if (
      user &&
      user.employee &&
      !user.externalUser &&
      !user.employee.last_productivity_survey &&
      user.employee.employee_status === 'Active'
    ) {
      return 'productivitySurvey';
    }
    if (user && user.employee && user.employee.address_review_pending) {
      return 'addressVerification';
    }
    if (user && user.employee && user.employee.asset_review_pending) {
      return 'assetVerification';
    }
    if (user && user.employee && user.employee.detail_review_pending) {
      return 'detailReview';
    }
  };

  useEffect(() => {
    const modalComponent = getModalComponent();
    if (modalComponent) {
      setMounted(true);
    }
  }, [user]);
  const modalComponent = modalComponents[getModalComponent()] || {};
  const {component: ModalView, options = {}} = modalComponent;

  return (
    <>
      <Home {...props} />
      <WithModal
        {...options}
        autoHide={false}
        variant={'form'}
        position="screenCenter"
        mounted={mounted}
        onChange={setMounted}
        renderModal={
          <ModalView
            {...props}
            onClose={() => {
              setMounted(false);
            }}
          />
        }
      />
    </>
  );
};
