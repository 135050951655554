import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';

import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {
  ActivePIPEmployeeList,
  CompletedPIPEmployeeList,
  PotentialPIPEmployeeList,
  SelfActivePIPEmployeeList,
  SelfCompletedPIPEmployeeList,
  SelfExtendedPIPEmployeeList,
} from './PipResourceLists';

export const PipResourceTabs = props => {
  let params = props?.route?.params;
  const {department} = params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    filter: {department: department},
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    organization,
  };
  let tabs = {};
  tabs.potential = {
    label: 'Potential',
    view: (
      <PotentialPIPEmployeeList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/potential-pip',
    addOnFilter: filter,
    eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.active = {
    label: 'Initiated',
    view: (
      <ActivePIPEmployeeList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/active-pip',
    addOnFilter: filter,
    eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'date',
            label: 'Initiated On',
            field: 'pip_marked_on',
          },
          {
            type: 'autoComplete',
            label: 'Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'pip_owner',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };

  // tabs.expended = {
  //   label: 'Extended PIP',
  //   view: (
  //     <ExtendedPIPEmployeeList
  //       {...props}
  //       search={searchValue}
  //       addOnFilter={filter}
  //       filterParams={filterParams}
  //       params={params}
  //     />
  //   ),
  //   api: '/extended-pip',
  //   eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
  // };

  tabs.completed = {
    label: 'Closed',
    view: (
      <CompletedPIPEmployeeList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/completed-pip',
    addOnFilter: filter,
    eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            placeholder: 'Select',
            options: [
              {label: 'Successful', value: 'Success'},
              {
                label: 'Unsuccessful',
                value: {$in: ['Terminate', 'Resignation']},
              },
            ],
            label: 'Status',
            field: 'pip_status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'date',
            label: 'Closed On',
            field: 'pip_end_on',
          },
        ]}
      />,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const SelfPipResourceTabs = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {
    ...filterParams,
    ...rest,
    // product,
    organization,
  };
  let tabs = {};

  tabs.active = {
    label: 'Active PIP',
    view: (
      <SelfActivePIPEmployeeList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    filter: {
      pip_mentor: employeeId,
    },
    api: '/active-pip/trainer',
    eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
  };

  tabs.expended = {
    label: 'Extended PIP',
    view: (
      <SelfExtendedPIPEmployeeList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/extended-pip/trainer',
    filter: {
      pip_mentor: employeeId,
    },
    eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
  };
  tabs.completed = {
    label: 'Completed PIP',
    view: (
      <SelfCompletedPIPEmployeeList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/completed-pip/trainer',
    filter: {
      pip_mentor: employeeId,
    },
    eventSourceId: ['EmployeeAssignment', 'EmployeeSkills'],
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
