import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useNavigation, useRoute} from '@react-navigation/native';
import {useStyles} from '@unthinkable/react-theme';
import {
  AddButton as AddButtonStyle,
  MarkQaDoneButtonTheme,
  FillButtonTheme,
  BorderLessButtonTheme,
  DownloadButtonTheme,
} from './theme';
import {Action} from '../action/Action';
import {Button} from './Button';
import {useAppStateContext} from '../../providers/AppState';

export const AddButton = ({title, view, params, onPress, ...props}) => {
  const navigation = useNavigation();
  return (
    <Button
      text={`+ ${title}`}
      {...props}
      onPress={e => {
        if (view) {
          navigation.navigate(view, {...params});
        } else if (onPress) {
          onPress(e);
        }
      }}
    />
  );
};

export const HeaderButton = ({title, view, params, onPress}) => {
  const {buttonStyle, titleStyle} = useStyles(AddButtonStyle);
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        if (onPress && typeof onPress === 'function') {
          onPress();
        }
        if (!view) {
          return null;
        }
        navigation.navigate(`${view}`, {...params});
      }}
      style={buttonStyle}>
      <Row gap={8}>
        <Text style={titleStyle}>{title}</Text>
      </Row>
    </TouchableOpacity>
  );
};

export const CompactAddButton = ({highlighted, view, extraParams = {}}) => {
  const {compactAddIcon, selectedAddIcon} = useStyles(AddButtonStyle);
  let route = useRoute();
  let params = route?.params;
  return (
    <Action view={view} params={{...params, ...extraParams}}>
      <Image source={highlighted ? selectedAddIcon : compactAddIcon} />
    </Action>
  );
};

export const MarkQaDoneButton = ({onPress, isMarked}) => {
  const {activeIcon, inactiveIcon, activeStyles, inactiveStyles} = useStyles(
    MarkQaDoneButtonTheme,
  );

  return (
    <Button
      onPress={!isMarked && onPress}
      text={isMarked ? 'Done' : 'Mark Done'}
      icon={isMarked ? activeIcon : inactiveIcon}
      styles={isMarked ? activeStyles : inactiveStyles}
    />
  );
};

export const FillButton = ({activeStyles: userActiveStyles, ...props}) => {
  const {disabled} = props;
  const {activeStyles, disabledStyles} = useStyles(FillButtonTheme);

  return (
    <Button
      styles={disabled ? disabledStyles : userActiveStyles || activeStyles}
      {...props}
    />
  );
};

export const BorderLessButton = props => {
  const style = useStyles(BorderLessButtonTheme);

  return <Button styles={style} {...props} />;
};

export const DownloadButton = ({uri, ...props}) => {
  const style = useStyles(DownloadButtonTheme);
  const {download} = useAppStateContext();

  const onDownloadPress = uri
    ? () => {
        download({
          uri,
        });
      }
    : void 0;

  return (
    <Button
      icon={style?.downloadIcon}
      styles={style}
      onPress={onDownloadPress}
      {...props}
    />
  );
};
