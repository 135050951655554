import { Row } from '@unthinkable/react-core-components';
import React from 'react';
import {
    GroupFilter,
    SearchFilter
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { ObjectWiseTasks } from '../../common/actions/ObjectWiseTasks';
import {
    PreSalesActiveTable,
    PreSalesActiveTableSolCon,
    PreSalesAllTable,
    PreSalesAllTableForSolCon,
    PreSalesProposalSubTable,
    PreSalesProposalSubTableSolCon,
    PreSalesProposalSubmittedDropTable,
    PreSalesProposalSubmittedDropTableSolCon,
    PreSalesProposalSubmittedLostTable,
    PreSalesProposalSubmittedLostTableSolCon,
    PreSalesProposalSubmittedWonTable,
    PreSalesProposalSubmittedWonTableSolCon,
    PreSalesUnassignedTable
} from './PreSalesTable';

export const PreSalesTabView = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    // product,
    organization,
  };
  let tabs = {};

  tabs.all = {
    label: 'All',
    view: (
      <PreSalesAllTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/all',
    search: searchValue,
    searchFields: ['requirement'],
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
  };

  tabs.unassigned = {
    label: 'Unassigned',
    view: (
      <PreSalesUnassignedTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/unassigned',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };
  tabs.active = {
    label: 'Active',
    view: (
      <PreSalesActiveTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/active',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };
  tabs.proposal = {
    label: 'Proposal',
    view: (
      <PreSalesProposalSubTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/proposal',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };
  tabs.won = {
    label: 'Won',
    view: (
      <PreSalesProposalSubmittedWonTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/won',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };

  tabs.lost = {
    label: 'Lost',
    view: (
      <PreSalesProposalSubmittedLostTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/lost',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };

  tabs.drop = {
    label: 'Drop',
    view: (
      <PreSalesProposalSubmittedDropTable
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/drop',
    addOnFilter: filter,
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'date',
              field: 'creation_date',
              asParam: true,
              label: 'Created On',
            },
            {
              type: 'autoComplete',
              label: 'Outcome',
              options: [
                'New',
                'Active',
                'Won',
                'Lost',
                {label: 'Drop', value: 'invalidRfq'},
              ],
              placeholder: 'Select',
              field: 'status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },

            {
              type: 'autoComplete',
              label: 'Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'multiAutoComplete',
              label: 'Pre Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'rfq_owner',
            },
            {
              type: 'multiAutoComplete',
              label: 'Sol. Consultant',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'tech_person',
            },
            {
              type: 'multiAutoComplete',
              label: 'Pri. Sol.Consultant',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'primary_owner',
            },
            {
              type: 'autoComplete',
              label: 'Quote Status',
              options: ['Submitted', 'Pending', 'On Hold'],
              placeholder: 'Select',
              field: 'quote_status',
            },
            {
              type: 'date',
              field: 'quote_status_date',
              asParam: true,
              label: 'Quote Date',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'autoComplete',
              label: 'Dropped RFQ',
              options: [{label: 'True', value: 'true'}],
              placeholder: 'Select',
              field: 'is_drop',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task date',
            },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <ObjectWiseTasks
            {...props}
            params={params}
            object={{
              _id: '64e06ebc94f415bdfd0a4aef',
            }}
          />
        </Row>,
      ]}
    />
  );
};

export const PreSalesTabViewForSolCon = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  params = {
    ...filterParams,
    ...rest,
    // product,
    organization,
  };
  let tabs = {};

  tabs.all = {
    label: 'All',
    view: (
      <PreSalesAllTableForSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/all',
    search: searchValue,
    searchFields: ['requirement'],
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    addOnFilter: {
      ...filter,
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
  };

  // tabs.unassigned = {
  //   label: 'Unassigned',
  //   view: (
  //     <PreSalesUnassignedTableForSolCon
  //       {...props}
  //       search={searchValue}
  //       addOnFilter={filter}
  //       filterParams={filterParams}
  //       params={params}
  //     />
  //   ),
  //   api: '/opportunities/unassigned',
  //   addOnFilter: filter,
  //   eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
  //   filter: {
  //     $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
  //   },
  //   params: params,
  //   search: searchValue,
  //   searchFields: ['requirement'],
  // };
  tabs.active = {
    label: 'Active',
    view: (
      <PreSalesActiveTableSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/active/solution-consultant',
    addOnFilter: filter,
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };
  tabs.proposal = {
    label: 'Proposal',
    view: (
      <PreSalesProposalSubTableSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/proposal/solution-consultant',
    addOnFilter: filter,
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };
  tabs.won = {
    label: 'Won',
    view: (
      <PreSalesProposalSubmittedWonTableSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/won/solution-consultant',
    addOnFilter: filter,
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };

  tabs.lost = {
    label: 'Lost',
    view: (
      <PreSalesProposalSubmittedLostTableSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/lost/solution-consultant',
    addOnFilter: filter,
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };

  tabs.drop = {
    label: 'Drop',
    view: (
      <PreSalesProposalSubmittedDropTableSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    api: '/opportunities/drop/solution-consultant',
    addOnFilter: filter,
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    eventSourceId: ['CustomPlatformOpportunity', 'communicationAdded'],
    params: params,
    search: searchValue,
    searchFields: ['requirement'],
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Outcome',
              options: [
                'New',
                'Active',
                'Won',
                'Lost',
                {label: 'Drop', value: 'invalidRfq'},
              ],
              placeholder: 'Select',
              field: 'status',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Dropped RFQ',
              options: [{label: 'True', value: 'true'}],
              placeholder: 'Select',
              field: 'is_drop',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'owner',
            },
            {
              type: 'multiAutoComplete',
              label: 'Pre Sales',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'rfq_owner',
            },
            {
              type: 'autoComplete',
              label: 'Quote Status',
              options: ['Submitted', 'Pending', 'On Hold'],
              placeholder: 'Select',
              field: 'quote_status',
            },
            {
              type: 'date',
              field: 'quote_status_date',
              asParam: true,
              label: 'Quote Date',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'date',
              field: 'creation_date',
              asParam: true,
              label: 'Created On',
            },
            {
              type: 'date',
              field: 'outcome_date',
              asParam: true,
              label: 'Outcome date',
            },
            {
              type: 'date',
              field: 'next_task_date',
              asParam: true,
              label: 'Next Task date',
            },
          ]}
        />,
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <ObjectWiseTasks
            {...props}
            params={params}
            object={{
              _id: '64e06ebc94f415bdfd0a4aef',
            }}
          />
        </Row>,
      ]}
    />
  );
};
