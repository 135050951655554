import React, {memo} from 'react';
import {NavHeader as NavHeaderComponent} from '@unthinkable/react-screen-components';
import {useStyles} from '@unthinkable/react-theme';
import {Image, Row, View} from '@unthinkable/react-core-components';
import {navHeaderStyles} from './theme';
import {useNavigation, useNavigationState} from '@react-navigation/native';
import {UserProfile} from '../../app-components/renders/user/UserProfile';
import {useAppStateContext} from '../../providers/AppState';
import {NotificationIcon} from '../../modules/notification/component/NotificationIcon';
import {IconButton} from '../button/IconButton';
import {Breadcrumb} from './AppBreadcrumbs';
import {GroupFilter} from '../filter/Filters';
import {getPreviousMonth, useFilterKeyValues, useGlobalFilter} from '@unthinkable/react-filters';
import {globalFilters} from './GlobalFilters';
import {useCaptureContext} from '../../providers/CaptureContextProvider';
import { Tooltip } from '../modal/Tooltip';

const NavFixedHeaderActions = props => {
  const {
    route: {params},
  } = props;
  const navigation = useNavigation();
  const {user, globalData} = useAppStateContext();

  const {capture} = useCaptureContext();

  let userAccessRoles = user?.accessRoles || [];
  const filter = useFilterKeyValues('global');
  let defaultGlobalFilter={};
  if(!Object.keys(filter).length){
    defaultGlobalFilter = {
      params: {
        product:
          userAccessRoles && userAccessRoles?.[0]?.product
            ? {...userAccessRoles[0]?.product}
            : void 0,
        period: getPreviousMonth(),
      },
    }
  }
  const globalFilterInfo = useGlobalFilter(defaultGlobalFilter);

  const {styles} = props;
  const {chatIcon, reportIcon} = styles || {};

  const {chatCount} = globalData || {};

  let filters = [];

  const {appModule: {filters: appModuleFilters} = {}} = params || {};

  if (appModuleFilters?.length) {
    appModuleFilters.forEach(filter => {
      filters.push(globalFilters[filter]);
    });
  }

  let fixedActions = [];

  fixedActions.push(
    <Tooltip content="Report an Issue" width={125}>
      <IconButton
        icon={reportIcon}
        onPress={async () => {
          let image = await capture();
          navigation.navigate('report-issue', {...params, image});
        }}
      />
    </Tooltip>
  );
  if (filters?.length) {
    fixedActions.push(
      <GroupFilter
        title={'Global Filters'}
        filters={filters}
        {...globalFilterInfo}
      />,
    );
  }

  fixedActions.push(
    <IconButton
      onPress={() => {
        navigation.navigate('comments');
      }}>
      <Image style={{height: 20, width: 20}} source={chatIcon} />
    </IconButton>,
  );
  fixedActions.push(<NotificationIcon />);
  fixedActions.push(<UserProfile {...props} />);

  return (
    <Row style={{alignItems: 'center'}} gap={8}>
      {fixedActions}
    </Row>
  );
};

const NavHeaderActions = ({actions = [], ...props}) => {
  const {styles} = props;
  const {actionSeparator} = styles || {};
  if (actions && !Array.isArray(actions)) {
    actions = [actions];
  }

  return (
    <Row gap={24}>
      {actions?.length ? (
        <Row gap={16} style={{alignItems: 'center'}}>
          {actions}
        </Row>
      ) : (
        void 0
      )}
      <View style={actionSeparator}></View>
      <NavFixedHeaderActions {...props} />
    </Row>
  );
};

export const CustomNavHeader = ({back, title, actions, ...props}) => {
  const {route: {params} = {}} = props;
  const navigation = useNavigation();
  const state = useNavigationState(state => state);
  const {navigationType} = useAppStateContext();

  const onBack = () => {
    navigation?.goBack?.();
  };

  if (navigation?.canGoBack?.()) {
    const prevRoute = state?.routes[state.index - 1];
    back = back || {title: prevRoute?.name};
    title = title || back?.title;
  }

  const actionsComponent = <NavHeaderActions actions={actions} {...props} />;

  return (
    <NavHeaderComponent
      {...props}
      title={title}
      back={params?.breadcrumb ? void 0 : back}
      onBack={onBack}
      skipLogo={navigationType === 'new'}
      actions={actionsComponent}>
      {props?.route?.params?.breadcrumb ? <Breadcrumb {...props} /> : void 0}
    </NavHeaderComponent>
  );
};

export const NavHeader = memo(({...props}) => {
  const styles = useStyles(navHeaderStyles);
  return <CustomNavHeader {...props} styles={styles} />;
});
