import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddTicketOwner} from './TicketOwnerForms';

export const TicketOwnerList = props => {
  const {
    route: {params},
  } = props;
  const {employee_team} = params;
  return (
    <Table
      renderHeader={() => <AddTicketOwner {...props} />}
      api="/ticketowners"
      filter={{
        employee_team: employee_team?._id,
      }}
      eventSourceId={['TicketOwner']}
      fields={{
        owner: {name: 1, color: 1},
        category: {
          name: 1,
        },
      }}
      columns={[
        {
          header: 'Owner',
          field: 'owner',
          type: 'userAvatarChip',

        },
        {
          header: 'Category',
          field: 'category.name',
          colorField: 'category.color',
          type: 'colorTag',
        },
      ]}
      moreActions={[{
        title: 'Edit',
        onPress: ({row}) => {
          props.navigation.navigate('edit-ticket-owner', {
            ticket_owner: row,
            employee_team
          });
        },
      }]}
      {...props}
    />
  );
};
