import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const AdCreativeForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Ad Creative',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/adcreatives',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/adcreatives/${row?._id}`}
      fields={{
        ad_head_line: 1,
        ad_description: 1,
        ad_display_url: 1,
        marketing_concepts: {concept_name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
      }}
      layoutFields={[
        {
          placeholder: 'Head Line',
          field: 'ad_head_line',
          type: 'text',
        },
        {
          placeholder: 'Description',
          field: 'ad_description',
          type: 'text',
        },
        {
          placeholder: 'Ad URL',
          field: 'ad_display_url',
          type: 'text',
        },
        {
          placeholder: 'Marketing Concept',
          field: 'marketing_concepts',
          type: 'autoComplete',
          api: `/marketingconcepts`,
          suggestionField: 'concept_name',
          valueField: 'concept_name',
        },
      ]}
      {...props}
    />
  );
};

export const EditAdCreativeForm = props => {
  return <AdCreativeForm mode="edit" header={'Edit Ad Creative'} {...props} />;
};
