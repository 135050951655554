import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {useState} from 'react';
import { UserAvatarChip } from '../../../components/avatar/UserAvatar';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithDateChip, UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

export const renderType = ({value, styles}) => {
  let updateColor = {color: '#34C759'};
  let background = '';
  let {rowText = {}} = styles;
  let {name} = value || {};
  if (name === 'Leave') {
    background = '#F5E0FF';
  } else if (name === 'Tour') {
    background = '#DBFFFE';
  } else if (name === 'Holiday') {
    background = '#FFF7D6';
  } else if (name === 'EWD') {
    background = '#FFEFE0';
  } else if (name === 'Present') {
    background = '#F0FFF4';
  } else if (name === 'Absent') {
    background = '#FFF1F0';
  } else {
    background = '#F7F7F7';
  }
  return (
    <View
      style={{
        backgroundColor: background,
        padding: 6,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText}}>{name || '-'}</Text>
    </View>
  );
};
const RenderStatus = ({row = {}, styles = {}, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'teamAttendance',
    close: false,
    setLoading,
  });
  let {first_attendance_type_id, second_attendance_type_id} = row;
  let {rowText = {}} = styles;
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          {first_attendance_type_id?.name === 'EWD' ||
          second_attendance_type_id.name === 'EWD' ? (
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                navigation.navigate(`ewd-form`, {
                  row,
                });
              }}>
              <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
            </View>
          ) : (
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/dailyattendances/${row?._id}`,
                  props: {
                    status: 'approve',
                    is_approved: true,
                    __v: 0,
                  },
                });
              }}>
              <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
            </View>
          )}

          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              navigation.navigate('reject-team-request', {row});
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </View>
      )}
    </>
  );
};

export const TeamAttendanceRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  let addOnFilter = {
    ...filter,
    is_approved: false,
    status: 'unapproved',
  };

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'teamAttendance',
    close: false,
  });

  return (
    <Table
      api={'/attendance/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        if (row && row.status === 'unapproved') {
          navigation.navigate(`update-team-attendance-request`, {
            row,
          });
        }
      }}
      selection={{
        actions: [
          {
            title: 'Approve Present Requests',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/dailyattendances/approvePresentRequests`,
                props: {
                  ids: selectedIds,
                },
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 200,
          type: 'text',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM')}
              </Text>
            );
          },
          width: 160,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          width: 250,
          render: ({row, styles}) => (
            <RenderStatus row={row} styles={styles} navigation={navigation} />
          ),
        },
      ]}
    />
  );
};
export const ApprovedAttendanceRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  let addOnFilter = {
    ...filter,
    status: 'approve',
  };
  return (
    <Table
      api={'/attendance/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        if (row && row.status === 'unapproved') {
          navigation.navigate(`update-team-attendance-request`, {
            row,
          });
        }
      }}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 260,
          type: 'text',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM')}
              </Text>
            );
          },
          width: 160,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Accepted On',
          width: 260,
          render: ({row, styles}) => {
            if (row && row.updatedAt) {
              return (
                <Text style={{...styles.rowText}}>
                  {moment(row.updatedAt).format('DD MMM YY')}
                </Text>
              );
            }
          },
        },
      ]}
    />
  );
};
export const RejectedAttendanceRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  let addOnFilter = {
    ...filter,
    is_approved: true,
    status: 'reject',
  };
  return (
    <Table
      api={'/attendance/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        if (row && row.status === 'unapproved') {
          navigation.navigate(`update-team-attendance-request`, {
            row,
          });
        }
      }}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 300,
          type: 'text',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM')}
              </Text>
            );
          },
          width: 180,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Rejected On',
          width: 160,
          render: ({row, styles}) => {
            if (row && row.updatedAt) {
              return (
                <Text style={{...styles.rowText}}>
                  {moment(row.updatedAt).format('DD MMM YY')}
                </Text>
              );
            }
          },
        },
      ]}
    />
  );
};

export const TeamEWDRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  let addOnFilter = {
    ...filter,
    is_approved: false,
    status: 'unapproved',
    $or: [
      {first_attendance_type_id: '5382ea3470f5d40200321611'},
      {second_attendance_type_id: '5382ea3470f5d40200321611'},
    ],
  };

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'teamAttendance',
    close: false,
  });

  return (
    <Table
      api={'/attendance/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      sort={{updatedAt: -1}}
      search={searchValue}
      onRowPress={({row}) => {
        if (row && row.status === 'unapproved') {
          navigation.navigate(`update-team-attendance-request`, {
            row,
          });
        }
      }}
      selection={{
        actions: [
          {
            title: 'Approve Present Requests',
            onPress: ({selectedIds}) => {
              invoke({
                uri: `/dailyattendances/approvePresentRequests`,
                props: {
                  ids: selectedIds,
                },
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          width: 200,
          type: 'text',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM')}
              </Text>
            );
          },
          width: 160,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          width: 250,
          render: ({row, styles}) => (
            <RenderStatus row={row} styles={styles} navigation={navigation} />
          ),
        },
      ]}
    />
  );
};
export const ClosedEWDRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  let addOnFilter = {
    ...filter,
    status: {$in: ['approve', 'reject']},
    $or: [
      {first_attendance_type_id: '5382ea3470f5d40200321611'},
      {second_attendance_type_id: '5382ea3470f5d40200321611'},
    ],
  };
  return (
    <Table
      api={'/attendance/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      sort={{updatedAt: -1}}
      search={searchValue}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 300,
          type: 'text',
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM')}
              </Text>
            );
          },
          width: 160,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 170,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Status',
          render: ({row, styles}) => {
            let {rowText} = styles;
            if (row && row?.status && row?.status === 'approve') {
              return (
                <Text style={{...rowText, color: '#34C759'}}>Approved </Text>
              );
            }
            if (row && row?.status && row?.status === 'reject') {
              return (
                <Text style={{...rowText, color: '#FF5247'}}>Rejected </Text>
              );
            }
          },
        },
      ]}
    />
  );
};
