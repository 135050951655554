import {Table} from '../../../components/table/Table';

export const QuotationRequestTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
    searchValue,
  } = props;
  return (
    <Table
      {...props}
      api={`/quotation/all`}
      eventSourceId={['quotations']}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="po_number"
      onRowPress={({row}) => {
        if (tab === 'Approved' || tab === 'Rejected') {
          navigation.navigate('edit-quotation-request', {row, readOnly: true});
        } else navigation.navigate('edit-quotation-request', {row});
      }}
      columns={[
        {
          header: 'Quot No',
          field: 'po_number',
          type: 'text',
          width: 150,
        },
        {
          header: 'PO Date',
          field: 'po_date',
          type: 'date',
          width: 100,
        },
        {
          header: 'Department',
          field: 'team.name',
          type: 'colorTag',
          minWidth: 300,
        },
        // {
        //   header: 'Payee',
        //   field: 'payee_id.name',
        //   type: 'text',
        //   width: 150,
        // },
        {
          header: 'Description',
          field: 'purpose',
          type: 'text',
          width: 200,
        },
        {
          header: 'Requested By',
          field: 'requested_by',
          type: 'userAvatar',
          width: 200,
        },
        {
          header: 'Approver',
          field: 'approved_by',
          type: 'userAvatar',
          width: 200,
        },
        // {
        //   header: 'Approved',
        //   field: 'approved_on',
        //   type: 'date',
        //   visible: value => {
        //     if (tab === 'Approved') return true;
        //   },
        //   width: 150,
        // },
        // {
        //   header: 'Rejected',
        //   field: 'rejected_on',
        //   type: 'date',
        //   visible: ({values}) => {
        //     if (tab === 'Rejected') return true;
        //   },
        //   width: 150,
        // },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'requested_by', select: {name: 1}},
                {path: 'approved_by', select: {name: 1}},
              ],
              api: `/quotations/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
