import React from 'react';

import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const DeploymentConfigFileForm = props => {
  const {
    route: {params},
  } = props;
  const {repository = {}} = params;

  let {onSubmit} = useFormSubmit({
    uri: `/repositories/${repository?._id}/awsDeploymentConfig`,
    eventSourceId: ['Repository'],
    ...props,
  });

  const {aws_environments, deployment_environments} = repository;

  const awsEnvs =
    aws_environments?.reduce((acc, aws_env) => {
      if (aws_env?.environment_id?.environment) {
        acc.push(aws_env?.environment_id?.environment);
      }
      return acc;
    }, []) || [];

  const jenkinEnvs = [];
  deployment_environments?.forEach(deploy_env => {
    if (deploy_env?.deployment_platform_id?.platform === 'Jenkins') {
      jenkinEnvs.push(deploy_env?.deployment_environment_id?.environment);
    }
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Generate"
      successMessage={
        'Deployment configurations generated. You can checkout branch and review.'
      }
      header={{
        title: 'Deployment Configurations',
        secondaryTitle: repository.label,
      }}
      defaultValues={{
        is_after_install: true,
        is_before_install: true,
        is_appSpec_file: true,
        is_compose_template: true,
        docker_envs: awsEnvs,
        jenkins_env: jenkinEnvs,
      }}
      layoutFields={[
        {
          type: 'checkbox',
          option: {label: 'after_install.sh'},
          field: 'is_after_install',
          labelPosition: 'right',
        },
        {
          type: 'checkbox',
          option: {label: 'before_install.sh'},
          field: 'is_before_install',
          labelPosition: 'right',
        },
        {
          type: 'checkbox',
          option: {label: 'appspec.yml'},
          field: 'is_appSpec_file',
          labelPosition: 'right',
        },
        {
          type: 'checkbox',
          option: {label: 'compose.template.yml'},
          field: 'is_compose_template',
          labelPosition: 'right',
        },
        {
          type: 'checkbox',
          option: {label: 'default.conf'},
          field: 'is_default_conf',
          labelPosition: 'right',
        },
        {
          label: 'Select environments for Docker File',
          visible: awsEnvs?.length,
          fields: [
            {
              field: 'docker_envs',
              type: 'multiCheckbox',
              direction: 'row',
              options: awsEnvs,
            },
          ],
        },
        {
          label: 'Select environments for Jenkin Deployment File',
          visible: jenkinEnvs?.length,
          fields: [
            {
              field: 'jenkins_env',
              type: 'multiCheckbox',
              direction: 'row',
              options: jenkinEnvs,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

/* 

  invoke({
              uri: `/repositories/${row._id}/awsDeploymentConfig`,
              message: 'Files committed successfully.',
            });

*/

/* 


before_install.sh
appspec.yml
*/
