import React, {forwardRef} from 'react';
import {
  TextInput as TextInputComponent,
  PasswordInput as PasswordInputComponent,
  TextRenderer as TextRendererComponent,
  PasswordRenderer as PasswordRendererComponent,
  LinkRender,
} from '@unthinkable/react-text-input';
import {
  NumberInput as NumberInputComponent,
  NumberRenderer as NumberRendererComponent,
} from '@unthinkable/react-number-input';
import {
  TextAreaInput as TextAreaInputComponent,
  TextAreaRenderer as TextAreaRendererComponent,
} from '@unthinkable/react-text-area-input';
import {useStyles} from '@unthinkable/react-theme';
import {InputStyles, LinkInputStyles} from './text/theme';
import {DateRenderer as DateRendererComponent} from '@unthinkable/react-date-picker';

const WithStyles = (Component, InputStyles) =>
  forwardRef((props, ref) => {
    const styles = useStyles(InputStyles);
    return <Component styles={styles} ref={ref} {...props} />;
  });

export const TextInput = WithStyles(TextInputComponent, InputStyles);
export const NumberInput = WithStyles(NumberInputComponent, InputStyles);
export const TextAreaInput = WithStyles(TextAreaInputComponent, InputStyles);
export const PasswordInput = WithStyles(PasswordInputComponent, InputStyles);

export const TextRenderer = WithStyles(TextRendererComponent, InputStyles);
export const PasswordRenderer = WithStyles(
  PasswordRendererComponent,
  InputStyles,
);
export const DateRenderer = WithStyles(DateRendererComponent, InputStyles);
export const NumberRenderer = WithStyles(NumberRendererComponent, InputStyles);
export const TextAreaRenderer = WithStyles(
  TextAreaRendererComponent,
  InputStyles,
);

export const LinkRenderer = WithStyles(LinkRender, LinkInputStyles);
