import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {EmailMarketingTheme} from './EmailMarketingTheme';
import {InmailMarketingTheme} from './InmailMarketingTheme';
import {useFilter} from '../../../controllers/useFilter';
import {AddButton} from '../../../components/button/AddButton';
import {NurturingMarketingTheme} from './NurturingMarketingThemeTable';
import {CaseStudyActiveTable} from './CaseStudyTable';
import {CaseStudyMarketingTheme} from './CaseStudyTheme';
import {OutreacThemeTable} from './OutreachThemeTable';

export const MarketingThemeTabs = props => {
  const {
    route: {params},
  } = props;
  const {hideNuturingTab} = params;
  const {filterValues = {}} = useFilter({
    filterKey: 'marketingThemeFilter',
    params,
  });
  const {params: filterParams} = filterValues;
  const tabs = {
    emailAndInmail: {
      label: 'Email & Inmail',
      view: <OutreacThemeTable />,
      actions: [
        <AddButton
          {...props}
          title="Message Category"
          view="add-marketing-theme"
          params={{
            ...params,
            theme_type: 'outreach',
          }}
        />,
      ],
    },
    caseStudy: {
      label: 'Case Study',
      view: <CaseStudyMarketingTheme />,
      actions: [
        <AddButton
          {...props}
          title="Message Category"
          view="add-marketing-theme"
          params={{
            ...params,
            theme_type: 'caseStudy',
          }}
        />,
      ],
    },
  };
  return (
    <TabView {...props} tabs={tabs} params={{...params, ...filterParams}} />
  );
};

export const MarketingThemeTabsForNurturing = props => {
  const {
    route: {params},
  } = props;
  const {filterValues = {}} = useFilter({
    filterKey: 'marketingThemeFilter',
    params,
  });
  const {params: filterParams} = filterValues;

  const tabs = {
    nurturing: {
      label: 'Nurturing',
      view: <NurturingMarketingTheme />,
    },
  };
  return (
    <TabView {...props} tabs={tabs} params={{...params, ...filterParams}} />
  );
};
