import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { IconButton } from '../../../components/button/IconButton';
import {
    GroupFilter,
    PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';

export const ProvisionInvoiceReport = props => {
    let { navigation } = props;
    const { filterValues, onChangeFilter, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    const { params: filterParams, filter } = filterValues;
    const { period } = filterParams;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    return (
        <Table
            {...props}
            api={'/provisionInvoiceReport'}
            params={{ ...filterParams, ...filter }}
            limit={2000}
            renderHeader={() => (
                <TableHeader
                    title="Provision Expenses"
                    actions={[
                        <PeriodRangeMonthWiseBreakupFilter
                            onChangeFilter={onChangeFilter}
                            filterValues={filterValues}
                        />,
                        <GroupFilter
                            filterValues={filterValues}
                            applyFilter={applyFilter}
                            filters={[
                                {
                                    type: 'multiAutoComplete',
                                    label: 'Payee',
                                    api: '/vendors',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    titleField: 'name',
                                    field: 'payee_id',
                                    searchFields: ['name'],
                                },
                                {
                                    type: 'multiAutoComplete',
                                    label: 'Organization',
                                    api: '/organizations',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    titleField: 'name',
                                    field: 'organization',
                                    searchFields: ['name'],
                                },
                            ]}
                        />,
                          <IconButton
                            icon={Download}
                            onPress={() => {
                              download({
                                uri: '/downloadProvisionExpense',
                                props: {
                                  ...filterParams,
                                  ...filter,
                                },
                              });
                            }}
                          />,
                    ]}
                />
            )}
            columns={[
                {
                    type: 'text',
                    header: 'Vendor',
                    field: 'payee_id',
                    type: 'userAvatarChip',
                    minWidth: 250,
                },
                {
                    type: 'text',
                    header: 'Invoice No.',
                    field: 'invoice_no',
                    width: 150,
                },
                {
                    type: 'date',
                    header: 'Date',
                    field: 'invoice_date',
                    formatOptions: {
                        format: 'DD MMM, YY',
                    },
                    width: 150,
                },
                {
                    type: 'date',
                    header: 'Schedule From Date',
                    field: 'schedule_from_date',
                    formatOptions: {
                        format: 'DD MMM, YY',
                    },
                    width: 150,
                },
                {
                    type: 'date',
                    header: 'Schedule To Date',
                    field: 'schedule_to_date',
                    formatOptions: {
                        format: 'DD MMM, YY',
                    },
                    width: 150,
                },
                {
                    aggregate: true,
                    header: 'Amount',
                    type: 'currency',
                    field: 'amount',
                    toFixed: 2,
                    width: 150,
                    onPress: ({ row }) => {
                        navigation.navigate('vendor-invoice-direct-expense-table', {
                            addOnFilter: {
                                _id: row._id,
                            },
                        });
                    },
                },
                {
                    aggregate: true,
                    header: 'Consumed Amount',
                    type: 'currency',
                    field: 'consumed_amount',
                    toFixed: 2,
                    width: 150,
                    onPress: ({ row }) => {
                        navigation.navigate('vendor-invoice-direct-expense-table', {
                            addOnFilter: {
                                _id: {$in : row.consumedIds || []},
                            },
                        });
                    },
                },
                {
                    aggregate: true,
                    header: 'Pending Amount',
                    type: 'currency',
                    field: 'pending_amount',
                    toFixed: 2,
                    width: 150,
                    onPress: ({ row }) => {
                        navigation.navigate('vendor-invoice-direct-expense-table', {
                            addOnFilter: {
                                _id: {$in : row.pendingIds || []},
                            },
                        });
                    },
                },
            ]}
        />
    );
};