import React from 'react';
import {Table} from '../../../components/table/Table';
import {customDigitalPlatformOppControllers} from '../controllers/customDigitalPlatformControllers';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {Chip} from '@unthinkable/react-chip';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {CommunicationRender} from './LeadTable';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {leadControllers} from '../controllers/leadControllers';
import {objectTasksMoreAction} from '../../common/actions/ObjectWiseTasks';
import {OPPORTUNITY_TYPE_ID} from '../../common/constants/SourceConstants';

const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const ProposalRender = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: CountCellRender,
          responsive: 'sm',
          align: 'right',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-proposal`, {
              opportunity: row,
            });
          },
          visible: ({row}) => !row?.quoted_amount,
        },
        {
          render: props => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  View
                </Text>
              </View>
            );
          },
          // align: 'right',
          visible: ({row}) => row?.quoted_amount,
          onPress: () => {
            const {row} = props;
            navigation.navigate(`opportunity-show-submit-proposal`, {
              opportunity: row,
            });
          },
        },
      ]}
    />
  );
};
const ProposalRenderForWon = props => {
  const {navigation} = props;
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          render: () => {
            return (
              <View style={{paddingLeft: 10, alignItems: 'flex-end'}}>
                <Text style={{...props?.styles?.rowText, color: 'Blue'}}>
                  View
                </Text>
              </View>
            );
          },
          align: 'right',
          visible: ({row}) => row?.quoted_amount,
          onPress: () => {
            const {row} = props;
            navigation.navigate(`opportunity-show-submit-proposal`, {
              // ...params,
              opportunity: row,
            });
          },
        },
      ]}
    />
  );
};

const ProposalStatusRender = ({row}) => {
  if (
    row?.proposal_status &&
    (row?.proposal_status == 'Negotiation' ||
      row?.proposal_status == 'Walkthorugh done')
  ) {
    return (
      <Chip
        textColor="#62D06D"
        color="#F3FCF0"
        value={row?.proposal_status}
        borderRadius={6}
      />
    );
  } else if (
    row?.proposal_status &&
    (row?.proposal_status == 'Proposal Walkthrough' ||
      row?.proposal_status == 'Re-Estimation' ||
      row?.proposal_status == 'Revise Proposal')
  ) {
    return (
      <Chip
        textColor="#f99b2e"
        color="#FFF9F1"
        value={row?.proposal_status}
        borderRadius={6}
      />
    );
  } else {
    return null;
  }
};

const WinabilityRender = ({row}) => {
  if (row?.winning_chance && row?.winning_chance == 'High') {
    return (
      <Chip
        color="#DAFDFE"
        textColor="BLACK"
        value={row?.winning_chance}
        borderRadius={6}
      />
    );
  } else if (row?.winning_chance && row?.winning_chance == 'Mid') {
    return (
      <Chip
        textColor="Black"
        color="#FEEFE0"
        value={row?.winning_chance}
        borderRadius={6}
      />
    );
  } else if (row?.winning_chance && row?.winning_chance == 'Low') {
    return (
      <Chip
        textColor="Black"
        color="#FADFEF"
        value={row?.winning_chance}
        borderRadius={6}
      />
    );
  } else {
    return null;
  }
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'next_task', type: 'text'},
        {
          field: 'next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const LostRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description', type: 'text'},
        {
          field: 'outcome_date',
          type: 'date',
        },
      ]}
    />
  );
};

const RejectRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description_comment', type: 'text'},
        {
          field: 'outcome_date',
          type: 'date',
        },
      ]}
    />
  );
};

const CustomerRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'customer_id.name', type: 'text'},
        {
          field: 'potential_customer.name',
          type: 'text',
        },
      ]}
    />
  );
};

const QuoteRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[{field: 'quote_status', type: 'text'}]}
    />
  );
};

const CHANNEL = {
  header: 'Channel',
  field: 'campaign_type.label',
  colorField: 'campaign_type.color',
  type: 'colorTag',
};

// const RenderPratice = props => {
//   const {
//     row,
//     styles: {rowText},
//   } = props;
//   const colors = useTheme('colors');
//   const practiceName = row?.practice
//     ?.map(element => {
//       return element?.name;
//     })
//     .join(', ');
//   if (!practiceName) return null;
//   return (
//     <Text
//       style={{
//         ...rowText,
//         backgroundColor: colors['ACCENT1_MEDIUM'],
//         paddingRight: 8,
//         paddingLeft: 8,
//         paddingTop: 4,
//         paddingBottom: 4,
//         borderRadius: 5,
//         overflow: 'hidden',
//       }}>
//       {practiceName}
//     </Text>
//   );
// };

const RenderPratice = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  // const colors = useTheme('colors');
  const {INFORMATION_MEDIUM, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  const practiceName = row?.practice
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!practiceName) return null;
  return (
    // <Text
    //   style={{
    //     ...rowText,
    //     backgroundColor: colors['ACCENT1_MEDIUM'],
    //     paddingRight: 8,
    //     paddingLeft: 8,
    //     paddingTop: 4,
    //     paddingBottom: 4,
    //     borderRadius: 5,
    //     overflow: 'hidden',
    //   }}>
    //   {practiceName}
    // </Text>
    <Chip
      color={INFORMATION_MEDIUM}
      textColor={INFORMATION_HIGH}
      value={practiceName}
      borderRadius={6}
    />
  );
};

const renderAddProposal = ({params, navigation}) => ({
  render: CountCellRender,
  header: 'Proposals',
  align: 'right',
  responsive: 'sm',
  width: 120,
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`opportunity-submit-proposal`, {
      ...params,
      opportunity: row,
    });
  },
});

const EmployeeCountRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.employee_count.number', type: 'text'},
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
        },
      ]}
    />
  );
};

const CountryRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.country.name', type: 'text'},
        {
          field: 'customer_id.country.name',
          type: 'text',
        },
      ]}
    />
  );
};

const CUSTOMER = {
  header: 'Opportunity',
  render: CustomerRender,
  responsive: 'sm',
};

const REJECTION = {
  header: 'Lost Reason',
  render: RejectRender,
};

const DROP_REJECTION = {
  header: 'Drop Reason',
  render: RejectRender,
};

const PROPOSAL_STATUS = {
  render: ProposalStatusRender,
  header: 'Proposal Status',
  width: 200,
};

const WINABILITY = {
  render: WinabilityRender,
  header: 'Winnablitiy',
  widht: 10,
};

const REQUIREMENT = {
  field: 'requirement',
  header: 'Requirement',
};

const PRACTICE = {
  field: 'practice.name',
  header: 'Practice',
};

const OWNER = {
  field: 'owner',
  width: 80,
  header: 'Owner',
  type: 'userAvatar',
};

const QUOTE = {
  header: 'Quote',
  render: QuoteRender,
};
const QUOTEDATE = {
  type: 'date',
  field: 'quote_status_date',
  header: 'Quote Status Date',
};

const OUTCOMEDATE = {
  type: 'date',
  field: 'outcome_date',
  header: 'Outcome Date',
};

const NEXTTASK = {
  header: 'Next Task',
  render: NextTaskRender,
};

const LOSTREASON = {
  header: 'Reason for Lost',
  render: LostRender,
};

const DROPREASON = {
  header: 'Reason for Drop',
  render: LostRender,
};

const CREATEDON = {
  header: 'Created On',
  field: 'creation_date',
  type: 'date',
};

const WONQUOTEDHRS = {
  header: 'Proposal Hours',
  field: 'proposal_hours',
  type: 'number',
};

const INDUSTRY = {
  header: 'Industry',
  field: 'industry.name',
  colorField: 'industry.color',
  type: 'colorTag',
};

const EMPLOYEE_COUNT = {
  header: 'Size',
  render: EmployeeCountRender,
  // responsive: 'sm',
};
const COUNTRY = {
  header: 'Country',
  render: CountryRender,
  responsive: 'sm',
  // width: 200
};

export const CustomPlatformOpportunityAllTable = props => {
  const {
    route: {params},
  } = props;
  const {
    assignToTechPerson,
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    opportunitySubmitProposal,
    opportunitySubmitQuote,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      api="/allOpportunities"
      selection={{
        actions: [
          {title: 'Assign Solution Consultant', onPress: assignToTechPerson},
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
        ],
      }}
      searchFields="requirement"
      moreActions={[
        {
          title: 'Submit Quote',
          // onPress: props => {
          //   const {row} = props;
          //   navigation.navigate('opportunity-submit-quote', {
          //     row,
          //   });
          // },
          onPress: opportunitySubmitQuote,
        },
        {title: 'Submit Proposal', onPress: opportunitySubmitProposal},
        {
          title: 'Add Communication',
          onPress: customPlatformOpportunityCommunications,
        },
      ]}
      // params={{
      //   period: params.period,
      // }}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        {
          field: 'requirement',
          header: 'Requirement',
        },
        {
          field: 'practice.name',
          header: 'Practice',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'owner',
          header: 'Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          type: 'date',
          field: 'due_date',
          header: 'Due Date',
        },
        {
          field: 'priority',
          header: 'Priority',
        },
        {
          header: 'Quote',
          render: QuoteRender,
        },
        {
          field: 'status',
          header: 'Status',
        },
        {
          type: 'date',
          field: 'outcome_date',
          header: 'Outcome Date',
        },
        {
          header: 'Communication',
          onPress: customPlatformOpportunityCommunications,
          render: CommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};

export const CustomPlatformOpportunityActiveTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    assignToTechPerson,
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    assignTechPersonOnActive,
    customPlatformOpportunityCommunications,
    addProjectionOnOpportunity,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/active"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      selection={{
        actions: [
          {
            title: 'Assign Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
        ],
      }}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      searchFields="requirement"
      columns={[
        {...CREATEDON, width: 120},
        CUSTOMER,
        {...EMPLOYEE_COUNT, width: 150},
        {
          header: 'Industry',
          field: 'industry.name',
          titleField: 'industry.name',
          colorField: 'industry.color',
          type: 'colorTag',
          width: 300,
        },
        {...COUNTRY, width: 300},
        {
          header: 'Practice',
          render: RenderPratice,
          width: 150,
        },
        CHANNEL,
        {
          render: CountCellRender,
          header: 'Quotation',
          align: 'right',
          responsive: 'sm',
          width: 150,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-quote`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {...OWNER, width: 80},
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
      ]}
    />
  );
};

export const CustomPlatformOpportunityActiveOverview = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    assignTechPersonOnActive,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/allOpportunities"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      selection={{
        actions: [
          {
            title: 'Assign Solution Consultant',
            onPress: assignTechPersonOnActive,
          },
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
        ],
      }}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      searchFields="requirement"
      columns={[
        CUSTOMER,
        EMPLOYEE_COUNT,
        {
          header: 'Industry',
          field: 'industry.name',
          colorField: 'industry.color',
          type: 'colorTag',
        },
        COUNTRY,
        {
          header: 'Practice',
          render: RenderPratice,
          width: 120,
        },
        {
          render: CountCellRender,
          header: 'Proposal',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-quote`, {
              ...params,
              opportunity: row,
            });
          },
        },
        OWNER,
        {
          type: 'colorTag',
          header: 'Status',
          field: 'status',
          width: 100,
        },
        CREATEDON,
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
      ]}
    />
  );
};

export const CustomPlatformOpportunityUnassignedTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/unassigned"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      selection={{
        actions: [
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
        ],
      }}
      searchFields="requirement"
      columns={[
        {...CREATEDON, width: 120},
        CUSTOMER,
        {...EMPLOYEE_COUNT, width: 150},
        // {
        //   header: 'Industry',
        //   field: 'industry.code',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        // },
        {...COUNTRY, width: 300},

        {
          header: 'Practice',
          render: RenderPratice,
          width: 200,
        },
        {
          render: CountCellRender,
          header: 'Owner',
          align: 'right',
          // responsive: 'sm',
          width: 100,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`assign-tech-person-on-active`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
      ]}
    />
  );
};

export const CustomPlatformOppProposalSubTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  console.log({params});
  const {
    assignToTechPerson,
    customPlatformOpportunityCommunications,
    updateProposalStatus,
    opportunityMarkWonRowActive,
    opportunityDropRfqRowAction,
    opportunityMarkLostRowAction,
    addProjectionOnOpportunity,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      selection={{
        actions: [{title: 'Assign Tech Person', onPress: assignToTechPerson}],
      }}
      onRowPress={row => {
        navigation.navigate('edit-customplatformopportunity', row);
      }}
      api="/opportunities/proposal"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        CUSTOMER,
        {...EMPLOYEE_COUNT, width: 150},
        {
          header: 'Industry',
          field: 'industry.name',
          titleField: 'industry.name',
          colorField: 'industry.color',
          type: 'colorTag',
          width: 300,
        },
        {...COUNTRY, width: 200},
        CHANNEL,
        {
          render: CountCellRender,
          header: 'Quotation',
          align: 'right',
          responsive: 'sm',
          width: 120,
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`opportunity-submit-quote`, {
              ...params,
              opportunity: row,
            });
          },
        },
        {
          header: 'Proposal Value',
          render: props => {
            return (
              <ProposalRender
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        PROPOSAL_STATUS,
        {...WINABILITY, width: 100},
        OWNER,

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
      ]}
      moreActions={() => [
        {
          title: 'Change Status & Win-ability',
          onPress: updateProposalStatus,
        },
        {
          title: 'Mark Won',
          onPress: opportunityMarkWonRowActive,
        },
        {
          title: 'Mark Lost',
          onPress: opportunityMarkLostRowAction,
        },
        {
          title: 'Mark Drop',
          onPress: opportunityDropRfqRowAction,
        },
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
      ]}
    />
  );
};

export const ProposalSubmittedWonTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {addCustomerFromCustomPlatformOpportunity, updateProposalHours} =
    leadControllers(props);
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/won"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        CUSTOMER,
        {...EMPLOYEE_COUNT, width: 150},
        {
          header: 'Industry',
          field: 'industry.name',
          titleField: 'industry.name',
          colorField: 'industry.color',
          type: 'colorTag',
          width: 300,
        },
        {...COUNTRY, width: 200},
        {...CHANNEL, width: 150},
        WONQUOTEDHRS,
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        OWNER,
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
      ]}
      moreActions={({row}) => [
        {
          title: 'Update Proposal Hrs.',
          onPress: updateProposalHours,
        },
        ...(row.potential_customer?._id
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
      ]}
    />
  );
};

export const ProposalSubmittedLostTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/lost"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {
          field: 'creation_date',
          header: 'Created On',
          type: 'date',
          width: 120,
        },
        CUSTOMER,

        // {...EMPLOYEE_COUNT, width: 150},
        // {
        //   header: 'Industry',
        //   field: 'industry.name',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        //   width: 300,
        // },
        // {...COUNTRY, width: 200},
        {...CHANNEL, width: 200},
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        {
          field: 'quote_date',
          header: 'Quote Date',
          type: 'date',
          width: 120,
        },
        {
          header: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal Value',
          field: 'quoted_amount',
          type: 'number',
          width: 120,
        },

        {...OWNER, width: 80},
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
        {...REJECTION, width: 250},
      ]}
    />
  );
};

export const ProposalSubmittedDropTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/drop"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      columns={[
        {
          field: 'creation_date',
          header: 'Created On',
          type: 'date',
          width: 120,
        },
        CUSTOMER,

        // {...EMPLOYEE_COUNT, width: 150},
        // {
        //   header: 'Industry',
        //   field: 'industry.name',
        //   titleField: 'industry.name',
        //   colorField: 'industry.color',
        //   type: 'colorTag',
        //   width: 300,
        // },
        // {...COUNTRY, width: 200},
        {...CHANNEL, width: 200},
        {
          header: 'Proposal',
          render: props => {
            return (
              <ProposalRenderForWon
                {...props}
                params={params}
                navigation={navigation}
              />
            );
          },
          width: 120,
          align: 'right',
        },
        {
          field: 'quote_date',
          header: 'Quote Date',
          type: 'date',
          width: 120,
        },
        {
          header: 'Quoted Hours',
          field: 'quoted_hours',
          type: 'number',
          width: 120,
        },
        {
          header: 'Proposal Value',
          field: 'quoted_amount',
          type: 'number',
          width: 120,
        },

        {...OWNER, width: 80},
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 150,
        },
        {...DROP_REJECTION, width: 200},
      ]}
    />
  );
};

export const CustomPlatformOpportunityOwnTable = props => {
  let {
    route: {params},
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      // moreActions={[
      //   {
      //     title: 'Add Communication',
      //     onPress: customPlatformOpportunityCommunications,
      //   },
      // ]}
      api="/opportunities/won"
      eventSourceId={'CustomPlatformOpportunity'}
      searchFields="requirement"
      columns={[
        CUSTOMER,
        REQUIREMENT,
        {
          header: 'Practice',
          render: RenderPratice,
        },
        OWNER,
        QUOTE,
        OUTCOMEDATE,
        CREATEDON,
      ]}
    />
  );
};

export const CustomPlatformOpportunityLostTable = props => {
  let {
    route: {params},
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      // moreActions={[
      //   {
      //     title: 'Add Communication',
      //     onPress: customPlatformOpportunityCommunications,
      //   },
      // ]}
      api="/opportunities/lost"
      eventSourceId={'CustomPlatformOpportunity'}
      searchFields="requirement"
      columns={[
        CUSTOMER,
        REQUIREMENT,
        {
          header: 'Practice',
          render: RenderPratice,
        },
        OWNER,
        QUOTE,
        OUTCOMEDATE,
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        LOSTREASON,
        CREATEDON,
      ]}
    />
  );
};

export const CustomPlatformOpportunityDropTable = props => {
  let {
    route: {params},
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const {opportunityMarkActive} = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      api="/opportunities/drop"
      eventSourceId={'CustomPlatformOpportunity'}
      searchFields="requirement"
      selection={{
        actions: [{title: 'Mark Active', onPress: opportunityMarkActive}],
      }}
      columns={[
        CUSTOMER,
        REQUIREMENT,
        {
          header: 'Practice',
          render: RenderPratice,
        },
        OWNER,
        QUOTE,
        OUTCOMEDATE,
        DROPREASON,
      ]}
    />
  );
};

export const CustomPlatformOpportunityOverDueTable = props => {
  const {
    assignToTechPerson,
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    opportunityProposalPending,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      api="/opportunities/overdue"
      eventSourceId={'CustomPlatformOpportunity'}
      selection={{
        actions: [
          {title: 'Assign Solution Consutant', onPress: assignToTechPerson},
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
        ],
      }}
      moreActions={[
        {title: 'Proposal Pending', onPress: opportunityProposalPending},
        {
          title: 'Add Communication',
          onPress: customPlatformOpportunityCommunications,
        },
      ]}
      searchFields="requirement"
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        {
          field: 'requirement',
          header: 'Requirement',
        },
        {
          field: 'practice.name',
          header: 'Practice',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'owner',
          header: 'Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          type: 'date',
          field: 'due_date',
          header: 'Due Date',
        },
        {
          field: 'priority',
          header: 'Priority',
        },
        {
          header: 'Quote',
          render: QuoteRender,
        },
        {
          field: 'status',
          header: 'Status',
        },
        {
          type: 'date',
          field: 'outcome_date',
          header: 'Outcome Date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};

export const CustomPlatformOpportunityProposalApi = {
  api: '/opportunities/won',
  addOnFilter: {
    rfr_type: 'PotentailCustomer',
  },
};

export const CustomPlatformOpportunityProposalTable = props => {
  const params = props.route.params;
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={'CustomPlatformOpportunity'}
      moreActions={[
        {
          title: 'Add Communication',
          onPress: customPlatformOpportunityCommunications,
        },
      ]}
      {...CustomPlatformOpportunityProposalApi}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          header: 'Customer',
          field: 'customer_id.name',
        },
        {
          header: 'Entity',
          field: 'potential_customer.name',
        },
        {
          field: 'requirement',
          header: 'Requirement',
        },
        {
          field: 'practice.name',
          header: 'Practice',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'owner',
          header: 'Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          type: 'date',
          field: 'due_date',
          header: 'Due Date',
        },
        {
          field: 'priority',
          header: 'Priority',
        },
        {
          header: 'Quote',
          render: QuoteRender,
        },
        {
          field: 'status',
          header: 'Status',
        },
        {
          type: 'date',
          field: 'outcome_date',
          header: 'Outcome Date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};

export const AllOpportunityTable = props => {
  const {
    route: {params},
  } = props;
  const {
    assignToTechPerson,
    opportunityMarkWon,
    opportunityMarkLost,
    opportunityDropRfq,
    opportunitySubmitProposal,
    customPlatformOpportunityCommunications,
  } = customDigitalPlatformOppControllers(props);
  const {filters} = params;
  return (
    <Table
      {...props}
      params={params}
      filter={filters}
      api="/potentialcustomer/opportunites"
      selection={{
        actions: [
          {title: 'Assign Solution Consultant', onPress: assignToTechPerson},
          {title: 'Mark Won', onPress: opportunityMarkWon},
          {title: 'Mark Lost', onPress: opportunityMarkLost},
          {title: 'Drop RFQ', onPress: opportunityDropRfq},
          {
            title: 'Add Communication',
            onPress: customPlatformOpportunityCommunications,
          },
        ],
      }}
      moreActions={[
        {title: 'Submit Proposal', onPress: opportunitySubmitProposal},
      ]}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          header: 'Customer',
          field: 'customer_id.name',
        },
        {
          header: 'Potential Customer',
          field: 'potential_customer.name',
        },
        {
          field: 'requirement',
          header: 'Requirement',
        },
        {
          field: 'practice.name',
          header: 'Practice',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'owner',
          header: 'Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          type: 'date',
          field: 'due_date',
          header: 'Due Date',
        },
        {
          field: 'priority',
          header: 'Priority',
        },
        {
          header: 'Quote',
          render: QuoteRender,
        },
        {
          field: 'status',
          header: 'Status',
        },
        {
          type: 'date',
          field: 'outcome_date',
          header: 'Outcome Date',
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
      ]}
    />
  );
};
