import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {BlogActiveTable, BlogCompletedTable} from './BlogTable';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

export const BlogTabView = props => {
  const {route: {params} = {}} = props;
  const {onChangeFilter, searchValue} = useFilter({});
  let tabs = {
    active: {
      label: 'Active',
      view: <BlogActiveTable searchValue={searchValue} />,
    },
    completed: {
      label: 'Completed',
      view: <BlogCompletedTable searchValue={searchValue} />,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
