import React, {useContext, useMemo, useRef} from 'react';

const ReactEventHandlerContext = React.createContext();

export const EventHandlerProvider = ({children}) => {
  const eventListeners = useRef({});

  const notify = (event, params) => {
    let listeners = eventListeners.current[event];
    if (listeners?.length) {
      listeners.forEach(listener => {
        listener({params});
      });
    }
  };

  const listen = (event, callback) => {
    eventListeners.current[event] = [
      ...(eventListeners.current[event] || []),
      callback,
    ];
  };

  const unlisten = (event, callback) => {
    let listeners = eventListeners.current[event];
    if (listeners?.length) {
      eventListeners.current[event] = listeners.filter(
        _callback => _callback !== callback,
      );
    }
  };

  const eventHandlerContextValue = useMemo(
    () => ({
      notify,
      listen,
      unlisten,
    }),
    [],
  );

  return (
    <ReactEventHandlerContext.Provider value={eventHandlerContextValue}>
      {children}
    </ReactEventHandlerContext.Provider>
  );
};

export const useEventHandler = () => {
  let context = useContext(ReactEventHandlerContext);
  return context;
};
