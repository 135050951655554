import {useTheme} from '@unthinkable/react-theme';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  Image,
  Row,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {isMobile, resolveExp} from '@unthinkable/react-utils';
import {WithModal} from '../../../components/modal/WithModal';
import {IconButton} from '../../../components/button/IconButton';
import {useAIActions} from '../actions/AIActions';
import {AIButton} from '../../../components/button/Button';
import {useFetchData} from '../../../controllers/useFetchData';
import pluralize from 'pluralize';
import {useEventHandler} from '@unthinkable/react-event-handler';

export const AISuggestionsButton = props => {
  const {
    navigation,
    params,
    entityName,
    uri,
    filter,
    suggestionUri,
    eventSourceId = 'ProjectAI',
    newPromptName = 'EntityNewSuggestions',
    improvePromptName = 'EntityImproveSuggestions',
    morePromptName = 'EntityMoreSuggestions',
    improveMorePromptName = 'EntityImproveMoreSuggestions',
  } = props;
  const {icons} = useTheme();
  const {notify} = useEventHandler();

  const pluralEntityName = entityName ? pluralize.plural(entityName) : void 0;

  const {data: suggestionCountData, loading: suggestionDataLoading} =
    useFetchData({
      api: suggestionUri || `/${pluralEntityName}/ai`,
      count: true,
      params,
      eventSourceId,
    });

  const {data: entityCountData, loading: entityDataLoading} = useFetchData({
    api: uri || `/${pluralEntityName}`,
    count: true,
    filter,
    params,
    eventSourceId,
  });

  const setLoadingCallback = state => {
    notify('onMoreSuggestion', {loadingAction: state});
  };

  const {loadingAction, onNewSuggestions, onMoreSuggestions} = useAIActions({
    ...props,
    pluralEntityName,
    setLoadingCallback,
  });
  if (suggestionDataLoading !== false && entityDataLoading !== false) {
    return null;
  }

  const isMoreSuggestion = entityCountData?.count > 0;

  let actions = [];
  if (isMoreSuggestion) {
    actions.push(
      <AIButton
        text="Create with AI"
        title={'Ask AI for more suggestions'}
        icon={icons.AISuggestNew}
        // loading={loadingAction === 'MoreSuggestions'}
        disabled={loadingAction}
        onPress={() => {
          navigation.navigate('ai-suggestion-form', {
            onSubmit: (...props) => {
              const callback = onMoreSuggestions({
                prompt_name: morePromptName,
                is_more_suggestion: true,
              });
              callback(...props);
              notify('onMoreSuggestion', {loadingAction: 'MoreSuggestions'});
            },
            onSubmit: onMoreSuggestions({
              prompt_name: morePromptName,
              is_more_suggestion: true,
            }),
          });
        }}
      />,
    );
  } else {
    // actions.push(
    //   <AIButton
    //     title={'Ask AI for suggestions'}
    //     text="Ask AI"
    //     icon={icons.AISuggestNew}
    //     // loading={loadingAction === 'NewSuggestions'}
    //     disabled={loadingAction}
    //     onPress={onNewSuggestions({
    //       prompt_name: newPromptName,
    //     })}
    //   />,
    // );
  }
  return actions;
};

export const AcceptRejectButton = ({onAccept, onReject}) => {
  const theme = useTheme();
  const {icons} = theme;
  return (
    <Row gap={8}>
      {onAccept ? (
        <IconButton
          icon={icons.GreyTick}
          title="Accept"
          onPress={() => {
            onAccept();
          }}
        />
      ) : (
        void 0
      )}
      {onReject ? (
        <IconButton
          icon={icons.Close}
          title="Reject"
          onPress={() => {
            onReject();
          }}
        />
      ) : (
        void 0
      )}
    </Row>
  );
};

export const AIGeneratedIcon = () => {
  const icons = useTheme('icons');
  return (
    <Image
      source={icons.AISuggested}
      style={{
        height: 20,
        width: 90,
      }}
    />
  );
};

export const AIReviewSuggestion = ({
  row,
  onAccept,
  onReject,
  primaryTitle,
  secondaryTitle,
}) => {
  const icons = useTheme('icons');

  return (
    <WithModal
      width={isMobile ? void 0 : 400}
      renderModal={({hide}) => {
        return (
          <TouchableOpacity onPress={() => {}}>
            <PrimaryColumnCard
              primaryTitle={primaryTitle}
              secondaryTitle={secondaryTitle}
              numberOfLines={6}
            />
            <View style={{alignItems: 'flex-end', paddingTop: 8}}>
              <AcceptRejectButton
                onAccept={() => {
                  hide();
                  onAccept({row});
                }}
                onReject={() => {
                  hide();
                  onReject({row});
                }}
              />
            </View>
          </TouchableOpacity>
        );
      }}
      position={isMobile ? 'screenBottom' : 'bottom'}
      placement="start">
      <Image
        source={icons.ViewSuggestion}
        style={{
          height: 20,
        }}
      />
    </WithModal>
  );
};

export const AISuggestionRender = props => {
  const {row, primaryField, secondaryField, acceptBody, ...restProps} = props;
  const {onAccept, onReject} = useAIActions(props);
  const {icons, fonts, colors} = useTheme();

  const primaryTitle = resolveExp(row, primaryField);
  let secondaryTitle = secondaryField
    ? resolveExp(row, secondaryField)
    : void 0;

  const {aiGenerated, aiSuggestion} = row || {};

  let renderComponent = (
    <PrimaryColumnCard
      numberOfLines={1}
      secondaryTitleNumberOfLines={2}
      primaryTitle={primaryTitle}
      secondaryTitle={secondaryTitle}
      secondaryTitleFont={fonts.BODY3}
      secondaryTitleColor={colors.NEUTRAL_MEDIUM}
      {...restProps}
      renderIcon={() => {
        if (aiGenerated) {
          return <AIGeneratedIcon />;
        } else if (aiSuggestion) {
          const reviewPrimaryTitle = resolveExp(aiSuggestion, primaryField);
          let reviewSecondaryTitle = secondaryField
            ? resolveExp(aiSuggestion, secondaryField)
            : void 0;
          return (
            <AIReviewSuggestion
              row={row}
              primaryTitle={reviewPrimaryTitle}
              secondaryTitle={reviewSecondaryTitle}
              onAccept={onAccept}
              onReject={onReject}
            />
          );
        } else {
          return void 0;
        }
      }}
    />
  );
  if (aiGenerated) {
    renderComponent = (
      <Row style={{alignItems: 'center'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>{renderComponent}</View>
        <IconButton
          icon={icons.Close}
          title="Reject"
          onPress={() => {
            onReject({row});
          }}
        />
      </Row>
    );
  }
  return renderComponent;
};
