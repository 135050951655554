import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useAppStateContext} from '../../../providers/AppState';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssetVerificationForm = props => {
  const {onClose} = props;

  const {onSubmit} = useFormSubmit({
    uri: `/employees`,
    eventSourceId: 'Employees',
    afterSubmit: onClose,
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            asset_review_pending: false,
          },
        };
      }}
      closable={false}
      layoutFields={[
        {
          field: 'asset_details',
          label: 'Asset Details',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              skipAdd
              skipDelete
              fields={[
                {
                  label: 'Serial No.',
                  size: 'small',
                  field: 'serial_no',
                  type: 'text',
                  readOnly: true,
                },
                {
                  label: 'Brand',
                  size: 'small',
                  field: 'brand',
                  type: 'text',
                  readOnly: true,
                },
                {
                  label: 'Model',
                  size: 'small',
                  field: 'model',
                  type: 'text',
                  readOnly: true,
                },
                {
                  label: 'Asset Code',
                  size: 'small',
                  field: 'code',
                  type: 'text',
                  readOnly: true,
                },
                {
                  label: 'Internal Code',
                  size: 'small',
                  field: 'internal_code',
                  type: 'text',
                  readOnly: true,
                },
                {
                  type: 'text',
                  label: 'Asset Category',
                  size: 'small',
                  field: 'type.name',
                  readOnly: true,
                },
                {
                  type: 'text',
                  label: 'Specifications',
                  size: 'small',
                  field: 'description',
                  readOnly: true,
                },
                {
                  type: 'radioGroup',
                  field: 'asset_confirmation',
                  label: 'Asset Confirmation',
                  size: 'small',
                  options: [
                    {value: 'Accepted', label: 'Yes'},
                    {value: 'Rejected', label: 'No'},
                  ],
                  required: true,
                },
                {
                  type: 'textArea',
                  label: 'Comments',
                  field: 'comment_from_employee',
                  placeholder: 'Type comment(if any)',
                },
              ]}
              columns={[
                {
                  field: 'serial_no',
                  header: 'Serial No.',
                  type: 'text',
                },
                {
                  field: 'brand',
                  header: 'Brand',
                  type: 'text',
                },
                {
                  field: 'model',
                  header: 'Model',
                  type: 'text',
                },
                {
                  field: 'type.name',
                  header: 'Asset Category',
                  type: 'text',
                },
                {
                  field: 'description',
                  header: 'Specifications',
                  type: 'text',
                },
                {
                  field: 'asset_confirmation',
                  header: 'Asset Confirmation',
                  type: 'text',
                },
                {
                  field: 'comment_from_employee',
                  header: 'Comments',
                  type: 'text',
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export const AssetVerification = props => {
  const {user} = useAppStateContext();
  const {employee} = user;
  return (
    <AssetVerificationForm
      header={{
        title: 'Asset Acknowledge Form',
      }}
      mode="edit"
      api={'/employees/' + employee?._id}
      fields={{
        asset_details: {
          serial_no: 1,
          brand: 1,
          model: 1,
          code: 1,
          internal_code: 1,
          type: {
            name: 1,
          },
          description: 1,
          asset_confirmation: 1,
          comment_from_employee: 1,
        },
      }}
      {...props}
    />
  );
};
