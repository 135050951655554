import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {
  PurchaseOrderTable,
  PurchaseOrderTableDepartment,
} from './PurchaseOrderTable';
import {useAppStateContext} from '../../../providers/AppState';

export const PurchaseOrderTab = props => {
  let {
    route: {params},
  } = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    Requested: {
      label: 'Processed',
      view: (
        <PurchaseOrderTable
          addOnFilter={{
            is_approved: {$in: [null, false]},
            ...filterValues.filter,
          }}
          searchValue={searchValue}
          tab="Processed"
          {...props}
        />
      ),
      api: '/purchaseorder/all',
      addOnFilter: {
        is_approved: {$in: [null, false]},
        ...filterValues.filter,
      },
      eventSourceId: 'purchaseOrder',
      search: searchValue,
      searchFields: 'po_no',
    },
    Approved: {
      label: 'Approved',
      view: (
        <PurchaseOrderTable
          addOnFilter={{
            is_approved: true,
            ...filterValues.filter,
            is_invoice_generated: {$in: [null, false]},
          }}
          tab="Approved"
          searchValue={searchValue}
          {...props}
        />
      ),
      api: '/purchaseorder/all',
      addOnFilter: {
        is_approved: true,
        is_invoice_generated: {$in: [null, false]},
        ...filterValues.filter,
      },
      eventSourceId: 'purchaseOrder',
      search: searchValue,
      searchFields: 'po_no',
    },
    invoicegenerated: {
      label: 'Invoice Created',
      view: (
        <PurchaseOrderTable
          addOnFilter={{
            is_approved: true,
            ...filterValues.filter,
            is_invoice_generated: true,
          }}
          tab="invoice Generated"
          searchValue={searchValue}
          {...props}
        />
      ),
      api: '/purchaseorder/all',
      addOnFilter: {
        is_approved: true,
        is_invoice_generated: true,
        ...filterValues.filter,
      },
      eventSourceId: 'purchaseOrder',
      search: searchValue,
      searchFields: 'po_no',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search PO Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Supplier',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
            {
              type: 'date',
              field: 'po_date',
              label: 'PO Date',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const PurchaseOrderTabDepartment = props => {
  let {
    route: {params},
  } = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const tabs = {
    Requested: {
      label: 'PO',
      view: (
        <PurchaseOrderTableDepartment
          addOnFilter={{
            is_approved: true,
            ...filterValues.filter,
            requested_by: employeeId,
          }}
          searchValue={searchValue}
          tab="Processed"
          {...props}
        />
      ),
      api: '/purchaseorder/all',
      addOnFilter: {
        is_approved: true,
        ...filterValues.filter,
        requested_by: employeeId,
      },
      eventSourceId: 'purchaseOrder',
      search: searchValue,
      searchFields: 'po_no',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search PO Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Supplier',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
