import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const SingleScreenMessageForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Single Screen Message',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/singlescreenmessages',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/singlescreenmessages/${row?._id}`}
      fields={{
        single_screen_message_title: 1,
        single_screen_message_content: 1,
        marketing_concept: {concept_name: 1},
        attachment: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
      }}
      layoutFields={[
        {
          placeholder: 'Single Screen Message',
          field: 'single_screen_message_title',
          type: 'text',
        },
        {
          placeholder: 'Description',
          field: 'single_screen_message_content',
          type: 'text',
        },
        {
          placeholder: 'Marketing Concept',
          field: 'marketing_concept',
          type: 'autoComplete',
          api: `/marketingconcepts`,
          suggestionField: 'concept_name',
          valueField: 'concept_name',
        },
        {
          field: 'attachment',
          type: 'file',
          placeholder: 'Upload file*',
          multiple: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditSingleScreenMessageForm = props => {
  return (
    <SingleScreenMessageForm
      mode="edit"
      header={'Edit Single Screen Message'}
      {...props}
    />
  );
};
