import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';
import { renderLocale } from './OrderTable';
import { AmountRender } from './components/AmountRender';
import { Button } from '../../../components/button/Button';

const SoftexTable = props => {
    const { filterParams, searchValue, params } = props;
    return (
        <Table
            {...props}
            api={'/softexreport'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="Softex"
            searchFields={['invoice_number']}
            search={searchValue}
            limit={50}
            columns={[
                {
                    header: 'Customer',
                    field: 'customer.name',
                    type: 'colorTag',
                    minWidth: 250,
                },
                {
                    field: 'customer.correspondence_address',
                    header: 'Address',
                    type: 'text',
                },
                {
                    field: 'customer.correspondence_country.name',
                    header: 'Country',
                    type: 'text',
                },
                {
                    field: 'invoice_number',
                    header: 'Invoice No.',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    field: 'exchange_rate',
                    header: 'Exchange Rate',
                    type: 'text',
                    width: 140,
                },
                {
                    field: 'invoice_date',
                    header: 'Invoice Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 140,
                },
                {
                    field: 'invoice_date',
                    header: 'Order',
                    type: 'text',
                    value: ({ row }) => row?.invoice_details?.[0]?.delivery_id?.order?.order_number || "",
                    width: 140,
                },
                {
                    field: 'invoice_date',
                    header: 'Order Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    value: ({ row }) => row?.invoice_details?.[0]?.delivery_id?.order?.order_date || "",

                    width: 140,
                },
                {
                    header: 'Amount',
                    field: 'invoice_amount',
                    type: 'currency',
                    width: 160,
                    align: 'center',
                    render: ({ row, styles }) => (
                        <AmountRender
                            row={row}
                            styles={styles}
                            amount={row?.invoice_amount || 0}
                            base_amount={row?.base_invoice_amount || 0}
                            currency={row?.currency?.currency}
                        />
                    ),
                    formatOptions: ({ row }) => {
                        return {
                            locale: renderLocale(row?.currency?.currency),
                            currency: row?.currency?.currency,
                        };
                    },
                }
            ]}
        />
    );
};

export const SoftexTab = props => {
    let { route: { params } = {}, navigation } = props;
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const icons = useTheme('icons');
    const tabs = {
        Softex: {
            label: 'Softex',
            view: (
                <SoftexTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'Softex',
            api: '/softexreport',
            addOnFilter: filter,
            search: searchValue,
            searchFields: ['invoice_number'],
            actions: [
                <IconButton
                    icon={icons.Download}
                    onPress={() => {
                        download({
                            uri: '/downloadsoftexlist',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <SearchFilter
                    value={searchValue}
                    onChangeFilter={onChangeFilter}
                    placeholder="Search Invoice Number"
                />,
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Customer',
                            api: '/customers',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'customer',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
                <Button
                    text="SREF"
                    onPress={() => {
                        download({
                            uri: `/exportsref`,
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
                <Button
                    text="Softex"
                    onPress={() => {
                        download({
                            uri: `/exportsoftex`,
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
                <Button
                    text="Project Profitability"
                    onPress={() => {
                        download({
                            uri: `/exportprojectprofitabilityreport`,
                            props: {
                                filter: { ...filter },
                            },
                        });
                    }}
                />,
                <Button
                    text="Import Softex"
                    onPress={() => {
                        navigation.navigate('import-list', {
                            source: 'softexdetails',
                            model: 'RevenueInvoice',
                        });
                    }}
                />,
            ]}
        />
    );
};
