export const CommunicationComponentStyles = ({theme: {fonts, colors}}) => {
  return {
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 10,
      backgroundColor: 'white',
      overflow: 'hidden',
      marginBottom: 16,
    },
    subjectStyle: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.CAPTION_LARGE,
    },
    subjectContentStyle: {
      color: '#1A1A1A',
      ...fonts.CAPTION_LARGE,
    },
    contentHeader: {
      alignItems: 'center',
      backgroundColor: colors.SURFACE1,
      overflow: 'hidden',
      padding: '12px 16px',
    },
    clientAttendeeName: {
      ...fonts.CAPTION_LARGE,
      color: '#1A1A1A',
    },
    clientAttendeeDesignation: {
      ...fonts.CAPTION_LARGE,
      color: '#FF9500',
    },
    clientStyle: {
      display: 'flex',
      backgroundColor: '#FFF7D6',
      flexDirection: 'row',
      borderRadius: 6,
      padding: 4,
    },
    clientAttendeeArrayStyle: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 12,
      paddingTop: 5,
      paddingRight: 10,
      paddingBottom: 5,
      paddingLeft: 10,
    },
    initiatorStyle: {
      display: 'flex',
      backgroundColor: colors.ACCENT6_MEDIUM,
      paddingTop: 5,
      paddingRight: 10,
      paddingBottom: 5,
      paddingLeft: 10,
      flexDirection: 'row',
      ...fonts.CAPTION_LARGE,
      borderRadius: 12,
    },
    communicationDate: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_MEDIUM,
    },
    headersStyle: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_MEDIUM,
      paddingRight: 5,
    },
    communicationMode: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_LOW,
    },
    positiveSentiment: {
      ...fonts.CAPTION_LARGE,
      color: 'green',
    },
    negativeSentiment: {
      ...fonts.CAPTION_LARGE,
      color: 'red',
    },
    neutralSentiment: {
      ...fonts.CAPTION_LARGE,
      color: 'blue',
    },
    seperator: {
      margin: 5,
      height: 5,
      width: 5,
      backgroundColor: '#f8952d',
      borderRadius: '50%',
    },
    Infoseperator: {
      margin: 5,
      height: 5,
      width: 5,
      backgroundColor: colors.NEUTRAL_MEDIUM,
      borderRadius: '50%',
    },
    footerMainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };
};
