import React, {forwardRef, memo} from 'react';
import Avatar from './Avatar';
import {AvatarGroupContainer} from './Styles';

export const AvatarGroup = forwardRef(
  (
    {
      styles,
      children,
      avatars,
      maxAvatars = 4,
      space = -6,
      direction = 'row',
      avatar,
      avatarSize,
    },
    ref,
  ) => {
    if (avatars) {
      if (!Array.isArray(avatars)) {
        avatars = [avatars];
      }
    } else if (children) {
      avatars = React.Children.toArray(children);
    }
    avatars = avatars ? avatars.filter(avatar => !!avatar) : avatars;
    if (!avatars || !avatars.length) {
      return null;
    }
    let remainingAvatars = void 0;
    if (maxAvatars && maxAvatars < avatars.length) {
      remainingAvatars = avatars.slice(maxAvatars);
      avatars = avatars.slice(0, maxAvatars);
    }
    if (remainingAvatars && remainingAvatars.length) {
      avatars.push({
        value: `+ ${remainingAvatars.length}`,
      });
    }

    const containerStyle = {};
    if (direction === 'row') {
      containerStyle.marginLeft = space;
    } else if (direction === 'row-reverse') {
      containerStyle.marginRight = space;
      avatars = avatars.reverse();
    } else if (direction === 'column') {
      containerStyle.marginTop = space;
    } else if (direction === 'column-reverse') {
      containerStyle.marginBottom = space;
      avatars = avatars.reverse();
    }

    let groupAvatars = avatars.map((_avatar, index) => {
      const avatarProps = {
        borderColor: 'white',
        ...avatar,
        containerStyle: index > 0 ? containerStyle : {},
        key: index,
      };
      if (avatarSize) {
        avatarProps.size = avatarSize;
      }
      if (React.isValidElement(_avatar)) {
        return React.cloneElement(_avatar, {
          ...avatarProps,
          ..._avatar.props,
        });
      } else {
        _avatar = typeof _avatar === 'string' ? {value: _avatar} : _avatar;
        return <Avatar styles={styles?.avatar} {...avatarProps} {..._avatar} />;
      }
    });

    return (
      <AvatarGroupContainer styles={styles} ref={ref} direction={direction}>
        {groupAvatars}
      </AvatarGroupContainer>
    );
  },
);

export default memo(AvatarGroup);
