import React from 'react';
import {Table} from '../../../components/table/Table';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useInvoke} from '../../../controllers/useInvoke';

export const ActiveTargetList = props => {
  const {
    route: {params},
  } = props;

  const invoke = useInvoke({method: 'put'});

  const markTargetComplete = ({row}) => {
    invoke({
      uri: `/targets/${row?._id}`,
      props: {
        completed_on: getZeroTimeDate(new Date()),
        status: 'completed',
      },
    });
  };

  const markTargetCompleteSelection = ({selectedIds}) => {
    invoke({
      uri: `/targets/batchUpdate`,
      props: {
        ids: selectedIds,
        updates: {
          completed_on: getZeroTimeDate(new Date()),
          status: 'completed',
        },
      },
    });
  };

  return (
    <Table
      selection={{
        actions: [
          {title: 'Mark Complete', onPress: markTargetCompleteSelection},
        ],
      }}
      api={`/departments/${params?.department?._id}/activeTargetsList`}
      params={params}
      columns={[
        {
          field: 'target_name',
          header: 'Target',
          width: 500,
        },
        {
          field: 'target_value',
          header: 'Target value',
        },

        {
          field: 'previous_target_value',
          header: 'Actual value',
        },
        {
          field: 'tasks',
          header: 'Completed/Total tasks',
          compareField: 'completed_tasks',
        },
        {
          field: 'team_cost',
          header: 'Team Cost',
        },
      ]}
      moreActions={() => [
        {title: 'Mark Complete', onPress: markTargetComplete},
      ]}
    />
  );
};
