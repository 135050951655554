import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddTargetAudience = props => {
  const {
    route: {params},
  } = props;
  const {product, department, row, target_audience, organization} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/buyerPersonas',
    eventSourceId: 'targetAdded',
    ...props,
  });
  return (
    <Form
      api={`/buyerPersonas/${row?._id}`}
      fields={{
        name: 1,
        job_title: 1,
        country: {name: 1, color: 1},
        employee_size: {number: 1},
        industry: {name: 1},
        designation: 1,
        description: 1,
        parent: {name: 1},
        experiment_status: 1,
        owner: {
          name: 1,
          color: 1,
        },
        status: 1,
      }}
      onSubmit={onSubmit}
      eventSourceId={['targetAdded', 'valuePropositionAdded']}
      defaultValues={{
        product: product?._id,
        department: department?._id,
        parent: target_audience?._id,
        organization,
        status: 'In Experiment',
      }}
      header={{title: 'Add Target Audience'}}
      layoutFields={[
        {
          label: 'Target Audience',
          field: 'name',
          type: 'text',
          size: 6,
        },
        {
          label: 'Parent',
          type: 'autoComplete',
          field: 'parent',
          api: '/buyerpersonas',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Target Audiene Description',
          field: 'description',
          type: 'textArea',
        },
        {
          label: 'Size',
          type: 'autoComplete',
          field: 'employee_size',
          api: '/employeeSizes',
          suggestionField: 'number',
          valueField: 'number',
          size: 4,
        },
        {
          label: 'Industry',
          type: 'autoComplete',
          field: 'industry',
          api: '/industries',
          suggestionField: 'name',
          valueField: 'name',
          size: 4,
        },
        {
          label: ' Country',
          type: 'autoComplete',
          field: 'country',
          api: '/countries',
          suggestionField: 'name',
          valueField: 'name',
          size: 4,
        },

        {
          label: 'POC Designation',
          field: 'designation',
          type: 'text',
          size: 6,
        },

        {
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const EditTargetAudience = props => {
  return (
    <AddTargetAudience
      header={{title: 'Edit Target Audience'}}
      mode="edit"
      {...props}
    />
  );
};
