import React from 'react';
import {Table} from '../../../components/table/Table';
import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';

const inlineForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'VersionControl',
    uri: '/projectversioncontrols',
    ...props,
  });

  const {project} = params || {};

  const defaultValues = {};

  if (project?._id) {
    defaultValues.project_id = project?._id;
  }

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'VCS',
          type: 'autoComplete',
          options: ['bitbucket', 'github'],
          field: 'type',
          required: true,
          helperText: 'Select the version control service provider.',
        },
        {
          label: 'Workspace/User Name',
          field: 'workspace',
          type: 'text',
          required: true,
          helperText: 'Input the workspace or user ID for the VCS.',
        },
      ]}
    />
  );
};

const vcs = {
  type: 'text',
  field: 'type',
  header: 'VCS',
};

const workspace = {
  type: 'text',
  field: 'workspace',
  header: 'Workspace/User Name',
};

export const VCSTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: ['VersionControl'],
  });

  return (
    <>
      {inlineForm(props)}
      <Table
        eventSourceId={['VersionControl']}
        api={`/projectversioncontrols/active`}
        params={{
          project_id: project?._id,
        }}
        onRowPress={({row}) => {
          navigation.navigate('vcs-detail', {
            ...params,
            vcs: {_id: row?._id},
          });
        }}
        fields={{type: 1, workspace: 1}}
        columns={[vcs, workspace]}
        moreActions={[
          {
            title: 'Delete',
            variant: 'error',
            confirm: {
              title: 'Delete',
              message: 'Are you sure you want to Delete?',
              confirmText: 'Delete',
            },
            onPress: ({row}) => {
              deleteInvoke({
                uri: `/projectversioncontrols/${row?._id}`,
              });
            },
          },
        ]}
      />
    </>
  );
};
