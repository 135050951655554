import React, {useState} from 'react';
import {Text, TouchableOpacity, View} from '@unthinkable/react-core-components';
import {CommentBox} from './CommentBox';
import {useStyles} from '@unthinkable/react-theme';
import {CommentStyle} from './theme';

export const InteractiveCommentBox = props => {
  const [isFocused, setIsFocused] = useState();

  let placeholder = props?.placeholder || 'Type your comment...';
  const {
    commentBox: {textContainer, text},
  } = useStyles(CommentStyle);

  return (
    <View style={props.containerStyle}>
      {isFocused ? (
        <CommentBox
          {...props}
          autoFocus={true}
          successMessage={false}
          setIsFocused={setIsFocused}
        />
      ) : (
        <TouchableOpacity
          style={textContainer}
          onPress={() => setIsFocused(true)}>
          <Text style={text}>{placeholder}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};
