import React from 'react';
import {MentionsInput, Mention} from 'react-mentions';

const MentionComponent = ({
  styles = {},
  inputRef,
  value,
  fetchSuggestions,
  onChange: onChangeProp,
  placeholder,
  maxHeight,
  minHeight,
  renderSuggestion,
  suggestionsPortalHost,
  onBlur,
  onFocus,
}) => {
  const onChange = (event, newValue, plainText, mentions) => {
    onChangeProp?.(newValue, event, plainText, mentions);
  };

  return (
    <MentionsInput
      className="mentions"
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      allowSuggestionsAboveCursor
      allowSpaceInQuery
      suggestionsPortalHost={
        suggestionsPortalHost || document.getElementById('root')
      }
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        control: {
          overflow: 'hidden',
          ...styles.inputContainer,
          maxHeight,
          minHeight,
        },
        input: {
          overflow: 'auto',
          padding: 0,
          borderWidth: 0,
          backgroundColor: 'transparent',
          outline: 'none',
          ...styles.input,
        },
        suggestions: {
          list: {
            maxHeight: 200,
            overflowY: 'auto',
            ...styles.list,
          },
        },
      }}
      placeholder={placeholder}>
      <Mention
        trigger="@"
        data={fetchSuggestions}
        appendSpaceOnAdd
        style={{
          ...styles.mentionsText,
        }}
        displayTransform={(id, display) => `@${display}`}
        renderSuggestion={(suggestion, search, index, focused) => {
          return renderSuggestion({
            row: suggestion,
            search,
            index,
          });
        }}
      />
    </MentionsInput>
  );
};

export default MentionComponent;
