import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AssignResourceOnTeam = props => {
  const {
    route: {params},
  } = props;

  let {employee_team,is_internal} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/assignResourceOnTeam',
    eventSourceId: 'EmployeeTeam',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header={{title:"Add Member",secondaryTitle:employee_team?.name || ""}}
      defaultValues={{
        team_id:employee_team,
        hours: 9,
        is_internal
      }}
      layoutFields={[
        {
          label: 'Resource',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          idField:'_id',
          required: true,
        },
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
          required: true,
          size: 6,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
          required: true,
          size: 6,
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          required: true,
          size: 4,
        },
      ]}
      {...props}
    />
  );
};
