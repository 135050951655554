import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';


const EmployeePackageTable = props => {
    const { filterParams, params } = props;
    return (
        <Table
            {...props}
            api={'/employeepackagereport'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="employeepackage"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'name',
                    header: 'Employee',
                },
                {
                    type: 'text',
                    field: 'employee_code',
                    header: 'Employee Code',
                },
                {
                    field: 'last_package_review_date',
                    header: 'Package Review Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                },
                {
                    header: 'Fixed Salary',
                    field: "employee_salary_component.fixed_salary",
                    type: 'number',
                },
                {
                    field: 'employee_salary_component.incentive_salary',
                    type: 'number',
                    header: 'Incentive Salary',
                },
                {
                    header: 'CTC Amount',
                    field: "employee_salary_component.ctc_amount",
                    type: 'number',
                },
            ]}
        />
    );
};

export const EmployeePackageTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Employee Package',
            view: (
                <EmployeePackageTable filterParams={filter} {...props} />
            ),
            eventSourceId: 'employeepackage',
            addOnFilter: filter,
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/downloademployeepackage',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Employee',
                            api: '/employeeSuggestions',
                            searchFields: ['name', 'official_email_id', 'employee_code'],
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'employee',
                        },
                    ]}
                />,
            ]}
        />
    );
};
