import React from 'react';
import {Table} from '../../../components/table/Table';
import {EmployeeSize} from '../controllers/EmployeeSize';

export const InActiveEmployeeSizeTable = props => {
  const {navigation} = props;
  const {markEmployeeSizeActive} = EmployeeSize(props);

  return (
    <Table
      eventSourceId="EmployeeSize"
      api={`/employeeSize/inActive`}
      onRowPress={props => {
        navigation.navigate(`employee-size-detail`, {
          ...props,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'number',
          header: 'Number',
          responsive: 'sm',
        },
        {
          type: 'number',
          field: 'score',
          header: 'Score',
          responsive: 'sm',
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          onPress: markEmployeeSizeActive,
        },
      ]}
    />
  );
};
