import {
  Row,
  Styled,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const CardContainer = Styled(TouchableOpacity)`
  ${props =>
    props.isSelected
      ? {...props.styles?.card, ...props.styles?.selectedCard}
      : props.styles?.card}
  ${props => `
    &:hover {
        background-color:${props?.styles?.cardHover?.backgroundColor};
        box-shadow: ${props?.styles?.cardHover?.boxShadow};
        .imageContainer {
            box-shadow: 0px 0px 0px rgba(0, 0, 0);
          }
    }
  `}
`;

export const ItemContainer = Styled(View)`
  padding: 12px;
  ${props =>
    props.index > 0 ? {borderTopWidth: 1, borderColor: '#EEEEED'} : {}}
  ${props => props.styles?.itemContainer}
`;

export const BreadCrumbText = Styled(Text).withConfig()`
  ${props => props.styles?.title}
`;

export const BreadCrumbItemContainer = Styled(Row).withConfig()`
  align-items: center;
  padding: 2px 0px;
  border-radius: 8px;
  overflow: hidden;
  ${({styles}) => {
    return {
      ...styles,
    };
  }}
  ${props => `
  &:hover {
   .breadcrumbTextContainer,.breadcrumbIconContainer {
     background-color: ${props?.hoverColor};
   }
   `}
  }
`;

export const BreadCrumbChildItemContainer = Styled(
  TouchableOpacity,
).withConfig()`
  ${props => `
  &:hover {
   .child {
     background-color: ${props?.hoverColor};
   }
   `}
  }
`;

export const BreadCrumbTextContainer = Styled(View).withConfig()`
  height: 28px;
  justify-content: center;
  padding: 0px 8px;
  ${({styles}) => {
    return {
      ...styles,
    };
  }}
`;

export const BreadCrumbTitleTextContainer = Styled(Row).withConfig()`
  height: 32px;
  align-items: center;
  padding: 0px 8px;
  border-radius: 8px;
  ${({styles}) => {
    return {
      ...styles,
    };
  }}
  ${props => `
  &:hover {
    background-color: ${props?.hoverColor};
   `}
  }
`;

export const BreadCrumbIconContainer = Styled(TouchableOpacity).withConfig()`
  height: 28px;
  width: 28px;
  align-items: center;
  justify-content: center;
`;
