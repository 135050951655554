import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {TouchableOpacity, View} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';

export const Action = ({
  children,
  style,
  onPress,
  view,
  params,
  renderModal,
  disabled,
}) => {
  const navigation = useNavigation();
  if (!children) {
    return null;
  }
  if (renderModal) {
    return (
      <WithModal position="screenCenter" renderModal={renderModal}>
        {children}
      </WithModal>
    );
  } else {
    let Component = View;
    let componentProps = {};
    if (view || onPress) {
      Component = TouchableOpacity;
      componentProps.onPress = () => {
        if (view) {
          navigation.navigate(view, {...params});
        } else if (onPress) {
          onPress();
        }
      };
    }
    return (
      <Component
        style={{...style, alignItems: 'center', justifyContent: 'center'}}
        disabled={disabled}
        {...componentProps}>
        {children}
      </Component>
    );
  }
};
