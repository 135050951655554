import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import CountCellRender from '../../../app-components/renders/CountCellRender';

export const OutreacThemeTable = props => {
  const {params, navigation} = props;
  const {showDripInmails, showDripEmails} = leadControllers({
    ...props,
    params,
  });
  const {target_audience} = params;
  return (
    <Table
      api={`/marketingThemes`}
      fields={{
        name: 1,
        type: {name: 1},
        theme_value_proposition: 1,
        target_audience: {
          name: 1,
        },
        drip_inmail_count: {
          _id: 1,
        },
        drip_email_count: {
          _id: 1,
        },
      }}
      filter={{
        theme_type: 'outreach',
        target_audience,
      }}
      eventSourceId={[
        'marketingThemeAdded',
        'dripInmailAdded',
        'dripEmailAdded',
      ]}
      onRowPress={({row}) => {
        navigation.navigate('edit-marketing-theme', {...params, row});
      }}
      columns={[
        {
          header: 'Value Proposition',
          field: 'theme_value_proposition',
          type: 'text',
          responsive: 'sm',
        },
        {
          header: 'Type',
          field: 'type.name',
          colorField: 'type.color',
          responsive: 'sm',
          type: 'colorTag',
        },
        {
          render: CountCellRender,
          header: 'Email',
          align: 'right',
          count_field: 'drip_email_count',
          responsive: 'sm',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`add-dripemailcontent`, {
              ...params,
              marketingThemeFilter: {
                marketing_theme: row?._id,
              },
              marketing_theme: row,
              campaign_type: '64d0fd55ab78dfead48c144a',
            });
          },
          onPress: showDripEmails,
          width: 150,
        },
        {
          render: CountCellRender,
          header: 'Inmail',
          align: 'right',
          count_field: 'drip_inmail_count',
          responsive: 'sm',
          onPlusPress: props => {
            const {row} = props;
            navigation.navigate(`add-dripinmailcontent`, {
              ...params,
              marketing_theme: row,
            });
          },
          onPress: showDripInmails,
          width: 150,
        },
      ]}
      {...props}
    />
  );
};
