import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {StaffAugmentationOpportunityTableOverview} from '../../leadGeneration/views/StaffAugmentationTable';
import { leadToCustomerFilter } from './CampaignCommonFilter';

const ChannelWiseStaffAugmentationTab = props => {
  const {route: {params} = {}} = props;
  let {filters = {}, addOnFilter = {}, row} = params || {};
  const {applyFilter, filterValues} = useFilter({});
  let {filter, params: filterParams = {}} = filterValues;

  addOnFilter = {...addOnFilter, ...leadToCustomerFilter(row)};
  filter = {...filter, ...addOnFilter};

  let tabs = {};

  tabs.all = {
    label: 'Staff Augmentation Opportunities',
    api: '/staffAugmentationOpportunities/all',
    params: {
      ...params,
    },
    addOnFilter: {...filter, ...filters},
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <StaffAugmentationOpportunityTableOverview
        sort={{outcome_date: -1}}
        {...props}
        addOnFilter={{...filter, ...filters}}
        filterParams={filterParams}
        params={params}
      />
    ),
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
            {
              type: 'autoComplete',
              label: 'Skill',
              api: '/skills',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'skill',
            },
            {
              type: 'autoComplete',
              label: 'Potential Customer',
              api: '/potentialcustomers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'potential_customer',
            },
            {
              type: 'autoComplete',
              label: 'Mode',
              options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
              placeholder: 'Select',
              field: 'process',
            },
          ]}
        />,
      ]}
    />
  );
};

export default ChannelWiseStaffAugmentationTab;
