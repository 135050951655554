import React from 'react';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
export const JoinStrategicInitiative = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {
    user: {
      employee: {_id: employeeId},
    },
  } = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    uri: '/joinStrategicInitiative',
    eventSourceId: 'reloadStrategicInitiative',
    ...props,
  });
  return (
    <Form
      {...props}
      header={{title: 'Join Initiative'}}
      onSubmit={onSubmit}
      defaultValues={{
        strategic_initiative: row?._id,
        resource: employeeId,
      }}
      layoutFields={[
        {
          label: 'Why are you interested in this initiative?',
          type: 'text',
          field: 'interest',
        },
      ]}
    />
  );
};
