import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchFormData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const RFRTatForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'TATReport',
    ...props,
  });

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={true}
      layoutFields={[
        {
          fields: [
            {
              type: 'autoComplete',
              field: 'resource',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Resource',
              label: 'Resource',
              size: 6,
            },
            {
              type: 'text',
              field: 'mode',
              placeholder: 'Mode',
              label: 'Mode',
              size: 6,
            },
            {
              type: 'autoComplete',
              field: 'mentor',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Mentor',
              label: 'Mentor',
              size: 6,
            },
            {
              label: 'Practice',
              field: 'practice',
              type: 'multiAutoComplete',
              api: '/practices',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              placeholder: 'Profile',
              label: 'Profile',
              field: 'profile',
              multiple: true,
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
      ]}
    />
  );
};

export const TATFormDetails = props => {
  const {route: {params} = {}} = props;
  const {rfrId, row} = params;
  let {data} = useFetchFormData({
    api: '/staffaugmentationopportunities',
    filter: {
      _id: rfrId,
    },

    fields: {
      _id: 1,
      creation_date: 1,
      rfr_assigned_date: 1,
      profile: 1,
      projection_date: 1,
      outcome_date: 1,
      training_from: 1,
      training_to: 1,
      mode: 1,
      skill: {_id: 1, name: 1},
      practice: {_id: 1, name: 1},
      outcome: 1,
      requirement: 1,
      outcome_description: 1,
      outcome_rejection_reason: 1,
      process: 1,
      work_location: 1,
      interview_start_time: 1,
      interview_date: 1,
      opportunity_id: {_id: 1},
      potential_customer: {_id: 1, name: 1},
      customer_id: {_id: 1, name: 1},
      resource: {_id: 1, official_email_id: 1, color: 1, name: 1},
      owner: {_id: 1, official_email_id: 1, color: 1, name: 1},
      mentor: {_id: 1, official_email_id: 1, color: 1, name: 1},
    },
  });
  if (!data) {
    return null;
  }

  return (
    <RFRTatForm header={{title: row?.resource?.name}} defaultValues={data} />
  );
};
