import React from 'react';
import {
  NestedAddFormModal,
  NestedTableModal,
} from '../../components/form/NestedTable';
import {Chip} from '../../components/chip/Chip';
import {useStyles} from '@unthinkable/react-theme';
import {NestedTableModalRenderStyles} from './theme';
import {Image, Row, View} from '@unthinkable/react-core-components';
import NestedField from '@unthinkable/react-form/src/NestedField';

export const NestedTableModalRender = ({
  header,
  skipAdd,
  computations,
  _parentValues,
  fieldInfo,
  modalProps,
  ...props
}) => {
  const {addPlus, iconStyle, iconContainerStyle} = useStyles(
    NestedTableModalRenderStyles,
  );

  const {field, readOnly, values} = props;

  const nestedFieldProps = {
    ...fieldInfo,
    field,
    readOnly,
    _parentValues,
    computations: computations?.nestedComputations?.[field],
  };

  let renderComponent = (
    <NestedField {...nestedFieldProps}>
      {_props => {
        return (
          <NestedTableModal
            modalProps={{header, ...modalProps}}
            {...props}
            {..._props}>
            <Chip
              color={'Information'}
              displayTextColor
              value={values[field]?.length || 0}
            />
          </NestedTableModal>
        );
      }}
    </NestedField>
  );

  if (!readOnly && !skipAdd) {
    renderComponent = (
      <Row gap={4} style={{alignItems: 'center'}}>
        {renderComponent}
        <NestedField {...nestedFieldProps}>
          {_props => {
            return (
              <NestedAddFormModal {...props} {..._props}>
                <View style={iconContainerStyle}>
                  <Image source={addPlus} style={iconStyle} />
                </View>
              </NestedAddFormModal>
            );
          }}
        </NestedField>
      </Row>
    );
  }

  return renderComponent;
};
