import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useAccentColor} from '../../../controllers/useAccentColor';
import useRealTimeSync from '../../../components/customHooks/useRealTimeSync';
import {Tag} from '../../../components/chip/Chip';
import {Col} from '@unthinkable/react-core-components';
import {convertSecondsToHMS} from '../../../utils/util';
import {TextRenderer} from '../../../components/form-editors/Editors';

const DEPLOYMENT_STATUS_COLORS = {
  Pending: {
    color: 'ACCENT1',
  },
  Success: {
    color: 'SUCCESS',
  },
  SUCCESS: {
    color: 'SUCCESS',
  },
  'In Progress': {
    color: 'ACCENT6',
  },
  Failure: {
    color: 'ERROR',
  },
  FAILURE: {
    color: 'ERROR',
  },
  Failed: {
    color: 'ERROR',
  },
  ABORTED: {
    color: 'ERROR',
  },
};

export const DeploymentStatusCard = ({row}) => {
  if (!row) {
    return;
  }

  const [deploymentData, deploymentUpdateData] = useState(row);

  const deploymentStatus = deploymentData?.status || 'Pending';

  const {HIGH, LOW} = useAccentColor(
    DEPLOYMENT_STATUS_COLORS[deploymentStatus]?.color,
  );

  const {HIGH: erroHigh, LOW: errorLow} = useAccentColor('Failure');

  useRealTimeSync({
    event: 'JenkinsDeploymentStatus',
    group: 'JenkinsDeployment',
    callBack: ({repo_env_id, build_number, live_url, build_status}) => {
      if (
        deploymentData?.repository_environment_id?.toString() ===
          repo_env_id.toString() &&
        deploymentData.build_number.toString() === build_number.toString()
      ) {
        deploymentUpdateData(doc => ({
          ...doc,
          status: build_status,
        }));
      }
    },
  });
  return (
    <Col gap={8}>
      <Tag value={deploymentStatus} color={LOW} textColor={HIGH} />
      {deploymentData.error && (
        <Tag
          value={deploymentData.error}
          color={errorLow}
          textColor={erroHigh}
        />
      )}
    </Col>
  );
};

const DeploymentHistoryTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {environment} = params;

  return (
    <Table
      api={`/deploymentHistories`}
      filter={{
        repository_environment_id: environment._id,
        is_discontinued: {$ne: true},
      }}
      fields={{
        build_number: 1,
        status: 1,
        deployed_by: {name: 1, color: 1},
        repository_environment_id: 1,
        createdAt: 1,
        error: 1,
        duration: 1,
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          type: 'userAvatar',
          field: 'deployed_by',
          width: 50,
          align: 'center',
        },
        {
          header: 'Build ID',
          type: 'text',
          field: 'build_number',
          width: 100,
          align: 'center',
        },
        {
          header: 'Status',
          render: ({row}) => <DeploymentStatusCard row={row} />,
        },
        {
          header: 'Started',
          type: 'date',
          field: 'createdAt',
          formatOptions: {fromNow: true},
          width: 150,
        },
        {
          header: 'Duration',
          width: 150,
          render: ({row}) => {
            if (row?.duration) {
              return <TextRenderer value={convertSecondsToHMS(row.duration)} />;
            } else {
              return void 0;
            }
          },
        },

        // {
        //   render: PipelineRender,
        //   width: 60,
        // },
        // {
        //   header: 'Pipeline',
        //   render: PipelineCardRender,
        // },
        // {
        //   header: 'Status',
        //   width: 200,
        //   render: DeploymentStatusRender,
        //   align: 'center',
        // },
        // {
        //   header: 'Started',
        //   field: 'createdAt',
        //   width: 150,
        //   render: ({row}) => {
        //     return (
        //       <TextRenderer
        //         title={formats.formatDate(row.createdAt)}
        //         value={formats.formatDate(row.createdAt, {fromNow: true})}
        //       />
        //     );
        //   },
        // },
      ]}
      moreActions={[
        {
          title: 'View logs',
          onPress: ({row}) => {
            navigation.navigate('deployment-logs', {
              ...params,
              env_id: environment._id,
              status: row?.status?.toLowerCase(),
              build_number: row?.build_number,
            });
          },
        },
      ]}
    />
  );
};

export default DeploymentHistoryTable;
