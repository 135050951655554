import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const MarketingConceptsForm = props => {
  const {
    route: {params},
  } = props;
  const {department, product, row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/marketingconcepts',
  });
  return (
    <Form
      api={`/marketingconcepts/${row?._id}`}
      fields={{
        concept_name: 1,
        concept_details: 1,
      }}
      onSubmit={onSubmit}
      defaultValues={{
        department: department?._id,
        product: product?._id,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Name',
          field: 'concept_name',
          type: 'text',
        },
        {
          label: 'Detail',
          field: 'concept_details',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const AddMarketingConcepts = props => {
  return <MarketingConceptsForm header={'Add Marketing Concepts'} {...props} />;
};

export const EditMarketingConceptForm = props => {
  return (
    <MarketingConceptsForm
      header={'Edit Marketing Concepts'}
      mode="edit"
      {...props}
    />
  );
};
