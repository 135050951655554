import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const BuyerPersonaForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Buyer Persona',
    mode,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/buyerpersonas',
    ...props,
  });
  const {department, product, row} = params;
  return (
    <Form
      api={`/buyerpersonas/${row?._id}`}
      fields={{
        created_on: 1,
        assigned_to: {name: 1},
        name: 1,
        job_title: 1,
        company: 1,
        pain_points: 1,
        buyer_persona_characteristics: 1,
        preferred_communication_channels: 1,
        marketing_concept: {concept_name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        created_on: mode !== 'edit' ? new Date() : void 0,
        status: 'active',
      }}
      layoutFields={[
        {
          placeholder: 'Date',
          field: 'created_on',
          type: 'date', //how to show by default on form
        },
        {
          placeholder: 'Assigned To',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          placeholder: 'Job Title',
          field: 'job_title',
          type: 'text',
        },
        {
          placeholder: 'Company',
          field: 'company',
          type: 'text',
        },
        {
          placeholder: 'Pain Points',
          field: 'pain_points',
          type: 'text',
        },
        {
          placeholder: 'Buyer Persona Characteristics',
          field: 'buyer_persona_characteristics',
          type: 'text',
        },
        {
          placeholder: 'Preferred Communication Channels',
          field: 'preferred_communication_channels',
          type: 'text',
        },
        {
          placeholder: 'Marketing Concept',
          field: 'marketing_concept',
          type: 'autoComplete',
          api: `/marketingconcepts`,
          suggestionField: 'concept_name',
          valueField: 'concept_name',
        },
      ]}
      {...props}
    />
  );
};

export const EditBuyerPersonaForm = props => {
  return (
    <BuyerPersonaForm mode="edit" header={'Edit Buyer Persona'} {...props} />
  );
};
