import React from 'react';

import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';

const ModuleRoleInlineForm = props => {
  const {navigation, route: {params} = {}} = props;
  const {project, module_id} = params || {};
  const {_id: project_id} = project || {};

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Role',
    uri: '/projectroles',
  });
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project_id,
      }}
      beforeSubmit={({data}) => {
        const role = data.role;
        let newData = {_id: role._id, module_id: role.module_id || []};
        if (newData.module_id.indexOf(module_id) === -1) {
          newData.module_id.push(module_id);
        }
        return {
          data: newData,
        };
      }}
      layoutFields={[
        {
          label: 'Role',
          field: 'role',
          type: 'autoComplete',
          api: `/projectroles`,
          suggestionField: 'role',
          searchFields: ['role'],
          fields: {
            module_id: 1,
            role: 1,
          },
          required: true,
          filter: {module_id: {$ne: module_id}, project_id},
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-role`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

export const ModuleRoleTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {module_id} = params || {};

  const {
    user: {email},
  } = useAppStateContext();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Role',
  });

  const screenAIEmail = [
    'amit.singh@daffodildb.com',
    'rohit.bansal@daffodilsw.com',
    'sachin.bansal@daffodilsw.com',
    'sushil.nagvan@daffodilsw.com',
    'monu@daffodilsw.com',
    'abhishek.kumar@daffodilsw.com',
    'gopesh.singhal@daffodilsw.com',
    'ojasvita@daffodilsw.com',
  ];

  return (
    <Table
      renderHeader={() => {
        return <ModuleRoleInlineForm {...props} />;
      }}
      api={`/projectroles`}
      eventSourceId={['Role']}
      filter={{
        module_id,
      }}
      columns={[
        {
          header: 'Role',
          field: 'role',
          type: 'text',
        },
      ]}
      onRowPress={({row}) => {
        navigation.navigate(`role-detail`, {
          ...params,
          role: row,
        });
      }}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this role?',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/roles/${row?._id}/removeModule`,
              props: {
                module_id,
              },
            });
          },
        },
        {
          title: 'Screen IA',
          onPress: ({row}) => {
            navigation.navigate('screen-ia', {...params, row});
          },
          visible: screenAIEmail.includes(email),
        },
      ]}
    />
  );
};
