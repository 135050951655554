import {Chip} from '@unthinkable/react-chip';
import {Col, Text, View} from '@unthinkable/react-core-components';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {Tag} from '../../../components/chip/Chip';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

const renderExperience = ({row = {}, styles: {rowText = {}}}) => {
  return (
    <Text style={{...rowText}}>
      {row?.experience && `${row?.experience} yr`}
    </Text>
  );
};

const RenderBenchSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        style={{
          ...rowText,
        }}>
        {row?.bench_days || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {moment(row.bench_from).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

const renderStatus = ({row = {}}) => {
  let {status = '', assigned_to = {}} = row || {};
  if (!status) return null;
  if (status === 'Fully Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag
          value={assigned_to?.types ? assigned_to?.types : status}
          color={'#E0EFFF'}
        />
      </View>
    );
  }

  if (status === 'Partialy Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag
          value={assigned_to?.types ? assigned_to?.types : status}
          color={'#DBFFFE'}
        />
      </View>
    );
  }
};

const renderAvailablitity = ({row = {}, styles = {}}) => {
  let {rowText = {}} = styles;
  let {totalActualWorkingHours = 0} = row || {};
  let freeHours = 0;
  freeHours = 9 - Number(totalActualWorkingHours);
  if (Number.isInteger(freeHours)) {
    return (
      <Text
        style={
          freeHours === 9
            ? {...rowText, color: '#34C759'}
            : {...rowText, color: '#007AFF'}
        }>
        {`${freeHours} h`}
      </Text>
    );
  } else {
    return (
      <Text
        style={
          freeHours === 9
            ? {...rowText, color: '#34C759'}
            : {...rowText, color: '#007AFF'}
        }>
        {`${freeHours.toFixed(2)} h`}
      </Text>
    );
  }
};

const EXPERIENCE = {
  header: 'Exp.',
  render: renderExperience,
  type: 'number',
};

const SKILLS = {
  header: 'Skill',
  field: 'assigned_to.resource_language.name',
  type: 'colorTag',
  colorField: 'resource_language.color',
  width: 200,
};

const BenchResources = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
    tab,
    api,
    fromInternalResourceRequest = false,
  } = props;
  const {period, row: OrderResourceRequest, ...rest} = params;
  const {projectDetail = {}} = addOnParams || {};
  params = {
    ...addOnParams,
    ...rest,
  };
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      api={api}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 240,
        },
        EXPERIENCE,
        SKILLS,
        tab == 'project' && {
          header: 'Project',
          align: 'center',
          field: 'project',
          labelField: 'project',
          titleField: 'project',
          colorField: 'color',
          render: MultiColorChip,
          maxChips: 3,
          width: 240,
        },
        tab !== 'project' && {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 100,
        },
        {
          header: 'Status',
          render: renderStatus,
          width: 140,
        },
        tab !== 'project' && {
          header: 'Availability',
          align: 'right',
          render: renderAvailablitity,
          width: 90,
        },
        {
          header: 'Skill Leader',
          align: 'center',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          header: 'Last Rating',
          render: ({row}) => {
            const colors = useTheme('colors');
            if (row?.assigned_to?.project_rating) {
              const {project_rating} = row?.assigned_to;
              if (project_rating == 'A+') {
                return (
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'CE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'A') {
                return (
                  <Chip
                    color={colors.ACCENT6_LOW}
                    textColor={colors.ACCENT6_HIGH}
                    value={'OE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'B+') {
                return (
                  <Chip
                    color={colors.ACCENT1_LOW}
                    textColor={colors.ACCENT1_HIGH}
                    value={'ME'}
                    borderRadius={6}
                  />
                );
              }

              if (project_rating == 'B') {
                return (
                  <Chip
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'PE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'C') {
                return (
                  <Chip
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'DE'}
                    borderRadius={6}
                  />
                );
              }
            }
          },
          width: 100,
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate(
                    fromInternalResourceRequest
                      ? 'internal-allocation'
                      : 'project-order-resource-assignment',
                    {
                      row,
                      projectDetail,
                      OrderResourceRequest,
                    },
                  );
                }}
                style={{...rowText, color: '#007AFF'}}>
                Allocate
              </Text>
            );
          },
        },
      ]}
    />
  );
};

export const ProjectAllocateResourceTab = props => {
  const {route: {params} = {}} = props;
  const {allocateResourceFilter = {}, fromInternalResourceRequest = false} =
    params;
  const defaultFilter = {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {...defaultFilter, ...allocateResourceFilter},
  });
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  // filter.is_manager = false;
  const tabs = {
    bench: {
      label: 'Bench',
      view: (
        <BenchResources
          {...props}
          addOnParams={{...params, is_AllBenchEmployee: true}}
          search={searchValue}
          period={period}
          tab="Bench"
          addOnFilter={{
            ...filter,
          }}
          api="/employees/bench"
          fromInternalResourceRequest={fromInternalResourceRequest}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      params: {...params, is_AllBenchEmployee: true},
      limit: 2000,
    },

    project: {
      label: 'Project',
      view: (
        <BenchResources
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="project"
          addOnFilter={{
            ...filter,
            project_type: {$in: ['tm', 'f']},
          }}
          api="/employees/internalresources"
          fromInternalResourceRequest={fromInternalResourceRequest}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/internalresources',
      addOnFilter: {
        ...filter,
        project_type: {$in: ['tm', 'f']},
      },
      params: {...params, period},
      limit: 2000,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
      ]}
    />
  );
};
