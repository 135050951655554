import React from 'react';
import {TabView} from '../../../components/tab';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {CompletedTasksTable, CompletedTeamTasksTable} from '../../task/views/TasksTable';
import {taskFilters, useTaskActions} from '../../task/views/TaskActions';
import {TeamEfficiencyTable} from '../../task/views/EfficiencyTable';

export const TeamTaskOutputTabs = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {employee_team} = params;

  const {
    activityLogs,
    showOutput,
    markDisqualified,
    markQualified,
    markIncomplete,
  } = useTaskActions({
    navigation,
    params,
  });
  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
    });
  };

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {params: filterParams, filter: extraFilter} = filterValues;

  const addOnFilter = {
    type: {
      $in: ['Task', 'Continuous'],
    },
    ...extraFilter,
  };

  if (filterParams.is_open == 'Yes') {
    addOnFilter.assigned_to = null;
  } else if (filterParams.is_open == 'No') {
    addOnFilter.assigned_to = {$exists: true};
  }

  return (
    <TabView
      tabs={{
        completed: {
          label: 'All',
          view:
            <CompletedTeamTasksTable
              isCompleted
              employee_team={employee_team}
              onRowPress={taskDetail}
              searchValue={searchValue}
              addOnFilter={{
                status: 'completed',
                $or: [
                  {
                    review_pending: false,
                  },
                  {
                    review_not_required: true,
                  },
                ],
                ...addOnFilter,
              }}
              moreActions={[
                // markIncomplete,
                markDisqualified,
                markQualified,
                showOutput,
                activityLogs,
              ]}
            />,
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                taskFilters.assigned_to,
                taskFilters.reviewer,
                taskFilters.custom,
                taskFilters.strategic,
                taskFilters.due_date,
                taskFilters.completed_on,
                taskFilters.source,
              ]}
            />,
          ],
        },
        output: {
          label: 'Output',
          view: <TeamEfficiencyTable {...props} />,
        },
      }}
      {...props}
    /> 
  );
};
