import {useInvoke} from '../../../controllers/useInvoke';

const _INTERVIEWROUNDCONSTANTS = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
};

const getInterviewDetails = ({status, current_round = 0}) => {
  let currentRound, nextRound;
  currentRound = _INTERVIEWROUNDCONSTANTS[current_round];
  switch (status) {
    case 'InterviewRound':
      nextRound = current_round + 1;
      nextRound = _INTERVIEWROUNDCONSTANTS[nextRound];
      break;
    case 'HrRound':
      nextRound = 'Hr';
      break;
  }

  return {
    currentRound,
    nextRound,
  };
};

const dropCandidate = ({navigation}) => ({
  title: 'Drop',
  variant: 'error',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`drop-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      candidate_previous_status: row?.status,
    });
  },
});

const addLOIDetails = ({navigation}) => {
  return {
    title: 'Add LOI Details',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`add-loi`, {
        candidate: row,
      });
    },
    visible: ({row}) => row?.status == 'Offered',
  };
};

const rejectCandidate = ({navigation}) => ({
  title: 'Reject',
  variant: 'error',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`reject-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      candidate_previous_status: row?.status,
    });
  },
});

const putOnHoldCandidate = ({navigation}) => ({
  title: 'Put on Hold',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`hold-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      candidate_previous_status: row?.status,
    });
  },
});

const changeRecruiterOfCandidate = ({navigation}) => ({
  title: 'Change Recruiter',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`change-recruiter-of-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      candidate_previous_recruiter: row?.recruiter,
    });
  },
});

const moveCandidateToFirstRound = ({navigation}) => ({
  title: 'Moving to First Round',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`moving-candidate-to-first-round`, {
      candidateId: row?._id,
      candidateName: row?.name,
      candidate_resume: row?.candidate_resume,
    });
  },
});

const moveCandidateToNextRound = ({navigation}) => ({
  title: 'Moving to Next Round',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`moving-candidate-to-next-round`, {
      candidateId: row?._id,
      candidateName: row?.name,
      current_round: row?.current_round,
      status: row?.status,
    });
  },
});

const moveCandidateToHrRound = ({navigation}) => ({
  title: 'Moving to Hr Round',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`moving-candidate-to-hr-round`, {
      candidateId: row?._id,
      candidateName: row?.name,
      current_round: row?.current_round,
      status: row?.status,
    });
  },
});

const changeInterviewDateOfCandidate = ({navigation}) => ({
  title: 'Change Interview Date',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`change-interview-date-of-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      current_round: row?.current_round,
      status: row?.status,
      first_round_interview_date: row?.first_round_interview_date,
      second_round_interview_date: row?.second_round_interview_date,
      third_round_interview_date: row?.third_round_interview_date,
      fourth_round_interview_date: row?.fourth_round_interview_date,
      fifth_round_interview_date: row?.fifth_round_interview_date,
      hr_round_interview_date: row?.hr_round_interview_date,
    });
  },
});

const changeInterviewTimeOfCandidate = ({navigation}) => ({
  title: 'Change Interview Time',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`change-interview-time-of-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      current_round: row?.current_round,
      status: row?.status,
      first_round_interview_time: row?.first_round_interview_time,
      second_round_interview_time: row?.second_round_interview_time,
      third_round_interview_time: row?.third_round_interview_time,
      fourth_round_interview_time: row?.fourth_round_interview_time,
      fifth_round_interview_time: row?.fifth_round_interview_time,
      hr_round_interview_time: row?.hr_round_interview_time,
    });
  },
});

const changeInterviewerOfCandidate = ({navigation}) => ({
  title: 'Change Interviewer',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`change-interviewer-of-candidate`, {
      candidateId: row?._id,
      candidateName: row?.name,
      current_round: row?.current_round,
      status: row?.status,
      first_round_interview_panel_members:
        row?.first_round_interview_panel_members,
      second_round_interview_panel_members:
        row?.second_round_interview_panel_members,
      third_round_interview_panel_members:
        row?.third_round_interview_panel_members,
      fourth_round_interview_panel_members:
        row?.fourth_round_interview_panel_members,
      fifth_round_interview_panel_members:
        row?.fifth_round_interview_panel_members,
      hr_round_interview_panel_members: row?.hr_round_interview_panel_members,
    });
  },
});

const detailSummaryOfAllRounds = ({navigation}) => {
  return {
    title: 'View Detail Summary of Previous Rounds',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`detail_summary_of_all_rounds`, {
        candidateId: row?._id,
        candidateName: row?.name,
      });
    },
  };
};

const detailReasonofRejection = ({navigation}) => {
  return {
    title: 'View Detail Reason of Rejection',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`detail-reason-of-rejection`, {
        candidateId: row?._id,
        candidateName: row?.name,
      });
    },
  };
};

const moveToAnotherJobOpening = ({navigation}) => {
  return {
    title: 'Move To Another Job Opening',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`move-to-another-job-opening`, {
        candidateId: row?._id,
        candidateName: row?.name,
      });
    },
  };
};

const moveToInterview = ({invoke}) => {
  return {
    title: 'Move To Interview',
    onPress: props => {
      const {row} = props;
      invoke({
        uri: '/candidates/' + row._id,
        props: {
          status: 'Interview',
        },
      });
    },
    visible: ({row}) => row?.status == 'Sortlisted',
  };
};

const detailReasonofDrop = ({navigation}) => {
  return {
    title: 'View Detail Reason of Drop',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`detail-reason-of-drop`, {
        candidateId: row?._id,
        candidateName: row?.name,
      });
    },
  };
};
const detailReasonOfMakingNurturable = ({navigation}) => {
  return {
    title: 'Detail Reason of Making Nurturable',
    onPress: props => {
      const {row} = props;
      navigation.navigate(`detail-reason-of-nurturable`, {
        candidateId: row?._id,
        candidateName: row?.name,
      });
    },
  };
};

const offerJob = ({navigation}) => {
  return {
    title: 'Offer Job',
    onPress: ({row}) => {
      navigation.navigate('offer-job', {
        candidate: row,
      });
    },
    visible: ({row}) => row?.status == 'Interview',
  };
};
const markAccepted = ({navigation}) => {
  return {
    title: 'Mark Accepted',
    onPress: ({row}) => {
      navigation.navigate('mark-candidate-accepted', {
        candidate: row,
      });
    },
    visible: ({row}) => row?.status == 'Offered',
  };
};
const markJoined = ({navigation}) => {
  return {
    title: 'Mark Joined',
    onPress: ({row}) => {
      navigation.navigate('mark-candidate-joined', {
        candidate: row,
      });
    },
    visible: ({row}) => row?.status == 'Offer Accepted',
  };
};
const markNurturable = ({navigation}) => {
  return {
    title: 'Mark Nurturable',
    onPress: ({row}) => {
      navigation.navigate('mark-candidate-nuraturable', {
        candidate: row,
      });
    },
  };
};

const changeOfferDate = ({navigation}) => {
  return {
    title: 'Offer Date',
    onPress: ({row}) => {
      navigation.navigate('change-candidate-offer-date', {
        candidate: row,
      });
    },
  };
};

const downloadLoiWithLetterHead = ({download}) => {
  return {
    title: 'Download LOI on Letter Head',
    onPress: ({row}) =>
      download({
        uri: `/downloadLoi`,
        props: {
          candidate: row,
          on_letter_head: true,
        },
      }),

    visible: ({row}) => row?.status == 'Offered',
  };
};

const downloadLoiWithoutLetterHead = ({download}) => {
  return {
    title: 'Download LOI without Letter Head',
    onPress: ({row}) =>
      download({
        uri: `/downloadLoi`,
        props: {
          candidate: row,
          on_letter_head: false,
        },
      }),

    visible: ({row}) => row?.status == 'Offered',
  };
};

const checkPackageBreakdown = ({navigation}) => {
  return {
    title: 'Check Package Breakdown',
    onPress: ({row}) => {
      navigation.navigate('check-package-breakdown', {
        candidate: row,
      });
    },
  };
};

const UnmarkNurturable = ({invoke}) => ({
  title: 'Remove From Nurturable',
  confirm: {
    title: 'unmark Nurturable',
    message: 'Do you want un-mark Nurturable',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/candidates/${row?._id}`,
      props: {
        is_nurturable: false,
      },
    });
  },
});

export {
  dropCandidate,
  rejectCandidate,
  changeRecruiterOfCandidate,
  moveCandidateToFirstRound,
  changeInterviewDateOfCandidate,
  changeInterviewerOfCandidate,
  changeInterviewTimeOfCandidate,
  putOnHoldCandidate,
  moveCandidateToNextRound,
  getInterviewDetails,
  moveCandidateToHrRound,
  detailSummaryOfAllRounds,
  offerJob,
  markAccepted,
  markJoined,
  changeOfferDate,
  addLOIDetails,
  downloadLoiWithLetterHead,
  downloadLoiWithoutLetterHead,
  checkPackageBreakdown,
  detailReasonofRejection,
  markNurturable,
  detailReasonofDrop,
  moveToAnotherJobOpening,
  detailReasonOfMakingNurturable,
  UnmarkNurturable,
  moveToInterview,
};
