import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';
import {useInvoke} from '../../../controllers/useInvoke';

export const EntityVariablesTable = props => {
  const {navigation} = props;
  const {filter, onChangeFilter, searchValue} = useFilter();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'EntityVariable',
  });

  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title={'Entity Variables'}
          actions={
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />
          }
        />
      )}
      searchFields="label"
      addOnFilter={{...filter}}
      search={searchValue}
      eventSourceId={['EntityVariable']}
      api={`/entityvariables`}
      fields={{
        name: 1,
        label: 1,
        description: 1,
        output_fields: 1,
        example_output: 1,
        category: 1,
        response_format: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`entity-variable-detail`, {
          entityId: row._id,
          entity: row,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Entity',
          width: 200,
        },
        {
          type: 'textArea',
          field: 'description',
          header: 'Description',
        },
        {
          type: 'text',
          field: 'category',
          header: 'Category',
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this?',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/entityvariables/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
