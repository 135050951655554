import React from 'react';
import {Row, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {UserAvatar} from '../avatar/UserAvatar';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {CommentStyle} from './theme';

export const CommenterAvatar = ({row}) => {
  return <UserAvatar value={row.comment_by} size={28} />;
};

export const CommenterName = ({row}) => {
  const {
    commenter: {text},
  } = useStyles(CommentStyle);
  return <Text style={text}>{row.comment_by?.name}</Text>;
};

export const Commenter = ({row}) => {
  const {
    commenter: {container, text},
  } = useStyles(CommentStyle);
  return (
    <Row style={container}>
      <UserAvatar value={row.comment_by} size={28} />
      <Text style={text}>{row.comment_by?.name}</Text>
    </Row>
  );
};

export const DateTime = ({row}) => {
  const {date_time, separator} = useStyles(CommentStyle);
  const {fonts, colors} = useTheme();

  const currentTime = moment();
  const createdAt = moment(row?.createdAt);

  const date = <Text style={date_time}>{createdAt.format('DD MMM YY')}</Text>;
  const time = <Text style={date_time}>{createdAt.format('hh:mm A')}</Text>;

  const isLessThanHour = currentTime.diff(createdAt, 'hours') < 1;

  if (isLessThanHour) {
    return (
      <Text
        style={{
          color: colors.NEUTRAL_LOW,
          ...fonts.CAPTION_LARGE,
        }}>
        {createdAt.fromNow()}
      </Text>
    );
  }

  const isToday = createdAt.isSame(currentTime, 'day');

  if (isToday) {
    return (
      <Row gap={4} style={{alignItems: 'center'}}>
        {time}
      </Row>
    );
  }

  return (
    <Row gap={4} style={{alignItems: 'center'}}>
      {date}
      <View style={separator} />
      {time}
    </Row>
  );
};

export const Comment = ({row}) => {
  return <RichText value={row.comment} />;
};
