import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {leadControllers} from '../controllers/leadControllers';
import {OutlineButton} from '../../../components/button/OutlineButton';

export const LinkedinProfileFormNew = props => {
  const {header = 'Add Profile'} = props;
  let {onSubmit} = useFormSubmit({
    uri: '/linkedinprofiles',
    eventSourceId: ['profileAdded'],
  });

  return (
    <Form
      fields={{
        email: 1,
        proxy_server: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'first_name',
          type: 'text',
          label: 'First Name',
          placeholder: 'First Name',
        },
        {
          field: 'last_name',
          type: 'text',
          label: 'Last Name',
          placeholder: 'Last Name',
        },
        {
          field: 'email',
          type: 'text',
          label: 'Email',
          placeholder: 'Email',
        },

        {
          field: 'password',
          type: 'password',
          label: 'Password',
          placeholder: 'Password',
        },

        {
          field: 'proxy_server',
          type: 'autoComplete',
          label: 'Action',
          placeholder: 'Proxy Server',
          options: ['ggn', 'hsr'],
        },
        {
          label: 'Auto Read',
          field: 'auto_read',
          type: 'autoComplete',
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
        {
          label: 'Connection Requset Limit',
          field: 'connection_request_day_limit',
          type: 'text',
        },
        {
          label: 'Direct message limit',
          field: 'direct_message_day_limit',
          type: 'text',
        },
        {
          label: 'Inmail message limit',
          field: 'inmail_message_day_limit',
          type: 'text',
        },
        {
          label: 'Follow Request limit',
          field: 'follow_request_day_limit',
          type: 'text',
        },
        {
          label: 'Degree of Connection limit',
          field: 'degree_of_connection_day_limit',
          type: 'text',
        },
        {
          label: 'Like Post limit',
          field: 'like_post_day_limit',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

const LinkedinProfileFormShort = ({header = 'Add Profile', ...props}) => {
  let {route: {params} = {}} = props || {};
  const {updateBrowser} = leadControllers(props);
  let {onSubmit} = useFormSubmit({
    uri: '/linkedinprofiles',
    eventSourceId: ['profileAdded'],
  });

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  return (
    <Form
      fields={{
        first_name: 1,
        last_name: 1,
        email: 1,
        proxy_server: 1,
        browser: 1,
        auto_read: 1,
        connection_request_day_limit: 1,
        direct_message_day_limit: 1,
        follow_request_day_limit: 1,
        degree_of_connection_day_limit: 1,
        like_post_day_limit: 1,
        inmail_message_day_limit: 1,
      }}
      onSubmit={onSubmit}
      eventSourceId={'updateBrowser'}
      header={{
        title: header,
      }}
      footer={{
        actions: [
          <OutlineButton
            text="Set Current Browser"
            onPress={() => {
              updateBrowser({...params, windowHeight, windowWidth});
            }}
          />,
        ],
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'first_name',
          type: 'text',
          label: 'First Name',
          placeholder: 'First Name',
        },
        {
          field: 'last_name',
          type: 'text',
          label: 'Last Name',
          placeholder: 'Last Name',
        },
        {
          field: 'email',
          type: 'text',
          label: 'Email',
          placeholder: 'Email',
        },

        {
          field: 'proxy_server',
          type: 'autoComplete',
          label: 'Action',
          placeholder: 'Proxy Server',
          options: ['ggn', 'hsr'],
        },
        {
          field: 'otp',
          type: 'text',
          label: 'Otp',
          placeholder: 'Otp',
        },
        {
          field: 'browser',
          type: 'text',
          label: 'Browser',
          placeholder: 'Browser',
        },
        {
          label: 'Auto Read',
          field: 'auto_read',
          type: 'autoComplete',
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
        {
          label: 'Connection Requset Limit',
          field: 'connection_request_day_limit',
          type: 'text',
        },
        {
          label: 'Direct message limit',
          field: 'direct_message_day_limit',
          type: 'text',
        },
        {
          label: 'Inmail message limit',
          field: 'inmail_message_day_limit',
          type: 'text',
        },
        {
          label: 'Follow Request limit',
          field: 'follow_request_day_limit',
          type: 'text',
        },
        {
          label: 'Degree of Connection limit',
          field: 'degree_of_connection_day_limit',
          type: 'text',
        },
        {
          label: 'Like Post limit',
          field: 'like_post_day_limit',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
export const LinkedinProfileFormEdit = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <LinkedinProfileFormShort
      mode="edit"
      api={`/linkedinprofiles/${_id}`}
      header={'Edit Profile'}
      {...props}
    />
  );
};
