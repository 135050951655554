import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {
  StyledView,
  WeekView,
  MonthView,
  StyledContainer,
  MonthContainer,
  MonthHeaderContainer,
  YearHeaderContainer,
  YearHeader,
  YearContainer,
  HeaderContainer,
  WeekContainer,
  DateContainer,
  DatesList,
  PrevNextContainer,
  StyledImage,
  YearHeaderText,
  YearText,
  WeekText,
  DateText,
  MonthYearText,
  PrevNextText,
  DateRow,
  YearRow,
  YearListContainer,
  RenderWrapper,
  MonthsRow,
  MonthText,
} from './styles/CalendarStyles';
import {Row} from '@unthinkable/react-core-components';

const PrevHandler = ({onClick, styles = {}}) => {
  const {prevIcon: icon} = styles;

  return (
    <PrevNextContainer styles={styles} onClick={onClick}>
      {icon ? (
        <StyledImage styles={styles} source={icon} />
      ) : (
        <PrevNextText styles={styles}>{'<'}</PrevNextText>
      )}
    </PrevNextContainer>
  );
};

const NextHandler = ({onClick, styles = {}}) => {
  const {nextIcon: icon} = styles;

  return (
    <PrevNextContainer styles={styles} onClick={onClick}>
      {icon ? (
        <StyledImage styles={styles} source={icon} />
      ) : (
        <PrevNextText styles={styles}>{'>'}</PrevNextText>
      )}
    </PrevNextContainer>
  );
};

const Week = ({children, primary, styles = {}}) => {
  return (
    <WeekView styles={styles} primary={primary}>
      <WeekText styles={styles} primary={primary}>
        {children}
      </WeekText>
    </WeekView>
  );
};

const DateView = ({
  children,
  deActive,
  selected,
  onChange,
  date,
  month,
  year,
  renderDate,
  styles,
  hideModal,
  select,
  ...rest
}) => {
  const onChangeHandler = e => {
    e.preventDefault();
    let value = new Date();
    value.setFullYear(year);
    value.setMonth(month, date);
    select && select(value);
    onChange && onChange(value, e);
    hideModal && hideModal();
  };

  if (renderDate) {
    const renderDateProps = {date, year, month, deActive, selected};
    if (React.isValidElement(renderDate)) {
      renderDate = React.cloneElement(renderDate, renderDateProps);
    } else if (typeof renderDate === 'function') {
      renderDate = renderDate(renderDateProps);
    }
  }

  return (
    <DateContainer
      styles={styles}
      selected={selected}
      deActive={deActive}
      onClick={onChangeHandler}>
      {renderDate || (
        <DateText
          styles={styles}
          deActive={deActive}
          selected={selected}
          {...rest}>
          {children > 9 ? children : `0${children}`}
        </DateText>
      )}
    </DateContainer>
  );
};

const Calendar = props => {
  let {
    value,
    format = 'DD MMM YY',
    onChange,
    renderDate,
    hideModal,
    styles,
    weekNames,
    monthNames,
  } = props;

  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [visibleView, setVisibleView] = useState('dateView');
  const [yearViewBase, setYearViewBase] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const currentDate = value
      ? moment(value, format).toDate()
      : moment(new Date()).toDate();
    setDate(currentDate.getDate());
    setMonth(currentDate.getMonth());
    setYear(currentDate.getFullYear());
    setYearViewBase(currentDate.getFullYear());
  }, [value, format]);

  const select = value => {
    setSelectedDate(moment(value).format('D'));
  };

  const getDates = () => {
    let dates = [];
    let currMonLstDtObj = new Date(year, month + 1, 0);
    let currMonLstDt = currMonLstDtObj.getDate();
    let currMonLstDay = currMonLstDtObj.getDay();
    let prevMonlstDtObj = new Date(year, month, 0);
    let prevMonLstDt = prevMonlstDtObj.getDate();
    let prevMonLstDay = prevMonlstDtObj.getDay();
    let perRowDate = 0;
    let dateToRender = [];

    while (prevMonLstDay >= 0 && prevMonLstDay < 6) {
      dates.unshift(
        <DateView
          styles={styles}
          deActive
          key={'prev' + prevMonLstDt}
          date={prevMonLstDt}
          renderDate={renderDate}>
          {prevMonLstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.unshift(<DateRow>{dates}</DateRow>);
        dates = [];
      }
      prevMonLstDt--;
      prevMonLstDay--;
    }

    let currMonFstDt = 1;
    const currentDate = (selectedDate && Number(selectedDate)) || date;
    while (currMonFstDt <= currMonLstDt) {
      dates.push(
        <DateView
          styles={styles}
          key={currMonFstDt}
          selected={currentDate === currMonFstDt}
          date={currMonFstDt}
          month={month}
          year={year}
          value={value}
          onChange={onChange}
          hideModal={hideModal}
          select={select}
          renderDate={renderDate}>
          {currMonFstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(<Row>{dates}</Row>);
        dates = [];
      }
      currMonFstDt++;
    }
    currMonLstDay = 7 - currMonLstDay;
    let i = 1;
    while (i < currMonLstDay) {
      dates.push(
        <DateView
          styles={styles}
          deActive
          key={'next' + i}
          date={i}
          renderDate={renderDate}>
          {i}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(<Row>{dates}</Row>);
        dates = [];
      }
      i++;
    }

    return <React.Fragment>{dateToRender}</React.Fragment>;
  };

  const onDateChange = params => {
    props.onDateChange && props.onDateChange(params);
  };

  const nextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    // onDateChange({month, year});
  };

  const prevMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    // onDateChange({month, year});
  };

  const onMonthClick = month => {
    setMonth(month);
    setVisibleView('dateView');
    // onDateChange({month, year});
  };

  const onYearClick = year => {
    setYear(year);
    // onDateChange({month, year});
    setVisibleView('dateView');
  };

  const getYearsArray = () => {
    let {
      yearView: {bodyStyle: {yearViewStyle = {}, yearTextStyle = {}} = {}} = {},
    } = styles || {};
    let yearBase = yearViewBase;
    let yearArray = [];
    let yearArrayToRender = [];
    for (let i = 0; i < 3; i++) {
      yearArray[i] = [];

      for (let j = 0; j < 3; j++) {
        yearArray[i][j] = yearBase + i * 3 + j;
        yearArrayToRender.push(
          <YearListContainer
            onClick={() => onYearClick(yearArray[i][j])}
            containerStyle={yearViewStyle}>
            <YearText textStyle={yearTextStyle}>{yearArray[i][j]}</YearText>
          </YearListContainer>,
        );
      }
    }

    return yearArrayToRender;
  };

  weekNames = weekNames || ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  monthNames = monthNames || [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let render = [];

  if (visibleView === 'dateView') {
    let {
      containerStyle = {},
      header: {
        containerStyle: header_containerStyle,
        prev: prevTheme,
        next: nextTheme,
        year: {viewStyle: year_viewStyle, textStyle: year_textStyle} = {},
        month: {viewStyle: month_viewStyle, textStyle: month_textStyle} = {},
      } = {},
      body: {weekContainerStyle} = {},
    } = styles?.dataView || {};
    render = (
      <React.Fragment>
        <HeaderContainer styles={header_containerStyle}>
          <PrevHandler onClick={prevMonth} styles={prevTheme} />
          <MonthContainer
            styles={month_viewStyle}
            onClick={() => setVisibleView('monthView')}>
            <MonthYearText styles={month_textStyle}>
              {monthNames[month]}
            </MonthYearText>
          </MonthContainer>
          <YearContainer
            styles={year_viewStyle}
            onClick={() => setVisibleView('yearView')}>
            <MonthYearText styles={year_textStyle}>{year}</MonthYearText>
          </YearContainer>
          <NextHandler onClick={nextMonth} styles={nextTheme} />
        </HeaderContainer>
        <WeekContainer containerStyle={weekContainerStyle}>
          <Week styles={styles} primary>
            {weekNames[0]}
          </Week>
          <Week styles={styles}>{weekNames[1]}</Week>
          <Week styles={styles}>{weekNames[2]}</Week>
          <Week styles={styles}>{weekNames[3]}</Week>
          <Week styles={styles}>{weekNames[4]}</Week>
          <Week styles={styles}>{weekNames[5]}</Week>
          <Week styles={styles}>{weekNames[6]}</Week>
        </WeekContainer>
        <DatesList>{getDates()}</DatesList>
      </React.Fragment>
    );
    return (
      <StyledContainer containerStyle={containerStyle}>
        {render}
      </StyledContainer>
    );
  } else if (visibleView === 'monthView') {
    let {
      containerStyle,
      header: {
        containerStyle: header_containerStyle,
        prev: prevTheme,
        next: nextTheme,
        year: {viewStyle: year_viewStyle, textStyle: year_textStyle} = {},
      } = {},
      bodyStyle: {monthRowStyle, monthTextStyle, monthViewStyle} = {},
    } = styles?.monthView || {};
    let render = (
      <React.Fragment>
        <MonthHeaderContainer containerStyle={header_containerStyle}>
          <PrevHandler
            styles={prevTheme}
            onClick={() => {
              setYear(year - 1);
              setYearViewBase(year - 1);
            }}
          />
          <YearHeaderContainer
            containerStyle={year_viewStyle}
            onClick={() => setVisibleView('year')}>
            <YearHeaderText textStyle={year_textStyle}>{year}</YearHeaderText>
          </YearHeaderContainer>
          <NextHandler
            styles={nextTheme}
            onClick={() => {
              setYear(year + 1);
              setYearViewBase(year + 1);
            }}
          />
        </MonthHeaderContainer>
        <MonthsRow containerStyle={monthRowStyle}>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(0)}>
            <MonthText textStyle={monthTextStyle}>{'Jan'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(1)}>
            <MonthText textStyle={monthTextStyle}>{'Feb'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(2)}>
            <MonthText textStyle={monthTextStyle}>{'Mar'}</MonthText>
          </MonthView>
        </MonthsRow>
        <MonthsRow containerStyle={monthRowStyle}>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(3)}>
            <MonthText textStyle={monthTextStyle}>{'Apr'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(4)}>
            <MonthText textStyle={monthTextStyle}>{'May'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(5)}>
            <MonthText textStyle={monthTextStyle}>{'Jun'}</MonthText>
          </MonthView>
        </MonthsRow>
        <MonthsRow containerStyle={monthRowStyle}>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(6)}>
            <MonthText textStyle={monthTextStyle}>{'July'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(7)}>
            <MonthText textStyle={monthTextStyle}>{'Aug'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(8)}>
            <MonthText textStyle={monthTextStyle}>{'Sep'}</MonthText>
          </MonthView>
        </MonthsRow>
        <MonthsRow containerStyle={monthRowStyle}>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(9)}>
            <MonthText textStyle={monthTextStyle}>{'Oct'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(10)}>
            <MonthText textStyle={monthTextStyle}>{'Nov'}</MonthText>
          </MonthView>
          <MonthView
            containerStyle={monthViewStyle}
            onClick={() => onMonthClick(11)}>
            <MonthText textStyle={monthTextStyle}>{'Dec'}</MonthText>
          </MonthView>
        </MonthsRow>
      </React.Fragment>
    );

    return <StyledView containerStyle={containerStyle}>{render}</StyledView>;
  } else {
    let {
      containerStyle = {},
      header: {
        containerStyle: header_containerStyle = {},
        prev: prevTheme,
        next: nextTheme,
        year: {
          viewStyle: year_viewStyle = {},
          textStyle: year_textStyle = {},
        } = {},
      } = {},
      bodyStyle: {yearRowStyle, container: body_container} = {},
    } = styles?.yearView || {};
    let yearArray = getYearsArray();
    let yearArrayItems = [];
    let yearArrayPerRow = [];
    yearArray.map(year => {
      yearArrayItems.push(year);
      if (yearArrayItems.length === 3) {
        yearArrayPerRow.push(
          <YearRow containerStyle={yearRowStyle}>{yearArrayItems}</YearRow>,
        );
        yearArrayItems = [];
      }
    });
    let render = (
      <React.Fragment>
        <YearHeader containerStyle={header_containerStyle}>
          <PrevHandler
            styles={prevTheme}
            onClick={() => setYearViewBase(yearViewBase - 9)}
          />
          <YearHeaderContainer containerStyle={year_viewStyle}>
            <YearHeaderText textStyle={year_textStyle}>
              {'Select Year'}
            </YearHeaderText>
          </YearHeaderContainer>
          <NextHandler
            styles={nextTheme}
            onClick={() => setYearViewBase(yearViewBase + 9)}
          />
        </YearHeader>
        <StyledView containerStyle={body_container}>
          {yearArrayPerRow}
        </StyledView>
      </React.Fragment>
    );
    return (
      <RenderWrapper containerStyle={containerStyle}>{render}</RenderWrapper>
    );
  }
};

export default Calendar;
