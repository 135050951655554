import React from 'react';
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {breadcrumbStyles} from './theme';
import {IconButton} from '../button/IconButton';
import {WithModal} from '@unthinkable/react-popper';
import {
  BreadCrumbText,
  BreadCrumbTitleTextContainer,
  ItemContainer,
} from './styles';

export const BreadcrumbItem = ({
  styles,
  icon,
  title,
  Component,
  children,
  onPress,
  maxWidth = 300,
}) => {
  let itemComponent = children || Component;
  if (!itemComponent) {
    itemComponent = (
      <BreadCrumbTitleTextContainer
        title={title}
        styles={{maxWidth}}
        hoverColor={styles?.itemHoverColor}>
        {icon ? <Image source={icon} style={styles.icon} /> : void 0}
        {title ? (
          <BreadCrumbText numberOfLines={1} styles={styles}>
            {title}
          </BreadCrumbText>
        ) : (
          void 0
        )}
      </BreadCrumbTitleTextContainer>
    );
  }
  if (onPress) {
    itemComponent = (
      <TouchableOpacity styles={styles.itemContainer} onPress={onPress}>
        {itemComponent}
      </TouchableOpacity>
    );
  }
  return itemComponent;
};

export const BreadcrumbSeparator = ({styles, separator = '/'}) => {
  return <Text style={styles.separator}>{separator}</Text>;
};

export const BreadcrumbsComponent = ({
  styles,
  gap = 6,
  items,
  separator,
  SeparatorComponent = BreadcrumbSeparator,
  children,
  startItemsCount,
  endItemsCount,
  modalPosition,
  modalPlacement = 'start',
}) => {
  if (!items && children) {
    items = React.Children.toArray(children);
  }
  items = items?.filter(item => item);
  if (!items?.length) {
    return null;
  }
  const totalItems = items.length;
  const startItems = items.slice(0, Math.min(startItemsCount, totalItems));
  const endItems = items.slice(
    Math.max(totalItems - endItemsCount, startItemsCount),
  );

  const middleItemsStart = Math.min(startItemsCount, totalItems);
  const middleItemsEnd = Math.max(totalItems - endItemsCount, startItemsCount);
  const middleItems = items.slice(middleItemsStart, middleItemsEnd);

  const renderModal = () => {
    return (
      <ScrollView>
        {middleItems.map((item, index) => {
          let itemComponent = item;
          if (!React.isValidElement(item)) {
            itemComponent = <BreadcrumbItem {...item} styles={styles} />;
          }
          return (
            <ItemContainer index={index} styles={styles}>
              {itemComponent}
            </ItemContainer>
          );
        })}
      </ScrollView>
    );
  };

  let threeDotComponent = [];
  if (middleItems.length > 0) {
    threeDotComponent = [
      <WithModal
        position={modalPosition}
        placement={modalPlacement}
        styles={styles?.modal}
        renderModal={renderModal}>
        <IconButton icon={styles?.threeDot} />
      </WithModal>,
    ];
  }
  let newItems = [...startItems, ...threeDotComponent, ...endItems];

  return (
    <Row
      style={{
        flex: 1,
        overflow: 'hidden',
        alignItems: 'center',
        gap,
        ...styles.container,
      }}>
      {newItems.map((item, index) => {
        let itemComponent = item;
        if (!React.isValidElement(item)) {
          itemComponent = <BreadcrumbItem {...item} styles={styles} />;
        }
        return (
          <>
            {index > 1 ? (
              <SeparatorComponent seperator={separator} styles={styles} />
            ) : (
              void 0
            )}
            {itemComponent}
          </>
        );
      })}
    </Row>
  );
};

export const Breadcrumbs = props => {
  const styles = useStyles(breadcrumbStyles);
  return <BreadcrumbsComponent {...props} styles={styles} />;
};
