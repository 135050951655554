import React from 'react';
import {
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const OpenInBrowseButton = ({
  url,
  openInBrowserAbsoluteContainerStyle,
  OpenInBrowseButtonStyle,
}) => {
  const {openInBrowserContainerStyle, openInBrowserTextStyle} =
    OpenInBrowseButtonStyle || {};
  return (
    <View style={openInBrowserAbsoluteContainerStyle}>
      <TouchableOpacity
        onPress={() => {
          url && Linking.openURL(url, '_blank');
        }}
        style={openInBrowserContainerStyle}>
        <Text style={openInBrowserTextStyle}>Open in browser</Text>
      </TouchableOpacity>
    </View>
  );
};
