import {ModalViewStyles} from '../../modal/theme';
import {InputStyles} from '../text/theme';

export const DateInputStyles = ({theme}) => {
  const {colors, fonts, shadows, icons} = theme;
  return {
    ...InputStyles({theme}),
    selectorContainer: {},
    selectorIconContainer: {},
    selectorIcon: {},
    crossIcon: {},
    modalContainerNative: {},
    modal: {
      ...ModalViewStyles({theme}),
      sm: {
        container: {
          backgroundColor: colors.BACKGROUND,
          padding: 10,
          width: '100%',
          height: '50%',
          ...shadows.M,
        },
      },
      md: {
        container: {
          backgroundColor: colors.BACKGROUND,
          padding: 10,
          width: 300,
          height: 270,
          ...shadows.M,
        },
      },
    },

    calendarStyle: {
      dataView: {
        header: {
          containerStyle: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          prev: {
            prevIcon: void 0,
            imageStyle: {},
            viewStyle: {
              width: 20,
              height: 20,
              backgroundColor: colors.ACCENT1_LOW,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
            },
            textStyle: {
              color: colors.NEUTRAL_HIGH,
            },
          },
          next: {
            nextIcon: void 0,
            imageStyle: {},
            viewStyle: {
              width: 20,
              height: 20,
              backgroundColor: colors.ACCENT1_LOW,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
            },
            textStyle: {
              color: colors.NEUTRAL_HIGH,
            },
          },
          year: {
            viewStyle: {
              cursor: 'pointer',
              flex: 1,
              padding: 5,
              justifyContent: 'center',
              alignItems: 'center',
            },
            textStyle: {
              ...fonts.BODY1,
              color: colors.NEUTRAL_HIGH,
            },
          },
          month: {
            viewStyle: {
              flex: 1,
              cursor: 'pointer',
              padding: 5,
              justifyContent: 'center',
              alignItems: 'center',
            },
            textStyle: {
              ...fonts.BODY1,
              color: colors.NEUTRAL_HIGH,
            },
          },
        },
        body: {
          weekContainerStyle: {
            flexDirection: 'row',
            paddingTop: 5,
            alignItems: 'center',
          },
        },
      },
      monthView: {
        containerStyle: {
          paddingTop: 10,
          paddingBottom: 10,
          ...fonts.BODY1,
        },
        header: {
          containerStyle: {
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'space-around',
          },
          prev: {
            prevIcon: void 0,
            imageStyle: {},
            viewStyle: {
              width: 20,
              height: 20,
              backgroundColor: colors.ACCENT1_LOW,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
            },
            textStyle: {
              color: colors.NEUTRAL_HIGH,
            },
          },
          next: {
            nextIcon: void 0,
            imageStyle: {},
            viewStyle: {
              width: 20,
              height: 20,
              backgroundColor: colors.ACCENT1_LOW,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
            },
            textStyle: {
              color: colors.NEUTRAL_HIGH,
            },
          },
          year: {
            viewStyle: {
              cursor: 'pointer',
              flex: 1,
              padding: 5,
              justifyContent: 'center',
              alignItems: 'center',
            },
            textStyle: {
              ...fonts.BODY1,
              color: colors.NEUTRAL_HIGH,
            },
          },
        },
        bodyStyle: {
          monthRowStyle: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            cursor: 'pointer',
          },
          monthTextStyle: {
            padding: 7,
            ...fonts.BODY3,
            color: colors.NEUTRAL_HIGH,
          },
          monthViewStyle: {
            cursor: 'pointer',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      },
      yearView: {
        containerStyle: {
          paddingTop: 10,
          paddingBottom: 10,
          ...fonts.BODY1,
        },
        header: {
          containerStyle: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          prev: {
            prevIcon: void 0,
            imageStyle: {},
            viewStyle: {
              width: 20,
              height: 20,
              backgroundColor: colors.ACCENT1_LOW,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
            },
            textStyle: {
              color: colors.NEUTRAL_HIGH,
            },
          },
          next: {
            nextIcon: void 0,
            imageStyle: {},
            viewStyle: {
              width: 20,
              height: 20,
              backgroundColor: colors.ACCENT1_LOW,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
            },
            textStyle: {
              color: colors.NEUTRAL_HIGH,
            },
          },
          year: {
            viewStyle: {
              cursor: 'pointer',
              flex: 1,
              padding: 5,
              justifyContent: 'center',
              alignItems: 'center',
            },
            textStyle: {
              ...fonts.BODY1,
              color: colors.NEUTRAL_HIGH,
            },
          },
        },
        bodyStyle: {
          // container: {justifyContent: 'space-around', alignItems: 'center'},
          yearRowStyle: {
            flexDirection: 'row',
            cursor: 'pointer',
            justifyContent: 'space-between',
          },
          yearTextStyle: {
            padding: 7,
            ...fonts.BODY3,
            color: colors.NEUTRAL_HIGH,
          },
          yearViewStyle: {
            cursor: 'pointer',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      },
      dateStyle: {
        viewStyle: {
          flex: 1,
          paddingTop: 5,
          paddingBottom: 5,
          // minWidth: 15,
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        },
        deActiveViewStyle: {},
        selectedViewStyle: {},
        textStyle: {
          paddingLeft: 5,
          paddingRight: 5,
          whiteSpace: 'pre-wrap',
          ...fonts.BODY3,
          color: colors.NEUTRAL_HIGH,
        },
        deActiveTextStyle: {
          color: colors.NEUTRAL_LOW,
        },
        selectedTextStyle: {
          color: colors.ACCENT1_HIGH,
          borderBottomStyle: 'solid',
          borderBottomColor: colors.ACCENT1_HIGH,
          borderBottomWidth: 2,
        },
      },
      weekComponentStyle: {
        viewStyle: {
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          padding: 5,
          cursor: 'pointer',
        },
        textStyle: {
          textAlign: 'center',
          color: colors.NEUTRAL_MEDIUM,
          ...fonts.BODY1,
        },
        primaryViewStyle: {},
        primaryTextStyle: {
          color: colors.ERROR_HIGH,
        },
      },
      timeViewStye: {
        seperatorStye: {
          width: '100%',
          height: 1,
          backgroundColor: '#97979766',
          marginBottom: 5,
        },
        timeContainerStyle: {flexDirection: 'row', alignItems: 'center'},
        itemStyle: {paddingLeft: 5, alignItems: 'center'},
        mainStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 6,
        },
      },
    },
  };
};