import React from 'react';

import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {
  FnfAccountsDraftList,
  FnfHrDraftList,
  FnfOverdueList,
  FnfOverdueNewList,
  FnfPaymentClosedList,
  FnfPaymentPendingList,
  FnfRecoveryPendingList,
  FnfSharedWithList,
  FnfToBeSharedList,
} from './F&FLists';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const FnFHrTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.pending = {
    label: 'Creation Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      fnf_status: 'Un-Paid',
      fnf_file: {$exists: false},
      is_cancelled: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfHrDraftList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.tobeshared = {
    label: 'To be shared',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      fnf_status: 'Un-Paid',
      fnf_file: {$exists: true},
      is_cancelled: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfToBeSharedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="hr"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.shared = {
    label: 'Acceptance Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      fnf_status: 'Shared',
      is_cancelled: {$in: [null, false]},
      recovery_pending: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfSharedWithList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="hr"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };

  tabs.payment_pending = {
    label: 'Payment Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      fnf_status: 'Payment Pending',
      is_cancelled: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfPaymentPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };

  tabs.recovery_pending = {
    label: 'Recovery Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      fnf_status: 'Shared',
      is_cancelled: {$in: [null, false]},
      recovery_pending: true,
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfRecoveryPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="hr"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };

  tabs.overdue = {
    label: 'Overdue',
    api: '/fnf/overdue',
    params: {
      ...params,
    },
    filter: {
      recovery_pending: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfOverdueNewList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="hr"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.paid = {
    label: 'Closed',
    // api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      fnf_status: 'Paid',
      is_cancelled: {$in: [null, false]},
      isOverdue: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfPaymentClosedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'autoComplete',
            label: 'TAT',
            options: ['On time', 'Delay'],
            placeholder: 'Select',
            field: 'fnf_tat',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };

  // tabs.closed_overdue = {
  //   label: 'Closed Overdue',
  //   api: '/fnf/hr',
  //   params: {
  //     ...params,
  //   },
  //   filter: {
  //     isOverdue: true,
  //     is_cancelled: {$in: [null, false]},
  //     ...filter,
  //   },
  //   limit: 5000,
  //   eventSourceId: 'NoduesHr',
  //   view: (
  //     <FnfOverdueList
  //       {...props}
  //       search={searchValue}
  //       addOnFilter={filter}
  //       filterParams={filterParams}
  //       params={params}
  //       tab="hr"
  //     />
  //   ),
  // };
  return (
    <TabView
      tabs={tabs}
      {...props}
      // actions={[
      //   <GroupFilter
      //     filterValues={filterValues}
      //     applyFilter={applyFilter}
      //     filters={[
      //       {
      //         type: 'autoComplete',
      //         label: 'Employee',
      //         api: '/employeeSuggestions',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'employee',
      //         secondarySuggestionField: 'official_email_id',
      //       },
      //       tabs.paid.label == 'Closed' && {
      //         type: 'autoComplete',
      //         label: 'Employee',
      //         api: '/employeeSuggestions',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'employee',
      //         secondarySuggestionField: 'official_email_id',
      //       },
      //     ]}
      //   />,
      // ]}
    />
  );
};

export const FnFAccountsTabs = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.pending = {
    label: 'Creation Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      fnf_status: 'Un-Paid',
      fnf_file: {$exists: false},
      is_cancelled: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfAccountsDraftList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="accounts"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.tobeshared = {
    label: 'To be shared',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    // addOnFilter: filter,
    filter: {
      fnf_status: 'Un-Paid',
      fnf_file: {$exists: true},
      is_cancelled: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfToBeSharedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="accounts"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.shared = {
    label: 'Acceptance Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      fnf_status: 'Shared',
      is_cancelled: {$in: [null, false]},
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfSharedWithList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="accounts"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };

  tabs.payment_pending = {
    label: 'Payment Due',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      is_cancelled: {$in: [null, false]},

      fnf_status: 'Payment Pending',
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfPaymentPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="accounts"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.recovery_pending = {
    label: 'Recovery pending',
    api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      fnf_status: 'Shared',
      is_cancelled: {$in: [null, false]},
      recovery_pending: true,
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfRecoveryPendingList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="accounts"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
        ]}
      />,
    ],
  };
  tabs.overdue = {
    label: 'Overdue',
    api: '/fnf/overdue',
    params: {
      ...params,
    },
    filter: {
      ...filter,
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfOverdueNewList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
        tab="accounts"
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            searchFields: ['name', 'official_email_id', 'employee_code'],
            secondarySuggestionField: 'official_email_id',
          },
        ]}
      />,
    ],
  };
  tabs.paid = {
    label: 'Closed',
    // api: '/fnf/hr',
    params: {
      ...params,
    },
    filter: {
      is_cancelled: {$in: [null, false]},
      isOverdue: {$in: [null, false]},
      fnf_status: 'Paid',
    },
    limit: 5000,
    eventSourceId: 'NoduesHr',
    view: (
      <FnfPaymentClosedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <PeriodRangeMonthWiseBreakupFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Employee',
            api: '/employeeSuggestions',
            filter: {employee_status: {$exists: true}},
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'autoComplete',
            label: 'TAT',
            options: ['On time', 'Delay'],
            placeholder: 'Select',
            field: 'fnf_tat',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };

  // tabs.closed_overdue = {
  //   label: 'Closed Overdue',
  //   api: '/fnf/hr',
  //   params: {
  //     ...params,
  //   },
  //   filter: {
  //     isOverdue: true,
  //     recovery_pending: {$in: [null, false]},
  //     is_cancelled: {$in: [null, false]},
  //   },
  //   limit: 5000,
  //   eventSourceId: 'NoduesHr',
  //   view: (
  //     <FnfOverdueList
  //       {...props}
  //       search={searchValue}
  //       addOnFilter={filter}
  //       filterParams={filterParams}
  //       params={params}
  //     />
  //   ),
  // };
  return (
    <TabView
      tabs={tabs}
      {...props}
      // actions={[
      //   <GroupFilter
      //     filterValues={filterValues}
      //     applyFilter={applyFilter}
      //     filters={[
      //       {
      //         type: 'autoComplete',
      //         label: 'Employee',
      //         api: '/employeeSuggestions',
      //         placeholder: 'Select',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'employee',
      //         secondarySuggestionField: 'official_email_id',
      //       },
      //     ]}
      //   />,
      // ]}
    />
  );
};
