import React from 'react';
import {SwitchThumb, SwitchTrack} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';

const SwitchInput = ({value, onChangeValue, readOnly, ...props}) => {
  const {styles} = props;
  return (
    <InputWrapper {...props}>
      <SwitchTrack
        styles={styles}
        value={value}
        {...(onChangeValue && {
          onPress: () => {
            onChangeValue(!value);
          },
        })}
        disabled={readOnly}
        {...props}>
        <SwitchThumb styles={styles} value={value} {...props} />
      </SwitchTrack>
    </InputWrapper>
  );
};

export default SwitchInput;
