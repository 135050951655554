import React from 'react';
import {
  DepartmentWiseEmployeeTabs,
  EmployeeList,
  EmployeeTabs,
  RoleWiseEmployeeTabs,
  SkillWiseEmployeeTabs,
} from './views/EmployeeList';
import {EmployeeTurnoverReport} from './views/EmployeeTurnoverReport';
import {
  EmployeeWithPackageForHrNewTabs,
  EmployeeWithPackageForHrTabs,
  EmployeeWithPackageTabs,
  LegalEmployees,
  OnHoldEmployeeTabs,
  RecoveryPendingEmployees,
} from './views/EmployeeWithPackage';
import {ReplacementTabs} from './views/ReplacementTab';
import {EnggBilledTab} from './views/EnggBilledTab';
import {
  BenchTab,
  DeliveryOwnersBenchTab,
  InternalTab,
  SocialTab,
  TainingTab,
} from './views/EnggNonBilledTab';
import {
  InternalProjectWiseAllocationDueTab,
  ProjectWiseAllocationDueTab,
} from '../order/views/ProjectDueAllocationsTable';
import {AllResginationTabs} from './views/ResignationTabs';
import {EnggResginationTabs} from './views/EnggResignationTabs';
import {AttritionTab} from '../recruitment/views/AttritionTabs';
import {PipResourceTabs, SelfPipResourceTabs} from './views/PipResourceTabs';
import {
  NoDuesAccountsTabs,
  NoDuesAdminTabs,
  NoDuesHRTabs,
  NoDuesHrTabs,
  NoDuesITNetworkTabs,
  NoDuesTeamTabs,
} from './views/NoduesTabs';
import {FnFAccountsTabs, FnFHrTabs} from './views/F&FTabView';
import {
  EmployeeParichayDocTabs,
  EmployeeParichayTabs,
} from './views/EmployeeParichayViews';
import {EmployeeSetupTab} from './views/EmployeeSetupTabs';
import {ReimbursementHeadTable} from './views/ReimbursementHeadTable';
import {EmployeeUserTabs} from './views/EmployeeUserTabs';
import {BlopdGroupTable} from './views/BloodGroupTable';
import {NomineeRelationTable} from './views/NomineeRelationTable';
import {EmployeeTypeTable} from './views/EmployeeTypeTable';
import {EnggAnalyticsTabs} from './views/EngineeringEmployeeAnalyticsTabs';
import {BenchComparisionReport} from './views/BenchComparisionReport';
import {BillingStatusReportTab} from './views/BillingStatusReportTab';
import {
  ProbationConfirmationHrTabs,
  ProbationConfirmationManagerTabs,
} from './views/ProbationConfirmationTabs';
import {
  InternshipConfirmationHrTabs,
  InternshipConfirmationManagerTabs,
} from './views/InternshipConfirmationTabs';
import {BackgroundVerificationTabs} from './views/BackgroundVerificationTabs';
import {BenchCostReportTab} from './views/BenchCostReport';
import {EngGrossMarginTab} from './views/EnggGrossMarginTab';
import {NonEnggTeamReport} from './views/AllNonEnggReport';
import {
  ResourceAllocationTab,
  ResourceAllocationTabDO,
} from '../order/views/ResourceAllocationTab';
import {ManagementCostTable} from './views/ManagementCostReportTable';
import {AllocatedResourceTab} from '../order/views/AllocatedResourceTab';
import {
  ExitManagementTab,
  SelfNoduesTab,
} from '../department/views/ExitManagementTab';
import {PIPSelfTrainingFeedback} from '../skills/views/TrainingFeedbackTable';
import {TeamExitManagementTab} from '../department/views/TeamExitManagementTab';
import {ProductivitySurveyTableSelf} from './views/ProductivitySurveyTableSelf';
import {ProductivitySurveyTableManager} from './views/ProductivitySurveyTableManager';
import {ProductivitySurveyTablePI} from './views/ProductivitySurveyTablePI';
import {ProjectWiseAllocationTab} from '../order/views/ProjectWiseAllocationsTab';
import {EmployeeToBeFreeTabs} from './views/EmployeeToBeFree';
import {AboutMeTab} from '../common/views/AboutMeTabs';
import {TeamTab} from '../department/views/TeamAttendanceTab';
import EmployeeAppraisalTab from '../department/views/EmployeeAppraisalTabs';
import {SelfAppraisal} from '../department/views/SelfAppraisalForm';
import {
  EmployeeClosedSelfAppraisalTable,
  EmployeeSelfAppraisalTable,
} from '../department/views/EmployeeAppraisalTable';
import PHAppraisalTab from '../department/views/PHAppraisalTab';
import {
  HRExtendAppraisalTab,
  HRHoldAppraisalTab,
  HROverdueAppraisalTab,
  HRPendingAppraisalTab,
  HRProcessedAppraisalTab,
} from '../department/views/HRAppraisalTabs';
import TeamProjectFeedbackTab from '../department/views/TeamProjectFeedbackTabs';
import RMFeedbackTab from '../department/views/ResourceManagementFeedbackTab';
import SelfProjectFeedbackTab from '../department/views/SelfManagerFeedbackTab';
import {OrderWiseAllocationsTab} from '../order/views/FixedOrderWiseAllocations';
import UHAppraisalTab from '../department/views/UHAppraisalTab';
import DepartmentTable from '../organizationTask/views/DepartmentTable';
import {ResignationActiveTable} from './views/ResignationTables';
import {getCurrentMonth} from '@unthinkable/react-filters';
import {
  DepartmentTabs,
  DepartmentWiseTable,
} from '../department/views/DepartmentWiseEmployeeTable';
import {ShiftAllowanceReportTab} from './views/ShiftAllowanceReport';
import {EmployeesReportTab} from './views/EmployeesReport';
import {DepartmentTagsWithNavigationTable} from '../department/views/DepartmentTagTable';
import {AllocationsRequestsTab} from '../order/views/AllocationRequests';
import {ResourceAllocationsReadOnly} from '../order/views/DetailResourceAllocatedList';
import ReferralBenifitTab from '../department/views/ReferralBenifitTab';
import PromotionTab from './views/EmployeePromotionTable';
import {PracticeTable} from './views/PracticeTable';
import UserKpisTable from '../organizationTask/views/UserKpisTable';
import {EmployeeForITTabs} from './views/EmployeeForIT';
import VariableKpisTable from '../organizationTask/views/VariableTable';
import {EmployeeGratuityTabs} from './views/EmployeeGratuity';

export const ScreenMapping = [
  {
    id: 'employeerm',
    view: ({user}) => {
      return <EmployeeTabs user={user} />;
    },
  },
  {
    id: 'employeeaccount',
    view: ({user}) => {
      return <EmployeeWithPackageTabs user={user} />;
    },
  },
  {
    id: 'employeehr',
    view: ({user}) => {
      return <EmployeeWithPackageForHrTabs user={user} />;
    },
  },
 
  {
    id: 'onholdemployee',
    view: ({user}) => {
      return <OnHoldEmployeeTabs user={user} />;
    },
  },
  {
    id: 'legalTeam',
    view: ({user}) => {
      return <LegalEmployees user={user} />;
    },
  },
  {
    id: 'recoverypending',
    view: ({user}) => {
      return <RecoveryPendingEmployees user={user} />;
    },
  },
  {
    id: 'employeeturnoverreport',
    view: ({user}) => {
      return <EmployeeTurnoverReport user={user} />;
    },
  },
  {
    id: 'requests',
    view: ({user}) => {
      return <ProjectWiseAllocationDueTab user={user} />;
    },
  },
  {
    id: 'request',
    view: ({user}) => {
      return <ReplacementTabs user={user} />;
    },
  },
  {
    id: 'aboutme',
    view: ({user}) => {
      return <AboutMeTab user={user} />;
    },
  },
  {
    id: 'employeedepartments',
    view: props => {
      return <DepartmentTabs {...props} />;
    },
  },
  {
    id: 'reportingToTeam',
    view: ({user}) => {
      return <TeamTab user={user} />;
    },
  },
  {
    id: 'resourcewise',
    view: <AllocatedResourceTab />,
  },
  {
    id: 'resourceallocationreadonly',
    view: <ResourceAllocationsReadOnly />,
  },
  {
    id: 'projectWise',
    view: ({user}, props) => {
      return <ProjectWiseAllocationTab user={user} {...props} />;
    },
  },
  {
    id: 'customers',
    view: ({user}) => {
      return <EnggBilledTab user={user} />;
    },
  },
  {
    id: 'socials',
    view: ({user}) => {
      return <SocialTab user={user} />;
    },
  },
  {
    id: 'internals',
    view: ({user}) => {
      return <InternalTab user={user} />;
    },
  },
  {
    id: 'trainings',
    view: ({user}) => {
      return <TainingTab user={user} />;
    },
  },
  {
    id: 'benchresource',
    view: ({user}, props) => {
      return <BenchTab user={user} fromICMenu={true} {...props} />;
    },
  },
  {
    id: 'employeetobefree',
    view: ({user}, props) => {
      return <EmployeeToBeFreeTabs user={user} {...props} />;
    },
  },
  {
    id: 'deliveryownersbench',
    view: ({user}) => {
      return <DeliveryOwnersBenchTab user={user} fromICMenu={true} />;
    },
  },
  {
    id: 'benchcomparison',
    view: ({user}, props) => {
      return <BenchComparisionReport user={user} {...props} />;
    },
  },
  {
    id: 'engggrossmargin',
    view: props => {
      return <EngGrossMarginTab {...props} />;
    },
  },
  {
    id: 'nonenggteamreport',
    view: props => {
      return <NonEnggTeamReport {...props} />;
    },
  },
  {
    id: 'allocationreport',
    view: ({user}) => {
      return <ResourceAllocationTab user={user} />;
    },
  },
  {
    id: 'allocationreportdo',
    view: ({user}) => {
      return <ResourceAllocationTabDO user={user} />;
    },
  },
  {
    id: 'benchcost',
    view: ({user}, props) => {
      return <BenchCostReportTab user={user} {...props} />;
    },
  },
  {
    id: 'managementcost',
    view: ({user}) => {
      return <ManagementCostTable user={user} />;
    },
  },
  {
    id: 'billingstatusreport',
    view: ({user}, props) => {
      return <BillingStatusReportTab user={user} {...props} />;
    },
  },
  {
    id: 'analytics',
    view: ({user}, props) => {
      return <EnggAnalyticsTabs user={user} {...props} />;
    },
  },
  {
    id: 'probationhr',
    view: props => {
      return <ProbationConfirmationHrTabs {...props} />;
    },
  },
  {
    id: 'internshiphr',
    view: props => {
      return <InternshipConfirmationHrTabs {...props} />;
    },
  },
  {
    id: 'probationteam',
    view: props => {
      return <ProbationConfirmationManagerTabs {...props} />;
    },
  },
  {
    id: 'internshipteam',
    view: props => {
      return <InternshipConfirmationManagerTabs {...props} />;
    },
  },
  {
    id: 'phappraisal',
    view: props => {
      const {user} = props;
      return <PHAppraisalTab {...props} />;
    },
  },
  {
    id: 'uhappraisal',
    view: props => {
      const {user} = props;
      return <UHAppraisalTab {...props} />;
    },
  },
  {
    id: 'pending',
    view: props => <HRPendingAppraisalTab {...props} />,
  },
  {
    id: 'overdue',
    view: props => <HROverdueAppraisalTab {...props} />,
  },
  {
    id: 'hold',
    view: props => <HRHoldAppraisalTab {...props} />,
  },
  {
    id: 'extend',
    view: props => <HRExtendAppraisalTab {...props} />,
  },
  {
    id: 'processed',
    view: props => <HRProcessedAppraisalTab {...props} />,
  },
  {
    id: 'teamperformances',
    view: props => {
      const {user} = props;
      return <EmployeeAppraisalTab {...props} />;
    },
  },
  {
    id: 'performancemanagement',
    view: props => {
      const {user} = props;
      return (
        <EmployeeSelfAppraisalTable
          reporting_to={user && user.employee && user.employee._id}
        />
      );
    },
  },
  {
    id: 'previousappraisal',
    view: props => {
      const {user} = props;
      return (
        <EmployeeClosedSelfAppraisalTable
          reporting_to={user && user.employee && user.employee._id}
        />
      );
    },
  },
  {
    id: 'piphr',
    view: props => {
      return <PipResourceTabs {...props} />;
    },
  },
  {
    id: 'pipteam',
    view: ({user}) => {
      const {employee} = user;
      return (
        <SelfPipResourceTabs
        // addOnFilter={{status: 'completed', mentor: employee?._id}}
        />
      );
    },
  },
  {
    id: 'selfpip',
    view: props => {
      return <PIPSelfTrainingFeedback {...props} />;
    },
  },
  {
    id: 'allresignation',
    view: props => {
      return <AllResginationTabs {...props} />;
    },
  },
  {
    id: 'enggresignation',
    view: props => {
      return <EnggResginationTabs {...props} />;
    },
  },
  {
    id: 'teamresignationreqests',
    view: ({user}) => {
      return <TeamExitManagementTab user fromMyView={true} />;
    },
  },
  {
    id: 'myresignation',
    view: ({user}) => {
      return <ExitManagementTab user={user} fromMyView={true} />;
    },
  },
  {
    id: 'analyticsresignation',
    view: props => {
      return <AttritionTab {...props} />;
    },
  },
  {
    id: 'noduesall',
    view: ({user}) => {
      return <NoDuesHrTabs user={user} />;
    },
  },
  {
    id: 'teamnodues',
    view: ({user}) => {
      return <NoDuesTeamTabs user />;
    },
  },
  {
    id: 'noduesaccounts',
    view: ({user}) => {
      return <NoDuesAccountsTabs user={user} />;
    },
  },
  {
    id: 'nodueshr',
    view: ({user}) => {
      return <NoDuesHRTabs user={user} />;
    },
  },
  {
    id: 'noduesadmin',
    view: ({user}) => {
      return <NoDuesAdminTabs user={user} />;
    },
  },
  {
    id: 'noduesit',
    view: ({user}) => {
      return <NoDuesITNetworkTabs user={user} />;
    },
  },
  {
    id: 'selfnodues',
    view: ({user}) => {
      return <SelfNoduesTab user fromMyView={true} />;
    },
  },
  {
    id: 'fnfhr',
    view: ({user}) => {
      return <FnFHrTabs user={user} />;
    },
  },
  {
    id: 'fnfaccounts',
    view: ({user}) => {
      return <FnFAccountsTabs user={user} />;
    },
  },
  {
    id: 'employeeparichays',
    view: ({user}) => {
      return <EmployeeParichayTabs user={user} />;
    },
  },
  {
    id: 'monthwisebgv',
    view: props => {
      return <BackgroundVerificationTabs {...props} />;
    },
  },
  {
    id: 'productivitysurveypi',
    view: props => {
      return <ProductivitySurveyTablePI {...props} />;
    },
  },
  {
    id: 'productivitysurveymanager',
    view: props => {
      return <ProductivitySurveyTableManager {...props} />;
    },
  },
  {
    id: 'productivitysurveyself',
    view: props => {
      return <ProductivitySurveyTableSelf {...props} />;
    },
  },
  {
    id: 'EmployeeSetup',
    view: props => {
      return <EmployeeSetupTab {...props} />;
    },
  },
  {
    id: 'ReimbursementHead',
    view: props => {
      return <ReimbursementHeadTable {...props} />;
    },
  },
  {
    id: 'EmployeeUser',
    view: props => {
      return <EmployeeUserTabs {...props} />;
    },
  },
  {
    id: 'BloodGroup',
    view: props => {
      return <BlopdGroupTable {...props} />;
    },
  },
  {
    id: 'NomineeRelation',
    view: props => {
      return <NomineeRelationTable {...props} />;
    },
  },
  {
    id: 'EmployeeType',
    view: props => {
      return <EmployeeTypeTable {...props} />;
    },
  },
  {
    id: 'practice',
    view: props => {
      return <PracticeTable {...props} />;
    },
  },
  {
    id: 'EmployeeParichayDoc',
    view: props => {
      return <EmployeeParichayDocTabs {...props} />;
    },
  },
  {
    id: 'setups',
    label: 'Setup',
  },
  {
    id: 'rmfeedback',
    view: props => {
      return <RMFeedbackTab tab="RM" />;
    },
  },
  {
    id: 'rmprojectfeedback',
    view: props => {
      return <TeamProjectFeedbackTab tab="RM" {...props} />;
    },
  },
  {
    id: 'phprojectfeedback',
    view: props => {
      const {user} = props;
      return (
        <TeamProjectFeedbackTab
          key="practice"
          practice_head={user && user.employee && user.employee._id}
          tab="Practice"
          {...props}
        />
      );
    },
  },
  {
    id: 'hrprojectfeedback',
    view: props => {
      return <TeamProjectFeedbackTab tab="HR" />;
    },
  },
  {
    id: 'hrrmfeedback',
    view: props => {
      return <RMFeedbackTab tab="HR" />;
    },
  },
  {
    id: 'teamprojectfeedback',
    view: props => {
      const {user} = props;
      return (
        <TeamProjectFeedbackTab
          reporting_to={user && user.employee && user.employee._id}
          tab="team"
          {...props}
        />
      );
    },
  },
  {
    id: 'selfprojectfeedback',
    view: props => {
      return <SelfProjectFeedbackTab {...props} />;
    },
  },
  {
    id: 'orderwise',
    view: props => {
      return <OrderWiseAllocationsTab {...props} />;
    },
  },
  {
    id: 'userallkpis',
    view: <DepartmentTable />,
  },
  {
    id: 'shiftallowancereport',
    view: <ShiftAllowanceReportTab />,
  },
  {
    id: 'employeesreport',
    view: <EmployeesReportTab />,
  },
  {
    id: 'referralbenifit',
    view: <ReferralBenifitTab />,
  },
  {
    id: 'promotiondetail',
    view: <PromotionTab />,
  },

  // {
  //   id: 'employeeincentiveschedule',
  //   view: props => {
  //     return <EmployeeIncentiveScheduleTabs {...props} />;
  //   },
  // },

  {
    id: 'all_active',
    view: <DepartmentWiseEmployeeTabs />,
  },
  {
    id: 'new_active',
    view: props => {
      let {route: {params = {}} = {}} = props || {};
      const currentMonth = getCurrentMonth();
      return (
        <EmployeeList
          addOnFilter={{
            department: params?.department,
            joining_date: {
              $gte: currentMonth.from,
              $lte: currentMonth.to,
            },
          }}
        />
      );
    },
  },
  {
    id: 'skillwise_screen',
    view: <SkillWiseEmployeeTabs />,
  },
  {
    id: 'rolewise_screen',
    view: <RoleWiseEmployeeTabs />,
  },
  {
    id: 'tagwise_screen',
    view: <DepartmentTagsWithNavigationTable />,
  },
  {
    id: 'resignations_screen',
    view: <AllResginationTabs />,
  },
  {
    id: 'pending_exits_screen',
    view: props => {
      let {route: {params = {}} = {}} = props || {};
      return (
        <ResignationActiveTable
          addOnFilter={{
            department: params?.department,
          }}
        />
      );
    },
  },
  {
    id: 'bgv_screen',
    view: <BackgroundVerificationTabs />,
  },
  {
    id: 'pip_screen',
    view: <PipResourceTabs />,
  },
  {
    id: 'appraisal_screen',
    view: <HRPendingAppraisalTab />,
  },
  {
    id: 'feedback_screen',
    view: <RMFeedbackTab />,
  },
  {
    id: 'probation_screen',
    view: <ProbationConfirmationHrTabs />,
  },
  {
    id: 'internship_screen',
    view: <InternshipConfirmationHrTabs />,
  },
  {
    id: 'allocations_screen',
    view: <AllocationsRequestsTab />,
  },
  {
    id: 'order_wise_screen',
    view: <OrderWiseAllocationsTab />,
  },
  {
    id: 'project_wise_allocation_screen',
    view: <ProjectWiseAllocationTab />,
  },
  {
    id: 'bench_do_screen',
    view: <DeliveryOwnersBenchTab />,
  },
  {
    id: 'analytics_screen',
    view: <EnggAnalyticsTabs />,
  },
  {
    id: 'engg_gross_margin_screen',
    view: <EngGrossMarginTab />,
  },
  {
    id: 'non_engg_team_report_screen',
    view: <NonEnggTeamReport />,
  },
  {
    id: 'allocation_report_screen',
    view: <ResourceAllocationTab />,
  },
  {
    id: 'employee_billing_status_screen',
    view: <BillingStatusReportTab fromDepartment={true} />,
  },
  {
    id: 'department_kpi',
    view: <UserKpisTable />,
  },
  {
    id: 'variable_kpi',
    view: <VariableKpisTable />,
  },
  {
    id: 'internal_project_wise_allocation_due_screen',
    view: <InternalProjectWiseAllocationDueTab />,
  },
  {
    id: 'employees_for_it',
    view: <EmployeeForITTabs />,
  },
  {
    id: 'employees_gratuity',
    view: <EmployeeGratuityTabs />,
  },
];

// export const Screens = [
//   {
//     menuItemId: 'employees',
//     views: [
//       {
//         id: 'employeerm',
//         label: 'All(RM)',
//         view: ({user}) => {
//           return <EmployeeTabs user={user} />;
//         },
//       },
//       {
//         id: 'employeeaccount',
//         label: 'All(Account)',
//         view: ({user}) => {
//           return <EmployeeWithPackageTabs user={user} />;
//         },
//       },
//       {
//         id: 'employeehr',
//         label: 'All(HR)',
//         view: ({user}) => {
//           return <EmployeeWithPackageForHrTabs user={user} />;
//         },
//       },
//       {
//         id: 'legalTeam',
//         label: 'Legal',
//         view: ({user}) => {
//           return <LegalEmployees user={user} />;
//         },
//       },
//       {
//         id: 'employeeturnoverreport',
//         label: 'Employee Turnover Report',
//         view: ({user}) => {
//           return <EmployeeTurnoverReport user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'employeerequests',
//     views: [
//       {
//         id: 'requests',
//         label: 'Employee Request',
//         view: ({user}) => {
//           return <ProjectWiseAllocationDueTab user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'replacementrequests',
//     views: [
//       {
//         id: 'request',
//         label: 'Replacement',
//         view: ({user}) => {
//           return <ReplacementTabs user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'aboutme',
//     views: [
//       {
//         id: 'aboutme',
//         label: 'About me',
//         view: ({user}) => {
//           return <AboutMeTab user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'reportingToTeam',
//     views: [
//       {
//         id: 'reportingToTeam',
//         label: 'Team',
//         view: ({user}) => {
//           return <TeamTab user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'employeeallocations',
//     views: [
//       {
//         id: 'resourcewise',
//         label: 'Resource Wise',
//         view: <AllocatedResourceTab />,
//       },
//       {
//         id: 'projectWise',
//         label: 'Project Wise',
//         view: ({user}, props) => {
//           return <ProjectWiseAllocationTab user={user} {...props} />;
//         },
//       },
//       {
//         id: 'customers',
//         label: 'Customer',
//         view: ({user}) => {
//           return <EnggBilledTab user={user} />;
//         },
//       },
//       // {
//       //   id: 'orderwise',
//       //   label: 'Order Wise',
//       //   view: <OrderWiseAllocationsTab />,
//       // },
//       // {
//       //   id: 'projectwise',
//       //   label: 'Project Wise',
//       //   view: <ProjectWiseAllocationTab />,
//       // },
//       {
//         id: 'socials',
//         label: 'Social',
//         view: ({user}) => {
//           return <SocialTab user={user} />;
//         },
//       },
//       {
//         id: 'internals',
//         label: 'Internal',
//         view: ({user}) => {
//           return <InternalTab user={user} />;
//         },
//       },
//       {
//         id: 'trainings',
//         label: 'Training',
//         view: ({user}) => {
//           return <TainingTab user={user} />;
//         },
//       },
//       {
//         id: 'benchs',
//         label: 'Bench',
//         section: true,
//         menus: [
//           {
//             id: 'benchresource',
//             label: 'All',
//             view: ({user}, props) => {
//               return <BenchTab user={user} fromICMenu={true} {...props} />;
//             },
//           },
//           {
//             id: 'employeetobefree',
//             label: 'Employee To Be Free',
//             view: ({user}, props) => {
//               return <EmployeeToBeFreeTabs user={user} {...props} />;
//             },
//           },
//           {
//             id: 'deliveryownersbench',
//             label: 'DO Bench',
//             view: ({user}) => {
//               return <DeliveryOwnersBenchTab user={user} fromICMenu={true} />;
//             },
//           },
//           {
//             id: 'benchcomparison',
//             label: 'Bench Comparison',
//             view: ({user}, props) => {
//               return <BenchComparisionReport user={user} {...props} />;
//             },
//           },
//         ],
//       },
//       {
//         id: 'reports',
//         label: 'Reports',
//         section: true,
//         menus: [
//           {
//             id: 'engggrossmargin',
//             label: 'Engg Gross Margin',
//             view: props => {
//               return <EngGrossMarginTab {...props} />;
//             },
//           },
//           {
//             id: 'nonenggteamreport',
//             label: 'Non Engg Team Report',
//             view: props => {
//               return <NonEnggTeamReport {...props} />;
//             },
//           },
//           {
//             id: 'allocationreport',
//             label: 'Allocation Report',
//             view: ({user}) => {
//               return <ResourceAllocationTab user={user} />;
//             },
//           },
//           {
//             id: 'benchcost',
//             label: 'Bench Cost',
//             view: ({user}, props) => {
//               return <BenchCostReportTab user={user} {...props} />;
//             },
//           },
//           {
//             id: 'managementcost',
//             label: 'Management cost',
//             view: ({user}) => {
//               return <ManagementCostTable user={user} />;
//             },
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menuItemId: 'billingstatus',
//     views: [
//       {
//         id: 'billingstatusreport',
//         label: 'DO & TL Billing Status',
//         view: ({user}, props) => {
//           return <BillingStatusReportTab user={user} {...props} />;
//         },
//       },
//       {
//         id: 'analytics',
//         label: 'Analytics',
//         view: ({user}, props) => {
//           return <EnggAnalyticsTabs user={user} {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'feedback',
//     views: [
//       {
//         id: 'employeeperformancefeedback',
//         label: 'Employee Performance Feedback',
//         view: ({user}) => {
//           return <Text>Employee Performance Feedback</Text>;
//         },
//       },
//       {
//         id: 'rmfeedback',
//         label: 'RM Feedback',
//         view: ({user}) => {
//           return <Text>RM Feedback</Text>;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'confirmation',
//     views: [
//       {
//         id: 'ConfirmationHr',
//         label: 'All',
//         section: true,
//         menus: [
//           {
//             id: 'probationhr',
//             label: 'Probation',
//             view: props => {
//               return <ProbationConfirmationHrTabs {...props} />;
//             },
//           },
//           {
//             id: 'internshiphr',
//             label: 'Internship',
//             view: props => {
//               return <InternshipConfirmationHrTabs {...props} />;
//             },
//           },
//         ],
//       },
//       {
//         id: 'ConfirmationManager',
//         label: 'Team',
//         section: true,
//         menus: [
//           {
//             id: 'probationteam',
//             label: 'Probation',
//             view: props => {
//               return <ProbationConfirmationManagerTabs {...props} />;
//             },
//           },
//           {
//             id: 'internshipteam',
//             label: 'Internship',
//             view: props => {
//               return <InternshipConfirmationManagerTabs {...props} />;
//             },
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menuItemId: 'appraisal',
//     views: [
//       {
//         id: 'phappraisal',
//         label: 'Appraisal(PH)',
//         view: props => {
//           const {user} = props;
//           return <PHAppraisalTab {...props} />;
//         },
//       },
//       {
//         id: 'hrappraisal',
//         label: 'Appraisal(HR)',
//         section: true,
//         menus: [
//           {
//             id: 'pending',
//             label: 'Pending',
//             view: props => <HRPendingAppraisalTab {...props} />,
//           },
//           {
//             id: 'inprocess',
//             label: 'In Process',
//             view: props => <HRInProcessAppraisalTab {...props} />,
//           },
//           {
//             id: 'processed',
//             label: 'Processed',
//             view: props => <HRProcessedAppraisalTab {...props} />,
//           },
//           // {
//           //   id: 'closed',
//           //   label: 'Closed',
//           //   view: props => <HRClosedAppraisalTab {...props} />,
//           // },
//           // {
//           //   id: 'extended',
//           //   label: 'Extended',
//           //   view: props => <HRExtendedAppraisalTab {...props} />,
//           // },
//           // {
//           //   id: 'onhold',
//           //   label: 'On Hold',
//           //   view: props => <HROnHoldAppraisalTab {...props} />,
//           // },
//           // {
//           //   id: 'promotionrecommendations',
//           //   label: 'Promotion Recommendations',
//           //   view: props => (
//           //     <HRPromotionRecommendationsAppraisalTab {...props} />
//           //   ),
//           // },
//           // {
//           //   id: 'inactive',
//           //   label: 'Inactive',
//           //   view: props => <HRInactiveAppraisalTab {...props} />,
//           // },
//         ],
//       },
//       {
//         id: 'teamperformances',
//         label: 'Team Appraisal',
//         view: props => {
//           const {user} = props;
//           return <EmployeeAppraisalTab {...props} />;
//         },
//       },
//       {
//         id: 'selfappraisal',
//         label: 'Self Appraisal',
//         section: true,
//         menus: [
//           {
//             id: 'performancemanagement',
//             label: 'Performance Management',
//             view: props => {
//               const {user} = props;
//               return (
//                 <SelfAppraisal
//                   reporting_to={user && user.employee && user.employee._id}
//                 />
//               );
//             },
//           },
//           {
//             id: 'previousappraisal',
//             label: 'Previous Appraisal',
//             view: props => {
//               const {user} = props;
//               return (
//                 <EmployeeSelfAppraisalTable
//                   reporting_to={user && user.employee && user.employee._id}
//                 />
//               );
//             },
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menuItemId: 'employeespip',
//     views: [
//       {
//         id: 'piphr',
//         label: 'All',
//         view: props => {
//           return <PipResourceTabs {...props} />;
//         },
//       },
//       {
//         id: 'pipteam',
//         label: 'Team',
//         view: ({user}) => {
//           const {employee} = user;
//           return (
//             <SelfPipResourceTabs
//             // addOnFilter={{status: 'completed', mentor: employee?._id}}
//             />
//           );
//         },
//       },
//       {
//         id: 'selfpip',
//         label: 'Self',
//         view: props => {
//           return <PIPSelfTrainingFeedback {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'employeesresignation',
//     views: [
//       {
//         id: 'allresignation',
//         label: 'All',
//         view: props => {
//           return <AllResginationTabs {...props} />;
//         },
//       },
//       {
//         id: 'enggresignation',
//         label: 'Engg',
//         view: props => {
//           return <EnggResginationTabs {...props} />;
//         },
//       },
//       {
//         id: 'teamresignationreqests',
//         label: 'Team',
//         view: ({user}) => {
//           return <TeamExitManagementTab user fromMyView={true} />;
//         },
//       },
//       {
//         id: 'myresignation',
//         label: 'Self',
//         view: ({user}) => {
//           return <ExitManagementTab user={user} fromMyView={true} />;
//         },
//       },
//       {
//         id: 'analyticsresignation',
//         label: 'Report',
//         view: props => {
//           return <AttritionTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'nodues',
//     views: [
//       {
//         id: 'noduesall',
//         label: 'All',
//         view: ({user}) => {
//           return <NoDuesHrTabs user={user} />;
//         },
//       },
//       {
//         id: 'teamnodues',
//         label: 'Team',
//         view: ({user}) => {
//           return <NoDuesTeamTabs user />;
//         },
//       },
//       {
//         id: 'noduesaccounts',
//         label: 'Accounts',
//         view: ({user}) => {
//           return <NoDuesAccountsTabs user={user} />;
//         },
//       },
//       {
//         id: 'nodueshr',
//         label: 'HR',
//         view: ({user}) => {
//           return <NoDuesHRTabs user={user} />;
//         },
//       },
//       {
//         id: 'noduesadmin',
//         label: 'Admin',
//         view: ({user}) => {
//           return <NoDuesAdminTabs user={user} />;
//         },
//       },
//       {
//         id: 'noduesit',
//         label: 'IT',
//         view: ({user}) => {
//           return <NoDuesITNetworkTabs user={user} />;
//         },
//       },
//       {
//         id: 'selfnodues',
//         label: 'Self',
//         view: ({user}) => {
//           return <SelfNoduesTab user fromMyView={true} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'fnf',
//     views: [
//       {
//         id: 'fnfhr',
//         label: 'FnF(HR)',
//         view: ({user}) => {
//           return <FnFHrTabs user={user} />;
//         },
//       },
//       {
//         id: 'fnfaccounts',
//         label: 'Fnf(Accounts)',
//         view: ({user}) => {
//           return <FnFAccountsTabs user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'employeeparichay',
//     views: [
//       {
//         id: 'employeeparichays',
//         label: 'Employee Parichay',
//         view: ({user}) => {
//           return <EmployeeParichayTabs user={user} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'backgroudverification',
//     views: [
//       {
//         id: 'monthwisebgv',
//         label: 'BGV',
//         view: props => {
//           return <BackgroundVerificationTabs {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'Productivitysurvey',
//     views: [
//       {
//         id: 'productivitysurveypi',
//         label: 'All',
//         view: props => {
//           return <ProductivitySurveyTablePI {...props} />;
//         },
//       },
//       {
//         id: 'productivitysurveymanager',
//         label: 'Team',
//         view: props => {
//           return <ProductivitySurveyTableManager {...props} />;
//         },
//       },
//       {
//         id: 'productivitysurveyself',
//         label: 'Self',
//         view: props => {
//           return <ProductivitySurveyTableSelf {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'setups',
//     views: [
//       {
//         id: 'EmployeeSetup',
//         label: 'Dpt. & Designation',
//         view: props => {
//           return <EmployeeSetupTab {...props} />;
//         },
//       },
//       {
//         id: 'ReimbursementHead',
//         label: 'Reimbursement Head',
//         view: props => {
//           return <ReimbursementHeadTable {...props} />;
//         },
//       },
//       {
//         id: 'EmployeeUser',
//         label: 'Employee User',
//         view: props => {
//           return <EmployeeUserTabs {...props} />;
//         },
//       },
//       {
//         id: 'BloodGroup',
//         label: 'Blood Group List',
//         view: props => {
//           return <BlopdGroupTable {...props} />;
//         },
//       },
//       {
//         id: 'NomineeRelation',
//         label: 'Relations',
//         view: props => {
//           return <NomineeRelationTable {...props} />;
//         },
//       },
//       {
//         id: 'EmployeeType',
//         label: 'Employee Type',
//         view: props => {
//           return <EmployeeTypeTable {...props} />;
//         },
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'aboutme',
//     label: 'About me',
//   },
//   {
//     id: 'reportingToTeam',
//     label: 'Team',
//   },
//   {
//     id: 'employees',
//     label: 'Employee',
//   },
//   {
//     id: 'employeerequests',
//     label: 'Employee Request',
//   },
//   {
//     id: 'replacementrequests',
//     label: 'Employee Replacement Request',
//   },
//   {
//     id: 'employeeallocations',
//     label: 'Employee Allocation',
//   },
//   {
//     id: 'billingstatus',
//     label: 'Billing Status',
//   },
//   {
//     id: 'confirmation',
//     label: 'Confirmation',
//   },
//   {
//     id: 'employeespip',
//     label: 'PIP',
//   },
//   {
//     id: 'employeesresignation',
//     label: 'Resignation',
//   },
//   {
//     id: 'nodues',
//     label: 'No Dues',
//   },
//   {
//     id: 'fnf',
//     label: 'FnF',
//   },
//   {
//     id: 'employeeparichay',
//     label: 'Employee Parichay',
//   },
//   {
//     id: 'backgroudverification',
//     label: 'BGV',
//   },
//   {
//     id: 'Productivitysurvey',
//     label: 'Productivity Survey',
//   },
//   {
//     id: 'setups',
//     label: 'Setup',
//   },
//   {
//     id: 'appraisal',
//     label: 'Appraisal',
//   },
//   {
//     id: 'feedback',
//     label: 'Feedback',
//   },
// ];
