import React from 'react';
import {View, Row, Image} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {FeatureColumnCardStyles} from './theme';
import {PrimaryColumnCard} from './PrimaryColumnCard';

export const MilestoneColumnCard = ({
  primaryTitle,
  primaryTitleColor,
  numberOfLines = 1,
  items,
}) => {
  const styles = useStyles(FeatureColumnCardStyles);
  const {containerStyle, imageContainerStyle, flagIcon} = styles;

  return (
    <Row style={containerStyle} gap={8}>
      <View style={imageContainerStyle}>
        <Image source={flagIcon} />
      </View>
      <PrimaryColumnCard
        primaryTitle={primaryTitle}
        items={items}
        primaryTitleColor={primaryTitleColor}
        numberOfLines={numberOfLines}
      />
    </Row>
  );
};
