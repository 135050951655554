import React from 'react';
import { Form } from '../../../../components/form/Form';
import { useFormSubmit } from '../../../../controllers/useSubmitForm';

export const UpdateLevelsOwner = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });

  return (
    <Form
      header="Update Level Owner"
      api={`/employeeassignments/${row._id}`}
      beforeSubmit={({data}) => {
        if (
          !data?.level_one_owner?._id &&
          !data?.level_two_owner?._id &&
          !data?.level_three_owner?._id
        ) {
          throw new Error('Please select level owner');
        }
        return {
          data: {
            ...data,
            level_update: true,
          },
        };
      }}
      fields={{
        hours: 1,
        percentage: 1,
        is_applicable_for_ma: 1,
        employee: {_id: 1, name: 1, color: 1},
        from_date: 1,
        to_date: 1,
        object: 1,
        product: 1,
        project_id: {_id: 1, project: 1},
        department: {_id: 1, name: 1},
        skill_metrics: {_id: 1, name: 1},
        project_skill: {_id: 1, name: 1},
        resource_skill: {_id: 1, name: 1},
        front_facing_resource: {_id: 1, name: 1},
        tool: {_id: 1, name: 1},
        resource_tool: {_id: 1, name: 1},
        level_one_owner: {_id: 1, name: 1},
        level_two_owner: {_id: 1, name: 1},
        level_three_owner: {_id: 1, name: 1},
        order: {_id: 1, order_number: 1},
        applicable_for_daily_incentive: 1,
        is_applicable_for_ma: 1,
        allow_allocation_more_than_100: 1,
        is_included_in_utilisation: 1,
        percIncludeInUtilisation: 1,
      }}
      mode="edit"
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Employee',
          placeholder: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Front Facing Resource',
          placeholder: 'Front Facing Resource',
          field: 'front_facing_resource',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Start Date',
          placeholder: 'Start Date',
          field: 'from_date',
          type: 'date',
          size: 4,
          readOnly: true,
        },
        {
          label: 'End Date',
          placeholder: 'End Date',
          field: 'to_date',
          type: 'date',
          size: 4,
          readOnly: true,
        },
        {
          label: 'Hours',
          placeholder: 'Hours',
          field: 'hours',
          type: 'number',
          readOnly: true,
          size: 4,
        },
        {
          label: 'Project',
          placeholder: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/projects`,
          suggestionField: 'project',
          valueField: 'project',
          size: 4,
          readOnly: true,
        },
        {
          label: 'Project Skill',
          placeholder: 'Project Skill',
          field: 'tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          readOnly: true,
          size: 4,
        },
        {
          label: 'Resource Skill',
          placeholder: 'Resource Skill',
          field: 'resource_tool',
          type: 'autoComplete',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
          readOnly: true,
          size: 4,
        },
        {
          type: 'switch',
          label: 'Shift allowance',
          field: 'applicable_for_daily_incentive',
          readOnly: true,
          size: 6,
        },
        {
          type: 'number',
          label: 'Amount',
          field: 'daily_incentive',
          visible: ({values = {}}) => values?.applicable_for_daily_incentive,
          readOnly: true,
          size: 6,
        },
        {
          type: 'switch',
          label: 'Allow More Than 9 Hours',
          field: 'allow_allocation_more_than_100',
          readOnly: true,
          size: 6,
        },
        {
          type: 'switch',
          field: 'is_wrong_input',
          placeholder: 'Worng Input',
          label: 'Worng Input',
          readOnly: true,
          size: 6,
        },
        {
          type: 'switch',
          label: 'Is Included In Utilisation',
          field: 'is_included_in_utilisation',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Percentage Included In Utilisation',
          field: 'percIncludeInUtilisation',
          type: 'autoComplete',
          options: [
            {label: '100 %', value: 100},
            {label: '75 %', value: 75},
            {label: '50 %', value: 50},
          ],
          visible: ({values = {}}) => values?.is_included_in_utilisation,
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          size: 6,
          readOnly: true,
        },
        {
          size: 6,
          groups: [
            {
              defaultHidden: true,
              label: 'Leader',
              fields: [
                {
                  label: 'Skill Leader',
                  placeholder: 'Skill Leader',
                  field: 'level_one_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Project Owner',
                  placeholder: 'Project Owner',
                  field: 'level_two_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Level 3 Owner',
                  placeholder: 'Level 3 Owner',
                  field: 'level_three_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
          ],
        },
      ]}
      eventSourceId={['allocatedResource']}
      {...props}
    />
  );
};
