import React from 'react';
import {NavHeader} from '../../../components/nav/NavHeader';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {useAppStateContext} from '../../../providers/AppState';
import {RenderLinkedInUrl, RenderName} from './CustomRendersCollection';

export const PotentialCustomerLocksRequestHeader = props => {
  return <NavHeader title="Unlock Requests" {...props} />;
};

export const PotentialCustomerLocksRequestTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {acceptUnlockRequest} = leadControllers(props);

  let {user} = useAppStateContext();

  let {employee, email} = user || {};

  let filter = {};

  if (email !== 'rohit.bansal@daffodilsw.com') {
    filter = {owner: employee?._id};
  }
  return (
    <Table
      api={`/potentialcustomercontactlocks`}
      eventSourceId={['UnlockRequest', "'AddReason'"]}
      addOnFilter={{
        ...filter,
        $or: [{status: 'locked'}, {status: 'rejected'}],
      }}
      fields={{
        campaign_id: {name: 1},
        new_campaign_id: {name: 1},
        owner: {name: 1},
        new_owner: {name: 1},
        potential_customer: {name: 1, website: 1},
        contact_id: {name: 1, linkedin_url: 1},
        createdAt: 1,
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 250,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 250,
        },
        {
          type: 'text',
          field: 'campaign_id.name',
          header: 'Old Campaign',
        },
        {
          type: 'text',
          field: 'new_campaign_id.name',
          header: 'New Campaign',
        },
        {
          type: 'text',
          field: 'owner.name',
          header: 'Owner',
        },
        {
          type: 'text',
          field: 'new_owner.name',
          header: 'New Owner',
        },
        {
          type: 'date',
          field: 'createdAt',
          header: 'Date',
        },
      ]}
      moreActions={[
        {
          title: 'Accept',
          onPress: acceptUnlockRequest,
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            navigation.navigate('reject-form', {...params, row});
          },
        },
      ]}
    />
  );
};
