import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

export const CurrentBussinessCustomerList = props => {
  const {
    route: {params},
  } = props;
  const {filter = {}} = params;
  return (
    <Table
      api={`/projectresourceworkingdata`}
      filter={{...filter}}
      renderHeader={() => {
        return <TableHeader title="Delivery" />;
      }}
      fields={{
        customer: {_id: 1, name: 1},
        order: {_id: 1, order_number: 1},
        delivery_owner_id: {_id: 1, name: 1, official_email_id: 1, color: 1},
        start_date: 1,
        due_date: 1,
        billing_hrs: 1,
        variable_potential: 1,
      }}
      columns={[
        {
          header: 'Delivery Owner',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.delivery_owner_id?.name}
                color={row?.delivery_owner_id?.color}
                official_email_id={row?.delivery_owner_id?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Customer',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.customer?.name}
                items={[
                  {
                    value: row?.order?.order_number,
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'start_date',
          header: 'From Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'due_date',
          header: 'To Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'variable_potential',
          header: 'Potential',
          type: 'number',
        },
      ]}
    />
  );
};
