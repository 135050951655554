import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {CommentScreenStyles, FormCommentTableStyles} from './theme';
import {CommentList} from '../../../components/comment';

const mentionSuggestionApi = ({project, mentionApi}) => {
  if (mentionApi) {
    return mentionApi;
  }
  if (project) {
    return `/projects/${project?._id || project}/members`;
  } else {
    return `/usersuggestions`;
  }
};

export const FormCommentTable = props => {
  const styles = useStyles(FormCommentTableStyles);
  const {comment_source, comment_source_id} = props;
  if (!comment_source || !comment_source_id) {
    return null;
  }
  return (
    <View style={styles.container}>
      <CommentList
        comment_source={comment_source}
        comment_source_id={comment_source_id}
        mentionApi={mentionSuggestionApi(props)}
      />
    </View>
  );
};

export const CommentScreen = ({route: {params = {}} = {}, ...props}) => {
  const styles = useStyles(CommentScreenStyles);
  const {comment_source, comment_source_id} = params;
  if (!comment_source || !comment_source_id) {
    return null;
  }
  return (
    <View style={styles.container}>
      <CommentList
        {...params}
        {...props}
        mentionApi={mentionSuggestionApi(params)}
      />
    </View>
  );
};
