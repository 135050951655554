import {TabView} from '../../../components/tab';
import DepartmentTable from './DepartmentTable';

const DepartmentTab = props => {
  const {navigation} = props;
  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <DepartmentTable tab="Active" filter={{is_active: true}} {...props} />
      ),
    },
    Inactive: {
      label: 'Inactive',
      view: (
        <DepartmentTable
          tab="Inactive"
          filter={{is_active: {$in: [null, false]}}}
          {...props}
        />
      ),
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export default DepartmentTab;
