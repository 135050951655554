import React from 'react';

import {Table} from '../../../../components/table/Table';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {DataServiceDetailForm} from './DataServiceForm';
import {SplitScreen} from '../../../../components/SplitScreen';
import {AssetColumns, assetFilters} from './AssetUtility';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {PROJECT_DATA_SERVICE_TYPE_ID} from '../../../common/constants/SourceConstants';
import {AllDataServicesScreen} from './FeatureDataServicesScreen';
import {TabView} from '../../../../components/tab';
import {GroupBy} from '../../../../components/table/GroupBy';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';

const DataServiceHeader = ({
  params,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  selectedIds,
  excelParams,
  groupBy,
}) => {
  const {project, module_id, referenceAsset} = params;
  const filters = assetFilters({project});
  return (
    <TableHeader
      actions={
        referenceAsset
          ? null
          : [
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  !module_id && filters.module,
                  filters.dataEntity,
                  filters.owner,
                ]}
              />,
              !module_id && <GroupBy {...groupBy} />,
              <ExportExcelAction
                selectedIds={selectedIds}
                params={excelParams}
              />,
            ]
      }
    />
  );
};

export const dataServiceExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  params: parameters,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';
  return {
    addOnFilter,
    search,
    searchFields,
    params: parameters,
    excelParams: {
      ...params,
      filename: 'Data Services',
      columns: [
        {header: 'name', key: 'name', width: 15},
        {header: 'Model', key: 'model_id.model', width: 15},
        {header: 'Module', key: 'module_id.module', width: 15},
        {header: 'Owner', key: 'owner_id.name', width: 15},
        {header: 'Description', key: 'desc', width: 20},
        {header: 'Remarks', key: remarksKey, width: 50, isHtml: true},
      ],
    },
  };
};

export const DataServiceTable = props => {
  const {
    navigation,
    route: {params},
    status,
    selectedId,
  } = props;

  const {referenceAsset} = params;

  const {groupBy, ...restProps} = props;

  const {
    assetDetail,
    mergeReferences,
    changeModule,
    changeDataEntity,
    deleteAsset,
    deleteMultipleAssets,
    versions,
    updateModuleMultipleAssets,
    updateDataEntityMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_DATA_SERVICE_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({
    navigation,
    params,
    api: '/projectDataServices',
    source: PROJECT_DATA_SERVICE_TYPE_ID,
  });

  const {allowDynamicGrouping} = groupBy || {};

  return (
    <Table
      {...restProps}
      allowDynamicGrouping={allowDynamicGrouping}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId={['DataService', 'MapUserstory']}
      columns={[
        !selectedId && !allowDynamicGrouping && assetColumns.module,
        assetColumns.dataService,
        assetColumns.dataEntity,
        !selectedId && assetColumns.userstory_count,
        !selectedId && assetColumns.multipleApis,
        !selectedId && assetColumns.owner,
      ]}
      onRowPress={assetDetail}
      selection={
        referenceAsset
          ? null
          : {
              actions: [
                updateModuleMultipleAssets,
                updateDataEntityMultipleAssets,
                deleteMultipleAssets,
              ],
            }
      }
      moreActions={() =>
        referenceAsset
          ? null
          : [
              versions,
              changeModule,
              changeDataEntity,
              mergeReferences({
                targetApi: '/projectdataservices',
              }),
              deleteAsset,
            ]
      }
    />
  );
};

export const DatabaseServiceScreen = props => {
  const {
    route: {params},
    groupBy,
  } = props;

  const {project, module_id, referenceAsset, apiEndpoint, dataEntity} = params;

  const filterProps = useFilter();
  const {searchValue, filterValues} = filterProps;

  let addOnFilter =
    referenceAsset === 'model'
      ? {model_id: dataEntity?._id}
      : referenceAsset === 'api'
      ? {controller_ids: apiEndpoint?._id}
      : {project_id: project?._id, module_id};

  const tableProps = {
    api: `/projectdataservices/released`,
    addOnFilter: {
      ...addOnFilter,
      ...filterValues?.filter,
    },
    search: searchValue,
    searchFields: 'name',
  };

  const renderHeader = props => {
    return (
      <DataServiceHeader
        {...props}
        {...filterProps}
        params={params}
        groupBy={groupBy}
        excelParams={dataServiceExportParams(tableProps)}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<DataServiceDetailForm {...props} />}>
      <DataServiceTable
        {...props}
        groupBy={groupBy}
        renderHeader={renderHeader}
        {...tableProps}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const DataServicesTab = props => {
  const {route: {params} = {}} = props;
  const {project, module} = params;

  let groupBy = useGroupBy({
    defaultSelected: module?._id ? void 0 : 'module_id',
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          exclusiveExpand: true,
          defaultExpandedIndex: 0,
          leftContent: ({row}) => {
            const moduleName = row?.module || 'No module';
            return <GroupContentItem value={moduleName + ` (${row.count})`} />;
          },
        },
      },
    ],
  });

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: <DatabaseServiceScreen {...props} groupBy={groupBy} />,
          api: '/projectdataservices',
          filter: {
            status: 'released',
            project_id: project?._id,
            module_id: module?._id,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllDataServicesScreen
              api={`/projects/${project?._id}/asset/${PROJECT_DATA_SERVICE_TYPE_ID}/all-data`}
              groupBy={groupBy}
              {...props}
            />
          ),
          params: {module_id: module?._id},
          api: `/projects/${project?._id}/asset/${PROJECT_DATA_SERVICE_TYPE_ID}/in-development/count`,
        },
      }}
    />
  );
};
