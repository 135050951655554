import React, {useEffect} from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {navigateToListView} from '../../common/constants/SourceConstants';
import MultiDynamicFKRender from '../cellrenders/MultiDynamicFKRender';
import {ErrorChip} from '../../../components/chip/ColorChip';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';

export const MyChatsList = props => {
  const {
    navigation,
    route: {params},
  } = props;

  let invoke = useInvoke({method: 'post', eventSourceId: 'userUnreadCount'});
  const {user} = useAppStateContext();
  useEffect(() => {
    const _fun = async () => {
      try {
        await invoke({
          uri: `/chats/userId/${user?._id}/markRead`,
        });
      } catch (e) {
        toast({message: e.message, type: 'Error'});
      }
    };
    _fun();
  }, []);

  return (
    <Table
      skipNoData={true}
      eventSourceId={['MsgAdded', 'TriggerOnData']}
      api={`/myChats`}
      fields={{name: 1, parent: {name: 1}}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`new-chat-with-chatId`, {
          title: row?.source_id?.name,
          source_name: row?.source?.name,
          row,
          chatId: row?._id,
        });
      }}
      columns={[
        {
          render: MultiDynamicFKRender,
          width: 265,
          sourceField: 'source',
          sourceIdField: 'source_id',
          navigation,
          onPress: navigateToListView,
        },
        {
          type: 'mention',
          field: 'message',
          header: 'Last Message',
        },
        {
          type: 'date',
          field: 'createdAt',
          formatOptions: {
            format: 'hh:mm A',
          },
          width: 95,
        },
        {
          field: 'unreadCount',
          header: 'Unread',
          render: ({row}) => <ErrorChip value={row?.unreadCount} />,
          width: 95,
        },
      ]}
    />
  );
};
