import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateBillingStatusForm = props => {
  const {
    route: {params},
  } = props;
  const {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'BillingStatusUpdate',
  });
  const {row = {}} = params;
  return (
    <Form
      header={{title: 'Update Billing Status'}}
      mode="edit"
      api={`/employees/${row?._id}`}
      fields={{
        billing_status: 1,
      }}
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          is_manual_update_billing_status: true,
        },
      })}
      onSubmit={onSubmit}
      layoutFields={[
        {
          field: 'billing_status',
          type: 'autoComplete',
          label: 'Billing Status',
          options: [
            'Training',
            'Module Development',
            'Shadow',
            'Planned',
            'Not Available',
            'Unplanned',
          ],
        },
      ]}
      {...props}
    />
  );
};
