import React from 'react';
import {Table} from '../../../components/table/Table';
import {AutoCompleteFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';
import {TextRenderer} from '../../../components/form-editors/Editors';

const RenderParentModelEvent = props => {
  const {value, row} = props;
  let text = '';
  if (value == 'Lifetime') {
    text = row?.master_task_assignment?.frequency_mode;
  } else {
    text = value;
  }
  return <TextRenderer {...props} value={text} />;
};

const RenderEstHoursPerInstance = props => {
  const {row} = props;
  return (
    <TextRenderer
      {...props}
      value={Number(row?.est_hours / row?.instances).toFixed(2)}
    />
  );
};

export const MonthlyWorkloadOverviewTable = props => {
  const {
    route: {params},
  } = props;
  const {employee, reviewer} = params;
  const {onChangeFilter, filterValues} = useFilter({});
  return (
    <Table
      {...props}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AutoCompleteFilter
              placeholder="Select Employee"
              api="/employeeSuggestions"
              suggestionField="name"
              valueField="name"
              secondarySuggestionField="official_email_id"
              field="employee"
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
          ]}
        />
      )}
      filter={{employee, reviewer}}
      addOnFilter={{...filterValues?.filter}}
      api="/monthlyworkloads"
      fields={{
        employee: {
          name: 1,
          color: 1,
        },
        est_hours: 1,
        instances: 1,
        master_task_assignment: {
          parent_model_relation: 1,
          task: 1,
          frequency_mode: 1,
          output_model: {
            name: 1,
            label: 1,
            color: 1,
          },
        },
        reviewer: {
          name: 1,
          color: 1,
        },
        reviewer_est_hours: 1,
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatar',
          width: 95,
        },
        {
          header: 'Task',
          field: 'master_task_assignment.task',
          type: 'text',
        },
        {
          header: 'Monthly Workload',
          field: 'est_hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 135,
        },
        {
          header: 'Est Hours/Instance',
          render: RenderEstHoursPerInstance,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 140,
        },
        {
          header: 'Output Type',
          field: 'master_task_assignment.output_model.label',
          colorField: 'master_task_assignment.output_model.color',
          type: 'colorChip',
          width: 120,
        },
        {
          header: 'Parent Model Event',
          field: 'master_task_assignment.parent_model_relation',
          type: 'text',
          width: 180,
          variant: 'secondary',
          render: RenderParentModelEvent,
        },
        {
          header: 'Reviewer',
          field: 'reviewer',
          type: 'userAvatarGroup',
          width: 90,
        },
        {
          header: 'Reviewer Est Hours',
          field: 'reviewer_est_hours',
          width: 145,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
        },
      ]}
    />
  );
};
