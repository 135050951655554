import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {GrowthAndBillingTab} from './CurrentBusinessTAndM';
import {
  FixedOpportunityProjectionTab,
  TnMOpportunityProjectionTab,
} from '../../opportunityProjection/views/opportunityProjectionTabs';
import {MyOrdersTable} from '../../common/views/MyOrdersTable';
import {ProjectedVsAchievedTable} from './ProjectedVsAchievedTable';
import {GrowthAndBillingComparsionTab} from './GrowthAndBillingComparsion';

export const ProjectionMenu = props => {
  const menus = [
    // {
    //   label: 'Current business',
    //   section: true,
    //   menus: [
    //     {
    //       label: 'T&M',
    //       view: <CurrentBusinessTAndMTab />,
    //     },
    //     {
    //       label: 'Fixed',
    //       view: <CurrentBusinessFixedTab />,
    //     },
    //   ],
    // },
    // {
    //   label: 'Pipeline',
    //   section: true,
    //   menus: [
    //     {
    //       label: 'T&M',
    //       view: <TnMOpportunityProjectionTab />,
    //     },
    //     {
    //       label: 'Fixed',
    //       view: <FixedOpportunityProjectionTab />,
    //     },
    //   ],
    // },
    {
      label: 'Active Fixed Orders',
      view: <MyOrdersTable />,
    },
    {
      label: 'Growth & Billing',
      view: <GrowthAndBillingTab />,
    },
    {
      label: 'Projected Vs Achieved',
      view: <ProjectedVsAchievedTable />,
    },
    {
      label: 'Growth & Billing Comparsion',
      view: <GrowthAndBillingComparsionTab />,
    },
  ];
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
