import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {
  ActiveTasksTable,
  BacklogTasksTable,
  CompletedTasksTable,
} from './TasksTable';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';

export const TaskTabs = props => {
  let {route: {params} = {}} = props;
  const {searchValue, onChangeFilter} = useFilter();
  params = {...params, showSource: false};
  const tabs = {
    backlog: {
      label: 'Backlog',
      view: (
        <BacklogTasksTable
          skipAddTask={!params?.source_id}
          searchValue={searchValue}
        />
      ),
    },
    active: {
      label: 'Active',
      view: <ActiveTasksTable searchValue={searchValue} />,
    },
    completed: {
      label: 'Completed',
      view: <CompletedTasksTable searchValue={searchValue} />,
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
      ]}
    />
  );
};
