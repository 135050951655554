import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const ReplanForm = props => {
  const {
    route: {params},
    defaultValues: oldDefaultValues,
  } = props;

  const {row = {}, project, api} = params;
  let {onSubmit} = useFormSubmit({
    uri: api ? `${api}` : '/projecttasks',
    eventSourceId: 'Task',
  });

  const defaultValues = {...oldDefaultValues, project_id: project?._id};

  let url = api ? `/projectfeatures/${row?._id}` : `/projecttasks/${row?._id}`;
  return (
    <Form
      onSubmit={onSubmit}
      api={url}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Est Delivery Date',
          field: 'due_date',
          type: 'date',
          required: !!row.due_date,
        },
        {
          label: 'Reason',
          field: 'update_due_date_reason',
          type: 'textArea',
          inputProps: {
            minRows: 2,
            maxRows: 5,
          },
          visible: !!row.due_date,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateReplanForm = props => {
  const {route: {params = {}} = {}} = props;
  return (
    <ReplanForm
      mode="edit"
      header={{
        title: 'Update Delivery Date',
        secondaryTitle: params.row?.feature,
      }}
      {...props}
    />
  );
};
