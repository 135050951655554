import React from 'react';
import {Form} from '../../../../components/form/Form';
import {
  PROJECT_DATA_SERVICE_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';

const dataServiceFields = ({params}) => {
  const {project} = params;

  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    name: {
      label: 'Name',
      type: 'text',
      field: 'name',
      required: true,
    },
    model: ({readOnly} = {}) => ({
      label: 'Model',
      field: 'model_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
      suggestionField: 'model',
      valueField: 'model',
      searchFields: ['model'],
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-model`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      readOnly,
    }),
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      readOnly,
    }),
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
    },
    description: {
      label: 'Description',
      type: 'textArea',
      field: 'desc',
      inputProps: {
        minRows: 3,
        maxRows: 6,
      },
    },
    remarks: {
      label: 'Remarks',
      field: remarksField,
      type: 'richText',
      placeholder: 'Write remarks here...',
      minHeight: 200,
    },
  };
};

export const AddDataServiceForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {feature, project, module, searchValue, afterSubmit, paramsFilter} =
    params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_DATA_SERVICE_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    name: searchValue,
    project_id: project?._id,
    module_id: module,
    model_id: paramsFilter?.model_id,
    controller_ids: paramsFilter?.controller_ids,
    source: PROJECT_DATA_SERVICE_TYPE_ID,
    feature_id: feature?._id,
  };

  const fields = dataServiceFields({params});

  return (
    <Form
      {...props}
      header="Add Services"
      onSubmit={createAsset}
      beforeSubmit={requiredValidation}
      defaultValues={initialValues}
      afterSubmit={afterSubmit}
      submitAction="Save"
      layoutFields={[
        fields.name,
        fields.model(),
        {
          collapsedFields: [fields.module(), fields.owner, fields.description],
        },
      ]}
    />
  );
};

export const DataServiceDetailForm = props => {
  const {
    route: {params},
  } = props;

  const {readOnly, row, feature} = params;

  const fields = dataServiceFields({params});

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_DATA_SERVICE_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  return (
    <Form
      {...props}
      key={row?._id}
      header={{title: 'Service Detail', secondaryTitle: row.name}}
      mode="edit"
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      isDualMode
      readOnly={readOnly}
      data={row}
      submitAction="Save"
      type={'tab'}
      tabs={{
        detail: {
          label: 'Detail',
          layoutFields: [
            fields.name,
            fields.model({readOnly: true}),
            fields.module({readOnly: true}),
            fields.owner,
            fields.description,
          ],
        },
        remarks: {
          label: 'Remarks',
          layoutFields: [fields.remarks],
        },
      }}
    />
  );
};
