import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {
  getLastWorkingDayOfMonth,
  getLastWorkingDayOfWeek,
} from '@unthinkable/react-date-picker';

export const TaskPlanForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params || {};

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Task',
    uri: '/projecttasks',
    ...props,
  });

  return (
    <Form
      defaultValues={{_id: row?._id}}
      submitAction="Save"
      onSubmit={onSubmit}
      api={`/projecttasks/${row?._id}`}
      fields={{plan_type: 1, plan_date: 1}}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            plan_date:
              data?.plan_type === 'Date'
                ? data?.plan_date
                : data?.plan_type === 'This Week'
                ? getLastWorkingDayOfWeek()
                : data?.plan_type === 'This Month'
                ? getLastWorkingDayOfMonth()
                : row?.plan_date
                ? null
                : void 0,
          },
        };
      }}
      layoutFields={[
        {
          field: 'plan_type',
          type: 'radioGroup',
          options: ['Date', 'This Week', 'This Month', 'Later'],
          required: true,
          onChangeValue: async (value, _, {setFieldValue}) => {
            if (value === 'Date') {
              setFieldValue('plan_date', void 0);
            }
          },
        },
        {
          field: 'plan_date',
          type: 'date',
          required: true,
          label: 'Plan Date',
          visible: ({values}) => {
            return values?.plan_type === 'Date';
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddTaskPlanForm = props => {
  return (
    <TaskPlanForm
      header={{
        title: 'Plan Task',
        secondaryTitle: props.route.params.row?.name,
      }}
      {...props}
    />
  );
};

export const UpdateTaskPlanForm = props => {
  return (
    <TaskPlanForm
      mode={'edit'}
      header={{
        title: 'Update Plan',
        secondaryTitle: props.route.params.row?.name,
      }}
      {...props}
    />
  );
};
