import { Col } from '@unthinkable/react-core-components';
import { useFilter } from '@unthinkable/react-filters';
import { RowWithSeparator } from '@unthinkable/react-layout';
import moment from 'moment';
import React from 'react';
import { GroupContentItem } from '../../../components/card/GroupContentItem';
import { GroupFilter } from '../../../components/filter/Filters';
import { TextRenderer } from '../../../components/form/Renderers';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';

const getResolvedProductiveValue = value => {
  if (value === 'Yes') {
    return 'Productive';
  }
  if (value === 'No') {
    return 'Not Productive';
  }
  return value;
};

export const ProductivitySurveyTableSelf = props => {
  const {user} = useAppStateContext();
  const {filterValues, applyFilter} = useFilter({
    filter: {
      feedback_date: {
        $gte: moment().startOf('year').toDate(),
        $lte: moment().endOf('year').toDate(),
      },
    },
    plainFilter: {
      feedback_date: {
        filterOf: 'Year',
        from: moment().utc().startOf('year').toDate(),
        to: moment().utc().endOf('year').toDate(),
      },
    },
  });  return (
    <Table
      renderHeader={props => (
        <TableHeader
          title={'Productivity Feedbacks'}
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'date',
                  field: 'feedback_date',
                  label: 'Feedback Date',
                  options: [
                    {label: 'Month', value: 'Month'},
                    {
                      label: 'Year',
                      value: 'Year',
                    },
                    {
                      label: 'Custom',
                      value: 'Custom',
                    },
                  ],
                },
                {
                    type: 'autoComplete',
                    field: 'productive',
                    label: 'Productivity',
                    suggestionField: 'label',
                    keyField: 'value',
                    valueField: 'label',
                    placeholder: 'Select Productivity',
                    options: [
                      {label: 'Yes', value: 'Productive'},
                      {label: 'No', value: 'Not Productive'},
                      {
                        label: 'Sometimes Yes, Sometimes No',
                        value: 'Sometimes Productive',
                      },
                    ],
                  },
                {
                  type: 'autoComplete',
                  field: 'rating',
                  label: 'Rating',
                  placeholder: 'Select Rating',
                  options: ['Awesome', 'Just Okay', 'Unsatisfied'],
                },
              ]}
              {...props}
            />,
          ]}
        />
      )}
      api="/productivitysurveys"
      sort={{feedback_date: -1}}
      eventSourceId="productivitySurvey"
      filter={{user: user._id}}
      addOnFilter={filterValues.filter}
      columns={[
        {
          header: 'Month',
          field: 'feedback_date',
          render: ({value, ...rest}) => (
            <TextRenderer
              value={moment(value).utc().format('MMMM YYYY')}
              {...rest}
            />
          ),
          width: 100,
        },
        {
          header: 'Productivity',
          render: ({row}) => {
            const {productive_points, productive_suggestion, productive} = row;
            return (
              <Col style={{gap: 4, alignItems: 'center'}}>
                <TextRenderer value={getResolvedProductiveValue(productive)} />
                <RowWithSeparator
                  style={{
                    gap: 4,
                    alignItems: 'center',
                  }}>
                  {productive == 'Productive' ? (
                    productive_points?.map(item => (
                      <GroupContentItem value={item} title={item} />
                    ))
                  ) : (
                    <GroupContentItem
                      value={productive_suggestion}
                      title={productive_suggestion}
                    />
                  )}
                </RowWithSeparator>
              </Col>
            );
          },
          align: 'center',
        },
        {
          header: 'Rating',
          render: ({row}) => {
            const {rating_feedback, rating} = row;
            return (
              <Col style={{gap: 4, alignItems: 'center'}}>
                <TextRenderer
                  value={`Work Experience (${rating})`}
                  title={`Work Experience (${rating})`}
                />
                <GroupContentItem
                  value={rating_feedback}
                  title={rating_feedback}
                />
              </Col>
            );
          },
          align: 'center',
          width: 250,
        },
      ]}
      {...props}
    />
  );
};
