import { getPreviousMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { MoreActions } from '../../../components/moreAction/MoreAction';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { OtherCampaignTable } from './OtherCampaignTable';

export const InreachCampaignTabs = props => {
  const {
    route: {params},
    navigation
  } = props;
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams} = filterValues || {};
  const tabs = {
    confirmed: {
      label: 'Confirmed',
      view: (
        <OtherCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{status: 'Confirmed'}}
        />
      ),
    },
    inexperiment: {
      label: 'In Experiment',
      view: (
        <OtherCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{status: 'In Experiment'}}
        />
      ),
    },
    retired: {
      label: 'Retired',
      view: (
        <OtherCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{status: 'Retired'}}
        />
      ),
    },
    failed: {
      label: 'Failed',
      view: (
        <OtherCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{status: 'Failed'}}
        />
      ),
    },
  };
  const actions = [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search"
    />,
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        {
          type: 'autoComplete',
          label: 'Owner',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'assigned_to',
          secondarySuggestionField: 'official_email_id',
        },
      ]}
    />,
    <AddButton
      title="Inreach Campaign"
      view="add-othercampaign"
      params={props?.route?.params}
    />,
    <MoreActions
      actions={[
        {
          title: 'Import Prospects',
          onPress: () => {
            navigation.navigate('import-list', {
              source: 'inreach prospects',
              model: 'PotentialCustomer',
            });
          },
        },
        {
          title: 'Import Apollo Prospects',
          onPress: () => {
            navigation.navigate('import-list', {
              ...props?.route?.params,
              source: 'apollo prospects',
              model: 'PotentialCustomer',
            });
          },
        },
      ]}
    />,
  ];
  return <TabView {...props} tabs={tabs} actions={actions} />;
};
