import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddTicketCategory} from './TicketCategoryForms';

export const TicketCategoryList = props => {
  const {
    route: {params},
  } = props;
  const {employee_team} = params;
  return (
    <Table
      renderHeader={() => <AddTicketCategory {...props} />}
      api="/ticketCategories"
      filter={{
        employee_team: employee_team?._id,
      }}
      eventSourceId={['TicketCategory']}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            props.navigation.navigate('edit-ticket-category', {
              ticket_category: row,
              employee_team,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
