import {Text} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';

import {EfficiencyStyles} from './theme';

export const Efficiency = ({value}) => {
  const styles = useStyles(EfficiencyStyles);
  const {colors} = useTheme();

  let color = '';
  if (value >= 80) {
    color = '#34C759';
  } else if (value >= 65) {
    color = colors.WARNING_HIGH;
  } else {
    color = colors.ERROR_HIGH;
  }

  if (value == undefined || value == null) {
    return;
  }

  return (
    <Text
      style={{
        ...styles.title,
        color,
      }}>
      {`${value?.toFixed()}%`}
    </Text>
  );
};
