import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';

const DepartmentOutputTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="DepartmentOutput"
      api={`/departmentoutputs`}
      fields={{
        label: 1,
        department: {name: 1},
        creation_date: 1,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`department-output-detail`, {
          departmentOutputId: row._id,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'department.name',
          header: 'Department',
        },
      ]}
    />
  );
};

export default DepartmentOutputTable;
