import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from '../../order/views/components/Durations';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  dropReimbursement,
  sendForApproval,
} from '../controllers/StatusController';
import {activityLogReimbursement} from '../controllers/ReimbursementActivityLogController';
import {
  UserAvatarWithDateChip,
  renderType,
} from '../components/ReimbursementColumnRenders';

const SelfReimbursementColumns = [
  {
    field: 'createdAt',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
    header: 'Requested On',
  },
  {
    // field: 'reimbursement_type',
    align: 'center',
    render: renderType,
    header: 'Type',
  },
  {
    field: 'description',
    type: 'text',
    header: 'Description',
    widthn: 250,
  },
  {
    header: 'Duration',
    align: 'center',
    render: ({row}) => {
      return Durations({
        toDate: row?.to_date,
        fromDate: row?.from_date,
      });
    },
  },

  {
    field: 'total_amount',
    type: 'number',
    header: 'Amount',
  },
];

export const ReimbursementSelfNewTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ReimbursementSelfNew', 'ReimbursementSelfPending'],
  });

  return (
    <Table
      eventSourceId={['ReimbursementSelfNew', 'ReimbursementSelfPending']}
      api={`/reimbursement/self/New`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...SelfReimbursementColumns,
        {
          field: 'approved_by',
          type: 'userAvatarChip',
          header: 'Approver',
        },
      ]}
      moreActions={() => [
        sendForApproval({invoke}),
        // activityLogReimbursement({navigation, params}),
        dropReimbursement({invoke}),
      ]}
    />
  );
};

export const ReimbursementSelfPendingTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'ReimbursementSelfPending',
  });

  return (
    <Table
      eventSourceId={['ReimbursementSelfNew', 'ReimbursementSelfPending']}
      api={`/reimbursement/self/pending&partial`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...SelfReimbursementColumns,
        {
          field: 'approved_by',
          type: 'userAvatarChip',
          header: 'Approver',
        },
      ]}
      moreActions={() => [
        dropReimbursement({invoke}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementSelfApprovedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/reimbursement/self/Approved`}
      eventSourceId={['ReimbursementSelfNew', 'ReimbursementSelfPending']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...SelfReimbursementColumns,
        {
          render: props => (
            <UserAvatarWithDateChip
              field={props?.row?.approver ? 'approver' : 'approved_by'}
              dateField={'approved_on'}
              approve
              {...props}
            />
          ),
          header: 'Approved By',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementSelfRejectTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/reimbursement/self/Rejected`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...SelfReimbursementColumns,
        {
          render: props => (
            <UserAvatarWithDateChip
              field={'rejected_by'}
              dateField={'rejected_on'}
              {...props}
            />
          ),
          header: 'Rejected By',
        },
        {
          field: 'reject_reason',
          type: 'text',
          header: 'Reason',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementSelfProcessedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};

  return (
    <Table
      api={`/reimbursement/self/Processed`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...SelfReimbursementColumns,
        {
          render: props => (
            <UserAvatarWithDateChip
              field={props?.row?.approver ? 'approver' : 'approved_by'}
              dateField={'approved_on'}
              approve
              {...props}
            />
          ),
          header: 'Approved By',
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementSelfReopenTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementSelfNew',
      'ReimbursementSelfPending',
      'ReimbursementSelfReopen',
    ],
  });
  return (
    <Table
      eventSourceId="ReimbursementSelfReopen"
      api={`/reimbursement/self/ReOpen`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-reopen-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      columns={[
        ...SelfReimbursementColumns,
        {
          render: props => (
            <UserAvatarWithDateChip
              field={props?.row?.approver ? 'approver' : 'approved_by'}
              dateField={'approved_on'}
              approve
              {...props}
            />
          ),
          header: 'Approved By',
        },
        {
          header: 'Remarks',
          field: 'remark',
        },
      ]}
      moreActions={() => [
        sendForApproval({invoke}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};
