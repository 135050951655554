import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';

export const AddMappingForm = props => {
  const {
    route: {params},
    header = 'Add Mapping',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/importmappings',
    eventSourceId: 'mappingAdded',
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Mapping',
          field: 'mapping',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  field: 'header',
                  placeholder: 'Header',
                  header: 'Header',
                  type: 'text',
                },
                {
                  field: 'field',
                  placeholder: 'Field',
                  header: 'Field',
                  type: 'autoComplete',
                  options: [
                    'first_name',
                    'last_name',
                    'email',
                    'website',
                    'designation',
                    'linkedin_url',
                    'company_linkedin_url',
                    'employee_size',
                    'industry',
                    'country',
                    'batch_id',
                    'connection_request_date',
                    'connected_at',
                    'campaign',
                    'company_name',
                    'employee_count',
                    'place',
                    'name',
                    'date_created',
                    'first_message_at',
                    'request_date',
                  ],
                },
                // {
                //   field: 'format',
                //   placeholder: 'Date Format',
                //   header: 'Date Format',
                //   type: 'autoComplete',
                //   options: [
                //     'D/M/YY',
                //     'D-M-YY',
                //     'YY-M-D',
                //     'YY/M/D',
                //     'D.M.YY',
                //     'YY.M.D',
                //     'D/M/YYYY',
                //     'D-M-YYYY',
                //     'YYYY-M-D',
                //     'YYYY/M/D',
                //     'YYYY.M.D',
                //     'D.M.YYYY',
                //   ],
                // },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};

export const EditMappingForm = props => {
  const {
    route: {
      params: {
        row: {_id},
      },
    },
  } = props;
  return (
    <AddMappingForm
      mode="edit"
      header={'Edit Mapping'}
      api={`/importmappings/${_id}`}
      {...props}
    />
  );
};
