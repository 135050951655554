import React from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {ToggleStyles} from './theme';

export const ToggleButtonComponent = ({
  value,
  onChange,
  options,
  containerStyle,
  itemStyle,
  activeItemStyle,
  labelStyle,
  activeLabelStyle,
  itemWidth,
  itemMinWidth,
  fullWidth,
}) => {
  let extraContainerStyle = {};
  let extraItemStyle = {};
  if (fullWidth) {
    extraContainerStyle.width = '100%';
    extraItemStyle.flex = 1;
  }

  let renderComponent = (
    <View style={[{flexDirection: 'row'}, containerStyle, extraContainerStyle]}>
      {options.map((option, index) => {
        if (typeof option === 'string') {
          option = {label: option, value: option};
        }
        const {
          icon,
          label,
          value: _value,
          width = itemWidth,
          minWidth = itemMinWidth,
        } = option;
        const selected = value === _value;
        return (
          <TouchableOpacity
            key={index}
            style={[
              itemStyle,
              extraItemStyle,
              width ? {width} : void 0,
              minWidth ? {minWidth} : void 0,
              selected ? activeItemStyle : {},
            ]}
            onPress={() => {
              onChange?.(_value);
            }}>
            {icon ? <Image source={icon} resizeMode={'contain'} /> : void 0}
            {label && (
              <Text style={[labelStyle, selected ? activeLabelStyle : {}]}>
                {label}
              </Text>
            )}
          </TouchableOpacity>
        );
      })}
    </View>
  );
  if (!fullWidth) {
    renderComponent = (
      <View style={{flexDirection: 'row'}}>{renderComponent}</View>
    );
  }
  return renderComponent;
};

export const ToggleButton = props => {
  const styles = useStyles(ToggleStyles);
  return <ToggleButtonComponent {...styles} {...props} />;
};
