import moment from 'moment';

const getStartAndEndOfWeek = dateString => {
  const date = moment(dateString);
  const startOfWeek = date.clone().startOf('week');
  const endOfWeek = date.clone().endOf('week');

  return {startOfWeek, endOfWeek};
};

export const getGroupedTasks = ({tasks}) => {
  const tasksByWeek = {};

  tasks.forEach(task => {
    const {completed_on} = task || {};

    if (!completed_on) {
      return;
    }

    const {startOfWeek, endOfWeek} = getStartAndEndOfWeek(completed_on);

    const weekRange =
      startOfWeek.format('D MMM YYYY') + ' - ' + endOfWeek.format('D MMM YYYY');

    if (!tasksByWeek[weekRange]) {
      tasksByWeek[weekRange] = [];
    }

    tasksByWeek[weekRange].push(task);
  });

  const result = [];

  for (const week in tasksByWeek) {
    const total_est_hours = tasksByWeek[week]?.reduce((acc, task) => {
      return acc + (task.est_hours || 0);
    }, 0) || 0;

    result.push({
      _id: week,
      _children: tasksByWeek[week],
      total_est_hours: total_est_hours,
    });
  }

  return result;
};
