import {LinkRenderer} from '../form/Renderers';
import {Chip, Chips} from '@unthinkable/react-chip';
import {
  UserAvatar,
  UserAvatarChip,
  UserAvatarGroup,
} from '../avatar/UserAvatar';
import {ColorChip, ColorTag} from '../chip/ColorChip';
import {FileRenderer} from '../form-editors/file/FileInput';
import {DurationRenderer} from '../form-editors/duration/DurationInput';
import {RatingRenderer} from '../form-editors/rating/Rating';
import { MentionRenderer } from '../form-editors/mentions/MentionsInput';

export default {
  userAvatar: UserAvatar,
  userAvatarGroup: UserAvatarGroup,
  userAvatarChip: UserAvatarChip,
  colorChip: ColorChip,
  colorTag: ColorTag,
  chip: Chip,
  chips: Chips,
  file: FileRenderer,
  duration: DurationRenderer,
  link: LinkRenderer,
  rating: RatingRenderer,
  mention:MentionRenderer
};
