import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { getCurrentDay } from '../../employee/views/EmployeeTeamTabs';
import {
    PerformanceBilledCompletedReportTable,
    PerformanceBilledUnassignedTable
} from './PerformanceReportTable';

export const PerformanceCompletedTab = props => {
  let {route: {params} = {}, project_status, tab, is_project_assign} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });

  // const groupBy = useGroupBy({
  //   options: [
  //     {
  //       label: 'Completed on',
  //       field: 'completed_on',
  //       groupRow: {
  //         title: 'project',
  //         data: '_children',
  //         defaultExpanded: true,
  //       },
  //     },
  //   ],
  // });

  const filters = {
    staff_augmentation: {
      ...filterValues.filter,
      project_status,
      // projecttype: 'tm',
      // project_sub_category: {$in: ['Hybrid Managed', 'Client Managed']},
      is_project_assign,
      new_project_type: 'staffaugmentation',
    },
    managed_team: {
      ...filterValues.filter,
      project_status,
      // projecttype: 'tm',
      // project_sub_category: 'Daffodil Managed',
      is_project_assign,
      new_project_type: 'managedteam',
    },
    sdlc: {
      ...filterValues.filter,
      project_status,
      // projecttype: 'tm',
      // tool: 'Locomo',
      // project_sub_category: 'Daffodil Managed',
      is_project_assign,
      new_project_type: 'sdlc',
    },
    sdlcplus: {
      ...filterValues.filter,
      project_status,
      projecttype: 'f',
      is_project_assign,
      new_project_type: 'sdlcplus',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    staff_augmentation: {
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledCompletedReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.staff_augmentation}
          tabName="staff"
          project_status={project_status}
          tab={tab}
          // groupBy={groupBy}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.staff_augmentation,
      params: filterParams,
      limit: 500,
    },
    managed_team: {
      label: 'Managed Team',
      view: (
        <PerformanceBilledCompletedReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.managed_team}
          tabName="managed"
          project_status={project_status}
          tab={tab}
          // groupBy={groupBy}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.managed_team,
      params: filterParams,
      limit: 500,
    },
    sdlc: {
      label: 'SDLC',
      view: (
        <PerformanceBilledCompletedReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlc}
          tabName="sdlc"
          project_status={project_status}
          tab={tab}
          // groupBy={groupBy}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlc,
      params: filterParams,
      limit: 500,
    },
    sdlcplus: {
      label: 'SDLC+',
      view: (
        <PerformanceBilledCompletedReportTable
          PerformanceBilledCompletedReportTable={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlcplus}
          tabName="sdlcplus"
          project_status={project_status}
          tab={tab}
          // groupBy={groupBy}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlcplus,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        // <GroupBy {...groupBy} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Managed By',
            },
            {
              label: 'Tool',
              field: 'tool',
              placeholder: 'Select Tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
            },
            {
              label: 'Feedback Mode',
              field: 'feedback_mode',
              placeholder: 'Select Feedback Mode',
              type: 'autoComplete',
              options: ['Automatic', 'Manual'],
            },
            {
              label: 'Last automatic feedback request',
              field: 'automatic_feedback_request_success_date',
              type: 'date',
            },
            {
              label: 'Last automatic feedback failure',
              field: 'automatic_feedback_request_failure_date',
              type: 'date',
            },
            {
              type: 'date',
              field: 'current_date',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const ProjectUnassignedCompletedTab = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const filters = {
    staff_augmentation: {
      ...filterValues.filter,
      project_status,
      projecttype: 'tm',
      project_sub_category: {$in: ['Hybrid Managed', 'Client Managed']},
      is_project_assign: {$in: [false, null]},
    },
    managed_team: {
      ...filterValues.filter,
      project_status,
      projecttype: 'tm',
      project_sub_category: 'Daffodil Managed',
      is_project_assign: {$in: [false, null]},
    },
    sdlc: {
      ...filterValues.filter,
      project_status,
      projecttype: 'tm',
      tool: 'Locomo',
      project_sub_category: 'Daffodil Managed',
      is_project_assign: {$in: [false, null]},
    },
    sdlcplus: {
      ...filterValues.filter,
      project_status,
      projecttype: 'f',
      is_project_assign: {$in: [false, null]},
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    staff_augmentation: {
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.staff_augmentation}
          tabName="staff"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.staff_augmentation,
      params: filterParams,
      limit: 500,
    },
    managed_team: {
      label: 'Managed Team',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.managed_team}
          tabName="managed"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.managed_team,
      params: filterParams,
      limit: 500,
    },
    sdlc: {
      label: 'SDLC',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlc}
          tabName="sdlc"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlc,
      params: filterParams,
      limit: 500,
    },
    sdlcplus: {
      label: 'SDLC+',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlcplus}
          tabName="sdlcplus"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlcplus,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Managed By',
            },
            {
              label: 'Tool',
              field: 'tool',
              placeholder: 'Select Tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
            },
            {
              label: 'Feedback Mode',
              field: 'feedback_mode',
              placeholder: 'Select Feedback Mode',
              type: 'autoComplete',
              options: ['Automatic', 'Manual'],
            },
            {
              label: 'Last automatic feedback request',
              field: 'automatic_feedback_request_success_date',
              type: 'date',
            },
            {
              label: 'Last automatic feedback failure',
              field: 'automatic_feedback_request_failure_date',
              type: 'date',
            },
          ]}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const PerformanceCompletedAllTab = props => {
  let {route: {params} = {}, project_status, tab, is_project_assign} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });

  const filters = {
    all_closed: {
      ...filterValues.filter,
      project_status,
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    all_closed: {
      label: 'All Closed',
      view: (
        <PerformanceBilledCompletedReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.all_closed}
          tabName="staff"
          project_status={project_status}
          tab={tab}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.all_closed,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              options: [
                'Client Managed',
                'Daffodil Managed',
                'Hybrid Managed',
                'Daffodil Managed - Support',
              ],
              field: 'project_sub_category',
              label: 'Managed By',
            },
            {
              label: 'Tool',
              field: 'tool',
              placeholder: 'Select Tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
            },
            {
              label: 'Feedback Mode',
              field: 'feedback_mode',
              placeholder: 'Select Feedback Mode',
              type: 'autoComplete',
              options: ['Automatic', 'Manual'],
            },
            {
              label: 'Last automatic feedback request',
              field: 'automatic_feedback_request_success_date',
              type: 'date',
            },
            {
              label: 'Last automatic feedback failure',
              field: 'automatic_feedback_request_failure_date',
              type: 'date',
            },
            {
              type: 'date',
              field: 'current_date',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};
