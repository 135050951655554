import React from 'react';
import {Rating} from '@unthinkable/react-star-rating';
import {useTheme} from '@unthinkable/react-theme';

export const RatingRenderer = props => {
  const {BRAND_UPPER_MEDIUM, OUTLINE} = useTheme('colors');
  return (
    <Rating
      color={BRAND_UPPER_MEDIUM}
      emptyColor={OUTLINE}
      {...props}
      readOnly={true}
    />
  );
};
