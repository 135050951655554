const {Form} = require('../../../components/form/Form');
const {useFormSubmit} = require('../../../controllers/useSubmitForm');

const MySkillsForm = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {employeeSkill, user} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeskills',
    eventSourceId: 'MySkill',
  });

  return (
    <Form
      {...props}
      params={params}
      api={`/employeeskills/${employeeSkill?._id}`}
      fields={{
        employee_id: {
          name: 1,
        },
        skill_metrics: {
          name: 1,
          tools: 1,
        },
        child_skill_metrics: {
          name: 1,
        },
        tools: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="save"
      defaultValues={{
        employee_id: user?.employee,
        status: 'active',
      }}
      layoutFields={[
        {
          field: 'employee_id',
          label: 'Employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          readOnly: true,
        },
        {
          label: 'Skill',
          field: 'skill_metrics',
          type: 'autoComplete',
          api: '/skillmetrics',
          suggestionField: 'name',
          valueField: 'name',
          onCreate: props => {
            navigation.navigate('add-skills-metrics', {
              ...props,
            });
          },
          fields: {
            name: 1,
            tools: 1,
          },
          required: true,
          filter: {
            parent: {
              $eq: null,
            },
          },
        },
        {
          placeholder: 'Child Skills',
          field: 'child_skill_metrics',
          type: 'multiAutoComplete',
          api: `/skillMetrics`,
          label: 'Child Skills',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          filter: ({values}) => ({
            parent: values.skill_metrics,
          }),
        },
        {
          placeholder: 'Tools',
          field: 'tools',
          type: 'multiAutoComplete',
          api: `/tools`,
          label: 'Tools & Languages',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          filter: ({values}) => {
            return {
              _id: {
                $in: values?.skill_metrics?.tools || [],
              },
            };
          },
        },
      ]}
    />
  );
};

export const AddMySkillsForm = props => {
  return <MySkillsForm header="Add Skill" {...props} />;
};

export const EditMySkillsForm = props => {
  return <MySkillsForm header="Edit Skill" mode="edit" {...props} />;
};
