import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddPlatform = props => {
  const {
    route: {params},
    header = 'Add Platform',
    defaultValue,
  } = props;
  const {department, product, row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/platforms',
    ...props,
  });
  return (
    <Form
      api={`/platforms/${row?._id}`}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const EditPlatformForm = props => {
  return <AddPlatform mode="edit" header={'Edit Platform'} {...props} />;
};
