import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AccessGroupForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/accessGroups',
    eventSourceId: ['accessGroupAdded'],
  });
  return (
    <Form
      api={`/accessGroups/${row?._id}`}
      fields={{
        name: 1,
        parent: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',

        },
        {
          label: 'Parent',
          field: 'parent',
          type: 'autoComplete',
          api: '/accessGroups',
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddAccessGroup = props => {
  return <AccessGroupForm header="Add Access Group" {...props} />;
};

export const UpdateAccessGroup = props => {
  return (
    <AccessGroupForm mode="edit" header="Update Access Group" {...props} />
  );
};
