import React from 'react';

const StatusBar = () => {
  return null; // This component does not render anything
};

// Static properties
StatusBar.currentHeight = 0;

export default StatusBar;
