import {Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';

export const BudgetTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  return (
    <Table
      eventSourceId={['budget']}
      api={`/budget/budgetdetail`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      onRowPress={({row}) => {
        if (tab === 'Approved' || tab === 'Rejected') {
          navigation.navigate('edit-budget-request', {row, readOnly: true});
        } else {
          navigation.navigate('edit-budget-request', {row});
        }
      }}
      columns={[
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Term',
          field: 'term',
          type: 'text',
          width: 100,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 200,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.amount}
                currency={row?.currency?.currency}
              />
            );
          },
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
          align: 'center',
        },
        {
          header: 'Approved',
          type: 'date',
          width: 100,
          visible: ({values}) => {
            if (tab === 'Approved') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Rejected',
          type: 'date',
          visible: ({values}) => {
            if (tab === 'Rejected') return true;
          },
          render: ({row, styles}) => {
            return (
              <View>
                <Text style={{...styles.rowText, color: '#FF3B30'}}>
                  {moment(row.rejected_date).format('DD MMM YY')}
                </Text>
                <Text style={{...styles.rowText, color: '#737373'}}>
                  {row.reject_reason}
                </Text>
              </View>
            );
          },
          width: 200,
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: ({row}) => {
            invoke({
              uri: `/expenserequest/delete/${row._id}`,
              props: {
                ...row,
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const BudgetRequestAccountTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
  });
  return (
    <Table
      eventSourceId={['budget']}
      api={`/budget/budgetdetail`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      // onRowPress={({row}) => {
      //   navigation.navigate('edit-budget-request', {row});
      // }}
      columns={[
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Term',
          field: 'term',
          type: 'text',
          minwidth: 200,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 200,
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 250,
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
