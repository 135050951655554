import React from 'react';
import {
  RecursiveColumnIndent,
  RecursiveColumnRow,
  RecursiveIcon,
  RecursiveIconContainer,
  RecursiveIconTouchable,
  RecursiveNoData,
} from './styles/TableStyles';
import {Row, Col, View} from '@unthinkable/react-core-components';
import {Checkbox} from '@unthinkable/react-checkbox';
import {resolveExp} from '@unthinkable/react-utils';
import {MoreActions} from '@unthinkable/react-actions';
import {TextRender, Column} from './Column';
import {SeparatorText} from './styles/TableStyles';

const CompositeFieldRender = ({fieldInfo, getColumnProps, ...props}) => {
  const {width, fullWidth} = fieldInfo;
  const column = getColumnProps ? getColumnProps(fieldInfo) : fieldInfo;
  let fieldRender = <Column {...props} {...column} />;
  if (width || fullWidth) {
    fieldRender = (
      <View style={fullWidth ? {flex: 1} : {width}}>{fieldRender}</View>
    );
  }
  return fieldRender;
};

export const CompositeFieldsRender = props => {
  let {
    fields,
    separator: cellSeparator = '|',
    separatorComponent: cellSeparatorComponent,
    skipSeparator: cellSkipSeparator,
    render,
    direction = 'column',
    ...restProps
  } = props;

  fields = fields?.filter(field => {
    if (field) {
      const {visible = true} = field;
      if (typeof visible == 'function') return visible(restProps);
      return visible;
    }
  });

  if (!fields?.length) {
    return null;
  }

  const renderSeparator = fieldInfo => {
    const {
      separatorComponent = cellSeparatorComponent,
      separator = cellSeparator,
    } = fieldInfo;
    return (
      separatorComponent || (
        <SeparatorText styles={props.styles}>{separator}</SeparatorText>
      )
    );
  };

  const Component = direction === 'row' ? Row : Col;
  return (
    <Component>
      {fields.map(fieldInfo => {
        if (Array.isArray(fieldInfo)) {
          fieldInfo = {fields: fieldInfo};
        }
        if (fieldInfo.fields) {
          let {
            direction: innerDirection,
            fields: innerFields,
            skipSeparator = cellSkipSeparator,
          } = fieldInfo;
          if (!innerDirection) {
            innerDirection = direction === 'row' ? 'col' : 'row';
          }

          const rowComponents = [];
          innerFields.forEach(innerFieldInfo => {
            const renderComponent = (
              <CompositeFieldRender fieldInfo={innerFieldInfo} {...restProps} />
            );
            if (renderComponent) {
              if (rowComponents.length && !skipSeparator) {
                rowComponents.push(renderSeparator(fieldInfo));
              }
              rowComponents.push(renderComponent);
            }
          });
          return rowComponents.length ? (
            direction === 'row' ? (
              <Row gap={4} style={{alignItems: 'center'}}>
                {rowComponents}
              </Row>
            ) : (
              <Col>{rowComponents}</Col>
            )
          ) : null;
        } else {
          return <CompositeFieldRender fieldInfo={fieldInfo} {...restProps} />;
        }
      })}
    </Component>
  );
};

export const MultiCurrencyRender = props => {
  const {row, currencyType, formatOptions} = props;
  const currencyTypeValue = resolveExp(row, currencyType);
  return (
    <TextRender
      {...props}
      formatOptions={{
        ...formatOptions,
        currency: currencyTypeValue,
      }}
    />
  );
};

export const RecursiveRender = ({
  indentWidth = 20,
  indent = true,
  level,
  recursive: {expanded, toggleExpand, hasChildren} = {},
  children,
  selection,
  ...props
}) => {
  const {styles} = props;
  let selectionComponent = void 0;
  if (selection) {
    selectionComponent = <SelectionRender {...props} selection={selection} />;
  }
  const toggleIconComponent = (
    <RecursiveIconContainer styles={styles}>
      {hasChildren ? (
        <RecursiveIconTouchable styles={styles} onPress={toggleExpand}>
          <RecursiveIcon styles={styles} expanded={expanded} />
        </RecursiveIconTouchable>
      ) : (
        <RecursiveNoData styles={styles} />
      )}
    </RecursiveIconContainer>
  );

  let render = (
    <RecursiveColumnRow styles={styles}>
      {toggleIconComponent}
      {selectionComponent}
      {children || <TextRender {...props} />}
    </RecursiveColumnRow>
  );
  if (indent !== false) {
    render = (
      <RecursiveColumnIndent level={level} indentWidth={indentWidth}>
        {render}
      </RecursiveColumnIndent>
    );
  }
  return render;
};

export const MoreActionsRender = ({styles, actions, ...props}) => {
  return (
    <MoreActions
      modalPlacement="end"
      {...props}
      actions={actions}
      styles={styles?.moreActions}
    />
  );
};

export const SelectionRender = ({
  row,
  index,
  isSelected,
  indeterminate,
  toggleSelection,
  styles,
  selection,
}) => {
  return (
    <Checkbox
      value={isSelected}
      onChangeValue={(_, e) => toggleSelection(row, index, e)}
      indeterminate={indeterminate}
      styles={styles?.checkbox}
    />
  );
};

export const HeaderSelectionRender = ({
  isAllSelected,
  toggleAllSelection,
  styles,
  isIndeterminate,
  disableHeaderSelection,
  selection,
  ...props
}) => {
  if (selection === true) selection = {};
  let {actions, onError} = selection;

  if (typeof actions == 'function') actions = actions(props);

  if (actions) {
    if (!Array.isArray(actions)) {
      actions = [actions];
    }
    actions = actions.map(action => {
      if (action) {
        const {resetSelection = true, ...restAction} = action;
        if (resetSelection) {
          const {onSuccess} = restAction;
          restAction.onSuccess = async props => {
            await onSuccess?.(props);
            props.resetSelection?.();
          };
        }
        return restAction;
      }
      return action;
    });
  }

  let indeterminate = isIndeterminate?.();
  let checkboxProps = {styles: styles?.checkbox, indeterminate};
  if (disableHeaderSelection) {
    if (!indeterminate && props.selectedIds?.length) {
      checkboxProps.indeterminate = true;
    }
    checkboxProps.disabled = true;
  }

  return (
    <Row style={{alignItems: 'center'}}>
      <Checkbox
        value={isAllSelected?.()}
        onChangeValue={e => toggleAllSelection(e, indeterminate)}
        {...checkboxProps}
      />
      {actions ? (
        <MoreActions
          {...props}
          onError={onError}
          actions={actions}
          styles={styles?.moreActions}
        />
      ) : (
        void 0
      )}
    </Row>
  );
};
