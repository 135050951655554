import {
  useFetchTableData,
  useFetchFormData as useFetchFormDataHandler,
} from '@unthinkable/react-data-handler';
import {useAppStateContext} from '../providers/AppState';
import {useModuleGlobalParams} from './useModuleGlobalParams';

export const useFetchData = props => {
  const {fetch} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);
  return useFetchTableData({fetch, globalParams, ...props});
};

export const useFetchFormData = props => {
  const {fetch} = useAppStateContext();
  return useFetchFormDataHandler({fetch, mode: 'edit', ...props});
};
