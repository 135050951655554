import React from 'react';

import {TabView} from '../../../components/tab/TabView';
import {ProjectTeamTasks} from './TasksTable';
import {ProjectEfficiencyTable} from './EfficiencyTable';

export const ProjectTeamTab = props => {
  const {route: {params} = {}, user} = props;

  const tabs = {
    plan: {
      label: 'Plan',
      view: <ProjectTeamTasks user={user} />,
    },
    output: {
      apiKey: 'active',
      label: 'Output',
      view: <ProjectEfficiencyTable user={user} />,
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
