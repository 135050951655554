import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {EmployeeTabs} from './EmployeeList';
import {DeploybleBenchTabs} from './BenchResource';
import {AllocationOnOrderTypeBasisTab} from '../../order/views/AllocationTab';
import {ProjectResourceWorkingTabView} from '../../order/views/ProjectResourceWorkingTabView';
import {ProjectWiseAllocationTab} from '../../order/views/ProjectWiseAllocationsTab';
import {ResourceAllocationTab} from '../../order/views/ResourceAllocationTab';
import {AllocatedResourceTab} from '../../order/views/AllocatedResourceTab';
import {PracticeWiseOpportunityTab, PracticeWiseScheduledOpportunityTab} from '../../order/views/PracticeWiseOpportunityTab';
import {useTheme} from '@unthinkable/react-theme';
import {InternalProjectAndPocsTabs} from './InternalProjectAndPocs';
import {ShadowEmployeesTabs} from './ShadowEmployees';
import {ReplacementEmployeesTabs} from './ReplacementEmployees';
import {TrainingEmployeesTabs} from './TrainingEmployees';
import {ConsumableAndNotAllignedTabs} from './ConsumableAndNotAlligned';
import {ActiveStaffAugmentationOpportunityTableForEmployeeMenu} from '../../leadGeneration/views/StaffAugmentationTable';
import {FixedTab, TAndMTab} from '../../order/views/TAndMBilled';
import {NonBilledResignedTabs} from './NonBilledResigned';
import {BilledResignedTabs} from './BilledResigned';
import {PlanForDeploymentTab} from './PlanForDeployment';
import {MarkIdentifyForSwappingTabs} from './MarkIdentifyForSwapping';
import {
  OrderTableWithDueAllocations,
  OrderTableWithDueAllocationsTab,
} from '../../order/views/OrderTable';
import {PipResourceTabs} from './PipResourceTabs';
import {FixedOrderWiseAllocationsTab} from '../../order/views/FixedOrderWiseAllocations';
import {RfrTatTabs} from './RfrTATTabs';
import {AllProjectTabs} from '../../pmt/views/ProjectsTable';
import {EmployeeTeamTabs} from './EmployeeTeamTabs';
import {PartialDeploybleBenchTabs} from './PartialDeployableBench';
import {
  RFRMentorWise,
  RFRMentorWiseComparitive,
  RFRResourceWise,
  RFRResourceWiseComparitiveReport,
} from '../../order/views/ResourceWiseRFRSuccessTabs';
import {OrderWiseAllocationTab} from '../../order/views/OrderWiseAllocationsTab';
import {EnggBilledTab} from './EnggBilledTab';
import {EnggNonBilledTab} from './EnggNonBilledTab';
import {EnggAllResourceTabs} from './EnggAllResourceTabs';
import {NonEnggTeams} from './NonEnggTeams';
import {ManagementCostTable} from './ManagementCostReportTable';
import {EngGrossMarginReport} from './EngGrossMarginReport';
import { ActiveEngineeringOpportunitiesListForEmployeeMenu } from '../../engineeringOpportunities/views/EngineeringOpportunitiesActiveList';

export const EmployeeMenu = props => {
  const icon = useTheme('icons');
  const menus = [
    {
      label: 'All',
      id: 'All',
      view: <EmployeeTabs />,
    },
    {
      label: 'Engg.',
      section: true,
      menus: [
        {
          label: 'All',
          id: 'AllEngg',
          view: <EnggAllResourceTabs />,
        },
        {
          label: 'Billed',
          view: <EnggBilledTab />,
        },
        {
          label: 'Non-billed',
          view: <EnggNonBilledTab />,
        },
      ],
    },
    {
      label: 'Non Engg.',
      view: <NonEnggTeams />,
      api: '/employees/nonprojectteams',
    },
    // {
    //   label: 'Non-Billed (Engg.)',
    //   section: true,
    //   menus: [
    //     {
    //       label: 'Deployable Bench',
    //       view: <DeploybleBenchTabs />,
    //       api: '/employees/bench',
    //       limit: 2000,
    //     },
    //     {
    //       label: 'Internal Project and POCs',
    //       view: <InternalProjectAndPocsTabs />,
    //       api: '/employees/internalresources',
    //       limit: 2000,
    //       addOnFilter: {
    //         is_internal: true,
    //         is_shadow: {$in: [false, null, true]},
    //       },
    //     },
    //     {
    //       label: 'Shadow',
    //       view: <ShadowEmployeesTabs />,
    //       api: '/employees/internalresources',
    //       limit: 2000,
    //       addOnFilter: {
    //         is_shadow: true,
    //         is_fixed_shadow: {$in: [null, false, true]},
    //         is_front_facing_shadow: {$in: [null, false, true]},
    //       },
    //     },
    //     {
    //       label: 'Planned Deployment',
    //       view: <PlanForDeploymentTab />,
    //       api: '/employee/planForDeployment',
    //       eventSourceId: 'EmployeeAssignment',
    //       limit: 2000,
    //     },
    //     {
    //       label: 'Replacements',
    //       view: <ReplacementEmployeesTabs />,
    //       api: '/employees/internalresources',
    //       limit: 2000,
    //       addOnFilter: {
    //         bench_training_type: {
    //           $in: ['Project Shadow', 'KT For Swapping', 'KT For Resignation'],
    //         },
    //         is_actual_allocation: {$in: [null, false, true]},
    //         is_shadow: true,
    //       },
    //     },
    //     {
    //       label: 'Training',
    //       view: <TrainingEmployeesTabs />,
    //       api: '/employees/internalresources',
    //       limit: 2000,
    //       addOnFilter: {
    //         bench_training_type: {$in: ['Offline', 'RFR']},
    //         is_actual_allocation: {$in: [null, false, true]},
    //       },
    //     },
    //     {
    //       label: 'Not aligned',
    //       view: <ConsumableAndNotAllignedTabs />,
    //       api: '/employees/no-allocation',
    //       limit: 2000,
    //     },
    //     {
    //       label: 'Partially Deployable',
    //       view: <PartialDeploybleBenchTabs />,
    //       api: '/employees/partialDeployablebench',
    //       limit: 2000,
    //     },
    //   ],
    // },
    {
      group: true,
      menus: [
        {
          label: 'Allocations Due',
          section: true,
          menus: [
            {
              label: 'Billed Requests',
              view: <OrderTableWithDueAllocationsTab key="billed" />,
            },
            {
              label: 'Internal Requests',
              view: (
                <OrderTableWithDueAllocationsTab
                  key="internal"
                  employeeMenu={true}
                />
              ),
            },
            {
              label: 'Skillwise',
              view: <AllocationOnOrderTypeBasisTab />,
            },
          ],
        },
      ],
    },
    {
      group: true,
      menus: [
        {
          label: 'Interview for opportunities',
          view: <ActiveEngineeringOpportunitiesListForEmployeeMenu />,
        },
      ],
    },
    {
      group: true,
      menus: [
        {
          label: 'Anchor Resource',
          view: <MarkIdentifyForSwappingTabs />,
        },
      ],
    },
    {
      label: 'Billed Resignation',
      view: <BilledResignedTabs />,
    },
    {
      label: 'Non Billed Resignation',
      view: <NonBilledResignedTabs />,
    },
    {
      label: 'Allocations',
      section: true,
      menus: [
        {
          label: 'Fixed Order Wise Allocation',
          view: <FixedOrderWiseAllocationsTab fixOrderForRm={true} />,
        },
        {
          label: 'T&M Order Wise Allocations',
          view: <OrderWiseAllocationTab />,
        },
        {
          label: 'Project Wise Allocations',
          view: <ProjectWiseAllocationTab />,
        },
        {
          label: 'Employee Wise Allocations',
          view: <AllocatedResourceTab />,
        },
      ],
    },
    // {
    //   label: 'Work Load',
    //   view: <EmployeeWorkloadTabs fromEmployeeMenu={true} />,
    // },
    {
      label: 'Reports',
      section: true,
      menus: [
        {
          label: 'Resource Allocation',
          view: <ResourceAllocationTab />,
        },
        {
          label: 'RFR Success',
          onPress: () => {
            {
              props.navigation.navigate('success-reports-menu', {});
            }
          },
        },

        {
          label: 'Fullfilment TAT',
          view: <RfrTatTabs />,
        },
      ],
    },
    {
      label: 'Project',
      view: <AllProjectTabs />,
    },
    {
      label: 'Team',
      view: <EmployeeTeamTabs />,
    },
    {
      label: 'Project Resource Working',
      view: <ProjectResourceWorkingTabView />,
    },
    {
      label: 'PIP Resources',
      view: <PipResourceTabs />,
    },

    {
      label: 'Eng. gross margins report',
      view: <EngGrossMarginReport />,
    },
    // {
    //   label: 'Management cost report',
    //   view: <ManagementCostTable />,
    // },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

const successMenus = [
  {
    label: 'Practice Wise',
    view: <PracticeWiseOpportunityTab />,
  },
  {
    label: 'Resource Wise',
    view: <RFRResourceWise />,
  },
  {
    label: 'Mentor Wise',
    view: <RFRMentorWise />,
  },
];

const scheduledMenus = [
  {
    label: 'Practice Wise',
    view: <PracticeWiseScheduledOpportunityTab />,
  },
  {
    label: 'Resource Wise',
    view: <RFRResourceWiseComparitiveReport />,
  },
  {
    label: 'Mentor Wise',
    view: <RFRMentorWiseComparitive />,
  },
];

export const SuccessReportMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={successMenus} />;
};


export const ScheduledReportMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={scheduledMenus} />;
};
