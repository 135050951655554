import React from 'react';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';
import {SearchFilter} from '../../../components/filter/Filters';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';

export const EmployeeTeamOrganizationEfficiency = props => {
  const {onChangeFilter, filterValues, searchValue} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {
    route: {params},
    navigation
  } = props;
  let {params: filterParams = {}} = filterValues;
  const {employee_team} = params;
  return (
    <Table
      {...props}
      renderHeader={() => (
        <TableHeader
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
          ]}
        />
      )}
      api={`/teams/${employee_team?._id}/employee-team-organization-efficiency`}
      limit={2000}
      onChangeFilter={onChangeFilter}
      params={{...params, ...filterParams}}
      search={searchValue}
      searchFields={['name']}
      columns={[
        {
          header: 'Employee',
          field: 'name',
          type: 'text',
        },
        {
          width: 300,
          header: 'Workload',
          field: 'workload',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          onPress:({row})=>{
            navigation.navigate('monthly-workload-overview',{...params,employee:row})
          }
        },
        {
          width: 300,
          header: 'Reviewer Workload',
          field: 'reviewer_workload',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          onPress:({row})=>{
            navigation.navigate('monthly-workload-overview',{...params,reviewer:row})
          }
        }
      ]}
    />
  );
};
