import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const FinetuneDuplicateForm = props => {
  const {
    route: {params},
    header = 'Add Usecase',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/finetune/duplicate-usecase',
    eventSourceId: ['useCaseAdded'],
  });
  const {defaultValues, usecase_id} = params;

  return (
    <Form
      fields={{
        name: 1,
        reference_model: 1,
      }}
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        usecase_id,
        ...defaultValues,
      }}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
        {
          label: 'AIprompt',
          field: 'aiprompt',
          type: 'autoComplete',
          api: '/aiprompts',
          filter: {for_crm: true},
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};
