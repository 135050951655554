import React from 'react';
import {useAppStateContext} from '../../../providers/AppState';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {
  PerformanceUnbilledReportTableAm,
  PerformanceUnbilledReportUnAssignedTable,
} from '../../pmt/views/PerformanceReportTable';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {NewProjectUnbilledTab} from './NewProjectTab';

const ProjectGroup = createMenuNavigator();
export const ProjectUnbilledNavigatorAm = props => {
  const {
    route: {params},
  } = props;
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const {employee} = user;
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <ProjectGroup.Navigator
      // canAccess={canAccess}
      data-navigator-id="ProjectGroup"
      {...props}>
      <ProjectGroup.Screen
        data-resource-id="ProjectBilledNew"
        name="New"
        props={{
          user: user,
          addOnParamFilter: {
            account_owner: employee?._id,
            project_status: 'a',
            projecttype: 'i',
          },
          tabName: 'unbilled',
          ...props,
        }}
        component={NewProjectUnbilledTab}
        options={{
          label: 'New',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectActive"
        name="Unassigned"
        props={{
          filter: {
            project_status: 'a',
            projecttype: 'i',
            is_project_assign: {$in: [null, false]},
            account_owner: employee?._id,
          },
          user: user,
          tab: 'Unassigned',
          tabName: 'unbilled',
        }}
        component={PerformanceUnbilledReportUnAssignedTable}
        options={{
          label: 'Unassigned',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectActive"
        name="Assigned"
        props={{
          filter: {
            project_status: 'a',
            projecttype: 'i',
            is_project_assign: true,
          },
          user: user,
          tab: 'Assigned',
          tabName: 'unbilled',
          ...props,
        }}
        component={PerformanceUnbilledReportTableAm}
        options={{
          label: 'Assigned',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectHold"
        name="On-Hold-unbilled"
        props={{
          filter: {
            project_status: 'h',
            projecttype: 'i',
          },
          tab: 'On-hold',
          project_status: 'h',
        }}
        component={PerformanceUnbilledReportTableAm}
        options={{
          label: 'On-hold',
        }}
      />
      <ProjectGroup.Screen
        data-resource-id="ProjectCompleted"
        name="Completed-unbilled"
        props={{
          filter: {
            project_status: 'c',
            projecttype: 'i',
          },
          tab: 'Completed',
          project_status: 'c',
        }}
        component={PerformanceUnbilledReportTableAm}
        options={{
          label: 'Closed',
        }}
      />
    </ProjectGroup.Navigator>
  );
};
