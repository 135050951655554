import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {
  ActiveCandidate,
  ClosedCandidate,
  OnHoldCandidate,
} from './CandidateTable';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';

export const JobOpeningCandidateTab = props => {
  let {route: {params} = {}} = props;
  const {job_details = {}, selectedTab} = params || {};
  const addOnFilter = {job_opening: job_details?._id};
  const {searchValue, onChangeFilter} = useFilter();
  const tabs = {
    active: {
      label: 'Active',
      view: <ActiveCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: {$in: ['Sortlisted', 'Interview', 'Offered', 'Offer Accepted']},
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
      actions: [
        <AddButton
          disabled={false}
          title=" Candidate"
          view="add-candidate"
          params={params}
        />,
      ],
    },
    closed: {
      label: 'Closed',
      view: <ClosedCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: {$in: ['Offer Rejected', 'Joined', 'Rejected', 'Dropped']},
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
    joined: {
      label: 'Hold',
      view: <OnHoldCandidate addOnFilter={addOnFilter} {...props} />,
      api: `/candidates`,
      filter: {
        status: 'On Hold',
      },
      addOnFilter,
      search: searchValue,
      searchFields: ['name', 'email'],
      eventSourceId: 'candidate',
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      search={searchValue}
      searchFields={['name', 'email']}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Candidate"
        />,
      ]}
      selectedTab={selectedTab}
    />
  );
};
