import React from 'react';
import {TabView} from '../../../components/tab';
import {AddButton} from '../../../components/button/AddButton';
import {EmployeeTicketList} from './EmployeeTicketLists';

export const EmployeeTicketTabs = props => {
  const {
    route: {params},
    user: {employee = {}} = {},
  } = props;

  const tabs = [
    {
      label: 'Open',
      view: (
        <EmployeeTicketList
          sort={{raised_on_date: -1}}
          addOnFilter={{
            status: {$in: ['In Progress', 'Draft', 'Reopen']},
          }}
        />
      ),
      actions: [
        <AddButton
          view="create-new-ticket"
          title="New Ticket"
          params={params}
        />,
      ],
      api: '/tickets',
      filter: {
        raised_by: employee?._id,
        status: {$in: ['In Progress', 'Draft', 'Reopen']},
      },
      eventSourceId: 'tickets',
    },
    {
      label: 'Under Observation',
      view: (
        <EmployeeTicketList
          addOnFilter={{
            status: {$in: ['Temporarily Closed']},
          }}
          isTempClosed
          sort={{temporarily_closed_on: -1}}
        />
      ),
      api: '/tickets',
      filter: {
        raised_by: employee?._id,
        status: {$in: ['Temporarily Closed']},
      },
      eventSourceId: 'tickets',
    },
    {
      label: 'Closed',
      view: (
        <EmployeeTicketList
          isClosed
          addOnFilter={{
            status: {$in: ['Closed']},
          }}
          sort={{closed_on: -1}}
        />
      ),
      api: '/tickets',
      filter: {
        raised_by: employee?._id,
        status: {$in: ['Closed']},
      },
      eventSourceId: 'tickets',
    },
  ];

  return <TabView tabs={tabs} {...props} {...params} />;
};
