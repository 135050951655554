import {Image, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';

export const IconWithBackground = ({color: propColor, icon: propIcon}) => {
  let {icons, colors} = useTheme();

  let icon = icons?.[propIcon];
  let color = colors?.[propColor] || 'transparent';

  if (!icon) {
    return null;
  }

  return (
    <View
      style={{
        height: 28,
        width: 28,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
        borderRadius: 4,
      }}>
      <Image source={icon} />
    </View>
  );
};
