import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Durations} from './components/Durations';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {TableHeader} from '../../../components/table/Headers';
import {Text} from '@unthinkable/react-core-components';

const RenderOrder = ({row}) => {
  return (
    <PrimaryColumnCard
      primaryTitle={row?.order?.order_number}
      items={[
        {
          value: row?.customer?.name,
        },
      ]}
    />
  );
};

export const EmployeeRequestTable = props => {
  let {
    navigation = {},
    route = {},
    filterParams = {},
    isResourceRequest,
    filterValues = {},
  } = props || {};

  let {params = {}} = route || {};
  const {row = {}} = params;
  const {filter = {}} = filterValues;
  return (
    <Table
      api={`/orderRequest/pending/ot`}
      filter={{filterParams, ...filter, order: row?._id}}
      params={params}
      eventSourceId="orderresourcerequests"
      columns={[
        {
          field: 'tool.name',
          header: 'Tool',
          type: 'colorTag',
          colorField: 'tool.color',
        },
        {
          render: CountCellRender,
          count_field: 'count',
          header: 'Due Aloc.',
          styleForCountField: true,
          aggregate: true,
          field: 'count',
          align: 'right',
          onPress: ({row = {}}) => {
            if (row?.count) {
              !isResourceRequest &&
                navigation.navigate('create-fixed-order-assignment', {
                  row,
                });
            }
          },
        },
      ]}
    />
  );
};

export const ProjectWiseEmployeeRequestTable = props => {
  let {route = {}, filterParams = {}, filterValues = {}} = props || {};
  let {params = {}} = route || {};
  const {row = {}} = params;
  const {filter = {}} = filterValues;
  return (
    <Table
      api={'/fixedProjectOrderRequest/pending'}
      filter={{filterParams, ...filter, project: row?._id}}
      params={params}
      eventSourceId="orderresourcerequests"
      renderHeader={() => <TableHeader title="Resource Request" />}
      columns={[
        {
          field: 'requested_date',
          header: 'Req. date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'tool.name',
          header: 'Skill',
          type: 'colorTag',
          colorField: 'tool.color',
        },
        {
          field: 'experience.experience',
          header: 'Experience',
        },
        {
          field: 'milestone_id.order_milestone_no',
          header: 'Milestone',
        },
        {
          type: 'date',
          field: 'from_date',
          header: 'From',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          type: 'date',
          field: 'to_date',
          header: 'To',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          type: 'number',
          field: 'hours',
          header: 'Hrs/Day',
        },
        {
          header: 'Status',
          field: 'status',
          render: ({row, styles = {rowText: {}}}) => {
            if (row?.status == 'allocated')
              return (
                <Text style={{...styles.rowText, color: '#07C838'}}>
                  Allocated
                </Text>
              );
            if (row?.status == 'pending')
              return (
                <Text style={{...styles.rowText, color: '#A3A3A3'}}>
                  Requested
                </Text>
              );
          },
        },
      ]}
    />
  );
};
