import React from 'react';
import {GraphView} from './GraphView';
import {useMultiTableData} from '../table/MultiDataTable';

export const DataEntityGraphView = props => {
  return (
    <GraphView
      focusable={true}
      direction="bottom-to-top"
      nodeField="model"
      relationshipKey="fields.reference_table"
      excludeRelationKey="is_secondary_reference"
      {...props}
    />
  );
};
export const AIDataEntityGraphView = props => {
  const aiProps = useMultiTableData(props);

  return (
    <GraphView
      focusable={true}
      direction="bottom-to-top"
      nodeField="model"
      relationshipKey="fields.reference_table"
      excludeRelationKey="is_secondary_reference"
      mapKey={'model'}
      {...aiProps}
    />
  );
};
