import {getCurrentMonth} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {GroupBy} from '../../../components/table/GroupBy';
import {useFilter} from '../../../controllers/useFilter';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useAppStateContext} from '../../../providers/AppState';
import {
  PendingInvoiceAllTable,
  PendingInvoiceCancelTable,
} from './PendingInvoiceTable';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'autoComplete',
      label: 'Order Type',
      placeholder: 'Select',
      options: [
        {label: 'T&M', value: 'r'},
        {label: 'Fixed', value: 'ot'},
      ],
      field: 'order_type',
      suggestionField: 'label',
      keyField: 'value',
      valueField: 'label',
    },
    {
      label: 'Cancel Date',
      type: 'date',
      field: 'cancelled_on',
    },
    {
      label: 'Due Date',
      type: 'date',
      field: 'due_date',
    },
    {
      type: 'autoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'organization',
    },
    {
      type: 'multiAutoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'multiAutoComplete',
      label: 'AM',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'account_owner_id',
      searchFields: ['name', 'official_email_id', 'employee_code'],
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project',
    },
    {
      type: 'autoComplete',
      field: 'status',
      label: 'Status',
      options: [
        'New',
        'Delivered',
        'Adjust',
        'Cancel',
        'Force Fully Closed',
        'Fully Invoiced',
        'Fully Paid',
        'In Progress',
        'Invoiced',
        'Less Amount Paid',
        'Partially Paid',
        'Partilly Invoiced',
        'Pending',
      ],
    },
  ];
};

const commonFilters = ({
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'All',
  params = {},
  groupBy = {},
  showGroupBy = false,
}) => {
  return [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search Invoice Number"
    />,
    showGroupBy && <GroupBy {...groupBy} />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
  ];
};

export const AllPendingInvoiceTab = props => {
  //
  const {route: {params} = {}, extraFilter = {}} = props;
  const dueDatePeriod = getCurrentMonth();
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: getCurrentMonth(),
    // },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
      due_date: {
        $gte: dueDatePeriod.from,
        $lte: dueDatePeriod.to,
      },
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {download} = useAppStateContext();
  const {Download} = useTheme('icons');
  const groupBy = useGroupBy({
    options: [
      !extraFilter?.account_owner_id && {
        label: 'AM',
        field: 'account_owner_id',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_amount.toFixed(2)} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const tabs = {
    allDeliveries: {
      label: 'All',
      view: (
        <PendingInvoiceAllTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{...filter, ...extraFilter}}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/pending-all-invoices`,
      addOnFilter: {...filter, ...extraFilter},
      search: searchValue,
      searchFields: 'delivery_number',
      actions: [
        ...commonFilters({
          searchValue,
          onChangeFilter,
          filterValues,
          applyFilter,
          filters,
          tab: 'All',
          params,
          groupBy,
          showGroupBy: true,
        }),
      ],
    },

    cancel_deliveries: {
      label: 'Cancel',
      view: (
        <PendingInvoiceCancelTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{...filter, ...extraFilter}}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/cancelled-invoices`,
      addOnFilter: {...filter, ...extraFilter},
      search: searchValue,
      searchFields: 'delivery_number',
      actions: [
        ...commonFilters({
          searchValue,
          onChangeFilter,
          filterValues,
          applyFilter,
          filters,
          tab: 'Cancel',
          params,
          groupBy,
          showGroupBy: true,
        }),
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
