// This component for floating help button. - Rajnish 03-09-2024

import React, {useState} from 'react';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {FloatingHelpButtonStyes} from './theme';

const FloatingHelpButton = props => {
  let {navigation, route: {params} = {}} = props;

  const [help, setHelp] = useState(false);
  const styles = useStyles(FloatingHelpButtonStyes);

  let {
    helpIcons,
    helpCharacterIcons,
    helpGuideCharacterIcons,
    floatingButtonContainer,
  } = styles || {};

  let icons = help ? helpCharacterIcons : helpIcons;

  if (params && params.screenId === 'help_screen_pmt') {
    icons = helpGuideCharacterIcons;
  }

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      onMouseEnter={() => setHelp(true)}
      onMouseLeave={() => setHelp(false)}
      onPress={() => {
        setHelp(false);
        navigation.setParams({
          itemId: params?.helpItemId,
          screenId: params?.helpScreenId,
        });
      }}
      style={floatingButtonContainer}>
      <Image source={icons} />
    </TouchableOpacity>
  );
};

export default FloatingHelpButton;
