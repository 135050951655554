import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab/TabView';
import {
  EsiCycleTable,
  LeaveCreditTable,
  SalaryCycleTable,
  SavingHeadsTable,
  TaxDeclarationSetupTable,
  TaxSlabsNewRegimeTable,
  TaxSlabsTable,
} from './SalarySetupTables';

export const SalarySetupTab = props => {
  let {
    route: {params},
  } = props;
  const tabs = {
    leave_credit: {
      label: 'Leave Credit',
      view: <LeaveCreditTable {...props} />,
      actions: [
        <AddButton
          title="Leave Credit"
          eventSourceId="leaveCredit"
          view="add-leave-credit"
          params={params}
        />,
      ],
    },
    esi_Cycle: {
      label: 'ESI Cycle',
      view: <EsiCycleTable {...props} />,
      actions: [
        <AddButton
          title="ESI Cycle"
          eventSourceId="esicycle"
          view="add-esi-cycle"
          params={params}
        />,
      ],
    },
    salary_cycle: {
      label: 'Salary Cycle',
      view: <SalaryCycleTable {...props} />,
      actions: [
        <AddButton
          title="Salary Cycle"
          eventSourceId="salarycycle"
          view="add-salary-cycle"
          params={params}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TaxSetupTabs = props => {
  let {
    route: {params},
  } = props;
  const tabs = {
    declaration_section: {
      label: 'Declaration Sections',
      view: <TaxDeclarationSetupTable {...props} />,
      actions: [
        <AddButton
          title="Declation Section"
          eventSourceId="taxsetup"
          view="add-declaration-section"
          params={params}
        />,
      ],
    },
    saving_heads: {
      label: 'Saving Heads',
      view: <SavingHeadsTable {...props} />,
      actions: [
        <AddButton
          title="Saving Head"
          eventSourceId="taxsetup"
          view="add-saving-heads"
          params={params}
        />,
      ],
    },
    tax_slabs: {
      label: 'Tax Slabs',
      view: <TaxSlabsTable {...props} />,
      actions: [
        <AddButton
          title="Tax Slab"
          eventSourceId="salarycycle"
          view="add-tax-slab"
          params={params}
        />,
      ],
    },
    tax_slab_new_regime: {
      label: 'Tax Slabs New Regime',
      view: <TaxSlabsNewRegimeTable {...props} />,
      actions: [
        <AddButton
          title="Tax Slab New Regime"
          eventSourceId="salarycycle"
          view="add-tax-slab-new-regime"
          params={params}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
