import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const TargetForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/targets',
    ...props,
  });
  const params = props.route.params;
  const {department, product} = params;
  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Target'}
      submitAction={'Save'}
      defaultValues={{
        department: department?._id,
        product: product?._id,
        status: 'active',
      }}
      layoutFields={[
        {
          label: 'Target',
          field: 'target_name',
          type: 'text',
        },
        {
          label: 'KPI',
          field: 'kpi',
          type: 'autoComplete',
          suggestionHeader: 'Select an option',
          valueField: 'kpi',
          api: `/kpis`,
          filter: {
            department: department?._id,
          },
          suggestionField: 'kpi',
          onChangeValue: (kpi, e, {setFieldValue}) => {
            setFieldValue('previous_target_value', kpi?.previous_value || null);
          },
        },
        {
          label: 'Previous Target Value',
          field: 'previous_target_value',
          type: 'text',
        },
        {
          label: 'Target Value',
          field: 'target_value',
          type: 'text',
        },
        {
          label: 'Complete date',
          field: 'completed_on',
          type: 'date',
        },
        {
          label: 'Resources',
          field: 'resources',
          type: 'multiAutoComplete',
          suggestionHeader: 'Select an option',
          valueField: 'name',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          api: '/employeeSuggestions',
        },
        {
          label: 'Leaders',
          field: 'leaders',
          type: 'multiAutoComplete',
          suggestionHeader: 'Select an option',
          valueField: 'name',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          api: '/employeeSuggestions',
        },
      ]}
    />
  );
};
