import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
  UserAvatarForEmployeeUser,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import {AddButton} from '../../../components/button/AddButton';
import { SearchFilter } from '../../../components/filter/Filters';

export const EmployeeUserList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  // const {markPip, markPotentialPip} = employeeController(props);
  // const invoke = useInvoke({
  //   method: 'put',
  //   eventSourceId: 'allocatedResource',
  // });
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={'/users'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarForEmployeeUser name={row?.name} color={row?.color} />
            );
          },
          //   width: 280,
        },
        {
          header: 'Type',
          field: 'userType',
          type: 'text',
          width: 200,
        },
        {
          header: 'Email',
          field: 'email',
          type: 'text',
          width: 300,
        },
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Parichay Details',
      //     onPress: ({row}) => {
      //       navigation.navigate('edit-employee-parichay-form', {row: row});
      //     },
      //   },
      //   // markIdentifyForSwapping({invoke}),
      // ]}
    />
  );
};

export const EmployeeUserTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    // params: {
    //   period: getCurrentFY(),
    // },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Users',
    api: '/users',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeUserList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <AddButton
        title="User"
        view="add-employee-user-form"
        params={props?.route?.params}
      />,
    ],
  };

  return <TabView tabs={tabs} {...props} />;
};
