import React from 'react';
import {
  TargetAudienceTable,
} from './TargetAudienceTable';
import {TabView} from '../../../components/tab/TabView';

export const TargetAudienceTabs = props => {
  const tabs = {
    confirmed: {
      label: 'Confirmed',
      view: <TargetAudienceTable tabFilter={{status: 'Confirmed'}} />,
    },
    in_experiment: {
      label: 'In Experiment',
      view: <TargetAudienceTable tabFilter={{status: 'In Experiment'}} />,
    },
    retired: {
      label: 'Retired',
      view: <TargetAudienceTable tabFilter={{status: 'Retired'}} />,
    },
    failed: {
      label: 'Failed',
      view: <TargetAudienceTable tabFilter={{status: 'Failed'}} />,
    },
  };
  return <TabView {...props} tabs={tabs} />;
};