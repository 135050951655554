import React from 'react';
import {RecursiveTable} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {leadControllers} from '../controllers/leadControllers';
import {useTheme} from '@unthinkable/react-theme';
import {TextRenderer} from '../../../components/form/Renderers';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';
import {Row, View} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';
import {RecursiveRender} from '@unthinkable/react-table';
import {AutoCompleteFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

const ChildrenRender = props => {
  const {row} = props;
  const {name, experiment_status} = row;
  const {colors, fonts} = useTheme();
  const color =
    experiment_status == 'Confirmed'
      ? colors['SUCCESS_HIGH']
      : colors['ACCENT1_HIGH'];
  return (
    <Row gap={4} style={{alignItems: 'center'}}>
      <TextRenderer value={name} />
      {experiment_status && (
        <Row gap={4} style={{alignItems: 'center'}}>
          <View
            style={{
              width: 6,
              height: 6,
              borderRadius: 3,
              backgroundColor: color,
            }}
          />
          <TextRenderer
            value={experiment_status}
            style={{
              ...fonts.CAPTION_SMALL,
              color: color,
            }}
          />
        </Row>
      )}
    </Row>
  );
};

const NAME = {
  field: 'name',
  header: 'Name',
  render: props => (
    <RecursiveRender
      {...props}
      // children={<ChildrenRender {...props}/>}
    />
  ),
  indent: false,
  responsive: 'sm',
};

export const STATUS = {
  RETIRED: 'Retired',
  FAILED: 'Failed',
  IN_EXPERIMENT: 'In Experiment',
  CONFIRMED: 'Confirmed',
};

const valuePropositionRender = ({
  params,
  navigation,
  showValuePropositions = () => {},
  addnewValuePropositions,
}) => ({
  render: CountCellRender,
  header: 'V Propositions',
  align: 'right',
  count_field: 'value-proposition-count',
  responsive: 'sm',
  width: 150,
  onPlusPress: addnewValuePropositions,
  onPress: showValuePropositions,
});
const contactsRender = ({params, navigation, showContacts = () => {}}) => ({
  render: CountCellRender,
  header: 'Prospects',
  align: 'right',
  count_field: 'contacts-count',
  responsive: 'sm',
  width: 150,
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-targetaudience-contacts`, {
      target_audience: {_id: row._id, name: row.name},
    });
  },
  onPress: showContacts,
});

export const RenderView = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="View"
      style={{...rowText, textAlign: 'center', color: colors['ACCENT1_HIGH']}}
    />
  );
};

const targetAudienceActions = ({row, navigation, invoke, ...params}) => {
  const uri = `/buyerpersonas/${row?._id}`;
  const eventSourceId = 'targetAdded';
  return [
    {
      title: 'Edit',
      onPress: ({row}) => {
        navigation.navigate('edit-target-audience', {...params, row});
      },
      visible: ({row}) => row?.status != STATUS.FAILED,
    },

    {
      title: 'Confirm',
      onPress: () => {
        navigation.navigate('dialogue-box-for-date', {
          label: 'Confirmed Date',
          header: 'Confirmed Date',
          uri: uri,
          eventSourceId: eventSourceId,
          field: 'confirmed_on',
          status: STATUS.CONFIRMED,
        });
      },
      visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
    },
    {
      title: 'Retire',
      onPress: () => {
        navigation.navigate('dialogue-box-for-date', {
          label: 'Retired Date',
          header: 'Retired Date',
          uri: uri,
          eventSourceId: eventSourceId,
          field: 'retired_on',
          status: STATUS.RETIRED,
        });
      },
      visible: ({row}) =>
        row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
    },
    {
      title: 'Push back to experiment',
      onPress: ({row}) => {
        navigation.navigate('dialogue-box-for-date', {
          label: 'In Experiment Date',
          header: 'In Experiment Date',
          uri: uri,
          eventSourceId: eventSourceId,
          field: 'experiment_on',
          status: STATUS.IN_EXPERIMENT,
        });
      },
      visible: ({row}) => row?.status == STATUS.RETIRED,
    },
    {
      title: 'Fail',
      onPress: ({row}) => {
        navigation.navigate('dialogue-box-for-date', {
          label: 'Failed Date',
          header: 'Failed Date',
          uri: uri,
          eventSourceId: eventSourceId,
          field: 'failed_on',
          status: STATUS.FAILED,
        });
      },
      visible: ({row}) => row?.status != STATUS.FAILED,
    },
    {
      title: 'Delete Permanently',
      confirm: {
        message: 'Are you sure you want to delete the record?',
      },
      onPress: ({row}) => {
        invoke({
          uri: `/buyerpersonas/${row?._id}`,
          props: {
            status: 'deleted',
          },
        });
      },
      visible: ({row}) => row?.status == STATUS.RETIRED,
    },
  ];
};

const campaignActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 140,
  header: 'Campaigns',
  align: 'right',

  count_field: 'campaign_count',
  responsive: 'sm',

  onPress: ({row}) => {
    navigation.navigate('outreach-campaign-menu', {
      target_audience: row?._id,
      name: row?.name,
    });
  },
});

const OutreachTargetAudienceTable = props => {
  const {
    route: {params},
    navigation,
    searchValue,
    tabFilter = {},
    user,
  } = props;
  const {
    showTargetAudienceContacts,
    newValuePropositionsList,
    addnewValuePropositions,
  } = leadControllers({
    params,
    navigation,
  });
  const {organization} = params;
  let extraParams = {};
  if (user?.employee?._id) {
    extraParams.owner = user?.employee?._id;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['targetAdded'],
  });
  const {filterValues, onChangeFilter} = useFilter();
  return (
    <RecursiveTable
      eventSourceId={['targetAdded', 'valuePropositionAdded', 'prospectAdded']}
      search={searchValue}
      searchFields={'name'}
      api={`/targetaudiences/all`}
      renderHeader={() => (
        <TableHeader
          title="Target Audience"
          actions={[
            <AutoCompleteFilter
              placeholder="Select Owner"
              api="/employeeSuggestions"
              suggestionField="name"
              valueField="name"
              secondarySuggestionField="official_email_id"
              field="owner"
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <ObjectWiseTasks
              {...props}
              params={{...props?.route?.params}}
              object={{
                _id: '64ddbdfcc04e3fa90747b2f2',
                name: 'BuyerPersona',
              }}
            />,
            tabFilter?.status === 'In Experiment' && (
              <AddButton
                title="Target Audience"
                view="add-target-audience"
                params={props?.route?.params}
              />
            ),
          ]}
        />
      )}
      params={{...params, ...extraParams}}
      onRowPress={({row}) => {
        navigation.navigate('marketing-themes', {
          ...params,
          target_audience: row,
        });
      }}
      addOnFilter={{
        parent: {$eq: null},
        organization: organization,
        ...tabFilter,
        ...filterValues?.filter,
      }}
      defaultExpanded={true}
      columns={[
        NAME,
        {
          header: 'Owner',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        {
          header: 'Country',
          field: 'country.code',
          titleField: 'country.name',
          colorField: 'country.color',
          type: 'colorTag',
          width: 140,
        },
        {
          header: 'Industry',
          field: 'industry.code',
          titleField: 'industry.name',
          colorField: 'industry.color',
          type: 'colorTag',
          width: 150,
        },
        campaignActionCount({navigation, params}),
        valuePropositionRender({
          params,
          navigation,
          showValuePropositions: newValuePropositionsList,
          addnewValuePropositions,
        }),
        contactsRender({
          params,
          navigation,
          showContacts: showTargetAudienceContacts,
        }),
      ]}
      moreActions={({row}, params) => {
        return row?.status !== STATUS.FAILED && !row?.parent
          ? [
              ...targetAudienceActions({row, navigation, invoke, params}),
              {
                title: 'Tasks',
                onPress: ({row}) => {
                  navigation.navigate(`tasks-table`, {
                    ...params,
                    dataEntity: 'BuyerPersona',
                    source_id: row?._id,
                    row,
                    source: '64ddbdfcc04e3fa90747b2f2',
                    label: row?.name,
                  });
                },
              },
              {
                title: 'Add Target Audience',
                onPress: ({row}) => {
                  navigation.navigate('add-target-audience', {
                    ...params,
                    target_audience: row,
                  });
                },
              },
            ]
          : [];
      }}
      {...props}
    />
  );
};

export {OutreachTargetAudienceTable};
