import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {CommunicationTable} from './CommunicationTable';

export const CommunicationTabView = props => {
  const params = props?.route?.params;
  const {period} = params;

  let tabs = {};
  tabs.all = {
    label: 'All',
    view: (
      <CommunicationTable
        sort={{date: -1}}
        filter={{
          date: {
            $gte: period.from,
            $lte: period.to,
          },
        }}
        {...props}
      />
    ),
  };
  return <TabView tabs={tabs} {...props} />;
};
