import React from 'react';
import {useFilter} from '../../../controllers/useFilter';
import {SearchFilter} from '../../../components/filter/Filters';
import {OtherEmployeeTeamList} from './EmployeeTeamsList';
import {AddButton} from '../../../components/button/AddButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {TableHeader} from '../../../components/table/Headers';
import {IconButton} from '../../../components/button/IconButton';

export const NonEnggTeams = props => {
  const {departmentTitle} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter();

  const {params} = filterValues;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();

  return (
    <>
      <TableHeader
        title={departmentTitle ? departmentTitle : 'Non Engineers Team'}
        actions={[
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
            placeholder="Search"
          />,
          <IconButton
            icon={Download}
            onPress={() => {
              download({
                uri: '/exportNonProjectTeamReport',
                props: {
                  uri: '/employees/nonprojectteams',
                  parameters: {
                    params,
                  },
                },
              });
            }}
          />,
          !props?.fromMyView && (
            <AddButton {...props} title={'Team'} view="add-employee-team" />
          ),
        ]}
      />
      <OtherEmployeeTeamList
        searchValue={searchValue}
        params={params}
        filterValues={filterValues}
        {...props}
      />
    </>
  );
};
