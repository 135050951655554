import { Checkbox } from '@unthinkable/react-checkbox';
import { Chip } from '@unthinkable/react-chip';
import { Image, Row, Text } from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import moment from 'moment';
import React, { useState } from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {
    GroupFilter,
    SearchFilter
} from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { leadControllers } from '../controllers/leadControllers';

const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YYYY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const POCRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_detail?.[0]?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
        }}>
        {row?.contact_detail?.[0]?.designation}
      </Text>
    </Row>
  );
};

const NAME = {
  field: 'name',
  header: 'Prospects',
  type: 'text',
};

const INDUSTRY = {
  field: 'industry.name',
  colorField: 'industry.color',
  header: 'Industry',
  type: 'colorTag',
};

const EMPLOYEE_COUNT = {
  field: 'employee_count.number',
  header: 'Size',
  width: 100,
};

const OWNER = {
  header: 'Owner',
  field: 'owner',
  type: 'userAvatar',
};

const CHANNEL = {
  header: 'Channel',
  field: 'campaign_type.name',
  colorField: 'campaign_type.color',
  type: 'colorTag',
  labelField: 'label',
};

const COUNTRY = {
  type: 'colorTag',
  field: 'country.name',
  colorField: 'country.color',
  header: 'Country',
};

export const LoadProspectsTable = props => {
  let {
    route: {params},
    formProps,
    source,
  } = props;

  const {communications} = leadControllers(props);
  const {values = {}, setFieldValue} = formProps;
  const {loaded_prospects, target_audience} = values;
  const loadedProspectMap = {};
  if (Array.isArray(loaded_prospects)) {
    loaded_prospects.forEach(item => {
      loadedProspectMap[item] = 1;
    });
  }
  const [loadedProspects, setLoadedProspects] = useState(loadedProspectMap);
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    filterKey: 'loadProspectFilter',
    params: params,
    filter: {},
  });
  const {params: filterParams} = filterValues;

  return (
    <Table
      {...props}
      renderHeader={_ => (
        <TableHeader
          title="Prospects"
          actions={[
            <SearchFilter
              onChangeFilter={onChangeFilter}
              value={searchValue}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Account Owner',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'owner',
                },
              ]}
            />,
            <AddButton
              title="Prospect"
              view="add-prospect"
              params={{
                ...params,
              }}
            />,
            <AddButton
              title="Import"
              view="import-list"
              params={{
                ...params,
                source: source,
                model: 'PotentialCustomer',
              }}
            />,
          ]}
        />
      )}
      search={searchValue}
      searchFields={['name', 'email', 'website', 'linkedin_url']}
      api={`/prospect/active`}
      // params={params}
      filter={{
        industry: target_audience?.industry,
      }}
      eventSourceId={['Lead']}
      filterParams={filterParams}
      columns={[
        {
          render: ({row}) => (
            <Checkbox
              value={loadedProspects[row?._id]}
              onChangeValue={() => {
                setLoadedProspects(prev => {
                  if (prev[row?._id]) {
                    delete prev[row?._id];
                  } else {
                    prev[row?._id] = 1;
                  }
                  return prev;
                });
                setFieldValue('loaded_prospects', [
                  ...Object.keys(loadedProspects),
                ]);
              }}
            />
          ),
          width: 50,
        },
        NAME,
        EMPLOYEE_COUNT,
        COUNTRY,
        INDUSTRY,
        {
          header: 'POC',
          render: POCRender,
        },
        {
          header: 'Communication',
          render: LastCommunicationRender,
          onPress: communications,
        },
      ]}
    />
  );
};
