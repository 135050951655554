export const ContainerPreviewerStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    featureIcon: icons.StarIcon,
    modalHeaderContainer: {
      paddingTop: 12,
      paddingRight: 20,
      paddingBottom: 12,
      paddingLeft: 20,
      backgroundColor: colors.BACKGROUND,
    },
    modalContainerHeaderText: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
    container: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: colors.OUTLINE,
    },
    separator: {
      height: '100%',
      width: 10,
      backgroundColor: colors.BACKGROUND,
    },
  };
};
export const ContainerPreviewerHeaderStyles = ({
  theme: {fonts, colors, icons},
}) => {
  return {
    LeftCaret: icons.CaretLeft,
    RightCaret: icons.CaretRight,
    headerContainer: {
      paddingBottom: 8,
      backgroundColor: colors.BACKGROUND,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: colors.OUTLINE,
    },
    switchLabel: {...fonts.ACTION, color: colors.NEUTRAL_MEDIUM},
    switchStyle: {
      trackStyle: {
        cursor: 'pointer',
        height: 14,
        width: 26,
        backgroundColor: colors.NEUTRAL_LOW,
        borderRadius: 14,
      },
      ActiveTrackStyle: {
        cursor: 'pointer',
        height: 14,
        width: 26,
        backgroundColor: colors.BRAND_UPPER_MEDIUM,
        borderRadius: 14,
      },
      thumbStyle: {
        height: 12,
        width: 12,
        borderRadius: 100,
        backgroundColor: colors.BACKGROUND,
        position: 'absolute',
        transition: 'left 0.5s',
        top: 1,
        bottom: 1,
      },
    },
  };
};

export const ContainerSelectorStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    LeftCaret: icons.CaretLeft,
    RightCaret: icons.CaretRight,
    container: {},
    textContainer: {
      width: 180,
      overflow: 'hidden',
      alignItems: 'center',
    },
    containerLabel: {...fonts.CAPTION_LARGE, color: colors.NEUTRAL_LOW},
    containerNameText: {
      ...fonts.HEADING2,
      color: colors.NEUTRAL_HIGH,
      numberOfLines: 1,
      width: 150,
      textAlign: 'center',
    },
    moveIconContainer: {
      cursor: 'pointer',
      height: 36,
      width: 36,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      justifyContent: 'center',
      alignItems: 'center',
    },
    disableMoveIconContainer: {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
  };
};

export const ContainerInfoStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    FeatureIcon: icons.BlueStarIcon,
    infoContainer: {
      paddingTop: 12,
      paddingBottom: 12,
      backgroundColor: colors.BACKGROUND,
    },
    containerNameText: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_HIGH,
      numberOfLines: 1,
    },
    requirementName: {
      ...fonts.CAPTION_SMALL,
      color: colors.ACCENT1_HIGH,
      numberOfLines: 1,
      paddingTop: 3,
    },
    date: {...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM},
    remark: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const ShowContainerevisionStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    mainContainer: {
      width: 400,
      overflow: 'hidden',
      backgroundColor: colors.SURFACE2,
      padding: 12,
    },
  };
};

export const RevisionStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    modalContainer: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 6,
      boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.18)',
      padding: 8,
      width: 414,
      maxHeight: 400,
      padding: 10,
    },
    title: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryText: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    icon: {
      height: 16,
      width: 16,
    },
    star: icons.BlueStarIcon,
    collapse: icons.DownArrowIcon,
    informationText: {
      ...fonts.BODY1,
      color: colors.ACCENT1_HIGH,
    },
    linkText: {
      ...fonts.CAPTION_SMALL,
      color: colors.SECONDARY_UPPER_MEDIUM,
    },
    actionText: {
      ...fonts.ACTION,
      color: colors.NEUTRAL_MEDIUM,
    },
    date: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    cardContainer: {
      cursor: 'pointer',
      padding: 12,
      borderRadius: 6,
      backgroundColor: colors.SURFACE1,
      overflow: 'hidden',
    },
    selected: {
      borderWidth: 1,
      borderRadius: 6,
      borderColor: colors.BRAND_UPPER_MEDIUM,
    },
  };
};
