import React, {useState} from 'react';
import moment from 'moment';

import {Text, Col, Row} from '@unthinkable/react-core-components';
import {Rating} from '@unthinkable/react-star-rating';
import {useStyles, useTheme} from '@unthinkable/react-theme';

import {useInvoke} from '../../../controllers/useInvoke';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {FeatureReviewStyle} from '../cellrenders/theme';
import {Labels} from '../constants/Label';

const GiveRating = ({updateReview, rating}) => {
  const {GiveRating, emptyRatingColor} = useStyles(FeatureReviewStyle);
  const {containerStyle, textStyle} = GiveRating;

  return (
    <Col style={containerStyle}>
      <Rating
        starSize={24}
        value={rating}
        onChange={updateReview}
        emptyColor={emptyRatingColor}
      />
      <Text style={textStyle}>Rate this new feature</Text>
    </Col>
  );
};

const DisplayRating = ({updateReview, rating, reviewDate}) => {
  const {icons} = useTheme();
  const {DisplayRating, emptyRatingColor} = useStyles(FeatureReviewStyle);
  const {
    containerStyle,
    containerRowStyle,
    ratingRowContainerStyle,
    ratingTextStyle,
    bottomRowTextStyle,
  } = DisplayRating;

  const {ThreeDotVerticalWhite} = icons;

  return (
    <Col style={containerStyle}>
      <Row style={containerRowStyle}>
        <Row gap={8} style={ratingRowContainerStyle}>
          <Text style={ratingTextStyle}>{rating}</Text>
          <Rating
            starSize={24}
            value={rating}
            onChange={updateReview}
            emptyColor={emptyRatingColor}
          />
        </Row>
        <MoreActions
          icon={ThreeDotVerticalWhite}
          actions={[
            {
              title: 'Remove Rating',
              onPress: () => {
                updateReview(null);
              },
            },
          ]}
        />
      </Row>
      <Row style={containerRowStyle}>
        <Text style={bottomRowTextStyle}>Feature Performance Rating</Text>
        <Text style={bottomRowTextStyle}>
          {moment(reviewDate).format('DD MMM YY')}
        </Text>
      </Row>
    </Col>
  );
};

export const FeatureReview = props => {
  const {
    route: {params},
  } = props;
  const {FeatureDetail, PerformanceRating} = useStyles(FeatureReviewStyle);

  const {row} = params;

  const [rating, setRating] = useState(row?.performance_review);
  const [reviewDate, setReviewDate] = useState(row?.review_date);

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'Feature',
  });

  const updateReview = value => {
    let date = new Date();
    setRating(value);
    setReviewDate(date.toISOString());
    invoke({
      uri: '/projectfeatures/' + row?._id,
      props: {
        performance_review: value,
      },
    });
  };

  return (
    <Col>
      {/* FEATURE DETAILS */}
      <Col gap={10} style={FeatureDetail.containerStyle}>
        <Col gap={10} style={FeatureDetail.headerContainerStyle}>
          <Col>
            <Text style={FeatureDetail.headerTextStyle}>{row?.feature}</Text>
            <Text style={FeatureDetail.codeTextStyle}>#{row?.code}</Text>
          </Col>
          <Row gap={8} style={FeatureDetail.releaseRowStyle}>
            <Text style={FeatureDetail.releaseTextStyle}>Released on:</Text>
            <Text style={FeatureDetail.releaseDateTextStyle}>
              {moment(row?.releasedOn).format('DD MMM YY')}
            </Text>
          </Row>
        </Col>
        <Col>
          <Text
            style={
              FeatureDetail.featureHeaderTextStyle
            }>{`${Labels.Feature}`}</Text>
          <Text style={FeatureDetail.featureTextStyle}>{row.desc || '--'}</Text>
        </Col>
      </Col>

      {/* PERFORMANCE RATING */}
      <Col gap={16} style={PerformanceRating.containerStyle}>
        <Text style={PerformanceRating.headingTextStyle}>
          Feature Performance Rating
        </Text>
        {rating ? (
          <DisplayRating
            updateReview={updateReview}
            rating={rating}
            reviewDate={reviewDate}
          />
        ) : (
          <GiveRating updateReview={updateReview} rating={rating} />
        )}
      </Col>
    </Col>
  );
};
