import React from 'react';
import {
  Row,
  Text,
  TouchableOpacity,
  View,
  Image,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';

export const DesignUploadCellRender = props => {
  let {row, value, onUpload, onView, uploading = true, textColor, icon} = props;
  let {
    addPlus,
    iconStyle,
    iconContainerStyle,
    rowStyle,
    designIcon,
    designIconStyle,
    textStyle,
  } = useComponentTheme('DesignCellRenderStyles');

  if (textColor) {
    textStyle = {
      ...textStyle,
      color: textColor,
    };
  }
  if (typeof value === 'function') {
    value = value(props);
  }

  if (!uploading && !value) {
    return null;
  }

  return (
    <View style={rowStyle}>
      {!value ? (
        <TouchableOpacity
          onPress={() => {
            onUpload({row});
          }}
          style={iconContainerStyle}>
          <Image source={addPlus} style={iconStyle} />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          onPress={() => {
            onView({row});
          }}>
          <Row gap={6} style={{alignItems: 'center'}}>
            <Image source={icon || designIcon} style={designIconStyle} />
            <Text style={textStyle}>{value}</Text>
          </Row>
        </TouchableOpacity>
      )}
    </View>
  );
};

export const FeatureDesignScreensRender = ({value}) => {
  let {featureDesignIcon, textStyle, featureDesignIconStyle} =
    useComponentTheme('DesignCellRenderStyles');
  if (!value) {
    return null;
  }
  return (
    <Row style={{alignItems: 'center'}} gap={4}>
      <Image style={featureDesignIconStyle} source={featureDesignIcon} />
      <Text style={textStyle}>{value}</Text>
    </Row>
  );
};
