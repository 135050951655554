import React from 'react';

import { Row } from '@unthinkable/react-core-components';
import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import { AddButton } from '../../../components/button/AddButton';
import {
    GroupFilter,
    PeriodRangeFilter
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { OutreachCampaignTable } from './OutreachCampaignTable';

export const OutreachInmailCampaignTabs = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
      target_audience: params?.target_audience || {},
    },
  });
  const {filter, params: filterParams} = filterValues || {};
  const {period} = filterParams;
  const tabs = {
    inexperiment: {
      label: 'In Experiment',
      view: (
        <OutreachCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{
            status: 'In Experiment',
          }}
        />
      ),
    },
    confirmed: {
      label: 'Confirmed',
      view: (
        <OutreachCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{
            status: 'Confirmed',
          }}
        />
      ),
    },

    retired: {
      label: 'Retired',
      view: (
        <OutreachCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{status: 'Retired'}}
        />
      ),
    },
    failed: {
      label: 'Failed',
      view: (
        <OutreachCampaignTable
          filterParams={filterParams}
          filter={filter}
          searchValue={searchValue}
          tabFilter={{status: 'Failed'}}
        />
      ),
    },
  };
  const actions = [
    // <SearchFilter
    //   value={searchValue}
    //   onChangeFilter={onChangeFilter}
    //   placeholder="Search"
    // />,
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        {
          type: 'autoComplete',
          label: 'Owner',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'assigned_to',
        },
      ]}
    />,
    <Row
      gap={8}
      style={{alignItems: 'center', justifyContent: 'space-between'}}>
      <AddButton
        title="Campaign"
        view="add-outreachcampaign"
        params={props?.route?.params}
      />
    </Row>,
  ];
  return <TabView {...props} tabs={tabs} actions={actions} />;
};
