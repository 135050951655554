import {Text, View} from '@unthinkable/react-core-components';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {TableHeader} from '../../../components/table/Headers';
import {BorderedGroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {useOrderActions} from '../actions/OrderActions';
import {AmountRender} from './components/AmountRender';

export const renderLocale = currency => {
  const currencyToLocaleMap = {
    USD: 'en-US',
    EUR: 'de-DE',
    JPY: 'ja-JP',
    GBP: 'en-GB',
    AUD: 'en-AU',
    CAD: 'en-CA',
    CHF: 'de-CH',
    CNY: 'zh-CN',
    SEK: 'sv-SE',
    NZD: 'en-NZ',
    AED: 'ar-AE',
  };
  return currencyToLocaleMap[currency];
};

const renderOrderStatus = ({row = {}, styles = {}, field}) => {
  let value = row[field];
  let updateColor = {color: '#34C759'};
  let {rowText = {}} = styles;
  if (value == 'Allocation due') {
    updateColor['color'] = '#007AFF';
  }
  return (
    <View
      style={{
        backgroundColor: '#F0F7FF',
        padding: 4,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...rowText, ...updateColor}}>{value || '-'}</Text>
    </View>
  );
};

const RenderOrder = ({row}) => {
  return (
    <PrimaryColumnCard
      primaryTitle={row?.order_number}
      items={[
        {
          value: row?.customer_id?.name,
        },
      ]}
    />
  );
};

const ACCOUNT_OWNER = {
  type: 'colorTag',
  field: 'account_owner_id.name',
  colorField: 'account_owner_id.color',
  header: 'Account Owner',
};

const DATE = {
  type: 'date',
  field: 'order_date',
  header: 'Date',
  width: 180,
  align: 'center',
  formatOptions: {
    format: 'DD MMM ,YYYY',
  },
};
const Customer = {
  header: 'Customer',
  minWidth: 200,
  render: ({row, styles}) => {
    return <Tag value={row?.customer_id?.name} color={randomColor()} />;
  },
};
const AccountManager = {
  type: 'userAvatar',
  field: 'account_owner_id',
  header: 'AM',
  width: 60,
  align: 'center',
};

const CSM = {
  header: 'CSM',
  type: 'userAvatar',
  width: 60,
  field: 'delivery_owner_id',
};

const ORDER_NUMBER = {
  field: 'order_number',
  header: 'Number',
};

const CUSTOMER = {
  type: 'colorTag',
  field: 'customer_id.name',
  colorField: 'customer_id.color',
  header: 'Customer',
  minWidth: 200,
};

const AMOUNT = {
  header: 'Value (₹)',
  type: 'currency',
  aggregate: true,
  align: 'right',
  field: 'base_amount',
  width: 150,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.amount || 0}
        base_amount={row?.base_amount || 0}
        currency={row.currency_id?.currency}
      />
    );
  },
};

const fixedOrderAmount = ({fromInternal = false}) => {
  return {
    header: 'Total Value (₹)',
    type: 'currency',
    aggregate: true,
    align: 'right',
    field: 'base_amount',
    width: 150,
    render: ({row, styles}) => {
      return (
        <AmountRender
          row={row}
          styles={styles}
          amount={row?.amount || 0}
          base_amount={row?.base_amount || 0}
          currency={row.currency_id?.currency}
        />
      );
    },
    visible: () => !fromInternal,
  };
};

const BASEAMOUNT = {
  type: 'currency',
  field: 'base_amount',
  header: 'Base Amount',
  aggregate: true,
  width: 180,
};

const LAST_DELIVERY_DATE = {
  type: 'date',
  field: 'order_due_date',
  header: 'Last Delivery Date',
};

const ORDER = {
  header: 'Order',
  width: 160,
  render: ({row, styles: {rowText = {}}}) => {
    return (
      <View style={{flexDirection: 'column'}}>
        <Tag value={row?.order_number} color={randomColor()} />
        <Text style={{...rowText, fontSize: 12, color: '#737373'}}>
          {moment(row.order_date).format('DD MMM YY')}-
          {moment(row.order_end_date).format('DD MMM YY')}
        </Text>
      </View>
    );
  },
};

const ORDER_EXISTS_TYPE = {
  field: 'order_exists_type',
  header: 'Type',
};

const showProject = ({assignTab = false}) => {
  return {
    header: 'Project',
    minWidth: 165,
    render: ({row, styles}) => {
      const ProjectName = row?.project
        ?.map(element => {
          return element?.project;
        })
        .join(', ');
      if (!ProjectName) return null;
      return <Tag value={ProjectName} color={randomColor()} />;
    },
    visible: () => assignTab,
  };
};

const ORDER_STATUS = {
  header: 'Status',
  width: 100,
  align: 'center',
  render: ({row, styles}) => {
    return (
      <View>
        <Text
          style={{
            ...styles.rowText,
            color: row.order_status === 'Closed' ? '#FF3B30' : '#7DCD28',
          }}>
          {row.order_status}
        </Text>
        <Text style={{...styles.rowText, color: '#A3A3A3'}}>
          {row.close_date && moment(row.close_date).format('DD MMM YY')}
        </Text>
      </View>
    );
  },
};

const DUE_ALLOCATIONS = ({navigation}) => ({
  render: CountCellRender,
  count_field: 'due_allocation_count',
  field: 'due_allocation_count',
  header: 'Requests',
  styleForCountField: true,
  align: 'right',
  onPress: ({row}) => {
    navigation.navigate('order-resource-request-table', {
      data: {order: row._id},
    });
  },
  aggregate: true,
});
const createMilestone = ({navigation, params}) => ({
  title: 'Create Milestone',
  onPress: props => {
    const {row} = props;
    navigation.navigate('edit-milestone', {
      row: {
        ...row,
        order: {
          _id: row._id,
        },
      },
    });
  },
});

const createAdminMilestone = ({navigation, params}) => ({
  title: 'Create Milestone',
  onPress: props => {
    const {row} = props;
    navigation.navigate('create-admin-milestone', {
      row: {
        ...row,
        order: {
          _id: row._id,
        },
        extraParameter: {
          project_plan_status: 'Approved',
        },
      },
    });
  },
});

const updateProjectMappedForFixed = ({navigation, assignTab}) => ({
  title: 'Update Project Mapped',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-project-mapped', {
      row: {
        ...row,
        customer: row.customer_id,
        projectTab: 'Billed',
        orderDefaultValue: {
          order: {
            _id: row?._id,
            order_number: row?.order_number,
          },
          start_date: row?.order_date,
          end_date: row?.order_end_date,
          hours: row?.est_order_hrs,
        },
      },
      fromOrder: true,
      forProjectUpdate: true,
    });
  },
  visible: () => assignTab,
});

const createAdminMilestoneForInternal = ({navigation, params}) => ({
  title: 'Create Milestone',
  onPress: props => {
    const {row} = props;
    navigation.navigate('create-admin-milestone', {
      row: {
        ...row,
        order: {
          _id: row._id,
        },
      },
    });
  },
  visible: ({row}) => {
    return row?.is_internal;
  },
});

const navigateToMilestone = ({navigation, params}) => ({
  title: 'Milestones ',
  onPress: props => {
    const {row} = props;
    navigation.navigate('show-milestones', {
      row,
    });
  },
});

const navigateToEmployeeRequest = ({navigation, params}) => ({
  title: 'Resource Request',
  onPress: props => {
    const {row} = props;
    navigation.navigate('fixed-employee-request-tab', {
      row,
    });
  },
});

const navigateToAssignedResources = ({navigation, params}) => ({
  title: 'Resources',
  onPress: props => {
    const {row} = props;
    navigation.navigate('fixed-order-assigned-resources', {
      row,
    });
  },
});

const assignedProject = ({navigation, params}) => ({
  title: 'Assign Project',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-project', {
      row,
    });
  },
});

const project = ({navigation, tmOrder = false, assignTab = false}) => {
  return {
    header: 'Project',
    align: 'right',
    render: CountCellRender,
    count_field: 'project_count',
    onPlusPress: ({row}) => {
      navigation.navigate(tmOrder ? `tm-order-map` : `fixed-order-map`, {
        row: {
          ...row,
          customer: row.customer_id,
          projectTab: 'Billed',
          orderDefaultValue: {
            order: {
              _id: row?._id,
              order_number: row?.order_number,
            },
            start_date: row?.order_date,
            end_date: row?.order_end_date,
            hours: row?.est_order_hrs,
          },
        },
        fromOrder: true,
      });
    },
    visible: () => !assignTab,
  };
};

const TOTALMANMONTH = {
  header: 'Total Pot.',
  align: 'center',
  aggregate: true,
  field: 'man_month',
  width: 140,
  render: ({row, styles}) => {
    return (
      <Text
        style={{
          ...styles.rowText,
        }}>
        {row?.man_month && `${(row?.man_month).toFixed(2)} MM`}
      </Text>
    );
  },
};

const ORDERCREATEDON = {
  header: 'Created On',
  field: 'createdAt',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

// const REMAININGHOURS =   {
//   header: 'Rem. Pot.',
//   field: 'remainingHours',
//   aggregate: true,
//   align: 'center',
//   width: 100,
//   render: ({row, styles}) => {
//     return (
//       <Text
//         style={{
//           ...styles.rowText,
//         }}>
//         {row?.remainingHours?.toFixed(0)} h
//       </Text>
//     );
//   },
// },

const ORDERENGG = {
  header: 'Order Engg.',
  field: 'engg_count',
  aggregate: true,
  type: 'number',
  width: 100,
};

export const TMOrderTable = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
    assignTab = false,
    showViewAssignedEnggineer = false,
  } = props;
  params = {...params, ...filterParams};
  const {orderActivityLogs} = useOrderActions({navigation});
  const {post, download, user = {}} = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {...filter, order_status: {$in: ['Active']}},
    searchFields: ['order_number'],
    // api: '/product/activeCustomer',
  };
  const closedOrder = useInvoke({
    method: 'put',
    eventSourceId: 't&m',
  });
  const markOldOrder = useInvoke({
    method: 'put',
    eventSourceId: 'performance-report',
    throwError: true,
  });
  const generateOrderAllocations = useInvoke({
    method: 'post',
    eventSourceId: 'performance-report',
    throwError: true,
  });
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/orderDataDownload',
                    },
                    column: {
                      'Order date': 'order_date',
                      'Order Number': 'order_number',
                      Customer: 'customer_id.name',
                      Amount: 'amount',
                      'Base amount': 'base_amount',
                      Relation: 'account_owner_id.name',
                      Product: 'product.name',
                      Organization: 'organization.name',
                      'Billing type': 'billing_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId={['t&m', 'performance-report']}
      api={'/t&m/order'}
      params={params}
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      onRowPress={({row}) => {
        navigation.navigate('detail-tm-order', {row});
      }}
      addOnFilter={{
        ...filter,
        order_status: {$in: ['Active']},
      }}
      columns={[
        ORDERCREATEDON,
        CUSTOMER,
        AccountManager,
        ORDER,
        AMOUNT,
        {
          header: 'Potential(MM)',
          align: 'right',
          width: 120,
          field: 'man_month',
          aggregate: true,
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {row?.man_month && `${(row?.man_month).toFixed(2)} MM`}
              </Text>
            );
          },
        },
        ORDERENGG,
        project({navigation, tmOrder: true}),
      ]}
      moreActions={[
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-tm-order', {row});
          },
        },
        {
          title: 'View Assigned Engineers',
          onPress: ({row}) => {
            navigation.navigate('project-resource-working-data-for-orders', {
              row,
            });
          },
        },
        {
          title: 'Engagement ',
          onPress: ({row}) => {
            navigation.navigate('close-partial-engagement', {
              row,
            });
          },
        },
        {
          title: 'Extend order ',
          onPress: ({row}) => {
            navigation.navigate('order-extend-form', {
              row,
            });
          },
        },
        {
          title: 'Close order',
          onPress: ({row}) => {
            navigation.navigate('order-mark-closed', {
              row: {
                ...row,
                fromOrder: true,
              },
            });
          },
        },
        {
          title: 'Mark Old Order',
          confirm: {
            title: 'Mark Old Order',
            message: 'Are you sure you want to mark old this order?',
            confirmText: 'Save',
          },
          onPress: ({row}) => {
            markOldOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                is_old_order: true,
              },
            });
          },
          visible: () => !assignTab,
        },
        {
          title: 'Manual Closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to closed this order?',
            confirmText: 'Close',
          },
          onPress: ({row}) => {
            closedOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                order_status: 'Closed',
              },
            });
          },
          visible: () =>
            user?.email === 'sushil.nagvan@daffodilsw.com' ||
            user?.email === 'nitu.choubey@unthinkable.co',
        },
        {
          title: 'Skill Requirements',
          onPress: ({row}) => {
            navigation.navigate('order-skill-requirement', {row});
          },
        },
        {
          title: 'Copy Order',
          onPress: ({row}) => {
            navigation.navigate('copy-tm-order', {row});
          },
        },
        {
          title: 'Generate Allocations',
          confirm: {
            title: 'Generate Allocations',
            message:
              'Are you sure you want to generate allocations for this order?',
            confirmText: 'Save',
          },
          onPress: ({row}) => {
            generateOrderAllocations({
              uri: '/generateorderallocations',
              props: {
                ...row,
              },
            });
          },
          visible: ({row}) => row?.oldOrderId,
        },
      ]}
    />
  );
};

export const TMOrderTableAm = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
    showViewAssignedEnggineer = false,
  } = props;
  params = {...params, ...filterParams};
  const {orderActivityLogs} = useOrderActions({navigation});
  const {
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();
  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {
      ...filter,
      order_status: {$in: ['Active']},
      order_exists_type: {$in: ['New', 'Extension', 'Support']},
      $or: [
        {account_owner_id: employeeId},
        {account_owner_id: {$in: employeeTeam}},
      ],
    },
    searchFields: ['order_number'],
    // api: '/product/activeCustomer',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/orderDataDownload',
                    },
                    column: {
                      'Order date': 'order_date',
                      'Order Number': 'order_number',
                      Customer: 'customer_id.name',
                      Amount: 'amount',
                      'Base amount': 'base_amount',
                      Relation: 'account_owner_id.name',
                      Product: 'product.name',
                      Organization: 'organization.name',
                      'Billing type': 'billing_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId="t&m"
      api={'/t&m/order'}
      params={params}
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      onRowPress={({row}) => {
        navigation.navigate('detail-tm-order', {row});
      }}
      addOnFilter={{
        ...filter,
        order_status: {$in: ['Active']},
        order_exists_type: {$in: ['New', 'Extension', 'Support']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
      }}
      columns={[
        ORDER,
        Customer,
        AccountManager,
        CSM,
        AMOUNT,
        TOTALMANMONTH,
        {
          header: 'Engineer',
          width: 100,
          aggregate: true,
          field: 'engg_count',
          type: 'number',
        },
        project({navigation, tmOrder: true}),
      ]}
      moreActions={[
        // {
        //   title: 'Edit',
        //   onPress: ({row}) => {
        //     navigation.navigate('edit-tm-order', {row});
        //   },
        // },
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
        {
          title: 'View Assigned Engineers',
          onPress: ({row}) => {
            navigation.navigate('project-resource-working-data-for-orders', {
              row,
            });
          },
          // visible: () => showViewAssignedEnggineer,
        },
        // {
        //   title: 'Engagement ',
        //   onPress: ({row}) => {
        //     navigation.navigate('close-partial-engagement', {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Extend order ',
        //   onPress: ({row}) => {
        //     navigation.navigate('order-extend-form', {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Close order',
        //   onPress: ({row}) => {
        //     navigation.navigate('order-mark-closed', {
        //       row: {
        //         ...row,
        //         fromOrder: true,
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const TMOrderTablePM = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
  } = props;
  params = {...params, ...filterParams};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      eventSourceId={['t&m', 'performance-report']}
      api={'/t&m/order'}
      params={params}
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      onRowPress={({row}) => {
        navigation.navigate('detail-tm-order', {row});
      }}
      addOnFilter={{
        ...filter,
        order_status: {$in: ['Active']},
        order_exists_type: {$in: ['New', 'Extension', 'Support']},
      }}
      columns={[ORDER, Customer, project({navigation, tmOrder: true})]}
    />
  );
};

export const TMOrderCloseTable = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
    showViewAssignedEnggineer = false,
  } = props || {};
  const {orderActivityLogs} = useOrderActions({navigation});
  const activeOrder = useInvoke({
    method: 'put',
    eventSourceId: 'orderTable',
  });
  return (
    <BorderedGroupTable
      api={`/order/closed`}
      addOnFilter={{
        ...filterValues.filter,
        order_type: 'r',
        order_status: {$in: ['Cancled', 'Closed']},
        order_exists_type: {$in: ['New', 'Extension']},
      }}
      params={{...params, ...filterValues.params}}
      eventSourceId="orderTable"
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.from,
                  to: row?.to,
                  filterOf: filterValues?.params?.period?.filterOf
                    ? 'Month'
                    : filterValues?.params?.period?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.closedCount} closed
              </Text>
              <Text
                style={{
                  ...styles,
                }}></Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.canceledCount} Cancelled
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      // columns={[
      //   ORDER,
      //   Customer,
      //   AccountManager,
      //   CSM,
      //   {
      //     header: 'Engineer',
      //     width: 80,
      //     type: 'number',
      //     aggregate: true,
      //     field: 'engg_count',
      //   },
      //   AMOUNT,
      //   {
      //     header: 'Project',
      //     align: 'right',
      //     render: CountCellRender,
      //     width: 80,
      //     count_field: 'project_count',
      //   },
      //   // {
      //   //   header: 'Delivered Value',
      //   //   width: 150,
      //   // },
      //   ORDER_STATUS,
      // ]}
      columns={[
        ORDERCREATEDON,
        CUSTOMER,
        AccountManager,
        ORDER,
        AMOUNT,
        ORDERENGG,
        {
          header: 'Project',
          align: 'right',
          render: CountCellRender,
          width: 80,
          count_field: 'project_count',
        },
      ]}
      moreActions={() => [
        {
          title: 'View Assigned Engineers',
          onPress: ({row}) => {
            navigation.navigate('project-resource-working-data-for-orders', {
              row,
            });
          },
          visible: () => showViewAssignedEnggineer,
        },
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to active this order?',
            confirmText: 'OK',
          },
          onPress: ({row}) => {
            activeOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                order_status: 'Active',
              },
            });
          },
        },
      ]}
    />
  );
};

export const TMOrderCloseTableAm = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
  } = props || {};
  const {orderActivityLogs} = useOrderActions({navigation});
  const {
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();
  return (
    <BorderedGroupTable
      api={`/order/closed`}
      variant={'bordered'}
      addOnFilter={{
        ...filterValues.filter,
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        order_type: 'r',
        order_status: {$in: ['Cancled', 'Closed']},
        order_exists_type: {$in: ['New', 'Extension']},
      }}
      params={{...params, ...filterValues.params}}
      eventSourceId="orderTable"
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.from,
                  to: row?.to,
                  filterOf: filterValues?.params?.period?.filterOf
                    ? 'Month'
                    : filterValues?.params?.period?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.closedCount} closed
              </Text>
              <Text
                style={{
                  ...styles,
                }}></Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.canceledCount} Cancelled
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        ORDER,
        Customer,
        AccountManager,
        CSM,
        {
          header: 'Engineer',
          width: 80,
          aggregate: true,
          type: 'number',
          field: 'engg_count',
        },
        AMOUNT,
        // {
        //   header: 'Remaining Value',
        //   width: 150,
        // },
        ORDER_STATUS,
      ]}
      moreActions={() => [
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
      ]}
    />
  );
};

export const FixedOrderTable = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
    addOnFilter,
    assignTab = false,
    fromInternal = false,
    showViewAssignedEnggineer = false,
  } = props;
  params = {...params, ...filterParams};
  const {orderActivityLogs} = useOrderActions({navigation});
  const closedOrder = useInvoke({
    method: 'put',
    eventSourceId: 'fixed',
  });
  const markOldOrder = useInvoke({
    method: 'put',
    eventSourceId: 'fixed',
    throwError: true,
  });
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter: {
      ...filter,
      order_status: {$in: ['Active', null]},
      ...addOnFilter,
    },
    searchFields: ['order_number'],
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/orderDataDownload',
                    },
                    column: {
                      'Order date': 'order_date',
                      'Order Number': 'order_number',
                      Customer: 'customer_id.name',
                      Amount: 'amount',
                      'Base amount': 'base_amount',
                      Relation: 'account_owner_id.name',
                      Product: 'product.name',
                      Organization: 'organization.name',
                      'Billing type': 'billing_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={'/fixed/order'}
      params={params}
      addOnFilter={{
        ...filter,
        order_status: {$in: ['Active', null]},
        ...addOnFilter,
      }}
      eventSourceId={['fixed', 'performance-report']}
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      onRowPress={({row}) => {
        navigation.navigate('detail-fixed-order', {row});
      }}
      // columns={[
      //   ORDER,
      //   Customer,
      //   AccountManager,
      //   CSM,
      //   fixedOrderAmount({fromInternal}),
      //   {
      //     header: 'Total Pot.',
      //     field: 'est_order_hrs',
      //     align: 'center',
      //     aggregate: true,
      //     render: ({row, styles}) => {
      //       return (
      //         <Text
      //           style={{
      //             ...styles.rowText,
      //           }}>
      //           {row?.totalHours} MM
      //         </Text>
      //       );
      //     },
      //   },
      //   project({navigation, tmOrder: false, assignTab}),
      //   showProject({assignTab}),
      // ]}
      columns={[
        ORDERCREATEDON,
        Customer,
        AccountManager,
        ORDER,
        fixedOrderAmount({fromInternal}),
        {
          header: 'Potential(MM)',
          field: 'est_order_hrs',
          align: 'right',
          aggregate: true,
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {row?.totalHours} MM
              </Text>
            );
          },
        },
        project({navigation, tmOrder: false, assignTab}),
        showProject({assignTab}),
      ]}
      moreActions={() => [
        createAdminMilestone({navigation, params}),
        // createMilestone({navigation, params}),
        navigateToMilestone({navigation, params}),
        // navigateToEmployeeRequest({navigation, params}),
        navigateToAssignedResources({navigation, params}),
        updateProjectMappedForFixed({navigation, assignTab}),
        // assignedProject({navigation, params}),
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-fixed-order', {row});
          },
        },
        {
          title: 'View Assigned Engineers',
          onPress: ({row}) => {
            navigation.navigate('project-resource-working-data-for-orders', {
              row,
            });
          },
          // visible: () => showViewAssignedEnggineer,
        },
        {
          title: 'Add Target Order',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`add-my-order-target`, {
              filter: {
                customer: row.customer_id,
                order: {_id: row?._id},
                project_resource_working_data: row?.deliveries.map(
                  item => item._id,
                ),
              },
            });
          },
        },
        {
          title: 'Update Target Order',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`order-target-list`, {
              filter: {
                order: row?._id,
                from_date: {$gte: params?.period.from},
                to_date: {$lte: params?.period.to},
              },
            });
          },
        },
        {
          title: 'Mark Closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to closed this order?',
            confirmText: 'Close',
          },
          onPress: ({row}) => {
            closedOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                order_status: 'Closed',
              },
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
        {
          title: 'Mark Old Order',
          confirm: {
            title: 'Mark Old Order',
            message: 'Are you sure you want to mark old this order?',
            confirmText: 'Save',
          },
          onPress: ({row}) => {
            markOldOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                is_old_order: true,
              },
            });
          },
          visible: () => !assignTab,
        },
      ]}
    />
  );
};

export const FixedOrderTableAm = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
    addOnFilter,
    assignTab = false,
    fromInternal = false,
    showViewAssignedEnggineer = false,
  } = props;
  params = {...params, ...filterParams};
  const {orderActivityLogs} = useOrderActions({navigation});
  const closedOrder = useInvoke({
    method: 'put',
    eventSourceId: 'fixed',
  });
  const {
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
    download,
  } = useAppStateContext();
  const fetchProps = {
    params,
    search: searchValue,
    addOnFilter: {
      ...filter,
      order_status: {$in: ['Active']},
      $or: [
        {account_owner_id: employeeId},
        {account_owner_id: {$in: employeeTeam}},
      ],
      ...addOnFilter,
    },
    searchFields: ['order_number'],
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/orderDataDownload',
                    },
                    column: {
                      'Order date': 'order_date',
                      'Order Number': 'order_number',
                      Customer: 'customer_id.name',
                      Amount: 'amount',
                      'Base amount': 'base_amount',
                      Relation: 'account_owner_id.name',
                      Product: 'product.name',
                      Organization: 'organization.name',
                      'Billing type': 'billing_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={'/fixed/order'}
      params={params}
      addOnFilter={{
        ...filter,
        order_status: {$in: ['Active']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        ...addOnFilter,
      }}
      eventSourceId="fixed"
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      onRowPress={({row}) => {
        navigation.navigate('detail-fixed-order', {row});
      }}
      columns={[
        ORDER,
        Customer,
        AccountManager,
        CSM,
        fixedOrderAmount({fromInternal}),
        {
          header: 'Total Potential',
          field: 'est_order_hrs',
          align: 'center',
          width: 150,
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {row.est_order_hrs} h
              </Text>
            );
          },
        },
        project({navigation, tmOrder: false, assignTab}),
        showProject({assignTab}),
        // {
        //   header: 'Remaining Potential',
        //   field: 'remainingHours',
        //   align: 'center',
        //   width: 150,
        //   render: ({row, styles}) => {
        //     return (
        //       <Text
        //         style={{
        //           ...styles.rowText,
        //         }}>
        //         {row.remainingHours} h
        //       </Text>
        //     );
        //   },
        // },
      ]}
      moreActions={() => [
        createAdminMilestone({navigation, params}),
        navigateToMilestone({navigation, params}),
        // navigateToEmployeeRequest({navigation, params}),
        navigateToAssignedResources({navigation, params}),
        // assignedProject({navigation, params}),
        // {
        //   title: 'Edit',
        //   onPress: ({row}) => {
        //     navigation.navigate('edit-fixed-order', {row});
        //   },
        // },
        {
          title: 'View Assigned Engineers',
          onPress: ({row}) => {
            navigation.navigate('project-resource-working-data-for-orders', {
              row,
            });
          },
          // visible: () => showViewAssignedEnggineer,
        },
        {
          title: 'Add Target Order',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`add-my-order-target`, {
              filter: {
                customer: row.customer_id,
                order: {_id: row?._id},
                project_resource_working_data: row?.deliveries.map(
                  item => item._id,
                ),
              },
            });
          },
        },
        {
          title: 'Update Target Order',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`order-target-list`, {
              filter: {
                order: row?._id,
                from_date: {$gte: params?.period.from},
                to_date: {$lte: params?.period.to},
              },
            });
          },
        },
        {
          title: 'Mark Closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to closed this order?',
            confirmText: 'Close',
          },
          onPress: ({row}) => {
            closedOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                order_status: 'Closed',
              },
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
      ]}
    />
  );
};

export const FixedOrderTablePM = props => {
  let {
    route: {params},
    filter,
    filterParams,
    navigation,
    searchValue,
    addOnFilter,
    assignTab = false,
  } = props;
  params = {...params, ...filterParams};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api={'/fixed/order'}
      params={params}
      addOnFilter={{
        ...filter,
        order_status: {$in: ['Active']},
        ...addOnFilter,
      }}
      eventSourceId={['fixed', 'performance-report']}
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      onRowPress={({row}) => {
        navigation.navigate('detail-fixed-order', {row});
      }}
      columns={[
        ORDER,
        Customer,
        project({navigation, tmOrder: false, assignTab}),
        showProject({assignTab}),
      ]}
      moreActions={() => [
        createAdminMilestoneForInternal({navigation, params}),
      ]}
    />
  );
};

export const FixedOrderCloseTable = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
    addOnFilter,
    fromInternal = false,
    showViewAssignedEnggineer = false,
  } = props || {};
  const {orderActivityLogs} = useOrderActions({navigation});
  const activeOrder = useInvoke({
    method: 'put',
    eventSourceId: 'orderTable',
  });
  return (
    <BorderedGroupTable
      api={`/order/closed`}
      addOnFilter={{
        ...filterValues.filter,
        order_type: 'ot',
        order_status: {$in: ['Cancled', 'Closed']},
        ...addOnFilter,
      }}
      params={{...params, ...filterValues.params}}
      eventSourceId="orderTable"
      search={searchValue}
      searchFields="order_number"
      limit={50}
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.from,
                  to: row?.to,
                  filterOf: filterValues?.params?.period?.filterOf
                    ? 'Month'
                    : filterValues?.params?.period?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.closedCount} closed
              </Text>
              <Text
                style={{
                  ...styles,
                }}></Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.canceledCount} Cancelled
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      onRowPress={({row}) => {
        navigation.navigate('detail-fixed-order', {row});
      }}
      // columns={[
      //   ORDER,
      //   Customer,
      //   AccountManager,
      //   CSM,
      //   fixedOrderAmount({fromInternal}),
      //   showProject({assignTab: true}),
      //   {
      //     header: 'Total pot.',
      //     align: 'center',
      //     render: ({row, styles}) => {
      //       return (
      //         <Text
      //           style={{
      //             ...styles.rowText,
      //           }}>
      //           {row?.est_order_hrs || 0}
      //         </Text>
      //       );
      //     },
      //     width: 100,
      //   },
      //   ORDER_STATUS,
      // ]}
      columns={[
        ORDERCREATEDON,
        Customer,
        AccountManager,
        ORDER,
        fixedOrderAmount({fromInternal}),
        {
          header: 'Potential(MM)',
          field: 'est_order_hrs',
          align: 'right',
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {(row?.est_order_hrs / 180).toFixed(2)} MM
              </Text>
            );
          },
        },
        showProject({assignTab: true}),
      ]}
      moreActions={() => [
        {
          title: 'View Assigned Engineers',
          onPress: ({row}) => {
            navigation.navigate('project-resource-working-data-for-orders', {
              row,
            });
          },
          // visible: () => showViewAssignedEnggineer,
        },
        {
          title: 'Activity Logs',
          onPress: orderActivityLogs,
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to active this order?',
            confirmText: 'OK',
          },
          onPress: ({row}) => {
            activeOrder({
              uri: '/orders/' + row?._id,
              props: {
                ...row,
                order_status: 'Active',
              },
            });
          },
        },
      ]}
    />
  );
};

export const FixedOrderCloseTableAm = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    searchValue,
    addOnFilter,
  } = props || {};
  const {
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();

  return (
    <BorderedGroupTable
      api={`/order/closed`}
      variant={'bordered'}
      addOnFilter={{
        ...filterValues.filter,
        order_type: 'ot',
        order_status: {$in: ['Cancled', 'Closed']},
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        ...addOnFilter,
      }}
      params={{...params, ...filterValues.params}}
      eventSourceId="orderTable"
      search={searchValue}
      searchFields="order_number"
      limit={5000}
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.from,
                  to: row?.to,
                  filterOf: filterValues?.params?.period?.filterOf
                    ? 'Month'
                    : filterValues?.params?.period?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.closedCount} closed
              </Text>
              <Text
                style={{
                  ...styles,
                }}></Text>
              <Text
                style={{
                  ...styles,
                }}>
                {row.canceledCount} Cancelled
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      onRowPress={({row}) => {
        navigation.navigate('detail-fixed-order', {row});
      }}
      columns={[
        ORDER,
        Customer,
        AccountManager,
        CSM,
        AMOUNT,
        {
          header: 'Actual eff./Est. eff.',
          render: ({row, styles}) => {
            return (
              <Text
                style={{
                  ...styles.rowText,
                }}>
                {row?.totalEmployeeWorkload || 0}/{row?.est_order_hrs || 0}
              </Text>
            );
          },
          width: 160,
        },
        ORDER_STATUS,
      ]}
    />
  );
};

export const CloseOrderTable = props => {
  let {
    route: {params},
    filter,
    filterParams,
    searchValue,
  } = props;
  params = {...params, ...filterParams};
  return (
    <Table
      api={'/close/order'}
      params={params}
      addOnFilter={filter}
      search={searchValue}
      searchFields="order_number"
      columns={[
        ACCOUNT_OWNER,
        DATE,
        ORDER_NUMBER,
        CUSTOMER,
        AMOUNT,
        BASEAMOUNT,
        LAST_DELIVERY_DATE,
      ]}
    />
  );
};

const OrderTable = props => {
  const params = props.route.params;
  const {period} = params;
  return (
    <Table
      params={params}
      fields={{
        order_date: 1,
        order_number: 1,
        base_amount: 1,
        amount: 1,
        customer: {
          name: 1,
        },
        account_owner_id: {
          name: 1,
        },
      }}
      filter={
        props.route?.name == 'department-outputs-orders'
          ? {
              order_date: {
                $gte: period?.from,
                $lte: period?.to,
              },
              product: params?.product?._id,
            }
          : void 0
      }
      columns={[
        DATE,
        ORDER_NUMBER,
        AMOUNT,
        BASEAMOUNT,
        CUSTOMER,
        ACCOUNT_OWNER,
      ]}
    />
  );
};

export const OrderTabDepartmentOutputView = props => {
  const {route: {params} = {}} = props;
  const {period} = params;

  let tabs = {};
  tabs.all = {
    label: 'All',
    api: '/orders',
    addOnFilter: {
      order_date: {
        $gte: period?.from,
        $lte: period?.to,
      },
      product: params?.product?._id,
    },
    view: <OrderTable sort={{creation_date: -1}} {...props} />,
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrderTabView = props => {
  const {route: {params} = {}} = props;
  let tabs = {};
  tabs.all = {
    label: 'All',
    api: '/orders',
    view: <OrderTable sort={{creation_date: -1}} {...props} />,
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrderTableWithDueAllocations = props => {
  const {navigation, filterInfo, filterValues, searchValue, extraFilter} =
    props;
  return (
    <Table
      {...props}
      {...filterInfo}
      renderHeader={() => <TableHeader title="Order Requests" />}
      addOnFilter={{...filterValues.filter, ...extraFilter}}
      eventSourceId={['orderresourcerequests', 'employeeAssigned']}
      api="/getOrdersWithDueAllocations"
      fields={{
        order_date: 1,
        order_number: 1,
        customer_id: {
          name: 1,
        },
        requirement_detail: 1,
      }}
      columns={[
        {header: 'Order', render: RenderOrder},
        // ORDER_NUMBER,
        DATE,
        DUE_ALLOCATIONS({navigation}),
      ]}
      search={searchValue}
      searchFields={['order_number', 'customer_id.name']}
      limit={5000}
    />
  );
};

export const OrderTableWithDueAllocationsTab = props => {
  const {route: {params} = {}, employeeMenu = false} = props;
  const {department} = params;
  const filterInfo = useFilter({
    filter: {
      'project.department': department,
    },
  });
  const {searchValue, onChangeFilter, filterValues} = filterInfo;
  let tabs = {};
  if (employeeMenu) {
    tabs.fixed = {
      label: 'Internal Requests',
      api: '/getOrdersWithDueAllocations',
      view: (
        <OrderTableWithDueAllocations
          filterInfo={filterInfo}
          searchValue={searchValue}
          filterValues={filterValues}
          extraFilter={{order_type: 'ot', is_internal: true}}
        />
      ),
      addOnFilter: {
        ...filterValues.filter,
        order_type: 'ot',
        is_internal: true,
      },
    };
  } else {
    tabs.tandm = {
      label: 'T&M',
      api: '/getOrdersWithDueAllocations',
      view: (
        <OrderTableWithDueAllocations
          filterInfo={filterInfo}
          searchValue={searchValue}
          filterValues={filterValues}
          extraFilter={{order_type: 'r'}}
        />
      ),
      addOnFilter: {
        ...filterValues.filter,
        order_type: 'r',
      },
    };

    tabs.fixed = {
      label: 'Fixed',
      api: '/getOrdersWithDueAllocations',
      view: (
        <OrderTableWithDueAllocations
          filterInfo={filterInfo}
          searchValue={searchValue}
          filterValues={filterValues}
          extraFilter={{order_type: 'ot', is_internal: {$in: [null, false]}}}
        />
      ),
      addOnFilter: {
        ...filterValues.filter,
        order_type: 'ot',
        is_internal: {$in: [null, false]},
      },
    };
  }

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          {...filterInfo}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
          ]}
        />,
      ]}
    />
  );
};

export const OrderTableWithInternalDueAllocationsTab = props => {
  const {route: {params} = {}, employeeMenu = false} = props;
  const filterInfo = useFilter();
  const {searchValue, onChangeFilter, filterValues} = filterInfo;
  let tabs = {};
  tabs.fixed = {
    label: 'Internal Requests',
    api: '/getOrdersWithDueAllocations',
    view: (
      <OrderTableWithDueAllocations
        filterInfo={filterInfo}
        searchValue={searchValue}
        filterValues={filterValues}
        extraFilter={{order_type: 'ot', is_internal: true}}
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
      order_type: 'ot',
      is_internal: true,
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          {...filterInfo}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer_id',
            },
          ]}
        />,
      ]}
    />
  );
};
