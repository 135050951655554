import {useEffect, useState} from 'react';
import {View} from '@unthinkable/react-core-components';
import Editor from '@monaco-editor/react';
import {useInvoke} from '../../../controllers/useInvoke';
import {Button} from '../../../components/button/Button';
import {useFetchData} from '../../../controllers/useFetchData';
import {useColorMode} from '@unthinkable/react-theme';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import './codeEditor.css';
import {OutlineButton} from '../../../components/button/OutlineButton';

export const CodeEditor = props => {
  const {
    route: {params},
    navigation,
    onClose,
  } = props;
  const {folder_path_id, file_name, feature} = params;
  const {colorMode} = useColorMode();
  const [fileContent, setFileContent] = useState('');
  const [commitLoading, setLoading] = useState(false);
  const {data, loading} = useFetchData({
    api: `/fetchGitFile`,
    params: {
      folder_path_id: folder_path_id?._id || folder_path_id,
      file_name,
      feature_id: feature?._id,
    },
  });

  useEffect(() => {
    if (data) {
      setFileContent(data);
    }
  }, [data]);

  const invoke = useInvoke({setLoading});

  const commitFile = async () => {
    await invoke({
      uri: '/commitGitFile',
      props: {
        fileContent,
        folder_path_id: folder_path_id?._id,
        file_name,
        feature_id: feature?._id,
      },
    });
    onClose ? onClose() : navigation.goBack();
  };

  return (
    <View style={{flex: 1}}>
      {loading ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator />
        </View>
      ) : (
        <Editor
          loading={false}
          defaultLanguage="javascript"
          defaultValue={data || '// write your code here'}
          value={fileContent}
          onChange={value => {
            setFileContent(value);
          }}
          theme={colorMode === 'dark' ? 'vs-dark' : 'vs-light'}
          options={{
            minimap: {
              enabled: false,
            },
          }}
        />
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 12,
          paddingTop: 12,
          paddingRight: 12,
        }}>
        <OutlineButton
          text="Cancel"
          onPress={() => (onClose ? onClose() : navigation.goBack())}
        />
        <Button
          text="Commit"
          disabled={!fileContent || fileContent === data}
          loading={commitLoading}
          onPress={() => {
            commitFile();
          }}
        />
      </View>
    </View>
  );
};
