import React, {forwardRef, useCallback} from 'react';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';
import {ColorPickerModal} from './ColorPickerModal';
import {InputWrapper} from '@unthinkable/react-input';

const ColorInput = forwardRef((props, ref) => {
  const {
    value,
    modalPosition,
    placeholder = 'Select Color',
    containerStyle,
    colorViewStyle,
    labelStyle,
    textStyle,
    modalStyle,
    readOnly,
  } = props;
  const upperCaseValue = value?.toUpperCase();

  const renderModal = useCallback(({hide}) => {
    return <ColorPickerModal {...modalStyle} {...props} hide={hide} />;
  });

  let renderComponent = (
    <Row ref={ref} gap={8} style={containerStyle}>
      {value ? (
        <>
          <View style={{...colorViewStyle, backgroundColor: value}} />
          <Text style={textStyle}>{upperCaseValue}</Text>
        </>
      ) : (
        <Text style={labelStyle}>{placeholder}</Text>
      )}
    </Row>
  );
  return (
    <InputWrapper {...props}>
      <WithModal
        disabled={readOnly}
        renderModal={renderModal}
        position={modalPosition}>
        {renderComponent}
      </WithModal>
    </InputWrapper>
  );
});

export default ColorInput;
