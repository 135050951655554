import {useFilter} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useAppStateContext} from '../../../providers/AppState';
import {QuotationRequestTable} from './QuotationRequestTable';

export const QuotationRequestTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    requested: {
      label: 'Requested',
      view: (
        <QuotationRequestTable
          addOnFilter={{
            status: 'Requested',
            ...filterValues.filter,
            requested_by: employee._id,
          }}
          tab={'Requested'}
          searchValue={searchValue}
          {...props}
        />
      ),
      api: '/quotation/all',
      search: searchValue,
      searchFields: 'po_number',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Requested',
        ...filterValues.filter,
      },
      actions: [
        <AddButton title="Request Quotation" view={'add-quotation-request'} />,
      ],
    },
    approved: {
      label: 'Approved',
      view: (
        <QuotationRequestTable
          addOnFilter={{
            status: 'Approved',
            ...filterValues.filter,
            requested_by: employee._id,
          }}
          tab={'Approved'}
          searchValue={searchValue}
          {...props}
        />
      ),
      api: '/quotation/all',
      search: searchValue,
      searchFields: 'po_number',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Approved',
        ...filterValues.filter,
      },
    },
    rejected: {
      label: 'Rejected',
      view: (
        <QuotationRequestTable
          addOnFilter={{
            status: 'Rejected',
            ...filterValues.filter,
            requested_by: employee._id,
          }}
          tab={'Rejected'}
          searchValue={searchValue}
          {...props}
        />
      ),
      api: '/quotation/all',
      search: searchValue,
      searchFields: 'po_number',
      addOnFilter: {
        requested_by: employee._id,
        status: 'Rejected',
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search PO Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
