import React from 'react';

import {LeftMenuView} from '../../../components/menu/LeftMenuView';

import {ProjectClientTab, ProjectTeamTab} from './ProjectTeamTab';

const ProjectTeamMenu = props => {
  const {route: {params} = {}} = props;

  const menus = [
    {
      label: 'Internal Team',
      view: <ProjectTeamTab params={params} />,
    },
    {
      label: 'Client',
      view: <ProjectClientTab params={{params}} isClient={true} />,
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export default ProjectTeamMenu;
