import React from 'react';
import {Table} from '../../../components/table/Table';
import {Text} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';

const DeliveryTable = props => {
  const {filterParams, searchValue, params} = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'deliveryList',
  });
  return (
    <Table
      {...props}
      api={'/projectResourceWorkingData/get-deliveries'}
      addOnFilter={filterParams}
      params={params}
      eventSourceId="deliveryList"
      searchFields={['delivery_number']}
      search={searchValue}
      columns={[
        {
          field: 'account_owner_id',
          header: 'Relation',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          field: 'delivery_owner_id',
          header: 'Delivery Owner',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          header: 'Type',
          type: 'text',
          render: ({row, styles = {}}) => (
            <Text style={{...styles.rowText}}>
              {row && row.order && row.order.order_type === 'r'
                ? 'T&M'
                : 'Fixed'}
            </Text>
          ),
        },
        {
          type: 'text',
          header: 'Delivery No',
          field: 'delivery_number',
          width: 160,
        },
        {
          header: 'Description',
          field: 'description',
          width: 200,
        },
        {
          type: 'date',
          header: 'Due Date',
          field: 'due_date',
        },
        {
          header: 'Customer',
          field: 'customer.name',
          width: 200,
        },
        {
          type: 'currency',
          header: 'Amount',
          field: 'base_amount',
        },
        {
          type: 'number',
          header: 'Potential',
          field: 'variable_potential',
        },
        {
          type: 'number',
          header: 'Unbilled',
          field: 'base_pending_amount',
        },
        {
          type: 'date',
          header: 'Advance Due',
          field: 'advance_due_date',
        },
        {
          type: 'date',
          header: 'Invoice Due',
          field: 'invoice_due_date',
        },
      ]}
      moreActions={[
        {
          title: 'Cancel Delivery',
          onPress: ({row}) => {
            invoke({
              uri: `/projectresourceworkingdata/${row?._id}`,
              props: {
                ...row,
                status: 'Cancelled',
              },
            });
          },
        },
      ]}
    />
  );
};

export default DeliveryTable;
