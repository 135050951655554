import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import { useFilter } from '@unthinkable/react-filters';
import { GroupFilter } from '../../../components/filter/Filters';

const RolePermissionTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {row, appRole} = params || {};

  const {applyFilter, filterValues, onChangeFilter} = useFilter({});
  const {filter = {}, params: filterParams = {}} = filterValues || {};

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'rolePermissionRemoved',
  });

  return (
    <Table
      eventSourceId={['rolePermissionAdded', 'rolePermissionRemoved']}
      api={`/rolepermissions`}
      fields={{
        app_role_id: {display_label: 1},
        app_resource_id: {display_label: 1, type: 1},
        scope: 1,
        exclude_resource: 1,
      }}
      filter={{app_role_id: row?._id, ...filter}}
      onRowPress={({row}) => {
        const {app_resource_id: {type} = {}} = row || {};
        type === 'DataEntity' || type === 'DataView'
          ? navigation.navigate('edit-role-permissions-data-entity', {row})
          : navigation.navigate('edit-role-permissions', {row});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            (!appRole ? 
            <GroupFilter
              filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                label: 'App Resource',
                api: '/appresources',
                placeholder: 'Select',
                suggestionField: 'display_label',
                valueField: 'display_label',
                field: 'app_resource_id',
              },
              {
                type: 'autoComplete',
                label: 'App Roles',
                api: '/approles',
                placeholder: 'Select',
                suggestionField: 'display_label',
                valueField: 'display_label',
                field: 'app_role_id',
              },
            ]}
            /> : void 0), 
            <AddButton
              title="Add Role Permission Data Entity"
              view="add-role-permissions-data-entity"
              params={props?.route?.params}
            />,
            <AddButton
              title="Add Role Permission"
              view="add-role-permissions"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'App Role',
          field: 'app_role_id.display_label',
          type: 'text',
        },
        {
          header: 'App Resource',
          field: 'app_resource_id.display_label',
          type: 'text',
        },
        {
          header: 'Scope',
          field: 'scope',
          type: 'text',
        },
        {
          header: 'Exclude',
          field: 'exclude_resource',
          type: 'text',
          width: 80
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            const {app_resource_id: {type} = {}} = row || {};
            type === 'DataEntity' || type === 'DataView'
              ? navigation.navigate('edit-role-permissions-data-entity', {row})
              : navigation.navigate('edit-role-permissions', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/rolepermissions/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default RolePermissionTable;
