import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { CASCADER_NAVIGATE_TO_ADD_VIEW } from '../../common/constants/SourceConstants';

export const UpdateOwnerForm = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Account Owner'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'Owner',
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Channel & Campaign',
          label: 'Channel & Campaign',
          field: 'campaign_type',
          type: 'cascader',
          cascades: [
            {
              label: 'Select Source',
              field: 'campaign_type',
              api: '/types/suggestionsForLead',
              suggestionField: 'label',
              valueField: 'label',
            },
            {
              label: 'Campaign Value',
              placeholder: 'Campaign Id',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',

              params: ({values: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
              onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                navigation,
                params,
              }),
            },
          ],
        },
      ]}
    />
  );
};

export const UpdateOwnerRowForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  return (
    <Form
      defaultValues={{...row}}
      onSubmit={onSubmit}
      header={'Update Account Owner'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'Owner',
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
    />
  );
};

export const UpdateOwnerFormForFreeProspect = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        props.lead_status = 'Active';
        props.activity_date = new Date();
        props.added_on = getZeroTimeDate(new Date());
        onSubmit(props);
      }}
      header={'Update Account Owner'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'Owner',
          label: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
        {
          placeholder: 'Channel & Campaign',
          label: 'Channel & Campaign',
          field: 'campaign_type',
          type: 'cascader',
          cascades: [
            {
              label: 'Select Source',
              field: 'campaign_type',
              api: '/types/suggestionsForLead',
              suggestionField: 'label',
              valueField: 'label',
            },
            {
              label: 'Campaign Value',
              placeholder: 'Campaign Id',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',

              params: ({values: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
              onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                navigation,
                params,
              }),
            },
          ],
        },
      ]}
    />
  );
};
