import React from 'react';
import {Table} from '../../../components/table/Table';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {ColorTag} from '../../../components/chip/ColorChip';

const RenderLevel = props => {
  const {row} = props;
  const SKILL_LEVELS = {
    A: 'Expert-A',
    B: 'Intermediate-B',
    C: 'Beginner-C',
    D: 'Novice-D',
  };
  return <ColorTag {...props} value={SKILL_LEVELS[row.level]} />;
};

export const EmployeeSkillTable = props => {
  const {
    route: {params},
  } = props;
  const {skill_metrics} = params;
  return (
    <Table
      api="/employeeSkills"
      filter={{
        skill_metrics,
      }}
      fields={{
        employee_id: {
          name: 1,
          color: 1,
        },
        level: 1,
        tools: {
          name: 1,
          color: 1,
        },
      }}
      columns={[
        {
          field: 'employee_id.name',
          colorField: 'employee_id.color',
          header: 'Resource',
          type: 'colorTag',
        },
        {
          header: 'Level',
          type: 'colorTag',
          render: RenderLevel,
          width: 180,
        },
        {
          field: 'tools',
          labelField: 'name',
          titleField: 'name',
          colorField: 'color',
          header: 'Tool',
          maxChips: 5,
          render: MultiColorTag,
        },
      ]}
      {...props}
    />
  );
};
