import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';

export const AttendanceBaseSalary = props => {
  const {route: {params} = {}} = props;

  const {row, key, ...rest} = params;

  const invoke = useInvoke({
    method: 'post',
  });
  return (
    <Form
      onSubmit={data => {
        invoke({
          uri: '/invoke',
          props: {data, ...rest},
        });
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'date',
          field: 'invoiceDate',
          label: 'Date',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'month_id',
          label: 'Month',
          api: '/monthtypes',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'year_id',
          label: 'Year',
          api: '/yeartypes',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'payable_month_id',
          label: 'Payable Month',
          api: '/monthtypes',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: () => {
            if (key === 'Performance') return true;
          },
        },
        {
          type: 'autoComplete',
          field: 'payable_year_id',
          label: 'Payable Year',
          api: '/yeartypes',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: () => {
            if (key === 'Performance') return true;
          },
        },
      ]}
      {...props}
    />
  );
};

export const GenerateAttendanceBaseSalary = props => {
  const {route = {}} = props;
  const {params = {}} = route;
  if (params?.key == 'Attendance') {
    return (
      <AttendanceBaseSalary
        header="Generate Attendance Base Salary"
        {...props}
      />
    );
  }
  if (params?.key == 'Fixed') {
    return (
      <AttendanceBaseSalary header="Generate Fixed Base Salary" {...props} />
    );
  }
  if (params?.key == 'Performance') {
    return (
      <AttendanceBaseSalary
        header="Generate Performance Base Salary"
        {...props}
      />
    );
  }
};
