import {
  Text,
  TouchableOpacity,
  View,
  Image,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';
import moment from 'moment';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter} from '../../../components/filter/Filters';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {MultiTag} from '../../../components/chip/MultiChip';
import {randomColor} from '../../../utils/util';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import { TabView } from '../../../components/tab';
const financialYearRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  return (
    <>
      <Text
        numberOfLines={1}
        title={row?.financial_year_id?.name}
        style={{
          ...rowText,
          overflow: 'hidden',
        }}>
        {`Declaration for FY ${row?.financial_year_id?.name}`}
      </Text>
    </>
  );
  if (row?.financial_year_id) {
  } else {
    return null;
  }
};

const isFreezeRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row?.is_freeze) {
    return (
      <Text
        numberOfLines={1}
        title={row?.financial_year_id?.name}
        style={{
          ...rowText,
          overflow: 'hidden',
        }}>
        Freezed
      </Text>
    );
  } else {
    return (
      <Text
        numberOfLines={1}
        title={row?.financial_year_id?.name}
        style={{
          ...rowText,
          overflow: 'hidden',
        }}>
        No
      </Text>
    );
  }
};

const taxableIncomeRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row?.net_taxable_income) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            // color: '#30839f',
            overflow: 'hidden',
          }}>
          {row?.net_taxable_income.toFixed(2)}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const totalTaxAmount = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row?.total_tax_amount) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            // color: '#30839f',
            overflow: 'hidden',
          }}>
          {row?.total_tax_amount.toFixed(2)}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const pendingAmount = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  let pendingAmt = 0;
  if (row?.total_tax_amount && row?.tax_already_paid) {
    pendingAmt = (row?.total_tax_amount || 0) - (row?.tax_already_paid || 0);
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          color: '#FF9500',
          overflow: 'hidden',
        }}>
        {pendingAmt.toFixed(2)}
      </Text>
    );
  } else {
    return null;
  }
};
const taxAlreadyPaid = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (row?.tax_already_paid) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            // color: '#30839f',
            overflow: 'hidden',
          }}>
          {row?.tax_already_paid.toFixed(2)}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const submitTaxDeclaration = ({invoke}) => ({
  title: 'Submit Declaration',
  confirm: {
    title: 'Submit Declaration',
    message: 'Do you want submit the tax?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/incometax/submitted`,
      props: {
        row,
        status: 'Submitted',
      },
    });
  },
});

const FINANCIAL_YEAR = {
  header: 'Declaration for FY',
  render: financialYearRender,
  minWidth: 150,
};

const DECLARED_ON = {
  header: 'Declared On',
  field: 'tax_calculation_date',
  type: 'date',
  align: 'center',
  width: 150,
};

const CALCULATION_TYPE = {
  header: 'Type',
  field: 'calculation_type',
  variant: 'secondary',
  width: 150,
};

const IS_FREEZE = {
  header: 'Is Freezed',
  render: isFreezeRender,
  width: 150,
};

const NET_TAXABLE_INCOME = {
  header: 'Income',
  render: taxableIncomeRender,
  type: 'number',
  width: 150,
  toFixed: 0,
};

const TOTAL_TAX_AMOUNT = {
  header: 'Tax',
  render: totalTaxAmount,
  type: 'number',
  width: 150,
  toFixed: 0,
};
const PENDING_AMT_RENDER = {
  header: 'Pending',
  render: pendingAmount,
  type: 'number',
  width: 150,
  toFixed: 0,
};

const TAX_ALREADY_PAID = {
  header: 'Paid',
  render: taxAlreadyPaid,
  width: 150,
  toFixed: 0,
};

export const FixedSalaryTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {download} = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};

  return (
    <Table
      api={'/fixedSalary/self'}
      params={params}
      eventSourceId={['myAttendance', 'allattendance']}
      // addOnFilter={}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`fixed-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
          width: 300,
        },
        {
          header: 'Fixed Gross',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.gross_amount) {
              return (
                <Text
                  style={{
                    ...rowText,
                    color: '#FF9500',
                  }}>
                  {'₹' + row.gross_amount.toFixed(2)}
                </Text>
              );
            } else {
              return void 0;
            }
          },
          type: 'currency',
          width: 300,
        },
        {
          header: 'Fixed Earning',
          field: 'payable_amount',
          toFixed: 1,
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row?.payable_amount) {
              return (
                <Text
                  style={{
                    ...rowText,
                    color: '#007AFF',
                  }}>
                  {'₹' + (row?.payable_amount).toFixed(2)}
                </Text>
              );
            } else {
              return void 0;
            }
          },
          type: 'currency',
          width: 300,
        },
        {
          header: 'Month of Payment',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                  {moment(row?.invoice_date).format("MMM YYYY")}
              </Text>
            );
          },
          width: 300,
        },
      ]}
    />
  );
};


export const SelfPayslipTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {download} = useAppStateContext();
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};

  return (
    <Table
      api={'/fixedSalary/self'}
      params={params}
      eventSourceId={['myAttendance', 'allattendance']}
      // addOnFilter={}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`fixed-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
        },
        {
          render: props => {
            const {
              styles: {rowText},
              row,
            } = props;
            const colors = useTheme('colors');
            const icons = useTheme('icons');
            return (
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onPress={() => {
                  // download({uri: `/salarySlipDownload/${row._id}`});
                  download({
                    uri: `/generateSalarySlipData`,
                    props: {
                      requestId: row._id,
                    },
                  });
                }}>
                <Image
                  source={icons.DownloadBlue}
                  style={{height: 16, width: 16, marginRight: 5}}></Image>
                <Text style={{...rowText, color: '#007AFF'}}>Payslip</Text>
              </TouchableOpacity>
            );
          },
          width: 150,
        },
      ]}
    />
  );
};

export const MyIncentiveTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});

  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    Open: {
      label: 'KPI Based',
      view: <KPIIncentiveTable filter={filter} {...props} />,
      eventSourceId: 'kpiincentiveSalary',
      api: '/kpiincentive/self',
    },
    Submitted: {
      label: 'Others',
      view: <IncentiveSalaryTable filter={filter} {...props} />,
      eventSourceId: ['incentiveSalary'],
      api: '/incentivesalary/self',
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
}

export const IncentiveSalaryTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/incentivesalary/self'}
      params={params}
      eventSourceId={'incentiveSalary'}
      // addOnFilter={filter}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`incentive-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Month & year',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Component',
          field: 'component_details.salary_component_id.name',
          type: 'text',
        },
        // {
        //   header: 'Add On',
        //   field: 'invoice_date',
        //   type: 'date',
        // },
        {
          header: 'Payable',
          field: 'component_details.payable_amount',
          type: 'text',
        },
        {
          header: 'Gross',
          field: 'component_details.gross_amount',
          type: 'text',
        },
        {
          header: 'Month of Payment',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row?.invoice_date && moment(row?.invoice_date).format("MMMM YYYY")}
              </Text>
            );
          },
        },
      ]}
    />
  );
};

export const BonusSalaryTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/bonus/self'}
      params={params}
      eventSourceId={'bonusSalary'}
      // addOnFilter={filter}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`bonus-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Created On',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles?.rowText}}>
                {moment(row.invoice_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Month & year',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Component',
          field: 'component_details.salary_component_id.name',
          type: 'text',
        },
        {
          header: 'Payable',
          field: 'payable_amount',
          type: 'text',
        },
        {
          header: 'Gross',
          field: 'gross_amount',
          type: 'text',
        },
        {
          header: 'Month of Payment',
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row?.invoice_date && moment(row?.invoice_date).format("MMMM YYYY")}
              </Text>
            );
          },
        },
      ]}
    />
  );
};

export const ICIncentiveTable = ({navigation}) => {
  const {applyFilter, filterValues} = useFilter({});
  let {filter, params} = filterValues;
  params = {...params, ...filter};
  return (
    <Table
      eventSourceId="icincentiveSalary"
      api={'/icincentive/self'}
      params={params}
      addOnFilter={filter}
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'date',
                    field: 'period',
                    asParam: true,
                    label: 'Date',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`ic-incentive-salary-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
        },
        {
          header: 'Payable',
          field: 'variable',
          type: 'currency',
        },
      ]}
    />
  );
};

export const IcIncentiveTMBillingTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/icincentivetm/self'}
      params={params}
      eventSourceId={'selfBillingSalary'}
      // addOnFiler={filter}
      search={searchValue}
      columns={[
        {
          field: 'project.project',
          header: 'Project',
          type: 'text',
        },
        {
          header: 'Delivery',
          type: 'text',
          field: 'delivery_number',
        },
        {
          header: 'Efficiency',
          type: 'text',
          render: ({row}) => {
            let {totalVariableHours, totalEffHrs, efficiencyContributionValue} =
              row || {};
            let efficiency =
              efficiencyContributionValue > 1000
                ? (efficiencyContributionValue / 1000).toFixed(0) + 'k'
                : efficiencyContributionValue;
            return (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 1,
                      paddingBottom: 5,
                    }}>
                    {(totalVariableHours && totalVariableHours.toFixed(2)) || 0}
                  </Text>
                  <Text style={{paddingTop: 5}}>
                    {(totalEffHrs && totalEffHrs.toFixed(2)) || 0}
                  </Text>
                </View>
                <Text style={{...Headline9}}>{`${' '} = ${
                  efficiency || 0
                } %`}</Text>
              </View>
            );
          },
        },
        {
          header: 'CSAT',
          type: 'text',
          field: 'CSAT',
        },
        {
          header: 'Amount',
          type: 'text',
          field: 'projectIncentive',
        },
      ]}
    />
  );
};

export const IcIncentiveFixedBillingTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/icincentivefixed/self'}
      params={params}
      eventSourceId={'selfBillingSalary'}
      search={searchValue}
      columns={[
        {
          field: 'project.project',
          header: 'Project',
          type: 'text',
        },
        {
          header: 'Delivery',
          type: 'text',
          field: 'delivery_number',
        },
        {
          header: 'Potential',
          type: 'text',
          field: 'resourcePotential',
        },
        {
          header: 'Efficiency',
          type: 'text',
          render: ({row}) => {
            let {totalVariableHours, totalEffHrs, efficiencyContributionValue} =
              row || {};
            let efficiency =
              efficiencyContributionValue > 1000
                ? (efficiencyContributionValue / 1000).toFixed(0) + 'k'
                : efficiencyContributionValue;
            return (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 1,
                      paddingBottom: 5,
                    }}>
                    {(totalVariableHours && totalVariableHours.toFixed(2)) || 0}
                  </Text>
                  <Text style={{paddingTop: 5}}>
                    {(totalEffHrs && totalEffHrs.toFixed(2)) || 0}
                  </Text>
                </View>
                <Text style={{...Headline9}}>{`${' '} = ${
                  efficiency || 0
                } %`}</Text>
              </View>
            );
          },
        },
        {
          header: 'CSAT',
          type: 'text',
          field: 'CSAT',
        },
        {
          header: 'Amount',
          type: 'text',
          field: 'projectIncentive',
        },
      ]}
    />
  );
};

export const KPIIncentiveTable = props => {
  let {navigation, addOnFilter} = props;
  const {applyFilter, filterValues} = useFilter({period: getCurrentMonth()});
  let {filter, params} = filterValues;
  params = {...params};
  const {user} = useAppStateContext();
  return (
    <Table
      api={'/kpiincentive/self'}
      params={params}
      eventSourceId={'kpiincentiveSalary'}
      addOnFilter={addOnFilter}
      // search={searchValue}
      onRowPress={props => {
        const {row} = props;
        const {user: users, ...rest} = row;
        const userData = {
          _id: user?._id,
          name: user?.name,
          employee: {
            _id: user?.employee?._id,
            name: user?.employee?.name,
            employee_code: user?.employee?.employee_code,
          },
        };
        navigation.navigate(`ic-incentive-salary-detail-tab`, {
          row: {...rest, user: userData, ...params},
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="KPI Incentive"
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'date',
                    field: 'period',
                    asParam: true,
                    label: 'Date',
                  },
                  {
                    type: 'autoComplete',
                    field: 'subsidiary',
                    asParam: true,
                    label: 'Organization',
                    api: '/organizations',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        // {
        //   header: 'Employee',
        //   field: 'employee.name',
        //   type: 'text',
        // },
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
        },
        {
          header: 'Amount',
          field: 'variable',
          type: 'currency',
        },
      ]}
    />
  );
};

export const KPIIncentiveDetailRFRTable = props => {
  let {navigation, params} = props;
  let {applyFilter, filterValues} = useFilter({});
  let {filter, params: filterParams} = filterValues;
  params = {...params, ...filter};
  return (
    <Table
      {...props}
      api={'/kpiIncentiveRFRSalary/self'}
      params={params}
      eventSourceId={'kpiincentiveSalary'}
      addOnFilter={filterParams}
      // search={searchValue}
      // onRowPress={props => {
      //   const {row} = props;
      //   navigation.navigate(`ic-incentive-salary-detail-tab`, {
      //     row,
      //   });
      // }}
      columns={[
        {
          header: 'Mentor',
          field: 'skill_manager.name',
        },
        {
          header: 'Resources',
          field: 'totalResource',
        },
        {
          header: 'Drop',
          field: 'dropedCount',
        },
        {
          header: 'Won',
          field: 'wonCount',
        },
        {
          header: 'Lost',
          field: 'lostCount',
        },
        {
          header: 'Other',
          field: 'otherCount',
        },
        {
          header: 'Success',
          field: 'success_percentage',
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'number',
          count_field: 'amount',
          aggregate: true,
        },
      ]}
    />
  );
};

export const KPIIncentiveDetailRFQTable = props => {
  let {navigation, params} = props;
  let {applyFilter, filterValues} = useFilter({});
  let {filter, params: filterParams} = filterValues;
  params = {...params, ...filter};
  return (
    <Table
      {...props}
      api={'/kpiIncentiveRFQSalary/self'}
      params={params}
      eventSourceId={'kpiincentiveSalary'}
      addOnFilter={filterParams}
      // search={searchValue}
      // onRowPress={props => {
      //   const {row} = props;
      //   navigation.navigate(`ic-incentive-salary-detail-tab`, {
      //     row,
      //   });
      // }}
      columns={[
        {
          header: 'RFQ Owner',
          field: 'name',
          type: 'text',
          width: 200,
        },
        {
          field: 'totalCustomers',
          header: 'Customers',
          field: 'entity.name',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Outcome Date',
          field: 'won_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Proposal Hours',
          field: 'proposal_hours',
          type: 'text',
          width: 150,
        },
        {
          header: 'Consultant Count',
          field: 'techPersonCount',
          type: 'text',
          width: 150,
        },
        {
          header: 'Primary Owner',
          field: 'primary_owner.name',
          type: 'text',
          widh: 200,
        },
        {
          header: 'Amount',
          field: 'amount',
          aggregate: true,
          type: 'number',
          count_field: 'amount',
        },
      ]}
    />
  );
};

export const KPIIncentiveDetailTMTable = props => {
  let {navigation, params} = props;
  let {applyFilter, filterValues} = useFilter({});
  let {filter, params: filterParams} = filterValues;
  params = {...params, ...filter};
  return (
    <Table
      api={'/kpiIncentiveTMSalary/self'}
      params={params}
      eventSourceId={'kpiincentiveSalary'}
      addOnFilter={filterParams}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
          minWidth: 200,
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.project.project || ''}
                items={[
                  {
                    value: row?.delivery_id.delivery_number || '',
                  },
                  {
                    value: row?.CSAT || '',
                  },
                ]}
              />
            );
          },
        },
        // {
        //   header: 'CSAT',
        //   field: 'CSAT',
        //   width: 100,
        // },
        // {
        //   header: 'Resource Count',
        //   field: 'resourceCount',
        //   width: 150,
        //   type: 'number',
        //   // toFixed: 2,
        // },
        {
          header: 'Potential ',
          field: 'potential',
          width: 100,
        },
        {
          header: 'Delivered(Efficiency)',
          field: 'deliveredHours',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText}}>
                {row?.deliveredHours}({row?.efficiency?.toFixed(1)})
              </Text>
            );
          },
          width: 200,
        },
        {
          header: 'Leave Hours',
          field: 'leaveHours',
          type: 'number',
          width: 150,
        },
        {
          header: 'Leave Benefit',
          type: 'number',
          field: 'leaveBenifit',
          width: 150,
        },
        {
          field: 'resource.name',
          header: 'Resource',
          width: 150,
        },
        {
          header: 'L1 Owner',
          field: 'level_1_owner.name',
          width: 150,
        },
        {
          header: 'L2 Owner',
          field: 'level_2_owner.name',
          width: 150,
        },
        {
          header: 'L3 Owner',
          field: 'level_3_owner.name',
          width: 150,
        },
        {
          header: 'L1 Amount',
          field: 'level_1_amount',
          aggregate: true,
          count_field: 'level_1_amount',
          type: 'number',
          width: 150,
        },
        {
          header: 'L2 Amount',
          field: 'level_2_amount',
          type: 'number',
          aggregate: true,
          count_field: 'level_2_amount',
          width: 150,
        },
        {
          header: 'L3 Amount',
          field: 'level_3_amount',
          aggregate: true,
          count_field: 'level_3_amount',
          type: 'number',
          width: 150,
        },
        // {
        //   field: 'delivery_id.delivery_number',
        //   header: 'Delivery',
        //   width: 150,
        // },
      ]}
    />
  );
};

export const KPIIncentiveDetailFixedTable = props => {
  let {navigation, params} = props;
  let {applyFilter, filterValues} = useFilter({});
  let {filter, params: filterParams} = filterValues;
  params = {...params, ...filter};
  return (
    <Table
      api={'/kpiIncentiveFixedSalary/self'}
      params={params}
      eventSourceId={'kpiincentiveSalary'}
      addOnFilter={filterParams}
      // search={searchValue}
      // onRowPress={props => {
      //   const {row} = props;
      //   navigation.navigate(`ic-incentive-salary-detail-tab`, {
      //     row,
      //   });
      // }}
      columns={[
        // {
        //   header: 'Milestone',
        //   type: 'text',
        //   field: 'delivery_number',
        //   width: 200,
        // },
        {
          type: 'text',
          header: 'Project',
          // render: ({row}) => (row && row.project && row.project.project) || '',
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.project.project || ''}
                items={[
                  {
                    value: row?.delivery_number || '',
                  },
                  {
                    value: row?.CSAT || '',
                  },
                ]}
              />
            );
          },
          minWidth: 300,
        },
        // {field: 'CSAT', header: 'CSAT', width: 100},
        {
          header: 'Initation Date',
          type: 'date',
          field: 'start_date',
          width: 150,
        },
        {
          header: 'Planned',
          type: 'date',
          field: 'due_date',
          width: 100,
        },
        {
          header: 'Actual',
          type: 'date',
          field: 'delivered_date',
          width: 100,
        },

        {
          header: 'Total Delivered Hrs',
          field: 'total_delivered_hours',
          type: 'number',
          width: 200,
        },
        {
          header: 'Billed Hrs',
          field: 'billed_hours',
          type: 'number',
          width: 150,
        },
        {
          header: 'Allocation Hrs',
          field: 'total_allocation_hours',
          type: 'number',
          width: 200,
        },
        {
          header: 'Name',
          field: 'name',
          width: 200,
        },
        {
          header: 'Variance%',
          field: 'diff',
          width: 100,
        },
        {
          header: 'L1 owner',
          field: 'level_1_owner.name',
          width: 150,
        },
        {
          header: 'L2 owner',
          field: 'level_2_owner.name',
          width: 150,
        },
        {
          header: 'L3 owner',
          field: 'level_3_owner.name',
          width: 150,
        },
        {
          header: 'L1 Amt',
          align: 'right',
          field: 'level_one_amount',
          aggregate: true,
          count_field: 'level_one_amount',
          width: 150,
          type: 'number',
        },
        {
          header: 'L2 Amt',
          align: 'right',
          field: 'level_two_amount',
          aggregate: true,
          type: 'number',
          count_field: 'level_two_amount',
          width: 150,
        },
        {
          header: 'L3 Amt',
          align: 'right',
          field: 'level_three_amount',
          aggregate: true,
          type: 'number',
          count_field: 'level_three_amount',
          width: 150,
        },
      ]}
    />
  );
};

export const KPIIncentiveDetailAuditTable = props => {
  let {navigation, params} = props;
  let {applyFilter, filterValues} = useFilter({});
  let {filter, params: filterParams} = filterValues;
  params = {...params, ...filter};
  return (
    <Table
      {...props}
      api={'/kpiIncentiveAuditSalary/self'}
      params={params}
      addOnFilter={filterParams}
      eventSourceId={'kpiincentiveSalary'}
      columns={[
        {
          header: 'Auditor',
          field: 'auditor.name',
        },
        {
          header: 'Assigned',
          render: ({row}) => {
            let {assigned_audits = 0} = row || {};
            return assigned_audits;
          },
        },
        {header: 'Completed', field: 'complete_audits'},
        {header: 'Audit Status', type: 'text', field: 'status'},
        {header: 'Completion Date', field: 'submitted_date'},
        {
          header: 'All Projects',
          render: ({row}) => {
            let {allProjects = []} = row || {};
            return allProjects && allProjects.join(', ');
          },
        },
        {
          field: 'practice_head_ratting',
          align: 'center',
          header: 'Feedback Rating',
        },
        {
          header: 'Amount',
          type: 'number',
          field: 'amount',
          aggregate: true,
          count_field: 'amount',
        },
      ]}
    />
  );
};

export const ShiftAllowanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/dailyincentive/self'}
      params={params}
      eventSourceId={'shiftAllowanceSalary'}
      // addOnFilter={filter}
      search={searchValue}
      // onRowPress={props => {
      //   const {row} = props;
      //   navigation.navigate(`incentive-component-detail`, {
      //     row,
      //   });
      // }}
      columns={[
        {
          header: 'Period',
          type: 'date',
          width: 800,
          render: ({row, styles}) => {
            let {rowText} = styles;
            if (row.from_date && row.to_date) {
              return (
                <Text style={{...rowText}}>
                  {moment(row?.from_date).format('DD MMM YY')} -
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              );
            } else {
              return void 0;
            }
          },
        },
        {
          header: 'Payable Amount',
          field: 'amount',
          type: 'currency',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: '#34C759'}}>₹{row?.amount}</Text>
            );
          },
          width: 350,
        },
        {
          header: 'Month of Payment',
          field: 'processed_date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            if (row.processed_date) {
              return (
                <Text style={{...rowText}}>
                  {moment(row?.processed_date).format('MMM YYYY')}
                </Text>
              );
            } else {
              return void 0;
            }
          },
          width: 160,
        },
      ]}
    />
  );
};

export const CompensationStructureSalaryTable = props => {
  let {params, navigation} = props;
  const {period = {}, ...rest} = params || {};

  params = {
    ...rest,
  };
  params = {...params};
  return (
    <Table
      api={'/employeesalarycomponent/self'}
      params={params}
      eventSourceId={'compensationstructure'}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`compensation-structure-detail-salary`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Duration',
          type: 'date',
          width: 800,
          render: ({row, styles}) => {
            let {rowText} = styles;
            if (row.from_date && row.to_date) {
              return (
                <Text style={{...rowText}}>
                  {moment(row?.from_date).format('DD MMM YY')} -
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              );
            } else {
              return void 0;
            }
          },
        },
        {
          header: 'CTC (per month)',
          field: 'ctc_amount',
        },
      ]}
    />
  );
};

export const OpenTaxDeclarationTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};
  const invoke = useInvoke({method: 'put', eventSourceId: 'taxDeclaration'});
  const {download} = useAppStateContext();
  return (
    <Table
      api={'/incometax/self'}
      addOnFilter={{status: 'Open'}}
      params={params}
      sort={{tax_calculation_date: -1}}
      eventSourceId={'taxDeclaration'}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('tax-declarations-card', {
          row,
          self: true,
        });
      }}
      columns={[
        FINANCIAL_YEAR,
        CALCULATION_TYPE,
        DECLARED_ON,
        NET_TAXABLE_INCOME,
        TOTAL_TAX_AMOUNT,
        TAX_ALREADY_PAID,
        PENDING_AMT_RENDER,
        IS_FREEZE,
      ]}
      moreActions={[
        {
          title: 'Update Tax Calculation Type',
          onPress: ({row}) => {
            navigation.navigate('update-calculation-type', {
              row,
            });
          },
        },
        {
          title: 'Tax Sheet',
          onPress: ({row}) => {
            download({
              uri: `/incometax/taxsheet`,
              props: {
                Id: row._id,
                callFor: 'Tax Sheet',
              },
            });
          },
        },
        {
          title: 'Tax Sheet Annexure',
          onPress: ({row}) => {
            download({
              uri: '/incometax/annexure',
              props: {
                Id: row._id,
              },
            });
          },
        },
        submitTaxDeclaration({invoke}),
      ]}
    />
  );
};

export const SubmittedTaxDeclarationTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};
  const {download} = useAppStateContext();
  return (
    <Table
      api={'/incometax/self'}
      addOnFilter={{status: 'Submitted'}}
      params={params}
      sort={{tax_calculation_date: -1}}
      eventSourceId={'taxDeclaration'}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('tax-declarations-card', {
          row,
          readOnly: true,
          self: true,
        });
      }}
      columns={[
        FINANCIAL_YEAR,
        CALCULATION_TYPE,
        DECLARED_ON,
        NET_TAXABLE_INCOME,
        TOTAL_TAX_AMOUNT,
      ]}
      moreActions={[
        {
          title: 'Update Tax Calculation Type',
          onPress: ({row}) => {
            navigation.navigate('update-calculation-type', {
              row,
            });
          },
        },
        {
          title: 'Tax Sheet',
          onPress: ({row}) => {
            download({
              uri: `/incometax/taxsheet`,
              props: {
                Id: row._id,
                callFor: 'Tax Sheet',
              },
            });
          },
        },
        {
          title: 'Tax Sheet Annexure',
          onPress: ({row}) => {
            download({
              uri: '/incometax/annexure',
              props: {
                Id: row._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const AccpetedTaxDeclarationTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};
  const invoke = useInvoke({method: 'put', eventSourceId: 'taxDeclaration'});
  const {download} = useAppStateContext();
  return (
    <Table
      api={'/incometax/self'}
      addOnFilter={{status: 'Accepted'}}
      params={params}
      sort={{tax_calculation_date: -1}}
      eventSourceId={'taxDeclaration'}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('tax-declarations-card', {
          row,
          readOnly: true,
          self: true,
        });
      }}
      columns={[
        FINANCIAL_YEAR,
        CALCULATION_TYPE,
        DECLARED_ON,
        NET_TAXABLE_INCOME,
        TOTAL_TAX_AMOUNT,
      ]}
      moreActions={[
        {
          title: 'Tax Sheet',
          onPress: ({row}) => {
            download({
              uri: `/incometax/taxsheet`,
              props: {
                Id: row._id,
                callFor: 'Tax Sheet',
              },
            });
          },
        },
        {
          title: 'Tax Sheet Annexure',
          onPress: ({row}) => {
            download({
              uri: '/incometax/annexure',
              props: {
                Id: row._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const ReopenTaxDeclarationTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, ...filter};
  const invoke = useInvoke({method: 'put', eventSourceId: 'taxDeclaration'});
  const {download} = useAppStateContext();
  return (
    <Table
      api={'/incometax/self'}
      addOnFilter={{status: 'ReOpen'}}
      params={params}
      sort={{tax_calculation_date: -1}}
      eventSourceId={'taxDeclaration'}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('tax-declarations-card', {
          row,
          self: true,
        });
      }}
      columns={[
        FINANCIAL_YEAR,
        CALCULATION_TYPE,
        DECLARED_ON,
        NET_TAXABLE_INCOME,
        TOTAL_TAX_AMOUNT,
      ]}
      moreActions={[
        {
          title: 'Update Tax Calculation Type',
          onPress: ({row}) => {
            navigation.navigate('update-calculation-type', {
              row,
            });
          },
        },
        {
          title: 'Tax Sheet',
          onPress: ({row}) => {
            download({
              uri: `/incometax/taxsheet`,
              props: {
                Id: row._id,
                callFor: 'Tax Sheet',
              },
            });
          },
        },
        {
          title: 'Tax Sheet Annexure',
          onPress: ({row}) => {
            download({
              uri: '/incometax/annexure',
              props: {
                Id: row._id,
              },
            });
          },
        },
        submitTaxDeclaration({invoke}),
      ]}
    />
  );
};

export const InvoiceSalaryTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/invoice/self'}
      params={params}
      eventSourceId={'InvoiceSalary'}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`invoice-detail-salary`, {
          row,
        });
      }}
      columns={[
        {
          field: 'invoice_date',
          header: 'Invoice Date',
          type: 'date',
          width : 200
        },
        {
          field: 'invoice_no',
          header: 'Invoice No',
        },
        {
          render: CountCellRender,
          count_field: 'voucher_amount',
          aggregate: true,
          field: 'voucher_amount',
          header: 'Amount',
          align: 'right',
        },
        {
          render: CountCellRender,
          count_field: 'payable_amount',
          aggregate: true,
          field: 'payable_amount',
          header: 'Payable Amount',
          align: 'right',
        },
        {
          header: 'Pending Amount',
          align: 'right',
          render: ({row, styles}) => {
            const {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {row.pending_amount ? row?.pending_amount : 0}
              </Text>
            );
          },
        },
      ]}
    />
  );
};

export const SelfTMBillingTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    period,
    api,
  } = props;

  params = {...params, ...filterParams, ...filter, period};
  return (
    <Table
      api={api ? api : '/selfleveltmsalary/self'}
      params={params}
      eventSourceId={'selfBillingSalary'}
      search={searchValue}
      columns={[
        {
          field: 'month',
          header: 'Month',
          type: 'text',
        },
        {
          header: 'Level1 Owner',
          type: 'text',
          render: ({row}) => {
            let {level1OwnerCount} = row;
            return <Text>{level1OwnerCount || 0}</Text>;
          },
          onPress: ({row}) => {
            navigation.navigate(`view-deliveries`, {
              level1Owner: row.level1OwnerDeliveryIds,
            });
          },
        },
        {
          header: 'level2 Owner',
          type: 'text',
          // field: 'level2OwnerCount',
          render: ({row}) => {
            let {level2OwnerCount} = row;
            return <Text>{level2OwnerCount || 0}</Text>;
          },
          onPress: ({row}) => {
            navigation.navigate(`view-deliveries`, {
              level1Owner: row.level2OwnerDeliveryIds,
            });
          },
        },
        {
          header: 'Level3 Owner',
          type: 'text',
          render: ({row}) => {
            let {level3OwnerCount} = row;
            return <Text>{level3OwnerCount || 0}</Text>;
          },
          onPress: ({row}) => {
            navigation.navigate(`view-deliveries`, {
              level1Owner: row.level3OwnerDeliveryIds,
            });
          },
        },
      ]}
    />
  );
};

export const SelfFixedBillingTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    period,
    api,
  } = props;

  params = {...params, ...filterParams, ...filter, period};
  return (
    <Table
      api={api ? api : '/selflevelfixedsalary/self'}
      params={params}
      eventSourceId={'selfBillingSalary'}
      search={searchValue}
      columns={[
        {
          field: 'month',
          header: 'Month',
          type: 'text',
        },
        {
          header: 'Level1 Owner',
          type: 'text',
          render: ({row}) => {
            let {level1OwnerCount} = row;
            return <Text>{level1OwnerCount || 0}</Text>;
          },
          onPress: ({row}) => {
            navigation.navigate(`view-deliveries`, {
              level1Owner: row.level1OwnerDeliveryIds,
            });
          },
        },
        {
          header: 'level2 Owner',
          type: 'text',
          render: ({row}) => {
            let {level2OwnerCount} = row;
            return <Text>{level2OwnerCount || 0}</Text>;
          },
          onPress: ({row}) => {
            navigation.navigate(`view-deliveries`, {
              level1Owner: row.level2OwnerDeliveryIds,
            });
          },
        },
        {
          header: 'Level3 Owner',
          type: 'text',
          render: ({row}) => {
            let {level3OwnerCount} = row;
            return <Text>{level3OwnerCount || 0}</Text>;
          },
          onPress: ({row}) => {
            navigation.navigate(`view-deliveries`, {
              level1Owner: row.level3OwnerDeliveryIds,
            });
          },
        },
      ]}
    />
  );
};

export const SelfLevelTMDetailTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/selfLevelTMDetailSalary/self'}
      params={params}
      eventSourceId={'selfBillingSalary'}
      search={searchValue}
      columns={[
        {
          header: 'Relation',
          minWidth: 250,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.account_owner_id?.name}
                color={row?.account_owner_id?.color}
                official_email_id={row?.account_owner_id?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Delivery Owner',
          width: 250,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.delivery_owner_id?.name}
                color={row?.delivery_owner_id?.color}
                official_email_id={row?.delivery_owner_id?.official_email_id}
              />
            );
          },
        },
        {
          type: 'text',
          header: 'Employee',
          width: 250,
          render: ({row}) => {
            let resource = row.projectResources.reduce((total, one) => {
              if (one && one.resource && one.resource.name) {
                total.push(one.resource.name);
              }
              return total;
            }, []);
            return (
              <MultiTag
                color={randomColor()}
                values={resource}
                maxChips={2}
                gap={8}
              />
            );
          },
        },
        {
          type: 'text',
          header: 'Delivery No',
          field: 'delivery_number',
          width: 150,
        },
        {
          type: 'date',
          header: 'Due Date',
          field: 'due_date',
          width: 200,
        },
        {
          type: 'text',
          header: 'Customer',
          field: 'customer_id.name',
          width: 150,
        },
        {
          type: 'text',
          header: 'Working Potential',
          field: 'variable_potential',
          width: 200,
        },
      ]}
    />
  );
};

export const SelfLevelFixedDetailTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  params = {...params, ...filterParams, ...filter};
  return (
    <Table
      api={'/selfLevelFixedDetailSalary/self'}
      params={params}
      eventSourceId={'selfBillingSalary'}
      search={searchValue}
      columns={[
        {
          field: 'project',
          header: 'Project name',
          type: 'text',
        },
        {
          header: 'Code',
          type: 'text',
          fixed: 'code',
        },
        {
          header: 'Level 1 Owner',
          type: 'text',
          field: 'level_1_owner.name',
        },
        {
          header: 'Level 2 Owner',
          type: 'text',
          field: 'level_2_owner.name',
        },
        {
          header: 'Level 3 Owner',
          type: 'text',
          field: 'level_3_owner.name',
        },
        {
          header: 'Resources',
          type: 'text',
          field: 'no_of_resource',
        },
      ]}
    />
  );
};
