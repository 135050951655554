const filterParser = ({type, value}) => {
  if (type === 'date') {
    const {from, to} = value || {};
    return {
      $gte: from,
      $lte: to,
    };
  } else if (type === 'multiAutoComplete') {
    return {$in: value?.map(v => v._id)};
  } else if (type === 'boolean') {
    if (value === true) {
      return true;
    } else if (value === false) {
      return {$in: [false, null]};
    }
  } else {
    return value;
  }
};

export const populateFilter = (
  filterInfo,
  {type, field, value, asParam, dependentFilterFields},
) => {
  filterInfo = {...filterInfo, filterValues: {...filterInfo?.filterValues}};
  filterInfo.filterValues.params = filterInfo.filterValues.params || {};
  filterInfo.filterValues.plainFilter =
    filterInfo.filterValues.plainFilter || {};
  filterInfo.filterValues.filter = filterInfo.filterValues.filter || {};
  if (asParam) {
    filterInfo.filterValues.params = {
      ...filterInfo.filterValues.params,
      [field]: value,
    };
  } else if (type === 'search') {
    if (value === '') {
      value = void 0;
    }
    filterInfo.searchValue = value;
  } else if (type === 'fts') {
    // todo
  } else if (field) {
    let newFilter = {
      ...filterInfo.filterValues.filter,
    };
    let newPlainFilter = {
      ...filterInfo.filterValues.plainFilter,
    };
    if (value === undefined || value === null) {
      delete newFilter[field];
      delete newPlainFilter[field];
      if (!Object.keys(newPlainFilter).length) {
        newPlainFilter = void 0;
        newFilter = void 0;
      }
    } else {
      newPlainFilter[field] = value;
      newFilter[field] = filterParser({type, value});
    }
    filterInfo.filterValues.filter = newFilter;
    filterInfo.filterValues.plainFilter = newPlainFilter;
  }
  if (dependentFilterFields?.length) {
    dependentFilterFields.forEach(field => {
      if (filterInfo?.filterValues?.filter?.[field]) {
        delete filterInfo?.filterValues.filter[field];
      }
      if (filterInfo?.filterValues?.params?.[field]) {
        delete filterInfo?.filterValues.params[field];
      }
      if (filterInfo?.filterValues?.plainFilter?.[field]) {
        delete filterInfo?.filterValues.plainFilter[field];
      }
    });
  }
  return filterInfo;
};

export const resolveFilterValue = ({filterValues, field, asParam, type}) => {
  const {plainFilter, params} = filterValues || {};
  if (asParam) {
    return params?.[field];
  } else if (field) {
    return plainFilter?.[field];
  }
};
