import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';

export const messageControllers = props => {
  const {params, route, navigation} = props;

  let deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'campaignMessageDeleted',
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'markArchive',
  });

  const editCampaignMessage = ({row}) => {
    navigation.navigate(`edit-outreach-campaign-message-form`, {
      ...route?.params,
      row: row,
    });
  };
  const editNewCampaignMessage = ({row, dualMode}) => {
    navigation.navigate(`edit-campaign-message-form`, {
      ...route?.params,
      row: row,
      dualMode,
    });
  };
  
  const editCampaignContactMessage = ({row}) => {
    navigation.navigate(`edit-outreach-campaign-contact-message-form`, {
      ...params,
      row: row,
    });
  };

  const deleteCampaignMessage = ({row}) => {
    deleteInvoke({
      uri: `/campaignMessages/${row?._id}`,
      props: {
        checkReferences: true,
      },
    });
  };
  // Navigate to view email reply - Parveen -23-12-2023
  const editEmailReplyMessage = ({row}) => {
    navigation.navigate(`edit-outreach-campaign-email-reply-form`, {
      ...params,
      row: row,
    });
  };

  const markArchive = ({row}) => {
    invoke({
      uri: `/campaignMessages/${row._id}`,
      props: {
        status: 'archived',
      },
      success: 'Archived',
    });
  };

  return {
    editCampaignMessage,
    editCampaignContactMessage,
    deleteCampaignMessage,
    editEmailReplyMessage,
    markArchive,
    editNewCampaignMessage,
  };
};
