import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';

const DepartmentInputTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="DepartmentInput"
      api={`/departmentinputs`}
      fields={{
        label: 1,
        department: {name: 1},
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`department-input-detail`, {
          departmentInputId: row._id,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'label',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'department.name',
          header: 'department',
        },
      ]}
    />
  );
};

export default DepartmentInputTable;
