import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  RFRDeliveryOwnerWiseNew,
  RFRDeliveryOwnerWiseNewDeliveryOwner,
  RFRDeliveryOwnerWiseNewDeliveryOwnerWise,
  RFRMentorWise,
  RFRMentorWiseNew,
  RFRResourceWise,
  RFRResourceWiseNew,
} from '../../order/views/ResourceWiseRFRSuccessTabs';

import {
  EngineeringOpportunitiesTabView,
  EngineeringOpportunitiesTabViewForDeliveryOwners,
  StaffaugmentationOppResources,
} from '../../engineeringOpportunities/views/EngineeringOpportunitiesTabs';
import {PracticeWiseOpportunityTabNew} from '../../order/views/PracticeWiseOpportunityTab';

const staffaugmentationMenu = [
  {
    label: 'Positions',
    view: <EngineeringOpportunitiesTabView />,
  },
  {
    section: true,
    label: 'Candidates',
    menus: [
      {
        label: 'All',
        view: <StaffaugmentationOppResources />,
      },
      {
        label: 'Practice-wise',
        view: <PracticeWiseOpportunityTabNew />,
      },
      {
        label: 'DO-wise',
        view: <RFRDeliveryOwnerWiseNew />,
      },
      {
        label: 'Mentor-wise',
        view: <RFRMentorWiseNew />,
      },
      {
        label: 'Employee-wise',
        view: <RFRResourceWiseNew />,
      },
    ],
  },
];

const staffaugmentationMenuDeliveryOwners = [
  {
    label: 'Positions',
    view: <EngineeringOpportunitiesTabViewForDeliveryOwners />,
  },

  {
    label: 'Candidates',
    view: <RFRDeliveryOwnerWiseNewDeliveryOwnerWise />,
  },
];

export const StaffaugmentationOppMenu = props => {
  const {route: {params} = {}} = props;
  return (
    <LeftMenuView {...props} params={params} menus={staffaugmentationMenu} />
  );
};

export const StaffaugmentationOppMenuDeliveryOwners = props => {
  const {route: {params} = {}} = props;
  return (
    <LeftMenuView
      {...props}
      params={params}
      menus={staffaugmentationMenuDeliveryOwners}
    />
  );
};
