export const leadToCustomerFilter = row => {
  let addOnFilter = {};
  if (!row) {
    return addOnFilter;
  }
  const rowFilters = [
    {key: 'owner', value: row.owner},
    {key: 'geography_location', value: row.geography_location},
    {key: 'organization_category', value: row.organization_category},
    {key: 'employee_team', value: row.employee_team},
    {key: 'organization', value: row.organization},
  ];

  rowFilters.forEach(({key, value}) => {
    if (value) {
      addOnFilter[key] = value;
    }
  });

  return addOnFilter;
};
