import React from 'react';
import {Form} from '../../../components/form/Form';
import {useAppStateContext} from '../../../providers/AppState';

export const StatementOfAccount = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  const {download} = useAppStateContext();
  return (
    <Form
      onSubmit={props => {
        download({
          uri: '/statementOfAccount',
          props: props,
        });
      }}
      defaultValues={{
        _selectedIds_: [row._id],
        downloadType: 'pdf',
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'From Date',
          field: 'fromdate',
          type: 'date',
          size: 6,
          required: true,
        },
        {
          label: 'To Date',
          field: 'todate',
          type: 'date',
          size: 6,
          required: true,
        },
        {
          type: 'radioGroup',
          field: 'downloadType',
          label: 'Download Type',
          options: [
            {label: 'PDF', value: 'pdf'},
            {label: 'Excel', value: 'excel'},
          ],
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const CheckStatementOfAccount = props => {
  return <StatementOfAccount header="Check statement of account" {...props} />;
};
