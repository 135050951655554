import React from 'react';
import {Table} from '../../../components/table/Table';

export const CompletedTargetList = props => {
  const {
    route: {params},
  } = props;
  return (
    <Table
      api={`/departments/${params?.department?._id}/completedTargetsList`}
      params={params}
      columns={[
        {
          field: 'target_name',
          header: 'Target',
          width: 500,
        },
        {
          field: 'target_value',
          header: 'Target value',
        },

        {
          field: 'previous_target_value',
          header: 'Actual value',
        },
        {
          field: 'tasks',
          header: 'Completed/Total tasks',
          compareField: 'completed_tasks',
        },
        {
          field: 'team_cost',
          header: 'Team Cost',
        },
      ]}
    />
  );
};
