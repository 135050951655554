import React from 'react';
import {GroupTable} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {MultiTag} from '../../../components/chip/MultiChip';
import {Tag} from '../../../components/chip/Chip';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {useTheme} from '@unthinkable/react-theme';
import {Image} from '@unthinkable/react-core-components';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {InlineForm} from '../../../components/form/Form';
import {UserAvatarGroup} from '../../../components/avatar/UserAvatar';

export const RenderAssignments = ({row, assigneeField, ...rest}) => {
  const {Add} = useTheme('icons');
  const value = row[assigneeField];
  if (value?.length) {
    return <UserAvatarGroup {...rest} value={value} />;
  } else {
    return <Image source={Add} style={{height: 20, width: 20}} />;
  }
};

const addModuleMasterTaskAssignmentInlineForm = props => {
  const {
    route: {params},
  } = props;
  const {project, module} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/modules/${module?._id}/taskassignments`,
    eventSourceId: ['MasterTaskAssignment'],
    ...props,
  });

  return (
    <InlineForm
      onSubmit={onSubmit}
      defaultValues={{
        project_id: project?._id,
        status: 'Active',
      }}
      layoutFields={[
        {
          api: `/modules/${module?._id}/mastertasks`,
          label: 'Master Tasks',
          type: 'autoComplete',
          field: 'master_task',
          suggestionField: 'task',
          secondarySuggestionField: 'parent_model.label',
          valueField: 'task',
          required: true,
          params: {
            project_id: project?._id,
          },
        },
      ]}
    />
  );
};

export const PMTTaskAssignmentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {project, module, fromModule} = params;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'MasterTaskAssignment',
  });

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'MasterTaskAssignment',
  });
  // Akshat Garg - 21/03/24 - passing an additional param of source below so that we can determine whether the source_id is project_id or module_id
  return (
    <>
      {fromModule && addModuleMasterTaskAssignmentInlineForm(props)}
      <GroupTable
        eventSourceId={['MasterTaskAssignment']}
        api={`/masterTaskAssignmentsGroupBy`}
        params={{
          source_id: module?._id || project?._id,
          source: `${
            module?._id ? 'module' : project?._id ? 'project' : void 0
          }`,
        }}
        onRowPress={({row}) => {
          navigation.navigate(`edit-pmt-master-task-assignment`, {
            ...params,
            row,
          });
        }}
        groupRow={{
          data: 'children',
          title: 'label',
          defaultExpandedIndex: 0,
          hideRow: ({row}) => !row?.label,
        }}
        columns={[
          {
            type: 'text',
            field: 'task',
            header: 'Task',
          },
          {
            render: RenderAssignments,
            assigneeField: 'team',
            header: 'Team',
            width: 180,
            align: 'center',
            onPress: ({row}) => {
              navigation.navigate('assignment-team-form', {
                row,
                ...params,
              });
            },
          },
          {
            render: RenderAssignments,
            assigneeField: 'reviewer',
            header: 'Reviewers',
            width: 180,
            align: 'center',
            onPress: ({row}) => {
              navigation.navigate('assignment-reviewer-form', {
                row,
                ...params,
              });
            },
          },
          {
            header: 'Active',
            align: 'center',
            width: 80,
            render: ({row}) => {
              return (
                <Switch
                  value={row?.status === 'Active'}
                  onChangeValue={async () => {
                    invoke({
                      uri: `/mastertaskassignments/${row?._id}`,
                      props: {
                        status:
                          row?.status === 'Active' ? 'InActive' : 'Active',
                      },
                    });
                  }}
                />
              );
            },
            visible: !fromModule,
          },
          {
            header: 'Auto Create',
            align: 'center',
            width: 120,
            render: ({row}) => {
              if (!row.event_on) {
                return null;
              }
              return (
                <Switch
                  size={20}
                  value={!row?.skip_auto_create}
                  onChangeValue={async () => {
                    invoke({
                      uri: `/mastertaskassignments/${row?._id}`,
                      props: {
                        skip_auto_create: !row?.skip_auto_create,
                      },
                    });
                  }}
                />
              );
            },
            visible: !fromModule,
          },
        ]}
        moreActions={[
          {
            title: 'Delete',
            variant: 'error',
            confirm: {
              title: 'Delete',
              message: 'Are you sure you want to delete this module?',
            },
            onPress: ({row}) => {
              deleteInvoke({
                uri: `/mastertaskassignments/${row?._id}`,
                props: {},
              });
            },
          },
        ]}
        {...props}
      />
    </>
  );
};
