import React from 'react';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useTheme} from '@unthinkable/react-theme';
import {AddButton} from '../../../components/button/AddButton';

const FundTransferTable = props => {
  const {navigation, filterParams, params} = props;
  return (
    <Table
      {...props}
      api={'/fundtransfer'}
      addOnFilter={{...filterParams, transaction_status: 'New'}}
      params={{...filterParams, ...params}}
      eventSourceId="fundTransfer"
      limit={50}
      onRowPress={({row}) =>
        navigation.navigate('edit-fund-transfer', {
          row,
          readOnly: true,
        })
      }
      columns={[
        {
          field: 'date',
          header: 'Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Fund Transfer No',
          field: 'fund_transfer_no',
          type: 'text',
        },
        {
          header: 'Transfer Amount',
          field: 'transfer_amount',
          type: 'text',
        },
        {
          header: 'From Fund',
          field: 'from_fund.name',
          type: 'text',
        },
        {
          header: 'From Account',
          field: 'from_fund.account_number',
          type: 'text',
        },
        {
          header: 'To Fund',
          field: 'to_fund.name',
          type: 'text',
        },
        {
          header: 'To Account',
          field: 'to_fund.account_number',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`edit-fund-transfer`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {fund_transfer_id: row._id},
            });
          },
        },
      ]}
    />
  );
};

export const FundTranferTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter} = filterValues;
  const tabs = {
    tds: {
      label: 'Fund Transfer',
      view: (
        <FundTransferTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'fundTransfer',
      addOnFilter: {...filter, transaction_status: 'New'},
      api: '/fundtransfer',
      // actions: [
      //     <IconButton
      //         icon={Download}
      //         onPress={() => {
      //             download({
      //                 uri: '/exportfoodcouponreport',
      //                 props: {
      //                     addOnFilter: { ...filter },
      //                 },
      //             });
      //         }}
      //     />,
      // ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={{...filterValues, transaction_status: 'New'}}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'date',
            },
          ]}
        />,
        <AddButton title="Add Fund Transfer" view={'add-fund-transfer'} />,
      ]}
    />
  );
};
