import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {ProjectDatabaseNameInlineForm} from './ProjectDatabaseNameForm';

export const ProjectDatabaseNamesTable = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;
  const {project, database} = params;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: ['ProjectDatabaseName', 'ProjectDatabase'],
  });

  return (
    <Table
      renderHeader={() => <ProjectDatabaseNameInlineForm {...props} />}
      eventSourceId={['ProjectDatabaseName']}
      api={`/projectdatabasenames`}
      filter={{project_id: project?._id, database_id: database?._id}}
      fields={{
        name: 1,
        module_ids: {
          module: 1,
        },
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          header: 'Modules',
          field: 'module_ids',
          labelField: 'module',
          colorField: 'color',
          titleField: 'module',
          maxChips: 3,
          render: MultiColorTag,
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this database name?',
          },
          onPress: ({row}) => {
            deleteInvoke({uri: `/projectdatabasenames/${row._id}`});
          },
        },
      ]}
      onRowPress={({row}) => {
        navigation.navigate('edit-database-name', {
          ...params,
          database: row,
        });
      }}
    />
  );
};
