import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import RMFeedbackTab from '../../department/views/ResourceManagementFeedbackTab';
import TeamProjectFeedbackTab from '../../department/views/TeamProjectFeedbackTabs';

export const EmployeeFeedbackMenu = props => {
  const menus = [
    {
      label: 'Project Feedback',
      view: <TeamProjectFeedbackTab />,
    },
    {
      label: 'Resource Management Feedback',
      view: <RMFeedbackTab />,
    },
  ];
  const {route: {params} = {}} = props;

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
