import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilterKeyValues} from '../../../providers/FilterProvider';
import { useFilter } from '../../../controllers/useFilter';

const PlatformTableHeader = props => {
  const {route: {params} = {}} = props;
  const {onChangeFilter, searchValue} = useFilter({
    filterKey: 'platformFilter',
  });
  return (
    <TableHeader
      title="Platform"
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};

export const PlatformTable = props => {
  const params = props.route.params;
  const {searchValue} = useFilterKeyValues('platformFilter');
  return (
    <Table
      renderHeader={() => {
        return <PlatformTableHeader />;
      }}
      search={searchValue}
      searchFields={'name'}
      api={`/platform-list`}
      params={params}
      onRowPress={row => {
        props.navigation.navigate('edit-platform', row);
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
        },
      ]}
    />
  );
};
