import React from 'react';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab/TabView';
import {CompositeFieldsRender} from '@unthinkable/react-table';

const CustomerRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'customer_id.name',
        type: 'text',
      },
      {field: 'potential_customer.name', type: 'text'},
    ]}
  />
);

const QuoteRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'quote_status',
        type: 'text',
      },
      {field: 'quote_date', type: 'date'},
    ]}
  />
);

const NextTaskRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {field: 'next_task', type: 'text'},
      {
        field: 'next_task_date',
        type: 'date',
      },
    ]}
  />
);

const LastCommunicationRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'last_communication',
        type: 'text',
      },
      {
        field: 'last_communication_date',
        type: 'date',
      },
    ]}
  />
);

const InputDepartmentProposalTable = props => {
  const {
    route: {params},
  } = props;
  const {addOnFilter, api} = props;
  return (
    <Table
      api={api}
      {...props}
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },
        {
          header: 'Customer/Entity',
          render: CustomerRender,
        },
        {
          field: 'requirement',
          header: 'Requirement',
        },
        {
          field: 'practice.name',
          header: 'Practice',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },
        {
          field: 'owner',
          header: 'Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          type: 'date',
          field: 'due_date',
          header: 'Due Date',
        },
        {
          field: 'priority',
          header: 'Priority',
        },
        {
          header: 'Quote',
          render: QuoteRender,
        },
        {
          field: 'status',
          header: 'Status',
        },
        {
          type: 'date',
          field: 'outcome_date',
          header: 'Outcome Date',
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
        {
          header: 'Last Communication',
          render: LastCommunicationRender,
        },
      ]}
    />
  );
};

export const InputDepartmentProposalTabView = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentProposalTableApi = {
    api: '/inputDepartmentProposal/all',
    addOnFilter: {
      rfr_type: 'Customer',
      quote_status_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentProposalTableApi,
    view: (
      <InputDepartmentProposalTable
        {...props}
        {...InputDepartmentProposalTableApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const ProposalWonTabView = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentProposalTableApi = {
    api: '/inputDepartmentProposal/all',
    addOnFilter: {
      rfr_type: 'Customer',
      status: 'Won',
      outcome_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentProposalTableApi,
    view: (
      <InputDepartmentProposalTable
        {...props}
        {...InputDepartmentProposalTableApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerAcquisationProposalTab = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentProposalTableApi = {
    api: '/inputDepartmentProposal/all',
    addOnFilter: {
      rfr_type: 'PotentailCustomer',
      quote_status_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentProposalTableApi,
    view: (
      <InputDepartmentProposalTable
        {...props}
        {...InputDepartmentProposalTableApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
