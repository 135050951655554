import React from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {theme} from './theme';
import {useGoogleLogin} from './hooks/UseGoogleLogin';

const LoginButton = ({onSubmit, icon, text}) => {
  const signIn =
    icon === 'google'
      ? useGoogleLogin({
          onSuccess: tokenResponse => {
            onSubmit &&
              onSubmit({
                ...tokenResponse,
                loginUsing: 'google',
              });
          },
        })
      : onSubmit;

  const {LoginButton: GoogleLoginButtonStyles} = theme;
  const {
    containerStyle,
    googleIcon,
    iconContainerStyle,
    textContainerStyle,
    textStyle,
    mailIcon
  } = GoogleLoginButtonStyles;

  let Icon = icon === 'google' ? googleIcon : mailIcon;
  return (
    <TouchableOpacity onPress={signIn} style={containerStyle}>
      <View style={iconContainerStyle}>
        <Image source={Icon} />
      </View>
      <View style={textContainerStyle}>
        <Text style={textStyle}>{text}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default LoginButton;
