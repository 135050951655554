import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {SocialMediaPostTable} from './SocialMediaPostTable';
import {KeywordTable} from './KeywordTable';
import {SocialMediaWebpageTable} from './SocialMediaWebpageTable';
import {BackLinkTable} from './BackLinkTable';
import { BackLinkTabs } from './BackLinkTabs';
export const SocialMediaMasterNavigator = props => {
  const {route: {params} = {}} = props;
  const menus = [
    {
      label: 'Social Media Post',
      view: <SocialMediaPostTable />,
      api: '/socialMediaPosts',
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'SocialMediaPost',
    },
    {
      label: 'WebPage',
      view: <SocialMediaWebpageTable />,
      api: '/socialMediaWebpages',
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'SocialMediaWebpage',
    },
    {
      label: 'Keyword',
      view: <KeywordTable />,
      api: '/keywords',
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'Keyword',
    },
    {
      label: 'BackLink',
      view: <BackLinkTabs />,
      api: '/backLinks',
      params: {
        type: 'component',
        ...params,
      },
      eventSourceId: 'BackLink',
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
