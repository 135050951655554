import {ConfirmStyles} from '../confirm/theme';

export const MoreActionStyles = ({theme}) => {
  return {
    modal: {
      md: {
        container: {
          borderWidth: 1,
          borderColor: theme.colors.OUTLINE,
          width: 200,
          maxHeight: 300,
          backgroundColor: theme.colors.BACKGROUND,
        },
        actionContainer: {
          borderColor: theme.colors.OUTLINE,
        },
        actionContainerHoverColor: theme.colors.SURFACE2,
        actionText: {
          ...theme.fonts.BODY3,
          color: theme.colors.NEUTRAL_HIGH,
        },
        error: {
          actionText: {color: theme.colors.ERROR_HIGH},
        },
      },
    },
    confirm: ConfirmStyles({theme}),
  };
};
