import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {
  SwitchInput,
  SwitchRenderer as SwitchRendererComponent,
} from '@unthinkable/react-switch';
import {SwitchStyles} from './theme';

export const Switch = props => {
  let styles = useStyles(SwitchStyles);
  return <SwitchInput {...styles} {...props} />;
};

export const SwitchRenderer = props => {
  let styles = useStyles(SwitchStyles);
  return <SwitchRendererComponent styles={styles} {...props} />;
};
