import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter} from '../../../components/filter/Filters';

const UserAccessTable = props => {  
  const {
    route: {params},
    navigation,
  } = props;

  const {applyFilter, filterValues, onChangeFilter} = useFilter({});
  const {filter = {}, params: filterParams = {}} = filterValues || {};

  return (
    <Table
      api={`/userAccessRights`}
      params={{
        filter,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="User Resource"
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Users',
                    api: '/users',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    secondarySuggestionField: 'email',
                    valueField: 'name',
                    field: 'user_id',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'User',
          field: 'name',
          type: 'text',
          width: 250,
        },
        {
          header: 'App Resource',
          field: 'display_label',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export default UserAccessTable;
