import React from 'react';
import {useTheme} from '@unthinkable/react-theme';

import {Table} from '../../../../components/table/Table';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {SplitScreen} from '../../../../components/SplitScreen';
import {AssetColumns, assetFilters} from './AssetUtility';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID} from '../../../common/constants/SourceConstants';
import {TabView} from '../../../../components/tab';
import {BorderLessButton} from '../../../../components/button/AddButton';
import {DataServiceLibraryDetailForm} from './DataServiceLibraryForm';
import {AllDataServiceLibraryScreen} from './FeatureDataServiceLibraryScreen';

export const IntegratedProjectDataServiceLibraryTable = props => {
  const {
    navigation,
    route: {params},
    status,
  } = props;

  const {assetDetail, mergeReferences} = useAssetActions({
    navigation,
    params,
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({navigation, params});

  return (
    <Table
      {...props}
      columns={[assetColumns.dataService]}
      onRowPress={assetDetail}
    />
  );
};

export const IntegratedProjectDataServiceLibraryScreen = props => {
  const {
    route: {params},
    version,
  } = props;

  const {project, projectlibrary_id, integratedProject} = params;
  const filterProps = useFilter();
  const {searchValue, filterValues, onChangeFilter, applyFilter} = filterProps;

  const filters = assetFilters({project});

  const tableProps = {
    api: `/ProjectDataServiceLibraries/released`,
    addOnFilter: {
      library_id: projectlibrary_id,
      library_version_id: version
        ? version?._id
        : integratedProject
        ? integratedProject.library_version_id
        : null,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'name',
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        title={'Data Services'}
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[filters.owner]}
          />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={dataServiceLibraryExportParams(tableProps)}
          />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<DataServiceLibraryDetailForm {...props} />}>
      <IntegratedProjectDataServiceLibraryTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const dataServiceLibraryExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';

  return {
    addOnFilter,
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: 'DataServiceLibrary',
      columns: [
        {header: 'Name', key: 'name', width: 15},
        {header: 'Owner', key: 'owner_id.name', width: 15},
        {header: 'Description', key: 'desc', width: 20},
        {header: 'Signature', key: 'signature', width: 20},
        {header: 'Return Type', key: 'return_type', width: 20},
        {header: 'Remarks', key: remarksKey, width: 50, isHtml: true},
      ],
    },
  };
};

export const DataServiceLibraryTable = props => {
  const {
    navigation,
    route: {params},
    status,
  } = props;

  const {
    assetDetail,
    mergeReferences,
    changeModule,
    deleteAsset,
    deleteMultipleAssets,
    versions,
    updateModuleMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({navigation, params});

  return (
    <Table
      {...props}
      eventSourceId={['DataServiceLibrary', 'DataImport']}
      selection={{
        actions: [updateModuleMultipleAssets, deleteMultipleAssets],
      }}
      columns={[assetColumns.dataService]}
      onRowPress={assetDetail}
      moreActions={[
        versions,
        changeModule,
        mergeReferences({
          targetApi: '/ProjectDataServiceLibraries',
          deleteSource: true,
        }),
        deleteAsset,
      ]}
    />
  );
};

export const DataServiceLibraryScreen = props => {
  const {
    route: {params},
  } = props;

  const {project, projectlibrary_id, integratedProject} = params;
  const filterProps = useFilter();
  const {icons} = useTheme();
  const {searchValue, filterValues, onChangeFilter, applyFilter} = filterProps;

  const filters = assetFilters({project});

  const tableProps = {
    api: `/ProjectDataServiceLibraries/released`,
    addOnFilter: {
      library_id: projectlibrary_id,
      library_version_id: integratedProject
        ? integratedProject.library_version_id
        : null,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'name',
  };

  const renderHeader = ({selectedIds, navigation}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[filters.owner]}
          />,
          <BorderLessButton
            onPress={() => {
              navigation.navigate('project-import-data', {
                ...params,
                source: PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
                // sampleId: PROJECT_COMPONENT_IMPORT_SAMPLE_ID,
              });
            }}
            text={'Import'}
            icon={icons.DownloadBlue}
          />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={dataServiceLibraryExportParams(tableProps)}
          />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<DataServiceLibraryDetailForm {...props} />}>
      <DataServiceLibraryTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const DbServicesTab = props => {
  const {route: {params} = {}} = props;
  const {project, projectlibrary_id, integratedProject} = params;

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: <DataServiceLibraryScreen {...props} />,
          api: '/ProjectDataServiceLibraries',
          filter: {
            status: 'released',
            library_id: projectlibrary_id,
            library_version_id: integratedProject
              ? integratedProject.library_version_id
              : null,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllDataServiceLibraryScreen
              api={`/projects/${project?._id}/asset/${PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID}/all-data`}
              {...props}
            />
          ),
          api: `/projects/${project?._id}/asset/${PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID}/in-development/count`,
          params: {
            library_id: projectlibrary_id, // check this whether needed
            library_version_id: integratedProject
              ? integratedProject.library_version_id
              : null,
          },
        },
      }}
    />
  );
};
