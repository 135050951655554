import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';

const VoucherForm = props => {
  const {
    route: {params},
    api,
    addOnFilter,
    mode,
    readOnly,
  } = props;
  const {onSubmit} = useFormSubmit({
    uri: '/saveVouchers',
    eventSourceId: ['voucher'],
  });
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();
  const {organization, location, product} = employeeData;
  const defaultValues = {
    organization,
    location,
    product,
    is_direct_voucher: true,
    voucher_date: getZeroTimeDate(new Date()),
    voucher_type_id: {_id: '53a953d779df44bac9918e92', name: 'General Voucher'},
  };
  return (
    <Form
      api={api}
      onSubmit={onSubmit}
      addOnFilter={addOnFilter}
      eventSourceId="voucher"
      submitAction="Save"
      defaultValues={defaultValues}
      computations={{
        'set cr dr amount': {
          compute: value => {
            const cr_amount = value.voucherlineitem
              .reduce((accum, one) => accum + (one.cr_amount || 0), 0)
              .toFixed(1);

            const dr_amount = value.voucherlineitem
              .reduce((accum, one) => accum + (one.dr_amount || 0), 0)
              .toFixed(1);

            console.log(cr_amount, dr_amount);

            return {
              cr_amount: Number(cr_amount).toFixed(2) * 1,
              dr_amount: Number(dr_amount).toFixed(2) * 1,
            };
          },
          multi: true,
          dependencies: ['voucherlineitem'],
        },
        nestedComputations: {
          voucherlineitem: {
            amount: {
              compute: values => {
                if (values.cr_amount) {
                  return 1 * Number(values.cr_amount).toFixed(1);
                } else if (values.dr_amount) {
                  return -1 * Number(values.dr_amount).toFixed(1);
                } else {
                  return 0;
                }
              },
              dependencies: ['cr_amount', 'dr_amount'],
            },
            entitymodel: {
              compute: values => {
                if (values?.entitymodel) {
                  return values.entitymodel;
                } else {
                  return void 0;
                }
              },
              dependencies: ['entity'],
            },
          },
        },
      }}
      fields={{
        voucher_type_id: {name: 1},
        voucher_no: 1,
        narration: 1,
        voucher_date: 1,
        cr_amount: 1,
        dr_amount: 1,
        email_attachment: 1,
        department: {name: 1},
        organization: {
          name: 1,
        },
        category: {name: 1},
        product: {
          name: 1,
        },
        location: {
          name: 1,
        },
        voucherlineitem: {
          account: {
            name: 1,
          },
          department: {name: 1},
          entity: {
            name: 1,
          },
          entitymodel: 1,
          department: {
            name: 1,
          },
          amount: 1,
          cr_amount: 1,
          dr_amount: 1,
          is_round_off: 1,
        },
      }}
      layoutFields={[
        // {
        //   placeholder: 'Type',
        //   field: 'voucher_type_id',
        //   type: 'autoComplete',
        //   api: `/vouchertypenames`,
        //   label: 'Voucher Type',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        //   size: 6,
        // },
        {
          type: 'text',
          placeholder: 'Voucher Number',
          field: 'voucher_no',
          label: 'Voucher Number',
          size: 6,
          visible: () => readOnly === true,
        },
        {
          type: 'date',
          placeholder: 'Date',
          field: 'voucher_date',
          required: true,
          label: 'Date',
          size: 4,
        },
        {
          field: 'narration',
          placeholder: 'Narration',
          type: 'textArea',
          label: 'Narration',
          required: true,
          size: 8,
        },
        {
          type: 'number',
          placeholder: 'Cr',
          field: 'cr_amount',
          label: 'Cr',
          size: 6,
          toFixed: 2,
          readOnly: true,
        },
        {
          type: 'number',
          placeholder: 'Dr',
          field: 'dr_amount',
          label: 'Dr',
          size: 6,
          toFixed: 2,
          readOnly: true,
        },
        {
          type: 'checkbox',
          field: 'is_direct_voucher',
          label: 'Is Direct Voucher',
          size: 6,
        },
        {
          type: 'file',
          field: 'email_attachment',
          label: 'Email Attachment',
          type: 'file',
          options: {bucketName: 'manaze'},
          size: 12,
        },
        {
          label: 'Accounting Details',
          fields: [
            {
              label: 'Department',
              field: 'department',
              type: 'autoComplete',
              api: `/departments`,
              suggestionField: 'name',
              valueField: 'name',
              // required: true,
              size: 4,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
            {
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
            {
              label: 'Tax Location',
              field: 'location',
              type: 'autoComplete',
              api: `/offices`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },
            {
              label: 'Category',
              field: 'category',
              type: 'autoComplete',
              api: '/categories',
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
          ],
        },
        {
          field: 'voucherlineitem',
          label: 'Voucher Line Item',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm={true}
              selection={{
                deleteAction: true,
              }}
              columns={[
                {
                  type: 'text',
                  field: 'account.name',
                  header: 'Account',
                },
                {
                  type: 'text',
                  field: 'department.name',
                  header: 'Department',
                },
                {
                  type: 'text',
                  field: 'entity.name',
                  header: 'Entity',
                },
                {
                  type: 'number',
                  field: 'cr_amount',
                  header: 'Cr',
                  toFixed: 2,
                  formatOptions: {
                    maximumFractionDigits: 2,
                  },
                },
                {
                  type: 'number',
                  field: 'dr_amount',
                  header: 'Dr',
                  toFixed: 2,
                  formatOptions: {
                    maximumFractionDigits: 2,
                  },
                },
              ]}
              fields={[
                {
                  label: 'Account',
                  field: 'account',
                  type: 'autoComplete',
                  api: `/accounts`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                },
                {
                  label: 'Department',
                  field: 'department',
                  type: 'autoComplete',
                  api: `/departments`,
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  label: 'Entity',
                  field: 'entity',
                  type: 'autoComplete',
                  api: `/getentities`,
                  suggestionField: 'name',
                  valueField: 'name',
                  onChangeValue: (value, e, {setFieldValue}) => {
                    let entitymodel;
                    if (value?.entitymodel) {
                      entitymodel = value.entitymodel;
                    }
                    setFieldValue('entitymodel', entitymodel);
                  },
                },
                {
                  type: 'number',
                  field: 'cr_amount',
                  label: 'Cr',
                  toFixed: 2,
                  width: 120,
                  onChangeValue: (value, e, {setFieldValue, values}) => {
                    setFieldValue('amount', value);
                  },
                },
                {
                  type: 'number',
                  field: 'dr_amount',
                  label: 'Dr',
                  toFixed: 2,
                  width: 120,
                  onChangeValue: (value, e, {setFieldValue, values}) => {
                    setFieldValue('amount', value * -1);
                  },
                },
              ]}
            />
          ),
        },
      ]}
      {...props}
    />
  );
};

export const CreateVoucherForm = props => {
  return <VoucherForm header="Create Voucher" {...props} />;
};

export const EditVoucherForm = props => {
  const {
    route: {params},
  } = props;
  return (
    <VoucherForm
      mode="edit"
      readOnly={params.readOnly}
      header={{
        title: 'Update Voucher',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {params?.row?.voucher_no}
            </Text>
          );
        },
      }}
      api={`/vouchers/${params?.row?._id}`}
      {...props}
    />
  );
};

export const OpenVoucherForm = props => {
  const {
    route: {params},
    api,
  } = props;
  const {extraFilter = {}} = params;
  const addOnFilter = {...extraFilter};
  return (
    <VoucherForm
      mode="edit"
      readOnly={true}
      header="Update Voucher"
      api={`/vouchers`}
      addOnFilter={{...addOnFilter}}
      {...props}
    />
  );
};

export const OpenEditVoucherForm = props => {
  const {
    route: {params},
    api,
  } = props;
  const {extraFilter = {}} = params;
  const addOnFilter = {...extraFilter};
  return (
    <VoucherForm
      mode="edit"
      header="Update Voucher"
      api={`/vouchers`}
      addOnFilter={{...addOnFilter}}
      {...props}
    />
  );
};
