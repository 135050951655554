import React from 'react';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {RichTextRenderer} from '@unthinkable/react-rich-text-input';
import {MailContentRenderStyles} from './theme';

export const MailContentRender = ({subject, message}) => {
  if (!subject && !message) {
    return null;
  }
  const styles = useStyles(MailContentRenderStyles);
  return (
    <View style={styles.container}>
      {subject ? (
        <Row style={styles.subjectContainer}>
          <Text style={styles.subjectLabel}>{'Subject'}</Text>
          <Text style={styles.subjectText}>{subject || ""}</Text>
        </Row>
      ) : (
        void 0
      )}
      {message ? (
        <View style={styles.messageContainer}>
          <Text style={styles.messageLabel}>{'Message'}</Text>
          <RichTextRenderer value={message} />
        </View>
      ) : (
        void 0
      )}
    </View>
  );
};
