import React, {useEffect, useState} from 'react';
import {Badge} from '../../../components/badge/Badge';
import {useAppStateContext} from '../../../providers/AppState';
import {useStyles} from '@unthinkable/react-theme';
import {NotificationIconStyle} from './theme';
import {useNavigation} from '@react-navigation/native';
import {useEventHandler} from '@unthinkable/react-event-handler';
import useRealTimeSync from '../../../components/customHooks/useRealTimeSync';
import {IconButton} from '../../../components/button/IconButton';

export const NotificationIcon = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  let {user, fetch, globalData} = useAppStateContext();
  const {notificationCount: notificationCountData} = globalData || {};
  const {listen, unlisten} = useEventHandler();
  const navigation = useNavigation();
  const {notificationIcon, badgeBackgroundColor, badgeTextColor} = useStyles(
    NotificationIconStyle,
  );

  // Use the useRealTimeSync hook to listen for socket events - Rajnish Trikaldarshi 17-02-2024
  useRealTimeSync({
    event: 'notificationAdded',
    callBack: data => {
      if (data) {
        setNotificationCount(prevState => (prevState ?? 0) + 1);
      }
    },
  });

  const fetchNotificationCount = async () => {
    const result = await fetch({
      uri: '/notificationcount',
      props: {
        filter: {user: user?._id, read_on: {$exists: false}},
        count: true,
      },
    });
    setNotificationCount(result?.data?.count);
  };

  useEffect(() => {
    setNotificationCount(notificationCountData?.count);
  }, [notificationCountData]);

  useEffect(() => {
    listen('notificationCountUpdated', fetchNotificationCount);
    listen('notificationMarkedRead', fetchNotificationCount);

    return () => {
      unlisten('notificationCountUpdated', fetchNotificationCount);
      unlisten('notificationMarkedRead', fetchNotificationCount);
    };
  }, [listen]);

  return (
    <Badge
      top={0}
      right={-4}
      value={notificationCount || null}
      color={badgeBackgroundColor}
      textColor={badgeTextColor}>
      <IconButton
        icon={notificationIcon}
        onPress={async () => {
          navigation.navigate('notification-route', {notificationCount});
        }}
      />
    </Badge>
  );
};
