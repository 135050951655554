import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {ComparisonIndicator} from '../card/ComparisionCard';
import {
  AggregateRender,
  CompositeFieldsRender,
  TextRender,
} from '@unthinkable/react-table';
import {resolveExp} from '@unthinkable/react-utils';
import {useComponentTheme, useStyles, useTheme} from '@unthinkable/react-theme';
import {UserAvatar} from '../avatar/UserAvatar';
import {WithModal} from '@unthinkable/react-popper';
import {ImageViewer} from '../attachment/ImageViewer';
import {AddFilledAction} from '../action/AddAction';
import {AttachmentCountRenderStyle} from './theme';

export const MarkCompletedRender = () => {
  const {GreyTickIcon} = useTheme('icons');
  return <Image style={{height: 24, width: 24}} source={GreyTickIcon} />;
};

export const CompletedRender = () => {
  const {GreenTickIcon} = useTheme('icons');
  return <Image style={{height: 24, width: 24}} source={GreenTickIcon} />;
};

export const UncompletedRender = () => {
  const {FilledTickIcon} = useTheme('icons');
  return <Image style={{height: 24, width: 24}} source={FilledTickIcon} />;
};

export const AssignUserAvatar = ({value, lockable, onLock}) => {
  const {LockGrey} = useTheme('icons');
  if (value) {
    return <UserAvatar value={value} />;
  } else if (onLock && lockable !== false) {
    return (
      <TouchableOpacity onPress={onLock}>
        <Image source={LockGrey} style={{height: 20, width: 20}} />
      </TouchableOpacity>
    );
  } else {
    return null;
  }
};

export const AttachmentCountRender = ({
  value,
  showImage,
  count,
  suffix,
  ...props
}) => {
  const {rowText} = useStyles(AttachmentCountRenderStyle);
  let {designImage, iconStyle} = useComponentTheme('ImageTextRenderStyles');

  if (value && !Array.isArray(value)) {
    value = [value];
  }
  if (!value || !value.length) {
    return null;
  }
  return (
    <WithModal
      position={'fullScreen'}
      renderModal={({hide}) => {
        return (
          <View style={{flex: 1, overflow: 'hidden', padding: 16}}>
            <ImageViewer onClose={hide} attachments={value} {...props} />
          </View>
        );
      }}>
      {showImage ? (
        <Row style={{alignItems: 'center', gap: 6}}>
          <Image source={designImage} style={iconStyle} />
          {count ? <Text style={rowText}>{value?.length}</Text> : void 0}
        </Row>
      ) : (
        <Text style={rowText}>{`${value.length}${
          suffix ? ' ' + suffix : ''
        }`}</Text>
      )}
    </WithModal>
  );
};

export const ComparisonRender = props => {
  const {
    row,
    value,
    compareField,
    onComparePress,
    compareWidth = 50,
    toFixed,
  } = props;
  const compareValue = resolveExp(row, compareField);

  let compareComponent = (
    <View style={{width: compareWidth}}>
      <ComparisonIndicator
        value={value}
        compareValue={compareValue}
        toFixed={toFixed}
      />
    </View>
  );
  if (onComparePress) {
    compareComponent = (
      <TouchableOpacity onPress={() => onComparePress({row})}>
        {compareComponent}
      </TouchableOpacity>
    );
  }

  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <TextRender {...props} />
      </View>
      {compareComponent}
    </Row>
  );
};

export const AggregateComparisonRender = props => {
  const {value, aggregates, compareField, compareWidth = 50, toFixed} = props;
  let compareAggregateValue =
    aggregates && compareField ? resolveExp(aggregates, compareField) : 0;

  let compareComponent = (
    <View style={{width: compareWidth}}>
      <ComparisonIndicator
        value={value}
        compareValue={compareAggregateValue}
        toFixed={toFixed}
      />
    </View>
  );
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <AggregateRender {...props} />
      </View>
      {compareComponent}
    </Row>
  );
};

export const FieldAddFallbackRender = ({
  fieldDef,
  addAction,
  renderModal,
  showAddAction,
  ...props
}) => {
  const {field} = fieldDef;
  if (showAddAction === undefined && field) {
    showAddAction = !resolveExp(props.row, field);
  }
  const fields = [];
  if (showAddAction) {
    fields.push({
      render: () => {
        return <AddFilledAction {...addAction} />;
      },
    });
  } else {
    fields.push(fieldDef);
  }
  let render = <CompositeFieldsRender {...props} fields={fields} />;
  if (renderModal) {
    render = (
      <WithModal position="screenCenter" renderModal={renderModal}>
        {render}
      </WithModal>
    );
  }
  return render;
};

export const ExpandRowIconRender = ({rowExpanded}) => {
  const icons = useTheme('icons');
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      <Image
        style={{height: 16, width: 16}}
        source={rowExpanded ? icons?.ExpandSmall : icons?.ChevronRight}
      />
    </View>
  );
};
