import React from 'react';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
const {Form} = require('../../../components/form/Form');

export const AddSkillTopic = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/skilltopics',
    eventSourceId: 'SkillTopics',
  });
  const {
    route: {params},
  } = props;
  const {skill_metrics} = params;
  return (
    <Form
      onSubmit={onSubmit}
      header={{title: 'Add Skill Topic'}}
      submitAction="Save"
      defaultValues={{
        skill_metrics,
      }}
      layoutFields={[
        {
          label: 'Topic',
          field: 'name',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const EditSkillTopic = props => {
  const {
    route: {params},
  } = props;
  const {skill_topic} = params;
  return (
    <AddSkillTopic
      header="Edit Skill Topic"
      api={'/skilltopics/' + skill_topic?._id}
      fields={{
        name: 1,
        description: 1,
        skill_metrics: {
          name: 1,
        },
        proficiency: 1,
        skill_type: 1,
      }}
      mode="edit"
      {...props}
    />
  );
};
