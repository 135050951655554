import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const MonthlyAttendance = props => {
  const {route: {params} = {}} = props;
  const {row, uri, api} = params;
  let {onSubmit} = useFormSubmit({
    uri: uri,
    eventSourceId: 'monthlyAttendance',
  });
  return (
    <Form
      api={`${api}/${row?._id}`}
      mode="edit"
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          field: 'employee.name',
          type: 'text',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Employee Code',
          field: 'employee.employee_code',
          type: 'text',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Year',
          field: 'year_id.name',
          type: 'text',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Month',
          field: 'month_id.name',
          type: 'text',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Leave Register',
          visible: ({values}) => {
            if (uri === '/fixedsalaries' || uri === '/performancesalaries') {
              return false;
            } else return true;
          },
          fields: [
            {
              field: 'leave_details',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  // readOnly={true}
                  columns={[
                    {
                      header: 'Type',
                      field: 'leave_type_id.name',
                      type: 'text',
                      minWidth: 150,
                    },
                    {
                      header: 'Opening',
                      type: 'number',
                      field: 'opening_leave_balance',
                      width: 150,
                    },
                    {
                      header: 'Accured',
                      field: 'accrued_leaves',
                      type: 'number',
                      width: 150,
                    },
                    {
                      header: 'Consumed',
                      field: 'consumed_leaves',
                      type: 'number',
                      width: 150,
                    },
                    {
                      header: 'Leave',
                      field: 'leave_balance',
                      type: 'number',
                      width: 150,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Type',
                      field: 'leave_type_id',
                      type: 'autoComplete',
                      api: '/leavetypes',
                      suggestionField: 'name',
                      valueField: 'name',
                      size: 6,
                    },
                    {
                      label: 'Opening Balance',
                      type: 'number',
                      field: 'opening_leave_balance',
                      size: 6,
                      toFixed: 2,
                    },
                    {
                      label: 'Accured Balance',
                      type: 'number',
                      field: 'accrued_leaves',
                      size: 6,
                      toFixed: 2,
                    },
                    {
                      label: 'Consumed Balance',
                      type: 'number',
                      field: 'consumed_leaves',
                      size: 6,
                      toFixed: 2,
                    },
                    {
                      label: 'Leave Balance',
                      type: 'number',
                      field: 'leave_balance',
                      size: 6,
                      toFixed: 2,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Component Details',
          fields: [
            {
              field: 'component_details',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  readOnly={true}
                  columns={[
                    {
                      header: 'Salary Component',
                      field: 'salary_component_id.name',
                      type: 'text',
                      minWidth: 150,
                    },
                    {
                      header: 'Gross',
                      field: 'gross_amount',
                      type: 'number',
                      width: 200,
                    },
                    {
                      header: 'Payable',
                      field: 'payable_amount',
                      type: 'number',
                      width: 200,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Salary Component',
                      field: 'salary_component_id',
                      type: 'autoComplete',
                      api: '/salarycomponents',
                      suggestionField: 'name',
                      valueField: 'name',
                      size: 6,
                    },
                    {
                      label: 'Gross',
                      type: 'number',
                      field: 'gross_amount',
                      size: 6,
                      toFixed: 2,
                    },
                    {
                      label: 'Payable',
                      type: 'number',
                      field: 'payable_amount',
                      size: 6,
                      toFixed: 2,
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Salary Details',
          fields: [
            {
              field: 'salary_details',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  columns={[
                    {
                      header: 'Invoice Date',
                      field: 'invoice_date',
                      type: 'date',
                      minWidth: 150,
                    },
                    {
                      header: 'Gross Salary',
                      field: 'gross_salary',
                      type: 'number',
                      width: 200,
                    },
                    {
                      header: 'Payable Salary',
                      field: 'payable_salary',
                      type: 'number',
                      width: 200,
                    },
                    {
                      header: 'Salary Type',
                      field: 'salary_type',
                      type: 'text',
                      width: 200,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Invoice Date',
                      field: 'invoice_date',
                      type: 'date',
                      size: 6,
                    },
                    {
                      label: 'Gross Salary',
                      field: 'gross_salary',
                      type: 'number',
                      size: 6,
                      toFixed: 2,
                    },
                    {
                      label: 'Payable Salary',
                      field: 'payable_salary',
                      type: 'number',
                      size: 6,
                      toFixed: 2,
                    },
                    {
                      label: 'Salary Type',
                      field: 'salary_type',
                      type: 'text',
                      size: 6,
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditMonthlyAttendance = props => {
  return (
    <MonthlyAttendance
      mode="edit"
      header={'Update Monthly Attendance'}
      {...props}
    />
  );
};
