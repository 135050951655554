import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {PrimaryCardItems} from '../../../components/card/PrimaryColumnCard';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {PrimaryColumnCardStyles} from '../../../components/card/theme';
import {toFixedDecimalValue} from '../../../utils/util';
import {Efficiency} from '../../../components/efficiency/Efficiency';

export const EfficiencyColumnRender = ({row}) => {
  const styles = useStyles(PrimaryColumnCardStyles);
  const colors = useTheme('colors');
  let {
    efficiencyPercentage = 0,
    reviewHrs = 0,
    plannedHrs = 0,
    allocatedHrs = 0,
  } = row || {};

  let efficiencyHrs = plannedHrs + reviewHrs;

  return (
    <View style={styles.container}>
      <RowWithSeparator style={{gap: 8}} separator={<LineSeparator />}>
        <Efficiency value={efficiencyPercentage} />
        <Text
          style={{
            ...styles.title,
            color: colors.NEUTRAL_MEDIUM,
          }}>{`${toFixedDecimalValue(efficiencyHrs)}/${toFixedDecimalValue(
          allocatedHrs,
        )} h`}</Text>
      </RowWithSeparator>
      <PrimaryCardItems
        items={[
          {
            value: toFixedDecimalValue(plannedHrs) + 'h tasks',
          },
          {
            value: toFixedDecimalValue(reviewHrs) + 'h reviews',
          },
        ]}
      />
    </View>
  );
};
