import React, {forwardRef} from 'react';
import {ChipRender} from './Chip';
import {View, Wrap} from '@unthinkable/react-core-components';
import {Input, InputContainer, InputWrapper} from '@unthinkable/react-input';
import {useMultiTextInput} from '../hooks';

const MultiTextInput = forwardRef(({chipGap = 8, ...props}, ref) => {
  props = useMultiTextInput({...props, ref});
  let {
    styles,
    inputProps,
    inputRef,
    value,
    onChangeSearch,
    searchValue,
    disabled,
    readOnly,
    onRemove,
  } = props;

  const renderChip = chipProps => {
    return <ChipRender {...props} {...chipProps} />;
  };

  let inputComponent = (
    <Input
      {...inputProps}
      isFloatingLabel={false}
      value={searchValue}
      ref={inputRef}
      styles={styles}
      onChangeText={onChangeSearch}
    />
  );

  return (
    <InputWrapper {...props}>
      <InputContainer {...props}>
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
            marginTop: 12,
          }}>
          <Wrap gap={chipGap}>
            {value?.map((doc, index) => {
              return renderChip({
                value: doc,
                crossable: !(disabled || readOnly),
                onCross: () => {
                  onRemove({index});
                },
              });
            })}
            <View style={{flex: 1, justifyContent: 'center'}}>
              {inputComponent}
            </View>
          </Wrap>
        </View>
      </InputContainer>
    </InputWrapper>
  );
});

export default MultiTextInput;
