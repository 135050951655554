import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const SetActualScheduleOnForm = props => {
  const {
    route: {params},
    header = 'Set Actual ScheduleOn',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignactions/set-actual-schedule-on-cron',
  });
  const {campaign_id} = params;

  return (
    <Form
      onSubmit={onSubmit}
      header={header}
      defaultValues={{
        campaign_id,
      }}
      layoutFields={[
        {
          field: 'spanDays',
          type: 'number',
          label: 'Send After (in Days)',
        },
        {
          field: 'limit_passed',
          type: 'number',
          label: 'Limit',
        },
        {
          field: 'actionType',
          type: 'autoComplete',
          options: [
            'connection_request',
            'direct_message',
            'follow_request',
            'email',
            'degree_of_connection',
            'like_post',
            'inmail_message',
          ],
          label: 'Action',
        },
        {
          field: 'startup_email',
          type: 'number',
          label: 'Startup Email Limit',
          visible: ({values}) => {
            return values.actionType === 'email';
          },
        },
      ]}
      {...props}
    />
  );
};
