import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {getCurrentDay} from '../../employee/views/EmployeeTeamTabs';
import {
  PerformanceAllBilledReportTable,
  PerformanceBilledReportTable,
  PerformanceBilledUnassignedTable,
} from './PerformanceReportTable';
import {useTheme} from '@unthinkable/react-theme';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupBy} from '../../../components/table/GroupBy';
import {AllActiveProjects} from './AllProjectsAndClientTable';

const groupFilterArray = [
  {
    type: 'autoComplete',
    label: 'Customer',
    api: '/customers',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'customer',
  },
  // {
  //   type: 'autoComplete',
  //   label: 'Type',
  //   placeholder: 'Select Type',
  //   field: 'new_project_type',
  //   options: [
  //     {
  //       label: 'Staff Augmentation',
  //       value: 'staffaugmentation',
  //     },
  //     {
  //       label: 'Managed Team',
  //       value: 'managedteam',
  //     },
  //     {
  //       label: 'SDLC',
  //       value: 'sdlc',
  //     },
  //   ],
  //   suggestionField: 'label',
  //   keyField: 'value',
  // },
  {
    type: 'autoComplete',
    label: 'Label',
    api: '/projectsubtypes',
    placeholder: 'Select',
    suggestionField: 'project_sub_type',
    valueField: 'project_sub_type',
    field: 'new_project_sub_type',
  },
  // {
  //   type: 'autoComplete',
  //   options: [
  //     'Client Managed',
  //     'Daffodil Managed',
  //     'Hybrid Managed',
  //     'Daffodil Managed - Support',
  //   ],
  //   field: 'project_sub_category',
  //   label: 'Managed By',
  // },
  {
    label: 'Tool',
    field: 'tool',
    placeholder: 'Select Tool',
    type: 'autoComplete',
    options: ['Locomo', 'Other'],
  },
  {
    type: 'date',
    field: 'current_date',
    asParam: true,
    label: 'Date',
  },
  {
    label: 'Feedback Mode',
    field: 'feedback_mode',
    placeholder: 'Select Feedback Mode',
    type: 'autoComplete',
    options: ['Automatic', 'Manual'],
  },
  {
    label: 'Last automatic feedback request',
    field: 'automatic_feedback_request_success_date',
    type: 'date',
  },
  {
    label: 'Last automatic feedback failure',
    field: 'automatic_feedback_request_failure_date',
    type: 'date',
  },
  {
    label: 'Last automatic feedback failure',
    field: 'automatic_feedback_request_failure_date',
    type: 'date',
  },
  {
    label: 'Next Audit Date',
    field: 'next_audit_date',
    type: 'date',
  },
  {
    label: 'Audit Date',
    field: 'audit_date',
    type: 'date',
  },
  {
    type: 'autoComplete',
    label: 'Auditer',
    api: '/employeeSuggestions',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    secondarySuggestionField: 'official_email_id',
    field: 'auditer',
    searchFields: ['name', 'official_email_id', 'employee_code'],
  },
];

const groupFilterClosedArray = [
  {
    type: 'autoComplete',
    label: 'Customer',
    api: '/customers',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'customer',
  },
  {
    type: 'autoComplete',
    label: 'Type',
    placeholder: 'Select Type',
    field: 'new_project_type',
    options: [
      {
        label: 'Staff Augmentation',
        value: 'staffaugmentation',
      },
      {
        label: 'Managed Team',
        value: 'managedteam',
      },
      {
        label: 'SDLC',
        value: 'sdlc',
      },
    ],
    suggestionField: 'label',
    keyField: 'value',
  },
  {
    type: 'date',
    field: 'completed_on',
    asParam: true,
    label: 'Closed Date',
  },
];

export const PerformanceTab = props => {
  let {route: {params} = {}, project_status, tab, is_project_assign} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const filters = {
    staff_augmentation: {
      ...filterValues.filter,
      project_status,
      is_project_assign,
      new_project_type: 'staffaugmentation',
    },
    managed_team: {
      ...filterValues.filter,
      project_status,
      is_project_assign,
      new_project_type: 'managedteam',
    },
    sdlc: {
      ...filterValues.filter,
      project_status,
      is_project_assign,
      new_project_type: 'sdlc',
    },
    sdlcplus: {
      ...filterValues.filter,
      project_status,
      projecttype: 'f',
      is_project_assign,
      new_project_type: 'sdlcplus',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    staff_augmentation: {
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.staff_augmentation}
          tabName="staff"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product,
            new_project_type: 'staffaugmentation',
          }}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.staff_augmentation,
      params: filterParams,
      limit: 500,
    },
    managed_team: {
      label: 'Managed Team',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.managed_team}
          tabName="managed"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product,
            new_project_type: 'managedteam',
          }}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.managed_team,
      params: filterParams,
      limit: 500,
    },
    sdlc: {
      label: 'SDLC',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlc}
          tabName="sdlc"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product, new_project_type: 'sdlc'}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlc,
      params: filterParams,
      limit: 500,
    },
    sdlcplus: {
      label: 'SDLC+',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlcplus}
          tabName="sdlcplus"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product, new_project_type: 'sdlcplus'}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlcplus,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const PerformanceTandMTab = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {download} = useAppStateContext();
  const filters = {
    staff_augmentation: {
      ...filterValues.filter,
      project_status,
      new_project_type: 'staffaugmentation',
    },
    managed_team: {
      ...filterValues.filter,
      project_status,
      new_project_type: 'managedteam',
    },
    sdlc: {
      ...filterValues.filter,
      project_status,
      new_project_type: 'sdlc',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const icons = useTheme('icons');
  const tabs = {
    staff_augmentation: {
      apiKey: 'active',
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.staff_augmentation}
          tabName="staff"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product,
            new_project_type: 'staffaugmentation',
          }}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.staff_augmentation,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.staff_augmentation,
      params: filterParams,
      limit: 500,
    },
    managed_team: {
      apiKey: 'active',
      label: 'Managed Team',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.managed_team}
          tabName="managed"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product,
            new_project_type: 'managedteam',
          }}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.managed_team,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.managed_team,
      params: filterParams,
      limit: 500,
    },
    sdlc: {
      apiKey: 'active',
      label: 'SDLC',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlc}
          tabName="sdlc"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product, new_project_type: 'sdlc'}}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.sdlc,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlc,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const PerformanceClosedTandMTab = props => {
  let {
    route: {params} = {},
    project_status,
    tab,
    extraAddOnfilter = {},
    billed_project_menu,
  } = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    // params: {
    //   period: getCurrentDay(),
    // },
    filter: {
      ...extraAddOnfilter,
    },
  });
  const {download} = useAppStateContext();
  const filters = {
    closed_project: billed_project_menu
      ? {
          $or: [
            {
              new_project_type: {
                $in: ['staffaugmentation', 'managedteam', 'sdlc'],
              },
            },
            {
              new_project_type: 'sdlcplus',
              projecttype: 'f',
            },
            {
              projecttype: 'i',
            },
          ],
          ...filterValues.filter,
          project_status,
        }
      : {
          ...filterValues.filter,
          project_status,
          new_project_type: {$in: ['staffaugmentation', 'managedteam', 'sdlc']},
        },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const icons = useTheme('icons');
  const tabs = {
    closed_project: {
      apiKey: 'active',
      label: 'Closed',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.closed_project}
          tabName="staff"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.closed_project,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.closed_project,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterClosedArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const PerformanceFixedTab = props => {
  let {
    route: {params} = {},
    project_status: props_project_status,
    tab: props_tab,
    addOnParamFilter: propsAddOnParams,
  } = props;

  let project_status = props_project_status || params?.project_status;
  let tab = props_tab || params?.tab;
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;

  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });

  const filters = {
    sdlcplus: {
      ...filterValues.filter,
      project_status,
      projecttype: 'f',
      new_project_type: 'sdlcplus',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    sdlcplus: {
      apiKey: 'active',
      label: 'SDLC+',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlcplus}
          tabName="sdlcplus"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product, new_project_type: 'sdlcplus'}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlcplus,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const PerformanceFixedRetainerTab = props => {
  let {
    route: {params} = {},
    project_status: props_project_status,
    tab: props_tab,
    addOnParamFilter: propsAddOnParams,
  } = props;

  let project_status = props_project_status || params?.project_status;
  let tab = props_tab || params?.tab;
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;

  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });

  const filters = {
    sdlcplus: {
      ...filterValues.filter,
      project_status,
      projecttype: 'f',
      new_project_type: 'sdlcplus',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    sdlcplus: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlcplus}
          tabName="sdlcplus"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product, new_project_type: 'sdlcplus'}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlcplus,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const ProjectUnassignedTab = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const filters = {
    staff_augmentation: {
      ...filterValues.filter,
      project_status,
      is_project_assign: {$in: [false, null]},
      new_project_type: 'staffaugmentation',
    },
    managed_team: {
      ...filterValues.filter,
      project_status,
      is_project_assign: {$in: [false, null]},
      new_project_type: 'managedteam',
    },
    sdlc: {
      ...filterValues.filter,
      project_status,
      is_project_assign: {$in: [false, null]},
      new_project_type: 'sdlc',
    },
    sdlcplus: {
      ...filterValues.filter,
      project_status,
      projecttype: 'f',
      is_project_assign: {$in: [false, null]},
      new_project_type: 'sdlcplus',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const tabs = {
    staff_augmentation: {
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.staff_augmentation}
          tabName="staff"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product?._id,
            new_project_type: 'staffaugmentation',
          }}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.staff_augmentation,
      params: filterParams,
      limit: 500,
    },
    managed_team: {
      label: 'Managed Team',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.managed_team}
          tabName="managed"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product?._id,
            new_project_type: 'managedteam',
          }}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.managed_team,
      params: filterParams,
      limit: 500,
    },
    sdlc: {
      label: 'SDLC',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlc}
          tabName="sdlc"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlc'}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlc,
      params: filterParams,
      limit: 500,
    },
    sdlcplus: {
      label: 'SDLC+',
      view: (
        <PerformanceBilledUnassignedTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlcplus}
          tabName="sdlcplus"
          project_status={project_status}
          tab={tab}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlcplus'}}
        />,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlcplus,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const PerformanceAllTab = props => {
  let {
    route: {params} = {},
    project_status,
    tab,
    is_project_assign,
    billed_project_menu,
  } = props;

  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });

  const filters = {
    all_active: billed_project_menu
      ? {
          $or: [
            {
              new_project_type: {
                $in: ['staffaugmentation', 'managedteam', 'sdlc'],
              },
            },
            {
              new_project_type: 'sdlcplus',
              projecttype: 'f',
            },
            {
              projecttype: 'i',
            },
          ],
          ...filterValues.filter,
          project_status,
        }
      : {
          new_project_type: {
            $in:
              project_status === 'a'
                ? ['staffaugmentation', 'managedteam', 'sdlc']
                : ['staffaugmentation', 'managedteam', 'sdlc', 'sdlcplus'],
          },
          ...filterValues.filter,
          project_status,
        },
  };

  let filterParams = {...params, ...filterValues?.params, tab};

  // const groupBy = useGroupBy({
  //   options: [
  //     {
  //       label: 'Label',
  //       field: 'new_project_sub_type',
  //       // aggregates: {
  //       //   base_pending_receipt_amount: 'sum',
  //       // },
  //       groupRow: {
  //         data: '_children',
  //         defaultExpanded: true,
  //         leftContent: ({row}) => {
  //           return (
  //             <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
  //               <GroupContentItem value={row.name} />
  //               <GroupContentItem
  //                 value={row.base_pending_receipt_amount.toFixed(2)}
  //               />
  //             </RowWithSeparator>
  //           );
  //         },
  //       },
  //     },
  //   ],
  // });

  const tabs = {
    all_active: {
      apiKey: 'active',
      label: 'All',
      view: (
        <PerformanceAllBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.all_active}
          tabName="all"
          project_status={project_status}
          tab={tab}
          // groupBy={groupBy}
        />
      ),
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.all_active,
      params: filterParams,
      limit: 500,
    },
  };

  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
        // <GroupBy {...groupBy} />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const BilledStaffaugmentationProjects = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {download} = useAppStateContext();
  const filters = {
    staff_augmentation: {
      ...filterValues.filter,
      project_status: 'a',
      new_project_type: 'staffaugmentation',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const icons = useTheme('icons');
  const tabs = {
    staff_augmentation: {
      apiKey: 'active',
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.staff_augmentation}
          tabName="staff"
          project_status={'a'}
          tab={'Assigned'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product,
            new_project_type: 'staffaugmentation',
          }}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.staff_augmentation,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.staff_augmentation,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const BilledManagedTeamProjects = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {download} = useAppStateContext();
  const filters = {
    managed_team: {
      ...filterValues.filter,
      project_status: 'a',
      new_project_type: 'managedteam',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const icons = useTheme('icons');
  const tabs = {
    managed_team: {
      apiKey: 'active',
      label: 'Managed Team',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.managed_team}
          tabName="managed"
          project_status={'a'}
          tab={'Assigned'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product,
            new_project_type: 'managedteam',
          }}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.managed_team,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.managed_team,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const BilledSDLCProjects = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  const {download} = useAppStateContext();
  const filters = {
    sdlc: {
      ...filterValues.filter,
      project_status: 'a',
      new_project_type: 'sdlc',
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const icons = useTheme('icons');
  const tabs = {
    sdlc: {
      apiKey: 'active',
      label: 'SDLC',
      view: (
        <PerformanceBilledReportTable
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={filters.sdlc}
          tabName="sdlc"
          project_status={'a'}
          tab={'Assigned'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product, new_project_type: 'sdlc'}}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.sdlc,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.sdlc,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const BilledCommonSubTypeProjects = props => {
  let {
    route: {params} = {},
    project_status,
    tab: props_tab,
    addOnParamFilter: propsAddOnParams,
    user,
  } = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentDay(),
    },
  });
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;
  let tab = props_tab || params?.tab;
  const {download} = useAppStateContext();
  const filters = {
    common_sub_type_filter: {
      ...filterValues.filter,
      project_status: 'a',
      ...addOnParamFilter,
    },
  };

  let filterParams = {...params, ...filterValues?.params, tab};
  const icons = useTheme('icons');
  const tabs = {
    common_sub_type: {
      apiKey: 'active',
      label: `${tab}`,
      view: (
        <AllActiveProjects
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          addOnFilter={{...filters.common_sub_type_filter, project_status: 'a'}}
          tabName="staff"
          project_status={'a'}
          tab={'Assigned'}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'client-add-project'}
          params={{
            user,
            new_project_sub_type: addOnParamFilter?.new_project_sub_type,
            ...params,
          }}
        />,
        <TouchableOpacity
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/project/performance-report',
                    params: {...filterValues.params, tab},
                    searchFields: ['project'],
                    search: searchValue,
                    addOnFilter: filters.common_sub_type_filter,
                  },
                  column: {
                    Project: 'project',
                    Type: 'new_project_type',
                    'ASM/PM': 'owner_id.name',
                    Allocated: 'team',
                    'Avg Exp.': 'avg_experience',
                  },
                },
              },
            });
          }}>
          <Image source={icons.Download} />
        </TouchableOpacity>,
      ],
      api: '/projects',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: filters.common_sub_type_filter,
      params: filterParams,
      limit: 500,
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};
