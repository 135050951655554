import React from 'react';
import {Table} from '../../../components/table/Table';
import {useNavigation} from '@react-navigation/native';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

export const OrganizationTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="Organization"
      api={`/organizations`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`organization-detail`, {organizationId: row._id});
      }}
      renderHeader={() => (
        <TableHeader
          title="Organization"
          actions={[
            <AddButton
              title="Add Organization"
              view="add-organization"
              // params={params}
            />,
          ]}
        />
      )}
      columns={[
        {
          type: 'colorTag',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'code',
          header: 'Code',
          width: 80,
        },
        // {
        //   type: 'text',
        //   field: 'email',
        //   header: 'Email',
        // },
        {
          type: 'text',
          field: 'url',
          header: 'URL',
          width: 200,
        },

        // {
        //   type: 'date',
        //   field: 'start_date',
        //   header: 'Start Date',
        // },
        {
          type: 'text',
          field: 'address',
          header: 'Address',
        },
        // {
        //   type: 'string',
        //   field: 'phone_no',
        //   header: 'Phone No.',
        // },
        // {
        //   type: 'text',
        //   field: 'beneficiary_name',
        //   header: 'Beneficiary Name',
        // },
        // {
        //   type: 'text',
        //   field: 'deputation_address',
        //   header: 'Deputation Address',
        // },
      ]}
    />
  );
};
