import React from 'react';
import {Form, InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ThreadGoalForm = props => {
  let {props: newProps = {}, mode} = props;
  newProps = mode ? props : newProps;
  const {
    navigation,
    route: {params},
    issues,
  } = newProps;
  const {row, thread = {}} = params;
  const {onSubmit} = useFormSubmit({
    uri: issues ? '/threadissues' : '/threadgoals',
    eventSourceId: 'threadGoal',
    ...newProps,
  });

  return !mode ? (
    <InlineForm
      api={issues ? `/threadissues/${row?._id}` : `/threadgoals/${row?._id}`}
      fields={{
        goal: 1,
        issue: 1,
        due_date: 1,
        owner: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            thread: thread?._id,
            status: !issues ? 'Plan' : 'Active',
          },
        };
      }}
      header={{title: issues ? 'Add Issue' : 'Add Goal'}}
      layoutFields={[
        {
          label: issues ? 'Issue' : 'Goal',
          type: 'text',
          field: issues ? 'issue' : 'goal',
          required: true,
        },
        {
          label: 'Owner',
          type: 'autoComplete',
          field: 'owner',
          api: '/users',
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
        },
        {
          label: 'KPI',
          type: 'autoComplete',
          field: 'kpi',
          api: '/kpis',
          suggestionField: 'kpi',
          valueField: 'kpi',
        },
        !issues
          ? {
              label: 'Due Date',
              type: 'date',
              field: 'due_date',
              required: true,
            }
          : void 0,
      ]}
      {...newProps}
    />
  ) : (
    <Form
      api={issues ? `/threadissues/${row?._id}` : `/threadgoals/${row?._id}`}
      fields={{
        goal: 1,
        issue: 1,
        due_date: 1,
        owner: {
          name: 1,
        },
        kpi:{
          kpi: 1,
        }
      }}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            thread: thread?._id,
            status: 'Active',
          },
        };
      }}
      header={{title: issues ? 'Add Issue' : 'Add Goal'}}
      layoutFields={[
        {
          label: issues ? 'Issue' : 'Goal',
          type: 'text',
          field: issues ? 'issue' : 'goal',
          required: true,
        },
        {
          label: 'Owner',
          type: 'autoComplete',
          field: 'owner',
          api: '/users',
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
        },
        {
          label: 'KPI',
          type: 'autoComplete',
          field: 'kpi',
          api: '/kpis',
          suggestionField: 'kpi',
          valueField: 'kpi',
        },
      ]}
      {...newProps}
    />
  );
};

export const EditThreadGoalForm = props => {
  const {
    route: {params},
  } = props;
  const {
    row: {issues},
  } = params || {};
  props = {...props, issues};
  return (
    <ThreadGoalForm
      header={{title: `Edit Thread ${issues ? 'Issue' : 'Goal'}`}}
      mode="edit"
      {...props}
    />
  );
};
