import React from 'react';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import {Header} from '@unthinkable/react-screen-components';
import {PositionContainer, ModalViewContainer} from './ModalStyles';
import {isMobile} from '@unthinkable/react-utils';
import Close from './images/Close.svg';
import {IconButton} from '@unthinkable/react-button';

const ModalContent = ({
  styles = {},
  hideModal,
  closeable,
  closeActionPosition,
  absoluteCloseAction,
  header,
  children,
  ...props
}) => {
  closeActionPosition = closeActionPosition || (header ? 'header' : 'absolute');

  const {close: headerClose} = styles?.header || {};
  const {icon: headerCloseIcon = Close, ...headerCloseStyles} =
    headerClose || {};

  let closeAction = void 0;
  if (closeable && hideModal) {
    closeAction =
      closeActionPosition === 'absolute' ? (
        <TouchableOpacity
          style={styles?.crossAbsoluteContainer}
          onPress={() => {
            hideModal();
          }}>
          <Image source={styles?.crossIcon} style={styles?.crossIconStyle} />
        </TouchableOpacity>
      ) : (
        <IconButton
          styles={headerCloseStyles}
          icon={headerCloseIcon}
          onPress={() => {
            hideModal();
          }}
        />
      );
  }

  return (
    <>
      {header ? (
        <Header
          {...props}
          styles={styles?.header}
          {...header}
          closeAction={closeAction}
        />
      ) : (
        void 0
      )}
      {typeof children === 'function' ? children({hide: hideModal}) : children}
      {closeActionPosition === 'absolute' ? closeAction : void 0}
    </>
  );
};

export const ModalViewComponent = ({
  styles = {},
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  backgroundColor,
  borderRadius,
  size,
  position = 'screenCenter',
  variant,
  ...props
}) => {
  styles = {...styles, ...styles?.[variant]};
  return (
    <ModalViewContainer
      onPress={() => {}}
      styles={styles}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      maxHeight={maxHeight}
      minHeight={minHeight}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      size={size}
      position={position}>
      <ModalContent {...props} styles={styles} />
    </ModalViewContainer>
  );
};

export const ModalView = ({styles, position, autoHide = true, ...props}) => {
  if (!position) {
    position = isMobile ? 'screenBottom' : 'screenCenter';
  }
  return (
    <PositionContainer
      styles={styles}
      position={position}
      onPress={e => {
        autoHide && props.hideModal?.(e);
      }}>
      <ModalViewComponent {...props} styles={styles} position={position} />
    </PositionContainer>
  );
};
