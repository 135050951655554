import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

const TraineeForm = props => {
  const {
    route: {params},
  } = props;
  const {employee={}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/addEmployeeTraining',
    eventSourceId: ['allocatedResource'],
  });
  let defaultValues={employee}

  return (
    <Form
      onSubmit={onSubmit}
    //   eventSourceId="account"
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
            label: 'Resource',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            valueField: 'name',
            readOnly:true
          },
          {
            label: 'Trainer',
            field: 'mentor',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            valueField: 'name',
          },
          {
            label: 'Training Batch',
            field: 'training_batch',
            type: 'autoComplete',
            api: `/trainingBatches`,
            suggestionField: 'name',
            valueField: 'name',
          },
          {
            label: 'Traning Program',
            field: 'training_program',
            type: 'autoComplete',
            api: `/trainingPrograms`,
            suggestionField: 'name',
            valueField: 'name',
          },
        
      
      ]}
      {...props}
    />
  );
};

export const AssignTraineeForm = props => {
  return <TraineeForm header="Assign Trainee" {...props} />;
};

