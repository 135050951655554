import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Row, Text} from '@unthinkable/react-core-components';
import {LineSeparator} from '@unthinkable/react-layout';

export const OutreachBatchHeader = props => {
  return (
    <NavHeader
      title={`Outreach Campaigns (${
        props?.route?.params?.product?.name ||
        props?.route?.params?.organization?.name
      })`}
      {...props}
    />
  );
};

const RendersAcceptedPercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.accepted_percentage
          ? row?.accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.accepted_percentage || row?.overall_accepted_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_accepted_percentage
          ? row?.overall_accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const RendersPositivePercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.positive_result_percentage
          ? row?.positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.positive_result_percentage ||
      row?.overall_positive_result_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_positive_result_percentage
          ? row?.overall_positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

export const OutreachBatchInmailTable = props => {
  let {
    route: {params},
    filter,
    searchValue,
    filterParams,
    campaign_type,
  } = props;
  params = {...params, ...filterParams};
  const {product, period} = params;
  const {
    acceptedProspectsForOutreachBatch,
    requestSentForOutreach,
    messageSendForOutreach,
    postiveForOutreachBatch,
    outreachBatchUqls,
    preoutreachBatchUqls,
    qualifiedLeadsForOutreach,
    prequalifiedLeadsForOutreach,
    inmailMessages,
  } = leadControllers({...props, params: {...params, period}});

  return (
    <Table
      eventSourceId="outreachBatch"
      api={`/product/${product?._id}/outreach-batch-inmail`}
      params={{
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      onRowPress={inmailMessages}
      addOnFilter={filter}
      search={searchValue}
      searchFields={'name'}
      columns={[
        {
          width: 145,
          field: 'name',
          header: 'Name',
        },
        {
          width: 145,
          field: 'campaign_id.name',
          colorField: 'campaign_id.color',
          header: 'Campaign',
          type: 'colorTag',
        },
        {
          width: 125,
          type: 'number',
          field: 'request_send_count',
          header: 'Connection Request',
          aggregate: true,
          onPress: requestSentForOutreach,
        },
        {
          width: 200,
          type: 'number',
          header: 'Accepted %',
          render: RendersAcceptedPercentage,
          onPress: acceptedProspectsForOutreachBatch,
        },
        {
          width: 120,
          type: 'number',
          field: 'message_send_count',
          header: 'Message Sent',
          aggregate: true,
          onPress: messageSendForOutreach,
        },
        {
          width: 140,
          type: 'number',
          header: 'Positive %',
          render: RendersPositivePercentage,
          onPress: postiveForOutreachBatch,
        },
        {
          width: 200,
          type: 'number',
          field: 'lead_count',
          compareField: 'previous_lead_count',
          header: 'Lead',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: outreachBatchUqls,
          onComparePress: preoutreachBatchUqls,
          render: ComparisonRender,
        },
        {
          width: 200,
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'Qualified Lead',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: qualifiedLeadsForOutreach,
          onComparePress: prequalifiedLeadsForOutreach,
          render: ComparisonRender,
        },
      ]}
    />
  );
};

export const OutreachBatchEmailTable = props => {
  let {
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  params = {...params, ...filterParams};
  const {product, period} = params;
  const {
    messageSendForOutreach,
    postiveForOutreachBatch,
    outreachBatchUqls,
    preoutreachBatchUqls,
    qualifiedLeadsForOutreach,
    prequalifiedLeadsForOutreach,
    inmailMessages,
    prospectsCountForCampaignForOutreachBatch,
  } = leadControllers({...props, params: {...params, period}});

  return (
    <Table
      eventSourceId="outreachBatch"
      api={`/product/${product?._id}/outreach-batch-email`}
      params={{
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      onRowPress={inmailMessages}
      addOnFilter={filter}
      search={searchValue}
      searchFields={'name'}
      columns={[
        {
          width: 145,
          field: 'name',
          header: 'Name',
        },
        {
          width: 145,
          header: 'Campaign',
          field: 'campaign_id.name',
          colorField: 'campaign_id.color',
          type: 'colorTag',
        },
        {
          width: 180,
          type: 'number',
          field: 'prospect_count',
          header: 'Prospect',
          aggregate: true,
          onPress: prospectsCountForCampaignForOutreachBatch,
        },
        {
          width: 120,
          type: 'number',
          field: 'message_send_count',
          header: 'Message Sent',
          aggregate: true,
          onPress: messageSendForOutreach,
        },
        {
          width: 140,
          type: 'number',
          header: 'Positive %',
          render: RendersPositivePercentage,
          onPress: postiveForOutreachBatch,
        },
        {
          width: 200,
          type: 'number',
          field: 'lead_count',
          compareField: 'previous_lead_count',
          header: 'Lead',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: outreachBatchUqls,
          onComparePress: preoutreachBatchUqls,
          render: ComparisonRender,
        },
        {
          width: 200,
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'Qualified Lead',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: qualifiedLeadsForOutreach,
          onComparePress: prequalifiedLeadsForOutreach,
          render: ComparisonRender,
        },
      ]}
    />
  );
};
