import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EditDepartmentRole = props => {
  const {
    route: {params},
  } = props;
  const {departmentRole} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/departmentroles`,
    eventSourceId: 'DepartmentRole',
  });

  return (
    <Form
      header={{
        title: 'Edit Department Role',
        secondaryTitle: departmentRole?.name,
      }}
      mode="edit"
      api={`/departmentroles/${departmentRole._id}`}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
      ]}
      onSubmit={onSubmit}
      submitAction="Update"
    />
  );
};

export const DepartmentRoleWithSkills = props => {
  const {
    route: {params},
  } = props;
  const {departmentRole} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/departmentroles`,
    eventSourceId: 'DepartmentRole',
  });
  return (
    <Form
      {...props}
      header={{
        title: 'Department Role',
        secondaryTitle: departmentRole?.name,
      }}
      fields={{name: 1, skills: {name: 1}, department: {name: 1}}}
      api={`/departmentroles/${departmentRole?._id}`}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
        {
          field: 'department',
          type: 'autoComplete',
          label: 'Department',
          api: `/departments`,
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          field: 'skills',
          type: 'multiAutoComplete',
          label: 'Skills',
          api: `/tools`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      onSubmit={onSubmit}
      submitAction="Save"
    />
  );
};

export const EditDepartmentRoleForm = props => {
  const {
    route: {params},
  } = props;

  return <DepartmentRoleWithSkills {...props} mode="edit" />;
};
