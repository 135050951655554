import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupTable} from '../../../components/table/Table';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const helpItemCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Item Count',
  align: 'center',
  count_field: 'help_item_count',
  responsive: 'sm',

  onPlusPress: props => {
    const {row} = props;
    navigation.navigate('add-home-item', {row});
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate('help-screen-item', {row});
  },
});

const HelpScreenTable = props => {
  const {navigation, route: {params} = {}} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'helpscreens',
  });

  return (
    <GroupTable
      {...props}
      api={`/help_screen_details`}
      eventSourceId={'helpscreens'}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Add"
              view="add-home-screen"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      defaultExpanded={true}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.title} />
            </RowWithSeparator>
          );
        },
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-home-screen', {row});
      }}
      columns={[
        {
          header: 'Help Text Id',
          field: 'help_text_id',
          type: 'text',
          responisve: 'sm',
        },
        {
          header: 'Title',
          field: 'title',
          type: 'text',
          responisve: 'sm',
        },
        {
          header: 'Render',
          field: 'render',
          type: 'text',
        },
        helpItemCount({navigation, params}),
      ]}
      moreActions={() => [
        {
          title: 'Help Screen Item',
          onPress: ({row}) => {
            navigation.navigate('help-screen-item', {row});
          },
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-home-screen', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/helpscreens/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};

export default HelpScreenTable;
