import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {ChipComponent} from '../../components/chip/Chip';
import {useStyles} from '@unthinkable/react-theme';
import {BadgeStyles} from './theme';

export const Badge = ({
  value,
  children,
  color,
  textColor,
  top = -8,
  right = -10,
}) => {
  const {badge, backgroundColor} = useStyles(BadgeStyles);

  if (value > 99) {
    value = '+99';
  }

  return (
    <View>
      {children}
      <View style={{position: 'absolute', top, right}}>
        <ChipComponent
          styles={badge}
          value={value}
          color={color || backgroundColor}
          textColor={textColor}
        />
      </View>
    </View>
  );
};
