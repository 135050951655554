import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {OrderTableTabView} from '../../order/views/OrderTableTabView';
import {CustomerTableTabView} from './CustomerTableTabView';
// import RecieptTable from '../../order/views/RecieptTable';

const menus = [
  {
    label: 'Customer',
    view: <CustomerTableTabView />,
  },
  {
    label: 'Order',
    view: <OrderTableTabView />,
  },
  {
    label: 'Receipt',
    // view: <RecieptTable />,
  },
];

export const CustomerMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};
