import React from 'react';
import { Form } from '../../../components/form/Form';
import { useInvoke } from '../../../controllers/useInvoke';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddEmployeeUserForm = props => {
  const {
    route: {params},
  } = props;
  const {row, defaultValues = {}, candidate, updateCandidate} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/users`,
    eventSourceId: ['user', 'selectedCandidate'],
    ...props,
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['user', 'selectedCandidate'],
  });

  return (
    <Form
      header="New User"
      onSubmit={onSubmit}
      submitAction={'Save'}
      afterSubmit={async () => {
        if (updateCandidate)
          await invoke({
            uri: '/candidates/' + candidate,
            props: {
              is_parichay_form_shared: true,
            },
          });
      }}
      defaultValues={{
        ...defaultValues,
        parichay_user_password: '12345678',
        password:
          'ef797c8118f02dfb649607dd5d3f8c7623048c9c063d532cc95c5ed7a898a64f',
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          size: 6,
          required: true,
        },
        {
          type: 'text',
          field: 'email',
          label: 'Email',
          size: 6,
          required: true,
        },
        {
          //   type: 'text',
          field: 'userType',
          type: 'autoComplete',
          suggestionField: 'label',
          valueField: 'label',
          keyField: 'value',
          options: [
            {label: 'Employee', value: 'employee'},
            {label: 'Client', value: 'client'},
            {label: 'Pre Employee', value: 'pre employee'},
            {label: 'Data Verifier', value: ' data verifier'},
          ],
          label: 'User type',
          size: 4,
          required: true,
        },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          visible: ({values}) =>
            values && values.userType && values.userType === 'employee',
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          //   required: true,
          visible: ({values}) =>
            values && values.userType && values.userType === 'pre employee',
        },
        {
          label: 'Gender',
          field: 'gender',
          type: 'autoComplete',
          type: 'autoComplete',
          suggestionField: 'label',
          valueField: 'label',
          keyField: 'value',
          options: [
            {label: 'Male', value: 'male'},
            {label: 'Female', value: 'female'},
          ],
          size: 3,
          visible: ({values}) =>
            values && values.userType && values.userType === 'pre employee',
        },
        {
          label: 'Marital Status',
          field: 'marital_status',
          type: 'autoComplete',
          valueField: 'label',
          keyField: 'value',
          suggestionField: 'label',
          valueField: 'label',
          keyField: 'value',
          options: [
            {label: 'Married', value: 'married'},
            {label: 'Unmarried', value: 'unmarried'},
          ],
          visible: ({values}) =>
            values && values.userType && values.userType === 'pre employee',
          size: 3,
        },
        // {
        //   type: 'password',
        //   field: 'password',
        //   placeholder: 'Password',
        //   label: 'Password',
        //   required: true,
        // },
      ]}
      //   {...props}
    />
  );
};
