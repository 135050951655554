import {Form, InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ProjectDatabaseNameInlineForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {project, database} = params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: ['ProjectDatabaseName', 'ProjectDatabase'],
    uri: '/projectdatabasenames',
  });
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project_id: project?._id,
        database_id: database?._id,
      }}
      layoutFields={[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          label: 'Modules',
          field: 'module_ids',
          type: 'multiAutoComplete',
          api: `/projectmodules`,
          suggestionField: 'module',
          valueField: 'module',
          filter: {project_id: project?._id, status: {$ne: 'archived'}},
        },
      ]}
    />
  );
};

const ProjectDatabaseNameForm = ({hideModule, hideDatabase, ...props}) => {
  const {
    navigation,
    route: {params = {}},
  } = props;
  const {project} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'ProjectDatabaseName',
    uri: '/projectdatabasenames',
  });
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Database',
          type: 'autoComplete',
          field: 'database_id',
          api: '/projectdatabases',
          suggestionField: 'database',
          secondarySuggestionField: 'version',
          valueField: 'database',
          required: true,
          filter: {project_id: project?._id},
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('add-database', {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
          visible: !hideDatabase,
        },
        {
          label: 'Database Name',
          type: 'text',
          field: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Modules',
          type: 'multiAutoComplete',
          field: 'module_ids',
          api: `/projectmodules`,
          suggestionField: 'module',
          valueField: 'module',
          filter: {project_id: project?._id, status: {$ne: 'archived'}},
          visible: !hideModule,
        },
      ]}
    />
  );
};

export const EditProjectDatabaseNameForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {database} = params;

  return (
    <ProjectDatabaseNameForm
      {...props}
      defaultValues={{
        ...database,
      }}
      header={{title: 'Edit Database Name'}}
      hideDatabase
    />
  );
};

export const AddProjectDatabaseNameForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {project_id, searchValue, afterSubmit} = params;

  return (
    <ProjectDatabaseNameForm
      {...props}
      hideModule
      defaultValues={{
        project_id,
        name: searchValue,
      }}
      afterSubmit={afterSubmit}
      header={{title: 'Add Database Name'}}
    />
  );
};
