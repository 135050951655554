import React from 'react';
import {Table} from '../../../components/table/Table';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form/Renderers';

const EmployeeNameRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'name', type: 'text'},
        {
          field: 'employee_code',
          type: 'text',
          variant: 'subtle',
        },
      ]}
    />
  );
};

const JoiningDateRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  let joinngDate = '';
  joinngDate = moment(row?.joining_date).format('DD MMM, YY');
  if (!joinngDate) return null;
  return (
    <Text
      style={{
        ...rowText,
      }}>
      {joinngDate}
    </Text>
  );
};

const InactiveFromRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  let relievingDate = '';
  relievingDate = moment(row?.relieving_date).format('DD MMM, YY');
  if (!relievingDate) return null;
  return (
    <Text
      style={{
        ...rowText,
      }}>
      {relievingDate}
    </Text>
  );
};

const ResignDateRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  let relievingDate = '';
  relievingDate = moment(row?.relieving_date).format('DD MMM, YY');
  if (!relievingDate) return null;
  return (
    <Text
      style={{
        ...rowText,
      }}>
      {relievingDate}
    </Text>
  );
};

const InseperationFromRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  let resignDate = '';
  resignDate = moment(row?.resign_date).format('DD MMM, YY');
  if (!resignDate) return null;
  return (
    <Text
      style={{
        ...rowText,
      }}>
      {resignDate}
    </Text>
  );
};

const RenderReportingTo = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  let reportingToName = '';
  if (row && row.reporting_to && row.reporting_to.length > 0) {
    reportingToName = row.reporting_to[0].name;
  }
  console.log('reportingTo>>>>>', reportingToName);
  if (!reportingToName) return null;
  return (
    <Text
      style={{
        ...rowText,
      }}>
      {reportingToName}
    </Text>
  );
};

const RenderSkillCount = props => {
  const {row} = props;
  const value = row?.['skill_metrics'];
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value={value?.length ? JSON.stringify(value?.length) : '0'}
      style={{
        color: colors['ACCENT1_HIGH'],
        textAlign: 'center',
      }}
    />
  );
};

const EMPLOYEE = {
  header: 'Name',
  render: EmployeeNameRender,
};

const DESIGNATION = {
  type: 'text',
  field: 'designation.name',
  header: 'Designation',
};

const RESIGN_DATE = {
  render: ResignDateRender,
  header: 'Resignation Date',
  width: 300,
};

const RELIEVING_DATE = {
  type: 'date',
  field: 'relieving_date',
  header: 'Relieving Date',
};

const INACTIVE_FROM = {
  render: InactiveFromRender,
  header: 'Relieving Date',
};

const INSEPERATION_FROM = {
  render: InseperationFromRender,
  header: 'Resign Date',
};

const PRODUCT = {
  type: 'text',
  field: 'product.name',
  header: 'Product',
};

const JOINING = {
  render: JoiningDateRender,
  header: 'Joining',
};

const REPORTINGTO = {
  header: 'Reporting To',
  render: RenderReportingTo,
};

export const ActiveEmployeeTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    searchValue,
  } = props;
  const showActions = ({row}) => {
    const value = row['skill_metrics']?.map(x => x?._id);
    navigation.navigate('employee-skill-metrics', {
      ...params,
      ...row,
      addOnFilter: {
        _id: {$in: value?.length ? value : null},
      },
    });
  };
  return (
    <Table
      api="/active/employees"
      params={params}
      onRowPress={row => {
        navigation.navigate('employee-form', row);
      }}
      searchFields="name"
      search={searchValue}
      columns={[
        EMPLOYEE,
        DESIGNATION,
        JOINING,
        REPORTINGTO,
        PRODUCT,
        {
          render: RenderSkillCount,
          header: 'Skills',
          onPress: showActions,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};



export const InActiveEmployeeTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    searchValue,
  } = props;
  return (
    <Table
      {...props}
      api="/inactive/employees"
      params={params}
      searchFields="name"
      search={searchValue}
      columns={[
        EMPLOYEE,
        DESIGNATION,
        RESIGN_DATE,
        JOINING,
        INACTIVE_FROM,
        INSEPERATION_FROM,
        REPORTINGTO,
        PRODUCT,
      ]}
    />
  );
};

export const InSeperationEmployeeTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    searchValue,
  } = props;
  return (
    <Table
      {...props}
      api="/inseperation/employees"
      params={params}
      searchFields="name"
      search={searchValue}
      columns={[EMPLOYEE, DESIGNATION, JOINING, REPORTINGTO, PRODUCT]}
    />
  );
};

export const AlumniEmployeeTable = props => {
  let {
    route: {params},
    filterParams,
    navigation,
    searchValue,
  } = props;
  return (
    <Table
      {...props}
      api="/alumni/employees"
      params={params}
      search={searchValue}
      searchFields="name"
      columns={[
        EMPLOYEE,
        DESIGNATION,
        JOINING,
        REPORTINGTO,
        INACTIVE_FROM,
        PRODUCT,
      ]}
    />
  );
};
