import React, {useEffect, useRef, useState} from 'react';
import {
  PasswordRenderer,
  TextAreaRenderer,
  TextRenderer,
} from '../../../components/form-editors/Editors';
import {
  Linking,
  ScrollView,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {NoDataFound} from '../../../components/table/Table';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useStyles} from '@unthinkable/react-theme';
import {BranchStyle} from '../../pmt/headers/theme';
import {IconButton} from '../../../components/button/IconButton';
import {Button} from '../../../components/button/Button';

export const ViewLogs = props => {
  const {
    route: {params},
  } = props;

  const {env_id, status, build_number, mapDomain} = params;
  const {fetch} = useAppStateContext();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const scrollViewRef = useRef(null);

  const fetchData = async () => {
    const {data, loading} =
      (await fetch({
        uri: `/repositoryEnvironments/${env_id}/builds/${build_number}/getlogs`,
      })) || {};
    setLoading(loading);
    setValue(data);
  };

  useEffect(() => {
    fetchData();
    if (status === 'in progress') {
      const intervalId = setInterval(fetchData, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [value]);

  if (loading) {
    return (
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <ActivityIndicator />
      </View>
    );
  }

  if (!loading && !value) {
    return (
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <NoDataFound />
      </View>
    );
  }

  return (
    <ScrollView ref={scrollViewRef}>
      <TextAreaRenderer value={value} />
    </ScrollView>
  );
};

export const RunningLogs = props => {
  const {
    route: {params},
  } = props;

  const {
    environemnt: {grafana_url, grafana_user, grafana_password},
  } = params;

  const {buttonContainer, copyIcon} = useStyles(BranchStyle);
  const RenderValue = ({children, label, value}) => {
    return (
      <View
        style={{
          position: 'relative',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#c0c0c0',
          padding: 10,
          paddingTop: 12,
          marginBottom: 20,
          borderRadius: 8,
        }}>
        <View
          style={{
            position: 'absolute',
            top: -10,
            left: 10,
            backgroundColor: '#fff',
            paddingLeft: 10,
            paddingRight: 10,
            color: 'black',
          }}>
          <TextRenderer value={label} />
        </View>
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <View style={{flex: 1}}>{children}</View>
          <TouchableOpacity
            title={`Copy ${label}`}
            onPress={async () => {
              try {
                navigator.clipboard.writeText(`${value}`);
              } catch (err) {
                //err
              }
            }}>
            <IconButton styles={buttonContainer} icon={copyIcon} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        margin: 20,
      }}>
      <RenderValue label={'Email or Username'} value={grafana_user}>
        <TextRenderer value={grafana_user} />
      </RenderValue>
      <RenderValue label={'Password'} value={grafana_password}>
        <PasswordRenderer value={grafana_password} />
      </RenderValue>

      {grafana_url && (
        <Button
          onPress={() => {
            Linking.openURL(grafana_url, '_blank');
          }}>
          Visit Grafana
        </Button>
      )}
    </View>
  );
};
