import React from 'react';
import moment from 'moment';

import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  EmployeeProjectTasks,
  ProjectReviewTaskTable,
} from './TasksTable';

export const ProjectOverdueTasksTab = props => {
  const {
    route: {params},
  } = props;
  const {assigned_to, userId, project_id} = params;

  const start_date = moment.utc().startOf('day').toDate();

  const review_date = moment(start_date).subtract(2, 'days').toDate();

  return (
    <LeftMenuView
      {...props}
      menus={[
        {
          label: 'Tasks',
          view: (
            <EmployeeProjectTasks
              key="Task"
              filterValues={{
                filter: {
                  status: 'active',
                  due_date: {$lt: start_date},
                  assigned_to: assigned_to?._id,
                  project_id,
                },
              }}
            />
          ),
        },
        {
          label: 'Reviews',
          view: (
            <ProjectReviewTaskTable
              key={'Reviews'}
              source={'Pending'}
              filterValues={{
                filter: {
                  status: 'completed',
                  review_pending: true,
                  reviewer: userId,
                  completed_on: {
                    $lt: review_date,
                  },
                  project_id,
                },
              }}
              {...props}
            />
          ),
        },
      ]}
    />
  );
};
