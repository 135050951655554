import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { useInvoke } from '../../../controllers/useInvoke';

export const TestimonialsTable = () => {
  const navigation = useNavigation();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'testimonial',
  });

  return (
    <Table
      eventSourceId={["testimonial"]}
      api={`/testimonials`}
      fields={{
        companyName: 1,
        ownerName: 1,
        ownerRole: 1,
        ownerReview: 1,
      }}
      sort={{index: 1}}
      renderHeader={() => (
        <TableHeader
          title="Testimonials"
          actions={[
            <AddButton
              title="Add Testimonial"
              view="add-testimonials"
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-testimonials', { row });
      }}
      columns={[
        {
          type: 'text',
          field: 'companyName',
          header: 'Company',
          width: 150,
        },
        {
          type: 'text',
          field: 'ownerName',
          header: 'Owner',
          align: 'center',
          width: 150,
        },
        {
          type: 'text',
          field: 'ownerRole',
          header: 'Designation',
          align: 'center',
          width: 150,
        },
        {
          type: 'textArea',
          field: 'ownerReview',
          header: 'Review',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-testimonials', { row });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deleteInvoke({
              uri: `/testimonials/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
