import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';

export const BlopdGroupTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  return (
    <Table
      eventSourceId={['bloodgroup']}
      api={`/bloodgroups`}
      renderHeader={() => {
        return (
          <TableHeader
            title="Blood Group"
            actions={[
              <AddButton
                title="Blood Group"
                view="add-blood-group"
                params={params}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-blood-group`, {
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          header: 'Name',
          field: 'name',
        },
      ]}
    />
  );
};
