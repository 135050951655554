import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const AccountForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/accounts',
    eventSourceId: ['account'],
  });

  return (
    <Form
      api={`/accounts/${row?._id}`}
      onSubmit={onSubmit}
      eventSourceId="account"
      submitAction="Save"
      fields={{
        name: 1,
        is_bank: 1,
        is_child_account: 1,
        description: 1,
        account_group_type: 1,
        use_of_life: 1,
        depreciation_rate: 1,
        is_breakable: 1,
        mis_view: 1,
        mis_view_type: 1,
        is_summary_required: 1,
        parent_account_id: {
          name: 1,
        },
        depreciation_account: {
          name: 1,
        },
        department: {
          name: 1,
        },
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          placeholder: 'Name',
          label: 'Name',
        },
        {
          type: 'text',
          field: 'description',
          placeholder: 'Description',
          label: 'Description',
        },
        {
          placeholder: 'Parent',
          field: 'parent_account_id',
          type: 'autoComplete',
          api: `/accounts`,
          label: 'Parent',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          field: 'account_group_type',
          type: 'autoComplete',
          options: [
            {label: 'Revenue', value: 'revenue'},
            {label: 'Asset', value: 'assert'},
            {label: 'Liability', value: 'liability'},
            {label: 'Expense', value: 'expense'},
          ],
          placeholder: 'Type',
          valueField: 'label',
          keyField: 'value',
          label: 'Type',
          suggestionField: 'label',
        },
        {
          type: 'number',
          field: 'depreciation_rate',
          placeholder: 'Depreciation Rate',
          label: 'Depreciation Rate',
        },
        {
          label: 'Useful Life(in Year)',
          visible: ({values}) =>
            values &&
            values.account_group_type &&
            values.account_group_type === 'assert',
          field: 'use_of_life',
          type: 'number',
        },
        {
          placeholder: 'Depreciation Account',
          field: 'depreciation_account',
          type: 'autoComplete',
          api: `/accounts`,
          label: 'Depreciation Account',
          suggestionField: 'name',
          valueField: 'name',
        },

        {
          type: 'switch',
          field: 'is_breakable',
          label: 'Is Breakable',
          size: 6,
        },
        {
          type: 'switch',
          field: 'is_bank',
          label: 'Is Bank',
          size: 6,
        },
        {
          type: 'switch',
          field: 'is_child_account',
          label: 'Is Child Account',
          size: 6,
        },
        {
          type: 'switch',
          field: 'is_summary_required',
          label: 'Include in Summary',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const CreateAccountForm = props => {
  return <AccountForm header="Create Account" {...props} />;
};
export const EditAccountForm = props => {
  return <AccountForm mode="edit" header="Update Account" {...props} />;
};
