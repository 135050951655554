import {Table} from '../../../components/table/Table';

export const BulkForm16Table = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  return (
    <Table
      api={'/Form16BatchDocuments'}
      params={params}
      eventSourceId={'bulkform16'}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Created On',
          field: 'createdAt',
          type: 'date',
          // width: 320,
        },
        {
          header: 'Status',
          field: 'status',
          type: 'text',
        },
        {
          header: 'Error',
          field: 'error',
          type: 'text',
        },
        {
          header: 'success',
          field: 'success_count',
          type: 'number',
        },
      ]}
    />
  );
};
