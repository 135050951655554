import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

export const SkillTypesTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'SkillTypes',
  });

  return (
    <Table
      eventSourceId={['SkillTypes']}
      renderHeader={() => (
        <TableHeader
          title="Skill Types"
          actions={[
            <AddButton
              text="+Add Skill Type"
              view="add-skill-type"
              params={params}
            />,
          ]}
        />
      )}
      api={`/skillTypes`}
      columns={[
        {
          field: 'name',
          header: 'Skill Type',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-skill-type', {
              skill_type: row,
            });
          },
        },
        {
          title: 'Delete',
          onPress: async ({row}) => {
            await deleteInvoke({
              uri: `/skillTypes/${row?._id}`,
            });
          },
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this Skill Type?',
            confirmText: 'Delete',
          },
        },
      ]}
      {...props}
    />
  );
};
