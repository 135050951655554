import React, {useEffect} from 'react';
import {ModalView} from '@unthinkable/react-modal';
import {Modal, TouchableOpacity} from '@unthinkable/react-core-components';
import Popper from './Popper';
import {StyledPopperContainer} from './PopperStyles';
import {isMobile, useIsMounted} from '@unthinkable/react-utils';

const PopperComponent = props => {
  let {
    isVisible,
    hideModal,
    position,
    backdrop = true,
    width,
    maxWidth,
    height,
    maxHeight,
    styles,
    children,
    referenceElement,
    onModalHide,
    autoHide = true,
  } = props;
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted() && !isVisible) {
      onModalHide?.();
    }
  }, [isVisible, isMounted, onModalHide]);
  return (
    <>
      {isVisible && backdrop && (
        <TouchableOpacity
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            cursor: 'default',
          }}
          onPress={e => {
            autoHide && hideModal?.(e);
          }}
        />
      )}
      <Popper
        referenceElement={referenceElement}
        mounted={isVisible}
        position={position}
        zIndex={10}
        {...props}>
        <StyledPopperContainer
          styles={styles}
          width={width}
          height={height}
          maxWidth={maxWidth}
          maxHeight={maxHeight}>
          {typeof children === 'function'
            ? children({hide: hideModal})
            : children}
        </StyledPopperContainer>
      </Popper>
    </>
  );
};

export const ResponsiveModal = props => {
  let {isVisible, hideModal, position, onModalHide, backdropColor, children} =
    props;
  if (!position) {
    position = isMobile ? 'screenBottom' : 'bottom';
  }

  if (position?.includes('screen') || position?.includes('Screen')) {
    return (
      <Modal
        avoidKeyboard
        useNativeDriver
        useNativeDriverForBackdrop
        isVisible={isVisible}
        onModalHide={onModalHide}
        backdropColor={backdropColor}>
        <ModalView {...props} position={position} hideModal={hideModal}>
          {children}
        </ModalView>
      </Modal>
    );
  } else {
    return <PopperComponent {...props} />;
  }
};
