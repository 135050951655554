import {useFetchTableData} from '@unthinkable/react-data-handler';
import {Table} from './Table';
import {useAppStateContext} from '../../providers/AppState';
import {useMemo} from 'react';

export const useMultiTableData = ({secondaryApiProps, ...props}) => {
  const {fetch} = useAppStateContext();
  const {modifyRow: modifySecondaryRow, ...aiProps} = secondaryApiProps || {};
  let {data, ...restFetchProps} = useFetchTableData({...props, fetch});
  let {data: secondaryData} = useFetchTableData({...aiProps, fetch});

  const mergeData = useMemo(() => {
    let modifiedData = data;
    if (secondaryData?.length) {
      let aiSuggestedModifications = [];
      let secondaryModifiedData = secondaryData
        .map(row => {
          let {suggestion} = row || {};
          if (typeof suggestion === 'string')
            suggestion = JSON.parse(suggestion);

          if (typeof modifySecondaryRow === 'function') {
            suggestion = modifySecondaryRow(suggestion || row);
          } else {
            suggestion = suggestion || row;
          }
          if (suggestion?.record_id) {
            aiSuggestedModifications.push({...suggestion});
            return false;
          }
          suggestion = {
            ...suggestion,
            aiGenerated: true,
          };
          return suggestion;
        })
        .filter(Boolean);
      if (aiSuggestedModifications.length) {
        modifiedData = modifiedData?.map(row => {
          if (row) {
            const aiSuggestion = aiSuggestedModifications.find(
              item => item._id === row._id,
            );
            if (aiSuggestion) {
              row = {...row, aiSuggestion};
            }
          }
          return row;
        });
      }
      if (secondaryModifiedData?.length) {
        modifiedData = [...secondaryModifiedData, ...(modifiedData || [])];
      }
    }
    return modifiedData;
  }, [secondaryData, data, modifySecondaryRow]);

  return {
    data: mergeData,
    ...restFetchProps,
  };
};

export const MultiDataTable = props => {
  const tableProps = useMultiTableData(props);
  return <Table {...tableProps} />;
};
