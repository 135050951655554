import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

const SwitchRenderer = ({value, option = [], ...props}) => {
  value = Boolean(value) ? 'True' : 'False';
  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} value={value} />
    </InputLabel>
  );
};

export default SwitchRenderer;
