import React from 'react';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';

const AccountTable = props => {
  let {navigation = {}, route = {}} = props || {};

  let {params = {}} = route || {};

  const filterProps = useFilter();

  const {searchValue, filterValues} = filterProps;
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Accounts"
          actions={[
            <AddButton title="Account" view="create-account" params={params} />,
            <SearchFilter {...filterProps} />,
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  type: 'autoComplete',
                  field: 'parent_account_id',
                  label: 'Parent',
                  api: '/accounts',
                  valueField: 'name',
                  suggestionField: 'name',
                },
                {
                  type: 'autoComplete',
                  field: 'account_group_type',
                  label: 'Account Type',
                  options: [
                    {
                      label: '<Empty>',
                      value: {$in: ['', null]},
                    },
                    {
                      label: 'Revenue',
                      value: 'Revenue',
                    },
                    {
                      label: 'assert',
                      value: 'assert',
                    },
                    {
                      label: 'expense',
                      value: 'expense',
                    },
                    {
                      label: 'liability',
                      value: 'liability',
                    },
                    {
                      label: 'revenue',
                      value: 'revenue',
                    },
                  ],
                  suggestionField: 'label',
                  valueField: 'label',
                  keyField: 'value',
                },
              ]}
            />,
          ]}
        />
      )}
      api={`/accounts`}
      search={searchValue}
      filter={filterValues.filter}
      params={params}
      eventSourceId="account"
      searchFields={['name']}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-account`, {
          row,
        });
      }}
      fields={{
        name: 1,
        is_bank: 1,
        is_child_account: 1,
        description: 1,
        account_group_type: 1,
        use_of_life: 1,
        depreciation_rate: 1,
        is_breakable: 1,
        mis_view: 1,
        mis_view_type: 1,
        parent_account_id: {
          name: 1,
        },
        depreciation_account: {
          name: 1,
        },
        department: {
          name: 1,
        },
      }}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
        },
        {
          field: 'parent_account_id.name',
          header: 'Parent',
          type: 'text',
        },
        {
          field: 'account_group_type',
          header: 'Type',
          type: 'text',
        },
      ]}
    />
  );
};

export const AccountsTabView = props => {
  const {route: {params} = {}} = props;
  const {product} = params;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues || {};
  filterParams = {...filterParams, ...props?.params};
  const tabs = {
    active: {
      label: 'Accounts',
      view: (
        <AccountTable
          {...props}
          searchValue={searchValue}
          params={params}
          addOnFilter={filter}
          filterParams={filterParams}
        />
      ),
      eventSourceId: 'account',
      api: `/accounts`,
      params: {
        ...params,
      },
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton
          title="Add Account"
          view="create-account"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

export default AccountTable;
