import {Platform} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';
import {IconButton} from '../button/IconButton';

export const CopyLink = ({
  link,
  title = 'Copy Link',
  message = 'Link has been copied to clipboard.',
}) => {
  const toast = useToast();
  const {CopyBlack} = useTheme('icons');
  if (Platform.OS !== 'web') {
    return null;
  }
  return (
    <IconButton
      icon={CopyBlack}
      title={title}
      onPress={() => {
        navigator.clipboard.writeText(link);
        toast({message});
      }}
    />
  );
};
