import React from 'react';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {AddCardStyles} from './theme';
import { useFetchData } from '../../controllers/useFetchData';

export const AddCard = ({title, arrowIcon, action, addAction, ...props}) => {
  const styles = useStyles(AddCardStyles);
  const {AddIcon, cardContainerStyle, titleTextStyle, countTextStyle} = styles;
  const {aggregates: {count} = {}} = useFetchData({
    aggregates: 'count',
    ...props,
  });
  return (
    <Row style={cardContainerStyle} onPress={action}>
      <Col gap={4} style={{flex: 1}}>
        <Text style={titleTextStyle}>{title}</Text>
        <Text style={countTextStyle}>{count || 0}</Text>
      </Col>
      {addAction ? (
        <TouchableOpacity onPress={addAction}>
          <Image source={AddIcon} />
        </TouchableOpacity>
      ) : (
        void 0
      )}
    </Row>
  );
};
