import { Row } from '@unthinkable/react-core-components';
import { getPreviousMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { MoreActions } from '../../../components/moreAction/MoreAction';
import { TabView } from '../../../components/tab/TabView';
import { useFilter } from '../../../controllers/useFilter';
import { ObjectWiseResources } from '../../common/actions/ObjectWiseResources';
import { ObjectWiseTasks } from '../../common/actions/ObjectWiseTasks';
import { EmailCampaignTableBeta } from './EmailCampaignTableNew';
import { EmailCampaignTabs } from './EmailCampaignTabs';
import { InmailCampaignTableBeta } from './InmailCampaignTableNew';
import { InmailCampaignTabs } from './InmailCampaignTabs';

export const OutreachBatchTabView = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const tabs = {
    active: {
      label: 'Inmail',
      view: <InmailCampaignTabs />,
      actions: [
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <ObjectWiseTasks
            {...props}
            params={params}
            object={{
              _id: '64d0fd55ab78dfead48c144b',
            }}
          />
          <AddButton
            title="Inmail Campaign"
            view="add-inmailcampaign"
            params={props?.route?.params}
          />
          <MoreActions
            actions={[
              {
                title: 'Import Inmail Prospects',
                onPress: () => {
                  navigation.navigate('import-list', {
                    ...props?.route?.params,
                    source: 'linkedin prospects',
                    model: 'PotentialCustomer',
                    inmailProspect: true,
                  });
                },
              },
              // {
              //   title: 'Import Inmail Responses',
              //   onPress: () => {
              //     navigation.navigate('import-list', {
              //       ...props?.route?.params,
              //       source: 'linkedin responses',
              //       model: 'PotentialCustomer',
              //     });
              //   },
              // },
              {
                title: 'Import Helper Prospects',
                onPress: () => {
                  navigation.navigate('import-list', {
                    ...props?.route?.params,
                    source: 'helper prospects',
                    model: 'PotentialCustomer',
                    inmailProspect: true,
                  });
                },
              },
              // {
              //   title: 'Import Helper Responses',
              //   onPress: () => {
              //     navigation.navigate('import-list', {
              //       ...props?.route?.params,
              //       source: 'helper responses',
              //       model: 'PotentialCustomer',
              //     });
              //   },
              // },
            ]}
          />
        </Row>,
      ],
    },
    completed: {
      label: 'Email',
      view: <EmailCampaignTabs />,
      actions: [
        <ObjectWiseResources
          {...props}
          params={params}
          object={{
            _id: '64d0fd55ab78dfead48c144a',
          }}
        />,
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64d0fd55ab78dfead48c144a',
          }}
        />,
        <AddButton
          title="Email Campaign"
          view="add-emailcampaign"
          params={props?.route?.params}
        />,
        // Add action to show woodpecker logs in Email Campaign - Parveen 07-03-2024
        <MoreActions
          actions={[
            {
              title: 'View woodpecker logs',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'woodpecker prospects',
                  model: 'PotentialCustomer',
                  woodpeckerEnabled: true,
                });
              },
            },
            {
              title: 'Import Email Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'email prospects',
                  model: 'PotentialCustomer',
                  emailProspect: true,
                });
              },
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OutreachBatchTabViewBeta = props => {
  const {route: {params} = {}, navigation} = props;
  const {
    onChangeFilter,
    searchValue,
    applyFilter,
    filterValues = {},
  } = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams} = filterValues || {};

  const tabs = {
    active: {
      label: 'Inmail',
      view: (
        <InmailCampaignTableBeta
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
        />
      ),
      actions: [
        <Row
          gap={8}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Inmail Campaign"
            view="add-inmailcampaign"
            params={props?.route?.params}
          />
          <MoreActions
            actions={[
              {
                title: 'Import Inmail Prospects',
                onPress: () => {
                  navigation.navigate('import-list', {
                    ...props?.route?.params,
                    source: 'linkedin prospects',
                    model: 'PotentialCustomer',
                  });
                },
              },
              {
                title: 'Import Inmail Responses',
                onPress: () => {
                  navigation.navigate('import-list', {
                    ...props?.route?.params,
                    source: 'linkedin responses',
                    model: 'PotentialCustomer',
                  });
                },
              },
            ]}
          />
        </Row>,
      ],
    },
    email: {
      label: 'Email',
      view: (
        <EmailCampaignTableBeta
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
        />
      ),
      actions: [
        <ObjectWiseResources
          {...props}
          params={{...params, ...filterParams}}
          object={{
            _id: '64d0fd55ab78dfead48c144a',
          }}
        />,
        <ObjectWiseTasks
          {...props}
          params={{...params, ...filterParams}}
          object={{
            _id: '64d0fd55ab78dfead48c144a',
          }}
        />,
        <AddButton
          title="Email Campaign"
          view="add-emailcampaign"
          params={props?.route?.params}
        />,
      ],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'assigned_to',
            },
          ]}
        />,
      ]}
    />
  );
};
