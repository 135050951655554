import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';

export const FinetuneDocumentList = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {row} = params;

  const {editFinetuneDocument, deleteFineTuneDocument} = leadControllers(props);

  return (
    <Table
      api={`/finetunedocuments`}
      eventSourceId={['addDocument', 'removeDocument']}
      filter={{
        usecase_id: row._id,
      }}
      fields={{
        messages: {role: 1, content: 1},
        createdAt: 1,
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          header: 'Messages',
          type: 'textArea',
          field: 'messages',
        },
        {
          header: 'Date',
          type: 'date',
          field: 'createdAt',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editFinetuneDocument,
        },
        {
          title: 'Delete',
          onPress: deleteFineTuneDocument,
        },
      ]}
      {...props}
    />
  );
};
