import React from 'react';
import {Col, Image, Row, Text, View} from '@unthinkable/react-core-components';
import {WithModal} from '@unthinkable/react-popper';
import {isMobile} from '@unthinkable/react-utils';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {appModuleDropdownStyles} from './theme';
import {
  CardContainer,
  BreadCrumbItemContainer,
  BreadCrumbChildItemContainer,
  BreadCrumbIconContainer,
  BreadCrumbTextContainer,
} from './styles';

export const AppModuleDropdown = props => {
  const {
    options,
    value,
    onLinkPress,
    onChangeValue,
    maxWidth,
    suggestionField,
    valueField,
    secondarySuggestionField,
  } = props;

  if (!(Array.isArray(options) || options.length)) {
    return null;
  }
  const {
    modalStyles,
    name,
    desc,
    iconContainer,
    icon,
    DownArrow,
    selectedIconContainer,
    containerHoverColor,
    itemHoverColor,
    title,
    ...rest
  } = useStyles(appModuleDropdownStyles);
  const icons = useTheme('icons');

  let width = 310;
  if (options?.length > 7 && options.length <= 14) {
    width = 620;
  } else if (options?.length > 14) {
    width = 910;
  }

  return (
    <WithModal
      position={isMobile ? 'screenCenter' : void 0}
      styles={modalStyles}
      width={isMobile ? void 0 : width}
      renderModal={({hide}) => {
        return (
          <>
            {options.map(option => {
              let isSelected = option?.id === value?.id;
              return (
                <CardContainer
                  styles={rest}
                  isSelected={isSelected}
                  onPress={() => {
                    onChangeValue?.(option);
                    hide();
                  }}>
                  <Row style={{padding: 12, gap: 10, alignItems: 'center'}}>
                    <View
                      className="imageContainer"
                      style={
                        isSelected ? selectedIconContainer : iconContainer
                      }>
                      <Image style={icon} source={icons[option?.icon]} />
                    </View>
                    <Col style={{flex: 1, overflow: 'hidden'}}>
                      <Text style={name}>{option?.[suggestionField]}</Text>
                      <Text
                        title={option?.[secondarySuggestionField]}
                        style={desc}
                        numberOfLines={2}>
                        {option?.[secondarySuggestionField]}
                      </Text>
                    </Col>
                  </Row>
                </CardContainer>
              );
            })}
          </>
        );
      }}>
      <BreadCrumbItemContainer hoverColor={containerHoverColor} gap={1}>
        <BreadCrumbChildItemContainer
          onPress={onLinkPress}
          hoverColor={itemHoverColor}
          className="breadcrumbTextContainer">
          <BreadCrumbTextContainer
            title={value?.[valueField]}
            styles={{maxWidth}}
            className="child">
            <Text numberOfLines={1} style={title}>
              {value?.[valueField]}
            </Text>
          </BreadCrumbTextContainer>
        </BreadCrumbChildItemContainer>
        <BreadCrumbChildItemContainer
          hoverColor={itemHoverColor}
          className="breadcrumbIconContainer">
          <BreadCrumbIconContainer className="child">
            <Image source={DownArrow} />
          </BreadCrumbIconContainer>
        </BreadCrumbChildItemContainer>
      </BreadCrumbItemContainer>
    </WithModal>
  );
};
