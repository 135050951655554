import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const InterviewForm = props => {
  const {
    route: {params},
    header = 'Interview Details',
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    ...props,
  });
  const {department, product, row} = params;

  return (
    <Form
      api={`/staffaugmentationopportunities`}
      fields={{
        customer_id: {name: 1},
        potential_customer: {_id: 1, name: 1},
        opportunity_id: {name: 1, skill: 1, requirement: 1},
        interview_date: 1,
        outcome_description: 1,
        outcome_rejection_reason: 1,
        description: 1,
        outcome_date: 1,
        resource: {name: 1},
        mentor: {name: 1},
      }}
      filter={{
        _id: row?._id,
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        department: department?._id,
        product: product?._id,
        ...row,
      }}
      layoutFields={[
        {
          placeholder: 'Customer',
          label: 'Customer',
          field: 'customer_id',
          type: 'autoComplete',
          api: `/customers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: ({values}) => (values?.customer_id ? true : false),
        },
        {
          placeholder: 'Potentail Customer',
          label: 'Potential Customer',
          field: 'potential_customer',
          type: 'autoComplete',
          api: `/potentialcustomers`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          visible: ({values}) => (values?.potential_customer ? true : false),
        },

        {
          placeholder: 'Requirement',
          field: 'opportunity_id.requirement',
          type: 'text',
        },

        {
          placeholder: 'Resource',
          label: 'Resource',
          field: 'resource',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        // {
        //   placeholder: 'Experience',
        //   label: 'Experience',
        //   field: 'resource.experience',
        //   type: 'autoComplete',
        //   api: '/employeeexperiences',
        //   suggestionField: 'experience',
        //   valueField: 'experience',
        //   required: true, //??
        // },

        {
          placeholder: 'Trainer',
          label: 'Trainer',
          field: 'mentor',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        // {
        //   placeholder: 'Skills',
        //   label: 'Skills',
        //   field: 'opportunity_id.skill',
        //   type: 'multiAutoComplete',
        //   api: '/skills',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   required: true,
        // },
        {
          placeholder: 'Interview Date',
          field: 'interview_date',
          type: 'date',
          visible: ({values}) => (values?.interview_date ? true : false),
        },
        {
          placeholder: 'Outcome Description',
          field: 'outcome_description',
          type: 'text',
          visible: ({values}) => (values?.outcome_description ? true : false),
        },
        {
          placeholder: 'Outcome Description',
          field: 'outcome_rejection_reason',
          type: 'text',
          visible: ({values}) =>
            values?.outcome_rejection_reason ? true : false,
        },
        {
          placeholder: 'Outcome Date',
          field: 'outcome_date',
          type: 'date',
          visible: ({values}) => (values?.outcome_date ? true : false),
        },
      ]}
      {...props}
    />
  );
};

export const EditInterview = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {potential_customer} = row;
  return (
    <InterviewForm
      mode="edit"
      header={{
        title: 'Edit Interview',
        secondaryTitle: potential_customer?.name,
      }}
      {...props}
    />
  );
};
