import React from 'react';
import {
  ContainerImageViewerStyles,
  ImagePaginationStyles,
  ImageViewerStyles,
  OpenInBrowseButtonStyles,
  ZoomImageStyles,
} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {ImageViewer as ImageViewerComponent} from '@unthinkable/react-image-viewer';
import {useAppStateContext} from '../../providers/AppState';
import { MultipleAttachmentPreviewStyles } from '../form-editors/file/theme';

export const ImageViewer = props => {
  let {resourceUrl} = useAppStateContext();
  const ImageViewerStyle = useStyles(ImageViewerStyles);
  const ContainerImageViewerStyle = useStyles(ContainerImageViewerStyles);
  const ZoomImageStyle = useStyles(ZoomImageStyles);
  const OpenInBrowseButtonStyle = useStyles(OpenInBrowseButtonStyles);
  const ImagePaginationStyle = useStyles(ImagePaginationStyles);
  const multipleAttachmentsPreviewTheme = useStyles(MultipleAttachmentPreviewStyles);

  return (
    <ImageViewerComponent
      {...ImageViewerStyle}
      ContainerImageViewerStyle={ContainerImageViewerStyle}
      ZoomImageStyle={ZoomImageStyle}
      OpenInBrowseButtonStyle={OpenInBrowseButtonStyle}
      ImagePaginationStyle={ImagePaginationStyle}
      multipleAttachmentsPreviewTheme={multipleAttachmentsPreviewTheme}
      resourceUrl={resourceUrl}
      {...props}
    />
  );
};
