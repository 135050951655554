import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

const CurrencyRenderer = ({value, ...props}) => {
  if (value) {
    value = `${value?.amount || ''} ${value?.currency || ''}`;
  }

  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} value={value} />
    </InputLabel>
  );
};

export default CurrencyRenderer;
