import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useAppStateContext} from '../../../providers/AppState';
import Barcode from 'react-jsbarcode';

export const TaggedAssetTable = props => {
  const {route: {params = {}} = {}} = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  const {row} = params;
  return (
    <Table
      eventSourceId={['NewITAsset', 'AssetRegister']}
      renderHeader={() => {
        return <TableHeader title="Tagged Assets" />;
      }}
      api={`/assetinventories`}
      fields={{
        asset_type: 1,
        code: 1,
        brand: 1,
        model: 1,
        specification: 1,
        taged_with: {code: 1},
        code: 1,
      }}
      addOnFilter={{
        taged_with: row?._id,
        assigned_to: employee._id,
      }}
      columns={[
        {
          header: 'Asset Details',
          field: 'code',
        },
        {
          header: 'Asset Type',
          type: 'colorTag',
          field: 'asset_type',
        },
        {
          header: 'Brand',
          type: 'colorTag',
          field: 'brand',
        },
        {
          header: 'Model',
          type: 'colorTag',
          field: 'model',
        },
        {
          header: 'Specification',
          type: 'colorTag',
          field: 'specification',
        },
        // {
        //   header: 'Bar Code',
        //   align: 'center',
        //   width: 250,
        //   render: ({row, styles: {rowText = {}}}) => {
        //     return (
        //       <Barcode
        //         value={row?.code}
        //         renderer="image"
        //         style={{
        //           height: 80,
        //         }}
        //       />
        //     );
        //   },
        // },
      ]}
    />
  );
};
