import React from 'react';

import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

import {TextRenderer} from '../../../components/form/Renderers';
import {messageControllers} from '../outreachcampaign-controllers/OutreachCampaignMessageControllers';
import {leadControllers} from '../controllers/leadControllers';

export const OutreachCampaignContactMessageTable = props => {
  const {editCampaignContactMessage} = messageControllers(props);
  const {
    updatePersonalizeMessage,
    sendContactConnectionRequest,
    readContactConnectionRequest,
    sendContactMessageThroughUrl,
    readContactReceivedMessageByUrl,
    scheduledActions,
    sendContactFollowed,
    campaignActionSendEmail,
    campaignActionTestEmail,
    deleteCampaignActionRecord,
    sendParticularAction
  } = leadControllers(props);
  const {
    route: {
      params: {campaign_id, contact_id},
    },
  } = props;

  const invoke = useInvoke({method: 'put', eventSourceId: ['indexUpdated']});
  return (
    <Table
      {...props}
      fields={{
        _id: 1,
        name: 1,
        body: 1,
        sequence: 1,
        sequence_display: 1,
        send_after: 1,
        action: 1,
        personalize_body: 1,
        linkedin_profile: {email: 1},
        contact_id: {linkedin_url: 1},
        status: 1,
        schedule_without_message: 1,
        completedOn: 1,
      }}
      api="/campaignactions"
      filter={{
        campaign_id: campaign_id._id,
        contact_id: contact_id._id,
      }}
      eventSourceId={['indexUpdated', 'popuateMessage']}
      sort={{
        sequence_display: 1,
      }}
      columns={[
        {
          width: 30,
          field: 'sequence_display',
          type: 'number',
        },

        {
          field: 'name',
          type: 'text',
          header: 'Name',
          width: 200,
        },
        {
          field: 'action',
          type: 'text',
          header: 'Action',
          width: 200,
        },
        // {
        //   field: 'body',
        //   type: 'textArea',
        //   header: 'Body',
        // },
        // {
        //   field: 'personalize_body',
        //   type: 'textArea',
        //   header: 'Personalized Body',
        // },
        // {
        //   field: 'linkedin_profile.email',
        //   type: 'text',
        //   header: 'Sender Email',
        //   width: 150,
        // },
        // {
        //   field: 'contact_id.linkedin_url',
        //   type: 'text',
        //   header: 'To',
        //   width: 200,
        // },
        // {
        //   field: 'send_after',
        //   type: 'number',
        //   header: 'Delay',
        //   width: 100,
        // },
        {
          field: 'status',
          type: 'text',
          header: 'Status',
          width: 100,
        },
        {
          header: 'Action Date',
          type: 'date',
          field: 'completedOn',
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editCampaignContactMessage,
        },
        // {
        //   title: 'Personalize message',
        //   onPress: updatePersonalizeMessage,
        // },
        {
          title: 'Send Action',
          onPress: sendParticularAction,
        },
        {
          title: 'Read message',
          onPress: readContactReceivedMessageByUrl,
        },
        {
          title: 'Read connection request',
          onPress: readContactConnectionRequest,
        },
        {
          title: 'Test Email',
          visible: ({row}) => {
            if (row?.status === 'pending' && row?.action === 'email') {
              return true;
            }
            return false;
          },
          onPress: campaignActionTestEmail,
        },

        {
          title: 'Schedule Action',
          onPress: scheduledActions,
        },
        {
          title: 'Verify Message',
          variant: 'success',
          confirm: {
            title: 'Verify',
            message: 'Are you sure you want to verify this message?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/campaignactions/${row?._id}`,
              props: {
                verified: true,
              },
            });
          },
        },
        {
          title: 'Schedule Without Message',
          variant: 'success',
          confirm: {
            title: 'Schedule Without Message',
            message: 'Are you sure you want to schedule without message?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/campaignactions/${row?._id}`,
              props: {
                verified: true,
                schedule_without_message: true,
              },
            });
          },
          visible: ({row}) => {
            if (row?.schedule_without_message) {
              return false;
            }
            return true;
          },
        },
        {
          title: 'Delete',
          onPress: deleteCampaignActionRecord,
        },
      ]}
    />
  );
};
