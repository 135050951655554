import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';

export const PracticeTable = props => {
    const {
        route: { params },
        navigation,
    } = props;
    return (
        <Table
            eventSourceId={['practice']}
            api={`/practices`}
            fields = {
                {
                    name : 1,
                    owner : {name : 1}
                }
            }
            renderHeader={() => {
                return (
                    <TableHeader
                        title="Practice"
                        actions={[
                            <AddButton
                                title="Add Practice"
                                view="add-practice"
                                params={params}
                            />,
                        ]}
                    />
                );
            }}
            onRowPress={props => {
                const {row} = props;
                navigation.navigate(`edit-practice`, {
                  row,
                });
              }}
            columns={[
                {
                    type: 'text',
                    header: 'Name',
                    field: 'name',
                },
                {
                    type: 'text',
                    header: 'Owner',
                    field: 'owner.name',
                }
            ]}
        />
    );
};
