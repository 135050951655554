import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const KeyPillarForm = props => {
  const {
    route: { params },
  } = props;
  const { row } = params;
  let { onSubmit } = useFormSubmit({
    uri: '/keypillars',
    eventSourceId: 'keyPillar',
  });
  return (
    <Form
      api={`/keypillars/${row?._id}`}
      fields={{
        title: 1,
        type: 1,
        tags: 1,
        header: {
          text: 1,
          color: 1,
        },
        index: 1,
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },
        {
          label: 'Type',
          type: 'autoComplete',
          options: ['Frontend', 'Backend', 'Module'],
          field: 'type',
          required: true,
        },
        {
          label: 'Tags',
          type: 'multiText',
          field: 'tags',
        },
        {
          label: 'Header',
          field: 'header',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              fields={[
                {
                  header: 'Text',
                  field: 'text',
                  type: 'text',
                  placeholder: 'text',
                  required: true,
                },
                {
                  label: 'Color',
                  field: 'color',
                  placeholder: 'Color',
                  type: 'autoComplete',
                  options: ['black', 'green'],
                  required: true,
                },
              ]}
              columns={[
                {
                  header: 'Text',
                  field: 'text',
                  placeholder: 'Text',
                  type: 'text',
                },
                {
                  header: 'Color',
                  field: 'color',
                  placeholder: 'Color',
                },
              ]}
            />
          ),
        },
        {
          label: 'Index',
          type: 'number',
          field: 'index',
        },
      ]}
      {...props}
    />
  );
};

export const AddKeyPillarForm = props => {
  return <KeyPillarForm header="Add Key Pillar" {...props} />;
};

export const UpdateKeyPillarForm = props => {
  return (
    <KeyPillarForm mode="edit" header="Update Key Pillar" {...props} />
  );
};
