import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';
import moment from 'moment';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';
import {useTheme} from '@unthinkable/react-theme';
import { TextRenderer } from '../../../components/form/Renderers';

export const ExpenseVarianceReport = props => {
  const filterProps = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {filterValues} = filterProps;
  const {download} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);
  const {colors} = useTheme();
  return (
    <Table
      params={filterValues.params}
      filter={filterValues.filter}
      renderHeader={() => (
        <TableHeader
          title="Expense Variance Report"
          actions={[
            // <PeriodRangeFilter {...filterProps} options={[]} skipQuarter />,
            <GroupFilter
              {...filterProps}
              filters={[
                // {
                //   type: 'multiAutoComplete',
                //   field: 'organization',
                //   label: 'Organization',
                //   api: '/organizations',
                //   valueField: 'name',
                //   suggestionField: 'name',
                // },
                {
                  type: 'multiAutoComplete',
                  field: 'account',
                  label: 'Account',
                  api: '/accounts',
                  valueField: 'name',
                  suggestionField: 'name',
                  filter: {
                    is_summary_required: true,
                  },
                  asParam: true,
                },
              ]}
            />,
            <Button
              text="Download"
              onPress={() => {
                download({
                  uri: `/exportExcelHelper`,
                  props: {
                    props: {
                      fetchProps: {
                        api: '/expenseVariationReport',
                        params: filterValues.params,
                        filter: filterValues.filter,
                        globalParams: globalParams,
                      },
                      column: {
                        Account: 'account.name',
                        'Current Closing': 'current_closing',
                        'Previous Closing': 'previous_closing',
                        Variance: 'variance',
                      },
                      filename: `Expense Variance Report(${moment(
                        filterValues.params.period.from,
                      ).format("MMM'YY")}vs${moment(
                        filterValues.params.period.from,
                      )
                        .subtract(1, 'months')
                        .format("MMM'YY")})`,
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      api="/expenseVariationReport"
      columns={[
        {header: 'Account', field: 'account.name', type: 'text'},
        {
          header: 'Previous',
          field: 'previous_closing',
          type: 'number',
          width: 250,
        },
        {
          header: 'Current',
          field: 'current_closing',
          type: 'number',
          width: 250,
        },
        {
          header: 'Variance',
          field: 'variance',
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.variance >= 0 ? colors.ERROR_HIGH : colors.SUCCESS_HIGH,
              }}
              value={Number(Math.abs(row.variance)).toFixed(0)}
            />
          ),
          width: 250,
          align:'right',
        },
      ]}
      {...props}
    />
  );
};
