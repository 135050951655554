import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useInvoke} from '../../../controllers/useInvoke';
import {TabView} from '../../../components/tab';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {AddButton} from '../../../components/button/AddButton';
import {Tag} from '../../../components/chip/Chip';

export const MasterTaskTable = props => {
  const {
    navigation,
    route: {params},
    addOnFilter,
  } = props;
  const {row, ...restParams} = params;
  const updateMasterTaskCheckList = ({row}) => {
    navigation.navigate('update-master-task-checklist', {
      ...params,
      row,
    });
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'MasterTask',
    close: false,
  });

  const isCompleted = addOnFilter?.status === 'Completed';
  return (
    <Table
      eventSourceId={['MasterTask', 'MasterTaskAssignment']}
      api={`/masterTasks`}
      filter={{
        parent_model: row?._id,
        parent_model_relation: 'Event',
        ...addOnFilter,
      }}
      sort={{index: 1, createdAt: 1}}
      fields={{
        task: 1,
        output_model: {name: 1, label: 1},
        output_model_id: {name: 1},
        parent_model: {name: 1, label: 1, color: 1},
        parent_model_relation: 1,
        event_on: 1,
        acceptance_criteria: 1,
        master_task_assignment_count: {
          _id: 1,
        },
        master_task_assignment_completed_count: {
          _id: 1,
        },
        status: 1,
        event_field: 1,
        event_field_value: 1,
      }}
      onRowPress={({row}) => {
        navigation.navigate(`edit-master-task`, {
          ...restParams,
          row,
        });
      }}
      columns={[
        {
          type: 'text',
          header: 'Tasks',
          render: ({row}) => {
            let {task} = row;
            return <PrimaryColumnCard primaryTitle={task} />;
          },
        },
        {
          header: 'Relation',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {event_on} = row;
            let color = 'ACCENT3';
            if (event_on === 'Insert') {
              color = 'ACCENT5';
            } else {
              color = 'ACCENT6';
            }
            return <Tag color={color} value={event_on} />;
          },
        },
        {
          type: 'colorTag',
          colorField: 'output_model.color',
          field: 'output_model.label',
          header: 'Output',
          width: 200,
        },
        {
          render: CountCellRender,
          isNestedObject: true,
          count_field: 'acceptance_criteria.length',
          header: 'Checklist',
          onPress: isCompleted ? void 0 : updateMasterTaskCheckList,
          visible: () => row?.task_assignment_type === 'Product',
          align: 'center',
          width: 100,
        },
      ]}
      moreActions={[
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Mark Complete',
            message:
              'Completed Tasks will not be added to workload from upcoming month.',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/mastertasks/${row?._id}`,
              props: {
                status: 'Completed',
              },
            });
          },
          visible: ({row}) =>
            row?.status == 'Active' && row?.task_assignment_type === 'Product',
        },
        {
          title: 'Mark Active',
          visible: ({row}) =>
            row?.status == 'Completed' &&
            row?.task_assignment_type === 'Product',
          onPress: ({row}) => {
            invoke({
              uri: `/mastertasks/${row?._id}`,
              props: {
                status: 'Active',
              },
            });
          },
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
        },
      ]}
    />
  );
};

export const MasterTaskTabs = props => {
  const {
    route: {params},
  } = props;
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'Active',
          view: <MasterTaskTable addOnFilter={{status: 'Active'}} />,
          actions: [
            <AddButton
              {...props}
              title={'Task'}
              view="add-master-task"
              params={params}
            />,
          ],
        },
        completed: {
          label: 'Completed ',
          view: <MasterTaskTable addOnFilter={{status: 'Completed'}} />,
        },
      }}
    />
  );
};
