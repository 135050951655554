import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const ModuleMenuRights = props => {
  const {
    route: {params},
    navigation,
    searchValue,
    tabFilter = {},
  } = props;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'moduleMenuRights',
  });

  return (
    <Table
      eventSourceId={'moduleMenuRights'}
      api={`/modulemenurights`}
      fields={{
        entity: {
          name: 1,
        },
        entity_id: {
          name: 1,
        },
        module_menu: {
          menu: 1,
        },
        roles: 1,
        scope: 1,
      }}
      onRowPress={({row}) => {
        navigation.navigate('edit-module-menu-rights', {row});
      }}
      renderHeader={() => (
        <TableHeader
          actions={[
            <AddButton
              title="Module Menu Rights"
              view="add-module-menu-rights"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      filter={{
        module_menu: params?.row?._id,
      }}
      columns={[
        {
          header: 'Entity',
          field: 'entity.name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Entity Name',
          field: 'entity_id.name',
          type: 'text',
          width: 200,
        },
        // {
        //   header: 'Menu',
        //   field: 'module_menu.menu',
        //   type: 'text',
        //   width: 200,
        // },
        {
          header: 'Roles',
          field: 'roles',
          type: 'text',
          width: 200,
        },
        {
          header: 'Scope',
          field: 'scope',
          type: 'text',
          width: 200,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-module-menu-rights', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/modulemenurights/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default ModuleMenuRights;
