import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const UpdateCalculationTypeForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/incometaxes`,
    eventSourceId: ['taxDeclarationAccounts', 'taxDeclaration'],
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Calculation Type'}
      api={`/taxDeclarationDetail/${row?._id}`}
      mode="edit"
      submitAction={'save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'calculation_type',
          options: [
            {
              label: 'New Regime Under Section 115',
              value: 'New Regime Under Section 115',
            },
            {
              label: 'Old Regime',
              value: 'Old Regime',
            },
          ],
          required: true,
        },
      ]}
    />
  );
};
