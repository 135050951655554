import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';


const ESIReportTable = props => {
    const { filterParams, params } = props;
    return (
        <Table
            {...props}
            api={'/getesireportdata'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="esireport"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'employee.name',
                    header: 'Employee',
                    minWidth: 200,
                },
                {
                    field: 'employee.employee_code',
                    header: 'Employee Code.',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    field: "invoiceDate",
                    header: 'Invoice Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 160
                },
                {
                    field: 'employee.adhar_number',
                    header: 'Adhaar umber',
                    type: 'text',
                    width: 140,
                },
                {
                    type: 'text',
                    header: "Father's Name",
                    field: "employee.father_name",
                    width: 140,
                },
                {
                    type: 'text',
                    header: "Marital Status",
                    field: "employee.marital_status",
                    width: 140,
                },
                {
                    field: 'employee.gender',
                    header: 'Gender',
                    type: 'text',
                    width: 180,
                },
                {
                    field: 'gross_amount',
                    type: 'currency',
                    header: 'Gross Amount',
                    width: 140,
                },
                {
                    field: 'payable_amount',
                    type: 'currency',
                    header: 'Payable Amount',
                    width: 140,
                },
                {
                    field: 'employee_amount',
                    header: 'ESI Employee',
                    type: 'text',
                    width: 180,
                },
                {
                    field: 'employer_amount',
                    header: 'ESI Employer',
                    type: 'text',
                    width: 180,
                },
                {
                    field: 'employee.dob',
                    header: 'DOB',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 180,
                },
                {
                    field: 'employee.joining_date',
                    header: 'Joining Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 180,
                },
                {
                    field: 'employee.relieving_date',
                    header: 'Relieving Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 180,
                },
                {
                    field: 'total_salary_days',
                    header: 'Salary Days',
                    type: 'number',
                },
            ]}
        />
    );
};

export const ESIReportTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'ESI Report',
            view: (
                <ESIReportTable filterParams={filter} {...props} />
            ),
            eventSourceId: 'esireport',
            addOnFilter: filter,
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportesireportdata',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        }
                    ]}
                />,
            ]}
        />
    );
};
