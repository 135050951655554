import React, {useEffect, useRef, useState} from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './jsonInput.css';
import {InputWrapper} from '@unthinkable/react-input';
import {View} from '@unthinkable/react-core-components';
import {useColorMode} from '@unthinkable/react-theme';

const JsonInput = ({
  value,
  setValue,
  mode = 'text',
  height = 400,
  className,
}) => {
  const editorRef = useRef(null);
  const jsonEditorRef = useRef(null);
  useEffect(() => {
    jsonEditorRef.current = new JSONEditor(editorRef.current, {
      mode,
      onChange: () => {
        const updatedJson = jsonEditorRef.current.get();
        setValue(updatedJson);
      },
    });
    jsonEditorRef.current.set(value);
  }, []);

  return <View className={className} ref={editorRef} style={{height}}></View>;
};

export const JsonFormComponent = props => {
  let {value: propValue = {}, onChangeValue, mode, height} = props;
  const [value, setValue] = useState(propValue);
  const {colorMode} = useColorMode();

  useEffect(() => {
    onChangeValue && onChangeValue(value);
  }, [value]);

  return (
    <InputWrapper {...props}>
      <JsonInput
        className={colorMode === 'dark' ? 'dark-mode' : 'light-mode'}
        setValue={setValue}
        value={propValue}
        mode={mode}
        height={height}
      />
    </InputWrapper>
  );
};
