import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

export const RevenueTable = props => {
  const params = props.route.params;
  return (
    <Table
      api={`/customerRevenueInvoices`}
      renderHeader={() => <TableHeader title="Customer Revenue" />}
      params={params}
      columns={[
        {
          type: 'date',
          field: 'invoice_date',
          header: 'Date',
        },
        {
          type: 'text',
          field: 'invoice_number',
          header: 'Invoice Number',
        },
        {
          type: 'text',
          field: 'status',
          header: 'Status',
        },
        {
          type: 'currency',
          field: 'base_invoice_amount',
          header: 'Amount',
          aggregate: true,
        },
        {
          type: 'currency',
          field: 'base_total_invoice_amount',
          header: 'Total Base Amount',
          aggregate: true,
        },
        {
          type: 'currency',
          field: 'total_invoice_amount',
          header: 'Total Amount',
          aggregate: true,
        },
      ]}
    />
  );
};
