import React from 'react';
import {RecursiveTableV2, Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {InlineRepositoryFolderPathForm} from './RepositoryFolderPathForm';
import {RecursiveRender} from '@unthinkable/react-table';
import {Image, View} from '@unthinkable/react-core-components';
import {SplitScreen, useSplitScreen} from '../../../components/SplitScreen';
import {UpdateFileNameForm} from './FileNameForm';
import {CodeEditor} from './CodeEditor';
import {useTheme} from '@unthinkable/react-theme';

export const RepositoryFileTable = props => {
  const {
    route: {params},
  } = props;

  const {folderPath} = params;

  return (
    <Table
      eventSourceId={['RepositoryFolderPath']}
      api={`/folderFiles`}
      fields={{
        name: 1,
      }}
      filter={{
        folder_path_id: folderPath?._id,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
      ]}
    />
  );
};

const RepositoryFolderPathTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {Code} = useTheme('icons');

  const {project, repository_id} = params;
  const {toggleSplitScreen} = useSplitScreen();
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'RepositoryFolderPath',
  });

  return (
    <RecursiveTableV2
      eventSourceId={['RepositoryFolderPath', 'FileName']}
      api={`/grouprepositoryfolderpaths`}
      renderHeader={() => {
        if (props?.selectedId) {
          return;
        }
        return (
          <InlineRepositoryFolderPathForm
            {...props}
            defaultValues={{repository_id, project_id: project?._id}}
          />
        );
      }}
      filter={{
        project_id: project?._id,
        repository_id,
      }}
      defaultExpanded={true}
      onRowPress={({row}) => {
        row?.itemType === 'file' &&
          toggleSplitScreen({
            ...params,
            row,
            folder_path_id: row?.folder_path_id,
            file_name: row?.name,
          });
      }}
      columns={[
        {
          header: 'name',
          field: 'name',
          render: props => <RecursiveRender {...props} />,
          indent: false,
      },
        {
          width: 50,
          render: ({row}) => {
            if (row?.itemType !== 'file') {
              return;
            }
            return <Image style={{height: 16, width: 16}} source={Code} />;
          },
        },
        // {
        //   field: 'path',
        //   header: 'Folder Path',
        // },
        // {
        //   type: 'text',
        //   field: 'type',
        //   header: 'Folder Type',
        //   width: 200,
        //   value: ({row}) =>
        //     folderTypeOptions.find(x => x.value === row?.type)?.label,
        // },
        // {
        //   header: 'Files',
        //   field: 'file_count',
        //   type: 'colorTag',
        //   align: 'center',
        //   width: 150,
        //   onPress: ({row}) => {
        //     navigation.navigate('file-path-table', {
        //       ...params,
        //       folderPath: row,
        //     });
        //   },
        // },
      ]}
      moreActions={[
        {
          title: 'Add File',
          onPress: ({row}) => {
            navigation.navigate('add-file-name', {
              ...params,
              defaultValues: {folder_path_id: row?.folder_path_id},
            });
          },
          visible: ({row}) => {
            return row?.folder_path_id && row?.itemType === 'folder';
          },
        },
        {
          title: 'Edit',
          onPress: ({row}) => {
            if (row?.itemType === 'folder') {
              navigation.navigate('update-repository-folder-path', {
                ...params,
                row: {
                  _id: row?.folder_path_id,
                  repository_id: row?.repository_id,
                  name: row?.folderName,
                  type: row?.folderType,
                  path: row?.folderPath,
                },
              });
            } else if (row?.itemType === 'file') {
              navigation.navigate('update-file-name', {
                ...params,
                row,
              });
            }
          },
          visible: ({row}) => {
            return row?.folder_path_id;
          },
        },
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            if (row?.itemType === 'folder') {
              deleteInvoke({
                uri: `/repositoryfolderpaths/${row?.folder_path_id}`,
                props: {},
              });
            } else if (row?.itemType === 'file') {
              deleteInvoke({
                uri: `/folderfiles/${row?._id}`,
                props: {},
              });
            }
          },
          visible: ({row}) => {
            return row?.folder_path_id;
          },
        },
      ]}
    />
  );
};

export const RepositoryFolderPathScreen = props => {
  return (
    <SplitScreen
      sideScreenWidth={'70%'}
      splitScreen={<CodeEditor {...props} />}>
      <RepositoryFolderPathTable {...props} />
    </SplitScreen>
  );
};
