import {
  ActivityIndicator,
  Row,
  Text,
  View,
} from '@unthinkable/react-core-components';
import moment from 'moment';
import {useState} from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Confirm} from '../../../components/confirm/Confirm';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';

export const UserFieldWithDateChip = ({
  field,
  dateField,
  size = 27,
  ...props
}) => {
  const {row} = props;
  let value = row[field];
  let dateValue = row[dateField];
  if (!dateValue) dateValue = void 0;
  else dateValue = moment(dateValue).format('DD MMM ');

  if (!value) {
    return null;
  }
  let subTitle = `${dateValue}`;

  if (!dateValue) subTitle = '';

  const {name, color} = value;
  return (
    <Row gap={7} style={{alignItems: 'center'}}>
      <PrimaryColumnCard
        primaryTitle={value}
        items={[
          {
            value: subTitle,
          },
        ]}
      />
    </Row>
  );
};

const submitTaxDeclaration = ({invoke}) => ({
  title: 'Submit Declaration',
  confirm: {
    title: 'Submit Declaration',
    message: 'Do you want submit the tax?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/incometaxes/${row?._id}`,
      props: {
        status: 'Submitted',
      },
    });
  },
});

const RenderStatus = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'exitRequest',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  if (row.resign_status === ('Accepted' || 'Rejected')) {
    return;
  }
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Confirm
            title="Approve Resignation"
            message="Are you sure you want to Approve this Resignation?"
            onConfirm={() => {
              invoke({
                uri: `/employees/${row._id}`,
                eventSourceId: 'TeamResignedEmployee',
                props: {
                  resign_status: 'Accepted',
                  resign_accepted_on: getZeroTimeDate(new Date()),
                },
              });
            }}>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}>
              <Text style={{...rowText, color: '#34C759'}}>Approve </Text>
            </View>
          </Confirm>
          <Confirm
            title="Reject Resignation"
            message="Are you sure you want to Reject this Resignation?"
            onConfirm={() => {
              invoke({
                uri: `/employees/${row._id}`,
                props: {
                  resign_status: 'Rejected',
                  eventSourceId: 'TeamResignedEmployee',
                  applied_for_resignation: false,
                  resign_rejected_on: getZeroTimeDate(new Date())
                },
              });
            }}>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}>
              <Text style={{...rowText, color: '#FF5247'}}>Reject </Text>
            </View>
          </Confirm>
        </>
      )}
    </View>
  );
};

export const TeamResignedTable = props => {
  let {
    route: {params},
  } = props;
  let addOnFilter = {
    employee_status: 'Active',
    applied_for_resignation: true,
    resign_status: 'Pending',
  };
  return (
    <Table
      api={'/employee/exitmanagement/team'}
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId={'TeamResignedEmployee'}
      columns={[
        {
          header: 'Resource',
          field: 'name',
          type: 'text',
          width: 300,
          render: ({row}) => {
            let {name, official_email_id} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={name}
                official_email_id={official_email_id}
              />
            );
          },
        },
        {
          header: 'Joining',
          field: 'joining_date',
          type: 'date',
          width: 300,
        },
        {
          header: 'Resignation',
          field: 'resign_date_new',
          type: 'date',
          width: 400,
        },
        {
          header: 'Reason',
          field: 'resignation_description',
          type: 'text',
          width: 400,
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles}) => <RenderStatus row={row} styles={styles} />,
        },
      ]}
    />
  );
};
export const TeamResignedApprovedTable = props => {
  let {
    route: {params},
  } = props;
  let addOnFilter = {
    employee_status: 'Active',
    applied_for_resignation: true,
    resign_status: 'Accepted',
  };
  return (
    <Table
      api={'/employee/exitmanagement/team'}
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId={'TeamResignedApprovedEmployee'}
      columns={[
        {
          header: 'Resource',
          field: 'name',
          type: 'text',
          width: 300,
          render: ({row}) => {
            let {name, official_email_id} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={name}
                official_email_id={official_email_id}
              />
            );
          },
        },
        {
          header: 'Joining',
          field: 'joining_date',
          type: 'date',
          width: 250,
        },
        {
          header: 'Resignation',
          field: 'resign_date_new',
          type: 'date',
          width: 250,
        },
        {
          header: 'Relieving',
          field: 'expected_relieving_date',
          type: 'date',
          width: 350,
        },
        {
          header: 'Reason',
          field: 'resignation_description',
          type: 'text',
          width: 350,
        },
      ]}
    />
  );
};

export const TeamResignedwithdrawTable = props => {
  let {
    route: {params},
  } = props;
  let {user} = useAppStateContext();
  let addOnFilter = {reporting_to: user._id};
  return (
    <Table
      api={'/withdrawresign/team'}
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId={'TeamResignedApprovedEmployee'}
      columns={[
        {
          header: 'Resource',
          type: 'text',
          width: 350,
          render: ({row}) => {
            let {
              employee: {name, official_email_id},
            } = row;
            return (
              <UserAvatarWithNameAndEmail
                name={name}
                official_email_id={official_email_id}
              />
            );
          },
        },
        {
          header: 'Joining',
          field: 'employee.joining_date',
          type: 'date',
          width: 350,
        },
        {
          header: 'Resignation Reason & Date',
          render: props => (
            <UserFieldWithDateChip
              field={'resignation_description'}
              dateField={'resign_date_new'}
              {...props}
            />
          ),
        },
        {
          header: 'Withdrawal Reason & Date',
          render: props => (
            <UserFieldWithDateChip
              field={'withdraw_resign_reason'}
              dateField={'withdraw_date'}
              {...props}
            />
          ),
        },
      ]}
    />
  );
};
