export const CommentCellStyles = ({theme: {icons, fonts, colors}}) => {
  return {
    container: {
      alignItems: 'flex-start',
    },
    ChatIcon: icons.ChatBlue,
    badgeBackgroundColor: colors.NEUTRAL_MEDIUM,
    badgeTextColor: colors.BACKGROUND,
    chatIconContainer: {
      height: 32,
      width: 32,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      height: 20,
      width: 20,
    },
    commentCountText: {
      ...fonts.BODY2,
      color: colors.INFORMATION_HIGH,
    },
    lastCommentDate: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_LOW,
    },
  };
};

export const ActivityLogStyles = ({theme: {colors, fonts, spacing}}) => {
  return {
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    fontWeightStyle: {
      fontWeight: 'bold',
    },
    dateText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_LOW,
    },
    changesText: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.BODY2,
    },
  };
};

export const NestedTableModalRenderStyles = ({theme: {colors, icons}}) => {
  return {
    addPlus: icons.AddPlus,
    iconStyle: {height: 12, width: 12},
    iconContainerStyle: {
      backgroundColor: colors.BRAND_UPPER_LOW,
      padding: 6,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 46,
    },
  };
};

export const exportActionsButtonStyles = ({theme: {colors, fonts, icons, shadows}}) => {
  return {
    MoreActionIcon: icons.ThreeDotVertical,
    DownArrowIcon: icons.BreadCrumbDropdown,
    DownloadIcon: icons.Download,
    button: {
      text: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_MEDIUM,
      },
      container: {
        paddingTop: 4,
        paddingRight: 12,
        paddingBottom: 4,
        paddingLeft: 12,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: colors?.NEUTRAL_LOW,
      },
      iconStyle: {height: 16, width: 16},
    },
  };
};
