import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const deleted = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message: 'Are you sure you want to Delete?',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/aiprompts/${row?._id}`,
      props: {},
    });
  },
});

export const AiPromptViewTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'AI Propmt',
  });
  let invoke = useInvoke({method: 'post'});
  const postInvoke = async row => {
    let resp = await invoke({
      uri: `/aiprompts/data`,
      props: {
        filter: {
          _id: row?._id,
        },
        fields: {
          name: 1,
          generatePrompt: 1,
          revisedGeneratePrompt: 1,
          status: 1,
        },
        only: true,
      },
    });
    return resp?.data;
  };

  const {addOnFilter = {}} = params || {};

  let filter = {for_crm: null};

  if (addOnFilter) {
    filter = {...filter, ...addOnFilter};
  }

  let defaultValues = {};

  let {for_crm} = addOnFilter || {};

  if (for_crm) {
    defaultValues = {for_crm: true};
  }
  // Make function to navigate on ai-prompt to duplicate the prompt - Rajnish Trikaldarshi 13-01-2024
  const duplicateAiPrompt = async ({row}) => {
    let data = await postInvoke(row);
    let {name, revisedGeneratePrompt, generatePrompt, status} = data || {};
    if (name || revisedGeneratePrompt || generatePrompt) {
      defaultValues = {
        name,
        revisedGeneratePrompt,
        generatePrompt,
        status,
        ...defaultValues,
      };
    }
    navigation.navigate('ai-prompt', {
      ...params,
      defaultValues,
    });
  };

  return (
    <>
      <Table
        eventSourceId={['AI Propmt']}
        renderHeader={props => (
          <TableHeader
            title="Prompts"
            actions={[
              <AddButton
                title={'Prompt'}
                view={'ai-prompt'}
                params={{defaultValues}}
              />,
            ]}
          />
        )}
        onRowPress={props => {
          const {row} = props;
          navigation.navigate(`update-ai-prompt`, {
            row,
            defaultValues,
          });
        }}
        api={`/aiprompts`}
        filter={filter}
        fields={{
          name: 1,
          desc: 1,
          prompt: 1,
          apis: 1,
          populate: 1,
          outFields: 1,
          type: {name: 1},
          status: 1,
        }}
        columns={[
          {
            type: 'text',
            field: 'name',
            header: 'Name',
            width: 500,
          },
          // {
          //   type: 'text',
          //   field: 'desc',
          //   header: 'Desc',
          //   visible: !for_crm,
          // },
          // {
          //   type: 'text',
          //   field: 'prompt',
          //   header: 'Prompt',
          //   visible: !for_crm,
          // },
          // {
          //   header: 'Type',
          //   field: 'type.name',
          //   type: 'autoComplete',
          //   api: `/types`,
          //   suggestionField: 'name',
          //   valueField: 'name',
          //   visible: !for_crm,
          // },
          // {
          //   header: 'OutFields',
          //   type: 'text',
          //   field: 'outFields',
          //   visible: !for_crm,
          // },
          {
            header: 'Status',
            type: 'colorTag',
            field: 'status',
          },
        ]}
        moreActions={() => [
          {
            title: 'Edit',
            onPress: props => {
              const {row} = props;
              navigation.navigate('update-ai-prompt', {
                ...params,
                row,
              });
            },
          },
          deleted({deleteInvoke}),
          {
            title: 'Duplicate',
            onPress: duplicateAiPrompt,
          },
        ]}
      />
    </>
  );
};

export const MasterPromptViewTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Master Prompt',
  });

  return (
    <>
      <Table
        eventSourceId={['Master Prompt']}
        renderHeader={props => (
          <TableHeader
            title="Master Prompt"
            actions={[
              <AddButton title={'Prompt'} view={'master-prompt-form'} />,
            ]}
          />
        )}
        api={`/masterprompts`}
        fields={{
          prompt: 1,
        }}
        columns={[
          {
            type: 'text',
            field: 'prompt',
            header: 'prompt',
          },
        ]}
        moreActions={() => [
          {
            title: 'Edit',
            onPress: props => {
              const {row} = props;
              navigation.navigate('update-master-prompt', {
                ...params,
                row,
              });
            },
          },
          {
            title: 'Delete',
            variant: 'error',
            confirm: {
              title: 'Delete',
              message: 'Are you sure you want to Delete?',
              confirmText: 'Delete',
            },
            onPress: props => {
              const {row} = props;
              deleteInvoke({
                uri: `/masterprompts/${row?._id}`,
                props: {},
              });
            },
          },
        ]}
      />
    </>
  );
};
