import React from 'react';
import {HelpGuideStyles, HelpItemContainer} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const HelpGuide = props => {
  const {user, helpId, navigation} = props;
  const {
    containerStyle,
    headerTextStyle,
    textStyle,
    descStyle,
    subContainerStyle,
    itemContainerStyle,
    titleContainerStyle,
    lineStyle,
    learnMoreTextStyle,
    learnMoreContainerStyle,
    helpTickIcon,
    helpArrowIcon,
    imageContainerStyle,
  } = useStyles(HelpGuideStyles);
  const {help_contents = {}} = user || {};

  let {title, help_items = []} = help_contents[helpId] || {};

  return (
    <ScrollView style={containerStyle}>
      <Text style={headerTextStyle}>{title}</Text>
      <View style={itemContainerStyle}>
        {help_items &&
          help_items.map(item => {
            const {title, desc, videoUrl, image, index, how_title, why_title} =
              item || {};
            const navigateUri =
              (how_title || why_title) && (videoUrl || image)
                ? 'help-guide-video-player'
                : 'help-guide-video-player-no-data';

            return (
              <HelpItemContainer
                onPress={() => {
                  navigation.navigate(navigateUri, {
                    videoContent: item,
                  });
                }}
                styles={subContainerStyle}>
                <View style={titleContainerStyle}>
                  <Text style={textStyle}>
                    {index}. {title}
                  </Text>
                  <View style={learnMoreContainerStyle}>
                    <View className={'childContainer'} style={lineStyle} />
                    <Text
                      className={'childContainer'}
                      style={learnMoreTextStyle}>
                      Learn More
                    </Text>
                    <View
                      className={'childContainer'}
                      style={imageContainerStyle}>
                      <Image source={helpArrowIcon} />
                    </View>
                  </View>
                </View>
                <Text style={descStyle}>{desc}</Text>
              </HelpItemContainer>
            );
          })}
      </View>
    </ScrollView>
  );
};
