export const globalFilters = {
  product: {
    label: 'Product',
    field: 'product',
    placeholder: 'Select Product',
    type: 'autoComplete',
    api: `/products/all`,
    suggestionField: 'name',
    valueField: 'name',
    asParam: true,
  },
  organization: {
    label: 'Organization',
    field: 'organization',
    placeholder: 'Select Organization',
    type: 'autoComplete',
    api: `/organizations`,
    suggestionField: 'name',
    valueField: 'name',
    asParam: true,
  },
  products: {
    label: 'Product',
    field: 'products',
    placeholder: 'Select Product',
    type: 'multiAutoComplete',
    api: `/products/all`,
    suggestionField: 'name',
    valueField: 'name',
    asParam: true,
  },
  organizations: {
    label: 'Organization',
    field: 'organizations',
    placeholder: 'Select Organization',
    type: 'multiAutoComplete',
    api: `/organizations`,
    suggestionField: 'name',
    valueField: 'name',
    asParam: true,
  },
  period: {
    label: 'Period',
    field: 'period',
    placeholder: 'Select Period',
    type: 'date',
    asParam: true,
  },
};
