import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';

export const ValuePropositionList = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {filter} = params;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'valuePropositionAdded',
  });
  return (
    <Table
      api="/valuePropositions"
      eventSourceId={['valuePropositionAdded']}
      filter={{...filter, status: 'active'}}
      onRowPress={({row}) => {
        navigation.navigate('edit-value-proposition', {...params, row});
      }}
      columns={[
        {
          header: 'Value Proposition',
          field: 'name',
          type: 'textArea',
        },
        {
          header: 'Use Case',
          field: 'use_case',
          type: 'textArea',
        },
        {
          header: 'Problem',
          field: 'problem',
          type: 'textArea',
        },
        {
          header: 'Current Way',
          field: 'current_way',
          type: 'textArea',
        },
        {
          header: 'Limitations Of Current Way',
          field: 'limitations_of_current_way',
          type: 'textArea',
        },
        {
          header: "Our Products's capability",
          field: 'our_prospect_capability',
          type: 'textArea',
        },
        {
          field: 'our_products_feature',
          type: 'textArea',
          header: 'Our Products Feature',
        },
        {
          field: 'benefit',
          type: 'textArea',
          header: 'Benefit',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: ({row}) => {
            invoke({
              uri: `/valuePropositions/${row?._id}`,
              props: {status: 'deleted'},
            });
          },
        },
      ]}
      {...props}
    />
  );
};
