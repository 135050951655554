import React from 'react';
import {Table} from '../../../components/table/Table';
import {Header} from '@unthinkable/react-screen-components';
import {AddButton} from '../../../components/button/AddButton';

export const EmployeeAssignmentTable = props => {
  const params = props.route.params;
  const {row, addOnFilter} = params || {};
  const {navigation} = props;
  return (
    <Table
      eventSourceId="EmployeeAssignment"
      api={`/EmployeeAssignments`}
      fields={{
        from_date: 1,
        to_date: 1,
        percentage: 1,
        hours: 1,
        employee: {name: 1},
        department: {name: 1},
        product: {name: 1},
        object: {name: 1},
      }}
      filter={{employee: row?._id}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`employee-assignment-detail`, {
          EmployeeAssignmentId: row._id,
        });
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          type: 'text',
          field: 'employee.name',
          header: 'Employee',
        },
        {
          type: 'text',
          field: 'department.name',
          header: 'Department',
        },
        {
          type: 'text',
          field: 'product.name',
          header: 'Product',
        },
        {
          type: 'text',
          field: 'object.name',
          header: 'Type',
        },
        {
          width: 80,
          type: 'date',
          field: 'from_date',
          header: 'From',
        },
        {
          width: 80,
          type: 'date',
          field: 'to_date',
          header: 'To',
        },
        {
          width: 120,
          type: 'number',
          field: 'percentage',
          header: 'Percentage',
        },
        {
          width: 80,
          type: 'number',
          field: 'hours',
          header: 'Hours',
        },
      ]}
    />
  );
};
