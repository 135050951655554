import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddEmployeeTeam = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeteams',
    eventSourceId: 'EmployeeTeam',
    ...props,
  });
  return (
    <Form
      api={`/employeeteams/${row?._id}`}
      fields={{
        name: 1,
        performance_manager: {
          _id: 1,
          name: 1,
          color: 1,
        },
        ticket_head: {
          _id: 1,
          name: 1,
        },
        project: {
          _id: 1,
          project: 1,
        },
        is_ticket_enabled: 1,
        requester: {_id: 1, name: 1},
        approver: {_id: 1, name: 1},
        observers: {name: 1},
      }}
      defaultValues={{
        team_type: 'Department',
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      header="Add Team"
      layoutFields={[
        {
          placeholder: 'Name',
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          placeholder: 'Performace Manager',
          label: 'Performace Manager',
          field: 'performance_manager',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          colorField: 'color',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          avatar: true,
        },
        {
          placeholder: 'Ticket Head',
          label: 'Ticket Head',
          field: 'ticket_head',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          visible: ({values}) => values?.is_ticket_enabled,
        },
        {
          header: 'Requester',
          placeholder: 'Requester',
          type: 'multiAutoComplete',
          field: 'requester',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
        },
        {
          header: 'Approver',
          placeholder: 'Approver',
          type: 'multiAutoComplete',
          field: 'approver',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
        },
        {
          header: 'Observers',
          placeholder: 'Observers',
          type: 'multiAutoComplete',
          field: 'observers',
          api: `/users`,
          filter: {
            status: 'a',
          },
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'email',
        },
        // {
        //   label: 'Project',
        //   placeholder: 'Project',
        //   field: 'project',
        //   type: 'autoComplete',
        //   api: `/projects`,
        //   suggestionField: 'project',
        //   valueField: 'project',
        // },
      ]}
      {...props}
    />
  );
};

export const EditEmployeeTeam = props => {
  return <AddEmployeeTeam mode="edit" header={'Edit Team'} {...props} />;
};
