import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';

export const UploadBulkForm16 = props => {
    const {
        route: { params },
    } = props;
    let { onSubmit } = useFormSubmit({
        uri: '/form16batchdocuments',
        eventSourceId: 'form16',
        ...props,
    });
    return (
        <Form
            onSubmit={onSubmit}
            submitAction="Save"
            header="Upload Form16"
            layoutFields={[
                {
                    field: 'document',
                    type: 'file',
                    multiple: true,
                    options: { bucketName: 'manaze' }
                },
            ]}
            {...props}
        />
    );
};


export const BulkForm16ErrorForm = props => {
    const {
        route: { params },
    } = props;
    let { onSubmit } = useFormSubmit({
        uri: '/form16batchdocuments',
        eventSourceId: 'form16',
        ...props,
    });

    return (
        <Form
            onSubmit={onSubmit}
            submitAction="Save"
            header="Upload Form16"
            layoutFields={[
                {
                    field: 'document',
                    type: 'file',
                    multiple: true,
                    options: { bucketName: 'manaze' }
                },
            ]}
            {...props}
        />
    );
};


export const BulkForm16LogDetail = props => {
    const {
        route: { params },
    } = props;
    const { importHistoryLogId } = params;
    return (
        <Form
            api={`/getbulkform16errordetail/${importHistoryLogId}`}
            fields={{
                createdAt: 1,
                status: 1,
                success_count: 1,
                pending_count: 1,
                errors: 1,
            }}
            defaultValues={{
                source: "BulkForm16",
                model: "Form16BatchDocumentsModel",
            }}
            mode="edit"
            readOnly={true}
            header={{ title: 'Error Detail' }}
            editable={false}
            layoutFields={[
                {
                    field: 'source',
                    value : 'BulkForm16',
                    type: 'text',
                    label: 'Source',
                    size: 6
                },
                {
                    field: 'model',
                    type: 'text',
                    label: 'Model',
                    size: 6
                },
                {
                    field: 'createdAt',
                    type: 'date',
                    label: 'Date',
                    size: 6
                },
                {
                    field: 'status',
                    type: 'text',
                    label: 'Status',
                    size: 6
                },
                {
                    field: 'total',
                    value: ({ row }) => (row.success_count || 0) + (row.pending_count || 0),
                    type: 'number',
                    label: 'Total',
                    size: 6
                },
                {
                    field: 'success_count',
                    type: 'number',
                    label: 'Success',
                    size: 6
                },
                {
                    field: 'pending_count',
                    type: 'number',
                    label: 'Error',
                    size: 6
                },
                {
                    field: 'errors',
                    nested: true,
                    label: 'Errors',
                    render: props => (
                        <NestedTable
                            {...props}
                            fields={[
                                {
                                    field: 'index',
                                    header: 'Index',
                                    type: 'number',
                                },
                                {
                                    field: 'error',
                                    header: 'Error',
                                    type: 'textArea',
                                },
                            ]}
                        />
                    ),
                },
            ]}
        />
    );
};