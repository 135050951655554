import {Form, InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

const databaseOptions = [
  'MongoDB',
  'PostgreSQL',
  'MySQL',
  'SQLite',
  'Oracle',
  'Redis',
  'CouchDB',
  'Amazon DynamoDB',
  'MariaDB',
  'Neo4j',
  'Amazon Neptune',
  'ArangoDB',
  'OrientDB',
  'Memcached',
  'Google Spanner',
  'CockroachDB',
  'VoltDB',
  'NuoDB',
  'Elasticsearch',
  'Other',
];

export const ProjectDatabaseInlineForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {project} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'ProjectDatabase',
    uri: '/projectdatabases',
  });
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project_id: project?._id,
      }}
      beforeSubmit={({data}) => {
        if (data.database !== 'Other') return {data};
        let {other_database, ...restData} = data;
        return {
          data: {
            ...restData,
            database: other_database,
          },
        };
      }}
      layoutFields={[
        {
          label: 'Database Type',
          type: 'autoComplete',
          field: 'database',
          options: databaseOptions,
          required: true,
        },
        {
          label: 'Other Database Type',
          type: 'text',
          field: 'other_database',
          required: true,
          visible: ({values}) => values.database === 'Other',
        },
        {
          label: 'Version',
          field: 'version',
          type: 'text',
        },
      ]}
    />
  );
};

const ProjectDatabaseForm = props => {
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'ProjectDatabase',
    uri: '/projectdatabases',
  });
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        if (data.database !== 'Other') return {data};
        let {other_database, ...restData} = data;
        return {
          data: {
            ...restData,
            database: other_database,
          },
        };
      }}
      layoutFields={[
        {
          label: 'Database Type',
          type: 'autoComplete',
          field: 'database',
          options: databaseOptions,
          required: true,
        },
        {
          label: 'Other Database Type',
          type: 'text',
          field: 'other_database',
          required: true,
          visible: ({values}) => values.database === 'Other',
        },
        {
          label: 'Version',
          type: 'text',
          field: 'version',
        },
      ]}
    />
  );
};

export const EditProjectDatabaseForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {database} = params;

  return (
    <ProjectDatabaseForm
      {...props}
      defaultValues={{
        ...database,
      }}
      header={{title: 'Edit Database'}}
    />
  );
};

export const AddProjectDatabaseForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {project_id, searchValue, afterSubmit} = params;

  return (
    <ProjectDatabaseForm
      {...props}
      defaultValues={{
        project_id,
        database: searchValue,
      }}
      afterSubmit={afterSubmit}
      header={{title: 'Add Database'}}
    />
  );
};
