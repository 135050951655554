import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {useProjectActions} from '../../pmt/actions/ProjectActions';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

export const TaxDeclarationHrView = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;
  const {download, user} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'taxDeclaration',
  });
  const {activityLogForIncomeTaxAccounts} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      {...props}
      eventSourceId={['taxDeclaration']}
      api={'/incometax/all'}
      addOnFilter={{...addOnFilter}}
      limit={1000}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('tax-declarations-card', {
          row,
          tab,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Income Tax calculation',
            onPress: ({selectedIds}) => {
              navigation.navigate('Income-tax-calculate', {selectedIds});
            },
            visible: ({values}) => {
              if (
                (user.employee.official_email_id = 'amit@daffodilsw.com') ||
                (user.employee.official_email_id =
                  'sushil.nagvan@daffodilsw.com')
              ) {
                return true;
              }
            },
          },
          {
            title: 'Component wise Download',
            onPress: ({selectedIds}) => {
              download({
                uri: `/incometax/componentWiseDownload`,
                props: {
                  selectedIds: selectedIds,
                },
              });
            },
          },
          {
            title: 'Update TDS Month And Year',
            onPress: ({selectedIds}) => {
              navigation.navigate('update-tds-month-and-year', {selectedIds});
            },
            visible: ({values}) => {
              if (tab === 'open' || tab === 'all') {
                return true;
              }
            },
          },
          {
            title: 'Update Tax Calculation Date',
            onPress: ({selectedIds}) => {
              navigation.navigate('update-tax-calculation-date', {selectedIds});
            },
            visible: () => {
              if (tab === 'open' || tab === 'all') {
                return true;
              }
            },
          },
          {
            title: 'Freeze',
            confirm: {
              title: 'Freeze',
              message: 'Are you sure you want to freeze?',
            },
            onPress: ({selectedIds}) => {
              invoke({
                uri: '/incometax/freeze',
                props: {selectedIds: selectedIds},
              });
            },
            visible: () => {
              if (tab === 'open' || tab === 'submitted' || tab === 'reopen') {
                return true;
              }
            },
          },
          {
            title: 'UnFreeze',
            confirm: {
              title: 'unFreeze',
              message: 'Are you sure you want to unFreeze?',
            },
            onPress: ({selectedIds}) => {
              invoke({
                uri: '/incometax/unfreeze',
                props: {selectedIds: selectedIds},
              });
            },
            visible: () => {
              if (tab === 'accepted') {
                return true;
              }
            },
          },
          {
            title: 'Include Current Month',
            confirm: {
              title: 'Include Current Month',
              message: 'Are you sure you want to include current month?',
            },
            onPress: ({selectedIds}) => {
              invoke({
                uri: '/incometax/includeCurrentMonth',
                props: {selectedIds: selectedIds},
              });
            },
            visible: () => {
              if (tab === 'open' || tab === 'accepted') {
                return true;
              }
            },
          },
          {
            title: 'Add Component',
            confirm: {
              title: 'Add Component',
              message: 'Are you sure you want to add component?',
            },
            onPress: ({selectedIds}) => {
              invoke({
                uri: '/incometax/addComponent',
                props: {
                  selectedIds: selectedIds,
                },
              });
            },
            visible: () => {
              if (tab === 'all' || tab === 'open') {
                return true;
              }
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee_id?.name}
                color={row?.employee_id?.color}
                official_email_id={row?.employee_id?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Employee Code',
          field: 'employee_id.employee_code',
          type: 'text',
          width: 150,
        },
        {
          header: 'Financial Year',
          field: 'financial_year_id.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Type',
          field: 'calculation_type',
          type: 'text',
          width: 250,
        },
        {
          header: 'Pan Number',
          field: 'employee_id.pan_number',
          type: 'text',
          width: 150,
        },
        {
          header: 'Landlord Name',
          field: 'land_lord_name',
          type: 'text',
          width: 200,
        },
        {
          header: 'Landlord Pan Number',
          field: 'landlord_pan_no',
          type: 'text',
          width: 200,
        },
        {
          header: 'Landlord Address',
          field: 'landlord_address',
          type: 'text',
          width: 200,
        },
        {
          header: 'Taxable Income',
          field: 'net_taxable_income',
          type: 'currency',
          width: 150,
          tofixed: 2,
        },
      ]}
      moreActions={[
        {
          title: 'Update Tax Calculation Type',
          onPress: ({row}) => {
            navigation.navigate('update-calculation-type', {
              row,
            });
          },
          visible: ({}) => {
            if (tab === 'open' || tab === 'reopen' || tab === 'accepted')
              return true;
          },
        },
        {
          title: 'Tax Sheet',
          onPress: ({row}) => {
            download({
              uri: `/incometax/taxsheet`,
              props: {
                Id: row._id,
                callFor: 'Tax Sheet',
              },
            });
          },
        },
        {
          title: 'Tax Sheet Annexure',
          onPress: ({row}) => {
            download({
              uri: '/incometax/annexure',
              props: {
                Id: row._id,
              },
            });
          },
        },
        {
          title: 'Re-open',
          onPress: ({row}) => {
            invoke({
              uri: `/incometaxes/${row._id}`,
              props: {
                status: 'ReOpen',
              },
            });
          },
          visible: ({}) => {
            if (tab === 'submitted') return true;
          },
        },
        {
          title: 'Add LandLord Details',
          onPress: ({row}) => {
            navigation.navigate('add-landlord-details', {row});
          },
          visible: ({}) => {
            if (tab === 'reopen' || tab === 'open') return true;
          },
        },
        {
          title: 'Update Values',
          onPress: ({row}) => {
            navigation.navigate('update-income-tax-value', {row});
          },
          visible: ({}) => {
            if (tab === 'all' || tab === 'open') return true;
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogForIncomeTaxAccounts,
        },
        {
          title: 'Download Form 16',
          onPress: ({row}) => {
            download({
              uri: `/incometax/taxsheet`,
              props: {
                Id: row._id,
                callFor: 'Form 16 calculation',
              },
            });
          },
          visible: ({values}) => {
            if (tab === 'all') return true;
          },
        },
      ]}
    />
  );
};
