import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AssignResourceOnSkill = props => {
  const {
    route: {params},
    navigation
  } = props;
  const {skill_metrics} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/employeeSkills',
    eventSourceId: 'EmployeeSkills',
  });
  return (
    <Form
      header={{
        title: 'Add Resource',
      }}
      onSubmit={onSubmit}
      defaultValues={{
        skill_metrics: skill_metrics?._id,
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          api: '/employeeSuggestions',
          label: 'Resource',
          suggestionField: 'name',
          valueField: 'name',
          field: 'employee_id',
          required: true,
          size: 'medium',
        },
        {
          field: 'level',
          type: 'autoComplete',
          label: 'Proficiency Level',
          options: [
            {
              label: 'Expert-A',
              value: 'A',
            },
            {
              label: 'Intermediate-B',
              value: 'B',
            },
            {
              label: 'Beginner-C',
              value: 'C',
            },
            {
              label: 'Novice-D',
              value: 'D',
            },
          ],
          suggestionField: 'label',
          keyField: 'value',
          size: 'medium',
        },
        {
          type: 'multiAutoComplete',
          api: '/tools',
          label: 'Tools',
          suggestionField: 'name',
          valueField: 'name',
          field: 'tools',
          filter: () => {
            return {
              _id: {
                $in: skill_metrics?.tools || [],
              },
            };
          },
          required: true,
        },
      ]}
    />
  );
};
