import {Image, Row, Text} from '@unthinkable/react-core-components';

export const IconTextLabel = ({title, value, icon, iconStyle, textStyle}) => {
  return (
    <Row gap={6} style={{alignItems: 'center'}}>
      {icon ? <Image source={icon} style={iconStyle} /> : void 0}
      <Text title={title} style={textStyle}>
        {value}
      </Text>
    </Row>
  );
};
