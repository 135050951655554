import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const ProjectDescriptionForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Project',
    uri: '/projects',
    ...props,
  });

  return (
    <Form
      api={`/projects/${row?._id}`}
      fields={{
        desc: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          field: 'desc',
          type: 'aiTextArea',
          placeholder: 'Write description here...',
          inputProps: {
            minRows: 4,
            maxRows: 10,
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectDescriptionForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;
  return (
    <ProjectDescriptionForm
      mode="edit"
      header={{title: 'Project Description', secondaryTitle: `${row?.project}`}}
      {...props}
    />
  );
};

export const UpdateProjectDescriptionForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;

  return (
    <ProjectDescriptionForm
      mode="edit"
      header={{title: 'Project Description', secondaryTitle: `${row?.project}`}}
      isDualMode={true}
      {...props}
    />
  );
};
