import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const RoleForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, module} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Role',
    uri: '/projectroles',
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Role',
          type: 'text',
          field: 'role',
          required: true,
        },
        {
          label: 'Modules',
          field: 'module_id',
          type: 'multiAutoComplete',
          api: `/projectmodules`,
          suggestionField: 'module',
          valueField: 'module',
          filter: ({values}) => {
            const {module_id} = values;
            return {
              project_id: project?._id,
              _id: {$nin: module_id},
              status: {$ne: 'archived'},
            };
          },
          visible: !module?._id,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'desc',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
      ]}
      {...props}
    />
  );
};

export const AddRoleForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, module, searchValue, afterSubmit} = params;

  const defaultValues = {project_id: project?._id};
  if (module?._id) {
    defaultValues.module_id = [module._id];
  }

  if (searchValue) {
    defaultValues.role = searchValue;
  }
  return (
    <RoleForm
      header="Add Role"
      {...props}
      defaultValues={defaultValues}
      afterSubmit={afterSubmit}
    />
  );
};

export const UpdateRoleForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {role} = params;
  return <RoleForm mode="edit" header="Update Role" {...props} data={role} />;
};
