import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {ActiveIndustryTable} from './ActiveIndustryTable';
import {InActiveIndustryTable} from './InActiveIndustryTable';

const tabs = {
  active: {
    label: 'Active',
    view: <ActiveIndustryTable />,
    eventSourceId: 'Industry',
    api: '/industry/active',
  },
  inActive: {
    label: 'InActive',
    view: <InActiveIndustryTable />,
    eventSourceId: 'Industry',
    api: '/industry/inActive',
  },
};

export const IndustryTab = props => {
  return <TabView tabs={tabs} {...props} />;
};
