import React from 'react';
import {PermanentTeamTable} from './PermanentTeamTable';
import {AllocatedTeamTable} from './AllocatedTeamTable';
import {ScrollView, View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton, HeaderButton} from '../../../components/button/AddButton';
import {PeriodRangeFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {getPreviousMonth} from '@unthinkable/react-filters/src/DateUtility';

export const TeamTableTabView = props => {
  const {params} = props;
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  let {params: filterParams = {}} = filterValues;
  return (
    <ScrollView>
      <PermanentTeamTable
        {...props}
        renderHeader={() => (
          <TableHeader
            leftActions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
            ]}
            actions={[
              <AddButton
                title="Request Talent"
                view="add-request-talent"
                params={props?.route?.params}
              />,
              <HeaderButton
                title="My Resources"
                view="my-resource"
                params={props?.route?.params}
              />,
            ]}
          />
        )}
        filterParams={filterParams}
      />
      <AllocatedTeamTable
        renderHeader={() => <TableHeader title="Temporary" />}
        {...props}
        filterParams={filterParams}
      />
    </ScrollView>
  );
};
