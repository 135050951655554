import {
  Col,
  Image,
  Platform,
  Text,
  View,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {PasswordInput, TextInput} from '@unthinkable/react-text-input';
import React, {useState} from 'react';
import GoogleLoginButton from './GoogleLoginButton';
import AppleLoginButton from './AppleLoginButton';
import {useNavigation} from '@react-navigation/native';

export const LoginFormMobile = props => {
  const navigation = useNavigation();

  const {onLogin, onGoogleLogin, onAppleLogin} = props;

  const theme = useComponentTheme('LoginFormMobile');
  const {
    WelcomeBackIcon,
    containerStyle,
    headerContainerStyle,
    headerTextStyle,
    googleLoginContainerStyle,
    saparatorContainerStyle,
    separatorTextStyle,
    inputContainerStyle,
    loginButtonStyle,
    loginTextStyle,
    iconContainerStyle,
    UnthinkableIcon,
    FormTextContainerStyle,
    forgotPasswordTextStyle,
  } = theme;

  const [data, updateData] = useState({});

  return (
    <View style={containerStyle}>
      <View style={headerContainerStyle}>
        <Text style={headerTextStyle}>Log in </Text>
        <Image source={WelcomeBackIcon} />
      </View>
      <View style={googleLoginContainerStyle}>
        <GoogleLoginButton onSubmit={onGoogleLogin} />
      </View>
      {Platform.OS === 'ios' ? (
        <AppleLoginButton onSubmit={onAppleLogin} />
      ) : (
        void 0
      )}
      <View style={saparatorContainerStyle}>
        <Text style={separatorTextStyle}>or</Text>
      </View>
      <Col gap={16} style={inputContainerStyle}>
        <TextInput
          placeholder="Sign in with Email"
          value={data['email']}
          onChangeText={text => {
            updateData({...data, email: text});
          }}
          required
        />
        <PasswordInput
          placeholder="Password"
          value={data['password']}
          onSubmitEditing={() => {
            onLogin(data);
          }}
          onChangeText={text => {
            updateData({...data, password: text});
          }}
          required
        />
      </Col>
      <View style={FormTextContainerStyle}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('forgotPassword');
          }}>
          <Text style={forgotPasswordTextStyle}>Forgot Password?</Text>
        </TouchableOpacity>
      </View>
      <View
        style={loginButtonStyle}
        onPress={() => {
          onLogin(data);
        }}>
        <Text style={loginTextStyle}>Login</Text>
      </View>
      <View style={iconContainerStyle}>
        <Image source={UnthinkableIcon} />
      </View>
    </View>
  );
};
