import {useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {GroupBy} from '../../../components/table/GroupBy';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useAppStateContext} from '../../../providers/AppState';
import {
  OverduePendingTable,
  OverduePendingTableAm,
  PendingTable,
} from './RevenueInvoiceTable';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

export const PendingInvoiceTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const groupBy = useGroupBy({
    options: [
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_pending_receipt_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem
                  value={row.base_pending_receipt_amount.toFixed(2)}
                />
              </RowWithSeparator>
            );
          },
        },
      },
      {
        label: 'AM',
        field: 'account_owner_id',
        aggregates: {
          base_pending_receipt_amount: 'sum',
        },
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem
                  value={row.base_pending_receipt_amount.toFixed(2)}
                />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const tabs = {
    // pending: {
    //   label: 'Pending',
    //   view: (
    //     <PendingInvoiceTable
    //       {...props}
    //       searchValue={searchValue}
    //       addOnFilter={filter}
    //       groupBy={groupBy}
    //     />
    //   ),
    //   api: `/revenueInvoices/all`,
    //   search: searchValue,
    //   searchFields: 'invoice_number',
    //   addOnFilter: {
    //     ...filter,
    //     base_pending_receipt_amount: {$gt: 0},
    //     invoice_type: {$in: ['i', 'a']},
    //   },
    //   actions: [
    //     <IconButton
    //       icon={Download}
    //       onPress={() => {
    //         download({
    //           uri: '/downloadInvoices',
    //           props: {
    //             uri: '/revenueInvoices/all',
    //             parameters: {
    //               params,
    //               addOnFilter: {
    //                 ...filter,
    //                 base_pending_receipt_amount: {$gt: 0},
    //                 invoice_type: 'i',
    //               },
    //             },
    //           },
    //         });
    //       }}
    //     />,
    //   ],
    // },

    Overdue: {
      label: 'Overdue',
      view: (
        <OverduePendingTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      api: `/revenueInvoices/overdue`,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {...filter},
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/downloadInvoices',
              props: {
                uri: '/revenueInvoices/overdue',
                parameters: {
                  params,
                  addOnFilter: {...filter},
                },
              },
            });
          }}
        />,
      ],
    },

    pending: {
      label: 'Pending',
      view: (
        <PendingTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      api: `/revenueInvoices/pending`,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {...filter},
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/downloadInvoices',
              props: {
                uri: '/revenueInvoices/pending',
                parameters: {
                  params,
                  addOnFilter: {...filter},
                },
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Invoice Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Locations',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account Manager',
              api: '/employeeSuggestions',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
            {
              type: 'autoComplete',
              label: 'Type',
              placeholder: 'Select',
              options: [
                {label: 'Fixed', value: 'ot'},
                {label: 'T&M', value: 'r'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              valueField: 'label',
              field: 'pricing_type',
            },
            {
              type: 'autoComplete',
              label: 'Invoice Category',
              placeholder: 'Select',
              options: ['Export Invoice', 'Tax Invoice', 'Bill of Supply'],
              field: 'invoice_category',
            },
          ]}
        />,
        <GroupBy {...groupBy} />,
      ]}
      {...props}
    />
  );
};

export const PendingInvoiceTabAm = props => {
  let {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const groupBy = useGroupBy({
    options: [
      // {
      //   label: 'AM',
      //   field: 'account_owner_id',
      //   groupRow: {
      //     title: 'account_owner',
      //     data: '_children',
      //     defaultExpanded: true,
      //   },
      // },
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_pending_receipt_amount: 'sum',
        },
        groupRow: {
          // title: 'name',
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row.name} />
                <GroupContentItem value={row.base_pending_receipt_amount} />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const tabs = {
    // pending: {
    //   label: 'Pending',
    //   view: (
    //     <PendingInvoiceTableAm
    //       {...props}
    //       searchValue={searchValue}
    //       addOnFilter={filter}
    //       groupBy={groupBy}
    //     />
    //   ),
    //   api: `/revenueInvoices/all`,
    //   search: searchValue,
    //   searchFields: 'invoice_number',
    //   addOnFilter: {
    //     ...filter,
    //     base_pending_receipt_amount: {$gt: 0},
    //     invoice_type: {$in: ['i', 'a']},
    //     account_owner_id: employeeId,
    //   },
    // },
    // Credit: {
    //   label: 'Credit Note',
    //   view: (
    //     <CreditNotePendingTableAm
    //       {...props}
    //       searchValue={searchValue}
    //       addOnFilter={filter}
    //     />
    //   ),
    //   api: `/revenueInvoices/all`,
    //   search: searchValue,
    //   searchFields: 'invoice_number',
    //   addOnFilter: {...filter, invoice_type: 'c', account_owner_id: employeeId},
    // },
    // Advance: {
    //   label: 'Advance',
    //   view: (
    //     <AdvancePendingTableAm
    //       {...props}
    //       searchValue={searchValue}
    //       addOnFilter={filter}
    //     />
    //   ),
    //   api: `/revenueInvoices/advance`,
    //   search: searchValue,
    //   searchFields: 'invoice_number',
    //   addOnFilter: {...filter, account_owner_id: employeeId},
    // },
    Overdue: {
      label: 'Overdue',
      view: (
        <OverduePendingTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      api: `/revenueInvoices/overdue`,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        ...filter,
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
    },

    pending: {
      label: 'Pending',
      view: (
        <PendingTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            $or: [
              {
                account_owner_id: employeeId,
              },
              {
                account_owner_id: {
                  $in: employeeTeam,
                },
              },
            ],
          }}
          groupBy={groupBy}
        />
      ),
      api: `/revenueInvoices/pending`,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        ...filter,
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Invoice Number"
        />,
        <GroupBy {...groupBy} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Locations',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'autoComplete',
              label: 'Type',
              placeholder: 'Select',
              options: [
                {label: 'Fixed', value: 'ot'},
                {label: 'T&M', value: 'r'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              valueField: 'label',
              field: 'pricing_type',
            },
            {
              type: 'autoComplete',
              label: 'Invoice Category',
              placeholder: 'Select',
              options: ['Export Invoice', 'Tax Invoice', 'Bill of Supply'],
              field: 'invoice_category',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
