import React from 'react';
import {Table} from '../../../components/table/Table';
import {staffAugmentationControllers} from '../controllers/staffAugmentationControllers';
import {TabView} from '../../../components/tab/TabView';

const InputDepartmentInterviewTable = props => {
  const params = props.route.params;
  const {addOnFilter, api} = props;
  const {assignedCount, activeCount, selectedCount} =
    staffAugmentationControllers(props);
  return (
    <Table
      api={api}
      {...props}
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },

        {
          field: 'owner',
          header: 'BD Person',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },

        {
          header: 'Customer',
          field: 'customer_id.name',
        },

        {
          header: 'Skill',
          field: 'skill.name',
        },

        {
          header: 'Mode of Screening',
          field: 'process',
        },
        {
          header: 'Work Location',
          field: 'work_location',
        },
        {
          header: 'No. of Resources',
          field: 'no_of_resources',
        },
        {
          header: 'Priority',
          field: 'priority',
        },
        {
          header: 'Status',
          field: 'status',
        },
        {
          header: 'Resource',
          field: 'no_of_resources',
        },
        {
          header: 'Assigned',
          field: 'assigned_count',
          onPress: ({row}) => {
            assignedCount({row});
          },
        },
        {
          header: 'Active',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Pending',
          field: 'pending_count',
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        {
          type: 'date',
          header: 'Outcome Date',
          field: 'outcome_date',
        },
        {
          header: 'Next Task',
          field: 'next_task',
        },
        {
          header: 'Last Communication',
          field: 'last_communication',
        },
      ]}
    />
  );
};

export const InputDepartmentInterviewTabView = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentInterviewTableApi = {
    api: '/inputDepartmentInterview/all',
    addOnFilter: {
      rfr_type: 'Customer',
      quote_status_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentInterviewTableApi,
    view: (
      <InputDepartmentInterviewTable
        {...props}
        {...InputDepartmentInterviewTableApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const NewResourceAdditionTabView = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentInterviewTableApi = {
    api: '/inputDepartmentInterview/all',
    addOnFilter: {
      rfr_type: 'Customer',
      outcome: 'Selected',
      outcome_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentInterviewTableApi,
    view: (
      <InputDepartmentInterviewTable
        {...props}
        {...InputDepartmentInterviewTableApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerAcquisationInterviewTab = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentInterviewTableApi = {
    api: '/inputDepartmentInterview/all',
    addOnFilter: {
      rfr_type: 'PotentailCustomer',
      creation_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentInterviewTableApi,
    view: (
      <InputDepartmentInterviewTable
        {...props}
        {...InputDepartmentInterviewTableApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};


export const CustomerAcquisationWinTab = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentWinCustomerApi = {
    api: '/customerContractNegotiation/all',
    addOnFilter: {
      rfr_type: 'PotentailCustomer',
      status: 'Won',
      outcome_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentWinCustomerApi,
    view: (
      <InputDepartmentInterviewTable
        {...props}
        {...InputDepartmentWinCustomerApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
