import React from 'react';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {useAppStateContext} from '../../../providers/AppState';
import {EmployeeTabs} from './EmployeeList';
import {NonEnggTeams} from './NonEnggTeams';
import {PipResourceTabs} from './PipResourceTabs';
import {ManagementCostTable} from './ManagementCostReportTable';
import {useAccessRight} from '../../../controllers/useAccessRight';
import {ReplacementTabs} from './ReplacementTab';
import {BenchCostReportTab} from './BenchCostReport';
import {NonEnggTeamReport} from './AllNonEnggReport';
import {EngGrossMarginTab} from './EnggGrossMarginTab';
import {EmployeeTurnoverReport} from './EmployeeTurnoverReport';
import {
  EmployeeWithPackageForHrTabs,
  EmployeeWithPackageTabs,
} from './EmployeeWithPackage';
import {MarkIdentifyForSwappingTabs} from './MarkIdentifyForSwapping';
import {ResourceAllocationTab} from '../../order/views/ResourceAllocationTab';
import {EmployeeParichayTabs} from './EmployeeParichayViews';
import {NoDuesHrTabs} from './NoduesTabs';
import {FnFHrTabs} from './F&FTabView';
import {EmployeeVariableTable} from './EmployeeVariableTable';
import {SelectedCandidates} from '../../recruitment/views/CandidateTable';
import {BackgroundVerificationTabs} from './BackgroundVerificationTabs';
import { ProductivitySurveyTablePI } from './ProductivitySurveyTablePI';

const EmployeeGroup = createMenuNavigator();
const EmployeeNewNavigator = props => {
  const {user, globalData: {accessRights: userAccessRights} = {}} =
    useAppStateContext();
  const canAccess = useAccessRight({accessRights: userAccessRights});
  return (
    <EmployeeGroup.Navigator
      canAccess={canAccess}
      data-navigator-id="EmployeeGroup"
      {...props}>
      <EmployeeGroup.Screen
        data-resource-id="AllEngineers"
        name="All Employees"
        component={EmployeeTabs}
        props={{user: user, ...props}}
        options={{
          label: 'All Employees',
          api: '/employees/active',
          limit: 5000,
        }}
      />
      <EmployeeGroup.Screen
        data-resource-id="NonEngineers"
        name="Department Wise"
        component={NonEnggTeams}
        props={{user: user, ...props, departmentTitle: 'Department Wise'}}
        options={{
          label: 'Department Wise',
          api: '/employees/nonprojectteams',
          limit: 5000,
        }}
      />
      <EmployeeGroup.Screen
        data-resource-id="Engineers"
        name="Project Wise"
        options={{
          label: 'Project Wise',
          onPress: () => {
            props.navigation.navigate('engineering-project-wise-menu', {});
          },
          api: '/employees-team/workload',
          limit: 5000,
        }}
      />
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="Replacement"
          name="Replacement"
          component={ReplacementTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Replacement',
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="PIPResources"
          name="PIP"
          component={PipResourceTabs}
          props={{user: user, ...props}}
          options={{
            label: 'PIP',
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Screen
          data-resource-id="Resignations"
          name="Resignations"
          options={{
            label: 'Resignations',
            onPress: () => {
              props.navigation.navigate('resignation-menu', {});
            },
          }}
        />
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        <EmployeeGroup.Section options={{label: 'Report'}}>
          <EmployeeGroup.Screen
            data-resource-id="EngGrossMarginsReport"
            name="Eng. gross margins"
            component={EngGrossMarginTab}
            props={{user: user, ...props}}
            options={{
              label: 'Eng. gross margins',
            }}
          />
          <EmployeeGroup.Screen
            data-resource-id="BenchCostReport"
            name="Bench Cost"
            component={BenchCostReportTab}
            props={{user: user, ...props}}
            options={{
              label: 'Bench Cost',
            }}
          />
          <EmployeeGroup.Screen
            data-resource-id="Non engg team report"
            name="Non engg cost"
            component={NonEnggTeamReport}
            props={{user: user, ...props}}
            options={{
              label: 'Non engg cost',
            }}
          />
          <EmployeeGroup.Screen
            data-resource-id="Employee Turnover"
            name="Employee Turnover"
            component={EmployeeTurnoverReport}
            props={{user: user, ...props}}
            options={{
              label: 'Employee Turnover',
            }}
          />
          <EmployeeGroup.Screen
            data-resource-id="CurrentAllocations"
          name="Allocation Report"
            component={ResourceAllocationTab}
            props={{user: user, ...props}}
            options={{
              label: 'Allocation Report',
            }}
          />
          <EmployeeGroup.Screen
            data-resource-id="ManagementCostReport"
            name="Management cost"
            component={ManagementCostTable}
            props={{user: user, ...props}}
            options={{
              label: 'Management cost',
            }}
          />
        </EmployeeGroup.Section>
      </EmployeeGroup.Group>
      <EmployeeGroup.Group>
        {/* <EmployeeGroup.Section options={{label: 'HR'}}> */}
        <EmployeeGroup.Screen
          data-resource-id="Confirmations"
          name="Confirmations"
          options={{
            label: 'Confirmation',
            onPress: () => {
              props.navigation.navigate('confirmation-menu', {});
            },
          }}
          // component={MarkIdentifyForSwappingTabs}
          props={{user: user, ...props}}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeWithPackage"
          name="Employee"
          component={EmployeeWithPackageTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Employee',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeWithPackageForHR"
          name="Employee(Hr)"
          component={EmployeeWithPackageForHrTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Employee(HR)',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeAttendances"
          options={{
            label: 'Attendance',
            onPress: () => {
              props.navigation.navigate('employee-setup', {
                ...props?.route?.params,
              });
            },
          }}
        />
        {/* <EmployeeGroup.Screen
          data-resource-id="ProjectFeedback"
          options={{
            label: 'Employee Feedback',
            onPress: () => {
              props.navigation.navigate('employee-project-feedback-hr', {
                ...props?.route?.params,
                tab : "HR"
              });
            },
          }}
        />
         <EmployeeGroup.Screen
          data-resource-id="Appraisal"
          options={{
            label: 'Employee Appraisal',
            onPress: () => {
              props.navigation.navigate('employee-appraisal-hr', {
                ...props?.route?.params,
                tab : "HR"
              });
            },
          }}
        /> */}
        <EmployeeGroup.Screen
          data-resource-id="EmployeeSalaryHr"
          options={{
            label: 'Salary',
            onPress: () => {
              props.navigation.navigate('employee-salary-hr', {
                ...props?.route?.params,
              });
            },
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeParichay"
          name="EmployeeParichay"
          component={EmployeeParichayTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Employee Parichay',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="BackgroundVerification"
          name="BackgroundVerification"
          component={BackgroundVerificationTabs}
          props={{user: user, ...props}}
          options={{
            label: 'Background verification',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeVariable"
          name="EmployeeVariable"
          component={EmployeeVariableTable}
          props={{user: user, ...props}}
          options={{
            label: 'Employee Variable',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="Nodues"
          name="Nodues"
          component={NoDuesHrTabs}
          props={{user: user, ...props}}
          options={{
            label: 'No dues',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="F&F"
          name="F&F"
          component={FnFHrTabs}
          props={{user: user, ...props}}
          options={{
            label: 'F&F',
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="ProductivityFeedbacks"
          name="Productivity Feedbacks"
          options={{
            label: 'Productivity Feedbacks',
          }}
          component={ProductivitySurveyTablePI}
        />
        <EmployeeGroup.Screen
          data-resource-id="SelectedCandidates"
          name="Selected Candidates"
          options={{
            label: 'Selected Candidates',
          }}
          component={SelectedCandidates}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeSetupHr"
          options={{
            label: 'Setup',
            onPress: () => {
              props.navigation.navigate('employee-setup-navigator', {
                ...props?.route?.params,
              });
            },
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="EmployeeReports"
          options={{
            label: 'Reports',
            onPress: () => {
              props.navigation.navigate('employee-report-navigator', {
                ...props?.route?.params,
              });
            },
          }}
        />
        <EmployeeGroup.Screen
          data-resource-id="HR Recruitment"
          name="Recruitment"
          options={{
            label: 'Recruitment',
            onPress: () => props.navigation.navigate('hr-recruitment-menu'),
          }}
        />
      </EmployeeGroup.Group>
    </EmployeeGroup.Navigator>
  );
};
export default EmployeeNewNavigator;
