import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {Chip} from '../../../components/chip/Chip';
import {Row} from '@unthinkable/react-core-components';
import {plural} from 'pluralize';

const RenderResources = ({value}) => {
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      {value?.map(resource => (
        <Chip value={resource.resource} />
      ))}
    </Row>
  );
};
const RenderDataEntity = ({value}) => {
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      <Chip value={value?.name} />
    </Row>
  );
};

export const AccessRoleForm = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/accessroles',
    eventSourceId: ['accessRoleAdded'],
  });
  return (
    <Form
      api={`/accessroles/${row?._id}`}
      fields={{
        role: 1,
        access_groups: {
          access_group: {name: 1},
          resources: {resource: 1},
          access_level: 1,
        },
        access_entities: {
          role: 1,
          view: 1,
          dataEntity: {name: 1},
        },
        roles: {role: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Role',
          field: 'role',
          type: 'text',
        },
        {
          label: 'Access Groups',
          field: 'access_groups',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              columns={[
                {
                  header: 'Access Group',
                  field: 'access_group.name',
                  type: 'text',
                },
                {
                  header: 'Access Level',
                  field: 'access_level',
                  type: 'text',
                },
                {
                  header: 'Resources',
                  field: 'resources',
                  render: RenderResources,
                  type: 'text',
                  width: 300,
                },
              ]}
              fields={[
                {
                  label: 'Access Group',
                  field: 'access_group',
                  type: 'autoComplete',
                  api: '/accessGroups',
                  valueField: 'name',
                  suggestionField: 'name',
                },
                {
                  label: 'Access Level',
                  field: 'access_level',
                  type: 'autoComplete',
                  options: ['full', 'partial'],
                },
                {
                  label: 'Resources',
                  field: 'resources',
                  type: 'multiAutoComplete',
                  api: '/accessresources',
                  suggestionField: 'resource',
                  valueField: 'resource',
                  visible: ({values}) => values?.access_level == 'partial',
                  // onCreate: props => {
                  //   navigation.navigate('add-access-resource', {});
                  // },
                },
              ]}
            />
          ),
        },
        {
          label: 'Access Entities',
          field: 'access_entities',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              columns={[
                {
                  header: 'Data Entity',
                  field: 'dataEntity',
                  render: RenderDataEntity,
                  type: 'text',
                  width: 300,
                },
                {
                  header: 'View',
                  field: 'view',
                  type: 'text',
                },
                {
                  header: 'Role',
                  field: 'role',
                  type: 'text',
                },
              ]}
              fields={[
                {
                  label: 'Data Entity',
                  field: 'dataEntity',
                  type: 'autoComplete',
                  api: '/types',
                  suggestionField: 'name',
                  valueField: 'name',
                },
                {
                  label: 'View',
                  field: 'view',
                  type: 'autoComplete',
                  api: props => {
                    const {values: {dataEntity: {name = ''} = {}} = {}} = props;
                    return `/${plural(name)}/metadata/views`;
                  },
                },
                {
                  label: 'Role',
                  field: 'role',
                  type: 'autoComplete',
                  api: props => {
                    const {values: {view, dataEntity: {name = ''} = {}} = {}} =
                      props;
                    return {
                      uri: `/${plural(name)}/metadata/roles`,
                    };
                  },
                  params: props => {
                    const {values: {view, dataEntity: {name = ''} = {}} = {}} =
                      props;
                    return {
                      view,
                    };
                  },
                },
              ]}
            />
          ),
        },
        {
          label: 'Child Roles',
          placeholder: 'select role',
          field: 'roles',
          type: 'multiAutoComplete',
          api: `/accessroles`,
          suggestionField: 'role',
          valueField: 'role',
        },
      ]}
      {...props}
    />
  );
};

export const AddAccessRole = props => {
  return <AccessRoleForm header="Add Access Role" {...props} />;
};

export const UpdateAccessRole = props => {
  return <AccessRoleForm mode="edit" header="Update Access Role" {...props} />;
};
