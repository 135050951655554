import React from 'react';
import {Table} from '../../../components/table/Table';
import {staffAugmentationControllers} from '../controllers/staffAugmentationControllers';
import {TabView} from '../../../components/tab/TabView';
import {CompositeFieldsRender} from '@unthinkable/react-table';

const NextTaskRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {field: 'next_task', type: 'text'},
      {
        field: 'next_task_date',
        type: 'date',
      },
    ]}
  />
);

const LastCommunicationRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {
        field: 'last_communication',
        type: 'text',
      },
      {
        field: 'last_communication_date',
        type: 'date',
      },
    ]}
  />
);

const InputDepartmentContractNegotiationTable = props => {
  const params = props.route.params;
  const {addOnFilter, api} = props;
  const {assignedCount, activeCount, selectedCount} =
    staffAugmentationControllers(props);
  return (
    <Table
      api={api}
      {...props}
      params={params}
      addOnFilter={addOnFilter}
      columns={[
        {
          type: 'date',
          field: 'creation_date',
          header: 'Created On',
        },

        {
          field: 'owner',
          header: 'BD Person',
          type: 'userAvatarChip',
        },
        {
          field: 'account_owner',
          header: 'Account Owner',
          type: 'userAvatarChip',
        },
        {
          field: 'rfq_owner',
          header: 'Pre Sales',
          type: 'userAvatarGroup',
        },

        {
          header: 'Customer',
          field: 'customer_id.name',
        },

        {
          header: 'Skill',
          field: 'skill.name',
        },

        {
          header: 'Mode of Screening',
          field: 'process',
        },
        {
          header: 'Work Location',
          field: 'work_location',
        },
        {
          header: 'No. of Resources',
          field: 'no_of_resources',
        },
        {
          header: 'Priority',
          field: 'priority',
        },
        {
          header: 'Status',
          field: 'status',
        },
        {
          header: 'Resource',
          field: 'no_of_resources',
        },
        {
          header: 'Assigned',
          field: 'assigned_count',
          onPress: ({row}) => {
            assignedCount({row});
          },
        },
        {
          header: 'Active',
          field: 'active_count',
          onPress: ({row}) => {
            activeCount({row});
          },
        },
        {
          header: 'Pending',
          field: 'pending_count',
        },
        {
          header: 'Selected',
          field: 'selected_count',
          onPress: ({row}) => {
            selectedCount({row});
          },
        },
        {
          type: 'date',
          header: 'Outcome Date',
          field: 'outcome_date',
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
        },
        {
          header: 'Last Communication',
          render: LastCommunicationRender,
        },
      ]}
    />
  );
};

export const CustomerGrowthContractNegotiationTab = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentContractNegotiationApi = {
    api: '/customerContractNegotiation/all',
    addOnFilter: {
      contract_negotiation_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentContractNegotiationApi,
    view: (
      <InputDepartmentContractNegotiationTable
        {...props}
        {...InputDepartmentContractNegotiationApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomerAcquisitionContractNegotiationTab = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const tabs = {};

  const InputDepartmentContractNegotiationApi = {
    api: '/customerContractNegotiation/all',
    addOnFilter: {
      contract_negotiation_date: {
        $gte: period.from,
        $lte: period.to,
      },
    },
  };

  tabs.all = {
    label: 'All',
    ...InputDepartmentContractNegotiationApi,
    view: (
      <InputDepartmentContractNegotiationTable
        {...props}
        {...InputDepartmentContractNegotiationApi}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
