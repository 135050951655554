import React from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  Linking,
  Image,
  Styled,
} from '@unthinkable/react-core-components';

const StyledTouchableOpacity = Styled(TouchableOpacity)`
cursor: pointer;
justify-content: center;
height: 70px;
min-width: 100px;
max-width: 150px;
padding: 4px;
border-width: 1px;
border-color: #EEEEED;
border-radius: 4px;
background-color: #fff;
${({containerStyle, showText, selected}) => {
  containerStyle = {
    ...(selected && {
      cursor: 'pointer',
      justifyContent: 'center',
      height: 70,
      minWidth: 100,
      maxWidth: 150,
      padding: 4,
      borderWidth: 3,
      borderColor: '#7DCD28',
      borderRadius: 4,
      backgroundColor: '#fff',
    }),
    ...(showText ? {maxWidth: 200, padding: 8} : {}),
    ...containerStyle,
  };

  return containerStyle;
}}`;

const RemoveWrapper = Styled(TouchableOpacity)`
  position: absolute;
        top: -8px;
        right: -8px;
  
  ${({containerStyle}) => containerStyle}`;

const StyledView = Styled(View)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const RemoveInnerContainer = Styled(View)`
cursor: pointer;
height: 18px;
width: 18px;
border-radius: 18px;
background-color: #fff;
border-width: 1px;
border-color: #EEEEED;
align-items: center;
justify-content: center;

${({containerStyle}) => containerStyle}`;
const StyledText = Styled(Text)`${({textStyle}) => textStyle}`;
const RemoveText = Styled(Text)`
font-size: 10px;
font-family: Inter-Medium;
line-height: 10px;
color: #7D7D82;
${({textStyle}) => textStyle}`;

const StyledImage = Styled(Image)`max-height: 100%;
max-width: 100%;`;

const RemoveFile = ({
  removePositionStyle,
  removeContainerStyle,
  removeTextStyle,
  onRemove,
}) => {
  if (!onRemove) {
    return null;
  }
  return (
    <RemoveWrapper
      containerStyle={{
        ...removePositionStyle,
      }}
      onPress={onRemove}>
      <RemoveInnerContainer containerStyle={removeContainerStyle}>
        <RemoveText textStyle={removeTextStyle}>X</RemoveText>
      </RemoveInnerContainer>
    </RemoveWrapper>
  );
};

export const FileRender = ({
  value,
  resourceUrl,
  containerStyle,
  selectedContainerStyle,
  textContainerStyle,
  textStyle,
  onPress,
  openInNewTab,
  selected,
  defaultContentTypeIcons,
  ...props
}) => {
  if (!value) {
    return null;
  }
  const {filename, contentType} = value;
  const url = resourceUrl && resourceUrl(value);
  let renderComponent = void 0;
  let showText = false;
  if (contentType?.startsWith('image/') && !contentType?.endsWith('/svg+xml')) {
    renderComponent = (
      <StyledView>
        <StyledImage resizeMode={'contain'} source={url} />
      </StyledView>
    );
  } else {
    showText = true;
    const isApplicationType = contentType?.startsWith('application/');
    const isVideoType = contentType?.startsWith('video/');
    const isSVGType = contentType?.endsWith('/svg+xml');
    let contentTypeIcon = isApplicationType
      ? defaultContentTypeIcons?.application
      : isVideoType
      ? defaultContentTypeIcons?.video
      : isSVGType
      ? defaultContentTypeIcons?.image
      : void 0;

    renderComponent = <StyledText textStyle={textStyle}>{filename}</StyledText>;

    if (contentTypeIcon) {
      renderComponent = (
        <View>
          <View>
            <StyledView>
              <StyledImage resizeMode={'contain'} source={contentTypeIcon} />
            </StyledView>
          </View>
          {renderComponent}
        </View>
      );
    }
  }
  return (
    <StyledTouchableOpacity
      title={filename}
      selected={selected}
      showText={showText}
      containerStyle={{
        ...containerStyle,
        ...(selected && selectedContainerStyle),
        ...(showText && textContainerStyle),
      }}
      onPress={() => {
        onPress && onPress();
        url && openInNewTab !== false && Linking.openURL(url, '_blank');
      }}>
      {renderComponent}
      <RemoveFile {...props} />
    </StyledTouchableOpacity>
  );
};
