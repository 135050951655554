import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddLandlordDetailsForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/incometaxes`,
    eventSourceId: 'taxDeclaration',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Landlord Details'}
      api={`/taxDeclarationDetail/${row?._id}`}
      mode={'edit'}
      submitAction={'Update'}
      layoutFields={[
        {
          type: 'text',
          field: 'land_lord_name',
          label: 'Landlord Name',
          size: 6,
        },
        {
          type: 'text',
          field: 'landlord_pan_no',
          label: 'Landlord Pan No',
          size: 6,
        },
        {
          type: 'textArea',
          field: 'landlord_address',
          label: 'Landlord Address',
        },
      ]}
    />
  );
};
