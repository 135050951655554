import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {
  ActiveTasksTable,
  OverDueTasksTable,
  OverdueReviewTasksTable,
} from './TasksTable';
import {EmployeeProjectTasks} from './TasksTable';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter} from '../../../components/filter/Filters';
import {taskFilters} from './TaskActions';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {useAppStateContext} from '../../../providers/AppState';

export const EmployeeTasksTab = props => {
  const {route} = props;
  const {params = {}} = route;
  const {filterValues, ...filterProps} = useFilter({});
  const {fromProject} = params;
  if (fromProject) {
    return <EmployeeProjectTasks {...props} filterValues={filterValues} />;
  }
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      selectedTab={params.selectedTab}
      {...props}
      tabs={{
        projectTasks: {
          label: 'Project Tasks',
          view: <EmployeeProjectTasks {...props} filterValues={filterValues} />,
          eventSourceId: 'Task',
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.project({user})]}
            />,
          ],
        },
        organizationTasks: {
          label: 'Custom Tasks',
          view: (
            <ActiveTasksTable
              {...props}
              skipAddTask
              filterValues={filterValues}
            />
          ),
          eventSourceId: 'Task',
          actions: [
            <GroupFilter
              {...filterProps}
              filterValues={filterValues}
              filters={[taskFilters.custom]}
            />,
          ],
        },
      }}
    />
  );
};

export const EmployeeOverdueTasksTab = props => {
  return (
    <LeftMenuView
      {...props}
      menus={[
        {
          label: 'Tasks',
          view: <OverDueTasksTable />,
        },
        {
          label: 'Reviews',
          view: <OverdueReviewTasksTable />,
        },
      ]}
    />
  );
};
