import { getPreviousMonth } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { Chip } from '../../../components/chip/Chip';
import {
    GroupFilter,
    PeriodRangeFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { NavHeader } from '../../../components/nav/NavHeader';
import { TableHeader } from '../../../components/table/Headers';
import {
    AggregateComparisonRender,
    ComparisonRender,
} from '../../../components/table/Renders';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useFilterKeyValues } from '../../../providers/FilterProvider';
import { leadControllers } from '../controllers/leadControllers';

export const PaidAdHeader = props => {
  const {
    route: {params},
  } = props;
  return (
    <NavHeader
      title="Paid Ad"
      {...props}
      actions={[
        <AddButton
          title="Paid Ad"
          view="add-paidadcampaign"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

const PaidAdCampaignTableHeader = props => {
  const {
    route: {params},
  } = props;
  const {onChangeFilter, searchValue, applyFilter} = useFilter({
    filterKey: 'paidAdFilter',
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  return (
    <TableHeader
      leftActions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'owner',
            },
          ]}
        />,
      ]}
    />
  );
};

const AdIdAndTypeRender = props => {
  const {row} = props;
  if (!row?.adId && !row?.ad_type) {
    return null;
  }
  const value = row?.adId + ' ( ' + row?.ad_type + ' )';
  return <Chip value={value} />;
};

export const PaidAdsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {product} = params;
  const {searchValue, filterValues = {}} = useFilterKeyValues('paidAdFilter');
  const {filter, params: {period} = {}} = filterValues;
  const {qualifiedLeads} = leadControllers({
    ...props,
    params: {...params, period},
  });
  return (
    <Table
      renderHeader={() => {
        return <PaidAdCampaignTableHeader {...props} />;
      }}
      api={`/product/${product?._id}/paid-ads`}
      params={{
        period: {
          from: period?.from,
          to: period?.to,
        },
      }}
      onRowPress={({row}) => {
        navigation.navigate(`edit-paid-ad`, {
          row,
        });
      }}
      addOnFilter={filter}
      search={searchValue}
      searchFields={'name'}
      columns={[
        {
          field: 'name',
          header: 'Task Name',
        },
        {
          header: 'Ad ID (Ad Type)',
          render: AdIdAndTypeRender,
        },
        {
          field: 'owner.name',
          colorField: 'owner.color',
          header: 'Owner',
          type: 'colorTag',
        },
        {
          type: 'number',
          field: ({ad_metrics}) => {
            return ad_metrics?.[0]?.click_through_rate;
          },
          header: 'CTR',
        },
        {
          type: 'number',
          field: ({ad_metrics}) => {
            return ad_metrics?.[0]?.conversion_rate;
          },
          header: 'CR',
        },
        {
          type: 'number',
          field: ({ad_metrics}) => {
            return ad_metrics?.[0]?.cost_per_click;
          },
          header: 'CPC',
        },
        // {
        //   type: 'number',
        //   field: ({ad_metrics}) => {
        //     return ad_metrics?.[0]?.cost_per_conversion;
        //   },
        //   header: 'Cost Per Conversion',
        // },
        {
          type: 'number',
          field: 'hours',
          header: 'Hours',
        },
        {
          width: 140,
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'Qualified Lead',
          aggregate: AggregateComparisonRender,
          onPress: qualifiedLeads,
          onComparePress: qualifiedLeads,
          render: ComparisonRender,
        },
      ]}
      moreActions={[
        {
          title: 'Fetch Analytics',
          onPress: ({row}) => {
            props.navigation.navigate('fetch-paidad-analytics', {
              row,
            });
          },
        },
      ]}
    />
  );
};
