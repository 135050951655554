export const DynamicFkColumnStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    textStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_LOW,
      marginLeft: 8,
      marginTop: 2,
    },
  };
};

export const CountCellRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {
      ...fonts.BODY3,
      color: colors.INFORMATION_HIGH,
    },
    addPlus: icons.AddPlus,
    iconStyle: {height: 12, width: 12},
    iconContainerStyle: {
      backgroundColor: colors.BRAND_UPPER_LOW,
      padding: 6,
      marginLeft: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 46,
    },
    rowStyle: {
      alignSelf: 'flex-end',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subCountStyle: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_LOW,
    },
    containerStyle: {
      backgroundColor: colors.INFORMATION_LOW,
      borderRadius: 4,
      paddingTop: 1,
      paddingRight: 6,
      paddingBottom: 1,
      paddingLeft: 6,
    },
    columnFieldContainer: {
      paddingTop: 2,
      paddingRight: 4,
      paddingBottom: 2,
      paddingLeft: 4,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.INFORMATION_LOW,
    },
    countFieldContainer: {
      paddingTop: 2,
      paddingRight: 4,
      paddingBottom: 2,
      paddingLeft: 4,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 82, 71, 0.1)',
    },
    countFieldStyle: {
      ...fonts.ACTION,
      color: colors.ERROR_HIGH,
    },
    successBackgroundStyle: colors.SUCCESS_LOW,
    successTextStyle: colors.SUCCESS_HIGH,
    estChange: icons.EstChange,
    estChangeStyle: {
      height: 12,
      width: 12,
      position: 'absolute',
      top: -5,
      right: -3,
    },
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    fontWeightStyle: {
      fontWeight: 'bold',
    },
    emailTextStyle: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_HIGH,
    },
    emailCountStyle: {
      ...fonts.CAPTION_LARGE,
      color: colors.INFORMATION_HIGH,
    },
    latestActivityTextStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    latestDateTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const CountPlusRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {
      ...fonts.BODY2,
      color: colors.INFORMATION_HIGH,
    },
  };
};

export const PlusColumnRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    addPlus: icons.AddPlus,
    lockIcon: icons.LockIcon,
    iconStyle: {height: 12, width: 12},
    iconContainerStyle: {
      backgroundColor: colors.BRAND_UPPER_LOW,
      padding: 6,
      marginLeft: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 46,
      width: 24,
    },
    rowStyle: {
      alignItems: 'flex-start',
    },
    containerStyle: {
      backgroundColor: colors.INFORMATION_LOW,
      borderRadius: 100,
      padding: 4,
    },
    estChange: icons.EstChange,
    alertCircleGray: icons.AlertCircleGray,
    estChangeStyle: {
      height: 12,
      width: 12,
      position: 'absolute',
      top: -8,
      right: -7,
    },
    iconStyle: {
      height: 11,
      width: 12,
    },
  };
};

export const DeployRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    RUNNING: {
      icon: icons.BlueSandClock,
      branchIcon: icons.BranchBlue,
      status_header: {
        backgroundColor: colors.INFORMATION_MEDIUM,
      },
      status_text: {
        color: colors.INFORMATION_HIGH,
      },
    },
    INPROGRESS: {
      icon: icons.BlueSandClock,
      branchIcon: icons.BranchBlue,
      status_header: {
        backgroundColor: colors.INFORMATION_MEDIUM,
      },
      status_text: {
        color: colors.INFORMATION_HIGH,
      },
    },
    FAILURE: {
      icon: icons.RedCircleIssue,
      branchIcon: icons.BranchRed,
      status_header: {
        backgroundColor: colors.ERROR_MEDIUM,
      },
      status_text: {
        color: colors.ERROR_HIGH,
      },
    },
    FAILED: {
      icon: icons.RedCircleIssue,
      branchIcon: icons.BranchRed,
      status_header: {
        backgroundColor: colors.ERROR_MEDIUM,
      },
      status_text: {
        color: colors.ERROR_HIGH,
      },
    },
    DEFAULT: {
      icon: icons.Rocket,
      branchIcon: icons.BranchGrey,
      status_header: {
        backgroundColor: colors.OUTLINE,
      },
      status_text: {
        color: colors.NEUTRAL_HIGH,
      },
    },
    SUCCESS: {
      icon: icons.GreenOutlineTick,
      branchIcon: icons.BranchGreen,
      status_header: {
        backgroundColor: colors.SUCCESS_MEDIUM,
      },
      status_text: {
        color: colors.SUCCESS_HIGH,
      },
    },
    container: {
      height: 220,
      borderRadius: 4,
      overflow: 'hidden',
      margin: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.OUTLINE,
    },
    header: {
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 8,
      paddingBottom: 8,
      alignItems: 'center',
      height: 45,
    },
    titleContainer: {
      flex: 1,
    },
    title: {
      ...fonts.BODY1,
      color: colors.BACKGROUND,
    },
    body: {
      flex: 1,
      backgroundColor: colors.BACKGROUND,
    },
    buildText: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_HIGH,
    },
    columnTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    textStyle: {
      ...fonts.BODY2,
      color: colors.INFORMATION_HIGH,
    },
    deployContainer: {
      height: 35,
      backgroundColor: colors.SURFACE3,
      overflow: 'hidden',
    },
    deployTextStyle: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.HEADING1,
    },
    historyText: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_HIGH,
    },
    historyPipelineText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    duration: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_LOW,
    },
    currentHistoryStyle: {
      flex: 1,
      padding: 16,
    },
    successHistoryStyle: {
      borderColor: colors.OUTLINE,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderStyle: 'solid',
      borderWidth: 1,
      padding: 16,
    },
    activeDeployButtonStyle: {
      container: {
        backgroundColor: colors.SURFACE3,
        borderColor: colors.OUTLINE,
        borderStyle: 'solid',
        borderWidth: 1,
      },
      text: {
        color: colors.NEUTRAL_HIGH,
        ...fonts.ACTION,
      },
    },
  };
};

export const StatusIconRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    greyTickIcon: icons.GreyTickIcon,
    greenTickIcon: icons.GreenTickIcon,
    iconStyle: {height: 20, width: 20},
  };
};
export const StatusRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {
      display: 'flex',
      justifyContent: 'center',
      ...fonts.BODY2,
      backgroundColor: colors.SUCCESS_LOW,
      padding: 4,
      borderRadius: 4,
    },
    textStyle: {
      color: colors.SUCCESS_HIGH,
    },
    activeTextStyle: {
      color: colors.INFORMATION_HIGH,
    },
    activeBackgroundStyle: {
      backgroundColor: colors.INFORMATION_LOW,
    },
    qaTextStyle: {
      color: colors.WARNING_HIGH,
    },
    qaBackgroundStyle: {
      backgroundColor: colors.WARNING_LOW,
    },
    rejectedTextStyle: {
      color: colors.ERROR_HIGH,
    },
    rejectedBackgroundStyle: {
      backgroundColor: colors.ERROR_LOW,
    },
    passedBackgroundStyle: {
      backgroundColor: colors.SUCCESS_LOW,
    },
    passedTextStyle: {
      color: colors.SUCCESS_HIGH,
    },
    designTextStyle: {
      color: colors.ACCENT2_HIGH,
    },
    designBackgroundStyle: {
      backgroundColor: colors.ACCENT2_LOW,
    },
    qATextStyle: {
      color: colors.ACCENT4_HIGH,
    },
    qABackgroundStyle: {
      backgroundColor: colors.ACCENT4_LOW,
    },
    codeTextStyle: {
      color: colors.ACCENT3_HIGH,
    },
    codeBackgroundStyle: {
      backgroundColor: colors.ACCENT3_LOW,
    },

    uatTextStyle: {
      color: colors.ACCENT6_HIGH,
    },
    uatBackgroundStyle: {
      backgroundColor: colors.ACCENT6_LOW,
    },
  };
};

export const ColumnValueRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {...fonts.BODY2, color: colors.INFORMATION_HIGH},
    rowStyle: {alignSelf: 'flex-end'},
  };
};

export const ImageTextRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {
      ...fonts.BODY2,
      color: colors.INFORMATION_HIGH,
      backgroundColor: colors.INFORMATION_LOW,
      padding: 4,
      borderRadius: 10,
    },
    actionTextWrapper: {alignItems: 'center', justifyContent: 'center'},
    designImage: icons.DesignImage,
    iconStyle: {height: 16, width: 16},
  };
};

export const MultipleTextRenderStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    rowStyle: {justifyContent: 'center'},

    addPlus: icons.AddPlus,
    iconStyle: {height: 12, width: 12},
    iconContainerStyle: {
      backgroundColor: colors.BRAND_UPPER_LOW,
      padding: 6,
      marginLeft: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 46,
      width: 24,
    },
    textStyle: {
      marginLeft: 4,
      alignSelf: 'center',
    },
    columnTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    actionTextWrapper: {
      backgroundColor: colors.ACCENT2_MEDIUM,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 4,
      marginRight: 10,
    },
  };
};

export const DesignCellRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    columnTextStyle: {...fonts.BODY2, color: colors.INFORMATION_HIGH},
    textStyle: {...fonts.BODY2, color: colors.INFORMATION_HIGH},
    addPlus: icons.AddPlus,
    Edit: icons.Edit,
    designIcon: icons.DesignImage,
    BrushIcon: icons.BrushGrey,
    featureDesignIcon: icons.Document,
    iconStyle: {height: 12, width: 12},
    designIconStyle: {height: 16, width: 16},
    featureDesignIconStyle: {height: 18, width: 18},
    iconContainerStyle: {
      backgroundColor: colors.BRAND_UPPER_LOW,
      padding: 6,
      marginLeft: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 46,
    },
    rowStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerStyle: {
      backgroundColor: colors.INFORMATION_LOW,
      borderRadius: 100,
      padding: 4,
    },
  };
};

export const ReviewedTaskRenderStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    lowTextStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_LOW,
    },
  };
};

export const BranchNameCopyRenderStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    copy: icons.Copy,
    text: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    subTextStyle: {
      ...fonts.FOOTNOTE,
      color: colors.NEUTRAL_MEDIUM,
      marginTop: 4,
    },
  };
};

export const SelectButtonRenderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    dropDown: icons.ExpandSmall,
    iconStyle: {height: 16, width: 16},
    rowStyle: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      borderWidth: 1,
      borderRadius: 8,
      height: 48,
      backgroundColor: colors.SURFACE1,
      borderColor: colors.OUTLINE,
      paddingLeft: 6,
      paddingRight: 6,
      marginLeft: 8,
    },
    textStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
      marginBottom: 2,
      marginRight: 8,
      marginLeft: 4,
    },
  };
};

export const FeatureWiseStatusRenderStyles = ({theme: {fonts, colors}}) => {
  return {
    columnTextStyle: {...fonts.CAPTION_LARGE, color: colors.NEUTRAL_HIGH},
    footerTextStyle: {
      ...fonts.FOOTNOTE,
      color: colors.ACCENT1_HIGH,
    },
    releasedStatusColor: {
      color: colors.ACCENT3_HIGH,
      // backgroundColor: colors.SUCCESS_LOW,
    },
    backlogStatusColor: {
      color: colors.WARNING_HIGH,
      // backgroundColor: colors.WARNING_LOW,
    },
    archivedStatusColor: {
      color: colors.NEUTRAL_LOW,
      // backgroundColor: colors.SURFACE2,
    },
    colStyle: {
      alignItems: 'flex-start',
    },
    containerStyle: {
      backgroundColor: colors.INFORMATION_LOW,
    },
  };
};

export const PipelineRenderStyle = ({theme: {fonts, colors}}) => {
  return {
    queueTextStyle: {
      color: colors.NEUTRAL_LOW,
      ...fonts.CAPTION_SMALL,
    },
    textStyle: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.CAPTION_LARGE,
    },
  };
};

export const DeploymentStatusStyle = ({theme: {fonts, colors}}) => {
  return {
    FAILURE: {
      color: colors.ERROR_HIGH,
      ...fonts.BODY1,
    },
    FAILED: {
      color: colors.ERROR_HIGH,
      ...fonts.BODY1,
    },
    SUCCESS: {
      color: colors.SUCCESS_HIGH,
      ...fonts.BODY1,
    },
    SUCCESSFUL: {
      color: colors.SUCCESS_HIGH,
      ...fonts.BODY1,
    },
    RUNNING: {
      color: colors.ACCENT3_HIGH,
      ...fonts.BODY1,
    },
  };
};

export const FileFieldRenderStyle = ({theme: {fonts, colors}}) => ({
  textStyle: {
    color: colors.INFORMATION_HIGH,
    textDecoration: 'underline',
    ...fonts.BODY2,
  },
});

export const DataEntityStatusStyle = ({theme: {fonts, colors}}) => ({
  statusStyle: {
    'In Progress': {
      color: colors.INFORMATION_HIGH,
    },
    Failed: {
      color: colors.ERROR_HIGH,
    },
    Completed: {
      color: colors.SUCCESS_HIGH,
    },
    Success: {
      color: colors.SUCCESS_HIGH,
    },
  },
  statusText: {
    ...fonts.BODY2,
  },
  errorText: {
    ...fonts.FOOTNOTE,
    color: colors.NEUTRAL_LOW,
  },
});

export const FeatureReviewStyle = ({theme: {fonts, colors}}) => ({
  FeatureDetail: {
    containerStyle: {
      backgroundColor: colors.BACKGROUND,
      padding: 12,
      borderBottomWidth: 3,
      borderBottomColor: colors.SURFACE2,
      borderRadius: 8,
    },
    headerContainerStyle: {
      paddingBottom: 10,
      borderBottomWidth: 0.5,
      borderBottomColor: colors.OUTLINE,
    },
    headerTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
    codeTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
    releaseRowStyle: {alignSelf: 'flex-end'},
    releaseTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_LOW,
    },
    releaseDateTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.ACCENT2_HIGHNEUTRAL_MEDIUM,
    },
    featureHeaderTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_LOW,
    },
    featureTextStyle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_HIGH,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  PerformanceRating: {
    containerStyle: {
      backgroundColor: colors.BACKGROUND,
      padding: 12,
      borderRadius: 8,
    },
    headingTextStyle: {...fonts.BODY2, color: colors.NEUTRAL_HIGH},
  },
  GiveRating: {
    containerStyle: {
      backgroundColor: colors.ACCENT1_HIGH,
      padding: 12,
      alignItems: 'center',
      borderRadius: 6,
    },
    textStyle: {color: colors.BACKGROUND, ...fonts.BODY2},
  },
  DisplayRating: {
    containerStyle: {
      backgroundColor: colors.ACCENT1_HIGH,
      padding: 12,
      borderRadius: 6,
    },
    containerRowStyle: {justifyContent: 'space-between'},
    ratingRowContainerStyle: {
      paddingTop: 1,
      paddingBottom: 1,
      alignItems: 'center',
    },
    ratingTextStyle: {...fonts.TITLE, color: colors.BACKGROUND},
    bottomRowTextStyle: {...fonts.CAPTION_SMALL, color: colors.BACKGROUND},
  },
  emptyRatingColor: colors.BACKGROUND,
});

export const DeploymentPlatformCardStyle = ({
  theme: {fonts, colors, icons},
}) => {
  return {
    linkIcons: icons.ArrowSquareOut,
    container: {
      height: 150,
      width: 250,
      borderColor: colors.OUTLINE,
      borderStyle: 'solid',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: colors.SURFACE3,
      margin: 10,
      justifyContent: 'space-between',
      padding: 12,
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    label: {
      ...fonts.BODY1,
      color: colors.NEUTRAL_MEDIUM,
    },
    separator: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    title: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const PerformanceRenderStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    columnStyle: {alignItems: 'center'},
    textStyle: {
      ...fonts.FOOTNOTE,
      color: colors['NEUTRAL_LOW'],
      paddingBottom: 4,
      textAlign: 'left',
    },
    reviewTextStyle: {
      color: colors.NEUTRAL_MEDIUM,
    },
    noRatingTextColor: colors.NEUTRAL_LOW,
    noRatingTextBackgroundColor: colors.SURFACE2,
  };
};

export const ClosedByBugTableStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    columnStyle: {alignItems: 'center'},
    textStyle: {
      ...fonts.FOOTNOTE,
      color: colors['NEUTRAL_LOW'],
      paddingBottom: 4,
      textAlign: 'left',
    },
    reviewTextStyle: {
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
