import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFetchData } from '../../../controllers/useFetchData';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const MilestoneOrderResourceAssignmentForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}, filterParams = {}} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/assign/orderMileStone',
    eventSourceId: 'milestoneAssigned',
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Assign Resource"
      defaultValues={{
        order: row?.order?._id,
        product: row?.product,
        milestone_id: row?._id,
        is_internal: row?.order?.is_internal,
        project_id: row?.project,
      }}
      layoutFields={[
        {
          fields: [
            {
              label: 'Resource',
              field: 'employee',
              type: 'autoComplete',
              api: `/orderassignments`,
              suggestionField: 'employee.name',
              filter: {...filterParams},
              size: 12,
              valueField: 'name',
              required: true,
              fields: {
                _id: 1,
                name: 1,
                employee: {_id: 1, name: 1},
                from_date: 1,
                to_date: 1,
                hours: 1,
                skill_required: {name: 1},
                tool: {_id: 1, name: 1},
                resource_tool: {_id: 1, name: 1},
                resource_skill: {_id: 1, name: 1},
                project_id: {_id: 1, project: 1},
                level_one_owner: {_id: 1, name: 1},
                level_two_owner: {_id: 1, name: 1},
                level_three_owner: {_id: 1, name: 1},
                benefit_type: 1,
                applicable_for_daily_incentive: 1,
                is_manager: 1,
              },
              onChangeValue: (value, e, {setFieldValue}) => {
                let {
                  employee = {},
                  from_date,
                  to_date,
                  hours,
                  tool,
                  skill_required,
                  level_one_owner,
                  level_two_owner,
                  level_three_owner,
                  _id: order_assignment,
                  resource_tool,
                  resource_skill,
                  project_id,
                  benefit_type,
                  applicable_for_daily_incentive,
                  is_manager,
                } = value || {};
                setFieldValue('employee', {
                  ...employee,
                });
                setFieldValue('from_date', from_date);
                setFieldValue('from_date', from_date);
                setFieldValue('hours', hours);
                setFieldValue('to_date', to_date);
                setFieldValue('project_skill', skill_required);
                setFieldValue('tool', tool);
                setFieldValue('level_one_owner', level_one_owner);
                setFieldValue('level_two_owner', level_two_owner);
                setFieldValue('level_three_owner', level_three_owner);
                setFieldValue('order_assignment', order_assignment);
                setFieldValue('tool', tool);
                setFieldValue('resource_tool', resource_tool);
                setFieldValue('resource_skill', resource_skill);
                setFieldValue('project_id', project_id);
                setFieldValue('benefit_type', benefit_type);
                setFieldValue(
                  'applicable_for_daily_incentive',
                  applicable_for_daily_incentive,
                );
                setFieldValue('is_manager', is_manager);
              },
            },
          ],
        },
        {
          fields: [
            {
              label: 'Start Date',
              field: 'from_date',
              type: 'date',
              required: true,
              size: 4,
            },
            {
              label: 'End Date',
              field: 'to_date',
              type: 'date',
              required: true,
              size: 4,
            },
            {
              label: 'Hours',
              field: 'hours',
              type: 'number',
              required: true,
              size: 4,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Project',
              field: 'project_id',
              type: 'autoComplete',
              api: `/projects`,
              suggestionField: 'project',
              filter: {
                projecttype: {$in: ['f', 'i']},
              },
              valueField: 'project',
              required: true,
              size: 12,
            },
            {
              label: 'Project Skill',
              field: 'project_skill',
              type: 'autoComplete',
              api: `/skillmetrics`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Project Tool',
              field: 'tool',
              type: 'autoComplete',
              api: ({values = {}}) => {
                const {project_skill} = values;
                if (!project_skill) return `/tools`;
                return `/skillMetrics/toolsSuggestion/${project_skill?._id}`;
              },
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Resource Skill',
              field: 'resource_skill',
              type: 'autoComplete',
              api: `/skillmetrics`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Resource Tool',
              field: 'resource_tool',
              type: 'autoComplete',
              api: ({values = {}}) => {
                const {resource_skill} = values;
                if (!resource_skill) return `/tools`;
                return `/skillMetrics/toolsSuggestion/${resource_skill?._id}`;
              },
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              type: 'autoComplete',
              field: 'benefit_type',
              label: 'Benefit Type',
              valueField: 'label',
              keyField: 'value',
              suggestionField: 'label',

              options: [
                {value: 'training', label: 'Training-Project'},
                {value: 'shadow', label: 'Shadow'},
                {value: 'skill', label: 'Training-Cross-Skill'},
                {value: 'technology', label: 'Training-Cross-Technology'},
                {value: 'kt', label: 'KT'},
              ],
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value == 'shadow') {
                  setFieldValue('is_shadow', true);
                }
              },
              size: 6,
            },
            {
              label: 'Benefit Upto',
              field: 'benefit_upto',
              type: 'date',
              visible: ({values}) => {
                return values && values.benefit_type == 'training';
              },
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Level 1 Owner',
              field: 'level_one_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Level 2 Owner',
              field: 'level_two_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Level 3 Owner',
              field: 'level_three_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Applicable for MA',
          field: 'applicable_for_daily_incentive',
          size: 6,
        },
        {
          type: 'number',
          label: 'Amount',
          field: 'daily_incentive',
          visible: ({values = {}}) => values?.applicable_for_daily_incentive,
          required: true,
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'is_manager',
          label: 'Is Manager',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const FixOrderResourceAssignmentForRM = props => {
  const {
    route: {params},
  } = props;
  let {row = {}, milestone = {}, filterParams = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assign/orderMileStone',
    eventSourceId: 'milestoneAssigned',
  });
  const {data: resourceData} = useFetchData({
    api: '/orderassignments',
    filter: {
      employee: row?.employee?._id,
      ...filterParams,
    },
    fields: {
      _id: 1,
      employee: {_id: 1, name: 1},
      from_date: 1,
      to_date: 1,
      hours: 1,
      skill_required: {name: 1},
      tool: {_id: 1, name: 1},
      resource_tool: {_id: 1, name: 1},
      resource_skill: {_id: 1, name: 1},
      project_id: {_id: 1, project: 1},
      level_one_owner: {_id: 1, name: 1},
      level_two_owner: {_id: 1, name: 1},
      level_three_owner: {_id: 1, name: 1},
      benefit_type: 1,
      is_shadow: 1,
      applicable_for_daily_incentive: 1,
      is_manager: 1,
      is_included_in_utilisation: 1,
      percIncludeInUtilisation: 1,
    },
    only: true,
  });
  if (!resourceData) return null;
  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      header="Assign Resources"
      defaultValues={{
        order: milestone?.order?._id,
        product: milestone?.product,
        milestone_id: milestone?._id,
        is_internal: milestone?.order?.is_internal,
        // project_id: milestone?.project,
        employee: row?.employee,
        from_date: row?.from_date,
        to_date: row?.to_date,
        hours: resourceData?.hours,
        tool: resourceData?.tool,
        resource_tool: resourceData?.resource_tool,
        resource_skill: resourceData?.resource_skill,
        project_id: resourceData?.project_id,
        level_one_owner: resourceData?.level_one_owner,
        level_two_owner: resourceData?.level_two_owner,
        level_three_owner: resourceData?.level_three_owner,
        benefit_type: resourceData?.benefit_type,
        applicable_for_daily_incentive:
          resourceData?.applicable_for_daily_incentive,
        is_manager: resourceData?.is_manager,
        project_skill: resourceData?.skill_required,
        order_assignment: resourceData?._id,
        is_shadow: resourceData?.is_shadow,
        is_included_in_utilisation: resourceData?.is_included_in_utilisation,
        percIncludeInUtilisation: resourceData?.percIncludeInUtilisation,
      }}
      layoutFields={[
        {
          fields: [
            {
              label: 'Resource',
              field: 'employee',
              type: 'autoComplete',
              api: `/orderassignments`,
              suggestionField: 'employee.name',
              size: 12,
              valueField: 'name',
              required: true,
              // readOnly: true,
              filter: {...filterParams},
              fields: {
                _id: 1,
                name: 1,
                employee: {_id: 1, name: 1},
                from_date: 1,
                to_date: 1,
                hours: 1,
                skill_required: {name: 1},
                tool: {_id: 1, name: 1},
                resource_tool: {_id: 1, name: 1},
                resource_skill: {_id: 1, name: 1},
                project_id: {_id: 1, project: 1},
                level_one_owner: {_id: 1, name: 1},
                level_two_owner: {_id: 1, name: 1},
                level_three_owner: {_id: 1, name: 1},
                benefit_type: 1,
                applicable_for_daily_incentive: 1,
                is_manager: 1,
              },
              onChangeValue: (value, e, {setFieldValue}) => {
                let {
                  employee = {},
                  from_date,
                  to_date,
                  hours,
                  tool,
                  skill_required,
                  level_one_owner,
                  level_two_owner,
                  level_three_owner,
                  _id: order_assignment,
                  resource_tool,
                  resource_skill,
                  project_id,
                  benefit_type,
                  applicable_for_daily_incentive,
                  is_manager,
                } = value || {};
                setFieldValue('employee', {
                  ...employee,
                });
                setFieldValue('from_date', from_date);
                setFieldValue('from_date', from_date);
                setFieldValue('hours', hours);
                setFieldValue('to_date', to_date);
                setFieldValue('project_skill', skill_required);
                setFieldValue('tool', tool);
                setFieldValue('level_one_owner', level_one_owner);
                setFieldValue('level_two_owner', level_two_owner);
                setFieldValue('level_three_owner', level_three_owner);
                setFieldValue('order_assignment', order_assignment);
                setFieldValue('tool', tool);
                setFieldValue('resource_tool', resource_tool);
                setFieldValue('resource_skill', resource_skill);
                setFieldValue('project_id', project_id);
                setFieldValue('benefit_type', benefit_type);
                setFieldValue(
                  'applicable_for_daily_incentive',
                  applicable_for_daily_incentive,
                );
                setFieldValue('is_manager', is_manager);
              },
            },
          ],
        },
        {
          fields: [
            {
              label: 'Start Date',
              field: 'from_date',
              type: 'date',
              required: true,
              size: 4,
            },
            {
              label: 'End Date',
              field: 'to_date',
              type: 'date',
              required: true,
              size: 4,
            },
            {
              label: 'Hours',
              field: 'hours',
              type: 'number',
              required: true,
              size: 4,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Project',
              field: 'project_id',
              type: 'autoComplete',
              api: `/projects`,
              suggestionField: 'project',
              filter: {
                projecttype: {$in: ['f', 'i']},
              },
              valueField: 'project',
              required: true,
              size: 12,
            },
            {
              label: 'Project Skill',
              field: 'project_skill',
              type: 'autoComplete',
              api: `/skillmetrics`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Project Tool',
              field: 'tool',
              type: 'autoComplete',
              api: ({values = {}}) => {
                const {project_skill} = values;
                if (!project_skill) return `/tools`;
                return `/skillMetrics/toolsSuggestion/${project_skill?._id}`;
              },
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Resource Skill',
              field: 'resource_skill',
              type: 'autoComplete',
              api: `/skillmetrics`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Resource Tool',
              field: 'resource_tool',
              type: 'autoComplete',
              api: ({values = {}}) => {
                const {resource_skill} = values;
                if (!resource_skill) return `/tools`;
                return `/skillMetrics/toolsSuggestion/${resource_skill?._id}`;
              },
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              type: 'autoComplete',
              field: 'benefit_type',
              label: 'Benefit Type',
              valueField: 'label',
              keyField: 'value',
              suggestionField: 'label',

              options: [
                {value: 'training', label: 'Training-Project'},
                {value: 'shadow', label: 'Shadow'},
                {value: 'skill', label: 'Training-Cross-Skill'},
                {value: 'technology', label: 'Training-Cross-Technology'},
                {value: 'kt', label: 'KT'},
              ],
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value == 'shadow') {
                  setFieldValue('is_shadow', true);
                }
              },
              size: 6,
            },
            {
              label: 'Benefit Upto',
              field: 'benefit_upto',
              type: 'date',
              visible: ({values}) => {
                return values && values.benefit_type == 'training';
              },
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Level 1 Owner',
              field: 'level_one_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Level 2 Owner',
              field: 'level_two_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Level 3 Owner',
              field: 'level_three_owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 4,
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Applicable for MA',
          field: 'applicable_for_daily_incentive',
          size: 6,
        },
        {
          type: 'number',
          label: 'Amount',
          field: 'daily_incentive',
          visible: ({values = {}}) => values?.applicable_for_daily_incentive,
          required: true,
          size: 6,
        },
        {
          type: 'checkbox',
          field: 'is_manager',
          label: 'Is Manager',
          size: 6,
        },
        {
          type: 'checkbox',
          label: 'Allow More Than 9 Hours',
          field: 'allow_allocation_more_than_100',
          size: 6,
        },
        {
          type: 'checkbox',
          label: 'Is Included In Utilisation',
          field: 'is_included_in_utilisation',
          size: 6,
        },
        {
          label: 'Percentage Included In Utilisation',
          field: 'percIncludeInUtilisation',
          type: 'autoComplete',
          options: [
            {label: '100 %', value: 100},
            {label: '75 %', value: 75},
            {label: '50 %', value: 50},
          ],
          visible: ({values = {}}) => values?.is_included_in_utilisation,
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};
